import React from 'react'
import Navbar from '../components/Navbar'
import UploadTranslat from '../components/uploadtranslate'

 const UploadTranslate = () => {
   return (
     <div style={{backgroundColor:"white"}}>
         <Navbar />
         <UploadTranslat />
     </div>
   )
 }

export default UploadTranslate;