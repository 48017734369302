import React from 'react'
import Arch_navbar from '../components/arch_navbar'
import Arch_Video from '../components/arch_video'
import Footer from '../components/arch_footer'

const Archamap = () => {
  return (
    <div>
      <Arch_navbar />
      <Arch_Video />
      <Footer />
    </div>
  )
}

export default Archamap