import React from 'react'
import Navbar from '../components/Navbar'
import Sociomap_1 from '../components/map1';

const Map1 = () => {
    return (
      <div>
        <Navbar />
        <Sociomap_1 />
      </div>
    )
  }
  
export default Map1
