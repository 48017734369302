import React from 'react'
import Navbar from '../components/Navbar'
import Register from '../components/RegisterPage'

const RegisterPage = () => {
   return (
     <div style={{backgroundColor:"white"}}>
         <Navbar />
         <Register />
     </div>
   )
}

export default RegisterPage