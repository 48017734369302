import React from 'react'
import Navbar from '../components/Navbar'
import Sociotranslate from '../components/sociotranslate'

 const Translate = () => {
   return (
     <div style={{backgroundColor:"white"}}>
         <Navbar />
         <Sociotranslate />
     </div>
   )
 }

export default Translate;