import React from 'react'
import Navbar from '../components/Navbar'
import LoginPage from '../components/LoginPage'

const Logins = () => {
   return (
     <div style={{backgroundColor:"white",height:"50vh"}}>
         <Navbar />
         <LoginPage />
     </div>
   )
}

export default Logins