import React from 'react'
import Navbar from '../components/Navbar'
import Sociomap_4 from '../components/map4';

const Map4 = () => {
    return (
      <div>
        <Navbar />
        <Sociomap_4 />
      </div>
    )
  }
  
export default Map4
