import React from 'react'
import Navbar from '../components/Navbar'
import Searchbar from '../components/Searchbar'

 const Explore = () => {
   return (
     <div style={{backgroundColor:"white"}}>
         <Navbar />
         <Searchbar />
     </div>
   )
 }


export default Explore;