import React from 'react'
import ArchNavbar from '../components/arch_navbar'
import Searchbar from '../components/Searchbar'

 const Archamapexplore = () => {
   return (
     <div style={{backgroundColor:"white"}}>
         <ArchNavbar />
         <Searchbar />
     </div>
   )
 }


export default Archamapexplore;