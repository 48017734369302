import React from 'react'
import Cat_navbar from '../components/cat_navbar'
import Cat_Video from '../components/cat_video'
import Cat_footer from '../components/cat_footer'

const Catmapper = () => {
  return (
    <div>
      <Cat_navbar />
      <Cat_Video />
      <Cat_footer />
    </div>
  )
}

export default Catmapper