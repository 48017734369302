import React from 'react'
import Navbar from '../components/Navbar'
import Admin from '../components/admin';

 const AdminPage = () => {
   return (
    <div style={{backgroundColor:"white",height:"100vh"}}>
    <Navbar />
    <Admin />
  </div>
   )
 }


export default AdminPage;