import React from 'react'
import Navbar from '../components/arch_navbar'
import UploadTranslat from '../components/uploadtranslate'

 const Archamap_UploadTranslate = () => {
   return (
     <div style={{backgroundColor:"white"}}>
         <Navbar />
         <UploadTranslat />
     </div>
   )
 }

export default Archamap_UploadTranslate;