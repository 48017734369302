import React from 'react'
import Navbar from '../components/arch_navbar'
import Sociotranslate from '../components/sociotranslate'

 const Archamap_Translate = () => {
   return (
     <div style={{backgroundColor:"white"}}>
         <Navbar />
         <Sociotranslate />
     </div>
   )
 }

export default Archamap_Translate;