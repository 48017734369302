import React from 'react'
import Navbar from '../components/Navbar'
import Sociomap_2 from '../components/map2';

const Map3 = () => {
    return (
      <div>
        <Navbar />
        <Sociomap_2 />
      </div>
    )
  }
  
export default Map3
