const map1 = `country,lat,long,count
Afghanistan,35.114630612244895,69.21674795918366,98
Albania,41.49885,19.977825,8
Algeria,30.901980000000002,4.289914285714286,70
Angola,-12.69030275862069,17.20370896551724,145
Antigua and Barb.,17.0683,-61.8219,2
Argentina,-32.991191752577315,-63.886009278350514,97
Armenia,40.05798,44.74212,10
Aruba,12.4849,-69.9399,2
Australia,-22.264481767394617,136.99789537836466,1969
Austria,47.85498,14.8642,10
Azerbaijan,40.83246923076923,47.604638461538464,26
Bahamas,25.491466666666668,-77.3304,3
Bahrain,26.14906666666667,50.55386666666667,3
Bangladesh,23.464266666666667,91.48519166666667,36
Barbados,13.1365,-59.5346,2
Belarus,54.11535,26.8019,4
Belgium,50.580619999999996,4.37696,10
Belize,16.31770909090909,-88.94461818181819,11
Benin,8.537920833333333,1.9864541666666666,96
Bhutan,27.413926530612247,90.59044081632653,49
Bolivia,-15.616181407035176,-65.24251708542714,199
Bosnia and Herz.,43.900533333333335,17.73453333333333,15
Botswana,-21.998767213114753,23.505344262295083,61
Brazil,-8.906808837772397,-55.825683656174334,826
Brunei,4.67216,114.6203,10
Bulgaria,43.0465,24.026799999999998,12
Burkina Faso,11.553566666666667,-3.1439975308641976,162
Burundi,-3.02946,29.91306,10
Cabo Verde,15.0586,-23.6148,2
Cambodia,12.724204081632653,105.19671020408163,49
Cameroon,6.762058395245171,11.79171500742942,673
Canada,54.32325688405797,-103.28926376811594,552
Cayman Is.,19.3333,-81.2167,2
Central African Rep.,5.756002857142858,19.67514,140
Chad,11.486382167832168,17.863567832167835,286
Chile,-41.00482686567164,-79.0326119402985,67
China,28.638151490825685,105.47707121559633,872
Colombia,3.809690202702703,-73.0514956081081,296
Comoros,-12.047199999999998,43.82396666666667,6
Congo,-1.6507615384615386,14.832200961538462,104
Cook Is.,-21.65,-158.658,5
Costa Rica,9.738794594594594,-83.78978108108109,37
Croatia,45.0318,15.992693333333333,15
Cuba,21.48016,-78.61266666666667,30
Cyprus,34.85673333333333,33.02503333333333,3
Cyprus U.N. Buffer Zone,35.1674,33.0943,2
Czechia,49.85615,15.576012500000001,8
Côte d'Ivoire,6.957958252427185,-5.44534854368932,206
Dem. Rep. Congo,-1.0862142857142858,23.745976031746032,630
Denmark,55.34491666666667,10.6352,12
Dominica,15.403416666666667,-61.3296,12
Dominican Rep.,18.92745,-70.57555,8
Ecuador,-0.7703013698630137,-78.24810136986301,73
Egypt,27.70335230769231,30.960984615384614,65
El Salvador,13.813815384615385,-88.76601538461539,13
Eq. Guinea,2.1965399999999997,9.51192,20
Eritrea,15.67203448275862,38.39218620689655,29
Estonia,58.644822222222224,25.296888888888887,9
Ethiopia,7.9226757377049175,37.20425344262295,305
Faeroe Is.,62.0732,-6.885,2
Falkland Is.,-51.6947,-57.8548,1
Fiji,-17.290904761904763,76.01156904761905,42
Finland,65.15017714285713,25.806325714285716,35
Fr. Polynesia,-15.813735483870968,-142.56193548387097,31
France,31.778993333333336,-7.603454666666667,75
Gabon,-0.996847619047619,12.076304761904762,84
Gambia,13.44868,-15.861759999999999,5
Georgia,42.249131428571424,43.34033428571429,35
Germany,51.08900727272727,10.121323636363638,55
Ghana,8.085623923444977,-0.8374444976076555,209
Greece,38.99605714285714,22.951678571428573,28
Greenland,69.59093947368422,-51.747731578947366,38
Grenada,12.0067,-61.6989,2
Guam,13.438,144.7447,2
Guatemala,14.958837647058823,-90.75452705882353,85
Guernsey,49.44025,-2.4688,2
Guinea,10.32592,-11.993504444444445,90
Guinea-Bissau,11.902021621621621,-15.857427027027025,37
Guyana,4.670684615384616,-59.05934615384615,39
Haiti,18.794018181818185,-72.37001818181818,11
Honduras,14.804523076923077,-86.9496076923077,26
Hong Kong,22.3913,114.122825,4
Hungary,47.11435,19.530083333333334,12
Iceland,64.36336,-20.07886,10
India,21.966117657342657,83.86481555944056,1144
Indonesia,-3.579719900187149,126.84845377417342,1603
Iran,33.538163507109005,52.54297630331753,211
Iraq,34.11095076923077,43.89604,65
Ireland,53.14413684210526,-8.313715789473685,19
Isle of Man,54.234725,-4.498025,4
Israel,31.588928125,34.948971875,32
Italy,42.30596310679611,12.637564077669904,103
Jamaica,18.108533333333334,-77.2628857142857,21
Japan,31.422067796610172,132.21882033898305,59
Jersey,49.2123,-2.1325,1
Jordan,31.405116666666668,36.18571666666667,6
Kazakhstan,46.5875,66.325,4
Kenya,-0.11937570850202431,36.58745263157895,247
Kiribati,-1.47,175.06,2
Kosovo,42.4935,21.276850000000003,4
Kuwait,29.403233333333333,47.71573333333333,6
Kyrgyzstan,42.25973333333334,75.76773333333334,6
Laos,18.239132592592593,104.20379555555556,135
Latvia,57.058290909090914,23.532436363636364,11
Lebanon,33.756816666666666,35.80363333333333,12
Lesotho,-29.451814285714285,27.884371428571427,7
Liberia,6.3858,-9.3225325,80
Libya,28.97361875,18.6450375,32
Lithuania,55.090833333333336,24.136983333333333,12
Luxembourg,49.6839,6.1493,2
Macao,22.177,113.5496,4
Madagascar,-20.990645161290324,46.68092258064516,62
Malawi,-11.826337931034482,33.89366896551724,29
Malaysia,3.910654785478548,110.33507821782179,303
Mali,14.512790476190476,-4.369570068027211,147
Malta,35.896159999999995,14.467279999999999,5
Mauritania,17.965555555555554,-12.4129,18
Mauritius,-20.214,57.5342,4
Mexico,19.494237079953653,-98.95083545770568,863
Micronesia,7.708176,150.077328,25
Moldova,47.071333333333335,28.796000000000003,3
Mongolia,47.91922631578947,103.16445263157895,19
Montenegro,43.09,18.99,1
Morocco,31.991155999999997,-6.485201333333333,75
Mozambique,-17.747993798449613,35.15602945736434,129
Myanmar,21.53448090909091,96.33312363636364,220
N. Mariana Is.,15.1674,145.7344,10
Namibia,-20.695564615384615,18.49718,65
Nauru,-0.52795,166.9245,8
Nepal,27.879367088607594,85.29169915611814,237
Netherlands,52.5692358974359,5.836764102564103,39
New Caledonia,-21.130820512820513,165.49730769230771,78
New Zealand,-39.22056923076923,121.78703076923077,13
Nicaragua,13.02217037037037,-84.77238518518519,27
Niger,15.593187096774194,8.1692,62
Nigeria,8.596913175932977,8.889750952018279,1313
Niue,-19.055,-169.86,4
North Korea,40.02333333333333,127.15666666666668,6
North Macedonia,41.75535,21.6966,4
Norway,62.776030769230765,12.752384615384615,13
Oman,20.812896296296298,55.96408148148148,27
Pakistan,30.890547857142856,70.49191071428572,140
Palau,7.4655555555555555,134.54444444444442,9
Palestine,32.16336,35.15822666666667,15
Panama,8.81175,-79.63788,40
Papua New Guinea,-5.98686554953179,145.96238334154756,2029
Paraguay,-22.927173913043475,-58.2229884057971,69
Peru,-8.627600694444444,-74.84527187500001,288
Philippines,12.802316856492027,122.300293166287,439
Pitcairn Is.,-24.3799,-128.3241,2
Poland,51.75163333333333,17.97084444444444,18
Portugal,39.621175,-8.028875000000001,12
Puerto Rico,18.262675,-66.49350625,32
Qatar,25.27,51.195,2
Romania,45.48124,25.888093333333334,15
Russia,54.97332167487685,79.93752955665025,406
Rwanda,-1.874425,29.928525,8
S. Sudan,6.56612,30.547120540540543,185
Saint Helena,-30.061866666666663,-10.084033333333332,3
Samoa,-13.817777777777778,-172.09055555555557,9
Saudi Arabia,25.353384375,42.68620625,64
Senegal,13.71215934065934,-15.29785054945055,91
Serbia,44.12631999999999,20.183632,25
Seychelles,-4.6231,55.4503,2
Sierra Leone,8.55152,-11.825676000000001,50
Singapore,1.3307,103.864,2
Sint Maarten,18.0471,-63.0785,2
Slovakia,48.296,18.4548,4
Slovenia,46.19748,15.2304,5
Solomon Is.,-9.182655844155844,160.32805194805195,154
Somalia,1.9937866666666666,43.591833333333334,30
Somaliland,9.071428571428571,47.30357142857143,14
South Africa,-28.142105405405406,27.22838558558559,111
South Korea,36.75303902439025,127.15021463414635,41
Spain,39.8379796875,-4.2093796875,64
Sri Lanka,7.874021212121211,80.73155757575758,33
St-Barthélemy,17.9036,-62.8316,1
St. Vin. and Gren.,13.2436,-61.1963,2
Sudan,12.851947272727271,30.357035454545457,220
Suriname,4.247821621621622,-55.49575135135135,37
Sweden,62.69050714285714,17.233864285714287,28
Switzerland,46.67692,8.637466666666667,15
Syria,34.886715625,38.15041875,32
São Tomé and Principe,0.583,6.832225,8
Taiwan,23.81555213675214,121.07215641025641,117
Tajikistan,38.154738888888886,70.8744,18
Tanzania,-5.947555632183908,35.18446758620689,435
Thailand,15.439166346153845,100.73283846153846,104
Timor-Leste,-8.783604347826087,125.84792826086958,46
Togo,8.339955555555555,0.9816074074074074,54
Tonga,-19.38570909090909,-175.21272727272728,11
Trinidad and Tobago,10.682614285714285,-61.29085714285714,7
Tunisia,34.724652380952385,10.064138095238096,21
Turkey,38.94998309859155,35.106092957746476,71
Turkmenistan,38.095375,60.5319,8
Turks and Caicos Is.,21.8078,-71.7451,2
Tuvalu,-7.48,178.67999999999998,3
U.S. Virgin Is.,18.3416,-64.8922,2
Uganda,1.3948584415584415,32.63179350649351,154
Ukraine,48.89680555555556,29.611205555555557,18
United Arab Emirates,24.48685714285714,54.86031428571429,7
United Kingdom,53.13940512820513,-2.4741794871794873,39
United States of America,40.66416537234043,-110.70504856382978,1880
Uruguay,-33.87738,-56.24696,10
Uzbekistan,40.80882857142857,63.97050476190476,21
Vanuatu,-16.190642241379308,167.72640215517242,232
Venezuela,6.530831638418079,-66.13655084745763,177
Vietnam,17.271320212765957,106.3822085106383,188
Wallis and Futuna Is.,-13.956233333333335,-177.4724,6
Yemen,15.152901666666667,47.35729,60
Zambia,-13.799917557251907,27.30492290076336,131
Zimbabwe,-18.818338775510206,29.791493877551023,49
eSwatini,-26.236666666666668,31.349999999999998,3`

export default map1
