import React, { useEffect, useState } from 'react';

const parseCsvData = (csvString) => {
    const lines = csvString.trim().split('\n');
    const headers = lines[0].split(',');
    return lines.slice(1).map(line => {
      const values = line.split(',');
      const entry = {};
      headers.forEach((header, index) => {
        entry[header] = values[index];
      });
      return entry;
    });
  };

let map1 = `CMID,long,lat,CMName,domain,group
SM259612,28.75,-31.32,!Gã!ne,LANGUAGE,LANGUAGE
SM254,17.5,-27,!Karas,ADM1,DISTRICT
SM241878,14.35,-15.3,!O!ung,LANGUAGE,LANGUAGE
SM254225,19.3,-18.56,!Xun,ETHNICITY,ETHNICITY
SM254225,17.81,-16.25,!Xun,ETHNICITY,ETHNICITY
SM254225,16.12,-14,!Xun,ETHNICITY,ETHNICITY
SM224808,52.2282,12.1959,'Abd Al-Kuri,DIALECT,LANGUAGE
SM225714,161.158,-9.207,'Are'are,LANGUAGE,LANGUAGE
SM225957,151.002,-10.0959,'Auhelawa,LANGUAGE,LANGUAGE
SM296757,38.3482,36.6022,'Ayn al-'Arab,ADM2,DISTRICT
SM253,-174.9297,-21.3632,'Eua,ADM1,DISTRICT
SM17982,-174.9216,-21.3696,'Eua Prope,ADM2,DISTRICT
SM17983,-174.9538,-21.3733,'Eua fo'ou,ADM2,DISTRICT
SM17984,-174.5511,-19.8259,'Uiha,ADM2,DISTRICT
SM4661,69.4053,35.224,'Unabah,ADM2,DISTRICT
SM285641,4.2864,52.0687,'s-Gravenhage,ADM2,DISTRICT
SM225523,22.9088,-19.0966,//Ani,LANGUAGE,LANGUAGE
SM231197,22.2003,-21.8555,//Gana,LANGUAGE,LANGUAGE
SM249193,30.4028,-26.3407,//Xegwi,LANGUAGE,LANGUAGE
SM251356,30.23,-26.28,//Xegwi,ETHNICITY,ETHNICITY
SM232024,21.9431,-22.2708,/Gwi,LANGUAGE,LANGUAGE
SM251357,23,-22.25,/Gwi,ETHNICITY,ETHNICITY
SM251357,23,-22.25,/Gwi,ETHNICITY,ETHNICITY
SM251357,23,-22.25,/Gwi,ETHNICITY,ETHNICITY
SM251357,23.39,-22.46,/Gwi,ETHNICITY,ETHNICITY
SM251357,23.39,-22.46,/Gwi,ETHNICITY,ETHNICITY
SM251357,23.39,-22.46,/Gwi,ETHNICITY,ETHNICITY
SM251357,23.2947,-21.4866,/Gwi,ETHNICITY,ETHNICITY
SM251357,23.2947,-21.4866,/Gwi,ETHNICITY,ETHNICITY
SM251357,23.2947,-21.4866,/Gwi,ETHNICITY,ETHNICITY
SM249180,20.1733,-31.7612,/Xam,LANGUAGE,LANGUAGE
SM12085,-78.136,18.211,12th Street,ADM2,DISTRICT
SM18323,-56.2176,-34.6954,18 De Mayo,ADM2,DISTRICT
SM14160,47.5116,-18.9058,1er Arrondissement,ADM2,DISTRICT
SM290147,28.5544,43.9194,23 August,ADM2,DISTRICT
SM273491,-80.4333,-1.2667,24 De Mayo,ADM2,DISTRICT
SM289639,-56.8013,-24.7726,25 de Diciembre,ADM2,DISTRICT
SM14161,47.5484,-18.9289,2e Arrondissement,ADM2,DISTRICT
SM15317,-56.0677,-26.4055,3 De Mayo,ADM2,DISTRICT
SM289679,-55.8081,-25.2126,3 de Febrero,ADM2,DISTRICT
SM14162,47.5289,-18.8964,3e Arrondissement,ADM2,DISTRICT
SM14163,47.5139,-18.9334,4e Arrondissement,ADM2,DISTRICT
SM14164,47.5393,-18.8801,5e Arrondissement,ADM2,DISTRICT
SM14165,47.4933,-18.8661,6e Arrondissement,ADM2,DISTRICT
SM274045,-8.4167,43.1667,A Coruña,ADM2,DISTRICT
SM304480,107.3065,16.2342,A Lưới,ADM2,DISTRICT
SM257,-171.9667,-13.8833,A'ana,ADM1,DISTRICT
SM225587,105.85,26.8,A'ou,LANGUAGE,LANGUAGE
SM296758,37.1838,36.4999,A'zaz,ADM2,DISTRICT
SM17894,32.5,13.5,AL Gultana/Ad Douiem,ADM2,DISTRICT
SM285546,6.7491,53.0045,Aa en Hunze,ADM2,DISTRICT
SM271736,9.3128,54.9898,Aabenraa (2007-),ADM2,DISTRICT
SM295783,46.5459,3.5484,Aadan,ADM2,DISTRICT
SM271708,9.95,57,Aalborg (2007-),ADM2,DISTRICT
SM285970,4.7653,52.2641,Aalsmeer,ADM2,DISTRICT
SM304690,-171.9241,-13.8478,Aana Alofi I,ADM2,DISTRICT
SM304691,-171.95,-13.8543,Aana Alofi II,ADM2,DISTRICT
SM304692,-171.9907,-13.8526,Aana Alofi III,ADM2,DISTRICT
SM268896,8.0524,47.3771,Aarau,ADM2,DISTRICT
SM258,8.1721,47.3956,Aargau,ADM1,DISTRICT
SM224779,36.5721,5.9503,Aari,LANGUAGE,LANGUAGE
SM224781,36.8648,-4.0068,Aasax,LANGUAGE,LANGUAGE
SM251360,-49.65,69.8,Aasiaat,ETHNICITY,ETHNICITY
SM260289,68.0636,32.8836,Ab Band,ADM2,DISTRICT
SM260098,62.9017,34.9251,Ab Kamari,ADM2,DISTRICT
SM295424,161.5361,-9.6105,Aba - Asimeuru,ADM2,DISTRICT
SM284632,7.3948,5.0939,Aba North,ADM2,DISTRICT
SM284641,7.35,5.1,Aba South,ADM2,DISTRICT
SM250708,34,24,Ababda,ETHNICITY,ETHNICITY
SM285361,13.3132,13.4316,Abadam,ADM2,DISTRICT
SM277529,48.5333,30.2167,Abadan,ADM2,DISTRICT
SM277706,52.5,31.25,Abadeh,ADM2,DISTRICT
SM224788,146.992,-9.0339,Abadi,LANGUAGE,LANGUAGE
SM268043,-49.4684,-16.7776,Abadia de Goiás,ADM2,DISTRICT
SM263224,-47.47,-18.3635,Abadia dos Dourados,ADM2,DISTRICT
SM268154,-48.6268,-16.2263,Abadiânia,ADM2,DISTRICT
SM264078,-48.9439,-1.7349,Abaetetuba,ADM2,DISTRICT
SM263335,-45.4305,-19.111,Abaeté,ADM2,DISTRICT
SM224789,145.665,-6.1203,Abaga,LANGUAGE,LANGUAGE
SM224791,118.306,5.5539,Abai Sungai,LANGUAGE,LANGUAGE
SM224792,116.1625,3.5242,Abai Tubu-Abai Sembuak,LANGUAGE,LANGUAGE
SM13316,172.9437,1.8529,Abaiang,ADM2,DISTRICT
SM267780,-39.0613,-7.3364,Abaiara,ADM2,DISTRICT
SM284738,6.8182,8.8921,Abaji,ADM2,DISTRICT
SM285085,7.7874,5.021,Abak,ADM2,DISTRICT
SM284674,8.2113,6.2565,Abakalik,ADM2,DISTRICT
SM293202,91.4333,53.7167,Abakan,ADM2,DISTRICT
SM16295,15.5211,-1.3327,Abala,ADM2,DISTRICT
SM15210,6.1697,15.4162,Abalak,ADM2,DISTRICT
SM282932,-89.6567,20.6649,Abalá,ADM2,DISTRICT
SM298588,34.0038,41.9708,Abana,ADM2,DISTRICT
SM286856,-72.9167,-13.6667,Abancay,ADM2,DISTRICT
SM274556,10.984,-0.196,Abanga-Bigné,ADM2,DISTRICT
SM270921,-84.9908,10.2425,Abangares,ADM2,DISTRICT
SM293411,96,56.8333,Abanskiy rayon,ADM2,DISTRICT
SM224797,8.6306,6.3062,Abanyom,LANGUAGE,LANGUAGE
SM267475,-39.2706,-8.8096,Abaré,ADM2,DISTRICT
SM274828,42.2,42.2,Abasha,ADM2,DISTRICT
SM278146,144.2105,43.9857,Abashiri Shi,ADM2,DISTRICT
SM12860,144.0981,43.6893,Abashiri-gun,ADM2,DISTRICT
SM282637,-98.3069,24.1046,Abasolo,ADM2,DISTRICT
SM282151,-100.4052,25.9478,Abasolo,ADM2,DISTRICT
SM281534,-101.538,20.5058,Abasolo,ADM2,DISTRICT
SM283277,-101.3129,27.1482,Abasolo,ADM2,DISTRICT
SM264443,-50.3218,-23.299,Abatiá,ADM2,DISTRICT
SM224801,141.324,-3.9722,Abau,LANGUAGE,LANGUAGE
SM288603,148.9167,-10.0833,Abau,ADM2,DISTRICT
SM251362,141.3,-4.24,Abau,ETHNICITY,ETHNICITY
SM13383,22.515,57.063,Abavas pagasts,ADM2,DISTRICT
SM230967,139.213,-3.0458,Abawiri,LANGUAGE,LANGUAGE
SM13150,72.8833,49.6333,"Abay Qalasy (excl. Shakhtinsk,Tel'man,Michurin,Temirtau)",ADM2,DISTRICT
SM251363,22.81,32.34,Abaydat,ETHNICITY,ETHNICITY
SM279948,79.25,49,Abayskiy,ADM2,DISTRICT
SM224804,42,44.25,Abaza,LANGUAGE,LANGUAGE
SM8035,42.2,44.15,Abaza (Abazins),ETHNICITY,ETHNICITY
SM289698,-55.7089,-25.8826,Abaí,ADM2,DISTRICT
SM267364,-41.6976,-13.3213,Abaíra,ADM2,DISTRICT
SM276231,21.2831,48.3995,Abaúj–Hegyközi,ADM2,DISTRICT
SM10220,15.2176,5.2253,Abba,ADM2,DISTRICT
SM302432,-82.4587,34.2226,Abbeville,ADM2,DISTRICT
SM375417,73.2604,34.095,Abbottabad,ADM2:ADM3,DISTRICT
SM277501,47.5,32.8833,Abdanan,ADM2,DISTRICT
SM266139,-51.0402,-27.5898,Abdon Batista,ADM2,DISTRICT
SM14202,-96.6042,17.4325,Abejones,ADM2,DISTRICT
SM270147,-75.4438,5.8183,Abejorral,ADM2,DISTRICT
SM264134,-48.4228,-4.9438,Abel Figueiredo,ADM2,DISTRICT
SM262717,-68.015,-13.1799,Abel Iturralde,ADM2,DISTRICT
SM251365,142.28,-3.33,Abelam,ETHNICITY,ETHNICITY
SM251365,142.28,-3.33,Abelam,ETHNICITY,ETHNICITY
SM251365,143.09,-3.79,Abelam,ETHNICITY,ETHNICITY
SM251365,143.09,-3.79,Abelam,ETHNICITY,ETHNICITY
SM266250,-52.2232,-26.5983,Abelardo Luz,ADM2,DISTRICT
SM13384,25.9493,56.4329,Abelu pagasts,ADM2,DISTRICT
SM13317,173.8667,0.4,Abemama,ADM2,DISTRICT
SM251366,-69.69,44.19,Abenaki,ETHNICITY,ETHNICITY
SM251366,-69.69,44.19,Abenaki,ETHNICITY,ETHNICITY
SM251366,-70,46,Abenaki,ETHNICITY,ETHNICITY
SM251366,-70,46,Abenaki,ETHNICITY,ETHNICITY
SM224815,120.2,15.4131,Abenlen Ayta,LANGUAGE,LANGUAGE
SM285047,3.3546,7.1543,Abeokuta South,ADM2,DISTRICT
SM285058,3.1685,7.2308,AbeokutaNorth,ADM2,DISTRICT
SM274713,-2.1667,57.1667,Aberdeen,ADM1:ADM2,DISTRICT
SM12086,-77.6793,18.2051,Aberdeen,ADM2,DISTRICT
SM274724,-2.6667,57.1667,Aberdeenshire,ADM1:ADM2,DISTRICT
SM283353,2.2003,19.4888,Abeïbara,ADM2,DISTRICT
SM17711,42.5333,18.0833,Abha,ADM2,DISTRICT
SM11743,49.05,36.3,Abhar,ADM2,DISTRICT
SM285389,8.038,5.9066,Abi,ADM2,DISTRICT
SM259,7.5,5.4167,Abia (1996-),ADM1,DISTRICT
SM251368,46.04,15.73,Abida,ETHNICITY,ETHNICITY
SM269463,-3.9884,5.342,Abidjan,ADM2,DISTRICT
SM260,-3.9992,5.3629,Abidjan Department (1998-),ADM1,DISTRICT
SM224817,-4.5842,5.6566,Abidji,LANGUAGE,LANGUAGE
SM279346,140.0651,35.8695,Abiko,ADM2,DISTRICT
SM224822,138.891,-2.9228,Abinomn,LANGUAGE,LANGUAGE
SM16826,38.2789,44.834,Abinskiy Rayon,ADM2,DISTRICT
SM224823,-61,-29,Abipon,LANGUAGE,LANGUAGE
SM251369,-61.67,-30.36,Abipon,ETHNICITY,ETHNICITY
SM251369,-61.67,-30.36,Abipon,ETHNICITY,ETHNICITY
SM251369,-61.67,-30.36,Abipon,ETHNICITY,ETHNICITY
SM251369,-61,-29,Abipon,ETHNICITY,ETHNICITY
SM251369,-61,-29,Abipon,ETHNICITY,ETHNICITY
SM251369,-61,-29,Abipon,ETHNICITY,ETHNICITY
SM251369,-59.5,-28,Abipon,ETHNICITY,ETHNICITY
SM251369,-59.5,-28,Abipon,ETHNICITY,ETHNICITY
SM251369,-59.5,-28,Abipon,ETHNICITY,ETHNICITY
SM10205,-77.7828,48.1002,Abitibi-Temiscamingue,ADM2,DISTRICT
SM251370,41.9,43.01,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41.9,43.01,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41.9,43.01,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41.9,43.01,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41.9,43.01,Abkhasian,ETHNICITY,ETHNICITY
SM251370,40.7915,43.125,Abkhasian,ETHNICITY,ETHNICITY
SM251370,40.7915,43.125,Abkhasian,ETHNICITY,ETHNICITY
SM251370,40.7915,43.125,Abkhasian,ETHNICITY,ETHNICITY
SM251370,40.7915,43.125,Abkhasian,ETHNICITY,ETHNICITY
SM251370,40.7915,43.125,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41,43,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41,43,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41,43,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41,43,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41,43,Abkhasian,ETHNICITY,ETHNICITY
SM251370,40.77,43.13,Abkhasian,ETHNICITY,ETHNICITY
SM251370,40.77,43.13,Abkhasian,ETHNICITY,ETHNICITY
SM251370,40.77,43.13,Abkhasian,ETHNICITY,ETHNICITY
SM251370,40.77,43.13,Abkhasian,ETHNICITY,ETHNICITY
SM251370,40.77,43.13,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41.0153,43.0033,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41.0153,43.0033,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41.0153,43.0033,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41.0153,43.0033,Abkhasian,ETHNICITY,ETHNICITY
SM251370,41.0153,43.0033,Abkhasian,ETHNICITY,ETHNICITY
SM224828,41.1591,43.0562,Abkhaz,LANGUAGE,LANGUAGE
SM261,41.005,43.0005,Abkhazia,ADM1,DISTRICT
SM284755,7.2518,5.4255,Aboh-Mba,ADM2,DISTRICT
SM224831,142.842,-8.4245,Abom,LANGUAGE,LANGUAGE
SM262123,1.973,7.188,Abomey,ADM2,DISTRICT
SM262141,2.325,6.508,Abomey-Calavi,ADM2,DISTRICT
SM224832,10.8769,6.9062,Abon,LANGUAGE,LANGUAGE
SM296813,19.5928,11.5656,Aboudeïa,ADM2,DISTRICT
SM10257,20.6498,13.5475,Abougoudam,ADM2,DISTRICT
SM12087,-77.3433,18.2509,Aboukir,ADM2,DISTRICT
SM250007,-3.58,5.27,Aboure,ETHNICITY,ETHNICITY
SM12088,-76.8759,18.1143,Above Rocks,ADM2,DISTRICT
SM262,120.75,17.5833,Abra,ADM1:ADM2,DISTRICT
SM15394,-66.849,18.4223,Abra Honda Barrio,ADM2,DISTRICT
SM292396,22.4202,47.3244,Abram,ADM2,DISTRICT
SM292407,22.2561,47.3335,Abramut,ADM2,DISTRICT
SM289318,-8.1753,39.4716,Abrantes,ADM2,DISTRICT
SM15395,-66.3172,18.3571,Abras Barrio,ADM2,DISTRICT
SM263446,-42.4391,-20.2727,Abre Campo,ADM2,DISTRICT
SM270361,-73.2,8,Abrego,ADM2,DISTRICT
SM264844,-35.0817,-7.8687,Abreu e Lima,ADM2,DISTRICT
SM267151,-49.3173,-9.4367,Abreulândia,ADM2,DISTRICT
SM271078,-80.7147,22.1923,Abreus,ADM2,DISTRICT
SM270183,-76.0888,6.6347,Abriaquí,ADM2,DISTRICT
SM251371,-6.33,5.12,Abriwi,ETHNICITY,ETHNICITY
SM224837,-2.5029,7.7209,Abron,LANGUAGE,LANGUAGE
SM250006,-2.15,7.53,Abron,ETHNICITY,ETHNICITY
SM250006,-2.15,7.53,Abron,ETHNICITY,ETHNICITY
SM250006,-2,7.5,Abron,ETHNICITY,ETHNICITY
SM250006,-2,7.5,Abron,ETHNICITY,ETHNICITY
SM289775,23.0613,46.2741,Abrud,ADM2,DISTRICT
SM263,13.75,42.25,Abruzzo,ADM1,DISTRICT
SM304833,43.0485,16.0215,Abs,ADM2,DISTRICT
SM224853,144.474,-4.2403,Abu,LANGUAGE,LANGUAGE
SM279576,131.5737,34.5557,Abu,ADM2,DISTRICT
SM260504,54.433,24.4136,Abu Dhabi,ADM2,DISTRICT
SM265,54,23.6667,Abu Dhabi,ADM1,DISTRICT
SM305759,44.0692,33.2919,Abu Ghraib,ADM2,DISTRICT
SM305759,44.0692,33.2919,Abu Ghraib,ADM2,DISTRICT
SM305759,44.0743,33.2814,Abu Ghraib,ADM2,DISTRICT
SM305759,44.0743,33.2814,Abu Ghraib,ADM2,DISTRICT
SM273754,31.6856,30.5265,Abu Hammad,ADM2,DISTRICT
SM17895,33.8931,12.6593,Abu Hujar Locality,ADM2,DISTRICT
SM273898,30.3289,31.111,Abu Hummus,ADM2,DISTRICT
SM273755,31.6977,30.7298,Abu Kabir,ADM2,DISTRICT
SM296775,40.5197,34.5408,Abu Kamal,ADM2,DISTRICT
SM273659,30.7766,27.9218,Abu Qurqas,ADM2,DISTRICT
SM273761,32.5833,22.7833,Abu Simbel,ADM2,DISTRICT
SM273777,31.2795,27.0255,Abu Tij,ADM2,DISTRICT
SM273847,32.0858,26.1193,Abu Tisht,ADM2,DISTRICT
SM17712,42.8667,16.95,Abu Arish,ADM2,DISTRICT
SM305816,48.1,30.25,Abu al Khasib,ADM2,DISTRICT
SM273889,30.1144,30.781,Abu al-Matamir,ADM2,DISTRICT
SM224842,142.917,-3.4616,Abu' Arapesh,LANGUAGE,LANGUAGE
SM224841,6.6149,4.8306,Abua,LANGUAGE,LANGUAGE
SM285196,6.5674,4.8298,Abua/Odu,ADM2,DISTRICT
SM224843,124.588,-8.3106,Abui,LANGUAGE,LANGUAGE
SM284739,7.3783,9,AbujaMun,ADM2,DISTRICT
SM251373,30.22,5.49,Abukaya,ETHNICITY,ETHNICITY
SM224846,132.416,-0.5707,Abun,LANGUAGE,LANGUAGE
SM251374,104.8,-4.45,Abung Komering,ETHNICITY,ETHNICITY
SM274888,-1.1667,5.3333,Abura-Asebu-Kwamankese,ADM2,DISTRICT
SM224850,-3.6412,5.2153,Abure,LANGUAGE,LANGUAGE
SM224851,6.5063,4.623,Abureni,LANGUAGE,LANGUAGE
SM251376,-75.15,7.36,Aburra,ETHNICITY,ETHNICITY
SM12861,140.8115,42.7968,Abuta-gun,ADM2,DISTRICT
SM266,46.126,13.6291,Abyan,ADM1,DISTRICT
SM11744,50.35,36.1,Abyek,ADM2,DISTRICT
SM13151,78,49.3333,Abyraly Audany,ADM2,DISTRICT
SM294303,146,68.3333,Abyyskiy rayon,ADM2,DISTRICT
SM294234,58.5,53.4167,Abzelilovskiy rayon,ADM2,DISTRICT
SM224810,-4.385,5.5968,Abé,LANGUAGE,LANGUAGE
SM261880,49.3333,40.4167,Abşeron,ADM1:ADM2,DISTRICT
SM283102,-92.6817,15.4141,Acacoyagua,ADM2,DISTRICT
SM270271,-73.7802,4.0093,Acacías,ADM2,DISTRICT
SM301108,-92.412,30.2905,Acadia,ADM2,DISTRICT
SM323866,-110.2155,51.0957,Acadia No. 34,ADM2:ADM3,DISTRICT
SM289618,-57.0766,-25.8893,Acahay,ADM2,DISTRICT
SM263557,-43.1008,-20.4036,Acaiaca,ADM2,DISTRICT
SM282729,-97.0333,19.5682,Acajete,ADM2,DISTRICT
SM282234,-97.9473,19.1028,Acajete,ADM2,DISTRICT
SM267586,-38.0359,-11.6068,Acajutiba,ADM2,DISTRICT
SM295558,-89.8362,13.6376,Acajutla,ADM2,DISTRICT
SM283123,-92.8177,16.5539,Acala,ADM2,DISTRICT
SM281864,-99.8791,19.9715,Acambay,ADM2,DISTRICT
SM282950,-89.4534,20.8422,Acanceh,ADM2,DISTRICT
SM269912,-77.2928,8.4247,Acandí,ADM2,DISTRICT
SM283134,-92.6833,15.2833,Acapetahua,ADM2,DISTRICT
SM282131,-105.3305,22.4815,Acaponeta,ADM2,DISTRICT
SM281580,-99.7449,16.9266,Acapulco de Juárez,ADM2,DISTRICT
SM267876,-38.6916,-4.2057,Acarapé,ADM2,DISTRICT
SM267887,-40.086,-2.9542,Acaraú,ADM2,DISTRICT
SM265438,-36.6391,-6.3855,Acari,ADM2,DISTRICT
SM264145,-48.4799,-2.1036,Acará,ADM2,DISTRICT
SM291647,22.7411,47.5314,Acas,ADM2,DISTRICT
SM291118,24.6481,46.4542,Acatari,ADM2,DISTRICT
SM275389,-90.9517,14.5516,Acatenango,ADM2,DISTRICT
SM282345,-97.2271,20.1132,Acateno,ADM2,DISTRICT
SM14203,-98.976,17.1748,Acatepec,ADM2,DISTRICT
SM224858,-99.0602,17.1034,Acatepec Me'phaa,LANGUAGE,LANGUAGE
SM281739,-102.9152,20.7642,Acatic,ADM2,DISTRICT
SM14204,-96.5282,18.4836,Acatlan de Perez Figueroa,ADM2,DISTRICT
SM282840,-96.8437,19.6965,Acatlán,ADM2,DISTRICT
SM282370,-98.0768,18.2237,Acatlán,ADM2,DISTRICT
SM281655,-98.4432,20.2086,Acatlán,ADM2,DISTRICT
SM281776,-103.6014,20.4241,Acatlán de Juárez,ADM2,DISTRICT
SM282381,-97.7678,19.0094,Acatzingo,ADM2,DISTRICT
SM265026,-40.9128,-8.3155,Acauã,ADM2,DISTRICT
SM251377,-106.21,24.77,Acaxee,ETHNICITY,ETHNICITY
SM281666,-98.1897,20.1667,Acaxochitlán,ADM2,DISTRICT
SM282855,-95.0036,18.022,Acayucan,ADM2,DISTRICT
SM302936,-75.7566,37.7649,Accomack,ADM2,DISTRICT
SM12089,-77.7482,18.2299,Accompong,ADM2,DISTRICT
SM11356,-0.186,5.586,Accra (2012-),ADM2,DISTRICT
SM18324,-54.3607,-31.8705,Acegua,ADM2,DISTRICT
SM9867,-54.1072,-31.646,Acegua Municipality,ADM2,DISTRICT
SM267,97,4,Aceh,ADM1,DISTRICT
SM251379,95.96,4.87,Aceh,ETHNICITY,ETHNICITY
SM276291,96.1667,4.45,Aceh Barat,ADM2,DISTRICT
SM276280,96.8833,3.8333,Aceh Barat Daya,ADM2,DISTRICT
SM276296,95.5167,5.3833,Aceh Besar,ADM2,DISTRICT
SM276297,95.64,4.86,Aceh Jaya,ADM2,DISTRICT
SM276298,97.4167,3.1667,Aceh Selatan,ADM2,DISTRICT
SM276299,97.9167,2.4167,Aceh Singkil,ADM2,DISTRICT
SM276300,97.9667,4.25,Aceh Tamiang,ADM2,DISTRICT
SM276301,96.855,4.51,Aceh Tengah,ADM2,DISTRICT
SM276302,97.7,3.3667,Aceh Tenggara,ADM2,DISTRICT
SM276281,97.6333,4.6333,Aceh Timur,ADM2,DISTRICT
SM276282,97.14,4.97,Aceh Utara,ADM2,DISTRICT
SM224871,96.6032,3.9076,Acehnese,LANGUAGE,LANGUAGE
SM15396,-67.0676,18.4372,Aceitunas Barrio,ADM2,DISTRICT
SM303635,-66.3333,10.25,Acevedo,ADM2,DISTRICT
SM270080,-76.0061,1.7047,Acevedo,ADM2,DISTRICT
SM224862,-72.2005,4.3865,Achagua,LANGUAGE,LANGUAGE
SM251380,-73.19,3.71,Achagua,ETHNICITY,ETHNICITY
SM251380,-72.17,7.26,Achagua,ETHNICITY,ETHNICITY
SM251380,-72.91,5.21,Achagua,ETHNICITY,ETHNICITY
SM251380,-69.02,6.95,Achagua,ETHNICITY,ETHNICITY
SM303816,-68.4167,7.25,Achaguas,ADM2,DISTRICT
SM224864,102.446,24.152,Ache,LANGUAGE,LANGUAGE
SM251384,-55.7,-25.26,Ache,ETHNICITY,ETHNICITY
SM251384,-55.7,-25.26,Ache,ETHNICITY,ETHNICITY
SM251384,-55.38,-25.7,Ache,ETHNICITY,ETHNICITY
SM251384,-55.38,-25.7,Ache,ETHNICITY,ETHNICITY
SM10548,32.4807,34.7305,Acheleia,ADM2,DISTRICT
SM10549,33.8576,35.1052,Acheritou,ADM2,DISTRICT
SM224865,30.3187,10.8827,Acheron,LANGUAGE,LANGUAGE
SM375332,81.2942,29.0678,Achham,ADM2:ADM3,DISTRICT
SM224870,-90.3513,15.073,Achi,LANGUAGE,LANGUAGE
SM10373,-74.4776,8.6258,Achi (1997-),ADM2,DISTRICT
SM260115,70.6613,34.0569,Achin,ADM2,DISTRICT
SM293422,90.5,56.25,Achinskiy rayon,ADM2,DISTRICT
SM15397,-66.2473,18.3133,Achiote Barrio,ADM2,DISTRICT
SM292722,45.25,43.0833,Achkhoy-Martanovskiy rayon,ADM2,DISTRICT
SM10550,33.7901,35.0475,Achna,ADM2,DISTRICT
SM250008,33.27,3.77,Acholi,ETHNICITY,ETHNICITY
SM250008,33.27,3.77,Acholi,ETHNICITY,ETHNICITY
SM250008,32,3,Acholi,ETHNICITY,ETHNICITY
SM250008,32,3,Acholi,ETHNICITY,ETHNICITY
SM251387,-119.43,41.62,Achomawi,ETHNICITY,ETHNICITY
SM251387,-119.43,41.62,Achomawi,ETHNICITY,ETHNICITY
SM251387,-119.43,41.62,Achomawi,ETHNICITY,ETHNICITY
SM251387,-121,41,Achomawi,ETHNICITY,ETHNICITY
SM251387,-121,41,Achomawi,ETHNICITY,ETHNICITY
SM251387,-121,41,Achomawi,ETHNICITY,ETHNICITY
SM251387,-121.19,41.32,Achomawi,ETHNICITY,ETHNICITY
SM251387,-121.19,41.32,Achomawi,ETHNICITY,ETHNICITY
SM251387,-121.19,41.32,Achomawi,ETHNICITY,ETHNICITY
SM251388,-120.5,41.4,Achomawi (East),ETHNICITY,ETHNICITY
SM251389,-121.4,41.2,Achomawi (West),ETHNICITY,ETHNICITY
SM224873,6.4752,52.021,Achterhoeks,DIALECT,LANGUAGE
SM285727,6.1378,53.2117,Achtkarspelen,ADM2,DISTRICT
SM285415,-86.591,13.062,Achuapa,ADM2,DISTRICT
SM224875,-77.2641,-2.8265,Achuar-Shiwiar,LANGUAGE,LANGUAGE
SM251390,-75.75,-2.95,Achuara,ETHNICITY,ETHNICITY
SM224874,-121.191,41.0979,Achumawi,LANGUAGE,LANGUAGE
SM224866,-56.4697,-25.5865,Aché,LANGUAGE,LANGUAGE
SM268,-73.9642,22.4317,Acklins,ADM1,DISTRICT
SM323877,-113.5093,51.4962,Acme,ADM2:ADM3,DISTRICT
SM286909,-74.6667,-12.75,Acobamba,ADM2,DISTRICT
SM224879,32.5147,3.5774,Acoli,LANGUAGE,LANGUAGE
SM281897,-98.9172,19.6391,Acolman,ADM2,DISTRICT
SM251391,-107.47,34.89,Acoma,ETHNICITY,ETHNICITY
SM251391,-107.47,34.89,Acoma,ETHNICITY,ETHNICITY
SM251391,-107.47,34.89,Acoma,ETHNICITY,ETHNICITY
SM251391,-108,35,Acoma,ETHNICITY,ETHNICITY
SM251391,-108,35,Acoma,ETHNICITY,ETHNICITY
SM251391,-108,35,Acoma,ETHNICITY,ETHNICITY
SM251391,-107.5,35,Acoma,ETHNICITY,ETHNICITY
SM251391,-107.5,35,Acoma,ETHNICITY,ETHNICITY
SM251391,-107.5,35,Acoma,ETHNICITY,ETHNICITY
SM286896,-71.6667,-13.9167,Acomayo,ADM2,DISTRICT
SM282550,-110.2203,29.794,Aconchi,ADM2,DISTRICT
SM275138,10.9444,1.2965,Aconibe,ADM2,DISTRICT
SM267898,-39.5176,-6.1131,Acopiara,ADM2,DISTRICT
SM263098,-56.3043,-15.1734,Acorizal,ADM2,DISTRICT
SM303563,-68.75,11.1,Acosta,ADM2,DISTRICT
SM303656,-63.75,10.1,Acosta,ADM2,DISTRICT
SM270959,-84.236,9.7449,Acosta,ADM2,DISTRICT
SM269,45.0581,-12.7221,Acoua,ADM1,DISTRICT
SM285521,-85.1283,11.8691,Acoyapa,ADM2,DISTRICT
SM270,12.4167,43.95,Acquaviva,ADM1,DISTRICT
SM271,-70,-9,Acre,ADM1,DISTRICT
SM262781,-66.8439,-9.9805,Acrelândia,ADM2,DISTRICT
SM268208,-50.267,-17.4361,Acreúna,ADM2,DISTRICT
SM224881,-40.2145,-12.594,Acroá,LANGUAGE,LANGUAGE
SM282392,-98.71,18.7675,Acteopan,ADM2,DISTRICT
SM17165,-60.9777,14.0194,Active Hill,ADM2,DISTRICT
SM282866,-96.5145,19.5321,Actopan,ADM2,DISTRICT
SM281677,-98.9153,20.2845,Actopan,ADM2,DISTRICT
SM14205,-98.1941,19.2199,Acuamanala de Miguel Hidalgo (1995-),ADM2,DISTRICT
SM251392,-81.58,29.09,Acuera,ETHNICITY,ETHNICITY
SM281985,-101.3292,19.4718,Acuitzio,ADM2,DISTRICT
SM282877,-95.7878,18.5295,Acula,ADM2,DISTRICT
SM281908,-99.8249,20.1109,Aculco,ADM2,DISTRICT
SM282888,-97.2771,18.7233,Acultzingo,ADM2,DISTRICT
SM275120,10.546,1.0928,Acurenam,ADM2,DISTRICT
SM283288,-101.4051,29.3476,Acuña,ADM2,DISTRICT
SM281545,-100.7414,20.0583,Acámbaro,ADM2,DISTRICT
SM298785,34.5173,38.561,Acıgöl,ADM2,DISTRICT
SM298300,29.3615,37.4354,Acıpayam,ADM2,DISTRICT
SM17713,43.15,17.4167,Ad Da'ir,ADM2,DISTRICT
SM305030,43.1935,15.2232,Ad Dahi,ADM1:ADM2,DISTRICT
SM272,57.3333,22.5,Ad Dakhliyah,ADM1,DISTRICT
SM295182,33.325,17.558,Ad Damer,ADM2,DISTRICT
SM17714,49.8667,26.4167,Ad Dammam,ADM2,DISTRICT
SM273,31.6,31.1,Ad Daqahliyah,ADM1,DISTRICT
SM17715,42.2,17.75,Ad Darb,ADM2,DISTRICT
SM17716,44.1667,24.6667,Ad Dawadimi,ADM2,DISTRICT
SM274,51.5,25.3,Ad Dawhah,ADM1,DISTRICT
SM305021,44.7145,13.714,Ad Dhale'e,ADM2,DISTRICT
SM17717,46.3667,24.8833,Ad Diriyah,ADM2,DISTRICT
SM304803,50.0159,15.0949,Ad Dis,ADM2,DISTRICT
SM305827,44.8385,31.9079,Ad Diwaniyah,ADM2,DISTRICT
SM305041,43.0718,14.6046,Ad Durayhimi,ADM2,DISTRICT
SM273899,30.5024,30.8312,Ad-Dilinat,ADM2,DISTRICT
SM300544,-116.2411,43.4511,Ada,ADM2,DISTRICT
SM11357,0.5567,5.8492,Ada East,ADM2,DISTRICT
SM11358,0.421,5.8891,Ada West,ADM2,DISTRICT
SM283811,105.7348,46.3834,Adaatsag,ADM2,DISTRICT
SM224884,125.576,-8.2267,Adabe,LANGUAGE,LANGUAGE
SM279426,139.7951,35.7788,Adachi,ADM2,DISTRICT
SM224886,-92.0059,31.6314,Adai,LANGUAGE,LANGUAGE
SM302172,-94.6587,35.8839,Adair,ADM2,DISTRICT
SM301508,-92.6007,40.1906,Adair,ADM2,DISTRICT
SM300988,-85.2807,37.1042,Adair,ADM2,DISTRICT
SM300784,-94.4709,41.3308,Adair,ADM2,DISTRICT
SM4385,0.5459,6.4288,Adaklu,ADM2,DISTRICT
SM298201,40.4825,39.2287,Adaklı,ADM2,DISTRICT
SM298492,29.1206,40.8691,Adalar,ADM2,DISTRICT
SM266432,-51.0667,-21.5914,Adamantina,ADM2,DISTRICT
SM275,13.25,6.8333,Adamaoua,ADM1,DISTRICT
SM276,12.5,9.3333,Adamawa,ADM1,DISTRICT
SM251028,12.47,7.06,"Adamawa Fulani (Cameroon, Chad, Sudan)",ETHNICITY,ETHNICITY
SM251028,12.47,7.06,"Adamawa Fulani (Cameroon, Chad, Sudan)",ETHNICITY,ETHNICITY
SM251028,12.47,7.06,"Adamawa Fulani (Cameroon, Chad, Sudan)",ETHNICITY,ETHNICITY
SM251028,13,8,"Adamawa Fulani (Cameroon, Chad, Sudan)",ETHNICITY,ETHNICITY
SM251028,13,8,"Adamawa Fulani (Cameroon, Chad, Sudan)",ETHNICITY,ETHNICITY
SM251028,13,8,"Adamawa Fulani (Cameroon, Chad, Sudan)",ETHNICITY,ETHNICITY
SM251028,13.5,9,"Adamawa Fulani (Cameroon, Chad, Sudan)",ETHNICITY,ETHNICITY
SM251028,13.5,9,"Adamawa Fulani (Cameroon, Chad, Sudan)",ETHNICITY,ETHNICITY
SM251028,13.5,9,"Adamawa Fulani (Cameroon, Chad, Sudan)",ETHNICITY,ETHNICITY
SM224888,13.0773,8.1403,Adamawa Fulfulde,LANGUAGE,LANGUAGE
SM290158,27.9499,44.1145,Adamclisi,ADM2,DISTRICT
SM224887,-0.151,5.834,Adamorobe Sign Language,LANGUAGE,LANGUAGE
SM303221,-89.7706,43.9696,Adams,ADM2,DISTRICT
SM303069,-118.5605,46.9834,Adams,ADM2,DISTRICT
SM302285,-77.2179,39.8715,Adams,ADM2,DISTRICT
SM302083,-83.4722,38.8455,Adams,ADM2,DISTRICT
SM302030,-102.5285,46.0968,Adams,ADM2,DISTRICT
SM301426,-91.3535,31.4829,Adams,ADM2,DISTRICT
SM301678,-98.5012,40.5245,Adams,ADM2,DISTRICT
SM300691,-84.9367,40.7457,Adams,ADM2,DISTRICT
SM300795,-94.6992,41.029,Adams,ADM2,DISTRICT
SM300588,-91.1885,39.9879,Adams,ADM2,DISTRICT
SM303318,-104.3379,39.8736,Adams,ADM2,DISTRICT
SM300555,-116.4539,44.8897,Adams,ADM2,DISTRICT
SM291129,24.2032,46.3159,Adamus,ADM2,DISTRICT
SM277,35.2881,36.985,Adana (1997-),ADM1,DISTRICT
SM290893,26.4301,44.7669,Adancata,ADM2,DISTRICT
SM224896,124.448,-8.1896,Adang,LANGUAGE,LANGUAGE
SM224893,0.7272,6.5055,Adangbe,LANGUAGE,LANGUAGE
SM224892,0.1324,5.9437,Adangme,LANGUAGE,LANGUAGE
SM250010,-0.27,5.71,Adangme,ETHNICITY,ETHNICITY
SM250010,-0.27,5.71,Adangme,ETHNICITY,ETHNICITY
SM250010,0,6,Adangme,ETHNICITY,ETHNICITY
SM250010,0,6,Adangme,ETHNICITY,ETHNICITY
SM274836,-1.5167,6.2833,Adansi North,ADM2,DISTRICT
SM274847,-1.4,6.0667,Adansi South,ADM2,DISTRICT
SM224898,90.3369,27.141,Adap,LANGUAGE,LANGUAGE
SM18015,30.3999,40.7834,Adapazari,ADM2,DISTRICT
SM224900,120.905,17.8187,Adasen,LANGUAGE,LANGUAGE
SM284952,6.4337,7.6414,Adavi,ADM2,DISTRICT
SM3999,24.3794,57.1092,Adazu Novads,ADM1,DISTRICT
SM13385,24.3803,57.1094,Adazu novads,ADM2,DISTRICT
SM11359,38.7446,9.0205,Addis Ababa Zone 1,ADM2,DISTRICT
SM11360,38.7484,8.995,Addis Ababa Zone 2,ADM2,DISTRICT
SM11361,38.7706,8.9797,Addis Ababa Zone 3,ADM2,DISTRICT
SM11362,38.718,9.059,Addis Ababa Zone 5,ADM2,DISTRICT
SM11363,38.7816,8.8934,Addis Ababa Zone 6,ADM2,DISTRICT
SM278,38.75,9,Addis Abeba,ADM1,DISTRICT
SM346072,38.7254,9.0346,Addis Ketema,ADM2:ADM3,DISTRICT
SM302922,-73.1409,44.0309,Addison,ADM2,DISTRICT
SM4249,73.1684,-0.6869,Addu Atoll,ADM1:ADM2,DISTRICT
SM10258,22.2407,12.4561,Ade,ADM2,DISTRICT
SM261683,138.5953,-34.9285,Adelaide,ADM2,DISTRICT
SM261694,138.8546,-34.901,Adelaide Hills,ADM2,DISTRICT
SM224901,0.6192,8.1698,Adele,LANGUAGE,LANGUAGE
SM12090,-77.7859,18.4506,Adelphi,ADM2,DISTRICT
SM268219,-50.1885,-16.3858,Adelândia,ADM2,DISTRICT
SM11365,-0.1542,5.7142,Adenta,ADM2,DISTRICT
SM15211,8.923,16.5145,Aderbissinat,ADM2,DISTRICT
SM304814,47.8972,15.0139,Adh Dhlia'ah,ADM2,DISTRICT
SM305768,44.3665,33.4809,Adhamiya,ADM2,DISTRICT
SM224908,48.06,40.05,Adhari,LANGUAGE,LANGUAGE
SM224911,34.0341,0.7323,Adhola,LANGUAGE,LANGUAGE
SM250891,34.18,0.9,Adhola,ETHNICITY,ETHNICITY
SM250891,35.87,1.37,Adhola,ETHNICITY,ETHNICITY
SM251399,95,28,Adi (St),ETHNICITY,ETHNICITY
SM274022,38.7762,15.5693,Adi Teklezan,ADM2,DISTRICT
SM274773,42.7333,41.7167,Adigeni,ADM2,DISTRICT
SM11633,78.5,19.5,Adilabad (2016-),ADM2,DISTRICT
SM298209,42.7365,38.8058,Adilcevaz,ADM2,DISTRICT
SM224918,-4.5252,5.3823,Adioukrou,LANGUAGE,LANGUAGE
SM459304,141.7114,-12.9242,Adithinngithigh,ETHNICITY,ETHNICITY
SM224921,73.1426,24.0712,Adiwasi Garasia,LANGUAGE,LANGUAGE
SM279,38.25,37.75,Adiyaman,ADM1,DISTRICT
SM250838,1.86,6.98,Adja,ETHNICITY,ETHNICITY
SM262118,2.587,6.968,Adja-Ouèrè,ADM2,DISTRICT
SM251422,42.92,41.25,Adjar,ETHNICITY,ETHNICITY
SM262109,2.681,6.4914,Adjarra,ADM2,DISTRICT
SM262110,2.494,6.7118,Adjohoun,ADM2,DISTRICT
SM292332,27.1928,46.1146,Adjud,ADM2,DISTRICT
SM280,31.75,3.25,Adjumani (1997-),ADM1:ADM2,DISTRICT
SM281,-66.7221,18.1627,Adjuntas,ADM1,DISTRICT
SM15398,-66.7237,18.1638,Adjuntas Pueblo Barrio,ADM2,DISTRICT
SM11211,33.9734,8.147,Administrative Zone 1,ADM2,DISTRICT
SM251423,147.23,-2.11,Admirality Islands,ETHNICITY,ETHNICITY
SM16827,30.2982,59.9234,Admiralteysky Rayon,ADM2,DISTRICT
SM224927,138.671,-30.1451,Adnyamathanha,LANGUAGE,LANGUAGE
SM458666,138.8826,-30.3502,Adnyamathanha,ETHNICITY,ETHNICITY
SM285338,8.0054,6.7993,Ado,ADM2,DISTRICT
SM284705,5.2738,7.6126,Ado-Ekiti,ADM2,DISTRICT
SM285060,3.0863,6.6237,AdoOdo/Ota,ADM2,DISTRICT
SM266543,-49.655,-21.2854,Adolfo,ADM2,DISTRICT
SM260931,-63.75,-40.75,Adolfo Alsina,ADM2,DISTRICT
SM260696,-63,-37.25,Adolfo Alsina,ADM2,DISTRICT
SM260736,-60.25,-38,Adolfo Gonzales Chaves,ADM2,DISTRICT
SM224929,123.159,-8.3203,Adonara,LANGUAGE,LANGUAGE
SM282,-1,25.75,Adrar,ADM1,DISTRICT
SM283,-10.25,20.75,Adrar,ADM1,DISTRICT
SM457369,-1,27,Adrar District,ADM2,DISTRICT
SM260009,61.9955,33.7054,Adraskan,ADM2,DISTRICT
SM264554,-48.8,-24.7663,Adrianópolis,ADM2,DISTRICT
SM224932,6.1309,7.6457,Aduge,LANGUAGE,LANGUAGE
SM291489,25.6018,45.1883,Adunati,ADM2,DISTRICT
SM290559,26.0648,44.2585,Adunatii-copaceni,ADM2,DISTRICT
SM224934,69.32,33.79,Adurgari,LANGUAGE,LANGUAGE
SM267697,-37.9845,-10.4823,Adustina,ADM2,DISTRICT
SM457938,41.8333,44.3333,Adyge-Khabl'skiy rayon (2007-),ADM2,DISTRICT
SM284,40.0105,45.0001,Adygey,ADM1,DISTRICT
SM16828,39.1917,44.8785,Adygeysk Republican Urban Okrug,ADM2,DISTRICT
SM224936,39.33,44,Adyghe,LANGUAGE,LANGUAGE
SM251426,39,44,Adyghe,ETHNICITY,ETHNICITY
SM251426,39,44,Adyghe,ETHNICITY,ETHNICITY
SM251426,39,44,Adyghe,ETHNICITY,ETHNICITY
SM251426,39,44,Adyghe,ETHNICITY,ETHNICITY
SM251427,9.52,-0.12,Adyumba,ETHNICITY,ETHNICITY
SM224937,146.294,-6.3939,Adzera,LANGUAGE,LANGUAGE
SM280406,103.4589,13.2362,Aek Phnum,ADM2,DISTRICT
SM224941,147.9948,-8.8551,Aeka,LANGUAGE,LANGUAGE
SM224942,141.532,-5.8848,Aekyom,LANGUAGE,LANGUAGE
SM12091,-77.3985,18.2103,Aenon Town,ADM2,DISTRICT
SM224945,69.029,25.254,Aer,LANGUAGE,LANGUAGE
SM286,21.4594,41.9881,Aerodrom,ADM1:ADM2,DISTRICT
SM15017,32.5804,-25.9086,Aeroporto,ADM2,DISTRICT
SM224824,-75.0844,-1.2841,Aewa,LANGUAGE,LANGUAGE
SM273986,38.6833,16.1833,Afabet,ADM2,DISTRICT
SM224948,14.6343,12.0551,Afade,LANGUAGE,LANGUAGE
SM305828,45.4143,31.9334,Afak,ADM2,DISTRICT
SM293261,53.25,58.8333,Afanas'evskiy rayon,ADM2,DISTRICT
SM10551,33.5711,35.15,Afanteia,ADM2,DISTRICT
SM224949,41.8083,12.2281,Afar,LANGUAGE,LANGUAGE
SM287,41.5,12,Afar,ADM1,DISTRICT
SM274334,41.1667,12,Afar Zone 1,ADM2,DISTRICT
SM274335,40.0562,13.6851,Afar Zone 2,ADM2,DISTRICT
SM274336,40.4739,10.009,Afar Zone 3,ADM2,DISTRICT
SM274337,40.1667,12.3333,Afar Zone 4,ADM2,DISTRICT
SM274337,40.1667,12.3333,Afar Zone 4,ADM2,DISTRICT
SM274337,40.3,12.57,Afar Zone 4,ADM2,DISTRICT
SM274337,40.3,12.57,Afar Zone 4,ADM2,DISTRICT
SM274338,40.25,10.5,Afar Zone 5,ADM2,DISTRICT
SM274376,43,5.25,Afder,ADM2,DISTRICT
SM250014,41.71,13.08,Affar,ETHNICITY,ETHNICITY
SM250014,41.71,13.08,Affar,ETHNICITY,ETHNICITY
SM250014,41.71,13.08,Affar,ETHNICITY,ETHNICITY
SM250014,41.63,13.02,Affar,ETHNICITY,ETHNICITY
SM250014,41.63,13.02,Affar,ETHNICITY,ETHNICITY
SM250014,41.63,13.02,Affar,ETHNICITY,ETHNICITY
SM250014,42,12,Affar,ETHNICITY,ETHNICITY
SM250014,42,12,Affar,ETHNICITY,ETHNICITY
SM250014,42,12,Affar,ETHNICITY,ETHNICITY
SM250014,41.4,11.8,Affar,ETHNICITY,ETHNICITY
SM250014,41.4,11.8,Affar,ETHNICITY,ETHNICITY
SM250014,41.4,11.8,Affar,ETHNICITY,ETHNICITY
SM269008,8.4744,47.2731,Affoltern,ADM2,DISTRICT
SM224955,70.4478,34.4342,Afghan Sign Language,LANGUAGE,LANGUAGE
SM17846,45.077,2.2355,Afgooye (excl. Darkenley),ADM2,DISTRICT
SM11366,-1.6083,6.8965,Afigya-Kwabre District,ADM2,DISTRICT
SM285146,3.8642,7.7379,Afijio,ADM2,DISTRICT
SM284679,7.9374,5.8931,Afikpo,ADM2,DISTRICT
SM251428,8,6,Afikpo,ETHNICITY,ETHNICITY
SM284680,7.7987,5.8493,AfikpoSo,ADM2,DISTRICT
SM224960,30.7611,12.446,Afitti,LANGUAGE,LANGUAGE
SM250323,10.71,12.24,Afizire,ETHNICITY,ETHNICITY
SM304844,43.4111,15.9789,Aflah Al Yaman,ADM2,DISTRICT
SM304855,43.412,16.0528,Aflah Ash Shawm,ADM2,DISTRICT
SM457370,2,34,Aflou District,ADM2,DISTRICT
SM295768,41.7862,0.5909,Afmadow,ADM2,DISTRICT
SM250230,6.47,8.13,Afo,ETHNICITY,ETHNICITY
SM250230,6.47,8.13,Afo,ETHNICITY,ETHNICITY
SM250230,8,8,Afo,ETHNICITY,ETHNICITY
SM250230,8,8,Afo,ETHNICITY,ETHNICITY
SM4387,0.4142,6.9085,Afodzato South,ADM2,DISTRICT
SM264938,-37.6178,-7.723,Afogados da Ingazeira,ADM2,DISTRICT
SM265529,-36.6562,-5.4318,Afonso Bezerra,ADM2,DISTRICT
SM267965,-41.1242,-20.1051,Afonso Cláudio,ADM2,DISTRICT
SM262914,-43.2974,-4.2148,Afonso Cunha,ADM2,DISTRICT
SM250839,28,-16,Afrikaaner,ETHNICITY,ETHNICITY
SM224965,30,-22,Afrikaans,LANGUAGE,LANGUAGE
SM296759,36.793,36.5489,Afrin,ADM2,DISTRICT
SM224968,-99.2934,31.7843,Afro-Seminole Creole,LANGUAGE,LANGUAGE
SM264949,-41.0577,-8.6259,Afrânio,ADM2,DISTRICT
SM251443,48.37,36.09,Afshar,ETHNICITY,ETHNICITY
SM224973,53.7,35.36,Aftari,DIALECT,LANGUAGE
SM224974,11.2968,9.8311,Afudu,LANGUAGE,LANGUAGE
SM290957,26.2494,44.525,Afumati,ADM2,DISTRICT
SM290326,23.4439,43.9979,Afumati,ADM2,DISTRICT
SM264156,-50.7668,-0.3768,Afuá,ADM2,DISTRICT
SM288,30.6667,38.75,Afyon,ADM1,DISTRICT
SM298558,36.919,38.258,Afşin,ADM2,DISTRICT
SM278931,139.4641,37.66,Aga,ADM2,DISTRICT
SM289,10.25,19.75,Agadez,ADM1,DISTRICT
SM281423,-9.5,30.5833,Agadir-Ida ou Tanane,ADM2,DISTRICT
SM299378,33.3534,2.9202,Agago,ADM1:ADM2,DISTRICT
SM251444,-112,44,Agaiduka,ETHNICITY,ETHNICITY
SM251444,-112,44,Agaiduka,ETHNICITY,ETHNICITY
SM251444,-112,44,Agaiduka,ETHNICITY,ETHNICITY
SM251444,-115.3,42.94,Agaiduka,ETHNICITY,ETHNICITY
SM251444,-115.3,42.94,Agaiduka,ETHNICITY,ETHNICITY
SM251444,-115.3,42.94,Agaiduka,ETHNICITY,ETHNICITY
SM251444,-113.8,45,Agaiduka,ETHNICITY,ETHNICITY
SM251444,-113.8,45,Agaiduka,ETHNICITY,ETHNICITY
SM251444,-113.8,45,Agaiduka,ETHNICITY,ETHNICITY
SM285022,6.4082,8.9306,Agaie,ADM2,DISTRICT
SM290,56.6167,-10.4,Agalega Islands,ADM1,DISTRICT
SM276639,100.1667,-0.25,Agam,ADM2,DISTRICT
SM291,144.7477,13.4656,Agana Heights,ADM1,DISTRICT
SM278942,139.2591,37.8142,Agano,ADM2,DISTRICT
SM291314,26.2955,47.162,Agapia,ADM2,DISTRICT
SM292738,59.3333,53.3333,Agapovskiy rayon,ADM2,DISTRICT
SM276968,76.0098,23.7159,Agar Malwa,ADM2,DISTRICT
SM224983,145.917,-6.206,Agarabi,LANGUAGE,LANGUAGE
SM251446,146.02,-5.82,Agarabi,ETHNICITY,ETHNICITY
SM17166,-60.9646,14.0077,Agard Lands/Morne Dudon,ADM2,DISTRICT
SM297938,10.4611,34.7441,Agareb,ADM2,DISTRICT
SM224982,82.297,24.5024,Agariya,LANGUAGE,LANGUAGE
SM292085,26.2296,46.4646,Agas,ADM2,DISTRICT
SM292,144.6694,13.3562,Agat,ADM1,DISTRICT
SM12862,138.6724,36.5737,Agatsuma-gun,ADM2,DISTRICT
SM224984,7.7164,7.9792,Agatu,LANGUAGE,LANGUAGE
SM285349,7.8549,7.9061,Agatu,ADM2,DISTRICT
SM224986,-53.4394,-13.2985,Agavotaguerra,LANGUAGE,LANGUAGE
SM12863,133.237,33.6401,Agawa-gun,ADM2,DISTRICT
SM262124,1.961,7.076,Agbangnizoun,ADM2,DISTRICT
SM4185,7.6232,58.6983,Agder,ADM1,DISTRICT
SM284989,3.3076,6.6394,Agege,ADM2,DISTRICT
SM279153,139.5816,35.9704,Ageo,ADM2,DISTRICT
SM274339,36.6969,10.9606,Agew Awi,ADM2,DISTRICT
SM11745,49.8282,30.6935,Aghajari,ADM2,DISTRICT
SM224992,10.0807,6.3896,Aghem,LANGUAGE,LANGUAGE
SM290067,23.2532,46.8779,Aghiresu,ADM2,DISTRICT
SM224996,140.043,-6.3467,Aghu,LANGUAGE,LANGUAGE
SM459421,144.1358,-14.9227,Aghu Tharnggala,ETHNICITY,ETHNICITY
SM224995,142.426,-13.735,Aghu Tharnggalu,LANGUAGE,LANGUAGE
SM224997,144,-15,Aghu Tharnggalu (Retired),LANGUAGE,LANGUAGE
SM224994,47.5843,41.9242,Aghul,LANGUAGE,LANGUAGE
SM224999,47.1267,40.3744,Aghwan,LANGUAGE,LANGUAGE
SM277997,35.9173,32.4539,Aghwar Shamaliyyeh,ADM2,DISTRICT
SM225001,142.387,-3.491,Agi,LANGUAGE,LANGUAGE
SM10552,33.5653,35.1109,Agia,ADM2,DISTRICT
SM10553,33.49,34.9387,Agia Anna,ADM2,DISTRICT
SM18558,32.9737,35.2909,Agia Eirini (Kyrenia),ADM2,DISTRICT
SM10554,32.9649,34.9854,Agia Eirini (Nicosia),ADM2,DISTRICT
SM10555,33.0613,35.0417,Agia Marina,ADM2,DISTRICT
SM10555,33.0613,35.0417,Agia Marina,ADM2,DISTRICT
SM10555,33.1299,35.2265,Agia Marina,ADM2,DISTRICT
SM10555,33.1299,35.2265,Agia Marina,ADM2,DISTRICT
SM10556,32.4791,34.7624,Agia Marinouda,ADM2,DISTRICT
SM10557,34.0109,34.9945,Agia Napa,ADM2,DISTRICT
SM10558,34.2246,35.5385,Agia Trias,ADM2,DISTRICT
SM10559,33.3658,34.9926,Agia Varvara,ADM2,DISTRICT
SM8873,114.5,51,Aginskiy Buryatskiy Avtonomnyy Okrug,ADM1:ADM2,DISTRICT
SM10560,33.1128,35.0658,Agioi Iliofotoi,ADM2,DISTRICT
SM10561,33.2357,35.1163,Agioi Trimithias,ADM2,DISTRICT
SM10562,33.1918,34.889,Agioi Vavatsinias,ADM2,DISTRICT
SM10563,33.5838,35.3298,Agios Amvrosios,ADM2,DISTRICT
SM10564,34.1562,35.5025,Agios Andronikos,ADM2,DISTRICT
SM10564,34.1562,35.5025,Agios Andronikos,ADM2,DISTRICT
SM10564,33.858,35.3393,Agios Andronikos,ADM2,DISTRICT
SM10564,33.858,35.3393,Agios Andronikos,ADM2,DISTRICT
SM10565,33.0553,34.7221,Agios Athanasios,ADM2,DISTRICT
SM10566,33.6007,35.2992,Agios Chariton,ADM2,DISTRICT
SM10567,32.551,34.9024,Agios Dimitrianos,ADM2,DISTRICT
SM10568,32.8115,34.9202,Agios Dimitrios,ADM2,DISTRICT
SM10569,33.3218,35.1796,Agios Dometios,ADM2,DISTRICT
SM10570,34.018,35.3872,Agios Efstathios,ADM2,DISTRICT
SM10571,33.1144,34.9988,Agios Epifanios,ADM2,DISTRICT
SM10571,33.1144,34.9988,Agios Epifanios,ADM2,DISTRICT
SM10571,32.8672,35.0618,Agios Epifanios,ADM2,DISTRICT
SM10571,32.8672,35.0618,Agios Epifanios,ADM2,DISTRICT
SM10572,33.4375,35.3292,Agios Epiktitos,ADM2,DISTRICT
SM10573,33.1768,35.2686,Agios Ermolaos,ADM2,DISTRICT
SM18556,33.8664,35.2533,Agios Georgios (Famagusta),ADM2,DISTRICT
SM18557,33.2698,35.3434,Agios Georgios (Kyrenia),ADM2,DISTRICT
SM10574,32.8897,35.1053,Agios Georgios (Nicosia),ADM2,DISTRICT
SM10574,32.8897,35.1053,Agios Georgios (Nicosia),ADM2,DISTRICT
SM10574,33.001,35.0349,Agios Georgios (Nicosia),ADM2,DISTRICT
SM10574,33.001,35.0349,Agios Georgios (Nicosia),ADM2,DISTRICT
SM10575,33.8185,35.3196,Agios Iakovos,ADM2,DISTRICT
SM10576,33.9324,35.3269,Agios Ilias,ADM2,DISTRICT
SM10577,33.1781,35.0754,Agios Ioannis,ADM2,DISTRICT
SM10578,32.6943,35.1323,Agios Ioannis Selemani,ADM2,DISTRICT
SM10579,32.4756,35.0075,Agios Isidoros,ADM2,DISTRICT
SM10580,33.0747,34.8736,Agios Konstantinos,ADM2,DISTRICT
SM10581,32.9457,34.8526,Agios Mamas,ADM2,DISTRICT
SM3969,33.6759,35.3221,Agios Nikolaos (Famagusta),ADM2,DISTRICT
SM10582,32.8733,35.1007,Agios Nikolaos (Nicosia),ADM2,DISTRICT
SM10583,33.049,34.8715,Agios Pavlos,ADM2,DISTRICT
SM10584,33.888,35.2002,Agios Sergios,ADM2,DISTRICT
SM10585,33.4435,35.07,Agios Sozomenos,ADM2,DISTRICT
SM10586,34.2325,35.4865,Agios Symeon,ADM2,DISTRICT
SM18555,34.0376,35.3527,Agios Theodoros (Famagusta),ADM2,DISTRICT
SM10587,32.934,35.0291,Agios Theodoros (Nicosia),ADM2,DISTRICT
SM10587,32.934,35.0291,Agios Theodoros (Nicosia),ADM2,DISTRICT
SM10587,32.6341,35.163,Agios Theodoros (Nicosia),ADM2,DISTRICT
SM10587,32.6341,35.163,Agios Theodoros (Nicosia),ADM2,DISTRICT
SM10588,32.8744,34.7824,Agios Therapon,ADM2,DISTRICT
SM10589,32.7313,34.7116,Agios Tomas,ADM2,DISTRICT
SM10590,33.1339,34.7215,Agios Tychon,ADM2,DISTRICT
SM10591,33.1919,35.2145,Agios Vasileios,ADM2,DISTRICT
SM10592,33.2794,35.2943,Agirda,ADM2,DISTRICT
SM10593,33.4169,35.1443,Aglantzia,ADM2,DISTRICT
SM3984,27.1006,56.1067,Aglonas Novads,ADM1,DISTRICT
SM13386,27.0074,56.1186,Aglonas pagasts,ADM2,DISTRICT
SM250038,-3.44,6.58,Agni,ETHNICITY,ETHNICITY
SM250038,-3.44,6.58,Agni,ETHNICITY,ETHNICITY
SM250038,-4,7,Agni,ETHNICITY,ETHNICITY
SM250038,-4,7,Agni,ETHNICITY,ETHNICITY
SM291707,24.6146,45.9895,Agnita,ADM2,DISTRICT
SM274355,34.4465,7.6529,Agnuak,ADM2,DISTRICT
SM269488,-4.3719,5.7949,Agnéby-Tiassa,ADM2,DISTRICT
SM225005,142.532,-9.0852,Agob-Ende-Kawam,LANGUAGE,LANGUAGE
SM225009,8.2163,5.8795,Agoi,LANGUAGE,LANGUAGE
SM251457,8.44,5.41,Agoi,ETHNICITY,ETHNICITY
SM251458,38.81,13.42,Agon,ETHNICITY,ETHNICITY
SM11367,-0.75,5.65,Agona East,ADM2,DISTRICT
SM11368,-0.7,5.5333,Agona West,ADM2,DISTRICT
SM4386,0.6936,6.4848,Agotime-Ziope,ADM2,DISTRICT
SM277268,77.9667,27.0833,Agra,ADM2,DISTRICT
SM270091,-75.7183,2.2705,Agrado,ADM2,DISTRICT
SM264960,-35.9324,-8.4519,Agrestina,ADM2,DISTRICT
SM293,43.1667,39.6667,Agri,ADM1,DISTRICT
SM265137,-42.6746,-5.7482,Agricolândia,ADM2,DISTRICT
SM10594,33.154,35.301,Agridaki,ADM2,DISTRICT
SM10595,32.9945,34.9312,Agridia,ADM2,DISTRICT
SM277898,13.5,37.45,Agrigento,ADM2,DISTRICT
SM15280,145.668,18.745,Agrihan Island,ADM2,DISTRICT
SM10596,33.1527,35.0492,Agrokipia,ADM2,DISTRICT
SM266355,-49.8376,-27.4639,Agrolândia,ADM2,DISTRICT
SM266366,-49.7312,-27.3166,Agronômica,ADM2,DISTRICT
SM10597,33.0186,34.9187,Agros,ADM2,DISTRICT
SM294601,52.8333,56.25,Agryzskiy rayon,ADM2,DISTRICT
SM251461,122.13,17.33,Agta (Casiguran),ETHNICITY,ETHNICITY
SM251462,122.05,17.48,Agta (Isabela),ETHNICITY,ETHNICITY
SM225012,122.2802,17.0538,Agta-Pahanan,LANGUAGE,LANGUAGE
SM251463,-50.64,67.93,Agto,ETHNICITY,ETHNICITY
SM303680,-69.1075,9.6615,Agua Blanca,ADM2,DISTRICT
SM275181,-89.6003,14.4515,Agua Blanca,ADM2,DISTRICT
SM281688,-98.3698,20.36,Agua Blanca de Iturbide,ADM2,DISTRICT
SM9868,-52.4909,-14.1358,Agua Boa (1999-),ADM2,DISTRICT
SM295606,-89.2229,14.1966,Agua Caliente,ADM2,DISTRICT
SM9869,-52.9509,-19.8979,Agua Clara (2003-),ADM2,DISTRICT
SM14206,-94.1656,18.0895,Agua Dulce,ADM2,DISTRICT
SM282561,-109.4276,31.2136,Agua Prieta,ADM2,DISTRICT
SM9870,-52.0129,-28.1854,Agua Santa (1996-),ADM2,DISTRICT
SM269957,-74.69,4.3584,Agua de Dios,ADM2,DISTRICT
SM251464,-76.21,20.34,Aguacadibe,ETHNICITY,ETHNICITY
SM15400,-67.11,18.498,Aguacate Barrio (Aguadilla),ADM2,DISTRICT
SM15399,-65.8603,18.0911,Aguacate Barrio (Yabucoa),ADM2,DISTRICT
SM225014,-91.3153,15.3307,Aguacateco,LANGUAGE,LANGUAGE
SM275475,-91.2948,15.352,Aguacatán,ADM2,DISTRICT
SM269888,-73.6189,8.2493,Aguachica,ADM2,DISTRICT
SM270441,-73.5275,6.1602,Aguada,ADM2,DISTRICT
SM294,-67.1746,18.3605,Aguada,ADM1,DISTRICT
SM15401,-67.1886,18.3802,Aguada Barrio-Pueblo,ADM2,DISTRICT
SM271079,-80.847,22.397,Aguada de Pasajeros,ADM2,DISTRICT
SM270839,-75.4427,5.6061,Aguadas,ADM2,DISTRICT
SM295,-67.1541,18.4275,Aguadilla,ADM1,DISTRICT
SM15402,-67.1548,18.4245,Aguadilla Barrio-Pueblo,ADM2,DISTRICT
SM286690,-80.6144,8.2127,Aguadulce,ADM2,DISTRICT
SM266654,-47.067,-22.0259,Aguai,ADM2,DISTRICT
SM282162,-99.6449,26.3056,Agualeguas,ADM2,DISTRICT
SM264001,-45.4172,-20.9696,Aguanil,ADM2,DISTRICT
SM225013,-75.8333,-5.25,Aguano,LANGUAGE,LANGUAGE
SM251465,-76.83,-5.34,Aguano,ETHNICITY,ETHNICITY
SM275679,-87.6462,13.9861,Aguaqueterique,ADM2,DISTRICT
SM273530,-75.3928,-0.901,Aguarico,ADM2,DISTRICT
SM225015,-77.9218,-5.3004,Aguaruna,LANGUAGE,LANGUAGE
SM251466,-76.22,-5.93,Aguaruna,ETHNICITY,ETHNICITY
SM15403,-66.8234,18.1218,Aguas Blancas Barrio,ADM2,DISTRICT
SM296,-66.1029,18.2569,Aguas Buenas,ADM1,DISTRICT
SM15404,-66.1052,18.2567,Aguas Buenas Barrio-Pueblo,ADM2,DISTRICT
SM18325,-56.3581,-34.5257,Aguas Corrientes,ADM2,DISTRICT
SM303661,-63.7308,9.4249,Aguasay,ADM2,DISTRICT
SM297,-102.3667,22,Aguascalientes,ADM1,DISTRICT
SM14207,-102.3,21.8833,Aguascalientes (1991-),ADM2,DISTRICT
SM285289,7.0812,5.9908,Aguata,ADM2,DISTRICT
SM270879,-72.5716,5.0992,Aguazul,ADM2,DISTRICT
SM265717,-53.224,-29.6263,Agudo,ADM2,DISTRICT
SM267054,-49.1616,-22.5894,Agudos,ADM2,DISTRICT
SM264665,-49.3119,-26.0462,Agudos do Sul,ADM2,DISTRICT
SM264333,-38.2426,-7.0762,Aguiar,ADM2,DISTRICT
SM289228,-7.5269,40.7832,Aguiar da Beira,ADM2,DISTRICT
SM267200,-47.5144,-6.4818,Aguiarnópolis,ADM2,DISTRICT
SM15281,145.558,14.85,Aguijan Island,ADM2,DISTRICT
SM295510,-89.201,13.9566,Aguilares,ADM2,DISTRICT
SM282010,-102.7654,18.7683,Aguililla,ADM2,DISTRICT
SM270948,-84.1614,9.4319,Aguirre,ADM2,DISTRICT
SM261036,-62.5,-29.3333,Aguirre,ADM2,DISTRICT
SM15405,-66.2599,17.9525,Aguirre Barrio,ADM2,DISTRICT
SM292819,47.5833,41.8333,Agul'skiy rayon,ADM2,DISTRICT
SM251467,48.83,41.68,Aguli,ETHNICITY,ETHNICITY
SM225021,1.7445,7.4926,Aguna,LANGUAGE,LANGUAGE
SM251470,126.03,8.47,Agusan,ETHNICITY,ETHNICITY
SM225023,125.742,8.6273,Agusan Manobo,LANGUAGE,LANGUAGE
SM298,125.571,8.9936,Agusan del Norte,ADM1:ADM2,DISTRICT
SM299,125.8333,8.5,Agusan del Sur,ADM1:ADM2,DISTRICT
SM269899,-73.2216,9.9485,Agustín Codazzi,ADM2,DISTRICT
SM225024,119.655,10.5328,Agutaynen,LANGUAGE,LANGUAGE
SM262111,2.5195,6.462,Aguégués,ADM2,DISTRICT
SM225028,8.0317,5.8106,Agwagwune,LANGUAGE,LANGUAGE
SM285033,4.5398,10.7748,Agwara,ADM2,DISTRICT
SM17718,42.8667,18.15,Ahad Rufaydah,ADM2,DISTRICT
SM17719,42.9167,16.75,Ahad al Musarihah,ADM2,DISTRICT
SM3930,-2.45,7,Ahafo,ADM1,DISTRICT
SM274851,-2.1667,7,Ahafo Ano North,ADM2,DISTRICT
SM274852,-1.9576,6.9178,Ahafo Ano South,ADM2,DISTRICT
SM300,59.75,38.25,Ahal,ADM1,DISTRICT
SM225030,-1.9466,4.8515,Ahanta,LANGUAGE,LANGUAGE
SM274857,-2.0098,4.8495,Ahanta West,ADM2,DISTRICT
SM11746,47.2834,38.5334,Ahar,ADM2,DISTRICT
SM251473,-97.87,60.03,Ahiarmiut,ETHNICITY,ETHNICITY
SM225037,120.9,16.321,Ahin-Kayapa Kalanguya,LANGUAGE,LANGUAGE
SM251474,78.99,23.64,Ahir,ETHNICITY,ETHNICITY
SM225039,73.685,21.552,Ahirani,LANGUAGE,LANGUAGE
SM284766,7.2714,5.5464,Ahizu-Mb,ADM2,DISTRICT
SM298210,42.4881,38.7545,Ahlat,ADM2,DISTRICT
SM4662,69.3398,33.7738,Ahmadabad,ADM2,DISTRICT
SM277032,74.65,19.4,Ahmadnagar,ADM2,DISTRICT
SM276811,72.58,23.03,Ahmedabad (2013-),ADM2,DISTRICT
SM298724,27.9448,38.5289,Ahmetli,ADM2,DISTRICT
SM285207,6.643,5.0703,Ahoada East,ADM2,DISTRICT
SM285212,6.4709,5.0858,Ahoada West,ADM2,DISTRICT
SM225041,88.464,27.2778,Ahom,LANGUAGE,LANGUAGE
SM282531,-109.0811,25.8989,Ahome,ADM2,DISTRICT
SM225043,-144.889,61.7068,Ahtena,LANGUAGE,LANGUAGE
SM251480,-136.2,60.06,Ahtna,ETHNICITY,ETHNICITY
SM251480,-136.19,59.95,Ahtna,ETHNICITY,ETHNICITY
SM282403,-97.8635,20.0077,Ahuacatlán,ADM2,DISTRICT
SM282142,-104.5413,21.0452,Ahuacatlán,ADM2,DISTRICT
SM295498,-89.9167,13.8333,Ahuachapán,ADM2,DISTRICT
SM301,-89.9167,13.8667,Ahuachapán,ADM1,DISTRICT
SM281591,-98.9178,17.7425,Ahuacuotzingo,ADM2,DISTRICT
SM282474,-101.23,22.4596,Ahualulco,ADM2,DISTRICT
SM281787,-103.9556,20.6993,Ahualulco de Mercado,ADM2,DISTRICT
SM275942,-84.3448,15.4692,Ahuas,ADM2,DISTRICT
SM282414,-98.2779,18.5793,Ahuatlán,ADM2,DISTRICT
SM282425,-98.1424,20.0545,Ahuazotepec,ADM2,DISTRICT
SM282436,-98.2029,18.2313,Ahuehuetitla,ADM2,DISTRICT
SM283211,-106.4883,30.4817,Ahumada,ADM2,DISTRICT
SM304950,46.7556,13.6853,Ahwar,ADM2,DISTRICT
SM298666,32.1242,37.2483,Ahırlı,ADM2,DISTRICT
SM225050,107.844,25.4908,Ai-Cham,LANGUAGE,LANGUAGE
SM295435,161.1284,-9.1164,Aiaisi,ADM2,DISTRICT
SM302,-66.266,18.14,Aibonito,ADM1,DISTRICT
SM15406,-66.7814,18.3565,Aibonito Barrio (Hatillo),ADM2,DISTRICT
SM15407,-66.9423,18.3731,Aibonito Barrio (San Sebastián),ADM2,DISTRICT
SM15408,-66.2642,18.1382,Aibonito Barrio-Pueblo,ADM2,DISTRICT
SM303,136.9066,35.1802,Aichi,ADM1:ADMX,DISTRICT
SM251483,147.96,-8.18,Aiga,ETHNICITY,ETHNICITY
SM304700,-172.0417,-13.8638,Aiga i le Tai,ADM2,DISTRICT
SM304,-172.25,-13.85,Aiga-i-le-Tai,ADM1,DISTRICT
SM225052,150.272,-5.9949,Aighon,LANGUAGE,LANGUAGE
SM10598,34.245,35.5534,Aigialousa,ADM2,DISTRICT
SM268997,7.0297,46.3058,Aigle,ADM2,DISTRICT
SM286790,-77.6667,-9.8333,Aija,ADM2,DISTRICT
SM225054,-60.5353,-12.6695,Aikanã,LANGUAGE,LANGUAGE
SM302443,-81.6347,33.5444,Aiken,ADM2,DISTRICT
SM225055,149.571,-6.2358,Aiklep,LANGUAGE,LANGUAGE
SM12864,139.2521,35.5001,Aiko-gun,ADM2,DISTRICT
SM297793,125.5569,-8.7447,Aileu,ADM2,DISTRICT
SM305,125.6375,-8.6917,Aileu,ADM1,DISTRICT
SM4074,166.4,11.1333,Ailinginae Atoll,ADM1,DISTRICT
SM4075,168.7667,7.3833,Ailinglaplap Atoll,ADM1,DISTRICT
SM4092,169.9218,10.2862,Ailuk Atoll,ADM1,DISTRICT
SM225058,59.1756,35.283,Aimaq,LANGUAGE,LANGUAGE
SM295446,160.7507,-8.7535,Aimela,ADM2,DISTRICT
SM225060,142.656,-6.6866,Aimele,LANGUAGE,LANGUAGE
SM306,134.49,7.445,Aimeliik,ADM1,DISTRICT
SM225061,94.3556,24.6437,Aimol,LANGUAGE,LANGUAGE
SM251485,94,25,Aimol,ETHNICITY,ETHNICITY
SM252445,-42,-18,Aimore,ETHNICITY,ETHNICITY
SM252445,-42,-18,Aimore,ETHNICITY,ETHNICITY
SM252445,-42,-18,Aimore,ETHNICITY,ETHNICITY
SM252445,-41.86,-18.71,Aimore,ETHNICITY,ETHNICITY
SM252445,-41.86,-18.71,Aimore,ETHNICITY,ETHNICITY
SM252445,-41.86,-18.71,Aimore,ETHNICITY,ETHNICITY
SM252445,-42.5,-19,Aimore,ETHNICITY,ETHNICITY
SM252445,-42.5,-19,Aimore,ETHNICITY,ETHNICITY
SM252445,-42.5,-19,Aimore,ETHNICITY,ETHNICITY
SM263236,-41.2096,-19.6255,Aimorés,ADM2,DISTRICT
SM304961,45.6004,14.9503,Ain,ADM2,DISTRICT
SM274421,5.3333,46.1667,Ain,ADM2,DISTRICT
SM305784,43.5153,32.4414,Ain Al Tamur,ADM2,DISTRICT
SM305784,43.5153,32.4414,Ain Al Tamur,ADM2,DISTRICT
SM305784,43.5348,32.5169,Ain Al Tamur,ADM2,DISTRICT
SM305784,43.5348,32.5169,Ain Al Tamur,ADM2,DISTRICT
SM272732,6.5,36,Ain M'Lila,ADM2,DISTRICT
SM279624,132.5825,32.9885,Ainan,ADM2,DISTRICT
SM297815,125.5071,-9.0075,Ainaro,ADM2,DISTRICT
SM309,125.525,-9.025,Ainaro,ADM1,DISTRICT
SM13387,24.3556,57.8586,Ainazi,ADM2,DISTRICT
SM13388,24.5021,57.8708,Ainazu pagasts,ADM2,DISTRICT
SM225065,141.149,-3.0921,Ainbai,LANGUAGE,LANGUAGE
SM251486,142.15,46.59,Ainu,ETHNICITY,ETHNICITY
SM251486,142.15,46.59,Ainu,ETHNICITY,ETHNICITY
SM251486,142.15,46.59,Ainu,ETHNICITY,ETHNICITY
SM251486,142.15,46.59,Ainu,ETHNICITY,ETHNICITY
SM251486,144,44,Ainu,ETHNICITY,ETHNICITY
SM251486,144,44,Ainu,ETHNICITY,ETHNICITY
SM251486,144,44,Ainu,ETHNICITY,ETHNICITY
SM251486,144,44,Ainu,ETHNICITY,ETHNICITY
SM251486,144.17,44.01,Ainu,ETHNICITY,ETHNICITY
SM251486,144.17,44.01,Ainu,ETHNICITY,ETHNICITY
SM251486,144.17,44.01,Ainu,ETHNICITY,ETHNICITY
SM251486,144.17,44.01,Ainu,ETHNICITY,ETHNICITY
SM251486,143,42.83,Ainu,ETHNICITY,ETHNICITY
SM251486,143,42.83,Ainu,ETHNICITY,ETHNICITY
SM251486,143,42.83,Ainu,ETHNICITY,ETHNICITY
SM251486,143,42.83,Ainu,ETHNICITY,ETHNICITY
SM225068,76.4209,39.2714,Ainu (China),LANGUAGE,LANGUAGE
SM278326,134.467,34.8272,Aioi,ADM2,DISTRICT
SM225070,144.765,-5.1347,Aiome,LANGUAGE,LANGUAGE
SM270102,-75.3079,3.3234,Aipe,ADM2,DISTRICT
SM267736,-39.8764,-14.1114,Aiquara,ADM2,DISTRICT
SM12865,130.6067,31.7846,Aira Shi,ADM2,DISTRICT
SM283672,112.0499,46.9725,Airag,ADM2,DISTRICT
SM283797,109.3167,45.7943,Airag,ADM2,DISTRICT
SM310,134.5187,7.367,Airai,ADM1,DISTRICT
SM323901,-114.0073,51.2867,Airdrie,ADM2:ADM3,DISTRICT
SM251487,-59.99,55.01,Airitumiut,ETHNICITY,ETHNICITY
SM225074,138.158,-1.8522,Airoran,LANGUAGE,LANGUAGE
SM12092,-76.3422,17.9251,Airy Castle,ADM2,DISTRICT
SM251488,-81.07,28.02,Ais,ETHNICITY,ETHNICITY
SM278032,136.6948,35.1723,Aisai,ADM2,DISTRICT
SM279223,136.25,35.1667,Aishō,ADM2,DISTRICT
SM238979,145.197,-5.3741,Aisi,LANGUAGE,LANGUAGE
SM274499,3.5,49.5,Aisne,ADM2,DISTRICT
SM269065,-72.5732,-45.3563,Aisén,ADM2,DISTRICT
SM311,-73.5,-46.5,Aisén del General Carlos Ibáñez del Campo,ADM1,DISTRICT
SM250145,-3.95,32.25,Ait Atta,ETHNICITY,ETHNICITY
SM251489,-3.58,33.77,Ait Idrassen,ETHNICITY,ETHNICITY
SM251490,-7.09,30.1,Ait Khebache,ETHNICITY,ETHNICITY
SM251491,-4.31,33.26,Ait Oumalou,ETHNICITY,ETHNICITY
SM251492,-4.99,31.42,Ait Walnzgit,ETHNICITY,ETHNICITY
SM251493,-3.56,33.24,Ait Yafelman,ETHNICITY,ETHNICITY
SM290206,25.5882,45.9713,Aita Mare,ADM2,DISTRICT
SM288594,142.0833,-3.25,Aitape-Lumi,ADM2,DISTRICT
SM301338,-93.4154,46.6083,Aitkin,ADM2,DISTRICT
SM225080,92.3169,26.5145,Aiton,LANGUAGE,LANGUAGE
SM290078,23.7161,46.6828,Aiton,ADM2,DISTRICT
SM262393,27.25,42.7,Aitos,ADM2,DISTRICT
SM4233,-159.7857,-18.8559,Aitutaki,ADM1,DISTRICT
SM267909,-40.2395,-6.589,Aiuaba,ADM2,DISTRICT
SM289786,23.6975,46.3044,Aiud,ADM2,DISTRICT
SM263247,-44.6478,-21.947,Aiuruoca,ADM2,DISTRICT
SM13389,25.7913,56.6592,Aiviekstes pagasts,ADM2,DISTRICT
SM251495,-91.79,65.38,Aivilik,ETHNICITY,ETHNICITY
SM251495,-91.79,65.38,Aivilik,ETHNICITY,ETHNICITY
SM251495,-88.12,65.16,Aivilik,ETHNICITY,ETHNICITY
SM251495,-88.12,65.16,Aivilik,ETHNICITY,ETHNICITY
SM312,166.9099,-0.5342,Aiwo,ADM1,DISTRICT
SM277087,92.9,23.8,Aizawl (1999-),ADM2,DISTRICT
SM13390,26.7539,56.2051,Aizkalnes pagasts,ADM2,DISTRICT
SM13391,25.2463,56.5923,Aizkraukle,ADM2,DISTRICT
SM4048,25.2521,56.6018,Aizkraukles novads,ADM1,DISTRICT
SM13392,25.2299,56.6448,Aizkraukles pagasts,ADM2,DISTRICT
SM251496,1.69,7.59,Aizo,ETHNICITY,ETHNICITY
SM13393,21.6087,56.7172,Aizpute,ADM2,DISTRICT
SM3954,21.5689,56.7167,Aizputes Novads,ADM1,DISTRICT
SM13394,21.5251,56.6908,Aizputes pagasts,ADM2,DISTRICT
SM251497,-64.8,-2.61,Aizuare,ETHNICITY,ETHNICITY
SM279749,139.9681,37.4507,Aizuwakamatsu,ADM2,DISTRICT
SM225088,1.6499,7.0663,Aja (Benin),LANGUAGE,LANGUAGE
SM225090,25.6122,8.58,Aja (Sudan),LANGUAGE,LANGUAGE
SM281699,-99.0614,20.1433,Ajacuba,ADM2,DISTRICT
SM282235,-97.1226,18.4249,Ajalpan,ADM2,DISTRICT
SM284963,6.5813,7.5235,Ajaokuta,ADM2,DISTRICT
SM313,42,41.6667,Ajaria,ADM1,DISTRICT
SM225091,9.8921,10.21,Ajawa,LANGUAGE,LANGUAGE
SM296247,13.9286,45.9069,Ajdovščina,ADM1:ADM2,DISTRICT
SM285000,3.3472,6.4494,Ajeromi/Ifelodun,ADM2,DISTRICT
SM251498,165.47,-21.37,Ajie,ETHNICITY,ETHNICITY
SM251498,165.47,-21.37,Ajie,ETHNICITY,ETHNICITY
SM251498,165.67,-21.33,Ajie,ETHNICITY,ETHNICITY
SM251498,165.67,-21.33,Ajie,ETHNICITY,ETHNICITY
SM284853,9.0675,11.9591,Ajingi,ADM2,DISTRICT
SM225093,165.468,-21.3029,Ajië,LANGUAGE,LANGUAGE
SM250300,11.84,28.7,Ajjer,ETHNICITY,ETHNICITY
SM250300,11.84,28.7,Ajjer,ETHNICITY,ETHNICITY
SM250300,10,25,Ajjer,ETHNICITY,ETHNICITY
SM250300,10,25,Ajjer,ETHNICITY,ETHNICITY
SM11536,17.5638,47.0799,Ajkai Jaras,ADM2,DISTRICT
SM277969,35.7533,32.3446,Ajloun,ADM2,DISTRICT
SM314,35.75,32.3333,Ajlun,ADM1,DISTRICT
SM260507,55.5234,25.4033,Ajman,ADM2,DISTRICT
SM315,55.5167,25.3833,Ajman,ADM1,DISTRICT
SM277163,74.6667,26.25,Ajmer,ADM2,DISTRICT
SM260296,67.1089,33.5157,Ajristan,ADM2,DISTRICT
SM281602,-100.5916,17.9511,Ajuchitlán del Progreso,ADM2,DISTRICT
SM274894,-0.95,5.4167,Ajumako-Enyan-Esiam,ADM2,DISTRICT
SM238001,10.2556,6.5379,Ajumbu,LANGUAGE,LANGUAGE
SM265828,-53.7446,-28.2072,Ajuricaba,ADM2,DISTRICT
SM275839,-87.7158,14.3951,Ajuterique,ADM2,DISTRICT
SM225100,-74.6195,-9.8236,Ajyíninka Apurucayali,LANGUAGE,LANGUAGE
SM225139,141.784,-3.5703,Ak,LANGUAGE,LANGUAGE
SM280343,78.9914,42.1994,Ak-Suu,ADM2,DISTRICT
SM280325,74.7805,41.0628,Ak-Talaa,ADM2,DISTRICT
SM225101,33.6524,11.2101,Aka,LANGUAGE,LANGUAGE
SM251500,17,2,Aka (Bambenzele),ETHNICITY,ETHNICITY
SM225103,92.7818,12.3782,Akabea,LANGUAGE,LANGUAGE
SM278260,142.0628,43.5679,Akabira,ADM2,DISTRICT
SM225102,92.8011,12.3629,Akabo,LANGUAGE,LANGUAGE
SM225105,92.9486,13.4909,Akacari,LANGUAGE,LANGUAGE
SM279020,134.0179,34.8377,Akaiwa,ADM2,DISTRICT
SM225106,92.9504,13.1525,Akajeru,LANGUAGE,LANGUAGE
SM225108,92.8227,12.8494,Akakede,LANGUAGE,LANGUAGE
SM10599,33.1304,35.1389,Akaki,ADM2,DISTRICT
SM346074,38.8045,8.8986,Akaki - Kalit,ADM2:ADM3,DISTRICT
SM225109,92.7866,12.7325,Akakol,LANGUAGE,LANGUAGE
SM225107,92.7466,12.0944,Akakora,LANGUAGE,LANGUAGE
SM18597,39.5,14.8333,Akale Guzay Awraja,ADM2,DISTRICT
SM285399,8.5186,5.4273,Akamkpa,ADM2,DISTRICT
SM225114,-1.3325,6.3476,Akan,LANGUAGE,LANGUAGE
SM250988,-4.26,6.33,Akan,ETHNICITY,ETHNICITY
SM10600,33.7612,35.3768,Akanthou,ADM2,DISTRICT
SM10601,33.1989,34.8432,Akapnou,ADM2,DISTRICT
SM225116,92.8278,12.6663,Akarbale,LANGUAGE,LANGUAGE
SM458119,135.363,-22.8425,Akarre,ETHNICITY,ETHNICITY
SM225119,1.3704,8.8858,Akaselem,LANGUAGE,LANGUAGE
SM278337,134.9403,34.6828,Akashi,ADM2,DISTRICT
SM297493,103.9759,17.6359,Akat Amnuai,ADM2,DISTRICT
SM248742,-91.8421,15.5932,Akateko,LANGUAGE,LANGUAGE
SM4390,0.799,6.197,Akatsi North,ADM2,DISTRICT
SM4389,0.7815,6.1474,Akatsi South,ADM2,DISTRICT
SM8036,-61,7.64,Akawai,ETHNICITY,ETHNICITY
SM225120,-60.862,6.1628,Akawaio,LANGUAGE,LANGUAGE
SM457371,4.5833,36.4167,Akbou District,ADM2,DISTRICT
SM299025,35.8975,39.664,Akdağmadeni,ADM2,DISTRICT
SM18016,34.6773,36.8642,Akdeniz,ADM2,DISTRICT
SM18114,59.384,42.0674,Akdepe District,ADM2,DISTRICT
SM225122,8.7577,8.5954,Ake,LANGUAGE,LANGUAGE
SM251504,121.94,11.46,Akeanon,ETHNICITY,ETHNICITY
SM225121,0.7767,7.7837,Akebu,LANGUAGE,LANGUAGE
SM225123,166.767,-15.6377,Akei,LANGUAGE,LANGUAGE
SM250355,10.53,-1,Akele,ETHNICITY,ETHNICITY
SM250355,12.79,1.08,Akele,ETHNICITY,ETHNICITY
SM250355,12.31,-1.29,Akele,ETHNICITY,ETHNICITY
SM225125,101.0823,22.1959,Akeu,LANGUAGE,LANGUAGE
SM225126,100.964,21.2309,Akha,LANGUAGE,LANGUAGE
SM11289,44.4837,42.1254,Akhalgori,ADM2,DISTRICT
SM11290,44.4833,42.2167,Akhalgoris Munitsip'alit'et'I (excl. Akhalgoris),ADM2,DISTRICT
SM274774,43.4499,41.4166,Akhalkalaki,ADM2,DISTRICT
SM274775,43,41.6333,Akhaltsikhe,ADM2,DISTRICT
SM298732,27.848,38.9255,Akhisar,ADM2,DISTRICT
SM274805,45.3333,42.25,Akhmeta,ADM2,DISTRICT
SM16830,46.25,48.1667,Akhtubinskiy Rayon,ADM2,DISTRICT
SM16831,47.5833,41.3333,Akhtynskiy Rayon,ADM2,DISTRICT
SM225131,46.3037,42.4003,Akhvakh,LANGUAGE,LANGUAGE
SM292841,46.3333,42.5833,Akhvakhskiy rayon,ADM2,DISTRICT
SM278584,133.9511,33.5908,Aki,ADM2,DISTRICT
SM12866,134.0918,33.5489,Aki Gun,ADM2,DISTRICT
SM12867,132.5578,34.3535,Aki-gun,ADM2,DISTRICT
SM238738,37.1869,-5.4347,Akie,LANGUAGE,LANGUAGE
SM454487,37.5,-5,Akie,ETHNICITY,ETHNICITY
SM282961,-89.3485,20.264,Akil,ADM2,DISTRICT
SM256884,-111.24,33.52,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-111.24,33.52,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-111.24,33.52,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-111.24,33.52,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-111.24,33.52,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-112,31,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-112,31,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-112,31,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-112,31,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-112,31,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-111,31,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-111,31,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-111,31,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-111,31,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-111,31,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-112,33.2,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-112,33.2,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-112,33.2,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-112,33.2,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-112,33.2,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-108,29,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-108,29,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-108,29,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-108,29,Akimel Oodham,ETHNICITY,ETHNICITY
SM256884,-108,29,Akimel Oodham,ETHNICITY,ETHNICITY
SM285157,3.9061,7.5615,Akinyele,ADM2,DISTRICT
SM279437,139.2253,35.7329,Akiruno,ADM2,DISTRICT
SM279448,139.3642,35.7078,Akishima,ADM2,DISTRICT
SM278559,140.2321,39.7104,Akita,ADM2,DISTRICT
SM317,140.4,39.75,Akita,ADM1:ADMX,DISTRICT
SM278134,132.6777,34.7025,Akitakata,ADM2,DISTRICT
SM284021,-15.009,19.9693,Akjoujt,ADM2,DISTRICT
SM225138,44.1,33.1,Akkadian,LANGUAGE,LANGUAGE
SM225137,32.4046,67.8625,Akkala Saami,LANGUAGE,LANGUAGE
SM280898,36.1667,34.5333,Akkar,ADM2,DISTRICT
SM318,36.1667,34.5333,Akkar,ADM1,DISTRICT
SM12868,144.9072,43.1554,Akkeshi-gun,ADM2,DISTRICT
SM284744,11.0483,10.0999,Akko,ADM2,DISTRICT
SM13152,71.25,52.1667,Akkol (excl. Stepnogorsk),ADM2,DISTRICT
SM298799,36.96,40.81,Akkuş,ADM2,DISTRICT
SM298608,36.179,39.0122,Akkışla,ADM2,DISTRICT
SM319,122.3333,11.6667,Aklan,ADM1:ADM2,DISTRICT
SM225140,122.3541,11.5543,Aklan,FAMILY,LANGUAGE
SM225141,122.354,11.5543,Aklanon,LANGUAGE,LANGUAGE
SM18365,69.1789,41.3122,Akmal Ikromow Tumani,ADM2,DISTRICT
SM281348,22.9,56.3167,Akmenės,ADM2,DISTRICT
SM13395,25.745,56.1604,Akniste,ADM2,DISTRICT
SM4061,25.8089,56.1464,Aknistes Novads,ADM1,DISTRICT
SM13396,25.7585,56.1811,Aknistes pagasts,ADM2,DISTRICT
SM251508,-95.31,29.68,Akokisa,ETHNICITY,ETHNICITY
SM251510,4.17,9.6,Akoko,ETHNICITY,ETHNICITY
SM285067,5.8102,7.5274,Akoko North-East,ADM2,DISTRICT
SM285077,5.9116,7.4179,Akoko South-East,ADM2,DISTRICT
SM285078,5.6946,7.3977,Akoko South-West,ADM2,DISTRICT
SM284687,6.1149,7.3451,Akoko-Ed,ADM2,DISTRICT
SM285079,5.794,7.641,AkokoNorthWest,ADM2,DISTRICT
SM277043,77.1667,20.5,Akola (1997-),ADM2,DISTRICT
SM225143,150.182,-6.2271,Akolet,LANGUAGE,LANGUAGE
SM225146,9.6552,4.9243,Akoose,LANGUAGE,LANGUAGE
SM273998,37.8833,15.55,Akordat,ADM2,DISTRICT
SM251511,25.13,-12.6,Akosa,ETHNICITY,ETHNICITY
SM297985,10.5499,35.9206,Akouda,ADM2,DISTRICT
SM10602,32.4251,34.883,Akoursos,ADM2,DISTRICT
SM225149,145.669,-7.6607,Akoye,LANGUAGE,LANGUAGE
SM225150,8.0163,7.3259,Akpa,LANGUAGE,LANGUAGE
SM285400,8.4811,4.9009,Akpabuyo,ADM2,DISTRICT
SM225153,5.9556,7.5026,Akpes,LANGUAGE,LANGUAGE
SM225156,8.5455,9.2352,Akpondu,LANGUAGE,LANGUAGE
SM251513,0.38,8.37,Akposso,ETHNICITY,ETHNICITY
SM262112,2.5853,6.5625,Akpro-Missérété,ADM2,DISTRICT
SM298653,33.9698,39.4588,Akpınar,ADM2,DISTRICT
SM277788,-18.7957,65.3551,Akrahreppur,ADM2,DISTRICT
SM277843,-22.0437,64.3241,Akranes,ADM2,DISTRICT
SM305793,43.8936,36.7415,Akre (incl. Bardarash),ADM2,DISTRICT
SM320,32.9656,34.6004,Akrotiri,ADM1:ADM2,DISTRICT
SM10604,33.0841,34.7698,Akrounta,ADM2,DISTRICT
SM262454,27.85,43.3167,Aksakovo,ADM2,DISTRICT
SM321,34.0142,38.365,Aksaray,ADM1,DISTRICT
SM294143,39.8717,47.2519,Aksayskiy rayon,ADM2,DISTRICT
SM299010,31.8015,37.0582,Akseki,ADM2,DISTRICT
SM295087,113.25,50.25,Akshinskiy rayon,ADM2,DISTRICT
SM298471,31.0844,37.8097,Aksu,ADM2,DISTRICT
SM18017,30.8235,36.9279,Aksu,ADM2,DISTRICT
SM269343,81.8463,40.5569,Aksu,ADM2,DISTRICT
SM294612,50.8333,54.8333,Aksubayevskiy rayon,ADM2,DISTRICT
SM280317,71.9975,41.4963,Aksyi,ADM2,DISTRICT
SM294623,53.8333,55.75,Aktanyshskiy rayon,ADM2,DISTRICT
SM279987,75.1667,52.75,Aktogayskiy,ADM2,DISTRICT
SM262584,29,52.6667,Aktsyabar,ADM2,DISTRICT
SM11370,-0.2336,5.8663,Akuapim South (2012-),ADM2,DISTRICT
SM251515,-77.26,71.78,Akudnirmiut,ETHNICITY,ETHNICITY
SM225161,6.3426,7.4035,Akuku,LANGUAGE,LANGUAGE
SM285213,6.752,4.5615,Akukutor,ADM2,DISTRICT
SM251516,-71.77,64.23,Akuliarmiut,ETHNICITY,ETHNICITY
SM251517,-122.36,69.44,Akulliakatagmiut,ETHNICITY,ETHNICITY
SM225162,9.9626,6.8572,Akum,LANGUAGE,LANGUAGE
SM251518,146.35,-6.1,Akuna,ETHNICITY,ETHNICITY
SM278491,130.1977,32.0252,Akune,ADM2,DISTRICT
SM225165,-60.9716,-12.8322,Akuntsu,LANGUAGE,LANGUAGE
SM285080,5.3114,7.2638,Akure North,ADM2,DISTRICT
SM285081,5.1877,7.205,Akure South,ADM2,DISTRICT
SM11599,-18.2065,65.6457,Akureyrarkaupstadur (2008-),ADM2,DISTRICT
SM225167,-55.6294,3.1961,Akurio,LANGUAGE,LANGUAGE
SM292852,47.3333,42.1667,Akushinskiy rayon,ADM2,DISTRICT
SM225170,15.4623,-0.399,Akwa,LANGUAGE,LANGUAGE
SM322,7.8333,5,Akwa Ibom,ADM1,DISTRICT
SM285009,8.327,8.9939,Akwanga,ADM2,DISTRICT
SM274910,-0.0833,5.9667,Akwapim North,ADM2,DISTRICT
SM298580,43.6143,40.7409,Akyaka,ADM2,DISTRICT
SM298836,30.628,40.696,Akyazı,ADM2,DISTRICT
SM250044,-2.93,5.55,Akye,ETHNICITY,ETHNICITY
SM250044,-2.93,5.55,Akye,ETHNICITY,ETHNICITY
SM250044,-4,6,Akye,ETHNICITY,ETHNICITY
SM250044,-4,6,Akye,ETHNICITY,ETHNICITY
SM250017,-0.82,6.1,Akyem,ETHNICITY,ETHNICITY
SM250017,-0.82,6.1,Akyem,ETHNICITY,ETHNICITY
SM250017,-0.82,6.1,Akyem,ETHNICITY,ETHNICITY
SM250017,-1.5,7,Akyem,ETHNICITY,ETHNICITY
SM250017,-1.5,7,Akyem,ETHNICITY,ETHNICITY
SM250017,-1.5,7,Akyem,ETHNICITY,ETHNICITY
SM250017,-1,7,Akyem,ETHNICITY,ETHNICITY
SM250017,-1,7,Akyem,ETHNICITY,ETHNICITY
SM250017,-1,7,Akyem,ETHNICITY,ETHNICITY
SM11371,-1.15,6.1667,Akyemansa,ADM2,DISTRICT
SM298882,33.0974,40.1414,Akyurt,ADM2,DISTRICT
SM279970,72.1667,53.8333,Akzharskiy,ADM2,DISTRICT
SM279970,72.1667,53.8333,Akzharskiy,ADM2,DISTRICT
SM279970,71.25,53.8333,Akzharskiy,ADM2,DISTRICT
SM279970,71.25,53.8333,Akzharskiy,ADM2,DISTRICT
SM298960,39.5629,41.0197,Akçaabat,ADM2,DISTRICT
SM298710,37.9741,38.344,Akçadağ,ADM2,DISTRICT
SM298864,38.9694,36.7614,Akçakale,ADM2,DISTRICT
SM298652,34.0847,39.6318,Akçakent,ADM2,DISTRICT
SM298333,31.1297,41.0805,Akçakoca,ADM2,DISTRICT
SM298677,32.3749,37.4626,Akören,ADM2,DISTRICT
SM298922,38.3462,40.0787,Akıncılar,ADM2,DISTRICT
SM278348,134.3724,34.7889,Akō,ADM2,DISTRICT
SM298688,31.4198,38.3673,Akşehir,ADM2,DISTRICT
SM304884,44.8816,13.0624,Al  Hawtah,ADM2,DISTRICT
SM304978,43.428,13.52,Al  Mukha,ADM2,DISTRICT
SM305001,44.7778,14.3656,Al A'rsh,ADM2,DISTRICT
SM304899,45.3934,14.717,Al Abdiyah,ADM2,DISTRICT
SM304825,47.2288,15.9137,Al Abr,ADM2,DISTRICT
SM17720,46.8333,22.0833,Al Aflaj,ADM2,DISTRICT
SM328,48,28.9167,Al Ahmadi,ADM1,DISTRICT
SM17721,51,22,Al Ahsa',ADM2,DISTRICT
SM260505,55.8204,24.1522,Al Ain,ADM2,DISTRICT
SM305787,47.1575,31.9941,Al Amarah,ADM2,DISTRICT
SM304771,43.7874,16.324,Al Ashah,ADM2,DISTRICT
SM17722,44.1667,26.95,Al Asyah,ADM2,DISTRICT
SM305022,44.637,13.6468,Al Azariq,ADM2,DISTRICT
SM305795,41.6818,35.6206,Al Ba'aj,ADM2,DISTRICT
SM305795,41.6818,35.6206,Al Ba'aj,ADM2,DISTRICT
SM305795,41.6827,35.6446,Al Ba'aj,ADM2,DISTRICT
SM305795,41.6827,35.6446,Al Ba'aj,ADM2,DISTRICT
SM296760,37.5328,36.3095,Al Bab,ADM2,DISTRICT
SM17723,43.7333,25.8,Al Bada'i,ADM2,DISTRICT
SM17724,41.4333,20.0167,Al Bahah,ADM2,DISTRICT
SM329,41.4333,20.1667,Al Bahah,ADM1,DISTRICT
SM330,33.7885,27.1862,Al Bahr al Ahmar,ADM1,DISTRICT
SM18294,55.7427,25.2232,Al Batayih,ADM2,DISTRICT
SM331,56.75,24,Al Batinah North,ADM1,DISTRICT
SM332,57.7,23.45,Al Batinah South,ADM1,DISTRICT
SM305012,45.5482,13.9367,Al Bayda,ADM2,DISTRICT
SM305014,45.5566,13.9849,Al Bayda City,ADM2,DISTRICT
SM333,45.3944,14.3417,Al Bayda',ADM1,DISTRICT
SM334,30.2,30.6,Al Buhayrah,ADM1,DISTRICT
SM17725,43.1667,26.4333,Al Bukayriyah,ADM2,DISTRICT
SM304763,44.7762,12.8038,Al Buraiqeh,ADM2,DISTRICT
SM335,56.1167,24.2167,Al Buraymi,ADM1,DISTRICT
SM336,24.0333,29.9333,Al Butnan,ADM1,DISTRICT
SM337,51.45,25.5167,Al Daayen,ADM1,DISTRICT
SM338,44.7625,13.8924,Al Dali',ADM1,DISTRICT
SM304919,43.2753,16.7314,Al Dhaher,ADM2,DISTRICT
SM339,55.95,22.9,Al Dhahira,ADM1,DISTRICT
SM306040,55.9202,25.2695,Al Dhaid,ADM2,DISTRICT
SM304864,44.1526,13.979,Al Dhihar,ADM2,DISTRICT
SM305804,43.8079,34.4619,Al Door,ADM2,DISTRICT
SM305760,43.8,33.3,Al Fallujah,ADM2,DISTRICT
SM340,47.9167,29.25,Al Farwaniyah,ADM1,DISTRICT
SM295236,25.5,15.5,Al Fasher,ADM2,DISTRICT
SM305817,48.2126,30.0935,Al Faw,ADM2,DISTRICT
SM341,30.5,29.3,Al Fayyum,ADM1,DISTRICT
SM18295,56.3328,25.1356,Al Fujairah Municipality,ADM2,DISTRICT
SM295211,36.084,14.079,Al Fushqa,ADM2,DISTRICT
SM305049,43.4094,14.1058,Al Garrahi,ADM2,DISTRICT
SM260506,53.7223,23.6575,Al Gharbia,ADM2,DISTRICT
SM342,31,30.9,Al Gharbiyah,ADM1,DISTRICT
SM17726,44.9,26,Al Ghat,ADM2,DISTRICT
SM305068,52.0816,16.2618,Al Ghaydah,ADM2,DISTRICT
SM305056,44.6852,16.0914,Al Ghayl,ADM2,DISTRICT
SM17727,40.9167,26.1667,Al Ghazalah,ADM2,DISTRICT
SM251523,51.03,24.09,Al Habib,ETHNICITY,ETHNICITY
SM304891,45.2553,13.9783,Al Had,ADM2,DISTRICT
SM304791,44.5679,14.8076,Al Hada,ADM2,DISTRICT
SM305761,42.358,34.1287,Al Haditha,ADM2,DISTRICT
SM305050,43.5823,14.9907,Al Hajjaylah,ADM2,DISTRICT
SM305051,43.0014,14.825,Al Hali,ADM2,DISTRICT
SM305796,43.3737,36.2715,Al Hamdaniyah,ADM2,DISTRICT
SM18296,55.5481,25.4612,Al Hamriyah,ADM2,DISTRICT
SM305829,44.985,31.7222,Al Hamza,ADM2,DISTRICT
SM281455,-7.9108,31.3408,Al Haouz,ADM2,DISTRICT
SM251524,23.6,31.72,Al Haraba,ETHNICITY,ETHNICITY
SM17728,46.1,23.5,Al Hariq,ADM2,DISTRICT
SM17729,43.15,16.8,Al Harth,ADM2,DISTRICT
SM305856,44.75,32.35,Al Hashimiyah,ADM2,DISTRICT
SM304926,44.2738,16.8884,Al Hashwah,ADM2,DISTRICT
SM251525,46.8,13.83,Al Hawaashib,ETHNICITY,ETHNICITY
SM305052,42.9978,14.7601,Al Hawak,ADM2,DISTRICT
SM304934,43.8393,15.2722,Al Haymah Ad Dakhiliyah,ADM2,DISTRICT
SM304942,43.85,15.0267,Al Haymah Al Kharijiyah,ADM2,DISTRICT
SM305811,46,32.2,Al Hayy,ADM2,DISTRICT
SM305060,44.9585,16.0378,Al Hazm,ADM2,DISTRICT
SM305857,44.3628,32.3958,Al Hillah,ADM2,DISTRICT
SM17730,41,24.75,Al Hinakiyah,ADM2,DISTRICT
SM281437,-4.25,35,Al Hoceïma,ADM2,DISTRICT
SM343,43.1928,14.9173,Al Hudaydah,ADM1,DISTRICT
SM344,42.4167,30.25,Al Hudud ash Shamaliyah,ADM1,DISTRICT
SM305061,44.4299,16.4667,Al Humaydat,ADM2,DISTRICT
SM305023,44.4685,13.7495,Al Husha,ADM2,DISTRICT
SM304943,44.5044,15.0206,Al Husn,ADM2,DISTRICT
SM305024,44.8284,13.789,Al Hussein,ADM2,DISTRICT
SM345,29.75,31,Al Iskandariyah,ADM1,DISTRICT
SM346,32.4,30.6,Al Ismailiyah,ADM1,DISTRICT
SM347,21.8167,32.15,Al Jabal al Akhdar,ADM1,DISTRICT
SM348,13,30.4667,Al Jabal al Gharbi,ADM1,DISTRICT
SM295208,32.96,12.95,Al Jabalian,ADM2,DISTRICT
SM304913,43.6196,14.6899,Al Jabin,ADM2,DISTRICT
SM305785,44.2202,32.5441,Al Jadwal al Gharbi,ADM2,DISTRICT
SM304914,43.5817,14.5196,Al Jafariyah,ADM2,DISTRICT
SM349,47.5,29.5,Al Jahrah,ADM1,DISTRICT
SM304858,43.5297,16.0284,Al Jamimah,ADM2,DISTRICT
SM351,45.5243,16.5974,Al Jawf,ADM1,DISTRICT
SM350,39.6,30.05,Al Jawf,ADM1,DISTRICT
SM352,33.35,14.6,Al Jazirah,ADM1,DISTRICT
SM353,12.9167,32.5,Al Jifarah,ADM1,DISTRICT
SM354,31.0529,29.9978,Al Jizah,ADM1,DISTRICT
SM304905,45.2868,15.1249,Al Jubah,ADM2,DISTRICT
SM17731,49.3333,26.9167,Al Jubayl,ADM2,DISTRICT
SM355,17.05,27.7333,Al Jufrah,ADM1,DISTRICT
SM17732,39.9167,21.7833,Al Jumum,ADM2,DISTRICT
SM305788,47.5551,31.6484,Al Kahla,ADM2,DISTRICT
SM17733,39.9,22.4,Al Kamil,ADM2,DISTRICT
SM295166,32.8333,15.25,Al Kamlin,ADM2,DISTRICT
SM305077,43.3677,15.4817,Al Khabt,ADM2,DISTRICT
SM17734,48.1667,28.1667,Al Khafji,ADM2,DISTRICT
SM305778,44.6,34,Al Khalis,ADM2,DISTRICT
SM305062,44.797,16.0681,Al Khalq,ADM2,DISTRICT
SM17735,47.5,23.9167,Al Kharj,ADM2,DISTRICT
SM17736,50.95,19.1333,Al Kharkhir,ADM2,DISTRICT
SM305053,43.2532,13.8114,Al Khawkhah,ADM2,DISTRICT
SM305823,45.591,31.2112,Al Khithir,ADM2,DISTRICT
SM305823,45.591,31.2112,Al Khithir,ADM2,DISTRICT
SM305823,45.6078,31.2108,Al Khithir,ADM2,DISTRICT
SM305823,45.6078,31.2108,Al Khithir,ADM2,DISTRICT
SM356,51.3167,25.7667,Al Khor,ADM1,DISTRICT
SM17737,50.1167,26.2667,Al Khubar,ADM2,DISTRICT
SM17738,42.5333,21.9167,Al Khurmah,ADM2,DISTRICT
SM305831,44.4365,32.0362,Al Kufa,ADM2,DISTRICT
SM357,22.2167,23.7667,Al Kufrah,ADM1,DISTRICT
SM305812,45.8,32.55,Al Kut,ADM2,DISTRICT
SM358,47.9167,29.3333,Al Kuwayt,ADM1,DISTRICT
SM17739,40.3667,20.4667,Al Lith,ADM2,DISTRICT
SM304989,43.9262,13.3742,Al Ma'afer,ADM2,DISTRICT
SM296030,33.75,9.9792,Al Mabien,ADM2,DISTRICT
SM18297,55.7682,24.9554,Al Madam,ADM2,DISTRICT
SM304782,43.639,16.2328,Al Madan,ADM2,DISTRICT
SM304892,43.9906,12.8569,Al Madaribah Wa Al Arah,ADM2,DISTRICT
SM305818,47.2644,30.941,Al Madiana,ADM2,DISTRICT
SM305818,47.2644,30.941,Al Madiana,ADM2,DISTRICT
SM305818,47.2592,31.0246,Al Madiana,ADM2,DISTRICT
SM305818,47.2592,31.0246,Al Madiana,ADM2,DISTRICT
SM359,39.5,24.9,Al Madinah,ADM1,DISTRICT
SM17740,39.6,24.3,Al Madinah al Munawwarah,ADM2,DISTRICT
SM304893,45.1088,13.7767,Al Maflahy,ADM2,DISTRICT
SM304859,43.6091,15.8937,Al Maghrabah,ADM2,DISTRICT
SM304860,43.448,15.9287,Al Mahabishah,ADM2,DISTRICT
SM305858,44.6176,32.6145,Al Mahawil,ADM2,DISTRICT
SM17741,40.75,23.5,Al Mahd,ADM2,DISTRICT
SM304953,46.756,13.9705,Al Mahfad,ADM2,DISTRICT
SM360,51.5,17,Al Mahrah,ADM1,DISTRICT
SM305078,43.5663,15.4117,Al Mahwait,ADM2,DISTRICT
SM305079,43.5463,15.4635,Al Mahwait City,ADM2,DISTRICT
SM361,43.5362,15.392,Al Mahwit,ADM1,DISTRICT
SM251526,21.29,28.71,Al Majabra,ETHNICITY,ETHNICITY
SM17742,41.8167,18.9833,Al Majaridah,ADM2,DISTRICT
SM17743,45.6667,26.4167,Al Majmaah,ADM2,DISTRICT
SM304875,44.2033,14.1397,Al Makhadir,ADM2,DISTRICT
SM305015,45.3851,14.3654,Al Malagim,ADM2,DISTRICT
SM304795,44.1164,14.653,Al Manar,ADM2,DISTRICT
SM305832,44.4116,31.7965,Al Manathera,ADM2,DISTRICT
SM17744,41.2333,20.1833,Al Mandaq,ADM2,DISTRICT
SM304764,44.9724,12.8532,Al Mansura,ADM2,DISTRICT
SM305054,43.3682,14.6903,Al Mansuriyah,ADM2,DISTRICT
SM304894,44.1404,13.1372,Al Maqatirah,ADM2,DISTRICT
SM305055,43.1965,14.8346,Al Marawi'ah,ADM2,DISTRICT
SM362,21.2,31.7833,Al Marj,ADM1,DISTRICT
SM363,14.1667,32.1667,Al Marqab,ADM1,DISTRICT
SM304877,44.17,13.9573,Al Mashannah,ADM2,DISTRICT
SM305069,50.6445,15.6399,Al Masilah,ADM2,DISTRICT
SM305063,44.5667,16.0961,Al Maslub,ADM2,DISTRICT
SM305064,44.3902,16.1984,Al Matammah,ADM2,DISTRICT
SM305065,44.6389,16.2872,Al Maton,ADM2,DISTRICT
SM304994,44.1005,13.3042,Al Mawasit,ADM2,DISTRICT
SM305789,46.8512,31.4934,Al Miamona,ADM2,DISTRICT
SM305789,46.8512,31.4934,Al Miamona,ADM2,DISTRICT
SM305789,46.7333,31.4,Al Miamona,ADM2,DISTRICT
SM305789,46.7333,31.4,Al Miamona,ADM2,DISTRICT
SM17745,44.2667,25.8,Al Midhnab,ADM2,DISTRICT
SM304861,43.5147,15.961,Al Miftah,ADM2,DISTRICT
SM305031,43.1836,15.3111,Al Mighlaf,ADM2,DISTRICT
SM305790,47.2036,31.4294,Al Mijar al Kabir,ADM2,DISTRICT
SM305790,47.2036,31.4294,Al Mijar al Kabir,ADM2,DISTRICT
SM305790,47.2046,31.449,Al Mijar al Kabir,ADM2,DISTRICT
SM305790,47.2046,31.449,Al Mijar al Kabir,ADM2,DISTRICT
SM17746,41.3667,19.7,Al Mikhwah,ADM2,DISTRICT
SM304895,44.9267,13.3202,Al Milah,ADM2,DISTRICT
SM305032,42.9373,14.8078,Al Mina,ADM2,DISTRICT
SM364,31,30.5,Al Minufiyah,ADM1,DISTRICT
SM365,30,28.1,Al Minya,ADM1,DISTRICT
SM305779,44.9362,33.9798,Al Miqdadiyah,ADM2,DISTRICT
SM305859,44.3167,32.7833,Al Misiab,ADM2,DISTRICT
SM304995,44.0377,13.4702,Al Misrakh,ADM2,DISTRICT
SM304765,43.4274,12.6584,Al Mualla,ADM2,DISTRICT
SM304996,43.9927,13.5831,Al Mudhaffar,ADM2,DISTRICT
SM304827,48.9199,14.7292,Al Mukalla,ADM2,DISTRICT
SM304828,49.248,14.6203,Al Mukalla City,ADM2,DISTRICT
SM305033,42.9062,15.3528,Al Munirah,ADM2,DISTRICT
SM251527,51.2,25.05,Al Muraikhat,ETHNICITY,ETHNICITY
SM251528,50.09,23.22,Al Murra,ETHNICITY,ETHNICITY
SM251528,47.3,18.16,Al Murra,ETHNICITY,ETHNICITY
SM251528,50.73,19.27,Al Murra,ETHNICITY,ETHNICITY
SM251528,52.53,21.75,Al Murra,ETHNICITY,ETHNICITY
SM304896,44.5914,13.4291,Al Musaymir,ADM2,DISTRICT
SM17747,46.25,24.1667,Al Muzahimiyah,ADM2,DISTRICT
SM305813,45.4841,32.3611,Al Noamania,ADM2,DISTRICT
SM305813,45.4841,32.3611,Al Noamania,ADM2,DISTRICT
SM305813,45.4866,32.3595,Al Noamania,ADM2,DISTRICT
SM305813,45.4866,32.3595,Al Noamania,ADM2,DISTRICT
SM305762,41.0211,34.2395,Al Qa'im,ADM2,DISTRICT
SM305762,41.0211,34.2395,Al Qa'im,ADM2,DISTRICT
SM305762,41.0576,34.2377,Al Qa'im,ADM2,DISTRICT
SM305762,41.0576,34.2377,Al Qa'im,ADM2,DISTRICT
SM304897,44.5025,13.3275,Al Qabbaytah,ADM2,DISTRICT
SM366,35.35,14.05,Al Qadarif,ADM1,DISTRICT
SM304829,48.9405,17.4287,Al Qaf,ADM2,DISTRICT
SM304784,43.7036,16.3386,Al Qaflah,ADM2,DISTRICT
SM304878,44.03,14.3129,Al Qafr,ADM2,DISTRICT
SM304997,44.0101,13.5849,Al Qahirah,ADM2,DISTRICT
SM367,31.65,30.05,Al Qahirah,ADM1,DISTRICT
SM368,31.25,30.3,Al Qalyubiyah,ADM1,DISTRICT
SM296731,41.1724,36.8507,Al Qamishli,ADM2,DISTRICT
SM305034,43.1378,15.4921,Al Qanawis,ADM2,DISTRICT
SM17748,49.9167,26.6333,Al Qatif,ADM2,DISTRICT
SM304830,48.2146,15.9232,Al Qatn,ADM2,DISTRICT
SM369,43.4667,27.0833,Al Quassim,ADM1,DISTRICT
SM17749,41.3833,19.25,Al Qunfudhah,ADM2,DISTRICT
SM17750,41.3,20.45,Al Qura,ADM2,DISTRICT
SM305016,44.8896,14.605,Al Quraishyah,ADM2,DISTRICT
SM17751,37.75,31.0833,Al Qurayyat,ADM2,DISTRICT
SM305819,47.5,31,Al Qurnah,ADM2,DISTRICT
SM17752,44.6667,23.25,Al Quwayiyah,ADM2,DISTRICT
SM296031,33.0616,11.3141,Al Renk,ADM2,DISTRICT
SM12028,44.4793,33.3216,Al Rusafa,ADM2,DISTRICT
SM12028,44.4793,33.3216,Al Rusafa,ADM2,DISTRICT
SM12028,44.4793,33.3168,Al Rusafa,ADM2,DISTRICT
SM12028,44.4793,33.3168,Al Rusafa,ADM2,DISTRICT
SM251529,43.3,20.91,Al Shalawah,ETHNICITY,ETHNICITY
SM305805,43.22,35.5292,Al Shirkat,ADM2,DISTRICT
SM304970,47.3514,15.0826,Al Talh,ADM2,DISTRICT
SM12029,44.2801,33.6614,Al Tarmia District,ADM2,DISTRICT
SM12029,44.2801,33.6614,Al Tarmia District,ADM2,DISTRICT
SM12029,44.3738,33.6644,Al Tarmia District,ADM2,DISTRICT
SM12029,44.3738,33.6644,Al Tarmia District,ADM2,DISTRICT
SM304879,43.9661,13.9611,Al Udayn,ADM2,DISTRICT
SM370,32.65,25.7,Al Uqsur,ADM1,DISTRICT
SM304954,46.0122,13.7136,Al Wade'a,ADM2,DISTRICT
SM371,28.9,24.8,Al Wadi al Jadid,ADM1,DISTRICT
SM372,21.3833,28.6667,Al Wahat,ADM1,DISTRICT
SM305086,44.1868,15.3352,Al Wahdah,ADM2,DISTRICT
SM17753,36.75,26.4167,Al Wajh,ADM2,DISTRICT
SM373,51.3333,24.85,Al Wakrah,ADM1,DISTRICT
SM304998,43.7298,13.1846,Al Wazi'iyah,ADM2,DISTRICT
SM374,56.6167,20.1667,Al Wusta,ADM1,DISTRICT
SM305820,47.0517,30.1403,Al Zubair,ADM2,DISTRICT
SM17754,41.6667,20.4,Al Aqiq,ADM2,DISTRICT
SM17755,43.0833,17.0333,Al Aridah,ADM2,DISTRICT
SM17756,42.95,17.3833,Al Idabi,ADM2,DISTRICT
SM17757,37.9167,26.5,Al Ula,ADM2,DISTRICT
SM327,40.9,36.5167,Al Ḥasakah,ADM1,DISTRICT
SM294634,50,54.9167,Al'keevskiy rayon,ADM2,DISTRICT
SM293469,63.25,54.8333,Al'menevskiy rayon,ADM2,DISTRICT
SM294645,52.3333,54.8833,Al'met'evsk,ADM2,DISTRICT
SM294650,52.4167,54.9167,Al'met'evskiy rayon,ADM2,DISTRICT
SM294245,55,54,Al'sheyevskiy rayon,ADM2,DISTRICT
SM273936,29.75,30.8833,Al-'Amriyah,ADM2,DISTRICT
SM273964,31.2437,29.5573,Al-'Ayyat,ADM2,DISTRICT
SM273664,30.7414,28.689,Al-'Idwah,ADM2,DISTRICT
SM323,41.3175,33.544,Al-Anbar,ADM1,DISTRICT
SM273778,31.4519,26.9362,Al-Badari,ADM2,DISTRICT
SM273967,31.2579,29.8214,Al-Badrashayn,ADM2,DISTRICT
SM273971,31.057,30.4225,Al-Bajur,ADM2,DISTRICT
SM273882,31.9562,26.2286,Al-Baliyana,ADM2,DISTRICT
SM324,47.4167,30.4167,Al-Basrah,ADM1,DISTRICT
SM10259,20.6262,14.9805,Al-Biher,ADM2,DISTRICT
SM273830,31.0917,31.5991,Al-Burulus,ADM2,DISTRICT
SM305803,44.2012,33.8465,Al-Faris,ADM2,DISTRICT
SM273785,30.8507,28.7974,Al-Fashn,ADM2,DISTRICT
SM273779,31.2044,27.1968,Al-Fath,ADM2,DISTRICT
SM273906,30.9012,29.2944,Al-Fayyum,ADM2,DISTRICT
SM273780,31.3179,26.9033,Al-Ghanayim,ADM2,DISTRICT
SM296738,36.112,35.5969,Al-Haffah,ADM2,DISTRICT
SM273832,31.1113,31.3714,Al-Hamul,ADM2,DISTRICT
SM296729,40.7345,36.2258,Al-Hasakah,ADM2,DISTRICT
SM277988,35.8947,32.0252,Al-Jami'ah,ADM2,DISTRICT
SM273719,31.4227,30.2314,Al-Khankah,ADM2,DISTRICT
SM305855,44.707,33.2575,Al-Mada'in,ADM2,DISTRICT
SM305855,44.707,33.2575,Al-Mada'in,ADM2,DISTRICT
SM305855,44.5856,33.0995,Al-Mada'in,ADM2,DISTRICT
SM305855,44.5856,33.0995,Al-Mada'in,ADM2,DISTRICT
SM273914,31.1338,31.0207,Al-Mahallah al-Kubra,ADM2,DISTRICT
SM273900,30.5087,31.1788,Al-Mahmudiyah,ADM2,DISTRICT
SM296730,41.9016,36.9411,Al-Malikiyah,ADM2,DISTRICT
SM273884,31.7706,26.4272,Al-Manshah,ADM2,DISTRICT
SM273654,31.3973,31.04,Al-Mansurah 2,ADM2,DISTRICT
SM273655,31.9432,31.1688,Al-Manzilah,ADM2,DISTRICT
SM273885,31.5784,26.6548,Al-Maraghah,ADM2,DISTRICT
SM273673,31.3419,30.1457,Al-Marj,ADM2,DISTRICT
SM273673,31.3419,30.1457,Al-Marj,ADM2,DISTRICT
SM273673,31.3344,30.1553,Al-Marj,ADM2,DISTRICT
SM273673,31.3344,30.1553,Al-Marj,ADM2,DISTRICT
SM296783,37.3769,34.8506,Al-Mukharram,ADM2,DISTRICT
SM273941,30.0325,31.2495,Al-Muntazah,ADM2,DISTRICT
SM325,45.3333,30.25,Al-Muthannia,ADM1,DISTRICT
SM326,45.0833,31.9167,Al-Qadisiyah,ADM1,DISTRICT
SM273944,32.2531,30.7604,Al-Qantarah,ADM2,DISTRICT
SM273945,32.3442,30.5448,Al-Qantarah ash-Sharqiyah,ADM2,DISTRICT
SM296784,36.5553,34.5408,Al-Qusayr,ADM2,DISTRICT
SM273781,30.8153,27.3897,Al-Qusiyah,ADM2,DISTRICT
SM296743,36.7738,33.8977,Al-Qutayfah,ADM2,DISTRICT
SM225262,34.9161,31.2844,Al-Sayyid Bedouin Sign Language,LANGUAGE,LANGUAGE
SM4122,50.9876,25.3738,Al-Shahaniya,ADM1,DISTRICT
SM12030,44.7748,32.9245,Al-Suwaira District,ADM2,DISTRICT
SM12031,43.5498,33.9728,Al-Thethar District,ADM2,DISTRICT
SM273969,28.807,28.4062,Al-Wahat al-Bahariyah,ADM2,DISTRICT
SM273730,31.4549,24.6216,Al-Wahat al-Kharijah,ADM2,DISTRICT
SM273786,31.1746,29.3096,Al-Wasta,ADM2,DISTRICT
SM251530,40.07,7.96,Ala,ETHNICITY,ETHNICITY
SM15409,-66.5117,18.181,Ala de la Piedra Barrio,ADM2,DISTRICT
SM280318,71.4631,41.4081,Ala-Buka,ADM2,DISTRICT
SM298099,9.455,35.6521,Alaa,ADM2,DISTRICT
SM274307,38.2119,7.4535,Alaba,ADM2,DISTRICT
SM225176,38.1732,7.3901,Alaba-K'abeena,LANGUAGE,LANGUAGE
SM225173,-94.6285,30.7674,Alabama,LANGUAGE,LANGUAGE
SM375,-86.7503,32.7504,Alabama,ADM1,DISTRICT
SM251532,-86.82,32.5,Alabama,ETHNICITY,ETHNICITY
SM225174,122.014,14.1217,Alabat Island Agta,LANGUAGE,LANGUAGE
SM225178,46.66,43.59,Alabugat Tatar,DIALECT,LANGUAGE
SM298286,34.8497,40.1784,Alaca,ADM2,DISTRICT
SM298353,39.8551,38.4633,Alacakaya,ADM2,DISTRICT
SM251533,-71.38,-53.53,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-71.38,-53.53,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-71.38,-53.53,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-72.27,-51.54,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-72.27,-51.54,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-72.27,-51.54,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-71.38,-53.53,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-71.38,-53.53,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-71.38,-53.53,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-72.27,-51.54,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-72.27,-51.54,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-72.27,-51.54,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-74,-52,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-74,-52,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-74,-52,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-74.52,-49.55,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-74.52,-49.55,Alacaluf,ETHNICITY,ETHNICITY
SM251533,-74.52,-49.55,Alacaluf,ETHNICITY,ETHNICITY
SM225179,-70.1404,-54.788,Alacalufe-Austral,LANGUAGE,LANGUAGE
SM225180,-71.0413,-53.4357,Alacalufe-Central,LANGUAGE,LANGUAGE
SM225181,-98.58,17.25,Alacatlatzala Mixtec,LANGUAGE,LANGUAGE
SM300313,-82.3577,29.6748,Alachua,ADM2,DISTRICT
SM298123,35.402,37.5585,Aladağ,ADM2,DISTRICT
SM283596,100.0429,50.1118,Alag-Erdene,ADM2,DISTRICT
SM293808,44.0833,42.8333,Alagirskiy rayon,ADM2,DISTRICT
SM225183,8.5209,8.3499,Alago,LANGUAGE,LANGUAGE
SM250018,7.24,11.49,Alago,ETHNICITY,ETHNICITY
SM263258,-44.6602,-22.1815,Alagoa,ADM2,DISTRICT
SM264366,-35.5953,-7.0783,Alagoa Grande,ADM2,DISTRICT
SM264377,-35.754,-7.0473,Alagoa Nova,ADM2,DISTRICT
SM376,-36.4167,-9.5833,Alagoas,ADM1,DISTRICT
SM264993,-36.7561,-8.535,Alagoinha,ADM2,DISTRICT
SM264388,-35.5147,-6.9626,Alagoinha,ADM2,DISTRICT
SM265180,-40.9181,-6.9802,Alagoinha do Piauí,ADM2,DISTRICT
SM267747,-38.3615,-12.0058,Alagoinhas,ADM2,DISTRICT
SM225185,35.7231,-4.6415,Alagwa,LANGUAGE,LANGUAGE
SM280332,73.5594,39.8586,Alai,ADM2,DISTRICT
SM270898,-84.2099,10.0284,Alajuela,ADM2,DISTRICT
SM377,-84.5,10.5,Alajuela,ADM1,DISTRICT
SM270970,-84.1116,9.8873,Alajuelita,ADM2,DISTRICT
SM225188,106.597,15.5565,Alak,LANGUAGE,LANGUAGE
SM251534,105.29,16.59,Alak,ETHNICITY,ETHNICITY
SM13153,81.25,46.1667,Alakol District (excl. Uygentas),ADM2,DISTRICT
SM15282,145.821,17.595,Alamagan Island,ADM2,DISTRICT
SM251536,24.3,31.71,Alamamra,ETHNICITY,ETHNICITY
SM301930,-79.3995,36.0441,Alamance,ADM2,DISTRICT
SM267065,-47.8627,-23.5375,Alambari,ADM2,DISTRICT
SM225190,143.316,-4.6631,Alamblak,LANGUAGE,LANGUAGE
SM303163,-121.9179,37.6506,Alameda,ADM2,DISTRICT
SM10605,33.4456,34.8004,Alaminos,ADM2,DISTRICT
SM303329,-105.7883,37.5729,Alamosa,ADM2,DISTRICT
SM10606,33.4066,34.9891,Alampra,ADM2,DISTRICT
SM231695,50.6987,36.4133,Alamuti,LANGUAGE,LANGUAGE
SM280309,74.594,42.8199,Alamüdün,ADM2,DISTRICT
SM289487,-7.366,38.6292,Alandroal,ADM2,DISTRICT
SM4166,19.9336,60.225,Alands landsbygd,ADM1,DISTRICT
SM4167,20.718,60.1758,Alands skaergard,ADM1,DISTRICT
SM225193,121.022,13.0982,Alangan,LANGUAGE,LANGUAGE
SM251538,120.99,13.01,Alangan,ETHNICITY,ETHNICITY
SM286677,-82.6406,8.3984,Alanje,ADM2,DISTRICT
SM299017,32.0231,36.5617,Alanya,ADM2,DISTRICT
SM281480,48.4,-17.9,Alaotra-Mangoro,ADM1:ADM2,DISTRICT
SM299039,31.3866,41.1779,Alaplı,ADM2,DISTRICT
SM225195,-59.9967,-12.3299,Alapmunte,LANGUAGE,LANGUAGE
SM276953,76.5,9.4167,Alappuzha,ADM2,DISTRICT
SM282485,-99.5544,22.0865,Alaquines,ADM2,DISTRICT
SM292880,103,53.3333,Alarskiy rayon,ADM2,DISTRICT
SM297806,125.7868,-9.0273,Alas,ADM2,DISTRICT
SM251539,98.01,3.66,Alas,ETHNICITY,ETHNICITY
SM260061,69.8091,34.9256,Alasay,ADM2,DISTRICT
SM378,-150.0003,64.0003,Alaska,ADM1,DISTRICT
SM10607,32.9237,34.7588,Alassa,ADM2,DISTRICT
SM18366,64.1504,39.2112,Alat,ADM2,DISTRICT
SM16832,46.75,54.9167,Alatyrskiy Rayon,ADM2,DISTRICT
SM275895,-86.6953,13.8402,Alauca,ADM2,DISTRICT
SM273597,-78.85,-2.2,Alausí,ADM2,DISTRICT
SM225203,134.282,-15.5458,Alawa,LANGUAGE,LANGUAGE
SM251541,134.3,-16.68,Alawa,ETHNICITY,ETHNICITY
SM251541,134.3,-16.68,Alawa,ETHNICITY,ETHNICITY
SM251541,134.4796,-15.5237,Alawa,ETHNICITY,ETHNICITY
SM251541,134.4796,-15.5237,Alawa,ETHNICITY,ETHNICITY
SM251542,36,35.25,Alawi,ETHNICITY,ETHNICITY
SM298849,35.6063,41.6156,Alaçam,ADM2,DISTRICT
SM298733,28.5239,38.3606,Alaşehir,ADM2,DISTRICT
SM379,23.5725,46.0682,Alba,ADM1,DISTRICT
SM289797,23.5749,46.0635,Alba Iulia,ADM2,DISTRICT
SM289808,22.9503,46.4525,Albac,ADM2,DISTRICT
SM274062,-2,38.8333,Albacete,ADM2,DISTRICT
SM270300,-77.0685,1.4705,Alban (1992-),ADM2,DISTRICT
SM270864,-75.9537,1.2633,Albania,ADM2,DISTRICT
SM270452,-73.8646,5.7706,Albania,ADM2,DISTRICT
SM10374,-72.5856,11.1597,Albania,ADM2,DISTRICT
SM251543,20.48,39.84,Albanian,ETHNICITY,ETHNICITY
SM251543,20.48,39.84,Albanian,ETHNICITY,ETHNICITY
SM251543,20.48,39.84,Albanian,ETHNICITY,ETHNICITY
SM251543,20.48,39.84,Albanian,ETHNICITY,ETHNICITY
SM251543,20.48,39.84,Albanian,ETHNICITY,ETHNICITY
SM251543,20.0085,41.6665,Albanian,ETHNICITY,ETHNICITY
SM251543,20.0085,41.6665,Albanian,ETHNICITY,ETHNICITY
SM251543,20.0085,41.6665,Albanian,ETHNICITY,ETHNICITY
SM251543,20.0085,41.6665,Albanian,ETHNICITY,ETHNICITY
SM251543,20.0085,41.6665,Albanian,ETHNICITY,ETHNICITY
SM251543,20,42,Albanian,ETHNICITY,ETHNICITY
SM251543,20,42,Albanian,ETHNICITY,ETHNICITY
SM251543,20,42,Albanian,ETHNICITY,ETHNICITY
SM251543,20,42,Albanian,ETHNICITY,ETHNICITY
SM251543,20,42,Albanian,ETHNICITY,ETHNICITY
SM251543,20.17,42,Albanian,ETHNICITY,ETHNICITY
SM251543,20.17,42,Albanian,ETHNICITY,ETHNICITY
SM251543,20.17,42,Albanian,ETHNICITY,ETHNICITY
SM251543,20.17,42,Albanian,ETHNICITY,ETHNICITY
SM251543,20.17,42,Albanian,ETHNICITY,ETHNICITY
SM251543,19.83,41.33,Albanian,ETHNICITY,ETHNICITY
SM251543,19.83,41.33,Albanian,ETHNICITY,ETHNICITY
SM251543,19.83,41.33,Albanian,ETHNICITY,ETHNICITY
SM251543,19.83,41.33,Albanian,ETHNICITY,ETHNICITY
SM251543,19.83,41.33,Albanian,ETHNICITY,ETHNICITY
SM225209,19.8178,41.3289,Albanian Sign Language,LANGUAGE,LANGUAGE
SM303295,-105.7239,41.6545,Albany,ADM2,DISTRICT
SM301867,-73.9736,42.6002,Albany,ADM2,DISTRICT
SM261296,118.1235,-34.7099,Albany,ADM2,DISTRICT
SM260967,-68.4167,-31.2833,Albardón,ADM2,DISTRICT
SM284864,9.1029,11.6624,Albasu,ADM2,DISTRICT
SM380,123.55,13.2167,Albay,ADM1:ADM2,DISTRICT
SM302981,-78.5565,38.0229,Albemarle,ADM2,DISTRICT
SM290608,23.6021,45.0136,Albeni,ADM2,DISTRICT
SM289602,-58.0709,-26.1899,Alberdi,ADM2,DISTRICT
SM261047,-62.75,-26.5,Alberdi,ADM2,DISTRICT
SM289220,-8.504,40.6871,Albergaria-a-Velha,ADM2,DISTRICT
SM268743,-125.3362,49.2496,Alberni-Clayoquot,ADM2,DISTRICT
SM268794,-64.9155,45.8335,Albert,ADM2,DISTRICT
SM12093,-77.5349,18.2973,Albert Town,ADM2,DISTRICT
SM381,-117.469,52.2833,Alberta,ADM1,DISTRICT
SM324069,-114.3478,53.6757,Alberta Beach,ADM2:ADM3,DISTRICT
SM260747,-60.3333,-35,Alberti,ADM2,DISTRICT
SM263269,-46.6208,-22.1991,Albertina,ADM2,DISTRICT
SM303612,-71.7656,8.6086,Alberto Adriani,ADM2,DISTRICT
SM303841,-69.9352,8.7533,Alberto Arvelo Torrealba,ADM2,DISTRICT
SM271659,12.348,55.6802,Albertslund,ADM2,DISTRICT
SM291140,24.8565,46.2438,Albesti,ADM2,DISTRICT
SM290180,28.4033,43.8086,Albesti,ADM2,DISTRICT
SM292547,27.087,47.6839,Albesti,ADM2,DISTRICT
SM291501,26.233,44.9343,Albesti-paleologu,ADM2,DISTRICT
SM291215,24.6667,45.2167,Albestii Pamanteni,ADM2,DISTRICT
SM296073,-54.0567,5.496,Albina,ADM2,DISTRICT
SM282246,-98.5551,18.0128,Albino Zertuche,ADM2,DISTRICT
SM12094,-77.9141,18.4847,Albion (Saint James),ADM2,DISTRICT
SM18554,-76.6076,17.9012,Albion (Saint Thomas),ADM2,DISTRICT
SM285663,4.6632,51.8599,Alblasserdam,ADM2,DISTRICT
SM11747,50.0783,36.1886,Alborz,ADM2,DISTRICT
SM382,50.8096,36.0112,Alborz,ADM1,DISTRICT
SM291226,24.8184,44.7829,Albota,ADM2,DISTRICT
SM285674,4.4083,51.8579,Albrandswaard,ADM2,DISTRICT
SM289501,-8.2614,37.0834,Albufeira,ADM2,DISTRICT
SM268907,9.6515,46.6026,Albula,ADM2,DISTRICT
SM261439,146.9568,-36.0149,Albury,ADM2,DISTRICT
SM269984,-74.4439,4.8943,Albán,ADM2,DISTRICT
SM270605,-75.7719,4.6746,Alcalá,ADM2,DISTRICT
SM289329,-8.6883,39.4675,Alcanena,ADM2,DISTRICT
SM264399,-36.066,-7.6982,Alcantil,ADM2,DISTRICT
SM299554,38.7682,48.5187,Alchevs'ka,ADM2,DISTRICT
SM299554,38.7682,48.5187,Alchevs'ka,ADM2,DISTRICT
SM299554,38.7969,48.4803,Alchevs'ka,ADM2,DISTRICT
SM299554,38.7969,48.4803,Alchevs'ka,ADM2,DISTRICT
SM263032,-53.7581,-18.1996,Alcinópolis,ADM2,DISTRICT
SM267758,-39.374,-17.4637,Alcobaca,ADM2,DISTRICT
SM289242,-8.9883,39.5544,Alcobaça,ADM2,DISTRICT
SM289344,-8.9794,38.7383,Alcochete,ADM2,DISTRICT
SM301251,-83.3437,44.7116,Alcona,ADM2,DISTRICT
SM301437,-88.5803,34.8808,Alcorn,ADM2,DISTRICT
SM289509,-7.6546,37.424,Alcoutim,ADM2,DISTRICT
SM225212,-98.3969,17.3687,Alcozauca Mixtec,LANGUAGE,LANGUAGE
SM281613,-98.3718,17.377,Alcozauca de Guerrero,ADM2,DISTRICT
SM289339,-8.5001,38.3882,Alcácer do Sal,ADM2,DISTRICT
SM262955,-44.5062,-2.3277,Alcântara,ADM2,DISTRICT
SM267920,-40.5552,-3.5873,Alcântaras,ADM2,DISTRICT
SM282648,-98.0312,23.0005,Aldama,ADM2,DISTRICT
SM283222,-105.711,28.9364,Aldama,ADM2,DISTRICT
SM14208,-92.6833,16.9167,Aldama,ADM2,DISTRICT
SM270311,-77.7005,0.8816,Aldana,ADM2,DISTRICT
SM294313,126,59,Aldanskiy rayon,ADM2,DISTRICT
SM14995,96.5315,47.6327,Aldarhaan (excl. Uliastay),ADM2,DISTRICT
SM262966,-43.4628,-4.5852,Aldeias Altas,ADM2,DISTRICT
SM383,-2.1985,49.7168,Alderney,ADM1,DISTRICT
SM12095,-77.2083,18.2871,Alderton,ADM2,DISTRICT
SM248152,166.902,-15.5656,Ale,LANGUAGE,LANGUAGE
SM296567,12.2517,57.9702,Ale,ADM2,DISTRICT
SM231302,37.2737,5.3787,Ale-Gawwada,LANGUAGE,LANGUAGE
SM299325,33.2423,2.2693,Alebtong District,ADM1:ADM2,DISTRICT
SM265939,-54.7594,-27.659,Alecrim,ADM2,DISTRICT
SM283999,-13.7233,16.9645,Aleg,ADM2,DISTRICT
SM225214,9.0039,6.5942,Alege,LANGUAGE,LANGUAGE
SM267987,-41.5194,-20.709,Alegre,ADM2,DISTRICT
SM266018,-55.8926,-29.7535,Alegrete,ADM2,DISTRICT
SM265191,-40.8249,-7.1623,Alegrete do Piauí,ADM2,DISTRICT
SM266029,-54.0586,-27.7997,Alegria,ADM2,DISTRICT
SM295574,-88.4881,13.5091,Alegría,ADM2,DISTRICT
SM304701,-171.4287,-14.0084,Aleipata Itupa i Lalo,ADM2,DISTRICT
SM304702,-171.4635,-14.0343,Aleipata Itupa i Luga,ADM2,DISTRICT
SM251544,33.4,28.64,Aleiqat,ETHNICITY,ETHNICITY
SM284931,4.4279,12.2965,Aleiro,ADM2,DISTRICT
SM270194,-75.0833,6.3667,Alejandría,ADM2,DISTRICT
SM225215,145.387,-6.039,Alekano,LANGUAGE,LANGUAGE
SM295098,117.95,50.9167,Aleksandrovo-Zavodskiy rayon,ADM2,DISTRICT
SM294339,142.3333,51.1667,Aleksandrovsk-Sakhalinskiy rayon,ADM2,DISTRICT
SM294878,38.5833,56.4167,Aleksandrovskiy rayon,ADM2,DISTRICT
SM288844,18.7193,52.7847,Aleksandrów,ADM2,DISTRICT
SM294674,38.6667,50.6333,Alekseevka,ADM2,DISTRICT
SM294901,42.4167,50.25,Alekseevskiy rayon,ADM2,DISTRICT
SM294651,50,55.1667,Alekseevskiy rayon,ADM2,DISTRICT
SM294685,38.8333,50.5833,Alekseevskiy rayon,ADM2,DISTRICT
SM16833,37.0833,54.5,Aleksinskiy Rayon,ADM2,DISTRICT
SM10608,32.6689,34.7032,Alektora,ADM2,DISTRICT
SM289258,-9.0071,39.0649,Alenquer,ADM2,DISTRICT
SM264178,-54.9451,-0.8542,Alenquer,ADM2,DISTRICT
SM384,37.6167,36.25,Aleppo,ADM1,DISTRICT
SM292418,22.4138,47.0877,Alesd,ADM2,DISTRICT
SM277882,8.7,44.8167,Alessandria,ADM2,DISTRICT
SM461349,6.1542,62.4712,Alesund (2020-2024),ADM2,DISTRICT
SM10609,33.4938,34.8645,Alethriko,ADM2,DISTRICT
SM225220,-174.29,52.1228,Aleut,LANGUAGE,LANGUAGE
SM251546,166.17,55.18,Aleut,ETHNICITY,ETHNICITY
SM251546,166.17,55.18,Aleut,ETHNICITY,ETHNICITY
SM251546,166.17,55.18,Aleut,ETHNICITY,ETHNICITY
SM251546,166.17,55.18,Aleut,ETHNICITY,ETHNICITY
SM251546,166.17,55.18,Aleut,ETHNICITY,ETHNICITY
SM251546,-164,54.96,Aleut,ETHNICITY,ETHNICITY
SM251546,-164,54.96,Aleut,ETHNICITY,ETHNICITY
SM251546,-164,54.96,Aleut,ETHNICITY,ETHNICITY
SM251546,-164,54.96,Aleut,ETHNICITY,ETHNICITY
SM251546,-164,54.96,Aleut,ETHNICITY,ETHNICITY
SM251546,-166.79,53.86,Aleut,ETHNICITY,ETHNICITY
SM251546,-166.79,53.86,Aleut,ETHNICITY,ETHNICITY
SM251546,-166.79,53.86,Aleut,ETHNICITY,ETHNICITY
SM251546,-166.79,53.86,Aleut,ETHNICITY,ETHNICITY
SM251546,-166.79,53.86,Aleut,ETHNICITY,ETHNICITY
SM251546,-162.85,55,Aleut,ETHNICITY,ETHNICITY
SM251546,-162.85,55,Aleut,ETHNICITY,ETHNICITY
SM251546,-162.85,55,Aleut,ETHNICITY,ETHNICITY
SM251546,-162.85,55,Aleut,ETHNICITY,ETHNICITY
SM251546,-162.85,55,Aleut,ETHNICITY,ETHNICITY
SM251546,166,55.18,Aleut,ETHNICITY,ETHNICITY
SM251546,166,55.18,Aleut,ETHNICITY,ETHNICITY
SM251546,166,55.18,Aleut,ETHNICITY,ETHNICITY
SM251546,166,55.18,Aleut,ETHNICITY,ETHNICITY
SM251546,166,55.18,Aleut,ETHNICITY,ETHNICITY
SM301172,-163.4167,54.85,Aleutians East,ADM2,DISTRICT
SM301183,-174.2851,52.163,Aleutians West,ADM2,DISTRICT
SM293095,165.9833,55.2167,Aleutskiy rayon,ADM2,DISTRICT
SM10610,32.6076,35.1513,Alevga,ADM2,DISTRICT
SM301942,-81.177,35.921,Alexander,ADM2,DISTRICT
SM300603,-89.3376,37.1916,Alexander,ADM2,DISTRICT
SM327658,-96.0506,50.4833,Alexander,ADM2:ADM3,DISTRICT
SM302992,-77.0469,38.8048,Alexandria,ADM2,DISTRICT
SM291867,25.3327,43.9697,Alexandria,ADM2,DISTRICT
SM12096,-77.3531,18.3041,Alexandria,ADM2,DISTRICT
SM265551,-37.9686,-6.4,Alexandria,ADM2,DISTRICT
SM261705,138.837,-35.3377,Alexandrina,ADM2,DISTRICT
SM290805,26.8545,47.1442,Alexandru I. Cuza,ADM2,DISTRICT
SM289937,27.094,44.2725,Alexandru Odobescu,ADM2,DISTRICT
SM9871,-48.4597,-16.1493,Alexania,ADM2,DISTRICT
SM290915,26.7106,44.6797,Alexeni,ADM2,DISTRICT
SM280906,35.593,33.7757,Aley,ADM2,DISTRICT
SM16834,82.75,52.4167,Aleyskiy Rayon,ADM2,DISTRICT
SM281710,-99.3839,20.4118,Alfajayucan,ADM2,DISTRICT
SM302183,-98.324,36.731,Alfalfa,ADM2,DISTRICT
SM270905,-84.4088,10.2088,Alfaro Ruiz,ADM2,DISTRICT
SM262382,27.3333,43.9,Alfatar,ADM2,DISTRICT
SM263291,-45.9952,-21.3929,Alfenas,ADM2,DISTRICT
SM305096,29.1549,-30.668,Alfred Nzo,ADM2,DISTRICT
SM273431,-79.5407,-1.9449,Alfredo Baquerizo Moreno,ADM2,DISTRICT
SM267998,-40.8237,-20.5425,Alfredo Chaves,ADM2,DISTRICT
SM266433,-51.3952,-21.9295,Alfredo Marconde,ADM2,DISTRICT
SM263302,-43.7111,-21.1424,Alfredo Vasconcelos,ADM2,DISTRICT
SM266421,-49.3407,-27.7048,Alfredo Wagner,ADM2,DISTRICT
SM454426,129,-3,Alfur,ETHNICITY,ETHNICITY
SM289447,-6.9506,41.3495,Alfândega da Fé,ADM2,DISTRICT
SM10375,-74.0616,10.1855,Algarrobo,ADM2,DISTRICT
SM15413,-66.2711,18.0992,Algarrobo Barrio (Aibonito),ADM2,DISTRICT
SM15412,-66.0844,17.9816,Algarrobo Barrio (Guayama),ADM2,DISTRICT
SM15410,-66.4183,18.4481,Algarrobo Barrio (Vega Baja),ADM2,DISTRICT
SM15411,-66.8651,18.0813,Algarrobo Barrio (Yauco),ADM2,DISTRICT
SM15414,-67.1669,18.2297,Algarrobos Barrio,ADM2,DISTRICT
SM270111,-75.282,2.5279,Algeciras,ADM2,DISTRICT
SM301262,-86.5476,46.4511,Alger,ADM2,DISTRICT
SM385,3.0096,36.7405,Alger,ADM1,DISTRICT
SM251083,3.04,36.75,Algeria-Arab,ETHNICITY,ETHNICITY
SM251146,2,31,Algerian,ETHNICITY,ETHNICITY
SM225225,3.2303,35.4208,Algerian Arabic,LANGUAGE,LANGUAGE
SM225226,4.8063,20.8884,Algerian Saharan Arabic,LANGUAGE,LANGUAGE
SM225224,3.5462,36.4762,Algerian Sign Language,LANGUAGE,LANGUAGE
SM225227,8.3287,40.5643,Algherese,DIALECT,LANGUAGE
SM279927,57.25,49.9167,Alginskiy,ADM2,DISTRICT
SM264410,-35.9772,-6.8699,Algodão de Jandaíra,ADM2,DISTRICT
SM268843,-84.4241,47.8836,Algoma,ADM2,DISTRICT
SM251547,-78.67,45.61,Algonkin,ETHNICITY,ETHNICITY
SM251547,-78.67,45.61,Algonkin,ETHNICITY,ETHNICITY
SM251547,-71.1,48.4,Algonkin,ETHNICITY,ETHNICITY
SM251547,-71.1,48.4,Algonkin,ETHNICITY,ETHNICITY
SM225230,-77.5258,47.3876,Algonquin,LANGUAGE,LANGUAGE
SM264421,-34.9284,-7.3504,Alhandra,ADM2,DISTRICT
SM225237,18.0352,4.6001,Ali,LANGUAGE,LANGUAGE
SM251550,54,22.07,Ali Bin Morrah,ETHNICITY,ETHNICITY
SM386,42.94,11.2,Ali Sabieh,ADM1:ADMX,DISTRICT
SM260091,68.8778,36.5301,Ali abad,ADM2,DISTRICT
SM305791,46.7232,32.3961,Ali al Gharbi,ADM2,DISTRICT
SM277734,54.8833,36.8333,Aliabad,ADM2,DISTRICT
SM275793,-87.6864,13.4482,Alianza,ADM2,DISTRICT
SM265004,-35.1654,-7.597,Aliança,ADM2,DISTRICT
SM267211,-48.9586,-11.3286,Aliança do Tocantins,ADM2,DISTRICT
SM298530,27.0438,38.8008,Aliağa,ADM2,DISTRICT
SM387,2.9167,11.3333,Alibori,ADM1,DISTRICT
SM274040,-0.5,38.5,Alicante,ADM2,DISTRICT
SM261592,133.8332,-23.731,Alice Springs,ADM2,DISTRICT
SM4250,72.9693,4.265,Alifu Atholhu,ADM1:ADM2,DISTRICT
SM277279,78.1667,27.8333,Aligarh,ADM2,DISTRICT
SM277555,49.4833,33.0833,Aligudarz,ADM2,DISTRICT
SM289362,-7.4855,41.2845,Alijó,ADM2,DISTRICT
SM292812,46.75,55.75,Alikovskiy rayon,ADM2,DISTRICT
SM290191,27.837,44.1843,Aliman,ADM2,DISTRICT
SM285002,3.2485,6.5889,Alimosho,ADM2,DISTRICT
SM290619,23.7965,45.1054,Alimpesti,ADM2,DISTRICT
SM268581,21.2122,5.2028,Alindao,ADM2,DISTRICT
SM260105,70.4108,34.8175,Alingar,ADM2,DISTRICT
SM296578,12.5,58,Alingsås,ADM2,DISTRICT
SM225241,77.1075,16.8436,Alipur Sign Language,LANGUAGE,LANGUAGE
SM277356,89.528,26.4914,Alipurduar,ADM2,DISTRICT
SM276979,74.3645,22.3138,Alirajpur,ADM2,DISTRICT
SM260106,70.0842,34.8255,Alishing,ADM2,DISTRICT
SM10611,33.0322,34.9579,Alithinou,ADM2,DISTRICT
SM323956,-113.188,52.3968,Alix,ADM2:ADM3,DISTRICT
SM289510,-8.8049,37.3086,Aljezur,ADM2,DISTRICT
SM282257,-97.5055,19.0951,Aljojuca,ADM2,DISTRICT
SM289419,-8.193,37.9056,Aljustrel,ADM2,DISTRICT
SM285310,10.48,9.8898,Alkaleri,ADM2,DISTRICT
SM296064,-55.0201,5.8372,Alkmaar,ADM2,DISTRICT
SM262142,2.128,6.682,Allada,ADM2,DISTRICT
SM225245,-4.4265,5.2237,Alladian,LANGUAGE,LANGUAGE
SM250027,-4.42,5.22,Alladian,ETHNICITY,ETHNICITY
SM277290,81.8839,25.4201,Allahabad (1997-),ADM2,DISTRICT
SM294324,148,71,Allaikhovskiy rayon,ADM2,DISTRICT
SM300806,-91.3781,43.2843,Allamakee,ADM2,DISTRICT
SM225244,76.6593,11.0803,Allar,LANGUAGE,LANGUAGE
SM13397,24.7875,57.0485,Allazu pagasts,ADM2,DISTRICT
SM301273,-85.9103,42.5177,Allegan,ADM2,DISTRICT
SM301878,-78.0276,42.2574,Allegany,ADM2,DISTRICT
SM301214,-78.6989,39.6215,Allegany,ADM2,DISTRICT
SM303003,-80.007,37.7876,Alleghany,ADM2,DISTRICT
SM301953,-81.1272,36.4913,Alleghany,ADM2,DISTRICT
SM302296,-79.9812,40.4688,Allegheny,ADM2,DISTRICT
SM16296,15.2476,-1.3652,Allembe,ADM2,DISTRICT
SM302094,-84.1058,40.7715,Allen,ADM2,DISTRICT
SM300702,-85.0666,41.0909,Allen,ADM2,DISTRICT
SM301119,-92.8279,30.6529,Allen,ADM2,DISTRICT
SM301020,-86.1904,36.7513,Allen,ADM2,DISTRICT
SM300883,-95.3014,37.8857,Allen,ADM2,DISTRICT
SM302454,-81.3582,32.9881,Allendale,ADM2,DISTRICT
SM282173,-100.0065,25.3054,Allende,ADM2,DISTRICT
SM283299,-100.8837,28.324,Allende,ADM2,DISTRICT
SM283233,-105.2879,27.0779,Allende,ADM2,DISTRICT
SM281556,-100.7107,20.9421,Allende,ADM2,DISTRICT
SM225248,-68.0969,-33.4809,Allentiac,LANGUAGE,LANGUAGE
SM251555,-69.05,-28.34,Allentiac,ETHNICITY,ETHNICITY
SM271670,12.3256,55.8586,Allerød,ADM2,DISTRICT
SM12097,-77.2709,17.7904,Alley,ADM2,DISTRICT
SM323923,-111.7851,52.4361,Alliance,ADM2:ADM3,DISTRICT
SM274425,3,46.5,Allier,ADM2,DISTRICT
SM12098,-77.5607,17.8837,Alligator Pond,ADM2,DISTRICT
SM12099,-76.7869,17.983,Allman Town,ADM2,DISTRICT
SM305035,42.8716,15.6363,Alluheyah,ADM2,DISTRICT
SM15415,-66.878,18.064,Almacigo Alto Barrio,ADM2,DISTRICT
SM15416,-66.8666,18.0494,Almacigo Bajo Barrio,ADM2,DISTRICT
SM289345,-9.1496,38.684,Almada,ADM2,DISTRICT
SM267769,-39.6907,-14.6761,Almadina,ADM2,DISTRICT
SM269850,-76.8333,1.9167,Almaguer,ADM2,DISTRICT
SM260277,64.2784,35.956,Almar,ADM2,DISTRICT
SM290484,22.2317,46.2848,Almas,ADM2,DISTRICT
SM267222,-47.2364,-11.4353,Almas,ADM2,DISTRICT
SM291600,23.1333,46.95,Almasu,ADM2,DISTRICT
SM289819,23.1204,46.1088,Almasu Mare,ADM2,DISTRICT
SM279840,76.95,43.25,Almaty (Alma-Ata),ADM1:ADM2,DISTRICT
SM279840,76.95,43.25,Almaty (Alma-Ata),ADM1:ADM2,DISTRICT
SM279840,76.9,43.2667,Almaty (Alma-Ata),ADM1:ADM2,DISTRICT
SM279840,76.9,43.2667,Almaty (Alma-Ata),ADM1:ADM2,DISTRICT
SM3946,77.9167,45,"Almaty Oblast (excl. Almaty City, 1997-)",ADM1,DISTRICT
SM289234,-6.9059,40.7307,Almeida,ADM2,DISTRICT
SM270717,-73.4113,4.9226,Almeida,ADM2,DISTRICT
SM289332,-8.59,39.1578,Almeirim,ADM2,DISTRICT
SM285558,6.6594,52.3446,Almelo,ADM2,DISTRICT
SM263313,-40.7101,-16.1005,Almenara,ADM2,DISTRICT
SM285721,5.2375,52.3686,Almere,ADM2,DISTRICT
SM264189,-53.873,0.4762,Almerim,ADM2,DISTRICT
SM274032,-2.3333,37.1667,Almería,ADM2,DISTRICT
SM265562,-37.7712,-6.1669,Almino Afonso,ADM2,DISTRICT
SM261082,-61.4558,-25.8166,Almirante Brown,ADM2,DISTRICT
SM260758,-58.3333,-34.8333,Almirante Brown,ADM2,DISTRICT
SM15417,-66.3685,18.4153,Almirante Norte Barrio,ADM2,DISTRICT
SM303795,-71.6491,10.954,Almirante Padilla,ADM2,DISTRICT
SM15418,-66.3814,18.3749,Almirante Sur Barrio,ADM2,DISTRICT
SM9872,-52.9114,-28.151,Almirante Tamandare Do Sul,ADM2,DISTRICT
SM264776,-49.3335,-25.2818,Almirante Tamandaré,ADM2,DISTRICT
SM289425,-8.0937,37.4959,Almodôvar,ADM2,DISTRICT
SM275210,-91.4835,14.8112,Almolonga,ADM2,DISTRICT
SM281721,-98.3394,19.7434,Almoloya,ADM2,DISTRICT
SM281919,-99.8642,18.8422,Almoloya de Alquisiras,ADM2,DISTRICT
SM281930,-99.8177,19.4009,Almoloya de Juárez,ADM2,DISTRICT
SM281941,-99.4804,19.1557,Almoloya del Río,ADM2,DISTRICT
SM17167,-60.9616,14.0185,Almondale,ADM2,DISTRICT
SM277343,79.4979,29.6922,Almora (1998-),ADM2,DISTRICT
SM298948,36.9128,40.3864,Almus,ADM2,DISTRICT
SM225251,141.938,-12.5324,Alngith,LANGUAGE,LANGUAGE
SM459391,141.9267,-12.6436,Alngith,ETHNICITY,ETHNICITY
SM304685,-178.1215,-14.2805,Alo,ADM2,DISTRICT
SM389,-178.1188,-14.3066,Alo,ADM1,DISTRICT
SM225254,101.933,23.59,Alo Phola,LANGUAGE,LANGUAGE
SM10612,33.8312,35.2169,Aloda,ADM2,DISTRICT
SM459294,144.8256,-15.6787,Alodja,ETHNICITY,ETHNICITY
SM251556,128.52,-3.15,Aloene,ETHNICITY,ETHNICITY
SM13398,24.8775,57.768,Aloja,ADM2,DISTRICT
SM3992,24.8353,57.8008,Alojas Novads,ADM1,DISTRICT
SM13399,24.8367,57.7782,Alojas pagasts,ADM2,DISTRICT
SM10613,33.0445,34.9296,Alona,ADM2,DISTRICT
SM327895,-99.0508,50.9833,Alonsa,ADM2:ADM3,DISTRICT
SM262748,-66.4189,-18.142,Alonso de Ibáñez,ADM2,DISTRICT
SM276492,124.5667,-8.3,Alor,ADM2,DISTRICT
SM251557,124.78,-8.3,Alor,ETHNICITY,ETHNICITY
SM251557,124.78,-8.3,Alor,ETHNICITY,ETHNICITY
SM251557,124.78,-8.3,Alor,ETHNICITY,ETHNICITY
SM251557,124.26,-8.22,Alor,ETHNICITY,ETHNICITY
SM251557,124.26,-8.22,Alor,ETHNICITY,ETHNICITY
SM251557,124.26,-8.22,Alor,ETHNICITY,ETHNICITY
SM251557,124.67,-8.33,Alor,ETHNICITY,ETHNICITY
SM251557,124.67,-8.33,Alor,ETHNICITY,ETHNICITY
SM251557,124.67,-8.33,Alor,ETHNICITY,ETHNICITY
SM225256,124.264,-8.2038,Alorese,LANGUAGE,LANGUAGE
SM288576,150.0833,-10.25,Alotau,ADM2,DISTRICT
SM275264,-90.8277,14.4649,Alotenango,ADM2,DISTRICT
SM225252,-96.7438,17.4137,Aloápam Zapotec,LANGUAGE,LANGUAGE
SM268044,-49.4499,-17.6941,Aloândia,ADM2,DISTRICT
SM282899,-97.1217,19.1016,Alpatláhuac,ADM2,DISTRICT
SM301284,-83.4604,45.0635,Alpena,ADM2,DISTRICT
SM263324,-42.0014,-18.9838,Alpercata,ADM2,DISTRICT
SM274464,7.2044,43.9131,Alpes-Maritimes,ADM2,DISTRICT
SM274463,6.2654,44.0983,Alpes-de-Haute-Provence,ADM2,DISTRICT
SM266040,-53.0575,-27.2214,Alpestre,ADM2,DISTRICT
SM285923,4.8801,51.5034,Alphen-Chaam,ADM2,DISTRICT
SM289333,-8.5634,39.2388,Alpiarça,ADM2,DISTRICT
SM303174,-119.8207,38.5973,Alpine,ADM2,DISTRICT
SM261216,146.9794,-36.8341,Alpine,ADM2,DISTRICT
SM263336,-46.3794,-20.8182,Alpinópolis,ADM2,DISTRICT
SM281624,-98.51,17.646,Alpoyeca,ADM2,DISTRICT
SM12100,-77.509,18.355,Alps,ADM2,DISTRICT
SM298407,30.9702,39.7789,Alpu,ADM2,DISTRICT
SM270552,-74.9327,3.3922,Alpujarra,ADM2,DISTRICT
SM271139,-82.6008,22.7524,Alquízar,ADM2,DISTRICT
SM251558,4.11,48.61,Alsatians,ETHNICITY,ETHNICITY
SM251559,-123.82,44.63,Alsea,ETHNICITY,ETHNICITY
SM251559,-123.82,44.63,Alsea,ETHNICITY,ETHNICITY
SM251559,-123.82,44.63,Alsea,ETHNICITY,ETHNICITY
SM251559,-123.82,44.63,Alsea,ETHNICITY,ETHNICITY
SM251559,-124,44,Alsea,ETHNICITY,ETHNICITY
SM251559,-124,44,Alsea,ETHNICITY,ETHNICITY
SM251559,-124,44,Alsea,ETHNICITY,ETHNICITY
SM251559,-124,44,Alsea,ETHNICITY,ETHNICITY
SM251559,-123.84,44.42,Alsea,ETHNICITY,ETHNICITY
SM251559,-123.84,44.42,Alsea,ETHNICITY,ETHNICITY
SM251559,-123.84,44.42,Alsea,ETHNICITY,ETHNICITY
SM251559,-123.84,44.42,Alsea,ETHNICITY,ETHNICITY
SM251559,-124,44.7,Alsea,ETHNICITY,ETHNICITY
SM251559,-124,44.7,Alsea,ETHNICITY,ETHNICITY
SM251559,-124,44.7,Alsea,ETHNICITY,ETHNICITY
SM251559,-124,44.7,Alsea,ETHNICITY,ETHNICITY
SM225263,-123.94,44.408,Alsea-Yaquina,LANGUAGE,LANGUAGE
SM286056,12.5684,65.9756,Alstahaug,ADM2,DISTRICT
SM12101,-77.4347,18.1764,Alston,ADM2,DISTRICT
SM3951,21.5544,56.9756,Alsungas Novads,ADM1,DISTRICT
SM13400,21.5554,56.975,Alsungas novads,ADM2,DISTRICT
SM13401,26.8825,57.4286,Alsviku pagasts,ADM2,DISTRICT
SM286336,23.2963,69.9768,Alta,ADM2,DISTRICT
SM263147,-56.3029,-10.02,Alta Floresta,ADM2,DISTRICT
SM266073,-62.1371,-12.4711,Alta Floresta d'Oeste,ADM2,DISTRICT
SM390,-90,15.6667,Alta Verapaz,ADM1,DISTRICT
SM285480,-85.5417,11.4798,Altagracia,ADM2,DISTRICT
SM283579,92.2869,45.7957,Altai,ADM2,DISTRICT
SM283541,94.9167,44.6138,Altai,ADM2,DISTRICT
SM283684,89.5149,48.2936,Altai,ADM2,DISTRICT
SM251561,85.95,51.9,Altai,ETHNICITY,ETHNICITY
SM266444,-49.1022,-20.5389,Altair,ADM2,DISTRICT
SM282659,-98.0695,22.5251,Altamira,ADM2,DISTRICT
SM271816,-70.8333,19.7,Altamira,ADM2,DISTRICT
SM270112,-75.8045,2.055,Altamira,ADM2,DISTRICT
SM264200,-53.6582,-5.8789,Altamira,ADM2,DISTRICT
SM262977,-45.4615,-4.1407,Altamira do Maranhão,ADM2,DISTRICT
SM264789,-52.7074,-24.8126,Altamira do Paran,ADM2,DISTRICT
SM283145,-92.0098,16.6987,Altamirano,ADM2,DISTRICT
SM283698,106.4019,47.6894,Altanbulag,ADM2,DISTRICT
SM14996,106.4991,50.3071,Altanbulag,ADM2,DISTRICT
SM267931,-39.7274,-6.9874,Altaneira,ADM2,DISTRICT
SM283803,110.4668,45.5244,Altanshiree,ADM2,DISTRICT
SM283690,90.4618,49.0481,Altantsögts,ADM2,DISTRICT
SM282583,-111.9214,30.9835,Altar,ADM2,DISTRICT
SM269350,88.1167,47.8667,Altay,ADM2,DISTRICT
SM391,83,52.5,Altay,ADM1,DISTRICT
SM293204,91.5,53.5,Altayskiy rayon,ADM2,DISTRICT
SM292978,85.4167,51.8333,Altayskiy rayon,ADM2,DISTRICT
SM15111,4.9459,51.7656,Altena,ADM2,DISTRICT
SM282268,-97.3039,18.3721,Altepexi,ADM2,DISTRICT
SM289285,-7.7443,39.231,Alter do Chão,ADM2,DISTRICT
SM263347,-46.1869,-21.219,Alterosa,ADM2,DISTRICT
SM18018,27.8864,39.641,Altieyluel Ilcesi,ADM2,DISTRICT
SM291718,24.4614,45.9553,Altina,ADM2,DISTRICT
SM265015,-36.0816,-8.4515,Altinho,ADM2,DISTRICT
SM18019,37.7957,40.9488,Altinordu,ADM2,DISTRICT
SM266455,-47.398,-21.0041,Altinópolis,ADM2,DISTRICT
SM266466,-50.196,-21.6315,Alto Alegre,ADM2,DISTRICT
SM266124,-62.768,3.0309,Alto Alegre,ADM2,DISTRICT
SM266051,-52.9818,-28.7975,Alto Alegre,ADM2,DISTRICT
SM262988,-44.4197,-4.2029,Alto Alegre do Maranho,ADM2,DISTRICT
SM262999,-46.0387,-3.8359,Alto Alegre do Pindaré,ADM2,DISTRICT
SM266084,-61.9797,-12.756,Alto Alegre dos Parecis,ADM2,DISTRICT
SM263158,-53.4059,-17.4844,Alto Araguaia,ADM2,DISTRICT
SM269923,-77.0698,5.6046,Alto Baudó,ADM2,DISTRICT
SM266140,-51.9315,-27.41,Alto Bela Vista,ADM2,DISTRICT
SM9873,-51.746,-11.8283,Alto Boa Vista (2000-),ADM2,DISTRICT
SM263358,-41.871,-20.4467,Alto Caparaó,ADM2,DISTRICT
SM260372,15.9167,-7.8333,Alto Cauale,ADM2,DISTRICT
SM266062,-51.3007,-29.3644,Alto Feliz,ADM2,DISTRICT
SM263180,-53.6246,-16.8196,Alto Garças,ADM2,DISTRICT
SM268055,-49.435,-14.2024,Alto Horizonte,ADM2,DISTRICT
SM263369,-41.9511,-20.437,Alto Jequitibá,ADM2,DISTRICT
SM265202,-42.0661,-5.4079,Alto Longá,ADM2,DISTRICT
SM282910,-96.5594,19.7459,Alto Lucero de Gutiérrez Barrios,ADM2,DISTRICT
SM283876,37.6638,-15.6493,Alto Molocue,ADM2,DISTRICT
SM303555,-64.8303,2.7346,Alto Orinoco,ADM2,DISTRICT
SM263191,-56.7211,-14.7685,Alto Paraguai,ADM2,DISTRICT
SM392,-59.1667,-20.5,Alto Paraguay,ADM1,DISTRICT
SM264800,-52.312,-23.0678,Alto Paraná,ADM2,DISTRICT
SM393,-54.8333,-25.5,Alto Paraná,ADM1,DISTRICT
SM266095,-63.5872,-9.66,Alto Paraíso,ADM2,DISTRICT
SM268066,-47.459,-14.182,Alto Paraíso de Goiás,ADM2,DISTRICT
SM263010,-46.139,-9.5348,Alto Parnaiba,ADM2,DISTRICT
SM264811,-53.3466,-24.1297,Alto Piquiri,ADM2,DISTRICT
SM268009,-40.9914,-19.0325,Alto Rio Novo,ADM2,DISTRICT
SM263380,-43.4054,-21.0193,Alto Rio doce,ADM2,DISTRICT
SM15419,-67.0186,18.2691,Alto Sano Barrio (Las Marías),ADM2,DISTRICT
SM15420,-67.0304,18.2979,Alto Sano Barrio (San Sebastián),ADM2,DISTRICT
SM267942,-38.2092,-5.5241,Alto Santo,ADM2,DISTRICT
SM263202,-53.2801,-17.7662,Alto Taquari,ADM2,DISTRICT
SM15318,-55.7421,-26.5883,Alto Vera (1996-),ADM2,DISTRICT
SM260358,23.0211,-12.2799,Alto Zambeze,ADM2,DISTRICT
SM265573,-36.7968,-5.349,Alto do Rodrigues,ADM2,DISTRICT
SM289527,-57.2217,-25.2459,Altos,ADM2,DISTRICT
SM9874,-42.4504,-5.1609,Altos (1997-),ADM2,DISTRICT
SM270692,-74.2301,8.7389,Altos del Rosario,ADM2,DISTRICT
SM282921,-97.1368,19.7776,Altotonga,ADM2,DISTRICT
SM298793,34.3607,38.0004,Altunhisar,ADM2,DISTRICT
SM18367,71.5,40.3833,Altyarik,ADM2,DISTRICT
SM279847,64.5,53.1667,Altynsarinskiy,ADM2,DISTRICT
SM282691,-97.8047,19.4073,Altzayanca,ADM2,DISTRICT
SM264822,-53.9685,-23.8865,Altônia,ADM2,DISTRICT
SM298893,32.9702,40.0011,Altındağ,ADM2,DISTRICT
SM298691,32.8745,38.3179,Altınekin,ADM2,DISTRICT
SM299004,29.5161,40.7048,Altınova,ADM2,DISTRICT
SM298697,30.1205,39.0693,Altıntaş,ADM2,DISTRICT
SM298931,36.7568,39.2823,Altınyayla,ADM2,DISTRICT
SM298237,29.5505,37.0072,Altınyayla,ADM2,DISTRICT
SM298455,36.2469,36.1154,Altınözü,ADM2,DISTRICT
SM225274,76.7995,11.3456,Alu Kurumba,LANGUAGE,LANGUAGE
SM229598,142.248,-3.3884,Alu-Sinagen,LANGUAGE,LANGUAGE
SM275914,-87.4699,13.7823,Alubarén,ADM2,DISTRICT
SM298429,38.7653,40.3192,Alucra,ADM2,DISTRICT
SM225273,103,23,Alugu,LANGUAGE,LANGUAGE
SM13402,27.0666,57.4263,Aluksne,ADM2,DISTRICT
SM4022,27,57.4167,Aluksnes Novads,ADM1,DISTRICT
SM454425,-55.75,3.5,Aluku,ETHNICITY,ETHNICITY
SM260915,-71,-39.1667,Aluminé,ADM2,DISTRICT
SM225276,8.5596,9.0151,Alumu-Tesu,LANGUAGE,LANGUAGE
SM266477,-47.2837,-23.5226,Alumínio,ADM2,DISTRICT
SM225282,128.394,-3.1148,Alune,LANGUAGE,LANGUAGE
SM291512,25.8966,45.2011,Alunis,ADM2,DISTRICT
SM291151,24.8303,46.8892,Alunis,ADM2,DISTRICT
SM290089,23.7437,47.056,Alunis,ADM2,DISTRICT
SM291388,24.559,44.7181,Alunisu,ADM2,DISTRICT
SM292172,23.8176,45.0095,Alunu,ADM2,DISTRICT
SM225283,102,26,Aluo,LANGUAGE,LANGUAGE
SM225285,31.0016,2.3621,Alur,LANGUAGE,LANGUAGE
SM250028,30.9,2.49,Alur,ETHNICITY,ETHNICITY
SM250028,30.9,2.49,Alur,ETHNICITY,ETHNICITY
SM250028,31,2,Alur,ETHNICITY,ETHNICITY
SM250028,31,2,Alur,ETHNICITY,ETHNICITY
SM299879,34.4147,44.6726,Alushtyns'ka,ADM2,DISTRICT
SM11159,27.3839,59.1379,Alutaguse vald,ADM2,DISTRICT
SM225286,165.653,60.3844,Alutor,LANGUAGE,LANGUAGE
SM12102,-77.3172,18.2968,Alva,ADM2,DISTRICT
SM289250,-8.3886,39.8195,Alvaiázere,ADM2,DISTRICT
SM282730,-95.8503,18.8,Alvarado,ADM2,DISTRICT
SM270913,-83.8035,9.9333,Alvarado,ADM2,DISTRICT
SM270563,-74.9781,4.5883,Alvarado,ADM2,DISTRICT
SM263391,-41.6814,-19.3987,Alvarenga,ADM2,DISTRICT
SM283325,-99.2198,19.375,Alvaro Obregón,ADM2,DISTRICT
SM267304,-64.9512,-3.2009,Alvarães,ADM2,DISTRICT
SM286355,10.6348,62.1085,Alvdal,ADM2,DISTRICT
SM15292,5.2835,60.6719,Alver,ADM2,DISTRICT
SM296667,14.4749,56.8466,Alvesta,ADM2,DISTRICT
SM266521,-49.7624,-22.4567,Alvinlândia,ADM2,DISTRICT
SM263402,-43.154,-20.1208,Alvinópolis,ADM2,DISTRICT
SM225290,50.3794,35.0075,Alviri-Vidari,LANGUAGE,LANGUAGE
SM289426,-8.0504,38.2465,Alvito,ADM2,DISTRICT
SM267233,-49.1061,-12.4179,Alvorada,ADM2,DISTRICT
SM265607,-51.0372,-29.989,Alvorada,ADM2,DISTRICT
SM266106,-62.5015,-11.2993,Alvorada d'Oeste,ADM2,DISTRICT
SM263413,-43.37,-18.7953,Alvorada de Minas,ADM2,DISTRICT
SM265224,-43.8324,-8.3855,Alvorada do Gurguéia,ADM2,DISTRICT
SM268077,-46.6413,-14.5242,Alvorada do Norte,ADM2,DISTRICT
SM264833,-51.2742,-22.8093,Alvorada do Sul,ADM2,DISTRICT
SM277174,76.5,27.5,Alwar,ADM2,DISTRICT
SM269255,102.8458,40.4809,Alxa,ADM2,DISTRICT
SM225292,136.835,-21.0427,Alyawarr,LANGUAGE,LANGUAGE
SM394,24.1667,54.1667,Alytaus,ADM1,DISTRICT
SM14134,24.05,54.4,Alytaus rajonas (excl. Alytus City),ADM2,DISTRICT
SM14135,24.05,54.4,Alytus,ADM2,DISTRICT
SM263280,-42.7574,-21.8094,Além Paraíba,ADM2,DISTRICT
SM225311,141.658,-4.2019,Ama (Papua New Guinea),LANGUAGE,LANGUAGE
SM225320,29.4332,12.0993,Ama (Sudan),LANGUAGE,LANGUAGE
SM12869,136.8037,35.1884,Ama-Shi,ADM2,DISTRICT
SM12870,136.8231,35.1717,Ama-gun,ADM2,DISTRICT
SM281798,-103.6135,19.9878,Amacueca,ADM2,DISTRICT
SM282098,-99.3725,18.6006,Amacuzac,ADM2,DISTRICT
SM10221,15.1041,4.8218,Amada-Gaza,ADM2,DISTRICT
SM303185,-120.6511,38.4464,Amador,ADM2,DISTRICT
SM289266,-9.2234,38.7557,Amadora,ADM2,DISTRICT
SM278359,135.4118,34.7354,Amagasaki,ADM2,DISTRICT
SM270205,-75.7078,6.0403,Amagá,ADM2,DISTRICT
SM225296,128.939,-3.3203,Amahai,LANGUAGE,LANGUAGE
SM225297,-72.3208,-10.2012,Amahuaca,LANGUAGE,LANGUAGE
SM251563,-72.59,-9.8,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72.59,-9.8,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72.59,-9.8,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72.59,-9.8,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72.25,-10.3335,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72.25,-10.3335,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72.25,-10.3335,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72.25,-10.3335,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72,-10,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72,-10,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72,-10,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72,-10,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72.25,-10.33,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72.25,-10.33,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72.25,-10.33,Amahuaca,ETHNICITY,ETHNICITY
SM251563,-72.25,-10.33,Amahuaca,ETHNICITY,ETHNICITY
SM225298,145.368,-5.1874,Amaimon,LANGUAGE,LANGUAGE
SM266131,-62.5276,3.7809,Amajari,ADM2,DISTRICT
SM305114,30.1354,-27.7356,Amajuba,ADM2,DISTRICT
SM395,29.9167,-1.5833,Amajyaruguru,ADM1:ADMX,DISTRICT
SM396,29.6667,-2.3333,Amajyepfo,ADM1:ADMX,DISTRICT
SM458011,130.1501,32.3852,Amakusa,ADM2,DISTRICT
SM225300,141.995,-4.0452,Amal,LANGUAGE,LANGUAGE
SM270216,-74.9679,7.0261,Amalfi,ADM2,DISTRICT
SM225303,146.92,-7.9,Amam,LANGUAGE,LANGUAGE
SM263043,-54.9579,-23.1271,Amambai,ADM2,DISTRICT
SM397,-55.95,-22.8333,Amambay,ADM1,DISTRICT
SM278513,129.5022,28.3454,Amami,ADM2,DISTRICT
SM225304,129.359,28.2684,Amami O Shima Sign Language,LANGUAGE,LANGUAGE
SM225309,141.178,-3.5152,Amanab,LANGUAGE,LANGUAGE
SM281952,-99.9996,19.2621,Amanalco,ADM2,DISTRICT
SM398,44.221,15.4504,Amanat Al Asimah,ADM1,DISTRICT
SM251565,-48.95,-3.83,Amanaye,ETHNICITY,ETHNICITY
SM225310,-48.6613,-3.7977,Amanayé,LANGUAGE,LANGUAGE
SM270992,-77.5759,20.8199,Amancio,ADM2,DISTRICT
SM13154,65.2162,50.1619,Amangeldi Audany (excl. Amantoghay),ADM2,DISTRICT
SM251566,115.32,-30.27,Amangu,ETHNICITY,ETHNICITY
SM251566,115.32,-30.27,Amangu,ETHNICITY,ETHNICITY
SM251566,115.5298,-29.4733,Amangu,ETHNICITY,ETHNICITY
SM251566,115.5298,-29.4733,Amangu,ETHNICITY,ETHNICITY
SM274853,-1.7507,6.2542,Amansie Central,ADM2,DISTRICT
SM274855,-1.8833,6.4667,Amansie West,ADM2,DISTRICT
SM13155,65.5,50.5,Amantoghay Audany,ADM2,DISTRICT
SM275794,-87.6186,13.3251,Amapala,ADM2,DISTRICT
SM264444,-52.8381,-23.1363,Amapora,ADM2,DISTRICT
SM267288,-50.8437,1.8571,Amapá,ADM2,DISTRICT
SM399,-52,1,Amapá,ADM1,DISTRICT
SM262804,-45.9299,-1.6947,Amapá do Maranho,ADM2,DISTRICT
SM225315,148.68,-5.5578,Amara,LANGUAGE,LANGUAGE
SM290926,27.3341,44.6179,Amara,ADM2,DISTRICT
SM264845,-35.4899,-8.3719,Amaraji,ADM2,DISTRICT
SM225317,-70.5533,-12.4931,Amarakaeri,LANGUAGE,LANGUAGE
SM265618,-52.2996,-30.8077,Amaral Ferrador,ADM2,DISTRICT
SM268088,-49.6321,-13.8139,Amaralina,ADM2,DISTRICT
SM289300,-8.065,41.2705,Amarante,ADM2,DISTRICT
SM265235,-42.7881,-6.3702,Amarante,ADM2,DISTRICT
SM262815,-46.6411,-5.5694,Amarante do Maranhão,ADM2,DISTRICT
SM250029,35.83,20.22,Amarar,ETHNICITY,ETHNICITY
SM250029,35.83,20.22,Amarar,ETHNICITY,ETHNICITY
SM250029,36,20,Amarar,ETHNICITY,ETHNICITY
SM250029,36,20,Amarar,ETHNICITY,ETHNICITY
SM225316,123.9637,-10.2175,Amarasi,LANGUAGE,LANGUAGE
SM292183,24.1438,44.7611,Amarasti,ADM2,DISTRICT
SM290348,24.1608,43.9261,Amarastii De Jos,ADM2,DISTRICT
SM251568,41.72,32.87,Amarat,ETHNICITY,ETHNICITY
SM289433,-8.3556,41.6472,Amares,ADM2,DISTRICT
SM10614,32.584,34.8191,Amargeti,ADM2,DISTRICT
SM267365,-39.6072,-13.0497,Amargosa,ADM2,DISTRICT
SM251569,144.29,-4.62,Amarken,ETHNICITY,ETHNICITY
SM274322,37.8333,5.8333,Amaro,ADM2,DISTRICT
SM289852,26.5999,44.9377,Amaru,ADM2,DISTRICT
SM298180,32.3946,41.7379,Amasra,ADM2,DISTRICT
SM400,35.8867,40.7262,Amasya,ADM1,DISTRICT
SM4024,25.3156,57.1017,Amatas Novads,ADM1,DISTRICT
SM13403,25.3197,57.1666,Amatas pagasts,ADM2,DISTRICT
SM283167,-92.1167,15.4333,Amatenango de la Frontera,ADM2,DISTRICT
SM283178,-92.4333,16.5333,Amatenango del Valle,ADM2,DISTRICT
SM281963,-100.2906,18.7048,Amatepec,ADM2,DISTRICT
SM305097,27.4592,-32.559,Amathole,ADM2,DISTRICT
SM282741,-95.6839,18.4337,Amatitlán,ADM2,DISTRICT
SM275437,-90.621,14.4585,Amatitlán,ADM2,DISTRICT
SM282752,-96.8812,18.8571,Amatitlán de los Reyes,ADM2,DISTRICT
SM281809,-103.7244,20.8579,Amatitán,ADM2,DISTRICT
SM282763,-97.6913,21.3114,Amatlan Tuxpan,ADM2,DISTRICT
SM225321,-96.4003,16.2707,Amatlán Zapotec,LANGUAGE,LANGUAGE
SM282144,-104.4251,20.8423,Amatlán de Cañas,ADM2,DISTRICT
SM267315,-68.227,-3.3893,Amaturá,ADM2,DISTRICT
SM283156,-92.8167,17.3667,Amatán,ADM2,DISTRICT
SM282702,-98.1815,19.3621,Amaxac de Guerrero,ADM2,DISTRICT
SM402,-71.5,-1.1667,Amazonas,ADM1,DISTRICT
SM401,-64.5,-3.75,Amazonas,ADM1,DISTRICT
SM404,-66,3.5,Amazonas,ADM1,DISTRICT
SM403,-78.2333,-5,Amazonas,ADM1,DISTRICT
SM225327,166.498,-11.2958,Amba (Solomon Islands),LANGUAGE,LANGUAGE
SM225324,30.0014,0.8121,Amba (Uganda),LANGUAGE,LANGUAGE
SM260425,15.4936,-8.2533,Ambaca,ADM2,DISTRICT
SM225326,136.526,-1.8813,Ambai,LANGUAGE,LANGUAGE
SM225330,144.285,-4.2318,Ambakich,LANGUAGE,LANGUAGE
SM276844,76.9422,30.3285,Ambala (1994-),ADM2,DISTRICT
SM225328,120.283,14.8156,Ambala Ayta,LANGUAGE,LANGUAGE
SM370976,46.6802,-21.9201,Ambalavao,ADM2:ADM3,DISTRICT
SM270574,-74.7961,4.8328,Ambalema,ADM2,DISTRICT
SM370958,48.4656,-13.8695,Ambanja,ADM2:ADM3,DISTRICT
SM273566,-78.6333,-1.25,Ambato,ADM2,DISTRICT
SM260994,-65.9167,-28,Ambato,ADM2,DISTRICT
SM370993,46.4755,-16.6707,Ambato-Boina,ADM2:ADM3,DISTRICT
SM370967,46.7867,-20.4566,Ambatofinandrahana,ADM2:ADM3,DISTRICT
SM370952,47.5501,-19.4618,Ambatolampy,ADM2:ADM3,DISTRICT
SM370999,45.5705,-17.7453,Ambatomainty,ADM2:ADM3,DISTRICT
SM371011,48.444,-17.8909,Ambatondrazaka,ADM2:ADM3,DISTRICT
SM277301,82.6976,26.4054,Ambedkar Nagar,ADM2,DISTRICT
SM225334,127.196,-3.8592,Ambelau,LANGUAGE,LANGUAGE
SM225333,9.6203,5.9404,Ambele,LANGUAGE,LANGUAGE
SM13404,26.89,56.0384,Ambelu pagasts,ADM2,DISTRICT
SM405,124.2931,-9.3136,Ambeno,ADM1,DISTRICT
SM225332,137.9037,-1.7634,Ambermo,LANGUAGE,LANGUAGE
SM370959,49.0514,-13.2026,Ambilobe,ADM2:ADM3,DISTRICT
SM225336,166.95,-15.5169,Amblong,LANGUAGE,LANGUAGE
SM225339,10.7798,7.0764,Ambo,LANGUAGE,LANGUAGE
SM286728,-76.1667,-10.1667,Ambo,ADM2,DISTRICT
SM251577,146.69,-7.8,Ambo,ETHNICITY,ETHNICITY
SM251578,31.29,-13.64,Ambo (Lala-bisa),ETHNICITY,ETHNICITY
SM225341,-76.1135,-10.5138,Ambo-Pasco Quechua,LANGUAGE,LANGUAGE
SM371032,46.3843,-24.672,Amboasary-Sud,ADM2:ADM3,DISTRICT
SM370939,47.4361,-18.8179,Ambohidratrimo,ADM2:ADM3,DISTRICT
SM370977,47.1517,-21.0681,Ambohimahasoa,ADM2:ADM3,DISTRICT
SM260438,14.4073,-10.8983,Amboim,ADM2,DISTRICT
SM276462,128.1833,-3.7,Ambon,ADM2,DISTRICT
SM251579,128.18,-3.71,Ambonese,ETHNICITY,ETHNICITY
SM225340,128.184,-3.7134,Ambonese Malay,LANGUAGE,LANGUAGE
SM370968,47.2559,-20.5495,Ambositra,ADM2:ADM3,DISTRICT
SM371028,46.087,-25.177,Ambovombe-Androy,ADM2:ADM3,DISTRICT
SM225344,142.56,-3.61,Ambrak,LANGUAGE,LANGUAGE
SM260304,13.4808,-7.9305,Ambriz,ADM2,DISTRICT
SM274824,43.1,42.5667,Ambrolauri,ADM2,DISTRICT
SM260380,14.5,-7.6667,Ambuila,ADM2,DISTRICT
SM225349,143.059,-3.7801,Ambulas,LANGUAGE,LANGUAGE
SM288626,142.3333,-4.3333,Ambunti-Dreikikir,ADM2,DISTRICT
SM304831,47.9286,15.3486,Amd,ADM2,DISTRICT
SM225350,20.8912,14.7528,Amdang,LANGUAGE,LANGUAGE
SM10260,22.7336,16.0528,Amdjarass,ADM2,DISTRICT
SM251580,90.64,44.21,Amdo,ETHNICITY,ETHNICITY
SM225351,100.485,34.5177,Amdo Tibetan,LANGUAGE,LANGUAGE
SM297976,9.04,36.8084,Amdoun,ADM2,DISTRICT
SM282447,-100.1096,20.1603,Amealco de Bonfin,ADM2,DISTRICT
SM281820,-104.0508,20.5357,Ameca,ADM2,DISTRICT
SM281974,-98.7656,19.1178,Amecameca,ADM2,DISTRICT
SM305774,43.4883,37.0934,Amedi,ADM2,DISTRICT
SM260769,-62.4672,-34.8389,Ameghino,ADM2,DISTRICT
SM285738,5.7828,53.4502,Ameland,ADM2,DISTRICT
SM225353,145.687,-5.2913,Amele,LANGUAGE,LANGUAGE
SM303014,-77.9761,37.336,Amelia,ADM2,DISTRICT
SM251581,-49.68,65.03,Ameralik,ETHNICITY,ETHNICITY
SM17168,-60.9322,14.0209,Americ,ADM2,DISTRICT
SM225357,-81.6121,33.8117,American Sign Language,LANGUAGE,LANGUAGE
SM266532,-47.2901,-22.7141,Americana,ADM2,DISTRICT
SM268099,-49.9941,-16.265,Americano do Brazil,ADM2,DISTRICT
SM285606,5.3895,52.1738,Amersfoort,ADM2,DISTRICT
SM277312,81.6944,26.2081,Amethi,ADM2,DISTRICT
SM265629,-53.187,-27.367,Ametista do Sul,ADM2,DISTRICT
SM251583,-77.14,18.14,Ameyao,ETHNICITY,ETHNICITY
SM225365,121.042,16.9016,Amganad Ifugao,LANGUAGE,LANGUAGE
SM406,38.5,11.5,Amhara,ADM1,DISTRICT
SM250033,38.09,12.03,Amhara,ETHNICITY,ETHNICITY
SM250033,38.09,12.03,Amhara,ETHNICITY,ETHNICITY
SM250033,38.09,12.03,Amhara,ETHNICITY,ETHNICITY
SM250033,38.09,12.03,Amhara,ETHNICITY,ETHNICITY
SM250033,37,13,Amhara,ETHNICITY,ETHNICITY
SM250033,37,13,Amhara,ETHNICITY,ETHNICITY
SM250033,37,13,Amhara,ETHNICITY,ETHNICITY
SM250033,37,13,Amhara,ETHNICITY,ETHNICITY
SM250033,38,13,Amhara,ETHNICITY,ETHNICITY
SM250033,38,13,Amhara,ETHNICITY,ETHNICITY
SM250033,38,13,Amhara,ETHNICITY,ETHNICITY
SM250033,38,13,Amhara,ETHNICITY,ETHNICITY
SM250033,37.75,12.5,Amhara,ETHNICITY,ETHNICITY
SM250033,37.75,12.5,Amhara,ETHNICITY,ETHNICITY
SM250033,37.75,12.5,Amhara,ETHNICITY,ETHNICITY
SM250033,37.75,12.5,Amhara,ETHNICITY,ETHNICITY
SM225367,39.5435,11.7082,Amharic,LANGUAGE,LANGUAGE
SM303025,-79.1451,37.6048,Amherst,ADM2,DISTRICT
SM225369,130.04,-13.5573,Ami,LANGUAGE,LANGUAGE
SM10615,32.9242,34.9281,Amiantos,ADM2,DISTRICT
SM12103,-76.9761,18.3403,Amiel Town,ADM2,DISTRICT
SM225372,121.348,23.0917,Amis,LANGUAGE,LANGUAGE
SM251584,121.38,24.58,Amis,ETHNICITY,ETHNICITY
SM251584,121.38,24.58,Amis,ETHNICITY,ETHNICITY
SM251584,121.38,24.58,Amis,ETHNICITY,ETHNICITY
SM251584,121.05,22.7,Amis,ETHNICITY,ETHNICITY
SM251584,121.05,22.7,Amis,ETHNICITY,ETHNICITY
SM251584,121.05,22.7,Amis,ETHNICITY,ETHNICITY
SM251584,121.2,22.8,Amis,ETHNICITY,ETHNICITY
SM251584,121.2,22.8,Amis,ETHNICITY,ETHNICITY
SM251584,121.2,22.8,Amis,ETHNICITY,ETHNICITY
SM454424,-82,40.5,Amish,ETHNICITY,ETHNICITY
SM323934,-111.0576,52.5646,Amisk,ADM2:ADM3,DISTRICT
SM301448,-90.8044,31.1744,Amite,ADM2,DISTRICT
SM12104,-77.8981,18.2736,Amity,ADM2,DISTRICT
SM12104,-77.8981,18.2736,Amity,ADM2,DISTRICT
SM12104,-78.0941,18.2482,Amity,ADM2,DISTRICT
SM12104,-78.0941,18.2482,Amity,ADM2,DISTRICT
SM12105,-76.257,17.9676,Amity Hall,ADM2,DISTRICT
SM282279,-97.8016,20.0576,Amixtlán,ADM2,DISTRICT
SM232408,24.6983,-23.9345,Amkoe,LANGUAGE,LANGUAGE
SM7372,23.4351,-23.9989,Amkoe,ETHNICITY,ETHNICITY
SM11748,50.1167,36.95,Amlash,ADM2,DISTRICT
SM10616,32.7122,35.1671,Ammadies,ADM2,DISTRICT
SM277982,35.9512,31.8894,Amman,ADM2,DISTRICT
SM407,36.3334,31.5837,Amman,ADM1,DISTRICT
SM251586,-29.08,69.05,Ammassalimiut,ETHNICITY,ETHNICITY
SM251586,-29.08,69.05,Ammassalimiut,ETHNICITY,ETHNICITY
SM251586,-29.08,69.05,Ammassalimiut,ETHNICITY,ETHNICITY
SM251586,-37,66,Ammassalimiut,ETHNICITY,ETHNICITY
SM251586,-37,66,Ammassalimiut,ETHNICITY,ETHNICITY
SM251586,-37,66,Ammassalimiut,ETHNICITY,ETHNICITY
SM251586,-37.64,65.64,Ammassalimiut,ETHNICITY,ETHNICITY
SM251586,-37.64,65.64,Ammassalimiut,ETHNICITY,ETHNICITY
SM251586,-37.64,65.64,Ammassalimiut,ETHNICITY,ETHNICITY
SM10617,33.9274,35.1171,Ammochostos,ADM2,DISTRICT
SM10618,33.9434,35.1176,Ammochostos Municipality,ADM2,DISTRICT
SM408,104.75,15.9167,Amnat Charoen,ADM1,DISTRICT
SM225379,8.6541,10.3229,Amo,LANGUAGE,LANGUAGE
SM251588,-41.63,-9.25,Amoipira,ETHNICITY,ETHNICITY
SM277578,52.3833,36.2,Amol,ADM2,DISTRICT
SM225376,-97.4518,16.6521,Amoltepec Mixtec,LANGUAGE,LANGUAGE
SM251589,-51.47,-11.26,Amonap,ETHNICITY,ETHNICITY
SM251589,-51.47,-11.26,Amonap,ETHNICITY,ETHNICITY
SM251589,-54,-13,Amonap,ETHNICITY,ETHNICITY
SM251589,-54,-13,Amonap,ETHNICITY,ETHNICITY
SM267953,-39.8058,-3.2794,Amontada,ADM2,DISTRICT
SM268110,-51.0985,-16.6342,Amorinópolis,ADM2,DISTRICT
SM225381,40.72,34.71,Amorite,LANGUAGE,LANGUAGE
SM281471,46.7,-20.5,Amoron'i mania,ADM1:ADM2,DISTRICT
SM296858,1.05,7.5,Amou,ADM2,DISTRICT
SM284011,-7.0096,15.7639,Amourj,ADM2,DISTRICT
SM282290,-98.0415,19.0502,Amozoc,ADM2,DISTRICT
SM12871,136.65,35.3,Ampachi-gun,ADM2,DISTRICT
SM225387,116.349,-0.7926,Ampanang,LANGUAGE,LANGUAGE
SM371035,44.746,-24.696,Ampanihy,ADM2:ADM3,DISTRICT
SM409,81.75,7.0833,Ampara,ADM1:ADM2,DISTRICT
SM371012,48.3644,-17.3792,Amparafaravola,ADM2:ADM3,DISTRICT
SM225386,-3.55,14.15,Ampari Dogon,LANGUAGE,LANGUAGE
SM457975,-46.8015,-22.7085,Amparo,ADM2,DISTRICT
SM264432,-37.0337,-7.5589,Amparo,ADM2,DISTRICT
SM267076,-36.9222,-10.1374,Amparo de São Francisco,ADM2,DISTRICT
SM263424,-42.7988,-20.5238,Amparo do Serra,ADM2,DISTRICT
SM371022,49.0635,-18.8199,Ampasimanolotra,ADM2:ADM3,DISTRICT
SM10619,32.8022,35.1036,Ampelikou,ADM2,DISTRICT
SM297520,99.9123,13.3672,Amphawa,ADM2,DISTRICT
SM17957,98.3033,18.9444,Amphoe Galyani Vadhana,ADM2,DISTRICT
SM17958,98.3245,18.6669,Amphoe Mae Chaem (2009-),ADM2,DISTRICT
SM297758,104.178,15.8555,Amphoe Muang Yasothon,ADM2,DISTRICT
SM297759,104.1907,15.9792,Amphoe Sai Mun,ADM2,DISTRICT
SM264455,-53.4937,-25.9124,Ampére,ADM2,DISTRICT
SM251590,57.91,22.35,Amr,ETHNICITY,ETHNICITY
SM251591,19.73,-21.23,Amraal Orlam,ETHNICITY,ETHNICITY
SM304785,43.9343,15.6775,Amran,ADM2,DISTRICT
SM410,43.9098,16.2302,Amran,ADM1,DISTRICT
SM277054,77.7569,20.9348,Amravati,ADM2,DISTRICT
SM276822,71.1832,21.5079,Amreli,ADM2,DISTRICT
SM225391,91.482,26.024,Amri Karbi,LANGUAGE,LANGUAGE
SM277141,74.84,31.67,Amritsar (2006-),ADM2,DISTRICT
SM277323,78.3387,28.7716,Amroha,ADM2,DISTRICT
SM285992,4.8577,52.2984,Amstelveen,ADM2,DISTRICT
SM286003,4.8986,52.373,Amsterdam,ADM2,DISTRICT
SM261803,14.7667,48,Amstetten,ADM2,DISTRICT
SM225394,141.339,-4.1217,Amto,LANGUAGE,LANGUAGE
SM303498,60.095,42.1495,Amudaryo,ADM2,DISTRICT
SM15421,-66.5015,18.037,Amuelas Barrio,ADM2,DISTRICT
SM251592,-75,-11.84,Amuesha,ETHNICITY,ETHNICITY
SM225399,-62.3064,-9.0402,Amundava,LANGUAGE,LANGUAGE
SM411,128,54,Amur,ADM1,DISTRICT
SM231524,140.681,52.59,Amur Nivkh,LANGUAGE,LANGUAGE
SM251593,132.98,-11.71,Amurag,ETHNICITY,ETHNICITY
SM251593,132.98,-11.71,Amurag,ETHNICITY,ETHNICITY
SM251593,132.8736,-11.9503,Amurag,ETHNICITY,ETHNICITY
SM251593,132.8736,-11.9503,Amurag,ETHNICITY,ETHNICITY
SM225314,132.878,-11.9876,Amurdak,LANGUAGE,LANGUAGE
SM10491,-159.7931,-18.8533,Amuri District,ADM2,DISTRICT
SM4784,33.6667,2.0833,Amuria (2005-),ADM1:ADM2,DISTRICT
SM16836,136.9167,50.25,Amurskiy Rayon,ADM2,DISTRICT
SM299417,32,3,Amuru (2010-),ADM1:ADM2,DISTRICT
SM285003,3.263,6.472,Amuwo Odofin,ADM2,DISTRICT
SM244688,47.3321,42.1716,Amuzgu-Shiri,DIALECT,LANGUAGE
SM299934,38.5,47.75,Amvrosi‹vs'kyi,ADM2,DISTRICT
SM251595,140.22,-6.03,Amyu,ETHNICITY,ETHNICITY
SM290202,28.3212,43.9598,Amzacea,ADM2,DISTRICT
SM267376,-38.7515,-12.3898,Amélia Rodrigues,ADM2,DISTRICT
SM267387,-41.4734,-11.4694,América dourada,ADM2,DISTRICT
SM266544,-48.0157,-21.718,Américo Brasiliense,ADM2,DISTRICT
SM320351,-49.7554,-20.2745,Américo de Campos,ADM2:ADM3,DISTRICT
SM304220,105.0523,9.8129,An Biên,ADM2,DISTRICT
SM304074,106.5875,20.8727,An Dương,ADM2,DISTRICT
SM413,105.1667,10.5,An Giang,ADM1,DISTRICT
SM304045,108.6967,14.0067,An Khê,ADM2,DISTRICT
SM304602,108.801,14.5568,An Lão,ADM2,DISTRICT
SM304081,106.5627,20.8065,An Lão,ADM2,DISTRICT
SM304227,104.9485,9.667,An Minh,ADM2,DISTRICT
SM17758,42.7,25.4667,An Nabhaniyah,ADM2,DISTRICT
SM304880,44.4993,14.074,An Nadirah,ADM2,DISTRICT
SM17759,42.2333,19.2167,An Namas,ADM2,DISTRICT
SM305769,46.2584,31.0406,An Nasiriyah,ADM2,DISTRICT
SM304605,109.0633,13.8696,An Nhơn,ADM2,DISTRICT
SM17760,48.5,27,An Nuayriyah,ADM2,DISTRICT
SM414,11.9,32.6333,An Nuqat al Khams,ADM1,DISTRICT
SM303912,105.0899,10.8427,An Phú,ADM2,DISTRICT
SM296744,36.7122,34.0831,An-Nabk,ADM2,DISTRICT
SM412,43.8333,31.25,An-Najaf,ADM1,DISTRICT
SM225421,-3.1168,14.6669,Ana Tinga Dogon,LANGUAGE,LANGUAGE
SM11231,-145.5134,-17.408,Anaa,ADM2,DISTRICT
SM225407,7.5797,4.9483,Anaang,LANGUAGE,LANGUAGE
SM415,166.9524,-0.5074,Anabar,ADM1,DISTRICT
SM294334,116,72.5,Anabarskiy rayon,ADM2,DISTRICT
SM303694,-64.4667,9.3333,Anaco,ADM2,DISTRICT
SM289221,-8.4625,40.4511,Anadia,ADM2,DISTRICT
SM265350,-36.3379,-9.675,Anadia,ADM2,DISTRICT
SM10620,32.5593,34.9564,Anadiou,ADM2,DISTRICT
SM251596,-175.95,66.79,Anadyr Eskimo,ETHNICITY,ETHNICITY
SM16837,173,65,Anadyrskiy Rayon (2011-),ADM2,DISTRICT
SM10621,33.4617,34.8141,Anafotida,ADM2,DISTRICT
SM416,-172.497,-8.558,Anafu,ADM1,DISTRICT
SM10622,33.2439,35.078,Anageia,ADM2,DISTRICT
SM251597,9,10,Anaguta,ETHNICITY,ETHNICITY
SM267398,-41.147,-14.6617,Anagé,ADM2,DISTRICT
SM12032,41.8123,34.2707,Anah District,ADM2,DISTRICT
SM264466,-53.1323,-24.6652,Anahy,ADM2,DISTRICT
SM264211,-50.0397,-0.8602,Anajas,ADM2,DISTRICT
SM262826,-44.5328,-3.2741,Anajatuba,ADM2,DISTRICT
SM289148,125.4861,38.5117,Anak,ADM2,DISTRICT
SM251598,103,-3,Anak Dalam,ETHNICITY,ETHNICITY
SM251598,103,-3,Anak Dalam,ETHNICITY,ETHNICITY
SM251598,102.69,-3.04,Anak Dalam,ETHNICITY,ETHNICITY
SM251598,102.69,-3.04,Anak Dalam,ETHNICITY,ETHNICITY
SM225409,119.605,-9.6343,Anakalangu,LANGUAGE,LANGUAGE
SM251599,21.17,18.62,Anakazza,ETHNICITY,ETHNICITY
SM251599,20.67,17.55,Anakazza,ETHNICITY,ETHNICITY
SM225410,94.2806,24.0506,Anal,LANGUAGE,LANGUAGE
SM371004,47.781,-14.7246,Analalava,ADM2:ADM3,DISTRICT
SM281465,47.5,-18.45,Analamanga,ADM1:ADM2,DISTRICT
SM266577,-47.6894,-22.12,Analandia,ADM2,DISTRICT
SM281481,49.5,-16.5,Analanjirofo,ADM1:ADM2,DISTRICT
SM10623,33.294,35.011,Analiontas,ADM2,DISTRICT
SM225411,145.051,-4.6887,Anam,LANGUAGE,LANGUAGE
SM417,7,6.3333,Anambra (1991-),ADM1,DISTRICT
SM285300,6.8167,6.2667,Anambra East,ADM2,DISTRICT
SM285303,6.7497,6.3593,Anambra West,ADM2,DISTRICT
SM225413,-49.303,-2.7112,Anambé,LANGUAGE,LANGUAGE
SM295700,-87.8892,13.7357,Anamorós,ADM2,DISTRICT
SM284549,15.0167,-17.4833,Anamulenge,ADM2,DISTRICT
SM298750,32.8262,36.0836,Anamur,ADM2,DISTRICT
SM225412,144.991,-4.6376,Anamuxra,LANGUAGE,LANGUAGE
SM267326,-61.7169,-3.4799,Anamã,ADM2,DISTRICT
SM279413,134.6064,33.8772,Anan,ADM2,DISTRICT
SM299607,29.958,47.7213,Anan'‹vs'kyi,ADM2,DISTRICT
SM276833,72.75,22.4,Anand,ADM2,DISTRICT
SM261716,131.3731,-27.058,Anangu Pitjantjatjara,ADM2,DISTRICT
SM264079,-48.3829,-1.3461,Ananindeua,ADM2,DISTRICT
SM277019,77.4167,14.55,Anantapur,ADM2,DISTRICT
SM276877,75.1667,33.8333,Anantnag (2006-),ADM2,DISTRICT
SM267244,-48.2151,-6.1462,Ananás,ADM2,DISTRICT
SM285304,6.9963,6.0682,Anaocha,ADM2,DISTRICT
SM269995,-74.5469,4.5436,Anapoima,ADM2,DISTRICT
SM9875,-49.0164,-16.2886,Anapolis (1997-),ADM2,DISTRICT
SM16838,37.3524,45.0268,Anapskiy Rayon,ADM2,DISTRICT
SM264090,-51.338,-3.8858,Anapu,ADM2,DISTRICT
SM262837,-43.0431,-3.5607,Anapuros,ADM2,DISTRICT
SM260266,61.249,33.0112,Anar Dara,ADM2,DISTRICT
SM10624,32.541,34.7457,Anarita,ADM2,DISTRICT
SM251601,-41.78,63.72,Anarkat,ETHNICITY,ETHNICITY
SM15422,-67.1741,18.275,Anasco Abajo Barrio,ADM2,DISTRICT
SM15423,-67.147,18.276,Anasco Arriba Barrio,ADM2,DISTRICT
SM15424,-67.1414,18.2829,Anasco Barrio-Pueblo,ADM2,DISTRICT
SM225419,137.716,-1.7391,Anasi,LANGUAGE,LANGUAGE
SM263054,-55.7305,-20.7627,Anastácio,ADM2,DISTRICT
SM15283,145.641,16.362,Anatahan Island,ADM2,DISTRICT
SM10492,-159.7875,-18.8422,Anaunga District,ADM2,DISTRICT
SM263065,-52.7201,-22.142,Anaurilândia,ADM2,DISTRICT
SM225428,-65.4132,1.4806,Anauyá,LANGUAGE,LANGUAGE
SM17169,-60.9278,13.8269,Anbre,ADM2,DISTRICT
SM289169,127.5597,39.0392,Anbyŏn,ADM2,DISTRICT
SM419,-77.75,-9.5,Ancash,ADM1,DISTRICT
SM261002,-65.5,-29,Ancasti,ADM2,DISTRICT
SM13405,22.0466,57.5258,Ances pagasts,ADM2,DISTRICT
SM266151,-53.3546,-26.5267,Anchieta,ADM2,DISTRICT
SM268020,-40.7006,-20.7342,Anchieta,ADM2,DISTRICT
SM301191,-149.2506,61.1667,Anchorage,ADM2,DISTRICT
SM12106,-77.9505,18.412,Anchovy,ADM2,DISTRICT
SM251603,44.75,32.583,Ancient Babylonians,ETHNICITY,ETHNICITY
SM251603,44.75,32.583,Ancient Babylonians,ETHNICITY,ETHNICITY
SM251603,44.75,32.583,Ancient Babylonians,ETHNICITY,ETHNICITY
SM251603,45,32,Ancient Babylonians,ETHNICITY,ETHNICITY
SM251603,45,32,Ancient Babylonians,ETHNICITY,ETHNICITY
SM251603,45,32,Ancient Babylonians,ETHNICITY,ETHNICITY
SM251603,44.75,32.58,Ancient Babylonians,ETHNICITY,ETHNICITY
SM251603,44.75,32.58,Ancient Babylonians,ETHNICITY,ETHNICITY
SM251603,44.75,32.58,Ancient Babylonians,ETHNICITY,ETHNICITY
SM251604,31,30,Ancient Egyptians,ETHNICITY,ETHNICITY
SM225434,21.9129,39.8155,Ancient Greek,LANGUAGE,LANGUAGE
SM225435,35.1725,31.7761,Ancient Hebrew,LANGUAGE,LANGUAGE
SM251605,34.9165,31.2085,Ancient Hebrew,ETHNICITY,ETHNICITY
SM251605,34.9165,31.2085,Ancient Hebrew,ETHNICITY,ETHNICITY
SM251605,34.9165,31.2085,Ancient Hebrew,ETHNICITY,ETHNICITY
SM251605,35,32,Ancient Hebrew,ETHNICITY,ETHNICITY
SM251605,35,32,Ancient Hebrew,ETHNICITY,ETHNICITY
SM251605,35,32,Ancient Hebrew,ETHNICITY,ETHNICITY
SM251605,34.92,31.18,Ancient Hebrew,ETHNICITY,ETHNICITY
SM251605,34.92,31.18,Ancient Hebrew,ETHNICITY,ETHNICITY
SM251605,34.92,31.18,Ancient Hebrew,ETHNICITY,ETHNICITY
SM251606,-72,-13.5,Ancient Inca,ETHNICITY,ETHNICITY
SM251606,-72,-13.5,Ancient Inca,ETHNICITY,ETHNICITY
SM251606,-72,-13,Ancient Inca,ETHNICITY,ETHNICITY
SM251606,-72,-13,Ancient Inca,ETHNICITY,ETHNICITY
SM251607,75,30,Ancient Indo-Iranian,ETHNICITY,ETHNICITY
SM225436,39.776,29.044,Ancient North Arabian,LANGUAGE,LANGUAGE
SM251609,13.5,41.833,Ancient Romans,ETHNICITY,ETHNICITY
SM251609,13.5,41.833,Ancient Romans,ETHNICITY,ETHNICITY
SM251609,13.5,41.833,Ancient Romans,ETHNICITY,ETHNICITY
SM251609,12,42,Ancient Romans,ETHNICITY,ETHNICITY
SM251609,12,42,Ancient Romans,ETHNICITY,ETHNICITY
SM251609,12,42,Ancient Romans,ETHNICITY,ETHNICITY
SM251609,13.5,41.67,Ancient Romans,ETHNICITY,ETHNICITY
SM251609,13.5,41.67,Ancient Romans,ETHNICITY,ETHNICITY
SM251609,13.5,41.67,Ancient Romans,ETHNICITY,ETHNICITY
SM277875,13.1667,43.55,Ancona,ADM2,DISTRICT
SM15425,-66.0434,17.9945,Ancones Barrio (Arroyo),ADM2,DISTRICT
SM15426,-67.0469,18.0706,Ancones Barrio (San Germán),ADM2,DISTRICT
SM283849,39.8982,-12.9893,Ancuabe,ADM2,DISTRICT
SM270322,-77.5326,1.2654,Ancuyá,ADM2,DISTRICT
SM225442,127.6937,-16.5312,Andadjin,DIALECT,LANGUAGE
SM286857,-73.4167,-13.6667,Andahuaylas,ADM2,DISTRICT
SM225443,143.637,-4.9645,Andai,LANGUAGE,LANGUAGE
SM261003,-66.4667,-27.5833,Andalgalá,ADM2,DISTRICT
SM270616,-76.1474,4.1404,Andalucía,ADM2,DISTRICT
SM420,-4.5833,37.5,Andalucía,ADM1,DISTRICT
SM225447,-5.2,36.54,Andalusian Arabic,LANGUAGE,LANGUAGE
SM225440,92.738,11.676,Andaman Creole Hindi,LANGUAGE,LANGUAGE
SM421,92.6752,11.7007,Andaman and Nicobar,ADM1,DISTRICT
SM251610,92.93,13.19,Andamanese,ETHNICITY,ETHNICITY
SM251610,92.93,13.19,Andamanese,ETHNICITY,ETHNICITY
SM251610,92.93,13.19,Andamanese,ETHNICITY,ETHNICITY
SM251610,93,12,Andamanese,ETHNICITY,ETHNICITY
SM251610,93,12,Andamanese,ETHNICITY,ETHNICITY
SM251610,93,12,Andamanese,ETHNICITY,ETHNICITY
SM251610,92.89,13.32,Andamanese,ETHNICITY,ETHNICITY
SM251610,92.89,13.32,Andamanese,ETHNICITY,ETHNICITY
SM251610,92.89,13.32,Andamanese,ETHNICITY,ETHNICITY
SM370963,49.65,-14.65,Andapa,ADM2:ADM3,DISTRICT
SM225446,-76,2.75,Andaqui,LANGUAGE,LANGUAGE
SM260297,68.447,33.3058,Andar,ADM2,DISTRICT
SM9362,69.1786,35.624,Andarab (2005-),ADM2,DISTRICT
SM267409,-41.2768,-12.8417,Andarai,ADM2,DISTRICT
SM225444,144.767,-4.4873,Andarum,LANGUAGE,LANGUAGE
SM458620,126.1281,-17.3486,Andedja,ETHNICITY,ETHNICITY
SM225450,137.3515,-22.5128,Andegerebinha,LANGUAGE,LANGUAGE
SM269013,8.6812,47.6133,Andelfingen,ADM2,DISTRICT
SM302639,-95.6526,31.8133,Anderson,ADM2,DISTRICT
SM302465,-82.6379,34.5191,Anderson,ADM2,DISTRICT
SM302544,-84.1985,36.1184,Anderson,ADM2,DISTRICT
SM300900,-95.2933,38.2142,Anderson,ADM2,DISTRICT
SM301031,-84.991,38.0039,Anderson,ADM2,DISTRICT
SM270227,-75.8991,5.6697,Andes,ADM2,DISTRICT
SM225453,77.7156,19.4734,Andh,LANGUAGE,LANGUAGE
SM422,79.75,15.8333,Andhra Pradesh (2014-),ADM1,DISTRICT
SM225456,46.2919,42.8078,Andi,LANGUAGE,LANGUAGE
SM423,72.3333,40.75,Andijon,ADM1,DISTRICT
SM18368,72.3509,40.7858,Andijon Shahri,ADM2,DISTRICT
SM18369,72.4167,40.8,Andijon Tumani,ADM2,DISTRICT
SM371013,48.5474,-16.9506,Andilamena,ADM2:ADM3,DISTRICT
SM277537,48.3,32.7,Andimeshk,ADM2,DISTRICT
SM225458,123.174,-0.8837,Andio,LANGUAGE,LANGUAGE
SM9876,-50.274,-23.0393,Andira,ADM2,DISTRICT
SM9495,65.1167,36.95,Andkhoy (2005-),ADM2,DISTRICT
SM225460,-76.6056,-3.2966,Andoa,LANGUAGE,LANGUAGE
SM251615,-74.6,-2.55,Andoa,ETHNICITY,ETHNICITY
SM280733,128.7167,36.5667,Andong,ADM2,DISTRICT
SM285214,7.3826,4.4727,Andoni/O,ADM2,DISTRICT
SM459265,142.6073,-10.938,Andooyomo,ETHNICITY,ETHNICITY
SM225461,-72.0869,-0.5375,Andoque,LANGUAGE,LANGUAGE
SM225459,-72.3109,-0.667,Andoquero,LANGUAGE,LANGUAGE
SM267420,-39.8639,-10.2222,Andorinha,ADM2,DISTRICT
SM424,1.4941,42.5045,Andorra la Vella,ADM1,DISTRICT
SM280416,107.3116,13.9335,Andoung Meas,ADM2,DISTRICT
SM225466,147.085,-2.0589,Andra-Hus,LANGUAGE,LANGUAGE
SM263435,-46.5723,-22.0698,Andradas,ADM2,DISTRICT
SM266588,-51.3157,-20.8655,Andradina,ADM2,DISTRICT
SM370940,47.5889,-19.1872,Andramasina,ADM2:ADM3,DISTRICT
SM290937,27.1244,44.5765,Andrasesti,ADM2,DISTRICT
SM294749,32.25,56.6667,Andreapol'skiy rayon,ADM2,DISTRICT
SM276782,-4.4425,54.3674,Andreas,ADM1:ADM2,DISTRICT
SM290630,23.5552,44.8224,Andreesti,ADM2,DISTRICT
SM292343,26.8275,45.7367,Andreiasu De Jos,ADM2,DISTRICT
SM263447,-44.2791,-21.7113,Andrelândia,ADM2,DISTRICT
SM303845,-71.2956,7.4773,Andres Eloy Blanco,ADM2,DISTRICT
SM301534,-94.802,39.9835,Andrew,ADM2,DISTRICT
SM323754,-112.3358,53.8794,Andrew,ADM2:ADM3,DISTRICT
SM302750,-102.6378,32.305,Andrews,ADM2,DISTRICT
SM291658,22.3469,47.5172,Andrid,ADM2,DISTRICT
SM290816,27.295,47.5111,Andrieseni,ADM2,DISTRICT
SM305536,18.7405,45.2252,Andrijaševci,ADM2,DISTRICT
SM425,19.7586,42.7183,Andrijevica,ADM1,DISTRICT
SM225464,94.0338,24.7543,Andro,LANGUAGE,LANGUAGE
SM10625,32.3518,35.0028,Androlikou,ADM2,DISTRICT
SM301198,-70.2065,44.1659,Androscoggin,ADM2,DISTRICT
SM281483,45.5,-24.75,Androy,ADM1:ADM2,DISTRICT
SM13406,27.3695,56.1477,Andrupenes pagasts,ADM2,DISTRICT
SM299841,29.0322,50.0171,Andrushivs'kyi,ADM2,DISTRICT
SM265640,-51.4998,-28.5883,André da Rocha,ADM2,DISTRICT
SM303760,-70.8076,9.6322,Andrés Bello,ADM2,DISTRICT
SM303731,-72.1825,7.8563,Andrés Bello,ADM2,DISTRICT
SM303646,-65.9956,10.2974,Andrés Bello,ADM2,DISTRICT
SM303623,-71.3333,8.8333,Andrés Bello,ADM2,DISTRICT
SM303716,-63.4167,10.55,Andrés Eloy Blanco,ADM2,DISTRICT
SM303603,-69.6532,9.7515,Andrés Eloy Blanco,ADM2,DISTRICT
SM262763,-63.19,-17.8737,Andrés Ibáñez,ADM2,DISTRICT
SM303723,-63.3295,10.5988,Andrés Mata,ADM2,DISTRICT
SM260403,16.4728,-11.2124,Andulo,ADM2,DISTRICT
SM13407,27.5268,56.1878,Andzelu pagasts,ADM2,DISTRICT
SM286067,15.7764,69.0803,Andøy,ADM2,DISTRICT
SM298560,36.3611,37.5879,Andırın,ADM2,DISTRICT
SM296643,14.798,57.8699,Aneby,ADM2,DISTRICT
SM225468,169.825,-20.1864,Aneityum,LANGUAGE,LANGUAGE
SM225470,148.993,-5.5484,Anem,LANGUAGE,LANGUAGE
SM225469,148.479,-9.7591,Aneme Wake,LANGUAGE,LANGUAGE
SM427,29.25,46.9167,Anenii Noi,ADM1,DISTRICT
SM428,166.9435,-0.5035,Anetan,ADM1,DISTRICT
SM225473,34.647,8.568,Anfillo,LANGUAGE,LANGUAGE
SM251618,35,9,Anfillo,ETHNICITY,ETHNICITY
SM429,100.3585,14.6105,Ang Thong,ADM1,DISTRICT
SM225479,146.352,-7.1501,Angaataha,LANGUAGE,LANGUAGE
SM260977,-68.1333,-31.2,Angaco,ADM2,DISTRICT
SM251619,-60.68,-20.74,Angaite,ETHNICITY,ETHNICITY
SM225489,-58.45,-22.85,Angaité,LANGUAGE,LANGUAGE
SM225482,143.592,-6.0127,Angal,LANGUAGE,LANGUAGE
SM225483,143.583,-6.233,Angal Enen,LANGUAGE,LANGUAGE
SM225481,143.478,-6.1335,Angal Heneng,LANGUAGE,LANGUAGE
SM282032,-101.727,20.1344,Angamacutiro,ADM2,DISTRICT
SM225477,93.8668,25.6841,Angami Naga,LANGUAGE,LANGUAGE
SM225488,93.7191,25.8964,Angami Naga Sign Language,LANGUAGE,LANGUAGE
SM282043,-100.3018,19.6224,Angangueo,ADM2,DISTRICT
SM251620,-75.24,-13.16,Angara,ETHNICITY,ETHNICITY
SM286910,-74.75,-13,Angaraes,ADM2,DISTRICT
SM16839,103.6667,52.3333,Angarskiy Rayon,ADM2,DISTRICT
SM250558,9,9,Angas,ETHNICITY,ETHNICITY
SM10626,33.5853,35.2063,Angastina,ADM2,DISTRICT
SM266599,-48.4257,-23.4745,Angatuba,ADM2,DISTRICT
SM430,134.138,6.9112,Angaur,ADM1,DISTRICT
SM14209,-92.7232,15.8662,Angel Albino Corzo (1999-),ADM2,DISTRICT
SM15427,-66.8021,18.2917,Angeles Barrio,ADM2,DISTRICT
SM264856,-36.2767,-8.8915,Angelim,ADM2,DISTRICT
SM302816,-94.6119,31.2548,Angelina,ADM2,DISTRICT
SM266162,-49.0856,-27.5345,Angelina,ADM2,DISTRICT
SM263458,-42.2623,-17.7239,Angelândia,ADM2,DISTRICT
SM270238,-75.7143,6.1223,Angelópolis,ADM2,DISTRICT
SM225492,139.457,-4.0836,Angguruk Yali,LANGUAGE,LANGUAGE
SM267431,-44.7498,-11.9628,Angical,ADM2,DISTRICT
SM265027,-42.7202,-6.0988,Angical do Piauí,ADM2,DISTRICT
SM267255,-47.9311,-6.3434,Angico,ADM2,DISTRICT
SM265584,-36.5488,-5.674,Angicos,ADM2,DISTRICT
SM225493,85.534,26.0047,Angika,LANGUAGE,LANGUAGE
SM10087,104.7318,11.5559,Angk Snuol (2019-),ADM2,DISTRICT
SM225491,142.242,-11.443,Angkamuthi,LANGUAGE,LANGUAGE
SM280449,104.9518,10.9929,Angkor Borei,ADM2,DISTRICT
SM280500,104.65,10.7667,Angkor Chey,ADM2,DISTRICT
SM280425,103.717,13.7213,Angkor Chum,ADM2,DISTRICT
SM280429,103.8565,13.5788,Angkor Thum,ADM2,DISTRICT
SM274745,-4.3333,53.25,Anglesey,ADM1:ADM2,DISTRICT
SM288566,144.5667,-6.05,Anglimp-South Waghi,ADM2,DISTRICT
SM10627,33.4598,34.8567,Anglisides,ADM2,DISTRICT
SM269557,25.8667,4.0333,Ango,ADM2,DISTRICT
SM283933,39.9111,-16.2327,Angoche,ADM2,DISTRICT
SM225511,6.5949,0.1144,Angolar,LANGUAGE,LANGUAGE
SM10628,32.9471,35.1169,Angolemi,ADM2,DISTRICT
SM251623,-135.69,57.1,Angoon,ETHNICITY,ETHNICITY
SM251623,-133.54,55.09,Angoon,ETHNICITY,ETHNICITY
SM225507,141.199,-3.7167,Angor,LANGUAGE,LANGUAGE
SM303430,67.1667,37.4167,Angor,ADM2,DISTRICT
SM225508,144.028,-4.0776,Angoram,LANGUAGE,LANGUAGE
SM288627,143.8333,-4.5,Angoram,ADM2,DISTRICT
SM303853,-63.2694,7.4925,Angostura,ADM2,DISTRICT
SM14210,-108.1274,25.2914,Angostura,ADM2,DISTRICT
SM270249,-75.3595,6.8712,Angostura,ADM2,DISTRICT
SM225510,-71.9212,6.542,Angosturas Tunebo,LANGUAGE,LANGUAGE
SM289376,-27.2491,38.6953,Angra do Heroísmo,ADM2,DISTRICT
SM265246,-44.3153,-23.0027,Angra dos Reis,ADM2,DISTRICT
SM18370,70.1436,41.0167,Angren Shahri,ADM2,DISTRICT
SM251624,145.91,-7.02,Angu,ETHNICITY,ETHNICITY
SM267442,-39.2138,-12.1822,Anguera,ADM2,DISTRICT
SM274735,-2.9167,56.6667,Angus,ADM1:ADM2,DISTRICT
SM225513,141.755,-12.395,Anguthimri,LANGUAGE,LANGUAGE
SM459273,141.8361,-12.3777,Anguthimri,ETHNICITY,ETHNICITY
SM263076,-53.8667,-22.0278,Angélica,ADM2,DISTRICT
SM283864,34.1632,-14.6745,Angónia,ADM2,DISTRICT
SM283864,34.1632,-14.6745,Angónia,ADM2,DISTRICT
SM283864,34.367,-14.717,Angónia,ADM2,DISTRICT
SM283864,34.367,-14.717,Angónia,ADM2,DISTRICT
SM304318,105.0829,18.929,Anh Sơn,ADM2,DISTRICT
SM268132,-48.2219,-18.3151,Anhanguera,ADM2,DISTRICT
SM266610,-48.1638,-22.8329,Anhembi,ADM2,DISTRICT
SM431,117.1667,31.9167,Anhui,ADM1,DISTRICT
SM266621,-51.4276,-22.3457,Anhumas,ADM2,DISTRICT
SM225531,103.6,23.7,Ani Phowa,LANGUAGE,LANGUAGE
SM251625,40.71,7.28,Ania,ETHNICITY,ETHNICITY
SM432,166.9503,-0.5346,Anibare,ADM1,DISTRICT
SM262775,-64.3952,-22.1948,Aniceto Arce,ADM2,DISTRICT
SM268143,-49.9794,-16.3858,Anicuns,ADM2,DISTRICT
SM225522,1.6017,9.1113,Anii,LANGUAGE,LANGUAGE
SM11160,25.4817,59.2764,Anija vald,ADM2,DISTRICT
SM14211,-96.7142,16.9867,Animas Trujano,ADM2,DISTRICT
SM225526,0.4196,7.6039,Animere,LANGUAGE,LANGUAGE
SM289990,21.8521,45.0768,Anina,ADM2,DISTRICT
SM251627,136.6,-13.99,Anindilyagwa,ETHNICITY,ETHNICITY
SM251627,136.6,-13.99,Anindilyagwa,ETHNICITY,ETHNICITY
SM251627,136.6,-13.99,Anindilyagwa,ETHNICITY,ETHNICITY
SM251627,136.62,-14,Anindilyagwa,ETHNICITY,ETHNICITY
SM251627,136.62,-14,Anindilyagwa,ETHNICITY,ETHNICITY
SM251627,136.62,-14,Anindilyagwa,ETHNICITY,ETHNICITY
SM251627,136.5854,-13.9311,Anindilyagwa,ETHNICITY,ETHNICITY
SM251627,136.5854,-13.9311,Anindilyagwa,ETHNICITY,ETHNICITY
SM251627,136.5854,-13.9311,Anindilyagwa,ETHNICITY,ETHNICITY
SM225528,136.6415,-13.999,Anindilyakwa,LANGUAGE,LANGUAGE
SM259118,8.51,9.08,Aninka,LANGUAGE,LANGUAGE
SM290736,23.3302,45.3932,Aninoasa,ADM2,DISTRICT
SM290641,23.4737,44.7484,Aninoasa,ADM2,DISTRICT
SM290244,25.438,44.9686,Aninoasa,ADM2,DISTRICT
SM291237,24.9121,45.2022,Aninoasa,ADM2,DISTRICT
SM284721,7.5914,6.0616,Aninri,ADM2,DISTRICT
SM284649,6.4874,6.3545,AniochaN,ADM2,DISTRICT
SM284660,6.4833,6.1283,AniochaS,ADM2,DISTRICT
SM4660,10.8333,1.75,Anisok (2017-),ADM2,DISTRICT
SM266173,-51.0584,-27.7262,Anita Garibaldi,ADM2,DISTRICT
SM266184,-49.132,-27.869,Anitápolis,ADM2,DISTRICT
SM294350,142.25,46.5,Anivskiy rayon,ADM2,DISTRICT
SM251628,41.72,29.49,Anizah,ETHNICITY,ETHNICITY
SM225535,145.717,-5.459,Anjam,LANGUAGE,LANGUAGE
SM277196,96.8288,28.0655,Anjaw,ADM2,DISTRICT
SM370941,47.7119,-18.1366,Anjozorobe,ADM2:ADM3,DISTRICT
SM289048,125.7261,39.5908,Anju,ADM2,DISTRICT
SM278043,137.0717,34.9434,Anjō,ADM2,DISTRICT
SM285275,5.9866,11.9608,Anka,ADM2,DISTRICT
SM269281,108.878,32.8151,Ankang,ADM2,DISTRICT
SM433,32.854,39.9206,Ankara (1989-),ADM1,DISTRICT
SM4142,13.7333,45.5833,Ankaran,ADM1,DISTRICT
SM225539,145.754,-7.1944,Ankave,LANGUAGE,LANGUAGE
SM371036,44.701,-22.18,Ankazoabo-Sud,ADM2:ADM3,DISTRICT
SM370942,47.1162,-18.3174,Ankazobe,ADM2:ADM3,DISTRICT
SM284966,7.6317,7.4485,Ankpa,ADM2,DISTRICT
SM251631,0.2,5.86,Anlo,ETHNICITY,ETHNICITY
SM458140,133.2623,-22.2547,Anmatyerr,ETHNICITY,ETHNICITY
SM225542,133.286,-22.2853,Anmatyerre,LANGUAGE,LANGUAGE
SM303892,-64.6997,17.7232,Anna's Hope Village,ADM2,DISTRICT
SM434,7.5833,36.8333,Annaba,ADM1,DISTRICT
SM457201,7.75,36.9,Annaba District,ADM2,DISTRICT
SM457201,7.75,36.9,Annaba District,ADM2,DISTRICT
SM457201,8,36.75,Annaba District,ADM2,DISTRICT
SM457201,8,36.75,Annaba District,ADM2,DISTRICT
SM278085,138.7765,36.3408,Annaka,ADM2,DISTRICT
SM268822,-65.1655,44.5835,Annapolis,ADM2,DISTRICT
SM13408,27.006,57.3375,Annas pagasts,ADM2,DISTRICT
SM301225,-76.5676,38.9942,Anne Arundel,ADM2,DISTRICT
SM13409,23.0824,56.6485,Annenieku pagasts,ADM2,DISTRICT
SM294969,40.25,51.4167,Anninskiy rayon,ADM2,DISTRICT
SM230820,5.6188,-1.4303,Annobonese,LANGUAGE,LANGUAGE
SM435,5.6208,-1.4292,Annobón,ADM1:ADMX,DISTRICT
SM12107,-76.767,18.265,Annotto Bay,ADM2,DISTRICT
SM17170,-61.0051,13.7745,Annus,ADM2,DISTRICT
SM10629,32.7339,34.7404,Anogyra,ADM2,DISTRICT
SM301349,-93.2465,45.2732,Anoka,ADM2,DISTRICT
SM270006,-74.4841,4.7762,Anolaima,ADM2,DISTRICT
SM15428,-66.5907,18.1429,Anon Barrio,ADM2,DISTRICT
SM15429,-67.0449,18.2589,Anones Barrio (Las Marías),ADM2,DISTRICT
SM15430,-66.2437,18.2715,Anones Barrio (Naranjito),ADM2,DISTRICT
SM225544,144.801,-4.9845,Anor,LANGUAGE,LANGUAGE
SM267337,-62.1556,-4.2132,Anori,ADM2,DISTRICT
SM270260,-75.1472,7.0742,Anorí,ADM2,DISTRICT
SM371014,48.2,-19.4718,Anosibe,ADM2:ADM3,DISTRICT
SM281484,46.3,-24.1,Anosy,ADM1:ADM2,DISTRICT
SM269119,116.5591,30.6627,Anqing,ADM2,DISTRICT
SM280702,126.8305,37.3169,Ansan,ADM2,DISTRICT
SM11534,-73.4511,18.4397,Ansavo,ADM2,DISTRICT
SM438,55.4833,-4.7083,Anse Boileau,ADM1,DISTRICT
SM17171,-60.9044,13.9087,Anse Canot,ADM2,DISTRICT
SM17172,-61.0702,13.8643,Anse Chastanet,ADM2,DISTRICT
SM17173,-61.0525,13.9226,Anse Cochon,ADM2,DISTRICT
SM17174,-60.9408,13.7315,Anse De Sables/Beane Field,ADM2,DISTRICT
SM17175,-61.0429,13.9322,Anse Galet,ADM2,DISTRICT
SM17176,-60.9157,13.7957,Anse Ger,ADM2,DISTRICT
SM17177,-61.0476,13.9096,Anse La Verdue,ADM2,DISTRICT
SM440,55.5167,-4.7417,Anse Royale,ADM1,DISTRICT
SM437,55.5172,-4.6893,Anse aux Pins,ADM1,DISTRICT
SM276080,-74.3913,18.4664,Anse d'Hainault,ADM2,DISTRICT
SM439,55.45,-4.5917,Anse Étoile,ADM1,DISTRICT
SM436,-61.0157,13.9121,Anse-la-Raye,ADM1,DISTRICT
SM441,37.5,16.5,Anseba,ADM1,DISTRICT
SM225546,-76.05,6.25,Anserma,LANGUAGE,LANGUAGE
SM270850,-75.7917,5.2043,Anserma,ADM2,DISTRICT
SM270627,-76.0345,4.8021,Ansermanuevo,ADM2,DISTRICT
SM269242,122.8275,40.8222,Anshan,ADM2,DISTRICT
SM269451,105.9091,26.0803,Anshun,ADM2,DISTRICT
SM300136,-55.4058,-31.9609,Ansina,ADM2,DISTRICT
SM289251,-8.4205,39.9342,Ansião,ADM2,DISTRICT
SM280713,127.3006,37.0379,Ansoeng,ADM2,DISTRICT
SM301964,-80.1027,34.9738,Anson,ADM2,DISTRICT
SM283342,0.7801,15.7369,Ansongo,ADM2,DISTRICT
SM304796,44.3889,14.4361,Anss,ADM2,DISTRICT
SM225547,135.866,-1.7543,Ansus,LANGUAGE,LANGUAGE
SM286901,-72.3333,-13.5,Anta,ADM2,DISTRICT
SM260944,-64,-24.9167,Anta,ADM2,DISTRICT
SM265651,-51.9711,-28.9684,Anta Gorda,ADM2,DISTRICT
SM248319,141.52,-8.6492,Anta-Komnzo-Wára-Wérè-Kémä,LANGUAGE,LANGUAGE
SM286858,-72.75,-14.4167,Antabamba,ADM2,DISTRICT
SM250843,49.36,-12.8,Antakarana,ETHNICITY,ETHNICITY
SM225550,134.838,-27.1152,Antakarinya,LANGUAGE,LANGUAGE
SM251634,128.86,-29.5,Antakarinya,ETHNICITY,ETHNICITY
SM251634,128.86,-29.5,Antakarinya,ETHNICITY,ETHNICITY
SM251634,128.86,-29.5,Antakarinya,ETHNICITY,ETHNICITY
SM251634,131.81,-30.38,Antakarinya,ETHNICITY,ETHNICITY
SM251634,131.81,-30.38,Antakarinya,ETHNICITY,ETHNICITY
SM251634,131.81,-30.38,Antakarinya,ETHNICITY,ETHNICITY
SM251634,134.0166,-27.0298,Antakarinya,ETHNICITY,ETHNICITY
SM251634,134.0166,-27.0298,Antakarinya,ETHNICITY,ETHNICITY
SM251634,134.0166,-27.0298,Antakarinya,ETHNICITY,ETHNICITY
SM18020,36.2011,36.2444,Antakya Ilcesi,ADM2,DISTRICT
SM370964,50.2443,-14.9039,Antalaha,ADM2:ADM3,DISTRICT
SM442,31.9022,36.7698,Antalya,ADM1,DISTRICT
SM371023,48.4702,-19.5585,Antanambao manampotsy,ADM2:ADM3,DISTRICT
SM370943,47.6257,-18.9016,Antananarivo-Nord,ADM2:ADM3,DISTRICT
SM370944,47.507,-18.9911,Antananarivo-Sud,ADM2:ADM3,DISTRICT
SM250738,45.86,-25.18,Antandroy,ETHNICITY,ETHNICITY
SM250738,45.86,-25.18,Antandroy,ETHNICITY,ETHNICITY
SM250738,45.86,-25.18,Antandroy,ETHNICITY,ETHNICITY
SM250738,45,-25,Antandroy,ETHNICITY,ETHNICITY
SM250738,45,-25,Antandroy,ETHNICITY,ETHNICITY
SM250738,45,-25,Antandroy,ETHNICITY,ETHNICITY
SM250738,46,-25,Antandroy,ETHNICITY,ETHNICITY
SM250738,46,-25,Antandroy,ETHNICITY,ETHNICITY
SM250738,46,-25,Antandroy,ETHNICITY,ETHNICITY
SM370953,47.4813,-19.7447,Antanifotsy,ADM2:ADM3,DISTRICT
SM225552,49.2402,-13.2164,Antankarana Malagasy,LANGUAGE,LANGUAGE
SM250845,47.04,-24.26,Antanosy,ETHNICITY,ETHNICITY
SM251635,-110,37,Antarianunts,ETHNICITY,ETHNICITY
SM251635,-110,37,Antarianunts,ETHNICITY,ETHNICITY
SM251635,-110.72,37.86,Antarianunts,ETHNICITY,ETHNICITY
SM251635,-110.72,37.86,Antarianunts,ETHNICITY,ETHNICITY
SM9676,-50.6785,-82.7807,Antartida Argentina,ADM2,DISTRICT
SM267453,-38.3034,-10.4214,Antas,ADM2,DISTRICT
SM458110,137.6206,-22.7539,Antekerrepenhe,ETHNICITY,ETHNICITY
SM301689,-98.0667,42.1769,Antelope,ADM2,DISTRICT
SM251636,-114.24,40.19,Antelope Valley Shoshone,ETHNICITY,ETHNICITY
SM289646,-57.1692,-24.0908,Antequera,ADM2,DISTRICT
SM268832,-61.9153,45.6668,Antigonish,ADM2,DISTRICT
SM275272,-90.7268,14.5431,Antigua Guatemala,ADM2,DISTRICT
SM225557,-61.8219,17.0683,Antigua and Barbuda Creole English,LANGUAGE,LANGUAGE
SM295656,-89.2462,13.6676,Antiguo Cuscatlán,ADM2,DISTRICT
SM282670,-99.0985,22.5949,Antiguo Morelos,ADM2,DISTRICT
SM271124,-75.7333,20.8333,Antilla,ADM2,DISTRICT
SM251638,47.32,-23.88,Antimanambondro,ETHNICITY,ETHNICITY
SM444,-75.5,7,Antioquia,ADM1,DISTRICT
SM225556,-75.3422,7.1993,Antioquian,LANGUAGE,LANGUAGE
SM445,122.0833,11.1667,Antique,ADM1:ADM2,DISTRICT
SM269096,-69.5708,-24.3112,Antofagasta,ADM2,DISTRICT
SM446,-69,-23.5,Antofagasta,ADM1,DISTRICT
SM261004,-67.5833,-26,Antofagasta de la Sierra,ADM2,DISTRICT
SM303669,-63.8564,11.1076,Antolín del Campo,ADM2,DISTRICT
SM262416,24.3,42.7667,Anton,ADM2,DISTRICT
SM15431,-65.7863,18.1794,Anton Ruiz Barrio,ADM2,DISTRICT
SM264510,-48.7204,-25.2843,Antonina,ADM2,DISTRICT
SM267781,-39.9788,-6.7362,Antonina do Norte,ADM2,DISTRICT
SM273456,-78.2052,0.3399,Antonio Ante,ADM2,DISTRICT
SM303888,-60.8333,8.5,Antonio Diaz,ADM2,DISTRICT
SM281831,-104.0215,20.8016,Antonio Escobedo,ADM2,DISTRICT
SM303846,-70.822,8.2306,Antonio José de Sucre,ADM2,DISTRICT
SM264521,-50.1299,-25.9644,Antonio Olinto,ADM2,DISTRICT
SM303628,-71.6427,8.3961,Antonio Pinto Salinas,ADM2,DISTRICT
SM263491,-42.155,-21.0266,Antonio Prado de Minas,ADM2,DISTRICT
SM262755,-66.4495,-20.0262,Antonio Quijarro,ADM2,DISTRICT
SM286801,-77.0833,-9.0833,Antonio Raymondi,ADM2,DISTRICT
SM303742,-72.1654,8.17,Antonio Rómulo Costa,ADM2,DISTRICT
SM262449,26.2,43.1167,Antonovo,ADM2,DISTRICT
SM299564,39.0667,48.1083,Antratsitivs'ka,ADM2,DISTRICT
SM301295,-85.1758,45.0074,Antrim,ADM2,DISTRICT
SM274702,-6.1977,54.7018,Antrim and Newtownabbey,ADM1:ADM2,DISTRICT
SM293327,43,58.1667,Antropovskiy rayon,ADM2,DISTRICT
SM371000,44.631,-18.8307,Antsalova,ADM2:ADM3,DISTRICT
SM371005,48.0865,-14.9626,Antsihihy,ADM2:ADM3,DISTRICT
SM370954,47.1484,-19.8689,Antsirabe Rural,ADM2:ADM3,DISTRICT
SM370955,47.0376,-19.8556,Antsirabe Urban,ADM2:ADM3,DISTRICT
SM370960,49.2394,-12.4607,Antsiranana Rural,ADM2:ADM3,DISTRICT
SM370961,49.2778,-12.3105,Antsiranana Urban,ADM2:ADM3,DISTRICT
SM11161,26.5952,57.7774,Antsla vald,ADM2,DISTRICT
SM305362,18.6726,45.4902,Antunovac,ADM2,DISTRICT
SM262093,4.834,51.1656,Antwerpen,ADM1:ADM2,DISTRICT
SM269071,-69.2278,-55.0225,Antártica Chilena,ADM2,DISTRICT
SM286691,-80.1836,8.4539,Antón,ADM2,DISTRICT
SM265049,-44.2531,-7.1221,Antônio Almeida,ADM2,DISTRICT
SM267464,-39.1455,-12.3864,Antônio Cardoso,ADM2,DISTRICT
SM266195,-48.8392,-27.4981,Antônio Carlos,ADM2,DISTRICT
SM263469,-43.7694,-21.4084,Antônio Carlos,ADM2,DISTRICT
SM263480,-42.8886,-19.5623,Antônio Dias,ADM2,DISTRICT
SM267476,-40.4093,-10.6228,Antônio Gonçalves,ADM2,DISTRICT
SM263087,-55.939,-22.2011,Antônio João,ADM2,DISTRICT
SM265595,-37.9214,-6.2061,Antônio Martins,ADM2,DISTRICT
SM265662,-51.3174,-28.8732,Antônio Prado,ADM2,DISTRICT
SM225566,93.05,21.5,Anu-Hkongso,LANGUAGE,LANGUAGE
SM225560,34.0267,7.5771,Anuak,LANGUAGE,LANGUAGE
SM294082,133,44,Anuchinskiy rayon,ADM2,DISTRICT
SM225561,0.5602,10.2839,Anufo,LANGUAGE,LANGUAGE
SM277107,85.0608,20.849,Anugul,ADM2,DISTRICT
SM225562,149.822,-9.6692,Anuki,LANGUAGE,LANGUAGE
SM276990,81.684,23.0567,Anuppur,ADM2,DISTRICT
SM448,80.5,8.3333,Anuradhapura,ADM1:ADM2,DISTRICT
SM225563,139.502,-2.2627,Anus,LANGUAGE,LANGUAGE
SM225565,169.85,-11.61,Anuta,LANGUAGE,LANGUAGE
SM280724,126.9287,37.4028,Anyang,ADM2,DISTRICT
SM269160,114.3367,36.1364,Anyang,ADM2,DISTRICT
SM225575,115.5477,28.8436,Anyi,DIALECT,LANGUAGE
SM225573,-3.3053,6.3178,Anyin,LANGUAGE,LANGUAGE
SM225572,-4.6423,6.1627,Anyin Morofo,LANGUAGE,LANGUAGE
SM250036,34,8,Anyiwak,ETHNICITY,ETHNICITY
SM281360,25.1,55.5333,Anykščių,ADM2,DISTRICT
SM303879,-68.8667,9.5833,Anzoátegui,ADM2,DISTRICT
SM270585,-75.1805,4.6223,Anzoátegui,ADM2,DISTRICT
SM449,-64.3333,9,Anzoátegui,ADM1,DISTRICT
SM270148,-75.9167,6.3333,Anzá,ADM2,DISTRICT
SM282184,-100.1229,27.2955,Anáhuac,ADM2,DISTRICT
SM265038,-43.0524,-9.2478,Anísio de Abreu,ADM2,DISTRICT
SM297001,98.7538,8.3853,Ao Luk,ADM2,DISTRICT
SM225584,94.3824,26.3241,Ao Naga,LANGUAGE,LANGUAGE
SM225579,114.127,0.874,Aoheng-Seputan,LANGUAGE,LANGUAGE
SM295354,160.4352,-9.5246,Aola,ADM2,DISTRICT
SM278973,140.7542,40.77,Aomori,ADM2,DISTRICT
SM450,140.8283,40.7831,Aomori,ADM1:ADMX,DISTRICT
SM280484,104.0611,11.7367,Aoral,ADM2,DISTRICT
SM225585,167.166,-15.5753,Aore,LANGUAGE,LANGUAGE
SM283978,-13.0739,19.4556,Aoujeft,ADM2,DISTRICT
SM15011,-15.0207,21.9198,Aousserd,ADM2,DISTRICT
SM10261,17.4511,21.8469,Aouzou,ADM2,DISTRICT
SM4399,-2.8167,5.8167,Aowin,ADM2,DISTRICT
SM225608,144.085,-4.3515,Ap Ma,LANGUAGE,LANGUAGE
SM291669,23.1897,47.7493,Apa,ADM2,DISTRICT
SM285354,7.8711,7.6296,Apa,ADM2,DISTRICT
SM4760,32.6,1.902,Apac (2010-),ADM1:ADM2,DISTRICT
SM301788,-109.4888,35.3956,Apache,ADM2,DISTRICT
SM251643,-106.98,33.58,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-106.98,33.58,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-106.98,33.58,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-106.98,33.58,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-108,31,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-108,31,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-108,31,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-108,31,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-101.76,32.52,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-101.76,32.52,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-101.76,32.52,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-101.76,32.52,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-109.5,32,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-109.5,32,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-109.5,32,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251643,-109.5,32,Apache Chiricahua,ETHNICITY,ETHNICITY
SM251644,-110,31.8,Apache Chiricahua (Huachuca),ETHNICITY,ETHNICITY
SM251645,-108,33,Apache Chiricahua (Warm Springs),ETHNICITY,ETHNICITY
SM251646,-104.38,36.96,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-104.38,36.96,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-104.38,36.96,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-104.38,36.96,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-106.983,36.917,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-106.983,36.917,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-106.983,36.917,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-106.983,36.917,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-104,36,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-104,36,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-104,36,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-104,36,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-105.5,37,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-105.5,37,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-105.5,37,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251646,-105.5,37,Apache Jicarilla,ETHNICITY,ETHNICITY
SM251647,-110.54,44.42,Apache Kiowa,ETHNICITY,ETHNICITY
SM251647,-110.54,44.42,Apache Kiowa,ETHNICITY,ETHNICITY
SM251647,-110.54,44.42,Apache Kiowa,ETHNICITY,ETHNICITY
SM251647,-109.96,43.21,Apache Kiowa,ETHNICITY,ETHNICITY
SM251647,-109.96,43.21,Apache Kiowa,ETHNICITY,ETHNICITY
SM251647,-109.96,43.21,Apache Kiowa,ETHNICITY,ETHNICITY
SM251647,-99,36,Apache Kiowa,ETHNICITY,ETHNICITY
SM251647,-99,36,Apache Kiowa,ETHNICITY,ETHNICITY
SM251647,-99,36,Apache Kiowa,ETHNICITY,ETHNICITY
SM251647,-98.9,35.83,Apache Kiowa,ETHNICITY,ETHNICITY
SM251647,-98.9,35.83,Apache Kiowa,ETHNICITY,ETHNICITY
SM251647,-98.9,35.83,Apache Kiowa,ETHNICITY,ETHNICITY
SM251648,-102.46,29.35,Apache Mescalero,ETHNICITY,ETHNICITY
SM251648,-102.46,29.35,Apache Mescalero,ETHNICITY,ETHNICITY
SM251648,-102.46,29.35,Apache Mescalero,ETHNICITY,ETHNICITY
SM251648,-103,33,Apache Mescalero,ETHNICITY,ETHNICITY
SM251648,-103,33,Apache Mescalero,ETHNICITY,ETHNICITY
SM251648,-103,33,Apache Mescalero,ETHNICITY,ETHNICITY
SM251648,-105,32.5,Apache Mescalero,ETHNICITY,ETHNICITY
SM251648,-105,32.5,Apache Mescalero,ETHNICITY,ETHNICITY
SM251648,-105,32.5,Apache Mescalero,ETHNICITY,ETHNICITY
SM251649,-110.84,34.65,Apache Western,ETHNICITY,ETHNICITY
SM251649,-110.84,34.65,Apache Western,ETHNICITY,ETHNICITY
SM251649,-110.84,34.65,Apache Western,ETHNICITY,ETHNICITY
SM251649,-110.5,34.033,Apache Western,ETHNICITY,ETHNICITY
SM251649,-110.5,34.033,Apache Western,ETHNICITY,ETHNICITY
SM251649,-110.5,34.033,Apache Western,ETHNICITY,ETHNICITY
SM251649,-110,34,Apache Western,ETHNICITY,ETHNICITY
SM251649,-110,34,Apache Western,ETHNICITY,ETHNICITY
SM251649,-110,34,Apache Western,ETHNICITY,ETHNICITY
SM251650,-110.6,34.6,Apache Western (Cibecue),ETHNICITY,ETHNICITY
SM251651,-111.8,35,Apache Western (North Tonto),ETHNICITY,ETHNICITY
SM251652,-110.8,33.5,Apache Western (San Carlos),ETHNICITY,ETHNICITY
SM251653,-111.2,34,Apache Western (South Tonto),ETHNICITY,ETHNICITY
SM251654,-110,33.8,Apache Western (White Mountain),ETHNICITY,ETHNICITY
SM275813,-87.0112,13.4582,Apacilagua,ADM2,DISTRICT
SM290100,23.7443,46.783,Apahida,ADM2,DISTRICT
SM225593,-84.416,30.118,Apalachee,LANGUAGE,LANGUAGE
SM251655,-84.58,30.26,Apalachee,ETHNICITY,ETHNICITY
SM251656,-53.32,1.08,Apalai,ETHNICITY,ETHNICITY
SM251656,-53.32,1.08,Apalai,ETHNICITY,ETHNICITY
SM251656,-52.17,-0.46,Apalai,ETHNICITY,ETHNICITY
SM251656,-52.17,-0.46,Apalai,ETHNICITY,ETHNICITY
SM251656,-53,0,Apalai,ETHNICITY,ETHNICITY
SM251656,-53,0,Apalai,ETHNICITY,ETHNICITY
SM225596,-54.7457,1.4979,Apalaí,LANGUAGE,LANGUAGE
SM225595,145.023,-5.1727,Apali,LANGUAGE,LANGUAGE
SM225594,149.96,-6.29,Apalik,LANGUAGE,LANGUAGE
SM281732,-98.4333,19.739,Apan,ADM2,DISTRICT
SM295502,-89.7976,13.8514,Apaneca,ADM2,DISTRICT
SM285004,3.3493,6.4293,Apapa,ADM2,DISTRICT
SM266643,-45.2356,-22.9043,Aparecida,ADM2,DISTRICT
SM264223,-38.0718,-6.8093,Aparecida,ADM2,DISTRICT
SM268155,-49.2484,-16.8399,Aparecida de Goiânia,ADM2,DISTRICT
SM267266,-47.9651,-10.0618,Aparecida do Rio Negro,ADM2,DISTRICT
SM268166,-51.2414,-18.2296,Aparecida do Rio doce,ADM2,DISTRICT
SM263096,-51.3463,-20.0091,Aparecida do Taboado,ADM2,DISTRICT
SM266632,-50.9188,-20.4841,Aparecida doeste,ADM2,DISTRICT
SM270159,-76.5731,7.904,Apartadó,ADM2,DISTRICT
SM225601,-97.0657,17.6143,Apasco-Apoala Mixtec,LANGUAGE,LANGUAGE
SM281567,-100.5676,20.4268,Apaseo el Alto,ADM2,DISTRICT
SM281575,-100.6347,20.5532,Apaseo el Grande,ADM2,DISTRICT
SM295530,-88.7029,13.657,Apastepeque,ADM2,DISTRICT
SM16840,48.5,55.25,Apastovsky District (1991-),ADM2,DISTRICT
SM292619,25.5331,45.9504,Apata,ADM2,DISTRICT
SM225602,93.7284,27.1933,Apatani,LANGUAGE,LANGUAGE
SM251657,90.17,26.22,Apatani,ETHNICITY,ETHNICITY
SM290495,21.8253,46.626,Apateu,ADM2,DISTRICT
SM282054,-102.4218,18.9884,Apatzingán,ADM2,DISTRICT
SM281865,-99.1689,19.9758,Apaxco,ADM2,DISTRICT
SM281635,-99.9956,18.0763,Apaxtla,ADM2,DISTRICT
SM452,121.1792,18.0336,Apayao,ADM1:ADM2,DISTRICT
SM282785,-96.6506,19.3318,Apazapan,ADM2,DISTRICT
SM13410,26.702,57.5395,Ape,ADM2,DISTRICT
SM15432,-65.9831,18.0192,Apeadero Barrio,ADM2,DISTRICT
SM285780,5.9197,52.1872,Apeldoorn,ADM2,DISTRICT
SM290370,24.0661,44.0666,Apele Vii,ADM2,DISTRICT
SM265257,-42.1275,-21.6415,Aperibé,ADM2,DISTRICT
SM4023,26.5206,57.4669,Apes Novads,ADM1,DISTRICT
SM13411,26.7235,57.5232,Apes pagasts,ADM2,DISTRICT
SM10630,32.9761,34.7829,Apesia,ADM2,DISTRICT
SM282713,-98.1922,19.344,Apetatitlán de Antonio Carvajal,ADM2,DISTRICT
SM251658,-59.9,-11.59,Apiaca,ETHNICITY,ETHNICITY
SM268031,-41.561,-21.0803,Apiacá,ADM2,DISTRICT
SM263213,-57.7595,-9.0396,Apiacás,ADM2,DISTRICT
SM225604,-49.7717,-6.5,Apiaka-Tocantins,LANGUAGE,LANGUAGE
SM225605,-57.91,-7.8471,Apiaká,LANGUAGE,LANGUAGE
SM266655,-48.8224,-24.3977,Apiaí,ADM2,DISTRICT
SM262848,-45.0854,-1.4804,Apicum-Açu,ADM2,DISTRICT
SM251659,-47.39,-6.08,Apinaye,ETHNICITY,ETHNICITY
SM251659,-47.39,-6.08,Apinaye,ETHNICITY,ETHNICITY
SM251659,-49,-6,Apinaye,ETHNICITY,ETHNICITY
SM251659,-49,-6,Apinaye,ETHNICITY,ETHNICITY
SM225606,-47.6315,-6.1077,Apinayé,LANGUAGE,LANGUAGE
SM282719,-98.127,19.423,Apizaco,ADM2,DISTRICT
SM266206,-49.3813,-27.1074,Apiúna,ADM2,DISTRICT
SM262167,1.6833,6.9333,Aplahoué,ADM2,DISTRICT
SM10631,33.4808,34.8286,Aplanta,ADM2,DISTRICT
SM10632,33.1147,34.9187,Apliki,ADM2,DISTRICT
SM225607,168.189,-15.8559,Apma,LANGUAGE,LANGUAGE
SM282195,-100.1864,25.79,Apodaca,ADM2,DISTRICT
SM265439,-37.9234,-5.6669,Apodi,ADM2,DISTRICT
SM291162,24.8361,46.1443,Apold,ADM2,DISTRICT
SM291729,23.8504,45.896,Apoldu De Jos,ADM2,DISTRICT
SM225613,-68.66,-14.83,Apolista,LANGUAGE,LANGUAGE
SM251660,-68.28,-15.22,Apolista,ETHNICITY,ETHNICITY
SM295521,-89.195,13.7965,Apopa,ADM2,DISTRICT
SM282065,-100.3958,19.6519,Aporo,ADM2,DISTRICT
SM267487,-38.2278,-11.7544,Aporá,ADM2,DISTRICT
SM268177,-52.0694,-18.7433,Aporé,ADM2,DISTRICT
SM291523,26.2654,45.1212,Apostolache,ADM2,DISTRICT
SM283038,-103.0556,21.4677,Apozol,ADM2,DISTRICT
SM300817,-92.8686,40.7432,Appanoose,ADM2,DISTRICT
SM453,9.2828,47.3629,Appenzell Ausserrhoden,ADM1,DISTRICT
SM269021,9.4306,47.3413,Appenzell Innerrhoden,ADM2,DISTRICT
SM454,9.4056,47.3126,Appenzell Innerrhoden,ADM1,DISTRICT
SM285829,6.8486,53.3193,Appingedam,ADM2,DISTRICT
SM300380,-82.289,31.7493,Appling,ADM2,DISTRICT
SM303036,-78.8121,37.3723,Appomattox,ADM2,DISTRICT
SM262543,24.9167,42.8333,Apriltsi,ADM2,DISTRICT
SM225620,-4.5219,5.2027,Aproumu Aizi,LANGUAGE,LANGUAGE
SM293391,39.6801,44.2874,Apsheronskiy rayon,ADM2,DISTRICT
SM10633,33.0301,34.8007,Apsiou,ADM2,DISTRICT
SM267498,-39.7344,-13.8301,Apuarema,ADM2,DISTRICT
SM264532,-51.4536,-23.5616,Apucarana,ADM2,DISTRICT
SM225622,92.7965,12.241,Apucikwar,LANGUAGE,LANGUAGE
SM267792,-39.3037,-3.95,Apuiarés,ADM2,DISTRICT
SM283049,-102.6934,21.4666,Apulco,ADM2,DISTRICT
SM455,16.7338,41.0016,Apulia,ADM1,DISTRICT
SM225624,18.1667,40.35,Apulia-Calabrian Greek,LANGUAGE,LANGUAGE
SM225625,18.2832,40.2488,Apulian Greek,DIALECT,LANGUAGE
SM270017,-74.5977,4.509,Apulo,ADM2,DISTRICT
SM456,-68.8333,7.1667,Apure,ADM1,DISTRICT
SM225628,-66.7714,-8.2169,Apurinã,LANGUAGE,LANGUAGE
SM457,-73,-14,Apurímac,ADM1,DISTRICT
SM225629,126.496,-7.8473,Aputai,LANGUAGE,LANGUAGE
SM267348,-59.341,-7.7892,Apuí,ADM2,DISTRICT
SM270425,-75.9576,5.1396,Apía,ADM2,DISTRICT
SM260898,-55.75,-27.9167,Apóstoles,ADM2,DISTRICT
SM458,35.3333,29.75,Aqaba,ADM1,DISTRICT
SM13156,72.5,47.3333,Aqadyr Audany,ADM2,DISTRICT
SM251662,36.13,33.26,Aqaidat,ETHNICITY,ETHNICITY
SM251662,41.02,34.14,Aqaidat,ETHNICITY,ETHNICITY
SM9496,66.208,36.9041,Aqchah (2005-),ADM2,DISTRICT
SM3947,69.9167,51.8333,Aqmola Oblysy (excl. Nur Sultan),ADM1,DISTRICT
SM13157,76.75,52.1667,Aqsu Audany (exc. Aksu City),ADM2,DISTRICT
SM13158,79.5,45.6667,Aqsu Audany (excl. Qapal),ADM2,DISTRICT
SM13159,82.5,47.8333,Aqsuat Audany,ADM2,DISTRICT
SM13160,51.25,43.6667,Aqtau Qalasy (excl. Munaily),ADM2,DISTRICT
SM13161,57.1667,50.5,Aqtobe Audany (excl. Aqtobe),ADM2,DISTRICT
SM13162,57.1667,50.2833,Aqtobe Qalasy,ADM2,DISTRICT
SM13163,74.75,48.5,Aqtoghay Audany (excl. Balqash and Toqyrauyn),ADM2,DISTRICT
SM460,58.5833,48.5833,Aqtöbe,ADM1:ADMX,DISTRICT
SM267087,-37.1045,-10.3162,Aquidabã,ADM2,DISTRICT
SM263097,-55.838,-20.4579,Aquidauana,ADM2,DISTRICT
SM282796,-97.3232,18.7896,Aquila,ADM2,DISTRICT
SM282076,-103.2231,18.3759,Aquila,ADM2,DISTRICT
SM283244,-105.8811,28.5845,Aquiles Serdán,ADM2,DISTRICT
SM276075,-73.3805,18.2975,Aquin,ADM2,DISTRICT
SM267803,-38.3913,-3.9016,Aquiraz,ADM2,DISTRICT
SM282496,-99.0999,21.6959,Aquismón,ADM2,DISTRICT
SM270752,-72.9019,5.4034,Aquitania,ADM2,DISTRICT
SM282301,-97.9353,19.7796,Aquixtla,ADM2,DISTRICT
SM13164,51.5833,50.6667,Aqzhayyq Audany (excl. Chapaev and Taypaq),ADM2,DISTRICT
SM304881,44.5642,14.212,Ar Radmah,ADM2,DISTRICT
SM305764,43.3,33.4167,Ar Ramadi,ADM2,DISTRICT
SM461,38.9333,36,Ar Raqqah,ADM1,DISTRICT
SM17761,43.55,25.5667,Ar Rass,ADM2,DISTRICT
SM304971,47.2746,14.4956,Ar Rawdah,ADM2,DISTRICT
SM304832,50.3133,15.1841,Ar Raydah Wa Qusayar,ADM2,DISTRICT
SM17762,42.8167,17.65,Ar Rayth,ADM2,DISTRICT
SM17763,47.0833,24.6667,Ar Riyad,ADM2,DISTRICT
SM463,45.55,23.05,Ar Riyad,ADM1,DISTRICT
SM305080,43.6606,15.4101,Ar Rujum,ADM2,DISTRICT
SM305765,41,33,Ar Rutbah,ADM2,DISTRICT
SM305017,44.7754,14.2424,Ar Ryashyyah,ADM2,DISTRICT
SM273901,30.6055,31.0968,Ar-Rahmaniyah,ADM2,DISTRICT
SM296765,39.0471,35.872,Ar-Raqqah,ADM2,DISTRICT
SM296785,36.7725,34.871,Ar-Rastan,ADM2,DISTRICT
SM273833,30.9281,31.3148,Ar-Riyad,ADM2,DISTRICT
SM277971,38.1718,32.3612,Ar-Ruwayshid,ADM2,DISTRICT
SM251669,44.583,15.1665,Arab-Yemen,ETHNICITY,ETHNICITY
SM251669,44.583,15.1665,Arab-Yemen,ETHNICITY,ETHNICITY
SM251669,44.583,15.1665,Arab-Yemen,ETHNICITY,ETHNICITY
SM251669,45,15,Arab-Yemen,ETHNICITY,ETHNICITY
SM251669,45,15,Arab-Yemen,ETHNICITY,ETHNICITY
SM251669,45,15,Arab-Yemen,ETHNICITY,ETHNICITY
SM251669,44.5715,14.0407,Arab-Yemen,ETHNICITY,ETHNICITY
SM251669,44.5715,14.0407,Arab-Yemen,ETHNICITY,ETHNICITY
SM251669,44.5715,14.0407,Arab-Yemen,ETHNICITY,ETHNICITY
SM298420,37.7818,37.4256,Araban,ADM2,DISTRICT
SM225634,135.449,-28.705,Arabana,LANGUAGE,LANGUAGE
SM251015,13.83,12.19,Arabe Choua,ETHNICITY,ETHNICITY
SM251015,13.83,12.19,Arabe Choua,ETHNICITY,ETHNICITY
SM251015,13.83,12.19,Arabe Choua,ETHNICITY,ETHNICITY
SM251015,15,13,Arabe Choua,ETHNICITY,ETHNICITY
SM251015,15,13,Arabe Choua,ETHNICITY,ETHNICITY
SM251015,15,13,Arabe Choua,ETHNICITY,ETHNICITY
SM251015,14.5,12.5,Arabe Choua,ETHNICITY,ETHNICITY
SM251015,14.5,12.5,Arabe Choua,ETHNICITY,ETHNICITY
SM251015,14.5,12.5,Arabe Choua,ETHNICITY,ETHNICITY
SM225635,-75.0612,-1.9613,Arabela,LANGUAGE,LANGUAGE
SM251667,36,34,Arabic-Lebanon,ETHNICITY,ETHNICITY
SM250778,10,37,Arabic-Tunisia,ETHNICITY,ETHNICITY
SM250778,10,37,Arabic-Tunisia,ETHNICITY,ETHNICITY
SM250778,10.17,36.82,Arabic-Tunisia,ETHNICITY,ETHNICITY
SM250778,10.17,36.82,Arabic-Tunisia,ETHNICITY,ETHNICITY
SM9721,44.507,40.2055,Arabkir,ADM2,DISTRICT
SM266217,-52.1813,-27.1414,Arabutã,ADM2,DISTRICT
SM264234,-35.3605,-6.8542,Aracagi,ADM2,DISTRICT
SM263502,-44.2243,-19.242,Aracai,ADM2,DISTRICT
SM267098,-37.1033,-10.9823,Aracaju,ADM2,DISTRICT
SM10376,-73.8726,10.6449,Aracataca (1996-),ADM2,DISTRICT
SM267814,-37.7669,-4.5651,Aracati,ADM2,DISTRICT
SM267520,-41.3633,-14.3901,Aracatu,ADM2,DISTRICT
SM251670,-52.12,-31.13,Arachane,ETHNICITY,ETHNICITY
SM290217,25.6793,45.8174,Araci,ADM2,DISTRICT
SM267531,-39.07,-11.1356,Araci,ADM2,DISTRICT
SM464,21.5833,42.05,Aracinovo,ADM1,DISTRICT
SM263513,-43.4086,-21.3524,Aracitaba,ADM2,DISTRICT
SM268041,-40.191,-19.8195,Aracruz,ADM2,DISTRICT
SM290506,21.3135,46.1775,Arad,ADM2,DISTRICT
SM465,21.75,46.3333,Arad,ADM1,DISTRICT
SM275765,-88.3434,14.842,Arada,ADM2,DISTRICT
SM346075,38.7556,9.0346,Arada,ADM2:ADM3,DISTRICT
SM10634,33.5766,34.9557,Aradippou,ADM2,DISTRICT
SM251672,21.4,31.85,Arafah,ETHNICITY,ETHNICITY
SM14187,-15.9719,18.0464,Arafat,ADM2,DISTRICT
SM225645,143.298,-5.1018,Arafundi-Enga Pidgin,LANGUAGE,LANGUAGE
SM268199,-52.0807,-15.9474,Aragarças,ADM2,DISTRICT
SM466,44.3628,40.2911,Aragatsotn,ADM1:ADM2,DISTRICT
SM466,44.3628,40.2911,Aragatsotn,ADM1:ADM2,DISTRICT
SM466,44.1667,40.4167,Aragatsotn,ADM1:ADM2,DISTRICT
SM466,44.1667,40.4167,Aragatsotn,ADM1:ADM2,DISTRICT
SM268203,-49.406,-16.9542,Aragoiânia,ADM2,DISTRICT
SM267277,-48.6159,-6.8926,Aragominas,ADM2,DISTRICT
SM225646,-0.0092,42.4669,Aragonese,LANGUAGE,LANGUAGE
SM461533,-1,41,Aragonese,ETHNICITY,ETHNICITY
SM303705,-64.9,9.3167,Aragua,ADM2,DISTRICT
SM468,-67.1667,10,Aragua,ADM1,DISTRICT
SM267152,-49.4091,-8.9135,Araguacema,ADM2,DISTRICT
SM263099,-51.8005,-14.9946,Araguaiana,ADM2,DISTRICT
SM263110,-53.0798,-16.7843,Araguainha,ADM2,DISTRICT
SM267185,-48.527,-6.7663,Araguanã,ADM2,DISTRICT
SM262859,-45.7587,-3.0418,Araguanã,ADM2,DISTRICT
SM268204,-50.4984,-15.1299,Araguapaz,ADM2,DISTRICT
SM263535,-48.2615,-18.5907,Araguari,ADM2,DISTRICT
SM267194,-48.0886,-5.6407,Araguatins,ADM2,DISTRICT
SM267163,-49.7494,-12.7258,Araguaçu,ADM2,DISTRICT
SM267174,-48.6211,-7.3114,Araguaína,ADM2,DISTRICT
SM467,-0.6667,41.5,Aragón,ADM1,DISTRICT
SM225648,-67.2052,-3.1043,Araicu,LANGUAGE,LANGUAGE
SM262870,-42.023,-2.8979,Araioses,ADM2,DISTRICT
SM273534,-76.7755,-1.3458,Arajuno,ADM2,DISTRICT
SM10635,33.1056,34.8521,Arakapas,ADM2,DISTRICT
SM279459,139.7813,35.7399,Arakawa,ADM2,DISTRICT
SM251675,-51.7,-4.17,Arake,ETHNICITY,ETHNICITY
SM225650,166.9525,-15.633,Araki,LANGUAGE,LANGUAGE
SM298970,39.96,40.74,Araklı,ADM2,DISTRICT
SM263022,-55.3701,-22.9095,Aral Moreira,ADM2,DISTRICT
SM279864,61.5,46.6667,Aralskiy,ADM2,DISTRICT
SM225656,119.319,-2.784,Aralle-Tabulahan,LANGUAGE,LANGUAGE
SM298467,44.5233,39.8831,Aralık,ADM2,DISTRICT
SM225658,37.3491,-2.9066,Aramanik,LANGUAGE,LANGUAGE
SM267542,-38.5576,-12.0462,Aramari,ADM2,DISTRICT
SM295718,-88.115,13.9372,Arambala,ADM2,DISTRICT
SM265673,-51.5561,-30.9133,Arambaré,ADM2,DISTRICT
SM282197,-99.8275,24.223,Aramberri,ADM2,DISTRICT
SM262881,-45.8632,-5.0316,Arame,ADM2,DISTRICT
SM225662,-16.3732,12.2993,Arame-Gubaare,LANGUAGE,LANGUAGE
SM275795,-87.693,13.736,Aramecina,ADM2,DISTRICT
SM266699,-47.8265,-20.1672,Aramina,ADM2,DISTRICT
SM225659,141.445,-8.2959,Arammba,LANGUAGE,LANGUAGE
SM225665,75.7954,11.3525,Aranadan,LANGUAGE,LANGUAGE
SM225668,-97.3853,28.6543,Aranama,LANGUAGE,LANGUAGE
SM251678,134.85,-24.29,Aranda,ETHNICITY,ETHNICITY
SM251678,134.85,-24.29,Aranda,ETHNICITY,ETHNICITY
SM251678,134.85,-24.29,Aranda,ETHNICITY,ETHNICITY
SM251678,134.85,-24.29,Aranda,ETHNICITY,ETHNICITY
SM251678,134.85,-24.29,Aranda,ETHNICITY,ETHNICITY
SM251678,134,-24,Aranda,ETHNICITY,ETHNICITY
SM251678,134,-24,Aranda,ETHNICITY,ETHNICITY
SM251678,134,-24,Aranda,ETHNICITY,ETHNICITY
SM251678,134,-24,Aranda,ETHNICITY,ETHNICITY
SM251678,134,-24,Aranda,ETHNICITY,ETHNICITY
SM251678,133.76,-23.7,Aranda,ETHNICITY,ETHNICITY
SM251678,133.76,-23.7,Aranda,ETHNICITY,ETHNICITY
SM251678,133.76,-23.7,Aranda,ETHNICITY,ETHNICITY
SM251678,133.76,-23.7,Aranda,ETHNICITY,ETHNICITY
SM251678,133.76,-23.7,Aranda,ETHNICITY,ETHNICITY
SM251678,133.5,-24.25,Aranda,ETHNICITY,ETHNICITY
SM251678,133.5,-24.25,Aranda,ETHNICITY,ETHNICITY
SM251678,133.5,-24.25,Aranda,ETHNICITY,ETHNICITY
SM251678,133.5,-24.25,Aranda,ETHNICITY,ETHNICITY
SM251678,133.5,-24.25,Aranda,ETHNICITY,ETHNICITY
SM251678,134.2609,-23.9128,Aranda,ETHNICITY,ETHNICITY
SM251678,134.2609,-23.9128,Aranda,ETHNICITY,ETHNICITY
SM251678,134.2609,-23.9128,Aranda,ETHNICITY,ETHNICITY
SM251678,134.2609,-23.9128,Aranda,ETHNICITY,ETHNICITY
SM251678,134.2609,-23.9128,Aranda,ETHNICITY,ETHNICITY
SM14212,-102.3039,20.762,Arandas (2005-),ADM2,DISTRICT
SM284493,14.8305,-22.383,Arandis,ADM2,DISTRICT
SM266710,-49.0586,-23.1706,Arandu,ADM2,DISTRICT
SM302827,-97.0738,28.015,Aransas,ADM2,DISTRICT
SM263546,-44.2263,-21.8963,Arantina,ADM2,DISTRICT
SM13318,173.6058,0.1643,Aranuka,ADM2,DISTRICT
SM297484,102.4769,13.6909,Aranyaprathet,ADM2,DISTRICT
SM270857,-75.5098,5.2552,Aranzazú,ADM2,DISTRICT
SM278655,130.4756,32.9762,Arao,ADM2,DISTRICT
SM225673,-67.7293,-12.4242,Araona,LANGUAGE,LANGUAGE
SM251679,-69.52,-9.91,Araona,ETHNICITY,ETHNICITY
SM251679,-68.15,-12.5,Araona,ETHNICITY,ETHNICITY
SM225675,-108.81,43.3879,Arapaho,LANGUAGE,LANGUAGE
SM251680,-102.69,44.67,Arapaho,ETHNICITY,ETHNICITY
SM251680,-102.69,44.67,Arapaho,ETHNICITY,ETHNICITY
SM251680,-102.69,44.67,Arapaho,ETHNICITY,ETHNICITY
SM251680,-103,40,Arapaho,ETHNICITY,ETHNICITY
SM251680,-103,40,Arapaho,ETHNICITY,ETHNICITY
SM251680,-103,40,Arapaho,ETHNICITY,ETHNICITY
SM251680,-102.72,40.13,Arapaho,ETHNICITY,ETHNICITY
SM251680,-102.72,40.13,Arapaho,ETHNICITY,ETHNICITY
SM251680,-102.72,40.13,Arapaho,ETHNICITY,ETHNICITY
SM303340,-104.3392,39.6498,Arapahoe,ADM2,DISTRICT
SM225676,-67.7282,0.1373,Arapaso,LANGUAGE,LANGUAGE
SM251681,142.94,-3.67,Arapesh,ETHNICITY,ETHNICITY
SM251681,142.94,-3.67,Arapesh,ETHNICITY,ETHNICITY
SM251681,144,-4,Arapesh,ETHNICITY,ETHNICITY
SM251681,144,-4,Arapesh,ETHNICITY,ETHNICITY
SM266721,-44.4386,-22.665,Arapeí,ADM2,DISTRICT
SM265361,-36.5932,-9.7438,Arapiraca,ADM2,DISTRICT
SM298716,38.5026,39.0503,Arapkir,ADM2,DISTRICT
SM267195,-49.0329,-7.7194,Arapoema,ADM2,DISTRICT
SM263558,-42.5111,-20.6661,Araponga,ADM2,DISTRICT
SM264543,-51.4595,-23.4473,Arapongas,ADM2,DISTRICT
SM268205,-49.1376,-18.4906,Araporã,ADM2,DISTRICT
SM264555,-50.0373,-24.069,Arapoti,ADM2,DISTRICT
SM264566,-51.8114,-24.3199,Arapu,ADM2,DISTRICT
SM263121,-58.4973,-15.1748,Araputanga,ADM2,DISTRICT
SM263569,-46.0959,-19.0307,Arapuá,ADM2,DISTRICT
SM266228,-48.8008,-26.4683,Araquari,ADM2,DISTRICT
SM264245,-35.7449,-6.8323,Arara,ADM2,DISTRICT
SM251682,-64.83,-9.57,Arara,ETHNICITY,ETHNICITY
SM251684,-68.33,1.2,Araraibo,ETHNICITY,ETHNICITY
SM266239,-49.5112,-28.9395,Araranguá,ADM2,DISTRICT
SM266732,-48.2011,-21.8276,Araraquara,ADM2,DISTRICT
SM266743,-47.3159,-22.3138,Araras,ADM2,DISTRICT
SM261227,142.8224,-37.4893,Ararat,ADM2,DISTRICT
SM470,44.8333,39.9167,Ararat,ADM1,DISTRICT
SM267836,-40.7539,-4.7706,Ararendá,ADM2,DISTRICT
SM262892,-44.7657,-3.609,Arari,ADM2,DISTRICT
SM277403,87.4,26.2,Araria,ADM2,DISTRICT
SM265684,-50.9335,-29.6499,Araricá,ADM2,DISTRICT
SM267847,-40.1143,-7.2046,Araripe,ADM2,DISTRICT
SM264878,-40.5297,-7.7102,Araripina,ADM2,DISTRICT
SM265268,-42.2752,-22.6732,Araruama,ADM2,DISTRICT
SM264577,-52.5913,-23.9722,Araruna,ADM2,DISTRICT
SM264256,-35.7219,-6.5348,Araruna,ADM2,DISTRICT
SM267553,-39.4248,-15.2461,Arataca,ADM2,DISTRICT
SM265695,-52.2901,-27.3771,Aratiba,ADM2,DISTRICT
SM270463,-73.0288,6.7045,Aratoca,ADM2,DISTRICT
SM267858,-39.0482,-4.419,Aratuba,ADM2,DISTRICT
SM9878,-39.0826,-13.0936,Aratuipe,ADM2,DISTRICT
SM251687,-60.5,-1.99,Arauaki,ETHNICITY,ETHNICITY
SM270598,-70.4797,6.8731,Arauca,ADM2,DISTRICT
SM471,-71,6.6667,Arauca,ADM1,DISTRICT
SM472,-72,-38.5,Araucanía,ADM1,DISTRICT
SM269106,-73.358,-37.7417,Arauco,ADM2,DISTRICT
SM260862,-66.8333,-28.5,Arauco,ADM2,DISTRICT
SM264588,-49.4586,-25.5985,Araucária,ADM2,DISTRICT
SM263580,-45.1568,-19.8754,Araujos,ADM2,DISTRICT
SM270599,-71.4697,6.9458,Arauquita,ADM2,DISTRICT
SM303686,-69.1667,9.6333,Araure,ADM2,DISTRICT
SM267109,-37.5695,-11.2438,Arauá,ADM2,DISTRICT
SM276838,73.3919,23.462,Aravalli,ADM2,DISTRICT
SM280333,72.52,40.459,Aravan,ADM2,DISTRICT
SM251689,-58.91,7.39,Arawak,ETHNICITY,ETHNICITY
SM251689,-58.91,7.39,Arawak,ETHNICITY,ETHNICITY
SM251689,-58.91,7.39,Arawak,ETHNICITY,ETHNICITY
SM251689,-56.63,5.7,Arawak,ETHNICITY,ETHNICITY
SM251689,-56.63,5.7,Arawak,ETHNICITY,ETHNICITY
SM251689,-56.63,5.7,Arawak,ETHNICITY,ETHNICITY
SM251689,-54.71,5.78,Arawak,ETHNICITY,ETHNICITY
SM251689,-54.71,5.78,Arawak,ETHNICITY,ETHNICITY
SM251689,-54.71,5.78,Arawak,ETHNICITY,ETHNICITY
SM251689,-52.22,4.63,Arawak,ETHNICITY,ETHNICITY
SM251689,-52.22,4.63,Arawak,ETHNICITY,ETHNICITY
SM251689,-52.22,4.63,Arawak,ETHNICITY,ETHNICITY
SM251689,-57,6,Arawak,ETHNICITY,ETHNICITY
SM251689,-57,6,Arawak,ETHNICITY,ETHNICITY
SM251689,-57,6,Arawak,ETHNICITY,ETHNICITY
SM251689,-56.98,5.95,Arawak,ETHNICITY,ETHNICITY
SM251689,-56.98,5.95,Arawak,ETHNICITY,ETHNICITY
SM251689,-56.98,5.95,Arawak,ETHNICITY,ETHNICITY
SM251690,-52.09,-5.64,Arawete,ETHNICITY,ETHNICITY
SM225692,-52.5001,-4.9025,Araweté,LANGUAGE,LANGUAGE
SM225691,145.903,-5.5279,Arawum,LANGUAGE,LANGUAGE
SM263591,-46.9798,-19.6108,Araxá,ADM2,DISTRICT
SM225701,-70.8,-13.25,Arazaire,LANGUAGE,LANGUAGE
SM298601,33.3373,41.2515,Araç,ADM2,DISTRICT
SM266666,-47.0782,-23.4229,Araçariguama,ADM2,DISTRICT
SM267509,-38.1723,-12.1468,Araças,ADM2,DISTRICT
SM266677,-50.5747,-21.1499,Araçatuba,ADM2,DISTRICT
SM264867,-35.0765,-7.7939,Araçoiaba,ADM2,DISTRICT
SM267825,-38.6777,-4.4905,Araçoiaba,ADM2,DISTRICT
SM266688,-47.6673,-23.566,Araçoiaba da Serra,ADM2,DISTRICT
SM268188,-49.7078,-16.383,Araçu,ADM2,DISTRICT
SM263524,-41.986,-16.9082,Araçuaí,ADM2,DISTRICT
SM293272,48.3333,57.75,Arbazhskiy rayon,ADM2,DISTRICT
SM270028,-74.4146,4.2125,Arbeláez,ADM2,DISTRICT
SM473,44.2,36.3333,Arbil,ADM1,DISTRICT
SM296556,15.7926,59.3945,Arboga,ADM2,DISTRICT
SM270333,-77.1361,1.4983,Arboleda,ADM2,DISTRICT
SM270372,-72.8605,7.6349,Arboledas,ADM2,DISTRICT
SM270170,-76.4175,8.6068,Arboletes,ADM2,DISTRICT
SM268965,9.3516,47.5487,Arbon,ADM2,DISTRICT
SM225706,36.7986,4.9218,Arbore,LANGUAGE,LANGUAGE
SM291780,25.9035,47.7478,Arbore,ADM2,DISTRICT
SM251692,37,5,Arbore,ETHNICITY,ETHNICITY
SM276800,-4.6774,54.1009,Arbory,ADM1:ADM2,DISTRICT
SM283607,99.4342,49.892,Arbulag,ADM2,DISTRICT
SM299585,31.3179,47.91,Arbuzyns'kyi,ADM2,DISTRICT
SM225703,16.7164,38.911,Arbëreshë Albanian,LANGUAGE,LANGUAGE
SM270763,-73.4678,5.7239,Arcabuco,ADM2,DISTRICT
SM12108,-76.782,18.0374,Arcadia (Saint Andrew),ADM2,DISTRICT
SM18552,-77.7923,18.2499,Arcadia (Saint James),ADM2,DISTRICT
SM18553,-76.3003,17.9223,Arcadia (Saint Thomas),ADM2,DISTRICT
SM290652,23.1411,45.0723,Arcani,ADM2,DISTRICT
SM295617,-88.7499,14.0711,Arcatao,ADM2,DISTRICT
SM263602,-46.9447,-21.3666,Arceburgo,ADM2,DISTRICT
SM281646,-100.1907,18.2663,Arcelia,ADM2,DISTRICT
SM225497,-2.56,51.75,Archaic Angloromani,LANGUAGE,LANGUAGE
SM230052,21.3486,62.4769,Archaic Finnish Swedish,LANGUAGE,LANGUAGE
SM231973,19.1629,57.9517,Archaic Gutnish,LANGUAGE,LANGUAGE
SM225709,22.84,66.32,Archaic Norrlandic,LANGUAGE,LANGUAGE
SM302838,-98.6877,33.6153,Archer,ADM2,DISTRICT
SM225708,46.8267,42.3239,Archi,LANGUAGE,LANGUAGE
SM273525,-78.2132,-0.6475,Archidona,ADM2,DISTRICT
SM290517,22.0966,46.4833,Archis,ADM2,DISTRICT
SM303351,-107.0483,37.1936,Archuleta,ADM2,DISTRICT
SM266754,-50.4298,-21.7434,Arco-íris,ADM2,DISTRICT
SM263613,-45.5676,-20.2435,Arcos,ADM2,DISTRICT
SM289352,-8.4262,41.8735,Arcos de Valdevez,ADM2,DISTRICT
SM264889,-37.0047,-8.3725,Arcoverde,ADM2,DISTRICT
SM474,42.8333,41.0833,Ardahan (1994-),ADM1,DISTRICT
SM11749,50.4167,31.9501,Ardal,ADM2,DISTRICT
SM10636,33.8898,35.3539,Ardana,ADM2,DISTRICT
SM298136,42.0667,41.1378,Ardanuç,ADM2,DISTRICT
SM293751,43.0833,55.25,Ardatovskiy rayon,ADM2,DISTRICT
SM293635,46.25,54.8333,Ardatovskiy rayon,ADM2,DISTRICT
SM475,48,38.5,Ardebil,ADM1,DISTRICT
SM274489,4.6537,49.632,Ardennes,ADM2,DISTRICT
SM292096,26.6017,46.5218,Ardeoani,ADM2,DISTRICT
SM277690,52.4333,33.45,Ardestan,ADM2,DISTRICT
SM298824,40.9864,41.1856,Ardeşen,ADM2,DISTRICT
SM277993,35.8447,32.106,Ardhah,ADM2,DISTRICT
SM262526,25.15,41.6,Ardino,ADM2,DISTRICT
SM285242,11.1671,8.8036,Ardo-Kola,ADM2,DISTRICT
SM293809,44.3333,43.1667,Ardonskiy rayon,ADM2,DISTRICT
SM291680,22.8833,47.6333,Ardud,ADM2,DISTRICT
SM290985,23.3916,47.6324,Ardusat,ADM2,DISTRICT
SM274426,4.3333,44.6667,Ardèche,ADM2,DISTRICT
SM225718,149.999,-9.6715,Are,LANGUAGE,LANGUAGE
SM263624,-46.168,-21.334,Areado,ADM2,DISTRICT
SM265279,-43.1219,-22.2342,Areal,ADM2,DISTRICT
SM266766,-48.9929,-22.0688,Arealva,ADM2,DISTRICT
SM295451,161.2631,-9.3772,Areare,ADM2,DISTRICT
SM225715,142.6311,-16.6355,Areba,LANGUAGE,LANGUAGE
SM459182,142.2228,-17.0096,Areba,ETHNICITY,ETHNICITY
SM476,-66.6746,18.4041,Arecibo,ADM1,DISTRICT
SM15433,-66.721,18.4736,Arecibo Barrio-Pueblo,ADM2,DISTRICT
SM251695,-61.86,6.73,Arecuna,ETHNICITY,ETHNICITY
SM10637,33.2048,35.0528,Arediou,ADM2,DISTRICT
SM289715,-57.3863,-25.2939,Areguá,ADM2,DISTRICT
SM264278,-35.6693,-6.9472,Areia,ADM2,DISTRICT
SM267120,-37.3012,-10.7426,Areia Branca,ADM2,DISTRICT
SM265450,-37.075,-5.0013,Areia Branca,ADM2,DISTRICT
SM264267,-36.9718,-7.1133,Areia de Baraúnas,ADM2,DISTRICT
SM264289,-35.9262,-7.044,Areial,ADM2,DISTRICT
SM266777,-44.7165,-22.6681,Areias,ADM2,DISTRICT
SM266788,-48.6527,-22.6063,Areiópolis,ADM2,DISTRICT
SM225721,105.928,17.7672,Arem,LANGUAGE,LANGUAGE
SM286278,11.6958,59.222,Aremark,ADM2,DISTRICT
SM15434,-66.9397,18.0183,Arena Barrio,ADM2,DISTRICT
SM301306,-83.7472,44.0429,Arenac,ADM2,DISTRICT
SM275802,-86.8158,15.3553,Arenal,ADM2,DISTRICT
SM10377,-74.1058,8.3417,Arenal,ADM2,DISTRICT
SM15435,-66.6694,18.4267,Arenalejos Barrio,ADM2,DISTRICT
SM15436,-67.0232,18.4267,Arenales Altos Barrio,ADM2,DISTRICT
SM15437,-67.0247,18.4592,Arenales Bajos Barrio,ADM2,DISTRICT
SM15438,-67.1067,18.4711,Arenales Barrio,ADM2,DISTRICT
SM15439,-66.1402,18.1606,Arenas Barrio (Cidra),ADM2,DISTRICT
SM15440,-66.7079,18.241,Arenas Barrio (Utuado),ADM2,DISTRICT
SM286307,8.765,58.4604,Arendal,ADM2,DISTRICT
SM273615,-80.0667,-3.55,Arenillas,ADM2,DISTRICT
SM271897,-69.7682,19.191,Arenoso,ADM2,DISTRICT
SM263129,-56.8582,-14.4821,Arenápolis,ADM2,DISTRICT
SM268206,-51.6046,-16.361,Arenópolis,ADM2,DISTRICT
SM286863,-71.5,-16.3333,Arequipa,ADM2,DISTRICT
SM477,-72.25,-16,Arequipa,ADM1,DISTRICT
SM225722,-72.6333,-15.1806,Arequipa-La Unión Quechua,LANGUAGE,LANGUAGE
SM284942,4.0313,12.6984,Arewa,ADM2,DISTRICT
SM274015,38.5802,14.9183,Areza,ADM2,DISTRICT
SM277907,11.8333,43.5333,Arezzo,ADM2,DISTRICT
SM10638,32.507,35.0678,Argaka,ADM2,DISTRICT
SM10639,33.0354,35.1894,Argaki,ADM2,DISTRICT
SM283709,105.8952,47.9336,Argalant,ADM2,DISTRICT
SM289470,-7.9811,40.2353,Arganil,ADM2,DISTRICT
SM292749,60.75,55.4167,Argayashskiy rayon,ADM2,DISTRICT
SM270638,-76.1164,4.729,Argelia,ADM2,DISTRICT
SM269861,-77.257,2.3173,Argelia,ADM2,DISTRICT
SM270176,-75.1215,5.705,Argelia,ADM2,DISTRICT
SM324003,-114.0264,53.0527,Argentia Beach,ADM2:ADM3,DISTRICT
SM225727,-59.1798,-34.797,Argentine Sign Language,LANGUAGE,LANGUAGE
SM290381,23.3759,44.5092,Argetoaia,ADM2,DISTRICT
SM478,24.8333,45,Argeș,ADM1,DISTRICT
SM375361,83.1458,27.9321,Arghakhanchi,ADM2:ADM3,DISTRICT
SM260048,65.6441,31.7586,Arghandab,ADM2,DISTRICT
SM9497,66.9874,32.512,Arghandab (2005-),ADM2,DISTRICT
SM9498,70.833,37.2233,Arghanj Khwah,ADM2,DISTRICT
SM260053,66.5543,31.5209,Arghistan,ADM2,DISTRICT
SM263635,-42.8286,-21.6391,Argirita,ADM2,DISTRICT
SM9499,70.4253,37.0635,Argo,ADM2,DISTRICT
SM225728,39.756,10.6596,Argobba,LANGUAGE,LANGUAGE
SM251700,43.82,7.6,Argobe,ETHNICITY,ETHNICITY
SM284945,4.4036,12.688,Argungu,ADM2,DISTRICT
SM225729,132.5556,-2.6458,Arguni,LANGUAGE,LANGUAGE
SM251702,133.36,-2.82,Arguni,ETHNICITY,ETHNICITY
SM298717,38.2635,38.7817,Arguvan,ADM2,DISTRICT
SM327765,-99.2475,49.3464,Argyle,ADM2:ADM3,DISTRICT
SM274739,-5.4368,56.0369,Argyll and Bute,ADM1:ADM2,DISTRICT
SM304944,44.2466,15.7995,Arhab,ADM2,DISTRICT
SM479,101.5,48,Arhangay,ADM1,DISTRICT
SM298137,41.3195,41.3483,Arhavi,ADM2,DISTRICT
SM225734,-73.6363,10.7018,Arhuaco,LANGUAGE,LANGUAGE
SM225730,165.273,-21.2866,Arhâ,LANGUAGE,LANGUAGE
SM225732,165.161,-21.3183,Arhö,LANGUAGE,LANGUAGE
SM225744,142.4,-7.9553,Ari,LANGUAGE,LANGUAGE
SM250003,37,6,Ari,ETHNICITY,ETHNICITY
SM480,10.1167,36.9833,Ariana,ADM1,DISTRICT
SM297855,10.1685,36.8654,Ariana Médina,ADM2,DISTRICT
SM225736,146.991,-6.7227,Aribwatsa,LANGUAGE,LANGUAGE
SM225735,146.919,-6.6525,Aribwaung,LANGUAGE,LANGUAGE
SM269101,-69.8561,-18.7064,Arica,ADM2,DISTRICT
SM251704,-69.74,-18.36,Arica,ETHNICITY,ETHNICITY
SM481,-69.4611,-18.5734,Arica y Parinacota,ADM1,DISTRICT
SM303629,-71.1371,8.2234,Aricagua,ADM2,DISTRICT
SM298354,40.1351,38.5642,Aricak,ADM2,DISTRICT
SM263646,-42.5979,-17.8579,Aricanduva,ADM2,DISTRICT
SM291534,25.8621,44.9451,Aricestii Rahtivani,ADM2,DISTRICT
SM291545,26.1688,45.2151,Aricestii Zeletin,ADM2,DISTRICT
SM251705,-46.18,-11.37,Aricobi,ETHNICITY,ETHNICITY
SM279512,135.1423,34.0794,Arida,ADM2,DISTRICT
SM12872,135.3342,34.0615,Arida-gun,ADM2,DISTRICT
SM289830,22.7554,46.4766,Arieseni,ADM2,DISTRICT
SM225739,149.246,-9.1557,Arifama-Miniafia,LANGUAGE,LANGUAGE
SM18021,30.3613,40.7133,Arifiye,ADM2,DISTRICT
SM225741,5.8956,7.5632,Arigidi,LANGUAGE,LANGUAGE
SM10378,-74.1358,9.8938,Ariguani (1999-),ADM2,DISTRICT
SM296733,36.552,35.7628,Arihah,ADM2,DISTRICT
SM225749,-62.7261,-12.4878,Arikapú,LANGUAGE,LANGUAGE
SM225746,-100,45,Arikara,LANGUAGE,LANGUAGE
SM251706,-100.13,45.97,Arikari,ETHNICITY,ETHNICITY
SM251706,-100.13,45.97,Arikari,ETHNICITY,ETHNICITY
SM251706,-101,46,Arikari,ETHNICITY,ETHNICITY
SM251706,-101,46,Arikari,ETHNICITY,ETHNICITY
SM225748,-63.742,-8.924,Arikem,LANGUAGE,LANGUAGE
SM251707,-63.99,-10.31,Arikem,ETHNICITY,ETHNICITY
SM482,-61.2667,10.65,Arima,ADM1:ADM2,DISTRICT
SM482,-61.2667,10.65,Arima,ADM1:ADM2,DISTRICT
SM482,-61.2792,10.625,Arima,ADM1:ADM2,DISTRICT
SM482,-61.2792,10.625,Arima,ADM1:ADM2,DISTRICT
SM225752,93,57,Arin,LANGUAGE,LANGUAGE
SM251708,-62.74,6.86,Arinagoto,ETHNICITY,ETHNICITY
SM225753,31.3071,3.5614,Aringa,LANGUAGE,LANGUAGE
SM290996,23.2244,47.5076,Arinis,ADM2,DISTRICT
SM263657,-45.942,-15.8033,Arinos,ADM2,DISTRICT
SM282087,-101.7066,19.1647,Ario,ADM2,DISTRICT
SM9879,-59.6118,-10.2259,Aripuana (1998-),ADM2:ADM3,DISTRICT
SM266117,-62.9549,-9.9105,Ariquemes,ADM2,DISTRICT
SM266799,-48.7777,-21.1769,Ariranha,ADM2,DISTRICT
SM264610,-51.5319,-24.3519,Ariranha do Ivaí,ADM2,DISTRICT
SM303672,-63.8491,11.0529,Arismendi,ADM2,DISTRICT
SM303724,-62.5833,10.7,Arismendi,ADM2,DISTRICT
SM303847,-68.369,8.4759,Arismendi,ADM2,DISTRICT
SM279133,129.8647,33.1992,Arita,ADM2,DISTRICT
SM225755,142.1387,-12.5084,Aritinngitigh,LANGUAGE,LANGUAGE
SM14213,-109.0592,28.8701,Arivechi,ADM2,DISTRICT
SM370949,47.2596,-19.046,Arivonimamo,ADM2:ADM3,DISTRICT
SM277218,79.25,11.15,Ariyalur,ADM2,DISTRICT
SM275650,-87.3333,15.6833,Arizona,ADM2,DISTRICT
SM483,-111.501,34.5003,Arizona,ADM1,DISTRICT
SM225757,-110.3814,35.8367,Arizona Tewa,LANGUAGE,LANGUAGE
SM282594,-110.1691,30.3957,Arizpe,ADM2,DISTRICT
SM274445,1.5,43,Ariège,ADM2,DISTRICT
SM296395,17.1748,66.375,Arjeplog,ADM2,DISTRICT
SM270703,-75.3588,10.1759,Arjona,ADM2,DISTRICT
SM279857,66.6667,50.1667,Arkalyk,ADM2,DISTRICT
SM302352,-91.3749,34.2908,Arkansas,ADM2,DISTRICT
SM484,-92.5004,34.7504,Arkansas,ADM1,DISTRICT
SM485,44,64,Arkhangel'sk (excl. Nenets okrug),ADM1,DISTRICT
SM294256,56.8333,54.5,Arkhangel'skiy rayon,ADM2,DISTRICT
SM293212,130.6667,49.5,Arkharinskiy rayon,ADM2,DISTRICT
SM283717,107.7972,47.7107,Arkhust,ADM2,DISTRICT
SM13412,22.6752,57.382,Arlavas pagasts,ADM2,DISTRICT
SM12109,-76.8179,18.0371,Arlene Gardens,ADM2,DISTRICT
SM269022,7.5873,47.5167,Arlesheim,ADM2,DISTRICT
SM303047,-77.101,38.8786,Arlington,ADM2,DISTRICT
SM15212,7.4333,18.8341,Arlit,ADM2,DISTRICT
SM225762,-76,2.0333,Arma,LANGUAGE,LANGUAGE
SM304972,47.2345,15.4127,Arma,ADM2,DISTRICT
SM261348,116.1275,-32.1789,Armadale,ADM2,DISTRICT
SM282507,-100.6423,22.2333,Armadillo de los Infante,ADM2,DISTRICT
SM274705,-6.4565,54.3692,"Armagh, Banbridge and Craigavon",ADM1:ADM2,DISTRICT
SM289395,-7.6855,41.0972,Armamar,ADM2,DISTRICT
SM273853,32.4992,25.6298,Armant,ADM2,DISTRICT
SM292194,24.0049,45.0343,Armasesti,ADM2,DISTRICT
SM486,44,40.1167,Armavir,ADM1,DISTRICT
SM266251,-49.0078,-28.2241,Armazém,ADM2,DISTRICT
SM265290,-41.9097,-22.7695,Armação dos Búzios,ADM2,DISTRICT
SM295566,-89.5006,13.7597,Armenia,ADM2,DISTRICT
SM270413,-75.7044,4.5331,Armenia,ADM2,DISTRICT
SM270177,-75.8123,6.1755,Armenia,ADM2,DISTRICT
SM251710,38.58,38.54,Armenian,ETHNICITY,ETHNICITY
SM251710,38.58,38.54,Armenian,ETHNICITY,ETHNICITY
SM251710,38.58,38.54,Armenian,ETHNICITY,ETHNICITY
SM251710,38.58,38.54,Armenian,ETHNICITY,ETHNICITY
SM251710,45,40,Armenian,ETHNICITY,ETHNICITY
SM251710,45,40,Armenian,ETHNICITY,ETHNICITY
SM251710,45,40,Armenian,ETHNICITY,ETHNICITY
SM251710,45,40,Armenian,ETHNICITY,ETHNICITY
SM251710,44.5,40,Armenian,ETHNICITY,ETHNICITY
SM251710,44.5,40,Armenian,ETHNICITY,ETHNICITY
SM251710,44.5,40,Armenian,ETHNICITY,ETHNICITY
SM251710,44.5,40,Armenian,ETHNICITY,ETHNICITY
SM251710,44.5454,40.1936,Armenian,ETHNICITY,ETHNICITY
SM251710,44.5454,40.1936,Armenian,ETHNICITY,ETHNICITY
SM251710,44.5454,40.1936,Armenian,ETHNICITY,ETHNICITY
SM251710,44.5454,40.1936,Armenian,ETHNICITY,ETHNICITY
SM225765,44.4767,40.1002,Armenian Sign Language,LANGUAGE,LANGUAGE
SM290001,22.352,45.219,Armenis,ADM2,DISTRICT
SM10640,33.1245,34.752,Armenochori,ADM2,DISTRICT
SM270593,-74.8813,4.9887,Armero,ADM2,DISTRICT
SM283315,-103.9836,18.9555,Armería,ADM2,DISTRICT
SM261504,151.9401,-30.4125,Armidale Dumaresq,ADM2,DISTRICT
SM10641,32.7208,34.8699,Arminou,ADM2,DISTRICT
SM294809,67.5833,56,Armizonskiy rayon,ADM2,DISTRICT
SM17847,49.0612,10.5669,Armo,ADM2,DISTRICT
SM10642,32.4823,34.8002,Armou,ADM2,DISTRICT
SM302307,-79.4645,40.8123,Armstrong,ADM2,DISTRICT
SM302849,-101.3574,34.9649,Armstrong,ADM2,DISTRICT
SM327924,-97.4507,50.6667,Armstrong,ADM2:ADM3,DISTRICT
SM299005,28.8387,40.5292,Armutlu,ADM2,DISTRICT
SM251713,17.68,19.25,Arna,ETHNICITY,ETHNICITY
SM10643,33.8497,35.2356,Arnadi,ADM2,DISTRICT
SM303486,67.8667,40.5,Arnasoy,ADM2,DISTRICT
SM298503,28.7341,41.1967,Arnavutkoy,ADM2,DISTRICT
SM267869,-40.094,-6.2361,Arneiroz,ADM2,DISTRICT
SM12110,-76.7983,17.9915,Arnett Gardens,ADM2,DISTRICT
SM285791,5.8964,52.0011,Arnhem,ADM2,DISTRICT
SM4093,171.7855,7.0614,Arno Atoll,ADM1,DISTRICT
SM246939,142.622,-3.4529,Aro,LANGUAGE,LANGUAGE
SM258893,-60.3984,-2.1022,Aroaqui,LANGUAGE,LANGUAGE
SM265060,-41.8693,-6.2035,Aroazes,ADM2,DISTRICT
SM284642,7.7658,5.4857,Arochukw,ADM2,DISTRICT
SM264300,-35.6993,-7.5206,Aroeiras,ADM2,DISTRICT
SM9880,-41.56,-7.27,Aroeiras do Itaim,ADM2,DISTRICT
SM262727,-67.9588,-17.187,Aroma,ADM2,DISTRICT
SM251714,148.99,-10.12,Aroma,ETHNICITY,ETHNICITY
SM225770,21.2,40.616,Aromanian,LANGUAGE,LANGUAGE
SM13413,26.0967,56.9062,Aronas pagasts,ADM2,DISTRICT
SM290827,27.6063,47.2158,Aroneanu,ADM2,DISTRICT
SM301206,-68.5989,46.6588,Aroostook,ADM2,DISTRICT
SM225773,147.103,-5.3167,Arop-Lokep,LANGUAGE,LANGUAGE
SM225772,142.12,-3.0698,Arop-Sissano,LANGUAGE,LANGUAGE
SM13319,176.8223,-2.6349,Arorae,ADM2,DISTRICT
SM225775,161.432,-10.2441,Arosi,LANGUAGE,LANGUAGE
SM295404,161.4913,-10.2805,Arosi East,ADM2,DISTRICT
SM295415,161.3929,-10.2437,Arosi North,ADM2,DISTRICT
SM295417,161.3759,-10.3179,Arosi South,ADM2,DISTRICT
SM295418,161.3049,-10.2517,Arosi West,ADM2,DISTRICT
SM289222,-8.2725,40.9266,Arouca,ADM2,DISTRICT
SM291740,24.6087,45.7731,Arpasu De Jos,ADM2,DISTRICT
SM298581,43.3288,40.846,Arpaçay,ADM2,DISTRICT
SM231007,6.4815,45.8051,Arpitan,LANGUAGE,LANGUAGE
SM265071,-42.4939,-6.6272,Arraial,ADM2,DISTRICT
SM265301,-42.061,-22.9551,Arraial do Cabo,ADM2,DISTRICT
SM267196,-46.9087,-12.8411,Arraias,ADM2,DISTRICT
SM286652,-79.7,8.95,Arraiján,ADM2,DISTRICT
SM289493,-7.9583,38.7677,Arraiolos,ADM2,DISTRICT
SM459393,141.9332,-12.9518,Arraythinngith,ETHNICITY,ETHNICITY
SM260780,-60,-34,Arrecifes,ADM2,DISTRICT
SM283200,-93.8956,16.235,Arriaga,ADM2,DISTRICT
SM457380,6.1667,35.1667,Arris District,ADM2,DISTRICT
SM9881,-52.3964,-31.4393,Arroio Do Padre,ADM2:ADM3,DISTRICT
SM265751,-52.8665,-32.1795,Arroio Grande,ADM2,DISTRICT
SM266262,-51.3453,-26.9162,Arroio Trinta,ADM2,DISTRICT
SM265706,-51.9682,-29.3697,Arroio do Meio,ADM2,DISTRICT
SM265718,-49.8915,-29.5293,Arroio do Sal,ADM2,DISTRICT
SM265729,-53.0606,-29.2621,Arroio do Tigre,ADM2,DISTRICT
SM265740,-51.7305,-30.2122,Arroio dos Ratos,ADM2,DISTRICT
SM289292,-7.2496,39.1382,Arronches,ADM2,DISTRICT
SM11535,-73.6667,18.45,Arrondissement de Baraderes,ADM2,DISTRICT
SM323888,-113.1525,50.7374,Arrowwood,ADM2:ADM3,DISTRICT
SM487,-66.0579,18.0008,Arroyo,ADM1,DISTRICT
SM15441,-66.0626,17.9632,Arroyo Barrio-Pueblo,ADM2,DISTRICT
SM271086,-82.371,23.0359,Arroyo Naranjo,ADM2,DISTRICT
SM282457,-99.6433,21.4226,Arroyo Seco,ADM2,DISTRICT
SM10379,-75.0422,10.2358,Arroyohondo,ADM2,DISTRICT
SM289538,-57.1625,-25.0428,Arroyos y Esteros,ADM2,DISTRICT
SM15442,-66.6499,18.3833,Arrozal Barrio,ADM2,DISTRICT
SM289267,-9.0881,38.9717,Arruda dos Vinhos,ADM2,DISTRICT
SM294723,36.5833,53.6667,Arsen'evskiy rayon,ADM2,DISTRICT
SM294723,36.5833,53.6667,Arsen'evskiy rayon,ADM2,DISTRICT
SM294723,38.0461,54.1658,Arsen'evskiy rayon,ADM2,DISTRICT
SM294723,38.0461,54.1658,Arsen'evskiy rayon,ADM2,DISTRICT
SM262665,30.3333,54.5,Arsha,ADM2,DISTRICT
SM279919,72.25,51,Arshalynskiy,ADM2,DISTRICT
SM274359,39.5,7.5,Arsi,ADM2,DISTRICT
SM250041,39.36,6.73,Arsi,ETHNICITY,ETHNICITY
SM250041,39.36,6.73,Arsi,ETHNICITY,ETHNICITY
SM250041,40,8,Arsi,ETHNICITY,ETHNICITY
SM250041,40,8,Arsi,ETHNICITY,ETHNICITY
SM298971,39.9294,40.8674,Arsin,ADM2,DISTRICT
SM294653,49.9167,56.1667,Arskiy rayon,ADM2,DISTRICT
SM10644,32.7638,34.8406,Arsos (Limassol),ADM2,DISTRICT
SM18551,33.6347,35.0775,Arsos (Lárnakas),ADM2,DISTRICT
SM251716,-47.34,61.64,Arsuk,ETHNICITY,ETHNICITY
SM292279,28.039,46.7962,Arsura,ADM2,DISTRICT
SM18022,35.8916,36.4113,Arsuz,ADM2,DISTRICT
SM225777,121.656,16.4185,Arta,LANGUAGE,LANGUAGE
SM3919,42.75,11.5,Arta,ADM1,DISTRICT
SM281986,-102.3701,18.3222,Arteaga,ADM2,DISTRICT
SM283309,-100.653,25.3662,Arteaga,ADM2,DISTRICT
SM10645,33.7144,35.3148,Artemi,ADM2,DISTRICT
SM271140,-82.8042,22.7738,Artemisa,ADM2,DISTRICT
SM1925,-82.7561,22.814,Artemisa,ADM1,DISTRICT
SM299945,37.9999,48.5957,Artemivs'ka,ADM2,DISTRICT
SM301700,-101.6959,41.5689,Arthur,ADM2,DISTRICT
SM488,-57,-30.5833,Artigas,ADM1,DISTRICT
SM298952,36.3073,40.1268,Artova,ADM2,DISTRICT
SM299618,29.4275,45.9866,Artsyz'kyi,ADM2,DISTRICT
SM18023,40.7247,37.3171,Artuklu,ADM2,DISTRICT
SM266810,-47.1273,-22.5517,Artur Nogueira,ADM2,DISTRICT
SM489,41.8333,41.1667,Artvin,ADM1,DISTRICT
SM251717,134.48,-6.15,Aru Islands,ETHNICITY,ETHNICITY
SM251718,-49.8,-0.96,Arua,ETHNICITY,ETHNICITY
SM4763,31.1667,2.9667,Arua (2005-2011),ADM1:ADM2,DISTRICT
SM225783,144.842,-4.2896,Aruamu,LANGUAGE,LANGUAGE
SM225787,-50.1233,-0.6086,Aruan,LANGUAGE,LANGUAGE
SM9882,-50.945,-14.8102,Aruana (1963-),ADM2,DISTRICT
SM225788,-69.6505,11.2868,Aruba,LANGUAGE,LANGUAGE
SM11232,-149.5128,-17.5335,Arue,ADM2,DISTRICT
SM225789,142.715,-3.3784,Aruek,LANGUAGE,LANGUAGE
SM266821,-46.3158,-23.3841,Aruja,ADM2,DISTRICT
SM491,93.37,27.06,Arunachal Pradesh,ADM1,DISTRICT
SM299074,36.774,-3.4696,Arusha,ADM2,DISTRICT
SM251103,35.63,-5.48,Arusha,ETHNICITY,ETHNICITY
SM492,36,-3,Arusha (2002-),ADM1,DISTRICT
SM299073,36.678,-3.4411,Arusha Urban,ADM2,DISTRICT
SM10493,-159.7922,-18.8633,Arutanga District,ADM2,DISTRICT
SM225799,-62.8676,4.0674,Arutani,LANGUAGE,LANGUAGE
SM11233,-146.6453,-15.2366,Arutua,ADM2,DISTRICT
SM225786,-67.98,-5.5,Aruá (Amazonas State),LANGUAGE,LANGUAGE
SM225784,-62.4357,-12.1186,Aruá (Rondonia State),LANGUAGE,LANGUAGE
SM283637,102.7754,46.2589,Arvaikheer,ADM2,DISTRICT
SM225801,23.3703,38.283,Arvanitika Albanian,LANGUAGE,LANGUAGE
SM296396,19.25,65.6667,Arvidsjaur,ADM2,DISTRICT
SM296511,12.632,59.7048,Arvika,ADM2,DISTRICT
SM251724,-77.35,64.57,Arviligyuarmiut,ETHNICITY,ETHNICITY
SM251724,-87.1,66.29,Arviligyuarmiut,ETHNICITY,ETHNICITY
SM251725,-94.41,71.22,Arviquurmiut,ETHNICITY,ETHNICITY
SM266273,-52.4489,-27.0691,Arvoredo,ADM2,DISTRICT
SM265762,-52.1963,-28.8697,Arvorezinha,ADM2,DISTRICT
SM277414,84.6904,25.1616,Arwal,ADM2,DISTRICT
SM13165,68.25,52.8333,Aryqbalyq Audany,ADM2,DISTRICT
SM13166,68.25,42.1667,Arys Audany (excl. Arys Village),ADM2,DISTRICT
SM13167,68.8,42.4333,Arys Qalasy,ADM2,DISTRICT
SM16843,43.8248,55.3885,Arzamas Urban Okrug,ADM2,DISTRICT
SM293773,43.75,55.3333,Arzamasskiy rayon (excl. Arzamas),ADM2,DISTRICT
SM303630,-71.4167,8.0333,Arzobispo Chacón,ADM2,DISTRICT
SM265461,-35.2023,-6.1966,Arês,ADM2,DISTRICT
SM303781,-68.8445,10.2483,Arístides Bastidas,ADM2,DISTRICT
SM225869,131.728,-0.7527,As,LANGUAGE,LANGUAGE
SM271649,42.11,11.01,As Eyla,ADM2,DISTRICT
SM305088,44.2198,15.3145,As Sabain,ADM2,DISTRICT
SM304882,44.3386,13.8427,As Sabrah,ADM2,DISTRICT
SM304883,44.3751,14.1661,As Saddah,ADM2,DISTRICT
SM304927,43.8566,17.0142,As Safra,ADM2,DISTRICT
SM304973,46.8693,14.2766,As Said,ADM2,DISTRICT
SM304915,43.8216,14.6866,As Salafiyah,ADM2,DISTRICT
SM305036,42.7254,15.2288,As Salif,ADM2,DISTRICT
SM305824,44.5333,30.5,As Salman,ADM2,DISTRICT
SM305825,45.262,31.2264,As Samawah,ADM2,DISTRICT
SM305018,45.3697,14.452,As Sawadiyah,ADM2,DISTRICT
SM304786,43.777,15.8134,As Sawd,ADM2,DISTRICT
SM304804,49.6686,15.998,As Sawm,ADM2,DISTRICT
SM305019,45.8311,14.1667,As Sawma'ah,ADM2,DISTRICT
SM304865,44.2118,13.8224,As Sayyani,ADM2,DISTRICT
SM304999,44.2072,13.3459,As Silw,ADM2,DISTRICT
SM304787,43.7756,15.9664,As Sudah,ADM2,DISTRICT
SM305037,43.3342,14.7484,As Sukhnah,ADM2,DISTRICT
SM17764,46.4167,20.3333,As Sulayyil,ADM2,DISTRICT
SM494,36.85,32.7667,As Suwayda',ADM1,DISTRICT
SM495,32.3,29.7,As Suways,ADM1,DISTRICT
SM273953,31.3095,29.6184,As-Saff,ADM2,DISTRICT
SM296761,37.4635,35.8331,As-Safirah,ADM2,DISTRICT
SM296772,36.2754,33.1256,As-Sanamayn,ADM2,DISTRICT
SM273920,31.1138,30.7653,As-Santah,ADM2,DISTRICT
SM493,45.3333,35.5833,As-Sulaymaniyah (pre-2014),ADM1,DISTRICT
SM296778,36.3244,35.481,As-Suqaylabiyah,ADM2,DISTRICT
SM296768,36.865,32.7813,As-Suwayda,ADM2,DISTRICT
SM284973,4.426,8.4301,Asa,ADM2,DISTRICT
SM245571,141.978,-4.9507,Asabano,LANGUAGE,LANGUAGE
SM277476,48,34.7833,Asadabad,ADM2,DISTRICT
SM9500,71.13,34.8683,Asadabad (2005-),ADM2,DISTRICT
SM278362,134.8253,35.2625,Asago,ADM2,DISTRICT
SM276674,99.55,2.7833,Asahan,ADM2,DISTRICT
SM279357,140.6555,35.7344,Asahi,ADM2,DISTRICT
SM278293,142.3647,43.7707,Asahikawa,ADM2,DISTRICT
SM279164,139.6,35.8,Asaka,ADM2,DISTRICT
SM18371,72.25,40.6333,Asaka Shahri,ADM2,DISTRICT
SM18372,72.25,40.6667,Asaka Tumani,ADM2,DISTRICT
SM279031,133.5946,34.5361,Asakuchi,ADM2,DISTRICT
SM279672,130.7417,33.4168,Asakura,ADM2,DISTRICT
SM12873,130.6067,33.4576,Asakura-gun,ADM2,DISTRICT
SM280653,126.9777,36.8079,Asan,ADM2,DISTRICT
SM496,144.7164,13.4721,Asan,ADM1,DISTRICT
SM251731,59.72,30.02,Asan,ETHNICITY,ETHNICITY
SM250042,-1.31,6.54,Asante,ETHNICITY,ETHNICITY
SM250042,-1.31,6.54,Asante,ETHNICITY,ETHNICITY
SM250042,-1.31,6.54,Asante,ETHNICITY,ETHNICITY
SM250042,-1.31,6.54,Asante,ETHNICITY,ETHNICITY
SM250042,-1.5,7,Asante,ETHNICITY,ETHNICITY
SM250042,-1.5,7,Asante,ETHNICITY,ETHNICITY
SM250042,-1.5,7,Asante,ETHNICITY,ETHNICITY
SM250042,-1.5,7,Asante,ETHNICITY,ETHNICITY
SM250042,-2,7,Asante,ETHNICITY,ETHNICITY
SM250042,-2,7,Asante,ETHNICITY,ETHNICITY
SM250042,-2,7,Asante,ETHNICITY,ETHNICITY
SM250042,-2,7,Asante,ETHNICITY,ETHNICITY
SM250042,-1,6,Asante,ETHNICITY,ETHNICITY
SM250042,-1,6,Asante,ETHNICITY,ETHNICITY
SM250042,-1,6,Asante,ETHNICITY,ETHNICITY
SM250042,-1,6,Asante,ETHNICITY,ETHNICITY
SM11373,-1.2303,6.5869,Asante Akim Central Municipal,ADM2,DISTRICT
SM11374,-0.9617,6.8758,Asante Akim North (2008-),ADM2,DISTRICT
SM274837,-1.084,6.478,Asante Akim South,ADM2,DISTRICT
SM298856,36.2073,41.0469,Asarcik,ADM2,DISTRICT
SM13414,25.9117,56.1317,Asares pagasts,ADM2,DISTRICT
SM285215,6.8397,4.7481,Asari-To,ADM2,DISTRICT
SM225809,146.508,-5.6826,Asaro'o,LANGUAGE,LANGUAGE
SM225810,145.549,-5.6935,Asas,DIALECT,LANGUAGE
SM292107,26.375,46.465,Asau,ADM2,DISTRICT
SM301130,-90.9113,30.2035,Ascension,ADM2,DISTRICT
SM497,-14.3625,-7.9417,Ascension,ADM1,DISTRICT
SM283255,-107.7315,31.2791,Ascensión,ADM2,DISTRICT
SM290111,23.4847,46.985,Aschileu Mare,ADM2,DISTRICT
SM277876,13.5531,42.8844,Ascoli Piceno,ADM2,DISTRICT
SM286753,-79.1515,-7.7241,Ascope,ADM2,DISTRICT
SM266284,-49.3708,-26.9858,Ascurra,ADM2,DISTRICT
SM262341,24.9667,41.95,Asenovgrad,ADM2,DISTRICT
SM270972,-84.1,9.8667,Aserrí,ADM2,DISTRICT
SM283676,113.577,46.3598,Asgat,ADM2,DISTRICT
SM283837,96.84,49.547,Asgat,ADM2,DISTRICT
SM283837,96.84,49.547,Asgat,ADM2,DISTRICT
SM283837,96.7262,49.5012,Asgat,ADM2,DISTRICT
SM283837,96.7262,49.5012,Asgat,ADM2,DISTRICT
SM10646,33.2503,34.7771,Asgata,ADM2,DISTRICT
SM304866,44.3619,14.0318,Ash Sha'ir,ADM2,DISTRICT
SM304862,43.4992,15.6061,Ash Shaghadirah,ADM2,DISTRICT
SM304863,43.4516,15.8553,Ash Shahil,ADM2,DISTRICT
SM304766,45.0156,12.8864,Ash Shaikh Outhman,ADM2,DISTRICT
SM305000,43.98,13.1822,Ash Shamayatayn,ADM2,DISTRICT
SM499,31.8,30.7,Ash Sharqiyah,ADM1,DISTRICT
SM500,49.75,24,Ash Sharqiyah,ADM1,DISTRICT
SM501,58.4333,22.1,Ash Sharqiyah North,ADM1,DISTRICT
SM502,59.1,21.65,Ash Sharqiyah South,ADM1,DISTRICT
SM305020,45.1263,14.307,Ash Sharyah,ADM2,DISTRICT
SM304805,49.5526,14.9723,Ash Shihr,ADM2,DISTRICT
SM17765,44.4667,26.0333,Ash Shimasiyah,ADM2,DISTRICT
SM17766,42.4167,27.0833,Ash Shinan,ADM2,DISTRICT
SM305025,44.9654,13.8347,Ash Shu'ayb,ADM2,DISTRICT
SM296752,36.0981,35.0449,Ash-Shaykh Badr,ADM2,DISTRICT
SM273974,30.8712,30.5974,Ash-Shuhada,ADM2,DISTRICT
SM11375,-0.0333,5.7,Ashaiman,ADM2,DISTRICT
SM503,-1.5,6.75,Ashanti,ADM1,DISTRICT
SM286536,171.75,-43.9,Ashburton,ADM2,DISTRICT
SM261359,116.9006,-22.2767,Ashburton,ADM2,DISTRICT
SM225823,7.3944,9.3657,Ashe,LANGUAGE,LANGUAGE
SM301975,-81.5003,36.4342,Ashe,ADM2,DISTRICT
SM278304,142.2016,43.4254,Ashibetsu,ADM2,DISTRICT
SM12874,139.0734,35.409,Ashigarakami-gun,ADM2,DISTRICT
SM12875,139.0521,35.216,Ashigarashimo-gun,ADM2,DISTRICT
SM279315,139.453,36.3593,Ashikaga,ADM2,DISTRICT
SM12876,130.5286,32.29,Ashikita-gun,ADM2,DISTRICT
SM16844,57.5,55,Ashinskiy Rayon,ADM2,DISTRICT
SM278363,135.2976,34.7485,Ashiya,ADM2,DISTRICT
SM251733,21.0167,52.2333,Ashkenazim (Jewish),ETHNICITY,ETHNICITY
SM225832,70.3745,34.8428,Ashkun,LANGUAGE,LANGUAGE
SM225832,70.3745,34.8428,Ashkun,LANGUAGE,LANGUAGE
SM225832,70.542,35.107,Ashkun,LANGUAGE,LANGUAGE
SM225832,70.542,35.107,Ashkun,LANGUAGE,LANGUAGE
SM303232,-90.7196,46.5097,Ashland,ADM2,DISTRICT
SM302105,-82.2707,40.846,Ashland,ADM2,DISTRICT
SM302363,-91.7685,33.1912,Ashley,ADM2,DISTRICT
SM12111,-77.3402,18.0144,Ashley,ADM2,DISTRICT
SM504,123.3333,-12.4167,Ashmore and Cartier Islands,ADM1,DISTRICT
SM273975,30.9842,30.303,Ashmun,ADM2,DISTRICT
SM262605,25.9167,54.4167,Ashmyany,ADM2,DISTRICT
SM225834,93.5069,21.393,Asho Chin,LANGUAGE,LANGUAGE
SM277001,77.73,24.58,Ashoknagar,ADM2,DISTRICT
SM12877,143.6143,43.4057,Ashoro-gun,ADM2,DISTRICT
SM12063,34.7167,31.6333,Ashqelon,ADM2,DISTRICT
SM305973,70.3502,40.6936,Asht,ADM2,DISTRICT
SM302116,-80.759,41.8964,Ashtabula,ADM2,DISTRICT
SM225837,48.6808,37.7469,Ashtiani,LANGUAGE,LANGUAGE
SM277564,50.05,34.45,Ashtiyan,ADM2,DISTRICT
SM12112,-77.9207,18.2314,Ashton,ADM2,DISTRICT
SM225821,-73.9073,-11.9311,Asháninka,LANGUAGE,LANGUAGE
SM252585,-74.25,-11.12,Asháninka,ETHNICITY,ETHNICITY
SM252585,-74.25,-11.12,Asháninka,ETHNICITY,ETHNICITY
SM252585,-75,-8,Asháninka,ETHNICITY,ETHNICITY
SM252585,-75,-8,Asháninka,ETHNICITY,ETHNICITY
SM225827,-74.2014,-10.7583,Ashéninka Pajonal,LANGUAGE,LANGUAGE
SM225826,-74.9153,-10.9777,Ashéninka Perené,LANGUAGE,LANGUAGE
SM12113,-77.4156,17.9158,Asia/Pratville,ADM2,DISTRICT
SM251734,-96.49,67.14,Asiagmiut,ETHNICITY,ETHNICITY
SM281488,-102.0833,22.2333,Asientos,ADM2,DISTRICT
SM274895,-1,5.5833,Asikuma Odoben Brakwa,ADM2,DISTRICT
SM225844,127.952,-3.673,Asilulu,LANGUAGE,LANGUAGE
SM295452,161.4658,-9.6269,Asimae,ADM2,DISTRICT
SM16845,85.6667,57.25,Asinovkiy Rayon,ADM2,DISTRICT
SM9754,28.75,53.3333,Asipovitski Rayon (excl. Asipovitski),ADM2,DISTRICT
SM10647,33.0795,34.9377,Askas,ADM2,DISTRICT
SM10648,33.611,35.1518,Askeia,ADM2,DISTRICT
SM12114,-78.1491,18.3849,Askenish,ADM2,DISTRICT
SM461864,10.436,59.8373,Asker (2020-),ADM2,DISTRICT
SM296403,14.9842,58.8805,Askersund,ADM2,DISTRICT
SM294267,56.5833,56.0833,Askinskiy rayon,ADM2,DISTRICT
SM285372,12.8782,10.6358,Askira/U,ADM2,DISTRICT
SM293205,89.75,53.25,Askizskiy rayon,ADM2,DISTRICT
SM225845,155.307,-6.2254,Askopan,LANGUAGE,LANGUAGE
SM286165,5.0532,61.3485,Askvoll,ADM2,DISTRICT
SM286377,5.226,60.4092,Askøy,ADM2,DISTRICT
SM298702,29.8822,39.2259,Aslanapa,ADM2,DISTRICT
SM304834,43.2939,16.06,Aslem,ADM2,DISTRICT
SM276524,138.4634,-5.3795,Asmat,ADM2,DISTRICT
SM274024,38.0331,16.2498,Asmat,ADM2,DISTRICT
SM251738,139.33,-5.61,Asmat,ETHNICITY,ETHNICITY
SM278652,131.0946,32.9561,Aso,ADM2,DISTRICT
SM12878,131.1023,32.8322,Aso-gun (excl. Aso town),ADM2,DISTRICT
SM225851,26.7198,2.2682,Asoa,LANGUAGE,LANGUAGE
SM11376,-1.5729,6.7115,Asokore Mampong Municipal,ADM2,DISTRICT
SM15443,-67.1723,18.384,Asomante Barrio (Aguada),ADM2,DISTRICT
SM15444,-66.304,18.1324,Asomante Barrio (Aibonito),ADM2,DISTRICT
SM10649,33.0846,35.269,Asomatos,ADM2,DISTRICT
SM274351,34.5,10,Asosa,ADM2,DISTRICT
SM303080,-117.2031,46.1919,Asotin,ADM2,DISTRICT
SM274776,43.25,41.5,Aspindza,ADM2,DISTRICT
SM10650,32.6028,34.9323,Asprogia,ADM2,DISTRICT
SM225855,15.8862,38.0166,Aspromonte,DIALECT,LANGUAGE
SM266832,-50.7312,-20.1839,Aspásia,ADM2,DISTRICT
SM461925,-9.4167,28.1667,Assa-Zag,ADM2,DISTRICT
SM505,-11.5833,16.5833,Assaba,ADM1,DISTRICT
SM305089,44.2355,15.3431,Assafi'yah,ADM2,DISTRICT
SM506,93,26,Assam (1972-),ADM1,DISTRICT
SM225857,91.2932,26.0876,Assamese,LANGUAGE,LANGUAGE
SM251740,93.21,21.19,Assamese,ETHNICITY,ETHNICITY
SM251740,93.21,21.19,Assamese,ETHNICITY,ETHNICITY
SM251740,93,26,Assamese,ETHNICITY,ETHNICITY
SM251740,93,26,Assamese,ETHNICITY,ETHNICITY
SM225860,94,56,Assan,LANGUAGE,LANGUAGE
SM225863,22.6393,13.9592,Assangori,LANGUAGE,LANGUAGE
SM251744,20.96,13.56,Assangori,ETHNICITY,ETHNICITY
SM267875,-39.8291,-6.8975,Assaré,ADM2,DISTRICT
SM264621,-50.8833,-23.4229,Assaí,ADM2,DISTRICT
SM285550,6.5526,52.9964,Assen,ADM2,DISTRICT
SM271751,10.0795,55.2996,Assens (2007-),ADM2,DISTRICT
SM274896,-1.2833,5.7,Assin North,ADM2,DISTRICT
SM274897,-1.2891,5.4764,Assin South,ADM2,DISTRICT
SM10262,19.2046,13.1764,Assinet,ADM2,DISTRICT
SM251746,-108.18,48.76,Assiniboin,ETHNICITY,ETHNICITY
SM251746,-108.18,48.76,Assiniboin,ETHNICITY,ETHNICITY
SM251746,-108.18,48.76,Assiniboin,ETHNICITY,ETHNICITY
SM251746,-106,48,Assiniboin,ETHNICITY,ETHNICITY
SM251746,-106,48,Assiniboin,ETHNICITY,ETHNICITY
SM251746,-106,48,Assiniboin,ETHNICITY,ETHNICITY
SM251746,-102.43,49.49,Assiniboin,ETHNICITY,ETHNICITY
SM251746,-102.43,49.49,Assiniboin,ETHNICITY,ETHNICITY
SM251746,-102.43,49.49,Assiniboin,ETHNICITY,ETHNICITY
SM225868,-106.068,49.2923,Assiniboine,LANGUAGE,LANGUAGE
SM266843,-50.4284,-22.5867,Assis,ADM2,DISTRICT
SM262792,-70.0131,-10.8833,Assis Brazil,ADM2,DISTRICT
SM264632,-53.5333,-24.4082,Assis Chateaubri,ADM2,DISTRICT
SM296847,1.25,9.3333,Assoli,ADM2,DISTRICT
SM296810,21.9155,13.6624,Assoungha,ADM2,DISTRICT
SM301141,-91.0626,29.9008,Assumption,ADM2,DISTRICT
SM264311,-36.6989,-7.0737,Assunção,ADM2,DISTRICT
SM265082,-40.948,-5.8557,Assunção do Piauí,ADM2,DISTRICT
SM225870,43,36.75,Assyrian Neo-Aramaic,LANGUAGE,LANGUAGE
SM282807,-97.1035,18.5657,Astacinga,ADM2,DISTRICT
SM277722,49.9667,37.3167,Astaneh-ye-Ashrafiyeh,ADM2,DISTRICT
SM277726,48.7667,38.3333,Astara,ADM2,DISTRICT
SM261933,48.6667,38.5,Astara,ADM1:ADM2,DISTRICT
SM285934,5.7791,51.3923,Asten,ADM2,DISTRICT
SM277883,8.1667,44.9167,Asti,ADM2,DISTRICT
SM292429,22.3844,47.0222,Astileu,ADM2,DISTRICT
SM263669,-42.8819,-21.3041,Astolfo Dutra,ADM2,DISTRICT
SM264643,-51.6954,-23.2401,Astorga,ADM2,DISTRICT
SM293848,48.0833,46.3667,Astrakhan',ADM2,DISTRICT
SM508,48,47,Astrakhan',ADM1,DISTRICT
SM279920,69.5,51.5,Astrakhansiy,ADM2,DISTRICT
SM262614,26,54.6667,Astravyets,ADM2,DISTRICT
SM269905,-73.9788,9.4899,Astrea,ADM2,DISTRICT
SM10651,33.0325,35.1335,Astromeritis,ADM2,DISTRICT
SM225878,-6.0223,42.4181,Asturian-Leonese-Cantabrian,LANGUAGE,LANGUAGE
SM274056,-5.8611,43.3666,Asturias,ADM2,DISTRICT
SM225882,5.4208,10.0849,Asu (Nigeria),LANGUAGE,LANGUAGE
SM225886,37.8323,-4.1798,Asu (Tanzania),LANGUAGE,LANGUAGE
SM291007,23.2,47.55,Asuaju De Sus,ADM2,DISTRICT
SM225880,139.123,-5.9834,Asue Awyu,LANGUAGE,LANGUAGE
SM225881,166.499,-11.2969,Asumboa,LANGUAGE,LANGUAGE
SM274870,-2.6948,6.8157,Asunafo North,ADM2,DISTRICT
SM274880,-2.45,6.6833,Asunafo South,ADM2,DISTRICT
SM14214,-95.9336,17.0347,Asuncion Cacalotepec,ADM2,DISTRICT
SM14215,-97.6794,17.9243,Asuncion Cuyotepeji,ADM2,DISTRICT
SM15284,145.399,19.682,Asuncion Island,ADM2,DISTRICT
SM14216,-95.0304,16.5482,Asuncion Ixtaltepec,ADM2,DISTRICT
SM14217,-97.1797,17.4375,Asuncion Nochixtlan,ADM2,DISTRICT
SM14218,-96.7219,16.7572,Asuncion Ocotlan,ADM2,DISTRICT
SM14219,-95.7662,16.2261,Asuncion Tlacolulita,ADM2,DISTRICT
SM286803,-77.3988,-9.1899,Asunción,ADM2,DISTRICT
SM289675,-57.6049,-25.2912,Asunción,ADM2,DISTRICT
SM509,-57.6354,-25.2821,Asunción,ADM1,DISTRICT
SM275190,-89.6762,14.3033,Asunción Mita,ADM2,DISTRICT
SM225883,-96.8704,16.8762,Asunción Mixtepec Zapotec,LANGUAGE,LANGUAGE
SM13415,27.5973,56.0345,Asunes pagasts,ADM2,DISTRICT
SM274912,0.0817,6.2031,Asuogyaman,ADM2,DISTRICT
SM225884,83.4562,21.5721,Asuri,LANGUAGE,LANGUAGE
SM251754,-51.79,-4.73,Asurini of Xingu,ETHNICITY,ETHNICITY
SM4339,-2.3833,6.9667,Asutifi North,ADM2,DISTRICT
SM4338,-2.4596,6.8794,Asutifi South,ADM2,DISTRICT
SM510,32.9,23.3,Aswan,ADM1,DISTRICT
SM273782,31.1314,27.1617,Asyut,ADM2,DISTRICT
SM511,31.1,27.2,Asyut,ADM1,DISTRICT
SM276149,19.5424,47.6474,Aszód,ADM2,DISTRICT
SM297464,103.8662,15.8401,At Samat,ADM2,DISTRICT
SM17767,41.25,22.25,At Ta'if,ADM2,DISTRICT
SM304979,44.0094,13.6948,At Ta'iziyah,ADM2,DISTRICT
SM305002,45.3581,14.1983,At Taffah,ADM2,DISTRICT
SM305090,44.1979,15.354,At Tahrir,ADM2,DISTRICT
SM305081,43.7748,15.4417,At Tawilah,ADM2,DISTRICT
SM305038,43.1598,14.1099,At Tuhayat,ADM2,DISTRICT
SM280326,75.8,41.1667,At-Bashi,ADM2,DISTRICT
SM512,44.1333,35.3667,At-Ta'mim,ADM1,DISTRICT
SM296745,36.3279,33.718,At-Tall,ADM2,DISTRICT
SM273946,31.9031,30.5436,At-Tall al-Kabir,ADM2,DISTRICT
SM225889,122.9,9.715,Ata,LANGUAGE,LANGUAGE
SM461494,150.8858,-5.5773,Ata,ETHNICITY,ETHNICITY
SM225898,125.499,7.7789,Ata Manobo,LANGUAGE,LANGUAGE
SM297829,125.1585,-8.8095,Atabai,ADM2,DISTRICT
SM303556,-66.5772,4.0944,Atabapo,ADM2,DISTRICT
SM298476,30.6452,37.9613,Atabey,ADM2,DISTRICT
SM513,-70,-27.5,Atacama,ADM1,DISTRICT
SM225892,-79.7781,0.8349,Atacame,LANGUAGE,LANGUAGE
SM273629,-79.8567,0.7919,Atacames,ADM2,DISTRICT
SM270594,-75.4635,3.4264,Ataco,ADM2,DISTRICT
SM273621,-79.7162,-3.5464,Atahualpa,ADM2,DISTRICT
SM225895,-93.0827,30.9121,Atakapa,LANGUAGE,LANGUAGE
SM251756,-92.41,30.06,Atakapa,ETHNICITY,ETHNICITY
SM514,1.6667,10.75,Atakora (1999-),ADM1,DISTRICT
SM18024,36.2305,41.3473,Atakum,ADM2,DISTRICT
SM285116,4.7756,7.4592,Atakumosa East,ADM2,DISTRICT
SM285127,4.6752,7.4921,Atakumosa West,ADM2,DISTRICT
SM267359,-71.8278,-5.6645,Atalaia do Norte,ADM2,DISTRICT
SM266295,-49.7609,-27.443,Atalanta,ADM2,DISTRICT
SM286852,-73.2198,-10.3898,Atalaya,ADM2,DISTRICT
SM286666,-80.9174,8.029,Atalaya,ADM2,DISTRICT
SM15445,-67.1863,18.3303,Atalaya Barrio (Aguada),ADM2,DISTRICT
SM15446,-67.1976,18.3145,Atalaya Barrio (Rincon),ADM2,DISTRICT
SM263680,-41.1672,-18.1835,Ataleia,ADM2,DISTRICT
SM264654,-52.0429,-23.1273,Ataléia,ADM2,DISTRICT
SM265372,-36.0746,-9.5298,Ataléia,ADM2,DISTRICT
SM279283,139.0636,35.0896,Atami,ADM2,DISTRICT
SM261056,-63.9,-28.6667,Atamisqui,ADM2,DISTRICT
SM225897,142.273,-11.0018,Atampaya,LANGUAGE,LANGUAGE
SM459256,142.3287,-11.5848,Atampaya,ETHNICITY,ETHNICITY
SM304974,46.8319,14.5634,Ataq,ADM2,DISTRICT
SM283979,-13.3824,20.6905,Atar,ADM2,DISTRICT
SM281576,-99.8085,21.2426,Atarjea,ADM2,DISTRICT
SM302860,-98.5271,28.8935,Atascosa,ADM2,DISTRICT
SM298514,29.1347,40.991,Atasehir,ADM2,DISTRICT
SM13416,26.4521,56.5455,Atasienes pagasts,ADM2,DISTRICT
SM225901,-97.6775,17.0713,Atatláhuca Mixtec,LANGUAGE,LANGUAGE
SM297841,125.597,-8.2678,Atauro,ADM2,DISTRICT
SM225903,121.388,24.5209,Atayal,LANGUAGE,LANGUAGE
SM251758,121.38,24.58,Atayal,ETHNICITY,ETHNICITY
SM251758,121.38,24.58,Atayal,ETHNICITY,ETHNICITY
SM251758,121.38,24.58,Atayal,ETHNICITY,ETHNICITY
SM251758,121.38,24.58,Atayal,ETHNICITY,ETHNICITY
SM251758,121,24,Atayal,ETHNICITY,ETHNICITY
SM251758,121,24,Atayal,ETHNICITY,ETHNICITY
SM251758,121,24,Atayal,ETHNICITY,ETHNICITY
SM251758,121,24,Atayal,ETHNICITY,ETHNICITY
SM251758,120.75,24.33,Atayal,ETHNICITY,ETHNICITY
SM251758,120.75,24.33,Atayal,ETHNICITY,ETHNICITY
SM251758,120.75,24.33,Atayal,ETHNICITY,ETHNICITY
SM251758,120.75,24.33,Atayal,ETHNICITY,ETHNICITY
SM251758,121.3,24.6,Atayal,ETHNICITY,ETHNICITY
SM251758,121.3,24.6,Atayal,ETHNICITY,ETHNICITY
SM251758,121.3,24.6,Atayal,ETHNICITY,ETHNICITY
SM251758,121.3,24.6,Atayal,ETHNICITY,ETHNICITY
SM295184,35.025,17.285,Atbara,ADM2,DISTRICT
SM251759,34.02,18.1,Atbara,ETHNICITY,ETHNICITY
SM279921,68.75,51.5,Atbasarskiy,ADM2,DISTRICT
SM301545,-95.4281,40.4309,Atchison,ADM2,DISTRICT
SM300911,-95.3134,39.5317,Atchison,ADM2,DISTRICT
SM274882,-1.067,7.633,Atebubu-Amantin,ADM2,DISTRICT
SM281853,-103.7118,20.1427,Atemajac de Brizuela,ADM2,DISTRICT
SM282312,-97.4418,19.8239,Atempan,ADM2,DISTRICT
SM281653,-99.1108,18.1801,Atenango del Río,ADM2,DISTRICT
SM270906,-84.396,9.9777,Atenas,ADM2,DISTRICT
SM281876,-98.9118,19.5444,Atenco,ADM2,DISTRICT
SM281740,-104.2622,20.2943,Atengo,ADM2,DISTRICT
SM281751,-104.5125,20.3706,Atenguillo,ADM2,DISTRICT
SM275191,-89.7293,14.1712,Atescatempa,ADM2,DISTRICT
SM250751,33.83,1.38,Ateso,ETHNICITY,ETHNICITY
SM250751,33.83,1.38,Ateso,ETHNICITY,ETHNICITY
SM250751,34,2,Ateso,ETHNICITY,ETHNICITY
SM250751,34,2,Ateso,ETHNICITY,ETHNICITY
SM282323,-97.6877,18.3639,Atexcal,ADM2,DISTRICT
SM273954,31.2548,29.3603,Atfih,ADM2,DISTRICT
SM260235,67.3851,31.725,Atghar,ADM2,DISTRICT
SM305091,44.1929,15.3911,Ath'thaorah,ADM2,DISTRICT
SM296766,38.3455,35.7984,Ath-Thawrah,ADM2,DISTRICT
SM324091,-113.2798,54.7177,Athabasca,ADM2:ADM3,DISTRICT
SM454420,24,38,Athenian,ETHNICITY,ETHNICITY
SM302127,-82.0451,39.3339,Athens,ADM2,DISTRICT
SM17178,-60.8918,13.9057,Athens,ADM2,DISTRICT
SM10652,33.5425,35.0595,Athienou,ADM2,DISTRICT
SM225911,142.4,-17.2,Athima,LANGUAGE,LANGUAGE
SM275147,24.3302,40.158,Athos,ADM1:ADM2,DISTRICT
SM225914,87.3296,26.8794,Athpariya,LANGUAGE,LANGUAGE
SM225915,122.512,11.1539,Ati,LANGUAGE,LANGUAGE
SM285168,3.8869,8.2663,Atiba,ADM2,DISTRICT
SM266854,-46.589,-23.131,Atibaia,ADM2,DISTRICT
SM290678,25.0436,46.4584,Atid,ADM2,DISTRICT
SM225916,-73.7989,47.9555,Atikamekw,LANGUAGE,LANGUAGE
SM251764,-71.07,48.75,Atikamekw,ETHNICITY,ETHNICITY
SM282605,-111.5657,30.8289,Atil,ADM2,DISTRICT
SM268042,-41.191,-20.9547,Atilio Vivacqua,ADM2,DISTRICT
SM275776,-88.4992,14.9223,Atima,ADM2,DISTRICT
SM291173,24.0749,46.4063,Atintis,ADM2,DISTRICT
SM295503,-89.75,13.9833,Atiquizaya,ADM2,DISTRICT
SM285173,3.2467,8.3698,Atisbo,ADM2,DISTRICT
SM281738,-99.2178,20.0569,Atitalaquia,ADM2,DISTRICT
SM4234,-158.1095,-19.9957,Atiu,ADM1,DISTRICT
SM274913,-0.6394,6.2797,Atiwa,ADM2,DISTRICT
SM281887,-99.4929,19.1768,Atizapán,ADM2,DISTRICT
SM281890,-99.2671,19.5591,Atizapán de Zaragoza,ADM2,DISTRICT
SM298274,33.082,40.8256,Atkaracalar,ADM2,DISTRICT
SM16846,45,51.9167,Atkarskiy Rayon,ADM2,DISTRICT
SM300451,-82.88,31.2971,Atkinson,ADM2,DISTRICT
SM281891,-99.8612,19.8141,Atlacomulco,ADM2,DISTRICT
SM282818,-97.1155,18.6831,Atlahuilco,ADM2,DISTRICT
SM225924,-98.6076,17.3117,Atlamajalcingo del Monte,DIALECT,LANGUAGE
SM281654,-98.5705,17.3014,Atlamajalcingo del Monte,ADM2,DISTRICT
SM282720,-98.1814,19.5458,Atlangatepec,ADM2,DISTRICT
SM301813,-74.6337,39.4688,Atlantic,ADM2,DISTRICT
SM18326,-55.7712,-34.6978,Atlantida,ADM2,DISTRICT
SM520,2.25,6.6667,Atlantique (1999-),ADM1,DISTRICT
SM281656,-98.3683,21.0251,Atlapexco,ADM2,DISTRICT
SM282109,-98.895,18.9355,Atlatlahucan,ADM2,DISTRICT
SM281892,-98.7734,19.0233,Atlautla,ADM2,DISTRICT
SM251767,-131.63,57.84,Atlin,ETHNICITY,ETHNICITY
SM282334,-98.4274,18.9015,Atlixco,ADM2,DISTRICT
SM281581,-98.903,17.5143,Atlixtac,ADM2,DISTRICT
SM516,-75,10.75,Atlántico,ADM1,DISTRICT
SM517,-84.25,14,Atlántico Norte,ADM1,DISTRICT
SM518,-84.1498,11.8459,Atlántico Sur,ADM1,DISTRICT
SM519,-87,15.6667,Atlántida,ADM1,DISTRICT
SM251768,43.96,38.11,Atmanika,ETHNICITY,ETHNICITY
SM251768,44.49,41.44,Atmanika,ETHNICITY,ETHNICITY
SM294602,49.4167,56.25,Atninskiy rayon,ADM2,DISTRICT
SM225930,138.666,-6.2712,Atohwaim,LANGUAGE,LANGUAGE
SM302194,-96.0378,34.3737,Atoka,ADM2,DISTRICT
SM283060,-103.4466,21.7773,Atolinga,ADM2,DISTRICT
SM225932,9.7212,6.0354,Atong,LANGUAGE,LANGUAGE
SM225931,90.6587,25.3465,Atong (India),LANGUAGE,LANGUAGE
SM225934,-60.286,2.481,Atorada,LANGUAGE,LANGUAGE
SM251769,-58.93,2.79,Atorai,ETHNICITY,ETHNICITY
SM281762,-102.5367,20.5499,Atotonilco El Alto,ADM2,DISTRICT
SM281657,-99.2387,19.9703,Atotonilco de Tula,ADM2,DISTRICT
SM281658,-98.6736,20.3466,Atotonilco el Grande,ADM2,DISTRICT
SM282829,-96.802,18.9318,Atoyac,ADM2,DISTRICT
SM281769,-103.4698,19.9846,Atoyac,ADM2,DISTRICT
SM281582,-100.3473,17.2723,Atoyac de Alvarez,ADM2,DISTRICT
SM282346,-97.9077,18.8144,Atoyatempan,ADM2,DISTRICT
SM10380,-76.6406,5.5736,Atrato,ADM2,DISTRICT
SM260840,-63.75,-37,Atreucó,ADM2,DISTRICT
SM297845,125.3968,-8.9306,Atsabe,ADM2,DISTRICT
SM225939,-69.5,-14.1666,Atsahuaca,LANGUAGE,LANGUAGE
SM251770,-118,42,Atsakudokwa,ETHNICITY,ETHNICITY
SM225938,8.483,9.9591,Atsam,LANGUAGE,LANGUAGE
SM280776,105.3933,16.7265,Atsaphangthong,ADM2,DISTRICT
SM280783,105.4029,16.9474,Atsaphone,ADM2,DISTRICT
SM281485,44.4,-23.2,Atsimo-Andrefana,ADM1:ADM2,DISTRICT
SM281472,47.4,-23.2,Atsimo-Atsinana,ADM1:ADM2,DISTRICT
SM251771,-102.4,52.22,Atsina,ETHNICITY,ETHNICITY
SM251771,-102.4,52.22,Atsina,ETHNICITY,ETHNICITY
SM251771,-102.4,52.22,Atsina,ETHNICITY,ETHNICITY
SM251771,-102.4,52.22,Atsina,ETHNICITY,ETHNICITY
SM251771,-102.4,52.22,Atsina,ETHNICITY,ETHNICITY
SM251771,-109,49,Atsina,ETHNICITY,ETHNICITY
SM251771,-109,49,Atsina,ETHNICITY,ETHNICITY
SM251771,-109,49,Atsina,ETHNICITY,ETHNICITY
SM251771,-109,49,Atsina,ETHNICITY,ETHNICITY
SM251771,-109,49,Atsina,ETHNICITY,ETHNICITY
SM251771,-108.98,48.78,Atsina,ETHNICITY,ETHNICITY
SM251771,-108.98,48.78,Atsina,ETHNICITY,ETHNICITY
SM251771,-108.98,48.78,Atsina,ETHNICITY,ETHNICITY
SM251771,-108.98,48.78,Atsina,ETHNICITY,ETHNICITY
SM251771,-108.98,48.78,Atsina,ETHNICITY,ETHNICITY
SM251771,-105.6,48.12,Atsina,ETHNICITY,ETHNICITY
SM251771,-105.6,48.12,Atsina,ETHNICITY,ETHNICITY
SM251771,-105.6,48.12,Atsina,ETHNICITY,ETHNICITY
SM251771,-105.6,48.12,Atsina,ETHNICITY,ETHNICITY
SM251771,-105.6,48.12,Atsina,ETHNICITY,ETHNICITY
SM251771,-108,48,Atsina,ETHNICITY,ETHNICITY
SM251771,-108,48,Atsina,ETHNICITY,ETHNICITY
SM251771,-108,48,Atsina,ETHNICITY,ETHNICITY
SM251771,-108,48,Atsina,ETHNICITY,ETHNICITY
SM251771,-108,48,Atsina,ETHNICITY,ETHNICITY
SM281482,48.9,-19,Atsinanana,ADM1:ADM2,DISTRICT
SM225941,-120.661,40.4271,Atsugewi,LANGUAGE,LANGUAGE
SM251772,-120.04,40.75,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-120.04,40.75,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-120.04,40.75,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-120.04,40.75,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-121,41,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-121,41,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-121,41,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-121,41,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-121.12,40.75,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-121.12,40.75,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-121.12,40.75,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-121.12,40.75,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-121.3,40.5,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-121.3,40.5,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-121.3,40.5,Atsugewi,ETHNICITY,ETHNICITY
SM251772,-121.3,40.5,Atsugewi,ETHNICITY,ETHNICITY
SM278537,139.3271,35.4651,Atsugi,ADM2,DISTRICT
SM301459,-89.5815,33.0863,Attala,ADM2,DISTRICT
SM225942,76.5365,11.1575,Attapady Kurumba,LANGUAGE,LANGUAGE
SM521,107,14.6667,Attapu,ADM1,DISTRICT
SM304767,44.9941,12.7741,Attawahi,ADM2,DISTRICT
SM275148,23.9035,37.9204,Attica,ADM1:ADM2,DISTRICT
SM225944,-3.8209,5.9977,Attié,LANGUAGE,LANGUAGE
SM375472,72.3342,33.5081,Attok,ADM2:ADM3,DISTRICT
SM304945,44.5774,15.3293,Attyal,ADM2,DISTRICT
SM523,-171.6333,-13.95,Atua,ADM1,DISTRICT
SM303557,-67.3462,5.6925,Atures,ADM2,DISTRICT
SM274838,-1.8167,6.6667,Atwima,ADM2,DISTRICT
SM11377,-1.7333,6.65,Atwima Kwanwoma,ADM2,DISTRICT
SM274839,-2.1167,6.6,Atwima Mponua,ADM2,DISTRICT
SM251777,31.29,5.6,Atwot,ETHNICITY,ETHNICITY
SM454612,8,10,Atyap,ETHNICITY,ETHNICITY
SM293646,46.0833,54.5833,Atyashevskiy rayon,ADM2,DISTRICT
SM524,52,47,Atyrau,ADM1:ADMX,DISTRICT
SM13168,51.9167,47.1167,Atyrau Qalasy (excl.  Balyqshy),ADM2,DISTRICT
SM289540,-57.1546,-25.2599,Atyrá,ADM2,DISTRICT
SM293653,43.4167,54.3333,Atyur'evskiy rayon,ADM2,DISTRICT
SM282841,-97.0764,18.9339,Atzacan,ADM2,DISTRICT
SM282357,-98.5503,18.546,Atzala,ADM2,DISTRICT
SM282846,-97.1057,19.907,Atzalan,ADM2,DISTRICT
SM225949,-99.9239,19.3871,Atzingo Matlatzinca,LANGUAGE,LANGUAGE
SM14221,-98.6029,18.8251,Atzitzihuacan,ADM2,DISTRICT
SM14222,-97.3027,18.9158,Atzitzintla,ADM2,DISTRICT
SM225983,142.105,-3.5328,Au,LANGUAGE,LANGUAGE
SM525,55.5167,-4.7,Au Cap,ADM1,DISTRICT
SM17179,-60.9046,13.9525,Au Leon,ADM2,DISTRICT
SM17180,-61.0411,13.9454,Au Tabor,ADM2,DISTRICT
SM17181,-61.0356,13.9446,Au Tabor Hill,ADM2,DISTRICT
SM251778,142.85,-1.74,Aua,ETHNICITY,ETHNICITY
SM251779,148.97,-24.82,Auanbura,ETHNICITY,ETHNICITY
SM274491,4.0833,48.25,Aube,ADM2,DISTRICT
SM251780,-54.76,4.66,Aucaans,ETHNICITY,ETHNICITY
SM251780,-54.76,4.66,Aucaans,ETHNICITY,ETHNICITY
SM251780,-54,5,Aucaans,ETHNICITY,ETHNICITY
SM251780,-54,5,Aucaans,ETHNICITY,ETHNICITY
SM13417,22.9043,56.4605,Auce,ADM2,DISTRICT
SM4058,22.9628,56.4631,Auces Novads,ADM1,DISTRICT
SM250821,5.43,8.47,Auchi,ETHNICITY,ETHNICITY
SM250821,5.43,8.47,Auchi,ETHNICITY,ETHNICITY
SM250821,1.98,6.46,Auchi,ETHNICITY,ETHNICITY
SM250821,1.98,6.46,Auchi,ETHNICITY,ETHNICITY
SM250821,6,7,Auchi,ETHNICITY,ETHNICITY
SM250821,6,7,Auchi,ETHNICITY,ETHNICITY
SM12115,-77.6036,18.2254,Auchtembeddie,ADM2,DISTRICT
SM286486,174.743,-36.8558,Auckland,ADM2,DISTRICT
SM526,174.7833,-36.85,Auckland,ADM1,DISTRICT
SM274450,2.5,43.0833,Aude,ADM2,DISTRICT
SM301556,-91.8416,39.2158,Audrain,ADM2,DISTRICT
SM13418,27.2338,56.588,Audrinu pagasts,ADM2,DISTRICT
SM300828,-94.9058,41.6846,Audubon,ADM2,DISTRICT
SM284946,4.5636,12.9193,Augie,ADM2,DISTRICT
SM17182,-60.9806,13.7667,Augier,ADM2,DISTRICT
SM302138,-84.2217,40.5609,Auglaize,ADM2,DISTRICT
SM13419,23.3753,56.4081,Augstkalnes pagasts,ADM2,DISTRICT
SM251782,142.9,-6.4,Augu,ETHNICITY,ETHNICITY
SM12116,-76.7367,17.9956,August Town,ADM2,DISTRICT
SM303058,-79.1338,38.1645,Augusta,ADM2,DISTRICT
SM261370,115.3228,-34.1152,Augusta-Margaret River,ADM2,DISTRICT
SM267197,-47.9154,-5.5108,Augustinópolis,ADM2,DISTRICT
SM264101,-46.4801,-0.9895,Augusto Corrêa,ADM2,DISTRICT
SM265773,-53.9793,-28.5403,Augusto Pestana,ADM2,DISTRICT
SM265472,-37.3012,-5.9052,Augusto Severo,ADM2,DISTRICT
SM263691,-44.1679,-18.1072,Augusto de Lima,ADM2,DISTRICT
SM288679,23.1428,53.7974,Augustów,ADM2,DISTRICT
SM239452,-54.6419,4.3126,Aukan,LANGUAGE,LANGUAGE
SM251783,-131.67,57.13,Auke,ETHNICITY,ETHNICITY
SM295453,160.6942,-8.7658,Auki,ADM2,DISTRICT
SM286422,6.8872,62.805,Aukra,ADM2,DISTRICT
SM250425,24.28,29.68,Aulad Suliman,ETHNICITY,ETHNICITY
SM13420,27.2723,56.0514,Aulejas pagasts,ADM2,DISTRICT
SM279858,64,52.3333,Auliekolskiy,ADM2,DISTRICT
SM250318,3.44,19.52,Aulliminden,ETHNICITY,ETHNICITY
SM250318,3.44,19.52,Aulliminden,ETHNICITY,ETHNICITY
SM250318,2,8,Aulliminden,ETHNICITY,ETHNICITY
SM250318,2,8,Aulliminden,ETHNICITY,ETHNICITY
SM225960,167.697,-16.3579,Aulua,LANGUAGE,LANGUAGE
SM4094,171.1395,8.187,Aur Atoll,ADM1,DISTRICT
SM277334,79.4286,26.6469,Auraiya,ADM2,DISTRICT
SM277425,84.5,24.75,Aurangabad,ADM2,DISTRICT
SM277062,75.3399,19.8847,"Aurangabad, MH (1989-)",ADM2,DISTRICT
SM461346,8.5278,63.2675,Aure (2006-),ADM2,DISTRICT
SM267587,-39.4796,-14.3631,Aurelino Leal,ADM2,DISTRICT
SM17183,-60.9867,14.0007,Aurendel Hill,ADM2,DISTRICT
SM294278,55.9167,54,Aurgazinskiy rayon,ADM2,DISTRICT
SM266865,-50.5937,-20.6216,Auriflama,ADM2,DISTRICT
SM268209,-50.5258,-16.6768,Aurilândia,ADM2,DISTRICT
SM286173,7.1873,60.9056,Aurland,ADM2,DISTRICT
SM302478,-98.5616,43.718,Aurora,ADM2,DISTRICT
SM527,121.5802,15.9877,Aurora,ADM1:ADM2,DISTRICT
SM266306,-49.5782,-27.3393,Aurora,ADM2,DISTRICT
SM267877,-38.9704,-7.0001,Aurora,ADM2,DISTRICT
SM264112,-47.7562,-2.3032,Aurora do Pará,ADM2,DISTRICT
SM267198,-46.4444,-12.6162,Aurora do Tocantins,ADM2,DISTRICT
SM461893,11.5649,59.8832,Aurskog-Høland (2020-),ADM2,DISTRICT
SM13421,23.2447,56.5896,Auru pagasts,ADM2,DISTRICT
SM261609,141.8244,-13.5126,Aurukun,ADM2,DISTRICT
SM225966,-46.792,-4.137,Aurê y Aurá,LANGUAGE,LANGUAGE
SM292440,22.5084,47.044,Auseu,ADM2,DISTRICT
SM225968,29.0168,-11.6295,Aushi,LANGUAGE,LANGUAGE
SM225969,-74.5411,-3.3196,Aushiri,LANGUAGE,LANGUAGE
SM225974,145,-30,Auslan,LANGUAGE,LANGUAGE
SM286388,5.2272,60.0958,Austevoll,ADM2,DISTRICT
SM302871,-96.2779,29.887,Austin,ADM2,DISTRICT
SM225975,-149.462,-23.3843,Austral,LANGUAGE,LANGUAGE
SM225973,135,-25,Australian Aborigines Sign Language,LANGUAGE,LANGUAGE
SM529,149,-35.5,Australian Capital Territory,ADM1,DISTRICT
SM286399,4.9323,60.7774,Austrheim,ADM2,DISTRICT
SM251785,12.88,47.63,Austrian,ETHNICITY,ETHNICITY
SM225972,16.225,48.1197,Austrian Sign Language,LANGUAGE,LANGUAGE
SM530,-15,65,Austurland,ADM1,DISTRICT
SM303558,-67.2473,5.0143,Autana,ADM2,DISTRICT
SM300246,-86.6428,32.5349,Autauga,ADM2,DISTRICT
SM267361,-59.6814,-3.873,Autazes,ADM2,DISTRICT
SM281770,-104.3027,19.7534,Autlán de Navarro,ADM2,DISTRICT
SM4205,20,45,Autonomna Pokrajina Vojvodina,ADM1,DISTRICT
SM4191,124.2773,7.5368,Autonomous Region in Muslim Mindanao (2001-2019),ADM1,DISTRICT
SM531,5.3744,45.482,Auvergne-Rhône-Alpes,ADM1,DISTRICT
SM225988,141.254,-3.3366,Auwe,LANGUAGE,LANGUAGE
SM226056,141.999,-3.7967,Auwon,LANGUAGE,LANGUAGE
SM251788,146.02,-6.44,Auyana,ETHNICITY,ETHNICITY
SM225991,136.096,-3.4371,Auye,LANGUAGE,LANGUAGE
SM284782,9.9877,12.3774,Auyo,ADM2,DISTRICT
SM225993,11.322,9.8575,Auyokawa,LANGUAGE,LANGUAGE
SM252833,-64.13,-20.29,Ava Guarani,ETHNICITY,ETHNICITY
SM252833,-64.13,-20.29,Ava Guarani,ETHNICITY,ETHNICITY
SM252833,-64.13,-20.29,Ava Guarani,ETHNICITY,ETHNICITY
SM252833,-63,-18.5,Ava Guarani,ETHNICITY,ETHNICITY
SM252833,-63,-18.5,Ava Guarani,ETHNICITY,ETHNICITY
SM252833,-63,-18.5,Ava Guarani,ETHNICITY,ETHNICITY
SM252833,-63,-20,Ava Guarani,ETHNICITY,ETHNICITY
SM252833,-63,-20,Ava Guarani,ETHNICITY,ETHNICITY
SM252833,-63,-20,Ava Guarani,ETHNICITY,ETHNICITY
SM11750,49.2224,35.5769,Avaj,ADM2,DISTRICT
SM226514,-67.5633,5.2612,Avane,LANGUAGE,LANGUAGE
SM266888,-49.9479,-21.466,Avanhandava,ADM2,DISTRICT
SM3940,-51.083,69.2154,Avannaata,ADM1,DISTRICT
SM298786,34.8531,38.724,Avanos,ADM2,DISTRICT
SM10494,-159.7777,-18.8507,Avanui District,ADM2,DISTRICT
SM226000,46.558,41.7047,Avar,LANGUAGE,LANGUAGE
SM251789,47,42.25,Avar,ETHNICITY,ETHNICITY
SM251789,47,42.25,Avar,ETHNICITY,ETHNICITY
SM251789,47,42.25,Avar,ETHNICITY,ETHNICITY
SM251789,46.43,42.41,Avar,ETHNICITY,ETHNICITY
SM251789,46.43,42.41,Avar,ETHNICITY,ETHNICITY
SM251789,46.43,42.41,Avar,ETHNICITY,ETHNICITY
SM251789,48.3,41.22,Avar,ETHNICITY,ETHNICITY
SM251789,48.3,41.22,Avar,ETHNICITY,ETHNICITY
SM251789,48.3,41.22,Avar,ETHNICITY,ETHNICITY
SM266899,-48.888,-23.034,Avare,ADM2,DISTRICT
SM226004,0.4131,6.8688,Avatime,LANGUAGE,LANGUAGE
SM226005,150.427,-6.2434,Avau,LANGUAGE,LANGUAGE
SM234197,167.484,-16.2701,Avava,LANGUAGE,LANGUAGE
SM266877,-49.3051,-22.1779,Avaí,ADM2,DISTRICT
SM298524,28.7186,41.0236,Avcılar,ADM2,DISTRICT
SM10653,33.5698,35.0099,Avdellero,ADM2,DISTRICT
SM10654,32.7607,34.6848,Avdimou,ADM2,DISTRICT
SM18180,37.7522,48.1419,Avdiyivs'ka Mis'krada,ADM2,DISTRICT
SM17965,0.9333,6.4333,Ave Prefecture,ADM2,DISTRICT
SM289223,-8.6163,40.624,Aveiro,ADM2,DISTRICT
SM264123,-56.0164,-3.6672,Aveiro,ADM2,DISTRICT
SM532,-8.4815,40.7297,Aveiro,ADM1,DISTRICT
SM265093,-43.8929,-10.1146,Avelino Lopes,ADM2,DISTRICT
SM268210,-49.7637,-16.4912,Avelinópolis,ADM2,DISTRICT
SM260936,-66.5,-39.5,Avellaneda,ADM2,DISTRICT
SM261057,-63.1667,-28.5,Avellaneda,ADM2,DISTRICT
SM260791,-58.3333,-34.6667,Avellaneda,ADM2,DISTRICT
SM277942,15.15,40.9833,Avellino,ADM2,DISTRICT
SM292290,27.9772,46.7981,Averesti,ADM2,DISTRICT
SM251790,-65.85,4.61,Averiano,ETHNICITY,ETHNICITY
SM301986,-81.9225,36.0766,Avery,ADM2,DISTRICT
SM286443,7.6327,63.0534,Averøy,ADM2,DISTRICT
SM296548,16.3623,60.2125,Avesta,ADM2,DISTRICT
SM226008,53.633,31.541,Avestan,LANGUAGE,LANGUAGE
SM226008,53.633,31.541,Avestan,LANGUAGE,LANGUAGE
SM226008,62.112,34.217,Avestan,LANGUAGE,LANGUAGE
SM226008,62.112,34.217,Avestan,LANGUAGE,LANGUAGE
SM274451,2.5,44.25,Aveyron,ADM2,DISTRICT
SM10655,33.9571,35.3531,Avgolida,ADM2,DISTRICT
SM10656,33.8422,35.0342,Avgorou,ADM2,DISTRICT
SM226013,-5.2529,5.1584,Avikam,LANGUAGE,LANGUAGE
SM250048,-5.25,5.15,Avikam Ou Brignan,ETHNICITY,ETHNICITY
SM251791,58.05,29.36,Avil,ETHNICITY,ETHNICITY
SM289293,-7.8956,39.0645,Avis,ADM2,DISTRICT
SM10657,33.1036,35.1652,Avlona,ADM2,DISTRICT
SM226016,167.7739,-16.5367,Avok,LANGUAGE,LANGUAGE
SM226014,29.9379,4.3864,Avokaya,LANGUAGE,LANGUAGE
SM301152,-92.0014,31.0762,Avoyelles,ADM2,DISTRICT
SM292451,21.5242,46.6694,Avram Iancu,ADM2,DISTRICT
SM289841,22.7833,46.3833,Avram Iancu,ADM2,DISTRICT
SM290689,25.0322,46.364,Avramesti,ADM2,DISTRICT
SM262113,2.6667,6.55,Avrankou,ADM2,DISTRICT
SM262458,27.7167,43.1,Avren,ADM2,DISTRICT
SM291762,24.3871,45.738,Avrig,ADM2,DISTRICT
SM295365,160.3988,-9.7936,Avuavu,ADM2,DISTRICT
SM225995,-48.1336,-13.6081,Avá-Canoeiro,LANGUAGE,LANGUAGE
SM279419,134.25,34.1,Awa,ADM2,DISTRICT
SM251793,145.98,-6.61,Awa,ETHNICITY,ETHNICITY
SM226031,145.714,-6.6553,Awa (Papua New Guinea),LANGUAGE,LANGUAGE
SM257612,-77.05,2.59,Awa Pit,ETHNICITY,ETHNICITY
SM226022,-78.3401,1.2165,Awa-Cuaiquer,LANGUAGE,LANGUAGE
SM226019,151.4356,-33.1285,Awabakal,LANGUAGE,LANGUAGE
SM459083,151.5267,-33.0551,Awabakal,ETHNICITY,ETHNICITY
SM226024,146.355,-5.599,Awad Bing,LANGUAGE,LANGUAGE
SM226023,82.4663,27.5907,Awadhi,LANGUAGE,LANGUAGE
SM251796,141.15,-4.46,Awai,ETHNICITY,ETHNICITY
SM278364,134.9133,34.4975,Awaji,ADM2,DISTRICT
SM226027,11.3291,9.8633,Awak,LANGUAGE,LANGUAGE
SM251799,53.08,23.9,Awamir,ETHNICITY,ETHNICITY
SM251801,20.79,30.84,Awaqir,ETHNICITY,ETHNICITY
SM226033,144.836,-4.1355,Awar,LANGUAGE,LANGUAGE
SM226032,146.372,-6.1699,Awara,LANGUAGE,LANGUAGE
SM279644,136.2515,36.2278,Awara,ADM2,DISTRICT
SM375374,65.4294,26.2116,Awaran,ADM2:ADM3,DISTRICT
SM251803,47.55,27.14,Awazim,ETHNICITY,ETHNICITY
SM226035,139.724,-5.0588,Awbono,LANGUAGE,LANGUAGE
SM533,43.5,10.5,Awdal,ADM1,DISTRICT
SM285014,9.1849,8.2746,Awe,ADM2,DISTRICT
SM251804,10.52,9.33,Awe,ETHNICITY,ETHNICITY
SM226038,41.234,-1.4012,Aweer,LANGUAGE,LANGUAGE
SM226041,136.063,-2.7189,Awera,LANGUAGE,LANGUAGE
SM251805,-52.2,-11.41,Aweti,ETHNICITY,ETHNICITY
SM251805,-52.2,-11.41,Aweti,ETHNICITY,ETHNICITY
SM251805,-54,-12,Aweti,ETHNICITY,ETHNICITY
SM251805,-54,-12,Aweti,ETHNICITY,ETHNICITY
SM226042,-53.3919,-12.3819,Awetí,LANGUAGE,LANGUAGE
SM284730,7.4366,6.1431,Awgu,ADM2,DISTRICT
SM226044,143.6,-4.7,Awiakay,DIALECT,LANGUAGE
SM226046,10.264,5.8836,Awing,LANGUAGE,LANGUAGE
SM226049,145.76,-6.5408,Awiyaana,LANGUAGE,LANGUAGE
SM226050,21.1819,29.2341,Awjilah,LANGUAGE,LANGUAGE
SM285305,7,6.3333,AwkaNort,ADM2,DISTRICT
SM285306,7.0678,6.2069,AwkaSout,ADM2,DISTRICT
SM251806,28.7,30.51,Awlad Ali,ETHNICITY,ETHNICITY
SM226051,36.6916,10.9819,Awngi,LANGUAGE,LANGUAGE
SM226052,141.984,-12.544,Awngthim,DIALECT,LANGUAGE
SM459258,141.8213,-12.7115,Awngthim,ETHNICITY,ETHNICITY
SM226055,141.928,-3.5313,Awtuw,LANGUAGE,LANGUAGE
SM245014,103.1016,22.9097,Awu,LANGUAGE,LANGUAGE
SM251808,-2,11,Awuna,ETHNICITY,ETHNICITY
SM226057,-0.5955,5.4244,Awutu,LANGUAGE,LANGUAGE
SM11378,-0.5152,5.5514,Awutu Senya,ADM2,DISTRICT
SM11379,-0.445,5.5719,Awutu Senya East Municipal,ADM2,DISTRICT
SM226059,140.604,-3.0056,Awyi,LANGUAGE,LANGUAGE
SM251809,138.83,-3.75,Awyu,ETHNICITY,ETHNICITY
SM226063,167.707,-16.4838,Axamb,LANGUAGE,LANGUAGE
SM281893,-98.7474,19.7321,Axapusco,ADM2,DISTRICT
SM291766,24.2254,46.0732,Axente Sever,ADM2,DISTRICT
SM226065,103.713,24.6009,Axi Yi,LANGUAGE,LANGUAGE
SM290943,26.7881,44.5869,Axintele,ADM2,DISTRICT
SM262903,-44.1052,-2.8459,Axixá,ADM2,DISTRICT
SM267199,-47.7728,-5.6485,Axixá do Tocantins,ADM2,DISTRICT
SM282120,-98.747,18.5163,Axochiapan,ADM2,DISTRICT
SM282518,-98.8705,21.4385,Axtla de Terrazas,ADM2,DISTRICT
SM282363,-98.3821,18.1877,Axutla,ADM2,DISTRICT
SM10658,32.5557,34.807,Axylou,ADM2,DISTRICT
SM286813,-79.8333,-4.75,Ayabaca,ADM2,DISTRICT
SM278667,135.3447,35.3526,Ayabe,ADM2,DISTRICT
SM303753,-72.25,8.0833,Ayacucho,ADM2,DISTRICT
SM260985,-66.5833,-32.1667,Ayacucho,ADM2,DISTRICT
SM260802,-58.5,-37,Ayacucho,ADM2,DISTRICT
SM534,-74.25,-14,Ayacucho,ADM1,DISTRICT
SM226068,-74.3225,-13.8471,Ayacucho Quechua,LANGUAGE,LANGUAGE
SM282847,-97.1485,19.3861,Ayahualulco,ADM2,DISTRICT
SM251811,32.25,28.66,Ayaida,ETHNICITY,ETHNICITY
SM13169,80.3833,47.9333,Ayakoz Qalasy (excl. Shubartau),ADM2,DISTRICT
SM282125,-98.9511,18.726,Ayala,ADM2,DISTRICT
SM251812,132.83,-1.22,Ayamaro,ETHNICITY,ETHNICITY
SM285307,6.9869,6.5536,Ayamelum,ADM2,DISTRICT
SM298906,34.6,41.83,Ayancık,ADM2,DISTRICT
SM281894,-98.8097,19.1301,Ayapango,ADM2,DISTRICT
SM10381,-75.0396,8.2722,Ayapel (1997-),ADM2,DISTRICT
SM278548,139.4309,35.44,Ayase,ADM2,DISTRICT
SM12879,133.9528,34.217,Ayauta-gun,ADM2,DISTRICT
SM226070,-96.5952,18.0993,Ayautla Mazatec,LANGUAGE,LANGUAGE
SM298899,32.3409,40.0248,Ayaş,ADM2,DISTRICT
SM260185,67.9866,36.2067,Aybak,ADM2,DISTRICT
SM298809,37.4079,40.6969,Aybastı,ADM2,DISTRICT
SM535,28,37.75,Aydin,ADM1,DISTRICT
SM298752,33.304,36.1536,Aydıncık,ADM2,DISTRICT
SM299031,35.2946,40.1373,Aydıncık,ADM2,DISTRICT
SM298875,42.0115,37.9496,Aydınlar,ADM2,DISTRICT
SM298190,40.1473,40.3914,Aydıntepe,ADM2,DISTRICT
SM285138,4.3491,7.3338,Ayedaade,ADM2,DISTRICT
SM285140,4.2345,7.5812,Ayedire,ADM2,DISTRICT
SM275140,10.7023,1.8788,Ayene,ADM2,DISTRICT
SM11380,-0.4752,5.9445,Ayensuano,ADM2,DISTRICT
SM226072,6.0198,7.5221,Ayere,LANGUAGE,LANGUAGE
SM15213,1.1726,14.9391,Ayerou,ADM2,DISTRICT
SM458467,137.4154,-21.3938,Ayerrerenge,ETHNICITY,ETHNICITY
SM536,95,17,Ayeyarwady,ADM1,DISTRICT
SM226074,98.8684,27.1213,Ayi (China),LANGUAGE,LANGUAGE
SM226075,142.407,-3.9595,Ayi (Papua New Guinea),LANGUAGE,LANGUAGE
SM226077,103.7,25,Ayizi,LANGUAGE,LANGUAGE
SM226078,2.2055,6.6265,Ayizo Gbe,LANGUAGE,LANGUAGE
SM251816,-72.55,-13.99,Aymara,ETHNICITY,ETHNICITY
SM251816,-72.55,-13.99,Aymara,ETHNICITY,ETHNICITY
SM251816,-72.55,-13.99,Aymara,ETHNICITY,ETHNICITY
SM251816,-72.55,-13.99,Aymara,ETHNICITY,ETHNICITY
SM251816,-72.55,-13.99,Aymara,ETHNICITY,ETHNICITY
SM251816,-70.22,-15.11,Aymara,ETHNICITY,ETHNICITY
SM251816,-70.22,-15.11,Aymara,ETHNICITY,ETHNICITY
SM251816,-70.22,-15.11,Aymara,ETHNICITY,ETHNICITY
SM251816,-70.22,-15.11,Aymara,ETHNICITY,ETHNICITY
SM251816,-70.22,-15.11,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.31,-16.39,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.31,-16.39,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.31,-16.39,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.31,-16.39,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.31,-16.39,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.75,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.75,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.75,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.75,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.75,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-69,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-69,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-69,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-69,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-69,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-65.75,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-65.75,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-65.75,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-65.75,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-65.75,-16,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.2,-16.5,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.2,-16.5,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.2,-16.5,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.2,-16.5,Aymara,ETHNICITY,ETHNICITY
SM251816,-68.2,-16.5,Aymara,ETHNICITY,ETHNICITY
SM286859,-73.25,-14.4167,Aymaraes,ADM2,DISTRICT
SM17927,68.7703,39.1876,Ayninskiy Rayon,ADM2,DISTRICT
SM289592,-56.9749,-27.3701,Ayolas,ADM2,DISTRICT
SM262703,-66.5074,-16.679,Ayopaya,ADM2,DISTRICT
SM226086,-96.8575,16.6633,Ayoquesco Zapotec,LANGUAGE,LANGUAGE
SM14223,-96.8592,16.6849,Ayoquezco de Aldama,ADM2,DISTRICT
SM226087,-60.2175,-19.2201,Ayoreo,LANGUAGE,LANGUAGE
SM251817,-61.69,-16.65,Ayoreo,ETHNICITY,ETHNICITY
SM251817,-61.69,-16.65,Ayoreo,ETHNICITY,ETHNICITY
SM251817,-62.16,-18.85,Ayoreo,ETHNICITY,ETHNICITY
SM251817,-62.16,-18.85,Ayoreo,ETHNICITY,ETHNICITY
SM281771,-102.3508,20.4939,Ayotlán,ADM2,DISTRICT
SM282364,-97.4089,20.0787,Ayotoxco de Guerrero,ADM2,DISTRICT
SM14224,-96.142,17.7191,Ayotzintepec,ADM2,DISTRICT
SM298110,8.9054,35.5091,Ayoun,ADM2,DISTRICT
SM298574,33.6929,37.3711,Ayrancı,ADM2,DISTRICT
SM251819,120.33,15.5,Ayta (Pinatubo),ETHNICITY,ETHNICITY
SM13170,60,50,Ayteke Bi Audany (excl. Bogetkol),ADM2,DISTRICT
SM226094,8.6023,9.2634,Ayu,LANGUAGE,LANGUAGE
SM304054,108.4189,13.3192,Ayun Pa,ADM2,DISTRICT
SM281772,-104.4001,20.0652,Ayutla,ADM2,DISTRICT
SM275280,-92.1301,14.7272,Ayutla,ADM2,DISTRICT
SM226093,-97.9545,16.4607,Ayutla Mixtec,LANGUAGE,LANGUAGE
SM281583,-99.0656,16.9505,Ayutla de los Libres,ADM2,DISTRICT
SM295523,-89.196,13.7583,Ayutuxtepeque,ADM2,DISTRICT
SM298857,36.6371,41.0024,Ayvacık,ADM2,DISTRICT
SM298262,26.4231,39.6119,Ayvacık,ADM2,DISTRICT
SM298161,26.7116,39.3231,Ayvalık,ADM2,DISTRICT
SM278007,35.6295,31.1409,Ayy,ADM2,DISTRICT
SM13171,68,53.3333,Ayyrtau Audany (excl. Aryqbalyq),ADM2,DISTRICT
SM305066,44.5244,16.3308,Az Zahir,ADM2,DISTRICT
SM305003,45.4218,13.9918,Az Zahir,ADM2,DISTRICT
SM538,12.5167,32.4667,Az Zawiyah,ADM1,DISTRICT
SM305039,43.0645,15.2959,Az Zaydiyah,ADM2,DISTRICT
SM305040,43.0637,15.7173,Az Zuhrah,ADM2,DISTRICT
SM17768,44.7,26.5333,Az Zulfi,ADM2,DISTRICT
SM305092,44.2385,15.3562,Az'zal,ADM2,DISTRICT
SM275786,-88.5713,15.3651,Azacualpa,ADM2,DISTRICT
SM295628,-88.9814,13.9907,Azacualpa,ADM2,DISTRICT
SM539,73.9104,33.9472,Azad Kashmir,ADM1,DISTRICT
SM289268,-8.8905,39.1456,Azambuja,ADM2,DISTRICT
SM277341,83,26,Azamgarh,ADM2,DISTRICT
SM457383,4.3333,36.75,Azazga District,ADM2,DISTRICT
SM283333,-99.1877,19.4873,Azcapotzalco,ADM2,DISTRICT
SM298602,33.3098,41.6532,Azdavay,ADM2,DISTRICT
SM251823,46.63,37.76,Azeri,ETHNICITY,ETHNICITY
SM251823,46.63,37.76,Azeri,ETHNICITY,ETHNICITY
SM251823,46.63,37.76,Azeri,ETHNICITY,ETHNICITY
SM251823,48.23,42.2,Azeri,ETHNICITY,ETHNICITY
SM251823,48.23,42.2,Azeri,ETHNICITY,ETHNICITY
SM251823,48.23,42.2,Azeri,ETHNICITY,ETHNICITY
SM251823,47.19,40.06,Azeri,ETHNICITY,ETHNICITY
SM251823,47.19,40.06,Azeri,ETHNICITY,ETHNICITY
SM251823,47.19,40.06,Azeri,ETHNICITY,ETHNICITY
SM226100,104.15,23.9,Azha,LANGUAGE,LANGUAGE
SM226101,103.441,24.405,Azhe,LANGUAGE,LANGUAGE
SM281430,-6.5,31.9167,Azilal,ADM2,DISTRICT
SM277556,49.4167,33.4667,Azna,ADM2,DISTRICT
SM294603,53.0718,54.8618,Aznakaevo,ADM2,DISTRICT
SM294604,53,54.9167,Aznakaevskiy rayon,ADM2,DISTRICT
SM273584,-78.8167,-2.7333,Azogues,ADM2,DISTRICT
SM540,-25.4747,37.8085,Azores,ADM1,DISTRICT
SM15319,-56.5011,-23.3234,Azotey,ADM2,DISTRICT
SM281584,-98.5783,16.6493,Azoyú (pre-2002),ADM2,DISTRICT
SM226105,-98.5241,16.7584,Azoyú Me'phaa,LANGUAGE,LANGUAGE
SM260161,69.6245,34.109,Azro,ADM2,DISTRICT
SM251824,-98.44,18.85,Azteca,ETHNICITY,ETHNICITY
SM251824,-98.44,18.85,Azteca,ETHNICITY,ETHNICITY
SM251824,-98.44,18.85,Azteca,ETHNICITY,ETHNICITY
SM251824,-98.44,18.85,Azteca,ETHNICITY,ETHNICITY
SM251824,-99.167,19,Azteca,ETHNICITY,ETHNICITY
SM251824,-99.167,19,Azteca,ETHNICITY,ETHNICITY
SM251824,-99.167,19,Azteca,ETHNICITY,ETHNICITY
SM251824,-99.167,19,Azteca,ETHNICITY,ETHNICITY
SM251824,-99,19,Azteca,ETHNICITY,ETHNICITY
SM251824,-99,19,Azteca,ETHNICITY,ETHNICITY
SM251824,-99,19,Azteca,ETHNICITY,ETHNICITY
SM251824,-99,19,Azteca,ETHNICITY,ETHNICITY
SM251824,-99.17,19,Azteca,ETHNICITY,ETHNICITY
SM251824,-99.17,19,Azteca,ETHNICITY,ETHNICITY
SM251824,-99.17,19,Azteca,ETHNICITY,ETHNICITY
SM251824,-99.17,19,Azteca,ETHNICITY,ETHNICITY
SM541,-70.8,18.5833,Azua,ADM1,DISTRICT
SM271758,-70.75,18.5,Azua de Compostela,ADM2,DISTRICT
SM542,-79.3333,-3.0833,Azuay,ADM1,DISTRICT
SM291556,25.5562,45.4451,Azuga,ADM2,DISTRICT
SM260813,-59.6667,-36.75,Azul,ADM2,DISTRICT
SM278842,137.8399,36.3272,Azumino,ADM2,DISTRICT
SM251825,9.89,14.58,Azza,ETHNICITY,ETHNICITY
SM251825,16.42,15.56,Azza,ETHNICITY,ETHNICITY
SM251825,13.51,16.35,Azza,ETHNICITY,ETHNICITY
SM286821,-70.1667,-14.8333,Azángaro,ADM2,DISTRICT
SM262803,-47.343,-4.6921,Açailândia,ADM2,DISTRICT
SM265518,-36.9251,-5.5849,Açu,ADM2,DISTRICT
SM263668,-42.4111,-19.0301,Açucena,ADM2,DISTRICT
SM457217,7.1667,35.75,Aïn Beïda District,ADM2,DISTRICT
SM307,2.1667,36.1667,Aïn Defla,ADM1,DISTRICT
SM298090,8.7122,36.7919,Aïn Draham,ADM2,DISTRICT
SM457230,5.5,36.4167,Aïn El Kébira District,ADM2,DISTRICT
SM457243,5.3,35.9167,Aïn Oulmane District,ADM2,DISTRICT
SM457244,2.5,35.3333,Aïn Oussera District,ADM2,DISTRICT
SM457245,-0.75,32.6667,Aïn Sefra District,ADM2,DISTRICT
SM308,-1.0833,35.3333,Aïn Témouchent,ADM1,DISTRICT
SM284017,-9.253,16.8046,Aïoun,ADM2,DISTRICT
SM418,-67.1274,18.2813,Añasco,ADM1,DISTRICT
SM260923,-69,-38.1667,Añelo,ADM2,DISTRICT
SM298637,33.9242,38.8845,Ağaçören,ADM2,DISTRICT
SM261905,47.4167,40,Ağcabədi,ADM1:ADM2,DISTRICT
SM261884,47,40,Ağdam,ADM1:ADM2,DISTRICT
SM261906,47.4167,40.5833,Ağdaş,ADM1:ADM2,DISTRICT
SM298234,30.5383,37.6596,Ağlasun,ADM2,DISTRICT
SM298599,33.5449,41.6928,Ağlı,ADM2,DISTRICT
SM261917,45.5,41.25,Ağstafa,ADM1:ADM2,DISTRICT
SM261913,48.3333,40.5,Ağsu,ADM1:ADM2,DISTRICT
SM298350,38.6725,38.9385,Ağın,ADM2,DISTRICT
SM498,58.3833,37.95,Aşgabat,ADM1,DISTRICT
SM298388,40.6872,39.9308,Aşkale,ADM2,DISTRICT
SM13989,-8.4898,6.3875,B'hai District,ADM2,DISTRICT
SM458073,118.373,-22.7431,BANYJIMA,ETHNICITY,ETHNICITY
SM274418,177.6667,-17.6667,Ba,ADM2,DISTRICT
SM304301,105.75,22.4167,Ba Bể,ADM2,DISTRICT
SM304416,107.193,21.295,Ba Chẽ,ADM2,DISTRICT
SM280394,105.4245,11.2802,Ba Phnum,ADM2,DISTRICT
SM304554,106.6055,10.0663,Ba Tri,ADM2,DISTRICT
SM304402,108.6933,14.7397,Ba Tơ,ADM2,DISTRICT
SM304156,105.3718,21.1499,Ba Vì,ADM2,DISTRICT
SM18472,105.8216,21.0359,Ba Đình (excl. Ba Đình Square),ADM2,DISTRICT
SM304377,106.4471,17.7742,Ba Đồn,ADM2,DISTRICT
SM304867,44.3236,13.9887,Ba'dan,ADM2,DISTRICT
SM305780,44.4895,33.5891,Baqubah,ADM2,DISTRICT
SM235609,11.4439,9.4222,Baa,LANGUAGE,LANGUAGE
SM4251,72.9731,4.891,Baa Atholhu,ADM1:ADM2,DISTRICT
SM251826,42.67,19.07,BaalahMah,ETHNICITY,ETHNICITY
SM251827,42.29,18.37,Baalasmar,ETHNICITY,ETHNICITY
SM549,36.3,34.2083,Baalbak - Hermel,ADM1,DISTRICT
SM280900,36.2716,34.0982,Baalbeck,ADM2,DISTRICT
SM250030,30.03,1.26,Baamba,ETHNICITY,ETHNICITY
SM250030,30.03,1.26,Baamba,ETHNICITY,ETHNICITY
SM250030,30,1,Baamba,ETHNICITY,ETHNICITY
SM250030,30,1,Baamba,ETHNICITY,ETHNICITY
SM226115,7.3007,4.6671,Baan,LANGUAGE,LANGUAGE
SM458448,152.1111,-30.059,Baanbay,ETHNICITY,ETHNICITY
SM226116,5.7261,10.8083,Baangi,LANGUAGE,LANGUAGE
SM295826,41.7067,2.1021,Baar-Dheere,ADM2,DISTRICT
SM285945,4.9118,51.4354,Baarle-Nassau,ADM2,DISTRICT
SM285611,5.2714,52.206,Baarn,ADM2,DISTRICT
SM226117,2.9961,9.6319,Baatonum,LANGUAGE,LANGUAGE
SM283744,99.4333,45.5504,Baatsagaan,ADM2,DISTRICT
SM226118,9.9712,6.8024,Baazem,LANGUAGE,LANGUAGE
SM298014,10.1853,36.8013,Bab Bhar,ADM2,DISTRICT
SM298025,10.163,36.8059,Bab Souika,ADM2,DISTRICT
SM226120,10.5216,6.1122,Baba,LANGUAGE,LANGUAGE
SM273478,-79.6667,-1.7833,Baba,ADM2,DISTRICT
SM251829,147.02,-7.99,Baba,ETHNICITY,ETHNICITY
SM291567,26.4859,44.9751,Baba Ana,ADM2,DISTRICT
SM226123,103.076,1.7541,Baba Malay,LANGUAGE,LANGUAGE
SM284000,-13.9326,16.4482,Bababé,ADM2,DISTRICT
SM292037,28.7186,44.8909,Babadag,ADM2,DISTRICT
SM298312,28.8682,37.8182,Babadağ,ADM2,DISTRICT
SM298644,27.1046,41.4437,Babaeski,ADM2,DISTRICT
SM294939,36,59.5,Babaevskiy rayon,ADM2,DISTRICT
SM273483,-79.5167,-1.8167,Babahoyo,ADM2,DISTRICT
SM226128,16.6925,12.7617,Babalia Creole Arabic,LANGUAGE,LANGUAGE
SM291248,24.7192,44.8754,Babana,ADM2,DISTRICT
SM226119,23.5851,1.7948,Babango,LANGUAGE,LANGUAGE
SM226122,10.2869,5.9786,Babanki,LANGUAGE,LANGUAGE
SM226124,157.149,-7.2228,Babatana,LANGUAGE,LANGUAGE
SM295340,156.7948,-6.984,Babatana,ADM2,DISTRICT
SM299089,35.8759,-4.1958,Babati,ADM2,DISTRICT
SM299088,35.7453,-4.1945,Babati Urban,ADM2,DISTRICT
SM292861,47,43.5833,Babayurtovskiy rayon,ADM2,DISTRICT
SM267201,-47.7682,-7.2052,Babaçulândia,ADM2,DISTRICT
SM9739,45.39,39.29,Babek Rayon (2004-),ADM2,DISTRICT
SM251832,15,-7,Babembe,ETHNICITY,ETHNICITY
SM292205,24.2255,44.9702,Babeni,ADM2,DISTRICT
SM291611,23.4,47.3,Babeni,ADM2,DISTRICT
SM291401,24.5653,44.0323,Babiciu,ADM2,DISTRICT
SM550,44.6167,32.5333,Babil,ADM1,DISTRICT
SM305545,18.5381,45.1163,Babina Greda,ADM2,DISTRICT
SM4000,23.7661,56.8889,Babites Novads,ADM1,DISTRICT
SM13422,23.8168,56.8941,Babites pagasts,ADM2,DISTRICT
SM250140,25,3,Baboa,ETHNICITY,ETHNICITY
SM277582,52.65,36.3167,Babol,ADM2,DISTRICT
SM17184,-60.9464,14.0054,Babonneau Proper,ADM2,DISTRICT
SM10222,14.889,5.7266,Baboua Sub-Prefecture,ADM2,DISTRICT
SM262633,29.1667,53.0833,Babruysk,ADM2,DISTRICT
SM284793,8.8587,12.614,Babura,ADM2,DISTRICT
SM294950,43.5,59.5833,Babushkinskiy rayon,ADM2,DISTRICT
SM226136,120.585,24.0017,Babuza,LANGUAGE,LANGUAGE
SM293067,35.75,54.4167,Babyninskiy rayon,ADM2,DISTRICT
SM303362,-102.5605,37.3192,Baca,ADM2,DISTRICT
SM282972,-89.402,21.1158,Baca,ADM2,DISTRICT
SM262915,-44.7418,-3.9856,Bacabal,ADM2,DISTRICT
SM262926,-44.3451,-2.8687,Bacabeira,ADM2,DISTRICT
SM282616,-109.0952,29.8545,Bacadéhuachi,ADM2,DISTRICT
SM14227,-88.4817,18.9057,Bacalar,ADM2,DISTRICT
SM226143,12.0416,9.5307,Bacama,LANGUAGE,LANGUAGE
SM226142,7.5526,10.6947,Bacama-Yimburu,LANGUAGE,LANGUAGE
SM226141,127.49,-0.6228,Bacanese Malay,LANGUAGE,LANGUAGE
SM292301,27.6791,46.3377,Bacani,ADM2,DISTRICT
SM282617,-109.3657,28.8491,Bacanora,ADM2,DISTRICT
SM292118,26.9114,46.5685,Bacau,ADM2,DISTRICT
SM282618,-108.8168,30.2976,Bacerac,ADM2,DISTRICT
SM292312,27.2367,46.8333,Bacesti,ADM2,DISTRICT
SM280848,105.9672,15.2443,Bachiangchaleunsook,ADM2,DISTRICT
SM297247,101.6492,6.5459,Bacho,ADM2,DISTRICT
SM283266,-107.2886,28.8566,Bachíniva,ADM2,DISTRICT
SM290747,23.0253,45.8083,Bacia,ADM2,DISTRICT
SM290122,23.4904,46.8201,Baciu,ADM2,DISTRICT
SM291054,23.1221,44.4803,Bacles,ADM2,DISTRICT
SM282551,-109.979,30.6532,Bacoachi,ADM2,DISTRICT
SM300462,-82.4527,31.5537,Bacon,ADM2,DISTRICT
SM226149,66.85,36.81,Bactrian,LANGUAGE,LANGUAGE
SM262936,-45.22,-1.6514,Bacuri,ADM2,DISTRICT
SM262937,-44.6548,-2.6465,Bacurituba,ADM2,DISTRICT
SM551,26.8333,46.4167,Bacău,ADM1,DISTRICT
SM226154,120.319,-1.8025,Bada (Indonesia),LANGUAGE,LANGUAGE
SM226151,9.95,9.6246,Bada (Nigeria),LANGUAGE,LANGUAGE
SM226150,76.5974,11.3094,Badaga,LANGUAGE,LANGUAGE
SM251837,77,11,Badaga,ETHNICITY,ETHNICITY
SM285005,2.9025,6.4381,Badagary,ADM2,DISTRICT
SM274043,-6.1667,38.6667,Badajoz,ADM2,DISTRICT
SM553,72,36.75,Badakhshan,ADM1,DISTRICT
SM226160,10.6557,12.789,Bade,LANGUAGE,LANGUAGE
SM285258,10.6434,12.7161,Bade,ADM2,DISTRICT
SM268899,8.3006,47.4566,Baden,ADM2,DISTRICT
SM261814,16.1985,47.9773,Baden,ADM2,DISTRICT
SM554,9,48.5,Baden-Württemberg,ADM1,DISTRICT
SM226159,72.6823,35.2655,Badeshi,LANGUAGE,LANGUAGE
SM276888,74.663,33.89,Badgam,ADM2,DISTRICT
SM555,63.75,35,Badghis,ADM1,DISTRICT
SM295769,41.6614,-1.0021,Badhaadhe,ADM2,DISTRICT
SM17848,48.3338,10.7141,Badhan (excl. Laasqoray town),ADM2,DISTRICT
SM251847,122.89,-16.92,Badi,ETHNICITY,ETHNICITY
SM251847,122.89,-16.92,Badi,ETHNICITY,ETHNICITY
SM251847,122.9023,-16.6682,Badi,ETHNICITY,ETHNICITY
SM251847,122.9023,-16.6682,Badi,ETHNICITY,ETHNICITY
SM251848,120.02,-27.8,Badimaia,ETHNICITY,ETHNICITY
SM251848,120.02,-27.8,Badimaia,ETHNICITY,ETHNICITY
SM251848,117.1853,-28.5933,Badimaia,ETHNICITY,ETHNICITY
SM251848,117.1853,-28.5933,Badimaia,ETHNICITY,ETHNICITY
SM226164,117.246,-29.0647,Badimaya,LANGUAGE,LANGUAGE
SM375480,68.8746,24.7244,Badin,ADM2:ADM3,DISTRICT
SM282543,-107.396,25.6392,Badiraguato,ADM2,DISTRICT
SM251850,119.97,5.09,Badjau Tawi-Tawi,ETHNICITY,ETHNICITY
SM251850,119.97,5.09,Badjau Tawi-Tawi,ETHNICITY,ETHNICITY
SM251850,120,5,Badjau Tawi-Tawi,ETHNICITY,ETHNICITY
SM251850,120,5,Badjau Tawi-Tawi,ETHNICITY,ETHNICITY
SM251851,149.71,-28.67,Badjeri,ETHNICITY,ETHNICITY
SM251851,149.71,-28.67,Badjeri,ETHNICITY,ETHNICITY
SM251851,145.2278,-28.4336,Badjeri,ETHNICITY,ETHNICITY
SM251851,145.2278,-28.4336,Badjeri,ETHNICITY,ETHNICITY
SM226167,143,-27.33,Badjiri,LANGUAGE,LANGUAGE
SM226167,143,-27.33,Badjiri,LANGUAGE,LANGUAGE
SM226167,145.47,-28.79,Badjiri,LANGUAGE,LANGUAGE
SM226167,145.47,-28.79,Badjiri,LANGUAGE,LANGUAGE
SM251852,13.89,-4.2,Badondo,ETHNICITY,ETHNICITY
SM17769,38.95,23.75,Badr,ADM2,DISTRICT
SM17770,43.7333,17.8,Badr al Janub,ADM2,DISTRICT
SM305815,45.9,33.0833,Badrah,ADM2,DISTRICT
SM11751,47.25,33,Badreh,ADM2,DISTRICT
SM226170,106.177,-6.3536,Badui,LANGUAGE,LANGUAGE
SM251854,106.49,-6.45,Badui,ETHNICITY,ETHNICITY
SM290255,25.2406,44.7428,Badulesti,ADM2,DISTRICT
SM556,81.0564,6.9847,Badulla,ADM1:ADM2,DISTRICT
SM276473,115.2,-8.5167,Badung,ADM2,DISTRICT
SM266910,-49.4421,-20.9334,Bady Bassitt,ADM2,DISTRICT
SM226173,160.771,-8.4901,Baeggu,LANGUAGE,LANGUAGE
SM226174,160.721,-8.4163,Baelelea,LANGUAGE,LANGUAGE
SM226175,-39.5552,-15.3525,Baenan,LANGUAGE,LANGUAGE
SM263702,-44.8556,-21.9762,Baependi,ADM2,DISTRICT
SM226176,168.159,-15.2574,Baetora,LANGUAGE,LANGUAGE
SM292989,80.6667,53.3333,Baevskiy rayon,ADM2,DISTRICT
SM226179,10.4518,5.8461,Bafanji,LANGUAGE,LANGUAGE
SM275076,-14.593,12.173,Bafata,ADM2,DISTRICT
SM557,-14.7167,12.1667,Bafatá,ADM1,DISTRICT
SM226177,9.522,4.5331,Bafaw-Balong,LANGUAGE,LANGUAGE
SM251861,-64.63,65.24,Baffin Island Inuit,ETHNICITY,ETHNICITY
SM251861,-64.63,65.24,Baffin Island Inuit,ETHNICITY,ETHNICITY
SM251861,-64.65,65.21,Baffin Island Inuit,ETHNICITY,ETHNICITY
SM251861,-64.65,65.21,Baffin Island Inuit,ETHNICITY,ETHNICITY
SM226184,11.0982,4.726,Bafia,LANGUAGE,LANGUAGE
SM250054,11.45,4.39,Bafia,ETHNICITY,ETHNICITY
SM250054,11.45,4.39,Bafia,ETHNICITY,ETHNICITY
SM250054,11.45,4.39,Bafia,ETHNICITY,ETHNICITY
SM250054,11,5,Bafia,ETHNICITY,ETHNICITY
SM250054,11,5,Bafia,ETHNICITY,ETHNICITY
SM250054,11,5,Bafia,ETHNICITY,ETHNICITY
SM250054,11,4.8,Bafia,ETHNICITY,ETHNICITY
SM250054,11,4.8,Bafia,ETHNICITY,ETHNICITY
SM250054,11,4.8,Bafia,ETHNICITY,ETHNICITY
SM269469,-7.5805,8.4061,Bafing,ADM2,DISTRICT
SM251044,26.68,-24.92,Bafokeng,ETHNICITY,ETHNICITY
SM226186,19.76,1.19,Bafoto,LANGUAGE,LANGUAGE
SM283346,-10.6667,13.6667,Bafoulabé,ADM1:ADM2,DISTRICT
SM298858,35.8444,41.4608,Bafra,ADM2,DISTRICT
SM226188,10.1194,6.1415,Bafut,LANGUAGE,LANGUAGE
SM250055,10,6,Bafut,ETHNICITY,ETHNICITY
SM250055,10,6,Bafut,ETHNICITY,ETHNICITY
SM250055,10.1314,6.0861,Bafut,ETHNICITY,ETHNICITY
SM250055,10.1314,6.0861,Bafut,ETHNICITY,ETHNICITY
SM18373,71.2,40.45,Bagdod Tumani,ADM2,DISTRICT
SM250056,-14.28,10.55,Baga,ETHNICITY,ETHNICITY
SM250056,-14.28,10.55,Baga,ETHNICITY,ETHNICITY
SM250056,-14,10,Baga,ETHNICITY,ETHNICITY
SM250056,-14,10,Baga,ETHNICITY,ETHNICITY
SM226193,-12.887,9.951,Baga Kaloum,LANGUAGE,LANGUAGE
SM226195,-13.947,10.1286,Baga Koga,LANGUAGE,LANGUAGE
SM226192,-14.6052,10.7675,Baga Manduri,LANGUAGE,LANGUAGE
SM226198,-12.0736,12.2725,Baga Mboteni,DIALECT,LANGUAGE
SM226194,-14.4756,10.4793,Baga Sitemu,LANGUAGE,LANGUAGE
SM226196,-13.9054,9.9887,Baga Sobané,LANGUAGE,LANGUAGE
SM270924,-85.2621,10.5081,Bagaces,ADM2,DISTRICT
SM291184,24.3567,46.2671,Bagaciu,ADM2,DISTRICT
SM461343,-76.1879,5.5207,Bagado (1999-),ADM2,DISTRICT
SM276923,75.69,16.18,Bagalkot,ADM2,DISTRICT
SM299149,38.4665,-6.3076,Bagamoyo,ADM2,DISTRICT
SM14175,100.6805,5.4965,Bagan Sena,ADM2,DISTRICT
SM293855,77.6667,54.0833,Baganskiy rayon,ADM2,DISTRICT
SM14997,108.3671,47.7458,Baganuur,ADM2,DISTRICT
SM15214,4.6235,14.3534,Bagaroua,ADM2,DISTRICT
SM269592,17.95,-3.7333,Bagata,ADM2,DISTRICT
SM17185,-60.9816,13.998,Bagatelle,ADM2,DISTRICT
SM18374,71.1957,40.4856,Bagdad,ADM2,DISTRICT
SM9883,-54.0171,-31.2369,Bage (1996-),ADM2,DISTRICT
SM262258,89.75,22.4,Bagerhat,ADM2,DISTRICT
SM277348,79.8322,29.9732,Bageshwar,ADM2,DISTRICT
SM5112,73.7577,33.9961,Bagh (2009-),ADM2:ADM3,DISTRICT
SM226205,77.0471,31.194,Baghati,DIALECT,LANGUAGE
SM12034,44.4213,33.3248,Baghdad,ADM2,DISTRICT
SM558,44.3667,33.3167,Baghdad,ADM1,DISTRICT
SM11291,42.8333,42.0833,Baghdati,ADM2,DISTRICT
SM11292,42.9,42,Baghdatis Munitsip'alit'et'I (excl. Baghdati),ADM2,DISTRICT
SM226207,87.4994,24.6837,Bagheli,LANGUAGE,LANGUAGE
SM277538,49.9167,31.5,Baghemalek,ADM2,DISTRICT
SM559,69,35.75,Baghlan,ADM1,DISTRICT
SM9501,68.6243,36.3269,Baghlan-e Jadid,ADM2,DISTRICT
SM277342,77.2167,28.95,Baghpat,ADM2,DISTRICT
SM226209,14.7695,11.5239,Bagirmi,LANGUAGE,LANGUAGE
SM226208,18.3467,8.8166,Bagirmi Fulfulde,LANGUAGE,LANGUAGE
SM18026,40.2263,37.9107,Baglar,ADM2,DISTRICT
SM375351,83.1426,28.3564,Baglung,ADM2:ADM3,DISTRICT
SM560,96.25,18.25,Bago,ADM1,DISTRICT
SM226212,1.4121,8.527,Bago-Kusuntu,LANGUAGE,LANGUAGE
SM251878,125.41,7.7,Bagobo,ETHNICITY,ETHNICITY
SM269464,-6.4078,9.9359,Bagoué,ADM2,DISTRICT
SM260176,69.3307,34.924,Bagram,ADM2,DISTRICT
SM260034,69.3362,34.4836,Bagrami,ADM2,DISTRICT
SM293036,20.4167,54.5,Bagrationovskiy rayon,ADM2,DISTRICT
SM226215,75.06,31.4,Bagri,LANGUAGE,LANGUAGE
SM286721,-78.3997,-5.0901,Bagua,ADM2,DISTRICT
SM284947,3.9567,11.3476,Bagudo,ADM2,DISTRICT
SM297823,126.6574,-8.6279,Baguia,ADM2,DISTRICT
SM10263,16.3464,11.4215,Baguirmi (excl. Dourbali),ADM2,DISTRICT
SM250057,17,11,Baguirmien,ETHNICITY,ETHNICITY
SM226221,145.632,-5.0778,Bagupi,LANGUAGE,LANGUAGE
SM226220,137.914,-1.9884,Bagusa,LANGUAGE,LANGUAGE
SM226222,46.1041,42.3966,Bagvalal,LANGUAGE,LANGUAGE
SM284875,8.1437,12.1,Bagwai,ADM2,DISTRICT
SM251882,33.87,0.53,Bagwe,ETHNICITY,ETHNICITY
SM264129,-50.1663,-2.4017,Bagé,ADM2,DISTRICT
SM226227,104.449,23.0105,Baha Buyang,LANGUAGE,LANGUAGE
SM14176,116.6667,6.5,Bahagian Kudat,ADM2,DISTRICT
SM14177,116.25,6,Bahagian Pantai Barat,ADM2,DISTRICT
SM14177,116.25,6,Bahagian Pantai Barat,ADM2,DISTRICT
SM14177,116.5,6.0833,Bahagian Pantai Barat,ADM2,DISTRICT
SM14177,116.5,6.0833,Bahagian Pantai Barat,ADM2,DISTRICT
SM14178,116.1667,4.8333,Bahagian Pedalaman,ADM2,DISTRICT
SM14179,117.5833,5.5833,Bahagian Sandakan,ADM2,DISTRICT
SM14180,118,4.5,Bahagian Tawau,ADM2,DISTRICT
SM226229,132.637,-3.1063,Baham,LANGUAGE,LANGUAGE
SM226231,-77.3906,25.0322,Bahamas Creole English,LANGUAGE,LANGUAGE
SM277477,48.35,34.9833,Bahar,ADM2,DISTRICT
SM251885,53.39,28.41,Bahar Lu,ETHNICITY,ETHNICITY
SM9503,70.9012,37.029,Baharak (2005-),ADM2,DISTRICT
SM9502,69.3953,36.8329,Baharak (Takhar),ADM2,DISTRICT
SM18115,58.498,37.9076,Baharly District,ADM2,DISTRICT
SM226230,50.5808,26.2236,Baharna Arabic,LANGUAGE,LANGUAGE
SM226228,115.332,0.308,Bahau,LANGUAGE,LANGUAGE
SM375442,73.0253,29.6411,Bahawalnagar,ADM2:ADM3,DISTRICT
SM375443,71.7127,28.8399,Bahawalpur,ADM2:ADM3,DISTRICT
SM299207,35.2889,-5.9379,Bahi,ADM2,DISTRICT
SM561,-42,-12,Bahia,ADM1,DISTRICT
SM15320,-59.9651,-19.8932,Bahia Negra,ADM2,DISTRICT
SM14228,-105.3091,20.8043,Bahia de Banderas,ADM2,DISTRICT
SM255987,30.27,0.86,Bahima,ETHNICITY,ETHNICITY
SM226237,142.832,-4.5553,Bahinemo,LANGUAGE,LANGUAGE
SM226236,86.3949,27.3323,Bahing,LANGUAGE,LANGUAGE
SM291325,26.7974,46.7786,Bahna,ADM2,DISTRICT
SM226241,108.471,14.0476,Bahnar,LANGUAGE,LANGUAGE
SM291195,24.4808,46.3196,Bahnea,ADM2,DISTRICT
SM15447,-66.9943,18.3406,Bahomamey Barrio,ADM2,DISTRICT
SM226244,121.707,-2.2257,Bahonsuai,LANGUAGE,LANGUAGE
SM562,-71.35,18.5,Bahoruco,ADM1,DISTRICT
SM11131,32.1349,30.9224,Bahr El Bakar,ADM2,DISTRICT
SM295999,31.5631,4.8602,Bahr al Jabal,ADM2,DISTRICT
SM277269,81.5186,27.8002,Bahraich (1996-),ADM2,DISTRICT
SM454419,50.5,26,Bahraini Arabs,ETHNICITY,ETHNICITY
SM9504,68.0685,33.5282,Bahram-e Shahid,ADM2,DISTRICT
SM251893,23.72,-12.04,Bahumbu,ETHNICITY,ETHNICITY
SM298817,36.5769,37.2011,Bahçe,ADM2,DISTRICT
SM298526,28.841,41.0065,Bahçelievler,ADM2,DISTRICT
SM298992,42.8049,38.1264,Bahçesaray,ADM2,DISTRICT
SM260697,-62.3333,-38.6667,Bahía Blanca,ADM2,DISTRICT
SM271024,-83.166,22.9046,Bahía Honda,ADM2,DISTRICT
SM305306,-77.3915,6.2252,Bahía Solano,ADM2,DISTRICT
SM298628,33.4408,39.8098,Bahşılı,ADM2,DISTRICT
SM226256,26.4825,7.5722,Bai,LANGUAGE,LANGUAGE
SM292048,28.6403,44.7571,Baia,ADM2,DISTRICT
SM291802,26.2028,47.4131,Baia,ADM2,DISTRICT
SM291065,22.8071,45.0037,Baia De Arama,ADM2,DISTRICT
SM289849,23.299,46.3793,Baia De Aries,ADM2,DISTRICT
SM290758,22.7003,46.1823,Baia De Cris,ADM2,DISTRICT
SM290663,23.7415,45.174,Baia De Fier,ADM2,DISTRICT
SM291018,23.6034,47.7405,Baia Mare,ADM2,DISTRICT
SM291029,23.6697,47.6645,Baia Sprie,ADM2,DISTRICT
SM267598,-44.5311,-12.4833,Baianópolis,ADM2,DISTRICT
SM226251,141.345,-3.4267,Baibai,LANGUAGE,LANGUAGE
SM269233,123.1514,45.4372,Baicheng,ADM2,DISTRICT
SM291578,25.8696,45.0416,Baicoi,ADM2,DISTRICT
SM291259,24.6733,45.0602,Baiculesti,ADM2,DISTRICT
SM563,55.4833,-4.7472,Baie Lazare,ADM1,DISTRICT
SM564,55.7333,-4.3167,Baie Sainte Anne,ADM1,DISTRICT
SM251898,80.88,22.08,Baiga,ETHNICITY,ETHNICITY
SM251898,80.88,22.08,Baiga,ETHNICITY,ETHNICITY
SM251898,81,22,Baiga,ETHNICITY,ETHNICITY
SM251898,81,22,Baiga,ETHNICITY,ETHNICITY
SM8782,118.2,56.9,Baikal Evenki,ETHNICITY,ETHNICITY
SM226257,124.256,-9.3299,Baikeno,LANGUAGE,LANGUAGE
SM3948,63.2833,45.65,Baikonur,ADM1,DISTRICT
SM290012,22.4161,44.8733,Baile Herculane,ADM2,DISTRICT
SM290700,25.8601,46.1471,Baile Tusnad,ADM2,DISTRICT
SM290392,23.3448,44.0212,Bailesti,ADM2,DISTRICT
SM302882,-102.8299,34.0685,Bailey,ADM2,DISTRICT
SM12117,-76.9259,18.3453,Baileys Vale,ADM2,DISTRICT
SM260456,15.7793,-12.0071,Bailundo,ADM2,DISTRICT
SM226258,103.952,33.1001,Baima,LANGUAGE,LANGUAGE
SM226259,145.606,-5.1894,Baimak,LANGUAGE,LANGUAGE
SM291813,25.9811,47.9602,Bainet,ADM2,DISTRICT
SM276109,-72.8486,18.2125,Bainet,ADM2,DISTRICT
SM461495,152.3,-4.633,Baining,ETHNICITY,ETHNICITY
SM251903,-71.39,18.83,Bainoa,ETHNICITY,ETHNICITY
SM226263,-16.229,12.8672,Bainouk-Gunyaamolo-Gutobor,LANGUAGE,LANGUAGE
SM226262,-15.9969,12.5053,Bainouk-Samik,LANGUAGE,LANGUAGE
SM250058,-16,12,Bainounk,ETHNICITY,ETHNICITY
SM226261,-16.0586,12.3128,Bainounk-Gujaher,LANGUAGE,LANGUAGE
SM15448,-66.089,18.2598,Bairoa Barrio (Aguas Buenas),ADM2,DISTRICT
SM15449,-66.0397,18.2599,Bairoa Barrio (Caguas),ADM2,DISTRICT
SM269437,106.6167,23.9,Baise,ADM2,DISTRICT
SM269234,127.2914,42.185,Baishan,ADM2,DISTRICT
SM226269,37.7681,6.4489,Baiso,LANGUAGE,LANGUAGE
SM290133,23.3935,46.5833,Baisoara,ADM2,DISTRICT
SM226268,10.7684,7.3802,Baissa Fali,LANGUAGE,LANGUAGE
SM251909,51.52,18.92,Bait Imani,ETHNICITY,ETHNICITY
SM251910,52.62,18.25,Bait Kathir,ETHNICITY,ETHNICITY
SM290769,22.8909,46.0242,Baita,ADM2,DISTRICT
SM291040,23.15,47.5333,Baita De Sub Codru,ADM2,DISTRICT
SM375328,80.5586,29.5082,Baitadi,ADM2:ADM3,DISTRICT
SM565,166.9297,-0.5075,Baiti,ADM1,DISTRICT
SM291051,24.0018,47.6019,Baiut,ADM2,DISTRICT
SM267609,-40.1799,-11.9774,Baixa Grande,ADM2,DISTRICT
SM265104,-45.1258,-8.6917,Baixa Grande do Ribeiro,ADM2,DISTRICT
SM267878,-38.7575,-6.7073,Baixio,ADM2,DISTRICT
SM267966,-40.9639,-19.5235,Baixo Guandu,ADM2,DISTRICT
SM269402,104.785,36.5365,Baiyin,ADM2,DISTRICT
SM289310,-8.0358,41.1638,Baião,ADM2,DISTRICT
SM264130,-49.7545,-3.2528,Baião,ADM2,DISTRICT
SM276114,19.0136,46.1451,Baja,ADM2,DISTRICT
SM566,-115,30,Baja California,ADM1,DISTRICT
SM567,-111.8333,25.8333,Baja California Sur,ADM1,DISTRICT
SM568,-90.3333,15.0833,Baja Verapaz,ADM1,DISTRICT
SM226282,-59.5346,13.1365,Bajan,LANGUAGE,LANGUAGE
SM8440,120,5,Bajau,ETHNICITY,ETHNICITY
SM8440,120,5,Bajau,ETHNICITY,ETHNICITY
SM8440,122.5947,-3.9675,Bajau,ETHNICITY,ETHNICITY
SM8440,122.5947,-3.9675,Bajau,ETHNICITY,ETHNICITY
SM375402,71.4907,34.7573,Bajaur,ADM2:ADM3,DISTRICT
SM226283,42.9867,33.9498,Bajelani,LANGUAGE,LANGUAGE
SM375333,81.2461,29.7233,Bajhang,ADM2:ADM3,DISTRICT
SM305042,43.1735,15.0756,Bajil,ADM2,DISTRICT
SM15450,-65.9908,17.9798,Bajo Barrio,ADM2,DISTRICT
SM10382,-77.2277,4.9018,Bajo Baudo (1999-),ADM2,DISTRICT
SM271831,-70.0333,18.4167,Bajos de Haina,ADM2,DISTRICT
SM250059,41,-2,Bajuni,ETHNICITY,ETHNICITY
SM375334,81.5767,29.6107,Bajura,ADM2:ADM3,DISTRICT
SM15451,-66.5258,18.4105,Bajura Adentro Barrio,ADM2,DISTRICT
SM15452,-66.5117,18.4323,Bajura Afuera Barrio,ADM2,DISTRICT
SM15454,-67.1289,18.1019,Bajura Barrio (Cabo Rojo),ADM2,DISTRICT
SM15453,-67.0622,18.5118,Bajura Barrio (Isabela),ADM2,DISTRICT
SM15455,-66.3469,18.4145,Bajura Barrio (Vega Alta),ADM2,DISTRICT
SM260067,70.0766,33.5301,Bak,ADM2,DISTRICT
SM251921,15.31,2.39,Baka,ETHNICITY,ETHNICITY
SM251921,15.31,2.39,Baka,ETHNICITY,ETHNICITY
SM251921,13.5,2.5,Baka,ETHNICITY,ETHNICITY
SM251921,13.5,2.5,Baka,ETHNICITY,ETHNICITY
SM226293,13.7804,2.1601,Baka (Cameroon),LANGUAGE,LANGUAGE
SM7700,29.6,4.26,Baka (South Sudan),ETHNICITY,ETHNICITY
SM226295,29.59,4.6594,Baka (Sudan),LANGUAGE,LANGUAGE
SM14181,100.8131,5.4895,Bakai,ADM2,DISTRICT
SM280339,71.9167,42.4833,Bakai-Ata,ADM2,DISTRICT
SM251925,-52.58,-14.55,Bakairi,ETHNICITY,ETHNICITY
SM251925,-52.58,-14.55,Bakairi,ETHNICITY,ETHNICITY
SM251925,-55,-14,Bakairi,ETHNICITY,ETHNICITY
SM251925,-55,-14,Bakairi,ETHNICITY,ETHNICITY
SM226298,-54.7034,-14.2628,Bakairí,LANGUAGE,LANGUAGE
SM226294,9.804,4.7999,Bakaka,LANGUAGE,LANGUAGE
SM268563,20.3917,6.5654,Bakala,ADM2,DISTRICT
SM294289,53.75,55.1667,Bakalinskiy rayon,ADM2,DISTRICT
SM251926,12.71,-4.44,Bakamba,ETHNICITY,ETHNICITY
SM280381,103.79,12.78,Bakan,ADM2,DISTRICT
SM275968,14.5336,45.3069,Bakar,ADM2,DISTRICT
SM285401,8.6021,4.8171,Bakassi,ADM2,DISTRICT
SM294668,82.5,56.9167,Bakcharskiy rayon,ADM2,DISTRICT
SM295256,-12.2396,14.2361,Bakel,ADM2,DISTRICT
SM302249,-117.6753,44.7092,Baker,ADM2,DISTRICT
SM300473,-84.4447,31.3262,Baker,ADM2,DISTRICT
SM300324,-82.2846,30.3311,Baker,ADM2,DISTRICT
SM569,-176.4794,0.1947,Baker,ADM0:ADM1,DISTRICT
SM299890,33.8633,44.75,Bakhchysarais'kyi,ADM2,DISTRICT
SM18181,38,48.5833,Bakhmut Raion (excl. Chasia Yar),ADM2,DISTRICT
SM18375,68.6946,40.7184,Bakht Shahri,ADM2,DISTRICT
SM226302,49.7713,32.0163,Bakhtiari,LANGUAGE,LANGUAGE
SM251931,48.65,32.69,Bakhtiari,ETHNICITY,ETHNICITY
SM251931,48.65,32.69,Bakhtiari,ETHNICITY,ETHNICITY
SM251931,48,33,Bakhtiari,ETHNICITY,ETHNICITY
SM251931,48,33,Bakhtiari,ETHNICITY,ETHNICITY
SM226308,168.165,-16.7096,Baki,LANGUAGE,LANGUAGE
SM295764,43.7326,10.286,Baki,ADM2,DISTRICT
SM250161,29.75,-0.67,Bakiga,ETHNICITY,ETHNICITY
SM250161,29.75,-0.67,Bakiga,ETHNICITY,ETHNICITY
SM250161,30,-3,Bakiga,ETHNICITY,ETHNICITY
SM250161,30,-3,Bakiga,ETHNICITY,ETHNICITY
SM251932,43.73,16.17,Bakil,ETHNICITY,ETHNICITY
SM304835,43.3615,16.5069,Bakil Al Mir,ADM2,DISTRICT
SM296065,-54.8867,5.8939,Bakki,ADM2,DISTRICT
SM298313,29.6203,37.9876,Baklan,ADM2,DISTRICT
SM226310,9.9991,3.5652,Bakoko,LANGUAGE,LANGUAGE
SM226311,8.9739,4.4365,Bakole,LANGUAGE,LANGUAGE
SM250382,30.64,0.72,Bakonjo,ETHNICITY,ETHNICITY
SM250382,30.64,0.72,Bakonjo,ETHNICITY,ETHNICITY
SM250382,30,-1,Bakonjo,ETHNICITY,ETHNICITY
SM250382,30,-1,Bakonjo,ETHNICITY,ETHNICITY
SM570,44,4,Bakool,ADM1,DISTRICT
SM284897,7.4288,11.6398,Bakori,ADM2,DISTRICT
SM268599,22.5856,5.5267,Bakouma,ADM2,DISTRICT
SM251935,150.04,-5.64,Bakovi,ETHNICITY,ETHNICITY
SM251935,150.04,-5.64,Bakovi,ETHNICITY,ETHNICITY
SM251935,150.05,-5.42,Bakovi,ETHNICITY,ETHNICITY
SM251935,150.05,-5.42,Bakovi,ETHNICITY,ETHNICITY
SM226316,8.2632,5.2676,Bakpinka,LANGUAGE,LANGUAGE
SM277376,91.1514,26.698,Baksa,ADM2,DISTRICT
SM293024,43.5,43.75,Baksanskiy rayon,ADM2,DISTRICT
SM276174,22.0245,48.0044,Baktalórántháza,ADM2,DISTRICT
SM226322,114.77,-1.9884,Bakumpai,LANGUAGE,LANGUAGE
SM250624,9,5,Bakundu-Oroko,ETHNICITY,ETHNICITY
SM250624,9,5,Bakundu-Oroko,ETHNICITY,ETHNICITY
SM250624,9,10,Bakundu-Oroko,ETHNICITY,ETHNICITY
SM250624,9,10,Bakundu-Oroko,ETHNICITY,ETHNICITY
SM285281,5.8579,12.6166,Bakura,ADM2,DISTRICT
SM260269,62.8809,32.2148,Bakwa,ADM2,DISTRICT
SM250062,-6.22,6.41,Bakwe,ETHNICITY,ETHNICITY
SM250062,-6.22,6.41,Bakwe,ETHNICITY,ETHNICITY
SM250062,-7,5,Bakwe,ETHNICITY,ETHNICITY
SM250062,-7,5,Bakwe,ETHNICITY,ETHNICITY
SM250520,9.38,4.36,Bakweri,ETHNICITY,ETHNICITY
SM250520,9.38,4.36,Bakweri,ETHNICITY,ETHNICITY
SM250520,9.27,4.22,Bakweri,ETHNICITY,ETHNICITY
SM250520,9.27,4.22,Bakweri,ETHNICITY,ETHNICITY
SM226326,-6.5108,5.2312,Bakwé,LANGUAGE,LANGUAGE
SM261881,49.7241,40.398,Bakı,ADM1:ADM2,DISTRICT
SM298527,28.8354,40.9839,Bakırköy,ADM2,DISTRICT
SM9505,65.4534,35.8808,Bal Chiragh (2005-),ADM2,DISTRICT
SM277972,36.1286,32.2853,Bal'ama,ADM2,DISTRICT
SM298900,33.1296,39.5637,Bala,ADM2,DISTRICT
SM291206,24.5054,46.722,Bala,ADM2,DISTRICT
SM291076,22.8324,44.8948,Bala,ADM2,DISTRICT
SM260270,62.7519,32.7704,Bala Buluk,ADM2,DISTRICT
SM289863,27.136,45.2466,Balaceanu,ADM2,DISTRICT
SM291889,24.9096,44.3519,Balaci,ADM2,DISTRICT
SM291087,23.121,44.3904,Balacita,ADM2,DISTRICT
SM12118,-77.6515,18.1781,Balaclava,ADM2,DISTRICT
SM305806,44.1383,33.9961,Balad,ADM2,DISTRICT
SM305781,45.0833,33.7,Balad Ruz,ADM2,DISTRICT
SM226347,164.2,-20.25,Balade,LANGUAGE,LANGUAGE
SM4123,51,24.9167,Baladiyat ar Rayyan (2014-),ADM1,DISTRICT
SM226345,119.689,-0.0564,Balaesang,LANGUAGE,LANGUAGE
SM292913,102.8333,54.6667,Balaganskiy rayon,ADM2,DISTRICT
SM277012,80.3333,21.9667,Balaghat,ADM2,DISTRICT
SM571,35.0591,-15.0484,Balaka,ADM1:ADM2,DISTRICT
SM16847,43.5,56.5,Balakhninskiy Rayon,ADM2,DISTRICT
SM293433,91.5,55.5,Balakhtinskiy rayon,ADM2,DISTRICT
SM299708,33.6704,44.5058,Balaklavs'kyi,ADM2,DISTRICT
SM261952,46.4167,41.75,Balakən,ADM1:ADM2,DISTRICT
SM251941,13.14,-3.92,Balali,ETHNICITY,ETHNICITY
SM283856,38.407,-13.469,Balama,ADM2,DISTRICT
SM458792,136.97,-12.96,Balamumu,ETHNICITY,ETHNICITY
SM291622,23.3119,47.1577,Balan,ADM2,DISTRICT
SM290711,25.8055,46.6574,Balan,ADM2,DISTRICT
SM282619,-91.3752,17.8264,Balancán,ADM2,DISTRICT
SM284747,11.7862,9.8096,Balanga,ADM2,DISTRICT
SM276391,115.6292,-2.3231,Balangan,ADM2,DISTRICT
SM226341,121.272,17.0994,Balangao,LANGUAGE,LANGUAGE
SM226342,121.6867,6.0116,Balangingi,LANGUAGE,LANGUAGE
SM277118,83.25,20.75,Balangir (1993-),ADM2,DISTRICT
SM250063,-15.56,13.24,Balanta,ETHNICITY,ETHNICITY
SM250063,-15.56,13.24,Balanta,ETHNICITY,ETHNICITY
SM250063,-16,12,Balanta,ETHNICITY,ETHNICITY
SM250063,-16,12,Balanta,ETHNICITY,ETHNICITY
SM226333,-15.6658,12.5382,Balanta-Ganja,LANGUAGE,LANGUAGE
SM226332,-15.3221,11.8203,Balanta-Kentohe,LANGUAGE,LANGUAGE
SM226346,123.289,-0.8681,Balantak,LANGUAGE,LANGUAGE
SM251942,123,-0.81,Balantak,ETHNICITY,ETHNICITY
SM273442,-79.7075,-2.8941,Balao,ADM2,DISTRICT
SM251943,118.2,-31.82,Balardong,ETHNICITY,ETHNICITY
SM251943,118.2,-31.82,Balardong,ETHNICITY,ETHNICITY
SM251943,117.1317,-31.5555,Balardong,ETHNICITY,ETHNICITY
SM251943,117.1317,-31.5555,Balardong,ETHNICITY,ETHNICITY
SM290436,27.6584,46.09,Balasesti,ADM2,DISTRICT
SM16848,38,55.8333,Balashikha Urban Okrug,ADM2,DISTRICT
SM276143,19.3059,48.0166,Balassagyarmat,ADM2,DISTRICT
SM17186,-60.9517,14.0147,Balata,ADM2,DISTRICT
SM276200,18.0962,47.0417,Balatonalmádi,ADM2,DISTRICT
SM276201,17.7941,46.9395,Balatonfüred,ADM2,DISTRICT
SM226337,110.913,1.3348,Balau,LANGUAGE,LANGUAGE
SM291119,24.6972,46.3825,Balauseri,ADM2,DISTRICT
SM266921,-49.3351,-21.9009,Balbinos,ADM2,DISTRICT
SM286659,-79,8.4667,Balboa,ADM2,DISTRICT
SM269872,-77.2078,2.0844,Balboa,ADM2,DISTRICT
SM270431,-75.9403,4.9228,Balboa,ADM2,DISTRICT
SM292462,22.4995,47.3114,Balc,ADM2,DISTRICT
SM17187,-61.0239,13.7712,Balca,ADM2,DISTRICT
SM17188,-61.0193,13.7764,Balca/En Leur Ba,ADM2,DISTRICT
SM295784,45.6592,2.4878,Balcad,ADM2,DISTRICT
SM292129,26.5441,46.6411,Balcani,ADM2,DISTRICT
SM260708,-58.25,-37.75,Balcarce,ADM2,DISTRICT
SM12119,-76.7206,18.1674,Balcarres,ADM2,DISTRICT
SM291824,26.0867,47.9044,Balcauti,ADM2,DISTRICT
SM292216,23.9405,44.6294,Balcesti,ADM2,DISTRICT
SM262502,28.1333,43.5,Balchik,ADM2,DISTRICT
SM226351,14.4674,10.9078,Baldemu,LANGUAGE,LANGUAGE
SM263713,-43.8468,-19.2366,Baldim,ADM2,DISTRICT
SM13423,24.4052,56.7453,Baldone,ADM2,DISTRICT
SM4001,24.3725,56.7311,Baldones Novads,ADM1,DISTRICT
SM13424,24.3713,56.7308,Baldones pagasts,ADM2,DISTRICT
SM300484,-83.2496,33.0693,Baldwin,ADM2,DISTRICT
SM300257,-87.7498,30.661,Baldwin,ADM2,DISTRICT
SM274368,40.4526,6.9219,Bale,ADM2,DISTRICT
SM305657,13.7857,45.0409,Bale,ADM2,DISTRICT
SM274049,3.0295,39.6099,Baleares,ADM2,DISTRICT
SM17189,-61.0284,13.7599,Balembouche,ADM2,DISTRICT
SM290447,27.8502,45.8094,Baleni,ADM2,DISTRICT
SM290266,25.6476,44.8202,Baleni-romani,ADM2,DISTRICT
SM277129,86.75,21.5,Baleshwar (1993-),ADM2,DISTRICT
SM292354,27.237,45.4403,Balesti,ADM2,DISTRICT
SM290674,23.1815,45.019,Balesti,ADM2,DISTRICT
SM295106,117,51.5,Baleyskiy rayon,ADM2,DISTRICT
SM275829,-86.2905,15.7571,Balfate,ADM2,DISTRICT
SM13425,22.8752,57.1824,Balgales pagasts,ADM2,DISTRICT
SM251948,42.05,19.47,Balgarn,ETHNICITY,ETHNICITY
SM285250,10.9685,8.1554,Bali,ADM2,DISTRICT
SM572,115,-8.5,Bali,ADM1,DISTRICT
SM250064,22.33,3.34,Bali (DRC),ETHNICITY,ETHNICITY
SM250064,22.33,3.34,Bali (DRC),ETHNICITY,ETHNICITY
SM250064,27,1,Bali (DRC),ETHNICITY,ETHNICITY
SM250064,27,1,Bali (DRC),ETHNICITY,ETHNICITY
SM226359,26.5941,1.1807,Bali (Democratic Republic of Congo),LANGUAGE,LANGUAGE
SM226357,11.2952,8.8135,Bali (Nigeria),LANGUAGE,LANGUAGE
SM251950,15.69,-3.22,Bali (Republic of Congo),ETHNICITY,ETHNICITY
SM297830,125.043,-8.9715,Balibó,ADM2,DISTRICT
SM573,28,39.75,Balikesir,ADM1,DISTRICT
SM276418,116.8833,-1.1667,Balikpapan,ADM2,DISTRICT
SM291270,24.9394,45.0798,Balilesti,ADM2,DISTRICT
SM10264,18.1865,8.9582,Balimba,ADM2,DISTRICT
SM226363,115.075,-8.3571,Balinese,LANGUAGE,LANGUAGE
SM251949,115.18,-8.25,Balinese,ETHNICITY,ETHNICITY
SM251949,115.18,-8.25,Balinese,ETHNICITY,ETHNICITY
SM251949,115.18,-8.25,Balinese,ETHNICITY,ETHNICITY
SM251949,115.18,-8.25,Balinese,ETHNICITY,ETHNICITY
SM251949,115,-8.4165,Balinese,ETHNICITY,ETHNICITY
SM251949,115,-8.4165,Balinese,ETHNICITY,ETHNICITY
SM251949,115,-8.4165,Balinese,ETHNICITY,ETHNICITY
SM251949,115,-8.4165,Balinese,ETHNICITY,ETHNICITY
SM251949,115.02,-8.18,Balinese,ETHNICITY,ETHNICITY
SM251949,115.02,-8.18,Balinese,ETHNICITY,ETHNICITY
SM251949,115.02,-8.18,Balinese,ETHNICITY,ETHNICITY
SM251949,115.02,-8.18,Balinese,ETHNICITY,ETHNICITY
SM251949,115.33,-8.5,Balinese,ETHNICITY,ETHNICITY
SM251949,115.33,-8.5,Balinese,ETHNICITY,ETHNICITY
SM251949,115.33,-8.5,Balinese,ETHNICITY,ETHNICITY
SM251949,115.33,-8.5,Balinese,ETHNICITY,ETHNICITY
SM226364,114.6484,-8.3559,Balinese Malay,LANGUAGE,LANGUAGE
SM14182,100.7292,5.5658,Baling,ADM2,DISTRICT
SM291955,21.8584,45.8229,Balint,ADM2,DISTRICT
SM303401,72,40.8667,Baliqchi,ADM2,DISTRICT
SM268211,-52.4361,-16.3667,Baliza,ADM2,DISTRICT
SM17771,41.7,19.9833,Baljurashi,ADM2,DISTRICT
SM305949,69.8495,38.4976,Baljuvon,ADM2,DISTRICT
SM574,54.5,40,Balkan,ADM1,DISTRICT
SM226371,21.17,42.67,Balkan Romani,LANGUAGE,LANGUAGE
SM251960,43.6167,43.4833,Balkar,ETHNICITY,ETHNICITY
SM260243,66.891,36.7513,Balkh,ADM2,DISTRICT
SM575,67,36.75,Balkh,ADM1,DISTRICT
SM260202,66.6023,35.458,Balkhab,ADM2,DISTRICT
SM279841,75.75,45.3333,Balkhashskiy,ADM2,DISTRICT
SM261238,143.7728,-37.5188,Ballarat,ADM2,DISTRICT
SM301042,-88.9993,37.0584,Ballard,ADM2,DISTRICT
SM12120,-77.6309,17.8795,Ballards  Valley,ADM2,DISTRICT
SM276792,-4.5423,54.3128,Ballaugh,ADM1:ADM2,DISTRICT
SM271681,12.3579,55.7325,Ballerup,ADM2,DISTRICT
SM15215,2.875,13.7285,Balleyara,ADM2,DISTRICT
SM283274,-106.5359,26.7354,Balleza,ADM2,DISTRICT
SM277270,84.1667,25.8333,Ballia,ADM2,DISTRICT
SM261537,153.4857,-28.8527,Ballina,ADM2,DISTRICT
SM251962,-15.72,12.37,Ballouk,ETHNICITY,ETHNICITY
SM276271,21.1488,47.6184,Balmazújváros,ADM2,DISTRICT
SM9884,-49.2543,-28.8268,Balneario Rincao,ADM2:ADM3,DISTRICT
SM266317,-49.4785,-29.007,Balneário Arroio do Silva,ADM2,DISTRICT
SM266328,-48.6515,-26.4189,Balneário Barra do Sul,ADM2,DISTRICT
SM266339,-48.6204,-27.0047,Balneário Camboriú,ADM2,DISTRICT
SM266350,-49.6119,-29.1301,Balneário Gaivota,ADM2,DISTRICT
SM265795,-50.2931,-30.2128,Balneário Pinhal,ADM2,DISTRICT
SM226381,9.6937,6.1255,Balo,LANGUAGE,LANGUAGE
SM236301,19.0222,1.7511,Balobo,LANGUAGE,LANGUAGE
SM251964,64.44,25.75,Balochi,ETHNICITY,ETHNICITY
SM251964,64.44,25.75,Balochi,ETHNICITY,ETHNICITY
SM251964,64.44,25.75,Balochi,ETHNICITY,ETHNICITY
SM251964,64.44,25.75,Balochi,ETHNICITY,ETHNICITY
SM251964,69,30,Balochi,ETHNICITY,ETHNICITY
SM251964,69,30,Balochi,ETHNICITY,ETHNICITY
SM251964,69,30,Balochi,ETHNICITY,ETHNICITY
SM251964,69,30,Balochi,ETHNICITY,ETHNICITY
SM251964,66.5,30.4987,Balochi,ETHNICITY,ETHNICITY
SM251964,66.5,30.4987,Balochi,ETHNICITY,ETHNICITY
SM251964,66.5,30.4987,Balochi,ETHNICITY,ETHNICITY
SM251964,66.5,30.4987,Balochi,ETHNICITY,ETHNICITY
SM251964,66.5,30.5,Balochi,ETHNICITY,ETHNICITY
SM251964,66.5,30.5,Balochi,ETHNICITY,ETHNICITY
SM251964,66.5,30.5,Balochi,ETHNICITY,ETHNICITY
SM251964,66.5,30.5,Balochi,ETHNICITY,ETHNICITY
SM277453,81.2022,20.7303,Balod,ADM2,DISTRICT
SM277442,82.1638,21.659,Baloda Bazar,ADM2,DISTRICT
SM260395,14.8333,-13.3333,Balombo,ADM2,DISTRICT
SM261620,148.1793,-28.2848,Balonne,ADM2,DISTRICT
SM290403,23.8299,44.5434,Balota De Jos,ADM2,DISTRICT
SM290968,26.0866,44.6203,Balotesti,ADM2,DISTRICT
SM251965,18.66,0.15,Baloulou (Mongo),ETHNICITY,ETHNICITY
SM13426,24.128,56.8715,Balozi,ADM2,DISTRICT
SM576,35.6667,32,Balqa,ADM1,DISTRICT
SM13172,75,46.8167,Balqash Qalasy,ADM2,DISTRICT
SM277271,82.4028,27.4345,Balrampur,ADM2,DISTRICT
SM277462,83.6141,23.6077,Balrampur,ADM2,DISTRICT
SM261548,143.5638,-34.6784,Balranald,ADM2,DISTRICT
SM291423,24.1132,44.3655,Bals,ADM2,DISTRICT
SM290780,23.0689,46.0474,Balsa,ADM2,DISTRICT
SM9885,-49.6774,-25.5215,Balsa Nova,ADM2,DISTRICT
SM273622,-79.8328,-3.7683,Balsas,ADM2,DISTRICT
SM262938,-46.3428,-8.2376,Balsas,ADM2,DISTRICT
SM286223,19.2265,69.2402,Balsfjord,ADM2,DISTRICT
SM291098,22.6179,44.9065,Balta,ADM2,DISTRICT
SM289874,27.3056,45.2638,Balta Alba,ADM2,DISTRICT
SM298091,8.9492,36.7035,Balta Bou Aouane,ADM2,DISTRICT
SM291490,26.1947,44.7578,Balta Doamnei,ADM2,DISTRICT
SM294300,55.9458,55.9892,Baltachevskiy rayon,ADM2,DISTRICT
SM294605,50.25,56.3333,Baltasinskiy rayon,ADM2,DISTRICT
SM290838,27.1187,47.2337,Baltati,ADM2,DISTRICT
SM294444,46.6667,52.5,Baltayskiy rayon,ADM2,DISTRICT
SM292323,27.6102,46.6755,Balteni,ADM2,DISTRICT
SM290676,23.2674,44.8667,Balteni,ADM2,DISTRICT
SM291492,26.1304,45.1039,Baltesti,ADM2,DISTRICT
SM226383,77.2859,34.4491,Balti,LANGUAGE,LANGUAGE
SM226382,38,60,Baltic Romani,LANGUAGE,LANGUAGE
SM18301,-76.6163,39.4431,Baltimore County (excl. Baltimore),ADM2,DISTRICT
SM3971,27.61,56.9169,Baltinavas Novads,ADM1,DISTRICT
SM13427,27.6103,56.9168,Baltinavas novads,ADM2,DISTRICT
SM299629,29.6227,47.9359,Balts'kyi,ADM2,DISTRICT
SM251968,18.9,-6.05,Balua,ETHNICITY,ETHNICITY
SM578,65.6436,28.4785,Baluchistan,ADM1,DISTRICT
SM292569,26.7786,47.6776,Baluseni,ADM2,DISTRICT
SM291109,22.6831,44.7976,Balvanesti,ADM2,DISTRICT
SM13428,27.2604,57.1315,Balvi,ADM2,DISTRICT
SM3972,27.4397,57.0325,Balvu Novads,ADM1,DISTRICT
SM13429,27.238,57.0925,Balvu pagasts,ADM2,DISTRICT
SM298172,27.5863,39.7591,Balya,ADM2,DISTRICT
SM13173,52,47.25,Balyqshy Audany,ADM2,DISTRICT
SM273449,-79.8833,-1.3667,Balzar,ADM2,DISTRICT
SM579,9.5041,47.0677,Balzers,ADM1,DISTRICT
SM298541,27.0871,38.373,Balçova,ADM2,DISTRICT
SM262179,-3.05,11.7167,Balé,ADM1:ADM2,DISTRICT
SM298629,33.7319,39.9213,Balışeyh,ADM2,DISTRICT
SM11752,58.2333,29.0667,Bam,ADM2,DISTRICT
SM262205,-1.5833,13.4667,Bam,ADM1:ADM2,DISTRICT
SM285382,14.0966,11.5206,Bama,ADM2,DISTRICT
SM283341,-8.0124,12.6358,Bamako,ADM2,DISTRICT
SM580,-8,12.65,Bamako,ADM1,DISTRICT
SM226393,10.4567,5.9133,Bamali,LANGUAGE,LANGUAGE
SM275077,-14.8607,12.0213,Bambadinca,ADM2,DISTRICT
SM226399,10.544,5.861,Bambalang,LANGUAGE,LANGUAGE
SM226404,119.201,-3.0276,Bambam,LANGUAGE,LANGUAGE
SM269825,13.5466,-2.5389,Bambama,ADM2,DISTRICT
SM226403,-7.65,12.9,Bambara,LANGUAGE,LANGUAGE
SM250065,-5.96,12.81,Bambara,ETHNICITY,ETHNICITY
SM250065,-5.96,12.81,Bambara,ETHNICITY,ETHNICITY
SM250065,-5.96,12.81,Bambara,ETHNICITY,ETHNICITY
SM250065,-5.96,12.81,Bambara,ETHNICITY,ETHNICITY
SM250065,-7,12.75,Bambara,ETHNICITY,ETHNICITY
SM250065,-7,12.75,Bambara,ETHNICITY,ETHNICITY
SM250065,-7,12.75,Bambara,ETHNICITY,ETHNICITY
SM250065,-7,12.75,Bambara,ETHNICITY,ETHNICITY
SM250065,-7,13,Bambara,ETHNICITY,ETHNICITY
SM250065,-7,13,Bambara,ETHNICITY,ETHNICITY
SM250065,-7,13,Bambara,ETHNICITY,ETHNICITY
SM250065,-7,13,Bambara,ETHNICITY,ETHNICITY
SM250065,-7,12.5,Bambara,ETHNICITY,ETHNICITY
SM250065,-7,12.5,Bambara,ETHNICITY,ETHNICITY
SM250065,-7,12.5,Bambara,ETHNICITY,ETHNICITY
SM250065,-7,12.5,Bambara,ETHNICITY,ETHNICITY
SM268564,21.0496,5.7363,Bambari,ADM2,DISTRICT
SM226396,34.6522,9.8255,Bambassi,LANGUAGE,LANGUAGE
SM251971,16.51,-3.78,Bambata,ETHNICITY,ETHNICITY
SM302473,-81.0542,33.2148,Bamberg,ADM2,DISTRICT
SM295266,-16.4826,14.8027,Bambey,ADM2,DISTRICT
SM226400,10.2233,6.1082,Bambili-Bambui,LANGUAGE,LANGUAGE
SM268576,16.7867,3.8062,Bambio,ADM2,DISTRICT
SM286205,9.7457,59.0015,Bamble,ADM2,DISTRICT
SM461329,9.11,4.58,Bamboko,ETHNICITY,ETHNICITY
SM12121,-77.2813,18.3937,Bamboo,ADM2,DISTRICT
SM251973,28.26,-6.64,Bambote,ETHNICITY,ETHNICITY
SM10223,26.973,5.4678,Bambouti,ADM2,DISTRICT
SM269540,10.25,5.6333,Bamboutos,ADM2,DISTRICT
SM263724,-45.9874,-20.1045,Bambuí,ADM2,DISTRICT
SM226407,10.3302,5.7705,Bamenyam,LANGUAGE,LANGUAGE
SM250066,7.24,4.63,Bamilike-Central,ETHNICITY,ETHNICITY
SM250066,7.24,4.63,Bamilike-Central,ETHNICITY,ETHNICITY
SM250066,10,5,Bamilike-Central,ETHNICITY,ETHNICITY
SM250066,10,5,Bamilike-Central,ETHNICITY,ETHNICITY
SM268552,20.0487,7.889,Bamingui,ADM2,DISTRICT
SM581,20.6667,8.4167,Bamingui-Bangoran,ADM1,DISTRICT
SM297767,101.6445,15.4694,Bamnet Narong,ADM2,DISTRICT
SM250067,11.9,4.35,Bamoun,ETHNICITY,ETHNICITY
SM250067,11.9,4.35,Bamoun,ETHNICITY,ETHNICITY
SM250067,11.9,4.35,Bamoun,ETHNICITY,ETHNICITY
SM250067,11,6,Bamoun,ETHNICITY,ETHNICITY
SM250067,11,6,Bamoun,ETHNICITY,ETHNICITY
SM250067,11,6,Bamoun,ETHNICITY,ETHNICITY
SM250067,10.8,5.5,Bamoun,ETHNICITY,ETHNICITY
SM250067,10.8,5.5,Bamoun,ETHNICITY,ETHNICITY
SM250067,10.8,5.5,Bamoun,ETHNICITY,ETHNICITY
SM226425,143.545,-7.9382,Bamu,LANGUAGE,LANGUAGE
SM251977,143.53,-7.82,Bamu,ETHNICITY,ETHNICITY
SM226422,10.3537,5.8729,Bamukumbit,LANGUAGE,LANGUAGE
SM226421,10.8477,5.4902,Bamun,LANGUAGE,LANGUAGE
SM226424,10.5127,6.002,Bamunka,LANGUAGE,LANGUAGE
SM226427,19.1644,2.1677,Bamwe,LANGUAGE,LANGUAGE
SM582,67.25,34.75,Bamyan,ADM1,DISTRICT
SM260257,67.673,34.7955,Bamyan City,ADM2,DISTRICT
SM296914,101.1815,13.2528,Ban Bung,ADM2,DISTRICT
SM297456,101.0488,12.7323,Ban Chang,ADM2,DISTRICT
SM297599,99.4915,17.0528,Ban Dan Lan Hoi,ADM2,DISTRICT
SM297713,103.2673,17.7113,Ban Dung,ADM2,DISTRICT
SM296975,102.6167,16.4916,Ban Fang,ADM2,DISTRICT
SM297029,98.8379,18.2742,Ban Hong,ADM2,DISTRICT
SM297457,101.3421,12.8383,Ban Khai,ADM2,DISTRICT
SM297741,101.0661,18.1468,Ban Khok,ADM2,DISTRICT
SM226527,103.296,16.911,Ban Khor Sign Language,LANGUAGE,LANGUAGE
SM297775,101.8395,15.8277,Ban Khwao,ADM2,DISTRICT
SM297410,103.1149,14.3921,Ban Kruat,ADM2,DISTRICT
SM297348,99.9877,13.1697,Ban Laem,ADM2,DISTRICT
SM297349,99.8379,13.0453,Ban Lat,ADM2,DISTRICT
SM297112,102.142,15.5743,Ban Luam,ADM2,DISTRICT
SM297232,100.4601,18.8838,Ban Luang,ADM2,DISTRICT
SM280417,107.0303,13.682,Ban Lung,ADM2,DISTRICT
SM297421,102.8439,15.5786,Ban Mai Chai Pho,ADM2,DISTRICT
SM297051,100.552,15.0615,Ban Mi,ADM2,DISTRICT
SM297534,100.724,14.608,Ban Mo,ADM2,DISTRICT
SM297503,103.5279,17.8957,Ban Muang,ADM2,DISTRICT
SM297089,101.0644,14.268,Ban Na,ADM2,DISTRICT
SM297618,99.2814,8.9061,Ban Na Doem,ADM2,DISTRICT
SM297629,99.402,8.8006,Ban Na San,ADM2,DISTRICT
SM297100,104.2274,17.8696,Ban Phaeng,ADM2,DISTRICT
SM297517,100.1179,13.5875,Ban Phaeo,ADM2,DISTRICT
SM296986,102.7776,16.046,Ban Phai,ADM2,DISTRICT
SM297523,101.0784,13.61,Ban Pho,ADM2,DISTRICT
SM297377,100.5488,14.6438,Ban Phraek,ADM2,DISTRICT
SM297724,102.4456,17.6771,Ban Phu,ADM2,DISTRICT
SM297446,99.8215,13.8166,Ban Pong,ADM2,DISTRICT
SM297733,99.3282,15.2492,Ban Rai,ADM2,DISTRICT
SM297403,101.2506,13.9501,Ban Sang,ADM2,DISTRICT
SM297630,98.6722,9.1042,Ban Ta Khun,ADM2,DISTRICT
SM297654,99.0665,17.0916,Ban Tak,ADM2,DISTRICT
SM297776,102.3568,16.3817,Ban Thaen,ADM2,DISTRICT
SM297030,99.1588,18.6589,Ban Thi,ADM2,DISTRICT
SM226443,13.6131,10.3535,Bana,LANGUAGE,LANGUAGE
SM583,45.4,2.1167,Banaadir,ADM1,DISTRICT
SM13320,169.538,-0.855,Banaba,ADM2,DISTRICT
SM267879,-38.8746,-5.2356,Banabuiú,ADM2,DISTRICT
SM17849,45.4268,2.1072,Banadir,ADM2,DISTRICT
SM226432,90.2,25.8,Banai,DIALECT,LANGUAGE
SM283357,-7.333,13.75,Banamba,ADM2,DISTRICT
SM280408,103.0474,12.9707,Banan,ADM2,DISTRICT
SM261631,149.8984,-24.8065,Banana,ADM2,DISTRICT
SM266943,-44.3341,-22.7375,Bananal,ADM2,DISTRICT
SM264334,-35.5901,-6.6822,Bananeiras,ADM2,DISTRICT
SM17190,-61.0014,14.0109,Banannes Bay,ADM2,DISTRICT
SM226437,121.05,17.5643,Banao Itneg,LANGUAGE,LANGUAGE
SM226441,144.329,-4.5662,Banaro,LANGUAGE,LANGUAGE
SM251982,143.71,-4.69,Banaro,ETHNICITY,ETHNICITY
SM251982,143.71,-4.69,Banaro,ETHNICITY,ETHNICITY
SM251982,143.71,-4.69,Banaro,ETHNICITY,ETHNICITY
SM251982,144.34,-4.34,Banaro,ETHNICITY,ETHNICITY
SM251982,144.34,-4.34,Banaro,ETHNICITY,ETHNICITY
SM251982,144.34,-4.34,Banaro,ETHNICITY,ETHNICITY
SM251982,144.34,-4.56,Banaro,ETHNICITY,ETHNICITY
SM251982,144.34,-4.56,Banaro,ETHNICITY,ETHNICITY
SM251982,144.34,-4.56,Banaro,ETHNICITY,ETHNICITY
SM276839,72.5,24.25,Banas Kantha (2000-),ADM2,DISTRICT
SM226445,-64.8962,-6.741,Banawa,DIALECT,LANGUAGE
SM298986,29.758,38.7467,Banaz,ADM2,DISTRICT
SM16297,12.0956,-3.6637,Banba,ADM2,DISTRICT
SM4343,-2.381,8.2615,Banda,ADM2,DISTRICT
SM261058,-64.3333,-27.5,Banda,ADM2,DISTRICT
SM250068,14.16,3.26,Banda,ETHNICITY,ETHNICITY
SM250068,14.16,3.26,Banda,ETHNICITY,ETHNICITY
SM250068,22,7,Banda,ETHNICITY,ETHNICITY
SM250068,22,7,Banda,ETHNICITY,ETHNICITY
SM277272,80.5,25.5,Banda (1997-),ADM2,DISTRICT
SM226468,133.142,-5.4214,Banda (Indonesia),LANGUAGE,LANGUAGE
SM276283,95.3333,5.5417,Banda Aceh,ADM2,DISTRICT
SM226466,129.905,-4.5557,Banda Malay,LANGUAGE,LANGUAGE
SM226457,20.9827,6.2156,Banda-Bambari,LANGUAGE,LANGUAGE
SM226456,22.7568,6.6389,Banda-Banda,LANGUAGE,LANGUAGE
SM226455,23.0108,7.3292,Banda-Mbrès,LANGUAGE,LANGUAGE
SM226458,23.9021,8.0334,Banda-Ndélé,LANGUAGE,LANGUAGE
SM226461,16.5907,3.9576,Banda-Yangere,LANGUAGE,LANGUAGE
SM284403,100.6068,5.2301,Bandar Baharu,ADM2,DISTRICT
SM276439,105.25,-5.4167,Bandar Lampung,ADM2,DISTRICT
SM11753,54.65,26.7833,Bandar Lengeh,ADM2,DISTRICT
SM277727,49.3667,37.45,Bandar-e Anzali,ADM2,DISTRICT
SM11754,53.9667,36.7167,Bandar-e Gaz,ADM2,DISTRICT
SM277493,56.2833,27.55,Bandar-e-Abbas,ADM2,DISTRICT
SM277494,58.2,25.8833,Bandar-e-Jask,ADM2,DISTRICT
SM262230,92.3333,22,Bandarban,ADM2,DISTRICT
SM461490,56.267,27.183,Bandari,ETHNICITY,ETHNICITY
SM263735,-40.5997,-15.8804,Bandeira,ADM2,DISTRICT
SM266356,-53.6491,-26.7686,Bandeirante,ADM2,DISTRICT
SM264677,-50.3387,-23.1552,Bandeirantes,ADM2,DISTRICT
SM263023,-54.331,-19.8086,Bandeirantes,ADM2,DISTRICT
SM267202,-48.6822,-8.0042,Bandeirantes do Tocantins,ADM2,DISTRICT
SM295811,50.3293,9.2302,Bander-Beyla,ADM2,DISTRICT
SM302640,-99.2462,29.7472,Bandera,ADM2,DISTRICT
SM282848,-96.94,19.5826,Banderilla,ADM2,DISTRICT
SM226465,-10.1684,7.7373,Bandi,LANGUAGE,LANGUAGE
SM283364,-3.581,14.484,Bandiagara,ADM2,DISTRICT
SM226453,-16.4245,12.5258,Bandial,LANGUAGE,LANGUAGE
SM263746,-46.3828,-21.7291,Bandiera do Sul,ADM2,DISTRICT
SM276892,74.8283,34.504,Bandipore,ADM2,DISTRICT
SM303436,67.3667,37.8333,Bandixon,ADM2,DISTRICT
SM226452,152.859,-28.3458,Bandjalangic,FAMILY,LANGUAGE
SM226451,143.282,-30.0771,Bandjigali,LANGUAGE,LANGUAGE
SM250070,10,6,Bandobo,ETHNICITY,ETHNICITY
SM584,45.122,-12.7022,Bandraboua,ADM1,DISTRICT
SM585,45.1939,-12.9111,Bandrele,ADM1,DISTRICT
SM276766,107.6,-7.1,Bandung,ADM2,DISTRICT
SM276755,107.4833,-6.8333,Bandung Barat,ADM2,DISTRICT
SM298173,27.9638,40.2728,Bandırma,ADM2,DISTRICT
SM278378,139.8879,36.0638,Bandō,ADM2,DISTRICT
SM290570,26.0607,44.0379,Baneasa,ADM2,DISTRICT
SM290458,27.9796,45.9301,Baneasa,ADM2,DISTRICT
SM290203,27.7143,44.0501,Baneasa,ADM2,DISTRICT
SM277547,45.8167,35.9833,Baneh,ADM2,DISTRICT
SM250777,11.82,3.39,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,11.82,3.39,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,11.82,3.39,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,11.82,3.39,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,11.82,3.39,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.7915,4.6665,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.7915,4.6665,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.7915,4.6665,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.7915,4.6665,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.7915,4.6665,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,11,5,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,11,5,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,11,5,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,11,5,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,11,5,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.8,4.67,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.8,4.67,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.8,4.67,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.8,4.67,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.8,4.67,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.8,4.8,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.8,4.8,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.8,4.8,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.8,4.8,Banen-Bandem,ETHNICITY,ETHNICITY
SM250777,10.8,4.8,Banen-Bandem,ETHNICITY,ETHNICITY
SM271131,-75.7493,20.9801,Banes,ADM2,DISTRICT
SM291493,25.7784,45.0949,Banesti,ADM2,DISTRICT
SM290277,25.5741,44.7365,Banesti,ADM2,DISTRICT
SM275115,8.9107,3.7033,Baney,ADM2,DISTRICT
SM324113,-115.5663,51.1765,Banff,ADM2:ADM3,DISTRICT
SM297384,100.4667,14.3833,Bang Ban,ADM2,DISTRICT
SM297511,100.8671,13.5896,Bang Bo,ADM2,DISTRICT
SM297144,100.388,13.6592,Bang Bon,ADM2,DISTRICT
SM297275,100.3936,13.9264,Bang Bua Thong,ADM2,DISTRICT
SM297316,100.1716,7.4178,Bang Kaeo,ADM2,DISTRICT
SM297155,100.6385,13.7726,Bang Kapi,ADM2,DISTRICT
SM297166,100.3928,13.7193,Bang Khae,ADM2,DISTRICT
SM297209,99.4845,8.0121,Bang Khan,ADM2,DISTRICT
SM297177,100.6277,13.869,Bang Khen,ADM2,DISTRICT
SM297526,101.2075,13.7419,Bang Khla,ADM2,DISTRICT
SM297188,100.5097,13.7003,Bang Kho Laem,ADM2,DISTRICT
SM297521,99.9484,13.4745,Bang Khon Ti,ADM2,DISTRICT
SM297582,100.4066,7.064,Bang Klam,ADM2,DISTRICT
SM297368,100.3504,16.5932,Bang Krathum,ADM2,DISTRICT
SM297276,100.4153,13.8025,Bang Kruai,ADM2,DISTRICT
SM296917,100.8924,12.9258,Bang Lamung,ADM2,DISTRICT
SM297093,100.1833,14.05,Bang Len,ADM2,DISTRICT
SM297356,100.4319,16.0266,Bang Mun Nak,ADM2,DISTRICT
SM297190,100.6209,13.6725,Bang Na,ADM2,DISTRICT
SM297527,101.0323,13.8803,Bang Nam Prieo,ADM2,DISTRICT
SM297385,100.5941,14.2348,Bang Pa-In,ADM2,DISTRICT
SM297386,100.5473,14.4717,Bang Pahan,ADM2,DISTRICT
SM297528,100.9605,13.5324,Bang Pakong,ADM2,DISTRICT
SM297448,99.9833,13.6833,Bang Phae,ADM2,DISTRICT
SM297608,100.1448,14.3499,Bang Pla Ma,ADM2,DISTRICT
SM297191,100.4915,13.7867,Bang Plad,ADM2,DISTRICT
SM297512,100.7258,13.6197,Bang Plee,ADM2,DISTRICT
SM297576,100.278,14.8996,Bang Rachan,ADM2,DISTRICT
SM297192,100.5239,13.7306,Bang Rak,ADM2,DISTRICT
SM297369,100.0422,16.7184,Bang Rakam,ADM2,DISTRICT
SM297387,100.4942,14.2104,Bang Sai,ADM2,DISTRICT
SM297387,100.4942,14.2104,Bang Sai,ADM2,DISTRICT
SM297387,100.2976,14.3172,Bang Sai,ADM2,DISTRICT
SM297387,100.2976,14.3172,Bang Sai,ADM2,DISTRICT
SM297434,99.4441,11.294,Bang Saphan,ADM2,DISTRICT
SM297433,99.3411,11.0706,Bang Saphan Noi,ADM2,DISTRICT
SM297193,100.5279,13.8192,Bang Su,ADM2,DISTRICT
SM297277,100.3732,13.8539,Bang Yai,ADM2,DISTRICT
SM260430,15.1671,-8.6841,Banga,ADM2,DISTRICT
SM245168,121.068,17.2936,Bangad,LANGUAGE,LANGUAGE
SM226494,28.0445,3.3466,Bangala,LANGUAGE,LANGUAGE
SM250074,18.48,1.44,Bangala,ETHNICITY,ETHNICITY
SM250074,18.48,1.44,Bangala,ETHNICITY,ETHNICITY
SM250074,18,1,Bangala,ETHNICITY,ETHNICITY
SM250074,18,1,Bangala,ETHNICITY,ETHNICITY
SM276945,77.5833,13,Bangalore,ADM2,DISTRICT
SM276934,77.575,13.2257,Bangalore Rural (2007-),ADM2,DISTRICT
SM226477,29.8692,79.0686,Bangani,DIALECT,LANGUAGE
SM295346,156.7794,-6.8468,Bangara,ADM2,DISTRICT
SM268600,23.1533,5.0135,Bangassou,ADM2,DISTRICT
SM226487,28.3138,3.3996,Bangba,LANGUAGE,LANGUAGE
SM226508,123.171,-1.5831,Banggai,LANGUAGE,LANGUAGE
SM252003,123.46,-1.32,Banggai,ETHNICITY,ETHNICITY
SM276594,123.0373,-1.3024,Banggai Kepulauan,ADM2,DISTRICT
SM252004,136.68,-33.1,Banggala,ETHNICITY,ETHNICITY
SM252004,136.68,-33.1,Banggala,ETHNICITY,ETHNICITY
SM252004,135.7,-33.73,Banggala,ETHNICITY,ETHNICITY
SM252004,135.7,-33.73,Banggala,ETHNICITY,ETHNICITY
SM252004,136.8101,-32.6884,Banggala,ETHNICITY,ETHNICITY
SM252004,136.8101,-32.6884,Banggala,ETHNICITY,ETHNICITY
SM226481,136.079,-33.3036,Banggarla,LANGUAGE,LANGUAGE
SM226495,17.227,-0.8744,Bangi,LANGUAGE,LANGUAGE
SM260208,69.3381,36.6134,Bangi,ADM2,DISTRICT
SM226503,-3.7709,14.8116,Bangime,LANGUAGE,LANGUAGE
SM226505,105.897,-1.7329,Bangka,LANGUAGE,LANGUAGE
SM276635,105.9333,-1.9167,Bangka,ADM2,DISTRICT
SM276632,105.5374,-1.9584,Bangka Barat,ADM2,DISTRICT
SM586,106.6667,-2.6667,Bangka Belitung,ADM1,DISTRICT
SM276633,106.0126,-2.668,Bangka Selatan,ADM2,DISTRICT
SM276634,106.1142,-2.3399,Bangka Tengah,ADM2,DISTRICT
SM276339,112.9333,-7.05,Bangkalan,ADM2,DISTRICT
SM297145,100.4269,13.5948,Bangkhuntien,ADM2,DISTRICT
SM587,100.7199,13.8772,Bangkok Metropolis,ADM1:ADMX,DISTRICT
SM297146,100.4699,13.7655,Bangkok Noi,ADM2,DISTRICT
SM297147,100.4743,13.7375,Bangkok Yai,ADM2,DISTRICT
SM238033,91.6132,25.1292,Bangladesh Lyngam,LANGUAGE,LANGUAGE
SM276474,115.35,-8.2833,Bangli,ADM2,DISTRICT
SM226497,10.667,6.0125,Bangolan,LANGUAGE,LANGUAGE
SM12122,-76.689,18.1364,Bangor Ridge,ADM2,DISTRICT
SM226509,93.15,27.95,Bangru,LANGUAGE,LANGUAGE
SM226492,27.2438,-4.3146,Bangubangu,LANGUAGE,LANGUAGE
SM268580,18.6062,4.3806,Bangui,ADM2,DISTRICT
SM588,18.5667,4.3833,Bangui,ADM1,DISTRICT
SM226490,11.2022,9.5716,Bangwinji,LANGUAGE,LANGUAGE
SM273722,31.2159,30.4629,Banha,ADM2,DISTRICT
SM252008,-76.97,20.96,Bani,ETHNICITY,ETHNICITY
SM304836,43.6079,15.581,Bani Al Awam,ADM2,DISTRICT
SM305093,44.2601,15.5062,Bani Al Harith,ADM2,DISTRICT
SM304946,44.8718,15.0481,Bani Dhabyan,ADM2,DISTRICT
SM252009,49.98,24.93,Bani Hajir,ETHNICITY,ETHNICITY
SM252010,41.14,19.47,Bani Hilal,ETHNICITY,ETHNICITY
SM304947,44.3782,15.4505,Bani Hushaysh,ADM2,DISTRICT
SM252011,48.44,27.28,Bani Khalid,ETHNICITY,ETHNICITY
SM252012,54.7,23.98,Bani Kitab,ETHNICITY,ETHNICITY
SM304948,44.0498,15.215,Bani Matar,ADM2,DISTRICT
SM273667,30.7574,28.5079,Bani Mazar,ADM2,DISTRICT
SM304837,43.3372,15.6346,Bani Qa'is,ADM2,DISTRICT
SM305082,43.5156,15.2355,Bani Sa'd,ADM2,DISTRICT
SM252013,41.53,20.31,Bani Shahr,ETHNICITY,ETHNICITY
SM304788,43.9602,16.1253,Bani Suraim,ADM2,DISTRICT
SM589,30.6,28.9,Bani Suwayf,ADM1,DISTRICT
SM252014,45.27,17.38,Bani Yam,ETHNICITY,ETHNICITY
SM290023,22.0275,44.872,Bania,ADM2,DISTRICT
SM15216,2.4714,15.1062,Banibangou,ADM2,DISTRICT
SM271777,-71.6167,19.0333,Banica,ADM2,DISTRICT
SM262103,2.4592,11.3397,Banikoara,ADM2,DISTRICT
SM291633,22.8365,47.1022,Banisor,ADM2,DISTRICT
SM290791,23.2321,45.4564,Banita,ADM2,DISTRICT
SM262406,25,41.7,Banite,ADM2,DISTRICT
SM231783,-66.9225,1.8084,Baniva de Maroa,LANGUAGE,LANGUAGE
SM252017,-66.77,3.02,Baniwa,ETHNICITY,ETHNICITY
SM252017,-67.56,2.83,Baniwa,ETHNICITY,ETHNICITY
SM226515,-67.7704,2.1786,Baniwa do Icana,LANGUAGE,LANGUAGE
SM296753,36.0786,35.137,Baniyas,ADM2,DISTRICT
SM313180,17.0875,44.7319,Banja Luka,ADM2:ADM3,DISTRICT
SM226523,115.79,0.7471,Banjar,LANGUAGE,LANGUAGE
SM276397,115.0833,-3.3167,Banjar,ADM2,DISTRICT
SM276775,108.5321,-7.37,Banjar,ADM2,DISTRICT
SM276396,114.8116,-3.483,Banjar Baru,ADM2,DISTRICT
SM276398,114.591,-3.3244,Banjarmasin,ADM2,DISTRICT
SM276303,109.5875,-7.3511,Banjarnegara,ADM2,DISTRICT
SM590,-16.5833,13.45,Banjul,ADM1,DISTRICT
SM277435,86.9843,24.8921,Banka,ADM2,DISTRICT
SM252023,105.4,-3.05,Banka,ETHNICITY,ETHNICITY
SM226530,-5.5074,11.7497,Bankagooma,LANGUAGE,LANGUAGE
SM226531,-2.92,15.06,Bankan Tey Dogon,LANGUAGE,LANGUAGE
SM283365,-3.6035,13.6597,Bankass,ADM2,DISTRICT
SM375337,81.7732,28.0958,Banke,ADM2:ADM3,DISTRICT
SM15217,0.6647,14.5084,Bankilare,ADM2,DISTRICT
SM226528,9.6403,4.3702,Bankon,LANGUAGE,LANGUAGE
SM300495,-83.4974,34.3541,Banks,ADM2,DISTRICT
SM12123,-77.3073,17.8322,Banks,ADM2,DISTRICT
SM252025,167.7,-13.84,Banks Islands,ETHNICITY,ETHNICITY
SM277367,87.25,23.25,Bankura,ADM2,DISTRICT
SM253808,36,6,Banna,ETHNICITY,ETHNICITY
SM264131,-50.659,-7.4686,Bannach,ADM2,DISTRICT
SM297750,101.2785,6.2564,Bannang Star,ADM2,DISTRICT
SM301711,-103.7105,41.546,Banner,ADM2,DISTRICT
SM300566,-112.2246,42.6685,Bannock,ADM2,DISTRICT
SM252027,-119.14,42.73,Bannock,ETHNICITY,ETHNICITY
SM252027,-119.14,42.73,Bannock,ETHNICITY,ETHNICITY
SM252027,-119.14,42.73,Bannock,ETHNICITY,ETHNICITY
SM252027,-112,43,Bannock,ETHNICITY,ETHNICITY
SM252027,-112,43,Bannock,ETHNICITY,ETHNICITY
SM252027,-112,43,Bannock,ETHNICITY,ETHNICITY
SM252027,-112.32,43.66,Bannock,ETHNICITY,ETHNICITY
SM252027,-112.32,43.66,Bannock,ETHNICITY,ETHNICITY
SM252027,-112.32,43.66,Bannock,ETHNICITY,ETHNICITY
SM226534,155.128,-6.2306,Bannoni,LANGUAGE,LANGUAGE
SM375411,70.6368,32.9036,Bannu,ADM2:ADM3,DISTRICT
SM5105,70.6087,33.1144,Bannu Frontier Region,ADM2:ADM3,DISTRICT
SM297194,99.9941,15.9971,Banphot Phisai,ADM2,DISTRICT
SM17191,-60.9928,13.7921,Banse,ADM2,DISTRICT
SM17192,-60.9984,13.7815,Banse La Grace,ADM2,DISTRICT
SM262288,23.6,41.8,Bansko,ADM2,DISTRICT
SM591,19.5,48.5,Banskobystrický,ADM1,DISTRICT
SM296113,19.15,48.7333,Banská Bystrica,ADM2,DISTRICT
SM296118,18.9082,48.4496,Banská Štiavnica,ADM2,DISTRICT
SM277185,74.3333,23.5,Banswara (2008-),ADM2,DISTRICT
SM276570,119.9833,-5.4833,Bantaeng,ADM2,DISTRICT
SM226559,102.06,21.7253,Bantang,LANGUAGE,LANGUAGE
SM226543,87.0474,27.1215,Bantawa,LANGUAGE,LANGUAGE
SM226554,123.755,11.2144,Bantayanon,LANGUAGE,LANGUAGE
SM280374,103.2593,14.1859,Banteay Ampil,ADM2,DISTRICT
SM280501,104.5333,10.6167,Banteay Meas,ADM2,DISTRICT
SM280430,104.0176,13.5725,Banteay Srei,ADM2,DISTRICT
SM593,106.25,-6.5,Banten,ADM1,DISTRICT
SM226547,124.846,1.4459,Bantik,LANGUAGE,LANGUAGE
SM226549,122.034,12.5194,Bantoanon,LANGUAGE,LANGUAGE
SM276708,110.3667,-7.9,Bantul,ADM2,DISTRICT
SM262157,1.872,8.34,Bantè,ADM2,DISTRICT
SM262180,-4.1667,12.1667,Banwa,ADM1:ADM2,DISTRICT
SM252039,96.16,2.61,Banyak,ETHNICITY,ETHNICITY
SM255959,35.39,0.74,Banyala,ETHNICITY,ETHNICITY
SM250855,9,6,Banyang,ETHNICITY,ETHNICITY
SM251062,30.04,-0.24,Banyankole,ETHNICITY,ETHNICITY
SM251062,30.04,-0.24,Banyankole,ETHNICITY,ETHNICITY
SM251062,31.39,-2.47,Banyankole,ETHNICITY,ETHNICITY
SM251062,31.39,-2.47,Banyankole,ETHNICITY,ETHNICITY
SM251062,31,0,Banyankole,ETHNICITY,ETHNICITY
SM251062,31,0,Banyankole,ETHNICITY,ETHNICITY
SM250616,31.81,1.98,Banyoro,ETHNICITY,ETHNICITY
SM250616,31.81,1.98,Banyoro,ETHNICITY,ETHNICITY
SM250616,32,2,Banyoro,ETHNICITY,ETHNICITY
SM250616,32,2,Banyoro,ETHNICITY,ETHNICITY
SM276659,104.3831,-2.8833,Banyu Asin,ADM2,DISTRICT
SM252041,-5.71,7.84,Banyua,ETHNICITY,ETHNICITY
SM261249,145.0858,-37.7339,Banyule,ADM2,DISTRICT
SM276314,109.1667,-7.45,Banyumas,ADM2,DISTRICT
SM276350,114.2,-8.3333,Banyuwangi,ADM2,DISTRICT
SM267620,-38.6269,-10.6158,Banzaê,ADM2,DISTRICT
SM250246,21.44,5.46,Banzirti,ETHNICITY,ETHNICITY
SM282553,-110.2414,30.0445,Banámichi,ADM2,DISTRICT
SM271814,-70.3833,18.3333,Baní,ADM2,DISTRICT
SM10265,23.0265,16.5083,Bao,ADM2,DISTRICT
SM269136,115.48,38.866,Baoding,ADM2,DISTRICT
SM269283,107.1364,34.3694,Baoji,ADM2,DISTRICT
SM268557,16.1272,5.4516,Baoro,ADM2,DISTRICT
SM269366,98.8965,25.0807,Baoshan,ADM2,DISTRICT
SM269259,110.2354,41.6555,Baotou,ADM2,DISTRICT
SM250076,-2.77,7.33,Baoule,ETHNICITY,ETHNICITY
SM250076,-2.77,7.33,Baoule,ETHNICITY,ETHNICITY
SM250076,-5,8,Baoule,ETHNICITY,ETHNICITY
SM250076,-5,8,Baoule,ETHNICITY,ETHNICITY
SM226572,-4.7657,5.4251,Baoulé,LANGUAGE,LANGUAGE
SM226575,-12.79,12.13,Bapen,LANGUAGE,LANGUAGE
SM12124,-76.5784,17.8767,Baptist,ADM2,DISTRICT
SM252043,11.66,-2.74,Bapuna,ETHNICITY,ETHNICITY
SM252043,10.77,-2.91,Bapuna,ETHNICITY,ETHNICITY
SM17772,42.8333,28,Baqa',ADM2,DISTRICT
SM304928,43.4635,17.3781,Baqim,ADM2,DISTRICT
SM252044,44.14,20.76,Baqum,ETHNICITY,ETHNICITY
SM594,19.1667,42.1667,Bar,ADM1,DISTRICT
SM280418,107.1944,13.7075,Bar Kaev,ADM2,DISTRICT
SM260083,71.3777,35.0719,Bar Kunar,ADM2,DISTRICT
SM295241,30.3757,13.7054,Bara,ADM2,DISTRICT
SM291977,21.9044,45.911,Bara,ADM2,DISTRICT
SM375308,85.0693,27.1074,Bara,ADM2:ADM3,DISTRICT
SM250078,45.31,-22.54,Bara,ETHNICITY,ETHNICITY
SM252045,-70.31,-0.11,Bara,ETHNICITY,ETHNICITY
SM17193,-60.9341,13.9591,Bara Bara,ADM2,DISTRICT
SM226596,45.5937,-23.9716,Bara Malagasy,LANGUAGE,LANGUAGE
SM226584,85.0584,28.3768,Baraamu,LANGUAGE,LANGUAGE
SM295788,43.8449,1.1084,Baraawe,ADM2,DISTRICT
SM259607,78.3308,55.1496,Baraba Tatar,DIALECT,LANGUAGE
SM252048,147.12,-35.36,Baraba-baraba,ETHNICITY,ETHNICITY
SM252048,147.12,-35.36,Baraba-baraba,ETHNICITY,ETHNICITY
SM252048,144.6114,-35.6737,Baraba-baraba,ETHNICITY,ETHNICITY
SM252048,144.6114,-35.6737,Baraba-baraba,ETHNICITY,ETHNICITY
SM252050,35.73,-4.25,Barabaig,ETHNICITY,ETHNICITY
SM277273,81.3274,26.9386,Barabanki (1996-),ADM2,DISTRICT
SM229410,35.2321,-4.4131,Barabayiiga-Gisamjanga,LANGUAGE,LANGUAGE
SM16849,78.3333,55.0833,Barabinskiy Rayon,ADM2,DISTRICT
SM271114,-74.5848,20.3682,Baracoa,ADM2,DISTRICT
SM260719,-59.5,-33.8333,Baradero,ADM2,DISTRICT
SM458421,148.9253,-21.9824,Baradha,ETHNICITY,ETHNICITY
SM301317,-88.3522,46.6998,Baraga,ADM2,DISTRICT
SM292671,27.5152,44.8107,Baraganul,ADM2,DISTRICT
SM271068,-78.6244,21.6819,Baraguá,ADM2,DISTRICT
SM595,-71.25,18.1667,Barahona,ADM1,DISTRICT
SM15456,-66.4478,18.3594,Barahona Barrio,ADM2,DISTRICT
SM241030,147.899,-9.4625,Barai,LANGUAGE,LANGUAGE
SM226637,17.9117,10.9701,Barain,LANGUAGE,LANGUAGE
SM226594,134.671,-6.7394,Barakai,LANGUAGE,LANGUAGE
SM260110,68.8852,33.9299,Baraki Barak,ADM2,DISTRICT
SM303806,-70.9167,9.8333,Baralt,ADM2,DISTRICT
SM226597,114.482,3.5121,Baram Kayan,LANGUAGE,LANGUAGE
SM226589,10.6777,-2.4966,Barama,LANGUAGE,LANGUAGE
SM226588,26.861,3.3464,Barambu,LANGUAGE,LANGUAGE
SM250077,27,4,Barambu,ETHNICITY,ETHNICITY
SM226605,143.002,-8.4885,Baramu,LANGUAGE,LANGUAGE
SM276893,74.3692,34.1929,Baramula (2006-),ADM2,DISTRICT
SM277190,76.66,25.09,Baran,ADM2,DISTRICT
SM458424,148.1985,-22.0806,Baranha,ETHNICITY,ETHNICITY
SM299852,27.6667,50.3333,Baranivs'kyi,ADM2,DISTRICT
SM270659,-74.9207,10.795,Baranoa,ADM2,DISTRICT
SM9755,25.8333,53.1667,Baranovichskiy Rayon (excl. Baranvichy),ADM2,DISTRICT
SM596,18.25,46.0833,Baranya,ADM1,DISTRICT
SM252055,61.2,28.66,Baranzai,ETHNICITY,ETHNICITY
SM457979,-51.5462,-29.3964,Barao,ADM2,DISTRICT
SM265806,-52.4475,-27.5511,Barao de Cotegipe,ADM2,DISTRICT
SM290228,25.605,46.0625,Baraolt,ADM2,DISTRICT
SM226602,137.054,-2.2873,Barapasi,LANGUAGE,LANGUAGE
SM226598,119.342,-1.5667,Baras,LANGUAGE,LANGUAGE
SM226606,-70.808,0.0219,Barasana-Eduria,LANGUAGE,LANGUAGE
SM291434,24.6447,44.7085,Barastii De Vede,ADM2,DISTRICT
SM284321,100.2193,5.3751,Barat Daya,ADM2,DISTRICT
SM284321,100.2193,5.3751,Barat Daya,ADM2,DISTRICT
SM284321,100.235,5.3622,Barat Daya,ADM2,DISTRICT
SM284321,100.235,5.3622,Barat Daya,ADM2,DISTRICT
SM280492,105.06,12.42,Baray,ADM2,DISTRICT
SM270113,-74.954,3.1453,Baraya,ADM2,DISTRICT
SM265494,-37.5223,-4.9719,Baraúna,ADM2,DISTRICT
SM264345,-36.2696,-6.6185,Baraúna,ADM2,DISTRICT
SM263780,-43.8417,-21.2503,Barbacena,ADM2,DISTRICT
SM252058,-80,22.34,Barbacoa,ETHNICITY,ETHNICITY
SM252059,-76.7,4.14,Barbacoan,ETHNICITY,ETHNICITY
SM252059,-78.01,2.23,Barbacoan,ETHNICITY,ETHNICITY
SM226616,-78.4834,1.5206,Barbacoas,LANGUAGE,LANGUAGE
SM270344,-78.1222,1.4817,Barbacoas,ADM2,DISTRICT
SM267880,-39.3527,-7.436,Barbalha,ADM2,DISTRICT
SM305667,14.0142,45.0639,Barban,ADM2,DISTRICT
SM226618,-119.699,34.462,Barbareño,LANGUAGE,LANGUAGE
SM12125,-77.7796,17.968,Barbary Hall,ADM2,DISTRICT
SM290677,23.5076,44.8592,Barbatesti,ADM2,DISTRICT
SM13430,24.6108,56.4629,Barbeles pagasts,ADM2,DISTRICT
SM300922,-98.6848,37.2288,Barber,ADM2,DISTRICT
SM275309,-90.408,14.3072,Barberena,ADM2,DISTRICT
SM12126,-76.7699,18.0355,Barbican,ADM2,DISTRICT
SM270474,-73.6373,5.9516,Barbosa,ADM2,DISTRICT
SM270178,-75.3333,6.45,Barbosa,ADM2,DISTRICT
SM266965,-49.9229,-21.2962,Barbosa,ADM2,DISTRICT
SM264688,-52.0549,-24.0613,Barbosa Ferraz,ADM2,DISTRICT
SM303108,-80.003,39.1329,Barbour,ADM2,DISTRICT
SM300268,-85.3932,31.8696,Barbour,ADM2,DISTRICT
SM597,-61.8,17.6333,Barbuda,ADM1,DISTRICT
SM290414,23.6178,43.9695,Barca,ADM2,DISTRICT
SM261642,145.4349,-23.052,Barcaldine,ADM2,DISTRICT
SM291494,26.0667,44.877,Barcanesti,ADM2,DISTRICT
SM290945,26.6679,44.6352,Barcanesti,ADM2,DISTRICT
SM264132,-48.6325,-1.5466,Barcarena,ADM2,DISTRICT
SM290469,27.4627,45.7474,Barcea,ADM2,DISTRICT
SM274076,2,41.6667,Barcelona,ADM2,DISTRICT
SM265505,-35.9202,-5.9427,Barcelona,ADM2,DISTRICT
SM598,-66.5413,18.4563,Barceloneta,ADM1,DISTRICT
SM15457,-66.5385,18.4546,Barceloneta Barrio-Pueblo,ADM2,DISTRICT
SM289439,-8.6123,41.5318,Barcelos,ADM2,DISTRICT
SM267362,-63.5371,-0.3929,Barcelos,ADM2,DISTRICT
SM13990,-8.1844,4.727,Barclayville,ADM2,DISTRICT
SM226623,-8.0938,4.6784,Barclayville Grebo,LANGUAGE,LANGUAGE
SM261653,142.4648,-25.1729,Barcoo,ADM2,DISTRICT
SM276166,17.4743,46.0369,Barcs,ADM2,DISTRICT
SM11634,87.75,23.25,Barddhaman (2017-),ADM2,DISTRICT
SM296152,21.2833,49.2833,Bardejov,ADM2,DISTRICT
SM277596,57.4833,35.2667,Bardeskan,ADM2,DISTRICT
SM226629,122.906,-16.6274,Bardi,LANGUAGE,LANGUAGE
SM375338,81.4503,28.3718,Bardiya,ADM2:ADM3,DISTRICT
SM298030,10.129,36.8096,Bardo,ADM2,DISTRICT
SM268444,90.9906,27.0729,Bardo,ADM2,DISTRICT
SM277511,56.5833,29.8333,Bardsir,ADM2,DISTRICT
SM286234,18.3505,68.8606,Bardu,ADM2,DISTRICT
SM252067,-65.77,2.54,Bare,ETHNICITY,ETHNICITY
SM277274,79.3833,28.4167,Bareilly,ADM2,DISTRICT
SM285707,4.5254,51.8497,Barendrecht,ADM2,DISTRICT
SM274003,37.586,15.112,Barentu,ADM2,DISTRICT
SM226642,145.756,-4.8723,Bargam,LANGUAGE,LANGUAGE
SM277131,83.6167,21.3333,Bargarh,ADM2,DISTRICT
SM291347,26.6459,46.9788,Bargauani,ADM2,DISTRICT
SM291767,24.5043,46.0113,Barghis,ADM2,DISTRICT
SM260140,71.2535,35.7746,Bargi Matal,ADM2,DISTRICT
SM297965,9.6155,36.1063,Bargou,ADM2,DISTRICT
SM262224,90.1333,22.1333,Barguna,ADM2,DISTRICT
SM295111,109.75,54,Barguzinskiy rayon,ADM2,DISTRICT
SM296814,20.28,11.05,Barh Azoum,ADM2,DISTRICT
SM10266,18.2463,9.2734,"Barh Koeh (excl. Djoli, Korbol)",ADM2,DISTRICT
SM296799,17.72,8.34,Barh Sara,ADM2,DISTRICT
SM296835,18.6171,10.9368,Barh Signaka,ADM2,DISTRICT
SM599,16.9167,14.6667,Barh el Ghazel,ADM1,DISTRICT
SM10268,17.1145,14.8701,Barh-El-Gazel Nord,ADM2,DISTRICT
SM10269,16.1919,13.4091,Barh-El-Gazel Ouest,ADM2,DISTRICT
SM10270,16.7303,13.4902,Barh-El-Gazel Sud,ADM2,DISTRICT
SM226649,31.0723,4.1952,Bari,LANGUAGE,LANGUAGE
SM277922,16.6667,40.9333,Bari,ADM2,DISTRICT
SM600,50,10,Bari,ADM1,DISTRICT
SM250080,31.57,4.83,Bari,ETHNICITY,ETHNICITY
SM250080,31.57,4.83,Bari,ETHNICITY,ETHNICITY
SM252073,-72.36,9.67,Bari,ETHNICITY,ETHNICITY
SM252073,-72.36,9.67,Bari,ETHNICITY,ETHNICITY
SM252073,-72.47,8.86,Bari,ETHNICITY,ETHNICITY
SM252073,-72.47,8.86,Bari,ETHNICITY,ETHNICITY
SM252073,-72,9,Bari,ETHNICITY,ETHNICITY
SM252073,-72,9,Bari,ETHNICITY,ETHNICITY
SM250080,32,5,Bari,ETHNICITY,ETHNICITY
SM250080,32,5,Bari,ETHNICITY,ETHNICITY
SM299173,34.2608,-2.5754,Bariadi,ADM2,DISTRICT
SM226650,148.666,-5.5087,Bariai,LANGUAGE,LANGUAGE
SM250051,-1.92,10.62,Bariba,ETHNICITY,ETHNICITY
SM280476,104.47,12.43,Baribour,ADM2,DISTRICT
SM270485,-73.2339,6.6456,Barichara,ADM2,DISTRICT
SM226653,148.454,-9.231,Bariji,LANGUAGE,LANGUAGE
SM457393,5.3333,35.4167,Barika District,ADM2,DISTRICT
SM226645,8.4331,11.989,Barikanchi,LANGUAGE,LANGUAGE
SM226655,143.9928,-7.0974,Barikewa,LANGUAGE,LANGUAGE
SM260937,-71.5,-41.5,Bariloche,ADM2,DISTRICT
SM305688,15.5494,45.3824,Barilović,ADM2,DISTRICT
SM601,-59.75,7.6667,Barima-Waini,ADM1,DISTRICT
SM15458,-66.8479,17.9917,Barina Barrio,ADM2,DISTRICT
SM303848,-70.1985,8.615,Barinas,ADM2,DISTRICT
SM602,-69.8333,8.1667,Barinas,ADM1,DISTRICT
SM603,36,0.6667,Baringo,ADM1:ADM2,DISTRICT
SM297800,126.0656,-8.8547,Barique,ADM2,DISTRICT
SM266976,-48.7076,-22.0561,Bariri,ADM2,DISTRICT
SM273731,29.6342,22.7904,Baris Shurtah,ADM2,DISTRICT
SM262225,90.5,22.8,Barisal,ADM2,DISTRICT
SM604,90.3333,22.5,Barisal,ADM1,DISTRICT
SM276399,114.6167,-3.0833,Barito Kuala,ADM2,DISTRICT
SM276404,114.7333,-1.8667,Barito Selatan,ADM2,DISTRICT
SM276410,115.1,-1.9333,Barito Timur,ADM2,DISTRICT
SM276411,115.1,-0.9833,Barito Utara,ADM2,DISTRICT
SM252078,47.96,26,Bariyah,ETHNICITY,ETHNICITY
SM18598,37.5,15.8333,Barka Awraja,ADM2,DISTRICT
SM13431,26.5928,56.732,Barkavas pagasts,ADM2,DISTRICT
SM375397,69.6994,29.9848,Barkhan,ADM2:ADM3,DISTRICT
SM285179,8.9344,9.5497,Barkin Ladi,ADM2,DISTRICT
SM12127,-76.2892,17.8928,Barking Lodge,ADM2,DISTRICT
SM252079,146.47,-32.14,Barkinji,ETHNICITY,ETHNICITY
SM252079,146.47,-32.14,Barkinji,ETHNICITY,ETHNICITY
SM252079,146.47,-32.14,Barkinji,ETHNICITY,ETHNICITY
SM252079,142.25,-32.4,Barkinji,ETHNICITY,ETHNICITY
SM252079,142.25,-32.4,Barkinji,ETHNICITY,ETHNICITY
SM252079,142.25,-32.4,Barkinji,ETHNICITY,ETHNICITY
SM252079,142.778,-31.6332,Barkinji,ETHNICITY,ETHNICITY
SM252079,142.778,-31.6332,Barkinji,ETHNICITY,ETHNICITY
SM252079,142.778,-31.6332,Barkinji,ETHNICITY,ETHNICITY
SM261601,134.8236,-19.5415,Barkly,ADM2,DISTRICT
SM283994,-12.5,16.6333,Barkéol,ADM2,DISTRICT
SM283994,-12.5,16.6333,Barkéol,ADM2,DISTRICT
SM283994,-12.4021,16.7152,Barkéol,ADM2,DISTRICT
SM283994,-12.4021,16.7152,Barkéol,ADM2,DISTRICT
SM291281,24.7795,44.4264,Barla,ADM2,DISTRICT
SM292330,27.6707,46.2258,Barlad,ADM2,DISTRICT
SM277923,16.176,41.251,Barletta-Andria-Trani,ADM2,DISTRICT
SM277191,71.5,25.75,Barmer,ADM2,DISTRICT
SM291988,22.0653,45.7164,Barna,ADM2,DISTRICT
SM277152,75.5,30.38,Barnala,ADM2,DISTRICT
SM17194,-60.9892,14.0142,Barnard Hill,ADM2,DISTRICT
SM293000,83.6444,53.3309,Barnaul gorsovet,ADM2,DISTRICT
SM302041,-98.0716,46.9361,Barnes,ADM2,DISTRICT
SM285802,5.6399,52.1688,Barneveld,ADM2,DISTRICT
SM12128,-77.9696,18.2767,Barneyside,ADM2,DISTRICT
SM290849,27.6264,47.0867,Barnova,ADM2,DISTRICT
SM274584,-1.5,53.5333,Barnsley,ADM1:ADM2,DISTRICT
SM301237,-70.2741,41.6842,Barnstable,ADM2,DISTRICT
SM302474,-81.435,33.2661,Barnwell,ADM2,DISTRICT
SM323825,-112.2636,49.7594,Barnwell,ADM2:ADM3,DISTRICT
SM226665,152.193,-3.4767,Barok,LANGUAGE,LANGUAGE
SM252086,152.03,-3.66,Barok,ETHNICITY,ETHNICITY
SM268536,25.4247,-25.5903,Barolong,ADM2,DISTRICT
SM226664,9.2526,4.6458,Barombi,LANGUAGE,LANGUAGE
SM323836,-113.0826,49.9982,Barons,ADM2:ADM3,DISTRICT
SM17195,-61.0594,13.8514,Barons Drive/Coin De L'Anse,ADM2,DISTRICT
SM261727,138.9617,-34.6135,Barossa,ADM2,DISTRICT
SM283372,-6.5717,13.0749,Barouéli,ADM2,DISTRICT
SM277387,91.0308,26.471,Barpeta (2004-),ADM2,DISTRICT
SM267675,-43.4688,-11.3183,Barra,ADM2,DISTRICT
SM266988,-48.547,-22.4751,Barra Bonita,ADM2,DISTRICT
SM266357,-53.4139,-26.6854,Barra Bonita,ADM2,DISTRICT
SM265884,-53.0329,-27.9156,Barra Funda,ADM2,DISTRICT
SM263791,-43.0717,-20.2786,Barra Longa,ADM2,DISTRICT
SM265323,-44.1955,-22.4899,Barra Mansa,ADM2,DISTRICT
SM266358,-48.7378,-26.65,Barra Velha,ADM2,DISTRICT
SM265115,-42.115,-6.5409,Barra d'Alcântara,ADM2,DISTRICT
SM267631,-40.6673,-15.0102,Barra da Choça,ADM2,DISTRICT
SM267642,-41.1346,-13.6462,Barra da Estiva,ADM2,DISTRICT
SM264900,-35.6205,-8.3945,Barra de Guabira,ADM2,DISTRICT
SM264356,-36.0018,-6.7904,Barra de Santa Rosa,ADM2,DISTRICT
SM264359,-35.9882,-7.5905,Barra de Santana,ADM2,DISTRICT
SM265383,-35.5654,-9.3975,Barra de Santo Antônio,ADM2,DISTRICT
SM267967,-40.8059,-18.6318,Barra de São Francisco,ADM2,DISTRICT
SM264360,-36.2548,-7.7164,Barra de São Miguel,ADM2,DISTRICT
SM265394,-35.9609,-9.8123,Barra de São Miguel,ADM2,DISTRICT
SM266999,-49.0862,-24.4405,Barra do Chapéu,ADM2,DISTRICT
SM262940,-45.2885,-5.5583,Barra do Corda,ADM2,DISTRICT
SM263132,-52.4837,-15.3477,Barra do Garças,ADM2,DISTRICT
SM265840,-53.7646,-27.2174,Barra do Guarita,ADM2,DISTRICT
SM264699,-50.1572,-23.1107,Barra do Jacaré,ADM2,DISTRICT
SM267653,-42.0354,-12.1609,Barra do Mendes,ADM2,DISTRICT
SM267203,-47.5878,-7.7585,Barra do Ouro,ADM2,DISTRICT
SM265312,-43.8975,-22.4096,Barra do Piraí,ADM2,DISTRICT
SM265851,-57.3101,-30.1745,Barra do Quaraí,ADM2,DISTRICT
SM265862,-51.3613,-30.3444,Barra do Ribeiro,ADM2,DISTRICT
SM265873,-52.4089,-27.3985,Barra do Rio Azul,ADM2,DISTRICT
SM267664,-39.5907,-14.0751,Barra do Rocha,ADM2,DISTRICT
SM267010,-48.4079,-24.8905,Barra do Turvo,ADM2,DISTRICT
SM263133,-57.526,-15.0858,Barra dos Bugre,ADM2,DISTRICT
SM267131,-36.9609,-10.8277,Barra dos Coqueiros,ADM2,DISTRICT
SM265895,-51.4423,-27.7373,Barracão,ADM2,DISTRICT
SM264710,-53.5259,-26.2401,Barracão,ADM2,DISTRICT
SM226673,145.93,-30.1,Barranbinya,LANGUAGE,LANGUAGE
SM286769,-77.6571,-10.666,Barranca,ADM2,DISTRICT
SM270282,-72.9937,4.4745,Barranca de Upía,ADM2,DISTRICT
SM270496,-73.7712,7.0545,Barrancabermeja,ADM2,DISTRICT
SM10383,-72.6911,10.9718,Barrancas (1994-),ADM2,DISTRICT
SM15459,-66.3159,18.2186,Barrancas Barrio,ADM2,DISTRICT
SM10384,-69.583,3.099,Barranco Minas (1999-),ADM2,DISTRICT
SM270711,-74.1828,8.8829,Barranco de Loba (1994-),ADM2,DISTRICT
SM289427,-7.0712,38.1546,Barrancos,ADM2,DISTRICT
SM10385,-74.7918,10.9584,Barranquilla,ADM2,DISTRICT
SM605,-66.3121,18.2036,Barranquitas,ADM1,DISTRICT
SM15460,-66.3102,18.1848,Barranquitas Barrio-Pueblo,ADM2,DISTRICT
SM265126,-42.3136,-4.1322,Barras,ADM2,DISTRICT
SM15461,-65.9361,18.3121,Barrazas Barrio,ADM2,DISTRICT
SM17196,-60.9602,13.9236,Barre De L'Isle,ADM2,DISTRICT
SM17197,-60.9907,13.9681,Barre Denis,ADM2,DISTRICT
SM17198,-60.9968,13.9619,Barre Duchaussee,ADM2,DISTRICT
SM17199,-61.0183,13.9725,Barre St.Joseph,ADM2,DISTRICT
SM15462,-66.7531,18.1051,Barreal Barrio,ADM2,DISTRICT
SM267881,-38.6102,-4.3705,Barreira,ADM2,DISTRICT
SM9886,-45.4924,-11.9333,Barreiras (2000-),ADM2:ADM3,DISTRICT
SM265138,-45.6933,-9.9904,Barreiras do Piauí,ADM2,DISTRICT
SM267363,-57.1662,-3.0656,Barreirinha,ADM2,DISTRICT
SM262941,-42.8343,-2.7514,Barreirinhas,ADM2,DISTRICT
SM289346,-9.0517,38.6376,Barreiro,ADM2,DISTRICT
SM264911,-35.2393,-8.8101,Barreiros,ADM2,DISTRICT
SM301053,-85.9337,36.9656,Barren,ADM2,DISTRICT
SM252089,-83.39,54.61,Barren Ground Cree,ETHNICITY,ETHNICITY
SM15463,-66.7717,18.071,Barrero Barrio (Guayanilla),ADM2,DISTRICT
SM15464,-67.2311,18.304,Barrero Barrio (Rincon),ADM2,DISTRICT
SM267021,-48.6775,-20.5284,Barretos,ADM2,DISTRICT
SM12129,-77.7963,18.5087,Barrett Town,ADM2,DISTRICT
SM324104,-114.4045,54.1258,Barrhead,ADM2:ADM3,DISTRICT
SM324105,-114.5277,54.1524,Barrhead County No. 11,ADM2:ADM3,DISTRICT
SM606,144.7988,13.4691,Barrigada,ADM1,DISTRICT
SM267032,-48.0839,-21.224,Barrinha,ADM2,DISTRICT
SM267882,-38.7337,-7.1354,Barro,ADM2,DISTRICT
SM268212,-48.8774,-14.9128,Barro Alto,ADM2,DISTRICT
SM267698,-41.9132,-11.7739,Barro Alto,ADM2,DISTRICT
SM265149,-42.4588,-5.8506,Barro Duro,ADM2,DISTRICT
SM226672,-72.044,6.1262,Barro Negro Tunebo,LANGUAGE,LANGUAGE
SM267709,-39.4071,-14.758,Barro Preto,ADM2,DISTRICT
SM280930,-7.9667,5,Barrobo,ADM2,DISTRICT
SM280930,-7.9667,5,Barrobo,ADM2,DISTRICT
SM280930,-7.6333,4.6167,Barrobo,ADM2,DISTRICT
SM280930,-7.6333,4.6167,Barrobo,ADM2,DISTRICT
SM322142,-39.1227,-11.6019,Barrocas,ADM2:ADM3,DISTRICT
SM267204,-48.8344,-9.85,Barrolândia,ADM2,DISTRICT
SM303243,-91.8483,45.4237,Barron,ADM2,DISTRICT
SM267883,-41.1546,-2.9926,Barroquinha,ADM2,DISTRICT
SM15465,-66.4124,18.2474,Barros Barrio,ADM2,DISTRICT
SM18327,-56.0022,-34.7596,Barros Blancos,ADM2,DISTRICT
SM265906,-52.5751,-29.1353,Barros Cassal,ADM2,DISTRICT
SM263802,-43.9571,-21.1789,Barroso,ADM2,DISTRICT
SM300506,-83.7128,33.9932,Barrow,ADM2,DISTRICT
SM226669,144.531,-14.1219,Barrow Point,LANGUAGE,LANGUAGE
SM459419,144.5944,-14.476,Barrow Point,ETHNICITY,ETHNICITY
SM276581,119.6833,-4.4333,Barru,ADM2,DISTRICT
SM301567,-93.8291,36.7099,Barry,ADM2,DISTRICT
SM301328,-85.309,42.595,Barry,ADM2,DISTRICT
SM299768,27.672,49.0727,Bars'kyi,ADM2,DISTRICT
SM290528,22.0446,46.3841,Barsa,ADM2,DISTRICT
SM292140,26.6819,46.3329,Barsanesti,ADM2,DISTRICT
SM291691,23.2426,47.5938,Barsau De Sus,ADM2,DISTRICT
SM305067,44.5188,16.9197,Bart Al Anan,ADM2,DISTRICT
SM226674,72.1184,38.2197,Bartangi,DIALECT,LANGUAGE
SM252090,74.37,38.5,Bartangs,ETHNICITY,ETHNICITY
SM13432,21.3285,56.3777,Bartas pagasts,ADM2,DISTRICT
SM300713,-85.8976,39.206,Bartholomew,ADM2,DISTRICT
SM15466,-66.8492,18.1999,Bartolo Barrio,ADM2,DISTRICT
SM271101,-76.9458,20.1661,Bartolomé Masó,ADM2,DISTRICT
SM301578,-94.3471,37.5023,Barton,ADM2,DISTRICT
SM300933,-98.7565,38.479,Barton,ADM2,DISTRICT
SM12130,-77.1332,18.0289,Bartons,ADM2,DISTRICT
SM288766,20.7408,54.2125,Bartoszyce,ADM2,DISTRICT
SM300517,-84.8405,34.2379,Bartow,ADM2,DISTRICT
SM607,32.5,41.5833,Bartın,ADM1,DISTRICT
SM290802,23.1513,45.4791,Baru,ADM2,DISTRICT
SM283915,33.1727,-17.8105,Barue,ADM2,DISTRICT
SM267038,-46.8699,-23.5017,Barueri,ADM2,DISTRICT
SM226681,148.761,-9.4013,Baruga,LANGUAGE,LANGUAGE
SM252094,147.46,-29.86,Barumbinnya,ETHNICITY,ETHNICITY
SM252094,147.46,-29.86,Barumbinnya,ETHNICITY,ETHNICITY
SM252094,146.2687,-29.7802,Barumbinnya,ETHNICITY,ETHNICITY
SM252094,146.2687,-29.7802,Barumbinnya,ETHNICITY,ETHNICITY
SM252095,147.4,-31.94,Barunga,ETHNICITY,ETHNICITY
SM252095,147.4,-31.94,Barunga,ETHNICITY,ETHNICITY
SM252095,143.5271,-32.8179,Barunga,ETHNICITY,ETHNICITY
SM252095,143.5271,-32.8179,Barunga,ETHNICITY,ETHNICITY
SM261738,137.9231,-33.7948,Barunga West,ADM2,DISTRICT
SM458160,150.4468,-26.7951,Barunggam,ETHNICITY,ETHNICITY
SM303649,-66.8754,10.4335,Baruta,ADM2,DISTRICT
SM284981,3.3161,9.2604,Baruten,ADM2,DISTRICT
SM250946,32.28,1.24,Baruuli,ETHNICITY,ETHNICITY
SM283648,101.4201,45.1778,Baruun Bayan-Ulaan,ADM2,DISTRICT
SM283648,101.4201,45.1778,Baruun Bayan-Ulaan,ADM2,DISTRICT
SM283648,101.4182,45.1645,Baruun Bayan-Ulaan,ADM2,DISTRICT
SM283648,101.4182,45.1645,Baruun Bayan-Ulaan,ADM2,DISTRICT
SM14998,113.2787,46.6782,Baruun-Urt,ADM2,DISTRICT
SM283664,104.7442,49.2595,Baruunbüren,ADM2,DISTRICT
SM283725,94.4036,49.6524,Baruuntutuun,ADM2,DISTRICT
SM226679,145.818,-6.8926,Baruya,LANGUAGE,LANGUAGE
SM252096,146.17,-7.14,Baruya,ETHNICITY,ETHNICITY
SM270932,-84.1085,10.0669,Barva,ADM2,DISTRICT
SM277015,74.918,22.0249,Barwani,ADM2,DISTRICT
SM226685,33.4534,-17.675,Barwe,LANGUAGE,LANGUAGE
SM293078,34.5833,54.3333,Baryatinskiy rayon,ADM2,DISTRICT
SM9756,28.4914,54.2256,Barysaw City,ADM2,DISTRICT
SM9757,28.5833,54.3333,Barysawski Rayon (excl. Barysaw),ADM2,DISTRICT
SM299478,31.349,50.3692,Baryshivs'kyi,ADM2,DISTRICT
SM291445,24.1599,44.3269,Barza,ADM2,DISTRICT
SM226688,35.1536,31.7696,Barzani Jewish Neo-Aramaic,LANGUAGE,LANGUAGE
SM290539,22.061,46.0991,Barzava,ADM2,DISTRICT
SM266954,-49.5666,-23.571,Barão de Antonina,ADM2,DISTRICT
SM263757,-43.4948,-19.8798,Barão de Cocais,ADM2,DISTRICT
SM262939,-43.2032,-6.6298,Barão de Grajaú,ADM2,DISTRICT
SM263131,-56.2161,-16.8451,Barão de Melgaço,ADM2,DISTRICT
SM263768,-42.2789,-21.2654,Barão de Monte Alto,ADM2,DISTRICT
SM318953,-51.7949,-30.3926,Barão do Triunfo,ADM2:ADM3,DISTRICT
SM226636,-66.828,1.9165,Baré,LANGUAGE,LANGUAGE
SM226654,-72.9353,9.085,Barí,LANGUAGE,LANGUAGE
SM286682,-82.9209,8.2999,Barú,ADM2,DISTRICT
SM11117,13.05,-5.85,Bas Fleuve District,ADM2,DISTRICT
SM11117,13.05,-5.85,Bas Fleuve District,ADM2,DISTRICT
SM11117,12.9842,-5.443,Bas Fleuve District,ADM2,DISTRICT
SM11117,12.9842,-5.443,Bas Fleuve District,ADM2,DISTRICT
SM274492,7.5635,48.6569,Bas-Rhin,ADM2,DISTRICT
SM10206,-68.0227,48.0503,Bas-Saint-Laurent,ADM2,DISTRICT
SM608,-6.6609,5.4993,Bas-Sassandra,ADM1,DISTRICT
SM609,24.7333,2.8,Bas-Uélé,ADM1:ADM2,DISTRICT
SM226698,10.4213,3.8077,Basa (Cameroon),LANGUAGE,LANGUAGE
SM250853,10.86,3.91,Basa (Cameroon),ETHNICITY,ETHNICITY
SM226696,7.0618,8.0316,Basa (Nigeria),LANGUAGE,LANGUAGE
SM226694,6.5811,9.9421,Basa-Gumna,LANGUAGE,LANGUAGE
SM226695,6.7487,10.2488,Basa-Gurmana,LANGUAGE,LANGUAGE
SM298528,28.802,41.092,Basaksehir,ADM2,DISTRICT
SM250604,5.5,9,Basange,ETHNICITY,ETHNICITY
SM226700,117.942,1.4141,Basap,LANGUAGE,LANGUAGE
SM461489,117.1572,1.6553,Basap,ETHNICITY,ETHNICITY
SM610,28.9167,46.4167,Basarabeasca,ADM1,DISTRICT
SM226701,121.857,25.0046,Basay,LANGUAGE,LANGUAGE
SM291292,24.7853,44.893,Bascov,ADM2,DISTRICT
SM280485,104.5358,11.1843,Basedth,ADM2,DISTRICT
SM269027,7.602,47.5666,Basel,ADM2,DISTRICT
SM611,7.7044,47.5044,Basel-Landschaft,ADM1,DISTRICT
SM612,7.6152,47.5669,Basel-Stadt,ADM1,DISTRICT
SM291053,23.1079,47.4961,Basesti,ADM2,DISTRICT
SM323765,-112.9688,52.5864,Bashaw,ADM2:ADM3,DISTRICT
SM250677,19.74,-3.95,Bashilange,ETHNICITY,ETHNICITY
SM250677,19.74,-3.95,Bashilange,ETHNICITY,ETHNICITY
SM250677,29,-2,Bashilange,ETHNICITY,ETHNICITY
SM250677,29,-2,Bashilange,ETHNICITY,ETHNICITY
SM226710,53,32,Bashkardi,LANGUAGE,LANGUAGE
SM9626,19.8837,40.9314,Bashkia Belsh,ADM2,DISTRICT
SM9627,19.9498,40.7,Bashkia Berat,ADM2,DISTRICT
SM9628,19.9976,40.9755,Bashkia Cerrik,ADM2,DISTRICT
SM9629,19.5236,40.9572,Bashkia Divjake,ADM2,DISTRICT
SM9630,20.3053,39.9858,Bashkia Dropull,ADM2,DISTRICT
SM9631,19.4869,41.4274,Bashkia Durres,ADM2,DISTRICT
SM9632,20.0968,41.1041,Bashkia Elbasan,ADM2,DISTRICT
SM9633,19.4869,40.7294,Bashkia Fier,ADM2,DISTRICT
SM9634,20.1666,39.8439,Bashkia Finiq,ADM2,DISTRICT
SM9635,20.0417,42.1475,Bashkia Fushe Arres,ADM2,DISTRICT
SM9636,20.0729,40.1278,Bashkia Gjirokaster,ADM2,DISTRICT
SM9637,19.8139,40.1158,Bashkia Himare,ADM2,DISTRICT
SM9638,19.7854,41.3723,Bashkia Kamez,ADM2,DISTRICT
SM9639,19.5558,41.1813,Bashkia Kavaje,ADM2,DISTRICT
SM9640,20.1648,40.362,Bashkia Kelcyre,ADM2,DISTRICT
SM9641,20.0711,41.5083,Bashkia Klos,ADM2,DISTRICT
SM9642,20.1335,39.7006,Bashkia Konispol,ADM2,DISTRICT
SM9643,20.6295,40.5751,Bashkia Korce,ADM2,DISTRICT
SM9644,20.2575,40.1075,Bashkia Libohove,ADM2,DISTRICT
SM9645,20.354,41.229,Bashkia Librazhd,ADM2,DISTRICT
SM9646,20.6075,40.7551,Bashkia Maliq,ADM2,DISTRICT
SM9647,20.0123,41.6332,Bashkia Mat,ADM2,DISTRICT
SM9648,19.9691,40.4152,Bashkia Memaliaj,ADM2,DISTRICT
SM9649,19.6559,40.6779,Bashkia Patos,ADM2,DISTRICT
SM9650,20.4109,40.2325,Bashkia Permet,ADM2,DISTRICT
SM9651,20.0454,40.5861,Bashkia Polican,ADM2,DISTRICT
SM9652,20.4421,41.0564,Bashkia Prrenjas,ADM2,DISTRICT
SM9653,19.9278,42.03,Bashkia Puke,ADM2,DISTRICT
SM9654,20.8941,40.8359,Bashkia Pustec,ADM2,DISTRICT
SM9655,19.722,40.722,Bashkia Roskovec,ADM2,DISTRICT
SM9656,19.5861,41.0903,Bashkia Rrogozhine,ADM2,DISTRICT
SM9657,20.0205,39.8558,Bashkia Sarande,ADM2,DISTRICT
SM9658,19.6596,40.395,Bashkia Selenice,ADM2,DISTRICT
SM9659,19.5898,41.3356,Bashkia Shijak,ADM2,DISTRICT
SM9660,19.6339,42.1439,Bashkia Shkoder,ADM2,DISTRICT
SM9661,19.97,40.2674,Bashkia Tepelene,ADM2,DISTRICT
SM9662,19.8938,41.2988,Bashkia Tirane,ADM2,DISTRICT
SM9663,19.8102,40.7661,Bashkia Ura Vajgurore,ADM2,DISTRICT
SM9664,19.7,42.0483,Bashkia Vau i Dejes,ADM2,DISTRICT
SM9665,19.4979,40.4134,Bashkia Vlore,ADM2,DISTRICT
SM9666,19.677,41.398,Bashkia Vore,ADM2,DISTRICT
SM226711,56.5594,53.5967,Bashkir,LANGUAGE,LANGUAGE
SM252103,56.77,51.39,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.77,51.39,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.77,51.39,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.77,51.39,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.77,51.39,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.77,51.39,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.77,51.39,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.77,51.39,Bashkir,ETHNICITY,ETHNICITY
SM252103,49.96,52.51,Bashkir,ETHNICITY,ETHNICITY
SM252103,49.96,52.51,Bashkir,ETHNICITY,ETHNICITY
SM252103,49.96,52.51,Bashkir,ETHNICITY,ETHNICITY
SM252103,49.96,52.51,Bashkir,ETHNICITY,ETHNICITY
SM252103,49.96,52.51,Bashkir,ETHNICITY,ETHNICITY
SM252103,49.96,52.51,Bashkir,ETHNICITY,ETHNICITY
SM252103,49.96,52.51,Bashkir,ETHNICITY,ETHNICITY
SM252103,49.96,52.51,Bashkir,ETHNICITY,ETHNICITY
SM252103,56,54,Bashkir,ETHNICITY,ETHNICITY
SM252103,56,54,Bashkir,ETHNICITY,ETHNICITY
SM252103,56,54,Bashkir,ETHNICITY,ETHNICITY
SM252103,56,54,Bashkir,ETHNICITY,ETHNICITY
SM252103,56,54,Bashkir,ETHNICITY,ETHNICITY
SM252103,56,54,Bashkir,ETHNICITY,ETHNICITY
SM252103,56,54,Bashkir,ETHNICITY,ETHNICITY
SM252103,56,54,Bashkir,ETHNICITY,ETHNICITY
SM252103,55.03,54.9,Bashkir,ETHNICITY,ETHNICITY
SM252103,55.03,54.9,Bashkir,ETHNICITY,ETHNICITY
SM252103,55.03,54.9,Bashkir,ETHNICITY,ETHNICITY
SM252103,55.03,54.9,Bashkir,ETHNICITY,ETHNICITY
SM252103,55.03,54.9,Bashkir,ETHNICITY,ETHNICITY
SM252103,55.03,54.9,Bashkir,ETHNICITY,ETHNICITY
SM252103,55.03,54.9,Bashkir,ETHNICITY,ETHNICITY
SM252103,55.03,54.9,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.32,55.43,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.32,55.43,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.32,55.43,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.32,55.43,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.32,55.43,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.32,55.43,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.32,55.43,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.32,55.43,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.08,56.27,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.08,56.27,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.08,56.27,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.08,56.27,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.08,56.27,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.08,56.27,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.08,56.27,Bashkir,ETHNICITY,ETHNICITY
SM252103,54.08,56.27,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.55,52.67,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.55,52.67,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.55,52.67,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.55,52.67,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.55,52.67,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.55,52.67,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.55,52.67,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.55,52.67,Bashkir,ETHNICITY,ETHNICITY
SM252103,58.32,52.58,Bashkir,ETHNICITY,ETHNICITY
SM252103,58.32,52.58,Bashkir,ETHNICITY,ETHNICITY
SM252103,58.32,52.58,Bashkir,ETHNICITY,ETHNICITY
SM252103,58.32,52.58,Bashkir,ETHNICITY,ETHNICITY
SM252103,58.32,52.58,Bashkir,ETHNICITY,ETHNICITY
SM252103,58.32,52.58,Bashkir,ETHNICITY,ETHNICITY
SM252103,58.32,52.58,Bashkir,ETHNICITY,ETHNICITY
SM252103,58.32,52.58,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.93,54.52,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.93,54.52,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.93,54.52,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.93,54.52,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.93,54.52,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.93,54.52,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.93,54.52,Bashkir,ETHNICITY,ETHNICITY
SM252103,56.93,54.52,Bashkir,ETHNICITY,ETHNICITY
SM613,56,54,Bashkortostan,ADM1,DISTRICT
SM299596,32.4339,47.4058,Bashtans'kyi,ADM2,DISTRICT
SM614,122.1185,6.5473,Basilan,ADM1:ADM2,DISTRICT
SM615,16.114,40.5041,Basilicata,ADM1,DISTRICT
SM252105,26.1,5.28,Basiri,ETHNICITY,ETHNICITY
SM18027,29.9002,40.6457,Basiskele,ADM2,DISTRICT
SM226718,36.5763,6.4577,Basketo,LANGUAGE,LANGUAGE
SM274323,36.5833,6.25,Basketo,ADM2,DISTRICT
SM252108,35.91,5.85,Basketo,ETHNICITY,ETHNICITY
SM252108,35.91,5.85,Basketo,ETHNICITY,ETHNICITY
SM252108,37,6,Basketo,ETHNICITY,ETHNICITY
SM252108,37,6,Basketo,ETHNICITY,ETHNICITY
SM298355,38.8236,38.567,Baskil,ADM2,DISTRICT
SM250687,33.46,0.62,Basoga,ETHNICITY,ETHNICITY
SM250687,33.46,0.62,Basoga,ETHNICITY,ETHNICITY
SM250687,33,1,Basoga,ETHNICITY,ETHNICITY
SM250687,33,1,Basoga,ETHNICITY,ETHNICITY
SM250697,26.84,-1.1,Basongora,ETHNICITY,ETHNICITY
SM250697,26.84,-1.1,Basongora,ETHNICITY,ETHNICITY
SM250697,26,-4,Basongora,ETHNICITY,ETHNICITY
SM250697,26,-4,Basongora,ETHNICITY,ETHNICITY
SM226723,-1.3162,43.2787,Basque,LANGUAGE,LANGUAGE
SM258821,-2,43,Basque,ETHNICITY,ETHNICITY
SM252111,-1,43,Basque-French,ETHNICITY,ETHNICITY
SM252111,-1,43,Basque-French,ETHNICITY,ETHNICITY
SM252111,-1,43,Basque-French,ETHNICITY,ETHNICITY
SM252111,0,43,Basque-French,ETHNICITY,ETHNICITY
SM252111,0,43,Basque-French,ETHNICITY,ETHNICITY
SM252111,0,43,Basque-French,ETHNICITY,ETHNICITY
SM252111,0,43,Basque-French,ETHNICITY,ETHNICITY
SM252111,0,43,Basque-French,ETHNICITY,ETHNICITY
SM252111,0,43,Basque-French,ETHNICITY,ETHNICITY
SM252112,-2.33,42.94,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.33,42.94,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.33,42.94,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.33,42.94,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.33,42.94,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2,43,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2,43,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2,43,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2,43,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2,43,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-1.67,43.25,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-1.67,43.25,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-1.67,43.25,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-1.67,43.25,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-1.67,43.25,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.7,42.8,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.7,42.8,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.7,42.8,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.7,42.8,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.7,42.8,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.08,43.13,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.08,43.13,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.08,43.13,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.08,43.13,Basque-Spanish,ETHNICITY,ETHNICITY
SM252112,-2.08,43.13,Basque-Spanish,ETHNICITY,ETHNICITY
SM12035,47.7833,30.5,Basrah District,ADM2,DISTRICT
SM261260,145.4639,-38.4851,Bass Coast,ADM2,DISTRICT
SM240832,-11.543,7.275,Bassa,LANGUAGE,LANGUAGE
SM285188,8.7848,10.082,Bassa,ADM2,DISTRICT
SM284967,7.0244,7.771,Bassa,ADM2,DISTRICT
SM250598,-9.55,5.92,Bassa (Liberia),ETHNICITY,ETHNICITY
SM250081,7,8,Bassa (Nigeria),ETHNICITY,ETHNICITY
SM250081,7,8,Bassa (Nigeria),ETHNICITY,ETHNICITY
SM250081,8,8,Bassa (Nigeria),ETHNICITY,ETHNICITY
SM250081,8,8,Bassa (Nigeria),ETHNICITY,ETHNICITY
SM226731,4.9833,10.3333,Bassa-Kontagora,LANGUAGE,LANGUAGE
SM323841,-112.4687,50.7885,Bassano,ADM2:ADM3,DISTRICT
SM250082,-13.25,14.12,Bassari,ETHNICITY,ETHNICITY
SM250082,-13.25,14.12,Bassari,ETHNICITY,ETHNICITY
SM250082,-13,12,Bassari,ETHNICITY,ETHNICITY
SM250082,-13,12,Bassari,ETHNICITY,ETHNICITY
SM226730,-13.3495,12.4529,Bassari-Tanda,LANGUAGE,LANGUAGE
SM11279,39.6744,-21.4754,Bassas da India,ADM2,DISTRICT
SM274565,10.679,-3.17,Basse Banio,ADM2,DISTRICT
SM616,21.3667,5,Basse-Kotto,ADM1,DISTRICT
SM283459,94.7149,16.8795,Bassein,ADM2,DISTRICT
SM261381,115.945,-31.9058,Bassendean,ADM2,DISTRICT
SM252113,52,30,Basseri,ETHNICITY,ETHNICITY
SM252113,52,30,Basseri,ETHNICITY,ETHNICITY
SM252113,52,30,Basseri,ETHNICITY,ETHNICITY
SM252113,34,30,Basseri,ETHNICITY,ETHNICITY
SM252113,34,30,Basseri,ETHNICITY,ETHNICITY
SM252113,34,30,Basseri,ETHNICITY,ETHNICITY
SM252113,53,29,Basseri,ETHNICITY,ETHNICITY
SM252113,53,29,Basseri,ETHNICITY,ETHNICITY
SM252113,53,29,Basseri,ETHNICITY,ETHNICITY
SM284012,-5.9358,15.85,Bassikounou,ADM2,DISTRICT
SM262163,1.6667,9.0167,Bassila,ADM2,DISTRICT
SM226732,9.5228,5.2366,Bassossi,LANGUAGE,LANGUAGE
SM11755,54.3667,27.2,Bastak,ADM2,DISTRICT
SM277463,81.7383,19.2679,Bastar  (2012-),ADM2,DISTRICT
SM277275,82.7792,26.8282,Basti (1997-),ADM2,DISTRICT
SM267039,-50.7493,-21.9523,Bastos,ADM2,DISTRICT
SM302651,-97.312,30.1036,Bastrop,ADM2,DISTRICT
SM226742,93.8815,29.9868,Basum,LANGUAGE,LANGUAGE
SM250723,14,-5,Basundi,ETHNICITY,ETHNICITY
SM273921,30.8302,30.9544,Basyun,ADM2,DISTRICT
SM280409,103.0954,13.2777,Bat Dambang,ADM2,DISTRICT
SM283649,102.2494,46.8132,Bat-Ölzii,ADM2,DISTRICT
SM283649,102.2494,46.8132,Bat-Ölzii,ADM2,DISTRICT
SM283649,102.2406,46.8194,Bat-Ölzii,ADM2,DISTRICT
SM283649,102.2406,46.8194,Bat-Ölzii,ADM2,DISTRICT
SM226761,12.677,9.6809,Bata,LANGUAGE,LANGUAGE
SM290550,22.0781,45.9964,Bata,ADM2,DISTRICT
SM275131,9.9167,1.9167,Bata,ADM2,DISTRICT
SM250084,13,10,Bata,ETHNICITY,ETHNICITY
SM618,120.4167,14.6667,Bataan,ADM1:ADM2,DISTRICT
SM226756,120.49,14.4153,Bataan Ayta,LANGUAGE,LANGUAGE
SM271013,-82.2833,22.7167,Batabanó,ADM2,DISTRICT
SM226757,121.23,16.8266,Batad Ifugao,LANGUAGE,LANGUAGE
SM284908,7.6065,12.8938,Batagarawa,ADM2,DISTRICT
SM263024,-52.5654,-21.7855,Bataguassu,ADM2,DISTRICT
SM250707,32.28,14.41,Batahin,ETHNICITY,ETHNICITY
SM263025,-53.1768,-22.465,Bataiporã,ADM2,DISTRICT
SM226760,119.025,10.1115,Batak,LANGUAGE,LANGUAGE
SM262313,24.2167,41.8667,Batak,ADM2,DISTRICT
SM226751,97.8123,3.4293,Batak Alas-Kluet,LANGUAGE,LANGUAGE
SM226749,99.6025,1.6613,Batak Angkola,LANGUAGE,LANGUAGE
SM226753,98.2427,2.5144,Batak Dairi,LANGUAGE,LANGUAGE
SM226752,98.2517,2.1279,Batak Karo,LANGUAGE,LANGUAGE
SM226750,99.898,0.8581,Batak Mandailing,LANGUAGE,LANGUAGE
SM226747,98.9083,3.0437,Batak Simalungun,LANGUAGE,LANGUAGE
SM226748,99.1761,2.355,Batak Toba,LANGUAGE,LANGUAGE
SM289252,-8.7811,39.6478,Batalha,ADM2,DISTRICT
SM265160,-42.1065,-3.9974,Batalha,ADM2,DISTRICT
SM265405,-37.0888,-9.7326,Batalha,ADM2,DISTRICT
SM276432,104.0285,1.0521,Batam,ADM2,DISTRICT
SM619,121.9941,20.458,Batanes,ADM1:ADM2,DISTRICT
SM276325,109.8833,-7.0333,Batang,ADM2,DISTRICT
SM276744,103.1167,-1.75,Batang Hari,ADM2,DISTRICT
SM226744,9.5101,1.397,Batanga,LANGUAGE,LANGUAGE
SM250083,10.03,2.86,Batanga,ETHNICITY,ETHNICITY
SM250083,10.03,2.86,Batanga,ETHNICITY,ETHNICITY
SM250083,10.03,2.86,Batanga,ETHNICITY,ETHNICITY
SM250083,10,2,Batanga,ETHNICITY,ETHNICITY
SM250083,10,2,Batanga,ETHNICITY,ETHNICITY
SM250083,10,2,Batanga,ETHNICITY,ETHNICITY
SM250083,10,3,Batanga,ETHNICITY,ETHNICITY
SM250083,10,3,Batanga,ETHNICITY,ETHNICITY
SM250083,10,3,Batanga,ETHNICITY,ETHNICITY
SM268571,17.9606,7.3457,Batangafo,ADM2,DISTRICT
SM620,121.0833,13.9167,Batangas,ADM1:ADM2,DISTRICT
SM290238,25.7193,46.0917,Batanii Mari,ADM2,DISTRICT
SM226754,130.646,-0.8624,Batanta,LANGUAGE,LANGUAGE
SM292473,21.7916,46.7143,Batar,ADM2,DISTRICT
SM291702,23.1427,48.0405,Batarci,ADM2,DISTRICT
SM267040,-47.5674,-20.8352,Batatais,ADM2,DISTRICT
SM295347,156.4771,-6.716,Batava,ADM2,DISTRICT
SM621,103.0833,12.9167,Batdâmbâng (1997-),ADM1,DISTRICT
SM226768,102.382,4.8223,Batek,LANGUAGE,LANGUAGE
SM252121,102.38,5.22,Batek,ETHNICITY,ETHNICITY
SM226767,72.9267,34.9582,Bateri,LANGUAGE,LANGUAGE
SM301589,-94.34,38.2573,Bates,ADM2,DISTRICT
SM293817,30.5,58.5833,Batetskiy rayon,ADM2,DISTRICT
SM15467,-67.0699,18.2078,Bateyes Barrio,ADM2,DISTRICT
SM302937,-79.7411,38.0587,Bath,ADM2,DISTRICT
SM301064,-83.7427,38.145,Bath,ADM2,DISTRICT
SM18550,-76.3462,17.9573,Bath (St. Thomas),ADM2,DISTRICT
SM12131,-78.1175,18.2573,Bath (Westmoreland),ADM2,DISTRICT
SM12132,-78.0993,18.3443,Bath Mountain,ADM2,DISTRICT
SM274614,-2.5,51.3333,Bath and North East Somerset,ADM1:ADM2,DISTRICT
SM226776,12.3606,12.1883,Batha,DIALECT:LANGUAGE,LANGUAGE
SM10271,19.6903,13.2897,Batha,ADM2,DISTRICT
SM622,19,14,Batha,ADM1,DISTRICT
SM296819,18.3496,12.9889,Batha Oues,ADM2,DISTRICT
SM10272,20.0146,13.3972,Batha-Est,ADM2,DISTRICT
SM226775,53.119,17.4485,Bathari,LANGUAGE,LANGUAGE
SM280466,104.9317,12.0299,Batheay,ADM2,DISTRICT
SM277156,75.03,30.21,Bathinda (1995-),ADM2,DISTRICT
SM261570,149.5196,-33.4731,Bathurst,ADM2,DISTRICT
SM280451,104.8222,11.2729,Bati,ADM2,DISTRICT
SM226779,11.0591,4.2557,Bati (Cameroon),LANGUAGE,LANGUAGE
SM226781,130.815,-3.614,Bati (Indonesia),LANGUAGE,LANGUAGE
SM260126,70.7565,34.2894,Bati Kot,ADM2,DISTRICT
SM252128,127.43,-0.52,Batjan,ETHNICITY,ETHNICITY
SM280305,71,39.75,Batken,ADM2,DISTRICT
SM623,71,39.8333,Batken,ADM1,DISTRICT
SM624,41.3333,38,Batman,ADM1,DISTRICT
SM625,5.9167,35.5,Batna,ADM1,DISTRICT
SM457395,6.1667,35.25,Batna District,ADM2,DISTRICT
SM283562,111.4978,47.9414,Batnorov,ADM2,DISTRICT
SM297751,101.2274,5.8636,Batong,ADM2,DISTRICT
SM283275,-107.7184,26.9527,Batopilas,ADM2,DISTRICT
SM250764,31.27,0.62,Batoro,ETHNICITY,ETHNICITY
SM250764,31.27,0.62,Batoro,ETHNICITY,ETHNICITY
SM250764,31,1,Batoro,ETHNICITY,ETHNICITY
SM250764,31,1,Batoro,ETHNICITY,ETHNICITY
SM291121,24.6467,46.8749,Batos,ADM2,DISTRICT
SM290803,22.5886,45.809,Batrana,ADM2,DISTRICT
SM226787,45.344,42.1806,Bats,LANGUAGE,LANGUAGE
SM284919,7.2345,12.7793,Batsari,ADM2,DISTRICT
SM283571,110.1826,48.6765,Batshireet,ADM2,DISTRICT
SM283718,106.7418,48.3597,Batsümber,ADM2,DISTRICT
SM375418,73.0054,34.6805,Battagram,ADM2:ADM3,DISTRICT
SM298718,38.4576,38.4393,Battalgazi,ADM2,DISTRICT
SM626,81.4997,7.75,Batticaloa,ADM1:ADM2,DISTRICT
SM283522,101.9722,47.788,Battsengel,ADM2,DISTRICT
SM226790,10.9377,6.8159,Batu,LANGUAGE,LANGUAGE
SM276361,112.5375,-7.8327,Batu,ADM2,DISTRICT
SM276685,99.5265,3.1617,Batu Bara,ADM2,DISTRICT
SM284310,103,1.9333,Batu Pahat,ADM2,DISTRICT
SM226795,122.4872,-1.2544,Batui,LANGUAGE,LANGUAGE
SM226793,134.7843,-5.8144,Batuley,LANGUAGE,LANGUAGE
SM252133,28.02,-5.04,Batumbwe,ETHNICITY,ETHNICITY
SM11293,41.6446,41.6224,Batumi,ADM2,DISTRICT
SM267884,-38.8592,-4.3731,Baturité,ADM2,DISTRICT
SM251077,28.1,-1.19,Batwa,ETHNICITY,ETHNICITY
SM226799,153.154,-25.4449,Batyala,DIALECT,LANGUAGE
SM292813,47.5,55.0833,Batyrevskiy rayon,ADM2,DISTRICT
SM226812,145.611,-5.2646,Bau,LANGUAGE,LANGUAGE
SM276605,122.6166,-5.477,Bau-Bau,ADM2,DISTRICT
SM226800,109.985,1.5447,Bau-Jagoi Bidayuh,LANGUAGE,LANGUAGE
SM297824,126.45,-8.4667,Baucau,ADM2,DISTRICT
SM627,126.475,-8.5417,Baucau,ADM1,DISTRICT
SM226801,6.6169,10.2077,Bauchi,LANGUAGE,LANGUAGE
SM285321,9.8442,10.3158,Bauchi,ADM2,DISTRICT
SM628,10,10.5,Bauchi (1996-),ADM1,DISTRICT
SM277132,84.333,20.833,Bauda,ADM2,DISTRICT
SM248326,142.012,-3.0726,Bauni,LANGUAGE,LANGUAGE
SM295122,114.5,54.6667,Bauntovskiy,ADM2,DISTRICT
SM226807,-64.1838,-13.1278,Baure,LANGUAGE,LANGUAGE
SM226807,-64.1838,-13.1278,Baure,LANGUAGE,LANGUAGE
SM284925,8.7891,12.7889,Baure,ADM2,DISTRICT
SM252136,-64.42,-12.97,Baure,ETHNICITY,ETHNICITY
SM226807,-63.68,-13.61,Baure,LANGUAGE,LANGUAGE
SM226807,-63.68,-13.61,Baure,LANGUAGE,LANGUAGE
SM226805,75.8713,25.2977,Bauria,LANGUAGE,LANGUAGE
SM226806,161.863,-10.5826,Bauro,LANGUAGE,LANGUAGE
SM295419,161.8998,-10.5427,Bauro Central,ADM2,DISTRICT
SM295420,161.9807,-10.5605,Bauro East,ADM2,DISTRICT
SM295421,161.7545,-10.4682,Bauro West,ADM2,DISTRICT
SM267041,-49.1364,-22.2635,Bauru,ADM2,DISTRICT
SM13433,24.196,56.4053,Bauska,ADM2,DISTRICT
SM4054,24.3333,56.5,Bauskas Novads,ADM1,DISTRICT
SM271141,-82.5,22.9696,Bauta,ADM2,DISTRICT
SM15468,-66.4693,18.1953,Bauta Abajo Barrio,ADM2,DISTRICT
SM15469,-66.3878,18.182,Bauta Arriba Barrio,ADM2,DISTRICT
SM290034,22.5785,45.5302,Bautar,ADM2,DISTRICT
SM262728,-68.8342,-15.09,Bautista Saavedra,ADM2,DISTRICT
SM226813,148.815,-9.8258,Bauwaki,LANGUAGE,LANGUAGE
SM226816,137.629,-2.4472,Bauzi,LANGUAGE,LANGUAGE
SM226817,13.246,47.9232,Bavarian,LANGUAGE,LANGUAGE
SM280410,102.8291,13.236,Bavel,ADM2,DISTRICT
SM250797,12.44,-5.25,Bavili,ETHNICITY,ETHNICITY
SM282555,-108.9674,30.5718,Bavispe,ADM2,DISTRICT
SM282554,-110.1416,29.6185,Baviácora,ADM2,DISTRICT
SM294606,53.25,54.25,Bavlinskiy rayon,ADM2,DISTRICT
SM295218,34.0845,11.3837,Baw,ADM2,DISTRICT
SM261271,146.144,-37.97,Baw Baw,ADM2,DISTRICT
SM252144,149.55,-10.22,Bawaki,ETHNICITY,ETHNICITY
SM11381,-0.2333,11.05,Bawku Municipal District,ADM2,DISTRICT
SM274943,-0.5167,10.9167,Bawku West,ADM2,DISTRICT
SM283505,97.4292,18.9213,Bawlake,ADM2,DISTRICT
SM323776,-112.466,52.919,Bawlf,ADM2:ADM3,DISTRICT
SM226819,92.9255,22.4405,Bawm Chin,LANGUAGE,LANGUAGE
SM303490,67.8333,39.75,Baxmal,ADM2,DISTRICT
SM302374,-92.337,36.2872,Baxter,ADM2,DISTRICT
SM12133,-76.772,18.2041,Baxter Mountain,ADM2,DISTRICT
SM301337,-83.9418,43.7214,Bay,ADM2,DISTRICT
SM300335,-85.6326,30.2377,Bay,ADM2,DISTRICT
SM629,43.5,3,Bay,ADM1,DISTRICT
SM231021,-86.4614,16.3573,Bay Islands Sign Language,LANGUAGE,LANGUAGE
SM226834,-122.419,37.784,Bay Miwok,LANGUAGE,LANGUAGE
SM630,176.9128,-38.1648,Bay of Plenty,ADM1,DISTRICT
SM271803,-69.6,18.7833,Bayaguana,ADM2,DISTRICT
SM252150,17.76,3.58,Bayaka,LANGUAGE,LANGUAGE
SM252150,17.76,3.58,Bayaka,LANGUAGE,LANGUAGE
SM252150,17,4,Bayaka,LANGUAGE,LANGUAGE
SM252150,17,4,Bayaka,LANGUAGE,LANGUAGE
SM252151,12.37,-3.14,Bayaka (Teke),ETHNICITY,ETHNICITY
SM226823,151.699,-24.9793,Bayali,LANGUAGE,LANGUAGE
SM271106,-76.6861,20.4251,Bayamo,ADM2,DISTRICT
SM252152,-76.21,20.34,Bayamo,ETHNICITY,ETHNICITY
SM15470,-66.1504,18.1872,Bayamon Barrio,ADM2,DISTRICT
SM15471,-66.151,18.3953,Bayamon Barrio-Pueblo,ADM2,DISTRICT
SM15472,-66.1596,18.2358,Bayamoncito Barrio,ADM2,DISTRICT
SM631,-66.1704,18.3522,Bayamón,ADM1,DISTRICT
SM283719,107.5335,47.2422,Bayan,ADM2,DISTRICT
SM283719,107.5335,47.2422,Bayan,ADM2,DISTRICT
SM283719,107.6876,47.1499,Bayan,ADM2,DISTRICT
SM283719,107.6876,47.1499,Bayan,ADM2,DISTRICT
SM283572,111.0835,48.5466,Bayan-Adarga,ADM2,DISTRICT
SM283764,102.0901,49.0353,Bayan-Agt,ADM2,DISTRICT
SM283620,106.12,42.969,Bayan-Ovoo,ADM2,DISTRICT
SM283620,106.12,42.969,Bayan-Ovoo,ADM2,DISTRICT
SM283620,105.964,42.7571,Bayan-Ovoo,ADM2,DISTRICT
SM283620,105.964,42.7571,Bayan-Ovoo,ADM2,DISTRICT
SM283757,100.4487,46.2808,Bayan-Ovoo,ADM2,DISTRICT
SM283573,112.1158,47.7763,Bayan-Ovoo,ADM2,DISTRICT
SM283551,95.1996,46.9889,Bayan-Uul,ADM2,DISTRICT
SM283783,112.7345,49.1456,Bayan-Uul,ADM2,DISTRICT
SM632,90,48.5,Bayan-Ölgiy,ADM1,DISTRICT
SM283650,104.1186,46.4912,Bayan-Öndör,ADM2,DISTRICT
SM283755,98.655,44.7723,Bayan-Öndör,ADM2,DISTRICT
SM283635,104.0834,49.0239,Bayan-Öndör,ADM2,DISTRICT
SM283635,104.0834,49.0239,Bayan-Öndör,ADM2,DISTRICT
SM283635,104.1248,49.0731,Bayan-Öndör,ADM2,DISTRICT
SM283635,104.1248,49.0731,Bayan-Öndör,ADM2,DISTRICT
SM283720,106.0186,46.915,Bayan-Önjüül,ADM2,DISTRICT
SM279988,75.5,50.9167,Bayanaulskiy,ADM2,DISTRICT
SM283758,98.0948,46.8719,Bayanbulag,ADM2,DISTRICT
SM283758,98.0948,46.8719,Bayanbulag,ADM2,DISTRICT
SM283758,98.0909,46.8049,Bayanbulag,ADM2,DISTRICT
SM283758,98.0909,46.8049,Bayanbulag,ADM2,DISTRICT
SM283721,106.2914,48.2203,Bayanchandmani,ADM2,DISTRICT
SM283627,103.5135,43.4582,Bayandalai,ADM2,DISTRICT
SM292923,105.5833,53.0833,Bayandayevskiy rayon,ADM2,DISTRICT
SM283677,112.3556,45.723,Bayandelger,ADM2,DISTRICT
SM283722,108.1106,47.7162,Bayandelger,ADM2,DISTRICT
SM283789,113.3739,49.2408,Bayandun,ADM2,DISTRICT
SM14999,107.1668,47.8136,Bayandzuerh,ADM2,DISTRICT
SM15473,-66.8066,18.3522,Bayaney Barrio,ADM2,DISTRICT
SM10224,16.2261,2.7886,Bayanga,ADM2,DISTRICT
SM283651,103.259,45.9703,Bayangol,ADM2,DISTRICT
SM283665,106.2042,48.9883,Bayangol,ADM2,DISTRICT
SM283665,106.2042,48.9883,Bayangol,ADM2,DISTRICT
SM283665,106.0867,48.9117,Bayangol,ADM2,DISTRICT
SM283665,106.0867,48.9117,Bayangol,ADM2,DISTRICT
SM283759,100.3912,44.7319,Bayangovi,ADM2,DISTRICT
SM633,99.5,45.5,Bayanhongor,ADM1,DISTRICT
SM283818,107.9838,45.7565,Bayanjargalan,ADM2,DISTRICT
SM283723,108.2603,47.1742,Bayanjargalan,ADM2,DISTRICT
SM283842,96.3424,49.3036,Bayankhairkhan,ADM2,DISTRICT
SM283699,105.5534,47.9436,Bayankhangai,ADM2,DISTRICT
SM283760,100.7023,46.1914,Bayankhongor,ADM2,DISTRICT
SM283760,100.7023,46.1914,Bayankhongor,ADM2,DISTRICT
SM283760,100.7182,46.1896,Bayankhongor,ADM2,DISTRICT
SM283760,100.7182,46.1896,Bayankhongor,ADM2,DISTRICT
SM283574,110.8167,47.161,Bayankhutag,ADM2,DISTRICT
SM283761,100.8279,44.5419,Bayanlig,ADM2,DISTRICT
SM283575,109.75,46.8947,Bayanmönkh,ADM2,DISTRICT
SM283771,104.4358,47.827,Bayannuur,ADM2,DISTRICT
SM283771,104.4358,47.827,Bayannuur,ADM2,DISTRICT
SM283691,91.1333,48.9333,Bayannuur,ADM2,DISTRICT
SM283771,104.5097,47.867,Bayannuur,ADM2,DISTRICT
SM283771,104.5097,47.867,Bayannuur,ADM2,DISTRICT
SM283559,108.3091,46.6802,Bayantal,ADM2,DISTRICT
SM283559,108.3091,46.6802,Bayantal,ADM2,DISTRICT
SM283559,107.8583,46.786,Bayantal,ADM2,DISTRICT
SM283559,107.8583,46.786,Bayantal,ADM2,DISTRICT
SM283843,96.3595,49.6976,Bayantes,ADM2,DISTRICT
SM283762,98.9283,45.0114,Bayantsagaan,ADM2,DISTRICT
SM283700,107.1941,46.7484,Bayantsagaan,ADM2,DISTRICT
SM283700,107.1941,46.7484,Bayantsagaan,ADM2,DISTRICT
SM283700,107.1497,46.7954,Bayantsagaan,ADM2,DISTRICT
SM283700,107.1497,46.7954,Bayantsagaan,ADM2,DISTRICT
SM283701,106.1928,47.9299,Bayantsogt,ADM2,DISTRICT
SM283701,106.1928,47.9299,Bayantsogt,ADM2,DISTRICT
SM283701,106.0457,48.1227,Bayantsogt,ADM2,DISTRICT
SM283701,106.0457,48.1227,Bayantsogt,ADM2,DISTRICT
SM283790,114.3663,48.0448,Bayantümen,ADM2,DISTRICT
SM283790,114.3663,48.0448,Bayantümen,ADM2,DISTRICT
SM283790,114.9169,48.0083,Bayantümen,ADM2,DISTRICT
SM283790,114.9169,48.0083,Bayantümen,ADM2,DISTRICT
SM283613,98.97,50.1685,Bayanzürkh,ADM2,DISTRICT
SM283613,98.97,50.1685,Bayanzürkh,ADM2,DISTRICT
SM283613,98.9782,50.1756,Bayanzürkh,ADM2,DISTRICT
SM283613,98.9782,50.1756,Bayanzürkh,ADM2,DISTRICT
SM252153,-76.21,20.34,Bayaquitiri,ETHNICITY,ETHNICITY
SM298292,34.2643,40.6559,Bayat,ADM2,DISTRICT
SM298371,30.931,38.9918,Bayat,ADM2,DISTRICT
SM226828,124.8086,10.6956,Baybayanon,LANGUAGE,LANGUAGE
SM634,40.25,40.25,Bayburt,ADM1,DISTRICT
SM17850,43.6393,3.2664,Baydhabo (excl. Baidoa city),ADM2,DISTRICT
SM17851,43.65,3.1167,Baydhabo city,ADM2,DISTRICT
SM279878,69.5,43,Baydibekskiy,ADM2,DISTRICT
SM252155,12,2,Bayele,ETHNICITY,ETHNICITY
SM252155,12,2,Bayele,ETHNICITY,ETHNICITY
SM252155,10,2.8,Bayele,ETHNICITY,ETHNICITY
SM252155,10,2.8,Bayele,ETHNICITY,ETHNICITY
SM635,6.0833,4.75,Bayelsa,ADM1,DISTRICT
SM636,11.5,49,Bayern,ADM1,DISTRICT
SM264361,-34.9173,-7.1214,Bayeux,ADM2,DISTRICT
SM303254,-91.1807,46.6354,Bayfield,ADM2,DISTRICT
SM279934,56.5,47.25,Bayganinskiy,ADM2,DISTRICT
SM226831,25.22,11.13,Baygo,LANGUAGE,LANGUAGE
SM304975,45.7482,14.7619,Bayhan,ADM2,DISTRICT
SM11284,14.0948,-0.4635,Bayi-Brikolo,ADM2,DISTRICT
SM458658,127.9739,-15.6327,Bayimbarr,ETHNICITY,ETHNICITY
SM269351,86.15,41.7333,Bayin'gholin Mongol,ADM2,DISTRICT
SM305807,43.4932,34.9296,Bayji,ADM2,DISTRICT
SM298876,41.7841,38.1632,Baykan,ADM2,DISTRICT
SM302662,-99.2135,33.6165,Baylor,ADM2,DISTRICT
SM16851,58.25,52.6667,Baymakskiy Rayon,ADM2,DISTRICT
SM269260,107.4949,41.5696,Baynnur,ADM2,DISTRICT
SM285383,11.7062,10.4225,Bayo,ADM2,DISTRICT
SM250826,13.39,-5.05,Bayombe,ETHNICITY,ETHNICITY
SM250826,13.39,-5.05,Bayombe,ETHNICITY,ETHNICITY
SM250826,13,-5,Bayombe,ETHNICITY,ETHNICITY
SM250826,13,-5,Bayombe,ETHNICITY,ETHNICITY
SM226841,139.617,-5.2173,Bayono,LANGUAGE,LANGUAGE
SM18028,27.1667,38.4622,Bayrakli,ADM2,DISTRICT
SM298266,26.6249,39.8195,Bayramiç,ADM2,DISTRICT
SM298529,28.9028,41.0469,Bayrampaşa,ADM2,DISTRICT
SM298278,33.2099,40.9512,Bayramören,ADM2,DISTRICT
SM17773,42.4667,17.4333,Baysh,ADM2,DISTRICT
SM261282,145.0193,-37.9399,Bayside,ADM2,DISTRICT
SM261392,115.9087,-31.9118,Bayswater,ADM2,DISTRICT
SM305043,43.2781,14.4874,Bayt Al Faqiah,ADM2,DISTRICT
SM226845,114.108,-23.3809,Bayungu,LANGUAGE,LANGUAGE
SM459138,113.9521,-23.2095,Bayungu,ETHNICITY,ETHNICITY
SM13174,71.6673,43.4339,Bayzak District,ADM2,DISTRICT
SM13174,71.6673,43.4339,Bayzak District,ADM2,DISTRICT
SM13174,71.75,43.25,Bayzak District,ADM2,DISTRICT
SM13174,71.75,43.25,Bayzak District,ADM2,DISTRICT
SM298551,27.6552,38.227,Bayındır,ADM2,DISTRICT
SM297797,125.3817,-8.6246,Bazar Tete,ADM2,DISTRICT
SM280319,72.7436,41.0281,Bazar-Korgon,ADM2,DISTRICT
SM9506,69.5106,35.375,Bazarak,ADM2,DISTRICT
SM294397,46.3333,52.3333,Bazarno-Karabulakskiy rayon,ADM2,DISTRICT
SM269320,107.0623,32.0403,Bazhong,ADM2,DISTRICT
SM226847,77.2213,25.2977,Bazigar,LANGUAGE,LANGUAGE
SM17200,-60.9243,13.9108,Bazile,ADM2,DISTRICT
SM291768,24.2644,46.2138,Bazna,ADM2,DISTRICT
SM262212,-1.5,11.9167,Bazèga,ADM1:ADM2,DISTRICT
SM260394,13.1046,-13.1483,Baía Farta,ADM2,DISTRICT
SM265483,-35.0505,-6.4301,Baía Formosa,ADM2,DISTRICT
SM264322,-34.9727,-6.6629,Baía da Traição,ADM2,DISTRICT
SM273567,-78.4167,-1.4,Baños de Agua Santa,ADM2,DISTRICT
SM298525,28.8363,41.0428,Bağcılar,ADM2,DISTRICT
SM298996,44.0216,38.0531,Başkale,ADM2,DISTRICT
SM298361,30.0162,37.9071,Başmakçı,ADM2,DISTRICT
SM298575,32.6886,36.7622,Başyayla,ADM2,DISTRICT
SM298953,37.1767,40.5573,Başçiftlik,ADM2,DISTRICT
SM305412,14.7516,44.9716,Baška,ADM2,DISTRICT
SM305460,16.9502,43.3589,Baška Voda,ADM2,DISTRICT
SM250052,12,10,Bchama,ETHNICITY,ETHNICITY
SM280919,35.9665,34.2505,Bcharre,ADM2,DISTRICT
SM302489,-98.2781,44.4145,Beadle,ADM2,DISTRICT
SM371006,48.7711,-14.573,Bealanana,ADM2:ADM3,DISTRICT
SM226850,142.508,-6.1495,Beami,LANGUAGE,LANGUAGE
SM17201,-60.9474,13.7398,Beane Field,ADM2,DISTRICT
SM252161,-120.49,43.94,Bear Creek Northern Paiute,ETHNICITY,ETHNICITY
SM300577,-111.3297,42.2848,Bear Lake,ADM2,DISTRICT
SM226852,-123.4217,65.1867,Bearlake Salvey,DIALECT,LANGUAGE
SM17202,-61.034,13.8215,Beasejour/Myers Bridge,ADM2,DISTRICT
SM15474,-66.0791,18.1751,Beatriz Barrio (Caguas),ADM2,DISTRICT
SM15475,-66.0972,18.1492,Beatriz Barrio (Cayey),ADM2,DISTRICT
SM15476,-66.1167,18.1567,Beatriz Barrio (Cidra),ADM2,DISTRICT
SM252164,-116,37,Beatty,ETHNICITY,ETHNICITY
SM637,55.4316,-4.6201,Beau Vallon,ADM1,DISTRICT
SM17203,-60.9153,13.8178,Beauchamp,ADM2,DISTRICT
SM302475,-80.6922,32.3571,Beaufort,ADM2,DISTRICT
SM301997,-76.8452,35.4858,Beaufort,ADM2,DISTRICT
SM370467,6.2917,49.8358,Beaufort,ADM1:ADM2:ADM3,DISTRICT
SM12134,-77.9501,18.2342,Beaufort,ADM2,DISTRICT
SM324014,-113.4154,53.3524,Beaumont,ADM2:ADM3,DISTRICT
SM301163,-93.3433,30.6485,Beauregard,ADM2,DISTRICT
SM17205,-60.9378,14.0765,Beausejour (Gros-Islet),ADM2,DISTRICT
SM17204,-60.9552,13.7523,Beausejour (Vieux-Fort),ADM2,DISTRICT
SM17206,-60.9293,14.0745,Beausejour/Fostin'S Development,ADM2,DISTRICT
SM17207,-60.9268,14.0773,Beausejour/Ndc,ADM2,DISTRICT
SM226856,-120.352,56.7984,Beaver,LANGUAGE,LANGUAGE
SM302893,-113.2358,38.3577,Beaver,ADM2,DISTRICT
SM302318,-80.3493,40.6823,Beaver,ADM2,DISTRICT
SM302205,-100.4768,36.7497,Beaver,ADM2,DISTRICT
SM252165,-111.13,55.51,Beaver,ETHNICITY,ETHNICITY
SM252165,-111.13,55.51,Beaver,ETHNICITY,ETHNICITY
SM252165,-111.13,55.51,Beaver,ETHNICITY,ETHNICITY
SM252165,-118.43,54.72,Beaver,ETHNICITY,ETHNICITY
SM252165,-118.43,54.72,Beaver,ETHNICITY,ETHNICITY
SM252165,-118.43,54.72,Beaver,ETHNICITY,ETHNICITY
SM252165,-117,58,Beaver,ETHNICITY,ETHNICITY
SM252165,-117,58,Beaver,ETHNICITY,ETHNICITY
SM252165,-117,58,Beaver,ETHNICITY,ETHNICITY
SM252165,-115.76,58.51,Beaver,ETHNICITY,ETHNICITY
SM252165,-115.76,58.51,Beaver,ETHNICITY,ETHNICITY
SM252165,-115.76,58.51,Beaver,ETHNICITY,ETHNICITY
SM323787,-112.1976,53.2207,Beaver County,ADM2:ADM3,DISTRICT
SM301622,-112.8989,45.1327,Beaverhead,ADM2,DISTRICT
SM323794,-119.4227,55.2092,Beaverlodge,ADM2:ADM3,DISTRICT
SM13991,-9.3131,5.5735,Beawor District,ADM2,DISTRICT
SM226858,9.7358,6.3226,Beba,LANGUAGE,LANGUAGE
SM291999,20.3629,46.135,Beba Veche,ADM2,DISTRICT
SM226861,10.5646,6.7483,Bebe,LANGUAGE,LANGUAGE
SM267042,-48.538,-20.9455,Bebedouro,ADM2,DISTRICT
SM284886,8.2035,11.5315,Bebeji,ADM2,DISTRICT
SM226859,12.824,4.7887,Bebele,LANGUAGE,LANGUAGE
SM226863,150.323,-5.6328,Bebeli,LANGUAGE,LANGUAGE
SM267885,-38.08,-4.439,Beberibe,ADM2,DISTRICT
SM226867,13.3848,4.8667,Bebil,LANGUAGE,LANGUAGE
SM13434,26.1135,56.0486,Bebrenes pagasts,ADM2,DISTRICT
SM305561,17.8355,45.0978,Bebrina,ADM2,DISTRICT
SM13435,25.4558,56.7349,Bebru pagasts,ADM2,DISTRICT
SM289885,26.7698,45.3846,Beceni,ADM2,DISTRICT
SM269906,-73.2175,9.7429,Becerril,ADM2,DISTRICT
SM370468,6.3636,49.7461,Bech,ADM1:ADM2:ADM3,DISTRICT
SM290327,23.9587,43.783,Bechet,ADM2,DISTRICT
SM457397,1.25,36,Bechloul District,ADM2,DISTRICT
SM301360,-95.6739,46.9347,Becker,ADM2,DISTRICT
SM370444,5.8872,49.7306,Beckerich,ADM1:ADM2:ADM3,DISTRICT
SM12135,-77.324,18.0855,Beckford Kraal,ADM2,DISTRICT
SM302216,-99.6819,35.2687,Beckham,ADM2,DISTRICT
SM292490,24.1739,47.1612,Beclean,ADM2,DISTRICT
SM292630,24.9232,45.8312,Beclean,ADM2,DISTRICT
SM275109,-15.1167,11.35,Bedanda,ADM2,DISTRICT
SM250716,29.74,8.93,Bedaria,ETHNICITY,ETHNICITY
SM427809,17.9902,9.0372,Bedaya,ADM2:ADM3,DISTRICT
SM305726,15.9948,46.0442,Bedekovčina,ADM2,DISTRICT
SM305613,16.2495,46.0326,Bedenica,ADM2,DISTRICT
SM302948,-79.5242,37.3149,Bedford,ADM2,DISTRICT
SM302555,-86.4589,35.5138,Bedford,ADM2,DISTRICT
SM302329,-78.4903,40.0066,Bedford,ADM2,DISTRICT
SM274625,-0.4532,52.1883,Bedfordshire,ADM1:ADM2,DISTRICT
SM226877,-12.4796,12.7531,Bedik,LANGUAGE,LANGUAGE
SM252172,-12.19,14.15,Bedik,ETHNICITY,ETHNICITY
SM305501,15.9824,46.228,Bednja,ADM2,DISTRICT
SM226882,132.566,-2.7444,Bedoanas,LANGUAGE,LANGUAGE
SM306057,35,31,Bedouin-Israel,ETHNICITY,ETHNICITY
SM306057,35,31,Bedouin-Israel,ETHNICITY,ETHNICITY
SM306057,35,31,Bedouin-Israel,ETHNICITY,ETHNICITY
SM306057,35,31,Bedouin-Israel,ETHNICITY,ETHNICITY
SM454489,11.6,5.5,Bedzan,ETHNICITY,ETHNICITY
SM302673,-97.7412,28.4174,Bee,ADM2,DISTRICT
SM12136,-77.1162,18.3847,Beecher Town,ADM2,DISTRICT
SM285876,5.8059,50.9324,Beek,ADM2,DISTRICT
SM15112,5.8972,50.9367,Beekdaelen,ADM2,DISTRICT
SM226886,29.9842,3.1844,Beeke,LANGUAGE,LANGUAGE
SM296089,-55.1802,5.8071,Beekhuizen,ADM2,DISTRICT
SM226887,10.4204,10.1609,Beele,LANGUAGE,LANGUAGE
SM226888,14.1,-3.959,Beembe,LANGUAGE,LANGUAGE
SM286032,4.9181,52.554,Beemster,ADM2,DISTRICT
SM285887,6.0677,51.2702,Beesel,ADM2,DISTRICT
SM12137,-77.9755,18.1601,Beeston Spring,ADM2,DISTRICT
SM226889,10.109,6.8155,Beezen,LANGUAGE,LANGUAGE
SM371007,48.7795,-15.1446,Befandriana-Nord,ADM2:ADM3,DISTRICT
SM226891,10.0342,6.2756,Befang,LANGUAGE,LANGUAGE
SM370971,46.8162,-23.8845,Befotaka,ADM2:ADM3,DISTRICT
SM261581,149.7206,-36.8165,Bega Valley,ADM2,DISTRICT
SM226893,7.5957,9.4052,Begbere-Ejar,LANGUAGE,LANGUAGE
SM252173,36.54,14.05,Begemder,ETHNICITY,ETHNICITY
SM277436,86.25,25.5,Begusarai,ADM2,DISTRICT
SM252175,30.59,31.16,Beheria,ETHNICITY,ETHNICITY
SM11756,53.6833,36.6667,Behshahr,ADM2,DISTRICT
SM286089,14.573,67.0073,Beiarn,ADM2,DISTRICT
SM291122,24.8387,46.724,Beica De Jos,ADM2,DISTRICT
SM292059,28.5327,44.7213,Beidaud,ADM2,DISTRICT
SM269443,109,21.5,Beihai,ADM2,DISTRICT
SM269267,116.4155,39.8906,Beijing,ADM2,DISTRICT
SM639,116.3971,39.9169,Beijing,ADM1,DISTRICT
SM226898,116.1173,39.9386,Beijing Mandarin,DIALECT,LANGUAGE
SM10659,33.5101,35.2479,Beikioi,ADM2,DISTRICT
SM640,35.5,33.8833,Beirut,ADM1,DISTRICT
SM323912,-113.5405,51.3809,Beiseker,ADM2:ADM3,DISTRICT
SM305273,30.0741,-21.8983,Beitbridge,ADM2,DISTRICT
SM292484,22.3457,46.6807,Beius,ADM2,DISTRICT
SM226902,36.6666,17.2436,Beja,LANGUAGE,LANGUAGE
SM289428,-7.8777,37.988,Beja,ADM2,DISTRICT
SM641,-7.9186,37.842,Beja,ADM1,DISTRICT
SM271016,-82.3372,22.9065,Bejucal,ADM2,DISTRICT
SM283103,-92.1667,15.45,Bejucal de Ocampo,ADM2,DISTRICT
SM15477,-67.0427,18.4945,Bejucos Barrio,ADM2,DISTRICT
SM303864,-68.2,10.25,Bejuma,ADM2,DISTRICT
SM644,35.8333,33.6667,Bekaa,ADM1,DISTRICT
SM297907,11.0086,35.6009,Bekalta,ADM2,DISTRICT
SM276776,107.1083,-6.2467,Bekasi,ADM2,DISTRICT
SM11537,21.1,46.8332,Bekesi Jaras,ADM2,DISTRICT
SM298314,29.4256,38.2403,Bekilli,ADM2,DISTRICT
SM371029,45.3833,-24.1833,Bekily,ADM2:ADM3,DISTRICT
SM18376,69.2514,40.2158,Bekobod Shahri,ADM2,DISTRICT
SM18377,69.1667,40.4167,Bekobod Tumani,ADM2,DISTRICT
SM18378,69.3456,41.2378,Bektemir Tumani,ADM2,DISTRICT
SM11382,-1.5833,6.45,Bekwai,ADM2,DISTRICT
SM226907,8.8649,6.7374,Bekwarra,LANGUAGE,LANGUAGE
SM285402,8.9106,6.6958,Bekwarra,ADM2,DISTRICT
SM226908,14.6527,1.718,Bekwil,LANGUAGE,LANGUAGE
SM646,55.45,-4.6333,Bel Air,ADM1,DISTRICT
SM294760,33.0833,55.8333,Bel'skiy rayon,ADM2,DISTRICT
SM267886,-40.2584,-3.0629,Bela Cruz,ADM2,DISTRICT
SM263026,-56.5082,-22.0406,Bela Vista,ADM2,DISTRICT
SM264721,-53.6276,-25.874,Bela Vista da Caroba,ADM2,DISTRICT
SM268213,-48.9156,-16.9741,Bela Vista de Goiás,ADM2,DISTRICT
SM263813,-43.1027,-19.8014,Bela Vista de Minas,ADM2,DISTRICT
SM262942,-45.2955,-3.7914,Bela Vista do Maranhão,ADM2,DISTRICT
SM264732,-51.28,-22.969,Bela Vista do Paraíso,ADM2,DISTRICT
SM265162,-41.9009,-7.9161,Bela Vista do Piauí,ADM2,DISTRICT
SM266359,-50.5106,-26.4594,Bela Vista do Toldo,ADM2,DISTRICT
SM17208,-60.9975,13.9528,Belair,ADM2,DISTRICT
SM647,114.5833,4.3167,Belait,ADM1,DISTRICT
SM270858,-75.8188,5.0036,Belalcázar,ADM2,DISTRICT
SM226913,27.8232,6.6503,Belanda Bor,LANGUAGE,LANGUAGE
SM226912,27.6956,7.4131,Belanda Viri,LANGUAGE,LANGUAGE
SM252181,101.55,3.67,Belandas,ETHNICITY,ETHNICITY
SM10660,33.3515,35.3015,Belapais,ADM2,DISTRICT
SM226911,25.6038,53.2307,Belarusian,LANGUAGE,LANGUAGE
SM13436,26.7516,57.2438,Belavas pagasts,ADM2,DISTRICT
SM15218,7.86,15.1005,Belbedji,ADM2,DISTRICT
SM290860,27.0875,47.3058,Belcesti,ADM2,DISTRICT
SM289948,26.4456,44.5116,Belciugatele,ADM2,DISTRICT
SM16852,54.25,54.1667,Belebeyevskiy Rayon,ADM2,DISTRICT
SM17852,45.2084,4.7475,Beledweyne District (excl. Belet Weyne town),ADM2,DISTRICT
SM298459,36.1934,36.4922,Belen,ADM2,DISTRICT
SM275698,-88.4282,14.5083,Belen,ADM2,DISTRICT
SM262330,25.1333,43.6333,Belene,ADM2,DISTRICT
SM241284,163.66,-19.72,Belep,LANGUAGE,LANGUAGE
SM17853,45.3796,4.7243,Belet Weyne Town,ADM2,DISTRICT
SM17854,41.7337,3.4356,Belet Xaawo Town,ADM2,DISTRICT
SM274706,-5.9167,54.5833,Belfast,ADM1:ADM2,DISTRICT
SM12138,-76.8357,18.2735,Belfield,ADM2,DISTRICT
SM265334,-43.3827,-22.7096,Belford Roxo,ADM2,DISTRICT
SM276947,74.75,16.3333,Belgaum,ADM2,DISTRICT
SM648,37.5,50.75,Belgorod,ADM1,DISTRICT
SM16853,36.5,50.5,Belgorodskiy Rayon,ADM2,DISTRICT
SM261059,-62.25,-29.0833,Belgrano,ADM2,DISTRICT
SM261017,-61.75,-32.5,Belgrano,ADM2,DISTRICT
SM260986,-66.75,-32.75,Belgrano,ADM2,DISTRICT
SM226927,87.2783,26.9972,Belhariya,LANGUAGE,LANGUAGE
SM226929,142.34,-3.6788,Beli (Papua New Guinea),LANGUAGE,LANGUAGE
SM226928,29.7954,6.4043,Beli (Sudan),LANGUAGE,LANGUAGE
SM275957,18.6108,45.7722,Beli Manastir,ADM2,DISTRICT
SM305340,16.5206,46.4074,Belica,ADM2,DISTRICT
SM290239,25.5913,45.9336,Belin,ADM2,DISTRICT
SM292021,21.7755,45.7702,Belint,ADM2,DISTRICT
SM290144,22.9521,46.6525,Belis,ADM2,DISTRICT
SM262686,-64.3202,-18.9317,Belisario Boeto,ADM2,DISTRICT
SM262294,23.5833,42.0167,Belitsa,ADM2,DISTRICT
SM276637,107.7,-2.8667,Belitung,ADM2,DISTRICT
SM276636,108.1522,-2.9627,Belitung Timur,ADM2,DISTRICT
SM290557,21.9957,46.5296,Beliu,ADM2,DISTRICT
SM260412,12.8304,-4.5838,Belize,ADM2,DISTRICT
SM649,-88.4167,17.55,Belize,ADM1,DISTRICT
SM226930,-88.6281,17.3941,Belize Kriol English,LANGUAGE,LANGUAGE
SM275960,18.4007,45.6799,Belišće,ADM2,DISTRICT
SM298079,9.4045,34.332,Belkhir,ADM2,DISTRICT
SM301803,-71.4234,43.5187,Belknap,ADM2,DISTRICT
SM302684,-97.4782,31.0377,Bell,ADM2,DISTRICT
SM301075,-83.6741,36.7307,Bell,ADM2,DISTRICT
SM226932,-126.689,52.3448,Bella Coola,LANGUAGE,LANGUAGE
SM17209,-60.9963,14.0078,Bella Rosa (Castries),ADM2,DISTRICT
SM17210,-60.9457,14.0771,Bella Rosa (Gros-Islet),ADM2,DISTRICT
SM289672,-56.4548,-22.4708,Bella Vista,ADM2,DISTRICT
SM289574,-55.5451,-26.9953,Bella Vista,ADM2,DISTRICT
SM283114,-92.2167,15.5833,Bella Vista,ADM2,DISTRICT
SM261148,-58.8333,-28.5,Bella Vista,ADM2,DISTRICT
SM226933,76.1025,12.5852,Bellari,LANGUAGE,LANGUAGE
SM276948,76.55,15.15,Bellary (1997-),ADM2,DISTRICT
SM12139,-77.1491,18.0471,Bellas Gate,ADM2,DISTRICT
SM286835,-76.3193,-7.6383,Bellavista,ADM2,DISTRICT
SM250856,-9.57,8.24,Belle,ETHNICITY,ETHNICITY
SM17211,-61.0313,13.827,Belle Plaine,ADM2,DISTRICT
SM17213,-61.0362,13.8051,Belle Vue (Choiseul),ADM2,DISTRICT
SM17214,-60.9434,14.0847,Belle Vue (Gros-Islet),ADM2,DISTRICT
SM17212,-60.9538,13.7922,Belle Vue (Vieux-Fort),ADM2,DISTRICT
SM17215,-60.9461,14.0884,Belle Vue Estate,ADM2,DISTRICT
SM276110,-71.9875,18.2572,Belle-Anse,ADM2,DISTRICT
SM12140,-77.44,18.0927,Bellefield,ADM2,DISTRICT
SM17216,-61.0427,13.8275,Bellefond,ADM2,DISTRICT
SM280961,-9.9892,7.546,Belleh,ADM2,DISTRICT
SM252194,41.19,39.19,Bellikan,ETHNICITY,ETHNICITY
SM261440,152.7249,-30.3739,Bellingen,ADM2,DISTRICT
SM268974,9.0025,46.1886,Bellinzona,ADM2,DISTRICT
SM270179,-75.5873,6.3616,Bello,ADM2,DISTRICT
SM277928,12.1333,46.25,Belluno,ADM2,DISTRICT
SM270180,-75.6667,6.6667,Belmira,ADM2,DISTRICT
SM263824,-43.4593,-21.9644,Belmiro Braga,ADM2,DISTRICT
SM302149,-80.9885,40.0158,Belmont,ADM2,DISTRICT
SM17217,-60.9328,13.9504,Belmont,ADM2,DISTRICT
SM261403,115.9422,-31.9546,Belmont,ADM2,DISTRICT
SM289459,-7.3516,40.3618,Belmonte,ADM2,DISTRICT
SM266360,-53.614,-26.8532,Belmonte,ADM2,DISTRICT
SM267717,-39.3227,-15.8916,Belmonte,ADM2,DISTRICT
SM226937,9.5347,9.2217,Belneng,LANGUAGE,LANGUAGE
SM267718,-41.2267,-14.8742,Belo Campo,ADM2,DISTRICT
SM263835,-43.9398,-19.9262,Belo Horizonte,ADM2,DISTRICT
SM264937,-36.4253,-8.2767,Belo Jardim,ADM2,DISTRICT
SM265427,-37.1916,-9.8052,Belo Monte,ADM2,DISTRICT
SM263846,-42.4429,-19.2556,Belo Oriente,ADM2,DISTRICT
SM263857,-44.0647,-20.4158,Belo Vale,ADM2,DISTRICT
SM293407,41.0616,45.9864,Beloglinskiy rayon,ADM2,DISTRICT
SM16854,128.5,50.75,Belogorskiy Rayon,ADM2,DISTRICT
SM262476,22.6,43.65,Belogradchik,ADM2,DISTRICT
SM371030,45.06,-25.174,Beloha,ADM2:ADM3,DISTRICT
SM294200,40.7485,48.0816,Belokalitvinskiy rayon,ADM2,DISTRICT
SM294237,59,55.75,Belokatayskiy rayon,ADM2,DISTRICT
SM293283,51.1667,58.9167,Belokholunitskiy rayon,ADM2,DISTRICT
SM650,55.4179,-4.6184,Belombre,ADM1,DISTRICT
SM293117,35,64.1667,Belomorskiy rayon,ADM2,DISTRICT
SM371044,44.8421,-19.6372,Belon-i Tsiribihina,ADM2:ADM3,DISTRICT
SM16855,39.7206,44.7992,Belorechenskiy Rayon,ADM2,DISTRICT
SM294238,58,54,Beloretskiy rayon,ADM2,DISTRICT
SM252195,25.69,51.69,Belorussian,ETHNICITY,ETHNICITY
SM252195,25.69,51.69,Belorussian,ETHNICITY,ETHNICITY
SM252195,25.69,51.69,Belorussian,ETHNICITY,ETHNICITY
SM252195,28,55,Belorussian,ETHNICITY,ETHNICITY
SM252195,28,55,Belorussian,ETHNICITY,ETHNICITY
SM252195,28,55,Belorussian,ETHNICITY,ETHNICITY
SM252195,28.0119,53.9199,Belorussian,ETHNICITY,ETHNICITY
SM252195,28.0119,53.9199,Belorussian,ETHNICITY,ETHNICITY
SM252195,28.0119,53.9199,Belorussian,ETHNICITY,ETHNICITY
SM262459,27.7333,43.2,Beloslav,ADM2,DISTRICT
SM262316,23.9333,42.2,Belovo,ADM2,DISTRICT
SM16856,86.75,54.4167,Belovskiy Rayon,ADM2,DISTRICT
SM293495,35.75,51.0833,Belovskiy rayon,ADM2,DISTRICT
SM294569,61.3333,56.75,Beloyarskiy rayon,ADM2,DISTRICT
SM294961,37.25,59.9167,Belozerskiy rayon,ADM2,DISTRICT
SM293480,65.5,55.9167,Belozerskiy rayon,ADM2,DISTRICT
SM264135,-54.9913,-3.0126,Belterra,ADM2,DISTRICT
SM296358,16.2322,46.6147,Beltinci,ADM1:ADM2,DISTRICT
SM291704,22.8545,47.5652,Beltiug,ADM2,DISTRICT
SM301371,-94.9377,47.9738,Beltrami,ADM2,DISTRICT
SM270039,-74.7566,4.7195,Beltrán,ADM2,DISTRICT
SM457943,124.9163,-9.1758,Belu (2012-),ADM2,DISTRICT
SM226941,28.9337,1.3147,Belueli,LANGUAGE,LANGUAGE
SM17218,-61.0555,13.8912,Belvedere (Canaries),ADM2,DISTRICT
SM12141,-76.6967,18.2077,Belvedere (Portland),ADM2,DISTRICT
SM17219,-61.0177,13.8344,Belvedere (Soufrière),ADM2,DISTRICT
SM18549,-77.9321,18.2522,Belvedere (Westmoreland),ADM2,DISTRICT
SM261602,130.6832,-12.5382,Belyuen,ADM2,DISTRICT
SM262943,-43.4567,-3.088,Belágua,ADM2,DISTRICT
SM264363,-35.5024,-6.7233,Belém,ADM2,DISTRICT
SM264133,-48.4599,-1.2407,Belém,ADM2,DISTRICT
SM265416,-36.502,-9.5442,Belém,ADM2,DISTRICT
SM264922,-35.825,-8.5785,Belém de Maria,ADM2,DISTRICT
SM264933,-38.9817,-8.5339,Belém de São Francisco,ADM2,DISTRICT
SM264362,-37.4055,-6.1626,Belém do Brejo do Cruz,ADM2,DISTRICT
SM265163,-40.9887,-7.4066,Belém do Piauí,ADM2,DISTRICT
SM289520,-57.2317,-23.4278,Belén,ADM2,DISTRICT
SM285481,-85.9632,11.5719,Belén,ADM2,DISTRICT
SM270934,-84.1667,9.9833,Belén,ADM2,DISTRICT
SM305311,-77.0373,1.5937,Belén,ADM2,DISTRICT
SM305314,-72.9248,5.9763,Belén,ADM2,DISTRICT
SM261005,-66.9167,-27.0833,Belén,ADM2,DISTRICT
SM275726,-88.7833,14.4795,Belén Gualcho,ADM2,DISTRICT
SM270432,-75.8721,5.209,Belén de Umbría,ADM2,DISTRICT
SM270871,-75.9167,1.4167,Belén de los Andaquies,ADM2,DISTRICT
SM250089,31.73,-11.95,Bemba,ETHNICITY,ETHNICITY
SM250089,31.73,-11.95,Bemba,ETHNICITY,ETHNICITY
SM250089,31.73,-11.95,Bemba,ETHNICITY,ETHNICITY
SM250089,30.5,-10.5,Bemba,ETHNICITY,ETHNICITY
SM250089,30.5,-10.5,Bemba,ETHNICITY,ETHNICITY
SM250089,30.5,-10.5,Bemba,ETHNICITY,ETHNICITY
SM250089,31,-11,Bemba,ETHNICITY,ETHNICITY
SM250089,31,-11,Bemba,ETHNICITY,ETHNICITY
SM250089,31,-11,Bemba,ETHNICITY,ETHNICITY
SM226946,28.08,-10,Bemba (Democratic Republic of Congo),LANGUAGE,LANGUAGE
SM226945,29.2993,-10.6122,Bemba (Zambia),LANGUAGE,LANGUAGE
SM226943,29.0507,-4.4906,Bembe,LANGUAGE,LANGUAGE
SM260381,14.2745,-7.0622,Bembe,ADM2,DISTRICT
SM260381,14.2745,-7.0622,Bembe,ADM2,DISTRICT
SM260381,14.3333,-7.1667,Bembe,ADM2,DISTRICT
SM260381,14.3333,-7.1667,Bembe,ADM2,DISTRICT
SM297914,10.7605,35.6815,Bembla,ADM2,DISTRICT
SM262149,2.7716,10.2676,Bembéréké,ADM2,DISTRICT
SM277464,81.5299,21.7052,Bemetara,ADM2,DISTRICT
SM11757,50.752,32.5356,Ben,ADM2,DISTRICT
SM251019,1,11,Ben (Moba),ETHNICITY,ETHNICITY
SM298043,10.2275,36.7513,Ben Arous,ADM2,DISTRICT
SM651,10.323,36.7213,Ben Arous (Tunis Sud),ADM1,DISTRICT
SM457399,-0.9139,34.9517,Ben Badis District,ADM2,DISTRICT
SM297898,11.2074,32.8994,Ben Guerdane,ADM2,DISTRICT
SM250523,-4.77,33.77,Ben Guil,ETHNICITY,ETHNICITY
SM300528,-83.2205,31.7598,Ben Hill,ADM2,DISTRICT
SM459128,147.8021,-41.5164,Ben Lomond,ETHNICITY,ETHNICITY
SM281411,-7.1667,33.5,Ben Slimane,ADM2,DISTRICT
SM226995,-2.94,14.78,Ben Tey Dogon,LANGUAGE,LANGUAGE
SM226991,147.7871,-41.3264,Ben-Lomond-Cape-Portland,LANGUAGE,LANGUAGE
SM250091,34.92,-6.31,Bena,ETHNICITY,ETHNICITY
SM250091,34.92,-6.31,Bena,ETHNICITY,ETHNICITY
SM250091,36,-9,Bena,ETHNICITY,ETHNICITY
SM250091,36,-9,Bena,ETHNICITY,ETHNICITY
SM226954,12.3972,9.7486,Bena (Nigeria),LANGUAGE,LANGUAGE
SM226955,35.0364,-9.2667,Bena (Tanzania),LANGUAGE,LANGUAGE
SM226957,145.534,-6.0997,Benabena,LANGUAGE,LANGUAGE
SM252197,145.63,-6.21,Benabena,ETHNICITY,ETHNICITY
SM261293,146.0281,-36.5904,Benalla,ADM2,DISTRICT
SM289334,-8.769,38.908,Benavente,ADM2,DISTRICT
SM15478,-67.1057,18.1169,Benavente Barrio,ADM2,DISTRICT
SM226961,35.7673,7.0468,Bench,LANGUAGE,LANGUAGE
SM274324,35.3055,6.4599,Bench Maji,ADM2,DISTRICT
SM297554,104.7188,14.7832,Benchalak,ADM2,DISTRICT
SM226965,30.5926,-6.2208,Bende,LANGUAGE,LANGUAGE
SM284643,7.59,5.6497,Bende,ADM2,DISTRICT
SM250094,30.45,-6.88,Bende,ETHNICITY,ETHNICITY
SM250094,30.45,-6.88,Bende,ETHNICITY,ETHNICITY
SM250094,31,-7,Bende,ETHNICITY,ETHNICITY
SM250094,31,-7,Bende,ETHNICITY,ETHNICITY
SM652,29.45,46.8333,Bender,ADM1,DISTRICT
SM226967,30.3679,2.2023,Bendi,LANGUAGE,LANGUAGE
SM274576,8.7753,-0.758,Bendje (incl. Port-Gentil),ADM2,DISTRICT
SM296324,15.8891,46.6174,Benedikt,ADM1:ADM2,DISTRICT
SM265164,-42.4397,-5.4601,Beneditinos,ADM2,DISTRICT
SM262945,-44.5876,-7.107,Benedito Leite,ADM2,DISTRICT
SM266361,-49.436,-26.7859,Benedito Novo,ADM2,DISTRICT
SM14229,-90.6531,16.5172,Benemerito de las Americas,ADM2,DISTRICT
SM371037,45.0202,-23.4656,Benenitra,ADM2:ADM3,DISTRICT
SM276284,96.8616,4.7302,Bener Meriah,ADM2,DISTRICT
SM13437,23.0692,56.4583,Benes pagasts,ADM2,DISTRICT
SM291643,23.3,47.4167,Benesat,ADM2,DISTRICT
SM277943,14.7464,41.2179,Benevento,ADM2,DISTRICT
SM264136,-48.2887,-1.3245,Benevides,ADM2,DISTRICT
SM300583,-116.6588,47.2176,Benewah,ADM2,DISTRICT
SM271183,14.75,49.6667,Benešov,ADM2,DISTRICT
SM226978,-4.2354,7.7812,Beng,LANGUAGE,LANGUAGE
SM280891,101.7313,20.3536,Beng,ADM2,DISTRICT
SM226975,9.6007,1.0758,Benga,LANGUAGE,LANGUAGE
SM226973,90,24,Bengali,LANGUAGE,LANGUAGE
SM252202,88,23,Bengali,ETHNICITY,ETHNICITY
SM252202,88,23,Bengali,ETHNICITY,ETHNICITY
SM252202,88,23,Bengali,ETHNICITY,ETHNICITY
SM252202,90.4,23.7,Bengali,ETHNICITY,ETHNICITY
SM252202,90.4,23.7,Bengali,ETHNICITY,ETHNICITY
SM252202,90.4,23.7,Bengali,ETHNICITY,ETHNICITY
SM252202,87.7,23.3,Bengali,ETHNICITY,ETHNICITY
SM252202,87.7,23.3,Bengali,ETHNICITY,ETHNICITY
SM252202,87.7,23.3,Bengali,ETHNICITY,ETHNICITY
SM269128,117.4356,33.1479,Bengbu,ADM2,DISTRICT
SM290609,23.6062,45.0985,Bengesti,ADM2,DISTRICT
SM226979,130.091,-3.1392,Benggoi,LANGUAGE,LANGUAGE
SM653,20.1874,32.0872,Benghazi,ADM1,DISTRICT
SM276553,101.5453,1.2988,Bengkalis,ADM2,DISTRICT
SM276377,109.6639,1.0691,Bengkayang,ADM2,DISTRICT
SM276725,102.3167,-3.8167,Bengkulu,ADM2,DISTRICT
SM654,102.2668,-3.7997,Bengkulu,ADM1,DISTRICT
SM276721,103.0333,-4.35,Bengkulu Selatan,ADM2,DISTRICT
SM276723,102.3965,-3.6778,Bengkulu Tengah,ADM2,DISTRICT
SM276724,102.05,-3.3333,Bengkulu Utara,ADM2,DISTRICT
SM461303,13.8274,-8.975,Bengo province (2011-),ADM1,DISTRICT
SM296600,12.2167,59.0333,Bengtsfors,ADM2,DISTRICT
SM260396,13.5778,-12.7396,Benguela,ADM2,DISTRICT
SM656,13.9283,-12.854,Benguela,ADM1,DISTRICT
SM657,120.6667,16.5,Benguet,ADM1:ADM2,DISTRICT
SM269650,29.4667,0.5,Beni,ADM2,DISTRICT
SM252205,-5.3,33.04,Beni Amir,ETHNICITY,ETHNICITY
SM252206,37.48,27.6,Beni Atiyah,ETHNICITY,ETHNICITY
SM252207,51.19,25.49,Beni Hajar,ETHNICITY,ETHNICITY
SM297915,10.7576,35.5195,Beni Hassen,ADM2,DISTRICT
SM226986,-2.3167,34.9167,Beni Iznassen,DIALECT,LANGUAGE
SM297922,10.6364,36.6269,Beni Khalled,ADM2,DISTRICT
SM297899,10.1182,33.2293,Beni Khedache,ADM2,DISTRICT
SM297930,10.77,36.5321,Beni Khiar,ADM2,DISTRICT
SM4424,-6.3333,32.5,Beni Mellal (2015-),ADM2,DISTRICT
SM4270,-6.3089,32.3264,Beni Mellal-Khenifra,ADM1,DISTRICT
SM252208,36.1,30.13,Beni Sakhr Bedu,ETHNICITY,ETHNICITY
SM252209,31.22,28,Beni Suef,ETHNICITY,ETHNICITY
SM250095,36.9,15.6,Beni-Amar,ETHNICITY,ETHNICITY
SM250095,36.9,15.6,Beni-Amar,ETHNICITY,ETHNICITY
SM250095,36.9,15.6,Beni-Amar,ETHNICITY,ETHNICITY
SM250095,38.18,17.73,Beni-Amar,ETHNICITY,ETHNICITY
SM250095,38.18,17.73,Beni-Amar,ETHNICITY,ETHNICITY
SM250095,38.18,17.73,Beni-Amar,ETHNICITY,ETHNICITY
SM250095,38,18,Beni-Amar,ETHNICITY,ETHNICITY
SM250095,38,18,Beni-Amar,ETHNICITY,ETHNICITY
SM250095,38,18,Beni-Amar,ETHNICITY,ETHNICITY
SM250095,36,21,Beni-Amar,ETHNICITY,ETHNICITY
SM250095,36,21,Beni-Amar,ETHNICITY,ETHNICITY
SM250095,36,21,Beni-Amar,ETHNICITY,ETHNICITY
SM14230,-109.8379,27.119,Benito Juarez (Sonora),ADM2,DISTRICT
SM14231,-98.4254,19.5868,Benito Juarez (Tlaxcala),ADM2,DISTRICT
SM283071,-103.5668,21.5045,Benito Juárez,ADM2,DISTRICT
SM282849,-98.196,20.8205,Benito Juárez,ADM2,DISTRICT
SM282465,-87.041,21.0203,Benito Juárez,ADM2,DISTRICT
SM281585,-100.4676,17.108,Benito Juárez,ADM2,DISTRICT
SM283334,-99.1571,19.3981,Benito Juárez,ADM2,DISTRICT
SM260729,-59.9167,-37.5,Benito Juárez,ADM2,DISTRICT
SM267305,-70.4069,-5.6484,Benjamin Constant,ADM2,DISTRICT
SM265917,-52.6547,-27.4873,Benjamin Constant do Sul,ADM2,DISTRICT
SM289635,-58.0453,-24.3962,Benjamín Aceval,ADM2,DISTRICT
SM282556,-111.2053,30.1954,Benjamín Hill,ADM2,DISTRICT
SM276024,15.611,44.033,Benkovac,ADM2,DISTRICT
SM302500,-101.664,43.1949,Bennett,ADM2,DISTRICT
SM302928,-73.093,43.0355,Bennington,ADM2,DISTRICT
SM658,35.5,11,Benshangul-Gumaz,ADM1,DISTRICT
SM302052,-99.366,48.0694,Benson,ADM2,DISTRICT
SM12142,-77.2086,18.2232,Bensonton,ADM2,DISTRICT
SM303373,-103.0717,37.9551,Bent,ADM2,DISTRICT
SM323967,-114.0481,52.467,Bentley,ADM2:ADM3,DISTRICT
SM265515,-35.817,-5.6343,Bento Fernandes,ADM2,DISTRICT
SM9888,-51.5434,-29.0996,Bento Goncalves (2013-),ADM2,DISTRICT
SM267044,-50.8654,-21.3406,Bento de Abreu,ADM2,DISTRICT
SM303091,-119.5112,46.2398,Benton,ADM2,DISTRICT
SM302566,-88.0683,36.0698,Benton,ADM2,DISTRICT
SM302260,-123.4293,44.4918,Benton,ADM2,DISTRICT
SM301470,-89.1885,34.8173,Benton,ADM2,DISTRICT
SM301382,-93.9988,45.6991,Benton,ADM2,DISTRICT
SM301600,-93.288,38.2948,Benton,ADM2,DISTRICT
SM300839,-92.0657,42.0802,Benton,ADM2,DISTRICT
SM300724,-87.3109,40.6063,Benton,ADM2,DISTRICT
SM302385,-94.2562,36.3387,Benton,ADM2,DISTRICT
SM226994,119.806,-4.76,Bentong,LANGUAGE,LANGUAGE
SM659,8.75,7.3333,Benue (1991-),ADM1,DISTRICT
SM269247,124.6567,41.1769,Benxi,ADM2,DISTRICT
SM227000,109.753,0.7853,Benyadu',LANGUAGE,LANGUAGE
SM301252,-86.139,44.6169,Benzie,ADM2,DISTRICT
SM303725,-62.875,10.27,Benítez,ADM2,DISTRICT
SM227005,-57,48,Beothuk,LANGUAGE,LANGUAGE
SM252213,-56.62,48.75,Beothuk,ETHNICITY,ETHNICITY
SM227006,145.442,-4.5985,Bepour,LANGUAGE,LANGUAGE
SM279002,131.4528,33.2857,Beppu,ADM2,DISTRICT
SM262946,-44.8012,-2.4181,Bequimão,ADM2,DISTRICT
SM227009,30.0293,1.5005,Bera,LANGUAGE,LANGUAGE
SM284436,102.5,3.3333,Bera,ADM2,DISTRICT
SM250019,-2.6,18.03,Berabiche,ETHNICITY,ETHNICITY
SM250019,-2.6,18.03,Berabiche,ETHNICITY,ETHNICITY
SM250019,-5,20,Berabiche,ETHNICITY,ETHNICITY
SM250019,-5,20,Berabiche,ETHNICITY,ETHNICITY
SM268293,114.9299,4.9703,Berakas A,ADM2,DISTRICT
SM268296,114.9575,4.9597,Berakas B,ADM2,DISTRICT
SM227011,16.7259,12.8324,Berakou,LANGUAGE,LANGUAGE
SM227011,16.7259,12.8324,Berakou,LANGUAGE,LANGUAGE
SM227011,15,12.5,Berakou,LANGUAGE,LANGUAGE
SM227011,15,12.5,Berakou,LANGUAGE,LANGUAGE
SM4110,19.8707,42.8391,Berane (2013-),ADM1,DISTRICT
SM661,20.0833,40.6333,Berat,ADM1,DISTRICT
SM260467,20,40.6667,Beratit District (pre-2015),ADM2,DISTRICT
SM276424,117.3,2,Berau,ADM2,DISTRICT
SM227012,117.934,1.6687,Berau Malay,LANGUAGE,LANGUAGE
SM260730,-58.1667,-34.8333,Berazategui,ADM2,DISTRICT
SM270784,-73.1253,5.2276,Berbeo,ADM2,DISTRICT
SM295802,45.2854,10.3594,Berbera,ADM2,DISTRICT
SM292227,23.8801,44.9992,Berbesti,ADM2,DISTRICT
SM227021,-1.7691,15.2785,Berbey Sign Language,LANGUAGE,LANGUAGE
SM227019,-58.0002,5.3829,Berbice Creole Dutch,LANGUAGE,LANGUAGE
SM268596,15.8895,4.3121,Berbérati,ADM2,DISTRICT
SM289896,26.6599,45.301,Berca,ADM2,DISTRICT
SM291495,26.1053,44.9238,Berceni,ADM2,DISTRICT
SM290979,26.1905,44.3089,Berceni,ADM2,DISTRICT
SM370469,6.3503,49.8136,Berdorf,ADM1:ADM2:ADM3,DISTRICT
SM18182,36.791,46.7551,Berdyansk Raion,ADM2,DISTRICT
SM299861,28.5972,49.8968,Berdychivs'ka,ADM2,DISTRICT
SM299862,28.5833,49.9167,Berdychivs'kyi,ADM2,DISTRICT
SM294818,68.5833,55.8333,Berdyuzhskiy rayon,ADM2,DISTRICT
SM662,27.9167,-29.1667,Berea,ADM1,DISTRICT
SM299753,22.6468,48.207,Berehivs'kyi,ADM2,DISTRICT
SM305322,16.8291,45.7366,Berek,ADM2,DISTRICT
SM274883,-2.5833,7.45,Berekum,ADM2,DISTRICT
SM290480,27.8862,46.0965,Beresti,ADM2,DISTRICT
SM290481,27.9229,46.1036,Beresti-meria,ADM2,DISTRICT
SM292162,26.6788,46.483,Beresti-tazlau,ADM2,DISTRICT
SM305509,16.3053,46.2478,Beretinec,ADM2,DISTRICT
SM276272,21.5523,47.1411,Berettyóújfalu,ADM2,DISTRICT
SM291303,24.9429,45.2533,Berevoesti,ADM2,DISTRICT
SM18183,31.4864,50.3052,Berezans'ka Mis'krada,ADM2,DISTRICT
SM299600,31.3824,46.8562,Berezans'kyi,ADM2,DISTRICT
SM292331,28.1157,46.3965,Berezeni,ADM2,DISTRICT
SM299735,24.9405,49.4422,Berezhans'kyi,ADM2,DISTRICT
SM299630,30.907,47.2003,Berezivs'kyi,ADM2,DISTRICT
SM299601,32.8504,47.3079,Bereznehuvats'kyi,ADM2,DISTRICT
SM16857,93.1387,56.0345,Berezovskiy rayon,ADM2,DISTRICT
SM296636,13.7118,62.8611,Berg,ADM2,DISTRICT
SM298552,27.1892,39.1709,Bergama,ADM2,DISTRICT
SM277863,9.8,45.8333,Bergamo,ADM2,DISTRICT
SM285956,5.3395,51.3018,Bergeijk,ADM2,DISTRICT
SM301824,-74.0744,40.9598,Bergen,ADM2,DISTRICT
SM286405,5.328,60.392,Bergen,ADM2,DISTRICT
SM286034,4.6761,52.6578,Bergen,ADM2,DISTRICT
SM285896,6.0901,51.5772,Bergen,ADM2,DISTRICT
SM285967,4.2911,51.5045,Bergen op Zoom,ADM2,DISTRICT
SM289850,23.7377,46.0748,Berghin,ADM2,DISTRICT
SM249804,23.4626,17.4842,Beria,LANGUAGE,LANGUAGE
SM252217,133.15,-11.97,Beriguruk,ETHNICITY,ETHNICITY
SM252217,133.15,-11.97,Beriguruk,ETHNICITY,ETHNICITY
SM252217,131.373,-12.4752,Beriguruk,ETHNICITY,ETHNICITY
SM252217,131.373,-12.4752,Beriguruk,ETHNICITY,ETHNICITY
SM227029,138.866,-2.269,Berik,LANGUAGE,LANGUAGE
SM273983,38.7671,15.3321,Berikh,ADM2,DISTRICT
SM263868,-42.4889,-16.8634,Berilo,ADM2,DISTRICT
SM227028,142.463,-4.4169,Berinomo,LANGUAGE,LANGUAGE
SM292238,24.4379,45.2641,Berislavesti,ADM2,DISTRICT
SM260731,-57.8333,-34.9167,Berisso,ADM2,DISTRICT
SM290804,23.2313,45.7635,Beriu,ADM2,DISTRICT
SM263879,-41.7714,-15.6776,Berizal,ADM2,DISTRICT
SM4451,-2.57,34.61,Berkane,ADM2,DISTRICT
SM303119,-78.0275,39.4641,Berkeley,ADM2,DISTRICT
SM302476,-79.951,33.1977,Berkeley,ADM2,DISTRICT
SM15113,6.5675,52.1061,Berkelland (2005-),ADM2,DISTRICT
SM262302,23.15,43.2667,Berkovitsa,ADM2,DISTRICT
SM313228,18.0625,43.1197,Berkovići,ADM2:ADM3,DISTRICT
SM302340,-75.926,40.4163,Berks,ADM2,DISTRICT
SM301243,-73.2064,42.3707,Berkshire,ADM2,DISTRICT
SM12143,-77.9037,18.2129,Berkshire,ADM2,DISTRICT
SM290610,23.6565,44.9284,Berlesti,ADM2,DISTRICT
SM286348,29.0851,70.8572,Berlevåg,ADM2,DISTRICT
SM663,13.4167,52.5,Berlin,ADM1,DISTRICT
SM290045,21.4692,44.99,Berliste,ADM2,DISTRICT
SM295585,-88.6,13.5167,Berlín,ADM2,DISTRICT
SM15479,-66.4225,18.175,Bermejales Barrio,ADM2,DISTRICT
SM261190,-61.5,-24.3333,Bermejo,ADM2,DISTRICT
SM261093,-58.6667,-27,Bermejo,ADM2,DISTRICT
SM15219,6.9509,15.0825,Bermo,ADM2,DISTRICT
SM303726,-63.25,10.6667,Bermúdez,ADM2,DISTRICT
SM664,7.5956,46.8725,Bern (1979-),ADM1,DISTRICT
SM17911,7.4902,46.9253,Bern-Mittelland District,ADM2,DISTRICT
SM301834,-106.6702,35.0513,Bernalillo,ADM2,DISTRICT
SM264364,-38.57,-6.4766,Bernardino Batista,ADM2,DISTRICT
SM262756,-66.0572,-17.9981,Bernardino Bilbao,ADM2,DISTRICT
SM267045,-49.4928,-23.027,Bernardino de Campos,ADM2,DISTRICT
SM267205,-48.9861,-7.9738,Bernardo Sayão,ADM2,DISTRICT
SM262947,-44.6428,-4.6718,Bernardo do Mearim,ADM2,DISTRICT
SM285968,5.5238,51.6856,Bernheze,ADM2,DISTRICT
SM268910,10.0735,46.3349,Bernina,ADM2,DISTRICT
SM227037,8.8122,9.6704,Berom,LANGUAGE,LANGUAGE
SM250096,10.63,10.88,Berom,ETHNICITY,ETHNICITY
SM250096,10.63,10.88,Berom,ETHNICITY,ETHNICITY
SM250096,9,10,Berom,ETHNICITY,ETHNICITY
SM250096,9,10,Berom,ETHNICITY,ETHNICITY
SM371038,45.234,-21.514,Beroroha,ADM2:ADM3,DISTRICT
SM271187,14.0833,49.8333,Beroun,ADM2,DISTRICT
SM665,22.848,41.7097,Berovo,ADM1,DISTRICT
SM4427,-7.5814,33.2658,Berrechid,ADM2,DISTRICT
SM261749,140.4936,-34.2879,Berri and Barmera,ADM2,DISTRICT
SM301253,-86.4281,41.9186,Berrien,ADM2,DISTRICT
SM300381,-83.2296,31.2761,Berrien,ADM2,DISTRICT
SM261451,145.6698,-35.7519,Berrigan,ADM2,DISTRICT
SM283115,-93.2667,16.8,Berriozábal,ADM2,DISTRICT
SM666,-77.8314,25.7211,Berry Islands,ADM1,DISTRICT
SM252219,18.96,-24.84,Berseba Orlam,ETHNICITY,ETHNICITY
SM299779,29.5136,48.3639,Bershads'kyi,ADM2,DISTRICT
SM227040,34.7042,10.6474,Berta,LANGUAGE,LANGUAGE
SM291496,25.8455,45.2408,Bertea,ADM2,DISTRICT
SM292682,27.7201,44.8302,Bertestii De Jos,ADM2,DISTRICT
SM227041,25.83,14.67,Berti,LANGUAGE,LANGUAGE
SM302008,-76.9666,36.0656,Bertie,ADM2,DISTRICT
SM267046,-46.0476,-23.7487,Bertioga,ADM2,DISTRICT
SM265165,-43.7959,-7.7548,Bertolínia,ADM2,DISTRICT
SM370518,6.05,49.6111,Bertrange,ADM1:ADM2:ADM3,DISTRICT
SM263891,-40.5644,-16.9544,Bertópolis,ADM2,DISTRICT
SM13321,175.9807,-1.3296,Beru,ADM2,DISTRICT
SM303506,61,42.1667,Beruniy,ADM2,DISTRICT
SM267306,-61.7513,-4.3437,Beruri,ADM2,DISTRICT
SM291705,22.4755,47.749,Berveni,ADM2,DISTRICT
SM323805,-117.734,56.1468,Berwyn,ADM2:ADM3,DISTRICT
SM300022,33.4215,46.8377,Beryslavs'kyi,ADM2,DISTRICT
SM13438,25.2023,57.6247,Berzaines pagasts,ADM2,DISTRICT
SM290056,21.9858,44.6484,Berzasca,ADM2,DISTRICT
SM13439,25.9873,56.8302,Berzaunes pagasts,ADM2,DISTRICT
SM13440,23.3916,56.6551,Berzes pagasts,ADM2,DISTRICT
SM13441,27.4913,56.6274,Berzgales pagasts,ADM2,DISTRICT
SM13442,27.8469,56.0829,Berzinu pagasts,ADM2,DISTRICT
SM13443,27.3857,57.1122,Berzkalnes pagasts,ADM2,DISTRICT
SM290065,21.6023,45.4078,Berzovia,ADM2,DISTRICT
SM13444,27.1108,56.8432,Berzpils pagasts,ADM2,DISTRICT
SM292171,26.6224,46.4136,Berzunti,ADM2,DISTRICT
SM261159,-57.6667,-27.5,Berón de Astrada,ADM2,DISTRICT
SM371001,44.9128,-16.9036,Besalampy,ADM2:ADM3,DISTRICT
SM252221,52,58,Besermyan,ETHNICITY,ETHNICITY
SM252221,52,58,Besermyan,ETHNICITY,ETHNICITY
SM252221,52.09,58.01,Besermyan,ETHNICITY,ETHNICITY
SM252221,52.09,58.01,Besermyan,ETHNICITY,ETHNICITY
SM250111,34.36,16.81,Besharin,ETHNICITY,ETHNICITY
SM250111,34.36,16.81,Besharin,ETHNICITY,ETHNICITY
SM250111,35,20,Besharin,ETHNICITY,ETHNICITY
SM250111,35,20,Besharin,ETHNICITY,ETHNICITY
SM303471,70.6,40.4333,Beshariq,ADM2,DISTRICT
SM286645,-82.0898,8.5486,Besiko,ADM2,DISTRICT
SM227046,101.676,2.717,Besisi,LANGUAGE,LANGUAGE
SM279958,79.25,51,Beskaragayskiy,ADM2,DISTRICT
SM13175,68.75,54.6667,Beskol Audany,ADM2,DISTRICT
SM227048,16.0938,9.5923,Besme,LANGUAGE,LANGUAGE
SM298225,37.8709,37.7014,Besni,ADM2,DISTRICT
SM227049,120.379,-1.601,Besoa,LANGUAGE,LANGUAGE
SM427810,17.7833,9.043,Bessada,ADM2:ADM3,DISTRICT
SM285969,5.3833,51.5,Best,ADM2,DISTRICT
SM284392,102.5524,5.829,Besut,ADM2,DISTRICT
SM250643,37,12,Beta Israel,ETHNICITY,ETHNICITY
SM250643,37,12,Beta Israel,ETHNICITY,ETHNICITY
SM250643,37,12,Beta Israel,ETHNICITY,ETHNICITY
SM250643,38.7333,9.0333,Beta Israel,ETHNICITY,ETHNICITY
SM250643,38.7333,9.0333,Beta Israel,ETHNICITY,ETHNICITY
SM250643,38.7333,9.0333,Beta Israel,ETHNICITY,ETHNICITY
SM250643,38,12,Beta Israel,ETHNICITY,ETHNICITY
SM250643,38,12,Beta Israel,ETHNICITY,ETHNICITY
SM250643,38,12,Beta Israel,ETHNICITY,ETHNICITY
SM227053,139.255,-2.1242,Betaf,LANGUAGE,LANGUAGE
SM14168,46.5024,-19.7538,Betafo District,ADM2,DISTRICT
SM250193,1,11,Betamariba,ETHNICITY,ETHNICITY
SM270181,-75.9896,5.7357,Betania,ADM2,DISTRICT
SM227052,107.022,-6.2117,Betawi,LANGUAGE,LANGUAGE
SM250099,-5.12,5.74,Bete,ETHNICITY,ETHNICITY
SM250099,-5.12,5.74,Bete,ETHNICITY,ETHNICITY
SM250099,-7,6,Bete,ETHNICITY,ETHNICITY
SM250099,-7,6,Bete,ETHNICITY,ETHNICITY
SM227057,9.743,7.0213,Bete (Yukubenic),LANGUAGE,LANGUAGE
SM227058,9.2019,6.6204,Bete-Bendi,LANGUAGE,LANGUAGE
SM270795,-72.8708,5.8943,Beteitiva,ADM2,DISTRICT
SM268402,90.1126,26.8966,Beteni,ADM2,DISTRICT
SM250620,15.73,-24.38,Bethanie Orlam,ETHNICITY,ETHNICITY
SM12144,-77.5503,18.1537,Bethany,ADM2,DISTRICT
SM292032,21.9605,45.8251,Bethausen,ADM2,DISTRICT
SM301192,-160.5006,60.7506,Bethel,ADM2,DISTRICT
SM12145,-76.5805,17.9853,Bethel,ADM2,DISTRICT
SM12146,-77.9396,18.2918,Bethel Town,ADM2,DISTRICT
SM289740,35.3,31.65,Bethlehem,ADM1:ADM2,DISTRICT
SM227067,11.6896,3.2226,Beti (Cameroon),LANGUAGE,LANGUAGE
SM227065,-3.2863,5.265,Beti (Côte d'Ivoire),LANGUAGE,LANGUAGE
SM7516,12.37,3.25,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,12.37,3.25,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,12.37,3.25,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,10.86,1.28,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,10.86,1.28,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,10.86,1.28,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,12.04,1.47,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,12.04,1.47,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,12.04,1.47,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,11.43,-0.83,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,11.43,-0.83,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,11.43,-0.83,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,19.6,3.91,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,19.6,3.91,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,19.6,3.91,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,9.25,4.5,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,9.25,4.5,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,9.25,4.5,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,9.25,1,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,9.25,1,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,9.25,1,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,13.833,4.5,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,13.833,4.5,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,13.833,4.5,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,13.833,1,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,13.833,1,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,13.833,1,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,11,1,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,11,1,Beti-Pahuin,ETHNICITY,ETHNICITY
SM7516,11,1,Beti-Pahuin,ETHNICITY,ETHNICITY
SM263902,-44.1946,-19.9626,Betim,ADM2,DISTRICT
SM371039,44.3799,-23.722,Betioky-Sud,ADM2:ADM3,DISTRICT
SM227068,-71.4202,7.2239,Betoi-Jirara,LANGUAGE,LANGUAGE
SM16298,18.3977,3.1228,Betou,ADM2,DISTRICT
SM371033,45.9366,-23.3574,Betroka,ADM2:ADM3,DISTRICT
SM281476,47.1,-17.2,Betsiboka,ADM1:ADM2,DISTRICT
SM250101,46.22,-20.74,Betsileo,ETHNICITY,ETHNICITY
SM250101,46.22,-20.74,Betsileo,ETHNICITY,ETHNICITY
SM250101,47,-22,Betsileo,ETHNICITY,ETHNICITY
SM250101,47,-22,Betsileo,ETHNICITY,ETHNICITY
SM250586,49.21,-16.58,Betsimisaraka,ETHNICITY,ETHNICITY
SM250586,48.63,-18.99,Betsimisaraka,ETHNICITY,ETHNICITY
SM278169,145.024,43.389,Betsukai,ADM2,DISTRICT
SM227071,76.6064,11.6385,Betta Kurumba,LANGUAGE,LANGUAGE
SM370504,6.0831,49.5234,Bettembourg,ADM1:ADM2:ADM3,DISTRICT
SM370432,6.2181,49.8767,Bettendorf,ADM1:ADM2:ADM3,DISTRICT
SM277016,77.8333,21.8333,Betul,ADM2,DISTRICT
SM324025,-114.6833,53.5425,Betula Beach,ADM2:ADM3,DISTRICT
SM270507,-73.3916,6.9962,Betulia,ADM2,DISTRICT
SM270182,-75.945,6.1863,Betulia,ADM2,DISTRICT
SM370475,6.35,49.6833,Betzdorf,ADM1:ADM2:ADM3,DISTRICT
SM264939,-37.9762,-8.2879,Betânia,ADM2,DISTRICT
SM265166,-40.8592,-8.0643,Betânia do Piauí,ADM2,DISTRICT
SM285824,5.7436,51.8595,Beuningen,ADM2,DISTRICT
SM4041,25.5944,57.5231,Beverinas Novads,ADM1,DISTRICT
SM261414,116.7636,-32.1598,Beverley,ADM2,DISTRICT
SM12147,-76.7621,18.0083,Beverley Hills,ADM2,DISTRICT
SM285971,4.6547,52.4827,Beverwijk,ADM2,DISTRICT
SM302695,-98.52,29.449,Bexar,ADM2,DISTRICT
SM17220,-60.975,13.9608,Bexon,ADM2,DISTRICT
SM17221,-60.9907,13.774,Beyar,ADM2,DISTRICT
SM298315,28.9004,37.2455,Beyağaç,ADM2,DISTRICT
SM298553,28.2161,38.0824,Beydağ,ADM2,DISTRICT
SM298494,29.1693,41.1377,Beykoz,ADM2,DISTRICT
SM275001,-8.4167,8.9167,Beyla,ADM1:ADM2,DISTRICT
SM298495,28.6407,41.0032,Beylikduzu,ADM2,DISTRICT
SM298412,31.2121,39.6974,Beylikova,ADM2,DISTRICT
SM261908,47.6667,39.75,Beyləqan,ADM1:ADM2,DISTRICT
SM279966,54.8333,45.5,Beyneuskiy,ADM2,DISTRICT
SM298496,28.9677,41.0379,Beyoğlu,ADM2,DISTRICT
SM298901,31.9191,40.1469,Beypazarı,ADM2,DISTRICT
SM293206,91,53,Beyskiy rayon,ADM2,DISTRICT
SM298915,43.1651,37.5714,Beytüşşebap,ADM2,DISTRICT
SM298692,31.7305,37.6867,Beyşehir,ADM2,DISTRICT
SM250858,48.43,-18.55,Bezanozano,ETHNICITY,ETHNICITY
SM290288,25.4948,45.1371,Bezdead,ADM2,DISTRICT
SM294372,49.25,53,Bezenchukskiy rayon,ADM2,DISTRICT
SM264940,-35.8197,-8.2602,Bezerros,ADM2,DISTRICT
SM294115,30,57,Bezhanitskiy rayon,ADM2,DISTRICT
SM16858,36.5833,57.7667,Bezhetskiy Rayon,ADM2,DISTRICT
SM227080,46.0332,42.1206,Bezhta,LANGUAGE,LANGUAGE
SM16859,46.0833,42.0833,Bezhtinskiy Uchastok Rayon,ADM2,DISTRICT
SM17912,9.2735,47.3647,Bezirk Hinterland,ADM2,DISTRICT
SM17913,9.4347,47.377,Bezirk Mittelland,ADM2,DISTRICT
SM17914,9.5387,47.4417,Bezirk Vorderland,ADM2,DISTRICT
SM288867,19.1779,51.3688,Bełchatów,ADM2,DISTRICT
SM298972,39.229,41.0511,Beşikdüzü,ADM2,DISTRICT
SM298493,29.0232,41.0712,Beşiktaş,ADM2,DISTRICT
SM298184,41.3931,37.8572,Beşiri,ADM2,DISTRICT
SM252228,30.14,-30.24,Bhaca,ETHNICITY,ETHNICITY
SM11635,80.6198,17.5546,Bhadradri Kothagudem,ADM2,DISTRICT
SM277133,86.6,21,Bhadrak,ADM2,DISTRICT
SM227083,75.8707,33.2851,Bhadrawahi,LANGUAGE,LANGUAGE
SM277437,87.0667,25.2902,Bhagalpur (1999-),ADM2,DISTRICT
SM375476,71.4343,31.647,Bhakkar,ADM2:ADM3,DISTRICT
SM375294,85.4265,27.6743,Bhaktapur,ADM2:ADM3,DISTRICT
SM227088,77.8268,21.2665,Bhalay,LANGUAGE,LANGUAGE
SM227087,76.1352,33.3947,Bhalesi,DIALECT,LANGUAGE
SM283502,97.1788,24.2602,Bhamo,ADM2,DISTRICT
SM277063,80,21.1833,Bhandara (1999-),ADM2,DISTRICT
SM277192,77.29,27.21,Bharatpur,ADM2,DISTRICT
SM227095,83.1071,22.3429,Bharia,LANGUAGE,LANGUAGE
SM276840,73,21.75,Bharuch (1997-),ADM2,DISTRICT
SM227096,80.842,22.7174,Bhatola,LANGUAGE,LANGUAGE
SM227101,82.2406,19.2608,Bhatri,LANGUAGE,LANGUAGE
SM227099,76.0311,32.4022,Bhattiyali,LANGUAGE,LANGUAGE
SM276841,71.8333,21.6667,Bhavnagar (2013-),ADM2,DISTRICT
SM227104,69.31,25.482,Bhaya,LANGUAGE,LANGUAGE
SM227105,28.6681,0.2455,Bhele,LANGUAGE,LANGUAGE
SM252275,74.98,21.88,Bhil,ETHNICITY,ETHNICITY
SM252275,74.98,21.88,Bhil,ETHNICITY,ETHNICITY
SM252275,74.98,21.88,Bhil,ETHNICITY,ETHNICITY
SM252275,71.13,25.67,Bhil,ETHNICITY,ETHNICITY
SM252275,71.13,25.67,Bhil,ETHNICITY,ETHNICITY
SM252275,71.13,25.67,Bhil,ETHNICITY,ETHNICITY
SM252275,75,21,Bhil,ETHNICITY,ETHNICITY
SM252275,75,21,Bhil,ETHNICITY,ETHNICITY
SM252275,75,21,Bhil,ETHNICITY,ETHNICITY
SM252275,74,22,Bhil,ETHNICITY,ETHNICITY
SM252275,74,22,Bhil,ETHNICITY,ETHNICITY
SM252275,74,22,Bhil,ETHNICITY,ETHNICITY
SM252284,73.79,22.92,Bhil Patelia,ETHNICITY,ETHNICITY
SM227108,74.5901,22.5835,Bhilali,LANGUAGE,LANGUAGE
SM227106,73.9592,20.85,Bhili,LANGUAGE,LANGUAGE
SM277193,74.75,25.5,Bhilwara,ADM2,DISTRICT
SM375367,74.1176,33.0094,Bhimber,ADM2:ADM3,DISTRICT
SM277017,78.75,26.5,Bhind,ADM2,DISTRICT
SM11636,76.1667,28.75,Bhiwani (2016-),ADM2,DISTRICT
SM375312,87.0939,27.175,Bhojpur,ADM2:ADM3,DISTRICT
SM277438,84.445,25.3089,Bhojpur (1999-),ADM2,DISTRICT
SM227112,84.8171,26.4585,Bhojpuri,LANGUAGE,LANGUAGE
SM262226,90.8167,22.3667,Bhola,ADM2,DISTRICT
SM277018,77.4167,23.5,Bhopal,ADM2,DISTRICT
SM252304,80.29,31.65,Bhote,ETHNICITY,ETHNICITY
SM252304,80.29,31.65,Bhote,ETHNICITY,ETHNICITY
SM252304,84.36,28.63,Bhote,ETHNICITY,ETHNICITY
SM252304,84.36,28.63,Bhote,ETHNICITY,ETHNICITY
SM252304,77.5,34,Bhote,ETHNICITY,ETHNICITY
SM252304,77.5,34,Bhote,ETHNICITY,ETHNICITY
SM227116,78.5728,31.8503,Bhoti Kinnauri,LANGUAGE,LANGUAGE
SM252309,85,21,Bhuiya,ETHNICITY,ETHNICITY
SM227123,85.804,22.552,Bhumij,DIALECT,LANGUAGE
SM227125,83.1614,21.2761,Bhunjia,LANGUAGE,LANGUAGE
SM459092,142.2743,-38.2938,Bi:gwurrung,ETHNICITY,ETHNICITY
SM4401,-3.0077,6.8354,Bia East,ADM2,DISTRICT
SM4402,-3.044,6.7366,Bia West,ADM2,DISTRICT
SM227129,-15.1347,11.467,Biafada,LANGUAGE,LANGUAGE
SM250102,-15,12,Biafada,ETHNICITY,ETHNICITY
SM252325,146.67,-7.43,Biagai,ETHNICITY,ETHNICITY
SM227130,147.6533,-8.8794,Biage,LANGUAGE,LANGUAGE
SM227134,135.879,-0.9523,Biak,LANGUAGE,LANGUAGE
SM252326,135.95,-1.01,Biak,ETHNICITY,ETHNICITY
SM276535,136,-1.0333,Biak Numfor,ADM2,DISTRICT
SM4379,0.3167,7.1167,Biakoye,ADM2,DISTRICT
SM227137,1.0826,11.0382,Biali,LANGUAGE,LANGUAGE
SM262636,29.75,54,Bialynichy,ADM2,DISTRICT
SM227140,140.568,-7.3594,Bian Marind,LANGUAGE,LANGUAGE
SM227141,146.765,-7.3388,Biangai,LANGUAGE,LANGUAGE
SM227145,113.469,24.6451,Biao Mon,LANGUAGE,LANGUAGE
SM227143,111.344,25.5309,Biao-Jiao Mien,LANGUAGE,LANGUAGE
SM262576,25,52.5,Biaroza,ADM2,DISTRICT
SM263913,-43.7551,-21.6301,Bias Fortes,ADM2,DISTRICT
SM285403,8.0781,5.5594,Biase,ADM2,DISTRICT
SM252327,106.23,12.16,Biat,ETHNICITY,ETHNICITY
SM227149,110.254,1.176,Biatah Bidayuh,LANGUAGE,LANGUAGE
SM288902,23.1533,51.9722,Biała Podlaska,ADM2,DISTRICT
SM288891,23.137,52.028,Biała Podlaska (City),ADM2,DISTRICT
SM288951,20.9433,51.621,Białobrzegi,ADM2,DISTRICT
SM288822,16.0886,53.9769,Białogard,ADM2,DISTRICT
SM288689,23.5931,53.099,Białystok,ADM2,DISTRICT
SM288688,23.1565,53.1274,Białystok (City),ADM2,DISTRICT
SM273789,30.9724,28.9486,Biba,ADM2,DISTRICT
SM278180,141.8818,43.3381,Bibai,ADM2,DISTRICT
SM260367,13.14,-14.7025,Bibala,ADM2,DISTRICT
SM300392,-83.6978,32.8066,Bibb,ADM2,DISTRICT
SM300279,-87.1264,32.9986,Bibb,ADM2,DISTRICT
SM227150,117.1738,-34.9083,Bibbulman,DIALECT,LANGUAGE
SM252328,116.6,-34.09,Bibelmen,ETHNICITY,ETHNICITY
SM252328,116.6,-34.09,Bibelmen,ETHNICITY,ETHNICITY
SM252328,115.9466,-34.4187,Bibelmen,ETHNICITY,ETHNICITY
SM252328,115.9466,-34.4187,Bibelmen,ETHNICITY,ETHNICITY
SM274864,-2.3333,6.4667,Bibiani Anhwiaso Bekwai,ADM2,DISTRICT
SM305594,15.2829,44.0732,Bibinje,ADM2,DISTRICT
SM273585,-78.9052,-2.7047,Biblián,ADM2,DISTRICT
SM263924,-43.1045,-21.7324,Bicas,ADM2,DISTRICT
SM291358,26.0798,46.9489,Bicaz,ADM2,DISTRICT
SM290986,23.0147,47.4624,Bicaz,ADM2,DISTRICT
SM291369,25.8723,46.8344,Bicaz-chei,ADM2,DISTRICT
SM291380,25.9257,46.8688,Bicazu Ardelean,ADM2,DISTRICT
SM284892,8.2794,12.2339,Bichi,ADM2,DISTRICT
SM291123,24.0885,46.3667,Bichis,ADM2,DISTRICT
SM295129,107.5833,50.5833,Bichurskiy rayon,ADM2,DISTRICT
SM12148,-77.9365,18.3634,Bickersteth,ADM2,DISTRICT
SM4190,123.5639,13.3139,Bicol,ADM1,DISTRICT
SM252330,122.68,14.06,Bicolano,ETHNICITY,ETHNICITY
SM252330,124.31,13.79,Bicolano,ETHNICITY,ETHNICITY
SM276257,18.5676,47.4338,Bicske,ADM2,DISTRICT
SM275121,10.4683,1.5816,Bicurga,ADM2,DISTRICT
SM277064,75.75,18.8333,Bid (1989-),ADM2,DISTRICT
SM285040,5.9847,9.0827,Bida,ADM2,DISTRICT
SM276949,77.3333,18.0833,Bidar,ADM2,DISTRICT
SM459065,149.053,-37.3831,Bidawal,ETHNICITY,ETHNICITY
SM304906,44.7473,15.3981,Bidbadah,ADM2,DISTRICT
SM250772,24.05,17.92,Bideyat,ETHNICITY,ETHNICITY
SM227160,18.8065,11.9204,Bidiyo,LANGUAGE,LANGUAGE
SM275125,11.1491,2.163,Bidjabidjan,ADM2,DISTRICT
SM458685,143.0055,-28.3857,Bidjara,ETHNICITY,ETHNICITY
SM258814,147.1213,-24.9032,Bidjara,ETHNICITY,ETHNICITY
SM227165,147.2734,-25.1927,Bidyara,LANGUAGE,LANGUAGE
SM261106,-65,-42.4167,Biedma,ADM2,DISTRICT
SM17915,7.2617,47.1272,Biel/Bienne District,ADM2,DISTRICT
SM277884,8.0859,45.5871,Biella,ADM2,DISTRICT
SM288690,23.0067,52.7463,Bielsk,ADM2,DISTRICT
SM288738,19.1066,49.7618,Bielsko,ADM2,DISTRICT
SM288727,19.039,49.812,Bielsko-Biała,ADM2,DISTRICT
SM227168,144.816,-3.6134,Biem,LANGUAGE,LANGUAGE
SM301169,-93.056,32.3472,Bienville,ADM2,DISTRICT
SM227169,168.154,-16.6663,Bierebo,LANGUAGE,LANGUAGE
SM227171,168.232,-16.7867,Bieria,LANGUAGE,LANGUAGE
SM291708,24.5195,46.1246,Biertan,ADM2,DISTRICT
SM288746,19.1427,50.0936,Bieruń-Lędziny,ADM2,DISTRICT
SM289005,22.6445,49.3368,Bieszczady,ADM2,DISTRICT
SM227172,92.9434,24.2735,Biete,LANGUAGE,LANGUAGE
SM12149,-78.1672,18.2332,Big Bridge,ADM2,DISTRICT
SM303306,-107.9952,44.5268,Big Horn,ADM2,DISTRICT
SM301633,-107.4897,45.4235,Big Horn,ADM2,DISTRICT
SM227177,167.239,-16.0903,Big Nambas,LANGUAGE,LANGUAGE
SM459129,146.7589,-42.4251,Big River Tasmanian,ETHNICITY,ETHNICITY
SM301393,-96.4109,45.4261,Big Stone,ADM2,DISTRICT
SM323945,-112.7544,52.0336,Big Valley,ADM2:ADM3,DISTRICT
SM227174,130.272,-1.9957,Biga,LANGUAGE,LANGUAGE
SM298267,27.2084,40.2692,Biga,ADM2,DISTRICT
SM298174,28.1384,39.4025,Bigadiç,ADM2,DISTRICT
SM227173,149.515,-28.266,Bigambal,LANGUAGE,LANGUAGE
SM252335,151.79,-27.55,Bigambal,ETHNICITY,ETHNICITY
SM252335,151.79,-27.55,Bigambal,ETHNICITY,ETHNICITY
SM252335,150.1748,-28.1414,Bigambal,ETHNICITY,ETHNICITY
SM252335,150.1748,-28.1414,Bigambal,ETHNICITY,ETHNICITY
SM275089,-15.7534,12.3538,Bigene,ADM2,DISTRICT
SM283552,97.1767,45.7015,Biger,ADM2,DISTRICT
SM296084,-55.5099,5.4055,Bigi Poika,ADM2,DISTRICT
SM295263,-16.3694,12.8816,Bignona,ADM2,DISTRICT
SM266362,-48.7082,-27.4117,Biguaçu,ADM2,DISTRICT
SM12150,-77.719,17.9334,Bigwoods,ADM2,DISTRICT
SM227182,108.0167,12.4833,Bih,LANGUAGE,LANGUAGE
SM668,85.75,25.75,Bihar (2000-),ADM1,DISTRICT
SM299226,31.459,-2.766,Biharamulo,ADM2,DISTRICT
SM252336,86,25,Bihari,ETHNICITY,ETHNICITY
SM669,22.3333,47,Bihor,ADM1,DISTRICT
SM9507,70.509,34.4428,Bihsud,ADM2,DISTRICT
SM277465,80.7761,18.8432,"Bijapur, CH",ADM2,DISTRICT
SM276950,75.9167,16.6667,"Bijapur, KA (1997-)",ADM2,DISTRICT
SM277548,47.6,35.8667,Bijar,ADM2,DISTRICT
SM313202,19.1508,44.7658,Bijeljina,ADM2:ADM3,DISTRICT
SM670,19.7433,43.0447,Bijelo Polje,ADM1,DISTRICT
SM269452,105.3009,27.2903,Bijie,ADM2,DISTRICT
SM277276,78.5167,29.4167,Bijnor,ADM2,DISTRICT
SM250103,-16.15,11.1,Bijogo,ETHNICITY,ETHNICITY
SM227192,84.1907,22.5904,Bijori,LANGUAGE,LANGUAGE
SM277194,73.1667,28.1667,Bikaner,ADM2,DISTRICT
SM4095,170.103,12.2511,Bikar Atoll,ADM1,DISTRICT
SM227195,142.945,-4.86,Bikaru-Bragge,LANGUAGE,LANGUAGE
SM13445,26.8647,55.9704,Bikernieku pagasts,ADM2,DISTRICT
SM4076,165.3762,11.5836,Bikini Atoll,ADM1,DISTRICT
SM252338,165.55,11.62,Bikini-Ebon,ETHNICITY,ETHNICITY
SM252338,165.55,11.62,Bikini-Ebon,ETHNICITY,ETHNICITY
SM252338,165.29,11.7,Bikini-Ebon,ETHNICITY,ETHNICITY
SM252338,165.29,11.7,Bikini-Ebon,ETHNICITY,ETHNICITY
SM252339,171.13,7.09,Bikini-Ratak,ETHNICITY,ETHNICITY
SM16860,134.25,47,Bikinskiy Rayon,ADM2,DISTRICT
SM305259,31.9316,-20.1375,Bikita,ADM2,DISTRICT
SM13446,22.9365,56.6727,Bikstu pagasts,ADM2,DISTRICT
SM252340,149.73,-23.47,Bikulbura,ETHNICITY,ETHNICITY
SM227206,10.5333,6.75,Bikya,LANGUAGE,LANGUAGE
SM227209,28.7626,1.1684,Bila,LANGUAGE,LANGUAGE
SM18184,30.0833,49.75,Bila Tserkva Raion,ADM2,DISTRICT
SM252341,124.69,5.45,Bilaan,ETHNICITY,ETHNICITY
SM252341,124.69,5.45,Bilaan,ETHNICITY,ETHNICITY
SM252341,125,6,Bilaan,ETHNICITY,ETHNICITY
SM252341,125,6,Bilaan,ETHNICITY,ETHNICITY
SM267047,-50.4786,-21.4208,Bilac,ADM2,DISTRICT
SM304949,44.2514,15.0351,Bilad Ar Rus,ADM2,DISTRICT
SM304916,43.6078,14.8382,Bilad At Ta'am,ADM2,DISTRICT
SM227211,145.559,-4.9312,Bilakura,LANGUAGE,LANGUAGE
SM277466,82.1333,22.3833,"Bilaspur, CH (2012-)",ADM2,DISTRICT
SM276865,76.75,31.3333,"Bilaspur, HP",ADM2,DISTRICT
SM227207,76.6739,31.3842,Bilaspuri,LANGUAGE,LANGUAGE
SM227215,123.307,-10.6503,Bilba,LANGUAGE,LANGUAGE
SM273741,31.5379,30.4153,Bilbays,ADM2,DISTRICT
SM290722,25.4999,47.0562,Bilbor,ADM2,DISTRICT
SM291835,25.7546,47.9183,Bilca,ADM2,DISTRICT
SM290299,25.8062,44.7477,Bilciuresti,ADM2,DISTRICT
SM227218,11.1907,8.9005,Bile,LANGUAGE,LANGUAGE
SM671,30.1667,40,Bilecik,ADM1,DISTRICT
SM277573,47.9667,39.3667,Bilehsavar,ADM2,DISTRICT
SM461503,150.8,-5.75,Bileki,ETHNICITY,ETHNICITY
SM283892,33.1689,-25.0161,Bilene,ADM2,DISTRICT
SM313229,18.3608,42.9936,Bileća,ADM2:ADM3,DISTRICT
SM18185,30.3577,46.1968,Bilhorod-Dnistrovskyy Raion,ADM2,DISTRICT
SM299633,30.2089,46.484,Bilia‹vs'kyi,ADM2,DISTRICT
SM227214,145.764,-5.265,Bilibil,LANGUAGE,LANGUAGE
SM292785,164,67,Bilibinskiy rayon,ADM2,DISTRICT
SM305433,15.8759,43.7883,Bilice,ADM2,DISTRICT
SM227226,38.3917,15.7833,Bilin,LANGUAGE,LANGUAGE
SM250105,38.75,15.75,Bilin,ETHNICITY,ETHNICITY
SM250105,38.75,15.75,Bilin,ETHNICITY,ETHNICITY
SM250105,36,16,Bilin,ETHNICITY,ETHNICITY
SM250105,36,16,Bilin,ETHNICITY,ETHNICITY
SM227221,130.534,-16.8688,Bilinarra,DIALECT,LANGUAGE
SM458879,130.745,-16.9669,Bilinarra,ETHNICITY,ETHNICITY
SM672,124.4833,11.4833,Biliran,ADM1:ADM2,DISTRICT
SM305390,18.7426,45.6047,Bilje,ADM2,DISTRICT
SM252345,37.84,25.71,Billi,ETHNICITY,ETHNICITY
SM302063,-103.3764,47.0234,Billings,ADM2,DISTRICT
SM284748,11.1242,9.8158,Billiri,ADM2,DISTRICT
SM252346,107.91,-2.79,Billiton,ETHNICITY,ETHNICITY
SM271752,8.9684,55.7308,Billund (2007-),ADM2,DISTRICT
SM284597,13.25,20.5,Bilma,ADM2,DISTRICT
SM227229,13.0665,19.1,Bilma Kanuri,LANGUAGE,LANGUAGE
SM299445,26.4155,49.9985,Bilohirs'kyi,ADM2,DISTRICT
SM299895,34.5568,45.063,Bilohirs'kyi,ADM2,DISTRICT
SM299489,30.1199,49.8042,Bilotserkivs'ka,ADM2,DISTRICT
SM227231,-89.1951,31.32,Biloxi,LANGUAGE,LANGUAGE
SM252347,-88.99,30.88,Biloxi,ETHNICITY,ETHNICITY
SM300033,32.444,46.6258,Bilozers'kyi,ADM2,DISTRICT
SM273640,31.3865,31.3407,Bilqas,ADM2,DISTRICT
SM13447,26.0594,57.4859,Bilskas pagasts,ADM2,DISTRICT
SM10275,20.8907,14.5845,Biltine (2018-),ADM2,DISTRICT
SM227233,156.663,-7.9239,Bilua,LANGUAGE,LANGUAGE
SM295314,156.6867,-7.7932,Bilua,ADM2,DISTRICT
SM461531,156.7,-7.9,Bilua,ETHNICITY,ETHNICITY
SM227232,152.334,-4.3973,Bilur,LANGUAGE,LANGUAGE
SM261909,48.4167,39.5,Biləsuvar,ADM1:ADM2,DISTRICT
SM227237,118.445,-8.5738,Bima,LANGUAGE,LANGUAGE
SM276482,118.6167,-8.6,Bima,ADM2,DISTRICT
SM296849,1.2833,9.75,Bimah,ADM2,DISTRICT
SM252349,118.02,-8.54,Bimanese,ETHNICITY,ETHNICITY
SM268559,18.5096,4.3243,Bimbo,ADM2,DISTRICT
SM227239,142.04,-5.3873,Bimin,LANGUAGE,LANGUAGE
SM673,-79.2889,25.7044,Biminis,ADM1,DISTRICT
SM227241,0.0612,10.4575,Bimoba,LANGUAGE,LANGUAGE
SM227242,8.1973,10.2868,Bina (Nigeria),LANGUAGE,LANGUAGE
SM227243,149.661,-10.2667,Bina (Papua New Guinea),LANGUAGE,LANGUAGE
SM227246,148.7231,-10.1339,Binahari-Ma,LANGUAGE,LANGUAGE
SM227247,148.835,-10.0874,Binahari-Neme,LANGUAGE,LANGUAGE
SM227249,147.884,-8.3743,Binandere,LANGUAGE,LANGUAGE
SM252350,147.68,-8.04,Binandere,ETHNICITY,ETHNICITY
SM458777,136.0457,-16.3614,Binbinka,ETHNICITY,ETHNICITY
SM227252,134.7866,-16.5521,Binbinka,DIALECT,LANGUAGE
SM286096,12.3775,65.0878,Bindal,ADM2,DISTRICT
SM458431,147.1106,-19.5636,Bindal,ETHNICITY,ETHNICITY
SM227253,147.2764,-19.5035,Bindal-Cunningham,LANGUAGE,LANGUAGE
SM227254,147.2695,-19.6782,Bindal-Gorton,LANGUAGE,LANGUAGE
SM227255,147.0028,-19.409,Bindal-Mount Elliot,LANGUAGE,LANGUAGE
SM284926,7.832,12.7108,Bindawa,ADM2,DISTRICT
SM283576,110.6001,48.6118,Binder,ADM2,DISTRICT
SM459038,140.4916,-36.4229,Bindjali / Bodaruwitj,ETHNICITY,ETHNICITY
SM252352,116.2,-32.74,Bindjareb,ETHNICITY,ETHNICITY
SM252352,116.2,-32.74,Bindjareb,ETHNICITY,ETHNICITY
SM252352,115.9166,-32.8984,Bindjareb,ETHNICITY,ETHNICITY
SM252352,115.9166,-32.8984,Bindjareb,ETHNICITY,ETHNICITY
SM252354,131.04,-21.84,Bindubi,ETHNICITY,ETHNICITY
SM252354,131.04,-21.84,Bindubi,ETHNICITY,ETHNICITY
SM252354,131.04,-21.84,Bindubi,ETHNICITY,ETHNICITY
SM252354,129.44,-22.86,Bindubi,ETHNICITY,ETHNICITY
SM252354,129.44,-22.86,Bindubi,ETHNICITY,ETHNICITY
SM252354,129.44,-22.86,Bindubi,ETHNICITY,ETHNICITY
SM252354,127.5817,-23.3701,Bindubi,ETHNICITY,ETHNICITY
SM252354,127.5817,-23.3701,Bindubi,ETHNICITY,ETHNICITY
SM252354,127.5817,-23.3701,Bindubi,ETHNICITY,ETHNICITY
SM305237,31.303,-17.2123,Bindura,ADM2,DISTRICT
SM11383,-0.3084,10.9721,Binduri District,ADM2,DISTRICT
SM227257,142.893,-9.0888,Bine,LANGUAGE,LANGUAGE
SM252355,143.01,-8.98,Bine,ETHNICITY,ETHNICITY
SM252355,142.7,-8.82,Bine,ETHNICITY,ETHNICITY
SM305266,27.7009,-17.8046,Binga,ADM2,DISTRICT
SM252356,18.2,4.22,Binga,ETHNICITY,ETHNICITY
SM252357,146.76,-21.49,Bingabura,ETHNICITY,ETHNICITY
SM300584,-112.3981,43.2165,Bingham,ADM2,DISTRICT
SM674,40.8333,39.0833,Bingöl,ADM1,DISTRICT
SM227262,5.7043,6.407,Bini,LANGUAGE,LANGUAGE
SM255544,133.5566,-12.578,Bininj,ETHNICITY,ETHNICITY
SM231918,133.358,-12.3398,Bininj Kun-Wok,LANGUAGE,LANGUAGE
SM227265,21.7,-6.7,Binji,LANGUAGE,LANGUAGE
SM285219,4.8329,13.2087,Binji,ADM2,DISTRICT
SM227268,120.876,17.5972,Binongan Itneg,LANGUAGE,LANGUAGE
SM280914,35.4114,33.1596,Bint Jbayl,ADM2,DISTRICT
SM276433,104.6194,0.95,Bintan,ADM2,DISTRICT
SM227271,123.486,0.7507,Bintauna,LANGUAGE,LANGUAGE
SM227272,113.634,3.867,Bintulu,LANGUAGE,LANGUAGE
SM284378,113.25,2.8333,Bintulu,ADM2,DISTRICT
SM227274,146.102,-6.2207,Binumarien,LANGUAGE,LANGUAGE
SM252360,146.47,-6.99,Binumarien,ETHNICITY,ETHNICITY
SM269291,117.8364,37.605,Binzhou,ADM2,DISTRICT
SM269107,-71.8955,-37.5951,Biobío,ADM2,DISTRICT
SM276028,15.4333,43.9333,Biograd na Moru,ADM2,DISTRICT
SM680,8.7833,3.6833,Bioko Norte,ADM1:ADMX,DISTRICT
SM681,8.6667,3.4167,Bioko Sur,ADM1:ADMX,DISTRICT
SM682,-15.8433,11.8828,Biombo,ADM1,DISTRICT
SM227275,146.398,-2.1112,Bipi,LANGUAGE,LANGUAGE
SM263935,-45.5485,-18.7623,Biquinhas,ADM2,DISTRICT
SM297945,10.1065,34.7172,Bir Ali Ben Khélifa,ADM2,DISTRICT
SM297953,9.2848,34.9054,Bir El Hfay,ADM2,DISTRICT
SM298002,10.4605,33.1478,Bir Lahmar,ADM2,DISTRICT
SM298037,9.9759,36.5319,Bir Mchergua,ADM2,DISTRICT
SM283985,-8.1054,25.6166,Bir Moghreïn,ADM2,DISTRICT
SM273867,33.0902,30.933,Bir al-'Abd,ADM2,DISTRICT
SM227278,160.52,-9.7372,Birao,LANGUAGE,LANGUAGE
SM295369,160.7419,-9.8117,Birao,ADM2,DISTRICT
SM268578,22.5352,10.0032,Birao,ADM2,DISTRICT
SM277370,87.5833,24,Birbhum,ADM2,DISTRICT
SM324106,-114.3687,53.9558,Birch Cove,ADM2:ADM3,DISTRICT
SM323811,-118.0799,55.7232,Birch Hills County,ADM2:ADM3,DISTRICT
SM323978,-114.1158,52.3601,Birchcliff,ADM2:ADM3,DISTRICT
SM290558,22.1685,45.9699,Birchis,ADM2,DISTRICT
SM252362,59.24,25.71,Birdi,ETHNICITY,ETHNICITY
SM298867,37.9966,37.0339,Birecik,ADM2,DISTRICT
SM276285,96.5833,5.0833,Bireuen,ADM2,DISTRICT
SM227281,19.8182,12.0228,Birgit,LANGUAGE,LANGUAGE
SM283444,14.5271,35.8859,Birgu,ADM1:ADM2,DISTRICT
SM227283,85.5611,23.585,Birhor,LANGUAGE,LANGUAGE
SM252364,84.38,23.41,Birhor,ETHNICITY,ETHNICITY
SM227284,146.67,-20.1494,Biri,LANGUAGE,LANGUAGE
SM252365,24.08,6.41,Biri,ETHNICITY,ETHNICITY
SM458426,147.9886,-20.5081,Biri^,ETHNICITY,ETHNICITY
SM250109,-2.38,9.61,Birifor,ETHNICITY,ETHNICITY
SM250109,-2.38,9.61,Birifor,ETHNICITY,ETHNICITY
SM250109,-3,10,Birifor,ETHNICITY,ETHNICITY
SM250109,-3,10,Birifor,ETHNICITY,ETHNICITY
SM267048,-50.3373,-21.2409,Birigui,ADM2,DISTRICT
SM458440,152.8589,-29.048,Birihn,ETHNICITY,ETHNICITY
SM293465,90.75,57.25,Birilyusskiy rayon,ADM2,DISTRICT
SM11384,-0.9833,5.9167,Birim Central,ADM2,DISTRICT
SM11385,-0.9956,6.3386,Birim North (2008-),ADM2,DISTRICT
SM11386,-1.0167,5.9,Birim South (2012-),ADM2,DISTRICT
SM284802,10.0747,12.8332,Biriniwa,ADM2,DISTRICT
SM227287,137.68,-2.9204,Biritai,LANGUAGE,LANGUAGE
SM267049,-46.0194,-23.6201,Biritiba Mirim,ADM2,DISTRICT
SM267719,-38.7893,-11.6708,Biritinga,ADM2,DISTRICT
SM273976,31.0853,30.6486,Birkat as-Sab',ADM2,DISTRICT
SM227289,24.83,12.5,Birked,LANGUAGE,LANGUAGE
SM286308,8.2327,58.3348,Birkenes,ADM2,DISTRICT
SM295272,-15.6904,14.0685,Birkilane,ADM2,DISTRICT
SM283391,14.4653,35.8972,Birkirkara,ADM1:ADM2,DISTRICT
SM274636,-1.8982,52.4805,Birmingham,ADM1:ADM2,DISTRICT
SM13322,-171.5167,-3.5833,Birnie,ADM2,DISTRICT
SM284809,6.6551,10.8261,Birnin-G,ADM2,DISTRICT
SM285282,6.8912,12.4253,Birnin-Magaji/Kiyaw,ADM2,DISTRICT
SM284948,4.2627,12.4583,BirninKe,ADM2,DISTRICT
SM284803,9.4937,11.4902,BirninKu,ADM2,DISTRICT
SM295071,132.75,48.5,Birobidzhanskiy rayon,ADM2,DISTRICT
SM458208,152.134,-31.647,Birrbay,ETHNICITY,ETHNICITY
SM231191,148.93,-37.05,Birrdhawal,LANGUAGE,LANGUAGE
SM227291,27.0842,5.3328,Birri,LANGUAGE,LANGUAGE
SM227291,27.0842,5.3328,Birri,LANGUAGE,LANGUAGE
SM227291,24.6,5.33,Birri,LANGUAGE,LANGUAGE
SM227291,24.6,5.33,Birri,LANGUAGE,LANGUAGE
SM16861,55.5,55.3333,Birskiy Rayon,ADM2,DISTRICT
SM303817,-67.5168,7.8448,Biruaca,ADM2,DISTRICT
SM227293,28.33,-22.17,Birwa,LANGUAGE,LANGUAGE
SM13448,24.7792,56.6264,Birzgales pagasts,ADM2,DISTRICT
SM281327,24.0333,54.6167,Birštono,ADM2,DISTRICT
SM283418,14.5228,35.8222,Birżebbuġa,ADM1:ADM2,DISTRICT
SM281343,24.75,56.2,Biržų,ADM2,DISTRICT
SM250411,31.64,-12.4,Bisa,ETHNICITY,ETHNICITY
SM252371,115.51,4.72,Bisayas,ETHNICITY,ETHNICITY
SM227300,6.5165,5.403,Biseni,LANGUAGE,LANGUAGE
SM17774,42.5333,19.8,Bishah,ADM2,DISTRICT
SM227301,92.0157,24.4578,Bishnupriya Manipuri,LANGUAGE,LANGUAGE
SM277068,93.78,24.6077,Bishnupur,ADM2,DISTRICT
SM17222,-60.985,14.0002,Bishop'S Gap,ADM2,DISTRICT
SM227303,9.794,6.6725,Bishuo,LANGUAGE,LANGUAGE
SM227306,143.058,-4.4624,Bisis,LANGUAGE,LANGUAGE
SM252375,142.91,-4.57,Bisis,ETHNICITY,ETHNICITY
SM684,5.4167,34.6667,Biskra,ADM1,DISTRICT
SM457418,6,34.6667,Biskra District,ADM2,DISTRICT
SM305441,16.2275,44.0096,Biskupija,ADM2,DISTRICT
SM227307,166.89,-15.4,Bislama,LANGUAGE,LANGUAGE
SM298319,40.7772,37.8653,Bismil,ADM2,DISTRICT
SM289907,26.7059,45.5434,Bisoca,ADM2,DISTRICT
SM227310,143.213,-4.7776,Bisorio,LANGUAGE,LANGUAGE
SM261990,29.7117,-3.6269,Bisoro,ADM2,DISTRICT
SM227313,-1.6733,10.4257,Bissa,LANGUAGE,LANGUAGE
SM250112,0,12,Bissa,ETHNICITY,ETHNICITY
SM275085,-15.6046,11.8898,Bissau,ADM2,DISTRICT
SM685,-15.591,11.8539,Bissau,ADM1,DISTRICT
SM17223,-60.9749,14.0226,Bissee,ADM2,DISTRICT
SM370529,6.0694,49.7875,Bissen,ADM1:ADM2:ADM3,DISTRICT
SM275100,-15.505,12.204,Bissora,ADM2,DISTRICT
SM290987,24.1929,47.8686,Bistra,ADM2,DISTRICT
SM289776,23.1015,46.3786,Bistra,ADM2,DISTRICT
SM305651,15.8509,45.9066,Bistra,ADM2,DISTRICT
SM290328,23.5229,43.9016,Bistret,ADM2,DISTRICT
SM296209,15.6473,46.06,Bistrica ob Sotli,ADM1:ADM2,DISTRICT
SM292501,24.4912,47.135,Bistrita,ADM2,DISTRICT
SM292512,24.8343,47.1903,Bistrita Bargaului,ADM2,DISTRICT
SM686,24.5833,47.1667,Bistrița-Năsăud,ADM1,DISTRICT
SM227315,99.9862,20.8542,Bisu,LANGUAGE,LANGUAGE
SM11637,93.1453,26.7246,Biswanath,ADM2,DISTRICT
SM227327,101.916,21.3474,Bit,LANGUAGE,LANGUAGE
SM227320,11.3072,6.874,Bitare,LANGUAGE,LANGUAGE
SM275132,9.6235,1.4219,Bitica,ADM2,DISTRICT
SM296836,18.2126,11.9811,Bitkine,ADM2,DISTRICT
SM687,42.3913,38.342,Bitlis,ADM1,DISTRICT
SM12151,-76.6663,17.9736,Bito,ADM2,DISTRICT
SM688,21.3167,41.0167,Bitola,ADM1,DISTRICT
SM250859,35.09,-22.65,Bitonga,ETHNICITY,ETHNICITY
SM323789,-113.0452,53.0049,Bittern Lake,ADM2:ADM3,DISTRICT
SM253384,-112.71,48.37,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-112.71,48.37,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-112.71,48.37,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-112.71,48.37,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-112.71,48.37,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-111.5,46,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-111.5,46,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-111.5,46,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-111.5,46,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-111.5,46,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-113,46,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-113,46,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-113,46,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-113,46,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-113,46,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-113.26,46.28,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-113.26,46.28,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-113.26,46.28,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-113.26,46.28,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-113.26,46.28,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-114,47.5,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-114,47.5,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-114,47.5,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-114,47.5,Bitterroot Salish,ETHNICITY,ETHNICITY
SM253384,-114,47.5,Bitterroot Salish,ETHNICITY,ETHNICITY
SM270049,-74.552,4.829,Bituima,ADM2,DISTRICT
SM276617,125.15,1.4833,Bitung,ADM2,DISTRICT
SM227329,142.71,-8.5184,Bitur,LANGUAGE,LANGUAGE
SM264743,-51.545,-26.1854,Bituruna,ADM2,DISTRICT
SM285384,12.0885,10.8174,Biu,ADM2,DISTRICT
SM290871,27.4273,47.5299,Bivolari,ADM2,DISTRICT
SM459100,141.7857,-36.3059,Biwadjali,ETHNICITY,ETHNICITY
SM227337,143.873,-4.3943,Biwat,LANGUAGE,LANGUAGE
SM370476,6.3747,49.7028,Biwer,ADM1:ADM2:ADM3,DISTRICT
SM291706,23.3734,47.9203,Bixad,ADM2,DISTRICT
SM273834,31.2404,31.3245,Biyala,ADM2,DISTRICT
SM227138,34.3,-5.9,Biyanjiida,LANGUAGE,LANGUAGE
SM459296,146.2353,-18.5775,Biyay,ETHNICITY,ETHNICITY
SM459206,146.1694,-18.348,Biyay,ETHNICITY,ETHNICITY
SM227340,101.652,22.511,Biyo,LANGUAGE,LANGUAGE
SM227341,145.348,-5.7993,Biyom,LANGUAGE,LANGUAGE
SM16862,85.5,52.5,Biyskiy Rayon,ADM2,DISTRICT
SM279042,134.2351,34.795,Bizen,ADM2,DISTRICT
SM689,9.5833,37.0833,Bizerte,ADM1,DISTRICT
SM298055,9.8324,37.3084,Bizerte Nord,ADM2,DISTRICT
SM298061,9.6792,37.2553,Bizerte Sud,ADM2,DISTRICT
SM294241,54.25,53.75,Bizhbulyakskiy rayon,ADM2,DISTRICT
SM305396,18.4567,45.5924,Bizovac,ADM2,DISTRICT
SM667,17.3031,-12.3499,Bié,ADM1,DISTRICT
SM303998,106.8426,10.9575,Biên Hòa,ADM2,DISTRICT
SM288908,22.7951,50.5858,Biłgoraj,ADM2,DISTRICT
SM683,74.6,42.8667,Biškek,ADM1,DISTRICT
SM9827,90.5326,27.4124,Bjakteng,ADM2,DISTRICT
SM275948,16.8452,45.9017,Bjelovar,ADM2,DISTRICT
SM690,17,45.6667,Bjelovarska-Bilogorska,ADM1,DISTRICT
SM286128,6.0813,58.6305,Bjerkreim,ADM2,DISTRICT
SM15293,5.6965,60.2175,Bjornafjorden,ADM2,DISTRICT
SM17908,19.0064,74.4435,Bjornoya,ADM2,DISTRICT
SM296526,19,63.9667,Bjurholm,ADM2,DISTRICT
SM296455,12.9654,56.0541,Bjuv,ADM2,DISTRICT
SM283373,-5.7678,12.9237,Bla,ADM2,DISTRICT
SM227345,159.419,-8.1506,Blablanga,LANGUAGE,LANGUAGE
SM17224,-60.9827,13.7433,Black Bay,ADM2,DISTRICT
SM323916,-114.23,50.6882,Black Diamond,ADM2:ADM3,DISTRICT
SM300850,-92.3088,42.4701,Black Hawk,ADM2,DISTRICT
SM12152,-76.5976,18.2179,Black Hill,ADM2,DISTRICT
SM17225,-60.9881,14.0046,Black Mallet,ADM2,DISTRICT
SM691,-76.3956,24.09,Black Point,ADM1,DISTRICT
SM12153,-77.8357,18.0317,Black River,ADM2,DISTRICT
SM692,57.4167,-20.3333,Black River,ADM1,DISTRICT
SM261664,145.9728,-24.788,Blackall Tambo,ADM2,DISTRICT
SM274647,-2.4667,53.6667,Blackburn with Darwen,ADM1:ADM2,DISTRICT
SM323979,-113.784,52.3865,Blackfalds,ADM2:ADM3,DISTRICT
SM252382,-111.33,51.09,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-111.33,51.09,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-111.33,51.09,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-111.33,51.09,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-100,51,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-100,51,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-100,51,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-100,51,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-112,51,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-112,51,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-112,51,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-112,51,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-110.76,51.01,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-110.76,51.01,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-110.76,51.01,Blackfoot,ETHNICITY,ETHNICITY
SM252382,-110.76,51.01,Blackfoot,ETHNICITY,ETHNICITY
SM300735,-85.3248,40.4736,Blackford,ADM2,DISTRICT
SM274658,-3.0333,53.8333,Blackpool,ADM1:ADM2,DISTRICT
SM12154,-77.0425,18.2736,Blackstonedge,ADM2,DISTRICT
SM261462,150.8625,-33.7402,Blacktown,ADM2,DISTRICT
SM285902,5.2167,51.35,Bladel,ADM2,DISTRICT
SM302019,-78.5632,34.6143,Bladen,ADM2,DISTRICT
SM274756,-3.1667,51.75,Blaenau Gwent,ADM1:ADM2,DISTRICT
SM227354,124.251,-8.3179,Blagar,LANGUAGE,LANGUAGE
SM292258,27.997,46.1413,Blagesti,ADM2,DISTRICT
SM292086,26.6484,46.6867,Blagesti,ADM2,DISTRICT
SM262295,23.1667,42.0167,Blagoevgrad,ADM2,DISTRICT
SM693,23.45,41.7333,Blagoevgrad,ADM1,DISTRICT
SM16863,55,54.6667,Blagovarskiy Rayon,ADM2,DISTRICT
SM293233,127.6,50.4,Blagoveshchensk,ADM2,DISTRICT
SM294244,56.0833,55.0833,Blagoveshchenskiy rayon,ADM2,DISTRICT
SM293023,80,52.8333,Blagoveshchenskiy rayon,ADM2,DISTRICT
SM293234,127.5,50.75,Blagoveshchenskiy rayon,ADM2,DISTRICT
SM302227,-98.4335,35.8753,Blaine,ADM2,DISTRICT
SM301722,-99.9769,41.9128,Blaine,ADM2,DISTRICT
SM301644,-108.9587,48.4328,Blaine,ADM2,DISTRICT
SM300585,-113.9804,43.4123,Blaine,ADM2,DISTRICT
SM302349,-78.3486,40.481,Blair,ADM2,DISTRICT
SM289777,23.9104,46.1508,Blaj,ADM2,DISTRICT
SM289918,26.8172,45.2996,Blajani,ADM2,DISTRICT
SM291709,24.3411,46.2244,Blajel,ADM2,DISTRICT
SM290737,22.9034,46.2549,Blajeni,ADM2,DISTRICT
SM17226,-60.9463,13.8062,Blanchard,ADM2,DISTRICT
SM302706,-98.3999,30.2664,Blanco,ADM2,DISTRICT
SM302970,-81.1303,37.134,Bland,ADM2,DISTRICT
SM261473,146.9272,-33.919,Bland,ADM2,DISTRICT
SM289778,23.3854,45.9815,Blandiana,ADM2,DISTRICT
SM227356,99.7405,22.0092,Blang,LANGUAGE,LANGUAGE
SM271206,16.6436,49.374,Blansko,ADM2,DISTRICT
SM694,34.9396,-15.6473,Blantyre,ADM1:ADM2,DISTRICT
SM285972,5.2683,52.2855,Blaricum,ADM2,DISTRICT
SM11600,-20.3333,64.4167,Blaskogabyggd,ADM2,DISTRICT
SM305633,16.7967,42.9293,Blato,ADM2,DISTRICT
SM296093,-55.1252,5.8745,Blauwgrond,ADM2,DISTRICT
SM261484,149.1305,-33.6165,Blayney,ADM2,DISTRICT
SM300403,-83.3278,32.4344,Bleckley,ADM2,DISTRICT
SM302577,-85.2052,35.5964,Bledsoe,ADM2,DISTRICT
SM13992,-7.9556,4.7548,Bleebo District,ADM2,DISTRICT
SM291900,25.4527,44.3032,Blejesti,ADM2,DISTRICT
SM291497,26.0188,44.9826,Blejoi,ADM2,DISTRICT
SM695,15.3333,56.3333,Blekinge,ADM1,DISTRICT
SM268975,8.9461,46.4761,Blenio,ADM2,DISTRICT
SM696,3,36.5833,Blida,ADM1,DISTRICT
SM457419,2.5,36.5833,Blida District,ADM2,DISTRICT
SM13449,22.7457,56.6432,Blidenes pagasts,ADM2,DISTRICT
SM276371,112.25,-8.1333,Blitar,ADM2,DISTRICT
SM11601,-20.0919,65.6461,Bloenduosbaer,ADM2,DISTRICT
SM296287,14.5066,45.7856,Bloke,ADM1:ADM2,DISTRICT
SM13450,25.7402,57.4469,Blomes pagasts,ADM2,DISTRICT
SM13451,27.8583,56.6417,Blontu pagasts,ADM2,DISTRICT
SM276333,111.3833,-7.0667,Blora,ADM2,DISTRICT
SM302588,-83.9255,35.6872,Blount,ADM2,DISTRICT
SM300290,-86.5674,33.9809,Blount,ADM2,DISTRICT
SM4219,-63.0916,18.177,Blowing Point,ADM1,DISTRICT
SM12155,-76.6477,17.9958,Bloxborough,ADM2,DISTRICT
SM261874,9.8245,47.1514,Bludenz,ADM2,DISTRICT
SM301404,-94.067,44.0346,Blue Earth,ADM2,DISTRICT
SM12156,-77.4284,18.071,Blue Mountain,ADM2,DISTRICT
SM261495,150.4129,-33.632,Blue Mountains,ADM2,DISTRICT
SM697,34.1,11.15,Blue Nile,ADM1,DISTRICT
SM17896,34,12.5,Blue Nile Province,ADM2,DISTRICT
SM285488,-83.75,12,Bluefields,ADM2,DISTRICT
SM12157,-78.004,18.1425,Bluefields,ADM2,DISTRICT
SM266363,-49.1023,-26.8742,Blumenau,ADM2,DISTRICT
SM295492,-11.6734,7.9888,Bo,ADM1:ADM2,DISTRICT
SM227577,141.45,-4.3211,Bo (Papua New Guinea),LANGUAGE,LANGUAGE
SM297239,101.1895,19.0899,Bo Klue,ADM2,DISTRICT
SM296962,99.4499,14.3872,Bo Phloi,ADM2,DISTRICT
SM297681,102.5657,12.5582,Bo Rai,ADM2,DISTRICT
SM296918,101.5086,13.2423,Bo Thong,ADM2,DISTRICT
SM303452,69.1667,40.75,Bo'ka,ADM2,DISTRICT
SM303453,70,41.6667,Bo'stonliq,ADM2,DISTRICT
SM303402,71.9167,40.6667,Bo'zsuv,ADM2,DISTRICT
SM227457,104.9534,18.1866,Bo-Maleng,DIALECT,LANGUAGE
SM227614,8.9051,8.9352,Bo-Rukul,LANGUAGE,LANGUAGE
SM227654,144.086,-5.8626,Bo-Ung,LANGUAGE,LANGUAGE
SM18379,59.33,43.1522,Bozatov Tumani,ADM2,DISTRICT
SM267050,-48.4779,-21.9116,Boa Esperanca do Sul,ADM2,DISTRICT
SM264765,-52.7357,-24.2437,Boa Esperança,ADM2,DISTRICT
SM263946,-45.6243,-21.0813,Boa Esperança,ADM2,DISTRICT
SM267968,-40.3446,-18.4861,Boa Esperança,ADM2,DISTRICT
SM264754,-53.2262,-25.6352,Boa Esperança do Iguaçu,ADM2,DISTRICT
SM265167,-42.1411,-4.3558,Boa Hora,ADM2,DISTRICT
SM267720,-40.2238,-14.3641,Boa Nova,ADM2,DISTRICT
SM264365,-38.1768,-7.4568,Boa Ventura,ADM2,DISTRICT
SM264777,-51.5564,-24.8408,Boa Ventura de São Roque,ADM2,DISTRICT
SM267888,-39.8417,-5.0254,Boa Viagem,ADM2,DISTRICT
SM266132,-60.6121,3.1886,Boa Vista,ADM2,DISTRICT
SM264367,-36.1574,-7.27,Boa Vista,ADM2,DISTRICT
SM698,-22.8333,16.0833,Boa Vista,ADM1,DISTRICT
SM9889,-53.8162,-28.6221,Boa Vista Do Cadeado,ADM2:ADM3,DISTRICT
SM9890,-53.4565,-28.8419,Boa Vista Do Incra,ADM2:ADM3,DISTRICT
SM264780,-53.4129,-25.4371,Boa Vista da Aparecida,ADM2,DISTRICT
SM265951,-53.3525,-27.6924,Boa Vista das Missões,ADM2,DISTRICT
SM265962,-54.1093,-27.6802,Boa Vista do Buricá,ADM2,DISTRICT
SM262948,-46.1964,-1.7308,Boa Vista do Gurupi,ADM2,DISTRICT
SM267307,-57.9052,-3.1578,Boa Vista do Ramos,ADM2,DISTRICT
SM265973,-51.6808,-29.342,Boa Vista do Sul,ADM2,DISTRICT
SM267721,-40.6282,-12.8731,Boa Vista do Tupim,ADM2,DISTRICT
SM285496,-85.4869,12.5938,Boaco,ADM2,DISTRICT
SM699,-85.5,12.5,Boaco,ADM1,DISTRICT
SM276731,122.3568,0.6269,Boalemo,ADM2,DISTRICT
SM268560,18.106,4.784,Boali,ADM2,DISTRICT
SM252388,147.49,-21.9,Boanbura,ETHNICITY,ETHNICITY
SM283924,32.4952,-25.8347,Boane (in Maputo City),ADM2,DISTRICT
SM283926,32.389,-26.029,Boane (in Maputo Province),ADM2,DISTRICT
SM227367,127.953,-2.9554,Boano (Maluku),LANGUAGE,LANGUAGE
SM227368,120.992,0.5115,Boano (Sulawesi),LANGUAGE,LANGUAGE
SM252389,140.96,-7.22,Boardji,ETHNICITY,ETHNICITY
SM270806,-72.6431,6.3074,Boavita,ADM2,DISTRICT
SM290146,23.6516,47.1332,Bobalna,ADM2,DISTRICT
SM291456,24.1422,44.3932,Bobicesti,ADM2,DISTRICT
SM250114,-3.28,12.47,Bobo,ETHNICITY,ETHNICITY
SM250114,-3.28,12.47,Bobo,ETHNICITY,ETHNICITY
SM250114,-4,13,Bobo,ETHNICITY,ETHNICITY
SM250114,-4,13,Bobo,ETHNICITY,ETHNICITY
SM297831,125.325,-9.0319,Bobonaro,ADM2,DISTRICT
SM700,125.2167,-8.95,Bobonaro,ADM1,DISTRICT
SM227382,121.922,-0.3676,Bobongko,LANGUAGE,LANGUAGE
SM268522,28.1297,-21.9828,Bobonong,ADM2,DISTRICT
SM262534,23.0273,42.1833,Boboshevo,ADM2,DISTRICT
SM227381,130.179,-3.4351,Bobot,LANGUAGE,LANGUAGE
SM291644,22.7667,47.3833,Bobota,ADM2,DISTRICT
SM262535,22.9833,42.3167,Bobovdol,ADM2,DISTRICT
SM15220,2.9108,13.0817,Boboye Department,ADM2,DISTRICT
SM294980,40,51.25,Bobrovskiy rayon,ADM2,DISTRICT
SM299508,32.1651,48.057,Bobrynets'kyi,ADM2,DISTRICT
SM15480,-66.8158,17.9718,Boca Barrio,ADM2,DISTRICT
SM271881,-69.6141,18.4572,Boca Chica,ADM2,DISTRICT
SM15481,-66.4209,17.9665,Boca Velazquez Barrio,ADM2,DISTRICT
SM265338,-36.1413,-9.6465,Boca da Mata,ADM2,DISTRICT
SM282850,-96.121,19.1174,Boca del Río,ADM2,DISTRICT
SM267308,-68.0359,-8.5542,Boca do Acre,ADM2,DISTRICT
SM17227,-60.9695,14.002,Bocage,ADM2,DISTRICT
SM267051,-48.5195,-22.0983,Bocaina,ADM2,DISTRICT
SM265168,-41.3275,-6.8951,Bocaina,ADM2,DISTRICT
SM263957,-44.4934,-22.2344,Bocaina de Minas,ADM2,DISTRICT
SM266364,-49.9171,-27.7389,Bocaina do Sul,ADM2,DISTRICT
SM263968,-43.8357,-17.3892,Bocaiúva,ADM2,DISTRICT
SM264781,-48.8712,-25.1004,Bocaiúva do Sul,ADM2,DISTRICT
SM285489,-85.1333,12.8,Bocana de Paiwas,ADM2,DISTRICT
SM10225,15.7787,6.7655,Bocaranga,ADM2,DISTRICT
SM252391,-65.7,-10.54,Bocas Negras,ETHNICITY,ETHNICITY
SM286642,-82.2629,9.3624,Bocas del Toro,ADM2,DISTRICT
SM701,-82.65,9.25,Bocas del Toro (1997-),ADM1,DISTRICT
SM270383,-72.6884,7.6022,Bochalema,ADM2,DISTRICT
SM283116,-92.9167,16.9833,Bochil,ADM2,DISTRICT
SM288929,20.4308,49.9691,Bochnia,ADM2,DISTRICT
SM290988,24.0112,47.9467,Bocicoiu Mare,ADM2,DISTRICT
SM260397,14.2361,-12.3202,Bocoio,ADM2,DISTRICT
SM303771,-70.25,9.25,Boconó,ADM2,DISTRICT
SM283276,-107.6104,27.7894,Bocoyna,ADM2,DISTRICT
SM291645,22.9124,47.3033,Bocsa,ADM2,DISTRICT
SM290066,21.7148,45.3757,Bocsa,ADM2,DISTRICT
SM290485,21.9842,46.4189,Bocsig,ADM2,DISTRICT
SM292641,25.6208,45.7599,Bod,ADM2,DISTRICT
SM10226,17.317,4.0728,Boda,ADM2,DISTRICT
SM13993,-8.3339,5.1358,Bodae District,ADM2,DISTRICT
SM16864,116,58.5,Bodaybinskiy Rayon,ADM2,DISTRICT
SM702,-81.258,19.2821,Bodden Town,ADM1,DISTRICT
SM261425,116.4188,-32.792,Boddington,ADM2,DISTRICT
SM15114,4.7663,52.0654,Bodegraven-Reeuwijk,ADM2,DISTRICT
SM296397,21.2998,66.0439,Boden,ADM2,DISTRICT
SM292249,24.1093,45.1415,Bodesti,ADM2,DISTRICT
SM291387,26.4379,47.0382,Bodesti,ADM2,DISTRICT
SM4403,-2.8204,6.2511,Bodi,ADM2,DISTRICT
SM451020,15,12,Bodi,ETHNICITY,ETHNICITY
SM227391,36.0052,6.4023,Bodi,DIALECT,LANGUAGE
SM285230,5.1667,12.8833,Bodinga,ADM2,DISTRICT
SM227399,26,6,Bodo (Central African Republic),LANGUAGE,LANGUAGE
SM227395,82.771,18.3857,Bodo Gadaba,LANGUAGE,LANGUAGE
SM227394,82.8774,19.0745,Bodo Parja,LANGUAGE,LANGUAGE
SM227396,92,26.1,Bodo-Mech,LANGUAGE,LANGUAGE
SM290240,25.8415,45.9619,Bodoc,ADM2,DISTRICT
SM264941,-39.9526,-7.7888,Bodocó,ADM2,DISTRICT
SM263027,-56.6589,-20.4823,Bodoquena,ADM2,DISTRICT
SM276238,21.8765,48.3034,Bodrogközi,ADM2,DISTRICT
SM298760,27.4982,37.065,Bodrum,ADM2,DISTRICT
SM265516,-36.3972,-5.9339,Bodó,ADM2,DISTRICT
SM461896,14.3832,67.2833,Bodø (2005-),ADM2,DISTRICT
SM275095,-14.2113,11.7466,Boe,ADM2,DISTRICT
SM703,166.9142,-0.5416,Boe,ADM1,DISTRICT
SM13994,-8.6477,6.8069,Boe and Quilla District,ADM2,DISTRICT
SM13994,-8.6477,6.8069,Boe and Quilla District,ADM2,DISTRICT
SM13994,-8.5833,6.8,Boe and Quilla District,ADM2,DISTRICT
SM13994,-8.5833,6.8,Boe and Quilla District,ADM2,DISTRICT
SM285903,5.6936,51.6049,Boekel,ADM2,DISTRICT
SM10088,104.9223,11.5515,Boeng Keng Kang,ADM2,DISTRICT
SM281477,46.1,-16.3,Boeny,ADM1:ADM2,DISTRICT
SM280641,127.7287,36.4949,Boeun,ADM2,DISTRICT
SM370532,6.0156,49.7753,Boevange-sur-Attert,ADM1:ADM2:ADM3,DISTRICT
SM267052,-48.2806,-23.1224,Bofete,ADM2,DISTRICT
SM274973,-14.1667,10.3333,Boffa,ADM1:ADM2,DISTRICT
SM227406,17.3032,4.0204,Bofi,LANGUAGE,LANGUAGE
SM12158,-77.9436,18.1563,Bog,ADM2,DISTRICT
SM303526,60.8533,41.315,Bog'ot,ADM2,DISTRICT
SM227409,12.936,10.188,Boga,LANGUAGE,LANGUAGE
SM261500,146.9062,-31.5701,Bogan,ADM2,DISTRICT
SM10227,17.1405,4.7356,Boganangone,ADM2,DISTRICT
SM10228,17.1591,4.366,Boganda,ADM2,DISTRICT
SM10229,18.21,5.5141,Bogangolo,ADM2,DISTRICT
SM291124,24.1256,46.4707,Bogata,ADM2,DISTRICT
SM291216,25.1505,44.8734,Bogati,ADM2,DISTRICT
SM294383,51.3333,53,Bogatovskiy rayon,ADM2,DISTRICT
SM227408,142.255,-5.5449,Bogaya,LANGUAGE,LANGUAGE
SM10661,33.9504,35.3147,Bogazi,ADM2,DISTRICT
SM283652,102.1753,44.6653,Bogd,ADM2,DISTRICT
SM283763,100.7704,45.1961,Bogd,ADM2,DISTRICT
SM291956,21.5696,45.9703,Bogda,ADM2,DISTRICT
SM290989,24.2955,47.6948,Bogdan Voda,ADM2,DISTRICT
SM292259,27.6152,46.5512,Bogdana,ADM2,DISTRICT
SM291911,25.0836,43.9286,Bogdana,ADM2,DISTRICT
SM704,22.6,41.2,Bogdanci,ADM1,DISTRICT
SM291648,22.9287,47.4182,Bogdand,ADM2,DISTRICT
SM292260,27.7202,46.437,Bogdanesti,ADM2,DISTRICT
SM291846,26.2739,47.3667,Bogdanesti,ADM2,DISTRICT
SM292087,26.6995,46.2179,Bogdanesti,ADM2,DISTRICT
SM292261,27.6751,46.4475,Bogdanita,ADM2,DISTRICT
SM305554,18.9297,45.3372,Bogdanovci,ADM2,DISTRICT
SM294513,62.0833,56.75,Bogdanovichskiy rayon,ADM2,DISTRICT
SM13176,61.6667,50.3333,Bogetkol Audany,ADM2,DISTRICT
SM292376,27.4187,46.1621,Boghesti,ADM2,DISTRICT
SM227411,10.1145,9.4968,Boghom,LANGUAGE,LANGUAGE
SM284001,-14.4711,16.6763,Boghé,ADM2,DISTRICT
SM288569,145,-4.5,Bogia,ADM2,DISTRICT
SM250116,10,10,Bogom,ETHNICITY,ETHNICITY
SM276777,106.7167,-6.5833,Bogor,ADM2,DISTRICT
SM285323,9.6167,9.65,Bogoro,ADM2,DISTRICT
SM16865,43.5,56.0833,Bogorodskiy Rayon,ADM2,DISTRICT
SM293294,50.8333,57.8333,Bogorodskiy rayon,ADM2,DISTRICT
SM16866,89.75,56.25,Bogotol'skiy Rayon,ADM2,DISTRICT
SM705,20.8822,41.9503,Bogovinje,ADM1,DISTRICT
SM262268,89.35,24.7833,Bogra,ADM2,DISTRICT
SM293207,91,54.3333,Bogradskiy rayon,ADM2,DISTRICT
SM293468,97.5,58.5,Boguchanskiy rayon,ADM2,DISTRICT
SM294991,40.5833,49.9167,Bogucharskiy rayon,ADM2,DISTRICT
SM12159,-77.6697,18.1362,Bogue (Saint Elizabeth),ADM2,DISTRICT
SM18548,-77.9202,18.4372,Bogue (Saint James),ADM2,DISTRICT
SM17228,-60.9209,14.0155,Boguis,ADM2,DISTRICT
SM17229,-60.9257,14.0106,Boguis/Desa Blond,ADM2,DISTRICT
SM227414,29.1344,4.4921,Boguru,LANGUAGE,LANGUAGE
SM12160,-76.9879,18.1047,Bogwalk,ADM2,DISTRICT
SM252396,-57.65,-31.64,Bohane,ETHNICITY,ETHNICITY
SM271842,-71,18.8333,Bohechio,ADM2,DISTRICT
SM12161,-77.4695,18.2124,Bohemia,ADM2,DISTRICT
SM262125,2.094,7.163,Bohicon,ADM2,DISTRICT
SM296201,13.9239,46.295,Bohinj,ADM1:ADM2,DISTRICT
SM252397,-112,43,Bohogue,ETHNICITY,ETHNICITY
SM252397,-112,43,Bohogue,ETHNICITY,ETHNICITY
SM252397,-113,43,Bohogue,ETHNICITY,ETHNICITY
SM252397,-113,43,Bohogue,ETHNICITY,ETHNICITY
SM706,124.1667,9.8333,Bohol,ADM1:ADM2,DISTRICT
SM299975,24.537,48.8079,Bohorodchans'kyi,ADM2,DISTRICT
SM292398,22.5251,47.3748,Boianu Mare,ADM2,DISTRICT
SM262305,23.4333,43.5333,Boichinovtsi,ADM2,DISTRICT
SM227427,143.474,-3.683,Boikin,LANGUAGE,LANGUAGE
SM262479,22.55,44,Boinitsa,ADM2,DISTRICT
SM13995,-9.2332,6.6982,Boinsen District,ADM2,DISTRICT
SM17230,-60.9316,13.8395,Bois Canot,ADM2,DISTRICT
SM17231,-60.9944,14.004,Bois Catchet,ADM2,DISTRICT
SM17233,-61.0097,13.9415,Bois D'Inde (Anse-la-Raye),ADM2,DISTRICT
SM17232,-61.0349,13.8365,Bois D'Inde (Soufrière),ADM2,DISTRICT
SM17234,-60.9599,14.058,Bois D'Orange,ADM2,DISTRICT
SM17235,-60.9691,14.062,Bois D'Ornange/Trouya,ADM2,DISTRICT
SM17236,-61.0495,13.8046,Bois Dinde,ADM2,DISTRICT
SM17237,-60.9141,13.9202,Bois Joli,ADM2,DISTRICT
SM17238,-60.9821,14.0089,Bois Patat,ADM2,DISTRICT
SM300586,-115.7302,43.9891,Boise,ADM2,DISTRICT
SM292256,24.3746,45.4423,Boisoara,ADM2,DISTRICT
SM267053,-47.6852,-23.3101,Boituva,ADM2,DISTRICT
SM290990,23.5997,47.402,Boiu Mare,ADM2,DISTRICT
SM270060,-74.3371,4.6872,Bojacá,ADM2,DISTRICT
SM305133,27.2243,-25.4261,Bojanala,ADM2,DISTRICT
SM269927,-77.1,6.5,Bojayá,ADM2,DISTRICT
SM276372,111.8,-7.25,Bojonegoro,ADM2,DISTRICT
SM4096,168.9667,14.6167,Bokak Atoll,ADM1,DISTRICT
SM227432,94.0664,28.6143,Bokar-Ramo,LANGUAGE,LANGUAGE
SM276899,85.9903,23.6856,Bokaro,ADM2,DISTRICT
SM708,100.5,20.5,Bokeo,ADM1,DISTRICT
SM227436,103.7,23.05,Bokha,LANGUAGE,LANGUAGE
SM292926,104,53.1667,Bokhanskiy rayon,ADM2,DISTRICT
SM305960,68.85,37.865,Bokhtar,ADM2,DISTRICT
SM305960,68.85,37.865,Bokhtar,ADM2,DISTRICT
SM305960,68.8584,37.8453,Bokhtar,ADM2,DISTRICT
SM305960,68.8584,37.8453,Bokhtar,ADM2,DISTRICT
SM285404,9.0333,6.25,Boki,ADM2,DISTRICT
SM250118,9,6,Boki,ETHNICITY,ETHNICITY
SM285189,8.9268,9.2269,Bokkos,ADM2,DISTRICT
SM286139,5.4352,59.2306,Bokn,ADM2,DISTRICT
SM16299,14.6544,-4.7883,Boko (1994-),ADM2,DISTRICT
SM227446,3.7177,10.5189,Boko (Benin),LANGUAGE,LANGUAGE
SM227443,18.8806,1.5161,Boko (Democratic Republic of Congo),LANGUAGE,LANGUAGE
SM269794,13.5748,-4.5409,Boko-Songho,ADM2,DISTRICT
SM227447,3.8006,9.5272,Bokobaru,LANGUAGE,LANGUAGE
SM282983,-89.1764,21.0064,Bokobá,ADM2,DISTRICT
SM13996,-10.1738,7.2414,Bokomu (geonames),ADM2,DISTRICT
SM13997,-8.4745,5.0265,Bokon (excl. Nake),ADM2,DISTRICT
SM299352,34.1683,2.3815,Bokora,ADM1:ADM2,DISTRICT
SM227441,16.6721,4.7511,Bokoto,LANGUAGE,LANGUAGE
SM16867,34,59.5,Boksitogorskiy Rayon,ADM2,DISTRICT
SM227455,9.0588,6.1048,Bokyi,LANGUAGE,LANGUAGE
SM274974,-14.4167,11.0833,Boké,ADM1:ADM2,DISTRICT
SM707,-14.3299,11.2625,Boké,ADM1,DISTRICT
SM305476,16.6524,43.2639,Bol,ADM2,DISTRICT
SM293654,45.8333,54.1667,Bol'shebereznikovskiy rayon,ADM2,DISTRICT
SM293806,45.25,55,Bol'sheboldinskiy rayon,ADM2,DISTRICT
SM294390,50.8333,52.0833,Bol'shechernigovskiy rayon,ADM2,DISTRICT
SM294391,50.75,52.4167,Bol'sheglushitskiy rayon,ADM2,DISTRICT
SM293655,45.5,55,Bol'sheignatovskiy rayon,ADM2,DISTRICT
SM293807,44.75,55.8333,Bol'shemurashkinskiy rayon,ADM2,DISTRICT
SM293412,93,57,Bol'shemurtinskiy rayon,ADM2,DISTRICT
SM293902,74.5,56.0833,Bol'sherechenskiy rayon,ADM2,DISTRICT
SM295048,39,57.6667,Bol'shesel'skiy rayon,ADM2,DISTRICT
SM293506,35.55,51.3333,Bol'shesoldatskiy rayon,ADM2,DISTRICT
SM293913,72.5,56.8333,Bol'sheukovskiy rayon,ADM2,DISTRICT
SM293413,90.75,56.6667,Bol'sheuluyskiy rayon,ADM2,DISTRICT
SM227458,150.095,-5.5579,Bola,LANGUAGE,LANGUAGE
SM276627,124.0833,0.75,Bolaang Mongondow,ADM2,DISTRICT
SM276624,123.7596,0.4091,Bolaang Mongondow Selatan,ADM2,DISTRICT
SM276625,124.5026,0.7207,Bolaang Mongondow Timur,ADM2,DISTRICT
SM276626,123.4177,0.7853,Bolaang Mongondow Utara,ADM2,DISTRICT
SM275086,-15.5089,11.5777,Bolama,ADM2,DISTRICT
SM709,-16.0093,11.1548,Bolama,ADM1,DISTRICT
SM227460,123.888,0.3904,Bolango,LANGUAGE,LANGUAGE
SM250600,11.44,10.95,Bolawa,ETHNICITY,ETHNICITY
SM250600,11.44,10.95,Bolawa,ETHNICITY,ETHNICITY
SM250600,11,11,Bolawa,ETHNICITY,ETHNICITY
SM250600,11,11,Bolawa,ETHNICITY,ETHNICITY
SM281773,-103.93,21.84,Bolaños,ADM2,DISTRICT
SM290611,23.2216,44.7374,Bolbosi,ADM2,DISTRICT
SM291498,26.5348,44.8765,Boldesti,ADM2,DISTRICT
SM291499,26.0457,45.0246,Boldesti-scaeni,ADM2,DISTRICT
SM289929,27.2431,45.3227,Boldu,ADM2,DISTRICT
SM291957,21.7623,45.7002,Boldur,ADM2,DISTRICT
SM241089,11.2322,11.4839,Bole,LANGUAGE,LANGUAGE
SM274924,-2.0678,8.5927,Bole,ADM2,DISTRICT
SM346076,38.834,8.978,Bole,ADM2:ADM3,DISTRICT
SM288650,15.5322,51.3217,Bolesławiec,ADM2,DISTRICT
SM227467,42.7292,48.6715,Bolgarian,LANGUAGE,LANGUAGE
SM274944,-0.8514,10.7856,Bolgatanga,ADM2,DISTRICT
SM227468,18.8076,10.9025,Bolgo,LANGUAGE,LANGUAGE
SM299634,28.6138,45.6776,Bolhrads'kyi,ADM2,DISTRICT
SM252410,148.89,-9.5,Boli,ETHNICITY,ETHNICITY
SM227472,18.0975,-1.3836,Bolia,LANGUAGE,LANGUAGE
SM710,104.3333,18.5,Bolikhamxai,ADM1,DISTRICT
SM280842,103.7116,18.714,Bolikhanh,ADM2,DISTRICT
SM227473,119.853,16.3071,Bolinao,LANGUAGE,LANGUAGE
SM290581,25.823,44.4459,Bolintin-deal,ADM2,DISTRICT
SM290592,25.7393,44.4436,Bolintin-vale,ADM2,DISTRICT
SM301481,-90.8804,33.7955,Bolivar,ADM2,DISTRICT
SM9828,-66.5333,-17.9667,Bolivar,ADM2,DISTRICT
SM711,-79.0833,-1.5833,Bolivar,ADM1,DISTRICT
SM228765,-78.8521,-1.3673,Bolivar-North Chimborazo Highland Quichua,LANGUAGE,LANGUAGE
SM271070,-78.3442,22.0776,Bolivia,ADM2,DISTRICT
SM227471,-67.5782,-16.3895,Bolivian Sign Language,LANGUAGE,LANGUAGE
SM293928,36,53.4167,Bolkhovskiy rayon,ADM2,DISTRICT
SM296611,12.6145,57.7426,Bollebygd,ADM2,DISTRICT
SM301611,-90.026,37.3222,Bollinger,ADM2,DISTRICT
SM296618,16.4173,61.3142,Bollnäs,ADM2,DISTRICT
SM13998,-8.398,4.9647,Bolloh District,ADM2,DISTRICT
SM274813,44.5167,41.3667,Bolnisi,ADM2,DISTRICT
SM277947,11.4333,44.4667,Bologna,ADM2,DISTRICT
SM286804,-77.148,-10.1072,Bolognesi,ADM2,DISTRICT
SM16868,34,57.9167,Bologovskiy Rayon,ADM2,DISTRICT
SM227481,18.4555,0.8346,Boloki,LANGUAGE,LANGUAGE
SM227485,-4.7552,11.8242,Bolon,LANGUAGE,LANGUAGE
SM227482,19.6181,2.4633,Bolondo,LANGUAGE,LANGUAGE
SM227487,117.293,2.7593,Bolongan,LANGUAGE,LANGUAGE
SM260431,15.1219,-8.3969,Bolongongo,ADM2,DISTRICT
SM292387,27.0412,45.841,Bolotesti,ADM2,DISTRICT
SM293886,84.25,55.75,Bolotninskiy rayon,ADM2,DISTRICT
SM274669,-2.5,53.5833,Bolton,ADM1:ADM2,DISTRICT
SM714,31.5822,40.6413,Bolu,ADM1,DISTRICT
SM277836,-23.3302,66.1445,Bolungarvík,ADM2,DISTRICT
SM285141,4.813,7.9189,Boluwaduro,ADM2,DISTRICT
SM298372,31.0543,38.7206,Bolvadin,ADM2,DISTRICT
SM289991,22.3311,45.3363,Bolvasnita,ADM2,DISTRICT
SM262497,26.85,42.15,Bolyarovo,ADM2,DISTRICT
SM11538,18.4845,45.9808,Bolyi Jaras,ADM2,DISTRICT
SM227491,104.955,24.7013,Bolyu,LANGUAGE,LANGUAGE
SM277917,11.5,46.7167,Bolzano,ADM2,DISTRICT
SM303773,-70.7992,9.4013,Bolívar,ADM2,DISTRICT
SM303787,-68.9167,10.5,Bolívar,ADM2,DISTRICT
SM303754,-72.4167,7.8333,Bolívar,ADM2,DISTRICT
SM303727,-63.9045,10.4472,Bolívar,ADM2,DISTRICT
SM303709,-64.6724,10.1446,Bolívar,ADM2,DISTRICT
SM303849,-70.4145,8.7599,Bolívar,ADM2,DISTRICT
SM303662,-63.1323,10.0493,Bolívar,ADM2,DISTRICT
SM303574,-69.6856,11.1199,Bolívar,ADM2,DISTRICT
SM303823,-67.4237,10.213,Bolívar,ADM2,DISTRICT
SM286757,-77.75,-7.3333,Bolívar,ADM2,DISTRICT
SM295710,-87.95,13.5833,Bolívar,ADM2,DISTRICT
SM273591,-77.9025,0.5026,Bolívar,ADM2,DISTRICT
SM273502,-80.1667,-0.85,Bolívar,ADM2,DISTRICT
SM270642,-76.3487,4.3771,Bolívar,ADM2,DISTRICT
SM270518,-74.1504,6.0987,Bolívar,ADM2,DISTRICT
SM270184,-76.0109,5.8395,Bolívar,ADM2,DISTRICT
SM260732,-61.0833,-36.3333,Bolívar,ADM2,DISTRICT
SM712,-74.3333,9,Bolívar,ADM1,DISTRICT
SM713,-63.5,6.3333,Bolívar,ADM1,DISTRICT
SM10386,-76.9829,1.8467,Bolívar (1999-),ADM2,DISTRICT
SM227509,-11.597,9.2752,Bom,DIALECT,LANGUAGE
SM264942,-36.668,-9.1714,Bom Conselho,ADM2,DISTRICT
SM265247,-42.3352,-22.1787,Bom Jardim,ADM2,DISTRICT
SM264943,-35.5909,-7.7763,Bom Jardim,ADM2,DISTRICT
SM266365,-49.6422,-28.3674,Bom Jardim da Serra,ADM2,DISTRICT
SM268214,-52.0568,-16.2703,Bom Jardim de Goiás,ADM2,DISTRICT
SM263990,-44.1219,-21.9439,Bom Jardim de Minas,ADM2,DISTRICT
SM262949,-46.2171,-3.7741,Bom Jardin,ADM2,DISTRICT
SM266368,-52.3918,-26.7408,Bom Jesus,ADM2,DISTRICT
SM265984,-50.462,-28.5998,Bom Jesus,ADM2,DISTRICT
SM265517,-35.5838,-6.0088,Bom Jesus,ADM2,DISTRICT
SM265170,-44.6018,-9.1626,Bom Jesus,ADM2,DISTRICT
SM264368,-38.6255,-6.8185,Bom Jesus,ADM2,DISTRICT
SM9891,-51.7516,-12.2788,Bom Jesus Do Araguaia,ADM2,DISTRICT
SM267722,-43.2962,-13.2085,Bom Jesus da Lapa,ADM2,DISTRICT
SM264002,-46.5477,-20.999,Bom Jesus da Penha,ADM2,DISTRICT
SM267723,-40.5398,-14.3838,Bom Jesus da Serra,ADM2,DISTRICT
SM262950,-46.6069,-4.5133,Bom Jesus das Selvas,ADM2,DISTRICT
SM268215,-49.8954,-18.1921,Bom Jesus de Goiás,ADM2,DISTRICT
SM264013,-43.4684,-19.7167,Bom Jesus do Amparo,ADM2,DISTRICT
SM264024,-42.3756,-19.7169,Bom Jesus do Galho,ADM2,DISTRICT
SM265248,-41.6838,-21.1232,Bom Jesus do Itabapoana,ADM2,DISTRICT
SM267969,-41.6394,-21.0883,Bom Jesus do Norte,ADM2,DISTRICT
SM266367,-53.0967,-26.6905,Bom Jesus do Oeste,ADM2,DISTRICT
SM264782,-53.5496,-26.1528,Bom Jesus do Sul,ADM2,DISTRICT
SM267206,-47.8697,-9.0184,Bom Jesus do Tocantins,ADM2,DISTRICT
SM264137,-48.8237,-4.9918,Bom Jesus do Tocantins,ADM2,DISTRICT
SM262951,-45.021,-4.3467,Bom Lugar,ADM2,DISTRICT
SM265995,-51.3641,-29.4617,Bom Princípio,ADM2,DISTRICT
SM265171,-41.6457,-3.2375,Bom Princípio do Piauí,ADM2,DISTRICT
SM266006,-53.8289,-27.5422,Bom Progresso,ADM2,DISTRICT
SM264035,-46.1863,-22.4496,Bom Repouso,ADM2,DISTRICT
SM266369,-49.6196,-27.7605,Bom Retiro,ADM2,DISTRICT
SM266015,-51.911,-29.6356,Bom Retiro do Sul,ADM2,DISTRICT
SM264784,-51.8195,-23.6951,Bom Sucesso,ADM2,DISTRICT
SM264369,-37.9577,-6.4735,Bom Sucesso,ADM2,DISTRICT
SM264046,-44.795,-21.0303,Bom Sucesso,ADM2,DISTRICT
SM267055,-49.167,-24.3123,Bom Sucesso de Itararé,ADM2,DISTRICT
SM264783,-52.851,-26.0824,Bom Sucesso do Sul,ADM2,DISTRICT
SM263979,-45.253,-19.6915,Bom despacho,ADM2,DISTRICT
SM227508,-11.597,9.2752,Bom-Kim,LANGUAGE,LANGUAGE
SM227494,15.6,2.5,Boma,LANGUAGE,LANGUAGE
SM227494,15.6,2.5,Boma,LANGUAGE,LANGUAGE
SM227494,17.45,-3.27,Boma,LANGUAGE,LANGUAGE
SM227494,17.45,-3.27,Boma,LANGUAGE,LANGUAGE
SM11118,13.069,-5.8016,Boma (city),ADM2,DISTRICT
SM11118,13.069,-5.8016,Boma (city),ADM2,DISTRICT
SM11118,13.05,-5.85,Boma (city),ADM2,DISTRICT
SM11118,13.05,-5.85,Boma (city),ADM2,DISTRICT
SM259008,17.26,-3.42,Boma Nkuu,LANGUAGE,LANGUAGE
SM259016,16.92,-3.01,Boma Nord,LANGUAGE,LANGUAGE
SM284667,5.9042,5.1691,Bomadi,ADM2,DISTRICT
SM4421,-12.1645,9.2844,Bombali (2017-),ADM2,DISTRICT
SM276609,121.8164,-4.6257,Bombana,ADM2,DISTRICT
SM252418,149.24,-22.26,Bombarabua,ETHNICITY,ETHNICITY
SM252418,149.24,-22.26,Bombarabua,ETHNICITY,ETHNICITY
SM252418,148.4629,-20.4018,Bombarabua,ETHNICITY,ETHNICITY
SM252418,148.4629,-20.4018,Bombarabua,ETHNICITY,ETHNICITY
SM289253,-9.17,39.2811,Bombarral,ADM2,DISTRICT
SM12162,-77.4494,18.1178,Bombay,ADM2,DISTRICT
SM250498,22.86,0.77,Bombesa,ETHNICITY,ETHNICITY
SM250498,22.86,0.77,Bombesa,ETHNICITY,ETHNICITY
SM250498,23,1,Bombesa,ETHNICITY,ETHNICITY
SM250498,23,1,Bombesa,ETHNICITY,ETHNICITY
SM266370,-48.502,-27.1788,Bombinhas,ADM2,DISTRICT
SM227502,18.5131,1.9932,Bomboli,LANGUAGE,LANGUAGE
SM227503,18.7841,2.4019,Bomboma,LANGUAGE,LANGUAGE
SM715,35.35,-0.79,"Bomet (1992-1998, 2010-)",ADM1:ADM2,DISTRICT
SM716,-10.8167,6.7333,Bomi,ADM1,DISTRICT
SM227507,17.3733,1.7004,Bomitaba,LANGUAGE,LANGUAGE
SM227512,-4.538,12.3816,Bomu,LANGUAGE,LANGUAGE
SM252420,28.84,-31.5,Bomvana,ETHNICITY,ETHNICITY
SM252420,28.84,-31.5,Bomvana,ETHNICITY,ETHNICITY
SM252420,28,-32,Bomvana,ETHNICITY,ETHNICITY
SM252420,28,-32,Bomvana,ETHNICITY,ETHNICITY
SM227517,15.9546,1.7101,Bomwali,LANGUAGE,LANGUAGE
SM324036,-113.407,53.8392,Bon Accord,ADM2:ADM3,DISTRICT
SM227538,19.5774,10.6577,Bon Gula,LANGUAGE,LANGUAGE
SM302511,-97.8846,42.9884,Bon Homme,ADM2,DISTRICT
SM267056,-46.4712,-23.1649,Bon Jesus dos Perdoes,ADM2,DISTRICT
SM252429,-1.52,9.33,Bona,ETHNICITY,ETHNICITY
SM252429,-0.74,7.56,Bona,ETHNICITY,ETHNICITY
SM717,-68.25,12.2,Bonaire,ADM1,DISTRICT
SM227518,102.995,35.6972,Bonan,LANGUAGE,LANGUAGE
SM285407,-84.5968,14.0297,Bonanza,ADM2,DISTRICT
SM271794,-70.4667,18.9167,Bonao,ADM2,DISTRICT
SM227523,-58.4685,-2.0475,Bonari,LANGUAGE,LANGUAGE
SM300614,-89.4356,38.8868,Bond,ADM2,DISTRICT
SM294571,42,53,Bondarskiy rayon,ADM2,DISTRICT
SM227529,38.5079,-5.1075,Bondei,LANGUAGE,LANGUAGE
SM250122,39.34,-4.13,Bondei,ETHNICITY,ETHNICITY
SM370401,-9.715,8.2569,Bondi,ADM2:ADM3,DISTRICT
SM324107,-112.6953,54.5995,Bondiss,ADM2:ADM3,DISTRICT
SM227527,83.8141,19.1512,Bondo,LANGUAGE,LANGUAGE
SM252424,82,18,Bondo,ETHNICITY,ETHNICITY
SM5341,37.316,-2.218,Bondo District,ADM2,DISTRICT
SM252425,15.4,-8.34,Bondongo,ETHNICITY,ETHNICITY
SM227528,-2.8738,7.8595,Bondoukou Kulango,LANGUAGE,LANGUAGE
SM276373,113.9834,-7.9404,Bondowoso,ADM2,DISTRICT
SM276587,120.1333,-4.7,Bone,ADM2,DISTRICT
SM276732,123.275,0.503,Bone Bolango,ADM2,DISTRICT
SM227536,121.792,-7.3766,Bonerate,LANGUAGE,LANGUAGE
SM227537,139.208,-2.0953,Bonerif,LANGUAGE,LANGUAGE
SM266133,-60.0748,2.6975,Bonfim,ADM2,DISTRICT
SM264057,-44.2074,-20.3159,Bonfim,ADM2,DISTRICT
SM265172,-42.8825,-9.1646,Bonfim do Piauí,ADM2,DISTRICT
SM268216,-49.0149,-16.5973,Bonfinópolis,ADM2,DISTRICT
SM264063,-46.1327,-16.5442,Bonfinópolis de Minas,ADM2,DISTRICT
SM718,-9.6333,6.9667,Bong,ADM1,DISTRICT
SM277396,90.6464,26.4603,Bongaigaon (2004-),ADM2,DISTRICT
SM17239,-61.0299,13.7667,Bongalo,ADM2,DISTRICT
SM286722,-77.8731,-5.6834,Bongará,ADM2,DISTRICT
SM227545,117.174,7.2925,Bonggi,LANGUAGE,LANGUAGE
SM227543,139.576,-2.2804,Bonggo,LANGUAGE,LANGUAGE
SM252426,139.6,-2.34,Bonggo,ETHNICITY,ETHNICITY
SM280744,128.7357,36.8895,Bonghwa,ADM2,DISTRICT
SM227540,16.6079,0.3706,Bongili,LANGUAGE,LANGUAGE
SM227541,27.9918,7.4145,Bongo,LANGUAGE,LANGUAGE
SM274945,-0.8081,10.9078,Bongo,ADM2,DISTRICT
SM268436,89.6219,26.9204,Bongo,ADM2,DISTRICT
SM252428,23.48,3.88,Bongo,ETHNICITY,ETHNICITY
SM252428,23.48,3.88,Bongo,ETHNICITY,ETHNICITY
SM252428,29,7,Bongo,ETHNICITY,ETHNICITY
SM252428,29,7,Bongo,ETHNICITY,ETHNICITY
SM281466,46.1,-18.5,Bongolava,ADM1:ADM2,DISTRICT
SM227547,145.806,-5.5052,Bongu,LANGUAGE,LANGUAGE
SM227553,142.21,27.07,Bonin English Pidgin,LANGUAGE,LANGUAGE
SM267724,-41.6834,-12.8036,Boninal,ADM2,DISTRICT
SM264944,-35.6945,-8.5224,Bonito,ADM2,DISTRICT
SM264138,-47.3374,-1.4293,Bonito,ADM2,DISTRICT
SM263028,-56.3386,-20.9104,Bonito,ADM2,DISTRICT
SM267726,-41.3178,-12.0049,Bonito,ADM2,DISTRICT
SM275831,-85.7361,15.7464,Bonito Oriental,ADM2,DISTRICT
SM264064,-44.8847,-14.9102,Bonito de Minas,ADM2,DISTRICT
SM264370,-38.4798,-7.2894,Bonito de Santa Fé,ADM2,DISTRICT
SM227555,9.7631,4.6605,Bonkeng,LANGUAGE,LANGUAGE
SM227556,146.738,-5.8401,Bonkiman,LANGUAGE,LANGUAGE
SM300587,-116.601,48.2998,Bonner,ADM2,DISTRICT
SM17240,-60.9415,14.0699,Bonneterre,ADM2,DISTRICT
SM17241,-60.9339,14.0674,Bonneterre Gardens,ADM2,DISTRICT
SM300545,-111.6149,43.3877,Bonneville,ADM2,DISTRICT
SM285216,7.2329,4.4396,Bonny,ADM2,DISTRICT
SM12163,-76.9461,18.3287,Bonnygate,ADM2,DISTRICT
SM10127,-110.7464,54.2665,Bonnyville,ADM2,DISTRICT
SM324062,-110.8692,54.2263,Bonnyville Beach,ADM2:ADM3,DISTRICT
SM3931,-2.5,7.65,Bono,ADM1,DISTRICT
SM3932,-1.05,7.75,Bono East,ADM1,DISTRICT
SM262114,2.4793,6.8694,Bonou,ADM2,DISTRICT
SM276425,117.496,0.1253,Bontang,ADM2,DISTRICT
SM295493,-12.3359,7.5162,Bonthe,ADM1:ADM2,DISTRICT
SM290068,23.8223,46.9004,Bontida,ADM2,DISTRICT
SM227560,121.0266,17.0939,Bontok,FAMILY,LANGUAGE
SM252430,120.69,17.22,Bontok,ETHNICITY,ETHNICITY
SM252430,120.69,17.22,Bontok,ETHNICITY,ETHNICITY
SM252430,121,17,Bontok,ETHNICITY,ETHNICITY
SM252430,121,17,Bontok,ETHNICITY,ETHNICITY
SM276185,18.4974,46.34,Bonyhád,ADM2,DISTRICT
SM268217,-49.897,-13.5773,Bonópolis,ADM2,DISTRICT
SM227565,116.474,5.3252,Bookan,LANGUAGE,LANGUAGE
SM227568,43.1208,0.4199,Boon,LANGUAGE,LANGUAGE
SM280761,101.894,21.6679,Boon Neua,ADM2,DISTRICT
SM303130,-81.7112,38.023,Boone,ADM2,DISTRICT
SM301509,-92.3097,38.9906,Boone,ADM2,DISTRICT
SM301733,-98.0673,41.7068,Boone,ADM2,DISTRICT
SM301086,-84.7279,38.9699,Boone,ADM2,DISTRICT
SM300746,-86.4687,40.0508,Boone,ADM2,DISTRICT
SM300861,-93.9317,42.0366,Boone,ADM2,DISTRICT
SM300625,-88.8234,42.3231,Boone,ADM2,DISTRICT
SM302396,-93.0915,36.3086,Boone,ADM2,DISTRICT
SM280762,101.9985,21.3321,Boontai,ADM2,DISTRICT
SM227569,145.837,-38.4463,Boonwurrung,DIALECT,LANGUAGE
SM227575,17.5648,10.0904,Boor,LANGUAGE,LANGUAGE
SM262175,1.945,6.657,Bopa,ADM2,DISTRICT
SM280963,-10.4211,7.1463,Bopolu,ADM2,DISTRICT
SM264371,-38.1353,-7.1386,Boqueirao dos Cochos,ADM2,DISTRICT
SM264372,-36.1298,-7.5506,Boqueirão,ADM2,DISTRICT
SM266016,-52.3984,-29.3125,Boqueirão do Leão,ADM2,DISTRICT
SM265173,-42.136,-4.5665,Boqueirão do Piauí,ADM2,DISTRICT
SM15483,-67.1816,17.999,Boqueron Barrio (Cabo Rojo),ADM2,DISTRICT
SM15482,-65.8415,18.2066,Boqueron Barrio (Las Piedras),ADM2,DISTRICT
SM286683,-82.5667,8.6167,Boquerón,ADM2,DISTRICT
SM719,-61.0738,-22.6411,Boquerón,ADM1,DISTRICT
SM286684,-82.3684,8.749,Boquete,ADM2,DISTRICT
SM267727,-42.6418,-12.7394,Boquira,ADM2,DISTRICT
SM298794,34.5031,37.8354,Bor,ADM2,DISTRICT
SM252431,30.18,6.39,Bor,ETHNICITY,ETHNICITY
SM16869,44.0901,56.3533,Bor Urban Okrug,ADM2,DISTRICT
SM15000,109.3804,46.2477,Bor-Ondor Sum,ADM2,DISTRICT
SM227581,-72.2571,-2.0003,Bora,LANGUAGE,LANGUAGE
SM11234,-151.7436,-16.4948,Bora-Bora,ADM2,DISTRICT
SM297069,103.1252,15.9888,Borabu,ADM2,DISTRICT
SM267058,-48.7886,-22.1676,Boracéia,ADM2,DISTRICT
SM17855,43.1702,10.1876,Borama (excl. Dilla),ADM2,DISTRICT
SM250125,39.67,2.91,Boran,ETHNICITY,ETHNICITY
SM250125,39.67,2.91,Boran,ETHNICITY,ETHNICITY
SM250125,39.67,2.91,Boran,ETHNICITY,ETHNICITY
SM250125,39.95,5.66,Boran,ETHNICITY,ETHNICITY
SM250125,39.95,5.66,Boran,ETHNICITY,ETHNICITY
SM250125,39.95,5.66,Boran,ETHNICITY,ETHNICITY
SM250125,39,3,Boran,ETHNICITY,ETHNICITY
SM250125,39,3,Boran,ETHNICITY,ETHNICITY
SM250125,39,3,Boran,ETHNICITY,ETHNICITY
SM250125,38,3,Boran,ETHNICITY,ETHNICITY
SM250125,38,3,Boran,ETHNICITY,ETHNICITY
SM250125,38,3,Boran,ETHNICITY,ETHNICITY
SM227582,37.882,1.0568,Borana-Arsi-Guji Oromo,LANGUAGE,LANGUAGE
SM290612,23.2674,44.7011,Borascu,ADM2,DISTRICT
SM289494,-7.4672,38.8141,Borba,ADM2,DISTRICT
SM267309,-59.3054,-5.1124,Borba,ADM2,DISTRICT
SM267059,-49.0437,-21.6291,Borborema,ADM2,DISTRICT
SM264373,-35.6209,-6.7991,Borborema,ADM2,DISTRICT
SM291315,25.7814,47.191,Borca,ADM2,DISTRICT
SM289959,27.7181,44.3236,Borcea,ADM2,DISTRICT
SM264065,-46.1671,-22.2511,Borda da Mata,ADM2,DISTRICT
SM292693,27.5709,45.0323,Bordei Verde,ADM2,DISTRICT
SM17242,-60.9009,13.8955,Bordelais,ADM2,DISTRICT
SM302717,-101.4317,32.7437,Borden,ADM2,DISTRICT
SM227588,-77.1664,7.9299,Border Kuna,LANGUAGE,LANGUAGE
SM292393,27.041,45.5472,Bordesti,ADM2,DISTRICT
SM720,4.75,36.0833,Bordj Bou Arréridj,ADM1,DISTRICT
SM457273,3.8333,36.75,Bordj Menaïel District,ADM2,DISTRICT
SM290894,27.9032,44.4724,Bordusani,ADM2,DISTRICT
SM267060,-48.9848,-22.6764,Borebi,ADM2,DISTRICT
SM227590,144.715,-4.087,Borei,LANGUAGE,LANGUAGE
SM274369,38.2092,4.9495,Borena,ADM2,DISTRICT
SM11602,-21.2379,64.7145,Borgarbyggd (2005-),ADM2,DISTRICT
SM277748,-13.8293,65.434,Borgarfjarðarhreppur,ADM2,DISTRICT
SM285551,6.8804,52.905,Borger-Odoorn,ADM2,DISTRICT
SM296654,16.9409,57.0975,Borgholm,ADM2,DISTRICT
SM276097,-72.5311,19.7838,Borgne,ADM2,DISTRICT
SM721,12.4456,43.9409,Borgo Maggiore,ADM1,DISTRICT
SM722,2.75,9.75,Borgou (1999-),ADM1,DISTRICT
SM285041,4.1626,10.3784,Borgu,ADM2,DISTRICT
SM227592,3.988,9.801,Borgu Fulfulde,LANGUAGE,LANGUAGE
SM227594,94.3538,27.6281,Bori-Karko,LANGUAGE,LANGUAGE
SM262408,24.3169,41.6483,Borino,ADM2,DISTRICT
SM15484,-67.1593,18.4732,Borinquen Barrio (Aguadilla),ADM2,DISTRICT
SM15485,-66.0406,18.1696,Borinquen Barrio (Caguas),ADM2,DISTRICT
SM285142,4.6695,7.8559,Boripe,ADM2,DISTRICT
SM295059,39,57.3333,Borisoglebskiy rayon,ADM2,DISTRICT
SM16870,42.0833,51.4167,Borisoglebsky Urban Okrug,ADM2,DISTRICT
SM294696,36,50.5833,Borisovskiy rayon,ADM2,DISTRICT
SM18186,31.0247,50.3345,Borispol'skiy Rayon (excl. Boryspil's),ADM2,DISTRICT
SM297890,9.8477,36.6969,Borj El Amri,ADM2,DISTRICT
SM289547,-56.6103,-26.0343,Borja,ADM2,DISTRICT
SM274777,43.5,41.7833,Borjomi,ADM2,DISTRICT
SM723,18.5,18.25,Borkou,ADM1,DISTRICT
SM10276,19.9059,17.081,Borkou (1999-),ADM2,DISTRICT
SM10277,18.3974,18.4028,Borkou-Yala,ADM2,DISTRICT
SM291316,26.4908,46.7893,Borlesti,ADM2,DISTRICT
SM296549,15.3987,60.458,Borlänge,ADM2,DISTRICT
SM283451,14.5217,35.8806,Bormla,ADM1:ADM2,DISTRICT
SM227597,26.0814,1.7916,Borna (Democratic Republic of Congo),LANGUAGE,LANGUAGE
SM285577,6.744,52.3112,Borne,ADM2,DISTRICT
SM271683,14.9112,55.1239,Bornholm,ADM2,DISTRICT
SM724,13,11.5,Borno (1991-),ADM1,DISTRICT
SM298554,27.2524,38.4849,Bornova,ADM2,DISTRICT
SM283702,106.2673,48.4602,Bornuur,ADM2,DISTRICT
SM227609,0.3623,7.4967,Boro,LANGUAGE,LANGUAGE
SM252436,-72.79,0.89,Boro,ETHNICITY,ETHNICITY
SM227603,35.345,10.6596,Boro (Ethiopia),LANGUAGE,LANGUAGE
SM291857,26.3304,47.3423,Boroaia,ADM2,DISTRICT
SM12164,-77.4276,18.1978,Borobridge,ADM2,DISTRICT
SM292399,22.6091,47.005,Borod,ADM2,DISTRICT
SM13177,80.75,50.8333,Borodulikha Audany (excl. Novoshul'ba),ADM2,DISTRICT
SM18187,29.8856,50.6589,Borodyans'kyy Rayon,ADM2,DISTRICT
SM227605,147.511,-6.5669,Borong,LANGUAGE,LANGUAGE
SM261295,145.053,-37.8264,Boroondara,ADM2,DISTRICT
SM227608,-55.2004,-16.6029,Bororo,LANGUAGE,LANGUAGE
SM252437,-54.09,-16.6,Bororo,ETHNICITY,ETHNICITY
SM252437,-54.09,-16.6,Bororo,ETHNICITY,ETHNICITY
SM252437,-54.09,-16.6,Bororo,ETHNICITY,ETHNICITY
SM252437,-55.76,-14.3,Bororo,ETHNICITY,ETHNICITY
SM252437,-55.76,-14.3,Bororo,ETHNICITY,ETHNICITY
SM252437,-55.76,-14.3,Bororo,ETHNICITY,ETHNICITY
SM252437,-54,-16.5,Bororo,ETHNICITY,ETHNICITY
SM252437,-54,-16.5,Bororo,ETHNICITY,ETHNICITY
SM252437,-54,-16.5,Bororo,ETHNICITY,ETHNICITY
SM252437,-55,-16,Bororo,ETHNICITY,ETHNICITY
SM252437,-55,-16,Bororo,ETHNICITY,ETHNICITY
SM252437,-55,-16,Bororo,ETHNICITY,ETHNICITY
SM290241,26.0157,45.8101,Borosneu Mare,ADM2,DISTRICT
SM262487,23.75,43.4333,Borovan,ADM2,DISTRICT
SM16871,34,58.5,Borovichskiy Rayon,ADM2,DISTRICT
SM296300,14.3794,45.9144,Borovnica,ADM1:ADM2,DISTRICT
SM305555,18.9717,45.4013,Borovo,ADM2,DISTRICT
SM262364,25.8,43.6,Borovo,ADM2,DISTRICT
SM16872,36.5,55.1667,Borovskiy Rayon,ADM2,DISTRICT
SM264785,-51.6014,-23.9393,Borrazópolis,ADM2,DISTRICT
SM292400,21.8314,47.1307,Bors,ADM2,DISTRICT
SM290991,24.6885,47.6697,Borsa,ADM2,DISTRICT
SM290069,23.6494,46.924,Borsa,ADM2,DISTRICT
SM285267,11.4258,12.6993,Borsari,ADM2,DISTRICT
SM285267,11.4258,12.6993,Borsari,ADM2,DISTRICT
SM285267,11.4127,12.7158,Borsari,ADM2,DISTRICT
SM285267,11.4127,12.7158,Borsari,ADM2,DISTRICT
SM290732,25.5617,46.9655,Borsec,ADM2,DISTRICT
SM285617,3.8113,51.4419,Borsele,ADM2,DISTRICT
SM299745,26.0319,48.8036,Borshchivs'kyi,ADM2,DISTRICT
SM725,22.25,44.3667,Borski,ADM1:ADM2,DISTRICT
SM294392,51.75,53,Borskiy rayon,ADM2,DISTRICT
SM726,21,48.25,Borsod-Abaúj-Zemplén,ADM1,DISTRICT
SM227615,-83.2124,8.8809,Boruca,LANGUAGE,LANGUAGE
SM252439,-84.48,9.6,Boruca,ETHNICITY,ETHNICITY
SM252439,-84.48,9.6,Boruca,ETHNICITY,ETHNICITY
SM252439,-84.08,9.36,Boruca,ETHNICITY,ETHNICITY
SM252439,-84.08,9.36,Boruca,ETHNICITY,ETHNICITY
SM277557,48.7167,33.8833,Borujerd,ADM2,DISTRICT
SM280660,126.5944,36.3565,Boryeong,ADM2,DISTRICT
SM18188,23.4202,49.2868,Boryslavs'ka Mis'krada,ADM2,DISTRICT
SM18189,30.9642,50.3571,Boryspil's'ka Mis'krada,ADM2,DISTRICT
SM16873,117.3333,50.1667,Borzinskiy Rayon,ADM2,DISTRICT
SM267057,-50.4925,-22.2388,Borá,ADM2,DISTRICT
SM296614,12.9408,57.7378,Borås,ADM2,DISTRICT
SM298138,41.6763,41.3594,Borçka,ADM2,DISTRICT
SM291770,26.2958,47.575,Bosanci,ADM2,DISTRICT
SM313191,17.1861,45.0575,Bosanska Gradiška,ADM2:ADM3,DISTRICT
SM313195,16.5739,45.1825,Bosanska Kostajnica,ADM2:ADM3,DISTRICT
SM313205,18.0589,45.0503,Bosanski Brod,ADM2:ADM3,DISTRICT
SM269907,-73.8886,9.9292,Bosconia,ADM2,DISTRICT
SM227619,150.693,-9.4476,Boselewa,LANGUAGE,LANGUAGE
SM280588,127.2219,34.8462,Boseong,ADM2,DISTRICT
SM11758,57.4167,34.1667,Boshruyeh,ADM2,DISTRICT
SM290882,27.7466,47.2018,Bosia,ADM2,DISTRICT
SM305696,15.2854,45.4024,Bosiljevo,ADM2,DISTRICT
SM727,22.7833,41.4667,Bosilovo,ADM1,DISTRICT
SM252440,145.14,-4.93,Boskien,ETHNICITY,ETHNICITY
SM227628,144.647,-4.162,Bosngun,LANGUAGE,LANGUAGE
SM252441,17.467,44.05,Bosnian,ETHNICITY,ETHNICITY
SM262551,18.8,43.6553,Bosnian Podrinje,ADM1:ADM2,DISTRICT
SM227625,17.046,43.859,Bosnian Standard,DIALECT,LANGUAGE
SM11388,-1.3333,6.4167,Bosome Freho,ADM2,DISTRICT
SM11389,-1.4833,6.5333,Bosomtwe,ADM2,DISTRICT
SM290738,23.1299,45.6381,Bosorod,ADM2,DISTRICT
SM302728,-97.6344,31.9004,Bosque,ADM2,DISTRICT
SM17243,-60.9113,13.9289,Bosquet D'Or,ADM2,DISTRICT
SM10230,17.3519,6.4068,Bossangoa (2002-),ADM2,DISTRICT
SM17856,49.3999,10.9213,Bossaso (2013-),ADM2,DISTRICT
SM10231,17.6087,5.2415,Bossembele Sub-Prefecture,ADM2,DISTRICT
SM10232,16.5417,5.9485,Bossemtele,ADM2,DISTRICT
SM301170,-93.605,32.679,Bossier,ADM2,DISTRICT
SM285042,6.3659,9.6146,Bosso,ADM2,DISTRICT
SM15221,13.2838,13.7371,Bosso,ADM2,DISTRICT
SM266017,-54.9619,-28.6711,Bossoroca,ADM2,DISTRICT
SM277683,46.8333,37.8333,Bostan Abad,ADM2,DISTRICT
SM252442,11.48,2.38,Bosyeba,ETHNICITY,ETHNICITY
SM276842,71.6678,22.1736,Botad,ADM2,DISTRICT
SM261503,151.1987,-33.9466,Botany Bay,ADM2,DISTRICT
SM227636,84.348,27.7074,Bote-Majhi,LANGUAGE,LANGUAGE
SM264066,-46.4283,-21.649,Botelhos,ADM2,DISTRICT
SM280810,101.021,17.5905,Botene,ADM2,DISTRICT
SM280810,101.021,17.5905,Botene,ADM2,DISTRICT
SM280810,101.1858,17.593,Botene,ADM2,DISTRICT
SM280810,101.1858,17.593,Botene,ADM2,DISTRICT
SM291217,25.1307,45.1794,Boteni,ADM2,DISTRICT
SM292262,27.8847,46.7963,Botesti,ADM2,DISTRICT
SM291218,25.1472,45.007,Botesti,ADM2,DISTRICT
SM302975,-79.8123,37.5571,Botetourt,ADM2,DISTRICT
SM262427,23.75,42.95,Botevgrad,ADM2,DISTRICT
SM323950,-112.5189,52.3079,Botha,ADM2:ADM3,DISTRICT
SM289368,-7.716,41.6758,Boticas,ADM2,DISTRICT
SM15486,-66.3634,18.2243,Botijas Barrio,ADM2,DISTRICT
SM296470,17.8167,59.2,Botkyrka,ADM2,DISTRICT
SM227639,46.22,42.665,Botlikh,LANGUAGE,LANGUAGE
SM292862,46.25,42.75,Botlikhskiy rayon,ADM2,DISTRICT
SM227642,9.2333,9.7333,Boto,DIALECT,LANGUAGE
SM227641,120.164,15.2315,Botolan Sambal,LANGUAGE,LANGUAGE
SM291922,25.5517,44.15,Botoroaga,ADM2,DISTRICT
SM292580,26.6716,47.7398,Botosani,ADM2,DISTRICT
SM290329,23.2638,44.4039,Botosesti-paia,ADM2,DISTRICT
SM728,26.75,47.8333,Botoșani,ADM1,DISTRICT
SM302074,-100.8333,48.7922,Bottineau,ADM2,DISTRICT
SM267061,-48.5179,-22.8777,Botucatu,ADM2,DISTRICT
SM280519,103.3899,11.1146,Botum Sakor,ADM2,DISTRICT
SM264068,-43.0085,-16.9095,Botumirim,ADM2,DISTRICT
SM267728,-42.5259,-13.2955,Botuporã,ADM2,DISTRICT
SM266371,-49.1118,-27.2046,Botuverá,ADM2,DISTRICT
SM297931,10.5738,36.5387,Bou Argoub,ADM2,DISTRICT
SM457276,4,35.1667,Bou Saâda District,ADM2,DISTRICT
SM16300,17.1989,0.726,Bouanila,ADM2,DISTRICT
SM268558,15.4604,5.9978,Bouar,ADM2,DISTRICT
SM297968,9.6116,36.3162,Bouarada,ADM2,DISTRICT
SM268573,18.2867,6.3725,Bouca,ADM2,DISTRICT
SM12165,-76.8126,18.0034,Boucher Park,ADM2,DISTRICT
SM274465,5.4245,43.5237,Bouches-du-Rhône,ADM2,DISTRICT
SM729,-3.4731,12.472,Boucle du Mouhoun,ADM1,DISTRICT
SM268926,6.8284,46.9459,Boudry,ADM2,DISTRICT
SM730,45.08,-12.9092,Boueni,ADM1,DISTRICT
SM731,13.7109,-4.1629,Bouenza,ADM1,DISTRICT
SM297994,10.4275,36.2698,Bouficha,ADM2,DISTRICT
SM732,155,-6,Bougainville,ADM1,DISTRICT
SM252447,155.13,-5.88,Bougainville Islands,ETHNICITY,ETHNICITY
SM457277,5,36.4167,Bougaâ District,ADM2,DISTRICT
SM283379,-7.3474,11.2724,Bougouni,ADM2,DISTRICT
SM262196,-3.4167,10.8333,Bougouriba (1995-),ADM2,DISTRICT
SM298102,10.055,35.3684,Bouhajla,ADM2,DISTRICT
SM733,3.9167,36.25,Bouira,ADM1,DISTRICT
SM734,-13.6842,25.6617,Boujdour,ADM1:ADM2,DISTRICT
SM262132,1.1,10.1833,Boukoumbé,ADM2,DISTRICT
SM370457,5.8164,49.8878,Boulaide,ADM1:ADM2:ADM3,DISTRICT
SM303379,-105.3577,40.0925,Boulder,ADM2,DISTRICT
SM281442,-4,33.1667,Boulemane,ADM2,DISTRICT
SM262202,-0.4167,11.5,Boulgou (1995-),ADM1:ADM2,DISTRICT
SM261675,139.6253,-22.2791,Boulia,ADM2,DISTRICT
SM262208,-2.1667,12.3333,Boulkiemdé,ADM1:ADM2,DISTRICT
SM252448,11,3,Boulou-Fang,ETHNICITY,ETHNICITY
SM284582,166.0513,-21.8607,Boulouparis,ADM2,DISTRICT
SM269515,15,3,Boumba et Ngoko,ADM2,DISTRICT
SM283995,-11.2597,17.7474,Boumdeïd,ADM2,DISTRICT
SM297879,10.7105,35.4231,Boumerdès,ADM2,DISTRICT
SM735,3.6667,36.75,Boumerdès,ADM1,DISTRICT
SM298047,10.2835,36.7229,Boumhel,ADM2,DISTRICT
SM4316,11.8541,-1.9923,Boumi-lowetsi (1996-),ADM2,DISTRICT
SM227652,-2.7463,8.4601,Bouna Kulango,LANGUAGE,LANGUAGE
SM300546,-116.4629,48.767,Boundary,ADM2,DISTRICT
SM12166,-76.4708,18.1757,Boundbrook,ADM2,DISTRICT
SM16301,15.298,-0.781,Boundji (1994-),ADM2,DISTRICT
SM227655,141.9323,-3.0673,Bouni-Bobe,LANGUAGE,LANGUAGE
SM269473,-3.3124,9.4784,Bounkani,ADM2,DISTRICT
SM295253,-15.6116,13.137,Bounkiling,ADM2,DISTRICT
SM12167,-77.7166,18.458,Bounty Hall,ADM2,DISTRICT
SM284588,165.4667,-21.5667,Bourail,ADM2,DISTRICT
SM300944,-94.8493,37.8552,Bourbon,ADM2,DISTRICT
SM301097,-84.2172,38.2067,Bourbon,ADM2,DISTRICT
SM280452,105.034,10.8222,Bourei Cholsar,ADM2,DISTRICT
SM283343,-0.3428,17.7119,Bourem,ADM2,DISTRICT
SM736,5.0482,47.0698,Bourgogne-Franche-Comté,ADM1,DISTRICT
SM261505,145.3616,-29.9958,Bourke,ADM2,DISTRICT
SM12168,-76.7627,17.9701,Bournemouth Gardens,ADM2,DISTRICT
SM11369,-1.907,50.7315,"Bournemouth, Christchurch and Poole Council",ADM2,DISTRICT
SM297969,9.1553,36.183,Bourouis,ADM2,DISTRICT
SM370436,6.0672,49.91,Bourscheid,ADM1:ADM2:ADM3,DISTRICT
SM370483,6.3292,49.5539,Bous,ADM1:ADM2:ADM3,DISTRICT
SM298092,8.9917,36.57,Bousalem,ADM2,DISTRICT
SM283988,-14.125,18.17,Boutilimit,ADM2,DISTRICT
SM17244,-61.0645,13.8794,Bouton,ADM2,DISTRICT
SM227661,106.187,24.8484,Bouyei,LANGUAGE,LANGUAGE
SM284615,6,14.4333,Bouza,ADM2,DISTRICT
SM457279,4.1667,36.25,Bouïra District,ADM2,DISTRICT
SM296251,13.5564,46.3356,Bovec,ADM1:ADM2,DISTRICT
SM276546,140.3635,-5.7052,Boven Digoel,ADM2,DISTRICT
SM296107,-55.5792,3.9095,Boven Suriname,ADM2,DISTRICT
SM323818,-111.3709,49.8733,Bow Island,ADM2:ADM3,DISTRICT
SM323980,-114.0282,51.9315,Bowden,ADM2:ADM3,DISTRICT
SM302739,-94.4233,33.4458,Bowie,ADM2,DISTRICT
SM302079,-103.5207,46.1126,Bowman,ADM2,DISTRICT
SM301744,-103.0857,42.2198,Box Butte,ADM2,DISTRICT
SM302904,-113.0821,41.521,Box Elder,ADM2,DISTRICT
SM296420,15.1099,58.1484,Boxholm,ADM2,DISTRICT
SM285904,5.95,51.6333,Boxmeer,ADM2,DISTRICT
SM285905,5.3265,51.5847,Boxtel,ADM2,DISTRICT
SM298907,34.7718,41.4791,Boyabat,ADM2,DISTRICT
SM252451,-76.97,20.96,Boyaca,ETHNICITY,ETHNICITY
SM270817,-73.3514,5.4487,Boyacá,ADM2,DISTRICT
SM737,-73,5.6667,Boyacá,ADM1,DISTRICT
SM301755,-98.7665,42.8997,Boyd,ADM2,DISTRICT
SM300989,-82.6877,38.3596,Boyd,ADM2,DISTRICT
SM271093,-82.3943,23.0043,Boyeros,ADM2,DISTRICT
SM301000,-84.8668,37.6243,Boyle,ADM2,DISTRICT
SM324108,-112.8071,54.5885,Boyle,ADM2:ADM3,DISTRICT
SM269529,10.3554,6.3637,Boyo,ADM2,DISTRICT
SM276334,110.7,-7.5,Boyolali,ADM2,DISTRICT
SM303422,68.9559,40.3524,Boyovut,ADM2,DISTRICT
SM18380,69,40.3667,Boyowut Tumani,ADM2,DISTRICT
SM303437,67.25,38.25,Boysun,ADM2,DISTRICT
SM261297,116.5189,-33.8856,Boyup Brook,ADM2,DISTRICT
SM227668,18.6784,2.073,Bozaba,LANGUAGE,LANGUAGE
SM9892,-53.7689,-28.3658,Bozano,ADM2,DISTRICT
SM298268,26.0407,39.8241,Bozcaada,ADM2,DISTRICT
SM298144,28.3305,37.6806,Bozdoğan,ADM2,DISTRICT
SM269129,115.7777,33.8633,Bozhou,ADM2,DISTRICT
SM262431,23.1167,42.7833,Bozhurishte,ADM2,DISTRICT
SM291318,27.1528,46.8445,Bozieni,ADM2,DISTRICT
SM289936,26.4677,45.3899,Bozioru,ADM2,DISTRICT
SM298603,34.0191,41.9679,Bozkurt,ADM2,DISTRICT
SM298316,29.6081,37.7758,Bozkurt,ADM2,DISTRICT
SM298693,32.2523,37.1998,Bozkır,ADM2,DISTRICT
SM250126,-3.21,13.25,Bozo,ETHNICITY,ETHNICITY
SM250126,-3.21,13.25,Bozo,ETHNICITY,ETHNICITY
SM250126,-5,14,Bozo,ETHNICITY,ETHNICITY
SM250126,-5,14,Bozo,ETHNICITY,ETHNICITY
SM10233,16.0019,6.2304,Bozoum Sub-Prefecture,ADM2,DISTRICT
SM18029,38.5334,37.3707,Bozova Ilcesi (2014-),ADM2,DISTRICT
SM289992,21.9797,44.9782,Bozovici,ADM2,DISTRICT
SM298654,34.2666,39.2798,Boztepe,ADM2,DISTRICT
SM298753,32.9789,36.1133,Bozyazı,ADM2,DISTRICT
SM298193,30.0511,39.9181,Bozüyük,ADM2,DISTRICT
SM298293,34.6175,40.0303,Boğazkale,ADM2,DISTRICT
SM299032,35.2542,39.1963,Boğazlıyan,ADM2,DISTRICT
SM305556,18.7572,45.0491,Bošnjaci,ADM2,DISTRICT
SM262098,4.5833,50.75,Brabant Wallon,ADM1:ADM2,DISTRICT
SM290330,23.3982,44.3586,Brabova,ADM2,DISTRICT
SM459063,147.3284,-37.4986,Brabralung,ETHNICITY,ETHNICITY
SM301011,-84.0902,38.6888,Bracken,ADM2,DISTRICT
SM274691,-0.75,51.4167,Bracknell Forest,ADM1:ADM2,DISTRICT
SM290739,22.8128,46.1407,Brad,ADM2,DISTRICT
SM276795,-4.5062,54.1632,Braddan,ADM1:ADM2,DISTRICT
SM289853,26.8611,44.8937,Bradeanu,ADM2,DISTRICT
SM291710,24.8629,46.067,Bradeni,ADM2,DISTRICT
SM290331,23.6175,44.5177,Bradesti,ADM2,DISTRICT
SM302350,-76.5155,41.7887,Bradford,ADM2,DISTRICT
SM300346,-82.1688,29.95,Bradford,ADM2,DISTRICT
SM274585,-1.8333,53.8333,Bradford,ADM1:ADM2,DISTRICT
SM302599,-84.8596,35.1541,Bradley,ADM2,DISTRICT
SM302407,-92.1624,33.4664,Bradley,ADM2,DISTRICT
SM291219,24.9,44.7833,Bradu,ADM2,DISTRICT
SM291220,24.7737,45.275,Bradulet,ADM2,DISTRICT
SM290242,25.6033,46.1257,Bradut,ADM2,DISTRICT
SM12169,-77.6649,18.0935,Braes River,ADM2,DISTRICT
SM290892,27.0903,47.1632,Braesti,ADM2,DISTRICT
SM292591,26.4572,47.849,Braesti,ADM2,DISTRICT
SM289854,26.4949,45.4425,Braesti,ADM2,DISTRICT
SM12170,-76.8863,17.9417,Braeton,ADM2,DISTRICT
SM289440,-8.4231,41.558,Braga,ADM2,DISTRICT
SM266019,-53.7621,-27.5722,Braga,ADM2,DISTRICT
SM739,-8.4255,41.5482,Braga,ADM1,DISTRICT
SM291933,25.5198,43.7606,Bragadiru,ADM2,DISTRICT
SM290980,25.9787,44.37,Bragadiru,ADM2,DISTRICT
SM260733,-60.6667,-35,Bragado,ADM2,DISTRICT
SM264786,-53.0886,-24.7902,Braganey,ADM2,DISTRICT
SM264139,-46.7338,-0.9681,Braganga,ADM2,DISTRICT
SM289451,-6.7554,41.7561,Bragança,ADM2,DISTRICT
SM740,-6.7635,41.8362,Bragança,ADM1,DISTRICT
SM267062,-46.5556,-22.94,Bragança Paulista,ADM2,DISTRICT
SM227674,142.495,-3.4871,Bragat,LANGUAGE,LANGUAGE
SM262233,91.1667,23.9833,Brahamanbaria,ADM2,DISTRICT
SM290482,27.362,46.0616,Brahasesti,ADM2,DISTRICT
SM262595,30.3333,51.6667,Brahin,ADM2,DISTRICT
SM252486,83.4,21.9,Brahmin Tiwari,ETHNICITY,ETHNICITY
SM227677,66.56,29.04,Brahui,LANGUAGE,LANGUAGE
SM252494,66.5,30.4987,Brahui,ETHNICITY,ETHNICITY
SM252494,66.5,30.4987,Brahui,ETHNICITY,ETHNICITY
SM252494,66.5,30.5,Brahui,ETHNICITY,ETHNICITY
SM252494,66.5,30.5,Brahui,ETHNICITY,ETHNICITY
SM292704,27.975,45.2719,Braila,ADM2,DISTRICT
SM12171,-76.8689,18.1699,Brainerd,ADM2,DISTRICT
SM227679,78.0813,26.4758,Braj,LANGUAGE,LANGUAGE
SM742,-13.4,17.25,Brakna,ADM1,DISTRICT
SM290332,23.4912,44.5048,Bralostita,ADM2,DISTRICT
SM12172,-77.4642,18.4426,Brampton,ADM2,DISTRICT
SM292652,25.3729,45.5141,Bran,ADM2,DISTRICT
SM291944,25.3971,43.8779,Branceni,ADM2,DISTRICT
SM301254,-85.059,41.9161,Branch,ADM2,DISTRICT
SM291125,24.8789,46.8636,Brancovenesti,ADM2,DISTRICT
SM291467,24.3294,44.3149,Brancoveni,ADM2,DISTRICT
SM743,13.0158,52.4591,Brandenburg,ADM1,DISTRICT
SM12173,-77.2366,18.1511,Brandon Hill (Clarendon),ADM2,DISTRICT
SM18546,-76.8051,18.1482,Brandon Hill (Saint Andrew),ADM2,DISTRICT
SM18547,-77.9112,18.4777,Brandon Hill (Saint James),ADM2,DISTRICT
SM260734,-58.1667,-35.25,Brandsen,ADM2,DISTRICT
SM290981,26.35,44.4536,Branesti,ADM2,DISTRICT
SM290613,23.4721,44.6656,Branesti,ADM2,DISTRICT
SM288777,20.0063,54.2778,Braniewo,ADM2,DISTRICT
SM290740,22.7672,45.9719,Branisca,ADM2,DISTRICT
SM290483,27.8324,45.4414,Branistea,ADM2,DISTRICT
SM290321,25.5839,44.6813,Branistea,ADM2,DISTRICT
SM292523,24.0782,47.1625,Branistea,ADM2,DISTRICT
SM745,21.5861,44.4803,Braničevski,ADM1:ADM2,DISTRICT
SM265341,-36.0896,-9.2117,Branquinha,ADM2,DISTRICT
SM268854,-80.333,43.1668,Brant,ADM2,DISTRICT
SM300414,-81.9819,31.1969,Brantley,ADM2,DISTRICT
SM13452,25.7983,57.3765,Brantu pagasts,ADM2,DISTRICT
SM236060,107.008,14.5994,Brao,LANGUAGE,LANGUAGE
SM252496,13.67,19.98,Braoya,ETHNICITY,ETHNICITY
SM264069,-43.2244,-20.8784,Bras Pires,ADM2,DISTRICT
SM268218,-49.3845,-16.3791,Brasabrantes,ADM2,DISTRICT
SM263029,-52.4383,-21.1199,Brasilândia,ADM2,DISTRICT
SM264070,-45.9639,-16.9306,Brasilândia de Minas,ADM2:ADM3,DISTRICT
SM264787,-53.5725,-24.2168,Brasilândia do Sul,ADM2,DISTRICT
SM267207,-48.4345,-8.2605,Brasilândia do Tocantins,ADM2,DISTRICT
SM262796,-69.0114,-10.7677,Brasiléia,ADM2,DISTRICT
SM13453,25.0192,57.7385,Braslavas pagasts,ADM2,DISTRICT
SM262676,27,55.5597,Braslaw,ADM2,DISTRICT
SM296220,15.0368,46.2777,Braslovce,ADM1:ADM2,DISTRICT
SM263134,-58.0029,-12.249,Brasnorte,ADM2,DISTRICT
SM457951,-45.628,-22.4817,Brasopolis Municipality,ADM2,DISTRICT
SM292663,25.6091,45.6505,Brasov,ADM2,DISTRICT
SM295005,34.75,52.6667,Brasovskiy rayon,ADM2,DISTRICT
SM285330,6.4546,4.3665,Brass,ADM2,DISTRICT
SM291478,24.4114,43.9155,Brastavatu,ADM2,DISTRICT
SM267964,-47.8956,-15.7916,Brasília,ADM2,DISTRICT
SM264071,-44.4571,-16.2525,Brasília de Minas,ADM2,DISTRICT
SM459057,146.4478,-38.7158,Brataualung,ETHNICITY,ETHNICITY
SM292401,22.6183,46.9179,Bratca,ADM2,DISTRICT
SM291711,24.4201,46.1536,Brateiu,ADM2,DISTRICT
SM290243,26.0789,45.8399,Brates,ADM2,DISTRICT
SM296126,17.1058,48.1465,Bratislava I,ADM2,DISTRICT
SM296127,17.1776,48.1528,Bratislava II,ADM2,DISTRICT
SM296128,17.1379,48.1854,Bratislava III,ADM2,DISTRICT
SM296129,16.9972,48.2279,Bratislava IV,ADM2,DISTRICT
SM296130,17.0944,48.1112,Bratislava V,ADM2,DISTRICT
SM746,17.1667,48.3333,Bratislavský,ADM1,DISTRICT
SM299602,31.5769,47.8682,Brats'kyi,ADM2,DISTRICT
SM262317,24.4,42,Bratsigovo,ADM2,DISTRICT
SM292927,101.627,56.1249,Bratsk,ADM2,DISTRICT
SM292881,102,56,Bratskiy rayon,ADM2,DISTRICT
SM313235,19.315,44.1903,Bratunac,ADM2:ADM3,DISTRICT
SM262438,25.2167,42.3,Bratya Daskalovi,ADM2,DISTRICT
SM267063,-50.3523,-21.5658,Brauna,ADM2,DISTRICT
SM261828,13.0892,48.112,Braunau am Inn,ADM2,DISTRICT
SM747,-24.7,14.8667,Brava,ADM1,DISTRICT
SM303141,-80.7193,38.6999,Braxton,ADM2,DISTRICT
SM459060,146.5796,-37.8725,Brayakaulung,ETHNICITY,ETHNICITY
SM324038,-115.2216,53.1779,Brazeau No. 77,ADM2:ADM3,DISTRICT
SM290486,22.3261,46.2218,Brazii,ADM2,DISTRICT
SM291500,26.0032,44.8543,Brazii De Sus,ADM2,DISTRICT
SM264140,-52.5745,-3.3377,Brazil Novo,ADM2,DISTRICT
SM265174,-41.5877,-4.1333,Brazileira,ADM2,DISTRICT
SM252498,-39,-12.5,Brazilian,ETHNICITY,ETHNICITY
SM252498,-39,-12.5,Brazilian,ETHNICITY,ETHNICITY
SM252498,-46.64,-23.68,Brazilian,ETHNICITY,ETHNICITY
SM252498,-46.64,-23.68,Brazilian,ETHNICITY,ETHNICITY
SM227684,-48,-15.5,Brazilian Sign Language,LANGUAGE,LANGUAGE
SM302751,-95.4343,29.1678,Brazoria,ADM2,DISTRICT
SM302762,-96.3024,30.6608,Brazos,ADM2,DISTRICT
SM269811,15.2584,-4.2408,Brazzaville,ADM2,DISTRICT
SM748,15.2805,-4.2657,Brazzaville,ADM1,DISTRICT
SM266372,-49.1299,-28.2473,Braço do Norte,ADM2,DISTRICT
SM266373,-49.9138,-27.3789,Braço do Trombudo,ADM2,DISTRICT
SM264073,-42.714,-19.0218,Braúnas,ADM2,DISTRICT
SM738,25.3333,45.75,Brașov,ADM1,DISTRICT
SM305623,16.2932,45.836,Brckovljani,ADM2,DISTRICT
SM296252,13.5433,46.0075,Brda,ADM1:ADM2,DISTRICT
SM305628,15.7641,45.87,Brdovec,ADM2,DISTRICT
SM261754,147.9748,-41.3907,Break O'Day,ADM2,DISTRICT
SM290334,23.6707,44.3492,Breasta,ADM2,DISTRICT
SM12174,-76.4661,18.16,Breastworks,ADM2,DISTRICT
SM301013,-83.3241,37.5216,Breathitt,ADM2,DISTRICT
SM291772,25.3317,47.6244,Breaza,ADM2,DISTRICT
SM291126,24.6097,46.7668,Breaza,ADM2,DISTRICT
SM289855,26.5324,45.0918,Breaza,ADM2,DISTRICT
SM291502,25.6585,45.1871,Breaza De Jos,ADM2,DISTRICT
SM291389,24.4436,44.3596,Brebeni,ADM2,DISTRICT
SM276335,108.9,-7.05,Brebes,ADM2,DISTRICT
SM289993,22.0184,45.4036,Brebu,ADM2,DISTRICT
SM291503,25.7774,45.1941,Brebu Manastirei,ADM2,DISTRICT
SM289994,22.1181,45.2306,Brebu Nou,ADM2,DISTRICT
SM301014,-86.4293,37.7733,Breckinridge,ADM2,DISTRICT
SM285906,4.7604,51.5779,Breda,ADM2,DISTRICT
SM292778,60.4167,52.5,Bredinskiy rayon,ADM2,DISTRICT
SM261876,9.9199,47.4207,Bregenz,ADM2,DISTRICT
SM262480,22.7,44.1167,Bregovo,ADM2,DISTRICT
SM267970,-41.3065,-20.1562,Brejetuba,ADM2,DISTRICT
SM265519,-35.4047,-6.2401,Brejinho,ADM2,DISTRICT
SM264946,-37.3346,-7.3446,Brejinho,ADM2,DISTRICT
SM267208,-48.6469,-11.0187,Brejinho de Nazaré,ADM2,DISTRICT
SM262953,-42.8823,-3.6804,Brejo,ADM2,DISTRICT
SM267064,-50.2181,-21.1776,Brejo Alegre,ADM2,DISTRICT
SM267142,-36.4588,-10.4924,Brejo Grande,ADM2,DISTRICT
SM264141,-48.4557,-5.7437,Brejo Grande do Araguaia,ADM2,DISTRICT
SM267889,-38.882,-7.6223,Brejo Santo,ADM2,DISTRICT
SM264947,-36.2267,-8.0511,Brejo da Madre de deus,ADM2,DISTRICT
SM262952,-45.5397,-4.3245,Brejo de Areia,ADM2,DISTRICT
SM264374,-37.4972,-6.3258,Brejo do Cruz,ADM2,DISTRICT
SM265175,-42.788,-8.2686,Brejo do Piauí,ADM2,DISTRICT
SM264375,-37.8607,-6.3955,Brejo dos Santos,ADM2,DISTRICT
SM267730,-43.9282,-12.4124,Brejolândia,ADM2,DISTRICT
SM264945,-36.5606,-9.036,Brejão,ADM2,DISTRICT
SM267729,-39.821,-13.0706,Brejões,ADM2,DISTRICT
SM227688,96.8365,19.0561,Brek Karen,LANGUAGE,LANGUAGE
SM305487,16.9312,43.369,Brela,ADM2,DISTRICT
SM227689,145.562,-4.6737,Brem,LANGUAGE,LANGUAGE
SM286175,5.2891,61.7686,Bremanger,ADM2,DISTRICT
SM751,8.8333,53.0833,Bremen,ADM1,DISTRICT
SM300872,-92.3181,42.7746,Bremer,ADM2,DISTRICT
SM268900,8.3334,47.3493,Bremgarten,ADM2,DISTRICT
SM13454,25.5845,57.5327,Brengulu pagasts,ADM2,DISTRICT
SM227690,144.707,-4.7327,Breri,LANGUAGE,LANGUAGE
SM277867,10.3356,45.7065,Brescia,ADM2,DISTRICT
SM752,23.7854,52.0998,Brest,ADM1,DISTRICT
SM305400,17.5973,45.3306,Brestovac,ADM2,DISTRICT
SM291958,21.6799,45.8782,Brestovat,ADM2,DISTRICT
SM9758,23.75,52,Brestski Rayon (excl. Brest),ADM2,DISTRICT
SM753,-2.8333,48.1667,Bretagne,ADM1,DISTRICT
SM290207,26.3289,46.0444,Bretcu,ADM2,DISTRICT
SM290741,23.0166,45.6602,Bretea Romana,ADM2,DISTRICT
SM227693,-3.7893,48.2452,Breton,LANGUAGE,LANGUAGE
SM324039,-114.4731,53.1126,Breton,ADM2:ADM3,DISTRICT
SM252499,-3.35,48.43,Bretons,ETHNICITY,ETHNICITY
SM264142,-49.3712,-3.7335,Breu Branco,ADM2,DISTRICT
SM300357,-80.7012,28.3003,Brevard,ADM2,DISTRICT
SM264143,-50.5597,-1.1684,Breves,ADM2,DISTRICT
SM261506,147.1476,-29.9506,Brewarrina,ADM2,DISTRICT
SM302773,-103.2518,29.812,Brewster,ADM2,DISTRICT
SM295064,37.75,58.25,Breytovskiy rayon,ADM2,DISTRICT
SM305517,16.2704,46.0701,Breznica,ADM2,DISTRICT
SM262324,22.85,42.7167,Breznik,ADM2,DISTRICT
SM291115,23.2419,44.5567,Breznita-motru,ADM2,DISTRICT
SM291116,22.6099,44.6927,Breznita-ocol,ADM2,DISTRICT
SM305518,16.2829,46.1078,Breznički Hum,ADM2,DISTRICT
SM296119,19.75,48.8,Brezno,ADM2,DISTRICT
SM290325,25.7666,44.5665,Brezoaele,ADM2,DISTRICT
SM292173,24.2568,45.3509,Brezoi,ADM2,DISTRICT
SM296311,14.4244,45.9489,Brezovica,ADM1:ADM2,DISTRICT
SM262349,25.05,42.4,Brezovo,ADM2,DISTRICT
SM296241,15.6281,45.9289,Brežice,ADM1:ADM2,DISTRICT
SM268588,22.004,6.7539,Bria,ADM2,DISTRICT
SM299581,38.668,48.4989,Briankivs'ka,ADM2,DISTRICT
SM227694,-83.0539,9.3979,Bribri,LANGUAGE,LANGUAGE
SM252500,-83.4585,9.375,Bribri,ETHNICITY,ETHNICITY
SM252500,-83.4585,9.375,Bribri,ETHNICITY,ETHNICITY
SM252500,-83.4585,9.375,Bribri,ETHNICITY,ETHNICITY
SM252500,-83,9,Bribri,ETHNICITY,ETHNICITY
SM252500,-83,9,Bribri,ETHNICITY,ETHNICITY
SM252500,-83,9,Bribri,ETHNICITY,ETHNICITY
SM252500,-83.25,9,Bribri,ETHNICITY,ETHNICITY
SM252500,-83.25,9,Bribri,ETHNICITY,ETHNICITY
SM252500,-83.25,9,Bribri,ETHNICITY,ETHNICITY
SM227695,-83.05,9.4,Bribri Sign Language,LANGUAGE,LANGUAGE
SM754,27,48.25,Briceni,ADM1,DISTRICT
SM270828,-73.9328,5.6873,Briceño,ADM2,DISTRICT
SM270185,-75.509,7.05,Briceño,ADM2,DISTRICT
SM276783,-4.3909,54.3831,Bride,ADM1:ADM2,DISTRICT
SM274760,-3.5833,51.55,Bridgend,ADM1:ADM2,DISTRICT
SM12175,-76.8781,17.9626,Bridgeport,ADM2,DISTRICT
SM261308,116.225,-33.9923,Bridgetown-Greenbushes,ADM2,DISTRICT
SM285638,4.1848,51.8882,Brielle,ADM2,DISTRICT
SM13455,27.5523,56.9723,Briezuciema pagasts,ADM2,DISTRICT
SM268983,8.0119,46.2826,Brig,ADM2,DISTRICT
SM12176,-77.856,18.1484,Brighton,ADM2,DISTRICT
SM261765,147.2423,-42.7267,Brighton,ADM2,DISTRICT
SM274596,-0.1333,50.8333,Brighton and Hove,ADM1:ADM2,DISTRICT
SM13456,28.0675,56.4492,Brigu pagasts,ADM2,DISTRICT
SM261217,144.8164,-37.7429,Brimbank,ADM2,DISTRICT
SM277924,17.6667,40.5833,Brindisi,ADM2,DISTRICT
SM305751,15.1292,44.9986,Brinje,ADM2,DISTRICT
SM261681,153.0299,-27.4457,Brisbane,ADM2,DISTRICT
SM302784,-101.2086,34.5303,Briscoe,ADM2,DISTRICT
SM281997,-102.5712,20.2515,Briseñas,ADM2,DISTRICT
SM302976,-82.1885,36.5965,Bristol,ADM2,DISTRICT
SM302427,-71.2861,41.7055,Bristol,ADM2,DISTRICT
SM301244,-71.0885,41.7571,Bristol,ADM2,DISTRICT
SM274606,-2.6,51.45,Bristol,ADM1:ADM2,DISTRICT
SM301193,-156.8333,58.7506,Bristol Bay,ADM2,DISTRICT
SM9893,-51.1479,-15.2112,Britania,ADM2,DISTRICT
SM252503,-0.42,52.3,British,ETHNICITY,ETHNICITY
SM252503,-0.42,52.3,British,ETHNICITY,ETHNICITY
SM252503,-0.42,52.3,British,ETHNICITY,ETHNICITY
SM252503,-0.42,52.3,British,ETHNICITY,ETHNICITY
SM252503,0,52,British,ETHNICITY,ETHNICITY
SM252503,0,52,British,ETHNICITY,ETHNICITY
SM252503,0,52,British,ETHNICITY,ETHNICITY
SM252503,0,52,British,ETHNICITY,ETHNICITY
SM252503,-4.9,50.03,British,ETHNICITY,ETHNICITY
SM252503,-4.9,50.03,British,ETHNICITY,ETHNICITY
SM252503,-4.9,50.03,British,ETHNICITY,ETHNICITY
SM252503,-4.9,50.03,British,ETHNICITY,ETHNICITY
SM252503,0.73,51.19,British,ETHNICITY,ETHNICITY
SM252503,0.73,51.19,British,ETHNICITY,ETHNICITY
SM252503,0.73,51.19,British,ETHNICITY,ETHNICITY
SM252503,0.73,51.19,British,ETHNICITY,ETHNICITY
SM755,-125.0032,53.9998,British Columbia,ADM1,DISTRICT
SM227698,-0.4431,51.6688,British Sign Language,LANGUAGE,LANGUAGE
SM13457,24.9522,57.6699,Brivzemnieku pagasts,ADM2,DISTRICT
SM12177,-77.3174,18.0204,Brixton Hill,ADM2,DISTRICT
SM303650,-66.1667,10.4167,Brión,ADM2,DISTRICT
SM271208,16.6076,49.1995,Brno,ADM2,DISTRICT
SM271209,16.5,49.1667,Brno-Venkov,ADM2,DISTRICT
SM12178,-76.8079,18.2285,Broadgate,ADM2,DISTRICT
SM301655,-111.4955,46.332,Broadwater,ADM2,DISTRICT
SM13458,22.5763,56.6782,Broceni,ADM2,DISTRICT
SM3963,22.6567,56.7142,Brocenu Novads,ADM1,DISTRICT
SM266020,-51.5919,-29.5575,Brochier,ADM2,DISTRICT
SM305420,14.9703,45.4631,Brod Moravice,ADM2,DISTRICT
SM291773,25.3926,47.8515,Brodina,ADM2,DISTRICT
SM288855,19.4228,53.2698,Brodnica,ADM2,DISTRICT
SM267066,-47.6173,-21.0506,Brodosqui,ADM2,DISTRICT
SM305571,17.8158,45.1681,Brodski Stupnik,ADM2,DISTRICT
SM756,17.75,45.1667,Brodsko-Posavska,ADM1,DISTRICT
SM261507,141.4333,-31.95,Broken Hill,ADM2,DISTRICT
SM327839,-96.5,50.0781,Brokenhead,ADM2:ADM3,DISTRICT
SM757,-55.1667,4.75,Brokopondo,ADM1,DISTRICT
SM227702,91.9972,27.3438,Brokpake,LANGUAGE,LANGUAGE
SM227701,76.6026,34.5308,Brokskat,LANGUAGE,LANGUAGE
SM304806,48.7281,14.3423,Brom Mayfa,ADM2,DISTRICT
SM12179,-77.8882,18.068,Brompton,ADM2,DISTRICT
SM296456,14.5115,56.1193,Bromölla,ADM2,DISTRICT
SM301889,-73.9236,40.8273,Bronx,ADM2,DISTRICT
SM303152,-80.5764,40.2738,Brooke,ADM2,DISTRICT
SM227707,117.871,8.8389,Brooke's Point Palawano,LANGUAGE,LANGUAGE
SM302522,-96.7904,44.3697,Brookings,ADM2,DISTRICT
SM302795,-98.2187,27.0316,Brooks,ADM2,DISTRICT
SM300425,-83.5802,30.842,Brooks,ADM2,DISTRICT
SM323842,-111.892,50.5648,Brooks,ADM2:ADM3,DISTRICT
SM261319,116.9999,-32.3553,Brookton,ADM2,DISTRICT
SM301900,-75.8196,42.1602,Broome,ADM2,DISTRICT
SM261330,122.6404,-17.9404,Broome,ADM2,DISTRICT
SM227706,122.233,-17.927,Broome Pearling Lugger Pidgin,LANGUAGE,LANGUAGE
SM459029,122.2989,-17.93,Broome Pearling Lugger Pidgin,ETHNICITY,ETHNICITY
SM261341,117.653,-34.0132,Broomehill-Tambellup,ADM2,DISTRICT
SM303380,-105.0527,39.9541,Broomfield,ADM2,DISTRICT
SM292602,26.4665,47.956,Broscauti,ADM2,DISTRICT
SM292394,27.0131,45.7657,Brosteni,ADM2,DISTRICT
SM291774,25.7003,47.2367,Brosteni,ADM2,DISTRICT
SM291117,22.9908,44.747,Brosteni,ADM2,DISTRICT
SM267067,-48.0874,-22.2794,Brotas,ADM2,DISTRICT
SM267731,-42.5111,-12.1061,Brotas de Macaúbas,ADM2,DISTRICT
SM12180,-78.2289,18.2175,Broughton,ADM2,DISTRICT
SM299505,30.8012,50.5078,Brovars'ka,ADM2,DISTRICT
SM18190,31,50.5833,Brovary Raion,ADM2,DISTRICT
SM300368,-80.4559,26.1519,Broward,ADM2,DISTRICT
SM303265,-87.9929,44.4743,Brown,ADM2,DISTRICT
SM302533,-98.3516,45.5897,Brown,ADM2,DISTRICT
SM302806,-98.9998,31.7743,Brown,ADM2,DISTRICT
SM301766,-99.9295,42.43,Brown,ADM2,DISTRICT
SM302160,-83.8674,38.9341,Brown,ADM2,DISTRICT
SM301415,-94.7275,44.2422,Brown,ADM2,DISTRICT
SM300955,-95.5642,39.8265,Brown,ADM2,DISTRICT
SM300757,-86.2274,39.1962,Brown,ADM2,DISTRICT
SM300636,-90.7503,39.9618,Brown,ADM2,DISTRICT
SM12181,-77.1186,18.0536,Browns Hill,ADM2,DISTRICT
SM12182,-77.3529,18.3846,Browns Town,ADM2,DISTRICT
SM296051,-55.1695,5.014,Brownsweg,ADM2,DISTRICT
SM268999,6.9016,46.7808,Broye-Vully,ADM2,DISTRICT
SM305674,13.6255,45.3792,Brtonigla,ADM2,DISTRICT
SM268865,-81.2497,44.5001,Bruce,ADM2,DISTRICT
SM261343,118.0239,-31.9665,Bruce Rock,ADM2,DISTRICT
SM17245,-60.9491,13.7256,Bruceville/Shanty Town,ADM2,DISTRICT
SM261821,16.7728,48.0678,Bruck an der Leitha,ADM2,DISTRICT
SM261852,15.2751,47.4188,Bruck-Mürzzuschlag,ADM2,DISTRICT
SM323790,-112.9313,53.8042,Bruderheim,ADM2:ADM3,DISTRICT
SM268901,8.178,47.4778,Brugg,ADM2,DISTRICT
SM291712,24.7034,45.8635,Bruiu,ADM2,DISTRICT
SM302541,-99.0809,43.7181,Brule,ADM2,DISTRICT
SM264074,-44.1539,-20.2045,Brumadinho,ADM2,DISTRICT
SM267732,-41.6038,-14.1527,Brumado,ADM2,DISTRICT
SM285828,6.1179,52.1106,Brummen,ADM2,DISTRICT
SM13459,24.4323,56.308,Brunavas pagasts,ADM2,DISTRICT
SM227721,-84.8214,9.9786,Brunca Sign Language,LANGUAGE,LANGUAGE
SM227716,114.4295,4.456,Brunei,LANGUAGE,LANGUAGE
SM252508,114.79,2.89,Brunei,ETHNICITY,ETHNICITY
SM227719,114.517,4.6079,Brunei Bisaya,LANGUAGE,LANGUAGE
SM759,114.9167,4.9167,Brunei and Muara,ADM1,DISTRICT
SM243450,117.024,4.6481,Brunei-Sabah Malay,LANGUAGE,LANGUAGE
SM285897,5.9762,50.9445,Brunssum,ADM2,DISTRICT
SM302977,-77.859,36.7648,Brunswick,ADM2,DISTRICT
SM301931,-78.2273,34.039,Brunswick,ADM2,DISTRICT
SM271230,17.4167,50,Bruntál,ADM2,DISTRICT
SM227715,147.2479,-43.3731,Bruny Island,LANGUAGE,LANGUAGE
SM266374,-50.8363,-27.3478,Brunópolis,ADM2,DISTRICT
SM275943,-84.6994,15.4851,Brus Laguna,ADM2,DISTRICT
SM262306,23.0667,43.6667,Brusartsi,ADM2,DISTRICT
SM252509,75,37,Brushaski,ETHNICITY,ETHNICITY
SM252509,75,37,Brushaski,ETHNICITY,ETHNICITY
SM252509,75,37,Brushaski,ETHNICITY,ETHNICITY
SM252509,75,37,Brushaski,ETHNICITY,ETHNICITY
SM252509,74.58,36.43,Brushaski,ETHNICITY,ETHNICITY
SM252509,74.58,36.43,Brushaski,ETHNICITY,ETHNICITY
SM252509,74.58,36.43,Brushaski,ETHNICITY,ETHNICITY
SM252509,74.58,36.43,Brushaski,ETHNICITY,ETHNICITY
SM252509,74,36.4984,Brushaski,ETHNICITY,ETHNICITY
SM252509,74,36.4984,Brushaski,ETHNICITY,ETHNICITY
SM252509,74,36.4984,Brushaski,ETHNICITY,ETHNICITY
SM252509,74,36.4984,Brushaski,ETHNICITY,ETHNICITY
SM252509,74,36.5,Brushaski,ETHNICITY,ETHNICITY
SM252509,74,36.5,Brushaski,ETHNICITY,ETHNICITY
SM252509,74,36.5,Brushaski,ETHNICITY,ETHNICITY
SM252509,74,36.5,Brushaski,ETHNICITY,ETHNICITY
SM266375,-48.9039,-27.1134,Brusque,ADM2,DISTRICT
SM291319,26.3544,47.2958,Brusturi,ADM2,DISTRICT
SM292402,22.2651,47.1441,Brusturi,ADM2,DISTRICT
SM292088,26.1935,46.519,Brusturoasa,ADM2,DISTRICT
SM262092,4.3466,50.8488,Bruxelles,ADM1:ADM2,DISTRICT
SM760,4.3514,50.8533,Bruxelles,ADM1,DISTRICT
SM303788,-68.8833,10.1667,Bruzual,ADM2,DISTRICT
SM303710,-65.1652,9.9399,Bruzual,ADM2,DISTRICT
SM761,21.0333,41.8833,Brvenica,ADM1,DISTRICT
SM302238,-96.2598,33.9623,Bryan,ADM2,DISTRICT
SM300436,-81.4425,32.0132,Bryan,ADM2,DISTRICT
SM295016,34.4167,53.25,Bryansk,ADM2,DISTRICT
SM762,33.5,53,Bryansk,ADM1,DISTRICT
SM295027,34.4167,53.25,Bryanskiy rayon,ADM2,DISTRICT
SM293410,39.1337,45.8382,Bryukhovetskiy rayon,ADM2,DISTRICT
SM288993,17.485,50.7893,Brzeg,ADM2,DISTRICT
SM288940,20.6057,49.9717,Brzesko,ADM2,DISTRICT
SM288878,19.8304,51.8407,Brzeziny,ADM2,DISTRICT
SM289016,22.0904,49.7276,Brzozów,ADM2,DISTRICT
SM296637,15.6661,62.8547,Bräcke,ADM2,DISTRICT
SM744,21,60.4167,Brändö,ADM1:ADM2,DISTRICT
SM271684,12.411,55.6429,Brøndby,ADM2,DISTRICT
SM271711,10.1006,57.2351,Brønderslev (2007-),ADM2,DISTRICT
SM286099,12.2063,65.4679,Brønnøy,ADM2,DISTRICT
SM741,27.6667,45.0833,Brăila,ADM1,DISTRICT
SM749,18.8333,44.8333,Brčko,ADM1,DISTRICT
SM277976,35.6153,30.6798,Bsaira,ADM2,DISTRICT
SM227985,8.657,9.0508,Bu,LANGUAGE,LANGUAGE
SM295835,42.7408,1.198,Bu'aale,ADM2,DISTRICT
SM227861,107.711,24.1025,Bu-Nao Bunu,LANGUAGE,LANGUAGE
SM227724,18.0821,9.9249,Bua,LANGUAGE,LANGUAGE
SM274414,178.75,-16.8333,Bua,ADM2,DISTRICT
SM297637,103.9781,14.4841,Bua Chet,ADM2,DISTRICT
SM297123,102.3874,15.5849,Bua Yai,ADM2,DISTRICT
SM763,166.9209,-0.5353,Buada,ADM1,DISTRICT
SM295388,159.5608,-8.1442,Buala,ADM2,DISTRICT
SM280866,105.9408,17.3033,Bualapha,ADM2,DISTRICT
SM227729,93.68,22.91,Bualkhaw Chin,LANGUAGE,LANGUAGE
SM227731,-3.503,11.1687,Buamu,LANGUAGE,LANGUAGE
SM280574,126.6667,35.7,Buan,ADM2,DISTRICT
SM297337,101.004,15.8165,Buang Sam Phan,ADM2,DISTRICT
SM275105,-15,11.5756,Buba,ADM2,DISTRICT
SM261959,29.3696,-3.0521,Bubanza,ADM2,DISTRICT
SM764,29.4,-3.1167,Bubanza,ADM1,DISTRICT
SM275087,-15.8333,11.2833,Bubaque,ADM2,DISTRICT
SM227735,8.6893,3.5364,Bube,LANGUAGE,LANGUAGE
SM227738,12.3225,-1.1676,Bubi,LANGUAGE,LANGUAGE
SM305267,28.68,-19.5251,Bubi,ADM2,DISTRICT
SM250128,8.72,3.54,Bubi,ETHNICITY,ETHNICITY
SM227737,9.223,4.0218,Bubia,LANGUAGE,LANGUAGE
SM298555,27.2505,38.3481,Buca,ADM2,DISTRICT
SM298238,30.5996,37.4703,Bucak,ADM2,DISTRICT
SM15487,-66.5948,17.979,Bucana Barrio,ADM2,DISTRICT
SM15488,-66.9455,18.2076,Bucarabones Barrio (Las Marías),ADM2,DISTRICT
SM15489,-66.9378,18.186,Bucarabones Barrio (Maricao),ADM2,DISTRICT
SM270527,-73.1328,7.1372,Bucaramanga,ADM2,DISTRICT
SM270394,-72.9291,8.0807,Bucarasica,ADM2,DISTRICT
SM292613,26.4397,47.7774,Bucecea,ADM2,DISTRICT
SM290742,22.9772,46.1913,Buces,ADM2,DISTRICT
SM457989,29.8006,50.5448,Bucha Raion,ADM2,DISTRICT
SM302978,-82.036,37.2666,Buchanan,ADM2,DISTRICT
SM301520,-94.8062,39.6599,Buchanan,ADM2,DISTRICT
SM300785,-91.8378,42.4708,Buchanan,ADM2,DISTRICT
SM370331,-10.0417,5.8781,Buchanan City,ADM2:ADM3,DISTRICT
SM765,26.0833,44.5,Bucharest,ADM1,DISTRICT
SM299746,25.3958,49.062,Buchats'kyi,ADM2,DISTRICT
SM268954,7.4789,47.1321,Bucheggberg,ADM2,DISTRICT
SM280727,126.7663,37.5044,Bucheon,ADM2,DISTRICT
SM289995,22.2088,45.3476,Buchin,ADM2,DISTRICT
SM303581,-70.75,10.9167,Buchivacoa,ADM2,DISTRICT
SM291392,24.2546,43.942,Bucinisu,ADM2,DISTRICT
SM289779,23.1574,46.2613,Bucium,ADM2,DISTRICT
SM290245,25.4639,45.159,Buciumeni,ADM2,DISTRICT
SM290426,27.3192,45.977,Buciumeni,ADM2,DISTRICT
SM291646,23.0255,47.0348,Buciumi,ADM2,DISTRICT
SM302979,-78.5287,37.5722,Buckingham,ADM2,DISTRICT
SM274607,-0.75,51.75,Buckinghamshire,ADM1:ADM2,DISTRICT
SM12183,-77.2375,18.0062,Bucknor,ADM2,DISTRICT
SM302351,-75.1069,40.3369,Bucks,ADM2,DISTRICT
SM12184,-77.2385,17.9823,Bucks Common,ADM2,DISTRICT
SM260413,12.5468,-4.7951,Buco Zau,ADM2,DISTRICT
SM289996,22.2686,45.3065,Bucosnita,ADM2,DISTRICT
SM291504,26.103,44.9844,Bucov,ADM2,DISTRICT
SM290335,23.6951,44.2945,Bucovat,ADM2,DISTRICT
SM290603,25.6508,44.3581,Bucsani,ADM2,DISTRICT
SM290246,25.6456,44.8527,Bucsani,ADM2,DISTRICT
SM282994,-88.6698,21.2237,Buctzotz,ADM2,DISTRICT
SM290743,22.9305,46.1239,Bucuresci,ADM2,DISTRICT
SM289856,26.9043,45.5091,Buda,ADM2,DISTRICT
SM9759,30.5,52.6667,Buda-Kashalyowski Rayon (excl. Uvarovichi),ADM2,DISTRICT
SM292534,24.5184,47.0821,Budacu De Jos,ADM2,DISTRICT
SM299382,33.9922,1.0525,Budaka,ADM1:ADM2,DISTRICT
SM11539,18.8498,47.4979,Budakeszi Jaras,ADM2,DISTRICT
SM766,19.0833,47.5,Budapest,ADM1,DISTRICT
SM11540,19.0222,47.5172,Budapest II.,ADM2,DISTRICT
SM11541,19.045,47.5674,Budapest III.,ADM2,DISTRICT
SM11542,19.0891,47.5667,Budapest IV.,ADM2,DISTRICT
SM11543,19.0908,47.466,Budapest IX.,ADM2,DISTRICT
SM11544,19.0512,47.5,Budapest V.,ADM2,DISTRICT
SM11545,19.0658,47.5097,Budapest VI.,ADM2,DISTRICT
SM11546,19.0722,47.5006,Budapest VII.,ADM2,DISTRICT
SM11547,19.0701,47.4894,Budapest VIII.,ADM2,DISTRICT
SM11548,19.1584,47.4764,Budapest X.,ADM2,DISTRICT
SM11549,19.0361,47.475,Budapest XI.,ADM2,DISTRICT
SM11550,19.0149,47.5,Budapest XII.,ADM2,DISTRICT
SM11551,19.0698,47.5186,Budapest XIII.,ADM2,DISTRICT
SM11552,19.1055,47.5,Budapest XIV.,ADM2,DISTRICT
SM11553,19.1494,47.45,Budapest XIX.,ADM2,DISTRICT
SM11554,19.1168,47.5667,Budapest XV.,ADM2,DISTRICT
SM11555,19.1703,47.5154,Budapest XVI.,ADM2,DISTRICT
SM11556,19.2539,47.4803,Budapest XVII.,ADM2,DISTRICT
SM11557,19.176,47.4333,Budapest XVIII.,ADM2,DISTRICT
SM11558,19.1009,47.4333,Budapest XX.,ADM2,DISTRICT
SM11559,19.071,47.4167,Budapest XXI.,ADM2,DISTRICT
SM11560,19.0402,47.425,Budapest XXII.,ADM2,DISTRICT
SM11561,19.1149,47.3964,Budapest XXIII.,ADM2,DISTRICT
SM276246,19.0396,47.4975,Budapesti,ADM2,DISTRICT
SM277277,78.9833,28.1167,Budaun (2012-),ADM2,DISTRICT
SM291221,24.8025,44.9504,Budeasa Mare,ADM2,DISTRICT
SM227745,106.691,11.6917,Budeh Stieng,LANGUAGE,LANGUAGE
SM292174,24.366,45.0471,Budesti,ADM2,DISTRICT
SM290993,23.9457,47.7462,Budesti,ADM2,DISTRICT
SM292543,24.238,46.834,Budesti,ADM2,DISTRICT
SM227748,153.689,-9.298,Budibud,LANGUAGE,LANGUAGE
SM252513,117.64,-22.45,Budidjara,ETHNICITY,ETHNICITY
SM252513,117.64,-22.45,Budidjara,ETHNICITY,ETHNICITY
SM252513,122.6148,-24.2952,Budidjara,ETHNICITY,ETHNICITY
SM252513,122.6148,-24.2952,Budidjara,ETHNICITY,ETHNICITY
SM292667,25.8079,45.6743,Budila,ADM2,DISTRICT
SM305735,16.2041,46.1279,Budinšćina,ADM2,DISTRICT
SM299276,33.1427,1.2663,Budiope,ADM1:ADM2,DISTRICT
SM250131,23,3,Budja,ETHNICITY,ETHNICITY
SM227751,119.327,-2.0776,Budong-Budong,LANGUAGE,LANGUAGE
SM227754,27.951,2.005,Budu,LANGUAGE,LANGUAGE
SM250129,29,2.11,Budu,ETHNICITY,ETHNICITY
SM250129,29,2.11,Budu,ETHNICITY,ETHNICITY
SM250129,28,2,Budu,ETHNICITY,ETHNICITY
SM250129,28,2,Budu,ETHNICITY,ETHNICITY
SM227752,48.3353,41.2272,Budukh,LANGUAGE,LANGUAGE
SM227756,14.4492,13.0692,Buduma,LANGUAGE,LANGUAGE
SM250130,11.8,12.54,Buduma,ETHNICITY,ETHNICITY
SM250130,11.8,12.54,Buduma,ETHNICITY,ETHNICITY
SM250130,15,13,Buduma,ETHNICITY,ETHNICITY
SM250130,15,13,Buduma,ETHNICITY,ETHNICITY
SM292403,22.4642,46.6697,Budureasa,ADM2,DISTRICT
SM292404,22.2539,47.3956,Buduslau,ADM2,DISTRICT
SM767,18.8414,42.3,Budva,ADM1,DISTRICT
SM227758,22.6107,2.1995,Budza,LANGUAGE,LANGUAGE
SM250685,0.46,7.22,Buem,ETHNICITY,ETHNICITY
SM273484,-79.4022,-0.616,Buena Fé,ADM2,DISTRICT
SM302980,-79.3539,37.7343,Buena Vista,ADM2,DISTRICT
SM300786,-95.1512,42.7355,Buena Vista,ADM2,DISTRICT
SM15492,-66.1907,18.3322,Buena Vista Barrio (Bayamón),ADM2,DISTRICT
SM15493,-66.8051,18.4094,Buena Vista Barrio (Hatillo),ADM2,DISTRICT
SM15490,-65.7988,18.1157,Buena Vista Barrio (Humacao),ADM2,DISTRICT
SM15494,-65.9463,18.4081,Buena Vista Barrio (Inactive),ADM2,DISTRICT
SM15491,-66.9637,18.2503,Buena Vista Barrio (Las Marías),ADM2,DISTRICT
SM227759,-119.2375,35.2204,Buena Vista Yokuts,LANGUAGE,LANGUAGE
SM283212,-107.1931,30.0212,Buenaventura,ADM2,DISTRICT
SM270643,-77.0355,3.8643,Buenaventura,ADM2,DISTRICT
SM282002,-102.5847,19.188,Buenavista,ADM2,DISTRICT
SM270417,-75.7438,4.3665,Buenavista,ADM2,DISTRICT
SM270528,-74.9483,9.3324,Buenavista,ADM2,DISTRICT
SM270718,-73.9667,5.5,Buenavista,ADM2,DISTRICT
SM269942,-75.4448,8.177,Buenavista,ADM2,DISTRICT
SM281586,-99.4119,18.4692,Buenavista de Cuéllar,ADM2,DISTRICT
SM768,103.7509,18.1623,Bueng Kan (2011-),ADM1,DISTRICT
SM260382,15.8567,-6.6603,Buengas,ADM2,DISTRICT
SM264075,-46.3527,-22.5046,Bueno Brandão,ADM2,DISTRICT
SM285482,-85.7833,11.5176,Buenos Aires,ADM2,DISTRICT
SM270951,-83.2553,9.1026,Buenos Aires,ADM2,DISTRICT
SM269883,-76.6307,3.0483,Buenos Aires,ADM2,DISTRICT
SM264948,-35.32,-7.72,Buenos Aires,ADM2,DISTRICT
SM769,-60,-36,Buenos Aires,ADM1,DISTRICT
SM15495,-66.8468,18.2657,Buenos Aires Barrio,ADM2,DISTRICT
SM264076,-44.0237,-17.8746,Buenópolis,ADM2,DISTRICT
SM267733,-39.3059,-14.9907,Buerarema,ADM2,DISTRICT
SM270358,-77.1237,1.3293,Buesaco,ADM2,DISTRICT
SM271107,-76.759,20.1736,Buey Arriba,ADM2,DISTRICT
SM12185,-76.6604,18.2325,Buff Bay,ADM2,DISTRICT
SM303276,-91.7545,44.3798,Buffalo,ADM2,DISTRICT
SM302542,-99.205,44.0764,Buffalo,ADM2,DISTRICT
SM301679,-99.075,40.8552,Buffalo,ADM2,DISTRICT
SM305098,27.6116,-32.9514,Buffalo City,ADM2,DISTRICT
SM290982,25.9563,44.5548,Buftea,ADM2,DISTRICT
SM286685,-82.6794,8.6879,Bugaba,ADM2,DISTRICT
SM261964,30.0198,-2.4297,Bugabira,ADM2,DISTRICT
SM270644,-76.0833,4.1667,Bugalagrande,ADM2,DISTRICT
SM227764,104.963,23.6124,Bugan,LANGUAGE,LANGUAGE
SM262059,29.1887,-2.9648,Buganda,ADM2,DISTRICT
SM299297,31.3249,0.7826,Bugangaizi,ADM1:ADM2,DISTRICT
SM262032,29.4004,-3.6905,Bugarama,ADM2,DISTRICT
SM283772,103.666,49.0586,Bugat,ADM2,DISTRICT
SM283692,90.0197,48.9474,Bugat,ADM2,DISTRICT
SM283553,94.3647,45.5493,Bugat,ADM2,DISTRICT
SM227767,147.32,-6.6752,Bugawac,LANGUAGE,LANGUAGE
SM262065,29.947,-3.252,Bugendana,ADM2,DISTRICT
SM262076,30.0606,-3.0787,Bugenyuzi,ADM2,DISTRICT
SM295149,30.1483,-2.2346,Bugesera,ADM1:ADM2,DISTRICT
SM291222,25.0032,45.274,Bughea De Jos,ADM2,DISTRICT
SM227770,159.802,-8.4795,Bughotu,LANGUAGE,LANGUAGE
SM259996,65.0421,33.0141,Bughran,ADM2,DISTRICT
SM227772,119.977,-4.47,Buginese,LANGUAGE,LANGUAGE
SM4765,33.79,0.5333,Bugiri (2010-),ADM1:ADM2,DISTRICT
SM227775,-81.2565,8.577,Buglere,LANGUAGE,LANGUAGE
SM264077,-42.3082,-19.367,Bugre,ADM2,DISTRICT
SM294607,52.8,54.55,Bugul'ma,ADM2,DISTRICT
SM294608,52.9167,54.5833,Bugul'minskiy rayon,ADM2,DISTRICT
SM227779,92.4713,27.193,Bugun,LANGUAGE,LANGUAGE
SM458885,132.7021,-12.7659,Bugurnidja,ETHNICITY,ETHNICITY
SM298153,28.7515,37.974,Buharkent,ADM2,DISTRICT
SM305230,31.9316,-19.4566,Buhera,ADM2,DISTRICT
SM227784,123.5,13.17,Buhi,DIALECT,LANGUAGE
SM227786,121.21,12.6396,Buhid,LANGUAGE,LANGUAGE
SM252516,121.32,13.27,Buhid,ETHNICITY,ETHNICITY
SM262077,30.1876,-3.0855,Buhiga,ADM2,DISTRICT
SM299240,29.9137,-4.4509,Buhigwe,ADM2,DISTRICT
SM261983,30.3783,-2.9798,Buhinyuza,ADM2,DISTRICT
SM292089,27.0082,46.5658,Buhoci,ADM2,DISTRICT
SM292090,26.6995,46.7185,Buhusi,ADM2,DISTRICT
SM227787,150.18,-10.5074,Buhutu,LANGUAGE,LANGUAGE
SM299409,30.3249,-0.3287,Buhweju,ADM1:ADM2,DISTRICT
SM269530,10.75,6.25,Bui,ADM2,DISTRICT
SM459085,142.4199,-35.7149,Buibadjali,ETHNICITY,ETHNICITY
SM299364,33.0518,0.3012,Buikwe,ADM1:ADM2,DISTRICT
SM11390,-1.3345,10.5382,Builsa South,ADM2,DISTRICT
SM11759,50.1464,33.0724,Buin va Miandasht,ADM2,DISTRICT
SM295325,157.7565,-8.4043,Buini Tusu,ADM2,DISTRICT
SM16874,48.25,55,Buinskiy Rayon (excl. Buinsk),ADM2,DISTRICT
SM262797,-68.172,-9.5786,Bujari,ADM2,DISTRICT
SM264144,-48.053,-1.6532,Bujaru,ADM2,DISTRICT
SM10500,13.6591,45.4079,Buje-Buie (excl. Grad Buje),ADM2,DISTRICT
SM227790,84.5068,28.0084,Bujhyal,LANGUAGE,LANGUAGE
SM284804,9.7201,11.5791,Buji,ADM2,DISTRICT
SM291954,25.6515,44.1205,Bujoreni,ADM2,DISTRICT
SM291868,25.5632,43.7109,Bujoru,ADM2,DISTRICT
SM771,29.3547,-3.3802,Bujumbura Mairie,ADM1:ADMX,DISTRICT
SM3890,29.4626,-3.4627,Bujumbura Rural (2015-),ADM1,DISTRICT
SM280636,129.3605,35.5824,Buk,ADM2,DISTRICT
SM280668,128.5918,35.9,Buk,ADM2,DISTRICT
SM280699,126.9244,35.1923,Buk,ADM2,DISTRICT
SM280527,128.9913,35.1972,Buk,ADM2,DISTRICT
SM461496,154.673,-5.422,Buka,ETHNICITY,ETHNICITY
SM13460,23.2185,56.4129,Bukaisu pagasts,ADM2,DISTRICT
SM277637,46.1666,36.6,Bukan,ADM2,DISTRICT
SM227795,110.657,0.9682,Bukar-Sadung Bidayuh,LANGUAGE,LANGUAGE
SM227799,113.246,1.2908,Bukat,LANGUAGE,LANGUAGE
SM269691,28.8667,-2.5,Bukavu,ADM2,DISTRICT
SM299307,34.1333,1.3667,Bukedea,ADM1:ADM2,DISTRICT
SM262005,30.0735,-4.0237,Bukemba,ADM2,DISTRICT
SM261978,29.6045,-3.1931,Bukeye,ADM2,DISTRICT
SM13178,73.6812,49.8566,Bukhar-Zhyrau,ADM2,DISTRICT
SM773,64,40,Bukhara (1981-),ADM1,DISTRICT
SM227802,64,41,Bukharic,LANGUAGE,LANGUAGE
SM774,125,8,Bukidnon,ADM1:ADM2,DISTRICT
SM252518,125.45,8.77,Bukidnon,ETHNICITY,ETHNICITY
SM262060,29.3586,-2.8682,Bukinanyana,ADM2,DISTRICT
SM262068,29.9668,-3.7018,Bukirasazi,ADM2,DISTRICT
SM227804,115.64,-2.7505,Bukit Malay,LANGUAGE,LANGUAGE
SM227805,114.895,2.3495,Bukitan,LANGUAGE,LANGUAGE
SM252519,114.05,3.06,Bukitan,ETHNICITY,ETHNICITY
SM276650,100.3722,-0.2992,Bukittinggi,ADM2,DISTRICT
SM227806,143.111,-3.4717,Bukiyip,LANGUAGE,LANGUAGE
SM285283,5.5865,11.9747,Bukkuyum,ADM2,DISTRICT
SM299227,31.6454,-1.4157,Bukoba Rural,ADM2,DISTRICT
SM299228,31.8043,-1.3208,Bukoba Urban,ADM2,DISTRICT
SM299330,31.6253,-0.1286,Bukomansimbi,ADM1:ADM2,DISTRICT
SM299216,31.686,-3.573,Bukombe,ADM2,DISTRICT
SM305576,18.0681,45.1848,Bukovlje,ADM2,DISTRICT
SM227810,79.4233,29.0853,Buksa,LANGUAGE,LANGUAGE
SM255935,34.99,1.22,Bukusi,ETHNICITY,ETHNICITY
SM255935,34.99,1.22,Bukusi,ETHNICITY,ETHNICITY
SM255935,32.36,0.89,Bukusi,ETHNICITY,ETHNICITY
SM255935,32.36,0.89,Bukusi,ETHNICITY,ETHNICITY
SM255935,35,1,Bukusi,ETHNICITY,ETHNICITY
SM255935,35,1,Bukusi,ETHNICITY,ETHNICITY
SM227812,34.7719,0.6804,Bukusu,LANGUAGE,LANGUAGE
SM227815,10.2006,6.9415,Bukwen,LANGUAGE,LANGUAGE
SM275090,-15.7077,12.1058,Bula,ADM2,DISTRICT
SM260432,14.8022,-8.6717,Bula Atumba,ADM2,DISTRICT
SM17857,41.8748,3.924,Bula Hawa (excl. Belet Xaawo town),ADM2,DISTRICT
SM775,121.0833,15,Bulacan,ADM1:ADM2,DISTRICT
SM13179,70.5,55,Bulaevo Audany (excl. Vozvyshenka),ADM2,DISTRICT
SM252521,142.64,-31.61,Bulalli,ETHNICITY,ETHNICITY
SM252521,142.64,-31.61,Bulalli,ETHNICITY,ETHNICITY
SM252521,141.7698,-31.4896,Bulalli,ETHNICITY,ETHNICITY
SM252521,141.7698,-31.4896,Bulalli,ETHNICITY,ETHNICITY
SM299395,34.2806,1.3205,Bulambuli,ADM1:ADM2,DISTRICT
SM299278,33.4876,1.0778,Bulamogi,ADM1:ADM2,DISTRICT
SM298437,38.2315,40.938,Bulancak,ADM2,DISTRICT
SM277278,77.8333,28.4167,Bulandshahr (1997-),ADM2,DISTRICT
SM279922,70,52.1667,Bulandynskiy,ADM2,DISTRICT
SM298779,42.2732,39.0929,Bulanık,ADM2,DISTRICT
SM227817,138.1567,-21.0266,Bularnu,LANGUAGE,LANGUAGE
SM458468,138.2747,-20.6567,Bularnu,ETHNICITY,ETHNICITY
SM305220,28.551,-20.1335,Bulawayo,ADM2,DISTRICT
SM776,28.55,-20.1417,Bulawayo,ADM1,DISTRICT
SM290604,25.8228,44.2954,Bulbucata,ADM2,DISTRICT
SM298317,28.8401,38.0554,Buldan,ADM2,DISTRICT
SM277065,76.4167,20.5833,Buldana,ADM2,DISTRICT
SM276475,114.9667,-8.25,Buleleng,ADM2,DISTRICT
SM292175,24.245,45.0328,Buleta,ADM2,DISTRICT
SM283533,101.13,47.2089,Bulgan,ADM2,DISTRICT
SM283533,101.13,47.2089,Bulgan,ADM2,DISTRICT
SM283628,103.543,44.0914,Bulgan,ADM2,DISTRICT
SM283588,91.5328,46.0961,Bulgan,ADM2,DISTRICT
SM283693,91.0871,46.9207,Bulgan,ADM2,DISTRICT
SM283533,101.1159,47.315,Bulgan,ADM2,DISTRICT
SM283533,101.1159,47.315,Bulgan,ADM2,DISTRICT
SM283791,113.9334,47.9945,Bulgan,ADM2,DISTRICT
SM777,103.5,49,Bulgan (1979-),ADM1,DISTRICT
SM15001,103.5389,48.8039,Bulgan District,ADM2,DISTRICT
SM227826,25.047,43.3646,Bulgarian,LANGUAGE,LANGUAGE
SM252522,23.11,43.58,Bulgarian,ETHNICITY,ETHNICITY
SM252522,23.11,43.58,Bulgarian,ETHNICITY,ETHNICITY
SM252522,23.11,43.58,Bulgarian,ETHNICITY,ETHNICITY
SM252522,23.11,43.58,Bulgarian,ETHNICITY,ETHNICITY
SM252522,23,43,Bulgarian,ETHNICITY,ETHNICITY
SM252522,23,43,Bulgarian,ETHNICITY,ETHNICITY
SM252522,23,43,Bulgarian,ETHNICITY,ETHNICITY
SM252522,23,43,Bulgarian,ETHNICITY,ETHNICITY
SM252522,24,43,Bulgarian,ETHNICITY,ETHNICITY
SM252522,24,43,Bulgarian,ETHNICITY,ETHNICITY
SM252522,24,43,Bulgarian,ETHNICITY,ETHNICITY
SM252522,24,43,Bulgarian,ETHNICITY,ETHNICITY
SM252522,24.7412,42.1634,Bulgarian,ETHNICITY,ETHNICITY
SM252522,24.7412,42.1634,Bulgarian,ETHNICITY,ETHNICITY
SM252522,24.7412,42.1634,Bulgarian,ETHNICITY,ETHNICITY
SM252522,24.7412,42.1634,Bulgarian,ETHNICITY,ETHNICITY
SM227825,25.0026,42.7918,Bulgarian Sign Language,LANGUAGE,LANGUAGE
SM227829,146.403,-5.6971,Bulgebi,LANGUAGE,LANGUAGE
SM227831,-1.2675,10.5763,Buli (Ghana),LANGUAGE,LANGUAGE
SM227832,128.443,1.0008,Buli (Indonesia),LANGUAGE,LANGUAGE
SM305274,27.651,-20.2324,Bulilima (North),ADM2,DISTRICT
SM4757,31.3935,1.9639,Bulisa,ADM1:ADM2,DISTRICT
SM268754,-125.67,54.4998,Bulkley-Nechako,ADM2,DISTRICT
SM12186,-76.684,17.9525,Bull Bay/ Seven Mile,ADM2,DISTRICT
SM12187,-77.59,17.8806,Bull Savannah,ADM2,DISTRICT
SM286526,172.009,-41.6493,Buller,ADM2,DISTRICT
SM301015,-85.6959,37.9701,Bullitt,ADM2,DISTRICT
SM300447,-81.7432,32.3968,Bulloch,ADM2,DISTRICT
SM300301,-85.7157,32.1006,Bullock,ADM2,DISTRICT
SM250133,-12.74,8.18,Bullom,ETHNICITY,ETHNICITY
SM227837,-13.16,9,Bullom So,LANGUAGE,LANGUAGE
SM261682,142.9128,-27.8308,Bulloo,ADM2,DISTRICT
SM227839,106.798,11.9777,Bulo Stieng,LANGUAGE,LANGUAGE
SM261218,143.052,-35.8464,Buloke,ADM2,DISTRICT
SM288580,146.75,-7.4167,Bulolo,ADM2,DISTRICT
SM303403,72.4667,40.6167,Buloqboshi,ADM2,DISTRICT
SM260478,20.3333,41.5,Bulqizës,ADM2,DISTRICT
SM260478,20.3333,41.5,Bulqizës,ADM2,DISTRICT
SM260478,20.3319,41.4715,Bulqizës,ADM2,DISTRICT
SM260478,20.3319,41.4715,Bulqizës,ADM2,DISTRICT
SM250132,-2,11,Bulsa,ETHNICITY,ETHNICITY
SM227843,11.6365,3.1614,Bulu (Cameroon),LANGUAGE,LANGUAGE
SM227845,150.101,-5.0613,Bulu (Papua New Guinea),LANGUAGE,LANGUAGE
SM459134,113.8053,-26.057,Buluguda,ETHNICITY,ETHNICITY
SM459226,146.5624,-18.7084,Buluguyban,ETHNICITY,ETHNICITY
SM276588,120.2333,-5.4167,Bulukumba,ADM2,DISTRICT
SM303408,67.3333,39.75,Bulung'ur,ADM2,DISTRICT
SM276426,117.1667,3,Bulungan,ADM2,DISTRICT
SM294335,126,71,Bulunskiy rayon,ADM2,DISTRICT
SM459186,145.5624,-17.0045,Bulway,ETHNICITY,ETHNICITY
SM292405,22.6666,46.8528,Bulz,ADM2,DISTRICT
SM290744,22.7777,46.2938,Bulzestii De Sus,ADM2,DISTRICT
SM227855,10.418,6.4866,Bum,LANGUAGE,LANGUAGE
SM252523,10.76,5.74,Bum,ETHNICITY,ETHNICITY
SM295454,160.7497,-8.7002,Buma,ADM2,DISTRICT
SM227848,9.3603,6.4491,Bumaji,LANGUAGE,LANGUAGE
SM227851,103.34,23.02,Bumang,LANGUAGE,LANGUAGE
SM290614,23.3819,45.1443,Bumbesti-jiu,ADM2,DISTRICT
SM290615,23.7035,45.1241,Bumbesti-pitic,ADM2,DISTRICT
SM227853,142.859,-3.6551,Bumbita Arapesh,LANGUAGE,LANGUAGE
SM252524,-2.54,8.19,Bumbu,ETHNICITY,ETHNICITY
SM778,90.7833,27.7,Bumthang,ADM1,DISTRICT
SM227858,90.8037,27.6494,Bumthangkha,LANGUAGE,LANGUAGE
SM227892,143.892,-4.5188,Bun,LANGUAGE,LANGUAGE
SM227865,144.119,-3.9555,Buna,LANGUAGE,LANGUAGE
SM227863,125.631,-17.8297,Bunaba,LANGUAGE,LANGUAGE
SM227866,125.216,-9.1362,Bunak,LANGUAGE,LANGUAGE
SM227864,151.066,-10.0761,Bunama,LANGUAGE,LANGUAGE
SM231115,77.9596,32.2168,Bunan,LANGUAGE,LANGUAGE
SM261344,115.6621,-33.3549,Bunbury,ADM2,DISTRICT
SM301933,-82.5301,35.6112,Buncombe,ADM2,DISTRICT
SM299095,33.8454,-2.0141,Bunda,ADM2,DISTRICT
SM261610,151.9844,-24.9731,Bundaberg,ADM2,DISTRICT
SM227869,78.9542,24.8982,Bundeli,LANGUAGE,LANGUAGE
SM277195,75.8333,25.5,Bundi,ADM2,DISTRICT
SM252531,144.44,-5.54,Bundi,ETHNICITY,ETHNICITY
SM299380,30.05,0.6667,Bundibugyo (2010-),ADM1:ADM2,DISTRICT
SM458086,119.3972,-21.0876,Bundjuwanga,ETHNICITY,ETHNICITY
SM375430,72.4993,34.443,Buner,ADM2:ADM3,DISTRICT
SM292176,24.214,45.102,Bunesti,ADM2,DISTRICT
SM291775,26.2972,47.5179,Bunesti,ADM2,DISTRICT
SM292668,25.0553,46.1004,Bunesti,ADM2,DISTRICT
SM227875,11.6389,6.4074,Bung,LANGUAGE,LANGUAGE
SM297565,104.0617,15.3029,Bung Bun,ADM2,DISTRICT
SM297308,103.6402,18.2995,Bung Kan,ADM2,DISTRICT
SM297309,104.073,18.009,Bung Khong Long,ADM2,DISTRICT
SM297148,100.6514,13.8102,Bung Kum,ADM2,DISTRICT
SM227883,143.796,-3.6967,Bungain,LANGUAGE,LANGUAGE
SM459036,140.5117,-37.2253,Bungandidj^,ETHNICITY,ETHNICITY
SM227877,140.5,-36.5,Bunganditj,LANGUAGE,LANGUAGE
SM458053,130.4634,-27.2861,Bunggura,ETHNICITY,ETHNICITY
SM227882,122.212,-3.222,Bungku,LANGUAGE,LANGUAGE
SM276747,101.96,-1.5022,Bungo,ADM2,DISTRICT
SM260383,15.4428,-7.4266,Bungo,ADM2,DISTRICT
SM279012,131.5121,32.9725,Bungo-ōno,ADM2,DISTRICT
SM780,34.639,0.66,Bungoma,ADM1:ADM2,DISTRICT
SM279013,131.5069,33.5757,Bungotakada,ADM2,DISTRICT
SM227878,32.8801,-8.3259,Bungu,LANGUAGE,LANGUAGE
SM252532,33.51,-8.01,Bungu,ETHNICITY,ETHNICITY
SM285284,6.4894,12.1887,Bungudu,ADM2,DISTRICT
SM290745,22.6576,45.6945,Bunila,ADM2,DISTRICT
SM13461,21.5264,56.5243,Bunkas pagasts,ADM2,DISTRICT
SM12188,-77.6861,18.362,Bunkers Hill,ADM2,DISTRICT
SM274934,-0.1121,10.4846,Bunkpurugu Yunyoo,ADM2,DISTRICT
SM284893,8.5969,11.6672,Bunkure,ADM2,DISTRICT
SM279470,139.7473,35.7175,Bunkyō,ADM2,DISTRICT
SM252535,168.22,-15.95,Bunlap,ETHNICITY,ETHNICITY
SM285613,5.2166,52.0423,Bunnik,ADM2,DISTRICT
SM227894,-3.49,14.43,Bunoge Dogon,LANGUAGE,LANGUAGE
SM285614,5.3584,52.2425,Bunschoten,ADM2,DISTRICT
SM252537,146.22,-27.29,Buntamurra,ETHNICITY,ETHNICITY
SM252537,146.22,-27.29,Buntamurra,ETHNICITY,ETHNICITY
SM252537,143.5024,-27.3429,Buntamurra,ETHNICITY,ETHNICITY
SM252537,143.5024,-27.3429,Buntamurra,ETHNICITY,ETHNICITY
SM292406,22.502,46.607,Buntesti,ADM2,DISTRICT
SM297688,105.4044,14.705,Buntharik,ADM2,DISTRICT
SM458645,125.6052,-17.7247,Bunuba,ETHNICITY,ETHNICITY
SM227897,121.025,23.4116,Bunun,LANGUAGE,LANGUAGE
SM252538,121,24,Bunun,ETHNICITY,ETHNICITY
SM252539,144.41,-37.94,Bunurong,ETHNICITY,ETHNICITY
SM252539,144.41,-37.94,Bunurong,ETHNICITY,ETHNICITY
SM252539,144.41,-37.94,Bunurong,ETHNICITY,ETHNICITY
SM252539,145.23,-38.48,Bunurong,ETHNICITY,ETHNICITY
SM252539,145.23,-38.48,Bunurong,ETHNICITY,ETHNICITY
SM252539,145.23,-38.48,Bunurong,ETHNICITY,ETHNICITY
SM252539,145.5753,-38.4596,Bunurong,ETHNICITY,ETHNICITY
SM252539,145.5753,-38.4596,Bunurong,ETHNICITY,ETHNICITY
SM252539,145.5753,-38.4596,Bunurong,ETHNICITY,ETHNICITY
SM299268,30.204,0.4833,Bunyangabu,ADM1:ADM2,DISTRICT
SM299410,29.9891,-0.2543,Bunyaruguru,ADM1:ADM2,DISTRICT
SM299401,33.945,0.8908,Bunyole,ADM1:ADM2,DISTRICT
SM284949,3.9148,12.1678,Bunza,ADM2,DISTRICT
SM227898,121.519,0.9773,Buol,LANGUAGE,LANGUAGE
SM276598,121.3687,1.0466,Buol,ADM2,DISTRICT
SM280562,126.7111,37.4972,Bupyeong,ADM2,DISTRICT
SM17775,49.5833,26.0833,Buqayq,ADM2,DISTRICT
SM267149,-37.6094,-11.1311,Buquim,ADM2,DISTRICT
SM781,32.4193,31.1234,Bur Said,ADM1,DISTRICT
SM305044,43.4753,14.8982,Bura,ADM2,DISTRICT
SM250135,12.833,10.5,Bura,ETHNICITY,ETHNICITY
SM250135,12.833,10.5,Bura,ETHNICITY,ETHNICITY
SM250135,12,10,Bura,ETHNICITY,ETHNICITY
SM250135,12,10,Bura,ETHNICITY,ETHNICITY
SM227911,12.2848,10.4786,Bura Sign Language,LANGUAGE,LANGUAGE
SM227908,12.3007,10.4822,Bura-Pabir,LANGUAGE,LANGUAGE
SM227905,34.1,-3.9,Buraadiga,LANGUAGE,LANGUAGE
SM227903,11.0698,9.4945,Burak,LANGUAGE,LANGUAGE
SM227901,20.7161,4.4228,Buraka,LANGUAGE,LANGUAGE
SM262044,29.3778,-3.8172,Burambi,ADM2,DISTRICT
SM295798,45.7587,9.1413,Burao,ADM2,DISTRICT
SM227899,134.365,-12.3061,Burarra,LANGUAGE,LANGUAGE
SM227907,136.038,-2.793,Burate,LANGUAGE,LANGUAGE
SM17776,43.6333,27.3667,Buraydah,ADM2,DISTRICT
SM294246,55.3333,55.8333,Burayevskiy rayon,ADM2,DISTRICT
SM262069,29.912,-3.7575,Buraza,ADM2,DISTRICT
SM261611,147.2724,-19.8493,Burdekin,ADM2,DISTRICT
SM17858,43.3167,2.4833,Burdhubo,ADM2,DISTRICT
SM458829,131.9291,-11.3103,Burdinmanduwij,ETHNICITY,ETHNICITY
SM227915,114.658,-22.9646,Burduna,LANGUAGE,LANGUAGE
SM459139,115.1152,-23.0479,Burduna,ETHNICITY,ETHNICITY
SM782,30,37.5,Burdur,ADM1,DISTRICT
SM227919,10.4554,10.4603,Bure,LANGUAGE,LANGUAGE
SM300647,-89.5287,41.4042,Bureau,ADM2,DISTRICT
SM285759,5.334,51.909,Buren,ADM2,DISTRICT
SM295136,29.8347,-1.4739,Burera,ADM1:ADM2,DISTRICT
SM293235,130,50,Bureyskiy rayon,ADM2,DISTRICT
SM262398,27.4667,42.5,Burgas,ADM2,DISTRICT
SM783,27.2333,42.5333,Burgas,ADM1,DISTRICT
SM784,16.4167,47.5,Burgenland,ADM1,DISTRICT
SM227920,16.626,47.544,Burgenland Croatian,DIALECT,LANGUAGE
SM227922,16.222,47.272,Burgenland Romani,DIALECT,LANGUAGE
SM274068,-3.7079,42.3394,Burgos,ADM2,DISTRICT
SM282675,-98.8047,24.9494,Burgos,ADM2,DISTRICT
SM298175,26.9786,39.511,Burhaniye,ADM2,DISTRICT
SM276969,76.23,21.31,Burhanpur,ADM2,DISTRICT
SM267068,-48.5527,-23.7152,Buri,ADM2,DISTRICT
SM785,102.9933,14.8482,Buri Ram,ADM1,DISTRICT
SM252546,106.37,50.77,Buriad,ETHNICITY,ETHNICITY
SM252546,106.37,50.77,Buriad,ETHNICITY,ETHNICITY
SM252546,106.37,50.77,Buriad,ETHNICITY,ETHNICITY
SM252546,106.37,50.77,Buriad,ETHNICITY,ETHNICITY
SM252546,106.37,50.77,Buriad,ETHNICITY,ETHNICITY
SM252546,106.37,50.77,Buriad,ETHNICITY,ETHNICITY
SM252546,106.37,50.77,Buriad,ETHNICITY,ETHNICITY
SM252546,104,52,Buriad,ETHNICITY,ETHNICITY
SM252546,104,52,Buriad,ETHNICITY,ETHNICITY
SM252546,104,52,Buriad,ETHNICITY,ETHNICITY
SM252546,104,52,Buriad,ETHNICITY,ETHNICITY
SM252546,104,52,Buriad,ETHNICITY,ETHNICITY
SM252546,104,52,Buriad,ETHNICITY,ETHNICITY
SM252546,104,52,Buriad,ETHNICITY,ETHNICITY
SM252546,107,52,Buriad,ETHNICITY,ETHNICITY
SM252546,107,52,Buriad,ETHNICITY,ETHNICITY
SM252546,107,52,Buriad,ETHNICITY,ETHNICITY
SM252546,107,52,Buriad,ETHNICITY,ETHNICITY
SM252546,107,52,Buriad,ETHNICITY,ETHNICITY
SM252546,107,52,Buriad,ETHNICITY,ETHNICITY
SM252546,107,52,Buriad,ETHNICITY,ETHNICITY
SM252546,105.69,57.3,Buriad,ETHNICITY,ETHNICITY
SM252546,105.69,57.3,Buriad,ETHNICITY,ETHNICITY
SM252546,105.69,57.3,Buriad,ETHNICITY,ETHNICITY
SM252546,105.69,57.3,Buriad,ETHNICITY,ETHNICITY
SM252546,105.69,57.3,Buriad,ETHNICITY,ETHNICITY
SM252546,105.69,57.3,Buriad,ETHNICITY,ETHNICITY
SM252546,105.69,57.3,Buriad,ETHNICITY,ETHNICITY
SM252546,103.43,50.88,Buriad,ETHNICITY,ETHNICITY
SM252546,103.43,50.88,Buriad,ETHNICITY,ETHNICITY
SM252546,103.43,50.88,Buriad,ETHNICITY,ETHNICITY
SM252546,103.43,50.88,Buriad,ETHNICITY,ETHNICITY
SM252546,103.43,50.88,Buriad,ETHNICITY,ETHNICITY
SM252546,103.43,50.88,Buriad,ETHNICITY,ETHNICITY
SM252546,103.43,50.88,Buriad,ETHNICITY,ETHNICITY
SM252546,109.85,52.61,Buriad,ETHNICITY,ETHNICITY
SM252546,109.85,52.61,Buriad,ETHNICITY,ETHNICITY
SM252546,109.85,52.61,Buriad,ETHNICITY,ETHNICITY
SM252546,109.85,52.61,Buriad,ETHNICITY,ETHNICITY
SM252546,109.85,52.61,Buriad,ETHNICITY,ETHNICITY
SM252546,109.85,52.61,Buriad,ETHNICITY,ETHNICITY
SM252546,109.85,52.61,Buriad,ETHNICITY,ETHNICITY
SM252546,114.54,51.11,Buriad,ETHNICITY,ETHNICITY
SM252546,114.54,51.11,Buriad,ETHNICITY,ETHNICITY
SM252546,114.54,51.11,Buriad,ETHNICITY,ETHNICITY
SM252546,114.54,51.11,Buriad,ETHNICITY,ETHNICITY
SM252546,114.54,51.11,Buriad,ETHNICITY,ETHNICITY
SM252546,114.54,51.11,Buriad,ETHNICITY,ETHNICITY
SM252546,114.54,51.11,Buriad,ETHNICITY,ETHNICITY
SM291055,22.5492,44.4784,Burila Mare,ADM2,DISTRICT
SM267069,-50.1778,-21.047,Buritama,ADM2,DISTRICT
SM262956,-42.8581,-3.948,Buriti,ADM2,DISTRICT
SM268220,-49.0134,-18.1311,Buriti Alegre,ADM2,DISTRICT
SM262954,-43.8323,-5.7558,Buriti Bravo,ADM2,DISTRICT
SM268221,-50.4292,-16.1644,Buriti de Goiás,ADM2,DISTRICT
SM267209,-48.1348,-5.3706,Buriti do Tocantins,ADM2,DISTRICT
SM265176,-41.8038,-3.2398,Buriti dos Lopes,ADM2,DISTRICT
SM265177,-41.2508,-5.2089,Buriti dos Montes,ADM2,DISTRICT
SM262957,-46.3771,-4.5197,Buriticupu,ADM2,DISTRICT
SM270186,-75.9209,6.8055,Buriticá,ADM2,DISTRICT
SM268222,-46.3176,-14.4097,Buritinópolis,ADM2,DISTRICT
SM267734,-43.6886,-10.5708,Buritirama,ADM2,DISTRICT
SM262958,-46.9972,-5.5925,Buritirana,ADM2,DISTRICT
SM266120,-63.9696,-10.0922,Buritis,ADM2,DISTRICT
SM263226,-46.5547,-15.4139,Buritis,ADM2,DISTRICT
SM267070,-47.7078,-20.2135,Buritizal,ADM2,DISTRICT
SM263227,-45.3045,-17.4058,Buritizeiro,ADM2,DISTRICT
SM227926,38.4646,4.4462,Burji,LANGUAGE,LANGUAGE
SM274325,37.8333,5.5,Burji,ADM2,DISTRICT
SM252547,38,5,Burji,ETHNICITY,ETHNICITY
SM252547,38,5,Burji,ETHNICITY,ETHNICITY
SM252547,37.8,5.5,Burji,ETHNICITY,ETHNICITY
SM252547,37.8,5.5,Burji,ETHNICITY,ETHNICITY
SM290746,22.5016,45.971,Burjuc,ADM2,DISTRICT
SM260196,69.1629,36.2408,Burka,ADM2,DISTRICT
SM301934,-81.7047,35.7495,Burke,ADM2,DISTRICT
SM302080,-102.5183,48.791,Burke,ADM2,DISTRICT
SM300448,-82.0008,33.0612,Burke,ADM2,DISTRICT
SM261612,139.0375,-18.0646,Burke,ADM2,DISTRICT
SM227927,-1.5238,12.3635,Burkina Faso Sign Language,LANGUAGE,LANGUAGE
SM302081,-100.4687,46.9774,Burleigh,ADM2,DISTRICT
SM302812,-96.6215,30.4925,Burleson,ADM2,DISTRICT
SM301827,-74.6682,39.8777,Burlington,ADM2,DISTRICT
SM279890,53,51.3333,Burlinskiy,ADM2,DISTRICT
SM292957,78.5,53.3333,Burlinskiy rayon,ADM2,DISTRICT
SM13180,79,46.3333,Burlyutobinskiy Rayon,ADM2,DISTRICT
SM296457,13.0947,55.6387,Burlöv,ADM2,DISTRICT
SM227929,95.8979,21.3193,Burma Pyu,LANGUAGE,LANGUAGE
SM227930,167.7312,-16.4165,Burmbar,LANGUAGE,LANGUAGE
SM240740,93.9665,20.7926,Burmese,LANGUAGE,LANGUAGE
SM252548,96.11,20.42,Burmese,ETHNICITY,ETHNICITY
SM252548,96.11,20.42,Burmese,ETHNICITY,ETHNICITY
SM252548,96.11,20.42,Burmese,ETHNICITY,ETHNICITY
SM252548,96.11,20.42,Burmese,ETHNICITY,ETHNICITY
SM252548,96.11,20.42,Burmese,ETHNICITY,ETHNICITY
SM252548,96.167,16.783,Burmese,ETHNICITY,ETHNICITY
SM252548,96.167,16.783,Burmese,ETHNICITY,ETHNICITY
SM252548,96.167,16.783,Burmese,ETHNICITY,ETHNICITY
SM252548,96.167,16.783,Burmese,ETHNICITY,ETHNICITY
SM252548,96.167,16.783,Burmese,ETHNICITY,ETHNICITY
SM252548,95,20,Burmese,ETHNICITY,ETHNICITY
SM252548,95,20,Burmese,ETHNICITY,ETHNICITY
SM252548,95,20,Burmese,ETHNICITY,ETHNICITY
SM252548,95,20,Burmese,ETHNICITY,ETHNICITY
SM252548,95,20,Burmese,ETHNICITY,ETHNICITY
SM252548,95.67,22,Burmese,ETHNICITY,ETHNICITY
SM252548,95.67,22,Burmese,ETHNICITY,ETHNICITY
SM252548,95.67,22,Burmese,ETHNICITY,ETHNICITY
SM252548,95.67,22,Burmese,ETHNICITY,ETHNICITY
SM252548,95.67,22,Burmese,ETHNICITY,ETHNICITY
SM252548,96.72,17.96,Burmese,ETHNICITY,ETHNICITY
SM252548,96.72,17.96,Burmese,ETHNICITY,ETHNICITY
SM252548,96.72,17.96,Burmese,ETHNICITY,ETHNICITY
SM252548,96.72,17.96,Burmese,ETHNICITY,ETHNICITY
SM252548,96.72,17.96,Burmese,ETHNICITY,ETHNICITY
SM227931,138.203,-2.4758,Burmeso,LANGUAGE,LANGUAGE
SM260146,69.275,32.7143,Burmul,ADM2,DISTRICT
SM302813,-98.1825,30.7883,Burnet,ADM2,DISTRICT
SM262776,-64.0973,-21.4315,Burnet O'Connor,ADM2,DISTRICT
SM303287,-92.3676,45.8627,Burnett,ADM2,DISTRICT
SM261776,145.8114,-41.1965,Burnie,ADM2,DISTRICT
SM261752,138.65,-34.9333,Burnside,ADM2,DISTRICT
SM323986,-114.8925,51.9892,Burnstick Lake,ADM2:ADM3,DISTRICT
SM12189,-77.7494,18.0318,Burnt Savannah (Saint Elizabeth),ADM2,DISTRICT
SM18545,-78.1263,18.3168,Burnt Savannah (Westmoreland),ADM2,DISTRICT
SM10045,114.9166,4.8714,Burong Pingai Ayer,ADM2,DISTRICT
SM303651,-66.1304,10.3646,Buroz,ADM2,DISTRICT
SM458438,151.9407,-30.4224,Burrgati,ETHNICITY,ETHNICITY
SM261065,-64.9167,-26.5,Burruyacú,ADM2,DISTRICT
SM786,29.0833,40.1667,Bursa (1995-),ADM1,DISTRICT
SM301680,-96.3286,41.8516,Burt,ADM2,DISTRICT
SM295777,48.3991,7.8022,Burtinle,ADM2,DISTRICT
SM4042,25.3553,57.7006,Burtnieku Novads,ADM1,DISTRICT
SM13462,25.2987,57.689,Burtnieku pagasts,ADM2,DISTRICT
SM276466,126.6841,-3.3277,Buru,ADM2,DISTRICT
SM252549,126.54,-3.46,Buru,ETHNICITY,ETHNICITY
SM227942,126.632,-3.4396,Buru (Indonesia),LANGUAGE,LANGUAGE
SM227939,10.8617,7.0109,Buru (Nigeria),LANGUAGE,LANGUAGE
SM276465,126.5927,-3.5219,Buru Selatan,ADM2,DISTRICT
SM227948,143.015,-4.0353,Burui,LANGUAGE,LANGUAGE
SM285355,9.2,7.4667,Buruku,ADM2,DISTRICT
SM285355,9.2,7.4667,Buruku,ADM2,DISTRICT
SM285355,9.2607,7.3653,Buruku,ADM2,DISTRICT
SM285355,9.2607,7.3653,Buruku,ADM2,DISTRICT
SM227945,147.333,-6.5166,Burum-Mindik,LANGUAGE,LANGUAGE
SM227946,140.03,-5.1222,Burumakok,LANGUAGE,LANGUAGE
SM227941,33.921,10.725,Burun,LANGUAGE,LANGUAGE
SM252551,31,13.42,Burun,ETHNICITY,ETHNICITY
SM250649,30.22,-3.37,Burundi,ETHNICITY,ETHNICITY
SM250649,30.22,-3.37,Burundi,ETHNICITY,ETHNICITY
SM250649,30,-3,Burundi,ETHNICITY,ETHNICITY
SM250649,30,-3,Burundi,ETHNICITY,ETHNICITY
SM227950,35.963,-5.2137,Burunge,LANGUAGE,LANGUAGE
SM250136,36.66,-4.16,Burungi,ETHNICITY,ETHNICITY
SM250136,36.66,-4.16,Burungi,ETHNICITY,ETHNICITY
SM250136,36,-5.3,Burungi,ETHNICITY,ETHNICITY
SM250136,36,-5.3,Burungi,ETHNICITY,ETHNICITY
SM262046,29.6739,-3.9633,Bururi,ADM2,DISTRICT
SM3891,29.6333,-3.85,Bururi (2015-),ADM1,DISTRICT
SM227936,74.8236,36.2161,Burushaski,LANGUAGE,LANGUAGE
SM227943,117.012,3.3691,Burusu,LANGUAGE,LANGUAGE
SM284668,5.6029,5.2117,Burutu,ADM2,DISTRICT
SM227947,133.327,-3.5456,Buruwai,LANGUAGE,LANGUAGE
SM261508,151.1048,-33.8854,Burwood,ADM2,DISTRICT
SM274608,-2.3333,53.5833,Bury,ADM1:ADM2,DISTRICT
SM788,109,53,Buryat,ADM1,DISTRICT
SM294247,57.5,53.1667,Burzyanskiy rayon,ADM2,DISTRICT
SM227959,4.3993,9.9107,Busa,LANGUAGE,LANGUAGE
SM227957,9.6807,5.8604,Busam,LANGUAGE,LANGUAGE
SM252552,147.26,-6.69,Busama,ETHNICITY,ETHNICITY
SM227960,136.032,-1.7368,Busami,LANGUAGE,LANGUAGE
SM789,129.05,35.1333,Busan,ADM1,DISTRICT
SM227961,115.401,0.0349,Busang Kayan,LANGUAGE,LANGUAGE
SM280534,129.0513,35.1629,Busanjin,ADM2,DISTRICT
SM270729,-72.8699,5.8435,Busbanza,ADM2,DISTRICT
SM299174,33.77,-2.3913,Busega,ADM2,DISTRICT
SM277651,50.9816,29.1419,Bushehr,ADM2,DISTRICT
SM790,51.5,28.75,Bushehr,ADM1,DISTRICT
SM4768,30.183,-0.491,Bushenyi (2010-),ADM1:ADM2,DISTRICT
SM250138,21.46,-2.93,Bushongo,ETHNICITY,ETHNICITY
SM250138,21.46,-2.93,Bushongo,ETHNICITY,ETHNICITY
SM250138,20.93,-4.59,Bushongo,ETHNICITY,ETHNICITY
SM250138,20.93,-4.59,Bushongo,ETHNICITY,ETHNICITY
SM250138,22,-5,Bushongo,ETHNICITY,ETHNICITY
SM250138,22,-5,Bushongo,ETHNICITY,ETHNICITY
SM227963,21.4556,-4.6946,Bushoong,LANGUAGE,LANGUAGE
SM12190,-77.2514,17.9824,Bushy Park,ADM2,DISTRICT
SM793,34.0042,0.4176,Busia,ADM1:ADM2,DISTRICT
SM792,34.17,0.35,Busia,ADM1:ADM2,DISTRICT
SM261996,29.7064,-2.8588,Busiga,ADM2,DISTRICT
SM299258,33.6667,0.8667,Busiki,ADM1:ADM2,DISTRICT
SM288752,20.8762,50.4558,Busko,ADM2,DISTRICT
SM227970,17.6011,12.5008,Buso,LANGUAGE,LANGUAGE
SM227969,122.622,-5.6052,Busoa,LANGUAGE,LANGUAGE
SM261965,30.2541,-2.4688,Busoni,ADM2,DISTRICT
SM227971,37.2017,5.7499,Bussa,LANGUAGE,LANGUAGE
SM261345,115.3708,-33.6848,Busselton,ADM2,DISTRICT
SM282676,-99.9114,23.3298,Bustamante,ADM2,DISTRICT
SM282198,-100.506,26.5557,Bustamante,ADM2,DISTRICT
SM291505,25.5397,45.4057,Busteni,ADM2,DISTRICT
SM290616,23.7035,44.9684,Bustuchin,ADM2,DISTRICT
SM227973,9.7843,6.6815,Busuu,LANGUAGE,LANGUAGE
SM262083,29.6948,-3.1023,Butaganzwa1,ADM2,DISTRICT
SM262011,30.1456,-3.5314,Butaganzwa2,ADM2,DISTRICT
SM227975,152.2457,-4.4717,Butam,LANGUAGE,LANGUAGE
SM299357,32.1559,0.1667,Butambala,ADM1:ADM2,DISTRICT
SM13323,172.7968,3.1721,Butaritari,ADM2,DISTRICT
SM370217,-9.0833,5.1333,Butaw Clan,ADM2:ADM3,DISTRICT
SM227976,121.091,17.2239,Butbut Kalinga,LANGUAGE,LANGUAGE
SM458209,153.0328,-25.3372,Butchulla,ETHNICITY,ETHNICITY
SM290806,26.9355,47.0839,Butea,ADM2,DISTRICT
SM4727,33.9214,1.1966,Butebo (2005-),ADM1:ADM2,DISTRICT
SM795,21.4689,42.0819,Butel,ADM1:ADM2,DISTRICT
SM269653,29.2819,0.1423,Butembo,ADM2,DISTRICT
SM290487,22.1147,46.3312,Buteni,ADM2,DISTRICT
SM5282,34.513,0.293,Butere Mumias,ADM2,DISTRICT
SM262018,29.337,-3.338,Buterere,ADM2,DISTRICT
SM262012,30.1975,-3.3539,Butezi,ADM2,DISTRICT
SM796,28.5667,-28.8,Butha-Buthe,ADM1,DISTRICT
SM299096,33.9637,-1.684,Butiama,ADM2,DISTRICT
SM261984,30.3223,-2.711,Butihinda,ADM2,DISTRICT
SM290247,25.9054,44.6868,Butimanu,ADM2,DISTRICT
SM266021,-52.0066,-30.2053,Butiá,ADM2,DISTRICT
SM302286,-79.913,40.9117,Butler,ADM2,DISTRICT
SM301681,-97.1318,41.2261,Butler,ADM2,DISTRICT
SM302171,-84.5757,39.4387,Butler,ADM2,DISTRICT
SM301526,-90.4066,36.7164,Butler,ADM2,DISTRICT
SM300787,-92.7902,42.7316,Butler,ADM2,DISTRICT
SM301016,-86.6818,37.2073,Butler,ADM2,DISTRICT
SM300966,-96.8391,37.7813,Butler,ADM2,DISTRICT
SM300310,-86.6803,31.7524,Butler,ADM2,DISTRICT
SM227979,80.165,13.05,Butler English,LANGUAGE,LANGUAGE
SM252554,47.88,42.54,Butlikh,ETHNICITY,ETHNICITY
SM291056,23.3759,44.5704,Butoiesti,ADM2,DISTRICT
SM276610,122.9302,-5.0146,Buton Utara,ADM2,DISTRICT
SM12191,-77.5778,17.9731,Butt-Up,ADM2,DISTRICT
SM302543,-103.508,44.9058,Butte,ADM2,DISTRICT
SM300547,-113.172,43.7229,Butte,ADM2,DISTRICT
SM303196,-121.6007,39.6669,Butte,ADM2,DISTRICT
SM300449,-83.9572,33.2879,Butts,ADM2,DISTRICT
SM227984,125.418,8.8701,Butuanon,LANGUAGE,LANGUAGE
SM252556,123,-4.67,Butung,ETHNICITY,ETHNICITY
SM295001,40.6667,50.8333,Buturlinovskiy rayon,ADM2,DISTRICT
SM293754,44.9167,55.5,Buturlinskiy rayon,ADM2,DISTRICT
SM290605,25.8338,44.3466,Buturugeni,ADM2,DISTRICT
SM259113,10.28,6.54,Buu,LANGUAGE,LANGUAGE
SM13999,-8.4591,6.9285,Buu-Yao District,ADM2,DISTRICT
SM295799,46.6613,8.4602,Buuhoodle,ADM2,DISTRICT
SM295833,45.5323,3.9001,Buulo Burdo,ADM2,DISTRICT
SM295818,44.0069,2.4854,Buur Xakaba,ADM2,DISTRICT
SM283746,98.6917,46.1615,Buutsagaan,ADM2,DISTRICT
SM303479,71.1667,40.5833,Buvayda,ADM2,DISTRICT
SM299365,33.2007,-0.3674,Buvuma Islands,ADM1:ADM2,DISTRICT
SM227988,13.8838,10.6151,Buwal,LANGUAGE,LANGUAGE
SM277439,84.1,25.5,Buxar,ADM2,DISTRICT
SM303460,64.3992,39.5354,Buxoro,ADM2,DISTRICT
SM227995,26.298,-4.9083,Buya,LANGUAGE,LANGUAGE
SM299298,30.8133,0.9379,Buyaga,ADM1:ADM2,DISTRICT
SM283589,91.8172,48.0624,Buyant,ADM2,DISTRICT
SM283694,89.5476,48.5762,Buyant,ADM2,DISTRICT
SM262047,29.5275,-3.8504,Buyengero,ADM2,DISTRICT
SM262024,29.359,-3.369,Buyenze,ADM2,DISTRICT
SM280661,126.858,36.2626,Buyeo,ADM2,DISTRICT
SM458204,152.9282,-27.2211,Buyibara,ETHNICITY,ETHNICITY
SM292863,47.1225,42.8261,Buynaksk,ADM2,DISTRICT
SM292864,47.1667,42.8333,Buynakskiy rayon,ADM2,DISTRICT
SM16875,41.5,58.5,Buyskiy rayon,ADM2,DISTRICT
SM227998,28.5545,-5.4181,Buyu,LANGUAGE,LANGUAGE
SM250139,28,-7,Buyu,ETHNICITY,ETHNICITY
SM227997,101.08,22,Buyuan Jinuo,LANGUAGE,LANGUAGE
SM290070,24.1804,46.9049,Buza,ADM2,DISTRICT
SM289857,26.8214,45.1505,Buzau,ADM2,DISTRICT
SM294248,54.5,54.5833,Buzdyakskiy rayon,ADM2,DISTRICT
SM279694,131.0858,33.5674,Buzen,ADM2,DISTRICT
SM291869,25.2289,44.0108,Buzescu,ADM2,DISTRICT
SM276051,13.9667,45.4167,Buzet,ADM2,DISTRICT
SM283966,34.3724,-20.0393,Buzi,ADM2,DISTRICT
SM291959,21.5893,45.6404,Buzias,ADM2,DISTRICT
SM797,26.75,45.4167,Buzău,ADM1,DISTRICT
SM264950,-37.1332,-8.741,Buíque,ADM2,DISTRICT
SM303982,108.0283,12.6577,Buôn Ma Thuột,ADM2,DISTRICT
SM303975,107.7387,12.904,Buôn Đôn,ADM2,DISTRICT
SM296108,-55.5543,4.7817,Bven Saramacca,ADM2,DISTRICT
SM228004,25.2514,2.5957,Bwa,LANGUAGE,LANGUAGE
SM252557,149.03,-5.9,Bwaidoga,ETHNICITY,ETHNICITY
SM252557,149.03,-5.9,Bwaidoga,ETHNICITY,ETHNICITY
SM252557,150.33,-9.44,Bwaidoga,ETHNICITY,ETHNICITY
SM252557,150.33,-9.44,Bwaidoga,ETHNICITY,ETHNICITY
SM228006,150.297,-9.5116,Bwaidoka,LANGUAGE,LANGUAGE
SM250141,19.73,3.8,Bwaka,ETHNICITY,ETHNICITY
SM250141,19.73,3.8,Bwaka,ETHNICITY,ETHNICITY
SM250141,19.73,3.8,Bwaka,ETHNICITY,ETHNICITY
SM250141,16.93,-0.02,Bwaka,ETHNICITY,ETHNICITY
SM250141,16.93,-0.02,Bwaka,ETHNICITY,ETHNICITY
SM250141,16.93,-0.02,Bwaka,ETHNICITY,ETHNICITY
SM250141,19,3,Bwaka,ETHNICITY,ETHNICITY
SM250141,19,3,Bwaka,ETHNICITY,ETHNICITY
SM250141,19,3,Bwaka,ETHNICITY,ETHNICITY
SM261966,30.3408,-2.5525,Bwambarangwe,ADM2,DISTRICT
SM228017,151.034,-10.6599,Bwanabwana,LANGUAGE,LANGUAGE
SM284740,7.408,9.218,Bwari,ADM2,DISTRICT
SM228019,165.076,-21.3777,Bwatoo,LANGUAGE,LANGUAGE
SM228022,96.9936,18.9796,Bwe Karen,LANGUAGE,LANGUAGE
SM228024,19.28,3.22,Bwela,LANGUAGE,LANGUAGE
SM228026,167.6609,-16.339,Bwenelang,LANGUAGE,LANGUAGE
SM262013,30.3827,-3.2967,Bweru,ADM2,DISTRICT
SM228028,29.4071,-8.3041,Bwile,LANGUAGE,LANGUAGE
SM250861,29.44,-7.69,Bwile,ETHNICITY,ETHNICITY
SM228031,11.9595,-3.2369,Bwisi,LANGUAGE,LANGUAGE
SM262025,29.3696,-3.3726,Bwiza,ADM2,DISTRICT
SM262365,25.8333,43.4167,Byala,ADM2,DISTRICT
SM262460,27.8167,42.8833,Byala,ADM2,DISTRICT
SM262489,23.9333,43.4667,Byala Slatina,ADM2,DISTRICT
SM228035,80.6365,29.92,Byangsi,LANGUAGE,LANGUAGE
SM262654,29,53.8333,Byarezan',ADM2,DISTRICT
SM12192,-76.6573,18.1464,Bybrook,ADM2,DISTRICT
SM288861,18.0381,53.1903,Bydgoszcz,ADM2,DISTRICT
SM288860,18.0383,53.1297,Bydgoszcz (City),ADM2,DISTRICT
SM228036,13.4625,4.5728,Byep,LANGUAGE,LANGUAGE
SM262615,24,53.25,Byerastavitsa,ADM2,DISTRICT
SM262679,29.5,55,Byeshankovichy,ADM2,DISTRICT
SM286309,7.7972,58.8313,Bygland,ADM2,DISTRICT
SM262637,30.25,53.5,Bykhaw,ADM2,DISTRICT
SM286310,7.3581,59.3569,Bykle,ADM2,DISTRICT
SM294912,45.75,49.5833,Bykovskiy rayon,ADM2,DISTRICT
SM289185,125.4292,40.5433,Byokdong,ADM2,DISTRICT
SM261509,153.5167,-28.6167,Byron,ADM2,DISTRICT
SM293098,158.5,56,Bystrinskiy rayon,ADM2,DISTRICT
SM292958,84.5,52.3333,Bystroistokskiy rayon,ADM2,DISTRICT
SM296181,18.5584,49.2227,Bytca,ADM2,DISTRICT
SM288747,18.8763,50.3652,Bytom,ADM2,DISTRICT
SM288696,17.2915,54.1569,Bytów,ADM2,DISTRICT
SM304595,107.19,10.5114,Bà Rịa,ADM2,DISTRICT
SM548,107.25,10.5833,Bà Rịa - Vũng Tàu (1992-),ADM1,DISTRICT
SM304615,106.614,11.2631,Bàu Bàng,ADM2,DISTRICT
SM304517,105.253,20.3577,Bá Thước,ADM2,DISTRICT
SM304347,108.8552,11.8334,Bác Ái,ADM2,DISTRICT
SM552,19.4167,46.5,Bács-Kiskun,ADM1,DISTRICT
SM276112,19.3306,46.1017,Bácsalmás,ADM2,DISTRICT
SM282552,-110.1088,27.5111,Bácum,ADM2,DISTRICT
SM271130,-76.0267,20.7504,Báguanos,ADM2,DISTRICT
SM266932,-49.5505,-20.7024,Bálsamo,ADM2,DISTRICT
SM296165,18.2577,48.7191,Bánovce nad Bebravou,ADM2,DISTRICT
SM304279,103.7134,22.5677,Bát Xát,ADM2,DISTRICT
SM276144,19.8734,47.9769,Bátonyterenye,ADM2,DISTRICT
SM592,103,13.75,Bântéay Méanchey,ADM1,DISTRICT
SM296450,12.7991,56.401,Båstad,ADM2,DISTRICT
SM286347,29.7215,70.6351,Båtsfjord,ADM2,DISTRICT
SM286050,10.5265,59.89,Bærum,ADM2,DISTRICT
SM638,-3.0833,30.25,Béchar,ADM1,DISTRICT
SM457256,-2,30,Béchar District,ADM2,DISTRICT
SM642,9.25,36.6667,Béja,ADM1,DISTRICT
SM297977,9.2498,36.8035,Béja Nord,ADM2,DISTRICT
SM297978,9.2085,36.6426,Béja Sud,ADM2,DISTRICT
SM643,4.9167,36.6667,Béjaïa,ADM1,DISTRICT
SM457257,5.1667,36.6667,Béjaïa District,ADM2,DISTRICT
SM645,21,46.8,Békés,ADM1,DISTRICT
SM276223,21.0741,46.6813,Békéscsabai,ADM1:ADM2,DISTRICT
SM276122,20.3616,48.0639,Bélapátfalva,ADM2,DISTRICT
SM284564,163.6667,-19.7333,Bélep,ADM2,DISTRICT
SM269484,-5.0674,7.0258,Bélier,ADM2,DISTRICT
SM457259,-3,30,Béni Abbès District,ADM2,DISTRICT
SM457261,-1.4167,35.25,Béni Saf District,ADM2,DISTRICT
SM269536,13.4766,9.2202,Bénoué,ADM2,DISTRICT
SM269470,-6.1716,8.1895,Béré,ADM2,DISTRICT
SM296821,16.1405,9.2607,Béré,ADM2,DISTRICT
SM304097,106.5651,10.7033,Bình Chánh,ADM2,DISTRICT
SM676,106.6667,11.1667,Bình Dương,ADM1,DISTRICT
SM304248,106.3041,22.0728,Bình Gia,ADM2,DISTRICT
SM304062,106.1914,20.8759,Bình Giang,ADM2,DISTRICT
SM304422,107.4405,21.5439,Bình Liêu,ADM2,DISTRICT
SM303923,106.6041,11.6788,Bình Long,ADM2,DISTRICT
SM304162,106.0296,20.5013,Bình Lục,ADM2,DISTRICT
SM304569,105.7755,10.1357,Bình Minh,ADM2,DISTRICT
SM677,106.9167,11.75,Bình Phước,ADM1,DISTRICT
SM304408,108.7638,15.319,Bình Sơn,ADM2,DISTRICT
SM678,108.0833,11.0833,Bình Thuận,ADM1,DISTRICT
SM303944,105.7293,10.0629,Bình Thuỷ,ADM2,DISTRICT
SM304114,106.7045,10.8049,Bình Thạnh,ADM2,DISTRICT
SM304570,105.7597,10.1349,Bình Tân,ADM2,DISTRICT
SM304108,106.7453,10.858,Bình Tân,ADM2,DISTRICT
SM304577,105.6623,21.3056,Bình Xuyên,ADM2,DISTRICT
SM304555,106.6446,10.1935,Bình Đại,ADM2,DISTRICT
SM675,109,14.1667,Bình Định,ADM1,DISTRICT
SM679,-72.45,-37.375,Bío-Bío,ADM1,DISTRICT
SM283736,90.2917,49.6612,Bökhmörön,ADM2,DISTRICT
SM283745,99.5988,46.205,Bömbögör,ADM2,DISTRICT
SM269352,81.95,44.9833,Börtala Mongol,ADM2,DISTRICT
SM286097,14.479,68.6882,Bø,ADM2,DISTRICT
SM286406,5.1762,59.7938,Bømlo,ADM2,DISTRICT
SM303928,107.0707,12.008,Bù Gia Mập,ADM2,DISTRICT
SM303927,107.2128,11.7756,Bù Đăng,ADM2,DISTRICT
SM303926,106.8155,12.0037,Bù Đốp,ADM2,DISTRICT
SM269014,8.5676,47.4971,Bülach,ADM2,DISTRICT
SM298616,35.852,38.8546,Bünyan,ADM2,DISTRICT
SM283653,103.7836,46.9781,Bürd,ADM2,DISTRICT
SM283773,104.1093,48.3277,Büregkhangai,ADM2,DISTRICT
SM283773,104.1093,48.3277,Büregkhangai,ADM2,DISTRICT
SM283773,103.8799,48.2491,Büregkhangai,ADM2,DISTRICT
SM283773,103.8799,48.2491,Büregkhangai,ADM2,DISTRICT
SM283703,105.0506,46.9102,Büren,ADM2,DISTRICT
SM283614,99.6,49.4246,Bürentogtokh,ADM2,DISTRICT
SM298245,28.8934,39.7822,Büyükorhan,ADM2,DISTRICT
SM298497,28.5957,41.0452,Büyükçekmece,ADM2,DISTRICT
SM577,27.9167,47.7833,Bălţi,ADM1,DISTRICT
SM288716,19.1042,50.3292,Będzin,ADM2,DISTRICT
SM271207,16.8726,48.7596,Břeclav,ADM2,DISTRICT
SM261907,47.1667,40.3333,Bərdə,ADM1:ADM2,DISTRICT
SM304028,105.7612,9.2674,Bạc Liêu,ADM2,DISTRICT
SM543,105.5,9.3,Bạc Liêu,ADM1,DISTRICT
SM304082,107.7303,20.1344,Bạch Long Vĩ,ADM2,DISTRICT
SM304300,105.833,22.2576,Bạch Thông,ADM2,DISTRICT
SM304268,107.7534,11.7116,Bảo Lâm,ADM2,DISTRICT
SM303967,105.4878,22.8704,Bảo Lâm,ADM2,DISTRICT
SM303962,105.7333,22.9008,Bảo Lạc,ADM2,DISTRICT
SM304264,107.7864,11.5376,Bảo Lộc,ADM2,DISTRICT
SM304276,104.1389,22.369,Bảo Thắng,ADM2,DISTRICT
SM304277,104.4642,22.2611,Bảo Yên,ADM2,DISTRICT
SM303934,108.3851,11.2757,Bắc Bình,ADM2,DISTRICT
SM304181,106.189,21.2899,Bắc Giang,ADM2,DISTRICT
SM544,106.4333,21.3333,Bắc Giang,ADM1,DISTRICT
SM304278,104.3077,22.51,Bắc Hà,ADM2,DISTRICT
SM545,105.8333,22.25,Bắc Kạn,ADM1,DISTRICT
SM304121,105.2902,22.7548,Bắc Mê,ADM2,DISTRICT
SM304430,106.0696,21.18,Bắc Ninh,ADM2,DISTRICT
SM546,106.1,21.1,Bắc Ninh,ADM1,DISTRICT
SM304124,104.9183,22.423,Bắc Quang,ADM2,DISTRICT
SM304245,106.2769,21.838,Bắc Sơn,ADM2,DISTRICT
SM304384,108.2168,15.2984,Bắc Trà My,ADM2,DISTRICT
SM304613,106.8,11.0667,Bắc Tân Uyên,ADM2,DISTRICT
SM304459,104.385,21.2504,Bắc Yên,ADM2,DISTRICT
SM304614,106.6154,11.1053,Bến Cát,ADM2,DISTRICT
SM304471,106.143,11.1289,Bến Cầu,ADM2,DISTRICT
SM304285,106.4548,10.6886,Bến Lức,ADM2,DISTRICT
SM304553,106.3783,10.2356,Bến Tre,ADM2,DISTRICT
SM547,106.5,10.1667,Bến Tre,ADM1,DISTRICT
SM304506,105.8846,20.0858,Bỉm Sơn,ADM2,DISTRICT
SM304376,106.283,17.5356,Bố Trạch,ADM2,DISTRICT
SM228978,5.2654,11.4653,C'lela,LANGUAGE,LANGUAGE
SM292070,29.5729,45.3021,C.a. Rosetti,ADM2,DISTRICT
SM289858,27.1672,45.0368,C.a. Rosetti,ADM2,DISTRICT
SM252563,107.36,16.21,Ca Tu,ETHNICITY,ETHNICITY
SM295821,46.125,5.8261,Caabudwaaq,ADM2,DISTRICT
SM228039,164.578,-20.4114,Caac,LANGUAGE,LANGUAGE
SM289541,-57.1267,-25.3804,Caacupé,ADM2,DISTRICT
SM289690,-56.0517,-25.4192,Caaguazú,ADM2,DISTRICT
SM800,-55.8333,-25.2,Caaguazú,ADM1,DISTRICT
SM267310,-61.7441,-3.1298,Caapiranga,ADM2,DISTRICT
SM264376,-34.9183,-7.4872,Caaporã,ADM2,DISTRICT
SM289627,-57.3845,-26.3375,Caapucú,ADM2,DISTRICT
SM263030,-54.8316,-22.6104,Caarapó,ADM2,DISTRICT
SM267735,-40.3905,-14.9761,Caatiba,ADM2,DISTRICT
SM289700,-56.2883,-26.1788,Caazapá,ADM2,DISTRICT
SM801,-56.0667,-26.25,Caazapá,ADM1,DISTRICT
SM264378,-36.3335,-7.5086,Cabaceiras,ADM2,DISTRICT
SM267737,-39.196,-12.5542,Cabaceiras do Paraguaçu,ADM2,DISTRICT
SM271038,-79.5,22.0833,Cabaiguán,ADM2,DISTRICT
SM301935,-80.552,35.3869,Cabarrus,ADM2,DISTRICT
SM275690,-88.0286,13.9942,Cabañas,ADM2,DISTRICT
SM275860,-89.06,14.76,Cabañas,ADM2,DISTRICT
SM275379,-89.776,14.8886,Cabañas,ADM2,DISTRICT
SM802,-88.75,13.8667,Cabañas,ADM1,DISTRICT
SM263228,-47.1757,-16.0618,Cabeceira Grande,ADM2,DISTRICT
SM268223,-47.0343,-15.7375,Cabeceiras,ADM2,DISTRICT
SM289441,-7.972,41.5208,Cabeceiras de Basto,ADM2,DISTRICT
SM265178,-42.2306,-4.4335,Cabeceiras do Piauí,ADM2,DISTRICT
SM264379,-34.8504,-7.0272,Cabedelo,ADM2,DISTRICT
SM303159,-82.2417,38.4203,Cabell,ADM2,DISTRICT
SM292408,22.3716,46.7659,Cabesti,ADM2,DISTRICT
SM15496,-65.6328,18.3677,Cabezas Barrio,ADM2,DISTRICT
SM17246,-60.9543,14.0058,Cabiche/Babonneau,ADM2,DISTRICT
SM303809,-71.4,10.3667,Cabimas,ADM2,DISTRICT
SM260414,12.3731,-5.4535,Cabinda,ADM2,DISTRICT
SM803,12.4053,-4.9342,Cabinda,ADM1,DISTRICT
SM263135,-60.6026,-13.5108,Cabixi,ADM2,DISTRICT
SM228041,-71.0622,0.161,Cabiyarí,LANGUAGE,LANGUAGE
SM264951,-35.0909,-8.2773,Cabo,ADM2,DISTRICT
SM252564,-83.43,14,Cabo,ETHNICITY,ETHNICITY
SM15497,-66.3814,18.4641,Cabo Caribe Barrio,ADM2,DISTRICT
SM281774,-105.3932,20.3439,Cabo Corrientes,ADM2,DISTRICT
SM804,39.4,-12.5,Cabo Delgado,ADM1,DISTRICT
SM265249,-42.0422,-22.7173,Cabo Frio,ADM2,DISTRICT
SM805,-67.1469,18.0563,Cabo Rojo,ADM1,DISTRICT
SM15498,-67.1467,18.0872,Cabo Rojo Barrio-Pueblo,ADM2,DISTRICT
SM263229,-46.38,-21.487,Cabo Verde,ADM2,DISTRICT
SM261510,148.8269,-33.1108,Cabonne,ADM2,DISTRICT
SM282557,-112.518,30.7841,Caborca,ADM2,DISTRICT
SM271861,-71.2466,18.1999,Cabral,ADM2,DISTRICT
SM252565,-71.55,2.66,Cabre,ETHNICITY,ETHNICITY
SM271790,-69.9667,19.5833,Cabrera,ADM2,DISTRICT
SM270442,-73.2677,6.5644,Cabrera,ADM2,DISTRICT
SM269958,-74.4719,3.8744,Cabrera,ADM2,DISTRICT
SM267072,-47.0763,-23.315,Cabreúva,ADM2,DISTRICT
SM275221,-91.6492,15.1031,Cabricán,ADM2,DISTRICT
SM264952,-39.3338,-8.3988,Cabrobó,ADM2,DISTRICT
SM267071,-49.3864,-22.4916,Cabrália Paulista,ADM2,DISTRICT
SM270293,-72.9803,4.2853,Cabuyaro,ADM2,DISTRICT
SM228040,-83.4102,9.6703,Cabécar,LANGUAGE,LANGUAGE
SM305099,24.7758,-33.0658,Cacadu,ADM2,DISTRICT
SM14232,-92.1588,15.0615,Cacahoatan,ADM2,DISTRICT
SM270071,-67.624,3.3253,Cacahual,ADM2,DISTRICT
SM283005,-89.238,20.988,Cacalchén,ADM2,DISTRICT
SM228047,-97.7637,17.7248,Cacaloxtepec Mixtec,LANGUAGE,LANGUAGE
SM15499,-66.0285,18.0158,Cacao Alto Barrio,ADM2,DISTRICT
SM15500,-66.0255,17.9975,Cacao Bajo Barrio,ADM2,DISTRICT
SM15502,-65.9631,18.3464,Cacao Barrio (Carolina),ADM2,DISTRICT
SM15501,-66.9452,18.4542,Cacao Barrio (Quebradillas),ADM2,DISTRICT
SM228044,-88.1133,13.7437,Cacaopera,LANGUAGE,LANGUAGE
SM295729,-88.0593,13.8014,Cacaopera,ADM2,DISTRICT
SM252566,-84.59,11.58,Cacaopera,ETHNICITY,ETHNICITY
SM252566,-84.59,11.58,Cacaopera,ETHNICITY,ETHNICITY
SM252566,-85.9,14.1,Cacaopera,ETHNICITY,ETHNICITY
SM252566,-85.9,14.1,Cacaopera,ETHNICITY,ETHNICITY
SM252566,-85.26,12.98,Cacaopera,ETHNICITY,ETHNICITY
SM252566,-85.26,12.98,Cacaopera,ETHNICITY,ETHNICITY
SM15503,-66.4967,18.2263,Cacaos Barrio,ADM2,DISTRICT
SM266121,-63.1495,-10.3258,Cacaulândia,ADM2,DISTRICT
SM266023,-54.7923,-29.9082,Cacequi,ADM2,DISTRICT
SM9894,-57.7108,-16.4226,Caceres (1998-),ADM2,DISTRICT
SM228048,109.138,11.6489,Cacgia Roglai,LANGUAGE,LANGUAGE
SM269112,-70.7206,-34.2999,Cachapoal,ADM2,DISTRICT
SM277397,92.8577,24.7821,Cachar,ADM2,DISTRICT
SM302915,-111.7436,41.7224,Cache,ADM2,DISTRICT
SM275091,-16.1667,12.1667,Cacheu,ADM2,DISTRICT
SM806,-16.1667,12,Cacheu,ADM1,DISTRICT
SM260955,-66.1854,-25.0974,Cachi,ADM2,DISTRICT
SM269969,-74.4871,4.7042,Cachipay,ADM2,DISTRICT
SM267738,-38.9709,-12.5846,Cachoeira,ADM2,DISTRICT
SM268224,-50.9821,-18.5428,Cachoeira Alta,ADM2,DISTRICT
SM262959,-43.9313,-3.1201,Cachoeira Grande,ADM2,DISTRICT
SM267074,-44.9923,-22.7011,Cachoeira Paulista,ADM2,DISTRICT
SM263230,-44.4625,-19.5187,Cachoeira da Prata,ADM2,DISTRICT
SM268225,-50.6861,-16.7251,Cachoeira de Goias,ADM2,DISTRICT
SM263231,-45.7945,-22.3556,Cachoeira de Minas,ADM2,DISTRICT
SM263232,-41.493,-15.9716,Cachoeira de Pajes,ADM2,DISTRICT
SM264146,-48.9312,-0.8461,Cachoeira do Arari,ADM2,DISTRICT
SM264147,-46.4691,-1.9404,Cachoeira do Piriá,ADM2,DISTRICT
SM266024,-52.9707,-30.2081,Cachoeira do Sul,ADM2,DISTRICT
SM264380,-38.699,-6.9449,Cachoeira dos índios,ADM2,DISTRICT
SM263233,-49.4701,-18.5909,Cachoeira dourada,ADM2,DISTRICT
SM268226,-49.651,-18.4952,Cachoeira dourada,ADM2,DISTRICT
SM265250,-42.7115,-22.4874,Cachoeiras de Macacu,ADM2,DISTRICT
SM267210,-47.8789,-6.0981,Cachoeirinha,ADM2,DISTRICT
SM266025,-51.0958,-29.9218,Cachoeirinha,ADM2,DISTRICT
SM267971,-41.1906,-20.7681,Cachoeiro de Itapemirim,ADM2,DISTRICT
SM264953,-36.2942,-8.5239,Cachoerinha,ADM2,DISTRICT
SM264381,-37.1537,-7.1345,Cacimba de Areia,ADM2,DISTRICT
SM264382,-35.8005,-6.6233,Cacimba de dentro,ADM2,DISTRICT
SM264383,-37.0998,-7.2318,Cacimbas,ADM2,DISTRICT
SM265342,-36.9047,-9.449,Cacimbinhas,ADM2,DISTRICT
SM275110,-15.0167,11.1167,Cacine,ADM2,DISTRICT
SM303582,-68.5444,10.971,Cacique Manaure,ADM2,DISTRICT
SM266026,-51.6827,-27.8173,Cacique doble,ADM2,DISTRICT
SM17247,-60.9386,13.7789,Cacoa,ADM2,DISTRICT
SM17248,-60.9511,13.9888,Cacoa/Babonneau,ADM2,DISTRICT
SM266122,-61.4317,-11.4904,Cacoal,ADM2,DISTRICT
SM271132,-76.3267,20.7359,Cacocum,ADM2,DISTRICT
SM260340,19.2865,-10.1365,Cacolo,ADM2,DISTRICT
SM260309,15.1539,-13.7538,Caconda,ADM2,DISTRICT
SM263234,-46.6225,-21.5415,Caconde,ADM2,DISTRICT
SM12193,-78.2073,18.4164,Cacoon,ADM2,DISTRICT
SM12194,-78.0134,18.4025,Cacoon Castle,ADM2,DISTRICT
SM260322,13.5008,-8.7568,Cacuaco,ADM2,DISTRICT
SM306120,14.2438,-14.441,Cacula,ADM2:ADM3,DISTRICT
SM267739,-42.4152,-14.3883,Caculé,ADM2,DISTRICT
SM260344,15.7281,-9.4648,Cacuzo,ADM2,DISTRICT
SM17859,46.6259,6.137,Cadaado (excl. Cadaado town),ADM2,DISTRICT
SM17860,47.0367,6.1307,Cadaado Town,ADM2,DISTRICT
SM295785,46.264,3.0907,Cadale,ADM2,DISTRICT
SM289269,-9.0674,39.2462,Cadaval,ADM2,DISTRICT
SM296184,18.7833,49.4333,Cadca,ADM2,DISTRICT
SM228053,-93.5,33.33,Caddo,LANGUAGE,LANGUAGE
SM302247,-98.3752,35.1744,Caddo,ADM2,DISTRICT
SM301171,-93.8824,32.5802,Caddo,ADM2,DISTRICT
SM282199,-99.9608,25.4887,Cadereyta Jiménez,ADM2,DISTRICT
SM282458,-99.6562,20.7824,Cadereyta de Montes,ADM2,DISTRICT
SM274761,-3.1667,51.6667,Caerphilly,ADM1:ADM2,DISTRICT
SM267741,-41.01,-14.2923,Caetanos,ADM2,DISTRICT
SM263235,-44.41,-19.3347,Caetanópolis,ADM2,DISTRICT
SM252567,-35.23,-7.96,Caete,ETHNICITY,ETHNICITY
SM252567,-36.46,-10.03,Caete,ETHNICITY,ETHNICITY
SM267742,-42.4933,-13.9788,Caetité,ADM2,DISTRICT
SM263237,-43.6506,-19.8702,Caeté,ADM2,DISTRICT
SM264954,-36.6439,-8.8059,Caetés,ADM2,DISTRICT
SM267743,-41.5073,-11.7456,Cafarnaum,ADM2,DISTRICT
SM260960,-65.8333,-26.0833,Cafayate,ADM2,DISTRICT
SM264788,-51.7143,-22.7998,Cafeara,ADM2,DISTRICT
SM267075,-49.5706,-21.743,Cafelândia,ADM2,DISTRICT
SM264790,-53.3509,-24.693,Cafelândia,ADM2,DISTRICT
SM264791,-53.6091,-23.9565,Cafezal do Sul,ADM2,DISTRICT
SM17249,-61.0355,13.7841,Caffiere,ADM2,DISTRICT
SM228055,-43.6455,-23.0448,Cafundo,LANGUAGE,LANGUAGE
SM807,121.8333,18,Cagayan,ADM1:ADM2,DISTRICT
SM4198,121.7333,17.6567,Cagayan Valley,ADM1,DISTRICT
SM228060,-74,4,Cagua,LANGUAGE,LANGUAGE
SM15504,-67.2105,18.2976,Caguabo Barrio,ADM2,DISTRICT
SM15505,-66.7508,18.2911,Caguana Barrio,ADM2,DISTRICT
SM808,-66.0496,18.2133,Caguas,ADM1,DISTRICT
SM15506,-66.0332,18.2342,Caguas Barrio-Pueblo,ADM2,DISTRICT
SM15507,-66.096,18.2378,Cagueitas Barrio,ADM2,DISTRICT
SM260450,14.2364,-16.3999,Cahama,ADM2,DISTRICT
SM252568,-69.87,19,Cahibo,ETHNICITY,ETHNICITY
SM283868,32.6733,-15.7077,Cahora Bassa,ADM2,DISTRICT
SM228067,-73.9469,-3.7267,Cahuarano,LANGUAGE,LANGUAGE
SM228063,-116.691,33.5324,Cahuilla,LANGUAGE,LANGUAGE
SM252569,-115.94,33.32,Cahuilla,ETHNICITY,ETHNICITY
SM252569,-115.94,33.32,Cahuilla,ETHNICITY,ETHNICITY
SM252569,-116,33,Cahuilla,ETHNICITY,ETHNICITY
SM252569,-116,33,Cahuilla,ETHNICITY,ETHNICITY
SM252570,-116.24,33.59,Cahuilla (Desert),ETHNICITY,ETHNICITY
SM252570,-116.24,33.59,Cahuilla (Desert),ETHNICITY,ETHNICITY
SM252570,-116.2,33.5,Cahuilla (Desert),ETHNICITY,ETHNICITY
SM252570,-116.2,33.5,Cahuilla (Desert),ETHNICITY,ETHNICITY
SM252571,-116.5,33.3,Cahuilla (Mountain),ETHNICITY,ETHNICITY
SM252572,-116.7,33.8,Cahuilla (Pass),ETHNICITY,ETHNICITY
SM809,28.3333,45.8333,Cahul,ADM1,DISTRICT
SM304537,106.0677,10.3894,Cai Lậy,ADM2,DISTRICT
SM304536,106.1435,10.4259,Cai Lậy (Thị xã),ADM2,DISTRICT
SM283970,35.058,-17.769,Caia,ADM2,DISTRICT
SM266434,-51.2323,-21.9382,Caiabu,ADM2,DISTRICT
SM260398,13.922,-13.0838,Caiambambo,ADM2,DISTRICT
SM263238,-41.9002,-20.7308,Caiana,ADM2,DISTRICT
SM290071,23.9088,46.8067,Caianu,ADM2,DISTRICT
SM292544,24.1344,47.2539,Caianu Mic,ADM2,DISTRICT
SM268228,-51.7915,-16.9599,Caiapônia,ADM2,DISTRICT
SM271055,-79.466,22.52,Caibarién,ADM2,DISTRICT
SM9895,-54.6559,-28.3043,Caibate (1996-),ADM2:ADM3,DISTRICT
SM266378,-53.2784,-27.0342,Caibi,ADM2,DISTRICT
SM270188,-75.9972,6.4281,Caicedo,ADM2,DISTRICT
SM270645,-75.8343,4.3337,Caicedonia,ADM2,DISTRICT
SM17250,-60.9926,13.9195,Caico/Millet,ADM2,DISTRICT
SM265522,-37.0478,-6.4471,Caicó,ADM2,DISTRICT
SM266435,-46.7382,-23.3813,Caieiras,ADM2,DISTRICT
SM228069,115.4343,29.1565,Caijia,LANGUAGE,LANGUAGE
SM297832,125.28,-8.9,Cailaco,ADM2,DISTRICT
SM17251,-60.8881,13.9824,Caille Des,ADM2,DISTRICT
SM271116,-75.1518,19.9897,Caimanera,ADM2,DISTRICT
SM15508,-67.1134,18.4365,Caimital Alto Barrio,ADM2,DISTRICT
SM15509,-67.137,18.4281,Caimital Bajo Barrio,ADM2,DISTRICT
SM15510,-66.1009,18.0002,Caimital Barrio,ADM2,DISTRICT
SM271142,-82.6,22.95,Caimito,ADM2,DISTRICT
SM270539,-75.1358,8.7883,Caimito,ADM2,DISTRICT
SM15512,-65.8668,18.2428,Caimito Barrio (Juncos),ADM2,DISTRICT
SM15513,-66.0715,18.3371,Caimito Barrio (San Juan),ADM2,DISTRICT
SM15511,-66.8549,18.0703,Caimito Barrio (Yauco),ADM2,DISTRICT
SM15514,-67.0124,18.1181,Cain Alto Barrio,ADM2,DISTRICT
SM15515,-67.0463,18.1087,Cain Bajo Barrio,ADM2,DISTRICT
SM292177,24.3089,45.495,Cainenii Mici,ADM2,DISTRICT
SM252573,-52.77,-23.97,Caingua,ETHNICITY,ETHNICITY
SM260907,-54.8333,-27.1667,Cainguás,ADM2,DISTRICT
SM275092,-16.1636,11.9251,Caio,ADM2,DISTRICT
SM12195,-78.0113,18.2284,Cairn Curran,ADM2,DISTRICT
SM9728,145.7529,-16.9027,Cairns (2014-),ADM2,DISTRICT
SM267744,-38.9481,-13.5266,Cairu,ADM2,DISTRICT
SM292091,26.9163,46.1799,Caiuti,ADM2,DISTRICT
SM266436,-51.959,-21.7652,Caiuá,ADM2,DISTRICT
SM252574,-69.87,19,Caizcimu,ETHNICITY,ETHNICITY
SM266028,-53.4582,-27.2483,Caiçara,ADM2,DISTRICT
SM264384,-35.395,-6.5963,Caiçara,ADM2,DISTRICT
SM265520,-36.0837,-5.1753,Caiçara do Norte,ADM2,DISTRICT
SM265521,-36.0255,-5.8061,Caiçara do Rio do Vento,ADM2,DISTRICT
SM286882,-78.1667,-7.5,Cajabamba,ADM2,DISTRICT
SM266437,-46.8813,-23.3519,Cajamar,ADM2,DISTRICT
SM286887,-78.4167,-7.3333,Cajamarca,ADM2,DISTRICT
SM270595,-75.5,4.4167,Cajamarca,ADM2,DISTRICT
SM811,-78.8333,-6.25,Cajamarca,ADM1,DISTRICT
SM228073,-78.3214,-7.0931,Cajamarca Quechua,LANGUAGE,LANGUAGE
SM262960,-44.5729,-2.8637,Cajapió,ADM2,DISTRICT
SM262961,-45.022,-3.3851,Cajari,ADM2,DISTRICT
SM286770,-77,-10.6667,Cajatambo,ADM2,DISTRICT
SM228074,-76.9602,-10.4126,Cajatambo North Lima Quechua,LANGUAGE,LANGUAGE
SM266438,-48.2011,-24.7716,Cajati,ADM2,DISTRICT
SM264385,-38.5693,-6.9221,Cajazeiras,ADM2,DISTRICT
SM265179,-42.3924,-6.7818,Cajazeiras do Piauí,ADM2,DISTRICT
SM264386,-37.8145,-6.9463,Cajazeirinhas,ADM2,DISTRICT
SM282558,-109.9294,27.51,Cajeme,ADM2,DISTRICT
SM269884,-76.708,2.6449,Cajibío,ADM2,DISTRICT
SM269976,-74.0526,4.9128,Cajicá,ADM2,DISTRICT
SM303728,-62.8269,10.5692,Cajigal,ADM2,DISTRICT
SM303711,-65.1935,9.5971,Cajigal,ADM2,DISTRICT
SM266439,-48.8518,-20.8862,Cajobi,ADM2,DISTRICT
SM275227,-91.613,14.9342,Cajolá,ADM2,DISTRICT
SM228076,-96.3352,17.1427,Cajonos Zapotec,LANGUAGE,LANGUAGE
SM265343,-36.1578,-9.3764,Cajueiro,ADM2,DISTRICT
SM265181,-41.3544,-2.9875,Cajueiro da Praia,ADM2,DISTRICT
SM454417,-92.417,30.617,Cajun,ETHNICITY,ETHNICITY
SM228078,-91.6133,29.6869,Cajun French,LANGUAGE,LANGUAGE
SM263239,-42.7626,-20.7874,Cajuri,ADM2,DISTRICT
SM266440,-47.3165,-21.2705,Cajuru,ADM2,DISTRICT
SM291776,25.9919,47.7139,Cajvana,ADM2,DISTRICT
SM228079,9.7775,6.2698,Caka,LANGUAGE,LANGUAGE
SM274415,179.4167,-16.6667,Cakaudrove,ADM2,DISTRICT
SM228080,-90.6989,14.6467,Cakchiquel-Quiché Mixed Language,LANGUAGE,LANGUAGE
SM228083,9.1607,9.0092,Cakfem-Mushere-Jibyal,LANGUAGE,LANGUAGE
SM285405,8.3034,4.9909,Calabar,ADM2,DISTRICT
SM285406,8.3364,4.8641,Calabar South,ADM2,DISTRICT
SM4197,121.09,14.31,Calabarzon,ADM1,DISTRICT
SM15516,-66.9728,18.3027,Calabazas Barrio (San Sebastián),ADM2,DISTRICT
SM15517,-65.9112,18.0537,Calabazas Barrio (Yabucoa),ADM2,DISTRICT
SM812,16.5,39,Calabria,ADM1,DISTRICT
SM290336,22.9313,43.9898,Calafat,ADM2,DISTRICT
SM461276,26.116,47.8658,Calafindesti (2003-),ADM2,DISTRICT
SM260416,19.3738,-17.3263,Calai,ADM2,DISTRICT
SM14234,-89.75,18,Calakmul (1998-),ADM2,DISTRICT
SM270076,-72.9824,1.6018,Calamar,ADM2,DISTRICT
SM10387,-74.9424,10.2338,Calamar (1997-),ADM2,DISTRICT
SM228090,120.094,12.0716,Calamian Tagbanwa,LANGUAGE,LANGUAGE
SM261122,-64.5833,-32.25,Calamuchita,ADM2,DISTRICT
SM290748,23.0104,45.7394,Calan,ADM2,DISTRICT
SM260350,15.9485,-8.6709,Calandula,ADM2,DISTRICT
SM252575,-123.04,44.04,Calapuya,ETHNICITY,ETHNICITY
SM290338,24.0408,43.7842,Calarasi,ADM2,DISTRICT
SM292617,27.2777,47.6034,Calarasi,ADM2,DISTRICT
SM289981,27.3333,44.2,Calarasi,ADM2,DISTRICT
SM290072,23.8536,46.4938,Calarasi,ADM2,DISTRICT
SM814,28.3084,47.2937,Calarasi,ADM1,DISTRICT
SM270418,-75.6333,4.5,Calarcá,ADM2,DISTRICT
SM290073,23.0305,46.7628,Calatele,ADM2,DISTRICT
SM303207,-120.5541,38.2046,Calaveras,ADM2,DISTRICT
SM295813,50.7935,11.6672,Calawla,ADM2,DISTRICT
SM252576,-52.25,2.14,Calayua,ETHNICITY,ETHNICITY
SM286902,-72.1667,-13,Calca,ADM2,DISTRICT
SM282851,-97.142,19.137,Calcahualco,ADM2,DISTRICT
SM301109,-93.358,30.2292,Calcasieu,ADM2,DISTRICT
SM296101,-55.6736,5.845,Calcutta,ADM2,DISTRICT
SM291224,24.9426,44.454,Caldararu,ADM2,DISTRICT
SM270189,-75.6271,6.0567,Caldas,ADM2,DISTRICT
SM270740,-73.8807,5.5783,Caldas,ADM2,DISTRICT
SM263240,-46.3592,-21.8867,Caldas,ADM2,DISTRICT
SM815,-75.3333,5.2667,Caldas,ADM1,DISTRICT
SM264387,-35.3487,-7.1511,Caldas Brandão,ADM2,DISTRICT
SM268229,-48.6548,-17.7313,Caldas Novas,ADM2,DISTRICT
SM289254,-9.1362,39.4123,Caldas da Rainha,ADM2,DISTRICT
SM268231,-49.0006,-16.7486,Caldazinha,ADM2,DISTRICT
SM267745,-40.2952,-11.0435,Caldeirão Grande,ADM2,DISTRICT
SM265182,-40.5877,-7.3436,Caldeirão Grande do Piauí,ADM2,DISTRICT
SM274609,-2,53.7,Calderdale,ADM1:ADM2,DISTRICT
SM12196,-77.2714,18.2564,Calderwood,ADM2,DISTRICT
SM228093,-78.2587,-0.3621,Calderón Highland Quichua,LANGUAGE,LANGUAGE
SM269885,-76.4567,2.8076,Caldonó,ADM2,DISTRICT
SM302814,-97.62,29.8371,Caldwell,ADM2,DISTRICT
SM301936,-81.5466,35.953,Caldwell,ADM2,DISTRICT
SM301527,-93.9828,39.6558,Caldwell,ADM2,DISTRICT
SM301017,-87.8679,37.1453,Caldwell,ADM2,DISTRICT
SM301110,-92.1166,32.0923,Caldwell,ADM2,DISTRICT
SM302929,-72.1022,44.4647,Caledonia,ADM2,DISTRICT
SM12197,-78.0117,18.2587,Caledonia,ADM2,DISTRICT
SM283082,-102.6926,22.9826,Calera,ADM2,DISTRICT
SM260851,-64,-38.5833,Caleu Caleu,ADM2,DISTRICT
SM10129,-114.052,51.035,Calgary,ADM2,DISTRICT
SM289274,-17.1977,32.7637,Calheta,ADM2,DISTRICT
SM289387,-27.9146,38.5977,Calheta,ADM2,DISTRICT
SM303160,-81.1176,38.8445,Calhoun,ADM2,DISTRICT
SM302477,-80.7803,33.6749,Calhoun,ADM2,DISTRICT
SM302815,-96.6151,28.4391,Calhoun,ADM2,DISTRICT
SM301492,-89.3365,33.9365,Calhoun,ADM2,DISTRICT
SM301255,-85.0056,42.2465,Calhoun,ADM2,DISTRICT
SM300788,-94.6404,42.3852,Calhoun,ADM2,DISTRICT
SM300377,-85.1972,30.406,Calhoun,ADM2,DISTRICT
SM300450,-84.6245,31.5292,Calhoun,ADM2,DISTRICT
SM300658,-90.6675,39.1693,Calhoun,ADM2,DISTRICT
SM300311,-85.826,33.7714,Calhoun,ADM2,DISTRICT
SM302418,-92.503,33.558,Calhoun,ADM2,DISTRICT
SM295590,-88.4636,13.4375,California,ADM2,DISTRICT
SM270443,-72.9582,7.3434,California,ADM2,DISTRICT
SM816,-119.7513,37.2502,California,ADM1,DISTRICT
SM264792,-51.3282,-23.6634,Califórnia,ADM2,DISTRICT
SM14235,-98.2676,17.5398,Calihuala,ADM2,DISTRICT
SM270646,-76.6265,3.9251,Calima,ADM2,DISTRICT
SM292178,24.3438,45.2425,Calimanesti,ADM2,DISTRICT
SM281895,-99.6128,19.1769,Calimaya,ADM2,DISTRICT
SM271000,-80.9096,22.5336,Calimete,ADM2,DISTRICT
SM291870,25.2131,44.11,Calinesti,ADM2,DISTRICT
SM290994,23.9701,47.785,Calinesti,ADM2,DISTRICT
SM291650,23.2735,47.9034,Calinesti-oas,ADM2,DISTRICT
SM260978,-69.75,-31.5,Calingasta,ADM2,DISTRICT
SM271133,-76.5909,20.8432,Calixto García,ADM2,DISTRICT
SM283094,-89.9876,20.3858,Calkiní,ADM2,DISTRICT
SM15518,-66.5409,18.0647,Callabo Barrio,ADM2,DISTRICT
SM302817,-99.3735,32.2977,Callahan,ADM2,DISTRICT
SM286895,-77.1037,-11.9475,Callao,ADM2,DISTRICT
SM817,-77.125,-12.0514,Callao,ADM1,DISTRICT
SM228096,-68.1018,-15.8845,Callawalla,LANGUAGE,LANGUAGE
SM301528,-91.926,38.8355,Callaway,ADM2,DISTRICT
SM15519,-66.8489,18.3272,Callejones Barrio,ADM2,DISTRICT
SM301018,-88.2722,36.6211,Calloway,ADM2,DISTRICT
SM324040,-113.8072,53.2637,Calmar,ADM2:ADM3,DISTRICT
SM291871,24.8561,43.9608,Calmatuiu,ADM2,DISTRICT
SM291872,24.8158,44.044,Calmatuiu De Sus,ADM2,DISTRICT
SM266379,-51.0244,-26.6404,Calmon,ADM2,DISTRICT
SM281659,-98.5703,20.9022,Calnali,ADM2,DISTRICT
SM290617,23.0629,44.942,Calnic,ADM2,DISTRICT
SM228097,-3.5144,40.8339,Calo,LANGUAGE,LANGUAGE
SM286669,-80.8512,8.3968,Calobre,ADM2,DISTRICT
SM290339,23.7597,44.1556,Calopar,ADM2,DISTRICT
SM283016,-88.1258,21.0249,Calotmul,ADM2,DISTRICT
SM10388,-76.3604,3.0389,Caloto (2006-),ADM2,DISTRICT
SM282365,-98.4624,19.1041,Calpan,ADM2,DISTRICT
SM282721,-98.5663,19.5707,Calpulalpan,ADM2,DISTRICT
SM277899,14.0642,37.3745,Caltanissetta,ADM2,DISTRICT
SM282366,-97.5106,18.1635,Caltepec,ADM2,DISTRICT
SM295567,-89.6397,13.7054,Caluco,ADM2,DISTRICT
SM291506,26.3918,45.08,Calugareni,ADM2,DISTRICT
SM290618,22.8095,45.0509,Calugareni,ADM2,DISTRICT
SM290606,25.9939,44.1641,Calugareni,ADM2,DISTRICT
SM273547,-79.25,-1.6333,Caluma,ADM2,DISTRICT
SM264956,-38.098,-8.0191,Calumbi,ADM2,DISTRICT
SM303293,-88.2181,44.0816,Calumet,ADM2,DISTRICT
SM9668,14.5348,-13.9209,Caluquembe (2011-),ADM2,DISTRICT
SM252577,-81.44,26.13,Calusa,ETHNICITY,ETHNICITY
SM252577,-81.44,26.13,Calusa,ETHNICITY,ETHNICITY
SM252577,-81.96,26.48,Calusa,ETHNICITY,ETHNICITY
SM252577,-81.96,26.48,Calusa,ETHNICITY,ETHNICITY
SM228099,121.384,12.0489,Caluyanun,LANGUAGE,LANGUAGE
SM15520,-67.2254,18.3159,Calvache Barrio,ADM2,DISTRICT
SM274512,-0.3061,49.0901,Calvados,ADM2,DISTRICT
SM17252,-60.9866,14.0139,Calvary,ADM2,DISTRICT
SM17253,-61.0562,13.8504,Calvary/Calvaire,ADM2,DISTRICT
SM273462,-79.5667,-4.3167,Calvas,ADM2,DISTRICT
SM301231,-76.5306,38.5347,Calvert,ADM2,DISTRICT
SM281489,-102.7167,21.85,Calvillo,ADM2,DISTRICT
SM289859,26.2998,45.2491,Calvini,ADM2,DISTRICT
SM17254,-60.9611,13.8255,Calypso,ADM2,DISTRICT
SM15521,-65.9141,17.9937,Calzada Barrio,ADM2,DISTRICT
SM264955,-36.3315,-8.7358,Calçado,ADM2,DISTRICT
SM267296,-51.4529,2.361,Calçoene,ADM2,DISTRICT
SM228098,0.6,41.424,Caló,LANGUAGE,LANGUAGE
SM304212,109.0917,12.0771,Cam Lâm,ADM2,DISTRICT
SM304440,106.9618,16.7934,Cam Lộ,ADM2,DISTRICT
SM304213,109.1486,11.9071,Cam Ranh,ADM2,DISTRICT
SM267746,-39.5082,-15.4277,Camacan,ADM2,DISTRICT
SM252578,-40.79,-14.61,Camacan Mongoyo,ETHNICITY,ETHNICITY
SM15522,-67.1386,18.4667,Camaceyes Barrio,ADM2,DISTRICT
SM263241,-45.1433,-20.6422,Camacho,ADM2,DISTRICT
SM260368,13.0219,-13.9562,Camacuio,ADM2,DISTRICT
SM9669,17.7029,-12.3926,Camacupa City,ADM2,DISTRICT
SM266030,-51.853,-30.9197,Camagua,ADM2,DISTRICT
SM252579,-77.73,21.41,Camaguey,ETHNICITY,ETHNICITY
SM303588,-67.6072,8.1066,Camaguán,ADM2,DISTRICT
SM270979,-77.9617,21.4373,Camagüey,ADM2,DISTRICT
SM818,-77.85,21.35,Camagüey,ADM1,DISTRICT
SM271060,-79.7333,22.4667,Camajuaní,ADM2,DISTRICT
SM264389,-36.7421,-7.927,Camalaú,ADM2,DISTRICT
SM267749,-39.1223,-13.9407,Camamu,ADM2,DISTRICT
SM252580,-72.05,-16.48,Camana,ETHNICITY,ETHNICITY
SM263242,-46.0784,-22.7907,Camanducaia,ADM2,DISTRICT
SM260359,20.1853,-11.4098,Camanongue,ADM2,DISTRICT
SM286864,-72.8333,-16.4167,Camaná,ADM2,DISTRICT
SM9896,-53.8578,-19.0577,Camapua (2005-),ADM2,DISTRICT
SM291590,22.6282,47.3173,Camar,ADM2,DISTRICT
SM252581,-61.7,5.6585,Camaracoto,ETHNICITY,ETHNICITY
SM252581,-61.7,5.6585,Camaracoto,ETHNICITY,ETHNICITY
SM252581,-63,6,Camaracoto,ETHNICITY,ETHNICITY
SM252581,-63,6,Camaracoto,ETHNICITY,ETHNICITY
SM264957,-34.9914,-7.9878,Camaragibe,ADM2,DISTRICT
SM290074,24.1137,46.787,Camarasu,ADM2,DISTRICT
SM282677,-98.8502,26.2313,Camargo,ADM2,DISTRICT
SM283213,-104.7801,27.8291,Camargo,ADM2,DISTRICT
SM266031,-52.2199,-28.6144,Camargo,ADM2,DISTRICT
SM819,122.75,14.1667,Camarines Norte,ADM1:ADM2,DISTRICT
SM228103,122.835,14.1571,Camarines Norte Agta,LANGUAGE,LANGUAGE
SM820,123.3333,13.6667,Camarines Sur,ADM1:ADM2,DISTRICT
SM15523,-66.1114,18.3368,Camarones Barrio,ADM2,DISTRICT
SM291651,23.3087,47.9944,Camarzana,ADM2,DISTRICT
SM282852,-96.5681,19.0081,Camarón de Tejada,ADM2,DISTRICT
SM300548,-114.8059,43.4633,Camas,ADM2,DISTRICT
SM275658,-88.3861,13.9986,Camasca,ADM2,DISTRICT
SM303833,-66.9062,9.7876,Camatagua,ADM2,DISTRICT
SM267748,-38.2062,-12.6658,Camaçari,ADM2,DISTRICT
SM15524,-66.6939,18.4623,Cambalache Barrio,ADM2,DISTRICT
SM260433,14.3449,-9.5633,Cambambe,ADM2,DISTRICT
SM247305,11.7002,6.3666,Cambap-Langa,LANGUAGE,LANGUAGE
SM264793,-50.0882,-23.0042,Cambará,ADM2,DISTRICT
SM266032,-50.1185,-29.0673,Cambará do Sul,ADM2,DISTRICT
SM252582,-69.66,-3.6,Cambeba,ETHNICITY,ETHNICITY
SM252582,-67.8,-2.35,Cambeba,ETHNICITY,ETHNICITY
SM12198,-76.7809,18.2393,Camberwell,ADM2,DISTRICT
SM264795,-51.5667,-23.6316,Cambira,ADM2,DISTRICT
SM271832,-70.2333,18.5,Cambita Garabito,ADM2,DISTRICT
SM228108,104.8703,11.5578,Cambodian Sign Language,LANGUAGE,LANGUAGE
SM266380,-48.7153,-27.0756,Camboriú,ADM2,DISTRICT
SM302287,-78.7137,40.4953,Cambria,ADM2,DISTRICT
SM12199,-77.8955,18.3131,Cambridge,ADM2,DISTRICT
SM261346,115.7989,-31.9354,Cambridge,ADM2,DISTRICT
SM274610,0.0833,52.3333,Cambridgeshire,ADM1:ADM2,DISTRICT
SM265251,-41.9489,-21.5081,Cambuci,ADM2,DISTRICT
SM260331,21.4688,-7.8598,Cambulo,ADM2,DISTRICT
SM260351,17.8105,-10.1394,Cambundi-Catembo,ADM2,DISTRICT
SM263244,-45.2717,-21.8538,Cambuquira,ADM2,DISTRICT
SM263243,-46.0612,-22.5824,Cambuí,ADM2,DISTRICT
SM289585,-55.7635,-27.3564,Cambyreta,ADM2,DISTRICT
SM264794,-51.295,-23.1803,Cambé,ADM2,DISTRICT
SM301937,-76.1611,36.3415,Camden,ADM2,DISTRICT
SM301828,-74.9598,39.8035,Camden,ADM2,DISTRICT
SM301529,-92.7661,38.027,Camden,ADM2,DISTRICT
SM300452,-81.6364,30.9225,Camden,ADM2,DISTRICT
SM261511,150.7252,-34.0161,Camden,ADM2,DISTRICT
SM260360,20.8315,-11.5635,Cameia,ADM2,DISTRICT
SM282853,-97.1602,18.7825,Camerino Z. Mendoza,ADM2,DISTRICT
SM302288,-78.2038,41.4368,Cameron,ADM2,DISTRICT
SM302818,-97.4529,26.1515,Cameron,ADM2,DISTRICT
SM301111,-93.1989,29.8469,Cameron,ADM2,DISTRICT
SM284437,101.3764,4.4709,Cameron Highlands,ADM2,DISTRICT
SM228114,9.8017,5.4393,Cameroon Pidgin,LANGUAGE,LANGUAGE
SM264148,-49.4921,-2.2958,Cametá,ADM2,DISTRICT
SM821,124.75,9.1667,Camiguin,ADM1:ADM2,DISTRICT
SM273416,-79.7374,-3.0628,Camilo Ponce Enriquez,ADM2,DISTRICT
SM289354,-8.8013,41.8465,Caminha,ADM2,DISTRICT
SM15525,-65.8431,18.0308,Camino Nuevo Barrio,ADM2,DISTRICT
SM228117,86.7612,27.3371,Camling,LANGUAGE,LANGUAGE
SM285497,-85.2098,12.5115,Camoapa,ADM2,DISTRICT
SM267890,-40.8389,-2.9366,Camocim,ADM2,DISTRICT
SM264958,-35.7766,-8.3361,Camocim de São Félix,ADM2,DISTRICT
SM282367,-97.7512,20.0399,Camocuautla,ADM2,DISTRICT
SM275405,-89.2846,14.8467,Camotán,ADM2,DISTRICT
SM302819,-94.9785,32.9732,Camp,ADM2,DISTRICT
SM228124,119.6236,-4.0167,Campalagian,LANGUAGE,LANGUAGE
SM275742,-86.6447,14.4966,Campamento,ADM2,DISTRICT
SM270190,-75.2894,7.0391,Campamento,ADM2,DISTRICT
SM260735,-58.9167,-34.1667,Campana,ADM2,DISTRICT
SM263246,-45.3975,-21.8141,Campanha,ADM2,DISTRICT
SM292409,22.5274,46.5244,Campani,ADM2,DISTRICT
SM822,14.9205,40.9106,Campania,ADM1,DISTRICT
SM263245,-41.7391,-18.2749,Campanário,ADM2,DISTRICT
SM261219,144.7084,-36.3234,Campaspe,ADM2,DISTRICT
SM303311,-105.5483,44.2484,Campbell,ADM2,DISTRICT
SM302982,-79.0964,37.2056,Campbell,ADM2,DISTRICT
SM302479,-100.0516,45.7711,Campbell,ADM2,DISTRICT
SM302610,-84.1494,36.4035,Campbell,ADM2,DISTRICT
SM301019,-84.3797,38.9465,Campbell,ADM2,DISTRICT
SM12200,-76.7813,17.9791,Campbell Town,ADM2,DISTRICT
SM261753,138.6855,-34.8855,Campbelltown,ADM2,DISTRICT
SM261512,150.8333,-34.0833,Campbelltown,ADM2,DISTRICT
SM283095,-90.5301,19.8184,Campeche,ADM2,DISTRICT
SM823,-90.25,18.8333,Campeche,ADM1,DISTRICT
SM271108,-77.242,20.1184,Campechuela,ADM2,DISTRICT
SM289781,23.0455,46.3849,Campeni,ADM2,DISTRICT
SM263248,-46.2257,-21.7344,Campestre,ADM2,DISTRICT
SM265344,-35.5324,-8.8939,Campestre,ADM2,DISTRICT
SM266033,-51.1192,-28.7065,Campestre da Serra,ADM2,DISTRICT
SM268232,-49.7096,-16.7834,Campestre de Goiás,ADM2,DISTRICT
SM262962,-47.2343,-6.1516,Campestre do Maranhão,ADM2,DISTRICT
SM290075,23.8847,46.5442,Campia Turzii,ADM2,DISTRICT
SM228123,9.0394,39.0566,Campidanese Sardinian,LANGUAGE,LANGUAGE
SM291507,25.735,45.1262,Campina,ADM2,DISTRICT
SM264390,-35.9763,-7.2782,Campina Grande,ADM2,DISTRICT
SM264798,-48.8721,-25.1843,Campina Grande do Sul,ADM2,DISTRICT
SM263249,-49.7397,-19.4611,Campina Verde,ADM2,DISTRICT
SM264796,-52.7855,-24.6015,Campina da Lagoa,ADM2,DISTRICT
SM266034,-54.8302,-27.9873,Campina das Missões,ADM2,DISTRICT
SM266441,-48.4539,-23.6136,Campina do Monte Alegre,ADM2,DISTRICT
SM264797,-51.7842,-25.0848,Campina do Simão,ADM2,DISTRICT
SM266442,-47.0444,-22.897,Campinas,ADM2,DISTRICT
SM9897,-52.6394,-27.7063,Campinas Do Sul (2000-),ADM2:ADM3,DISTRICT
SM265183,-41.8794,-7.6791,Campinas do Piauí,ADM2,DISTRICT
SM268233,-48.5675,-13.8637,Campinaçu,ADM2,DISTRICT
SM292395,27.1319,45.7084,Campineanca,ADM2,DISTRICT
SM268234,-48.9752,-14.045,Campinorte,ADM2,DISTRICT
SM263138,-53.1559,-14.3071,Campinápolis,ADM2,DISTRICT
SM264804,-49.5961,-25.2841,Campo,ADM2,DISTRICT
SM266381,-49.1875,-26.0853,Campo Alegre,ADM2,DISTRICT
SM265345,-36.2857,-9.7934,Campo Alegre,ADM2,DISTRICT
SM15526,-66.7821,18.4074,Campo Alegre Barrio,ADM2,DISTRICT
SM268235,-47.778,-17.6734,Campo Alegre de Goiás,ADM2,DISTRICT
SM267750,-42.9828,-9.501,Campo Alegre de Lourdes,ADM2,DISTRICT
SM265184,-41.7918,-8.3145,Campo Alegre do Fidalgo,ADM2,DISTRICT
SM263250,-44.8068,-16.5216,Campo Azul,ADM2,DISTRICT
SM263251,-45.234,-20.9245,Campo Belo,ADM2,DISTRICT
SM266382,-50.7658,-27.8394,Campo Belo do Sul,ADM2,DISTRICT
SM266036,-51.0505,-29.6672,Campo Bom,ADM2,DISTRICT
SM264799,-53.0101,-24.8881,Campo Bonito,ADM2,DISTRICT
SM303631,-71.2833,8.45,Campo Elías,ADM2,DISTRICT
SM266383,-53.1826,-26.4583,Campo Erê,ADM2,DISTRICT
SM263253,-48.6566,-19.6926,Campo Florido,ADM2,DISTRICT
SM267751,-40.8108,-10.3101,Campo Formoso,ADM2,DISTRICT
SM263033,-54.668,-20.388,Campo Grande,ADM2,DISTRICT
SM265346,-36.7559,-9.95,Campo Grande,ADM2,DISTRICT
SM265185,-41.0451,-7.2207,Campo Grande do Piauí,ADM2,DISTRICT
SM265186,-42.6032,-3.8552,Campo Largo do Piauí,ADM2,DISTRICT
SM9898,-49.0904,-16.3005,Campo Limpo De Goias,ADM2:ADM3,DISTRICT
SM457973,-46.7584,-23.216,Campo Limpo Paulista,ADM2,DISTRICT
SM266445,-45.5262,-22.6954,Campo Limpo Paulista Part,ADM2,DISTRICT
SM264802,-49.4758,-25.2682,Campo Magro,ADM2,DISTRICT
SM289294,-7.0648,39.0294,Campo Maior,ADM2,DISTRICT
SM265187,-42.1087,-4.7867,Campo Maior,ADM2,DISTRICT
SM264803,-52.3919,-24.1191,Campo Mourão,ADM2,DISTRICT
SM266037,-53.8175,-27.6731,Campo Novo,ADM2,DISTRICT
SM266123,-63.8519,-10.4815,Campo Novo de Rondônia,ADM2,DISTRICT
SM263139,-58.0213,-13.6266,Campo Novo do Parecis,ADM2,DISTRICT
SM266038,-52.804,-28.4742,Campo Real,ADM2,DISTRICT
SM265523,-36.2197,-6.2458,Campo Redondo,ADM2,DISTRICT
SM263140,-54.9295,-15.3793,Campo Verde,ADM2,DISTRICT
SM270674,-74.9026,10.3784,Campo de la Cruz,ADM2,DISTRICT
SM267150,-37.5053,-10.7722,Campo do Brito,ADM2,DISTRICT
SM263252,-45.7957,-21.126,Campo do Meio,ADM2,DISTRICT
SM264801,-49.6594,-25.9579,Campo do Tenente,ADM2,DISTRICT
SM270114,-75.3333,2.6667,Campoalegre,ADM2,DISTRICT
SM277880,14.7585,41.7007,Campobasso,ADM2,DISTRICT
SM270745,-73.1671,4.9773,Campohermoso,ADM2,DISTRICT
SM265252,-41.4553,-21.6774,Campos,ADM2,DISTRICT
SM263254,-46.1982,-19.6369,Campos Altos,ADM2,DISTRICT
SM268236,-46.4878,-12.9964,Campos Belos,ADM2,DISTRICT
SM266039,-53.0254,-28.8939,Campos Borges,ADM2,DISTRICT
SM263255,-45.7139,-21.2471,Campos Gerais,ADM2,DISTRICT
SM267212,-46.8451,-8.2255,Campos Lindos,ADM2,DISTRICT
SM266384,-51.2416,-27.3772,Campos Novos,ADM2,DISTRICT
SM266446,-50.0128,-22.6289,Campos Novos Paulista,ADM2,DISTRICT
SM267891,-40.2219,-6.9253,Campos Sales,ADM2,DISTRICT
SM268237,-49.6584,-14.1902,Campos Verdes,ADM2,DISTRICT
SM263256,-45.1834,-21.0206,Campos Verdes de Goiás,ADM2,DISTRICT
SM263141,-59.1785,-13.633,Campos de Júlio,ADM2,DISTRICT
SM291225,25.0442,45.2669,Campulung,ADM2,DISTRICT
SM290995,23.7726,47.9865,Campulung La Tisa,ADM2,DISTRICT
SM291778,25.5598,47.5298,Campulung Moldovenesc,ADM2,DISTRICT
SM292333,26.7543,46.0362,Campuri,ADM2,DISTRICT
SM10130,-112.8317,53.0082,Camrose (2012-),ADM2,DISTRICT
SM323792,-112.7386,52.9015,Camrose County No. 22,ADM2:ADM3,DISTRICT
SM228125,-76.8931,1.1454,Camsá,LANGUAGE,LANGUAGE
SM228126,27.942,-26.247,Camtho,LANGUAGE,LANGUAGE
SM264959,-35.3017,-7.4352,Camutanga,ADM2,DISTRICT
SM824,-66.8593,18.4258,Camuy,ADM1,DISTRICT
SM15527,-66.8879,18.4487,Camuy Arriba Barrio,ADM2,DISTRICT
SM15528,-66.8466,18.4849,Camuy Barrio-Pueblo,ADM2,DISTRICT
SM14000,-9.75,6.5,Camwood Chiefdom,ADM2,DISTRICT
SM304173,105.7635,18.4441,Can Lộc,ADM2,DISTRICT
SM252586,-71.99,-15.09,Cana,ETHNICITY,ETHNICITY
SM263142,-51.8638,-11.1811,CanaBrava do Norte,ADM2,DISTRICT
SM15530,-66.3369,18.2219,Canabon Barrio (Barranquitas),ADM2,DISTRICT
SM15529,-66.0669,18.2381,Canabon Barrio (Caguas),ADM2,DISTRICT
SM15531,-66.0822,18.2115,Canaboncito Barrio,ADM2,DISTRICT
SM261513,151.1159,-33.8659,Canada Bay,ADM2,DISTRICT
SM302248,-97.9824,35.5424,Canadian,ADM2,DISTRICT
SM284574,165.9629,-21.517,Canala,ADM2,DISTRICT
SM269950,-76.2588,8.7487,Canalete,ADM2,DISTRICT
SM282559,-110.2535,30.9765,Cananea,ADM2,DISTRICT
SM9899,-48.0009,-24.981,Cananeia (excl. Ilha Comprida),ADM2,DISTRICT
SM265347,-37.5199,-9.1733,Canapi,ADM2,DISTRICT
SM252589,-78.89,-3.67,Canar,ETHNICITY,ETHNICITY
SM263143,-52.371,-13.1775,Canarana,ADM2,DISTRICT
SM267753,-41.7376,-11.6678,Canarana,ADM2,DISTRICT
SM228129,-78.9791,-2.4752,Canari,LANGUAGE,LANGUAGE
SM827,-61.0633,13.9047,Canaries,ADM1,DISTRICT
SM286903,-71.3369,-14.4108,Canas,ADM2,DISTRICT
SM266448,-45.0321,-22.74,Canas,ADM2,DISTRICT
SM15532,-66.6679,17.9896,Canas Barrio,ADM2,DISTRICT
SM15533,-66.6316,18.0064,Canas Urbano Barrio,ADM2,DISTRICT
SM14236,-104.8028,24.5176,Canatlan (1989-),ADM2,DISTRICT
SM265188,-43.6863,-7.5076,Canavieira,ADM2,DISTRICT
SM267754,-39.1709,-15.6483,Canavieiras,ADM2,DISTRICT
SM263257,-42.6379,-20.6613,Canaã,ADM2,DISTRICT
SM264149,-50.0933,-6.4987,Canaã dos Carajás,ADM2,DISTRICT
SM458178,148.9985,-35.475,Canberra language,ETHNICITY,ETHNICITY
SM252590,-71.73,-14.52,Canchi,ETHNICITY,ETHNICITY
SM286904,-71.1856,-14.0819,Canchis,ADM2,DISTRICT
SM286845,-70.3021,-17.114,Candarave,ADM2,DISTRICT
SM267755,-39.2039,-11.895,Candeal,ADM2,DISTRICT
SM263260,-45.2872,-20.7389,Candeias,ADM2,DISTRICT
SM267756,-38.487,-12.6557,Candeias,ADM2,DISTRICT
SM266074,-63.3573,-8.9451,Candeias do Jamari,ADM2,DISTRICT
SM283310,-100.7043,26.8532,Candela,ADM2,DISTRICT
SM303774,-70.3571,9.6182,Candelaria,ADM2,DISTRICT
SM14237,-90.75,18,Candelaria,ADM2,DISTRICT
SM275709,-88.5591,14.0607,Candelaria,ADM2,DISTRICT
SM295640,-88.9693,13.6817,Candelaria,ADM2,DISTRICT
SM271030,-82.9608,22.7531,Candelaria,ADM2,DISTRICT
SM270606,-76.4247,3.3837,Candelaria,ADM2,DISTRICT
SM270675,-74.8858,10.4739,Candelaria,ADM2,DISTRICT
SM260908,-55.5,-27.5,Candelaria,ADM2,DISTRICT
SM15536,-67.0795,18.0516,Candelaria Barrio (Lajas),ADM2,DISTRICT
SM15534,-66.2171,18.4082,Candelaria Barrio (Toa Baja),ADM2,DISTRICT
SM15535,-66.3579,18.3749,Candelaria Barrio (Vega Alta),ADM2,DISTRICT
SM14238,-96.5175,15.928,Candelaria Loxicha,ADM2,DISTRICT
SM295543,-89.6292,14.1145,Candelaria de la Frontera,ADM2,DISTRICT
SM15537,-65.8079,18.0904,Candelero Abajo Barrio,ADM2,DISTRICT
SM15538,-65.834,18.1056,Candelero Arriba Barrio,ADM2,DISTRICT
SM266041,-52.8097,-29.7162,Candelária,ADM2,DISTRICT
SM291320,26.5833,46.7167,Candesti,ADM2,DISTRICT
SM290248,25.2046,45.0666,Candesti-vale,ADM2,DISTRICT
SM267757,-42.8739,-14.4697,Candiba,ADM2,DISTRICT
SM266043,-53.7027,-31.4909,Candiota,ADM2,DISTRICT
SM300453,-82.0737,32.4034,Candler,ADM2,DISTRICT
SM228137,-76.9347,-4.2383,Candoshi-Shapra,LANGUAGE,LANGUAGE
SM252591,-75.81,-4.74,Candoshi-Shapra,ETHNICITY,ETHNICITY
SM264806,-52.0224,-25.5132,Candói,ADM2,DISTRICT
SM275691,-87.6748,14.2822,Cane,ADM2,DISTRICT
SM12201,-76.6862,17.981,Cane River,ADM2,DISTRICT
SM266044,-50.7754,-29.3472,Canela,ADM2,DISTRICT
SM252592,-44.94,-6.03,Canela,ETHNICITY,ETHNICITY
SM252592,-44.94,-6.03,Canela,ETHNICITY,ETHNICITY
SM252592,-44.94,-6.03,Canela,ETHNICITY,ETHNICITY
SM252592,-44.94,-6.03,Canela,ETHNICITY,ETHNICITY
SM252592,-45.417,-5.583,Canela,ETHNICITY,ETHNICITY
SM252592,-45.417,-5.583,Canela,ETHNICITY,ETHNICITY
SM252592,-45.417,-5.583,Canela,ETHNICITY,ETHNICITY
SM252592,-45.417,-5.583,Canela,ETHNICITY,ETHNICITY
SM252592,-45,-7,Canela,ETHNICITY,ETHNICITY
SM252592,-45,-7,Canela,ETHNICITY,ETHNICITY
SM252592,-45,-7,Canela,ETHNICITY,ETHNICITY
SM252592,-45,-7,Canela,ETHNICITY,ETHNICITY
SM252592,-46,-6.5,Canela,ETHNICITY,ETHNICITY
SM252592,-46,-6.5,Canela,ETHNICITY,ETHNICITY
SM252592,-46,-6.5,Canela,ETHNICITY,ETHNICITY
SM252592,-46,-6.5,Canela,ETHNICITY,ETHNICITY
SM228139,-45.1299,-6.1071,Canela-Krahô,LANGUAGE,LANGUAGE
SM281507,-106.505,25.0855,Canelas,ADM2,DISTRICT
SM266385,-48.7878,-27.2487,Canelinha,ADM2,DISTRICT
SM17255,-60.9179,13.7852,Canelles,ADM2,DISTRICT
SM828,-55.9167,-34.5333,Canelones,ADM1,DISTRICT
SM289860,26.6089,45.3971,Canesti,ADM2,DISTRICT
SM286871,-74.389,-13.573,Cangallo,ADM2,DISTRICT
SM260352,16.6636,-9.9595,Cangandala,ADM2,DISTRICT
SM275093,-15.983,12.03,Canghungo,ADM2,DISTRICT
SM15539,-66.01,18.4395,Cangrejo Arriba Barrio,ADM2,DISTRICT
SM265524,-35.1375,-6.3908,Canguaretama,ADM2,DISTRICT
SM266045,-52.6682,-31.2118,Canguçu,ADM2,DISTRICT
SM269139,116.86,38.31,Cangzhou,ADM2,DISTRICT
SM267077,-37.0003,-10.1485,Canhoba,ADM2,DISTRICT
SM264961,-36.1791,-8.9357,Canhotinho,ADM2,DISTRICT
SM15540,-66.6702,18.3115,Caniaco Barrio,ADM2,DISTRICT
SM228142,-64.9445,-14.3058,Canichana,LANGUAGE,LANGUAGE
SM18030,36.3369,41.2386,Canik,ADM2,DISTRICT
SM829,1.6583,42.5833,Canillo,ADM1,DISTRICT
SM275234,-90.8597,15.1955,Canillá,ADM2,DISTRICT
SM830,-55.25,-24.25,Canindeyú,ADM1,DISTRICT
SM267892,-39.4175,-4.4047,Canindé,ADM2,DISTRICT
SM267078,-37.9457,-9.7108,Canindé de São Francisco,ADM2,DISTRICT
SM266451,-49.7871,-23.0142,Canitar,ADM2,DISTRICT
SM296369,16.0317,46.7289,Cankova,ADM1:ADM2,DISTRICT
SM262054,30.5528,-3.2194,Cankuzo,ADM2,DISTRICT
SM832,30.5833,-3.1333,Cankuzo,ADM1,DISTRICT
SM324118,-115.3255,51.0636,Canmore,ADM2:ADM3,DISTRICT
SM261347,115.9194,-32.0492,Canning,ADM2,DISTRICT
SM302621,-86.0617,35.8087,Cannon,ADM2,DISTRICT
SM15541,-66.9019,17.9963,Cano Barrio,ADM2,DISTRICT
SM266046,-51.1767,-29.9212,Canoas,ADM2,DISTRICT
SM266386,-50.5334,-26.2483,Canoinhas,ADM2,DISTRICT
SM15543,-65.9067,18.3605,Canovanas Barrio (Canóvanas),ADM2,DISTRICT
SM15542,-65.8641,18.3906,Canovanas Barrio (Loizas),ADM2,DISTRICT
SM15544,-65.9021,18.3793,Canovanas Barrio-Pueblo,ADM2,DISTRICT
SM15545,-65.9232,18.3547,Canovanillas Barrio,ADM2,DISTRICT
SM283027,-89.0888,21.1658,Cansahcab,ADM2,DISTRICT
SM267760,-39.4742,-10.7311,Cansanção,ADM2,DISTRICT
SM286772,-76.6167,-11.4667,Canta,ADM2,DISTRICT
SM274061,-4,43.1667,Cantabria,ADM2,DISTRICT
SM834,-4.0333,43.2,Cantabria,ADM1,DISTRICT
SM461534,-4,43.3,Cantabrian,ETHNICITY,ETHNICITY
SM270713,-74.1297,7.2292,Cantagallo,ADM2,DISTRICT
SM296046,6.7,0.2167,Cantagalo,ADM1:ADM2,DISTRICT
SM265253,-42.3222,-21.8843,Cantagalo,ADM2,DISTRICT
SM264807,-52.1527,-25.2953,Cantagalo,ADM2,DISTRICT
SM263261,-42.6498,-18.5103,Cantagalo,ADM2,DISTRICT
SM274427,2.6495,45.1399,Cantal,ADM2,DISTRICT
SM282933,-89.0718,20.4319,Cantamayec,ADM2,DISTRICT
SM289479,-8.6055,40.3635,Cantanhede,ADM2,DISTRICT
SM262964,-44.252,-3.6666,Cantanhede,ADM2,DISTRICT
SM275228,-91.4378,14.8166,Cantel,ADM2,DISTRICT
SM835,28.3333,46.25,Cantemir,ADM1,DISTRICT
SM12202,-77.9166,18.4792,Canterbury,ADM2,DISTRICT
SM836,171.5,-43.5,Canterbury,ADM1,DISTRICT
SM9670,151.0656,-33.9239,Canterbury-Bankstown,ADM2,DISTRICT
SM265189,-43.0804,-8.2597,Canto do Buriti,ADM2,DISTRICT
SM262553,16.8564,43.9786,Canton 10,ADM1:ADM2,DISTRICT
SM12077,-78.0833,-1.4349,Canton Mera,ADM2,DISTRICT
SM11129,-78.9935,-0.2098,Canton Santo Domingo de los Colorados,ADM2,DISTRICT
SM17256,-60.9766,13.7567,Cantonement,ADM2,DISTRICT
SM252593,113,23,Cantonese,ETHNICITY,ETHNICITY
SM228145,113.2021,23.0118,Cantonese,DIALECT,LANGUAGE
SM266134,-60.4366,2.2208,Cantá,ADM2,DISTRICT
SM265738,-52.2248,-29.3206,Canudos,ADM2,DISTRICT
SM267761,-39.1472,-9.8798,Canudos,ADM2,DISTRICT
SM267311,-64.1294,-7.5897,Canutama,ADM2,DISTRICT
SM300549,-116.7093,43.6251,Canyon,ADM2,DISTRICT
SM263259,-49.2758,-18.7778,Canápolis,ADM2,DISTRICT
SM267752,-44.251,-13.1252,Canápolis,ADM2,DISTRICT
SM833,-65.8871,18.3383,Canóvanas,ADM1,DISTRICT
SM303968,106.255,22.6634,Cao Bằng,ADM2,DISTRICT
SM837,106.0833,22.75,Cao Bằng,ADM1,DISTRICT
SM228148,105.521,23.2541,Cao Lan,LANGUAGE,LANGUAGE
SM304013,105.7097,10.4679,Cao Lãnh,ADM2,DISTRICT
SM304009,105.6351,10.4541,Cao Lãnh (Thành phố),ADM2,DISTRICT
SM304249,106.8544,21.8986,Cao Lộc,ADM2,DISTRICT
SM228149,109.547,26.2236,Cao Miao,LANGUAGE,LANGUAGE
SM304191,105.3356,20.6953,Cao Phong,ADM2,DISTRICT
SM260353,16.6591,-8.6327,Caombo,ADM2,DISTRICT
SM15546,-66.6468,18.2738,Caonillas Abajo Barrio (Utuado),ADM2,DISTRICT
SM15547,-66.4495,18.1002,Caonillas Abajo Barrio (Villalba),ADM2,DISTRICT
SM15548,-66.6385,18.2484,Caonillas Arriba Barrio (Utuado),ADM2,DISTRICT
SM15549,-66.442,18.1397,Caonillas Arriba Barrio (Villalba),ADM2,DISTRICT
SM15550,-66.264,18.16,Caonillas Barrio,ADM2,DISTRICT
SM17257,-60.9359,14.0941,Cap Estate,ADM2,DISTRICT
SM17258,-60.9454,14.1022,Cap Estate/Anse Du Cap,ADM2,DISTRICT
SM17259,-60.9482,14.0946,Cap Estate/Becune Park,ADM2,DISTRICT
SM17260,-60.9516,14.0927,Cap Estate/Becune Point,ADM2,DISTRICT
SM17261,-60.9245,14.1002,Cap Estate/Caribbean Park,ADM2,DISTRICT
SM17262,-60.9371,14.1,Cap Estate/Golf Park,ADM2,DISTRICT
SM17263,-60.9431,14.1052,Cap Estate/Lower Saline Point,ADM2,DISTRICT
SM17264,-60.9376,14.1055,Cap Estate/Mon Du Cap,ADM2,DISTRICT
SM17265,-60.9336,14.1044,Cap Estate/Ranch Site,ADM2,DISTRICT
SM17266,-60.9425,14.1002,Cap Estate/Saddlec Back,ADM2,DISTRICT
SM17267,-60.9449,14.1074,Cap Estate/Upper Saline Point,ADM2,DISTRICT
SM15551,-67.0493,18.3685,Capa Barrio,ADM2,DISTRICT
SM15552,-66.7411,18.201,Capaez Barrio (Adjuntas),ADM2,DISTRICT
SM15553,-66.812,18.4612,Capaez Barrio (Hatillo),ADM2,DISTRICT
SM290734,25.5151,46.3605,Capalnita,ADM2,DISTRICT
SM228150,-74.3813,-6.6325,Capanahua,LANGUAGE,LANGUAGE
SM264808,-53.7926,-25.6184,Capanema,ADM2,DISTRICT
SM264150,-47.0815,-1.0997,Capanema,ADM2,DISTRICT
SM9901,-51.423,-28.1743,Capao Bonito Do Sul,ADM2:ADM3,DISTRICT
SM9902,-54.6037,-28.9499,Capao Do Cipo,ADM2,DISTRICT
SM457980,-50.0142,-29.6612,Capao de Canoa,ADM2,DISTRICT
SM263262,-41.9022,-20.5261,Caparaó,ADM2,DISTRICT
SM269977,-74.5228,5.3785,Caparrapí,ADM2,DISTRICT
SM291227,24.6294,45.3314,Capatanenii Pamanteni,ADM2,DISTRICT
SM261006,-66,-28.8333,Capayán,ADM2,DISTRICT
SM268833,-60.3152,46.0001,Cape Breton,ADM2,DISTRICT
SM274899,-1.2466,5.1053,Cape Coast,ADM2,DISTRICT
SM454490,18.5,-34,Cape Coloured,ETHNICITY,ETHNICITY
SM252594,-78.76,34.12,Cape Fear Indians,ETHNICITY,ETHNICITY
SM301530,-89.6845,37.384,Cape Girardeau,ADM2,DISTRICT
SM301829,-74.85,39.0851,Cape May,ADM2,DISTRICT
SM459130,148.0593,-41.0825,Cape Portland Tasmanian,ETHNICITY,ETHNICITY
SM305142,19.69,-33.3225,Cape Winelands,ADM2,DISTRICT
SM261349,115.6181,-33.527,Capel,ADM2,DISTRICT
SM267079,-37.0567,-10.4283,Capela,ADM2,DISTRICT
SM265348,-36.1239,-9.3934,Capela,ADM2,DISTRICT
SM263263,-43.6159,-20.9184,Capela Nova,ADM2,DISTRICT
SM319538,-51.3808,-29.7124,Capela de Santana,ADM2:ADM3,DISTRICT
SM266453,-47.7508,-23.4687,Capela do Alto,ADM2,DISTRICT
SM267762,-39.8323,-11.6184,Capela do Alto Alegre,ADM2,DISTRICT
SM263264,-42.5021,-17.6917,Capelinha,ADM2,DISTRICT
SM285639,4.5782,51.9357,Capelle aan den IJssel,ADM2,DISTRICT
SM281373,5.9703,49.6368,Capellen,ADM1:ADM2,DISTRICT
SM260332,18.7633,-9.5646,Capenda,ADM2,DISTRICT
SM263265,-47.0183,-20.653,Capetinga,ADM2,DISTRICT
SM289726,-57.4519,-25.3772,Capiatá,ADM2,DISTRICT
SM5176,-55.9916,-24.7459,Capiibary (2002-),ADM2,DISTRICT
SM264391,-35.183,-6.8915,Capim,ADM2,DISTRICT
SM263266,-44.1666,-19.5783,Capim Branco,ADM2,DISTRICT
SM267763,-39.9557,-11.2942,Capim Grosso,ADM2,DISTRICT
SM262704,-66.2295,-17.7443,Capinota,ADM2,DISTRICT
SM266389,-51.6571,-27.4297,Capinzal,ADM2,DISTRICT
SM262965,-44.2517,-4.7477,Capinzal do Norte,ADM2,DISTRICT
SM263267,-49.5794,-18.6922,Capinópolis,ADM2,DISTRICT
SM286653,-79.8828,8.7623,Capira,ADM2,DISTRICT
SM267893,-38.9207,-4.4623,Capistrano,ADM2,DISTRICT
SM268764,-123.5859,48.4163,Capital,ADM2,DISTRICT
SM260880,-68.8583,-32.8847,Capital,ADM2,DISTRICT
SM260824,-65.3333,-24.1667,Capital,ADM2,DISTRICT
SM261060,-64.5,-27.8333,Capital,ADM2,DISTRICT
SM260872,-66.3333,-29.3333,Capital,ADM2,DISTRICT
SM260961,-65.3333,-24.8333,Capital,ADM2,DISTRICT
SM260979,-68.5333,-31.5333,Capital,ADM2,DISTRICT
SM261166,-58.75,-27.5,Capital,ADM2,DISTRICT
SM260909,-55.8333,-27.5,Capital,ADM2,DISTRICT
SM260855,-64.25,-36.5,Capital,ADM2,DISTRICT
SM261074,-65.2113,-26.8283,Capital,ADM2,DISTRICT
SM261007,-65.8,-28.4167,Capital,ADM2,DISTRICT
SM261133,-64.2,-31.4167,Capital,ADM2,DISTRICT
SM461320,50.5833,26.2167,Capital (2014-),ADM1,DISTRICT
SM17268,-60.991,13.9931,Capital Hill,ADM2,DISTRICT
SM10207,-71.1234,47.376,Capitale-Nationale,ADM2,DISTRICT
SM461278,-55.2909,-26.8288,Capitan Meza (1996-),ADM2,DISTRICT
SM270444,-72.6743,6.5208,Capitanejo,ADM2,DISTRICT
SM15555,-66.5054,17.9917,Capitanejo Barrio (Juana Díaz),ADM2,DISTRICT
SM15554,-66.5481,17.9886,Capitanejo Barrio (Ponce),ADM2,DISTRICT
SM289673,-55.8129,-23.4242,Capitán Bado,ADM2,DISTRICT
SM289555,-56.1542,-25.6214,Capitán Mauricio José Troche,ADM2,DISTRICT
SM289587,-55.8117,-27.1897,Capitán Miranda,ADM2,DISTRICT
SM269066,-73.5416,-47.9755,Capitán Prat,ADM2,DISTRICT
SM260738,-59.8333,-34.1667,Capitán Sarmiento,ADM2,DISTRICT
SM319539,-51.9832,-29.2802,Capitão,ADM2:ADM3,DISTRICT
SM314306,-41.8261,-19.0429,Capitão Andrade,ADM2:ADM3,DISTRICT
SM457955,-43.6747,-16.0563,Capitão Enéas,ADM2,DISTRICT
SM265192,-41.9013,-8.5294,Capitão Gervásio Oliveira,ADM2,DISTRICT
SM264809,-53.588,-25.48,Capitão Leônidas Marques,ADM2,DISTRICT
SM264151,-47.2031,-1.9799,Capitão Poço,ADM2,DISTRICT
SM265190,-41.8851,-4.472,Capitão de Campos,ADM2,DISTRICT
SM263271,-46.1572,-20.5924,Capitólio,ADM2,DISTRICT
SM266454,-47.4852,-22.9855,Capivari,ADM2,DISTRICT
SM266390,-48.9536,-28.4524,Capivari de Baixo,ADM2,DISTRICT
SM457982,-50.487,-30.1729,Capivari do Sul,ADM2,DISTRICT
SM262798,-67.8483,-10.4878,Capixaba,ADM2,DISTRICT
SM839,122.5667,11.4,Capiz,ADM1:ADM2,DISTRICT
SM228153,122.954,11.4122,Capiznon,LANGUAGE,LANGUAGE
SM264962,-36.565,-8.6829,Capoeiras,ADM2,DISTRICT
SM228155,-42.0333,-16.7667,Capoxo,LANGUAGE,LANGUAGE
SM228156,35.45,38.73,Cappadocian Greek,LANGUAGE,LANGUAGE
SM290620,23.6203,44.7444,Capreni,ADM2,DISTRICT
SM305125,29.1835,-23.4816,Capricorn,ADM2,DISTRICT
SM14239,-96.4466,17.3097,Capulalpam de Mendez,ADM2,DISTRICT
SM281896,-99.4624,19.2135,Capulhuac,ADM2,DISTRICT
SM290076,23.2346,46.7875,Capusu Mare,ADM2,DISTRICT
SM263272,-42.2528,-20.1851,Caputira,ADM2,DISTRICT
SM266387,-50.6292,-28.0683,Capão Alto,ADM2,DISTRICT
SM266452,-48.2952,-24.0618,Capão Bonito,ADM2,DISTRICT
SM457981,-52.5532,-31.8432,Capão do Leão,ADM2,DISTRICT
SM252596,-71.55,12.06,Caquetio,ETHNICITY,ETHNICITY
SM252596,-71.23,8.43,Caquetio,ETHNICITY,ETHNICITY
SM252596,-71,10.46,Caquetio,ETHNICITY,ETHNICITY
SM252596,-68.64,10.93,Caquetio,ETHNICITY,ETHNICITY
SM252596,-67.18,9.26,Caquetio,ETHNICITY,ETHNICITY
SM840,-74,0.75,Caquetá,ADM1,DISTRICT
SM228157,-73.4451,-11.4812,Caquinte,LANGUAGE,LANGUAGE
SM228175,92.7671,9.1885,Car Nicobarese,LANGUAGE,LANGUAGE
SM228160,8.7457,10.1281,Cara,LANGUAGE,LANGUAGE
SM252597,-77.42,0.3,Cara,ETHNICITY,ETHNICITY
SM286827,-70.25,-13.8333,Carabaya,ADM2,DISTRICT
SM228158,-69.9099,-1.7922,Carabayo,LANGUAGE,LANGUAGE
SM842,-68.0833,10.1667,Carabobo,ADM1,DISTRICT
SM252598,-65.77,9.84,Caraca,ETHNICITY,ETHNICITY
SM252598,-64.96,9.53,Caraca,ETHNICITY,ETHNICITY
SM291393,24.3509,44.1127,Caracal,ADM2,DISTRICT
SM252599,-57.38,-29.62,Caracara,ETHNICITY,ETHNICITY
SM266135,-61.4721,0.9909,Caracaraí,ADM2,DISTRICT
SM303632,-71.273,8.9668,Caracciolo Parra Olmedo,ADM2,DISTRICT
SM303775,-70.3333,9.6667,Carache,ADM2,DISTRICT
SM265193,-43.2708,-9.3494,Caracol,ADM2,DISTRICT
SM263034,-57.1267,-21.9671,Caracol,ADM2,DISTRICT
SM15556,-67.1594,18.3006,Caracol Barrio,ADM2,DISTRICT
SM270192,-74.7419,6.3265,Caracolí,ADM2,DISTRICT
SM4188,125.7417,9,Caraga,ADM1,DISTRICT
SM266456,-45.4899,-23.6338,Caraguatatuba,ADM2,DISTRICT
SM289542,-56.7774,-25.2151,Caraguatay,ADM2,DISTRICT
SM252600,-63.86,0.92,Carahyaby,ETHNICITY,ETHNICITY
SM228161,-75.877,5.624,Caramanta,LANGUAGE,LANGUAGE
SM270193,-75.6696,5.5414,Caramanta,ADM2,DISTRICT
SM264810,-50.1272,-24.9092,Carambeí,ADM2,DISTRICT
SM9829,-67.569,-15.8365,Caranavi,ADM2,DISTRICT
SM263274,-43.7123,-20.8853,Caranaíba,ADM2,DISTRICT
SM290488,22.0576,46.4555,Carand,ADM2,DISTRICT
SM263275,-43.8341,-20.9884,Carandaí,ADM2,DISTRICT
SM252602,-67.76,-17.97,Caranga,ETHNICITY,ETHNICITY
SM262737,-67.9433,-17.8375,Carangas (pre-1980),ADM2,DISTRICT
SM263276,-42.1049,-20.7078,Carangola,ADM2,DISTRICT
SM289997,22.2036,45.4348,Caransebes,ADM2,DISTRICT
SM228162,-69.6465,0.0617,Carapana,LANGUAGE,LANGUAGE
SM289628,-57.3289,-25.7591,Carapeguá,ADM2,DISTRICT
SM266457,-46.8485,-23.5534,Carapicuíba,ADM2,DISTRICT
SM258904,-64.2322,-6.9484,Carari,LANGUAGE,LANGUAGE
SM289998,21.8815,45.2154,Carasova,ADM2,DISTRICT
SM291591,22.6806,47.3071,Carastelec,ADM2,DISTRICT
SM263277,-41.8878,-19.6888,Caratinga,ADM2,DISTRICT
SM267312,-67.3578,-5.1159,Carauari,ADM2,DISTRICT
SM290340,23.253,44.1929,Caraula,ADM2,DISTRICT
SM263278,-45.8269,-21.7749,Caravalhopolis,ADM2,DISTRICT
SM252603,-70.82,-14.77,Caravaya,ETHNICITY,ETHNICITY
SM252603,-70.82,-14.77,Caravaya,ETHNICITY,ETHNICITY
SM252603,-69.683,-15.7662,Caravaya,ETHNICITY,ETHNICITY
SM252603,-69.683,-15.7662,Caravaya,ETHNICITY,ETHNICITY
SM11530,-16.2667,11.5667,Caravela,ADM2,DISTRICT
SM267765,-39.3599,-17.6584,Caravelas,ADM2,DISTRICT
SM286865,-74,-15.5833,Caravelí,ADM2,DISTRICT
SM289691,-56.329,-25.1875,Carayaó,ADM2,DISTRICT
SM9903,-52.8641,-28.2782,Carazinho (1996-),ADM2,DISTRICT
SM843,-86.25,11.75,Carazo,ADM1,DISTRICT
SM266053,-50.3537,-29.774,Caraá,ADM2,DISTRICT
SM263273,-41.5496,-17.1809,Caraí,ADM2,DISTRICT
SM267764,-41.2576,-14.6063,Caraíbas,ADM2,DISTRICT
SM265525,-37.5652,-5.8677,Caraúbas,ADM2,DISTRICT
SM264392,-36.5028,-7.7909,Caraúbas,ADM2,DISTRICT
SM265194,-41.8459,-3.537,Caraúbas do Piauí,ADM2,DISTRICT
SM841,22.0833,45.0833,Caraș-Severin,ADM1,DISTRICT
SM302916,-110.589,39.6482,Carbon,ADM2,DISTRICT
SM303312,-106.9306,41.6946,Carbon,ADM2,DISTRICT
SM302289,-75.7088,40.9182,Carbon,ADM2,DISTRICT
SM301666,-109.0283,45.2274,Carbon,ADM2,DISTRICT
SM323894,-113.152,51.485,Carbon,ADM2:ADM3,DISTRICT
SM263279,-43.0489,-17.4788,Carbonita,ADM2,DISTRICT
SM289999,21.7418,44.8399,Carbunari,ADM2,DISTRICT
SM291508,26.197,45.2305,Carbunesti,ADM2,DISTRICT
SM282560,-111.0115,29.7184,Carbó,ADM2,DISTRICT
SM292080,28.1487,45.1869,Carcaliu,ADM2,DISTRICT
SM270445,-72.5933,6.648,Carcasí,ADM2,DISTRICT
SM844,-78.0833,0.75,Carchi,ADM1,DISTRICT
SM267766,-37.9247,-11.998,Cardeal da Silva,ADM2,DISTRICT
SM269113,-71.945,-34.3865,Cardenal Caro,ADM2,DISTRICT
SM303633,-70.6928,8.8616,Cardenal Quintero,ADM2,DISTRICT
SM274762,-3.1667,51.5,Cardiff,ADM1:ADM2,DISTRICT
SM261220,145.5646,-38.0951,Cardinia,ADM2,DISTRICT
SM281660,-99.0735,20.6278,Cardonal,ADM2,DISTRICT
SM266458,-49.9474,-20.0917,Cardoso,ADM2,DISTRICT
SM265254,-41.4612,-21.5117,Cardoso Moreira,ADM2,DISTRICT
SM323859,-113.3101,49.1924,Cardston,ADM2:ADM3,DISTRICT
SM10131,-113.1661,49.2761,Cardston County,ADM2,DISTRICT
SM263281,-45.6654,-22.0796,Careaçu,ADM2,DISTRICT
SM291653,22.4663,47.682,Carei,ADM2,DISTRICT
SM267314,-60.3479,-3.7871,Careiro,ADM2,DISTRICT
SM267313,-59.6553,-3.3516,Careiro da Várzea,ADM2,DISTRICT
SM17269,-60.9685,14.0189,Carellie,ADM2,DISTRICT
SM15557,-66.8832,17.965,Carenero Barrio,ADM2,DISTRICT
SM270195,-76.6627,7.7601,Carepa,ADM2,DISTRICT
SM265255,-41.6131,-22.2243,Carepebus,ADM2,DISTRICT
SM280932,-10.5906,6.435,Careysburg,ADM2,DISTRICT
SM286805,-77.551,-9.317,Carhuaz,ADM2,DISTRICT
SM267972,-40.4692,-20.3005,Cariacica,ADM2,DISTRICT
SM228164,28.182,37.203,Carian,LANGUAGE,LANGUAGE
SM228169,-65.1086,-0.2571,Cariay,LANGUAGE,LANGUAGE
SM228169,-65.1086,-0.2571,Cariay,LANGUAGE,LANGUAGE
SM228169,-62.8871,-1.0729,Cariay,LANGUAGE,LANGUAGE
SM228169,-62.8871,-1.0729,Cariay,LANGUAGE,LANGUAGE
SM228165,-55.3332,5.7229,Caribbean Hindustani,LANGUAGE,LANGUAGE
SM228166,-54.6305,5.627,Caribbean Javanese,LANGUAGE,LANGUAGE
SM268765,-123.1696,52.3331,Cariboo,ADM2,DISTRICT
SM300550,-111.5622,42.7705,Caribou,ADM2,DISTRICT
SM252605,-93.91,61.87,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-93.91,61.87,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-93.91,61.87,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-93.91,61.87,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-96,64,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-96,64,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-96,64,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-96,64,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-96,63,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-96,63,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-96,63,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-96,63,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-96.2,64.33,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-96.2,64.33,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-96.2,64.33,Caribou Inuit,ETHNICITY,ETHNICITY
SM252605,-96.2,64.33,Caribou Inuit,ETHNICITY,ETHNICITY
SM283214,-107.078,27.746,Carichic,ADM2,DISTRICT
SM275796,-87.6812,13.8156,Caridad,ADM2,DISTRICT
SM267894,-39.1415,-4.201,Caridade,ADM2,DISTRICT
SM265195,-40.896,-7.7014,Caridade do Piauí,ADM2,DISTRICT
SM17270,-60.9672,13.7851,Carierre,ADM2,DISTRICT
SM252606,-56.48,1.4,Cariguano,ETHNICITY,ETHNICITY
SM252607,-72.17,-0.87,Carijo,ETHNICITY,ETHNICITY
SM228168,-72.0664,1.2189,Carijona,LANGUAGE,LANGUAGE
SM252601,-63.01,9.15,Carina,ETHNICITY,ETHNICITY
SM252601,-63.01,9.15,Carina,ETHNICITY,ETHNICITY
SM252601,-60.03,7.81,Carina,ETHNICITY,ETHNICITY
SM252601,-60.03,7.81,Carina,ETHNICITY,ETHNICITY
SM252601,-64,9,Carina,ETHNICITY,ETHNICITY
SM252601,-64,9,Carina,ETHNICITY,ETHNICITY
SM267767,-43.8471,-14.2139,Carinhanha,ADM2,DISTRICT
SM303663,-63.4167,10.1667,Caripe,ADM2,DISTRICT
SM252608,-61.07,0.32,Caripuna,ETHNICITY,ETHNICITY
SM267080,-37.7499,-10.3656,Carira,ADM2,DISTRICT
SM252609,-38.62,-7.34,Cariri,ETHNICITY,ETHNICITY
SM267213,-49.208,-11.945,Cariri do Tocantins,ADM2,DISTRICT
SM267896,-39.2542,-7.0417,Caririaçú,ADM2,DISTRICT
SM303583,-70,11.7167,Carirubana,ADM2,DISTRICT
SM267895,-40.5468,-3.9359,Cariré,ADM2,DISTRICT
SM12203,-77.7737,18.1474,Carisbrook,ADM2,DISTRICT
SM15558,-66.0958,18.0806,Carite Barrio,ADM2,DISTRICT
SM267897,-39.475,-6.6562,Cariús,ADM2,DISTRICT
SM290749,22.8347,45.8468,Carjiti,ADM2,DISTRICT
SM268801,-67.4156,46.3334,Carleton,ADM2,DISTRICT
SM291779,25.0943,47.5855,Carlibaba,ADM2,DISTRICT
SM292334,27.0603,45.6772,Carligele,ADM2,DISTRICT
SM263144,-55.8509,-10.0379,Carlinda,ADM2,DISTRICT
SM301021,-88.9711,36.8532,Carlisle,ADM2,DISTRICT
SM291394,24.1601,44.5199,Carlogani,ADM2,DISTRICT
SM14240,-95.718,18.3324,Carlos A. Carrillo,ADM2,DISTRICT
SM289588,-54.9257,-26.3574,Carlos Antonio López,ADM2,DISTRICT
SM303871,-67.7339,9.9953,Carlos Arevalo,ADM2,DISTRICT
SM266055,-51.5495,-29.3467,Carlos Barbosa,ADM2,DISTRICT
SM260739,-61.25,-35.75,Carlos Casares,ADM2,DISTRICT
SM263282,-40.8852,-17.6782,Carlos Chagas,ADM2,DISTRICT
SM286806,-77.2417,-9.0442,Carlos Fermin Fitzcarrald,ADM2,DISTRICT
SM266056,-51.9065,-27.7118,Carlos Gomes,ADM2,DISTRICT
SM273526,-77.949,-1.1397,Carlos Julio Arosemena Tola,ADM2,DISTRICT
SM270984,-78.2795,21.575,Carlos Manuel de Céspedes,ADM2,DISTRICT
SM260740,-62.4167,-35.4167,Carlos Tejedor,ADM2,DISTRICT
SM845,-6.8333,52.6667,Carlow,ADM1:ADM2,DISTRICT
SM301425,-92.677,46.5924,Carlton,ADM2,DISTRICT
SM264812,-49.7014,-23.4497,Carlópolis,ADM2,DISTRICT
SM323895,-113.1121,50.1281,Carmangay,ADM2:ADM3,DISTRICT
SM274763,-4.1667,51.8333,Carmarthenshire,ADM1:ADM2,DISTRICT
SM12204,-77.9393,18.1733,Carmel,ADM2,DISTRICT
SM18328,-58.1617,-34.0737,Carmelo,ADM2,DISTRICT
SM15322,-58.2808,-21.65,Carmelo Peralta,ADM2,DISTRICT
SM282200,-100.351,25.8913,Carmen,ADM2,DISTRICT
SM14242,-91.83,18.63,Carmen (1998-),ADM2,DISTRICT
SM15559,-66.1625,18.0362,Carmen Barrio,ADM2,DISTRICT
SM270596,-74.7678,4.1077,Carmen de Apicalá,ADM2,DISTRICT
SM260741,-59.8333,-34.3333,Carmen de Areco,ADM2,DISTRICT
SM269979,-73.9279,5.3326,Carmen de Carupa,ADM2,DISTRICT
SM10389,-76.9522,7.0364,Carmen del Darien,ADM2,DISTRICT
SM289589,-56.0983,-27.2085,Carmen del Paraná,ADM2,DISTRICT
SM265256,-42.5376,-21.8997,Carmo,ADM2,DISTRICT
SM263284,-45.1804,-21.4332,Carmo da Cachoeira,ADM2,DISTRICT
SM263285,-44.8852,-20.5685,Carmo da Mata,ADM2,DISTRICT
SM263286,-45.1495,-22.0886,Carmo de Minas,ADM2,DISTRICT
SM263287,-44.714,-20.1878,Carmo do Cajuru,ADM2,DISTRICT
SM263288,-46.1443,-18.8733,Carmo do Paranaiba,ADM2,DISTRICT
SM263289,-46.1045,-20.9797,Carmo do Rio Claro,ADM2,DISTRICT
SM268238,-49.7237,-15.4165,Carmo do Rio Verde,ADM2,DISTRICT
SM267214,-48.3563,-7.03,Carmolândia,ADM2,DISTRICT
SM263283,-43.184,-19.0653,Carmésia,ADM2,DISTRICT
SM267081,-36.9575,-10.6671,Carmópolis,ADM2,DISTRICT
SM263290,-44.6453,-20.5491,Carmópolis de Minas,ADM2,DISTRICT
SM261350,115.594,-29.8041,Carnamah,ADM2,DISTRICT
SM261351,114.3369,-24.4668,Carnarvon,ADM2,DISTRICT
SM265527,-36.7946,-5.2646,Carnaubais,ADM2,DISTRICT
SM267899,-41.0137,-4.121,Carnaubal,ADM2,DISTRICT
SM264964,-38.7652,-8.4265,Carnaubeira da Penha,ADM2,DISTRICT
SM264963,-37.7039,-7.7685,Carnaíba,ADM2,DISTRICT
SM265526,-36.529,-6.5616,Carnaúba dos Dantas,ADM2,DISTRICT
SM263292,-50.8087,-19.7142,Carneirinho,ADM2,DISTRICT
SM265349,-37.3556,-9.4647,Carneiros,ADM2,DISTRICT
SM4002,24.2458,57.1172,Carnikavas Novads,ADM1,DISTRICT
SM13463,24.2469,57.1176,Carnikavas novads,ADM2,DISTRICT
SM266136,-59.5129,1.2184,Caroebe,ADM2,DISTRICT
SM296085,-54.9983,5.4482,Carolina,ADM2,DISTRICT
SM295744,-88.3116,13.8543,Carolina,ADM2,DISTRICT
SM262967,-47.1491,-7.5079,Carolina,ADM2,DISTRICT
SM846,-65.9579,18.3758,Carolina,ADM1,DISTRICT
SM228177,-77.291,35.979,Carolina Algonquian,LANGUAGE,LANGUAGE
SM15560,-65.9569,18.3818,Carolina Barrio-Pueblo,ADM2,DISTRICT
SM270196,-75.3213,6.7481,Carolina del Principe,ADM2,DISTRICT
SM302983,-77.347,38.0268,Caroline,ADM2,DISTRICT
SM301232,-75.8316,38.8717,Caroline,ADM2,DISTRICT
SM13324,-150.2075,-9.9587,Caroline,ADM2,DISTRICT
SM323987,-114.738,52.0958,Caroline,ADM2:ADM3,DISTRICT
SM252610,143.74,7.54,Caroline Islands,ETHNICITY,ETHNICITY
SM252610,143.74,7.54,Caroline Islands,ETHNICITY,ETHNICITY
SM252610,145.76,15.21,Caroline Islands,ETHNICITY,ETHNICITY
SM252610,145.76,15.21,Caroline Islands,ETHNICITY,ETHNICITY
SM228176,145.765,15.205,Carolinian,LANGUAGE,LANGUAGE
SM303856,-62.8,8.1167,Caroní,ADM2,DISTRICT
SM290341,23.2606,44.3417,Carpen,ADM2,DISTRICT
SM261614,141.3531,-17.3222,Carpentaria,ADM2,DISTRICT
SM264965,-35.3307,-7.8175,Carpina,ADM2,DISTRICT
SM292410,22.4892,46.4416,Carpinet,ADM2,DISTRICT
SM15561,-66.0237,18.3349,Carraizo Barrio,ADM2,DISTRICT
SM263293,-44.6104,-21.4959,Carrancas,ADM2,DISTRICT
SM264393,-38.3295,-7.0292,Carrapateira,ADM2,DISTRICT
SM262705,-64.8929,-17.3888,Carrasco,ADM2,DISTRICT
SM267215,-48.0336,-5.3103,Carrasco Bonito,ADM2,DISTRICT
SM261516,145.4252,-33.6257,Carrathool,ADM2,DISTRICT
SM289452,-7.3113,41.2342,Carrazeda de Ansiães,ADM2,DISTRICT
SM289406,-7.9982,40.4501,Carregal do Sal,ADM2,DISTRICT
SM15563,-66.6827,18.3838,Carreras Barrio (Arecibo),ADM2,DISTRICT
SM15562,-67.1243,18.2868,Carreras Barrio (Añasco),ADM2,DISTRICT
SM847,-61.4538,12.473,Carriacou,ADM1,DISTRICT
SM17271,-60.9455,13.8361,Carriere,ADM2,DISTRICT
SM270926,-85.5886,10.4149,Carrillo,ADM2,DISTRICT
SM282854,-96.5823,18.8131,Carrillo Puerto,ADM2,DISTRICT
SM303652,-66.9936,10.3514,Carrizal,ADM2,DISTRICT
SM15564,-67.1788,18.3989,Carrizal Barrio,ADM2,DISTRICT
SM15565,-66.7889,18.4811,Carrizales Barrio,ADM2,DISTRICT
SM302984,-80.7339,36.7315,Carroll,ADM2,DISTRICT
SM302632,-88.4503,35.9732,Carroll,ADM2,DISTRICT
SM301805,-71.2028,43.8739,Carroll,ADM2,DISTRICT
SM302084,-81.0897,40.5796,Carroll,ADM2,DISTRICT
SM301233,-77.0225,39.5629,Carroll,ADM2,DISTRICT
SM301531,-93.5052,39.427,Carroll,ADM2,DISTRICT
SM301503,-89.9202,33.4485,Carroll,ADM2,DISTRICT
SM300768,-86.5635,40.5829,Carroll,ADM2,DISTRICT
SM300789,-94.8606,42.0362,Carroll,ADM2,DISTRICT
SM301022,-85.1236,38.6679,Carroll,ADM2,DISTRICT
SM300669,-89.9343,42.0686,Carroll,ADM2,DISTRICT
SM300454,-85.0797,33.5828,Carroll,ADM2,DISTRICT
SM302425,-93.5382,36.341,Carroll,ADM2,DISTRICT
SM12205,-76.9525,18.278,Carron Hall,ADM2,DISTRICT
SM15566,-65.9163,18.3134,Carruzos Barrio,ADM2,DISTRICT
SM302820,-101.3542,35.4035,Carson,ADM2,DISTRICT
SM301771,-119.7763,39.1646,Carson City,ADM2,DISTRICT
SM323918,-114.0946,51.5606,Carstairs,ADM2:ADM3,DISTRICT
SM291713,24.5637,45.8003,Carta,ADM2,DISTRICT
SM290735,25.7628,46.5407,Carta,ADM2,DISTRICT
SM270714,-75.4543,10.5074,Cartagena de Indias,ADM2,DISTRICT
SM270872,-74.6059,0.8525,Cartagena del Chairá,ADM2,DISTRICT
SM270914,-83.9139,9.8161,Cartago,ADM2,DISTRICT
SM270607,-75.9167,4.7,Cartago,ADM2,DISTRICT
SM848,-83.75,9.8333,Cartago,ADM1,DISTRICT
SM289335,-8.8137,39.1538,Cartaxo,ADM2,DISTRICT
SM302545,-82.1274,36.2927,Carter,ADM2,DISTRICT
SM302173,-97.2858,34.2509,Carter,ADM2,DISTRICT
SM301674,-104.5362,45.5168,Carter,ADM2,DISTRICT
SM301532,-90.9623,36.9413,Carter,ADM2,DISTRICT
SM301023,-83.0495,38.3182,Carter,ADM2,DISTRICT
SM301938,-76.5325,34.864,Carteret,ADM2,DISTRICT
SM286518,175.5167,-41.0167,Carterton,ADM2,DISTRICT
SM298032,10.3298,36.8596,Carthage,ADM2,DISTRICT
SM327634,-97.6765,49.8831,Cartier,ADM2:ADM3,DISTRICT
SM291714,24.5799,45.727,Cartisoara,ADM2,DISTRICT
SM10174,-99.2902,49.1044,Cartwright-Roblin,ADM2,DISTRICT
SM264966,-36.0279,-8.1792,Caruaru,ADM2,DISTRICT
SM270647,-71.3379,1.0208,Carurú,ADM2,DISTRICT
SM262968,-45.9597,-1.1803,Carutapera,ADM2,DISTRICT
SM303712,-65.674,9.91,Carvajal,ADM2,DISTRICT
SM263294,-44.4754,-22.031,Carvalhos,ADM2,DISTRICT
SM301339,-93.8026,44.8208,Carver,ADM2,DISTRICT
SM282003,-101.0416,18.9851,Carácuaro,ADM2,DISTRICT
SM17272,-60.9321,14.0833,Cas En Bas,ADM2,DISTRICT
SM266459,-47.0826,-21.814,Casa Branca,ADM2,DISTRICT
SM263295,-43.9395,-20.8407,Casa Grande,ADM2,DISTRICT
SM267768,-41.1574,-9.1044,Casa Nova,ADM2,DISTRICT
SM270597,-75.187,5.0173,Casabianca,ADM2,DISTRICT
SM4271,-7.4323,33.478,Casablanca-Settat,ADM1,DISTRICT
SM4434,-7.5579,33.5884,Casablance Prefecture,ADM2,DISTRICT
SM303889,-62.4597,8.4401,Casacoima,ADM2,DISTRICT
SM849,-71.5,5.5,Casanare,ADM1,DISTRICT
SM282678,-98.667,23.5809,Casas,ADM2,DISTRICT
SM283215,-108.0704,30.3398,Casas Grandes,ADM2,DISTRICT
SM266057,-51.9198,-28.5759,Casca,ADM2,DISTRICT
SM301675,-111.3472,47.308,Cascade,ADM2,DISTRICT
SM850,55.4833,-4.6667,Cascade,ADM1,DISTRICT
SM252614,-122.46,45.68,Cascade,ETHNICITY,ETHNICITY
SM12206,-78.0964,18.4066,Cascade (Hanover),ADM2,DISTRICT
SM18544,-76.7122,18.0938,Cascade (Portland),ADM2,DISTRICT
SM18543,-77.4625,18.2321,Cascade (Saint Ann),ADM2,DISTRICT
SM851,-4.5,10.38,Cascades,ADM1,DISTRICT
SM289270,-9.4638,38.7433,Cascais,ADM2,DISTRICT
SM273559,-77.2354,0.1508,Cascales,ADM2,DISTRICT
SM263296,-47.8513,-18.5462,Cascalho Rico,ADM2,DISTRICT
SM264813,-53.3881,-25.0525,Cascavel,ADM2,DISTRICT
SM267900,-38.2738,-4.27,Cascavel,ADM2,DISTRICT
SM289986,26.4696,44.1273,Cascioarele,ADM2,DISTRICT
SM267216,-49.836,-9.4598,Caseara,ADM2,DISTRICT
SM266058,-51.7816,-28.2322,Caseiros,ADM2,DISTRICT
SM290077,23.864,47.2381,Caseiu,ADM2,DISTRICT
SM261028,-61.6667,-33.25,Caseros,ADM2,DISTRICT
SM277944,14.1667,41.2333,Caserta,ADM2,DISTRICT
SM301024,-84.9284,37.3223,Casey,ADM2,DISTRICT
SM261221,145.3228,-38.0952,Casey,ADM2,DISTRICT
SM15567,-67.1031,18.2682,Casey Abajo Barrio,ADM2,DISTRICT
SM15568,-67.0861,18.2609,Casey Arriba Barrio,ADM2,DISTRICT
SM12207,-78.1094,18.3692,Cash Hill,ADM2,DISTRICT
SM228183,-75.4206,-8.7023,Cashibo-Cacataibo,LANGUAGE,LANGUAGE
SM228186,-71.1733,-9.7203,Cashinahua,LANGUAGE,LANGUAGE
SM454416,-73,-8,Cashinawa,ETHNICITY,ETHNICITY
SM228185,-70.25,-7.5,Cashinawa of the Tarauacá river,LANGUAGE,LANGUAGE
SM228187,121.878,16.2176,Casiguran-Nagtipunan Agta,LANGUAGE,LANGUAGE
SM275315,-90.1726,14.3785,Casillas,ADM2,DISTRICT
SM292081,28.3316,44.7628,Casimcea,ADM2,DISTRICT
SM281777,-104.479,19.5957,Casimiro Castillo,ADM2,DISTRICT
SM265258,-42.1443,-22.4825,Casimiro de Abreu,ADM2,DISTRICT
SM292092,26.7292,46.1939,Casin,ADM2,DISTRICT
SM264967,-35.6992,-7.7581,Casinhas,ADM2,DISTRICT
SM458441,152.9874,-28.854,Casino language,ETHNICITY,ETHNICITY
SM286807,-78.2474,-9.4764,Casma,ADM2,DISTRICT
SM252615,-77.95,-9.75,Casma,ETHNICITY,ETHNICITY
SM302821,-94.3436,33.0775,Cass,ADM2,DISTRICT
SM301682,-96.1409,40.9097,Cass,ADM2,DISTRICT
SM302082,-97.248,46.933,Cass,ADM2,DISTRICT
SM301256,-85.9935,41.9154,Cass,ADM2,DISTRICT
SM301533,-94.3548,38.647,Cass,ADM2,DISTRICT
SM301340,-94.3254,46.9496,Cass,ADM2,DISTRICT
SM300779,-86.346,40.7615,Cass,ADM2,DISTRICT
SM300790,-94.9278,41.3315,Cass,ADM2,DISTRICT
SM300680,-90.2474,39.9736,Cass,ADM2,DISTRICT
SM264394,-35.8168,-6.7813,Casserengue,ADM2,DISTRICT
SM300551,-113.6004,42.2839,Cassia,ADM2,DISTRICT
SM12208,-76.8067,18.0205,Cassia Park,ADM2,DISTRICT
SM263035,-52.1108,-19.0611,Cassilândia,ADM2,DISTRICT
SM263298,-44.4886,-21.1423,Cassiterita,ADM2,DISTRICT
SM260442,14.969,-11.8181,Cassongue,ADM2,DISTRICT
SM261615,145.9656,-17.9679,Cassowary Coast,ADM2,DISTRICT
SM264152,-47.8563,-1.2723,Castanhal,ADM2,DISTRICT
SM263145,-58.651,-10.8793,Castanheira,ADM2,DISTRICT
SM289255,-8.1889,40.0323,Castanheira de Pêra,ADM2,DISTRICT
SM266075,-61.8892,-11.4266,Castanheiras,ADM2,DISTRICT
SM283311,-101.38,26.6634,Castaños,ADM2,DISTRICT
SM271797,-71.4833,19.7167,Castañuela,ADM2,DISTRICT
SM853,-2.5936,49.465,Castel,ADM1,DISTRICT
SM261029,-61.6667,-31.1667,Castellanos,ADM2,DISTRICT
SM260742,-57.6667,-36,Castelli,ADM2,DISTRICT
SM274041,-0.1667,40.1667,Castellón,ADM2,DISTRICT
SM228193,16.0465,40.5306,Castelmezzano,LANGUAGE,LANGUAGE
SM267973,-41.2047,-20.5367,Castelo,ADM2,DISTRICT
SM289462,-7.5131,39.8885,Castelo Branco,ADM2,DISTRICT
SM854,-7.5232,39.9566,Castelo Branco,ADM1,DISTRICT
SM289224,-8.2982,41.0335,Castelo de Paiva,ADM2,DISTRICT
SM289295,-7.5147,39.4334,Castelo de Vide,ADM2,DISTRICT
SM265196,-41.5574,-5.2158,Castelo do Piauí,ADM2,DISTRICT
SM268239,-50.34,-18.1558,Castelândia,ADM2,DISTRICT
SM266461,-51.6186,-21.0046,Castilho,ADM2,DISTRICT
SM286866,-72.4167,-15.5833,Castilla,ADM2,DISTRICT
SM270294,-73.5833,3.8,Castilla la Nueva,ADM2,DISTRICT
SM856,-4.25,41.6667,Castilla y León,ADM1,DISTRICT
SM855,-3,39.5,Castilla-La Mancha,ADM1,DISTRICT
SM271898,-70,19.25,Castillo,ADM2,DISTRICT
SM282856,-97.6652,20.7414,Castillo de Teayo,ADM2,DISTRICT
SM12209,-76.3463,18.1422,Castle Comfort,ADM2,DISTRICT
SM324070,-114.3411,53.7091,Castle Island,ADM2:ADM3,DISTRICT
SM12210,-76.7834,18.1762,Castleton,ADM2,DISTRICT
SM276801,-4.6582,54.0746,Castletown,ADM1:ADM2,DISTRICT
SM323951,-111.9078,52.2179,Castor,ADM2:ADM3,DISTRICT
SM290342,24.0124,44.1278,Castranova,ADM2,DISTRICT
SM285975,4.6895,52.5558,Castricum,ADM2,DISTRICT
SM857,-60.97,13.984,Castries,ADM1,DISTRICT
SM302822,-102.2617,34.53,Castro,ADM2,DISTRICT
SM264814,-49.8554,-24.8304,Castro,ADM2,DISTRICT
SM267770,-39.3769,-12.7494,Castro Alves,ADM2,DISTRICT
SM260873,-66.9167,-28.8333,Castro Barros,ADM2,DISTRICT
SM289412,-7.9388,40.9228,Castro Daire,ADM2,DISTRICT
SM289511,-7.5073,37.2587,Castro Marim,ADM2,DISTRICT
SM289429,-8.0242,37.7119,Castro Verde,ADM2,DISTRICT
SM286911,-75.406,-13.1454,Castrovirreyna,ADM2,DISTRICT
SM228194,138.566,-6.43,Casuarina Coast Asmat,LANGUAGE,LANGUAGE
SM300223,-55.7185,-34.1247,Casupa,ADM2,DISTRICT
SM300223,-55.7185,-34.1247,Casupa,ADM2,DISTRICT
SM300223,-55.6366,-34.0993,Casupa,ADM2,DISTRICT
SM300223,-55.6366,-34.0993,Casupa,ADM2,DISTRICT
SM301939,-79.3336,36.3934,Caswell,ADM2,DISTRICT
SM858,-75.4575,24.335,Cat Island,ADM1,DISTRICT
SM292669,25.2698,46.1317,Cata,ADM2,DISTRICT
SM260405,17.2415,-12.1409,Catabola,ADM2,DISTRICT
SM275753,-85.5426,14.6038,Catacamas,ADM2,DISTRICT
SM12211,-77.8658,18.2805,Catadupa,ADM2,DISTRICT
SM228197,126.373,7.7719,Cataelano Mandaya,LANGUAGE,LANGUAGE
SM263299,-42.6498,-21.3455,Cataguases,ADM2,DISTRICT
SM301112,-91.8471,31.6662,Catahoula,ADM2,DISTRICT
SM245520,1.569,41.453,Catalan,LANGUAGE,LANGUAGE
SM228195,-1.97,41.635,Catalan Sign Language,LANGUAGE,LANGUAGE
SM290208,26.1225,45.9374,Catalina,ADM2,DISTRICT
SM252616,-0.28,41.13,Catalonians,ETHNICITY,ETHNICITY
SM252616,-0.28,41.13,Catalonians,ETHNICITY,ETHNICITY
SM252616,1.5,41.8,Catalonians,ETHNICITY,ETHNICITY
SM252616,1.5,41.8,Catalonians,ETHNICITY,ETHNICITY
SM18031,37.4433,40.8868,Catalpinar Ilcesi,ADM2,DISTRICT
SM859,1.8677,41.8205,Cataluña,ADM1,DISTRICT
SM268240,-47.7019,-17.9772,Catalão,ADM2,DISTRICT
SM860,-67,-27,Catamarca,ADM1,DISTRICT
SM273470,-79.35,-3.9833,Catamayo,ADM2,DISTRICT
SM861,124.25,13.8333,Catanduanes,ADM1:ADM2,DISTRICT
SM266462,-48.9619,-21.1289,Catanduva,ADM2,DISTRICT
SM266391,-51.724,-27.0539,Catanduvas,ADM2,DISTRICT
SM264815,-53.1702,-25.2605,Catanduvas,ADM2,DISTRICT
SM277900,14.8473,37.4717,Catania,ADM2,DISTRICT
SM15569,-65.8246,18.1295,Catano Barrio,ADM2,DISTRICT
SM15570,-66.1197,18.4399,Catano Barrio-Pueblo,ADM2,DISTRICT
SM277937,16.4333,38.9167,Catanzaro,ADM2,DISTRICT
SM11119,14.3504,-5.1971,Cataractes District,ADM2,DISTRICT
SM11119,14.3504,-5.1971,Cataractes District,ADM2,DISTRICT
SM11119,14.8667,-5.25,Cataractes District,ADM2,DISTRICT
SM11119,14.8667,-5.25,Cataractes District,ADM2,DISTRICT
SM285442,-86.0718,11.922,Catarina,ADM2,DISTRICT
SM275291,-92.0702,14.8518,Catarina,ADM2,DISTRICT
SM267901,-39.9487,-6.2502,Catarina,ADM2,DISTRICT
SM263301,-43.422,-20.0705,Catas Altas,ADM2,DISTRICT
SM263300,-43.4978,-20.6809,Catas Altas da Noruega,ADM2,DISTRICT
SM303810,-72.2341,9.0609,Catatumbo,ADM2,DISTRICT
SM228199,-80.8857,34.9685,Catawba,LANGUAGE,LANGUAGE
SM301940,-81.2145,35.6626,Catawba,ADM2,DISTRICT
SM252617,-80,35,Catawba,ETHNICITY,ETHNICITY
SM252618,-67.28,-3.4,Catawisi,ETHNICITY,ETHNICITY
SM252618,-64.2,-5.17,Catawisi,ETHNICITY,ETHNICITY
SM11154,-92.0167,17.7333,Catazaja,ADM2,DISTRICT
SM862,-66.1371,18.4355,Cataño,ADM1,DISTRICT
SM290079,23.7933,47.2306,Catcau,ADM2,DISTRICT
SM260460,16.3233,-12.7863,Catchiungo,ADM2,DISTRICT
SM291228,25.0363,44.7706,Cateasca,ADM2,DISTRICT
SM282857,-95.0526,18.4509,Catemaco,ADM2,DISTRICT
SM264968,-35.7497,-8.6619,Catende,ADM2,DISTRICT
SM12212,-77.9229,18.4626,Catherine Hall,ADM2,DISTRICT
SM12213,-77.8969,18.4567,Catherine Mount,ADM2,DISTRICT
SM266463,-49.0498,-21.0654,Catigua,ADM2,DISTRICT
SM17273,-60.9797,13.7769,Catin,ADM2,DISTRICT
SM290080,24.165,46.8518,Catina,ADM2,DISTRICT
SM289861,26.2448,45.2875,Catina,ADM2,DISTRICT
SM264395,-37.5965,-7.137,Catingueira,ADM2,DISTRICT
SM11531,-15.2565,11.2776,Catio,ADM2,DISTRICT
SM267771,-44.6753,-12.2961,Catolândia,ADM2,DISTRICT
SM264396,-37.7284,-6.3123,Catolé do Rocha,ADM2,DISTRICT
SM300455,-85.1383,34.9037,Catoosa,ADM2,DISTRICT
SM282528,-101.0029,23.6428,Catorce,ADM2,DISTRICT
SM260856,-63.75,-36.5,Catriló,ADM2,DISTRICT
SM301845,-108.4047,33.9153,Catron,ADM2,DISTRICT
SM301911,-78.6789,42.2486,Cattaraugus,ADM2,DISTRICT
SM17274,-60.946,13.8425,Catter Beaufond,ADM2,DISTRICT
SM267772,-38.4005,-12.3776,Catu,ADM2,DISTRICT
SM263303,-41.4818,-17.3633,Catuji,ADM2,DISTRICT
SM267902,-40.1863,-4.6047,Catunda,ADM2,DISTRICT
SM290621,22.9247,44.839,Catunele,ADM2,DISTRICT
SM228207,-70.8672,-0.8347,Catuquinaru-Bach,LANGUAGE,LANGUAGE
SM267773,-42.3025,-13.186,Caturama,ADM2,DISTRICT
SM268242,-49.5956,-16.4698,Caturaí,ADM2,DISTRICT
SM264397,-36.0419,-7.4199,Caturité,ADM2,DISTRICT
SM263304,-43.1139,-15.3236,Catuti,ADM2,DISTRICT
SM266059,-54.0587,-28.1981,Catuípe,ADM2,DISTRICT
SM260924,-70.3333,-39.5,Catán Lil,ADM2,DISTRICT
SM291654,22.5555,47.58,Cauas,ADM2,DISTRICT
SM252620,-76.62,-10.67,Cauatambo,ETHNICITY,ETHNICITY
SM228208,-69.91,1.267,Cauca,LANGUAGE,LANGUAGE
SM863,-76.8333,2.5,Cauca,ADM1,DISTRICT
SM267903,-38.6568,-3.7286,Caucaia,ADM2,DISTRICT
SM270197,-75.0286,7.867,Caucasia,ADM2,DISTRICT
SM260980,-67.6667,-31.45,Caucete,ADM2,DISTRICT
SM228210,102.0461,22.0339,Cauho,LANGUAGE,LANGUAGE
SM12214,-78.2411,18.3858,Cauldwell,ADM2,DISTRICT
SM260333,18.8415,-8.3375,Caungula,ADM2,DISTRICT
SM269075,-72.3292,-35.9683,Cauquenes,ADM2,DISTRICT
SM228212,-75.9136,-12.7182,Cauqui of Cachuy,LANGUAGE,LANGUAGE
SM864,29.3135,46.6188,Causeni,ADM1,DISTRICT
SM274707,-6.5908,55.0601,Causeway Coast and Glens,ADM1:ADM2,DISTRICT
SM271109,-76.4706,20.5561,Cauto Cristo,ADM2,DISTRICT
SM269099,-72.362,-38.9472,Cautín,ADM2,DISTRICT
SM296047,6.6383,0.1342,Caué,ADM1:ADM2,DISTRICT
SM290427,28.0164,46.0804,Cavadinesti,ADM2,DISTRICT
SM268243,-47.7606,-13.5953,Cavalcante,ADM2,DISTRICT
SM302031,-98.4649,48.7723,Cavalier,ADM2,DISTRICT
SM12215,-76.8432,18.0968,Cavaliers,ADM2,DISTRICT
SM14002,-7.5833,4.3833,Cavalla,ADM2,DISTRICT
SM269491,-7.9253,6.5634,Cavally,ADM2,DISTRICT
SM865,-7.25,53.9167,Cavan,ADM1:ADM2,DISTRICT
SM290000,22.1637,45.5146,Cavaran,ADM2,DISTRICT
SM12216,-78.0407,18.2102,Cave,ADM2,DISTRICT
SM12217,-77.3634,18.2327,Cave Valley,ADM2,DISTRICT
SM252622,-67.71,-13.28,Cavinena,ETHNICITY,ETHNICITY
SM228214,-66.6277,-13.3544,Cavineña,LANGUAGE,LANGUAGE
SM866,120.8333,14.25,Cavite,ADM1:ADM2,DISTRICT
SM290997,23.8255,47.6499,Cavnic,ADM2,DISTRICT
SM252623,-61.33,-10.77,Cawahib,ETHNICITY,ETHNICITY
SM252623,-61.33,-10.77,Cawahib,ETHNICITY,ETHNICITY
SM252623,-61.54,-11.74,Cawahib,ETHNICITY,ETHNICITY
SM252623,-61.54,-11.74,Cawahib,ETHNICITY,ETHNICITY
SM252623,-60,-9,Cawahib,ETHNICITY,ETHNICITY
SM252623,-60,-9,Cawahib,ETHNICITY,ETHNICITY
SM263305,-44.953,-21.9602,Caxambu,ADM2,DISTRICT
SM266392,-52.9186,-27.131,Caxambu do Sul,ADM2,DISTRICT
SM282369,-97.6108,20.0757,Caxhuacan,ADM2,DISTRICT
SM262969,-43.4208,-4.8171,Caxias,ADM2,DISTRICT
SM266060,-50.96,-29.0669,Caxias do Sul,ADM2,DISTRICT
SM265197,-41.8877,-3.4086,Caxingó,ADM2,DISTRICT
SM252624,-59.58,-12.76,Cayabi,ETHNICITY,ETHNICITY
SM15571,-65.9701,18.1446,Cayaguas Barrio,ADM2,DISTRICT
SM252625,-76.97,20.96,Cayaguayo,ETHNICITY,ETHNICITY
SM273539,-78.0441,0.0475,Cayambe,ADM2,DISTRICT
SM252626,-53.04,-7.91,Cayapo (Brazil),ETHNICITY,ETHNICITY
SM252626,-53.04,-7.91,Cayapo (Brazil),ETHNICITY,ETHNICITY
SM252626,-49.92,-8.54,Cayapo (Brazil),ETHNICITY,ETHNICITY
SM252626,-49.92,-8.54,Cayapo (Brazil),ETHNICITY,ETHNICITY
SM252626,-47.56,-7.95,Cayapo (Brazil),ETHNICITY,ETHNICITY
SM252626,-47.56,-7.95,Cayapo (Brazil),ETHNICITY,ETHNICITY
SM252626,-52,-8,Cayapo (Brazil),ETHNICITY,ETHNICITY
SM252626,-52,-8,Cayapo (Brazil),ETHNICITY,ETHNICITY
SM17275,-60.9356,14.0663,Caye Manje',ADM2,DISTRICT
SM271906,-70.4757,19.3429,Cayetano Germosén,ADM2,DISTRICT
SM868,-66.1538,18.1036,Cayey,ADM1,DISTRICT
SM15572,-66.1626,18.1144,Cayey Barrio-Pueblo,ADM2,DISTRICT
SM18032,29.3901,40.8278,Cayirova,ADM2,DISTRICT
SM286867,-71.5833,-15.6667,Caylloma,ADM2,DISTRICT
SM12218,-76.8983,18.0382,Caymanas,ADM2,DISTRICT
SM295794,46.4901,9.2824,Caynabo,ADM2,DISTRICT
SM870,-88.9,17,Cayo,ADM1,DISTRICT
SM228222,-65.4822,-13.2482,Cayubaba,LANGUAGE,LANGUAGE
SM228221,-76.5969,42.41,Cayuga,LANGUAGE,LANGUAGE
SM301922,-76.5744,43.0103,Cayuga,ADM2,DISTRICT
SM252627,-77.26,43.14,Cayuga,ETHNICITY,ETHNICITY
SM228220,-117.94,46.1136,Cayuse,LANGUAGE,LANGUAGE
SM252628,-120.08,44.91,Cayuse,ETHNICITY,ETHNICITY
SM13968,35.6679,33.8459,Caza de Baabda,ADM2,DISTRICT
SM13969,35.8289,34.2171,Caza de Batroun,ADM2,DISTRICT
SM13970,35.5601,33.5029,Caza de Jezzine,ADM2,DISTRICT
SM13971,35.5382,33.2751,Caza de Marjayoun (excl. Marjayoun City),ADM2,DISTRICT
SM13972,35.4448,33.3944,Caza de Nabatiye,ADM2,DISTRICT
SM13973,35.8406,34.4221,Caza de Trablous (excl. Tripoli city),ADM2,DISTRICT
SM291057,22.9058,44.7048,Cazanesti,ADM2,DISTRICT
SM290897,27.017,44.6251,Cazanesti,ADM2,DISTRICT
SM252629,-103.69,21.69,Cazcan,ETHNICITY,ETHNICITY
SM260323,13.2911,-8.8214,Cazenga,ADM2,DISTRICT
SM260434,14.7986,-9.3082,Cazengo,ADM2,DISTRICT
SM282858,-97.2896,20.7085,Cazones de Herrera,ADM2,DISTRICT
SM17276,-60.9048,13.8,Cazuca,ADM2,DISTRICT
SM260459,15.5488,-13.2068,Caála,ADM2,DISTRICT
SM266376,-51.1197,-26.7683,Caçador,ADM2,DISTRICT
SM267073,-45.7179,-23.0959,Caçapava,ADM2,DISTRICT
SM266022,-53.4723,-30.61,Caçapava do Sul,ADM2,DISTRICT
SM268227,-51.1304,-18.5577,Caçu,ADM2,DISTRICT
SM267740,-40.2914,-11.1418,Caém,ADM2,DISTRICT
SM282368,-97.4037,18.7526,Cañada Morelos,ADM2,DISTRICT
SM281775,-102.6983,21.1502,Cañadas de Obregón,ADM2,DISTRICT
SM273586,-78.9333,-2.55,Cañar,ADM2,DISTRICT
SM826,-79,-2.5,Cañar,ADM1,DISTRICT
SM228131,-78.9672,-2.4831,Cañar-Azuay-South Chimborazo Highland Quichua,LANGUAGE,LANGUAGE
SM270925,-85.1135,10.4801,Cañas,ADM2,DISTRICT
SM270191,-76.0395,6.8095,Cañasgordas,ADM2,DISTRICT
SM286670,-81.2863,8.356,Cañazas,ADM2,DISTRICT
SM286771,-76.3333,-12.75,Cañete,ADM2,DISTRICT
SM283090,-102.7174,23.5788,Cañitas de Felipe Pescador,ADM2,DISTRICT
SM289987,27.2711,44.2673,Ceacu,ADM2,DISTRICT
SM291321,25.9596,47.0449,Ceahlau,ADM2,DISTRICT
SM292082,28.7512,44.7373,Ceamurlia De Jos,ADM2,DISTRICT
SM290081,23.9459,46.6532,Ceanu Mare,ADM2,DISTRICT
SM871,-39.5,-5,Ceará,ADM1,DISTRICT
SM265528,-35.3767,-5.5505,Ceará-Mirim,ADM2,DISTRICT
SM292083,28.7832,45.279,Ceatalchioi,ADM2,DISTRICT
SM228223,-6.1526,9.5077,Cebaara Senoufo,LANGUAGE,LANGUAGE
SM872,123.7802,10.396,Cebu,ADM1:ADM2,DISTRICT
SM228224,124.367,8.388,Cebuano,LANGUAGE,LANGUAGE
SM252642,123.23,9.92,Cebuano,ETHNICITY,ETHNICITY
SM252642,123.23,9.92,Cebuano,ETHNICITY,ETHNICITY
SM252642,124.77,11.12,Cebuano,ETHNICITY,ETHNICITY
SM252642,124.77,11.12,Cebuano,ETHNICITY,ETHNICITY
SM252642,123.65,10.2,Cebuano,ETHNICITY,ETHNICITY
SM252642,123.65,10.2,Cebuano,ETHNICITY,ETHNICITY
SM301234,-75.9481,39.5624,Cecil,ADM2,DISTRICT
SM301683,-97.2524,42.5993,Cedar,ADM2,DISTRICT
SM301535,-93.8566,37.7239,Cedar,ADM2,DISTRICT
SM300791,-91.1324,41.7723,Cedar,ADM2,DISTRICT
SM12219,-77.4963,18.0123,Cedar Grove,ADM2,DISTRICT
SM12220,-76.5956,18.0282,Cedar Valley,ADM2,DISTRICT
SM17277,-60.9823,14.006,Cedars,ADM2,DISTRICT
SM17278,-61.0472,13.77,Cedars/Chu Tigre,ADM2,DISTRICT
SM303664,-63.75,9.8333,Cedeño,ADM2,DISTRICT
SM303857,-66,6.5,Cedeño,ADM2,DISTRICT
SM282529,-100.6802,23.8668,Cedral,ADM2,DISTRICT
SM266464,-49.255,-20.9074,Cedral,ADM2,DISTRICT
SM262970,-44.5742,-1.9609,Cedral,ADM2,DISTRICT
SM15573,-66.1964,18.2543,Cedrito Barrio,ADM2,DISTRICT
SM264969,-39.2333,-7.7188,Cedro,ADM2,DISTRICT
SM267904,-39.1204,-6.591,Cedro,ADM2,DISTRICT
SM15574,-66.2709,18.2892,Cedro Abajo Barrio,ADM2,DISTRICT
SM15575,-66.2813,18.2582,Cedro Arriba Barrio,ADM2,DISTRICT
SM15576,-65.9171,18.2878,Cedro Barrio (Carolina),ADM2,DISTRICT
SM15577,-66.1246,18.1009,Cedro Barrio (Cayey),ADM2,DISTRICT
SM15578,-66.7517,18.0275,Cedro Barrio (Guayanilla),ADM2,DISTRICT
SM267082,-36.8883,-10.2805,Cedro de São João,ADM2,DISTRICT
SM263306,-45.7007,-19.1147,Cedro do Abaeté,ADM2,DISTRICT
SM275925,-87.2168,14.5046,Cedros,ADM2,DISTRICT
SM261684,133.8629,-32.1474,Ceduna,ADM2,DISTRICT
SM295805,43.9983,4.6739,Ceel Barde,ADM2,DISTRICT
SM17861,46.6196,4.6846,Ceel Buur (exc. Ceel Buur town),ADM2,DISTRICT
SM17862,46.8416,4.7605,Ceel Buur town,ADM2,DISTRICT
SM295824,47.051,4.0519,Ceel Dheer,ADM2,DISTRICT
SM295828,41.411,2.656,Ceel Waaq,ADM2,DISTRICT
SM17863,46.6885,10.1476,Ceel-Afwein,ADM2,DISTRICT
SM295782,47.8076,10.29,Ceerigaabo,ADM2,DISTRICT
SM11562,19.8246,47.2,Cegledi Jaras,ADM2,DISTRICT
SM275787,-88.2055,14.8158,Ceguaca,ADM2,DISTRICT
SM291655,22.5961,47.3844,Cehalut,ADM2,DISTRICT
SM291592,23.1766,47.4129,Cehu Silvaniei,ADM2,DISTRICT
SM873,-65.6718,18.2605,Ceiba,ADM1,DISTRICT
SM15579,-67.0898,18.4795,Ceiba Alta Barrio,ADM2,DISTRICT
SM15580,-67.0789,18.4585,Ceiba Baja Barrio,ADM2,DISTRICT
SM15582,-66.1693,18.2016,Ceiba Barrio (Cidra),ADM2,DISTRICT
SM15583,-65.8955,18.1884,Ceiba Barrio (Las Piedras),ADM2,DISTRICT
SM15581,-66.3589,18.4493,Ceiba Barrio (Vega Baja),ADM2,DISTRICT
SM15584,-65.6481,18.2636,Ceiba Barrio-Pueblo,ADM2,DISTRICT
SM15585,-65.8941,18.2228,Ceiba Norte Barrio,ADM2,DISTRICT
SM15586,-65.9113,18.2062,Ceiba Sur Barrio,ADM2,DISTRICT
SM292412,22.1817,46.8644,Ceica,ADM2,DISTRICT
SM15587,-66.1895,18.2088,Cejas Barrio,ADM2,DISTRICT
SM15588,-65.9838,18.276,Celada Barrio,ADM2,DISTRICT
SM290343,24.1197,44.0419,Celaru,ADM2,DISTRICT
SM281577,-100.8068,20.5368,Celaya,ADM2,DISTRICT
SM286888,-78.25,-6.8333,Celendín,ADM2,DISTRICT
SM282941,-90.3086,20.8871,Celestún,ADM2,DISTRICT
SM273471,-80.0073,-4.1554,Celica,ADM2,DISTRICT
SM296231,15.2703,46.25,Celje,ADM1:ADM2,DISTRICT
SM276190,17.1321,47.25,Celldömölk,ADM2,DISTRICT
SM289235,-7.3863,40.6179,Celorico da Beira,ADM2,DISTRICT
SM289442,-8.0335,41.4028,Celorico de Basto,ADM2,DISTRICT
SM266393,-51.3206,-27.638,Celso Ramos,ADM2,DISTRICT
SM228231,-2.53,41.279,Celtiberian,LANGUAGE,LANGUAGE
SM228234,165.13,-20.8489,Cemuhî,LANGUAGE,LANGUAGE
SM228237,9.0218,9.6896,Cen,LANGUAGE,LANGUAGE
SM17279,-61.0424,13.8721,Cenac,ADM2,DISTRICT
SM291961,20.58,46.1337,Cenad,ADM2,DISTRICT
SM289782,24.0138,46.0805,Cenade,ADM2,DISTRICT
SM262055,30.604,-3.3083,Cendajuru,ADM2,DISTRICT
SM282942,-88.5839,21.064,Cenotillo,ADM2,DISTRICT
SM874,21.4289,42.005,Centar,ADM1:ADM2,DISTRICT
SM875,20.5833,41.4667,Centar župa,ADM1,DISTRICT
SM305238,31.1667,-16.4167,Centenary,ADM2,DISTRICT
SM267217,-47.4477,-9.1533,Centenário,ADM2,DISTRICT
SM266061,-52.0091,-27.7865,Centenário,ADM2,DISTRICT
SM264816,-51.5459,-22.8093,Centenário do Sul,ADM2,DISTRICT
SM273575,-78.7493,-3.9156,Centinela del Cóndor,ADM2,DISTRICT
SM282631,-92.6932,18.4393,Centla,ADM2,DISTRICT
SM303901,-64.7389,18.3415,Central,ADM2,DISTRICT
SM293660,37.6237,55.7494,Central,ADM2,DISTRICT
SM267774,-42.0815,-11.1542,Central,ADM2,DISTRICT
SM879,-1,5.5,Central,ADM1,DISTRICT
SM878,178.3333,-18,Central,ADM1,DISTRICT
SM877,26,-22,Central,ADM1,DISTRICT
SM884,160.2411,-9.0967,Central,ADM1,DISTRICT
SM881,148,-10,Central,ADM1,DISTRICT
SM882,-57.4667,-25.5333,Central,ADM1,DISTRICT
SM461265,29,-14,Central (2012-2018),ADM1:ADM2,DISTRICT
SM886,-77.1297,26.4392,Central Abaco,ADM1,DISTRICT
SM228379,-161.486,60.3062,Central Alaskan Yupik,LANGUAGE,LANGUAGE
SM245766,8.4642,47.0516,Central Alemannic,LANGUAGE,LANGUAGE
SM888,-77.9519,24.4389,Central Andros,ADM1,DISTRICT
SM228369,138.471,-5.8039,Central Asmat,LANGUAGE,LANGUAGE
SM228370,139.693,-6.2936,Central Awyu,LANGUAGE,LANGUAGE
SM228394,-68.5,-17,Central Aymara,LANGUAGE,LANGUAGE
SM275057,-15.9167,13.5333,Central Baddibu,ADM2,DISTRICT
SM228283,99.7052,26.1666,Central Bai,LANGUAGE,LANGUAGE
SM228252,13.35,48.34,Central Bavarian,DIALECT,LANGUAGE
SM274611,-0.4215,51.9975,Central Bedfordshire,ADM1:ADM2,DISTRICT
SM228353,114.565,3.9719,Central Berawan,LANGUAGE,LANGUAGE
SM228474,120.92,17.08,Central Bontoc,LANGUAGE,LANGUAGE
SM262554,17.6556,44.0983,Central Bosnia,ADM1:ADM2,DISTRICT
SM288563,155.5658,-6.2281,Central Bougainville,ADM2,DISTRICT
SM228344,121.857,17.9571,Central Cagayan Agta,LANGUAGE,LANGUAGE
SM252644,121.8,17.82,Central Cagayan Agta,ETHNICITY,ETHNICITY
SM228180,-124.328,54.3768,Central Carrier,LANGUAGE,LANGUAGE
SM228494,102.2706,12.6086,Central Chong,LANGUAGE,LANGUAGE
SM268766,-127.0032,52.1664,Central Coast,ADM2,DISTRICT
SM261778,146.0592,-41.27,Central Coast,ADM2,DISTRICT
SM9672,151.2668,-33.3072,Central Coast,ADM2,DISTRICT
SM245070,-2.5227,10.4226,Central Dagaare,LANGUAGE,LANGUAGE
SM889,23.3,12.3833,Central Darfur,ADM1,DISTRICT
SM261517,143.6257,-31.7716,Central Darling,ADM2,DISTRICT
SM5856,77.219,28.6486,Central Delhi,ADM2,DISTRICT
SM261603,133.4995,-21.072,Central Desert,ADM2,DISTRICT
SM12221,-76.7926,17.9696,Central Down Town,ADM2,DISTRICT
SM228354,116.681,6.03,Central Dusun,LANGUAGE,LANGUAGE
SM247664,12.692,51.152,Central East Middle German,LANGUAGE,LANGUAGE
SM890,-76.1456,25.135,Central Eleuthera,ADM1,DISTRICT
SM891,31,4.75,Central Equatoria,ADM1,DISTRICT
SM274397,25.5,62.5,Central Finland,ADM1:ADM2,DISTRICT
SM17280,-60.9848,13.8675,Central Forest Reserve,ADM2,DISTRICT
SM242927,106.1646,26.1554,Central Gelao-Qau,LANGUAGE,LANGUAGE
SM261222,143.7386,-36.9789,Central Goldfields,ADM2,DISTRICT
SM274935,-1.3235,8.9242,Central Gonja,ADM2,DISTRICT
SM228311,-7.8867,4.8804,Central Grebo,LANGUAGE,LANGUAGE
SM275157,23.14,38.3524,Central Greece,ADM1:ADM2,DISTRICT
SM231816,-99.0023,17.8583,Central Guerrero Nahuatl,LANGUAGE,LANGUAGE
SM286548,176.5306,-40.0557,Central Hawke's Bay,ADM2,DISTRICT
SM261779,146.6393,-42.1965,Central Highlands,ADM2,DISTRICT
SM261616,148.3196,-24.0634,Central Highlands,ADM2,DISTRICT
SM228286,108,23.95,Central Hongshuihe Zhuang,LANGUAGE,LANGUAGE
SM228386,-98.4356,21.0963,Central Huasteca Nahuatl,LANGUAGE,LANGUAGE
SM228289,106.719,26.041,Central Huishui Hmong,LANGUAGE,LANGUAGE
SM228480,18.1167,-19.5667,Central Ju,LANGUAGE,LANGUAGE
SM233767,-123.925,44.9861,Central Kalapuya,LANGUAGE,LANGUAGE
SM228319,13.13,11.8,Central Kanuri,LANGUAGE,LANGUAGE
SM250344,12.58,12.4,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,12.58,12.4,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,12.58,12.4,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,12.58,12.4,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,12.4165,12,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,12.4165,12,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,12.4165,12,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,12.4165,12,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,13,12,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,13,12,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,13,12,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,13,12,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,14.3,11.3,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,14.3,11.3,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,14.3,11.3,Central Kanuri,ETHNICITY,ETHNICITY
SM250344,14.3,11.3,Central Kanuri,ETHNICITY,ETHNICITY
SM305143,22.2119,-32.6281,Central Karoo,ADM2,DISTRICT
SM228270,105.015,12.0515,Central Khmer,LANGUAGE,LANGUAGE
SM5291,34.812,-0.717,Central Kisii (1998-2010),ADM2,DISTRICT
SM5291,34.812,-0.717,Central Kisii (1998-2010),ADM2,DISTRICT
SM5291,39.85,-3.61,Central Kisii (1998-2010),ADM2,DISTRICT
SM5291,39.85,-3.61,Central Kisii (1998-2010),ADM2,DISTRICT
SM268767,-117.5022,49.9166,Central Kootenay,ADM2,DISTRICT
SM228254,45.8077,35.6539,Central Kurdish,LANGUAGE,LANGUAGE
SM236232,123.44,-8.4709,Central Lembata-Lewokukun,LANGUAGE,LANGUAGE
SM4192,120.7976,15.3071,Central Luzon (2002-),ADM1,DISTRICT
SM275152,22.8406,40.9135,Central Macedonia,ADM1:ADM2,DISTRICT
SM228323,168.135,-15.0784,Central Maewo,LANGUAGE,LANGUAGE
SM228322,103.137,-3.7967,Central Malay,LANGUAGE,LANGUAGE
SM228502,62.4023,61.1432,Central Mansi,LANGUAGE,LANGUAGE
SM228335,129.865,-8.1541,Central Masela,LANGUAGE,LANGUAGE
SM228240,106.396,25.6005,Central Mashan Hmong,LANGUAGE,LANGUAGE
SM228396,-100.018,19.7357,Central Mazahua,LANGUAGE,LANGUAGE
SM228355,111.3465,2.5241,Central Melanau,LANGUAGE,LANGUAGE
SM228492,10.968,6.6788,Central Mfumte,LANGUAGE,LANGUAGE
SM228273,107.301,12.6313,Central Mnong,LANGUAGE,LANGUAGE
SM228406,-2.4087,32.7415,Central Moroccan Berber,LANGUAGE,LANGUAGE
SM228337,121.0476,-8.6778,Central Ngada,DIALECT,LANGUAGE
SM228271,93.3921,7.9481,Central Nicobarese,LANGUAGE,LANGUAGE
SM228388,-84.1543,46.5741,Central Ojibwa,LANGUAGE,LANGUAGE
SM268768,-119.4191,49.9998,Central Okanagan,ADM2,DISTRICT
SM228378,127.7816,26.1485,Central Okinawan,LANGUAGE,LANGUAGE
SM274398,24.0833,63.5833,Central Ostrobothnia,ADM1:ADM2,DISTRICT
SM286492,169.6041,-45.1309,Central Otago,ADM2,DISTRICT
SM228349,117.838,8.7701,Central Palawano,LANGUAGE,LANGUAGE
SM228397,-99.2898,21.3762,Central Pame,LANGUAGE,LANGUAGE
SM228255,69.4529,31.9153,Central Pashto,LANGUAGE,LANGUAGE
SM228390,-123.6639,38.9055,Central Pomo,LANGUAGE,LANGUAGE
SM12222,-76.4534,18.1876,Central Port Antonio,ADM2,DISTRICT
SM4207,80.75,7.3333,Central Province-Sri Lanka,ADM1,DISTRICT
SM228385,-98.1264,18.7545,Central Puebla Nahuatl,LANGUAGE,LANGUAGE
SM295333,156.5623,-7.9946,Central Ranongga,ADM2,DISTRICT
SM4177,34,-13.5,Central Region,ADM1:ADMX,DISTRICT
SM4215,32,0.5,Central Region-Uganda,ADM1,DISTRICT
SM14003,-9.3179,5.8101,Central River Cess District,ADM2,DISTRICT
SM228178,20.3129,48.1968,Central Romani,LANGUAGE,LANGUAGE
SM228350,118.456,7.0046,Central Sama,LANGUAGE,LANGUAGE
SM228506,82.0129,59.8903,Central Selkup,DIALECT,LANGUAGE
SM4204,21,44,Central Serbia,ADM1:ADMX,DISTRICT
SM228380,-170.272,63.4308,Central Siberian Yupik,LANGUAGE,LANGUAGE
SM228392,-120.257,38.0812,Central Sierra Miwok,LANGUAGE,LANGUAGE
SM273995,41.7,13.9333,Central So. Red-Sea,ADM2,DISTRICT
SM228409,53.788,12.475,Central Soqotri,DIALECT,LANGUAGE
SM228347,123.018,8.127,Central Subanen,LANGUAGE,LANGUAGE
SM228348,119.338,10.6519,Central Tagbanwa,LANGUAGE,LANGUAGE
SM228383,-107.154,27.5648,Central Tarahumara,LANGUAGE,LANGUAGE
SM228499,33,37,Central Taurus Sign Language,LANGUAGE,LANGUAGE
SM4391,0.5734,6.1804,Central Tongu,ADM2,DISTRICT
SM228399,-72.097,6.8867,Central Tunebo,LANGUAGE,LANGUAGE
SM12223,-76.9239,17.9999,Central Village,ADM2,DISTRICT
SM4199,123.8709,10.3582,Central Visayas,ADM1,DISTRICT
SM887,114.1449,22.2822,Central and Western,ADM1,DISTRICT
SM263307,-41.2896,-18.7678,Central de Minas,ADM2,DISTRICT
SM262971,-44.841,-2.2552,Central do Maranhão,ADM2,DISTRICT
SM228293,8.4326,15.0907,Central-Eastern Niger Fulfulde,LANGUAGE,LANGUAGE
SM263308,-49.1606,-18.6092,Centralina,ADM2,DISTRICT
SM302290,-77.82,40.9193,Centre,ADM2,DISTRICT
SM895,-72,19,Centre,ADM1,DISTRICT
SM896,1,8.6667,Centre,ADM1,DISTRICT
SM893,-1.5328,12.3787,Centre (incl. Ouagadougou),ADM1,DISTRICT
SM894,11.8333,4.75,Centre (incl. Youande),ADM1,DISTRICT
SM897,-0.3571,11.7892,Centre-Est,ADM1,DISTRICT
SM898,-1.0855,13.0983,Centre-Nord,ADM1,DISTRICT
SM899,-2.3688,12.2549,Centre-Ouest,ADM1,DISTRICT
SM900,-1.0737,11.6713,Centre-Sud,ADM1,DISTRICT
SM901,1.75,47.5,Centre-Val de Loire,ADM1,DISTRICT
SM10208,-71.9824,46.0199,Centre-du-Quebec,ADM2,DISTRICT
SM282632,-92.8867,17.9863,Centro,ADM2,DISTRICT
SM15589,-67.0998,18.4197,Centro Barrio,ADM2,DISTRICT
SM271094,-82.3618,23.138,Centro Habana,ADM2,DISTRICT
SM262973,-46.7936,-3.3167,Centro Novo do Maranhão,ADM2,DISTRICT
SM902,10.4167,1.5,Centro Sur,ADM1,DISTRICT
SM262972,-46.0735,-2.2807,Centro do Guilherme,ADM2,DISTRICT
SM296052,-54.9555,5.0685,Centrum,ADM2,DISTRICT
SM13464,23.8535,56.6996,Cenu pagasts,ADM2,DISTRICT
SM291229,24.5306,45.2056,Ceparii Pamanteni,ADM2,DISTRICT
SM270446,-72.9491,6.7338,Cepitá,ADM2,DISTRICT
SM290807,26.926,47.3758,Ceplenita,ADM2,DISTRICT
SM291509,26.3134,45.0463,Ceptura De Jos,ADM2,DISTRICT
SM252645,130.03,-3.22,Ceram,ETHNICITY,ETHNICITY
SM291510,26.0387,45.3294,Cerasu,ADM2,DISTRICT
SM290344,23.6708,44.0736,Cerat,ADM2,DISTRICT
SM13465,24.2939,56.3709,Ceraukstes pagasts,ADM2,DISTRICT
SM290750,22.6806,45.786,Cerbal,ADM2,DISTRICT
SM276071,-71.7743,19.134,Cerca La Source,ADM2,DISTRICT
SM15590,-66.1956,18.067,Cercadillo Barrio,ADM2,DISTRICT
SM262777,-64.5459,-21.4896,Cercado,ADM2,DISTRICT
SM262710,-64.4758,-14.5375,Cercado,ADM2,DISTRICT
SM262706,-66.1648,-17.4233,Cercado,ADM2,DISTRICT
SM290205,28.0844,43.8498,Cerchezu,ADM2,DISTRICT
SM323869,-110.8019,51.4188,Cereal,ADM2:ADM3,DISTRICT
SM274764,-4,52.25,Ceredigion,ADM1:ADM2,DISTRICT
SM266076,-61.4752,-13.1832,Cerejeiras,ADM2,DISTRICT
SM9904,-49.6446,-15.2735,Ceres (2000-),ADM2,DISTRICT
SM13466,22.8518,57.1134,Ceres pagasts,ADM2,DISTRICT
SM269951,-75.8218,8.8821,Cereté,ADM2,DISTRICT
SM289783,23.946,46.0931,Cergau Mare,ADM2,DISTRICT
SM270746,-72.9913,5.9365,Cerinza,ADM2,DISTRICT
SM18033,27.9997,41.2862,Cerkezkoey (2014-),ADM2,DISTRICT
SM296202,14.5,46.25,Cerklje na Gorenjskem,ADM1:ADM2,DISTRICT
SM296288,14.3667,45.8,Cerknica,ADM1:ADM2,DISTRICT
SM296253,13.9731,46.125,Cerkno,ADM1:ADM2,DISTRICT
SM296335,15.942,46.5613,Cerkvenjak,ADM1:ADM2,DISTRICT
SM228554,-4.9492,10.1245,Cerma,LANGUAGE,LANGUAGE
SM290489,21.8708,46.5543,Cermei,ADM2,DISTRICT
SM292084,28.3167,45.0401,Cerna,ADM2,DISTRICT
SM305557,18.6872,45.1887,Cerna,ADM2,DISTRICT
SM290209,26.0196,45.9665,Cernat,ADM2,DISTRICT
SM290345,23.4758,44.4432,Cernatesti,ADM2,DISTRICT
SM289862,26.7626,45.2984,Cernatesti,ADM2,DISTRICT
SM290148,28.0369,44.3433,Cernavoda,ADM2,DISTRICT
SM290998,23.7837,47.5358,Cernesti,ADM2,DISTRICT
SM290983,26.2899,44.3934,Cernica,ADM2,DISTRICT
SM305581,17.3807,45.2863,Cernik,ADM2,DISTRICT
SM305684,14.0104,45.2765,Cerovlje,ADM2,DISTRICT
SM266465,-49.107,-23.03,Cerqueira César,ADM2,DISTRICT
SM266467,-47.7709,-23.1913,Cerquilho,ADM2,DISTRICT
SM282152,-99.6534,26.0977,Cerralvo,ADM2,DISTRICT
SM260962,-65.4167,-25,Cerrillos,ADM2,DISTRICT
SM15591,-66.5719,18.0552,Cerrillos Barrio,ADM2,DISTRICT
SM289610,-57.5412,-27.3213,Cerrito,ADM2,DISTRICT
SM270447,-72.671,6.8907,Cerrito,ADM2,DISTRICT
SM266063,-52.7838,-31.7395,Cerrito,ADM2,DISTRICT
SM282530,-100.2912,22.4455,Cerritos,ADM2,DISTRICT
SM271095,-82.3799,23.1104,Cerro,ADM2,DISTRICT
SM282859,-97.7693,21.1786,Cerro Azul,ADM2,DISTRICT
SM266064,-52.9873,-29.6337,Cerro Branco,ADM2,DISTRICT
SM265530,-36.3393,-6.0013,Cerro Corá,ADM2,DISTRICT
SM300792,-93.2608,43.0816,Cerro Gordo,ADM2,DISTRICT
SM15592,-67.144,18.3332,Cerro Gordo Barrio (Aguada),ADM2,DISTRICT
SM15593,-67.081,18.3027,Cerro Gordo Barrio (Añasco),ADM2,DISTRICT
SM15594,-66.1729,18.3615,Cerro Gordo Barrio (Bayamón),ADM2,DISTRICT
SM15595,-67.0769,18.3352,Cerro Gordo Barrio (Moca),ADM2,DISTRICT
SM15596,-65.945,18.1548,Cerro Gordo Barrio (San Lorenzo),ADM2,DISTRICT
SM266066,-53.1603,-27.6226,Cerro Grande,ADM2,DISTRICT
SM266065,-51.7424,-30.5862,Cerro Grande do Sul,ADM2,DISTRICT
SM266067,-54.7367,-28.1349,Cerro Largo,ADM2,DISTRICT
SM903,-54.3333,-32.3333,Cerro Largo,ADM1,DISTRICT
SM266394,-50.9404,-27.7727,Cerro Negro,ADM2,DISTRICT
SM10390,-74.8217,10.2906,Cerro San Antonio (1999-),ADM2,DISTRICT
SM282475,-100.8262,22.1743,Cerro de San Pedro,ADM2,DISTRICT
SM15597,-66.9238,18.2125,Cerrote Barrio,ADM2,DISTRICT
SM10391,-76.6,5.4,Certegui,ADM2,DISTRICT
SM290751,22.9886,45.9744,Certeju De Sus,ADM2,DISTRICT
SM15598,-66.1224,18.1935,Certenejas Barrio,ADM2,DISTRICT
SM290428,27.6232,46.0176,Certesti,ADM2,DISTRICT
SM291656,23.4735,47.9201,Certeze,ADM2,DISTRICT
SM289784,23.2698,46.0012,Ceru-bacainti,ADM2,DISTRICT
SM291873,25.4605,43.8359,Cervenia,ADM2,DISTRICT
SM904,-73.5,9.3333,Cesar,ADM1,DISTRICT
SM13467,25.2641,57.3124,Cesis,ADM2,DISTRICT
SM261518,151.2123,-32.8939,Cessnock,ADM2,DISTRICT
SM305519,16.1237,46.37,Cestica,ADM2,DISTRICT
SM4025,25.4132,57.2423,Cesu Novads,ADM1,DISTRICT
SM13468,26.3113,56.9651,Cesvaine,ADM2,DISTRICT
SM4033,26.2767,57.0075,Cesvaines Novads,ADM1,DISTRICT
SM13469,26.2764,57.009,Cesvaines pagasts,ADM2,DISTRICT
SM266468,-47.8847,-23.212,Cesário Lange,ADM2,DISTRICT
SM290346,23.0637,44.0989,Cetate,ADM2,DISTRICT
SM16710,24.6288,47.1107,Cetate (Bistrița-Năsăud),ADM2,DISTRICT
SM289785,24.1672,46.2162,Cetatea De Balta,ADM2,DISTRICT
SM291230,25.2079,45.1923,Cetateni,ADM2,DISTRICT
SM305699,15.7388,45.1591,Cetingrad,ADM2,DISTRICT
SM906,18.9197,42.4,Cetinje,ADM1,DISTRICT
SM291127,24.4865,46.6459,Ceuasu De Campie,ADM2,DISTRICT
SM274080,-5.3079,35.8879,Ceuta,ADM1:ADM2,DISTRICT
SM274080,-5.3079,35.8879,Ceuta,ADM1:ADM2,DISTRICT
SM274080,-5.3075,35.8903,Ceuta,ADM1:ADM2,DISTRICT
SM274080,-5.3075,35.8903,Ceuta,ADM1:ADM2,DISTRICT
SM273568,-78.6085,-1.3481,Cevallos,ADM2,DISTRICT
SM271854,-69.9667,19,Cevicos,ADM2,DISTRICT
SM298128,35.8363,37.075,Ceyhan,ADM2,DISTRICT
SM298869,39.905,36.9194,Ceylanpınar,ADM2,DISTRICT
SM268245,-49.73,-17.1182,Cezarina,ADM2,DISTRICT
SM291395,24.2685,44.1825,Cezieni,ADM2,DISTRICT
SM289177,127.8333,38.5,Ch'angdo,ADM2,DISTRICT
SM289196,125.2672,40.4433,Ch'angsŏng,ADM2,DISTRICT
SM252858,-90.05,16.89,Ch'ol,ETHNICITY,ETHNICITY
SM252858,-91.72,17.71,Ch'ol,ETHNICITY,ETHNICITY
SM289030,125.8333,40.75,Ch'osan,ADM2,DISTRICT
SM228912,127.31,36.63,Ch'ungch'ongdo,DIALECT,LANGUAGE
SM289059,125.5817,38.9497,Ch'ŏllima,ADM2,DISTRICT
SM289202,124.6431,39.7492,Ch'ŏlsan,ADM2,DISTRICT
SM289159,125.9167,38,Ch'ŏngdan,ADM2,DISTRICT
SM289095,129.8319,41.8872,Ch'ŏngjin,ADM2,DISTRICT
SM289070,125.5467,39.5019,Ch'ŏngnam,ADM2,DISTRICT
SM289203,124.9167,40.0833,Ch'ŏnma,ADM2,DISTRICT
SM289178,127.1722,39.2992,Ch'ŏnnae,ADM2,DISTRICT
SM289179,127,38.3333,Ch'ŏrwŏn,ADM2,DISTRICT
SM228560,-79.0508,0.7149,Cha'palaa,LANGUAGE,LANGUAGE
SM297350,99.8983,12.7537,Cha-Am,ADM2,DISTRICT
SM297220,99.9893,7.9565,Cha-uat,ADM2,DISTRICT
SM250026,6.61,33.79,Chaamba,ETHNICITY,ETHNICITY
SM250026,6.61,33.79,Chaamba,ETHNICITY,ETHNICITY
SM250026,3.71,30.7,Chaamba,ETHNICITY,ETHNICITY
SM250026,3.71,30.7,Chaamba,ETHNICITY,ETHNICITY
SM250026,3,32,Chaamba,ETHNICITY,ETHNICITY
SM250026,3,32,Chaamba,ETHNICITY,ETHNICITY
SM269082,-70.8165,-33.1594,Chacabuco,ADM2,DISTRICT
SM260987,-65.25,-32.75,Chacabuco,ADM2,DISTRICT
SM261099,-61.3138,-27.141,Chacabuco,ADM2,DISTRICT
SM260743,-60.3333,-34.6667,Chacabuco,ADM2,DISTRICT
SM282860,-95.8101,18.2309,Chacaltianguis,ADM2,DISTRICT
SM303653,-66.8535,10.496,Chacao,ADM2,DISTRICT
SM270359,-77.2693,1.3873,Chachagüí,ADM2,DISTRICT
SM252647,-78.14,-5.75,Chachapoya,ETHNICITY,ETHNICITY
SM286723,-77.8107,-6.2748,Chachapoyas,ADM2,DISTRICT
SM228561,-77.6615,-6.2702,Chachapoyas Quechua,LANGUAGE,LANGUAGE
SM461512,-77.9681,-6.1912,Chachapoyas Quechua,ETHNICITY,ETHNICITY
SM262599,31.0833,52.9167,Chachersk,ADM2,DISTRICT
SM252648,-78.96,0.96,Chachi,ETHNICITY,ETHNICITY
SM252648,-78.96,0.96,Chachi,ETHNICITY,ETHNICITY
SM252648,-79,1,Chachi,ETHNICITY,ETHNICITY
SM252648,-79,1,Chachi,ETHNICITY,ETHNICITY
SM908,101.4167,13.6311,Chachoengsao,ADM1,DISTRICT
SM909,-60.5,-26.3333,Chaco,ADM1,DISTRICT
SM252649,-66.63,-12.57,Chacobo,ETHNICITY,ETHNICITY
SM252649,-66.63,-12.57,Chacobo,ETHNICITY,ETHNICITY
SM252649,-67,-12,Chacobo,ETHNICITY,ETHNICITY
SM252649,-67,-12,Chacobo,ETHNICITY,ETHNICITY
SM282943,-89.0079,20.1818,Chacsinkín,ADM2,DISTRICT
SM228567,13.5,14.67,Chadian Arabic,LANGUAGE,LANGUAGE
SM228565,15.2481,12.1115,Chadian Sign Language,LANGUAGE,LANGUAGE
SM18119,32.4871,-14.0974,Chadiza District (2012-),ADM2,DISTRICT
SM228566,109.9604,25.2353,Chadong,LANGUAGE,LANGUAGE
SM297009,99.6682,18.754,Chae Hom,ADM2,DISTRICT
SM289162,125.6586,38.3828,Chaeryŏng,ADM2,DISTRICT
SM303381,-106.1941,38.7469,Chaffee,ADM2,DISTRICT
SM5127,63.5909,28.9877,Chagai (excl. Nushki),ADM2:ADM3,DISTRICT
SM910,126.5,40.75,Chagang-do,ADM1,DISTRICT
SM228570,57.8876,38.2115,Chagatai,LANGUAGE,LANGUAGE
SM250156,38.45,-1.54,Chagga,ETHNICITY,ETHNICITY
SM250156,38.45,-1.54,Chagga,ETHNICITY,ETHNICITY
SM250156,38.45,-1.54,Chagga,ETHNICITY,ETHNICITY
SM250156,37.5,-3.25,Chagga,ETHNICITY,ETHNICITY
SM250156,37.5,-3.25,Chagga,ETHNICITY,ETHNICITY
SM250156,37.5,-3.25,Chagga,ETHNICITY,ETHNICITY
SM250156,37,-3,Chagga,ETHNICITY,ETHNICITY
SM250156,37,-3,Chagga,ETHNICITY,ETHNICITY
SM250156,37,-3,Chagga,ETHNICITY,ETHNICITY
SM9508,65.3298,34.724,Chaghcharan (2005-),ADM2,DISTRICT
SM294963,35.4167,59,Chagodoshchenskiy rayon,ADM2,DISTRICT
SM286696,-80.1184,9.123,Chagres,ADM2,DISTRICT
SM911,-61.3833,10.5,Chaguanas,ADM1:ADM2,DISTRICT
SM911,-61.3833,10.5,Chaguanas,ADM1:ADM2,DISTRICT
SM911,-61.415,10.5172,Chaguanas,ADM1:ADM2,DISTRICT
SM911,-61.415,10.5172,Chaguanas,ADM1:ADM2,DISTRICT
SM269980,-74.6595,4.9455,Chaguaní,ADM2,DISTRICT
SM303595,-66.2526,9.3386,Chaguaramas,ADM2,DISTRICT
SM273472,-79.6723,-3.856,Chaguarpamba,ADM2,DISTRICT
SM260212,69.8799,37.45,Chah Ab,ADM2,DISTRICT
SM275159,-89.5649,15.7286,Chahal,ADM2,DISTRICT
SM252656,115,41,Chahar,ETHNICITY,ETHNICITY
SM260040,69.0727,34.3871,Chahar Asyab,ADM2,DISTRICT
SM912,50.75,32.1667,Chahar Mahall and Bakhtiari,ADM1,DISTRICT
SM9509,65.1179,35.1194,Chahar Sadah,ADM2,DISTRICT
SM260177,69.142,34.9882,Chaharikar,ADM2,DISTRICT
SM14243,-94.1999,16.2727,Chahuites,ADM2,DISTRICT
SM297054,101.1268,15.1894,Chai Badan,ADM2,DISTRICT
SM913,100.1,15.1167,Chai Nat,ADM1,DISTRICT
SM296872,99.1748,19.674,Chai Prakarn,ADM2,DISTRICT
SM297726,103.2783,17.2158,Chai Wan,ADM2,DISTRICT
SM297631,99.0772,8.4388,Chaiburi,ADM2,DISTRICT
SM228579,-63.1034,9.2126,Chaima,LANGUAGE,LANGUAGE
SM294669,82.5,57.75,Chainskiy rayon,ADM2,DISTRICT
SM228580,93.8664,24.3044,Chairel,LANGUAGE,LANGUAGE
SM297632,98.9957,9.4914,Chaiya,ADM2,DISTRICT
SM914,101.8125,16.018,Chaiyaphum,ADM1,DISTRICT
SM297022,100.4679,14.6723,Chaiyo,ADM2,DISTRICT
SM275245,-91.0379,15.4871,Chajul,ADM2,DISTRICT
SM228586,92.5953,21.4134,Chak,LANGUAGE,LANGUAGE
SM260226,68.4906,34.1082,Chak,ADM2,DISTRICT
SM228587,-1.9018,9.8345,Chakali,LANGUAGE,LANGUAGE
SM252661,-90.6,33.64,Chakchiuma,ETHNICITY,ETHNICITY
SM299144,39.7739,-5.2473,Chake,ADM2,DISTRICT
SM260135,62.4681,31.3635,Chakhansur,ADM2,DISTRICT
SM297134,102.4372,14.9569,Chakkarat,ADM2,DISTRICT
SM228583,92.339,23.0015,Chakma,LANGUAGE,LANGUAGE
SM252667,92,23,Chakma,ETHNICITY,ETHNICITY
SM250037,0.69,10.96,Chakosi,ETHNICITY,ETHNICITY
SM375473,72.5311,32.8978,Chakwal,ADM2:ADM3,DISTRICT
SM260213,69.5592,36.4974,Chal,ADM2,DISTRICT
SM228599,0.509,8.0009,Chala,LANGUAGE,LANGUAGE
SM915,144.7589,13.4474,Chalan-Pago-Ordot,ADM1,DISTRICT
SM295634,-88.8333,14.05,Chalatenango,ADM2,DISTRICT
SM916,-89.0167,14.1333,Chalatenango,ADM1,DISTRICT
SM14244,-97.5498,16.9929,Chalcatongo de Hidalgo,ADM2,DISTRICT
SM282371,-97.4334,18.9705,Chalchicomula de Sesma,ADM2,DISTRICT
SM283091,-103.897,23.4425,Chalchihuites,ADM2,DISTRICT
SM283119,-92.65,16.9667,Chalchihuitán,ADM2,DISTRICT
SM295550,-89.6667,14.0333,Chalchuapa,ADM2,DISTRICT
SM14245,-98.8994,19.2664,Chalco (1994-),ADM2,DISTRICT
SM228601,43.5027,37.0972,Chaldean Neo-Aramaic,LANGUAGE,LANGUAGE
SM277643,44.3333,39.0667,Chaldoran,ADM2,DISTRICT
SM263310,-41.6781,-20.0335,Chale,ADM2,DISTRICT
SM297225,100.05,8.1833,Chalermphrakiet,ADM2,DISTRICT
SM297539,100.9084,14.6567,Chalermphrakiet,ADM2,DISTRICT
SM297138,102.2937,14.9754,Chalermphrakiet,ADM2,DISTRICT
SM297240,101.1425,19.5062,Chalermphrakiet,ADM2,DISTRICT
SM297426,102.8988,14.5573,Chalermphrakiet,ADM2,DISTRICT
SM228597,91.262,27.3231,Chalikha,LANGUAGE,LANGUAGE
SM260857,-66.5833,-36.4167,Chalileo,ADM2,DISTRICT
SM12224,-77.1752,18.3796,Chalky Hill,ADM2,DISTRICT
SM282861,-98.3753,21.208,Chalma,ADM2,DISTRICT
SM228600,-121.1513,36.3746,Chalon,LANGUAGE,LANGUAGE
SM17281,-61.0441,13.9202,Chalon,ADM2,DISTRICT
SM270545,-75.3181,9.5701,Chalán,ADM2,DISTRICT
SM252678,103.05,12.96,Cham,ETHNICITY,ETHNICITY
SM252678,103.05,12.96,Cham,ETHNICITY,ETHNICITY
SM252678,105.46,13.38,Cham,ETHNICITY,ETHNICITY
SM252678,105.46,13.38,Cham,ETHNICITY,ETHNICITY
SM252678,104.89,10.83,Cham,ETHNICITY,ETHNICITY
SM252678,104.89,10.83,Cham,ETHNICITY,ETHNICITY
SM252678,108,11,Cham,ETHNICITY,ETHNICITY
SM252678,108,11,Cham,ETHNICITY,ETHNICITY
SM297427,102.8539,14.7864,Cham Ni,ADM2,DISTRICT
SM305189,32.8333,-11.25,Chama,ADM2,DISTRICT
SM252680,-67.29,-12.32,Chama,ETHNICITY,ETHNICITY
SM252680,-67.29,-12.32,Chama,ETHNICITY,ETHNICITY
SM252680,-75,-8,Chama,ETHNICITY,ETHNICITY
SM252680,-75,-8,Chama,ETHNICITY,ETHNICITY
SM228611,-58.2005,-20.5899,Chamacoco,LANGUAGE,LANGUAGE
SM252681,-60.41,-18.61,Chamacoco,ETHNICITY,ETHNICITY
SM252681,-60.41,-18.61,Chamacoco,ETHNICITY,ETHNICITY
SM252681,-59.45,-21.39,Chamacoco,ETHNICITY,ETHNICITY
SM252681,-59.45,-21.39,Chamacoco,ETHNICITY,ETHNICITY
SM252681,-59,-20,Chamacoco,ETHNICITY,ETHNICITY
SM252681,-59,-20,Chamacoco,ETHNICITY,ETHNICITY
SM228605,45.995,42.5024,Chamalal,LANGUAGE,LANGUAGE
SM228617,79.6232,21.3532,Chamari,LANGUAGE,LANGUAGE
SM276869,76.1023,32.5715,Chamba,ADM2,DISTRICT
SM250661,10.8,7.36,Chamba (Daka and Leko),ETHNICITY,ETHNICITY
SM271071,-78.9133,22.1967,Chambas,ADM2,DISTRICT
SM228603,76.1796,32.5476,Chambeali,LANGUAGE,LANGUAGE
SM302823,-94.6714,29.7083,Chambers,ADM2,DISTRICT
SM300312,-85.392,32.9144,Chambers,ADM2,DISTRICT
SM12225,-78.1598,18.4052,Chambers Pen,ADM2,DISTRICT
SM273599,-78.5453,-1.7515,Chambo,ADM2,DISTRICT
SM228609,143.097,-4.2783,Chambri,LANGUAGE,LANGUAGE
SM305841,44.8913,35.3384,Chamchamal,ADM2,DISTRICT
SM269358,97.223,31.5837,Chamdo,ADM2,DISTRICT
SM286654,-79.9167,8.6,Chame,ADM2,DISTRICT
SM14188,-15.7441,20.2886,Chami,ADM2,DISTRICT
SM260874,-65.8333,-30.25,Chamical,ADM2,DISTRICT
SM228614,-75.6778,-5.1149,Chamicuro,LANGUAGE,LANGUAGE
SM260165,69.8127,33.7899,Chamkani,ADM2,DISTRICT
SM280468,105.291,12.2661,Chamkar Leu,ADM2,DISTRICT
SM277349,79.5,30.5,Chamoli (1997-),ADM2,DISTRICT
SM228608,144.7447,13.438,Chamorro,LANGUAGE,LANGUAGE
SM252699,145.71,15.13,Chamorro,ETHNICITY,ETHNICITY
SM15599,-66.9157,18.2241,Chamorro Barrio,ADM2,DISTRICT
SM302085,-83.7695,40.1377,Champaign,ADM2,DISTRICT
SM300589,-88.1992,40.1401,Champaign,ADM2,DISTRICT
SM917,106,14.75,Champasak,ADM1,DISTRICT
SM280850,105.7533,14.857,Champassack,ADM2,DISTRICT
SM277350,80.0374,29.2876,Champawat,ADM2,DISTRICT
SM17282,-61.0299,13.9358,Champen Estate,ADM2,DISTRICT
SM275255,-91.8809,14.3427,Champerico,ADM2,DISTRICT
SM277088,93.3256,23.4744,Champhai,ADM2,DISTRICT
SM280784,105.2202,16.4936,Champhone,ADM2,DISTRICT
SM323896,-113.148,50.24,Champion,ADM2:ADM3,DISTRICT
SM14246,-90.7099,19.3504,Champoton (1996-),ADM2,DISTRICT
SM276951,77.09,11.96,Chamrajnagar,ADM2,DISTRICT
SM283120,-92.6833,16.7833,Chamula,ADM2,DISTRICT
SM289336,-8.3993,39.299,Chamusca,ADM2,DISTRICT
SM299208,36.0956,-6.1133,Chamwino,ADM2,DISTRICT
SM293656,45.75,54.4167,Chamzinskiy rayon,ADM2,DISTRICT
SM297591,100.7013,6.8958,Chana,ADM2,DISTRICT
SM252701,-58.94,-33.94,Chana,ETHNICITY,ETHNICITY
SM252704,-59.28,-34.55,Chana-Timbu,ETHNICITY,ETHNICITY
SM283121,-92.2501,16.6539,Chanal,ADM2,DISTRICT
SM285043,6.5142,9.6055,Chanchaga,ADM2,DISTRICT
SM286744,-75.3167,-11.05,Chanchamayo,ADM2,DISTRICT
SM277280,83.2641,25.2613,Chandauli,ADM2,DISTRICT
SM11638,94.0932,24.2355,Chandel (2017-),ADM2,DISTRICT
SM277441,76.7815,30.7301,Chandigarh,ADM2,DISTRICT
SM918,76.7778,30.7278,Chandigarh,ADM1,DISTRICT
SM283554,97.9907,45.3293,Chandmani,ADM2,DISTRICT
SM283590,92.6493,47.8265,Chandmani,ADM2,DISTRICT
SM283615,100.9307,50.4692,Chandmani-Öndör,ADM2,DISTRICT
SM262234,90.8333,23.25,Chandpur,ADM2,DISTRICT
SM277066,79.4438,20.1179,Chandrapur (1989-),ADM2,DISTRICT
SM10662,32.9913,34.947,Chandria,ADM2,DISTRICT
SM252713,-63.92,-22.88,Chane,ETHNICITY,ETHNICITY
SM228634,94.9536,26.4336,Chang Naga,LANGUAGE,LANGUAGE
SM15019,33.1711,-16.2165,"Changara District (2013-, incl. Luenha town)",ADM2,DISTRICT
SM269235,125.7319,44.3828,Changchun,ADM2,DISTRICT
SM269195,111.6124,29.3218,Changde,ADM2,DISTRICT
SM289040,126.7256,41.0633,Changgang,ADM2,DISTRICT
SM297475,103.6161,16.1621,Changhan,ADM2,DISTRICT
SM299058,120.4908,23.9536,Changhua,ADM2,DISTRICT
SM269353,87.3167,44.0167,Changji Hui,ADM2,DISTRICT
SM277206,96.3452,27.3627,Changlang,ADM2,DISTRICT
SM280542,128.4902,35.5082,Changnyeong,ADM2,DISTRICT
SM228640,143.696,-4.2836,Changriwa,LANGUAGE,LANGUAGE
SM228646,112.9433,28.8503,Changsha,DIALECT,LANGUAGE
SM269201,113.2297,28.1458,Changsha,ADM2,DISTRICT
SM228630,78.2389,34.0662,Changthang,LANGUAGE,LANGUAGE
SM228644,-82.5682,9.3596,Changuena,LANGUAGE,LANGUAGE
SM286643,-82.6333,9.25,Changuinola,ADM2,DISTRICT
SM280553,128.6515,35.2753,Changwon,ADM2,DISTRICT
SM289163,125.0442,38.2797,Changyŏn,ADM2,DISTRICT
SM269309,112.8844,36.4556,Changzhi,ADM2,DISTRICT
SM269209,119.6653,31.6672,Changzhou,ADM2,DISTRICT
SM282944,-88.5693,20.4966,Chankom,ADM2,DISTRICT
SM228647,-2.3625,49.4259,Channel Islands English,DIALECT,LANGUAGE
SM293887,77,55.25,Chanovskiy rayon,ADM2,DISTRICT
SM280791,102.611,18.0066,Chanthabuly,ADM2,DISTRICT
SM919,102.1543,12.858,Chanthaburi,ADM1,DISTRICT
SM12226,-77.4663,18.1045,Chantilly (Manchester),ADM2,DISTRICT
SM18542,-78.1246,18.2306,Chantilly (Westmoreland),ADM2,DISTRICT
SM280442,106.1146,10.9456,Chantrea,ADM2,DISTRICT
SM228631,83.4488,28.684,Chantyal,LANGUAGE,LANGUAGE
SM296865,104.9228,16.1255,Chanuman,ADM2,DISTRICT
SM228626,-57.78,-33.69,Chaná,LANGUAGE,LANGUAGE
SM228643,-63.9865,-22.5474,Chané,DIALECT,LANGUAGE
SM228643,-63.9865,-22.5474,Chané,DIALECT,LANGUAGE
SM228643,-62.638,-18.186,Chané,DIALECT,LANGUAGE
SM228643,-62.638,-18.186,Chané,DIALECT,LANGUAGE
SM245816,6.889,35.1867,Chaouia of the Aures,LANGUAGE,LANGUAGE
SM269248,120.45,41.57,Chaoyang,ADM2,DISTRICT
SM228649,116.6333,23.6667,Chaozhou,DIALECT,LANGUAGE
SM269416,116.7694,23.838,Chaozhou,ADM2,DISTRICT
SM281899,-99.5525,19.8242,Chapa de Mota,ADM2,DISTRICT
SM282945,-89.4678,20.4863,Chapab,ADM2,DISTRICT
SM252717,-64.26,-14.27,Chapacura,ETHNICITY,ETHNICITY
SM266068,-53.0886,-28.1043,Chapada,ADM2,DISTRICT
SM263312,-45.4124,-15.5793,Chapada Gaúcha,ADM2,DISTRICT
SM267218,-47.8809,-11.5416,Chapada da Natividade,ADM2,DISTRICT
SM267219,-49.2,-10.1484,Chapada de Areia,ADM2,DISTRICT
SM263311,-42.3772,-17.1629,Chapada do Norte,ADM2,DISTRICT
SM263146,-55.5374,-15.0202,Chapada dos Guimarães,ADM2,DISTRICT
SM9905,-52.7406,-19.067,Chapadao Do Sul (2003-),ADM2,DISTRICT
SM268246,-52.5656,-18.3704,Chapadinha,ADM2,DISTRICT
SM262974,-43.463,-3.6928,Chapadão do Céu,ADM2,DISTRICT
SM266395,-49.5564,-27.5849,Chapadão do Lageado,ADM2,DISTRICT
SM13181,51.0833,50.1667,Chapaev Audany,ADM2,DISTRICT
SM9510,70.7032,34.959,Chapah Darah (2005-),ADM2,DISTRICT
SM228652,-62.2,-14.8,Chapakura,LANGUAGE,LANGUAGE
SM281778,-103.1642,20.3303,Chapala,ADM2,DISTRICT
SM260858,-63.75,-35.1667,Chapaleufú,ADM2,DISTRICT
SM281661,-99.4658,20.2653,Chapantongo,ADM2,DISTRICT
SM262707,-65.7193,-16.8092,Chapare,ADM2,DISTRICT
SM260128,70.3733,34.2908,Chaparhar,ADM2,DISTRICT
SM270553,-75.5833,3.75,Chaparral,ADM2,DISTRICT
SM268437,89.549,27.1763,Chapchha,ADM2,DISTRICT
SM266396,-52.6615,-27.1192,Chapecó,ADM2,DISTRICT
SM295755,-88.2733,13.6343,Chapeltique,ADM2,DISTRICT
SM12227,-77.2606,18.0857,Chapelton,ADM2,DISTRICT
SM293562,40,53.25,Chaplyginskiy rayon,ADM2,DISTRICT
SM300035,33.5424,46.3667,Chaplyns'kyi,ADM2,DISTRICT
SM261352,115.0279,-28.2725,Chapman Valley,ADM2,DISTRICT
SM282372,-97.4065,18.6281,Chapulco,ADM2,DISTRICT
SM281662,-98.9309,21.1441,Chapulhuacán,ADM2,DISTRICT
SM283122,-93.1333,17.3333,Chapultenango,ADM2,DISTRICT
SM281900,-99.5592,19.2011,Chapultepec,ADM2,DISTRICT
SM260249,66.6394,36.8333,Char Bolak,ADM2,DISTRICT
SM260136,62.0798,30.1679,Char Burjak,ADM2,DISTRICT
SM260092,68.5596,36.6466,Char Dara,ADM2,DISTRICT
SM228659,36.4192,6.6395,Chara,LANGUAGE,LANGUAGE
SM252724,34.97,6.05,Chara,ETHNICITY,ETHNICITY
SM252725,-65.56,-17.78,Characa,ETHNICITY,ETHNICITY
SM11639,94.7447,26.9287,Charaideo,ADM2,DISTRICT
SM270448,-73.1759,6.1751,Charalá,ADM2,DISTRICT
SM284927,7.6837,12.6015,Charanchi,ADM2,DISTRICT
SM282004,-102.2453,19.6578,Charapan,ADM2,DISTRICT
SM282476,-101.1344,23.1945,Charcas,ADM2,DISTRICT
SM262757,-65.8802,-18.3052,Charcas,ADM2,DISTRICT
SM15600,-66.9326,18.4085,Charcas Barrio,ADM2,DISTRICT
SM279879,67.25,41.5,Chardarinskiy,ADM2,DISTRICT
SM11760,46.5698,33.7591,Chardavol County,ADM2,DISTRICT
SM921,63,39,Chardzhou,ADM1,DISTRICT
SM274437,0.1816,45.706,Charente,ADM2,DISTRICT
SM274433,-0.7131,45.7556,Charente-Maritime,ADM2,DISTRICT
SM297504,103.5222,17.6333,Chareon Silp,ADM2,DISTRICT
SM252732,16.83,10.36,Chari River Groups,ETHNICITY,ETHNICITY
SM922,16.25,11.34,Chari-Baguirmi (2008-),ADM1,DISTRICT
SM301536,-92.9626,39.5151,Chariton,ADM2,DISTRICT
SM10663,33.5338,35.308,Charkeia,ADM2,DISTRICT
SM9511,68.9196,33.8159,Charkh (2005-),ADM2,DISTRICT
SM11640,76.2688,28.5936,Charki Dadri,ADM2,DISTRICT
SM260250,67.2403,36.3679,Charkint,ADM2,DISTRICT
SM301235,-77.0135,38.4737,Charles,ADM2,DISTRICT
SM302985,-77.0591,37.3543,Charles City,ADM2,DISTRICT
SM302480,-98.5879,43.2079,Charles Mix,ADM2,DISTRICT
SM261685,138.5318,-34.9007,Charles Sturt,ADM2,DISTRICT
SM12228,-76.6639,18.2053,Charles Town (Portland),ADM2,DISTRICT
SM18541,-77.0398,18.3892,Charles Town (Saint Mary),ADM2,DISTRICT
SM302433,-79.942,32.7492,Charleston,ADM2,DISTRICT
SM301257,-85.2402,45.2672,Charlevoix,ADM2,DISTRICT
SM302986,-78.6616,37.0116,Charlotte,ADM2,DISTRICT
SM300378,-81.9503,26.8999,Charlotte,ADM2,DISTRICT
SM268802,-66.8322,45.1668,Charlotte,ADM2,DISTRICT
SM923,-61.152,13.2506,Charlotte,ADM1,DISTRICT
SM303905,-64.9495,18.3396,Charlotte Amalie,ADM2,DISTRICT
SM302987,-78.4767,38.0293,Charlottesville,ADM2,DISTRICT
SM300456,-82.1377,30.7817,Charlton,ADM2,DISTRICT
SM282005,-101.0149,19.6647,Charo,ADM2,DISTRICT
SM292865,46.75,42.1667,Charodinskiy rayon,ADM2,DISTRICT
SM457445,-0.2667,29.0167,Charouine District,ADM2,DISTRICT
SM266469,-47.7377,-22.5372,Charqueada,ADM2,DISTRICT
SM266069,-51.5822,-30.0065,Charqueadas,ADM2,DISTRICT
SM266070,-51.9905,-27.9454,Charrua,ADM2,DISTRICT
SM252738,-55.09,-33.14,Charrua,ETHNICITY,ETHNICITY
SM228656,-56.16,-34.9,Charrúa,LANGUAGE,LANGUAGE
SM375433,71.6904,34.251,Charsadda,ADM2:ADM3,DISTRICT
SM270449,-73.0072,7.2449,Charta,ADM2,DISTRICT
SM261617,145.8165,-20.2093,Charters Towers,ADM2,DISTRICT
SM292959,83.6667,51.25,Charyshskiy rayon,ADM2,DISTRICT
SM301684,-101.698,40.5242,Chase,ADM2,DISTRICT
SM300977,-96.5939,38.3021,Chase,ADM2,DISTRICT
SM17283,-60.974,14.0148,Chase Gardens,ADM2,DISTRICT
SM228663,98.75,26.1,Chashan,LANGUAGE,LANGUAGE
SM262680,29.25,54.75,Chashniki,ADM2,DISTRICT
SM17284,-60.9219,13.991,Chassin/Babonneau,ADM2,DISTRICT
SM293488,67.8833,55.5667,Chastoozerskiy rayon,ADM2,DISTRICT
SM289041,126.6667,41.4167,Chasŏng,ADM2,DISTRICT
SM297370,100.6846,17.3936,Chat Trakan,ADM2,DISTRICT
SM12229,-77.1874,17.9897,Chateau,ADM2,DISTRICT
SM17285,-61.0535,13.8183,Chateau Belair,ADM2,DISTRICT
SM301941,-79.2554,35.7026,Chatham,ADM2,DISTRICT
SM300457,-81.0924,31.974,Chatham,ADM2,DISTRICT
SM924,-176.5,-44,Chatham Islands,ADM1,DISTRICT
SM268876,-82.1831,42.4001,Chatham-Kent,ADM2,DISTRICT
SM17286,-61.039,13.9184,Chatin,ADM2,DISTRICT
SM252745,-96.83,16.75,Chatino,ETHNICITY,ETHNICITY
SM280320,71.0087,41.8704,Chatkal,ADM2,DISTRICT
SM299217,31.7667,-2.6333,Chato,ADM2,DISTRICT
SM252746,-85.2,30.47,Chatot,ETHNICITY,ETHNICITY
SM276910,84.8871,24.1735,Chatra,ADM2,DISTRICT
SM300458,-84.787,32.347,Chattahoochee,ADM2,DISTRICT
SM300459,-85.3453,34.475,Chattooga,ADM2,DISTRICT
SM297777,101.8456,15.565,Chatturat,ADM2,DISTRICT
SM297149,100.5637,13.8289,Chatuchak,ADM2,DISTRICT
SM297477,103.5493,15.8267,Chaturaphak Phim,ADM2,DISTRICT
SM228669,80.4832,29.7033,Chaudangsi,LANGUAGE,LANGUAGE
SM10209,-70.8308,46.555,Chaudiere-Appalaches,ADM2,DISTRICT
SM228672,96.2457,20.0653,Chaungtha,LANGUAGE,LANGUAGE
SM292786,171,69,Chaunskiy rayon,ADM2,DISTRICT
SM228668,93.0331,8.4575,Chaura,LANGUAGE,LANGUAGE
SM301927,-79.4058,42.3029,Chautauqua,ADM2,DISTRICT
SM300884,-96.2454,37.1501,Chautauqua,ADM2,DISTRICT
SM323952,-110.1424,52.6935,Chauvin,ADM2:ADM3,DISTRICT
SM228673,122.1885,7.435,Chavacano,LANGUAGE,LANGUAGE
SM267905,-41.2372,-3.075,Chaval,ADM2,DISTRICT
SM266470,-49.7232,-23.0346,Chavantes,ADM2,DISTRICT
SM262432,24.0833,42.6167,Chavdar,ADM2,DISTRICT
SM301856,-104.467,33.3632,Chaves,ADM2,DISTRICT
SM289369,-7.4503,41.7396,Chaves,ADM2,DISTRICT
SM264153,-49.6479,-0.0629,Chaves,ADM2,DISTRICT
SM282006,-102.4391,20.0477,Chavinda,ADM2,DISTRICT
SM305194,22.3896,-13.1977,Chavuma,ADM2,DISTRICT
SM262638,30.9167,53.8333,Chavusy,ADM2,DISTRICT
SM252755,9.71,10.85,Chawai,ETHNICITY,ETHNICITY
SM252755,9.71,10.85,Chawai,ETHNICITY,ETHNICITY
SM252755,9,10,Chawai,ETHNICITY,ETHNICITY
SM252755,9,10,Chawai,ETHNICITY,ETHNICITY
SM297226,99.5111,8.4632,Chawang,ADM2,DISTRICT
SM260085,70.8665,34.74,Chawkay,ADM2,DISTRICT
SM262758,-65.8913,-18.7949,Chayanta,ADM2,DISTRICT
SM11761,45,38.8667,Chaypareh,ADM2,DISTRICT
SM228676,-97.83,16.42,Chayuco Mixtec,LANGUAGE,LANGUAGE
SM228678,-97.7873,18.1541,Chazumba Mixtec,LANGUAGE,LANGUAGE
SM269103,-69.4875,-26.3252,Chañaral,ADM2,DISTRICT
SM10089,104.9364,11.531,Chbar Ampov,ADM2,DISTRICT
SM280486,104.5066,11.4709,Chbar Mon,ADM2,DISTRICT
SM228682,8.6702,10.0031,Che,LANGUAGE,LANGUAGE
SM458106,116.4016,-27.4991,Cheangwa language,ETHNICITY,ETHNICITY
SM302546,-87.0868,36.2612,Cheatham,ADM2,DISTRICT
SM271213,12.5,50.0833,Cheb,ADM2,DISTRICT
SM16876,60.5,54.8333,Chebarkul'skiy Rayon,ADM2,DISTRICT
SM297882,11.0557,35.239,Chebba,ADM2,DISTRICT
SM298103,9.909,35.6499,Chebika,ADM2,DISTRICT
SM16877,47.25,56.0833,Cheboksarskiy Rayon,ADM2,DISTRICT
SM301258,-84.4921,45.4729,Cheboygan,ADM2,DISTRICT
SM293174,87.5,55.75,Chebulinskiy rayon,ADM2,DISTRICT
SM252759,-61.66,-38.55,Chechehet,ETHNICITY,ETHNICITY
SM299790,29.3636,48.2153,Chechel'nyts'kyi,ADM2,DISTRICT
SM228680,45.5,43.5,Chechen,LANGUAGE,LANGUAGE
SM252760,46.63,42.68,Chechen,ETHNICITY,ETHNICITY
SM252760,46.63,42.68,Chechen,ETHNICITY,ETHNICITY
SM252760,46.63,42.68,Chechen,ETHNICITY,ETHNICITY
SM252760,46,43,Chechen,ETHNICITY,ETHNICITY
SM252760,46,43,Chechen,ETHNICITY,ETHNICITY
SM252760,46,43,Chechen,ETHNICITY,ETHNICITY
SM252760,45.65,43.3333,Chechen,ETHNICITY,ETHNICITY
SM252760,45.65,43.3333,Chechen,ETHNICITY,ETHNICITY
SM252760,45.65,43.3333,Chechen,ETHNICITY,ETHNICITY
SM925,45.8333,43.25,Chechnya,ADM1,DISTRICT
SM4457,-5.0833,35,Chefachouen (2009-),ADM2,DISTRICT
SM16878,43.25,43.4167,Chegemskiy Rayon (excl. Nalchik Okrug),ADM2,DISTRICT
SM305253,30.4005,-18.131,Chegutu,ADM2,DISTRICT
SM252762,-123,46.78,Chehalis,ETHNICITY,ETHNICITY
SM228683,159.625,-8.2706,Cheke Holo,LANGUAGE,LANGUAGE
SM293677,37.4167,55.1667,Chekhovskiy rayon,ADM2,DISTRICT
SM294249,54.75,55.0833,Chekmagushevskiy rayon,ADM2,DISTRICT
SM18381,66.9,39.9,Chelak Tumani,ADM2,DISTRICT
SM303102,-120.6189,47.8691,Chelan,ADM2,DISTRICT
SM457286,6.1667,36.1667,Chelghoum Laïd District,ADM2,DISTRICT
SM461514,86.97,52.33,Chelkans,ETHNICITY,ETHNICITY
SM262433,24.0667,42.7167,Chelopech,ADM2,DISTRICT
SM926,60.5,54,Chelyabinsk,ADM1,DISTRICT
SM292781,61.4167,55.1655,Chelyabinsk gorsovet,ADM2,DISTRICT
SM292866,86.25,50.3333,Chemal'skiy rayon,ADM2,DISTRICT
SM282946,-87.8794,20.7249,Chemax,ADM2,DISTRICT
SM299209,35.6914,-5.3377,Chemba,ADM2,DISTRICT
SM283971,34.8878,-17.1647,Chemba,ADM2,DISTRICT
SM18120,28.6545,-11.7065,Chembe District,ADM2,DISTRICT
SM228686,-116.4384,35.3561,Chemehuevi,DIALECT:LANGUAGE,LANGUAGE
SM252767,-114.3,34.15,Chemehuevi,ETHNICITY,ETHNICITY
SM252767,-114.3,34.15,Chemehuevi,ETHNICITY,ETHNICITY
SM252767,-114.3,34.15,Chemehuevi,ETHNICITY,ETHNICITY
SM252767,-115,35,Chemehuevi,ETHNICITY,ETHNICITY
SM252767,-115,35,Chemehuevi,ETHNICITY,ETHNICITY
SM252767,-115,35,Chemehuevi,ETHNICITY,ETHNICITY
SM252767,-116,34.3,Chemehuevi,ETHNICITY,ETHNICITY
SM252767,-116,34.3,Chemehuevi,ETHNICITY,ETHNICITY
SM252767,-116,34.3,Chemehuevi,ETHNICITY,ETHNICITY
SM252768,-123.09,47.9,Chemekum,ETHNICITY,ETHNICITY
SM299456,26.3402,49.0115,Chemerovets'kyi,ADM2,DISTRICT
SM301928,-76.76,42.1413,Chemung,ADM2,DISTRICT
SM14247,-92.6333,16.8833,Chenalho (1990-),ADM2,DISTRICT
SM301929,-75.6116,42.4935,Chenango,ADM2,DISTRICT
SM228690,142.317,-4.2515,Chenapian,LANGUAGE,LANGUAGE
SM277606,59.15,36.6667,Chenaran,ADM2,DISTRICT
SM228687,79.2534,15.8787,Chenchu,LANGUAGE,LANGUAGE
SM252770,79,16,Chenchu,ETHNICITY,ETHNICITY
SM252770,79,16,Chenchu,ETHNICITY,ETHNICITY
SM252770,78.97,16.25,Chenchu,ETHNICITY,ETHNICITY
SM252770,78.97,16.25,Chenchu,ETHNICITY,ETHNICITY
SM269140,117.93,40.97,Chengde,ADM2,DISTRICT
SM269331,103.8706,30.7598,Chengdu,ADM2,DISTRICT
SM228693,103.971,30.6058,Chengdu Mandarin,DIALECT,LANGUAGE
SM277229,80.2648,13.0889,Chennai,ADM2,DISTRICT
SM269202,113.0538,25.8435,Chenzhou,ADM2,DISTRICT
SM280662,127.1943,36.8049,Cheonan,ADM2,DISTRICT
SM280749,128.7851,35.6717,Cheongdo,ADM2,DISTRICT
SM280645,127.4678,36.6354,Cheongju,ADM2,DISTRICT
SM280750,129.0516,36.4329,Cheongsong,ADM2,DISTRICT
SM280663,126.7942,36.4168,Cheongyang,ADM2,DISTRICT
SM280681,127.4452,38.2439,Cheorwon,ADM2,DISTRICT
SM228699,84.9017,27.7206,Chepang,LANGUAGE,LANGUAGE
SM252775,152.83,-27.39,Chepara,ETHNICITY,ETHNICITY
SM252775,152.83,-27.39,Chepara,ETHNICITY,ETHNICITY
SM252775,152.9813,-28.0945,Chepara,ETHNICITY,ETHNICITY
SM252775,152.9813,-28.0945,Chepara,ETHNICITY,ETHNICITY
SM262409,24.6833,41.8,Chepelare,ADM2,DISTRICT
SM286702,-77.8383,7.8437,Chepigana,ADM2,DISTRICT
SM286660,-78.7037,9.1739,Chepo,ADM2,DISTRICT
SM228697,101.7501,22.1749,Chepya,LANGUAGE,LANGUAGE
SM286758,-79.4567,-7.1437,Chepén,ADM2,DISTRICT
SM252776,102.49,3.66,Cheq Wong,ETHNICITY,ETHNICITY
SM274481,2.5098,47.113,Cher,ADM2,DISTRICT
SM261618,151.9328,-26.2875,Cherbourg,ADM2,DISTRICT
SM457453,2,36.5,Cherchell District,ADM2,DISTRICT
SM294872,48.8333,54.25,Cherdaklinskiy rayon,ADM2,DISTRICT
SM294081,56,60.5,Cherdynskiy rayon,ADM2,DISTRICT
SM292414,22.1355,47.3993,Cherechiu,ADM2,DISTRICT
SM293029,43.5,43.25,Cherekskiy rayon,ADM2,DISTRICT
SM293517,37.1667,51.9167,Cheremisinovskiy rayon,ADM2,DISTRICT
SM16879,102.3333,52.6667,Cheremkhovskiy Rayon,ADM2,DISTRICT
SM294610,51.5,54.75,Cheremshanskiy rayon,ADM2,DISTRICT
SM293888,83.25,54.25,Cherepanovskiy rayon,ADM2,DISTRICT
SM228701,-0.1096,6.0539,Cherepon,LANGUAGE,LANGUAGE
SM4363,0.2881,10.1342,Chereponi,ADM2,DISTRICT
SM294964,37.9167,59.3333,Cherepovetskiy rayon,ADM2,DISTRICT
SM283972,35.3183,-18.5327,Cheringoma,ADM2,DISTRICT
SM927,32.0607,49.4452,Cherkasy,ADM1,DISTRICT
SM18191,32.0695,49.4176,Cherkasy Raion,ADM2,DISTRICT
SM293922,75.25,54.25,Cherlakskiy rayon,ADM2,DISTRICT
SM299864,28.6667,50.4167,Cherniakhivs'kyi,ADM2,DISTRICT
SM294110,132.5833,44.3333,Chernigovskiy rayon,ADM2,DISTRICT
SM928,32,51.3333,Chernihiv,ADM1,DISTRICT
SM18192,31.3333,51.5833,Chernihiv Raion (2020-),ADM2,DISTRICT
SM299702,31.2881,51.4992,Chernihivs'ka,ADM2,DISTRICT
SM299834,36.2173,47.1924,Chernihivs'kyi,ADM2,DISTRICT
SM18194,25.8985,48.2217,Chernivestkyi Raion,ADM2,DISTRICT
SM299868,25.9321,48.2907,Chernivets'ka (2020-),ADM2,DISTRICT
SM929,25.9326,48.2907,Chernivtsi,ADM1,DISTRICT
SM18195,28.1249,48.5411,Chernivtsi Raion (1990-2020),ADM2,DISTRICT
SM16880,91.2703,53.8217,Chernogorsk Gorod,ADM2,DISTRICT
SM262527,25.25,41.7833,Chernoochene,ADM2,DISTRICT
SM293849,45.75,48.0833,Chernoyarskiy rayon,ADM2,DISTRICT
SM293053,46,45.5,Chernozermel'skiy rayon,ADM2,DISTRICT
SM16881,21.75,54.6667,Chernyakhovskiy Rayon,ADM2,DISTRICT
SM294697,37.9167,51,Chernyanskiy rayon,ADM2,DISTRICT
SM295109,117.1667,52.8333,Chernyshevskiy rayon,ADM2,DISTRICT
SM294923,42.3333,48.25,Chernyshkovskiy rayon,ADM2,DISTRICT
SM228703,-83.163,35.4664,Cherokee,LANGUAGE,LANGUAGE
SM302824,-95.1652,31.837,Cherokee,ADM2,DISTRICT
SM302434,-81.6204,35.0482,Cherokee,ADM2,DISTRICT
SM302174,-94.9997,35.9066,Cherokee,ADM2,DISTRICT
SM301943,-84.0635,35.1338,Cherokee,ADM2,DISTRICT
SM300891,-94.8463,37.1693,Cherokee,ADM2,DISTRICT
SM300793,-95.6238,42.7356,Cherokee,ADM2,DISTRICT
SM300460,-84.4762,34.2439,Cherokee,ADM2,DISTRICT
SM300247,-85.6038,34.176,Cherokee,ADM2,DISTRICT
SM252779,-83.2,36.84,Cherokee,ETHNICITY,ETHNICITY
SM252779,-83.2,36.84,Cherokee,ETHNICITY,ETHNICITY
SM252779,-83.2,36.84,Cherokee,ETHNICITY,ETHNICITY
SM252779,-83.5,35.5,Cherokee,ETHNICITY,ETHNICITY
SM252779,-83.5,35.5,Cherokee,ETHNICITY,ETHNICITY
SM252779,-83.5,35.5,Cherokee,ETHNICITY,ETHNICITY
SM252779,-83,36,Cherokee,ETHNICITY,ETHNICITY
SM252779,-83,36,Cherokee,ETHNICITY,ETHNICITY
SM252779,-83,36,Cherokee,ETHNICITY,ETHNICITY
SM301685,-101.1186,42.5449,Cherry,ADM2,DISTRICT
SM12230,-76.7787,18.0496,Cherry Gardens,ADM2,DISTRICT
SM262333,24.1333,43.3333,Cherven Bryag,ADM2,DISTRICT
SM299865,28.25,50.5,Chervonoarmiis'kyi,ADM2,DISTRICT
SM299547,24.2265,50.3842,Chervonohrads'ka,ADM2,DISTRICT
SM18198,24.3824,50.3533,Chervonohradskyi Raion (2020-),ADM2,DISTRICT
SM262658,28.5,53.75,Chervyen',ADM2,DISTRICT
SM262639,31.3333,53.6667,Cherykaw,ADM2,DISTRICT
SM282007,-101.997,19.7041,Cherán,ADM2,DISTRICT
SM302988,-76.3122,36.6877,Chesapeake,ADM2,DISTRICT
SM301806,-72.2512,42.9193,Cheshire,ADM2,DISTRICT
SM274612,-2.3625,53.167,Cheshire East,ADM1:ADM2,DISTRICT
SM274613,-2.736,53.1635,Cheshire West and Chester,ADM1:ADM2,DISTRICT
SM292782,60.5,53.6667,Chesmenskiy rayon,ADM2,DISTRICT
SM302291,-75.7485,39.9731,Chester,ADM2,DISTRICT
SM302547,-88.6135,35.4218,Chester,ADM2,DISTRICT
SM302435,-81.1595,34.6921,Chester,ADM2,DISTRICT
SM12231,-77.265,18.4479,Chester,ADM2,DISTRICT
SM12232,-77.9472,18.3382,Chester Castle,ADM2,DISTRICT
SM302989,-77.5869,37.3785,Chesterfield,ADM2,DISTRICT
SM302436,-80.1587,34.6398,Chesterfield,ADM2,DISTRICT
SM323919,-113.8244,51.0337,Chestermere,ADM2:ADM3,DISTRICT
SM228704,101.98,24.05,Chesu,LANGUAGE,LANGUAGE
SM291128,23.9979,46.4985,Chetani,ADM2,DISTRICT
SM228706,-124.376,42.6864,Chetco,LANGUAGE,LANGUAGE
SM252782,-124.03,42.07,Chetco,ETHNICITY,ETHNICITY
SM252782,-124.03,42.07,Chetco,ETHNICITY,ETHNICITY
SM252782,-124.2,42.1,Chetco,ETHNICITY,ETHNICITY
SM252782,-124.2,42.1,Chetco,ETHNICITY,ETHNICITY
SM280469,105.07,12.1,Cheung Prey,ADM2,DISTRICT
SM291963,21.4789,45.6623,Cheveresu Mare,ADM2,DISTRICT
SM250160,33.32,-12.5,Chewa,ETHNICITY,ETHNICITY
SM250160,33.32,-12.5,Chewa,ETHNICITY,ETHNICITY
SM250160,33.32,-12.5,Chewa,ETHNICITY,ETHNICITY
SM250160,32.7,-14.25,Chewa,ETHNICITY,ETHNICITY
SM250160,32.7,-14.25,Chewa,ETHNICITY,ETHNICITY
SM250160,32.7,-14.25,Chewa,ETHNICITY,ETHNICITY
SM250160,33,-14,Chewa,ETHNICITY,ETHNICITY
SM250160,33,-14,Chewa,ETHNICITY,ETHNICITY
SM250160,33,-14,Chewa,ETHNICITY,ETHNICITY
SM250160,33.5977,-13.7035,Chewa,ETHNICITY,ETHNICITY
SM250160,33.5977,-13.7035,Chewa,ETHNICITY,ETHNICITY
SM250160,33.5977,-13.7035,Chewa,ETHNICITY,ETHNICITY
SM228707,102.081,3.8333,Chewong,LANGUAGE,LANGUAGE
SM280387,105.3453,13.5822,Chey Saen,ADM2,DISTRICT
SM228709,-106.659,45.5321,Cheyenne,LANGUAGE,LANGUAGE
SM301686,-102.995,41.2198,Cheyenne,ADM2,DISTRICT
SM300892,-101.7311,39.7859,Cheyenne,ADM2,DISTRICT
SM303319,-102.6034,38.8279,Cheyenne,ADM2,DISTRICT
SM252785,-96.75,46.24,Cheyenne,ETHNICITY,ETHNICITY
SM252785,-96.75,46.24,Cheyenne,ETHNICITY,ETHNICITY
SM252785,-96.75,46.24,Cheyenne,ETHNICITY,ETHNICITY
SM252785,-101.12,45.12,Cheyenne,ETHNICITY,ETHNICITY
SM252785,-101.12,45.12,Cheyenne,ETHNICITY,ETHNICITY
SM252785,-101.12,45.12,Cheyenne,ETHNICITY,ETHNICITY
SM252785,-104,39,Cheyenne,ETHNICITY,ETHNICITY
SM252785,-104,39,Cheyenne,ETHNICITY,ETHNICITY
SM252785,-104,39,Cheyenne,ETHNICITY,ETHNICITY
SM252785,-102.35,38.83,Cheyenne,ETHNICITY,ETHNICITY
SM252785,-102.35,38.83,Cheyenne,ETHNICITY,ETHNICITY
SM252785,-102.35,38.83,Cheyenne,ETHNICITY,ETHNICITY
SM288910,23.3817,51.1509,Chełm,ADM2,DISTRICT
SM288909,23.4897,51.1356,Chełm (City),ADM2,DISTRICT
SM288862,18.504,53.3006,Chełmno,ADM2,DISTRICT
SM280388,105.4618,13.9108,Chhaeb,ADM2,DISTRICT
SM276970,79.75,24.75,Chhatarpur,ADM2,DISTRICT
SM930,82,21.5,Chhattisgarh,ADM1,DISTRICT
SM228711,82.0483,20.3601,Chhattisgarhi,LANGUAGE,LANGUAGE
SM276971,78.8,22.1167,Chhindwara,ADM2,DISTRICT
SM280358,106.1156,12.1772,Chhloung,ADM2,DISTRICT
SM276843,74.0152,22.3133,Chhota Udaipur,ADM2,DISTRICT
SM280502,104.45,10.8167,Chhuk,ADM2,DISTRICT
SM931,89.55,27,Chhukha,ADM1,DISTRICT
SM228718,87.2133,26.9107,Chhulung,LANGUAGE,LANGUAGE
SM280431,104.3715,13.2077,Chi Kraeng,ADM2,DISTRICT
SM304250,106.6293,21.676,Chi Lăng,ADM2,DISTRICT
SM263315,-43.0074,-21.9725,Chiador,ADM2,DISTRICT
SM290984,25.9935,44.455,Chiajna,ADM2,DISTRICT
SM297227,100.1582,8.1172,Chian Yai,ADM2,DISTRICT
SM296883,98.9418,19.5128,Chiang Dao,ADM2,DISTRICT
SM297328,100.3387,19.4706,Chiang Kham,ADM2,DISTRICT
SM297037,101.7342,17.8244,Chiang Khan,ADM2,DISTRICT
SM296896,100.3653,20.1359,Chiang Khong,ADM2,DISTRICT
SM297241,100.8788,19.3064,Chiang Klang,ADM2,DISTRICT
SM932,99,18.75,Chiang Mai,ADM1,DISTRICT
SM297329,100.3179,18.9038,Chiang Muan,ADM2,DISTRICT
SM933,99.9167,19.9167,Chiang Rai,ADM1,DISTRICT
SM296906,100.1554,20.2357,Chiang Saen,ADM2,DISTRICT
SM297074,103.0701,16.4094,Chiang Yun,ADM2,DISTRICT
SM228719,99.039,18.8629,Chiangmai Sign Language,LANGUAGE,LANGUAGE
SM283125,-93.0085,16.702,Chiapa de Corzo,ADM2,DISTRICT
SM228722,-91.9417,16.6562,Chiapanec,LANGUAGE,LANGUAGE
SM934,-92.5,16.5,Chiapas,ADM1,DISTRICT
SM266071,-53.9205,-27.9763,Chiapeta,ADM2,DISTRICT
SM283126,-92.7259,16.554,Chiapilla,ADM2,DISTRICT
SM274797,43.25,42.2833,Chiatura,ADM2,DISTRICT
SM14248,-98.1495,19.2898,Chiautempan (1995-),ADM2,DISTRICT
SM282373,-98.6021,18.2878,Chiautla,ADM2,DISTRICT
SM281901,-98.8806,19.55,Chiautla,ADM2,DISTRICT
SM282374,-98.4883,19.1993,Chiautzingo,ADM2,DISTRICT
SM299065,120.4453,23.4772,Chiayi City,ADM2,DISTRICT
SM299066,120.5817,23.4633,Chiayi County,ADM2,DISTRICT
SM279368,140.165,35.606,Chiba,ADM2,DISTRICT
SM935,140.1233,35.6051,Chiba,ADM1:ADMX,DISTRICT
SM283973,33.531,-20.292,Chibabava,ADM2,DISTRICT
SM305770,47,31,Chibayish,ADM2,DISTRICT
SM228727,-74.1666,6.25,Chibcha,LANGUAGE,LANGUAGE
SM260315,13.8847,-15.2366,Chibia,ADM2,DISTRICT
SM285385,12.7705,10.8364,Chibok,ADM2,DISTRICT
SM305148,28.0738,-14.6581,Chibombo (pre-2013),ADM2,DISTRICT
SM283895,33.5983,-24.2885,Chibuto,ADM2,DISTRICT
SM275351,-91.3006,14.5338,Chicacao,ADM2,DISTRICT
SM228734,-97.8547,17.1628,Chicahuaxtla Triqui,LANGUAGE,LANGUAGE
SM260859,-67.5833,-36.4167,Chical Có,ADM2,DISTRICT
SM275248,-90.7994,15.3483,Chicaman,ADM2,DISTRICT
SM454415,-110,34,Chicano,ETHNICITY,ETHNICITY
SM252800,-64.62,-18.53,Chicha,ETHNICITY,ETHNICITY
SM281456,-8.8441,31.2637,Chichaoua,ADM2,DISTRICT
SM279175,138.9335,35.9597,Chichibu,ADM2,DISTRICT
SM12880,138.9208,36.0292,Chichibu-gun (excl. Chichibu-shi),ADM2,DISTRICT
SM228735,-96.5486,16.8459,Chichicapan Zapotec,LANGUAGE,LANGUAGE
SM275250,-91.0881,14.892,Chichicastenango,ADM2,DISTRICT
SM285509,-87.0417,12.5456,Chichigalpa,ADM2,DISTRICT
SM228733,-100.524,21.2943,Chichimeca-Jonaz,LANGUAGE,LANGUAGE
SM252801,-100,22,Chichimeca-Jonaz,ETHNICITY,ETHNICITY
SM282947,-88.1804,20.4664,Chichimilá,ADM2,DISTRICT
SM282375,-97.0711,19.2065,Chichiquila,ADM2,DISTRICT
SM290210,25.8138,45.7678,Chichis,ADM2,DISTRICT
SM275249,-91.0384,15.012,Chiché,ADM2,DISTRICT
SM228731,-88,34,Chickasaw,LANGUAGE,LANGUAGE
SM301507,-88.9479,33.9208,Chickasaw,ADM2,DISTRICT
SM300794,-92.3177,43.06,Chickasaw,ADM2,DISTRICT
SM252802,-88.85,35.01,Chickasaw,ETHNICITY,ETHNICITY
SM252802,-88.85,35.01,Chickasaw,ETHNICITY,ETHNICITY
SM252802,-85,35,Chickasaw,ETHNICITY,ETHNICITY
SM252802,-85,35,Chickasaw,ETHNICITY,ETHNICITY
SM286765,-79.85,-6.7667,Chiclayo,ADM2,DISTRICT
SM261075,-65.5833,-27.3333,Chicligasta,ADM2,DISTRICT
SM260963,-65.5947,-25.1158,Chicoana,ADM2,DISTRICT
SM283127,-93.1047,16.9653,Chicoasén,ADM2,DISTRICT
SM281902,-98.9026,19.4167,Chicoloapan,ADM2,DISTRICT
SM260316,14.9867,-14.3079,Chicomba,ADM2,DISTRICT
SM228732,-91.2869,15.6078,Chicomuceltec,LANGUAGE,LANGUAGE
SM283128,-92.2667,15.7667,Chicomuselo,ADM2,DISTRICT
SM282862,-98.4543,21.2433,Chiconamel,ADM2,DISTRICT
SM281903,-98.8966,19.5595,Chiconcuac,ADM2,DISTRICT
SM282376,-97.9702,20.0896,Chiconcuautla,ADM2,DISTRICT
SM936,45.1141,-12.8307,Chiconi,ADM1,DISTRICT
SM282863,-96.7775,19.7518,Chiconquiaco,ADM2,DISTRICT
SM282864,-98.0625,20.9775,Chicontepec,ADM2,DISTRICT
SM302426,-91.294,33.2673,Chicot,ADM2,DISTRICT
SM283896,32.1098,-22.7652,Chicualacuala,ADM2,DISTRICT
SM282948,-89.5133,21.1453,Chicxulub Pueblo,ADM2,DISTRICT
SM305178,29.0795,-8.7737,Chiengi,ADM2,DISTRICT
SM937,12.4167,43.9167,Chiesanuova,ADM1,DISTRICT
SM291593,22.893,47.365,Chiesd,ADM2,DISTRICT
SM277859,14.35,42.1167,Chieti,ADM2,DISTRICT
SM282377,-98.6245,18.505,Chietla,ADM2,DISTRICT
SM269261,119.1157,43.6118,Chifeng,ADM2,DISTRICT
SM283870,32.8333,-14.8179,Chifunde,ADM2,DISTRICT
SM228737,29.9122,-1.0439,Chiga,LANGUAGE,LANGUAGE
SM278553,139.4096,35.3443,Chigasaki,ADM2,DISTRICT
SM282378,-98.0505,18.651,Chigmecatitlán,ADM2,DISTRICT
SM228738,-98.1046,18.6303,Chigmecatitlán Mixtec,LANGUAGE,LANGUAGE
SM282379,-98.1267,19.8266,Chignahuapan,ADM2,DISTRICT
SM282380,-97.4034,19.7918,Chignautla,ADM2,DISTRICT
SM270198,-76.6322,7.6338,Chigorodó,ADM2,DISTRICT
SM283897,33.2971,-22.945,Chigubo,ADM2,DISTRICT
SM291130,24.8981,46.6965,Chiheru De Jos,ADM2,DISTRICT
SM283216,-106.1988,28.8267,Chihuahua,ADM2,DISTRICT
SM938,-106.25,28.75,Chihuahua,ADM1,DISTRICT
SM12881,138.2055,36.1976,Chiisakata-gun,ADM2,DISTRICT
SM18121,28.2397,-16.0357,Chikankata District,ADM2,DISTRICT
SM276952,77.87,13.55,Chikballapura,ADM2,DISTRICT
SM282949,-88.5471,20.2602,Chikindzonot,ADM2,DISTRICT
SM276924,75.73,13.49,Chikmagalur,ADM2,DISTRICT
SM305244,31.0975,-18.8885,Chikomba,ADM2,DISTRICT
SM279705,130.4912,33.2075,Chikugo,ADM2,DISTRICT
SM12882,131.0425,33.5862,Chikujo-gun,ADM2,DISTRICT
SM278864,138.1235,36.5062,Chikuma,ADM2,DISTRICT
SM284820,7.274,10.315,Chikun,ADM2,DISTRICT
SM235467,30.2804,-15.7337,Chikunda,LANGUAGE,LANGUAGE
SM251128,32.81,-13.27,Chikunda,ETHNICITY,ETHNICITY
SM251128,32.81,-13.27,Chikunda,ETHNICITY,ETHNICITY
SM251128,32,-15,Chikunda,ETHNICITY,ETHNICITY
SM251128,32,-15,Chikunda,ETHNICITY,ETHNICITY
SM278389,139.9869,36.2894,Chikusei,ADM2,DISTRICT
SM279724,130.5333,33.4833,Chikushino,ADM2,DISTRICT
SM939,34.75,-16.1667,Chikwawa,ADM1:ADM2,DISTRICT
SM282382,-97.8748,17.9675,Chila,ADM2,DISTRICT
SM282383,-98.4636,18.1113,Chila de la Sal,ADM2,DISTRICT
SM295737,-88.1451,13.7238,Chilanga,ADM2,DISTRICT
SM18122,28.0778,-15.4513,Chilanga District,ADM2,DISTRICT
SM14249,-99.1163,17.515,Chilapa de Alvarez (2003-),ADM2,DISTRICT
SM282008,-102.0848,19.8419,Chilchota,ADM2,DISTRICT
SM282384,-97.1992,19.2692,Chilchotla,ADM2,DISTRICT
SM228754,-123.2446,51.9895,Chilcotin,LANGUAGE,LANGUAGE
SM252803,-124.66,51.73,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-124.66,51.73,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-124.66,51.73,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-124.66,51.73,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-122,52,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-122,52,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-122,52,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-122,52,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-124,51.68,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-124,51.68,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-124,51.68,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-124,51.68,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-125,51.7,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-125,51.7,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-125,51.7,Chilcotin,ETHNICITY,ETHNICITY
SM252803,-125,51.7,Chilcotin,ETHNICITY,ETHNICITY
SM281663,-99.2343,20.3417,Chilcuautla,ADM2,DISTRICT
SM302825,-100.2076,34.5292,Childress,ADM2,DISTRICT
SM228757,-67.7604,-23.7036,Chilean Quechua,LANGUAGE,LANGUAGE
SM228748,-70.6698,-32.781,Chilean Sign Language,LANGUAGE,LANGUAGE
SM260875,-67.5,-29.4167,Chilecito,ADM2,DISTRICT
SM280751,128.4614,36.0151,Chilgok,ADM2,DISTRICT
SM292038,29.2151,45.378,Chilia Veche,ADM2,DISTRICT
SM289864,26.5944,45.4478,Chiliile,ADM2,DISTRICT
SM305161,27.7784,-12.3427,Chililabombwe,ADM2,DISTRICT
SM228749,73.3344,35.2494,Chilisso,LANGUAGE,LANGUAGE
SM252806,-134.66,58.6,Chilkat,ETHNICITY,ETHNICITY
SM273623,-79.6286,-3.4365,Chilla,ADM2,DISTRICT
SM273548,-79.0667,-1.9333,Chillanes,ADM2,DISTRICT
SM252807,-73.84,-42.18,Chiloe Araucanians,ETHNICITY,ETHNICITY
SM18382,69.2192,41.2797,Chilonzor Tumani,ADM2,DISTRICT
SM269115,-73.9606,-42.5747,Chiloé,ADM2,DISTRICT
SM281588,-99.5982,17.3994,Chilpancingo de los Bravo,ADM2,DISTRICT
SM228756,-96.2183,18.0606,Chiltepec Chinantec,LANGUAGE,LANGUAGE
SM295667,-89.4622,13.5643,Chiltiupán,ADM2,DISTRICT
SM300248,-86.7188,32.8479,Chilton,ADM2,DISTRICT
SM305201,30.263,-11.0381,Chilubi,ADM2,DISTRICT
SM252808,-123.3,41.31,Chilula,ETHNICITY,ETHNICITY
SM283129,-92.2833,17.1167,Chilón,ADM2,DISTRICT
SM228768,-123.079,47.632,Chimakuan,FAMILY,LANGUAGE
SM228767,-123.1392,47.9146,Chimakum,LANGUAGE,LANGUAGE
SM228763,-94.6913,16.6543,Chimalapa Zoque,LANGUAGE,LANGUAGE
SM281904,-98.9494,19.4212,Chimalhuacán,ADM2,DISTRICT
SM275397,-90.8284,14.6844,Chimaltenango,ADM2,DISTRICT
SM940,-90.9167,14.6667,Chimaltenango,ADM1,DISTRICT
SM281779,-103.6946,21.7482,Chimaltitán,ADM2,DISTRICT
SM252810,-66.56,-14.69,Chimane,ETHNICITY,ETHNICITY
SM251041,34.25,-17.56,Chimanica,ETHNICITY,ETHNICITY
SM251041,33.75,-20.87,Chimanica,ETHNICITY,ETHNICITY
SM305231,32.7334,-19.783,Chimanimani,ADM2,DISTRICT
SM228764,-123,41,Chimariko,LANGUAGE,LANGUAGE
SM252811,-122.29,40.41,Chimariko,ETHNICITY,ETHNICITY
SM252811,-122.29,40.41,Chimariko,ETHNICITY,ETHNICITY
SM252811,-122.29,40.41,Chimariko,ETHNICITY,ETHNICITY
SM252811,-122.29,40.41,Chimariko,ETHNICITY,ETHNICITY
SM252811,-123,41,Chimariko,ETHNICITY,ETHNICITY
SM252811,-123,41,Chimariko,ETHNICITY,ETHNICITY
SM252811,-123,41,Chimariko,ETHNICITY,ETHNICITY
SM252811,-123,41,Chimariko,ETHNICITY,ETHNICITY
SM252811,-123.3,40.85,Chimariko,ETHNICITY,ETHNICITY
SM252811,-123.3,40.85,Chimariko,ETHNICITY,ETHNICITY
SM252811,-123.3,40.85,Chimariko,ETHNICITY,ETHNICITY
SM252811,-123.3,40.85,Chimariko,ETHNICITY,ETHNICITY
SM252811,-123.2,40.7,Chimariko,ETHNICITY,ETHNICITY
SM252811,-123.2,40.7,Chimariko,ETHNICITY,ETHNICITY
SM252811,-123.2,40.7,Chimariko,ETHNICITY,ETHNICITY
SM252811,-123.2,40.7,Chimariko,ETHNICITY,ETHNICITY
SM260981,-68.5269,-31.4874,Chimbas,ADM2,DISTRICT
SM273549,-79.0253,-1.6844,Chimbo,ADM2,DISTRICT
SM941,-78.75,-1.9167,Chimborazo,ADM1,DISTRICT
SM18383,59.6744,43.0096,Chimboy Tumani (2019-),ADM2,DISTRICT
SM942,144.8333,-6.25,Chimbu,ADM1,DISTRICT
SM269908,-73.8075,9.2225,Chimichagua,ADM2,DISTRICT
SM228766,-74.2559,10.1902,Chimila,LANGUAGE,LANGUAGE
SM260251,66.6009,36.5178,Chimtal,ADM2,DISTRICT
SM228769,44.027,1.1118,Chimwiini,LANGUAGE,LANGUAGE
SM270450,-73.4253,6.3629,Chimá,ADM2,DISTRICT
SM269952,-75.651,9.1147,Chimá,ADM2,DISTRICT
SM286661,-78.5647,8.7604,Chimán,ADM2,DISTRICT
SM943,93.5,22,Chin,ADM1,DISTRICT
SM252812,94.18,19.45,Chin,ETHNICITY,ETHNICITY
SM252812,94.18,19.45,Chin,ETHNICITY,ETHNICITY
SM252812,94,22,Chin,ETHNICITY,ETHNICITY
SM252812,94,22,Chin,ETHNICITY,ETHNICITY
SM282153,-99.0046,25.5122,China,ADM2,DISTRICT
SM47,105,35,China (1950-),ADM0,DISTRICT
SM228777,117.786,48.7593,China Buriat,LANGUAGE,LANGUAGE
SM459231,145.2995,-16.0475,China Camp,ETHNICITY,ETHNICITY
SM275692,-87.954,14.1829,Chinacla,ADM2,DISTRICT
SM228776,76.961,32.555,Chinali,LANGUAGE,LANGUAGE
SM282865,-94.7087,18.0611,Chinameca,ADM2,DISTRICT
SM295757,-88.3333,13.5,Chinameca,ADM2,DISTRICT
SM282867,-97.6684,21.3707,Chinampa de Gorostiza,ADM2,DISTRICT
SM285513,-87.0437,12.7212,Chinandega,ADM2,DISTRICT
SM944,-87.0833,12.8333,Chinandega,ADM1,DISTRICT
SM252813,-96,18,Chinantec,ETHNICITY,ETHNICITY
SM282385,-98.2763,18.2342,Chinantla,ADM2,DISTRICT
SM275448,-90.4957,14.7306,Chinautla,ADM2,DISTRICT
SM270747,-73.3715,5.1792,Chinavita,ADM2,DISTRICT
SM228779,94.0506,20.0776,Chinbon Chin,LANGUAGE,LANGUAGE
SM286739,-75.9393,-13.3179,Chincha,ADM2,DISTRICT
SM252814,-75.74,-13.8,Chincha,ETHNICITY,ETHNICITY
SM228784,-75.649,-12.85,Chincha Quechua,LANGUAGE,LANGUAGE
SM252815,-76.39,-11.04,Chinchacha,ETHNICITY,ETHNICITY
SM286860,-73.6716,-13.4701,Chincheros,ADM2,DISTRICT
SM270859,-75.7042,4.9752,Chinchiná,ADM2,DISTRICT
SM273576,-79.1333,-4.8667,Chinchipe,ADM2,DISTRICT
SM275788,-88.2,15.1167,Chinda,ADM2,DISTRICT
SM454411,-122.45,37.75,Chinese Americans,ETHNICITY,ETHNICITY
SM454412,-79.417,43.7,Chinese Canadians,ETHNICITY,ETHNICITY
SM228770,166.926,-0.5322,Chinese Pidgin English,LANGUAGE,LANGUAGE
SM228772,121.569,30.9063,Chinese Sign Language,LANGUAGE,LANGUAGE
SM252821,135.64,-18.38,Chingali,ETHNICITY,ETHNICITY
SM252821,135.64,-18.38,Chingali,ETHNICITY,ETHNICITY
SM252821,133.7015,-17.3508,Chingali,ETHNICITY,ETHNICITY
SM252821,133.7015,-17.3508,Chingali,ETHNICITY,ETHNICITY
SM279891,54,50.6667,Chingirlauskiy,ADM2,DISTRICT
SM305163,27.6631,-12.4848,Chingola,ADM2,DISTRICT
SM260406,16.5283,-12.5414,Chinguar,ADM2,DISTRICT
SM14189,-12.3928,20.5949,Chinguetti (excl. Chinguetti),ADM2,DISTRICT
SM225158,144.821,-4.8869,Chini,LANGUAGE,LANGUAGE
SM282009,-103.3903,18.7223,Chinicuila,ADM2,DISTRICT
SM4947,72.98,31.72,Chiniot,ADM2:ADM3,DISTRICT
SM275251,-91.018,15.0695,Chinique,ADM2,DISTRICT
SM278868,138.2467,36.0252,Chino,ADM2,DISTRICT
SM252823,-123.68,46.39,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.68,46.39,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.68,46.39,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.68,46.39,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.68,46.39,Chinook,ETHNICITY,ETHNICITY
SM252823,123.77,45.99,Chinook,ETHNICITY,ETHNICITY
SM252823,123.77,45.99,Chinook,ETHNICITY,ETHNICITY
SM252823,123.77,45.99,Chinook,ETHNICITY,ETHNICITY
SM252823,123.77,45.99,Chinook,ETHNICITY,ETHNICITY
SM252823,123.77,45.99,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.77,45.99,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.77,45.99,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.77,45.99,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.77,45.99,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.77,45.99,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.75,46.06,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.75,46.06,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.75,46.06,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.75,46.06,Chinook,ETHNICITY,ETHNICITY
SM252823,-123.75,46.06,Chinook,ETHNICITY,ETHNICITY
SM252823,-124,46.35,Chinook,ETHNICITY,ETHNICITY
SM252823,-124,46.35,Chinook,ETHNICITY,ETHNICITY
SM252823,-124,46.35,Chinook,ETHNICITY,ETHNICITY
SM252823,-124,46.35,Chinook,ETHNICITY,ETHNICITY
SM252823,-124,46.35,Chinook,ETHNICITY,ETHNICITY
SM303454,68.8333,41,Chinoz,ADM2,DISTRICT
SM18123,32.2382,-10.3317,Chinsali District (2013-),ADM2,DISTRICT
SM228716,87.1899,26.961,Chintang,LANGUAGE,LANGUAGE
SM290082,23.5532,46.8882,Chinteni,ADM2,DISTRICT
SM270398,-72.6041,7.5554,Chinácota,ADM2,DISTRICT
SM269953,-75.3427,9.0387,Chinú,ADM2,DISTRICT
SM292545,24.1832,46.9838,Chiochis,ADM2,DISTRICT
SM291511,26.2762,45.167,Chiojdeanca,ADM2,DISTRICT
SM292335,26.8439,45.5611,Chiojdeni,ADM2,DISTRICT
SM289865,26.2184,45.3667,Chiojdu,ADM2,DISTRICT
SM11452,26,38.4167,Chios,ADM2,DISTRICT
SM269982,-74.0754,4.4148,Chipaque,ADM2,DISTRICT
SM305172,32.4971,-13.603,Chipata,ADM2,DISTRICT
SM270451,-73.6539,6.0631,Chipatá,ADM2,DISTRICT
SM228798,-67.8963,-18.7395,Chipaya,LANGUAGE,LANGUAGE
SM252826,-67.5,-18.41,Chipaya,ETHNICITY,ETHNICITY
SM252826,-67.5,-18.41,Chipaya,ETHNICITY,ETHNICITY
SM252826,-68,-19,Chipaya,ETHNICITY,ETHNICITY
SM252826,-68,-19,Chipaya,ETHNICITY,ETHNICITY
SM228797,-104.44,58.98,Chipewyan,LANGUAGE,LANGUAGE
SM252827,-110.24,59.27,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-110.24,59.27,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-110.24,59.27,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-110.24,59.27,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-110.24,59.27,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-111.89,60.34,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-111.89,60.34,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-111.89,60.34,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-111.89,60.34,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-111.89,60.34,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-106,62,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-106,62,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-106,62,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-106,62,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-106,62,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-105,60,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-105,60,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-105,60,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-105,60,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-105,60,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-106.19,59.47,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-106.19,59.47,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-106.19,59.47,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-106.19,59.47,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-106.19,59.47,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-107.3,59.6,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-107.3,59.6,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-107.3,59.6,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-107.3,59.6,Chipewyan,ETHNICITY,ETHNICITY
SM252827,-107.3,59.6,Chipewyan,ETHNICITY,ETHNICITY
SM228794,-71.1667,3.0833,Chipiajes,LANGUAGE,LANGUAGE
SM18124,29.1235,-10.3837,Chipili District,ADM2,DISTRICT
SM260317,15.7328,-13.8683,Chipindo,ADM2,DISTRICT
SM305232,32.4459,-20.4582,Chipinge,ADM2,DISTRICT
SM305232,32.4459,-20.4582,Chipinge,ADM2,DISTRICT
SM305232,32.4311,-20.5496,Chipinge,ADM2,DISTRICT
SM305232,32.4311,-20.5496,Chipinge,ADM2,DISTRICT
SM323793,-112.6354,53.688,Chipman,ADM2:ADM3,DISTRICT
SM228795,-94.2738,47.347,Chippewa,LANGUAGE,LANGUAGE
SM303294,-91.2799,45.0694,Chippewa,ADM2,DISTRICT
SM301259,-84.5294,46.3282,Chippewa,ADM2,DISTRICT
SM301341,-95.5667,45.0223,Chippewa,ADM2,DISTRICT
SM252829,-91.47,46.3,Chippewa,ETHNICITY,ETHNICITY
SM252829,-91.47,46.3,Chippewa,ETHNICITY,ETHNICITY
SM252829,-91.47,46.3,Chippewa,ETHNICITY,ETHNICITY
SM252829,-92.51,47.84,Chippewa,ETHNICITY,ETHNICITY
SM252829,-92.51,47.84,Chippewa,ETHNICITY,ETHNICITY
SM252829,-92.51,47.84,Chippewa,ETHNICITY,ETHNICITY
SM252829,-96.56,49.87,Chippewa,ETHNICITY,ETHNICITY
SM252829,-96.56,49.87,Chippewa,ETHNICITY,ETHNICITY
SM252829,-96.56,49.87,Chippewa,ETHNICITY,ETHNICITY
SM252829,-101.86,61.05,Chippewa,ETHNICITY,ETHNICITY
SM252829,-101.86,61.05,Chippewa,ETHNICITY,ETHNICITY
SM252829,-101.86,61.05,Chippewa,ETHNICITY,ETHNICITY
SM252829,-91,49,Chippewa,ETHNICITY,ETHNICITY
SM252829,-91,49,Chippewa,ETHNICITY,ETHNICITY
SM252829,-91,49,Chippewa,ETHNICITY,ETHNICITY
SM252829,-96,49,Chippewa,ETHNICITY,ETHNICITY
SM252829,-96,49,Chippewa,ETHNICITY,ETHNICITY
SM252829,-96,49,Chippewa,ETHNICITY,ETHNICITY
SM262307,22.9,43.4167,Chiprovtsi,ADM2,DISTRICT
SM17287,-60.9497,13.8012,Chique/Blanchard,ADM2,DISTRICT
SM14250,-96.7558,17.9906,Chiquihuitlan de Benito Juarez,ADM2,DISTRICT
SM228801,-96.6659,18.0541,Chiquihuitlán Mazatec,LANGUAGE,LANGUAGE
SM281780,-103.894,20.0856,Chiquilistlán,ADM2,DISTRICT
SM275408,-89.5809,14.8038,Chiquimula,ADM2,DISTRICT
SM945,-89.4167,14.6667,Chiquimula,ADM1,DISTRICT
SM275316,-90.379,14.0972,Chiquimulilla,ADM2,DISTRICT
SM270748,-73.8385,5.6136,Chiquinquirá,ADM2,DISTRICT
SM252831,-61.82,-16.18,Chiquito,ETHNICITY,ETHNICITY
SM252831,-61.82,-16.18,Chiquito,ETHNICITY,ETHNICITY
SM252831,-63,-18,Chiquito,ETHNICITY,ETHNICITY
SM252831,-63,-18,Chiquito,ETHNICITY,ETHNICITY
SM252832,-69.76,-33.65,Chiquiyami,ETHNICITY,ETHNICITY
SM228800,-77.4152,-10.1831,Chiquián Ancash Quechua,LANGUAGE,LANGUAGE
SM946,35.2158,-15.7527,Chiradzulu,ADM1:ADM2,DISTRICT
SM228805,47.4111,41.8695,Chirag,LANGUAGE,LANGUAGE
SM277398,90.4907,26.5253,Chirang,ADM2,DISTRICT
SM18384,69.5822,41.4689,Chirchiq Shahri,ADM2,DISTRICT
SM305260,31.7704,-21.2859,Chiredzi,ADM2,DISTRICT
SM269909,-73.5188,9.3932,Chiriguaná,ADM2,DISTRICT
SM295758,-88.1291,13.2552,Chirilagua,ADM2,DISTRICT
SM228807,-55.051,-25.6521,Chiripá,LANGUAGE,LANGUAGE
SM228813,-82.4093,8.3981,Chiriqui Sign Languages,LANGUAGE,LANGUAGE
SM947,-82.4167,8.5,Chiriquí (1997-),ADM1,DISTRICT
SM286644,-82.1242,8.9475,Chiriquí Grande,ADM2,DISTRICT
SM228811,-66.3,-13.5,Chiriva,LANGUAGE,LANGUAGE
SM252835,147.92,-8.86,Chirma,ETHNICITY,ETHNICITY
SM290149,28.222,43.9422,Chirnogeni,ADM2,DISTRICT
SM289988,26.5775,44.1106,Chirnogi,ADM2,DISTRICT
SM948,45.1511,-12.935,Chirongui,ADM1,DISTRICT
SM303513,66.2988,39.1879,Chiroqchi,ADM2,DISTRICT
SM262441,25.3833,42.2333,Chirpan,ADM2,DISTRICT
SM291715,24.6109,45.9055,Chirpar,ADM2,DISTRICT
SM228804,94.4072,25.4643,Chiru,LANGUAGE,LANGUAGE
SM305221,30.5213,-19.2994,Chirumhanzu,ADM2,DISTRICT
SM18125,28.6878,-16.1328,Chirundu District,ADM2,DISTRICT
SM278054,137.05,34.9986,Chiryū,ADM2,DISTRICT
SM301342,-92.9083,45.5025,Chisago,ADM2,DISTRICT
SM18126,28.5654,-14.8307,Chisamba District,ADM2,DISTRICT
SM292709,27.9178,45.2088,Chiscani,ADM2,DISTRICT
SM270750,-72.4243,6.6933,Chiscas,ADM2,DISTRICT
SM289989,26.856,44.1687,Chiselet,ADM2,DISTRICT
SM252837,28.64,-10.82,Chishinga,ETHNICITY,ETHNICITY
SM294250,55.4167,54.5,Chishminskiy rayon,ADM2,DISTRICT
SM260017,63.9573,34.4452,Chishti Sharif,ADM2,DISTRICT
SM290490,22.0857,46.2651,Chisindia,ADM2,DISTRICT
SM290491,21.513,46.5218,Chisineu-cris,ADM2,DISTRICT
SM292415,22.233,47.2666,Chislaz,ADM2,DISTRICT
SM293889,76.25,54.5833,Chistoozernyy rayon,ADM2,DISTRICT
SM294611,50.6333,55.3667,Chistopol',ADM2,DISTRICT
SM13182,67,52.5,Chistopol'e Audany,ADM2,DISTRICT
SM294613,50.75,55.3333,Chistopol'skiy rayon,ADM2,DISTRICT
SM278065,136.8649,34.9705,Chita,ADM2,DISTRICT
SM270751,-72.4677,6.0779,Chita,ADM2,DISTRICT
SM12883,136.9158,34.8363,Chita-gun,ADM2,DISTRICT
SM270399,-72.5507,7.032,Chitagá,ADM2,DISTRICT
SM461263,30.5672,-12.8636,Chitambo,ADM2,DISTRICT
SM270753,-73.4576,5.9617,Chitaraque,ADM2,DISTRICT
SM260334,20.3981,-7.4191,Chitato,ADM2,DISTRICT
SM260407,17.1919,-13.4871,Chitembo,ADM2,DISTRICT
SM290947,25.9785,44.4905,Chitila,ADM2,DISTRICT
SM228817,-90.9123,30.3386,Chitimacha,LANGUAGE,LANGUAGE
SM252843,-90.77,30.23,Chitimacha,ETHNICITY,ETHNICITY
SM252843,-90.77,30.23,Chitimacha,ETHNICITY,ETHNICITY
SM252843,-90,30,Chitimacha,ETHNICITY,ETHNICITY
SM252843,-90,30,Chitimacha,ETHNICITY,ETHNICITY
SM16882,113.5002,52,Chitinskiy Rayon,ADM2,DISTRICT
SM950,33.4254,-9.9273,Chitipa,ADM1:ADM2,DISTRICT
SM228822,78.5287,31.2965,Chitkuli Kinnauri,LANGUAGE,LANGUAGE
SM278237,141.5095,42.8005,Chitose,ADM2,DISTRICT
SM276925,76.5,14.2,Chitradurga (1997-),ADM2,DISTRICT
SM277281,81.0896,25.2051,Chitrakoot,ADM2,DISTRICT
SM375425,72.243,36.2583,Chitral,ADM2:ADM3,DISTRICT
SM286706,-80.4333,7.9833,Chitré,ADM2,DISTRICT
SM262235,91.9633,22.4875,Chittagong,ADM2,DISTRICT
SM951,91.5,22.9167,Chittagong (1995-),ADM1,DISTRICT
SM228819,93.1695,20.831,Chittagonian,LANGUAGE,LANGUAGE
SM277164,74.5,24.5,Chittaurgarh (2008-),ADM2,DISTRICT
SM302930,-73.0809,44.461,Chittenden,ADM2,DISTRICT
SM261353,116.0513,-31.3583,Chittering,ADM2,DISTRICT
SM277024,79,13.4167,Chittoor,ADM2,DISTRICT
SM6006,84.4532,27.618,Chitwan,ADM2:ADM3,DISTRICT
SM228818,84.4082,27.5825,Chitwania Tharu,LANGUAGE,LANGUAGE
SM290083,23.9202,47.3229,Chiuiesti,ADM2,DISTRICT
SM283871,33.302,-15.564,Chiuta,ADM2,DISTRICT
SM292546,24.24,47.2401,Chiuza,ADM2,DISTRICT
SM270754,-73.2847,5.5409,Chivatá,ADM2,DISTRICT
SM305261,30.5833,-20.5,Chivi,ADM2,DISTRICT
SM260745,-59.9167,-34.9167,Chivilcoy,ADM2,DISTRICT
SM270141,-74.507,10.0825,Chivolo,ADM2,DISTRICT
SM270755,-73.3739,4.8395,Chivor,ADM2,DISTRICT
SM279475,139.7536,35.694,Chiyoda,ADM2,DISTRICT
SM269131,117.4139,30.3043,Chizhou,ADM2,DISTRICT
SM252850,-104.7,29.96,Chizo,ETHNICITY,ETHNICITY
SM304562,105.2767,22.179,Chiêm Hóa,ADM2,DISTRICT
SM283857,39.7032,-13.4667,Chiúre,ADM2,DISTRICT
SM949,28.8333,47,Chişinău,ADM1,DISTRICT
SM13183,70.5,53.6667,Chkalov Audany,ADM2,DISTRICT
SM293755,43.2371,56.7631,Chkalovskiy rayon,ADM2,DISTRICT
SM11294,42.2,42.5667,Chkhorots'q'us Munitsip'alit'et'I (excl. Chkohortsku),ADM2,DISTRICT
SM11295,42.1292,42.5103,Chkhorotsqu,ADM2,DISTRICT
SM952,1.25,36.25,Chlef,ADM1,DISTRICT
SM10664,32.4057,34.7981,Chlorakas,ADM2,DISTRICT
SM297252,101.8472,6.2301,Cho-I-rong,ADM2,DISTRICT
SM269983,-73.9185,4.5606,Choachí,ADM2,DISTRICT
SM280389,104.8617,14.1589,Choam Khsant,ADM2,DISTRICT
SM269109,-70.9926,-31.7109,Choapa,ADM2,DISTRICT
SM228829,-95.8656,17.4371,Choapan Zapotec,LANGUAGE,LANGUAGE
SM268541,24.8038,-18.5127,Chobe,ADM2,DISTRICT
SM953,25,-18.5,Chobe,ADM1,DISTRICT
SM17288,-60.9765,14.0312,Choc,ADM2,DISTRICT
SM228830,91.2195,27.4696,Chocangacakha,LANGUAGE,LANGUAGE
SM282951,-89.8455,20.7511,Chocholá,ADM2,DISTRICT
SM228833,-97.2388,17.666,Chochotec,LANGUAGE,LANGUAGE
SM282868,-97.043,18.9978,Chocomán,ADM2,DISTRICT
SM269985,-73.6869,5.1233,Chocontá,ADM2,DISTRICT
SM228831,-88.5,32.25,Choctaw,LANGUAGE,LANGUAGE
SM302175,-95.5521,34.0266,Choctaw,ADM2,DISTRICT
SM301427,-89.2484,33.3473,Choctaw,ADM2,DISTRICT
SM300249,-88.2632,32.0196,Choctaw,ADM2,DISTRICT
SM252853,-88.88,32.74,Choctaw,ETHNICITY,ETHNICITY
SM252853,-88.88,32.74,Choctaw,ETHNICITY,ETHNICITY
SM252853,-88,33,Choctaw,ETHNICITY,ETHNICITY
SM252853,-88,33,Choctaw,ETHNICITY,ETHNICITY
SM954,-77,6,Chocó,ADM1,DISTRICT
SM228837,73.5283,20.8803,Chodri,LANGUAGE,LANGUAGE
SM288787,17.0155,52.9529,Chodzież,ADM2,DISTRICT
SM252854,70.98,30.67,Choiah,ETHNICITY,ETHNICITY
SM283792,115.5061,48.8903,Choibalsan,ADM2,DISTRICT
SM283792,115.5061,48.8903,Choibalsan,ADM2,DISTRICT
SM283792,114.8669,48.4285,Choibalsan,ADM2,DISTRICT
SM283792,114.8669,48.4285,Choibalsan,ADM2,DISTRICT
SM10665,33.3389,34.7989,Choirokoitia,ADM2,DISTRICT
SM956,156.8628,-6.9919,Choiseul,ADM1,DISTRICT
SM955,-61.0282,13.8016,Choiseul,ADM1,DISTRICT
SM252855,156.52,-6.69,Choiseulese,ETHNICITY,ETHNICITY
SM282545,-108.2231,26.6357,Choix,ADM2,DISTRICT
SM288707,17.7188,53.801,Chojnice,ADM2,DISTRICT
SM297139,102.2034,14.7634,Chok Chai,ADM2,DISTRICT
SM11296,42.3667,41.9333,Chokhat'auris Munitsip'alit'et'I (excl. Chokhatauri),ADM2,DISTRICT
SM11297,42.2435,42.0187,Chokhatauri,ADM2,DISTRICT
SM228842,94.2887,25.5636,Chokri Naga,LANGUAGE,LANGUAGE
SM228844,21.3992,-9.6608,Chokwe,LANGUAGE,LANGUAGE
SM250162,22.46,-11.71,Chokwe,ETHNICITY,ETHNICITY
SM250162,22.46,-11.71,Chokwe,ETHNICITY,ETHNICITY
SM250162,22.46,-11.71,Chokwe,ETHNICITY,ETHNICITY
SM250162,20.65,-10.14,Chokwe,ETHNICITY,ETHNICITY
SM250162,20.65,-10.14,Chokwe,ETHNICITY,ETHNICITY
SM250162,20.65,-10.14,Chokwe,ETHNICITY,ETHNICITY
SM250162,16.66,-10.41,Chokwe,ETHNICITY,ETHNICITY
SM250162,16.66,-10.41,Chokwe,ETHNICITY,ETHNICITY
SM250162,16.66,-10.41,Chokwe,ETHNICITY,ETHNICITY
SM250162,19.03,-14.2,Chokwe,ETHNICITY,ETHNICITY
SM250162,19.03,-14.2,Chokwe,ETHNICITY,ETHNICITY
SM250162,19.03,-14.2,Chokwe,ETHNICITY,ETHNICITY
SM250162,20,-10,Chokwe,ETHNICITY,ETHNICITY
SM250162,20,-10,Chokwe,ETHNICITY,ETHNICITY
SM250162,20,-10,Chokwe,ETHNICITY,ETHNICITY
SM250162,21,-10,Chokwe,ETHNICITY,ETHNICITY
SM250162,21,-10,Chokwe,ETHNICITY,ETHNICITY
SM250162,21,-10,Chokwe,ETHNICITY,ETHNICITY
SM228851,-92.4698,17.4897,Chol,LANGUAGE,LANGUAGE
SM280477,104.8229,12.1586,Chol Kiri,ADM2,DISTRICT
SM228854,-92.47,17.49,"Chol, Tila",LANGUAGE,LANGUAGE
SM252859,76.4,10.18,Cholanaikkan,ETHNICITY,ETHNICITY
SM10666,32.5924,34.7687,Choletria,ADM2,DISTRICT
SM10667,32.4579,34.9835,Choli,ADM2,DISTRICT
SM275883,-87.92,15.64,Choloma,ADM2,DISTRICT
SM252860,-77.23,-7.59,Cholon,ETHNICITY,ETHNICITY
SM228852,-89,15.5,Cholti,LANGUAGE,LANGUAGE
SM275821,-87.2012,13.2826,Choluteca,ADM2,DISTRICT
SM957,-87.0833,13.3333,Choluteca,ADM1,DISTRICT
SM228853,-75.9621,-9.2313,Cholón,LANGUAGE,LANGUAGE
SM297449,99.5187,13.6252,Chom Bung,ADM2,DISTRICT
SM297646,103.5788,15.1436,Chom Phra,ADM2,DISTRICT
SM296889,98.5976,18.3924,Chom Thong,ADM2,DISTRICT
SM297150,100.4618,13.6891,Chom Thong,ADM2,DISTRICT
SM18128,26.9873,-16.6888,Choma District (2012-),ADM2,DISTRICT
SM12036,44.837,36.6216,Choman District,ADM2,DISTRICT
SM12036,44.837,36.6216,Choman District,ADM2,DISTRICT
SM12036,44.8746,36.6373,Choman District,ADM2,DISTRICT
SM12036,44.8746,36.6373,Choman District,ADM2,DISTRICT
SM280880,101.9617,19.8872,Chomphet,ADM2,DISTRICT
SM271195,13.3333,50.4167,Chomutov,ADM2,DISTRICT
SM958,101.3,13.3,Chon Buri,ADM1,DISTRICT
SM297340,100.8357,16.1074,Chon Daen,ADM2,DISTRICT
SM273506,-80.1,-0.7,Chone,ADM2,DISTRICT
SM252861,103.74,11.98,Chong,ETHNICITY,ETHNICITY
SM280375,103.5821,14.0177,Chong Kal,ADM2,DISTRICT
SM228858,102.514,12.5218,Chong of Chanthaburi,LANGUAGE,LANGUAGE
SM280334,72.3302,39.4761,Chong-Alay,ADM2,DISTRICT
SM260399,13.8123,-13.5893,Chongoroi,ADM2,DISTRICT
SM269365,107.4023,29.5352,Chongqing,ADM2,DISTRICT
SM959,107.8333,30.0833,Chongqing,ADM1,DISTRICT
SM268515,91.3582,27.0006,Chongshing,ADM2,DISTRICT
SM18129,29.33,-15.72,Chongwe District (2012-),ADM2,DISTRICT
SM269444,107.3692,22.5717,Chongzuo,ADM2,DISTRICT
SM228859,99.8255,27.7552,Choni,LANGUAGE,LANGUAGE
SM296994,102.5595,16.0225,Chonnabot,ADM2,DISTRICT
SM228857,-73.8794,-44.2987,Chono,LANGUAGE,LANGUAGE
SM252863,-73.53,-48.35,Chono,ETHNICITY,ETHNICITY
SM252863,-73.53,-48.35,Chono,ETHNICITY,ETHNICITY
SM252863,-72.65,-46.99,Chono,ETHNICITY,ETHNICITY
SM252863,-72.65,-46.99,Chono,ETHNICITY,ETHNICITY
SM252863,-73.04,-45.95,Chono,ETHNICITY,ETHNICITY
SM252863,-73.04,-45.95,Chono,ETHNICITY,ETHNICITY
SM252863,-73.53,-48.35,Chono,ETHNICITY,ETHNICITY
SM252863,-73.53,-48.35,Chono,ETHNICITY,ETHNICITY
SM252863,-72.65,-46.99,Chono,ETHNICITY,ETHNICITY
SM252863,-72.65,-46.99,Chono,ETHNICITY,ETHNICITY
SM252863,-73.04,-45.95,Chono,ETHNICITY,ETHNICITY
SM252863,-73.04,-45.95,Chono,ETHNICITY,ETHNICITY
SM252863,-73.83,-45,Chono,ETHNICITY,ETHNICITY
SM252863,-73.83,-45,Chono,ETHNICITY,ETHNICITY
SM252864,-96,16,Chontal de Oaxaca,ETHNICITY,ETHNICITY
SM8275,-92.47,18.14,Chontal de Tabasco,ETHNICITY,ETHNICITY
SM960,-85.1667,12.0833,Chontales,ADM1,DISTRICT
SM282869,-97.9788,21.392,Chontla,ADM2,DISTRICT
SM252865,41.58,-0.93,Chonyi,ETHNICITY,ETHNICITY
SM228861,38.672,-6.518,Chonyi-Dzihana-Kauma,LANGUAGE,LANGUAGE
SM250163,34.74,-24.51,Chope,ETHNICITY,ETHNICITY
SM250163,34.74,-24.51,Chope,ETHNICITY,ETHNICITY
SM250163,34.47,-24.76,Chope,ETHNICITY,ETHNICITY
SM250163,34.47,-24.76,Chope,ETHNICITY,ETHNICITY
SM228865,34.1097,-24.8327,Chopi,LANGUAGE,LANGUAGE
SM264819,-52.4568,-25.8143,Chopinzinho,ADM2,DISTRICT
SM17289,-60.9862,13.956,Choppin Ridge/Sarot,ADM2,DISTRICT
SM252869,-74.31,2.23,Choque,ETHNICITY,ETHNICITY
SM260220,66.2627,32.9301,Chora,ADM2,DISTRICT
SM297883,10.4274,35.2212,Chorbane,ADM2,DISTRICT
SM273423,-78.7346,-2.9829,Chordeleg,ADM2,DISTRICT
SM15324,-56.6329,-24.1843,Chore (2011-),ADM2,DISTRICT
SM299438,32.3236,49.6701,Chornobaivs'kyi,ADM2,DISTRICT
SM299896,32.9261,45.488,Chornomors'kyi,ADM2,DISTRICT
SM299636,32.9336,50.2673,Chornukhyns'kyi,ADM2,DISTRICT
SM228870,-85.1228,10.8154,Chorotega,LANGUAGE,LANGUAGE
SM252871,-63.03,-22.22,Choroti,ETHNICITY,ETHNICITY
SM252871,-63.03,-22.22,Choroti,ETHNICITY,ETHNICITY
SM252871,-62,-22,Choroti,ETHNICITY,ETHNICITY
SM252871,-62,-22,Choroti,ETHNICITY,ETHNICITY
SM267907,-38.489,-4.2918,Chorozinho,ADM2,DISTRICT
SM267775,-39.1565,-9.3055,Chorrochó,ADM2,DISTRICT
SM252872,-89,14,Chorti,ETHNICITY,ETHNICITY
SM299747,25.7974,49.0165,Chortkivs'kyi,ADM2,DISTRICT
SM303536,71.8333,41.1667,Chortoq,ADM2,DISTRICT
SM228868,-89.2363,14.818,Chortí,LANGUAGE,LANGUAGE
SM288748,18.9628,50.2885,Chorzów,ADM2,DISTRICT
SM267906,-39.1778,-4.7728,Choró,ADM2,DISTRICT
SM260925,-70.1667,-36.9167,Chos Malal,ADM2,DISTRICT
SM12884,140.3553,35.4001,Chosei-gun,ADM2,DISTRICT
SM288833,15.6211,53.1428,Choszczno,ADM2,DISTRICT
SM286889,-79.1806,-6.3833,Chota,ADM2,DISTRICT
SM228873,94.2408,24.3722,Chothe,LANGUAGE,LANGUAGE
SM17290,-60.9585,13.8338,Choucomel,ADM2,DISTRICT
SM252875,55.3,42.35,Choudor Turkoman,ETHNICITY,ETHNICITY
SM280909,35.5324,33.6504,Chouf,ADM2,DISTRICT
SM10668,32.5583,34.8788,Choulou,ADM2,DISTRICT
SM301676,-110.4352,47.8806,Chouteau,ADM2,DISTRICT
SM301944,-76.6022,36.1266,Chowan,ADM2,DISTRICT
SM261061,-64.75,-28.75,Choya,ADM2,DISTRICT
SM292869,86.6667,51.75,Choyskiy rayon,ADM2,DISTRICT
SM10090,104.9255,11.6032,Chraoy Chongvar,ADM2,DISTRICT
SM298104,10.0709,35.1505,Chrarda,ADM2,DISTRICT
SM228876,107.293,10.8021,Chrau,LANGUAGE,LANGUAGE
SM252880,106.93,11.07,Chrau Jro,ETHNICITY,ETHNICITY
SM305100,26.4397,-31.7903,Chris Hani,ADM2,DISTRICT
SM961,-59.5242,13.0873,Christ Church,ADM1,DISTRICT
SM962,-62.7667,17.35,Christ Church Nichola Town,ADM1,DISTRICT
SM286538,172.7459,-43.6458,Christchurch,ADM2,DISTRICT
SM301537,-93.1889,36.9696,Christian,ADM2,DISTRICT
SM301025,-87.4904,36.8942,Christian,ADM2,DISTRICT
SM300594,-89.2773,39.5458,Christian,ADM2,DISTRICT
SM17291,-61.0469,13.7735,Christian Hill,ADM2,DISTRICT
SM12233,-77.4905,18.1802,Christiana,ADM2,DISTRICT
SM303893,-64.7073,17.744,Christiansted,ADM2,DISTRICT
SM271685,15.1878,55.3198,Christiansø,ADM2,DISTRICT
SM252882,1.79,-157.32,Christmas Islands,ETHNICITY,ETHNICITY
SM228884,108.529,11.6625,Chru,LANGUAGE,LANGUAGE
SM252883,108.28,12.72,Chru,ETHNICITY,ETHNICITY
SM271241,15.8333,49.8333,Chrudim,ADM2,DISTRICT
SM10669,32.4362,35.0072,Chrysochou,ADM2,DISTRICT
SM288944,19.4043,50.1357,Chrzanów,ADM2,DISTRICT
SM281424,-9.3091,30.0295,Chtouka-Aït Baha,ADM2,DISTRICT
SM262260,88.85,23.6333,Chuadanga,ADM2,DISTRICT
SM228890,101.95,22.5638,Chuanqiandian Cluster Miao,LANGUAGE,LANGUAGE
SM275459,-90.4749,14.8502,Chuarrancho,ADM2,DISTRICT
SM228888,145.139,-6.1782,Chuave,LANGUAGE,LANGUAGE
SM288616,145.0833,-6.1667,Chuave,ADM2,DISTRICT
SM963,-69,-44,Chubut,ADM1,DISTRICT
SM252885,-63.58,58.87,Chuckbukmiut,ETHNICITY,ETHNICITY
SM286828,-69.3576,-16.6055,Chucuíto,ADM2,DISTRICT
SM282011,-101.3483,19.8915,Chucándiro,ADM2,DISTRICT
SM12234,-77.5071,18.1515,Chudleigh,ADM2,DISTRICT
SM299866,28.1667,50,Chudnivs'kyi,ADM2,DISTRICT
SM293836,31.8333,59.1667,Chudovskiy rayon,ADM2,DISTRICT
SM252888,-147.59,61.81,Chugach,ETHNICITY,ETHNICITY
SM252888,-147.59,61.81,Chugach,ETHNICITY,ETHNICITY
SM252888,-147.59,61.81,Chugach,ETHNICITY,ETHNICITY
SM252888,-146.49,60.72,Chugach,ETHNICITY,ETHNICITY
SM252888,-146.49,60.72,Chugach,ETHNICITY,ETHNICITY
SM252888,-146.49,60.72,Chugach,ETHNICITY,ETHNICITY
SM252888,-147.61,61.21,Chugach,ETHNICITY,ETHNICITY
SM252888,-147.61,61.21,Chugach,ETHNICITY,ETHNICITY
SM252888,-147.61,61.21,Chugach,ETHNICITY,ETHNICITY
SM18199,36.7358,49.8359,Chuhuiv City Council,ADM2,DISTRICT
SM228896,-91.76,15.8399,Chuj,LANGUAGE,LANGUAGE
SM228903,37.6116,-0.2741,Chuka,LANGUAGE,LANGUAGE
SM228902,170.045,68.6436,Chukchi,LANGUAGE,LANGUAGE
SM252892,177.68,68.33,Chukchi,ETHNICITY,ETHNICITY
SM252892,177.68,68.33,Chukchi,ETHNICITY,ETHNICITY
SM252892,177.68,68.33,Chukchi,ETHNICITY,ETHNICITY
SM252892,177.68,68.33,Chukchi,ETHNICITY,ETHNICITY
SM252892,177.68,68.33,Chukchi,ETHNICITY,ETHNICITY
SM252892,177.68,68.33,Chukchi,ETHNICITY,ETHNICITY
SM252892,166.82,65.58,Chukchi,ETHNICITY,ETHNICITY
SM252892,166.82,65.58,Chukchi,ETHNICITY,ETHNICITY
SM252892,166.82,65.58,Chukchi,ETHNICITY,ETHNICITY
SM252892,166.82,65.58,Chukchi,ETHNICITY,ETHNICITY
SM252892,166.82,65.58,Chukchi,ETHNICITY,ETHNICITY
SM252892,166.82,65.58,Chukchi,ETHNICITY,ETHNICITY
SM252892,180,67.5,Chukchi,ETHNICITY,ETHNICITY
SM252892,180,67.5,Chukchi,ETHNICITY,ETHNICITY
SM252892,180,67.5,Chukchi,ETHNICITY,ETHNICITY
SM252892,180,67.5,Chukchi,ETHNICITY,ETHNICITY
SM252892,180,67.5,Chukchi,ETHNICITY,ETHNICITY
SM252892,180,67.5,Chukchi,ETHNICITY,ETHNICITY
SM252892,177,66,Chukchi,ETHNICITY,ETHNICITY
SM252892,177,66,Chukchi,ETHNICITY,ETHNICITY
SM252892,177,66,Chukchi,ETHNICITY,ETHNICITY
SM252892,177,66,Chukchi,ETHNICITY,ETHNICITY
SM252892,177,66,Chukchi,ETHNICITY,ETHNICITY
SM252892,177,66,Chukchi,ETHNICITY,ETHNICITY
SM252892,179.6,66.5,Chukchi,ETHNICITY,ETHNICITY
SM252892,179.6,66.5,Chukchi,ETHNICITY,ETHNICITY
SM252892,179.6,66.5,Chukchi,ETHNICITY,ETHNICITY
SM252892,179.6,66.5,Chukchi,ETHNICITY,ETHNICITY
SM252892,179.6,66.5,Chukchi,ETHNICITY,ETHNICITY
SM252892,179.6,66.5,Chukchi,ETHNICITY,ETHNICITY
SM252892,169,69,Chukchi,ETHNICITY,ETHNICITY
SM252892,169,69,Chukchi,ETHNICITY,ETHNICITY
SM252892,169,69,Chukchi,ETHNICITY,ETHNICITY
SM252892,169,69,Chukchi,ETHNICITY,ETHNICITY
SM252892,169,69,Chukchi,ETHNICITY,ETHNICITY
SM252892,169,69,Chukchi,ETHNICITY,ETHNICITY
SM252892,168,69.9,Chukchi,ETHNICITY,ETHNICITY
SM252892,168,69.9,Chukchi,ETHNICITY,ETHNICITY
SM252892,168,69.9,Chukchi,ETHNICITY,ETHNICITY
SM252892,168,69.9,Chukchi,ETHNICITY,ETHNICITY
SM252892,168,69.9,Chukchi,ETHNICITY,ETHNICITY
SM252892,168,69.9,Chukchi,ETHNICITY,ETHNICITY
SM293352,42.75,58.75,Chukhlomskiy rayon,ADM2,DISTRICT
SM964,172,67.5,Chukot,ADM1,DISTRICT
SM292787,-172.6667,66.3333,Chukotskiy rayon,ADM2,DISTRICT
SM228899,86.9824,27.358,Chukwa,LANGUAGE,LANGUAGE
SM297228,99.8537,8.0759,Chulaphon,ADM2,DISTRICT
SM283793,115.7176,49.853,Chuluunkhoroot,ADM2,DISTRICT
SM283534,100.2169,47.5297,Chuluut,ADM2,DISTRICT
SM228904,89.5541,54.3209,Chulym Turkic,LANGUAGE,LANGUAGE
SM293890,81,55,Chulymskiy rayon,ADM2,DISTRICT
SM280503,104.4421,11.0054,Chum Kiri,ADM2,DISTRICT
SM296995,102.0962,16.6159,Chum Phae,ADM2,DISTRICT
SM297140,102.7576,15.2599,Chum Phuang,ADM2,DISTRICT
SM252893,-119.89,34.59,Chumash,ETHNICITY,ETHNICITY
SM252893,-119.89,34.59,Chumash,ETHNICITY,ETHNICITY
SM252893,-119.89,34.59,Chumash,ETHNICITY,ETHNICITY
SM252893,-120.19,34.01,Chumash,ETHNICITY,ETHNICITY
SM252893,-120.19,34.01,Chumash,ETHNICITY,ETHNICITY
SM252893,-120.19,34.01,Chumash,ETHNICITY,ETHNICITY
SM252893,-119.71,34.44,Chumash,ETHNICITY,ETHNICITY
SM252893,-119.71,34.44,Chumash,ETHNICITY,ETHNICITY
SM252893,-119.71,34.44,Chumash,ETHNICITY,ETHNICITY
SM252893,-119.6,34.63,Chumash,ETHNICITY,ETHNICITY
SM252893,-119.6,34.63,Chumash,ETHNICITY,ETHNICITY
SM252893,-119.6,34.63,Chumash,ETHNICITY,ETHNICITY
SM282870,-97.5867,20.22,Chumatlán,ADM2,DISTRICT
SM282952,-89.2932,20.4536,Chumayel,ADM2,DISTRICT
SM286905,-72,-14.4167,Chumbivilcas,ADM2,DISTRICT
SM228906,-0.2755,8.1521,Chumburung,LANGUAGE,LANGUAGE
SM965,99.0667,10.4,Chumphon,ADM1,DISTRICT
SM297647,103.3709,15.3794,Chumphon Buri,ADM2,DISTRICT
SM297201,100.2897,15.8475,Chumsaeng,ADM2,DISTRICT
SM297330,100.1449,19.368,Chun,ADM2,DISTRICT
SM280691,127.7278,37.8805,Chuncheon,ADM2,DISTRICT
SM273600,-78.9167,-2.2833,Chunchi,ADM2,DISTRICT
SM229173,10.4104,6.5969,Chung,LANGUAGE,LANGUAGE
SM966,127.6569,36.8215,Chungcheongbuk-do (2012-),ADM1,DISTRICT
SM967,127,36.5,Chungcheongnam-do (2012-),ADM1,DISTRICT
SM289043,126.895,41.6547,Chunggang,ADM2,DISTRICT
SM289077,125.8289,38.8592,Chunghwa,ADM2,DISTRICT
SM280647,127.8771,37.0179,Chungju,ADM2,DISTRICT
SM292884,99.5,56.6667,Chunskiy rayon,ADM2,DISTRICT
SM4422,33.1642,-7.7678,Chunya (2016-),ADM2,DISTRICT
SM286745,-75.433,-12.3,Chupaca,ADM2,DISTRICT
SM15601,-65.6675,18.2565,Chupacallos Barrio,ADM2,DISTRICT
SM252901,-77.12,-9.8,Chupayachu,ETHNICITY,ETHNICITY
SM266077,-60.9262,-12.6724,Chupinguaia,ADM2,DISTRICT
SM262481,22.7167,43.5,Chuprene,ADM2,DISTRICT
SM968,-64.3333,-20,Chuquisaca,ADM1,DISTRICT
SM11641,93.6833,24.3333,Churachandpur (2016-),ADM2,DISTRICT
SM228917,76.1124,32.8969,Churahi,LANGUAGE,LANGUAGE
SM294336,132.5,62,Churapchinskiy rayon,ADM2,DISTRICT
SM286912,-74.52,-12.6,Churcampa,ADM2,DISTRICT
SM12235,-76.419,17.8872,Church Corner,ADM2,DISTRICT
SM228916,22.8442,43.7171,Church Slavic,LANGUAGE,LANGUAGE
SM301780,-118.3358,39.5809,Churchill,ADM2,DISTRICT
SM282012,-102.081,20.1285,Churintzio,ADM2,DISTRICT
SM277165,74.5,28.5,Churu,ADM2,DISTRICT
SM282013,-101.6099,18.7063,Churumuco,ADM2,DISTRICT
SM18386,71.2401,40.971,Chust Tumani,ADM2,DISTRICT
SM228922,105.773,17.6819,Chut,LANGUAGE,LANGUAGE
SM299647,35.16,49.7136,Chutivs'kyi,ADM2,DISTRICT
SM969,151.8543,7.4408,Chuuk,ADM1,DISTRICT
SM228926,151.5986,7.347,Chuukese,LANGUAGE,LANGUAGE
SM228928,167.96,65.17,Chuvantsy,LANGUAGE,LANGUAGE
SM252907,172.49,62.55,Chuvanzy,ETHNICITY,ETHNICITY
SM228927,47.163,55.487,Chuvash,LANGUAGE,LANGUAGE
SM970,47,55.5,Chuvash,ADM1,DISTRICT
SM252908,53,57,Chuvash,ETHNICITY,ETHNICITY
SM252908,53,57,Chuvash,ETHNICITY,ETHNICITY
SM252908,53,57,Chuvash,ETHNICITY,ETHNICITY
SM252908,47.78,54.75,Chuvash,ETHNICITY,ETHNICITY
SM252908,47.78,54.75,Chuvash,ETHNICITY,ETHNICITY
SM252908,47.78,54.75,Chuvash,ETHNICITY,ETHNICITY
SM252908,47.2511,56.1103,Chuvash,ETHNICITY,ETHNICITY
SM252908,47.2511,56.1103,Chuvash,ETHNICITY,ETHNICITY
SM252908,47.2511,56.1103,Chuvash,ETHNICITY,ETHNICITY
SM265608,-52.0022,-30.7765,Chuvisca,ADM2,DISTRICT
SM228929,37.1231,-17.3428,Chuwabu,LANGUAGE,LANGUAGE
SM269374,101.7048,25.432,Chuxiong Yi,ADM2,DISTRICT
SM13363,75.3333,42.6667,Chuyskiy Rayon,ADM2,DISTRICT
SM269132,118.0881,32.6008,Chuzhou,ADM2,DISTRICT
SM266072,-53.4328,-33.6592,Chuí,ADM2,DISTRICT
SM299439,32.6604,49.0797,Chyhyryns'kyi,ADM2,DISTRICT
SM263309,-43.2184,-21.6792,Chácara,ADM2,DISTRICT
SM228562,-66.7164,-12.133,Chácobo,LANGUAGE,LANGUAGE
SM270890,-72.8895,5.1924,Chámeza,ADM2,DISTRICT
SM303917,105.1699,10.5717,Châu Phú,ADM2,DISTRICT
SM304539,106.2731,10.386,Châu Thành,ADM2,DISTRICT
SM304472,105.9813,11.3157,Châu Thành,ADM2,DISTRICT
SM304448,105.8977,9.7026,Châu Thành,ADM2,DISTRICT
SM304547,106.3409,9.8643,Châu Thành,ADM2,DISTRICT
SM304228,105.1687,9.9437,Châu Thành,ADM2,DISTRICT
SM304294,106.4902,10.4521,Châu Thành,ADM2,DISTRICT
SM304090,105.8172,9.9206,Châu Thành,ADM2,DISTRICT
SM304014,105.8238,10.2254,Châu Thành,ADM2,DISTRICT
SM303918,105.2822,10.4321,Châu Thành,ADM2,DISTRICT
SM304557,106.3083,10.2921,Châu Thành,ADM2,DISTRICT
SM304089,105.6419,9.9306,Châu Thành A,ADM2,DISTRICT
SM303916,105.0903,10.6788,Châu Đốc,ADM2,DISTRICT
SM304596,107.251,10.6581,Châu Đức,ADM2,DISTRICT
SM264972,-35.4559,-8.2157,Chã Grande,ADM2,DISTRICT
SM265351,-36.3305,-9.2292,Chã Preta,ADM2,DISTRICT
SM264970,-35.1897,-7.9901,Chã de Alegria,ADM2,DISTRICT
SM457205,3.0096,36.7405,Chéraga District,ADM2,DISTRICT
SM304067,106.3964,21.1372,Chí Linh,ADM2,DISTRICT
SM269981,-74.0585,4.8445,Chía,ADM2,DISTRICT
SM283217,-108.4973,27.4685,Chínipas,ADM2,DISTRICT
SM270749,-73.4962,5.6201,Chíquiza,ADM2,DISTRICT
SM283898,33.0046,-24.5225,Chókwè,ADM2,DISTRICT
SM971,74.5,42.4167,Chüy,ADM1:ADMX,DISTRICT
SM279476,139.55,35.65,Chōfu,ADM2,DISTRICT
SM279398,140.7755,35.743,Chōshi,ADM2,DISTRICT
SM289042,126.3811,40.5583,Chŏnch'ŏn,ADM2,DISTRICT
SM289204,125.2528,39.7094,Chŏngju-si,ADM2,DISTRICT
SM289111,127.3333,39.75,Chŏngp'yŏng,ADM2,DISTRICT
SM279477,139.7771,35.6699,Chūō,ADM2,DISTRICT
SM279596,138.5411,35.5842,Chūō,ADM2,DISTRICT
SM303929,106.6666,11.4628,Chơn Thành,ADM2,DISTRICT
SM304056,107.811,13.5998,Chư Prông,ADM2,DISTRICT
SM304055,107.9841,14.1594,Chư Păh,ADM2,DISTRICT
SM304057,108.0795,13.5218,Chư Pưh,ADM2,DISTRICT
SM304058,108.0866,13.7425,Chư Sê,ADM2,DISTRICT
SM304158,105.6613,20.8831,Chương Mỹ,ADM2,DISTRICT
SM304538,106.4434,10.3737,Chợ Gạo,ADM2,DISTRICT
SM304556,106.1708,10.2267,Chợ Lách,ADM2,DISTRICT
SM303915,105.4909,10.4773,Chợ Mới,ADM2,DISTRICT
SM304303,105.8576,22.0016,Chợ Mới,ADM2,DISTRICT
SM304302,105.5728,22.1868,Chợ Đồn,ADM2,DISTRICT
SM228940,2.0213,6.8422,Ci Gbe,LANGUAGE,LANGUAGE
SM228934,122.791,-5.6829,Cia-Cia,LANGUAGE,LANGUAGE
SM972,-66.5163,18.2916,Ciales,ADM1,DISTRICT
SM15602,-66.4689,18.3362,Ciales Barrio-Pueblo,ADM2,DISTRICT
SM15603,-66.5355,18.2584,Cialitos Barrio,ADM2,DISTRICT
SM276779,107.0831,-6.7725,Cianjur,ADM2,DISTRICT
SM264820,-52.5903,-23.7,Cianorte,ADM2,DISTRICT
SM228935,12.902,10.8977,Cibak,LANGUAGE,LANGUAGE
SM15605,-66.8747,18.3756,Cibao Barrio (Camuy),ADM2,DISTRICT
SM15604,-66.904,18.347,Cibao Barrio (San Sebastián),ADM2,DISTRICT
SM262026,29.3701,-3.3418,Cibitoke,ADM2,DISTRICT
SM973,29.25,-2.8833,Cibitoke,ADM1,DISTRICT
SM3980,27.8694,56.5928,Ciblas Novads,ADM1,DISTRICT
SM13470,27.9184,56.534,Ciblas pagasts,ADM2,DISTRICT
SM301861,-107.9997,34.9126,Cibola,ADM2,DISTRICT
SM252909,-71.39,18.83,Ciboney,ETHNICITY,ETHNICITY
SM15606,-66.3435,18.3405,Cibuco Barrio (Corozal),ADM2,DISTRICT
SM15607,-66.3746,18.4863,Cibuco Barrio (Vega Bajo),ADM2,DISTRICT
SM291231,24.6063,45.2458,Cicanesti,ADM2,DISTRICT
SM290999,23.3814,47.7058,Cicarlau,ADM2,DISTRICT
SM17292,-61.0088,13.993,Ciceron,ADM2,DISTRICT
SM228936,5.5407,11.0417,Cicipu,LANGUAGE,LANGUAGE
SM290002,21.7143,44.9748,Ciclova Romana,ADM2,DISTRICT
SM10392,-74.691,9.2323,Cicuco,ADM2,DISTRICT
SM371988,35.2479,-13.2943,Cidade De Lichinga,ADM2:ADM3,DISTRICT
SM371930,39.2675,-15.0997,Cidade De Nampula,ADM2:ADM3,DISTRICT
SM264821,-52.9747,-23.3654,Cidade Gaúcha,ADM2,DISTRICT
SM268247,-47.8,-16.1618,Cidade Ocidental,ADM2,DISTRICT
SM15022,32.7956,-25.3985,Cidade de Manhica,ADM2,DISTRICT
SM15024,40.5498,-12.994,Cidade de Pemba,ADM2,DISTRICT
SM15026,33.6557,-25.0522,Cidade de Xai-Xai,ADM2,DISTRICT
SM298605,33.0398,41.8558,Cide,ADM2,DISTRICT
SM262975,-47.8686,-5.0523,Cidelândia,ADM2,DISTRICT
SM974,-66.1621,18.1758,Cidra,ADM1,DISTRICT
SM15608,-67.1027,18.2822,Cidra Barrio,ADM2,DISTRICT
SM15609,-66.1611,18.1758,Cidra Barrio-Pueblo,ADM2,DISTRICT
SM15610,-66.9548,18.3221,Cidral Barrio,ADM2,DISTRICT
SM265609,-50.2694,-30.091,Cidreira,ADM2,DISTRICT
SM13471,22.6016,56.6789,Cieceres pagasts,ADM2,DISTRICT
SM288962,20.5763,52.9021,Ciechanów,ADM2,DISTRICT
SM271080,-80.45,22.15,Ciefuegos,ADM2,DISTRICT
SM975,-78.6333,21.9667,Ciego de Ávila,ADM1,DISTRICT
SM271072,-78.7667,21.85,Ciego de ávila,ADM2,DISTRICT
SM10393,-74.0833,10.8333,Cienaga (1999-),ADM2,DISTRICT
SM15611,-65.8612,18.3246,Cienaga Alta Barrio,ADM2,DISTRICT
SM15612,-65.8512,18.3796,Cienaga Baja Barrio,ADM2,DISTRICT
SM15613,-66.9304,17.9926,Cienaga Barrio,ADM2,DISTRICT
SM15614,-66.8683,18.4434,Cienagas Barrio,ADM2,DISTRICT
SM14251,-96.768,16.8937,Cienega de Zimatlan,ADM2,DISTRICT
SM15615,-66.3664,18.3416,Cienegueta Barrio,ADM2,DISTRICT
SM976,-80.45,22.2667,Cienfuegos,ADM1,DISTRICT
SM288749,18.7915,49.7263,Cieszyn,ADM2,DISTRICT
SM271061,-80.1,22.64,Cifuentes,ADM2,DISTRICT
SM298695,32.9252,38.6713,Cihanbeyli,ADM2,DISTRICT
SM281781,-104.6473,19.2383,Cihuatlán,ADM2,DISTRICT
SM276336,108.9886,-7.5742,Cilacap,ADM2,DISTRICT
SM276713,106.0167,-6.0167,Cilegon,ADM2,DISTRICT
SM289866,27.0434,45.0522,Cilibia,ADM2,DISTRICT
SM291396,24.6147,43.9021,Cilieni,ADM2,DISTRICT
SM276780,107.55,-6.8917,Cimahi,ADM2,DISTRICT
SM302176,-102.5177,36.7483,Cimarron,ADM2,DISTRICT
SM243810,13.3587,-5.3354,Cimbala-Cizali,LANGUAGE,LANGUAGE
SM243810,13.3587,-5.3354,Cimbala-Cizali,LANGUAGE,LANGUAGE
SM243810,12.5372,-5.7114,Cimbala-Cizali,LANGUAGE,LANGUAGE
SM243810,12.5372,-5.7114,Cimbala-Cizali,LANGUAGE,LANGUAGE
SM228943,11.4286,46.8777,Cimbrian,LANGUAGE,LANGUAGE
SM228943,11.4286,46.8777,Cimbrian,LANGUAGE,LANGUAGE
SM228943,11.324,45.921,Cimbrian,LANGUAGE,LANGUAGE
SM228943,11.324,45.921,Cimbrian,LANGUAGE,LANGUAGE
SM270453,-74.1745,6.4283,Cimitarra,ADM2,DISTRICT
SM977,28.8333,46.5833,Cimişlia,ADM1,DISTRICT
SM228946,124.721,9.1793,Cinamiguin Manobo,LANGUAGE,LANGUAGE
SM285514,-86.8551,13.2146,Cinco Pinos,ADM2,DISTRICT
SM292670,24.7733,45.9071,Cincu,ADM2,DISTRICT
SM228950,13.8187,11.1319,Cineni,LANGUAGE,LANGUAGE
SM289413,-8.1122,41.0399,Cinfães,ADM2,DISTRICT
SM295597,-88.9668,13.8901,Cinquera,ADM2,DISTRICT
SM10279,15.07,12.1191,Cinquieme Arrondissement,ADM2,DISTRICT
SM228951,-60.8312,-10.5879,Cinta Larga,LANGUAGE,LANGUAGE
SM252911,-62.95,-6.91,Cinta Larga,ETHNICITY,ETHNICITY
SM283130,-93.7108,16.6878,Cintalapa,ADM2,DISTRICT
SM15616,-66.4995,18.0103,Cintrona Barrio,ADM2,DISTRICT
SM290150,27.989,44.7177,Ciobanu,ADM2,DISTRICT
SM290249,25.8449,44.6214,Ciocanesti,ADM2,DISTRICT
SM289938,27.0653,44.197,Ciocanesti,ADM2,DISTRICT
SM290898,26.67,44.7883,Ciocarlia,ADM2,DISTRICT
SM290151,28.3116,44.111,Ciocarlia,ADM2,DISTRICT
SM290899,27.0248,44.5752,Ciochina,ADM2,DISTRICT
SM292710,27.2518,44.8221,Ciocile,ADM2,DISTRICT
SM291232,24.537,45.1028,Ciofrangeni,ADM2,DISTRICT
SM291874,25.0833,44.3137,Ciolanestii Din Deal,ADM2,DISTRICT
SM290948,26.1019,44.726,Ciolpani,ADM2,DISTRICT
SM291513,26.4041,44.83,Cioranii De Jos,ADM2,DISTRICT
SM292336,27.3095,45.4387,Ciorasti,ADM2,DISTRICT
SM290949,25.8786,44.4322,Ciorogarla,ADM2,DISTRICT
SM290347,23.4521,44.0862,Cioroiasi,ADM2,DISTRICT
SM290808,27.8475,46.9177,Ciortesti,ADM2,DISTRICT
SM263316,-43.3655,-20.9303,Cipotânea,ADM2,DISTRICT
SM267777,-38.5095,-11.1105,Cipó,ADM2,DISTRICT
SM13472,21.3897,56.7474,Ciravas pagasts,ADM2,DISTRICT
SM270419,-75.6854,4.5994,Circasia,ADM2,DISTRICT
SM451139,42,44,Circassian,ETHNICITY,ETHNICITY
SM451139,42,44,Circassian,ETHNICITY,ETHNICITY
SM451139,41.83,44.2,Circassian,ETHNICITY,ETHNICITY
SM451139,41.83,44.2,Circassian,ETHNICITY,ETHNICITY
SM261780,145.0837,-41.0199,Circular Head,ADM2,DISTRICT
SM459119,145.3737,-40.8568,Circular Head Tasmanian,ETHNICITY,ETHNICITY
SM15027,34.7731,-16.8678,Circunscricao da Mutarara (2013-),ADM2,DISTRICT
SM15028,40.1909,-12.0632,Circunscricao de Macomia,ADM2,DISTRICT
SM15029,37.4661,-16.1804,Circunscricao do Ile  (2013-),ADM2,DISTRICT
SM15030,40.0874,-15.5392,Circunscricao do Mogincual (2013-),ADM2,DISTRICT
SM276781,108.5667,-6.8,Cirebon,ADM2,DISTRICT
SM228955,108.538,-6.7453,Cirebon Javanese,DIALECT,LANGUAGE
SM291058,22.5406,44.82,Ciresu,ADM2,DISTRICT
SM292711,27.379,44.9537,Ciresu,ADM2,DISTRICT
SM4157,15.996,46.3406,Cirkulane,ADM1,DISTRICT
SM13473,27.5872,56.5211,Cirmas pagasts,ADM2,DISTRICT
SM271073,-78.7031,22.0189,Ciro Redodo,ADM2,DISTRICT
SM265610,-51.9124,-28.3707,Ciríaco,ADM2,DISTRICT
SM228956,8.93,45.2,Cisalpine Gaulish,LANGUAGE,LANGUAGE
SM250660,13.87,-9.51,Cisama,ETHNICITY,ETHNICITY
SM250660,13.87,-9.51,Cisama,ETHNICITY,ETHNICITY
SM250660,14,-10,Cisama,ETHNICITY,ETHNICITY
SM250660,14,-10,Cisama,ETHNICITY,ETHNICITY
SM252914,14.42,-12.43,Cisanji,ETHNICITY,ETHNICITY
SM289867,26.3795,45.2258,Cislau,ADM2,DISTRICT
SM291716,24.1328,45.7092,Cisnadie,ADM2,DISTRICT
SM270199,-75.0942,6.5451,Cisneros,ADM2,DISTRICT
SM305492,16.9428,43.512,Cista Provo,ADM2,DISTRICT
SM228960,139.461,-5.5828,Citak,LANGUAGE,LANGUAGE
SM295635,-89.2667,14.3738,Citalá,ADM2,DISTRICT
SM282871,-97.892,21.3435,Citlaltépetl,ADM2,DISTRICT
SM300379,-82.5201,28.8476,Citrus,ADM2,DISTRICT
SM17293,-60.9903,14.0095,City,ADM2,DISTRICT
SM17294,-60.9815,14.0212,City Gate/La Clery,ADM2,DISTRICT
SM18302,-132.3201,56.4133,City and Borough of Wrangell,ADM2,DISTRICT
SM18303,-76.6122,39.2904,City of Baltimore,ADM2,DISTRICT
SM305144,18.4167,-33.9167,City of Cape Town,ADM2,DISTRICT
SM18305,-76.9225,36.6777,City of Franklin,ADM2,DISTRICT
SM978,-78.6453,26.5203,City of Freeport,ADM1,DISTRICT
SM305109,27.9635,-26.1767,City of Johannesburg,ADM2,DISTRICT
SM18306,-77.4603,37.5538,City of Richmond,ADM2,DISTRICT
SM18308,-90.1979,38.6273,City of Saint Louis,ADM2,DISTRICT
SM979,30.25,59.9167,City of St. Petersburg,ADM1,DISTRICT
SM305110,28.4434,-25.6599,City of Tshwane,ADM2,DISTRICT
SM298033,10.2072,36.8402,Cité El Khadra,ADM2,DISTRICT
SM290003,21.6142,44.9391,Ciuchici,ADM2,DISTRICT
SM290679,25.9986,46.3394,Ciucsangeorgiu,ADM2,DISTRICT
SM292039,28.4711,44.9136,Ciucurova,ADM2,DISTRICT
SM285464,-86.281,13.6434,Ciudad Antigua,ADM2,DISTRICT
SM295671,-89.418,13.8109,Ciudad Arce,ADM2,DISTRICT
SM295759,-88.283,13.7622,Ciudad Barrios,ADM2,DISTRICT
SM285451,-86.1387,12.6945,Ciudad Darío,ADM2,DISTRICT
SM18329,-55.9515,-34.8121,Ciudad De La Costa,ADM2,DISTRICT
SM18330,-56.4406,-34.7281,Ciudad Del Plata,ADM2,DISTRICT
SM282478,-100.0481,21.9905,Ciudad Fernández,ADM2,DISTRICT
SM281782,-103.4954,19.703,Ciudad Guzman,ADM2,DISTRICT
SM14252,-95.1098,16.5763,Ciudad Ixtepec,ADM2,DISTRICT
SM282679,-97.8314,22.2764,Ciudad Madero,ADM2,DISTRICT
SM275141,10.8284,1.617,Ciudad Nueva Oyala,ADM1:ADM2,DISTRICT
SM274063,-4,39,Ciudad Real,ADM2,DISTRICT
SM285467,-86.1323,13.7229,Ciudad Sandino,ADM2,DISTRICT
SM15184,-86.3565,12.1566,Ciudad Sandino,ADM2,DISTRICT
SM282479,-99.0601,22.0315,Ciudad Valles,ADM2,DISTRICT
SM281783,-103.7519,19.7122,Ciudad Venustiano Carranza,ADM2,DISTRICT
SM275273,-90.7703,14.508,Ciudad Vieja,ADM2,DISTRICT
SM980,-58.45,-34.6,Ciudad de Buenos Aires,ADM1:ADMX,DISTRICT
SM981,-82.3,23.0833,Ciudad de la Habana,ADM1,DISTRICT
SM289654,-54.6797,-25.498,Ciudad del Este,ADM2,DISTRICT
SM282477,-99.6627,22.4351,Ciudad del Maíz (pre-1994),ADM2,DISTRICT
SM290004,21.754,45.1531,Ciudanovita,ADM2,DISTRICT
SM289787,23.6279,46.0653,Ciugud,ADM2,DISTRICT
SM292416,22.1253,47.2456,Ciuhoi,ADM2,DISTRICT
SM290900,27.2846,44.5198,Ciulnita,ADM2,DISTRICT
SM290680,25.5182,46.6782,Ciumani,ADM2,DISTRICT
SM292417,21.626,46.7103,Ciumeghiu,ADM2,DISTRICT
SM291875,24.944,43.7512,Ciuperceni,ADM2,DISTRICT
SM290622,22.9917,44.928,Ciuperceni,ADM2,DISTRICT
SM290349,22.9588,43.9219,Ciupercenii Noi,ADM2,DISTRICT
SM290809,27.5757,47.0615,Ciurea,ADM2,DISTRICT
SM290085,23.5398,46.6337,Ciurila,ADM2,DISTRICT
SM289788,23.0164,46.2448,Ciuruleasa,ADM2,DISTRICT
SM305442,16.403,43.9518,Civljane,ADM2,DISTRICT
SM228964,9.8856,10.8881,Ciwogai,LANGUAGE,LANGUAGE
SM291594,22.8813,47.0668,Cizer,ADM2,DISTRICT
SM298916,42.1872,37.3279,Cizre,ADM2,DISTRICT
SM270756,-73.2975,5.3633,Ciénaga,ADM2,DISTRICT
SM269954,-75.6042,8.8358,Ciénaga de Oro,ADM2,DISTRICT
SM271006,-81.5739,22.3993,Ciénaga de Zapata,ADM2,DISTRICT
SM282154,-100.1732,25.9634,Ciénega de Flores,ADM2,DISTRICT
SM302271,-122.2209,45.1882,Clackamas,ADM2,DISTRICT
SM252916,-122.5,45.32,Clackamas,ETHNICITY,ETHNICITY
SM274740,-3.75,56.1667,Clackmannanshire,ADM1:ADM2,DISTRICT
SM302548,-83.6604,36.4859,Claiborne,ADM2,DISTRICT
SM301428,-90.9118,31.9737,Claiborne,ADM2,DISTRICT
SM301113,-92.9957,32.8227,Claiborne,ADM2,DISTRICT
SM228968,-123.511,48.0707,Clallam,LANGUAGE,LANGUAGE
SM303103,-123.9343,48.1104,Clallam,ADM2,DISTRICT
SM252917,-123.81,47.96,Clallam,ETHNICITY,ETHNICITY
SM252917,-123.81,47.96,Clallam,ETHNICITY,ETHNICITY
SM252917,-123.81,47.96,Clallam,ETHNICITY,ETHNICITY
SM252917,-123,48,Clallam,ETHNICITY,ETHNICITY
SM252917,-123,48,Clallam,ETHNICITY,ETHNICITY
SM252917,-123,48,Clallam,ETHNICITY,ETHNICITY
SM252917,-123.55,47.95,Clallam,ETHNICITY,ETHNICITY
SM252917,-123.55,47.95,Clallam,ETHNICITY,ETHNICITY
SM252917,-123.55,47.95,Clallam,ETHNICITY,ETHNICITY
SM263317,-47.2524,-20.3568,Claraval,ADM2,DISTRICT
SM301260,-84.8478,43.9879,Clare,ADM2,DISTRICT
SM982,-9,52.8333,Clare,ADM1:ADM2,DISTRICT
SM261686,138.7534,-34.0146,Clare and Gilbert Valleys,ADM2,DISTRICT
SM12236,-77.2064,18.3256,Claremont,ADM2,DISTRICT
SM261354,115.7794,-31.9813,Claremont,ADM2,DISTRICT
SM261781,147.4473,-42.8737,Clarence,ADM2,DISTRICT
SM261519,152.7816,-29.6944,Clarence Valley,ADM2,DISTRICT
SM302437,-80.2164,33.6658,Clarendon,ADM2,DISTRICT
SM983,-77.3,17.9833,Clarendon,ADM1,DISTRICT
SM323861,-113.5866,50.0207,Claresholm,ADM2:ADM3,DISTRICT
SM302292,-79.421,41.1924,Clarion,ADM2,DISTRICT
SM303104,-122.4826,45.7793,Clark,ADM2,DISTRICT
SM303222,-90.6121,44.7347,Clark,ADM2,DISTRICT
SM302481,-97.7295,44.8583,Clark,ADM2,DISTRICT
SM302086,-83.7839,39.9168,Clark,ADM2,DISTRICT
SM301781,-115.0136,36.2152,Clark,ADM2,DISTRICT
SM301538,-91.7384,40.4104,Clark,ADM2,DISTRICT
SM300692,-85.7073,38.4772,Clark,ADM2,DISTRICT
SM301026,-84.1474,37.9709,Clark,ADM2,DISTRICT
SM300893,-99.8203,37.2355,Clark,ADM2,DISTRICT
SM300552,-112.3514,44.284,Clark,ADM2,DISTRICT
SM300595,-87.7877,39.3336,Clark,ADM2,DISTRICT
SM302353,-93.1764,34.051,Clark,ADM2,DISTRICT
SM302990,-77.9967,39.1123,Clarke,ADM2,DISTRICT
SM301429,-88.6894,32.0414,Clarke,ADM2,DISTRICT
SM300796,-93.7852,41.029,Clarke,ADM2,DISTRICT
SM300461,-83.3673,33.9512,Clarke,ADM2,DISTRICT
SM300250,-87.8308,31.6767,Clarke,ADM2,DISTRICT
SM12237,-77.5333,18.4224,Clarks Town,ADM2,DISTRICT
SM263318,-44.233,-17.0968,Claro dos Poções,ADM2,DISTRICT
SM228973,49.5659,36.1203,Classical Mandaic,LANGUAGE,LANGUAGE
SM228970,-96.97,19.72,Classical Nahuatl,LANGUAGE,LANGUAGE
SM228971,-76.5,-10,Classical Quechua,LANGUAGE,LANGUAGE
SM228972,42.1432,37.1129,Classical Syriac,LANGUAGE,LANGUAGE
SM228974,92.01,29.24,Classical Tibetan,LANGUAGE,LANGUAGE
SM228969,41.3464,38.5923,Classical-Middle Armenian,LANGUAGE,LANGUAGE
SM252918,-123.41,45.75,Clatskanie,ETHNICITY,ETHNICITY
SM302279,-123.7168,46.0175,Clatsop,ADM2,DISTRICT
SM252919,-123.75,46.03,Clatsop,ETHNICITY,ETHNICITY
SM228773,-119.166,45.1729,Clatsop-Shoalwater Chinook,LANGUAGE,LANGUAGE
SM12238,-76.6392,18.1488,Claverty Cottage,ADM2,DISTRICT
SM303161,-81.0751,38.4625,Clay,ADM2,DISTRICT
SM302549,-85.5439,36.5512,Clay,ADM2,DISTRICT
SM302826,-98.2085,33.7855,Clay,ADM2,DISTRICT
SM302482,-96.9757,42.9147,Clay,ADM2,DISTRICT
SM301945,-83.7502,35.0572,Clay,ADM2,DISTRICT
SM301687,-98.0513,40.5244,Clay,ADM2,DISTRICT
SM301539,-94.4209,39.3105,Clay,ADM2,DISTRICT
SM301343,-96.4907,46.8923,Clay,ADM2,DISTRICT
SM301430,-88.7816,33.6557,Clay,ADM2,DISTRICT
SM300797,-95.1509,43.0826,Clay,ADM2,DISTRICT
SM301027,-83.7147,37.1597,Clay,ADM2,DISTRICT
SM300693,-87.1158,39.3927,Clay,ADM2,DISTRICT
SM300894,-97.1652,39.3497,Clay,ADM2,DISTRICT
SM300596,-88.4902,38.7542,Clay,ADM2,DISTRICT
SM300463,-84.9801,31.6263,Clay,ADM2,DISTRICT
SM300314,-81.8579,29.9831,Clay,ADM2,DISTRICT
SM300251,-85.8606,33.2691,Clay,ADM2,DISTRICT
SM302354,-90.4174,36.3684,Clay,ADM2,DISTRICT
SM300798,-91.3414,42.8448,Clayton,ADM2,DISTRICT
SM300464,-84.3577,33.5419,Clayton,ADM2,DISTRICT
SM303320,-105.6444,39.6891,Clear Creek,ADM2,DISTRICT
SM252920,-122.94,39.12,Clear Lake Pomo,ETHNICITY,ETHNICITY
SM302293,-78.4741,41.0002,Clearfield,ADM2,DISTRICT
SM301344,-95.379,47.5777,Clearwater,ADM2,DISTRICT
SM300553,-115.6569,46.6737,Clearwater,ADM2,DISTRICT
SM10126,-115.7161,52.2757,Clearwater County (incl. Reserves),ADM2,DISTRICT
SM300252,-85.5188,33.6746,Cleburne,ADM2,DISTRICT
SM302355,-92.0267,35.5381,Cleburne,ADM2,DISTRICT
SM292093,26.921,46.4018,Cleja,ADM2,DISTRICT
SM290607,25.7356,44.3158,Clejani,ADM2,DISTRICT
SM10394,-75.3333,10.5833,Clemencia,ADM2,DISTRICT
SM266471,-50.462,-21.5763,Clementina,ADM2,DISTRICT
SM302087,-84.1519,39.0474,Clermont,ADM2,DISTRICT
SM281365,6.0224,50.0785,Clervaux,ADM1:ADM2,DISTRICT
SM370424,6.0314,50.0547,Clervaux (pre-2009),ADM2:ADM3,DISTRICT
SM261687,136.2889,-33.6608,Cleve,ADM2,DISTRICT
SM302177,-97.3264,35.203,Cleveland,ADM2,DISTRICT
SM301946,-81.5556,35.3341,Cleveland,ADM2,DISTRICT
SM302356,-92.1851,33.8984,Cleveland,ADM2,DISTRICT
SM459293,146.8648,-19.2935,Cleveland Bay,ETHNICITY,ETHNICITY
SM264823,-52.368,-26.3053,Clevelândia,ADM2,DISTRICT
SM290950,25.9245,44.3764,Clinceni,ADM2,DISTRICT
SM300465,-82.7062,30.915,Clinch,ADM2,DISTRICT
SM302294,-77.6381,41.2341,Clinton,ADM2,DISTRICT
SM302088,-83.8084,39.415,Clinton,ADM2,DISTRICT
SM301868,-73.6782,44.7462,Clinton,ADM2,DISTRICT
SM301261,-84.6015,42.9437,Clinton,ADM2,DISTRICT
SM301540,-94.4046,39.6018,Clinton,ADM2,DISTRICT
SM301028,-85.136,36.7275,Clinton,ADM2,DISTRICT
SM300694,-86.4752,40.3017,Clinton,ADM2,DISTRICT
SM300799,-90.532,41.898,Clinton,ADM2,DISTRICT
SM300597,-89.4225,38.6065,Clinton,ADM2,DISTRICT
SM323981,-113.4455,52.4786,Clive,ADM2:ADM3,DISTRICT
SM261619,140.2712,-20.6989,Cloncurry,ADM2,DISTRICT
SM12239,-76.8314,18.2428,Clonmel,ADM2,DISTRICT
SM300895,-97.6493,39.4803,Cloud,ADM2,DISTRICT
SM984,23.8184,46.6494,Cluj,ADM1,DISTRICT
SM290086,23.6051,46.7779,Cluj-napoca,ADM2,DISTRICT
SM286493,169.6062,-46.0388,Clutha,ADM2,DISTRICT
SM324071,-113.6338,54.1488,Clyde,ADM2:ADM3,DISTRICT
SM263148,-54.9983,-11.4509,Cláudia,ADM2,DISTRICT
SM263319,-44.7914,-20.3901,Cláudio,ADM2,DISTRICT
SM15617,-66.5621,18.2141,Coabey Barrio,ADM2,DISTRICT
SM281905,-99.1089,19.6333,Coacalco de Berriozábal,ADM2,DISTRICT
SM282872,-96.9487,19.674,Coacoatzintla,ADM2,DISTRICT
SM301431,-90.6027,34.2292,Coahoma,ADM2,DISTRICT
SM282014,-103.6371,18.7291,Coahuayana,ADM2,DISTRICT
SM985,-102,27.3333,Coahuila,ADM1,DISTRICT
SM252921,-103.96,29.98,Coahuiltecan,ETHNICITY,ETHNICITY
SM252921,-103.96,29.98,Coahuiltecan,ETHNICITY,ETHNICITY
SM252921,-103.96,29.98,Coahuiltecan,ETHNICITY,ETHNICITY
SM252921,-99,29,Coahuiltecan,ETHNICITY,ETHNICITY
SM252921,-99,29,Coahuiltecan,ETHNICITY,ETHNICITY
SM252921,-99,29,Coahuiltecan,ETHNICITY,ETHNICITY
SM252921,-102.07,26,Coahuiltecan,ETHNICITY,ETHNICITY
SM252921,-102.07,26,Coahuiltecan,ETHNICITY,ETHNICITY
SM252921,-102.07,26,Coahuiltecan,ETHNICITY,ETHNICITY
SM228980,-97.89,28.2589,Coahuilteco,LANGUAGE,LANGUAGE
SM259585,-97.7295,20.2588,Coahuitlán Totonac,LANGUAGE,LANGUAGE
SM302178,-96.2979,34.5882,Coal,ADM2,DISTRICT
SM282015,-103.1039,18.6678,Coalcomán de Vázquez Pallares,ADM2,DISTRICT
SM323843,-112.618,49.7249,Coaldale,ADM2:ADM3,DISTRICT
SM323844,-112.9262,49.7429,Coalhurst,ADM2:ADM3,DISTRICT
SM986,-66.3621,18.098,Coamo,ADM1,DISTRICT
SM15618,-66.3675,18.1417,Coamo Arriba Barrio,ADM2,DISTRICT
SM15619,-66.3603,18.0787,Coamo Barrio-Pueblo,ADM2,DISTRICT
SM252922,-52.84,-2.24,Coani,ETHNICITY,ETHNICITY
SM283131,-93.1667,17.1333,Coapilla,ADM2,DISTRICT
SM267778,-39.5938,-14.6449,Coaraci,ADM2,DISTRICT
SM267316,-64.1511,-4.054,Coari,ADM2,DISTRICT
SM290810,27.097,47.402,Coarnele Caprei,ADM2,DISTRICT
SM228990,-122.994,38.3285,Coast Miwok,LANGUAGE,LANGUAGE
SM252923,-123.77,39.34,Coast Yuki,ETHNICITY,ETHNICITY
SM252923,-123.77,39.34,Coast Yuki,ETHNICITY,ETHNICITY
SM252923,-123.77,39.34,Coast Yuki,ETHNICITY,ETHNICITY
SM252923,-123.74,39.64,Coast Yuki,ETHNICITY,ETHNICITY
SM252923,-123.74,39.64,Coast Yuki,ETHNICITY,ETHNICITY
SM252923,-123.74,39.64,Coast Yuki,ETHNICITY,ETHNICITY
SM252923,-123.6,39.4,Coast Yuki,ETHNICITY,ETHNICITY
SM252923,-123.6,39.4,Coast Yuki,ETHNICITY,ETHNICITY
SM252923,-123.6,39.4,Coast Yuki,ETHNICITY,ETHNICITY
SM228983,116.063,5.7707,Coastal Kadazan,LANGUAGE,LANGUAGE
SM228984,120.304,-5.4153,Coastal Konjo,LANGUAGE,LANGUAGE
SM228345,123.403,13.7574,Coastal-Naga Bikol,LANGUAGE,LANGUAGE
SM245171,124.29,13.6989,Coastal-Virac Bikol,LANGUAGE,LANGUAGE
SM14254,-96.6166,16.5202,Coatecas Altas,ADM2,DISTRICT
SM229014,-96.7336,16.5609,Coatecas Altas Zapotec,LANGUAGE,LANGUAGE
SM282873,-96.9394,19.4513,Coatepec,ADM2,DISTRICT
SM282386,-97.7307,20.0624,Coatepec,ADM2,DISTRICT
SM281906,-99.7919,18.9378,Coatepec Harinas,ADM2,DISTRICT
SM229010,-99.7114,18.32,Coatepec Nahuatl,LANGUAGE,LANGUAGE
SM275229,-91.9793,14.6431,Coatepeque,ADM2,DISTRICT
SM295551,-89.4828,13.9311,Coatepeque,ADM2,DISTRICT
SM229008,-95.4659,16.9404,Coatlán Mixe,LANGUAGE,LANGUAGE
SM229013,-96.7683,16.1463,Coatlán Zapotec,LANGUAGE,LANGUAGE
SM282126,-99.4488,18.7344,Coatlán del Río,ADM2,DISTRICT
SM14255,-94.3892,18.125,Coatzacoalcos (1988-),ADM2,DISTRICT
SM282387,-98.1659,18.5947,Coatzingo,ADM2,DISTRICT
SM282875,-97.5111,20.4501,Coatzintla,ADM2,DISTRICT
SM229011,-96.7636,18.0487,Coatzospan Mixtec,LANGUAGE,LANGUAGE
SM290152,28.167,44.0257,Cobadin,ADM2,DISTRICT
SM261520,145.3606,-31.9661,Cobar,ADM2,DISTRICT
SM300466,-84.5767,33.9415,Cobb,ADM2,DISTRICT
SM12240,-77.47,18.1406,Cobbla,ADM2,DISTRICT
SM262133,0.9997,10.4922,Cobly,ADM2,DISTRICT
SM275167,-90.5403,15.7098,Cobán,ADM2,DISTRICT
SM265200,-41.5459,-3.4375,Cocal,ADM2,DISTRICT
SM265198,-41.9957,-4.6486,Cocal de Telha,ADM2,DISTRICT
SM266397,-49.3258,-28.5999,Cocal do Sul,ADM2,DISTRICT
SM265199,-41.4518,-3.5738,Cocal dos Alves,ADM2,DISTRICT
SM9906,-51.1534,-13.8027,Cocalinho (1999-),ADM2,DISTRICT
SM268248,-48.576,-15.659,Cocalzinho de Goiás,ADM2,DISTRICT
SM252925,-68.69,-4.03,Cocama,ETHNICITY,ETHNICITY
SM252925,-68.69,-4.03,Cocama,ETHNICITY,ETHNICITY
SM252925,-68.69,-4.03,Cocama,ETHNICITY,ETHNICITY
SM252925,-69.8,-1.81,Cocama,ETHNICITY,ETHNICITY
SM252925,-69.8,-1.81,Cocama,ETHNICITY,ETHNICITY
SM252925,-69.8,-1.81,Cocama,ETHNICITY,ETHNICITY
SM252925,-72.33,-4.22,Cocama,ETHNICITY,ETHNICITY
SM252925,-72.33,-4.22,Cocama,ETHNICITY,ETHNICITY
SM252925,-72.33,-4.22,Cocama,ETHNICITY,ETHNICITY
SM252925,-79,1,Cocama,ETHNICITY,ETHNICITY
SM252925,-79,1,Cocama,ETHNICITY,ETHNICITY
SM252925,-79,1,Cocama,ETHNICITY,ETHNICITY
SM252925,-69.9819,-4.1335,Cocama,ETHNICITY,ETHNICITY
SM252925,-69.9819,-4.1335,Cocama,ETHNICITY,ETHNICITY
SM252925,-69.9819,-4.1335,Cocama,ETHNICITY,ETHNICITY
SM229018,-74,-4.5,Cocama-Cocamilla,LANGUAGE,LANGUAGE
SM252926,-72.72,-6.38,Cocamilla,ETHNICITY,ETHNICITY
SM987,-65.6667,-17.5,Cochabamba,ADM1,DISTRICT
SM252927,-66.69,-16.1,Cochapampa,ETHNICITY,ETHNICITY
SM229023,-114.077,29.04,Cochimi,LANGUAGE,LANGUAGE
SM252928,-113.91,27.81,Cochimi,ETHNICITY,ETHNICITY
SM260832,-65.8333,-23,Cochinoca,ADM2,DISTRICT
SM289868,27.0113,45.2015,Cochirleanca,ADM2,DISTRICT
SM301795,-109.7511,31.8796,Cochise,ADM2,DISTRICT
SM252929,-106,36,Cochiti,ETHNICITY,ETHNICITY
SM252929,-106,36,Cochiti,ETHNICITY,ETHNICITY
SM252929,-106,35.6,Cochiti,ETHNICITY,ETHNICITY
SM252929,-106,35.6,Cochiti,ETHNICITY,ETHNICITY
SM14256,-98.4139,17.1131,Cochoapa el Grande,ADM2,DISTRICT
SM302828,-102.8285,33.6041,Cochran,ADM2,DISTRICT
SM268887,-82.9998,50.0002,Cochrane,ADM2,DISTRICT
SM323920,-114.469,51.1848,Cochrane,ADM2:ADM3,DISTRICT
SM261355,115.8443,-32.1026,Cockburn,ADM2,DISTRICT
SM12241,-76.8244,18.0036,Cockburn Gardens,ADM2,DISTRICT
SM302550,-83.1212,35.9254,Cocke,ADM2,DISTRICT
SM12242,-77.1584,17.8887,Cockpit,ADM2,DISTRICT
SM988,-80.4167,8.5667,Coclé,ADM1,DISTRICT
SM17295,-60.972,13.7309,Cocoa Dan,ADM2,DISTRICT
SM301796,-111.7705,35.8387,Coconino,ADM2,DISTRICT
SM229027,-114.848,32.376,Cocopa,LANGUAGE,LANGUAGE
SM252930,-114.68,31.85,Cocopa,ETHNICITY,ETHNICITY
SM252930,-114.68,31.85,Cocopa,ETHNICITY,ETHNICITY
SM252930,-115,32,Cocopa,ETHNICITY,ETHNICITY
SM252930,-115,32,Cocopa,ETHNICITY,ETHNICITY
SM291514,25.9414,45.0853,Cocorastii Mislii,ADM2,DISTRICT
SM270200,-75.1698,6.0058,Cocorná,ADM2,DISTRICT
SM303789,-68.7839,10.3198,Cocorote,ADM2,DISTRICT
SM267779,-45.2796,-14.5944,Cocos,ADM2,DISTRICT
SM15620,-66.9126,18.4566,Cocos Barrio,ADM2,DISTRICT
SM229026,96.8337,-12.1933,Cocos Islands Malay,LANGUAGE,LANGUAGE
SM281907,-98.8661,19.2336,Cocotitlán,ADM2,DISTRICT
SM281784,-103.826,20.3861,Cocula,ADM2,DISTRICT
SM281589,-99.7045,18.143,Cocula,ADM2,DISTRICT
SM292263,27.7536,46.8803,Codaesti,ADM2,DISTRICT
SM267317,-63.1473,-3.1626,Codajás,ADM2,DISTRICT
SM13474,24.2077,56.4695,Codes pagasts,ADM2,DISTRICT
SM302483,-97.1886,44.9778,Codington,ADM2,DISTRICT
SM292620,25.4478,45.6994,Codlea,ADM2,DISTRICT
SM262976,-43.856,-4.5995,Codó,ADM2,DISTRICT
SM262978,-43.1439,-4.1841,Coelho Neto,ADM2,DISTRICT
SM270554,-74.8866,4.3733,Coello,ADM2,DISTRICT
SM282016,-101.6196,19.8107,Coeneo,ADM2,DISTRICT
SM296109,-56.786,2.3574,Coeroeni,ADM2,DISTRICT
SM229029,-74,5.5,Coeruna,LANGUAGE,LANGUAGE
SM229029,-74,5.5,Coeruna,LANGUAGE,LANGUAGE
SM229029,-70.5846,-1.0542,Coeruna,LANGUAGE,LANGUAGE
SM229029,-70.5846,-1.0542,Coeruna,LANGUAGE,LANGUAGE
SM282876,-96.9238,18.7738,Coetzala,ADM2,DISTRICT
SM229030,-116.715,47.2958,Coeur d'Alene,LANGUAGE,LANGUAGE
SM252931,-116.5,47.5,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-116.5,47.5,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-116.5,47.5,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-116.5,47.5,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-117,48,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-117,48,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-117,48,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-117,48,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-116.34,47.42,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-116.34,47.42,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-116.34,47.42,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-116.34,47.42,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-117,47.7,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-117,47.7,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-117,47.7,Coeur d'Alene,ETHNICITY,ETHNICITY
SM252931,-117,47.7,Coeur d'Alene,ETHNICITY,ETHNICITY
SM285552,6.7249,52.7434,Coevorden,ADM2,DISTRICT
SM302551,-86.0748,35.4906,Coffee,ADM2,DISTRICT
SM300467,-82.8492,31.5493,Coffee,ADM2,DISTRICT
SM300253,-85.9882,31.4026,Coffee,ADM2,DISTRICT
SM12243,-77.4065,18.1384,Coffee Piece,ADM2,DISTRICT
SM300896,-95.7341,38.2368,Coffey,ADM2,DISTRICT
SM261521,153.0326,-30.1729,Coffs Harbour,ADM2,DISTRICT
SM229031,-77.0643,0.2767,Cofán,LANGUAGE,LANGUAGE
SM275133,9.8452,1.2231,Cogo,ADM2,DISTRICT
SM269986,-73.9949,5.0847,Cogua,ADM2,DISTRICT
SM229032,-73.8043,10.9485,Cogui,LANGUAGE,LANGUAGE
SM282388,-98.7727,18.2521,Cohetzala,ADM2,DISTRICT
SM282389,-98.7114,18.7943,Cohuecán,ADM2,DISTRICT
SM252933,-79.39,9.26,Coiba Cacique,ETHNICITY,ETHNICITY
SM14257,-98.1837,17.2159,Coicoyan de las Flores,ADM2,DISTRICT
SM229033,-98.2759,17.273,Coicoyán,DIALECT,LANGUAGE
SM269067,-72.3203,-45.6304,Coihaique,ADM2,DISTRICT
SM277240,77.09,10.8,Coimbatore (2008-),ADM2,DISTRICT
SM289480,-8.4268,40.2103,Coimbra,ADM2,DISTRICT
SM263320,-42.7983,-20.8449,Coimbra,ADM2,DISTRICT
SM989,-8.3296,40.1925,Coimbra,ADM1,DISTRICT
SM265352,-36.6004,-9.6262,Coité do Nóia,ADM2,DISTRICT
SM265201,-42.2658,-5.1166,Coivaras,ADM2,DISTRICT
SM290250,25.8557,44.7186,Cojasca,ADM2,DISTRICT
SM990,-68.3333,9.3333,Cojedes,ADM1,DISTRICT
SM290087,23.8395,46.7214,Cojocna,ADM2,DISTRICT
SM282017,-102.8701,20.114,Cojumatlán de Régules,ADM2,DISTRICT
SM295648,-88.9667,13.7667,Cojutepeque,ADM2,DISTRICT
SM302829,-100.5299,31.8885,Coke,ADM2,DISTRICT
SM229037,103.451,-3.5225,Col,LANGUAGE,LANGUAGE
SM229034,143.4224,-38.4713,Colac,LANGUAGE,LANGUAGE
SM261223,143.5967,-38.4387,Colac-Otway,ADM2,DISTRICT
SM264154,-48.2534,-0.9089,Colares,ADM2,DISTRICT
SM9907,-40.6629,-19.4624,Colatina (1997-),ADM2,DISTRICT
SM300254,-87.805,34.7004,Colbert,ADM2,DISTRICT
SM291515,26.3644,44.9374,Colceag,ADM2,DISTRICT
SM269114,-71.0761,-34.6949,Colchagua,ADM2,DISTRICT
SM268834,-63.2487,45.3335,Colchester,ADM2,DISTRICT
SM324064,-110.2466,54.4217,Cold Lake,ADM2:ADM3,DISTRICT
SM327926,-97.9026,50.6458,Coldwell,ADM2:ADM3,DISTRICT
SM301541,-92.2816,38.5054,Cole,ADM2,DISTRICT
SM12244,-77.0852,18.3797,Cole Gate,ADM2,DISTRICT
SM302830,-99.4536,31.7732,Coleman,ADM2,DISTRICT
SM300598,-88.2218,39.5203,Coles,ADM2,DISTRICT
SM12245,-77.5192,18.1999,Coleyville,ADM2,DISTRICT
SM301862,-104.6469,36.6061,Colfax,ADM2,DISTRICT
SM301688,-97.0865,41.574,Colfax,ADM2,DISTRICT
SM290560,26.1924,44.2184,Colibasi,ADM2,DISTRICT
SM283317,-103.6801,19.1795,Colima,ADM2,DISTRICT
SM991,-103.9,19.1333,Colima,ADM1,DISTRICT
SM273450,-80.0167,-1.55,Colimes,ADM2,DISTRICT
SM303584,-69.4167,11.2833,Colina,ADM2,DISTRICT
SM266472,-48.5816,-20.7523,Colina,ADM2,DISTRICT
SM265611,-51.8683,-29.3928,Colinas,ADM2,DISTRICT
SM262979,-44.1171,-5.9893,Colinas,ADM2,DISTRICT
SM268249,-48.0512,-14.0122,Colinas do Sul,ADM2,DISTRICT
SM267220,-48.5214,-8.0945,Colinas do Tocantins,ADM2,DISTRICT
SM282878,-96.7007,19.9331,Colipa,ADM2,DISTRICT
SM252935,-72.02,-15.58,Collagua,ETHNICITY,ETHNICITY
SM302438,-80.6497,32.8434,Colleton,ADM2,DISTRICT
SM261356,116.1656,-33.2735,Collie,ADM2,DISTRICT
SM300315,-81.381,26.0992,Collier,ADM2,DISTRICT
SM302831,-96.5724,33.1879,Collin,ADM2,DISTRICT
SM992,2.1667,8,Collines,ADM1,DISTRICT
SM302832,-100.2701,34.9649,Collingsworth,ADM2,DISTRICT
SM457456,6.3333,37,Collo District,ADM2,DISTRICT
SM228966,106.8481,-6.2772,Colloquial Jakarta Indonesian,DIALECT,LANGUAGE
SM237172,103,1.8586,Colloquial Malay,LANGUAGE,LANGUAGE
SM15621,-65.8202,18.1747,Collores Barrio (Humacao),ADM2,DISTRICT
SM15624,-66.6264,18.1914,Collores Barrio (Jayuya),ADM2,DISTRICT
SM15622,-66.5392,18.117,Collores Barrio (Juana Díaz),ADM2,DISTRICT
SM15623,-65.8501,18.1862,Collores Barrio (Las Piedras),ADM2,DISTRICT
SM15625,-66.487,18.2414,Collores Barrio (Orocovis),ADM2,DISTRICT
SM15626,-66.8871,18.0873,Collores Barrio (Yauco),ADM2,DISTRICT
SM260926,-70.1667,-40.0833,Collón Curá,ADM2,DISTRICT
SM370533,6.0892,49.8136,Colmar-Berg,ADM1:ADM2:ADM3,DISTRICT
SM267221,-48.7579,-8.8766,Colméia,ADM2,DISTRICT
SM9908,-60.3046,-9.2919,Colniza,ADM2:ADM3,DISTRICT
SM275719,-88.8772,14.3119,Cololaca,ADM2,DISTRICT
SM275230,-91.7469,14.71,Colomba,ADM2,DISTRICT
SM17296,-61.0499,13.8686,Colombette,ADM2,DISTRICT
SM270993,-77.4231,20.9862,Colombia,ADM2,DISTRICT
SM270115,-74.7203,3.3993,Colombia,ADM2,DISTRICT
SM232905,-76.8606,1.0031,Colombian Inga,LANGUAGE,LANGUAGE
SM229038,-75.4464,5.3353,Colombian Sign Language,LANGUAGE,LANGUAGE
SM993,80.0166,6.8664,Colombo,ADM1:ADM2:ADME:ADMX,DISTRICT
SM264824,-49.1697,-25.3013,Colombo,ADM2,DISTRICT
SM275667,-88.2767,13.9725,Colomoncagua,ADM2,DISTRICT
SM12246,-77.2555,18.1353,Colonels Ridge,ADM2,DISTRICT
SM291397,24.6767,44.6235,Colonesti,ADM2,DISTRICT
SM292094,27.2658,46.6047,Colonesti,ADM2,DISTRICT
SM996,-57.6667,-34.1667,Colonia,ADM1,DISTRICT
SM15325,-56.1674,-25.762,Colonia Independencia (1993-),ADM2,DISTRICT
SM229039,-67.2579,10.4853,Colonia Tovar German,LANGUAGE,LANGUAGE
SM302993,-77.4103,37.244,Colonial Heights,ADM2,DISTRICT
SM302833,-96.5263,29.6208,Colorado,ADM2,DISTRICT
SM265612,-52.9909,-28.4832,Colorado,ADM2,DISTRICT
SM264825,-51.9802,-22.8383,Colorado,ADM2,DISTRICT
SM997,-105.5008,39.0003,Colorado,ADM1,DISTRICT
SM252937,-78.82,-0.87,Colorado,ETHNICITY,ETHNICITY
SM266078,-60.4942,-13.0825,Colorado do Oeste,ADM2,DISTRICT
SM270546,-75.3623,9.5118,Colosó,ADM2,DISTRICT
SM275497,-91.7144,15.4072,Colotenango,ADM2,DISTRICT
SM281785,-103.2863,22.0865,Colotlán,ADM2,DISTRICT
SM300468,-83.7689,31.1884,Colquitt,ADM2,DISTRICT
SM273601,-78.75,-1.7,Colta,ADM2,DISTRICT
SM289869,26.4072,45.3911,Colti,ADM2,DISTRICT
SM303223,-89.3337,43.4666,Columbia,ADM2,DISTRICT
SM303105,-117.9079,46.2976,Columbia,ADM2,DISTRICT
SM302280,-123.0881,45.9438,Columbia,ADM2,DISTRICT
SM302295,-76.4052,41.0487,Columbia,ADM2,DISTRICT
SM301869,-73.6319,42.2501,Columbia,ADM2,DISTRICT
SM300316,-82.6215,30.2242,Columbia,ADM2,DISTRICT
SM300469,-82.2641,33.5441,Columbia,ADM2,DISTRICT
SM302357,-93.2273,33.2143,Columbia,ADM2,DISTRICT
SM252938,-119.5,47,Columbia,ETHNICITY,ETHNICITY
SM268769,-117.669,51.4166,Columbia-Shuswap,ADM2,DISTRICT
SM229044,-118.454,48.217,Columbia-Wenatchi,LANGUAGE,LANGUAGE
SM302089,-80.7772,40.7684,Columbiana,ADM2,DISTRICT
SM301947,-78.6551,34.2654,Columbus,ADM2,DISTRICT
SM263321,-42.8281,-18.2684,Coluna,ADM2,DISTRICT
SM303217,-122.237,39.1776,Colusa,ADM2,DISTRICT
SM252939,-118.24,48.82,Colville,ETHNICITY,ETHNICITY
SM263150,-55.4457,-10.5633,Colíder,ADM2,DISTRICT
SM303811,-72.0833,9,Colón,ADM2,DISTRICT
SM286697,-79.7946,9.2431,Colón,ADM2,DISTRICT
SM282459,-100.082,20.7253,Colón,ADM2,DISTRICT
SM295672,-89.3528,13.7147,Colón,ADM2,DISTRICT
SM271007,-80.8504,22.7311,Colón,ADM2,DISTRICT
SM270401,-76.9653,1.2314,Colón,ADM2,DISTRICT
SM270360,-77.0293,1.6285,Colón,ADM2,DISTRICT
SM260746,-61,-33.8333,Colón,ADM2,DISTRICT
SM261141,-64.1667,-31.1667,Colón,ADM2,DISTRICT
SM994,-85.6667,15.75,Colón,ADM1,DISTRICT
SM995,-80,9.1667,Colón,ADM1,DISTRICT
SM266473,-48.7233,-20.293,Colômbia,ADM2,DISTRICT
SM265353,-35.7601,-8.9418,Colônia Leopoldina,ADM2,DISTRICT
SM265203,-43.7746,-8.1447,Colônia do Gurguéia,ADM2,DISTRICT
SM265204,-42.2126,-7.2264,Colônia do Piauí,ADM2,DISTRICT
SM295760,-88.0578,13.5291,Comacarán,ADM2,DISTRICT
SM302834,-98.2783,29.8082,Comal,ADM2,DISTRICT
SM283318,-103.7442,19.3805,Comala,ADM2,DISTRICT
SM285522,-85.5599,12.1938,Comalapa,ADM2,DISTRICT
SM275398,-90.8979,14.746,Comalapa,ADM2,DISTRICT
SM295636,-88.9606,14.1631,Comalapa,ADM2,DISTRICT
SM282633,-93.3041,18.2654,Comalcalco,ADM2,DISTRICT
SM229048,-96.445,17.5909,Comaltepec Chinantec,LANGUAGE,LANGUAGE
SM290561,26.1491,44.1797,Comana,ADM2,DISTRICT
SM290154,28.3549,43.8996,Comana,ADM2,DISTRICT
SM292621,25.2208,45.9136,Comana De Jos,ADM2,DISTRICT
SM229047,-101,34,Comanche,LANGUAGE,LANGUAGE
SM302835,-98.5583,31.948,Comanche,ADM2,DISTRICT
SM302179,-98.4717,34.6621,Comanche,ADM2,DISTRICT
SM300897,-99.2719,37.1913,Comanche,ADM2,DISTRICT
SM252940,-105.14,40.17,Comanche,ETHNICITY,ETHNICITY
SM252940,-105.14,40.17,Comanche,ETHNICITY,ETHNICITY
SM252940,-105.14,40.17,Comanche,ETHNICITY,ETHNICITY
SM252940,-105.14,40.17,Comanche,ETHNICITY,ETHNICITY
SM252940,-105.14,40.17,Comanche,ETHNICITY,ETHNICITY
SM252940,-100.5,34,Comanche,ETHNICITY,ETHNICITY
SM252940,-100.5,34,Comanche,ETHNICITY,ETHNICITY
SM252940,-100.5,34,Comanche,ETHNICITY,ETHNICITY
SM252940,-100.5,34,Comanche,ETHNICITY,ETHNICITY
SM252940,-100.5,34,Comanche,ETHNICITY,ETHNICITY
SM252940,-100,33,Comanche,ETHNICITY,ETHNICITY
SM252940,-100,33,Comanche,ETHNICITY,ETHNICITY
SM252940,-100,33,Comanche,ETHNICITY,ETHNICITY
SM252940,-100,33,Comanche,ETHNICITY,ETHNICITY
SM252940,-100,33,Comanche,ETHNICITY,ETHNICITY
SM252940,-100.5,36.83,Comanche,ETHNICITY,ETHNICITY
SM252940,-100.5,36.83,Comanche,ETHNICITY,ETHNICITY
SM252940,-100.5,36.83,Comanche,ETHNICITY,ETHNICITY
SM252940,-100.5,36.83,Comanche,ETHNICITY,ETHNICITY
SM252940,-100.5,36.83,Comanche,ETHNICITY,ETHNICITY
SM252940,-101.5,34,Comanche,ETHNICITY,ETHNICITY
SM252940,-101.5,34,Comanche,ETHNICITY,ETHNICITY
SM252940,-101.5,34,Comanche,ETHNICITY,ETHNICITY
SM252940,-101.5,34,Comanche,ETHNICITY,ETHNICITY
SM252940,-101.5,34,Comanche,ETHNICITY,ETHNICITY
SM261100,-60.5,-26.8333,Comandante Fernández,ADM2,DISTRICT
SM290211,26.2778,45.7618,Comandau,ADM2,DISTRICT
SM292095,26.428,46.4235,Comanesti,ADM2,DISTRICT
SM282879,-96.7137,19.1389,Comapa,ADM2,DISTRICT
SM275192,-89.9008,14.1141,Comapa,ADM2,DISTRICT
SM291516,25.6386,45.2496,Comarnic,ADM2,DISTRICT
SM295673,-89.3623,13.6106,Comasagua,ADM2,DISTRICT
SM275850,-87.6006,14.4841,Comayagua,ADM2,DISTRICT
SM998,-87.6667,14.5833,Comayagua,ADM1,DISTRICT
SM267223,-46.5342,-12.8232,Combinado,ADM2,DISTRICT
SM252941,-65.04,-28.14,Comechingon,ETHNICITY,ETHNICITY
SM229049,-98.4667,26.1333,Comecrudan,LANGUAGE,LANGUAGE
SM271778,-71.6667,18.8833,Comendador,ADM2,DISTRICT
SM263322,-49.0874,-19.6533,Comendador Gomes,ADM2,DISTRICT
SM265259,-43.2531,-22.0365,Comendador Levy Gasparian,ADM2,DISTRICT
SM263323,-41.7735,-16.289,Comercinho,ADM2,DISTRICT
SM15627,-66.2244,18.2203,Comerio Barrio-Pueblo,ADM2,DISTRICT
SM999,-66.2218,18.2272,Comerío,ADM1,DISTRICT
SM12247,-77.4248,18.0539,Comfort,ADM2,DISTRICT
SM12248,-76.4161,18.0483,Comfort Castle,ADM2,DISTRICT
SM12249,-77.6104,18.1879,Comfort Hall (Manchester),ADM2,DISTRICT
SM18540,-77.9181,18.4127,Comfort Hall (Saint James),ADM2,DISTRICT
SM262236,91.0099,23.4417,Comilla,ADM2,DISTRICT
SM290251,25.5701,44.8753,Comisani,ADM2,DISTRICT
SM275302,-91.7411,15.1082,Comitancillo,ADM2,DISTRICT
SM283132,-92.132,16.2411,Comitán de Domínguez,ADM2,DISTRICT
SM291965,20.6294,45.8755,Comlosu Mare,ADM2,DISTRICT
SM1000,-54.9167,5.75,Commewijne,ADM1,DISTRICT
SM3970,-10.6674,6.3901,Commonealth District (Montserrado),ADM2,DISTRICT
SM280966,-11.3202,6.7024,Commonwealth,ADM2,DISTRICT
SM280966,-11.3202,6.7024,Commonwealth,ADM2,DISTRICT
SM280966,-11.3202,6.7024,Commonwealth,ADM2,DISTRICT
SM280966,-11.2912,6.696,Commonwealth,ADM2,DISTRICT
SM280966,-11.2912,6.696,Commonwealth,ADM2,DISTRICT
SM280966,-11.2912,6.696,Commonwealth,ADM2,DISTRICT
SM280966,-11.25,6.6667,Commonwealth,ADM2,DISTRICT
SM280966,-11.25,6.6667,Commonwealth,ADM2,DISTRICT
SM280966,-11.25,6.6667,Commonwealth,ADM2,DISTRICT
SM268637,-73.6825,45.5001,Communauté-Urbaine-de-Montréal,ADM2,DISTRICT
SM8028,7.4198,43.7322,Commune de Monaco,ADM1:ADM2,DISTRICT
SM9825,1.7026,6.5692,Commune of Athieme (excl. Lokossa),ADM2,DISTRICT
SM277868,9.1574,45.9125,Como,ADM2,DISTRICT
SM229056,10.816,8.866,Como Karim,LANGUAGE,LANGUAGE
SM263151,-59.8215,-13.3258,Comodoro,ADM2,DISTRICT
SM282201,-111.8167,26.0667,Comondú (pre-1992),ADM2,DISTRICT
SM281578,-100.7859,20.7281,Comonfort,ADM2,DISTRICT
SM229057,-124.967,49.7233,Comox,LANGUAGE,LANGUAGE
SM252942,-125.38,49.88,Comox,ETHNICITY,ETHNICITY
SM252942,-125.38,49.88,Comox,ETHNICITY,ETHNICITY
SM252942,-125.38,49.88,Comox,ETHNICITY,ETHNICITY
SM252942,-124.97,49.72,Comox,ETHNICITY,ETHNICITY
SM252942,-124.97,49.72,Comox,ETHNICITY,ETHNICITY
SM252942,-124.97,49.72,Comox,ETHNICITY,ETHNICITY
SM252942,-125.5,50,Comox,ETHNICITY,ETHNICITY
SM252942,-125.5,50,Comox,ETHNICITY,ETHNICITY
SM252942,-125.5,50,Comox,ETHNICITY,ETHNICITY
SM10172,-124.982,49.687,Comox Valley Regional District,ADM2,DISTRICT
SM1001,-3.2511,5.5039,Comoé,ADM1,DISTRICT
SM17297,-61.0593,13.8359,Compar,ADM2,DISTRICT
SM14259,-105.0899,21.1479,Compostela (1989-),ADM2,DISTRICT
SM1002,126.1167,7.6833,Compostela Valley,ADM1:ADM2,DISTRICT
SM9677,-58.3715,-34.6064,Comuna 1,ADM2,DISTRICT
SM16362,26.0618,44.2887,Comuna 1 Decembrie (2005-),ADM2,DISTRICT
SM9678,-58.5028,-34.6279,Comuna 10,ADM2,DISTRICT
SM9679,-58.4967,-34.6061,Comuna 11,ADM2,DISTRICT
SM9680,-58.4904,-34.5663,Comuna 12,ADM2,DISTRICT
SM9681,-58.454,-34.5543,Comuna 13,ADM2,DISTRICT
SM9682,-58.4222,-34.5738,Comuna 14,ADM2,DISTRICT
SM9683,-58.4628,-34.5919,Comuna 15,ADM2,DISTRICT
SM9684,-58.3949,-34.5858,Comuna 2,ADM2,DISTRICT
SM9685,-58.4027,-34.6138,Comuna 3,ADM2,DISTRICT
SM9686,-58.3875,-34.6421,Comuna 4,ADM2,DISTRICT
SM9687,-58.4206,-34.6174,Comuna 5,ADM2,DISTRICT
SM9688,-58.4436,-34.6168,Comuna 6,ADM2,DISTRICT
SM9689,-58.4519,-34.6366,Comuna 7,ADM2,DISTRICT
SM9690,-58.4619,-34.6745,Comuna 8,ADM2,DISTRICT
SM9691,-58.4992,-34.6518,Comuna 9,ADM2,DISTRICT
SM16363,26.3082,47.7368,Comuna Adancata (2004-),ADM2,DISTRICT
SM16364,26.9521,48.0629,Comuna Adaseni,ADM2,DISTRICT
SM16365,28.6268,44.0965,Comuna Agigea,ADM2,DISTRICT
SM16366,23.0976,47.0678,Comuna Agrij (1995-),ADM2,DISTRICT
SM16367,23.0034,47.8738,Comuna Agris,ADM2,DISTRICT
SM16368,27.1246,44.5358,Comuna Albesti (2004-),ADM2,DISTRICT
SM16369,25,45.3167,Comuna Albestii de Muscel,ADM2,DISTRICT
SM16370,27.6242,46.4497,Comuna Alexandru Vlahuta (2003-),ADM2,DISTRICT
SM16371,24.4683,46.2273,Comuna Alma,ADM2,DISTRICT
SM16372,23.7092,44.4409,Comuna Almaju (2004-),ADM2,DISTRICT
SM16373,24.1672,43.9859,Comuna Amarastii de Sus (2004-),ADM2,DISTRICT
SM16374,25.7767,45.9008,Comuna Arcus,ADM2,DISTRICT
SM16375,26.58,44.764,Comuna Armasesti (2005-),ADM2,DISTRICT
SM16376,24.483,46.1546,Comuna Atel (2004-),ADM2,DISTRICT
SM16377,25.5533,46.0461,Comuna Augustin,ADM2,DISTRICT
SM16378,26.9573,48.0244,Comuna Avrameni,ADM2,DISTRICT
SM16379,25.3916,44.1391,Comuna Babaita (2004-),ADM2,DISTRICT
SM16380,27.7538,46.0762,Comuna Balabanesti (2004-),ADM2,DISTRICT
SM16381,26.0476,47.6434,Comuna Balaceana,ADM2,DISTRICT
SM16382,26.8828,44.6099,Comuna Balaciu (2005-),ADM2,DISTRICT
SM16383,24.039,44.392,Comuna Baldovinesti (2004-),ADM2,DISTRICT
SM16384,26.9603,47.2922,Comuna Bals,ADM2,DISTRICT
SM16385,26.3019,47.1308,Comuna Baltatesti (2003-),ADM2,DISTRICT
SM16386,24.5325,44.4488,Comuna Balteni,ADM2,DISTRICT
SM16387,24.3403,46.6003,Comuna Band  (2004-),ADM2,DISTRICT
SM16388,21.132,45.386,Comuna Banloc (2006-),ADM2,DISTRICT
SM16389,27.0402,47.0132,Comuna Bara (2004-),ADM2,DISTRICT
SM16390,28.3989,44.0793,Comuna Baraganu,ADM2,DISTRICT
SM16391,26.5993,44.7263,Comuna Barbulesti,ADM2,DISTRICT
SM16392,25.2977,45.142,Comuna Barbuletu (2004-),ADM2,DISTRICT
SM16393,26.0639,45.7048,Comuna Barcani,ADM2,DISTRICT
SM16394,24.0573,47.815,Comuna Barsana (2004-),ADM2,DISTRICT
SM16395,26.7472,45.9072,Comuna Barsesti (2003-),ADM2,DISTRICT
SM16396,26.1509,45.3143,Comuna Batrani,ADM2,DISTRICT
SM16397,21.052,45.8348,Comuna Becicherecu Mic (2004-),ADM2,DISTRICT
SM16398,24.6616,44.0127,Comuna Beciu,ADM2,DISTRICT
SM16399,26.0428,47.5326,Comuna Berchisesti,ADM2,DISTRICT
SM16400,24.8673,46.5699,Comuna Bereni,ADM2,DISTRICT
SM16401,26.8565,46.7087,Comuna Beresti-Bistrita (2005-),ADM2,DISTRICT
SM16402,29.0243,45.1124,Comuna Bestepe,ADM2,DISTRICT
SM16403,24.9793,44.2659,Comuna Beuca,ADM2,DISTRICT
SM16404,21.933,47.16,Comuna Biharea,ADM2,DISTRICT
SM16405,20.9621,45.8876,Comuna Biled (2004-),ADM2,DISTRICT
SM16406,27.3488,45.7211,Comuna Biliesti,ADM2,DISTRICT
SM16407,21.3373,45.4127,Comuna Birda,ADM2,DISTRICT
SM16408,25.8634,46.1023,Comuna Bixad,ADM2,DISTRICT
SM16409,26.8919,47.7122,Comuna Blandesti,ADM2,DISTRICT
SM16410,27.1108,47.0603,Comuna Boghicea,ADM2,DISTRICT
SM16411,22.7271,47.1561,Comuna Boghis,ADM2,DISTRICT
SM16412,24.2465,45.5791,Comuna Boita,ADM2,DISTRICT
SM16414,26.5949,44.6636,Comuna Boranesti (incl. Sintesti),ADM2,DISTRICT
SM16415,26.7597,47.0642,Comuna Botesti (2004-),ADM2,DISTRICT
SM16416,22.9487,47.8329,Comuna Botiz (2004-),ADM2,DISTRICT
SM16417,24.1504,47.6682,Comuna Botiza (1994-),ADM2,DISTRICT
SM16418,25.9434,47.6876,Comuna Botosana (2002-),ADM2,DISTRICT
SM16419,25.352,46.3579,Comuna Bradesti (2004-),ADM2,DISTRICT
SM16420,25.4225,45.0343,Comuna Branesti (2002-),ADM2,DISTRICT
SM16421,23.9145,44.1373,Comuna Bratovoesti (2004-),ADM2,DISTRICT
SM16423,23.8561,46.2088,Comuna Bucerdea-Granoasa,ADM2,DISTRICT
SM16424,26.7858,46.2135,Comuna Buciumi,ADM2,DISTRICT
SM16425,21.4028,45.7531,Comuna Bucova,ADM2,DISTRICT
SM16426,27.4943,44.6066,Comuna Bucu (2004-),ADM2,DISTRICT
SM16428,27.1873,44.5447,Comuna Buesti,ADM2,DISTRICT
SM16429,25.0328,45.2981,Comuna Bughea de Sus (2004-),ADM2,DISTRICT
SM16430,25.927,47.7903,Comuna Burla,ADM2,DISTRICT
SM16431,23.6433,45.8731,Comuna Calnic,ADM2,DISTRICT
SM16432,24.0495,44.456,Comuna Caluiu,ADM2,DISTRICT
SM16433,22.4799,47.7284,Comuna Camin,ADM2,DISTRICT
SM16434,26.2205,47.9233,Comuna Candesti,ADM2,DISTRICT
SM16435,22.1111,46.7368,Comuna Capalnas,ADM2,DISTRICT
SM16436,22.5064,47.7094,Comuna Capleni (2002-),ADM2,DISTRICT
SM16437,25.9796,47.5082,Comuna Capu Campului,ADM2,DISTRICT
SM16438,23.898,44.2686,Comuna Carcea,ADM2,DISTRICT
SM16439,23.6015,43.8861,Comuna Carna,ADM2,DISTRICT
SM16440,20.9145,45.806,Comuna Carpinis (2004-),ADM2,DISTRICT
SM16441,28.3691,44.2585,Comuna Castelu,ADM2,DISTRICT
SM16442,23.4133,43.9258,Comuna Catane,ADM2,DISTRICT
SM16443,27.8901,45.2717,Comuna Cazasu,ADM2,DISTRICT
SM16444,21.6941,46.909,Comuna Cefa (2003-),ADM2,DISTRICT
SM16445,20.9256,45.7261,Comuna Cenei (2004-),ADM2,DISTRICT
SM16446,22.0546,47.1501,Comuna Cetariu (2003-,ADM2,DISTRICT
SM16447,20.836,45.753,Comuna Checea,ADM2,DISTRICT
SM16448,24.9622,46.5333,Comuna Chibed,ADM2,DISTRICT
SM16449,21.1056,45.5324,Comuna Ciacova (2004-),ADM2,DISTRICT
SM16450,25.7659,46.4127,Comuna Ciceu,ADM2,DISTRICT
SM16451,24.0038,47.2653,Comuna Ciceu-Giurgesti (2002-),ADM2,DISTRICT
SM16452,23.957,47.2174,Comuna Ciceu-Mihaiesti,ADM2,DISTRICT
SM16453,25.2467,47.5008,Comuna Ciocanesti,ADM2,DISTRICT
SM16454,27.5696,46.2539,Comuna Ciocani,ADM2,DISTRICT
SM16456,26.6896,47.1352,Comuna Ciohorani,ADM2,DISTRICT
SM16457,26.064,47.5684,Comuna Ciprian Porumbescu,ADM2,DISTRICT
SM16458,22.8436,46.9598,Comuna Ciucea (2002-),ADM2,DISTRICT
SM16459,22.3345,47.6631,Comuna Ciumesti,ADM2,DISTRICT
SM16460,23.6005,47.5128,Comuna Coas,ADM2,DISTRICT
SM16461,22.0397,46.729,Comuna Cociuba Mare,ADM2,DISTRICT
SM16462,27.0476,44.7375,Comuna Cocora (2005-),ADM2,DISTRICT
SM16463,25.8975,44.8378,Comuna Cocorastii-Colt,ADM2,DISTRICT
SM16464,28.5224,44.5766,Comuna Cogealac,ADM2,DISTRICT
SM16465,27.0069,44.7611,Comuna Colelia,ADM2,DISTRICT
SM16466,23.5345,47.6012,Comuna Coltau,ADM2,DISTRICT
SM16467,25.9866,47.6693,Comuna Comanesti,ADM2,DISTRICT
SM16468,26.0937,44.263,Comuna Copaceni,ADM2,DISTRICT
SM16469,26.8678,47.6112,Comuna Copalau,ADM2,DISTRICT
SM16470,26.5852,47.9312,Comuna Corlateni,ADM2,DISTRICT
SM16471,24.6459,46.5272,Comuna Corunca,ADM2,DISTRICT
SM16472,27.4585,44.561,Comuna Cosambesti (2005-),ADM2,DISTRICT
SM16473,26.561,44.6854,Comuna Cosereni (2004-),ADM2,DISTRICT
SM16474,25.1689,47.3734,Comuna Cosna,ADM2,DISTRICT
SM16475,25.819,44.5227,Comuna Cosoba,ADM2,DISTRICT
SM16476,23.9361,44.2463,Comuna Cosoveni (2004-),ADM2,DISTRICT
SM16477,26.9175,47.2422,Comuna Costesti,ADM2,DISTRICT
SM16478,28.6328,43.9478,Comuna Costinesti,ADM2,DISTRICT
SM16479,26.7855,47.6192,Comuna Cosula,ADM2,DISTRICT
SM16480,23.6705,44.4528,Comuna Cotofenii din Fata,ADM2,DISTRICT
SM16481,25.9427,46.2093,Comuna Cozmeni,ADM2,DISTRICT
SM16482,27.4975,46.7302,Comuna Cozmesti,ADM2,DISTRICT
SM16483,23.8359,46.1728,Comuna Craciunelu de Jos (2006-),ADM2,DISTRICT
SM16484,25.0812,43.8449,Comuna Crangu (2004-),ADM2,DISTRICT
SM16485,26.4385,44.1934,Comuna Crivat,ADM2,DISTRICT
SM16486,25.4674,45.8207,Comuna Crizbav,ADM2,DISTRICT
SM16455,28.5123,44.1075,Comuna Cumpana,ADM2,DISTRICT
SM16487,21.3105,46.3436,Comuna Curtici (2004-),ADM2,DISTRICT
SM16488,23.6672,45.9397,Comuna Cut,ADM2,DISTRICT
SM16490,28.3101,44.2877,Comuna Cuza Voda (Constanta),ADM2,DISTRICT
SM16489,27.7927,45.596,Comuna Cuza Voda (Galati),ADM2,DISTRICT
SM16491,25.9874,45.9224,Comuna Dalnic,ADM2,DISTRICT
SM16492,25.7499,46.5171,Comuna Danesti (2004-),ADM2,DISTRICT
SM291815,26.1268,47.7597,Comuna Darmanesti,ADM2,DISTRICT
SM16494,27.5515,46.6916,Comuna Delesti (2004-),ADM2,DISTRICT
SM16495,26.215,47.9896,Comuna Dersca (2003-),ADM2,DISTRICT
SM16496,23.988,44.6097,Comuna Diculesti,ADM2,DISTRICT
SM16497,26.5436,47.9183,Comuna Dimacheni,ADM2,DISTRICT
SM16498,21.9935,47.2956,Comuna Diosig,ADM2,DISTRICT
SM16499,26.4094,46.9948,Comuna Dobreni (2005-),ADM2,DISTRICT
SM16500,24.1284,43.9675,Comuna Dobrotesti,ADM2,DISTRICT
SM16501,24.233,44.2532,Comuna Dobrun,ADM2,DISTRICT
SM16502,26.5849,46.9174,Comuna Dochia,ADM2,DISTRICT
SM16503,27.9179,46.3517,Comuna Dodesti,ADM2,DISTRICT
SM16504,25.2374,47.3459,Comuna Dorna Candrenilor (2003-),ADM2,DISTRICT
SM16505,21.2444,46.3502,Comuna Dorobanti,ADM2,DISTRICT
SM16506,25.0318,43.8718,Comuna Dracea,ADM2,DISTRICT
SM16507,25.0037,44.2072,Comuna Dracsenei (2004-),ADM2,DISTRICT
SM16508,26.0918,47.5351,Comuna Dragoesti (2005-),ADM2,DISTRICT
SM16509,24.7791,45.7615,Comuna Dragus,ADM2,DISTRICT
SM16510,27.4949,46.8943,Comuna Draguseni,ADM2,DISTRICT
SM16511,26.4531,44.7039,Comuna Dridu (2005-),ADM2,DISTRICT
SM16512,21.1022,45.8399,Comuna Dudestii Noi,ADM2,DISTRICT
SM16513,20.4452,46.0856,Comuna Dudestii Vechi (2005-),ADM2,DISTRICT
SM16514,26.7806,46.9835,Comuna Dulcesti (2003-),ADM2,DISTRICT
SM16515,24.635,47.0678,Comuna Dumitrita (2002-),ADM2,DISTRICT
SM16516,26.2054,46.1254,Comuna Estelnic,ADM2,DISTRICT
SM16517,26.3,47.4,Comuna Fantana Mare,ADM2,DISTRICT
SM16522,21.3811,46.1244,"Comuna Fantanele (Arad, 2014-)",ADM2,DISTRICT
SM16521,28.5766,44.6128,"Comuna Fantanele (Constanta, 2005-)",ADM2,DISTRICT
SM16519,27.1807,47.415,Comuna Fantanele (Iasi),ADM2,DISTRICT
SM16520,26.364,45.0254,"Comuna Fantanele (Prahova, 2004-)",ADM2,DISTRICT
SM16518,25.2926,43.721,Comuna Fantanele (Teleorman),ADM2,DISTRICT
SM16523,23.7408,44.6127,Comuna Farcasu (2004-),ADM2,DISTRICT
SM16524,24.0087,44.5735,Comuna Fauresti (2004-),ADM2,DISTRICT
SM16525,21.167,46.1222,Comuna Felnac (2004-),ADM2,DISTRICT
SM16526,27.7009,46.7818,Comuna Feresti,ADM2,DISTRICT
SM16527,21.4236,45.9728,Comuna Fibis,ADM2,DISTRICT
SM16528,26.7663,44.908,Comuna Florica,ADM2,DISTRICT
SM16529,27.202,47.3511,Comuna Focuri (2004-),ADM2,DISTRICT
SM16530,25.3782,44.1881,Comuna Frasinet,ADM2,DISTRICT
SM16531,21.4584,46.1018,Comuna Frumuseni,ADM2,DISTRICT
SM16532,27.7737,46.1981,Comuna Fruntiseni,ADM2,DISTRICT
SM16533,27.0059,46.9371,Comuna Gadinti,ADM2,DISTRICT
SM16534,25.8289,47.9159,Comuna Galanesti (2004-),ADM2,DISTRICT
SM16535,26.4194,44.3172,Comuna Galbinasi,ADM2,DISTRICT
SM16536,23.3892,44.1019,Comuna Galiciuica,ADM2,DISTRICT
SM16537,23.3088,47.5514,Comuna Gardani,ADM2,DISTRICT
SM16538,22.777,44.2109,Comuna Garla Mare (2004-),ADM2,DISTRICT
SM16539,21.408,45.374,Comuna Gataia (2004-),ADM2,DISTRICT
SM16540,24.0087,44.414,Comuna Gavanesti,ADM2,DISTRICT
SM16541,21.789,46.9162,Comuna Gepiu,ADM2,DISTRICT
SM16542,26.2728,44.799,Comuna Gherghita  (2004-),ADM2,DISTRICT
SM16543,23.1945,43.889,Comuna Ghidici,ADM2,DISTRICT
SM16544,21.0647,45.4663,Comuna Ghilad,ADM2,DISTRICT
SM16545,24.783,44.29,Comuna Ghimpeteni,ADM2,DISTRICT
SM16546,26.3391,47.1098,Comuna Ghindaoani,ADM2,DISTRICT
SM16547,28.0319,44.6352,Comuna Ghindaresti,ADM2,DISTRICT
SM16548,24.9441,46.4891,Comuna Ghindari  (2003-),ADM2,DISTRICT
SM16549,23.9234,44.2122,Comuna Ghindeni,ADM2,DISTRICT
SM16550,26.9924,46.4293,Comuna Gioseni,ADM2,DISTRICT
SM16551,21.7686,47.0712,Comuna Girisu de Cris (2007-),ADM2,DISTRICT
SM16552,26.4824,46.9492,Comuna Girov (2003-),ADM2,DISTRICT
SM16553,23.4116,44.1282,Comuna Giubega (2004-),ADM2,DISTRICT
SM16554,23.6185,43.9262,Comuna Goicea (2004-),ADM2,DISTRICT
SM16555,27.2709,45.6098,Comuna Gologanu,ADM2,DISTRICT
SM16556,20.6838,45.9392,Comuna Gottlob,ADM2,DISTRICT
SM16557,28.4485,44.523,Comuna Gradina,ADM2,DISTRICT
SM16558,24.3923,43.9497,Comuna Gradinile,ADM2,DISTRICT
SM16560,27.3223,44.7297,Comuna Grivita (2003-),ADM2,DISTRICT
SM16561,27.6822,46.1683,Comuna Grivita (2004-),ADM2,DISTRICT
SM16559,27.641,45.7004,Comuna Grivita (Galati),ADM2,DISTRICT
SM16562,24.0637,47.4898,Comuna Grosii Tiblesului,ADM2,DISTRICT
SM16563,27.7404,44.7317,Comuna Gura Ialomitei,ADM2,DISTRICT
SM16564,24.3169,43.7628,Comuna Gura Padinii,ADM2,DISTRICT
SM16565,25.5299,45.7674,Comuna Halchiu,ADM2,DISTRICT
SM16566,23.0536,47.9783,Comuna Halmeu (2005-),ADM2,DISTRICT
SM16567,26.3423,47.767,Comuna Hantesti,ADM2,DISTRICT
SM16568,26.8132,47.2709,Comuna Harmanesti,ADM2,DISTRICT
SM16569,25.1134,45.1263,Comuna Hartiesti (2003-),ADM2,DISTRICT
SM16570,26.3723,47.49,Comuna Hartop,ADM2,DISTRICT
SM16571,26.3668,44.2108,Comuna Herasti,ADM2,DISTRICT
SM16572,25.3874,45.6592,Comuna Holbav,ADM2,DISTRICT
SM16573,27.2311,46.158,Comuna Homocea (2004-),ADM2,DISTRICT
SM16574,28.1203,44.6355,Comuna Horia,ADM2,DISTRICT
SM16575,25.8324,47.8609,Comuna Horodnic De Jos (2003-),ADM2,DISTRICT
SM16576,25.8369,47.8319,Comuna Horodnic De Sus (2003-),ADM2,DISTRICT
SM16577,26.3708,44.1754,Comuna Hotarele (2004-),ADM2,DISTRICT
SM16578,25.3243,47.4411,Comuna Iacobeni (2002-),ADM2,DISTRICT
SM16579,25.9774,47.7586,Comuna Iaslovat,ADM2,DISTRICT
SM16580,27.6204,46.4051,Comuna Ibanesti,ADM2,DISTRICT
SM16581,20.8931,45.8507,Comuna Iecea Mare,ADM2,DISTRICT
SM16582,26.0552,47.6167,Comuna Ilisesti (2004-),ADM2,DISTRICT
SM16583,23.5757,44.1123,Comuna Intorsura,ADM2,DISTRICT
SM16584,27.0322,47.2016,Comuna Ion Neculce,ADM2,DISTRICT
SM16585,24.4009,44.3253,Comuna Ipotesti,ADM2,DISTRICT
SM16586,26.8556,46.6737,Comuna Itesti,ADM2,DISTRICT
SM16588,27.5367,46.1949,Comuna Ivesti (2004-),ADM2,DISTRICT
SM16587,27.5247,45.6684,Comuna Ivesti (Galati),ADM2,DISTRICT
SM16589,28.5335,45.0393,Comuna Izvoarele (2004-),ADM2,DISTRICT
SM16590,21.2181,45.5602,Comuna Jebel (2004-),ADM2,DISTRICT
SM16591,25.8721,44.49,Comuna Joita (2004-),ADM2,DISTRICT
SM16592,23.8921,44.7086,Comuna Lacusteni,ADM2,DISTRICT
SM16593,27.5984,46.6524,Comuna Laza (2004-),ADM2,DISTRICT
SM16594,25.8491,46.349,Comuna Leliceni,ADM2,DISTRICT
SM16595,26.2728,46.0708,Comuna Lemnia (2004-),ADM2,DISTRICT
SM16596,24.6702,46.5485,Comuna Livezeni  (2004-),ADM2,DISTRICT
SM16597,21.0618,45.4099,Comuna Livezile,ADM2,DISTRICT
SM16598,20.7695,45.9694,Comuna Lovrin (2004-),ADM2,DISTRICT
SM16599,26.2869,47.9441,Comuna Lozna,ADM2,DISTRICT
SM16600,25.4199,44.8477,Comuna Lucieni (2004-),ADM2,DISTRICT
SM16601,28.5471,44.3276,Comuna Lumina,ADM2,DISTRICT
SM16602,24.7861,43.8579,Comuna Lunca (2004-),ADM2,DISTRICT
SM16603,28.3003,45.2865,Comuna Luncavita (2003-),ADM2,DISTRICT
SM16604,25.7475,46.4947,Comuna Madaras (Harghita),ADM2,DISTRICT
SM16605,24.4345,46.6049,Comuna Madaras (Mureş),ADM2,DISTRICT
SM16606,24.9061,46.5701,Comuna Magherani  (2004-),ADM2,DISTRICT
SM16607,26.551,45.2696,Comuna Magura (2004-),ADM2,DISTRICT
SM16608,24.8219,47.3663,Comuna Magura Ilvei (2003-),ADM2,DISTRICT
SM16609,29.0878,45.0858,Comuna Mahmudia (2004-),ADM2,DISTRICT
SM16610,26.4015,44.7362,Comuna Maia,ADM2,DISTRICT
SM16611,25.8271,46.02,Comuna Malnas,ADM2,DISTRICT
SM16612,25.8186,43.8152,Comuna Malu,ADM2,DISTRICT
SM16613,23.8557,44.2384,Comuna Malu Mare (2004-),ADM2,DISTRICT
SM16614,25.8346,44.8604,Comuna Manesti  (2004-),ADM2,DISTRICT
SM16615,27.5156,44.567,Comuna Marculesti,ADM2,DISTRICT
SM16616,21.4548,45.9964,Comuna Masloc (2004-),ADM2,DISTRICT
SM16617,25.2409,44.0356,Comuna Mavrodin (2003-),ADM2,DISTRICT
SM16618,26.2346,46.085,Comuna Mereni,ADM2,DISTRICT
SM16619,25.8374,46.0534,Comuna Micfalau (2004-),ADM2,DISTRICT
SM16620,27.7343,44.6622,Comuna Mihail Kogalniceanu (2004-),ADM2,DISTRICT
SM16621,26.162,47.9555,Comuna Mihaileni,ADM2,DISTRICT
SM16622,26.6733,44.9252,Comuna Mihailesti (2005-),ADM2,DISTRICT
SM16623,24.38,44.374,Comuna Milcov (2004-),ADM2,DISTRICT
SM16624,27.2593,45.6521,Comuna Milcovul (2004-),ADM2,DISTRICT
SM16625,26.0132,47.7836,Comuna Milisauti (2002-),ADM2,DISTRICT
SM16626,28.1865,44.3014,Comuna Mircea Voda,ADM2,DISTRICT
SM16627,26.8395,47.0549,Comuna Mircesti (2004-),ADM2,DISTRICT
SM16628,26.6465,47.1397,Comuna Miroslavesti (2005-),ADM2,DISTRICT
SM16629,24.2027,44.751,Comuna Mitrofani,ADM2,DISTRICT
SM16630,25.9527,45.8788,Comuna Moacsa (2004-),ADM2,DISTRICT
SM16631,26.5188,44.7143,Comuna Moldoveni,ADM2,DISTRICT
SM16632,26.4801,44.6178,Comuna Movilita (2004-),ADM2,DISTRICT
SM16633,25.195,46.2654,Comuna Mugeni (2004-),ADM2,DISTRICT
SM16634,27.4424,45.915,Comuna Munteni (2004-),ADM2,DISTRICT
SM16635,27.7632,46.6929,Comuna Muntenii de Sus,ADM2,DISTRICT
SM16636,23.3729,43.914,Comuna Negoiu (2004-),ADM2,DISTRICT
SM16637,22.7584,46.9522,Comuna Negreni,ADM2,DISTRICT
SM16638,26.3501,47.0478,Comuna Negresti,ADM2,DISTRICT
SM16639,24.0556,47.3105,Comuna Negrilesti (Bistriţa-Năsăud),ADM2,DISTRICT
SM16641,27.4802,45.9477,Comuna Negrilesti (Galaţi),ADM2,DISTRICT
SM16640,26.7064,45.937,Comuna Negrilesti (Vrancea),ADM2,DISTRICT
SM16642,25.1855,44.0386,Comuna Nenciulesti,ADM2,DISTRICT
SM16643,26.8884,46.4669,Comuna Nicolae Balcescu (2014-),ADM2,DISTRICT
SM16644,24.759,44.2701,Comuna Nicolae Titulescu (2004-),ADM2,DISTRICT
SM16645,27.2974,45.936,Comuna Nicoresti (2004-),ADM2,DISTRICT
SM16646,22.7078,47.2136,Comuna Nusfalau (2005-),ADM2,DISTRICT
SM16647,24.0872,44.4245,Comuna Oboga (2004-),ADM2,DISTRICT
SM16648,27.0897,45.501,Comuna Obrejita,ADM2,DISTRICT
SM16649,27.1322,46.669,Comuna Odobesti,ADM2,DISTRICT
SM16650,27.5705,44.6146,Comuna Ograda,ADM2,DISTRICT
SM16651,26.205,44.7926,Comuna Olari,ADM2,DISTRICT
SM16652,23.9818,47.8481,Comuna Oncesti,ADM2,DISTRICT
SM16653,24.6518,44.5794,Comuna Optasi-Magura (2004-),ADM2,DISTRICT
SM16654,23.3021,47.8347,Comuna Orasu Nou (2010-),ADM2,DISTRICT
SM16655,24.3678,43.7543,Comuna Orlea (2004-),ADM2,DISTRICT
SM16656,25.5568,46.0047,Comuna Ormenis (2005-),ADM2,DISTRICT
SM16657,24.295,44.238,Comuna Osica de Jos (2004-),ADM2,DISTRICT
SM16658,20.8565,45.5908,Comuna Otelec,ADM2,DISTRICT
SM16659,28.5321,44.2383,Comuna Ovidiu,ADM2,DISTRICT
SM16660,21.2177,45.6004,Comuna Padureni,ADM2,DISTRICT
SM16661,21.9775,47.1076,Comuna Paleu,ADM2,DISTRICT
SM16662,26.7477,45.7792,Comuna Paltin (2005-),ADM2,DISTRICT
SM16663,27.1688,46.9017,Comuna Pancesti,ADM2,DISTRICT
SM16664,28.3601,44.6005,Comuna Pantelimon,ADM2,DISTRICT
SM16665,24.2184,44.2968,Comuna Parscoveni (2004-),ADM2,DISTRICT
SM16666,21.1388,45.6289,Comuna Parta,ADM2,DISTRICT
SM16667,26.687,45.8871,Comuna Paulesti,ADM2,DISTRICT
SM16668,20.8752,46.0439,Comuna Periam (2007-),ADM2,DISTRICT
SM16669,27.6132,46.3041,Comuna Perieni (2004-),ADM2,DISTRICT
SM16670,24.4078,45.3793,Comuna Perisani (2002-),ADM2,DISTRICT
SM16671,25.4988,44.8019,Comuna Persinari,ADM2,DISTRICT
SM16672,20.8352,45.9959,Comuna Pesac,ADM2,DISTRICT
SM16673,24.0073,47.1922,Comuna Petru Rares (2005-),ADM2,DISTRICT
SM16674,25.2936,45.1003,Comuna Pietrari,ADM2,DISTRICT
SM16675,23.1636,43.8994,Comuna Piscu Vechi (2004-),ADM2,DISTRICT
SM16676,27.6864,44.6071,Comuna Platonesti,ADM2,DISTRICT
SM16677,23.5428,44.3538,Comuna Plesoiu,ADM2,DISTRICT
SM16678,24.6853,43.961,Comuna Plopii Slavitesti (2004-),ADM2,DISTRICT
SM16679,27.266,46.061,Comuna Ploscuteni,ADM2,DISTRICT
SM16680,27.5845,46.0523,Comuna Pochidia,ADM2,DISTRICT
SM16681,27.1197,46.8862,Comuna Poenari (2004-),ADM2,DISTRICT
SM16682,25.8937,47.6863,Comuna Poeni-Solca,ADM2,DISTRICT
SM16683,27.5267,46.1475,Comuna Pogonesti,ADM2,DISTRICT
SM16684,26.1742,46.0773,Comuna Poian (2005-),ADM2,DISTRICT
SM16685,27.2669,45.9955,Comuna Poiana,ADM2,DISTRICT
SM16686,24.741,47.3575,Comuna Poiana  Ilvei,ADM2,DISTRICT
SM16687,25.3093,45.6046,Comuna Poiana Marului (2016-),ADM2,DISTRICT
SM16688,24.1128,47.7031,Comuna Poienile Izei,ADM2,DISTRICT
SM16690,25.0911,44.4452,Comuna Popesti (2003-),ADM2,DISTRICT
SM16689,27.0656,45.5898,Comuna Popesti (Vrancea),ADM2,DISTRICT
SM16691,25.1201,46.2742,Comuna Porumbeni,ADM2,DISTRICT
SM16692,22.9724,47.9849,Comuna Porumbesti,ADM2,DISTRICT
SM16693,26.9763,46.6547,Comuna Prajesti,ADM2,DISTRICT
SM16694,23.5851,44.3464,Comuna Predesti (2004-),ADM2,DISTRICT
SM16695,26.4218,47.4548,Comuna Preutesti (2004-),ADM2,DISTRICT
SM16696,25.4132,44.3651,Comuna Purani,ADM2,DISTRICT
SM16697,27.6256,46.6253,Comuna Puscasi,ADM2,DISTRICT
SM16698,25.0367,44.4422,Comuna Raca,ADM2,DISTRICT
SM16699,26.905,47.0516,Comuna Rachiteni,ADM2,DISTRICT
SM16700,25.4346,44.8164,Comuna Raciu,ADM2,DISTRICT
SM16701,23.3325,47.8207,Comuna Racsa,ADM2,DISTRICT
SM16702,25.771,46.454,Comuna Racu,ADM2,DISTRICT
SM16703,27.7961,46.0831,Comuna Radesti,ADM2,DISTRICT
SM16704,23.5852,44.1769,Comuna Radovanu (2004-),ADM2,DISTRICT
SM16705,26.3399,44.7761,Comuna Radulesti (2004-),ADM2,DISTRICT
SM16706,27.3624,46.8006,Comuna Rafaila,ADM2,DISTRICT
SM16707,25.2562,44.5976,Comuna Rascaeti,ADM2,DISTRICT
SM16708,27.2872,45.6608,Comuna Rastoaca,ADM2,DISTRICT
SM16709,25.3371,45.1485,Comuna Rau Alb,ADM2,DISTRICT
SM16711,27.8465,45.7207,Comuna Rediu (2008-),ADM2,DISTRICT
SM16712,27.4875,47.2317,Comuna Rediu-Tatar (2004-),ADM2,DISTRICT
SM16713,21.3989,45.8104,Comuna Remetea Mare (2007-),ADM2,DISTRICT
SM16714,23.9187,44.0251,Comuna Rojiste,ADM2,DISTRICT
SM16715,27.4178,47.4426,Comuna Roscani,ADM2,DISTRICT
SM16716,21.9617,47.2558,Comuna Rosiori (Bihor),ADM2,DISTRICT
SM16717,26.535,44.6127,Comuna Rosiori (Ialomiţa),ADM2,DISTRICT
SM16718,24.2012,47.7379,Comuna Rozavlea (2006-),ADM2,DISTRICT
SM16719,26.7067,46.9745,Comuna Ruginoasa,ADM2,DISTRICT
SM16720,24.3253,47.3438,Comuna Runcu Salvei,ADM2,DISTRICT
SM16721,23.5781,47.278,Comuna Rus (2002-),ADM2,DISTRICT
SM16722,25.8882,44.5019,Comuna Sabareni,ADM2,DISTRICT
SM16723,23.5721,47.5704,Comuna Sacalaseni (2004-),ADM2,DISTRICT
SM16724,24.7384,43.8527,Comuna Saelele,ADM2,DISTRICT
SM16725,21.1693,45.6518,Comuna Sag (2004-),ADM2,DISTRICT
SM16726,27.0167,46.9681,Comuna Sagna (2004-),ADM2,DISTRICT
SM16727,28.0893,44.2832,Comuna Saligny,ADM2,DISTRICT
SM16728,23.3027,47.5193,Comuna Salsig (2004-),ADM2,DISTRICT
SM16729,24.3556,47.3059,Comuna Salva (2005-),ADM2,DISTRICT
SM16730,24.8107,45.727,Comuna Sambata de Sus,ADM2,DISTRICT
SM16731,25.8459,46.3058,Comuna Sancraieni (2004-),ADM2,DISTRICT
SM16732,20.878,45.9188,Comuna Sandra,ADM2,DISTRICT
SM16733,22.3311,47.6312,Comuna Sanislau (2004-),ADM2,DISTRICT
SM16734,25.9535,46.2637,Comuna Sanmartin (2004-),ADM2,DISTRICT
SM16735,21.7189,46.9641,Comuna Sannicolau-Roman,ADM2,DISTRICT
SM16736,20.8015,46.0799,Comuna Sanpetru Mare (2004-),ADM2,DISTRICT
SM16737,25.8105,46.2766,Comuna Santimbru,ADM2,DISTRICT
SM16738,26.8692,46.4958,Comuna Sarata,ADM2,DISTRICT
SM16740,26.9269,44.6342,Comuna Sarateni (Ialomiţa),ADM2,DISTRICT
SM16739,25.0133,46.5625,Comuna Sarateni (Mureş),ADM2,DISTRICT
SM16741,20.7408,46.0693,Comuna Saravale,ADM2,DISTRICT
SM16742,24.6959,44.5273,Comuna Sarbii-Magura,ADM2,DISTRICT
SM16743,25.3838,46.3408,Comuna Satu Mare,ADM2,DISTRICT
SM16744,27.6206,44.6087,Comuna Saveni (2005-),ADM2,DISTRICT
SM16745,27.4977,46.9395,Comuna Scheia (2004-),ADM2,DISTRICT
SM16746,27.0967,46.6533,Comuna Secuieni (2005-),ADM2,DISTRICT
SM16747,26.1468,47.8151,Comuna Serbauti,ADM2,DISTRICT
SM16748,25.7541,46.4354,Comuna Siculeni (2004-),ADM2,DISTRICT
SM16749,24.2239,47.7246,Comuna Sieu,ADM2,DISTRICT
SM16750,28.2097,44.4236,Comuna Silistea,ADM2,DISTRICT
SM16751,25.351,44.374,Comuna Silistea (2004-),ADM2,DISTRICT
SM16752,23.6155,47.2118,Comuna Simisna,ADM2,DISTRICT
SM16753,25.2218,45.6967,Comuna Sinca Noua,ADM2,DISTRICT
SM16754,26.4116,44.5626,Comuna Sintesti,ADM2,DISTRICT
SM16755,27.7714,45.5643,Comuna Slobozia Conachi (2005-),ADM2,DISTRICT
SM16756,24.6991,43.9158,Comuna Slobozia-Mandra (2004-),ADM2,DISTRICT
SM16757,24.2774,44.2771,Comuna Soparlita,ADM2,DISTRICT
SM16758,26.7393,45.7465,Comuna Spulber,ADM2,DISTRICT
SM16759,26.1742,45.3285,Comuna Starchiojd  (2003-),ADM2,DISTRICT
SM16760,26.8549,46.2064,Comuna Stefan Cel Mare (2005-),ADM2,DISTRICT
SM16761,24.4214,43.9678,Comuna Studina (2004-),ADM2,DISTRICT
SM16762,24.043,47.4431,Comuna Suciu de Sus (2003-),ADM2,DISTRICT
SM16763,25.2529,43.7395,Comuna Suhaia (2004-),ADM2,DISTRICT
SM16764,27.8268,45.7287,Comuna Suhurlui,ADM2,DISTRICT
SM16765,26.9351,47.6372,Comuna Sulita,ADM2,DISTRICT
SM16766,27.3842,45.6807,Comuna Suraia  (2004-),ADM2,DISTRICT
SM16767,24.2189,44.671,Comuna Sutesti (2004-),ADM2,DISTRICT
SM16768,24.2536,45.6675,Comuna Talmaciu (2004-),ADM2,DISTRICT
SM16769,23.7439,44.6826,Comuna Talpasu,ADM2,DISTRICT
SM16770,21.904,47.221,Comuna Tamaseu,ADM2,DISTRICT
SM16771,26.9955,46.4884,Comuna Tamasi (2005-),ADM2,DISTRICT
SM16772,27.0456,45.5179,Comuna Tamboesti (2004-),ADM2,DISTRICT
SM16773,27.8244,46.6872,Comuna Tanacu (2004-),ADM2,DISTRICT
SM16774,27.012,47.2079,Comuna Targu Frumos (2004-),ADM2,DISTRICT
SM16775,28.3882,44.4771,Comuna Targusor,ADM2,DISTRICT
SM16776,24.3932,45.4197,Comuna Titesti,ADM2,DISTRICT
SM16777,21.7032,47.0404,Comuna Toboliu,ADM2,DISTRICT
SM16779,26.8774,47.3214,"Comuna Todiresti (Iași, 2004-)",ADM2,DISTRICT
SM16778,27.3876,46.849,"Comuna Todiresti (Vaslui, 2004-)",ADM2,DISTRICT
SM16780,20.6619,45.9886,Comuna Tomnatic,ADM2,DISTRICT
SM16781,28.2656,44.3457,Comuna Tortoman,ADM2,DISTRICT
SM16782,27.3434,44.7635,Comuna Traian,ADM2,DISTRICT
SM16783,27.0353,46.6471,Comuna Traian (2005-),ADM2,DISTRICT
SM16784,23.1017,47.0943,Comuna Treznea,ADM2,DISTRICT
SM16785,27.5318,47.4499,Comuna Trifesti (2004-),ADM2,DISTRICT
SM16786,27.753,45.2254,Comuna Tudor Vladimirescu (2003-),ADM2,DISTRICT
SM16787,26.6045,45.9328,Comuna Tulnici (2003-),ADM2,DISTRICT
SM16788,27.5724,46.1122,Comuna Tutova (2004-),ADM2,DISTRICT
SM16789,28.6387,44.0016,Comuna Tuzla,ADM2,DISTRICT
SM16790,24.7191,43.8839,Comuna Uda-Clocociov,ADM2,DISTRICT
SM16791,20.9028,45.6509,Comuna Uivar (2008-),ADM2,DISTRICT
SM16792,26.641,45.2796,Comuna Unguriu,ADM2,DISTRICT
SM16793,27.0714,45.6018,Comuna Urechesti (2003-),ADM2,DISTRICT
SM16794,28.195,45.3246,Comuna Vacareni,ADM2,DISTRICT
SM16795,25.5199,44.8404,Comuna Vacaresti (2004-),ADM2,DISTRICT
SM16796,24.3807,43.865,Comuna Vadastra,ADM2,DISTRICT
SM16797,26.4002,47.386,Comuna Vadu Moldovei (2003-),ADM2,DISTRICT
SM16798,26.3887,45.0402,Comuna Vadu Sapat,ADM2,DISTRICT
SM16799,20.3987,46.0036,Comuna Valcani,ADM2,DISTRICT
SM16800,25.7848,45.9237,Comuna Valea Crisului (2004-),ADM2,DISTRICT
SM16801,27.4997,47.1792,Comuna Valea Lupului,ADM2,DISTRICT
SM16802,26.0144,45.7639,Comuna Valea Mare (1999-),ADM2,DISTRICT
SM16803,26.0154,47.4807,Comuna Valea Moldovei (2003-),ADM2,DISTRICT
SM16804,28.488,45.1115,Comuna Valea Teilor,ADM2,DISTRICT
SM16806,27.7593,46.7309,Comuna Valeni (2004-),ADM2,DISTRICT
SM16805,26.7153,47.0296,Comuna Valeni (Neamt),ADM2,DISTRICT
SM16807,26.4215,44.2914,Comuna Vasilati (2005-),ADM2,DISTRICT
SM16808,25.7886,43.7822,Comuna Vedea (2003-),ADM2,DISTRICT
SM16809,27.8607,46.3799,Comuna Viisoara (2004-),ADM2,DISTRICT
SM16810,25.3522,44.6019,Comuna Visina (2005-),ADM2,DISTRICT
SM16811,24.4182,43.8743,Comuna Visina Noua (2004-),ADM2,DISTRICT
SM16812,24.7524,45.772,Comuna Vistea de Jos (2004-),ADM2,DISTRICT
SM16813,24.8406,45.8182,Comuna Voila (2003-),ADM2,DISTRICT
SM16814,25.7574,47.8837,Comuna Voitinel,ADM2,DISTRICT
SM16815,25.8956,47.8073,Comuna Volovat (2004-),ADM2,DISTRICT
SM16816,22.8493,44.192,Comuna Vrata,ADM2,DISTRICT
SM16817,25.4167,45.6496,Comuna Vulcan (2004-),ADM2,DISTRICT
SM16818,25.3989,45.0217,Comuna Vulcana-Pandele,ADM2,DISTRICT
SM16819,25.0737,45.06,Comuna Vulturesti,ADM2,DISTRICT
SM16820,28.2729,44.6481,Comuna Vulturu,ADM2,DISTRICT
SM16821,21.2087,46.1308,Comuna Zadareni,ADM2,DISTRICT
SM16822,23.8432,44.7707,Comuna Zatreni (2004-),ADM2,DISTRICT
SM1004,-1.6667,42.75,Comunidad Foral de Navarra,ADM1,DISTRICT
SM1005,-0.75,39.5,Comunidad Valenciana,ADM1,DISTRICT
SM1003,-3.6906,40.4253,Comunidad de Madrid,ADM1,DISTRICT
SM262176,1.8833,6.4,Comè,ADM2,DISTRICT
SM229060,100.904,20.6442,Con,LANGUAGE,LANGUAGE
SM304332,104.8035,19.039,Con Cuông,ADM2,DISTRICT
SM274978,-13.597,9.603,Conakry,ADM1:ADM2,DISTRICT
SM1006,-13.597,9.607,Conakry,ADM1,DISTRICT
SM11739,-39.2409,-12.855,Conceicao Do Almeida,ADM2,DISTRICT
SM265260,-41.8534,-22.1261,Conceicao Macabu,ADM2,DISTRICT
SM264398,-38.5138,-7.4992,Conceição,ADM2,DISTRICT
SM263325,-46.2263,-21.1012,Conceição da Aparecida,ADM2,DISTRICT
SM267975,-39.7362,-18.5854,Conceição da Barra,ADM2,DISTRICT
SM267366,-38.9947,-12.5069,Conceição da Feira,ADM2,DISTRICT
SM457956,-48.3051,-19.9563,Conceição das Alagoas,ADM2,DISTRICT
SM314412,-45.4269,-22.1387,Conceição das Pedras,ADM2:ADM3,DISTRICT
SM263328,-41.7002,-19.9178,Conceição de Ipanema,ADM2,DISTRICT
SM264155,-49.6035,-8.1543,Conceição do Araguaia,ADM2,DISTRICT
SM265205,-41.5754,-7.987,Conceição do Canindé,ADM2,DISTRICT
SM267977,-41.2662,-20.3623,Conceição do Castelo,ADM2,DISTRICT
SM267369,-39.1911,-11.6321,Conceição do Coité,ADM2,DISTRICT
SM267370,-38.7574,-12.3323,Conceição do Jacuípe,ADM2,DISTRICT
SM262980,-44.7967,-3.7359,Conceição do Lago-Açu,ADM2,DISTRICT
SM263329,-43.5023,-18.9001,Conceição do Mato Dentro,ADM2,DISTRICT
SM263330,-44.8702,-19.7855,Conceição do Para,ADM2,DISTRICT
SM263331,-45.0798,-21.9013,Conceição do Rio Verde,ADM2,DISTRICT
SM267224,-47.2705,-12.0988,Conceição do Tocantins,ADM2,DISTRICT
SM263332,-45.7798,-22.4504,Conceição dos Ouros,ADM2,DISTRICT
SM15031,34.933,-19.7163,Concelho da Beira,ADM2,DISTRICT
SM15032,32.8951,-18.9486,Concelho de Manica,ADM2,DISTRICT
SM15033,36.9154,-17.8607,Concelho de Quelimane,ADM2,DISTRICT
SM15034,33.4563,-19.127,Concelho do Chimoio,ADM2,DISTRICT
SM15035,36.3227,-18.3949,Concelho do Chinde  (2013-),ADM2,DISTRICT
SM15036,40.6063,-12.3399,Concelho do Ibo,ADM2,DISTRICT
SM15326,-57.3678,-22.8793,Concepcion (2011-),ADM2,DISTRICT
SM14260,-97.4286,17.9607,Concepcion Buenavista,ADM2,DISTRICT
SM14261,-96.8447,17.8539,Concepcion Papalo,ADM2,DISTRICT
SM286746,-75,-11.75,Concepción,ADM2,DISTRICT
SM275668,-88.3194,14.0473,Concepción,ADM2,DISTRICT
SM275871,-88.8792,14.8808,Concepción,ADM2,DISTRICT
SM275734,-89.1937,14.5238,Concepción,ADM2,DISTRICT
SM275323,-91.1347,14.7823,Concepción,ADM2,DISTRICT
SM270454,-72.6344,6.7702,Concepción,ADM2,DISTRICT
SM270201,-75.2195,6.39,Concepción,ADM2,DISTRICT
SM269108,-72.8967,-36.9577,Concepción,ADM2,DISTRICT
SM260910,-55.4167,-27.8333,Concepción,ADM2,DISTRICT
SM261167,-58,-28.3333,Concepción,ADM2,DISTRICT
SM1007,-57.1667,-22.8333,Concepción,ADM1,DISTRICT
SM295591,-88.3488,13.2864,Concepción Batres,ADM2,DISTRICT
SM275231,-91.6185,14.8462,Concepción Chiquirichapa,ADM2,DISTRICT
SM275409,-89.4491,14.4907,Concepción Las Minas,ADM2,DISTRICT
SM295637,-88.9497,14.1137,Concepción Quezaltepeque,ADM2,DISTRICT
SM275303,-91.8674,15.288,Concepción Tutuapa,ADM2,DISTRICT
SM295504,-89.856,13.8563,Concepción de Ataco,ADM2,DISTRICT
SM281786,-103.2202,19.9906,Concepción de Buenos Aires,ADM2,DISTRICT
SM275822,-86.9624,13.2236,Concepción de Maria,ADM2,DISTRICT
SM295711,-87.7405,13.7893,Concepción de Oriente,ADM2,DISTRICT
SM271786,-70.5,19.25,Concepción de la Vega,ADM2,DISTRICT
SM275789,-88.1373,15.2023,Concepción del Norte,ADM2,DISTRICT
SM283092,-101.2932,24.5287,Concepción del Oro,ADM2,DISTRICT
SM275790,-88.1532,14.8126,Concepción del Sur,ADM2,DISTRICT
SM292618,26.5794,48.1468,Concesti,ADM2,DISTRICT
SM295712,-87.8786,13.2715,Conchagua,ADM2,DISTRICT
SM266474,-47.1362,-22.3461,Conchal,ADM2,DISTRICT
SM266475,-48.0726,-22.9592,Conchas,ADM2,DISTRICT
SM302836,-99.864,31.3265,Concho,ADM2,DISTRICT
SM252943,-106.45,30.04,Concho,ETHNICITY,ETHNICITY
SM252944,-77.82,-8.14,Conchuco,ETHNICITY,ETHNICITY
SM301114,-91.6401,31.4458,Concordia,ADM2,DISTRICT
SM282546,-106.0016,23.3626,Concordia,ADM2,DISTRICT
SM275758,-86.7222,14.6467,Concordia,ADM2,DISTRICT
SM10395,-74.7786,10.2328,Concordia,ADM2,DISTRICT
SM270202,-75.9018,6.045,Concordia,ADM2,DISTRICT
SM266398,-52.0011,-27.2294,Concórdia,ADM2,DISTRICT
SM264157,-47.9632,-1.8615,Concórdia do Pará,ADM2,DISTRICT
SM260443,14.4537,-11.1791,Conda,ADM2,DISTRICT
SM264973,-35.0889,-7.5902,Condado,ADM2,DISTRICT
SM264400,-37.6186,-6.8524,Condado,ADM2,DISTRICT
SM229065,152.1513,-28.2896,Condamine-Upper Clarence Bandjalang,LANGUAGE,LANGUAGE
SM264401,-34.8431,-7.2864,Conde,ADM2,DISTRICT
SM267371,-37.6482,-11.8033,Conde,ADM2,DISTRICT
SM285529,-86.3531,13.3413,Condega,ADM2,DISTRICT
SM289481,-8.4963,40.1064,Condeixa-a-Nova,ADM2,DISTRICT
SM286868,-72.8333,-15.5833,Condesuyos,ADM2,DISTRICT
SM267372,-42.0069,-14.9328,Condeúba,ADM2,DISTRICT
SM265613,-53.4926,-28.1926,Condor,ADM2,DISTRICT
SM286724,-78.0384,-4.1652,Condorcanqui,ADM2,DISTRICT
SM10396,-76.4516,5.106,Condoto (2000-),ADM2,DISTRICT
SM300255,-86.9937,31.4293,Conecuh,ADM2,DISTRICT
SM303321,-106.1916,37.2007,Conejos,ADM2,DISTRICT
SM260938,-64.25,-40.25,Conesa,ADM2,DISTRICT
SM281518,-104.734,25.0312,Coneto de Comonfort,ADM2,DISTRICT
SM270455,-73.2099,6.3488,Confines,ADM2,DISTRICT
SM263334,-43.9813,-19.6569,Confins,ADM2,DISTRICT
SM260927,-68.8333,-38.8333,Confluencia,ADM2,DISTRICT
SM263152,-51.7365,-10.3096,Confresa,ADM2,DISTRICT
SM264402,-36.6349,-7.7958,Congo,ADM2,DISTRICT
SM229066,27.4196,-7.2692,Congo Swahili,LANGUAGE,LANGUAGE
SM252945,28.66,0.58,Congo-nandi drc,ETHNICITY,ETHNICITY
SM263337,-46.0399,-22.1354,Congonhal,ADM2,DISTRICT
SM263339,-43.8591,-20.5316,Congonhas,ADM2,DISTRICT
SM263338,-43.6962,-18.886,Congonhas do Norte,ADM2,DISTRICT
SM264826,-50.4986,-23.6294,Congonhinhas,ADM2,DISTRICT
SM275193,-90.0166,14.0122,Conguaco,ADM2,DISTRICT
SM260860,-64.5,-36,Conhelo,ADM2,DISTRICT
SM282953,-89.5168,21.0814,Conkal,ADM2,DISTRICT
SM4174,-8.6333,53.87,Connaught,ADM1,DISTRICT
SM1008,-72.6665,41.667,Connecticut,ADM1,DISTRICT
SM290492,21.8805,46.104,Conop,ADM2,DISTRICT
SM252947,-77.31,38.01,Conoy,ETHNICITY,ETHNICITY
SM263340,-47.6335,-19.8684,Conquista,ADM2,DISTRICT
SM9909,-59.2674,-14.6151,Conquista D'Oeste,ADM2,DISTRICT
SM270301,-77.4632,1.2084,Consacá,ADM2,DISTRICT
SM370470,6.3383,49.7731,Consdorf,ADM1:ADM2:ADM3,DISTRICT
SM15629,-66.8131,18.0557,Consejo Barrio (Guayanilla),ADM2,DISTRICT
SM15628,-66.6663,18.1774,Consejo Barrio (Utuado),ADM2,DISTRICT
SM263341,-43.8055,-20.6514,Conselheiro Lafaiete,ADM2,DISTRICT
SM264827,-50.1163,-23.5877,Conselheiro Mayrinck,ADM2,DISTRICT
SM263342,-41.458,-19.1741,Conselheiro Pena,ADM2,DISTRICT
SM271031,-83.4199,22.441,Consolación del Sur,ADM2,DISTRICT
SM263343,-45.9041,-22.54,Consolação,ADM2,DISTRICT
SM323870,-110.7775,52.0113,Consort,ADM2:ADM3,DISTRICT
SM14262,-97.9955,17.0597,Constancia del Rosario,ADM2,DISTRICT
SM12250,-76.7947,18.0503,Constant Spring,ADM2,DISTRICT
SM12251,-76.7996,18.0294,Constant Spring Gardens,ADM2,DISTRICT
SM290155,28.6186,44.2141,Constanta,ADM2,DISTRICT
SM9910,-53.0022,-27.7063,Constantina (1996-),ADM2,DISTRICT
SM1010,6.6667,36.3333,Constantine,ADM1,DISTRICT
SM457457,6.75,36.3333,Constantine District,ADM2,DISTRICT
SM271787,-70.7,18.8667,Constanza,ADM2,DISTRICT
SM1009,28.3333,44.25,Constanța,ADM1,DISTRICT
SM261030,-60.9167,-33.5,Constitución,ADM2,DISTRICT
SM12252,-76.7052,18.0149,Constitution Hill,ADM2,DISTRICT
SM289337,-8.3037,39.4572,Constância,ADM2,DISTRICT
SM271848,-69.2571,18.5845,Consuelo,ADM2,DISTRICT
SM270302,-77.5333,0.9307,Contadero,ADM2,DISTRICT
SM263344,-44.0759,-19.8872,Contagem,ADM2,DISTRICT
SM264828,-49.508,-25.7141,Contenda,ADM2,DISTRICT
SM267373,-41.05,-13.796,Contendas do Sincorá,ADM2,DISTRICT
SM12253,-77.4512,18.0744,Content (Manchester),ADM2,DISTRICT
SM18539,-77.9992,18.1829,Content (Westmoreland),ADM2,DISTRICT
SM12254,-76.6907,18.0408,Content Gap,ADM2,DISTRICT
SM12255,-77.0754,18.4098,Content Garden,ADM2,DISTRICT
SM282018,-100.2124,19.9549,Contepec,ADM2,DISTRICT
SM370521,6.2267,49.5819,Contern,ADM1:ADM2:ADM3,DISTRICT
SM291876,25.4953,43.7918,Contesti,ADM2,DISTRICT
SM290252,25.6733,44.6788,Contesti,ADM2,DISTRICT
SM268989,7.2786,46.2049,Conthey,ADM2,DISTRICT
SM239459,16.4225,39.2288,Continental Southern Italian,LANGUAGE,LANGUAGE
SM282723,-98.1284,19.3189,Contla de Juan Cuamatzi,ADM2,DISTRICT
SM15630,-66.2586,18.3812,Contorno Barrio,ADM2,DISTRICT
SM303218,-121.9512,37.9234,Contra Costa,ADM2,DISTRICT
SM286849,-80.75,-4,Contralmirante Villar,ADM2,DISTRICT
SM271046,-76.2388,20.2916,Contramaestre,ADM2,DISTRICT
SM270456,-73.484,6.2932,Contratación,ADM2,DISTRICT
SM275078,-14.641,12.555,Contuboel,ADM2,DISTRICT
SM286890,-78.9167,-7.3333,Contumazá,ADM2,DISTRICT
SM270400,-73.2285,8.8224,Convención,ADM2,DISTRICT
SM303313,-105.5071,42.9723,Converse,ADM2,DISTRICT
SM302358,-92.7013,35.2622,Conway,ADM2,DISTRICT
SM17298,-60.9906,14.0139,Conway/Waterfront,ADM2,DISTRICT
SM274765,-3.75,53.0833,Conwy,ADM1:ADM2,DISTRICT
SM261688,134.7519,-29.0114,Coober Pedy,ADM2,DISTRICT
SM301345,-90.5411,47.8168,Cook,ADM2,DISTRICT
SM300599,-87.6462,41.8954,Cook,ADM2,DISTRICT
SM300470,-83.4305,31.154,Cook,ADM2,DISTRICT
SM261621,143.5316,-13.6815,Cook,ADM2,DISTRICT
SM252949,-159.77,-21.26,Cook Islands,ETHNICITY,ETHNICITY
SM252949,-159.77,-21.26,Cook Islands,ETHNICITY,ETHNICITY
SM252949,-157.91,-21.93,Cook Islands,ETHNICITY,ETHNICITY
SM252949,-157.91,-21.93,Cook Islands,ETHNICITY,ETHNICITY
SM302837,-97.2126,33.6392,Cooke,ADM2,DISTRICT
SM261523,147.1371,-34.6005,Coolamon,ADM2,DISTRICT
SM261357,121.2046,-31.0144,Coolgardie,ADM2,DISTRICT
SM17299,-61.0144,13.9568,Coolie Town (Castries),ADM2,DISTRICT
SM17300,-60.9138,13.8543,Coolie Town (Micoud),ADM2,DISTRICT
SM17301,-60.9668,13.7691,Coolie Town (Vieux-Fort),ADM2,DISTRICT
SM261604,131.0843,-13.0432,Coomalie,ADM2,DISTRICT
SM459228,146.8179,-19.2651,Coonambella,ETHNICITY,ETHNICITY
SM261525,148.2905,-30.8671,Coonamble,ADM2,DISTRICT
SM301542,-92.8101,38.8435,Cooper,ADM2,DISTRICT
SM12256,-76.8485,18.0224,Cooreville Gardens,ADM2,DISTRICT
SM261358,115.6561,-29.9787,Coorow,ADM2,DISTRICT
SM302281,-124.0933,43.185,Coos,ADM2,DISTRICT
SM301807,-71.3054,44.6896,Coos,ADM2,DISTRICT
SM252950,-123.94,43.88,Coos,ETHNICITY,ETHNICITY
SM252950,-123.94,43.88,Coos,ETHNICITY,ETHNICITY
SM252950,-123.94,43.88,Coos,ETHNICITY,ETHNICITY
SM252950,-123.94,43.88,Coos,ETHNICITY,ETHNICITY
SM252950,-124,43,Coos,ETHNICITY,ETHNICITY
SM252950,-124,43,Coos,ETHNICITY,ETHNICITY
SM252950,-124,43,Coos,ETHNICITY,ETHNICITY
SM252950,-124,43,Coos,ETHNICITY,ETHNICITY
SM252950,-124.11,43.43,Coos,ETHNICITY,ETHNICITY
SM252950,-124.11,43.43,Coos,ETHNICITY,ETHNICITY
SM252950,-124.11,43.43,Coos,ETHNICITY,ETHNICITY
SM252950,-124.11,43.43,Coos,ETHNICITY,ETHNICITY
SM252950,-124.2,43.3,Coos,ETHNICITY,ETHNICITY
SM252950,-124.2,43.3,Coos,ETHNICITY,ETHNICITY
SM252950,-124.2,43.3,Coos,ETHNICITY,ETHNICITY
SM252950,-124.2,43.3,Coos,ETHNICITY,ETHNICITY
SM300256,-86.2477,32.9362,Coosa,ADM2,DISTRICT
SM270203,-75.4992,6.3623,Copacabana,ADM2,DISTRICT
SM292420,22.1673,46.9723,Copacel,ADM2,DISTRICT
SM290005,22.0872,45.4804,Copacele,ADM2,DISTRICT
SM283133,-93.2,17.0833,Copainalá,ADM2,DISTRICT
SM229077,-93.3183,17.2157,Copainalá Zoque,LANGUAGE,LANGUAGE
SM281590,-98.9409,16.6067,Copala,ADM2,DISTRICT
SM229078,-97.9826,17.1541,Copala Triqui,LANGUAGE,LANGUAGE
SM281592,-99.0544,17.97,Copalillo,ADM2,DISTRICT
SM252951,-123.93,47.01,Copalis,ETHNICITY,ETHNICITY
SM291000,23.6878,47.5225,Copalnic-manastur,ADM2,DISTRICT
SM281593,-98.7001,17.4291,Copanatoyac,ADM2,DISTRICT
SM262164,1.5481,9.8375,Copargo,ADM2,DISTRICT
SM270758,-74.0321,5.4362,Coper,ADM2,DISTRICT
SM301432,-90.4488,31.8692,Copiah,ADM2,DISTRICT
SM269104,-70.0006,-27.5635,Copiapó,ADM2,DISTRICT
SM261062,-62.75,-26,Copo,ADM2,DISTRICT
SM296110,-56.5232,4.4148,Coppename,ADM2,DISTRICT
SM261689,137.7133,-33.9429,Copper Coast,ADM2,DISTRICT
SM252952,-107.64,69.41,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-107.64,69.41,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-107.64,69.41,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-107.64,69.41,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-107.64,69.41,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-117.73,70.65,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-117.73,70.65,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-117.73,70.65,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-117.73,70.65,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-117.73,70.65,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-110,69,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-110,69,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-110,69,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-110,69,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-110,69,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-106.61,68.58,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-106.61,68.58,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-106.61,68.58,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-106.61,68.58,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-106.61,68.58,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-112.53,68.72,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-112.53,68.72,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-112.53,68.72,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-112.53,68.72,Copper Inuit,ETHNICITY,ETHNICITY
SM252952,-112.53,68.72,Copper Inuit,ETHNICITY,ETHNICITY
SM1012,28,-13,Copperbelt,ADM1,DISTRICT
SM291717,24.2552,46.1165,Copsa Mica,ADM2,DISTRICT
SM12257,-77.9768,18.3947,Copse,ADM2,DISTRICT
SM229082,31.2053,29.472,Coptic,LANGUAGE,LANGUAGE
SM1011,-88.9167,14.9167,Copán,ADM1,DISTRICT
SM275876,-89.15,14.92,Copán Ruinas,ADM2,DISTRICT
SM282019,-101.2389,19.8894,Copándaro,ADM2,DISTRICT
SM263345,-45.4362,-21.1795,Coqueiral,ADM2,DISTRICT
SM9911,-52.1021,-29.1748,Coqueiro Baixo,ADM2:ADM3,DISTRICT
SM265354,-35.8094,-9.6466,Coqueiro Seco,ADM2,DISTRICT
SM265615,-52.7614,-28.1368,Coqueiros do Sul,ADM2,DISTRICT
SM229083,-119.605,44.5177,Coquille,LANGUAGE,LANGUAGE
SM252953,-124.02,43.34,Coquille,ETHNICITY,ETHNICITY
SM283319,-103.8486,19.199,Coquimatlán,ADM2,DISTRICT
SM1013,-71,-30.75,Coquimbo,ADM1,DISTRICT
SM252954,-105.03,22.33,Cora,ETHNICITY,ETHNICITY
SM291398,24.4703,43.8025,Corabia,ADM2,DISTRICT
SM276081,-73.928,18.5183,Corail,ADM2,DISTRICT
SM303902,-64.7127,18.3369,Coral Bay,ADM2,DISTRICT
SM12258,-77.8803,18.5079,Coral Gardens,ADM2,DISTRICT
SM1014,151,-17.5,Coral Sea Islands Territory,ADM1,DISTRICT
SM261224,143.2354,-38.216,Corangamite,ADM2,DISTRICT
SM263350,-44.6059,-18.3525,Coração de Jesus Part,ADM2,DISTRICT
SM263351,-42.2584,-18.6119,Coração de Jesus Part,ADM2,DISTRICT
SM267374,-38.7824,-12.2427,Coração de Maria,ADM2,DISTRICT
SM292097,27.1368,46.2973,Corbasca,ADM2,DISTRICT
SM290951,26.0324,44.5967,Corbeanca,ADM2,DISTRICT
SM291233,24.6621,45.296,Corbeni,ADM2,DISTRICT
SM291234,24.8161,45.2557,Corbi,ADM2,DISTRICT
SM290253,25.4806,44.5567,Corbii Mari,ADM2,DISTRICT
SM291399,24.7164,44.4551,Corbu,ADM2,DISTRICT
SM290681,25.657,46.9956,Corbu,ADM2,DISTRICT
SM290156,28.678,44.4032,Corbu,ADM2,DISTRICT
SM264829,-53.2333,-24.7268,Corbélia,ADM2,DISTRICT
SM291059,23.0784,44.6779,Corcova,ADM2,DISTRICT
SM15631,-67.0567,18.2941,Corcovada Barrio,ADM2,DISTRICT
SM15632,-66.7794,18.4506,Corcovado Barrio,ADM2,DISTRICT
SM292549,26.5911,48.0042,Cordareni,ADM2,DISTRICT
SM265261,-42.3175,-22.081,Cordeiro,ADM2,DISTRICT
SM267375,-41.8975,-14.9985,Cordeiros,ADM2,DISTRICT
SM266476,-47.4071,-22.483,Cordeirópolis,ADM2,DISTRICT
SM266400,-52.6331,-26.9755,Cordilheira Alta,ADM2,DISTRICT
SM269083,-70.5467,-33.6792,Cordillera,ADM2,DISTRICT
SM262768,-61.9348,-19.015,Cordillera,ADM2,DISTRICT
SM1015,-57,-25.25,Cordillera,ADM1,DISTRICT
SM4193,121.0332,17.0852,Cordillera Administrative Region,ADM1,DISTRICT
SM15633,-66.5008,18.3224,Cordillera Barrio,ADM2,DISTRICT
SM314455,-45.6716,-21.7875,Cordislândia,ADM2:ADM3,DISTRICT
SM291322,26.8627,46.9729,Cordun,ADM2,DISTRICT
SM267908,-40.7273,-3.6883,Coreaú,ADM2,DISTRICT
SM264403,-37.9826,-7.0106,Coremas,ADM2,DISTRICT
SM263037,-55.002,-19.8186,Corguinho,ADM2,DISTRICT
SM229101,8.06,9.59,Cori,LANGUAGE,LANGUAGE
SM267377,-44.4354,-13.7234,Coribe,ADM2,DISTRICT
SM17302,-60.9605,14.0471,Corinth,ADM2,DISTRICT
SM17303,-60.9539,14.0434,Corinth Estate,ADM2,DISTRICT
SM17304,-60.9448,14.0447,Corinth/La Bel Lair,ADM2,DISTRICT
SM285515,-87.2249,12.5319,Corinto,ADM2,DISTRICT
SM295738,-87.9714,13.8108,Corinto,ADM2,DISTRICT
SM269887,-76.2065,3.136,Corinto,ADM2,DISTRICT
SM263346,-44.3628,-16.6862,Corinto + Cordislândia + Cordisburgo + Coroaci Part,ADM2,DISTRICT
SM275134,9.3219,0.9172,Corisco,ADM2,DISTRICT
SM12055,-8.4677,51.8976,Cork City,ADM2,DISTRICT
SM291060,22.9285,44.381,Corlatel,ADM2,DISTRICT
SM18034,27.7911,41.197,Corlu (2014-),ADM2,DISTRICT
SM13475,27.4287,56.3961,Cornajas pagasts,ADM2,DISTRICT
SM290254,25.6864,44.7308,Cornatelu,ADM2,DISTRICT
SM290006,22.319,45.0195,Cornea,ADM2,DISTRICT
SM262759,-65.3669,-19.4505,Cornelio Saavedra,ADM2,DISTRICT
SM290007,22.4467,45.0697,Cornereva,ADM2,DISTRICT
SM290256,25.8872,44.7712,Cornesti,ADM2,DISTRICT
SM290088,23.685,47.0281,Cornesti,ADM2,DISTRICT
SM290952,25.9249,44.3429,Cornetu,ADM2,DISTRICT
SM290429,27.7769,45.8692,Corni,ADM2,DISTRICT
SM292551,26.6087,47.6403,Corni,ADM2,DISTRICT
SM229103,-4.1862,50.5233,Cornish,LANGUAGE,LANGUAGE
SM291517,25.703,45.1634,Cornu De Jos,ADM2,DISTRICT
SM291781,26.1412,47.4574,Cornu Luncii,ADM2,DISTRICT
SM274615,-4.75,50.4167,Cornwall,ADM1:ADM2,DISTRICT
SM12259,-77.8102,18.4893,Cornwall,ADM2,DISTRICT
SM12260,-77.9867,18.2872,Cornwall Mountian,ADM2,DISTRICT
SM327811,-99.8481,49.7972,Cornwallis,ADM2:ADM3,DISTRICT
SM264830,-50.6189,-23.237,Cornélio Procópio,ADM2,DISTRICT
SM263348,-44.1646,-19.0993,Coroaci Part,ADM2,DISTRICT
SM252955,-41.26,-21.19,Coroado,ETHNICITY,ETHNICITY
SM229107,-43.612,-20.264,Coroado-Puri,LANGUAGE,LANGUAGE
SM266478,-50.3127,-21.3712,Coroados,ADM2,DISTRICT
SM262981,-44.2096,-4.0816,Coroatá,ADM2,DISTRICT
SM290430,27.6515,45.9369,Corod,ADM2,DISTRICT
SM291001,23.7776,47.3663,Coroieni,ADM2,DISTRICT
SM292264,27.4925,46.2889,Coroiesti,ADM2,DISTRICT
SM291131,24.5977,46.4038,Coroisanmartin,ADM2,DISTRICT
SM263352,-47.1516,-18.4046,Coromandel,ADM2,DISTRICT
SM270457,-72.9754,6.2332,Coromoro,ADM2,DISTRICT
SM283218,-105.1209,26.6624,Coronado,ADM2,DISTRICT
SM282390,-98.2913,19.1314,Coronango,ADM2,DISTRICT
SM323953,-111.4444,52.0926,Coronation,ADM2:ADM3,DISTRICT
SM265616,-54.0637,-28.3945,Coronel Barros,ADM2,DISTRICT
SM265617,-53.6531,-27.7995,Coronel Bicaco,ADM2,DISTRICT
SM289590,-56.2795,-27.1863,Coronel Bogado,ADM2,DISTRICT
SM260749,-61.1667,-38.6667,Coronel Dorrego,ADM2,DISTRICT
SM265531,-36.23,-6.3199,Coronel Ezequiel,ADM2,DISTRICT
SM263353,-42.6853,-19.4566,Coronel Fabriciano,ADM2,DISTRICT
SM260876,-68.3333,-29.5,Coronel Felipe Varela,ADM2,DISTRICT
SM266401,-52.7633,-26.8943,Coronel Freitas,ADM2,DISTRICT
SM265206,-42.2724,-9.0722,Coronel José Dias,ADM2,DISTRICT
SM265532,-38.4173,-6.2622,Coronel João Pessoa,ADM2,DISTRICT
SM267378,-37.9428,-10.3888,Coronel João Sá,ADM2,DISTRICT
SM266479,-49.3031,-23.6209,Coronel Macedo,ADM2,DISTRICT
SM273451,-79.3814,-2.2359,Coronel Marcelino Maridueña,ADM2,DISTRICT
SM266402,-52.6739,-26.5477,Coronel Martins,ADM2,DISTRICT
SM289556,-56.602,-25.7422,Coronel Martínez (pre-2008),ADM2,DISTRICT
SM263354,-42.1974,-16.5893,Coronel Murta,ADM2,DISTRICT
SM289692,-56.4211,-25.4866,Coronel Oviedo,ADM2,DISTRICT
SM263355,-43.2901,-21.6087,Coronel Pacheco,ADM2,DISTRICT
SM9912,-51.7182,-29.2598,Coronel Pilar,ADM2:ADM3,DISTRICT
SM286853,-73.3333,-9.5,Coronel Portillo,ADM2,DISTRICT
SM260988,-65.8333,-33.0833,Coronel Pringles,ADM2,DISTRICT
SM260750,-61.3333,-38.25,Coronel Pringles,ADM2,DISTRICT
SM263038,-55.389,-23.3376,Coronel Sapucaia,ADM2,DISTRICT
SM260751,-61.8333,-37.5,Coronel Suárez,ADM2,DISTRICT
SM264832,-52.596,-26.0011,Coronel Vivida,ADM2,DISTRICT
SM263356,-44.2022,-21.0287,Coronel Xavier Chaves,ADM2,DISTRICT
SM260748,-61.8333,-38.8333,Coronel de Marina Leonardo Rosales,ADM2,DISTRICT
SM264831,-51.9778,-26.1829,Coronel domingos Soares,ADM2,DISTRICT
SM281579,-100.3763,20.2099,Coroneo,ADM2,DISTRICT
SM286808,-77.9167,-8.5833,Corongo,ADM2,DISTRICT
SM229105,-77.9864,-8.5765,Corongo Ancash Quechua,LANGUAGE,LANGUAGE
SM1019,-56.25,5.5833,Coronie,ADM1,DISTRICT
SM290008,21.7009,44.6729,Coronini,ADM2,DISTRICT
SM1020,-88.3167,18.2167,Corozal,ADM1,DISTRICT
SM1021,-66.326,18.3091,Corozal,ADM1,DISTRICT
SM10397,-75.2792,9.2,Corozal (1998-),ADM2,DISTRICT
SM15634,-66.3168,18.3397,Corozal Barrio-Pueblo,ADM2,DISTRICT
SM261010,-69.25,-50,Corpen Aike,ADM2,DISTRICT
SM15328,-55.0099,-24.1105,Corpus Christi (2011-),ADM2,DISTRICT
SM275877,-88.84,14.55,Corquín,ADM2,DISTRICT
SM270759,-72.8454,5.825,Corrales,ADM2,DISTRICT
SM15635,-67.1258,18.4513,Corrales Barrio,ADM2,DISTRICT
SM271062,-80.5119,22.8786,Corralillo,ADM2,DISTRICT
SM270952,-82.9188,8.5324,Corredores,ADM2,DISTRICT
SM282460,-100.4361,20.5084,Corregidora,ADM2,DISTRICT
SM266403,-50.3786,-27.5972,Correia Pinto,ADM2,DISTRICT
SM265207,-45.0469,-10.4722,Corrente,ADM2,DISTRICT
SM264974,-36.3203,-9.1338,Correntes,ADM2,DISTRICT
SM267379,-45.1319,-13.2899,Correntina,ADM2,DISTRICT
SM1022,-58,-29,Corrientes,ADM1,DISTRICT
SM261360,117.8102,-32.376,Corrigin,ADM2,DISTRICT
SM274438,1.8732,45.3475,Corrèze,ADM2,DISTRICT
SM1023,8.9542,42.0615,Corse,ADM1,DISTRICT
SM274487,9.1667,41.9167,Corse-du-Sud,ADM2,DISTRICT
SM229110,9.2554,41.7,Corsican,LANGUAGE,LANGUAGE
SM252956,9.02,42.37,Corsican,ETHNICITY,ETHNICITY
SM302484,-101.1969,45.7086,Corson,ADM2,DISTRICT
SM281535,-100.9641,20.4689,Cortazar,ADM2,DISTRICT
SM264975,-35.5338,-8.4425,Cortes,ADM2,DISTRICT
SM301870,-76.0703,42.595,Cortland,ADM2,DISTRICT
SM1024,-88,15.4167,Cortés,ADM1,DISTRICT
SM289338,-8.5275,38.9595,Coruche,ADM2,DISTRICT
SM266480,-47.5996,-22.217,Corumbataí,ADM2,DISTRICT
SM264834,-52.1462,-24.1201,Corumbataí do Sul,ADM2,DISTRICT
SM268253,-48.5321,-18.1666,Corumbaíba,ADM2,DISTRICT
SM266079,-61.1199,-12.8759,Corumbiara,ADM2,DISTRICT
SM263039,-56.821,-18.6476,Corumbá,ADM2,DISTRICT
SM268251,-48.6298,-15.9219,Corumbá de Goiás,ADM2,DISTRICT
SM290682,25.2007,46.4806,Corund,ADM2,DISTRICT
SM266404,-49.3179,-26.4237,Corupá,ADM2,DISTRICT
SM265355,-36.1996,-10.0745,Coruripe,ADM2,DISTRICT
SM289388,-31.1055,39.6977,Corvo,ADM2,DISTRICT
SM302839,-97.7992,31.3909,Coryell,ADM2,DISTRICT
SM282547,-106.7804,24.4755,Cosalá,ADM2,DISTRICT
SM14264,-95.9597,18.3016,Cosamaloapan de Carpio (1996-),ADM2,DISTRICT
SM229112,101.5667,21.6167,Cosao,LANGUAGE,LANGUAGE
SM282882,-96.9772,19.3288,Cosautlán de Carvajal,ADM2,DISTRICT
SM292492,24.3922,47.3654,Cosbuc,ADM2,DISTRICT
SM282883,-97.0736,19.0634,Coscomatepec,ADM2,DISTRICT
SM291595,23.0128,47.3319,Coseiu,ADM2,DISTRICT
SM277938,16.3549,39.557,Cosenza,ADM2,DISTRICT
SM291235,24.872,45.0643,Cosesti,ADM2,DISTRICT
SM302090,-81.92,40.3016,Coshocton,ADM2,DISTRICT
SM291877,25.386,44.3066,Cosmesti,ADM2,DISTRICT
SM290431,27.3161,45.8493,Cosmesti,ADM2,DISTRICT
SM291518,25.8952,45.166,Cosmina De Jos,ADM2,DISTRICT
SM266482,-49.749,-20.4093,Cosmorama,ADM2,DISTRICT
SM266481,-47.1832,-22.6619,Cosmópolis,ADM2,DISTRICT
SM14265,-96.663,18.5842,Cosolapa,ADM2,DISTRICT
SM282884,-94.6109,17.9862,Cosoleacaque,ADM2,DISTRICT
SM14266,-97.8055,18.1394,Cosoltepec,ADM2,DISTRICT
SM15636,-67.0003,17.983,Costa Barrio,ADM2,DISTRICT
SM266080,-64.0732,-12.0617,Costa Marques,ADM2,DISTRICT
SM9913,-53.2736,-18.6374,Costa Rica (2003-),ADM2,DISTRICT
SM229113,-84.118,9.8943,Costa Rican Sign Language,LANGUAGE,LANGUAGE
SM290432,27.7234,45.7052,Costache Negri,ADM2,DISTRICT
SM252957,-121.38,36.9,Costano,ETHNICITY,ETHNICITY
SM291967,21.8876,45.7524,Costeiu,ADM2,DISTRICT
SM292179,24.0581,45.1694,Costesti,ADM2,DISTRICT
SM292265,27.7636,46.4797,Costesti,ADM2,DISTRICT
SM289870,26.7732,45.0618,Costesti,ADM2,DISTRICT
SM291236,24.8833,44.6667,Costesti,ADM2,DISTRICT
SM290257,25.5015,44.641,Costestii Din Vale,ADM2,DISTRICT
SM303322,-105.4283,37.2781,Costilla,ADM2,DISTRICT
SM291323,26.6462,46.7582,Costisa,ADM2,DISTRICT
SM290811,27.8583,47.0106,Costuleni,ADM2,DISTRICT
SM281490,-102.3,22.3667,Cosío,ADM2,DISTRICT
SM269987,-74.1434,4.776,Cota,ADM2,DISTRICT
SM286861,-72.2,-14,Cotabambas,ADM2,DISTRICT
SM287782,124.25,7.2167,Cotabato City,ADM1:ADM2,DISTRICT
SM229116,124.234,6.3942,Cotabato Manobo,LANGUAGE,LANGUAGE
SM273457,-78.4851,0.3153,Cotacachi,ADM2,DISTRICT
SM281403,113.5658,22.1435,Cotai,ADM1:ADM2,DISTRICT
SM282885,-96.3628,18.8561,Cotaxtla,ADM2,DISTRICT
SM10210,-65.8154,50.8668,Cote-Nord,ADM2,DISTRICT
SM291400,24.4664,44.3013,Coteana,ADM2,DISTRICT
SM9914,-44.17,-11.5884,Cotegipe (1985-),ADM2,DISTRICT
SM292337,27.0755,45.6369,Cotesti,ADM2,DISTRICT
SM266483,-46.9472,-23.6704,Cotia,ADM2,DISTRICT
SM282020,-102.7088,19.7481,Cotija,ADM2,DISTRICT
SM265619,-51.6953,-29.0072,Cotiporã,ADM2,DISTRICT
SM291238,24.6202,45.0052,Cotmeana,ADM2,DISTRICT
SM290812,26.9169,47.3581,Cotnari,ADM2,DISTRICT
SM252961,-72.41,-3.33,Coto,ETHNICITY,ETHNICITY
SM15637,-66.9787,18.482,Coto Barrio (Isabela),ADM2,DISTRICT
SM15638,-66.7315,18.0544,Coto Barrio (Peñuelas),ADM2,DISTRICT
SM270953,-82.9412,8.8855,Coto Brus,ADM2,DISTRICT
SM15639,-66.5546,18.037,Coto Laurel Barrio,ADM2,DISTRICT
SM15640,-66.4609,18.437,Coto Norte Barrio,ADM2,DISTRICT
SM15641,-66.466,18.4127,Coto Sur Barrio,ADM2,DISTRICT
SM292098,26.9864,46.1481,Cotofanesti,ADM2,DISTRICT
SM290351,23.6437,44.4128,Cotofenii Din Dos,ADM2,DISTRICT
SM229120,-97.3956,25.9116,Cotoname,LANGUAGE,LANGUAGE
SM262173,2.4225,6.3674,Cotonou,ADM2,DISTRICT
SM1026,-78.8333,-0.8333,Cotopaxi,ADM1,DISTRICT
SM10398,-75.7792,9.0572,Cotorra,ADM2,DISTRICT
SM271096,-82.2651,23.0385,Cotorro,ADM2,DISTRICT
SM229119,-41.5,-15,Cotoxo,LANGUAGE,LANGUAGE
SM263153,-58.7913,-9.5033,Cotriguaçu,ADM2,DISTRICT
SM12261,-77.9155,18.0949,Cotterwood,ADM2,DISTRICT
SM261361,115.7609,-31.9991,Cottesloe,ADM2,DISTRICT
SM302840,-100.2787,34.0777,Cottle,ADM2,DISTRICT
SM302180,-98.3722,34.2902,Cotton,ADM2,DISTRICT
SM301346,-95.1812,44.0071,Cottonwood,ADM2,DISTRICT
SM15642,-67.0802,18.0688,Cotui Barrio,ADM2,DISTRICT
SM292552,26.8718,48.1211,Cotusca,ADM2,DISTRICT
SM271855,-70.1667,19,Cotuí,ADM2,DISTRICT
SM17305,-61.0092,14.0022,Coubaril,ADM2,DISTRICT
SM17306,-61.0556,13.847,Coubaril Estate,ADM2,DISTRICT
SM12056,-8.5833,51.9667,County Cork (excl. Cork City),ADM2,DISTRICT
SM12057,-9,53.3333,County Galway (excl. Galway City),ADM2,DISTRICT
SM10132,-113.0565,54.733,County of Athabasca No. 12 (2003-),ADM2,DISTRICT
SM10133,-111.1731,49.5236,County of Forty Mile No. 8,ADM2,DISTRICT
SM10134,-119.1555,55.2725,County of Grande Prairie No. 1 (2003-),ADM2,DISTRICT
SM10135,-111.6504,53.4108,County of Minburn No. 27 (2015-),ADM2,DISTRICT
SM10136,-111.9449,50.6159,County of Newell No. 4 (2013-),ADM2,DISTRICT
SM10137,-111.3179,53.9333,County of St. Paul No. 19,ADM2,DISTRICT
SM10138,-113.052,54.2244,County of Thorhild No. 7  (2009-),ADM2,DISTRICT
SM10139,-111.5271,53.716,County of Two Hills No. 21 (2010-),ADM2,DISTRICT
SM10124,-114.0678,52.9743,County of Wetaskiwin No. 10,ADM2,DISTRICT
SM12262,-78.2375,18.4243,Cousins Cove,ADM2,DISTRICT
SM252962,-51.39,1.36,Coussari,ETHNICITY,ETHNICITY
SM267225,-49.1729,-8.5014,Couto Magalhaes,ADM2,DISTRICT
SM263362,-43.4181,-18.1186,Couto de Magalhães,ADM2,DISTRICT
SM323845,-111.9626,49.0025,Coutts,ADM2:ADM3,DISTRICT
SM1027,-61.4512,10.4179,Couva-Tabaquite-Talparo (1992-),ADM1,DISTRICT
SM1028,125.25,-9.2667,Covalima,ADM1,DISTRICT
SM270760,-72.74,6.5193,Covarachía,ADM2,DISTRICT
SM290493,21.6094,46.2057,Covasint,ADM2,DISTRICT
SM290212,26.1664,45.8353,Covasna,ADM2,DISTRICT
SM1029,26,45.9167,Covasna,ADM1,DISTRICT
SM10399,-75.6572,9.3941,Covenas,ADM2,DISTRICT
SM274616,-1.5,52.4167,Coventry,ADM1:ADM2,DISTRICT
SM289463,-7.5037,40.2923,Covilhã,ADM2,DISTRICT
SM302994,-79.994,37.7935,Covington,ADM2,DISTRICT
SM301433,-89.5526,31.6332,Covington,ADM2,DISTRICT
SM300258,-86.4513,31.2485,Covington,ADM2,DISTRICT
SM262126,2.273,7.345,Covè,ADM2,DISTRICT
SM300471,-84.7634,33.3535,Coweta,ADM2,DISTRICT
SM252963,-123.85,48.91,Cowichan,ETHNICITY,ETHNICITY
SM252963,-123.85,48.91,Cowichan,ETHNICITY,ETHNICITY
SM252963,-123.85,48.91,Cowichan,ETHNICITY,ETHNICITY
SM252963,-123.85,48.91,Cowichan,ETHNICITY,ETHNICITY
SM252963,-122.73,49.01,Cowichan,ETHNICITY,ETHNICITY
SM252963,-122.73,49.01,Cowichan,ETHNICITY,ETHNICITY
SM252963,-122.73,49.01,Cowichan,ETHNICITY,ETHNICITY
SM252963,-122.73,49.01,Cowichan,ETHNICITY,ETHNICITY
SM252963,-123.64,48.41,Cowichan,ETHNICITY,ETHNICITY
SM252963,-123.64,48.41,Cowichan,ETHNICITY,ETHNICITY
SM252963,-123.64,48.41,Cowichan,ETHNICITY,ETHNICITY
SM252963,-123.64,48.41,Cowichan,ETHNICITY,ETHNICITY
SM252963,-123.7,48.7,Cowichan,ETHNICITY,ETHNICITY
SM252963,-123.7,48.7,Cowichan,ETHNICITY,ETHNICITY
SM252963,-123.7,48.7,Cowichan,ETHNICITY,ETHNICITY
SM252963,-123.7,48.7,Cowichan,ETHNICITY,ETHNICITY
SM268744,-124.2194,48.8329,Cowichan Valley,ADM2,DISTRICT
SM300898,-96.8375,37.2378,Cowley,ADM2,DISTRICT
SM323862,-114.0703,49.5676,Cowley,ADM2:ADM3,DISTRICT
SM229125,-122.917,46.4167,Cowlitz,LANGUAGE,LANGUAGE
SM303106,-122.6808,46.1933,Cowlitz,ADM2,DISTRICT
SM252964,-122.85,46.32,Cowlitz,ETHNICITY,ETHNICITY
SM261529,148.7835,-33.8114,Cowra,ADM2,DISTRICT
SM262237,92.0153,21.5839,Cox'S Bazar,ADM2,DISTRICT
SM282391,-97.1336,18.2704,Coxcatlán,ADM2,DISTRICT
SM282480,-98.9106,21.5218,Coxcatlán,ADM2,DISTRICT
SM265620,-52.3658,-28.1033,Coxilha,ADM2,DISTRICT
SM263041,-54.717,-18.2126,Coxim,ADM2,DISTRICT
SM229126,-72.0833,6.0833,Coxima,LANGUAGE,LANGUAGE
SM264404,-36.6182,-7.6675,Coxixola,ADM2,DISTRICT
SM282886,-97.5523,20.2094,Coxquihi,ADM2,DISTRICT
SM12263,-77.2344,18.1027,Coxswain,ADM2,DISTRICT
SM274988,-13.4167,9.75,Coyah,ADM1:ADM2,DISTRICT
SM229127,-73,11.3333,Coyaima,LANGUAGE,LANGUAGE
SM270555,-75.174,3.7278,Coyaima,ADM2,DISTRICT
SM283219,-105.1386,29.6879,Coyame del Sotol,ADM2,DISTRICT
SM283335,-99.1597,19.3468,Coyoacán,ADM2,DISTRICT
SM282393,-96.9838,18.2719,Coyomeapan,ADM2,DISTRICT
SM282394,-97.827,18.4166,Coyotepec,ADM2,DISTRICT
SM281909,-99.2059,19.7762,Coyotepec,ADM2,DISTRICT
SM229128,-97.6258,18.3814,Coyotepec Popoloca,LANGUAGE,LANGUAGE
SM281594,-100.0939,17.0754,Coyuca de Benítez,ADM2,DISTRICT
SM281595,-100.9617,18.0587,Coyuca de Catalán,ADM2,DISTRICT
SM282887,-97.6565,20.3355,Coyutla,ADM2,DISTRICT
SM229129,-97.6852,20.5447,Coyutla Totonac,LANGUAGE,LANGUAGE
SM291782,25.4438,47.3593,Cozanesti,ADM2,DISTRICT
SM289871,26.4941,45.3324,Cozieni,ADM2,DISTRICT
SM291132,24.4983,46.8038,Cozma,ADM2,DISTRICT
SM290813,28.013,46.8603,Cozmesti,ADM2,DISTRICT
SM14267,-86.9284,20.4535,Cozumel (1993-),ADM2,DISTRICT
SM291324,26.2587,47.096,Cracaoani,ADM2,DISTRICT
SM291133,24.5712,46.4743,Craciunesti,ADM2,DISTRICT
SM291657,22.6988,47.5979,Craidorolt,ADM2,DISTRICT
SM291134,24.4479,46.7442,Craiesti,ADM2,DISTRICT
SM302995,-80.2123,37.4813,Craig,ADM2,DISTRICT
SM302181,-95.2085,36.7618,Craig,ADM2,DISTRICT
SM12264,-77.563,18.2366,Craig Head,ADM2,DISTRICT
SM302359,-90.6329,35.8308,Craighead,ADM2,DISTRICT
SM290352,23.8088,44.3194,Craiova,ADM2,DISTRICT
SM304768,45.0381,12.7731,Craiter,ADM2,DISTRICT
SM290494,21.9791,46.5997,Craiva,ADM2,DISTRICT
SM291402,24.7274,44.3051,Crampoia,ADM2,DISTRICT
SM261362,117.329,-34.3341,Cranbrook,ADM2,DISTRICT
SM302841,-102.5156,31.4285,Crane,ADM2,DISTRICT
SM285907,5.5837,51.2775,Cranendonck,ADM2,DISTRICT
SM291879,24.8085,44.0137,Crangeni,ADM2,DISTRICT
SM291596,22.8298,47.1619,Crasna,ADM2,DISTRICT
SM290623,23.5563,45.1707,Crasna,ADM2,DISTRICT
SM267910,-40.7434,-5.2508,Crateús,ADM2,DISTRICT
SM289296,-7.6441,39.2866,Crato,ADM2,DISTRICT
SM267911,-39.4713,-7.1471,Crato,ADM2,DISTRICT
SM301948,-77.0826,35.1172,Craven,ADM2,DISTRICT
SM266484,-47.7573,-21.3379,Cravinhos,ADM2,DISTRICT
SM270600,-70.1547,6.3875,Cravo Norte,ADM2,DISTRICT
SM267381,-39.7549,-13.4702,Cravolândia,ADM2,DISTRICT
SM303224,-90.9311,43.2395,Crawford,ADM2,DISTRICT
SM302297,-80.1063,41.6847,Crawford,ADM2,DISTRICT
SM302091,-82.9198,40.8508,Crawford,ADM2,DISTRICT
SM301263,-84.6103,44.6836,Crawford,ADM2,DISTRICT
SM301543,-91.304,37.9764,Crawford,ADM2,DISTRICT
SM300800,-95.382,42.0372,Crawford,ADM2,DISTRICT
SM300899,-94.8518,37.5073,Crawford,ADM2,DISTRICT
SM300695,-86.4517,38.2924,Crawford,ADM2,DISTRICT
SM300472,-83.9863,32.7145,Crawford,ADM2,DISTRICT
SM300600,-87.7596,39.0027,Crawford,ADM2,DISTRICT
SM302360,-94.2431,35.5888,Crawford,ADM2,DISTRICT
SM12265,-77.916,18.0506,Crawford,ADM2,DISTRICT
SM265356,-36.8027,-9.6012,Craíbas,ADM2,DISTRICT
SM291597,23.2276,47.1949,Creaca,ADM2,DISTRICT
SM252965,-103.77,56.14,Cree,ETHNICITY,ETHNICITY
SM252965,-103.77,56.14,Cree,ETHNICITY,ETHNICITY
SM252965,-102.5,50.3,Cree,ETHNICITY,ETHNICITY
SM252965,-102.5,50.3,Cree,ETHNICITY,ETHNICITY
SM229131,-87.5,31,Creek,LANGUAGE,LANGUAGE
SM302182,-96.3709,35.9027,Creek,ADM2,DISTRICT
SM252966,-83.54,33.67,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-83.54,33.67,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-83.54,33.67,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-83.41,31.29,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-83.41,31.29,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-83.41,31.29,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-84.92,33.64,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-84.92,33.64,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-84.92,33.64,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-84,33,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-84,33,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-84,33,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-86,32.93,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-86,32.93,Creek-generic,ETHNICITY,ETHNICITY
SM252966,-86,32.93,Creek-generic,ETHNICITY,ETHNICITY
SM277869,9.9333,45.2333,Cremona,ADM2,DISTRICT
SM323921,-114.4794,51.5427,Cremona,ADM2:ADM3,DISTRICT
SM300259,-86.3136,31.7315,Crenshaw,ADM2,DISTRICT
SM228771,-125,55,Creolized Grand Ronde Chinook Jargon,LANGUAGE,LANGUAGE
SM275979,14.4084,44.9611,Cres,ADM2,DISTRICT
SM12266,-76.9763,18.0362,Crescent,ADM2,DISTRICT
SM303604,-69.1667,10.3,Crespo,ADM2,DISTRICT
SM17307,-61.0403,13.86,Cresslands,ADM2,DISTRICT
SM275149,24.895,35.1559,Crete,ADM1:ADM2,DISTRICT
SM292180,24.1801,44.7057,Creteni,ADM2,DISTRICT
SM292266,27.9683,46.6351,Cretesti,ADM2,DISTRICT
SM274439,2.0548,46.0752,Creuse,ADM2,DISTRICT
SM290258,25.9226,44.5979,Crevedia,ADM2,DISTRICT
SM290562,25.6105,44.4367,Crevedia Mare,ADM2,DISTRICT
SM291881,25.5739,44.2451,Crevenicu,ADM2,DISTRICT
SM289790,23.5531,46.1869,Cricau,ADM2,DISTRICT
SM291968,22.0626,45.6445,Criciova,ADM2,DISTRICT
SM266405,-49.3872,-28.721,Criciúma,ADM2,DISTRICT
SM275980,14.7,45.1667,Crikvenica,ADM2,DISTRICT
SM1031,34,45,Crimea,ADM1,DISTRICT
SM229139,34.4,45.3,Crimean Gothic,LANGUAGE,LANGUAGE
SM229141,34.08,45,Crimean Tatar,LANGUAGE,LANGUAGE
SM292040,29.3426,45.1609,Crisan,ADM2,DISTRICT
SM290752,22.8659,46.1233,Criscior,ADM2,DISTRICT
SM291598,23.0697,47.2503,Criseni,ADM2,DISTRICT
SM300474,-83.7681,31.9229,Crisp,ADM2,DISTRICT
SM265621,-54.1417,-27.4809,Crissiumal,ADM2,DISTRICT
SM263364,-45.5245,-20.8096,Cristais,ADM2,DISTRICT
SM266485,-47.373,-20.3654,Cristais Paulista,ADM2,DISTRICT
SM265623,-52.0293,-31.0245,Cristal,ADM2,DISTRICT
SM265622,-53.2436,-27.4252,Cristal do Sul,ADM2,DISTRICT
SM268254,-47.4976,-16.7537,Cristalina,ADM2,DISTRICT
SM267226,-49.3686,-10.6257,Cristalândia,ADM2,DISTRICT
SM265208,-45.1105,-10.8201,Cristalândia do Piauí,ADM2,DISTRICT
SM291135,24.4894,46.501,Cristesti,ADM2,DISTRICT
SM290814,26.5853,47.2782,Cristesti,ADM2,DISTRICT
SM292553,26.7155,47.6148,Cristesti,ADM2,DISTRICT
SM291719,24.03,45.7844,Cristian,ADM2,DISTRICT
SM292622,25.4829,45.6247,Cristian,ADM2,DISTRICT
SM263366,-43.8293,-20.8369,Cristiano Otoni,ADM2,DISTRICT
SM268255,-48.6811,-17.1967,Cristianópolis,ADM2,DISTRICT
SM263367,-45.2882,-22.2159,Cristina,ADM2,DISTRICT
SM292554,26.3695,48.102,Cristinesti,ADM2,DISTRICT
SM265209,-44.0481,-8.6904,Cristino Castro,ADM2,DISTRICT
SM267083,-37.7195,-11.4951,Cristinápolis,ADM2,DISTRICT
SM292421,22.5657,46.4233,Cristioru De Jos,ADM2,DISTRICT
SM17959,125.6084,-8.5205,Cristo Rei,ADM2,DISTRICT
SM271768,-71.2987,18.3397,Cristobal,ADM2,DISTRICT
SM291599,23.4374,47.2123,Cristolt,ADM2,DISTRICT
SM290683,25.0458,46.2839,Cristuru Secuiesc,ADM2,DISTRICT
SM263365,-42.8187,-16.7255,Cristália,ADM2,DISTRICT
SM303654,-66.8375,10.2629,Cristóbal Rojas,ADM2,DISTRICT
SM267383,-44.2499,-12.2179,Cristópolis,ADM2,DISTRICT
SM263363,-40.975,-17.2415,Crisólita,ADM2,DISTRICT
SM267382,-38.1393,-11.4553,Crisópolis,ADM2,DISTRICT
SM301029,-88.0972,37.3527,Crittenden,ADM2,DISTRICT
SM302361,-90.3089,35.2079,Crittenden,ADM2,DISTRICT
SM1032,29.0307,47.1586,Criuleni,ADM1,DISTRICT
SM268256,-50.0424,-14.6616,Crixás,ADM2,DISTRICT
SM267227,-49.0725,-11.1643,Crixás do Tocantins,ADM2,DISTRICT
SM305530,17.9388,45.6921,Crnac,ADM2,DISTRICT
SM296259,15.1983,45.5239,Crnomelj,ADM1:ADM2,DISTRICT
SM17830,14.5537,46.1151,Crnuce District,ADM2,DISTRICT
SM252968,15.3,44.82,Croat,ETHNICITY,ETHNICITY
SM252968,15.3,44.82,Croat,ETHNICITY,ETHNICITY
SM252968,15.3,44.82,Croat,ETHNICITY,ETHNICITY
SM252968,16.5,43.383,Croat,ETHNICITY,ETHNICITY
SM252968,16.5,43.383,Croat,ETHNICITY,ETHNICITY
SM252968,16.5,43.383,Croat,ETHNICITY,ETHNICITY
SM252968,16.446,43.513,Croat,ETHNICITY,ETHNICITY
SM252968,16.446,43.513,Croat,ETHNICITY,ETHNICITY
SM252968,16.446,43.513,Croat,ETHNICITY,ETHNICITY
SM229145,16.796,45.502,Croatian Sign Language,LANGUAGE,LANGUAGE
SM229146,15.982,45.555,Croatian Standard,DIALECT,LANGUAGE
SM267912,-40.868,-4.3909,Croatá,ADM2,DISTRICT
SM302842,-101.4122,30.723,Crockett,ADM2,DISTRICT
SM302552,-89.1395,35.8135,Crockett,ADM2,DISTRICT
SM12267,-77.2051,18.1327,Crofts Hill,ADM2,DISTRICT
SM276104,-72.0695,18.5385,Croix-des-Bouquets,ADM2,DISTRICT
SM12268,-76.9846,17.9584,Cromarty,ADM2,DISTRICT
SM268257,-49.3298,-17.2476,Cromínia,ADM2,DISTRICT
SM303314,-104.5699,44.5886,Crook,ADM2,DISTRICT
SM302282,-120.3566,44.1422,Crook,ADM2,DISTRICT
SM1033,-74.2153,22.7336,Crooked Island,ADM1,DISTRICT
SM12269,-77.3,18.1429,Crooked River,ADM2,DISTRICT
SM302843,-101.3,33.6146,Crosby,ADM2,DISTRICT
SM302362,-90.7712,35.2957,Cross,ADM2,DISTRICT
SM12270,-77.5011,17.9073,Cross Keys,ADM2,DISTRICT
SM1034,8.5,5.75,Cross River,ADM1,DISTRICT
SM229149,8.2359,6.1693,Cross River Mbembe,LANGUAGE,LANGUAGE
SM12271,-76.7881,17.9945,Cross Roads,ADM2,DISTRICT
SM323922,-114.0337,51.4262,Crossfield,ADM2:ADM3,DISTRICT
SM277939,17.0813,39.1201,Crotone,ADM2,DISTRICT
SM229150,-107.874,45.368,Crow,LANGUAGE,LANGUAGE
SM252969,-103.66,47.25,Crow,ETHNICITY,ETHNICITY
SM252969,-103.66,47.25,Crow,ETHNICITY,ETHNICITY
SM252969,-103.66,47.25,Crow,ETHNICITY,ETHNICITY
SM252969,-108,45,Crow,ETHNICITY,ETHNICITY
SM252969,-108,45,Crow,ETHNICITY,ETHNICITY
SM252969,-108,45,Crow,ETHNICITY,ETHNICITY
SM252969,-108.48,45.83,Crow,ETHNICITY,ETHNICITY
SM252969,-108.48,45.83,Crow,ETHNICITY,ETHNICITY
SM252969,-108.48,45.83,Crow,ETHNICITY,ETHNICITY
SM301347,-94.0709,46.4824,Crow Wing,ADM2,DISTRICT
SM303323,-103.7848,38.3267,Crowley,ADM2,DISTRICT
SM17308,-60.9715,13.9517,Crownlands/Marc,ADM2,DISTRICT
SM324119,-114.4798,49.6234,Crowsnest Pass,ADM2:ADM3,DISTRICT
SM261622,142.3225,-18.5136,Croydon,ADM2,DISTRICT
SM291783,25.5926,47.3747,Crucea,ADM2,DISTRICT
SM290159,28.2059,44.5505,Crucea,ADM2,DISTRICT
SM271081,-80.2125,22.2388,Cruces,ADM2,DISTRICT
SM15644,-67.2134,18.3507,Cruces Barrio (Aguada),ADM2,DISTRICT
SM15643,-67.222,18.3392,Cruces Barrio (Rincon),ADM2,DISTRICT
SM263368,-44.3599,-20.4066,Crucilândia,ADM2,DISTRICT
SM291659,23.2478,47.6595,Crucisor,ADM2,DISTRICT
SM282638,-98.3998,24.6412,Cruillas,ADM2,DISTRICT
SM290624,23.6725,44.6385,Cruset,ADM2,DISTRICT
SM295376,159.9545,-9.4311,Cruz,ADM2,DISTRICT
SM267913,-40.3357,-2.8962,Cruz,ADM2,DISTRICT
SM261076,-64.9167,-26.9167,Cruz Alta,ADM2,DISTRICT
SM9915,-53.5733,-28.7285,Cruz Alta (1996-),ADM2,DISTRICT
SM15645,-67.1014,18.3704,Cruz Barrio,ADM2,DISTRICT
SM303903,-64.7787,18.3265,Cruz Bay,ADM2,DISTRICT
SM264835,-51.2256,-25.9109,Cruz Machado,ADM2,DISTRICT
SM303850,-70.1122,8.7687,Cruz Paredes,ADM2,DISTRICT
SM303729,-64.2583,10.5797,Cruz Salmerón Acosta,ADM2,DISTRICT
SM267384,-39.1144,-12.6703,Cruz das Almas,ADM2,DISTRICT
SM261142,-65,-30.75,Cruz del Eje,ADM2,DISTRICT
SM264405,-35.1418,-7.1475,Cruz do Espírito Santo,ADM2,DISTRICT
SM9916,-52.6326,-27.6411,Cruzaltense,ADM2:ADM3,DISTRICT
SM266487,-45.0011,-22.571,Cruzeiro,ADM2,DISTRICT
SM263370,-46.6578,-18.9671,Cruzeiro da Fortaleza,ADM2,DISTRICT
SM264836,-53.1274,-25.603,Cruzeiro do Iguaçu,ADM2,DISTRICT
SM264837,-53.0843,-23.8097,Cruzeiro do Oeste,ADM2,DISTRICT
SM265625,-52.0304,-29.5671,Cruzeiro do Sul,ADM2,DISTRICT
SM264838,-52.1597,-22.9742,Cruzeiro do Sul,ADM2,DISTRICT
SM262799,-72.9298,-8.0159,Cruzeiro do Sul,ADM2,DISTRICT
SM265533,-36.8473,-6.3202,Cruzeta,ADM2,DISTRICT
SM229151,-119.864,34.0465,Cruzeño,LANGUAGE,LANGUAGE
SM264839,-51.4848,-24.0236,Cruzmaltina,ADM2,DISTRICT
SM266486,-50.7579,-22.7371,Cruzália,ADM2,DISTRICT
SM263371,-44.7983,-21.736,Cruzília,ADM2,DISTRICT
SM324041,-114.04,52.9779,Crystal Springs,ADM2:ADM3,DISTRICT
SM276177,22.5981,47.8305,Csenger,ADM2,DISTRICT
SM1035,20.25,46.4167,Csongrád,ADM1,DISTRICT
SM276247,20.0879,46.6615,Csongrádi,ADM2,DISTRICT
SM276264,17.2604,47.584,Csorna,ADM2,DISTRICT
SM276167,17.1002,46.295,Csurgó,ADM2,DISTRICT
SM229154,108.431,15.1688,Cua,LANGUAGE,LANGUAGE
SM252970,106.78,15.11,Cua,ETHNICITY,ETHNICITY
SM260354,16.5739,-9.0439,Cuaba Nzogo,ADM2,DISTRICT
SM260354,16.5739,-9.0439,Cuaba Nzogo,ADM2,DISTRICT
SM260354,16.4747,-9.0195,Cuaba Nzogo,ADM2,DISTRICT
SM260354,16.4747,-9.0195,Cuaba Nzogo,ADM2,DISTRICT
SM283336,-99.2877,19.3593,Cuajimalpa de Morelos,ADM2,DISTRICT
SM14268,-98.5075,16.4936,Cuajinicuilapa (2002-),ADM2,DISTRICT
SM281597,-98.6725,17.7293,Cualác,ADM2,DISTRICT
SM283951,36.5422,-14.7329,Cuamba,ADM2,DISTRICT
SM1036,19.3832,-15.9476,Cuando Cubango,ADM1,DISTRICT
SM260417,18.1966,-17.0106,Cuangar,ADM2,DISTRICT
SM260335,18.0879,-8.5804,Cuango,ADM2,DISTRICT
SM260451,16.3163,-16.5113,Cuanhama,ADM2,DISTRICT
SM461305,14.8887,-8.9114,Cuanza Norte province (2011-),ADM1,DISTRICT
SM1038,15.032,-10.827,Cuanza Sul,ADM1,DISTRICT
SM282681,-97.763,19.3355,Cuapiaxtla,ADM2,DISTRICT
SM282395,-97.8222,18.9182,Cuapiaxtla de Madero,ADM2,DISTRICT
SM15646,-66.6102,18.0072,Cuarto Barrio,ADM2,DISTRICT
SM270304,-77.7364,0.8757,Cuaspud,ADM2,DISTRICT
SM283312,-102.1038,26.8465,Cuatrociénegas,ADM2,DISTRICT
SM229155,-98.6155,17.2884,Cuatzoquitengo,DIALECT,LANGUAGE
SM252971,-101.23,20.35,Cuauhcomeca,ETHNICITY,ETHNICITY
SM283093,-102.3456,22.4823,Cuauhtémoc,ADM2,DISTRICT
SM283337,-99.143,19.4449,Cuauhtémoc,ADM2,DISTRICT
SM283220,-106.8965,28.5703,Cuauhtémoc,ADM2,DISTRICT
SM283320,-103.6089,19.3274,Cuauhtémoc,ADM2,DISTRICT
SM282396,-97.791,19.9161,Cuautempan,ADM2,DISTRICT
SM281598,-98.9847,16.7052,Cuautepec,ADM2,DISTRICT
SM281664,-98.2868,19.9823,Cuautepec de Hinojosa,ADM2,DISTRICT
SM282397,-98.0468,18.9496,Cuautinchán,ADM2,DISTRICT
SM281910,-99.1801,19.6736,Cuautitlán,ADM2,DISTRICT
SM281911,-99.2153,19.6473,Cuautitlán Izcalli,ADM2,DISTRICT
SM281788,-104.2934,19.4325,Cuautitlán de García Barragán,ADM2,DISTRICT
SM282127,-98.9529,18.8251,Cuautla,ADM2,DISTRICT
SM281789,-104.4716,20.2048,Cuautla,ADM2,DISTRICT
SM282398,-98.2641,19.1162,Cuautlancingo,ADM2,DISTRICT
SM282682,-98.096,19.3499,Cuaxomulco,ADM2,DISTRICT
SM282399,-98.1919,18.4442,Cuayuca de Andrade,ADM2,DISTRICT
SM229158,4.6846,10.2607,Cuba,LANGUAGE,LANGUAGE
SM289430,-7.9211,38.2197,Cuba,ADM2,DISTRICT
SM229157,-79.209,22.061,Cuba Sign Language,LANGUAGE,LANGUAGE
SM260400,14.3673,-13.1118,Cubal,ADM2,DISTRICT
SM261363,117.155,-32.7403,Cuballing,ADM2,DISTRICT
SM454409,-77,38.917,Cuban Americans,ETHNICITY,ETHNICITY
SM252973,-76.97,20.96,Cubanacan,ETHNICITY,ETHNICITY
SM270761,-72.19,6.8856,Cubará,ADM2,DISTRICT
SM264406,-36.3342,-6.8612,Cubati,ADM2,DISTRICT
SM266489,-46.4162,-23.8602,Cubatão,ADM2,DISTRICT
SM229159,-70.1939,1.3238,Cubeo,LANGUAGE,LANGUAGE
SM252975,-70.33,0.63,Cubeo,ETHNICITY,ETHNICITY
SM252975,-70.33,0.63,Cubeo,ETHNICITY,ETHNICITY
SM252975,-70.33,0.63,Cubeo,ETHNICITY,ETHNICITY
SM252975,-70.33,0.63,Cubeo,ETHNICITY,ETHNICITY
SM252975,-70.5,1.4165,Cubeo,ETHNICITY,ETHNICITY
SM252975,-70.5,1.4165,Cubeo,ETHNICITY,ETHNICITY
SM252975,-70.5,1.4165,Cubeo,ETHNICITY,ETHNICITY
SM252975,-70.5,1.4165,Cubeo,ETHNICITY,ETHNICITY
SM252975,-71,1,Cubeo,ETHNICITY,ETHNICITY
SM252975,-71,1,Cubeo,ETHNICITY,ETHNICITY
SM252975,-71,1,Cubeo,ETHNICITY,ETHNICITY
SM252975,-71,1,Cubeo,ETHNICITY,ETHNICITY
SM252975,-70.5,1.25,Cubeo,ETHNICITY,ETHNICITY
SM252975,-70.5,1.25,Cubeo,ETHNICITY,ETHNICITY
SM252975,-70.5,1.25,Cubeo,ETHNICITY,ETHNICITY
SM252975,-70.5,1.25,Cubeo,ETHNICITY,ETHNICITY
SM275343,-90.6713,15.1545,Cubulco,ADM2,DISTRICT
SM15647,-65.8605,18.275,Cubuy Barrio,ADM2,DISTRICT
SM290433,27.894,45.7325,Cuca,ADM2,DISTRICT
SM291239,24.5064,44.9565,Cuca,ADM2,DISTRICT
SM270762,-73.4463,5.5274,Cucaita,ADM2,DISTRICT
SM291136,24.2467,46.404,Cucerdea,ADM2,DISTRICT
SM260418,16.9611,-14.6373,Cuchi,ADM2,DISTRICT
SM15648,-66.3612,18.2906,Cuchillas Barrio (Corozal),ADM2,DISTRICT
SM15649,-67.0811,18.4035,Cuchillas Barrio (Moca),ADM2,DISTRICT
SM15650,-66.3859,18.3045,Cuchillas Barrio (Morovis),ADM2,DISTRICT
SM291137,24.1632,46.4591,Cuci,ADM2,DISTRICT
SM252976,-76.21,20.34,Cuciba,ETHNICITY,ETHNICITY
SM269988,-73.8076,5.2163,Cucunubá,ADM2,DISTRICT
SM282562,-110.6727,30.3946,Cucurpe,ADM2,DISTRICT
SM290815,26.9281,47.2759,Cucuteni,ADM2,DISTRICT
SM270362,-72.7971,7.4989,Cucutilla,ADM2,DISTRICT
SM275878,-88.8402,14.6868,Cucuyagua,ADM2,DISTRICT
SM290434,27.686,45.7762,Cudalbi,ADM2,DISTRICT
SM277244,79.51,11.52,Cuddalore,ADM2,DISTRICT
SM261364,118.005,-27.2263,Cue,ADM2,DISTRICT
SM15651,-66.7172,18.0457,Cuebas Barrio,ADM2,DISTRICT
SM260408,18.1123,-12.1387,Cuemba,ADM2,DISTRICT
SM274064,-2,40,Cuenca,ADM2,DISTRICT
SM273424,-78.9833,-2.9,Cuenca,ADM2,DISTRICT
SM281528,-103.7377,24.6412,Cuencamé,ADM2,DISTRICT
SM282128,-99.24,18.9553,Cuernavaca,ADM2,DISTRICT
SM281536,-101.6611,20.619,Cuerámaro,ADM2,DISTRICT
SM271134,-75.9327,20.6476,Cueto,ADM2,DISTRICT
SM281599,-99.8171,18.1126,Cuetzala del Progreso,ADM2,DISTRICT
SM282400,-97.4927,20.0386,Cuetzalan del Progreso,ADM2,DISTRICT
SM229162,-78.1846,8.2682,Cueva,LANGUAGE,LANGUAGE
SM15652,-66.0199,18.3643,Cuevas Barrio,ADM2,DISTRICT
SM12272,-77.4205,18.2279,Cuffie Ridge,ADM2,DISTRICT
SM289791,23.4074,45.8137,Cugir,ADM2,DISTRICT
SM263154,-55.8902,-15.4192,Cuiaba,ADM2,DISTRICT
SM229164,-69.8197,6.1983,Cuiba,LANGUAGE,LANGUAGE
SM282889,-96.8201,18.7642,Cuichapa,ADM2,DISTRICT
SM285908,5.8577,51.7174,Cuijk,ADM2,DISTRICT
SM275317,-90.274,14.2654,Cuilapa,ADM2,DISTRICT
SM14269,-96.7817,17.0039,Cuilapam de Guerrero,ADM2,DISTRICT
SM275501,-91.9921,15.4162,Cuilco,ADM2,DISTRICT
SM260336,19.6775,-8.0245,Cuilo,ADM2,DISTRICT
SM260388,14.6769,-6.2008,Cuimba,ADM2,DISTRICT
SM295568,-89.6333,13.6256,Cuisnahuat,ADM2,DISTRICT
SM264409,-35.5342,-6.9064,Cuitegi,ADM2,DISTRICT
SM229167,-100.5,18.1,Cuitlatec,LANGUAGE,LANGUAGE
SM282890,-96.7318,18.7908,Cuitláhuac,ADM2,DISTRICT
SM260419,18.8208,-14.9382,Cuito Cuanavale,ADM2,DISTRICT
SM282022,-101.1432,19.9959,Cuitzeo,ADM2,DISTRICT
SM264408,-36.0676,-6.5139,Cuité,ADM2,DISTRICT
SM264407,-35.2604,-6.8979,Cuité de Mamanguape,ADM2,DISTRICT
SM238199,-96.6647,16.2197,Cuixtla-Xitla Zapotec,LANGUAGE,LANGUAGE
SM291061,22.9313,44.1972,Cujmir,ADM2,DISTRICT
SM266081,-62.4425,-8.9782,Cujubim,ADM2,DISTRICT
SM18036,35.1312,37.0563,Cukurova,ADM2,DISTRICT
SM17309,-61.0065,13.9808,Cul De Sac,ADM2,DISTRICT
SM302844,-104.5174,31.4472,Culberson,ADM2,DISTRICT
SM291660,23.0559,47.7525,Culciu Mare,ADM2,DISTRICT
SM1040,-65.2871,18.3188,Culebra,ADM1,DISTRICT
SM15653,-65.302,18.3064,Culebra Barrio-Pueblo,ADM2,DISTRICT
SM15654,-66.1311,18.0714,Culebras Alto Barrio,ADM2,DISTRICT
SM15655,-66.1424,18.0807,Culebras Bajo Barrio,ADM2,DISTRICT
SM15656,-66.997,18.3102,Culebrinas Barrio,ADM2,DISTRICT
SM285760,5.2095,51.9447,Culemborg,ADM2,DISTRICT
SM261941,45.6667,39.1667,Culfa,ADM1:ADM2,DISTRICT
SM282548,-107.3527,24.7031,"Culiacán (pre-1982, excl. Boca San Lorenzo)",ADM2,DISTRICT
SM229168,-70.6648,-8.5527,Culina,LANGUAGE,LANGUAGE
SM252978,-66.54,-5.69,Culino,ETHNICITY,ETHNICITY
SM393980,125.0006,6.2522,Cullalabo Del Sur,ADM2:ADM3,DISTRICT
SM229169,-78.1165,-7.8786,Culli,LANGUAGE,LANGUAGE
SM300260,-86.8676,34.1319,Cullman,ADM2,DISTRICT
SM302996,-77.9559,38.4861,Culpeper,ADM2,DISTRICT
SM252979,-62.34,9.02,Cumana,ETHNICITY,ETHNICITY
SM229170,-63.018,10.209,Cumanagoto,LANGUAGE,LANGUAGE
SM252980,-64.24,9.84,Cumanagoto,ETHNICITY,ETHNICITY
SM271082,-80.1991,22.1498,Cumanayagua,ADM2,DISTRICT
SM273602,-79.135,-2.2079,Cumanda,ADM2,DISTRICT
SM270295,-73.341,4.2381,Cumaral,ADM2,DISTRICT
SM268258,-48.1638,-18.316,Cumari,ADM2,DISTRICT
SM461317,-69.5955,4.5757,Cumaribo (1996-),ADM2,DISTRICT
SM264976,-35.7353,-8.0344,Cumaru,ADM2,DISTRICT
SM264158,-51.2408,-8.3914,Cumaru do Norte,ADM2,DISTRICT
SM298335,30.9409,40.8818,Cumayeri,ADM2,DISTRICT
SM270305,-77.9414,0.9503,Cumbal,ADM2,DISTRICT
SM267084,-37.1767,-10.3496,Cumbe,ADM2,DISTRICT
SM302997,-78.245,37.5121,Cumberland,ADM2,DISTRICT
SM302553,-84.9984,35.9504,Cumberland,ADM2,DISTRICT
SM302298,-77.2656,40.1636,Cumberland,ADM2,DISTRICT
SM301830,-75.1293,39.3281,Cumberland,ADM2,DISTRICT
SM301949,-78.8274,35.0486,Cumberland,ADM2,DISTRICT
SM301207,-70.3302,43.8061,Cumberland,ADM2,DISTRICT
SM301030,-85.3885,36.7865,Cumberland,ADM2,DISTRICT
SM300601,-88.2402,39.2733,Cumberland,ADM2,DISTRICT
SM268835,-64.1654,45.7502,Cumberland,ADM2,DISTRICT
SM9887,150.967,-33.8367,Cumberland,ADM2,DISTRICT
SM12273,-77.4162,18.1052,Cumberland (Clarendon),ADM2,DISTRICT
SM18538,-76.8821,17.9765,Cumberland (Saint Catherine),ADM2,DISTRICT
SM270306,-77.5929,1.6768,Cumbitara,ADM2,DISTRICT
SM274617,-2.8333,54.5833,Cumbria,ADM1:ADM2,DISTRICT
SM229171,-69.5,2.8333,Cumeral,LANGUAGE,LANGUAGE
SM301690,-96.7874,41.9164,Cuming,ADM2,DISTRICT
SM282563,-109.7903,30.0602,Cumpas,ADM2,DISTRICT
SM229175,108.743,19.2182,Cun,LANGUAGE,LANGUAGE
SM252981,-77.23,7.31,Cuna,ETHNICITY,ETHNICITY
SM252981,-77.23,7.31,Cuna,ETHNICITY,ETHNICITY
SM252981,-77.23,7.31,Cuna,ETHNICITY,ETHNICITY
SM252981,-78.5,9.25,Cuna,ETHNICITY,ETHNICITY
SM252981,-78.5,9.25,Cuna,ETHNICITY,ETHNICITY
SM252981,-78.5,9.25,Cuna,ETHNICITY,ETHNICITY
SM252981,-78,9,Cuna,ETHNICITY,ETHNICITY
SM252981,-78,9,Cuna,ETHNICITY,ETHNICITY
SM252981,-78,9,Cuna,ETHNICITY,ETHNICITY
SM282954,-88.3464,20.6233,Cuncunul,ADM2,DISTRICT
SM260355,17.3562,-8.7226,Cunda-dia-Baza,ADM2,DISTRICT
SM270556,-74.693,4.0028,Cunday,ADM2,DISTRICT
SM261365,117.118,-31.6126,Cunderdin,ADM2,DISTRICT
SM3917,-74.1667,5,Cundinamarca (excl. Bogota),ADM1,DISTRICT
SM282634,-93.2315,18.0814,Cunduacán,ADM2,DISTRICT
SM229172,36,38,Cuneiform Luwian,LANGUAGE,LANGUAGE
SM1042,15.4353,-16.5101,Cunene,ADM1,DISTRICT
SM277885,7.5667,44.5167,Cuneo,ADM2,DISTRICT
SM291403,24.3927,44.685,Cungrea,ADM2,DISTRICT
SM266490,-44.9264,-23.0386,Cunha,ADM2,DISTRICT
SM266406,-53.2102,-26.8661,Cunha Porã,ADM2,DISTRICT
SM266407,-53.1079,-26.9774,Cunhataí,ADM2,DISTRICT
SM260409,16.8182,-11.977,Cunhinga,ADM2,DISTRICT
SM252982,-70.27,-6.61,Cuniba,ETHNICITY,ETHNICITY
SM275252,-91.0189,15.3631,Cunén,ADM2,DISTRICT
SM252983,-70.46,-15.93,Cupaca,ETHNICITY,ETHNICITY
SM263372,-41.1337,-18.9966,Cuparaque,ADM2,DISTRICT
SM252984,-115.8,32.83,Cupeno,ETHNICITY,ETHNICITY
SM252984,-115.8,32.83,Cupeno,ETHNICITY,ETHNICITY
SM252984,-115.8,32.83,Cupeno,ETHNICITY,ETHNICITY
SM252984,-115.8,32.83,Cupeno,ETHNICITY,ETHNICITY
SM252984,-117,33,Cupeno,ETHNICITY,ETHNICITY
SM252984,-117,33,Cupeno,ETHNICITY,ETHNICITY
SM252984,-117,33,Cupeno,ETHNICITY,ETHNICITY
SM252984,-117,33,Cupeno,ETHNICITY,ETHNICITY
SM252984,-116.59,33.26,Cupeno,ETHNICITY,ETHNICITY
SM252984,-116.59,33.26,Cupeno,ETHNICITY,ETHNICITY
SM252984,-116.59,33.26,Cupeno,ETHNICITY,ETHNICITY
SM252984,-116.59,33.26,Cupeno,ETHNICITY,ETHNICITY
SM252984,-116.7,33.2,Cupeno,ETHNICITY,ETHNICITY
SM252984,-116.7,33.2,Cupeno,ETHNICITY,ETHNICITY
SM252984,-116.7,33.2,Cupeno,ETHNICITY,ETHNICITY
SM252984,-116.7,33.2,Cupeno,ETHNICITY,ETHNICITY
SM15657,-66.0487,18.3462,Cupey Barrio,ADM2,DISTRICT
SM229180,-116.515,33.3148,Cupeño,LANGUAGE,LANGUAGE
SM264977,-35.9129,-8.5686,Cupira,ADM2,DISTRICT
SM291002,23.9424,47.5326,Cupseni,ADM2,DISTRICT
SM281790,-103.0373,20.964,Cuquito,ADM2,DISTRICT
SM260861,-66.5,-38.1667,Curacó,ADM2,DISTRICT
SM275935,-87.57,13.83,Curarén,ADM2,DISTRICT
SM292422,22.4372,46.7031,Curatele,ADM2,DISTRICT
SM12274,-77.2484,17.9398,Curatoe Hill,ADM2,DISTRICT
SM267385,-39.6685,-9.1346,Curaçá,ADM2,DISTRICT
SM289939,26.5763,44.2021,Curcani,ADM2,DISTRICT
SM269076,-71.0915,-35.0493,Curicó,ADM2,DISTRICT
SM270873,-76.0304,1.0986,Curillo,ADM2,DISTRICT
SM265210,-44.3899,-9.8621,Curimatá,ADM2,DISTRICT
SM264159,-49.6644,-6.1814,Curionópolis,ADM2,DISTRICT
SM264840,-49.2908,-25.504,Curitiba,ADM2,DISTRICT
SM266408,-50.6096,-27.2741,Curitibanos,ADM2,DISTRICT
SM270458,-73.046,6.6063,Curití,ADM2,DISTRICT
SM264841,-50.4654,-24.0039,Curiúva,ADM2,DISTRICT
SM260452,13.6667,-17,Curoca,ADM2,DISTRICT
SM265211,-44.8647,-8.7399,Currais,ADM2,DISTRICT
SM265534,-36.484,-6.214,Currais Novos,ADM2,DISTRICT
SM265212,-40.7643,-7.8898,Curral Novo do Piauí,ADM2,DISTRICT
SM264412,-38.1985,-7.558,Curral Velho,ADM2,DISTRICT
SM264411,-35.286,-6.7236,Curral de Cima,ADM2,DISTRICT
SM263373,-41.7541,-15.8463,Curral de dentro,ADM2,DISTRICT
SM264160,-49.987,-1.5824,Curralinho,ADM2,DISTRICT
SM265214,-42.8608,-5.5849,Curralinhos,ADM2,DISTRICT
SM270973,-84.0291,9.9165,Curridabat,ADM2,DISTRICT
SM229186,-67.5028,0.6962,Curripaco,LANGUAGE,LANGUAGE
SM301950,-75.9368,36.3672,Currituck,ADM2,DISTRICT
SM302283,-124.2153,42.4666,Curry,ADM2,DISTRICT
SM301863,-103.3469,34.5742,Curry,ADM2,DISTRICT
SM291969,22.3266,45.8445,Curtea,ADM2,DISTRICT
SM291240,24.6751,45.1384,Curtea De Arges,ADM2,DISTRICT
SM292555,26.6411,47.7028,Curtesti,ADM2,DISTRICT
SM291404,24.3421,44.4818,Curtisoara,ADM2,DISTRICT
SM292423,22.2296,47.5325,Curtuiseni,ADM2,DISTRICT
SM15330,-55.7746,-24.3362,Curuguaty (2011-),ADM2,DISTRICT
SM269910,-73.5199,9.2055,Curumaní,ADM2,DISTRICT
SM252985,-61.14,-15.22,Curuminaca,ETHNICITY,ETHNICITY
SM262982,-44.7068,-1.8057,Cururupu,ADM2,DISTRICT
SM261168,-58.5,-29.5,Curuzú Cuatiá,ADM2,DISTRICT
SM264161,-55.1149,-1.7382,Curuá,ADM2,DISTRICT
SM264162,-47.8676,-0.6979,Curuçá,ADM2,DISTRICT
SM9917,-57.8455,-15.6372,Curvelandia,ADM2:ADM3,DISTRICT
SM263374,-44.416,-18.7853,Curvelo,ADM2,DISTRICT
SM252986,-80.55,32.84,Cusabo,ETHNICITY,ETHNICITY
SM295524,-89.1787,13.7749,Cuscatancingo,ADM2,DISTRICT
SM1043,-89.0333,13.8333,Cuscatlán,ADM1,DISTRICT
SM286906,-72,-13.5,Cusco,ADM2,DISTRICT
SM1044,-72.25,-13,Cusco,ADM1,DISTRICT
SM229190,-71.7663,-14.0853,Cusco Quechua,LANGUAGE,LANGUAGE
SM261113,-70.8333,-42.5,Cushamen,ADM2,DISTRICT
SM283221,-106.9415,28.2324,Cusihuiriachic,ADM2,DISTRICT
SM302485,-103.4515,43.6776,Custer,ADM2,DISTRICT
SM302184,-99.0015,35.6389,Custer,ADM2,DISTRICT
SM301691,-99.7261,41.3943,Custer,ADM2,DISTRICT
SM301677,-105.5718,46.2527,Custer,ADM2,DISTRICT
SM303324,-105.3675,38.1087,Custer,ADM2,DISTRICT
SM300554,-114.2818,44.2414,Custer,ADM2,DISTRICT
SM264978,-37.6621,-8.1288,Custódia,ADM2,DISTRICT
SM229192,38.6217,-3.1391,Cutchi-Swahili,LANGUAGE,LANGUAGE
SM286891,-78.85,-6.3667,Cutervo,ADM2,DISTRICT
SM267297,-50.5204,0.9971,Cutias,ADM2,DISTRICT
SM277134,86.25,20.5,Cuttack,ADM2,DISTRICT
SM281600,-100.599,18.6622,Cutzamala de Pinzón,ADM2,DISTRICT
SM252987,16.27,-16.08,Cuvale,ETHNICITY,ETHNICITY
SM260453,16.1326,-15.6759,Cuvelai,ADM2,DISTRICT
SM1045,16,-0.5,Cuvette,ADM1,DISTRICT
SM1046,14.5,0,Cuvette-Ouest,ADM1,DISTRICT
SM229194,14.0006,10.6448,Cuvok,LANGUAGE,LANGUAGE
SM273560,-75.8844,-0.2695,Cuyabeno,ADM2,DISTRICT
SM302092,-81.6779,41.4788,Cuyahoga,ADM2,DISTRICT
SM229196,-96.7314,18.0821,Cuyamecalco Mixtec,LANGUAGE,LANGUAGE
SM14272,-96.8394,17.967,Cuyamecalco Villa de Zaragoza,ADM2,DISTRICT
SM282401,-97.5911,19.6017,Cuyoaco,ADM2,DISTRICT
SM15658,-66.2434,18.101,Cuyon Barrio (Aibonito),ADM2,DISTRICT
SM15659,-66.2962,18.0847,Cuyon Barrio (Coamo),ADM2,DISTRICT
SM229197,119.3,10.3201,Cuyonon,LANGUAGE,LANGUAGE
SM295678,-89.0922,13.5334,Cuyultitán,ADM2,DISTRICT
SM1047,-60,6,Cuyuni-Mazaruni,ADM1,DISTRICT
SM282955,-89.3346,20.7218,Cuzamá,ADM2,DISTRICT
SM291601,23.219,46.9465,Cuzaplac,ADM2,DISTRICT
SM290090,23.9325,47.1827,Cuzdrioara,ADM2,DISTRICT
SM270764,-72.9628,5.5916,Cuítiva,ADM2,DISTRICT
SM229198,-2.9512,11.4182,Cwi Bwamu,LANGUAGE,LANGUAGE
SM323820,-110.6824,49.8732,Cypress County,ADM2:ADM3,DISTRICT
SM7588,33.43,35.13,Cypriot,ETHNICITY,ETHNICITY
SM229200,33.0943,35.1674,Cypriot Arabic,LANGUAGE,LANGUAGE
SM229201,32.9781,34.9246,Cypriot Greek,LANGUAGE,LANGUAGE
SM323954,-110.8253,52.4536,Czar,ADM2:ADM3,DISTRICT
SM288798,16.3467,52.9289,Czarnków-Trzcianka,ADM2,DISTRICT
SM229204,15.1044,49.8734,Czech,LANGUAGE,LANGUAGE
SM252989,14.39,51.14,Czech,ETHNICITY,ETHNICITY
SM252989,14.39,51.14,Czech,ETHNICITY,ETHNICITY
SM252989,14.39,51.14,Czech,ETHNICITY,ETHNICITY
SM252989,16,50,Czech,ETHNICITY,ETHNICITY
SM252989,16,50,Czech,ETHNICITY,ETHNICITY
SM252989,16,50,Czech,ETHNICITY,ETHNICITY
SM252989,14.4,50.1,Czech,ETHNICITY,ETHNICITY
SM252989,14.4,50.1,Czech,ETHNICITY,ETHNICITY
SM252989,14.4,50.1,Czech,ETHNICITY,ETHNICITY
SM229203,14.4774,50.012,Czech Sign Language,LANGUAGE,LANGUAGE
SM288751,19.3405,50.8412,Częstochowa,ADM2,DISTRICT
SM288750,19.1222,50.8096,Częstochowa (CIty),ADM2,DISTRICT
SM288709,17.1894,53.7511,Człuchów,ADM2,DISTRICT
SM303953,105.188,9.1641,Cà Mau,ADM2,DISTRICT
SM799,105.0833,9.0833,Cà Mau,ADM1,DISTRICT
SM304546,106.214,9.9588,Càng Long,ADM2,DISTRICT
SM274044,-6.3748,39.5221,Cáceres,ADM2,DISTRICT
SM270187,-75.2248,7.6825,Cáceres,ADM2,DISTRICT
SM270396,-73.1667,7.75,Cáchira,ADM2,DISTRICT
SM270397,-72.6783,7.2505,Cácota,ADM2,DISTRICT
SM274033,-5.75,36.5,Cádiz,ADM2,DISTRICT
SM304533,105.9462,10.3882,Cái Bè,ADM2,DISTRICT
SM303954,105.042,9.0009,Cái Nước,ADM2,DISTRICT
SM303946,105.7855,9.9927,Cái Răng,ADM2,DISTRICT
SM269978,-73.9572,4.3871,Cáqueza,ADM2,DISTRICT
SM303755,-72.1167,7.8333,Cárdenas,ADM2,DISTRICT
SM282629,-93.5579,18.1342,Cárdenas,ADM2,DISTRICT
SM282527,-99.6608,21.9999,Cárdenas,ADM2,DISTRICT
SM271005,-81.2352,22.9898,Cárdenas,ADM2,DISTRICT
SM263297,-46.9176,-20.5929,Cássia,ADM2,DISTRICT
SM266460,-47.1378,-21.2592,Cássia dos Coqueiros,ADM2,DISTRICT
SM304083,106.9975,20.8063,Cát Hải,ADM2,DISTRICT
SM304269,107.3677,11.6629,Cát Tiên,ADM2,DISTRICT
SM289277,-16.9857,32.6872,Câmara de Lobos,ADM2,DISTRICT
SM266042,-54.7413,-27.8951,Cândido Godói,ADM2,DISTRICT
SM262963,-45.6051,-1.4317,Cândido Mendes,ADM2,DISTRICT
SM266449,-50.3923,-22.8025,Cândido Mota,ADM2,DISTRICT
SM266450,-48.6329,-21.3419,Cândido Rodrigues,ADM2,DISTRICT
SM267759,-41.1635,-15.4608,Cândido Sales,ADM2,DISTRICT
SM264805,-51.2575,-24.6706,Cândido de Abreu,ADM2,DISTRICT
SM286704,-77.5421,8.0829,Cémaco,ADM2,DISTRICT
SM264818,-53.7485,-25.2983,Céu Azul,ADM2,DISTRICT
SM264817,-49.2956,-24.8588,Cêrro Azul,ADM2,DISTRICT
SM267776,-38.4214,-10.5388,Cícero Dantas,ADM2,DISTRICT
SM270757,-73.3294,5.6876,Cómbita,ADM2,DISTRICT
SM303756,-72.2,7.5667,Córdoba,ADM2,DISTRICT
SM274034,-4.8333,38,Córdoba,ADM2,DISTRICT
SM282880,-96.9444,18.9204,Córdoba,ADM2,DISTRICT
SM270420,-75.6669,4.3806,Córdoba,ADM2,DISTRICT
SM270303,-77.3614,0.785,Córdoba,ADM2,DISTRICT
SM270716,-74.898,9.5515,Córdoba,ADM2,DISTRICT
SM1017,-75.6667,8.3333,Córdoba,ADM1,DISTRICT
SM1016,-64,-32,Córdoba,ADM1,DISTRICT
SM263357,-45.9615,-19.8135,Córrego Danta,ADM2,DISTRICT
SM263360,-45.5359,-20.4503,Córrego Fundo,ADM2,DISTRICT
SM263361,-42.4421,-19.8287,Córrego Novo,ADM2,DISTRICT
SM263359,-45.9968,-22.63,Córrego do Bom Jesus,ADM2,DISTRICT
SM268250,-50.5732,-16.3957,Córrego do Ouro,ADM2,DISTRICT
SM304424,107.807,21.0542,Cô Tô,ADM2,DISTRICT
SM263333,-44.6097,-14.9876,Cônego Marinho,ADM2,DISTRICT
SM274469,4.8333,47.5,Côte-d'Or,ADM2,DISTRICT
SM274477,-2.7822,48.343,Côtes-d'Armor,ADM2,DISTRICT
SM229074,102.759,22.3311,Côông,LANGUAGE,LANGUAGE
SM304451,106.1852,9.6243,Cù Lao Dung,ADM2,DISTRICT
SM813,27,44.25,Călărași,ADM1,DISTRICT
SM303990,107.7632,12.6959,Cư Jút,ADM2,DISTRICT
SM303983,108.1806,12.5866,Cư Kuin,ADM2,DISTRICT
SM303984,108.0756,12.8802,Cư M'gar,ADM2,DISTRICT
SM261886,47,39.3333,Cəbrayıl,ADM1:ADM2,DISTRICT
SM261934,48.5,39.25,Cəlilabad,ADM1:ADM2,DISTRICT
SM304293,106.6728,10.5774,Cần Giuộc,ADM2,DISTRICT
SM304115,106.8752,10.5278,Cần Giờ,ADM2,DISTRICT
SM798,105.5,10.1167,Cần Thơ (2003-),ADM1,DISTRICT
SM304292,106.5964,10.5415,Cần Đước,ADM2,DISTRICT
SM304157,105.7903,21.0274,Cầu Giấy,ADM2,DISTRICT
SM304544,106.0792,9.8674,Cầu Kè,ADM2,DISTRICT
SM304545,106.4365,9.7719,Cầu Ngang,ADM2,DISTRICT
SM304066,106.2167,20.95,Cẩm Giàng,ADM2,DISTRICT
SM304354,105.0985,21.4068,Cẩm Khê,ADM2,DISTRICT
SM304021,108.1995,16.0124,Cẩm Lệ,ADM2,DISTRICT
SM304001,107.2528,10.8089,Cẩm Mỹ,ADM2,DISTRICT
SM304423,107.3027,21.0827,Cẩm Phả,ADM2,DISTRICT
SM304526,105.4682,20.1959,Cẩm Thủy,ADM2,DISTRICT
SM304168,106.0019,18.1906,Cẩm Xuyên,ADM2,DISTRICT
SM304438,107.3393,17.1586,Cồn Cỏ,ADM2,DISTRICT
SM303945,105.4649,10.1215,Cờ Đỏ,ADM2,DISTRICT
SM304116,106.5056,10.9981,Củ Chi,ADM2,DISTRICT
SM304329,105.7261,18.7829,Cửa Lò,ADM2,DISTRICT
SM12275,-76.737,17.97,D'Aguilar Town/ Rennock Lodge,ADM2,DISTRICT
SM454469,150.82,-9.62,D'Entrecasteaux,ETHNICITY,ETHNICITY
SM14190,-6.1587,17.6529,D'har,ADM2,DISTRICT
SM459198,145.6613,-17.7402,DYIRBAL / JIRRBAL,ETHNICITY,ETHNICITY
SM229211,119.527,-1.2105,Da'a Kaili,LANGUAGE,LANGUAGE
SM229210,93.7853,20.7347,Daai Chin,LANGUAGE,LANGUAGE
SM242732,168.046,-16.3083,Daakie,LANGUAGE,LANGUAGE
SM229212,155.748,-6.4336,Daantanai',LANGUAGE,LANGUAGE
SM229213,35.9888,4.5888,Daasanach,LANGUAGE,LANGUAGE
SM229214,35.2226,11.5284,Daats'iin,LANGUAGE,LANGUAGE
SM241077,13.6318,10.3521,Daba,LANGUAGE,LANGUAGE
SM296839,16.7235,12.8128,Dababa,ADM2,DISTRICT
SM10280,17.1996,12.0384,Dababa (excl. Ngoura),ADM2,DISTRICT
SM290091,23.6946,46.9699,Dabaca,ADM2,DISTRICT
SM303585,-70.6777,11.0227,Dabajuro,ADM2,DISTRICT
SM17310,-61.0044,13.8049,Daban,ADM2,DISTRICT
SM229218,43.3304,1.2179,Dabarre,LANGUAGE,LANGUAGE
SM276157,19.33,47.19,Dabasi,ADM2,DISTRICT
SM229223,139.116,-2.0508,Dabe,LANGUAGE,LANGUAGE
SM270204,-76.3416,7.0337,Dabeiba,ADM2,DISTRICT
SM274979,-11.1167,10.6,Dabola,ADM1:ADM2,DISTRICT
SM290353,24.0865,43.7805,Dabuleni,ADM2,DISTRICT
SM289122,129,40.5833,Dachon,ADM2,DISTRICT
SM229229,23.3,46.2,Dacian,LANGUAGE,LANGUAGE
SM17311,-61.0357,13.7962,Dacretin,ADM2,DISTRICT
SM283577,111.6335,49.0091,Dadal,ADM2,DISTRICT
SM298606,33.458,41.4871,Daday,ADM2,DISTRICT
SM301544,-93.8503,37.432,Dade,ADM2,DISTRICT
SM300475,-85.5045,34.8545,Dade,ADM2,DISTRICT
SM375329,80.501,29.2092,Dadeldhura,ADM2:ADM3,DISTRICT
SM229232,144.511,-6.6577,Dadibi,LANGUAGE,LANGUAGE
SM229231,11.3587,9.6271,Dadiya,LANGUAGE,LANGUAGE
SM1053,73.0081,20.2065,Dadra and Nagar Haveli,ADM1:ADM2,DISTRICT
SM4172,72.8511,20.4259,Dadra and Nagar Haveli and Daman and Diu,ADM1,DISTRICT
SM375481,67.509,26.8733,Dadu,ADM2:ADM3,DISTRICT
SM229235,125.8637,-8.486,Dadua,DIALECT,LANGUAGE
SM289071,125.5219,38.7919,Daean,ADM2,DISTRICT
SM280676,127.4401,36.4059,Daedeok,ADM2,DISTRICT
SM1054,128.55,35.8,Daegu,ADM1,DISTRICT
SM1055,127.4167,36.3333,Daejeon,ADM1,DISTRICT
SM289205,125.2322,40.2033,Daengwan,ADM2,DISTRICT
SM292041,28.1322,44.8453,Daeni,ADM2,DISTRICT
SM292181,24.3934,45.1858,Daesti,ADM2,DISTRICT
SM11391,-2.3341,10.3916,Daffiama Bussie Issa District,ADM2,DISTRICT
SM239894,93.1284,27.3708,Dafla,LANGUAGE,LANGUAGE
SM229242,149.263,-9.9747,Daga,LANGUAGE,LANGUAGE
SM229239,-2.4857,11.6839,Dagaari Dioula,LANGUAGE,LANGUAGE
SM295250,-15.839,16.334,Dagana,ADM2,DISTRICT
SM296840,15.7325,12.9953,Dagana,ADM2,DISTRICT
SM1056,89.8667,27.1,Dagana,ADM1,DISTRICT
SM229244,112.0456,23.8877,Dagang-Baoshan-Liangcun,LANGUAGE,LANGUAGE
SM250702,-3,11,Dagari general,ETHNICITY,ETHNICITY
SM290817,27.1915,46.9447,Dagata,ADM2,DISTRICT
SM229247,18.4847,8.8428,Dagba,LANGUAGE,LANGUAGE
SM229245,-0.4323,9.6474,Dagbani,LANGUAGE,LANGUAGE
SM13476,27.5319,56.0971,Dagda,ADM2,DISTRICT
SM3978,27.6444,56.1203,Dagdas Novads,ADM1,DISTRICT
SM13477,27.5518,56.0994,Dagdas pagasts,ADM2,DISTRICT
SM1057,47,43,Dagestan,ADM1,DISTRICT
SM292820,48.1906,42.1164,Dagestanskie Og,ADM2,DISTRICT
SM302917,-109.5077,40.8873,Daggett,ADM2,DISTRICT
SM229251,29.9404,10.583,Dagik,LANGUAGE,LANGUAGE
SM229255,132.379,-15.764,Dagoman,LANGUAGE,LANGUAGE
SM7416,0.17,9.99,Dagomba,ETHNICITY,ETHNICITY
SM7416,0.17,9.99,Dagomba,ETHNICITY,ETHNICITY
SM7416,0,9,Dagomba,ETHNICITY,ETHNICITY
SM7416,0,9,Dagomba,ETHNICITY,ETHNICITY
SM270608,-76.7321,3.6164,Dagua,ADM2,DISTRICT
SM15660,-65.6825,18.2412,Daguao Barrio (Ceiba),ADM2,DISTRICT
SM15661,-65.6742,18.2045,Daguao Barrio (Naguabo),ADM2,DISTRICT
SM15662,-67.1278,18.3054,Dagueey Barrio,ADM2,DISTRICT
SM229419,124,48,Dagur,LANGUAGE,LANGUAGE
SM229259,40.0819,15.7329,Dahalik,LANGUAGE,LANGUAGE
SM229258,40.37,-2.1843,Dahalo,LANGUAGE,LANGUAGE
SM260197,68.4584,35.823,Dahana-I-Ghori,ADM2,DISTRICT
SM273996,40.2,15.8333,Dahlak,ADM2,DISTRICT
SM253002,45.94,17.18,Dahm,ETHNICITY,ETHNICITY
SM297868,8.7991,35.9675,Dahmani,ADM2,DISTRICT
SM229260,-7.3558,5.7246,Daho-Doo,LANGUAGE,LANGUAGE
SM276812,74,22.9,Dahod,ADM2,DISTRICT
SM305775,43,36.95,Dahuk,ADM2,DISTRICT
SM229265,129.687,-7.5589,Dai,LANGUAGE,LANGUAGE
SM253003,135.12,-13.63,Dai,ETHNICITY,ETHNICITY
SM253003,135.12,-13.63,Dai,ETHNICITY,ETHNICITY
SM253003,135.762,-13.3736,Dai,ETHNICITY,ETHNICITY
SM253003,135.762,-13.3736,Dai,ETHNICITY,ETHNICITY
SM253004,100,21,Dai,ETHNICITY,ETHNICITY
SM253004,100,21,Dai,ETHNICITY,ETHNICITY
SM253004,100,21,Dai,ETHNICITY,ETHNICITY
SM253004,100,21,Dai,ETHNICITY,ETHNICITY
SM229267,104.4,23.4,Dai Zhuang,LANGUAGE,LANGUAGE
SM290563,25.9923,43.9994,Daia,ADM2,DISTRICT
SM289792,23.6682,46.0071,Daia Romana,ADM2,DISTRICT
SM278400,140.3456,36.798,Daigo,ADM2,DISTRICT
SM375339,81.7034,28.8711,Dailekh,ADM2:ADM3,DISTRICT
SM253006,150.48,-10.58,Daiomoni,ETHNICITY,ETHNICITY
SM229266,30.6782,12.474,Dair,LANGUAGE,LANGUAGE
SM277994,35.597,32.1073,Dair Alla,ADM2,DISTRICT
SM260752,-61.7431,-36.5981,Daireaux,ADM2,DISTRICT
SM276696,98.2333,2.8667,Dairi,ADM2,DISTRICT
SM278570,140.4414,39.5371,Daisen,ADM2,DISTRICT
SM279101,135.6358,34.7128,Daitō,ADM2,DISTRICT
SM271891,-71.5833,19.5833,Dajabón,ADM2,DISTRICT
SM1059,-71.5833,19.4333,Dajabón,ADM1,DISTRICT
SM15663,-66.1909,18.3048,Dajaos Barrio,ADM2,DISTRICT
SM1048,108.25,12.75,Dak Lak (2003-),ADM1,DISTRICT
SM229272,168.013,-16.2696,Dakaka,LANGUAGE,LANGUAGE
SM295246,-17.4553,14.714,Dakar,ADM2,DISTRICT
SM1060,-17.2833,14.7667,Dakar,ADM1,DISTRICT
SM250166,5,12,Dakarkari,ETHNICITY,ETHNICITY
SM280825,107.3185,15.382,Dakcheung,ADM2,DISTRICT
SM454633,-125.41,52.49,Dakelh,ETHNICITY,ETHNICITY
SM454633,-125.41,52.49,Dakelh,ETHNICITY,ETHNICITY
SM454633,-125.41,52.49,Dakelh,ETHNICITY,ETHNICITY
SM454633,-124,54,Dakelh,ETHNICITY,ETHNICITY
SM454633,-124,54,Dakelh,ETHNICITY,ETHNICITY
SM454633,-124,54,Dakelh,ETHNICITY,ETHNICITY
SM454633,-123.15,53.33,Dakelh,ETHNICITY,ETHNICITY
SM454633,-123.15,53.33,Dakelh,ETHNICITY,ETHNICITY
SM454633,-123.15,53.33,Dakelh,ETHNICITY,ETHNICITY
SM292821,47.5833,42.0833,Dakhadayevskiy rayon,ADM2,DISTRICT
SM1061,-16.1667,20.7167,Dakhlet Nouadhibou,ADM1,DISTRICT
SM229275,119.235,-3.3367,Dakka,LANGUAGE,LANGUAGE
SM229279,-102.12,47.6857,Dakota,LANGUAGE,LANGUAGE
SM301692,-96.5645,42.3911,Dakota,ADM2,DISTRICT
SM301348,-93.0654,44.6719,Dakota,ADM2,DISTRICT
SM253012,-103.07,41.06,Dakota,ETHNICITY,ETHNICITY
SM229282,91.8245,27.3762,Dakpakha,LANGUAGE,LANGUAGE
SM277371,88.76,25.22,Dakshin Dinajpur,ADM2,DISTRICT
SM276926,75.29,12.84,Dakshina Kannada (1997-),ADM2,DISTRICT
SM253013,21.33,6.93,Dakwa,ETHNICITY,ETHNICITY
SM293756,44.0833,55.8333,Dal'nekonstantinovskiy rayon,ADM2,DISTRICT
SM284894,8.5072,12.014,Dala,ADM2,DISTRICT
SM260341,20.1997,-11.0261,Dala,ADM2,DISTRICT
SM274998,-12.3,10.75,Dalaba,ADM1:ADM2,DISTRICT
SM253015,133.63,-14.01,Dalabon,ETHNICITY,ETHNICITY
SM253015,133.63,-14.01,Dalabon,ETHNICITY,ETHNICITY
SM253015,134.0293,-13.3163,Dalabon,ETHNICITY,ETHNICITY
SM253015,134.0293,-13.3163,Dalabon,ETHNICITY,ETHNICITY
SM11603,-21.7287,65.1012,Dalabyggd (2006-),ADM2,DISTRICT
SM298764,28.8782,36.8169,Dalaman,ADM2,DISTRICT
SM283804,109.0666,45.9108,Dalanjargalan,ADM2,DISTRICT
SM283629,104.4247,43.5645,Dalanzadgad,ADM2,DISTRICT
SM1063,14.5,61,Dalarna,ADM1,DISTRICT
SM290009,21.935,44.8443,Dalboset,ADM2,DISTRICT
SM300261,-85.611,31.4318,Dale,ADM2,DISTRICT
SM253017,142.17,-19.73,Dalebura,ETHNICITY,ETHNICITY
SM253017,142.17,-19.73,Dalebura,ETHNICITY,ETHNICITY
SM253017,144.2829,-21.1571,Dalebura,ETHNICITY,ETHNICITY
SM253017,144.2829,-21.1571,Dalebura,ETHNICITY,ETHNICITY
SM229288,13.8304,61.2914,Dalecarlian,LANGUAGE,LANGUAGE
SM277558,47.7833,34.05,Dalfan,ADM2,DISTRICT
SM285578,6.2721,52.5246,Dalfsen,ADM2,DISTRICT
SM262461,27.35,43.05,Dalgopol,ADM2,DISTRICT
SM370486,6.2597,49.5408,Dalheim,ADM1:ADM2:ADM3,DISTRICT
SM10670,33.4129,35.0453,Dali,ADM2,DISTRICT
SM269375,100.131,25.6855,Dali Bai,ADM2,DISTRICT
SM269249,122.2558,39.7403,Dalian,ADM2,DISTRICT
SM14917,138.0854,9.5223,Dalipebinaw Municipality,ADM2,DISTRICT
SM458205,152.6684,-26.7754,Dalla,ETHNICITY,ETHNICITY
SM302845,-102.6022,36.2779,Dallam,ADM2,DISTRICT
SM302846,-96.7779,32.7666,Dallas,ADM2,DISTRICT
SM301546,-93.0237,37.6804,Dallas,ADM2,DISTRICT
SM300801,-94.0397,41.6849,Dallas,ADM2,DISTRICT
SM302364,-92.6544,33.9698,Dallas,ADM2,DISTRICT
SM300262,-87.1065,32.326,Dallas,ADM2,DISTRICT
SM12276,-76.7126,17.9846,Dallas,ADM2,DISTRICT
SM229294,18.0238,42.7095,Dalmatian,LANGUAGE,LANGUAGE
SM293489,63,56.25,Dalmatovskiy rayon,ADM2,DISTRICT
SM229296,-6.6394,6.6928,Daloa Bété,LANGUAGE,LANGUAGE
SM296615,11.924,58.9125,Dals-Ed,ADM2,DISTRICT
SM280669,128.524,35.8257,Dalseo,ADM2,DISTRICT
SM280670,128.4296,35.7747,Dalseong,ADM2,DISTRICT
SM12277,-76.25,17.9024,Dalvey,ADM2,DISTRICT
SM277776,-18.6084,65.8732,Dalvíkurbyggð,ADM2,DISTRICT
SM261366,116.984,-30.13,Dalwallinu,ADM2,DISTRICT
SM229300,14.0597,8.7221,Dama (Cameroon),LANGUAGE,LANGUAGE
SM17777,42.8167,17.1333,Damad,ADM2,DISTRICT
SM15222,9.4817,14.0944,Damagaram Takaya,ADM2,DISTRICT
SM229301,5.4914,11.0808,Damakawa,LANGUAGE,LANGUAGE
SM229305,137.496,-4.1735,Damal,LANGUAGE,LANGUAGE
SM299046,42.8458,41.3501,Damal,ADM2,DISTRICT
SM277470,72.8833,20.4167,Daman,ADM2,DISTRICT
SM260054,65.7498,31.2207,Daman,ADM2,DISTRICT
SM229307,72.8415,20.3968,Daman-Diu Portuguese,LANGUAGE,LANGUAGE
SM273903,30.4568,31.0013,Damanhur,ADM2,DISTRICT
SM253028,58.74,29.7,Damani,ETHNICITY,ETHNICITY
SM10234,18.6902,5.0996,Damara,ADM2,DISTRICT
SM250175,15.12,-21.6,Damara,ETHNICITY,ETHNICITY
SM250175,15.12,-21.6,Damara,ETHNICITY,ETHNICITY
SM250175,15.12,-21.6,Damara,ETHNICITY,ETHNICITY
SM250175,17.83,-19.4,Damara,ETHNICITY,ETHNICITY
SM250175,17.83,-19.4,Damara,ETHNICITY,ETHNICITY
SM250175,17.83,-19.4,Damara,ETHNICITY,ETHNICITY
SM250175,16,-22,Damara,ETHNICITY,ETHNICITY
SM250175,16,-22,Damara,ETHNICITY,ETHNICITY
SM250175,16,-22,Damara,ETHNICITY,ETHNICITY
SM250175,16.2257,-19.8302,Damara,ETHNICITY,ETHNICITY
SM250175,16.2257,-19.8302,Damara,ETHNICITY,ETHNICITY
SM250175,16.2257,-19.8302,Damara,ETHNICITY,ETHNICITY
SM296771,36.2803,33.5156,Damascus,ADM2,DISTRICT
SM1065,36.2833,33.5167,Damascus,ADM1,DISTRICT
SM285268,11.9786,11.7803,Damaturu,ADM2,DISTRICT
SM277628,52.1375,35.6301,Damavand,ADM2,DISTRICT
SM260384,15.2567,-6.7415,Damba,ADM2,DISTRICT
SM280459,105.8998,12.0368,Dambae,ADM2,DISTRICT
SM285324,10.7862,11.6259,Damban,ADM2,DISTRICT
SM284895,8.6343,12.4201,Dambatta,ADM2,DISTRICT
SM229311,146.474,-7.0059,Dambi,LANGUAGE,LANGUAGE
SM285386,12.7642,11.0983,Damboa,ADM2,DISTRICT
SM291241,25.2274,45.4345,Dambovicioara,ADM2,DISTRICT
SM17312,-60.9875,13.9183,Dame De Traversay,ADM2,DISTRICT
SM229315,71.6806,35.299,Dameli,LANGUAGE,LANGUAGE
SM253029,7.8,17.32,Damergu,ETHNICITY,ETHNICITY
SM277614,54.3333,35.5,Damghan,ADM2,DISTRICT
SM15664,-66.4671,18.2391,Damian Abajo Barrio,ADM2,DISTRICT
SM15665,-66.434,18.2424,Damian Arriba Barrio,ADM2,DISTRICT
SM268259,-46.1937,-14.5495,Damianópolis,ADM2,DISTRICT
SM292099,27.0065,46.7431,Damienesti,ADM2,DISTRICT
SM264413,-35.9221,-6.6784,Damião,ADM2,DISTRICT
SM297450,99.9704,13.5654,Damnoen Saduak,ADM2,DISTRICT
SM276972,79.5833,23.75,Damoh,ADM2,DISTRICT
SM9918,-49.3451,-16.2511,Damolandia,ADM2:ADM3,DISTRICT
SM229320,120.219,0.7023,Dampelas,LANGUAGE,LANGUAGE
SM305026,44.6788,14.1021,Damt,ADM2,DISTRICT
SM229321,95.5,29.483,Damu,LANGUAGE,LANGUAGE
SM291326,25.8922,46.7562,Damuc,ADM2,DISTRICT
SM280599,126.9913,35.3398,Damyang,ADM2,DISTRICT
SM229348,-8.2454,7.2281,Dan,LANGUAGE,LANGUAGE
SM250177,-7.71,7.35,Dan,ETHNICITY,ETHNICITY
SM250177,-7.71,7.35,Dan,ETHNICITY,ETHNICITY
SM250177,-6.43,8.38,Dan,ETHNICITY,ETHNICITY
SM250177,-6.43,8.38,Dan,ETHNICITY,ETHNICITY
SM250177,-8,8,Dan,ETHNICITY,ETHNICITY
SM250177,-8,8,Dan,ETHNICITY,ETHNICITY
SM297610,99.5164,14.8839,Dan Chang,ADM2,DISTRICT
SM297141,101.6979,15.2184,Dan Khun Thot,ADM2,DISTRICT
SM296967,99.3413,13.8342,Dan Makham Tia,ADM2,DISTRICT
SM297043,101.2237,17.221,Dan Sai,ADM2,DISTRICT
SM227822,33.7109,9.267,Dana,LANGUAGE,LANGUAGE
SM229325,145.457,-5.5363,Danaru,LANGUAGE,LANGUAGE
SM229323,96.998,20.4957,Danau,LANGUAGE,LANGUAGE
SM290625,23.7602,44.77,Danciulesti,ADM2,DISTRICT
SM229327,112.7199,22.275,Dancun,DIALECT,LANGUAGE
SM260166,69.9108,33.7917,Dand Wa Patan,ADM2,DISTRICT
SM253033,33.95,-20.07,Danda,ETHNICITY,ETHNICITY
SM229328,81.3598,18.7475,Dandami Maria,LANGUAGE,LANGUAGE
SM261367,115.4613,-30.5414,Dandaragan,ADM2,DISTRICT
SM260305,13.8333,-8.5,Dande,ADM2,DISTRICT
SM296481,18.0485,59.4092,Danderyd,ADM2,DISTRICT
SM284950,3.8086,11.8442,Dandi,ADM2,DISTRICT
SM269250,124.3867,40.5936,Dandong,ADM2,DISTRICT
SM284928,7.1904,11.4128,Dandume,ADM2,DISTRICT
SM303225,-89.4183,43.0674,Dane,ADM2,DISTRICT
SM291405,24.566,44.1139,Daneasa,ADM2,DISTRICT
SM291138,24.7023,46.1908,Danes,ADM2,DISTRICT
SM292267,27.6591,46.8397,Danesti,ADM2,DISTRICT
SM290626,23.3439,44.9674,Danesti,ADM2,DISTRICT
SM290354,24.0402,43.9775,Daneti,ADM2,DISTRICT
SM6005,82.4782,27.9677,Dang,ADM2:ADM3,DISTRICT
SM280504,104.4223,10.7073,Dang Tong,ADM2,DISTRICT
SM18387,70.8922,40.6001,Dangara Tumani,ADM2,DISTRICT
SM229336,18.385,12.1517,Dangaleat,LANGUAGE,LANGUAGE
SM260086,71.4372,35.0021,Dangam,ADM2,DISTRICT
SM253036,139.42,-36.35,Dangani,ETHNICITY,ETHNICITY
SM253036,139.42,-36.35,Dangani,ETHNICITY,ETHNICITY
SM253036,139.5691,-36.0894,Dangani,ETHNICITY,ETHNICITY
SM253036,139.5691,-36.0894,Dangani,ETHNICITY,ETHNICITY
SM229332,81.7761,27.8834,Dangaura Tharu,LANGUAGE,LANGUAGE
SM262115,2.4993,6.5804,Dangbo,ADM2,DISTRICT
SM268419,90.2107,27.553,Dangchhu,ADM2,DISTRICT
SM285235,5.4178,12.8463,Dange-Shuni,ADM2,DISTRICT
SM292556,26.9482,47.8476,Dangeni,ADM2,DISTRICT
SM305966,69.27,38.0851,Danghara,ADM2,DISTRICT
SM280664,126.6667,36.9167,Dangjin,ADM2,DISTRICT
SM9823,-88.2439,16.9642,Dangriga,ADM2,DISTRICT
SM253041,138.27,-3.37,Dani,ETHNICITY,ETHNICITY
SM253041,138.27,-3.37,Dani,ETHNICITY,ETHNICITY
SM253041,139,-4,Dani,ETHNICITY,ETHNICITY
SM253041,139,-4,Dani,ETHNICITY,ETHNICITY
SM286810,-76.5,-10.5,Daniel Alcides Carrión,ADM2,DISTRICT
SM262760,-68.4636,-20.2463,Daniel Campos,ADM2,DISTRICT
SM12278,-77.6159,18.464,Daniel Town,ADM2,DISTRICT
SM301623,-105.5486,48.7838,Daniels,ADM2,DISTRICT
SM1067,19.0911,42.5586,Danilovgrad,ADM1,DISTRICT
SM295065,40.0833,58.1667,Danilovskiy rayon,ADM2,DISTRICT
SM294933,44.25,50.4167,Danilovskiy rayon,ADM2,DISTRICT
SM229341,9.3628,54.8655,Danish,LANGUAGE,LANGUAGE
SM253042,9.54,56.36,Danish,ETHNICITY,ETHNICITY
SM253042,9.54,56.36,Danish,ETHNICITY,ETHNICITY
SM253042,11.07,54.91,Danish,ETHNICITY,ETHNICITY
SM253042,11.07,54.91,Danish,ETHNICITY,ETHNICITY
SM229340,12.3187,55.8257,Danish Sign Language,LANGUAGE,LANGUAGE
SM284929,7.5488,11.3954,Danja,ADM2,DISTRICT
SM284951,5.6749,11.4294,Danko Wasagu,ADM2,DISTRICT
SM16883,39.1667,53.25,Dankovskiy Rayon,ADM2,DISTRICT
SM275906,-86.37,14.09,Danlí,ADM2,DISTRICT
SM284930,7.3296,12.2322,Danmusa,ADM2,DISTRICT
SM229349,145.254,-5.9219,Dano,LANGUAGE,LANGUAGE
SM229350,9.2833,10.05,Danshe,DIALECT,LANGUAGE
SM277467,81.3796,18.5,Dantewada (2012-),ADM2,DISTRICT
SM285753,5.9955,53.2911,Dantumadeel,ADM2,DISTRICT
SM12279,-76.4759,17.9288,Danvers Pen,ADM2,DISTRICT
SM302998,-79.395,36.586,Danville,ADM2,DISTRICT
SM280648,128.3695,36.9862,Danyang,ADM2,DISTRICT
SM10357,109.4044,19.5689,Danzhou Shi,ADM2,DISTRICT
SM229364,136.421,-3.2864,Dao,LANGUAGE,LANGUAGE
SM229359,101.0072,30.0325,Daohua,LANGUAGE,LANGUAGE
SM229363,141.22,-3.3144,Daonda,LANGUAGE,LANGUAGE
SM269147,124.546,46.2918,Daqing,ADM2,DISTRICT
SM305851,44.3237,35.0845,Daquq,ADM2,DISTRICT
SM297932,10.7047,36.5246,Dar Chaabane El Fehri,ADM2,DISTRICT
SM229376,18.7996,12.1187,Dar Daju Daju,LANGUAGE,LANGUAGE
SM457206,3.1667,36.6667,Dar El Beïda District,ADM2,DISTRICT
SM229381,25.3127,12.2598,Dar Fur Daju,LANGUAGE,LANGUAGE
SM8192,27.55,11.46,Dar Hamar,ETHNICITY,ETHNICITY
SM8192,27.55,11.46,Dar Hamar,ETHNICITY,ETHNICITY
SM8192,28,11,Dar Hamar,ETHNICITY,ETHNICITY
SM8192,28,11,Dar Hamar,ETHNICITY,ETHNICITY
SM14191,-15.9257,18.1162,Dar Naim,ADM2,DISTRICT
SM304769,44.9784,12.907,Dar Sad,ADM2,DISTRICT
SM229397,22.2013,11.9861,Dar Sila Daju,LANGUAGE,LANGUAGE
SM296827,21.9841,14.4503,Dar Tama,ADM2,DISTRICT
SM1068,39.196,-6.8352,Dar es Salaam,ADM1,DISTRICT
SM10281,21.2815,14.3296,Dar-Alfawakih,ADM2,DISTRICT
SM1069,36.2167,32.85,Dara,ADM1,DISTRICT
SM260129,70.5859,34.7065,Dara-I-Nur,ADM2,DISTRICT
SM260087,70.9164,34.9531,Dara-i-Pech,ADM2,DISTRICT
SM296773,36.2035,32.6314,Daraa,ADM2,DISTRICT
SM253046,57.36,27.77,Darab Khani,ETHNICITY,ETHNICITY
SM292557,26.6152,48.1743,Darabani,ADM2,DISTRICT
SM9512,69.77,35.2356,Darah,ADM2,DISTRICT
SM9513,67.2825,35.6807,Darah-ye Suf-e Bala,ADM2,DISTRICT
SM9514,67.3472,36.025,Darah-ye Suf-e Pa'in,ADM2,DISTRICT
SM229371,84.1357,27.8623,Darai,LANGUAGE,LANGUAGE
SM290953,26.0214,44.3091,Darasti-ilfov,ADM2,DISTRICT
SM253048,-71.74,-6.78,Darawa,ETHNICITY,ETHNICITY
SM9515,70.4665,36.8721,Darayim,ADM2,DISTRICT
SM296746,36.1912,33.4991,Darayya,ADM2,DISTRICT
SM253049,55.97,31.32,Darazi,ETHNICITY,ETHNICITY
SM285325,10.654,11.1676,Darazo,ADM2,DISTRICT
SM277440,86,26,Darbhanga,ADM2,DISTRICT
SM375330,80.7892,29.9259,Darchula,ADM2:ADM3,DISTRICT
SM267228,-47.7569,-6.7607,Darcinópolis,ADM2,DISTRICT
SM305399,18.6909,45.6249,Darda,ADM2,DISTRICT
SM261368,115.8741,-33.4161,Dardanup,ADM2,DISTRICT
SM301951,-75.7809,35.7866,Dare,ADM2,DISTRICT
SM261225,145.0219,-37.7515,Darebin,ADM2,DISTRICT
SM298719,37.5138,38.5559,Darende,ADM2,DISTRICT
SM277607,59.0833,37.3333,Dargaz,ADM2,DISTRICT
SM298740,41.7242,37.5464,Dargeçit,ADM2,DISTRICT
SM253051,47.22,42.18,Dargin,ETHNICITY,ETHNICITY
SM15002,109.417,46.6056,Darhan (excl. Bor-Ondor),ADM2,DISTRICT
SM15002,109.417,46.6056,Darhan (excl. Bor-Ondor),ADM2,DISTRICT
SM15002,109.5009,46.5917,Darhan (excl. Bor-Ondor),ADM2,DISTRICT
SM15002,109.5009,46.5917,Darhan (excl. Bor-Ondor),ADM2,DISTRICT
SM1070,106.1187,49.2806,Darhan-Uul,ADM1,DISTRICT
SM229384,67.3593,31.0405,Dari,LANGUAGE,LANGUAGE
SM18037,29.3713,40.7678,Darica,ADM2,DISTRICT
SM283678,113.8493,45.3008,Dariganga,ADM2,DISTRICT
SM283678,113.8493,45.3008,Dariganga,ADM2,DISTRICT
SM283678,114.1111,45.4448,Dariganga,ADM2,DISTRICT
SM283678,114.1111,45.4448,Dariganga,ADM2,DISTRICT
SM1071,-77.8833,8.0833,Darién (1983-),ADM1,DISTRICT
SM11642,88.25,26.75,Darjiling (2017-),ADM2,DISTRICT
SM290685,25.1746,46.2038,Darjiu,ADM2,DISTRICT
SM302093,-84.6193,40.1332,Darke,ADM2,DISTRICT
SM17864,45.2647,2.4802,Darkenley,ADM2,DISTRICT
SM229388,99.4449,50.623,Darkhat,DIALECT,LANGUAGE
SM459082,150.8287,-33.0351,Darkinyung,ETHNICITY,ETHNICITY
SM17313,-60.9878,14.0136,Darling Road,ADM2,DISTRICT
SM302439,-79.9577,34.3324,Darlington,ADM2,DISTRICT
SM274618,-1.5333,54.5333,Darlington,ADM1:ADM2,DISTRICT
SM12280,-77.9822,18.2416,Darliston,ADM2,DISTRICT
SM229389,91.3339,23.992,Darlong,LANGUAGE,LANGUAGE
SM291720,24.3974,46.2079,Darlos,ADM2,DISTRICT
SM229393,80.5439,29.8629,Darma,LANGUAGE,LANGUAGE
SM290259,25.7615,44.9248,Darmanesti,ADM2,DISTRICT
SM291242,24.9022,45.0066,Darmanesti,ADM2,DISTRICT
SM292100,26.4767,46.3756,Darmanesti,ADM2,DISTRICT
SM1072,22.6667,31.8333,Darnah,ADM1,DISTRICT
SM299468,30.6931,50.4133,Darnyts'kyi,ADM2,DISTRICT
SM229394,111.536,2.5444,Daro-Matu Melanau,LANGUAGE,LANGUAGE
SM291970,21.7671,45.6308,Darova,ADM2,DISTRICT
SM293299,47.75,58.8333,Darovskiy rayon,ADM2,DISTRICT
SM260214,69.499,37.4538,Darqad,ADM2,DISTRICT
SM277399,92.1684,26.512,Darrang (2004-),ADM2,DISTRICT
SM11762,47.3667,33.1333,Darrehshahr,ADM2,DISTRICT
SM253059,20.13,31.81,Dars,ETHNICITY,ETHNICITY
SM253060,54.9,22.85,Daru,ETHNICITY,ETHNICITY
SM256315,150.6,-23.38,Darumbal,ETHNICITY,ETHNICITY
SM256315,150.6,-23.38,Darumbal,ETHNICITY,ETHNICITY
SM256315,151.04,-22.2,Darumbal,ETHNICITY,ETHNICITY
SM256315,151.04,-22.2,Darumbal,ETHNICITY,ETHNICITY
SM256315,150.06,-22.7157,Darumbal,ETHNICITY,ETHNICITY
SM256315,150.06,-22.7157,Darumbal,ETHNICITY,ETHNICITY
SM253061,57.68,26.82,Darusar,ETHNICITY,ETHNICITY
SM275950,17.2333,45.6,Daruvar,ADM2,DISTRICT
SM291062,23.0665,44.1818,Darvari,ADM2,DISTRICT
SM283555,94.1144,46.4395,Darvi,ADM2,DISTRICT
SM283591,93.6193,46.9299,Darvi,ADM2,DISTRICT
SM17929,71,38.5833,Darvozskiy Rayon,ADM2,DISTRICT
SM250729,-5.71,31.28,Darwa,ETHNICITY,ETHNICITY
SM250729,-5.71,31.28,Darwa,ETHNICITY,ETHNICITY
SM250729,-7,31,Darwa,ETHNICITY,ETHNICITY
SM250729,-7,31,Darwa,ETHNICITY,ETHNICITY
SM9516,71.225,38.1282,Darwaz-e Bala,ADM2,DISTRICT
SM9517,70.9114,38.2278,Darwaz-e Pa'in,ADM2,DISTRICT
SM229401,70.7643,38.3161,Darwazi,DIALECT,LANGUAGE
SM261605,130.8768,-12.4019,Darwin,ADM2,DISTRICT
SM260026,65.3728,36.0044,Darzab,ADM2,DISTRICT
SM290954,26.242,44.6001,Dascalu,ADM2,DISTRICT
SM250171,36.57,4.03,Dasenach,ETHNICITY,ETHNICITY
SM250171,36.57,4.03,Dasenach,ETHNICITY,ETHNICITY
SM250171,36,5,Dasenach,ETHNICITY,ETHNICITY
SM250171,36,5,Dasenach,ETHNICITY,ETHNICITY
SM283794,114.4029,49.5384,Dashbalbar,ADM2,DISTRICT
SM283774,104.0498,47.8371,Dashinchilen,ADM2,DISTRICT
SM283774,104.0498,47.8371,Dashinchilen,ADM2,DISTRICT
SM283774,104.0835,47.8638,Dashinchilen,ADM2,DISTRICT
SM283774,104.0835,47.8638,Dashinchilen,ADM2,DISTRICT
SM9518,69.5016,37.2023,Dasht-e Qal'ah,ADM2,DISTRICT
SM277652,51.25,29.2833,Dashtestan,ADM2,DISTRICT
SM277653,51.5618,28.4869,Dashti,ADM2,DISTRICT
SM260093,69.1573,36.9636,Dashti Archi,ADM2,DISTRICT
SM229406,9.5184,9.9325,Dass,LANGUAGE,LANGUAGE
SM285326,9.478,10.0185,Dass,ADM2,DISTRICT
SM262158,2.278,7.696,Dassa-Zoumè,ADM2,DISTRICT
SM263375,-43.6522,-18.4807,Datas,ADM2,DISTRICT
SM278239,140.8914,42.4934,Date,ADM2,DISTRICT
SM279782,140.5167,37.8167,Date,ADM2,DISTRICT
SM12885,140.5254,37.8751,Date-gun,ADM2,DISTRICT
SM15388,-76.9779,-4.1361,Datem del Maranon,ADM2,DISTRICT
SM276973,78.5,25.75,Datia (1996-),ADM2,DISTRICT
SM269312,113.6333,40.1048,Datong,ADM2,DISTRICT
SM298765,27.663,36.7689,Datça,ADM2,DISTRICT
SM13478,25.173,56.4806,Daudzeses pagasts,ADM2,DISTRICT
SM13479,26.5419,55.8906,Daugavpils,ADM2,DISTRICT
SM3975,26.5389,55.8814,Daugavpils,ADM1,DISTRICT
SM3976,26.5548,55.9467,Daugavpils municipality,ADM1,DISTRICT
SM13480,24.4443,56.8037,Daugmales pagasts,ADM2,DISTRICT
SM13481,26.7308,57.066,Daukstu pagasts,ADM2,DISTRICT
SM273452,-79.9667,-1.85,Daule,ADM2,DISTRICT
SM288632,145.2333,-5.9833,Daulo,ADM2,DISTRICT
SM229415,145.4207,-36.9669,Daungwurrung,DIALECT,LANGUAGE
SM302299,-76.7795,40.4154,Dauphin,ADM2,DISTRICT
SM17314,-60.9019,14.0565,Dauphin,ADM2,DISTRICT
SM10175,-100.0633,51.1958,Dauphin,ADM2,DISTRICT
SM253073,125,49,Daur,ETHNICITY,ETHNICITY
SM253073,125,49,Daur,ETHNICITY,ETHNICITY
SM253073,125,49,Daur,ETHNICITY,ETHNICITY
SM253073,124,48.4975,Daur,ETHNICITY,ETHNICITY
SM253073,124,48.4975,Daur,ETHNICITY,ETHNICITY
SM253073,124,48.4975,Daur,ETHNICITY,ETHNICITY
SM253073,124,48.5,Daur,ETHNICITY,ETHNICITY
SM253073,124,48.5,Daur,ETHNICITY,ETHNICITY
SM253073,124,48.5,Daur,ETHNICITY,ETHNICITY
SM284898,8.2516,12.982,Daura,ADM2,DISTRICT
SM284494,14.1394,-21.0179,Daures,ADM2,DISTRICT
SM277166,76.5705,26.8827,Dausa,ADM2,DISTRICT
SM276927,75.9,14.43,Davanagere,ADM2,DISTRICT
SM4187,125.925,7.5473,Davao,ADM1,DISTRICT
SM4886,125.67,6.08,Davao Occidental,ADM1:ADM2,DISTRICT
SM1075,126.3333,7.1667,Davao Oriental,ADM1:ADM2,DISTRICT
SM1073,125.8154,7.451,Davao del Norte (1998-),ADM1:ADM2,DISTRICT
SM4885,125.3018,6.7928,Davao del Sur (2013-),ADM1:ADM2,DISTRICT
SM11763,56.8333,36.3333,Davarzan,ADM2,DISTRICT
SM229422,126.458,7.2261,Davawenyo,LANGUAGE,LANGUAGE
SM286686,-82.4,8.4,David,ADM2,DISTRICT
SM265626,-51.8168,-28.4178,David Canabarro,ADM2,DISTRICT
SM291243,25.036,45.0069,Davidesti,ADM2,DISTRICT
SM302554,-86.7775,36.1707,Davidson,ADM2,DISTRICT
SM301952,-80.2127,35.7933,Davidson,ADM2,DISTRICT
SM301954,-80.5445,35.9292,Davie,ADM2,DISTRICT
SM301547,-93.9855,39.9608,Daviess,ADM2,DISTRICT
SM300696,-87.0721,38.7024,Daviess,ADM2,DISTRICT
SM301032,-87.0872,37.7318,Daviess,ADM2,DISTRICT
SM13482,24.3551,56.5012,Davinu pagasts,ADM2,DISTRICT
SM268260,-47.5775,-18.136,Davinópolis,ADM2,DISTRICT
SM262983,-47.3064,-5.5701,Davinópolis,ADM2,DISTRICT
SM302918,-112.1112,40.9906,Davis,ADM2,DISTRICT
SM300802,-92.4097,40.7477,Davis,ADM2,DISTRICT
SM253075,-63.24,57.21,Davis Inlet,ETHNICITY,ETHNICITY
SM302486,-98.146,43.6747,Davison,ADM2,DISTRICT
SM16884,55,54.25,Davlekanovskiy Rayon,ADM2,DISTRICT
SM10671,33.9134,35.4161,Davlos,ADM2,DISTRICT
SM305582,17.512,45.1129,Davor,ADM2,DISTRICT
SM283737,92.4029,50.6063,Davst,ADM2,DISTRICT
SM304807,48.3072,15.0507,Daw'an,ADM2,DISTRICT
SM284896,8.6916,11.8323,DawakinK,ADM2,DISTRICT
SM284854,8.3986,12.1261,DawakinT,ADM2,DISTRICT
SM229426,149.999,-10.1692,Dawawa,LANGUAGE,LANGUAGE
SM283489,98.4746,14.0911,Dawei,ADM2,DISTRICT
SM229428,130.06,-7.7632,Dawera-Daweloor,LANGUAGE,LANGUAGE
SM301693,-103.1354,42.7197,Dawes,ADM2,DISTRICT
SM12281,-77.333,18.0554,Dawkins,ADM2,DISTRICT
SM260107,69.9995,35.0359,Dawlat Shah,ADM2,DISTRICT
SM260283,65.0558,36.5363,Dawlat abad,ADM2,DISTRICT
SM260252,66.798,37.0643,Dawlat abad,ADM2,DISTRICT
SM17778,39.5,29.4167,Dawmat al Jandal,ADM2,DISTRICT
SM304797,44.1143,14.8288,Dawran Aness,ADM2,DISTRICT
SM229431,37,6.95,Dawro,LANGUAGE,LANGUAGE
SM274326,37.1004,6.957,Dawro,ADM2,DISTRICT
SM274326,37.1004,6.957,Dawro,ADM2,DISTRICT
SM274326,37.1086,6.9699,Dawro,ADM2,DISTRICT
SM274326,37.1086,6.9699,Dawro,ADM2,DISTRICT
SM302847,-101.9477,32.7425,Dawson,ADM2,DISTRICT
SM301694,-99.8196,40.8699,Dawson,ADM2,DISTRICT
SM301624,-104.8995,47.2664,Dawson,ADM2,DISTRICT
SM300476,-84.1706,34.4443,Dawson,ADM2,DISTRICT
SM269152,124.9612,52.3653,Daxing'anling,ADM2,DISTRICT
SM229441,17.5146,8.5261,Day,LANGUAGE,LANGUAGE
SM302487,-97.6074,45.3672,Day,ADM2,DISTRICT
SM253076,110.72,1.28,Dayak,ETHNICITY,ETHNICITY
SM253076,111.57,-1.34,Dayak,ETHNICITY,ETHNICITY
SM253076,115.03,2.03,Dayak,ETHNICITY,ETHNICITY
SM260236,66.6912,32.6132,Daychopan,ADM2,DISTRICT
SM229440,136.22,-13.1806,Dayi,LANGUAGE,LANGUAGE
SM1076,66.25,33.75,Daykundi,ADM1,DISTRICT
SM260227,68.2875,34.2713,Daymirdad,ADM2,DISTRICT
SM17865,45.3003,2.0581,Daynile,ADM2,DISTRICT
SM1077,40.2833,35.1667,Dayr Az Zawr,ADM1,DISTRICT
SM273668,30.7932,27.6265,Dayr Mawas,ADM2,DISTRICT
SM273771,30.7837,27.5281,Dayrut,ADM2,DISTRICT
SM323955,-112.2634,52.8679,Daysland,ADM2:ADM3,DISTRICT
SM277654,51.7023,28.0403,Dayyer,ADM2,DISTRICT
SM229443,10.4865,10.1185,Daza,LANGUAGE,LANGUAGE
SM250180,21.66,17.17,Daza,ETHNICITY,ETHNICITY
SM250180,21.66,17.17,Daza,ETHNICITY,ETHNICITY
SM250180,18,17,Daza,ETHNICITY,ETHNICITY
SM250180,18,17,Daza,ETHNICITY,ETHNICITY
SM229442,15.8517,15.7358,Dazaga,LANGUAGE,LANGUAGE
SM279726,130.5333,33.5,Dazaifu,ADM2,DISTRICT
SM269335,107.6507,31.3451,Dazhou,ADM2,DISTRICT
SM298375,29.867,37.9268,Dazkırı,ADM2,DISTRICT
SM261920,46,40.4167,Daşkəsən,ADM1:ADM2,DISTRICT
SM285615,5.1683,52.1414,De Bilt,ADM2,DISTRICT
SM15115,5.7725,52.9691,De Fryske Marren,ADM2,DISTRICT
SM301548,-94.4047,39.8932,De Kalb,ADM2,DISTRICT
SM300697,-84.9991,41.3976,De Kalb,ADM2,DISTRICT
SM300602,-88.7703,41.8935,De Kalb,ADM2,DISTRICT
SM300263,-85.8041,34.4598,De Kalb,ADM2,DISTRICT
SM17315,-60.9494,13.7965,De Mailly,ADM2,DISTRICT
SM296057,-55.2067,5.7501,De Nieuwe Grond,ADM2,DISTRICT
SM327737,-97.0089,49.4,De Salaberry,ADM2:ADM3,DISTRICT
SM301115,-93.7373,32.0555,De Soto,ADM2,DISTRICT
SM300604,-88.9041,40.1746,De Witt,ADM2,DISTRICT
SM285553,6.3728,52.701,De Wolden,ADM2,DISTRICT
SM302557,-85.8328,35.9799,DeKalb,ADM2,DISTRICT
SM300478,-84.2264,33.7715,DeKalb,ADM2,DISTRICT
SM253079,141.65,-7.33,Dea,ETHNICITY,ETHNICITY
SM253079,142.05,-7.6,Dea,ETHNICITY,ETHNICITY
SM302848,-102.6049,34.966,Deaf Smith,ADM2,DISTRICT
SM290686,25.306,46.3707,Dealu,ADM2,DISTRICT
SM292182,24.445,44.9118,Dealu Launele,ADM2,DISTRICT
SM292101,27.2951,46.3088,Dealu Morii,ADM2,DISTRICT
SM300698,-84.9733,39.1452,Dearborn,ADM2,DISTRICT
SM301864,-104.412,34.3424,Debaca,ADM2,DISTRICT
SM277135,84.8037,21.4659,Debagarh,ADM2,DISTRICT
SM18200,38.4215,48.3445,Debaltseve City Council,ADM2,DISTRICT
SM1078,20.5844,41.5325,Debar,ADM1,DISTRICT
SM1079,20.8222,41.3342,Debarca,ADM1,DISTRICT
SM276273,21.6687,47.5271,Debrecen,ADM1:ADM2,DISTRICT
SM17316,-61.0259,13.7903,Debreuil,ADM2,DISTRICT
SM1080,38.8333,14.8333,Debub,ADM1,DISTRICT
SM274345,38.0995,11.8385,Debub Gondar,ADM2,DISTRICT
SM274345,38.0995,11.8385,Debub Gondar,ADM2,DISTRICT
SM274345,38.1187,11.7504,Debub Gondar,ADM2,DISTRICT
SM274345,38.1187,11.7504,Debub Gondar,ADM2,DISTRICT
SM274370,38.2059,8.6365,Debub Mirab Shewa,ADM2,DISTRICT
SM274370,38.2059,8.6365,Debub Mirab Shewa,ADM2,DISTRICT
SM274370,38.2353,8.6092,Debub Mirab Shewa,ADM2,DISTRICT
SM274370,38.2353,8.6092,Debub Mirab Shewa,ADM2,DISTRICT
SM274327,36.5,5.5,Debub Omo,ADM2,DISTRICT
SM274327,36.5,5.5,Debub Omo,ADM2,DISTRICT
SM274327,36.4015,5.4621,Debub Omo,ADM2,DISTRICT
SM274327,36.4015,5.4621,Debub Omo,ADM2,DISTRICT
SM274346,39.25,11,Debub Wollo,ADM2,DISTRICT
SM1081,41.5,13.75,Debubawi Keyih Bahri,ADM1,DISTRICT
SM302556,-88.1088,35.603,Decatur,ADM2,DISTRICT
SM300699,-85.5011,39.307,Decatur,ADM2,DISTRICT
SM300901,-100.4599,39.7848,Decatur,ADM2,DISTRICT
SM300803,-93.7863,40.7377,Decatur,ADM2,DISTRICT
SM300477,-84.5791,30.8783,Decatur,ADM2,DISTRICT
SM229456,75.717,18.5919,Deccan,LANGUAGE,LANGUAGE
SM291139,24.8774,46.9464,Deda,ADM2,DISTRICT
SM14005,-7.6167,4.8,Dedabo,ADM2,DISTRICT
SM10235,15.2881,3.8817,Dede-Mokouba,ADM2,DISTRICT
SM1082,144.839,13.5178,Dededo,ADM1,DISTRICT
SM11298,46.1111,41.4639,Dedoplis Tskaro,ADM2,DISTRICT
SM11299,46.25,41.3333,Dedoplists'q'aros Munitsip'alit'et'I (excl. Dedoplis Tskaro),ADM2,DISTRICT
SM229461,147.627,-6.3821,Dedua,LANGUAGE,LANGUAGE
SM1083,34.1856,-14.2627,Dedza,ADM1:ADM2,DISTRICT
SM301625,-113.0678,46.0608,Deer Lodge,ADM2,DISTRICT
SM12282,-77.7372,18.3598,Deeside,ADM2,DISTRICT
SM229466,7.5019,4.5845,Defaka,LANGUAGE,LANGUAGE
SM229469,2.6861,6.4072,Defi Gbe,LANGUAGE,LANGUAGE
SM302095,-84.4905,41.3239,Defiance,ADM2,DISTRICT
SM18038,36.1636,36.2374,Defne,ADM2,DISTRICT
SM229479,-2.2872,8.4385,Deg,LANGUAGE,LANGUAGE
SM229471,86.7694,25.0461,Degaru,LANGUAGE,LANGUAGE
SM229474,6.8099,4.7337,Degema,LANGUAGE,LANGUAGE
SM285217,6.938,4.5741,Degema,ADM2,DISTRICT
SM229475,146.572,-5.7089,Degenan,LANGUAGE,LANGUAGE
SM296407,14.4335,59.1558,Degerfors,ADM2,DISTRICT
SM229476,-160.044,62.0697,Degexit'an,LANGUAGE,LANGUAGE
SM253083,-158.4,63.51,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-158.4,63.51,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-158.4,63.51,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-158.4,63.51,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-159.5,62.5,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-159.5,62.5,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-159.5,62.5,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-159.5,62.5,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-160,62,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-160,62,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-160,62,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-160,62,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-157.75,61.82,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-157.75,61.82,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-157.75,61.82,Degexit'an,ETHNICITY,ETHNICITY
SM253083,-157.75,61.82,Degexit'an,ETHNICITY,ETHNICITY
SM17317,-60.9733,13.9773,Deglos,ADM2,DISTRICT
SM13483,23.1392,56.8756,Degoles pagasts,ADM2,DISTRICT
SM281791,-102.1499,20.454,Degollado,ADM2,DISTRICT
SM298009,8.2505,34.0696,Degueche,ADM2,DISTRICT
SM9519,69.3638,35.7448,Deh-e Salah,ADM2,DISTRICT
SM10200,-121.3496,61.8572,Dehcho Region,ADM2,DISTRICT
SM277503,47.2667,32.6833,Dehloran,ADM2,DISTRICT
SM269376,98.0396,24.6635,Dehong Dai and Jingpo,ADM2,DISTRICT
SM303518,66.6667,38.3333,Dehqonobod,ADM2,DISTRICT
SM277351,78.06,30.33,Dehradun,ADM2,DISTRICT
SM229483,167.229,-20.8892,Dehu,LANGUAGE,LANGUAGE
SM253085,167.23,-20.94,Dehu,ETHNICITY,ETHNICITY
SM253086,61.78,27.95,Dehwar,ETHNICITY,ETHNICITY
SM229485,66.5977,29.5205,Dehwari,LANGUAGE,LANGUAGE
SM288609,144.3667,-5.6833,Dei,ADM2,DISTRICT
SM289735,34.3674,31.4077,Deir Al-Balah,ADM1:ADM2,DISTRICT
SM296776,40.1934,35.4491,Deir ez-Zor,ADM2,DISTRICT
SM276547,136.4057,-4.1529,Deiyai,ADM2,DISTRICT
SM290092,23.875,47.1278,Dej,ADM2,DISTRICT
SM229490,14.4833,11.0183,Dek,LANGUAGE,LANGUAGE
SM305355,16.5819,46.4481,Dekanovec,ADM2,DISTRICT
SM274016,39.0333,15.0667,Dekemehare,ADM2,DISTRICT
SM284968,7.1734,7.5844,Dekina,ADM2,DISTRICT
SM10236,19.1497,6.2753,Dekoa,ADM2,DISTRICT
SM13484,26.8224,56.6126,Deksares pagasts,ADM2,DISTRICT
SM303219,-123.9578,41.745,Del Norte,ADM2,DISTRICT
SM229493,122.852,-10.8469,Dela-Oenale,LANGUAGE,LANGUAGE
SM12283,-76.8224,17.9921,Delacree Park/ Union Gardens,ADM2,DISTRICT
SM12284,-76.8171,17.9958,Delacree Pen,ADM2,DISTRICT
SM17318,-60.9058,13.9299,Delaide,ADM2,DISTRICT
SM302300,-75.3989,39.9168,Delaware,ADM2,DISTRICT
SM301871,-74.9665,42.1981,Delaware,ADM2,DISTRICT
SM302185,-94.8027,36.4082,Delaware,ADM2,DISTRICT
SM302096,-83.0049,40.2784,Delaware,ADM2,DISTRICT
SM300804,-91.3674,42.4712,Delaware,ADM2,DISTRICT
SM300700,-85.3969,40.2275,Delaware,ADM2,DISTRICT
SM1084,-75.4999,39.0004,Delaware,ADM1,DISTRICT
SM253087,-74.51,39.99,Delaware,ETHNICITY,ETHNICITY
SM253087,-74.51,39.99,Delaware,ETHNICITY,ETHNICITY
SM253087,-74.51,39.99,Delaware,ETHNICITY,ETHNICITY
SM253087,-97.5,35.5,Delaware,ETHNICITY,ETHNICITY
SM253087,-97.5,35.5,Delaware,ETHNICITY,ETHNICITY
SM253087,-97.5,35.5,Delaware,ETHNICITY,ETHNICITY
SM253087,-75,41,Delaware,ETHNICITY,ETHNICITY
SM253087,-75,41,Delaware,ETHNICITY,ETHNICITY
SM253087,-75,41,Delaware,ETHNICITY,ETHNICITY
SM323982,-113.2262,52.1933,Delburne,ADM2:ADM3,DISTRICT
SM17319,-61.0589,13.8012,Delcer,ADM2,DISTRICT
SM292269,27.7573,46.5349,Deleni,ADM2,DISTRICT
SM290818,26.8945,47.4746,Deleni,ADM2,DISTRICT
SM290161,28.0226,44.0618,Deleni,ADM2,DISTRICT
SM263376,-45.2897,-22.5117,Delfim Moreira,ADM2,DISTRICT
SM263377,-46.8395,-20.3425,Delfinópolis,ADM2,DISTRICT
SM285643,4.3641,51.9997,Delft,ADM2,DISTRICT
SM285849,6.9313,53.3002,Delfzijl,ADM2,DISTRICT
SM295525,-89.1568,13.7558,Delgado,ADM2,DISTRICT
SM283556,97.3667,46.3431,Delger,ADM2,DISTRICT
SM283805,111.1998,45.7941,Delgerekh,ADM2,DISTRICT
SM283563,109.1897,47.1738,Delgerkhaan,ADM2,DISTRICT
SM283704,104.585,46.591,Delgerkhaan,ADM2,DISTRICT
SM283819,104.8051,45.2374,Delgerkhangai,ADM2,DISTRICT
SM283820,106.3738,46.1191,Delgertsogt,ADM2,DISTRICT
SM276702,98.6667,3.4167,Deli Serdang,ADM2,DISTRICT
SM323897,-112.37,51.6321,Delia,ADM2:ADM3,DISTRICT
SM298631,34.0309,39.9645,Delice,ADM2,DISTRICT
SM283223,-105.4491,28.1695,Delicias,ADM2,DISTRICT
SM295739,-88.143,13.783,Delicias de Concepción,ADM2,DISTRICT
SM277565,50.7667,34.0333,Delijan,ADM2,DISTRICT
SM10672,33.3391,34.9244,Delikipos,ADM2,DISTRICT
SM250288,-15,22,Delim,ETHNICITY,ETHNICITY
SM265357,-38.0248,-9.4445,Delmiro Gouveia,ADM2,DISTRICT
SM275981,14.8011,45.3981,Delnice,ADM2,DISTRICT
SM229498,0.5973,7.91,Delo,LANGUAGE,LANGUAGE
SM17320,-60.9293,13.8034,Delomel,ADM2,DISTRICT
SM302850,-95.6723,33.3863,Delta,ADM2,DISTRICT
SM301264,-86.8706,45.7916,Delta,ADM2,DISTRICT
SM303325,-107.8629,38.8614,Delta,ADM2,DISTRICT
SM263378,-47.8044,-19.9341,Delta,ADM2,DISTRICT
SM1086,6,5.5,Delta,ADM1,DISTRICT
SM1087,-61.3333,8.75,Delta Amacuro,ADM1,DISTRICT
SM12285,-78.2396,18.2971,Delveland,ADM2,DISTRICT
SM260475,20.049,39.9982,Delvinës,ADM2,DISTRICT
SM260475,20.049,39.9982,Delvinës,ADM2,DISTRICT
SM260475,20.0833,39.9167,Delvinës,ADM2,DISTRICT
SM260475,20.0833,39.9167,Delvinës,ADM2,DISTRICT
SM268918,7.3305,47.3543,Delémont,ADM2,DISTRICT
SM283695,90.8332,47.7585,Delüün,ADM2,DISTRICT
SM1085,22.7667,41.9333,Delčevo,ADM1,DISTRICT
SM229509,137.632,-3.7218,Dem,LANGUAGE,LANGUAGE
SM229505,32.7731,-15.6894,Dema,LANGUAGE,LANGUAGE
SM15666,-65.6516,18.288,Demajagua Barrio,ADM2,DISTRICT
SM276337,110.6122,-6.8993,Demak,ADM2,DISTRICT
SM1088,45.1855,-12.843,Dembeni,ADM1,DISTRICT
SM260435,14.5445,-8.4986,Dembos,ADM2,DISTRICT
SM13485,26.5908,55.7362,Demenes pagasts,ADM2,DISTRICT
SM1089,-58.0833,6.5,Demerara-Mahaica,ADM1,DISTRICT
SM265215,-42.6663,-5.3154,Demerval Lobão,ADM2,DISTRICT
SM1090,21.1439,41.2808,Demir Hisar,ADM1,DISTRICT
SM1091,22.25,41.3833,Demir Kapija,ADM1,DISTRICT
SM298734,28.6646,39.057,Demirci,ADM2,DISTRICT
SM298645,27.7714,41.8357,Demirköy,ADM2,DISTRICT
SM298191,39.8934,40.1656,Demirözü,ADM2,DISTRICT
SM229508,136.694,-2.5456,Demisa,LANGUAGE,LANGUAGE
SM303586,-70.1667,10.8333,Democracia,ADM2,DISTRICT
SM284832,12.078,9.3564,Demsa,ADM2,DISTRICT
SM229512,140.166,-2.3848,Demta,LANGUAGE,LANGUAGE
SM229513,-70.8737,-4.8376,Demushbo,LANGUAGE,LANGUAGE
SM293837,32.75,57.5833,Demyanskiy rayon,ADM2,DISTRICT
SM299675,25.2906,50.443,Demydiv's'kyi,ADM2,DISTRICT
SM297392,100.0333,17.9167,Den Chai,ADM2,DISTRICT
SM285976,4.7579,52.9237,Den Helder,ADM2,DISTRICT
SM301194,-150.1917,63.7889,Denali,ADM2,DISTRICT
SM274766,-3.3333,53.0833,Denbighshire,ADM1:ADM2,DISTRICT
SM229515,3.4911,11.6682,Dendi (Benin),LANGUAGE,LANGUAGE
SM229514,22.566,4.4815,Dendi (Central African Republic),LANGUAGE,LANGUAGE
SM257665,-122,62,Dene,ETHNICITY,ETHNICITY
SM10673,33.1475,35.182,Deneia,ADM2,DISTRICT
SM229519,146.3598,-7.0572,Dengalu,DIALECT,LANGUAGE
SM229517,21.4194,-3.2634,Dengese,LANGUAGE,LANGUAGE
SM229520,122.946,-10.7866,Dengka,LANGUAGE,LANGUAGE
SM1092,-7.3763,9.4971,Denguélé,ADM1,DISTRICT
SM12286,-76.7998,17.9781,Denham Town,ADM2,DISTRICT
SM229521,-67.5029,-6.7496,Deni,LANGUAGE,LANGUAGE
SM1093,166.9163,-0.5219,Denigomodu,ADM1,DISTRICT
SM263155,-56.9512,-14.7574,Denise,ADM2,DISTRICT
SM279859,61.6667,52.6667,Denisovskiy,ADM2,DISTRICT
SM1094,29.0698,37.8402,Denizli,ADM1,DISTRICT
SM18603,40.8947,14.4096,Denkel Awraja,ADM2,DISTRICT
SM11392,-0.8444,6.0542,Denkyembour,ADM2,DISTRICT
SM261369,117.3752,-34.9347,Denmark,ADM2,DISTRICT
SM1095,-60.9192,13.9251,Dennery,ADM1,DISTRICT
SM17321,-60.8945,13.9116,Dennery By Pass,ADM2,DISTRICT
SM17322,-60.8981,13.9109,Dennery By Pass/Green Mountain,ADM2,DISTRICT
SM17323,-60.9002,13.9144,Dennery By Pass/Rocky Lane,ADM2,DISTRICT
SM17324,-60.8933,13.9142,Dennery By Pass/White Rock Gardens,ADM2,DISTRICT
SM17325,-60.8912,13.911,Dennery Village,ADM2,DISTRICT
SM229522,10.4394,10.6315,Deno,LANGUAGE,LANGUAGE
SM18388,67.9,38.2667,Denov Shahri,ADM2,DISTRICT
SM18389,67.8337,38.3268,Denov Tumani,ADM2,DISTRICT
SM276476,115.2166,-8.6667,Denpasar,ADM2,DISTRICT
SM290753,22.7781,45.564,Densus,ADM2,DISTRICT
SM301549,-91.5079,37.6066,Dent,ADM2,DISTRICT
SM291971,21.2833,45.3595,Denta,ADM2,DISTRICT
SM302851,-97.117,33.2053,Denton,ADM2,DISTRICT
SM303326,-104.8764,39.762,Denver,ADM2,DISTRICT
SM229523,9.3999,5.9724,Denya,LANGUAGE,LANGUAGE
SM263042,-54.2032,-22.0804,Deodápolis,ADM2,DISTRICT
SM276916,86.7261,24.4438,Deoghar,ADM2,DISTRICT
SM229525,93.7057,26.8615,Deori,LANGUAGE,LANGUAGE
SM277282,83.75,26.6667,Deoria (1994-),ADM2,DISTRICT
SM9692,-58.3632,-32.0038,Departamento de Colon (1995-),ADM2,DISTRICT
SM9693,-58.1627,-31.2135,Departamento de Concordia (1995-),ADM2,DISTRICT
SM9694,-61.3333,-27.4167,Departamento de Doce de Octubre (1992-),ADM2,DISTRICT
SM9695,-61.3447,-27.6093,Departamento de Dos de Abril,ADM2,DISTRICT
SM9696,-59,-33.25,Departamento de Gualeguaychu,ADM2,DISTRICT
SM9697,-58.5228,-31.6161,Departamento de San Salvador,ADM2,DISTRICT
SM9698,-67.8962,-54.6291,Departamento de Ushuaia (2017-),ADM2,DISTRICT
SM9699,-59,-31.5833,Departamento de Villaguay (1995-),ADM2,DISTRICT
SM15223,5,14.25,Departement d' Illela,ADM2,DISTRICT
SM15224,7.7422,13.4698,Departement d'Aguie,ADM2,DISTRICT
SM15225,5.25,13.9,Departement de Birni Nkonni,ADM2,DISTRICT
SM15226,7,14.75,Departement de Dakoro,ADM2,DISTRICT
SM15227,12.5,13.6667,Departement de Diffa,ADM2,DISTRICT
SM15228,3.2361,14.3165,Departement de Filingue,ADM2,DISTRICT
SM15229,3.4605,11.9597,Departement de Gaya,ADM2,DISTRICT
SM15230,10.1472,14.0162,Departement de Goure,ADM2,DISTRICT
SM15231,12.0832,13.3121,Departement de Maine-Soroa,ADM2,DISTRICT
SM15232,13.1218,14.2075,Departement de Nguigmi,ADM2,DISTRICT
SM15233,2.25,14.6333,Departement de Ouallam,ADM2,DISTRICT
SM15234,2.2211,13.0358,Departement de Say,ADM2,DISTRICT
SM15235,4.8,14.8,Departement de Tahoua,ADM2,DISTRICT
SM15236,8.3333,14.75,Departement de Tanout,ADM2,DISTRICT
SM15237,5.7104,15.8669,Departement de Tchin-Tabaraden (2002-),ADM2,DISTRICT
SM15238,7.8932,17.4329,Departement de Tchirozerine,ADM2,DISTRICT
SM1096,-65.3089,10.9235,Dependencias Federales,ADM1,DISTRICT
SM276756,106.8167,-6.4,Depok,ADM2,DISTRICT
SM267914,-39.2592,-5.8874,Deputado Irapuan Pinheiro,ADM2,DISTRICT
SM229529,140.947,-3.5231,Dera (Indonesia),LANGUAGE,LANGUAGE
SM229530,12.0256,10.072,Dera (Nigeria),LANGUAGE,LANGUAGE
SM375393,69.0688,28.9425,Dera Bugti,ADM2:ADM3,DISTRICT
SM375445,70.4045,30.475,Dera Ghazi Kha,ADM2:ADM3,DISTRICT
SM375413,70.6488,31.8596,Dera Ismail Khan,ADM2:ADM3,DISTRICT
SM274328,37.3333,5.6667,Derashe,ADM2,DISTRICT
SM299461,27.4253,49.2694,Derazhnians'kyi,ADM2,DISTRICT
SM298667,32.0246,38.024,Derbent,ADM2,DISTRICT
SM292822,48.2958,42.0628,Derbent,ADM2,DISTRICT
SM292823,48.1667,42.0833,Derbentskiy rayon,ADM2,DISTRICT
SM274619,-1.4722,52.9207,Derby,ADM1:ADM2,DISTRICT
SM261371,125.2801,-17.5045,Derby-West Kimberley,ADM2,DISTRICT
SM274620,-1.5833,53.1667,Derbyshire,ADM1:ADM2,DISTRICT
SM298668,31.5167,37.4018,Derebucak,ADM2,DISTRICT
SM276274,21.7484,47.3819,Derecske–Létavértes,ADM2,DISTRICT
SM298440,38.39,40.613,Dereli,ADM2,DISTRICT
SM283821,106.7164,46.1744,Deren,ADM2,DISTRICT
SM298830,40.4388,41.0221,Derepazarı,ADM2,DISTRICT
SM17326,-60.9773,13.7681,Derierre Bois,ADM2,DISTRICT
SM17327,-60.9851,13.9947,Derierre Fort/Old Victoria Road,ADM2,DISTRICT
SM17328,-60.9606,13.7413,Derierre Morne,ADM2,DISTRICT
SM298742,40.2688,37.3643,Derik,ADM2,DISTRICT
SM298659,29.8231,40.7539,Derince,ADM2,DISTRICT
SM298787,34.7384,38.3462,Derinkuyu,ADM2,DISTRICT
SM253096,137.1,-3.19,Dern,ETHNICITY,ETHNICITY
SM292424,22.2882,47.208,Derna,ADM2,DISTRICT
SM298975,40.2473,40.7991,Dernekpazarı,ADM2,DISTRICT
SM17329,-60.9258,13.9568,Derniere Riviere,ADM2,DISTRICT
SM17330,-60.9222,13.9513,Derniere Riviere/Fond Maricient,ADM2,DISTRICT
SM17331,-60.9304,13.9656,Derniere Riviere/Fond Petit,ADM2,DISTRICT
SM17332,-60.9203,13.9629,Derniere Riviere/Mardi Gras/Morne Caca Cochon,ADM2,DISTRICT
SM17333,-60.9292,13.9525,Derniere Riviere/Morne Panache,ADM2,DISTRICT
SM17334,-61.0062,13.9255,Derriere Dos,ADM2,DISTRICT
SM17335,-61.0189,13.9417,Derriere Lagoon,ADM2,DISTRICT
SM17336,-61.051,13.8092,Derriere Morne,ADM2,DISTRICT
SM265627,-53.8842,-27.2503,Derrubadas,ADM2,DISTRICT
SM274708,-7.3596,54.8205,Derry and Strabane,ADM1:ADM2,DISTRICT
SM253097,39.68,39.93,Dersimili,ETHNICITY,ETHNICITY
SM313206,17.9167,44.9397,Derventa,ADM2:ADM3,DISTRICT
SM261782,146.5297,-42.8038,Derwent Valley,ADM2,DISTRICT
SM10674,33.942,35.0643,Deryneia,ADM2,DISTRICT
SM17337,-60.9017,13.9995,Des Barras,ADM2,DISTRICT
SM17338,-60.901,14.0169,Des Barras/Cacolie,ADM2,DISTRICT
SM17339,-60.9342,13.8116,Des Blanchard,ADM2,DISTRICT
SM17340,-61.0341,13.8806,Des Bottes,ADM2,DISTRICT
SM300805,-91.1815,40.9232,Des Moines,ADM2,DISTRICT
SM290355,23.0324,43.8704,Desa,ADM2,DISTRICT
SM270974,-84.0413,9.8555,Desamparados,ADM2,DISTRICT
SM253100,-68.68,-0.52,Desana,ETHNICITY,ETHNICITY
SM229539,-69.4955,0.5816,Desano,LANGUAGE,LANGUAGE
SM15667,-66.4247,17.9968,Descalabrado Barrio,ADM2,DISTRICT
SM266491,-47.6592,-21.8792,Descalvado,ADM2,DISTRICT
SM266409,-53.4842,-26.8738,Descanso,ADM2,DISTRICT
SM17341,-60.9783,13.8402,Descatier/Six Miles,ADM2,DISTRICT
SM302284,-121.2279,43.9149,Deschutes,ADM2,DISTRICT
SM263379,-42.9713,-21.4517,Descoberto,ADM2,DISTRICT
SM17342,-61.0253,13.8626,Descolons,ADM2,DISTRICT
SM261011,-67.8333,-47.3333,Deseado,ADM2,DISTRICT
SM15185,-83.5769,12.9319,Desembocadura de la Cruz de Rio Grande,ADM2,DISTRICT
SM291003,23.859,47.77,Desesti,ADM2,DISTRICT
SM302365,-91.254,33.8333,Desha,ADM2,DISTRICT
SM305744,15.6677,46.146,Desinić,ADM2,DISTRICT
SM229542,82.646,18.965,Desiya,LANGUAGE,LANGUAGE
SM289611,-58.1241,-27.088,Desmochados,ADM2,DISTRICT
SM299470,30.6088,50.5355,Desnians'kyi,ADM2,DISTRICT
SM301434,-89.9918,34.8754,Desoto,ADM2,DISTRICT
SM300317,-81.8093,27.1863,Desoto,ADM2,DISTRICT
SM17343,-60.9118,13.9497,Despinoze,ADM2,DISTRICT
SM17344,-60.927,14.0368,Desrameaux,ADM2,DISTRICT
SM17345,-60.9354,13.7964,Desruisseaux,ADM2,DISTRICT
SM276083,-72.4584,19.1786,Dessalines,ADM2,DISTRICT
SM264415,-37.1136,-7.2936,Desterro,ADM2,DISTRICT
SM263381,-44.2814,-20.6419,Desterro de Entre Rios,ADM2,DISTRICT
SM264414,-37.576,-6.7417,Desterro de Malta,ADM2,DISTRICT
SM263382,-43.5201,-21.1429,Desterro do Melo,ADM2,DISTRICT
SM296346,15.8802,46.481,Destrnik,ADM1:ADM2,DISTRICT
SM297699,105.0751,14.8515,Det Udom,ADM2,DISTRICT
SM291972,21.2483,45.4052,Deta,ADM2,DISTRICT
SM296120,19.4204,48.5599,Detva,ADM2,DISTRICT
SM302488,-96.668,44.7601,Deuel,ADM2,DISTRICT
SM301695,-102.334,41.1116,Deuel,ADM2,DISTRICT
SM285909,5.8225,51.437,Deurne,ADM2,DISTRICT
SM261857,15.2,46.75,Deutschlandsberg,ADM2,DISTRICT
SM274440,-0.3084,46.5392,Deux-Sèvres,ADM2,DISTRICT
SM10282,15.0377,12.1381,Deuxieme Arrondissement,ADM2,DISTRICT
SM290754,22.9312,45.8464,Deva,ADM2,DISTRICT
SM276813,69.655,22.2025,Devbhumi Dwarka,ADM2,DISTRICT
SM11563,17.3227,47.1684,Devecseri Jaras,ADM2,DISTRICT
SM298617,35.5916,38.2679,Develi,ADM2,DISTRICT
SM291063,22.6767,44.4879,Devesel,ADM2,DISTRICT
SM291406,24.3735,44.0657,Deveselu,ADM2,DISTRICT
SM17346,-61.0505,13.8137,Deville,ADM2,DISTRICT
SM262410,24.4019,41.7435,Devin,ADM2,DISTRICT
SM262462,27.6005,43.2206,Devnya,ADM2,DISTRICT
SM260493,20.9381,40.6008,Devollit,ADM2,DISTRICT
SM260493,20.9381,40.6008,Devollit,ADM2,DISTRICT
SM260493,20.9167,40.5833,Devollit,ADM2,DISTRICT
SM260493,20.9167,40.5833,Devollit,ADM2,DISTRICT
SM274621,-3.75,50.75,Devon,ADM1:ADM2,DISTRICT
SM12287,-77.5251,18.166,Devon,ADM2,DISTRICT
SM323993,-113.7259,53.3527,Devon,ADM2:ADM3,DISTRICT
SM12288,-76.8244,18.2156,Devon Pen,ADM2,DISTRICT
SM261783,146.324,-41.213,Devonport,ADM2,DISTRICT
SM1097,-64.7558,32.3028,Devonshire,ADM1,DISTRICT
SM299041,31.9652,41.2282,Devrek,ADM2,DISTRICT
SM298607,33.846,41.6135,Devrekani,ADM2,DISTRICT
SM276974,76.1667,23,Dewas,ADM2,DISTRICT
SM229564,85.4115,27.362,Dewas-Done Danuwar,LANGUAGE,LANGUAGE
SM323756,-110.5261,53.5862,Dewberry,ADM2:ADM3,DISTRICT
SM302490,-100.8719,45.1566,Dewey,ADM2,DISTRICT
SM302186,-99.0079,35.9877,Dewey,ADM2,DISTRICT
SM302852,-97.3568,29.0821,Dewitt,ADM2,DISTRICT
SM229550,-10.9076,6.5611,Dewoin,LANGUAGE,LANGUAGE
SM250868,-10.25,6.34,Dey,ETHNICITY,ETHNICITY
SM370123,-10.9167,6.5333,Dey-Gbo,ADM2:ADM3,DISTRICT
SM269336,104.4736,31.1366,Deyang,ADM2,DISTRICT
SM277655,50.356,30.0229,Deylam,ADM2,DISTRICT
SM265628,-55.0755,-28.1913,Dezesseis de Novembro,ADM2,DISTRICT
SM277542,48.7833,32.5667,Dezful,ADM2,DISTRICT
SM229551,48.907,32.374,Dezfuli-Shushtari,LANGUAGE,LANGUAGE
SM269300,116.7153,37.2972,Dezhou,ADM2,DISTRICT
SM290497,22.2273,46.41,Dezna,ADM2,DISTRICT
SM305337,17.0875,45.573,Dežanovac,ADM2,DISTRICT
SM229555,-2.5751,49.4546,Dgernesiais,DIALECT,LANGUAGE
SM274004,37.5465,15.6624,Dghe,ADM2,DISTRICT
SM229556,13.7785,11.0575,Dghwede,LANGUAGE,LANGUAGE
SM4252,73.012,2.9488,Dhaalu Atholhu,ADM1:ADM2,DISTRICT
SM375295,84.9066,28.0078,Dhading,ADM2:ADM3,DISTRICT
SM229560,38.818,-4.8121,Dhaiso,LANGUAGE,LANGUAGE
SM262241,90.3654,23.8109,Dhaka,ADM2,DISTRICT
SM3923,90.25,23.8333,Dhaka Division (2015-),ADM1,DISTRICT
SM277260,91.9259,23.8431,Dhalai,ADM2,DISTRICT
SM229561,114.937,-22.196,Dhalandji,LANGUAGE,LANGUAGE
SM459240,145.2801,-15.2538,Dhalundhirr,ETHNICITY,ETHNICITY
SM1099,44.2729,14.683,Dhamar,ADM1,DISTRICT
SM304798,44.4034,14.6068,Dhamar City,ADM2,DISTRICT
SM277468,81.8,20.5,Dhamtari,ADM2,DISTRICT
SM276917,86.4167,23.75,Dhanbad (1999-),ADM2,DISTRICT
SM458429,152.4169,-30.9064,Dhanggati / Dunghutti^,ETHNICITY,ETHNICITY
SM229569,135.752,-11.9493,Dhangu,LANGUAGE,LANGUAGE
SM458834,136.6073,-12.427,Dhangu,ETHNICITY,ETHNICITY
SM229563,73.7418,20.7719,Dhanki,LANGUAGE,LANGUAGE
SM375313,87.3132,27.0181,Dhankuta,ADM2:ADM3,DISTRICT
SM375302,86.0359,26.8512,Dhanusa,ADM2:ADM3,DISTRICT
SM229566,83.1644,23.0613,Dhanwar (India),LANGUAGE,LANGUAGE
SM229571,122.658,-10.8098,Dhao,LANGUAGE,LANGUAGE
SM304976,47.6058,15.5036,Dhar,ADM2,DISTRICT
SM276975,75.25,22.5,Dhar,ADM2,DISTRICT
SM459073,150.3412,-35.151,Dharamba,ETHNICITY,ETHNICITY
SM229574,114.847,-24.0601,Dhargari,LANGUAGE,LANGUAGE
SM277245,78.24,12.13,Dharmapuri (2004-),ADM2,DISTRICT
SM276653,101.5865,-1.2506,Dharmasraya,ADM2,DISTRICT
SM459081,150.0484,-33.8896,Dharug / Darug,ETHNICITY,ETHNICITY
SM229575,150.593,-23.307,Dharumbal,LANGUAGE,LANGUAGE
SM276928,75.14,15.37,Dharwad (1997-),ADM2,DISTRICT
SM229576,70.7826,26.4156,Dhatki,LANGUAGE,LANGUAGE
SM277167,77.7396,26.7118,Dhaulpur,ADM2,DISTRICT
SM277400,94.7942,27.6091,Dhemaji,ADM2,DISTRICT
SM277136,85.5,20.75,Dhenkanal (1993-),ADM2,DISTRICT
SM304868,44.0778,13.8214,Dhi As Sufal,ADM2,DISTRICT
SM304789,44.1515,16.0276,Dhi Bin,ADM2,DISTRICT
SM305004,45.4642,14.1111,Dhi Na'im,ADM2,DISTRICT
SM1101,46.25,31.25,Dhi-Qar,ADM1,DISTRICT
SM298003,10.5913,31.9836,Dhiba,ADM2,DISTRICT
SM458498,138.2621,-20.4774,Dhidhani,ETHNICITY,ETHNICITY
SM229581,88.3798,26.6283,Dhimal,LANGUAGE,LANGUAGE
SM458672,142.2387,-28.0144,Dhiraila,ETHNICITY,ETHNICITY
SM458669,137.7748,-28.5983,Dhirari,ETHNICITY,ETHNICITY
SM229583,73.5443,1.9285,Dhivehi,LANGUAGE,LANGUAGE
SM229584,73.2743,21.0656,Dhodia-Kukna,LANGUAGE,LANGUAGE
SM1102,54.1833,18.4833,Dhofar,ADM1,DISTRICT
SM229585,54.4894,17.0796,Dhofari Arabic,LANGUAGE,LANGUAGE
SM304980,43.4724,12.9659,Dhubab,ADM2,DISTRICT
SM11643,90.0224,26.0675,Dhubri (2016-),ADM2,DISTRICT
SM229589,147.045,-36.9588,Dhudhuroa,LANGUAGE,LANGUAGE
SM277067,74.5884,21.0685,Dhule (1998-),ADM2,DISTRICT
SM229592,76.198,26.473,Dhundari,LANGUAGE,LANGUAGE
SM229594,150.134,-35.412,Dhurga,LANGUAGE,LANGUAGE
SM459070,150.0947,-35.6191,Dhurga,ETHNICITY,ETHNICITY
SM253175,80.68,18.91,Dhurwa Gond,ETHNICITY,ETHNICITY
SM295825,46.6841,5.4411,Dhuusamareeb,ADM2,DISTRICT
SM229596,136.008,-12.7825,Dhuwal,LANGUAGE,LANGUAGE
SM252080,135.96,-12.48,Dhuwal,ETHNICITY,ETHNICITY
SM252080,135.96,-12.48,Dhuwal,ETHNICITY,ETHNICITY
SM252080,135.96,-12.48,Dhuwal,ETHNICITY,ETHNICITY
SM252080,135.84,-12.93,Dhuwal,ETHNICITY,ETHNICITY
SM252080,135.84,-12.93,Dhuwal,ETHNICITY,ETHNICITY
SM252080,135.84,-12.93,Dhuwal,ETHNICITY,ETHNICITY
SM252080,136.0099,-12.7125,Dhuwal,ETHNICITY,ETHNICITY
SM252080,136.0099,-12.7125,Dhuwal,ETHNICITY,ETHNICITY
SM252080,136.0099,-12.7125,Dhuwal,ETHNICITY,ETHNICITY
SM458840,135.9581,-12.654,Dhuwala,ETHNICITY,ETHNICITY
SM458732,136.8701,-12.2661,Dhuwaya,ETHNICITY,ETHNICITY
SM304275,108.0869,11.4947,Di Linh,ADM2,DISTRICT
SM266492,-46.6116,-23.6955,Diadema,ADM2,DISTRICT
SM253176,-68.91,-25.72,Diaguita,ETHNICITY,ETHNICITY
SM229601,-62.1936,-7.9939,Diahói,DIALECT,LANGUAGE
SM264416,-38.3087,-7.41,Diamante,ADM2,DISTRICT
SM261183,-60.4167,-32.1667,Diamante,ADM2,DISTRICT
SM264842,-54.1035,-24.9456,Diamante d'Oeste,ADM2,DISTRICT
SM264843,-52.8728,-22.6375,Diamante do Norte,ADM2,DISTRICT
SM264445,-52.7013,-24.9868,Diamante do Sul,ADM2,DISTRICT
SM263383,-43.6041,-17.978,Diamantina,ADM2,DISTRICT
SM261623,140.0716,-24.5768,Diamantina,ADM2,DISTRICT
SM263156,-56.7774,-14.0224,Diamantino,ADM2,DISTRICT
SM269519,14.5,10.5,Diamaré,ADM2,DISTRICT
SM5109,74.0329,35.5341,Diamer,ADM2:ADM3,DISTRICT
SM17347,-61.0435,13.8517,Diamond/Diamond Estate,ADM2,DISTRICT
SM281469,49,-13.5,Diana,ADM1:ADM2,DISTRICT
SM236005,104.06,27.8682,Diandongbei-Large Flowery Miao,LANGUAGE,LANGUAGE
SM267229,-46.7354,-11.6958,Dianopolis,ADM2,DISTRICT
SM12289,-78.1856,18.3938,Dias,ADM2,DISTRICT
SM267388,-38.3372,-12.6063,Dias d'vila,ADM2,DISTRICT
SM229605,126.018,7.8566,Dibabawon Manobo,LANGUAGE,LANGUAGE
SM277207,95.7,28.7,Dibang Valley,ADM2,DISTRICT
SM18299,56.2479,25.5858,Dibba Al Fujairah Municipality,ADM2,DISTRICT
SM260673,56.269,25.6159,Dibba Al-Hisn,ADM2,DISTRICT
SM305852,43.9039,35.6808,Dibis,ADM2,DISTRICT
SM305852,43.9039,35.6808,Dibis,ADM2,DISTRICT
SM305852,44.2365,35.8037,Dibis,ADM2,DISTRICT
SM305852,44.2365,35.8037,Dibis,ADM2,DISTRICT
SM229606,142.779,-7.821,Dibiyaso,LANGUAGE,LANGUAGE
SM229609,6.3731,9.0611,Dibo,LANGUAGE,LANGUAGE
SM229607,17.2305,1.0777,Dibole,LANGUAGE,LANGUAGE
SM277402,95,27.5,Dibrugarh,ADM2,DISTRICT
SM260479,20.3333,41.75,Dibrës,ADM2,DISTRICT
SM260479,20.3333,41.75,Dibrës,ADM2,DISTRICT
SM260479,20.365,41.714,Dibrës,ADM2,DISTRICT
SM260479,20.365,41.714,Dibrës,ADM2,DISTRICT
SM10400,-73.3,11.2667,Dibulla,ADM2,DISTRICT
SM1104,20.2333,41.6,Dibër,ADM1,DISTRICT
SM229613,121.606,17.8846,Dicamay Agta,LANGUAGE,LANGUAGE
SM289940,27.5347,44.2306,Dichiseni,ADM2,DISTRICT
SM302853,-100.7789,33.6166,Dickens,ADM2,DISTRICT
SM302999,-82.3504,37.1257,Dickenson,ADM2,DISTRICT
SM302032,-98.5047,46.1101,Dickey,ADM2,DISTRICT
SM301265,-87.8702,46.0094,Dickinson,ADM2,DISTRICT
SM300902,-97.1527,38.8665,Dickinson,ADM2,DISTRICT
SM300807,-95.1508,43.378,Dickinson,ADM2,DISTRICT
SM302558,-87.3567,36.1491,Dickson,ADM2,DISTRICT
SM298328,40.0709,38.3714,Dicle,ADM2,DISTRICT
SM305497,16.5898,43.6368,Dicmo,ADM2,DISTRICT
SM291882,24.8888,44.2233,Didesti,ADM2,DISTRICT
SM253179,46.84,41.7,Didi,ETHNICITY,ETHNICITY
SM298155,27.2936,37.3931,Didim,ADM2,DISTRICT
SM229619,33.6869,4.2149,Didinga,LANGUAGE,LANGUAGE
SM250187,34.49,5.12,Didinga,ETHNICITY,ETHNICITY
SM250187,34.49,5.12,Didinga,ETHNICITY,ETHNICITY
SM250187,34,4,Didinga,ETHNICITY,ETHNICITY
SM250187,34,4,Didinga,ETHNICITY,ETHNICITY
SM323902,-114.1367,51.6588,Didsbury,ADM2:ADM3,DISTRICT
SM290498,22.2237,46.3207,Dieci,ADM2,DISTRICT
SM15668,-66.8441,18.0599,Diego Hernandez Barrio,ADM2,DISTRICT
SM303872,-67.7181,10.2964,Diego Ibarra,ADM2,DISTRICT
SM1105,-61.5833,10.7,Diego Martin,ADM1:ADM2,DISTRICT
SM1105,-61.5833,10.7,Diego Martin,ADM1:ADM2,DISTRICT
SM1105,-61.5342,10.7005,Diego Martin,ADM1:ADM2,DISTRICT
SM1105,-61.5342,10.7005,Diego Martin,ADM1:ADM2,DISTRICT
SM370437,6.1492,49.8728,Diekirch,ADM1:ADM2:ADM3,DISTRICT
SM281366,6.1475,49.8616,Diekirch,ADM1:ADM2,DISTRICT
SM269015,8.4563,47.4718,Dielsdorf,ADM2,DISTRICT
SM285977,4.9681,52.3416,Diemen,ADM2,DISTRICT
SM229626,138,-28.1667,Dieri,LANGUAGE,LANGUAGE
SM10675,33.0977,34.8191,Dierona,ADM2,DISTRICT
SM269016,8.4426,47.388,Dietikon,ADM2,DISTRICT
SM253180,12.27,13.96,Dietko,ETHNICITY,ETHNICITY
SM271097,-82.36,23.0869,Diez de Octubre,ADM2,DISTRICT
SM1107,13.5,16,Diffa,ADM1,DISTRICT
SM370510,5.8829,49.5217,Differdange,ADM1:ADM2:ADM3,DISTRICT
SM268836,-65.6988,44.3669,Digby,ADM2,DISTRICT
SM13486,26.1113,56.3416,Dignajas pagasts,ADM2,DISTRICT
SM229630,39.1752,-4.621,Digo,LANGUAGE,LANGUAGE
SM250188,38.57,-3.97,Digo,ETHNICITY,ETHNICITY
SM250188,38.57,-3.97,Digo,ETHNICITY,ETHNICITY
SM250188,39,-4,Digo,ETHNICITY,ETHNICITY
SM250188,39,-4,Digo,ETHNICITY,ETHNICITY
SM298582,43.4136,40.3752,Digor,ADM2,DISTRICT
SM229629,44.1569,43.1581,Digor Ossetian,LANGUAGE,LANGUAGE
SM293810,44.0833,43.1667,Digorskiy rayon,ADM2,DISTRICT
SM269079,-72.2961,-36.722,Diguillín,ADM2,DISTRICT
SM260130,70.4438,34.0885,Dih Bala,ADM2,DISTRICT
SM260041,69.308,34.6656,Dih Sabz,ADM2,DISTRICT
SM260253,67.0074,36.6369,Dihdadi,ADM2,DISTRICT
SM1108,43.1333,37.0667,Dihok,ADM1,DISTRICT
SM260221,65.4656,32.5836,Dihrawud,ADM2,DISTRICT
SM260298,68.6528,33.4749,Dihyak,ADM2,DISTRICT
SM229636,13.9884,7.7741,Dii,LANGUAGE,LANGUAGE
SM17866,42.9019,2.102,Diinsoor Town,ADM2,DISTRICT
SM229637,11.5201,9.6757,Dijim-Bwilim,LANGUAGE,LANGUAGE
SM4670,42.1667,11.4167,Dikhil (2003-),ADM1,DISTRICT
SM298531,26.9069,39.0784,Dikili,ADM2,DISTRICT
SM273641,31.6874,31.0841,Dikirnis,ADM2,DISTRICT
SM13487,25.0511,57.581,Diklu pagasts,ADM2,DISTRICT
SM298908,35.2705,41.6599,Dikmen,ADM2,DISTRICT
SM285387,14.0269,11.9007,Dikwa,ADM2,DISTRICT
SM260153,68.1312,32.5638,Dila,ADM2,DISTRICT
SM265630,-54.154,-29.806,Dilermano de Aguiar,ADM2,DISTRICT
SM1110,125.6667,-8.55,Dili,ADM1,DISTRICT
SM17867,43.346,9.7789,Dilla,ADM2,DISTRICT
SM229646,29.6056,12.0347,Dilling,LANGUAGE,LANGUAGE
SM250189,13,12,Dilling,ETHNICITY,ETHNICITY
SM250189,13,12,Dilling,ETHNICITY,ETHNICITY
SM250189,30,12,Dilling,ETHNICITY,ETHNICITY
SM250189,30,12,Dilling,ETHNICITY,ETHNICITY
SM301195,-158.3185,59.7619,Dillingham,ADM2,DISTRICT
SM12290,-77.9908,18.2094,Dillion Bigwoods,ADM2,DISTRICT
SM302440,-79.3789,34.3915,Dillon,ADM2,DISTRICT
SM18039,29.535,40.7797,Dilovasi,ADM2,DISTRICT
SM229650,149.605,-9.7032,Dima,LANGUAGE,LANGUAGE
SM277377,93,25.5,Dima Hasao,ADM2,DISTRICT
SM277095,93.7851,25.7785,Dimapur,ADM2,DISTRICT
SM229649,93.1806,25.4198,Dimasa,LANGUAGE,LANGUAGE
SM229652,10.9888,4.8055,Dimbong,LANGUAGE,LANGUAGE
SM229653,36.3329,6.2095,Dime,LANGUAGE,LANGUAGE
SM253185,36,6,Dime,ETHNICITY,ETHNICITY
SM229655,145.676,-4.8296,Dimir,LANGUAGE,LANGUAGE
SM262515,25.6,42.05,Dimitrovgrad,ADM2,DISTRICT
SM229657,39.6388,38.6147,Dimli,LANGUAGE,LANGUAGE
SM302854,-99.7567,28.4225,Dimmit,ADM2,DISTRICT
SM304981,44.2542,13.4386,Dimnat Khadir,ADM2,DISTRICT
SM262482,22.8167,43.75,Dimovo,ADM2,DISTRICT
SM297151,100.5624,13.7773,Din Dang,ADM2,DISTRICT
SM1111,125.5573,10.123,Dinagat Islands,ADM1:ADM2,DISTRICT
SM262276,88.75,25.6167,Dinajpur,ADM2,DISTRICT
SM298376,30.1711,38.0748,Dinar,ADM2,DISTRICT
SM277246,77.8,10.4,Dindigul,ADM2,DISTRICT
SM276976,81.1,22.8,Dindori,ADM2,DISTRICT
SM229662,19.3881,-4.443,Ding,LANGUAGE,LANGUAGE
SM458197,152.2844,-28.7378,Dinggabal,ETHNICITY,ETHNICITY
SM274980,-10.675,11.545,Dinguiraye,ADM1:ADM2,DISTRICT
SM269408,104.3862,35.0371,Dingxi,ADM2,DISTRICT
SM250191,31.43,8.33,Dinka,ETHNICITY,ETHNICITY
SM250191,31.43,8.33,Dinka,ETHNICITY,ETHNICITY
SM250191,31.43,8.33,Dinka,ETHNICITY,ETHNICITY
SM250191,31.43,8.33,Dinka,ETHNICITY,ETHNICITY
SM250191,29,9,Dinka,ETHNICITY,ETHNICITY
SM250191,29,9,Dinka,ETHNICITY,ETHNICITY
SM250191,29,9,Dinka,ETHNICITY,ETHNICITY
SM250191,29,9,Dinka,ETHNICITY,ETHNICITY
SM250191,27.4,8.7833,Dinka,ETHNICITY,ETHNICITY
SM250191,27.4,8.7833,Dinka,ETHNICITY,ETHNICITY
SM250191,27.4,8.7833,Dinka,ETHNICITY,ETHNICITY
SM250191,27.4,8.7833,Dinka,ETHNICITY,ETHNICITY
SM250191,30,8,Dinka,ETHNICITY,ETHNICITY
SM250191,30,8,Dinka,ETHNICITY,ETHNICITY
SM250191,30,8,Dinka,ETHNICITY,ETHNICITY
SM250191,30,8,Dinka,ETHNICITY,ETHNICITY
SM285580,6.8929,52.3711,Dinkelland,ADM2,DISTRICT
SM293359,39.1479,45.3741,Dinskiy rayon,ADM2,DISTRICT
SM17868,42.9761,2.4104,Dinsoor District,ADM2,DISTRICT
SM303000,-77.6324,37.0759,Dinwiddie,ADM2,DISTRICT
SM229671,150.168,-9.3865,Diodio,LANGUAGE,LANGUAGE
SM263384,-43.1909,-20.4759,Diogo de Vasconcelos,ADM2,DISTRICT
SM250332,-16.5,13.09,Diola,ETHNICITY,ETHNICITY
SM250332,-16.5,13.09,Diola,ETHNICITY,ETHNICITY
SM250332,-16.5,13.09,Diola,ETHNICITY,ETHNICITY
SM250332,-16.4,12.3,Diola,ETHNICITY,ETHNICITY
SM250332,-16.4,12.3,Diola,ETHNICITY,ETHNICITY
SM250332,-16.4,12.3,Diola,ETHNICITY,ETHNICITY
SM250332,-16.19,12.88,Diola,ETHNICITY,ETHNICITY
SM250332,-16.19,12.88,Diola,ETHNICITY,ETHNICITY
SM250332,-16.19,12.88,Diola,ETHNICITY,ETHNICITY
SM263385,-42.6882,-19.8368,Dionísio,ADM2,DISTRICT
SM266411,-53.534,-26.3334,Dionísio Cerqueira,ADM2,DISTRICT
SM268261,-51.3497,-16.2232,Diorama,ADM2,DISTRICT
SM10676,33.0295,35.2961,Diorios,ADM2,DISTRICT
SM290356,24.1716,44.1292,Diosti,ADM2,DISTRICT
SM250216,-4,9,Dioula,ETHNICITY,ETHNICITY
SM250216,-4,9,Dioula,ETHNICITY,ETHNICITY
SM250216,-4.5,9.5,Dioula,ETHNICITY,ETHNICITY
SM250216,-4.5,9.5,Dioula,ETHNICITY,ETHNICITY
SM15239,3.5953,12.6292,Dioundiou,ADM2,DISTRICT
SM295267,-16.2232,14.7783,Diourbel,ADM2,DISTRICT
SM1112,-16.1,14.7667,Diourbel,ADM1,DISTRICT
SM283358,-6.7916,12.3304,Dioïla,ADM2,DISTRICT
SM285468,-86.5176,13.7357,Dipilto,ADM2,DISTRICT
SM370497,5.9833,49.5883,Dippach,ADM1:ADM2:ADM3,DISTRICT
SM229682,9.7083,10.2083,Dir,DIALECT,LANGUAGE
SM229674,138.078,-28.7992,Dirari,DIALECT,LANGUAGE
SM229675,37.4026,5.6045,Dirasha,LANGUAGE,LANGUAGE
SM266493,-50.628,-20.4559,Dirce Reis,ADM2,DISTRICT
SM265216,-42.4342,-9.3323,Dirceu Arcoverde,ADM2,DISTRICT
SM274354,42.014,9.567,Dire Dawa,ADM2,DISTRICT
SM1114,42.014,9.567,Dire Dawa,ADM1,DISTRICT
SM229680,9.3236,11.1701,Diri,LANGUAGE,LANGUAGE
SM253188,-85.76,11.95,Diria,ETHNICITY,ETHNICITY
SM5165,-86.314,11.7842,Diriamba,ADM2,DISTRICT
SM260420,20.8333,-17.5,Dirico,ADM2,DISTRICT
SM229677,20.6678,-18.1681,Diriku,LANGUAGE,LANGUAGE
SM229678,11.05,8.36,Dirim,LANGUAGE,LANGUAGE
SM285536,-86.0421,11.8575,Diriomo,ADM2,DISTRICT
SM253189,143.08,-29.03,Dirityangura,ETHNICITY,ETHNICITY
SM253189,143.08,-29.03,Dirityangura,ETHNICITY,ETHNICITY
SM253189,141.244,-27.5133,Dirityangura,ETHNICITY,ETHNICITY
SM253189,141.244,-27.5133,Dirityangura,ETHNICITY,ETHNICITY
SM285535,-86.0736,11.8702,Diriá,ADM2,DISTRICT
SM283386,-3.313,16.2802,Diré,ADM2,DISTRICT
SM229684,19.2156,10.3218,Disa,LANGUAGE,LANGUAGE
SM12291,-77.4182,18.4373,Discovery Bay,ADM2,DISTRICT
SM273854,32.4595,26.1428,Dishna,ADM2,DISTRICT
SM260001,63.1744,29.8792,Disho,ADM2,DISTRICT
SM253190,-53.99,72.87,Disko Bay,ETHNICITY,ETHNICITY
SM10401,-72.8833,10.9,Distraccion,ADM2,DISTRICT
SM280971,-10.1859,6.2426,District # 1,ADM2,DISTRICT
SM280971,-10.1859,6.2426,District # 1,ADM2,DISTRICT
SM280971,-10.1474,6.2808,District # 1,ADM2,DISTRICT
SM280971,-10.1474,6.2808,District # 1,ADM2,DISTRICT
SM280972,-9.8677,6.4068,District # 2,ADM2,DISTRICT
SM280972,-9.8677,6.4068,District # 2,ADM2,DISTRICT
SM280972,-9.931,6.3002,District # 2,ADM2,DISTRICT
SM280972,-9.931,6.3002,District # 2,ADM2,DISTRICT
SM280973,-9.5457,6.3428,District # 3,ADM2,DISTRICT
SM280973,-9.5457,6.3428,District # 3,ADM2,DISTRICT
SM280973,-9.6413,6.233,District # 3,ADM2,DISTRICT
SM280973,-9.6413,6.233,District # 3,ADM2,DISTRICT
SM280974,-9.7329,5.9307,District # 4,ADM2,DISTRICT
SM280974,-9.7329,5.9307,District # 4,ADM2,DISTRICT
SM280974,-9.7497,5.7506,District # 4,ADM2,DISTRICT
SM280974,-9.7497,5.7506,District # 4,ADM2,DISTRICT
SM303393,-77.0414,38.9668,District of Columbia,ADM2,DISTRICT
SM1115,-77.0002,38.9171,District of Columbia,ADM1,DISTRICT
SM1116,-66.9833,10.4764,Distrito Capital (1998-),ADM1,DISTRICT
SM275936,-87.2481,14.1498,Distrito Central,ADM2,DISTRICT
SM1117,-47.75,-15.75,Distrito Federal,ADM1,DISTRICT
SM1118,-99.1333,19.2833,Distrito Federal,ADM1,DISTRICT
SM271896,-69.9418,18.4849,Distrito Nacional,ADM2,DISTRICT
SM1119,-69.9333,18.4833,Distrito Nacional (2000-),ADM1,DISTRICT
SM15037,36.127,-17.0597,Distrito de Derre,ADM2,DISTRICT
SM15038,34.5328,-16.5831,Distrito de Doa,ADM2,DISTRICT
SM15039,39.5866,-16.4796,Distrito de Larde,ADM2,DISTRICT
SM15040,39.946,-15.6087,Distrito de Liupo,ADM2,DISTRICT
SM15041,36.1239,-18.3081,Distrito de Luabo,ADM2,DISTRICT
SM15042,33.569,-19.3892,Distrito de Macate,ADM2,DISTRICT
SM15043,33.1839,-16.038,Distrito de Marara,ADM2,DISTRICT
SM15044,37.6902,-16.874,Distrito de Mocubela,ADM2,DISTRICT
SM15045,36.177,-15.6648,Distrito de Molumbo,ADM2,DISTRICT
SM15046,39.2125,-16.3124,Distrito de Moma (2013-),ADM2,DISTRICT
SM15315,-81.053,7.9532,Distrito de Montijo (2001-),ADM2,DISTRICT
SM15047,37.6193,-16.3699,Distrito de Mulevala,ADM2,DISTRICT
SM15048,39.094,-15.1284,Distrito de Rapale (excl. Nampula district),ADM2,DISTRICT
SM15049,33.2635,-18.8357,Distrito de Vanduzi,ADM2,DISTRICT
SM273835,30.7161,31.1459,Disuq,ADM2,DISTRICT
SM229687,1.1805,10.1614,Ditammari,LANGUAGE,LANGUAGE
SM229690,-124.75,48.67,Ditidaht,LANGUAGE,LANGUAGE
SM290687,25.5274,46.8456,Ditrau,ADM2,DISTRICT
SM277471,70.9399,20.7208,Diu,ADM2,DISTRICT
SM229692,139.455,-5.1263,Diuwe,LANGUAGE,LANGUAGE
SM229693,-97.3861,17.3596,Diuxi-Tilantongo Mixtec,LANGUAGE,LANGUAGE
SM296293,14.0225,45.6844,Divaca,ADM1:ADM2,DISTRICT
SM277549,47,35.9167,Divandarreh,ADM2,DISTRICT
SM293757,43.4167,55.0833,Diveevskiy rayon,ADM2,DISTRICT
SM302033,-103.4874,48.8149,Divide,ADM2,DISTRICT
SM267085,-37.1682,-10.6794,Divina Pastora,ADM2,DISTRICT
SM263388,-42.1755,-20.59,Divino,ADM2,DISTRICT
SM263387,-41.4916,-18.7124,Divino das Laranjeiras,ADM2,DISTRICT
SM267978,-41.7219,-20.5975,Divino de São Lourenço,ADM2,DISTRICT
SM263389,-42.5707,-18.7756,Divinolandia de Minas,ADM2,DISTRICT
SM266494,-46.7045,-21.6646,Divinolândia,ADM2,DISTRICT
SM9919,-49.3874,-9.6573,Divinopolis Do Tocantins,ADM2:ADM3,DISTRICT
SM263386,-42.9951,-20.9947,Divinésia,ADM2,DISTRICT
SM263390,-44.9162,-20.1169,Divinópolis,ADM2,DISTRICT
SM268262,-46.527,-13.2238,Divinópolis de Goiás,ADM2,DISTRICT
SM263392,-41.3911,-15.6945,Divisa Alegre,ADM2,DISTRICT
SM263393,-46.2442,-21.5238,Divisa Nova,ADM2,DISTRICT
SM282564,-109.3922,29.6173,Divisaderos,ADM2,DISTRICT
SM268782,-97.3863,49.8697,Division No. 11,ADM2,DISTRICT
SM263394,-40.9239,-15.7674,Divisópolis,ADM2,DISTRICT
SM298932,38.1214,39.3792,Divriği,ADM2,DISTRICT
SM269832,11.9805,-2.6397,Divénié,ADM2,DISTRICT
SM300318,-83.187,29.5812,Dixie,ADM2,DISTRICT
SM10283,15.0661,12.1824,Dixieme Arrondissement,ADM2,DISTRICT
SM301696,-96.8678,42.4932,Dixon,ADM2,DISTRICT
SM229696,167.516,-16.3267,Dixon Reef,LANGUAGE,LANGUAGE
SM298484,43.6749,39.5488,Diyadin,ADM2,DISTRICT
SM1120,45,34,Diyala,ADM1:ADM2,DISTRICT
SM273742,31.4604,30.7579,Diyarb Najm,ADM2,DISTRICT
SM1121,40.2319,37.9615,Diyarbakir,ADM1,DISTRICT
SM253194,138.8,-29.7,Diyari,ETHNICITY,ETHNICITY
SM253194,138.8,-29.7,Diyari,ETHNICITY,ETHNICITY
SM253194,138.8,-29.7,Diyari,ETHNICITY,ETHNICITY
SM253194,138.8,-29.7,Diyari,ETHNICITY,ETHNICITY
SM253194,138,-28,Diyari,ETHNICITY,ETHNICITY
SM253194,138,-28,Diyari,ETHNICITY,ETHNICITY
SM253194,138,-28,Diyari,ETHNICITY,ETHNICITY
SM253194,138,-28,Diyari,ETHNICITY,ETHNICITY
SM253194,139.09,-28.54,Diyari,ETHNICITY,ETHNICITY
SM253194,139.09,-28.54,Diyari,ETHNICITY,ETHNICITY
SM253194,139.09,-28.54,Diyari,ETHNICITY,ETHNICITY
SM253194,139.09,-28.54,Diyari,ETHNICITY,ETHNICITY
SM253194,139.0584,-28.2281,Diyari,ETHNICITY,ETHNICITY
SM253194,139.0584,-28.2281,Diyari,ETHNICITY,ETHNICITY
SM253194,139.0584,-28.2281,Diyari,ETHNICITY,ETHNICITY
SM253194,139.0584,-28.2281,Diyari,ETHNICITY,ETHNICITY
SM253195,34.93,6.71,Dizi,ETHNICITY,ETHNICITY
SM229699,35.5763,6.1405,Dizin,LANGUAGE,LANGUAGE
SM283347,-9.25,14.583,Diéma,ADM2,DISTRICT
SM304214,109.0389,12.2734,Diên Khánh,ADM2,DISTRICT
SM304333,105.5763,18.9889,Diễn Châu,ADM2,DISTRICT
SM459052,143.6749,-36.7956,Dja Dja Wurrung,ETHNICITY,ETHNICITY
SM269501,12.9167,2.75,Dja et Lobo,ADM2,DISTRICT
SM459181,145.5085,-16.7372,Djabugay / Tjapukai^,ETHNICITY,ETHNICITY
SM253196,141.38,-35.86,Djabwurrung,ETHNICITY,ETHNICITY
SM253196,141.38,-35.86,Djabwurrung,ETHNICITY,ETHNICITY
SM253196,142.3366,-37.7742,Djabwurrung,ETHNICITY,ETHNICITY
SM253196,142.3366,-37.7742,Djabwurrung,ETHNICITY,ETHNICITY
SM459043,141.6828,-35.5605,Djadjala,ETHNICITY,ETHNICITY
SM253197,18.52,16.26,Djagada,ETHNICITY,ETHNICITY
SM459416,142.5203,-10.8163,Djagaraga,ETHNICITY,ETHNICITY
SM458207,150.8351,-26.0503,Djagunda,ETHNICITY,ETHNICITY
SM262168,1.7167,6.9,Djakotomey,ADM2,DISTRICT
SM253198,114.8,-23.04,Djalendi,ETHNICITY,ETHNICITY
SM253198,114.8,-23.04,Djalendi,ETHNICITY,ETHNICITY
SM253198,114.9409,-22.5229,Djalendi,ETHNICITY,ETHNICITY
SM253198,114.9409,-22.5229,Djalendi,ETHNICITY,ETHNICITY
SM458051,122.9731,-27.6457,Djalgandi,ETHNICITY,ETHNICITY
SM16302,14.7795,-2.5901,Djambala (1994-),ADM2,DISTRICT
SM229709,135.59,-12.021,Djambarrpuyngu,LANGUAGE,LANGUAGE
SM457464,9,25,Djanet District,ADM2,DISTRICT
SM458844,136.4213,-12.1474,Djangu,ETHNICITY,ETHNICITY
SM229712,144.9325,-16.9105,Djangun,LANGUAGE,LANGUAGE
SM229713,136.418,-12.8798,Djapu,DIALECT,LANGUAGE
SM253200,125.52,-18.07,Djara,ETHNICITY,ETHNICITY
SM253200,125.52,-18.07,Djara,ETHNICITY,ETHNICITY
SM253200,125.52,-18.07,Djara,ETHNICITY,ETHNICITY
SM253200,130,-19.16,Djara,ETHNICITY,ETHNICITY
SM253200,130,-19.16,Djara,ETHNICITY,ETHNICITY
SM253200,130,-19.16,Djara,ETHNICITY,ETHNICITY
SM253200,128.2276,-18.5173,Djara,ETHNICITY,ETHNICITY
SM253200,128.2276,-18.5173,Djara,ETHNICITY,ETHNICITY
SM253200,128.2276,-18.5173,Djara,ETHNICITY,ETHNICITY
SM459088,143.2568,-38.1754,Djargurd Wurrong,ETHNICITY,ETHNICITY
SM253201,123.7,-22.91,Djarugudi,ETHNICITY,ETHNICITY
SM253201,123.7,-22.91,Djarugudi,ETHNICITY,ETHNICITY
SM253201,121.0457,-23.0694,Djarugudi,ETHNICITY,ETHNICITY
SM253201,121.0457,-23.0694,Djarugudi,ETHNICITY,ETHNICITY
SM229717,123.186,-16.4163,Djawi,LANGUAGE,LANGUAGE
SM229718,134.097,-12.0133,Djeebbana,LANGUAGE,LANGUAGE
SM1122,3.6667,34.3333,Djelfa,ADM1,DISTRICT
SM457465,3.5,34.25,Djelfa District,ADM2,DISTRICT
SM268585,25.3372,6.7821,Djemah,ADM2,DISTRICT
SM283366,-4.595,13.916,Djenné,ADM2,DISTRICT
SM229722,-64.6694,-12.038,Djeoromitxí,LANGUAGE,LANGUAGE
SM297900,10.8238,33.7659,Djerba Ajim,ADM2,DISTRICT
SM297901,10.9522,33.7792,Djerba Midoun,ADM2,DISTRICT
SM269496,12.697,6.4811,Djerem,ADM2,DISTRICT
SM250831,2.33,14.83,Djerma,ETHNICITY,ETHNICITY
SM250831,2.33,14.83,Djerma,ETHNICITY,ETHNICITY
SM250831,3,13,Djerma,ETHNICITY,ETHNICITY
SM250831,3,13,Djerma,ETHNICITY,ETHNICITY
SM280344,78.0017,42.3396,Djety-Oguz,ADM2,DISTRICT
SM4659,10.7937,1.6092,Djibloho,ADM2,DISTRICT
SM4671,43.06,11.56,Djibouti (2003-),ADM1,DISTRICT
SM262127,1.938,7.431,Djidja,ADM2,DISTRICT
SM284013,-8.6654,15.7549,Djiguenni,ADM2,DISTRICT
SM229725,-4.4563,8.3628,Djimini Senoufo,LANGUAGE,LANGUAGE
SM229729,134.82,-12.462,Djinang,LANGUAGE,LANGUAGE
SM229728,134.967,-12.93,Djinba,LANGUAGE,LANGUAGE
SM253203,134.82,-13.05,Djinba,ETHNICITY,ETHNICITY
SM253203,134.82,-13.05,Djinba,ETHNICITY,ETHNICITY
SM253203,134.82,-13.05,Djinba,ETHNICITY,ETHNICITY
SM253203,135.2,-12.31,Djinba,ETHNICITY,ETHNICITY
SM253203,135.2,-12.31,Djinba,ETHNICITY,ETHNICITY
SM253203,135.2,-12.31,Djinba,ETHNICITY,ETHNICITY
SM253203,135.0046,-12.7619,Djinba,ETHNICITY,ETHNICITY
SM253203,135.0046,-12.7619,Djinba,ETHNICITY,ETHNICITY
SM253203,135.0046,-12.7619,Djinba,ETHNICITY,ETHNICITY
SM459199,146.0521,-17.9072,Djiru,ETHNICITY,ETHNICITY
SM253204,115.79,-24.95,Djiwali,ETHNICITY,ETHNICITY
SM253204,115.79,-24.95,Djiwali,ETHNICITY,ETHNICITY
SM253204,115.9009,-23.4973,Djiwali,ETHNICITY,ETHNICITY
SM253204,115.9009,-23.4973,Djiwali,ETHNICITY,ETHNICITY
SM229731,116.9886,-24.1072,Djiwarli,LANGUAGE,LANGUAGE
SM229730,116.9886,-24.1072,Djiwarli-Thiin,FAMILY,LANGUAGE
SM10284,18.0295,9.3226,Djoli,ADM2,DISTRICT
SM4308,14.2667,-0.8007,Djoue,ADM2,DISTRICT
SM262165,1.896,9.643,Djougou,ADM2,DISTRICT
SM4309,13.9756,-1.4872,Djouori-Agnili,ADM2,DISTRICT
SM296816,20.5569,12.6438,Djourf Al Ahmar,ADM2,DISTRICT
SM296811,21.239,12.9037,Djourf Al Ahmar,ADM2,DISTRICT
SM229737,122.2818,-20.3424,Djuwarliny,DIALECT,LANGUAGE
SM277751,-14.6667,64.6333,Djúpavogshreppur,ADM2,DISTRICT
SM11300,44.2,41.32,Dmanisi,ADM2,DISTRICT
SM11301,44.1333,41.35,Dmanisis Munitsip'alit'et'I (excl. Dmanisi),ADM2,DISTRICT
SM293521,35,52.1667,Dmitrievskiy rayon,ADM2,DISTRICT
SM293694,37.5833,56.35,Dmitrovskiy rayon,ADM2,DISTRICT
SM1124,35.0305,48.4668,Dnipropetrovs'k,ADM1,DISTRICT
SM299924,35.0397,48.4671,Dnipropetrovs'ka,ADM2,DISTRICT
SM299928,35.0833,48.5308,Dnipropetrovs'kyi,ADM2,DISTRICT
SM299471,30.6319,50.4676,Dniprovs'kyi,ADM2,DISTRICT
SM11406,26.8665,48.5015,Dnistrovskyi Raion,ADM2,DISTRICT
SM303491,68.0833,40.5833,Do'stlik,ADM2,DISTRICT
SM291661,22.6838,47.7459,Doba,ADM2,DISTRICT
SM427780,16.9429,8.6505,Doba,ADM2:ADM3,DISTRICT
SM292560,27.0761,47.8329,Dobarceni,ADM2,DISTRICT
SM290213,25.8811,45.7341,Dobarlau,ADM2,DISTRICT
SM229741,134.638,-6.3253,Dobel,LANGUAGE,LANGUAGE
SM13488,23.282,56.6239,Dobele,ADM2,DISTRICT
SM4059,23.1667,56.5833,Dobeles novads,ADM1,DISTRICT
SM13489,23.2566,56.6817,Dobeles pagasts,ADM2,DISTRICT
SM296235,15.4014,46.1332,Dobje,ADM1:ADM2,DISTRICT
SM9861,18.065,44.7594,Doboj (2014-),ADM2,DISTRICT
SM280611,127.0301,37.6507,Dobong,ADM2,DISTRICT
SM290755,22.5882,45.8865,Dobra,ADM2,DISTRICT
SM290260,25.713,44.7959,Dobra,ADM2,DISTRICT
SM266495,-48.3703,-21.5153,Dobrada,ADM2,DISTRICT
SM296317,14.7483,45.8044,Dobrepolje,ADM1:ADM2,DISTRICT
SM290357,23.9534,43.9677,Dobresti,ADM2,DISTRICT
SM292426,22.2693,46.8447,Dobresti,ADM2,DISTRICT
SM291244,25.1191,44.9499,Dobresti,ADM2,DISTRICT
SM291407,23.953,44.4916,Dobretu,ADM2,DISTRICT
SM262503,27.8063,43.5776,Dobrich,ADM2,DISTRICT
SM1125,27.9,43.6833,Dobrich,ADM1,DISTRICT
SM262504,27.7333,43.6333,Dobrich-selska,ADM2,DISTRICT
SM291602,23.1137,47.3081,Dobrin,ADM2,DISTRICT
SM305432,14.605,45.1295,Dobrinj,ADM2,DISTRICT
SM293578,40.5,52.1667,Dobrinskiy rayon,ADM2,DISTRICT
SM296236,15.226,46.3544,Dobrna,ADM1:ADM2,DISTRICT
SM290955,26.1837,44.4546,Dobroesti,ADM2,DISTRICT
SM290162,27.8389,44.0181,Dobromir,ADM2,DISTRICT
SM299960,37.0846,48.469,Dobropil's'ka,ADM2,DISTRICT
SM299970,37.1667,48.4167,Dobropil's'kyi,ADM2,DISTRICT
SM291408,24.3656,44.1736,Dobrosloveni,ADM2,DISTRICT
SM291409,24.3281,44.7711,Dobroteasa,ADM2,DISTRICT
SM291883,24.881,44.2647,Dobrotesti,ADM2,DISTRICT
SM296318,14.3088,46.0635,Dobrova-Polhov Gradec,ADM1:ADM2,DISTRICT
SM290819,27.7225,46.9634,Dobrovat,ADM2,DISTRICT
SM299519,31.179,48.3892,Dobrovelychkivs'kyi,ADM2,DISTRICT
SM296378,16.3519,46.6506,Dobrovnik,ADM1:ADM2,DISTRICT
SM293579,39.8333,52.8333,Dobrovskiy rayon,ADM2,DISTRICT
SM9760,31.4167,52.3333,Dobrushski Rayon (excl. Dobrush,ADM2,DISTRICT
SM294036,56.5,58.5,Dobryanskiy rayon,ADM2,DISTRICT
SM229748,150.825,-9.6324,Dobu,LANGUAGE,LANGUAGE
SM253211,150.84,-9.68,Dobu,ETHNICITY,ETHNICITY
SM253211,150.84,-9.68,Dobu,ETHNICITY,ETHNICITY
SM253211,151,-10,Dobu,ETHNICITY,ETHNICITY
SM253211,151,-10,Dobu,ETHNICITY,ETHNICITY
SM17348,-60.956,13.742,Docamel/La Resource,ADM2,DISTRICT
SM290010,21.6462,45.3237,Doclin,ADM2,DISTRICT
SM282155,-100.2875,23.7974,Doctor Arroyo,ADM2,DISTRICT
SM289557,-56.3638,-25.6009,Doctor Botrell,ADM2,DISTRICT
SM289693,-56.2309,-25.0891,Doctor Cecilio Báez,ADM2,DISTRICT
SM282156,-99.0487,25.9663,Doctor Coss,ADM2,DISTRICT
SM282157,-99.8313,25.8408,Doctor González,ADM2,DISTRICT
SM289694,-55.5941,-25.4029,Doctor J. Eulogio Estigarribia,ADM2,DISTRICT
SM289664,-55.2545,-25.4838,Doctor Juan León Mallorquín,ADM2,DISTRICT
SM289695,-55.8216,-25.3621,Doctor Juan Manuel Frutos,ADM2,DISTRICT
SM289701,-56.5946,-26.3818,Doctor Moisés S. Bertoni,ADM2,DISTRICT
SM281537,-100.3595,21.1385,Doctor Mora,ADM2,DISTRICT
SM276894,75.6497,33.1008,Doda (2006-),ADM2,DISTRICT
SM303162,-80.707,39.2692,Doddridge,ADM2,DISTRICT
SM11453,27.1481,36.8433,Dodecanese,ADM2,DISTRICT
SM303226,-88.7075,43.4163,Dodge,ADM2,DISTRICT
SM301697,-96.654,41.5779,Dodge,ADM2,DISTRICT
SM301350,-92.8621,44.0226,Dodge,ADM2,DISTRICT
SM300479,-83.1684,32.1722,Dodge,ADM2,DISTRICT
SM10286,15.53,8.7,Dodje (2008-),ADM2,DISTRICT
SM1126,35.9167,-5.9167,Dodoma,ADM1,DISTRICT
SM299210,35.798,-6.1597,Dodoma Urban,ADM2,DISTRICT
SM299304,34,3.5833,Dodoth,ADM1:ADM2,DISTRICT
SM253212,34.77,4.1,Dodoth,ETHNICITY,ETHNICITY
SM298294,34.8145,40.8632,Dodurga,ADM2,DISTRICT
SM229753,38.4902,-6.2584,Doe,LANGUAGE,LANGUAGE
SM14110,-8.8416,6.6074,Doe District,ADM2,DISTRICT
SM14110,-8.8416,6.6074,Doe District,ADM2,DISTRICT
SM14110,-8.79,6.45,Doe District,ADM2,DISTRICT
SM14110,-8.79,6.45,Doe District,ADM2,DISTRICT
SM297611,100.0467,14.8739,Doembang Nangbua,ADM2,DISTRICT
SM18040,36.2277,36.8342,Doertyol Ilcesi,ADM2,DISTRICT
SM285763,6.1486,52.0194,Doesburg,ADM2,DISTRICT
SM18041,30.5904,37.0237,Doesemealti Ilcesi,ADM2,DISTRICT
SM292102,26.5308,46.3228,Dofteana,ADM2,DISTRICT
SM229756,149.841,-9.637,Doga,LANGUAGE,LANGUAGE
SM262169,1.7841,6.7993,Dogbo,ADM2,DISTRICT
SM229759,148.766,-9.1369,Doghoro,LANGUAGE,LANGUAGE
SM276548,135.4395,-4.0319,Dogiyai,ADM2,DISTRICT
SM290011,21.7257,45.248,Dognecea,ADM2,DISTRICT
SM250209,-1,13.52,Dogon,ETHNICITY,ETHNICITY
SM250209,-1,13.52,Dogon,ETHNICITY,ETHNICITY
SM250209,-1,13.52,Dogon,ETHNICITY,ETHNICITY
SM250209,-3,14,Dogon,ETHNICITY,ETHNICITY
SM250209,-3,14,Dogon,ETHNICITY,ETHNICITY
SM250209,-3,14,Dogon,ETHNICITY,ETHNICITY
SM250209,-3,15,Dogon,ETHNICITY,ETHNICITY
SM250209,-3,15,Dogon,ETHNICITY,ETHNICITY
SM250209,-3,15,Dogon,ETHNICITY,ETHNICITY
SM284603,4,13.5,Dogon-Doutchi,ADM2,DISTRICT
SM253215,15.51,13.74,Dogorda,ETHNICITY,ETHNICITY
SM250208,-3,11,Dogose,ETHNICITY,ETHNICITY
SM250208,-3,11,Dogose,ETHNICITY,ETHNICITY
SM250208,-4,11,Dogose,ETHNICITY,ETHNICITY
SM250208,-4,11,Dogose,ETHNICITY,ETHNICITY
SM229761,-4.3581,10.0489,Dogoso,LANGUAGE,LANGUAGE
SM229762,-4.1493,10.1899,Dogosé,LANGUAGE,LANGUAGE
SM229768,75.7111,32.5534,Dogri,LANGUAGE,LANGUAGE
SM229770,-114.193,62.3823,Dogrib,LANGUAGE,LANGUAGE
SM229771,-3.5686,14.5872,Dogul Dom Dogon,LANGUAGE,LANGUAGE
SM284855,8.6639,10.8796,Doguwa,ADM2,DISTRICT
SM296890,99.2127,18.9237,Doi Saket,ADM2,DISTRICT
SM296891,98.6629,17.897,Doi Tao,ADM2,DISTRICT
SM290261,25.4007,44.9914,Doicesti,ADM2,DISTRICT
SM266496,-48.3372,-22.3728,Dois Córregos,ADM2,DISTRICT
SM9920,-49.1557,-9.2907,Dois Irmaos Do Tocantins,ADM2:ADM3,DISTRICT
SM265632,-51.0933,-29.6116,Dois Irmãos,ADM2,DISTRICT
SM265631,-53.5148,-27.6812,Dois Irmãos das Missões,ADM2,DISTRICT
SM263044,-55.3684,-20.5719,Dois Irmãos do Buriti,ADM2,DISTRICT
SM265633,-51.8497,-28.9709,Dois Lajeados,ADM2,DISTRICT
SM265358,-37.0657,-9.3582,Dois Riachos,ADM2,DISTRICT
SM264446,-53.0913,-25.7458,Dois Vizinhos,ADM2,DISTRICT
SM297331,100.0566,19.1081,Dok Kham Tai,ADM2,DISTRICT
SM229776,7.961,10.2287,Doka,LANGUAGE,LANGUAGE
SM250210,21.13,1.6,Doko,ETHNICITY,ETHNICITY
SM250210,21.13,1.6,Doko,ETHNICITY,ETHNICITY
SM250210,21,2,Doko,ETHNICITY,ETHNICITY
SM250210,21,2,Doko,ETHNICITY,ETHNICITY
SM229779,8.2693,5.3996,Doko-Uyanga,LANGUAGE,LANGUAGE
SM299321,33.0614,1.9361,Dokolo,ADM1:ADM2,DISTRICT
SM262681,27.9167,54.8333,Dokshytsy,ADM2,DISTRICT
SM289124,128.1667,40.4167,Doksong,ADM2,DISTRICT
SM18201,37.6785,47.7542,Dokuchaevsk City Council,ADM2,DISTRICT
SM16885,47.9167,42.3333,Dokuzparinskiy Rayon,ADM2,DISTRICT
SM296319,14.6736,46.0975,Dol pri Ljubljani,ADM1:ADM2,DISTRICT
SM274559,11.362,-2.349,Dola,ADM2,DISTRICT
SM375303,86.2217,27.8241,Dolakha,ADM2:ADM3,DISTRICT
SM286687,-82.45,8.6167,Dolega,ADM2,DISTRICT
SM296267,15.0497,45.715,Dolenjske Toplice,ADM1:ADM2,DISTRICT
SM229781,94.2936,71.1083,Dolgan,LANGUAGE,LANGUAGE
SM253219,135.01,66.74,Dolgan,ETHNICITY,ETHNICITY
SM253219,101.03,69.71,Dolgan,ETHNICITY,ETHNICITY
SM293580,38.3333,52.3333,Dolgorukovskiy rayon,ADM2,DISTRICT
SM291784,26.6108,47.4179,Dolhasca,ADM2,DISTRICT
SM290820,27.9065,46.8696,Dolhesti,ADM2,DISTRICT
SM291785,26.5238,47.4372,Dolhestii-mari,ADM2,DISTRICT
SM1127,23.75,44.1667,Dolj,ADM1,DISTRICT
SM291328,26.9756,47.0281,Doljesti,ADM2,DISTRICT
SM262434,23.7667,42.3,Dolna Banya,ADM2,DISTRICT
SM262334,24.4167,43.5833,Dolna Mitropoliya,ADM2,DISTRICT
SM1128,21.4333,41.4833,Dolneni,ADM1,DISTRICT
SM262463,27.6833,42.9667,Dolni Chiflik,ADM2,DISTRICT
SM262335,24.4167,43.3667,Dolni Dabnik,ADM2,DISTRICT
SM1129,16.4333,51.1,Dolnośląskie,ADM1,DISTRICT
SM296185,19.3,49.2,Dolný Kubín,ADM2,DISTRICT
SM300122,-58.2931,-33.5699,Dolores,ADM2,DISTRICT
SM303327,-108.5172,37.7516,Dolores,ADM2,DISTRICT
SM285503,-86.2184,11.8545,Dolores,ADM2,DISTRICT
SM275879,-88.8309,14.9015,Dolores,ADM2,DISTRICT
SM275669,-88.3579,14.2619,Dolores,ADM2,DISTRICT
SM295598,-88.5461,13.7963,Dolores,ADM2,DISTRICT
SM270557,-74.8059,3.6053,Dolores,ADM2,DISTRICT
SM260753,-57.6667,-36.4167,Dolores,ADM2,DISTRICT
SM281538,-100.8969,21.1623,Dolores Hidalgo,ADM2,DISTRICT
SM275735,-89.1249,14.551,Dolores Merendon,ADM2,DISTRICT
SM295829,42.1408,4.0025,Dolow,ADM2,DISTRICT
SM375342,83.0369,29.2142,Dolpa,ADM2:ADM3,DISTRICT
SM229787,83.2927,29.1957,Dolpo,LANGUAGE,LANGUAGE
SM299522,32.7695,48.116,Dolyns'kyi,ADM2,DISTRICT
SM299983,23.966,48.9713,Dolyns'kyi,ADM2,DISTRICT
SM229801,144.895,-6.0374,Dom,LANGUAGE,LANGUAGE
SM253220,144.7,-6.92,Dom,ETHNICITY,ETHNICITY
SM17960,125.5247,-8.5467,Dom Aleixo,ADM2,DISTRICT
SM263157,-54.8304,-15.721,Dom Aquino,ADM2,DISTRICT
SM267389,-41.7088,-13.7972,Dom Basílio,ADM2,DISTRICT
SM263395,-46.2846,-16.8113,Dom Bosco,ADM2,DISTRICT
SM263396,-42.0936,-19.3884,Dom Cavati,ADM2,DISTRICT
SM264163,-47.8905,-4.0827,Dom Eliseu,ADM2,DISTRICT
SM265217,-41.7163,-6.9581,Dom Expedito Lopes,ADM2,DISTRICT
SM265634,-52.1932,-30.6139,Dom Feliciano,ADM2,DISTRICT
SM265218,-41.72,-8.8848,Dom Inocêncio,ADM2,DISTRICT
SM263397,-43.266,-18.9293,Dom Joaquim,ADM2,DISTRICT
SM267390,-39.1462,-12.9338,Dom Macedo Costa,ADM2,DISTRICT
SM265635,-54.6062,-30.9962,Dom Pedrito,ADM2,DISTRICT
SM262984,-44.4218,-4.9931,Dom Pedro,ADM2,DISTRICT
SM265636,-49.8729,-29.3568,Dom Pedro de Alcântara,ADM2,DISTRICT
SM263398,-42.9465,-20.1273,Dom Silvério,ADM2,DISTRICT
SM263399,-45.1488,-22.2319,Dom Viçoso,ADM2,DISTRICT
SM285015,8.3047,8.2032,Doma,ADM2,DISTRICT
SM229790,74.7984,36.3258,Domaaki,LANGUAGE,LANGUAGE
SM1130,12.4833,43.9542,Domagnano,ADM1,DISTRICT
SM299603,30.9866,47.6281,Domanivs'kyi,ADM2,DISTRICT
SM298704,29.6221,39.8094,Domaniç,ADM2,DISTRICT
SM229788,35,32,Domari,LANGUAGE,LANGUAGE
SM290013,22.3369,45.0758,Domasnea,ADM2,DISTRICT
SM305356,16.5973,46.4362,Domašinec,ADM2,DISTRICT
SM271154,12.9167,49.5,Domažlice,ADM2,DISTRICT
SM225663,132.983,-2.0802,Dombano,LANGUAGE,LANGUAGE
SM296058,-55.0892,5.6804,Domburg,ADM2,DISTRICT
SM276186,18.1908,46.4559,Dombóvár,ADM2,DISTRICT
SM282402,-98.44,19.1329,Domingo Arenas,ADM2,DISTRICT
SM289665,-54.6902,-25.8582,Domingo Martínez de Irala,ADM2,DISTRICT
SM15669,-66.6885,18.4355,Domingo Ruiz Barrio,ADM2,DISTRICT
SM267979,-40.8272,-20.3233,Domingos Martins,ADM2,DISTRICT
SM265219,-41.3483,-4.1914,Domingos Mourão,ADM2,DISTRICT
SM15670,-66.7475,18.4068,Dominguito Barrio,ADM2,DISTRICT
SM229798,-71.1722,18.8798,Dominican Sign Language,LANGUAGE,LANGUAGE
SM290956,25.8949,44.3906,Domnesti,ADM2,DISTRICT
SM291245,24.8395,45.2056,Domnesti,ADM2,DISTRICT
SM293711,37.75,55.4167,Domodedovskiy rayon,ADM2,DISTRICT
SM229804,-2.295,8.182,Dompo,LANGUAGE,LANGUAGE
SM276484,118.4816,-8.5094,Dompu,ADM2,DISTRICT
SM229806,148.45,-10.151,Domu,LANGUAGE,LANGUAGE
SM253229,149.9,-10.13,Domu,ETHNICITY,ETHNICITY
SM229807,146.682,-5.8186,Domung,LANGUAGE,LANGUAGE
SM296320,14.6228,46.1426,Domžale,ADM1:ADM2,DISTRICT
SM15671,-66.6376,18.3061,Don Alonso Barrio,ADM2,DISTRICT
SM297612,99.9473,14.648,Don Chedi,ADM2,DISTRICT
SM270206,-75.3665,6.5056,Don Matías,ADM2,DISTRICT
SM297152,100.5937,13.926,Don Muang,ADM2,DISTRICT
SM297540,100.6158,14.6076,Don Phut,ADM2,DISTRICT
SM297633,99.6889,9.2106,Don Sak,ADM2,DISTRICT
SM297082,104.8223,16.288,Don Tan,ADM2,DISTRICT
SM297094,100.09,13.945,Don Tum,ADM2,DISTRICT
SM301865,-106.8328,32.3527,Dona Ana,ADM2,DISTRICT
SM15672,-66.2229,18.2525,Dona Elena Barrio,ADM2,DISTRICT
SM266412,-49.8108,-26.9769,Dona Emma,ADM2,DISTRICT
SM263400,-42.8083,-21.3234,Dona Eusébia,ADM2,DISTRICT
SM265637,-53.3396,-29.5868,Dona Francisca,ADM2,DISTRICT
SM264417,-35.6055,-6.601,Dona Inês,ADM2,DISTRICT
SM323924,-112.5757,52.5842,Donalda,ADM2:ADM3,DISTRICT
SM281912,-100.1236,19.3171,Donato Guerra,ADM2,DISTRICT
SM274622,-1.0833,53.5,Doncaster,ADM1:ADM2,DISTRICT
SM229811,120.707,0.8035,Dondo,LANGUAGE,LANGUAGE
SM15050,34.799,-19.522,Dondo District (excl. Beira),ADM2,DISTRICT
SM1131,27.6862,48.2677,Donduseni,ADM1,DISTRICT
SM1132,-8,54.9167,Donegal,ADM1:ADM2,DISTRICT
SM1133,37.6667,48,Donets'k,ADM1,DISTRICT
SM18202,37.837,47.9438,Donetska Miskrada,ADM2,DISTRICT
SM18203,38.1754,47.8823,Donetskyi Raion (2020-),ADM2,DISTRICT
SM229822,11.9924,8.6412,Dong,LANGUAGE,LANGUAGE
SM280700,126.9232,35.1459,Dong,ADM2,DISTRICT
SM280677,127.4431,36.3294,Dong,ADM2,DISTRICT
SM280564,126.6369,37.4838,Dong,ADM2,DISTRICT
SM280637,129.4186,35.5047,Dong,ADM2,DISTRICT
SM280671,128.633,35.8857,Dong,ADM2,DISTRICT
SM280535,129.0343,35.1247,Dong,ADM2,DISTRICT
SM253230,110.44,22.94,Dong,ETHNICITY,ETHNICITY
SM297083,104.3556,16.7965,Dong Luang,ADM2,DISTRICT
SM280622,127.0541,37.5819,Dong-daemun,ADM2,DISTRICT
SM285251,10.2133,7.5909,Donga,ADM2,DISTRICT
SM1134,1.7833,9.3333,Donga,ADM1,DISTRICT
SM228113,11.4195,6.4383,Donga Mambila,LANGUAGE,LANGUAGE
SM269531,10.8333,6.6667,Donga Mantung,ADM2,DISTRICT
SM280728,127.069,37.9189,Dongducheon,ADM2,DISTRICT
SM285910,4.9536,51.6375,Dongen,ADM2,DISTRICT
SM276599,119.85,-0.5833,Donggala,ADM2,DISTRICT
SM269427,113.9127,22.9442,Dongguan,ADM2,DISTRICT
SM280692,129.1136,37.5234,Donghae,ADM2,DISTRICT
SM280629,126.9515,37.5006,Dongjak,ADM2,DISTRICT
SM280536,129.078,35.2045,Dongnae,ADM2,DISTRICT
SM229819,29.9619,2.9668,Dongo,LANGUAGE,LANGUAGE
SM253231,29.64,2.3,Dongo,ETHNICITY,ETHNICITY
SM229825,24.6,10,Dongo (Sudan),LANGUAGE,LANGUAGE
SM229817,30.75,18.25,Dongola,DIALECT,LANGUAGE
SM229823,33.2429,4.4343,Dongotono,LANGUAGE,LANGUAGE
SM16303,17.9174,2.1672,Dongou,ADM2,DISTRICT
SM289206,124.7992,39.8825,Dongrim,ADM2,DISTRICT
SM229815,100.178,25.435,Dongshanba Lalo,LANGUAGE,LANGUAGE
SM229814,103.558,35.5808,Dongxiang,LANGUAGE,LANGUAGE
SM269301,118.6214,37.6206,Dongying,ADM2,DISTRICT
SM300903,-95.1468,39.7881,Doniphan,ADM2,DISTRICT
SM305357,16.8053,46.3152,Donja Dubrava,ADM2,DISTRICT
SM305363,18.0189,45.4955,Donja Motičina,ADM2,DISTRICT
SM276065,15.9707,45.9796,Donja Stubica,ADM2,DISTRICT
SM305520,16.0739,46.3162,Donja Voća,ADM2,DISTRICT
SM305583,18.2977,45.1887,Donji Andrijevci,ADM2,DISTRICT
SM305358,16.65,46.3658,Donji Kraljevec,ADM2,DISTRICT
SM305447,16.4839,45.2651,Donji Kukuruzari,ADM2,DISTRICT
SM305754,15.96,44.5539,Donji Lapac,ADM2,DISTRICT
SM305521,16.541,46.2638,Donji Martijanec,ADM2,DISTRICT
SM275956,18.1667,45.75,Donji Miholjac,ADM2,DISTRICT
SM305359,16.7821,46.3279,Donji Vidovec,ADM2,DISTRICT
SM313208,18.6464,44.9456,Donji Žabar,ADM2:ADM3,DISTRICT
SM302855,-100.814,34.9654,Donley,ADM2,DISTRICT
SM297706,105.0211,15.3769,Donmotdaeng,ADM2,DISTRICT
SM323812,-117.1109,55.7293,Donnelly,ADM2:ADM3,DISTRICT
SM229829,-3.5147,14.3402,Donno So Dogon,LANGUAGE,LANGUAGE
SM261372,115.9778,-33.7022,Donnybrook-Balingup,ADM2,DISTRICT
SM286698,-80.4297,9.02,Donoso,ADM2,DISTRICT
SM458646,128.4572,-15.0364,Doolboong,ETHNICITY,ETHNICITY
SM274377,46,7.25,Doolo,ADM2,DISTRICT
SM274377,46,7.25,Doolo,ADM2,DISTRICT
SM274377,45.992,7.2209,Doolo,ADM2,DISTRICT
SM274377,45.992,7.2209,Doolo,ADM2,DISTRICT
SM300480,-83.7988,32.1572,Dooly,ADM2,DISTRICT
SM261624,138.7436,-17.6388,Doomadgee,ADM2,DISTRICT
SM229831,13.5641,-4.3048,Doondo,LANGUAGE,LANGUAGE
SM303227,-87.3578,44.8383,Door,ADM2,DISTRICT
SM289941,26.9942,44.4412,Dor Marunt,ADM2,DISTRICT
SM10677,32.7313,34.7813,Dora,ADM2,DISTRICT
SM1135,-66.283,18.4397,Dorado,ADM1,DISTRICT
SM15673,-66.2616,18.4592,Dorado Barrio-Pueblo,ADM2,DISTRICT
SM229833,-82.3757,8.5674,Dorasque,LANGUAGE,LANGUAGE
SM253237,59.44,27.25,Dorazai,ETHNICITY,ETHNICITY
SM14011,-8.281,4.9938,Dorbor (excl. Topo),ADM2,DISTRICT
SM302441,-80.4056,33.0795,Dorchester,ADM2,DISTRICT
SM301236,-76.0833,38.4226,Dorchester,ADM2,DISTRICT
SM274441,0.735,45.1274,Dordogne,ADM2,DISTRICT
SM285645,4.7416,51.799,Dordrecht,ADM2,DISTRICT
SM263401,-43.9936,-21.1137,Dores de Campos,ADM2,DISTRICT
SM263403,-42.9282,-19.044,Dores de Guanhães,ADM2,DISTRICT
SM263404,-45.5405,-19.4743,Dores do Indaiá,ADM2,DISTRICT
SM267980,-41.8121,-20.6351,Dores do Rio Preto,ADM2,DISTRICT
SM263405,-43.1636,-21.0278,Dores do Turvo,ADM2,DISTRICT
SM263406,-45.8686,-20.2969,Doresópolis,ADM2,DISTRICT
SM290499,21.8149,46.0678,Dorgos,ADM2,DISTRICT
SM229837,160.897,-9.1154,Dori'o,LANGUAGE,LANGUAGE
SM248959,167.5459,-14.3667,Dorig,LANGUAGE,LANGUAGE
SM457846,80.5,17.68,Dorla Gond,ETHNICITY,ETHNICITY
SM4348,-2.8833,7.2833,Dorma Central (2012-),ADM2,DISTRICT
SM4346,-2.7,7.3,Dorma East,ADM2,DISTRICT
SM4680,-2.9633,6.9847,Dorma West,ADM2,DISTRICT
SM264979,-40.5972,-8.4302,Dormentes,ADM2,DISTRICT
SM296352,15.9905,46.4454,Dornava,ADM1:ADM2,DISTRICT
SM261877,9.7494,47.3835,Dornbirn,ADM2,DISTRICT
SM268956,7.6182,47.4803,Dorneck,ADM2,DISTRICT
SM291787,25.9942,47.8898,Dornesti,ADM2,DISTRICT
SM1136,115,48,Dornod,ADM1,DISTRICT
SM1137,110,44.5,Dornogovi,ADM1,DISTRICT
SM292042,28.3007,44.9487,Dorobantu,ADM2,DISTRICT
SM289942,26.9522,44.2232,Dorobantu,ADM2,DISTRICT
SM14012,-7.8333,4.75,Dorobo,ADM2,DISTRICT
SM250619,36.35,-0.15,Dorobo,ETHNICITY,ETHNICITY
SM250619,36.35,-0.15,Dorobo,ETHNICITY,ETHNICITY
SM250619,36.35,-0.15,Dorobo,ETHNICITY,ETHNICITY
SM250619,36.35,-0.15,Dorobo,ETHNICITY,ETHNICITY
SM250619,34.5,1.5,Dorobo,ETHNICITY,ETHNICITY
SM250619,34.5,1.5,Dorobo,ETHNICITY,ETHNICITY
SM250619,34.5,1.5,Dorobo,ETHNICITY,ETHNICITY
SM250619,34.5,1.5,Dorobo,ETHNICITY,ETHNICITY
SM250619,36,0,Dorobo,ETHNICITY,ETHNICITY
SM250619,36,0,Dorobo,ETHNICITY,ETHNICITY
SM250619,36,0,Dorobo,ETHNICITY,ETHNICITY
SM250619,36,0,Dorobo,ETHNICITY,ETHNICITY
SM250619,37,-5,Dorobo,ETHNICITY,ETHNICITY
SM250619,37,-5,Dorobo,ETHNICITY,ETHNICITY
SM250619,37,-5,Dorobo,ETHNICITY,ETHNICITY
SM250619,37,-5,Dorobo,ETHNICITY,ETHNICITY
SM253239,148.75,-22,Dorobura,ETHNICITY,ETHNICITY
SM253239,148.75,-22,Dorobura,ETHNICITY,ETHNICITY
SM253239,148.65,-21.26,Dorobura,ETHNICITY,ETHNICITY
SM253239,148.65,-21.26,Dorobura,ETHNICITY,ETHNICITY
SM253239,147.2563,-20.9284,Dorobura,ETHNICITY,ETHNICITY
SM253239,147.2563,-20.9284,Dorobura,ETHNICITY,ETHNICITY
SM292561,26.4169,47.9677,Dorohoi,ADM2,DISTRICT
SM291662,22.7864,47.8452,Dorolt,ADM2,DISTRICT
SM229846,147.986,-9.5669,Doromu-Koki,LANGUAGE,LANGUAGE
SM229847,157.767,-8.3056,Dororo,LANGUAGE,LANGUAGE
SM10678,32.9242,34.7976,Doros,ADM2,DISTRICT
SM296527,15.8061,64.5798,Dorotea,ADM2,DISTRICT
SM274623,-2.3333,50.75,Dorset,ADM1:ADM2,DISTRICT
SM261755,147.7317,-41.0479,Dorset,ADM2,DISTRICT
SM277559,49.0833,33.5,Dorud,ADM2,DISTRICT
SM229854,37.5554,6.2524,Dorze,LANGUAGE,LANGUAGE
SM253240,38,6,Dorze,ETHNICITY,ETHNICITY
SM15674,-66.3202,18.3195,Dos Bocas Barrio (Corozal),ADM2,DISTRICT
SM15675,-65.9859,18.3469,Dos Bocas Barrio (Trujillo Alto),ADM2,DISTRICT
SM286731,-76.75,-9.9167,Dos de Mayo,ADM2,DISTRICT
SM260198,68.6659,35.6209,Doshi,ADM2,DISTRICT
SM229856,142.656,-7.8581,Doso,LANGUAGE,LANGUAGE
SM262411,24.1877,41.6323,Dospat,ADM2,DISTRICT
SM270433,-75.6833,4.8333,Dosquebradas,ADM2,DISTRICT
SM284604,3.3333,12.8333,Dosso,ADM2,DISTRICT
SM1138,3.6,13.2,Dosso,ADM1,DISTRICT
SM289793,23.8331,45.9614,Dostat,ADM2,DISTRICT
SM270975,-83.9225,9.5886,Dota,ADM2,DISTRICT
SM375335,80.8514,29.1873,Doti,ADM2:ADM3,DISTRICT
SM229858,80.7252,29.242,Dotyali,LANGUAGE,LANGUAGE
SM297891,10.0809,36.8281,Douar Hicher,ADM2,DISTRICT
SM274470,6.4349,47.1997,Doubs,ADM2,DISTRICT
SM283367,-2.4088,15.0695,Douentza,ADM2,DISTRICT
SM229859,-2.9565,15.0063,Douentza Sign Language,LANGUAGE,LANGUAGE
SM296850,1.0833,9.8333,Doufelgou,ADM2,DISTRICT
SM300481,-84.2163,31.5334,Dougherty,ADM2,DISTRICT
SM303107,-119.6917,47.7361,Douglas,ADM2,DISTRICT
SM303228,-91.8994,46.4641,Douglas,ADM2,DISTRICT
SM302491,-98.3661,43.3869,Douglas,ADM2,DISTRICT
SM302250,-123.1794,43.2854,Douglas,ADM2,DISTRICT
SM301782,-119.6164,38.9122,Douglas,ADM2,DISTRICT
SM301698,-96.1545,41.2954,Douglas,ADM2,DISTRICT
SM301550,-92.4988,36.9326,Douglas,ADM2,DISTRICT
SM301351,-95.4535,45.9337,Douglas,ADM2,DISTRICT
SM300904,-95.2926,38.8847,Douglas,ADM2,DISTRICT
SM303328,-104.9296,39.3297,Douglas,ADM2,DISTRICT
SM300605,-88.2174,39.7695,Douglas,ADM2,DISTRICT
SM300482,-84.7679,33.7018,Douglas,ADM2,DISTRICT
SM9729,145.2891,-16.3097,Douglas,ADM2,DISTRICT
SM276796,-4.4845,54.1544,Douglas,ADM1:ADM2,DISTRICT
SM250524,-2.67,32.25,Doui-Menia,ETHNICITY,ETHNICITY
SM274566,10.8385,-2.4305,Douigny,ADM2,DISTRICT
SM280453,104.7955,10.9946,Doun Kaev,ADM2,DISTRICT
SM253245,147.75,-9.06,Doura,ETHNICITY,ETHNICITY
SM264447,-53.2749,-23.3404,Douradina,ADM2,DISTRICT
SM263045,-54.5815,-21.9853,Douradina,ADM2,DISTRICT
SM266497,-48.352,-22.1167,Dourado,ADM2,DISTRICT
SM263407,-47.6148,-18.442,Douradoquara,ADM2,DISTRICT
SM263046,-54.5408,-22.1626,Dourados,ADM2,DISTRICT
SM10287,15.6287,11.9287,Dourbali,ADM2,DISTRICT
SM229868,138.22,-3.2483,Doutai,LANGUAGE,LANGUAGE
SM264448,-52.2234,-23.5616,Doutor Camargo,ADM2,DISTRICT
SM265638,-54.364,-27.4954,Doutor Maurício Cardoso,ADM2,DISTRICT
SM266413,-49.5583,-26.7114,Doutor Pedrinho,ADM2,DISTRICT
SM265639,-51.9744,-29.1012,Doutor Ricardo,ADM2,DISTRICT
SM265535,-38.3966,-6.1121,Doutor Severiano,ADM2,DISTRICT
SM264449,-49.4002,-24.635,Doutor Ulysses,ADM2,DISTRICT
SM4313,11.5403,-2.853,Doutsila,ADM2,DISTRICT
SM4318,11.105,-1.69,Douya-Onoye (1996-),ADM2,DISTRICT
SM18005,9.5302,33.228,Douz Nord,ADM2,DISTRICT
SM18006,9.1649,33.0362,Douz Sud,ADM2,DISTRICT
SM295334,156.6394,-7.6882,Dovele,ADM2,DISTRICT
SM12292,-76.7081,18.2553,Dover,ADM2,DISTRICT
SM268264,-52.4992,-16.8777,Doverlândia,ADM2,DISTRICT
SM293856,79.75,54.5,Dovolenskiy rayon,ADM2,DISTRICT
SM286101,9.2495,61.9858,Dovre,ADM2,DISTRICT
SM1140,33.8238,-13.601,Dowa,ADM1:ADM2,DISTRICT
SM14013,-10.9497,6.5888,Dowein District,ADM2,DISTRICT
SM261373,117.1057,-31.1133,Dowerin,ADM2,DISTRICT
SM9520,65.7515,34.5802,Dowlatyar,ADM2,DISTRICT
SM12293,-77.9203,18.4773,Down Town Montego Bay,ADM2,DISTRICT
SM12294,-77.5731,17.95,Downs,ADM2,DISTRICT
SM229871,13.0768,8.686,Doyayo,LANGUAGE,LANGUAGE
SM250211,13,9,Doyayo,ETHNICITY,ETHNICITY
SM298669,31.6823,38.1565,Doğanhisar,ADM2,DISTRICT
SM298441,38.917,40.808,Doğankent,ADM2,DISTRICT
SM298721,39.0376,38.3101,Doğanyol,ADM2,DISTRICT
SM298589,33.4734,42.0005,Doğanyurt,ADM2,DISTRICT
SM298933,37.536,40.2141,Doğanşar,ADM2,DISTRICT
SM298720,37.8769,38.0913,Doğanşehir,ADM2,DISTRICT
SM298485,44.0891,39.556,Doğubeyazıt,ADM2,DISTRICT
SM305134,26.5693,-26.8068,Dr Kenneth Kaunda,ADM2,DISTRICT
SM305135,24.2746,-26.6377,Dr Ruth Segomotsi Mompati,ADM2,DISTRICT
SM283224,-106.4523,27.9846,Dr. Belisario Domínguez,ADM2,DISTRICT
SM15331,-55.2249,-26.135,Dr. Raul Pena,ADM2,DISTRICT
SM4272,-4.4193,31.6916,Draa-Tafilalet,ADM1,DISTRICT
SM13490,25.2263,57.2432,Drabesu pagasts,ADM2,DISTRICT
SM299440,32.1521,49.9617,Drabivs'kyi,ADM2,DISTRICT
SM266498,-51.596,-21.5794,Dracena,ADM2,DISTRICT
SM229872,98.0771,30.5092,Drag-yab,LANGUAGE,LANGUAGE
SM289943,27.3754,44.4448,Dragalina,ADM2,DISTRICT
SM305584,17.2883,45.2468,Dragalić,ADM2,DISTRICT
SM291519,26.3027,44.8274,Draganesti,ADM2,DISTRICT
SM290435,27.4644,45.7928,Draganesti,ADM2,DISTRICT
SM292427,22.4082,46.6347,Draganesti,ADM2,DISTRICT
SM291886,25.0487,44.1308,Draganesti De Vede,ADM2,DISTRICT
SM291410,24.5084,44.1762,Draganesti-olt,ADM2,DISTRICT
SM291885,25.5485,44.0952,Draganesti-vlasca,ADM2,DISTRICT
SM305700,15.5982,45.6009,Draganić,ADM2,DISTRICT
SM291246,24.703,44.9376,Draganu-olteni,ADM2,DISTRICT
SM292184,24.2463,44.6441,Dragasani,ADM2,DISTRICT
SM304755,20.65,42,Dragaš,ADM2,DISTRICT
SM292428,22.1253,46.9037,Dragesti,ADM2,DISTRICT
SM291411,24.2561,44.1269,Draghiceni,ADM2,DISTRICT
SM290262,25.3843,44.7589,Dragodana,ADM2,DISTRICT
SM292185,24.294,44.8039,Dragoesti,ADM2,DISTRICT
SM290905,26.5206,44.5877,Dragoesti,ADM2,DISTRICT
SM262435,22.9333,42.9333,Dragoman,ADM2,DISTRICT
SM292271,27.3579,46.6186,Dragomiresti,ADM2,DISTRICT
SM291329,26.5754,47.028,Dragomiresti,ADM2,DISTRICT
SM291004,24.2954,47.6686,Dragomiresti,ADM2,DISTRICT
SM290263,25.3661,44.8935,Dragomiresti,ADM2,DISTRICT
SM290958,25.9316,44.4756,Dragomiresti-vale,ADM2,DISTRICT
SM289944,27.1538,44.4484,Dragos Voda,ADM2,DISTRICT
SM291247,25.1701,45.3345,Dragoslavele,ADM2,DISTRICT
SM292338,26.6535,45.9971,Dragosloveni,ADM2,DISTRICT
SM290627,23.1619,44.7996,Dragotesti,ADM2,DISTRICT
SM290358,24.0814,44.2577,Dragotesti,ADM2,DISTRICT
SM291603,23.4109,47.0349,Dragu,ADM2,DISTRICT
SM291788,26.5228,47.3117,Draguseni,ADM2,DISTRICT
SM290437,27.733,46.002,Draguseni,ADM2,DISTRICT
SM292562,26.8199,48.0329,Draguseni,ADM2,DISTRICT
SM290628,23.2348,44.9637,Dragutesti,ADM2,DISTRICT
SM271686,12.6276,55.5823,Dragør,ADM2,DISTRICT
SM262578,25,52.1667,Drahichyn,ADM2,DISTRICT
SM291520,26.076,45.2435,Drajna De Sus,ADM2,DISTRICT
SM461890,10.206,59.7454,Drammen (2020-),ADM2,DISTRICT
SM286216,9.0596,59.0966,Drangedal,ADM2,DISTRICT
SM290360,23.8403,44.056,Dranic,ADM2,DISTRICT
SM12295,-76.4061,18.1712,Drapers,ADM2,DISTRICT
SM17831,14.4474,46.0729,Dravlje District,ADM2,DISTRICT
SM296279,15.0333,46.5833,Dravograd,ADM1:ADM2,DISTRICT
SM288837,16.0213,53.4474,Drawsko,ADM2,DISTRICT
SM323994,-114.977,53.2169,Drayton Valley,ADM2:ADM3,DISTRICT
SM10501,18.7886,45.8412,Draz,ADM2,DISTRICT
SM375405,70.1649,31.5569,Drazanda,ADM2:ADM3,DISTRICT
SM457296,3.9167,36.5833,Draâ El Mizan District,ADM2,DISTRICT
SM229877,141.68,-8.5,Dre,LANGUAGE,LANGUAGE
SM305365,18.2781,45.3811,Drenje,ADM2,DISTRICT
SM305558,18.9028,44.9194,Drenovci,ADM2,DISTRICT
SM1141,6.5833,52.8333,Drenthe,ADM1,DISTRICT
SM229879,6.5154,52.9024,Drents,DIALECT,LANGUAGE
SM302366,-91.72,33.5895,Drew,ADM2,DISTRICT
SM12296,-76.8263,18.0238,Drewsland,ADM2,DISTRICT
SM13491,27.1977,56.6628,Dricanu pagasts,ADM2,DISTRICT
SM285911,4.7833,51.6667,Drimmelen,ADM2,DISTRICT
SM4449,-3.3884,34.9767,Driouch,ADM2,DISTRICT
SM275985,16.1667,43.8333,Drniš,ADM2,DISTRICT
SM305713,16.918,46.2088,Drnje,ADM2,DISTRICT
SM291064,22.6067,44.6429,Drobeta-turnu Severin,ADM2,DISTRICT
SM1142,27.8333,48.0833,Drochia,ADM1,DISTRICT
SM299548,23.5053,49.2744,"Drohobych, Boryslav, and Truskavets",ADM2,DISTRICT
SM18204,23.5112,49.3503,Drohobyts'ka Mis'krada,ADM2,DISTRICT
SM299549,23.4725,49.3471,Drohobyts'kyi,ADM2,DISTRICT
SM10679,33.5874,34.8847,Dromolaxia,ADM2,DISTRICT
SM285722,5.7167,52.5,Dronten,ADM2,DISTRICT
SM10680,32.3193,35.0112,Drouseia,ADM2,DISTRICT
SM294614,47.5833,54.75,Drozhzhanovskiy rayon,ADM2,DISTRICT
SM268459,90.0292,26.979,Drugyelgang,ADM2,DISTRICT
SM12297,-76.7876,18.0235,Drumblair,ADM2,DISTRICT
SM323898,-112.6338,51.4147,Drumheller,ADM2:ADM3,DISTRICT
SM229882,98.4945,28.0079,Drung,LANGUAGE,LANGUAGE
SM281318,23.9667,54.0167,Druskininkų,ADM2,DISTRICT
SM13492,25.8922,57.2431,Drustu pagasts,ADM2,DISTRICT
SM285765,5.6132,51.8718,Druten,ADM2,DISTRICT
SM13493,26.2295,57.1107,Druvienas pagasts,ADM2,DISTRICT
SM253247,36,35,Druze,ETHNICITY,ETHNICITY
SM253247,36,35,Druze,ETHNICITY,ETHNICITY
SM253247,36,35,Druze,ETHNICITY,ETHNICITY
SM253247,36,35,Druze,ETHNICITY,ETHNICITY
SM253247,35.03,32.77,Druze,ETHNICITY,ETHNICITY
SM253247,35.03,32.77,Druze,ETHNICITY,ETHNICITY
SM253247,35.03,32.77,Druze,ETHNICITY,ETHNICITY
SM253247,35.03,32.77,Druze,ETHNICITY,ETHNICITY
SM253247,35,32,Druze,ETHNICITY,ETHNICITY
SM253247,35,32,Druze,ETHNICITY,ETHNICITY
SM253247,35,32,Druze,ETHNICITY,ETHNICITY
SM253247,35,32,Druze,ETHNICITY,ETHNICITY
SM253247,35,32,Druze,ETHNICITY,ETHNICITY
SM253247,35,32,Druze,ETHNICITY,ETHNICITY
SM253247,35,32,Druze,ETHNICITY,ETHNICITY
SM253247,35,32,Druze,ETHNICITY,ETHNICITY
SM18205,37.5291,48.6217,Druzhkivka City Council,ADM2,DISTRICT
SM253248,-136.83,59.32,Dry Bay,ETHNICITY,ETHNICITY
SM262510,25.4667,43,Dryanovo,ADM2,DISTRICT
SM262640,31,54.0833,Drybin,ADM2,DISTRICT
SM10681,32.5053,34.9244,Drymou,ADM2,DISTRICT
SM457298,7.75,36.6833,Dréan District,ADM2,DISTRICT
SM274428,5.1652,44.6997,Drôme,ADM2,DISTRICT
SM9521,70.3778,35.2215,Du Ab,ADM2,DISTRICT
SM9522,65.0079,34.1187,Du Lainah,ADM2,DISTRICT
SM229883,9.6188,4.1021,Duala,LANGUAGE,LANGUAGE
SM250212,10.16,3.79,Duala,ETHNICITY,ETHNICITY
SM250212,10.16,3.79,Duala,ETHNICITY,ETHNICITY
SM250212,10,4,Duala,ETHNICITY,ETHNICITY
SM250212,10,4,Duala,ETHNICITY,ETHNICITY
SM253249,107.77,15.68,Duan,ETHNICITY,ETHNICITY
SM229886,103.36,1.5683,Duano,LANGUAGE,LANGUAGE
SM229886,103.36,1.5683,Duano,LANGUAGE,LANGUAGE
SM229886,103.4676,0.7216,Duano,LANGUAGE,LANGUAGE
SM229886,103.4676,0.7216,Duano,LANGUAGE,LANGUAGE
SM12298,-77.5962,18.4027,Duanvale,ADM2,DISTRICT
SM1144,-70.0833,19.25,Duarte,ADM1,DISTRICT
SM266500,-49.4225,-22.405,Duartina,ADM2,DISTRICT
SM265262,-42.5247,-22.0728,Duas Barras,ADM2,DISTRICT
SM264418,-35.3974,-6.7195,Duas Estradas,ADM2,DISTRICT
SM229887,151.218,-10.187,Duau,LANGUAGE,LANGUAGE
SM17779,36,27.5,Duba',ADM2,DISTRICT
SM1145,55.387,24.9409,Dubai,ADM1:ADM2,DISTRICT
SM1145,55.387,24.9409,Dubai,ADM1:ADM2,DISTRICT
SM1145,55.3333,25,Dubai,ADM1:ADM2,DISTRICT
SM1145,55.3333,25,Dubai,ADM1:ADM2,DISTRICT
SM293657,46.3333,54.4167,Dubenskiy rayon,ADM2,DISTRICT
SM313197,16.8244,45.1247,Dubica,ADM2:ADM3,DISTRICT
SM253250,73,22.02,Dubla,ETHNICITY,ETHNICITY
SM229889,73.218,21.5161,Dubli,LANGUAGE,LANGUAGE
SM12058,-6.2492,53.3551,Dublin City,ADM2,DISTRICT
SM13494,26.6728,56.0832,Dubnas pagasts,ADM2,DISTRICT
SM299677,25.744,50.4016,Dubnivs'ka,ADM2,DISTRICT
SM300701,-86.8798,38.3643,Dubois,ADM2,DISTRICT
SM17349,-60.9234,13.9281,Dubonnaire,ADM2,DISTRICT
SM291066,22.1823,44.5728,Dubova,ADM2,DISTRICT
SM294934,44.75,49.25,Dubovskiy rayon,ADM2,DISTRICT
SM294145,42.9978,47.3345,Dubovskiy rayon,ADM2,DISTRICT
SM17350,-60.9715,13.9838,Dubrassay,ADM2,DISTRICT
SM305629,16.5368,45.8376,Dubrava,ADM2,DISTRICT
SM305630,15.7326,45.9573,Dubravica,ADM2,DISTRICT
SM1148,18.0947,42.6536,Dubrovacko-Neretvanska,ADM1,DISTRICT
SM305642,17.8624,42.7899,Dubrovačko Primorje,ADM2,DISTRICT
SM276040,18.094,42.6486,Dubrovnik,ADM2,DISTRICT
SM295030,33.4167,53.6667,Dubrovskiy rayon,ADM2,DISTRICT
SM262682,30.8333,54.5833,Dubrowna,ADM2,DISTRICT
SM274989,-13.4167,10.25,Dubréka,ADM1:ADM2,DISTRICT
SM229890,140.768,-3.6259,Dubu,LANGUAGE,LANGUAGE
SM300808,-90.8825,42.4688,Dubuque,ADM2,DISTRICT
SM1146,29.1167,47.25,Dubăsari,ADM1,DISTRICT
SM302919,-110.4248,40.2975,Duchesne,ADM2,DISTRICT
SM323846,-111.9073,50.7321,Duchess,ADM2:ADM3,DISTRICT
SM253251,-24.67,-124.79,Ducie,ETHNICITY,ETHNICITY
SM12299,-76.2266,17.9221,Duckenfield,ADM2,DISTRICT
SM292272,28.0465,46.7248,Duda-Epureni,ADM2,DISTRICT
SM370511,6.0877,49.4808,Dudelange,ADM1:ADM2:ADM3,DISTRICT
SM292712,27.4433,44.8762,Dudesti,ADM2,DISTRICT
SM274624,-2.1167,52.5,Dudley,ADM1:ADM2,DISTRICT
SM229893,145.642,-5.4312,Duduela,LANGUAGE,LANGUAGE
SM267231,-49.4614,-11.3554,Dueré,ADM2,DISTRICT
SM18592,6.6,51.4,Duesseldorf District,ADM2,DISTRICT
SM15676,-67.0641,18.1328,Duey Alto Barrio,ADM2,DISTRICT
SM15677,-67.0828,18.1321,Duey Bajo Barrio,ADM2,DISTRICT
SM15678,-66.8383,18.0949,Duey Barrio,ADM2,DISTRICT
SM295467,167.1779,-9.8782,Duff Islands,ADM2,DISTRICT
SM268892,-80.2497,44.0834,Dufferin,ADM2,DISTRICT
SM327758,-98.0741,49.5264,Dufferin,ADM2:ADM3,DISTRICT
SM276055,15.5,45.4333,Duga Resa,ADM2,DISTRICT
SM17352,-61.0283,13.8039,Dugard (Choiseul),ADM2,DISTRICT
SM17351,-60.9142,13.8109,Dugard (Micoud),ADM2,DISTRICT
SM370136,-8.0199,4.9544,Dugba Jaffa,ADM2:ADM3,DISTRICT
SM14014,-8.7282,4.9875,Dugbe River (excl. Nana Kru and Nuohn Point),ADM2,DISTRICT
SM305498,16.6474,43.4492,Dugi Rat,ADM2,DISTRICT
SM276036,16.2412,45.809,Dugo Selo,ADM2,DISTRICT
SM305499,16.5994,43.5816,Dugopolje,ADM2,DISTRICT
SM229895,13.3966,8.3869,Dugun,LANGUAGE,LANGUAGE
SM229896,10.21,10.05,Duguri,LANGUAGE,LANGUAGE
SM229900,14.2134,10.752,Dugwor,LANGUAGE,LANGUAGE
SM12300,-76.8462,18.0309,Duhaney Park,ADM2,DISTRICT
SM12301,-76.4319,17.894,Duhaney Pen,ADM2,DISTRICT
SM228892,92.2256,27.4178,Duhumbi,LANGUAGE,LANGUAGE
SM229902,8.78,9.3259,Duhwa,LANGUAGE,LANGUAGE
SM295370,159.9537,-9.7356,Duidui,ADM2,DISTRICT
SM229903,-73.0214,5.7868,Duit,LANGUAGE,LANGUAGE
SM270765,-73.0899,5.8636,Duitama,ADM2,DISTRICT
SM285766,6.0172,51.9474,Duiven,ADM2,DISTRICT
SM305844,45.0403,35.9098,Dukan,ADM2,DISTRICT
SM229908,157.064,-7.9777,Duke,LANGUAGE,LANGUAGE
SM301245,-70.6988,41.3888,Dukes,ADM2,DISTRICT
SM229909,98.9318,51.9307,Dukha,DIALECT,LANGUAGE
SM253258,-7.81,32.75,Dukkala,ETHNICITY,ETHNICITY
SM284749,10.8284,10.8026,Dukku,ADM2,DISTRICT
SM229913,9.9476,10.1911,Dulbu,LANGUAGE,LANGUAGE
SM275880,-88.8386,14.8555,Dulce Nombre,ADM2,DISTRICT
SM275759,-85.27,15.05,Dulce Nombre de Culmí,ADM2,DISTRICT
SM295638,-89.0333,14.2,Dulce Nombre de María,ADM2,DISTRICT
SM266501,-50.5277,-20.1167,Dulcinopolis,ADM2,DISTRICT
SM229915,13.4232,9.1124,Duli-Gewe,LANGUAGE,LANGUAGE
SM18042,36.9197,37.5825,Dulkadiroglu,ADM2,DISTRICT
SM262383,27.0833,43.8167,Dulovo,ADM2,DISTRICT
SM229920,13.0046,-0.9193,Duma,LANGUAGE,LANGUAGE
SM296747,37.6386,33.4326,Duma,ADM2,DISTRICT
SM253261,32.97,-19.66,Duma (Zimbabwe),ETHNICITY,ETHNICITY
SM276557,101.4917,1.6159,Dumai,ADM2,DISTRICT
SM229924,166.531,-22.0947,Dumbea,LANGUAGE,LANGUAGE
SM261374,117.9875,-33.2178,Dumbleyung,ADM2,DISTRICT
SM291974,22.1255,45.8175,Dumbrava,ADM2,DISTRICT
SM291521,26.2146,44.8627,Dumbrava,ADM2,DISTRICT
SM291067,23.1356,44.5212,Dumbrava De Jos,ADM2,DISTRICT
SM291331,26.4412,46.8854,Dumbrava Rosie,ADM2,DISTRICT
SM292339,27.0904,45.5542,Dumbraveni,ADM2,DISTRICT
SM291721,24.5527,46.2226,Dumbraveni,ADM2,DISTRICT
SM291789,26.407,47.668,Dumbraveni,ADM2,DISTRICT
SM290163,27.9911,43.9313,Dumbraveni,ADM2,DISTRICT
SM291522,25.9986,45.0923,Dumbravesti,ADM2,DISTRICT
SM291975,21.2465,45.8001,Dumbravita,ADM2,DISTRICT
SM291005,23.6573,47.6012,Dumbravita,ADM2,DISTRICT
SM292623,25.4333,45.7667,Dumbravita,ADM2,DISTRICT
SM284589,166.4473,-22.1496,Dumbéa,ADM2,DISTRICT
SM292563,26.5144,48.0362,Dumeni,ADM2,DISTRICT
SM292273,27.3004,46.8435,Dumesti,ADM2,DISTRICT
SM290821,27.3347,47.1767,Dumesti,ADM2,DISTRICT
SM12302,-77.7559,18.4297,Dumfries,ADM2,DISTRICT
SM274741,-3.8333,55.0833,Dumfries and Galloway,ADM1:ADM2,DISTRICT
SM229927,86.757,27.4585,Dumi,LANGUAGE,LANGUAGE
SM293089,35,53.9167,Duminichskiy rayon,ADM2,DISTRICT
SM292493,24.4343,47.2168,Dumitra,ADM2,DISTRICT
SM292340,26.9073,45.5772,Dumitresti,ADM2,DISTRICT
SM276918,87.25,24.3,Dumka (2001-),ADM2,DISTRICT
SM298705,30.0064,38.8764,Dumlupınar,ADM2,DISTRICT
SM266502,-47.996,-21.2444,Dumont,ADM2,DISTRICT
SM229930,117.419,5.9348,Dumpas,LANGUAGE,LANGUAGE
SM229931,145.655,-5.82,Dumpu,LANGUAGE,LANGUAGE
SM253265,144.25,-7.33,Dumu,ETHNICITY,ETHNICITY
SM229933,146.082,-5.5531,Dumun,LANGUAGE,LANGUAGE
SM1149,31.75,31.35,Dumyat,ADM1,DISTRICT
SM12059,-6.1349,53.2944,Dun Laoghaire-Rathdown,ADM2,DISTRICT
SM229935,142.475,-5.4144,Duna,LANGUAGE,LANGUAGE
SM253266,142.24,-5.49,Duna,ETHNICITY,ETHNICITY
SM299462,26.8447,48.888,Dunaievets'kyi,ADM2,DISTRICT
SM296174,17.6094,47.9964,Dunajská Streda,ADM2,DISTRICT
SM276158,19.1625,47.6337,Dunakeszi,ADM2,DISTRICT
SM13495,21.331,56.673,Dunalkas pagasts,ADM2,DISTRICT
SM11564,18.83,47.028,Dunaujvarosi Jaras,ADM1:ADM2,DISTRICT
SM13496,26.1775,56.1971,Dunavas pagasts,ADM2,DISTRICT
SM12303,-77.5463,18.4753,Duncans,ADM2,DISTRICT
SM3965,22.3947,57.5667,Dundagas Novads,ADM1,DISTRICT
SM13497,22.386,57.5398,Dundagas pagasts,ADM2,DISTRICT
SM261375,124.5058,-32.125,Dundas,ADM2,DISTRICT
SM274742,-2.9167,56.4667,Dundee,ADM1:ADM2,DISTRICT
SM12304,-77.887,18.2368,Dundee,ADM2,DISTRICT
SM1150,106.5,45.5,Dundgovi,ADM1,DISTRICT
SM301699,-101.688,40.1762,Dundy,ADM2,DISTRICT
SM286494,170.2437,-45.6405,Dunedin,ADM2,DISTRICT
SM229938,76.8382,42.7292,Dungan,LANGUAGE,LANGUAGE
SM253267,69.27,41.3,Dungan,ETHNICITY,ETHNICITY
SM277168,73.85,23.75,Dungarpur,ADM2,DISTRICT
SM15240,9.4842,13.1151,Dungass,ADM2,DISTRICT
SM458454,152.5522,-27.201,Dungibara,ETHNICITY,ETHNICITY
SM229937,87.3328,26.9813,Dungmali,LANGUAGE,LANGUAGE
SM261532,151.6112,-32.3462,Dungog,ADM2,DISTRICT
SM229936,73.187,22.306,Dungra Bhil,LANGUAGE,LANGUAGE
SM229939,8.1954,10.5043,Dungu,LANGUAGE,LANGUAGE
SM13498,21.3145,56.2792,Dunikas pagasts,ADM2,DISTRICT
SM301551,-90.1658,36.106,Dunklin,ADM2,DISTRICT
SM303229,-91.8964,44.9466,Dunn,ADM2,DISTRICT
SM302034,-102.6182,47.3568,Dunn,ADM2,DISTRICT
SM285308,6.978,6.2738,Dunukofia,ADM2,DISTRICT
SM248975,105.07,23.0196,Duoluo Gelao,LANGUAGE,LANGUAGE
SM229944,102.1771,28.5498,Duoxu,DIALECT,LANGUAGE
SM300606,-88.0857,41.852,Dupage,ADM2,DISTRICT
SM229945,122.188,17.3748,Dupaninan Agta,LANGUAGE,LANGUAGE
SM296353,15.77,46.5156,Duplek,ADM1:ADM2,DISTRICT
SM301955,-77.9329,34.9363,Duplin,ADM2,DISTRICT
SM262536,23.15,42.2833,Dupnitsa,ADM2,DISTRICT
SM17353,-61.0295,13.7944,Dupre,ADM2,DISTRICT
SM262985,-43.0318,-4.1114,Duque Bacelar,ADM2,DISTRICT
SM15679,-65.739,18.2394,Duque Barrio,ADM2,DISTRICT
SM265263,-43.2929,-22.6568,Duque de Caxias,ADM2,DISTRICT
SM260131,70.963,34.098,Dur Baba,ADM2,DISTRICT
SM227703,90.648,27.6544,Dur Brokkat,LANGUAGE,LANGUAGE
SM229947,84.435,28.173,Dura,LANGUAGE,LANGUAGE
SM17354,-60.9951,13.9228,Durandeau,ADM2,DISTRICT
SM263408,-41.7824,-20.1518,Durandé,ADM2,DISTRICT
SM281529,-104.6798,24.0298,Durango,ADM2,DISTRICT
SM1151,-104.8333,24.8333,Durango,ADM1,DISTRICT
SM270363,-72.7003,7.7192,Durania,ADM2,DISTRICT
SM296754,36.1268,34.932,Duraykish,ADM2,DISTRICT
SM1152,-56.0833,-33.0833,Durazno,ADM1,DISTRICT
SM298909,35.0579,41.4256,Durağan,ADM2,DISTRICT
SM13499,21.3789,56.5865,Durbe,ADM2,DISTRICT
SM3955,21.3953,56.6075,Durbes Novads,ADM1,DISTRICT
SM13500,21.4352,56.5777,Durbes pagasts,ADM2,DISTRICT
SM277443,81.4,21.15,Durg (2012-),ADM2,DISTRICT
SM301956,-78.8763,36.036,Durham,ADM2,DISTRICT
SM274626,-1.5758,54.7768,Durham,ADM1:ADM2,DISTRICT
SM12305,-76.5156,18.0857,Durham,ADM2,DISTRICT
SM268893,-78.9996,44.2001,Durham,ADM2,DISTRICT
SM229949,119.871,-3.3708,Duri,LANGUAGE,LANGUAGE
SM229950,130.925,-1.3895,Duriankere,LANGUAGE,LANGUAGE
SM292564,27.0941,47.7699,Durnesti,ADM2,DISTRICT
SM253273,69.9,28.53,Durrani,ETHNICITY,ETHNICITY
SM253273,65.19,27.65,Durrani,ETHNICITY,ETHNICITY
SM1153,19.6167,41.4833,Durrës,ADM1,DISTRICT
SM260481,19.45,41.3166,Durrësit,ADM2,DISTRICT
SM298176,28.6531,39.5291,Dursunbey,ADM2,DISTRICT
SM229954,39.322,-4.0963,Duruma,LANGUAGE,LANGUAGE
SM17780,46.0667,24.7,Duruma,ADM2,DISTRICT
SM250214,39.3335,-4.0835,Duruma,ETHNICITY,ETHNICITY
SM250214,39.3335,-4.0835,Duruma,ETHNICITY,ETHNICITY
SM250214,39,-4,Duruma,ETHNICITY,ETHNICITY
SM250214,39,-4,Duruma,ETHNICITY,ETHNICITY
SM229953,82.4881,19.0534,Duruwa,LANGUAGE,LANGUAGE
SM273453,-79.7748,-2.2258,Durán,ADM2,DISTRICT
SM17930,68.7787,38.5734,Dushanbe,ADM2,DISTRICT
SM1154,68.7797,38.5577,Dushanbe,ADM1:ADM2,DISTRICT
SM11302,44.7,42.0833,Dusheti,ADM2,DISTRICT
SM11303,44.3333,42.35,Dushetis Munitsip'alit'et'I (excl. Dusheti),ADM2,DISTRICT
SM297153,100.5168,13.7824,Dusit,ADM2,DISTRICT
SM229955,134.391,-2.7386,Dusner,LANGUAGE,LANGUAGE
SM253276,116.89,5.9,Dusun,ETHNICITY,ETHNICITY
SM253276,116.89,5.9,Dusun,ETHNICITY,ETHNICITY
SM253276,116.89,5.9,Dusun,ETHNICITY,ETHNICITY
SM253276,117,6,Dusun,ETHNICITY,ETHNICITY
SM253276,117,6,Dusun,ETHNICITY,ETHNICITY
SM253276,117,6,Dusun,ETHNICITY,ETHNICITY
SM253276,114.67,4.71,Dusun,ETHNICITY,ETHNICITY
SM253276,114.67,4.71,Dusun,ETHNICITY,ETHNICITY
SM253276,114.67,4.71,Dusun,ETHNICITY,ETHNICITY
SM229960,115.578,-1.9551,Dusun Deyah,LANGUAGE,LANGUAGE
SM229958,115.392,-0.8528,Dusun Malang,LANGUAGE,LANGUAGE
SM229956,114.979,-1.5741,Dusun Witu,LANGUAGE,LANGUAGE
SM229966,5,52,Dutch,LANGUAGE,LANGUAGE
SM253277,7,53,Dutch,ETHNICITY,ETHNICITY
SM229965,4.9113,52.1251,Dutch Sign Language,LANGUAGE,LANGUAGE
SM301872,-73.7429,41.7652,Dutchess,ADM2,DISTRICT
SM284805,9.3071,11.8033,Dutse,ADM2,DISTRICT
SM284899,8.1485,12.9129,Dutsi,ADM2,DISTRICT
SM284900,7.5224,12.4541,Dutsin-M,ADM2,DISTRICT
SM253279,152.87,-27.25,Duungidjawu,ETHNICITY,ETHNICITY
SM253279,152.87,-27.25,Duungidjawu,ETHNICITY,ETHNICITY
SM253279,152.5741,-26.9731,Duungidjawu,ETHNICITY,ETHNICITY
SM253279,152.5741,-26.9731,Duungidjawu,ETHNICITY,ETHNICITY
SM229970,152.345,-25.3987,Duungidjawu,DIALECT,LANGUAGE
SM229971,-5.8587,10.9234,Duungooma,LANGUAGE,LANGUAGE
SM229975,13.4544,8.5503,Duupa,LANGUAGE,LANGUAGE
SM283593,91.6327,47.5131,Duut,ADM2,DISTRICT
SM302856,-98.5089,27.6813,Duval,ADM2,DISTRICT
SM300319,-81.648,30.3354,Duval,ADM2,DISTRICT
SM294253,57.8333,55.6667,Duvanskiy rayon,ADM2,DISTRICT
SM271769,-71.5945,18.3163,Duvergé,ADM2,DISTRICT
SM229979,137.964,-3.2183,Duvle,LANGUAGE,LANGUAGE
SM229978,138.2004,-3.0131,Duvle-Wano-Pidgin,LANGUAGE,LANGUAGE
SM229982,11.3367,12.803,Duwai,LANGUAGE,LANGUAGE
SM253280,-122.32,47.23,Duwamish,ETHNICITY,ETHNICITY
SM253281,47.49,22.67,Duwasir,ETHNICITY,ETHNICITY
SM229983,146.841,-6.4575,Duwet,LANGUAGE,LANGUAGE
SM370207,-8.0137,5.4783,Duwraken,ADM2:ADM3,DISTRICT
SM304163,105.9619,20.628,Duy Tiên,ADM2,DISTRICT
SM304397,108.2025,15.7897,Duy Xuyên,ADM2,DISTRICT
SM275823,-86.8037,13.641,Duyure,ADM2,DISTRICT
SM304549,106.4771,9.621,Duyên Hải,ADM2,DISTRICT
SM304548,106.4678,9.5932,Duyên Hải (Thị xã),ADM2,DISTRICT
SM253282,56.11,30.06,Duzdgah,ETHNICITY,ETHNICITY
SM262366,25.9667,43.5167,Dve Mogili,ADM2,DISTRICT
SM13501,26.2565,56.1053,Dvietes pagasts,ADM2,DISTRICT
SM296688,31.7646,-26.454,Dvokodvweni,ADM2,DISTRICT
SM305455,16.3,45.1,Dvor,ADM2,DISTRICT
SM229984,-0.4301,7.7071,Dwang,LANGUAGE,LANGUAGE
SM14015,-8.0687,5.0707,Dweh District,ADM2,DISTRICT
SM229988,145.388,-16.596,Dyaabugay,LANGUAGE,LANGUAGE
SM229989,122.5231,-17.1865,Dyaberdyaber,LANGUAGE,LANGUAGE
SM229991,-3.5918,10.8342,Dyan,LANGUAGE,LANGUAGE
SM250215,-3,11,Dyan,ETHNICITY,ETHNICITY
SM229990,152.602,-30.8469,Dyangadi,LANGUAGE,LANGUAGE
SM229992,9.065,10.03,Dyarim,LANGUAGE,LANGUAGE
SM295031,34.35,53.6,Dyat'kovo,ADM2,DISTRICT
SM295032,34.25,53.6667,Dyat'kovskiy rayon,ADM2,DISTRICT
SM302559,-89.4138,36.0591,Dyer,ADM2,DISTRICT
SM229994,145.544,-17.4516,Dyirbal,LANGUAGE,LANGUAGE
SM459068,149.7216,-36.6938,Dyirringañ,ETHNICITY,ETHNICITY
SM299658,34.5288,49.826,Dykans'kyi,ADM2,DISTRICT
SM10682,32.9688,34.9276,Dymes,ADM2,DISTRICT
SM10683,33.0869,35.2429,Dyo Potamoi,ADM2,DISTRICT
SM286243,17.6909,69.0915,Dyrøy,ADM2,DISTRICT
SM229997,122.408,-17.906,Dyugun,LANGUAGE,LANGUAGE
SM229998,-4.7201,10.4536,Dyula,LANGUAGE,LANGUAGE
SM16886,55,55.5,Dyurtyulinskiy Rayon,ADM2,DISTRICT
SM229999,11.3884,9.2667,Dza,LANGUAGE,LANGUAGE
SM230000,91.374,27.7855,Dzalakha,LANGUAGE,LANGUAGE
SM282956,-89.4619,20.3904,Dzan,ADM2,DISTRICT
SM230003,19.0022,2.242,Dzando,LANGUAGE,LANGUAGE
SM230004,112.326,24.9754,Dzao Min,LANGUAGE,LANGUAGE
SM1156,45.2837,-12.7783,Dzaoudzi,ADM1,DISTRICT
SM1157,96,48,Dzavhan,ADM1,DISTRICT
SM253283,89.22,28.63,Dzayul,ETHNICITY,ETHNICITY
SM13502,26.474,56.9937,Dzelzavas pagasts,ADM2,DISTRICT
SM250572,14,3,Dzem,ETHNICITY,ETHNICITY
SM282957,-89.3322,21.2486,Dzemul,ADM2,DISTRICT
SM13503,25.6642,57.2198,Dzerbenes pagasts,ADM2,DISTRICT
SM16887,43.4627,56.2327,Dzerzhinsk Urban Okrug,ADM2,DISTRICT
SM293090,35.75,54.75,Dzerzhinskiy rayon,ADM2,DISTRICT
SM293418,95.25,56.8333,Dzerzhinskiy rayon,ADM2,DISTRICT
SM299972,37.8732,48.3823,Dzerzhyns'ka,ADM2,DISTRICT
SM279860,63.5,49.6667,Dzhangildinskiy,ADM2,DISTRICT
SM299897,34.3941,45.7081,Dzhankois'ka,ADM2,DISTRICT
SM299898,34.392,45.7871,Dzhankois'kyi,ADM2,DISTRICT
SM279893,47.5,49.3333,Dzhanybekskiy,ADM2,DISTRICT
SM16888,44.9176,42.8075,Dzhayrakhskiy Rayon,ADM2,DISTRICT
SM262528,25.2667,41.5,Dzhebel,ADM2,DISTRICT
SM295130,105.5,50.75,Dzhidinskiy rayon,ADM2,DISTRICT
SM288780,20.0482,53.2888,Działdowo,ADM2,DISTRICT
SM282958,-89.0284,21.2893,Dzidzantún,ADM2,DISTRICT
SM288661,16.6919,50.7351,Dzierżoniów,ADM2,DISTRICT
SM282960,-88.7229,21.3359,Dzilam González,ADM2,DISTRICT
SM282959,-88.6684,21.4336,Dzilam de Bravo,ADM2,DISTRICT
SM250190,20,-4,Dzing,ETHNICITY,ETHNICITY
SM282962,-88.5206,20.8277,Dzitás,ADM2,DISTRICT
SM230009,10.7633,6.8901,Dzodinka,LANGUAGE,LANGUAGE
SM282963,-88.854,21.0983,Dzoncauich,ADM2,DISTRICT
SM230011,89.5816,27.4108,Dzongkha,LANGUAGE,LANGUAGE
SM230012,-39.3979,-8.5142,Dzubukua,DIALECT,LANGUAGE
SM13504,23.3139,56.7904,Dzukstes pagasts,ADM2,DISTRICT
SM15003,106.9523,47.6996,Dzuunmod,ADM2,DISTRICT
SM9761,27.1667,53.75,Dzyarzhynski Rayon (excl. Dzyarzhynsk),ADM2,DISTRICT
SM262616,25.25,53.4167,Dzyatlava,ADM2,DISTRICT
SM230017,-4.9204,11.3796,Dzùùngoo,LANGUAGE,LANGUAGE
SM267386,-39.9594,-14.4234,Dário Meira,ADM2,DISTRICT
SM1066,25.5,44.9167,Dâmbovița,ADM1,DISTRICT
SM229434,-67.2934,-0.1177,Dâw,LANGUAGE,LANGUAGE
SM273587,-78.9328,-2.7715,Déleg,ADM2,DISTRICT
SM252162,-122.09,62.61,Délįne,ETHNICITY,ETHNICITY
SM252162,-122.09,62.61,Délįne,ETHNICITY,ETHNICITY
SM252162,-122.09,62.61,Délįne,ETHNICITY,ETHNICITY
SM252162,-119,65,Délįne,ETHNICITY,ETHNICITY
SM252162,-119,65,Délįne,ETHNICITY,ETHNICITY
SM252162,-119,65,Délįne,ETHNICITY,ETHNICITY
SM252162,-122.89,65.75,Délįne,ETHNICITY,ETHNICITY
SM252162,-122.89,65.75,Délįne,ETHNICITY,ETHNICITY
SM252162,-122.89,65.75,Délįne,ETHNICITY,ETHNICITY
SM269377,99.5484,27.9169,Dêqên Tibetan,ADM2,DISTRICT
SM303673,-64.15,10.9833,Díaz,ADM2,DISTRICT
SM283592,92.6333,48.3167,Dörgön,ADM2,DISTRICT
SM298216,32.0708,40.7304,Dörtdivan,ADM2,DISTRICT
SM283844,95.0026,47.6381,Dörvöljin,ADM2,DISTRICT
SM283844,95.0026,47.6381,Dörvöljin,ADM2,DISTRICT
SM283844,94.3372,47.8588,Dörvöljin,ADM2,DISTRICT
SM283844,94.3372,47.8588,Dörvöljin,ADM2,DISTRICT
SM286100,12.4819,66.1061,Dønna,ADM2,DISTRICT
SM1155,31.1667,40.8333,Düzce (1999-),ADM1,DISTRICT
SM298818,36.4705,37.2506,Düziçi,ADM2,DISTRICT
SM298976,39.4259,40.8724,Düzköy,ADM2,DISTRICT
SM288945,20.979,50.1757,Dąbrowa,ADM2,DISTRICT
SM288717,19.3213,50.371,Dąbrowa Górnicza,ADM2,DISTRICT
SM289023,21.354,49.9992,Dębica,ADM2,DISTRICT
SM271196,14.3433,50.8291,Děčín,ADM2,DISTRICT
SM304617,106.7782,10.9069,Dĩ An,ADM2,DISTRICT
SM304085,106.7179,20.7766,Dương Kinh,ADM2,DISTRICT
SM304473,106.257,11.3183,Dương Minh Châu,ADM2,DISTRICT
SM261947,48.8275,41.1854,Dəvəçi,ADM1:ADM2,DISTRICT
SM304616,106.4429,11.3136,Dầu Tiếng,ADM2,DISTRICT
SM230500,109.063,24.7262,E,LANGUAGE,LANGUAGE
SM253284,107.88,13.37,E De,ETHNICITY,ETHNICITY
SM253284,107.88,13.37,E De,ETHNICITY,ETHNICITY
SM253284,108,13,E De,ETHNICITY,ETHNICITY
SM253284,108,13,E De,ETHNICITY,ETHNICITY
SM230603,106.0303,23.6046,E'ma Buyang,LANGUAGE,LANGUAGE
SM303985,108.0715,13.3181,Ea H'leo,ADM2,DISTRICT
SM303986,108.5153,12.7861,Ea Kar,ADM2,DISTRICT
SM303987,107.7995,13.1828,Ea Súp,ADM2,DISTRICT
SM303330,-106.6953,39.6278,Eagle,ADM2,DISTRICT
SM300483,-84.9036,31.3228,Early,ADM2,DISTRICT
SM241585,-8,53,Early Irish,LANGUAGE,LANGUAGE
SM12306,-76.5926,17.9394,Easington,ADM2,DISTRICT
SM274916,-0.4437,6.2159,East Akim,ADM2,DISTRICT
SM230174,167.89,-15.3523,East Ambae,LANGUAGE,LANGUAGE
SM261606,135.8228,-12.6618,East Arnhem,ADM2,DISTRICT
SM274743,-4.25,55.5,East Ayrshire,ADM1:ADM2,DISTRICT
SM1160,46.75,38,East Azarbaijan,ADM1,DISTRICT
SM295455,160.8343,-8.4999,East Baegu,ADM2,DISTRICT
SM301116,-91.0956,30.5382,East Baton Rouge,ADM2,DISTRICT
SM230207,114.606,3.961,East Berawan,LANGUAGE,LANGUAGE
SM1161,-58.1667,4,East Berbice-Corentyne,ADM1,DISTRICT
SM301117,-91.2351,32.7326,East Carroll,ADM2,DISTRICT
SM230197,128.636,-7.1699,East Damar,LANGUAGE,LANGUAGE
SM244027,13.04,55.52,East Danish,LANGUAGE,LANGUAGE
SM1162,26.5,11.1667,East Darfur,ADM1,DISTRICT
SM5998,77.2912,28.6624,East Delhi (2012-),ADM2,DISTRICT
SM12307,-76.7847,17.972,East Down Town,ADM2,DISTRICT
SM274744,-4.2,55.9333,East Dunbartonshire,ADM1:ADM2,DISTRICT
SM303906,-64.8619,18.3323,East End,ADM2,DISTRICT
SM303904,-64.6698,18.3399,East End,ADM2,DISTRICT
SM303894,-64.6374,17.7462,East End,ADM2,DISTRICT
SM4220,-62.9973,18.234,East End,ADM1,DISTRICT
SM1163,-81.1105,19.2998,East End,ADM1,DISTRICT
SM301118,-91.0455,30.8451,East Feliciana,ADM2,DISTRICT
SM261376,115.7671,-32.0365,East Fremantle,ADM2,DISTRICT
SM230046,7.7355,52.9713,East Frisian Low Saxon,DIALECT,LANGUAGE
SM230177,-178.1372,-14.2787,East Futuna,LANGUAGE,LANGUAGE
SM277077,90.6243,25.6137,East Garo Hills (2012-),ADM2,DISTRICT
SM295301,160.1979,-9.0609,East Gela,ADM2,DISTRICT
SM295371,160.2021,-9.4769,East Ghaobata,ADM2,DISTRICT
SM295457,159.795,-11.3005,East Ghongau,ADM2,DISTRICT
SM261226,148.583,-37.3456,East Gippsland,ADM2,DISTRICT
SM277025,81.8333,17.8333,East Godavari,ADM2,DISTRICT
SM274936,-0.6773,8.5715,East Gonja (pre-2012),ADM2,DISTRICT
SM1164,-78.1414,26.6717,East Grand Bahama,ADM1,DISTRICT
SM5809,92.3668,25.3598,East Jaintia Hills,ADM2,DISTRICT
SM277208,93.05,27.3,East Kameng,ADM2,DISTRICT
SM5115,67.1232,24.9238,East Karachi (2013-),ADM2:ADM3,DISTRICT
SM1165,81.5,48.8333,East Kazakhstan (1997-),ADM1:ADMX,DISTRICT
SM230233,143.931,-6.3299,East Kewa,LANGUAGE,LANGUAGE
SM277079,91.7891,25.3805,East Khasi Hills (1992-),ADM2,DISTRICT
SM268745,-115.7521,49.9166,East Kootenay,ADM2,DISTRICT
SM230126,-11.7496,9.7111,East Limba,LANGUAGE,LANGUAGE
SM274714,-2.75,55.9167,East Lothian,ADM1:ADM2,DISTRICT
SM275153,25.3345,40.9467,East Macedonia and Thrace,ADM1:ADM2,DISTRICT
SM230172,127.423,0.0734,East Makian,LANGUAGE,LANGUAGE
SM274937,-0.4422,10.5306,East Mamprusi,ADM2,DISTRICT
SM230189,129.873,-8.1346,East Masela,LANGUAGE,LANGUAGE
SM1166,151.8878,-4.6128,East New Britain,ADM1,DISTRICT
SM313220,18.405,43.8047,East New Sarajevo,ADM2:ADM3,DISTRICT
SM230146,34.6626,0.3816,East Nyala,LANGUAGE,LANGUAGE
SM261377,123.7137,-21.6133,East Pilbara,ADM2,DISTRICT
SM274715,-4.3333,55.75,East Renfrewshire,ADM1:ADM2,DISTRICT
SM274627,-0.5,53.9167,East Riding of Yorkshire,ADM1:ADM2,DISTRICT
SM17897,33.8366,13.7838,East Sennar Locality,ADM2,DISTRICT
SM1167,143.75,-4,East Sepik,ADM1,DISTRICT
SM11644,95.1634,28.1238,East Siang (2017-),ADM2,DISTRICT
SM277214,88.6667,27.3333,East Sikkim,ADM2,DISTRICT
SM327844,-97.0109,49.9772,East St. Paul,ADM2:ADM3,DISTRICT
SM274628,0.3333,50.9167,East Sussex,ADM1:ADM2,DISTRICT
SM232507,20.7958,-24.2002,East Taa,LANGUAGE,LANGUAGE
SM230192,134.288,-6.7621,East Tarangan,LANGUAGE,LANGUAGE
SM295372,160.286,-9.4882,East Tasimboko,ADM2,DISTRICT
SM295459,160.5148,-11.7891,East Te Nggano,ADM2,DISTRICT
SM248215,-176.207,-13.2903,East Uvean,LANGUAGE,LANGUAGE
SM230078,101.762,37.9882,East Yugur,LANGUAGE,LANGUAGE
SM246081,94.3567,25.0489,East-Central Tangkhul Naga,LANGUAGE,LANGUAGE
SM253285,-109.38,-27.12,Easter Island,ETHNICITY,ETHNICITY
SM253285,-109.38,-27.12,Easter Island,ETHNICITY,ETHNICITY
SM253285,-109.38,-27.12,Easter Island,ETHNICITY,ETHNICITY
SM253285,-109.35,-27.11,Easter Island,ETHNICITY,ETHNICITY
SM253285,-109.35,-27.11,Easter Island,ETHNICITY,ETHNICITY
SM253285,-109.35,-27.11,Easter Island,ETHNICITY,ETHNICITY
SM253285,-109.36,-27.12,Easter Island,ETHNICITY,ETHNICITY
SM253285,-109.36,-27.12,Easter Island,ETHNICITY,ETHNICITY
SM253285,-109.36,-27.12,Easter Island,ETHNICITY,ETHNICITY
SM293662,37.7346,55.7853,Eastern,ADM2,DISTRICT
SM1171,114.2252,22.2772,Eastern,ADM1,DISTRICT
SM1168,-170.65,-14.2667,Eastern,ADM1,DISTRICT
SM1169,180,-18.5,Eastern,ADM1,DISTRICT
SM1170,-0.5,6.5,Eastern,ADM1,DISTRICT
SM1172,-11,8.25,Eastern,ADM1:ADMX,DISTRICT
SM1173,32,-13.6667,Eastern (2011-),ADM1,DISTRICT
SM230258,-68.6617,45.0112,Eastern Abenaki,LANGUAGE,LANGUAGE
SM230140,6.0105,10.7104,Eastern Acipa,LANGUAGE,LANGUAGE
SM230381,101.3577,23.4809,Eastern Angkuic,LANGUAGE,LANGUAGE
SM230265,-72.6686,-14.1754,Eastern Apurímac Quechua,LANGUAGE,LANGUAGE
SM240693,45,40,Eastern Armenian,LANGUAGE,LANGUAGE
SM230118,135.046,-23.3583,Eastern Arrernte,LANGUAGE,LANGUAGE
SM245158,123.531,-8.4978,Eastern Atadei,LANGUAGE,LANGUAGE
SM230053,62,28,Eastern Balochi,LANGUAGE,LANGUAGE
SM230268,-63.37,-20.42,Eastern Bolivian Guaraní,LANGUAGE,LANGUAGE
SM230074,105.671,17.1311,Eastern Bru,LANGUAGE,LANGUAGE
SM230251,-75.6139,62.1735,Eastern Canadian Inuktitut,LANGUAGE,LANGUAGE
SM1174,26,-32,Eastern Cape,ADM1,DISTRICT
SM230275,108.49,11.2853,Eastern Cham,LANGUAGE,LANGUAGE
SM230303,-104.6259,22.676,Eastern Durango Nahuatl,LANGUAGE,LANGUAGE
SM230299,33.6113,29.6742,Eastern Egyptian Bedawi Arabic,LANGUAGE,LANGUAGE
SM1175,32.5,5,Eastern Equatoria,ADM1:ADM2,DISTRICT
SM1175,32.5,5,Eastern Equatoria,ADM1:ADM2,DISTRICT
SM1175,33.8,4.9,Eastern Equatoria,ADM1:ADM2,DISTRICT
SM1175,33.8,4.9,Eastern Equatoria,ADM1:ADM2,DISTRICT
SM230087,84.9451,28.2213,Eastern Gorkha Tamang,LANGUAGE,LANGUAGE
SM458121,130.4059,-17.7485,Eastern Gurindji,ETHNICITY,ETHNICITY
SM230086,84.5815,28.4368,Eastern Gurung,DIALECT,LANGUAGE
SM230271,-97.1526,16.2437,Eastern Highland Chatino,LANGUAGE,LANGUAGE
SM230269,-98.0978,20.5991,Eastern Highland Otomi,LANGUAGE,LANGUAGE
SM1177,145.5833,-6.5,Eastern Highlands,ADM1,DISTRICT
SM230103,109,23.7,Eastern Hongshuihe Zhuang,LANGUAGE,LANGUAGE
SM230253,-97.9077,21.0639,Eastern Huasteca Nahuatl,LANGUAGE,LANGUAGE
SM230112,106.832,25.9942,Eastern Huishui Hmong,LANGUAGE,LANGUAGE
SM230135,-4.5225,10.4987,Eastern Karaboro,LANGUAGE,LANGUAGE
SM230025,107.644,15.8553,Eastern Katu,LANGUAGE,LANGUAGE
SM230083,98.3359,19.8939,Eastern Kayah,LANGUAGE,LANGUAGE
SM230032,-106.453,35.5216,Eastern Keres,LANGUAGE,LANGUAGE
SM230384,93.155,21.3425,Eastern Khumi Chin,LANGUAGE,LANGUAGE
SM230387,15.5,-5.25,Eastern Kikongo,LANGUAGE,LANGUAGE
SM230304,101.546,23.585,Eastern Lalu,LANGUAGE,LANGUAGE
SM346776,28.0725,66.6687,Eastern Lapland,ADM2:ADM3,DISTRICT
SM230072,98.7613,19.0136,Eastern Lawa,LANGUAGE,LANGUAGE
SM240090,12.3914,53.1154,Eastern Low German,LANGUAGE,LANGUAGE
SM248132,116.655,1.5664,Eastern Lowland Kenyah,LANGUAGE,LANGUAGE
SM230093,87.0617,27.4135,Eastern Magar,LANGUAGE,LANGUAGE
SM230162,-10.5394,9.3305,Eastern Maninkakan,LANGUAGE,LANGUAGE
SM230071,48.405,55.8307,Eastern Mari,LANGUAGE,LANGUAGE
SM230097,87.1134,27.5103,Eastern Meohang,LANGUAGE,LANGUAGE
SM230075,108.354,12.3939,Eastern Mnong,LANGUAGE,LANGUAGE
SM458122,131.9955,-17.5575,Eastern Mudburra,ETHNICITY,ETHNICITY
SM230081,80.8893,20.1422,Eastern Muria,LANGUAGE,LANGUAGE
SM230092,102.168,29.235,Eastern Muya,LANGUAGE,LANGUAGE
SM230378,86.034,27.577,Eastern Newari,LANGUAGE,LANGUAGE
SM230191,121.121,-8.8275,Eastern Ngad'a,DIALECT,LANGUAGE
SM458123,130.5118,-16.556,Eastern Ngarinyman,ETHNICITY,ETHNICITY
SM230090,102.694,23.9843,Eastern Nisu,LANGUAGE,LANGUAGE
SM285096,7.7669,4.5339,Eastern Obolo,ADM2,DISTRICT
SM230257,-79.7503,45.0112,Eastern Ojibwa,LANGUAGE,LANGUAGE
SM253286,-85,46,Eastern Ojibwa,ETHNICITY,ETHNICITY
SM253286,-85,46,Eastern Ojibwa,ETHNICITY,ETHNICITY
SM253286,-85.1,45.21,Eastern Ojibwa,ETHNICITY,ETHNICITY
SM253286,-85.1,45.21,Eastern Ojibwa,ETHNICITY,ETHNICITY
SM242953,110.4003,19.9062,Eastern Ong-Be,LANGUAGE,LANGUAGE
SM230292,41.4395,8.6743,Eastern Oromo,LANGUAGE,LANGUAGE
SM230027,87.6201,27.5947,Eastern Pahari,FAMILY,LANGUAGE
SM242138,75.6702,30.0368,Eastern Panjabi,LANGUAGE,LANGUAGE
SM230095,82.9523,28.5259,Eastern Parbate Kham,LANGUAGE,LANGUAGE
SM230206,114.584,4.5915,Eastern Penan,LANGUAGE,LANGUAGE
SM230259,-122.586,38.5647,Eastern Pomo,LANGUAGE,LANGUAGE
SM253287,-123.21,38.833,Eastern Pomo,ETHNICITY,ETHNICITY
SM253287,-123.21,38.833,Eastern Pomo,ETHNICITY,ETHNICITY
SM253287,-123.21,38.833,Eastern Pomo,ETHNICITY,ETHNICITY
SM253287,-123,39,Eastern Pomo,ETHNICITY,ETHNICITY
SM253287,-123,39,Eastern Pomo,ETHNICITY,ETHNICITY
SM253287,-123,39,Eastern Pomo,ETHNICITY,ETHNICITY
SM253287,-122.94,39.03,Eastern Pomo,ETHNICITY,ETHNICITY
SM253287,-122.94,39.03,Eastern Pomo,ETHNICITY,ETHNICITY
SM253287,-122.94,39.03,Eastern Pomo,ETHNICITY,ETHNICITY
SM4214,81.202,8.6061,Eastern Province-Sri Lanka,ADM1,DISTRICT
SM245642,92.3841,27.3154,Eastern Puroik,LANGUAGE,LANGUAGE
SM230110,109.046,26.4966,Eastern Qiandong Miao,LANGUAGE,LANGUAGE
SM4217,33.8262,0.9668,Eastern Region-Uganda,ADM1,DISTRICT
SM4681,82.86,28.6724,Eastern Rukum,ADM2,DISTRICT
SM1178,125.4765,11.5305,Eastern Samar,ADM1:ADM2,DISTRICT
SM230302,123.5234,8.2475,Eastern Subanen,LANGUAGE,LANGUAGE
SM230088,87.0832,27.3239,Eastern Tamang,LANGUAGE,LANGUAGE
SM230205,121.177,12.8968,Eastern Tawbuid,LANGUAGE,LANGUAGE
SM4200,124.962,11.5165,Eastern Visayas,ADM1,DISTRICT
SM230111,109.756,28.7318,Eastern Xiangxi Miao,LANGUAGE,LANGUAGE
SM230128,2.5499,6.378,Eastern Xwla Gbe,LANGUAGE,LANGUAGE
SM230051,19.42,51.75,Eastern Yiddish,LANGUAGE,LANGUAGE
SM302857,-98.8323,32.3271,Eastland,ADM2,DISTRICT
SM230047,10.0057,52.1179,Eastphalian,LANGUAGE,LANGUAGE
SM12308,-76.8007,18.0189,Eastwood Park Gardens,ADM2,DISTRICT
SM301266,-84.8383,42.5961,Eaton,ADM2,DISTRICT
SM303230,-91.286,44.7268,Eau Claire,ADM2,DISTRICT
SM17355,-60.9401,13.7603,Eau Piquant/St Urbain,ADM2,DISTRICT
SM14918,143.078,6.683,Eauripik Municipality,ADM2,DISTRICT
SM232262,30.5282,11.2908,Ebang,LANGUAGE,LANGUAGE
SM282481,-98.4768,22.2128,Ebano,ADM2,DISTRICT
SM275126,11.2353,2.0392,Ebebiyin,ADM2,DISTRICT
SM278240,141.5439,43.1073,Ebetsu,ADM2,DISTRICT
SM278554,139.3931,35.4376,Ebina,ADM2,DISTRICT
SM278768,130.8,32.0167,Ebino,ADM2,DISTRICT
SM230463,7.0618,8.1552,Ebira,LANGUAGE,LANGUAGE
SM250222,5.69,9.83,Ebira,ETHNICITY,ETHNICITY
SM250222,5.69,9.83,Ebira,ETHNICITY,ETHNICITY
SM250222,7,8,Ebira,ETHNICITY,ETHNICITY
SM250222,7,8,Ebira,ETHNICITY,ETHNICITY
SM253288,-59.66,-19.33,Ebitoso,ETHNICITY,ETHNICITY
SM230466,36.798,35.798,Eblaite,LANGUAGE,LANGUAGE
SM260444,14.7448,-10.9292,Ebo,ADM2,DISTRICT
SM4077,168.7167,4.6333,Ebon Atoll,ADM1,DISTRICT
SM284681,8.1298,6.5218,Ebonyi,ADM2,DISTRICT
SM1179,8.0833,6.25,Ebonyi,ADM1,DISTRICT
SM230468,-4.0319,5.3687,Ebrié,LANGUAGE,LANGUAGE
SM230469,8.013,4.8254,Ebughu,LANGUAGE,LANGUAGE
SM270207,-75.7852,6.3341,Ebéjico,ADM2,DISTRICT
SM281913,-99.0551,19.6038,Ecatepec de Morelos,ADM2,DISTRICT
SM281914,-98.7518,18.9579,Ecatzingo,ADM2,DISTRICT
SM298270,26.3578,40.1786,Eceabat,ADM2,DISTRICT
SM266503,-50.1826,-22.4108,Echaporã,ADM2,DISTRICT
SM273550,-79.2667,-1.4333,Echeandía,ADM2,DISTRICT
SM279653,136.056,35.959,Echizen,ADM2,DISTRICT
SM279654,136.1772,35.8895,Echizen City,ADM2,DISTRICT
SM300485,-82.8939,30.7101,Echols,ADM2,DISTRICT
SM285898,5.8667,51.1,Echt-Susteren,ADM2,DISTRICT
SM370471,6.4219,49.8131,Echternach,ADM1:ADM2:ADM3,DISTRICT
SM281370,6.3902,49.7887,Echternach,ADM1:ADM2,DISTRICT
SM250164,36.31,-17.31,Echuwabo,ETHNICITY,ETHNICITY
SM1180,19.555,60.2229,Eckerö,ADM1:ADM2,DISTRICT
SM323983,-114.37,52.3619,Eckville,ADM2:ADM3,DISTRICT
SM263409,-40.8493,-18.273,Ecoporanga,ADM2,DISTRICT
SM302858,-102.5428,31.8692,Ector,ADM2,DISTRICT
SM230477,-79.1666,-1.25,Ecuadorian Sign Language,LANGUAGE,LANGUAGE
SM282023,-102.2254,20.152,Ecuandureo,ADM2,DISTRICT
SM17898,34.7283,12.8817,Ed Dinder Locality,ADM2,DISTRICT
SM296512,12.2167,59.8,Eda,ADM2,DISTRICT
SM285044,5.6182,9.0275,Edati,ADM2,DISTRICT
SM323757,-112.7862,52.7857,Edberg,ADM2:ADM3,DISTRICT
SM301866,-104.3043,32.4715,Eddy,ADM2,DISTRICT
SM302035,-98.9016,47.7176,Eddy,ADM2,DISTRICT
SM285767,5.7449,52.0812,Ede,ADM2,DISTRICT
SM230484,2.4813,7.7818,Ede Cabe,LANGUAGE,LANGUAGE
SM230487,1.8214,8.4613,Ede Ica,LANGUAGE,LANGUAGE
SM230485,2.2704,7.7843,Ede Idaca,LANGUAGE,LANGUAGE
SM230486,2.6426,7.0973,Ede Ije,LANGUAGE,LANGUAGE
SM230489,2.6491,6.7576,Ede Nago,LANGUAGE,LANGUAGE
SM285143,4.5092,7.7069,Ede North,ADM2,DISTRICT
SM285144,4.4576,7.6582,Ede South,ADM2,DISTRICT
SM268265,-49.7332,-17.4399,Edealina,ADM2,DISTRICT
SM461277,-55.382,-26.5808,Edelira (1996-),ADM2,DISTRICT
SM276239,20.7751,48.4086,Edelényi,ADM2,DISTRICT
SM305145,22.0126,-33.8214,Eden,ADM2,DISTRICT
SM230490,139.914,-7.0038,Edera Awyu,LANGUAGE,LANGUAGE
SM300607,-87.7456,39.6785,Edgar,ADM2,DISTRICT
SM301957,-77.5971,35.913,Edgecombe,ADM2,DISTRICT
SM302442,-81.9666,33.7723,Edgefield,ADM2,DISTRICT
SM323925,-110.4531,52.7589,Edgerton,ADM2:ADM3,DISTRICT
SM12309,-76.8655,17.9645,Edgewater,ADM2,DISTRICT
SM274716,-3.1933,55.9497,Edinburgh,ADM1:ADM2,DISTRICT
SM1181,27.25,48.1667,Edineţ,ADM1,DISTRICT
SM1182,26.6667,41.25,Edirne,ADM1,DISTRICT
SM301033,-86.2386,37.2088,Edmonson,ADM2,DISTRICT
SM323995,-113.4949,53.5371,Edmonton,ADM2:ADM3,DISTRICT
SM302492,-99.2153,45.4188,Edmunds,ADM2,DISTRICT
SM1183,6,6.5,Edo,ADM1,DISTRICT
SM250106,6.09,6.46,Edo,ETHNICITY,ETHNICITY
SM250106,6.09,6.46,Edo,ETHNICITY,ETHNICITY
SM250106,5.43,7.83,Edo,ETHNICITY,ETHNICITY
SM250106,5.43,7.83,Edo,ETHNICITY,ETHNICITY
SM250106,6,6,Edo,ETHNICITY,ETHNICITY
SM250106,6,6,Edo,ETHNICITY,ETHNICITY
SM458009,139.8757,35.6924,Edogawa,ADM2,DISTRICT
SM13505,21.6832,57.0514,Edoles pagasts,ADM2,DISTRICT
SM230496,142.65,-6.1162,Edolo,LANGUAGE,LANGUAGE
SM230499,137.387,-3.1108,Edopi,LANGUAGE,LANGUAGE
SM298999,43.2972,38.3789,Edremit,ADM2,DISTRICT
SM298177,27.0321,39.6052,Edremit,ADM2,DISTRICT
SM324109,-116.4459,53.5857,Edson,ADM2:ADM3,DISTRICT
SM284982,5.2101,8.8918,Edu,ADM2,DISTRICT
SM281601,-99.6057,17.772,Eduardo Neri,ADM2,DISTRICT
SM9951,144.9841,-35.2337,Edward River,ADM2,DISTRICT
SM302859,-100.3047,29.9826,Edwards,ADM2,DISTRICT
SM300905,-99.3122,37.8877,Edwards,ADM2,DISTRICT
SM300608,-88.0533,38.4165,Edwards,ADM2,DISTRICT
SM268266,-50.0096,-17.5511,Edéia,ADM2,DISTRICT
SM248153,-123.284,39.1513,Eel River Athabaskan,LANGUAGE,LANGUAGE
SM285587,5.2839,52.2525,Eemnes,ADM2,DISTRICT
SM285913,5.3196,51.3958,Eersel,ADM2,DISTRICT
SM277504,46.1667,33.9,Eevan,ADM2,DISTRICT
SM230501,8.2762,4.6045,Efai,LANGUAGE,LANGUAGE
SM230505,29.2918,1.8798,Efe,LANGUAGE,LANGUAGE
SM253293,27.64,2.7,Efe,ETHNICITY,ETHNICITY
SM253293,27.64,2.7,Efe,ETHNICITY,ETHNICITY
SM253293,27.64,2.7,Efe,ETHNICITY,ETHNICITY
SM253293,29,1,Efe,ETHNICITY,ETHNICITY
SM253293,29,1,Efe,ETHNICITY,ETHNICITY
SM253293,29,1,Efe,ETHNICITY,ETHNICITY
SM253293,29,1,Efe,ETHNICITY,ETHNICITY
SM253293,29,1,Efe,ETHNICITY,ETHNICITY
SM253293,29,1,Efe,ETHNICITY,ETHNICITY
SM261838,13.9845,48.3217,Eferding,ADM2,DISTRICT
SM300486,-81.3413,32.3673,Effingham,ADM2,DISTRICT
SM300609,-88.5899,39.0598,Effingham,ADM2,DISTRICT
SM12310,-77.2186,17.9927,Effortville,ADM2,DISTRICT
SM11393,-0.6231,5.3511,Effutu,ADM2,DISTRICT
SM230508,8.3812,4.8938,Efik,LANGUAGE,LANGUAGE
SM250871,7.9,4.86,Efik,ETHNICITY,ETHNICITY
SM250871,7.9,4.86,Efik,ETHNICITY,ETHNICITY
SM250871,8,5,Efik,ETHNICITY,ETHNICITY
SM250871,8,5,Efik,ETHNICITY,ETHNICITY
SM298568,32.9477,41.4317,Eflani,ADM2,DISTRICT
SM284713,4.9372,7.6958,Efon,ADM2,DISTRICT
SM290014,22.0952,44.8774,Eftimie Murgu,ADM2,DISTRICT
SM230511,8.3543,5.9243,Efutop,LANGUAGE,LANGUAGE
SM230510,-0.6272,5.347,Efutu,DIALECT,LANGUAGE
SM230512,-5.4173,5.5608,Ega,LANGUAGE,LANGUAGE
SM250224,3.65,7.4,Egba,ETHNICITY,ETHNICITY
SM250224,3.65,7.4,Egba,ETHNICITY,ETHNICITY
SM250224,3.65,7.4,Egba,ETHNICITY,ETHNICITY
SM250224,3,6.875,Egba,ETHNICITY,ETHNICITY
SM250224,3,6.875,Egba,ETHNICITY,ETHNICITY
SM250224,3,6.875,Egba,ETHNICITY,ETHNICITY
SM250224,3,7,Egba,ETHNICITY,ETHNICITY
SM250224,3,7,Egba,ETHNICITY,ETHNICITY
SM250224,3,7,Egba,ETHNICITY,ETHNICITY
SM285061,2.918,7.1473,EgbadoNorth,ADM2,DISTRICT
SM285062,2.9614,6.7976,EgbadoSouth,ADM2,DISTRICT
SM285174,4.0452,7.3869,Egbeda,ADM2,DISTRICT
SM285145,4.4333,7.8167,Egbedore,ADM2,DISTRICT
SM230518,22.6804,1.8949,Egbuta,LANGUAGE,LANGUAGE
SM271687,12.2278,55.7556,Egedal,ADM2,DISTRICT
SM276123,20.3738,47.9296,Eger,ADM1:ADM2,DISTRICT
SM230520,8.4569,8.7144,Eggon,LANGUAGE,LANGUAGE
SM279923,69.5,51,Egindykolskiy,ADM2,DISTRICT
SM18537,33.8839,35.1591,Egkomi (Famagusta),ADM2,DISTRICT
SM10684,33.3142,35.1557,Egkomi (Nicosia),ADM2,DISTRICT
SM13506,26.052,55.9712,Eglaines pagasts,ADM2,DISTRICT
SM284697,5.5755,6.3575,Egor,ADM2,DISTRICT
SM292960,81,51.75,Egor'evskiy rayon,ADM2,DISTRICT
SM15680,-65.9987,18.0561,Egozcue Barrio,ADM2,DISTRICT
SM253297,2.97,6.84,Egun,ETHNICITY,ETHNICITY
SM230524,29.654,29.567,Egypt Sign Language,LANGUAGE,LANGUAGE
SM450368,33,25,Egyptian,ETHNICITY,ETHNICITY
SM450368,33,25,Egyptian,ETHNICITY,ETHNICITY
SM450368,33,25,Egyptian,ETHNICITY,ETHNICITY
SM450368,31.2341,30.1736,Egyptian,ETHNICITY,ETHNICITY
SM450368,31.2341,30.1736,Egyptian,ETHNICITY,ETHNICITY
SM450368,31.2341,30.1736,Egyptian,ETHNICITY,ETHNICITY
SM450368,30.96,31.12,Egyptian,ETHNICITY,ETHNICITY
SM450368,30.96,31.12,Egyptian,ETHNICITY,ETHNICITY
SM450368,30.96,31.12,Egyptian,ETHNICITY,ETHNICITY
SM230526,30.835,28.3817,Egyptian (Ancient),LANGUAGE,LANGUAGE
SM250225,33,25,Egyptian (Lower Nile),ETHNICITY,ETHNICITY
SM250225,33,25,Egyptian (Lower Nile),ETHNICITY,ETHNICITY
SM250225,33,24.75,Egyptian (Lower Nile),ETHNICITY,ETHNICITY
SM250225,33,24.75,Egyptian (Lower Nile),ETHNICITY,ETHNICITY
SM230528,31,31,Egyptian Arabic,LANGUAGE,LANGUAGE
SM1184,132.85,33.6167,Ehime,ADM1:ADMX,DISTRICT
SM284775,7.303,5.6649,Ehime-Mb,ADM2,DISTRICT
SM305130,31.361,-25.351,Ehlanzeni,ADM2,DISTRICT
SM230531,5.8589,7.451,Ehueun,LANGUAGE,LANGUAGE
SM286407,7.0697,60.4674,Eidfjord,ADM2,DISTRICT
SM286371,12.125,59.9856,Eidskog,ADM2,DISTRICT
SM286051,11.2612,60.3289,Eidsvoll (1964-),ADM2,DISTRICT
SM286147,6.0031,58.4524,Eigersund,ADM2,DISTRICT
SM279655,136.3549,36.0779,Eiheiji,ADM2,DISTRICT
SM15116,5.7741,50.8009,Eijsden-Margraten,ADM2,DISTRICT
SM274717,-7.1667,57.6667,Eilean Siar,ADM1:ADM2,DISTRICT
SM285914,5.4528,51.4504,Eindhoven,ADM2,DISTRICT
SM268948,8.7601,47.1079,Einsiedeln,ADM2,DISTRICT
SM230533,140.045,-4.3369,Eipomek,LANGUAGE,LANGUAGE
SM267318,-70.4155,-7.1585,Eirunepé,ADM2,DISTRICT
SM261785,16.5258,47.8445,Eisenstadt,ADM2,DISTRICT
SM261784,16.5833,47.8333,Eisenstadt Umgebung,ADM2,DISTRICT
SM230534,142.555,-3.5156,Eitiep,LANGUAGE,LANGUAGE
SM274517,-7.0854,62.3009,Eiði,ADM2,DISTRICT
SM230536,8.9096,5.5444,Ejagham,LANGUAGE,LANGUAGE
SM230538,-16.515,12.3355,Ejamat,LANGUAGE,LANGUAGE
SM285117,4.2863,7.8671,Ejigbo,ADM2,DISTRICT
SM274841,-1.379,6.68,Ejisu-Juabeng,ADM2,DISTRICT
SM274842,-1.432,7.375,Ejura Sekyedumase,ADM2,DISTRICT
SM281792,-104.0842,19.9161,Ejutla,ADM2,DISTRICT
SM230542,99.865,23.475,Eka,LANGUAGE,LANGUAGE
SM253304,135.98,-3.54,Ekagi,ETHNICITY,ETHNICITY
SM253304,135.98,-3.54,Ekagi,ETHNICITY,ETHNICITY
SM253304,135.98,-3.54,Ekagi,ETHNICITY,ETHNICITY
SM253304,136.07,-4.35,Ekagi,ETHNICITY,ETHNICITY
SM253304,136.07,-4.35,Ekagi,ETHNICITY,ETHNICITY
SM253304,136.07,-4.35,Ekagi,ETHNICITY,ETHNICITY
SM253304,136.125,-3.792,Ekagi,ETHNICITY,ETHNICITY
SM253304,136.125,-3.792,Ekagi,ETHNICITY,ETHNICITY
SM253304,136.125,-3.792,Ekagi,ETHNICITY,ETHNICITY
SM253304,136,-4,Ekagi,ETHNICITY,ETHNICITY
SM253304,136,-4,Ekagi,ETHNICITY,ETHNICITY
SM253304,136,-4,Ekagi,ETHNICITY,ETHNICITY
SM230543,8.5506,6.4573,Ekajuk,LANGUAGE,LANGUAGE
SM230545,136.018,-3.8897,Ekari,LANGUAGE,LANGUAGE
SM346799,23.555,60.0027,Ekenäs,ADM2:ADM3,DISTRICT
SM285331,5.7165,4.9221,Ekeremor,ADM2,DISTRICT
SM296489,17.6358,59.322,Ekerö,ADM2,DISTRICT
SM285107,7.9537,4.6366,Eket,ADM2,DISTRICT
SM292885,104.8333,53,Ekhirit-Bulagatskiy rayon,ADM2,DISTRICT
SM230547,8.1361,5.1503,Eki,LANGUAGE,LANGUAGE
SM13184,74.9167,51.8333,Ekibastuz Audany (excl. Ekibastuz),ADM2,DISTRICT
SM13185,75.3667,51.6667,Ekibastuz Qalasy,ADM2,DISTRICT
SM298562,37.1941,38.0701,Ekinözü,ADM2,DISTRICT
SM230552,7.8922,4.7645,Ekit,LANGUAGE,LANGUAGE
SM284983,5.3204,8.118,Ekiti,ADM2,DISTRICT
SM1185,5.25,7.6667,Ekiti,ADM1,DISTRICT
SM250228,5,7,Ekiti,ETHNICITY,ETHNICITY
SM250228,5,7,Ekiti,ETHNICITY,ETHNICITY
SM250228,5,8,Ekiti,ETHNICITY,ETHNICITY
SM250228,5,8,Ekiti,ETHNICITY,ETHNICITY
SM284714,5.6693,7.7353,EkitiEas,ADM2,DISTRICT
SM284715,5.0518,7.5133,EkitiSouth-West,ADM2,DISTRICT
SM284716,5.0285,7.6866,EkitiWest,ADM2,DISTRICT
SM250227,8,6,Ekoi,ETHNICITY,ETHNICITY
SM250229,19.71,-1.3,Ekondo,ETHNICITY,ETHNICITY
SM250229,19.71,-1.3,Ekondo,ETHNICITY,ETHNICITY
SM250229,18,-2,Ekondo,ETHNICITY,ETHNICITY
SM250229,18,-2,Ekondo,ETHNICITY,ETHNICITY
SM230565,6.5336,5.0672,Ekpeye,LANGUAGE,LANGUAGE
SM296646,15.2181,57.6201,Eksjö,ADM2,DISTRICT
SM296699,31.5281,-26.3875,Ekukhanyeni,ADM2,DISTRICT
SM11394,-0.9087,5.2617,Ekumfi,ADM2,DISTRICT
SM260461,15.4894,-12.5226,Ekunha,ADM2,DISTRICT
SM305111,28.3126,-26.1989,Ekurhuleni,ADM2,DISTRICT
SM285309,6.8399,5.9807,Ekwusigo,ADM2,DISTRICT
SM275194,-89.8489,14.1736,El Adelanto,ADM2,DISTRICT
SM298062,9.9955,37.1577,El Alia,ADM2,DISTRICT
SM285475,-84.7018,11.6784,El Almendro,ADM2,DISTRICT
SM261008,-65.4167,-28.4167,El Alto,ADM2,DISTRICT
SM230568,-96.9987,16.8742,El Alto Zapotec,LANGUAGE,LANGUAGE
SM297946,10.8702,34.9519,El Amra,ADM2,DISTRICT
SM457304,8.0833,35.75,El Aouïnet District,ADM2,DISTRICT
SM253310,3.52,32.66,El Arbaa,ETHNICITY,ETHNICITY
SM281665,-98.8884,20.2157,El Arenal,ADM2,DISTRICT
SM281793,-103.659,20.7546,El Arenal,ADM2,DISTRICT
SM275256,-91.7486,14.5671,El Asintal,ADM2,DISTRICT
SM270208,-74.6789,7.7444,El Bagre,ADM2,DISTRICT
SM270143,-73.9763,9.1241,El Banco,ADM2,DISTRICT
SM14273,-95.0391,16.8013,El Barrio de la Soledad,ADM2,DISTRICT
SM297892,9.7633,36.7933,El Battan,ADM2,DISTRICT
SM1186,1.1667,32.5,El Bayadh,ADM1,DISTRICT
SM1187,-65.5,-14,El Beni,ADM1,DISTRICT
SM283135,-92.7167,17.0667,El Bosque,ADM2,DISTRICT
SM270610,-76.2444,4.7489,El Cairo,ADM2,DISTRICT
SM303858,-61.8287,7.3333,El Callao,ADM2,DISTRICT
SM270296,-73.7443,4.3543,El Calvario,ADM2,DISTRICT
SM269929,-76.7728,5.3599,El Cantón del San Pablo,ADM2,DISTRICT
SM273507,-79.5786,-0.4085,El Carmen,ADM2,DISTRICT
SM295713,-87.9938,13.3201,El Carmen,ADM2,DISTRICT
SM295649,-88.9079,13.7324,El Carmen,ADM2,DISTRICT
SM270364,-73.353,8.8517,El Carmen,ADM2,DISTRICT
SM260833,-65.1667,-24.4167,El Carmen,ADM2,DISTRICT
SM282683,-97.683,19.328,El Carmen Tequexquitla,ADM2,DISTRICT
SM269930,-76.25,5.8333,El Carmen de Atrato,ADM2,DISTRICT
SM270682,-75.1596,9.7088,El Carmen de Bolívar,ADM2,DISTRICT
SM270459,-73.6154,6.6704,El Carmen de Chucurí,ADM2,DISTRICT
SM270209,-75.2632,5.9781,El Carmen de Viboral,ADM2,DISTRICT
SM295639,-88.9138,14.1864,El Carrizal,ADM2,DISTRICT
SM15187,-84.3969,11.0179,El Castillo,ADM2,DISTRICT
SM270297,-73.9644,3.5772,El Castillo,ADM2,DISTRICT
SM271843,-71.4667,18.7,El Cercado,ADM2,DISTRICT
SM270611,-76.2096,3.6427,El Cerrito,ADM2,DISTRICT
SM273527,-77.8423,-0.1372,El Chaco,ADM2,DISTRICT
SM270307,-78.0998,2.4691,El Charco,ADM2,DISTRICT
SM15681,-66.0625,18.3916,El Cinco Barrio,ADM2,DISTRICT
SM270766,-72.4358,6.3284,El Cocuy,ADM2,DISTRICT
SM269989,-74.4261,4.5605,El Colegio,ADM2,DISTRICT
SM286829,-69.7552,-16.7264,El Collao,ADM2,DISTRICT
SM295552,-89.5047,13.839,El Congo,ADM2,DISTRICT
SM269911,-73.9333,10.1854,El Copey,ADM2,DISTRICT
SM15188,-84.6476,11.9165,El Coral,ADM2,DISTRICT
SM275824,-87.0097,13.3046,El Corpus,ADM2,DISTRICT
SM15189,-86.3114,11.9803,El Crucero,ADM2,DISTRICT
SM260939,-68.5,-39.5833,El Cuy,ADM2,DISTRICT
SM17899,33.3946,12.5908,El Dali Locality,ADM2,DISTRICT
SM295740,-88.0774,13.5912,El Divisadero,ADM2,DISTRICT
SM270874,-75.2859,1.7086,El Doncello,ADM2,DISTRICT
SM303220,-120.5247,38.7787,El Dorado,ADM2,DISTRICT
SM286837,-76.7413,-6.563,El Dorado,ADM2,DISTRICT
SM270298,-73.8325,3.7077,El Dorado,ADM2,DISTRICT
SM270612,-76.2854,4.5438,El Dovio,ADM2,DISTRICT
SM273454,-79.6167,-1.05,El Empalme,ADM2,DISTRICT
SM10402,-73.2568,-1.5626,El Encanto (1997-),ADM2,DISTRICT
SM14274,-95.042,16.4675,El Espinal,ADM2,DISTRICT
SM270767,-72.4804,6.5081,El Espino,ADM2,DISTRICT
SM275506,-89.4488,15.4282,El Estor,ADM2,DISTRICT
SM457480,5.5,36.0833,El Eulma District,ADM2,DISTRICT
SM271791,-69.9167,19.2833,El Factor,ADM2,DISTRICT
SM282549,-108.7493,26.1917,El Fuerte,ADM2,DISTRICT
SM297947,10.2199,34.5009,El Ghraiba,ADM2,DISTRICT
SM281794,-104.2016,19.7939,El Grullo,ADM2,DISTRICT
SM273624,-79.85,-3.25,El Guabo,ADM2,DISTRICT
SM270460,-73.5296,6.2486,El Guacamayo,ADM2,DISTRICT
SM270683,-74.9348,10.0175,El Guamo,ADM2,DISTRICT
SM270915,-83.9274,9.7393,El Guarco,ADM2,DISTRICT
SM281460,-5.4612,33.7288,El Hajeb,ADM2,DISTRICT
SM303655,-66.8161,10.4299,El Hatillo,ADM2,DISTRICT
SM14275,-98.4081,21.743,El Higo,ADM2,DISTRICT
SM230581,28.5901,11.948,El Hugeirat,LANGUAGE,LANGUAGE
SM4429,-8.5,32.9,El Jadida (2009-),ADM2,DISTRICT
SM297884,10.7273,35.3003,El Jem,ADM2,DISTRICT
SM285417,-86.3893,12.6554,El Jicaral,ADM2,DISTRICT
SM275416,-89.916,14.8875,El Jícaro,ADM2,DISTRICT
SM457491,8.25,36.6667,El Kala District,ADM2,DISTRICT
SM4448,-7.75,32.1667,El Kelaa des Sraghna (2015-),ADM2,DISTRICT
SM297970,9.1032,36.3429,El Krib,ADM2,DISTRICT
SM281795,-104.1122,19.8295,El Limón,ADM2,DISTRICT
SM269913,-76.9693,4.2507,El Litoral del San Juan,ADM2,DISTRICT
SM14276,-101.9667,21.9167,El Llano,ADM2,DISTRICT
SM271779,-71.6667,18.7667,El Llano,ADM2,DISTRICT
SM269097,-68.1872,-22.9676,El Loa,ADM2,DISTRICT
SM457497,5.9167,33.95,El M'Ghair District,ADM2,DISTRICT
SM282639,-98.9382,22.6724,El Mante,ADM2,DISTRICT
SM282461,-100.2744,20.6585,El Marqués,ADM2,DISTRICT
SM457202,3,30.5,El Menia District,ADM2,DISTRICT
SM457202,3,30.5,El Menia District,ADM2,DISTRICT
SM457202,2.8833,30.5833,El Menia District,ADM2,DISTRICT
SM457202,2.8833,30.5833,El Menia District,ADM2,DISTRICT
SM298034,10.1573,36.8384,El Menzah,ADM2,DISTRICT
SM280910,35.6937,33.9185,El Metn,ADM2,DISTRICT
SM280910,35.6937,33.9185,El Metn,ADM2,DISTRICT
SM280910,35.7364,33.8942,El Metn,ADM2,DISTRICT
SM280910,35.7364,33.8942,El Metn,ADM2,DISTRICT
SM297933,10.8468,36.7225,El Mida,ADM2,DISTRICT
SM457502,6.25,36.75,El Milia District,ADM2,DISTRICT
SM14192,-15.9787,18.0631,El Mina,ADM2,DISTRICT
SM270119,-72.8539,10.5946,El Molino,ADM2,DISTRICT
SM230589,36.7619,2.7931,El Molo,LANGUAGE,LANGUAGE
SM253311,37.14,2.88,El Molo,ETHNICITY,ETHNICITY
SM253311,37.14,2.88,El Molo,ETHNICITY,ETHNICITY
SM253311,36.8,2.8,El Molo,ETHNICITY,ETHNICITY
SM253311,36.8,2.8,El Molo,ETHNICITY,ETHNICITY
SM298048,10.2108,36.7179,El Mourouj,ADM2,DISTRICT
SM14277,-99.3442,22.4724,El Naranjo,ADM2,DISTRICT
SM282146,-104.6504,22.1186,El Nayar,ADM2,DISTRICT
SM230590,-104.467,22.4099,El Nayar Cora,LANGUAGE,LANGUAGE
SM275805,-87.68,15.42,El Negrito,ADM2,DISTRICT
SM275791,-88.3444,14.7596,El Nispero,ADM2,DISTRICT
SM281915,-100.076,19.7908,El Oro,ADM2,DISTRICT
SM281530,-105.2673,25.6922,El Oro,ADM2,DISTRICT
SM1188,-79.8333,-3.5,El Oro,ADM1,DISTRICT
SM298035,10.1829,36.7729,El Ouardia,ADM2,DISTRICT
SM10288,14.448,9.5431,El Ouaya,ADM2,DISTRICT
SM1189,7.25,33.1667,El Oued,ADM1,DISTRICT
SM295526,-89.2222,14.0083,El Paisnal,ADM2,DISTRICT
SM275232,-91.6099,14.6956,El Palmar,ADM2,DISTRICT
SM273425,-78.6541,-2.8636,El Pan,ADM2,DISTRICT
SM273577,-78.5443,-3.6936,El Pangui,ADM2,DISTRICT
SM275907,-86.5309,13.8538,El Paraíso,ADM2,DISTRICT
SM275881,-88.9805,15.0422,El Paraíso,ADM2,DISTRICT
SM295607,-89.0846,14.102,El Paraíso,ADM2,DISTRICT
SM1190,-86.5,14.0833,El Paraíso,ADM1,DISTRICT
SM302861,-106.2348,31.7686,El Paso,ADM2,DISTRICT
SM303331,-104.5256,38.8321,El Paso,ADM2,DISTRICT
SM269889,-73.7142,9.6584,El Paso,ADM2,DISTRICT
SM270875,-75.314,1.5709,El Paujíl,ADM2,DISTRICT
SM10403,-77.4308,1.5129,El Penol,ADM2,DISTRICT
SM10404,-73.9115,8.8654,El Penon,ADM2,DISTRICT
SM270461,-73.9284,6.099,El Peñon,ADM2,DISTRICT
SM269990,-74.3333,5.26,El Peñon,ADM2,DISTRICT
SM271864,-71.2093,18.2976,El Peñón,ADM2,DISTRICT
SM271892,-71.4976,19.4062,El Pino,ADM2,DISTRICT
SM270144,-74.6667,10.3333,El Piñón,ADM2,DISTRICT
SM270462,-73.1956,7.517,El Playón,ADM2,DISTRICT
SM283136,-92.2645,15.4309,El Porvenir,ADM2,DISTRICT
SM275937,-87.2104,14.7201,El Porvenir,ADM2,DISTRICT
SM275651,-86.9105,15.666,El Porvenir,ADM2,DISTRICT
SM295553,-89.6427,14.04,El Porvenir,ADM2,DISTRICT
SM275806,-87.774,15.4276,El Progreso,ADM2,DISTRICT
SM275195,-89.8486,14.374,El Progreso,ADM2,DISTRICT
SM1191,-90,14.8333,El Progreso,ADM1,DISTRICT
SM275304,-91.8118,14.7564,El Quetzal,ADM2,DISTRICT
SM15190,-84.2167,12.15,El Rama,ADM2,DISTRICT
SM285516,-87.1863,12.5529,El Realejo,ADM2,DISTRICT
SM295505,-89.7082,13.9834,El Refugio,ADM2,DISTRICT
SM10405,-74.3255,10.6611,El Reten,ADM2,DISTRICT
SM270077,-71.5756,1.9261,El Retorno,ADM2,DISTRICT
SM15682,-65.8433,18.2388,El Rio Barrio,ADM2,DISTRICT
SM10406,-75.1368,9.07,El Roble,ADM2,DISTRICT
SM275305,-91.9976,14.8962,El Rodeo,ADM2,DISTRICT
SM10407,-74.2667,4.85,El Rosal,ADM2,DISTRICT
SM285504,-86.153,11.8328,El Rosario,ADM2,DISTRICT
SM282532,-105.8577,23.0176,El Rosario,ADM2,DISTRICT
SM275760,-86.6876,14.8872,El Rosario,ADM2,DISTRICT
SM275853,-87.7431,14.575,El Rosario,ADM2,DISTRICT
SM295689,-89.0017,13.4636,El Rosario,ADM2,DISTRICT
SM295741,-88.2,13.8667,El Rosario,ADM2,DISTRICT
SM295650,-88.9027,13.7719,El Rosario,ADM2,DISTRICT
SM270308,-77.4062,1.8459,El Rosario,ADM2,DISTRICT
SM281796,-103.2278,20.5288,El Salto,ADM2,DISTRICT
SM283039,-100.9203,24.4698,El Salvador,ADM2,DISTRICT
SM271117,-75.2299,20.2189,El Salvador,ADM2,DISTRICT
SM270210,-75.2571,6.1357,El Santuario,ADM2,DISTRICT
SM285418,-86.5399,12.8815,El Sauce,ADM2,DISTRICT
SM295714,-87.7913,13.6631,El Sauce,ADM2,DISTRICT
SM1192,-69.05,18.8,El Seybo,ADM1,DISTRICT
SM303596,-65.7456,8.9936,El Socorro,ADM2,DISTRICT
SM270309,-76.9853,1.412,El Tablón de Gomez,ADM2,DISTRICT
SM298036,10.1263,36.8237,El Tahrir,ADM2,DISTRICT
SM273588,-78.9249,-2.5081,El Tambo,ADM2,DISTRICT
SM269851,-77.0142,2.5368,El Tambo,ADM2,DISTRICT
SM10408,-77.3833,1.4305,El Tambo (1998-),ADM2,DISTRICT
SM1193,8.1667,36.75,El Tarf,ADM1,DISTRICT
SM270365,-73.0531,8.6545,El Tarra,ADM2,DISTRICT
SM275399,-90.7899,14.6706,El Tejar,ADM2,DISTRICT
SM275825,-87.0178,13.0802,El Triunfo,ADM2,DISTRICT
SM295592,-88.4155,13.5983,El Triunfo,ADM2,DISTRICT
SM273455,-79.3623,-2.2916,El Triunfo,ADM2,DISTRICT
SM295761,-88.2793,13.3594,El Tránsito,ADM2,DISTRICT
SM283225,-106.3174,27.0733,El Tule,ADM2,DISTRICT
SM275306,-91.9484,14.8499,El Tumbador,ADM2,DISTRICT
SM271910,-69.3833,18.9333,El Valle,ADM2,DISTRICT
SM285517,-87.391,12.7998,El Viejo,ADM2,DISTRICT
SM270366,-72.6599,8.0654,El Zulia,ADM2,DISTRICT
SM270609,-76.0568,4.9195,El Águila,ADM2,DISTRICT
SM293030,42.8333,43.4167,El'brusskiy rayon,ADM2,DISTRICT
SM292961,86.4167,53.25,El'tsovskiy rayon,ADM2,DISTRICT
SM274025,38.6286,15.7106,Elabered,ADM2,DISTRICT
SM17869,47.2173,9.9313,Elafweyn (excl. Ceel Afweyn city),ADM2,DISTRICT
SM230569,48.2333,31.1833,Elamite,LANGUAGE,LANGUAGE
SM294935,43.75,51,Elanskiy rayon,ADM2,DISTRICT
SM253314,146.17,7.51,Elato,ETHNICITY,ETHNICITY
SM14919,146.1718,7.5124,Elato Municipality,ADM2,DISTRICT
SM1194,39.1773,38.737,Elazığ,ADM1,DISTRICT
SM1195,20.2,41.0667,Elbasan,ADM1,DISTRICT
SM260483,20,41.0833,Elbasanit,ADM2,DISTRICT
SM300487,-82.8401,34.1168,Elbert,ADM2,DISTRICT
SM303332,-104.1359,39.2866,Elbert,ADM2,DISTRICT
SM298624,37.44,36.7256,Elbeyli,ADM2,DISTRICT
SM298563,37.2046,38.2154,Elbistan,ADM2,DISTRICT
SM288782,19.6377,54.1593,Elbląg,ADM2,DISTRICT
SM288781,19.4344,54.1988,Elbląg (City),ADM2,DISTRICT
SM285770,5.8487,52.4149,Elburg,ADM2,DISTRICT
SM12311,-77.7938,18.2242,Elderslie,ADM2,DISTRICT
SM298280,33.5057,40.5392,Eldivan,ADM2,DISTRICT
SM266504,-48.256,-24.5006,Eldorado,ADM2,DISTRICT
SM263047,-54.2157,-23.7796,Eldorado,ADM2,DISTRICT
SM260911,-54.4167,-26.25,Eldorado,ADM2,DISTRICT
SM265641,-51.534,-30.089,Eldorado do Sul,ADM2,DISTRICT
SM264164,-49.3033,-6.1059,Eldorado dos Carajás,ADM2,DISTRICT
SM10685,32.5662,34.8154,Eledio,ADM2,DISTRICT
SM13507,23.7016,56.4196,Elejas pagasts,ADM2,DISTRICT
SM14136,24.6611,54.786,Elektrenai,ADM2,DISTRICT
SM230575,7.267,4.7296,Eleme,LANGUAGE,LANGUAGE
SM285218,7.1437,4.7874,Eleme,ADM2,DISTRICT
SM262466,25.95,42.9167,Elena,ADM2,DISTRICT
SM230577,143.93,-3.7505,Elepi,LANGUAGE,LANGUAGE
SM265220,-42.1661,-6.1936,Elesbão Veloso,ADM2,DISTRICT
SM12312,-76.6434,17.9374,Eleven Miles,ADM2,DISTRICT
SM298486,42.6796,39.8124,Eleşkirt,ADM2,DISTRICT
SM1196,35.55,0.99,Elgeyo-Marakwet,ADM1:ADM2,DISTRICT
SM268894,-81.6665,42.5001,Elgin,ADM2,DISTRICT
SM262498,26.6167,42.1167,Elhovo,ADM2,DISTRICT
SM18536,33.2174,35.3354,Elia (Kyrenia),ADM2,DISTRICT
SM10686,32.9222,35.1424,Elia (Nicosia),ADM2,DISTRICT
SM266505,-47.3878,-23.0588,Elias Fausto,ADM2,DISTRICT
SM262436,23.6,42.6667,Elin Pelin,ADM2,DISTRICT
SM262729,-69.1012,-15.5169,Eliodoro Camacho,ADM2,DISTRICT
SM230585,11.193,4.4569,Elip,LANGUAGE,LANGUAGE
SM253315,141.75,-4.47,Eliptamin,ETHNICITY,ETHNICITY
SM265221,-43.7777,-7.9016,Eliseu Martins,ADM2,DISTRICT
SM266506,-49.0911,-21.1552,Elisiário,ADM2,DISTRICT
SM293059,44.3044,46.3009,Elista,ADM2,DISTRICT
SM293099,159,53.6667,Elizovskiy rayon,ADM2,DISTRICT
SM302301,-78.6491,41.4252,Elk,ADM2,DISTRICT
SM300906,-96.2441,37.4537,Elk,ADM2,DISTRICT
SM324068,-110.9014,53.9004,Elk Point,ADM2:ADM3,DISTRICT
SM230587,142.093,-3.4554,Elkei,LANGUAGE,LANGUAGE
SM300703,-85.8588,41.5974,Elkhart,ADM2,DISTRICT
SM301783,-115.3578,41.1458,Elko,ADM2,DISTRICT
SM253316,-116,41,Elko Shoshoni,ETHNICITY,ETHNICITY
SM13508,25.6,56.2303,Elksnu pagasts,ADM2,DISTRICT
SM370446,5.8572,49.7639,Ell,ADM1:ADM2:ADM3,DISTRICT
SM4406,-2.3222,4.9639,Ellembelle,ADM2,DISTRICT
SM12313,-77.4393,17.968,Ellen Street,ADM2,DISTRICT
SM12314,-76.7403,18.0095,Elleston Flats/ Mona Commons,ADM2,DISTRICT
SM10176,-101.2822,50.4597,Ellice-Archie,ADM2,DISTRICT
SM303508,61.7638,42.3558,Ellikqala,ADM2,DISTRICT
SM301034,-83.0976,38.1179,Elliott,ADM2,DISTRICT
SM302862,-96.7945,32.3484,Ellis,ADM2,DISTRICT
SM302187,-99.7546,36.2184,Ellis,ADM2,DISTRICT
SM300907,-99.3172,38.9148,Ellis,ADM2,DISTRICT
SM261690,135.0951,-33.5393,Elliston,ADM2,DISTRICT
SM300908,-98.2047,38.6966,Ellsworth,ADM2,DISTRICT
SM298905,33.2381,39.9312,Elmadağ,ADM2,DISTRICT
SM299018,29.9232,36.7464,Elmalı,ADM2,DISTRICT
SM300556,-115.4692,43.3539,Elmore,ADM2,DISTRICT
SM300264,-86.1492,32.5966,Elmore,ADM2,DISTRICT
SM323984,-113.2015,51.9977,Elnora,ADM2:ADM3,DISTRICT
SM282533,-106.8449,24.0346,Elota,ADM2,DISTRICT
SM230591,-97.1912,16.8693,Elotepec Zapotec,LANGUAGE,LANGUAGE
SM14278,-96.8772,18.1858,Eloxochitlan de Flores Magon,ADM2,DISTRICT
SM282404,-96.9227,18.5088,Eloxochitlán,ADM2,DISTRICT
SM281667,-98.8769,20.727,Eloxochitlán,ADM2,DISTRICT
SM273630,-79,1.25,Eloy Alfaro,ADM2,DISTRICT
SM230593,7.9706,8.247,Eloyi,LANGUAGE,LANGUAGE
SM230595,128.263,-3.078,Elpaputih,LANGUAGE,LANGUAGE
SM269110,-70.6446,-29.7359,Elqui,ADM2,DISTRICT
SM230596,140.418,-3.0018,Elseng,LANGUAGE,LANGUAGE
SM327813,-99.8359,49.9747,Elton,ADM2:ADM3,DISTRICT
SM230601,147.101,-1.9984,Elu,LANGUAGE,LANGUAGE
SM11162,26.2726,58.2456,Elva vald,ADM2,DISTRICT
SM289297,-7.2243,38.8987,Elvas,ADM2,DISTRICT
SM286372,11.5631,60.8816,Elverum,ADM2,DISTRICT
SM253317,-115,39,Ely Shoshoni,ETHNICITY,ETHNICITY
SM230602,12.8322,37.9414,Elymian,LANGUAGE,LANGUAGE
SM270116,-75.9806,1.9901,Elías,ADM2,DISTRICT
SM267391,-39.5058,-12.9543,Elísio Medrado,ADM2,DISTRICT
SM263410,-45.5947,-21.6007,Elói Mendes,ADM2,DISTRICT
SM230604,168.399,-17.052,Emae,LANGUAGE,LANGUAGE
SM230605,5.8449,7.0909,Emai-Iuleha-Ora,LANGUAGE,LANGUAGE
SM15683,-65.8767,18.0048,Emajagua Barrio,ADM2,DISTRICT
SM15684,-66.4398,18.0739,Emajagual Barrio,ADM2,DISTRICT
SM230607,9.7056,6.2292,Eman,LANGUAGE,LANGUAGE
SM300488,-82.3017,32.5898,Emanuel,ADM2,DISTRICT
SM292739,61.3333,54.75,Emanzhelinsk gorsovet,ADM2,DISTRICT
SM264419,-37.7388,-7.1268,Emas,ADM2,DISTRICT
SM230609,112.63,1.3156,Embaloh,LANGUAGE,LANGUAGE
SM266507,-48.8582,-20.943,Embaúba,ADM2,DISTRICT
SM252852,-77.03,6.43,Embera,ETHNICITY,ETHNICITY
SM252852,-77.03,6.43,Embera,ETHNICITY,ETHNICITY
SM252852,-78,8,Embera,ETHNICITY,ETHNICITY
SM252852,-78,8,Embera,ETHNICITY,ETHNICITY
SM1197,-77.525,8.1524,Emberá,ADM1,DISTRICT
SM230611,-76.3255,5.2824,Emberá-Baudó,LANGUAGE,LANGUAGE
SM230612,-76.7476,7.5272,Emberá-Catío,LANGUAGE,LANGUAGE
SM230614,-76.0749,4.8791,Emberá-Chamí,LANGUAGE,LANGUAGE
SM230613,-77.1533,5.2366,Emberá-Tadó,LANGUAGE,LANGUAGE
SM289543,-57.363,-25.1288,Emboscada,ADM2,DISTRICT
SM289702,-56.0828,-26.221,Emboscada (Caazapa),ADM2,DISTRICT
SM230615,37.5933,-0.6282,Embu,LANGUAGE,LANGUAGE
SM266509,-46.8539,-23.6466,Embu,ADM2,DISTRICT
SM253321,41.15,1.55,Embu,ETHNICITY,ETHNICITY
SM1198,37.531,-0.425,"Embu (pre-1996, 2010-)",ADM1:ADM2,DISTRICT
SM266508,-46.8396,-23.8534,Embu-Guaçu,ADM2,DISTRICT
SM13509,21.8237,56.505,Embutes pagasts,ADM2,DISTRICT
SM293419,92.75,56.4167,Emel'yanovskiy rayon,ADM2,DISTRICT
SM302920,-110.7007,38.9968,Emery,ADM2,DISTRICT
SM298706,29.2743,39.3522,Emet,ADM2,DISTRICT
SM10289,18.1431,20.3616,Emi-Koussi,ADM2,DISTRICT
SM1199,10.9836,44.5444,Emilia-Romagna,ADM1,DISTRICT
SM230619,11.5794,44.3536,Emiliano,LANGUAGE,LANGUAGE
SM282635,-91.6758,17.7052,Emiliano Zapata,ADM2,DISTRICT
SM282891,-96.7658,19.4577,Emiliano Zapata,ADM2,DISTRICT
SM14279,-97.9306,19.5639,Emiliano Zapata,ADM2,DISTRICT
SM282129,-99.1871,18.8106,Emiliano Zapata,ADM2,DISTRICT
SM281668,-98.5668,19.6614,Emiliano Zapata,ADM2,DISTRICT
SM266511,-51.4796,-21.7818,Emilianópolis,ADM2,DISTRICT
SM298377,31.1546,39.0281,Emirdağ,ADM2,DISTRICT
SM298670,33.8467,37.9123,Emirgazi,ADM2,DISTRICT
SM11209,31.3667,-25.7667,Emkhuzweni Mission,ADM2,DISTRICT
SM296658,15.5333,56.6333,Emmaboda,ADM2,DISTRICT
SM285554,6.9594,52.7524,Emmen,ADM2,DISTRICT
SM17916,7.6618,47.0443,Emmental District,ADM2,DISTRICT
SM301267,-84.9815,45.5875,Emmet,ADM2,DISTRICT
SM300809,-94.6785,43.378,Emmet,ADM2,DISTRICT
SM458793,130.1162,-13.5168,Emmi,ETHNICITY,ETHNICITY
SM302036,-100.2388,46.285,Emmons,ADM2,DISTRICT
SM230622,-57.8892,-24.9202,Emok,LANGUAGE,LANGUAGE
SM253322,150.26,-26.17,Emon,ETHNICITY,ETHNICITY
SM253322,150.26,-26.17,Emon,ETHNICITY,ETHNICITY
SM253322,149.5771,-25.589,Emon,ETHNICITY,ETHNICITY
SM253322,149.5771,-25.589,Emon,ETHNICITY,ETHNICITY
SM10687,32.4335,34.8406,Empa,ADM2,DISTRICT
SM275106,-15.2333,11.55,Empada,ADM2,DISTRICT
SM282565,-110.6772,28.02,Empalme,ADM2,DISTRICT
SM18331,-55.8776,-34.6408,Empalme Olmos,ADM2,DISTRICT
SM276666,102.9451,-3.7163,Empat Lawang,ADM2,DISTRICT
SM261169,-58.6667,-27.8333,Empedrado,ADM2,DISTRICT
SM230627,129.721,-8.0321,Emplawas,LANGUAGE,LANGUAGE
SM303001,-77.5425,36.686,Emporia,ADM2,DISTRICT
SM323871,-110.0164,50.9548,Empress,ADM2:ADM3,DISTRICT
SM243977,7.721,53.117,Ems-Weser Frisian,LANGUAGE,LANGUAGE
SM280263,34.6626,-0.0304,Emuhaya (2013-),ADM2,DISTRICT
SM230631,140.903,-3.7424,Emumu,LANGUAGE,LANGUAGE
SM285197,6.7474,5.0067,Emuoha,ADM2,DISTRICT
SM284717,5.5162,7.4321,Emure/Ise/Orun,ADM2,DISTRICT
SM230666,106.167,22.8386,En,LANGUAGE,LANGUAGE
SM17356,-60.9476,13.7816,En Bamboo,ADM2,DISTRICT
SM17357,-60.9246,13.8,En Mango,ADM2,DISTRICT
SM295203,29.046,12.8808,En Nuhud,ADM2,DISTRICT
SM17358,-60.941,13.9885,En Pois Doux/Babonneau,ADM2,DISTRICT
SM279798,137.4181,35.4472,Ena,ADM2,DISTRICT
SM230637,-58.9802,-12.4302,Enawené-Nawé,LANGUAGE,LANGUAGE
SM17359,-60.9927,13.9118,Enbar Pwin,ADM2,DISTRICT
SM279924,71,53,Enbekshilderskiy,ADM2,DISTRICT
SM1200,1.6333,42.5333,Encamp,ADM1,DISTRICT
SM265642,-51.9255,-29.2002,Encantado,ADM2,DISTRICT
SM265536,-38.3113,-6.1164,Encanto,ADM2,DISTRICT
SM15685,-66.7088,17.9888,Encarnacion Barrio,ADM2,DISTRICT
SM289564,-55.8952,-27.2623,Encarnación,ADM2,DISTRICT
SM281797,-102.1773,21.5803,Encarnación de Díaz,ADM2,DISTRICT
SM270464,-73.0674,6.0534,Encino,ADM2,DISTRICT
SM270465,-72.7071,6.6465,Enciso,ADM2,DISTRICT
SM271063,-79.8184,22.6718,Encrucijada,ADM2,DISTRICT
SM267392,-40.9079,-15.5684,Encruzilhada,ADM2,DISTRICT
SM265643,-52.6828,-30.6037,Encruzilhada do Sul,ADM2,DISTRICT
SM276240,21.0817,48.3995,Encsi,ADM2,DISTRICT
SM230640,121.555,-8.71,Ende,LANGUAGE,LANGUAGE
SM276506,121.6639,-8.8406,Ende,ADM2,DISTRICT
SM230638,142.2408,-8.9578,Ende (Papua New Guinea),DIALECT,LANGUAGE
SM253324,121.34,-8.3,Endenese,ETHNICITY,ETHNICITY
SM13325,-171.0833,-3.1333,Enderbury,ADM0:ADM2,DISTRICT
SM284534,15.7167,-17.6,Endola,ADM2,DISTRICT
SM15686,-66.9322,18.3287,Eneas Barrio,ADM2,DISTRICT
SM286052,11.1449,59.7645,Enebakk,ADM2,DISTRICT
SM4078,162.25,11.5,Enewetak Atoll,ADM1,DISTRICT
SM298341,26.1024,40.7339,Enez,ADM2,DISTRICT
SM297995,10.3115,36.1373,Enfidha,ADM2,DISTRICT
SM12315,-76.7375,18.2204,Enfield (Saint Mary),ADM2,DISTRICT
SM18535,-77.9527,18.214,Enfield (Westmoreland),ADM2,DISTRICT
SM230651,143.685,-5.4562,Enga,LANGUAGE,LANGUAGE
SM1201,143.5,-5.5,Enga,ADM1,DISTRICT
SM253325,143,-5.96,Enga,ETHNICITY,ETHNICITY
SM253325,143,-5.96,Enga,ETHNICITY,ETHNICITY
SM253325,144,-6,Enga,ETHNICITY,ETHNICITY
SM253325,144,-6,Enga,ETHNICITY,ETHNICITY
SM230652,143.4414,-5.4084,Enga Sign Language,LANGUAGE,LANGUAGE
SM13186,77.5,43.4167,Engbekshiqazaq Audany (excl. Shelek),ADM2,DISTRICT
SM16889,46.1667,51.5,Engel'sskiy Rayon,ADM2,DISTRICT
SM264451,-52.2089,-23.7554,Engenheiro Beltrão,ADM2,DISTRICT
SM263411,-42.0179,-19.1135,Engenheiro Caldas,ADM2,DISTRICT
SM266512,-47.1726,-22.489,Engenheiro Coelho,ADM2,DISTRICT
SM263412,-44.0347,-17.3093,Engenheiro Navarro,ADM2,DISTRICT
SM265264,-43.6727,-22.5481,Engenheiro Paulo de Front,ADM2,DISTRICT
SM265644,-52.9104,-27.677,Engenho Velho,ADM2,DISTRICT
SM230655,6.3734,5.1193,Engenni,LANGUAGE,LANGUAGE
SM286373,11.9597,61.7577,Engerdal,ADM2,DISTRICT
SM253326,102.3,-5.34,Engganese,ETHNICITY,ETHNICITY
SM230656,102.245,-5.3885,Enggano,LANGUAGE,LANGUAGE
SM1202,-0.7031,52.1605,England,ADM1,DISTRICT
SM245522,-1,53,English,LANGUAGE,LANGUAGE
SM1203,55.4541,-4.6118,English River,ADM1,DISTRICT
SM284470,17.2586,-18.22,Engodi,ADM2,DISTRICT
SM4018,23.2839,57.0275,Engures Novads,ADM1,DISTRICT
SM13510,23.188,57.1695,Engures pagasts,ADM2,DISTRICT
SM293420,89,60,Eniseyskiy rayon,ADM2,DISTRICT
SM278243,141.5667,42.8833,Eniwa,ADM2,DISTRICT
SM285980,5.2762,52.7165,Enkhuizen,ADM2,DISTRICT
SM296497,17.1167,59.6858,Enköping,ADM2,DISTRICT
SM277901,14.4333,37.5833,Enna,ADM2,DISTRICT
SM1204,23.1667,17.75,Ennedi Est,ADM1,DISTRICT
SM1205,21.4,18.4,Ennedi Ouest,ADM1,DISTRICT
SM293850,47,47.1667,Enotaevskiy rayon,ADM2,DISTRICT
SM230669,119.66,-3.5385,Enrekang,LANGUAGE,LANGUAGE
SM276589,119.8667,-3.5,Enrekang,ADM2,DISTRICT
SM9830,-67.6037,-21.3832,Enrique Baldivieso,ADM2,DISTRICT
SM271865,-71.25,17.9167,Enriquillo,ADM2,DISTRICT
SM285581,6.8804,52.2191,Enschede,ADM2,DISTRICT
SM282724,-116.6167,31.8667,Ensenada,ADM2,DISTRICT
SM260754,-57.9189,-34.8583,Ensenada,ADM2,DISTRICT
SM15687,-66.9287,17.9697,Ensenada Barrio (Guánica),ADM2,DISTRICT
SM15688,-67.2534,18.3467,Ensenada Barrio (Rincon),ADM2,DISTRICT
SM269178,109.6756,30.1981,Enshi Tujia and Miao,ADM2,DISTRICT
SM12316,-76.9622,18.0178,Ensom,ADM2,DISTRICT
SM268921,8.0143,46.9259,Entlebuch,ADM2,DISTRICT
SM263414,-42.241,-19.6596,Entre Folhas,ADM2,DISTRICT
SM266414,-52.597,-26.7314,Entre Rios,ADM2,DISTRICT
SM267393,-38.0299,-12.0697,Entre Rios,ADM2,DISTRICT
SM263415,-44.1069,-20.7028,Entre Rios de Minas,ADM2,DISTRICT
SM264452,-54.2156,-24.7041,Entre Rios do Oeste,ADM2,DISTRICT
SM265646,-52.7286,-27.5082,Entre Rios do Sul,ADM2,DISTRICT
SM1206,-59,-32,Entre Ríos,ADM1,DISTRICT
SM265645,-54.3032,-28.4607,Entre-Ijuís,ADM2,DISTRICT
SM268990,7.2126,46.021,Entremont,ADM2,DISTRICT
SM17360,-60.9795,13.9998,Entrepot,ADM2,DISTRICT
SM270211,-75.5833,6.5833,Entrerríos,ADM2,DISTRICT
SM289319,-8.4762,39.4666,Entroncamento,ADM2,DISTRICT
SM230672,101.85,22.95,Enu,LANGUAGE,LANGUAGE
SM1207,7.5,6.5,Enugu (1996-),ADM1,DISTRICT
SM284731,7.5333,6.5333,Enugu East,ADM2,DISTRICT
SM284732,7.5352,6.4448,Enugu North,ADM2,DISTRICT
SM284733,7.511,6.3917,EnuguSou,ADM2,DISTRICT
SM270212,-75.545,6.1534,Envigado,ADM2,DISTRICT
SM267319,-70.1408,-7.6421,Envira,ADM2,DISTRICT
SM230673,-70.945,-0.0356,Envuelto,LANGUAGE,LANGUAGE
SM230675,8.2097,4.6874,Enwan (Akwa Ibom State),LANGUAGE,LANGUAGE
SM230676,6.0739,7.1532,Enwan (Edu State),LANGUAGE,LANGUAGE
SM230678,25.15,0.56,Enya,LANGUAGE,LANGUAGE
SM16304,17.5186,3.1329,Enyelle,ADM2,DISTRICT
SM276259,18.3098,46.8724,Enying,ADM2,DISTRICT
SM264450,-53.159,-25.8645,Enéas Marques,ADM2,DISTRICT
SM459078,151.1755,-33.7777,Eora,ETHNICITY,ETHNICITY
SM14920,151.7392,7.3854,Eot Municipality,ADM2,DISTRICT
SM282405,-98.3791,18.6209,Epatlán,ADM2,DISTRICT
SM281669,-98.638,20.0265,Epazoyucan,ADM2,DISTRICT
SM285006,3.9912,6.5573,Epe,ADM2,DISTRICT
SM285771,5.9618,52.3339,Epe,ADM2,DISTRICT
SM284536,16.2,-17.7667,Epembe,ADM2,DISTRICT
SM230682,-77.3566,4.2588,Epena,LANGUAGE,LANGUAGE
SM16305,17.2328,1.6484,Epena,ADM2,DISTRICT
SM253328,168.17,-16.61,Epi,ETHNICITY,ETHNICITY
SM10688,33.5213,35.2315,Epicho,ADM2,DISTRICT
SM230683,6.3146,5.0089,Epie,LANGUAGE,LANGUAGE
SM230686,-88.7689,18.9931,Epigraphic Mayan,LANGUAGE,LANGUAGE
SM275150,20.7147,39.2365,Epirus,ADM1:ADM2,DISTRICT
SM10689,33.2313,35.0421,Episkopeio,ADM2,DISTRICT
SM18534,32.8828,34.6738,Episkopi (Limassol),ADM2,DISTRICT
SM10690,32.5305,34.7934,Episkopi (Paphos),ADM2,DISTRICT
SM282024,-100.2858,20.1466,Epitacio Huerta,ADM2,DISTRICT
SM262800,-68.4441,-10.9354,Epitaciolândia,ADM2,DISTRICT
SM12317,-76.7264,18.2522,Epson,ADM2,DISTRICT
SM10691,33.1491,34.8447,Eptagoneia,ADM2,DISTRICT
SM10692,34.0345,35.449,Eptakomi,ADM2,DISTRICT
SM284525,13.2483,-17.0039,Epupa,ADM2,DISTRICT
SM292274,27.8958,46.2423,Epureni,ADM2,DISTRICT
SM12318,-77.1334,18.3608,Epworth,ADM2,DISTRICT
SM277715,52.4,30.7,Eqlid,ADM2,DISTRICT
SM265537,-36.6617,-6.8859,Equador,ADM2,DISTRICT
SM462120,18.9333,0.2,Equator district (excl. Mbandaka),ADM1:ADM2,DISTRICT
SM275720,-88.4604,14.2304,Erandique,ADM2,DISTRICT
SM302863,-98.218,32.2363,Erath,ADM2,DISTRICT
SM283943,39.9247,-14.3692,Erati,ADM2,DISTRICT
SM230692,76.8389,10.8003,Eravallan,LANGUAGE,LANGUAGE
SM230694,143.918,-6.5501,Erave,LANGUAGE,LANGUAGE
SM298954,36.5936,40.7139,Erbaa,ADM2,DISTRICT
SM290822,27.2476,47.2522,Erbiceni,ADM2,DISTRICT
SM12033,44.0095,36.1907,Erbil (excl. Deshte Hewlere and Xebat),ADM2,DISTRICT
SM299000,43.3371,39.1512,Erciş,ADM2,DISTRICT
SM276260,18.7995,47.2904,Ercs,ADM2,DISTRICT
SM298178,27.8028,40.4092,Erdek,ADM2,DISTRICT
SM298755,34.296,36.6138,Erdemli,ADM2,DISTRICT
SM283557,97.7241,45.1411,Erdene,ADM2,DISTRICT
SM283806,111.0908,44.4344,Erdene,ADM2,DISTRICT
SM283705,107.883,47.7888,Erdene,ADM2,DISTRICT
SM283616,101.5762,50.1008,Erdenebulgan,ADM2,DISTRICT
SM283535,101.5126,47.4473,Erdenebulgan,ADM2,DISTRICT
SM283535,101.5126,47.4473,Erdenebulgan,ADM2,DISTRICT
SM283535,101.4574,47.4715,Erdenebulgan,ADM2,DISTRICT
SM283535,101.4574,47.4715,Erdenebulgan,ADM2,DISTRICT
SM283594,91.4404,48.4786,Erdenebüren,ADM2,DISTRICT
SM283822,104.9428,45.9963,Erdenedalai,ADM2,DISTRICT
SM283845,95.7131,48.1141,Erdenekhairkhan,ADM2,DISTRICT
SM283536,101.3736,48.5232,Erdenemandal,ADM2,DISTRICT
SM283706,104.5006,47.3202,Erdenesant,ADM2,DISTRICT
SM283679,115.3674,45.8953,Erdenetsagaan,ADM2,DISTRICT
SM283747,100.8226,46.4125,Erdenetsogt,ADM2,DISTRICT
SM283747,100.8226,46.4125,Erdenetsogt,ADM2,DISTRICT
SM283747,101.0285,46.5626,Erdenetsogt,ADM2,DISTRICT
SM283747,101.0285,46.5626,Erdenetsogt,ADM2,DISTRICT
SM305367,19.0597,45.5246,Erdut,ADM2,DISTRICT
SM230697,147.083,-2.1557,Ere,LANGUAGE,LANGUAGE
SM265647,-52.3191,-27.8256,Erebango,ADM2,DISTRICT
SM9921,-52.2559,-27.6767,Erechim (1996-),ADM2,DISTRICT
SM295593,-88.3868,13.3455,Ereguayquín,ADM2,DISTRICT
SM253330,145.96,-7.97,Erema,ETHNICITY,ETHNICITY
SM291141,24.9163,46.6484,Eremitu,ADM2,DISTRICT
SM18043,30.4145,40.7556,Erenler,ADM2,DISTRICT
SM267915,-38.3118,-5.9973,Ererê,ADM2,DISTRICT
SM1210,44.5,40.1833,Erevan,ADM1,DISTRICT
SM13187,73.25,51.5,Ereymentau (excl. Sileti),ADM2,DISTRICT
SM298671,34.0555,37.5231,Ereğli,ADM2,DISTRICT
SM299042,31.6058,41.2381,Ereğli,ADM2,DISTRICT
SM298910,34.9265,41.8891,Erfelek,ADM2,DISTRICT
SM298330,39.7597,38.2652,Ergani,ADM2,DISTRICT
SM10693,33.2306,35.0657,Ergates,ADM2,DISTRICT
SM13511,25.7785,57.8185,Ergemes pagasts,ADM2,DISTRICT
SM18044,27.2708,40.8595,Ergene,ADM2,DISTRICT
SM4034,25.6733,56.8981,Erglu Novads,ADM1,DISTRICT
SM13512,25.6076,56.9187,Erglu pagasts,ADM2,DISTRICT
SM230705,-79.64,42.158,Erie,LANGUAGE,LANGUAGE
SM302302,-80.0981,42.1175,Erie,ADM2,DISTRICT
SM302097,-82.6996,41.4321,Erie,ADM2,DISTRICT
SM301873,-78.7797,42.7582,Erie,ADM2,DISTRICT
SM253331,-81.2,41.39,Erie,ETHNICITY,ETHNICITY
SM4097,169.9918,9.1252,Erikub Atoll,ADM1,DISTRICT
SM10694,32.92,34.6901,Erimi,ADM2,DISTRICT
SM278244,143.2222,42.0627,Erimo,ADM2,DISTRICT
SM230707,138.053,-2.7118,Eritai,LANGUAGE,LANGUAGE
SM253332,37.75,16.77,Eritrea,ETHNICITY,ETHNICITY
SM293421,92.75,52.5,Ermakovskiy rayon,ADM2,DISTRICT
SM285772,5.6554,52.2883,Ermelo,ADM2,DISTRICT
SM298576,32.897,36.6506,Ermenek,ADM2,DISTRICT
SM297846,125.3969,-8.7522,Ermera,ADM2,DISTRICT
SM1211,125.3878,-8.8303,Ermera,ADM1,DISTRICT
SM266415,-49.6494,-28.9744,Ermo,ADM2,DISTRICT
SM276959,76.5,10,Ernakulam,ADM2,DISTRICT
SM291142,24.679,46.6176,Ernei,ADM2,DISTRICT
SM9922,-52.5737,-28.4232,Ernestina (1996-),ADM2,DISTRICT
SM305368,18.6589,45.4512,Ernestinovo,ADM2,DISTRICT
SM277247,77.55,11.34,Erode (2008-),ADM2,DISTRICT
SM230710,132.705,-2.8375,Erokwanas,LANGUAGE,LANGUAGE
SM281603,-101.6386,18.2785,Erongarícuaro,ADM2,DISTRICT
SM282025,-101.72,19.5934,Erongarícuaro,ADM2,DISTRICT
SM1212,15.3333,-22.0833,Erongo,ADM1,DISTRICT
SM370439,6.1147,49.8647,Erpeldange,ADM1:ADM2:ADM3,DISTRICT
SM4437,-4.5,31.75,Errachadia (2009-),ADM2,DISTRICT
SM17361,-60.9359,13.8924,Errard,ADM2,DISTRICT
SM230711,132.868,-12.2839,Erre,LANGUAGE,LANGUAGE
SM458895,133.0652,-12.4261,Erre,ETHNICITY,ETHNICITY
SM230713,-1.4611,43.1725,Erromintxela,LANGUAGE,LANGUAGE
SM230714,102.213,29.2867,Ersu,LANGUAGE,LANGUAGE
SM295002,40.75,51.75,Ertil'skiy rayon,ADM2,DISTRICT
SM298877,42.1751,37.7461,Eruh,ADM2,DISTRICT
SM230717,6.0482,5.1803,Eruwa,LANGUAGE,LANGUAGE
SM265650,-52.5749,-27.3614,Erval Grande,ADM2,DISTRICT
SM265652,-53.5279,-27.4943,Erval Seco,ADM2,DISTRICT
SM266416,-51.3865,-27.2903,Erval Velho,ADM2,DISTRICT
SM305443,15.942,44.1075,Ervenik,ADM2,DISTRICT
SM263416,-42.6197,-20.8468,Ervália,ADM2,DISTRICT
SM230718,99.9416,26.0991,Eryuan,DIALECT,LANGUAGE
SM298461,36.1962,36.9524,Erzin,ADM2,DISTRICT
SM1213,39.5,39.75,Erzincan,ADM1,DISTRICT
SM1214,41.5,40,Erzurum,ADM1,DISTRICT
SM230719,45.3901,52.8548,Erzya,LANGUAGE,LANGUAGE
SM253336,50,53,Erzya Mordvins,ETHNICITY,ETHNICITY
SM253336,50,53,Erzya Mordvins,ETHNICITY,ETHNICITY
SM253336,44.16,55.08,Erzya Mordvins,ETHNICITY,ETHNICITY
SM253336,44.16,55.08,Erzya Mordvins,ETHNICITY,ETHNICITY
SM461927,-11,27.75,Es Samara,ADM2,DISTRICT
SM297871,9.0271,36.0582,Es Sers,ADM2,DISTRICT
SM17900,34.1014,12.9962,Es Suki Locality,ADM2,DISTRICT
SM288577,150.75,-9.5833,Esa'ala,ADM2,DISTRICT
SM230720,6.3392,6.2569,Esan,LANGUAGE,LANGUAGE
SM250876,6.48,7.83,Esan,ETHNICITY,ETHNICITY
SM250876,6.48,7.83,Esan,ETHNICITY,ETHNICITY
SM250876,5.333,6.583,Esan,ETHNICITY,ETHNICITY
SM250876,5.333,6.583,Esan,ETHNICITY,ETHNICITY
SM284698,6.1931,6.7097,EsanCent,ADM2,DISTRICT
SM284699,6.3968,6.755,EsanNort,ADM2,DISTRICT
SM284700,6.4759,6.5866,EsanSout,ADM2,DISTRICT
SM284701,6.1291,6.6617,EsanWest,ADM2,DISTRICT
SM12886,142.4381,44.8655,Esashi Gun,ADM2,DISTRICT
SM271753,8.4622,55.4689,Esbjerg (2007-),ADM2,DISTRICT
SM264980,-35.2727,-8.3497,Escada,ADM2,DISTRICT
SM261114,-67.3333,-45.3333,Escalante,ADM2,DISTRICT
SM1216,1.5667,42.5,Escaldes-Engordany,ADM1,DISTRICT
SM300320,-87.3414,30.6144,Escambia,ADM2,DISTRICT
SM300265,-87.1616,31.1261,Escambia,ADM2,DISTRICT
SM17362,-60.8967,13.8303,Escap,ADM2,DISTRICT
SM14280,-90.7287,18.614,Escarcega,ADM2,DISTRICT
SM270976,-84.1333,9.9333,Escazú,ADM2,DISTRICT
SM370512,5.9806,49.4958,Esch-sur-Alzette,ADM1:ADM2:ADM3,DISTRICT
SM281374,6.0333,49.5,Esch-sur-Alzette,ADM1:ADM2,DISTRICT
SM9431,5.9364,49.9114,Esch-sur-Sûre (2011-),ADM1:ADM2,DISTRICT
SM1217,9.5359,47.2018,Eschen,ADM1,DISTRICT
SM289629,-57.0258,-25.6687,Escobar,ADM2,DISTRICT
SM260755,-58.75,-34.3333,Escobar,ADM2,DISTRICT
SM283313,-101.3317,27.3156,Escobedo,ADM2,DISTRICT
SM253339,-69.24,48.7,Escoumains,ETHNICITY,ETHNICITY
SM282534,-105.7299,22.7388,Escuinapa,ADM2,DISTRICT
SM283137,-92.6333,15.3333,Escuintla,ADM2,DISTRICT
SM275424,-90.7891,14.3029,Escuintla,ADM2,DISTRICT
SM1218,-91,14.1667,Escuintla,ADM1,DISTRICT
SM303776,-70.75,9.25,Escuque,ADM2,DISTRICT
SM230725,148.376,-9.0945,Ese,LANGUAGE,LANGUAGE
SM230726,-67.515,-11.7268,Ese Ejja,LANGUAGE,LANGUAGE
SM285082,4.9646,6.2174,Ese-Odo,ADM2,DISTRICT
SM250367,14.62,-11.35,Esele,ETHNICITY,ETHNICITY
SM291068,22.3658,44.6991,Eselnita,ADM2,DISTRICT
SM298500,28.8524,41.0793,Esenler,ADM2,DISTRICT
SM298501,28.6616,41.04,Esenyurt,ADM2,DISTRICT
SM1219,52.1667,33,Esfahan,ADM1,DISTRICT
SM277591,57.55,37,Esfarayen,ADM2,DISTRICT
SM230730,50.3677,35.7267,Eshtehardi,LANGUAGE,LANGUAGE
SM279977,68.25,54.3333,Esilskiy,ADM2,DISTRICT
SM279977,68.25,54.3333,Esilskiy,ADM2,DISTRICT
SM279926,66.5,52,Esilskiy,ADM2,DISTRICT
SM279977,68.45,54.0167,Esilskiy,ADM2,DISTRICT
SM279977,68.45,54.0167,Esilskiy,ADM2,DISTRICT
SM230731,9.8694,6.5009,Esimbi,LANGUAGE,LANGUAGE
SM285110,8.0706,4.6309,Esit Eket,ADM2,DISTRICT
SM298638,33.4199,38.4116,Eskil,ADM2,DISTRICT
SM296461,16.4901,59.3736,Eskilstuna,ADM2,DISTRICT
SM298569,32.546,40.9521,Eskipazar,ADM2,DISTRICT
SM1220,31.1667,39.6667,Eskisehir,ADM1,DISTRICT
SM277629,51.25,35.5833,Eslamshahr,ADM2,DISTRICT
SM296458,13.3752,55.8361,Eslöv,ADM2,DISTRICT
SM301784,-117.6324,37.7847,Esmeralda,ADM2,DISTRICT
SM270985,-78.0254,21.8444,Esmeralda,ADM2,DISTRICT
SM253341,-79.01,0.85,Esmeralda,ETHNICITY,ETHNICITY
SM9923,-51.1941,-27.9835,Esmeralda (1996-),ADM2,DISTRICT
SM273631,-79.65,0.9667,Esmeraldas,ADM2,DISTRICT
SM263417,-44.3231,-19.7414,Esmeraldas,ADM2,DISTRICT
SM1221,-79.25,0.8333,Esmeraldas,ADM1,DISTRICT
SM1222,-70.4167,19.5833,Espaillat,ADM1,DISTRICT
SM275652,-87.2562,15.6629,Esparta,ADM2,DISTRICT
SM270954,-84.6582,9.9809,Esparza,ADM2,DISTRICT
SM282684,-98.4367,19.4392,Españita,ADM2,DISTRICT
SM273592,-77.9333,0.6167,Espejo,ADM2,DISTRICT
SM263418,-41.9198,-20.5935,Espera Feliz,ADM2,DISTRICT
SM271804,-70.0564,18.8503,Esperalvillo,ADM2,DISTRICT
SM261378,122.1559,-33.5197,Esperance,ADM2,DISTRICT
SM17363,-61.0533,13.8078,Esperance (Choiseul),ADM2,DISTRICT
SM17364,-61.0371,13.8456,Esperance (Soufrière),ADM2,DISTRICT
SM17365,-60.9611,13.7885,Esperance (Vieux-Fort),ADM2,DISTRICT
SM267232,-48.5476,-5.3043,Esperantina,ADM2,DISTRICT
SM265222,-42.171,-3.849,Esperantina,ADM2,DISTRICT
SM262986,-44.7886,-4.8893,Esperantinópolis,ADM2,DISTRICT
SM230739,2.8105,49.3273,Esperanto,LANGUAGE,LANGUAGE
SM282406,-97.3726,18.865,Esperanza,ADM2,DISTRICT
SM271888,-70.9714,19.6238,Esperanza,ADM2,DISTRICT
SM15689,-66.7473,18.3647,Esperanza Barrio,ADM2,DISTRICT
SM264420,-35.9078,-6.9986,Esperança,ADM2,DISTRICT
SM264453,-53.7936,-23.7101,Esperança Nova,ADM2,DISTRICT
SM265655,-54.0111,-27.321,Esperança do Sul,ADM2,DISTRICT
SM264454,-52.7792,-25.3501,Espigão Alto do Iguaçu,ADM2,DISTRICT
SM266082,-60.6992,-11.4662,Espigão d'Oeste,ADM2,DISTRICT
SM282892,-97.4893,20.27,Espinal,ADM2,DISTRICT
SM270558,-74.9123,4.1509,Espinal,ADM2,DISTRICT
SM286907,-71.4167,-14.75,Espinar,ADM2,DISTRICT
SM15690,-67.1661,18.4069,Espinar Barrio,ADM2,DISTRICT
SM289225,-8.6251,40.999,Espinho,ADM2,DISTRICT
SM15691,-67.1154,18.2802,Espino Barrio (Añasco),ADM2,DISTRICT
SM15692,-66.8997,18.2754,Espino Barrio (Lares),ADM2,DISTRICT
SM15693,-66.9182,18.2479,Espino Barrio (Las Marías),ADM2,DISTRICT
SM15694,-66.0123,18.1114,Espino Barrio (San Lorenzo),ADM2,DISTRICT
SM263419,-42.9918,-14.8598,Espinosa,ADM2,DISTRICT
SM15695,-66.2881,18.3955,Espinosa Barrio (Dorado),ADM2,DISTRICT
SM15696,-66.3127,18.4135,Espinosa Barrio (Vega Alta),ADM2,DISTRICT
SM454468,167,-15.3,Espiritu Santo,ETHNICITY,ETHNICITY
SM282964,-88.3645,21.0352,Espita,ADM2,DISTRICT
SM298442,38.7073,40.9485,Espiye,ADM2,DISTRICT
SM267395,-37.8936,-11.9547,Esplanada,ADM2,DISTRICT
SM289443,-8.7575,41.5452,Esposende,ADM2,DISTRICT
SM265656,-52.8667,-28.8531,Espumoso,ADM2,DISTRICT
SM273473,-79.4167,-4.6,Espíndola,ADM2,DISTRICT
SM265538,-35.3258,-6.2839,Espírito Santo,ADM2,DISTRICT
SM1223,-40.75,-20,Espírito Santo,ADM1,DISTRICT
SM263420,-45.99,-22.006,Espírito Santo do Dourado,ADM2,DISTRICT
SM266513,-46.7842,-22.189,Espírito Santo do Pinhal,ADM2,DISTRICT
SM266514,-49.4218,-22.6681,Espírito Santo do Turvo,ADM2,DISTRICT
SM261170,-59.3333,-30,Esquina,ADM2,DISTRICT
SM285456,-85.7595,12.6935,Esquipulas,ADM2,DISTRICT
SM275410,-89.2697,14.614,Esquipulas,ADM2,DISTRICT
SM275307,-91.8424,14.927,Esquipulas Palo Gordo,ADM2,DISTRICT
SM275761,-86.5576,15.2776,Esquipulas del Norte,ADM2,DISTRICT
SM275854,-87.41,14.65,Esquías,ADM2,DISTRICT
SM281458,-9.7566,31.5068,Essaouira,ADM2,DISTRICT
SM230743,-121.867,36.5473,Esselen,LANGUAGE,LANGUAGE
SM253342,-121.21,36.21,Esselen,ETHNICITY,ETHNICITY
SM1224,-58.5,6.6667,Essequibo Islands-West Demerara,ADM1,DISTRICT
SM303002,-76.9409,37.9391,Essex,ADM2,DISTRICT
SM302931,-71.7361,44.7278,Essex,ADM2,DISTRICT
SM301874,-73.7727,44.1172,Essex,ADM2,DISTRICT
SM301831,-74.2469,40.7871,Essex,ADM2,DISTRICT
SM301246,-70.8679,42.6389,Essex,ADM2,DISTRICT
SM274629,0.5833,51.8333,Essex,ADM1:ADM2,DISTRICT
SM268895,-82.8331,42.1668,Essex,ADM2,DISTRICT
SM285111,7.6453,5.1068,Essien-U,ADM2,DISTRICT
SM274504,2.25,48.5,Essonne,ADM2,DISTRICT
SM296616,12.7227,58.2025,Essunga,ADM2,DISTRICT
SM1225,0.3635,12.0631,Est,ADM1,DISTRICT
SM1226,14.1667,3.8333,Est,ADM1,DISTRICT
SM230747,-99.61,19.8781,Estado de México Otomi,LANGUAGE,LANGUAGE
SM277716,54,29.1167,Estahban,ADM2,DISTRICT
SM275381,-89.6137,14.9835,Estanzuela,ADM2,DISTRICT
SM295594,-88.512,13.6225,Estanzuelas,ADM2,DISTRICT
SM289226,-8.5792,40.7546,Estarreja,ADM2,DISTRICT
SM265657,-52.2952,-27.9356,Estação,ADM2,DISTRICT
SM262708,-65.9934,-17.7557,Esteban Arce,ADM2,DISTRICT
SM271760,-70.6485,18.5347,Estebania,ADM2,DISTRICT
SM265659,-51.1663,-29.8501,Esteio,ADM2,DISTRICT
SM303687,-69.25,9.25,Esteller,ADM2,DISTRICT
SM285530,-86.3432,13.1317,Estelí,ADM2,DISTRICT
SM1227,-86.3333,13.1667,Estelí,ADM1,DISTRICT
SM301035,-83.9643,37.6925,Estill,ADM2,DISTRICT
SM263421,-46.0224,-22.4528,Estiva,ADM2,DISTRICT
SM266515,-46.9388,-22.2205,Estiva Gerbi,ADM2,DISTRICT
SM230750,25.82,58.55,Estonian,LANGUAGE,LANGUAGE
SM253344,22.2,56.99,Estonian,ETHNICITY,ETHNICITY
SM253344,22.2,56.99,Estonian,ETHNICITY,ETHNICITY
SM253344,22.2,56.99,Estonian,ETHNICITY,ETHNICITY
SM253344,26,59,Estonian,ETHNICITY,ETHNICITY
SM253344,26,59,Estonian,ETHNICITY,ETHNICITY
SM253344,26,59,Estonian,ETHNICITY,ETHNICITY
SM253344,24.8893,58.539,Estonian,ETHNICITY,ETHNICITY
SM253344,24.8893,58.539,Estonian,ETHNICITY,ETHNICITY
SM253344,24.8893,58.539,Estonian,ETHNICITY,ETHNICITY
SM230748,24.566,58.947,Estonian Sign Language,LANGUAGE,LANGUAGE
SM230751,23.45,59.0547,Estonian Swedish,LANGUAGE,LANGUAGE
SM262987,-47.1682,-6.7722,Estreito,ADM2,DISTRICT
SM265661,-51.9372,-29.5122,Estrela,ADM2,DISTRICT
SM265660,-53.1708,-29.2236,Estrela Velha,ADM2,DISTRICT
SM263422,-42.4683,-21.6933,Estrela dalva,ADM2,DISTRICT
SM265359,-36.7622,-9.3986,Estrela de Alagoas,ADM2,DISTRICT
SM263423,-45.8119,-19.5808,Estrela do Indaiá,ADM2,DISTRICT
SM266516,-51.6795,-22.4784,Estrela do Norte,ADM2,DISTRICT
SM268267,-49.1003,-13.7826,Estrela do Norte,ADM2,DISTRICT
SM266517,-50.4081,-20.2777,Estrela do Oeste,ADM2,DISTRICT
SM263425,-47.6973,-18.7223,Estrela do Sul,ADM2,DISTRICT
SM289495,-7.6012,38.85,Estremoz,ADM2,DISTRICT
SM10211,-71.6658,45.4834,Estrie,ADM2,DISTRICT
SM1228,10.0333,0.3,Estuaire (incl. Libreville),ADM1,DISTRICT
SM267086,-37.4205,-11.2383,Estância,ADM2,DISTRICT
SM265658,-51.1978,-29.6546,Estância Velha,ADM2,DISTRICT
SM230758,-3.0541,5.215,Esuma,LANGUAGE,LANGUAGE
SM11565,18.7033,47.7054,Esztergomi Jaras,ADM2,DISTRICT
SM277283,78.75,27.5,Etah (2008-),ADM2,DISTRICT
SM278139,132.4435,34.2199,Etajima,ADM2,DISTRICT
SM17366,-61.0406,13.819,Etangs,ADM2,DISTRICT
SM277284,79.25,26.75,Etawah (1997-),ADM2,DISTRICT
SM284554,15.5167,-17.55,Etayi,ADM2,DISTRICT
SM253345,-122.11,60.52,Etchaottine,ETHNICITY,ETHNICITY
SM253345,-122.11,60.52,Etchaottine,ETHNICITY,ETHNICITY
SM253345,-122.11,60.52,Etchaottine,ETHNICITY,ETHNICITY
SM253345,-127.77,60.53,Etchaottine,ETHNICITY,ETHNICITY
SM253345,-127.77,60.53,Etchaottine,ETHNICITY,ETHNICITY
SM253345,-127.77,60.53,Etchaottine,ETHNICITY,ETHNICITY
SM253345,-120,61,Etchaottine,ETHNICITY,ETHNICITY
SM253345,-120,61,Etchaottine,ETHNICITY,ETHNICITY
SM253345,-120,61,Etchaottine,ETHNICITY,ETHNICITY
SM253345,-119.5,61.15,Etchaottine,ETHNICITY,ETHNICITY
SM253345,-119.5,61.15,Etchaottine,ETHNICITY,ETHNICITY
SM253345,-119.5,61.15,Etchaottine,ETHNICITY,ETHNICITY
SM285198,7.0544,4.9908,Etche,ADM2,DISTRICT
SM14281,-109.6654,27.0177,Etchojoa (1996-),ADM2,DISTRICT
SM230762,7.9736,4.7906,Etebi,LANGUAGE,LANGUAGE
SM230763,8.6144,9.7307,Eten,LANGUAGE,LANGUAGE
SM230765,33.1189,34.721,Eteocypriot,LANGUAGE,LANGUAGE
SM10177,-100.494,51.5292,Ethelbert (2015-),ADM2,DISTRICT
SM261625,143.5383,-18.5628,Etheridge,ADM2,DISTRICT
SM284669,5.7294,5.92,Ethiope West,ADM2,DISTRICT
SM284670,5.9841,5.688,EthiopeE,ADM2,DISTRICT
SM230766,38.8534,8.9761,Ethiopian Sign Language,LANGUAGE,LANGUAGE
SM285007,3.5502,6.4415,Eti-Osa,ADM2,DISTRICT
SM285112,7.5925,4.978,EtimEkpo,ADM2,DISTRICT
SM298883,32.6374,39.9634,Etimesgut,ADM2,DISTRICT
SM285113,7.8446,4.8194,Etinan,ADM2,DISTRICT
SM292740,61.75,54.75,Etkul'skiy rayon,ADM2,DISTRICT
SM230768,10.3501,7.1377,Etkywan,LANGUAGE,LANGUAGE
SM286408,5.9344,59.6647,Etne,ADM2,DISTRICT
SM286112,9.6403,60.8887,Etnedal,ADM2,DISTRICT
SM253347,12.96,4.39,Eton,ETHNICITY,ETHNICITY
SM253347,12.96,4.39,Eton,ETHNICITY,ETHNICITY
SM253347,11.5,4.3,Eton,ETHNICITY,ETHNICITY
SM253347,11.5,4.3,Eton,ETHNICITY,ETHNICITY
SM230773,168.549,-17.74,Eton (Vanuatu),LANGUAGE,LANGUAGE
SM230771,11.3935,4.2105,Eton-Mengisa,LANGUAGE,LANGUAGE
SM253348,142.52,-6.73,Etoro,ETHNICITY,ETHNICITY
SM16306,14.7118,-0.2311,Etoumbi,ADM2,DISTRICT
SM300266,-86.0348,34.0453,Etowah,ADM2,DISTRICT
SM262437,24.0333,42.85,Etropole,ADM2,DISTRICT
SM230777,12.4833,41.9,Etruscan,LANGUAGE,LANGUAGE
SM284702,6.5124,6.9719,Etsako Central,ADM2,DISTRICT
SM284703,6.4961,7.2092,EtsakoEa,ADM2,DISTRICT
SM284704,6.3015,6.9983,EtsakoWe,ADM2,DISTRICT
SM297856,10.1039,36.8364,Ettadhamen,ADM2,DISTRICT
SM14921,153.56,5.592,Ettal Municipality,ADM2,DISTRICT
SM370440,6.0814,49.8457,Ettelbruck,ADM1:ADM2:ADM3,DISTRICT
SM285915,4.6425,51.5771,Etten-Leur,ADM2,DISTRICT
SM230779,9.4445,7.7587,Etulo,LANGUAGE,LANGUAGE
SM285390,8.7916,5.8589,Etung,ADM2,DISTRICT
SM281799,-104.1079,20.767,Etzatlán,ADM2,DISTRICT
SM267396,-39.0337,-10.4051,Euclides da Cunha,ADM2,DISTRICT
SM266518,-52.591,-22.5171,Euclides da Cunha Paulista,ADM2,DISTRICT
SM230781,-110,28.5,Eudeve,LANGUAGE,LANGUAGE
SM253350,-111.19,29.95,Eudeve,ETHNICITY,ETHNICITY
SM263426,-42.2462,-20.999,Eugenópolis,ADM2,DISTRICT
SM265663,-54.2658,-28.5718,Eugênio de Castro,ADM2,DISTRICT
SM280649,127.6807,36.926,Eumseong,ADM2,DISTRICT
SM280630,126.9278,37.6185,Eun-pyeong,ADM2,DISTRICT
SM267397,-39.6702,-16.3122,Eunápolis,ADM2,DISTRICT
SM274513,1,49.1667,Eure,ADM2,DISTRICT
SM274482,1.5,48.5,Eure-et-Loir,ADM2,DISTRICT
SM301785,-116.2686,39.9839,Eureka,ADM2,DISTRICT
SM261533,149.9278,-35.9331,Eurobodalla,ADM2,DISTRICT
SM11280,40.3667,-22.3333,Europa Island,ADM2,DISTRICT
SM289544,-56.9715,-25.3612,Eusebio Ayala,ADM2,DISTRICT
SM262778,-64.8998,-21.3166,Eustaquio Méndez,ADM2,DISTRICT
SM267916,-38.4555,-3.8695,Eusébio,ADM2,DISTRICT
SM301120,-92.4059,30.7289,Evangeline,ADM2,DISTRICT
SM300489,-81.8869,32.1568,Evans,ADM2,DISTRICT
SM230786,9.4643,6.3491,Evant,LANGUAGE,LANGUAGE
SM298378,29.8937,38.0521,Evciler,ADM2,DISTRICT
SM13513,25.5841,57.7194,Eveles pagasts,ADM2,DISTRICT
SM230790,130.914,70.6687,Even,LANGUAGE,LANGUAGE
SM286057,17.0089,68.5233,Evenes,ADM2,DISTRICT
SM230789,94.689,61.972,Evenki,LANGUAGE,LANGUAGE
SM253351,143.55,61.6,Evenki,ETHNICITY,ETHNICITY
SM253351,143.55,61.6,Evenki,ETHNICITY,ETHNICITY
SM253351,142.22,71.78,Evenki,ETHNICITY,ETHNICITY
SM253351,142.22,71.78,Evenki,ETHNICITY,ETHNICITY
SM253351,112,55,Evenki,ETHNICITY,ETHNICITY
SM253351,112,55,Evenki,ETHNICITY,ETHNICITY
SM16890,98,65,Evenkiyskiy District,ADM2,DISTRICT
SM294337,130.4462,67.8243,Eveno-Bytantayskiy rayon,ADM2,DISTRICT
SM253352,171.38,67.22,Evens,ETHNICITY,ETHNICITY
SM253352,171.38,67.22,Evens,ETHNICITY,ETHNICITY
SM253352,129.18,70.72,Evens,ETHNICITY,ETHNICITY
SM253352,129.18,70.72,Evens,ETHNICITY,ETHNICITY
SM253352,157.65,62.87,Evens,ETHNICITY,ETHNICITY
SM253352,157.65,62.87,Evens,ETHNICITY,ETHNICITY
SM253352,152.66,62.66,Evens,ETHNICITY,ETHNICITY
SM253352,152.66,62.66,Evens,ETHNICITY,ETHNICITY
SM253352,135.88,57.53,Evens,ETHNICITY,ETHNICITY
SM253352,135.88,57.53,Evens,ETHNICITY,ETHNICITY
SM12319,-77.5997,18.151,Evergreen,ADM2,DISTRICT
SM275122,10.4189,1.4349,Evinayong,ADM2,DISTRICT
SM286311,7.7804,58.562,Evje og Hornnes,ADM2,DISTRICT
SM298884,33.7977,39.03,Evren,ADM2,DISTRICT
SM10695,32.4836,34.962,Evretou,ADM2,DISTRICT
SM10696,32.917,35.0573,Evrychou,ADM2,DISTRICT
SM1230,166.9345,-0.5033,Ewa,ADM1,DISTRICT
SM230794,148.405,-8.6876,Ewage-Notu,LANGUAGE,LANGUAGE
SM12320,-77.1038,18.1675,Ewarton,ADM2,DISTRICT
SM263427,-43.5534,-21.5723,Ewbank da Câmara,ADM2,DISTRICT
SM230797,0.815,6.4606,Ewe,LANGUAGE,LANGUAGE
SM250232,-0.46,6.51,Ewe,ETHNICITY,ETHNICITY
SM250232,-0.46,6.51,Ewe,ETHNICITY,ETHNICITY
SM250232,1,7,Ewe,ETHNICITY,ETHNICITY
SM250232,1,7,Ewe,ETHNICITY,ETHNICITY
SM285063,3.1946,6.9612,Ewekoro,ADM2,DISTRICT
SM269812,14.735,-0.9595,Ewo,ADM2,DISTRICT
SM230799,11.9365,4.2114,Ewondo,LANGUAGE,LANGUAGE
SM454491,11.5,3.8,Ewondo,ETHNICITY,ETHNICITY
SM260757,-59.25,-34.25,Exaltación de la Cruz,ADM2,DISTRICT
SM12321,-77.0657,18.3727,Exchange,ADM2,DISTRICT
SM261379,114.0436,-22.0364,Exmouth,ADM2,DISTRICT
SM10697,33.5355,35.2071,Exo Metochi,ADM2,DISTRICT
SM263428,-46.2835,-22.8265,Extrema,ADM2,DISTRICT
SM1231,-6.1667,39.1667,Extremadura,ADM1,DISTRICT
SM230804,-6.1018,39.1926,Extremaduran,LANGUAGE,LANGUAGE
SM230808,14.3158,10.5972,Extreme North Cameroon Sign Language,LANGUAGE,LANGUAGE
SM265539,-35.2525,-5.6942,Extremoz,ADM2,DISTRICT
SM1232,14.5,11,Extrême-Nord,ADM1,DISTRICT
SM264981,-39.6614,-7.5541,Exu,ADM2,DISTRICT
SM1233,-75.8842,23.5694,Exuma,ADM1,DISTRICT
SM230809,-144.762,60.4232,Eyak,LANGUAGE,LANGUAGE
SM253354,-146.21,61.71,Eyak,ETHNICITY,ETHNICITY
SM253354,-146.21,61.71,Eyak,ETHNICITY,ETHNICITY
SM253354,-146.21,61.71,Eyak,ETHNICITY,ETHNICITY
SM253354,-146.21,61.71,Eyak,ETHNICITY,ETHNICITY
SM253354,-146.21,61.71,Eyak,ETHNICITY,ETHNICITY
SM253354,-145,61,Eyak,ETHNICITY,ETHNICITY
SM253354,-145,61,Eyak,ETHNICITY,ETHNICITY
SM253354,-145,61,Eyak,ETHNICITY,ETHNICITY
SM253354,-145,61,Eyak,ETHNICITY,ETHNICITY
SM253354,-145,61,Eyak,ETHNICITY,ETHNICITY
SM253354,-144.85,60.34,Eyak,ETHNICITY,ETHNICITY
SM253354,-144.85,60.34,Eyak,ETHNICITY,ETHNICITY
SM253354,-144.85,60.34,Eyak,ETHNICITY,ETHNICITY
SM253354,-144.85,60.34,Eyak,ETHNICITY,ETHNICITY
SM253354,-144.85,60.34,Eyak,ETHNICITY,ETHNICITY
SM253354,-144,60.48,Eyak,ETHNICITY,ETHNICITY
SM253354,-144,60.48,Eyak,ETHNICITY,ETHNICITY
SM253354,-144,60.48,Eyak,ETHNICITY,ETHNICITY
SM253354,-144,60.48,Eyak,ETHNICITY,ETHNICITY
SM253354,-144,60.48,Eyak,ETHNICITY,ETHNICITY
SM253354,-145,60.5,Eyak,ETHNICITY,ETHNICITY
SM253354,-145,60.5,Eyak,ETHNICITY,ETHNICITY
SM253354,-145,60.5,Eyak,ETHNICITY,ETHNICITY
SM253354,-145,60.5,Eyak,ETHNICITY,ETHNICITY
SM253354,-145,60.5,Eyak,ETHNICITY,ETHNICITY
SM277854,-22.5456,64.8585,Eyja- og Miklaholtshreppur,ADM2,DISTRICT
SM277777,-18.1667,65.3333,Eyjafjarðarsveit,ADM2,DISTRICT
SM295778,49.5655,8.0919,Eyl,ADM2,DISTRICT
SM298443,39.159,41.0633,Eynesil,ADM2,DISTRICT
SM277855,-23.195,64.9216,Eyrarsveit,ADM2,DISTRICT
SM274518,-6.7937,62.2075,Eystur,ADM2,DISTRICT
SM1234,-6.8667,62.2167,Eysturoyar,ADM1,DISTRICT
SM18045,38.7936,37.2143,Eyyuebiye,ADM2,DISTRICT
SM298502,28.8867,41.199,Eyüp,ADM2,DISTRICT
SM284734,7.2148,6.4106,Ezeagu,ADM2,DISTRICT
SM282462,-99.8935,20.6621,Ezequiel Montes,ADM2,DISTRICT
SM303665,-63.6092,9.6913,Ezequiel Zamora,ADM2,DISTRICT
SM303851,-71.1763,7.8129,Ezequiel Zamora,ADM2,DISTRICT
SM303880,-68.5833,9.5833,Ezequiel Zomora,ADM2,DISTRICT
SM13514,22.3371,56.4324,Ezeres pagasts,ADM2,DISTRICT
SM290015,21.9175,45.3898,Ezeris,ADM2,DISTRICT
SM13515,27.6762,56.1806,Ezernieku pagasts,ADM2,DISTRICT
SM269187,114.7798,30.4883,Ezhou,ADM2,DISTRICT
SM298271,26.357,39.7968,Ezine,ADM2,DISTRICT
SM284776,7.3263,5.4788,Ezinihit,ADM2,DISTRICT
SM284682,7.9713,6.2708,Ezza North,ADM2,DISTRICT
SM284683,8.001,6.1166,Ezza South,ADM2,DISTRICT
SM298049,10.2963,36.748,Ezzahra,ADM2,DISTRICT
SM298015,10.1362,36.7954,Ezzouhour,ADM2,DISTRICT
SM298115,8.8357,35.1582,Ezzouhour,ADM2,DISTRICT
SM298329,40.0835,38.2569,Eğil,ADM2,DISTRICT
SM298477,30.8186,37.8851,Eğirdir,ADM2,DISTRICT
SM288783,22.4047,53.8167,Ełk,ADM2,DISTRICT
SM298987,28.9758,38.4093,Eşme,ADM2,DISTRICT
SM283986,-9.841,23.5696,F'Dérik,ADM2,DISTRICT
SM1236,73.1338,33.7104,F.C.T.,ADM1,DISTRICT
SM1237,-172.25,-13.6667,Fa'asaleleaga,ADM1,DISTRICT
SM273947,32.2965,30.347,Fa'id,ADM2,DISTRICT
SM11235,-149.5967,-17.5521,Faaa,ADM2,DISTRICT
SM271754,10.4067,55.2267,Faaborg-Midtfyn,ADM2,DISTRICT
SM4253,72.9793,3.1061,Faafu Atholhu,ADM1:ADM2,DISTRICT
SM290907,27.8809,44.5688,Facaeni,ADM2,DISTRICT
SM269991,-74.3589,4.8196,Facatativá,ADM2,DISTRICT
SM15697,-66.6295,18.4567,Factor Barrio,ADM2,DISTRICT
SM10290,21.4996,17.1521,Fada,ADM2,DISTRICT
SM15294,10.4153,59.2279,Faerder,ADM2,DISTRICT
SM1238,12.4985,43.9251,Faetano,ADM1,DISTRICT
SM230823,15.1716,41.3244,Faeto and Celle San Vito Francoprovencal,LANGUAGE,LANGUAGE
SM274378,43,9.25,Fafan,ADM2,DISTRICT
SM289444,-8.1586,41.4684,Fafe,ADM2,DISTRICT
SM230824,161.662,-10.4479,Fagani,LANGUAGE,LANGUAGE
SM292624,24.9735,45.841,Fagaras,ADM2,DISTRICT
SM296559,15.8833,59.9483,Fagersta,ADM2,DISTRICT
SM291976,22.1761,45.8577,Faget,ADM2,DISTRICT
SM292103,26.0535,46.6048,Faget,ADM2,DISTRICT
SM291413,24.5442,44.7843,Fagetelu,ADM2,DISTRICT
SM284856,8.5336,12.027,Fagge,ADM2,DISTRICT
SM264422,-35.7742,-7.3916,Fagundes,ADM2,DISTRICT
SM265664,-51.7286,-28.8813,Fagundes Varela,ADM2,DISTRICT
SM11764,59.2167,29.1333,Fahraj,ADM2,DISTRICT
SM298038,9.8321,36.3013,Fahs,ADM2,DISTRICT
SM281433,-5.68,35.74,Fahs Anjra,ADM2,DISTRICT
SM258870,43.148,17.292,Faifi,LANGUAGE,LANGUAGE
SM268268,-50.3915,-15.437,Faina,ADM2,DISTRICT
SM301196,-146.4167,64.8333,Fairbanks North Star,ADM2,DISTRICT
SM230828,121.436,17.9808,Faire Atta,LANGUAGE,LANGUAGE
SM303004,-77.2762,38.8347,Fairfax,ADM2,DISTRICT
SM303005,-77.3064,38.8462,Fairfax City,ADM2,DISTRICT
SM302444,-81.1212,34.3951,Fairfield,ADM2,DISTRICT
SM302098,-82.6306,39.7516,Fairfield,ADM2,DISTRICT
SM303382,-73.3712,41.225,Fairfield,ADM2,DISTRICT
SM12322,-77.5754,18.0312,Fairfield,ADM2,DISTRICT
SM261534,150.9011,-33.8674,Fairfield,ADM2,DISTRICT
SM18533,-77.9124,18.4508,Fairfield (Saint James),ADM2,DISTRICT
SM323814,-118.3807,56.0712,Fairview,ADM2:ADM3,DISTRICT
SM323815,-118.455,56.0293,Fairview No. 136,ADM2:ADM3,DISTRICT
SM12323,-76.366,18.1651,Fairy Hill,ADM2,DISTRICT
SM14922,140.519,9.766,Fais Municipality,ADM2,DISTRICT
SM375449,73.1975,31.2309,Faisalabad,ADM2:ADM3,DISTRICT
SM12324,-77.088,18.2296,Faith's Pen,ADM2,DISTRICT
SM230831,141.656,-5.4967,Faiwol,LANGUAGE,LANGUAGE
SM277285,82,26.75,Faizabad (1996-),ADM2,DISTRICT
SM9523,70.6135,37.2163,Faizabad (2005-),ADM2,DISTRICT
SM1239,-65.676,18.3105,Fajardo,ADM1,DISTRICT
SM15698,-65.6497,18.3289,Fajardo Barrio-Pueblo,ADM2,DISTRICT
SM250635,31.07,4.83,Fajelu,ETHNICITY,ETHNICITY
SM250635,31.07,4.83,Fajelu,ETHNICITY,ETHNICITY
SM250635,31.07,4.83,Fajelu,ETHNICITY,ETHNICITY
SM250635,30.15,3.22,Fajelu,ETHNICITY,ETHNICITY
SM250635,30.15,3.22,Fajelu,ETHNICITY,ETHNICITY
SM250635,30.15,3.22,Fajelu,ETHNICITY,ETHNICITY
SM250635,31,4,Fajelu,ETHNICITY,ETHNICITY
SM250635,31,4,Fajelu,ETHNICITY,ETHNICITY
SM250635,31,4,Fajelu,ETHNICITY,ETHNICITY
SM250635,16,8,Fajelu,ETHNICITY,ETHNICITY
SM250635,16,8,Fajelu,ETHNICITY,ETHNICITY
SM250635,16,8,Fajelu,ETHNICITY,ETHNICITY
SM297742,100.8889,18.0008,Fak Tha,ADM2,DISTRICT
SM284932,4.8824,11.5223,Fakai,ADM2,DISTRICT
SM1240,-171.233,-9.376,Fakaofo,ADM1,DISTRICT
SM11236,-145.6179,-16.341,Fakarava,ADM2,DISTRICT
SM276513,132.2961,-2.9264,Fakfak,ADM2,DISTRICT
SM253358,132.34,-2.84,Fakfak,ETHNICITY,ETHNICITY
SM269548,9.1667,4.1667,Fako,ADM2,DISTRICT
SM230834,-6.9145,40.1651,Fala,LANGUAGE,LANGUAGE
SM4420,-11.1342,9.626,Falaba,ADM2,DISTRICT
SM15081,93.7149,23.422,Falam (2012-),ADM2,DISTRICT
SM230836,92.3255,23.7893,Falam Chin,LANGUAGE,LANGUAGE
SM10409,-74.957,5.0795,Falan (1996-),ADM2,DISTRICT
SM277700,51.5,32.5,Falavarjan,ADM2,DISTRICT
SM292275,28.1032,46.2937,Falciu,ADM2,DISTRICT
SM291414,24.376,44.2377,Falcoiu,ADM2,DISTRICT
SM303587,-70,11.8333,Falcón,ADM2,DISTRICT
SM303881,-68.3333,9.9167,Falcón,ADM2,DISTRICT
SM1241,-69.8333,11,Falcón,ADM1,DISTRICT
SM261204,-169.5075,-14.2195,Faleasao,ADM2,DISTRICT
SM304724,-171.7981,-13.8779,Faleata East,ADM2,DISTRICT
SM304725,-171.82,-13.859,Faleata West,ADM2,DISTRICT
SM10698,32.5871,34.8584,Faleia,ADM2,DISTRICT
SM293300,51.5833,58.3333,Falenskiy rayon,ADM2,DISTRICT
SM323816,-117.1983,55.7336,Falher,ADM2:ADM3,DISTRICT
SM230841,13.4087,10.5419,Fali,LANGUAGE,LANGUAGE
SM253361,14,10,Fali (Cameroon),ETHNICITY,ETHNICITY
SM6010,13.27,9.98,Fali (Nigeria),ETHNICITY,ETHNICITY
SM230847,12.345,42.3,Faliscan,LANGUAGE,LANGUAGE
SM296629,12.7299,57.0432,Falkenberg,ADM2,DISTRICT
SM274718,-3.75,56,Falkirk,ADM1:ADM2,DISTRICT
SM230848,-57.8548,-51.6947,Falkland Islands English,DIALECT,LANGUAGE
SM296617,13.5328,58.1405,Falköping,ADM2,DISTRICT
SM302493,-103.5276,43.2394,Fall River,ADM2,DISTRICT
SM301626,-104.4174,46.334,Fallon,ADM2,DISTRICT
SM302864,-96.9359,31.2533,Falls,ADM2,DISTRICT
SM303006,-77.1711,38.8823,Falls Church,ADM2,DISTRICT
SM15241,2.8107,12.5995,Falmey,ADM2,DISTRICT
SM12325,-77.6517,18.4782,Falmouth,ADM2,DISTRICT
SM291790,26.3167,47.4619,Falticeni,ADM2,DISTRICT
SM296550,15.6349,60.6048,Falun,ADM2,DISTRICT
SM230851,10.505,7.5428,Fam,LANGUAGE,LANGUAGE
SM263429,-45.8201,-21.4685,Fama,ADM2,DISTRICT
SM1243,33.8667,35.3333,Famagusta,ADM1,DISTRICT
SM261077,-65.5,-26.9167,Famaillá,ADM2,DISTRICT
SM260877,-67.5833,-28.6667,Famatina,ADM2,DISTRICT
SM230852,27.1401,-25.3848,Fanagalo,LANGUAGE,LANGUAGE
SM14923,151.9082,8.5581,Fananu Municipality,ADM2,DISTRICT
SM14924,151.6676,7.353,Fanapanges Municipality,ADM2,DISTRICT
SM370969,47.4843,-20.2171,Fandriana,ADM2:ADM3,DISTRICT
SM296892,99.1635,19.8755,Fang,ADM2,DISTRICT
SM230856,10.319,6.5438,Fang (Cameroon),LANGUAGE,LANGUAGE
SM230854,11.6145,1.724,Fang (Equatorial Guinea),LANGUAGE,LANGUAGE
SM7514,13,2.5,Fang proper,ETHNICITY,ETHNICITY
SM11237,-140.8843,-15.8212,Fangatau,ADM2,DISTRICT
SM269445,107.9593,21.9358,Fangchenggang,ADM2,DISTRICT
SM230858,18.75,10.291,Fania,LANGUAGE,LANGUAGE
SM14925,138.1133,9.5606,Fanif Municipality,ADM2,DISTRICT
SM302865,-96.1068,33.5938,Fannin,ADM2,DISTRICT
SM300490,-84.3199,34.8641,Fannin,ADM2,DISTRICT
SM291792,26.5204,47.585,Fantanele,ADM2,DISTRICT
SM291143,24.7885,46.404,Fantanele,ADM2,DISTRICT
SM250236,-1.86,5.18,Fante,ETHNICITY,ETHNICITY
SM250236,-1.86,5.18,Fante,ETHNICITY,ETHNICITY
SM250236,-1.86,5.18,Fante,ETHNICITY,ETHNICITY
SM250236,-1.25,5.625,Fante,ETHNICITY,ETHNICITY
SM250236,-1.25,5.625,Fante,ETHNICITY,ETHNICITY
SM250236,-1.25,5.625,Fante,ETHNICITY,ETHNICITY
SM250236,-1,6,Fante,ETHNICITY,ETHNICITY
SM250236,-1,6,Fante,ETHNICITY,ETHNICITY
SM250236,-1,6,Fante,ETHNICITY,ETHNICITY
SM274917,-0.3833,6.3833,Fanteakwa,ADM2,DISTRICT
SM271856,-70.3,19.1167,Fantino,ADM2,DISTRICT
SM11765,59.6386,26.5747,Fanuj,ADM2,DISTRICT
SM271755,8.4167,55.4167,Fanø,ADM2,DISTRICT
SM297864,8.6704,33.366,Faouar,ADM2,DISTRICT
SM278008,35.7801,31.3555,Faqqoo',ADM2,DISTRICT
SM304869,43.7827,13.9578,Far Al Udayn,ADM2,DISTRICT
SM8783,135.3,54.7,Far East Evenki,ETHNICITY,ETHNICITY
SM286489,173.4884,-35.0336,Far North,ADM2,DISTRICT
SM230966,142.9239,-14.8305,Far North Queensland Indigenous Sign Language,LANGUAGE,LANGUAGE
SM230869,99.6842,25.6469,Far Northwestern Nisu,LANGUAGE,LANGUAGE
SM230876,80.4712,20.1754,Far Western Muria,LANGUAGE,LANGUAGE
SM1244,80.9206,29.3206,Far-Western,ADM1:ADMD,DISTRICT
SM370972,47.5983,-22.8015,Farafangana,ADM2:ADM3,DISTRICT
SM227980,167.034,-15.3353,Farafi,LANGUAGE,LANGUAGE
SM291144,24.5278,46.758,Faragau,ADM2,DISTRICT
SM1245,62.5,32.5,Farah,ADM1,DISTRICT
SM260271,62.2599,32.2177,Farah City,ADM2,DISTRICT
SM15699,-66.0874,18.11,Farallon Barrio,ADM2,DISTRICT
SM15285,146.061,16.024,Farallon de Medinilla,ADM2,DISTRICT
SM15286,144.89,20.54,Farallon de Pajaros,ADM2,DISTRICT
SM274981,-10.8333,10,Faranah,ADM1:ADM2,DISTRICT
SM1246,-10.6958,10.3375,Faranah,ADM1,DISTRICT
SM292104,26.8984,46.4338,Faraoani,ADM2,DISTRICT
SM17781,41.9667,16.7333,Farasan,ADM2,DISTRICT
SM273816,31.7484,31.3184,Faraskur,ADM2,DISTRICT
SM370957,46.9198,-19.4305,Faratsiho,ADM2:ADM3,DISTRICT
SM289794,24.0434,46.33,Farau,ADM2,DISTRICT
SM14926,144.5065,8.588,Faraulep Municipality,ADM2,DISTRICT
SM291332,25.8488,47.1527,Farcasa,ADM2,DISTRICT
SM291006,23.3483,47.5948,Farcasa,ADM2,DISTRICT
SM291415,24.4328,44.1506,Farcasele,ADM2,DISTRICT
SM290629,23.1928,44.8707,Farcasesti,ADM2,DISTRICT
SM291978,22.1978,45.749,Fardea,ADM2,DISTRICT
SM11766,50.9787,35.7232,Fardis,ADM2,DISTRICT
SM230865,-0.8111,11.0918,Farefare,LANGUAGE,LANGUAGE
SM18390,71.7889,40.3842,Fargona Shahri,ADM2,DISTRICT
SM18391,71.75,40.25,Fargona Tumani,ADM2,DISTRICT
SM370203,-9.4217,5.4086,Farh,ADM2:ADM3,DISTRICT
SM263430,-42.0279,-20.7826,Faria Lemos,ADM2,DISTRICT
SM267917,-39.5341,-6.8866,Farias Brito,ADM2,DISTRICT
SM301352,-93.948,43.6739,Faribault,ADM2,DISTRICT
SM276858,77.3716,28.4629,Faridabad (2008-),ADM2,DISTRICT
SM277157,74.75,30.65,Faridkot,ADM2,DISTRICT
SM262250,89.8215,23.5942,Faridpur,ADM2,DISTRICT
SM275101,-15.166,12.561,Farim,ADM2,DISTRICT
SM277608,59.8333,35.7,Fariman,ADM2,DISTRICT
SM284590,165.776,-21.6651,Farino,ADM2,DISTRICT
SM305631,16.6347,45.879,Farkaševac,ADM2,DISTRICT
SM260215,69.9608,36.4699,Farkhar,ADM2,DISTRICT
SM305967,69.3328,37.5253,Farkhor,ADM2,DISTRICT
SM290016,21.8535,45.5003,Farliug,ADM2,DISTRICT
SM12326,-77.8776,18.4687,Farm Heights,ADM2,DISTRICT
SM10699,33.1449,34.9248,Farmakas,ADM2,DISTRICT
SM289512,-7.9294,37.0251,Faro,ADM2,DISTRICT
SM269537,12.8783,8.2501,Faro,ADM2,DISTRICT
SM264165,-57.7741,-1.1154,Faro,ADM2,DISTRICT
SM1247,-8.1667,37.2,Faro,ADM1,DISTRICT
SM269497,12.4961,7.4668,Faro et Déo,ADM2,DISTRICT
SM230871,-6.885,62.0732,Faroese,LANGUAGE,LANGUAGE
SM264456,-52.6631,-24.1035,Farol,ADM2,DISTRICT
SM265665,-51.3402,-29.1878,Farroupilha,ADM2,DISTRICT
SM277286,79.5,27.5,Farrukhabad (1997-),ADM2,DISTRICT
SM1248,53,29,Fars,ADM1,DISTRICT
SM244951,52.267,29.746,Fars Dialects,LANGUAGE,LANGUAGE
SM260018,63.2835,33.8428,Farsi,ADM2,DISTRICT
SM253365,51,33.71,Farsi,ETHNICITY,ETHNICITY
SM253365,51,33.71,Farsi,ETHNICITY,ETHNICITY
SM253365,51,33.71,Farsi,ETHNICITY,ETHNICITY
SM253365,52.5333,29.6167,Farsi,ETHNICITY,ETHNICITY
SM253365,52.5333,29.6167,Farsi,ETHNICITY,ETHNICITY
SM253365,52.5333,29.6167,Farsi,ETHNICITY,ETHNICITY
SM253365,52.584,29.591,Farsi,ETHNICITY,ETHNICITY
SM253365,52.584,29.591,Farsi,ETHNICITY,ETHNICITY
SM253365,52.584,29.591,Farsi,ETHNICITY,ETHNICITY
SM286256,6.804,58.0954,Farsund,ADM2,DISTRICT
SM290438,27.9695,45.7984,Fartanesti,ADM2,DISTRICT
SM292186,23.9808,44.7758,Fartatesti,ADM2,DISTRICT
SM266519,-49.5277,-23.3987,Fartura,ADM2,DISTRICT
SM265223,-42.7893,-9.5203,Fartura do Piauí,ADM2,DISTRICT
SM277600,58.2167,37.2167,Faruj,ADM2,DISTRICT
SM230875,55.05,33.47,Farvi,DIALECT,LANGUAGE
SM1249,64.8333,36.25,Faryab,ADM1,DISTRICT
SM9524,69.0177,34.794,Farzah,ADM2,DISTRICT
SM277717,53.7667,28.9667,Fasa,ADM2,DISTRICT
SM284901,7.0861,11.6755,Faskari,ADM2,DISTRICT
SM10700,32.366,34.9886,Fasli,ADM2,DISTRICT
SM18532,33.0296,34.7572,Fasoula (Limassol),ADM2,DISTRICT
SM10701,32.6348,34.7499,Fasoula (Paphos),ADM2,DISTRICT
SM299507,29.9107,50.0734,Fastivs'ka,ADM2,DISTRICT
SM299479,30,50.0833,Fastivs'kyi,ADM2,DISTRICT
SM230881,143.284,-6.6269,Fasu,LANGUAGE,LANGUAGE
SM253367,143.36,-7.01,Fasu,ETHNICITY,ETHNICITY
SM230882,160.861,-8.5904,Fataleka,LANGUAGE,LANGUAGE
SM230884,127.08,-8.4946,Fataluku,LANGUAGE,LANGUAGE
SM253368,126.73,-8.57,Fataluku,ETHNICITY,ETHNICITY
SM276859,75.27,29.31,Fatehabad,ADM2,DISTRICT
SM277158,76.3479,30.6438,Fatehgarh Sahib,ADM2,DISTRICT
SM277287,80.75,25.75,Fatehpur,ADM2,DISTRICT
SM293522,35.75,52,Fatezhskiy rayon,ADM2,DISTRICT
SM295269,-16.4988,14.2591,Fatick,ADM2,DISTRICT
SM1250,-16.5,14.2833,Fatick,ADM1,DISTRICT
SM298504,28.9405,41.0175,Fatih,ADM2,DISTRICT
SM263048,-54.3706,-22.3442,Fatima do Sul,ADM2,DISTRICT
SM297807,125.8663,-8.9479,Fato Berliu,ADM2,DISTRICT
SM298812,37.492,41.0221,Fatsa,ADM2,DISTRICT
SM297835,125.1319,-9.1908,Fatu Fulic,ADM2,DISTRICT
SM297836,125.0358,-9.2292,Fatu Mean,ADM2,DISTRICT
SM11238,-138.6435,-10.4703,Fatu-Hiva,ADM2,DISTRICT
SM295456,160.7381,-8.6307,Fauabu,ADM2,DISTRICT
SM253369,39.27,31.77,Fauara Bedu,ETHNICITY,ETHNICITY
SM302494,-99.1453,45.071,Faulk,ADM2,DISTRICT
SM302367,-92.332,35.147,Faulkner,ADM2,DISTRICT
SM295425,160.8855,-8.8159,Faumamanu - Kwai,ADM2,DISTRICT
SM303007,-77.8093,38.7385,Fauquier,ADM2,DISTRICT
SM253370,133.02,-3.91,Faur,ETHNICITY,ETHNICITY
SM291333,26.7183,46.9153,Faurei,ADM2,DISTRICT
SM292713,27.2716,45.0853,Faurei,ADM2,DISTRICT
SM286058,15.3941,67.2596,Fauske,ADM2,DISTRICT
SM17367,-60.9956,14.0088,Faux A Chaud,ADM2,DISTRICT
SM271700,9.94,56.3167,Favrskov municipality,ADM2,DISTRICT
SM253371,22.09,30.15,Fawakhir,ETHNICITY,ETHNICITY
SM253372,30.01,27.98,Fawayid,ETHNICITY,ETHNICITY
SM271719,12.0611,55.2944,Faxe,ADM2,DISTRICT
SM264457,-51.3012,-24.0266,Faxinal,ADM2,DISTRICT
SM265666,-53.4733,-29.572,Faxinal do Soturno,ADM2,DISTRICT
SM266417,-52.2621,-26.8315,Faxinal dos Guedes,ADM2,DISTRICT
SM265667,-52.669,-27.3718,Faxinalzinho,ADM2,DISTRICT
SM303109,-81.0812,38.0288,Fayette,ADM2,DISTRICT
SM302560,-89.4144,35.1971,Fayette,ADM2,DISTRICT
SM302303,-79.6474,39.9199,Fayette,ADM2,DISTRICT
SM302866,-96.9198,29.8768,Fayette,ADM2,DISTRICT
SM302099,-83.4561,39.5599,Fayette,ADM2,DISTRICT
SM300704,-85.1787,39.6401,Fayette,ADM2,DISTRICT
SM300810,-91.8443,42.8626,Fayette,ADM2,DISTRICT
SM301036,-84.4587,38.0423,Fayette,ADM2,DISTRICT
SM300491,-84.4942,33.4139,Fayette,ADM2,DISTRICT
SM300610,-89.0241,39.0002,Fayette,ADM2,DISTRICT
SM300267,-87.7389,33.7212,Fayette,ADM2,DISTRICT
SM9525,67.5773,36.4564,Fayroz Nakhchir,ADM2,DISTRICT
SM230891,136.867,-3.145,Fayu,LANGUAGE,LANGUAGE
SM260027,66.3297,36.7045,Fayz abad,ADM2,DISTRICT
SM305987,69.3167,38.55,Fayzobod,ADM2,DISTRICT
SM268269,-50.926,-16.1287,Fazenda Nova,ADM2,DISTRICT
SM264458,-49.3043,-25.6647,Fazenda Rio Grande,ADM2,DISTRICT
SM265668,-51.8424,-29.5982,Fazenda Vilanova,ADM2,DISTRICT
SM277159,74.1169,30.3759,Fazilka,ADM2,DISTRICT
SM305685,13.8055,44.9282,Fažana,ADM2,DISTRICT
SM230895,10.1442,5.1816,Fe'fe',LANGUAGE,LANGUAGE
SM253374,41.92,31.95,Fedaan Bedu,ETHNICITY,ETHNICITY
SM1251,17.5833,44,Federacija Bosna i Hercegovina (excl. Brcko),ADM1,DISTRICT
SM303564,-69.4167,10.8333,Federación,ADM2,DISTRICT
SM261184,-58.3333,-30.6667,Federación,ADM2,DISTRICT
SM261185,-58.7833,-30.9557,Federal,ADM2,DISTRICT
SM1252,7.1667,8.8333,Federal Capital Territory,ADM1,DISTRICT
SM9969,146.2295,-35.4609,Federation,ADM2,DISTRICT
SM9831,-65.8889,-10.2696,Federico Roman,ADM2,DISTRICT
SM286409,4.7197,60.7787,Fedje,ADM2,DISTRICT
SM294257,55.25,53.0833,Fedorovskiy rayon,ADM2,DISTRICT
SM14927,151.831,7.353,Fefen Municipality,ADM2,DISTRICT
SM276178,22.6301,48.0044,Fehérgyarmat,ADM2,DISTRICT
SM262801,-70.9149,-8.905,Feijó,ADM2,DISTRICT
SM13516,27.0512,56.273,Feimanu pagasts,ADM2,DISTRICT
SM265360,-36.6695,-9.9247,Feira Grande,ADM2,DISTRICT
SM267088,-37.3385,-10.3172,Feira Nova,ADM2,DISTRICT
SM264983,-35.3925,-7.9347,Feira Nova,ADM2,DISTRICT
SM262989,-46.65,-6.9956,Feira Nova do Maranhão,ADM2,DISTRICT
SM267400,-44.2528,-14.132,Feira da Mata,ADM2,DISTRICT
SM267401,-39.0656,-12.2337,Feira de Santana,ADM2,DISTRICT
SM1253,18.5833,47.1667,Fejér,ADM1,DISTRICT
SM298129,35.9183,37.8241,Feke,ADM2,DISTRICT
SM298618,35.5716,39.1017,Felahiye,ADM2,DISTRICT
SM292625,25.5746,45.8283,Feldioara,ADM2,DISTRICT
SM261878,9.5836,47.2554,Feldkirch,ADM2,DISTRICT
SM261793,14.0968,46.7213,Feldkirchen,ADM2,DISTRICT
SM292494,24.5642,47.278,Feldru,ADM2,DISTRICT
SM290093,23.6402,46.7034,Feleacu,ADM2,DISTRICT
SM289311,-8.2081,41.3477,Felgueiras,ADM2,DISTRICT
SM290688,25.2743,46.2791,Feliceni,ADM2,DISTRICT
SM15700,-66.3897,17.9565,Felicia 1 Barrio,ADM2,DISTRICT
SM15701,-66.3861,17.9937,Felicia 2 Barrio,ADM2,DISTRICT
SM261186,-58.75,-30.4167,Feliciano,ADM2,DISTRICT
SM282468,-88.1532,19.6452,Felipe Carrillo Puerto,ADM2,DISTRICT
SM265541,-37.6427,-5.5392,Felipe Guerra,ADM2,DISTRICT
SM263432,-43.3553,-18.0763,Felisberto Caldeira,ADM2,DISTRICT
SM263433,-40.7181,-16.6615,Felisburgo,ADM2,DISTRICT
SM263434,-44.9194,-18.6941,Felixlândia,ADM2,DISTRICT
SM265669,-51.2778,-29.4546,Feliz,ADM2,DISTRICT
SM265362,-36.3537,-10.2908,Feliz Deserto,ADM2,DISTRICT
SM263159,-54.2393,-11.936,Feliz Natal,ADM2,DISTRICT
SM12327,-76.472,18.1278,Fellowship,ADM2,DISTRICT
SM14016,-8.3834,4.7258,Felo-Jekwi District,ADM2,DISTRICT
SM263431,-43.2405,-18.1551,Felício dos Santos,ADM2,DISTRICT
SM230897,142.254,-5.716,Fembe,LANGUAGE,LANGUAGE
SM14017,-9.6447,5.6181,Fen River District,ADM2,DISTRICT
SM14018,-8.3737,4.905,Fenetoe District,ADM2,DISTRICT
SM253375,27.43,-31.83,Fengo,ETHNICITY,ETHNICITY
SM262238,91.3167,22.9667,Feni,ADM2,DISTRICT
SM371016,49.203,-17.1637,Fenoarivo Atsinanana,ADM2:ADM3,DISTRICT
SM370947,46.3332,-18.1293,Fenoarivo-Centre,ADM2:ADM3,DISTRICT
SM302561,-84.9325,36.3805,Fentress,ADM2,DISTRICT
SM295476,166.3083,-10.2402,Fenualoa,ADM2,DISTRICT
SM299899,35.3729,45.0461,Feodosiis'ka,ADM2,DISTRICT
SM18207,35.0225,45.2328,Feodosiiskyi Raion (2023-),ADM2,DISTRICT
SM253377,141.58,-4.85,Feramin,ETHNICITY,ETHNICITY
SM305720,17.189,46.06,Ferdinandovac,ADM2,DISTRICT
SM11767,57.95,33.95,Ferdows,ADM2,DISTRICT
SM11768,50.2667,33.1,Fereydan,ADM2,DISTRICT
SM277701,49.9833,32.8667,Fereydunshahr,ADM2,DISTRICT
SM1254,71.25,40.5,Ferghana,ADM1,DISTRICT
SM301627,-109.2243,47.2636,Fergus,ADM2,DISTRICT
SM253378,150.66,-9.58,Fergusson Island,ETHNICITY,ETHNICITY
SM298116,8.4661,35.0342,Feriana,ADM2,DISTRICT
SM323758,-112.9711,52.7641,Ferintosh,ADM2:ADM3,DISTRICT
SM298841,30.4891,40.9509,Ferizli,ADM2,DISTRICT
SM305369,17.9766,45.5244,Feričanci,ADM2,DISTRICT
SM274709,-7.503,54.4426,Fermanagh and Omagh,ADM1:ADM2,DISTRICT
SM277877,13.7242,43.1654,Fermo,ADM2,DISTRICT
SM298093,8.669,36.651,Fernana,ADM2,DISTRICT
SM264460,-50.5181,-25.444,Fernandes Pinheiro,ADM2,DISTRICT
SM263436,-42.0946,-19.103,Fernandes Tourinho,ADM2,DISTRICT
SM262990,-45.3281,-6.3529,Fernando Falcão,ADM2,DISTRICT
SM265543,-36.3974,-5.7202,Fernando Pedroza,ADM2,DISTRICT
SM266520,-48.6958,-21.3094,Fernando Prestes,ADM2,DISTRICT
SM265542,-32.42,-3.8507,Fernando de Noronha,ADM2,DISTRICT
SM303713,-65.0421,10.0613,Fernando de Peñalver,ADM2,DISTRICT
SM289728,-57.5507,-25.3278,Fernando de la Mora,ADM2,DISTRICT
SM266522,-50.292,-20.2976,Fernandópolis,ADM2,DISTRICT
SM266523,-49.5543,-22.3738,Ferno,ADM2,DISTRICT
SM303757,-71.9601,7.5288,Fernández Feo,ADM2,DISTRICT
SM230904,25.7171,8.6161,Feroge,LANGUAGE,LANGUAGE
SM17368,-60.9849,13.9868,Ferrand,ADM2,DISTRICT
SM277948,11.8333,44.8,Ferrara,ADM2,DISTRICT
SM266524,-46.3749,-23.5615,Ferraz de Vascon,ADM2,DISTRICT
SM267298,-51.3544,0.9101,Ferreira Gomes,ADM2,DISTRICT
SM289431,-8.1714,38.0992,Ferreira do Alentejo,ADM2,DISTRICT
SM289320,-8.3166,39.723,Ferreira do Zêzere,ADM2,DISTRICT
SM264984,-35.2,-7.4864,Ferreiros,ADM2,DISTRICT
SM286766,-79.5,-6.3333,Ferreñafe,ADM2,DISTRICT
SM12328,-78.0559,18.2227,Ferris,ADM2,DISTRICT
SM263437,-42.9702,-19.238,Ferros,ADM2,DISTRICT
SM303070,-118.5165,48.4701,Ferry,ADM2,DISTRICT
SM12329,-76.8672,18.0277,Ferry,ADM2,DISTRICT
SM263438,-42.3421,-20.6879,Fervedouro,ADM2,DISTRICT
SM293091,36.5833,54.5833,Ferzikovskiy rayon,ADM2,DISTRICT
SM281443,-4.87,34.04,Fes (pre-2004),ADM2,DISTRICT
SM4273,-5.0057,34.0283,Fes-Meknes,ADM1,DISTRICT
SM290908,27.8248,44.3861,Fetesti,ADM2,DISTRICT
SM18046,29.1268,36.6548,Fethiye (2014-),ADM2,DISTRICT
SM280800,102.0789,18.6236,Feuang,ADM2,DISTRICT
SM370441,6.0167,49.85,Feulen,ADM1:ADM2:ADM3,DISTRICT
SM305104,27.7193,-27.3392,Fezile Dabi,ADM2,DISTRICT
SM250426,17.01,26.45,Fezzan,ETHNICITY,ETHNICITY
SM370979,47.0899,-21.4645,Fianarantsoa Urban,ADM2:ADM3,DISTRICT
SM290264,25.4109,45.1305,Fieni,ADM2,DISTRICT
SM1257,19.6167,40.7667,Fier,ADM1,DISTRICT
SM290909,26.3832,44.6829,Fierbinti-targ,ADM2,DISTRICT
SM260487,19.5833,40.7167,Fierit,ADM2,DISTRICT
SM230912,11.3184,46.1111,Fierozzo,DIALECT,LANGUAGE
SM274719,-3.1667,56.25,Fife,ADM1:ADM2,DISTRICT
SM264461,-50.4208,-23.8612,Figueira,ADM2,DISTRICT
SM289482,-8.8061,40.1583,Figueira da Foz,ADM2,DISTRICT
SM289236,-6.9684,40.8736,Figueira de Castelo Rodrigo,ADM2,DISTRICT
SM9924,-53.6425,-18.675,Figueirao,ADM2:ADM3,DISTRICT
SM289256,-8.2775,39.9062,Figueiró dos Vinhos,ADM2,DISTRICT
SM267235,-49.2898,-12.2686,Figueirópolis,ADM2,DISTRICT
SM263160,-58.6972,-15.487,Figueirópolis d'Oeste,ADM2,DISTRICT
SM261037,-63.55,-27.3333,Figueroa,ADM2,DISTRICT
SM281415,-2.25,32.8333,Figuig,ADM2,DISTRICT
SM253380,177.99,-18.1,Fiji,ETHNICITY,ETHNICITY
SM253380,177.99,-18.1,Fiji,ETHNICITY,ETHNICITY
SM253380,178.32,-17.81,Fiji,ETHNICITY,ETHNICITY
SM253380,178.32,-17.81,Fiji,ETHNICITY,ETHNICITY
SM230913,177.772,-17.8148,Fiji Hindi,LANGUAGE,LANGUAGE
SM230914,178.33,-18,Fijian,LANGUAGE,LANGUAGE
SM285269,11.2375,11.377,Fika,ADM2,DISTRICT
SM10702,33.1828,34.9755,Fikardou,ADM2,DISTRICT
SM15332,-60.1787,-21.4374,Filadelfia,ADM2,DISTRICT
SM270860,-75.5929,5.2894,Filadelfia,ADM2,DISTRICT
SM267236,-47.8562,-7.488,Filadélfia,ADM2,DISTRICT
SM267402,-40.2056,-10.7663,Filadélfia,ADM2,DISTRICT
SM270421,-75.6714,4.6753,Filandia,ADM2,DISTRICT
SM291604,23.0159,46.9184,Fildu De Jos,ADM2,DISTRICT
SM290362,23.5516,44.5685,Filiasi,ADM2,DISTRICT
SM292105,27.1911,46.5336,Filipeni,ADM2,DISTRICT
SM292106,26.8934,46.7682,Filipesti,ADM2,DISTRICT
SM291525,25.7361,44.9942,Filipestii De Padure,ADM2,DISTRICT
SM291526,25.7981,44.948,Filipestii De Targ,ADM2,DISTRICT
SM230922,123.464,12.265,Filipino,LANGUAGE,LANGUAGE
SM296513,14.1471,59.8735,Filipstad,ADM2,DISTRICT
SM301701,-97.5965,40.5247,Fillmore,ADM2,DISTRICT
SM301353,-92.0902,43.674,Fillmore,ADM2,DISTRICT
SM282893,-97.6991,20.2052,Filomeno Mata,ADM2,DISTRICT
SM230924,-97.5931,20.1415,Filomeno Mata Totonac,LANGUAGE,LANGUAGE
SM10703,32.736,34.8552,Filousa,ADM2,DISTRICT
SM10703,32.736,34.8552,Filousa,ADM2,DISTRICT
SM10703,32.5051,34.9689,Filousa,ADM2,DISTRICT
SM10703,32.5051,34.9689,Filousa,ADM2,DISTRICT
SM230927,121.11,17.0319,Finallig,LANGUAGE,LANGUAGE
SM12060,-6.2194,53.4591,Fingal County,ADM2,DISTRICT
SM299019,30.0665,36.4236,Finike,ADM2,DISTRICT
SM292430,22.2762,46.6431,Finis,ADM2,DISTRICT
SM274478,-3.9926,48.2522,Finistère,ADM2,DISTRICT
SM274399,22.25,60.5,Finland Proper,ADM1:ADM2,DISTRICT
SM230931,25.67,60.39,Finland-Swedish Sign Language,LANGUAGE,LANGUAGE
SM300909,-100.737,38.0443,Finney,ADM2,DISTRICT
SM230934,25.5577,64.7628,Finnish,LANGUAGE,LANGUAGE
SM253382,23.08,62.01,Finnish,ETHNICITY,ETHNICITY
SM253382,23.08,62.01,Finnish,ETHNICITY,ETHNICITY
SM253382,23.08,62.01,Finnish,ETHNICITY,ETHNICITY
SM253382,25,64,Finnish,ETHNICITY,ETHNICITY
SM253382,25,64,Finnish,ETHNICITY,ETHNICITY
SM253382,25,64,Finnish,ETHNICITY,ETHNICITY
SM253382,24.9,60.2,Finnish,ETHNICITY,ETHNICITY
SM253382,24.9,60.2,Finnish,ETHNICITY,ETHNICITY
SM253382,24.9,60.2,Finnish,ETHNICITY,ETHNICITY
SM230932,25.0677,60.661,Finnish Sign Language,LANGUAGE,LANGUAGE
SM230936,146.629,-6.3301,Finongan,LANGUAGE,LANGUAGE
SM288581,147.5,-6.4167,Finschhafen,ADM2,DISTRICT
SM296421,15.7878,58.8057,Finspång,ADM2,DISTRICT
SM1259,19.9167,60.25,Finström,ADM1:ADM2,DISTRICT
SM290265,25.7993,44.7937,Finta Mare,ADM2,DISTRICT
SM230938,10.2936,6.3126,Fio,LANGUAGE,LANGUAGE
SM1260,12.4565,43.9114,Fiorentino,ADM1,DISTRICT
SM230941,31.3442,-7.8896,Fipa,LANGUAGE,LANGUAGE
SM250237,31.08,-7.93,Fipa,ETHNICITY,ETHNICITY
SM250237,31.08,-7.93,Fipa,ETHNICITY,ETHNICITY
SM250237,31,-8,Fipa,ETHNICITY,ETHNICITY
SM250237,31,-8,Fipa,ETHNICITY,ETHNICITY
SM17925,35.722,32.7979,Fiq District,ADM2,DISTRICT
SM230943,8.691,9.6525,Firan,LANGUAGE,LANGUAGE
SM270768,-73.0508,5.6405,Firavitoba,ADM2,DISTRICT
SM280990,-10.3636,6.3771,Firestone,ADM2,DISTRICT
SM14019,-7.5833,4.5,Firestone,ADM2,DISTRICT
SM9526,69.6814,36.1369,Firing wa Gharu,ADM2,DISTRICT
SM267403,-39.9119,-14.9136,Firmino Alves,ADM2,DISTRICT
SM268270,-50.3214,-16.6374,Firminópolis,ADM2,DISTRICT
SM294795,33.7,57.4833,Firovskiy rayon,ADM2,DISTRICT
SM277288,78.4287,27.2007,Firozabad,ADM2,DISTRICT
SM277160,74.56,30.89,Firozpur (2011-),ADM2,DISTRICT
SM277630,52.7416,35.7285,Firuzkuh,ADM2,DISTRICT
SM370534,6.1872,49.7397,Fischbach,ADM1:ADM2:ADM3,DISTRICT
SM302867,-100.4022,32.7428,Fisher,ADM2,DISTRICT
SM327915,-97.584,51.075,Fisher,ADM2:ADM3,DISTRICT
SM292341,27.0695,45.9666,Fitionesti,ADM2,DISTRICT
SM261205,-169.4279,-14.2333,Fitiuta,ADM2,DISTRICT
SM286410,5.3164,59.9174,Fitjar,ADM2,DISTRICT
SM296820,17.5342,12.8605,Fitri,ADM2,DISTRICT
SM250539,18,13,Fitri-Batha,ETHNICITY,ETHNICITY
SM14020,-9.4167,5.6667,Five A,ADM2,DISTRICT
SM230948,143.646,-6.6634,Fiwaga,LANGUAGE,LANGUAGE
SM290094,23.9473,46.9944,Fizesu Gherlii,ADM2,DISTRICT
SM269692,28.9442,-4.3011,Fizi,ADM2,DISTRICT
SM286177,5.3981,61.3638,Fjaler,ADM2,DISTRICT
SM11604,-18.7822,66.0596,Fjallabyggd,ADM2,DISTRICT
SM11607,-14,65.0833,Fjardabyggd (2018-),ADM2,DISTRICT
SM461345,7.3824,62.2877,Fjord,ADM2,DISTRICT
SM1261,57.7167,-20.2333,Flacq,ADM1,DISTRICT
SM300321,-81.293,29.4712,Flagler,ADM2,DISTRICT
SM12330,-77.7713,18.3541,Flagstaff,ADM2,DISTRICT
SM10123,-111.7948,52.6679,Flagstaff County  (2016-),ADM2,DISTRICT
SM286059,13.2331,68.0929,Flakstad,ADM2,DISTRICT
SM292565,26.9038,47.5619,Flamanzi,ADM2,DISTRICT
SM15702,-65.3059,18.3168,Flamenco Barrio,ADM2,DISTRICT
SM10704,33.8593,35.3944,Flamoudi,ADM2,DISTRICT
SM12331,-77.8132,18.3141,Flamstead,ADM2,DISTRICT
SM270560,-74.8333,4.25,Flandes,ADM2,DISTRICT
SM12332,-77.9083,18.5027,Flankers,ADM2,DISTRICT
SM10705,32.9036,35.0629,Flasou,ADM2,DISTRICT
SM286449,11.0329,64.4128,Flatanger,ADM2,DISTRICT
SM301628,-114.0498,48.2952,Flathead,ADM2,DISTRICT
SM273508,-79.8157,-0.3231,Flavio Alfaro,ADM2,DISTRICT
SM370477,6.3436,49.6617,Flaxweiler,ADM1:ADM2:ADM3,DISTRICT
SM286257,6.6635,58.2979,Flekkefjord,ADM2,DISTRICT
SM301037,-83.6967,38.3701,Fleming,ADM2,DISTRICT
SM253385,4.68,51.73,Flemish,ETHNICITY,ETHNICITY
SM296462,16.7167,59.05,Flen,ADM2,DISTRICT
SM370383,-8.1667,4.7667,Flenekpo,ADM2:ADM3,DISTRICT
SM286330,9.5831,59.8328,Flesberg,ADM2,DISTRICT
SM12333,-76.791,17.9797,Fletchers Land,ADM2,DISTRICT
SM1262,5.6667,52.5333,Flevoland,ADM1,DISTRICT
SM265363,-35.78,-9.2006,Flexeiras,ADM2,DISTRICT
SM261756,148.1397,-40.038,Flinders,ADM2,DISTRICT
SM261626,144.3881,-20.8903,Flinders,ADM2,DISTRICT
SM230955,144.259,-14.1812,Flinders Island,LANGUAGE,LANGUAGE
SM261691,138.3388,-32.1334,Flinders Ranges,ADM2,DISTRICT
SM13326,-151.8185,-11.4315,Flint,ADM2,DISTRICT
SM12334,-76.9217,18.2379,Flint River,ADM2,DISTRICT
SM274746,-3.1667,53.25,Flintshire,ADM1:ADM2,DISTRICT
SM11608,-15.3721,65.2502,Fljotsdalsherad,ADM2,DISTRICT
SM277755,-15.2568,64.8528,Fljótsdalshreppur,ADM2,DISTRICT
SM11609,-20.8016,63.8957,Floahreppur,ADM2,DISTRICT
SM17369,-60.9588,13.9628,Floissac/Marc,ADM2,DISTRICT
SM264462,-53.3037,-26.2308,Flor da Serra do Sul,ADM2,DISTRICT
SM266418,-53.3368,-26.7564,Flor do Sertão,ADM2,DISTRICT
SM296095,-55.1963,5.8164,Flora,ADM2,DISTRICT
SM266525,-51.3779,-21.701,Flora Rica,ADM2,DISTRICT
SM264463,-52.3242,-23.3283,Floraí,ADM2,DISTRICT
SM266526,-50.1584,-20.6539,Floreal,ADM2,DISTRICT
SM303231,-88.3982,45.8485,Florence,ADM2,DISTRICT
SM302445,-79.7028,34.0244,Florence,ADM2,DISTRICT
SM277909,11.3333,43.8333,Florence,ADM2,DISTRICT
SM271074,-78.9732,22.1452,Florencia,ADM2,DISTRICT
SM270876,-75.5962,1.7162,Florencia,ADM2,DISTRICT
SM269852,-77.0913,1.7125,Florencia,ADM2,DISTRICT
SM15703,-65.671,18.3133,Florencio Barrio,ADM2,DISTRICT
SM458007,-99.1525,16.7014,Florencio Villarreal,ADM2,DISTRICT
SM261115,-66.1667,-44.4167,Florentino Ameghino,ADM2,DISTRICT
SM275202,-89.8971,16.9238,Flores,ADM2,DISTRICT
SM270935,-84.1549,10.0064,Flores,ADM2,DISTRICT
SM264985,-37.9208,-7.9309,Flores,ADM2,DISTRICT
SM1263,-56.8333,-33.5833,Flores,ADM1,DISTRICT
SM275233,-91.8634,14.6324,Flores Costa Cuca,ADM2,DISTRICT
SM276507,122.9682,-8.2422,Flores Timur,ADM2,DISTRICT
SM265670,-51.2458,-29.0258,Flores da Cunha,ADM2,DISTRICT
SM268271,-46.8785,-14.59,Flores de Goiás,ADM2,DISTRICT
SM265225,-42.8455,-7.6412,Flores do Piauí,ADM2,DISTRICT
SM270769,-72.9251,5.859,Floresta,ADM2,DISTRICT
SM264986,-38.3026,-8.5769,Floresta,ADM2,DISTRICT
SM264464,-52.0763,-23.6301,Floresta,ADM2,DISTRICT
SM267404,-39.755,-14.8445,Floresta Azul,ADM2,DISTRICT
SM264166,-49.5286,-7.5794,Floresta do Araguaia,ADM2,DISTRICT
SM265226,-41.8443,-7.4763,Floresta do Piauí,ADM2,DISTRICT
SM263439,-44.4425,-19.8652,Florestal,ADM2,DISTRICT
SM291527,25.7854,45.0272,Floresti,ADM2,DISTRICT
SM291069,22.9099,44.7955,Floresti,ADM2,DISTRICT
SM290095,23.4786,46.7338,Floresti,ADM2,DISTRICT
SM290564,25.7114,44.4915,Floresti-Stoenesti,ADM2,DISTRICT
SM264465,-51.3763,-22.9012,Florestópolis,ADM2,DISTRICT
SM1264,28.3778,47.874,Floreşti,ADM1,DISTRICT
SM265227,-43.1361,-6.8364,Floriano,ADM2,DISTRICT
SM265671,-52.0336,-27.8524,Floriano Peixoto,ADM2,DISTRICT
SM266419,-48.5012,-27.6146,Florianopolis,ADM2,DISTRICT
SM275882,-88.81,15.17,Florida,ADM2,DISTRICT
SM270986,-78.3485,21.4658,Florida,ADM2,DISTRICT
SM270613,-76.1899,3.3094,Florida,ADM2,DISTRICT
SM262769,-63.9026,-18.1124,Florida,ADM2,DISTRICT
SM1266,-55.9167,-33.8333,Florida,ADM1:ADM2,DISTRICT
SM1267,-82.5001,28.7505,Florida,ADM1,DISTRICT
SM1265,-66.5621,18.3605,Florida,ADM1,DISTRICT
SM15704,-66.5602,18.3734,Florida Adentro Barrio,ADM2,DISTRICT
SM15705,-66.5605,18.426,Florida Afuera Barrio,ADM2,DISTRICT
SM15706,-65.9429,18.1877,Florida Barrio (San Lorenzo),ADM2,DISTRICT
SM15707,-65.4627,18.135,Florida Barrio (Vieques),ADM2,DISTRICT
SM270467,-73.0859,7.0632,Floridablanca,ADM2,DISTRICT
SM270466,-74.0158,5.8038,Florián,ADM2,DISTRICT
SM265544,-36.8006,-6.1529,Florânia,ADM2,DISTRICT
SM266528,-50.6898,-22.8722,Florínia,ADM2,DISTRICT
SM12335,-77.8391,18.4843,Flower Hill,ADM2,DISTRICT
SM303008,-80.3626,36.9315,Floyd,ADM2,DISTRICT
SM302868,-101.3032,34.0724,Floyd,ADM2,DISTRICT
SM300705,-85.9069,38.3189,Floyd,ADM2,DISTRICT
SM301038,-82.7457,37.5571,Floyd,ADM2,DISTRICT
SM300811,-92.789,43.0599,Floyd,ADM2,DISTRICT
SM300492,-85.2143,34.2632,Floyd,ADM2,DISTRICT
SM303009,-78.2775,37.8419,Fluvanna,ADM2,DISTRICT
SM286329,9.4661,60.4291,Flå,ADM2,DISTRICT
SM264467,-51.9786,-23.1127,Flórida,ADM2,DISTRICT
SM266527,-51.1807,-21.5419,Flórida Paulista,ADM2,DISTRICT
SM17985,-174.2986,-19.7385,Foa,ADM2,DISTRICT
SM302869,-99.778,33.9746,Foard,ADM2,DISTRICT
SM292342,27.2277,45.69,Focsani,ADM2,DISTRICT
SM291979,20.8781,45.4861,Foeni,ADM2,DISTRICT
SM277925,15.5333,41.45,Foggia,ADM2,DISTRICT
SM297837,125.0894,-9.2836,Fohorem,ADM2,DISTRICT
SM230972,143.495,-6.4726,Foi,LANGUAGE,LANGUAGE
SM291663,22.3833,47.7,Foieni,ADM2,DISTRICT
SM10706,32.8204,34.904,Foini,ADM2,DISTRICT
SM10707,33.1061,34.7603,Foinikaria,ADM2,DISTRICT
SM10708,32.5698,34.7462,Foinikas,ADM2,DISTRICT
SM253386,143.68,-7.01,Foll,ETHNICITY,ETHNICITY
SM286374,9.9971,62.1333,Folldal,ADM2,DISTRICT
SM269480,-7.5189,9.8124,Folon,ADM2,DISTRICT
SM230975,144.458,-6.8632,Folopa,LANGUAGE,LANGUAGE
SM290439,28.07,45.723,Foltesti,ADM2,DISTRICT
SM230976,24.3202,0.8803,Foma,LANGUAGE,LANGUAGE
SM271039,-79.6945,21.9816,Fomento,ADM2,DISTRICT
SM230981,1.665,7.1548,Fon,LANGUAGE,LANGUAGE
SM250221,2.34,6.86,Fon,ETHNICITY,ETHNICITY
SM250221,2.34,6.86,Fon,ETHNICITY,ETHNICITY
SM250221,2.34,6.86,Fon,ETHNICITY,ETHNICITY
SM250221,2,7,Fon,ETHNICITY,ETHNICITY
SM250221,2,7,Fon,ETHNICITY,ETHNICITY
SM250221,2,7,Fon,ETHNICITY,ETHNICITY
SM250221,1.91,7.2,Fon,ETHNICITY,ETHNICITY
SM250221,1.91,7.2,Fon,ETHNICITY,ETHNICITY
SM250221,1.91,7.2,Fon,ETHNICITY,ETHNICITY
SM17370,-60.9363,13.9949,Fond Assau/Babonneau,ADM2,DISTRICT
SM17371,-61.0035,13.7824,Fond Berange,ADM2,DISTRICT
SM17372,-61.0595,13.8585,Fond Bernier,ADM2,DISTRICT
SM17373,-61.0508,13.8544,Fond Cacoa,ADM2,DISTRICT
SM17374,-60.9595,13.992,Fond Canie,ADM2,DISTRICT
SM17375,-60.9484,13.7745,Fond Capeche,ADM2,DISTRICT
SM17376,-60.9212,13.8107,Fond D'Lor/Dugard,ADM2,DISTRICT
SM17377,-60.894,13.9236,Fond D'Or,ADM2,DISTRICT
SM17378,-61.0495,13.821,Fond Doux,ADM2,DISTRICT
SM17379,-61.0304,13.9478,Fond Eau Rouge,ADM2,DISTRICT
SM17380,-60.9186,13.8424,Fond Estate,ADM2,DISTRICT
SM17381,-61.0626,13.8079,Fond Gens Libre,ADM2,DISTRICT
SM17382,-61.0088,13.9641,Fond Manger,ADM2,DISTRICT
SM17383,-60.9555,13.7766,Fond Sabot,ADM2,DISTRICT
SM303233,-88.4883,43.7536,Fond du Lac,ADM2,DISTRICT
SM17384,-60.9413,13.7942,Fond/Desruisseaux,ADM2,DISTRICT
SM230979,22.5611,11.3179,Fongoro,LANGUAGE,LANGUAGE
SM275067,-16.2167,13.25,Foni Bintang Karanai,ADM2,DISTRICT
SM275068,-15.9333,13.2167,Foni Bondali,ADM2,DISTRICT
SM275069,-16.3333,13.2167,Foni Brefet,ADM2,DISTRICT
SM275070,-15.8333,13.2333,Foni Jarrol,ADM2,DISTRICT
SM275071,-16.05,13.2167,Foni Kansala,ADM2,DISTRICT
SM14928,151.8794,7.486,Fonoton Municipality,ADM2,DISTRICT
SM10410,-72.7978,10.8297,Fonseca (1995-),ADM2,DISTRICT
SM12336,-76.5117,17.9406,Font Hill,ADM2,DISTRICT
SM267320,-66.2662,-2.4959,Fonte Boa,ADM2,DISTRICT
SM265672,-52.3677,-29.0105,Fontoura Xavier,ADM2,DISTRICT
SM11566,17.7,46.6729,Fonyodi Jaras,ADM2,DISTRICT
SM230983,1.4049,9.5649,Foodo,LANGUAGE,LANGUAGE
SM295426,160.6327,-8.3981,Foondo - Gwaiau,ADM2,DISTRICT
SM230987,146.541,-5.6908,Forak,LANGUAGE,LANGUAGE
SM291793,26.4617,47.3639,Forasti,ADM2,DISTRICT
SM261535,147.8645,-33.379,Forbes,ADM2,DISTRICT
SM300910,-99.8879,37.6917,Ford,ADM2,DISTRICT
SM300611,-88.2233,40.5972,Ford,ADM2,DISTRICT
SM230988,131.511,-7.289,Fordata,LANGUAGE,LANGUAGE
SM230995,145.5065,-6.6447,Fore,LANGUAGE,LANGUAGE
SM253387,145.6,-6.5,Fore,ETHNICITY,ETHNICITY
SM253387,145.6,-6.5,Fore,ETHNICITY,ETHNICITY
SM253387,145,-6,Fore,ETHNICITY,ETHNICITY
SM253387,145,-6,Fore,ETHNICITY,ETHNICITY
SM323821,-111.4482,49.4757,Foremost,ADM2:ADM3,DISTRICT
SM303234,-88.7704,45.6673,Forest,ADM2,DISTRICT
SM302304,-79.236,41.5131,Forest,ADM2,DISTRICT
SM1269,-2.5919,49.4258,Forest,ADM1,DISTRICT
SM250104,29,1,Forest Bira,ETHNICITY,ETHNICITY
SM230991,88.2121,69.9829,Forest Enets,LANGUAGE,LANGUAGE
SM12337,-76.8287,18.0385,Forest Hills Gardens,ADM2,DISTRICT
SM12338,-76.8514,18.043,Forest Hills/ Plantation Height,ADM2,DISTRICT
SM230994,-7.5315,9.4969,Forest Maninka,LANGUAGE,LANGUAGE
SM230999,71.97,63.72,Forest Nenets,LANGUAGE,LANGUAGE
SM17385,-60.9314,13.9772,Forest Reserve,ADM2,DISTRICT
SM323927,-112.0681,52.5834,Forestburg,ADM2:ADM3,DISTRICT
SM17386,-60.9577,13.9778,Forestiere,ADM2,DISTRICT
SM303492,67.25,40.6667,Forish,ADM2,DISTRICT
SM277949,12.0333,44.0833,Forli' - Cesena,ADM2,DISTRICT
SM263440,-45.4776,-20.5401,Formiga,ADM2,DISTRICT
SM265674,-53.4674,-29.9694,Formigueiro,ADM2,DISTRICT
SM268272,-47.1907,-15.3453,Formosa,ADM2,DISTRICT
SM261191,-58.5,-26,Formosa,ADM2,DISTRICT
SM1270,-60,-25,Formosa,ADM1,DISTRICT
SM262991,-46.1954,-6.6954,Formosa da Serra Negra,ADM2,DISTRICT
SM264468,-53.3278,-24.3084,Formosa do Oeste,ADM2,DISTRICT
SM267405,-45.9121,-10.8886,Formosa do Rio Preto,ADM2,DISTRICT
SM266422,-52.8043,-26.647,Formosa do Sul,ADM2,DISTRICT
SM263441,-46.0937,-15.1475,Formoso,ADM2,DISTRICT
SM268273,-48.8754,-13.7034,Formoso,ADM2,DISTRICT
SM267237,-50.0981,-11.959,Formoso do Araguaia,ADM2,DISTRICT
SM289237,-7.4973,40.6494,Fornos de Algodres,ADM2,DISTRICT
SM273987,39.519,15.2093,Foro,ADM2,DISTRICT
SM290017,21.5789,45.2316,Forotic,ADM2,DISTRICT
SM370374,-8.237,5.1111,Forpoh,ADM2:ADM3,DISTRICT
SM9925,-52.1176,-29.397,Forquetinha,ADM2:ADM3,DISTRICT
SM267918,-40.2343,-3.8335,Forquilha,ADM2,DISTRICT
SM266423,-49.467,-28.7861,Forquilhinha,ADM2,DISTRICT
SM301435,-89.2579,31.1889,Forrest,ADM2,DISTRICT
SM296514,13.5057,59.646,Forshaga,ADM2,DISTRICT
SM346838,23.6129,60.8292,Forssa,ADM2:ADM3,DISTRICT
SM301958,-80.2564,36.1305,Forsyth,ADM2,DISTRICT
SM300493,-84.125,34.2255,Forsyth,ADM2,DISTRICT
SM302870,-95.7709,29.5275,Fort Bend,ADM2,DISTRICT
SM253389,-67.64,57.98,Fort Chimo,ETHNICITY,ETHNICITY
SM323863,-113.4003,49.7204,Fort MacLeod,ADM2:ADM3,DISTRICT
SM323997,-113.1886,53.7177,Fort Saskatchewan,ADM2:ADM3,DISTRICT
SM12339,-78.0633,18.2983,Fort Williams,ADM2,DISTRICT
SM276090,-71.8499,19.5797,Fort-Liberté,ADM2,DISTRICT
SM267919,-38.5247,-3.7227,Fortaleza,ADM2,DISTRICT
SM263442,-46.7744,-20.8817,Fortaleza de Minas,ADM2,DISTRICT
SM267238,-48.5564,-9.0881,Fortaleza do Tabocão,ADM2,DISTRICT
SM262992,-46.021,-6.8672,Fortaleza dos Nogueiras,ADM2,DISTRICT
SM265675,-53.3389,-28.9127,Fortaleza dos Valos,ADM2,DISTRICT
SM267921,-37.8529,-4.4471,Fortim,ADM2,DISTRICT
SM270601,-71.8567,6.7461,Fortul,ADM2,DISTRICT
SM262993,-43.9906,-5.668,Fortuna,ADM2,DISTRICT
SM263443,-44.5023,-19.5485,Fortuna de Minas,ADM2,DISTRICT
SM282894,-96.9891,18.9045,Fortín,ADM2,DISTRICT
SM274990,-13.1,9.4333,Forécariah,ADM1:ADM2,DISTRICT
SM269993,-73.974,4.3018,Fosca,ADM2,DISTRICT
SM269430,112.963,23.0226,Foshan,ADM2,DISTRICT
SM302037,-98.883,47.4571,Foster,ADM2,DISTRICT
SM10709,33.223,35.2554,Fota,ADM2,DISTRICT
SM298050,10.1813,36.6949,Fouchana,ADM2,DISTRICT
SM295427,160.797,-8.3133,Fouenda,ADM2,DISTRICT
SM10291,13.9137,14.0839,Fouli,ADM2,DISTRICT
SM295270,-16.4573,13.87,Foundiougne,ADM2,DISTRICT
SM300706,-87.242,40.1209,Fountain,ADM2,DISTRICT
SM12340,-77.296,17.9709,Four Paths,ADM2,DISTRICT
SM298117,8.5526,35.3389,Foussana,ADM2,DISTRICT
SM253390,-88.79,44.55,Fox,ETHNICITY,ETHNICITY
SM253390,-88.79,44.55,Fox,ETHNICITY,ETHNICITY
SM253390,-88.79,44.55,Fox,ETHNICITY,ETHNICITY
SM253390,-92.7,41.967,Fox,ETHNICITY,ETHNICITY
SM253390,-92.7,41.967,Fox,ETHNICITY,ETHNICITY
SM253390,-92.7,41.967,Fox,ETHNICITY,ETHNICITY
SM253390,-95,45,Fox,ETHNICITY,ETHNICITY
SM253390,-95,45,Fox,ETHNICITY,ETHNICITY
SM253390,-95,45,Fox,ETHNICITY,ETHNICITY
SM324200,-116.799,54.4039,Fox Creek,ADM2:ADM3,DISTRICT
SM280984,-10.2453,8.3758,Foya,ADM2,DISTRICT
SM280984,-10.2453,8.3758,Foya,ADM2,DISTRICT
SM280984,-10.2199,8.3703,Foya,ADM2,DISTRICT
SM280984,-10.2199,8.3703,Foya,ADM2,DISTRICT
SM264469,-54.5524,-25.4531,Foz do Iguaçu,ADM2,DISTRICT
SM264470,-52.0608,-25.6952,Foz do Jordão,ADM2,DISTRICT
SM298532,26.7721,38.6785,Foça,ADM2,DISTRICT
SM313215,18.7739,43.4472,Foča,ADM2:ADM3,DISTRICT
SM4423,-6.6842,32.5011,Fquih Ben Salah,ADM2,DISTRICT
SM266424,-50.8069,-27.0479,Fraiburgo,ADM2,DISTRICT
SM275469,-90.444,14.4719,Fraijanes,ADM2,DISTRICT
SM15708,-65.2543,18.3132,Fraile Barrio,ADM2,DISTRICT
SM18332,-54.5296,-32.4925,Fraile Muerto,ADM2,DISTRICT
SM15709,-66.1087,18.3722,Frailes Barrio (Guaynabo),ADM2,DISTRICT
SM15710,-66.9113,18.1274,Frailes Barrio (Yuaco),ADM2,DISTRICT
SM271135,-75.2624,20.6559,Frak País,ADM2,DISTRICT
SM289565,-56.0118,-27.0741,Fram,ADM2,DISTRICT
SM266529,-47.339,-20.569,Franca,ADM2,DISTRICT
SM305137,24.3819,-28.3,Frances Baard,ADM2,DISTRICT
SM292187,24.1679,45.0236,Francesti,ADM2,DISTRICT
SM265228,-42.2464,-6.4103,Francinópolis,ADM2,DISTRICT
SM17387,-61.0545,13.804,Franciou,ADM2,DISTRICT
SM264471,-53.8844,-24.0651,Francisco Alves,ADM2,DISTRICT
SM265229,-42.692,-6.6913,Francisco Ayres,ADM2,DISTRICT
SM457961,-42.2806,-16.953,Francisco Badaró,ADM2,DISTRICT
SM264472,-53.1254,-26.0644,Francisco Beltrão,ADM2,DISTRICT
SM15333,-54.7115,-24.1565,Francisco Caballero Alvarez (1994-),ADM2,DISTRICT
SM265545,-38.1262,-6.0164,Francisco Dantas,ADM2,DISTRICT
SM314648,-44.2178,-17.3977,Francisco Dumont,ADM2:ADM3,DISTRICT
SM281670,-99.0789,20.2387,Francisco I. Madero,ADM2,DISTRICT
SM283314,-103.258,25.8977,Francisco I. Madero,ADM2,DISTRICT
SM303812,-71.6173,8.9635,Francisco Javier Pulgar,ADM2,DISTRICT
SM283138,-93.25,17.3167,Francisco León,ADM2,DISTRICT
SM231012,-93.3633,17.3654,Francisco León Zoque,LANGUAGE,LANGUAGE
SM303834,-67.5514,10.2003,Francisco Linares,ADM2,DISTRICT
SM265230,-40.7706,-7.3585,Francisco Macêdo,ADM2,DISTRICT
SM266530,-46.7089,-23.2692,Francisco Morato,ADM2,DISTRICT
SM1272,-87.1667,14.25,Francisco Morazán,ADM1,DISTRICT
SM270312,-78.5888,2.1471,Francisco Pizarro,ADM2,DISTRICT
SM457960,-43.495,-16.4102,Francisco Sa,ADM2,DISTRICT
SM265231,-41.145,-7.1029,Francisco Santos,ADM2,DISTRICT
SM263449,-41.9844,-18.0098,Francisco Sá Part,ADM2,DISTRICT
SM282407,-97.8188,20.7261,Francisco Z. Mena,ADM2,DISTRICT
SM303758,-71.9719,7.9114,Francisco de Miranda,ADM2,DISTRICT
SM1273,27.5078,-21.17,Francistown,ADM1,DISTRICT
SM266531,-46.7309,-23.3028,Franco da Rocha,ADM2,DISTRICT
SM12341,-77.365,18.1497,Frankfield,ADM2,DISTRICT
SM302932,-72.912,44.8575,Franklin,ADM2,DISTRICT
SM303071,-118.8989,46.5348,Franklin,ADM2,DISTRICT
SM302562,-86.0922,35.155,Franklin,ADM2,DISTRICT
SM302305,-77.7213,39.9274,Franklin,ADM2,DISTRICT
SM302872,-95.2184,33.1756,Franklin,ADM2,DISTRICT
SM302100,-83.0094,39.9695,Franklin,ADM2,DISTRICT
SM301959,-78.2856,36.0828,Franklin,ADM2,DISTRICT
SM301702,-98.9528,40.1763,Franklin,ADM2,DISTRICT
SM301875,-74.3038,44.5929,Franklin,ADM2,DISTRICT
SM301552,-91.075,38.4111,Franklin,ADM2,DISTRICT
SM301247,-72.5919,42.5831,Franklin,ADM2,DISTRICT
SM301436,-90.8979,31.4772,Franklin,ADM2,DISTRICT
SM301208,-70.4441,44.9742,Franklin,ADM2,DISTRICT
SM301121,-91.6738,32.1332,Franklin,ADM2,DISTRICT
SM300912,-95.286,38.5645,Franklin,ADM2,DISTRICT
SM300707,-85.0603,39.4149,Franklin,ADM2,DISTRICT
SM301039,-84.8771,38.2392,Franklin,ADM2,DISTRICT
SM300812,-93.2625,42.7326,Franklin,ADM2,DISTRICT
SM300557,-111.8132,42.1812,Franklin,ADM2,DISTRICT
SM300494,-83.2292,34.3754,Franklin,ADM2,DISTRICT
SM300322,-84.8005,29.8117,Franklin,ADM2,DISTRICT
SM300612,-88.9242,37.9923,Franklin,ADM2,DISTRICT
SM300269,-87.8438,34.4417,Franklin,ADM2,DISTRICT
SM302368,-93.8906,35.5124,Franklin,ADM2,DISTRICT
SM18310,-79.881,36.9919,Franklin County (excl. Franklin),ADM2,DISTRICT
SM261692,136.9415,-33.6016,Franklin Harbour,ADM2,DISTRICT
SM12342,-76.7734,17.9779,Franklyn Town,ADM2,DISTRICT
SM261228,145.1688,-38.134,Frankston,ADM2,DISTRICT
SM15711,-66.4185,18.3553,Franquez Barrio,ADM2,DISTRICT
SM262730,-68.3034,-14.605,Franz Tamayo,ADM2,DISTRICT
SM261627,152.6702,-25.3414,Fraser Coast,ADM2,DISTRICT
SM458085,123.4745,-32.1754,Fraser Range,ETHNICITY,ETHNICITY
SM268747,-121.8359,49.583,Fraser Valley,ADM2,DISTRICT
SM268746,-121.2528,53.6999,Fraser-Fort George,ADM2,DISTRICT
SM291794,25.7998,47.5124,Frasin,ADM2,DISTRICT
SM289945,26.8246,44.308,Frasinet,ADM2,DISTRICT
SM231018,11.2922,46.0856,Frassilongo,DIALECT,LANGUAGE
SM290096,24.0539,46.6988,Frata,ADM2,DISTRICT
SM291795,25.8737,47.9257,Fratautii Noi,ADM2,DISTRICT
SM291796,25.903,47.9046,Fratautii Vechi,ADM2,DISTRICT
SM275736,-89.0774,14.5809,Fraternidad,ADM2,DISTRICT
SM290565,25.9621,43.9549,Fratesti,ADM2,DISTRICT
SM457996,8.8944,47.6112,Frauenfeld (2011-),ADM2,DISTRICT
SM275168,-89.737,15.8665,Fray Bartolomé de las Casas,ADM2,DISTRICT
SM9700,-61.25,-27.8333,Fray Justo Santa Maria de Oro (1992-),ADM2,DISTRICT
SM261009,-65.75,-28.3,Fray Mamerto Esquiú,ADM2,DISTRICT
SM292043,28.6449,45.1181,Frecatei,ADM2,DISTRICT
SM292672,28.0842,44.8598,Frecatei,ADM2,DISTRICT
SM267922,-40.8203,-3.7282,Frecheirinha,ADM2,DISTRICT
SM271688,12.45,55.95,Fredensborg,ADM2,DISTRICT
SM271756,9.6949,55.5727,Fredericia,ADM2,DISTRICT
SM303011,-78.2626,39.2046,Frederick,ADM2,DISTRICT
SM301215,-77.398,39.4722,Frederick,ADM2,DISTRICT
SM303012,-77.4605,38.3032,Fredericksburg,ADM2,DISTRICT
SM265676,-53.3541,-27.3245,Frederico Westphalen,ADM2,DISTRICT
SM271660,12.5222,55.6786,Frederiksberg,ADM2,DISTRICT
SM271712,10.4251,57.4335,Frederikshavn (2007-),ADM2,DISTRICT
SM271661,12.0404,55.8096,Frederikssund (2007-),ADM2,DISTRICT
SM461876,10.9379,59.2176,Frederikstad (1994-),ADM2,DISTRICT
SM303895,-64.8773,17.706,Frederiksted,ADM2,DISTRICT
SM270213,-75.6855,5.8924,Fredonia,ADM2,DISTRICT
SM12343,-76.9432,18.3544,Free Hill,ADM2,DISTRICT
SM1274,26,-29,Free State,ADM1,DISTRICT
SM301354,-93.3488,43.6738,Freeborn,ADM2,DISTRICT
SM12344,-77.5018,18.2838,Freemans Hall,ADM2,DISTRICT
SM302873,-96.1491,31.7049,Freestone,ADM2,DISTRICT
SM12345,-77.1478,17.9219,Freetown,ADM2,DISTRICT
SM263450,-41.4929,-18.1401,Frei Gaspar,ADM2,DISTRICT
SM263451,-41.871,-18.514,Frei Inocêncio,ADM2,DISTRICT
SM263452,-42.7606,-18.1429,Frei Lagonegro,ADM2,DISTRICT
SM264423,-36.4806,-6.446,Frei Martinho,ADM2,DISTRICT
SM264987,-35.8907,-7.9421,Frei Miguelinho,ADM2,DISTRICT
SM267089,-37.5803,-10.5222,Frei Paulo,ADM2,DISTRICT
SM266425,-50.7702,-27.2178,Frei Rogério,ADM2,DISTRICT
SM18211,8.3333,48.1667,Freiburg Region,ADM2,DISTRICT
SM261839,14.6484,48.4701,Freistadt,ADM2,DISTRICT
SM303714,-64.3584,9.3057,Freites,ADM2,DISTRICT
SM289453,-6.8246,41.1234,Freixo de Espada à Cinta,ADM2,DISTRICT
SM261380,115.7638,-32.0517,Fremantle,ADM2,DISTRICT
SM303315,-108.6304,43.0405,Fremont,ADM2,DISTRICT
SM300813,-95.6047,40.7456,Fremont,ADM2,DISTRICT
SM300558,-111.482,44.2288,Fremont,ADM2,DISTRICT
SM303333,-105.4397,38.473,Fremont,ADM2,DISTRICT
SM10710,33.9003,35.0514,Frenaros,ADM2,DISTRICT
SM245521,2,48,French,LANGUAGE,LANGUAGE
SM253391,1.17,47.78,French,ETHNICITY,ETHNICITY
SM253391,1.17,47.78,French,ETHNICITY,ETHNICITY
SM253391,1.17,47.78,French,ETHNICITY,ETHNICITY
SM253391,1.17,47.78,French,ETHNICITY,ETHNICITY
SM253391,1.17,47.78,French,ETHNICITY,ETHNICITY
SM253391,1.17,47.78,French,ETHNICITY,ETHNICITY
SM253391,1.17,47.78,French,ETHNICITY,ETHNICITY
SM253391,-0.05,43.09,French,ETHNICITY,ETHNICITY
SM253391,-0.05,43.09,French,ETHNICITY,ETHNICITY
SM253391,-0.05,43.09,French,ETHNICITY,ETHNICITY
SM253391,-0.05,43.09,French,ETHNICITY,ETHNICITY
SM253391,-0.05,43.09,French,ETHNICITY,ETHNICITY
SM253391,-0.05,43.09,French,ETHNICITY,ETHNICITY
SM253391,-0.05,43.09,French,ETHNICITY,ETHNICITY
SM253391,2.333,50.462,French,ETHNICITY,ETHNICITY
SM253391,2.333,50.462,French,ETHNICITY,ETHNICITY
SM253391,2.333,50.462,French,ETHNICITY,ETHNICITY
SM253391,2.333,50.462,French,ETHNICITY,ETHNICITY
SM253391,2.333,50.462,French,ETHNICITY,ETHNICITY
SM253391,2.333,50.462,French,ETHNICITY,ETHNICITY
SM253391,2.333,50.462,French,ETHNICITY,ETHNICITY
SM253391,6.6155,49.047,French,ETHNICITY,ETHNICITY
SM253391,6.6155,49.047,French,ETHNICITY,ETHNICITY
SM253391,6.6155,49.047,French,ETHNICITY,ETHNICITY
SM253391,6.6155,49.047,French,ETHNICITY,ETHNICITY
SM253391,6.6155,49.047,French,ETHNICITY,ETHNICITY
SM253391,6.6155,49.047,French,ETHNICITY,ETHNICITY
SM253391,6.6155,49.047,French,ETHNICITY,ETHNICITY
SM253391,-4.123,48.2988,French,ETHNICITY,ETHNICITY
SM253391,-4.123,48.2988,French,ETHNICITY,ETHNICITY
SM253391,-4.123,48.2988,French,ETHNICITY,ETHNICITY
SM253391,-4.123,48.2988,French,ETHNICITY,ETHNICITY
SM253391,-4.123,48.2988,French,ETHNICITY,ETHNICITY
SM253391,-4.123,48.2988,French,ETHNICITY,ETHNICITY
SM253391,-4.123,48.2988,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253391,2,46,French,ETHNICITY,ETHNICITY
SM253392,-72,47,French Canadian,ETHNICITY,ETHNICITY
SM77,-52.9986,3.9993,French Guiana,ADM0:ADM1,DISTRICT
SM77,-52.9986,3.9993,French Guiana,ADM0:ADM1,DISTRICT
SM77,-53,4,French Guiana,ADM0:ADM1,DISTRICT
SM77,-53,4,French Guiana,ADM0:ADM1,DISTRICT
SM12346,-77.5447,17.9557,French Park,ADM2,DISTRICT
SM231019,3,47,French Sign Language,LANGUAGE,LANGUAGE
SM457514,1,35,Frenda District,ADM2,DISTRICT
SM253393,-80.93,29.68,Fresh Water Indians,ETHNICITY,ETHNICITY
SM283040,-102.9723,23.2253,Fresnillo,ADM2,DISTRICT
SM14282,-98.1328,17.9251,Fresnillo de Trujano,ADM2,DISTRICT
SM303164,-119.6493,36.7582,Fresno,ADM2,DISTRICT
SM270561,-75.0376,5.1995,Fresno,ADM2,DISTRICT
SM274975,-13.5833,10.4167,Fria,ADM1:ADM2,DISTRICT
SM1275,7.1031,46.7083,Fribourg,ADM1,DISTRICT
SM12347,-77.8624,18.4459,Friendship,ADM2,DISTRICT
SM12348,-76.8196,18.1972,Friendship Gap,ADM2,DISTRICT
SM1276,5.8333,53.1667,Friesland,ADM1,DISTRICT
SM302874,-99.1083,28.8678,Frio,ADM2,DISTRICT
SM370513,6.1917,49.515,Frisange,ADM1:ADM2:ADM3,DISTRICT
SM1277,13,46,Friuli-Venezia Giulia,ADM1,DISTRICT
SM231024,13.0493,46.1473,Friulian,LANGUAGE,LANGUAGE
SM10711,32.667,35.0736,Frodisia,ADM2,DISTRICT
SM286054,10.6357,59.6667,Frogn (1962-),ADM2,DISTRICT
SM286312,8.6293,58.5072,Froland,ADM2,DISTRICT
SM16891,43.9167,49.8333,Frolovskiy Rayon,ADM2,DISTRICT
SM12349,-78.149,18.2797,Frome,ADM2,DISTRICT
SM289298,-7.6105,39.0718,Fronteira,ADM2,DISTRICT
SM263454,-49.1764,-20.2228,Fronteira,ADM2,DISTRICT
SM263453,-40.8301,-16.8917,Fronteira dos Vales,ADM2,DISTRICT
SM265232,-40.5621,-7.0125,Fronteiras,ADM2,DISTRICT
SM268844,-76.7494,44.6668,Frontenac,ADM2,DISTRICT
SM283278,-101.4801,26.9679,Frontera,ADM2,DISTRICT
SM283139,-92.0767,15.741,Frontera Comalapa,ADM2,DISTRICT
SM283140,-92.2071,14.7515,Frontera Hidalgo,ADM2,DISTRICT
SM282567,-109.6062,30.8215,Fronteras,ADM2,DISTRICT
SM301703,-100.3942,40.5301,Frontier,ADM2,DISTRICT
SM270214,-76.3232,6.7011,Frontino,ADM2,DISTRICT
SM15712,-66.5585,18.3083,Fronton Barrio,ADM2,DISTRICT
SM277960,13.45,41.6167,Frosinone,ADM2,DISTRICT
SM286466,10.7414,63.5877,Frosta,ADM2,DISTRICT
SM12350,-76.5862,18.1089,Fruitfulvale,ADM2,DISTRICT
SM291887,25.4631,43.803,Frumoasa,ADM2,DISTRICT
SM290690,25.8676,46.4468,Frumoasa,ADM2,DISTRICT
SM291797,25.6353,47.6183,Frumosu,ADM2,DISTRICT
SM289946,26.3097,44.3172,Frumusani,ADM2,DISTRICT
SM292566,26.8797,47.526,Frumusica,ADM2,DISTRICT
SM290440,28.0663,45.6559,Frumusita,ADM2,DISTRICT
SM16892,30.3789,59.8719,Frunzenskiy Rayon,ADM2,DISTRICT
SM299635,29.7595,47.3294,Frunzivs'kyi,ADM2,DISTRICT
SM9762,27.495,53.9006,Frunzyenski Rayon,ADM2,DISTRICT
SM263455,-42.5286,-16.1621,Fruta de Leite,ADM2,DISTRICT
SM263456,-48.9563,-19.9781,Frutal,ADM2,DISTRICT
SM17917,7.6229,46.6024,Frutigen-Niedersimmental District,ADM2,DISTRICT
SM265546,-37.8443,-6.1626,Frutuoso Gomes,ADM2,DISTRICT
SM286199,8.8243,63.7301,Frøya,ADM2,DISTRICT
SM271231,18.5,49.5833,Frýdek-Místek,ADM2,DISTRICT
SM10712,33.2261,35.3179,Ftericha,ADM2,DISTRICT
SM10713,33.0757,34.9666,Fterikoudi,ADM2,DISTRICT
SM280953,-10.274,6.9425,Fuamah,ADM2,DISTRICT
SM279427,139.5,35.6667,Fuchū,ADM2,DISTRICT
SM278141,133.2333,34.5667,Fuchū City,ADM2,DISTRICT
SM279617,138.6807,35.5996,Fuefuki,ADM2,DISTRICT
SM270299,-73.6295,3.3417,Fuente de Oro,ADM2,DISTRICT
SM15335,-58.9621,-20.7021,Fuerte Olimpo (2005-),ADM2,DISTRICT
SM284843,12.7936,9.2024,Fufore,ADM2,DISTRICT
SM274519,-6.8005,62.2532,Fuglafjørður,ADM2,DISTRICT
SM274524,-6.2979,62.3369,Fugloy,ADM2,DISTRICT
SM253395,9.84,5.79,Fugon,ETHNICITY,ETHNICITY
SM1278,56.25,25.1333,Fujairah,ADM1,DISTRICT
SM12887,138.6991,35.2011,Fuji Shi (2008-),ADM2,DISTRICT
SM1279,118,26.25,Fujian,ADM1,DISTRICT
SM1280,118.3859,24.4697,Fujian,ADM1,DISTRICT
SM12888,138.2338,34.9196,Fujieda Shi (2009-),ADM2,DISTRICT
SM279112,135.5973,34.5742,Fujiidera,ADM2,DISTRICT
SM279186,139.5548,35.8539,Fujimi,ADM2,DISTRICT
SM279197,139.5152,35.865,Fujimino,ADM2,DISTRICT
SM12889,138.6238,35.3175,Fujinomiya Shi (2008-),ADM2,DISTRICT
SM278117,138.9992,36.1921,Fujioka,ADM2,DISTRICT
SM278555,139.459,35.3681,Fujisawa,ADM2,DISTRICT
SM279619,138.7937,35.4392,Fujiyoshida,ADM2,DISTRICT
SM278247,142.0992,43.7933,Fukagawa,ADM2,DISTRICT
SM279208,139.2833,36.2,Fukaya,ADM2,DISTRICT
SM278678,135.1133,35.324,Fukuchiyama,ADM2,DISTRICT
SM279656,136.2167,36.0667,Fukui,ADM2,DISTRICT
SM1281,136.2167,35.85,Fukui,ADM1:ADMX,DISTRICT
SM279663,130.3576,33.5689,Fukuoka,ADM2,DISTRICT
SM1282,130.6647,33.5281,Fukuoka,ADM1:ADMX,DISTRICT
SM279312,137.9281,34.7418,Fukuroi,ADM2,DISTRICT
SM1283,140.222,37.3816,Fukushima,ADM1:ADMX,DISTRICT
SM12890,140.3885,37.7864,Fukushima Shi (2008-),ADM2,DISTRICT
SM279664,130.498,33.7798,Fukutsu,ADM2,DISTRICT
SM278142,133.3378,34.5234,Fukuyama,ADM2,DISTRICT
SM275107,-15.1667,11.8,Fulacunda,ADM2,DISTRICT
SM258818,3.24,11.92,Fulani-Generic,ETHNICITY,ETHNICITY
SM258818,-1.94,12.79,Fulani-Generic,ETHNICITY,ETHNICITY
SM258818,-7.43,13.08,Fulani-Generic,ETHNICITY,ETHNICITY
SM258818,-11.91,12.59,Fulani-Generic,ETHNICITY,ETHNICITY
SM250807,0,14,"Fulani-Western Niger (BF, Niger)",ETHNICITY,ETHNICITY
SM291528,26.4441,44.8891,Fulga De Jos,ADM2,DISTRICT
SM289703,-56.562,-26.5421,Fulgencio Yegros,ADM2,DISTRICT
SM231038,28.9865,-3.0402,Fuliiru,LANGUAGE,LANGUAGE
SM10358,107.332,29.6606,Fuling Qu,ADM2,DISTRICT
SM275063,-14.25,13.3333,Fulladu East,ADM2,DISTRICT
SM275047,-14.75,13.5,Fulladu West,ADM2,DISTRICT
SM12351,-78.1998,18.2989,Fullersfield,ADM2,DISTRICT
SM12352,-77.7757,18.0153,Fullerswood,ADM2,DISTRICT
SM253396,-38.07,-8.92,Fulni-o,ETHNICITY,ETHNICITY
SM231041,-37.1402,-9.0259,Fulniô,LANGUAGE,LANGUAGE
SM302306,-78.1127,39.9253,Fulton,ADM2,DISTRICT
SM302101,-84.1301,41.6018,Fulton,ADM2,DISTRICT
SM301876,-74.4222,43.1139,Fulton,ADM2,DISTRICT
SM300708,-86.2636,41.047,Fulton,ADM2,DISTRICT
SM301040,-89.1876,36.5541,Fulton,ADM2,DISTRICT
SM300613,-90.2075,40.4728,Fulton,ADM2,DISTRICT
SM300496,-84.467,33.7903,Fulton,ADM2,DISTRICT
SM302369,-91.8182,36.3817,Fulton,ADM2,DISTRICT
SM231044,11.1087,6.6652,Fum,LANGUAGE,LANGUAGE
SM277728,49.1833,37.1667,Fuman,ADM2,DISTRICT
SM11120,15.2971,-4.3575,Funa,ADM2,DISTRICT
SM458008,139.9826,35.6946,Funabashi,ADM2,DISTRICT
SM279393,139.9019,35.6425,Funabashi Part 2,ADM2,DISTRICT
SM1284,179.1305,-8.5172,Funafuti,ADM1,DISTRICT
SM284750,11.4084,10.7423,Funakaye,ADM2,DISTRICT
SM289278,-16.9172,32.6762,Funchal,ADM2,DISTRICT
SM10411,-73.9167,10.4167,Fundacion (1999-),ADM2,DISTRICT
SM271866,-71.1533,18.2631,Fundación,ADM2,DISTRICT
SM292626,25.2778,45.4462,Fundata,ADM2,DISTRICT
SM289947,26.3497,44.3832,Fundeni,ADM2,DISTRICT
SM289872,26.881,45.3095,Fundeni,ADM2,DISTRICT
SM290441,27.551,45.5674,Fundenii Noi,ADM2,DISTRICT
SM291798,25.3335,47.555,Fundu Moldovei,ADM2,DISTRICT
SM289949,26.499,44.4646,Fundulea,ADM2,DISTRICT
SM289464,-7.4954,40.1241,Fundão,ADM2,DISTRICT
SM267981,-40.3158,-19.9899,Fundão,ADM2,DISTRICT
SM285270,11.4486,11.8521,Fune,ADM2,DISTRICT
SM270313,-77.3522,0.9318,Funes,ADM2,DISTRICT
SM250518,10,7,Fungom,ETHNICITY,ETHNICITY
SM231047,6.5914,10.5021,Fungwa,LANGUAGE,LANGUAGE
SM283903,34.3915,-23.0873,Funhalouro,ADM2,DISTRICT
SM263457,-44.0821,-19.3519,Funilândia,ADM2,DISTRICT
SM10502,13.6063,45.1748,Funtana-Fontane,ADM2,DISTRICT
SM284902,7.307,11.472,Funtua,ADM2,DISTRICT
SM269994,-74.2179,4.7306,Funza,ADM2,DISTRICT
SM231052,23.6401,12.0455,Fur,LANGUAGE,LANGUAGE
SM250238,27.05,14.15,Fur,ETHNICITY,ETHNICITY
SM250238,27.05,14.15,Fur,ETHNICITY,ETHNICITY
SM250238,27.05,14.15,Fur,ETHNICITY,ETHNICITY
SM250238,24,12,Fur,ETHNICITY,ETHNICITY
SM250238,24,12,Fur,ETHNICITY,ETHNICITY
SM250238,24,12,Fur,ETHNICITY,ETHNICITY
SM250238,25.5,13.5,Fur,ETHNICITY,ETHNICITY
SM250238,25.5,13.5,Fur,ETHNICITY,ETHNICITY
SM250238,25.5,13.5,Fur,ETHNICITY,ETHNICITY
SM278250,142.4735,43.2818,Furano,ADM2,DISTRICT
SM291888,25.1398,43.8657,Furculesti,ADM2,DISTRICT
SM271662,12.3417,55.7833,Furesø,ADM2,DISTRICT
SM292928,41.1167,57.25,Furmanov,ADM2,DISTRICT
SM292939,41,57.25,Furmanovskiy rayon,ADM2,DISTRICT
SM301704,-99.9123,40.1765,Furnas,ADM2,DISTRICT
SM15713,-67.0125,18.2483,Furnias Barrio,ADM2,DISTRICT
SM303482,70.7619,40.5021,Furqat,ADM2,DISTRICT
SM231055,20.6448,4.485,Furu,LANGUAGE,LANGUAGE
SM278251,140.6113,43.1972,Furubira,ADM2,DISTRICT
SM12891,140.4612,43.1638,Furuu-gun,ADM2,DISTRICT
SM269997,-74.3833,4.2924,Fusagasugá,ADM2,DISTRICT
SM269251,124.7453,41.8019,Fushun,ADM2,DISTRICT
SM279428,139.3364,35.7358,Fussa,ADM2,DISTRICT
SM12892,140.8813,37.3985,Futaba-gun,ADM2,DISTRICT
SM261116,-71.4167,-43.0833,Futaleufú,ADM2,DISTRICT
SM279400,139.9005,35.226,Futtsu,ADM2,DISTRICT
SM231056,170.215,-19.5303,Futuna-Aniwa,LANGUAGE,LANGUAGE
SM253398,-178.11,-14.31,Futunans,ETHNICITY,ETHNICITY
SM12893,136.4854,35.3726,Fuwa-gun,ADM2,DISTRICT
SM273836,30.5823,31.2253,Fuwah,ADM2,DISTRICT
SM269252,122.0153,42.3303,Fuxin,ADM2,DISTRICT
SM269133,115.7078,32.9372,Fuyang,ADM2,DISTRICT
SM259606,124.2,47.8,Fuyu Kirghiz,DIALECT,LANGUAGE
SM231058,147.312,-8.6371,Fuyug,LANGUAGE,LANGUAGE
SM253399,147.25,-8.41,Fuyughe,ETHNICITY,ETHNICITY
SM253399,147.25,-8.41,Fuyughe,ETHNICITY,ETHNICITY
SM253399,147,-8,Fuyughe,ETHNICITY,ETHNICITY
SM253399,147,-8,Fuyughe,ETHNICITY,ETHNICITY
SM269222,116.4152,27.5022,Fuzhou,ADM2,DISTRICT
SM269393,119.1121,26.0792,Fuzhou,ADM2,DISTRICT
SM305405,14.7141,45.3048,Fužine,ADM2,DISTRICT
SM231061,24.4315,-17.7237,Fwe,LANGUAGE,LANGUAGE
SM231060,164.901,-20.6873,Fwâi,LANGUAGE,LANGUAGE
SM231062,9.2229,9.5901,Fyam,LANGUAGE,LANGUAGE
SM231064,9.295,9.4994,Fyer,LANGUAGE,LANGUAGE
SM10714,33.105,35.1963,Fyllia,ADM2,DISTRICT
SM279861,62.8333,53.5833,Fyodorovskiy,ADM2,DISTRICT
SM286217,8.0895,59.1865,Fyresdal,ADM2,DISTRICT
SM10715,32.5441,34.9328,Fyti,ADM2,DISTRICT
SM274538,-6.8685,61.5211,Fámjin,ADM2,DISTRICT
SM267234,-48.8676,-10.8195,Fátima,ADM2,DISTRICT
SM267399,-38.2237,-10.546,Fátima,ADM2,DISTRICT
SM296568,12.0114,58.6163,Färgelanda,ADM2,DISTRICT
SM289558,-56.5314,-25.7122,Félix Perez Cardozo,ADM2,DISTRICT
SM264459,-52.04,-23.9055,Fênix,ADM2,DISTRICT
SM269992,-73.7888,4.5281,Fómeque,ADM2,DISTRICT
SM1268,20.5,60,Föglö,ADM1:ADM2,DISTRICT
SM269996,-73.77,5.4199,Fúquene,ADM2,DISTRICT
SM276124,20.4288,47.7267,Füzesabony,ADM2,DISTRICT
SM261887,47.3333,39.5833,Füzuli,ADM1:ADM2,DISTRICT
SM1242,27.75,47.5833,Făleşti,ADM1,DISTRICT
SM298831,41.1445,41.2711,Fındıklı,ADM2,DISTRICT
SM303493,67.4167,40,G'allaorol,ADM2,DISTRICT
SM18393,64.8333,40.5,G'ijduvon Tumani,ADM2,DISTRICT
SM18393,64.8333,40.5,G'ijduvon Tumani,ADM2,DISTRICT
SM18393,64.8276,40.5201,G'ijduvon Tumani,ADM2,DISTRICT
SM18393,64.8276,40.5201,G'ijduvon Tumani,ADM2,DISTRICT
SM10503,17.2673,45.7822,GJakovo,ADM2,DISTRICT
SM458210,151.7903,-24.9048,GOORENG GOORENG,ETHNICITY,ETHNICITY
SM459436,144.9428,-15.0852,GUUGU YIMITHIRR,ETHNICITY,ETHNICITY
SM18394,66.201,38.5396,Guzor Tumani,ADM2,DISTRICT
SM231065,-0.186,5.6549,Ga,LANGUAGE,LANGUAGE
SM250239,-0.86,5.74,Ga,ETHNICITY,ETHNICITY
SM250239,-0.86,5.74,Ga,ETHNICITY,ETHNICITY
SM250239,0,6,Ga,ETHNICITY,ETHNICITY
SM250239,0,6,Ga,ETHNICITY,ETHNICITY
SM11395,-0.2848,5.6252,Ga Central Municipal Assembly,ADM2,DISTRICT
SM11396,-0.2,5.7333,Ga East (2012-),ADM2,DISTRICT
SM11397,-0.3034,5.5577,Ga South,ADM2,DISTRICT
SM11399,-0.3152,5.6725,Ga West (2012-),ADM2,DISTRICT
SM231070,12.6479,10.2955,Ga'anda,LANGUAGE,LANGUAGE
SM231089,121.455,17.2238,Ga'dang,LANGUAGE,LANGUAGE
SM231066,11.9603,8.4809,Gaa,LANGUAGE,LANGUAGE
SM297971,9.3536,36.2363,Gaafour,ADM2,DISTRICT
SM4254,73.5092,0.3857,Gaafu Alifu Atholhu,ADM1:ADM2,DISTRICT
SM4255,73.1016,0.2203,Gaafu Dhaalu Atholhu,ADM1:ADM2,DISTRICT
SM231110,132.643,-12.5176,Gaagudju,LANGUAGE,LANGUAGE
SM17870,47.688,6.9352,Gaalkacyo,ADM2,DISTRICT
SM231069,33.9797,11.4674,Gaam,LANGUAGE,LANGUAGE
SM253402,146.86,-8.97,Gabadi,ETHNICITY,ETHNICITY
SM253403,37.11,18.72,Gabail Ukhra,ETHNICITY,ETHNICITY
SM284857,8.8622,12.1452,Gabasawa,ADM2,DISTRICT
SM253404,37.94,3.49,Gabbra,ETHNICITY,ETHNICITY
SM253404,37.94,3.49,Gabbra,ETHNICITY,ETHNICITY
SM253404,37.5,3,Gabbra,ETHNICITY,ETHNICITY
SM253404,37.5,3,Gabbra,ETHNICITY,ETHNICITY
SM295803,43.6809,9.8596,Gabiley,ADM2,DISTRICT
SM231077,-5.8418,5.9648,Gabogbo,LANGUAGE,LANGUAGE
SM268543,25.7995,-24.7623,Gaborone,ADM2,DISTRICT
SM1286,25.9119,-24.6464,Gaborone,ADM1,DISTRICT
SM231078,16.623,9.3395,Gabri,LANGUAGE,LANGUAGE
SM266533,-50.57,-21.4999,Gabriel Monteiro,ADM2,DISTRICT
SM282026,-102.0172,19.1746,Gabriel Zamora,ADM2,DISTRICT
SM262511,25.3,42.9,Gabrovo,ADM2,DISTRICT
SM1287,25.25,42.95,Gabrovo,ADM1,DISTRICT
SM275096,-14.241,12.122,Gabu,ADM2,DISTRICT
SM458419,148.7252,-22.8686,Gabulbarra,ETHNICITY,ETHNICITY
SM231085,146.621,-5.8075,Gabutamon,LANGUAGE,LANGUAGE
SM1285,9.75,33.8333,Gabès,ADM1,DISTRICT
SM298069,10.0967,33.8962,Gabès Médina,ADM2,DISTRICT
SM298071,9.9884,33.8948,Gabès Ouest,ADM2,DISTRICT
SM298072,10.0494,33.7981,Gabès Sud,ADM2,DISTRICT
SM1288,-14.1667,12.1667,Gabú,ADM1,DISTRICT
SM269998,-73.5,4.6667,Gachalá,ADM2,DISTRICT
SM269999,-73.878,5.0102,Gachancipá,ADM2,DISTRICT
SM270770,-73.5425,5.7442,Gachantivá,ADM2,DISTRICT
SM270000,-73.6173,4.871,Gachetá,ADM2,DISTRICT
SM313230,18.5264,43.1828,Gacko,ADM2:ADM3,DISTRICT
SM285236,5.6552,13.7474,Gada,ADM2,DISTRICT
SM253409,82.97,18.33,Gadaba,ETHNICITY,ETHNICITY
SM276929,75.6519,15.4984,Gadag,ADM2,DISTRICT
SM231090,16.997,10.425,Gadang,LANGUAGE,LANGUAGE
SM231093,121.177,16.5468,Gaddang,LANGUAGE,LANGUAGE
SM253411,121.82,16.99,Gaddang,ETHNICITY,ETHNICITY
SM231092,76.5713,32.36,Gaddi,LANGUAGE,LANGUAGE
SM231096,7.4987,8.7114,Gade,LANGUAGE,LANGUAGE
SM253415,5.11,12.11,Gade,ETHNICITY,ETHNICITY
SM231095,78.2187,25.8583,Gade Lohar,LANGUAGE,LANGUAGE
SM17388,-60.9103,13.9594,Gadette,ADM2,DISTRICT
SM253421,128.77,-15.04,Gadjerong,ETHNICITY,ETHNICITY
SM253421,128.77,-15.04,Gadjerong,ETHNICITY,ETHNICITY
SM253421,129.2747,-15.293,Gadjerong,ETHNICITY,ETHNICITY
SM253421,129.2747,-15.293,Gadjerong,ETHNICITY,ETHNICITY
SM264424,-35.8197,-7.5998,Gado Bravo,ADM2,DISTRICT
SM253422,15.4,16.77,Gadoa,ETHNICITY,ETHNICITY
SM10046,114.894,4.9458,Gadong A,ADM2,DISTRICT
SM10047,114.9109,4.9205,Gadong B,ADM2,DISTRICT
SM323928,-112.3515,52.2969,Gadsby,ADM2:ADM3,DISTRICT
SM300323,-84.6136,30.5795,Gadsden,ADM2,DISTRICT
SM231101,145.997,-6.3163,Gadsup,LANGUAGE,LANGUAGE
SM253424,146.32,-6.29,Gadsup,ETHNICITY,ETHNICITY
SM459086,143.644,-38.6182,Gadubanud,ETHNICITY,ETHNICITY
SM253425,123.99,-22.12,Gadudjara,ETHNICITY,ETHNICITY
SM253425,123.99,-22.12,Gadudjara,ETHNICITY,ETHNICITY
SM253425,123.99,-22.12,Gadudjara,ETHNICITY,ETHNICITY
SM253425,125.06,-22.79,Gadudjara,ETHNICITY,ETHNICITY
SM253425,125.06,-22.79,Gadudjara,ETHNICITY,ETHNICITY
SM253425,125.06,-22.79,Gadudjara,ETHNICITY,ETHNICITY
SM253425,126.5032,-20.5765,Gadudjara,ETHNICITY,ETHNICITY
SM253425,126.5032,-20.5765,Gadudjara,ETHNICITY,ETHNICITY
SM253425,126.5032,-20.5765,Gadudjara,ETHNICITY,ETHNICITY
SM10237,16.7074,4.8901,Gadzi,ADM2,DISTRICT
SM290267,25.3204,44.72,Gaesti,ADM2,DISTRICT
SM231107,39.5605,11.5764,Gafat,LANGUAGE,LANGUAGE
SM253426,145.38,9.23,Gaferut,ETHNICITY,ETHNICITY
SM14929,145.384,9.228,Gaferut Municipality,ADM2,DISTRICT
SM1289,9,34.5,Gafsa,ADM1,DISTRICT
SM298082,8.9705,34.5555,Gafsa Nord,ADM2,DISTRICT
SM298083,8.6631,34.3783,Gafsa Sud,ADM2,DISTRICT
SM294258,56.75,54,Gafuriyskiy rayon,ADM2,DISTRICT
SM1290,-172.25,-13.5,Gaga'emauga,ADM1,DISTRICT
SM304726,-171.8659,-13.8286,Gaga'emauga I,ADM2,DISTRICT
SM253427,132.49,-12.52,Gagadju,ETHNICITY,ETHNICITY
SM253427,132.49,-12.52,Gagadju,ETHNICITY,ETHNICITY
SM253427,132.49,-12.52,Gagadju,ETHNICITY,ETHNICITY
SM253427,132.95,-13.13,Gagadju,ETHNICITY,ETHNICITY
SM253427,132.95,-13.13,Gagadju,ETHNICITY,ETHNICITY
SM253427,132.95,-13.13,Gagadju,ETHNICITY,ETHNICITY
SM253427,133.0564,-12.4808,Gagadju,ETHNICITY,ETHNICITY
SM253427,133.0564,-12.4808,Gagadju,ETHNICITY,ETHNICITY
SM253427,133.0564,-12.4808,Gagadju,ETHNICITY,ETHNICITY
SM1291,-172.5,-13.5,Gagaifomauga,ADM1,DISTRICT
SM10292,15.1935,8.8976,Gagal,ADM2,DISTRICT
SM284806,9.5372,12.5114,Gagarawa,ADM2,DISTRICT
SM231108,28.0277,44.5988,Gagauz,LANGUAGE,LANGUAGE
SM253428,29.32,45.44,Gagauzan,ETHNICITY,ETHNICITY
SM253428,29.32,45.44,Gagauzan,ETHNICITY,ETHNICITY
SM253428,28.38,45.54,Gagauzan,ETHNICITY,ETHNICITY
SM253428,28.38,45.54,Gagauzan,ETHNICITY,ETHNICITY
SM301705,-96.6894,40.2619,Gage,ADM2,DISTRICT
SM292276,27.9803,46.3331,Gagesti,ADM2,DISTRICT
SM14930,138.1903,9.5472,Gagil Municipality,ADM2,DISTRICT
SM293758,45,55.25,Gaginskiy rayon,ADM2,DISTRICT
SM296551,14.9468,60.4739,Gagnef,ADM2,DISTRICT
SM231111,-5.9701,6.1005,Gagnoa Bété,LANGUAGE,LANGUAGE
SM250242,-5.37,5.33,Gagou,ETHNICITY,ETHNICITY
SM250242,-5.37,5.33,Gagou,ETHNICITY,ETHNICITY
SM250242,-6,7,Gagou,ETHNICITY,ETHNICITY
SM250242,-6,7,Gagou,ETHNICITY,ETHNICITY
SM274767,40.2531,43.3228,Gagra,ADM2,DISTRICT
SM262084,29.7325,-2.9928,Gahombo,ADM2,DISTRICT
SM253431,145.41,-5.97,Gahuku-Gama,ETHNICITY,ETHNICITY
SM262277,89.5,25.25,Gaibandha,ADM2,DISTRICT
SM292108,27.2215,46.3506,Gaiceana,ADM2,DISTRICT
SM253432,146.62,-7.18,Gaidemoe,ETHNICITY,ETHNICITY
SM10716,33.7958,35.1547,Gaidouras,ADM2,DISTRICT
SM13517,27.0705,56.7569,Gaigalavas pagasts,ADM2,DISTRICT
SM13518,22.5373,56.7917,Gaiku pagasts,ADM2,DISTRICT
SM231118,143.139,-4.0172,Gaikundi,LANGUAGE,LANGUAGE
SM231119,26.895,-28.77,Gail,LANGUAGE,LANGUAGE
SM13519,24.2121,56.3165,Gailisu pagasts,ADM2,DISTRICT
SM261117,-66.3333,-43.3333,Gaiman,ADM2,DISTRICT
SM231120,148.59,-9.1441,Gaina,LANGUAGE,LANGUAGE
SM302875,-102.6352,32.7407,Gaines,ADM2,DISTRICT
SM299114,36.8808,-6.1384,Gairo,ADM2,DISTRICT
SM290566,25.6326,44.5163,Gaiseni,ADM2,DISTRICT
SM253433,147.25,-7.68,Gaiwa,ETHNICITY,ETHNICITY
SM459089,142.3483,-38.0241,Gaiwurrung,ETHNICITY,ETHNICITY
SM277108,84.2,18.91,Gajapati,ADM2,DISTRICT
SM231098,129.156,-15.4786,Gajirrabeng,LANGUAGE,LANGUAGE
SM295137,29.7854,-1.6981,Gakenke,ADM1:ADM2,DISTRICT
SM231154,145.623,-5.1635,Gal,LANGUAGE,LANGUAGE
SM262442,25.9667,42.15,Galabovo,ADM2,DISTRICT
SM253436,121.65,-31.65,Galago,ETHNICITY,ETHNICITY
SM253436,121.65,-31.65,Galago,ETHNICITY,ETHNICITY
SM253436,121.4876,-32.1102,Galago,ETHNICITY,ETHNICITY
SM253436,121.4876,-32.1102,Galago,ETHNICITY,ETHNICITY
SM231129,10.2542,10.1258,Galambu,LANGUAGE,LANGUAGE
SM296175,17.7167,48.2,Galanta,ADM2,DISTRICT
SM270676,-74.887,10.8979,Galapa,ADM2,DISTRICT
SM10717,32.8847,34.9915,Galata,ADM2,DISTRICT
SM10718,32.6422,34.8668,Galataria,ADM2,DISTRICT
SM10719,34.0742,35.4236,Galateia,ADM2,DISTRICT
SM291890,25.3647,44.2249,Galateni,ADM2,DISTRICT
SM15714,-66.9944,18.415,Galateo Alto Barrio,ADM2,DISTRICT
SM15715,-66.9969,18.4638,Galateo Bajo Barrio,ADM2,DISTRICT
SM15716,-66.2564,18.361,Galateo Barrio,ADM2,DISTRICT
SM290442,28.0412,45.4408,Galati,ADM2,DISTRICT
SM231125,32.84,39.34,Galatian,LANGUAGE,LANGUAGE
SM292495,24.4215,46.9931,Galatii Bistritei,ADM2,DISTRICT
SM290691,25.3912,46.9003,Galautas,ADM2,DISTRICT
SM303013,-80.924,36.6612,Galax,ADM2,DISTRICT
SM1293,27.75,45.75,Galați,ADM1,DISTRICT
SM17389,-60.9393,13.8359,Galba,ADM2,DISTRICT
SM292673,27.1667,45.1925,Galbenu,ADM2,DISTRICT
SM289873,26.9436,45.0744,Galbinasi,ADM2,DISTRICT
SM289795,23.5692,46.2178,Galda De Jos,ADM2,DISTRICT
SM458819,134.3889,-13.5656,Galduyh,ETHNICITY,ETHNICITY
SM282158,-100.3695,24.8504,Galeana,ADM2,DISTRICT
SM283226,-107.6317,30.1092,Galeana,ADM2,DISTRICT
SM231135,128.44,2.3146,Galela,LANGUAGE,LANGUAGE
SM13520,26.8474,56.4595,Galenu pagasts,ADM2,DISTRICT
SM270548,-74.9777,9.1332,Galeras,ADM2,DISTRICT
SM291145,24.7659,46.4965,Galesti,ADM2,DISTRICT
SM253438,149.65,-9.97,Galeva,ETHNICITY,ETHNICITY
SM231133,150.836,-9.5414,Galeya,LANGUAGE,LANGUAGE
SM291605,23.6876,47.2767,Galgau,ADM2,DISTRICT
SM13521,26.5715,57.1709,Galgauskas pagasts,ADM2,DISTRICT
SM1295,47,5,Galguduud,ADM1:ADM2,DISTRICT
SM11304,41.75,42.6667,Gali District (1995-),ADM2,DISTRICT
SM458194,152.8668,-28.5333,Galibal,ETHNICITY,ETHNICITY
SM296074,-54.0166,5.7142,Galibi,ADM2,DISTRICT
SM253439,-54.15,5.65,Galibi,ETHNICITY,ETHNICITY
SM253439,-53.12,5.27,Galibi,ETHNICITY,ETHNICITY
SM231144,-56.8323,5.8377,Galibi Carib,LANGUAGE,LANGUAGE
SM231143,-120.75,45.3914,Galice,LANGUAGE,LANGUAGE
SM292188,24.2963,44.9443,Galicea,ADM2,DISTRICT
SM290363,23.3097,44.0981,Galicea Mare,ADM2,DISTRICT
SM16893,42.25,58.3333,Galichskiy rayon,ADM2,DISTRICT
SM1296,-7.8662,42.7551,Galicia,ADM1,DISTRICT
SM231140,-7.5343,42.2446,Galician,LANGUAGE,LANGUAGE
SM263459,-41.525,-18.869,Galiléia,ADM2,DISTRICT
SM12353,-76.9004,18.4027,Galina,ADM2,DISTRICT
SM265547,-36.2631,-5.1217,Galinhos,ADM2,DISTRICT
SM10720,32.781,35.1321,Galini,ADM2,DISTRICT
SM10721,34.3085,35.5283,Galinoporni,ADM2,DISTRICT
SM253442,-8.14,42.74,Gallacians,ETHNICITY,ETHNICITY
SM253442,-8.14,42.74,Gallacians,ETHNICITY,ETHNICITY
SM253442,-8.1,42.5,Gallacians,ETHNICITY,ETHNICITY
SM253442,-8.1,42.5,Gallacians,ETHNICITY,ETHNICITY
SM301629,-111.1704,45.5405,Gallatin,ADM2,DISTRICT
SM301041,-84.8593,38.7569,Gallatin,ADM2,DISTRICT
SM300615,-88.2305,37.7627,Gallatin,ADM2,DISTRICT
SM1297,80.25,6.25,Galle,ADM1:ADM2,DISTRICT
SM302102,-82.3169,38.8247,Gallia,ADM2,DISTRICT
SM231151,-2.0544,47.931,Gallo,DIALECT,LANGUAGE
SM231152,9.2428,41.1131,Gallurese Sardinian,LANGUAGE,LANGUAGE
SM231157,94.6885,27.9806,Galo,LANGUAGE,LANGUAGE
SM231158,125.959,-8.6107,Galoli-Talur,LANGUAGE,LANGUAGE
SM275079,-14.571,11.956,Galomaro,ADM2,DISTRICT
SM305607,15.3912,44.0732,Galovac,ADM2,DISTRICT
SM283564,110.8349,46.2357,Galshar,ADM2,DISTRICT
SM283617,99.8747,48.7673,Galt,ADM2,DISTRICT
SM244602,142.367,-3.4245,Galu,LANGUAGE,LANGUAGE
SM283748,100.144,46.6917,Galuut,ADM2,DISTRICT
SM271809,-71.328,18.4897,Galvan,ADM2,DISTRICT
SM302876,-94.8109,29.2977,Galveston,ADM2,DISTRICT
SM266426,-52.6854,-26.4588,Galvão,ADM2,DISTRICT
SM12061,-9.05,53.2877,Galway City,ADM2,DISTRICT
SM270468,-73.3423,6.6627,Galán,ADM2,DISTRICT
SM1294,-90.8734,-0.6702,Galápagos,ADM1,DISTRICT
SM270001,-73.62,4.7098,Gama,ADM2,DISTRICT
SM278082,137.2275,34.8276,Gamagōri,ADM2,DISTRICT
SM231164,82.6749,28.411,Gamale Kham,LANGUAGE,LANGUAGE
SM275080,-14.818,12.285,Gamamundo,ADM2,DISTRICT
SM269890,-73.7177,8.3297,Gamarra,ADM2,DISTRICT
SM285327,10.5862,12.073,Gamawa,ADM2,DISTRICT
SM458453,152.6715,-30.3784,Gambalamam,ETHNICITY,ETHNICITY
SM1299,34.5,7.8333,Gambela Peoples,ADM1,DISTRICT
SM231170,126.2713,-14.2955,Gambera,LANGUAGE,LANGUAGE
SM455325,16.7,13.4,Gambia,ETHNICITY,ETHNICITY
SM231174,-16.5806,13.4534,Gambian Sign Language,LANGUAGE,LANGUAGE
SM231171,-16.1544,13.5337,Gambian Wolof,LANGUAGE,LANGUAGE
SM11239,-138.7189,-19.3803,Gambier,ADM2,DISTRICT
SM253447,-23.15,-135.04,Gambier Islands,ETHNICITY,ETHNICITY
SM459225,145.3898,-17.6805,Gambilbarra Jirrbal,ETHNICITY,ETHNICITY
SM10238,22.1574,4.6531,Gambo,ADM2,DISTRICT
SM16307,16.0207,-1.9341,Gamboma,ADM2,DISTRICT
SM260318,14.0766,-15.8093,Gambos,ADM2,DISTRICT
SM10239,15.1052,4.3786,Gamboula,ADM2,DISTRICT
SM458153,151.4416,-28.6189,Gambuwal,ETHNICITY,ETHNICITY
SM231177,-45.1835,-3.2913,Gamela of Viana,LANGUAGE,LANGUAGE
SM264988,-35.3654,-8.5905,Gameleira,ADM2,DISTRICT
SM9926,-48.6746,-16.4313,Gameleira De Goias,ADM2,DISTRICT
SM263460,-43.3061,-14.971,Gameleiras,ADM2,DISTRICT
SM231179,73.6839,21.0697,Gamit,LANGUAGE,LANGUAGE
SM253449,73.31,21.35,Gamit,ETHNICITY,ETHNICITY
SM285328,9.9999,10.7492,Gamjuwa,ADM2,DISTRICT
SM231183,127.506,1.3431,Gamkonora,LANGUAGE,LANGUAGE
SM253450,142.47,-11.29,Gammatti,ETHNICITY,ETHNICITY
SM253450,142.47,-11.29,Gammatti,ETHNICITY,ETHNICITY
SM253450,142.2942,-11.3145,Gammatti,ETHNICITY,ETHNICITY
SM253450,142.2942,-11.3145,Gammatti,ETHNICITY,ETHNICITY
SM231186,37.2381,6.2765,Gamo,LANGUAGE,LANGUAGE
SM274308,37,6.25,Gamo Gofa,ADM2,DISTRICT
SM231187,37.1381,6.5765,Gamo-Gofa-Dawro,LANGUAGE,LANGUAGE
SM231184,9.2484,10.9346,Gamo-Ningi,LANGUAGE,LANGUAGE
SM12894,136.2225,35.0252,Gamo-gun,ADM2,DISTRICT
SM1300,80,7.1333,Gampaha,ADM1:ADM2,DISTRICT
SM1301,9.51,47.2199,Gamprin,ADM1,DISTRICT
SM286349,27.8514,71.0411,Gamvik,ADM2,DISTRICT
SM231204,115.166,27.9896,Gan Chinese,LANGUAGE,LANGUAGE
SM231200,116.145,5.5758,Gana,LANGUAGE,LANGUAGE
SM461487,23.3889,-21.6523,Gana,ETHNICITY,ETHNICITY
SM231201,146.5,38.58,Ganai,LANGUAGE,LANGUAGE
SM231201,146.5,38.58,Ganai,LANGUAGE,LANGUAGE
SM231201,146.5,-38.58,Ganai,LANGUAGE,LANGUAGE
SM231201,146.5,-38.58,Ganai,LANGUAGE,LANGUAGE
SM231193,9.0974,9.932,Ganang,LANGUAGE,LANGUAGE
SM231207,32.1515,0.6688,Ganda,LANGUAGE,LANGUAGE
SM260401,14.7416,-13.086,Ganda,ADM2,DISTRICT
SM276895,75.2,34.2947,Ganderbal,ADM2,DISTRICT
SM276814,72.6498,23.2148,Gandhinagar,ADM2,DISTRICT
SM280631,127.1455,37.5527,Gandong,ADM2,DISTRICT
SM267406,-39.4475,-13.8156,Gandu,ADM2,DISTRICT
SM231211,127.976,-0.4262,Gane,LANGUAGE,LANGUAGE
SM253467,117.3,-33.16,Ganeang,ETHNICITY,ETHNICITY
SM253467,117.3,-33.16,Ganeang,ETHNICITY,ETHNICITY
SM253467,116.6983,-33.9842,Ganeang,ETHNICITY,ETHNICITY
SM253467,116.6983,-33.9842,Ganeang,ETHNICITY,ETHNICITY
SM291416,24.2677,44.4213,Ganeasa,ADM2,DISTRICT
SM290959,26.2992,44.4945,Ganeasa,ADM2,DISTRICT
SM291146,24.3418,46.3465,Ganesti,ADM2,DISTRICT
SM277169,73.61,29.49,Ganganagar (1998-),ADM2,DISTRICT
SM15082,94.1795,21.795,Gangaw District,ADM2,DISTRICT
SM280632,127.0202,37.6349,Gangbuk,ADM2,DISTRICT
SM231215,139.426,-17.5446,Ganggalida,LANGUAGE,LANGUAGE
SM280565,126.4263,37.7475,Ganghwa,ADM2,DISTRICT
SM290364,23.8616,43.8862,Gangiova,ADM2,DISTRICT
SM280603,126.7676,34.6179,Gangjin,ADM2,DISTRICT
SM231218,146.1305,-5.5794,Ganglau,LANGUAGE,LANGUAGE
SM280633,127.0628,37.4951,Gangnam,ADM2,DISTRICT
SM280693,128.8783,37.7519,Gangneung,ADM2,DISTRICT
SM231220,102.43,35.55,Gangou,LANGUAGE,LANGUAGE
SM280634,126.8162,37.5623,Gangseo,ADM2,DISTRICT
SM280537,128.933,35.1593,Gangseo,ADM2,DISTRICT
SM231214,94.1681,24.5113,Gangte,LANGUAGE,LANGUAGE
SM461973,19.73,-15.39,Ganguela,ETHNICITY,ETHNICITY
SM461973,19.62,-12.12,Ganguela,ETHNICITY,ETHNICITY
SM461973,18.2,-12.97,Ganguela,ETHNICITY,ETHNICITY
SM461973,17.56,-10.88,Ganguela,ETHNICITY,ETHNICITY
SM231216,149.416,-22.7637,Gangulu,LANGUAGE,LANGUAGE
SM253477,150.32,-23.1,Gangulu,ETHNICITY,ETHNICITY
SM253477,150.32,-23.1,Gangulu,ETHNICITY,ETHNICITY
SM253477,147.93,-23.91,Gangulu,ETHNICITY,ETHNICITY
SM253477,147.93,-23.91,Gangulu,ETHNICITY,ETHNICITY
SM253477,150.1514,-24.1861,Gangulu,ETHNICITY,ETHNICITY
SM253477,150.1514,-24.1861,Gangulu,ETHNICITY,ETHNICITY
SM1302,128.25,37.75,Gangwon-do,ADM1,DISTRICT
SM277109,84.5,19.5,Ganjam (1993-),ADM2,DISTRICT
SM269409,102.6591,34.5178,Gannan Tibetan,ADM2,DISTRICT
SM261229,143.8532,-35.7321,Gannawarra,ADM2,DISTRICT
SM1304,102.75,37.75,Gansu,ADM1,DISTRICT
SM231226,144.771,-5.2811,Gants,LANGUAGE,LANGUAGE
SM458433,149.011,-23.6554,Ganulu,ETHNICITY,ETHNICITY
SM284846,11.9457,8.3676,Ganye,ADM2,DISTRICT
SM231228,35.1373,10.2991,Ganza,LANGUAGE,LANGUAGE
SM269225,115.3482,25.7039,Ganzhou,ADM2,DISTRICT
SM231229,19.347,6.544,Ganzi,LANGUAGE,LANGUAGE
SM262189,-0.7667,12.2667,Ganzourgou,ADM1:ADM2,DISTRICT
SM231232,159.789,-8.3516,Gao,LANGUAGE,LANGUAGE
SM283344,0.4398,16.7224,Gao,ADM2,DISTRICT
SM4070,1.6,16.6,Gao Region (2012-),ADM1,DISTRICT
SM274976,-13.2,11.75,Gaoual,ADM1:ADM2,DISTRICT
SM231234,149.854,-9.7332,Gapapaiwa,LANGUAGE,LANGUAGE
SM280729,127.5152,37.8308,Gapyeong,ADM2,DISTRICT
SM292277,27.8209,46.3441,Gara Banca,ADM2,DISTRICT
SM262372,26.9167,43.4333,Gara Hitrino,ADM2,DISTRICT
SM270955,-84.6201,9.619,Garabito,ADM2,DISTRICT
SM253490,122.17,-18.56,Garadjeri,ETHNICITY,ETHNICITY
SM253490,122.17,-18.56,Garadjeri,ETHNICITY,ETHNICITY
SM253490,122.17,-18.56,Garadjeri,ETHNICITY,ETHNICITY
SM253490,122.17,-18.56,Garadjeri,ETHNICITY,ETHNICITY
SM253490,122.21,-17.97,Garadjeri,ETHNICITY,ETHNICITY
SM253490,122.21,-17.97,Garadjeri,ETHNICITY,ETHNICITY
SM253490,122.21,-17.97,Garadjeri,ETHNICITY,ETHNICITY
SM253490,122.21,-17.97,Garadjeri,ETHNICITY,ETHNICITY
SM253490,121.68,-18.94,Garadjeri,ETHNICITY,ETHNICITY
SM253490,121.68,-18.94,Garadjeri,ETHNICITY,ETHNICITY
SM253490,121.68,-18.94,Garadjeri,ETHNICITY,ETHNICITY
SM253490,121.68,-18.94,Garadjeri,ETHNICITY,ETHNICITY
SM253490,122.3226,-18.8174,Garadjeri,ETHNICITY,ETHNICITY
SM253490,122.3226,-18.8174,Garadjeri,ETHNICITY,ETHNICITY
SM253490,122.3226,-18.8174,Garadjeri,ETHNICITY,ETHNICITY
SM253490,122.3226,-18.8174,Garadjeri,ETHNICITY,ETHNICITY
SM270772,-73.316,5.0922,Garagoa,ADM2,DISTRICT
SM264989,-36.5077,-8.9308,Garanhuns,ADM2,DISTRICT
SM267090,-37.2527,-10.0573,Gararu,ADM2,DISTRICT
SM261031,-60.0833,-31,Garay,ADM2,DISTRICT
SM458414,149.1337,-23.3302,Garaynbal,ETHNICITY,ETHNICITY
SM17872,42.3591,3.206,Garbahaaray (excl. Burdhubo),ADM2,DISTRICT
SM290097,23.3705,46.8406,Garbau,ADM2,DISTRICT
SM291606,23.4352,47.1545,Garbou,ADM2,DISTRICT
SM289796,23.6869,45.8528,Garbova,ADM2,DISTRICT
SM290910,26.7696,44.7865,Garbovi,ADM2,DISTRICT
SM292278,27.2796,46.7514,Garceni,ADM2,DISTRICT
SM291334,26.3282,46.9922,Garcina,ADM2,DISTRICT
SM291417,24.6271,43.7734,Garcov,ADM2,DISTRICT
SM303674,-63.8831,10.9828,García,ADM2,DISTRICT
SM282159,-100.6198,25.8069,García,ADM2,DISTRICT
SM303759,-72.25,8.3333,García de Hevia,ADM2,DISTRICT
SM274452,4.2875,44.0179,Gard,ADM2,DISTRICT
SM289798,22.8152,46.4672,Garda De Sus,ADM2,DISTRICT
SM11610,-21.8569,64.0537,Gardabaer,ADM2,DISTRICT
SM11305,45.1,41.45,Gardabani,ADM2,DISTRICT
SM227422,45.084,41.459,Gardabani Bohtan Neo-Aramaic,LANGUAGE,LANGUAGE
SM11306,45,41.6167,Gardabnis Munitsip'alit'et'I (excl. Gardabani and Kalaki Rustavi),ADM2,DISTRICT
SM301706,-102.3354,41.6194,Garden,ADM2,DISTRICT
SM260167,69.2416,33.6013,Gardez,ADM2,DISTRICT
SM231246,16,39.4667,Gardiol,LANGUAGE,LANGUAGE
SM275951,16.9333,45.5667,Garešnica,ADM2,DISTRICT
SM303072,-117.5452,46.4316,Garfield,ADM2,DISTRICT
SM302921,-111.4431,37.8549,Garfield,ADM2,DISTRICT
SM302188,-97.7827,36.3791,Garfield,ADM2,DISTRICT
SM301707,-98.9914,41.9144,Garfield,ADM2,DISTRICT
SM301630,-106.9928,47.2777,Garfield,ADM2,DISTRICT
SM303334,-107.904,39.5993,Garfield,ADM2,DISTRICT
SM277033,80.2,19.8,Garhchiroli,ADM2,DISTRICT
SM276919,83.7102,24.0749,Garhwa,ADM2,DISTRICT
SM231248,78.7201,30.5061,Garhwali,LANGUAGE,LANGUAGE
SM253504,132.45,-11.43,Gari,ETHNICITY,ETHNICITY
SM253504,132.45,-11.43,Gari,ETHNICITY,ETHNICITY
SM253504,132.4184,-11.2458,Gari,ETHNICITY,ETHNICITY
SM253504,132.4184,-11.2458,Gari,ETHNICITY,ETHNICITY
SM253505,145.53,-5.36,Garia,ETHNICITY,ETHNICITY
SM277444,82.0588,20.6343,Gariaband,ADM2,DISTRICT
SM9927,-51.6568,-29.2368,Garibaldi (1996-),ADM2,DISTRICT
SM231252,-86.9091,15.3529,Garifuna,LANGUAGE,LANGUAGE
SM253506,-89,16,Garifuna,ETHNICITY,ETHNICITY
SM231249,132.182,-11.412,Garig-Ilgar,LANGUAGE,LANGUAGE
SM1307,40.041,-0.172,Garissa,ADM1:ADM2,DISTRICT
SM4003,24.3706,57.0178,Garkalne Municipality,ADM1,DISTRICT
SM13522,24.3815,57.0307,Garkalnes novads,ADM2,DISTRICT
SM284807,9.0605,12.4296,Garki,ADM2,DISTRICT
SM284858,8.8352,11.5729,Garko,ADM2,DISTRICT
SM302370,-93.1504,34.5767,Garland,ADM2,DISTRICT
SM12354,-77.7891,18.2781,Garlands,ADM2,DISTRICT
SM292109,26.7882,46.6625,Garlenii De Sus,ADM2,DISTRICT
SM290164,28.0946,44.7793,Garliciu,ADM2,DISTRICT
SM11769,56.2167,37.15,Garmeh,ADM2,DISTRICT
SM262296,23.85,41.7,Garmen,ADM2,DISTRICT
SM260002,64.2239,31.0257,Garmser,ADM2,DISTRICT
SM290018,21.7717,44.7552,Garnic,ADM2,DISTRICT
SM370498,5.9525,49.6167,Garnich,ADM1:ADM2:ADM3,DISTRICT
SM231258,90.462,25.2954,Garo,LANGUAGE,LANGUAGE
SM253507,90.42,24.87,Garo,ETHNICITY,ETHNICITY
SM253507,90.42,24.87,Garo,ETHNICITY,ETHNICITY
SM253507,91,26,Garo,ETHNICITY,ETHNICITY
SM253507,91,26,Garo,ETHNICITY,ETHNICITY
SM292344,27.2501,45.7981,Garoafa,ADM2,DISTRICT
SM295779,48.7864,8.4704,Garoowe,ADM2,DISTRICT
SM266427,-48.6145,-28.0274,Garopaba,ADM2,DISTRICT
SM14023,-8.9222,7.2144,Garr-Bain District,ADM2,DISTRICT
SM264168,-47.1172,-2.2152,Garrafão do Norte,ADM2,DISTRICT
SM17390,-60.9327,14.0042,Garrand,ADM2,DISTRICT
SM301043,-84.5376,37.6396,Garrard,ADM2,DISTRICT
SM461923,-7.9134,4.564,Garraway (excl. Nemia),ADM2,DISTRICT
SM231263,44.6862,2.2963,Garre,LANGUAGE,LANGUAGE
SM301216,-79.2739,39.5286,Garrett,ADM2,DISTRICT
SM15717,-66.5983,18.4583,Garrochales Barrio (Arecibo),ADM2,DISTRICT
SM15718,-66.5652,18.4589,Garrochales Barrio (Barceloneta),ADM2,DISTRICT
SM265678,-55.5156,-28.256,Garruchos,ADM2,DISTRICT
SM231241,137.7201,-17.4504,Garrwa,LANGUAGE,LANGUAGE
SM458797,137.2738,-16.8568,Garrwa^,ETHNICITY,ETHNICITY
SM13523,25.7795,56.0982,Garsenes pagasts,ADM2,DISTRICT
SM274947,-0.1833,10.85,Garu Tempane,ADM2,DISTRICT
SM284859,8.377,11.651,Garum Mallam,ADM2,DISTRICT
SM458455,152.6951,-27.353,Garumngar,ETHNICITY,ETHNICITY
SM231267,145.683,-4.9222,Garus,LANGUAGE,LANGUAGE
SM276757,107.7667,-7.3833,Garut,ADM2,DISTRICT
SM266428,-48.8708,-26.0605,Garuva,ADM2,DISTRICT
SM302189,-97.3093,34.7046,Garvin,ADM2,DISTRICT
SM288973,21.6162,51.8266,Garwolin,ADM2,DISTRICT
SM280967,-11.1667,6.8333,Garwula,ADM2,DISTRICT
SM280967,-11.1667,6.8333,Garwula,ADM2,DISTRICT
SM280967,-11.133,6.8152,Garwula,ADM2,DISTRICT
SM280967,-11.133,6.8152,Garwula,ADM2,DISTRICT
SM302877,-101.2984,33.1799,Garza,ADM2,DISTRICT
SM15719,-66.7462,18.1534,Garzas Barrio,ADM2,DISTRICT
SM269337,100.4055,31.0241,Garzê Tibetan,ADM2,DISTRICT
SM270081,-75.5781,2.1818,Garzón,ADM2,DISTRICT
SM266535,-49.7136,-22.2325,Garça,ADM2,DISTRICT
SM305585,18.1844,45.1818,Garčin,ADM2,DISTRICT
SM1308,89.7089,27.9131,Gasa,ADM1,DISTRICT
SM295163,30.1314,-1.8847,Gasabo,ADM1:ADM2,DISTRICT
SM231271,-0.1428,43.1811,Gascon,DIALECT,LANGUAGE
SM301553,-91.5079,38.4409,Gasconade,ADM2,DISTRICT
SM1309,37.5,15.25,Gash Barka,ADM1,DISTRICT
SM285252,11.389,7.4995,Gashaka,ADM2,DISTRICT
SM261997,29.9075,-2.8939,Gashikanwa,ADM2,DISTRICT
SM261985,30.1729,-2.7612,Gashoho,ADM2,DISTRICT
SM253513,46.05,25.11,Gasim,ETHNICITY,ETHNICITY
SM261986,30.2358,-2.8512,Gasorwe,ADM2,DISTRICT
SM266429,-48.9826,-26.924,Gaspar,ADM2,DISTRICT
SM271907,-70.1667,19.5833,Gaspar Hernández,ADM2,DISTRICT
SM10212,-65.4071,48.8755,Gaspesie-Iles-de-la-Madeleine,ADM2,DISTRICT
SM285253,10.5442,8.4727,Gassol,ADM2,DISTRICT
SM301960,-81.1803,35.2944,Gaston,ADM2,DISTRICT
SM261118,-68.6667,-42.8333,Gastre,ADM2,DISTRICT
SM10722,33.99,35.3358,Gastria,ADM2,DISTRICT
SM266536,-50.1924,-20.8063,Gastão Vidigal,ADM2,DISTRICT
SM231278,82.984,18.6076,Gata',LANGUAGE,LANGUAGE
SM262085,29.6774,-2.9923,Gatara,ADM2,DISTRICT
SM16894,30.1667,59.4167,Gatchinskiy Rayon,ADM2,DISTRICT
SM301961,-76.7005,36.4449,Gates,ADM2,DISTRICT
SM274630,-1.6667,54.9333,Gateshead,ADM1:ADM2,DISTRICT
SM15720,-66.3898,18.2607,Gato Barrio,ADM2,DISTRICT
SM295150,30.4554,-1.5919,Gatsibo,ADM1:ADM2,DISTRICT
SM290567,25.7108,43.74,Gaujani,ADM2,DISTRICT
SM13524,26.4223,57.5136,Gaujienas pagasts,ADM2,DISTRICT
SM253518,-81.96,31.54,Gaule,ETHNICITY,ETHNICITY
SM265679,-52.1041,-27.6169,Gaurama,ADM2,DISTRICT
SM286120,10.2215,61.2269,Gausdal,ADM2,DISTRICT
SM277289,77.5508,28.359,Gautam Buddha Nagar,ADM2,DISTRICT
SM1310,28.25,-26.0833,Gauteng,ADM1,DISTRICT
SM231287,13.8188,10.5603,Gavar,LANGUAGE,LANGUAGE
SM253527,-49.2,-4.77,Gaviaoes,ETHNICITY,ETHNICITY
SM253527,-47.67,-4.95,Gaviaoes,ETHNICITY,ETHNICITY
SM13525,21.3134,56.4694,Gaviezes pagasts,ADM2,DISTRICT
SM289299,-7.8953,39.4446,Gavião,ADM2,DISTRICT
SM267407,-39.7741,-11.4889,Gavião,ADM2,DISTRICT
SM231289,-61.6752,-10.7235,Gavião Do Jiparaná,LANGUAGE,LANGUAGE
SM266537,-48.469,-21.8088,Gavião Peixoto,ADM2,DISTRICT
SM291981,22.0247,45.6128,Gavojdia,ADM2,DISTRICT
SM295066,39.75,57.3333,Gavrilov-Yamskiy rayon,ADM2,DISTRICT
SM292949,40.0833,56.6667,Gavrilovo-Posadskiy rayon,ADM2,DISTRICT
SM253528,147.6,-6.59,Gawa,ETHNICITY,ETHNICITY
SM458159,150.3249,-28.6219,Gawambaraay,ETHNICITY,ETHNICITY
SM231295,71.5428,35.1982,Gawar-Bati,LANGUAGE,LANGUAGE
SM261693,138.7323,-34.6211,Gawler,ADM2,DISTRICT
SM233743,72.5738,35.5303,Gawri,LANGUAGE,LANGUAGE
SM284860,9.0285,11.7816,Gaya,ADM2,DISTRICT
SM277404,85,24.75,Gaya,ADM2,DISTRICT
SM17391,-61.0184,13.7864,Gayabois,ADM2,DISTRICT
SM260154,69.3993,32.9752,Gayan,ADM2,DISTRICT
SM231308,36.6721,6.1503,Gayil,LANGUAGE,LANGUAGE
SM458418,147.8977,-23.794,Gayiri,ETHNICITY,ETHNICITY
SM12355,-77.0021,18.3428,Gayle,ADM2,DISTRICT
SM294038,54,60.5,Gaynskiy rayon,ADM2,DISTRICT
SM231310,97.3456,4.3733,Gayo,LANGUAGE,LANGUAGE
SM253529,96.85,4.68,Gayo,ETHNICITY,ETHNICITY
SM276286,97.39,3.95,Gayo Lues,ADM2,DISTRICT
SM293960,58.3333,51.4167,Gayskiy rayon,ADM2,DISTRICT
SM9527,65.7211,33.5426,Gayti,ADM2,DISTRICT
SM1312,33,-23.5,Gaza,ADM1,DISTRICT
SM289736,34.4641,31.5107,Gaza (City),ADM1:ADM2,DISTRICT
SM1313,34.4559,31.2586,Gaza Strip,ADM1:ADM2,DISTRICT
SM1313,34.4559,31.2586,Gaza Strip,ADM1:ADM2,DISTRICT
SM1313,34.3333,31.4167,Gaza Strip,ADM1:ADM2,DISTRICT
SM1313,34.3333,31.4167,Gaza Strip,ADM1:ADM2,DISTRICT
SM289737,34.5358,31.5247,Gaza ash Shamaliyah,ADM1:ADM2,DISTRICT
SM15242,7.9444,13.4213,Gazaoua,ADM2,DISTRICT
SM288622,151.8667,-4.4833,Gazelle,ADM2,DISTRICT
SM1314,21.5167,42.05,Gazi Baba,ADM1:ADM2,DISTRICT
SM1315,37.3333,37.0833,Gaziantep (1997-),ADM1,DISTRICT
SM231312,49.4553,34.0357,Gazic,LANGUAGE,LANGUAGE
SM298533,27.1518,38.311,Gaziemir,ADM2,DISTRICT
SM295079,118.3667,51.55,Gazimuro-Zavodskiy rayon,ADM2,DISTRICT
SM298505,28.8968,41.0786,Gaziosmanpaşa,ADM2,DISTRICT
SM299020,32.3057,36.2801,Gazipaşa,ADM2,DISTRICT
SM262251,90.4724,24.2069,Gazipur,ADM2,DISTRICT
SM263161,-53.5226,-12.9937,Gaúcha do Norte,ADM2,DISTRICT
SM231324,6.905,9.2984,Gbagyi,LANGUAGE,LANGUAGE
SM285045,5.9861,9.2846,Gbako,ADM2,DISTRICT
SM370384,-8.0833,4.75,Gbalakpo,ADM2:ADM3,DISTRICT
SM231113,-5.5185,6.4086,Gban,LANGUAGE,LANGUAGE
SM250071,-8.93,8.94,Gbandi,ETHNICITY,ETHNICITY
SM250071,-8.93,8.94,Gbandi,ETHNICITY,ETHNICITY
SM250071,-10,8,Gbandi,ETHNICITY,ETHNICITY
SM250071,-10,8,Gbandi,ETHNICITY,ETHNICITY
SM231328,17.3887,5.2818,Gbanu,LANGUAGE,LANGUAGE
SM253531,20.93,3.16,Gbanu,ETHNICITY,ETHNICITY
SM231326,20.2609,4.6847,Gbanziri-Boraka,LANGUAGE,LANGUAGE
SM1317,-10.3167,7.3667,Gbapolu,ADM1,DISTRICT
SM370360,-8.2396,6.1102,Gbarbo,ADM2:ADM3,DISTRICT
SM231333,7.1076,8.4641,Gbari,LANGUAGE,LANGUAGE
SM370398,-9.5819,7.4514,Gbarlien,ADM2:ADM3,DISTRICT
SM14026,-7.5667,4.5833,Gbarlobo,ADM2,DISTRICT
SM280964,-10.6468,7.1769,Gbarma,ADM2,DISTRICT
SM231336,28.7911,2.9859,Gbati-ri,LANGUAGE,LANGUAGE
SM231347,25.5173,8.4339,Gbaya (Sudan),LANGUAGE,LANGUAGE
SM231347,25.5173,8.4339,Gbaya (Sudan),LANGUAGE,LANGUAGE
SM231347,25,8.4339,Gbaya (Sudan),LANGUAGE,LANGUAGE
SM231347,25,8.4339,Gbaya (Sudan),LANGUAGE,LANGUAGE
SM231346,17.645,6.619,Gbaya-Bossangoa,LANGUAGE,LANGUAGE
SM231345,16.4643,5.9879,Gbaya-Bozoum,LANGUAGE,LANGUAGE
SM231340,14.6069,4.8004,Gbaya-Mbodomo,LANGUAGE,LANGUAGE
SM250249,29.87,7.01,Gbaya-Ndogo,ETHNICITY,ETHNICITY
SM231337,21.9707,5.5738,Gbayi,LANGUAGE,LANGUAGE
SM14027,-8.4167,6.1667,Gbazohn,ADM2,DISTRICT
SM14028,-8.039,5.363,Gbeapo District (geonames),ADM2,DISTRICT
SM461918,-8.4843,7.3659,Gbehlageh (Geonames),ADM2,DISTRICT
SM370180,-8.6856,7.2233,Gbein,ADM2:ADM3,DISTRICT
SM269467,-5.2851,7.7027,Gbeke,ADM2,DISTRICT
SM231359,2.0269,6.5591,Gbesi Gbe,LANGUAGE,LANGUAGE
SM14031,-8.3333,4.7,Gbeta Klepo,ADM2,DISTRICT
SM14032,-8.9746,6.1935,Gbi and Doru,ADM2,DISTRICT
SM231366,-8.9346,5.8702,Gbii,LANGUAGE,LANGUAGE
SM231368,-2.8112,8.0209,Gbin,LANGUAGE,LANGUAGE
SM259910,8,10,Gbiri,ETHNICITY,ETHNICITY
SM231369,8.4316,10.3923,Gbiri-Niragu,LANGUAGE,LANGUAGE
SM14033,-8.1667,6.1667,Gbobo,ADM2,DISTRICT
SM14034,-8.7924,6.0171,Gboe-Ploe District,ADM2,DISTRICT
SM285356,8.9017,7.3162,Gboko,ADM2,DISTRICT
SM231374,-7.667,4.7541,Gboloo Grebo,LANGUAGE,LANGUAGE
SM370198,-8.7081,7.113,Gbor,ADM2:ADM3,DISTRICT
SM284718,5.4989,7.6065,Gboyin,ADM2,DISTRICT
SM269475,-6.0937,4.9571,Gbôkle,ADM2,DISTRICT
SM288711,18.5816,54.2249,Gdańsk,ADM2,DISTRICT
SM288710,18.6898,54.3611,Gdańsk (City),ADM2,DISTRICT
SM294137,28,58.75,Gdovskiy rayon,ADM2,DISTRICT
SM288712,18.4637,54.5036,Gdynia,ADM2,DISTRICT
SM231397,107.823,26.8542,Ge,LANGUAGE,LANGUAGE
SM250252,1.04,6.23,Ge,ETHNICITY,ETHNICITY
SM290098,24.0626,46.8814,Geaca,ADM2,DISTRICT
SM300913,-96.7525,39.0024,Geary,ADM2,DISTRICT
SM302103,-81.1787,41.4995,Geauga,ADM2,DISTRICT
SM253532,150.42,-33.36,Geawegal,ETHNICITY,ETHNICITY
SM253532,150.42,-33.36,Geawegal,ETHNICITY,ETHNICITY
SM458190,151.2117,-32.5198,Geawegal,ETHNICITY,ETHNICITY
SM253532,150.6794,-34.5658,Geawegal,ETHNICITY,ETHNICITY
SM253532,150.6794,-34.5658,Geawegal,ETHNICITY,ETHNICITY
SM231388,96.9492,19.4991,Geba Karen,LANGUAGE,LANGUAGE
SM231389,129.424,-0.0788,Gebe,LANGUAGE,LANGUAGE
SM253533,141.99,-6.76,Gebusi,ETHNICITY,ETHNICITY
SM18047,29.5506,40.8976,Gebze (2008-),ADM2,DISTRICT
SM14036,-7.9167,4.8,Gedabo,ADM2,DISTRICT
SM231392,145.781,-5.1818,Gedaged,LANGUAGE,LANGUAGE
SM17901,35,15,"Gedaref (excl. Al Fushqa, incl. Seteet)",ADM2,DISTRICT
SM250270,13,10,Gede,ETHNICITY,ETHNICITY
SM231395,38.2395,6.1847,Gedeo,LANGUAGE,LANGUAGE
SM274309,38.2772,6.1273,Gedeo,ADM2,DISTRICT
SM274309,38.2772,6.1273,Gedeo,ADM2,DISTRICT
SM274309,38.2521,6.1413,Gedeo,ADM2,DISTRICT
SM274309,38.2521,6.1413,Gedeo,ADM2,DISTRICT
SM250251,38.51,6.16,Gedeo,ETHNICITY,ETHNICITY
SM250251,38.51,6.16,Gedeo,ETHNICITY,ETHNICITY
SM250251,38,6,Gedeo,ETHNICITY,ETHNICITY
SM250251,38,6,Gedeo,ETHNICITY,ETHNICITY
SM14037,-7.6167,4.5667,Gedetarbo,ADM2,DISTRICT
SM298707,29.4176,39.0527,Gediz,ADM2,DISTRICT
SM1318,42,3,Gedo,ADM1,DISTRICT
SM285916,4.8784,51.6963,Geertruidenberg,ADM2,DISTRICT
SM231399,40.6821,10.1342,Geez,LANGUAGE,LANGUAGE
SM1319,45.25,40.0833,Gegharkunik,ADM1:ADM2,DISTRICT
SM1319,45.25,40.0833,Gegharkunik,ADM1:ADM2,DISTRICT
SM1319,45.4167,40.25,Gegharkunik,ADM1:ADM2,DISTRICT
SM1319,45.4167,40.25,Gegharkunik,ADM1:ADM2,DISTRICT
SM231401,-43,-6.56,Geico,LANGUAGE,LANGUAGE
SM285271,12.0673,12.6545,Geidam,ADM2,DISTRICT
SM299218,32.2098,-2.8095,Geita,ADM2,DISTRICT
SM1320,31.9,-3.2,Geita,ADM1,DISTRICT
SM231403,9.6497,10.3478,Geji,FAMILY,LANGUAGE
SM231404,96.5308,19.6741,Geko Karen,LANGUAGE,LANGUAGE
SM231408,160.186,-9.0459,Gela,LANGUAGE,LANGUAGE
SM253534,119.91,-30.57,Gelamai,ETHNICITY,ETHNICITY
SM253534,119.91,-30.57,Gelamai,ETHNICITY,ETHNICITY
SM253534,119.013,-30.2637,Gelamai,ETHNICITY,ETHNICITY
SM253534,119.013,-30.2637,Gelamai,ETHNICITY,ETHNICITY
SM260299,67.6546,32.7134,Gelan,ADM2,DISTRICT
SM231406,106.235,26.1545,Gelao,LANGUAGE,LANGUAGE
SM231409,107.2395,26.5746,Gelao Mulao,LANGUAGE,LANGUAGE
SM1321,5.8333,52,Gelderland,ADM1,DISTRICT
SM298478,31.0241,38.132,Gelendost,ADM2,DISTRICT
SM298272,26.6444,40.4557,Gelibolu,ADM2,DISTRICT
SM296134,20.9339,48.8521,Gelnica,ADM2,DISTRICT
SM300559,-116.3972,44.0617,Gem,ADM2,DISTRICT
SM231415,20.6611,8.4836,Geme,LANGUAGE,LANGUAGE
SM15117,7.0422,53.1686,Gemeente  Oldambt,ADM2,DISTRICT
SM15118,5.3006,51.6827,Gemeente 's-Hertogenbosch (2015-),ADM2,DISTRICT
SM15119,6.5657,51.9242,Gemeente Aalten (2005-),ADM2,DISTRICT
SM15120,4.744,52.6289,Gemeente Alkmaar (2015-),ADM2,DISTRICT
SM15121,4.6889,52.1297,Gemeente Alphen aan den Rijn (2014-),ADM2,DISTRICT
SM15122,5.9297,51.7841,Gemeente Berg en Dal,ADM2,DISTRICT
SM15123,4.5897,52.3623,Gemeente Bloemendaal (2006-),ADM2,DISTRICT
SM15124,6.3038,52.044,Gemeente Bronckhorst,ADM2,DISTRICT
SM15125,4.9012,52.2152,Gemeente De Ronde Venen (2011-),ADM2,DISTRICT
SM15126,6.238,52.2693,Gemeente Deventer (2005-),ADM2,DISTRICT
SM15127,6.2796,51.9622,Gemeente Doetinchem (2005-),ADM2,DISTRICT
SM15128,5.1657,52.6645,Gemeente Drechterland (2006-),ADM2,DISTRICT
SM15129,5.0592,52.5056,Gemeente Edam-Volendam (2016-),ADM2,DISTRICT
SM15130,5.5993,51.4337,Gemeente Geldrop-Mierlo,ADM2,DISTRICT
SM15131,4.1126,51.7535,Gemeente Goeree-Overflakkee,ADM2,DISTRICT
SM15132,6.5648,53.2237,Gemeente Groningen (2019-),ADM2,DISTRICT
SM15133,4.6842,52.3062,Gemeente Haarlemmermeer (2019-),ADM2,DISTRICT
SM15134,6.0441,52.9422,Gemeente Heerenveen (2014-),ADM2,DISTRICT
SM15135,4.9071,52.8488,Gemeente Hollands Kroon,ADM2,DISTRICT
SM15136,6.1025,51.4593,Gemeente Horst aan de Maas (2010-),ADM2,DISTRICT
SM15137,4.6552,52.1939,Gemeente Kaag en Braassem,ADM2,DISTRICT
SM15138,4.4213,52.1903,Gemeente Katwijk (2006-),ADM2,DISTRICT
SM15139,4.9491,52.6416,Gemeente Koggenland,ADM2,DISTRICT
SM15140,4.5109,52.0039,Gemeente Lansingerland,ADM2,DISTRICT
SM15142,5.8111,53.186,Gemeente Leeuwarden (2018-),ADM2,DISTRICT
SM15143,5.8982,51.2437,Gemeente Leudal,ADM2,DISTRICT
SM15144,6.337,52.1848,Gemeente Lochem (2005-),ADM2,DISTRICT
SM15145,5.8771,51.1543,Gemeente Maasgouw,ADM2,DISTRICT
SM15146,5.122,52.725,Gemeente Medemblik (2011-),ADM2,DISTRICT
SM15147,4.3053,51.9653,Gemeente Midden-Delfland,ADM2,DISTRICT
SM15148,6.2133,51.9193,Gemeente Montferland,ADM2,DISTRICT
SM15149,4.7801,52.172,Gemeente Nieuwkoop (2007-),ADM2,DISTRICT
SM15150,5.5811,51.781,Gemeente Oss (2015-),ADM2,DISTRICT
SM15151,5.9726,51.3235,Gemeente Peel en Maas,ADM2,DISTRICT
SM15152,6.0354,51.1349,Gemeente Roerdalen (2007-),ADM2,DISTRICT
SM15153,6.009,51.2,Gemeente Roermond (2007-),ADM2,DISTRICT
SM15154,4.2878,51.8825,Gemeente Rotterdam (2010-),ADM2,DISTRICT
SM15155,4.7997,52.7882,Gemeente Schagen (2013-),ADM2,DISTRICT
SM15156,4.5065,52.2156,Gemeente Teylingen,ADM2,DISTRICT
SM15157,5.3574,52.0349,Gemeente Utrechtse Heuvelrug,ADM2,DISTRICT
SM15158,6.1604,51.3928,Gemeente Venlo (2010-),ADM2,DISTRICT
SM15159,4.2273,51.9967,Gemeente Westland,ADM2,DISTRICT
SM15160,6.0773,51.9538,Gemeente Zevenaar (2018-),ADM2,DISTRICT
SM15161,4.6105,52.0157,Gemeente Zuidplas,ADM2,DISTRICT
SM15162,6.2313,52.1315,Gemeente Zutphen (2005-),ADM2,DISTRICT
SM292674,27.6564,45.2893,Gemenele,ADM2,DISTRICT
SM298934,36.0778,39.194,Gemerek,ADM2,DISTRICT
SM285918,5.728,51.5255,Gemert-Bakel,ADM2,DISTRICT
SM265233,-41.388,-7.166,Geminiano,ADM2,DISTRICT
SM298254,29.1494,40.4351,Gemlik,ADM2,DISTRICT
SM268546,21.0438,-25.199,Gemsbok,ADM2,DISTRICT
SM231428,1.6773,6.2597,Gen,LANGUAGE,LANGUAGE
SM10723,33.6917,35.211,Genagra,ADM2,DISTRICT
SM283041,-102.5203,22.5012,Genaro Codina,ADM2,DISTRICT
SM277656,50.647,29.6457,Genaveh,ADM2,DISTRICT
SM231421,145.177,-5.6753,Gende,LANGUAGE,LANGUAGE
SM253536,145.43,-6.13,Gende,ETHNICITY,ETHNICITY
SM260760,-58.0833,-38.1667,General Alvarado,ADM2,DISTRICT
SM261171,-56.5,-28.8333,General Alvear,ADM2,DISTRICT
SM260890,-67.3333,-35.1667,General Alvear,ADM2,DISTRICT
SM260761,-60.0833,-36,General Alvear,ADM2,DISTRICT
SM260878,-66.6667,-30.25,General Angel Vicente Peñaloza,ADM2,DISTRICT
SM273432,-79.1985,-2.155,General Antonio Elizalde,ADM2,DISTRICT
SM260762,-61.25,-34.25,General Arenales,ADM2,DISTRICT
SM289566,-56.3759,-26.9052,General Artigas,ADM2,DISTRICT
SM260879,-66,-30.5833,General Belgrano,ADM2,DISTRICT
SM260763,-58.6702,-35.8487,General Belgrano,ADM2,DISTRICT
SM261103,-61,-26.9167,General Belgrano,ADM2,DISTRICT
SM289630,-56.8861,-25.7601,General Bernardino Caballero,ADM2,DISTRICT
SM290756,22.888,45.6116,General Berthelot,ADM2,DISTRICT
SM282160,-98.8972,25.8077,General Bravo,ADM2,DISTRICT
SM281605,-100.1011,18.4307,General Canuto A. Neri,ADM2,DISTRICT
SM264473,-51.4008,-26.4375,General Carneiro,ADM2,DISTRICT
SM263162,-53.2979,-15.5154,General Carneiro,ADM2,DISTRICT
SM269068,-72.3148,-46.6344,General Carrera,ADM2,DISTRICT
SM283279,-101.5058,25.4511,General Cepeda,ADM2,DISTRICT
SM265680,-51.9472,-29.8392,General Câmara,ADM2,DISTRICT
SM289567,-56.5522,-27.0595,General Delgado,ADM2,DISTRICT
SM261104,-59.3333,-27.1667,General Donovan,ADM2,DISTRICT
SM289648,-56.7933,-24.3834,General Elizardo Aquino,ADM2,DISTRICT
SM283042,-102.7685,23.0183,General Enrique Estrada,ADM2,DISTRICT
SM282161,-100.358,25.8249,General Escobedo,ADM2,DISTRICT
SM289559,-56.1976,-25.9416,General Eugenio A. Garay,ADM2,DISTRICT
SM282408,-97.6828,19.0079,General Felipe Angeles,ADM2,DISTRICT
SM283043,-102.9365,24.1022,General Francisco R. Murguía,ADM2,DISTRICT
SM260764,-58,-36.75,General Guido,ADM2,DISTRICT
SM260964,-64.9439,-24.7946,General Güemes,ADM2,DISTRICT
SM261105,-61.4167,-25.1667,General Güemes,ADM2,DISTRICT
SM281606,-100.0286,17.743,General Heliodoro Castillo,ADM2,DISTRICT
SM289704,-56.0824,-26.0408,General Higinio Morínigo,ADM2,DISTRICT
SM289649,-56.2082,-24.0052,General Isidoro Resquín,ADM2,DISTRICT
SM283044,-103.0547,22.001,General Joaquin Amaro,ADM2,DISTRICT
SM15336,-58.8753,-24.3121,General Jose Maria Bruguez,ADM2,DISTRICT
SM289612,-58.344,-27.1679,General José Eduvigis Díaz,ADM2,DISTRICT
SM260965,-63.75,-22.8333,General José de San Martín,ADM2,DISTRICT
SM260863,-66.615,-30.8,General Juan Facundo Quiroga,ADM2,DISTRICT
SM260765,-57.25,-37.25,General Juan Madariaga,ADM2,DISTRICT
SM260766,-61.25,-37.5,General La Madrid,ADM2,DISTRICT
SM260864,-68.6667,-28.8333,General Lamadrid,ADM2,DISTRICT
SM260767,-59,-34.8333,General Las Heras,ADM2,DISTRICT
SM260768,-57,-36.6667,General Lavalle,ADM2,DISTRICT
SM261032,-61.8333,-33.8333,General López,ADM2,DISTRICT
SM260912,-53.9167,-26.1667,General Manuel Belgrano,ADM2,DISTRICT
SM267091,-36.983,-10.6941,General Maynard,ADM2,DISTRICT
SM261033,-59.75,-28.75,General Obligado,ADM2,DISTRICT
SM260865,-66.25,-31,General Ocampo,ADM2,DISTRICT
SM261172,-57.8333,-27.75,General Paz,ADM2,DISTRICT
SM260770,-58.4167,-35.5,General Paz,ADM2,DISTRICT
SM260989,-65.5833,-33.8333,General Pedernera,ADM2,DISTRICT
SM260771,-62.0016,-34.7178,General Pinto,ADM2,DISTRICT
SM14283,-112.7122,31.7438,General Plutarco Elias Calles,ADM2,DISTRICT
SM260772,-57.75,-38,General Pueyrredón,ADM2,DISTRICT
SM283045,-102.0962,22.6565,General Pánfilo Natera,ADM2,DISTRICT
SM260940,-67.5,-38.5,General Roca,ADM2,DISTRICT
SM261143,-64.25,-34.6667,General Roca,ADM2,DISTRICT
SM260773,-59,-34.6667,General Rodríguez,ADM2,DISTRICT
SM266538,-50.4317,-20.6195,General Salgado,ADM2,DISTRICT
SM267923,-39.4445,-4.0502,General Sampaio,ADM2,DISTRICT
SM260866,-66.0833,-31.5,General San Martín,ADM2,DISTRICT
SM260774,-58.541,-34.5733,General San Martín,ADM2,DISTRICT
SM261144,-63.3333,-32.5,General San Martín,ADM2,DISTRICT
SM281531,-103.2639,24.902,General Simón Bolívar,ADM2,DISTRICT
SM303715,-65.0154,9.1523,General Sir Arthur McGregor,ADM2,DISTRICT
SM286787,-70.6667,-16.5,General Sánchez Cerro,ADM2,DISTRICT
SM261038,-62.5,-28.5833,General Taboada,ADM2,DISTRICT
SM282163,-99.4844,25.2727,General Terán,ADM2,DISTRICT
SM262505,28.0833,43.7333,General Toshevo,ADM2,DISTRICT
SM282164,-99.4623,26.2042,General Treviño,ADM2,DISTRICT
SM283227,-106.3414,28.3445,General Trias,ADM2,DISTRICT
SM260776,-61,-35,General Viamonte,ADM2,DISTRICT
SM260777,-63,-34.75,General Villegas,ADM2,DISTRICT
SM282165,-99.7224,23.9353,General Zaragoza,ADM2,DISTRICT
SM282166,-100.1365,25.8896,General Zuazua,ADM2,DISTRICT
SM305701,15.3755,45.3419,Generalski Stol,ADM2,DISTRICT
SM301877,-78.1937,43.0009,Genesee,ADM2,DISTRICT
SM301268,-83.7067,43.0217,Genesee,ADM2,DISTRICT
SM300270,-85.839,31.095,Geneva,ADM2,DISTRICT
SM231426,11.9527,8.8956,Gengle,LANGUAGE,LANGUAGE
SM235341,12.1295,8.9197,Gengle-Kugama,LANGUAGE,LANGUAGE
SM279144,129.8654,33.4786,Genkai,ADM2,DISTRICT
SM285900,5.9845,51.7006,Gennep,ADM2,DISTRICT
SM277965,9.0667,44.5,Genova,ADM2,DISTRICT
SM17392,-60.9888,13.7682,Gentil,ADM2,DISTRICT
SM265681,-52.0493,-28.388,Gentil,ADM2,DISTRICT
SM267408,-42.5483,-11.3628,Gentio do Ouro,ADM2,DISTRICT
SM271663,12.55,55.75,Gentofte,ADM2,DISTRICT
SM301554,-94.4099,40.2121,Gentry,ADM2,DISTRICT
SM298202,40.5664,38.7597,Genç,ADM2,DISTRICT
SM269063,6.1274,46.208,Genève,ADM2,DISTRICT
SM1322,6.1104,46.1967,Genève,ADM1,DISTRICT
SM290757,23.2002,45.9484,Geoagiu,ADM2,DISTRICT
SM280555,127.9015,35.7303,Geochang,ADM2,DISTRICT
SM280556,128.6667,34.9,Geoje,ADM2,DISTRICT
SM301438,-88.644,30.8626,George,ADM2,DISTRICT
SM17393,-60.9867,14.005,George Charles Boulevard,ADM2,DISTRICT
SM4221,-63.0652,18.1994,George Hill,ADM1,DISTRICT
SM261757,147.0173,-41.1198,George Town,ADM2,DISTRICT
SM1323,-81.3698,19.2891,George Town,ADM1,DISTRICT
SM12356,-77.4808,18.0317,George's Valley,ADM2,DISTRICT
SM12357,-78.1312,18.2765,Georges Plain,ADM2,DISTRICT
SM10024,151.0901,-33.9727,Georges River,ADM2,DISTRICT
SM302446,-79.2993,33.4128,Georgetown,ADM2,DISTRICT
SM1324,-83.5002,32.7504,Georgia,ADM1,DISTRICT
SM240733,43.7861,41.8504,Georgian,LANGUAGE,LANGUAGE
SM253540,44.9,42.58,Georgian,ETHNICITY,ETHNICITY
SM253540,44.9,42.58,Georgian,ETHNICITY,ETHNICITY
SM253540,44.9,42.58,Georgian,ETHNICITY,ETHNICITY
SM253540,44.9,42.58,Georgian,ETHNICITY,ETHNICITY
SM253540,45,42,Georgian,ETHNICITY,ETHNICITY
SM253540,45,42,Georgian,ETHNICITY,ETHNICITY
SM253540,45,42,Georgian,ETHNICITY,ETHNICITY
SM253540,45,42,Georgian,ETHNICITY,ETHNICITY
SM253540,41.85,42.5,Georgian,ETHNICITY,ETHNICITY
SM253540,41.85,42.5,Georgian,ETHNICITY,ETHNICITY
SM253540,41.85,42.5,Georgian,ETHNICITY,ETHNICITY
SM253540,41.85,42.5,Georgian,ETHNICITY,ETHNICITY
SM253540,42.7,42.25,Georgian,ETHNICITY,ETHNICITY
SM253540,42.7,42.25,Georgian,ETHNICITY,ETHNICITY
SM253540,42.7,42.25,Georgian,ETHNICITY,ETHNICITY
SM253540,42.7,42.25,Georgian,ETHNICITY,ETHNICITY
SM454407,-0.167,51.5,Georgian Britain,ETHNICITY,ETHNICITY
SM231432,44.8134,41.7278,Georgian Sign Language,LANGUAGE,LANGUAGE
SM11734,43.5,44.25,Georgiyevskiy Rayon (incl. Georgiyevsk),ADM2,DISTRICT
SM262308,23,43.35,Georgy Damyanovo,ADM2,DISTRICT
SM231433,102.5,25.7,Gepo,LANGUAGE,LANGUAGE
SM231436,10.12,10.4331,Gera,LANGUAGE,LANGUAGE
SM10724,32.7882,35.0159,Gerakies,ADM2,DISTRICT
SM10725,33.9188,35.3628,Gerani,ADM2,DISTRICT
SM10726,32.9955,34.8101,Gerasa,ADM2,DISTRICT
SM298185,41.3328,37.5914,Gercüş,ADM2,DISTRICT
SM298217,32.3126,40.7136,Gerede,ADM2,DISTRICT
SM292824,47,42.5,Gergebil'skiy rayon,ADM2,DISTRICT
SM298227,39.0328,38.0286,Gerger,ADM2,DISTRICT
SM10727,33.4287,35.1071,Geri,ADM2,DISTRICT
SM245523,12.4676,48.649,German,LANGUAGE,LANGUAGE
SM276789,-4.6291,54.2232,German,ADM1:ADM2,DISTRICT
SM250881,9.76,51.39,German,ETHNICITY,ETHNICITY
SM250881,9.76,51.39,German,ETHNICITY,ETHNICITY
SM250881,9.76,51.39,German,ETHNICITY,ETHNICITY
SM250881,14,53,German,ETHNICITY,ETHNICITY
SM250881,14,53,German,ETHNICITY,ETHNICITY
SM250881,14,53,German,ETHNICITY,ETHNICITY
SM250881,12.373,51.34,German,ETHNICITY,ETHNICITY
SM250881,12.373,51.34,German,ETHNICITY,ETHNICITY
SM250881,12.373,51.34,German,ETHNICITY,ETHNICITY
SM9832,-63.1909,-17.7732,German Busch,ADM2,DISTRICT
SM240091,8.36,52.9713,German Northern Low Saxon,LANGUAGE,LANGUAGE
SM231444,7.3048,50.8154,German Sign Language,LANGUAGE,LANGUAGE
SM10728,33.0825,34.7221,Germasogeia,ADM2,DISTRICT
SM298156,27.6105,37.8805,Germencik,ADM2,DISTRICT
SM277574,47.95,39,Germi,ADM2,DISTRICT
SM262709,-65.9274,-17.5861,Germán Jordán,ADM2,DISTRICT
SM279809,137.25,35.75,Gero,ADM2,DISTRICT
SM10729,33.2531,35.1953,Gerolakkos,ADM2,DISTRICT
SM10730,32.4484,34.7609,Geroskipou Municipality,ADM2,DISTRICT
SM10731,32.7355,34.8154,Gerovasa,ADM2,DISTRICT
SM274453,0.4542,43.7173,Gers,ADM2,DISTRICT
SM268949,8.5168,47.008,Gersau,ADM2,DISTRICT
SM305131,29.9295,-26.671,Gert Sibande,ADM2,DISTRICT
SM231449,9.7848,10.4775,Geruma,LANGUAGE,LANGUAGE
SM298911,35.1904,41.81,Gerze,ADM2,DISTRICT
SM231452,130.701,-3.8016,Geser-Gorom,LANGUAGE,LANGUAGE
SM1325,19.8333,60.4167,Geta,ADM1:ADM2,DISTRICT
SM17394,-61.0074,13.7819,Getrine,ADM2,DISTRICT
SM266539,-50.0623,-21.7634,Getulina,ADM2,DISTRICT
SM265682,-52.1653,-27.8741,Getúlio Vargas,ADM2,DISTRICT
SM280635,126.8911,37.4749,Geum-cheon,ADM2,DISTRICT
SM280538,129.0901,35.2586,Geumjeong,ADM2,DISTRICT
SM280665,127.4806,36.1338,Geumsan,ADM2,DISTRICT
SM299001,43.1053,38.2939,Gevaş,ADM2,DISTRICT
SM1326,22.4,41.2,Gevgelija,ADM1,DISTRICT
SM231457,13.5809,9.3751,Gey,LANGUAGE,LANGUAGE
SM458158,152.1836,-28.1835,Geynyan,ETHNICITY,ETHNICITY
SM298842,30.2965,40.517,Geyve,ADM2,DISTRICT
SM284861,8.7374,12.0513,Gezawa,ADM2,DISTRICT
SM17902,33,14.5,Gezirah (excl. El Meilig  and Al Gura),ADM2,DISTRICT
SM244752,105.1,26.55,Gha-mu,LANGUAGE,LANGUAGE
SM231459,10.0165,30.1168,Ghadames,LANGUAGE,LANGUAGE
SM273984,38.7842,15.2494,Ghala Nefhi,ADM2,DISTRICT
SM253546,69.49,30.02,Ghalji,ETHNICITY,ETHNICITY
SM253546,69.49,30.02,Ghalji,ETHNICITY,ETHNICITY
SM253546,68,33,Ghalji,ETHNICITY,ETHNICITY
SM253546,68,33,Ghalji,ETHNICITY,ETHNICITY
SM253547,43.02,19.84,Ghamid,ETHNICITY,ETHNICITY
SM304929,43.3161,16.983,Ghamr,ADM2,DISTRICT
SM231466,-0.2836,5.7932,Ghanaian Pidgin English,LANGUAGE,LANGUAGE
SM231464,-0.3027,5.5523,Ghanaian Sign Language,LANGUAGE,LANGUAGE
SM231467,83.807,28.377,Ghandruk Sign Language,LANGUAGE,LANGUAGE
SM298073,10.0575,33.9432,Ghannouch,ADM2,DISTRICT
SM231465,156.552,-8.0204,Ghanongga,LANGUAGE,LANGUAGE
SM268544,22.5995,-22.2941,Ghanzi,ADM2,DISTRICT
SM1327,22,-22,Ghanzi,ADM1,DISTRICT
SM298063,10.104,37.1709,Ghar El Melh,ADM2,DISTRICT
SM231471,3.6667,32.4833,Ghardaia Sign Language,LANGUAGE,LANGUAGE
SM1329,3.1667,31.0833,Ghardaïa,ADM1,DISTRICT
SM298094,8.3895,36.5066,Ghardimaou,ADM2,DISTRICT
SM231470,159.917,-9.4791,Ghari,LANGUAGE,LANGUAGE
SM1330,10.5833,25.9167,Ghat,ADM1,DISTRICT
SM231474,149.84,-10.011,Ghayavi,LANGUAGE,LANGUAGE
SM304808,49.0245,14.8778,Ghayl Ba Wazir,ADM2,DISTRICT
SM304809,49.2854,15.3896,Ghayl Bin Yamin,ADM2,DISTRICT
SM298064,9.5105,37.0787,Ghazala,ADM2,DISTRICT
SM457516,-1.75,35,Ghazaouet District,ADM2,DISTRICT
SM9528,71.3307,35.2338,Ghaziabad,ADM2,DISTRICT
SM277291,77.4582,28.6954,Ghaziabad (2011-),ADM2,DISTRICT
SM277292,83.5,25.5,Ghazipur,ADM2,DISTRICT
SM260300,68.4367,33.5342,Ghazni,ADM2,DISTRICT
SM1332,67.75,33.3333,Ghazni,ADM1,DISTRICT
SM295204,27.7373,12.5342,Ghebeish,ADM2,DISTRICT
SM231476,21.3837,42.317,Gheg Albanian,LANGUAGE,LANGUAGE
SM273988,40.1623,14.7463,Ghelaelo',ADM2,DISTRICT
SM290759,22.7741,45.7181,Ghelari,ADM2,DISTRICT
SM274026,38.7958,15.7926,Gheleb,ADM2,DISTRICT
SM290214,26.2207,45.9349,Ghelinta,ADM2,DISTRICT
SM291147,24.5069,46.4607,Gheorghe Doja,ADM2,DISTRICT
SM290911,27.1839,44.6133,Gheorghe Doja,ADM2,DISTRICT
SM290912,27.4582,44.6328,Gheorghe Lazar,ADM2,DISTRICT
SM290692,25.5992,46.7227,Gheorgheni,ADM2,DISTRICT
SM231477,68.368,25.383,Ghera,LANGUAGE,LANGUAGE
SM291335,26.8201,47.0362,Gheraesti,ADM2,DISTRICT
SM289875,26.8007,45.0244,Gheraseni,ADM2,DISTRICT
SM290365,23.9248,44.3857,Ghercesti,ADM2,DISTRICT
SM289876,27.1918,45.2892,Ghergheasa,ADM2,DISTRICT
SM292280,27.524,46.5217,Gherghesti,ADM2,DISTRICT
SM290268,25.3346,44.812,Gherghitesti,ADM2,DISTRICT
SM290099,23.8813,47.0143,Gherla,ADM2,DISTRICT
SM292281,28.1209,46.7961,Ghermanesti,ADM2,DISTRICT
SM291664,23.2217,47.9372,Gherta Mica,ADM2,DISTRICT
SM273989,39.0959,15.4445,Ghidae,ADM2,DISTRICT
SM290215,25.864,45.8996,Ghidfalau,ADM2,DISTRICT
SM290443,27.4921,46.0336,Ghidigeni,ADM2,DISTRICT
SM292627,25.5084,45.664,Ghimbav,ADM2,DISTRICT
SM290568,25.7836,44.1923,Ghimpati,ADM2,DISTRICT
SM290502,21.5965,46.1532,Ghioroc,ADM2,DISTRICT
SM292189,23.8474,44.6787,Ghioroiu,ADM2,DISTRICT
SM290269,25.6841,44.9219,Ghirdoveni,ADM2,DISTRICT
SM291982,21.3025,45.7849,Ghiroda,ADM2,DISTRICT
SM291983,21.7505,45.8264,Ghizela,ADM2,DISTRICT
SM5142,73.75,36.1667,Ghizer (pre-2019),ADM2:ADM3,DISTRICT
SM454488,11,4.3,Ghomala,ETHNICITY,ETHNICITY
SM231487,-4.6696,34.7979,Ghomara,LANGUAGE,LANGUAGE
SM298004,10.2547,33.0345,Ghomrassen,ADM2,DISTRICT
SM231483,10.352,5.3962,Ghomálá',LANGUAGE,LANGUAGE
SM305981,69.1565,39.8311,Ghonchi,ADM2,DISTRICT
SM1333,65,34,Ghor,ADM1,DISTRICT
SM260055,65.1957,32.0439,Ghorak,ADM2,DISTRICT
SM260178,68.7877,35.0104,Ghorband,ADM2,DISTRICT
SM260099,63.779,35.7311,Ghormach,ADM2,DISTRICT
SM260019,61.1627,34.1823,Ghoryan,ADM2,DISTRICT
SM324120,-114.7652,51.2054,Ghost Lake,ADM2:ADM3,DISTRICT
SM375502,69.6581,27.8157,Ghotki,ADM2:ADM3,DISTRICT
SM231489,5.9566,7.1155,Ghotuo,LANGUAGE,LANGUAGE
SM278009,35.695,31.3654,Ghour El-Mazra'ah,ADM2,DISTRICT
SM17782,39.3652,21.9803,Ghran,ADM2,DISTRICT
SM253572,70.22,29.18,Ghurghusht Pathans,ETHNICITY,ETHNICITY
SM304431,106.209,21.078,Gia Bình,ADM2,DISTRICT
SM1334,108.25,13.75,Gia Lai,ADM1,DISTRICT
SM304133,105.9743,21.0316,Gia Lâm,ADM2,DISTRICT
SM304068,106.2913,20.8516,Gia Lộc,ADM2,DISTRICT
SM303995,107.6972,11.9898,Gia Nghĩa,ADM2,DISTRICT
SM304339,105.8644,20.3468,Gia Viễn,ADM2,DISTRICT
SM458161,151.7555,-27.6904,Giabal,ETHNICITY,ETHNICITY
SM285329,10.2834,11.4065,Giade,ADM2,DISTRICT
SM292714,40.1667,44.8333,Giaginskiy rayon,ADM2,DISTRICT
SM10732,32.5453,35.0835,Gialia,ADM2,DISTRICT
SM304230,104.5215,10.4266,Giang Thành,ADM2,DISTRICT
SM231496,125.359,6.9997,Giangan,LANGUAGE,LANGUAGE
SM276477,115.2833,-8.4667,Gianyar,ADM2,DISTRICT
SM304308,106.4625,20.2571,Giao Thủy,ADM2,DISTRICT
SM291984,21.2892,45.8422,Giarmata,ADM2,DISTRICT
SM231497,8.615,12.584,Gibanawa,LANGUAGE,LANGUAGE
SM271136,-76.2909,21.0952,Gibara,ADM2,DISTRICT
SM323998,-113.3302,53.8234,Gibbons,ADM2:ADM3,DISTRICT
SM250809,37,8,Gibe,ETHNICITY,ETHNICITY
SM284500,17.7644,-25.1285,Gibeon,ADM2,DISTRICT
SM280991,-10.0177,6.5768,Gibi,ADM2,DISTRICT
SM12358,-77.1549,18.2354,Gibraltar,ADM2,DISTRICT
SM12358,-77.1549,18.2354,Gibraltar,ADM2,DISTRICT
SM12358,-77.4214,18.3508,Gibraltar,ADM2,DISTRICT
SM12358,-77.4214,18.3508,Gibraltar,ADM2,DISTRICT
SM302563,-88.9326,35.9966,Gibson,ADM2,DISTRICT
SM300709,-87.5846,38.3118,Gibson,ADM2,DISTRICT
SM13526,22.377,57.2079,Gibulu pagasts,ADM2,DISTRICT
SM295138,30.121,-1.6165,Gicumbi,ADM1:ADM2,DISTRICT
SM231502,14.0785,9.8747,Gidar,LANGUAGE,LANGUAGE
SM12359,-77.8676,18.097,Giddy Hall,ADM2,DISTRICT
SM458193,152.3203,-28.5011,Gidhabal,ETHNICITY,ETHNICITY
SM253576,134.32,-12.29,Gidjingali,ETHNICITY,ETHNICITY
SM253576,134.32,-12.29,Gidjingali,ETHNICITY,ETHNICITY
SM253576,134.32,-12.29,Gidjingali,ETHNICITY,ETHNICITY
SM253576,134.4,-12.18,Gidjingali,ETHNICITY,ETHNICITY
SM253576,134.4,-12.18,Gidjingali,ETHNICITY,ETHNICITY
SM253576,134.4,-12.18,Gidjingali,ETHNICITY,ETHNICITY
SM253576,134.5807,-12.1618,Gidjingali,ETHNICITY,ETHNICITY
SM253576,134.5807,-12.1618,Gidjingali,ETHNICITY,ETHNICITY
SM253576,134.5807,-12.1618,Gidjingali,ETHNICITY,ETHNICITY
SM458826,132.9372,-12.1328,Gidjurra,ETHNICITY,ETHNICITY
SM253578,-7.67,6.9,Gien,ETHNICITY,ETHNICITY
SM291985,20.947,45.4206,Giera,ADM2,DISTRICT
SM279820,136.7647,35.4483,Gifu,ADM2,DISTRICT
SM1335,137.05,35.7833,Gifu,ADM1:ADMX,DISTRICT
SM270082,-75.5363,2.38,Gigante,ADM2,DISTRICT
SM290366,23.8059,43.8481,Gighera,ADM2,DISTRICT
SM261960,29.294,-3.192,Gihanga,ADM2,DISTRICT
SM262006,30.2339,-3.8354,Giharo,ADM2,DISTRICT
SM262070,29.891,-3.341,Giheta,ADM2,DISTRICT
SM262078,29.9802,-3.141,Gihogazi,ADM2,DISTRICT
SM262027,29.3929,-3.3583,Gihosha,ADM2,DISTRICT
SM231508,10.4604,10.3575,Giiwo,LANGUAGE,LANGUAGE
SM280539,129.2008,35.2972,Gijang,ADM2,DISTRICT
SM231510,0.5698,8.3948,Gikyode,LANGUAGE,LANGUAGE
SM301797,-110.8117,33.7998,Gila,ADM2,DISTRICT
SM231513,49.8359,37.1344,Gilaki,LANGUAGE,LANGUAGE
SM253579,153.62,-25.36,Gilambabura,ETHNICITY,ETHNICITY
SM1336,49.5,37.1667,Gilan,ADM1,DISTRICT
SM277521,46,34.1333,Gilan-e-Gharb,ADM2,DISTRICT
SM290101,23.3491,46.7261,Gilau,ADM2,DISTRICT
SM4246,172.984,1.42,Gilbert Islands,ADM1,DISTRICT
SM253580,172.98,3.37,Gilbert Islands,ETHNICITY,ETHNICITY
SM253580,172.98,3.37,Gilbert Islands,ETHNICITY,ETHNICITY
SM253580,172.98,3.37,Gilbert Islands,ETHNICITY,ETHNICITY
SM253580,172.99,3.38,Gilbert Islands,ETHNICITY,ETHNICITY
SM253580,172.99,3.38,Gilbert Islands,ETHNICITY,ETHNICITY
SM253580,172.99,3.38,Gilbert Islands,ETHNICITY,ETHNICITY
SM253580,175.06,-1.47,Gilbert Islands,ETHNICITY,ETHNICITY
SM253580,175.06,-1.47,Gilbert Islands,ETHNICITY,ETHNICITY
SM253580,175.06,-1.47,Gilbert Islands,ETHNICITY,ETHNICITY
SM10178,-100.4176,51.1833,Gilbert Plains (2015-),ADM2,DISTRICT
SM231517,176.44,-1.34,Gilbertese,LANGUAGE,LANGUAGE
SM231517,176.44,-1.34,Gilbertese,LANGUAGE,LANGUAGE
SM231517,173.64,0.179,Gilbertese,LANGUAGE,LANGUAGE
SM231517,173.64,0.179,Gilbertese,LANGUAGE,LANGUAGE
SM265234,-45.5202,-9.7274,Gilbués,ADM2,DISTRICT
SM300325,-82.8004,29.7258,Gilchrist,ADM2,DISTRICT
SM286060,14.0258,67.0339,Gildeskål,ADM2,DISTRICT
SM283885,38.3675,-16.1441,Gile,ADM2,DISTRICT
SM303015,-80.7037,37.314,Giles,ADM2,DISTRICT
SM302564,-87.0348,35.2022,Giles,ADM2,DISTRICT
SM261536,148.7035,-31.633,Gilgandra,ADM2,DISTRICT
SM375438,74.4123,35.8801,Gilgit (Tribal Territory),ADM2:ADM3,DISTRICT
SM5141,74.25,36.25,Gilgit (excl. Hunza-Nagar and Ghizer),ADM2:ADM3,DISTRICT
SM231521,19.0091,4.5585,Gilima,LANGUAGE,LANGUAGE
SM238158,43.1095,33.8178,Gilit Mesopotamian Arabic,LANGUAGE,LANGUAGE
SM253581,7.23,10.25,Giliu,ETHNICITY,ETHNICITY
SM302878,-98.9465,30.3181,Gillespie,ADM2,DISTRICT
SM302251,-120.2109,45.3781,Gilliam,ADM2,DISTRICT
SM14931,138.0639,9.4461,Gilman Municipality,ADM2,DISTRICT
SM303110,-80.8571,38.9241,Gilmer,ADM2,DISTRICT
SM300497,-84.4556,34.6912,Gilmer,ADM2,DISTRICT
SM303335,-105.5225,39.8576,Gilpin,ADM2,DISTRICT
SM285919,4.9125,51.5566,Gilze en Rijen,ADM2,DISTRICT
SM346562,39.2595,9.0679,Gimbichu,ADM2:ADM3,DISTRICT
SM280752,128.1116,36.1448,Gimcheon,ADM2,DISTRICT
SM280557,128.8667,35.25,Gimhae,ADM2,DISTRICT
SM253583,145.4,-6.82,Gimi,ETHNICITY,ETHNICITY
SM231527,145.214,-6.421,Gimi (Eastern Highlands),LANGUAGE,LANGUAGE
SM231526,149.478,-6.0246,Gimi (West New Britain),LANGUAGE,LANGUAGE
SM280580,126.9076,35.807,Gimje,ADM2,DISTRICT
SM327916,-97.0507,50.6667,Gimli,ADM2:ADM3,DISTRICT
SM250709,32,13,Gimma,ETHNICITY,ETHNICITY
SM231528,12.6658,8.5808,Gimme,LANGUAGE,LANGUAGE
SM12360,-77.2847,17.8895,Gimme-me-bit,ADM2,DISTRICT
SM231529,12.7869,8.5813,Gimnime,LANGUAGE,LANGUAGE
SM280730,126.6927,37.6364,Gimpo,ADM2,DISTRICT
SM253584,25.81,15.55,Gimr,ETHNICITY,ETHNICITY
SM270614,-76.1941,3.743,Ginebra,ADM2,DISTRICT
SM12361,-77.8487,18.1918,Ginger Hill,ADM2,DISTRICT
SM12362,-77.1687,18.0769,Ginger Ridge,ADM2,DISTRICT
SM261383,115.6594,-31.1844,Gingin,ADM2,DISTRICT
SM279072,127.7574,26.2762,Ginowan,ADM2,DISTRICT
SM231535,149.7,-10.0298,Ginuman,LANGUAGE,LANGUAGE
SM231536,0.9835,8.2327,Ginyanga,LANGUAGE,LANGUAGE
SM304443,107,16.9167,Gio Linh,ADM2,DISTRICT
SM10733,32.4789,34.9295,Giolou,ADM2,DISTRICT
SM276815,70.3671,20.9129,Gir Somnath,ADM2,DISTRICT
SM270215,-75.9535,6.6667,Giraldo,ADM2,DISTRICT
SM17395,-60.9596,14,Girard,ADM2,DISTRICT
SM303835,-67.6667,10.4167,Girardot,ADM2,DISTRICT
SM303882,-68.25,8.9,Girardot,ADM2,DISTRICT
SM270002,-74.835,4.318,Girardot,ADM2,DISTRICT
SM270217,-75.4623,6.3679,Girardota,ADM2,DISTRICT
SM253586,72.78,24.98,Girasia,ETHNICITY,ETHNICITY
SM265364,-36.842,-9.7838,Girau do Ponciano,ADM2,DISTRICT
SM17396,-61.0084,13.7925,Giraud,ADM2,DISTRICT
SM231540,145.423,-5.3534,Girawa,LANGUAGE,LANGUAGE
SM1337,38.5,40.5,Giresun,ADM1,DISTRICT
SM250257,40.57,-1.18,Giriama,ETHNICITY,ETHNICITY
SM250257,40.57,-1.18,Giriama,ETHNICITY,ETHNICITY
SM250257,40,-3,Giriama,ETHNICITY,ETHNICITY
SM250257,40,-3,Giriama,ETHNICITY,ETHNICITY
SM276920,85.9167,24.25,Giridih (1999-),ADM2,DISTRICT
SM284847,12.4809,9.4265,Girie,ADM2,DISTRICT
SM231546,42.17,5.76,Girirra,LANGUAGE,LANGUAGE
SM260301,68.3459,33.0631,Giro,ADM2,DISTRICT
SM291986,21.2264,45.7002,Giroc,ADM2,DISTRICT
SM274077,2.8111,41.9892,Girona,ADM2,DISTRICT
SM274442,-0.414,44.6931,Gironde,ADM2,DISTRICT
SM323817,-117.3386,55.7541,Girouxville,ADM2:ADM3,DISTRICT
SM459202,145.7326,-18.11,Girramay,ETHNICITY,ETHNICITY
SM265683,-54.3046,-28.0105,Giruá,ADM2,DISTRICT
SM231549,39.6648,-3.3064,Giryama,LANGUAGE,LANGUAGE
SM273426,-79.15,-3.2,Girón,ADM2,DISTRICT
SM270470,-73.3173,7.0687,Girón,ADM2,DISTRICT
SM295141,29.8502,-2.6181,Gisagara,ADM1:ADM2,DISTRICT
SM262056,30.7371,-3.2131,Gisagara,ADM2,DISTRICT
SM286547,178.0167,-38.6667,Gisborne,ADM2,DISTRICT
SM1339,178,-38.65,Gisborne,ADM1,DISTRICT
SM262071,29.8902,-3.6153,Gishubi,ADM2,DISTRICT
SM286447,6.1347,62.5007,Giske,ADM2,DISTRICT
SM296647,13.4755,57.3034,Gislaved,ADM2,DISTRICT
SM261991,29.6317,-3.5456,Gisozi,ADM2,DISTRICT
SM17931,68.5833,38.6667,Gissarskiy Rayon,ADM2,DISTRICT
SM262014,30.4979,-3.4459,Gisuru,ADM2,DISTRICT
SM262007,29.902,-4.0076,Gitanga,ADM2,DISTRICT
SM262079,30.1632,-2.975,Gitaramuka,ADM2,DISTRICT
SM262072,29.9548,-3.4381,Gitega,ADM2,DISTRICT
SM1340,29.95,-3.5,Gitega,ADM1,DISTRICT
SM261987,30.4463,-2.4616,Giteranyi,ADM2,DISTRICT
SM261967,30.2295,-2.6421,Gitobe,ADM2,DISTRICT
SM231553,35.1577,-23.7964,Gitonga,LANGUAGE,LANGUAGE
SM231556,147.485,-6.0151,Gitua,LANGUAGE,LANGUAGE
SM231557,-128.08,55.09,Gitxsan,LANGUAGE,LANGUAGE
SM253591,-128.06,55.65,Gitxsan,ETHNICITY,ETHNICITY
SM253591,-128.06,55.65,Gitxsan,ETHNICITY,ETHNICITY
SM253591,-127.5,56.3,Gitxsan,ETHNICITY,ETHNICITY
SM253591,-127.5,56.3,Gitxsan,ETHNICITY,ETHNICITY
SM291008,23.9299,47.8222,Giulesti,ADM2,DISTRICT
SM291987,20.985,45.5252,Giulvaz,ADM2,DISTRICT
SM290913,27.8564,44.7444,Giurgeni,ADM2,DISTRICT
SM290368,23.6305,44.0072,Giurgita,ADM2,DISTRICT
SM290569,25.9661,43.8905,Giurgiu,ADM2,DISTRICT
SM1341,25.9167,44.1667,Giurgiu,ADM1,DISTRICT
SM291418,24.6873,43.7941,Giuvarasti,ADM2,DISTRICT
SM284825,7.4057,11.1208,Giwa,ADM2,DISTRICT
SM458659,128.2514,-16.3095,Giwajbem,ETHNICITY,ETHNICITY
SM231558,148.4629,-20.4018,Giya,LANGUAGE,LANGUAGE
SM231559,130.092,-13.2001,Giyug,LANGUAGE,LANGUAGE
SM273956,31.2114,29.9391,Giza,ADM2,DISTRICT
SM260263,66.2656,33.4019,Gizab,ADM2,DISTRICT
SM231561,15.2691,10.0233,Gizey,LANGUAGE,LANGUAGE
SM295335,156.7877,-8.075,Gizo,ADM2,DISTRICT
SM231563,142.804,-9.183,Gizrra,LANGUAGE,LANGUAGE
SM370406,-9.6669,7.8378,Gizzima,ADM2:ADM3,DISTRICT
SM304030,105.4002,9.2716,Giá Rai,ADM2,DISTRICT
SM288784,21.8219,54.0181,Giżycko,ADM2,DISTRICT
SM304229,105.3731,9.9122,Giồng Riềng,ADM2,DISTRICT
SM304558,106.47,10.1591,Giồng Trôm,ADM2,DISTRICT
SM286448,7.6771,62.8938,Gjemnes,ADM2,DISTRICT
SM286055,11.036,60.0716,Gjerdrum,ADM2,DISTRICT
SM286313,9.0192,58.8813,Gjerstad,ADM2,DISTRICT
SM286150,5.855,58.7648,Gjesdal,ADM2,DISTRICT
SM260490,20.1667,40.0333,Gjirokastrës,ADM2,DISTRICT
SM1342,20.2,40.2167,Gjirokastër,ADM1,DISTRICT
SM1343,21.3193,42.0609,Gjorče Petrov,ADM1:ADM2,DISTRICT
SM10504,17.0717,46.0397,Gjurgevac (excl. Grad Gjurgevac),ADM2,DISTRICT
SM286121,10.6929,60.7947,Gjøvik,ADM2,DISTRICT
SM458489,141.1151,-17.3688,Gkuthaarn,ETHNICITY,ETHNICITY
SM301631,-112.9948,48.7051,Glacier,ADM2,DISTRICT
SM1344,55.4417,-4.575,Glacis,ADM1,DISTRICT
SM300326,-81.189,26.9565,Glades,ADM2,DISTRICT
SM271664,12.4333,55.7667,Gladsaxe,ADM2,DISTRICT
SM261628,151.6582,-24.0187,Gladstone,ADM2,DISTRICT
SM301269,-84.3883,43.9907,Gladwin,ADM2,DISTRICT
SM261758,147.9668,-42.2783,Glamorgan/Spring Bay,ADM2,DISTRICT
SM14038,-9.9167,6.1667,Glarkon Chiefdom,ADM2,DISTRICT
SM14039,-7.5666,5.3947,Glaro,ADM2,DISTRICT
SM231565,-7.5032,5.2326,Glaro-Twabo,LANGUAGE,LANGUAGE
SM269064,9.0589,47.0167,Glarus,ADM2,DISTRICT
SM1345,9.0732,46.9756,Glarus,ADM1,DISTRICT
SM300498,-82.6107,33.2293,Glascock,ADM2,DISTRICT
SM274720,-4.25,55.8667,Glasgow,ADM1:ADM2,DISTRICT
SM302879,-101.5208,31.8695,Glasscock,ADM2,DISTRICT
SM263461,-43.6608,-16.8953,Glaucilândia,ADM2,DISTRICT
SM292110,27.3878,46.2633,Glavanesti,ADM2,DISTRICT
SM231567,13.8652,11.1334,Glavda,LANGUAGE,LANGUAGE
SM17397,-60.9113,13.9225,Glavier,ADM2,DISTRICT
SM292190,24.1226,44.8042,Glavile,ADM2,DISTRICT
SM262384,26.85,43.95,Glavinitsa,ADM2,DISTRICT
SM262159,2.233,8.174,Glazoué,ADM2,DISTRICT
SM261230,145.041,-37.8995,Glen Eira,ADM2,DISTRICT
SM261538,151.9396,-29.6312,Glen Innes Severn,ADM2,DISTRICT
SM12363,-77.8971,18.4849,Glendevon,ADM2,DISTRICT
SM324043,-111.1553,54.2521,Glendon,ADM2:ADM3,DISTRICT
SM261231,141.4424,-37.8908,Glenelg,ADM2,DISTRICT
SM12364,-76.8906,18.1521,Glengoffe,ADM2,DISTRICT
SM12365,-77.2539,17.968,Glenmuir,ADM2,DISTRICT
SM303165,-122.3922,39.5984,Glenn,ADM2,DISTRICT
SM261759,147.2295,-42.8319,Glenorchy,ADM2,DISTRICT
SM323864,-113.5162,49.3657,Glenwood,ADM2:ADM3,DISTRICT
SM266540,-50.1778,-21.3187,Glicério,ADM2,DISTRICT
SM290019,22.3219,45.495,Glimboca,ADM2,DISTRICT
SM290960,26.2458,44.3855,Glina,ADM2,DISTRICT
SM275990,16.1,45.3333,Glina,ADM2,DISTRICT
SM231572,-7.5059,5.3997,Glio-Oubi,LANGUAGE,LANGUAGE
SM14041,-7.5953,5.6961,Glio-Twarbo,ADM2,DISTRICT
SM288719,18.5811,50.3627,Gliwice,ADM2,DISTRICT
SM288718,18.6498,50.3012,Gliwice (City),ADM2,DISTRICT
SM289878,26.659,44.876,Glodeanu Sarat,ADM2,DISTRICT
SM289877,26.7676,44.8396,Glodeanu-silistea,ADM2,DISTRICT
SM291149,24.561,46.6757,Glodeni,ADM2,DISTRICT
SM290270,25.4591,45.0193,Glodeni,ADM2,DISTRICT
SM1347,27.5,47.75,Glodeni,ADM1,DISTRICT
SM304748,20.8667,42.6,Glogovac,ADM2,DISTRICT
SM286156,6.2156,61.7773,Gloppen,ADM2,DISTRICT
SM265685,-50.7509,-29.8569,Glorinha,ADM2,DISTRICT
SM11281,47.3333,-11.5,Glorioso Islands,ADM2,DISTRICT
SM271665,12.4102,55.6819,Glostrup,ADM2,DISTRICT
SM303016,-76.523,37.4012,Gloucester,ADM2,DISTRICT
SM301832,-75.1417,39.7173,Gloucester,ADM2,DISTRICT
SM268803,-65.2533,47.6497,Gloucester,ADM2,DISTRICT
SM274631,-2.1667,51.8333,Gloucestershire,ADM1:ADM2,DISTRICT
SM279960,83,50.3333,Glubokovskiy,ADM2,DISTRICT
SM13527,23.54,56.6153,Gludas pagasts,ADM2,DISTRICT
SM293523,34.5833,51.3333,Glushkovskiy rayon,ADM2,DISTRICT
SM300499,-81.4937,31.2132,Glynn,ADM2,DISTRICT
SM267410,-38.4066,-9.111,Glória,ADM2,DISTRICT
SM263163,-58.3045,-15.9131,Glória d'Oeste,ADM2,DISTRICT
SM263049,-54.0901,-22.4839,Glória de Dourados,ADM2,DISTRICT
SM264990,-35.3401,-8.0387,Glória do Goitá,ADM2,DISTRICT
SM261840,13.75,47.75,Gmunden,ADM2,DISTRICT
SM261823,14.9817,48.7473,Gmünd,ADM2,DISTRICT
SM262216,0.0392,12.9188,Gnagna,ADM1:ADM2,DISTRICT
SM231578,142.196,-3.5909,Gnau,LANGUAGE,LANGUAGE
SM4256,73.4249,-0.2969,Gnaviyani Atoll,ADM1:ADM2,DISTRICT
SM296463,17.1453,59.0921,Gnesta,ADM2,DISTRICT
SM288809,17.5714,52.5536,Gniezno,ADM2,DISTRICT
SM304736,21.45,42.4667,Gnjilane,ADM2,DISTRICT
SM1348,21.4672,42.4608,Gnjilane,ADM1,DISTRICT
SM296648,13.8049,57.3623,Gnosjö,ADM2,DISTRICT
SM261384,118.2983,-34.0866,Gnowangerup,ADM2,DISTRICT
SM18395,66.5667,39.75,Gozalkent Tumani,ADM2,DISTRICT
SM1349,74.0833,15.3333,Goa,ADM1,DISTRICT
SM253595,140.37,-22.46,Goa,ETHNICITY,ETHNICITY
SM253595,140.37,-22.46,Goa,ETHNICITY,ETHNICITY
SM253595,142.3194,-22.0369,Goa,ETHNICITY,ETHNICITY
SM253595,142.3194,-22.0369,Goa,ETHNICITY,ETHNICITY
SM277378,90.6097,26.1279,Goalpara,ADM2,DISTRICT
SM231581,74.2098,15.2732,Goan Konkani,LANGUAGE,LANGUAGE
SM231582,68.363,27.253,Goaria,LANGUAGE,LANGUAGE
SM275797,-87.7,13.59,Goascorán,ADM2,DISTRICT
SM231584,142.201,-6.4868,Gobasi,LANGUAGE,LANGUAGE
SM260990,-65.8333,-35.3333,Gobernador Dupuy,ADM2,DISTRICT
SM15721,-66.0895,18.4079,Gobernador Pinero Barrio,ADM2,DISTRICT
SM231587,20.0194,5.012,Gobu,LANGUAGE,LANGUAGE
SM250258,24.35,7.47,Gobu,ETHNICITY,ETHNICITY
SM279534,135.1761,33.8804,Gobō,ADM2,DISTRICT
SM280581,126.7005,35.4348,Gochang,ADM2,DISTRICT
SM253600,-66.38,50.4,Godbout,ETHNICITY,ETHNICITY
SM276921,87.2167,24.8333,Godda,ADM2,DISTRICT
SM291071,22.6087,44.8081,Godeanu,ADM2,DISTRICT
SM262417,23.0667,43.0667,Godech,ADM2,DISTRICT
SM291249,24.9784,45.2231,Godeni,ADM2,DISTRICT
SM290631,22.9609,44.9763,Godinesti,ADM2,DISTRICT
SM231589,-5.827,5.3944,Godié,LANGUAGE,LANGUAGE
SM231481,46.2252,42.7428,Godoberi,LANGUAGE,LANGUAGE
SM262994,-45.7655,-1.276,Godofredo Viana,ADM2,DISTRICT
SM260891,-68.8667,-32.9333,Godoy Cruz,ADM2,DISTRICT
SM264474,-51.9156,-24.1481,Godoy Moreira,ADM2,DISTRICT
SM253606,-50.67,65.56,Godthiaab,ETHNICITY,ETHNICITY
SM231590,72.846,24.885,Godwari,LANGUAGE,LANGUAGE
SM11567,19.396,47.5513,Goedoelloi Jaras,ADM2,DISTRICT
SM231592,9.7245,8.7446,Goemai,LANGUAGE,LANGUAGE
SM458212,151.6817,-24.2669,Goeng Goeng,ETHNICITY,ETHNICITY
SM17398,-60.9845,14.0131,Goergeville,ADM2,DISTRICT
SM285622,3.8395,51.515,Goes,ADM2,DISTRICT
SM280650,127.8143,36.7718,Goesan,ADM2,DISTRICT
SM370461,5.9683,49.9217,Goesdorf,ADM1:ADM2:ADM3,DISTRICT
SM231594,37.05,6.5765,Gofa,LANGUAGE,LANGUAGE
SM253608,37.4,6.05,Gofa,ETHNICITY,ETHNICITY
SM253610,167.46,-14.28,Gog,ETHNICITY,ETHNICITY
SM253611,129.9,-30.62,Gogada,ETHNICITY,ETHNICITY
SM253611,129.9,-30.62,Gogada,ETHNICITY,ETHNICITY
SM253611,121.5137,-30.1741,Gogada,ETHNICITY,ETHNICITY
SM253611,121.5137,-30.1741,Gogada,ETHNICITY,ETHNICITY
SM301270,-89.7956,46.4955,Gogebic,ADM2,DISTRICT
SM274006,37.3645,15.0983,Gogne,ADM2,DISTRICT
SM231595,35.4807,-6.3595,Gogo,LANGUAGE,LANGUAGE
SM250259,37.46,-8.06,Gogo,ETHNICITY,ETHNICITY
SM250259,37.46,-8.06,Gogo,ETHNICITY,ETHNICITY
SM250259,37.46,-8.06,Gogo,ETHNICITY,ETHNICITY
SM250259,36,-7,Gogo,ETHNICITY,ETHNICITY
SM250259,36,-7,Gogo,ETHNICITY,ETHNICITY
SM250259,36,-7,Gogo,ETHNICITY,ETHNICITY
SM250259,36,-6,Gogo,ETHNICITY,ETHNICITY
SM250259,36,-6,Gogo,ETHNICITY,ETHNICITY
SM250259,36,-6,Gogo,ETHNICITY,ETHNICITY
SM231597,142.874,-8.0853,Gogodala,LANGUAGE,LANGUAGE
SM253612,142.71,-8.02,Gogodara,ETHNICITY,ETHNICITY
SM290571,25.685,43.8661,Gogosari,ADM2,DISTRICT
SM291072,22.5736,44.3793,Gogosu,ADM2,DISTRICT
SM290369,23.3784,44.4172,Gogosu,ADM2,DISTRICT
SM262104,2.8361,10.8386,Gogounou,ADM2,DISTRICT
SM280604,127.3333,34.5833,Goheung,ADM2,DISTRICT
SM290444,27.4265,46.0154,Gohor,ADM2,DISTRICT
SM263462,-41.2273,-19.0296,Goiabeira,ADM2,DISTRICT
SM268276,-48.1493,-18.1098,Goiandira,ADM2,DISTRICT
SM268278,-49.2599,-16.6402,Goiania,ADM2,DISTRICT
SM265548,-35.1827,-6.2698,Goianinha,ADM2,DISTRICT
SM268279,-49.436,-16.5023,Goianira,ADM2,DISTRICT
SM267239,-48.9936,-8.8366,Goianorte,ADM2,DISTRICT
SM264991,-35.0018,-7.5688,Goianá,ADM2,DISTRICT
SM263463,-43.1851,-21.5606,Goianá,ADM2,DISTRICT
SM268275,-49.0877,-16.5304,Goianápolis,ADM2,DISTRICT
SM268277,-49.1741,-15.311,Goianésia,ADM2,DISTRICT
SM264169,-48.8736,-3.9492,Goianésia do Pará,ADM2,DISTRICT
SM267240,-47.4718,-8.0443,Goiatins,ADM2,DISTRICT
SM268281,-49.7072,-18.032,Goiatuba,ADM2,DISTRICT
SM270977,-84.0637,9.9485,Goicoechea,ADM2,DISTRICT
SM290372,23.7538,44.4863,Goiesti,ADM2,DISTRICT
SM288604,147,-8.3333,Goilala,ADM2,DISTRICT
SM264475,-53.0992,-24.1887,Goioerê,ADM2,DISTRICT
SM264476,-52.0081,-25.0953,Goioxim,ADM2,DISTRICT
SM285920,5.0338,51.5088,Goirle,ADM2,DISTRICT
SM268280,-50.2413,-15.8028,Goiás,ADM2,DISTRICT
SM1351,-49.6362,-15.5807,Goiás (1989-),ADM1,DISTRICT
SM278914,135.6949,34.3508,Gojō,ADM2,DISTRICT
SM231603,7.3446,4.6189,Gokana,LANGUAGE,LANGUAGE
SM285199,7.2835,4.6605,Gokana,ADM2,DISTRICT
SM253617,53.85,35.71,Goklen,ETHNICITY,ETHNICITY
SM280605,127.2628,35.2145,Gokseong,ADM2,DISTRICT
SM305222,28.91,-17.53,Gokwe North,ADM2,DISTRICT
SM305223,28.65,-18.24,Gokwe South,ADM2,DISTRICT
SM286331,8.9502,60.6995,Gol,ADM2,DISTRICT
SM231608,-10.8138,7.0619,Gola,LANGUAGE,LANGUAGE
SM305721,17.0597,46.1976,Gola,ADM2,DISTRICT
SM250260,-9.87,7.57,Gola,ETHNICITY,ETHNICITY
SM250260,-9.87,7.57,Gola,ETHNICITY,ETHNICITY
SM250260,-11.29,7.78,Gola,ETHNICITY,ETHNICITY
SM250260,-11.29,7.78,Gola,ETHNICITY,ETHNICITY
SM250260,-11,7,Gola,ETHNICITY,ETHNICITY
SM250260,-11,7,Gola,ETHNICITY,ETHNICITY
SM277379,93.9119,26.4092,Golaghat,ADM2,DISTRICT
SM290824,27.6952,47.2636,Golaiesti,ADM2,DISTRICT
SM280968,-10.9038,7.1654,Golakonneh,ADM2,DISTRICT
SM280968,-10.9038,7.1654,Golakonneh,ADM2,DISTRICT
SM280968,-10.8624,7.1469,Golakonneh,ADM2,DISTRICT
SM280968,-10.8624,7.1469,Golakonneh,ADM2,DISTRICT
SM1352,35.75,33.0333,Golan,ADM1:ADM2,DISTRICT
SM261629,153.3606,-27.9785,Gold Coast,ADM2,DISTRICT
SM12366,-76.9804,18.0195,Golden Acres,ADM2,DISTRICT
SM323999,-114.0541,53.0541,Golden Days,ADM2:ADM3,DISTRICT
SM12367,-77.1226,18.3296,Golden Grove (Saint Ann),ADM2,DISTRICT
SM18531,-76.2734,17.9225,Golden Grove (Saint Thomas),ADM2,DISTRICT
SM261232,143.8635,-37.8523,Golden Plains,ADM2,DISTRICT
SM12368,-76.7973,18.0945,Golden Spring,ADM2,DISTRICT
SM302038,-103.8467,46.9402,Golden Valley,ADM2,DISTRICT
SM301632,-109.1749,46.3813,Golden Valley,ADM2,DISTRICT
SM295773,47.2217,7.0392,Goldogob,ADM2,DISTRICT
SM289321,-8.5059,39.3868,Golegã,ADM2,DISTRICT
SM288838,14.8542,53.6104,Goleniów,ADM2,DISTRICT
SM1353,55,37.25,Golestan,ADM1,DISTRICT
SM292345,27.1671,45.666,Golesti,ADM2,DISTRICT
SM292191,24.4739,45.1137,Golesti,ADM2,DISTRICT
SM296853,1.2,6.2333,Golfe (incl Lomé),ADM2,DISTRICT
SM270956,-83.12,8.6,Golfito,ADM2,DISTRICT
SM302880,-97.4265,28.6571,Goliad,ADM2,DISTRICT
SM231612,144.8505,-6.2249,Golin,LANGUAGE,LANGUAGE
SM13528,27.8914,56.7619,Golisevas pagasts,ADM2,DISTRICT
SM259146,26.85,7.75,Golo,LANGUAGE,LANGUAGE
SM269273,99.5518,34.0859,Golog Tibetan,ADM2,DISTRICT
SM17832,14.5507,46.0397,Golovec District,ADM2,DISTRICT
SM259475,135.5659,-12.0348,Golpa,DIALECT,LANGUAGE
SM277702,50.3333,33.5,Golpayegan,ADM2,DISTRICT
SM288863,18.9921,53.0992,Golub-Dobrzyń,ADM2,DISTRICT
SM260436,14.64,-9.1227,Golungo Alto,ADM2,DISTRICT
SM294819,68.4167,56.4167,Golyshmanovskiy rayon,ADM2,DISTRICT
SM269654,29.2333,-1.6833,Goma,ADM2,DISTRICT
SM16308,15.1121,-4.141,Goma-Tsetse (1994-),ADM2,DISTRICT
SM260155,68.9467,32.3889,Gomal,ADM2,DISTRICT
SM277261,91.4812,23.534,Gomati,ADM2,DISTRICT
SM299358,31.7502,0.2002,Gomba,ADM1:ADM2,DISTRICT
SM284383,101.7,3.3,Gombak,ADM2,DISTRICT
SM284751,11.1667,10.2826,Gombe,ADM2,DISTRICT
SM1354,11.1667,10.25,Gombe,ADM1,DISTRICT
SM284848,12.5277,10.1895,Gombi,ADM2,DISTRICT
SM14284,-107.7111,29.3384,Gomez Farias,ADM2,DISTRICT
SM17399,-60.9525,13.8149,Gomier,ADM2,DISTRICT
SM11400,-0.75,5.5167,Gomoa East,ADM2,DISTRICT
SM11401,-0.7333,5.2833,Gomoa West,ADM2,DISTRICT
SM268991,8.2219,46.4616,Goms,ADM2,DISTRICT
SM458865,132.1565,-12.4534,Gonbudj,ETHNICITY,ETHNICITY
SM253630,77.82,17.48,Gond,ETHNICITY,ETHNICITY
SM253630,77.82,17.48,Gond,ETHNICITY,ETHNICITY
SM253630,80.27,20.86,Gond,ETHNICITY,ETHNICITY
SM253630,80.27,20.86,Gond,ETHNICITY,ETHNICITY
SM253630,76.22,22.27,Gond,ETHNICITY,ETHNICITY
SM253630,76.22,22.27,Gond,ETHNICITY,ETHNICITY
SM253630,74.67,25.8,Gond,ETHNICITY,ETHNICITY
SM253630,74.67,25.8,Gond,ETHNICITY,ETHNICITY
SM253630,80,21,Gond,ETHNICITY,ETHNICITY
SM253630,80,21,Gond,ETHNICITY,ETHNICITY
SM253639,79.93,18.46,Gond Madia,ETHNICITY,ETHNICITY
SM253639,79.93,18.46,Gond Madia,ETHNICITY,ETHNICITY
SM253639,79.93,18.46,Gond Madia,ETHNICITY,ETHNICITY
SM253639,79.93,18.46,Gond Madia,ETHNICITY,ETHNICITY
SM253639,81.1335,19,Gond Madia,ETHNICITY,ETHNICITY
SM253639,81.1335,19,Gond Madia,ETHNICITY,ETHNICITY
SM253639,81.1335,19,Gond Madia,ETHNICITY,ETHNICITY
SM253639,81.1335,19,Gond Madia,ETHNICITY,ETHNICITY
SM253639,81,19,Gond Madia,ETHNICITY,ETHNICITY
SM253639,81,19,Gond Madia,ETHNICITY,ETHNICITY
SM253639,81,19,Gond Madia,ETHNICITY,ETHNICITY
SM253639,81,19,Gond Madia,ETHNICITY,ETHNICITY
SM253639,80.92,19.63,Gond Madia,ETHNICITY,ETHNICITY
SM253639,80.92,19.63,Gond Madia,ETHNICITY,ETHNICITY
SM253639,80.92,19.63,Gond Madia,ETHNICITY,ETHNICITY
SM253639,80.92,19.63,Gond Madia,ETHNICITY,ETHNICITY
SM277293,81.9669,27.1858,Gonda (1997-),ADM2,DISTRICT
SM277034,80.25,21.05,Gondiya,ADM2,DISTRICT
SM15051,33.66,-19.15,"Gondola District (2013-, excl. Chimoio)",ADM2,DISTRICT
SM289312,-8.496,41.1202,Gondomar,ADM2,DISTRICT
SM231624,178.599,-16.5886,Gone Dau,LANGUAGE,LANGUAGE
SM231626,91.1686,27.066,Gongduk,LANGUAGE,LANGUAGE
SM280666,127.0833,36.5,Gongju,ADM2,DISTRICT
SM267411,-39.6014,-14.3,Gongogi,ADM2,DISTRICT
SM231631,-0.7276,8.488,Gonja,LANGUAGE,LANGUAGE
SM251030,-0.69,9.54,Gonja,ETHNICITY,ETHNICITY
SM269474,-3.0707,7.8713,Gontougo,ADM2,DISTRICT
SM263465,-42.4951,-18.8787,Gonzaga,ADM2,DISTRICT
SM302881,-97.4925,29.4567,Gonzales,ADM2,DISTRICT
SM273561,-77.5876,0.0846,Gonzalo Pizarro,ADM2,DISTRICT
SM273474,-79.45,-4.25,Gonzanamá,ADM2,DISTRICT
SM282641,-98.5325,22.7554,González,ADM2,DISTRICT
SM269891,-73.4003,8.3674,González,ADM2,DISTRICT
SM263464,-45.8378,-22.6748,Gonçalves,ADM2,DISTRICT
SM262995,-44.2866,-5.1567,Gonçalves Dias,ADM2,DISTRICT
SM231635,-7.5626,7.581,Goo,LANGUAGE,LANGUAGE
SM303017,-77.9164,37.722,Goochland,ADM2,DISTRICT
SM12369,-77.5709,18.2081,Good Intent,ADM2,DISTRICT
SM12370,-78.1284,18.2422,Gooden's River,ADM2,DISTRICT
SM253648,150.24,-9.41,Goodenough Island,ETHNICITY,ETHNICITY
SM301355,-92.7226,44.4099,Goodhue,ADM2,DISTRICT
SM300560,-114.8115,42.9709,Gooding,ADM2,DISTRICT
SM17400,-60.9984,13.9912,Goodlands,ADM2,DISTRICT
SM12371,-77.7565,18.4695,Goodwill,ADM2,DISTRICT
SM15163,5.1337,52.2974,Gooise Meren,ADM2,DISTRICT
SM261385,116.7914,-31.2346,Goomalling,ADM2,DISTRICT
SM261630,150.3135,-28.4622,Goondiwindi,ADM2,DISTRICT
SM231637,126.351,-18.4073,Gooniyandi,LANGUAGE,LANGUAGE
SM458655,126.179,-18.591,Gooniyandi,ETHNICITY,ETHNICITY
SM277405,84.3333,26.5,Gopalganj,ADM2,DISTRICT
SM262252,89.9167,23.1667,Gopalganj,ADM2,DISTRICT
SM231666,17.0713,8.0841,Gor,LANGUAGE,LANGUAGE
SM293923,74.0833,55.5,Gor'kovskiy rayon,ADM2,DISTRICT
SM277294,83.25,26.9167,Gorakhpur,ADM2,DISTRICT
SM231641,146.579,-6.8556,Gorakor,LANGUAGE,LANGUAGE
SM261923,46.6667,40.5833,Goranboy,ADM1:ADM2,DISTRICT
SM231640,128.261,2.1306,Gorap,LANGUAGE,LANGUAGE
SM290825,28.0702,46.9006,Gorban,ADM2,DISTRICT
SM292567,26.8814,47.7745,Gorbanesti,ADM2,DISTRICT
SM295033,31.9167,52.9167,Gordeevskiy rayon,ADM2,DISTRICT
SM300500,-84.8758,34.5034,Gordon,ADM2,DISTRICT
SM12372,-76.7235,18.0376,Gordon Town,ADM2,DISTRICT
SM286501,168.9333,-46.0833,Gore,ADM2,DISTRICT
SM253654,118.86,-33.62,Goreng,ETHNICITY,ETHNICITY
SM253654,118.86,-33.62,Goreng,ETHNICITY,ETHNICITY
SM253654,118.4408,-34.0111,Goreng,ETHNICITY,ETHNICITY
SM253654,118.4408,-34.0111,Goreng,ETHNICITY,ETHNICITY
SM231651,119.0123,-33.7964,Goreng,DIALECT,LANGUAGE
SM296203,14.1256,46.1167,Gorenja Vas-Poljane,ADM1:ADM2,DISTRICT
SM1356,-12.8333,16,Gorgol,ADM1,DISTRICT
SM291530,26.0773,44.784,Gorgota,ADM2,DISTRICT
SM285648,4.9771,51.8387,Gorinchem,ADM2,DISTRICT
SM253657,128.38,-16.89,Gorindji,ETHNICITY,ETHNICITY
SM253657,128.38,-16.89,Gorindji,ETHNICITY,ETHNICITY
SM253657,129.8076,-17.4621,Gorindji,ETHNICITY,ETHNICITY
SM253657,129.8076,-17.4621,Gorindji,ETHNICITY,ETHNICITY
SM11307,44.1167,42.0667,Goris Munitsip'alit'et'I (excl. Tskhinvali),ADM2,DISTRICT
SM277956,13.502,45.8655,Gorizia,ADM2,DISTRICT
SM305360,16.6786,46.3836,Goričan,ADM2,DISTRICT
SM1358,23.2828,45.0464,Gorj,ADM1,DISTRICT
SM305370,18.3732,45.3968,Gorjani,ADM2,DISTRICT
SM4126,14.068,46.3792,Gorje,ADM1,DISTRICT
SM375355,84.8409,28.2739,Gorkha,ADM2:ADM3,DISTRICT
SM288946,21.1684,49.6645,Gorlice,ADM2,DISTRICT
SM262418,23.8,42.7167,Gorna Malina,ADM2,DISTRICT
SM262468,25.75,43.2167,Gorna Orhyahovitsa,ADM2,DISTRICT
SM291150,24.7338,46.6653,Gornesti,ADM2,DISTRICT
SM291531,26.0805,45.1364,Gornet,ADM2,DISTRICT
SM291532,26.2601,45.0902,Gornet-cricov,ADM2,DISTRICT
SM4159,15.9594,46.6349,Gornja Radgona  (2006-),ADM1,DISTRICT
SM305722,16.3908,46.1098,Gornja Rijeka,ADM2,DISTRICT
SM276066,16.0183,45.9755,Gornja Stubica,ADM2,DISTRICT
SM305580,18.0647,45.1539,Gornja Vrba,ADM2,DISTRICT
SM305562,17.2345,45.2557,Gornji Bogićevci,ADM2,DISTRICT
SM296237,14.8008,46.2892,Gornji Grad,ADM1:ADM2,DISTRICT
SM305522,16.3755,46.2502,Gornji Kneginec,ADM2,DISTRICT
SM305361,16.324,46.4295,Gornji Mihaljevec,ADM2,DISTRICT
SM296380,16.2133,46.8175,Gornji Petrovci,ADM1:ADM2,DISTRICT
SM1359,87,51,Gorno-Altay,ADM1,DISTRICT
SM292870,85.96,51.96,Gorno-Altaysk,ADM2,DISTRICT
SM1360,73,38,Gorno-Badakhshan,ADM1,DISTRICT
SM16895,46.75,56.4167,Gornomariyskiy Rayon,ADM2,DISTRICT
SM294338,126,62.3333,Gornyy rayon,ADM2,DISTRICT
SM13188,76.9167,52.0333,Gorod Aksu,ADM2,DISTRICT
SM16897,19.9167,54.65,Gorod Baltiysk,ADM2,DISTRICT
SM16898,53.2833,54.3833,Gorod Bavly,ADM2,DISTRICT
SM16899,48.285,54.9669,Gorod Buinsk,ADM2,DISTRICT
SM18385,71.2392,41,Gorod Chust,ADM2,DISTRICT
SM16900,20.4167,54.7167,Gorod Kaliningrad,ADM2,DISTRICT
SM16901,49.1333,55.75,Gorod Kazan',ADM2,DISTRICT
SM16902,20.2333,54.95,Gorod Pionerskiy,ADM2,DISTRICT
SM16903,20.1667,54.95,Gorod Svetlogorsk,ADM2,DISTRICT
SM16904,20.1167,54.6667,Gorod Svetlyy,ADM2,DISTRICT
SM16905,58.25,55,Gorod Ust'-Katav,ADM2,DISTRICT
SM16906,45.45,44.7,Gorod Yuzhno-Sukhokumsk,ADM2,DISTRICT
SM16908,43.75,56.75,Gorodetskiy Rayon,ADM2,DISTRICT
SM16909,44.4667,48.8,Gorodishchenskiy Rayon,ADM2,DISTRICT
SM293060,42,46.0833,Gorodovikovskiy rayon,ADM2,DISTRICT
SM18208,33.691,46.1087,Gorodskoy okrug Armyansk,ADM2,DISTRICT
SM18210,33.7967,45.9533,Gorodskoy okrug Krasnoperekopsk,ADM2,DISTRICT
SM18212,34.1634,44.4964,Gorodskoy okrug Yalta,ADM2,DISTRICT
SM18213,33.3634,45.1956,Gorodskoy okrug Yevpatoriya,ADM2,DISTRICT
SM288633,145.3833,-6.05,Goroka,ADM2,DISTRICT
SM253660,145.04,-6.44,Goroka,ETHNICITY,ETHNICITY
SM294889,42.6667,56.25,Gorokhovetskiy rayon,ADM2,DISTRICT
SM305245,31.3637,-17.807,Goromonzi,ADM2,DISTRICT
SM283975,34.238,-18.609,Gorongosa,ADM2,DISTRICT
SM231658,122.288,0.6269,Gorontalo,LANGUAGE,LANGUAGE
SM276735,122.7159,0.7021,Gorontalo,ADM2,DISTRICT
SM1361,122.4704,0.693,Gorontalo,ADM1,DISTRICT
SM276734,123.0489,0.8651,Gorontalo Utara,ADM2,DISTRICT
SM253661,112.19,1.86,Gorontaloan,ETHNICITY,ETHNICITY
SM285237,5.8005,13.3908,Goronyo,ADM2,DISTRICT
SM253662,-48.92,-8.73,Gorotire,ETHNICITY,ETHNICITY
SM231660,144.59,-4.5295,Gorovu,LANGUAGE,LANGUAGE
SM231661,35.7996,-4.2358,Gorowa,LANGUAGE,LANGUAGE
SM250263,36.08,-3.16,Gorowa,ETHNICITY,ETHNICITY
SM250263,36.08,-3.16,Gorowa,ETHNICITY,ETHNICITY
SM250263,36.08,-3.16,Gorowa,ETHNICITY,ETHNICITY
SM250263,36,-4,Gorowa,ETHNICITY,ETHNICITY
SM250263,36,-4,Gorowa,ETHNICITY,ETHNICITY
SM250263,36,-4,Gorowa,ETHNICITY,ETHNICITY
SM250263,35.8,-4.3,Gorowa,ETHNICITY,ETHNICITY
SM250263,35.8,-4.3,Gorowa,ETHNICITY,ETHNICITY
SM250263,35.8,-4.3,Gorowa,ETHNICITY,ETHNICITY
SM293524,38,51.5,Gorshechenskiy rayon,ADM2,DISTRICT
SM290020,21.7705,45.183,Goruia,ADM2,DISTRICT
SM242216,83.9723,19.075,Gorum-Parenga,LANGUAGE,LANGUAGE
SM280753,128.2971,35.7496,Goryeong,ADM2,DISTRICT
SM288921,15.0851,52.7386,Gorzów,ADM2,DISTRICT
SM288915,15.2171,52.7306,Gorzów Wielkopolski,ADM2,DISTRICT
SM278925,135.722,34.4367,Gose,ADM2,DISTRICT
SM278956,139.2263,37.6564,Gosen,ADM2,DISTRICT
SM280694,128.4676,38.3795,Goseong,ADM2,DISTRICT
SM280558,128.2824,35.0148,Goseong,ADM2,DISTRICT
SM9529,66.5173,36.0856,Gosfandi,ADM2,DISTRICT
SM303316,-104.3533,42.0879,Goshen,ADM2,DISTRICT
SM12373,-77.6414,18.0496,Goshen,ADM2,DISTRICT
SM278998,140.47,40.9166,Goshogawara,ADM2,DISTRICT
SM260132,70.867,34.4484,Goshta,ADM2,DISTRICT
SM253665,-113.29,39.72,Gosiute,ETHNICITY,ETHNICITY
SM253665,-113.29,39.72,Gosiute,ETHNICITY,ETHNICITY
SM253665,-113.29,39.72,Gosiute,ETHNICITY,ETHNICITY
SM253665,-114,40,Gosiute,ETHNICITY,ETHNICITY
SM253665,-114,40,Gosiute,ETHNICITY,ETHNICITY
SM253665,-114,40,Gosiute,ETHNICITY,ETHNICITY
SM253665,-114,39.88,Gosiute,ETHNICITY,ETHNICITY
SM253665,-114,39.88,Gosiute,ETHNICITY,ETHNICITY
SM253665,-114,39.88,Gosiute,ETHNICITY,ETHNICITY
SM261386,116.002,-32.0676,Gosnells,ADM2,DISTRICT
SM301708,-99.8307,40.5148,Gosper,ADM2,DISTRICT
SM276068,15.3727,44.5465,Gospić,ADM2,DISTRICT
SM295271,-15.8467,14.4918,Gossas,ADM2,DISTRICT
SM291419,24.5328,44.0803,Gostavatu,ADM2,DISTRICT
SM290572,26.2379,44.1777,Gostinari,ADM2,DISTRICT
SM290573,26.123,43.9974,Gostinu,ADM2,DISTRICT
SM1362,20.8322,41.7931,Gostivar,ADM1,DISTRICT
SM288984,19.6219,52.3899,Gostynin,ADM2,DISTRICT
SM288816,17.0347,51.8368,Gostyń,ADM2,DISTRICT
SM279313,138.879,35.2989,Gotemba,ADM2,DISTRICT
SM15243,1.4359,13.8044,Gotheye,ADM2,DISTRICT
SM231670,29.9786,46.9304,Gothic,LANGUAGE,LANGUAGE
SM296628,18.5,57.5,Gotland,ADM2,DISTRICT
SM1363,18.5,57.5,Gotland,ADM1,DISTRICT
SM262297,23.6833,41.6333,Gotse Delchev,ADM2,DISTRICT
SM231671,14.86,45.64,Gottscheerisch,DIALECT,LANGUAGE
SM11770,48.8,32.2167,Gotvand,ADM2,DISTRICT
SM278880,128.7687,32.6989,Gotō,ADM2,DISTRICT
SM297979,9.7008,36.5272,Goubellat,ADM2,DISTRICT
SM285649,4.7079,52.0192,Gouda,ADM2,DISTRICT
SM10734,32.4467,34.9933,Goudi,ADM2,DISTRICT
SM295257,-12.912,13.9777,Goudiry,ADM2,DISTRICT
SM295254,-15.8722,12.5778,Goudomp,ADM2,DISTRICT
SM15244,11.2474,13.7463,Goudoumaria,ADM2,DISTRICT
SM17401,-60.9396,13.8065,Gouette,ADM2,DISTRICT
SM10735,33.6932,35.2839,Goufes,ADM2,DISTRICT
SM261541,149.902,-34.9025,Goulburn Mulwaree,ADM2,DISTRICT
SM253670,13.29,18.68,Gounda,ETHNICITY,ETHNICITY
SM283387,-4.9931,18.6004,Goundam,ADM2,DISTRICT
SM427812,17.372,9.4125,Goundi,ADM2:ADM3,DISTRICT
SM231680,16.255,9.4668,Goundo,LANGUAGE,LANGUAGE
SM14045,-9.9531,7.2269,Gounwolaila District,ADM2,DISTRICT
SM262217,0.5,12.0833,Gourma (1995-),ADM1:ADM2,DISTRICT
SM283388,-1.7698,16.0798,Gourma-Rharous,ADM2,DISTRICT
SM231682,1.2849,11.6711,Gourmanchéma,LANGUAGE,LANGUAGE
SM250264,1.27,11.09,Gourmatches,ETHNICITY,ETHNICITY
SM250264,1.27,11.09,Gourmatches,ETHNICITY,ETHNICITY
SM250264,1.63,12.92,Gourmatches,ETHNICITY,ETHNICITY
SM250264,1.63,12.92,Gourmatches,ETHNICITY,ETHNICITY
SM250264,2,12,Gourmatches,ETHNICITY,ETHNICITY
SM250264,2,12,Gourmatches,ETHNICITY,ETHNICITY
SM253674,142.46,-38,Gournditch-Mara,ETHNICITY,ETHNICITY
SM253674,142.46,-38,Gournditch-Mara,ETHNICITY,ETHNICITY
SM253674,141.5397,-38.1556,Gournditch-Mara,ETHNICITY,ETHNICITY
SM253674,141.5397,-38.1556,Gournditch-Mara,ETHNICITY,ETHNICITY
SM10294,19.6208,19.5303,Gouro,ADM2,DISTRICT
SM250276,-5.61,6.55,Gouro,ETHNICITY,ETHNICITY
SM250276,-5.61,6.55,Gouro,ETHNICITY,ETHNICITY
SM250276,-6,7,Gouro,ETHNICITY,ETHNICITY
SM250276,-6,7,Gouro,ETHNICITY,ETHNICITY
SM253675,19.99,20.33,Gouroa,ETHNICITY,ETHNICITY
SM10736,33.1573,34.9579,Gourri,ADM2,DISTRICT
SM263466,-43.8536,-18.5358,Gouvea,ADM2,DISTRICT
SM289238,-7.5905,40.5083,Gouveia,ADM2,DISTRICT
SM268282,-50.1755,-18.5078,Gouvelândia,ADM2,DISTRICT
SM300914,-100.4829,38.9161,Gove,ADM2,DISTRICT
SM262996,-44.2051,-4.984,Governador Archer,ADM2,DISTRICT
SM266430,-48.5791,-27.372,Governador Celso Ramos,ADM2,DISTRICT
SM265549,-37.5119,-5.4087,Governador Dix-Sept Rosad,ADM2,DISTRICT
SM262997,-47.3254,-5.7431,Governador Edison Lobão,ADM2,DISTRICT
SM262998,-43.8803,-5.4258,Governador Eugênio Barros,ADM2,DISTRICT
SM266083,-63.0443,-10.7846,Governador Jorge Teixeira,ADM2,DISTRICT
SM9928,-40.4951,-19.2003,Governador Lindenberg Municipality,ADM2:ADM3,DISTRICT
SM263000,-44.1115,-5.5283,Governador Luiz Rocha,ADM2,DISTRICT
SM267412,-39.0311,-12.5772,Governador Mangabeira,ADM2,DISTRICT
SM263001,-45.6571,-3.368,Governador Newton Bello,ADM2,DISTRICT
SM263002,-45.8428,-2.0099,Governador Nunes Freire,ADM2,DISTRICT
SM263467,-41.9903,-18.7907,Governador Valadares,ADM2,DISTRICT
SM17783,43.95,25.9333,Governorate of Unaizah,ADM2,DISTRICT
SM1364,96,45.5,Govi-Altay,ADM1,DISTRICT
SM283823,107.4909,46.0342,Govi-Ugtaal,ADM2,DISTRICT
SM1365,108.5,46.5,Govisümber,ADM1,DISTRICT
SM283907,34.7408,-21.2777,Govuro,ADM2,DISTRICT
SM276590,119.75,-5.3167,Gowa,ADM2,DISTRICT
SM251029,29.71,-16.21,Gowa,ETHNICITY,ETHNICITY
SM253677,78.93,20.54,Gowari Gond,ETHNICITY,ETHNICITY
SM231693,77.2647,21.5096,Gowlan,LANGUAGE,LANGUAGE
SM231692,77.3733,21.7726,Gowli,LANGUAGE,LANGUAGE
SM231694,73.1233,35.1243,Gowro,LANGUAGE,LANGUAGE
SM261149,-59.5,-29.5,Goya,ADM2,DISTRICT
SM280731,126.8333,37.6667,Goyang,ADM2,DISTRICT
SM261695,139.0166,-33.7164,Goyder,ADM2,DISTRICT
SM268446,90.9162,26.9651,Gozhing,ADM2,DISTRICT
SM288785,22.3719,54.2609,Gołdap,ADM2,DISTRICT
SM17402,-60.9664,13.7768,Grace,ADM2,DISTRICT
SM267092,-37.2039,-10.235,Gracho Cardoso,ADM2,DISTRICT
SM275721,-88.6155,14.6075,Gracias,ADM2,DISTRICT
SM1366,-84.3333,15.25,Gracias a Dios,ADM1,DISTRICT
SM295477,165.8611,-10.7412,Graciosa Bay,ADM2,DISTRICT
SM296381,16.0983,46.7869,Grad,ADM1:ADM2,DISTRICT
SM1367,20.473,44.7974,Grad Beograd,ADM1:ADM2:ADMX,DISTRICT
SM10505,13.6667,45.4167,Grad Buje,ADM2,DISTRICT
SM10506,17.0667,46.0333,Grad GJurgevac,ADM2,DISTRICT
SM10507,13.6667,45.0833,Grad Rovinj,ADM2,DISTRICT
SM4180,21.4289,42.005,Grad Skopje,ADM1,DISTRICT
SM1368,23.3833,42.6667,Grad Sofiya,ADM1,DISTRICT
SM10508,13.5564,45.4375,Grad Umag,ADM2,DISTRICT
SM10509,13.8441,44.9668,Grad Vodnjan,ADM2,DISTRICT
SM1369,15.9773,45.8131,Grad Zagreb,ADM1,DISTRICT
SM10510,18.6951,45.0723,Grad Zupanja (2006-),ADM2,DISTRICT
SM305500,17.3427,43.108,Gradac,ADM2,DISTRICT
SM305632,16.4802,45.9132,Gradec,ADM2,DISTRICT
SM305531,17.5111,45.8542,Gradina,ADM2,DISTRICT
SM291420,24.2776,44.5674,Gradinari,ADM2,DISTRICT
SM290574,25.817,44.3955,Gradinari,ADM2,DISTRICT
SM290021,21.6058,45.1049,Gradinari,ADM2,DISTRICT
SM292192,23.8162,44.8993,Gradistea,ADM2,DISTRICT
SM290961,26.3145,44.6656,Gradistea,ADM2,DISTRICT
SM292675,27.3819,45.2483,Gradistea,ADM2,DISTRICT
SM289950,27.1616,44.2195,Gradistea,ADM2,DISTRICT
SM305559,18.7061,45.1494,Gradište,ADM2,DISTRICT
SM10511,15.9007,45.729,Gradska cetvrt Brezovica,ADM2,DISTRICT
SM10512,15.9387,45.8265,Gradska cetvrt Crnomerec,ADM2,DISTRICT
SM10513,16.0526,45.8234,Gradska cetvrt Donja Dubrava,ADM2,DISTRICT
SM10514,15.9695,45.8083,Gradska cetvrt Donji grad,ADM2,DISTRICT
SM10515,16.0539,45.8418,Gradska cetvrt Gornja Dubrava,ADM2,DISTRICT
SM10516,15.9794,45.8282,Gradska cetvrt Gornji Grad - Medvescak,ADM2,DISTRICT
SM10517,16.0113,45.8369,Gradska cetvrt Maksimir,ADM2,DISTRICT
SM10518,15.986,45.7836,Gradska cetvrt Novi Zagreb - istok,ADM2,DISTRICT
SM10519,15.959,45.7783,Gradska cetvrt Novi Zagreb - zapad,ADM2,DISTRICT
SM10520,16.0227,45.8053,Gradska cetvrt Pescenica - Zitnjak,ADM2,DISTRICT
SM10521,15.98,45.861,Gradska cetvrt Podsljeme,ADM2,DISTRICT
SM10522,15.8716,45.8057,Gradska cetvrt Stenjevec,ADM2,DISTRICT
SM10523,15.9067,45.7906,Gradska cetvrt Tresnjevka - jug,ADM2,DISTRICT
SM10524,15.937,45.7979,Gradska cetvrt Tresnjevka - sjever,ADM2,DISTRICT
SM10525,15.9796,45.797,Gradska cetvrt Trnje,ADM2,DISTRICT
SM1370,21.9167,41.6,Gradsko,ADM1,DISTRICT
SM302190,-97.8841,35.0169,Grady,ADM2,DISTRICT
SM300501,-84.2344,30.8747,Grady,ADM2,DISTRICT
SM301808,-71.8206,43.9407,Grafton,ADM2,DISTRICT
SM301962,-83.8336,35.3502,Graham,ADM2,DISTRICT
SM300915,-99.8833,39.3497,Graham,ADM2,DISTRICT
SM301798,-109.8874,32.9327,Graham,ADM2,DISTRICT
SM327918,-98.5424,51.375,Grahamdale,ADM2:ADM3,DISTRICT
SM302565,-83.5096,36.2763,Grainger,ADM2,DISTRICT
SM263004,-45.9838,-5.8105,Grajaú,ADM2,DISTRICT
SM290826,27.5557,46.9707,Grajduri,ADM2,DISTRICT
SM288691,22.49,53.5551,Grajewo,ADM2,DISTRICT
SM262483,22.65,43.8333,Gramada,ADM2,DISTRICT
SM265688,-50.9032,-29.3625,Gramado,ADM2,DISTRICT
SM265687,-52.612,-29.2866,Gramado Xavier,ADM2,DISTRICT
SM265686,-52.9171,-27.4544,Gramado dos Loureiros,ADM2,DISTRICT
SM270367,-72.822,7.8785,Gramalote,ADM2,DISTRICT
SM291800,26.145,47.8974,Gramesti,ADM2,DISTRICT
SM260484,20.25,40.8333,Gramshit,ADM2,DISTRICT
SM260484,20.25,40.8333,Gramshit,ADM2,DISTRICT
SM260484,20.2364,40.8469,Gramshit,ADM2,DISTRICT
SM260484,20.2364,40.8469,Gramshit,ADM2,DISTRICT
SM13529,21.6565,56.3576,Gramzdas pagasts,ADM2,DISTRICT
SM286122,10.5605,60.3929,Gran,ADM2,DISTRICT
SM262779,-63.105,-21.543,Gran Chaco,ADM2,DISTRICT
SM286759,-78.6472,-7.5798,Gran Chimú,ADM2,DISTRICT
SM283228,-106.4936,28.2484,Gran Morelos,ADM2,DISTRICT
SM303859,-61.1333,4.6167,Gran Sabana,ADM2,DISTRICT
SM274035,-3.25,37.25,Granada,ADM2,DISTRICT
SM285537,-85.9743,11.9485,Granada,ADM2,DISTRICT
SM270272,-73.7621,3.4859,Granada,ADM2,DISTRICT
SM10412,-74.3514,4.5186,Granada,ADM2,DISTRICT
SM270219,-75.1405,6.1141,Granada,ADM2,DISTRICT
SM1371,-85.9167,11.8333,Granada,ADM1,DISTRICT
SM282568,-109.3152,29.7877,Granados,ADM2,DISTRICT
SM275344,-90.5756,14.9314,Granados,ADM2,DISTRICT
SM302894,-109.5697,38.9817,Grand,ADM2,DISTRICT
SM303336,-106.1184,40.1026,Grand,ADM2,DISTRICT
SM1375,-11.05,7.1333,Grand Cape Mount,ADM1,DISTRICT
SM1377,-78.3306,27.2292,Grand Cay,ADM1,DISTRICT
SM1378,5.8667,48.6982,Grand Est,ADM1,DISTRICT
SM302039,-97.4569,47.9219,Grand Forks,ADM2,DISTRICT
SM302933,-73.2948,44.7968,Grand Isle,ADM2,DISTRICT
SM1379,57.6333,-20.4167,Grand Port,ADM1,DISTRICT
SM17403,-60.9515,14.0355,Grand Riviere,ADM2,DISTRICT
SM301271,-85.5522,44.7162,Grand Traverse,ADM2,DISTRICT
SM454406,139,-4,Grand Valley Dani,ETHNICITY,ETHNICITY
SM1372,55.4583,-4.6833,Grand' Anse,ADM1,DISTRICT
SM1373,-74.0833,18.5,Grand'Anse (2003-),ADM1,DISTRICT
SM1374,55.7167,-4.3167,Grand'Anse Praslin,ADM1,DISTRICT
SM262177,1.8333,6.2833,Grand-Popo,ADM2,DISTRICT
SM1381,-9.8333,6.2333,GrandBassa,ADM1,DISTRICT
SM1382,-8.2,5.9667,GrandGedeh,ADM1,DISTRICT
SM1383,-8.2167,4.7833,GrandKru,ADM1,DISTRICT
SM324201,-119.1199,53.8773,Grande Cache,ADM2:ADM3,DISTRICT
SM323795,-118.8331,55.1832,Grande Prairie,ADM2:ADM3,DISTRICT
SM17404,-60.934,13.9426,Grande Ravine,ADM2,DISTRICT
SM17405,-60.9324,13.9331,Grande Riviere,ADM2,DISTRICT
SM17406,-60.9512,14.0398,Grande Riviere/Assou Canal,ADM2,DISTRICT
SM17407,-60.9506,14.0277,Grande Riviere/Degazon,ADM2,DISTRICT
SM17408,-60.935,13.9338,Grande Riviere/Des Branch,ADM2,DISTRICT
SM17409,-60.9346,13.9322,Grande Riviere/En Leur Morne/Discompere,ADM2,DISTRICT
SM17410,-60.9331,13.9324,Grande Riviere/Funier,ADM2,DISTRICT
SM17411,-60.9559,14.0309,Grande Riviere/Ingle Woods,ADM2,DISTRICT
SM17412,-60.9379,13.9296,Grande Riviere/Morne Caca Cochon,ADM2,DISTRICT
SM17413,-60.9557,14.0293,Grande Riviere/Morne Elwin,ADM2,DISTRICT
SM17414,-60.9606,14.0351,Grande Riviere/Morne Serpent,ADM2,DISTRICT
SM17415,-60.9493,14.0432,Grande Riviere/Norbert,ADM2,DISTRICT
SM17416,-60.9436,14.0393,Grande Riviere/Piat,ADM2,DISTRICT
SM17417,-60.9521,14.0417,Grande Riviere/Postlewaithe,ADM2,DISTRICT
SM17418,-60.9556,14.0362,Grande Riviere/White Rock,ADM2,DISTRICT
SM296808,18.58,8.43,Grande Sido,ADM2,DISTRICT
SM276098,-72.1473,19.517,Grande-Rivière du Nord,ADM2,DISTRICT
SM264478,-51.4346,-24.1865,Grandes Rios,ADM2,DISTRICT
SM269489,-4.3925,5.3049,Grands Ponts,ADM2,DISTRICT
SM324000,-114.0587,52.9921,Grandview,ADM2:ADM3,DISTRICT
SM10179,-100.8134,51.2,Grandview (2015-),ADM2,DISTRICT
SM286061,13.385,65.5387,Grane,ADM2,DISTRICT
SM261078,-65.3333,-27.75,Graneros,ADM2,DISTRICT
SM231699,70.7323,34.6485,Grangali,LANGUAGE,LANGUAGE
SM231699,70.7323,34.6485,Grangali,LANGUAGE,LANGUAGE
SM231699,70.7033,34.9455,Grangali,LANGUAGE,LANGUAGE
SM231699,70.7033,34.9455,Grangali,LANGUAGE,LANGUAGE
SM12374,-78.0804,18.3316,Grange,ADM2,DISTRICT
SM12375,-78.184,18.3308,Grange Hill,ADM2,DISTRICT
SM290503,21.3367,46.5054,Graniceri,ADM2,DISTRICT
SM291801,26.0686,47.7766,Granicesti,ADM2,DISTRICT
SM301634,-113.4403,46.4044,Granite,ADM2,DISTRICT
SM264992,-39.6402,-7.7438,Granito,ADM2,DISTRICT
SM267925,-40.9637,-3.3289,Granja,ADM2,DISTRICT
SM267926,-39.2794,-6.9175,Granjeiro,ADM2,DISTRICT
SM1384,-76.8667,20.2833,Granma,ADM1,DISTRICT
SM303111,-79.1956,39.1051,Grant,ADM2,DISTRICT
SM303235,-90.7062,42.8675,Grant,ADM2,DISTRICT
SM303073,-119.4518,47.2057,Grant,ADM2,DISTRICT
SM302252,-119.0074,44.4913,Grant,ADM2,DISTRICT
SM302495,-96.7677,45.1719,Grant,ADM2,DISTRICT
SM301835,-108.3823,32.739,Grant,ADM2,DISTRICT
SM302040,-101.6397,46.3583,Grant,ADM2,DISTRICT
SM302191,-97.7862,36.7961,Grant,ADM2,DISTRICT
SM301709,-101.7405,41.915,Grant,ADM2,DISTRICT
SM301356,-96.0122,45.9341,Grant,ADM2,DISTRICT
SM301044,-84.6246,38.6488,Grant,ADM2,DISTRICT
SM300916,-101.308,37.5622,Grant,ADM2,DISTRICT
SM300710,-85.6547,40.5158,Grant,ADM2,DISTRICT
SM301122,-92.5595,31.5997,Grant,ADM2,DISTRICT
SM302371,-92.4236,34.29,Grant,ADM2,DISTRICT
SM261696,140.6332,-37.8364,Grant,ADM2,DISTRICT
SM12376,-77.3964,18.1584,Grantham,ADM2,DISTRICT
SM12377,-76.7889,18.0326,Grants Pen,ADM2,DISTRICT
SM323865,-113.5106,49.8701,Granum,ADM2:ADM3,DISTRICT
SM301963,-78.653,36.304,Granville,ADM2,DISTRICT
SM18530,-77.894,18.4419,Granville (St. James),ADM2,DISTRICT
SM12378,-77.6885,18.4505,Granville (Trelawny,ADM2,DISTRICT
SM231702,147.437,-9.5122,Grass Koiari,LANGUAGE,LANGUAGE
SM17419,-60.9876,14.0071,Grass Street,ADM2,DISTRICT
SM286244,17.5319,68.6927,Gratangen,ADM2,DISTRICT
SM291891,25.441,44.4338,Gratia,ADM2,DISTRICT
SM301272,-84.6049,43.2927,Gratiot,ADM2,DISTRICT
SM286862,-72.5833,-14,Grau,ADM2,DISTRICT
SM1385,9.623,46.6587,Graubünden,ADM1,DISTRICT
SM266141,-49.0435,-28.3153,Gravataí,ADM2,DISTRICT
SM265689,-50.953,-29.9092,Gravataí,ADM2,DISTRICT
SM264994,-35.5175,-8.246,Gravatá,ADM2,DISTRICT
SM285921,5.7437,51.7397,Grave,ADM2,DISTRICT
SM12379,-77.3335,17.9097,Gravel Hill,ADM2,DISTRICT
SM13530,27.1539,56.0583,Graveru pagasts,ADM2,DISTRICT
SM301045,-88.6512,36.7231,Graves,ADM2,DISTRICT
SM302883,-100.8126,35.4012,Gray,ADM2,DISTRICT
SM300917,-100.4379,37.7382,Gray,ADM2,DISTRICT
SM303074,-123.8285,47.1445,Grays Harbor,ADM2,DISTRICT
SM303018,-81.2251,36.6566,Grayson,ADM2,DISTRICT
SM302884,-96.6777,33.6268,Grayson,ADM2,DISTRICT
SM301046,-86.3439,37.4608,Grayson,ADM2,DISTRICT
SM294698,35.6667,50.5,Grayvoronskiy rayon,ADM2,DISTRICT
SM261859,15.4333,47.0667,Graz,ADM2,DISTRICT
SM261858,15.3882,47.1386,Graz Umgebung,ADM2,DISTRICT
SM267924,-40.795,-4.0662,Graça,ADM2,DISTRICT
SM263003,-44.2517,-5.4449,Graça Aranha,ADM2,DISTRICT
SM305608,15.8471,44.2995,Gračac,ADM2,DISTRICT
SM305686,14.0098,45.2174,Gračišće,ADM2,DISTRICT
SM290575,26.3337,44.1259,Greaca,ADM2,DISTRICT
SM12380,-77.0851,18.4023,Great Pond,ADM2,DISTRICT
SM12381,-78.0502,18.4117,Great Valley,ADM2,DISTRICT
SM1386,0,5.75,Greater Accra,ADM1,DISTRICT
SM261233,144.4397,-36.7228,Greater Bendigo,ADM2,DISTRICT
SM261234,145.1907,-38.0011,Greater Dandenong,ADM2,DISTRICT
SM261235,144.4614,-38.0518,Greater Geelong,ADM2,DISTRICT
SM457947,115.7566,-28.4397,Greater Geraldton,ADM2,DISTRICT
SM261543,147.1973,-35.7584,Greater Hume,ADM2,DISTRICT
SM274632,-0.1667,51.5,Greater London,ADM1:ADM2,DISTRICT
SM280933,-10.7336,6.3142,Greater Monrovia,ADM1:ADM2,DISTRICT
SM12382,-76.9113,17.9523,Greater Portmore,ADM2,DISTRICT
SM261236,145.4152,-36.4274,Greater Shepparton,ADM2,DISTRICT
SM268845,-80.9997,46.5001,Greater Sudbury,ADM2,DISTRICT
SM268748,-123.0026,49.333,Greater Vancouver,ADM2,DISTRICT
SM289879,26.9663,45.3879,Grebanu,ADM2,DISTRICT
SM291152,24.276,46.6132,Grebenisu De Campie,ADM2,DISTRICT
SM250266,-8.04,4.96,Grebo,ETHNICITY,ETHNICITY
SM290373,23.2639,44.4547,Grecesti,ADM2,DISTRICT
SM292044,28.2385,45.1913,Greci,ADM2,DISTRICT
SM291073,23.1075,44.5716,Greci,ADM2,DISTRICT
SM10498,-84.3146,10.0689,Grecia (2018-),ADM2,DISTRICT
SM253682,22.25,41.78,Greek,ETHNICITY,ETHNICITY
SM253682,22.25,41.78,Greek,ETHNICITY,ETHNICITY
SM253682,22.25,41.78,Greek,ETHNICITY,ETHNICITY
SM253682,22.25,41.78,Greek,ETHNICITY,ETHNICITY
SM253682,22.25,41.78,Greek,ETHNICITY,ETHNICITY
SM253682,23,39,Greek,ETHNICITY,ETHNICITY
SM253682,23,39,Greek,ETHNICITY,ETHNICITY
SM253682,23,39,Greek,ETHNICITY,ETHNICITY
SM253682,23,39,Greek,ETHNICITY,ETHNICITY
SM253682,23,39,Greek,ETHNICITY,ETHNICITY
SM253682,23.7167,37.9667,Greek,ETHNICITY,ETHNICITY
SM253682,23.7167,37.9667,Greek,ETHNICITY,ETHNICITY
SM253682,23.7167,37.9667,Greek,ETHNICITY,ETHNICITY
SM253682,23.7167,37.9667,Greek,ETHNICITY,ETHNICITY
SM253682,23.7167,37.9667,Greek,ETHNICITY,ETHNICITY
SM253682,22.94,40.64,Greek,ETHNICITY,ETHNICITY
SM253682,22.94,40.64,Greek,ETHNICITY,ETHNICITY
SM253682,22.94,40.64,Greek,ETHNICITY,ETHNICITY
SM253682,22.94,40.64,Greek,ETHNICITY,ETHNICITY
SM253682,22.94,40.64,Greek,ETHNICITY,ETHNICITY
SM253682,23.7,37.9,Greek,ETHNICITY,ETHNICITY
SM253682,23.7,37.9,Greek,ETHNICITY,ETHNICITY
SM253682,23.7,37.9,Greek,ETHNICITY,ETHNICITY
SM253682,23.7,37.9,Greek,ETHNICITY,ETHNICITY
SM253682,23.7,37.9,Greek,ETHNICITY,ETHNICITY
SM231728,23.3762,38.0536,Greek Sign Language,LANGUAGE,LANGUAGE
SM301710,-98.5212,41.5674,Greeley,ADM2,DISTRICT
SM300918,-101.806,38.4805,Greeley,ADM2,DISTRICT
SM303236,-89.6022,42.68,Green,ADM2,DISTRICT
SM301047,-85.5531,37.2641,Green,ADM2,DISTRICT
SM17420,-60.9528,13.996,Green Gold,ADM2,DISTRICT
SM12383,-78.2702,18.384,Green Island,ADM2,DISTRICT
SM303237,-89.0449,43.8004,Green Lake,ADM2,DISTRICT
SM12384,-77.8778,18.4736,Green Pond,ADM2,DISTRICT
SM303112,-80.453,37.9469,Greenbrier,ADM2,DISTRICT
SM12385,-76.9483,18.0125,Greendale,ADM2,DISTRICT
SM303019,-78.4669,38.2976,Greene,ADM2,DISTRICT
SM302308,-80.2229,39.8538,Greene,ADM2,DISTRICT
SM302567,-82.8458,36.1754,Greene,ADM2,DISTRICT
SM302104,-83.8899,39.6915,Greene,ADM2,DISTRICT
SM301879,-74.1227,42.2765,Greene,ADM2,DISTRICT
SM301965,-77.6759,35.4854,Greene,ADM2,DISTRICT
SM301439,-88.6392,31.2142,Greene,ADM2,DISTRICT
SM301555,-93.342,37.2581,Greene,ADM2,DISTRICT
SM300711,-86.9621,39.0363,Greene,ADM2,DISTRICT
SM300814,-94.3968,42.0362,Greene,ADM2,DISTRICT
SM300616,-90.3905,39.3562,Greene,ADM2,DISTRICT
SM300502,-83.1667,33.5788,Greene,ADM2,DISTRICT
SM302372,-90.5591,36.1177,Greene,ADM2,DISTRICT
SM300271,-87.9522,32.8531,Greene,ADM2,DISTRICT
SM301799,-109.2401,33.2154,Greenlee,ADM2,DISTRICT
SM303020,-77.5596,36.6758,Greensville,ADM2,DISTRICT
SM301048,-82.9223,38.5457,Greenup,ADM2,DISTRICT
SM12386,-77.5324,18.0393,Greenvale,ADM2,DISTRICT
SM302447,-82.3707,34.8943,Greenville,ADM2,DISTRICT
SM280948,-9.0465,5.0579,Greenville,ADM2,DISTRICT
SM12387,-76.8256,17.9844,Greenwich Town/ Newport West,ADM2,DISTRICT
SM302448,-82.1259,34.1538,Greenwood,ADM2,DISTRICT
SM300919,-96.2326,37.8778,Greenwood,ADM2,DISTRICT
SM12388,-77.7524,18.4977,Greenwood,ADM2,DISTRICT
SM302192,-99.5608,34.9357,Greer,ADM2,DISTRICT
SM302885,-94.817,32.4805,Gregg,ADM2,DISTRICT
SM302496,-99.1856,43.1924,Gregory,ADM2,DISTRICT
SM12389,-76.883,17.9949,Gregory Park,ADM2,DISTRICT
SM301440,-89.802,33.77,Grenada,ADM2,DISTRICT
SM231735,-61.6989,12.0067,Grenadian Creole English,LANGUAGE,LANGUAGE
SM1387,-61.1828,12.8781,Grenadines,ADM1,DISTRICT
SM231737,140.279,-2.7223,Gresi,LANGUAGE,LANGUAGE
SM276374,112.553,-7.1933,Gresik,ADM2,DISTRICT
SM271728,12.2506,55.5877,Greve,ADM2,DISTRICT
SM370478,6.4127,49.6746,Grevenmacher,ADM1:ADM2:ADM3,DISTRICT
SM281371,6.3431,49.6834,Grevenmacher,ADM1:ADM2,DISTRICT
SM286527,171.6625,-42.4094,Grey,ADM2,DISTRICT
SM327833,-98.0757,49.7111,Grey,ADM2:ADM3,DISTRICT
SM268846,-80.7497,44.3334,Grey,ADM2,DISTRICT
SM295003,42,51.5,Gribanovskiy rayon,ADM2,DISTRICT
SM271666,12.2417,56.0583,Gribskov,ADM2,DISTRICT
SM261841,13.8,48.2667,Grieskirchen,ADM2,DISTRICT
SM261545,146.023,-34.3345,Griffith,ADM2,DISTRICT
SM302042,-98.237,47.4573,Griggs,ADM2,DISTRICT
SM268565,19.9121,5.7355,Grimari,ADM2,DISTRICT
SM302886,-95.9855,30.5435,Grimes,ADM2,DISTRICT
SM323797,-117.6096,56.1905,Grimshaw,ADM2:ADM3,DISTRICT
SM286314,8.597,58.3462,Grimstad,ADM2,DISTRICT
SM277828,-22.2179,63.8843,Grindavík,ADM2,DISTRICT
SM292045,28.2003,45.4083,Grindu,ADM2,DISTRICT
SM290914,26.9095,44.7721,Grindu,ADM2,DISTRICT
SM253685,150.72,-32.75,Gringai,ETHNICITY,ETHNICITY
SM253685,150.72,-32.75,Gringai,ETHNICITY,ETHNICITY
SM253685,151.6993,-32.5135,Gringai,ETHNICITY,ETHNICITY
SM253685,151.6993,-32.5135,Gringai,ETHNICITY,ETHNICITY
SM291338,25.8764,47.0247,Grinties,ADM2,DISTRICT
SM253686,18.58,-31.32,Griqua,ETHNICITY,ETHNICITY
SM13531,27.4232,56.4875,Griskanu pagasts,ADM2,DISTRICT
SM265550,-37.2002,-4.9524,Groaíras,ADM2,DISTRICT
SM267927,-40.377,-3.9211,Groaíras,ADM2,DISTRICT
SM13532,21.1668,56.5388,Grobina,ADM2,DISTRICT
SM3956,21.2356,56.4869,Grobinas Novads,ADM1,DISTRICT
SM13533,21.1692,56.5082,Grobinas pagasts,ADM2,DISTRICT
SM276338,110.9167,-7.1167,Grobogan,ADM2,DISTRICT
SM9763,24,53.6667,Grodnenskiy Rayon (excl. Hrodna),ADM2,DISTRICT
SM288988,20.5941,52.0639,Grodzisk Mazowiecki,ADM2,DISTRICT
SM288817,16.3357,52.1604,Grodzisk Wielkopolski,ADM2,DISTRICT
SM291421,24.2607,43.7556,Grojdibodu,ADM2,DISTRICT
SM231742,88.8541,27.6089,Groma,LANGUAGE,LANGUAGE
SM297934,10.4384,36.5964,Grombalia,ADM2,DISTRICT
SM286467,12.3111,64.464,Grong,ADM2,DISTRICT
SM296102,-55.4774,5.7944,Groningen,ADM2,DISTRICT
SM1389,6.75,53.25,Groningen,ADM1,DISTRICT
SM231743,6.698,53.2842,Gronings,LANGUAGE,LANGUAGE
SM296079,-56.8874,5.8583,Groot Henar,ADM2,DISTRICT
SM235697,21.1793,-21.0554,Groot Laagte =/Kx'au//'ein,DIALECT,LANGUAGE
SM292676,27.8861,45.0733,Gropeni,ADM2,DISTRICT
SM290828,27.2638,47.3687,Gropnita,ADM2,DISTRICT
SM1390,-60.9449,14.0537,Gros Islet,ADM1,DISTRICT
SM17421,-60.9531,14.0808,Gros Islet Town,ADM2,DISTRICT
SM17422,-60.9497,14.0791,Gros Islet/Edge Water,ADM2,DISTRICT
SM17423,-61.0688,13.8086,Gros Piton,ADM2,DISTRICT
SM231745,-108.738,48.4834,Gros Ventre,LANGUAGE,LANGUAGE
SM276084,-72.85,19.65,Gros-Morne,ADM2,DISTRICT
SM269000,6.6573,46.6518,Gros-de-Vaud,ADM2,DISTRICT
SM370447,5.9672,49.8278,Grosbous,ADM1:ADM2:ADM3,DISTRICT
SM291009,23.5866,47.6119,Grosi,ADM2,DISTRICT
SM277910,11.25,42.8333,Grosseto,ADM2,DISTRICT
SM296321,14.6653,45.9364,Grosuplje,ADM1:ADM2,DISTRICT
SM284609,6.6958,13.6575,Groumdji,ADM2,DISTRICT
SM1391,-2.0492,49.1847,Grouville,ADM1,DISTRICT
SM12390,-77.5292,18.1047,Grove Place,ADM2,DISTRICT
SM12391,-77.4588,17.8861,Grove Town,ADM2,DISTRICT
SM291074,23.3321,44.6365,Grozesti,ADM2,DISTRICT
SM290829,28.0251,47.01,Grozesti,ADM2,DISTRICT
SM16910,45.5,43.3333,Groznenskiy Rayon,ADM2,DISTRICT
SM292732,45.6976,43.3102,Groznyy,ADM2,DISTRICT
SM305687,13.7215,45.3773,Grožnjan,ADM2,DISTRICT
SM275952,17.1667,45.7,Grubišno Polje,ADM2,DISTRICT
SM288865,18.9162,53.4715,Grudziądz,ADM2,DISTRICT
SM288864,18.7785,53.4725,Grudziądz (City),ADM2,DISTRICT
SM286375,12.0549,60.4513,Grue,ADM2,DISTRICT
SM291075,22.7295,44.2859,Gruia,ADM2,DISTRICT
SM290962,26.2213,44.7265,Gruiu,ADM2,DISTRICT
SM291339,26.3864,47.1473,Grumazesti,ADM2,DISTRICT
SM296515,13.0219,59.4041,Grums,ADM2,DISTRICT
SM302568,-85.7226,35.3884,Grundy,ADM2,DISTRICT
SM301557,-93.5653,40.1139,Grundy,ADM2,DISTRICT
SM300815,-92.7914,42.4019,Grundy,ADM2,DISTRICT
SM300617,-88.4185,41.2851,Grundy,ADM2,DISTRICT
SM13534,26.2324,57.4722,Grundzales pagasts,ADM2,DISTRICT
SM250992,-1.52,11.47,Grunshi,ETHNICITY,ETHNICITY
SM263470,-47.7714,-18.4796,Grupiara,ADM2,DISTRICT
SM16911,39.9167,52.5,Gryazinskiy Rayon,ADM2,DISTRICT
SM294965,40.75,58.9167,Gryazovetskiy rayon,ADM2,DISTRICT
SM288839,15.1908,53.9429,Gryfice,ADM2,DISTRICT
SM288840,14.4991,53.023,Gryfino,ADM2,DISTRICT
SM289347,-8.612,38.19,Grândola,ADM2,DISTRICT
SM263468,-42.9654,-16.4901,Grão Mogol,ADM2,DISTRICT
SM266431,-49.3161,-28.1358,Grão Pará,ADM2,DISTRICT
SM296571,12.6667,58.3333,Grästorp,ADM2,DISTRICT
SM277827,-20.9671,64.0853,Grímsnes- og Grafningshreppur,ADM2,DISTRICT
SM288989,20.8366,51.7574,Grójec,ADM2,DISTRICT
SM277780,-18.1167,65.9833,Grýtubakkahreppur,ADM2,DISTRICT
SM231750,0.1693,6.5211,Gua,LANGUAGE,LANGUAGE
SM265690,-51.6477,-28.5882,Guabiju,ADM2,DISTRICT
SM266142,-49.0222,-27.1119,Guabiruba,ADM2,DISTRICT
SM270471,-72.8479,6.916,Guaca,ADM2,DISTRICT
SM270773,-72.5168,6.446,Guacamayas,ADM2,DISTRICT
SM303873,-67.8333,10.3,Guacara,ADM2,DISTRICT
SM270615,-76.3081,3.775,Guacarí,ADM2,DISTRICT
SM273427,-78.7044,-2.7724,Guachapala,ADM2,DISTRICT
SM10413,-76.3926,3.1448,Guachene,ADM2,DISTRICT
SM270003,-73.697,5.389,Guachetá,ADM2,DISTRICT
SM231751,-57.5663,-19.5572,Guachi,LANGUAGE,LANGUAGE
SM253689,-100.9,23.75,Guachichil,ETHNICITY,ETHNICITY
SM281801,-104.4134,20.6708,Guachinango,ADM2,DISTRICT
SM260966,-65.5,-25.6667,Guachipas,ADM2,DISTRICT
SM283229,-107.294,27.17,Guachochic,ADM2,DISTRICT
SM270314,-77.7356,0.977,Guachucal,ADM2,DISTRICT
SM15722,-67.0011,18.2832,Guacio Barrio,ADM2,DISTRICT
SM295599,-88.6543,13.8732,Guacotecti,ADM2,DISTRICT
SM274065,-2.5,40.8333,Guadalajara,ADM2,DISTRICT
SM281802,-103.2979,20.7108,Guadalajara,ADM2,DISTRICT
SM270617,-76.1013,3.8559,Guadalajara de Buga,ADM2,DISTRICT
SM305290,160.2,-9.6333,Guadalcanal,ADM1,DISTRICT
SM253690,160.6,-9.77,Guadalcanal,ETHNICITY,ETHNICITY
SM282482,-100.3469,22.8476,Guadalcazar,ADM2,DISTRICT
SM302887,-97.9486,29.5831,Guadalupe,ADM2,DISTRICT
SM301836,-104.7907,34.8633,Guadalupe,ADM2,DISTRICT
SM282409,-98.1467,18.0652,Guadalupe,ADM2,DISTRICT
SM282167,-100.161,25.6847,Guadalupe,ADM2,DISTRICT
SM283230,-105.6406,30.8274,Guadalupe,ADM2,DISTRICT
SM295535,-88.8667,13.6167,Guadalupe,ADM2,DISTRICT
SM270083,-75.6901,1.988,Guadalupe,ADM2,DISTRICT
SM270472,-73.4079,6.206,Guadalupe,ADM2,DISTRICT
SM270220,-75.2382,6.8558,Guadalupe,ADM2,DISTRICT
SM265236,-43.7776,-6.823,Guadalupe,ADM2,DISTRICT
SM14285,-102.4658,22.7303,Guadalupe (2000-),ADM2,DISTRICT
SM14286,-96.7975,17.1791,Guadalupe Etla,ADM2,DISTRICT
SM282410,-97.3687,19.323,Guadalupe Victoria,ADM2,DISTRICT
SM281533,-104.0891,24.434,Guadalupe Victoria,ADM2,DISTRICT
SM14287,-98.1643,17.7544,Guadalupe de Ramirez,ADM2,DISTRICT
SM283231,-107.0451,26.1761,Guadalupe y Calvo,ADM2,DISTRICT
SM89,-61.5837,16.2499,Guadeloupe,ADM0:ADM1:ADM2,DISTRICT
SM89,-61.5837,16.2499,Guadeloupe,ADM0:ADM1:ADM2,DISTRICT
SM89,-61.5837,16.2499,Guadeloupe,ADM0:ADM1:ADM2,DISTRICT
SM89,-61.5837,16.2501,Guadeloupe,ADM0:ADM1:ADM2,DISTRICT
SM89,-61.5837,16.2501,Guadeloupe,ADM0:ADM1:ADM2,DISTRICT
SM89,-61.5837,16.2501,Guadeloupe,ADM0:ADM1:ADM2,DISTRICT
SM89,-61.5833,16.25,Guadeloupe,ADM0:ADM1:ADM2,DISTRICT
SM89,-61.5833,16.25,Guadeloupe,ADM0:ADM1:ADM2,DISTRICT
SM89,-61.5833,16.25,Guadeloupe,ADM0:ADM1:ADM2,DISTRICT
SM231755,-61.4059,16.3049,Guadeloupe-Martinique Creole French,LANGUAGE,LANGUAGE
SM231756,-61.5677,16.2503,Guadeloupean Creole French,DIALECT,LANGUAGE
SM15723,-66.2226,18.3148,Guadiana Barrio,ADM2,DISTRICT
SM270004,-74.5985,5.0743,Guaduas,ADM2,DISTRICT
SM231760,-68.9839,5.8174,Guahibo,LANGUAGE,LANGUAGE
SM253691,-73.57,6.16,Guahibo,ETHNICITY,ETHNICITY
SM253691,-73.57,6.16,Guahibo,ETHNICITY,ETHNICITY
SM253691,-73.57,6.16,Guahibo,ETHNICITY,ETHNICITY
SM253691,-73.57,6.16,Guahibo,ETHNICITY,ETHNICITY
SM253691,-68,5,Guahibo,ETHNICITY,ETHNICITY
SM253691,-68,5,Guahibo,ETHNICITY,ETHNICITY
SM253691,-68,5,Guahibo,ETHNICITY,ETHNICITY
SM253691,-68,5,Guahibo,ETHNICITY,ETHNICITY
SM253691,-69,5,Guahibo,ETHNICITY,ETHNICITY
SM253691,-69,5,Guahibo,ETHNICITY,ETHNICITY
SM253691,-69,5,Guahibo,ETHNICITY,ETHNICITY
SM253691,-69,5,Guahibo,ETHNICITY,ETHNICITY
SM253691,-68.31,5.38,Guahibo,ETHNICITY,ETHNICITY
SM253691,-68.31,5.38,Guahibo,ETHNICITY,ETHNICITY
SM253691,-68.31,5.38,Guahibo,ETHNICITY,ETHNICITY
SM253691,-68.31,5.38,Guahibo,ETHNICITY,ETHNICITY
SM253692,-60.53,5.02,Guaica,ETHNICITY,ETHNICITY
SM253692,-60.53,5.02,Guaica,ETHNICITY,ETHNICITY
SM253692,-65,2,Guaica,ETHNICITY,ETHNICITY
SM253692,-65,2,Guaica,ETHNICITY,ETHNICITY
SM303636,-67,10.25,Guaicaipuro,ADM2,DISTRICT
SM231761,-110.3,24.1667,Guaicurian,LANGUAGE,LANGUAGE
SM275938,-86.8543,14.5178,Guaimaca,ADM2,DISTRICT
SM253693,-76.97,20.96,Guaimaro,ETHNICITY,ETHNICITY
SM266542,-49.8496,-21.862,Guaimbê,ADM2,DISTRICT
SM1393,-69,2.5,Guainía,ADM1,DISTRICT
SM253694,-69.47,4.76,Guaipuave,ETHNICITY,ETHNICITY
SM264479,-52.7488,-22.9055,Guairaçá,ADM2,DISTRICT
SM1394,-56.2667,-25.8,Guairá,ADM1,DISTRICT
SM270315,-77.5584,1.1793,Guaitarilla,ADM2,DISTRICT
SM266541,-49.7626,-21.5541,Guaiçara,ADM2,DISTRICT
SM267928,-38.6828,-4.1195,Guaiúba,ADM2,DISTRICT
SM231765,-45.7081,-4.6946,Guajajára,DIALECT,LANGUAGE
SM267321,-72.7151,-7.1365,Guajará,ADM2,DISTRICT
SM266085,-64.441,-11.3311,Guajará-Mirim,ADM2,DISTRICT
SM15725,-66.9102,18.389,Guajataca Barrio (Quebradillas),ADM2,DISTRICT
SM15724,-66.9202,18.3536,Guajataca Barrio (San Sebastián),ADM2,DISTRICT
SM15337,-56.3481,-24.4079,Guajayvi (2011-),ADM2,DISTRICT
SM267413,-42.0399,-14.5788,Guajeru,ADM2,DISTRICT
SM275693,-87.7745,14.0698,Guajiquiro,ADM2,DISTRICT
SM303813,-72,11.3333,Guajira,ADM2,DISTRICT
SM231766,-46.3683,-3.9357,Guajá,LANGUAGE,LANGUAGE
SM286688,-82.2221,8.574,Gualaca,ADM2,DISTRICT
SM273428,-78.7833,-2.9,Gualaceo,ADM2,DISTRICT
SM275762,-86.0932,15.173,Gualaco,ADM2,DISTRICT
SM275792,-88.17,14.98,Gualala,ADM2,DISTRICT
SM273513,-78.5833,-3.4,Gualaquiza,ADM2,DISTRICT
SM262731,-67.9457,-17.6358,Gualberto Villarroel,ADM2,DISTRICT
SM275722,-88.5681,14.1287,Gualcince,ADM2,DISTRICT
SM261187,-59.5833,-33,Gualeguay,ADM2,DISTRICT
SM270316,-77.5827,0.9286,Gualmatán,ADM2,DISTRICT
SM295742,-88.2034,13.8114,Gualococti,ADM2,DISTRICT
SM275382,-89.37,15.13,Gualán,ADM2,DISTRICT
SM15726,-67.0032,18.1024,Guama Barrio,ADM2,DISTRICT
SM253695,-80,22.34,Guamahaya,ETHNICITY,ETHNICITY
SM270273,-73.9365,3.9444,Guamal,ADM2,DISTRICT
SM270146,-74.1311,9.2608,Guamal,ADM2,DISTRICT
SM15727,-66.1062,18.0359,Guamani Barrio,ADM2,DISTRICT
SM253696,-101.17,21.55,Guamar,ETHNICITY,ETHNICITY
SM265552,-36.3185,-5.1342,Guamaré,ADM2,DISTRICT
SM231772,-76.582,2.5585,Guambiano,LANGUAGE,LANGUAGE
SM260778,-62.5,-36.75,Guaminí,ADM2,DISTRICT
SM264481,-50.8618,-25.1503,Guamiranga,ADM2,DISTRICT
SM231771,-67.4019,8.2351,Guamo,LANGUAGE,LANGUAGE
SM270562,-74.9769,4.0746,Guamo,ADM2,DISTRICT
SM253698,-70.24,10.24,Guamontey,ETHNICITY,ETHNICITY
SM273603,-78.7167,-1.9333,Guamote,ADM2,DISTRICT
SM271047,-76.4383,19.9696,Guamá,ADM2,DISTRICT
SM231775,-58.0654,-21.9803,Guana (Paraguay),LANGUAGE,LANGUAGE
SM271098,-82.2827,23.0539,Guanabacoa,ADM2,DISTRICT
SM15728,-67.1544,18.3819,Guanabano Barrio,ADM2,DISTRICT
SM1395,-85.25,10.5,Guanacaste,ADM1,DISTRICT
SM281497,-106.109,25.9961,Guanaceví,ADM2,DISTRICT
SM253699,-84.41,21.89,Guanachahibe,ETHNICITY,ETHNICITY
SM253700,-75.62,2.2,Guanacoa,ETHNICITY,ETHNICITY
SM275429,-90.6621,14.1415,Guanagazapa,ADM2,DISTRICT
SM275675,-85.8757,16.4771,Guanaja,ADM2,DISTRICT
SM271143,-82.7087,22.8922,Guanajay,ADM2,DISTRICT
SM15730,-67.166,18.1351,Guanajibo Barrio (Cabo Rojo),ADM2,DISTRICT
SM5175,-67.1383,18.1475,Guanajibo Barrio (Hormigueros),ADM2,DISTRICT
SM15729,-67.1606,18.1686,Guanajibo Barrio (Mayagüez),ADM2,DISTRICT
SM281539,-101.2648,21.0048,Guanajuato,ADM2,DISTRICT
SM1396,-101,21,Guanajuato,ADM1,DISTRICT
SM267414,-42.824,-14.133,Guanambi,ADM2,DISTRICT
SM271817,-70.9212,19.6992,Guananico,ADM2,DISTRICT
SM303688,-69.5,8.9167,Guanare,ADM2,DISTRICT
SM303689,-69,8.5,Guanarito,ADM2,DISTRICT
SM231778,-15.5,28,Guanche,LANGUAGE,LANGUAGE
SM271032,-84.0366,22.2459,Guane,ADM2,DISTRICT
SM253701,-72.74,11.46,Guanebucan,ETHNICITY,ETHNICITY
SM269338,106.716,30.4723,Guang'an,ADM2,DISTRICT
SM1397,113.25,23.5,Guangdong (1988-),ADM1,DISTRICT
SM1398,109,24,Guangxi,ADM1,DISTRICT
SM269339,105.8236,32.2439,Guangyuan,ADM2,DISTRICT
SM231780,112.9477,23.1254,Guangzhou,DIALECT,LANGUAGE
SM269431,113.5122,23.3733,Guangzhou,ADM2,DISTRICT
SM263471,-42.8052,-18.8571,Guanhães,ADM2,DISTRICT
SM15731,-66.9119,17.9687,Guanica Barrio-Pueblo,ADM2,DISTRICT
SM253702,-81.55,22.82,Guaniguanico,ETHNICITY,ETHNICITY
SM303695,-64.161,8.88,Guanipa,ADM2,DISTRICT
SM15732,-67.1974,18.3888,Guaniquilla Barrio,ADM2,DISTRICT
SM273604,-78.6333,-1.6,Guano,ADM2,DISTRICT
SM303696,-64.5955,10.2345,Guanta,ADM2,DISTRICT
SM271118,-75.1555,20.2672,Guantánamo,ADM2,DISTRICT
SM1400,-74.8667,20.2167,Guantánamo,ADM1,DISTRICT
SM15733,-66.7402,18.2374,Guaonico Barrio,ADM2,DISTRICT
SM266547,-48.5452,-24.2173,Guapiara,ADM2,DISTRICT
SM266546,-49.1641,-20.7259,Guapiaçu,ADM2,DISTRICT
SM265265,-42.9753,-22.5891,Guapimirim,ADM2,DISTRICT
SM264482,-50.0913,-23.4684,Guapirama,ADM2,DISTRICT
SM264483,-52.8248,-23.3102,Guaporema,ADM2,DISTRICT
SM265692,-51.9188,-28.8419,Guaporé,ADM2,DISTRICT
SM270473,-73.3285,6.307,Guapotá,ADM2,DISTRICT
SM263472,-45.8994,-20.774,Guapé,ADM2,DISTRICT
SM269853,-77.8786,2.5521,Guapí,ADM2,DISTRICT
SM268283,-49.6059,-16.9117,Guapó,ADM2,DISTRICT
SM264425,-35.4412,-6.8796,Guarabira,ADM2,DISTRICT
SM266549,-49.0052,-20.4165,Guaraci,ADM2,DISTRICT
SM264484,-51.6974,-22.9734,Guaraci,ADM2,DISTRICT
SM266143,-53.5979,-26.5739,Guaraciaba,ADM2,DISTRICT
SM263473,-43.0115,-20.5692,Guaraciaba,ADM2,DISTRICT
SM267929,-40.8451,-4.2184,Guaraciaba do Norte,ADM2,DISTRICT
SM263474,-43.6031,-17.0807,Guaraciama,ADM2,DISTRICT
SM15734,-66.1444,18.3298,Guaraguao Abajo Barrio,ADM2,DISTRICT
SM15735,-66.1558,18.2926,Guaraguao Arriba Barrio,ADM2,DISTRICT
SM15737,-66.1338,18.3153,Guaraguao Barrio (Guaynabo),ADM2,DISTRICT
SM15736,-66.6691,18.1212,Guaraguao Barrio (Ponce),ADM2,DISTRICT
SM9929,-48.4025,-8.7023,Guarai,ADM2,DISTRICT
SM289729,-57.4518,-25.4887,Guarambaré,ADM2,DISTRICT
SM267930,-38.9645,-4.2218,Guaramiranga,ADM2,DISTRICT
SM266144,-48.9371,-26.4607,Guaramirim,ADM2,DISTRICT
SM273551,-79,-1.6,Guaranda,ADM2,DISTRICT
SM270549,-74.7986,8.3948,Guarandá,ADM2,DISTRICT
SM263475,-46.8223,-21.2882,Guaranesia,ADM2,DISTRICT
SM263476,-43.0589,-21.3504,Guarani,ADM2,DISTRICT
SM253703,-51.03,-21.94,Guarani,ETHNICITY,ETHNICITY
SM253703,-51.03,-21.94,Guarani,ETHNICITY,ETHNICITY
SM253703,-51.03,-21.94,Guarani,ETHNICITY,ETHNICITY
SM253703,-51.03,-21.94,Guarani,ETHNICITY,ETHNICITY
SM253703,-54,-21.73,Guarani,ETHNICITY,ETHNICITY
SM253703,-54,-21.73,Guarani,ETHNICITY,ETHNICITY
SM253703,-54,-21.73,Guarani,ETHNICITY,ETHNICITY
SM253703,-54,-21.73,Guarani,ETHNICITY,ETHNICITY
SM253703,-56.14,-24.74,Guarani,ETHNICITY,ETHNICITY
SM253703,-56.14,-24.74,Guarani,ETHNICITY,ETHNICITY
SM253703,-56.14,-24.74,Guarani,ETHNICITY,ETHNICITY
SM253703,-56.14,-24.74,Guarani,ETHNICITY,ETHNICITY
SM253703,-59,-27.5,Guarani,ETHNICITY,ETHNICITY
SM253703,-59,-27.5,Guarani,ETHNICITY,ETHNICITY
SM253703,-59,-27.5,Guarani,ETHNICITY,ETHNICITY
SM253703,-59,-27.5,Guarani,ETHNICITY,ETHNICITY
SM253703,-54.5,-23.3333,Guarani,ETHNICITY,ETHNICITY
SM253703,-54.5,-23.3333,Guarani,ETHNICITY,ETHNICITY
SM253703,-54.5,-23.3333,Guarani,ETHNICITY,ETHNICITY
SM253703,-54.5,-23.3333,Guarani,ETHNICITY,ETHNICITY
SM253703,-55.2,-23.3333,Guarani,ETHNICITY,ETHNICITY
SM253703,-55.2,-23.3333,Guarani,ETHNICITY,ETHNICITY
SM253703,-55.2,-23.3333,Guarani,ETHNICITY,ETHNICITY
SM253703,-55.2,-23.3333,Guarani,ETHNICITY,ETHNICITY
SM265693,-54.6003,-28.1507,Guarani das Missões,ADM2,DISTRICT
SM268045,-46.5071,-13.8849,Guarani de Goiás,ADM2,DISTRICT
SM266551,-50.3629,-20.0703,Guarani do Oeste,ADM2,DISTRICT
SM264485,-52.8764,-25.0798,Guaraniaçu,ADM2,DISTRICT
SM266552,-49.5852,-21.9228,Guarantã,ADM2,DISTRICT
SM263164,-54.7289,-9.756,Guarantã do Norte,ADM2,DISTRICT
SM260913,-54.1667,-27.1667,Guaraní,ADM2,DISTRICT
SM267983,-40.5274,-20.6103,Guarapari,ADM2,DISTRICT
SM264486,-51.46,-25.3986,Guarapuava,ADM2,DISTRICT
SM303634,-71.7356,8.1535,Guaraque,ADM2,DISTRICT
SM264487,-48.3595,-25.1516,Guaraqueçaba,ADM2,DISTRICT
SM457974,-50.6208,-21.2755,Guararapes,ADM2,DISTRICT
SM266554,-46.0609,-23.4342,Guararema,ADM2,DISTRICT
SM263477,-43.0247,-21.758,Guarará,ADM2,DISTRICT
SM286714,-80.3752,7.7702,Guararé,ADM2,DISTRICT
SM267415,-39.9782,-16.5699,Guaratinga,ADM2,DISTRICT
SM320335,-45.2129,-22.8268,Guaratinguetá,ADM2:ADM3,DISTRICT
SM264489,-48.804,-25.8034,Guaratuba,ADM2,DISTRICT
SM253704,-63.47,-15.56,Guarayo,ETHNICITY,ETHNICITY
SM253704,-63.94,-17.52,Guarayo,ETHNICITY,ETHNICITY
SM9833,-63.2428,-15.8213,Guarayos,ADM2,DISTRICT
SM231782,-61.6934,-15.8247,Guarayu,LANGUAGE,LANGUAGE
SM266548,-51.3012,-21.1082,Guaraçaí,ADM2,DISTRICT
SM268284,-50.0666,-15.6596,Guaraíta,ADM2,DISTRICT
SM289239,-7.2412,40.5295,Guarda,ADM2,DISTRICT
SM1401,-7.2185,40.6422,Guarda,ADM1,DISTRICT
SM263478,-47.1478,-17.7639,Guarda-Mor,ADM2,DISTRICT
SM15738,-65.9415,17.9771,Guardarraya Barrio,ADM2,DISTRICT
SM253705,-65.6,5.62,Guarequena,ETHNICITY,ETHNICITY
SM266557,-48.2247,-23.3734,Guareí,ADM2,DISTRICT
SM266558,-48.2043,-21.3957,Guariba,ADM2,DISTRICT
SM265237,-43.5819,-9.2887,Guaribas,ADM2,DISTRICT
SM268046,-49.731,-14.6998,Guarinos,ADM2,DISTRICT
SM275723,-88.8516,14.2078,Guarita,ADM2,DISTRICT
SM275763,-86.2876,14.9043,Guarizama,ADM2,DISTRICT
SM270221,-75.4436,6.2835,Guarne,ADM2,DISTRICT
SM231784,-71.65,-0.25,Guaru,LANGUAGE,LANGUAGE
SM266559,-46.2278,-23.9575,Guarujá,ADM2,DISTRICT
SM266145,-53.4721,-26.4056,Guarujá do Sul,ADM2,DISTRICT
SM266560,-46.4435,-23.412,Guarulhos,ADM2,DISTRICT
SM266550,-47.8007,-20.4896,Guará,ADM2,DISTRICT
SM282535,-108.5135,25.5808,Guasave,ADM2,DISTRICT
SM253706,-107.53,24.74,Guasave,ETHNICITY,ETHNICITY
SM261039,-64.8333,-28,Guasayán,ADM2,DISTRICT
SM270005,-73.8517,4.807,Guasca,ADM2,DISTRICT
SM15739,-66.0667,17.9764,Guasimas Barrio,ADM2,DISTRICT
SM303732,-72.2276,7.8361,Guasimos,ADM2,DISTRICT
SM275417,-90.0636,14.8504,Guastatoya,ADM2,DISTRICT
SM275764,-86.3762,15.1513,Guata,ADM2,DISTRICT
SM295743,-88.2251,13.6806,Guatajiagua,ADM2,DISTRICT
SM266146,-52.7894,-27.1105,Guatambú,ADM2,DISTRICT
SM266561,-47.9914,-21.4723,Guatapará,ADM2,DISTRICT
SM270222,-75.1643,6.2555,Guatapé,ADM2,DISTRICT
SM270007,-74.7722,4.5162,Guataquí,ADM2,DISTRICT
SM270008,-73.8399,4.9112,Guatavita,ADM2,DISTRICT
SM1403,-90.5,14.6667,Guatemala,ADM1:ADMX,DISTRICT
SM15740,-67.0067,18.3527,Guatemala Barrio,ADM2,DISTRICT
SM231785,-90.5667,14.5034,Guatemalan Sign Language,LANGUAGE,LANGUAGE
SM270774,-73.4882,5.0148,Guateque,ADM2,DISTRICT
SM253711,-61.15,-17.42,Guato,ETHNICITY,ETHNICITY
SM253711,-61.15,-17.42,Guato,ETHNICITY,ETHNICITY
SM253711,-61.15,-17.42,Guato,ETHNICITY,ETHNICITY
SM253711,-61.15,-17.42,Guato,ETHNICITY,ETHNICITY
SM253711,-57,-18,Guato,ETHNICITY,ETHNICITY
SM253711,-57,-18,Guato,ETHNICITY,ETHNICITY
SM253711,-57,-18,Guato,ETHNICITY,ETHNICITY
SM253711,-57,-18,Guato,ETHNICITY,ETHNICITY
SM253711,-58,-19,Guato,ETHNICITY,ETHNICITY
SM253711,-58,-19,Guato,ETHNICITY,ETHNICITY
SM253711,-58,-19,Guato,ETHNICITY,ETHNICITY
SM253711,-58,-19,Guato,ETHNICITY,ETHNICITY
SM253711,-57.5,-18,Guato,ETHNICITY,ETHNICITY
SM253711,-57.5,-18,Guato,ETHNICITY,ETHNICITY
SM253711,-57.5,-18,Guato,ETHNICITY,ETHNICITY
SM253711,-57.5,-18,Guato,ETHNICITY,ETHNICITY
SM260841,-63.75,-37.5,Guatraché,ADM2,DISTRICT
SM270908,-84.8369,10.6794,Guatuso,ADM2,DISTRICT
SM231786,-57.2897,-17.8696,Guató,LANGUAGE,LANGUAGE
SM15741,-66.08,18.1308,Guavate Barrio,ADM2,DISTRICT
SM270475,-73.7174,5.9339,Guavatá,ADM2,DISTRICT
SM1404,-72,1.9167,Guaviare,ADM1,DISTRICT
SM263479,-46.6811,-21.2918,Guaxupé,ADM2,DISTRICT
SM253712,-47.21,-3.51,Guaya,ETHNICITY,ETHNICITY
SM271761,-70.765,18.7174,Guayabal,ADM2,DISTRICT
SM15742,-66.4854,18.0768,Guayabal Barrio,ADM2,DISTRICT
SM270009,-74.4831,4.8788,Guayabal de Síquima,ADM2,DISTRICT
SM231788,-71.844,2.8969,Guayabero,LANGUAGE,LANGUAGE
SM270010,-73.8812,4.1751,Guayabetal,ADM2,DISTRICT
SM15743,-67.2101,18.3751,Guayabo Barrio,ADM2,DISTRICT
SM15744,-66.8211,18.2066,Guayabo Dulce Barrio,ADM2,DISTRICT
SM15745,-67.0011,18.5006,Guayabos Barrio,ADM2,DISTRICT
SM15746,-65.9684,18.0717,Guayabota Barrio,ADM2,DISTRICT
SM15747,-65.6456,18.2212,Guayacan Barrio,ADM2,DISTRICT
SM271849,-69.4335,18.4506,Guayacanes,ADM2,DISTRICT
SM1405,-66.1307,18.0166,Guayama,ADM1,DISTRICT
SM15748,-66.1144,17.9767,Guayama Barrio-Pueblo,ADM2,DISTRICT
SM1406,-66.7899,18.0541,Guayanilla,ADM1,DISTRICT
SM15749,-66.7914,18.023,Guayanilla Barrio-Pueblo,ADM2,DISTRICT
SM275743,-86.85,14.79,Guayape,ADM2,DISTRICT
SM273433,-79.8833,-2.2,Guayaquil,ADM1:ADM2:ADME,DISTRICT
SM1407,-79.8333,-2,Guayas,ADM1,DISTRICT
SM270775,-73.5297,4.9194,Guayatá,ADM2,DISTRICT
SM260892,-68.7,-32.8833,Guaymallén,ADM2,DISTRICT
SM295506,-89.8522,13.7368,Guaymango,ADM2,DISTRICT
SM14288,-110.626,28.1426,Guaymas (1996-),ADM2,DISTRICT
SM271783,-68.95,18.5833,Guaymate,ADM2,DISTRICT
SM8252,-80.39,8.57,Guaymi,ETHNICITY,ETHNICITY
SM8252,-80.39,8.57,Guaymi,ETHNICITY,ETHNICITY
SM8252,-80.39,8.57,Guaymi,ETHNICITY,ETHNICITY
SM8252,-83,8,Guaymi,ETHNICITY,ETHNICITY
SM8252,-83,8,Guaymi,ETHNICITY,ETHNICITY
SM8252,-83,8,Guaymi,ETHNICITY,ETHNICITY
SM8252,-81,8,Guaymi,ETHNICITY,ETHNICITY
SM8252,-81,8,Guaymi,ETHNICITY,ETHNICITY
SM8252,-81,8,Guaymi,ETHNICITY,ETHNICITY
SM1408,-66.1163,18.3411,Guaynabo,ADM1,DISTRICT
SM15750,-66.1124,18.36,Guaynabo Barrio-Pueblo,ADM2,DISTRICT
SM15751,-66.8224,18.1791,Guayo Barrio,ADM2,DISTRICT
SM271798,-71.3333,19.6167,Guayubín,ADM2,DISTRICT
SM275318,-90.434,14.0179,Guazacapán,ADM2,DISTRICT
SM295527,-89.1642,13.8909,Guazapa,ADM2,DISTRICT
SM231790,-108.279,27.357,Guazapar,LANGUAGE,LANGUAGE
SM283232,-108.2334,27.4401,Guazapares,ADM2,DISTRICT
SM289613,-57.9903,-26.9264,Guazú Cuá,ADM2,DISTRICT
SM267982,-41.7112,-20.768,Guaçuí,ADM2,DISTRICT
SM265691,-51.421,-30.1748,Guaíba,ADM2,DISTRICT
SM266545,-48.3377,-20.3013,Guaíra,ADM2,DISTRICT
SM264480,-54.2382,-24.2618,Guaíra,ADM2,DISTRICT
SM231795,-17.19,12.56,Gubeeher-Gufangor-Gubelor,LANGUAGE,LANGUAGE
SM285388,12.7536,12.6778,Gubio,ADM2,DISTRICT
SM16912,37.4167,51.1667,Gubkinsky Urban Okrug,ADM2,DISTRICT
SM457930,34.753,-0.845,Gucha District,ADM2,DISTRICT
SM283654,102.4209,45.4583,Guchin-Us,ADM2,DISTRICT
SM231799,142.5769,-10.9161,Gudang,LANGUAGE,LANGUAGE
SM459264,142.5879,-10.8673,Gudang,ETHNICITY,ETHNICITY
SM274769,40.6667,43.25,Gudauta,ADM2,DISTRICT
SM231803,13.403,10.2138,Gude,LANGUAGE,LANGUAGE
SM13535,21.6201,56.8867,Gudenieku pagasts,ADM2,DISTRICT
SM16913,46.1667,43.3333,Gudermesskiy Rayon,ADM2,DISTRICT
SM283424,14.5033,35.8514,Gudja,ADM1:ADM2,DISTRICT
SM231807,144.6483,-19.4416,Gudjal,LANGUAGE,LANGUAGE
SM458430,145.436,-19.7075,Gudjal,ETHNICITY,ETHNICITY
SM231808,12.5359,10.1206,Gudu,LANGUAGE,LANGUAGE
SM285238,4.4954,13.4316,Gudu,ADM2,DISTRICT
SM231810,13.7893,11.1057,Guduf-Gava,LANGUAGE,LANGUAGE
SM14289,-96.5001,17.3148,Guelatao de Juarez,ADM2,DISTRICT
SM1409,7.4167,36.4167,Guelma,ADM1,DISTRICT
SM457520,7.5,36.25,Guelma District,ADM2,DISTRICT
SM281451,-10,28.75,Guelmim,ADM2,DISTRICT
SM4274,-10.0391,29.0017,Guelmim-Oued Noun,ADM1,DISTRICT
SM10295,15.8742,8.9923,Gueni,ADM2,DISTRICT
SM253723,-56.03,-30.47,Guenoa,ETHNICITY,ETHNICITY
SM4439,-3.3254,34.2504,Guercif,ADM2,DISTRICT
SM250271,-7.27,8.81,Guere,ETHNICITY,ETHNICITY
SM250271,-7.27,8.81,Guere,ETHNICITY,ETHNICITY
SM250271,-8,7,Guere,ETHNICITY,ETHNICITY
SM250271,-8,7,Guere,ETHNICITY,ETHNICITY
SM231817,-39.6144,-17.727,Gueren,LANGUAGE,LANGUAGE
SM253724,146.45,-30.15,Guerno,ETHNICITY,ETHNICITY
SM253724,146.45,-30.15,Guerno,ETHNICITY,ETHNICITY
SM253724,145.3308,-29.7598,Guerno,ETHNICITY,ETHNICITY
SM253724,145.3308,-29.7598,Guerno,ETHNICITY,ETHNICITY
SM302106,-81.4943,40.0521,Guernsey,ADM2,DISTRICT
SM271882,-69.663,18.579,Guerra,ADM2,DISTRICT
SM282643,-99.46,26.9095,Guerrero,ADM2,DISTRICT
SM283234,-107.5038,28.4289,Guerrero,ADM2,DISTRICT
SM283280,-100.4395,28.2916,Guerrero,ADM2,DISTRICT
SM1412,-100,17.6667,Guerrero,ADM1,DISTRICT
SM231818,-98.1895,16.7723,Guerrero Amuzgo,LANGUAGE,LANGUAGE
SM15753,-67.0737,18.4774,Guerrero Barrio (Aguadillo),ADM2,DISTRICT
SM15752,-67.0437,18.4691,Guerrero Barrio (Isabella),ADM2,DISTRICT
SM17424,-60.9649,13.9908,Guesneau,ADM2,DISTRICT
SM298084,9.0235,34.2895,Guetar,ADM2,DISTRICT
SM253726,-84.69,9.94,Guetare,ETHNICITY,ETHNICITY
SM231821,-95.3641,16.7312,Guevea De Humboldt Zapotec,LANGUAGE,LANGUAGE
SM14290,-95.392,16.8108,Guevea de Humboldt,ADM2,DISTRICT
SM231822,141.168,-17.8372,Gugadj,LANGUAGE,LANGUAGE
SM292346,27.1437,45.5798,Gugesti,ADM2,DISTRICT
SM231824,144.46,-18.4921,Gugu Badhun,LANGUAGE,LANGUAGE
SM459203,145.233,-18.6903,Gugu Badhun,ETHNICITY,ETHNICITY
SM459427,144.1097,-14.7675,Gugu Rarmul,ETHNICITY,ETHNICITY
SM459429,144.0314,-15.3802,Gugu Yawa,ETHNICITY,ETHNICITY
SM15287,145.8375,17.32,Guguan Island,ADM2,DISTRICT
SM231825,141.635,-15.4296,Gugubera,LANGUAGE,LANGUAGE
SM231829,147.228,-7.9992,Guhu-Samane,LANGUAGE,LANGUAGE
SM267984,-40.7073,-18.9962,Guia Branca,ADM2,DISTRICT
SM263050,-55.9346,-21.6108,Guia Lopes da Laguna,ADM2,DISTRICT
SM231832,-52.7336,4.948,Guianese Creole French,LANGUAGE,LANGUAGE
SM231834,108.1,24.9,Guibei Zhuang,LANGUAGE,LANGUAGE
SM231835,-6.5129,6.1384,Guiberoua Béte,LANGUAGE,LANGUAGE
SM231833,106.2,24.3,Guibian Zhuang,LANGUAGE,LANGUAGE
SM270776,-72.2731,6.5368,Guicán,ADM2,DISTRICT
SM1413,-12.1333,15.3833,Guidimaka,ADM1,DISTRICT
SM263481,-42.7901,-21.1768,Guidoval,ADM2,DISTRICT
SM269446,110.0474,23.3177,Guigang,ADM2,DISTRICT
SM283899,33.1214,-24.1672,Guijá,ADM2,DISTRICT
SM15754,-66.7768,18.1591,Guilarte Barrio,ADM2,DISTRICT
SM301966,-79.789,36.0795,Guilford,ADM2,DISTRICT
SM269447,110.2833,25.2833,Guilin,ADM2,DISTRICT
SM231840,110.2833,25.2667,Guilin Pinghua,DIALECT,LANGUAGE
SM263482,-46.7355,-18.8169,Guimarania,ADM2,DISTRICT
SM1414,122.6262,10.5809,Guimaras,ADM1:ADM2,DISTRICT
SM289445,-8.2892,41.4438,Guimarães,ADM2,DISTRICT
SM263005,-44.6348,-2.1199,Guimarães,ADM2,DISTRICT
SM231849,-64.7388,4.7976,Guinau,LANGUAGE,LANGUAGE
SM231845,-8.9884,7.932,Guinea Kpelle,LANGUAGE,LANGUAGE
SM231851,-15.5916,11.8593,Guinea-Bissau Sign Language,LANGUAGE,LANGUAGE
SM231844,-13.674,9.553,Guinean Sign Language,LANGUAGE,LANGUAGE
SM295276,-15.9207,14.2989,Guinguinéo,ADM2,DISTRICT
SM275908,-86.9361,13.8778,Guinope,ADM2,DISTRICT
SM274054,-2.1667,43.1667,Guipúzcoa,ADM2,DISTRICT
SM231853,102.128,30.4803,Guiqiong,LANGUAGE,LANGUAGE
SM263165,-53.5748,-16.3698,Guiratinga,ADM2,DISTRICT
SM263483,-42.6989,-21.0129,Guiricema,ADM2,DISTRICT
SM271110,-76.5396,20.2543,Guisa,ADM2,DISTRICT
SM457997,30.0565,9.247,Guit County,ADM2,DISTRICT
SM231854,106.6383,26.7169,Guiyang,FAMILY,LANGUAGE
SM269453,106.71,26.58,Guiyang,ADM2,DISTRICT
SM1415,107,27,Guizhou,ADM1,DISTRICT
SM250587,14,10,Guiziga,ETHNICITY,ETHNICITY
SM250587,14,10,Guiziga,ETHNICITY,ETHNICITY
SM250587,14.3,10.3,Guiziga,ETHNICITY,ETHNICITY
SM250587,14.3,10.3,Guiziga,ETHNICITY,ETHNICITY
SM375452,73.4618,32.4421,Gujarat,ADM2:ADM3,DISTRICT
SM1416,71.75,23,Gujarat,ADM1,DISTRICT
SM231856,71.0962,22.687,Gujarati,LANGUAGE,LANGUAGE
SM253731,70.61,21.66,Gujarati,ETHNICITY,ETHNICITY
SM253731,70.61,21.66,Gujarati,ETHNICITY,ETHNICITY
SM253731,70.61,21.66,Gujarati,ETHNICITY,ETHNICITY
SM253731,70.61,21.66,Gujarati,ETHNICITY,ETHNICITY
SM253731,70.61,21.66,Gujarati,ETHNICITY,ETHNICITY
SM253731,73,21,Gujarati,ETHNICITY,ETHNICITY
SM253731,73,21,Gujarati,ETHNICITY,ETHNICITY
SM253731,73,21,Gujarati,ETHNICITY,ETHNICITY
SM253731,73,21,Gujarati,ETHNICITY,ETHNICITY
SM253731,73,21,Gujarati,ETHNICITY,ETHNICITY
SM253731,77,20,Gujarati,ETHNICITY,ETHNICITY
SM253731,77,20,Gujarati,ETHNICITY,ETHNICITY
SM253731,77,20,Gujarati,ETHNICITY,ETHNICITY
SM253731,77,20,Gujarati,ETHNICITY,ETHNICITY
SM253731,77,20,Gujarati,ETHNICITY,ETHNICITY
SM253731,72.7,23.2,Gujarati,ETHNICITY,ETHNICITY
SM253731,72.7,23.2,Gujarati,ETHNICITY,ETHNICITY
SM253731,72.7,23.2,Gujarati,ETHNICITY,ETHNICITY
SM253731,72.7,23.2,Gujarati,ETHNICITY,ETHNICITY
SM253731,72.7,23.2,Gujarati,ETHNICITY,ETHNICITY
SM253731,72,23,Gujarati,ETHNICITY,ETHNICITY
SM253731,72,23,Gujarati,ETHNICITY,ETHNICITY
SM253731,72,23,Gujarati,ETHNICITY,ETHNICITY
SM253731,72,23,Gujarati,ETHNICITY,ETHNICITY
SM253731,72,23,Gujarati,ETHNICITY,ETHNICITY
SM231857,75.11,33.7,Gujari,LANGUAGE,LANGUAGE
SM285272,11.9667,11.3833,Gujba,ADM2,DISTRICT
SM274371,39.067,5.588,Guji,ADM2,DISTRICT
SM4946,74.0536,32.1885,Gujranwala (ADM3),ADM2:ADM3,DISTRICT
SM375455,74.0545,32.7005,Gujrat,ADM2:ADM3,DISTRICT
SM279827,136.9456,35.833,Gujō,ADM2,DISTRICT
SM260042,68.9953,34.748,Gul dara,ADM2,DISTRICT
SM293364,40.7,45.35,Gul'kevichskiy rayon,ADM2,DISTRICT
SM458046,122.702,-27.9905,Gula,ETHNICITY,ETHNICITY
SM231865,23.3805,9.4909,Gula (Central African Republic),LANGUAGE,LANGUAGE
SM231868,19.2182,10.3049,Gula (Chad),LANGUAGE,LANGUAGE
SM231864,19.5359,10.267,Gula Iro,LANGUAGE,LANGUAGE
SM231869,161.023,-8.7474,Gula'alaa,LANGUAGE,LANGUAGE
SM295428,160.9358,-8.8913,Gulalafou,ADM2,DISTRICT
SM285273,11.7167,11,Gulani,ADM2,DISTRICT
SM231867,17.3151,9.0663,Gulay,LANGUAGE,LANGUAGE
SM276930,77.0833,17.1667,Gulbarga (2010-),ADM2,DISTRICT
SM13536,26.7554,57.1741,Gulbene,ADM2,DISTRICT
SM4032,26.6667,57.1667,Gulbenes novads,ADM1,DISTRICT
SM271729,11.8361,54.7694,Guldborgsund,ADM2,DISTRICT
SM231873,33.6929,11.8336,Gule,LANGUAGE,LANGUAGE
SM346077,38.7447,9.0703,Gulele,ADM2:ADM3,DISTRICT
SM286157,5.0741,60.9819,Gulen,ADM2,DISTRICT
SM300327,-85.261,29.9086,Gulf,ADM2,DISTRICT
SM1417,145.0855,-7.7246,Gulf,ADM1,DISTRICT
SM231876,47.5,30.17,Gulf Arabic,LANGUAGE,LANGUAGE
SM231877,-89.268,30.3796,Gulf Coast French,LANGUAGE,LANGUAGE
SM459050,143.6956,-38.2282,Gulidjan,ETHNICITY,ETHNICITY
SM231880,157.564,-8.1027,Guliguli,LANGUAGE,LANGUAGE
SM260272,63.595,32.6416,Gulistan,ADM2,DISTRICT
SM18396,68.7842,40.4897,Guliston Shahri,ADM2,DISTRICT
SM18397,68.9167,40.5,Guliston Tumani,ADM2,DISTRICT
SM323957,-113.9316,52.4646,Gull Lake,ADM2:ADM3,DISTRICT
SM454377,-80.5,32.333,Gullah,ETHNICITY,ETHNICITY
SM296572,14.1805,58.933,Gullspång,ADM2,DISTRICT
SM375362,83.3293,28.0958,Gulmi,ADM2:ADM3,DISTRICT
SM459201,145.8525,-17.9618,Gulngay,ETHNICITY,ETHNICITY
SM285855,5.893,50.8137,Gulpen-Wittem,ADM2,DISTRICT
SM260020,61.5714,35.134,Gulran,ADM2,DISTRICT
SM274770,41.1342,42.9373,Gulripshi,ADM2,DISTRICT
SM4771,32.43,2.856,Gulu (2016-),ADM1:ADM2,DISTRICT
SM459234,145.4466,-16.9352,Guluy,ETHNICITY,ETHNICITY
SM262336,24.65,43.6833,Gulyantsi,ADM2,DISTRICT
SM285357,8.7667,7.9667,Guma,ADM2,DISTRICT
SM370397,-10.4167,8,Guma/Mende,ADM2:ADM3,DISTRICT
SM370397,-10.4167,8,Guma/Mende,ADM2:ADM3,DISTRICT
SM370397,-10.4167,8,Guma/Mende,ADM2:ADM3,DISTRICT
SM370397,-10.4139,8.0614,Guma/Mende,ADM2:ADM3,DISTRICT
SM370397,-10.4139,8.0614,Guma/Mende,ADM2:ADM3,DISTRICT
SM370397,-10.4139,8.0614,Guma/Mende,ADM2:ADM3,DISTRICT
SM370397,-10.4907,8.0467,Guma/Mende,ADM2:ADM3,DISTRICT
SM370397,-10.4907,8.0467,Guma/Mende,ADM2:ADM3,DISTRICT
SM370397,-10.4907,8.0467,Guma/Mende,ADM2:ADM3,DISTRICT
SM458843,133.5636,-12.4811,Gumadir,ETHNICITY,ETHNICITY
SM231890,145.618,-5.2219,Gumalu,LANGUAGE,LANGUAGE
SM231888,136.8304,-12.2525,Gumatj,LANGUAGE,LANGUAGE
SM231889,150.762,-9.285,Gumawana,LANGUAGE,LANGUAGE
SM292825,46.5,42.8333,Gumbetovskiy rayon,ADM2,DISTRICT
SM284808,9.4015,12.6384,Gumel,ADM2,DISTRICT
SM280754,128.3544,36.2101,Gumi,ADM2,DISTRICT
SM288617,144.8833,-6.25,Gumine,ADM2,DISTRICT
SM276922,84.5383,23.0806,Gumla (2001-),ADM2,DISTRICT
SM253739,34.66,13.08,Gumma,ETHNICITY,ETHNICITY
SM285285,5.1136,12.0541,Gummi,ADM2,DISTRICT
SM231913,2.7677,6.4853,Gun,LANGUAGE,LANGUAGE
SM253744,134.73,-12.2,Gun-Narta,ETHNICITY,ETHNICITY
SM253744,134.73,-12.2,Gun-Narta,ETHNICITY,ETHNICITY
SM253744,134.5503,-12.2192,Gun-Narta,ETHNICITY,ETHNICITY
SM253744,134.5503,-12.2192,Gun-Narta,ETHNICITY,ETHNICITY
SM458832,134.6619,-12.1279,Gun-narda,ETHNICITY,ETHNICITY
SM458833,134.4884,-12.1071,Gun-narta,ETHNICITY,ETHNICITY
SM276978,77.2008,24.5546,Guna (2003-),ADM2,DISTRICT
SM261546,148.1346,-35.0262,Gundagai,ADM2,DISTRICT
SM458486,141.8156,-16.0524,Gundara,ETHNICITY,ETHNICITY
SM231902,15.7334,3.4597,Gundi,LANGUAGE,LANGUAGE
SM231902,15.7334,3.4597,Gundi,LANGUAGE,LANGUAGE
SM231902,17.301,3.6886,Gundi,LANGUAGE,LANGUAGE
SM231902,17.301,3.6886,Gundi,LANGUAGE,LANGUAGE
SM251502,17,4,Gundi,ETHNICITY,ETHNICITY
SM305563,18.4908,45.1563,Gundinci,ADM2,DISTRICT
SM459062,147.5219,-36.7236,Gundungerre / Yaithmathang,ETHNICITY,ETHNICITY
SM231903,150.3,-33.7,Gundungurra,DIALECT,LANGUAGE
SM459077,149.7245,-34.3976,Gundungurra / Gandangara,ETHNICITY,ETHNICITY
SM231906,148.604,-25.6896,Gungabula,LANGUAGE,LANGUAGE
SM458211,148.6437,-25.5996,Gungabula,ETHNICITY,ETHNICITY
SM458671,142.0253,-26.3519,Gungadidji,ETHNICITY,ETHNICITY
SM250645,5,11,Gunganchi,ETHNICITY,ETHNICITY
SM459190,145.9148,-16.9618,Gunggay,ETHNICITY,ETHNICITY
SM231908,31.4819,2.0675,Gungu,LANGUAGE,LANGUAGE
SM253743,146.94,-8.15,Guni,ETHNICITY,ETHNICITY
SM292826,46.8333,42.4167,Gunibskiy rayon,ADM2,DISTRICT
SM458632,127.6671,-14.6268,Gunin,ETHNICITY,ETHNICITY
SM458116,136.8447,-17.6692,Gunindirri,ETHNICITY,ETHNICITY
SM305560,18.8255,44.8871,Gunja,ADM2,DISTRICT
SM1420,138.9824,36.5075,Gunma,ADM1:ADMX,DISTRICT
SM261547,150.1155,-31.024,Gunnedah,ADM2,DISTRICT
SM303337,-107.0317,38.6668,Gunnison,ADM2,DISTRICT
SM280732,126.9215,37.3426,Gunpo,ADM2,DISTRICT
SM280582,126.6834,35.9358,Gunsan,ADM2,DISTRICT
SM277026,80.25,16.4167,Guntur,ADM2,DISTRICT
SM276709,110.6167,-7.9833,Gunung Kidul,ADM2,DISTRICT
SM276412,113.5,-0.95,Gunung Mas,ADM2,DISTRICT
SM276703,97.5502,1.3273,Gunungsitoli,ADM2,DISTRICT
SM280755,128.6471,36.17,Gunwi,ADM2,DISTRICT
SM231920,145.862,-27.0642,Gunya,LANGUAGE,LANGUAGE
SM458163,146.1613,-26.7847,Gunya,ETHNICITY,ETHNICITY
SM231925,6.6913,8.2129,Gupa-Abawa,LANGUAGE,LANGUAGE
SM231924,135.337,-12.2868,Gupapuyngu,LANGUAGE,LANGUAGE
SM16914,20.5833,54.75,Gur'yevskiy Rayon (excl. Kaliningrad),ADM2,DISTRICT
SM292347,26.9728,45.6136,Gura Calitei,ADM2,DISTRICT
SM290272,25.2985,44.7571,Gura Foii,ADM2,DISTRICT
SM291803,25.8777,47.5419,Gura Humorului,ADM2,DISTRICT
SM290273,25.5742,44.9345,Gura Ocnitei,ADM2,DISTRICT
SM291722,23.9842,45.7301,Gura Raului,ADM2,DISTRICT
SM292193,24.2816,45.089,Gura Suhasului,ADM2,DISTRICT
SM290274,25.4758,44.7616,Gura Sutii,ADM2,DISTRICT
SM289880,26.4344,45.507,Gura Teghii,ADM2,DISTRICT
SM291533,26.4417,45.0536,Gura Vadului,ADM2,DISTRICT
SM292111,26.8231,46.2731,Gura Vaii,ADM2,DISTRICT
SM291535,26.0331,45.1495,Gura Vitioarei,ADM2,DISTRICT
SM1421,-65.9718,18.2716,Gurabo,ADM1,DISTRICT
SM15755,-65.9106,18.2528,Gurabo Abajo Barrio,ADM2,DISTRICT
SM15756,-65.881,18.244,Gurabo Arriba Barrio,ADM2,DISTRICT
SM15757,-65.9728,18.2553,Gurabo Barrio-Pueblo,ADM2,DISTRICT
SM253749,120.67,-22.05,Guradjura,ETHNICITY,ETHNICITY
SM253749,120.67,-22.05,Guradjura,ETHNICITY,ETHNICITY
SM253749,122.3629,-22.9096,Guradjura,ETHNICITY,ETHNICITY
SM253749,122.3629,-22.9096,Guradjura,ETHNICITY,ETHNICITY
SM274310,38,8.25,Gurage,ADM2,DISTRICT
SM231928,134.095,-12.6686,Guragone,LANGUAGE,LANGUAGE
SM290504,22.3351,46.2922,Gurahont,ADM2,DISTRICT
SM253750,116.65,-22.29,Gurama,ETHNICITY,ETHNICITY
SM253750,116.65,-22.29,Gurama,ETHNICITY,ETHNICITY
SM253750,117.1253,-22.3454,Gurama,ETHNICITY,ETHNICITY
SM253750,117.1253,-22.3454,Gurama,ETHNICITY,ETHNICITY
SM231930,152.933,-4.2667,Guramalum,LANGUAGE,LANGUAGE
SM231927,44.4956,36.8377,Gurani,LANGUAGE,LANGUAGE
SM285046,7.0232,9.322,Gurara,ADM2,DISTRICT
SM250194,37.44,7.07,Gurarie,ETHNICITY,ETHNICITY
SM250194,37.44,7.07,Gurarie,ETHNICITY,ETHNICITY
SM250194,37.44,7.07,Gurarie,ETHNICITY,ETHNICITY
SM250194,35,8,Gurarie,ETHNICITY,ETHNICITY
SM250194,35,8,Gurarie,ETHNICITY,ETHNICITY
SM250194,35,8,Gurarie,ETHNICITY,ETHNICITY
SM250194,38,8,Gurarie,ETHNICITY,ETHNICITY
SM250194,38,8,Gurarie,ETHNICITY,ETHNICITY
SM250194,38,8,Gurarie,ETHNICITY,ETHNICITY
SM290760,22.5885,45.9852,Gurasada,ADM2,DISTRICT
SM289951,26.6928,44.3754,Gurbanesti,ADM2,DISTRICT
SM260071,69.9424,33.2023,Gurbuz,ADM2,DISTRICT
SM277161,75.27,31.92,Gurdaspur (2011-),ADM2,DISTRICT
SM231933,141.145,-17.1653,Gurdjar,LANGUAGE,LANGUAGE
SM458049,117.5474,-25.6688,Gurduwanga,ETHNICITY,ETHNICITY
SM231935,151.175,-25.3893,Gureng Gureng,LANGUAGE,LANGUAGE
SM253752,55.69,37.74,Gurgan,ETHNICITY,ETHNICITY
SM231937,54.43,36.83,Gurgani,LANGUAGE,LANGUAGE
SM276860,77.0059,28.4389,Gurgaon (2005-),ADM2,DISTRICT
SM291153,24.8972,46.7852,Gurghiu,ADM2,DISTRICT
SM231936,68.565,25.737,Gurgula,LANGUAGE,LANGUAGE
SM280703,127.1338,37.6012,Guri,ADM2,DISTRICT
SM284783,10.4696,12.6575,Guri,ADM2,DISTRICT
SM1422,42.2,41.9667,Guria,ADM1,DISTRICT
SM231940,141.597,-3.5718,Guriaso,LANGUAGE,LANGUAGE
SM231939,130.379,-17.0329,Gurindji,LANGUAGE,LANGUAGE
SM231941,130.833,-17.4484,Gurindji Kriol,LANGUAGE,LANGUAGE
SM263484,-49.8688,-19.0664,Gurinhatã,ADM2,DISTRICT
SM264426,-35.4079,-7.1717,Gurinhém,ADM2,DISTRICT
SM11308,45.8,41.75,Gurjaani,ADM2,DISTRICT
SM11309,45.7942,41.7444,Gurjaanis Munitsip'alit'et'I (excl. Gurjaani),ADM2,DISTRICT
SM264427,-36.5001,-7.261,Gurjão,ADM2,DISTRICT
SM262443,25.8,42.7167,Gurkovo,ADM2,DISTRICT
SM303528,60.3333,41.8333,Gurlan,ADM2,DISTRICT
SM231945,6.7946,10.1487,Gurmana,LANGUAGE,LANGUAGE
SM231953,-6.3115,7.3495,Guro,LANGUAGE,LANGUAGE
SM280612,126.8502,37.4945,Guro,ADM2,DISTRICT
SM15053,33.5187,-16.9526,Guro,ADM2,DISTRICT
SM15054,33.569,-16.964,Guro District (excl. Guro),ADM2,DISTRICT
SM458908,134.2867,-12.2284,Gurr-goni,ETHNICITY,ETHNICITY
SM283886,36.9817,-15.4636,Gurue,ADM2,DISTRICT
SM231955,86.2899,27.4169,Gurung,LANGUAGE,LANGUAGE
SM253759,82.41,27.08,Gurung,ETHNICITY,ETHNICITY
SM231960,10.162,9.8733,Guruntum-Mbaaru,LANGUAGE,LANGUAGE
SM267241,-48.8422,-11.6667,Gurupi,ADM2,DISTRICT
SM264170,-51.6274,-1.1656,Gurupá,ADM2,DISTRICT
SM253760,145.45,-5.71,Gururumba,ETHNICITY,ETHNICITY
SM305239,30.6061,-16.3721,Guruve,ADM2,DISTRICT
SM283630,101.0471,43.2237,Gurvan tes,ADM2,DISTRICT
SM283775,103.5173,47.6217,Gurvanbulag,ADM2,DISTRICT
SM283775,103.5173,47.6217,Gurvanbulag,ADM2,DISTRICT
SM283775,103.3914,47.7336,Gurvanbulag,ADM2,DISTRICT
SM283775,103.3914,47.7336,Gurvanbulag,ADM2,DISTRICT
SM283749,98.57,47.2251,Gurvanbulag,ADM2,DISTRICT
SM283824,107.0456,45.525,Gurvansaikhan,ADM2,DISTRICT
SM283795,114.9406,49.1807,Gurvanzagal,ADM2,DISTRICT
SM283795,114.9406,49.1807,Gurvanzagal,ADM2,DISTRICT
SM283795,114.883,49.1271,Gurvanzagal,ADM2,DISTRICT
SM283795,114.883,49.1271,Gurvanzagal,ADM2,DISTRICT
SM280606,127.5013,35.2362,Gurye,ADM2,DISTRICT
SM16915,85.9694,54.2604,Guryevskiy rayon,ADM2,DISTRICT
SM9530,65.283,35.6324,Gurziwan,ADM2,DISTRICT
SM294894,40.75,55.6667,Gus'-Khrustal'nyy rayon,ADM2,DISTRICT
SM231964,146.679,-6.29,Gusan,LANGUAGE,LANGUAGE
SM285286,6.6198,11.8756,Gusau,ADM2,DISTRICT
SM16916,22.1667,54.6167,Gusevskiy Rayon,ADM2,DISTRICT
SM18116,62.3333,35.2667,Gusgy Etrap,ADM2,DISTRICT
SM274938,-0.1877,9.9703,Gushiegu,ADM2,DISTRICT
SM231968,34.7675,-0.7887,Gusii,LANGUAGE,LANGUAGE
SM231967,-16.5026,12.7761,Gusilay,LANGUAGE,LANGUAGE
SM4112,19.8337,42.5571,Gusinje,ADM1,DISTRICT
SM292195,24.127,44.7213,Gusoeni,ADM2,DISTRICT
SM283338,-99.1114,19.4841,Gustavo A. Madero,ADM2,DISTRICT
SM282644,-98.6405,26.1613,Gustavo Díaz Ordáz,ADM2,DISTRICT
SM300816,-94.5011,41.6838,Guthrie,ADM2,DISTRICT
SM270011,-74.0117,4.1849,Gutiérrez,ADM2,DISTRICT
SM282895,-97.1043,20.4435,Gutiérrez Zamora,ADM2,DISTRICT
SM305262,31.2506,-19.6088,Gutu,ADM2,DISTRICT
SM231826,144.831,-14.9424,Guugu Yimidhirr,LANGUAGE,LANGUAGE
SM459432,144.7846,-14.6536,Guugu-Nyiguudyi,ETHNICITY,ETHNICITY
SM231979,142.5244,-21.3515,Guwa,LANGUAGE,LANGUAGE
SM231980,148.867,-27.8289,Guwamu,LANGUAGE,LANGUAGE
SM231981,153.3998,-27.167,Guwar,LANGUAGE,LANGUAGE
SM458206,153.4003,-27.1677,Guwar,ETHNICITY,ETHNICITY
SM231983,126.5,-15.1,Guwidj,DIALECT,LANGUAGE
SM458618,127.0497,-15.2895,Guwij,ETHNICITY,ETHNICITY
SM231986,146.453,-5.7037,Guya,LANGUAGE,LANGUAGE
SM231985,151,-29.1,Guyambal,LANGUAGE,LANGUAGE
SM458157,151.2486,-29.3405,Guyambal,ETHNICITY,ETHNICITY
SM231988,-58.1514,6.7958,Guyana Sign Language,LANGUAGE,LANGUAGE
SM11230,-52.9999,3.9989,Guyane,ADM2,DISTRICT
SM229134,-62,9,Guyanese Creole English,LANGUAGE,LANGUAGE
SM231984,137.661,-34.739,Guyani,LANGUAGE,LANGUAGE
SM458146,149.6,-31.0838,Guyinbaraay,ETHNICITY,ETHNICITY
SM12392,-76.9956,18.2624,Guys Hill,ADM2,DISTRICT
SM268837,-61.4985,45.3835,Guysborough,ADM2,DISTRICT
SM269268,106.2327,35.935,Guyuan,ADM2,DISTRICT
SM284849,11.9205,9.8235,Guyuk,ADM2,DISTRICT
SM285362,13.214,12.9185,Guzamala,ADM2,DISTRICT
SM18398,66.1414,38.5236,Guzar District,ADM2,DISTRICT
SM260021,62.0387,34.0355,Guzara,ADM2,DISTRICT
SM9531,69.5748,36.2295,Guzargah-e Nur,ADM2,DISTRICT
SM15758,-65.8369,18.3512,Guzman Abajo Barrio,ADM2,DISTRICT
SM15759,-65.8326,18.2987,Guzman Arriba Barrio,ADM2,DISTRICT
SM266562,-50.7193,-20.6204,Guzolandia,ADM2,DISTRICT
SM270942,-83.6667,10.2,Guácimo,ADM2,DISTRICT
SM270987,-77.3144,21.2645,Guáimaro,ADM2,DISTRICT
SM1399,-66.9121,17.9883,Guánica,ADM1,DISTRICT
SM1402,-66.5833,8.6667,Guárico,ADM1,DISTRICT
SM270434,-75.8098,5.3253,Guática,ADM2,DISTRICT
SM275002,-10.25,8.6667,Guéckédou,ADM1:ADM2,DISTRICT
SM295247,-17.3956,14.7761,Guédiawaye,ADM2,DISTRICT
SM269492,-7.1779,7.093,Guémon,ADM2,DISTRICT
SM296837,17.9591,11.3011,Guéra,ADM2,DISTRICT
SM1411,18.5,11.5,Guéra,ADM1,DISTRICT
SM283996,-11.9778,16.8646,Guérou,ADM2,DISTRICT
SM293050,21,54.6667,Gvardeyskiy rayon,ADM2,DISTRICT
SM13189,79,44.5,Gvardiya Audany,ADM2,DISTRICT
SM231992,13.7537,10.9918,Gvoko,LANGUAGE,LANGUAGE
SM305456,15.9,45.3333,Gvozd,ADM2,DISTRICT
SM231993,9.1143,10.3069,Gwa,LANGUAGE,LANGUAGE
SM280704,126.9999,37.4341,Gwacheon,ADM2,DISTRICT
SM285239,5.3106,13.4476,Gwadabaw,ADM2,DISTRICT
SM375382,63.181,25.3934,Gwadar,ADM2:ADM3,DISTRICT
SM284741,6.9854,9.0764,Gwagwala,ADM2,DISTRICT
SM231997,146.455,-5.6456,Gwahatike,LANGUAGE,LANGUAGE
SM284862,8.4982,11.9821,Gwale,ADM2,DISTRICT
SM276980,78.2,26.2,Gwalior (1996-),ADM2,DISTRICT
SM235635,34.2457,9.5084,Gwama,LANGUAGE,LANGUAGE
SM231999,5.079,11.8081,Gwamhi-Wuri,LANGUAGE,LANGUAGE
SM280613,126.9524,37.4788,Gwanak,ADM2,DISTRICT
SM305275,29.1756,-21.2093,Gwanda,ADM2,DISTRICT
SM232005,7.4993,9.2046,Gwandara,LANGUAGE,LANGUAGE
SM250278,6.26,11.86,Gwandara,ETHNICITY,ETHNICITY
SM284933,4.6649,12.517,Gwandu,ADM2,DISTRICT
SM280614,127.0837,37.5391,Gwang-jin,ADM2,DISTRICT
SM280705,127.2986,37.4033,Gwangju,ADM2,DISTRICT
SM1424,126.9167,35.1667,Gwangju,ADM1,DISTRICT
SM280706,126.865,37.4444,Gwangmyeong,ADM2,DISTRICT
SM17881,126.8081,35.1616,Gwangsan-gu,ADM2,DISTRICT
SM280607,127.6095,35.0401,Gwangyang,ADM2,DISTRICT
SM232012,7.88,9.49,Gwara,LANGUAGE,LANGUAGE
SM284784,9.9686,11.1858,Gwaram,ADM2,DISTRICT
SM250247,5.36,12.86,Gwari,ETHNICITY,ETHNICITY
SM250247,5.36,12.86,Gwari,ETHNICITY,ETHNICITY
SM250247,5.36,12.86,Gwari,ETHNICITY,ETHNICITY
SM250247,7,10,Gwari,ETHNICITY,ETHNICITY
SM250247,7,10,Gwari,ETHNICITY,ETHNICITY
SM250247,7,10,Gwari,ETHNICITY,ETHNICITY
SM250247,10,10,Gwari,ETHNICITY,ETHNICITY
SM250247,10,10,Gwari,ETHNICITY,ETHNICITY
SM250247,10,10,Gwari,ETHNICITY,ETHNICITY
SM284863,7.9559,11.8843,Gwarzo,ADM2,DISTRICT
SM232016,150.421,-10.2173,Gweda,LANGUAGE,LANGUAGE
SM14049,-7.9367,4.9452,Gwelekpoken District (excl. Gedabo),ADM2,DISTRICT
SM305212,27.885,-16.6408,Gwembe,ADM2,DISTRICT
SM232019,37.6827,-3.6841,Gweno,LANGUAGE,LANGUAGE
SM285358,8.4833,7.3,Gwer East,ADM2,DISTRICT
SM285359,8.2166,7.6321,GwerWest,ADM2,DISTRICT
SM232022,33.8227,1.1139,Gwere,LANGUAGE,LANGUAGE
SM305224,29.645,-19.4567,Gweru,ADM2,DISTRICT
SM232023,-142.963,67.4307,Gwich'in,LANGUAGE,LANGUAGE
SM253767,127.75,-15.09,Gwini,ETHNICITY,ETHNICITY
SM253767,127.75,-15.09,Gwini,ETHNICITY,ETHNICITY
SM253767,126.3425,-14.2181,Gwini,ETHNICITY,ETHNICITY
SM253767,126.3425,-14.2181,Gwini,ETHNICITY,ETHNICITY
SM300503,-84.0236,33.9617,Gwinnett,ADM2,DISTRICT
SM284785,8.2812,12.7541,Gwiwa,ADM2,DISTRICT
SM285363,13.5793,11.1764,Gwoza,ADM2,DISTRICT
SM261550,150.5381,-29.4981,Gwydir,ADM2,DISTRICT
SM274747,-3.9167,52.8333,Gwynedd,ADM1:ADM2,DISTRICT
SM241086,9.6497,10.3478,Gyaazi,LANGUAGE,LANGUAGE
SM11568,19.2754,47.3682,Gyali Jaras,ADM2,DISTRICT
SM232029,84.22,22.59,Gyalsumdo,DIALECT,LANGUAGE
SM240689,84.7772,28.5092,Gyalsumdo-Nubri,LANGUAGE,LANGUAGE
SM232031,10.2493,2.4746,Gyele,LANGUAGE,LANGUAGE
SM232032,9.2392,10.4109,Gyem,LANGUAGE,LANGUAGE
SM1425,127.25,37.6,Gyeonggi-do (1981-),ADM1,DISTRICT
SM280734,129.25,35.8333,Gyeongju,ADM2,DISTRICT
SM280735,128.8,35.8333,Gyeongsan,ADM2,DISTRICT
SM1426,128.75,36.3333,Gyeongsangbuk-do (1981-),ADM1,DISTRICT
SM1427,128.25,35.25,Gyeongsangnam-do (1997-),ADM1,DISTRICT
SM280667,127.2258,36.293,Gyeryong,ADM2,DISTRICT
SM280566,126.7374,37.5543,Gyeyang,ADM2,DISTRICT
SM261632,152.6587,-26.1888,Gympie,ADM2,DISTRICT
SM11569,20.8485,46.9822,Gyomaendrodi Jaras,ADM2,DISTRICT
SM1428,17.25,47.6667,Gyor-Moson-Sopron,ADM1,DISTRICT
SM10737,33.7903,35.2784,Gypsou,ADM2,DISTRICT
SM276225,21.2245,46.5863,Gyulai,ADM2,DISTRICT
SM276125,19.9543,47.7908,Gyöngyös,ADM2,DISTRICT
SM279219,139.4699,36.148,Gyōda,ADM2,DISTRICT
SM276265,17.6794,47.6727,Győr,ADM1:ADM2,DISTRICT
SM266534,-49.5787,-22.3432,Gália,ADM2,DISTRICT
SM270469,-73.3679,5.9027,Gámbita,ADM2,DISTRICT
SM270771,-72.7372,5.8031,Gámeza,ADM2,DISTRICT
SM276261,18.6362,47.2278,Gárdony,ADM2,DISTRICT
SM296398,19.7083,67.3935,Gällivare,ADM2,DISTRICT
SM261822,16.7389,48.3934,Gänserndorf,ADM2,DISTRICT
SM268957,7.775,47.2961,Gäu,ADM2,DISTRICT
SM296620,17.1207,60.6426,Gävle,ADM2,DISTRICT
SM1311,16.25,61.5,Gävleborg,ADM1,DISTRICT
SM275211,-91.8362,14.5589,Génova,ADM2,DISTRICT
SM270422,-75.7548,4.2274,Génova,ADM2,DISTRICT
SM304542,106.6585,10.4001,Gò Công,ADM2,DISTRICT
SM304541,106.5985,10.3453,Gò Công Tây,ADM2,DISTRICT
SM304540,106.7459,10.3678,Gò Công Đông,ADM2,DISTRICT
SM304474,106.2731,11.1574,Gò Dầu,ADM2,DISTRICT
SM304231,105.2977,9.7403,Gò Quao,ADM2,DISTRICT
SM304117,106.672,10.8359,Gò Vấp,ADM2,DISTRICT
SM289483,-8.0834,40.1254,Góis,ADM2,DISTRICT
SM303675,-63.9167,11.0833,Gómez,ADM2,DISTRICT
SM282640,-99.0773,22.9614,Gómez Farias,ADM2,DISTRICT
SM281800,-103.4443,19.831,Gómez Farías,ADM2,DISTRICT
SM281532,-103.4766,25.7069,Gómez Palacio,ADM2,DISTRICT
SM270218,-75.2055,6.6985,Gómez Plata,ADM2,DISTRICT
SM288673,16.5482,51.6367,Góra,ADM2,DISTRICT
SM269482,-5.9264,6.1446,Gôh,ADM2,DISTRICT
SM1350,-5.9489,6.1318,Gôh-Djiboua,ADM1,DISTRICT
SM298564,36.5024,38.0309,Göksun,ADM2,DISTRICT
SM298273,25.8591,40.1652,Gökçeada,ADM2,DISTRICT
SM299043,32.1498,41.3134,Gökçebey,ADM2,DISTRICT
SM298885,32.7942,39.8075,Gölbaşı,ADM2,DISTRICT
SM298228,37.6499,37.79,Gölbaşı,ADM2,DISTRICT
SM298661,29.8203,40.7201,Gölcük,ADM2,DISTRICT
SM299047,42.6099,40.794,Göle,ADM2,DISTRICT
SM298241,29.5141,37.1563,Gölhisar,ADM2,DISTRICT
SM298813,37.6166,40.6873,Gölköy,ADM2,DISTRICT
SM298735,27.9215,38.7238,Gölmarmara,ADM2,DISTRICT
SM298935,38.6084,40.0607,Gölova,ADM2,DISTRICT
SM298194,30.3339,40.2955,Gölpazarı,ADM2,DISTRICT
SM298336,31.0028,40.7864,Gölyaka,ADM2,DISTRICT
SM298179,26.8511,39.3998,Gömeç,ADM2,DISTRICT
SM298162,27.6703,40.1145,Gönen,ADM2,DISTRICT
SM298479,30.5237,37.9663,Gönen,ADM2,DISTRICT
SM298736,28.2943,38.9418,Gördes,ADM2,DISTRICT
SM298444,39.0072,41.0264,Görele,ADM2,DISTRICT
SM268958,7.9203,47.3776,Gösgen,ADM2,DISTRICT
SM296569,11.9458,57.7229,Göteborg,ADM2,DISTRICT
SM296570,13.4787,58.5511,Götene,ADM2,DISTRICT
SM298772,35.531,40.4101,Göynücek,ADM2,DISTRICT
SM298218,30.7918,40.4106,Göynük,ADM2,DISTRICT
SM261910,47.8333,40.5833,Göyçay,ADM1:ADM2,DISTRICT
SM298430,38.8085,40.8937,Güce,ADM2,DISTRICT
SM298886,32.2489,40.2195,Güdül,ADM2,DISTRICT
SM231814,-54.4739,-33.8102,Güenoa,LANGUAGE,LANGUAGE
SM270476,-73.5941,6.0268,Güepsa,ADM2,DISTRICT
SM261012,-70.3333,-51.3333,Güer Aike,ADM2,DISTRICT
SM231838,-96.4572,16.8824,Güilá Zapotec,LANGUAGE,LANGUAGE
SM271017,-82.016,22.7871,Güines,ADM2,DISTRICT
SM271144,-82.4857,22.7542,Güira de Melena,ADM2,DISTRICT
SM298639,34.3507,38.4064,Gülağaç,ADM2,DISTRICT
SM298756,33.4031,36.3529,Gülnar,ADM2,DISTRICT
SM298814,38.0568,40.9668,Gülyalı,ADM2,DISTRICT
SM298788,34.6299,38.7556,Gülşehir,ADM2,DISTRICT
SM1419,39.5833,40.25,Gümüshane (1989-),ADM1,DISTRICT
SM298773,35.2211,40.8837,Gümüşhacıköy,ADM2,DISTRICT
SM298337,30.9494,40.8564,Gümüşova,ADM2,DISTRICT
SM299021,32.0068,36.8245,Gündoğmuş,ADM2,DISTRICT
SM298304,29.0732,38.1645,Güney,ADM2,DISTRICT
SM298832,40.6076,40.9753,Güneysu,ADM2,DISTRICT
SM298672,32.7374,37.2798,Güneysınır,ADM2,DISTRICT
SM298506,28.8788,41.0179,Güngören,ADM2,DISTRICT
SM298414,31.814,39.3941,Günyüzü,ADM2,DISTRICT
SM298815,37.5758,40.7745,Gürgentepe,ADM2,DISTRICT
SM298211,42.02,38.5774,Güroymak,ADM2,DISTRICT
SM299002,43.4104,38.324,Gürpınar,ADM2,DISTRICT
SM298255,29.2118,40.255,Gürsu,ADM2,DISTRICT
SM298936,37.2752,38.7329,Gürün,ADM2,DISTRICT
SM261786,16.3036,47.1016,Güssing,ADM2,DISTRICT
SM298534,26.8693,38.3705,Güzelbahçe,ADM2,DISTRICT
SM298640,34.3619,38.2863,Güzelyurt,ADM2,DISTRICT
SM298917,41.913,37.4713,Güçlükonak,ADM2,DISTRICT
SM282642,-99.0687,23.9069,Güémez,ADM2,DISTRICT
SM1292,28.6667,46.1667,Găgăuzia,ADM1,DISTRICT
SM283404,14.2861,36.0267,Għajnsielem,ADM1:ADM2,DISTRICT
SM283432,14.455,35.9236,Għargħur,ADM1:ADM2,DISTRICT
SM288672,16.0949,51.6713,Głogów,ADM2,DISTRICT
SM288997,17.8327,50.1485,Głubczyce,ADM2,DISTRICT
SM279265,132.2949,34.981,Gōtsu,ADM2,DISTRICT
SM261921,45.6667,40.5,Gədəbəy,ADM1:ADM2,DISTRICT
SM261922,46.3408,40.6937,Gəncə,ADM1:ADM2,DISTRICT
SM17425,-60.987,13.756,H'Erelle,ADM2,DISTRICT
SM12895,131.2587,32.0362,HIgashimorokata Gun,ADM2,DISTRICT
SM253910,85,22,HO,ETHNICITY,ETHNICITY
SM232040,30.4894,-3.7787,Ha,LANGUAGE,LANGUAGE
SM250279,30.23,-4.79,Ha,ETHNICITY,ETHNICITY
SM250279,30.23,-4.79,Ha,ETHNICITY,ETHNICITY
SM250279,29.73,-4.13,Ha,ETHNICITY,ETHNICITY
SM250279,29.73,-4.13,Ha,ETHNICITY,ETHNICITY
SM250279,30,-5,Ha,ETHNICITY,ETHNICITY
SM250279,30,-5,Ha,ETHNICITY,ETHNICITY
SM1433,-174.3066,-19.7513,Ha'apai,ADM1,DISTRICT
SM232064,126.5511,45.8466,Ha'erbin Mandarin,DIALECT,LANGUAGE
SM17784,40.8333,27.8333,Ha'il,ADM2,DISTRICT
SM1434,41.45,27.4,Ha'il,ADM1,DISTRICT
SM1440,34.8333,30.6667,HaDarom,ADM1,DISTRICT
SM1455,34.9167,32.0833,HaMerkaz,ADM1,DISTRICT
SM1485,35.4667,32.8667,HaZafon,ADM1,DISTRICT
SM17986,-174.2838,-19.6726,Haano,ADM2,DISTRICT
SM1439,89.1833,27.3333,Haa,ADM1,DISTRICT
SM4257,72.9798,7.0218,Haa Alifu Atholhu,ADM1:ADM2,DISTRICT
SM4258,73.1886,6.8263,Haa Dhaalu Atholhu,ADM1:ADM2,DISTRICT
SM302497,-101.54,44.2944,Haakon,ADM2,DISTRICT
SM285582,6.754,52.1551,Haaksbergen,ADM2,DISTRICT
SM11163,23.5301,58.9358,Haapsalu linn,ADM2,DISTRICT
SM285922,5.2278,51.6183,Haaren,ADM2,DISTRICT
SM285983,4.644,52.3807,Haarlem,ADM2,DISTRICT
SM304977,47.1361,14.2878,Habban,ADM2,DISTRICT
SM250710,25,11,Habbaniya,ETHNICITY,ETHNICITY
SM274027,38.4,16.275,Habero,ADM2,DISTRICT
SM300504,-83.5311,34.631,Habersham,ADM2,DISTRICT
SM262284,91.4167,24.4333,Habiganj,ADM2,DISTRICT
SM279123,135.6063,34.5578,Habikino,ADM2,DISTRICT
SM304898,45.0737,13.5921,Habil Jabr,ADM2,DISTRICT
SM296573,14.09,57.909,Habo,ADM2,DISTRICT
SM232046,125.965,-8.7657,Habu,LANGUAGE,LANGUAGE
SM304790,43.7338,16.035,Habur Zulaymah,ADM2,DISTRICT
SM270368,-73.0963,8.3439,Hacarí,ADM2,DISTRICT
SM232048,139.7914,33.1094,Hachijo,LANGUAGE,LANGUAGE
SM12896,139.7966,33.0634,Hachijo Shicho,ADM2,DISTRICT
SM278453,140.9911,40.0239,Hachimantai,ADM2,DISTRICT
SM278999,141.4813,40.4739,Hachinohe,ADM2,DISTRICT
SM279429,139.2877,35.6598,Hachiōji,ADM2,DISTRICT
SM298789,34.5628,38.9508,Hacıbektaş,ADM2,DISTRICT
SM298619,35.4583,38.6571,Hacılar,ADM2,DISTRICT
SM261911,48.9167,40.0833,Hacıqabul,ADM1:ADM2,DISTRICT
SM278556,139.2,35.4,Hadano,ADM2,DISTRICT
SM12393,-78.0038,18.4204,Haddington,ADM2,DISTRICT
SM12394,-78.0189,18.2861,Haddo,ADM2,DISTRICT
SM284786,10.0527,12.4349,Hadejia,ADM2,DISTRICT
SM250280,36.74,17.88,Hadendowa,ETHNICITY,ETHNICITY
SM250280,36.74,17.88,Hadendowa,ETHNICITY,ETHNICITY
SM250280,36.74,17.88,Hadendowa,ETHNICITY,ETHNICITY
SM250280,36,18,Hadendowa,ETHNICITY,ETHNICITY
SM250280,36,18,Hadendowa,ETHNICITY,ETHNICITY
SM250280,36,18,Hadendowa,ETHNICITY,ETHNICITY
SM250280,36,21,Hadendowa,ETHNICITY,ETHNICITY
SM250280,36,21,Hadendowa,ETHNICITY,ETHNICITY
SM250280,36,21,Hadendowa,ETHNICITY,ETHNICITY
SM271757,9.3226,55.2444,Haderslev (2007-),ADM2,DISTRICT
SM4163,49,16,Hadhramaut Governorate (2013-),ADM1,DISTRICT
SM299659,33.9917,50.3685,Hadiats'kyi,ADM2,DISTRICT
SM253773,37.11,36.11,Hadid,ETHNICITY,ETHNICITY
SM274311,37.75,7.5,Hadiya,ADM2,DISTRICT
SM253774,36.78,6.84,Hadiya,ETHNICITY,ETHNICITY
SM232054,37.7577,7.5738,Hadiyya,LANGUAGE,LANGUAGE
SM1441,16.1667,12.6667,Hadjer-Lamis,ADM1,DISTRICT
SM280559,127.7729,35.1363,Hadong,ADM2,DISTRICT
SM232057,75.8366,24.8546,Hadothi,LANGUAGE,LANGUAGE
SM232058,47.603,15.528,Hadrami,LANGUAGE,LANGUAGE
SM232059,48.6524,15.4468,Hadrami Arabic,LANGUAGE,LANGUAGE
SM286062,14.9084,68.5633,Hadsel,ADM2,DISTRICT
SM280792,102.7439,17.9197,Hadxaifong,ADM2,DISTRICT
SM232061,35.0458,-3.8312,Hadza,LANGUAGE,LANGUAGE
SM250281,34.3,-3.95,Hadza,ETHNICITY,ETHNICITY
SM250281,34.3,-3.95,Hadza,ETHNICITY,ETHNICITY
SM250281,34.3,-3.95,Hadza,ETHNICITY,ETHNICITY
SM250281,34.3,-3.95,Hadza,ETHNICITY,ETHNICITY
SM250281,34.3,-3.95,Hadza,ETHNICITY,ETHNICITY
SM250281,34.3,-3.95,Hadza,ETHNICITY,ETHNICITY
SM250281,34.3,-3.95,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3,Hadza,ETHNICITY,ETHNICITY
SM250281,35,-3,Hadza,ETHNICITY,ETHNICITY
SM250281,35.32,-3.82,Hadza,ETHNICITY,ETHNICITY
SM250281,35.32,-3.82,Hadza,ETHNICITY,ETHNICITY
SM250281,35.32,-3.82,Hadza,ETHNICITY,ETHNICITY
SM250281,35.32,-3.82,Hadza,ETHNICITY,ETHNICITY
SM250281,35.32,-3.82,Hadza,ETHNICITY,ETHNICITY
SM250281,35.32,-3.82,Hadza,ETHNICITY,ETHNICITY
SM250281,35.32,-3.82,Hadza,ETHNICITY,ETHNICITY
SM250281,35.18,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35.18,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35.18,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35.18,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35.18,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35.18,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35.18,-3.75,Hadza,ETHNICITY,ETHNICITY
SM250281,35.1,-3.6,Hadza,ETHNICITY,ETHNICITY
SM250281,35.1,-3.6,Hadza,ETHNICITY,ETHNICITY
SM250281,35.1,-3.6,Hadza,ETHNICITY,ETHNICITY
SM250281,35.1,-3.6,Hadza,ETHNICITY,ETHNICITY
SM250281,35.1,-3.6,Hadza,ETHNICITY,ETHNICITY
SM250281,35.1,-3.6,Hadza,ETHNICITY,ETHNICITY
SM250281,35.1,-3.6,Hadza,ETHNICITY,ETHNICITY
SM250281,35.3,-3.8,Hadza,ETHNICITY,ETHNICITY
SM250281,35.3,-3.8,Hadza,ETHNICITY,ETHNICITY
SM250281,35.3,-3.8,Hadza,ETHNICITY,ETHNICITY
SM250281,35.3,-3.8,Hadza,ETHNICITY,ETHNICITY
SM250281,35.3,-3.8,Hadza,ETHNICITY,ETHNICITY
SM250281,35.3,-3.8,Hadza,ETHNICITY,ETHNICITY
SM250281,35.3,-3.8,Hadza,ETHNICITY,ETHNICITY
SM262298,23.8333,41.5,Hadzhidimovo,ADM2,DISTRICT
SM298673,32.4612,36.9979,Hadım,ADM2,DISTRICT
SM11164,24.5383,58.05,Haeaedemeeste vald,ADM2,DISTRICT
SM289164,125.7486,38.0769,Haeju,ADM2,DISTRICT
SM232062,164.869,-21.0735,Haeke,LANGUAGE,LANGUAGE
SM280608,126.5187,34.5405,Haenam,ADM2,DISTRICT
SM280540,129.1679,35.1666,Haeundae,ADM2,DISTRICT
SM298105,9.7149,35.6228,Haffouz,ADM2,DISTRICT
SM298937,37.3972,39.8669,Hafik,ADM2,DISTRICT
SM375456,73.5132,32.0219,Hafizabad,ADM2:ADM3,DISTRICT
SM277783,-21.9606,64.0224,Hafnarfjörður,ADM2,DISTRICT
SM17785,46.0833,28.25,Hafr al Batin,ADM2,DISTRICT
SM11771,49.4,31.5667,Haftkel,ADM2,DISTRICT
SM457999,140.1124,36.5031,Haga District,ADM2,DISTRICT
SM274028,38.2687,15.7092,Hagaz,ADM2,DISTRICT
SM231731,104.698,22.5632,Hagei Gelao,LANGUAGE,LANGUAGE
SM296516,13.6076,60.09,Hagfors,ADM2,DISTRICT
SM290216,25.595,45.8536,Haghig,ADM2,DISTRICT
SM279589,131.5062,34.4321,Hagi,ADM2,DISTRICT
SM12395,-76.6278,18.0485,Hagley Gap,ADM2,DISTRICT
SM304810,47.9537,16.3056,Hagr As Sai'ar,ADM2,DISTRICT
SM1443,144.7579,13.4728,Hagåtña,ADM1,DISTRICT
SM304687,-176.201,-13.2835,Hahake,ADM2,DISTRICT
SM17987,-173.9358,-18.6068,Hahake,ADM2,DISTRICT
SM299709,33.4393,44.5922,Haharins'kyi,ADM2,DISTRICT
SM232073,154.801,-5.5903,Hahon,LANGUAGE,LANGUAGE
SM299249,37.1912,-3.2543,Hai,ADM2,DISTRICT
SM250282,23,-22.25,Hai,ETHNICITY,ETHNICITY
SM250282,23,-22.25,Hai,ETHNICITY,ETHNICITY
SM250282,23,-22.25,Hai,ETHNICITY,ETHNICITY
SM250282,16.12,-18.65,Hai,ETHNICITY,ETHNICITY
SM250282,16.12,-18.65,Hai,ETHNICITY,ETHNICITY
SM250282,16.12,-18.65,Hai,ETHNICITY,ETHNICITY
SM250282,16.9695,-19.3451,Hai,ETHNICITY,ETHNICITY
SM250282,16.9695,-19.3451,Hai,ETHNICITY,ETHNICITY
SM250282,16.9695,-19.3451,Hai,ETHNICITY,ETHNICITY
SM304135,105.8554,21.0063,Hai Bà Trưng,ADM2,DISTRICT
SM232088,17.0299,-19.7637,Hai//om-Akhoe,LANGUAGE,LANGUAGE
SM12897,138.1066,35.1503,Haibara-gun,ADM2,DISTRICT
SM269275,100.4566,37.7192,Haibei Tibetan,ADM2,DISTRICT
SM289072,126.4167,39.0833,Haichang,ADM2,DISTRICT
SM253776,-132.31,53.47,Haida,ETHNICITY,ETHNICITY
SM253776,-132.31,53.47,Haida,ETHNICITY,ETHNICITY
SM253776,-132.31,53.47,Haida,ETHNICITY,ETHNICITY
SM253776,-132.31,53.47,Haida,ETHNICITY,ETHNICITY
SM253776,-132,54,Haida,ETHNICITY,ETHNICITY
SM253776,-132,54,Haida,ETHNICITY,ETHNICITY
SM253776,-132,54,Haida,ETHNICITY,ETHNICITY
SM253776,-132,54,Haida,ETHNICITY,ETHNICITY
SM253776,-132.29,53.54,Haida,ETHNICITY,ETHNICITY
SM253776,-132.29,53.54,Haida,ETHNICITY,ETHNICITY
SM253776,-132.29,53.54,Haida,ETHNICITY,ETHNICITY
SM253776,-132.29,53.54,Haida,ETHNICITY,ETHNICITY
SM253776,-132.5,54,Haida,ETHNICITY,ETHNICITY
SM253776,-132.5,54,Haida,ETHNICITY,ETHNICITY
SM253776,-132.5,54,Haida,ETHNICITY,ETHNICITY
SM253776,-132.5,54,Haida,ETHNICITY,ETHNICITY
SM269276,102.3716,36.3158,Haidong,ADM2,DISTRICT
SM1444,35,32.5833,Haifa,ADM1,DISTRICT
SM232076,150.256,-10.2741,Haigwai,LANGUAGE,LANGUAGE
SM253777,-128.5,53,Haihai,ETHNICITY,ETHNICITY
SM232083,110.3294,20.0439,Haikou,DIALECT,LANGUAGE
SM269460,110.4217,19.8306,Haikou,ADM2,DISTRICT
SM277380,92.6007,24.5017,Hailakandi,ADM2,DISTRICT
SM1445,109.75,19.25,Hainan,ADM1,DISTRICT
SM269277,100.3405,35.8173,Hainan Tibetan,ADM2,DISTRICT
SM262099,3.8333,50.5,Hainaut,ADM1:ADM2,DISTRICT
SM301197,-135.5006,59.2506,Haines,ADM2,DISTRICT
SM253778,109.96,19.3,Hainna,ETHNICITY,ETHNICITY
SM232086,-5.6509,13.8734,Hainyaxo Bozo,LANGUAGE,LANGUAGE
SM232089,106.689,20.8537,Haiphong Sign Language,LANGUAGE,LANGUAGE
SM262490,23.6667,43.5833,Hairedin,ADM2,DISTRICT
SM232090,-128.602,53.964,Haisla,LANGUAGE,LANGUAGE
SM253779,-129,54,Haisla,ETHNICITY,ETHNICITY
SM253779,-129,54,Haisla,ETHNICITY,ETHNICITY
SM253779,-129,54,Haisla,ETHNICITY,ETHNICITY
SM253779,-128.42,53.5,Haisla,ETHNICITY,ETHNICITY
SM253779,-128.42,53.5,Haisla,ETHNICITY,ETHNICITY
SM253779,-128.42,53.5,Haisla,ETHNICITY,ETHNICITY
SM253779,-128,53.5,Haisla,ETHNICITY,ETHNICITY
SM253779,-128,53.5,Haisla,ETHNICITY,ETHNICITY
SM253779,-128,53.5,Haisla,ETHNICITY,ETHNICITY
SM232092,-72.1361,18.7951,Haitian,LANGUAGE,LANGUAGE
SM253780,-72.7,19,Haitian,ETHNICITY,ETHNICITY
SM253780,-72.7,19,Haitian,ETHNICITY,ETHNICITY
SM253780,-72.7,19,Haitian,ETHNICITY,ETHNICITY
SM253780,-72.17,18.41,Haitian,ETHNICITY,ETHNICITY
SM253780,-72.17,18.41,Haitian,ETHNICITY,ETHNICITY
SM253780,-72.17,18.41,Haitian,ETHNICITY,ETHNICITY
SM253780,-72.17,18.83,Haitian,ETHNICITY,ETHNICITY
SM253780,-72.17,18.83,Haitian,ETHNICITY,ETHNICITY
SM253780,-72.17,18.83,Haitian,ETHNICITY,ETHNICITY
SM454405,-74.133,40.133,Haitian Americans,ETHNICITY,ETHNICITY
SM232093,-72.285,18.541,Haitian Sign Language,LANGUAGE,LANGUAGE
SM232091,-72.394,18.911,Haitian Vodoun Culture Language,LANGUAGE,LANGUAGE
SM299523,29.8651,48.3425,Haivorons'kyi,ADM2,DISTRICT
SM10359,95.4597,37.0825,Haixi Mengguzu Zangzu Zizhizhou,ADM2,DISTRICT
SM296355,15.8272,46.4175,Hajdina,ADM1:ADM2,DISTRICT
SM11570,21.7,47.67,Hajduhadhazi Jaras,ADM2,DISTRICT
SM1446,21.5,47.4167,Hajdú-Bihar,ADM1,DISTRICT
SM276275,21.5,47.74,Hajdúböszörmény,ADM2,DISTRICT
SM276277,21.31,47.4811,Hajdúszoboszló,ADM2,DISTRICT
SM298106,9.5732,35.4228,Hajeb El Ayoun,ADM2,DISTRICT
SM232094,104.714,-5.0778,Haji,LANGUAGE,LANGUAGE
SM277499,55.8333,28.3333,Hajiabad,ADM2,DISTRICT
SM304838,43.45,15.6816,Hajjah,ADM2,DISTRICT
SM1447,43.25,16,Hajjah,ADM1,DISTRICT
SM11364,43.6047,15.6878,Hajjah District (excl. Hajjah City),ADM2,DISTRICT
SM288692,23.5572,52.7085,Hajnówka,ADM2,DISTRICT
SM232096,89.8594,25.5143,Hajong,LANGUAGE,LANGUAGE
SM304811,48.2774,14.4708,Hajr,ADM2,DISTRICT
SM232097,92.8208,22.8735,Haka Chin,LANGUAGE,LANGUAGE
SM253784,14.33,-15.63,Hakavona,ETHNICITY,ETHNICITY
SM15083,93.5636,22.5124,Hakha,ADM2,DISTRICT
SM253785,117.15,26.01,Hakka,ETHNICITY,ETHNICITY
SM232101,116,25,Hakka Chinese,LANGUAGE,LANGUAGE
SM1448,44.1667,37.5833,Hakkari (1990-),ADM1,DISTRICT
SM278253,140.926,41.8375,Hakodate,ADM2,DISTRICT
SM12898,136.7812,37.0046,Hakui Gun,ADM2,DISTRICT
SM278423,136.8163,36.9114,Hakui Town,ADM2,DISTRICT
SM278424,136.6697,36.2756,Hakusan,ADM2,DISTRICT
SM232102,154.594,-5.0683,Hakö,LANGUAGE,LANGUAGE
SM305845,45.9123,35.1989,Halabja,ADM1:ADM2,DISTRICT
SM282965,-90.1138,20.5059,Halachó,ADM2,DISTRICT
SM243088,91.6649,23.8194,Halam,LANGUAGE,LANGUAGE
SM232103,107.457,14.7862,Halang,LANGUAGE,LANGUAGE
SM232104,107.86,14.9704,Halang Doan,LANGUAGE,LANGUAGE
SM290830,26.8097,47.1059,Halaucesti,ADM2,DISTRICT
SM232106,81.3742,20.3081,Halbi,LANGUAGE,LANGUAGE
SM253796,-113.95,34.22,Halchidhoma,ETHNICITY,ETHNICITY
SM286296,11.3871,59.1226,Halden,ADM2,DISTRICT
SM285924,4.506,51.5893,Halderberge,ADM2,DISTRICT
SM268847,-79.8663,42.9834,Haldimand-Norfolk,ADM2,DISTRICT
SM302888,-101.8269,34.0705,Hale,ADM2,DISTRICT
SM300272,-87.6291,32.7627,Hale,ADM2,DISTRICT
SM253799,36.21,16.81,Halenga,ETHNICITY,ETHNICITY
SM323958,-114.1742,52.3464,Half Moon Bay,ADM2:ADM3,DISTRICT
SM12396,-76.7958,18.0126,Half-Way-Tree,ADM2,DISTRICT
SM298870,37.8794,37.254,Halfeti,ADM2,DISTRICT
SM232110,106.289,48.324,Halh Mongolian,LANGUAGE,LANGUAGE
SM274029,38.229,15.993,Halhal,ADM2,DISTRICT
SM232113,154.662,-5.266,Halia,LANGUAGE,LANGUAGE
SM268848,-78.4996,45.1668,Haliburton,ADM2,DISTRICT
SM303021,-78.9366,36.767,Halifax,ADM2,DISTRICT
SM301967,-77.6519,36.2575,Halifax,ADM2,DISTRICT
SM268838,-63.7154,44.8669,Halifax,ADM2,DISTRICT
SM18048,38.796,37.1414,Haliliye,ADM2,DISTRICT
SM304885,44.9443,13.7088,Halimayn,ADM2,DISTRICT
SM11165,26.2202,59.4543,Haljala vald,ADM2,DISTRICT
SM298674,34.1934,37.4436,Halkapınar,ADM2,DISTRICT
SM323930,-112.1533,52.2845,Halkirk,ADM2:ADM3,DISTRICT
SM232116,-122.029,48.0751,Halkomelem,LANGUAGE,LANGUAGE
SM232116,-122.029,48.0751,Halkomelem,LANGUAGE,LANGUAGE
SM232116,-122.4437,49.2504,Halkomelem,LANGUAGE,LANGUAGE
SM232116,-122.4437,49.2504,Halkomelem,LANGUAGE,LANGUAGE
SM302889,-100.6811,34.5308,Hall,ADM2,DISTRICT
SM301712,-98.5022,40.8726,Hall,ADM2,DISTRICT
SM300505,-83.8197,34.3169,Hall,ADM2,DISTRICT
SM1449,13,56.75,Halland,ADM1,DISTRICT
SM261846,13.3,47.6,Hallein,ADM2,DISTRICT
SM261387,127.452,-19.0007,Halls Creek,ADM2,DISTRICT
SM296409,15.1847,59.038,Hallsberg,ADM2,DISTRICT
SM296560,16.228,59.617,Hallstahammar,ADM2,DISTRICT
SM290505,22.6318,46.2766,Halmagel,ADM2,DISTRICT
SM290507,22.5818,46.2754,Halmagiu,ADM2,DISTRICT
SM276453,127.5526,1.4171,Halmahera Barat,ADM2,DISTRICT
SM276454,127.9083,-0.3955,Halmahera Selatan,ADM2,DISTRICT
SM276455,128.25,0.4806,Halmahera Tengah,ADM2,DISTRICT
SM276456,128.4863,1.3352,Halmahera Timur,ADM2,DISTRICT
SM276457,128.0078,1.7319,Halmahera Utara,ADM2,DISTRICT
SM291607,22.5966,47.159,Halmasd,ADM2,DISTRICT
SM296630,12.9734,56.7392,Halmstad,ADM2,DISTRICT
SM271667,11.9421,55.9677,Halsnæs,ADM2,DISTRICT
SM274633,-2.75,53.3333,Halton,ADM1:ADM2,DISTRICT
SM268849,-79.8829,43.5001,Halton,ADM2,DISTRICT
SM253807,-115.28,31.74,Halyikwamai,ETHNICITY,ETHNICITY
SM299984,24.7345,49.1225,Halyts'kyi,ADM2,DISTRICT
SM232123,15.59,9.9517,Ham,LANGUAGE,LANGUAGE
SM296779,37.1263,35.2858,Hama,ADM2,DISTRICT
SM279266,132.1391,34.8281,Hamada,ADM2,DISTRICT
SM277478,48.8,34.85,Hamadan,ADM2,DISTRICT
SM1451,48.5833,34.8333,Hamadan,ADM1,DISTRICT
SM1452,37.2,35.2333,Hamah,ADM1,DISTRICT
SM250705,9,30,Hamama,ETHNICITY,ETHNICITY
SM279314,137.8073,34.9538,Hamamatsu,ADM2,DISTRICT
SM298774,35.0337,40.7954,Hamamözü,ADM2,DISTRICT
SM280560,128.4297,35.2912,Haman,ADM2,DISTRICT
SM232122,124.522,-8.2675,Hamap,LANGUAGE,LANGUAGE
SM286376,11.0783,60.7945,Hamar,ADM2,DISTRICT
SM461873,15.6167,68.0838,Hamarøy (2020-),ADM2,DISTRICT
SM18599,38.6667,15.4167,Hamasen Awraja,ADM2,DISTRICT
SM232125,24.2299,-2.4905,Hamba de Lomela,LANGUAGE,LANGUAGE
SM1453,81.1111,6.2544,Hambantota,ADM1:ADM2,DISTRICT
SM302569,-83.2667,36.2171,Hamblen,ADM2,DISTRICT
SM269468,-5.154,8.3037,Hambol,ADM2,DISTRICT
SM1454,10,53.5833,Hamburg,ADM1,DISTRICT
SM292046,28.3938,45.1156,Hamcearca,ADM2,DISTRICT
SM304935,44.0603,15.5066,Hamdan,ADM2,DISTRICT
SM250283,36,5,Hamer,ETHNICITY,ETHNICITY
SM232129,36.5176,5.0652,Hamer-Banna,LANGUAGE,LANGUAGE
SM232131,129.84,42.34,Hamgyongdo,DIALECT,LANGUAGE
SM1456,129.4167,41.8333,Hamgyŏng-bukto,ADM1,DISTRICT
SM1457,127.75,40.2333,Hamgyŏng-namdo,ADM1,DISTRICT
SM289125,127.4886,39.9656,Hamhŭng,ADM2,DISTRICT
SM269354,93.45,42.8,Hami,ADM2,DISTRICT
SM11772,48.433,31.4811,Hamidiyeh,ADM2,DISTRICT
SM302570,-85.1648,35.1809,Hamilton,ADM2,DISTRICT
SM302890,-98.1107,31.7048,Hamilton,ADM2,DISTRICT
SM302107,-84.5428,39.1955,Hamilton,ADM2,DISTRICT
SM301880,-74.4974,43.6611,Hamilton,ADM2,DISTRICT
SM301713,-98.0232,40.8736,Hamilton,ADM2,DISTRICT
SM300818,-93.7068,42.3838,Hamilton,ADM2,DISTRICT
SM300920,-101.7913,37.9992,Hamilton,ADM2,DISTRICT
SM300712,-86.052,40.0725,Hamilton,ADM2,DISTRICT
SM300328,-82.948,30.4964,Hamilton,ADM2,DISTRICT
SM300618,-88.5391,38.0816,Hamilton,ADM2,DISTRICT
SM286508,175.2598,-37.7779,Hamilton,ADM2,DISTRICT
SM268850,-79.931,43.2552,Hamilton,ADM2,DISTRICT
SM1458,-64.7283,32.3422,Hamilton,ADM1,DISTRICT
SM1459,-64.7806,32.2933,Hamilton Municipality,ADM1,DISTRICT
SM10180,-100.5967,50.165,Hamiota (2015-),ADM2,DISTRICT
SM276870,76.5,31.75,"Hamirpur, HP",ADM2,DISTRICT
SM277295,80,25.75,"Hamirpur, UP (1995-)",ADM2,DISTRICT
SM250622,0.23,32.85,Hamiyan,ETHNICITY,ETHNICITY
SM250622,0.23,32.85,Hamiyan,ETHNICITY,ETHNICITY
SM250622,2,35,Hamiyan,ETHNICITY,ETHNICITY
SM250622,2,35,Hamiyan,ETHNICITY,ETHNICITY
SM289126,127.3069,39.9222,Hamju,ADM2,DISTRICT
SM302498,-97.1883,44.6738,Hamlin,ADM2,DISTRICT
SM298074,9.5943,33.8926,Hamma,ADM2,DISTRICT
SM298051,10.3697,36.7174,Hammam Chott,ADM2,DISTRICT
SM297935,11.0762,36.9227,Hammam Ghezaz,ADM2,DISTRICT
SM298052,10.3223,36.7192,Hammam Lif,ADM2,DISTRICT
SM297996,10.586,35.8823,Hammam Sousse,ADM2,DISTRICT
SM297936,10.5122,36.4369,Hammamet,ADM2,DISTRICT
SM1460,19.75,60.25,Hammarland,ADM1:ADM2,DISTRICT
SM296517,13.5487,59.307,Hammarö,ADM2,DISTRICT
SM461886,23.683,70.6626,Hammerfest (2020-),ADM2,DISTRICT
SM11773,61.3369,30.9369,Hamoon,ADM2,DISTRICT
SM301248,-72.6316,42.1351,Hampden,ADM2,DISTRICT
SM12397,-77.732,18.4422,Hampden,ADM2,DISTRICT
SM303113,-78.6142,39.3171,Hampshire,ADM2,DISTRICT
SM301249,-72.6638,42.3401,Hampshire,ADM2,DISTRICT
SM274634,-1.25,51,Hampshire,ADM1:ADM2,DISTRICT
SM12398,-76.9048,18.3205,Hampstead,ADM2,DISTRICT
SM303022,-76.333,37.0627,Hampton,ADM2,DISTRICT
SM302449,-81.1407,32.7763,Hampton,ADM2,DISTRICT
SM12399,-76.2613,17.9178,Hampton Court,ADM2,DISTRICT
SM12400,-76.9682,18.0054,Hampton Green,ADM2,DISTRICT
SM280609,126.5334,35.1499,Hampyeong,ADM2,DISTRICT
SM232136,146.227,-7.5498,Hamtai,LANGUAGE,LANGUAGE
SM298487,42.9928,39.6192,Hamur,ADM2,DISTRICT
SM279430,139.3141,35.7635,Hamura,ADM2,DISTRICT
SM280561,127.712,35.5523,Hamyang,ADM2,DISTRICT
SM18399,69.3464,41.2956,Hamza Tumani,ADM2,DISTRICT
SM232154,-140.352,64.5002,Han,LANGUAGE,LANGUAGE
SM298415,30.8666,39.169,Han,ADM2,DISTRICT
SM253809,-146.85,65.47,Han,ETHNICITY,ETHNICITY
SM253809,-146.85,65.47,Han,ETHNICITY,ETHNICITY
SM253809,-141.2,64.79,Han,ETHNICITY,ETHNICITY
SM253809,-141.2,64.79,Han,ETHNICITY,ETHNICITY
SM8248,114,32.5,Han Chinese,ETHNICITY,ETHNICITY
SM297663,99.9609,15.0505,Han Kha,ADM2,DISTRICT
SM313221,18.9283,44.0569,Han Pijesak,ADM2:ADM3,DISTRICT
SM299048,42.8519,41.2437,Hanak,ADM2,DISTRICT
SM280707,127.2125,37.539,Hanam,ADM2,DISTRICT
SM278460,141.1675,39.4419,Hanamaki,ADM2,DISTRICT
SM253810,-81.55,22.82,Hanamana,ETHNICITY,ETHNICITY
SM299090,35.25,-4.5,Hanang,ADM2,DISTRICT
SM253811,31.16,-22.39,Hananwa,ETHNICITY,ETHNICITY
SM262579,26.5,52.6667,Hancavichy,ADM2,DISTRICT
SM303114,-80.5739,40.5219,Hancock,ADM2,DISTRICT
SM302571,-83.2218,36.5237,Hancock,ADM2,DISTRICT
SM302108,-83.6665,41.0019,Hancock,ADM2,DISTRICT
SM301209,-68.3682,44.5629,Hancock,ADM2,DISTRICT
SM301441,-89.4746,30.3938,Hancock,ADM2,DISTRICT
SM300714,-85.7732,39.8236,Hancock,ADM2,DISTRICT
SM300819,-93.7343,43.0819,Hancock,ADM2,DISTRICT
SM301049,-86.7779,37.8415,Hancock,ADM2,DISTRICT
SM300619,-91.1647,40.4038,Hancock,ADM2,DISTRICT
SM300507,-83.0007,33.2704,Hancock,ADM2,DISTRICT
SM302499,-99.0049,44.5478,Hand,ADM2,DISTRICT
SM278083,136.9266,34.899,Handa,ADM2,DISTRICT
SM269141,114.4588,36.5315,Handan,ADM2,DISTRICT
SM299195,38.3477,-5.5503,Handeni,ADM2,DISTRICT
SM299193,37.9906,-5.4991,Handeni Township Authority,ADM2,DISTRICT
SM253815,-111.17,70.5,Haneragmiut,ETHNICITY,ETHNICITY
SM292568,27.0115,47.9199,Hanesti,ADM2,DISTRICT
SM297014,99.2798,18.355,Hang Chat,ADM2,DISTRICT
SM296893,98.8844,18.7387,Hang Dong,ADM2,DISTRICT
SM232142,-1.5783,9.3393,Hanga,LANGUAGE,LANGUAGE
SM232147,142.849,-3.9036,Hanga Hundi,LANGUAGE,LANGUAGE
SM232144,30.7074,-2.5235,Hangaza,LANGUAGE,LANGUAGE
SM289073,125.4636,38.7644,Hanggu,ADM2,DISTRICT
SM291340,26.064,47.0353,Hangu,ADM2,DISTRICT
SM375422,70.8877,33.4472,Hangu,ADM2:ADM3,DISTRICT
SM232141,120.0318,30.2613,Hangzhou,DIALECT,LANGUAGE
SM269382,119.6,30,Hangzhou,ADM2,DISTRICT
SM232149,102.8517,22.6775,Hani,LANGUAGE,LANGUAGE
SM298331,40.3963,38.4114,Hani,ADM2,DISTRICT
SM13348,21.2657,42.1389,Hani i Elezit,ADM2,DISTRICT
SM253818,-94.83,60.97,Haningayogmiut,ETHNICITY,ETHNICITY
SM296490,18.1549,59.1738,Haninge,ADM2,DISTRICT
SM229076,-124.16,43.4413,Hanis,LANGUAGE,LANGUAGE
SM323872,-111.9268,51.6428,Hanna,ADM2:ADM3,DISTRICT
SM12401,-76.7943,17.98,Hannah Town/ Craig Town,ADM2,DISTRICT
SM279128,135.2352,34.3312,Hannan,ADM2,DISTRICT
SM279221,139.3278,35.8557,Hannō,ADM2,DISTRICT
SM232156,168.167,-15.5432,Hano,LANGUAGE,LANGUAGE
SM253820,-111,36,Hano,ETHNICITY,ETHNICITY
SM232155,105.834,21.0245,Hanoi Sign Language,LANGUAGE,LANGUAGE
SM303023,-77.4909,37.7602,Hanover,ADM2,DISTRICT
SM327743,-96.8339,49.4667,Hanover,ADM2:ADM3,DISTRICT
SM1461,-78.1333,18.4167,Hanover,ADM1,DISTRICT
SM302891,-101.3545,36.2775,Hansford,ADM2,DISTRICT
SM302501,-97.7873,43.6748,Hanson,ADM2,DISTRICT
SM268839,-63.8654,45.0335,Hants,ADM2,DISTRICT
SM277170,74.6,29.11,Hanumangarh,ADM2,DISTRICT
SM253823,121.56,12.51,Hanunodo,ETHNICITY,ETHNICITY
SM253823,121.56,12.51,Hanunodo,ETHNICITY,ETHNICITY
SM253823,121,13,Hanunodo,ETHNICITY,ETHNICITY
SM253823,121,13,Hanunodo,ETHNICITY,ETHNICITY
SM232162,121.221,12.4159,Hanunoo,LANGUAGE,LANGUAGE
SM253824,14.55,-12.93,Hanya,ETHNICITY,ETHNICITY
SM279222,139.5534,36.1725,Hanyū,ADM2,DISTRICT
SM269284,107.045,33.1711,Hanzhong,ADM2,DISTRICT
SM298590,34.4779,41.6373,Hanönü,ADM2,DISTRICT
SM11240,-140.9467,-18.129,Hao,ADM2,DISTRICT
SM277373,88.25,22.6,Haora,ADM2,DISTRICT
SM297937,10.9113,36.963,Haouaria,ADM2,DISTRICT
SM296399,23.8461,65.9166,Haparanda,ADM2,DISTRICT
SM280543,128.1384,35.5741,Hapcheon,ADM2,DISTRICT
SM277296,77.7731,28.7313,Hapur,ADM2,DISTRICT
SM17786,35.3667,29.0667,Haql,ADM2,DISTRICT
SM253828,29.72,29.19,Harabi,ETHNICITY,ETHNICITY
SM304840,43.0774,16.4045,Harad,ADM2,DISTRICT
SM253829,142.37,-7.28,Harado,ETHNICITY,ETHNICITY
SM262683,30.1667,55.6667,Haradok,ADM2,DISTRICT
SM300508,-85.211,33.7942,Haralson,ADM2,DISTRICT
SM305229,31.0686,-17.8128,Harare,ADM2,DISTRICT
SM1462,31.0583,-17.8542,Harare,ADM1,DISTRICT
SM232171,42.1332,9.3049,Harari,LANGUAGE,LANGUAGE
SM1463,42.1667,9.25,Harari People,ADM1,DISTRICT
SM253832,55.82,19.87,Harasis,ETHNICITY,ETHNICITY
SM290761,22.9814,45.9056,Harau,ADM2,DISTRICT
SM232167,30.417,15.083,Haraza,LANGUAGE,LANGUAGE
SM10296,19.4806,13.9461,Haraze,ADM2,DISTRICT
SM296841,15.3842,12.4715,Haraze Al Biar,ADM2,DISTRICT
SM296815,20.8,9.9167,Haraze Mangueigne,ADM2,DISTRICT
SM253834,39.8,23.28,Harb,ETHNICITY,ETHNICITY
SM253834,45.33,27.56,Harb,ETHNICITY,ETHNICITY
SM269153,128.0304,45.44,Harbin,ADM2,DISTRICT
SM1464,-76.6351,25.4895,Harbour Island,ADM1,DISTRICT
SM12402,-76.7166,17.9515,Harbour View,ADM2,DISTRICT
SM276981,76.9643,22.2341,Harda,ADM2,DISTRICT
SM1465,17.25,-24.5,Hardap,ADM1,DISTRICT
SM300329,-81.8099,27.4927,Hardee,ADM2,DISTRICT
SM302892,-99.7457,34.2903,Hardeman,ADM2,DISTRICT
SM302572,-88.9931,35.2069,Hardeman,ADM2,DISTRICT
SM285583,6.5348,52.5931,Hardenberg,ADM2,DISTRICT
SM285778,5.6395,52.3454,Harderwijk,ADM2,DISTRICT
SM302573,-88.1845,35.1987,Hardin,ADM2,DISTRICT
SM302641,-94.3902,30.3324,Hardin,ADM2,DISTRICT
SM302109,-83.6594,40.6615,Hardin,ADM2,DISTRICT
SM301050,-85.9634,37.6979,Hardin,ADM2,DISTRICT
SM300820,-93.2404,42.3839,Hardin,ADM2,DISTRICT
SM300620,-88.2669,37.5182,Hardin,ADM2,DISTRICT
SM302502,-103.4958,45.5804,Harding,ADM2,DISTRICT
SM301837,-103.8199,35.8579,Harding,ADM2,DISTRICT
SM285651,4.8643,51.8259,Hardinxveld-Giessendam,ADM2,DISTRICT
SM323931,-111.3146,52.6712,Hardisty,ADM2:ADM3,DISTRICT
SM277297,80.25,27.4167,Hardoi,ADM2,DISTRICT
SM277353,78.0746,29.9697,Hardwar,ADM2,DISTRICT
SM303115,-78.8579,39.0075,Hardy,ADM2,DISTRICT
SM253835,-130.36,67.54,Hare,ETHNICITY,ETHNICITY
SM253835,-130.36,67.54,Hare,ETHNICITY,ETHNICITY
SM253835,-130.36,67.54,Hare,ETHNICITY,ETHNICITY
SM253835,-129.88,65.58,Hare,ETHNICITY,ETHNICITY
SM253835,-129.88,65.58,Hare,ETHNICITY,ETHNICITY
SM253835,-129.88,65.58,Hare,ETHNICITY,ETHNICITY
SM253835,-126,67,Hare,ETHNICITY,ETHNICITY
SM253835,-126,67,Hare,ETHNICITY,ETHNICITY
SM253835,-126,67,Hare,ETHNICITY,ETHNICITY
SM253835,-125,67.47,Hare,ETHNICITY,ETHNICITY
SM253835,-125,67.47,Hare,ETHNICITY,ETHNICITY
SM253835,-125,67.47,Hare,ETHNICITY,ETHNICITY
SM232176,-124.4984,64.4389,Hare,DIALECT,LANGUAGE
SM286414,6.0211,62.3674,Hareid,ADM2,DISTRICT
SM296734,36.5654,36.1401,Harem,ADM2,DISTRICT
SM274358,42.1695,9.2987,Hareri,ADM2,DISTRICT
SM250284,43.03,8.09,Harerri,ETHNICITY,ETHNICITY
SM12403,-76.944,18.1728,Harewood,ADM2,DISTRICT
SM304772,43.9857,16.4139,Harf Sufyan,ADM2,DISTRICT
SM301217,-76.2989,39.5364,Harford,ADM2,DISTRICT
SM295804,44.2807,9.5216,Hargeysa,ADM2,DISTRICT
SM1466,25.4167,46.5833,Harghita,ADM1,DISTRICT
SM304907,45.3682,14.9305,Harib,ADM2,DISTRICT
SM304908,44.6153,15.4735,Harib Al Qaramish,ADM2,DISTRICT
SM375419,72.9115,33.9459,Haripur,ADM2:ADM3,DISTRICT
SM1467,24.8333,59.3333,Harju,ADM1,DISTRICT
SM12404,-76.9135,18.1315,Harkers Hall,ADM2,DISTRICT
SM274099,24.4621,59.3916,Harku,ADM2,DISTRICT
SM301714,-99.4046,40.1765,Harlan,ADM2,DISTRICT
SM301051,-83.218,36.857,Harlan,ADM2,DISTRICT
SM290831,26.8777,47.4315,Harlau,ADM2,DISTRICT
SM285728,5.4181,53.1753,Harlingen,ADM2,DISTRICT
SM292629,25.7045,45.7373,Harman,ADM2,DISTRICT
SM298256,29.1552,39.6774,Harmancık,ADM2,DISTRICT
SM262518,25.9833,41.9333,Harmanli,ADM2,DISTRICT
SM302193,-99.8463,34.7441,Harmon,ADM2,DISTRICT
SM265694,-51.4236,-29.549,Harmonia,ADM2,DISTRICT
SM12405,-77.4121,17.9817,Harmons,ADM2,DISTRICT
SM12406,-78.1369,18.2199,Harmony Town,ADM2,DISTRICT
SM5117,67.6811,30.1662,Harnai,ADM2:ADM3,DISTRICT
SM301968,-78.8693,35.3686,Harnett,ADM2,DISTRICT
SM302253,-118.9679,43.064,Harney,ADM2,DISTRICT
SM232182,108.995,13.2201,Haroi,LANGUAGE,LANGUAGE
SM253842,108.85,13.67,Haroi,ETHNICITY,ETHNICITY
SM302195,-99.6674,36.7887,Harper,ADM2,DISTRICT
SM300921,-98.0755,37.1916,Harper,ADM2,DISTRICT
SM298871,39.2183,36.8431,Harran,ADM2,DISTRICT
SM302642,-95.3923,29.8573,Harris,ADM2,DISTRICT
SM300509,-84.909,32.736,Harris,ADM2,DISTRICT
SM303116,-80.3799,39.2835,Harrison,ADM2,DISTRICT
SM302643,-94.3715,32.5481,Harrison,ADM2,DISTRICT
SM302110,-81.0911,40.2938,Harrison,ADM2,DISTRICT
SM301558,-93.9921,40.3547,Harrison,ADM2,DISTRICT
SM301442,-89.0816,30.4161,Harrison,ADM2,DISTRICT
SM300821,-95.8169,41.6829,Harrison,ADM2,DISTRICT
SM301052,-84.3314,38.4418,Harrison,ADM2,DISTRICT
SM300715,-86.1113,38.1951,Harrison,ADM2,DISTRICT
SM303024,-78.8689,38.4496,Harrisonburg,ADM2,DISTRICT
SM12407,-77.5761,18.1731,Harry Watch,ADM2,DISTRICT
SM292631,25.024,45.7342,Harseni,ADM2,DISTRICT
SM291250,24.7829,44.5109,Harsesti,ADM2,DISTRICT
SM277522,47.4833,34.35,Harsin,ADM2,DISTRICT
SM290166,27.9479,44.6872,Harsova,ADM2,DISTRICT
SM461874,16.5293,68.799,Harstad (2013-),ADM2,DISTRICT
SM232184,56.1562,19.6511,Harsusi,LANGUAGE,LANGUAGE
SM301054,-85.8847,37.2999,Hart,ADM2,DISTRICT
SM300510,-82.9643,34.3509,Hart,ADM2,DISTRICT
SM261860,15.9837,47.2792,Hartberg-Fürstenfeld,ADM2,DISTRICT
SM303383,-72.7328,41.8064,Hartford,ADM2,DISTRICT
SM274635,-1.25,54.6667,Hartlepool,ADM1:ADM2,DISTRICT
SM302644,-102.6029,35.84,Hartley,ADM2,DISTRICT
SM232187,144.3375,-5.0952,Haruai,LANGUAGE,LANGUAGE
SM232186,128.476,-3.5703,Haruku,LANGUAGE,LANGUAGE
SM253846,-96,62.97,Harvaqtormiut,ETHNICITY,ETHNICITY
SM300923,-97.4273,38.0432,Harvey,ADM2,DISTRICT
SM261388,115.9338,-33.1498,Harvey,ADM2,DISTRICT
SM1468,76.3333,29.25,Haryana,ADM1,DISTRICT
SM232188,76.0432,29.0166,Haryanvi,LANGUAGE,LANGUAGE
SM232189,48.2848,38.4341,Harzani-Kilit,LANGUAGE,LANGUAGE
SM260497,20.3723,42.199,Has,ADM2,DISTRICT
SM260497,20.3723,42.199,Has,ADM2,DISTRICT
SM260497,20.3333,42.1667,Has,ADM2,DISTRICT
SM260497,20.3333,42.1667,Has,ADM2,DISTRICT
SM253848,49.43,25.68,Hasa,ETHNICITY,ETHNICITY
SM298819,36.5561,37.1318,Hasanbeyli,ADM2,DISTRICT
SM250363,32,14,Hasania,ETHNICITY,ETHNICITY
SM298186,41.4997,37.6969,Hasankeyf,ADM2,DISTRICT
SM280915,35.7228,33.3861,Hasbaya,ADM2,DISTRICT
SM232195,8.6614,9.1406,Hasha,LANGUAGE,LANGUAGE
SM253850,44.29,17.93,Hashid,ETHNICITY,ETHNICITY
SM279828,136.7016,35.3074,Hashima Town,ADM2,DISTRICT
SM12899,136.7779,35.3744,Hashima-gun,ADM2,DISTRICT
SM279535,135.6104,34.3242,Hashimoto,ADM2,DISTRICT
SM253851,-95,31,Hasinai,ETHNICITY,ETHNICITY
SM302645,-99.7303,33.1782,Haskell,ADM2,DISTRICT
SM302196,-95.1166,35.2248,Haskell,ADM2,DISTRICT
SM300924,-100.8712,37.5623,Haskell,ADM2,DISTRICT
SM262519,25.6167,41.8833,Haskovo,ADM2,DISTRICT
SM1469,25.9167,41.8333,Haskovo,ADM1,DISTRICT
SM298780,41.6883,38.6846,Hasköy,ADM2,DISTRICT
SM290508,22.07,46.5222,Hasmas,ADM2,DISTRICT
SM298462,36.5214,36.7981,Hassa,ADM2,DISTRICT
SM276931,76.0833,12.95,Hassan,ADM2,DISTRICT
SM253854,45.18,39.11,Hassananli,ETHNICITY,ETHNICITY
SM232201,-14.11,18.29,Hassaniyya,LANGUAGE,LANGUAGE
SM298118,8.919,34.9613,Hassi El Ferid,ADM2,DISTRICT
SM286549,176.85,-39.6333,Hastings,ADM2,DISTRICT
SM268851,-77.6662,44.7501,Hastings,ADM2,DISTRICT
SM279154,139.6498,36.0015,Hasuda,ADM2,DISTRICT
SM286351,22.3027,70.5916,Hasvik,ADM2,DISTRICT
SM305070,51.675,17.8031,Hat,ADM2,DISTRICT
SM297592,100.4654,6.9809,Hat Yai,ADM2,DISTRICT
SM12900,133.0426,33.0814,Hata-gun,ADM2,DISTRICT
SM232203,134.037,-1.1353,Hatam,LANGUAGE,LANGUAGE
SM243172,121.453,14.6254,Hatang Kayi,LANGUAGE,LANGUAGE
SM1470,36.25,36.5,Hatay,ADM1,DISTRICT
SM290762,22.9228,45.6251,Hateg,ADM2,DISTRICT
SM12408,-77.5401,18.0156,Hatfield,ADM2,DISTRICT
SM277298,78.1616,27.5776,Hathras,ADM2,DISTRICT
SM304962,46.4439,14.1802,Hatib,ADM2,DISTRICT
SM1471,-66.7932,18.4077,Hatillo,ADM1,DISTRICT
SM15761,-67.1883,18.2993,Hatillo Barrio (Añasco),ADM2,DISTRICT
SM15760,-66.8208,18.4807,Hatillo Barrio (Hatillo),ADM2,DISTRICT
SM15762,-66.8254,18.4853,Hatillo Barrio-Pueblo,ADM2,DISTRICT
SM270684,-74.1245,8.9661,Hatillo de Loba,ADM2,DISTRICT
SM270477,-73.3589,6.5611,Hato,ADM2,DISTRICT
SM15763,-66.7518,18.4644,Hato Abajo Barrio,ADM2,DISTRICT
SM15764,-66.7452,18.4369,Hato Arriba Barrio (Arecibo),ADM2,DISTRICT
SM15765,-67.031,18.3581,Hato Arriba Barrio (San Sebastián),ADM2,DISTRICT
SM15766,-65.9848,18.194,Hato Barrio,ADM2,DISTRICT
SM297816,125.5541,-8.9385,Hato Builico,ADM2,DISTRICT
SM270891,-70.9969,6.1628,Hato Corozal,ADM2,DISTRICT
SM297817,125.5894,-9.1204,Hato Hudo,ADM2,DISTRICT
SM1472,-69.3333,18.8333,Hato Mayor,ADM1,DISTRICT
SM271911,-69.3333,18.7,Hato Mayor del Rey,ADM2,DISTRICT
SM15767,-65.9494,18.2686,Hato Nuevo Barrio (Gurabo),ADM2,DISTRICT
SM15768,-66.1003,18.2974,Hato Nuevo Barrio (Gyanabo),ADM2,DISTRICT
SM15769,-66.4832,18.1025,Hato Puerco Abajo Barrio,ADM2,DISTRICT
SM15770,-66.4905,18.1248,Hato Puerco Arriba Barrio,ADM2,DISTRICT
SM15771,-65.8843,18.3227,Hato Puerco Barrio,ADM2,DISTRICT
SM15772,-66.051,18.4217,Hato Rey Central Barrio,ADM2,DISTRICT
SM15773,-66.0779,18.4276,Hato Rey Norte Barrio,ADM2,DISTRICT
SM15774,-66.0586,18.4048,Hato Rey Sur Barrio,ADM2,DISTRICT
SM15775,-66.1774,18.4072,Hato Tejas Barrio,ADM2,DISTRICT
SM15776,-66.7033,18.3756,Hato Viejo Barrio (Arecibo),ADM2,DISTRICT
SM15777,-66.5143,18.3416,Hato Viejo Barrio (Ciales),ADM2,DISTRICT
SM1473,131.1236,3.009,Hatohobei,ADM1,DISTRICT
SM10414,-72.7669,11.0694,Hatonuevo,ADM2,DISTRICT
SM305798,42,35.5,Hatra,ADM2,DISTRICT
SM278143,132.3,34.35,Hatsukaichi,ADM2,DISTRICT
SM285779,6.052,52.4757,Hattem,ADM2,DISTRICT
SM286064,13.9895,65.5977,Hattfjelldal,ADM2,DISTRICT
SM5111,73.847,34.1728,Hattian Bala,ADM2:ADM3,DISTRICT
SM232211,40.3333,33.9833,Hattic,LANGUAGE,LANGUAGE
SM276126,19.7285,47.6947,Hatvani,ADM2,DISTRICT
SM297847,125.3167,-8.8167,Hatólia,ADM2,DISTRICT
SM253859,-65.03,3.16,Haue,ETHNICITY,ETHNICITY
SM286152,5.2755,59.4102,Haugesund,ADM2,DISTRICT
SM232220,141.6257,-4.2923,Hauna Trade Language,LANGUAGE,LANGUAGE
SM295422,161.5946,-10.5031,Haununu,ADM2,DISTRICT
SM286510,175.5802,-37.2942,Hauraki,ADM2,DISTRICT
SM253860,-108.51,27.36,Haurijio,ETHNICITY,ETHNICITY
SM232225,8.7804,11.1513,Hausa,LANGUAGE,LANGUAGE
SM253861,8,12,Hausa Kanawa,ETHNICITY,ETHNICITY
SM253861,8,12,Hausa Kanawa,ETHNICITY,ETHNICITY
SM253861,9,12,Hausa Kanawa,ETHNICITY,ETHNICITY
SM253861,9,12,Hausa Kanawa,ETHNICITY,ETHNICITY
SM253862,4.38,14.41,Hausa Niger & Nigeria,ETHNICITY,ETHNICITY
SM253862,4.38,14.41,Hausa Niger & Nigeria,ETHNICITY,ETHNICITY
SM253862,4.38,14.41,Hausa Niger & Nigeria,ETHNICITY,ETHNICITY
SM253862,5.867,14,Hausa Niger & Nigeria,ETHNICITY,ETHNICITY
SM253862,5.867,14,Hausa Niger & Nigeria,ETHNICITY,ETHNICITY
SM253862,5.867,14,Hausa Niger & Nigeria,ETHNICITY,ETHNICITY
SM253862,8,12,Hausa Niger & Nigeria,ETHNICITY,ETHNICITY
SM253862,8,12,Hausa Niger & Nigeria,ETHNICITY,ETHNICITY
SM253862,8,12,Hausa Niger & Nigeria,ETHNICITY,ETHNICITY
SM232224,7.168,8.486,Hausa Sign Language,LANGUAGE,LANGUAGE
SM239813,11.1188,10.9972,Hausa States Fulfulde,LANGUAGE,LANGUAGE
SM253863,14.5,10.5,Hausa-Cameroon/CAR,ETHNICITY,ETHNICITY
SM232223,-65.5,-54.7,Haush,LANGUAGE,LANGUAGE
SM253864,-66.6,-54.63,Haush,ETHNICITY,ETHNICITY
SM269541,10.1833,5.15,Haut Nkam,ADM2,DISTRICT
SM269516,13.75,3.5,Haut Nyong,ADM2,DISTRICT
SM1474,-4.32,11.28,Haut-Bassins,ADM1,DISTRICT
SM274579,10.6356,0.8279,Haut-Como,ADM2,DISTRICT
SM1475,27.2333,-11.7667,Haut-Katanga,ADM1:ADM2,DISTRICT
SM1475,27.2333,-11.7667,Haut-Katanga,ADM1:ADM2,DISTRICT
SM1475,27.2333,-11.7667,Haut-Katanga,ADM1:ADM2,DISTRICT
SM1475,27.9432,-10.4604,Haut-Katanga,ADM1:ADM2,DISTRICT
SM1475,27.9432,-10.4604,Haut-Katanga,ADM1:ADM2,DISTRICT
SM1475,27.9432,-10.4604,Haut-Katanga,ADM1:ADM2,DISTRICT
SM1475,27.8167,-10.4667,Haut-Katanga,ADM1:ADM2,DISTRICT
SM1475,27.8167,-10.4667,Haut-Katanga,ADM1:ADM2,DISTRICT
SM1475,27.8167,-10.4667,Haut-Katanga,ADM1:ADM2,DISTRICT
SM1476,25.5951,-8.1217,Haut-Lomami,ADM1:ADM2,DISTRICT
SM1476,25.5951,-8.1217,Haut-Lomami,ADM1:ADM2,DISTRICT
SM1476,25,-8.7333,Haut-Lomami,ADM1:ADM2,DISTRICT
SM1476,25,-8.7333,Haut-Lomami,ADM1:ADM2,DISTRICT
SM1477,25.6667,6.3333,Haut-Mbomou,ADM1,DISTRICT
SM274580,12.5912,1.8157,Haut-Ntem,ADM2,DISTRICT
SM1478,13.9,-1.3,Haut-Ogooué,ADM1,DISTRICT
SM274493,7.2719,47.876,Haut-Rhin,ADM2,DISTRICT
SM269494,-6.5012,6.6696,Haut-Sassandra,ADM2,DISTRICT
SM1479,27.6167,2.7667,Haut-Uélé,ADM1:ADM2,DISTRICT
SM269505,12.4,4.6,Haute Sanaga,ADM2,DISTRICT
SM281473,46.5,-21.37,Haute matsiatra,ADM1:ADM2,DISTRICT
SM274567,11.104,-3.805,Haute-Banio,ADM2,DISTRICT
SM274488,9.1667,42.4167,Haute-Corse,ADM2,DISTRICT
SM274454,1.2219,43.3872,Haute-Garonne,ADM2,DISTRICT
SM1480,23,7.4167,Haute-Kotto,ADM1,DISTRICT
SM274429,3.8394,45.11,Haute-Loire,ADM2,DISTRICT
SM274494,5.2404,48.1202,Haute-Marne,ADM2,DISTRICT
SM274430,6.4537,46.0614,Haute-Savoie,ADM2,DISTRICT
SM274471,6.1007,47.6763,Haute-Saône,ADM2,DISTRICT
SM274443,1.2178,45.8834,Haute-Vienne,ADM2,DISTRICT
SM274466,6.2897,44.6805,Hautes-Alpes,ADM2,DISTRICT
SM274455,0.1863,43.0369,Hautes-Pyrénées,ADM2,DISTRICT
SM269542,10.3431,5.2963,Hauts Plateaux,ADM2,DISTRICT
SM1481,2.6559,49.6123,Hauts-de-France,ADM1,DISTRICT
SM274505,2.1929,48.85,Hauts-de-Seine,ADM2,DISTRICT
SM253865,-81.55,22.82,Havana,ETHNICITY,ETHNICITY
SM292570,26.6831,48.0568,Havarna,ADM2,DISTRICT
SM253866,-112.76,36.16,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.76,36.16,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.76,36.16,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.76,36.16,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.76,36.16,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.1665,35.833,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.1665,35.833,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.1665,35.833,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.1665,35.833,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.1665,35.833,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112,36,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112,36,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112,36,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112,36,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112,36,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.8,36.2,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.8,36.2,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.8,36.2,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.8,36.2,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.8,36.2,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.17,35.83,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.17,35.83,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.17,35.83,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.17,35.83,Havasupai,ETHNICITY,ETHNICITY
SM253866,-112.17,35.83,Havasupai,ETHNICITY,ETHNICITY
SM232230,-113.385,35.6302,Havasupai-Walapai-Yavapai,LANGUAGE,LANGUAGE
SM232233,164.753,-21.0249,Haveke,LANGUAGE,LANGUAGE
SM5113,74.113,33.9259,Haveli,ADM2:ADM3,DISTRICT
SM12409,-76.8087,18.0543,Havendale,ADM2,DISTRICT
SM276932,75.4106,14.7373,Haveri,ADM2,DISTRICT
SM271216,15.5833,49.6667,Havlíčkův Brod,ADM2,DISTRICT
SM298163,27.1074,39.5677,Havran,ADM2,DISTRICT
SM298342,26.8339,41.5598,Havsa,ADM2,DISTRICT
SM232234,28.8624,-1.9998,Havu,LANGUAGE,LANGUAGE
SM295396,158.8095,-7.737,Havulei,ADM2,DISTRICT
SM298860,35.6711,40.9797,Havza,ADM2,DISTRICT
SM232237,-155.437,19.6287,Hawai'i Creole English,LANGUAGE,LANGUAGE
SM232235,-158.002,21.6311,Hawai'i Pidgin Sign Language,LANGUAGE,LANGUAGE
SM300539,-155.5229,19.6024,Hawaii,ADM2,DISTRICT
SM1482,-156.3861,20.7879,Hawaii,ADM1,DISTRICT
SM232236,-155.43,19.6297,Hawaiian,LANGUAGE,LANGUAGE
SM253868,-155.65,19.27,Hawaiians,ETHNICITY,ETHNICITY
SM253868,-155.65,19.27,Hawaiians,ETHNICITY,ETHNICITY
SM253868,-155.83,19.89,Hawaiians,ETHNICITY,ETHNICITY
SM253868,-155.83,19.89,Hawaiians,ETHNICITY,ETHNICITY
SM1483,48.05,29.3,Hawalli,ADM1,DISTRICT
SM253869,32.43,16.25,Hawawir,ETHNICITY,ETHNICITY
SM305853,43.7542,35.327,Haweeja,ADM2,DISTRICT
SM253870,31.73,29.43,Haweitat,ETHNICITY,ETHNICITY
SM305071,52.8182,16.6953,Hawf,ADM2,DISTRICT
SM250688,43.13,4.19,Hawiyah,ETHNICITY,ETHNICITY
SM1484,176.5,-39.6667,Hawke's Bay,ADM1,DISTRICT
SM232240,150.4952,-33.1147,Hawkesbury,LANGUAGE,LANGUAGE
SM261552,150.783,-33.3408,Hawkesbury,ADM2,DISTRICT
SM459112,151.219,-33.5057,Hawkesbury River - Broken Bay language,ETHNICITY,ETHNICITY
SM302574,-82.9447,36.4412,Hawkins,ADM2,DISTRICT
SM273904,30.3242,30.873,Hawsh 'Isa,ADM2,DISTRICT
SM17787,46.7,23.2,Hawtat Bani Tamim,ADM2,DISTRICT
SM243472,121.847,-10.5522,Hawu,LANGUAGE,LANGUAGE
SM285364,12.2468,10.4331,Hawul,ADM2,DISTRICT
SM261553,144.8383,-34.5428,Hay,ADM2,DISTRICT
SM323759,-113.0552,53.1966,Hay Lakes,ADM2:ADM3,DISTRICT
SM232244,31.4846,-1.7088,Haya,LANGUAGE,LANGUAGE
SM250296,31.42,-1.38,Haya,ETHNICITY,ETHNICITY
SM250296,31.42,-1.38,Haya,ETHNICITY,ETHNICITY
SM250296,32,-1,Haya,ETHNICITY,ETHNICITY
SM250296,32,-1,Haya,ETHNICITY,ETHNICITY
SM15778,-66.3713,18.1621,Hayales Barrio,ADM2,DISTRICT
SM278558,139.6006,35.2713,Hayama,ADM2,DISTRICT
SM279044,133.8224,34.6086,Hayashima,ADM2,DISTRICT
SM304930,43.4849,16.7287,Haydan,ADM2,DISTRICT
SM301715,-101.0618,40.5248,Hayes,ADM2,DISTRICT
SM12410,-77.2275,17.8792,Hayes,ADM2,DISTRICT
SM304982,44.2607,13.2906,Hayfan,ADM2,DISTRICT
SM12411,-76.3684,17.982,Hayfield,ADM2,DISTRICT
SM274007,37.0786,15.187,Haykota,ADM2,DISTRICT
SM298887,32.4885,39.4402,Haymana,ADM2,DISTRICT
SM298941,27.1195,41.2245,Hayrabolu,ADM2,DISTRICT
SM304841,43.0599,16.2508,Hayran,ADM2,DISTRICT
SM298977,40.3676,40.8914,Hayrat,ADM2,DISTRICT
SM305045,43.4984,13.9332,Hays,ADM2,DISTRICT
SM302646,-98.0311,30.0582,Hays,ADM2,DISTRICT
SM302575,-89.2838,35.5832,Haywood,ADM2,DISTRICT
SM301969,-82.9822,35.5561,Haywood,ADM2,DISTRICT
SM9532,69.5697,36.9263,Hazar Sumuch,ADM2,DISTRICT
SM253871,66.59,32.69,Hazara,ETHNICITY,ETHNICITY
SM253871,66.59,32.69,Hazara,ETHNICITY,ETHNICITY
SM253871,66.59,32.69,Hazara,ETHNICITY,ETHNICITY
SM253871,66.59,32.69,Hazara,ETHNICITY,ETHNICITY
SM253871,66,35,Hazara,ETHNICITY,ETHNICITY
SM253871,66,35,Hazara,ETHNICITY,ETHNICITY
SM253871,66,35,Hazara,ETHNICITY,ETHNICITY
SM253871,66,35,Hazara,ETHNICITY,ETHNICITY
SM253871,70,33.4986,Hazara,ETHNICITY,ETHNICITY
SM253871,70,33.4986,Hazara,ETHNICITY,ETHNICITY
SM253871,70,33.4986,Hazara,ETHNICITY,ETHNICITY
SM253871,70,33.4986,Hazara,ETHNICITY,ETHNICITY
SM253871,70,33.5,Hazara,ETHNICITY,ETHNICITY
SM253871,70,33.5,Hazara,ETHNICITY,ETHNICITY
SM253871,70,33.5,Hazara,ETHNICITY,ETHNICITY
SM253871,70,33.5,Hazara,ETHNICITY,ETHNICITY
SM253872,68.31,32.67,Hazara Berberi,ETHNICITY,ETHNICITY
SM232246,64.5105,33.3708,Hazaragi,LANGUAGE,LANGUAGE
SM12412,-77.2315,17.9538,Hazard,ADM2,DISTRICT
SM276900,85.25,24,Hazaribagh (2007-),ADM2,DISTRICT
SM304870,43.9381,14.1698,Hazm Al Udayn,ADM2,DISTRICT
SM18400,61.1667,41.25,Hazorasp Tumani,ADM2,DISTRICT
SM298010,7.6557,33.7661,Hazoua,ADM2,DISTRICT
SM260094,68.8537,37.1084,Hazrati Imam,ADM2,DISTRICT
SM260187,67.8651,36.383,Hazrati Sultan,ADM2,DISTRICT
SM298332,40.7787,38.2541,Hazro,ADM2,DISTRICT
SM297885,10.2347,35.2669,Hbira,ADM2,DISTRICT
SM232250,13.6881,10.942,Hdi,LANGUAGE,LANGUAGE
SM300511,-85.1283,33.297,Heard,ADM2,DISTRICT
SM12413,-77.4824,18.0488,Heartease,ADM2,DISTRICT
SM1486,115.6667,39,Hebei,ADM1,DISTRICT
SM269170,114.3517,35.6947,Hebi,ADM2,DISTRICT
SM289743,35.1,31.5,Hebron,ADM1:ADM2,DISTRICT
SM296561,17,60.1,Heby,ADM2,DISTRICT
SM283098,-90.1333,20.1667,Hecelchakán,ADM2,DISTRICT
SM269448,108.03,24.7,Hechi,ADM2,DISTRICT
SM12414,-76.2706,18.0009,Hectors River,ADM2,DISTRICT
SM296552,16.0735,60.3466,Hedemora,ADM2,DISTRICT
SM271701,9.744,55.7968,Hedensted (2007-),ADM2,DISTRICT
SM285987,4.6539,52.5095,Heemskerk,ADM2,DISTRICT
SM285988,4.6184,52.3472,Heemstede,ADM2,DISTRICT
SM285781,6.0389,52.3883,Heerde,ADM2,DISTRICT
SM285989,4.8436,52.6821,Heerhugowaard,ADM2,DISTRICT
SM285858,5.9741,50.8878,Heerlen,ADM2,DISTRICT
SM285925,5.5587,51.364,Heeze-Leende,ADM2,DISTRICT
SM12065,35.0667,32.7667,Hefa,ADM2,DISTRICT
SM269134,117.2781,31.9467,Hefei,ADM2,DISTRICT
SM232259,117.2237,31.8736,Hefei Mandarin,DIALECT,LANGUAGE
SM370535,6.2414,49.7625,Heffingen,ADM1:ADM2:ADM3,DISTRICT
SM269154,130.8059,47.4701,Hegang,ADM2,DISTRICT
SM232260,35.7255,-7.7157,Hehe,LANGUAGE,LANGUAGE
SM250297,36,-6.92,Hehe,ETHNICITY,ETHNICITY
SM250297,36,-6.92,Hehe,ETHNICITY,ETHNICITY
SM250297,35,-8,Hehe,ETHNICITY,ETHNICITY
SM250297,35,-8,Hehe,ETHNICITY,ETHNICITY
SM269155,127.1087,49.1188,Heihe,ADM2,DISTRICT
SM1488,128,47.75,Heilongjiang,ADM1,DISTRICT
SM285990,4.7095,52.6012,Heiloo,ADM2,DISTRICT
SM252192,-127.62,52.57,Heiltsuk,ETHNICITY,ETHNICITY
SM252192,-127.62,52.57,Heiltsuk,ETHNICITY,ETHNICITY
SM252192,-127.62,52.57,Heiltsuk,ETHNICITY,ETHNICITY
SM252192,-128,52,Heiltsuk,ETHNICITY,ETHNICITY
SM252192,-128,52,Heiltsuk,ETHNICITY,ETHNICITY
SM252192,-128,52,Heiltsuk,ETHNICITY,ETHNICITY
SM252192,-127.87,52.87,Heiltsuk,ETHNICITY,ETHNICITY
SM252192,-127.87,52.87,Heiltsuk,ETHNICITY,ETHNICITY
SM252192,-127.87,52.87,Heiltsuk,ETHNICITY,ETHNICITY
SM232266,-127.221,51.7045,Heiltsuk-Oowekyala,LANGUAGE,LANGUAGE
SM4683,8.871,63.2212,Heim (2020-),ADM2,DISTRICT
SM323932,-112.2241,52.6709,Heisler,ADM2:ADM3,DISTRICT
SM268047,-49.8311,-15.7342,Heitoraí,ADM2,DISTRICT
SM298722,37.9313,38.8163,Hekimhan,ADM2,DISTRICT
SM278023,136.9916,34.8796,Hekinan,ADM2,DISTRICT
SM1489,142.8442,-6.2982,Hela,ADM1,DISTRICT
SM15779,-66.3209,18.172,Helechal Barrio,ADM2,DISTRICT
SM292112,26.7669,46.3497,Helegiu,ADM2,DISTRICT
SM290832,26.8806,47.193,Helesteni,ADM2,DISTRICT
SM11611,-22.7895,64.9907,Helgafellssveit,ADM2,DISTRICT
SM270223,-75.7445,6.2169,Heliconia,ADM2,DISTRICT
SM263485,-45.5443,-22.0416,Heliodora,ADM2,DISTRICT
SM267416,-38.2549,-10.7286,Heliópolis,ADM2,DISTRICT
SM285559,6.4643,52.3902,Hellendoorn,ADM2,DISTRICT
SM285653,4.1333,51.8333,Hellevoetsluis,ADM2,DISTRICT
SM12415,-76.9619,17.8967,Hellshire,ADM2,DISTRICT
SM285926,5.6556,51.4797,Helmond,ADM2,DISTRICT
SM232272,123.377,-10.2647,Helong,LANGUAGE,LANGUAGE
SM253878,124.13,-10.01,Helong,ETHNICITY,ETHNICITY
SM296460,12.7872,56.0565,Helsingborg,ADM2,DISTRICT
SM271668,12.5,56.05,Helsingør,ADM2,DISTRICT
SM11217,24.6085,60.3694,Helsinki (2009-),ADM2,DISTRICT
SM232275,30.0872,1.0495,Hema,LANGUAGE,LANGUAGE
SM250085,20,11,Hemat,ETHNICITY,ETHNICITY
SM232276,27.5913,-7.7746,Hemba-Yazi,LANGUAGE,LANGUAGE
SM292113,26.8573,46.6193,Hemeius,ADM2,DISTRICT
SM286065,13.8135,66.0771,Hemnes,ADM2,DISTRICT
SM302647,-100.2705,35.8376,Hemphill,ADM2,DISTRICT
SM302373,-93.6684,33.7353,Hempstead,ADM2,DISTRICT
SM286332,8.5654,60.8611,Hemsedal,ADM2,DISTRICT
SM461517,39.7,41,Hemsheni,ETHNICITY,ETHNICITY
SM298833,40.9014,41.0592,Hemşin,ADM2,DISTRICT
SM1490,113.6667,34,Henan,ADM1,DISTRICT
SM297948,10.7286,35.0902,Hencha,ADM2,DISTRICT
SM298843,30.7392,40.8094,Hendek,ADM2,DISTRICT
SM302576,-88.388,35.6543,Henderson,ADM2,DISTRICT
SM302648,-95.8536,32.2119,Henderson,ADM2,DISTRICT
SM301970,-82.4799,35.3363,Henderson,ADM2,DISTRICT
SM301055,-87.5732,37.7959,Henderson,ADM2,DISTRICT
SM300621,-90.9251,40.8181,Henderson,ADM2,DISTRICT
SM300716,-86.51,39.7695,Hendricks,ADM2,DISTRICT
SM285654,4.6398,51.8427,Hendrik-Ido-Ambacht,ADM2,DISTRICT
SM300330,-81.1659,26.5535,Hendry,ADM2,DISTRICT
SM253880,33.96,-10.14,Henga,ETHNICITY,ETHNICITY
SM288634,145.6667,-6.2167,Henganofi,ADM2,DISTRICT
SM285560,6.7788,52.2591,Hengelo,ADM2,DISTRICT
SM269142,115.7,37.73,Hengshui,ADM2,DISTRICT
SM269203,112.6284,26.7631,Hengyang,ADM2,DISTRICT
SM300036,34.8089,46.1658,Heniches'kyi,ADM2,DISTRICT
SM253881,-24.4,-128.31,Henkison,ETHNICITY,ETHNICITY
SM301357,-93.4769,45.0046,Hennepin,ADM2,DISTRICT
SM303026,-77.2965,37.4577,Henrico,ADM2,DISTRICT
SM303027,-79.9815,36.6847,Henry,ADM2,DISTRICT
SM302578,-88.3012,36.3318,Henry,ADM2,DISTRICT
SM302111,-84.0682,41.3339,Henry,ADM2,DISTRICT
SM301559,-93.7928,38.3852,Henry,ADM2,DISTRICT
SM300717,-85.3964,39.931,Henry,ADM2,DISTRICT
SM300822,-91.5445,40.9879,Henry,ADM2,DISTRICT
SM301056,-85.1189,38.4485,Henry,ADM2,DISTRICT
SM300512,-84.1542,33.453,Henry,ADM2,DISTRICT
SM300622,-90.1314,41.3531,Henry,ADM2,DISTRICT
SM300273,-85.2414,31.5147,Henry,ADM2,DISTRICT
SM1491,110.5,48,Hentiy,ADM1,DISTRICT
SM261237,144.0336,-37.3171,Hepburn,ADM2,DISTRICT
SM232282,100.1397,26.5536,Heqing,DIALECT,LANGUAGE
SM11454,25.1299,35.3297,Heraklion Regional Unit,ADM2,DISTRICT
SM1492,18.5375,42.5067,Herceg Novi,ADM1,DISTRICT
SM305339,17.0119,45.6566,Hercegovac,ADM2,DISTRICT
SM266563,-50.3848,-21.962,Herculândia,ADM2,DISTRICT
SM232283,14.9267,9.1434,Herde,LANGUAGE,LANGUAGE
SM291608,23.007,47.263,Hereclean,ADM2,DISTRICT
SM270936,-84.1315,9.9866,Heredia,ADM2,DISTRICT
SM1493,-84,10.3333,Heredia,ADM1,DISTRICT
SM274637,-2.75,52.0833,Herefordshire,ADM1:ADM2,DISTRICT
SM232287,20.5655,-21.0231,Herero,LANGUAGE,LANGUAGE
SM250298,17.94,-21.17,Herero,ETHNICITY,ETHNICITY
SM250298,17.94,-21.17,Herero,ETHNICITY,ETHNICITY
SM250298,16.13,-19.41,Herero,ETHNICITY,ETHNICITY
SM250298,16.13,-19.41,Herero,ETHNICITY,ETHNICITY
SM250298,13.47,-15.7,Herero,ETHNICITY,ETHNICITY
SM250298,13.47,-15.7,Herero,ETHNICITY,ETHNICITY
SM250298,16,-21,Herero,ETHNICITY,ETHNICITY
SM250298,16,-21,Herero,ETHNICITY,ETHNICITY
SM303860,-63.5,6,Heres,ADM2,DISTRICT
SM297997,10.469,36.0349,Hergla,ADM2,DISTRICT
SM277685,46.8401,38.2306,Heris,ADM2,DISTRICT
SM301881,-74.9625,43.4197,Herkimer,ADM2,DISTRICT
SM271669,12.4311,55.7332,Herlev,ADM2,DISTRICT
SM261795,13.1325,46.6314,Hermagor,ADM2,DISTRICT
SM280901,36.2898,34.3735,Hermel,ADM2,DISTRICT
SM282411,-97.7345,20.1113,Hermenegildo Galeana,ADM2,DISTRICT
SM232289,145.075,-1.5289,Hermit,LANGUAGE,LANGUAGE
SM12416,-76.741,17.9835,Hermitage,ADM2,DISTRICT
SM282570,-111.2107,28.9809,Hermosillo,ADM2,DISTRICT
SM15339,-54.7778,-25.3151,Hernandarias (2003-),ADM2,DISTRICT
SM300331,-82.4685,28.5562,Hernando,ADM2,DISTRICT
SM262688,-64.0392,-20.2237,Hernando Siles,ADM2,DISTRICT
SM271702,8.8971,56.15,Herning (2007-),ADM2,DISTRICT
SM14291,-96.7308,16.5784,Heroica Ciudad de Ejutla de Crespo,ADM2,DISTRICT
SM14292,-97.7864,17.8231,Heroica Ciudad de Huajuapan de Leon,ADM2,DISTRICT
SM14293,-94.9862,16.4487,Heroica Ciudad de Juchitan de Zaragoza,ADM2,DISTRICT
SM14294,-97.6798,17.2077,Heroica Ciudad de Tlaxiaco,ADM2,DISTRICT
SM14295,-97.8461,17.5916,"Heroica Villa Tezoatlan de Segura y Luna, Cuna de la Independencia de Oaxaca",ADM2,DISTRICT
SM1495,-80.75,7.8333,Herrera,ADM1,DISTRICT
SM15780,-65.8237,18.4048,Herreras Barrio,ADM2,DISTRICT
SM296575,13.1206,58.0116,Herrljunga,ADM2,DISTRICT
SM270369,-72.5006,7.4807,Herrán,ADM2,DISTRICT
SM230736,-152.01,70.11,Herschel Island Eskimo Trade Jargon,LANGUAGE,LANGUAGE
SM232290,42.5192,37.373,Hertevin,LANGUAGE,LANGUAGE
SM301971,-76.9807,36.3586,Hertford,ADM2,DISTRICT
SM12417,-78.0908,18.2718,Hertford,ADM2,DISTRICT
SM274638,-0.25,51.8333,Hertfordshire,ADM1:ADM2,DISTRICT
SM18214,26.2557,48.1505,Hertsa Raion,ADM2,DISTRICT
SM9930,-53.4504,-31.9933,Herval (1999-),ADM2,DISTRICT
SM266147,-51.4203,-27.2041,Herval d'Oeste,ADM2,DISTRICT
SM265696,-52.6737,-29.4386,Herveiras,ADM2,DISTRICT
SM270564,-75.2695,5.0616,Herveo,ADM2,DISTRICT
SM262555,17.8125,43.4572,Herzegovina-Neretva,ADM1:ADM2,DISTRICT
SM262555,17.8125,43.4572,Herzegovina-Neretva,ADM1:ADM2,DISTRICT
SM262555,17.85,43.44,Herzegovina-Neretva,ADM1:ADM2,DISTRICT
SM262555,17.85,43.44,Herzegovina-Neretva,ADM1:ADM2,DISTRICT
SM286066,12.2892,65.9843,Herøy,ADM2,DISTRICT
SM286415,5.636,62.3333,Herøy,ADM2,DISTRICT
SM277977,35.8761,30.7706,Hesa,ADM2,DISTRICT
SM370522,6.1514,49.5681,Hesperange,ADM1:ADM2:ADM3,DISTRICT
SM1496,9,50.55,Hessen,ADM1,DISTRICT
SM253883,20.49,-33.59,Hessequa,ETHNICITY,ETHNICITY
SM253884,-65.37,-40.76,Het,ETHNICITY,ETHNICITY
SM15164,6.5288,53.3759,Het Hogeland,ADM2,DISTRICT
SM302043,-102.4604,46.4325,Hettinger,ADM2,DISTRICT
SM285783,5.8131,51.7744,Heumen,ADM2,DISTRICT
SM285927,5.166,51.6998,Heusden,ADM2,DISTRICT
SM276127,20.3174,47.6154,Heves,ADM2,DISTRICT
SM1497,20.25,47.8333,Heves,ADM1,DISTRICT
SM232294,142.551,-5.0364,Hewa,LANGUAGE,LANGUAGE
SM253885,142.54,-5.1,Hewa,ETHNICITY,ETHNICITY
SM17426,-60.9728,13.7449,Hewanorra Orchard,ADM2,DISTRICT
SM232298,142.471,-3.83,Heyo,LANGUAGE,LANGUAGE
SM269432,114.9859,23.9736,Heyuan,ADM2,DISTRICT
SM269302,115.6328,35.2028,Heze,ADM2,DISTRICT
SM232302,103.197,35.59,Hezhou,LANGUAGE,LANGUAGE
SM269449,111.6898,24.3947,Hezhou,ADM2,DISTRICT
SM1498,31.3333,-26.0833,Hhohho,ADM1,DISTRICT
SM296674,31.2222,-26.3197,Hhukwini,ADM2,DISTRICT
SM12418,-77.5483,18.1758,Hibernia,ADM2,DISTRICT
SM232304,-5.222,55.932,Hiberno-Scottish Gaelic,DIALECT,LANGUAGE
SM232306,-77.7,-6.5833,Hibito,LANGUAGE,LANGUAGE
SM253886,-76.91,-6.07,Hibito,ETHNICITY,ETHNICITY
SM302579,-87.4733,35.8033,Hickman,ADM2,DISTRICT
SM301057,-88.9762,36.6782,Hickman,ADM2,DISTRICT
SM301560,-93.3207,37.9408,Hickory,ADM2,DISTRICT
SM291609,23.3384,47.0783,Hida,ADM2,DISTRICT
SM279788,137.2057,36.3327,Hida,ADM2,DISTRICT
SM279157,139.3435,35.9086,Hidaka,ADM2,DISTRICT
SM12901,142.6383,42.4214,Hidaka-gun (Hokkaido),ADM2,DISTRICT
SM18529,135.3111,33.9173,Hidaka-gun (Wakayama),ADM2,DISTRICT
SM302649,-98.1811,26.3967,Hidalgo,ADM2,DISTRICT
SM301838,-108.7148,31.9142,Hidalgo,ADM2,DISTRICT
SM282645,-99.3561,24.2153,Hidalgo,ADM2,DISTRICT
SM282168,-100.4522,25.9858,Hidalgo,ADM2,DISTRICT
SM281498,-104.8282,26.0241,Hidalgo,ADM2,DISTRICT
SM283281,-99.9941,27.8452,Hidalgo,ADM2,DISTRICT
SM1499,-99,20.5,Hidalgo,ADM1,DISTRICT
SM283235,-105.7072,27.0796,Hidalgo del Parral,ADM2,DISTRICT
SM14296,-94.6114,17.5998,Hidalgotitlan (1997-),ADM2,DISTRICT
SM232309,-102.528,47.7534,Hidatsa,LANGUAGE,LANGUAGE
SM253887,-100.75,47.34,Hidatsa,ETHNICITY,ETHNICITY
SM253887,-100.75,47.34,Hidatsa,ETHNICITY,ETHNICITY
SM253887,-101,47,Hidatsa,ETHNICITY,ETHNICITY
SM253887,-101,47,Hidatsa,ETHNICITY,ETHNICITY
SM304812,54.0082,12.5025,Hidaybu,ADM1:ADM2,DISTRICT
SM292432,22.0247,46.9289,Hidiselu De Sus,ADM2,DISTRICT
SM298119,8.4952,35.5525,Hidra,ADM2,DISTRICT
SM268049,-49.3597,-14.7574,Hidrolina,ADM2,DISTRICT
SM268048,-49.2547,-17.0162,Hidrolândia,ADM2,DISTRICT
SM267932,-40.3808,-4.4739,Hidrolândia,ADM2,DISTRICT
SM284575,164.9377,-20.6874,Hienghène,ADM2,DISTRICT
SM232311,34.6153,40.0197,Hieroglyphic Luwian,LANGUAGE,LANGUAGE
SM232312,125.071,8.612,Higaonon,LANGUAGE,LANGUAGE
SM12902,137.8468,36.1401,Higashichikuma Gun,ADM2,DISTRICT
SM278144,132.7612,34.4476,Higashihiroshima,ADM2,DISTRICT
SM12903,140.4424,36.2952,Higashiibaraki-gun,ADM2,DISTRICT
SM278472,134.3335,34.2116,Higashikagawa,ADM2,DISTRICT
SM279431,139.5295,35.7582,Higashikurume,ADM2,DISTRICT
SM278721,141.1772,38.4109,Higashimatsushima,ADM2,DISTRICT
SM279159,139.3914,36.0318,Higashimatsuyama,ADM2,DISTRICT
SM279432,139.4697,35.7553,Higashimurayama,ADM2,DISTRICT
SM12904,140.2308,38.2947,Higashimurayama-gun,ADM2,DISTRICT
SM12905,135.7969,33.6247,Higashimuro-gun,ADM2,DISTRICT
SM279563,140.4667,38.4256,Higashine,ADM2,DISTRICT
SM12906,140.0853,38.0043,Higashiokitama-gun,ADM2,DISTRICT
SM12907,140.4341,36.9537,Higashishirakawa-gun,ADM2,DISTRICT
SM12908,129.9189,33.0794,Higashisonigi-gun,ADM2,DISTRICT
SM12909,139.948,38.809,Higashitagawa-gun,ADM2,DISTRICT
SM12910,140.9641,40.9087,Higashitsugaru-gun,ADM2,DISTRICT
SM12911,131.2894,32.4685,Higashiusuki-gun,ADM2,DISTRICT
SM279433,139.4266,35.7503,Higashiyamato,ADM2,DISTRICT
SM279243,136.1792,35.1089,Higashiōmi,ADM2,DISTRICT
SM279129,135.6176,34.6711,Higashiōsaka,ADM2,DISTRICT
SM12419,-77.2707,18.3412,Higgin Land,ADM2,DISTRICT
SM324156,-117.1414,58.5124,High Level,ADM2:ADM3,DISTRICT
SM324157,-116.4904,55.4359,High Prairie,ADM2:ADM3,DISTRICT
SM323905,-113.8704,50.5831,High River,ADM2:ADM3,DISTRICT
SM12420,-76.8955,18.2774,Highgate,ADM2,DISTRICT
SM303028,-79.5685,38.3623,Highland,ADM2,DISTRICT
SM302112,-83.601,39.1847,Highland,ADM2,DISTRICT
SM274721,-5,57.5,Highland,ADM1:ADM2,DISTRICT
SM232320,119.928,-5.2656,Highland Konjo,LANGUAGE,LANGUAGE
SM232313,-95.7301,16.1849,Highland Oaxaca Chontal,LANGUAGE,LANGUAGE
SM232321,-94.9109,18.198,Highland Popoluca,LANGUAGE,LANGUAGE
SM232323,-97.5556,20.1554,Highland Puebla Nahuatl,LANGUAGE,LANGUAGE
SM454404,-6.75,57.875,Highland Scots,ETHNICITY,ETHNICITY
SM454404,-6.75,57.875,Highland Scots,ETHNICITY,ETHNICITY
SM454404,-4.25,57.5,Highland Scots,ETHNICITY,ETHNICITY
SM454404,-4.25,57.5,Highland Scots,ETHNICITY,ETHNICITY
SM232314,-97.6159,20.3798,Highland Totonac,LANGUAGE,LANGUAGE
SM300332,-81.341,27.3434,Highlands,ADM2,DISTRICT
SM282169,-100.003,25.9885,Higueras,ADM2,DISTRICT
SM15781,-66.2856,18.4551,Higuillar Barrio,ADM2,DISTRICT
SM304688,-176.201,-13.2452,Hihifo,ADM2,DISTRICT
SM17988,-174.0459,-18.6376,Hihifo,ADM2,DISTRICT
SM273744,31.6002,30.658,Hihya,ADM2,DISTRICT
SM1500,45.5,4,Hiiraan,ADM1,DISTRICT
SM1501,22.6361,58.888,Hiiu,ADM1:ADM2:ADMX,DISTRICT
SM1501,22.6361,58.888,Hiiu,ADM1:ADM2:ADMX,DISTRICT
SM1501,22.6333,58.9,Hiiu,ADM1:ADM2:ADMX,DISTRICT
SM1501,22.6333,58.9,Hiiu,ADM1:ADM2:ADMX,DISTRICT
SM232337,38.9237,22.8346,Hijazi Arabic,LANGUAGE,LANGUAGE
SM279014,131.5275,33.3763,Hiji,ADM2,DISTRICT
SM232338,10.9081,4.3964,Hijuk,LANGUAGE,LANGUAGE
SM279590,131.9749,33.9825,Hikari,ADM2,DISTRICT
SM278663,130.6726,32.5743,Hikawa,ADM2,DISTRICT
SM12912,139.3342,36.0276,Hiki-gun,ADM2,DISTRICT
SM279244,136.2076,35.2304,Hikone,ADM2,DISTRICT
SM11241,-142.6092,-17.5497,Hikueru,ADM2,DISTRICT
SM232340,122.974,10.4018,Hiligaynon,LANGUAGE,LANGUAGE
SM292571,26.2969,48.0134,Hiliseu-horia,ADM2,DISTRICT
SM302650,-97.1324,31.9907,Hill,ADM2,DISTRICT
SM301635,-110.1113,48.6282,Hill,ADM2,DISTRICT
SM17427,-60.9499,13.9994,Hill 20/Babonneau,ADM2,DISTRICT
SM232345,94.0539,26.9612,Hill Miri,DIALECT,LANGUAGE
SM253892,36.12,1.92,Hill Suk,ETHNICITY,ETHNICITY
SM253892,36.12,1.92,Hill Suk,ETHNICITY,ETHNICITY
SM253892,36,1,Hill Suk,ETHNICITY,ETHNICITY
SM253892,36,1,Hill Suk,ETHNICITY,ETHNICITY
SM17428,-60.9708,14.0112,Hillcrest Gardens,ADM2,DISTRICT
SM285655,4.5782,52.2962,Hillegom,ADM2,DISTRICT
SM271671,12.2379,55.9232,Hillerød (2007-),ADM2,DISTRICT
SM234089,93.0462,25.7317,Hills Karbi,LANGUAGE,LANGUAGE
SM301809,-71.716,42.9153,Hillsborough,ADM2,DISTRICT
SM300333,-82.3469,27.9062,Hillsborough,ADM2,DISTRICT
SM301274,-84.5929,41.8878,Hillsdale,ADM2,DISTRICT
SM12421,-76.4908,17.9962,Hillside,ADM2,DISTRICT
SM323849,-113.6223,49.2947,Hillspring,ADM2:ADM3,DISTRICT
SM18559,148.5634,-34.4156,Hilltops,ADM2,DISTRICT
SM1502,64,31,Hilmand,ADM1,DISTRICT
SM298872,38.96,37.5938,Hilvan,ADM2,DISTRICT
SM285928,5.1478,51.4777,Hilvarenbeek,ADM2,DISTRICT
SM285991,5.1679,52.2225,Hilversum,ADM2,DISTRICT
SM1503,77.25,31.9167,Himachal Pradesh,ADM1,DISTRICT
SM232347,-64.7005,-6.2522,Himarimã,LANGUAGE,LANGUAGE
SM253894,12.5,-16.96,Himba,ETHNICITY,ETHNICITY
SM253894,12.5,-16.96,Himba,ETHNICITY,ETHNICITY
SM253894,13.81,-16.29,Himba,ETHNICITY,ETHNICITY
SM253894,13.81,-16.29,Himba,ETHNICITY,ETHNICITY
SM253894,15.05,-17.22,Himba,ETHNICITY,ETHNICITY
SM253894,15.05,-17.22,Himba,ETHNICITY,ETHNICITY
SM253894,15.65,-15.08,Himba,ETHNICITY,ETHNICITY
SM253894,15.65,-15.08,Himba,ETHNICITY,ETHNICITY
SM253894,14.1235,-19.1007,Himba,ETHNICITY,ETHNICITY
SM253894,14.1235,-19.1007,Himba,ETHNICITY,ETHNICITY
SM278327,134.6632,34.9013,Himeji,ADM2,DISTRICT
SM279015,131.66,33.7281,Himeshima (pre-2006),ADM2,DISTRICT
SM279505,136.9407,36.8722,Himi,ADM2,DISTRICT
SM296786,36.8951,34.5294,Hims,ADM2,DISTRICT
SM1504,38.3,34.4667,Hims,ADM1,DISTRICT
SM276072,-72.0444,19.1355,Hinche,ADM2,DISTRICT
SM261633,146.123,-18.6584,Hinchinbrook,ADM2,DISTRICT
SM16309,11.9831,-4.62,Hinda,ADM2,DISTRICT
SM232357,5.4013,52.9427,Hindeloopen-Molkwerum Frisian,LANGUAGE,LANGUAGE
SM232353,77,25,Hindi,LANGUAGE,LANGUAGE
SM253895,78.4,27,Hindi,ETHNICITY,ETHNICITY
SM261239,141.7328,-36.0329,Hindmarsh,ADM2,DISTRICT
SM301443,-90.4428,32.2667,Hinds,ADM2,DISTRICT
SM12422,-77.0981,18.3543,Hinds Town,ADM2,DISTRICT
SM232352,77.144,31.016,Hinduri,LANGUAGE,LANGUAGE
SM324158,-118.612,56.2474,Hines Creek,ADM2:ADM3,DISTRICT
SM277035,77.15,19.7,Hingoli,ADM2,DISTRICT
SM280802,102.5268,18.3949,Hinhurp,ADM2,DISTRICT
SM279434,139.3987,35.6633,Hino,ADM2,DISTRICT
SM12913,133.3495,35.1992,Hino Gun,ADM2,DISTRICT
SM291077,22.7768,44.5457,Hinova,ADM2,DISTRICT
SM303338,-107.3003,37.8213,Hinsdale,ADM2,DISTRICT
SM268911,9.4004,46.5876,Hinterrhein,ADM2,DISTRICT
SM283460,95.2017,17.8532,Hinthada,ADM2,DISTRICT
SM324110,-117.5806,53.3958,Hinton,ADM2:ADM3,DISTRICT
SM232359,45.8064,42.4502,Hinuq,LANGUAGE,LANGUAGE
SM269017,8.8343,47.2965,Hinwil,ADM2,DISTRICT
SM253902,-132.73,55.64,Hinyaa Ḵwáan,ETHNICITY,ETHNICITY
SM278523,130.3821,31.5993,Hioki,ADM2,DISTRICT
SM260779,-61.6667,-36.25,Hipólito Yrigoyen,ADM2,DISTRICT
SM278887,129.4869,33.316,Hirado,ADM2,DISTRICT
SM278461,141.104,38.986,Hiraizumi,ADM2,DISTRICT
SM279130,135.6817,34.8176,Hirakata,ADM2,DISTRICT
SM278963,140.7005,40.5183,Hirakawa,ADM2,DISTRICT
SM1506,62,34.5,Hirat,ADM1,DISTRICT
SM260022,62.2031,34.3419,Hirat City,ADM2,DISTRICT
SM278527,139.3201,35.351,Hiratsuka,ADM2,DISTRICT
SM232365,147.083,-9.3333,Hiri Motu,LANGUAGE,LANGUAGE
SM279665,130.5749,33.2502,Hirokawa,ADM2,DISTRICT
SM12914,143.1805,42.4297,Hiroo-gun,ADM2,DISTRICT
SM278965,140.3846,40.6051,Hirosaki,ADM2,DISTRICT
SM278145,132.4531,34.4794,Hiroshima,ADM2,DISTRICT
SM1507,132.7854,34.6067,Hiroshima,ADM1:ADMX,DISTRICT
SM260228,68.1919,34.5312,Hisa-i-Awali Bihsud,ADM2,DISTRICT
SM9533,69.3448,35.1386,Hisah-ye Awal-e Kohistan,ADM2,DISTRICT
SM9534,69.3325,35.0734,Hisah-ye Duwum-e Kohistan,ADM2,DISTRICT
SM262350,24.6,42.5,Hisar,ADM2,DISTRICT
SM260133,69.7687,34.2795,Hisarak,ADM2,DISTRICT
SM298708,29.2413,39.2599,Hisarcık,ADM2,DISTRICT
SM270224,-75.9041,5.7956,Hispania,ADM2,DISTRICT
SM276861,75.75,29.1667,Hissar (1997-),ADM2,DISTRICT
SM305766,42.6823,33.7348,Hit,ADM2,DISTRICT
SM279016,130.9506,33.2445,Hita,ADM2,DISTRICT
SM278408,140.6188,36.6249,Hitachi,ADM2,DISTRICT
SM278409,140.5579,36.396,Hitachinaka,ADM2,DISTRICT
SM278410,140.3399,36.6137,Hitachiōmiya,ADM2,DISTRICT
SM278411,140.5054,36.6484,Hitachiōta,ADM2,DISTRICT
SM301716,-101.0423,40.1764,Hitchcock,ADM2,DISTRICT
SM253906,-81.69,33.6,Hitchiti,ETHNICITY,ETHNICITY
SM11242,-149.3052,-17.6057,Hitiaa O Te Ra,ADM2,DISTRICT
SM278664,130.7401,32.173,Hitoyoshi,ADM2,DISTRICT
SM4684,8.7803,63.5453,Hitra (2020-),ADM2,DISTRICT
SM232374,35,40,Hittite,LANGUAGE,LANGUAGE
SM232375,128.186,-3.5748,Hitu,LANGUAGE,LANGUAGE
SM11243,-139.0053,-9.7515,Hiva-Oa,ADM2,DISTRICT
SM232378,166.5849,-13.1264,Hiw,LANGUAGE,LANGUAGE
SM232379,-58.8015,-0.1627,Hixkaryána,LANGUAGE,LANGUAGE
SM12915,140.2443,41.8125,Hiyama-gun,ADM2,DISTRICT
SM298212,42.4249,38.226,Hizan,ADM2,DISTRICT
SM304183,105.9667,21.3333,Hiệp Hòa,ADM2,DISTRICT
SM304399,108.0954,15.5386,Hiệp Đức,ADM2,DISTRICT
SM286218,8.9494,59.6128,Hjartdal,ADM2,DISTRICT
SM286153,6.1791,59.2367,Hjelmeland,ADM2,DISTRICT
SM296576,14.2075,58.2964,Hjo,ADM2,DISTRICT
SM271713,10.0586,57.4568,Hjørring (2007-),ADM2,DISTRICT
SM283470,95.5272,25.4256,Hkamti,ADM2,DISTRICT
SM232385,109.459,18.8062,Hlai,LANGUAGE,LANGUAGE
SM296691,31.8749,-26.2983,Hlane,ADM2,DISTRICT
SM305723,16.9668,46.1537,Hlebine,ADM2,DISTRICT
SM250299,32.83,-20.24,Hlengwe,ETHNICITY,ETHNICITY
SM13976,27.9024,-28.96,Hleoheng,ADM2,DISTRICT
SM232390,103.733,23.591,Hlepho Phowa,LANGUAGE,LANGUAGE
SM232391,101.4,24.4,Hlersu,LANGUAGE,LANGUAGE
SM292572,27.1708,47.604,Hlipiceni,ADM2,DISTRICT
SM299660,33.2744,49.3793,Hlobyns'kyi,ADM2,DISTRICT
SM296176,17.75,48.4167,Hlohovec,ADM2,DISTRICT
SM251115,27.42,-30.74,Hlubi,ETHNICITY,ETHNICITY
SM299728,33.8976,51.6743,Hlukhivs'kyi,ADM2,DISTRICT
SM262641,28.6667,52.9167,Hlusk,ADM2,DISTRICT
SM262684,27.8333,55.1667,Hlybokaye,ADM2,DISTRICT
SM299871,25.9273,48.0864,Hlybots'kyi (1962-1991),ADM2,DISTRICT
SM232395,93.5497,24.7534,Hmar,LANGUAGE,LANGUAGE
SM253909,99.25,18.92,Hmong,ETHNICITY,ETHNICITY
SM232400,103.164,21.5461,Hmong Daw,LANGUAGE,LANGUAGE
SM232396,104.589,21.5211,Hmong Don,LANGUAGE,LANGUAGE
SM232399,104.182,22.7962,Hmong Dô,LANGUAGE,LANGUAGE
SM232397,101.95,22.5638,Hmong Njua,LANGUAGE,LANGUAGE
SM232407,164.768,-20.9246,Hmwaveke,LANGUAGE,LANGUAGE
SM232462,87.1188,23.9596,Ho,LANGUAGE,LANGUAGE
SM4393,0.4667,6.6,Ho (2012-),ADM2,DISTRICT
SM232415,106.6807,10.7865,Ho Chi Minh City Sign Language,LANGUAGE,LANGUAGE
SM258603,-89.31,43.49,Ho Chunk,ETHNICITY,ETHNICITY
SM258603,-89.31,43.49,Ho Chunk,ETHNICITY,ETHNICITY
SM258603,-88,44,Ho Chunk,ETHNICITY,ETHNICITY
SM258603,-88,44,Ho Chunk,ETHNICITY,ETHNICITY
SM4394,0.3664,6.5599,Ho West,ADM2,DISTRICT
SM232416,-88.5,43.5,Ho-Chunk,LANGUAGE,LANGUAGE
SM304340,105.9079,20.2539,Hoa Lư,ADM2,DISTRICT
SM253913,120.87,23.29,Hoanya,ETHNICITY,ETHNICITY
SM232411,157.595,-8.1968,Hoava,LANGUAGE,LANGUAGE
SM261760,147.2789,-42.8939,Hobart,ADM2,DISTRICT
SM270084,-75.4478,2.5413,Hobo,ADM2,DISTRICT
SM232490,113.897,0.9841,Hobongan,LANGUAGE,LANGUAGE
SM370499,5.9147,49.6886,Hobscheid,ADM1:ADM2:ADM3,DISTRICT
SM261240,144.8335,-37.8569,Hobsons Bay,ADM2,DISTRICT
SM295774,48.2047,5.9708,Hobyo,ADM2,DISTRICT
SM232413,52.8334,17.216,Hobyót,LANGUAGE,LANGUAGE
SM282966,-89.2379,20.8084,Hocabá,ADM2,DISTRICT
SM298362,29.9716,38.5878,Hocalar,ADM2,DISTRICT
SM296356,15.6238,46.4911,Hoce-Slivnica,ADM1:ADM2,DISTRICT
SM292283,27.982,46.5403,Hoceni,ADM2,DISTRICT
SM268922,8.287,47.1706,Hochdorf,ADM2,DISTRICT
SM302113,-82.4792,39.497,Hocking,ADM2,DISTRICT
SM302652,-102.3432,33.6076,Hockley,ADM2,DISTRICT
SM15782,-67.0207,18.1382,Hoconuco Alto Barrio,ADM2,DISTRICT
SM15783,-67.0609,18.1191,Hoconuco Bajo Barrio,ADM2,DISTRICT
SM282967,-89.1965,20.8727,Hoctún,ADM2,DISTRICT
SM291154,24.9606,46.7909,Hodac,ADM2,DISTRICT
SM17873,45.35,2.0333,Hodan,ADM2,DISTRICT
SM300925,-99.8979,38.0875,Hodgeman,ADM2,DISTRICT
SM253914,-10.16,17.22,Hodh,ETHNICITY,ETHNICITY
SM1509,-7,18.8833,Hodh ech Chargui,ADM1,DISTRICT
SM1510,-9.8333,16.5833,Hodh el Gharbi,ADM1,DISTRICT
SM291666,23.0463,47.3987,Hodod,ADM2,DISTRICT
SM271210,17.1328,48.8647,Hodonín,ADM2,DISTRICT
SM291155,24.8097,46.6396,Hodosa,ADM2,DISTRICT
SM296382,16.325,46.8264,Hodoš,ADM1:ADM2,DISTRICT
SM15165,4.4833,51.7667,Hoeksche Waard,ADM2,DISTRICT
SM280695,127.9857,37.4882,Hoengseong,ADM2,DISTRICT
SM11613,-18.496,65.6483,Hoergarsveit (2010-),ADM2,DISTRICT
SM289103,129.7502,42.4442,Hoeryŏng,ADM2,DISTRICT
SM289180,127.6225,38.7414,Hoeyang,ADM2,DISTRICT
SM285561,6.5975,52.2434,Hof van Twente,ADM2,DISTRICT
SM296621,16.4109,60.4962,Hofors,ADM2,DISTRICT
SM291723,24.6156,46.2194,Hoghilag,ADM2,DISTRICT
SM292632,25.3217,45.966,Hoghiz,ADM2,DISTRICT
SM253915,-124.11,47.45,Hoh,ETHNICITY,ETHNICITY
SM289568,-55.6442,-27.0869,Hohenau,ADM2,DISTRICT
SM269262,111.6888,40.6495,Hohhot,ADM2,DISTRICT
SM4388,0.4667,7.15,Hohoe (2012-),ADM2,DISTRICT
SM232425,143.7007,-7.8228,Hoia Hoia-Ukusi-Koperami,LANGUAGE,LANGUAGE
SM1512,31.11,1.393,Hoima (1991-2018),ADM1:ADM2,DISTRICT
SM11645,92.8609,26.0013,Hojai,ADM2,DISTRICT
SM270927,-85.4202,9.9755,Hojancha,ADM2,DISTRICT
SM301972,-79.2371,35.0174,Hoke,ADM2,DISTRICT
SM1513,141.3466,43.0645,Hokkaido,ADM1,DISTRICT
SM225067,142.4617,43.6337,Hokkaido Ainu,LANGUAGE,LANGUAGE
SM454371,120.97,23.97,Hokkien,ETHNICITY,ETHNICITY
SM278368,140.5199,36.1734,Hokota,ADM2,DISTRICT
SM279621,138.3938,35.8372,Hokuto,ADM2,DISTRICT
SM278262,140.5542,41.8641,Hokuto,ADM2,DISTRICT
SM286333,8.3019,60.6153,Hol,ADM2,DISTRICT
SM266564,-47.065,-22.6383,Holambra,ADM2,DISTRICT
SM290833,27.6824,47.1751,Holboca,ADM2,DISTRICT
SM271730,11.6205,55.6592,Holbæk (2007-),ADM2,DISTRICT
SM323760,-112.2322,53.2311,Holden,ADM2:ADM3,DISTRICT
SM261697,138.5232,-35.0024,Holdfast Bay,ADM2,DISTRICT
SM286334,10.2784,60.0802,Hole,ADM2,DISTRICT
SM271137,-76.2706,20.9119,Holguín,ADM2,DISTRICT
SM1514,-75.9167,20.75,Holguín,ADM1,DISTRICT
SM232434,-159.831,62.7266,Holikachuk,LANGUAGE,LANGUAGE
SM253922,-157.54,63.33,Holikachuk,ETHNICITY,ETHNICITY
SM232432,79.9164,21.5259,Holiya,LANGUAGE,LANGUAGE
SM261824,15.997,48.6529,Hollabrunn,ADM2,DISTRICT
SM12423,-77.8016,18.1036,Holland,ADM2,DISTRICT
SM232436,13.1995,9.9684,Holma,LANGUAGE,LANGUAGE
SM302114,-81.9294,40.5612,Holmes,ADM2,DISTRICT
SM301444,-90.0921,33.1235,Holmes,ADM2,DISTRICT
SM300334,-85.8141,30.8679,Holmes,ADM2,DISTRICT
SM461883,10.3151,59.4898,Holmestrand (2020-),ADM2,DISTRICT
SM292433,22.1147,46.7958,Holod,ADM2,DISTRICT
SM232437,28.3274,-5.3755,Holoholo,LANGUAGE,LANGUAGE
SM250302,30.06,-6.1,Holoholo,ETHNICITY,ETHNICITY
SM300037,32.5283,46.5333,Holoprystans'kyi,ADM2,DISTRICT
SM299472,30.5674,50.3275,Holosiïvs'kyi,ADM2,DISTRICT
SM299524,30.4582,48.3873,Holovanivs'kyi,ADM2,DISTRICT
SM271703,8.5963,56.3592,Holstebro (2007-),ADM2,DISTRICT
SM253924,-51.82,67.6,Holsteins Borg,ETHNICITY,ETHNICITY
SM301717,-98.7838,42.4557,Holt,ADM2,DISTRICT
SM301561,-95.2155,40.0944,Holt,ADM2,DISTRICT
SM286202,11.2928,62.8432,Holtålen,ADM2,DISTRICT
SM232439,17.572,-8.1475,Holu,LANGUAGE,LANGUAGE
SM280820,103.2949,18.6925,Hom,ADM1:ADM2,DISTRICT
SM232441,28.0039,4.9791,Homa,LANGUAGE,LANGUAGE
SM1515,34.481,-0.666,"Homa Bay (1992-1996, 2010-)",ADM1:ADM2,DISTRICT
SM15055,35.194,-23.9518,Homoine District (excl. Maxixe town),ADM2,DISTRICT
SM292633,25.3346,46.0726,Homorod,ADM2,DISTRICT
SM291667,23.0659,47.639,Homorodu De Mijloc,ADM2,DISTRICT
SM1516,30,52,Homyel',ADM1,DISTRICT
SM9764,31,52.3333,Homyel'ski Rayon (excl. Homyel'),ADM2,DISTRICT
SM282968,-89.2446,20.6906,Homún,ADM2,DISTRICT
SM298305,29.3447,37.7715,Honaz,ADM2,DISTRICT
SM270565,-74.7828,5.1786,Honda,ADM2,DISTRICT
SM271780,-71.6975,18.7077,Hondo Valle,ADM2,DISTRICT
SM15784,-66.269,18.1794,Honduras Barrio (Barranquitas),ADM2,DISTRICT
SM15785,-66.2088,18.152,Honduras Barrio (Cidra),ADM2,DISTRICT
SM232447,-87.224,14.622,Honduras Sign Language,LANGUAGE,LANGUAGE
SM282412,-98.2309,20.2545,Honey,ADM2,DISTRICT
SM17429,-60.9049,13.7775,Honeymoon Beach,ADM2,DISTRICT
SM284850,12.9247,10.3102,Hong,ADM2,DISTRICT
SM249207,114.0984,22.4227,Hong Kong,DIALECT,LANGUAGE
SM232455,114.1745,22.3017,Hong Kong English,DIALECT,LANGUAGE
SM232451,113.5552,22.1606,Hong Kong-Macau Sign Language,LANGUAGE,LANGUAGE
SM280696,128.0282,37.7606,Hongcheon,ADM2,DISTRICT
SM269378,102.9739,23.5167,Honghe Hani and Yi,ADM2,DISTRICT
SM280812,101.498,19.6981,Hongsa,ADM2,DISTRICT
SM280654,126.6263,36.5671,Hongseong,ADM2,DISTRICT
SM289128,127.9661,40.1403,Hongwŏn,ADM2,DISTRICT
SM232456,101.886,23.2074,Honi,LANGUAGE,LANGUAGE
SM1517,159.9667,-9.4417,Honiara,ADM1,DISTRICT
SM253927,141.55,-6.73,Honibo,ETHNICITY,ETHNICITY
SM279160,139.1463,36.1993,Honjō,ADM2,DISTRICT
SM253928,13,10,Honna,ETHNICITY,ETHNICITY
SM300540,-157.9614,21.4554,Honolulu,ADM2,DISTRICT
SM264490,-52.3961,-26.1378,Honório Serpa,ADM2,DISTRICT
SM302653,-97.8323,32.4299,Hood,ADM2,DISTRICT
SM302254,-121.6511,45.5191,Hood River,ADM2,DISTRICT
SM253929,-122.01,45.26,Hood River,ETHNICITY,ETHNICITY
SM285555,6.518,52.7182,Hoogeveen,ADM2,DISTRICT
SM301718,-101.1353,41.9159,Hooker,ADM2,DISTRICT
SM280892,101.4322,20.0721,Hoon,ADM2,DISTRICT
SM253930,-135.74,57.84,Hoonah,ETHNICITY,ETHNICITY
SM18313,-134.9415,57.9481,Hoonah-Angoon Census Area (2013-),ADM2,DISTRICT
SM285993,5.0578,52.6431,Hoorn,ADM2,DISTRICT
SM18049,41.448,41.4013,Hopa Ilcesi (2017-),ADM2,DISTRICT
SM15084,98.9685,23.026,Hopang (excl. Matman),ADM2,DISTRICT
SM289799,23.9034,46.3313,Hoparta,ADM2,DISTRICT
SM12424,-76.5758,18.1875,Hope Bay,ADM2,DISTRICT
SM17430,-60.9583,13.7648,Hope Estate,ADM2,DISTRICT
SM12425,-76.7499,18.0231,Hope Pastures/ UTECH,ADM2,DISTRICT
SM12426,-76.732,18.0123,Hope Tavern,ADM2,DISTRICT
SM1518,-76.9701,26.5245,Hope Town,ADM1,DISTRICT
SM261634,145.1925,-15.1359,Hope Vale,ADM2,DISTRICT
SM9824,-89.1,17.8,Hopelchen,ADM2,DISTRICT
SM283099,-89.6875,19.5758,Hopelchén,ADM2,DISTRICT
SM17909,25.1971,76.5756,Hopen,ADM2,DISTRICT
SM18528,-77.6624,17.9563,Hopeton (St. Elizabeth),ADM2,DISTRICT
SM12427,-77.8683,18.3986,Hopeton (St. James),ADM2,DISTRICT
SM303029,-77.2872,37.3043,Hopewell,ADM2,DISTRICT
SM12428,-78.0264,18.4432,Hopewell,ADM2,DISTRICT
SM232466,-110.535,35.7653,Hopi,LANGUAGE,LANGUAGE
SM253931,-110.73,36.82,Hopi,ETHNICITY,ETHNICITY
SM253931,-110.73,36.82,Hopi,ETHNICITY,ETHNICITY
SM253931,-110.73,36.82,Hopi,ETHNICITY,ETHNICITY
SM253931,-111,36,Hopi,ETHNICITY,ETHNICITY
SM253931,-111,36,Hopi,ETHNICITY,ETHNICITY
SM253931,-111,36,Hopi,ETHNICITY,ETHNICITY
SM253931,-110.5,36,Hopi,ETHNICITY,ETHNICITY
SM253931,-110.5,36,Hopi,ETHNICITY,ETHNICITY
SM253931,-110.5,36,Hopi,ETHNICITY,ETHNICITY
SM302654,-95.564,33.1496,Hopkins,ADM2,DISTRICT
SM301058,-87.5408,37.3088,Hopkins,ADM2,DISTRICT
SM232469,131.1722,3.0052,Horace Holden's Pidgin Tobi,LANGUAGE,LANGUAGE
SM9765,28.6422,53.2933,Horad Asipovichy,ADM2,DISTRICT
SM9766,26.0333,53.1333,Horad Baranavichy,ADM2,DISTRICT
SM9767,23.7,52.1,Horad Brest,ADM2,DISTRICT
SM9768,31.3228,52.4094,Horad Dobrush,ADM2,DISTRICT
SM9769,27.1419,53.685,Horad Dzyarzhynsk,ADM2,DISTRICT
SM9770,30.9833,52.4417,Horad Homyel',ADM2,DISTRICT
SM9771,23.8333,53.6833,Horad Hrodna,ADM2,DISTRICT
SM9772,29.3289,52.1333,Horad Kalinkavichy,ADM2,DISTRICT
SM9773,25.2997,53.8833,Horad Lida,ADM2,DISTRICT
SM9774,28.7,54.8833,Horad Lyepyel',ADM2,DISTRICT
SM9775,26.8497,54.3167,Horad Maladzyechna,ADM2,DISTRICT
SM9776,29.2667,52.05,Horad Mazyr,ADM2,DISTRICT
SM9777,25.8333,53.6,Horad Navahrudak,ADM2,DISTRICT
SM9778,28.6431,55.5292,Horad Navapolatsk,ADM2,DISTRICT
SM9779,28.8042,55.4856,Horad Polatsk,ADM2,DISTRICT
SM9780,30.05,53.085,Horad Rahachow,ADM2,DISTRICT
SM9781,27.5333,52.8,Horad Salihorsk,ADM2,DISTRICT
SM9782,25.3219,53.0867,Horad Slonim,ADM2,DISTRICT
SM9783,27.5619,53.0272,Horad Slutsk,ADM2,DISTRICT
SM9784,26.4,54.4836,Horad Smarhon',ADM2,DISTRICT
SM9785,29.7339,52.6292,Horad Svyetlahorsk,ADM2,DISTRICT
SM9786,24.4556,53.1519,Horad Vawkavysk,ADM2,DISTRICT
SM9787,26.9211,54.4975,Horad Vilyeyka,ADM2,DISTRICT
SM9788,30.1944,55.1925,Horad Vitsyebsk,ADM2,DISTRICT
SM9789,27.2833,54,Horad Zaslawye,ADM2,DISTRICT
SM9790,30.0281,52.8922,Horad Zhlobin,ADM2,DISTRICT
SM298401,42.1672,40.0423,Horasan,ADM2,DISTRICT
SM289800,22.9492,46.5006,Horea,ADM2,DISTRICT
SM292196,23.9845,45.1696,Horezu,ADM2,DISTRICT
SM269018,8.597,47.2577,Horgen,ADM2,DISTRICT
SM292114,27.056,46.3984,Horgesti,ADM2,DISTRICT
SM292047,28.4595,45.0366,Horia,ADM2,DISTRICT
SM291341,26.9389,46.9064,Horia,ADM2,DISTRICT
SM267933,-38.515,-4.1146,Horizonte,ADM2,DISTRICT
SM265697,-54.3051,-27.5809,Horizontina,ADM2,DISTRICT
SM296322,14.2847,46.0249,Horjul,ADM1:ADM2,DISTRICT
SM262642,31,54.3333,Horki,ADM2,DISTRICT
SM290834,27.4086,47.1172,Horlesti,ADM2,DISTRICT
SM18216,38.0847,48.3396,Horlivska Miskrada,ADM2,DISTRICT
SM18217,38.0134,48.323,Horlivskyi Raion (excl. Horlivska),ADM2,DISTRICT
SM1520,-67.1163,18.1416,Hormigueros,ADM1,DISTRICT
SM15786,-67.1203,18.1375,Hormigueros Barrio,ADM2,DISTRICT
SM15787,-67.1261,18.1398,Hormigueros Barrio-Pueblo,ADM2,DISTRICT
SM1521,56.5,27.75,Hormozgan,ADM1,DISTRICT
SM261825,15.6871,48.7134,Horn,ADM2,DISTRICT
SM232470,104.9,26.8,Horned Miao,LANGUAGE,LANGUAGE
SM300038,33.728,47.0096,Hornosta‹vs'kyi,ADM2,DISTRICT
SM261555,151.1095,-33.5832,Hornsby,ADM2,DISTRICT
SM232473,18.378,9.239,Horo,LANGUAGE,LANGUAGE
SM274372,37.2449,9.7123,Horo Guduru,ADM2,DISTRICT
SM291610,22.9213,47.0973,Horoatu Crasnei,ADM2,DISTRICT
SM299985,25.4998,48.6663,Horodenkivs'kyi,ADM2,DISTRICT
SM291805,26.1728,47.5136,Horodniceni,ADM2,DISTRICT
SM299463,26.5652,49.1713,Horodots'kyi,ADM2,DISTRICT
SM299441,31.4436,49.2904,Horodyshchens'kyi,ADM2,DISTRICT
SM232471,8.9848,9.0574,Horom,LANGUAGE,LANGUAGE
SM286552,175.3807,-40.5773,Horowhenua,ADM2,DISTRICT
SM15340,-56.838,-23.2565,Horqueta (2009-),ADM2,DISTRICT
SM302450,-78.9762,33.9045,Horry,ADM2,DISTRICT
SM271704,9.7738,55.9274,Horsens (2007-),ADM2,DISTRICT
SM324045,-111.369,54.1207,Horseshoe Bay,ADM2:ADM3,DISTRICT
SM261241,142.1145,-36.8286,Horsham,ADM2,DISTRICT
SM289389,-28.6792,38.571,Horta,ADM2,DISTRICT
SM461900,10.4853,59.4155,Horten (2020-),ADM2,DISTRICT
SM266565,-47.2118,-22.8718,Hortolândia,ADM2,DISTRICT
SM232481,129.387,-3.1288,Horuru,LANGUAGE,LANGUAGE
SM296721,31.412,-27.12,Hosea,ADM2,DISTRICT
SM276982,77.9889,22.5883,Hoshangabad (1998-),ADM2,DISTRICT
SM277162,75.85,31.66,Hoshiarpur (1995-),ADM2,DISTRICT
SM17431,-60.9991,14.0091,Hospital Road,ADM2,DISTRICT
SM271899,-70.022,19.1403,Hostos,ADM2,DISTRICT
SM281803,-104.0918,21.0616,Hostotipaquillo,ADM2,DISTRICT
SM12916,137.0311,37.2941,Hosu Gun,ADM2,DISTRICT
SM296894,98.4638,18.1158,Hot,ADM2,DISTRICT
SM253933,59.44,27.25,Hot Baluch,ETHNICITY,ETHNICITY
SM302375,-92.946,34.3176,Hot Spring,ADM2,DISTRICT
SM303317,-108.4421,43.7189,Hot Springs,ADM2,DISTRICT
SM232485,146.869,-7.0771,Hote,LANGUAGE,LANGUAGE
SM249792,-65.4267,5.6388,Hoti,LANGUAGE,LANGUAGE
SM8091,-63.55,6.07,Hoti,ETHNICITY,ETHNICITY
SM232486,130.168,-3.0083,Hoti of East Seram,LANGUAGE,LANGUAGE
SM1522,104,20.5,Houaphan,ADM1,DISTRICT
SM284576,165.6344,-21.2865,Houaïlou,ADM2,DISTRICT
SM262221,-4.25,11.3333,Houet (1995-),ADM1:ADM2,DISTRICT
SM301275,-88.6521,46.9915,Houghton,ADM2,DISTRICT
SM231059,119.2942,26.089,Houguan,DIALECT,LANGUAGE
SM280836,100.6269,20.3876,Houixai,ADM2,DISTRICT
SM14932,149.3,6.683,Houk Municipality,ADM2,DISTRICT
SM297902,10.8662,33.8487,Houmt Souk,ADM2,DISTRICT
SM302580,-87.717,36.286,Houston,ADM2,DISTRICT
SM302655,-95.4227,31.3177,Houston,ADM2,DISTRICT
SM301358,-91.4928,43.6714,Houston,ADM2,DISTRICT
SM300513,-83.6662,32.459,Houston,ADM2,DISTRICT
SM300274,-85.3025,31.1532,Houston,ADM2,DISTRICT
SM285588,5.1694,52.0268,Houten,ADM2,DISTRICT
SM296059,-55.1444,5.7378,Houttuin,ADM2,DISTRICT
SM262178,1.852,6.544,Houéyogbé,ADM2,DISTRICT
SM274539,-6.769,61.5158,Hov,ADM2,DISTRICT
SM1523,92.5,47,Hovd,ADM1,DISTRICT
SM1524,12.3333,55.8333,Hovedstaden,ADM1,DISTRICT
SM11774,47.9,31.3,Hoveyzeh,ADM2,DISTRICT
SM295397,159.4496,-8.1086,Hovukoilo,ADM2,DISTRICT
SM302656,-101.4355,32.3062,Howard,ADM2,DISTRICT
SM301719,-98.5171,41.22,Howard,ADM2,DISTRICT
SM301562,-92.6963,39.1425,Howard,ADM2,DISTRICT
SM301218,-76.9312,39.2507,Howard,ADM2,DISTRICT
SM300718,-86.1169,40.4836,Howard,ADM2,DISTRICT
SM300823,-92.3172,43.3568,Howard,ADM2,DISTRICT
SM302376,-93.9935,34.0887,Howard,ADM2,DISTRICT
SM301563,-91.8865,36.774,Howell,ADM2,DISTRICT
SM253934,36.99,30.12,Howitat,ETHNICITY,ETHNICITY
SM1526,-176.6173,0.8102,Howland,ADM0:ADM1,DISTRICT
SM15788,-66.9633,18.3517,Hoya Mala Barrio,ADM2,DISTRICT
SM232493,143.2783,-7.7935,Hoyahoya-Matakaia,LANGUAGE,LANGUAGE
SM461871,6.0731,61.2188,Hoyanger (2020-),ADM2,DISTRICT
SM298979,39.2188,39.1078,Hozat,ADM2,DISTRICT
SM253935,40.43,21.38,Hozayl,ETHNICITY,ETHNICITY
SM232496,34.6045,9.3888,Hozo,LANGUAGE,LANGUAGE
SM303971,106.2254,22.7082,Hoà An,ADM2,DISTRICT
SM304032,105.6143,9.2158,Hoà Bình,ADM2,DISTRICT
SM1508,105.3333,20.6667,Hoà Bình (2008-),ADM1,DISTRICT
SM304607,109.0232,14.5054,Hoài Nhơn,ADM2,DISTRICT
SM304606,108.9041,14.3388,Hoài Ân,ADM2,DISTRICT
SM304136,105.7017,21.0201,Hoài Đức,ADM2,DISTRICT
SM304137,105.8536,21.0281,Hoàn Kiếm,ADM2,DISTRICT
SM304335,105.7066,19.2619,Hoàng Mai,ADM2,DISTRICT
SM304138,105.8581,20.9752,Hoàng Mai,ADM2,DISTRICT
SM304127,104.6845,22.7158,Hoàng Su Phì,ADM2,DISTRICT
SM304429,107.0315,21.1189,Hoành Bồ,ADM2,DISTRICT
SM304530,105.8607,19.8464,Hoằng Hóa,ADM2,DISTRICT
SM15086,97.7179,16.8729,Hpa-an District (excl. Hpapun),ADM2,DISTRICT
SM15087,97.3687,18.0766,Hpapun Town,ADM2,DISTRICT
SM232497,97.1142,24.4606,Hpon,LANGUAGE,LANGUAGE
SM271221,15.6667,50.25,Hradec Králové,ADM2,DISTRICT
SM298016,10.072,36.7829,Hrairia,ADM2,DISTRICT
SM232499,93.351,24.4559,Hrangkhol,LANGUAGE,LANGUAGE
SM296244,15.0997,46.1333,Hrastnik,ADM1:ADM2,DISTRICT
SM305748,16.2258,46.1111,Hrašćina,ADM2,DISTRICT
SM232501,108.694,14.6308,Hre,LANGUAGE,LANGUAGE
SM299661,32.4384,50.114,Hrebinkivs'kyi,ADM2,DISTRICT
SM1527,24.5,53.5,Hrodna,ADM1,DISTRICT
SM296294,14.0047,45.5728,Hrpelje-Kozina,ADM1:ADM2,DISTRICT
SM288911,23.8631,50.7078,Hrubieszów,ADM2,DISTRICT
SM277804,-19.7224,64.4094,Hrunamannahreppur,ADM2,DISTRICT
SM232504,92.63,27.27,Hruso,LANGUAGE,LANGUAGE
SM305461,16.6205,43.7565,Hrvace,ADM2,DISTRICT
SM305457,16.7992,45.1894,Hrvatska Dubica,ADM2,DISTRICT
SM275991,16.5667,45.25,Hrvatska Kostajnica,ADM2,DISTRICT
SM299067,120.9672,24.8147,Hsinchu City,ADM2,DISTRICT
SM299068,121.1611,24.6742,Hsinchu County,ADM2,DISTRICT
SM232615,100.881,22.0163,Hu,LANGUAGE,LANGUAGE
SM253937,145.7,-6.72,Hua,ETHNICITY,ETHNICITY
SM232509,145.3394,-6.3534,Hua (Yagaria),DIALECT,LANGUAGE
SM297435,99.7037,12.5352,Hua Hin,ADM2,DISTRICT
SM297229,100.2461,8.0118,Hua Sai,ADM2,DISTRICT
SM296866,104.527,15.6759,Hua Taphan,ADM2,DISTRICT
SM286732,-76.8103,-8.9948,Huacaybamba,ADM2,DISTRICT
SM282571,-108.9332,30.1426,Huachinera,ADM2,DISTRICT
SM232511,-71.2275,-13.3195,Huachipaeri,LANGUAGE,LANGUAGE
SM11244,-151.0296,-16.7521,Huahine,ADM2,DISTRICT
SM297734,99.5194,15.3276,Huai Khot,ADM2,DISTRICT
SM296968,99.6763,14.3406,Huai Kra Chao,ADM2,DISTRICT
SM297154,100.5812,13.7706,Huai Kwang,ADM2,DISTRICT
SM296933,103.241,16.5887,Huai Mek,ADM2,DISTRICT
SM296943,103.88,16.6697,Huai Phung,ADM2,DISTRICT
SM297428,103.235,15.009,Huai Rat,ADM2,DISTRICT
SM297142,102.6354,15.0424,Huai Thalang,ADM2,DISTRICT
SM297569,104.0387,15.0181,Huai Thap Than,ADM2,DISTRICT
SM297671,99.605,7.8006,Huai Yot,ADM2,DISTRICT
SM269214,119.0456,33.3116,Huai'an,ADM2,DISTRICT
SM269135,116.7633,33.7256,Huaibei,ADM2,DISTRICT
SM269204,110.1436,27.6595,Huaihua,ADM2,DISTRICT
SM269120,116.7881,32.7382,Huainan,ADM2,DISTRICT
SM282147,-105.2222,22.7703,Huajicori,ADM2,DISTRICT
SM14297,-99.6698,24.8832,Hualahuises,ADM2,DISTRICT
SM286892,-78.53,-6.68,Hualgayoc,ADM2,DISTRICT
SM299069,121.3933,23.7817,Hualien,ADM2,DISTRICT
SM286838,-76.9014,-6.7566,Huallaga,ADM2,DISTRICT
SM232515,-75.594,-9.5714,Huallaga Huánuco Quechua,LANGUAGE,LANGUAGE
SM286733,-76.5,-9.25,Huamalíes,ADM2,DISTRICT
SM232517,-76.3372,-9.1572,Huamalíes-Dos de Mayo Huánuco Quechua,LANGUAGE,LANGUAGE
SM286874,-74.25,-13.2,Huamanga,ADM2,DISTRICT
SM282685,-97.9069,19.3383,Huamantla,ADM2,DISTRICT
SM232516,-77.9797,-3.9911,Huambisa,LANGUAGE,LANGUAGE
SM253939,-76.03,-3.9,Huambisa,ETHNICITY,ETHNICITY
SM260462,15.7912,-12.7935,Huambo,ADM2,DISTRICT
SM1528,15.6667,-12.5,Huambo,ADM1,DISTRICT
SM253940,-78.93,-6.57,Huambo,ETHNICITY,ETHNICITY
SM273517,-77.9219,-1.9902,Huamboya,ADM2,DISTRICT
SM280858,103.8403,20.0626,Huameuang,ADM2,DISTRICT
SM281607,-98.5557,17.7891,Huamuxtitlán,ADM2,DISTRICT
SM461510,-77.8645,-6.1739,Huanca,ETHNICITY,ETHNICITY
SM286875,-74.428,-14.076,Huanca Sancos,ADM2,DISTRICT
SM286814,-79.5833,-5.5,Huancabamba,ADM2,DISTRICT
SM286830,-69.5833,-15.1667,Huancane,ADM2,DISTRICT
SM286913,-75.25,-12.6667,Huancavelica,ADM2,DISTRICT
SM1529,-75,-13,Huancavelica,ADM1,DISTRICT
SM253941,-79.68,-1.9,Huancavilca,ETHNICITY,ETHNICITY
SM286747,-75.1617,-12.1732,Huancayo,ADM2,DISTRICT
SM282028,-101.2851,19.9783,Huandacareo,ADM2,DISTRICT
SM249504,-75.7659,-13.1144,Huangascar-Topara-Yauyos Quechua,LANGUAGE,LANGUAGE
SM269188,115.3868,30.875,Huanggang,ADM2,DISTRICT
SM269279,101.6515,35.0632,Huangnan Tibetan,ADM2,DISTRICT
SM269121,118.1055,29.892,Huangshan,ADM2,DISTRICT
SM269189,115.1142,29.9794,Huangshi,ADM2,DISTRICT
SM282029,-101.5122,19.8947,Huaniqueo,ADM2,DISTRICT
SM286876,-74.1667,-12.5,Huanta,ADM2,DISTRICT
SM283047,-102.893,21.751,Huanusco,ADM2,DISTRICT
SM281540,-101.5013,20.3583,Huanímaro,ADM2,DISTRICT
SM282413,-98.5014,18.7805,Huaquechula,ADM2,DISTRICT
SM273625,-80.2333,-3.4833,Huaquillas,ADM2,DISTRICT
SM286773,-76.9167,-11.25,Huaral,ADM2,DISTRICT
SM286809,-77.6461,-9.5611,Huaraz,ADM2,DISTRICT
SM286791,-77.1667,-9.3333,Huari,ADM2,DISTRICT
SM232529,-108.437,27.8685,Huarijio,LANGUAGE,LANGUAGE
SM286792,-78,-9.9167,Huarmey,ADM2,DISTRICT
SM286774,-76.4167,-11.9167,Huarochiri,ADM2,DISTRICT
SM253942,-68.9,-29.85,Huarpe,ETHNICITY,ETHNICITY
SM281671,-98.5486,20.2312,Huasca de Ocampo,ADM2,DISTRICT
SM269105,-70.5147,-28.6843,Huasco,ADM2,DISTRICT
SM232532,-98.5028,21.6153,Huastec,LANGUAGE,LANGUAGE
SM253943,-99,22,Huastec,ETHNICITY,ETHNICITY
SM282574,-109.4856,26.7228,Huatabampo,ADM2,DISTRICT
SM282415,-98.0382,18.6919,Huatlatlauca,ADM2,DISTRICT
SM282897,-96.88,19.14,Huatusco,ADM2,DISTRICT
SM282416,-98.0453,20.1761,Huauchinango,ADM2,DISTRICT
SM232536,129.202,-2.9583,Huaulu,LANGUAGE,LANGUAGE
SM286775,-77.238,-11.0336,Huaura,ADM2,DISTRICT
SM14298,-96.794,18.0838,Huautepec,ADM2,DISTRICT
SM281672,-98.2479,21.0427,Huautla,ADM2,DISTRICT
SM232537,-96.724,18.2556,Huautla Mazatec,LANGUAGE,LANGUAGE
SM14299,-96.8035,18.1452,Huautla de Jimenez,ADM2,DISTRICT
SM253944,-94.53,16.37,Huave,ETHNICITY,ETHNICITY
SM253944,-94.53,16.37,Huave,ETHNICITY,ETHNICITY
SM253944,-95.02,16.22,Huave,ETHNICITY,ETHNICITY
SM253944,-95.02,16.22,Huave,ETHNICITY,ETHNICITY
SM232539,-96.9537,18.8989,Huaxcaleca Nahuatl,LANGUAGE,LANGUAGE
SM282898,-98.4522,20.5386,Huayacocotla,ADM2,DISTRICT
SM286793,-77.8333,-8.9167,Huaylas,ADM2,DISTRICT
SM253945,-78.12,-9.2,Huaylas,ETHNICITY,ETHNICITY
SM232541,-77.7768,-9.3871,Huaylas Ancash Quechua,LANGUAGE,LANGUAGE
SM232542,-75.2055,-12.1809,Huaylla Wanca Quechua,LANGUAGE,LANGUAGE
SM286914,-75.0923,-13.6562,Huaytara,ADM2,DISTRICT
SM281673,-98.4899,20.9986,Huazalingo,ADM2,DISTRICT
SM232544,13.139,10.3544,Huba,LANGUAGE,LANGUAGE
SM253946,-69.87,19,Hubabo,ETHNICITY,ETHNICITY
SM304871,44.0824,14.116,Hubaysh,ADM2,DISTRICT
SM301359,-94.9166,47.1087,Hubbard,ADM2,DISTRICT
SM1531,112.25,31,Hubei,ADM1,DISTRICT
SM17788,44.2,17.7833,Hubuna,ADM2,DISTRICT
SM260842,-64,-38,Hucal,ADM2,DISTRICT
SM15789,-65.7178,18.1969,Hucares Barrio,ADM2,DISTRICT
SM253947,-123,39,Huchnom,ETHNICITY,ETHNICITY
SM253948,56.08,24.24,Huddan,ETHNICITY,ETHNICITY
SM296491,18.0158,59.2143,Huddinge,ADM2,DISTRICT
SM292573,26.501,48.1528,Hudesti,ADM2,DISTRICT
SM296622,16.7706,61.7662,Hudiksvall,ADM2,DISTRICT
SM301833,-74.0759,40.7309,Hudson,ADM2,DISTRICT
SM302657,-105.3865,31.4562,Hudspeth,ADM2,DISTRICT
SM290102,23.0394,46.872,Huedin,ADM2,DISTRICT
SM275502,-91.3876,15.314,Huehuetenango,ADM2,DISTRICT
SM1532,-91.5833,15.6667,Huehuetenango,ADM1,DISTRICT
SM282417,-97.6251,20.1039,Huehuetla,ADM2,DISTRICT
SM281674,-98.0331,20.532,Huehuetla,ADM2,DISTRICT
SM232546,-97.9347,20.6189,Huehuetla Tepehua,LANGUAGE,LANGUAGE
SM282483,-98.9831,21.522,Huehuetlán,ADM2,DISTRICT
SM282418,-98.7114,18.3824,Huehuetlán el Chico,ADM2,DISTRICT
SM282419,-98.1577,18.7469,Huehuetlán el Grande,ADM2,DISTRICT
SM281916,-99.2038,19.8354,Huehuetoca,ADM2,DISTRICT
SM283141,-92.4009,15.0305,Huehuetán,ADM2,DISTRICT
SM283236,-106.1057,27.0271,Huejotitán,ADM2,DISTRICT
SM282420,-98.4172,19.1762,Huejotzingo,ADM2,DISTRICT
SM281805,-103.9035,22.5805,Huejuquilla el Alto,ADM2,DISTRICT
SM281675,-98.4771,21.1161,Huejutla de Reyes,ADM2,DISTRICT
SM281804,-103.2525,22.3341,Huejúcar,ADM2,DISTRICT
SM274036,-7,37.6667,Huelva,ADM2,DISTRICT
SM286734,-76.0833,-9.6667,Huenuco,ADM2,DISTRICT
SM253949,-77.14,18.14,Huereo,ETHNICITY,ETHNICITY
SM303339,-104.9606,37.6847,Huerfano,ADM2,DISTRICT
SM274058,-0.1667,42.1667,Huesca,ADM2,DISTRICT
SM282030,-101.0596,18.6577,Huetamo,ADM2,DISTRICT
SM232548,-84.2551,10.1598,Huetar,LANGUAGE,LANGUAGE
SM282421,-97.4102,19.9186,Hueyapan,ADM2,DISTRICT
SM282900,-95.1492,18.1658,Hueyapan de Ocampo,ADM2,DISTRICT
SM282686,-98.3465,19.4907,Hueyotlipan,ADM2,DISTRICT
SM281917,-99.077,19.912,Hueypoxtla,ADM2,DISTRICT
SM282422,-97.3108,20.0317,Hueytamalco,ADM2,DISTRICT
SM282423,-97.6773,20.0437,Hueytlalpan,ADM2,DISTRICT
SM305083,43.4265,15.3747,Hufash,ADM2,DISTRICT
SM12429,-76.8173,18.0304,Hughenden,ADM2,DISTRICT
SM323933,-110.969,52.5102,Hughenden,ADM2:ADM3,DISTRICT
SM302503,-99.9961,44.389,Hughes,ADM2,DISTRICT
SM302197,-96.2502,35.0483,Hughes,ADM2,DISTRICT
SM277374,88.201,22.8289,Hugli,ADM2,DISTRICT
SM265238,-42.4677,-6.0329,Hugo Napoleão,ADM2,DISTRICT
SM232549,111.7439,40.8555,Huhehaote,DIALECT,LANGUAGE
SM282969,-89.1523,20.7078,Huhí,ADM2,DISTRICT
SM253950,120.73,31.54,Hui,ETHNICITY,ETHNICITY
SM253950,116.89,37.42,Hui,ETHNICITY,ETHNICITY
SM253950,124.98,42.85,Hui,ETHNICITY,ETHNICITY
SM253950,125.46,40.55,Hui,ETHNICITY,ETHNICITY
SM232560,118.232,29.7646,Hui Chinese,LANGUAGE,LANGUAGE
SM281676,-99.652,20.3893,Huichapan,ADM2,DISTRICT
SM232551,-104.062,21.954,Huichol,LANGUAGE,LANGUAGE
SM253951,-104,23,Huichol,ETHNICITY,ETHNICITY
SM253951,-104,23,Huichol,ETHNICITY,ETHNICITY
SM253951,-105,22,Huichol,ETHNICITY,ETHNICITY
SM253951,-105,22,Huichol,ETHNICITY,ETHNICITY
SM1533,-75.5833,2.5,Huila,ADM1,DISTRICT
SM260928,-71.3333,-39.75,Huiliches,ADM2,DISTRICT
SM232554,-72.767,-40.0694,Huilliche,LANGUAGE,LANGUAGE
SM253952,-72.22,-41.84,Huilliche,ETHNICITY,ETHNICITY
SM282901,-97.1319,18.826,Huiloapan de Cuauhtémoc,ADM2,DISTRICT
SM282636,-93.667,17.8153,Huimanguillo,ADM2,DISTRICT
SM282463,-100.3232,20.4332,Huimilpan,ADM2,DISTRICT
SM282031,-101.4596,19.5265,Huiramba,ADM2,DISTRICT
SM232559,-97.1236,16.9476,Huitepec Mixtec,LANGUAGE,LANGUAGE
SM10297,15.104,12.1485,Huitieme Arrondissement,ADM2,DISTRICT
SM283142,-92.7081,17.239,Huitiupán,ADM2,DISTRICT
SM282130,-99.2485,19.0288,Huitzilac,ADM2,DISTRICT
SM282424,-97.7046,19.9455,Huitzilan de Serdán,ADM2,DISTRICT
SM282426,-97.8596,18.7669,Huitziltepec,ADM2,DISTRICT
SM281608,-99.2636,18.2906,Huitzuco de los Figueroa,ADM2,DISTRICT
SM275212,-91.6277,15.0452,Huitán,ADM2,DISTRICT
SM275383,-89.692,14.9217,Huité,ADM2,DISTRICT
SM281918,-99.3507,19.36,Huixquilucan,ADM2,DISTRICT
SM283144,-92.5122,15.1429,Huixtla,ADM2,DISTRICT
SM283143,-92.401,16.706,Huixtán,ADM2,DISTRICT
SM285994,5.2413,52.2966,Huizen,ADM2,DISTRICT
SM269433,114.4616,23.1989,Huizhou,ADM2,DISTRICT
SM295674,-89.2363,13.5652,Huizúcar,ADM2,DISTRICT
SM232563,126.194,-3.274,Hukumina,LANGUAGE,LANGUAGE
SM253953,-112,42,Hukundika,ETHNICITY,ETHNICITY
SM253953,-112,42,Hukundika,ETHNICITY,ETHNICITY
SM253953,-112,42,Hukundika,ETHNICITY,ETHNICITY
SM253953,-112.22,41.55,Hukundika,ETHNICITY,ETHNICITY
SM253953,-112.22,41.55,Hukundika,ETHNICITY,ETHNICITY
SM253953,-112.22,41.55,Hukundika,ETHNICITY,ETHNICITY
SM253953,-112,41.7,Hukundika,ETHNICITY,ETHNICITY
SM253953,-112,41.7,Hukundika,ETHNICITY,ETHNICITY
SM253953,-112,41.7,Hukundika,ETHNICITY,ETHNICITY
SM268547,21.6741,-24.0064,Hukunsti,ADM2,DISTRICT
SM250405,22.21,-18.25,Hukwe Bushmen,ETHNICITY,ETHNICITY
SM250405,22.21,-18.25,Hukwe Bushmen,ETHNICITY,ETHNICITY
SM250405,22.21,-18.25,Hukwe Bushmen,ETHNICITY,ETHNICITY
SM250405,24.32,-16.93,Hukwe Bushmen,ETHNICITY,ETHNICITY
SM250405,24.32,-16.93,Hukwe Bushmen,ETHNICITY,ETHNICITY
SM250405,24.32,-16.93,Hukwe Bushmen,ETHNICITY,ETHNICITY
SM250405,21.8851,-18.3735,Hukwe Bushmen,ETHNICITY,ETHNICITY
SM250405,21.8851,-18.3735,Hukwe Bushmen,ETHNICITY,ETHNICITY
SM250405,21.8851,-18.3735,Hukwe Bushmen,ETHNICITY,ETHNICITY
SM232564,147.705,-9.9943,Hula,LANGUAGE,LANGUAGE
SM253954,147.8,-9.87,Hula,ETHNICITY,ETHNICITY
SM232566,43.4865,31.9111,Hulaulá,LANGUAGE,LANGUAGE
SM265698,-53.8627,-31.572,Hulha Negra,ADM2,DISTRICT
SM232568,142.963,-5.9721,Huli,LANGUAGE,LANGUAGE
SM253955,142.32,-6.14,Huli,ETHNICITY,ETHNICITY
SM299836,36.2549,47.6667,Huliaipil's'kyi,ADM2,DISTRICT
SM285623,4.0869,51.319,Hulst,ADM2,DISTRICT
SM296660,15.8,57.4,Hultsfred,ADM2,DISTRICT
SM284384,101.8333,3.0833,Hulu Langat,ADM2,DISTRICT
SM284385,101.5833,3.5,Hulu Selangor,ADM2,DISTRICT
SM276400,115.2,-2.75,Hulu Sungai Selatan,ADM2,DISTRICT
SM276401,115.4167,-2.6167,Hulu Sungai Tengah,ADM2,DISTRICT
SM276402,115.1333,-2.45,Hulu Sungai Utara,ADM2,DISTRICT
SM290275,25.2625,44.842,Hulubesti,ADM2,DISTRICT
SM269253,120.2197,40.7453,Huludao,ADM2,DISTRICT
SM269263,121.7218,49.7099,Hulunbuir,ADM2,DISTRICT
SM232571,128.587,-2.8739,Hulung,LANGUAGE,LANGUAGE
SM305749,15.6887,46.2118,Hum na Sutli,ADM2,DISTRICT
SM1535,-65.8121,18.148,Humacao,ADM1,DISTRICT
SM15790,-65.825,18.1515,Humacao Barrio-Pueblo,ADM2,DISTRICT
SM260834,-65.3333,-23.0833,Humahuaca,ADM2,DISTRICT
SM289614,-58.5056,-27.1098,Humaitá,ADM2,DISTRICT
SM265699,-53.9963,-27.5856,Humaitá,ADM2,DISTRICT
SM267322,-62.3248,-7.4387,Humaitá,ADM2,DISTRICT
SM15791,-67.1067,18.3094,Humatas Barrio,ADM2,DISTRICT
SM276704,98.5038,2.2655,Humbang Hasundutan,ADM2,DISTRICT
SM263006,-43.5785,-2.6456,Humberto Campos,ADM2,DISTRICT
SM301786,-118.112,41.4068,Humboldt,ADM2,DISTRICT
SM300824,-94.2072,42.7765,Humboldt,ADM2,DISTRICT
SM303166,-123.9158,40.705,Humboldt,ADM2,DISTRICT
SM232574,-1.693,15.2948,Humburi Senni Songhay,LANGUAGE,LANGUAGE
SM261242,144.8051,-37.5972,Hume,ADM2,DISTRICT
SM232576,147.562,-9.7069,Humene,LANGUAGE,LANGUAGE
SM296157,21.9167,48.9333,Humenné,ADM2,DISTRICT
SM232577,81.5659,30.1451,Humla,LANGUAGE,LANGUAGE
SM375343,81.8343,30.026,Humla,ADM2:ADM3,DISTRICT
SM260319,13.3677,-15.0725,Humpata,ADM2,DISTRICT
SM302581,-87.7756,36.0408,Humphreys,ADM2,DISTRICT
SM301445,-90.5266,33.1287,Humphreys,ADM2,DISTRICT
SM253959,50.33,15.51,Humum,ETHNICITY,ETHNICITY
SM275855,-87.6667,14.25,Humuya,ADM2,DISTRICT
SM232589,5.0731,10.7898,Hun-Saare,LANGUAGE,LANGUAGE
SM1536,111.75,27.6667,Hunan,ADM1,DISTRICT
SM11615,-19.8088,65.231,Hunavatnshreppur (2006-2022),ADM2,DISTRICT
SM232578,28.9308,-1.4676,Hunde,LANGUAGE,LANGUAGE
SM290763,22.8754,45.7651,Hunedoara,ADM2,DISTRICT
SM1537,23,45.75,Hunedoara,ADM1,DISTRICT
SM232582,104.772,18.7849,Hung,LANGUAGE,LANGUAGE
SM232585,18.4312,-3.697,Hungana-Saamba,LANGUAGE,LANGUAGE
SM232581,19.6555,46.9069,Hungarian,LANGUAGE,LANGUAGE
SM253960,18.57,50.26,Hungarian,ETHNICITY,ETHNICITY
SM253960,18.57,50.26,Hungarian,ETHNICITY,ETHNICITY
SM253960,18.57,50.26,Hungarian,ETHNICITY,ETHNICITY
SM253960,18.57,50.26,Hungarian,ETHNICITY,ETHNICITY
SM253960,20,47,Hungarian,ETHNICITY,ETHNICITY
SM253960,20,47,Hungarian,ETHNICITY,ETHNICITY
SM253960,20,47,Hungarian,ETHNICITY,ETHNICITY
SM253960,20,47,Hungarian,ETHNICITY,ETHNICITY
SM253960,19.1,47.5,Hungarian,ETHNICITY,ETHNICITY
SM253960,19.1,47.5,Hungarian,ETHNICITY,ETHNICITY
SM253960,19.1,47.5,Hungarian,ETHNICITY,ETHNICITY
SM253960,19.1,47.5,Hungarian,ETHNICITY,ETHNICITY
SM253960,19.0503,47.4875,Hungarian,ETHNICITY,ETHNICITY
SM253960,19.0503,47.4875,Hungarian,ETHNICITY,ETHNICITY
SM253960,19.0503,47.4875,Hungarian,ETHNICITY,ETHNICITY
SM253960,19.0503,47.4875,Hungarian,ETHNICITY,ETHNICITY
SM232580,19.0521,47.4924,Hungarian Sign Language,LANGUAGE,LANGUAGE
SM232587,18.1921,-9.1646,Hungu,LANGUAGE,LANGUAGE
SM232583,6.3024,10.3243,Hungworo,LANGUAGE,LANGUAGE
SM232588,147.9,-8.92,Hunjara-Kaina Ke,LANGUAGE,LANGUAGE
SM243240,-51.7219,-29.3255,Hunsrik,LANGUAGE,LANGUAGE
SM302658,-96.0855,33.1236,Hunt,ADM2,DISTRICT
SM301814,-74.9122,40.5673,Hunterdon,ADM2,DISTRICT
SM261556,151.1451,-33.8344,Hunters Hill,ADM2,DISTRICT
SM302309,-77.9812,40.4169,Huntingdon,ADM2,DISTRICT
SM300719,-85.4882,40.8292,Huntington,ADM2,DISTRICT
SM12430,-77.5817,18.0665,Huntley,ADM2,DISTRICT
SM282970,-89.8805,21.0309,Hunucmá,ADM2,DISTRICT
SM5140,75.0041,36.4972,Hunza-Nagar (pre-2015),ADM2:ADM3,DISTRICT
SM232592,46.1369,42.0808,Hunzib,LANGUAGE,LANGUAGE
SM288582,146.95,-7.1833,Huon,ADM2,DISTRICT
SM261761,146.5656,-43.2939,Huon Valley,ADM2,DISTRICT
SM232600,-69.8359,0.5862,Hup,LANGUAGE,LANGUAGE
SM253961,-122.11,40.79,Hupa,ETHNICITY,ETHNICITY
SM253961,-122.11,40.79,Hupa,ETHNICITY,ETHNICITY
SM253961,-122.11,40.79,Hupa,ETHNICITY,ETHNICITY
SM253961,-122.11,40.79,Hupa,ETHNICITY,ETHNICITY
SM253961,-123,41,Hupa,ETHNICITY,ETHNICITY
SM253961,-123,41,Hupa,ETHNICITY,ETHNICITY
SM253961,-123,41,Hupa,ETHNICITY,ETHNICITY
SM253961,-123,41,Hupa,ETHNICITY,ETHNICITY
SM253961,-123.61,40.93,Hupa,ETHNICITY,ETHNICITY
SM253961,-123.61,40.93,Hupa,ETHNICITY,ETHNICITY
SM253961,-123.61,40.93,Hupa,ETHNICITY,ETHNICITY
SM253961,-123.61,40.93,Hupa,ETHNICITY,ETHNICITY
SM253961,-123.5,41,Hupa,ETHNICITY,ETHNICITY
SM253961,-123.5,41,Hupa,ETHNICITY,ETHNICITY
SM253961,-123.5,41,Hupa,ETHNICITY,ETHNICITY
SM253961,-123.5,41,Hupa,ETHNICITY,ETHNICITY
SM253962,-121.2,40.69,Hupa East,ETHNICITY,ETHNICITY
SM232598,-123.68,41.0982,Hupa-Chilula,LANGUAGE,LANGUAGE
SM232602,139.164,-4.3635,Hupla,LANGUAGE,LANGUAGE
SM304813,48.1751,15.5543,Huraidhah,ADM2,DISTRICT
SM17789,46.2833,25.2333,Huraymila',ADM2,DISTRICT
SM286036,11.0671,60.4352,Hurdal (1870-),ADM2,DISTRICT
SM292284,28.0506,46.5293,Hurdugi,ADM2,DISTRICT
SM290632,23.6362,44.8031,Hurezani,ADM2,DISTRICT
SM302115,-82.5984,41.1462,Huron,ADM2,DISTRICT
SM301276,-82.8555,43.9101,Huron,ADM2,DISTRICT
SM268852,-81.4998,43.6668,Huron,ADM2,DISTRICT
SM253963,-79.92,44.19,Huron,ETHNICITY,ETHNICITY
SM253963,-79.92,44.19,Huron,ETHNICITY,ETHNICITY
SM253963,-79.92,44.19,Huron,ETHNICITY,ETHNICITY
SM253963,-79.97,44.16,Huron,ETHNICITY,ETHNICITY
SM253963,-79.97,44.16,Huron,ETHNICITY,ETHNICITY
SM253963,-79.97,44.16,Huron,ETHNICITY,ETHNICITY
SM253963,-78,44,Huron,ETHNICITY,ETHNICITY
SM253963,-78,44,Huron,ETHNICITY,ETHNICITY
SM253963,-78,44,Huron,ETHNICITY,ETHNICITY
SM253963,-79,44.5,Huron,ETHNICITY,ETHNICITY
SM253963,-79,44.5,Huron,ETHNICITY,ETHNICITY
SM253963,-79,44.5,Huron,ETHNICITY,ETHNICITY
SM249172,-77.5,44.33,Huron-Wyandot,LANGUAGE,LANGUAGE
SM232606,43.3154,40.7339,Hurrian,LANGUAGE,LANGUAGE
SM292115,27.2383,46.253,Huruiesti,ADM2,DISTRICT
SM305254,29.5858,-16.413,Hurungwe,ADM2,DISTRICT
SM286539,172.6918,-42.6674,Hurunui,ADM2,DISTRICT
SM251046,28.33,-24.72,Hurutshe,ETHNICITY,ETHNICITY
SM292434,21.9191,46.849,Husasau De Tinca,ADM2,DISTRICT
SM292285,28.0595,46.6732,Husi,ADM2,DISTRICT
SM299748,26.2041,49.0711,Husiatyns'kyi,ADM2,DISTRICT
SM291078,22.8394,44.6628,Husnicioara,ADM2,DISTRICT
SM323899,-112.6797,51.0417,Hussar,ADM2:ADM3,DISTRICT
SM278014,35.7953,30.5336,Husseiniyyeh,ADM2,DISTRICT
SM15295,7.2254,62.8863,Hustadvika,ADM2,DISTRICT
SM305072,51.9124,15.723,Huswain,ADM2,DISTRICT
SM253965,40.79,25.22,Hutaim,ETHNICITY,ETHNICITY
SM302504,-97.7544,43.3349,Hutchinson,ADM2,DISTRICT
SM302659,-101.3547,35.84,Hutchinson,ADM2,DISTRICT
SM304773,43.9781,16.284,Huth,ADM2,DISTRICT
SM253966,24,49,Hutsuls,ETHNICITY,ETHNICITY
SM232612,-97,40,Hutterite German,LANGUAGE,LANGUAGE
SM295142,29.696,-2.517,Huye,ADM1:ADM2,DISTRICT
SM18473,106.6946,8.6436,Huyen Con GJao,ADM2,DISTRICT
SM18474,112,16.5,Huyen Hoang Sa,ADM2,DISTRICT
SM18475,114,9,Huyen Truong Sa,ADM2,DISTRICT
SM269385,119.9,30.8,Huzhou,ADM2,DISTRICT
SM232616,102.1175,36.8177,Huzhu,LANGUAGE,LANGUAGE
SM1530,-76,-9.5,Huánuco,ADM1,DISTRICT
SM282573,-109.3726,29.9592,Huásabas,ADM2,DISTRICT
SM282575,-110.2954,29.9325,Huépac,ADM2,DISTRICT
SM1534,15.0001,-14.6953,Huíla,ADM1,DISTRICT
SM304481,107.5763,16.4562,Huế,ADM2,DISTRICT
SM274540,-6.9674,61.5999,Hvalba,ADM2,DISTRICT
SM286298,11.0311,59.0281,Hvaler,ADM2,DISTRICT
SM11616,-21.6109,64.4427,Hvalfjardarsveit,ADM2,DISTRICT
SM274526,-6.5533,62.2967,Hvannasund,ADM2,DISTRICT
SM275993,16.4455,43.1726,Hvar,ADM2,DISTRICT
SM277805,-21.2,64,Hveragerði,ADM2,DISTRICT
SM271674,12.4662,55.6317,Hvidovre,ADM2,DISTRICT
SM280697,127.6762,38.1421,Hwacheon,ADM2,DISTRICT
SM289104,129.496,40.8406,Hwadae,ADM2,DISTRICT
SM253967,28.01,-25.08,Hwaduba,ETHNICITY,ETHNICITY
SM232621,12.7683,10.3633,Hwana,LANGUAGE,LANGUAGE
SM305268,26.5,-18.75,Hwange,ADM2,DISTRICT
SM461907,126.3,38.5667,"Hwanghae-bukto (1954-2002, 2019-)",ADM1,DISTRICT
SM1540,125.5333,38.2,Hwanghae-namdo,ADM1,DISTRICT
SM232620,125.47,38.29,Hwanghaedo,DIALECT,LANGUAGE
SM289131,125.7478,38.7058,Hwangju,ADM2,DISTRICT
SM289045,126.9183,41.2481,Hwap'yŏng,ADM2,DISTRICT
SM280708,126.8291,37.2002,Hwaseong,ADM2,DISTRICT
SM280589,127.0265,35.0047,Hwasun,ADM2,DISTRICT
SM289105,129.4167,41.25,Hwasŏng,ADM2,DISTRICT
SM232629,13.526,10.4814,Hya,LANGUAGE,LANGUAGE
SM232630,7.9516,9.5783,Hyam,LANGUAGE,LANGUAGE
SM289207,126.0994,40.0186,Hyangsan,ADM2,DISTRICT
SM302505,-99.4871,44.5474,Hyde,ADM2,DISTRICT
SM301973,-76.1485,35.41,Hyde,ADM2,DISTRICT
SM375482,68.4842,25.4324,Hyderabad,ADM2:ADM3,DISTRICT
SM277252,78.4898,17.4016,Hyderabad,ADM2,DISTRICT
SM289082,128.2467,41.3556,Hyesan,ADM2,DISTRICT
SM286160,5.2961,61.1704,Hyllestad,ADM2,DISTRICT
SM296631,13.2654,56.9739,Hylte,ADM2,DISTRICT
SM15263,125.7167,39.1,Hyongjesan-guyok,ADM2,DISTRICT
SM323799,-119.5582,55.3282,Hythe,ADM2:ADM3,DISTRICT
SM1541,134.8167,35.0333,Hyōgo,ADM1:ADMX,DISTRICT
SM278782,131.5228,32.3771,Hyūga,ADM2,DISTRICT
SM304126,105.0314,22.7893,Hà Giang,ADM2,DISTRICT
SM1435,105,22.75,Hà Giang,ADM1,DISTRICT
SM1437,105.9667,20.5333,Hà Nam,ADM1,DISTRICT
SM1436,105.75,21,Hà Nội (2003-),ADM1,DISTRICT
SM303970,106.1249,22.9076,Hà Quảng,ADM2,DISTRICT
SM304232,104.5029,10.386,Hà Tiên,ADM2,DISTRICT
SM304529,105.8193,20.0265,Hà Trung,ADM2,DISTRICT
SM304176,105.8924,18.3489,Hà Tĩnh,ADM2,DISTRICT
SM1438,105.75,18.3333,Hà Tĩnh,ADM1,DISTRICT
SM304134,105.7685,20.9548,Hà Đông,ADM2,DISTRICT
SM303938,108.0817,11.147,Hàm Thuận Bắc,ADM2,DISTRICT
SM303939,107.9333,10.921,Hàm Thuận Nam,ADM2,DISTRICT
SM303937,107.6423,10.7686,Hàm Tân,ADM2,DISTRICT
SM304563,105.0163,22.1094,Hàm Yên,ADM2,DISTRICT
SM1450,-17,65.5,Hálshreppur,ADM1,DISTRICT
SM296408,14.615,59.7574,Hällefors,ADM2,DISTRICT
SM346839,24.5703,61.0343,Hämeenlinna,ADM2:ADM3,DISTRICT
SM296638,13.8073,62.2054,Härjedalen,ADM2,DISTRICT
SM296535,17.6833,62.7667,Härnösand,ADM2,DISTRICT
SM296574,12.3201,57.6774,Härryda,ADM2,DISTRICT
SM296459,13.7237,56.2069,Hässleholm,ADM2,DISTRICT
SM286151,5.645,58.6161,Hå,ADM2,DISTRICT
SM296498,17.507,59.6495,Håbo,ADM2,DISTRICT
SM286258,7.2197,58.3757,Hægebostad,ADM2,DISTRICT
SM274456,3.5,43.6667,Hérault,ADM2,DISTRICT
SM268992,7.4239,46.1941,Hérens,ADM2,DISTRICT
SM276217,17.2238,46.7836,Hévíz/Keszthely,ADM2,DISTRICT
SM1505,28.4167,46.8333,Hîncesti,ADM1,DISTRICT
SM304195,105.3289,20.8246,Hòa Bình,ADM2,DISTRICT
SM304475,106.1449,11.2671,Hòa Thành,ADM2,DISTRICT
SM304023,108.0365,16.0671,Hòa Vang,ADM2,DISTRICT
SM304233,104.9528,10.2291,Hòn Đất,ADM2,DISTRICT
SM304118,106.588,10.8836,Hóc Môn,ADM2,DISTRICT
SM276248,20.376,46.433,Hódmezővásárhely,ADM1:ADM2,DISTRICT
SM232449,10.9206,9.7972,Hõne,LANGUAGE,LANGUAGE
SM268950,8.7396,47.1904,Höfe,ADM2,DISTRICT
SM1511,-21.6667,64.1667,Höfuðborgarsvæði,ADM1,DISTRICT
SM277797,-20.1633,65.8581,Höfðahreppur,ADM2,DISTRICT
SM296429,12.597,56.2252,Höganäs,ADM2,DISTRICT
SM296659,15.9167,57.1554,Högsby,ADM2,DISTRICT
SM296431,13.7428,55.8377,Hörby,ADM2,DISTRICT
SM1525,100,50,Hövsgöl,ADM1,DISTRICT
SM296430,13.5169,55.9459,Höör,ADM2,DISTRICT
SM271672,12.2485,55.6564,Høje Taastrup,ADM2,DISTRICT
SM271673,12.4857,55.8876,Hørsholm,ADM2,DISTRICT
SM286468,12.3004,64.6276,Høylandet,ADM2,DISTRICT
SM277798,-20.9167,65.25,Húnaþing vestra,ADM2,DISTRICT
SM274530,-6.6804,61.8121,Húsavík,ADM2,DISTRICT
SM298675,31.6001,37.9633,Hüyük,ADM2,DISTRICT
SM298400,41.6972,39.361,Hınıs,ADM2,DISTRICT
SM279592,131.5681,34.0729,Hōfu,ADM2,DISTRICT
SM289127,128.5542,40.7658,Hŏch'ŏn,ADM2,DISTRICT
SM289044,126.2417,40.1597,Hŭich'ŏn,ADM2,DISTRICT
SM304490,106.2123,20.5946,Hưng Hà,ADM2,DISTRICT
SM304336,105.6252,18.6781,Hưng Nguyên,ADM2,DISTRICT
SM304204,106.0595,20.6569,Hưng Yên,ADM2,DISTRICT
SM1538,106.05,20.8167,Hưng Yên,ADM1,DISTRICT
SM304177,105.6985,18.1701,Hương Khê,ADM2,DISTRICT
SM304178,105.3285,18.4758,Hương Sơn,ADM2,DISTRICT
SM304482,107.6244,16.3147,Hương Thủy,ADM2,DISTRICT
SM304483,107.4689,16.3777,Hương Trà,ADM2,DISTRICT
SM304445,106.6704,16.7013,Hướng Hóa,ADM2,DISTRICT
SM304360,104.9946,21.5776,Hạ Hoà,ADM2,DISTRICT
SM303969,106.6766,22.7093,Hạ Lang,ADM2,DISTRICT
SM304427,107.0496,20.9612,Hạ Long,ADM2,DISTRICT
SM304086,106.7356,20.8433,Hải An,ADM2,DISTRICT
SM304022,108.2135,16.0418,Hải Châu,ADM2,DISTRICT
SM304069,106.3116,20.9384,Hải Dương,ADM2,DISTRICT
SM1429,106.3333,20.9167,Hải Dương,ADM1,DISTRICT
SM304428,107.6679,21.4954,Hải Hà,ADM2,DISTRICT
SM304310,106.2716,20.1506,Hải Hậu,ADM2,DISTRICT
SM304444,107.2339,16.6757,Hải Lăng,ADM2,DISTRICT
SM1430,106.6667,20.8,Hải Phòng,ADM1,DISTRICT
SM1431,105.4641,9.7761,Hậu Giang (2003-),ADM1,DISTRICT
SM304528,105.8889,19.9334,Hậu Lộc,ADM2,DISTRICT
SM1432,106.6298,10.8233,Hồ Chí Minh,ADM1,DISTRICT
SM304087,106.657,20.8691,Hồng Bàng,ADM2,DISTRICT
SM304031,105.4224,9.5382,Hồng Dân,ADM2,DISTRICT
SM304175,105.719,18.525,Hồng Lĩnh,ADM2,DISTRICT
SM304016,105.2926,10.8113,Hồng Ngự,ADM2,DISTRICT
SM304015,105.3827,10.8144,Hồng Ngự (Thị xã),ADM2,DISTRICT
SM304398,108.342,15.8834,Hội An,ADM2,DISTRICT
SM303932,106.6274,11.5972,Hớn Quản,ADM2,DISTRICT
SM304252,106.3439,21.5447,Hữu Lũng,ADM2,DISTRICT
SM235280,141.303,-2.8008,I'saka,LANGUAGE,LANGUAGE
SM233124,120.7863,16.3045,I-Wak,LANGUAGE,LANGUAGE
SM292049,28.0533,45.4065,I.c.bratianu,ADM2,DISTRICT
SM285589,5.0287,52.0291,IJsselstein,ADM2,DISTRICT
SM304046,107.7374,13.9894,Ia Grai,ADM2,DISTRICT
SM304239,107.4624,14.0603,Ia H' Drai,ADM2,DISTRICT
SM304047,108.5388,13.5161,Ia Pa,ADM2,DISTRICT
SM232635,166.59,-20.6301,Iaai,LANGUAGE,LANGUAGE
SM290022,22.2918,44.9748,Iablanita,ADM2,DISTRICT
SM266567,-49.0592,-21.9075,Iacanga,ADM2,DISTRICT
SM268050,-46.7211,-14.0819,Iaciara,ADM2,DISTRICT
SM291724,24.7546,46.0444,Iacobeni,ADM2,DISTRICT
SM266568,-50.6152,-21.778,Iacri,ADM2,DISTRICT
SM299480,31.8946,50.2572,Iahotyns'kyi,ADM2,DISTRICT
SM370981,46.6346,-23.1535,Iakora,ADM2:ADM3,DISTRICT
SM299837,35.1658,46.7031,Iakymivs'kyi,ADM2,DISTRICT
SM288598,144,-6.1667,Ialibu-Pangia,ADM2,DISTRICT
SM1543,27,44.6667,Ialomița,ADM1,DISTRICT
SM1544,28.8333,46.8333,Ialoveni,ADM1,DISTRICT
SM232638,150.573,-9.45,Iamalele,LANGUAGE,LANGUAGE
SM299791,28.2811,48.2418,Iampil's'kyi,ADM2,DISTRICT
SM292286,27.5527,46.4044,Iana,ADM2,DISTRICT
SM291422,24.1917,43.7747,Ianca,ADM2,DISTRICT
SM292677,27.5021,45.1145,Ianca,ADM2,DISTRICT
SM291424,24.0126,44.5181,Iancu Jianu,ADM2,DISTRICT
SM232642,-53.3269,0.9759,Iapama,LANGUAGE,LANGUAGE
SM290103,23.5275,46.5427,Iara,ADM2,DISTRICT
SM266569,-49.1093,-22.8368,Iaras,ADM2,DISTRICT
SM299464,26.8332,49.191,Iarmolynets'kyi,ADM2,DISTRICT
SM290835,27.5845,47.1618,Iasi,ADM2,DISTRICT
SM461271,37.8592,48.1284,Iasynuvats'ka,ADM2,DISTRICT
SM299935,37.8333,48.1667,Iasynuvats'kyi (excl. Avdiyivs'ka),ADM2,DISTRICT
SM264995,-36.9495,-9.1913,Iati,ADM2,DISTRICT
SM232643,143.316,-4.3006,Iatmul,LANGUAGE,LANGUAGE
SM253969,143.33,-3.75,Iatmul,ETHNICITY,ETHNICITY
SM253969,143.33,-3.75,Iatmul,ETHNICITY,ETHNICITY
SM253969,143,-4,Iatmul,ETHNICITY,ETHNICITY
SM253969,143,-4,Iatmul,ETHNICITY,ETHNICITY
SM232645,137.69,-3.1454,Iau,LANGUAGE,LANGUAGE
SM263486,-42.2373,-19.3509,Iaçu,ADM2,DISTRICT
SM267417,-40.1834,-12.7698,Iaçu,ADM2,DISTRICT
SM1542,27.25,47.25,Iași,ADM1,DISTRICT
SM285175,3.8901,7.4092,IbadanNorth,ADM2,DISTRICT
SM285176,3.9275,7.3692,IbadanNorth-East,ADM2,DISTRICT
SM285177,3.8797,7.3927,IbadanNorth-West,ADM2,DISTRICT
SM285178,3.9036,7.3344,IbadanSouth-East,ADM2,DISTRICT
SM285147,3.8655,7.3507,IbadanSouth-West,ADM2,DISTRICT
SM270566,-75.2642,4.4354,Ibagué,ADM2,DISTRICT
SM264491,-50.3156,-23.8043,Ibaiti,ADM2,DISTRICT
SM284969,6.763,6.7993,Ibaji,ADM2,DISTRICT
SM232649,15.3893,-4.207,Ibali Teke,LANGUAGE,LANGUAGE
SM232651,120.695,16.4263,Ibaloi,LANGUAGE,LANGUAGE
SM253970,120.73,15.98,Ibaloi,ETHNICITY,ETHNICITY
SM253970,121.07,16.62,Ibaloi,ETHNICITY,ETHNICITY
SM232655,112.934,2.0904,Iban,LANGUAGE,LANGUAGE
SM253971,112.19,1.86,Iban,ETHNICITY,ETHNICITY
SM253971,112.19,1.86,Iban,ETHNICITY,ETHNICITY
SM253971,112.19,1.86,Iban,ETHNICITY,ETHNICITY
SM253971,112.19,1.86,Iban,ETHNICITY,ETHNICITY
SM253971,112.3,2,Iban,ETHNICITY,ETHNICITY
SM253971,112.3,2,Iban,ETHNICITY,ETHNICITY
SM253971,112.3,2,Iban,ETHNICITY,ETHNICITY
SM253971,112.3,2,Iban,ETHNICITY,ETHNICITY
SM253971,112,2,Iban,ETHNICITY,ETHNICITY
SM253971,112,2,Iban,ETHNICITY,ETHNICITY
SM253971,112,2,Iban,ETHNICITY,ETHNICITY
SM253971,112,2,Iban,ETHNICITY,ETHNICITY
SM253971,113,2,Iban,ETHNICITY,ETHNICITY
SM253971,113,2,Iban,ETHNICITY,ETHNICITY
SM253971,113,2,Iban,ETHNICITY,ETHNICITY
SM253971,113,2,Iban,ETHNICITY,ETHNICITY
SM232657,121.891,17.4201,Ibanag,LANGUAGE,LANGUAGE
SM299344,30.556,-0.081,Ibanda,ADM1:ADM2,DISTRICT
SM291156,25.0312,46.763,Ibanesti,ADM2,DISTRICT
SM292574,26.4045,48.0444,Ibanesti,ADM2,DISTRICT
SM232652,7.2356,4.4912,Ibani,LANGUAGE,LANGUAGE
SM279045,133.4737,34.6503,Ibara,ADM2,DISTRICT
SM279131,135.55,34.8559,Ibaraki,ADM2,DISTRICT
SM1545,140.3167,36.3,Ibaraki,ADM1:ADMX,DISTRICT
SM265700,-53.1825,-29.4089,Ibarama,ADM2,DISTRICT
SM285148,3.2676,7.4374,Ibarapa Central,ADM2,DISTRICT
SM285149,3.4921,7.608,Ibarapa East,ADM2,DISTRICT
SM285150,3.1592,7.6462,Ibarapa North,ADM2,DISTRICT
SM267934,-38.6657,-4.7505,Ibaretama,ADM2,DISTRICT
SM458075,122.3087,-21.2418,Ibarga,ETHNICITY,ETHNICITY
SM273458,-78.0827,0.3304,Ibarra,ADM2,DISTRICT
SM232661,121.909,18.9398,Ibatan,LANGUAGE,LANGUAGE
SM265365,-35.9096,-8.8969,Ibateguara,ADM2,DISTRICT
SM267985,-41.5546,-20.2746,Ibatiba,ADM2,DISTRICT
SM266570,-48.0358,-21.9509,Ibaté,ADM2,DISTRICT
SM304872,44.1551,14.0336,Ibb,ADM2,DISTRICT
SM1546,44.1667,14,Ibb,ADM1,DISTRICT
SM285008,3.9432,6.4545,Ibeju/Lekki,ADM2,DISTRICT
SM264492,-53.0127,-25.1649,Ibema,ADM2,DISTRICT
SM253972,-63.39,-3.31,Ibenama,ETHNICITY,ETHNICITY
SM285114,8.0726,4.5651,Ibeno,ADM2,DISTRICT
SM301123,-91.8396,29.5135,Iberia,ADM2,DISTRICT
SM232662,0.2124,40.624,Iberian,LANGUAGE,LANGUAGE
SM263487,-43.9432,-21.4514,Ibertioga,ADM2,DISTRICT
SM301124,-91.3494,30.2585,Iberville,ADM2,DISTRICT
SM285115,7.9581,4.9274,Ibesikpo Asutan,ADM2,DISTRICT
SM286246,17.1766,68.7787,Ibestad,ADM2,DISTRICT
SM285254,9.8873,8.3509,Ibi,ADM2,DISTRICT
SM12917,136.4633,35.5915,Ibi-gun,ADM2,DISTRICT
SM9931,-51.8028,-28.1047,Ibiaca (1996-),ADM2,DISTRICT
SM266148,-51.2223,-27.2094,Ibiam,ADM2,DISTRICT
SM267935,-40.9276,-3.9357,Ibiapina,ADM2,DISTRICT
SM264428,-38.3805,-7.4825,Ibiara,ADM2,DISTRICT
SM267418,-42.3021,-14.2029,Ibiassucê,ADM2,DISTRICT
SM263489,-44.7924,-16.81,Ibiaí,ADM2,DISTRICT
SM232664,7.8669,5.1528,Ibibio,LANGUAGE,LANGUAGE
SM250308,10.06,4.73,Ibibio,ETHNICITY,ETHNICITY
SM250308,10.06,4.73,Ibibio,ETHNICITY,ETHNICITY
SM250308,8,5,Ibibio,ETHNICITY,ETHNICITY
SM250308,8,5,Ibibio,ETHNICITY,ETHNICITY
SM267419,-39.5598,-14.8744,Ibicaraí,ADM2,DISTRICT
SM266149,-51.3714,-27.0974,Ibicaré,ADM2,DISTRICT
SM267421,-41.3408,-13.3766,Ibicoara,ADM2,DISTRICT
SM267936,-38.5202,-4.9619,Ibicuitinga,ADM2,DISTRICT
SM267422,-39.8557,-14.6097,Ibicuí,ADM2,DISTRICT
SM232666,6.0838,7.4266,Ibilo,LANGUAGE,LANGUAGE
SM264996,-37.597,-8.5647,Ibimirim,ADM2,DISTRICT
SM232667,8.1525,4.596,Ibino,LANGUAGE,LANGUAGE
SM285086,7.8931,5.207,Ibiono Ibom,ADM2,DISTRICT
SM267423,-42.1908,-11.4978,Ibipeba,ADM2,DISTRICT
SM267424,-42.3712,-12.8866,Ibipitanga,ADM2,DISTRICT
SM264493,-51.031,-23.2276,Ibiporã,ADM2,DISTRICT
SM267425,-40.8416,-12.5727,Ibiquera,ADM2,DISTRICT
SM263490,-44.1328,-15.667,Ibiracatu,ADM2,DISTRICT
SM263492,-47.1427,-20.4014,Ibiraci,ADM2,DISTRICT
SM265702,-51.6271,-28.3873,Ibiraiaras,ADM2,DISTRICT
SM264997,-36.154,-8.6129,Ibirajuba,ADM2,DISTRICT
SM266150,-49.5232,-27.034,Ibirama,ADM2,DISTRICT
SM267426,-39.4082,-14.0589,Ibirapitanga,ADM2,DISTRICT
SM9932,-52.4708,-28.617,Ibirapuita (1996-),ADM2,DISTRICT
SM267427,-39.9737,-17.7557,Ibirapuã,ADM2,DISTRICT
SM266572,-50.0782,-22.8091,Ibirarema,ADM2,DISTRICT
SM267428,-39.6371,-13.9729,Ibirataia,ADM2,DISTRICT
SM267986,-40.4208,-19.8185,Ibiraçu,ADM2,DISTRICT
SM263493,-44.0769,-20.015,Ibirité,ADM2,DISTRICT
SM265704,-53.1812,-28.5834,Ibirubá,ADM2,DISTRICT
SM266571,-49.2188,-21.0888,Ibirá,ADM2,DISTRICT
SM267429,-42.3899,-12.5658,Ibitiara,ADM2,DISTRICT
SM266573,-48.8384,-21.7952,Ibitinga,ADM2,DISTRICT
SM267988,-41.6696,-20.5115,Ibitirama,ADM2,DISTRICT
SM267430,-41.8415,-11.6935,Ibititá,ADM2,DISTRICT
SM263494,-46.4074,-22.0672,Ibitiúra de Minas,ADM2,DISTRICT
SM263495,-44.7755,-21.1636,Ibituruna,ADM2,DISTRICT
SM263488,-46.6173,-19.5437,Ibiá,ADM2,DISTRICT
SM266574,-47.2108,-23.8358,Ibiúna,ADM2,DISTRICT
SM253973,43.79,16.89,Ibn Amir,ETHNICITY,ETHNICITY
SM267432,-43.2529,-11.8862,Ibotirama,ADM2,DISTRICT
SM11571,21.6934,48.1279,Ibranyi Jaras,ADM2,DISTRICT
SM292816,47,55.25,Ibresinskiy rayon,ADM2,DISTRICT
SM273908,30.6228,29.351,Ibshaway,ADM2,DISTRICT
SM232673,127.542,1.5056,Ibu,LANGUAGE,LANGUAGE
SM232672,7.9311,5.4967,Ibuoro,LANGUAGE,LANGUAGE
SM1547,30.5,-1.75,Iburasirazuba,ADM1:ADMX,DISTRICT
SM1548,29.3333,-2.1667,Iburengerazuba,ADM1:ADMX,DISTRICT
SM278525,130.5806,31.2296,Ibusuki,ADM2,DISTRICT
SM286740,-75.6483,-14.3386,Ica,ADM2,DISTRICT
SM1549,-75.4997,-14.3325,Ica,ADM1,DISTRICT
SM267937,-37.4134,-4.7234,Icapuí,ADM2,DISTRICT
SM9933,-49.228,-28.7271,Icara (2013-),ADM2:ADM3,DISTRICT
SM263496,-44.8592,-16.2211,Icaraí de Minas,ADM2,DISTRICT
SM264494,-53.5949,-23.3724,Icaraíma,ADM2,DISTRICT
SM263007,-43.8218,-2.5635,Icatu,ADM2,DISTRICT
SM253974,-17.6,64.76,Icelander,ETHNICITY,ETHNICITY
SM253974,-17.6,64.76,Icelander,ETHNICITY,ETHNICITY
SM253974,-17.6,64.76,Icelander,ETHNICITY,ETHNICITY
SM253974,-20,64,Icelander,ETHNICITY,ETHNICITY
SM253974,-20,64,Icelander,ETHNICITY,ETHNICITY
SM253974,-20,64,Icelander,ETHNICITY,ETHNICITY
SM253974,-21.9333,64.1333,Icelander,ETHNICITY,ETHNICITY
SM253974,-21.9333,64.1333,Icelander,ETHNICITY,ETHNICITY
SM253974,-21.9333,64.1333,Icelander,ETHNICITY,ETHNICITY
SM232678,-19.0212,63.4837,Icelandic,LANGUAGE,LANGUAGE
SM232676,-20.2731,64.1731,Icelandic Sign Language,LANGUAGE,LANGUAGE
SM232679,-22.3,65.78,Icelandic-Basque Pidgin,LANGUAGE,LANGUAGE
SM232681,9.5026,6.4243,Iceve-Maci,LANGUAGE,LANGUAGE
SM293658,45.25,54.6667,Ichalkovskiy rayon,ADM2,DISTRICT
SM250231,10,7,Ichen,ETHNICITY,ETHNICITY
SM280709,127.4369,37.2764,Icheon,ADM2,DISTRICT
SM279401,140.1373,35.4248,Ichihara,ADM2,DISTRICT
SM279347,139.9329,35.7186,Ichikawa,ADM2,DISTRICT
SM279622,138.51,35.532,Ichikawamisato,ADM2,DISTRICT
SM278481,130.2889,31.7328,Ichikikushikino,ADM2,DISTRICT
SM262770,-64.1028,-17.0906,Ichilo,ADM2,DISTRICT
SM278463,141.2792,40.1428,Ichinohe,ADM2,DISTRICT
SM278025,136.7924,35.3104,Ichinomiya/Owari-ichinomiya,ADM2,DISTRICT
SM12918,141.1333,38.9167,Ichinoseki-shi (2010-),ADM2,DISTRICT
SM267433,-39.1872,-11.7034,Ichu,ADM2,DISTRICT
SM289181,126.8719,38.5081,Ichŏn,ADM2,DISTRICT
SM291157,24.2715,46.5366,Iclanzel,ADM2,DISTRICT
SM290104,23.8162,46.9917,Iclod,ADM2,DISTRICT
SM291425,24.7255,44.4068,Icoana,ADM2,DISTRICT
SM260306,13.7355,-9.2419,Icolo e Bengo,ADM2,DISTRICT
SM267989,-40.8657,-20.7476,Iconha,ADM2,DISTRICT
SM270567,-74.5341,4.1305,Icononzo,ADM2,DISTRICT
SM291342,26.9908,46.7989,Icusesti,ADM2,DISTRICT
SM266575,-49.2051,-20.38,Icém,ADM2,DISTRICT
SM267938,-38.7567,-6.3519,Icó,ADM2,DISTRICT
SM300825,-95.5135,42.3869,Ida,ADM2,DISTRICT
SM232684,118.515,5.2906,Ida'an,LANGUAGE,LANGUAGE
SM1550,27.4167,59.25,Ida-Viru,ADM1:ADMX,DISTRICT
SM284970,6.7151,7.0814,Idah,ADM2,DISTRICT
SM300561,-115.4675,45.8442,Idaho,ADM2,DISTRICT
SM1551,-114.2512,44.5002,Idaho,ADM1,DISTRICT
SM232688,34.817,0.2156,Idakho-Isukha-Tiriki,LANGUAGE,LANGUAGE
SM289465,-7.2369,39.9296,Idanha-a-Nova,ADM2,DISTRICT
SM285083,5.209,6.986,Idanre,ADM2,DISTRICT
SM232691,125.832,-8.7626,Idaté,LANGUAGE,LANGUAGE
SM284777,7.1391,5.8061,Ideato South,ADM2,DISTRICT
SM284778,7.1314,5.8854,IdeatoNo,ADM2,DISTRICT
SM291158,24.7809,46.8321,Ideciu De Jos,ADM2,DISTRICT
SM285290,6.9334,6.1285,Idemili North,ADM2,DISTRICT
SM285291,6.9499,6.0477,Idemili South,ADM2,DISTRICT
SM283846,97.3729,48.2055,Ider,ADM2,DISTRICT
SM232692,8.0831,5.2119,Idere,LANGUAGE,LANGUAGE
SM232693,6.2929,7.3463,Idesa,LANGUAGE,LANGUAGE
SM273765,32.8483,25.0074,Idfu,ADM2,DISTRICT
SM232694,142.301,-8.7598,Idi-Taeme,LANGUAGE,LANGUAGE
SM273905,30.2974,31.3066,Idku,ADM2,DISTRICT
SM296735,36.7568,35.9069,Idlib,ADM2,DISTRICT
SM1552,36.6667,35.7667,Idlib,ADM1,DISTRICT
SM285151,3.7119,7.5068,Ido,ADM2,DISTRICT
SM284719,5.1887,7.862,Ido/Osi,ADM2,DISTRICT
SM232695,7.7126,7.1914,Idoma,LANGUAGE,LANGUAGE
SM250309,6.96,6.58,Idoma,ETHNICITY,ETHNICITY
SM250309,6.96,6.58,Idoma,ETHNICITY,ETHNICITY
SM250309,8,7,Idoma,ETHNICITY,ETHNICITY
SM250309,8,7,Idoma,ETHNICITY,ETHNICITY
SM232700,7.7815,10.2175,Idon,LANGUAGE,LANGUAGE
SM296254,14,46,Idrija,ADM1:ADM2,DISTRICT
SM293423,92.5,54.5,Idrinskiy rayon,ADM2,DISTRICT
SM232702,95.6472,29.4473,Idu,LANGUAGE,LANGUAGE
SM276960,77,10,Idukki,ADM2,DISTRICT
SM232704,7.7753,9.1259,Idun,LANGUAGE,LANGUAGE
SM232705,150.24,-9.3436,Iduna,LANGUAGE,LANGUAGE
SM4055,24.2067,56.6094,Iecavas Novads,ADM1,DISTRICT
SM13537,24.2063,56.6099,Iecavas novads,ADM2,DISTRICT
SM290276,25.6333,45.0333,Iedera De Jos,ADM2,DISTRICT
SM299604,31.8515,47.6968,Ielanets'kyi,ADM2,DISTRICT
SM265553,-35.5281,-5.7678,Ielmo Marinho,ADM2,DISTRICT
SM299936,38.246,48.2403,Ienakiievs'ka,ADM2,DISTRICT
SM290577,25.8804,44.2638,Iepuresti,ADM2,DISTRICT
SM266576,-51.0439,-22.6573,Iepê,ADM2,DISTRICT
SM291159,24.2412,46.4535,Iernut,ADM2,DISTRICT
SM291010,24.2338,47.6774,Ieud,ADM2,DISTRICT
SM284990,3.3123,6.6582,Ifako/Ijaye,ADM2,DISTRICT
SM284990,3.3123,6.6582,Ifako/Ijaye,ADM2,DISTRICT
SM284990,3.3028,6.665,Ifako/Ijaye,ADM2,DISTRICT
SM284990,3.3028,6.665,Ifako/Ijaye,ADM2,DISTRICT
SM14933,144.4553,7.249,Ifalik Municipality,ADM2,DISTRICT
SM253979,144.46,7.25,Ifaluk,ETHNICITY,ETHNICITY
SM253979,144.46,7.25,Ifaluk,ETHNICITY,ETHNICITY
SM253979,144.46,7.25,Ifaluk,ETHNICITY,ETHNICITY
SM253979,144.46,7.25,Ifaluk,ETHNICITY,ETHNICITY
SM370984,47.6366,-21.0922,Ifanadiana,ADM2:ADM3,DISTRICT
SM262119,2.7167,6.6667,Ifangni,ADM2,DISTRICT
SM285118,4.6015,7.3653,Ife East,ADM2,DISTRICT
SM250311,3.89,8.27,Ife Ilesha,ETHNICITY,ETHNICITY
SM250311,3.89,8.27,Ife Ilesha,ETHNICITY,ETHNICITY
SM250311,3.89,8.27,Ife Ilesha,ETHNICITY,ETHNICITY
SM250311,4.5,8,Ife Ilesha,ETHNICITY,ETHNICITY
SM250311,4.5,8,Ife Ilesha,ETHNICITY,ETHNICITY
SM250311,4.5,8,Ife Ilesha,ETHNICITY,ETHNICITY
SM250311,5,7,Ife Ilesha,ETHNICITY,ETHNICITY
SM250311,5,7,Ife Ilesha,ETHNICITY,ETHNICITY
SM250311,5,7,Ife Ilesha,ETHNICITY,ETHNICITY
SM285119,4.4475,7.2669,Ife North,ADM2,DISTRICT
SM285120,4.6,7.2,Ife South,ADM2,DISTRICT
SM285121,4.5593,7.4744,IfeCentral,ADM2,DISTRICT
SM285122,4.9889,7.9776,Ifedayo,ADM2,DISTRICT
SM285084,5.0883,7.3632,Ifedore,ADM2,DISTRICT
SM284984,5.0107,8.5597,Ifelodun,ADM2,DISTRICT
SM285123,4.6667,7.9167,Ifelodun,ADM2,DISTRICT
SM15245,9.6231,20.0848,Iferouane,ADM2,DISTRICT
SM269485,-3.9503,7.1151,Iffou,ADM2,DISTRICT
SM232710,169.007,-18.7668,Ifo,LANGUAGE,LANGUAGE
SM285064,3.252,6.782,Ifo,ADM2,DISTRICT
SM250736,7.14,23,Iforas,ETHNICITY,ETHNICITY
SM250736,7.14,23,Iforas,ETHNICITY,ETHNICITY
SM250736,9.02,31.11,Iforas,ETHNICITY,ETHNICITY
SM250736,9.02,31.11,Iforas,ETHNICITY,ETHNICITY
SM250736,2,20,Iforas,ETHNICITY,ETHNICITY
SM250736,2,20,Iforas,ETHNICITY,ETHNICITY
SM281462,-5.25,33.3333,Ifrane,ADM2,DISTRICT
SM1553,121.1667,16.8333,Ifugao,ADM1:ADM2,DISTRICT
SM253980,121.2,17.13,Ifugao,ETHNICITY,ETHNICITY
SM253980,121.2,17.13,Ifugao,ETHNICITY,ETHNICITY
SM253980,121.2,17.13,Ifugao,ETHNICITY,ETHNICITY
SM253980,121.2,17.13,Ifugao,ETHNICITY,ETHNICITY
SM253980,121.208,16.65,Ifugao,ETHNICITY,ETHNICITY
SM253980,121.208,16.65,Ifugao,ETHNICITY,ETHNICITY
SM253980,121.208,16.65,Ifugao,ETHNICITY,ETHNICITY
SM253980,121.208,16.65,Ifugao,ETHNICITY,ETHNICITY
SM253980,121,17,Ifugao,ETHNICITY,ETHNICITY
SM253980,121,17,Ifugao,ETHNICITY,ETHNICITY
SM253980,121,17,Ifugao,ETHNICITY,ETHNICITY
SM253980,121,17,Ifugao,ETHNICITY,ETHNICITY
SM253980,121.17,16.83,Ifugao,ETHNICITY,ETHNICITY
SM253980,121.17,16.83,Ifugao,ETHNICITY,ETHNICITY
SM253980,121.17,16.83,Ifugao,ETHNICITY,ETHNICITY
SM253980,121.17,16.83,Ifugao,ETHNICITY,ETHNICITY
SM232707,1.5849,7.6357,Ifè,LANGUAGE,LANGUAGE
SM296323,14.5144,45.9358,Ig,ADM1:ADM2,DISTRICT
SM278704,136.1301,34.7686,Iga,ADM2,DISTRICT
SM284826,7.504,10.781,Igabi,ADM2,DISTRICT
SM265366,-36.6742,-9.58,Igaci,ADM2,DISTRICT
SM232714,7.1797,7.3433,Igala,LANGUAGE,LANGUAGE
SM251035,7,7,Igala,ETHNICITY,ETHNICITY
SM284971,7.0187,7.0573,Igalamela-Odolu,ADM2,DISTRICT
SM232716,144.841,-4.7094,Igana,LANGUAGE,LANGUAGE
SM4775,33.5333,0.7,Iganga (2010-),ADM1:ADM2,DISTRICT
SM267434,-42.7605,-13.8906,Igaporã,ADM2,DISTRICT
SM253981,7.47,8.23,Igara,ETHNICITY,ETHNICITY
SM264998,-34.9592,-7.7927,Igaracu,ADM2,DISTRICT
SM266579,-47.6902,-20.0826,Igarapava,ADM2,DISTRICT
SM263497,-44.3164,-20.0542,Igarapé,ADM2,DISTRICT
SM263009,-44.8391,-4.6497,Igarapé Grande,ADM2,DISTRICT
SM263008,-45.1098,-3.7251,Igarapé do Meio,ADM2,DISTRICT
SM264171,-47.5137,-1.1416,Igarapé-Açu,ADM2,DISTRICT
SM264172,-49.1477,-2.0771,Igarapé-Miri,ADM2,DISTRICT
SM263498,-44.7211,-19.9601,Igaratinga,ADM2,DISTRICT
SM266580,-46.1465,-23.1299,Igaratá,ADM2,DISTRICT
SM266578,-48.5856,-22.5418,Igaraçu do Tietê,ADM2,DISTRICT
SM240831,7.2344,4.6271,Igbo,LANGUAGE,LANGUAGE
SM250597,7.78,5.8,Igbo,ETHNICITY,ETHNICITY
SM250597,7.78,5.8,Igbo,ETHNICITY,ETHNICITY
SM250597,7.78,5.8,Igbo,ETHNICITY,ETHNICITY
SM250597,7.78,5.8,Igbo,ETHNICITY,ETHNICITY
SM250597,7.95,5.9585,Igbo,ETHNICITY,ETHNICITY
SM250597,7.95,5.9585,Igbo,ETHNICITY,ETHNICITY
SM250597,7.95,5.9585,Igbo,ETHNICITY,ETHNICITY
SM250597,7.95,5.9585,Igbo,ETHNICITY,ETHNICITY
SM250597,7,6,Igbo,ETHNICITY,ETHNICITY
SM250597,7,6,Igbo,ETHNICITY,ETHNICITY
SM250597,7,6,Igbo,ETHNICITY,ETHNICITY
SM250597,7,6,Igbo,ETHNICITY,ETHNICITY
SM250597,7.33,5.5,Igbo,ETHNICITY,ETHNICITY
SM250597,7.33,5.5,Igbo,ETHNICITY,ETHNICITY
SM250597,7.33,5.5,Igbo,ETHNICITY,ETHNICITY
SM250597,7.33,5.5,Igbo,ETHNICITY,ETHNICITY
SM284735,7.4196,6.6815,Igbo-Eti,ADM2,DISTRICT
SM284736,7.45,6.9833,Igbo-eze North,ADM2,DISTRICT
SM284737,7.3667,6.9337,Igbo-eze South,ADM2,DISTRICT
SM232726,8.2736,6.9552,Igede,LANGUAGE,LANGUAGE
SM289801,23.5123,46.1485,Ighiu,ADM2,DISTRICT
SM253983,-57.81,75.56,Igidlorssuit,ETHNICITY,ETHNICITY
SM260982,-69.5833,-29.3333,Iglesia,ADM2,DISTRICT
SM294259,57,55.25,Iglinskiy rayon,ADM2,DISTRICT
SM253984,-84.57,68.82,Iglulikmiut,ETHNICITY,ETHNICITY
SM253984,-84.57,68.82,Iglulikmiut,ETHNICITY,ETHNICITY
SM253984,-84.57,68.82,Iglulikmiut,ETHNICITY,ETHNICITY
SM253984,-82,70,Iglulikmiut,ETHNICITY,ETHNICITY
SM253984,-82,70,Iglulikmiut,ETHNICITY,ETHNICITY
SM253984,-82,70,Iglulikmiut,ETHNICITY,ETHNICITY
SM253984,-81.51,69.45,Iglulikmiut,ETHNICITY,ETHNICITY
SM253984,-81.51,69.45,Iglulikmiut,ETHNICITY,ETHNICITY
SM253984,-81.51,69.45,Iglulikmiut,ETHNICITY,ETHNICITY
SM232730,-65.3993,-15.1188,Ignaciano,LANGUAGE,LANGUAGE
SM282427,-97.631,20.016,Ignacio Allende,ADM2,DISTRICT
SM262771,-62.9869,-17.3708,Ignacio Warnes,ADM2,DISTRICT
SM283237,-107.7327,29.6656,Ignacio Zaragoza,ADM2,DISTRICT
SM282902,-95.9721,18.6618,Ignacio de la Llave,ADM2,DISTRICT
SM14137,26.1667,55.35,Ignalina (1994-),ADM2,DISTRICT
SM290509,22.1823,46.4339,Ignesti,ADM2,DISTRICT
SM16310,15.511,-3.8524,Ignie,ADM2,DISTRICT
SM232732,0.6785,7.255,Igo,LANGUAGE,LANGUAGE
SM253985,121.62,17.76,Igorot,ETHNICITY,ETHNICITY
SM267435,-39.1936,-13.8561,Igrapiúna,ADM2,DISTRICT
SM265367,-36.6172,-10.1666,Igreja Nova,ADM2,DISTRICT
SM265705,-50.7665,-29.5607,Igrejinha,ADM2,DISTRICT
SM265266,-42.2176,-22.843,Iguaba Grande,ADM2,DISTRICT
SM281609,-99.5458,18.3123,Iguala de la Independencia,ADM2,DISTRICT
SM281610,-98.4837,16.7553,Igualapa,ADM2,DISTRICT
SM266581,-47.5047,-24.5547,Iguape,ADM2,DISTRICT
SM264999,-37.4082,-7.8418,Iguaraci,ADM2,DISTRICT
SM264495,-51.8534,-23.2338,Iguaraçu,ADM2,DISTRICT
SM263499,-45.7419,-20.1453,Iguatama,ADM2,DISTRICT
SM263051,-54.5584,-23.426,Iguatemi,ADM2,DISTRICT
SM264496,-53.0869,-24.6878,Iguatu,ADM2,DISTRICT
SM267939,-39.2768,-6.3555,Iguatu,ADM2,DISTRICT
SM260914,-54.3667,-25.8667,Iguazú,ADM2,DISTRICT
SM267436,-40.0617,-14.6182,Iguaí,ADM2,DISTRICT
SM284688,6.2426,6.5278,Igueben,ADM2,DISTRICT
SM299186,33.7037,-4.3514,Igunga,ADM2,DISTRICT
SM232735,8.8171,9.9851,Iguta,LANGUAGE,LANGUAGE
SM232736,6.0816,7.1088,Igwe,LANGUAGE,LANGUAGE
SM232741,132.271,-2.8101,Iha,LANGUAGE,LANGUAGE
SM232742,132.294,-2.935,Iha-based Pidgin,LANGUAGE,LANGUAGE
SM232744,34.6563,-9.1059,Ihanja,DIALECT,LANGUAGE
SM285292,6.8592,5.8468,Ihiala,ADM2,DISTRICT
SM232747,6.0858,7.0092,Ihievbe,LANGUAGE,LANGUAGE
SM284779,7.3653,5.6218,Ihitte/U,ADM2,DISTRICT
SM273790,30.9409,29.0907,Ihnasiya,ADM2,DISTRICT
SM281474,46.2,-22.3,Ihorombe,ADM1:ADM2,DISTRICT
SM370982,45.8234,-22.3435,Ihosy,ADM2:ADM3,DISTRICT
SM262419,23.8167,42.4333,Ihtiman,ADM2,DISTRICT
SM278793,137.8938,35.4229,Iida,ADM2,DISTRICT
SM279269,132.7333,35.0617,Iinan,ADM2,DISTRICT
SM278795,138.3835,36.9125,Iiyama,ADM2,DISTRICT
SM279668,130.661,33.6135,Iizuka,ADM2,DISTRICT
SM232751,6.9861,8.9718,Ija-Zuba,LANGUAGE,LANGUAGE
SM263500,-44.9224,-21.1835,Ijaci,ADM2,DISTRICT
SM280292,40.808,-1.452,Ijara,ADM2,DISTRICT
SM250324,6.58,4.37,Ijaw,ETHNICITY,ETHNICITY
SM250314,3.63,6.67,Ijebu,ETHNICITY,ETHNICITY
SM285065,4.0226,6.8881,Ijebu North-East,ADM2,DISTRICT
SM285066,4.258,6.805,IjebuEast,ADM2,DISTRICT
SM285048,3.9709,6.9955,IjebuNorth,ADM2,DISTRICT
SM285049,3.929,6.8207,IjebuOde,ADM2,DISTRICT
SM284720,5.0569,7.8458,Ijero,ADM2,DISTRICT
SM288592,148.5833,-9.3333,Ijivitari,ADM2,DISTRICT
SM251142,6.63,5.74,Ijo,ETHNICITY,ETHNICITY
SM9934,-53.8743,-28.3533,Ijui (1996-),ADM2,DISTRICT
SM284972,5.9561,7.8488,Ijumu,ADM2,DISTRICT
SM1556,166.958,-0.5214,Ijuw,ADM1,DISTRICT
SM232770,34.2507,3.6141,Ik,LANGUAGE,LANGUAGE
SM253988,32.22,5.15,Ik,ETHNICITY,ETHNICITY
SM232758,6.3106,6.3642,Ika,LANGUAGE,LANGUAGE
SM285087,7.5341,5.0055,Ika,ADM2,DISTRICT
SM250887,6.5,7.32,Ika,ETHNICITY,ETHNICITY
SM284671,6.2896,6.2287,IkaNorth,ADM2,DISTRICT
SM284672,6.1288,6.1708,IkaSouth,ADM2,DISTRICT
SM370980,46.2096,-21.2267,Ikalamavony,ADM2:ADM3,DISTRICT
SM284827,8.2003,11.2476,Ikara,ADM2,DISTRICT
SM232763,143.349,-15.538,Ikaranggal,LANGUAGE,LANGUAGE
SM271705,9.2167,55.9833,Ikast-Brande,ADM2,DISTRICT
SM279635,132.2126,33.4346,Ikata,ADM2,DISTRICT
SM279132,135.4383,34.8344,Ikeda,ADM2,DISTRICT
SM279657,136.3333,35.8667,Ikeda,ADM2,DISTRICT
SM284780,7.1534,5.568,Ikeduru,ADM2,DISTRICT
SM284991,3.3426,6.6186,Ikeja,ADM2,DISTRICT
SM18130,24.2084,-11.2377,Ikelenge,ADM2,DISTRICT
SM285050,3.676,6.919,Ikenne,ADM2,DISTRICT
SM284706,5.2181,7.5021,Ikere,ADM2,DISTRICT
SM283537,100.8796,47.4897,Ikh-Tamir,ADM2,DISTRICT
SM283847,98.7938,48.7144,Ikh-Uul,ADM2,DISTRICT
SM283618,101.4385,49.5092,Ikh-Uul,ADM2,DISTRICT
SM258918,5.937,7.1462,Ikhin-Arokho,LANGUAGE,LANGUAGE
SM283807,110.1664,46.2407,Ikhkhet,ADM2,DISTRICT
SM278888,129.7167,33.7855,Iki,ADM2,DISTRICT
SM293061,44.6667,45.8333,Iki-Burul'skiy rayon,ADM2,DISTRICT
SM232769,34.3589,-1.9915,Ikizu,LANGUAGE,LANGUAGE
SM232779,7.9866,4.562,Iko,LANGUAGE,LANGUAGE
SM232772,143.618,-7.3034,Ikobi,LANGUAGE,LANGUAGE
SM284707,5.5111,7.8935,Ikole,ADM2,DISTRICT
SM285391,8.6167,6.0833,Ikom,ADM2,DISTRICT
SM278929,135.7006,34.6919,Ikoma Town,ADM2,DISTRICT
SM232775,34.5361,-1.9499,Ikoma-Nata,LANGUAGE,LANGUAGE
SM12919,135.7051,34.6209,Ikoma-gun,ADM2,DISTRICT
SM370985,47.4677,-21.9522,Ikongo,ADM2:ADM3,DISTRICT
SM285088,7.794,5.2104,Ikono,ADM2,DISTRICT
SM284992,3.5517,6.6264,Ikorodu,ADM2,DISTRICT
SM232780,5.09,7.06,Ikorom,DIALECT,LANGUAGE
SM285089,7.6244,4.6058,Ikot-Aba,ADM2,DISTRICT
SM285090,7.7037,5.1938,Ikot-Ekp,ADM2,DISTRICT
SM17886,33.1089,4.0783,Ikotos,ADM2:ADM3,DISTRICT
SM236440,0.4307,6.9284,Ikpana,LANGUAGE,LANGUAGE
SM232784,-53.4955,-11.3782,Ikpeng,LANGUAGE,LANGUAGE
SM232782,6.1765,7.0448,Ikpeshi,LANGUAGE,LANGUAGE
SM284689,5.6228,6.1645,Ikpoba-Okha,ADM2,DISTRICT
SM232785,0.8331,7.4478,Ikposo,LANGUAGE,LANGUAGE
SM293851,47.6667,46,Ikryaninskiy rayon,ADM2,DISTRICT
SM280583,127.0093,36.0244,Iksan,ADM2,DISTRICT
SM13538,24.5029,56.833,Ikskile,ADM2,DISTRICT
SM3995,24.5836,56.8603,Ikskiles Novads,ADM1,DISTRICT
SM232787,7.8411,10.2785,Iku-Gora-Ankwa,LANGUAGE,LANGUAGE
SM232788,7.8668,10.1339,Ikulu,LANGUAGE,LANGUAGE
SM232792,34.9083,-9.1371,Ikuna,DIALECT,LANGUAGE
SM299180,34.58,-5.1425,Ikungi,ADM2,DISTRICT
SM232794,6.8665,5.0433,Ikwere,LANGUAGE,LANGUAGE
SM285200,6.8841,5.0268,Ikwerre,ADM2,DISTRICT
SM284684,8.1628,6.0532,Ikwo,ADM2,DISTRICT
SM284644,7.5667,5.4333,Ikwuano,ADM2,DISTRICT
SM283410,14.2417,36.0453,Il-Belt Victoria,ADM1:ADM2,DISTRICT
SM283454,14.5203,35.8728,Il-Fgura,ADM1:ADM2,DISTRICT
SM283411,14.2361,36.0375,Il-Fontana,ADM1:ADM2,DISTRICT
SM283455,14.5042,35.8917,Il-Furjana,ADM1:ADM2,DISTRICT
SM283399,14.4928,35.9056,Il-Gżira,ADM1:ADM2,DISTRICT
SM283456,14.5328,35.8911,Il-Kalkara,ADM1:ADM2,DISTRICT
SM283457,14.4922,35.8764,Il-Marsa,ADM1:ADM2,DISTRICT
SM283437,14.4208,35.9125,Il-Mosta,ADM1:ADM2,DISTRICT
SM283412,14.2256,36.0292,Il-Munxar,ADM1:ADM2,DISTRICT
SM283413,14.3167,36.0375,Il-Qala,ADM1:ADM2,DISTRICT
SM283419,14.4514,35.8319,Il-Qrendi,ADM1:ADM2,DISTRICT
SM232798,26.4555,-15.6081,Ila,LANGUAGE,LANGUAGE
SM285124,4.9,8.0167,Ila,ADM2,DISTRICT
SM250317,27.59,-15.65,Ila,ETHNICITY,ETHNICITY
SM250317,27.59,-15.65,Ila,ETHNICITY,ETHNICITY
SM250317,27.59,-15.65,Ila,ETHNICITY,ETHNICITY
SM250317,25.39,-14.84,Ila,ETHNICITY,ETHNICITY
SM250317,25.39,-14.84,Ila,ETHNICITY,ETHNICITY
SM250317,25.39,-14.84,Ila,ETHNICITY,ETHNICITY
SM250317,27,-15,Ila,ETHNICITY,ETHNICITY
SM250317,27,-15,Ila,ETHNICITY,ETHNICITY
SM250317,27,-15,Ila,ETHNICITY,ETHNICITY
SM250317,27,-16,Ila,ETHNICITY,ETHNICITY
SM250317,27,-16,Ila,ETHNICITY,ETHNICITY
SM250317,27,-16,Ila,ETHNICITY,ETHNICITY
SM285068,4.7679,6.2032,IlajeEseodo,ADM2,DISTRICT
SM299146,39.1625,-6.9181,Ilala,ADM2,DISTRICT
SM375318,87.9204,26.8855,Ilam,ADM2:ADM3,DISTRICT
SM277505,46.25,33.6833,Ilam,ADM2,DISTRICT
SM1557,47,33.1667,Ilam,ADM1,DISTRICT
SM275766,-88.11,15.03,Ilama,ADM2,DISTRICT
SM282903,-98.422,20.7745,Ilamatlán,ADM2,DISTRICT
SM253997,124.77,7.99,Ilanon,ETHNICITY,ETHNICITY
SM293425,96.0833,56,Ilanskiy rayon,ADM2,DISTRICT
SM296166,18.2337,48.9964,Ilava,ADM2,DISTRICT
SM232805,123.442,-8.3864,Ile Ape,LANGUAGE,LANGUAGE
SM3928,55.4661,-4.608,Ile Perseverance I,ADM1,DISTRICT
SM3929,55.4632,-4.6022,Ile Perseverance II,ADM1,DISTRICT
SM285069,4.8875,7.278,IleOluji/Okeigbo,ADM2,DISTRICT
SM289952,26.6899,44.5273,Ileana,ADM2,DISTRICT
SM291612,23.6083,47.345,Ileanda,ADM2,DISTRICT
SM299106,33.3333,-9.4167,Ileje,ADM2,DISTRICT
SM284708,5.2373,7.9584,Ilejemeje,ADM2,DISTRICT
SM299128,33.0318,-2.4478,Ilemela,ADM2,DISTRICT
SM270317,-77.5192,0.98,Iles,ADM2,DISTRICT
SM13539,22.9968,56.553,Iles pagasts,ADM2,DISTRICT
SM285125,4.7618,7.6015,Ilesha East,ADM2,DISTRICT
SM285126,4.7192,7.642,Ilesha West,ADM2,DISTRICT
SM1568,26.1667,44.5833,Ilfov,ADM1,DISTRICT
SM458828,132.9074,-11.0709,Ilgar,ETHNICITY,ETHNICITY
SM298281,33.6154,40.9335,Ilgaz,ADM2,DISTRICT
SM298676,31.9192,38.2894,Ilgın,ADM2,DISTRICT
SM9935,-47.7476,-24.8779,Ilha Comprida,ADM2,DISTRICT
SM265239,-41.8288,-2.8402,Ilha Grande,ADM2,DISTRICT
SM266582,-51.2763,-20.4872,Ilha Solteira,ADM2,DISTRICT
SM267093,-36.5643,-10.4446,Ilha das Flores,ADM2,DISTRICT
SM15056,40.7324,-15.0353,Ilha de Mocambique,ADM2,DISTRICT
SM266583,-45.3232,-23.8484,Ilhabela,ADM2,DISTRICT
SM266153,-48.8865,-26.854,Ilhota,ADM2,DISTRICT
SM267437,-39.218,-14.7594,Ilhéus,ADM2,DISTRICT
SM269355,82.271,43.3121,Ili Kazakh,ADM2,DISTRICT
SM232814,80.4957,44.0089,Ili Turki,LANGUAGE,LANGUAGE
SM232816,125.902,-7.8439,Ili'uun,LANGUAGE,LANGUAGE
SM290764,22.6786,45.9406,Ilia,ADM2,DISTRICT
SM232808,124.766,7.3445,Ilianen Manobo,LANGUAGE,LANGUAGE
SM14300,-98.6772,17.0352,Iliatenco,ADM2,DISTRICT
SM254000,135.96,-22.74,Iliaura,ETHNICITY,ETHNICITY
SM254000,135.96,-22.74,Iliaura,ETHNICITY,ETHNICITY
SM254000,135.96,-22.74,Iliaura,ETHNICITY,ETHNICITY
SM254000,135.17,-22.27,Iliaura,ETHNICITY,ETHNICITY
SM254000,135.17,-22.27,Iliaura,ETHNICITY,ETHNICITY
SM254000,135.17,-22.27,Iliaura,ETHNICITY,ETHNICITY
SM254000,135.5344,-21.9484,Iliaura,ETHNICITY,ETHNICITY
SM254000,135.5344,-21.9484,Iliaura,ETHNICITY,ETHNICITY
SM254000,135.5344,-21.9484,Iliaura,ETHNICITY,ETHNICITY
SM263501,-45.8182,-20.9302,Ilicínea,ADM2,DISTRICT
SM290218,25.7644,45.8005,Ilieni,ADM2,DISTRICT
SM1570,21.6333,42,Ilinden,ADM1,DISTRICT
SM297850,126.8283,-8.7092,Iliomar,ADM2,DISTRICT
SM296289,14.2906,45.5675,Ilirska Bistrica,ADM1:ADM2,DISTRICT
SM294260,54.25,55.5,Ilishevskiy rayon,ADM2,DISTRICT
SM279843,76.75,43.8333,Iliyskiy,ADM2,DISTRICT
SM18051,36.3011,41.2916,Ilkadim,ADM2,DISTRICT
SM274479,-1.5,48.1667,Ille-et-Vilaine,ADM2,DISTRICT
SM285240,5.3612,13.6952,Illela,ADM2,DISTRICT
SM299792,29.2058,49.1061,Illinets'kyi,ADM2,DISTRICT
SM1571,-89.2504,40.0003,Illinois,ADM1,DISTRICT
SM254002,-88.97,41.5,Illinois,ETHNICITY,ETHNICITY
SM1572,8.1667,26.8333,Illizi,ADM1,DISTRICT
SM232818,18,43,Illyrian,LANGUAGE,LANGUAGE
SM254004,99.51,18.29,Ilmeam,ETHNICITY,ETHNICITY
SM286788,-71.1672,-17.4969,Ilo,ADM2,DISTRICT
SM295600,-88.8333,13.8833,Ilobasco,ADM2,DISTRICT
SM254005,121.62,17.76,Ilocano,ETHNICITY,ETHNICITY
SM4194,120.5208,16.9792,Ilocos,ADM1,DISTRICT
SM1573,120.75,18.1667,Ilocos Norte,ADM1:ADM2,DISTRICT
SM1574,120.542,17.1985,Ilocos Sur,ADM1:ADM2,DISTRICT
SM1575,122.6667,11,Iloilo (1992-),ADM1:ADM2,DISTRICT
SM276017,19.3749,45.2219,Ilok,ADM2,DISTRICT
SM232822,120.993,15.9361,Iloko,LANGUAGE,LANGUAGE
SM254006,122.7,11.77,Ilonggo,ETHNICITY,ETHNICITY
SM232823,121.547,16.3785,Ilongot,LANGUAGE,LANGUAGE
SM254007,121.23,16.11,Ilongot,ETHNICITY,ETHNICITY
SM254007,120.45,17.71,Ilongot,ETHNICITY,ETHNICITY
SM295528,-89.107,13.6961,Ilopango,ADM2,DISTRICT
SM284985,4.7283,8.5712,Ilorin East,ADM2,DISTRICT
SM284986,4.6802,8.4416,Ilorin South,ADM2,DISTRICT
SM284987,4.5235,8.4875,IlorinWe,ADM2,DISTRICT
SM291079,22.7375,44.8154,Ilovat,ADM2,DISTRICT
SM291080,22.4923,44.7803,Ilovita,ADM2,DISTRICT
SM294902,44.0833,49.25,Ilovlinskiy rayon,ADM2,DISTRICT
SM274373,35.7477,8.3591,Ilubabor,ADM2,DISTRICT
SM274373,35.7477,8.3591,Ilubabor,ADM2,DISTRICT
SM274373,35.756,8.2753,Ilubabor,ADM2,DISTRICT
SM274373,35.756,8.2753,Ilubabor,ADM2,DISTRICT
SM232829,8.2524,4.7862,Ilue,LANGUAGE,LANGUAGE
SM13540,26.3115,55.9788,Ilukste,ADM2,DISTRICT
SM3977,26.1483,56.0117,Ilukstes Novads,ADM1,DISTRICT
SM292496,24.8963,47.351,Ilva Mare,ADM2,DISTRICT
SM292497,24.6604,47.3076,Ilva Mica,ADM2,DISTRICT
SM232830,39.793,-0.6632,Ilwana,LANGUAGE,LANGUAGE
SM13541,26.6801,57.3849,Ilzenes pagasts,ADM2,DISTRICT
SM13542,27.3779,56.6469,Ilzeskalna pagasts,ADM2,DISTRICT
SM265708,-52.1481,-28.9169,Ilópolis,ADM2,DISTRICT
SM298402,41.1115,39.9403,Ilıca,ADM2,DISTRICT
SM292950,39.8333,56.9167,Il’inskiy rayon,ADM2,DISTRICT
SM279637,132.9979,34.0667,Imabari,ADM2,DISTRICT
SM264429,-37.5573,-7.3469,Imaculada,ADM2,DISTRICT
SM278267,140.0941,42.4464,Imakane,ADM2,DISTRICT
SM254008,-170.75,66.11,Imaklimiut,ETHNICITY,ETHNICITY
SM279146,129.886,33.3041,Imari,ADM2,DISTRICT
SM266154,-48.8232,-28.1962,Imaruí,ADM2,DISTRICT
SM346796,29.1469,61.447,Imatra,ADM2:ADM3,DISTRICT
SM250739,11.58,32.29,Imazighen,ETHNICITY,ETHNICITY
SM12920,140.2734,35.7393,Imba-gun (2010-),ADM2,DISTRICT
SM1576,-78.3237,0.3763,Imbabura,ADM1,DISTRICT
SM232837,-78.3729,0.3178,Imbabura Highland Quichua,LANGUAGE,LANGUAGE
SM264497,-50.7388,-24.4434,Imbaú,ADM2,DISTRICT
SM271818,-70.8667,19.75,Imbert,ADM2,DISTRICT
SM266155,-48.7045,-28.1909,Imbituba,ADM2,DISTRICT
SM264498,-50.6293,-25.2251,Imbituva,ADM2,DISTRICT
SM268912,9.3623,46.8488,Imboden,ADM2,DISTRICT
SM288599,144,-6.1667,Imbonggu,ADM2,DISTRICT
SM232839,144.026,-6.1363,Imbongu,LANGUAGE,LANGUAGE
SM266156,-49.4066,-27.5112,Imbuia,ADM2,DISTRICT
SM265709,-50.1283,-29.9272,Imbé,ADM2,DISTRICT
SM263503,-41.9677,-19.6186,Imbé de Minas,ADM2,DISTRICT
SM285051,2.864,7.6622,Imeko-Afon,ADM2,DISTRICT
SM9740,49.8,40.3333,Imeni Dvadtsati Shesti Bakinskikh Komissarov Rayon,ADM2,DISTRICT
SM11310,44.75,41.7,Imeni Dvadtsati Shesti Komissarov Rayon,ADM2,DISTRICT
SM9722,44.5,40.1833,Imeni Dvadtsati Shesti Komissarov Rayon,ADM2,DISTRICT
SM232842,-16.3473,18.3733,Imeraguen,LANGUAGE,LANGUAGE
SM1577,42.9833,42.1667,Imereti,ADM1,DISTRICT
SM265710,-51.7567,-29.3402,Imigrante,ADM2,DISTRICT
SM279506,137.0878,36.7294,Imizu,ADM2,DISTRICT
SM1578,7.1667,5.5,Imo (1991-),ADM1,DISTRICT
SM232851,141.171,-3.3123,Imonda,LANGUAGE,LANGUAGE
SM275994,17.1667,43.4333,Imotski,ADM2,DISTRICT
SM263011,-47.5382,-5.3867,Imperatriz,ADM2,DISTRICT
SM277966,7.7833,43.9667,Imperia,ADM2,DISTRICT
SM303167,-115.3653,33.0395,Imperial,ADM2,DISTRICT
SM232853,42.185,33.905,Imperial Aramaic (700-300 BCE),LANGUAGE,LANGUAGE
SM227554,17.6298,2.3982,Impfondo,LANGUAGE,LANGUAGE
SM16311,17.9797,1.6169,Impfondo,ADM2,DISTRICT
SM11646,93.9421,24.8166,Imphal East (2016-),ADM2,DISTRICT
SM277072,93.1544,24.6816,Imphal West,ADM2,DISTRICT
SM324121,-116.0716,51.5681,Improvement District No.  9,ADM2:ADM3,DISTRICT
SM324122,-117.5867,52.733,Improvement District No. 12,ADM2:ADM3,DISTRICT
SM323761,-112.8776,53.5995,Improvement District No. 13,ADM2:ADM3,DISTRICT
SM324137,-113.3189,59.0335,Improvement District No. 24,ADM2:ADM3,DISTRICT
SM324111,-119.1386,53.675,Improvement District No. 25,ADM2:ADM3,DISTRICT
SM10120,-110.6088,55.082,Improvement District No. 349,ADM2,DISTRICT
SM10121,-113.8977,49.0869,Improvement District No. 4,ADM2,DISTRICT
SM250285,-14.67,23.94,Imraguen,ETHNICITY,ETHNICITY
SM232855,129.651,-7.9677,Imroing,LANGUAGE,LANGUAGE
SM280584,127.2319,35.6216,Imsil,ADM2,DISTRICT
SM261865,10.8667,47.0833,Imst,ADM2,DISTRICT
SM232857,146.5651,-8.0753,Imunga Trade Language,LANGUAGE,LANGUAGE
SM282576,-110.7922,30.8246,Imuris,ADM2,DISTRICT
SM270318,-77.5,1.0789,Imués,ADM2,DISTRICT
SM271119,-74.6275,20.0685,Imías,ADM2,DISTRICT
SM297577,100.3487,15.0206,In Buri,ADM2,DISTRICT
SM457544,5.726,20.4631,In Guezzam District,ADM2,DISTRICT
SM457545,3,27.5,In Salah District,ADM2,DISTRICT
SM283414,14.2944,36.0486,In-Nadur,ADM1:ADM2,DISTRICT
SM283438,14.4375,35.9333,In-Naxxar,ADM1:ADM2,DISTRICT
SM278800,138.074,35.7908,Ina,ADM2,DISTRICT
SM279161,139.62,35.9978,Ina,ADM2,DISTRICT
SM232859,124.166,12.4264,Inabaknon,LANGUAGE,LANGUAGE
SM278714,136.5079,35.1606,Inabe,ADM2,DISTRICT
SM268051,-49.9016,-18.4989,Inaciolândia,ADM2,DISTRICT
SM278329,135.351,34.9485,Inagawa,ADM2,DISTRICT
SM279438,139.4929,35.6329,Inagi,ADM2,DISTRICT
SM1579,-73.3117,21.0769,Inagua,ADM1,DISTRICT
SM254010,-51.66,-13.02,Inahukwa,ETHNICITY,ETHNICITY
SM265000,-37.7885,-8.7878,Inajá,ADM2,DISTRICT
SM264501,-52.2413,-22.7151,Inajá,ADM2,DISTRICT
SM254011,54.56,30.83,Inanlu,ETHNICITY,ETHNICITY
SM232863,-69.3083,-12.4653,Inaparic,FAMILY,LANGUAGE
SM1580,144.7348,13.2941,Inarajan,ADM1,DISTRICT
SM232865,27.8658,69.4229,Inari Saami,LANGUAGE,LANGUAGE
SM278370,140.3689,35.9456,Inashiki Town,ADM2,DISTRICT
SM12921,140.2857,35.9905,Inashiki-gun,ADM2,DISTRICT
SM278026,136.7798,35.2481,Inazawa,ADM2,DISTRICT
SM1581,126.7002,37.4618,Incheon,ADM1,DISTRICT
SM1582,-15.0833,20,Inchiri,ADM1,DISTRICT
SM263504,-46.285,-22.3404,Inconfidentes,ADM2,DISTRICT
SM4004,24.6508,57.1022,Incukalna Novads,ADM1,DISTRICT
SM13543,24.6571,57.1029,Incukalna pagasts,ADM2,DISTRICT
SM263505,-42.1573,-15.5763,Indaiabira,ADM2,DISTRICT
SM266157,-49.2215,-27.0106,Indaial,ADM2,DISTRICT
SM266584,-47.1822,-23.1025,Indaiatuba,ADM2,DISTRICT
SM302377,-91.5697,35.7416,Independence,ADM2,DISTRICT
SM17432,-60.9788,14.002,Independence City,ADM2,DISTRICT
SM12431,-76.875,17.9778,Independence City,ADM2,DISTRICT
SM303790,-68.7556,10.3347,Independencia,ADM2,DISTRICT
SM303637,-66.6,10.2,Independencia,ADM2,DISTRICT
SM303733,-72.3062,7.8267,Independencia,ADM2,DISTRICT
SM303697,-63.4167,8.4667,Independencia,ADM2,DISTRICT
SM260867,-67.4167,-30.1667,Independencia,ADM2,DISTRICT
SM261083,-60.6667,-26.6667,Independencia,ADM2,DISTRICT
SM1583,-71.6333,18.4,Independencia,ADM1,DISTRICT
SM290167,28.0629,43.9602,Independenta,ADM2,DISTRICT
SM290446,27.7603,45.4747,Independenta,ADM2,DISTRICT
SM289953,27.1605,44.2792,Independenta,ADM2,DISTRICT
SM265711,-54.1978,-27.8933,Independência,ADM2,DISTRICT
SM267940,-40.3231,-5.4719,Independência,ADM2,DISTRICT
SM279941,51.75,48.5833,Inderskiy,ADM2,DISTRICT
SM461889,11.2842,63.8783,Inderøy (2012-),ADM2,DISTRICT
SM236863,91.05,25.35,India Lyngam,LANGUAGE,LANGUAGE
SM300336,-80.5741,27.6964,Indian River,ADM2,DISTRICT
SM232870,79.6787,27.5186,Indian Sign Language,LANGUAGE,LANGUAGE
SM302310,-79.0875,40.6521,Indiana,ADM2,DISTRICT
SM266585,-51.2605,-22.1259,Indiana,ADM2,DISTRICT
SM1584,-86.2503,40.0003,Indiana,ADM1,DISTRICT
SM264502,-52.6415,-23.4798,Indianópolis,ADM2,DISTRICT
SM263506,-47.8856,-18.9722,Indianópolis,ADM2,DISTRICT
SM266586,-50.2442,-19.9599,Indiaporã,ADM2,DISTRICT
SM268052,-49.9683,-17.185,Indiara,ADM2,DISTRICT
SM267094,-37.5284,-11.4815,Indiaroba,ADM2,DISTRICT
SM263166,-58.6296,-15.3148,Indiavaí,ADM2,DISTRICT
SM15792,-66.8722,18.1647,Indiera Alta Barrio,ADM2,DISTRICT
SM15793,-66.9056,18.1671,Indiera Baja Barrio,ADM2,DISTRICT
SM15794,-66.9474,18.161,Indiera Fria Barrio,ADM2,DISTRICT
SM261243,146.6863,-36.2085,Indigo,ADM2,DISTRICT
SM15795,-66.8098,18.0079,Indios Barrio,ADM2,DISTRICT
SM458470,137.4624,-19.3271,Indjilandji,ETHNICITY,ETHNICITY
SM254022,137.7,-17.92,Indjinandi,ETHNICITY,ETHNICITY
SM254022,137.7,-17.92,Indjinandi,ETHNICITY,ETHNICITY
SM254022,135.2557,-16.9806,Indjinandi,ETHNICITY,ETHNICITY
SM254022,135.2557,-16.9806,Indjinandi,ETHNICITY,ETHNICITY
SM232177,77.064,31.784,Indo-Aryan Kinnauri,LANGUAGE,LANGUAGE
SM232878,109.716,-7.3346,Indonesian,LANGUAGE,LANGUAGE
SM232879,122.253,-3.6866,Indonesian Bajau,LANGUAGE,LANGUAGE
SM282033,-100.9454,19.7599,Indoparapeo,ADM2,DISTRICT
SM276983,75.75,22.6667,Indore,ADM2,DISTRICT
SM276558,103.1667,-0.3333,Indragiri Hilir,ADM2,DISTRICT
SM276559,102.3167,-0.55,Indragiri Hulu,ADM2,DISTRICT
SM276758,108.1667,-6.45,Indramayu,ADM2,DISTRICT
SM13544,26.67,56.9225,Indranu pagasts,ADM2,DISTRICT
SM13545,27.5391,55.8694,Indras pagasts,ADM2,DISTRICT
SM274484,1.6667,46.8333,Indre,ADM2,DISTRICT
SM461898,9.9585,63.589,Indre Fosen (2020-),ADM2,DISTRICT
SM15297,11.2164,59.5965,Indre Ostfold,ADM2,DISTRICT
SM274483,0.75,47.25,Indre-et-Loire,ADM2,DISTRICT
SM232895,25.9848,8.8151,Indri,LANGUAGE,LANGUAGE
SM232898,73.0154,35.3389,Indus Kohistani,LANGUAGE,LANGUAGE
SM17433,-60.9739,13.741,Industrial Estate,ADM2,DISTRICT
SM17434,-61.0599,13.7913,Industry,ADM2,DISTRICT
SM281499,-105.0363,25.7678,Indé,ADM2,DISTRICT
SM269478,-3.494,6.7443,Indénié-Djuablin,ADM2,DISTRICT
SM232900,141.892,-3.3802,Inebu One,LANGUAGE,LANGUAGE
SM10738,32.3487,34.964,Ineia,ADM2,DISTRICT
SM232902,-120.08,34.6169,Ineseño,LANGUAGE,LANGUAGE
SM13546,25.8273,57.0088,Inesu pagasts,ADM2,DISTRICT
SM290510,21.8419,46.4271,Ineu,ADM2,DISTRICT
SM292435,22.1082,47.0912,Ineu,ADM2,DISTRICT
SM281425,-9.0333,30.1,Inezgane-Aït Melloul,ADM2,DISTRICT
SM303597,-66.0073,9.2155,Infante,ADM2,DISTRICT
SM8239,-76.5801,1.3683,Inga,ETHNICITY,ETHNICITY
SM232907,-54.2542,-24.6211,Ingain,LANGUAGE,LANGUAGE
SM15246,6.0779,17.7302,Ingall,ADM2,DISTRICT
SM250240,32.19,12.38,Ingassana,ETHNICITY,ETHNICITY
SM250240,32.19,12.38,Ingassana,ETHNICITY,ETHNICITY
SM250240,34,11,Ingassana,ETHNICITY,ETHNICITY
SM250240,34,11,Ingassana,ETHNICITY,ETHNICITY
SM262732,-68.7557,-16.7096,Ingavi,ADM2,DISTRICT
SM284903,8.0514,12.6241,Ingawa,ADM2,DISTRICT
SM265001,-37.4246,-7.7173,Ingazeira,ADM2,DISTRICT
SM263507,-44.9369,-21.4132,Ingaí,ADM2,DISTRICT
SM254026,114.57,-25.46,Inggade,ETHNICITY,ETHNICITY
SM254026,114.57,-25.46,Inggade,ETHNICITY,ETHNICITY
SM254026,114.5358,-24.9456,Inggade,ETHNICITY,ETHNICITY
SM254026,114.5358,-24.9456,Inggade,ETHNICITY,ETHNICITY
SM301277,-84.3735,42.5971,Ingham,ADM2,DISTRICT
SM461527,157.1,-8.1,Ingoana,ETHNICITY,ETHNICITY
SM232910,29.7115,59.474,Ingrian,LANGUAGE,LANGUAGE
SM254027,29,59,Ingrian,ETHNICITY,ETHNICITY
SM254028,-167.47,65.84,Inguklimiut,ETHNICITY,ETHNICITY
SM232911,45.03,43.11,Ingush,LANGUAGE,LANGUAGE
SM1585,45,43.1667,Ingush,ADM1,DISTRICT
SM254029,45.76,42.47,Ingush,ETHNICITY,ETHNICITY
SM254029,45.76,42.47,Ingush,ETHNICITY,ETHNICITY
SM254029,44.58,43.52,Ingush,ETHNICITY,ETHNICITY
SM254029,44.58,43.52,Ingush,ETHNICITY,ETHNICITY
SM264430,-35.629,-7.2544,Ingá,ADM2,DISTRICT
SM265712,-54.0379,-27.9144,Inhacor,ADM2,DISTRICT
SM1586,34.5,-23,Inhambane,ADM1,DISTRICT
SM371823,35.468,-23.8866,Inhambane (Cidade),ADM2:ADM3,DISTRICT
SM267438,-38.3704,-11.7966,Inhambupe,ADM2,DISTRICT
SM264173,-47.9497,-1.4628,Inhangapi,ADM2,DISTRICT
SM265368,-37.6362,-9.2773,Inhapi,ADM2,DISTRICT
SM263508,-42.1095,-19.4835,Inhapim,ADM2,DISTRICT
SM283909,35.0396,-24.4696,Inharrime,ADM2,DISTRICT
SM283910,35.2011,-21.5365,Inhassoro,ADM2,DISTRICT
SM283888,36.8236,-18.0331,Inhassunge,ADM2,DISTRICT
SM263509,-44.4242,-19.4997,Inhaúma,ADM2,DISTRICT
SM265240,-41.6814,-6.6619,Inhuma,ADM2,DISTRICT
SM9936,-49.513,-16.3303,Inhumas (1958-),ADM2:ADM3,DISTRICT
SM285091,7.7488,5.4041,Ini,ADM2,DISTRICT
SM263510,-44.2757,-18.7115,Inimutaba,ADM2,DISTRICT
SM10415,-68.9353,2.9241,Inirida (excl. Morichal),ADM2,DISTRICT
SM280698,128.2788,38.0442,Inje,ADM2,DISTRICT
SM260023,62.3285,34.3169,Injil,ADM2,DISTRICT
SM233195,68.611,36.0018,Inku,LANGUAGE,LANGUAGE
SM225207,123.49,13.1989,Inland-Buhi-Daraga Bikol,LANGUAGE,LANGUAGE
SM232915,120.6539,17.6175,Inlaod Itneg,LANGUAGE,LANGUAGE
SM232926,-77.0196,-12.1448,Inmaculada Sign Language,LANGUAGE,LANGUAGE
SM268913,10.2603,46.7521,Inn,ADM2,DISTRICT
SM255883,111,45,Inner Mongolian,ETHNICITY,ETHNICITY
SM255883,111,45,Inner Mongolian,ETHNICITY,ETHNICITY
SM255883,119,48.5,Inner Mongolian,ETHNICITY,ETHNICITY
SM255883,119,48.5,Inner Mongolian,ETHNICITY,ETHNICITY
SM346769,26.7973,62.7534,Inner Savonia,ADM2:ADM3,DISTRICT
SM295336,155.7723,-7.0359,Inner Shortlands,ADM2,DISTRICT
SM18560,151.1454,-33.89,Inner West,ADM2,DISTRICT
SM323959,-113.9562,52.0291,Innisfail,ADM2:ADM3,DISTRICT
SM323762,-111.5279,53.378,Innisfree,ADM2:ADM3,DISTRICT
SM3927,10.4272,61.4337,Innlandet,ADM1,DISTRICT
SM261867,11.3771,47.2843,Innsbruck,ADM2,DISTRICT
SM261866,11.3333,47.2,Innsbruck Land,ADM2,DISTRICT
SM255892,-67.94,55.38,Innu,ETHNICITY,ETHNICITY
SM255892,-67.94,55.38,Innu,ETHNICITY,ETHNICITY
SM255892,-67.94,55.38,Innu,ETHNICITY,ETHNICITY
SM255892,-67.94,55.38,Innu,ETHNICITY,ETHNICITY
SM255892,-67.94,55.38,Innu,ETHNICITY,ETHNICITY
SM255892,-72.34,53.13,Innu,ETHNICITY,ETHNICITY
SM255892,-72.34,53.13,Innu,ETHNICITY,ETHNICITY
SM255892,-72.34,53.13,Innu,ETHNICITY,ETHNICITY
SM255892,-72.34,53.13,Innu,ETHNICITY,ETHNICITY
SM255892,-72.34,53.13,Innu,ETHNICITY,ETHNICITY
SM255892,-69.84,50.95,Innu,ETHNICITY,ETHNICITY
SM255892,-69.84,50.95,Innu,ETHNICITY,ETHNICITY
SM255892,-69.84,50.95,Innu,ETHNICITY,ETHNICITY
SM255892,-69.84,50.95,Innu,ETHNICITY,ETHNICITY
SM255892,-69.84,50.95,Innu,ETHNICITY,ETHNICITY
SM255892,-61.15,53,Innu,ETHNICITY,ETHNICITY
SM255892,-61.15,53,Innu,ETHNICITY,ETHNICITY
SM255892,-61.15,53,Innu,ETHNICITY,ETHNICITY
SM255892,-61.15,53,Innu,ETHNICITY,ETHNICITY
SM255892,-61.15,53,Innu,ETHNICITY,ETHNICITY
SM255892,-66.82,52.15,Innu,ETHNICITY,ETHNICITY
SM255892,-66.82,52.15,Innu,ETHNICITY,ETHNICITY
SM255892,-66.82,52.15,Innu,ETHNICITY,ETHNICITY
SM255892,-66.82,52.15,Innu,ETHNICITY,ETHNICITY
SM255892,-66.82,52.15,Innu,ETHNICITY,ETHNICITY
SM255892,-75.5,46.56,Innu,ETHNICITY,ETHNICITY
SM255892,-75.5,46.56,Innu,ETHNICITY,ETHNICITY
SM255892,-75.5,46.56,Innu,ETHNICITY,ETHNICITY
SM255892,-75.5,46.56,Innu,ETHNICITY,ETHNICITY
SM255892,-75.5,46.56,Innu,ETHNICITY,ETHNICITY
SM255892,-62.5,55,Innu,ETHNICITY,ETHNICITY
SM255892,-62.5,55,Innu,ETHNICITY,ETHNICITY
SM255892,-62.5,55,Innu,ETHNICITY,ETHNICITY
SM255892,-62.5,55,Innu,ETHNICITY,ETHNICITY
SM255892,-62.5,55,Innu,ETHNICITY,ETHNICITY
SM255892,-72,48,Innu,ETHNICITY,ETHNICITY
SM255892,-72,48,Innu,ETHNICITY,ETHNICITY
SM255892,-72,48,Innu,ETHNICITY,ETHNICITY
SM255892,-72,48,Innu,ETHNICITY,ETHNICITY
SM255892,-72,48,Innu,ETHNICITY,ETHNICITY
SM255892,-63.33,52.42,Innu,ETHNICITY,ETHNICITY
SM255892,-63.33,52.42,Innu,ETHNICITY,ETHNICITY
SM255892,-63.33,52.42,Innu,ETHNICITY,ETHNICITY
SM255892,-63.33,52.42,Innu,ETHNICITY,ETHNICITY
SM255892,-63.33,52.42,Innu,ETHNICITY,ETHNICITY
SM255892,-74,50,Innu,ETHNICITY,ETHNICITY
SM255892,-74,50,Innu,ETHNICITY,ETHNICITY
SM255892,-74,50,Innu,ETHNICITY,ETHNICITY
SM255892,-74,50,Innu,ETHNICITY,ETHNICITY
SM255892,-74,50,Innu,ETHNICITY,ETHNICITY
SM263052,-52.0439,-19.6715,Inocência,ADM2,DISTRICT
SM232932,145.539,-6.3231,Inoke-Yate,LANGUAGE,LANGUAGE
SM232933,122.03,12.284,Inonhan,LANGUAGE,LANGUAGE
SM232934,37.7968,7.9488,Inoric,LANGUAGE,LANGUAGE
SM288866,18.2967,52.7646,Inowrocław,ADM2,DISTRICT
SM232937,93.8096,24.8761,Inpui Naga,LANGUAGE,LANGUAGE
SM254030,24.98,-32.46,Inqua,ETHNICITY,ETHNICITY
SM262733,-67.1565,-16.9579,Inquisivi,ADM2,DISTRICT
SM293659,44.4167,53.8333,Insarskiy rayon,ADM2,DISTRICT
SM305276,29.4739,-20.2643,Insiza,ADM2,DISTRICT
SM292678,27.6443,44.9193,Insuratei,ADM2,DISTRICT
SM276549,136.7084,-3.4102,Intan Jaya,ADM2,DISTRICT
SM17918,7.9887,46.6893,Interlaken-Oberhasli District,ADM2,DISTRICT
SM232943,2.615,48.203,Interlingua (International Auxiliary Language Association),LANGUAGE,LANGUAGE
SM232948,11.4,44.95,International Sign,LANGUAGE,LANGUAGE
SM258906,17.4445,49.0784,Interslavic zonal constructed language,LANGUAGE,LANGUAGE
SM232952,96.9388,20.392,Intha-Danu,LANGUAGE,LANGUAGE
SM275670,-88.17,14.43,Intibucá,ADM2,DISTRICT
SM1587,-88.1667,14.3333,Intibucá,ADM1,DISTRICT
SM295715,-88.0308,13.2012,Intipucá,ADM2,DISTRICT
SM290219,26.0126,45.6854,Intorsura Buzaului,ADM2,DISTRICT
SM289802,23.3921,46.2491,Intregalde,ADM2,DISTRICT
SM232955,-68.5197,63.7486,Inuit Sign Language,LANGUAGE,LANGUAGE
SM12923,136.3334,35.2106,Inukami-gun,ADM2,DISTRICT
SM254031,-132.08,69.67,Inuvialmiut,ETHNICITY,ETHNICITY
SM10203,-133.7141,68.3568,Inuvik Region (2011-),ADM2,DISTRICT
SM278027,136.9807,35.3623,Inuyama,ADM2,DISTRICT
SM286502,168.37,-46.4879,Invercargill,ADM2,DISTRICT
SM274722,-4.75,55.9,Inverclyde,ADM1:ADM2,DISTRICT
SM261558,151.0419,-29.3153,Inverell,ADM2,DISTRICT
SM268823,-61.0818,46.3334,Inverness,ADM2,DISTRICT
SM12432,-77.1903,17.912,Inverness (Clarendon),ADM2,DISTRICT
SM18527,-77.3686,18.2753,Inverness (Saint Ann),ADM2,DISTRICT
SM17435,-61.0359,13.9318,Invorgoil Estate,ADM2,DISTRICT
SM303168,-117.4108,36.5111,Inyo,ADM2,DISTRICT
SM12924,140.141,35.8121,Inzai-shi (2010-),ADM2,DISTRICT
SM269854,-76.1296,2.5092,Inzá,ADM2,DISTRICT
SM264500,-51.22,-25.64,Inácio Martins,ADM2,DISTRICT
SM266587,-50.9474,-21.72,Inúbia Paulista,ADM2,DISTRICT
SM262197,-3.0833,11.0833,Ioba,ADM1:ADM2,DISTRICT
SM232961,146.5869,-8.1039,Ioi Trade Jargon,LANGUAGE,LANGUAGE
SM266158,-51.2605,-26.9859,Iomerê,ADM2,DISTRICT
SM290168,27.8027,44.1072,Ion Corvin,ADM2,DISTRICT
SM291343,27.0036,46.8607,Ion Creanga,ADM2,DISTRICT
SM290917,26.768,44.6676,Ion Roata,ADM2,DISTRICT
SM292197,24.2313,44.8543,Ionesti,ADM2,DISTRICT
SM290633,23.4269,44.6164,Ionesti,ADM2,DISTRICT
SM301278,-85.0746,42.9451,Ionia,ADM2,DISTRICT
SM275154,20.7831,37.793,Ionian Islands,ADM1:ADM2,DISTRICT
SM291536,26.2261,45.0467,Iordacheanu,ADM2,DISTRICT
SM290634,22.9081,44.9162,Iormanesti,ADM2,DISTRICT
SM301279,-83.5139,44.3013,Iosco,ADM2,DISTRICT
SM303238,-90.1354,43.0005,Iowa,ADM2,DISTRICT
SM300826,-92.0655,41.6863,Iowa,ADM2,DISTRICT
SM1588,-93.5005,42.0003,Iowa,ADM1,DISTRICT
SM254033,-92.99,43.78,Iowa,ETHNICITY,ETHNICITY
SM254033,-92.99,43.78,Iowa,ETHNICITY,ETHNICITY
SM254033,-95,41,Iowa,ETHNICITY,ETHNICITY
SM254033,-95,41,Iowa,ETHNICITY,ETHNICITY
SM232967,-95.5213,40.0511,Iowa-Oto,LANGUAGE,LANGUAGE
SM291613,22.6241,47.2214,Ip,ADM2,DISTRICT
SM263511,-42.3607,-19.4056,Ipaba,ADM2,DISTRICT
SM232969,-116.993,33.005,Ipai,LANGUAGE,LANGUAGE
SM254034,-117,33,Ipai,ETHNICITY,ETHNICITY
SM275411,-89.6048,14.5823,Ipala,ADM2,DISTRICT
SM232970,-98.0129,16.5053,Ipalapa Amuzgo,LANGUAGE,LANGUAGE
SM268054,-47.9776,-17.4697,Ipameri,ADM2,DISTRICT
SM263512,-41.768,-19.7572,Ipanema,ADM2,DISTRICT
SM265554,-36.8315,-5.542,Ipanguaçu,ADM2,DISTRICT
SM267941,-40.8103,-4.8474,Ipaporanga,ADM2,DISTRICT
SM290836,27.4376,46.9039,Ipatele,ADM2,DISTRICT
SM263514,-42.606,-19.4387,Ipatinga,ADM2,DISTRICT
SM266589,-49.6037,-23.082,Ipaucu,ADM2,DISTRICT
SM267943,-38.7323,-6.8088,Ipaumirim,ADM2,DISTRICT
SM267439,-39.3284,-12.3378,Ipecaetá,ADM2,DISTRICT
SM254035,-67.18,1.92,Ipeka,ETHNICITY,ETHNICITY
SM254035,-67.18,1.92,Ipeka,ETHNICITY,ETHNICITY
SM254035,-67,5,Ipeka,ETHNICITY,ETHNICITY
SM254035,-67,5,Ipeka,ETHNICITY,ETHNICITY
SM18052,43.3469,38.4904,Ipekyolu,ADM2,DISTRICT
SM266590,-47.6421,-23.3802,Iperó,ADM2,DISTRICT
SM266591,-47.6945,-22.4438,Ipeúna,ADM2,DISTRICT
SM270319,-77.4167,0.5833,Ipiales,ADM2,DISTRICT
SM263515,-49.9175,-18.7068,Ipiaçu,ADM2,DISTRICT
SM267440,-39.7255,-14.0571,Ipiaú,ADM2,DISTRICT
SM266592,-49.3978,-20.6398,Ipiguá,ADM2,DISTRICT
SM232972,144.863,-7.3742,Ipiko,LANGUAGE,LANGUAGE
SM13547,25.1645,58.0314,Ipiku pagasts,ADM2,DISTRICT
SM232973,143.089,-5.4259,Ipili,LANGUAGE,LANGUAGE
SM254036,142.47,-5.85,Ipili,ETHNICITY,ETHNICITY
SM264503,-50.5548,-25.0159,Ipiranga,ADM2,DISTRICT
SM9937,-49.6505,-15.1688,Ipiranga De Goias,ADM2,DISTRICT
SM9938,-56.14,-12.24,Ipiranga do Norte,ADM2,DISTRICT
SM265241,-41.7469,-6.8463,Ipiranga do Piauí,ADM2,DISTRICT
SM265714,-52.4307,-27.9421,Ipiranga do Sul,ADM2,DISTRICT
SM266159,-51.7971,-27.3872,Ipirá,ADM2,DISTRICT
SM267441,-39.9164,-12.2741,Ipirá,ADM2,DISTRICT
SM267323,-71.3759,-7.1191,Ipixuna,ADM2,DISTRICT
SM264174,-48.1195,-2.9815,Ipixuna do Pará,ADM2,DISTRICT
SM265002,-35.0628,-8.447,Ipojuca,ADM2,DISTRICT
SM285052,2.8105,6.618,Ipokia,ADM2,DISTRICT
SM11738,-53.7198,-24.0451,Ipora,ADM2,DISTRICT
SM266593,-48.5525,-24.5044,Iporanga,ADM2,DISTRICT
SM268056,-51.1598,-16.4554,Iporá,ADM2,DISTRICT
SM266160,-53.4902,-26.9916,Iporã do Oeste,ADM2,DISTRICT
SM291808,26.298,47.6283,Ipotesti,ADM2,DISTRICT
SM292575,26.5627,47.7663,Ipotesti,ADM2,DISTRICT
SM268566,21.277,6.6447,Ippy,ADM2,DISTRICT
SM261635,152.6619,-27.6779,Ipswich,ADM2,DISTRICT
SM266161,-52.4826,-26.6708,Ipuaçu,ADM2,DISTRICT
SM265003,-40.243,-7.3929,Ipubi,ADM2,DISTRICT
SM265555,-37.1497,-6.7804,Ipueira,ADM2,DISTRICT
SM267242,-48.3789,-11.1585,Ipueiras,ADM2,DISTRICT
SM267945,-40.8219,-4.6213,Ipueiras,ADM2,DISTRICT
SM263516,-46.1247,-22.0073,Ipuiúna,ADM2,DISTRICT
SM232975,9.6468,6.2918,Ipulo-Olulu,LANGUAGE,LANGUAGE
SM266163,-52.1402,-27.0478,Ipumirim,ADM2,DISTRICT
SM267443,-42.433,-11.7695,Ipupiara,ADM2,DISTRICT
SM254037,-68.76,-8.65,Ipurina,ETHNICITY,ETHNICITY
SM266594,-48.0566,-20.3967,Ipuã,ADM2,DISTRICT
SM265713,-51.2779,-28.7162,Ipê,ADM2,DISTRICT
SM267944,-40.6593,-4.3493,Ipú,ADM2,DISTRICT
SM270085,-75.6735,2.6951,Iquira,ADM2,DISTRICT
SM232978,-73.9705,-3.3123,Iquito,LANGUAGE,LANGUAGE
SM254038,-73.83,-2.93,Iquito,ETHNICITY,ETHNICITY
SM233009,106.564,15.7188,Ir,LANGUAGE,LANGUAGE
SM283439,14.3978,35.8826,Ir-Rabat,ADM1:ADM2,DISTRICT
SM232980,125.1896,24.8311,Irabu-Jima,DIALECT,LANGUAGE
SM266137,-61.6699,2.1789,Iracema,ADM2,DISTRICT
SM267946,-38.3528,-5.8127,Iracema,ADM2,DISTRICT
SM264506,-53.3384,-24.4367,Iracema do Oeste,ADM2,DISTRICT
SM266164,-53.3185,-26.855,Iraceminha,ADM2,DISTRICT
SM266595,-47.5199,-22.616,Iracemápolis,ADM2,DISTRICT
SM293811,43.7497,43,Irafskiy rayon,ADM2,DISTRICT
SM250319,35.43,-3.72,Iragw,ETHNICITY,ETHNICITY
SM250319,35.43,-3.72,Iragw,ETHNICITY,ETHNICITY
SM250319,35.43,-3.72,Iragw,ETHNICITY,ETHNICITY
SM250319,35,-4,Iragw,ETHNICITY,ETHNICITY
SM250319,35,-4,Iragw,ETHNICITY,ETHNICITY
SM250319,35,-4,Iragw,ETHNICITY,ETHNICITY
SM250319,35.5,-4,Iragw,ETHNICITY,ETHNICITY
SM250319,35.5,-4,Iragw,ETHNICITY,ETHNICITY
SM250319,35.5,-4,Iragw,ETHNICITY,ETHNICITY
SM267444,-39.9953,-13.18,Irajuba,ADM2,DISTRICT
SM267445,-40.8283,-13.5463,Iramaia,ADM2,DISTRICT
SM299181,34.3027,-4.4212,Iramba,ADM2,DISTRICT
SM250571,34.62,-4.42,Iramba,ETHNICITY,ETHNICITY
SM254047,52.5021,29.6286,Iran-Zoroastrian,ETHNICITY,ETHNICITY
SM267324,-60.4464,-3.1015,Iranduba,ADM2,DISTRICT
SM266165,-51.9178,-27.0363,Irani,ADM2,DISTRICT
SM254044,52,33,Iranian,ETHNICITY,ETHNICITY
SM254044,52,33,Iranian,ETHNICITY,ETHNICITY
SM254044,52,36,Iranian,ETHNICITY,ETHNICITY
SM254044,52,36,Iranian,ETHNICITY,ETHNICITY
SM232982,118.224,4.3362,Iranun,LANGUAGE,LANGUAGE
SM254046,-60.11,-13.13,Iranxi,ETHNICITY,ETHNICITY
SM281541,-101.3718,20.6759,Irapuato,ADM2,DISTRICT
SM266597,-51.3396,-21.449,Irapuru,ADM2,DISTRICT
SM266596,-49.4025,-21.2501,Irapuã,ADM2,DISTRICT
SM232991,44.3779,33.3087,Iraqi Sign Language,LANGUAGE,LANGUAGE
SM267446,-41.5915,-12.2862,Iraquara,ADM2,DISTRICT
SM232989,35.2939,-4.1995,Iraqw,LANGUAGE,LANGUAGE
SM232992,133.586,-2.9444,Irarutu,LANGUAGE,LANGUAGE
SM267447,-38.7362,-12.049,Irará,ADM2,DISTRICT
SM266166,-52.8996,-26.6411,Irati,ADM2,DISTRICT
SM264507,-50.9005,-25.4968,Irati,ADM2,DISTRICT
SM290511,21.1875,46.2941,Iratosu,ADM2,DISTRICT
SM267947,-39.7886,-3.8978,Irauçuba,ADM2,DISTRICT
SM232993,120.65,13.381,Iraya,LANGUAGE,LANGUAGE
SM254050,119.97,13.5,Iraya,ETHNICITY,ETHNICITY
SM265715,-53.237,-27.2708,Iraí,ADM2,DISTRICT
SM263517,-47.437,-19.0633,Iraí de Minas,ADM2,DISTRICT
SM293427,96,55.4167,Irbeyskiy rayon,ADM2,DISTRICT
SM1589,35.8333,32.5,Irbid,ADM1,DISTRICT
SM267448,-41.8344,-11.3032,Irecê,ADM2,DISTRICT
SM301974,-80.8734,35.8071,Iredell,ADM2,DISTRICT
SM285070,5.0092,6.5348,Irele,ADM2,DISTRICT
SM285152,3.9945,8.9029,Irepo,ADM2,DISTRICT
SM284988,4.9129,8.1465,Irepodun,ADM2,DISTRICT
SM285128,4.4833,7.8333,Irepodun,ADM2,DISTRICT
SM284709,5.2292,7.716,Irepodun/Ifelodun,ADM2,DISTRICT
SM264508,-52.1066,-24.3441,Iretama,ADM2,DISTRICT
SM285129,4.2167,7.4167,Irewole,ADM2,DISTRICT
SM279935,61.5,49,Irgizskiy,ADM2,DISTRICT
SM10298,22.4222,15.1285,Iriba,ADM2,DISTRICT
SM10298,22.4222,15.1285,Iriba,ADM2,DISTRICT
SM10298,22.2497,15.1281,Iriba,ADM2,DISTRICT
SM10298,22.2497,15.1281,Iriba,ADM2,DISTRICT
SM303605,-69.4167,10.0833,Iribarren,ADM2,DISTRICT
SM232997,123.331,13.3896,Iriga Bicolano,LANGUAGE,LANGUAGE
SM232996,8.6525,9.852,Irigwe,LANGUAGE,LANGUAGE
SM282034,-100.4635,19.7076,Irimbo,ADM2,DISTRICT
SM266167,-50.7833,-26.3332,Irineópolis,ADM2,DISTRICT
SM1590,35.55,-7.85,Iringa (2012-),ADM1,DISTRICT
SM299221,34.8816,-7.564,Iringa Rural,ADM2,DISTRICT
SM299222,35.69,-7.77,Iringa Urban,ADM2,DISTRICT
SM295337,156.5432,-7.6765,Iringgila,ADM2,DISTRICT
SM302660,-100.9824,31.3039,Irion,ADM2,DISTRICT
SM275832,-85.2055,15.5908,Iriona,ADM2,DISTRICT
SM261034,-61.3333,-32.6333,Iriondo,ADM2,DISTRICT
SM233000,-7.6151,53.2186,Irish,LANGUAGE,LANGUAGE
SM254053,-7.52,52.59,Irish,ETHNICITY,ETHNICITY
SM254053,-7.52,52.59,Irish,ETHNICITY,ETHNICITY
SM254053,-7.52,52.59,Irish,ETHNICITY,ETHNICITY
SM254053,-7.52,52.59,Irish,ETHNICITY,ETHNICITY
SM254053,-7.52,52.59,Irish,ETHNICITY,ETHNICITY
SM254053,-7.52,52.59,Irish,ETHNICITY,ETHNICITY
SM254053,-7.52,52.59,Irish,ETHNICITY,ETHNICITY
SM254053,-7.52,52.59,Irish,ETHNICITY,ETHNICITY
SM254053,-9,53,Irish,ETHNICITY,ETHNICITY
SM254053,-9,53,Irish,ETHNICITY,ETHNICITY
SM254053,-9,53,Irish,ETHNICITY,ETHNICITY
SM254053,-9,53,Irish,ETHNICITY,ETHNICITY
SM254053,-9,53,Irish,ETHNICITY,ETHNICITY
SM254053,-9,53,Irish,ETHNICITY,ETHNICITY
SM254053,-9,53,Irish,ETHNICITY,ETHNICITY
SM254053,-9,53,Irish,ETHNICITY,ETHNICITY
SM254053,-10,53.5,Irish,ETHNICITY,ETHNICITY
SM254053,-10,53.5,Irish,ETHNICITY,ETHNICITY
SM254053,-10,53.5,Irish,ETHNICITY,ETHNICITY
SM254053,-10,53.5,Irish,ETHNICITY,ETHNICITY
SM254053,-10,53.5,Irish,ETHNICITY,ETHNICITY
SM254053,-10,53.5,Irish,ETHNICITY,ETHNICITY
SM254053,-10,53.5,Irish,ETHNICITY,ETHNICITY
SM254053,-10,53.5,Irish,ETHNICITY,ETHNICITY
SM254053,-9.2,51.84,Irish,ETHNICITY,ETHNICITY
SM254053,-9.2,51.84,Irish,ETHNICITY,ETHNICITY
SM254053,-9.2,51.84,Irish,ETHNICITY,ETHNICITY
SM254053,-9.2,51.84,Irish,ETHNICITY,ETHNICITY
SM254053,-9.2,51.84,Irish,ETHNICITY,ETHNICITY
SM254053,-9.2,51.84,Irish,ETHNICITY,ETHNICITY
SM254053,-9.2,51.84,Irish,ETHNICITY,ETHNICITY
SM254053,-9.2,51.84,Irish,ETHNICITY,ETHNICITY
SM254053,-6.88,53.09,Irish,ETHNICITY,ETHNICITY
SM254053,-6.88,53.09,Irish,ETHNICITY,ETHNICITY
SM254053,-6.88,53.09,Irish,ETHNICITY,ETHNICITY
SM254053,-6.88,53.09,Irish,ETHNICITY,ETHNICITY
SM254053,-6.88,53.09,Irish,ETHNICITY,ETHNICITY
SM254053,-6.88,53.09,Irish,ETHNICITY,ETHNICITY
SM254053,-6.88,53.09,Irish,ETHNICITY,ETHNICITY
SM254053,-6.88,53.09,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,53.72,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,53.72,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,53.72,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,53.72,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,53.72,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,53.72,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,53.72,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,53.72,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,52.36,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,52.36,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,52.36,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,52.36,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,52.36,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,52.36,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,52.36,Irish,ETHNICITY,ETHNICITY
SM254053,-8.49,52.36,Irish,ETHNICITY,ETHNICITY
SM254053,-6.95,54.71,Irish,ETHNICITY,ETHNICITY
SM254053,-6.95,54.71,Irish,ETHNICITY,ETHNICITY
SM254053,-6.95,54.71,Irish,ETHNICITY,ETHNICITY
SM254053,-6.95,54.71,Irish,ETHNICITY,ETHNICITY
SM254053,-6.95,54.71,Irish,ETHNICITY,ETHNICITY
SM254053,-6.95,54.71,Irish,ETHNICITY,ETHNICITY
SM254053,-6.95,54.71,Irish,ETHNICITY,ETHNICITY
SM254053,-6.95,54.71,Irish,ETHNICITY,ETHNICITY
SM232999,-6.3654,53.315,Irish Sign Language,LANGUAGE,LANGUAGE
SM12433,-76.7026,18.0623,Irish Town,ADM2,DISTRICT
SM264175,-47.4215,-1.7705,Irituia,ADM2,DISTRICT
SM1591,106,56,Irkutsk (2008-),ADM1,DISTRICT
SM16917,104.6667,52.3333,Irkutskiy Rayon,ADM2,DISTRICT
SM13548,22.9936,56.8533,Irlavas pagasts,ADM2,DISTRICT
SM323935,-111.2294,52.9132,Irma,ADM2:ADM3,DISTRICT
SM302895,-113.2893,37.8592,Iron,ADM2,DISTRICT
SM303239,-90.2645,46.3171,Iron,ADM2,DISTRICT
SM301280,-88.5305,46.2087,Iron,ADM2,DISTRICT
SM301564,-90.69,37.5043,Iron,ADM2,DISTRICT
SM233004,44.61,42.98,Iron Ossetian,LANGUAGE,LANGUAGE
SM12434,-77.8511,18.5132,Ironshore,ADM2,DISTRICT
SM300623,-87.8243,40.7472,Iroquois,ADM2,DISTRICT
SM254054,-77.15,42.81,Iroquois,ETHNICITY,ETHNICITY
SM254054,-77.15,42.81,Iroquois,ETHNICITY,ETHNICITY
SM254054,-78,43,Iroquois,ETHNICITY,ETHNICITY
SM254054,-78,43,Iroquois,ETHNICITY,ETHNICITY
SM299481,30.241,50.5159,Irpins'ka,ADM2,DISTRICT
SM323906,-113.6019,51.3236,Irricana,ADM2:ADM3,DISTRICT
SM299760,23.0413,48.3126,Irshavs'kyi,ADM2,DISTRICT
SM13549,25.5706,56.7791,Irsu pagasts,ADM2,DISTRICT
SM279990,74.5,53.25,Irtyshskiy,ADM2,DISTRICT
SM233012,79.9521,12.7065,Irula of the Nilgiri,LANGUAGE,LANGUAGE
SM254055,77.87,13.33,Irular,ETHNICITY,ETHNICITY
SM279162,139.3675,35.818,Iruma,ADM2,DISTRICT
SM12925,139.325,35.9297,Iruma-gun,ADM2,DISTRICT
SM15341,-55.0335,-26.1328,Iruna,ADM2,DISTRICT
SM267992,-41.6601,-20.3269,Irupi,ADM2,DISTRICT
SM260945,-64.9167,-22.8,Iruya,ADM2,DISTRICT
SM300514,-83.2764,31.6023,Irwin,ADM2,DISTRICT
SM12435,-77.8772,18.4495,Irwin,ADM2,DISTRICT
SM261389,115.0456,-29.3419,Irwin,ADM2,DISTRICT
SM232983,-57.9806,-12.7178,Irántxe-Münkü,LANGUAGE,LANGUAGE
SM283420,14.4264,35.8486,Is-Siġġiewi,ADM1:ADM2,DISTRICT
SM283440,14.4694,35.9217,Is-Swieqi,ADM1:ADM2,DISTRICT
SM285241,6.338,13.222,Isa,ADM2,DISTRICT
SM12926,130.601,32.0584,Isa Shi,ADM2,DISTRICT
SM261636,148.2367,-22.2435,Isaac,ADM2,DISTRICT
SM1592,159.137,-8.0538,Isabel,ADM1,DISTRICT
SM15796,-65.4428,18.1472,Isabel Segunda,ADM2,DISTRICT
SM1593,122,17,Isabela,ADM1:ADM2,DISTRICT
SM273636,-91.0671,-0.4879,Isabela,ADM2,DISTRICT
SM1594,-67.0244,18.5008,Isabela,ADM1,DISTRICT
SM15797,-67.0229,18.5007,Isabela Barrio-Pueblo,ADM2,DISTRICT
SM301281,-84.8468,43.6406,Isabella,ADM2,DISTRICT
SM233017,145.462,-5.8381,Isabi,LANGUAGE,LANGUAGE
SM292050,28.4045,45.269,Isaccea,ADM2,DISTRICT
SM278889,130.0637,32.8691,Isahaya,ADM2,DISTRICT
SM254056,-2,10,Isala,ETHNICITY,ETHNICITY
SM262036,29.4705,-3.3634,Isale,ADM2,DISTRICT
SM290374,23.7371,44.3939,Isalnita,ADM2,DISTRICT
SM14169,46.8966,-21.281,Isandra,ADM2,DISTRICT
SM301361,-93.2952,45.5615,Isanti,ADM2,DISTRICT
SM233021,34.846,-3.9999,Isanzu,LANGUAGE,LANGUAGE
SM233023,123.332,13.651,Isarog Agta,LANGUAGE,LANGUAGE
SM13190,55.1667,50.55,Isatay Audany,ADM2,DISTRICT
SM279942,50.6667,47.6667,Isatayskiy,ADM2,DISTRICT
SM265242,-41.6429,-7.5452,Isaías Coelho,ADM2,DISTRICT
SM261145,-64.5833,-30.5,Ischilín,ADM2,DISTRICT
SM233024,-74.5193,-7.7524,Isconahua,LANGUAGE,LANGUAGE
SM278715,136.7159,34.4674,Ise,ADM2,DISTRICT
SM284710,5.3945,7.4453,Ise/Orun,ADM2,DISTRICT
SM233025,145.691,-5.2072,Isebe,LANGUAGE,LANGUAGE
SM278528,139.292,35.4101,Isehara,ADM2,DISTRICT
SM233027,5.5862,5.819,Isekiri,LANGUAGE,LANGUAGE
SM233055,34.4136,-2.0199,Isenye,LANGUAGE,LANGUAGE
SM277881,14.25,41.6667,Isernia,ADM2,DISTRICT
SM278119,139.2182,36.3213,Isesaki,ADM2,DISTRICT
SM294820,65.4167,56.5,Isetskiy rayon,ADM2,DISTRICT
SM285153,3.5494,7.874,Iseyin,ADM2,DISTRICT
SM305982,70.5837,40.0933,Isfara,ADM2,DISTRICT
SM284685,7.8358,6.4271,Ishielu,ADM2,DISTRICT
SM12927,124.2011,24.394,Ishigaki-shi,ADM2,DISTRICT
SM254058,124.14,24.46,Ishigakians,ETHNICITY,ETHNICITY
SM278268,141.443,43.466,Ishikari Shi,ADM2,DISTRICT
SM12928,141.5706,43.339,Ishikari-gun,ADM2,DISTRICT
SM1595,136.7705,36.7715,Ishikawa,ADM1:ADMX,DISTRICT
SM12929,140.5211,37.1399,Ishikawa-gun,ADM2,DISTRICT
SM16918,56,53.5,Ishimbayskiy Rayon,ADM2,DISTRICT
SM16919,69.4167,56.1667,Ishimskiy Rayon,ADM2,DISTRICT
SM254059,22.6,-12.83,Ishinde,ETHNICITY,ETHNICITY
SM278732,141.3723,38.4792,Ishinomaki,ADM2,DISTRICT
SM278371,140.2179,36.2278,Ishioka,ADM2,DISTRICT
SM260216,69.3899,36.3673,Ishkamish,ADM2,DISTRICT
SM259983,71.4632,36.8539,Ishkashim,ADM2,DISTRICT
SM233032,71.55,36.67,Ishkashimi,LANGUAGE,LANGUAGE
SM254060,54.76,33.72,Ishkashimi,ETHNICITY,ETHNICITY
SM254060,73.11,35.64,Ishkashimi,ETHNICITY,ETHNICITY
SM305943,72.1091,36.9973,Ishkoshim,ADM2,DISTRICT
SM9535,66.2985,34.074,Ishtarlay,ADM2,DISTRICT
SM303414,66.5,40,Ishtixon,ADM2,DISTRICT
SM271675,12.3057,55.623,Ishøj,ADM2,DISTRICT
SM284722,7.6929,6.7307,Isi-Uzo,ADM2,DISTRICT
SM284645,7.4199,5.4031,Isiala Ngwa North,ADM2,DISTRICT
SM284645,7.4199,5.4031,Isiala Ngwa North,ADM2,DISTRICT
SM284645,7.3906,5.2678,Isiala Ngwa North,ADM2,DISTRICT
SM284645,7.3906,5.2678,Isiala Ngwa North,ADM2,DISTRICT
SM284646,7.3923,5.3271,Isiala Ngwa South,ADM2,DISTRICT
SM284646,7.3923,5.3271,Isiala Ngwa South,ADM2,DISTRICT
SM284646,7.4,5.3624,Isiala Ngwa South,ADM2,DISTRICT
SM284646,7.4,5.3624,Isiala Ngwa South,ADM2,DISTRICT
SM284781,7.2034,5.6677,IsialaMb,ADM2,DISTRICT
SM18333,-54.1332,-32.0313,Isidoro Noblia,ADM2,DISTRICT
SM273434,-80.1593,-1.9631,Isidro Ayora,ADM2,DISTRICT
SM281920,-99.4087,19.5631,Isidro Fabela,ADM2,DISTRICT
SM16920,71.25,55,Isil'kul'skiy Rayon,ADM2,DISTRICT
SM233034,35.4288,-3.4238,Isimjeega-Rootigaanga,LANGUAGE,LANGUAGE
SM284974,5.0536,8.2675,Isin,ADM2,DISTRICT
SM233035,121.12,16.3299,Isinai,LANGUAGE,LANGUAGE
SM254061,121.48,16.37,Isinai,ETHNICITY,ETHNICITY
SM4735,30.8718,-0.8335,Isingiro District,ADM1:ADM2,DISTRICT
SM1596,38.5333,0.9833,Isiolo,ADM1:ADM2,DISTRICT
SM233038,138.595,-1.8878,Isirawa,LANGUAGE,LANGUAGE
SM262337,24.2833,43.5,Iskar,ADM2,DISTRICT
SM18053,36.0897,36.4947,Iskenderun Ilcesi,ADM2,DISTRICT
SM16921,83.25,54.6667,Iskitimskiy Rayon,ADM2,DISTRICT
SM295814,50.5048,10.551,Iskushuban,ADM2,DISTRICT
SM282904,-95.5532,18.0637,Isla,ADM2,DISTRICT
SM282469,-86.899,21.271,Isla Mujeres,ADM2,DISTRICT
SM289545,-56.8961,-25.3008,Isla Pucú,ADM2,DISTRICT
SM289615,-58.3176,-26.9783,Isla Umbú,ADM2,DISTRICT
SM303676,-63.95,10.7833,Isla de Coche,ADM2,DISTRICT
SM15798,-67.8905,18.0905,Isla de Mona e Islote Monito Barrio,ADM2,DISTRICT
SM1598,-82.8167,21.7,Isla de la Juventud,ADM1,DISTRICT
SM303075,-122.6692,48.2082,Island,ADM2,DISTRICT
SM233046,-61.343,15.3648,Island Carib,LANGUAGE,LANGUAGE
SM254062,-63.81,16.11,Island Carib,ETHNICITY,ETHNICITY
SM254062,-63.81,16.11,Island Carib,ETHNICITY,ETHNICITY
SM254062,-63.81,16.11,Island Carib,ETHNICITY,ETHNICITY
SM254062,-58.37,14.7,Island Carib,ETHNICITY,ETHNICITY
SM254062,-58.37,14.7,Island Carib,ETHNICITY,ETHNICITY
SM254062,-58.37,14.7,Island Carib,ETHNICITY,ETHNICITY
SM254062,-61.3335,15.417,Island Carib,ETHNICITY,ETHNICITY
SM254062,-61.3335,15.417,Island Carib,ETHNICITY,ETHNICITY
SM254062,-61.3335,15.417,Island Carib,ETHNICITY,ETHNICITY
SM254062,-61.27,15.41,Island Carib,ETHNICITY,ETHNICITY
SM254062,-61.27,15.41,Island Carib,ETHNICITY,ETHNICITY
SM254062,-61.27,15.41,Island Carib,ETHNICITY,ETHNICITY
SM4222,-63.0072,18.2562,Island Harbour,ADM1,DISTRICT
SM324072,-113.5561,54.8453,Island Lake,ADM2:ADM3,DISTRICT
SM324073,-113.534,54.8259,Island Lake South,ADM2:ADM3,DISTRICT
SM233045,-119.5037,33.2461,Island Takic,LANGUAGE,LANGUAGE
SM1599,113.9604,22.2638,Islands,ADM1,DISTRICT
SM1600,2.9031,39.5888,Islas Baleares,ADM1,DISTRICT
SM1601,-15.5,28,Islas Canarias,ADM1,DISTRICT
SM1602,-86.9329,16.096,Islas de la Bahía,ADM1,DISTRICT
SM9701,-59.2163,-51.9443,Islas del Atlantico Sur,ADM2,DISTRICT
SM261174,-58.6597,-33.724,Islas del Ibicuy,ADM2,DISTRICT
SM286869,-71.8333,-17,Islay,ADM2,DISTRICT
SM291892,24.7438,43.7518,Islaz,ADM2,DISTRICT
SM254063,-4.51,54.24,Isle of Man,ETHNICITY,ETHNICITY
SM303030,-76.7091,36.9067,Isle of Wight,ADM2,DISTRICT
SM274639,-1.3333,50.6667,Isle of Wight,ADM1:ADM2,DISTRICT
SM274640,-6.2943,49.9212,Isles of Scilly,ADM1:ADM2,DISTRICT
SM254064,-106.72,34.9,Isleta,ETHNICITY,ETHNICITY
SM254064,-106.72,34.9,Isleta,ETHNICITY,ETHNICITY
SM254064,-106.72,34.9,Isleta,ETHNICITY,ETHNICITY
SM254064,-107,35,Isleta,ETHNICITY,ETHNICITY
SM254064,-107,35,Isleta,ETHNICITY,ETHNICITY
SM254064,-107,35,Isleta,ETHNICITY,ETHNICITY
SM254064,-106.7,34.8,Isleta,ETHNICITY,ETHNICITY
SM254064,-106.7,34.8,Isleta,ETHNICITY,ETHNICITY
SM254064,-106.7,34.8,Isleta,ETHNICITY,ETHNICITY
SM13550,24.1136,56.3305,Islices pagasts,ADM2,DISTRICT
SM12436,-76.841,18.3216,Islington,ADM2,DISTRICT
SM15799,-66.6444,18.4827,Islote Barrio,ADM2,DISTRICT
SM300209,-56.75,-34.25,Ismael Cortinas,ADM2,DISTRICT
SM273856,32.5395,25.3415,Isna,ADM2,DISTRICT
SM233050,121.16,18.2139,Isnag,LANGUAGE,LANGUAGE
SM254066,121.2,18.04,Isnag,ETHNICITY,ETHNICITY
SM13551,27.8163,56.4847,Isnaudas pagasts,ADM2,DISTRICT
SM270086,-76.2817,1.9763,Isnos,ADM2,DISTRICT
SM254067,139.54,-2.49,Isobei,ETHNICITY,ETHNICITY
SM18131,32.9141,-10.0177,Isoka District (2011-),ADM2,DISTRICT
SM285130,4.1833,7.3,Isokan,ADM2,DISTRICT
SM233051,6.2468,5.5289,Isoko,LANGUAGE,LANGUAGE
SM250321,5.43,7.16,Isoko,ETHNICITY,ETHNICITY
SM250321,5.43,7.16,Isoko,ETHNICITY,ETHNICITY
SM250321,6,5,Isoko,ETHNICITY,ETHNICITY
SM250321,6,5,Isoko,ETHNICITY,ETHNICITY
SM284673,6.2341,5.5249,IsokoNor,ADM2,DISTRICT
SM284650,6.2142,5.3821,IsokoSou,ADM2,DISTRICT
SM1603,31,38,Isparta,ADM1,DISTRICT
SM262357,26.8333,43.7167,Isperih,ADM2,DISTRICT
SM254069,35.09,32.28,Israeli,ETHNICITY,ETHNICITY
SM233054,34.7637,31.5279,Israeli Sign Language,LANGUAGE,LANGUAGE
SM268057,-50.895,-16.3578,Israelândia,ADM2,DISTRICT
SM250588,48,10,Issa,ETHNICITY,ETHNICITY
SM250588,48,10,Issa,ETHNICITY,ETHNICITY
SM250588,43,10,Issa,ETHNICITY,ETHNICITY
SM250588,43,10,Issa,ETHNICITY,ETHNICITY
SM301446,-90.9892,32.7414,Issaquena,ADM2,DISTRICT
SM294029,45,53.8333,Issinskiy rayon,ADM2,DISTRICT
SM260043,69.0235,34.8485,Istalif,ADM2,DISTRICT
SM1604,28.9833,41.0351,Istanbul (1995-),ADM1,DISTRICT
SM305983,69.0145,40.0021,Istaravshan,ADM2,DISTRICT
SM1605,13.9167,45.25,Istarska,ADM1,DISTRICT
SM233057,-95.0681,16.9481,Isthmus Mixe,LANGUAGE,LANGUAGE
SM233062,-94.8444,16.4548,Isthmus Zapotec,LANGUAGE,LANGUAGE
SM233058,-94.7347,17.9401,Isthmus-Cosoleacaque Nahuatl,LANGUAGE,LANGUAGE
SM233060,-94.7547,18.2,Isthmus-Mecayapan Nahuatl,LANGUAGE,LANGUAGE
SM233059,-94.6647,18.2513,Isthmus-Pajapan Nahuatl,LANGUAGE,LANGUAGE
SM10416,-76.8053,4.9877,Istmina (2000-),ADM2,DISTRICT
SM304745,20.4833,42.7667,Istok,ADM2,DISTRICT
SM313222,18.3622,43.7858,Istočna Ilidža,ADM2:ADM3,DISTRICT
SM313198,16.6294,44.4075,Istočni Drvar,ADM2:ADM3,DISTRICT
SM313223,18.4883,43.8572,Istočni Stari Grad,ADM2:ADM3,DISTRICT
SM262565,18.6368,43.8936,Istočno Sarajevo,ADM2,DISTRICT
SM13552,27.9669,56.2436,Istras pagasts,ADM2,DISTRICT
SM290170,28.6816,44.5549,Istria,ADM2,DISTRICT
SM16922,36.9333,55.8833,Istrinskiy Rayon,ADM2,DISTRICT
SM233064,13.64,45.088,Istriot,LANGUAGE,LANGUAGE
SM233065,14.2,45.433,Istro Romanian,LANGUAGE,LANGUAGE
SM284756,7.0565,5.6883,Isu,ADM2,DISTRICT
SM233068,9.3654,3.9676,Isu (Fako Division),LANGUAGE,LANGUAGE
SM233070,9.9658,6.6373,Isu (Menchum Division),LANGUAGE,LANGUAGE
SM284647,7.4645,5.7619,Isuikwua,ADM2,DISTRICT
SM254072,35.12,0.33,Isukha,ETHNICITY,ETHNICITY
SM279351,140.3433,35.2778,Isumi,ADM2,DISTRICT
SM12930,140.2334,35.2344,Isumi-gun,ADM2,DISTRICT
SM291081,22.6704,44.9657,Isverna,ADM2,DISTRICT
SM457946,26.2967,44.1686,Isvoarele comuna,ADM2,DISTRICT
SM274431,5.8333,45.1667,Isère,ADM2,DISTRICT
SM265716,-53.7458,-29.5631,Itaara,ADM2,DISTRICT
SM262073,30.015,-3.6543,Itaba,ADM2,DISTRICT
SM267095,-37.4277,-10.6865,Itabaiana,ADM2,DISTRICT
SM264431,-35.3658,-7.3666,Itabaiana,ADM2,DISTRICT
SM267096,-37.7921,-11.2699,Itabaianinha,ADM2,DISTRICT
SM279439,139.7093,35.7512,Itabashi,ADM2,DISTRICT
SM267449,-39.5733,-16.7163,Itabela,ADM2,DISTRICT
SM267450,-40.2031,-12.451,Itaberaba,ADM2,DISTRICT
SM268058,-49.8386,-16.1122,Itaberaí,ADM2,DISTRICT
SM266598,-49.1433,-23.8982,Itaberá,ADM2,DISTRICT
SM267097,-37.2,-10.1088,Itabi,ADM2,DISTRICT
SM9939,-43.285,-19.5757,Itabira,ADM2,DISTRICT
SM9940,-41.2537,-18.5298,Itabirinha,ADM2,DISTRICT
SM9941,-43.7802,-20.2384,Itabirito,ADM2,DISTRICT
SM265267,-42.8732,-22.756,Itaboraí,ADM2,DISTRICT
SM267451,-39.3257,-14.8497,Itabuna,ADM2,DISTRICT
SM267243,-47.622,-8.5554,Itacajá,ADM2,DISTRICT
SM263521,-44.1267,-15.1948,Itacarambi,ADM2,DISTRICT
SM263523,-43.3077,-16.8865,Itacarambira,ADM2,DISTRICT
SM267452,-39.1252,-14.3695,Itacaré,ADM2,DISTRICT
SM267325,-58.4335,-3.1344,Itacoatiara,ADM2,DISTRICT
SM265005,-38.7192,-8.7695,Itacuruba,ADM2,DISTRICT
SM265719,-55.2657,-28.7854,Itacurubi,ADM2,DISTRICT
SM289546,-56.864,-25.4523,Itacurubí de la Cordillera,ADM2,DISTRICT
SM289650,-56.8196,-24.5824,Itacurubí del Rosario,ADM2,DISTRICT
SM267454,-41.0257,-13.086,Itaeté,ADM2,DISTRICT
SM267456,-40.033,-14.197,Itagi,ADM2,DISTRICT
SM267455,-39.8497,-14.2464,Itagibá,ADM2,DISTRICT
SM267457,-39.8165,-16.1347,Itagimirim,ADM2,DISTRICT
SM264509,-51.9829,-22.661,Itaguajé,ADM2,DISTRICT
SM263525,-44.5461,-20.3787,Itaguara,ADM2,DISTRICT
SM268059,-49.6183,-15.9118,Itaguari,ADM2,DISTRICT
SM268060,-49.6004,-15.7694,Itaguaru,ADM2,DISTRICT
SM267245,-47.6543,-5.8141,Itaguatins,ADM2,DISTRICT
SM267993,-40.8731,-19.71,Itaguaçu,ADM2,DISTRICT
SM267458,-42.2108,-10.6426,Itaguaçu da Bahia,ADM2,DISTRICT
SM265269,-43.8189,-22.8546,Itaguaí,ADM2,DISTRICT
SM270225,-75.6312,6.1787,Itagüí,ADM2,DISTRICT
SM265243,-41.5416,-7.4552,Itainópolis,ADM2,DISTRICT
SM263012,-45.7851,-5.2209,Itaipava do Grajaú,ADM2,DISTRICT
SM264511,-54.3435,-25.1634,Itaipulândia,ADM2,DISTRICT
SM314782,-41.6551,-17.4274,Itaipé,ADM2:ADM3,DISTRICT
SM267949,-38.5443,-4.0304,Itaitinga,ADM2,DISTRICT
SM264176,-56.2318,-5.8602,Itaituba,ADM2,DISTRICT
SM267948,-37.8291,-4.7017,Itaiçaba,ADM2,DISTRICT
SM266168,-49.9046,-26.4761,Itaiópolis,ADM2,DISTRICT
SM9942,-51.2447,-19.1155,Itaja (2001-),ADM2:ADM3,DISTRICT
SM266170,-48.7715,-26.9752,Itajaí,ADM2,DISTRICT
SM266601,-49.0569,-21.3552,Itajobi,ADM2,DISTRICT
SM266602,-48.7831,-21.9288,Itaju,ADM2,DISTRICT
SM267459,-39.716,-15.1551,Itaju do Colônia,ADM2,DISTRICT
SM457954,-45.4214,-22.4205,Itajuba,ADM2,DISTRICT
SM267460,-39.4292,-14.6919,Itajuípe,ADM2,DISTRICT
SM265556,-36.7937,-5.6994,Itajá,ADM2,DISTRICT
SM278372,140.5621,35.961,Itako,ADM2,DISTRICT
SM289667,-55.1527,-24.8142,Itakyry,ADM2,DISTRICT
SM233073,12.6489,43.0464,Italian,LANGUAGE,LANGUAGE
SM254074,14.14,41.74,Italian,ETHNICITY,ETHNICITY
SM254074,14.14,41.74,Italian,ETHNICITY,ETHNICITY
SM254074,14.14,41.74,Italian,ETHNICITY,ETHNICITY
SM254074,14.14,41.74,Italian,ETHNICITY,ETHNICITY
SM254074,11,43,Italian,ETHNICITY,ETHNICITY
SM254074,11,43,Italian,ETHNICITY,ETHNICITY
SM254074,11,43,Italian,ETHNICITY,ETHNICITY
SM254074,11,43,Italian,ETHNICITY,ETHNICITY
SM254074,9.6807,45.6951,Italian,ETHNICITY,ETHNICITY
SM254074,9.6807,45.6951,Italian,ETHNICITY,ETHNICITY
SM254074,9.6807,45.6951,Italian,ETHNICITY,ETHNICITY
SM254074,9.6807,45.6951,Italian,ETHNICITY,ETHNICITY
SM254074,10.5,44.5,Italian,ETHNICITY,ETHNICITY
SM254074,10.5,44.5,Italian,ETHNICITY,ETHNICITY
SM254074,10.5,44.5,Italian,ETHNICITY,ETHNICITY
SM254074,10.5,44.5,Italian,ETHNICITY,ETHNICITY
SM454403,-87.617,41.817,Italian Americans,ETHNICITY,ETHNICITY
SM233072,12.4295,42.7291,Italian Sign Language,LANGUAGE,LANGUAGE
SM265270,-41.6673,-21.384,Italva,ADM2,DISTRICT
SM267461,-39.8127,-16.9452,Itamaraju,ADM2,DISTRICT
SM457953,-42.8941,-17.8543,Itamarandiba,ADM2,DISTRICT
SM267327,-68.0393,-6.7647,Itamarati,ADM2,DISTRICT
SM314792,-42.8339,-21.4188,Itamarati de Minas,ADM2:ADM3,DISTRICT
SM267462,-39.6842,-13.7859,Itamari,ADM2,DISTRICT
SM18579,-41.8889,-18.1851,Itambacuri,ADM2,DISTRICT
SM264512,-50.4272,-22.9839,Itambaraca,ADM2,DISTRICT
SM265007,-34.8473,-7.7467,Itambaracá,ADM2,DISTRICT
SM233080,35.3265,-9.3607,Itambo,DIALECT,LANGUAGE
SM264513,-52.0123,-23.6881,Itambé,ADM2,DISTRICT
SM267463,-40.4951,-15.2248,Itambé,ADM2,DISTRICT
SM263531,-43.341,-19.4032,Itambé do Mato Dentro,ADM2,DISTRICT
SM278331,135.4056,34.7868,Itami,ADM2,DISTRICT
SM263532,-47.0506,-21.0861,Itamogi,ADM2,DISTRICT
SM263533,-44.7527,-22.2873,Itamonte,ADM2,DISTRICT
SM267465,-38.1133,-12.3098,Itanagra,ADM2,DISTRICT
SM289710,-55.6576,-23.7589,Itanara,ADM2,DISTRICT
SM314798,-44.9131,-22.3247,Itanhandu,ADM2:ADM3,DISTRICT
SM9943,-56.68,-12.17,Itanhanga,ADM2,DISTRICT
SM266603,-46.8456,-24.0589,Itanhaém,ADM2,DISTRICT
SM18577,-41.8333,-19.1548,Itanhomi,ADM2,DISTRICT
SM267466,-40.361,-17.0811,Itanhém,ADM2,DISTRICT
SM12931,134.4694,34.14,Itano-gun,ADM2,DISTRICT
SM263537,-41.5349,-16.5633,Itaobim,ADM2,DISTRICT
SM265271,-42.0749,-21.7701,Itaocara,ADM2,DISTRICT
SM268062,-49.7023,-14.9209,Itapaci,ADM2,DISTRICT
SM314806,-49.4285,-19.7509,Itapagipe,ADM2:ADM3,DISTRICT
SM267950,-39.5651,-3.7334,Itapajé,ADM2,DISTRICT
SM267467,-38.6638,-12.906,Itaparica,ADM2,DISTRICT
SM267468,-39.6879,-15.8875,Itapebi,ADM2,DISTRICT
SM18576,-45.0881,-20.454,Itapecerica,ADM2,DISTRICT
SM266605,-46.8555,-23.7423,Itapecerica da Serra,ADM2,DISTRICT
SM263013,-44.31,-3.4194,Itapecuru Mirim,ADM2,DISTRICT
SM264514,-52.8258,-25.978,Itapejara d'Oeste,ADM2,DISTRICT
SM266171,-48.6244,-27.1072,Itapema,ADM2,DISTRICT
SM267994,-40.9669,-20.9838,Itapemirim,ADM2,DISTRICT
SM265272,-41.9293,-21.2151,Itaperuna,ADM2,DISTRICT
SM264515,-49.4926,-25.121,Itaperuçu,ADM2,DISTRICT
SM265008,-37.1311,-7.3964,Itapetim,ADM2,DISTRICT
SM267470,-40.0863,-15.3341,Itapetinga,ADM2,DISTRICT
SM266606,-48.1257,-23.6461,Itapetininga,ADM2,DISTRICT
SM266607,-48.8384,-23.947,Itapeva,ADM2,DISTRICT
SM314811,-46.2129,-22.6983,Itapeva,ADM2:ADM3,DISTRICT
SM266608,-46.9774,-23.5582,Itapevi,ADM2,DISTRICT
SM267471,-38.1499,-11.1811,Itapicuru,ADM2,DISTRICT
SM267951,-39.5622,-3.3728,Itapipoca,ADM2,DISTRICT
SM266609,-46.773,-22.4212,Itapira,ADM2,DISTRICT
SM9944,-58.5573,-2.516,"Itapiranga, Amazonas",ADM2,DISTRICT
SM9945,-53.7216,-27.1006,"Itapiranga, Santa Catarina",ADM2,DISTRICT
SM268063,-50.8002,-15.6739,Itapirapuã,ADM2,DISTRICT
SM266611,-49.2244,-24.5599,Itapirapuã Paulista,ADM2,DISTRICT
SM267246,-47.997,-8.3582,Itapiratins,ADM2,DISTRICT
SM265009,-34.9049,-7.7235,Itapissuma,ADM2,DISTRICT
SM267472,-39.6257,-14.4637,Itapitanga,ADM2,DISTRICT
SM267952,-38.9313,-4.6192,Itapiúna,ADM2,DISTRICT
SM266613,-49.4744,-23.662,Itaporanga,ADM2,DISTRICT
SM264433,-38.2516,-7.3004,Itaporanga,ADM2,DISTRICT
SM267099,-37.333,-11.0709,Itaporanga dajuda,ADM2,DISTRICT
SM264434,-35.2448,-6.8115,Itapororoca,ADM2,DISTRICT
SM263053,-54.8141,-21.9218,Itaporã,ADM2,DISTRICT
SM267247,-48.7521,-8.5018,Itaporã do Tocantins,ADM2,DISTRICT
SM266174,-48.6687,-26.0455,Itapoá,ADM2,DISTRICT
SM15342,-55.5453,-26.5614,Itapua Poty,ADM2,DISTRICT
SM265720,-52.2001,-28.7718,Itapuca,ADM2,DISTRICT
SM266615,-51.4254,-20.5963,Itapura,ADM2,DISTRICT
SM268064,-49.9348,-15.5694,Itapuranga,ADM2,DISTRICT
SM266086,-63.2322,-9.0339,Itapuã do Oeste,ADM2,DISTRICT
SM266614,-48.6974,-22.2504,Itapuí,ADM2,DISTRICT
SM289561,-56.5668,-25.8545,Itapé,ADM2,DISTRICT
SM267469,-39.4864,-14.9185,Itapé,ADM2,DISTRICT
SM1606,-55.6667,-26.8333,Itapúa,ADM1,DISTRICT
SM266616,-46.3292,-23.4581,Itaquaquecetuba,ADM2,DISTRICT
SM267473,-39.9103,-13.4472,Itaquara,ADM2,DISTRICT
SM265721,-56.0643,-29.1705,Itaqui,ADM2,DISTRICT
SM263055,-54.1928,-23.4708,Itaquiraí,ADM2,DISTRICT
SM265010,-35.0585,-7.6722,Itaquitinga,ADM2,DISTRICT
SM267995,-40.893,-19.9604,Itarana,ADM2,DISTRICT
SM267474,-40.0589,-15.6518,Itarantim,ADM2,DISTRICT
SM266617,-49.3261,-24.0777,Itararé,ADM2,DISTRICT
SM266618,-47.1332,-24.2888,Itariri,ADM2,DISTRICT
SM268065,-51.3202,-18.8799,Itaruma,ADM2,DISTRICT
SM267954,-39.897,-3.0834,Itarumã,ADM2,DISTRICT
SM285311,10.0486,11.8938,Itas/Gad,ADM2,DISTRICT
SM301362,-93.632,47.5095,Itasca,ADM2,DISTRICT
SM324001,-114.0782,53.0709,Itaska Beach,ADM2:ADM3,DISTRICT
SM281467,46.95,-18.95,Itasy,ADM1:ADM2,DISTRICT
SM269080,-72.5021,-36.3314,Itata,ADM2,DISTRICT
SM10033,-50.1778,-29.4284,Itati Municipality,ADM2,DISTRICT
SM265273,-44.5762,-22.411,Itatiaia,ADM2,DISTRICT
SM9877,-44.4659,-20.2216,Itatiaiuçu,ADM2,DISTRICT
SM266619,-46.7981,-23.0025,Itatiba,ADM2,DISTRICT
SM265722,-52.4907,-27.363,Itatiba do Sul,ADM2,DISTRICT
SM267477,-39.6823,-12.6284,Itatim,ADM2,DISTRICT
SM266620,-48.6234,-23.1257,Itatinga,ADM2,DISTRICT
SM267955,-39.5782,-4.5678,Itatira,ADM2,DISTRICT
SM264435,-35.5837,-7.3923,Itatuba,ADM2,DISTRICT
SM261150,-58,-27.3333,Itatí,ADM2,DISTRICT
SM9947,-55.5885,-11.0954,Itauba (2000-),ADM2:ADM3,DISTRICT
SM267299,-50.6783,0.5773,Itaubal,ADM2,DISTRICT
SM265244,-43.0987,-7.5524,Itaueira,ADM2,DISTRICT
SM289731,-57.3533,-25.3936,Itauguá,ADM2,DISTRICT
SM268067,-49.6007,-16.2143,Itauçu,ADM2,DISTRICT
SM263543,-43.5928,-20.7081,Itaverava,ADM2,DISTRICT
SM301447,-88.3613,34.28,Itawamba,ADM2,DISTRICT
SM233083,121.58,17.7329,Itawit,LANGUAGE,LANGUAGE
SM266600,-49.0757,-23.523,Itaí,ADM2,DISTRICT
SM265006,-37.2955,-8.989,Itaíba,ADM2,DISTRICT
SM266604,-48.8439,-24.6116,Itaóca,ADM2,DISTRICT
SM265557,-37.9249,-5.8419,Itaú,ADM2,DISTRICT
SM314768,-46.7793,-20.7179,Itaú de Minas,ADM2:ADM3,DISTRICT
SM263542,-44.5863,-20.0744,Itaúna,ADM2,DISTRICT
SM264516,-52.8924,-22.7368,Itaúna do Sul,ADM2,DISTRICT
SM233113,121.85,20.77,Itbayat,LANGUAGE,LANGUAGE
SM254075,161.79,54.98,Itelman,ETHNICITY,ETHNICITY
SM254075,161.79,54.98,Itelman,ETHNICITY,ETHNICITY
SM254075,156.9,57.2,Itelman,ETHNICITY,ETHNICITY
SM254075,156.9,57.2,Itelman,ETHNICITY,ETHNICITY
SM233087,-64.9841,-12.2994,Itene,LANGUAGE,LANGUAGE
SM233090,141.585,-4.12,Iteri,LANGUAGE,LANGUAGE
SM285154,3.5303,8.2072,Itesiwaju,ADM2,DISTRICT
SM305213,26.0415,-15.7409,Itezhi-Tezhi,ADM2,DISTRICT
SM233091,139.341,-2.1834,Itik,LANGUAGE,LANGUAGE
SM299175,34.3953,-2.8215,Itilima,ADM2,DISTRICT
SM459243,142.6539,-12.1263,Itinadyana,ETHNICITY,ETHNICITY
SM263544,-41.8318,-16.6072,Itinga,ADM2,DISTRICT
SM263014,-47.4886,-4.5283,Itinga do Maranhão,ADM2,DISTRICT
SM233092,35.2152,-9.3499,Itipula,DIALECT,LANGUAGE
SM263168,-54.7699,-17.3944,Itiquira,ADM2,DISTRICT
SM266622,-47.8345,-22.3045,Itirapina,ADM2,DISTRICT
SM266623,-47.1686,-20.6576,Itirapuã,ADM2,DISTRICT
SM267478,-40.1641,-13.5209,Itiruçu,ADM2,DISTRICT
SM254076,-74.8,60.09,Itivimiut,ETHNICITY,ETHNICITY
SM254076,-76.24,58.39,Itivimiut,ETHNICITY,ETHNICITY
SM267479,-39.8038,-10.7936,Itiúba,ADM2,DISTRICT
SM233099,8.0463,5.1444,Ito,LANGUAGE,LANGUAGE
SM12932,135.5615,34.2316,Ito-gun,ADM2,DISTRICT
SM266624,-46.9305,-21.749,Itobi,ADM2,DISTRICT
SM278957,137.894,36.9663,Itoigawa,ADM2,DISTRICT
SM279051,127.6871,26.118,Itoman,ADM2,DISTRICT
SM233097,-64.3575,-12.8043,Itonama,LANGUAGE,LANGUAGE
SM254077,-65.14,-12.94,Itonama,ETHNICITY,ETHNICITY
SM267480,-40.0194,-15.0672,Itororó,ADM2,DISTRICT
SM12933,130.1798,33.5332,Itoshima-shi,ADM2,DISTRICT
SM10299,22.3866,16.5406,Itou,ADM2,DISTRICT
SM273909,30.7284,29.1823,Itsa,ADM2,DISTRICT
SM250320,5.01,6.43,Itsekiri,ETHNICITY,ETHNICITY
SM250320,5.01,6.43,Itsekiri,ETHNICITY,ETHNICITY
SM250320,5.83,5.99,Itsekiri,ETHNICITY,ETHNICITY
SM250320,5.83,5.99,Itsekiri,ETHNICITY,ETHNICITY
SM250320,5,6,Itsekiri,ETHNICITY,ETHNICITY
SM250320,5,6,Itsekiri,ETHNICITY,ETHNICITY
SM250079,39.25,7.73,Ittu,ETHNICITY,ETHNICITY
SM285092,7.9779,5.1432,Itu,ADM2,DISTRICT
SM266625,-47.2757,-23.3005,Itu,ADM2,DISTRICT
SM233104,7.8038,5.5268,Itu Mbon Uzo,LANGUAGE,LANGUAGE
SM270226,-75.9186,7.3017,Ituango,ADM2,DISTRICT
SM261199,-170.7125,-14.3027,Ituau,ADM2,DISTRICT
SM267481,-41.3969,-13.9036,Ituaçu,ADM2,DISTRICT
SM267482,-39.1537,-13.7495,Ituberá,ADM2,DISTRICT
SM263545,-41.0976,-19.3778,Itueta,ADM2,DISTRICT
SM263547,-49.5504,-19.0151,Ituiutaba,ADM2,DISTRICT
SM292735,45.5725,42.7361,Itum-Kalinskiy rayon,ADM2,DISTRICT
SM268068,-49.4418,-18.3295,Itumbiara,ADM2,DISTRICT
SM263548,-44.8332,-21.2847,Itumirim,ADM2,DISTRICT
SM233107,-97.5975,16.7788,Itundujia Mixtec,LANGUAGE,LANGUAGE
SM266626,-47.0636,-23.1452,Itupeva,ADM2,DISTRICT
SM264177,-49.9495,-5.233,Itupiranga,ADM2,DISTRICT
SM266175,-49.4913,-27.4827,Ituporanga,ADM2,DISTRICT
SM263549,-50.3822,-19.6946,Iturama,ADM2,DISTRICT
SM289562,-56.471,-26.0723,Iturbe,ADM2,DISTRICT
SM282170,-99.8535,24.6316,Iturbide,ADM2,DISTRICT
SM1607,30.25,1.5667,Ituri,ADM1:ADM2,DISTRICT
SM232862,144.9,-4.6761,Itutang-Inapang,LANGUAGE,LANGUAGE
SM263550,-44.7239,-21.3482,Itutinga,ADM2,DISTRICT
SM266627,-47.8259,-20.3001,Ituverava,ADM2,DISTRICT
SM261151,-56.866,-27.9009,Ituzaingó,ADM2,DISTRICT
SM273890,30.6667,30.8667,Ityay al-Barud,ADM2,DISTRICT
SM233110,-89.8734,17.0269,Itzá,LANGUAGE,LANGUAGE
SM289730,-57.3744,-25.5297,Itá,ADM2,DISTRICT
SM266169,-52.3321,-27.2441,Itá,ADM2,DISTRICT
SM266612,-48.8302,-21.5468,Itápolis,ADM2,DISTRICT
SM279274,139.0873,34.9356,Itō,ADM2,DISTRICT
SM233112,101.17,20.83,Iu Mien,LANGUAGE,LANGUAGE
SM267483,-43.6487,-14.6502,Iuiú,ADM2,DISTRICT
SM16923,-179,66.6667,Iul'tinskiy Rayon,ADM2,DISTRICT
SM299929,36.0161,48.7415,Iurïvs'kyi,ADM2,DISTRICT
SM264518,-51.6328,-24.2823,Ivaiporã,ADM2,DISTRICT
SM262580,25.5833,52.1667,Ivanaw,ADM2,DISTRICT
SM296301,14.8072,45.9122,Ivancna Gorica,ADM1:ADM2,DISTRICT
SM13553,21.7836,56.9701,Ivandes pagasts,ADM2,DISTRICT
SM276013,16.1333,46.2333,Ivanec,ADM2,DISTRICT
SM292287,27.4447,46.6622,Ivanesti,ADM2,DISTRICT
SM299608,30.4692,46.9756,Ivanivs'kyi,ADM2,DISTRICT
SM300039,34.5486,46.7136,Ivanivs'kyi,ADM2,DISTRICT
SM276037,16.4,45.7,Ivanić-Grad,ADM2,DISTRICT
SM305537,18.6841,45.2885,Ivankovo,ADM2,DISTRICT
SM1608,24.7137,48.9232,Ivano-Frankivs'k,ADM1,DISTRICT
SM299987,24.7132,48.9224,Ivano-Frankivs'ka,ADM2,DISTRICT
SM18219,24.8016,48.922,Ivano-Frankivskyi Raion (2020-),ADM2,DISTRICT
SM292951,41,57,Ivanovo,ADM2,DISTRICT
SM262367,25.9833,43.7,Ivanovo,ADM2,DISTRICT
SM1609,41.75,57,Ivanovo,ADM1,DISTRICT
SM292952,41,57,Ivanovskiy rayon,ADM2,DISTRICT
SM293236,127.9978,50.4561,Ivanovskiy rayon,ADM2,DISTRICT
SM305323,16.8068,45.7817,Ivanska,ADM2,DISTRICT
SM457971,-53.4241,-23.3372,Ivate,ADM2,DISTRICT
SM262581,25.5,52.6667,Ivatsevichy,ADM2,DISTRICT
SM316502,-52.1943,-23.5972,Ivatuba,ADM2:ADM3,DISTRICT
SM262520,26,41.4833,Ivaylovgrad,ADM2,DISTRICT
SM264517,-50.8619,-24.9938,Ivaí,ADM2,DISTRICT
SM233114,6.2121,7.1531,Ivbie North-Okpela-Arhe,LANGUAGE,LANGUAGE
SM286301,7.9182,58.4574,Iveland,ADM2,DISTRICT
SM13554,22.5205,57.4365,Ives pagasts,ADM2,DISTRICT
SM274569,13.0339,0.6654,Ivindo,ADM2,DISTRICT
SM263056,-53.7557,-22.3808,Ivinhema,ADM2,DISTRICT
SM294675,36.25,51,Ivnyanskiy rayon,ADM2,DISTRICT
SM284686,7.6233,5.8843,Ivo,ADM2,DISTRICT
SM370983,46.916,-22.5208,Ivohibe,ADM2:ADM3,DISTRICT
SM16924,107.3333,51.8333,Ivolginskiy Rayon,ADM2,DISTRICT
SM268069,-50.9266,-16.6883,Ivolândia,ADM2,DISTRICT
SM254078,145.26,-7.31,Ivori,ETHNICITY,ETHNICITY
SM265723,-53.5682,-29.5074,Ivorá,ADM2,DISTRICT
SM265724,-51.1727,-29.5712,Ivoti,ADM2,DISTRICT
SM279540,135.3056,34.2809,Iwade,ADM2,DISTRICT
SM12934,139.5909,38.0823,Iwafune-gun,ADM2,DISTRICT
SM233121,132.453,-11.3379,Iwaidja,LANGUAGE,LANGUAGE
SM458881,132.6962,-11.6129,Iwaidja,ETHNICITY,ETHNICITY
SM285155,3.0833,8,Iwajowa,ADM2,DISTRICT
SM279736,140.8333,37.0833,Iwaki,ADM2,DISTRICT
SM279593,132.0629,34.2061,Iwakuni,ADM2,DISTRICT
SM278029,136.8689,35.2793,Iwakura,ADM2,DISTRICT
SM233125,146.983,-7.2094,Iwal,LANGUAGE,LANGUAGE
SM233126,141.817,-4.2735,Iwam,LANGUAGE,LANGUAGE
SM279494,134.3625,35.5462,Iwami,ADM2,DISTRICT
SM278269,141.7974,43.1809,Iwamizawa,ADM2,DISTRICT
SM12935,140.6333,42.9833,Iwanai-gun,ADM2,DISTRICT
SM278743,140.8833,38.1167,Iwanuma,ADM2,DISTRICT
SM12936,140.083,37.2682,Iwase-gun,ADM2,DISTRICT
SM279275,137.8504,34.7445,Iwata,ADM2,DISTRICT
SM1610,141.35,39.6,Iwate,ADM1:ADMX,DISTRICT
SM12937,141.2272,39.9724,Iwate-gun,ADM2,DISTRICT
SM285131,4.1413,7.6755,Iwo,ADM2,DISTRICT
SM262618,25.6667,54,Iwye,ADM2,DISTRICT
SM283415,14.2667,36.0542,Ix-Xagħra,ADM1:ADM2,DISTRICT
SM283416,14.2611,36.0333,Ix-Xewkija,ADM1:ADM2,DISTRICT
SM282428,-98.7177,18.0304,Ixcamilpa de Guerrero,ADM2,DISTRICT
SM282429,-97.8421,18.4713,Ixcaquixtla,ADM2,DISTRICT
SM233137,-97.1242,17.7597,Ixcatec,LANGUAGE,LANGUAGE
SM281611,-99.7902,18.4744,Ixcateopan de Cuauhtémoc,ADM2,DISTRICT
SM282905,-98.0226,21.2411,Ixcatepec,ADM2,DISTRICT
SM233138,-96.5719,18.1531,Ixcatlán Mazatec,LANGUAGE,LANGUAGE
SM275308,-91.9155,15.1429,Ixchiguan,ADM2,DISTRICT
SM275253,-90.7667,15.9833,Ixcán,ADM2,DISTRICT
SM282906,-97.0807,19.3556,Ixhuacán de los Reyes,ADM2,DISTRICT
SM282911,-97.138,18.8894,Ixhuatlancillo,ADM2,DISTRICT
SM282907,-98.0166,20.7286,Ixhuatlán de Madero,ADM2,DISTRICT
SM282908,-96.9269,19.0418,Ixhuatlán del Café,ADM2,DISTRICT
SM282909,-94.3779,18.0137,Ixhuatlán del Sureste,ADM2,DISTRICT
SM283146,-93.0081,17.2982,Ixhuatán,ADM2,DISTRICT
SM233142,-90.8935,15.4569,Ixil,LANGUAGE,LANGUAGE
SM282971,-89.4792,21.178,Ixil,ADM2,DISTRICT
SM233143,-90.89,15.46,"Ixil, Chajul",LANGUAGE,LANGUAGE
SM233144,-90.89,15.46,"Ixil, Nebaj",LANGUAGE,LANGUAGE
SM282912,-95.8816,18.4521,Ixmatlahuacan,ADM2,DISTRICT
SM281678,-99.1951,20.5434,Ixmiquilpan,ADM2,DISTRICT
SM14302,-98.0366,17.5055,Ixpantepec Nieves,ADM2,DISTRICT
SM282430,-97.8735,19.6153,Ixtacamaxtitlán,ADM2,DISTRICT
SM283147,-93.0943,17.4267,Ixtacomitán,ADM2,DISTRICT
SM14303,-98.3978,19.3489,Ixtacuixtla de Mariano Matamoros (1995-),ADM2,DISTRICT
SM282913,-97.031,18.8598,Ixtaczoquitlán,ADM2,DISTRICT
SM283148,-92.9133,16.813,Ixtapa,ADM2,DISTRICT
SM281921,-98.8864,19.3161,Ixtapaluca,ADM2,DISTRICT
SM281922,-99.685,18.8519,Ixtapan de la Sal,ADM2,DISTRICT
SM281923,-100.279,19.2574,Ixtapan del Oro,ADM2,DISTRICT
SM283149,-92.9874,17.4789,Ixtapangajoya,ADM2,DISTRICT
SM233146,-97.5759,16.5774,Ixtayutla Mixtec,LANGUAGE,LANGUAGE
SM282688,-97.9046,19.2535,Ixtenco,ADM2,DISTRICT
SM233147,-98.0272,19.2146,Ixtenco Otomi,LANGUAGE,LANGUAGE
SM282431,-97.6426,20.0394,Ixtepec,ADM2,DISTRICT
SM281924,-99.8086,19.5971,Ixtlahuaca,ADM2,DISTRICT
SM283321,-103.6929,18.9741,Ixtlahuacán,ADM2,DISTRICT
SM281806,-103.2048,20.4021,Ixtlahuacán de los Membrillos,ADM2,DISTRICT
SM281807,-103.2147,20.8805,Ixtlahuacán del Río,ADM2,DISTRICT
SM14304,-96.3393,17.4947,Ixtlan de Juarez,ADM2,DISTRICT
SM282035,-102.4107,20.1443,Ixtlán,ADM2,DISTRICT
SM282148,-104.3244,21.0444,Ixtlán del Río,ADM2,DISTRICT
SM304774,43.9974,15.7146,Iyal Surayh,ADM2,DISTRICT
SM233150,5.8433,7.3008,Iyayu,LANGUAGE,LANGUAGE
SM233153,9.533,6.5044,Iyive,LANGUAGE,LANGUAGE
SM233158,146.092,-5.8416,Iyo,LANGUAGE,LANGUAGE
SM279638,132.6891,33.6849,Iyo,ADM2,DISTRICT
SM233160,-62.6508,-22.2067,Iyo'wujwa Chorote,LANGUAGE,LANGUAGE
SM12938,132.7791,33.7109,Iyo-gun,ADM2,DISTRICT
SM233155,-63.2383,-22.1896,Iyojwa'ja Chorote,LANGUAGE,LANGUAGE
SM1611,-89,15.5,Izabal,ADM1,DISTRICT
SM295569,-89.5833,13.7,Izalco,ADM2,DISTRICT
SM282973,-88.9966,20.9314,Izamal,ADM2,DISTRICT
SM302378,-91.9134,36.0949,Izard,ADM2,DISTRICT
SM292827,47.8633,42.5633,Izberbash,ADM2,DISTRICT
SM291426,24.6642,43.8242,Izbiceni,ADM2,DISTRICT
SM303404,72.25,40.9167,Izboskan,ADM2,DISTRICT
SM277543,49.9833,31.9167,Izeh County,ADM2,DISTRICT
SM233162,8.7,9.5,Izere,LANGUAGE,LANGUAGE
SM293315,53.9167,65,Izhemskiy rayon,ADM2,DISTRICT
SM293177,86.8333,56.1667,Izhmorskiy rayon,ADM2,DISTRICT
SM233166,8,6.33,Izi,DIALECT,LANGUAGE
SM233165,8.0304,6.3183,Izi-Ezaa-Ikwo-Mgbo,LANGUAGE,LANGUAGE
SM299465,26.8203,50.1198,Iziaslavs'kyi,ADM2,DISTRICT
SM18220,37.2835,49.2036,Iziumska Miskrada,ADM2,DISTRICT
SM293564,38,52.6667,Izmalkovskiy rayon,ADM2,DISTRICT
SM299609,28.8375,45.3508,Izma‹l's'ka,ADM2,DISTRICT
SM1612,27.0923,38.4622,Izmir,ADM1,DISTRICT
SM18054,29.9462,40.7752,Izmit,ADM2,DISTRICT
SM293092,35.3096,54.9903,Iznoskovskiy rayon,ADM2,DISTRICT
SM296295,13.6566,45.5112,Izola,ADM1:ADM2,DISTRICT
SM233169,5.517,5.1799,Izon,LANGUAGE,LANGUAGE
SM233170,8.7647,10.4735,Izora,LANGUAGE,LANGUAGE
SM296774,36.1614,32.9012,Izra',ADM2,DISTRICT
SM283339,-99.0819,19.396,Iztacalco,ADM2,DISTRICT
SM275430,-90.6924,13.9533,Iztapa,ADM2,DISTRICT
SM283340,-99.0534,19.3532,Iztapalapa,ADM2,DISTRICT
SM279276,138.9259,34.922,Izu,ADM2,DISTRICT
SM278483,130.361,32.0641,Izumi,ADM2,DISTRICT
SM279091,135.4716,34.4314,Izumi,ADM2,DISTRICT
SM458013,130.163,32.1875,Izumi-gun,ADM2,DISTRICT
SM279093,135.3368,34.374,Izumisano,ADM2,DISTRICT
SM279092,135.4046,34.5065,Izumiōtsu,ADM2,DISTRICT
SM12939,132.739,35.3386,Izumo Shi (2011-),ADM2,DISTRICT
SM278958,138.7147,37.521,Izumozaki,ADM2,DISTRICT
SM279277,138.973,35.038,Izunokuni,ADM2,DISTRICT
SM13555,27.0148,55.9699,Izvaltas pagasts,ADM2,DISTRICT
SM290375,23.2833,44.15,Izvoare,ADM2,DISTRICT
SM291893,25.3946,43.8272,Izvoarele,ADM2,DISTRICT
SM291427,24.5314,44.2596,Izvoarele,ADM2,DISTRICT
SM291537,26.0093,45.271,Izvoarele,ADM2,DISTRICT
SM290578,25.8033,44.0487,Izvoarele,ADM2,DISTRICT
SM291809,25.1941,47.7577,Izvoarele Sucevei,ADM2,DISTRICT
SM289881,26.5732,45.1934,Izvoru,ADM2,DISTRICT
SM291252,25.0647,44.4941,Izvoru,ADM2,DISTRICT
SM291082,22.67,44.7083,Izvoru Barzii,ADM2,DISTRICT
SM292116,27.2284,46.5769,Izvoru Berheciului,ADM2,DISTRICT
SM290105,23.1003,46.8454,Izvoru Crisului,ADM2,DISTRICT
SM284675,8.2947,6.4845,Izzi,ADM2,DISTRICT
SM270777,-72.9782,5.6259,Izá,ADM2,DISTRICT
SM282432,-98.4564,18.5476,Izúcar de Matamoros,ADM2,DISTRICT
SM232864,-69.3083,-12.4653,Iñapari,LANGUAGE,LANGUAGE
SM267996,-41.7824,-20.3557,Iúna,ADM2,DISTRICT
SM1555,44,39.9167,Iğdır,ADM1,DISTRICT
SM288786,19.5379,53.6748,Iława,ADM2,DISTRICT
SM283417,14.2444,36.0667,Iż-Żebbuġ,ADM1:ADM2,DISTRICT
SM283458,14.5333,35.8578,Iż-Żejtun,ADM1:ADM2,DISTRICT
SM283421,14.4833,35.8222,Iż-Żurrieq,ADM1:ADM2,DISTRICT
SM297253,101.6177,6.0459,Ja-Nae,ADM2,DISTRICT
SM226172,-13.4759,12.6425,Jaad-Badyara,LANGUAGE,LANGUAGE
SM284828,8.0437,9.4819,Jaba,ADM2,DISTRICT
SM304799,43.9062,14.7296,Jabal Ash sharq,ADM2,DISTRICT
SM304983,43.881,13.4665,Jabal Habashy,ADM2,DISTRICT
SM304775,43.9051,15.7717,Jabal Iyal Yazid,ADM2,DISTRICT
SM304909,45.1887,15.0474,Jabal Murad,ADM2,DISTRICT
SM305046,43.6278,14.0309,Jabal Ra's,ADM2,DISTRICT
SM233175,43.2417,16.9092,Jabal Razih,LANGUAGE,LANGUAGE
SM296762,37.0847,35.9996,Jabal Sam'an,ADM2,DISTRICT
SM9536,69.1841,35.1163,Jabal us Saraj,ADM2,DISTRICT
SM276984,79.95,23.16,Jabalpur (1998-),ADM2,DISTRICT
SM4079,168.9768,7.7513,Jabat Island,ADM1,DISTRICT
SM458665,122.5274,-17.2324,Jabirr Jabirr,ETHNICITY,ETHNICITY
SM1613,21.9178,42.9953,Jablanički,ADM1:ADM2,DISTRICT
SM296739,36.0449,35.2905,Jableh,ADM2,DISTRICT
SM271227,15.25,50.75,Jablonec nad Nisou,ADM2,DISTRICT
SM265011,-34.9738,-8.1457,Jaboatão dos Guararapes,ADM2,DISTRICT
SM305984,69.496,40.0988,Jabor Rasulov,ADM2,DISTRICT
SM266628,-48.4016,-20.6662,Jaborandi,ADM2,DISTRICT
SM267484,-45.1841,-14.0385,Jaborandi,ADM2,DISTRICT
SM266176,-51.7987,-27.1285,Jaborá,ADM2,DISTRICT
SM265725,-53.2702,-27.6097,Jaboticaba,ADM2,DISTRICT
SM263551,-43.7455,-19.4191,Jaboticatubas,ADM2,DISTRICT
SM459056,145.1071,-35.711,Jabulajabula / Yabula Yabula,ETHNICITY,ETHNICITY
SM459222,145.84,-17.8043,Jabunbarra Jirrbal,ETHNICITY,ETHNICITY
SM264522,-50.0738,-23.6953,Jabuti,ADM2,DISTRICT
SM266629,-48.2931,-21.2022,Jabuticabal,ADM2,DISTRICT
SM15800,-65.9652,17.9831,Jacaboa Barrio,ADM2,DISTRICT
SM15801,-66.5239,18.0627,Jacaguas Barrio,ADM2,DISTRICT
SM281679,-99.1492,20.9672,Jacala de Ledezma,ADM2,DISTRICT
SM275909,-86.6789,14.0316,Jacaleapa,ADM2,DISTRICT
SM275503,-91.7332,15.7521,Jacaltenango,ADM2,DISTRICT
SM15802,-66.8322,18.0329,Jacana Barrio,ADM2,DISTRICT
SM15803,-65.9172,18.0972,Jacanas Barrio,ADM2,DISTRICT
SM267485,-42.4011,-14.8352,Jacaraci,ADM2,DISTRICT
SM264436,-35.2634,-6.6268,Jacaraú,ADM2,DISTRICT
SM264179,-57.3027,-7.4453,Jacareacanga,ADM2,DISTRICT
SM264523,-49.9541,-23.1907,Jacarezinho,ADM2,DISTRICT
SM266630,-45.9705,-23.2931,Jacareí,ADM2,DISTRICT
SM265369,-37.2228,-9.6728,Jacaré dos Homens,ADM2,DISTRICT
SM266631,-49.5761,-20.9475,Jaci,ADM2,DISTRICT
SM263170,-55.0224,-15.8956,Jaciara,ADM2,DISTRICT
SM263552,-40.3318,-16.1921,Jacinto,ADM2,DISTRICT
SM266177,-49.8879,-29.0118,Jacinto Machado,ADM2,DISTRICT
SM302661,-98.1725,33.2335,Jack,ADM2,DISTRICT
SM12437,-76.7489,18.0387,Jacks Hill,ADM2,DISTRICT
SM12438,-76.941,18.3722,Jacks River,ADM2,DISTRICT
SM303240,-90.8052,44.3191,Jackson,ADM2,DISTRICT
SM303117,-81.6748,38.8345,Jackson,ADM2,DISTRICT
SM302582,-85.6732,36.3592,Jackson,ADM2,DISTRICT
SM302255,-122.7284,42.4322,Jackson,ADM2,DISTRICT
SM302663,-96.5777,28.9542,Jackson,ADM2,DISTRICT
SM302198,-99.4148,34.588,Jackson,ADM2,DISTRICT
SM302506,-101.6281,43.6942,Jackson,ADM2,DISTRICT
SM302117,-82.6184,39.0197,Jackson,ADM2,DISTRICT
SM301976,-83.1408,35.2874,Jackson,ADM2,DISTRICT
SM301363,-95.1541,43.6742,Jackson,ADM2,DISTRICT
SM301282,-84.4234,42.2485,Jackson,ADM2,DISTRICT
SM301449,-88.6228,30.4629,Jackson,ADM2,DISTRICT
SM301565,-94.3461,39.0085,Jackson,ADM2,DISTRICT
SM300926,-95.7937,39.4168,Jackson,ADM2,DISTRICT
SM300827,-90.5742,42.1717,Jackson,ADM2,DISTRICT
SM300720,-86.0375,38.9064,Jackson,ADM2,DISTRICT
SM301059,-84.0058,37.4198,Jackson,ADM2,DISTRICT
SM301125,-92.5577,32.302,Jackson,ADM2,DISTRICT
SM300515,-83.5664,34.1339,Jackson,ADM2,DISTRICT
SM300337,-85.2155,30.7954,Jackson,ADM2,DISTRICT
SM300624,-89.3821,37.7851,Jackson,ADM2,DISTRICT
SM303341,-106.3428,40.6664,Jackson,ADM2,DISTRICT
SM302379,-91.2146,35.5992,Jackson,ADM2,DISTRICT
SM300275,-85.9993,34.7794,Jackson,ADM2,DISTRICT
SM12439,-77.4846,18.4178,Jackson Town,ADM2,DISTRICT
SM17436,-61.013,13.948,Jacmel,ADM2,DISTRICT
SM276111,-72.4816,18.2988,Jacmel,ADM2,DISTRICT
SM267486,-40.5594,-11.139,Jacobina,ADM2,DISTRICT
SM265245,-41.174,-7.9649,Jacobina do Piauí,ADM2,DISTRICT
SM254083,-52.71,72.31,Jacobshavn,ETHNICITY,ETHNICITY
SM282036,-102.3179,19.9495,Jacona,ADM2,DISTRICT
SM9948,-53.068,-29.031,Jacuizinho Municipality,ADM2,DISTRICT
SM264180,-49.2228,-4.5688,Jacundá,ADM2,DISTRICT
SM266633,-48.0434,-24.7804,Jacupiranga,ADM2,DISTRICT
SM303565,-68.856,11.0742,Jacura,ADM2,DISTRICT
SM265726,-52.5222,-27.7747,Jacutinga,ADM2,DISTRICT
SM263554,-46.6104,-22.2963,Jacutinga,ADM2,DISTRICT
SM263553,-46.7184,-21.011,Jacuí,ADM2,DISTRICT
SM265370,-35.4589,-8.8772,Jacuípe,ADM2,DISTRICT
SM233181,79.153,30.241,Jad,LANGUAGE,LANGUAGE
SM284851,12.3174,8.6888,Jada,ADM2,DISTRICT
SM254087,60.46,26.52,Jadgal,ETHNICITY,ETHNICITY
SM233183,63.535,26.9682,Jadgali,LANGUAGE,LANGUAGE
SM459154,116.1885,-21.8455,Jadira,ETHNICITY,ETHNICITY
SM260168,69.407,33.3736,Jadran,ADM2,DISTRICT
SM14050,-8.5173,5.112,Jaedae District,ADM2,DISTRICT
SM11218,25.0804,61.8179,Jaemsae,ADM2,DISTRICT
SM11166,25.823,58.9841,Jaerva vald,ADM2,DISTRICT
SM5130,68.1978,28.301,Jafarabad (2013-),ADM2:ADM3,DISTRICT
SM1614,80.0833,9.75,Jaffna,ADM1:ADM2,DISTRICT
SM277110,86.3,20.2,Jagatsinghapur,ADM2,DISTRICT
SM9537,68.3557,33.8375,Jaghatu (2005-),ADM2,DISTRICT
SM260303,67.4638,33.1434,Jaghuri,ADM2,DISTRICT
SM11647,78.9135,18.7938,Jagitial,ADM2,DISTRICT
SM305371,18.58,45.6997,Jagodnjak,ADM2,DISTRICT
SM254090,-80,22.34,Jagua,ETHNICITY,ETHNICITY
SM15804,-66.776,18.1105,Jagua Pasto Barrio,ADM2,DISTRICT
SM15805,-66.0328,18.031,Jagual Barrio (Patillas),ADM2,DISTRICT
SM15806,-65.9995,18.159,Jagual Barrio (San Lorenzo),ADM2,DISTRICT
SM264524,-51.583,-23.0763,Jaguapitã,ADM2,DISTRICT
SM267488,-39.9293,-13.5957,Jaguaquara,ADM2,DISTRICT
SM15807,-65.9688,18.2395,Jaguar Barrio,ADM2,DISTRICT
SM267489,-40.1043,-10.148,Jaguarari,ADM2,DISTRICT
SM263555,-42.7171,-19.6359,Jaguaraçu,ADM2,DISTRICT
SM267956,-38.756,-5.4833,Jaguaretama,ADM2,DISTRICT
SM265728,-54.6463,-29.4525,Jaguari,ADM2,DISTRICT
SM264525,-49.6655,-24.3436,Jaguariaíva,ADM2,DISTRICT
SM267957,-38.5103,-5.6503,Jaguaribara,ADM2,DISTRICT
SM267958,-38.6857,-5.9726,Jaguaribe,ADM2,DISTRICT
SM267490,-39.0134,-13.1283,Jaguaripe,ADM2,DISTRICT
SM266634,-47.0173,-22.6947,Jaguariúna,ADM2,DISTRICT
SM267959,-37.7925,-4.8141,Jaguaruana,ADM2,DISTRICT
SM266178,-49.0798,-28.6591,Jaguaruna,ADM2,DISTRICT
SM265727,-53.3323,-32.405,Jaguarão,ADM2,DISTRICT
SM267997,-39.9752,-18.9919,Jaguaré,ADM2,DISTRICT
SM15808,-66.4668,18.3211,Jaguas Barrio (Ciales),ADM2,DISTRICT
SM15809,-66.7917,18.0406,Jaguas Barrio (Guayanilla),ADM2,DISTRICT
SM15810,-66.0123,18.2912,Jaguas Barrio (Gurabo),ADM2,DISTRICT
SM15811,-66.7294,18.0922,Jaguas Barrio (Peñuelas),ADM2,DISTRICT
SM15812,-67.2012,18.3503,Jagueey Barrio (Aguada),ADM2,DISTRICT
SM15813,-67.2027,18.3256,Jagueey Barrio (Rincon),ADM2,DISTRICT
SM15814,-66.0831,18.2823,Jagueeyes Barrio,ADM2,DISTRICT
SM15815,-67.1056,18.1508,Jaguitas Barrio,ADM2,DISTRICT
SM459099,142.6847,-36.3216,Jagwadjali,ETHNICITY,ETHNICITY
SM271008,-81.1167,22.5333,Jagüey Grande,ADM2,DISTRICT
SM233190,102.291,3.6762,Jah Hut,LANGUAGE,LANGUAGE
SM254091,102.92,3.56,Jah hut,ETHNICITY,ETHNICITY
SM305027,44.6576,13.7257,Jahaf,ADM2,DISTRICT
SM254092,99.93,7.41,Jahai,ETHNICITY,ETHNICITY
SM233189,-12.7262,11.5399,Jahanka,LANGUAGE,LANGUAGE
SM304800,44.3143,14.7331,Jahran,ADM2,DISTRICT
SM277719,53.3333,28.7333,Jahrom,ADM2,DISTRICT
SM284787,9.5546,12.08,Jahun,ADM2,DISTRICT
SM265028,-41.2202,-7.4565,Jaicós,ADM2,DISTRICT
SM262689,-64.689,-19.0853,Jaime Zudáñez,ADM2,DISTRICT
SM277171,76,27,Jaipur (1996-),ADM2,DISTRICT
SM277172,71.0089,26.9938,Jaisalmer,ADM2,DISTRICT
SM280311,73.7949,42.7461,Jaiyl,ADM2,DISTRICT
SM277111,86.3124,20.8415,Jajapur,ADM2,DISTRICT
SM375340,82.168,28.8694,Jajarkot,ADM2:ADM3,DISTRICT
SM260169,69.6728,33.9776,Jaji,ADM2,DISTRICT
SM260072,70.0789,33.6408,Jaji Maydan,ADM2,DISTRICT
SM15816,-66.1447,18.0572,Jajome Alto Barrio,ADM2,DISTRICT
SM15817,-66.1731,18.0683,Jajome Bajo Barrio,ADM2,DISTRICT
SM254105,-90,14,Jakaltek,ETHNICITY,ETHNICITY
SM276738,106.7673,-6.1676,Jakarta Barat,ADM2,DISTRICT
SM276739,106.8403,-6.1777,Jakarta Pusat,ADM2,DISTRICT
SM1615,106.8584,-6.2182,Jakarta Raya,ADM1,DISTRICT
SM276740,106.8135,-6.266,Jakarta Selatan,ADM2,DISTRICT
SM276741,106.884,-6.2521,Jakarta Timur,ADM2,DISTRICT
SM276742,106.8823,-6.1339,Jakarta Utara,ADM2,DISTRICT
SM232875,112.378,-7.908,Jakartan Sign Language,LANGUAGE,LANGUAGE
SM228591,9.5031,9.0447,Jakato,LANGUAGE,LANGUAGE
SM250869,-12.19,14.69,Jakhanke,ETHNICITY,ETHNICITY
SM375493,68.4902,28.2221,Jakobabad,ADM2:ADM3,DISTRICT
SM346816,22.9321,63.5704,Jakobstadsregionen,ADM2:ADM3,DISTRICT
SM305614,15.8535,45.936,Jakovlje,ADM2,DISTRICT
SM233198,103.342,2.6458,Jakun,LANGUAGE,LANGUAGE
SM254108,103.52,2.54,Jakun,ETHNICITY,ETHNICITY
SM285274,10.9123,12.4561,Jakusko,ADM2,DISTRICT
SM305403,17.765,45.3577,Jakšić,ADM2,DISTRICT
SM282149,-104.3972,21.1806,Jala,ADM2,DISTRICT
SM228314,11.5853,9.5943,Jalaa,LANGUAGE,LANGUAGE
SM1616,72.5,41.5,Jalal-Abad,ADM1:ADMX,DISTRICT
SM9538,70.4635,34.4219,Jalalabad (2005-),ADM2,DISTRICT
SM295834,45.6127,3.4033,Jalalaqsi,ADM2,DISTRICT
SM282914,-97.2862,19.773,Jalancingo,ADM2,DISTRICT
SM277142,75.6167,31.4167,Jalandhar (1995-),ADM2,DISTRICT
SM285469,-86.1018,13.9248,Jalapa,ADM2,DISTRICT
SM282620,-92.776,17.7822,Jalapa,ADM2,DISTRICT
SM275174,-90.033,14.6328,Jalapa,ADM2,DISTRICT
SM1617,-89.9167,14.5833,Jalapa,ADM1,DISTRICT
SM233201,-96.4977,18.0803,Jalapa De Díaz Mazatec,LANGUAGE,LANGUAGE
SM281925,-99.3952,19.17,Jalatlaco,ADM2,DISTRICT
SM277299,79.5,26,Jalaun,ADM2,DISTRICT
SM282915,-96.7625,19.3383,Jalcomulco,ADM2,DISTRICT
SM266635,-50.5581,-20.2888,Jales,ADM2,DISTRICT
SM277036,75.5,21,Jalgaon,ADM2,DISTRICT
SM285255,11.3264,8.9195,Jalingo,ADM2,DISTRICT
SM1618,-103.6667,20.3333,Jalisco,ADM1,DISTRICT
SM243539,18.1397,11.1426,Jalking,LANGUAGE,LANGUAGE
SM233206,-5.2196,10.5426,Jalkunan,LANGUAGE,LANGUAGE
SM277037,75.9,19.8,Jalna,ADM2,DISTRICT
SM303405,72.6667,40.75,Jalolquduq,ADM2,DISTRICT
SM277173,72.29,25.08,Jalor,ADM2,DISTRICT
SM281808,-102.4823,21.1891,Jalostotitlán,ADM2,DISTRICT
SM17437,-61.0589,13.8281,Jalousie,ADM2,DISTRICT
SM283048,-102.9768,21.6364,Jalpa,ADM2,DISTRICT
SM282621,-93.0923,18.1906,Jalpa de Méndez,ADM2,DISTRICT
SM277375,88.7146,26.5197,Jalpaiguri (2014-),ADM2,DISTRICT
SM282464,-99.3924,21.2973,Jalpan de Serra,ADM2,DISTRICT
SM275196,-90.0181,14.1163,Jalpatagua,ADM2,DISTRICT
SM260229,68.5824,34.525,Jalrez,ADM2,DISTRICT
SM14305,-99.0938,19.751,Jaltenco (2003-),ADM2,DISTRICT
SM281680,-98.5245,21.1506,Jaltocán,ADM2,DISTRICT
SM254114,169.47,6.06,Jaluit,ETHNICITY,ETHNICITY
SM4080,169.5833,6,Jaluit Atoll,ADM1,DISTRICT
SM305502,16.4739,46.259,Jalžabet,ADM2,DISTRICT
SM11775,52.2333,28,Jam,ADM2,DISTRICT
SM273509,-80.2284,-0.2199,Jama,ADM2,DISTRICT
SM254115,117.13,7.37,Jama,ETHNICITY,ETHNICITY
SM254115,118.02,4.75,Jama,ETHNICITY,ETHNICITY
SM285312,9.9129,11.7216,Jama'are,ADM2,DISTRICT
SM17874,42.7496,0.0669,Jamaame,ADM2,DISTRICT
SM17875,42.8033,0.1231,Jamaame (excl. Jamame town),ADM2,DISTRICT
SM454429,-77.2665,18.125,Jamaican,ETHNICITY,ETHNICITY
SM233208,-76.9321,17.9997,Jamaican Country Sign Language,LANGUAGE,LANGUAGE
SM233211,-77.2612,18.13,Jamaican Creole English,LANGUAGE,LANGUAGE
SM233212,-77.758,18.0499,Jamaican Sign Language,LANGUAGE,LANGUAGE
SM262253,89.8333,25,Jamalpur,ADM2,DISTRICT
SM274885,-2.7,7.95,Jaman North,ADM2,DISTRICT
SM274886,-2.7868,7.5837,Jaman South,ADM2,DISTRICT
SM271908,-70.4503,19.6086,Jamao al Norte,ADM2,DISTRICT
SM282917,-96.2563,19.0075,Jamapa,ADM2,DISTRICT
SM281810,-102.673,20.2762,Jamay,ADM2,DISTRICT
SM260320,15.8349,-14.7629,Jamba,ADM2,DISTRICT
SM269855,-76.3251,2.8507,Jambaló,ADM2,DISTRICT
SM266636,-45.7122,-23.2815,Jambeiro,ADM2,DISTRICT
SM276748,103.65,-1.6167,Jambi,ADM2,DISTRICT
SM1619,103,-1.5,Jambi,ADM1,DISTRICT
SM233215,103.13,-2.6469,Jambi Malay,LANGUAGE,LANGUAGE
SM303031,-76.7738,37.3133,James City,ADM2,DISTRICT
SM12440,-77.337,18.1875,James Hill,ADM2,DISTRICT
SM233217,-97.8417,16.2279,Jamiltepec Mixtec,LANGUAGE,LANGUAGE
SM229708,130.06,-15.0558,Jaminjung-Ngaliwurru,LANGUAGE,LANGUAGE
SM297916,10.7157,35.6392,Jammel,ADM2,DISTRICT
SM271714,9.5625,57.1458,Jammerbugt,ADM2,DISTRICT
SM276896,74.8333,32.75,Jammu (2006-),ADM2,DISTRICT
SM3943,75.4078,33.5329,Jammu and Kashmir (2019-),ADM1,DISTRICT
SM276816,70,22.25,Jamnagar (2013-),ADM2,DISTRICT
SM263559,-41.7574,-18.4723,Jampruca,ADM2,DISTRICT
SM233223,-2.58,14.1794,Jamsay Dogon,LANGUAGE,LANGUAGE
SM254125,64.27,30.47,Jamshedi,ETHNICITY,ETHNICITY
SM254125,58.16,33,Jamshedi,ETHNICITY,ETHNICITY
SM375483,67.8136,25.7411,Jamshoro,ADM2:ADM3,DISTRICT
SM276901,86.85,24,Jamtara,ADM2,DISTRICT
SM233225,14.56,63.12,Jamtska,LANGUAGE,LANGUAGE
SM291989,21.4432,45.2677,Jamu Mare,ADM2,DISTRICT
SM277406,86.1754,24.9208,Jamui,ADM2,DISTRICT
SM270618,-76.5369,3.2608,Jamundí,ADM2,DISTRICT
SM1622,-8.3771,71.008,Jan Mayen,ADM1:ADM2,DISTRICT
SM1622,-8.3771,71.008,Jan Mayen,ADM1:ADM2,DISTRICT
SM1622,-8.5,70.9833,Jan Mayen,ADM1:ADM2,DISTRICT
SM1622,-8.5,70.9833,Jan Mayen,ADM1:ADM2,DISTRICT
SM233226,11.3841,9.6611,Jan-Awei,LANGUAGE,LANGUAGE
SM459149,118.2674,-24.7355,Janadjina,ETHNICITY,ETHNICITY
SM254127,-98.69,23.75,Janambre,ETHNICITY,ETHNICITY
SM263560,-43.3676,-15.7788,Janaúba,ADM2,DISTRICT
SM458200,153.3991,-27.7729,Jandai,ETHNICITY,ETHNICITY
SM233229,153.4624,-27.5687,Jandai,DIALECT,LANGUAGE
SM268070,-50.1948,-17.129,Jandaia,ADM2,DISTRICT
SM264526,-51.6963,-23.6279,Jandaia do Sul,ADM2,DISTRICT
SM233227,69.76,25.617,Jandavra,LANGUAGE,LANGUAGE
SM265559,-36.0983,-5.3789,Jandaíra,ADM2,DISTRICT
SM267491,-37.6185,-11.611,Jandaíra,ADM2,DISTRICT
SM266637,-46.8957,-23.5408,Jandira,ADM2,DISTRICT
SM265560,-37.515,-5.9359,Janduís,ADM2,DISTRICT
SM263171,-56.5428,-15.3376,Jangada,ADM2,DISTRICT
SM15057,35.3197,-24.1138,Jangamo District (excl. Innhambane city),ADM2,DISTRICT
SM254134,129.12,-28.24,Janggundjara,ETHNICITY,ETHNICITY
SM254134,129.12,-28.24,Janggundjara,ETHNICITY,ETHNICITY
SM254134,129.12,-28.24,Janggundjara,ETHNICITY,ETHNICITY
SM254134,131.95,-27,Janggundjara,ETHNICITY,ETHNICITY
SM254134,131.95,-27,Janggundjara,ETHNICITY,ETHNICITY
SM254134,131.95,-27,Janggundjara,ETHNICITY,ETHNICITY
SM254134,132.2606,-26.6237,Janggundjara,ETHNICITY,ETHNICITY
SM254134,132.2606,-26.6237,Janggundjara,ETHNICITY,ETHNICITY
SM254134,132.2606,-26.6237,Janggundjara,ETHNICITY,ETHNICITY
SM280590,126.9491,34.7056,Jangheung,ADM2,DISTRICT
SM254136,59.62,26.38,Jangizai Maid,ETHNICITY,ETHNICITY
SM289129,127.2619,40.5322,Jangjin,ADM2,DISTRICT
SM233236,110.647,0.2974,Jangkang,LANGUAGE,LANGUAGE
SM11648,79.161,17.7294,Jangoan,ADM2,DISTRICT
SM305892,126.6708,38.0824,Jangpung,ADM2,DISTRICT
SM458503,138.6827,-17.8514,Jangrala,ETHNICITY,ETHNICITY
SM280591,126.7682,35.3273,Jangseong,ADM2,DISTRICT
SM233233,78.4344,31.5666,Jangshung,LANGUAGE,LANGUAGE
SM280585,127.5636,35.625,Jangsu,ADM2,DISTRICT
SM254138,57.41,31.33,Jani Khan,ETHNICITY,ETHNICITY
SM260170,69.7866,33.6554,Jani Khel,ADM2,DISTRICT
SM271872,-70.7952,19.2353,Janico,ADM2,DISTRICT
SM457937,68.4805,32.6651,Janikhel,ADM2,DISTRICT
SM264527,-52.8029,-24.0884,Janiópolis,ADM2,DISTRICT
SM275049,-14.7652,13.5356,Janjanbureh,ADM2,DISTRICT
SM277445,82.7,21.9,Janjgir-Champa,ADM2,DISTRICT
SM233237,8.6303,10.1197,Janji,LANGUAGE,LANGUAGE
SM305646,17.4303,42.9253,Janjina,ADM2,DISTRICT
SM275744,-86.5152,15.079,Jano,ADM2,DISTRICT
SM254140,-108.87,32.16,Jano,ETHNICITY,ETHNICITY
SM283238,-108.4045,30.8086,Janos,ADM2,DISTRICT
SM282099,-98.7802,18.7013,Jantetelco,ADM2,DISTRICT
SM1623,33.8,29,Janub Sina',ADM1,DISTRICT
SM263561,-45.2014,-15.3213,Januária,ADM2,DISTRICT
SM265561,-35.6176,-6.1571,Januário Cicco,ADM2,DISTRICT
SM288912,22.4115,50.7456,Janów Lubelski,ADM2,DISTRICT
SM241042,135,35,Japanese,LANGUAGE,LANGUAGE
SM254143,139.04,36.51,Japanese,ETHNICITY,ETHNICITY
SM254143,139.04,36.51,Japanese,ETHNICITY,ETHNICITY
SM254143,139.04,36.51,Japanese,ETHNICITY,ETHNICITY
SM254143,139.04,36.51,Japanese,ETHNICITY,ETHNICITY
SM254143,139.04,36.51,Japanese,ETHNICITY,ETHNICITY
SM254143,142.94,43.84,Japanese,ETHNICITY,ETHNICITY
SM254143,142.94,43.84,Japanese,ETHNICITY,ETHNICITY
SM254143,142.94,43.84,Japanese,ETHNICITY,ETHNICITY
SM254143,142.94,43.84,Japanese,ETHNICITY,ETHNICITY
SM254143,142.94,43.84,Japanese,ETHNICITY,ETHNICITY
SM254143,136,35,Japanese,ETHNICITY,ETHNICITY
SM254143,136,35,Japanese,ETHNICITY,ETHNICITY
SM254143,136,35,Japanese,ETHNICITY,ETHNICITY
SM254143,136,35,Japanese,ETHNICITY,ETHNICITY
SM254143,136,35,Japanese,ETHNICITY,ETHNICITY
SM254143,133.67,34.67,Japanese,ETHNICITY,ETHNICITY
SM254143,133.67,34.67,Japanese,ETHNICITY,ETHNICITY
SM254143,133.67,34.67,Japanese,ETHNICITY,ETHNICITY
SM254143,133.67,34.67,Japanese,ETHNICITY,ETHNICITY
SM254143,133.67,34.67,Japanese,ETHNICITY,ETHNICITY
SM254143,138,38,Japanese,ETHNICITY,ETHNICITY
SM254143,138,38,Japanese,ETHNICITY,ETHNICITY
SM254143,138,38,Japanese,ETHNICITY,ETHNICITY
SM254143,138,38,Japanese,ETHNICITY,ETHNICITY
SM254143,138,38,Japanese,ETHNICITY,ETHNICITY
SM254143,138,38,Japanese,ETHNICITY,ETHNICITY
SM254143,138,38,Japanese,ETHNICITY,ETHNICITY
SM254143,138,38,Japanese,ETHNICITY,ETHNICITY
SM254143,138,38,Japanese,ETHNICITY,ETHNICITY
SM254143,138,38,Japanese,ETHNICITY,ETHNICITY
SM233238,139.079,36.0221,Japanese Sign Language,LANGUAGE,LANGUAGE
SM265371,-35.2963,-9.099,Japaratinga,ADM2,DISTRICT
SM267100,-36.8801,-10.5289,Japaratuba,ADM2,DISTRICT
SM263562,-45.5373,-20.1293,Japaraíba,ADM2,DISTRICT
SM254144,136.17,-1.8,Japen,ETHNICITY,ETHNICITY
SM265274,-43.601,-22.6652,Japeri,ADM2,DISTRICT
SM233242,102.36,32.1,Japhug,LANGUAGE,LANGUAGE
SM265563,-35.9219,-6.438,Japi,ADM2,DISTRICT
SM264528,-50.1801,-23.6905,Japira,ADM2,DISTRICT
SM267101,-36.823,-10.4225,Japoatã,ADM2,DISTRICT
SM263563,-44.3484,-15.9472,Japonvar,ADM2,DISTRICT
SM263057,-54.544,-23.7941,Japorã,ADM2,DISTRICT
SM233244,-72.7101,10.5523,Japrería,LANGUAGE,LANGUAGE
SM295398,159.583,-8.4726,Japuana,ADM2,DISTRICT
SM264529,-52.5622,-23.4186,Japurá,ADM2,DISTRICT
SM267328,-68.2453,-1.5535,Japurá,ADM2,DISTRICT
SM233246,-75.8092,-12.7419,Jaqaru,LANGUAGE,LANGUAGE
SM265012,-35.7985,-8.7388,Jaqueira,ADM2,DISTRICT
SM271867,-71.1236,18.3029,Jaquimeyes,ADM2,DISTRICT
SM265730,-50.3643,-28.9347,Jaquirana,ADM2,DISTRICT
SM233253,11.7036,10.5232,Jara,LANGUAGE,LANGUAGE
SM271788,-70.6667,19.1333,Jarabacoa,ADM2,DISTRICT
SM296763,37.8141,36.6909,Jarabulus,ADM2,DISTRICT
SM263058,-54.2374,-20.2724,Jaraguari,ADM2,DISTRICT
SM268071,-49.4317,-15.6529,Jaraguá,ADM2,DISTRICT
SM266179,-49.1467,-26.4281,Jaraguá do Sul,ADM2,DISTRICT
SM233251,107.813,13.6161,Jarai,LANGUAGE,LANGUAGE
SM254146,107.03,13.79,Jarai,ETHNICITY,ETHNICITY
SM281542,-101.0522,20.3552,Jaral del Progreso,ADM2,DISTRICT
SM254148,139.61,-35.59,Jaraldi,ETHNICITY,ETHNICITY
SM254148,139.61,-35.59,Jaraldi,ETHNICITY,ETHNICITY
SM254148,139.61,-35.59,Jaraldi,ETHNICITY,ETHNICITY
SM254148,139.29,-35.06,Jaraldi,ETHNICITY,ETHNICITY
SM254148,139.29,-35.06,Jaraldi,ETHNICITY,ETHNICITY
SM254148,139.29,-35.06,Jaraldi,ETHNICITY,ETHNICITY
SM254148,139.3755,-35.4513,Jaraldi,ETHNICITY,ETHNICITY
SM254148,139.3755,-35.4513,Jaraldi,ETHNICITY,ETHNICITY
SM254148,139.3755,-35.4513,Jaraldi,ETHNICITY,ETHNICITY
SM265373,-36.9604,-9.6505,Jaramataia,ADM2,DISTRICT
SM273510,-80.6042,-0.9789,Jaramijó,ADM2,DISTRICT
SM274379,43.75,8.25,Jarar,ADM2,DISTRICT
SM278006,35.8705,32.2519,Jarash,ADM2,DISTRICT
SM1624,35.9167,32.25,Jarash,ADM1,DISTRICT
SM254149,92.65,12.17,Jarawa,ETHNICITY,ETHNICITY
SM233248,92.658,11.6089,Jarawa (India),LANGUAGE,LANGUAGE
SM233250,9.61,9.8,Jarawa (Nigeria),LANGUAGE,LANGUAGE
SM304963,46.7338,15.19,Jardan,ADM2,DISTRICT
SM263059,-56.2351,-21.6451,Jardim,ADM2,DISTRICT
SM267960,-39.2404,-7.6382,Jardim,ADM2,DISTRICT
SM264530,-51.7227,-24.2145,Jardim Alegre,ADM2,DISTRICT
SM264531,-52.0652,-22.5694,Jardim Olinda,ADM2,DISTRICT
SM265565,-35.9503,-5.6386,Jardim de Angicos,ADM2,DISTRICT
SM265029,-42.4776,-6.1573,Jardim do Mulato,ADM2,DISTRICT
SM265566,-36.8256,-6.5642,Jardim do Seridó,ADM2,DISTRICT
SM265564,-37.2456,-6.271,Jardim-Piranhas,ADM2,DISTRICT
SM266638,-47.8171,-20.9864,Jardinópolis,ADM2,DISTRICT
SM266180,-52.8946,-26.7355,Jardinópolis,ADM2,DISTRICT
SM270228,-75.8185,5.5904,Jardín,ADM2,DISTRICT
SM283558,95.9278,46.9722,Jargalan,ADM2,DISTRICT
SM283636,104.4001,49.0421,Jargalant,ADM2,DISTRICT
SM283636,104.4001,49.0421,Jargalant,ADM2,DISTRICT
SM283538,100.7623,48.7287,Jargalant,ADM2,DISTRICT
SM283707,105.8684,48.5148,Jargalant,ADM2,DISTRICT
SM283619,99.4254,48.5597,Jargalant,ADM2,DISTRICT
SM283750,99.6642,47.1424,Jargalant,ADM2,DISTRICT
SM283595,91.6425,47.9929,Jargalant,ADM2,DISTRICT
SM283636,104.4016,49.054,Jargalant,ADM2,DISTRICT
SM283636,104.4016,49.054,Jargalant,ADM2,DISTRICT
SM283565,109.4731,47.4891,Jargaltkhaan,ADM2,DISTRICT
SM265731,-54.2978,-29.288,Jari,ADM2,DISTRICT
SM459045,141.7283,-34.7661,Jari Jari,ETHNICITY,ETHNICITY
SM295775,49.001,7.1624,Jariiban,ADM2,DISTRICT
SM296103,-55.3319,5.8256,Jarikaba,ADM2,DISTRICT
SM266639,-46.7254,-23.0977,Jarinu,ADM2,DISTRICT
SM292349,27.0426,45.7999,Jaristea,ADM2,DISTRICT
SM305538,18.7307,45.3172,Jarmina,ADM2,DISTRICT
SM288818,17.455,52.009,Jarocin,ADM2,DISTRICT
SM289024,22.7824,50.0252,Jarosław,ADM2,DISTRICT
SM303439,67.5,37.5,Jarqo'rg'on,ADM2,DISTRICT
SM275041,-15.4167,13.4167,Jarra Central,ADM2,DISTRICT
SM275042,-15.25,13.4167,Jarra East,ADM2,DISTRICT
SM275043,-15.5333,13.4167,Jarra West,ADM2,DISTRICT
SM17833,14.5543,46.0735,Jarse District,ADM2,DISTRICT
SM254151,42.09,8.11,Jarso,ETHNICITY,ETHNICITY
SM233263,128.262,-18.3288,Jaru,LANGUAGE,LANGUAGE
SM266087,-62.585,-10.5922,Jaru,ADM2,DISTRICT
SM254152,-63.76,-10.74,Jaru,ETHNICITY,ETHNICITY
SM271018,-81.972,23.0592,Jaruco,ADM2,DISTRICT
SM323960,-114.078,52.3393,Jarvis Bay,ADM2:ADM3,DISTRICT
SM1626,-159.997,-0.3722,Jarvis island,ADM0:ADM1,DISTRICT
SM305609,15.5751,44.2297,Jasenice,ADM2,DISTRICT
SM305458,16.9113,45.2708,Jasenovac,ADM2,DISTRICT
SM277446,83.8457,22.7849,Jashpur,ADM2,DISTRICT
SM4380,0.4687,7.4211,Jasikon (2008-),ADM2,DISTRICT
SM302451,-81.0233,32.422,Jasper,ADM2,DISTRICT
SM302664,-94.0251,30.744,Jasper,ADM2,DISTRICT
SM301566,-94.3406,37.2036,Jasper,ADM2,DISTRICT
SM301450,-89.1189,32.0191,Jasper,ADM2,DISTRICT
SM300829,-93.0538,41.686,Jasper,ADM2,DISTRICT
SM300721,-87.1161,41.023,Jasper,ADM2,DISTRICT
SM300626,-88.1538,39.01,Jasper,ADM2,DISTRICT
SM300516,-83.6881,33.3164,Jasper,ADM2,DISTRICT
SM324123,-118.1746,52.8931,Jasper,ADM2:ADM3,DISTRICT
SM276030,15.6667,45.6667,Jastrebarsko,ADM2,DISTRICT
SM288720,18.6005,49.9606,Jastrzębie-Zdrój,ADM2,DISTRICT
SM11572,20.0657,47.4613,Jaszapati Jaras,ADM2,DISTRICT
SM11573,19.8689,47.565,Jaszberenyi Jaras,ADM2,DISTRICT
SM289025,21.4464,49.6426,Jasło,ADM2,DISTRICT
SM264533,-50.9131,-23.273,Jataizinho,ADM2,DISTRICT
SM268072,-51.7053,-17.8583,Jataí,ADM2,DISTRICT
SM265013,-36.571,-8.053,Jataúba,ADM2,DISTRICT
SM263060,-53.815,-22.7399,Jateí,ADM2,DISTRICT
SM267961,-38.9436,-7.7047,Jati,ADM2,DISTRICT
SM271040,-79.1667,21.9333,Jatibonico,ADM2,DISTRICT
SM265014,-38.2141,-9.2191,Jatobá,ADM2,DISTRICT
SM263015,-44.2692,-5.8623,Jatobá,ADM2,DISTRICT
SM265030,-41.8954,-4.803,Jatobá do Piauí,ADM2,DISTRICT
SM254161,-58.73,0.38,Jauapery,ETHNICITY,ETHNICITY
SM15818,-66.3698,17.9677,Jauca 1 Barrio,ADM2,DISTRICT
SM15819,-66.3584,18.0067,Jauca 2 Barrio,ADM2,DISTRICT
SM15820,-66.6274,18.1697,Jauca Barrio,ADM2,DISTRICT
SM254162,46.34,16.46,Jauf,ETHNICITY,ETHNICITY
SM286748,-75.547,-11.726,Jauja,ADM2,DISTRICT
SM233272,-75.2786,-11.6747,Jauja Wanca Quechua,LANGUAGE,LANGUAGE
SM282646,-99.4029,23.4515,Jaumave,ADM2,DISTRICT
SM13556,27.2169,57.4206,Jaunaluksnes pagasts,ADM2,DISTRICT
SM13557,27.1159,57.2801,Jaunannas pagasts,ADM2,DISTRICT
SM13558,22.6604,56.4569,Jaunauces pagasts,ADM2,DISTRICT
SM13559,23.4117,56.7561,Jaunberzes pagasts,ADM2,DISTRICT
SM13560,26.5721,57.0843,Jaungulbenes pagasts,ADM2,DISTRICT
SM13561,25.0819,56.6114,Jaunjelgava,ADM2,DISTRICT
SM4049,25.2958,56.5142,Jaunjelgavas Novads,ADM1,DISTRICT
SM13562,25.0706,56.5946,Jaunjelgavas pagasts,ADM2,DISTRICT
SM13563,26.8856,57.5338,Jaunlaicenes pagasts,ADM2,DISTRICT
SM13564,22.3498,56.8039,Jaunlutrinu pagasts,ADM2,DISTRICT
SM4026,25.9736,57.15,Jaunpiebalgas Novads,ADM1,DISTRICT
SM13565,26.0236,57.1449,Jaunpiebalgas pagasts,ADM2,DISTRICT
SM4019,22.9269,56.7592,Jaunpils Novads,ADM1,DISTRICT
SM13566,22.9654,56.7464,Jaunpils pagasts,ADM2,DISTRICT
SM277300,82.75,25.75,Jaunpur,ADM2,DISTRICT
SM233274,77.9084,30.5587,Jaunsari,LANGUAGE,LANGUAGE
SM13567,22.9151,56.9612,Jaunsatu pagasts,ADM2,DISTRICT
SM13568,23.8919,56.5701,Jaunsvirlaukas pagasts,ADM2,DISTRICT
SM268073,-51.1017,-16.1675,Jaupaci,ADM2,DISTRICT
SM303734,-72,8.1,Jauregui,ADM2,DISTRICT
SM263172,-58.8458,-15.3245,Jauru,ADM2,DISTRICT
SM274780,44.05,42.5,Java,ADM2,DISTRICT
SM233278,109.287,-7.001,Javanese,LANGUAGE,LANGUAGE
SM254163,113.32,-7,Javanese,ETHNICITY,ETHNICITY
SM254163,113.32,-7,Javanese,ETHNICITY,ETHNICITY
SM254163,113.32,-7,Javanese,ETHNICITY,ETHNICITY
SM254163,113.32,-7,Javanese,ETHNICITY,ETHNICITY
SM254163,110.94,-7.65,Javanese,ETHNICITY,ETHNICITY
SM254163,110.94,-7.65,Javanese,ETHNICITY,ETHNICITY
SM254163,110.94,-7.65,Javanese,ETHNICITY,ETHNICITY
SM254163,110.94,-7.65,Javanese,ETHNICITY,ETHNICITY
SM254163,112.217,-7.717,Javanese,ETHNICITY,ETHNICITY
SM254163,112.217,-7.717,Javanese,ETHNICITY,ETHNICITY
SM254163,112.217,-7.717,Javanese,ETHNICITY,ETHNICITY
SM254163,112.217,-7.717,Javanese,ETHNICITY,ETHNICITY
SM254163,110,-7,Javanese,ETHNICITY,ETHNICITY
SM254163,110,-7,Javanese,ETHNICITY,ETHNICITY
SM254163,110,-7,Javanese,ETHNICITY,ETHNICITY
SM254163,110,-7,Javanese,ETHNICITY,ETHNICITY
SM254163,112.22,-7.7,Javanese,ETHNICITY,ETHNICITY
SM254163,112.22,-7.7,Javanese,ETHNICITY,ETHNICITY
SM254163,112.22,-7.7,Javanese,ETHNICITY,ETHNICITY
SM254163,112.22,-7.7,Javanese,ETHNICITY,ETHNICITY
SM233280,110.4198,-7.0329,Javindo,LANGUAGE,LANGUAGE
SM283666,106.3322,49.657,Javkhlant,ADM2,DISTRICT
SM1628,107.5,-6.75,Jawa Barat (2000-),ADM1,DISTRICT
SM1629,110,-7.5,Jawa Tengah,ADM1,DISTRICT
SM1630,112.5099,-7.7394,Jawa Timur,ADM1,DISTRICT
SM254164,30.39,29.11,Jawabi,ETHNICITY,ETHNICITY
SM260100,64.3363,35.0023,Jawand,ADM2,DISTRICT
SM233284,164.676,-20.6648,Jawe,LANGUAGE,LANGUAGE
SM295786,45.5681,2.9934,Jawhar,ADM2,DISTRICT
SM458617,123.2676,-16.4424,Jawi,ETHNICITY,ETHNICITY
SM254167,132.01,-14.04,Jawony,ETHNICITY,ETHNICITY
SM254167,132.01,-14.04,Jawony,ETHNICITY,ETHNICITY
SM254167,133.0385,-13.8973,Jawony,ETHNICITY,ETHNICITY
SM254167,133.0385,-13.8973,Jawony,ETHNICITY,ETHNICITY
SM288674,16.1636,51.0397,Jawor,ADM2,DISTRICT
SM288721,19.3013,50.1891,Jaworzno,ADM2,DISTRICT
SM229716,133.002,-14.1361,Jawoyn,LANGUAGE,LANGUAGE
SM1631,65.8333,36.8333,Jawzjan,ADM1,DISTRICT
SM300722,-85.0056,40.4379,Jay,ADM2,DISTRICT
SM233286,17.9415,12.3707,Jaya,LANGUAGE,LANGUAGE
SM276550,139.95,-3,Jayapura,ADM2,DISTRICT
SM295675,-89.4457,13.6763,Jayaque,ADM2,DISTRICT
SM11649,79.9398,18.1968,Jayashankar Bhupalapally,ADM2,DISTRICT
SM276551,139.0833,-4.0833,Jayawijaya,ADM2,DISTRICT
SM304955,46.1723,14.1662,Jayshan,ADM2,DISTRICT
SM1632,-66.5916,18.2186,Jayuya,ADM1,DISTRICT
SM15821,-66.6116,18.2274,Jayuya Abajo Barrio,ADM2,DISTRICT
SM15822,-66.5926,18.2198,Jayuya Barrio-Pueblo,ADM2,DISTRICT
SM17790,42.6667,16.8667,Jazan,ADM2,DISTRICT
SM265558,-36.194,-6.4167,Jaçanã,ADM2,DISTRICT
SM274037,-3.5,38,Jaén,ADM2,DISTRICT
SM286893,-79.0044,-5.7107,Jaén,ADM2,DISTRICT
SM263556,-43.6703,-15.2165,Jaíba,ADM2,DISTRICT
SM266640,-48.5272,-22.2738,Jaú,ADM2,DISTRICT
SM267248,-48.632,-12.8394,Jaú do Tocantins,ADM2,DISTRICT
SM14052,-8.4108,5.3709,Jeadepo (geonames),ADM2,DISTRICT
SM254168,-80.45,26.78,Jeaga,ETHNICITY,ETHNICITY
SM17438,-61.0158,13.947,Jean Baptiste,ADM2,DISTRICT
SM250327,-5,35,Jebala,ETHNICITY,ETHNICITY
SM298017,10.2021,36.768,Jebel Jelloud,ADM2,DISTRICT
SM250097,32.43,11.54,Jebelawi,ETHNICITY,ETHNICITY
SM297949,10.8981,35.0802,Jebeniana,ADM2,DISTRICT
SM233293,-76.4103,-5.4032,Jebero,LANGUAGE,LANGUAGE
SM17903,29.5,14.5,Jebrat al Sheikh/Sowdari,ADM2,DISTRICT
SM263564,-44.0311,-20.5558,Jeceaba,ADM2,DISTRICT
SM280651,128.1407,37.0621,Jecheon,ADM2,DISTRICT
SM297893,9.9319,36.8541,Jedaida,ADM2,DISTRICT
SM233297,101.75,5.3,Jedek,LANGUAGE,LANGUAGE
SM298120,8.9344,35.6463,Jedeliane,ADM2,DISTRICT
SM14053,-9,5.6667,Jeetrah,ADM2,DISTRICT
SM302665,-104.14,30.7155,Jeff Davis,ADM2,DISTRICT
SM300518,-82.6369,31.8056,Jeff Davis,ADM2,DISTRICT
SM303241,-88.7759,43.0208,Jefferson,ADM2,DISTRICT
SM303118,-77.8627,39.3076,Jefferson,ADM2,DISTRICT
SM303076,-123.5743,47.7766,Jefferson,ADM2,DISTRICT
SM302311,-78.9994,41.1282,Jefferson,ADM2,DISTRICT
SM302199,-97.8358,34.111,Jefferson,ADM2,DISTRICT
SM302256,-121.1754,44.6294,Jefferson,ADM2,DISTRICT
SM302666,-94.1534,29.8537,Jefferson,ADM2,DISTRICT
SM302583,-83.4463,36.051,Jefferson,ADM2,DISTRICT
SM301720,-97.1427,40.1758,Jefferson,ADM2,DISTRICT
SM301882,-76.0521,43.9989,Jefferson,ADM2,DISTRICT
SM302118,-80.761,40.385,Jefferson,ADM2,DISTRICT
SM301568,-90.5377,38.2611,Jefferson,ADM2,DISTRICT
SM301451,-91.0372,31.7342,Jefferson,ADM2,DISTRICT
SM301636,-112.0937,46.1485,Jefferson,ADM2,DISTRICT
SM301060,-85.6592,38.1872,Jefferson,ADM2,DISTRICT
SM300723,-85.4386,38.7858,Jefferson,ADM2,DISTRICT
SM301126,-90.098,29.681,Jefferson,ADM2,DISTRICT
SM300830,-91.9489,41.0318,Jefferson,ADM2,DISTRICT
SM300927,-95.3835,39.2358,Jefferson,ADM2,DISTRICT
SM300338,-83.9005,30.4235,Jefferson,ADM2,DISTRICT
SM300562,-112.3113,43.8201,Jefferson,ADM2,DISTRICT
SM300627,-88.924,38.3005,Jefferson,ADM2,DISTRICT
SM300519,-82.4182,33.0548,Jefferson,ADM2,DISTRICT
SM303342,-105.2505,39.5864,Jefferson,ADM2,DISTRICT
SM300276,-86.8965,33.5543,Jefferson,ADM2,DISTRICT
SM302380,-91.9315,34.2688,Jefferson,ADM2,DISTRICT
SM301452,-89.823,31.5696,Jefferson Davis,ADM2,DISTRICT
SM301127,-92.8141,30.2677,Jefferson Davis,ADM2,DISTRICT
SM12441,-77.0034,18.2896,Jeffery Town,ADM2,DISTRICT
SM284934,4.4864,12.164,Jega,ADM2,DISTRICT
SM289954,27.6429,44.2944,Jegalia,ADM2,DISTRICT
SM1633,21.1453,42.1131,Jegunovtse,ADM1,DISTRICT
SM233304,107.753,15.2351,Jeh,LANGUAGE,LANGUAGE
SM233300,101.541,5.637,Jehai,LANGUAGE,LANGUAGE
SM277407,84.9957,25.207,Jehanabad (2001-),ADM2,DISTRICT
SM458174,145.9813,-35.512,Jeithi,ETHNICITY,ETHNICITY
SM280572,126.5393,33.4558,Jeju,ADM2,DISTRICT
SM1634,126.5,33.4167,Jeju,ADM1,DISTRICT
SM233308,126.5554,33.3805,Jejueo,LANGUAGE,LANGUAGE
SM13569,25.8701,56.5013,Jekabpils,ADM2,DISTRICT
SM4062,25.8692,56.5011,Jekabpils,ADM1,DISTRICT
SM4063,25.8333,56.3333,Jekabpils Municipality,ADM1,DISTRICT
SM288676,15.6384,50.8965,Jelenia Góra,ADM2,DISTRICT
SM288675,15.7063,50.8736,Jelenia Góra (City),ADM2,DISTRICT
SM305407,14.4504,45.3883,Jelenje,ADM2,DISTRICT
SM13570,23.7164,56.6505,Jelgava,ADM2,DISTRICT
SM4067,23.7164,56.6511,Jelgava,ADM1,DISTRICT
SM4068,23.75,56.5833,Jelgavas novads,ADM1,DISTRICT
SM233332,-5.6672,9.3957,Jeli,LANGUAGE,LANGUAGE
SM284414,101.81,5.5876,Jeli,ADM2,DISTRICT
SM297957,9.3961,35.3319,Jelma,ADM2,DISTRICT
SM305462,16.693,43.1609,Jelsa,ADM2,DISTRICT
SM284829,8.2681,9.3827,Jema'a,ADM2,DISTRICT
SM276375,113.65,-8.25,Jember,ADM2,DISTRICT
SM276478,114.6667,-8.3,Jembrana,ADM2,DISTRICT
SM254171,-107.54,35.92,Jemez,ETHNICITY,ETHNICITY
SM254171,-107.54,35.92,Jemez,ETHNICITY,ETHNICITY
SM254171,-107.54,35.92,Jemez,ETHNICITY,ETHNICITY
SM254171,-107,36,Jemez,ETHNICITY,ETHNICITY
SM254171,-107,36,Jemez,ETHNICITY,ETHNICITY
SM254171,-107,36,Jemez,ETHNICITY,ETHNICITY
SM254171,-106.8,35.8,Jemez,ETHNICITY,ETHNICITY
SM254171,-106.8,35.8,Jemez,ETHNICITY,ETHNICITY
SM254171,-106.8,35.8,Jemez,ETHNICITY,ETHNICITY
SM4098,169.524,10.0792,Jemo Island,ADM1,DISTRICT
SM233319,-4,15.5,Jenaama Bozo,LANGUAGE,LANGUAGE
SM1635,8.75,36.6667,Jendouba,ADM1,DISTRICT
SM298095,8.7247,36.5434,Jendouba Nord,ADM2,DISTRICT
SM298096,8.8233,36.4852,Jendouba Sud,ADM2,DISTRICT
SM276591,119.7333,-5.6333,Jeneponto,ADM2,DISTRICT
SM270778,-73.3772,5.377,Jenesano,ADM2,DISTRICT
SM233320,107.147,15.0392,Jeng,DIALECT,LANGUAGE
SM289744,35.3,32.46,Jenin,ADM1:ADM2,DISTRICT
SM263565,-42.2122,-17.1873,Jenipapo de Minas,ADM2,DISTRICT
SM263016,-45.5424,-5.507,Jenipapo dos Vieiras,ADM2,DISTRICT
SM300520,-81.9635,32.7925,Jenkins,ADM2,DISTRICT
SM261787,16.15,46.9667,Jennersdorf,ADM2,DISTRICT
SM300725,-85.6281,38.9969,Jennings,ADM2,DISTRICT
SM233323,76.5767,11.5041,Jennu Kurumba,LANGUAGE,LANGUAGE
SM1636,127.25,35.75,Jeollabuk-do,ADM1,DISTRICT
SM1637,127,34.75,Jeollanam-do (1986-),ADM1,DISTRICT
SM280586,126.9167,35.6,Jeongeup,ADM2,DISTRICT
SM280682,128.73,37.3891,Jeongseon,ADM2,DISTRICT
SM280587,127.15,35.8333,Jeonju,ADM2,DISTRICT
SM276304,110.7667,-6.5833,Jepara,ADM2,DISTRICT
SM263566,-42.6157,-20.4808,Jequeri,ADM2,DISTRICT
SM9949,-36.099,-9.9115,Jequia Da Praia,ADM2,DISTRICT
SM263567,-44.4619,-17.1689,Jequitaí,ADM2,DISTRICT
SM263568,-44.0305,-19.2147,Jequitibá,ADM2,DISTRICT
SM263570,-41.0578,-16.4068,Jequitinhonha,ADM2,DISTRICT
SM267492,-40.0837,-13.923,Jequié,ADM2,DISTRICT
SM281416,-2.0428,34.2366,Jerada,ADM2,DISTRICT
SM284438,102.6667,4.25,Jerantut,ADM2,DISTRICT
SM302507,-98.6297,44.0664,Jerauld,ADM2,DISTRICT
SM13571,25.6937,57.6754,Jercenu pagasts,ADM2,DISTRICT
SM233329,8.9583,10.1962,Jere,LANGUAGE,LANGUAGE
SM285365,13.2916,11.8991,Jere,ADM2,DISTRICT
SM457950,-38.7263,-9.9209,Jeremoabo,ADM2,DISTRICT
SM250328,9.23,11.87,Jerewa,ETHNICITY,ETHNICITY
SM283050,-102.998,22.6867,Jerez,ADM2,DISTRICT
SM275197,-89.7604,14.0795,Jerez,ADM2,DISTRICT
SM289745,35.4455,32.0249,Jericho,ADM1:ADM2,DISTRICT
SM12442,-78.1303,18.4374,Jericho,ADM2,DISTRICT
SM270229,-75.761,5.7805,Jericó,ADM2,DISTRICT
SM270779,-72.586,6.1458,Jericó,ADM2,DISTRICT
SM264437,-37.8067,-6.5075,Jericó,ADM2,DISTRICT
SM233333,92.4686,27.3376,Jerigaon,DIALECT,LANGUAGE
SM266641,-47.5712,-20.3346,Jeriquara,ADM2,DISTRICT
SM297872,8.6546,35.8338,Jerissa,ADM2,DISTRICT
SM300563,-114.264,42.6899,Jerome,ADM2,DISTRICT
SM261390,119.1137,-34.0305,Jerramungup,ADM2,DISTRICT
SM233334,-2.1325,49.2123,Jerriais,DIALECT,LANGUAGE
SM300628,-90.3567,39.0857,Jersey,ADM2,DISTRICT
SM13572,26.2443,56.2718,Jersikas pagasts,ADM2,DISTRICT
SM13573,25.3378,57.8374,Jeru pagasts,ADM2,DISTRICT
SM265031,-43.5468,-7.0989,Jerumenha,ADM2,DISTRICT
SM233336,86.2547,27.1741,Jerung,LANGUAGE,LANGUAGE
SM289746,35.3,31.8,Jerusalem,ADM1:ADM2,DISTRICT
SM12443,-78.2156,18.3246,Jerusalem,ADM2,DISTRICT
SM1638,35.0333,31.75,Jerusalem,ADM1:ADM2,DISTRICT
SM1638,35.0333,31.75,Jerusalem,ADM1:ADM2,DISTRICT
SM1638,35,31.75,Jerusalem,ADM1:ADM2,DISTRICT
SM1638,35,31.75,Jerusalem,ADM1:ADM2,DISTRICT
SM295693,-88.9097,13.6576,Jerusalén,ADM2,DISTRICT
SM270012,-74.7094,4.5568,Jerusalén,ADM2,DISTRICT
SM1639,150.7204,-35.1444,Jervis Bay Territory,ADM1,DISTRICT
SM281543,-100.5069,20.1993,Jerécuaro,ADM2,DISTRICT
SM267999,-41.3954,-20.8124,Jerônimo Monteiro,ADM2,DISTRICT
SM296204,14.0725,46.4525,Jesenice,ADM1:ADM2,DISTRICT
SM305750,15.8819,46.2072,Jesenje,ADM2,DISTRICT
SM271236,17.1667,50.25,Jeseník,ADM2,DISTRICT
SM301061,-84.5809,37.872,Jessamine,ADM2,DISTRICT
SM262261,89.1667,23.0833,Jessore,ADM2,DISTRICT
SM233339,-76.5777,-4.4446,Jesuit-Maynas,DIALECT,LANGUAGE
SM14306,-94.9721,17.4411,Jesus Carranza (1997-),ADM2,DISTRICT
SM263571,-45.2824,-22.0085,Jesuânia,ADM2,DISTRICT
SM264534,-53.4134,-24.413,Jesuítas,ADM2,DISTRICT
SM268074,-49.3946,-15.9646,Jesúpolis,ADM2,DISTRICT
SM289569,-55.7742,-27.0536,Jesús,ADM2,DISTRICT
SM303814,-71.8365,10.6206,Jesús Enrique Lossada,ADM2,DISTRICT
SM281811,-102.1342,20.6252,Jesús María,ADM2,DISTRICT
SM281491,-102.35,21.9667,Jesús María,ADM2,DISTRICT
SM270478,-73.81,5.8545,Jesús María,ADM2,DISTRICT
SM303815,-72.5197,8.7449,Jesús María Semprún,ADM2,DISTRICT
SM270994,-76.4775,21.1633,Jesús Menéndez,ADM2,DISTRICT
SM275671,-88.0112,14.527,Jesús de Otoro,ADM2,DISTRICT
SM280652,127.5986,36.7838,Jeungpyeong,ADM2,DISTRICT
SM286123,10.3859,60.2406,Jevnaker,ADM2,DISTRICT
SM461488,44.7833,41.7167,Jew Georgian,ETHNICITY,ETHNICITY
SM461492,44.422,33.325,Jew Iraqi,ETHNICITY,ETHNICITY
SM461532,28.95,41.0167,Jew Turkish,ETHNICITY,ETHNICITY
SM300928,-98.2183,39.7847,Jewell,ADM2,DISTRICT
SM233341,44.245,32.273,Jewish Babylonian Aramaic (ca. 200-1200 CE),LANGUAGE,LANGUAGE
SM254176,76.28,9.97,Jewish-India,ETHNICITY,ETHNICITY
SM306077,-6.84,34.02,Jewish-Morocco,ETHNICITY,ETHNICITY
SM306078,10.1833,36.8,Jewish-Tunisia,ETHNICITY,ETHNICITY
SM454474,-74.133,40.133,"Jews, Hasidim",ETHNICITY,ETHNICITY
SM313199,17.1742,44.3169,Jezero,ADM2:ADM3,DISTRICT
SM296205,14.4835,46.3892,Jezersko,ADM1:ADM2,DISTRICT
SM276985,74.6718,22.8906,Jhabua (2008-),ADM2,DISTRICT
SM276862,76.6494,28.6048,Jhajjar,ADM2,DISTRICT
SM375387,67.543,28.3688,Jhal Magsi,ADM2:ADM3,DISTRICT
SM277175,76.25,24.4167,Jhalawar,ADM2,DISTRICT
SM262227,90.2,22.6,Jhalokati,ADM2,DISTRICT
SM4949,72.355,31.328,Jhang (2009-),ADM2:ADM3,DISTRICT
SM233345,82.9,28.9333,Jhankot Sign Language,LANGUAGE,LANGUAGE
SM277302,78.5,25.5,Jhansi,ADM2,DISTRICT
SM375319,87.8857,26.5837,Jhapa,ADM2:ADM3,DISTRICT
SM11650,86.9945,22.4518,Jhargram,ADM2,DISTRICT
SM1641,85.5,23.75,Jharkhand,ADM1,DISTRICT
SM277112,84,21.85,Jharsuguda,ADM2,DISTRICT
SM375474,73.356,32.8205,Jhelum,ADM2:ADM3,DISTRICT
SM262262,89.1667,23.5,Jhenaidah,ADM2,DISTRICT
SM277176,75.5,28,Jhunjhunun,ADM2,DISTRICT
SM269226,114.8028,27.038,Ji'an,ADM2,DISTRICT
SM266088,-61.9601,-10.834,Ji-Paraná,ADM2,DISTRICT
SM233351,109.854,18.5548,Jiamao,LANGUAGE,LANGUAGE
SM269156,132.0441,47.0216,Jiamusi,ADM2,DISTRICT
SM233353,118.3074,27.0424,Jian'ou,DIALECT,LANGUAGE
SM291083,22.7108,44.3916,Jiana,ADM2,DISTRICT
SM233352,99.91,26.5381,Jianchuan,DIALECT,LANGUAGE
SM269434,112.66,22.3152,Jiangmen,ADM2,DISTRICT
SM236532,118.3206,28.563,Jiangshan,DIALECT,LANGUAGE
SM1642,119.8333,33,Jiangsu,ADM1,DISTRICT
SM1643,115.6667,27.6667,Jiangxi,ADM1,DISTRICT
SM269171,113.1439,35.1375,Jiaozuo,ADM2,DISTRICT
SM269386,120.7524,30.7707,Jiaxing,ADM2,DISTRICT
SM269410,98.2323,39.8398,Jiayuguan,ADM2,DISTRICT
SM233363,11.5099,9.2651,Jiba,LANGUAGE,LANGUAGE
SM254195,42.07,-0.47,Jibana,ETHNICITY,ETHNICITY
SM244342,53.9953,17.2915,Jibbali,LANGUAGE,LANGUAGE
SM292634,25.0736,46.0017,Jibert,ADM2,DISTRICT
SM284904,7.2891,13.0403,Jibia,ADM2,DISTRICT
SM304873,44.1213,13.9148,Jiblah,ADM2,DISTRICT
SM291614,23.2549,47.26,Jibou,ADM2,DISTRICT
SM254196,42.78,39.72,Jibranli,ETHNICITY,ETHNICITY
SM233365,11.3086,7.4921,Jibu,LANGUAGE,LANGUAGE
SM250330,11,8,Jibu,ETHNICITY,ETHNICITY
SM233368,9.1836,9.0602,Jibyal,DIALECT,LANGUAGE
SM295676,-89.5069,13.5309,Jicalapa,ADM2,DISTRICT
SM233369,-107.007,36.8079,Jicarilla Apache,LANGUAGE,LANGUAGE
SM290106,23.7679,47.1203,Jichisu De Jos,ADM2,DISTRICT
SM17791,39.2,21.6333,Jiddah,ADM2,DISTRICT
SM289803,24.1148,46.2308,Jidvei,ADM2,DISTRICT
SM250331,34.36,3.4,Jie,ETHNICITY,ETHNICITY
SM250331,34.36,3.4,Jie,ETHNICITY,ETHNICITY
SM250331,34,3,Jie,ETHNICITY,ETHNICITY
SM250331,34,3,Jie,ETHNICITY,ETHNICITY
SM269435,116.1219,23.3128,Jieyang,ADM2,DISTRICT
SM1644,9.56,12.1,Jigawa,ADM1,DISTRICT
SM271111,-76.3861,20.4223,Jiguaní,ADM2,DISTRICT
SM304936,44.5195,15.1919,Jihanah,ADM2,DISTRICT
SM271217,15.5833,49.3333,Jihlava,ADM2,DISTRICT
SM1646,16.6134,49.2012,Jihomoravský,ADM1,DISTRICT
SM1645,14.5,49.0833,Jihočeský,ADM1,DISTRICT
SM233374,44.1294,1.8435,Jiiddu,LANGUAGE,LANGUAGE
SM1647,6,36.75,Jijel,ADM1,DISTRICT
SM457547,5.75,36.75,Jijel District,ADM2,DISTRICT
SM254198,29.84,-5.04,Jiji,ETHNICITY,ETHNICITY
SM292052,28.1571,45.3255,Jijila,ADM2,DISTRICT
SM267962,-40.4916,-2.8714,Jijoca de Jericoacoara,ADM2,DISTRICT
SM290963,26.0775,44.3286,Jilava,ADM2,DISTRICT
SM290918,26.5248,44.7752,Jilavele,ADM2,DISTRICT
SM233377,14.5794,11.7992,Jilbe,LANGUAGE,LANGUAGE
SM295836,43.0566,0.6447,Jilib,ADM2,DISTRICT
SM305968,68.4744,37.5544,Jilikul,ADM2,DISTRICT
SM233378,145.706,-5.5661,Jilim,LANGUAGE,LANGUAGE
SM269236,126.9056,43.6861,Jilin,ADM2,DISTRICT
SM1648,126.1667,43.5833,Jilin,ADM1,DISTRICT
SM282919,-96.9224,19.6113,Jilotepec,ADM2,DISTRICT
SM281927,-99.5918,20.0072,Jilotepec,ADM2,DISTRICT
SM281812,-102.9191,19.3312,Jilotlán de los Dolores,ADM2,DISTRICT
SM281928,-99.3843,19.5218,Jilotzingo,ADM2,DISTRICT
SM302667,-98.6973,27.0434,Jim Hogg,ADM2,DISTRICT
SM302668,-98.0899,27.7313,Jim Wells,ADM2,DISTRICT
SM271789,-70.3955,19.1289,Jima Abajo,ADM2,DISTRICT
SM270988,-77.8244,21.2427,Jimaguayú,ADM2,DISTRICT
SM271770,-71.8333,18.4667,Jimaní,ADM2,DISTRICT
SM291991,20.72,45.7923,Jimbolia,ADM2,DISTRICT
SM15823,-65.8064,18.3373,Jimenez Barrio,ADM2,DISTRICT
SM288567,144.5667,-5.5333,Jimi,ADM2,DISTRICT
SM233384,13.5258,10.251,Jimi (Cameroon),LANGUAGE,LANGUAGE
SM233385,9.8118,10.7299,Jimi (Nigeria),LANGUAGE,LANGUAGE
SM274374,37,7.6667,Jimma,ADM2,DISTRICT
SM254199,37,8,Jimma,ETHNICITY,ETHNICITY
SM303606,-69.5833,9.8,Jiménez,ADM2,DISTRICT
SM282647,-98.4966,24.2626,Jiménez,ADM2,DISTRICT
SM282037,-101.729,19.9239,Jiménez,ADM2,DISTRICT
SM283239,-104.7287,27.1273,Jiménez,ADM2,DISTRICT
SM283282,-100.8829,29.0629,Jiménez,ADM2,DISTRICT
SM270916,-83.6833,9.75,Jiménez,ADM2,DISTRICT
SM261040,-64.4167,-27,Jiménez,ADM2,DISTRICT
SM283051,-103.8619,23.1765,Jiménez del Teul,ADM2,DISTRICT
SM233389,14.9611,11.3555,Jina,LANGUAGE,LANGUAGE
SM291725,23.6779,45.7859,Jina,ADM2,DISTRICT
SM280575,127.4758,35.8047,Jinan,ADM2,DISTRICT
SM269303,117.1232,36.6476,Jinan,ADM2,DISTRICT
SM233390,116.9973,36.6908,Jinan Mandarin,DIALECT,LANGUAGE
SM269411,102.0584,38.4497,Jinchang,ADM2,DISTRICT
SM269313,112.6926,35.6036,Jincheng,ADM2,DISTRICT
SM280642,127.4277,36.8828,Jincheon,ADM2,DISTRICT
SM276863,76.25,29.5,Jind,ADM2,DISTRICT
SM280592,126.226,34.4196,Jindo,ADM2,DISTRICT
SM271149,15.1667,49.0833,Jindřichův Hradec,ADM2,DISTRICT
SM269227,117.2469,29.3168,Jingdezhen,ADM2,DISTRICT
SM269190,112.6431,31.1124,Jingmen,ADM2,DISTRICT
SM229727,133.659,-17.165,Jingulu,LANGUAGE,LANGUAGE
SM269191,112.5068,30.0041,Jingzhou,ADM2,DISTRICT
SM269387,120,29.1078,Jinhua,ADM2,DISTRICT
SM269304,116.7339,35.4803,Jining,ADM2,DISTRICT
SM1649,33.315,0.511,Jinja,ADM1:ADM2,DISTRICT
SM280545,128.1241,35.2045,Jinju,ADM2,DISTRICT
SM241182,98.8862,26.424,Jinman Bai,DIALECT,LANGUAGE
SM285540,-86,13.1,Jinotega,ADM2,DISTRICT
SM1650,-85.5833,13.75,Jinotega,ADM1,DISTRICT
SM285505,-86.233,11.7497,Jinotepe,ADM2,DISTRICT
SM278124,133.2619,34.7571,Jinsekikōgen,ADM2,DISTRICT
SM283751,100.574,45.4016,Jinst,ADM2,DISTRICT
SM233403,111.278,38.8284,Jinyu Chinese,LANGUAGE,LANGUAGE
SM269314,113.0878,37.436,Jinzhong,ADM2,DISTRICT
SM269254,121.6153,41.4944,Jinzhou,ADM2,DISTRICT
SM233405,110.007,23.8692,Jiongnai Bunu,LANGUAGE,LANGUAGE
SM273511,-80.5833,-1.35,Jipijapa,ADM2,DISTRICT
SM295595,-88.6493,13.3275,Jiquilisco,ADM2,DISTRICT
SM282038,-102.751,19.9847,Jiquilpan,ADM2,DISTRICT
SM283150,-93.6418,16.5695,Jiquipilas,ADM2,DISTRICT
SM281929,-99.6469,19.5821,Jiquipilco,ADM2,DISTRICT
SM321795,-39.5809,-13.3108,Jiquiriçá,ADM2:ADM3,DISTRICT
SM233413,141.83,-14.83,Jir'jorond,DIALECT:LANGUAGE,LANGUAGE
SM254205,-69,10.22,Jirajara,ETHNICITY,ETHNICITY
SM233407,-69.7,10.57,Jirajaran,LANGUAGE,LANGUAGE
SM11403,-2.7,10.5333,Jirapa,ADM2,DISTRICT
SM233411,86.3201,27.6931,Jirel,LANGUAGE,LANGUAGE
SM305993,71.5763,39.291,Jirgatol,ADM2,DISTRICT
SM11651,93.1209,24.8044,Jiribam,ADM2,DISTRICT
SM273887,31.8457,26.3068,Jirja,ADM2,DISTRICT
SM292679,27.209,45.1531,Jirlau,ADM2,DISTRICT
SM459224,146.0514,-17.6123,Jirribarra Mamu,ETHNICITY,ETHNICITY
SM233415,10.5872,8.6413,Jiru,LANGUAGE,LANGUAGE
SM296736,36.329,35.8776,Jisr ash-Shugur,ADM2,DISTRICT
SM233419,33.9141,-1.6268,Jita,LANGUAGE,LANGUAGE
SM250927,33.75,-1.91,Jita,ETHNICITY,ETHNICITY
SM267495,-39.9006,-13.979,Jitaúna,ADM2,DISTRICT
SM292350,26.7488,45.5848,Jitia,ADM2,DISTRICT
SM283151,-92.8377,17.0961,Jitotol,ADM2,DISTRICT
SM233420,-92.8584,17.0545,Jitotolteco,LANGUAGE,LANGUAGE
SM269228,115.3653,29.3854,Jiujiang,ADM2,DISTRICT
SM269412,96.6648,40.6488,Jiuquan,ADM2,DISTRICT
SM282100,-99.1503,18.8783,Jiutepec,ADM2,DISTRICT
SM1651,144.6728,-5.9022,Jiwaka,ADM1,DISTRICT
SM233422,118.5922,30.0711,Jixi,DIALECT,LANGUAGE
SM269157,132.4615,45.658,Jixi,ADM2,DISTRICT
SM458630,130.5394,-17.2171,Jiyil,ETHNICITY,ETHNICITY
SM1652,42.6667,17.3333,Jizan,ADM1,DISTRICT
SM277983,36.3375,31.5064,Jizeh,ADM2,DISTRICT
SM233424,110.868,21.388,Jizhao,LANGUAGE,LANGUAGE
SM1653,67.6667,40.3333,Jizzakh,ADM1,DISTRICT
SM18401,67.8422,40.1158,Jizzax Shahri,ADM2,DISTRICT
SM18402,67.8333,40.0833,Jizzax Tumani,ADM2,DISTRICT
SM271222,15.4167,50.4167,Jičín,ADM2,DISTRICT
SM233425,7.9717,9.8312,Jju,LANGUAGE,LANGUAGE
SM300629,-90.2124,42.3657,Jo Daviess,ADM2,DISTRICT
SM263573,-42.7067,-19.2032,Joanésia,ADM2,DISTRICT
SM266642,-46.211,-22.9346,Joanópolis,ADM2,DISTRICT
SM263576,-44.0937,-17.6232,Joaquim Felício,ADM2,DISTRICT
SM265374,-35.7374,-9.1035,Joaquim Gomes,ADM2,DISTRICT
SM265017,-35.5317,-8.5283,Joaquim Nabuco,ADM2,DISTRICT
SM265033,-42.0775,-3.5369,Joaquim Pires,ADM2,DISTRICT
SM264535,-49.9025,-23.4222,Joaquim Távora,ADM2,DISTRICT
SM18334,-56.0384,-34.7352,Joaquin Suarez,ADM2,DISTRICT
SM233427,-64.67,-13.4,Joaquiniano,LANGUAGE,LANGUAGE
SM295719,-88.0623,13.9062,Joateca,ADM2,DISTRICT
SM266181,-51.592,-27.1537,Joaçaba,ADM2,DISTRICT
SM263572,-41.0111,-16.8038,Joaíma,ADM2,DISTRICT
SM233428,28.9978,-3.4579,Joba,LANGUAGE,LANGUAGE
SM270995,-77.2831,20.9078,Jobabo,ADM2,DISTRICT
SM15825,-66.1805,17.9526,Jobos Barrio (Guayama),ADM2,DISTRICT
SM15824,-67.0697,18.4978,Jobos Barrio (Isabela),ADM2,DISTRICT
SM265034,-42.4358,-3.5421,Joca Marques,ADM2,DISTRICT
SM295720,-88.15,13.9667,Jocoaitique,ADM2,DISTRICT
SM254207,-108.82,33.05,Jocome,ETHNICITY,ETHNICITY
SM295721,-88.0077,13.6172,Jocoro,ADM2,DISTRICT
SM275274,-90.7358,14.5866,Jocotenango,ADM2,DISTRICT
SM281813,-103.4159,20.2906,Jocotepec,ADM2,DISTRICT
SM281931,-99.8019,19.7086,Jocotitlán,ADM2,DISTRICT
SM275412,-89.3804,14.7745,Jocotán,ADM2,DISTRICT
SM275807,-86.9535,15.299,Jocón,ADM2,DISTRICT
SM277177,72.75,26.75,Jodhpur,ADM2,DISTRICT
SM305101,27.0605,-30.9418,Joe Gqabi,ADM2,DISTRICT
SM12444,-77.4962,18.2674,Joe Hut,ADM2,DISTRICT
SM11219,29.9237,62.7916,Joensuu (2007-),ADM2,DISTRICT
SM370201,-9.4537,6.0496,Joewein Gbuizon,ADM2:ADM3,DISTRICT
SM233433,139.2969,-2.2478,Jofotek-Bromnya,LANGUAGE,LANGUAGE
SM11167,26.3812,58.7873,Jogeva vald,ADM2,DISTRICT
SM254213,137.72,-16.87,Jogula,ETHNICITY,ETHNICITY
SM254213,137.72,-16.87,Jogula,ETHNICITY,ETHNICITY
SM254213,138.7513,-17.3412,Jogula,ETHNICITY,ETHNICITY
SM254213,138.7513,-17.3412,Jogula,ETHNICITY,ETHNICITY
SM11652,77.8056,16.234,Jogulamba Gadwal,ADM2,DISTRICT
SM296070,-56.3406,5.8773,Johanna Maria,ADM2,DISTRICT
SM17439,-60.9911,14.0191,John Compton Highway,ADM2,DISTRICT
SM305138,23.0471,-27.0455,John Taolo Gaetsewe,ADM2,DISTRICT
SM12445,-77.8501,18.4285,John's Hall,ADM2,DISTRICT
SM12446,-77.3806,18.1888,Johns Hall,ADM2,DISTRICT
SM12447,-76.3614,17.8966,Johns Town,ADM2,DISTRICT
SM303296,-106.5846,44.0388,Johnson,ADM2,DISTRICT
SM302584,-81.8518,36.4549,Johnson,ADM2,DISTRICT
SM302669,-97.3663,32.379,Johnson,ADM2,DISTRICT
SM301721,-96.2651,40.3926,Johnson,ADM2,DISTRICT
SM301569,-93.8064,38.7441,Johnson,ADM2,DISTRICT
SM300831,-91.5881,41.6716,Johnson,ADM2,DISTRICT
SM300929,-94.8223,38.8838,Johnson,ADM2,DISTRICT
SM301062,-82.8315,37.8466,Johnson,ADM2,DISTRICT
SM300726,-86.1016,39.49,Johnson,ADM2,DISTRICT
SM300630,-88.8809,37.4596,Johnson,ADM2,DISTRICT
SM300521,-82.6601,32.7015,Johnson,ADM2,DISTRICT
SM302381,-93.4599,35.5701,Johnson,ADM2,DISTRICT
SM12448,-76.3199,17.9809,Johnson Mountain,ADM2,DISTRICT
SM12449,-76.7559,17.9777,Johnson Town,ADM2,DISTRICT
SM302200,-96.6607,34.3165,Johnston,ADM2,DISTRICT
SM301977,-78.3656,35.5176,Johnston,ADM2,DISTRICT
SM1655,-169.5275,16.7339,Johnston,ADM0:ADM1,DISTRICT
SM1656,103.5,2,Johor,ADM1,DISTRICT
SM284312,103.6,1.65,Johor Baharu,ADM2,DISTRICT
SM266182,-48.9987,-26.2659,Joinvile,ADM2,DISTRICT
SM282101,-99.2213,18.59,Jojutla,ADM2,DISTRICT
SM275058,-16.1833,13.5333,Jokadu,ADM2,DISTRICT
SM296400,18.5052,66.9097,Jokkmokk,ADM2,DISTRICT
SM233440,-16.5539,12.5089,Jola-Esulalu,LANGUAGE,LANGUAGE
SM233441,-15.7355,12.7575,Jola-Fonyi,LANGUAGE,LANGUAGE
SM282433,-98.897,18.3284,Jolalpan,ADM2,DISTRICT
SM228849,126.84,35.14,Jollado,DIALECT,LANGUAGE
SM1657,19.9167,60.1667,Jomala,ADM1:ADM2,DISTRICT
SM276376,112.25,-7.55,Jombang,ADM2,DISTRICT
SM303415,67.1667,39.75,Jomboy,ADM2,DISTRICT
SM305969,68.8143,38.0512,Jomi,ADM2,DISTRICT
SM274865,-2.62,5.133,Jomoro,ADM2,DISTRICT
SM282102,-98.8057,18.6648,Jonacatepec,ADM2,DISTRICT
SM299372,31.5007,2.464,Jonam,ADM1:ADM2,DISTRICT
SM281328,24.2833,55.0833,Jonavos,ADM2,DISTRICT
SM303464,63.75,40,Jondor,ADM2,DISTRICT
SM302670,-99.8787,32.7399,Jones,ADM2,DISTRICT
SM302508,-100.6897,43.9606,Jones,ADM2,DISTRICT
SM301978,-77.3553,35.0217,Jones,ADM2,DISTRICT
SM301453,-89.1688,31.6226,Jones,ADM2,DISTRICT
SM300832,-91.1314,42.1212,Jones,ADM2,DISTRICT
SM300522,-83.5605,33.0251,Jones,ADM2,DISTRICT
SM12450,-76.5477,17.9507,Jones Pen,ADM2,DISTRICT
SM12451,-76.7969,17.9842,Jones Town,ADM2,DISTRICT
SM305889,125.7483,39.02,Jong,ADM2,DISTRICT
SM17887,32,7.5,Jonglei (excl. Wat & Pibor),ADM2,DISTRICT
SM254215,30.7,2.03,Jongn,ETHNICITY,ETHNICITY
SM280615,126.9793,37.5729,Jongro,ADM2,DISTRICT
SM281349,23.6167,56.2333,Joniškio,ADM2,DISTRICT
SM233448,19.0954,11.5034,Jonkor Bourmataguil,LANGUAGE,LANGUAGE
SM282434,-97.5656,20.0442,Jonotla,ADM2,DISTRICT
SM282622,-92.1231,18.0548,Jonuta,ADM2,DISTRICT
SM261391,115.765,-31.7796,Joondalup,ADM2,DISTRICT
SM282435,-97.7834,20.2194,Jopala,ADM2,DISTRICT
SM281932,-99.5252,19.0672,Joquicingo,ADM2,DISTRICT
SM290448,27.8722,45.9854,Jorasti,ADM2,DISTRICT
SM254216,37,31.5,Jordan-Arab,ETHNICITY,ETHNICITY
SM254218,36,32,Jordanian,ETHNICITY,ETHNICITY
SM254218,36,32,Jordanian,ETHNICITY,ETHNICITY
SM254218,35.9143,32.0546,Jordanian,ETHNICITY,ETHNICITY
SM254218,35.9143,32.0546,Jordanian,ETHNICITY,ETHNICITY
SM233453,35.8585,32.5403,Jordanian Bengali Pidgin Arabic,LANGUAGE,LANGUAGE
SM270479,-73.1097,6.6986,Jordán,ADM2,DISTRICT
SM263577,-40.3053,-15.8749,Jordânia,ADM2,DISTRICT
SM262802,-71.8407,-9.0917,Jordão,ADM2,DISTRICT
SM286846,-70.7308,-17.5792,Jorge Basadre,ADM2,DISTRICT
SM11653,94.26,26.8,Jorhat (2016-),ADM2,DISTRICT
SM280954,-9.4364,6.9221,Jorquelleh,ADM2,DISTRICT
SM280954,-9.4364,6.9221,Jorquelleh,ADM2,DISTRICT
SM280954,-9.4325,6.9113,Jorquelleh,ADM2,DISTRICT
SM280954,-9.4325,6.9113,Jorquelleh,ADM2,DISTRICT
SM233454,9.4249,8.9306,Jorto,LANGUAGE,LANGUAGE
SM233451,-63.2094,-15.7492,Jorá,LANGUAGE,LANGUAGE
SM285190,9.1179,9.8911,Jos East,ADM2,DISTRICT
SM285191,8.9022,9.9394,Jos North,ADM2,DISTRICT
SM285192,8.8352,9.755,Jos South,ADM2,DISTRICT
SM289548,-56.0939,-25.9285,Jose A. Fassardi,ADM2,DISTRICT
SM300224,-55.0621,-33.5275,Jose Batlle Y Ordoñez,ADM2,DISTRICT
SM15343,-58.1241,-24.9097,Jose Falcon,ADM2,DISTRICT
SM14307,-98.9996,17.4408,Jose Joaquin de Herrera,ADM2,DISTRICT
SM9834,-67.7266,-16.1897,Jose Manuel Pando,ADM2,DISTRICT
SM263018,-44.7316,-5.0075,Joselândia,ADM2,DISTRICT
SM290693,25.4611,46.6953,Joseni,ADM2,DISTRICT
SM292498,24.6635,47.2124,Josenii Bargaului,ADM2,DISTRICT
SM263581,-42.517,-16.5531,Josenópolis,ADM2,DISTRICT
SM302257,-123.5556,42.3655,Josephine,ADM2,DISTRICT
SM305702,15.2887,45.1939,Josipdol,ADM2,DISTRICT
SM303836,-67.5118,10.18,José Angel Lamas,ADM2,DISTRICT
SM303791,-69.0364,10.1211,José Antonio Páez,ADM2,DISTRICT
SM282920,-95.7147,18.0962,José Azueta,ADM2,DISTRICT
SM281612,-101.4215,17.8135,José Azueta,ADM2,DISTRICT
SM262711,-66.9746,-14.015,José Ballivián,ADM2,DISTRICT
SM266183,-49.6462,-26.8385,José Boiteux,ADM2,DISTRICT
SM266645,-49.7729,-21.0899,José Bonifácio,ADM2,DISTRICT
SM289696,-55.4376,-25.4404,José Domingo Ocampos,ADM2,DISTRICT
SM303777,-70.6145,9.7712,José Felipe Márquez Cañizalez,ADM2,DISTRICT
SM303837,-67.3302,10.2184,José Félix Ribas,ADM2,DISTRICT
SM263578,-42.6691,-16.905,José Gonçalves de Minas,ADM2,DISTRICT
SM289570,-56.2291,-26.7478,José Leandro Oviedo,ADM2,DISTRICT
SM262780,-65.0787,-21.8072,José María Avilés,ADM2,DISTRICT
SM262761,-65.3461,-19.8262,José María Linares,ADM2,DISTRICT
SM282470,-88.7761,19.7491,José María Morelos,ADM2,DISTRICT
SM303735,-72.0567,8.0351,José María Vargas,ADM2,DISTRICT
SM303838,-67.2714,10.2372,José R Revenga,ADM2,DISTRICT
SM263579,-42.4653,-18.2637,José Raydan,ADM2,DISTRICT
SM275676,-86.3063,16.4176,José Santos Guardiola,ADM2,DISTRICT
SM282039,-101.5643,20.2751,José Sixto Verduzco,ADM2,DISTRICT
SM265569,-38.3203,-6.3287,José da Penha,ADM2,DISTRICT
SM265035,-42.6137,-4.7299,José de Freitas,ADM2,DISTRICT
SM298065,9.4213,36.8861,Joumine,ADM2,DISTRICT
SM233459,-109,28.5,Jova,LANGUAGE,LANGUAGE
SM254223,-108.28,29.69,Jova,ETHNICITY,ETHNICITY
SM271009,-81.2,22.8,Jovellanos,ADM2,DISTRICT
SM11776,57.5167,36.5833,Joveyn,ADM2,DISTRICT
SM268075,-49.5844,-17.7842,Joviânia,ADM2,DISTRICT
SM233461,51.4,33.99,Jowshaqani,DIALECT,LANGUAGE
SM233462,-5.4787,10.7691,Jowulu,LANGUAGE,LANGUAGE
SM14051,-10.1667,6.1667,Joya Chiefdom,ADM2,DISTRICT
SM275254,-90.8208,14.9807,Joyabaj,ADM2,DISTRICT
SM17440,-60.9607,13.7831,Joyeux,ADM2,DISTRICT
SM262269,89.1,25.0833,Joypurhat,ADM2,DISTRICT
SM265016,-35.5891,-7.8666,João Alfredo,ADM2,DISTRICT
SM265032,-42.4151,-8.5432,João Costa,ADM2,DISTRICT
SM265567,-35.815,-5.5608,João Câmara,ADM2,DISTRICT
SM265568,-37.7937,-6.2851,João Dias,ADM2,DISTRICT
SM267496,-41.4044,-11.1488,João Dourado,ADM2,DISTRICT
SM263017,-47.1826,-5.2272,João Lisboa,ADM2,DISTRICT
SM263574,-43.1556,-19.8386,João Monlevade,ADM2,DISTRICT
SM268000,-40.4376,-19.7253,João Neiva,ADM2,DISTRICT
SM264438,-34.8654,-7.1709,João Pessoa,ADM2,DISTRICT
SM263575,-45.9731,-17.5573,João Pinheiro,ADM2,DISTRICT
SM266644,-50.7875,-22.2444,João Ramalho,ADM2,DISTRICT
SM233524,9.8118,10.1845,Ju,LANGUAGE,LANGUAGE
SM458089,119.4554,-22.0008,Ju'una,ETHNICITY,ETHNICITY
SM302896,-112.7848,39.7026,Juab,ADM2,DISTRICT
SM4685,-2.8333,6.3333,Juaboso (2012-),ADM2,DISTRICT
SM283052,-103.3294,24.2606,Juan Aldama,ADM2,DISTRICT
SM15826,-67.0969,18.1922,Juan Alonso Barrio,ADM2,DISTRICT
SM15827,-66.163,18.2636,Juan Asencio Barrio,ADM2,DISTRICT
SM289732,-57.4339,-25.4327,Juan Augusto Saldívar,ADM2,DISTRICT
SM261079,-65.8278,-27.5948,Juan B. Alberdi,ADM2,DISTRICT
SM282437,-98.3409,19.121,Juan C. Bonilla,ADM2,DISTRICT
SM289668,-55.3651,-25.4801,Juan Emilio O'Leary,ADM2,DISTRICT
SM261041,-62.5,-28,Juan Felipe Ibarra,ADM2,DISTRICT
SM275944,-84.9099,15.9168,Juan Francisco Bulnes,ADM2,DISTRICT
SM282438,-97.9908,20.2198,Juan Galindo,ADM2,DISTRICT
SM15828,-66.7239,18.1881,Juan Gonzalez Barrio,ADM2,DISTRICT
SM303874,-68.2003,10.4889,Juan José Mora,ADM2,DISTRICT
SM15829,-65.6848,18.3511,Juan Martin Barrio (Luquillo),ADM2,DISTRICT
SM15830,-65.8604,18.0506,Juan Martin Barrio (Yabucoa),ADM2,DISTRICT
SM282439,-97.7483,18.5241,Juan N. Méndez,ADM2,DISTRICT
SM281614,-99.5087,17.1334,Juan R. Escudero,ADM2,DISTRICT
SM282922,-95.3786,18.0012,Juan Rodríguez Clara,ADM2,DISTRICT
SM15831,-66.1381,18.4025,Juan Sanchez Barrio,ADM2,DISTRICT
SM271781,-71.6071,18.7328,Juan Santiago,ADM2,DISTRICT
SM303778,-70.0604,9.3935,Juan Vicente Campos Elías,ADM2,DISTRICT
SM270677,-75.0744,10.8295,Juan de Acosta,ADM2,DISTRICT
SM271844,-71.2027,18.8744,Juan de Herrera,ADM2,DISTRICT
SM289528,-56.7729,-24.995,Juan de Mena,ADM2,DISTRICT
SM11282,42.7271,-17.0558,Juan de Nova Island,ADM2,DISTRICT
SM262690,-64.4593,-20.0002,Juana Azurduay de Padilla,ADM2,DISTRICT
SM15832,-66.5066,18.0512,Juana Diaz Barrio-Pueblo,ADM2,DISTRICT
SM1660,-66.4927,18.048,Juana Díaz,ADM1,DISTRICT
SM281814,-103.1494,20.4987,Juanacatlán,ADM2,DISTRICT
SM233466,85.5845,21.2606,Juang,LANGUAGE,LANGUAGE
SM254226,85.07,21.73,Juang,ETHNICITY,ETHNICITY
SM263173,-57.4916,-11.0983,Juara,ADM2,DISTRICT
SM264439,-35.5716,-7.1598,Juarez Távora,ADM2,DISTRICT
SM267249,-49.0799,-8.1018,Juarina,ADM2,DISTRICT
SM370241,-8.9197,5.3306,Juarzon Clan,ADM2:ADM3,DISTRICT
SM263582,-44.353,-19.9598,Juatuba,ADM2,DISTRICT
SM295570,-89.75,13.8333,Juayúa,ADM2,DISTRICT
SM264440,-36.6021,-6.9946,Juazeirinho,ADM2,DISTRICT
SM267497,-40.2338,-9.3968,Juazeiro,ADM2,DISTRICT
SM267963,-39.247,-7.1467,Juazeiro do Norte,ADM2,DISTRICT
SM265036,-41.5284,-5.0249,Juazeiro do Piauí,ADM2,DISTRICT
SM280912,35.7991,34.1252,Jubail,ADM2,DISTRICT
SM305028,44.9636,14.05,Juban,ADM2,DISTRICT
SM1661,42.6667,1.3333,Jubbada Dhexe,ADM1,DISTRICT
SM1662,42,0,Jubbada Hoose,ADM1,DISTRICT
SM265018,-36.4775,-8.7507,Jucati,ADM2,DISTRICT
SM283053,-103.1052,21.3835,Juchipila,ADM2,DISTRICT
SM282923,-96.6722,19.8165,Juchique de Ferrer,ADM2,DISTRICT
SM14308,-98.6728,16.5831,Juchitan,ADM2,DISTRICT
SM281933,-98.8791,19.1022,Juchitepec,ADM2,DISTRICT
SM281815,-104.0354,20.0558,Juchitlán,ADM2,DISTRICT
SM290107,23.8095,46.8496,Jucu De Sus,ADM2,DISTRICT
SM295596,-88.4333,13.6167,Jucuapa,ADM2,DISTRICT
SM295575,-88.2487,13.2131,Jucuarán,ADM2,DISTRICT
SM265570,-36.9694,-6.033,Jucurutu,ADM2,DISTRICT
SM267499,-40.0841,-16.8483,Jucuruçu,ADM2,DISTRICT
SM267782,-39.6201,-6.4468,Jucás,ADM2,DISTRICT
SM254227,40.23,20.56,Juddah,ETHNICITY,ETHNICITY
SM233478,-7.95,30.9,Judeo-Berber,LANGUAGE,LANGUAGE
SM233477,34.75,31.5,Judeo-Georgian,LANGUAGE,LANGUAGE
SM233487,48.5167,34.8,Judeo-Hamadani,DIALECT,LANGUAGE
SM233484,48.4984,34.7943,Judeo-Hamadani-Borujerdi,LANGUAGE,LANGUAGE
SM233482,41.2595,31.5339,Judeo-Iraqi Arabic,LANGUAGE,LANGUAGE
SM233492,51.65,32.6333,Judeo-Isfahani,DIALECT,LANGUAGE
SM233474,11.4723,43.1284,Judeo-Italian,LANGUAGE,LANGUAGE
SM234164,51.4772,33.9889,Judeo-Kashani,DIALECT,LANGUAGE
SM233481,-7.5,33.5,Judeo-Moroccan Arabic,LANGUAGE,LANGUAGE
SM233476,43.5715,31.4507,Judeo-Persian,LANGUAGE,LANGUAGE
SM233475,48.2648,42.0683,Judeo-Tat,LANGUAGE,LANGUAGE
SM233480,34.7422,31.9567,Judeo-Tripolitanian Arabic,LANGUAGE,LANGUAGE
SM233479,35.25,31.75,Judeo-Tunisian Arabic,LANGUAGE,LANGUAGE
SM233483,45,15,Judeo-Yemeni Arabic,LANGUAGE,LANGUAGE
SM301637,-110.2661,47.0455,Judith Basin,ADM2,DISTRICT
SM458619,122.445,-17.7866,Jugun,ETHNICITY,ETHNICITY
SM291538,26.4223,45.1016,Jugureni,ADM2,DISTRICT
SM254228,39.22,24.29,Juhainah,ETHNICITY,ETHNICITY
SM273888,31.4707,26.6782,Juhaynah al-Gharbiyah,ADM2,DISTRICT
SM263583,-43.4382,-21.749,Juiz de Fora,ADM2,DISTRICT
SM295507,-89.9355,13.7523,Jujutla,ADM2,DISTRICT
SM1664,-66,-23,Jujuy,ADM1,DISTRICT
SM250803,11.46,8.23,Jukun,ETHNICITY,ETHNICITY
SM250803,11.46,8.23,Jukun,ETHNICITY,ETHNICITY
SM250803,10,8,Jukun,ETHNICITY,ETHNICITY
SM250803,10,8,Jukun,ETHNICITY,ETHNICITY
SM233497,10.2576,7.2024,Jukun Takum,LANGUAGE,LANGUAGE
SM458643,122.5361,-18.1967,Julbayi,ETHNICITY,ETHNICITY
SM286760,-78.4636,-8.1724,Julcan,ADM2,DISTRICT
SM254233,-44.91,61.02,Julianehaab,ETHNICITY,ETHNICITY
SM283241,-105.213,28.4919,Julimes,ADM2,DISTRICT
SM303613,-70.9525,9.2593,Julio César Salas,ADM2,DISTRICT
SM233502,29.5528,11.5283,Julud,LANGUAGE,LANGUAGE
SM233505,-69.1956,-1.7729,Jumana,LANGUAGE,LANGUAGE
SM233505,-69.1956,-1.7729,Jumana,LANGUAGE,LANGUAGE
SM233505,-68.2956,-1.9301,Jumana,LANGUAGE,LANGUAGE
SM233505,-68.2956,-1.9301,Jumana,LANGUAGE,LANGUAGE
SM233503,-104.398,29.5232,Jumano,LANGUAGE,LANGUAGE
SM254234,-106.2,31.9,Jumanos,ETHNICITY,ETHNICITY
SM280327,74.4057,41.9492,Jumgal,ADM2,DISTRICT
SM266647,-47.7982,-23.1083,Jumirim,ADM2,DISTRICT
SM233506,33.7494,10.4349,Jumjum,LANGUAGE,LANGUAGE
SM375344,82.1987,29.2387,Jumla,ADM2:ADM3,DISTRICT
SM233508,82.1833,29.2753,Jumla Sign Language,LANGUAGE,LANGUAGE
SM233507,82.437,29.1708,Jumli,LANGUAGE,LANGUAGE
SM13574,24.9768,56.6719,Jumpravas pagasts,ADM2,DISTRICT
SM13575,25.7761,56.9399,Jumurdas pagasts,ADM2,DISTRICT
SM276817,70.3333,21.25,Junagadh (2013-),ADM2,DISTRICT
SM15833,-66.9203,18.3085,Juncal Barrio,ADM2,DISTRICT
SM265571,-37.4851,-6.081,Junco,ADM2,DISTRICT
SM263019,-46.1171,-1.9418,Junco do Maranhão,ADM2,DISTRICT
SM264441,-36.7277,-6.9784,Junco do Seridó,ADM2,DISTRICT
SM1665,-65.921,18.2275,Juncos,ADM1,DISTRICT
SM15834,-65.9219,18.2292,Juncos Barrio-Pueblo,ADM2,DISTRICT
SM12452,-77.5989,17.9147,Junction,ADM2,DISTRICT
SM9950,-35.3436,-6.2553,Jundia Municipality,ADM2,DISTRICT
SM266648,-46.9008,-23.203,Jundiaí,ADM2,DISTRICT
SM264536,-50.1807,-23.4792,Jundiaí do Sul,ADM2,DISTRICT
SM265375,-35.5302,-8.9643,Jundiá,ADM2,DISTRICT
SM303242,-90.114,43.9244,Juneau,ADM2,DISTRICT
SM301173,-134.3333,58.4167,Juneau,ADM2,DISTRICT
SM261561,147.6313,-34.8152,Junee,ADM2,DISTRICT
SM280616,126.994,37.5599,Jung,ADM2,DISTRICT
SM280567,126.6215,37.4735,Jung,ADM2,DISTRICT
SM280678,127.4177,36.2737,Jung,ADM2,DISTRICT
SM280672,128.5954,35.8668,Jung,ADM2,DISTRICT
SM280638,129.3323,35.5684,Jung,ADM2,DISTRICT
SM17882,129.0333,35.1059,Jung-gu,ADM2,DISTRICT
SM282041,-100.5145,19.4484,Jungapeo,ADM2,DISTRICT
SM370479,6.2531,49.7072,Junglinster,ADM1:ADM2:ADM3,DISTRICT
SM280617,127.1046,37.602,Jungnang,ADM2,DISTRICT
SM1666,32.4,7.4,Jungoli,ADM1,DISTRICT
SM289074,125.3544,39.0794,Jungsan,ADM2,DISTRICT
SM302312,-77.4022,40.5311,Juniata,ADM2,DISTRICT
SM265376,-36.4564,-9.8852,Junqueiro,ADM2,DISTRICT
SM266649,-51.4419,-21.4211,Junqueirópolis,ADM2,DISTRICT
SM254236,57.42,20.25,Junuba,ETHNICITY,ETHNICITY
SM303736,-72.4167,7.5833,Junín,ADM2,DISTRICT
SM286749,-76,-11.0833,Junín,ADM2,DISTRICT
SM273512,-80.2167,-0.9167,Junín,ADM2,DISTRICT
SM270013,-73.6955,4.7073,Junín,ADM2,DISTRICT
SM260893,-68.5216,-33.133,Junín,ADM2,DISTRICT
SM260991,-65.3333,-32.25,Junín,ADM2,DISTRICT
SM260781,-61,-34.5,Junín,ADM2,DISTRICT
SM1667,-75,-11.5,Junín,ADM1,DISTRICT
SM290635,23.5233,44.9124,Jupanesti,ADM2,DISTRICT
SM266185,-52.731,-26.411,Jupiá,ADM2,DISTRICT
SM265019,-36.394,-8.7155,Jupiá,ADM2,DISTRICT
SM233515,-95.8484,16.8912,Juquila Mixe,LANGUAGE,LANGUAGE
SM266651,-47.0137,-23.9586,Juquitiba,ADM2,DISTRICT
SM266650,-47.6354,-24.1896,Juquiá,ADM2,DISTRICT
SM233519,29.974,6.0332,Jur Modo,LANGUAGE,LANGUAGE
SM274472,5.7235,46.7343,Jura,ADM2,DISTRICT
SM1668,7.168,47.3727,Jura,ADM1,DISTRICT
SM17919,7.2198,47.2189,Jura bernois,ADM2,DISTRICT
SM269001,6.4543,46.7298,Jura-North Vaudois,ADM2,DISTRICT
SM269915,-77.6514,7.0043,Juradó,ADM2,DISTRICT
SM263584,-43.5883,-16.8334,Juramento,ADM2,DISTRICT
SM264537,-52.8273,-24.4132,Juranda,ADM2,DISTRICT
SM254237,5.27,47.69,Jurassian,ETHNICITY,ETHNICITY
SM233516,84.1204,19.091,Juray,LANGUAGE,LANGUAGE
SM281354,22.7989,55.1086,Jurbarko,ADM2,DISTRICT
SM276793,-4.5184,54.3628,Jurby,ADM1:ADM2,DISTRICT
SM233517,126.8668,44.4126,Jurchen,LANGUAGE,LANGUAGE
SM265039,-43.153,-9.2986,Jurema,ADM2,DISTRICT
SM265020,-36.1336,-8.7858,Jurema,ADM2,DISTRICT
SM233518,-69.9847,-1.3589,Juri,LANGUAGE,LANGUAGE
SM292053,28.8691,44.7726,Jurilovca,ADM2,DISTRICT
SM264442,-35.2209,-7.35,Juripiranga,ADM2,DISTRICT
SM13576,21.4363,57.0199,Jurkalnes pagasts,ADM2,DISTRICT
SM9539,70.8601,36.8464,Jurm (2005-),ADM2,DISTRICT
SM13577,23.6934,56.9594,Jurmala,ADM2,DISTRICT
SM4005,23.6953,56.952,Jurmala,ADM1,DISTRICT
SM459143,116.9072,-23.232,Jurruru,ETHNICITY,ETHNICITY
SM264224,-37.7885,-7.4913,Juru,ADM2,DISTRICT
SM263585,-46.5145,-21.2265,Juruaia,ADM2,DISTRICT
SM263175,-58.653,-10.2899,Juruena,ADM2,DISTRICT
SM264181,-56.0274,-2.3307,Juruti,ADM2,DISTRICT
SM267329,-66.2421,-3.2753,Juruá,ADM2,DISTRICT
SM233520,-53.3472,-10.9354,Jurúna,LANGUAGE,LANGUAGE
SM296357,15.9764,46.4875,Juršinci,ADM1:ADM2,DISTRICT
SM263176,-54.8803,-16.2053,Juscimeira,ADM2,DISTRICT
SM264538,-52.4708,-23.6212,Jussara,ADM2,DISTRICT
SM268076,-51.3297,-15.583,Jussara,ADM2,DISTRICT
SM267500,-41.8557,-10.9641,Jussara,ADM2,DISTRICT
SM267501,-39.5156,-15.1545,Jussari,ADM2,DISTRICT
SM267502,-41.6234,-13.4881,Jussiape,ADM2,DISTRICT
SM303614,-71,9,Justo Briceño,ADM2,DISTRICT
SM267330,-68.2803,-4.6549,Jutaí,ADM2,DISTRICT
SM263061,-54.5216,-22.8536,Juti,ADM2,DISTRICT
SM275653,-86.5041,15.6959,Jutiapa,ADM2,DISTRICT
SM275182,-89.9289,14.3036,Jutiapa,ADM2,DISTRICT
SM295601,-88.8827,13.9158,Jutiapa,ADM2,DISTRICT
SM1669,-89.8333,14.1667,Jutiapa,ADM1,DISTRICT
SM275745,-86.3,14.55,Juticalpa,ADM2,DISTRICT
SM233523,9.3892,54.911,Jutish,LANGUAGE,LANGUAGE
SM263586,-43.9506,-14.3993,Juvenília,ADM2,DISTRICT
SM233525,143.681,-3.786,Juwal,LANGUAGE,LANGUAGE
SM458097,124.6988,-20.6437,Juwaliny,ETHNICITY,ETHNICITY
SM233526,-98.0146,17.3518,Juxtlahuaca Mixtec,LANGUAGE,LANGUAGE
SM282171,-100.1165,25.6037,Juárez,ADM2,DISTRICT
SM283283,-100.6454,27.5836,Juárez,ADM2,DISTRICT
SM283240,-106.5173,31.4977,Juárez,ADM2,DISTRICT
SM283152,-93.1883,17.6938,Juárez,ADM2,DISTRICT
SM261146,-63.6667,-33.3333,Juárez Celman,ADM2,DISTRICT
SM281681,-98.837,20.833,Juárez Hidalgo,ADM2,DISTRICT
SM263174,-59.5081,-11.4033,Juína,ADM2,DISTRICT
SM1671,20.9658,45.0808,Južno-Banatski,ADM1:ADM2,DISTRICT
SM1670,19.8892,45.4086,Južno-Bački,ADM1:ADM2,DISTRICT
SM1672,24.7281,-24.6017,Jwaneng,ADM1,DISTRICT
SM233527,-2.1089,5.1075,Jwira-Pepesa,LANGUAGE,LANGUAGE
SM346804,25.819,62.2719,Jyväskylä,ADM2:ADM3,DISTRICT
SM260983,-68.5,-30.5,Jáchal,ADM2,DISTRICT
SM282916,-94.7263,17.9086,Jáltipan,ADM2,DISTRICT
SM276115,19.3174,46.2828,Jánoshalm,ADM2,DISTRICT
SM1627,20.5,47.25,Jász-Nagykun-Szolnok,ADM1,DISTRICT
SM1621,14.6667,63,Jämtland,ADM1,DISTRICT
SM296492,17.8651,59.4088,Järfälla,ADM2,DISTRICT
SM1625,25.6667,58.9167,Järva,ADM1:ADMX,DISTRICT
SM346833,23.8354,63.0735,Järviseutu,ADM2:ADM3,DISTRICT
SM276082,-74.2203,18.5415,Jérémie,ADM2,DISTRICT
SM265732,-54.1225,-28.7293,Jóia,ADM2,DISTRICT
SM274100,25.1354,59.44,Jõelähtme,ADM2,DISTRICT
SM1654,26.4667,58.7333,Jõgeva,ADM1:ADMX,DISTRICT
SM274230,27.393,59.3565,Jõhvi,ADM2,DISTRICT
SM296649,14.2028,57.741,Jönköping,ADM2,DISTRICT
SM1658,14.5,57.5,Jönköping,ADM1,DISTRICT
SM265037,-44.1918,-10.5158,Júlio Borges,ADM2,DISTRICT
SM266646,-49.7862,-21.9769,Júlio Mesquita,ADM2,DISTRICT
SM265733,-53.6246,-29.2645,Júlio de Castilhos,ADM2,DISTRICT
SM233504,-64.0998,-7.275,Júma,LANGUAGE,LANGUAGE
SM288758,20.1866,50.6568,Jędrzejów,ADM2,DISTRICT
SM278959,138.3375,37.1201,Jōetsu,ADM2,DISTRICT
SM278373,139.964,36.0641,Jōsō,ADM2,DISTRICT
SM278688,135.7976,34.8457,Jōyō,ADM2,DISTRICT
SM254729,-91,15,K'iche,ETHNICITY,ETHNICITY
SM234669,-91.1696,14.5278,K'iche',LANGUAGE,LANGUAGE
SM297429,103.1894,15.1236,K. Ban Dan,ADM2,DISTRICT
SM296996,102.7746,16.2007,K. Ban Haet,ADM2,DISTRICT
SM297451,99.3732,13.3237,K. Ban Kha,ADM2,DISTRICT
SM297513,100.8104,13.6329,K. Bang Sao Thon,ADM2,DISTRICT
SM297143,102.5176,15.6692,K. Bua Lai,ADM2,DISTRICT
SM297310,103.9943,18.2473,K. Bung Khla,ADM2,DISTRICT
SM297360,100.1429,16.1864,K. Bung Narang,ADM2,DISTRICT
SM296951,99.9672,16.2126,K. Bung Samakki,ADM2,DISTRICT
SM297230,99.6459,8.357,K. Chang Klang,ADM2,DISTRICT
SM297478,103.7492,16.1489,K. Chiang Kwan,ADM2,DISTRICT
SM297202,99.5691,15.6831,K. Chum Ta Bong,ADM2,DISTRICT
SM297075,103.148,16.548,K. Chun Chom,ADM2,DISTRICT
SM297430,103.1329,15.3062,K. Daen Kong,ADM2,DISTRICT
SM296895,98.7684,18.5333,K. Doi Lo,ADM2,DISTRICT
SM296907,100.1571,20.145,K. Doi Luang,ADM2,DISTRICT
SM296944,103.7167,16.4667,K. Don Chan,ADM2,DISTRICT
SM297361,100.5957,15.9985,K. Dong Charoen,ADM2,DISTRICT
SM297044,101.9744,17.2882,K. Erawan,ADM2,DISTRICT
SM297266,103.2964,18.0028,K. Fao Rai,ADM2,DISTRICT
SM297673,99.5816,7.2573,K. Hat Samran,ADM2,DISTRICT
SM297783,102.0976,12.9373,K. Kao Kichakut,ADM2,DISTRICT
SM297458,101.6785,12.9904,K. Khao Chamao,ADM2,DISTRICT
SM297529,101.1563,13.7796,K. Khlong Khuan,ADM2,DISTRICT
SM296997,102.3843,16.0719,K. Khok Pho Cha,ADM2,DISTRICT
SM296919,101.388,13.4025,K. Ko Chan,ADM2,DISTRICT
SM297682,102.3294,12.0595,K. Ko Chang,ADM2,DISTRICT
SM297683,102.5669,11.6571,K. Ko Kut,ADM2,DISTRICT
SM296954,99.348,16.607,K. Ko Sam Pi Nakhon,ADM2,DISTRICT
SM297485,102.6607,13.8739,K. Kok Sung,ADM2,DISTRICT
SM296945,103.4817,16.2585,K. Kong Chai,ADM2,DISTRICT
SM297752,101.2571,6.3993,K. Krong Pi Nung,ADM2,DISTRICT
SM297727,103.1675,17.1629,K. Ku Kaeo,ADM2,DISTRICT
SM297076,102.9506,16.0297,K. Kut Rang,ADM2,DISTRICT
SM297648,103.6206,15.0073,K. Kwao Si Narin,ADM2,DISTRICT
SM297113,102.9072,15.2842,K. Lam Tha Men Chai,ADM2,DISTRICT
SM297707,104.9166,15.4395,K. Lao Sua Kok,ADM2,DISTRICT
SM297547,99.954,7.0182,K. Ma Nang,ADM2,DISTRICT
SM296873,99.3038,18.735,K. Mae On,ADM2,DISTRICT
SM297203,99.3483,15.7066,K. Mae Poen,ADM2,DISTRICT
SM1673,37,38,K. Maras,ADM1,DISTRICT
SM297114,102.8933,15.4384,K. Muang Yang,ADM2,DISTRICT
SM296946,104.0006,16.7502,K. Na Du,ADM2,DISTRICT
SM297708,105.2845,15.9349,K. Na Tan,ADM2,DISTRICT
SM297785,101.875,12.7383,K. Na Yai Am,ADM2,DISTRICT
SM297709,105.0573,15.0414,K. Na Yia,ADM2,DISTRICT
SM297710,104.8987,14.5551,K. Nam Khun,ADM2,DISTRICT
SM297459,101.1534,12.8449,K. Nikhom Pattan,ADM2,DISTRICT
SM297664,99.8459,14.9825,K. Noen Kham,ADM2,DISTRICT
SM297649,103.9224,15.1911,K. Non Narai,ADM2,DISTRICT
SM296998,102.6907,15.9642,K. Non Sila,ADM2,DISTRICT
SM297479,104.0113,15.5798,K. Nong Hi,ADM2,DISTRICT
SM297045,101.8264,17.0809,K. Nong Hin,ADM2,DISTRICT
SM297665,99.8109,15.2337,K. Nong Ma Mong,ADM2,DISTRICT
SM296999,102.328,16.8101,K. Nong Na Kham,ADM2,DISTRICT
SM297231,99.6709,8.7517,K. Nophi Tam,ADM2,DISTRICT
SM297650,103.3101,14.4275,K. Phanom Dong Rak,ADM2,DISTRICT
SM297570,104.0674,15.2337,K. Pho Si Suwan,ADM2,DISTRICT
SM297267,102.4302,17.8882,K. Pho Tak,ADM2,DISTRICT
SM297115,101.9936,15.3511,K. Phra Thong Kham,ADM2,DISTRICT
SM297332,99.9661,19.3117,K. Phu Kam Yao,ADM2,DISTRICT
SM297242,100.875,18.7803,K. Phu Pieng,ADM2,DISTRICT
SM297333,100.3725,19.6237,K. Phu Sang,ADM2,DISTRICT
SM297728,102.9871,17.2465,K. Prachak Silapakhom,ADM2,DISTRICT
SM297268,103.2376,18.1926,K. Rattana Wapi,ADM2,DISTRICT
SM297269,102.7085,17.6599,K. Sa Khrai,ADM2,DISTRICT
SM297362,100.5235,16.5072,K. Sak Lek,ADM2,DISTRICT
SM296947,103.5224,16.8691,K. Sam Chai,ADM2,DISTRICT
SM297436,99.7401,12.2559,K. Sam Roi Yot,ADM2,DISTRICT
SM297000,103.0547,16.5621,K. Sam Sung,ADM2,DISTRICT
SM297778,101.65,15.6062,K. Sap Yai,ADM2,DISTRICT
SM297711,105.076,15.2344,K. Sawang Weeraw,ADM2,DISTRICT
SM297116,102.5582,15.5641,K. Sida,ADM2,DISTRICT
SM297571,104.0948,15.4807,K. Sila Lat,ADM2,DISTRICT
SM297320,99.9105,7.5757,K. Sri Nakarin,ADM2,DISTRICT
SM297651,103.8865,14.7809,K. Sri Narong,ADM2,DISTRICT
SM297441,98.4912,9.4157,K. Suk Samran,ADM2,DISTRICT
SM297117,101.5037,15.2864,K. The Pha Rak,ADM2,DISTRICT
SM297480,103.8709,15.9897,K. Thung Kao Lua,ADM2,DISTRICT
SM297655,99.1483,16.6331,K. Wang Chao,ADM2,DISTRICT
SM297486,102.1249,13.3611,K. Wang Sombun,ADM2,DISTRICT
SM297104,104.4537,17.0719,K. Wang Yang,ADM2,DISTRICT
SM297031,98.7586,18.4184,K. Wiang Nong Long,ADM2,DISTRICT
SM296908,100.0747,20.0221,K. Wieng Chiang,ADM2,DISTRICT
SM297634,98.8703,9.2197,K. Wipawadi,ADM2,DISTRICT
SM304048,108.4978,14.3051,KBang,ADM2,DISTRICT
SM459415,141.7922,-14.4898,KUGU NGANHCARA,ETHNICITY,ETHNICITY
SM459437,142.6543,-15.7238,KUNJEN,ETHNICITY,ETHNICITY
SM297753,100.9835,6.3792,Ka Bang,ADM2,DISTRICT
SM297288,101.5433,6.6026,Ka Pho,ADM2,DISTRICT
SM15058,32.636,-25.8872,KaMavota,ADM2,DISTRICT
SM15059,32.5973,-25.938,KaMaxakeni,ADM2,DISTRICT
SM15060,32.5829,-25.9654,KaMpfumo (2013-),ADM2,DISTRICT
SM15062,32.5726,-25.8868,KaMubukwana,ADM2,DISTRICT
SM15063,32.9431,-26.0173,KaNyaka,ADM2,DISTRICT
SM15064,32.532,-26.0299,KaTembe,ADM2,DISTRICT
SM4259,73.4992,4.1696,Kaafu Atoll,ADM1:ADM2,DISTRICT
SM284577,164.3977,-20.6694,Kaala-Gomen,ADM2,DISTRICT
SM233536,13.284,-4.0409,Kaamba,LANGUAGE,LANGUAGE
SM233538,12.1508,9.7078,Kaan,LANGUAGE,LANGUAGE
SM233537,-3.7099,10.1675,Kaansa,LANGUAGE,LANGUAGE
SM233544,16.7344,7.6497,Kaba,LANGUAGE,LANGUAGE
SM250154,28.95,17.53,Kababish,ETHNICITY,ETHNICITY
SM250154,28.95,17.53,Kababish,ETHNICITY,ETHNICITY
SM250154,31,17,Kababish,ETHNICITY,ETHNICITY
SM250154,31,17,Kababish,ETHNICITY,ETHNICITY
SM269481,-7.4377,9.6057,Kabadougou,ADM2,DISTRICT
SM298800,37.906,40.8571,Kabadüz,ADM2,DISTRICT
SM461509,152.1,-4.483,Kabakada,ETHNICITY,ETHNICITY
SM233550,16.2821,9.3837,Kabalai,LANGUAGE,LANGUAGE
SM4778,29.9823,-1.2599,Kabale (2017-),ADM1:ADM2,DISTRICT
SM296111,-57.2244,4.409,Kabalebo,ADM2,DISTRICT
SM295133,106,51.6667,Kabanskiy rayon,ADM2,DISTRICT
SM233541,43.3918,43.5082,Kabardian,LANGUAGE,LANGUAGE
SM254240,44.94,43.41,Kabardin,ETHNICITY,ETHNICITY
SM254240,44.94,43.41,Kabardin,ETHNICITY,ETHNICITY
SM254240,43.32,43.41,Kabardin,ETHNICITY,ETHNICITY
SM254240,43.32,43.41,Kabardin,ETHNICITY,ETHNICITY
SM1675,43.5,43.5,Kabardin-Balkar,ADM1,DISTRICT
SM269694,28.8,-2.4833,Kabare,ADM2,DISTRICT
SM298018,10.1876,36.7501,Kabaria,ADM2,DISTRICT
SM4780,30.2882,0.6545,Kabarole (2017-),ADM1:ADM2,DISTRICT
SM262086,29.5463,-2.8446,Kabarore,ADM2,DISTRICT
SM18055,37.4588,40.76,Kabatas Ilcesi (2014-),ADM2,DISTRICT
SM233539,49.7603,37.0529,Kabatei,LANGUAGE,LANGUAGE
SM12940,141.7291,43.5308,Kabato-gun,ADM2,DISTRICT
SM254242,16.8,8,Kabawa,ETHNICITY,ETHNICITY
SM284953,6.2034,8.0628,Kabba/Bu,ADM2,DISTRICT
SM296802,15.416,9.4726,Kabbia,ADM2,DISTRICT
SM277447,81.2,22.1,Kabeerdham,ADM2,DISTRICT
SM1677,33.2258,1.7573,Kaberamaido,ADM1:ADM2,DISTRICT
SM262037,29.37,-3.5311,Kabezi,ADM2,DISTRICT
SM233554,153.154,-25.4449,Kabikabi,LANGUAGE,LANGUAGE
SM13578,22.3534,56.9602,Kabiles pagasts,ADM2,DISTRICT
SM297404,101.8,13.9,Kabin Buri,ADM2,DISTRICT
SM254249,-61.1,-14.15,Kabishi,ETHNICITY,ETHNICITY
SM233558,-58.9931,-14.2403,Kabixí,LANGUAGE,LANGUAGE
SM233555,0.495,8.9682,Kabiyé,LANGUAGE,LANGUAGE
SM284865,8.1942,11.879,Kabo,ADM2,DISTRICT
SM268574,18.5864,7.7972,Kabo,ADM2,DISTRICT
SM233561,124.539,-8.1732,Kabola,LANGUAGE,LANGUAGE
SM18132,23.9561,-13.4895,Kabompo District (2012-),ADM2,DISTRICT
SM14054,-9.25,5.25,Kabor,ADM2,DISTRICT
SM254250,-46.32,-2.51,Kabora,ETHNICITY,ETHNICITY
SM233562,141.88,-3.324,Kabore One,LANGUAGE,LANGUAGE
SM233564,34.83,0.29,Kabras,LANGUAGE,LANGUAGE
SM277479,48.3667,35.3667,Kabudarahang,ADM2,DISTRICT
SM1678,69.4167,34.5,Kabul,ADM1,DISTRICT
SM260044,69.1055,34.4961,Kabul City,ADM2,DISTRICT
SM299386,31.1929,-0.2406,Kabula,ADM1:ADM2,DISTRICT
SM254251,31.16,-0.2,Kabula,ETHNICITY,ETHNICITY
SM11703,106.5226,-5.8031,Kabupaten Administrasi Kepulauan Seribu,ADM2,DISTRICT
SM11704,122.7184,-0.9114,Kabupaten Banggai (2012-),ADM2,DISTRICT
SM11705,123.4939,-1.6184,Kabupaten Banggai Laut,ADM2,DISTRICT
SM11706,122.9167,-5.3167,Kabupaten Buton (2014-),ADM2,DISTRICT
SM11707,122.7,-5.5667,Kabupaten Buton Selatan,ADM2,DISTRICT
SM11708,122.3333,-5.3167,Kabupaten Buton Tengah,ADM2,DISTRICT
SM11709,108.4167,-7.2833,Kabupaten Ciamis (2012-),ADM2,DISTRICT
SM11710,125.6905,-1.8646,Kabupaten Kepulauan Sula (2013-),ADM2,DISTRICT
SM11711,121.6667,-4.0833,Kabupaten Kolaka (2013-),ADM2,DISTRICT
SM11712,121.8617,-4.0181,Kabupaten Kolaka Timur,ADM2,DISTRICT
SM11713,122.0882,-3.9172,Kabupaten Konawe (2013-),ADM2,DISTRICT
SM11714,123.1018,-4.1166,Kabupaten Konawe Kepulauan,ADM2,DISTRICT
SM11715,115.5158,-0.5942,Kabupaten Kutai Barat (2012-),ADM2,DISTRICT
SM11716,104.1931,-5.149,Kabupaten Lampung Barat (2012-),ADM2,DISTRICT
SM11718,115.3805,0.3782,Kabupaten Mahakam Hulu,ADM2,DISTRICT
SM11719,119.4167,-2.5,Kabupaten Mamuju (2012-),ADM2,DISTRICT
SM11720,119.5405,-2.0216,Kabupaten Mamuju Tengah,ADM2,DISTRICT
SM11721,133.75,-0.9,Kabupaten Manokwari (2013-),ADM2,DISTRICT
SM11722,134.0031,-1.6053,Kabupaten Manokwari Selatan,ADM2,DISTRICT
SM11723,121.2465,-1.7207,Kabupaten Morowali Utara,ADM2,DISTRICT
SM11724,103.6141,-4.2327,Kabupaten Muara Enim (2012-),ADM2,DISTRICT
SM11725,122.6667,-4.9667,Kabupaten Muna (2014-),ADM2,DISTRICT
SM11726,122.4833,-4.8333,Kabupaten Muna Barat,ADM2,DISTRICT
SM11727,103.2,-3.0833,Kabupaten Musi Rawas (2013-),ADM2,DISTRICT
SM11728,102.7632,-2.7061,Kabupaten Musi Rawas Utara,ADM2,DISTRICT
SM11729,108.6404,-7.6673,Kabupaten Pangandaran,ADM2,DISTRICT
SM11730,133.7065,-1.2026,Kabupaten Pegunungan Arfak,ADM2,DISTRICT
SM11732,104.0872,-3.2134,Kabupaten Penukal Abab Lematang Ilir,ADM2,DISTRICT
SM11733,124.7899,-1.8458,Kabupaten Pulau Taliabu,ADM2,DISTRICT
SM233566,132.528,-1.9255,Kaburi,LANGUAGE,LANGUAGE
SM233567,69.691,25.292,Kabutra,LANGUAGE,LANGUAGE
SM233569,-23.6148,15.0586,Kabuverdianu,LANGUAGE,LANGUAGE
SM233571,34.3923,-1.7124,Kabwa,LANGUAGE,LANGUAGE
SM233572,29.1684,-4.3697,Kabwari,LANGUAGE,LANGUAGE
SM305149,28.355,-14.4603,Kabwe,ADM2,DISTRICT
SM288583,147,-6.0833,Kabwum,ADM2,DISTRICT
SM251080,1,10,Kabye Togo,ETHNICITY,ETHNICITY
SM251080,1,10,Kabye Togo,ETHNICITY,ETHNICITY
SM251080,2,10,Kabye Togo,ETHNICITY,ETHNICITY
SM251080,2,10,Kabye Togo,ETHNICITY,ETHNICITY
SM233573,4.7614,36.4103,Kabyle,LANGUAGE,LANGUAGE
SM250334,4.02,35.58,Kabyle,ETHNICITY,ETHNICITY
SM250334,4.02,35.58,Kabyle,ETHNICITY,ETHNICITY
SM250334,4,36,Kabyle,ETHNICITY,ETHNICITY
SM250334,4,36,Kabyle,ETHNICITY,ETHNICITY
SM233586,37.9291,6.3971,Kachama-Ganjule-Haro,LANGUAGE,LANGUAGE
SM233581,92.317,26.6882,Kachari,LANGUAGE,LANGUAGE
SM254254,90,27,Kachari,ETHNICITY,ETHNICITY
SM276818,70,23.5833,Kachchh,ADM2,DISTRICT
SM233579,69,23.83,Kachchi,LANGUAGE,LANGUAGE
SM254267,76.81,25.65,Kachhi,ETHNICITY,ETHNICITY
SM5123,67.4952,29.5039,Kachhi (2013-),ADM2:ADM3,DISTRICT
SM233575,69.1901,24.2966,Kachi Koli,LANGUAGE,LANGUAGE
SM284830,7.56,9.85,Kachia,ADM2,DISTRICT
SM1679,97.5,26,Kachin,ADM1,DISTRICT
SM461521,89,55,Kachin,ETHNICITY,ETHNICITY
SM279991,76.5,53.25,Kachirskiy,ADM2,DISTRICT
SM292888,106.5,54,Kachugskiy rayon,ADM2,DISTRICT
SM233588,34.9436,6.1651,Kacipo-Balesi,LANGUAGE,LANGUAGE
SM233590,107.257,13.8274,Kaco',LANGUAGE,LANGUAGE
SM233596,125.142,-1.7455,Kadai,LANGUAGE,LANGUAGE
SM280306,71.5833,40,Kadamjai,ADM2,DISTRICT
SM233591,76.7285,11.0602,Kadar,LANGUAGE,LANGUAGE
SM254271,77.17,10.25,Kadar South,ETHNICITY,ETHNICITY
SM233594,7.4593,9.997,Kadara,LANGUAGE,LANGUAGE
SM250335,8,10,Kadara,ETHNICITY,ETHNICITY
SM276169,17.7699,46.3979,Kadarkút,ADM1:ADM2,DISTRICT
SM233592,30.2709,12.1554,Kadaru,LANGUAGE,LANGUAGE
SM274411,178.2202,-18.9933,Kadavu,ADM2,DISTRICT
SM254274,116.28,5.3,Kadayan,ETHNICITY,ETHNICITY
SM269517,14.42,4.24,Kadey,ADM2,DISTRICT
SM305767,44.1667,33.45,Kadhimiya,ADM2,DISTRICT
SM254490,19.53,13.49,Kadianga,ETHNICITY,ETHNICITY
SM262215,-1.5,12.3333,Kadiogo (1995-),ADM1:ADM2,DISTRICT
SM283380,-5.8584,10.6326,Kadiolo,ADM2,DISTRICT
SM298820,36.1028,37.3841,Kadirli,ADM2,DISTRICT
SM233607,-57.582,-19.7222,Kadiwéu,LANGUAGE,LANGUAGE
SM274969,0.4667,7.5333,Kadjebi,ADM2,DISTRICT
SM233609,100.092,21.5259,Kado,LANGUAGE,LANGUAGE
SM254276,-93.75,33.14,Kadohadacho,ETHNICITY,ETHNICITY
SM254276,-93.75,33.14,Kadohadacho,ETHNICITY,ETHNICITY
SM254276,-93.75,33.14,Kadohadacho,ETHNICITY,ETHNICITY
SM254276,-95,31,Kadohadacho,ETHNICITY,ETHNICITY
SM254276,-95,31,Kadohadacho,ETHNICITY,ETHNICITY
SM254276,-95,31,Kadohadacho,ETHNICITY,ETHNICITY
SM254276,-93,33,Kadohadacho,ETHNICITY,ETHNICITY
SM254276,-93,33,Kadohadacho,ETHNICITY,ETHNICITY
SM254276,-93,33,Kadohadacho,ETHNICITY,ETHNICITY
SM305255,29.8283,-18.3405,Kadoma,ADM2,DISTRICT
SM279094,135.5993,34.7322,Kadoma,ADM2,DISTRICT
SM293636,44.4241,54.0254,Kadoshkinskiy rayon,ADM2,DISTRICT
SM274265,26.0415,59.3746,Kadrina,ADM2,DISTRICT
SM1680,7.75,10.3333,Kaduna,ADM1,DISTRICT
SM284831,7.452,10.568,Kaduna North,ADM2,DISTRICT
SM284810,7.421,10.468,Kaduna South,ADM2,DISTRICT
SM233611,101.74,22.2996,Kaduo,LANGUAGE,LANGUAGE
SM294966,37,59.3333,Kaduyskiy rayon,ADM2,DISTRICT
SM293355,43.25,57.75,Kadyyskiy rayon,ADM2,DISTRICT
SM298507,29.0903,40.9823,Kadıköy,ADM2,DISTRICT
SM298678,32.2173,38.2496,Kadınhanı,ADM2,DISTRICT
SM299036,35.798,40.0055,Kadışehri,ADM2,DISTRICT
SM297077,103.3895,16.0496,Kae Dam,ADM2,DISTRICT
SM289075,125.9013,39.701,Kaech'ŏn,ADM2,DISTRICT
SM297779,102.2018,16.1318,Kaeng Khlo,ADM2,DISTRICT
SM297541,101.053,14.5817,Kaeng Khoi,ADM2,DISTRICT
SM297351,99.4634,12.8592,Kaeng Krachan,ADM2,DISTRICT
SM297118,102.2537,15.6956,Kaeng Sanam Nang,ADM2,DISTRICT
SM305893,126.4564,37.9529,Kaepung,ADM2,DISTRICT
SM233616,124.0177,-8.3287,Kaera,LANGUAGE,LANGUAGE
SM9426,5.8989,49.5831,Kaerjong,ADM1:ADM2,DISTRICT
SM461906,126.603,37.9483,Kaesong County,ADM2,DISTRICT
SM280368,106.7703,12.4172,Kaev Seima,ADM2,DISTRICT
SM233620,36.1718,7.3402,Kafa,LANGUAGE,LANGUAGE
SM295273,-15.5019,14.1542,Kaffrine,ADM2,DISTRICT
SM1682,-15.5457,14.1053,Kaffrine,ADM1,DISTRICT
SM254277,18.43,-15.18,Kafima,ETHNICITY,ETHNICITY
SM284788,10.0025,12.1496,KafinHau,ADM2,DISTRICT
SM233624,124.446,-8.3657,Kafoa,LANGUAGE,LANGUAGE
SM11132,30.9361,31.1119,Kafr El Sheikh,ADM2,DISTRICT
SM233625,34.9751,32.1151,Kafr Qasem Sign Language,LANGUAGE,LANGUAGE
SM273817,31.6017,31.3474,Kafr Sa'd,ADM2,DISTRICT
SM273723,31.2905,30.5749,Kafr Shukr,ADM2,DISTRICT
SM273891,30.0892,31.1159,Kafr ad-Dawwar,ADM2,DISTRICT
SM1683,30.9,31.3,Kafr ash Shaykh,ADM1,DISTRICT
SM18144,28.4413,-15.6199,Kafue (2012-),ADM2,DISTRICT
SM284905,7.6897,11.6738,Kafur,ADM2,DISTRICT
SM285366,12.4517,11.6452,Kaga,ADM2,DISTRICT
SM278426,136.3796,36.255,Kaga,ADM2,DISTRICT
SM268554,19.2182,7.2377,Kaga-Bandoro,ADM2,DISTRICT
SM279046,133.9095,35.2062,Kagamino,ADM2,DISTRICT
SM233632,125.61,7.107,Kagan Kalagan,LANGUAGE,LANGUAGE
SM284811,7.577,9.437,Kagarko,ADM2,DISTRICT
SM233629,86.1675,27.4492,Kagate,LANGUAGE,LANGUAGE
SM1684,133.9947,34.2472,Kagawa,ADM1:ADMX,DISTRICT
SM233633,119.872,10.4656,Kagayanen,LANGUAGE,LANGUAGE
SM1685,31.25,-1.9167,Kagera (2012-),ADM1,DISTRICT
SM233643,8.1869,9.2261,Kagoma,LANGUAGE,LANGUAGE
SM233644,-9.964,14.0546,Kagoro,LANGUAGE,LANGUAGE
SM454613,8,10,Kagoro,ETHNICITY,ETHNICITY
SM1686,130.993,31.593,Kagoshima,ADM1:ADMX,DISTRICT
SM278484,130.5281,31.58,Kagoshima Town,ADM2,DISTRICT
SM12941,129.9213,29.9615,Kagoshima-gun,ADM2,DISTRICT
SM288600,144,-6.6667,Kagua-Erave,ADM2,DISTRICT
SM233645,37.0534,-6.382,Kagulu,LANGUAGE,LANGUAGE
SM251104,36.85,-6.45,Kaguru,ETHNICITY,ETHNICITY
SM251104,36.85,-6.45,Kaguru,ETHNICITY,ETHNICITY
SM251104,37,-6,Kaguru,ETHNICITY,ETHNICITY
SM251104,37,-6,Kaguru,ETHNICITY,ETHNICITY
SM254280,-63.87,-6.99,Kagwahiv,ETHNICITY,ETHNICITY
SM299169,32.3259,-4.0163,Kahama,ADM2,DISTRICT
SM299168,32.6102,-3.832,Kahama Township Authority,ADM2,DISTRICT
SM233649,37.5759,-3.5141,Kahe,LANGUAGE,LANGUAGE
SM9540,67.7757,35.357,Kahmard (2005-),ADM2,DISTRICT
SM12942,136.749,36.6903,Kahoku Gun,ADM2,DISTRICT
SM278427,136.7327,36.7437,Kahoku Town,ADM2,DISTRICT
SM298229,38.6312,37.7948,Kahta,ADM2,DISTRICT
SM233652,162.191,-10.6963,Kahua,LANGUAGE,LANGUAGE
SM279623,138.5187,35.7163,Kai,ADM2,DISTRICT
SM254286,146.85,-6.68,Kai,ETHNICITY,ETHNICITY
SM254287,152.29,-26.73,Kaiabara,ETHNICITY,ETHNICITY
SM254287,152.29,-26.73,Kaiabara,ETHNICITY,ETHNICITY
SM254287,152.6675,-26.0056,Kaiabara,ETHNICITY,ETHNICITY
SM254287,152.6675,-26.0056,Kaiabara,ETHNICITY,ETHNICITY
SM254288,139.03,-16.94,Kaiadilt,ETHNICITY,ETHNICITY
SM254288,139.03,-16.94,Kaiadilt,ETHNICITY,ETHNICITY
SM254288,139.4892,-17.0306,Kaiadilt,ETHNICITY,ETHNICITY
SM254288,139.4892,-17.0306,Kaiadilt,ETHNICITY,ETHNICITY
SM284975,4.1599,9.4534,Kaiama,ADM2,DISTRICT
SM233655,144.756,-4.0654,Kaian,LANGUAGE,LANGUAGE
SM233657,128.211,-3.1603,Kaibobo,LANGUAGE,LANGUAGE
SM233658,123.217,0.8564,Kaidipang,LANGUAGE,LANGUAGE
SM233660,143.884,-3.7052,Kaiep,LANGUAGE,LANGUAGE
SM269173,114.4972,34.6042,Kaifeng,ADM2,DISTRICT
SM12943,134.3531,33.6812,Kaifu Gun,ADM2,DISTRICT
SM254290,-129.76,53.53,Kaigani,ETHNICITY,ETHNICITY
SM233663,76.0003,18.3859,Kaikadi,LANGUAGE,LANGUAGE
SM233664,83.1384,28.853,Kaike,LANGUAGE,LANGUAGE
SM286540,173.622,-42.2364,Kaikoura,ADM2,DISTRICT
SM233665,28.7394,0.7507,Kaiku,LANGUAGE,LANGUAGE
SM295484,-10.7515,8.1077,Kailahun,ADM1:ADM2,DISTRICT
SM375336,80.9043,28.7314,Kailali,ADM2:ADM3,DISTRICT
SM233672,144.1525,-5.8984,Kailge Sign Language,LANGUAGE,LANGUAGE
SM276516,133.7745,-3.6609,Kaimana,ADM2,DISTRICT
SM254294,144.23,-6.38,Kaimbi,ETHNICITY,ETHNICITY
SM233674,122.538,-5.6545,Kaimbulawa,LANGUAGE,LANGUAGE
SM233673,-39.0263,-10.4796,Kaimbé,LANGUAGE,LANGUAGE
SM277408,83.5826,25.0508,Kaimur,ADM2,DISTRICT
SM252386,-110,48,Kainai,ETHNICITY,ETHNICITY
SM252386,-110,48,Kainai,ETHNICITY,ETHNICITY
SM252386,-110,48,Kainai,ETHNICITY,ETHNICITY
SM252386,-115,51,Kainai,ETHNICITY,ETHNICITY
SM252386,-115,51,Kainai,ETHNICITY,ETHNICITY
SM252386,-115,51,Kainai,ETHNICITY,ETHNICITY
SM252386,-113.86,52.79,Kainai,ETHNICITY,ETHNICITY
SM252386,-113.86,52.79,Kainai,ETHNICITY,ETHNICITY
SM252386,-113.86,52.79,Kainai,ETHNICITY,ETHNICITY
SM279513,135.224,34.1418,Kainan,ADM2,DISTRICT
SM288635,145.9167,-6.4167,Kainantu,ADM2,DISTRICT
SM262385,27.5333,43.95,Kainardzha,ADM2,DISTRICT
SM233680,-52.5394,-27.7671,Kaingang,LANGUAGE,LANGUAGE
SM254295,-48.9,-20.39,Kaingang,ETHNICITY,ETHNICITY
SM254295,-48.9,-20.39,Kaingang,ETHNICITY,ETHNICITY
SM254295,-48.98,-23.05,Kaingang,ETHNICITY,ETHNICITY
SM254295,-48.98,-23.05,Kaingang,ETHNICITY,ETHNICITY
SM254295,-52.1,-24.47,Kaingang,ETHNICITY,ETHNICITY
SM254295,-52.1,-24.47,Kaingang,ETHNICITY,ETHNICITY
SM254295,-51.45,-25.06,Kaingang,ETHNICITY,ETHNICITY
SM254295,-51.45,-25.06,Kaingang,ETHNICITY,ETHNICITY
SM254295,-50,-20,Kaingang,ETHNICITY,ETHNICITY
SM254295,-50,-20,Kaingang,ETHNICITY,ETHNICITY
SM274390,28.5,64.5,Kainuu,ADM1:ADM2,DISTRICT
SM286490,174.0469,-35.9955,Kaipara,ADM2,DISTRICT
SM233694,151.98,-4.4694,Kairak,LANGUAGE,LANGUAGE
SM233690,143.558,-3.3461,Kairiru,LANGUAGE,LANGUAGE
SM1687,9.8333,35.5833,Kairouan,ADM1:ADMX,DISTRICT
SM298107,10.0924,35.7519,Kairouan Nord,ADM2,DISTRICT
SM298108,10.1127,35.5964,Kairouan Sud,ADM2,DISTRICT
SM233692,126.059,-8.7403,Kairui-Midiki,LANGUAGE,LANGUAGE
SM288605,147.2791,-9.1365,Kairuku-Hiri,ADM2,DISTRICT
SM233695,132.312,-1.7808,Kais,LANGUAGE,LANGUAGE
SM233697,-67.4194,-1.6565,Kaishana,LANGUAGE,LANGUAGE
SM233697,-67.4194,-1.6565,Kaishana,LANGUAGE,LANGUAGE
SM233697,-67.3192,-2.1716,Kaishana,LANGUAGE,LANGUAGE
SM233697,-67.3192,-2.1716,Kaishana,LANGUAGE,LANGUAGE
SM284906,7.728,13.1716,Kaita,ADM2,DISTRICT
SM461520,47.63,42.15,Kaitag,ETHNICITY,ETHNICITY
SM276864,76.4182,29.814,Kaithal,ADM2,DISTRICT
SM254297,135.29,-22.23,Kaitish,ETHNICITY,ETHNICITY
SM254297,135.29,-22.23,Kaitish,ETHNICITY,ETHNICITY
SM254297,134.0695,-21.0199,Kaitish,ETHNICITY,ETHNICITY
SM254297,134.0695,-21.0199,Kaitish,ETHNICITY,ETHNICITY
SM13579,25.6116,57.0253,Kaives pagasts,ADM2,DISTRICT
SM233699,8.108,10.4328,Kaivi,LANGUAGE,LANGUAGE
SM233700,-54.6815,-24.589,Kaiwá,LANGUAGE,LANGUAGE
SM233702,138.161,-2.9445,Kaiy,LANGUAGE,LANGUAGE
SM279792,136.6244,35.2138,Kaizu,ADM2,DISTRICT
SM279095,135.3851,34.4011,Kaizuka,ADM2,DISTRICT
SM281329,24.45,54.8667,Kaišiadorių,ADM2,DISTRICT
SM346782,27.9022,64.2452,Kajaani,ADM2:ADM3,DISTRICT
SM260003,65.0387,32.4842,Kajaki,ADM2,DISTRICT
SM233707,20.879,12.2492,Kajakse,LANGUAGE,LANGUAGE
SM233704,53.4785,35.8989,Kajali,LANGUAGE,LANGUAGE
SM233706,113.886,2.7861,Kajaman,LANGUAGE,LANGUAGE
SM233710,-55.5722,3.8886,Kajana Sign Language,LANGUAGE,LANGUAGE
SM254299,52.1,34.91,Kajars,ETHNICITY,ETHNICITY
SM1688,36.878,-2.131,Kajiado,ADM1:ADM2,DISTRICT
SM233712,16.1341,45.6498,Kajkavian,LANGUAGE,LANGUAGE
SM296000,31.5467,3.8584,Kajo Kaii,ADM2,DISTRICT
SM285156,3.3575,8.0264,Kajola,ADM2,DISTRICT
SM9541,65.6175,33.2143,Kajran (2005-),ADM2,DISTRICT
SM233716,47.7766,42.0524,Kajtak,LANGUAGE,LANGUAGE
SM284812,7.8578,10.277,Kajuru,ADM2,DISTRICT
SM254300,14.96,4.29,Kaka,ETHNICITY,ETHNICITY
SM233723,150.027,-10.2976,Kakabai,LANGUAGE,LANGUAGE
SM233721,-11.4418,10.5962,Kakabe,LANGUAGE,LANGUAGE
SM1689,34.797,0.334,"Kakamega (1990-1998, 2010-)",ADM1:ADM2,DISTRICT
SM279793,136.8783,35.4028,Kakamigahara,ADM2,DISTRICT
SM269821,11.9354,-4.1143,Kakamoeka,ADM2,DISTRICT
SM233720,6.5592,8.3393,Kakanda,LANGUAGE,LANGUAGE
SM9542,67.1815,32.86,Kakar,ADM2,DISTRICT
SM280992,-10.1976,6.622,Kakata,ADM2,DISTRICT
SM233725,-72.3228,-39.0488,Kakauhua,LANGUAGE,LANGUAGE
SM11654,93.9846,24.4958,Kakching,ADM2,DISTRICT
SM279278,138.019,34.7799,Kakegawa,ADM2,DISTRICT
SM1690,45.7167,41.75,Kakheti,ADM1,DISTRICT
SM18221,33.4796,46.8162,Kakhovka Raion,ADM2,DISTRICT
SM233730,145.816,-7.9946,Kaki Ae,LANGUAGE,LANGUAGE
SM233729,5.5885,10.8119,Kakihum,LANGUAGE,LANGUAGE
SM233731,76.4856,9.5947,Kakkala,LANGUAGE,LANGUAGE
SM233733,15.6282,3.7999,Kako,LANGUAGE,LANGUAGE
SM12944,134.9112,34.7443,Kako-gun,ADM2,DISTRICT
SM278332,134.849,34.7847,Kakogawa,ADM2,DISTRICT
SM254306,143.53,-6.47,Kakoli,ETHNICITY,ETHNICITY
SM254306,143.53,-6.47,Kakoli,ETHNICITY,ETHNICITY
SM254306,144,-6,Kakoli,ETHNICITY,ETHNICITY
SM254306,144,-6,Kakoli,ETHNICITY,ETHNICITY
SM299241,30.949,-3.2769,Kakonko,ADM2,DISTRICT
SM10739,32.8977,34.9634,Kakopetria,ADM2,DISTRICT
SM228051,-69.5659,0.8878,Kakua,LANGUAGE,LANGUAGE
SM278751,140.779,37.9883,Kakuda,ADM2,DISTRICT
SM233737,30.7762,3.6523,Kakwa,LANGUAGE,LANGUAGE
SM250892,31.03,3.43,Kakwa,ETHNICITY,ETHNICITY
SM250892,31.03,3.43,Kakwa,ETHNICITY,ETHNICITY
SM250892,31,4,Kakwa,ETHNICITY,ETHNICITY
SM250892,31,4,Kakwa,ETHNICITY,ETHNICITY
SM233747,142.196,-10.6748,Kala Lagaw Ya,LANGUAGE,LANGUAGE
SM285367,14.4809,12.0464,Kala/Balge,ADM2,DISTRICT
SM297998,10.4324,35.887,Kalaa Kebira,ADM2,DISTRICT
SM297873,8.5354,35.7253,Kalaa Khesba,ADM2,DISTRICT
SM297999,10.4743,35.8085,Kalaa Sghira,ADM2,DISTRICT
SM233766,-52.864,69.3761,Kalaallisut,LANGUAGE,LANGUAGE
SM254307,-53,69,Kalaallit (West Greenland),ETHNICITY,ETHNICITY
SM254307,-53,69,Kalaallit (West Greenland),ETHNICITY,ETHNICITY
SM254307,-53,69,Kalaallit (West Greenland),ETHNICITY,ETHNICITY
SM254307,-51.22,68.66,Kalaallit (West Greenland),ETHNICITY,ETHNICITY
SM254307,-51.22,68.66,Kalaallit (West Greenland),ETHNICITY,ETHNICITY
SM254307,-51.22,68.66,Kalaallit (West Greenland),ETHNICITY,ETHNICITY
SM254307,-50.21,69.31,Kalaallit (West Greenland),ETHNICITY,ETHNICITY
SM254307,-50.21,69.31,Kalaallit (West Greenland),ETHNICITY,ETHNICITY
SM254307,-50.21,69.31,Kalaallit (West Greenland),ETHNICITY,ETHNICITY
SM233768,120.223,-30.245,Kalaamaya,LANGUAGE,LANGUAGE
SM297857,10.1295,37.0266,Kalaat El Andalous,ADM2,DISTRICT
SM297874,8.3577,35.8188,Kalaat Senan,ADM2,DISTRICT
SM233760,117.317,4.5361,Kalabakan,LANGUAGE,LANGUAGE
SM233751,6.6954,4.5319,Kalabari,LANGUAGE,LANGUAGE
SM18133,22.712,-14.84,Kalabo District (2012-),ADM2,DISTRICT
SM233739,131.617,-1.4307,Kalabra,LANGUAGE,LANGUAGE
SM254309,131.4,-1.06,Kalabra,ETHNICITY,ETHNICITY
SM295004,41.0833,50.4167,Kalacheevskiy rayon,ADM2,DISTRICT
SM294903,43.6667,48.5,Kalachevskiy rayon,ADM2,DISTRICT
SM16925,74.75,55.0833,Kalachinskiy Rayon,ADM2,DISTRICT
SM260217,69.9149,36.7877,Kalafgan,ADM2,DISTRICT
SM233757,125.328,6.8126,Kalagan,LANGUAGE,LANGUAGE
SM277113,83,19.75,Kalahandi (1993-),ADM2,DISTRICT
SM260045,69.1756,34.7625,Kalakan,ADM2,DISTRICT
SM11311,41.6833,42.15,Kalaki Poti,ADM2,DISTRICT
SM11312,45,41.5333,Kalaki Rustavi,ADM2,DISTRICT
SM233746,92.143,27.0731,Kalaktang Monpa,LANGUAGE,LANGUAGE
SM233750,143.929,-28.136,Kalali,LANGUAGE,LANGUAGE
SM262150,3.4209,10.361,Kalalé,ADM2,DISTRICT
SM233764,144.585,-5.2522,Kalam,LANGUAGE,LANGUAGE
SM234076,132.678,-3.4704,Kalamang,LANGUAGE,LANGUAGE
SM301283,-85.5312,42.2455,Kalamazoo,ADM2,DISTRICT
SM299156,31.5731,-8.5403,Kalambo,ADM2,DISTRICT
SM233753,-3.1003,13.6106,Kalamsé,LANGUAGE,LANGUAGE
SM261393,116.1329,-32.004,Kalamunda,ADM2,DISTRICT
SM233738,76.1225,11.6025,Kalanadi,LANGUAGE,LANGUAGE
SM300041,33.2908,46.2547,Kalanchats'kyi,ADM2,DISTRICT
SM233754,27.401,-21.2887,Kalanga,LANGUAGE,LANGUAGE
SM1691,32.3811,-0.5748,Kalangala,ADM1:ADM2,DISTRICT
SM233763,120.995,-7.3121,Kalao,LANGUAGE,LANGUAGE
SM254317,-51.93,-11.43,Kalapalo,ETHNICITY,ETHNICITY
SM305846,45.2719,34.8858,Kalar,ADM2,DISTRICT
SM233749,118.911,-31.7154,Kalarko,LANGUAGE,LANGUAGE
SM295080,118.5,56.5,Kalarskiy rayon,ADM2,DISTRICT
SM454492,71.5,35.9937,Kalash,ETHNICITY,ETHNICITY
SM454492,71.5,35.9937,Kalash,ETHNICITY,ETHNICITY
SM454492,71.5,36,Kalash,ETHNICITY,ETHNICITY
SM454492,71.5,36,Kalash,ETHNICITY,ETHNICITY
SM233742,71.6657,35.6415,Kalasha,LANGUAGE,LANGUAGE
SM1692,103.5,16.5,Kalasin,ADM1,DISTRICT
SM375377,66.5317,28.8824,Kalat,ADM2:ADM3,DISTRICT
SM10740,33.2927,34.7671,Kalavasos,ADM2,DISTRICT
SM459272,142.4788,-9.352,Kalaw Kawaw Ya,ETHNICITY,ETHNICITY
SM300541,-156.9594,21.1857,Kalawao,ADM2,DISTRICT
SM260679,56.2721,24.9982,Kalba,ADM2,DISTRICT
SM4168,46.1747,40.1027,Kalbajar District,ADM1,DISTRICT
SM260254,67.549,37.1271,Kaldar,ADM2,DISTRICT
SM277840,-21.5667,65.7833,Kaldrananeshreppur,ADM2,DISTRICT
SM298723,38.7441,38.3977,Kale,ADM2,DISTRICT
SM298306,28.8558,37.4454,Kale,ADM2,DISTRICT
SM299023,29.9979,36.2551,Kale,ADM2,DISTRICT
SM298888,33.4197,40.1063,Kalecik,ADM2,DISTRICT
SM269695,28.9167,-2.1,Kalehe,ADM2,DISTRICT
SM269708,29.1947,-5.9475,Kalemie (ville),ADM2,DISTRICT
SM283471,94.4105,23.0557,Kalemyo,ADM2,DISTRICT
SM233782,35.9236,-0.0216,Kalenjin,FAMILY,LANGUAGE
SM13580,21.4992,56.337,Kaletu pagasts,ADM2,DISTRICT
SM280826,107.0453,15.7989,Kaleum,ADM2,DISTRICT
SM293128,31,65,Kaleval'skiy rayon,ADM2,DISTRICT
SM11777,47.05,38.9,Kaleybar,ADM2,DISTRICT
SM295081,119,51,Kalganskiy rayon,ADM2,DISTRICT
SM284935,4.1009,12.2835,Kalgo,ADM2,DISTRICT
SM261394,124.9235,-30.7763,Kalgoorlie/Boulder,ADM2,DISTRICT
SM305610,15.2068,44.0624,Kali,ADM2,DISTRICT
SM10741,32.8715,35.0062,Kaliana,ADM2,DISTRICT
SM375345,81.689,29.2163,Kalikot,ADM2:ADM3,DISTRICT
SM1694,110.5,0,Kalimantan Barat,ADM1,DISTRICT
SM1695,115.5,-2.5,Kalimantan Selatan,ADM1,DISTRICT
SM1696,113.5,-2,Kalimantan Tengah,ADM1,DISTRICT
SM1697,116.5,0.5,Kalimantan Timur (2013-),ADM1,DISTRICT
SM11655,88.4695,27.0591,Kalimpong,ADM2,DISTRICT
SM1698,121.4161,17.426,Kalinga,ADM1:ADM2,DISTRICT
SM254325,120.74,17.77,Kalinga,ETHNICITY,ETHNICITY
SM254325,120.74,17.77,Kalinga,ETHNICITY,ETHNICITY
SM254325,121,18,Kalinga,ETHNICITY,ETHNICITY
SM254325,121,18,Kalinga,ETHNICITY,ETHNICITY
SM1699,21.5,54.75,Kaliningrad,ADM1,DISTRICT
SM11313,44.75,41.7,Kalininskiy Rayon,ADM2,DISTRICT
SM16927,30.3717,59.9908,Kalininskiy Rayon (Sankt Petersburg),ADM2,DISTRICT
SM16926,35.9895,56.7596,Kalininskiy Rayon (Tverskaya),ADM2,DISTRICT
SM293365,38.665,45.4426,Kalininskiy rayon,ADM2,DISTRICT
SM9791,29.4167,52.25,Kalinkavitski Rayon (excl. Kalinkavichy),ADM2,DISTRICT
SM305724,17.1131,46.0249,Kalinovac,ADM2,DISTRICT
SM313216,18.43,43.4611,Kalinovik,ADM2:ADM3,DISTRICT
SM254327,-115.19,48.13,Kalispel,ETHNICITY,ETHNICITY
SM254327,-115.19,48.13,Kalispel,ETHNICITY,ETHNICITY
SM254327,-115.19,48.13,Kalispel,ETHNICITY,ETHNICITY
SM254327,-115.19,48.13,Kalispel,ETHNICITY,ETHNICITY
SM254327,-115.19,48.13,Kalispel,ETHNICITY,ETHNICITY
SM254327,-117.5,48.5,Kalispel,ETHNICITY,ETHNICITY
SM254327,-117.5,48.5,Kalispel,ETHNICITY,ETHNICITY
SM254327,-117.5,48.5,Kalispel,ETHNICITY,ETHNICITY
SM254327,-117.5,48.5,Kalispel,ETHNICITY,ETHNICITY
SM254327,-117.5,48.5,Kalispel,ETHNICITY,ETHNICITY
SM254327,-117,49,Kalispel,ETHNICITY,ETHNICITY
SM254327,-117,49,Kalispel,ETHNICITY,ETHNICITY
SM254327,-117,49,Kalispel,ETHNICITY,ETHNICITY
SM254327,-117,49,Kalispel,ETHNICITY,ETHNICITY
SM254327,-117,49,Kalispel,ETHNICITY,ETHNICITY
SM254327,-115.67,47.94,Kalispel,ETHNICITY,ETHNICITY
SM254327,-115.67,47.94,Kalispel,ETHNICITY,ETHNICITY
SM254327,-115.67,47.94,Kalispel,ETHNICITY,ETHNICITY
SM254327,-115.67,47.94,Kalispel,ETHNICITY,ETHNICITY
SM254327,-115.67,47.94,Kalispel,ETHNICITY,ETHNICITY
SM254327,-116.2,48.2,Kalispel,ETHNICITY,ETHNICITY
SM254327,-116.2,48.2,Kalispel,ETHNICITY,ETHNICITY
SM254327,-116.2,48.2,Kalispel,ETHNICITY,ETHNICITY
SM254327,-116.2,48.2,Kalispel,ETHNICITY,ETHNICITY
SM254327,-116.2,48.2,Kalispel,ETHNICITY,ETHNICITY
SM233794,-114.19,47.5825,Kalispel-Pend d'Oreille,LANGUAGE,LANGUAGE
SM288820,18.2081,51.7892,Kalisz,ADM2,DISTRICT
SM288819,18.0761,51.7473,Kalisz (City),ADM2,DISTRICT
SM299187,31.664,-4.9076,Kaliua,ADM2,DISTRICT
SM296401,22.9867,65.9612,Kalix,ADM2,DISTRICT
SM298825,40.44,40.9214,Kalkandere,ADM2,DISTRICT
SM301285,-85.0902,44.6847,Kalkaska,ADM2,DISTRICT
SM458469,139.89,-20.8865,Kalkatungu / Kalkadoon,ETHNICITY,ETHNICITY
SM233799,72.0533,35.3651,Kalkoti,LANGUAGE,LANGUAGE
SM13581,26.4337,55.8385,Kalkunes pagasts,ADM2,DISTRICT
SM233800,139.5,-21,Kalkutung,LANGUAGE,LANGUAGE
SM10742,32.5074,34.8395,Kallepeia,ADM2,DISTRICT
SM292962,83.5,53,Kalmanskiy rayon,ADM2,DISTRICT
SM296661,16.1857,56.6636,Kalmar,ADM2,DISTRICT
SM1700,16,57.3333,Kalmar,ADM1,DISTRICT
SM18222,38.2169,47.7165,Kalmiuskyi Raion (2020-),ADM2,DISTRICT
SM1701,45.5,46.5,Kalmyk,ADM1,DISTRICT
SM254335,46.06,46.8,Kalmyk,ETHNICITY,ETHNICITY
SM254335,46.06,46.8,Kalmyk,ETHNICITY,ETHNICITY
SM254335,46.06,46.8,Kalmyk,ETHNICITY,ETHNICITY
SM254335,46,46,Kalmyk,ETHNICITY,ETHNICITY
SM254335,46,46,Kalmyk,ETHNICITY,ETHNICITY
SM254335,46,46,Kalmyk,ETHNICITY,ETHNICITY
SM254335,45.3428,46.1694,Kalmyk,ETHNICITY,ETHNICITY
SM254335,45.3428,46.1694,Kalmyk,ETHNICITY,ETHNICITY
SM254335,45.3428,46.1694,Kalmyk,ETHNICITY,ETHNICITY
SM13582,25.8214,56.3303,Kalna pagasts,ADM2,DISTRICT
SM13583,26.8819,57.3349,Kalncempju pagasts,ADM2,DISTRICT
SM13584,23.6022,56.7872,Kalnciema pagasts,ADM2,DISTRICT
SM13585,27.3693,55.8906,Kalniesu pagasts,ADM2,DISTRICT
SM305725,16.4644,46.1272,Kalnik,ADM2,DISTRICT
SM10743,32.8733,35.1181,Kalo Chorio,ADM2,DISTRICT
SM10743,32.8733,35.1181,Kalo Chorio,ADM2,DISTRICT
SM10743,33.1562,35.0048,Kalo Chorio,ADM2,DISTRICT
SM10743,33.1562,35.0048,Kalo Chorio,ADM2,DISTRICT
SM10744,33.0311,35.2414,Kalo Chorio Kapouti,ADM2,DISTRICT
SM233805,24.43,60.45,Kalo Finnish Romani,LANGUAGE,LANGUAGE
SM276116,19.0421,46.5565,Kalocsa,ADM2,DISTRICT
SM10745,33.6316,35.3405,Kalograia,ADM2,DISTRICT
SM295399,159.8023,-8.3172,Kaloka,ADM2,DISTRICT
SM18134,26.679,-17.147,Kalomo District (2012-),ADM2,DISTRICT
SM10746,32.834,35.0328,Kalopanagiotis,ADM2,DISTRICT
SM10747,33.7993,35.1046,Kalopsida,ADM2,DISTRICT
SM264539,-51.6803,-23.8671,Kaloré,ADM2,DISTRICT
SM233811,142.217,-3.9399,Kalou,LANGUAGE,LANGUAGE
SM262351,24.8167,42.3833,Kaloyanovo,ADM2,DISTRICT
SM13586,25.938,56.7311,Kalsnavas pagasts,ADM2,DISTRICT
SM294263,54.75,55.9167,Kaltasinskiy rayon,ADM2,DISTRICT
SM284752,11.4221,9.8235,Kaltungo,ADM2,DISTRICT
SM1702,35.5,54.5,Kaluga,ADM1,DISTRICT
SM233816,142.788,-6.4593,Kaluli,LANGUAGE,LANGUAGE
SM254339,142.65,-6.9,Kaluli,ETHNICITY,ETHNICITY
SM305164,27.952,-12.762,Kalulushi,ADM2,DISTRICT
SM233815,119.474,-2.3339,Kalumpang,LANGUAGE,LANGUAGE
SM271731,11.1999,55.6355,Kalundborg (2007-),ADM2,DISTRICT
SM299332,31.7972,-0.1634,Kalungu,ADM1:ADM2,DISTRICT
SM13587,26.5486,56.086,Kalupes pagasts,ADM2,DISTRICT
SM299988,24.3707,49.0237,Kalus'kyi,ADM2,DISTRICT
SM1703,80.1026,6.6031,Kalutara,ADM1:ADM2,DISTRICT
SM14138,23.2248,54.4147,Kalvarija Municipality,ADM2,DISTRICT
SM13588,21.7069,56.6083,Kalvenes pagasts,ADM2,DISTRICT
SM294797,37.8333,57.25,Kalyazinskiy rayon,ADM2,DISTRICT
SM299793,28.5215,49.4591,Kalynivs'kyi,ADM2,DISTRICT
SM10748,33.5416,35.2626,Kalyvakia,ADM2,DISTRICT
SM233879,11.0561,8.305,Kam,LANGUAGE,LANGUAGE
SM279670,130.7403,33.5366,Kama,ADM2,DISTRICT
SM260116,70.6563,34.443,Kama,ADM2,DISTRICT
SM279352,139.9988,35.7729,Kamagaya,ADM2,DISTRICT
SM278433,141.7985,39.2875,Kamaishi,ADM2,DISTRICT
SM233839,-41,-15,Kamakan,LANGUAGE,LANGUAGE
SM278530,139.538,35.33,Kamakura,ADM2,DISTRICT
SM296948,103.6001,16.28,Kamalasai,ADM2,DISTRICT
SM298656,33.7278,39.3672,Kaman,ADM2,DISTRICT
SM233833,124.792,-8.269,Kamang,LANGUAGE,LANGUAGE
SM254346,33.37,-11.11,Kamanga,ETHNICITY,ETHNICITY
SM10526,15.3932,45.634,Kamanje,ADM2,DISTRICT
SM233837,145.728,-6.2583,Kamano,LANGUAGE,LANGUAGE
SM254348,145.98,-6.1,Kamano,ETHNICITY,ETHNICITY
SM233826,7.7119,9.8546,Kamantan,LANGUAGE,LANGUAGE
SM233819,81.9101,20.5342,Kamar,LANGUAGE,LANGUAGE
SM233825,-2.3494,8.9389,Kamara,LANGUAGE,LANGUAGE
SM305047,42.5854,15.3605,Kamaran,ADM2,DISTRICT
SM11656,78.3342,18.3257,Kamareddy,ADM2,DISTRICT
SM233830,128.526,-3.4078,Kamarian,LANGUAGE,LANGUAGE
SM233832,123.045,-5.157,Kamaru,LANGUAGE,LANGUAGE
SM233820,94.83,55.07,Kamas-Koibal,LANGUAGE,LANGUAGE
SM233828,146.208,-6.9741,Kamasa,LANGUAGE,LANGUAGE
SM233835,143.842,-3.8503,Kamasau,LANGUAGE,LANGUAGE
SM18403,66.4747,38.7931,Kamashi District,ADM2,DISTRICT
SM233831,126.314,8.108,Kamayo,LANGUAGE,LANGUAGE
SM254354,-52.02,-11.01,Kamayura,ETHNICITY,ETHNICITY
SM254354,-52.02,-11.01,Kamayura,ETHNICITY,ETHNICITY
SM254354,-54,-12,Kamayura,ETHNICITY,ETHNICITY
SM254354,-54,-12,Kamayura,ETHNICITY,ETHNICITY
SM233840,-53.4651,-12.2661,Kamayurá,LANGUAGE,LANGUAGE
SM250338,40.65,0.29,Kamba,ETHNICITY,ETHNICITY
SM250338,40.65,0.29,Kamba,ETHNICITY,ETHNICITY
SM250338,40.65,0.29,Kamba,ETHNICITY,ETHNICITY
SM250338,38,-2.25,Kamba,ETHNICITY,ETHNICITY
SM250338,38,-2.25,Kamba,ETHNICITY,ETHNICITY
SM250338,38,-2.25,Kamba,ETHNICITY,ETHNICITY
SM250338,38,-2,Kamba,ETHNICITY,ETHNICITY
SM250338,38,-2,Kamba,ETHNICITY,ETHNICITY
SM250338,38,-2,Kamba,ETHNICITY,ETHNICITY
SM233846,-57.3333,-18.8333,Kamba (Brazil),LANGUAGE,LANGUAGE
SM233848,37.9532,-1.6083,Kamba (Kenya),LANGUAGE,LANGUAGE
SM233859,37.9088,7.3758,Kambaata,LANGUAGE,LANGUAGE
SM233854,146.083,-6.2666,Kambaira,LANGUAGE,LANGUAGE
SM233850,120.235,-9.8845,Kambera,LANGUAGE,LANGUAGE
SM233853,133.518,-3.5319,Kamberau,LANGUAGE,LANGUAGE
SM295488,-12.8131,9.1825,Kambia,ADM1:ADM2,DISTRICT
SM233845,-36.5993,-8.6094,Kambiwá,LANGUAGE,LANGUAGE
SM11168,26.69,58.2026,Kambja vald,ADM2,DISTRICT
SM269733,26.6333,-10.8666,Kambove,ADM2,DISTRICT
SM1704,159,55,Kamchatka (2007-),ADM1,DISTRICT
SM280398,105.6446,11.587,Kamchay Mear,ADM2,DISTRICT
SM233866,29.119,11.3912,Kamda,DIALECT,LANGUAGE
SM260141,71.3145,35.4833,Kamdesh,ADM2,DISTRICT
SM262028,29.3893,-3.3445,Kamenge,ADM2,DISTRICT
SM13349,21.6167,42.6,Kamenica,ADM2,DISTRICT
SM262399,27.1833,42.5167,Kameno,ADM2,DISTRICT
SM294703,37.75,53.25,Kamenskiy rayon,ADM2,DISTRICT
SM294970,39.4219,50.715,Kamenskiy rayon,ADM2,DISTRICT
SM294521,61.75,56.5,Kamenskiy rayon,ADM2,DISTRICT
SM294150,40.2488,48.2482,Kamenskiy rayon,ADM2,DISTRICT
SM292963,81.3333,53.6667,Kamenskiy rayon,ADM2,DISTRICT
SM278689,135.539,35.0102,Kameoka,ADM2,DISTRICT
SM294001,46.0833,52.75,Kameshkirskiy rayon,ADM2,DISTRICT
SM294895,40.9167,56.3333,Kameshkovskiy rayon,ADM2,DISTRICT
SM278716,136.3935,34.8687,Kameyama,ADM2,DISTRICT
SM278615,133.8532,33.7082,Kami,ADM2,DISTRICT
SM233876,6.6609,8.5744,Kami (Nigeria),LANGUAGE,LANGUAGE
SM233874,37.98,-6.7108,Kami (Tanzania),LANGUAGE,LANGUAGE
SM278620,130.4082,32.4955,Kami-Amakusa,ADM2,DISTRICT
SM12945,140.711,38.5838,Kami-gun,ADM2,DISTRICT
SM254361,-116.8,32.66,Kamia,ETHNICITY,ETHNICITY
SM254361,-116.8,32.66,Kamia,ETHNICITY,ETHNICITY
SM254361,-116.8,32.66,Kamia,ETHNICITY,ETHNICITY
SM254361,-115,33,Kamia,ETHNICITY,ETHNICITY
SM254361,-115,33,Kamia,ETHNICITY,ETHNICITY
SM254361,-115,33,Kamia,ETHNICITY,ETHNICITY
SM254361,-115,32.5,Kamia,ETHNICITY,ETHNICITY
SM254361,-115,32.5,Kamia,ETHNICITY,ETHNICITY
SM254361,-115,32.5,Kamia,ETHNICITY,ETHNICITY
SM18223,33.9975,48.372,Kamianskyi Raion,ADM2,DISTRICT
SM288677,16.0047,50.756,Kamienna Góra,ADM2,DISTRICT
SM288841,14.7442,53.911,Kamień,ADM2,DISTRICT
SM278334,134.3667,34.8667,Kamigōri,ADM2,DISTRICT
SM12946,137.9073,35.771,Kamiina-gun,ADM2,DISTRICT
SM12947,140.3778,41.679,Kamiiso-gun,ADM2,DISTRICT
SM279639,133.2098,34.2535,Kamijima,ADM2,DISTRICT
SM12948,142.8731,43.0122,Kamikawa (Tokachi),ADM2,DISTRICT
SM12949,142.8374,43.5943,Kamikawa (ishikara),ADM2,DISTRICT
SM12950,142.3814,44.056,Kamikawa-gun (Teshio no kuni),ADM2,DISTRICT
SM12951,141.226,40.8226,Kamikita-gun,ADM2,DISTRICT
SM278583,140.3186,39.95,Kamikoani,ADM2,DISTRICT
SM254362,150.54,-31.26,Kamilaroi,ETHNICITY,ETHNICITY
SM254362,150.54,-31.26,Kamilaroi,ETHNICITY,ETHNICITY
SM254362,150.54,-31.26,Kamilaroi,ETHNICITY,ETHNICITY
SM254362,147.25,-29.3,Kamilaroi,ETHNICITY,ETHNICITY
SM254362,147.25,-29.3,Kamilaroi,ETHNICITY,ETHNICITY
SM254362,147.25,-29.3,Kamilaroi,ETHNICITY,ETHNICITY
SM254362,149.8334,-29.7231,Kamilaroi,ETHNICITY,ETHNICITY
SM254362,149.8334,-29.7231,Kamilaroi,ETHNICITY,ETHNICITY
SM254362,149.8334,-29.7231,Kamilaroi,ETHNICITY,ETHNICITY
SM12952,130.9818,32.7111,Kamimashiki-gun,ADM2,DISTRICT
SM12953,138.2025,36.7697,Kamiminochi-gun,ADM2,DISTRICT
SM10749,32.7806,34.9228,Kaminaria,ADM2,DISTRICT
SM279342,139.9106,36.4455,Kaminokawa,ADM2,DISTRICT
SM279572,140.3333,38.1333,Kaminoyama,ADM2,DISTRICT
SM278374,140.7117,35.8513,Kamisu,ADM2,DISTRICT
SM12954,138.4079,36.6688,Kamitakai-gun,ADM2,DISTRICT
SM11657,94.5148,24.8526,Kamjong,ADM2,DISTRICT
SM296302,14.6672,46.2441,Kamnik,ADM1:ADM2,DISTRICT
SM233881,11.2712,10.0602,Kamo,LANGUAGE,LANGUAGE
SM278960,139.1107,37.6221,Kamo,ADM2,DISTRICT
SM12955,137.2103,35.5713,Kamo-gun (Gifu),ADM2,DISTRICT
SM18526,138.886,34.7684,Kamo-gun (Shizuoka),ADM2,DISTRICT
SM279353,140.1,35.1,Kamogawa,ADM2,DISTRICT
SM233883,-16.29,11.525,Kamona Bijogo,LANGUAGE,LANGUAGE
SM295143,29.8982,-2.0052,Kamonyi,ADM1:ADM2,DISTRICT
SM233882,135.883,-4.3043,Kamoro,LANGUAGE,LANGUAGE
SM254371,137.96,-5.09,Kamoro,ETHNICITY,ETHNICITY
SM254371,137.96,-5.09,Kamoro,ETHNICITY,ETHNICITY
SM254371,135,-4,Kamoro,ETHNICITY,ETHNICITY
SM254371,135,-4,Kamoro,ETHNICITY,ETHNICITY
SM1705,32.5831,0.3351,Kampala,ADM1:ADM2,DISTRICT
SM276560,101.2131,0.2344,Kampar,ADM2,DISTRICT
SM285562,5.9154,52.5618,Kampen,ADM2,DISTRICT
SM1706,99.5,16.25,Kamphaeng Phet,ADM1,DISTRICT
SM297095,99.9615,14.0282,Kamphaeng Saen,ADM2,DISTRICT
SM10750,33.1254,34.9056,Kampi,ADM2,DISTRICT
SM10751,33.2378,34.9897,Kampia,ADM2,DISTRICT
SM296104,-55.4054,5.7496,Kampong Baroe,ADM2,DISTRICT
SM280505,104.1714,10.5955,Kampong Bay,ADM2,DISTRICT
SM280470,105.445,11.98,Kampong Cham,ADM2,DISTRICT
SM280478,104.67,12.26,Kampong Chhnang,ADM2,DISTRICT
SM280479,104.7167,12.4269,Kampong Leaeng,ADM2,DISTRICT
SM280399,105.2627,11.5323,Kampong Leav,ADM2,DISTRICT
SM280399,105.2627,11.5323,Kampong Leav,ADM2,DISTRICT
SM280399,105.3354,11.4776,Kampong Leav,ADM2,DISTRICT
SM280399,105.3354,11.4776,Kampong Leav,ADM2,DISTRICT
SM280443,105.9413,10.9431,Kampong Rou,ADM2,DISTRICT
SM280520,103.9499,11.1406,Kampong Seila,ADM2,DISTRICT
SM11153,105.4375,12.0575,Kampong Siem,ADM2,DISTRICT
SM280493,104.72,12.7425,Kampong Svay,ADM2,DISTRICT
SM280400,105.5378,11.0947,Kampong Trabaek,ADM2,DISTRICT
SM280506,104.4667,10.55,Kampong Trach,ADM2,DISTRICT
SM280480,104.7569,11.9745,Kampong Tralach,ADM2,DISTRICT
SM10752,32.7305,35.0554,Kampos,ADM2,DISTRICT
SM280507,104.1101,10.7456,Kampot,ADM2,DISTRICT
SM10753,33.1035,35.3045,Kampyli,ADM2,DISTRICT
SM277383,91.6534,26.2232,Kamrup (2004-),ADM2,DISTRICT
SM277382,92.0076,26.0538,Kamrup Metropolitan,ADM2,DISTRICT
SM254374,-88.21,13.89,Kamsa,ETHNICITY,ETHNICITY
SM254374,-86.63,14.18,Kamsa,ETHNICITY,ETHNICITY
SM254376,54.2,29.7,Kamse,ETHNICITY,ETHNICITY
SM294615,49,55.1667,Kamsko-Ust'inskiy rayon,ADM2,DISTRICT
SM233893,131.098,-13.5393,Kamu,LANGUAGE,LANGUAGE
SM458877,130.7785,-13.6126,Kamu,ETHNICITY,ETHNICITY
SM250165,6,11,Kamuku,ETHNICITY,ETHNICITY
SM233894,142.639,-7.288,Kamula,LANGUAGE,LANGUAGE
SM4782,33.1167,0.95,Kamuli (2009-),ADM1:ADM2,DISTRICT
SM254378,142.97,-7.55,Kamura,ETHNICITY,ETHNICITY
SM233899,71.3388,35.4099,Kamviri,DIALECT:LANGUAGE,LANGUAGE
SM233900,13.5136,10.646,Kamwe,LANGUAGE,LANGUAGE
SM250342,13.93,10.39,Kamwe,ETHNICITY,ETHNICITY
SM250342,13.93,10.39,Kamwe,ETHNICITY,ETHNICITY
SM250342,14,11,Kamwe,ETHNICITY,ETHNICITY
SM250342,14,11,Kamwe,ETHNICITY,ETHNICITY
SM1713,30.4408,0.2164,Kamwenge (pre-2019),ADM1:ADM2,DISTRICT
SM18224,26.5718,48.6734,Kamyanets-Podilskyy Raion,ADM2,DISTRICT
SM18225,34.4139,47.4975,Kamyanka-Dniprovska Raion,ADM2,DISTRICT
SM18226,34.6054,48.517,Kamyanske urban raion,ADM2,DISTRICT
SM262582,23.6667,52.4167,Kamyanyets,ADM2,DISTRICT
SM277550,46.9167,34.9167,Kamyaran,ADM2,DISTRICT
SM16928,45.5,50.25,Kamyshinskiy Rayon,ADM2,DISTRICT
SM279862,62.5,51.6667,Kamystinskiy,ADM2,DISTRICT
SM293852,48.1667,46,Kamyzyakskiy rayon,ADM2,DISTRICT
SM278473,133.6745,34.0846,Kan'onji,ADM2,DISTRICT
SM254380,-0.45,8.35,Kana,ETHNICITY,ETHNICITY
SM301364,-93.2934,45.9452,Kanabec,ADM2,DISTRICT
SM1714,139.3333,35.4167,Kanagawa,ADM1:ADMX,DISTRICT
SM233907,120.887,23.3235,Kanakanavu,LANGUAGE,LANGUAGE
SM250185,12,10,Kanakuru,ETHNICITY,ETHNICITY
SM296255,13.6431,46.0897,Kanal,ADM1:ADM2,DISTRICT
SM285193,10.0973,9.5488,Kanam,ADM2,DISTRICT
SM233914,34.6378,-8.9187,Kanamalenga,DIALECT,LANGUAGE
SM254382,-69.69,-8.34,Kanamari,ETHNICITY,ETHNICITY
SM324124,-114.8792,50.7029,Kananaskis,ADM2:ADM3,DISTRICT
SM269773,22.4167,-5.9,Kananga,ADM2,DISTRICT
SM269773,22.4167,-5.9,Kananga,ADM2,DISTRICT
SM269773,22.505,-5.872,Kananga,ADM2,DISTRICT
SM269773,22.505,-5.872,Kananga,ADM2,DISTRICT
SM233904,77.1553,32.2843,Kanashi,LANGUAGE,LANGUAGE
SM16929,47.4167,55.5,Kanashskiy Rayon,ADM2,DISTRICT
SM233909,149.751,-10.1658,Kanasi,LANGUAGE,LANGUAGE
SM282974,-89.5328,20.9256,Kanasín,ADM2,DISTRICT
SM233902,79.2883,27.1074,Kanauji,LANGUAGE,LANGUAGE
SM303120,-81.5281,38.3366,Kanawha,ADM2,DISTRICT
SM278428,136.7084,36.5192,Kanazawa,ADM2,DISTRICT
SM1715,99.1,14.6333,Kanchanaburi,ADM1,DISTRICT
SM297635,99.5439,9.0726,Kanchanadit,ADM2,DISTRICT
SM375331,80.2833,28.8447,Kanchanpur,ADM2:ADM3,DISTRICT
SM277248,79.99,12.67,Kancheepuram,ADM2,DISTRICT
SM1716,65.75,31,Kandahar,ADM1,DISTRICT
SM260056,65.6861,31.5921,Kandahar City,ADM2,DISTRICT
SM280511,104.8667,11.4167,Kandal Stueng,ADM2,DISTRICT
SM293741,32.4461,67.1662,Kandalaksha gorsovet,ADM2,DISTRICT
SM233928,152.781,-4.3652,Kandas,LANGUAGE,LANGUAGE
SM13589,22.7806,57.039,Kandava,ADM2,DISTRICT
SM4020,22.705,56.9567,Kandavas Novads,ADM1,DISTRICT
SM13590,22.7396,57.0347,Kandavas pagasts,ADM2,DISTRICT
SM233930,144.966,-5.7367,Kandawo,LANGUAGE,LANGUAGE
SM233927,11.7076,-0.0078,Kande,LANGUAGE,LANGUAGE
SM288640,143.55,-5.8333,Kandep,ADM2,DISTRICT
SM277114,84,20.3,Kandhamal,ADM2,DISTRICT
SM262105,2.8674,11.0706,Kandi,ADM2,DISTRICT
SM280382,104.0259,12.7125,Kandieng,ADM2,DISTRICT
SM301365,-95.0047,45.1524,Kandiyohi,ADM2,DISTRICT
SM370990,46.0516,-17.4094,Kandreho,ADM2:ADM3,DISTRICT
SM288607,149.9167,-6.0833,Kandrian-Gloucester,ADM2,DISTRICT
SM1718,80.7073,7.3044,Kandy,ADM1:ADM2,DISTRICT
SM298662,30.158,41.0803,Kandıra,ADM2,DISTRICT
SM302897,-111.8879,37.2851,Kane,ADM2,DISTRICT
SM300631,-88.4287,41.9389,Kane,ADM2,DISTRICT
SM278434,141.0155,39.2061,Kanegasaki,ADM2,DISTRICT
SM295288,-13.1764,15.4917,Kanel,ADM2,DISTRICT
SM296842,15.6889,13.501,Kanem,ADM2,DISTRICT
SM1719,15,15,Kanem (2008-),ADM1,DISTRICT
SM250343,15.6,12.57,Kanem-bornou,ETHNICITY,ETHNICITY
SM250343,15.6,12.57,Kanem-bornou,ETHNICITY,ETHNICITY
SM250343,15.6,12.57,Kanem-bornou,ETHNICITY,ETHNICITY
SM250343,14,14,Kanem-bornou,ETHNICITY,ETHNICITY
SM250343,14,14,Kanem-bornou,ETHNICITY,ETHNICITY
SM250343,14,14,Kanem-bornou,ETHNICITY,ETHNICITY
SM250343,15,14,Kanem-bornou,ETHNICITY,ETHNICITY
SM250343,15,14,Kanem-bornou,ETHNICITY,ETHNICITY
SM250343,15,14,Kanem-bornou,ETHNICITY,ETHNICITY
SM233935,14.8437,13.8749,Kanembu,LANGUAGE,LANGUAGE
SM11169,26.7615,57.9806,Kanepi vald,ADM2,DISTRICT
SM293366,38.815,46.2281,Kanevskiy rayon,ADM2,DISTRICT
SM305658,13.8368,45.1224,Kanfanar,ADM2,DISTRICT
SM233939,100.671,22.4373,Kang,LANGUAGE,LANGUAGE
SM260137,61.8352,31.3211,Kang,ADM2,DISTRICT
SM297786,101.8854,13.0969,Kang Hang Maeo,ADM2,DISTRICT
SM280472,105.1515,11.9368,Kang Meas,ADM2,DISTRICT
SM233945,29.3463,11.0174,Kanga,LANGUAGE,LANGUAGE
SM283359,-8.6013,11.9652,Kangaba,ADM2,DISTRICT
SM298923,37.3973,39.2432,Kangal,ADM2,DISTRICT
SM254393,131.67,70.56,Kangalas,ETHNICITY,ETHNICITY
SM254394,-51.56,67.17,Kangamiut,ETHNICITY,ETHNICITY
SM9741,45.1353,39.4208,Kangarli Rayon,ADM2,DISTRICT
SM261698,137.3339,-35.8239,Kangaroo Island,ADM2,DISTRICT
SM254397,-49.91,68.62,Kangatsiak,ETHNICITY,ETHNICITY
SM295460,160.1558,-11.5982,Kangava,ADM2,DISTRICT
SM277523,47.9333,34.4833,Kangavar,ADM2,DISTRICT
SM305890,126.1403,39.0961,Kangdong,ADM2,DISTRICT
SM233946,112.968,-7.9834,Kangean,LANGUAGE,LANGUAGE
SM254399,-42.9,62.37,Kangerd,ETHNICITY,ETHNICITY
SM233948,144.816,-4.4153,Kanggape,LANGUAGE,LANGUAGE
SM289046,126.5478,40.9619,Kanggye,ADM2,DISTRICT
SM254400,-122.06,71.65,Kanghiryuarmiut,ETHNICITY,ETHNICITY
SM254401,-115.12,72.44,Kanghiryuatjiagmiut,ETHNICITY,ETHNICITY
SM233938,101.254,35.043,Kangjia,LANGUAGE,LANGUAGE
SM289078,125.6364,38.8661,Kangnam,ADM2,DISTRICT
SM233943,24.8202,3.4763,Kango (Bas-Uélé District),LANGUAGE,LANGUAGE
SM233942,27.0186,1.5986,Kango (Tshopo District),LANGUAGE,LANGUAGE
SM11658,93.9695,25.1511,Kangpokpi,ADM2,DISTRICT
SM276871,76.25,32.1667,Kangra,ADM2,DISTRICT
SM233937,76.366,31.915,Kangri,LANGUAGE,LANGUAGE
SM289165,125.5694,37.8856,Kangry'ŏng,ADM2,DISTRICT
SM289076,125.4589,38.9539,Kangsŏ,ADM2,DISTRICT
SM1720,127.45,38.7333,Kangwon Province,ADM1,DISTRICT
SM280401,105.5624,11.684,Kanhchriech,ADM2,DISTRICT
SM279794,137.066,35.4059,Kani,ADM2,DISTRICT
SM1721,45.106,-12.9547,Kani-Keli,ADM1,DISTRICT
SM254404,143.33,-12.15,Kanidji,ETHNICITY,ETHNICITY
SM254404,143.33,-12.15,Kanidji,ETHNICITY,ETHNICITY
SM254404,142.972,-13.3981,Kanidji,ETHNICITY,ETHNICITY
SM254404,142.972,-13.3981,Kanidji,ETHNICITY,ETHNICITY
SM233958,145.086,-1.5178,Kaniet,LANGUAGE,LANGUAGE
SM233959,145.5438,-0.8926,Kaniet-Dempwolff,LANGUAGE,LANGUAGE
SM14934,138.0594,9.4674,Kanifay Municipality,ADM2,DISTRICT
SM275040,-16.6667,13.45,Kanifing,ADM1:ADM2,DISTRICT
SM233951,77.2465,8.4416,Kanikkaran,LANGUAGE,LANGUAGE
SM233953,8.3764,9.525,Kaningdon-Nindem,LANGUAGE,LANGUAGE
SM233955,13.6111,-1.7132,Kaningi,LANGUAGE,LANGUAGE
SM233961,143.192,-4.4968,Kaningra,LANGUAGE,LANGUAGE
SM233957,150.253,-9.2315,Kaninuwa,LANGUAGE,LANGUAGE
SM233962,145.556,-6.3884,Kanite,LANGUAGE,LANGUAGE
SM254407,-65.43,58.95,Kanithlua,ETHNICITY,ETHNICITY
SM18227,31.4697,49.7515,Kaniv Raion,ADM2,DISTRICT
SM233952,116.3545,-33.4956,Kaniyang,DIALECT,LANGUAGE
SM233963,74.7355,25.6999,Kanjari,LANGUAGE,LANGUAGE
SM233964,142.893,-13.3243,Kanju,LANGUAGE,LANGUAGE
SM300632,-87.8618,41.1377,Kankakee,ADM2,DISTRICT
SM274983,-9.178,10.271,Kankan,ADM1:ADM2,DISTRICT
SM1722,-9.25,10.4,Kankan,ADM1,DISTRICT
SM233968,120.689,16.8548,Kankanaey,LANGUAGE,LANGUAGE
SM254414,121.03,17.07,Kankanaey,ETHNICITY,ETHNICITY
SM284907,7.3627,11.9738,Kankara,ADM2,DISTRICT
SM285194,9.6329,9.3931,Kanke,ADM2,DISTRICT
SM286646,-81.8575,8.895,Kankintú,ADM2,DISTRICT
SM284909,7.8,12.4667,Kankiya,ADM2,DISTRICT
SM283997,-11.1632,15.9563,Kankossa,ADM2,DISTRICT
SM233969,-73.3575,10.7502,Kankuamo,LANGUAGE,LANGUAGE
SM10754,33.2565,35.2324,Kanli,ADM2,DISTRICT
SM240736,76.1198,13.5878,Kannada,LANGUAGE,LANGUAGE
SM254416,76.64,12.52,Kannada,ETHNICITY,ETHNICITY
SM254416,76.64,12.52,Kannada,ETHNICITY,ETHNICITY
SM254416,75.01,13.41,Kannada,ETHNICITY,ETHNICITY
SM254416,75.01,13.41,Kannada,ETHNICITY,ETHNICITY
SM254416,76.04,17.42,Kannada,ETHNICITY,ETHNICITY
SM254416,76.04,17.42,Kannada,ETHNICITY,ETHNICITY
SM254416,75,15,Kannada,ETHNICITY,ETHNICITY
SM254416,75,15,Kannada,ETHNICITY,ETHNICITY
SM279279,138.994,35.1139,Kannami,ADM2,DISTRICT
SM277303,79.6785,27.0177,Kannauj,ADM2,DISTRICT
SM10755,32.9883,34.9894,Kannavia,ADM2,DISTRICT
SM10756,32.5735,34.9076,Kannaviou-Melamiou,ADM2,DISTRICT
SM277249,77.34,8.32,Kanniyakumari,ADM2,DISTRICT
SM276961,75.3333,12.1667,Kannur,ADM2,DISTRICT
SM284866,8.519,11.9816,Kano,ADM2,DISTRICT
SM1723,8.5,11.5,Kano (1991-),ADM1,DISTRICT
SM12956,131.8478,34.4532,Kanoashi Gun,ADM2,DISTRICT
SM233979,112.383,2.0378,Kanowit-Tanjong Melanau,LANGUAGE,LANGUAGE
SM278487,130.8673,31.4266,Kanoya,ADM2,DISTRICT
SM233980,-64.5812,-12.1789,Kanoê,LANGUAGE,LANGUAGE
SM277304,79.9896,26.4151,Kanpur Dehat (1996-),ADM2,DISTRICT
SM277305,80.21578,26.38052,Kanpur Nagar (1996-),ADM2,DISTRICT
SM12957,138.7379,36.2049,Kanra-gun,ADM2,DISTRICT
SM233983,-96.8571,36.8044,Kansa,LANGUAGE,LANGUAGE
SM254420,-96.49,39.32,Kansa,ETHNICITY,ETHNICITY
SM1724,-98.5006,38.5003,Kansas,ADM1,DISTRICT
SM16930,95.25,56.25,Kanskiy Rayon,ADM2,DISTRICT
SM294971,39.8353,49.676,Kantemirovskiy rayon,ADM2,DISTRICT
SM297572,104.6708,14.568,Kantharalak,ADM2,DISTRICT
SM297573,104.5732,15.1245,Kanthararom,ADM2,DISTRICT
SM297078,103.2589,16.2799,Kantharawichai,ADM2,DISTRICT
SM13591,27.1243,56.5844,Kantinieku pagasts,ADM2,DISTRICT
SM13327,-171.678,-2.81,Kanton,ADM0:ADM2,DISTRICT
SM275064,-13.9167,13.4167,Kantora,ADM2,DISTRICT
SM233986,-1.9281,10.9703,Kantosi,LANGUAGE,LANGUAGE
SM10757,32.9033,34.7006,Kantou,ADM2,DISTRICT
SM297674,99.4706,7.3856,Kantrang,ADM2,DISTRICT
SM282975,-88.9999,20.7806,Kantunil,ADM2,DISTRICT
SM233990,28.1017,-1.8256,Kanu,LANGUAGE,LANGUAGE
SM233989,8.6,9.55,Kanufi,LANGUAGE,LANGUAGE
SM254424,13.41,11.17,Kanum,ETHNICITY,ETHNICITY
SM254425,140.88,-8.66,Kanum-Anim,ETHNICITY,ETHNICITY
SM279343,139.6415,36.5755,Kanuma,ADM2,DISTRICT
SM1725,29.73,-0.75,Kanungu,ADM1:ADM2,DISTRICT
SM227166,-16.1497,11.1097,Kanyaki-Kagbaaga-Kajoko Bidyogo,LANGUAGE,LANGUAGE
SM233996,23.613,-7.3188,Kanyok,LANGUAGE,LANGUAGE
SM262038,29.4153,-3.431,Kanyosha1,ADM2,DISTRICT
SM262029,29.3559,-3.4551,Kanyosha2,ADM2,DISTRICT
SM279148,130.3588,33.3445,Kanzaki,ADM2,DISTRICT
SM12958,134.7693,35.0657,Kanzaki-gun,ADM2,DISTRICT
SM234002,127.847,1.1665,Kao,LANGUAGE,LANGUAGE
SM297487,102.0267,13.6229,Kao Cha Kan,ADM2,DISTRICT
SM297204,100.0965,15.8878,Kao Lieo,ADM2,DISTRICT
SM280454,105,10.75,Kaoh Andaet,ADM2,DISTRICT
SM280521,103.1667,11.5,Kaoh Kong,ADM2,DISTRICT
SM280369,106.9692,13.1016,Kaoh Nheaek,ADM2,DISTRICT
SM280473,105.3722,11.8859,Kaoh Soutin,ADM2,DISTRICT
SM280512,105.087,11.0868,Kaoh Thum,ADM2,DISTRICT
SM299053,120.3381,22.6533,Kaohsiung,ADM2,DISTRICT
SM1727,120.3662,22.6343,Kaohsiung,ADM1,DISTRICT
SM254427,159.82,-9.49,Kaoka,ETHNICITY,ETHNICITY
SM295277,-16.079,14.0573,Kaolack,ADM2,DISTRICT
SM1728,-16,14,Kaolack (2008-),ADM1,DISTRICT
SM262374,27.1167,43.6167,Kaolinovo,ADM2,DISTRICT
SM18135,24.407,-14.6429,Kaoma District (2012-),ADM2,DISTRICT
SM234000,25.7646,-12.4129,Kaonde,LANGUAGE,LANGUAGE
SM250345,25.87,-13.84,Kaonde subgoup,ETHNICITY,ETHNICITY
SM250345,25.87,-13.84,Kaonde subgoup,ETHNICITY,ETHNICITY
SM250345,26,-13,Kaonde subgoup,ETHNICITY,ETHNICITY
SM250345,26,-13,Kaonde subgoup,ETHNICITY,ETHNICITY
SM234019,142.659,-3.2876,Kap,LANGUAGE,LANGUAGE
SM297652,103.5848,14.468,Kap Choeng,ADM2,DISTRICT
SM18056,27.9771,41.3386,Kapakli,ADM2,DISTRICT
SM284507,19.5667,-17.9,Kapako,ADM2,DISTRICT
SM324002,-114.4454,53.5458,Kapasiwin,ADM2:ADM3,DISTRICT
SM254433,145.99,-7.59,Kapau,ETHNICITY,ETHNICITY
SM299289,34.42,1.3333,Kapchorwa (2010-),ADM1:ADM2,DISTRICT
SM10758,33.2527,34.969,Kapedes,ADM2,DISTRICT
SM305324,16.8517,45.9854,Kapela,ADM2,DISTRICT
SM285624,3.9652,51.4822,Kapelle,ADM2,DISTRICT
SM6007,82.9817,27.6192,Kapilbastu,ADM2:ADM3,DISTRICT
SM10759,32.9656,34.8311,Kapileio,ADM2,DISTRICT
SM234013,146.529,-7.0573,Kapin,LANGUAGE,LANGUAGE
SM234011,-38.3333,-8.5,Kapinawá,LANGUAGE,LANGUAGE
SM234012,154.7972,1.0392,Kapingamarangi,LANGUAGE,LANGUAGE
SM254435,154.8,1.09,Kapingamarangi,ETHNICITY,ETHNICITY
SM14935,154.8078,1.0825,Kapingamarangi Municipality,ADM2,DISTRICT
SM18136,28.515,-14.0775,Kapiri Mposhi (2012-),ADM2,DISTRICT
SM18136,28.515,-14.0775,Kapiri Mposhi (2012-),ADM2,DISTRICT
SM18136,27.748,-14.151,Kapiri Mposhi (2012-),ADM2,DISTRICT
SM18136,27.748,-14.151,Kapiri Mposhi (2012-),ADM2,DISTRICT
SM1730,69.5833,35,Kapisa,ADM1,DISTRICT
SM284353,113.75,2.1667,Kapit,ADM2,DISTRICT
SM286519,175.1469,-40.8568,Kapiti Coast,ADM2,DISTRICT
SM13592,27.1709,55.843,Kaplavas pagasts,ADM2,DISTRICT
SM297442,98.6237,9.5303,Kapoe,ADM2,DISTRICT
SM297300,98.4754,8.7414,Kapong,ADM2,DISTRICT
SM254437,151.06,-5.74,Kapore,ETHNICITY,ETHNICITY
SM234016,139.766,-3.527,Kapori,LANGUAGE,LANGUAGE
SM234021,143.241,-4.5101,Kapriman,LANGUAGE,LANGUAGE
SM289086,128.3864,41.1228,Kapsan,ADM2,DISTRICT
SM13191,77.0833,43.8833,Kapshagay city,ADM2,DISTRICT
SM234022,139.7958,-2.3884,Kaptiau,LANGUAGE,LANGUAGE
SM305404,17.7261,45.4324,Kaptol,ADM2,DISTRICT
SM276413,114.3833,-2.0167,Kapuas,ADM2,DISTRICT
SM276383,112.7667,0.8167,Kapuas Hulu,ADM2,DISTRICT
SM458499,138.5961,-22.1328,Kapula,ETHNICITY,ETHNICITY
SM277143,75.31,31.4,Kapurthala,ADM2,DISTRICT
SM18137,29.4352,-8.7505,Kaputa District (2012-),ADM2,DISTRICT
SM276266,17.0406,47.5671,Kapuvár-Beled,ADM2,DISTRICT
SM234025,10.0979,7.0326,Kapya,LANGUAGE,LANGUAGE
SM262660,27.0833,53.1667,Kapyl',ADM2,DISTRICT
SM234026,-90.9881,14.4662,Kaqchikel,LANGUAGE,LANGUAGE
SM234033,-90.99,14.47,"Kaqchikel, Akatenango Southwestern",LANGUAGE,LANGUAGE
SM234029,-90.99,14.47,"Kaqchikel, Eastern",LANGUAGE,LANGUAGE
SM234031,-90.99,14.47,"Kaqchikel, Northern",LANGUAGE,LANGUAGE
SM234032,-90.99,14.47,"Kaqchikel, Santa María de Jesús",LANGUAGE,LANGUAGE
SM234034,-90.99,14.47,"Kaqchikel, Santo Domingo Xenacoj",LANGUAGE,LANGUAGE
SM234028,-90.99,14.47,"Kaqchikel, South Central",LANGUAGE,LANGUAGE
SM234030,-90.99,14.47,"Kaqchikel, Southern",LANGUAGE,LANGUAGE
SM234035,-90.99,14.47,"Kaqchikel, Western",LANGUAGE,LANGUAGE
SM234027,-90.99,14.47,"Kaqchikel, Yepocapa Southwestern",LANGUAGE,LANGUAGE
SM254444,-91,14,Kaqchiquel,ETHNICITY,ETHNICITY
SM254444,-91,14,Kaqchiquel,ETHNICITY,ETHNICITY
SM254444,-91,15,Kaqchiquel,ETHNICITY,ETHNICITY
SM254444,-91,15,Kaqchiquel,ETHNICITY,ETHNICITY
SM1731,0.9167,9.6667,Kara,ADM1,DISTRICT
SM234059,22.9947,9.9953,Kara (Central African Republic),LANGUAGE,LANGUAGE
SM234063,151.111,-2.8196,Kara (Papua New Guinea),LANGUAGE,LANGUAGE
SM234058,33.0488,-1.8351,Kara (Tanzania),LANGUAGE,LANGUAGE
SM280340,71.4167,42.5,Kara-Buura,ADM2,DISTRICT
SM234045,63.3223,39.98,Kara-Kalpak,LANGUAGE,LANGUAGE
SM280335,73.4941,40.6409,Kara-Kuldja,ADM2,DISTRICT
SM13364,73.08,40.37,Kara-Suu,ADM2,DISTRICT
SM18057,27.127,38.3691,Karabaglar,ADM2,DISTRICT
SM279863,61.75,53.5833,Karabalykskiy,ADM2,DISTRICT
SM292741,60.1667,55.5,Karabash gorsovet,ADM2,DISTRICT
SM254445,51.48,18.14,Karabi,ETHNICITY,ETHNICITY
SM292828,47.5,42.6667,Karabudokhkentskiy rayon,ADM2,DISTRICT
SM298535,26.4965,38.6355,Karaburun,ADM2,DISTRICT
SM1732,32.5,41.25,Karabük,ADM1,DISTRICT
SM298259,28.3666,40.2229,Karacabey,ADM2,DISTRICT
SM298158,28.594,37.7473,Karacasu,ADM2,DISTRICT
SM254446,42.25,43.56,Karachay,ETHNICITY,ETHNICITY
SM254446,42.25,43.56,Karachay,ETHNICITY,ETHNICITY
SM254446,42.52,43.94,Karachay,ETHNICITY,ETHNICITY
SM254446,42.52,43.94,Karachay,ETHNICITY,ETHNICITY
SM234043,42.2606,43.565,Karachay-Balkar,LANGUAGE,LANGUAGE
SM1733,42,44,Karachay-Cherkess,ADM1,DISTRICT
SM16931,42,43.5,Karachayevskiy Rayon,ADM2,DISTRICT
SM295034,35,53.0833,Karachevskiy rayon,ADM2,DISTRICT
SM375488,67.0715,24.9553,Karachi Central,ADM2:ADM3,DISTRICT
SM375490,67.0331,24.8243,Karachi South,ADM2:ADM3,DISTRICT
SM375491,66.8666,24.9085,Karachi west,ADM2:ADM3,DISTRICT
SM234053,122.334,-18.6771,Karadjeri,LANGUAGE,LANGUAGE
SM274939,-0.5352,9.9057,Karaga,ADM2,DISTRICT
SM293100,159.8333,58,Karaginskiy rayon,ADM2,DISTRICT
SM250337,-8.84,15.18,Karago,ETHNICITY,ETHNICITY
SM299229,31.0426,-1.6905,Karagwe,ADM2,DISTRICT
SM298988,29.5392,38.3309,Karahallı,ADM2,DISTRICT
SM234052,-57.8716,-1.2999,Karahawyana,LANGUAGE,LANGUAGE
SM294264,57,55.75,Karaidel'skiy rayon,ADM2,DISTRICT
SM277137,79.8335,10.9221,Karaikal,ADM2,DISTRICT
SM234042,25.1054,54.5534,Karaim,LANGUAGE,LANGUAGE
SM461272,35.0503,37.2666,Karaisali (2008-),ADM2,DISTRICT
SM11778,51.0957,35.966,Karaj,ADM2,DISTRICT
SM254449,-49.23,-10.87,Karaja,ETHNICITY,ETHNICITY
SM254449,-49.23,-10.87,Karaja,ETHNICITY,ETHNICITY
SM254449,-49.23,-10.87,Karaja,ETHNICITY,ETHNICITY
SM254449,-50,-12.5,Karaja,ETHNICITY,ETHNICITY
SM254449,-50,-12.5,Karaja,ETHNICITY,ETHNICITY
SM254449,-50,-12.5,Karaja,ETHNICITY,ETHNICITY
SM254449,-50,-12,Karaja,ETHNICITY,ETHNICITY
SM254449,-50,-12,Karaja,ETHNICITY,ETHNICITY
SM254449,-50,-12,Karaja,ETHNICITY,ETHNICITY
SM254450,-55.08,73.26,Karajak,ETHNICITY,ETHNICITY
SM234077,-50.3492,-11.1881,Karajá,LANGUAGE,LANGUAGE
SM375423,71.0834,33.1361,Karak,ADM2:ADM3,DISTRICT
SM1734,35.75,31.1667,Karak,ADM1,DISTRICT
SM254453,74.21,33.4,Karakalpak,ETHNICITY,ETHNICITY
SM254453,74.21,33.4,Karakalpak,ETHNICITY,ETHNICITY
SM254453,59,43,Karakalpak,ETHNICITY,ETHNICITY
SM254453,59,43,Karakalpak,ETHNICITY,ETHNICITY
SM1735,58.8299,44.2164,Karakalpakstan,ADM1,DISTRICT
SM298632,33.3826,39.6047,Karakeçili,ADM2,DISTRICT
SM18058,38.8111,37.1803,Karakoeprue,ADM2,DISTRICT
SM10760,33.3421,35.3346,Karakoumi,ADM2,DISTRICT
SM298468,43.633,39.8804,Karakoyunlu,ADM2,DISTRICT
SM298356,40.0405,38.9553,Karakoçan,ADM2,DISTRICT
SM294852,53.6667,56.0833,Karakulinskiy rayon,ADM2,DISTRICT
SM1736,33.25,37.0833,Karaman,ADM1,DISTRICT
SM298242,29.8278,37.384,Karamanlı,ADM2,DISTRICT
SM269356,84.9168,45.4999,Karamay,ADM2,DISTRICT
SM234074,143.117,-7.1333,Karami,LANGUAGE,LANGUAGE
SM234060,34.2761,2.4516,Karamojong,LANGUAGE,LANGUAGE
SM250349,34.74,2.52,Karamojong,ETHNICITY,ETHNICITY
SM250349,34.74,2.52,Karamojong,ETHNICITY,ETHNICITY
SM250349,34,2,Karamojong,ETHNICITY,ETHNICITY
SM250349,34,2,Karamojong,ETHNICITY,ETHNICITY
SM10761,32.4436,35.0001,Karamoullides,ADM2,DISTRICT
SM298663,29.6069,40.6838,Karamürsel,ADM2,DISTRICT
SM234055,15.2114,8.0173,Karang,LANGUAGE,LANGUAGE
SM234061,20.6354,13.1748,Karanga,LANGUAGE,LANGUAGE
SM250347,32.71,-21.21,Karanga,ETHNICITY,ETHNICITY
SM276305,111.0625,-7.6281,Karanganyar,ADM2,DISTRICT
SM276479,115.5393,-8.3891,Karangasem,ADM2,DISTRICT
SM254459,138.88,-26.94,Karanguru,ETHNICITY,ETHNICITY
SM254459,138.88,-26.94,Karanguru,ETHNICITY,ETHNICITY
SM254459,138.7338,-25.9749,Karanguru,ETHNICITY,ETHNICITY
SM254459,138.7338,-25.9749,Karanguru,ETHNICITY,ETHNICITY
SM235112,44.895,37.794,Karanic,LANGUAGE,LANGUAGE
SM234036,-96.4856,28.9082,Karankawa,LANGUAGE,LANGUAGE
SM254460,-96.01,29.03,Karankawa,ETHNICITY,ETHNICITY
SM254460,-96.01,29.03,Karankawa,ETHNICITY,ETHNICITY
SM254460,-96.01,29.03,Karankawa,ETHNICITY,ETHNICITY
SM254460,-96,29,Karankawa,ETHNICITY,ETHNICITY
SM254460,-96,29,Karankawa,ETHNICITY,ETHNICITY
SM254460,-96,29,Karankawa,ETHNICITY,ETHNICITY
SM254460,-96.91,28.44,Karankawa,ETHNICITY,ETHNICITY
SM254460,-96.91,28.44,Karankawa,ETHNICITY,ETHNICITY
SM254460,-96.91,28.44,Karankawa,ETHNICITY,ETHNICITY
SM458678,139.8671,-24.3855,Karanya,ETHNICITY,ETHNICITY
SM234064,120.956,16.5655,Karao,LANGUAGE,LANGUAGE
SM298844,30.5451,40.6519,Karapürçek,ADM2,DISTRICT
SM298679,33.5576,37.7264,Karapınar,ADM2,DISTRICT
SM295229,32.1213,16.1435,Karary,ADM2,DISTRICT
SM284455,18.75,-28.0167,Karas,ADM2,DISTRICT
SM13192,76.6215,43.1994,Karasay (excl. Qaskeleng),ADM2,DISTRICT
SM286352,25.5189,69.4722,Karasjok,ADM2,DISTRICT
SM298845,30.6824,41.0998,Karasu,ADM2,DISTRICT
SM293859,78.0833,53.75,Karasukskiy rayon,ADM2,DISTRICT
SM285259,10.7835,12.9628,Karasuwa,ADM2,DISTRICT
SM234051,46.3151,42.6501,Karata,LANGUAGE,LANGUAGE
SM279845,77.25,45.6667,Karatalskiy,ADM2,DISTRICT
SM298680,32.9983,37.94,Karatay,ADM2,DISTRICT
SM298132,35.3678,36.5719,Karataş,ADM2,DISTRICT
SM279894,53.5,49.6667,Karatobinskiy,ADM2,DISTRICT
SM279149,129.9978,33.418,Karatsu,ADM2,DISTRICT
SM299075,35.6667,-3.3333,Karatu,ADM2,DISTRICT
SM293430,93,53.5,Karatuzskiy rayon,ADM2,DISTRICT
SM277178,77.1,26.58,Karauli,ADM2,DISTRICT
SM10762,33.23,35.3435,Karavas,ADM2,DISTRICT
SM10763,32.8238,35.1393,Karavostasi,ADM2,DISTRICT
SM234072,142.018,-3.6993,Karawa,LANGUAGE,LANGUAGE
SM276759,107.4167,-6.2667,Karawang,ADM2,DISTRICT
SM254465,122.17,10.47,Karay-A,ETHNICITY,ETHNICITY
SM298405,42.145,39.7029,Karayazı,ADM2,DISTRICT
SM284867,8.0098,11.7625,Karaye,ADM2,DISTRICT
SM298404,42.1097,39.3516,Karaçoban,ADM2,DISTRICT
SM305786,43.8903,32.4263,Karbala,ADM2,DISTRICT
SM1737,43.8333,32.5,Karbala',ADM1,DISTRICT
SM254466,92.6,25.57,Karbi,ETHNICITY,ETHNICITY
SM254466,92.6,25.57,Karbi,ETHNICITY,ETHNICITY
SM254466,92.6,25.57,Karbi,ETHNICITY,ETHNICITY
SM254466,93,26,Karbi,ETHNICITY,ETHNICITY
SM254466,93,26,Karbi,ETHNICITY,ETHNICITY
SM254466,93,26,Karbi,ETHNICITY,ETHNICITY
SM254466,94,26,Karbi,ETHNICITY,ETHNICITY
SM254466,94,26,Karbi,ETHNICITY,ETHNICITY
SM254466,94,26,Karbi,ETHNICITY,ETHNICITY
SM11659,93.5,26,Karbi Anglong (2015-),ADM2,DISTRICT
SM1738,22.2833,41.8,Karbinci,ADM1,DISTRICT
SM276130,20.8422,47.2858,Karcag,ADM2,DISTRICT
SM262529,25.4333,41.6833,Kardzhali,ADM2,DISTRICT
SM1739,25.4333,41.5333,Kardzhali,ADM1,DISTRICT
SM250350,25.29,7.04,Kare,ETHNICITY,ETHNICITY
SM234095,15.2207,7.0564,Kare (Central African Republic),LANGUAGE,LANGUAGE
SM234098,145.499,-5.0767,Kare (Papua New Guinea),LANGUAGE,LANGUAGE
SM234099,10.8732,12.5176,Karekare,LANGUAGE,LANGUAGE
SM250351,11,12,Karekare,ETHNICITY,ETHNICITY
SM274781,43.8955,42.0304,Kareli,ADM2,DISTRICT
SM1740,32.5,64,Karelia,ADM1,DISTRICT
SM234092,30.8655,65.1691,Karelian,LANGUAGE,LANGUAGE
SM254467,33.12,63.24,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,33.12,63.24,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,33.12,63.24,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,33.12,63.24,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,33,64,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,33,64,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,33,64,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,33,64,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,32.58,60.59,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,32.58,60.59,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,32.58,60.59,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,32.58,60.59,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,31.1,60.12,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,31.1,60.12,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,31.1,60.12,Karelian Izhars,ETHNICITY,ETHNICITY
SM254467,31.1,60.12,Karelian Izhars,ETHNICITY,ETHNICITY
SM262619,26.25,53.5,Karelichy,ADM2,DISTRICT
SM254468,131.96,-0.87,Karen,ETHNICITY,ETHNICITY
SM4418,-12.2218,9.6639,Karene,ADM2,DISTRICT
SM458670,142.6689,-29.1757,Karenggapa,ETHNICITY,ETHNICITY
SM18059,27.8357,39.7665,Karesi Ilcesi,ADM2,DISTRICT
SM234096,134.375,-6.7418,Karey,LANGUAGE,LANGUAGE
SM279936,58.3333,50.75,Kargalinskiy,ADM2,DISTRICT
SM293490,64.3333,55.8333,Kargapol'skiy rayon,ADM2,DISTRICT
SM293860,80.25,55.25,Kargatskiy rayon,ADM2,DISTRICT
SM276897,76.4845,33.9666,Kargil,ADM2,DISTRICT
SM293603,39,61.3333,Kargopol'skiy rayon,ADM2,DISTRICT
SM298296,34.4934,41.1438,Kargı,ADM2,DISTRICT
SM234110,25.7491,5.0352,Kari,LANGUAGE,LANGUAGE
SM305256,28.6598,-16.9101,Kariba,ADM2,DISTRICT
SM254472,118.37,-20.55,Kariera,ETHNICITY,ETHNICITY
SM254472,118.37,-20.55,Kariera,ETHNICITY,ETHNICITY
SM254472,118.37,-20.55,Kariera,ETHNICITY,ETHNICITY
SM254472,118.37,-20.55,Kariera,ETHNICITY,ETHNICITY
SM254472,118,-21,Kariera,ETHNICITY,ETHNICITY
SM254472,118,-21,Kariera,ETHNICITY,ETHNICITY
SM254472,118,-21,Kariera,ETHNICITY,ETHNICITY
SM254472,118,-21,Kariera,ETHNICITY,ETHNICITY
SM254472,118.27,-20.88,Kariera,ETHNICITY,ETHNICITY
SM254472,118.27,-20.88,Kariera,ETHNICITY,ETHNICITY
SM254472,118.27,-20.88,Kariera,ETHNICITY,ETHNICITY
SM254472,118.27,-20.88,Kariera,ETHNICITY,ETHNICITY
SM254472,118.4788,-20.8708,Kariera,ETHNICITY,ETHNICITY
SM254472,118.4788,-20.8708,Kariera,ETHNICITY,ETHNICITY
SM254472,118.4788,-20.8708,Kariera,ETHNICITY,ETHNICITY
SM254472,118.4788,-20.8708,Kariera,ETHNICITY,ETHNICITY
SM285256,10.8654,9.2221,Karim-La,ADM2,DISTRICT
SM262106,2.757,11.9382,Karimama,ADM2,DISTRICT
SM277385,92.4,24.6,Karimganj,ADM2,DISTRICT
SM11660,79.2294,18.3384,Karimnagar (2016-),ADM2,DISTRICT
SM288618,144.8333,-6.5833,Karimui-Nomane,ADM2,DISTRICT
SM276434,103.4191,0.8076,Karimun,ADM2,DISTRICT
SM234107,47.4329,38.5656,Karingani-Kalasuri-Khoynarudi,LANGUAGE,LANGUAGE
SM234115,-65.5,-10,Karipuna,LANGUAGE,LANGUAGE
SM234113,-52.42,-2.92,Karipuna do Amapa,LANGUAGE,LANGUAGE
SM254476,-66.9,-9.16,Karipuna-Kawahib,ETHNICITY,ETHNICITY
SM234117,-64.2807,-10.2006,Karipúna,LANGUAGE,LANGUAGE
SM234103,-40.4873,-7.4346,Kariri,LANGUAGE,LANGUAGE
SM234104,-36.3333,-9.85,Karirí-Xocó,LANGUAGE,LANGUAGE
SM454493,-63,-10,Karitiana,ETHNICITY,ETHNICITY
SM234114,-64.1522,-9.3794,Karitiâna,LANGUAGE,LANGUAGE
SM278961,138.6405,37.4234,Kariwa,ADM2,DISTRICT
SM234116,9.6399,11.0119,Kariya,LANGUAGE,LANGUAGE
SM278033,137.0017,35.011,Kariya,ADM2,DISTRICT
SM234108,118.613,-20.445,Kariyarra,LANGUAGE,LANGUAGE
SM298422,37.9222,36.8642,Karkamış,ADM2,DISTRICT
SM234122,141.098,-3.7495,Karkar-Yuri,LANGUAGE,LANGUAGE
SM12037,44.2774,33.2536,Karkh,ADM2,DISTRICT
SM234123,-122.167,40.56,Karkin,LANGUAGE,LANGUAGE
SM234120,29.2074,12.0542,Karko,LANGUAGE,LANGUAGE
SM13593,25.6195,57.8195,Karku pagasts,ADM2,DISTRICT
SM299662,35.1346,49.4545,Karlivs'kyi,ADM2,DISTRICT
SM305755,15.0725,44.5284,Karlobag,ADM2,DISTRICT
SM276056,15.5333,45.5,Karlovac,ADM2,DISTRICT
SM1741,15.5833,45.3333,Karlovacka,ADM1,DISTRICT
SM1742,12.6667,50.1667,Karlovarský,ADM1,DISTRICT
SM271214,13,50.1667,Karlovy Vary,ADM2,DISTRICT
SM296577,14.4542,58.6099,Karlsborg,ADM2,DISTRICT
SM296384,14.8667,56.25,Karlshamn,ADM2,DISTRICT
SM296410,14.5672,59.3738,Karlskoga,ADM2,DISTRICT
SM296385,15.6685,56.2817,Karlskrona,ADM2,DISTRICT
SM18270,8.6667,49,Karlsruhe Region,ADM2,DISTRICT
SM296518,13.5996,59.4741,Karlstad,ADM2,DISTRICT
SM286224,19.6181,69.9619,Karlsøy,ADM2,DISTRICT
SM370140,-7.6906,4.6936,Karluway #2,ADM2,DISTRICT
SM14056,-7.7444,4.8198,Karluway Number One District,ADM2:ADM3,DISTRICT
SM298203,41.0134,39.2978,Karlıova,ADM2,DISTRICT
SM262603,30.8333,53.1667,Karma,ADM2,DISTRICT
SM279865,63.75,45.5,Karmakchinskiy,ADM2,DISTRICT
SM254481,86.35,23.97,Karmali,ETHNICITY,ETHNICITY
SM303547,65.2156,40.0202,Karmana,ADM2,DISTRICT
SM294265,56,54.3333,Karmaskalinskiy rayon,ADM2,DISTRICT
SM10764,33.2635,35.3169,Karmi,ADM2,DISTRICT
SM286129,5.3053,59.2808,Karmøy,ADM2,DISTRICT
SM234127,147.917,-5.7413,Karnai,LANGUAGE,LANGUAGE
SM276845,76.8333,29.6667,Karnal (1996-),ADM2,DISTRICT
SM1743,75.8333,14.6667,Karnataka,ADM1,DISTRICT
SM302671,-97.8594,28.9057,Karnes,ADM2,DISTRICT
SM262400,26.9833,42.65,Karnobat,ADM2,DISTRICT
SM276705,98.3,3.1167,Karo,ADM2,DISTRICT
SM234143,-61.5691,-10.3342,Karo (Brazil),LANGUAGE,LANGUAGE
SM234135,36.1709,5.2101,Karo (Ethiopia),LANGUAGE,LANGUAGE
SM305659,13.8214,45.3029,Karojba,ADM2,DISTRICT
SM234142,-123.315,41.8228,Karok,LANGUAGE,LANGUAGE
SM254489,-122.05,41.64,Karok,ETHNICITY,ETHNICITY
SM254489,-122.05,41.64,Karok,ETHNICITY,ETHNICITY
SM254489,-122.05,41.64,Karok,ETHNICITY,ETHNICITY
SM254489,-122.05,41.64,Karok,ETHNICITY,ETHNICITY
SM254489,-123,42,Karok,ETHNICITY,ETHNICITY
SM254489,-123,42,Karok,ETHNICITY,ETHNICITY
SM254489,-123,42,Karok,ETHNICITY,ETHNICITY
SM254489,-123,42,Karok,ETHNICITY,ETHNICITY
SM254489,-123.47,41.58,Karok,ETHNICITY,ETHNICITY
SM254489,-123.47,41.58,Karok,ETHNICITY,ETHNICITY
SM254489,-123.47,41.58,Karok,ETHNICITY,ETHNICITY
SM254489,-123.47,41.58,Karok,ETHNICITY,ETHNICITY
SM254489,-123.5,41.4,Karok,ETHNICITY,ETHNICITY
SM254489,-123.5,41.4,Karok,ETHNICITY,ETHNICITY
SM254489,-123.5,41.4,Karok,ETHNICITY,ETHNICITY
SM254489,-123.5,41.4,Karok,ETHNICITY,ETHNICITY
SM234132,-16.7382,12.9918,Karon,LANGUAGE,LANGUAGE
SM234141,132.663,-0.7871,Karon Dori,DIALECT,LANGUAGE
SM1745,33.8815,-10.1115,Karonga,ADM1:ADM2,DISTRICT
SM295156,29.3617,-2.1296,Karongi,ADM1:ADM2,DISTRICT
SM261699,140.0248,-34.9285,Karoonda East Murray,ADM2,DISTRICT
SM234136,149.907,-6.1556,Karore,LANGUAGE,LANGUAGE
SM10765,33.0686,35.2879,Karpaseia,ADM2,DISTRICT
SM1746,21.3833,42,Karpoš,ADM1:ADM2,DISTRICT
SM298159,27.8407,37.5688,Karpuzlu,ADM2,DISTRICT
SM458124,131.3606,-16.2146,Karranga,ETHNICITY,ETHNICITY
SM234145,140.0263,-25.833,Karruwali,LANGUAGE,LANGUAGE
SM1747,43.0833,40.4167,Kars (1994-),ADM1,DISTRICT
SM13594,27.6818,56.7869,Karsava,ADM2,DISTRICT
SM3981,27.6764,56.7633,Karsavas Novads,ADM1,DISTRICT
SM18060,27.115,38.4678,Karsiyaka Ilcesi (2008-),ADM2,DISTRICT
SM294875,46.9167,54.25,Karsunskiy rayon,ADM2,DISTRICT
SM298509,29.2119,40.912,Kartal,ADM2,DISTRICT
SM16932,60.5,53,Kartalinskiy Rayon,ADM2,DISTRICT
SM458125,130.4807,-19.2503,Kartangarurru,ETHNICITY,ETHNICITY
SM18061,30.0279,40.7525,Kartepe,ADM2,DISTRICT
SM458071,122.0625,-23.283,Kartujarra,ETHNICITY,ETHNICITY
SM234149,121.7321,-23.2791,Kartujarra,DIALECT,LANGUAGE
SM288713,18.0863,54.3269,Kartuzy,ADM2,DISTRICT
SM285016,7.7636,9.0469,Karu,ADM2,DISTRICT
SM260024,62.6634,34.5039,Karukh,ADM2,DISTRICT
SM11779,48.6417,31.2762,Karun,ADM2,DISTRICT
SM458452,150.1,-23.5321,Karunbara,ETHNICITY,ETHNICITY
SM254493,149.29,-23.35,Karunbura,ETHNICITY,ETHNICITY
SM277219,78.19,10.88,Karur,ADM2,DISTRICT
SM254494,141.64,-24.41,Karuwali,ETHNICITY,ETHNICITY
SM254494,141.64,-24.41,Karuwali,ETHNICITY,ETHNICITY
SM254494,141.6236,-25.3559,Karuwali,ETHNICITY,ETHNICITY
SM254494,141.6236,-25.3559,Karuwali,ETHNICITY,ETHNICITY
SM1748,30.1333,-3.125,Karuzi,ADM1,DISTRICT
SM271232,18.5,49.8333,Karviná,ADM2,DISTRICT
SM295082,114.6667,51.9167,Karymskiy rayon,ADM2,DISTRICT
SM295192,24.012,12.4575,Kas,ADM2,DISTRICT
SM262623,32,53.3333,Kas'tsyukovichy,ADM2,DISTRICT
SM278336,134.8536,34.9248,Kasai,ADM2,DISTRICT
SM1751,23.5167,-6.1833,Kasai Oriental (2015-),ADM1:ADM2,DISTRICT
SM254497,24.27,-7.02,Kasai River Groups,ETHNICITY,ETHNICITY
SM12959,143.1508,42.6463,Kasai-gun,ADM2,DISTRICT
SM269616,23.45,-10.3667,Kasaji,ADM2,DISTRICT
SM305203,30.9898,-10.3578,Kasama,ADM2,DISTRICT
SM278375,140.2694,36.3565,Kasama,ADM2,DISTRICT
SM234157,-15.9413,12.4173,Kasanga,LANGUAGE,LANGUAGE
SM279047,133.5062,34.5152,Kasaoka,ADM2,DISTRICT
SM276962,74.9906,12.4925,Kasaragod,ADM2,DISTRICT
SM1749,21.4167,-5.35,Kasaï,ADM1:ADM2,DISTRICT
SM1750,22.483,-6.292,Kasaï-Central,ADM1:ADM2,DISTRICT
SM303520,65.4612,38.8755,Kasbi,ADM2,DISTRICT
SM234160,-1.3908,11.0824,Kasem,LANGUAGE,LANGUAGE
SM250896,-1,11,Kasem,ETHNICITY,ETHNICITY
SM305196,26.2258,-13.6367,Kasempa,ADM2,DISTRICT
SM1752,30.0078,0.121,Kasese,ADM1:ADM2,DISTRICT
SM297780,101.9222,16.2721,Kaset Sombon,ADM2,DISTRICT
SM297481,103.5665,15.6029,Kaset Wisai,ADM2,DISTRICT
SM277306,78.8454,27.7772,Kasganj,ADM2,DISTRICT
SM234168,-123.08,38.5424,Kashaya,LANGUAGE,LANGUAGE
SM10356,75.9897,39.4754,Kashgar (1997-),ADM2,DISTRICT
SM278894,135.6992,34.5411,Kashiba,ADM2,DISTRICT
SM278895,135.7899,34.5035,Kashihara,ADM2,DISTRICT
SM279150,130.094,33.062,Kashima,ADM2,DISTRICT
SM278376,140.6224,36,Kashima,ADM2,DISTRICT
SM16933,37.5833,57.4167,Kashinskiy Rayon,ADM2,DISTRICT
SM294972,39.6167,51.4167,Kashirskiy rayon,ADM2,DISTRICT
SM293675,38.25,54.8333,Kashirskiy rayon,ADM2,DISTRICT
SM279354,139.9939,35.8602,Kashiwa,ADM2,DISTRICT
SM279097,135.6287,34.5792,Kashiwara,ADM2,DISTRICT
SM278932,138.5865,37.2991,Kashiwazaki,ADM2,DISTRICT
SM1753,66,38.5833,Kashkadarya,ADM1,DISTRICT
SM234165,74.3305,34.1668,Kashmiri,LANGUAGE,LANGUAGE
SM254505,74.5,34.5,Kashmiri,ETHNICITY,ETHNICITY
SM254505,74.5,34.5,Kashmiri,ETHNICITY,ETHNICITY
SM254505,74.5,34.5,Kashmiri,ETHNICITY,ETHNICITY
SM254505,75,34,Kashmiri,ETHNICITY,ETHNICITY
SM254505,75,34,Kashmiri,ETHNICITY,ETHNICITY
SM254505,75,34,Kashmiri,ETHNICITY,ETHNICITY
SM254505,74.5,32.4,Kashmiri,ETHNICITY,ETHNICITY
SM254505,74.5,32.4,Kashmiri,ETHNICITY,ETHNICITY
SM254505,74.5,32.4,Kashmiri,ETHNICITY,ETHNICITY
SM375494,69.5835,28.4411,Kashmore,ADM2:ADM3,DISTRICT
SM234162,18.6163,54.2996,Kashubian,LANGUAGE,LANGUAGE
SM234176,122.118,16.1881,Kasiguranin,LANGUAGE,LANGUAGE
SM16934,41.4167,54.9167,Kasimovskiy rayon,ADM2,DISTRICT
SM234177,-132.412,61.9942,Kaska,LANGUAGE,LANGUAGE
SM254511,-139.9,65.36,Kaska,ETHNICITY,ETHNICITY
SM254511,-139.9,65.36,Kaska,ETHNICITY,ETHNICITY
SM254511,-139.9,65.36,Kaska,ETHNICITY,ETHNICITY
SM254511,-139.9,65.36,Kaska,ETHNICITY,ETHNICITY
SM254511,-131,60,Kaska,ETHNICITY,ETHNICITY
SM254511,-131,60,Kaska,ETHNICITY,ETHNICITY
SM254511,-131,60,Kaska,ETHNICITY,ETHNICITY
SM254511,-131,60,Kaska,ETHNICITY,ETHNICITY
SM254511,-128,59,Kaska,ETHNICITY,ETHNICITY
SM254511,-128,59,Kaska,ETHNICITY,ETHNICITY
SM254511,-128,59,Kaska,ETHNICITY,ETHNICITY
SM254511,-128,59,Kaska,ETHNICITY,ETHNICITY
SM254511,-125,59.73,Kaska,ETHNICITY,ETHNICITY
SM254511,-125,59.73,Kaska,ETHNICITY,ETHNICITY
SM254511,-125,59.73,Kaska,ETHNICITY,ETHNICITY
SM254511,-125,59.73,Kaska,ETHNICITY,ETHNICITY
SM299203,39.316,-5.866,Kaskazini 'A',ADM2,DISTRICT
SM299204,39.2432,-5.9548,Kaskazini 'B',ADM2,DISTRICT
SM375356,83.9709,28.3457,Kaski,ADM2:ADM3,DISTRICT
SM254512,-87.54,37.08,Kaskinampo,ETHNICITY,ETHNICITY
SM16935,61,56.0833,Kaslinskiy Rayon,ADM2,DISTRICT
SM274782,44.3667,41.9,Kaspi,ADM2,DISTRICT
SM262375,27.1833,43.3,Kaspichan,ADM2,DISTRICT
SM292829,47.6383,42.8806,Kaspiysk,ADM2,DISTRICT
SM280913,35.7972,34.0324,Kasrouane,ADM2,DISTRICT
SM280913,35.7972,34.0324,Kasrouane,ADM2,DISTRICT
SM280913,35.6156,33.9697,Kasrouane,ADM2,DISTRICT
SM280913,35.6156,33.9697,Kasrouane,ADM2,DISTRICT
SM295226,35.5,15,Kassala,ADM2,DISTRICT
SM1754,35.65,15.9,Kassala,ADM1,DISTRICT
SM11404,-1.0939,10.895,Kassena Nankana (2008-),ADM2,DISTRICT
SM11405,-1.1133,10.9589,Kassena Nankana West,ADM2,DISTRICT
SM298121,8.7805,35.2087,Kasserine Nord,ADM2,DISTRICT
SM298122,8.7763,35.1067,Kasserine Sud,ADM2,DISTRICT
SM1755,8.7833,35.25,Kassérine,ADM1:ADMX,DISTRICT
SM1756,33.6667,41.5,Kastamonu,ADM1,DISTRICT
SM275969,14.35,45.3714,Kastav,ADM2,DISTRICT
SM293525,38.1667,51.75,Kastorenskiy rayon,ADM2,DISTRICT
SM11170,27.0771,58.3126,Kastre vald,ADM2,DISTRICT
SM9792,27.5414,53.8567,Kastrychnitski Rayon,ADM2,DISTRICT
SM13595,27.2094,56.1593,Kastulinas pagasts,ADM2,DISTRICT
SM234185,142.993,-6.6486,Kasua,LANGUAGE,LANGUAGE
SM254514,143,-7.01,Kasua,ETHNICITY,ETHNICITY
SM279673,130.4564,33.524,Kasuga,ADM2,DISTRICT
SM278034,136.9723,35.2476,Kasugai,ADM2,DISTRICT
SM254515,-56.31,0.11,Kasuiana,ETHNICITY,ETHNICITY
SM279166,139.7757,35.9827,Kasukabe,ADM2,DISTRICT
SM299243,30.438,-4.4392,Kasulu,ADM2,DISTRICT
SM299242,30.0965,-4.5598,Kasulu Township Authority,ADM2,DISTRICT
SM278377,140.3021,36.1094,Kasumigaura,ADM2,DISTRICT
SM1757,33.4167,-13,Kasungu,ADM1:ADM2,DISTRICT
SM375460,74.1353,31.007,Kasur,ADM2:ADM3,DISTRICT
SM12960,130.5151,33.6262,Kasuya-gun,ADM2,DISTRICT
SM280803,102.1743,19.1421,Kasy,ADM2,DISTRICT
SM226972,115.098,-8.383,Kata Kolok,LANGUAGE,LANGUAGE
SM250784,7.27,12.34,Katab/Atyap/Kagoro,ETHNICITY,ETHNICITY
SM234192,121.544,17.9509,Katabaga,LANGUAGE,LANGUAGE
SM278560,140.0491,39.8721,Katagami,ADM2,DISTRICT
SM285313,10.327,11.7042,Katagum,ADM2,DISTRICT
SM299294,34.0475,1.9216,Katakwi (2005-),ADM1:ADM2,DISTRICT
SM254518,-160.74,61.02,Kataligamut,ETHNICITY,ETHNICITY
SM10766,33.305,34.9913,Kataliontas,ADM2,DISTRICT
SM234188,106.459,16.1823,Katang,FAMILY,LANGUAGE
SM254520,150.69,-34.03,Katang-Worimi,ETHNICITY,ETHNICITY
SM254520,150.69,-34.03,Katang-Worimi,ETHNICITY,ETHNICITY
SM254520,152.4156,-31.9826,Katang-Worimi,ETHNICITY,ETHNICITY
SM254520,152.4156,-31.9826,Katang-Worimi,ETHNICITY,ETHNICITY
SM292889,108,61,Katangskiy rayon,ADM2,DISTRICT
SM261395,117.6397,-33.6248,Katanning,ADM2,DISTRICT
SM279098,135.6869,34.7722,Katano,ADM2,DISTRICT
SM16936,58.25,54.75,Katav-Ivanovskiy Rayon,ADM2,DISTRICT
SM1759,31.3333,-6.4,Katavi,ADM1,DISTRICT
SM234194,-64.8319,-7.2502,Katawixi,LANGUAGE,LANGUAGE
SM293491,62.5,56.25,Katayskiy rayon,ADM2,DISTRICT
SM285023,6.2333,9.15,Katcha,ADM2,DISTRICT
SM234199,29.4914,10.8423,Katcha-Kadugli-Miri,LANGUAGE,LANGUAGE
SM254522,146.76,-8.33,Kate,ETHNICITY,ETHNICITY
SM299423,30.9718,48.9459,Katerynopil's'kyi,ADM2,DISTRICT
SM305173,32.0433,-14.0407,Katete (pre-2012),ADM2,DISTRICT
SM283472,95.8498,24.1136,Katha,ADM2,DISTRICT
SM261607,132.1741,-14.6501,Katherine,ADM2,DISTRICT
SM10767,32.4126,34.9098,Kathikas,ADM2,DISTRICT
SM234211,-123.56,46.197,Kathlamet,LANGUAGE,LANGUAGE
SM254530,-123.34,46.03,Kathlamet,ETHNICITY,ETHNICITY
SM375296,85.285,27.6941,Kathmandu,ADM2:ADM3,DISTRICT
SM239524,84.9577,27.2614,Kathmandu Valley Newari,LANGUAGE,LANGUAGE
SM239524,84.9577,27.2614,Kathmandu Valley Newari,LANGUAGE,LANGUAGE
SM239524,85.326,27.67,Kathmandu Valley Newari,LANGUAGE,LANGUAGE
SM239524,85.326,27.67,Kathmandu Valley Newari,LANGUAGE,LANGUAGE
SM234208,80.8931,28.5867,Kathoriya Tharu,LANGUAGE,LANGUAGE
SM297400,98.3333,7.9146,Kathu,ADM2,DISTRICT
SM276898,75.5,32.5833,Kathua (2006-),ADM2,DISTRICT
SM234212,70.9826,35.4145,Kati,LANGUAGE,LANGUAGE
SM299205,39.364,-6.1597,Kati,ADM2,DISTRICT
SM283360,-8.1993,12.5629,Kati,ADM2,DISTRICT
SM254532,138.3,-2.57,Kati,ETHNICITY,ETHNICITY
SM254532,138.3,-2.57,Kati,ETHNICITY,ETHNICITY
SM254532,141,-6,Kati,ETHNICITY,ETHNICITY
SM254532,141,-6,Kati,ETHNICITY,ETHNICITY
SM277409,87.6,25.5,Katihar,ADM2,DISTRICT
SM254534,-92,46,Katikitegon,ETHNICITY,ETHNICITY
SM254534,-92,46,Katikitegon,ETHNICITY,ETHNICITY
SM254534,-91,45,Katikitegon,ETHNICITY,ETHNICITY
SM254534,-91,45,Katikitegon,ETHNICITY,ETHNICITY
SM276414,113.4,-2.0667,Katingan,ADM2,DISTRICT
SM234220,73.1733,18.9669,Katkari,LANGUAGE,LANGUAGE
SM234220,73.1733,18.9669,Katkari,LANGUAGE,LANGUAGE
SM234220,73.2877,18.2069,Katkari,LANGUAGE,LANGUAGE
SM234220,73.2877,18.2069,Katkari,LANGUAGE,LANGUAGE
SM234222,29.2825,11.8033,Katla,LANGUAGE,LANGUAGE
SM276986,80.3942,23.8356,Katni,ADM2,DISTRICT
SM234226,-123.432,39.7002,Kato,LANGUAGE,LANGUAGE
SM254538,-123.35,39.19,Kato,ETHNICITY,ETHNICITY
SM254538,-123.35,39.19,Kato,ETHNICITY,ETHNICITY
SM254538,-123.3,39.7,Kato,ETHNICITY,ETHNICITY
SM254538,-123.3,39.7,Kato,ETHNICITY,ETHNICITY
SM10768,32.4563,34.962,Kato Akourdaleia,ADM2,DISTRICT
SM10769,32.3749,34.933,Kato Arodes,ADM2,DISTRICT
SM10770,33.2865,35.0915,Kato Deftera,ADM2,DISTRICT
SM10771,33.3153,35.2574,Kato Dikomo,ADM2,DISTRICT
SM10772,33.2983,34.8401,Kato Drys,ADM2,DISTRICT
SM10773,32.8702,34.7656,Kato Kivides,ADM2,DISTRICT
SM10774,32.9658,35.0963,Kato Koutrafas,ADM2,DISTRICT
SM10775,33.3481,34.87,Kato Lefkara,ADM2,DISTRICT
SM10776,33.0893,35.058,Kato Moni,ADM2,DISTRICT
SM10777,33.0023,34.8977,Kato Mylos,ADM2,DISTRICT
SM10778,32.8283,34.8804,Kato Platres,ADM2,DISTRICT
SM10779,32.9777,34.7032,Kato Polemidia,ADM2,DISTRICT
SM10780,32.6849,35.1782,Kato Pyrgos,ADM2,DISTRICT
SM10781,32.9822,35.1616,Kato Zodeia,ADM2,DISTRICT
SM12961,143.1638,43.2389,Kato-gun,ADM2,DISTRICT
SM10782,33.0596,35.169,Katokopia,ADM2,DISTRICT
SM12962,140.5167,35.8833,Katori-shi,ADM2,DISTRICT
SM12962,140.5167,35.8833,Katori-shi,ADM2,DISTRICT
SM12962,140.4675,35.7453,Katori-shi,ADM2,DISTRICT
SM12962,140.4675,35.7453,Katori-shi,ADM2,DISTRICT
SM288722,19.008,50.2141,Katowice,ADM2,DISTRICT
SM296464,16.2605,59.0173,Katrineholm,ADM2,DISTRICT
SM1760,7.5,12.25,Katsina,ADM1,DISTRICT
SM285360,9.5533,7.2896,Katsina (Benue),ADM2,DISTRICT
SM284910,7.6143,13.0056,Katsina (K),ADM2,DISTRICT
SM234229,102.4,24.6,Katso,LANGUAGE,LANGUAGE
SM278896,135.7046,34.4968,Katsuragi,ADM2,DISTRICT
SM279440,139.8557,35.7532,Katsushika,ADM2,DISTRICT
SM12963,134.1602,35.1023,Katsuta-gun,ADM2,DISTRICT
SM279356,140.2676,35.1754,Katsuura,ADM2,DISTRICT
SM12964,134.4264,33.9138,Katsuura Gun,ADM2,DISTRICT
SM279658,136.5218,36.0844,Katsuyama,ADM2,DISTRICT
SM12965,140.4746,38.05,Katta-gun,ADM2,DISTRICT
SM303416,66.3,39.95,Kattaqo'rg'on,ADM2,DISTRICT
SM234234,108.293,14.8971,Katua,LANGUAGE,LANGUAGE
SM15346,-54.7924,-24.3429,Katuete (2011-),ADM2,DISTRICT
SM254543,-67.61,-5.88,Katukina,ETHNICITY,ETHNICITY
SM241064,-70.3822,-8.1912,Katukína,LANGUAGE,LANGUAGE
SM233912,-68.7678,-6.454,Katukína-Kanamarí,LANGUAGE,LANGUAGE
SM306413,13.6716,-12.4645,Katumbela,ADM2:ADM3,DISTRICT
SM295348,157.2689,-7.3354,Katupika,ADM2,DISTRICT
SM13596,24.8107,57.5868,Katvaru pagasts,ADM2,DISTRICT
SM10783,32.8787,35.085,Katydata,ADM2,DISTRICT
SM278338,135.0213,34.9271,Katō,ADM2,DISTRICT
SM282976,-88.4244,20.6248,Kaua,ADM2,DISTRICT
SM300542,-159.7054,22.0111,Kauai,ADM2,DISTRICT
SM302672,-96.2878,32.5993,Kaufman,ADM2,DISTRICT
SM284789,9.7668,12.5027,Kaugama,ADM2,DISTRICT
SM13597,25.4755,57.4829,Kauguru pagasts,ADM2,DISTRICT
SM234239,149.696,-6.0819,Kaulong,LANGUAGE,LANGUAGE
SM14139,23.9066,54.8982,Kaunas,ADM2,DISTRICT
SM13598,27.5903,56.3078,Kaunatas pagasts,ADM2,DISTRICT
SM1761,24,55.1667,Kauno,ADM1,DISTRICT
SM14140,23.9615,54.9241,Kauno rajonas (excl. Kaunas),ADM2,DISTRICT
SM234244,103.512,-4.6251,Kaur,LANGUAGE,LANGUAGE
SM276726,103.3611,-4.7818,Kaur,ADM2,DISTRICT
SM284813,8.4699,9.6123,Kaura,ADM2,DISTRICT
SM285287,6.5762,12.562,Kaura-Na,ADM2,DISTRICT
SM254545,142.15,-10.73,Kaurareg,ETHNICITY,ETHNICITY
SM254545,142.15,-10.73,Kaurareg,ETHNICITY,ETHNICITY
SM254545,142.1929,-10.1118,Kaurareg,ETHNICITY,ETHNICITY
SM254545,142.1929,-10.1118,Kaurareg,ETHNICITY,ETHNICITY
SM234245,140.14,-3.3919,Kaure-Narau,LANGUAGE,LANGUAGE
SM234242,138.4801,-34.3497,Kaurna,LANGUAGE,LANGUAGE
SM254547,138.83,-34.69,Kaurna,ETHNICITY,ETHNICITY
SM254547,138.83,-34.69,Kaurna,ETHNICITY,ETHNICITY
SM254547,138.83,-34.69,Kaurna,ETHNICITY,ETHNICITY
SM254547,138.4,-34.56,Kaurna,ETHNICITY,ETHNICITY
SM254547,138.4,-34.56,Kaurna,ETHNICITY,ETHNICITY
SM254547,138.4,-34.56,Kaurna,ETHNICITY,ETHNICITY
SM254547,138.4085,-34.4271,Kaurna,ETHNICITY,ETHNICITY
SM254547,138.4085,-34.4271,Kaurna,ETHNICITY,ETHNICITY
SM254547,138.4085,-34.4271,Kaurna,ETHNICITY,ETHNICITY
SM284814,8.2942,10.2299,Kauru,ADM2,DISTRICT
SM277307,81.3773,25.5307,Kaushambi,ADM2,DISTRICT
SM346809,24.2295,63.4716,Kaustinen,ADM2:ADM3,DISTRICT
SM286353,23.0415,69.0115,Kautokeino,ADM2,DISTRICT
SM234248,138.157,-2.228,Kauwera,LANGUAGE,LANGUAGE
SM1762,22.0014,41.285,Kavadartsi,ADM1,DISTRICT
SM260473,19.5833,41.1667,Kavajës,ADM2,DISTRICT
SM298861,36.0518,41.0878,Kavak,ADM2,DISTRICT
SM298767,28.384,37.4393,Kavaklıdere,ADM2,DISTRICT
SM234250,121.645,24.6947,Kavalan,LANGUAGE,LANGUAGE
SM4116,20.5833,-18.3333,Kavango East Region,ADM1,DISTRICT
SM4117,18.9167,-18.25,Kavango West Region,ADM1,DISTRICT
SM262506,28.3833,43.5,Kavarna,ADM2,DISTRICT
SM234251,106.586,14.1796,Kavet,DIALECT,LANGUAGE
SM254551,-164.66,64.73,Kaviagmut,ETHNICITY,ETHNICITY
SM254551,-165.45,68.54,Kaviagmut,ETHNICITY,ETHNICITY
SM288590,151.4167,-3,Kavieng,ADM2,DISTRICT
SM234252,55.1183,33.5187,Kavir,LANGUAGE,LANGUAGE
SM254552,34.71,0.75,Kavirondo,ETHNICITY,ETHNICITY
SM254552,34.71,0.75,Kavirondo,ETHNICITY,ETHNICITY
SM254552,34,1,Kavirondo,ETHNICITY,ETHNICITY
SM254552,34,1,Kavirondo,ETHNICITY,ETHNICITY
SM16937,40.6353,45.4815,Kavkazskiy Rayon,ADM2,DISTRICT
SM375297,85.6231,27.5452,Kavrepalanchok,ADM2:ADM3,DISTRICT
SM234255,146.21,-6.9541,Kawacha,LANGUAGE,LANGUAGE
SM279099,135.5669,34.4055,Kawachinagano,ADM2,DISTRICT
SM279167,139.4792,35.9115,Kawagoe,ADM2,DISTRICT
SM12966,139.7325,35.8339,Kawaguchi-shi (2011-),ADM2,DISTRICT
SM254554,-57.12,-7.74,Kawahiwa,ETHNICITY,ETHNICITY
SM254555,31.24,12.96,Kawahla,ETHNICITY,ETHNICITY
SM234262,-118.679,36.0538,Kawaiisu,LANGUAGE,LANGUAGE
SM254556,-117.51,35.27,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-117.51,35.27,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-117.51,35.27,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-117.51,35.27,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-118,35,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-118,35,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-118,35,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-118,35,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-118,35.37,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-118,35.37,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-118,35.37,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-118,35.37,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-118.5,35.5,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-118.5,35.5,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-118.5,35.5,Kawaiisu,ETHNICITY,ETHNICITY
SM254556,-118.5,35.5,Kawaiisu,ETHNICITY,ETHNICITY
SM12967,144.4901,43.4057,Kawakami-gun,ADM2,DISTRICT
SM459407,142.2143,-10.6433,Kawalgaw Ya,ETHNICITY,ETHNICITY
SM18138,29.3125,-9.808,Kawambwa District (2012-),ADM2,DISTRICT
SM278339,135.4092,34.8796,Kawanishi,ADM2,DISTRICT
SM12968,139.7476,37.5024,Kawanuma-gun,ADM2,DISTRICT
SM234265,142.5,-16.1,Kawarrang-Ogh Undjan,LANGUAGE,LANGUAGE
SM268853,-78.8329,44.5834,Kawartha Lakes,ADM2,DISTRICT
SM278531,139.6285,35.57,Kawasaki,ADM2,DISTRICT
SM234266,18.5711,10.1821,Kawaway,LANGUAGE,LANGUAGE
SM234270,130.327,-0.1731,Kawe,LANGUAGE,LANGUAGE
SM286530,176.6875,-38.0911,Kawerau,ADM2,DISTRICT
SM234273,111,-7,Kawi,LANGUAGE,LANGUAGE
SM254561,-116.45,37.92,Kawich Mountain Shoshone,ETHNICITY,ETHNICITY
SM283508,98.2398,16.415,Kawkareik,ADM2,DISTRICT
SM283490,98.8474,11.0497,Kawthoung,ADM2,DISTRICT
SM234275,-66.3329,-9.4729,Kaxararí,LANGUAGE,LANGUAGE
SM234278,-55.9101,2.1714,Kaxuiâna,LANGUAGE,LANGUAGE
SM302201,-97.1439,36.818,Kay,ADM2,DISTRICT
SM10300,14.3127,13.5752,Kaya,ADM2,DISTRICT
SM12969,140.6113,42.071,Kayabe-gun,ADM2,DISTRICT
SM234292,-55.5479,-11.2747,Kayabí,LANGUAGE,LANGUAGE
SM234291,138.932,-6.3768,Kayagar,LANGUAGE,LANGUAGE
SM1764,97.5,19.25,Kayah,ADM1,DISTRICT
SM292831,47.9167,42.3333,Kayakentskiy rayon,ADM2,DISTRICT
SM254566,115.51,0.34,Kayan,ETHNICITY,ETHNICITY
SM254566,114.09,3.91,Kayan,ETHNICITY,ETHNICITY
SM234281,97.4138,19.5183,Kayan Lahwi,LANGUAGE,LANGUAGE
SM234287,114.452,0.9179,Kayan Mahakam,LANGUAGE,LANGUAGE
SM234288,115.827,1.7827,Kayan River Kayan,LANGUAGE,LANGUAGE
SM1765,134.7187,8.0835,Kayangel,ADM1,DISTRICT
SM262087,29.6444,-2.9213,Kayanza,ADM2,DISTRICT
SM1766,29.6667,-3.0667,Kayanza,ADM1,DISTRICT
SM234286,120.9,16.321,Kayapa Kallahan,DIALECT,LANGUAGE
SM18062,40.1731,37.938,Kayapinar,ADM2,DISTRICT
SM234293,-51.6737,-7.7735,Kayapó,LANGUAGE,LANGUAGE
SM234285,139.489,-17.0695,Kayardild,LANGUAGE,LANGUAGE
SM16938,48.1667,55.4167,Kaybitsky District,ADM2,DISTRICT
SM234299,127.118,-3.3925,Kayeli,LANGUAGE,LANGUAGE
SM283348,-11.5459,14.5335,Kayes,ADM2,DISTRICT
SM1767,-10.1,13.9,Kayes,ADM1,DISTRICT
SM1768,97.75,17.5,Kayin,ADM1,DISTRICT
SM254578,99.25,12.31,Kayin,ETHNICITY,ETHNICITY
SM254578,99.25,12.31,Kayin,ETHNICITY,ETHNICITY
SM254578,95.35,17.75,Kayin,ETHNICITY,ETHNICITY
SM254578,95.35,17.75,Kayin,ETHNICITY,ETHNICITY
SM254578,98.72,14.59,Kayin,ETHNICITY,ETHNICITY
SM254578,98.72,14.59,Kayin,ETHNICITY,ETHNICITY
SM254578,98.32,17.29,Kayin,ETHNICITY,ETHNICITY
SM254578,98.32,17.29,Kayin,ETHNICITY,ETHNICITY
SM254578,97.05,18.89,Kayin,ETHNICITY,ETHNICITY
SM254578,97.05,18.89,Kayin,ETHNICITY,ETHNICITY
SM254578,98.15,20.27,Kayin,ETHNICITY,ETHNICITY
SM254578,98.15,20.27,Kayin,ETHNICITY,ETHNICITY
SM254578,97.19,17.12,Kayin,ETHNICITY,ETHNICITY
SM254578,97.19,17.12,Kayin,ETHNICITY,ETHNICITY
SM370514,6.031,49.4791,Kayl,ADM1:ADM2:ADM3,DISTRICT
SM298846,30.2962,41.0405,Kaynarca,ADM2,DISTRICT
SM298338,31.3114,40.7791,Kaynaşlı,ADM2,DISTRICT
SM261971,30.0237,-4.15,Kayogoro,ADM2,DISTRICT
SM261992,29.7467,-3.5152,Kayokwe,ADM2,DISTRICT
SM234302,108.447,14.9227,Kayong,LANGUAGE,LANGUAGE
SM276384,110.104,-1.0602,Kayong Utara,ADM2,DISTRICT
SM295151,30.651,-1.851,Kayonza,ADM1:ADM2,DISTRICT
SM234304,87.6042,26.6179,Kayort,LANGUAGE,LANGUAGE
SM1769,35.4968,38.737,Kayseri,ADM1,DISTRICT
SM292832,47.8333,42.0833,Kaytagskiy rayon,ADM2,DISTRICT
SM234305,133.602,-21.5844,Kaytetye,LANGUAGE,LANGUAGE
SM1770,32.8667,1,Kayunga,ADM1:ADM2,DISTRICT
SM234307,140.626,-2.5442,Kayupulau,LANGUAGE,LANGUAGE
SM293431,93.4167,57.6667,Kazachinskiy rayon,ADM2,DISTRICT
SM292890,108,56,Kazachinsko-Lenskiy rayon,ADM2,DISTRICT
SM10784,33.3532,35.3185,Kazafani,ADM2,DISTRICT
SM254579,61.4,41.31,Kazak,ETHNICITY,ETHNICITY
SM254579,61.4,41.31,Kazak,ETHNICITY,ETHNICITY
SM254579,61.4,41.31,Kazak,ETHNICITY,ETHNICITY
SM254579,61.4,41.31,Kazak,ETHNICITY,ETHNICITY
SM254579,61.4,41.31,Kazak,ETHNICITY,ETHNICITY
SM254579,57.41,49.67,Kazak,ETHNICITY,ETHNICITY
SM254579,57.41,49.67,Kazak,ETHNICITY,ETHNICITY
SM254579,57.41,49.67,Kazak,ETHNICITY,ETHNICITY
SM254579,57.41,49.67,Kazak,ETHNICITY,ETHNICITY
SM254579,57.41,49.67,Kazak,ETHNICITY,ETHNICITY
SM254579,69.42,46.9,Kazak,ETHNICITY,ETHNICITY
SM254579,69.42,46.9,Kazak,ETHNICITY,ETHNICITY
SM254579,69.42,46.9,Kazak,ETHNICITY,ETHNICITY
SM254579,69.42,46.9,Kazak,ETHNICITY,ETHNICITY
SM254579,69.42,46.9,Kazak,ETHNICITY,ETHNICITY
SM254579,84.12,47.31,Kazak,ETHNICITY,ETHNICITY
SM254579,84.12,47.31,Kazak,ETHNICITY,ETHNICITY
SM254579,84.12,47.31,Kazak,ETHNICITY,ETHNICITY
SM254579,84.12,47.31,Kazak,ETHNICITY,ETHNICITY
SM254579,84.12,47.31,Kazak,ETHNICITY,ETHNICITY
SM254579,75.39,52.45,Kazak,ETHNICITY,ETHNICITY
SM254579,75.39,52.45,Kazak,ETHNICITY,ETHNICITY
SM254579,75.39,52.45,Kazak,ETHNICITY,ETHNICITY
SM254579,75.39,52.45,Kazak,ETHNICITY,ETHNICITY
SM254579,75.39,52.45,Kazak,ETHNICITY,ETHNICITY
SM254579,86.53,45.31,Kazak,ETHNICITY,ETHNICITY
SM254579,86.53,45.31,Kazak,ETHNICITY,ETHNICITY
SM254579,86.53,45.31,Kazak,ETHNICITY,ETHNICITY
SM254579,86.53,45.31,Kazak,ETHNICITY,ETHNICITY
SM254579,86.53,45.31,Kazak,ETHNICITY,ETHNICITY
SM254579,70,48,Kazak,ETHNICITY,ETHNICITY
SM254579,70,48,Kazak,ETHNICITY,ETHNICITY
SM254579,70,48,Kazak,ETHNICITY,ETHNICITY
SM254579,70,48,Kazak,ETHNICITY,ETHNICITY
SM254579,70,48,Kazak,ETHNICITY,ETHNICITY
SM254579,75.5,42.5,Kazak,ETHNICITY,ETHNICITY
SM254579,75.5,42.5,Kazak,ETHNICITY,ETHNICITY
SM254579,75.5,42.5,Kazak,ETHNICITY,ETHNICITY
SM254579,75.5,42.5,Kazak,ETHNICITY,ETHNICITY
SM254579,75.5,42.5,Kazak,ETHNICITY,ETHNICITY
SM254579,73.52,53.46,Kazak,ETHNICITY,ETHNICITY
SM254579,73.52,53.46,Kazak,ETHNICITY,ETHNICITY
SM254579,73.52,53.46,Kazak,ETHNICITY,ETHNICITY
SM254579,73.52,53.46,Kazak,ETHNICITY,ETHNICITY
SM254579,73.52,53.46,Kazak,ETHNICITY,ETHNICITY
SM254579,82.37,49.57,Kazak,ETHNICITY,ETHNICITY
SM254579,82.37,49.57,Kazak,ETHNICITY,ETHNICITY
SM254579,82.37,49.57,Kazak,ETHNICITY,ETHNICITY
SM254579,82.37,49.57,Kazak,ETHNICITY,ETHNICITY
SM254579,82.37,49.57,Kazak,ETHNICITY,ETHNICITY
SM234309,65.7709,41.8729,Kazakh,LANGUAGE,LANGUAGE
SM279866,62.5,45.3333,Kazalinskiy,ADM2,DISTRICT
SM298889,32.639,40.165,Kazan,ADM2,DISTRICT
SM298889,32.639,40.165,Kazan,ADM2,DISTRICT
SM298889,32.6914,40.2417,Kazan,ADM2,DISTRICT
SM298889,32.6914,40.2417,Kazan,ADM2,DISTRICT
SM299605,32.8303,47.8317,Kazanetskyi,ADM2,DISTRICT
SM262444,25.3989,42.6194,Kazanlak,ADM2,DISTRICT
SM294823,69.4167,55.5833,Kazanskiy rayon,ADM2,DISTRICT
SM284790,8.5135,12.5968,Kazaure,ADM2,DISTRICT
SM11314,44.6453,42.6575,Kazbegi,ADM2,DISTRICT
SM292833,46.6667,43,Kazbekovskiy rayon,ADM2,DISTRICT
SM13599,21.7021,56.7047,Kazdangas pagasts,ADM2,DISTRICT
SM277720,51.7833,29.5,Kazerun,ADM2,DISTRICT
SM288759,20.5337,50.2881,Kazimierza,ADM2,DISTRICT
SM276241,20.5798,48.2637,Kazincbarcikai,ADM2,DISTRICT
SM10785,32.9129,35.1739,Kazivera,ADM2,DISTRICT
SM14141,23.4927,54.7475,Kazlu Ruda Municipality,ADM2,DISTRICT
SM12970,139.6199,36.1367,Kazo-shi,ADM2,DISTRICT
SM279895,48.6667,49.6667,Kaztalovskiy,ADM2,DISTRICT
SM234312,157.687,-8.2389,Kazukuru,LANGUAGE,LANGUAGE
SM305215,25.687,-17.066,Kazungula,ADM2,DISTRICT
SM278561,140.8303,40.2104,Kazuno,ADM2,DISTRICT
SM279880,69.41,41.759,Kazygurtskiy,ADM2,DISTRICT
SM279880,69.41,41.759,Kazygurtskiy,ADM2,DISTRICT
SM279880,69.75,41.8333,Kazygurtskiy,ADM2,DISTRICT
SM279880,69.75,41.8333,Kazygurtskiy,ADM2,DISTRICT
SM234532,66.1218,62.4308,Kazym-Berezover-Suryskarer Khanty,LANGUAGE,LANGUAGE
SM298577,32.9641,37.2404,Kazımkarabekir,ADM2,DISTRICT
SM284005,-13.1602,16.0167,Kaédi,ADM2,DISTRICT
SM1726,103.5,11.5,Kaôh Kong,ADM1,DISTRICT
SM298508,28.9783,41.0832,Kağıthane,ADM2,DISTRICT
SM298583,43.12,40.1403,Kağızman,ADM2,DISTRICT
SM299024,29.6561,36.2121,Kaş,ADM2,DISTRICT
SM275995,16.3074,43.5386,Kaštela,ADM2,DISTRICT
SM305660,13.6884,45.3028,Kaštelir-Labinci,ADM2,DISTRICT
SM234314,143.174,-3.9871,Keak,LANGUAGE,LANGUAGE
SM285017,8.7683,8.1675,Keana,ADM2,DISTRICT
SM234316,147.95,-10.1342,Keapara,LANGUAGE,LANGUAGE
SM301723,-98.948,40.5067,Kearney,ADM2,DISTRICT
SM300930,-101.3199,38.0002,Kearny,ADM2,DISTRICT
SM298357,38.7377,38.7944,Keban,ADM2,DISTRICT
SM285220,4.7533,11.9362,Kebbe,ADM2,DISTRICT
SM1771,4,11.5,Kebbi,ADM1,DISTRICT
SM1772,8.8333,33.5,Kebili,ADM1,DISTRICT
SM297865,9.1683,33.8401,Kebili Nord,ADM2,DISTRICT
SM297866,9.0989,33.6149,Kebili Sud,ADM2,DISTRICT
SM276306,109.6606,-7.6392,Kebumen,ADM2,DISTRICT
SM375383,63.1103,26.0179,Kech,ADM2:ADM3,DISTRICT
SM254582,16.7,14.72,Kecherda,ETHNICITY,ETHNICITY
SM11574,19.587,46.8828,Kecskemeti Jaras,ADM2,DISTRICT
SM274786,41.9705,41.6587,Keda,ADM2,DISTRICT
SM1773,100.6667,6,Kedah,ADM1,DISTRICT
SM234332,100.492,5.933,Kedah Malay,LANGUAGE,LANGUAGE
SM234333,123.77,-8.2304,Kedang,LANGUAGE,LANGUAGE
SM10786,32.7276,34.8316,Kedares,ADM2,DISTRICT
SM254583,30.49,5.22,Kedaru,ETHNICITY,ETHNICITY
SM256363,17.33,-17.37,Kede,ETHNICITY,ETHNICITY
SM256363,16.92,-15.43,Kede,ETHNICITY,ETHNICITY
SM234335,139.081,-2.0359,Keder,LANGUAGE,LANGUAGE
SM276340,112.1667,-7.8333,Kediri,ADM2,DISTRICT
SM299070,121.6899,25.1149,Keelung,ADM2,DISTRICT
SM234437,-16.6857,12.3652,Keerak,LANGUAGE,LANGUAGE
SM276552,140.6167,-3.3,Keerom,ADM2,DISTRICT
SM459046,142.8801,-38.0398,Keerray Woorroong^,ETHNICITY,ETHNICITY
SM297875,8.87,36.1873,Kef Est,ADM2,DISTRICT
SM297876,8.659,36.1491,Kef Ouest,ADM2,DISTRICT
SM11455,20.5,38.25,Kefallonia Regional Unit,ADM2,DISTRICT
SM274312,36.0479,7.1797,Keffa,ADM2,DISTRICT
SM250336,36.15,7.37,Keffa,ETHNICITY,ETHNICITY
SM250336,36.15,7.37,Keffa,ETHNICITY,ETHNICITY
SM250336,36.15,7.37,Keffa,ETHNICITY,ETHNICITY
SM250336,36,7,Keffa,ETHNICITY,ETHNICITY
SM250336,36,7,Keffa,ETHNICITY,ETHNICITY
SM250336,36,7,Keffa,ETHNICITY,ETHNICITY
SM250336,36.5,7.27,Keffa,ETHNICITY,ETHNICITY
SM250336,36.5,7.27,Keffa,ETHNICITY,ETHNICITY
SM250336,36.5,7.27,Keffa,ETHNICITY,ETHNICITY
SM285018,7.8273,8.838,Keffi,ADM2,DISTRICT
SM11212,36,7.25,Keficho Shekicho Zone,ADM2,DISTRICT
SM1775,80.3333,7.1167,Kegalle,ADM1:ADM2,DISTRICT
SM13193,78.75,43,Kegen Audany,ADM2,DISTRICT
SM18404,59.6583,42.7217,Kegeyli Tumani (2019-),ADM2,DISTRICT
SM3996,24.7472,56.6683,Keguma Novads,ADM1,DISTRICT
SM13600,24.7184,56.7408,Kegums,ADM2,DISTRICT
SM370500,6.0358,49.6683,Kehlen,ADM1:ADM2:ADM3,DISTRICT
SM11171,24.89,58.8427,Kehtna vald,ADM2,DISTRICT
SM234344,136.14,-3.1933,Kehu,LANGUAGE,LANGUAGE
SM346783,29.1047,64.6653,Kehys-Kainuu,ADM2:ADM3,DISTRICT
SM234348,133.02,-5.6395,Kei,LANGUAGE,LANGUAGE
SM254590,133.21,-5.77,Kei Islands,ETHNICITY,ETHNICITY
SM254590,133.21,-5.77,Kei Islands,ETHNICITY,ETHNICITY
SM254590,133.07,-5.58,Kei Islands,ETHNICITY,ETHNICITY
SM254590,133.07,-5.58,Kei Islands,ETHNICITY,ETHNICITY
SM234347,29.3889,11.2064,Keiga,LANGUAGE,LANGUAGE
SM274101,24.3865,59.3114,Keila,ADM2,DISTRICT
SM234350,145.483,-5.3373,Kein,LANGUAGE,LANGUAGE
SM13601,25.141,56.9062,Keipenes pagasts,ADM2,DISTRICT
SM279677,130.6814,33.5733,Keisen,ADM2,DISTRICT
SM284617,6,14.8,Keita,ADM2,DISTRICT
SM301724,-101.6614,41.1988,Keith,ADM2,DISTRICT
SM234352,35.2268,0.5109,Keiyo,LANGUAGE,LANGUAGE
SM4006,24.2264,56.7981,Kekavas Novads,ADM1,DISTRICT
SM13602,24.1788,56.7939,Kekavas pagasts,ADM2,DISTRICT
SM234354,-89.2452,15.3788,Kekchí,LANGUAGE,LANGUAGE
SM250301,9.55,29.47,Kel Ahaggar,ETHNICITY,ETHNICITY
SM250301,9.55,29.47,Kel Ahaggar,ETHNICITY,ETHNICITY
SM250301,2.67,25.99,Kel Ahaggar,ETHNICITY,ETHNICITY
SM250301,2.67,25.99,Kel Ahaggar,ETHNICITY,ETHNICITY
SM250301,6,23,Kel Ahaggar,ETHNICITY,ETHNICITY
SM250301,6,23,Kel Ahaggar,ETHNICITY,ETHNICITY
SM250731,-3.49,18.03,Kel Ansar,ETHNICITY,ETHNICITY
SM250731,-3.49,18.03,Kel Ansar,ETHNICITY,ETHNICITY
SM250731,-3.49,18.03,Kel Ansar,ETHNICITY,ETHNICITY
SM250731,-5,17,Kel Ansar,ETHNICITY,ETHNICITY
SM250731,-5,17,Kel Ansar,ETHNICITY,ETHNICITY
SM250731,-5,17,Kel Ansar,ETHNICITY,ETHNICITY
SM250731,-3,18,Kel Ansar,ETHNICITY,ETHNICITY
SM250731,-3,18,Kel Ansar,ETHNICITY,ETHNICITY
SM250731,-3,18,Kel Ansar,ETHNICITY,ETHNICITY
SM250743,12.11,23.84,Kel Ayr,ETHNICITY,ETHNICITY
SM250743,12.11,23.84,Kel Ayr,ETHNICITY,ETHNICITY
SM250743,8,18,Kel Ayr,ETHNICITY,ETHNICITY
SM250743,8,18,Kel Ayr,ETHNICITY,ETHNICITY
SM254591,5.05,15.75,Kel Ferouan,ETHNICITY,ETHNICITY
SM254592,4.29,23.02,Kel Rela,ETHNICITY,ETHNICITY
SM299872,26.8324,48.4658,Kel'menets'kyi,ADM2,DISTRICT
SM254595,147.03,-7.46,Kela,ETHNICITY,ETHNICITY
SM250822,23,-3,Kela,ETHNICITY,ETHNICITY
SM234356,24.0051,-1.3909,Kela (Democratic Republic of Congo),DIALECT,LANGUAGE
SM234357,147.173,-7.471,Kela (Papua New Guinea),LANGUAGE,LANGUAGE
SM234360,115.428,3.3178,Kelabit,LANGUAGE,LANGUAGE
SM1776,102,5.3333,Kelantan,ADM1,DISTRICT
SM242285,101.41,6.3815,Kelantan-Pattani Malay,LANGUAGE,LANGUAGE
SM250354,25,1,Kele,ETHNICITY,ETHNICITY
SM234369,147.064,-2.0916,Kele (Papua New Guinea),LANGUAGE,LANGUAGE
SM234367,24.3098,0.3691,Kele-Foma,LANGUAGE,LANGUAGE
SM274375,34.7688,8.7813,Kelem Wellega,ADM2,DISTRICT
SM298260,29.2364,39.9236,Keles,ADM2,DISTRICT
SM13194,68.5,41.25,Keles Audany,ADM2,DISTRICT
SM234370,121.035,16.7099,Keley-i Kallahan,LANGUAGE,LANGUAGE
SM297923,11.0095,36.8919,Kelibia,ADM2,DISTRICT
SM234377,30.556,3.5604,Keliko,LANGUAGE,LANGUAGE
SM254596,30.11,3.67,Keliko,ETHNICITY,ETHNICITY
SM298445,39.4371,40.1295,Kelkit,ADM2,DISTRICT
SM10787,33.1606,34.8133,Kellaki,ADM2,DISTRICT
SM261396,117.8056,-31.5771,Kellerberrin,ADM2,DISTRICT
SM13195,69.25,53.8333,Kellerovka Audany,ADM2,DISTRICT
SM10788,33.6226,34.9868,Kellia,ADM2,DISTRICT
SM281350,22.9333,55.6333,Kelmės,ADM2,DISTRICT
SM234382,34.3816,10.9291,Kelo,LANGUAGE,LANGUAGE
SM10789,32.6443,34.8128,Kelokedara,ADM2,DISTRICT
SM10181,-101.3758,54.1584,Kelsey,ADM2,DISTRICT
SM284313,103.3333,2.2,Keluang,ADM2,DISTRICT
SM298381,39.0342,39.6012,Kemah,ADM2,DISTRICT
SM234384,125.159,-9.003,Kemak,LANGUAGE,LANGUAGE
SM298382,38.5059,39.2716,Kemaliye,ADM2,DISTRICT
SM18063,41.5291,41.4828,Kemalpasa,ADM2,DISTRICT
SM298537,27.491,38.4088,Kemalpaşa,ADM2,DISTRICT
SM274352,35.7804,9.84,Kemashi,ADM2,DISTRICT
SM274352,35.7804,9.84,Kemashi,ADM2,DISTRICT
SM274352,35.8333,9.75,Kemashi,ADM2,DISTRICT
SM274352,35.8333,9.75,Kemashi,ADM2,DISTRICT
SM250339,36.14,6.21,Kembata,ETHNICITY,ETHNICITY
SM274313,37.7789,7.2704,Kembata Tembaro,ADM2,DISTRICT
SM234386,132.988,-2.2449,Kemberano,LANGUAGE,LANGUAGE
SM234388,140.434,-3.8907,Kembra,LANGUAGE,LANGUAGE
SM268582,21.6963,4.4824,Kembé,ADM2,DISTRICT
SM11575,21.8841,48.1005,Kemecsei Jaras,ADM2,DISTRICT
SM298243,30.0675,37.3633,Kemer,ADM2,DISTRICT
SM299011,30.4804,36.6025,Kemer,ADM2,DISTRICT
SM1778,86,55,Kemerovo,ADM1,DISTRICT
SM16939,86.3333,55.5,Kemerovskiy Rayon,ADM2,DISTRICT
SM234391,10.4984,6.6958,Kemezung,LANGUAGE,LANGUAGE
SM234393,27.537,67.093,Kemi Saami,LANGUAGE,LANGUAGE
SM346777,24.7504,65.9491,Kemi-Tornio,ADM2:ADM3,DISTRICT
SM234394,101.144,22.0603,Kemiehua,LANGUAGE,LANGUAGE
SM280312,76.3326,42.8335,Kemin,ADM2,DISTRICT
SM234395,18,-2.3,Kempee,LANGUAGE,LANGUAGE
SM301454,-88.6412,32.7546,Kemper,ADM2,DISTRICT
SM261562,152.6861,-30.9055,Kempsey,ADM2,DISTRICT
SM16940,34,65.1667,Kemskiy Rayon,ADM2,DISTRICT
SM234396,140.372,-2.6489,Kemtuik,LANGUAGE,LANGUAGE
SM234398,102.016,3.1542,Kenaboi,LANGUAGE,LANGUAGE
SM301174,-151.2506,60.4167,Kenai Peninsula,ADM2,DISTRICT
SM234399,145.881,-6.7477,Kenati,LANGUAGE,LANGUAGE
SM12453,-76.7984,18.0004,Kencot,ADM2,DISTRICT
SM276307,110.1685,-7.0256,Kendal,ADM2,DISTRICT
SM12454,-78.2272,18.3629,Kendal (Hanover),ADM2,DISTRICT
SM18525,-77.5032,18.0832,Kendal (Manchester),ADM2,DISTRICT
SM302674,-98.7116,29.9447,Kendall,ADM2,DISTRICT
SM300633,-88.4289,41.5906,Kendall,ADM2,DISTRICT
SM276612,122.5,-3.9833,Kendari,ADM2,DISTRICT
SM234403,109.467,1.4796,Kendayan,LANGUAGE,LANGUAGE
SM234402,21.5395,13.2445,Kendeje,LANGUAGE,LANGUAGE
SM234401,9.632,5.7743,Kendem,LANGUAGE,LANGUAGE
SM277115,86.5,20.5,Kendrapara,ADM2,DISTRICT
SM277116,85.5,21.5,Kendujhar,ADM2,DISTRICT
SM262222,-5,11.4167,Kenedougou (1995-),ADM1:ADM2,DISTRICT
SM302675,-97.6365,26.928,Kenedy,ADM2,DISTRICT
SM295485,-11.19,7.9517,Kenema,ADM1:ADM2,DISTRICT
SM280813,101.3167,17.8516,Kenethao,ADM2,DISTRICT
SM234406,18.1464,11.6816,Kenga,LANGUAGE,LANGUAGE
SM15088,99.5889,21.583,Kengtung (2011-),ADM2,DISTRICT
SM234412,116.198,5.4227,Keningau Murut,LANGUAGE,LANGUAGE
SM234411,111.808,-0.6318,Keninjal,LANGUAGE,LANGUAGE
SM4454,-6.3333,34.5833,Kenitra (2009-),ADM2,DISTRICT
SM301210,-69.7673,44.4092,Kennebec,ADM2,DISTRICT
SM17441,-60.9959,13.7538,Kennedy Highway/Laborie Bypass,ADM2,DISTRICT
SM268855,-87.9999,54.0002,Kenora,ADM2,DISTRICT
SM303244,-87.8398,42.5728,Kenosha,ADM2,DISTRICT
SM254601,117.83,8.47,Kenoy,ETHNICITY,ETHNICITY
SM12455,-76.3005,18.0677,Kensington,ADM2,DISTRICT
SM234414,101.026,5.9809,Kensiu,LANGUAGE,LANGUAGE
SM254602,99.56,8.54,Kensiu,ETHNICITY,ETHNICITY
SM234417,10.412,6.0171,Kenswei Nsei,LANGUAGE,LANGUAGE
SM302676,-100.7776,33.1814,Kent,ADM2,DISTRICT
SM302428,-71.579,41.6733,Kent,ADM2,DISTRICT
SM301219,-76.0958,39.2356,Kent,ADM2,DISTRICT
SM301286,-85.5493,43.0322,Kent,ADM2,DISTRICT
SM303390,-75.5046,39.096,Kent,ADM2,DISTRICT
SM274641,0.6667,51.1667,Kent,ADM1:ADM2,DISTRICT
SM268804,-65.2488,46.5834,Kent,ADM2,DISTRICT
SM261397,118.6699,-33.5388,Kent,ADM2,DISTRICT
SM13196,68.6,43.5333,Kentau Qalasy,ADM2,DISTRICT
SM261762,146.1773,-41.4634,Kentish,ADM2,DISTRICT
SM301063,-84.5333,38.9335,Kenton,ADM2,DISTRICT
SM12456,-78.0177,18.1993,Kentucky,ADM2,DISTRICT
SM1780,-84.8776,38.2004,Kentucky,ADM1,DISTRICT
SM234425,32.9,24.09,Kenuzi,DIALECT,LANGUAGE
SM250357,32.5,23,Kenuzi Nubian,ETHNICITY,ETHNICITY
SM250357,32.5,23,Kenuzi Nubian,ETHNICITY,ETHNICITY
SM250357,32.5,23,Kenuzi Nubian,ETHNICITY,ETHNICITY
SM250357,33,23,Kenuzi Nubian,ETHNICITY,ETHNICITY
SM250357,33,23,Kenuzi Nubian,ETHNICITY,ETHNICITY
SM250357,33,23,Kenuzi Nubian,ETHNICITY,ETHNICITY
SM250357,39.11,23,Kenuzi Nubian,ETHNICITY,ETHNICITY
SM250357,39.11,23,Kenuzi Nubian,ETHNICITY,ETHNICITY
SM250357,39.11,23,Kenuzi Nubian,ETHNICITY,ETHNICITY
SM234424,31.8992,21.2837,Kenuzi-Dongola,LANGUAGE,LANGUAGE
SM454509,37,-3,Kenya Bantu,ETHNICITY,ETHNICITY
SM234426,37.1995,-1.0017,Kenya-Somali Sign Language,LANGUAGE,LANGUAGE
SM254603,116.19,1.81,Kenyah,ETHNICITY,ETHNICITY
SM254603,116.41,4.27,Kenyah,ETHNICITY,ETHNICITY
SM254603,116.19,1.81,Kenyah,ETHNICITY,ETHNICITY
SM254603,116.41,4.27,Kenyah,ETHNICITY,ETHNICITY
SM251168,30.833,-1.25,Kenyan,ETHNICITY,ETHNICITY
SM234430,9.5592,5.5829,Kenyang,LANGUAGE,LANGUAGE
SM234429,33.4791,1.1513,Kenyi,LANGUAGE,LANGUAGE
SM280804,102.5686,18.5693,Keo Oudom,ADM2,DISTRICT
SM300833,-92.1786,41.3365,Keokuk,ADM2,DISTRICT
SM234434,145.415,-7.636,Keoru-Ahia,LANGUAGE,LANGUAGE
SM1781,104.3167,10.5125,Kep,ADM1,DISTRICT
SM276727,102.5642,-3.6019,Kepahiang,ADM2,DISTRICT
SM18064,30.7597,37.0119,Kepez Ilcesi,ADM2,DISTRICT
SM254606,-62.11,-13.18,Kepkiriwat,ETHNICITY,ETHNICITY
SM234435,-61.246,-11.663,Kepkiriwát,LANGUAGE,LANGUAGE
SM234436,120.7753,-8.6032,Kepo',LANGUAGE,LANGUAGE
SM13603,27.7505,56.0137,Kepovas pagasts,ADM2,DISTRICT
SM298165,28.1624,39.6982,Kepsut,ADM2,DISTRICT
SM276435,105.7607,2.9397,Kepulauan Anambas,ADM2,DISTRICT
SM276467,134.4699,-6.1706,Kepulauan Aru,ADM2,DISTRICT
SM276654,98.8955,-1.3593,Kepulauan Mentawai,ADM2,DISTRICT
SM276561,102.6954,0.9749,Kepulauan Meranti,ADM2,DISTRICT
SM1782,107.0187,0.6333,Kepulauan Riau,ADM1,DISTRICT
SM276628,125.55,3.5,Kepulauan Sangihe,ADM2,DISTRICT
SM276629,126.7809,4.3118,Kepulauan Talaud,ADM2,DISTRICT
SM276525,136.2772,-1.7877,Kepulauan Yapen,ADM2,DISTRICT
SM234438,15.1419,9.8817,Kera,LANGUAGE,LANGUAGE
SM254609,144.75,-6.57,Kerabi,ETHNICITY,ETHNICITY
SM254610,142,-9,Keraki,ETHNICITY,ETHNICITY
SM1783,76.5,10.4167,Kerala,ADM1,DISTRICT
SM250712,30.22,23.04,Kerarish,ETHNICITY,ETHNICITY
SM13197,78.25,44.1667,Kerbulaq Audany (excl. Gvardiya),ADM2,DISTRICT
SM299880,36.4741,45.3531,Kerchens'ka,ADM2,DISTRICT
SM18229,36.1062,45.2615,Kerchenskyi Raion (2023-),ADM2,DISTRICT
SM234447,113.644,0.6465,Kereho,LANGUAGE,LANGUAGE
SM234442,175.07,62.1777,Kerek,LANGUAGE,LANGUAGE
SM288645,146.093,-7.796,Kerema,ADM2,DISTRICT
SM274030,38.45,15.7833,Keren,ADM2,DISTRICT
SM295349,157.0732,-7.0968,Kerepangara,ADM2,DISTRICT
SM254612,-107.35,35.34,Keres,ETHNICITY,ETHNICITY
SM234452,-106.7233,35.5036,Keresan Pueblo Indian Sign Language,LANGUAGE,LANGUAGE
SM234445,33.5326,-2.0932,Kerewe,LANGUAGE,LANGUAGE
SM250359,33.38,-3.12,Kerewe,ETHNICITY,ETHNICITY
SM250359,33.38,-3.12,Kerewe,ETHNICITY,ETHNICITY
SM250359,33,-3,Kerewe,ETHNICITY,ETHNICITY
SM250359,33,-3,Kerewe,ETHNICITY,ETHNICITY
SM234448,144.309,-7.551,Kerewo,LANGUAGE,LANGUAGE
SM1784,69.1667,-49.25,Kerguelen,ADM1,DISTRICT
SM1785,35.383,-0.273,"Kericho (1992-1998, 2010-)",ADM1:ADM2,DISTRICT
SM234455,101.403,-1.9367,Kerinci,LANGUAGE,LANGUAGE
SM276749,101.5333,-2.0333,Kerinci,ADM2,DISTRICT
SM274031,37.4167,16.1,Kerke Bet,ADM2,DISTRICT
SM297950,11.1832,34.7097,Kerkennah,ADM2,DISTRICT
SM285863,6.0488,50.8734,Kerkrade,ADM2,DISTRICT
SM277514,58,30.3333,Kerman,ADM2,DISTRICT
SM1786,57.25,29.75,Kerman,ADM1,DISTRICT
SM277524,47,34.3333,Kermanshah,ADM2,DISTRICT
SM1787,46.75,34.4167,Kermanshah,ADM1,DISTRICT
SM303169,-118.7299,35.3429,Kern,ADM2,DISTRICT
SM254613,-118.77,36.99,Kern,ETHNICITY,ETHNICITY
SM458812,133.5851,-13.6537,Kerniyn'mi,ETHNICITY,ETHNICITY
SM288619,144.8833,-5.9333,Kerowagi,ADM2,DISTRICT
SM302677,-99.3502,30.0615,Kerr,ADM2,DISTRICT
SM1788,-9.75,52.1667,Kerry,ADM1:ADM2,DISTRICT
SM302452,-80.5903,34.3388,Kershaw,ADM2,DISTRICT
SM271737,10.6222,55.455,Kerteminde (2007-),ADM2,DISTRICT
SM10790,33.3139,35.3286,Keryneia,ADM2,DISTRICT
SM234465,145.58,-5.768,Kesawai,LANGUAGE,LANGUAGE
SM278754,141.5571,38.9138,Kesennuma,ADM2,DISTRICT
SM234470,51.47,33.23,Kesha'i,DIALECT,LANGUAGE
SM298633,33.6184,39.6827,Keskin,ADM2,DISTRICT
SM294752,37.3333,57.5833,Kesovogorskiy rayon,ADM2,DISTRICT
SM297972,9.3845,35.7967,Kesra,ADM2,DISTRICT
SM298261,29.2892,40.1822,Kestel,ADM2,DISTRICT
SM285785,5.5715,51.9144,Kesteren,ADM2,DISTRICT
SM234483,87.5466,63.7551,Ket,LANGUAGE,LANGUAGE
SM254615,90,62,Ket,ETHNICITY,ETHNICITY
SM254615,90,62,Ket,ETHNICITY,ETHNICITY
SM254615,90,62,Ket,ETHNICITY,ETHNICITY
SM254615,87.547,64.4435,Ket,ETHNICITY,ETHNICITY
SM254615,87.547,64.4435,Ket,ETHNICITY,ETHNICITY
SM254615,87.547,64.4435,Ket,ETHNICITY,ETHNICITY
SM254615,89.0049,62.4623,Ket,ETHNICITY,ETHNICITY
SM254615,89.0049,62.4623,Ket,ETHNICITY,ETHNICITY
SM254615,89.0049,62.4623,Ket,ETHNICITY,ETHNICITY
SM274970,0.877,5.905,Keta,ADM2,DISTRICT
SM234473,121.425,25.0257,Ketangalan,LANGUAGE,LANGUAGE
SM276385,110.5,-1.5833,Ketapang,ADM2,DISTRICT
SM293062,45.1667,47.1667,Ketchenerovskiy rayon,ADM2,DISTRICT
SM301175,-131.4167,55.5006,Ketchikan Gateway (pre-2008),ADM2,DISTRICT
SM234475,22.9027,-7.151,Kete,LANGUAGE,LANGUAGE
SM254616,121.38,24.58,Ketegalan,ETHNICITY,ETHNICITY
SM234477,140.443,-4.5042,Ketengban,LANGUAGE,LANGUAGE
SM16941,65,55.5,Ketovskiy Rayon,ADM2,DISTRICT
SM4395,0.971,6.213,Ketu North,ADM2,DISTRICT
SM4396,1.047,6.086,Ketu South,ADM2,DISTRICT
SM234484,140.604,-5.3465,Ketum,LANGUAGE,LANGUAGE
SM283989,-16.2773,16.6418,Keur-Macène,ADM2,DISTRICT
SM346805,24.6697,62.3372,Keuruu,ADM2:ADM3,DISTRICT
SM254618,143.98,-6.74,Kewa,ETHNICITY,ETHNICITY
SM257359,-105,36,Kewa,ETHNICITY,ETHNICITY
SM257359,-105,36,Kewa,ETHNICITY,ETHNICITY
SM257359,-106.1,35.3,Kewa,ETHNICITY,ETHNICITY
SM257359,-106.1,35.3,Kewa,ETHNICITY,ETHNICITY
SM303245,-87.5143,44.4828,Kewaunee,ADM2,DISTRICT
SM301287,-88.212,47.283,Keweenaw,ADM2,DISTRICT
SM258709,-111,34,Kewyipaya Yavapai,ETHNICITY,ETHNICITY
SM258709,-111,34,Kewyipaya Yavapai,ETHNICITY,ETHNICITY
SM258709,-111,34,Kewyipaya Yavapai,ETHNICITY,ETHNICITY
SM258709,-110.5,33.37,Kewyipaya Yavapai,ETHNICITY,ETHNICITY
SM258709,-110.5,33.37,Kewyipaya Yavapai,ETHNICITY,ETHNICITY
SM258709,-110.5,33.37,Kewyipaya Yavapai,ETHNICITY,ETHNICITY
SM258709,-111.5,33.7,Kewyipaya Yavapai,ETHNICITY,ETHNICITY
SM258709,-111.5,33.7,Kewyipaya Yavapai,ETHNICITY,ETHNICITY
SM258709,-111.5,33.7,Kewyipaya Yavapai,ETHNICITY,ETHNICITY
SM301725,-99.7124,42.8788,Keya Paha,ADM2,DISTRICT
SM234494,145.464,-6.476,Keyagana,LANGUAGE,LANGUAGE
SM12457,-76.9353,18.0165,Keystone,ADM2,DISTRICT
SM250353,36,0,Keyu,ETHNICITY,ETHNICITY
SM293432,101,59,Kezhemskiy rayon,ADM2,DISTRICT
SM298480,30.2916,37.9528,Keçiborlu,ADM2,DISTRICT
SM18035,32.831,40.0211,Keçiören (2008-),ADM2,DISTRICT
SM298344,26.6442,40.8661,Keşan,ADM2,DISTRICT
SM298431,38.5144,40.9139,Keşap,ADM2,DISTRICT
SM296158,20.4293,49.1359,Kežmarok,ADM2,DISTRICT
SM234499,22.7202,-24.9208,Kgalagadi,LANGUAGE,LANGUAGE
SM1789,22,-25,Kgalagadi,ADM1,DISTRICT
SM251048,28.94,-23.32,Kgatla,ETHNICITY,ETHNICITY
SM268549,26.3691,-24.1714,Kgatleng,ADM2,DISTRICT
SM1790,26.5,-24.25,Kgatleng,ADM1,DISTRICT
SM234542,105,18.25,Kha Phong,DIALECT,LANGUAGE
SM1791,134,55,Khabarovsk,ADM1,DISTRICT
SM16942,135.25,48.5,Khabarovskiy Rayon,ADM2,DISTRICT
SM292964,79.5,53.5833,Khabarskiy rayon,ADM2,DISTRICT
SM305057,45.7853,16.5975,Khabb wa ash Sha'af,ADM2,DISTRICT
SM293112,41.8333,44.1667,Khabezskiy rayon,ADM2,DISTRICT
SM9544,65.7538,33.8965,Khadir,ADM2,DISTRICT
SM277612,60,34.5,Khaf,ADM2,DISTRICT
SM277410,86.6,25.5,Khagaria,ADM2,DISTRICT
SM262239,91.9,23.1667,Khagrachhari,ADM2,DISTRICT
SM259287,75.1972,33.2436,Khah,LANGUAGE,LANGUAGE
SM297578,100.3139,14.8144,Khai Bang Rachan,ADM2,DISTRICT
SM283539,101.9369,48.6067,Khairkhan,ADM2,DISTRICT
SM283539,101.9369,48.6067,Khairkhan,ADM2,DISTRICT
SM283539,101.9349,48.6036,Khairkhan,ADM2,DISTRICT
SM283539,101.9349,48.6036,Khairkhan,ADM2,DISTRICT
SM283655,102.0499,45.9407,Khairkhandulaan,ADM2,DISTRICT
SM375503,69.0644,26.8348,Khairpur,ADM2:ADM3,DISTRICT
SM234506,107.169,50.7393,Khakas,LANGUAGE,LANGUAGE
SM254630,72.17,32.71,Khakasi,ETHNICITY,ETHNICITY
SM1792,90,53.3333,Khakass,ADM1,DISTRICT
SM260046,69.4337,34.3649,Khaki Jabar,ADM2,DISTRICT
SM260273,62.0721,32.7565,Khaki Safed,ADM2,DISTRICT
SM260057,65.5034,32.0108,Khakrez,ADM2,DISTRICT
SM234508,49.576,36.5317,Khalaj,LANGUAGE,LANGUAGE
SM234512,87.5053,27.3912,Khaling,LANGUAGE,LANGUAGE
SM283542,96.1622,45.9254,Khaliun,ADM2,DISTRICT
SM254634,107.14,47.59,Khalkh,ETHNICITY,ETHNICITY
SM254634,107.14,47.59,Khalkh,ETHNICITY,ETHNICITY
SM254634,107.14,47.59,Khalkh,ETHNICITY,ETHNICITY
SM254634,107.14,47.59,Khalkh,ETHNICITY,ETHNICITY
SM254634,107.14,47.59,Khalkh,ETHNICITY,ETHNICITY
SM254634,107.14,47.59,Khalkh,ETHNICITY,ETHNICITY
SM254634,107.14,47.59,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.0835,47.1665,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.0835,47.1665,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.0835,47.1665,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.0835,47.1665,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.0835,47.1665,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.0835,47.1665,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.0835,47.1665,Khalkh,ETHNICITY,ETHNICITY
SM254634,97,46,Khalkh,ETHNICITY,ETHNICITY
SM254634,97,46,Khalkh,ETHNICITY,ETHNICITY
SM254634,97,46,Khalkh,ETHNICITY,ETHNICITY
SM254634,97,46,Khalkh,ETHNICITY,ETHNICITY
SM254634,97,46,Khalkh,ETHNICITY,ETHNICITY
SM254634,97,46,Khalkh,ETHNICITY,ETHNICITY
SM254634,97,46,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.08,47.17,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.08,47.17,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.08,47.17,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.08,47.17,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.08,47.17,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.08,47.17,Khalkh,ETHNICITY,ETHNICITY
SM254634,96.08,47.17,Khalkh,ETHNICITY,ETHNICITY
SM254634,96,48,Khalkh,ETHNICITY,ETHNICITY
SM254634,96,48,Khalkh,ETHNICITY,ETHNICITY
SM254634,96,48,Khalkh,ETHNICITY,ETHNICITY
SM254634,96,48,Khalkh,ETHNICITY,ETHNICITY
SM254634,96,48,Khalkh,ETHNICITY,ETHNICITY
SM254634,96,48,Khalkh,ETHNICITY,ETHNICITY
SM254634,96,48,Khalkh,ETHNICITY,ETHNICITY
SM254634,91.16,48.95,Khalkh,ETHNICITY,ETHNICITY
SM254634,91.16,48.95,Khalkh,ETHNICITY,ETHNICITY
SM254634,91.16,48.95,Khalkh,ETHNICITY,ETHNICITY
SM254634,91.16,48.95,Khalkh,ETHNICITY,ETHNICITY
SM254634,91.16,48.95,Khalkh,ETHNICITY,ETHNICITY
SM254634,91.16,48.95,Khalkh,ETHNICITY,ETHNICITY
SM254634,91.16,48.95,Khalkh,ETHNICITY,ETHNICITY
SM254634,101,47,Khalkh,ETHNICITY,ETHNICITY
SM254634,101,47,Khalkh,ETHNICITY,ETHNICITY
SM254634,101,47,Khalkh,ETHNICITY,ETHNICITY
SM254634,101,47,Khalkh,ETHNICITY,ETHNICITY
SM254634,101,47,Khalkh,ETHNICITY,ETHNICITY
SM254634,101,47,Khalkh,ETHNICITY,ETHNICITY
SM254634,101,47,Khalkh,ETHNICITY,ETHNICITY
SM283796,118.6178,47.6273,Khalkhgol,ADM2,DISTRICT
SM283680,112.9501,46.1613,Khalzan,ADM2,DISTRICT
SM280829,103.6569,19.7577,Kham,ADM2,DISTRICT
SM297760,104.3337,15.672,Kham Khuan Kaeo,ADM2,DISTRICT
SM296949,103.6398,16.9388,Kham Muang,ADM2,DISTRICT
SM297119,102.1664,15.3804,Kham Sakae Saeng,ADM2,DISTRICT
SM297505,103.786,17.8255,Kham Ta Kla,ADM2,DISTRICT
SM297120,101.9317,15.0364,Kham Thala So,ADM2,DISTRICT
SM234517,94.8944,28.993,Khamba,LANGUAGE,LANGUAGE
SM297084,104.3593,16.6145,Khamcha-i,ADM2,DISTRICT
SM304776,43.9337,15.9765,Khamir,ADM2,DISTRICT
SM17792,42.8667,18.7,Khamis Mushayt,ADM2,DISTRICT
SM11661,80.3333,17.5,Khammam (2016-),ADM2,DISTRICT
SM1793,105.3333,17.5,Khammouan,ADM1,DISTRICT
SM461524,114.6,50.68,Khamnigan,ETHNICITY,ETHNICITY
SM234515,117,49,Khamnigan Mongol,LANGUAGE,LANGUAGE
SM254641,94.93,34.87,Khampa,ETHNICITY,ETHNICITY
SM234516,91.7062,31.9313,Khams Tibetan,LANGUAGE,LANGUAGE
SM234523,97.0359,27.6653,Khamti,LANGUAGE,LANGUAGE
SM260028,65.7212,37.3137,Khamyab,ADM2,DISTRICT
SM234524,93.9933,26.8895,Khamyang,LANGUAGE,LANGUAGE
SM10091,104.9141,11.5625,Khan 7 Makara,ADM2,DISTRICT
SM260095,69.1041,36.6031,Khan Abad,ADM2,DISTRICT
SM10092,104.9218,11.5439,Khan Chamkar Mon,ADM2,DISTRICT
SM260284,65.2297,37.111,Khan Char Bagh,ADM2,DISTRICT
SM10093,104.7891,11.5631,Khan Chey Por Senchey,ADM2,DISTRICT
SM10094,104.8394,11.5246,Khan Dangkao,ADM2,DISTRICT
SM10095,104.9203,11.5756,Khan Duon Penh,ADM2,DISTRICT
SM10096,104.7716,11.5394,Khan Kamboul,ADM2,DISTRICT
SM10097,104.9547,11.5198,Khan Mean Chey,ADM2,DISTRICT
SM297156,100.6765,13.8238,Khan Na Yao,ADM2,DISTRICT
SM10098,104.9099,11.6195,Khan Russey Keo,ADM2,DISTRICT
SM10099,104.8608,11.6129,Khan Saen Sokh,ADM2,DISTRICT
SM10100,104.8996,11.5671,Khan Tuol Kouk,ADM2,DISTRICT
SM289738,34.3,31.35,Khan Yunis,ADM1:ADM2,DISTRICT
SM283631,104.48,43.7734,Khan khongor,ADM2,DISTRICT
SM15004,106.4364,47.7995,Khan-Uul,ADM2,DISTRICT
SM234537,7.4436,4.7091,Khana,LANGUAGE,LANGUAGE
SM285201,7.4226,4.6996,Khana,ADM2,DISTRICT
SM9545,66.1285,36.784,Khanaqah,ADM2,DISTRICT
SM305782,45.2897,34.3615,Khanaqin,ADM2,DISTRICT
SM283632,107.2001,43.1914,Khanbogd,ADM2,DISTRICT
SM234531,74.142,21.3181,Khandesi,LANGUAGE,LANGUAGE
SM276977,76.5833,21.75,Khandwa (East Nimar) (2003-),ADM2,DISTRICT
SM375466,72.061,30.3734,Khanewal,ADM2:ADM3,DISTRICT
SM304956,45.6871,13.4205,Khanfir,ADM2,DISTRICT
SM283540,99.4661,47.8618,Khangai,ADM2,DISTRICT
SM283776,104.3821,49.3142,Khangal,ADM2,DISTRICT
SM261888,46.7532,39.8174,Khankendi  (Nagorno-Karabakh),ADM1:ADM2,DISTRICT
SM283597,100.664,51.5027,Khankh,ADM2,DISTRICT
SM297210,99.8019,9.1832,Khanom,ADM2,DISTRICT
SM277704,50.3333,33.25,Khansar,ADM2,DISTRICT
SM280785,104.8234,16.5494,Khanthabouly,ADM2,DISTRICT
SM254657,68.66,62.15,Khants,ETHNICITY,ETHNICITY
SM254657,68.66,62.15,Khants,ETHNICITY,ETHNICITY
SM254657,75.33,56.2,Khants,ETHNICITY,ETHNICITY
SM254657,75.33,56.2,Khants,ETHNICITY,ETHNICITY
SM254657,74,62,Khants,ETHNICITY,ETHNICITY
SM254657,74,62,Khants,ETHNICITY,ETHNICITY
SM1795,72,62,Khanty-Mansiy,ADM1,DISTRICT
SM296955,99.6762,16.0023,Khanu Woralaksaburi,ADM2,DISTRICT
SM234541,103.467,21.4543,Khao,LANGUAGE,LANGUAGE
SM297321,100.0905,7.4631,Khao Chaison,ADM2,DISTRICT
SM297341,100.9945,16.6631,Khao Kho,ADM2,DISTRICT
SM297002,99.1181,8.2695,Khao Phanom,ADM2,DISTRICT
SM297684,102.4178,12.4289,Khao Saming,ADM2,DISTRICT
SM296976,102.7821,16.9168,Khao Suan Kwang,ADM2,DISTRICT
SM296950,104.1003,16.6796,Khao Wong,ADM2,DISTRICT
SM297352,99.8075,13.2424,Khao Yoi,ADM2,DISTRICT
SM268518,91.3901,26.9409,Khar,ADM2,DISTRICT
SM254658,20.95,-24.09,Khara (!Khara),ETHNICITY,ETHNICITY
SM305058,44.2255,16.6107,Kharab Al Marashi,ADM2,DISTRICT
SM293853,47.5,47.25,Kharabalinskiy rayon,ADM2,DISTRICT
SM274798,43.25,42,Kharagauli,ADM2,DISTRICT
SM234550,94.0503,25.2738,Kharam Naga,LANGUAGE,LANGUAGE
SM5102,65.5312,28.6086,Kharan (2007-),ADM2:ADM3,DISTRICT
SM234549,84.3922,22.3571,Kharia,LANGUAGE,LANGUAGE
SM254664,85.12,23.6,Kharia,ETHNICITY,ETHNICITY
SM254664,85.12,23.6,Kharia,ETHNICITY,ETHNICITY
SM254664,82.73,25.77,Kharia,ETHNICITY,ETHNICITY
SM254664,82.73,25.77,Kharia,ETHNICITY,ETHNICITY
SM234547,83.799,22.245,Kharia Thar,LANGUAGE,LANGUAGE
SM304777,44.0822,15.847,Kharif,ADM2,DISTRICT
SM283638,102.824,47.1925,Kharkhorin,ADM2,DISTRICT
SM1796,36.5,49.5,Kharkiv,ADM1,DISTRICT
SM18230,36.2664,49.9827,Kharkiv City Council,ADM2,DISTRICT
SM5133,76.0805,34.903,Kharmang,ADM2:ADM3,DISTRICT
SM294967,40.25,60.0833,Kharovskiy rayon,ADM2,DISTRICT
SM295231,32.5425,15.5103,Khartoum,ADM2,DISTRICT
SM1797,33,15.8,Khartoum,ADM1,DISTRICT
SM295230,32.824,15.874,Khartoum Bahri,ADM2,DISTRICT
SM299937,38.2492,48.0319,Khartsyz'ka,ADM2,DISTRICT
SM9546,68.8928,33.695,Kharwar,ADM2,DISTRICT
SM260088,70.9385,34.6129,Khas Kunar,ADM2,DISTRICT
SM260222,66.6936,32.9967,Khas Uruzgan,ADM2,DISTRICT
SM294086,131,42.75,Khasanskiy rayon,ADM2,DISTRICT
SM292834,46.6667,43.3333,Khasavyurtovskiy rayon,ADM2,DISTRICT
SM277620,61.2,28.2167,Khash,ADM2,DISTRICT
SM9547,70.7257,36.9403,Khash,ADM2,DISTRICT
SM260138,62.9104,31.7359,Khash Rod,ADM2,DISTRICT
SM283523,103.4353,47.3498,Khashaat,ADM2,DISTRICT
SM283523,103.4353,47.3498,Khashaat,ADM2,DISTRICT
SM283523,103.1503,47.4453,Khashaat,ADM2,DISTRICT
SM283523,103.1503,47.4453,Khashaat,ADM2,DISTRICT
SM274783,43.6333,42,Khashuri,ADM2,DISTRICT
SM234556,91.9998,24.977,Khasi,LANGUAGE,LANGUAGE
SM254674,82.84,21.63,Khasi,ETHNICITY,ETHNICITY
SM254674,82.84,21.63,Khasi,ETHNICITY,ETHNICITY
SM254674,82.84,21.63,Khasi,ETHNICITY,ETHNICITY
SM254674,91.48,24.71,Khasi,ETHNICITY,ETHNICITY
SM254674,91.48,24.71,Khasi,ETHNICITY,ETHNICITY
SM254674,91.48,24.71,Khasi,ETHNICITY,ETHNICITY
SM254674,91.825,25.55,Khasi,ETHNICITY,ETHNICITY
SM254674,91.825,25.55,Khasi,ETHNICITY,ETHNICITY
SM254674,91.825,25.55,Khasi,ETHNICITY,ETHNICITY
SM254674,92,26,Khasi,ETHNICITY,ETHNICITY
SM254674,92,26,Khasi,ETHNICITY,ETHNICITY
SM254674,92,26,Khasi,ETHNICITY,ETHNICITY
SM250813,-8.53,16.43,Khassonke,ETHNICITY,ETHNICITY
SM250813,-8.53,16.43,Khassonke,ETHNICITY,ETHNICITY
SM250813,-11,13,Khassonke,ETHNICITY,ETHNICITY
SM250813,-11,13,Khassonke,ETHNICITY,ETHNICITY
SM293610,150.9167,60.0833,Khasynskiy rayon,ADM2,DISTRICT
SM9742,49.9451,40.3785,Khatai raion,ADM2,DISTRICT
SM283808,109.1334,43.1455,Khatanbulag,ADM2,DISTRICT
SM293434,104,72,Khatangskiy rayon,ADM2,DISTRICT
SM283598,100.1617,50.4394,Khatgal,ADM2,DISTRICT
SM1798,69,37.8333,Khatlon,ADM1,DISTRICT
SM254694,44.54,16.03,Khawlan Al-Akiya,ETHNICITY,ETHNICITY
SM17793,39.3333,25.8333,Khaybar,ADM2,DISTRICT
SM294266,58.25,52,Khaybullinskiy rayon,ADM2,DISTRICT
SM234568,34.25,0.54,Khayo,LANGUAGE,LANGUAGE
SM304842,43.3576,16.0896,Khayran Al Muharraq,ADM2,DISTRICT
SM234569,-4.2438,9.797,Khe,LANGUAGE,LANGUAGE
SM276819,72.8333,22.75,Kheda (2013-),ADM2,DISTRICT
SM234570,146.746,-2.0663,Khehek,LANGUAGE,LANGUAGE
SM11315,41.6667,41.5833,Khelvachauri Municipality (excl. Batumi),ADM2,DISTRICT
SM297712,105.1517,15.9711,Khemarat,ADM2,DISTRICT
SM1799,7,35,Khenchela,ADM1,DISTRICT
SM234572,90.8633,27.0879,Khengkha,LANGUAGE,LANGUAGE
SM254699,31.85,-22.92,Kheni,ETHNICITY,ETHNICITY
SM4426,-5.6667,32.9333,Khenifra (2009-),ADM2,DISTRICT
SM283784,114.4988,48.0581,Kherlen,ADM2,DISTRICT
SM283784,114.4988,48.0581,Kherlen,ADM2,DISTRICT
SM283566,110.6801,47.3381,Kherlen,ADM2,DISTRICT
SM283784,114.5529,48.1373,Kherlen,ADM2,DISTRICT
SM283784,114.5529,48.1373,Kherlen,ADM2,DISTRICT
SM1800,32.6159,46.6406,Kherson,ADM1,DISTRICT
SM18231,32.6151,46.6374,Kherson City,ADM2,DISTRICT
SM18232,32.8141,46.63,Khersonskyi Raion (2020-),ADM2,DISTRICT
SM234578,70.2828,30.4799,Khetrani,LANGUAGE,LANGUAGE
SM234579,94.3114,25.7078,Khezha Naga,LANGUAGE,LANGUAGE
SM234581,95.0168,26.2008,Khiamniungan Naga,LANGUAGE,LANGUAGE
SM297636,99.1324,8.7277,Khian Sa,ADM2,DISTRICT
SM295083,110.4667,51.35,Khilokskiy rayon,ADM2,DISTRICT
SM16943,37.4167,55.9167,Khimki Urban Okrug,ADM2,DISTRICT
SM234584,48.0571,41.1801,Khinalugh,LANGUAGE,LANGUAGE
SM9548,69.7843,35.4296,Khinj,ADM2,DISTRICT
SM260200,68.9736,35.4713,Khinjan,ADM2,DISTRICT
SM268463,89.9576,27.0303,Khipisa,ADM2,DISTRICT
SM297600,99.7398,16.8292,Khiri Mat,ADM2,DISTRICT
SM297619,98.944,9.0059,Khiri Ratthanikhom,ADM2,DISTRICT
SM234585,82.9615,23.7062,Khirwar,LANGUAGE,LANGUAGE
SM234586,-4.0542,9.721,Khisa,LANGUAGE,LANGUAGE
SM283777,103.4292,48.2904,Khishig-Öndör,ADM2,DISTRICT
SM236381,92.2395,27.3689,Khispi,LANGUAGE,LANGUAGE
SM454410,118,42,Khitan,ETHNICITY,ETHNICITY
SM254705,58.48,27.76,Khitar,ETHNICITY,ETHNICITY
SM254706,56.48,41.48,Khiva Yomut,ETHNICITY,ETHNICITY
SM292835,47.9167,41.75,Khivskiy rayon,ADM2,DISTRICT
SM18405,60.3639,41.3783,Khiwa Shahri,ADM2,DISTRICT
SM293565,39.0833,52.1667,Khlevenskiy rayon,ADM2,DISTRICT
SM297488,102.276,13.474,Khlong Hat,ADM2,DISTRICT
SM297593,100.3535,6.8662,Khlong Hoi Kong,ADM2,DISTRICT
SM297281,100.6811,14.0932,Khlong Luang,ADM2,DISTRICT
SM297157,100.7381,13.8763,Khlong Sam Wa,ADM2,DISTRICT
SM297158,100.5007,13.726,Khlong San,ADM2,DISTRICT
SM297003,99.1994,7.9147,Khlong Thom,ADM2,DISTRICT
SM297159,100.5707,13.7181,Khlong Toey,ADM2,DISTRICT
SM297685,102.826,11.8554,Khlong Yai,ADM2,DISTRICT
SM234588,106.606,15.6415,Khlor,LANGUAGE,LANGUAGE
SM234590,104.45,23.1,Khlula,LANGUAGE,LANGUAGE
SM297787,102.3078,12.5725,Khlung,ADM2,DISTRICT
SM1801,26.9784,49.4198,Khmel'nyts'kyy,ADM1,DISTRICT
SM18233,26.9607,49.4026,Khmelnytskyy Raion,ADM2,DISTRICT
SM254707,104.46,14,Khmer,ETHNICITY,ETHNICITY
SM254707,104.46,14,Khmer,ETHNICITY,ETHNICITY
SM254707,104.46,14,Khmer,ETHNICITY,ETHNICITY
SM254707,104.46,14,Khmer,ETHNICITY,ETHNICITY
SM254707,104.72,11.99,Khmer,ETHNICITY,ETHNICITY
SM254707,104.72,11.99,Khmer,ETHNICITY,ETHNICITY
SM254707,104.72,11.99,Khmer,ETHNICITY,ETHNICITY
SM254707,104.72,11.99,Khmer,ETHNICITY,ETHNICITY
SM254707,105.93,10.35,Khmer,ETHNICITY,ETHNICITY
SM254707,105.93,10.35,Khmer,ETHNICITY,ETHNICITY
SM254707,105.93,10.35,Khmer,ETHNICITY,ETHNICITY
SM254707,105.93,10.35,Khmer,ETHNICITY,ETHNICITY
SM254707,105,12,Khmer,ETHNICITY,ETHNICITY
SM254707,105,12,Khmer,ETHNICITY,ETHNICITY
SM254707,105,12,Khmer,ETHNICITY,ETHNICITY
SM254707,105,12,Khmer,ETHNICITY,ETHNICITY
SM254707,103.83,13,Khmer,ETHNICITY,ETHNICITY
SM254707,103.83,13,Khmer,ETHNICITY,ETHNICITY
SM254707,103.83,13,Khmer,ETHNICITY,ETHNICITY
SM254707,103.83,13,Khmer,ETHNICITY,ETHNICITY
SM254707,105,12,Khmer,ETHNICITY,ETHNICITY
SM254707,105,12,Khmer,ETHNICITY,ETHNICITY
SM254707,105,12,Khmer,ETHNICITY,ETHNICITY
SM254707,105,12,Khmer,ETHNICITY,ETHNICITY
SM299794,27.9554,49.5587,Khmil'nyts'kyi (pre-2020),ADM2,DISTRICT
SM254709,103.92,19.99,Khmou,ETHNICITY,ETHNICITY
SM234594,101.671,20.2463,Khmu,LANGUAGE,LANGUAGE
SM297761,104.3411,15.3816,Kho Wang,ADM2,DISTRICT
SM234602,48.6212,36.8997,Kho'ini,LANGUAGE,LANGUAGE
SM11316,41.8,42.25,Khobis Munitsip'alit'et'I (excl. Kalaki Poti and Poti),ADM2,DISTRICT
SM277667,48.5,36,Khodabandeh,ADM2,DISTRICT
SM17932,69.9167,40.3333,Khodzhentskiy Rayon,ADM2,DISTRICT
SM260117,70.1494,34.2315,Khogyani,ADM2,DISTRICT
SM234603,94.1345,24.2041,Khoibu,LANGUAGE,LANGUAGE
SM234605,92.5105,27.3429,Khoina,DIALECT,LANGUAGE
SM234604,92.436,27.3294,Khoitam,DIALECT,LANGUAGE
SM297055,100.8497,15.4038,Khok Charoen,ADM2,DISTRICT
SM297292,101.1169,6.7086,Khok Pho,ADM2,DISTRICT
SM297056,100.7715,15.043,Khok Samrong,ADM2,DISTRICT
SM297506,104.3035,17.023,Khok Sri Supan,ADM2,DISTRICT
SM294973,38.75,51.5,Khokhol'skiy rayon,ADM2,DISTRICT
SM293604,41.6667,64.0833,Kholmogorskiy rayon,ADM2,DISTRICT
SM293838,31.3333,57.0833,Kholmskiy rayon,ADM2,DISTRICT
SM234608,10.9671,9.3364,Kholok,LANGUAGE,LANGUAGE
SM234609,54.2958,27.1197,Kholosi,LANGUAGE,LANGUAGE
SM375394,68.8072,29.6441,Kholu,ADM2:ADM3,DISTRICT
SM1802,17.0833,-22.75,Khomas,ADM1,DISTRICT
SM277566,50,33.6667,Khomeyn,ADM2,DISTRICT
SM13977,27.9317,-28.8819,Khomokhoana Community,ADM2,DISTRICT
SM293526,34.6667,51.8333,Khomutovskiy rayon,ADM2,DISTRICT
SM297121,102.2175,14.3763,Khon Buri,ADM2,DISTRICT
SM1803,102.6167,16.4306,Khon Kaen,ADM1,DISTRICT
SM297781,101.7179,16.5469,Khon San,ADM2,DISTRICT
SM297782,102.2729,15.9238,Khon Sawan,ADM2,DISTRICT
SM297122,102.2962,15.4331,Khong,ADM2,DISTRICT
SM280851,105.9594,14.147,Khong,ADM2,DISTRICT
SM297689,105.5036,15.4543,Khong Chiam,ADM2,DISTRICT
SM15005,105.9604,49.4262,Khongor and Darkhan,ADM2,DISTRICT
SM280768,105.7705,15.5463,Khongxedone,ADM2,DISTRICT
SM11317,42.4333,42.3167,Khoni,ADM2,DISTRICT
SM11318,42.5,42.4,Khonis Munitsip'alit'et'I (excl. Khoni),ADM2,DISTRICT
SM11780,53.0833,27.9333,Khonj,ADM2,DISTRICT
SM18406,73.0047,40.7976,Khonobod Shahri,ADM2,DISTRICT
SM280814,100.561,19.6646,Khop,ADM2,DISTRICT
SM260680,56.3028,25.3397,Khor Fakkan,ADM2,DISTRICT
SM234623,59.1434,35.8576,Khorasan Arabic,LANGUAGE,LANGUAGE
SM234619,60.0277,36.4438,Khorasan Turkic,LANGUAGE,LANGUAGE
SM277117,85.6,20.2,Khordha,ADM2,DISTRICT
SM1804,60.5,41.5,Khorezm,ADM1,DISTRICT
SM295134,109.8333,52.5,Khorinskiy rayon,ADM2,DISTRICT
SM284527,14.96,-20.3679,Khorixas,ADM2,DISTRICT
SM299663,33.2743,49.783,Khorol's'kyi,ADM2,DISTRICT
SM294087,132.0667,44.4167,Khorol'skiy rayon,ADM2,DISTRICT
SM277668,49.2,36.2333,Khorramdarreh,ADM2,DISTRICT
SM277530,48.1833,30.6833,Khorramshahr,ADM2,DISTRICT
SM254716,43.45,32.24,Khorsa Bedu,ETHNICITY,ETHNICITY
SM254716,40.64,36.3,Khorsa Bedu,ETHNICITY,ETHNICITY
SM1805,69.8833,33.3833,Khost,ADM1,DISTRICT
SM260073,70.0038,33.3404,Khost (Matun),ADM2,DISTRICT
SM9549,69.6648,35.9047,Khost wa Firing (2005-),ADM2,DISTRICT
SM269344,79.5444,36.0767,Khotan,ADM2,DISTRICT
SM234626,79.04,38.33,Khotanese,LANGUAGE,LANGUAGE
SM375322,86.6808,27.1563,Khotang,ADM2:ADM3,DISTRICT
SM283524,102.4685,47.3598,Khotont,ADM2,DISTRICT
SM262624,32.5,53.4167,Khotsimsk,ADM2,DISTRICT
SM299873,26.4946,48.5086,Khotyns'kyi,ADM2,DISTRICT
SM280830,103.509,19.2798,Khoune,ADM2,DISTRICT
SM281412,-6.6,32.9333,Khouribga,ADM2,DISTRICT
SM17933,69.9667,38.35,Khovalingskiy Rayon,ADM2,DISTRICT
SM283738,90.9137,49.2743,Khovd,ADM2,DISTRICT
SM283580,91.3568,48.1019,Khovd,ADM2,DISTRICT
SM18407,68.905,40.2672,Khovos Tumani,ADM2,DISTRICT
SM277262,91.6051,24.067,Khowai,ADM2,DISTRICT
SM234631,72,36,Khowar,LANGUAGE,LANGUAGE
SM277644,44.7834,38.5833,Khoy,ADM2,DISTRICT
SM262604,29.9167,51.9167,Khoyniki,ADM2,DISTRICT
SM304205,105.9746,20.8217,Khoái Châu,ADM2,DISTRICT
SM279938,58.5,50.3333,Khromtauskiy,ADM2,DISTRICT
SM231774,101.928,31.5655,Khroskyabs,LANGUAGE,LANGUAGE
SM299424,29.9687,48.8125,Khrystynivs'kyi,ADM2,DISTRICT
SM280513,105.0373,11.6922,Khsach Kandal,ADM2,DISTRICT
SM297431,103.0272,15.2684,Khu Muang,ADM2,DISTRICT
SM234634,106.021,17.402,Khua,LANGUAGE,LANGUAGE
SM280763,102.3762,21.0671,Khua,ADM2,DISTRICT
SM297548,100.1222,6.7698,Khuan Don,ADM2,DISTRICT
SM297549,100.0418,6.9059,Khuan Ka Long,ADM2,DISTRICT
SM297322,100.0435,7.7644,Khuan Khanun,ADM2,DISTRICT
SM297594,100.374,7.1823,Khuan Niang,ADM2,DISTRICT
SM297690,104.5436,15.391,Khuang Nai,ADM2,DISTRICT
SM17794,45.25,17.6667,Khubash,ADM2,DISTRICT
SM234637,101.327,21.0793,Khuen,LANGUAGE,LANGUAGE
SM234638,71.6037,37.8595,Khufi,DIALECT,LANGUAGE
SM283639,102.7819,46.8892,Khujirt,ADM2,DISTRICT
SM297574,104.1859,14.7378,Khukhan,ADM2,DISTRICT
SM17795,39.4333,22.3,Khulays,ADM2,DISTRICT
SM283825,105.5663,45.0408,Khuld,ADM2,DISTRICT
SM9550,67.7433,36.8145,Khulm (2005-),ADM2,DISTRICT
SM262263,89.3333,22.5,Khulna,ADM2,DISTRICT
SM1806,89.3333,22.9167,Khulna,ADM1,DISTRICT
SM250574,16.2,-15.36,Khumbi,ETHNICITY,ETHNICITY
SM250574,16.2,-15.36,Khumbi,ETHNICITY,ETHNICITY
SM250574,15,-15,Khumbi,ETHNICITY,ETHNICITY
SM250574,15,-15,Khumbi,ETHNICITY,ETHNICITY
SM254722,93.71,20.5,Khumi,ETHNICITY,ETHNICITY
SM234642,93.3656,20.5226,Khumi Awa Chin,LANGUAGE,LANGUAGE
SM234640,92.9895,22.8713,Khumi Chin,LANGUAGE,LANGUAGE
SM297575,104.4074,14.5346,Khun Han,ADM2,DISTRICT
SM296909,100.2873,19.8648,Khun Tan,ADM2,DISTRICT
SM297062,97.933,18.8264,Khun Yuam,ADM2,DISTRICT
SM234643,49.3742,36.6963,Khunsaric,LANGUAGE,LANGUAGE
SM276902,85.2746,22.9977,Khunti,ADM2,DISTRICT
SM292836,46.6667,42.5833,Khunzakhskiy rayon,ADM2,DISTRICT
SM304770,45.0552,12.8658,Khur Maksar,ADM2,DISTRICT
SM297301,98.4081,9.1897,Khura Buri,ADM2,DISTRICT
SM260188,68.1217,35.9865,Khuram Wa Sarbagh,ADM2,DISTRICT
SM305971,68.6667,38.1333,Khuroson,ADM2,DISTRICT
SM305971,68.6667,38.1333,Khuroson,ADM2,DISTRICT
SM305971,68.6833,38.0667,Khuroson,ADM2,DISTRICT
SM305971,68.6833,38.0667,Khuroson,ADM2,DISTRICT
SM283668,105.6576,49.8827,Khushaat,ADM2,DISTRICT
SM375477,72.111,32.1826,Khushab,ADM2:ADM3,DISTRICT
SM260112,69.2748,34.0013,Khushi,ADM2,DISTRICT
SM299761,23.2919,48.1762,Khusts'kyi,ADM2,DISTRICT
SM283778,102.694,49.3913,Khutag-Öndör,ADM2,DISTRICT
SM283778,102.694,49.3913,Khutag-Öndör,ADM2,DISTRICT
SM283778,102.815,49.3625,Khutag-Öndör,ADM2,DISTRICT
SM283778,102.815,49.3625,Khutag-Öndör,ADM2,DISTRICT
SM375379,66.587,27.4868,Khuzdar,ADM2:ADM3,DISTRICT
SM254725,29.97,-29.57,Khuze,ETHNICITY,ETHNICITY
SM1807,49,31.5,Khuzestan,ADM1,DISTRICT
SM16944,47.9167,52.5,Khvalynskiy Rayon,ADM2,DISTRICT
SM293094,35.1667,53.5,Khvastovichskiy rayon,ADM2,DISTRICT
SM293839,34.5,58.9167,Khvoyninskiy rayon,ADM2,DISTRICT
SM9551,70.394,37.8372,Khwahan (2005-),ADM2,DISTRICT
SM260029,65.6123,36.9484,Khwaja Du Koh,ADM2,DISTRICT
SM260285,64.966,36.0586,Khwaja Sabz Posh,ADM2,DISTRICT
SM9552,68.4217,33.6755,Khwajah 'Omari,ADM2,DISTRICT
SM9553,69.5825,37.3393,Khwajah Bahawuddin,ADM2,DISTRICT
SM9554,69.398,37.0183,Khwajah Ghar (2005-),ADM2,DISTRICT
SM9555,69.2939,35.966,Khwajah Hijran,ADM2,DISTRICT
SM234653,60.75,41.68,Khwarezmian,LANGUAGE,LANGUAGE
SM234652,45.9977,42.2743,Khwarshi-Inkhoqwari,LANGUAGE,LANGUAGE
SM304937,44.7686,15.2749,Khwlan,ADM2,DISTRICT
SM283739,93.8232,49.5352,Khyargas,ADM2,DISTRICT
SM375403,71.0577,33.9611,Khyber,ADM2:ADM3,DISTRICT
SM461318,71.6861,33.9831,Khyber Pakhtunkhwa (2018-),ADM1,DISTRICT
SM234533,103.736,21.6624,Kháng,LANGUAGE,LANGUAGE
SM1794,109,12.3333,Khánh Hòa,ADM1,DISTRICT
SM304215,108.9081,12.0286,Khánh Sơn,ADM2,DISTRICT
SM304216,108.8307,12.3059,Khánh Vĩnh,ADM2,DISTRICT
SM281419,-6.25,33.6667,Khémisset,ADM2,DISTRICT
SM283543,94.5253,47.3515,Khökh morit,ADM2,DISTRICT
SM283785,112.9504,47.9085,Khölönbuir,ADM2,DISTRICT
SM283785,112.9504,47.9085,Khölönbuir,ADM2,DISTRICT
SM283785,112.9617,47.9261,Khölönbuir,ADM2,DISTRICT
SM283785,112.9617,47.9261,Khölönbuir,ADM2,DISTRICT
SM283809,109.6388,43.5983,Khövsgöl,ADM2,DISTRICT
SM283667,107.5177,49.7702,Khüder,ADM2,DISTRICT
SM283667,107.5177,49.7702,Khüder,ADM2,DISTRICT
SM283667,107.5161,49.7764,Khüder,ADM2,DISTRICT
SM283667,107.5161,49.7764,Khüder,ADM2,DISTRICT
SM234646,99.3363,19.6062,Khün,LANGUAGE,LANGUAGE
SM283752,98.2878,46.4021,Khüreemaral,ADM2,DISTRICT
SM283633,104.0742,43.2993,Khürmen,ADM2,DISTRICT
SM244248,28.6923,-15.6067,KiSetla,LANGUAGE,LANGUAGE
SM295400,158.5769,-7.6208,Kia,ADM2,DISTRICT
SM233192,140.104,-6.7973,Kia River Awyu,LANGUAGE,LANGUAGE
SM10791,33.6045,35.2566,Kiados,ADM2,DISTRICT
SM231001,166.931,-15.4484,Kiai,LANGUAGE,LANGUAGE
SM261563,150.8457,-34.6712,Kiama,ADM2,DISTRICT
SM5253,36.8333,-1.1667,Kiambu (1994-2007),ADM2,DISTRICT
SM1808,36.699,-1.09,"Kiambu (pre-1992, 2010-)",ADM1:ADM2,DISTRICT
SM275044,-15.75,13.4,Kiang Central,ADM2,DISTRICT
SM275045,-15.6333,13.4167,Kiang East,ADM2,DISTRICT
SM275046,-16,13.3333,Kiang West,ADM2,DISTRICT
SM10048,114.9438,4.8995,Kianggeh (excl. Bandar Seri Begawan),ADM2,DISTRICT
SM299299,31.0326,0.9302,Kibaale (2016-),ADM1:ADM2,DISTRICT
SM261972,29.8911,-4.2902,Kibago,ADM2,DISTRICT
SM299151,38.6128,-6.8228,Kibaha,ADM2,DISTRICT
SM299150,38.9061,-6.6911,Kibaha Urban,ADM2,DISTRICT
SM227480,15.0867,-10.3511,Kibala,LANGUAGE,LANGUAGE
SM299337,32.0703,2.0157,Kibanda,ADM1:ADM2,DISTRICT
SM16313,12.1934,-3.3822,Kibangou,ADM2,DISTRICT
SM254728,143.69,-7.29,Kibenel,ETHNICITY,ETHNICITY
SM234665,20.8767,11.3205,Kibet,LANGUAGE,LANGUAGE
SM279048,133.751,34.8556,Kibichūō,ADM2,DISTRICT
SM234666,144.296,-7.4528,Kibiri,LANGUAGE,LANGUAGE
SM284868,8.6856,11.4972,Kibiya,ADM2,DISTRICT
SM4787,31.9328,0.85,Kiboga (2010-),ADM1:ADM2,DISTRICT
SM299244,31.0089,-4.0783,Kibondo,ADM2,DISTRICT
SM227966,45.1368,-12.9652,Kibosy Kiantalaotsy-Majunga,LANGUAGE,LANGUAGE
SM299384,33.8088,1.05,Kibuku,ADM1:ADM2,DISTRICT
SM461319,20.95,41.5167,Kicevo (2013-),ADM1,DISTRICT
SM294968,46,60,Kichmengsko-Gorodetskiy rayon,ADM2,DISTRICT
SM234675,-89.0303,41.6551,Kickapoo,LANGUAGE,LANGUAGE
SM254731,-89.31,43.49,Kickapoo,ETHNICITY,ETHNICITY
SM254731,-89.31,43.49,Kickapoo,ETHNICITY,ETHNICITY
SM254731,-90,43,Kickapoo,ETHNICITY,ETHNICITY
SM254731,-90,43,Kickapoo,ETHNICITY,ETHNICITY
SM295164,30.147,-2.0039,Kicukiro,ADM1:ADM2,DISTRICT
SM283354,1.3354,18.4439,Kidal,ADM2,DISTRICT
SM1812,1.2,19.4,Kidal,ADM1,DISTRICT
SM10792,32.7098,34.8143,Kidasi,ADM2,DISTRICT
SM302044,-99.7801,46.9801,Kidder,ADM2,DISTRICT
SM296325,15.7569,46.3989,Kidricevo,ADM1:ADM2,DISTRICT
SM254733,-120,42,Kidutokado,ETHNICITY,ETHNICITY
SM254733,-120,42,Kidutokado,ETHNICITY,ETHNICITY
SM254733,-120.06,41.5,Kidutokado,ETHNICITY,ETHNICITY
SM254733,-120.06,41.5,Kidutokado,ETHNICITY,ETHNICITY
SM288761,20.6332,50.8016,Kielce,ADM2,DISTRICT
SM288760,20.6156,50.8542,Kielce (City),ADM2,DISTRICT
SM254734,-95.99,33.85,Kieltai,ETHNICITY,ETHNICITY
SM280514,105.121,11.4319,Kien Svay,ADM2,DISTRICT
SM1815,30.2316,50.2402,Kiev,ADM1,DISTRICT
SM1816,30.549,50.4429,Kiev City,ADM1,DISTRICT
SM283998,-11.3478,16.7497,Kiffa,ADM2,DISTRICT
SM305783,44.8582,34.5229,Kifri,ADM2,DISTRICT
SM262057,30.5319,-3.0428,Kigamba,ADM2,DISTRICT
SM261979,29.6748,-3.3379,Kiganda,ADM2,DISTRICT
SM254735,-80.05,59.82,Kigiktagmiut,ETHNICITY,ETHNICITY
SM254735,-79.75,57.54,Kigiktagmiut,ETHNICITY,ETHNICITY
SM294268,58.75,55.4167,Kiginskiy rayon,ADM2,DISTRICT
SM1817,30.5,-4.75,Kigoma,ADM1,DISTRICT
SM299245,29.6892,-4.753,Kigoma Rural,ADM2,DISTRICT
SM299246,29.6623,-4.8891,Kigoma Urban,ADM2,DISTRICT
SM274301,23.99,58.13,Kihnu,ADM2,DISTRICT
SM278719,136.2369,34.176,Kihoku,ADM2,DISTRICT
SM274103,24.8529,59.2967,Kiili,ADM2,DISTRICT
SM370463,6.0076,49.9784,Kiischpelt,ADM1:ADM2:ADM3,DISTRICT
SM305444,16.358,43.9785,Kijevo,ADM2,DISTRICT
SM234688,129.982,28.31,Kikai,LANGUAGE,LANGUAGE
SM254737,-148.33,69.55,Kikitarmiut,ETHNICITY,ETHNICITY
SM293301,47.1667,57.3333,Kiknurskiy rayon,ADM2,DISTRICT
SM250169,15,-7,Kikongo (Bakongo N & S),ETHNICITY,ETHNICITY
SM250169,15,-7,Kikongo (Bakongo N & S),ETHNICITY,ETHNICITY
SM250169,15,-5.5,Kikongo (Bakongo N & S),ETHNICITY,ETHNICITY
SM250169,15,-5.5,Kikongo (Bakongo N & S),ETHNICITY,ETHNICITY
SM288646,144.3333,-7.25,Kikori,ADM2,DISTRICT
SM268405,90.1404,26.9987,Kikorthang,ADM2,DISTRICT
SM278622,130.8552,32.9911,Kikuchi,ADM2,DISTRICT
SM12971,130.8805,32.8853,Kikuchi-gun (excl. Kikuchi town),ADM2,DISTRICT
SM279284,138.1,34.75,Kikugawa,ADM2,DISTRICT
SM234691,36.719,-0.29,Kikuyu,LANGUAGE,LANGUAGE
SM250365,38.69,0.24,Kikuyu,ETHNICITY,ETHNICITY
SM250365,38.69,0.24,Kikuyu,ETHNICITY,ETHNICITY
SM250365,38.69,0.24,Kikuyu,ETHNICITY,ETHNICITY
SM250365,38.69,0.24,Kikuyu,ETHNICITY,ETHNICITY
SM250365,37.167,-0.667,Kikuyu,ETHNICITY,ETHNICITY
SM250365,37.167,-0.667,Kikuyu,ETHNICITY,ETHNICITY
SM250365,37.167,-0.667,Kikuyu,ETHNICITY,ETHNICITY
SM250365,37.167,-0.667,Kikuyu,ETHNICITY,ETHNICITY
SM250365,37,-1,Kikuyu,ETHNICITY,ETHNICITY
SM250365,37,-1,Kikuyu,ETHNICITY,ETHNICITY
SM250365,37,-1,Kikuyu,ETHNICITY,ETHNICITY
SM250365,37,-1,Kikuyu,ETHNICITY,ETHNICITY
SM250365,37.17,-0.67,Kikuyu,ETHNICITY,ETHNICITY
SM250365,37.17,-0.67,Kikuyu,ETHNICITY,ETHNICITY
SM250365,37.17,-0.67,Kikuyu,ETHNICITY,ETHNICITY
SM250365,37.17,-0.67,Kikuyu,ETHNICITY,ETHNICITY
SM294906,43.1667,50.75,Kikvidzenskiy rayon,ADM2,DISTRICT
SM296504,13.3174,59.5041,Kil,ADM2,DISTRICT
SM293302,51,57,Kil'mezskiy rayon,ADM2,DISTRICT
SM289106,129.1667,41,Kilchu,ADM2,DISTRICT
SM1818,-6.75,53.1667,Kildare,ADM1:ADM2,DISTRICT
SM234695,35.8348,68.2158,Kildin Saami,LANGUAGE,LANGUAGE
SM293628,46.9167,56.75,Kilemarskiy rayon,ADM2,DISTRICT
SM234697,132.48,47.585,Kilen,LANGUAGE,LANGUAGE
SM280197,34.8833,-1,Kilgoris,ADM2,DISTRICT
SM4081,169.1265,5.6461,Kili Island,ADM1,DISTRICT
SM1819,39.644,-3.563,Kilifi,ADM1:ADM2,DISTRICT
SM299610,29.2648,45.4445,Kiliis'kyi,ADM2,DISTRICT
SM1820,37.6333,-3.75,Kilimanjaro,ADM1,DISTRICT
SM18065,31.8339,41.4824,Kilimli,ADM2,DISTRICT
SM299196,37.5667,-5.5667,Kilindi,ADM2,DISTRICT
SM1821,80.4089,9.3949,Kilinochchi,ADM1:ADM2,DISTRICT
SM1822,37.14,36.73,Kilis,ADM1,DISTRICT
SM234702,151.072,-8.7257,Kilivila,LANGUAGE,LANGUAGE
SM234703,-115.123,30.5471,Kiliwa,LANGUAGE,LANGUAGE
SM254740,-115,31,Kiliwa,ETHNICITY,ETHNICITY
SM254740,-115,31,Kiliwa,ETHNICITY,ETHNICITY
SM254740,-115,31,Kiliwa,ETHNICITY,ETHNICITY
SM254740,-115.25,31.44,Kiliwa,ETHNICITY,ETHNICITY
SM254740,-115.25,31.44,Kiliwa,ETHNICITY,ETHNICITY
SM254740,-115.25,31.44,Kiliwa,ETHNICITY,ETHNICITY
SM254740,-115,31.5,Kiliwa,ETHNICITY,ETHNICITY
SM254740,-115,31.5,Kiliwa,ETHNICITY,ETHNICITY
SM254740,-115,31.5,Kiliwa,ETHNICITY,ETHNICITY
SM1823,-7.25,52.5833,Kilkenny,ADM1:ADM2,DISTRICT
SM323936,-111.852,52.7946,Killam,ADM2:ADM3,DISTRICT
SM10182,-99.6666,49.1667,Killarney-Turtle Mountain,ADM2,DISTRICT
SM254741,-102.9,69.05,Killinermiut,ETHNICITY,ETHNICITY
SM254742,-64.77,59.85,Killtinhunmiut,ETHNICITY,ETHNICITY
SM12458,-77.9277,18.1241,Kilmarnoch,ADM2,DISTRICT
SM234709,141.191,-2.8861,Kilmeri,LANGUAGE,LANGUAGE
SM299223,35.85,-8,Kilolo,ADM2,DISTRICT
SM299115,36.3349,-8.2414,Kilombero,ADM2,DISTRICT
SM299116,36.9779,-6.8967,Kilosa,ADM2,DISTRICT
SM254743,-102.21,70.13,Kilusiktogmiut,ETHNICITY,ETHNICITY
SM299082,39.0446,-9.0395,Kilwa,ADM2,DISTRICT
SM234719,15.8936,9.7944,Kim,LANGUAGE,LANGUAGE
SM304197,105.5477,20.6879,Kim Bôi,ADM2,DISTRICT
SM304164,105.8504,20.5725,Kim Bảng,ADM2,DISTRICT
SM234718,105.024,22.9856,Kim Mun,LANGUAGE,LANGUAGE
SM304341,106.0986,20.0603,Kim Sơn,ADM2,DISTRICT
SM304070,106.5099,20.9381,Kim Thành,ADM2,DISTRICT
SM304206,106.0363,20.7465,Kim Động,ADM2,DISTRICT
SM234712,138.355,-8.019,Kimaama,LANGUAGE,LANGUAGE
SM254745,138.5,-7.5,Kimam,ETHNICITY,ETHNICITY
SM254745,138.5,-7.5,Kimam,ETHNICITY,ETHNICITY
SM254745,138.46,-7.52,Kimam,ETHNICITY,ETHNICITY
SM254745,138.46,-7.52,Kimam,ETHNICITY,ETHNICITY
SM234710,116.96,6.6198,Kimaragang,LANGUAGE,LANGUAGE
SM16314,14.182,-3.3196,Kimba,ADM2,DISTRICT
SM261700,136.318,-33.0482,Kimba,ADM2,DISTRICT
SM301726,-103.715,41.1977,Kimball,ADM2,DISTRICT
SM302678,-99.7487,30.4868,Kimble,ADM2,DISTRICT
SM234715,33.6445,-7.2302,Kimbu,LANGUAGE,LANGUAGE
SM250368,32.96,-6.96,Kimbu,ETHNICITY,ETHNICITY
SM234714,14.8893,-8.9784,Kimbundu,LANGUAGE,LANGUAGE
SM289107,129.1067,40.8106,Kimch'aek,ADM2,DISTRICT
SM234716,-54.6259,-25.8266,Kimda,LANGUAGE,LANGUAGE
SM289182,127.5833,38.4172,Kimhwa,ADM2,DISTRICT
SM289087,128.1667,40.75,Kimhyŏnggwŏn,ADM2,DISTRICT
SM289088,127.2008,41.4003,Kimhyŏngjik,ADM2,DISTRICT
SM279516,135.3873,34.1568,Kimino,ADM2,DISTRICT
SM10301,21.3517,12.0101,Kimiti,ADM2,DISTRICT
SM279358,140.0241,35.263,Kimitsu,ADM2,DISTRICT
SM289089,127.6663,41.2502,Kimjŏngsuk,ADM2,DISTRICT
SM234717,140.884,-4.2742,Kimki,LANGUAGE,LANGUAGE
SM269834,12.9135,-4.4413,Kimongo,ADM2,DISTRICT
SM12972,130.8938,31.2161,Kimotsuki Gun,ADM2,DISTRICT
SM234720,16.9423,9.4207,Kimre,LANGUAGE,LANGUAGE
SM16945,37.3333,56.9167,Kimrskiy Rayon,ADM2,DISTRICT
SM234727,125.2217,10.2589,Kinabalian,LANGUAGE,LANGUAGE
SM234726,147.442,-6.0586,Kinalakna,LANGUAGE,LANGUAGE
SM262030,29.3923,-3.3252,Kinama,ADM2,DISTRICT
SM234725,122.297,10.8131,Kinaray-a,LANGUAGE,LANGUAGE
SM282977,-90.0097,20.8826,Kinchil,ADM2,DISTRICT
SM296422,15.7337,58.0132,Kinda,ADM2,DISTRICT
SM254747,56.77,24,Kinda,ETHNICITY,ETHNICITY
SM16315,14.5262,-3.7436,Kindamba,ADM2,DISTRICT
SM274991,-12.8,10.0833,Kindia,ADM1:ADM2,DISTRICT
SM1824,-13.2133,10.0867,Kindia,ADM1,DISTRICT
SM16946,50.75,53.1667,Kinel'skiy Rayon,ADM2,DISTRICT
SM292953,42.1333,57.4333,Kineshema,ADM2,DISTRICT
SM292954,42.3333,57.3333,Kineshemskiy rayon,ADM2,DISTRICT
SM302679,-100.2559,33.6165,King,ADM2,DISTRICT
SM303077,-121.8358,47.4908,King,ADM2,DISTRICT
SM303033,-77.1565,38.2737,King George,ADM2,DISTRICT
SM261763,143.9915,-39.8722,King Island,ADM2,DISTRICT
SM254748,-119.4,36.8,King River Yokuts,ETHNICITY,ETHNICITY
SM303034,-77.0884,37.7066,King William,ADM2,DISTRICT
SM303032,-76.8953,37.7186,King and Queen,ADM2,DISTRICT
SM240795,34.2321,-9.3587,Kinga,LANGUAGE,LANGUAGE
SM250594,35.53,-8.4,Kinga,ETHNICITY,ETHNICITY
SM261764,147.2511,-42.9745,Kingborough,ADM2,DISTRICT
SM302202,-97.9421,35.9454,Kingfisher,ADM2,DISTRICT
SM16947,28.5,59.5,Kingiseppskiy Rayon,ADM2,DISTRICT
SM300931,-98.1363,37.5589,Kingman,ADM2,DISTRICT
SM305293,-162.3754,6.3835,Kingman Reef,ADM0:ADM1,DISTRICT
SM254750,-63.64,65.92,Kingnaitmiut,ETHNICITY,ETHNICITY
SM16316,14.178,-3.7761,Kingoue,ADM2,DISTRICT
SM301883,-73.9503,40.6344,Kings,ADM2,DISTRICT
SM303170,-119.8155,36.0754,Kings,ADM2,DISTRICT
SM268805,-65.7488,45.6668,Kings,ADM2,DISTRICT
SM268622,-62.4986,46.2501,Kings,ADM2,DISTRICT
SM268824,-64.6655,45.0335,Kings,ADM2,DISTRICT
SM254751,-119,36.7,Kings River Mono,ETHNICITY,ETHNICITY
SM302509,-97.4915,44.3696,Kingsbury,ADM2,DISTRICT
SM261701,140.0509,-36.6408,Kingston,ADM2,DISTRICT
SM261244,145.0953,-38.0045,Kingston,ADM2,DISTRICT
SM1825,-76.8,17.9667,Kingston,ADM1,DISTRICT
SM12459,-76.7868,17.9784,Kingston Gardens,ADM2,DISTRICT
SM274642,-0.3333,53.75,Kingston upon Hull,ADM1:ADM2,DISTRICT
SM12460,-78.2052,18.3809,Kingsvale,ADM2,DISTRICT
SM304071,106.5038,21.0163,Kinh Môn,ADM2,DISTRICT
SM231777,-54.0333,-19.1667,Kinikinao,DIALECT,LANGUAGE
SM262031,29.3537,-3.4097,Kinindo,ADM2,DISTRICT
SM254752,152.11,-25.46,Kiniyen,ETHNICITY,ETHNICITY
SM16317,14.8682,-4.3066,Kinkala,ADM2,DISTRICT
SM1826,33.75,39.8333,Kinkkale,ADM1,DISTRICT
SM12461,-77.5697,18.3964,Kinloss,ADM2,DISTRICT
SM299051,118.3886,24.4533,Kinmen,ADM2,DISTRICT
SM15298,5.3276,61.6889,Kinn,ADM2,DISTRICT
SM276872,78.4167,31.5833,Kinnaur,ADM2,DISTRICT
SM234741,78.3148,31.3688,Kinnauri,LANGUAGE,LANGUAGE
SM302680,-100.418,29.3502,Kinney,ADM2,DISTRICT
SM279517,135.3941,34.254,Kinokawa,ADM2,DISTRICT
SM299147,39.1444,-6.7167,Kinondoni,ADM2,DISTRICT
SM1827,15.97,-4.375,Kinshasa,ADM1:ADM2,DISTRICT
SM236352,15.2853,-4.4092,Kinshasa Lingala,LANGUAGE,LANGUAGE
SM11121,15.4134,-4.4403,Kinshasa Urban,ADM2,DISTRICT
SM11122,15.5952,-4.384,Kinshasa rural,ADM2,DISTRICT
SM284450,101.2,4.5,Kinta,ADM2,DISTRICT
SM254753,99.5,7.7,Kintag,ETHNICITY,ETHNICITY
SM274887,-1.567,8.303,Kintampo North,ADM2,DISTRICT
SM274871,-1.7667,7.9,Kintampo South,ADM2,DISTRICT
SM234743,101.277,5.75,Kintaq,LANGUAGE,LANGUAGE
SM12462,-76.7269,18.0213,Kintyre,ADM2,DISTRICT
SM254755,-165.51,65.36,Kinugumint,ETHNICITY,ETHNICITY
SM234744,8.1296,10.3524,Kinuku,LANGUAGE,LANGUAGE
SM234747,29.6441,-1.5677,Kinyarwanda,LANGUAGE,LANGUAGE
SM262015,30.3642,-3.6241,Kinyinya,ADM2,DISTRICT
SM234748,122.951,-4.9901,Kioko,LANGUAGE,LANGUAGE
SM10793,33.2578,35.2899,Kiomourtzou,ADM2,DISTRICT
SM10794,33.2955,35.2221,Kioneli,ADM2,DISTRICT
SM234750,8.3523,5.205,Kiong,LANGUAGE,LANGUAGE
SM234751,101.211,21.0684,Kiorr,LANGUAGE,LANGUAGE
SM10795,32.5318,34.971,Kios,ADM2,DISTRICT
SM234754,-98.9042,34.9403,Kiowa,LANGUAGE,LANGUAGE
SM302203,-98.9809,34.9164,Kiowa,ADM2,DISTRICT
SM300932,-99.2861,37.5583,Kiowa,ADM2,DISTRICT
SM303343,-102.7403,38.4327,Kiowa,ADM2,DISTRICT
SM254756,-101.55,36.51,Kiowa,ETHNICITY,ETHNICITY
SM254756,-101.55,36.51,Kiowa,ETHNICITY,ETHNICITY
SM254756,-101.55,36.51,Kiowa,ETHNICITY,ETHNICITY
SM254756,-99,36,Kiowa,ETHNICITY,ETHNICITY
SM254756,-99,36,Kiowa,ETHNICITY,ETHNICITY
SM254756,-99,36,Kiowa,ETHNICITY,ETHNICITY
SM254756,-99.1,36.9,Kiowa,ETHNICITY,ETHNICITY
SM254756,-99.1,36.9,Kiowa,ETHNICITY,ETHNICITY
SM254756,-99.1,36.9,Kiowa,ETHNICITY,ETHNICITY
SM234752,-98.2461,34.9077,Kiowa Apache,LANGUAGE,LANGUAGE
SM254757,134.25,-3.67,Kiowiai,ETHNICITY,ETHNICITY
SM254757,134.25,-3.67,Kiowiai,ETHNICITY,ETHNICITY
SM254757,143,-9,Kiowiai,ETHNICITY,ETHNICITY
SM254757,143,-9,Kiowiai,ETHNICITY,ETHNICITY
SM234756,-37.1411,-7.393,Kipea,DIALECT,LANGUAGE
SM277098,94.8251,25.8449,Kiphire,ADM2,DISTRICT
SM234758,35.2585,-0.3577,Kipsigis,LANGUAGE,LANGUAGE
SM250370,35.55,-0.76,Kipsigis,ETHNICITY,ETHNICITY
SM250370,35.55,-0.76,Kipsigis,ETHNICITY,ETHNICITY
SM250370,36,-1,Kipsigis,ETHNICITY,ETHNICITY
SM250370,36,-1,Kipsigis,ETHNICITY,ETHNICITY
SM7491,15.56,-12.28,Kipunga,ETHNICITY,ETHNICITY
SM269736,27.2335,-11.7665,Kipushi (ville),ADM2,DISTRICT
SM234759,114.367,4.1654,Kiput,LANGUAGE,LANGUAGE
SM234764,9.77,10.1456,Kir-Balar,LANGUAGE,LANGUAGE
SM9793,29.4743,53.2729,Kirawsk,ADM2,DISTRICT
SM9794,29.5,53.3333,Kirawski Rayon (excl. Kirawsk),ADM2,DISTRICT
SM298539,28.2138,38.2409,Kiraz,ADM2,DISTRICT
SM261842,14.2135,47.8435,Kirchdorf an der Krems,ADM2,DISTRICT
SM254761,14.04,8.39,Kirdi,ETHNICITY,ETHNICITY
SM234766,144.711,-4.2593,Kire,LANGUAGE,LANGUAGE
SM294706,37.9167,53.9167,Kireevskiy rayon,ADM2,DISTRICT
SM295152,30.7262,-2.2566,Kirehe,ADM1:ADM2,DISTRICT
SM261998,30.0325,-2.8361,Kiremba,ADM2,DISTRICT
SM292892,109.25,58,Kirenskiy rayon,ADM2,DISTRICT
SM285314,10.5322,10.3943,Kirfi,ADM2,DISTRICT
SM234768,74,42,Kirghiz,LANGUAGE,LANGUAGE
SM280522,103.1731,11.1105,Kiri Sakor,ADM2,DISTRICT
SM280455,104.7984,10.6388,Kiri Vong,ADM2,DISTRICT
SM284791,10.2522,12.5653,KiriKasa,ADM2,DISTRICT
SM234770,6.9894,4.4113,Kirike,LANGUAGE,LANGUAGE
SM234772,137.282,-3.1849,Kirikiri,LANGUAGE,LANGUAGE
SM294940,38.5833,60,Kirillovskiy rayon,ADM2,DISTRICT
SM1828,37.3333,-0.5,Kirinyaga,ADM1:ADM2,DISTRICT
SM278492,130.7816,31.8056,Kirishima,ADM2,DISTRICT
SM16948,32.5,59.4167,Kirishskiy Rayon,ADM2,DISTRICT
SM13328,-157.475,1.94,Kiritimati,ADM2,DISTRICT
SM288578,151.0667,-8.4833,Kiriwina-Goodenough,ADM2,DISTRICT
SM459285,141.6657,-14.931,Kirka,ETHNICITY,ETHNICITY
SM1829,27.5,41.6667,Kirklareli,ADM1,DISTRICT
SM274643,-1.75,53.5833,Kirklees,ADM1:ADM2,DISTRICT
SM346078,38.7591,9.0003,Kirkos,ADM2:ADM3,DISTRICT
SM262530,25.3373,41.3582,Kirkovo,ADM2,DISTRICT
SM305854,44.3,35.5,Kirkuk,ADM2,DISTRICT
SM254763,-12.07,7.44,Kirm,ETHNICITY,ETHNICITY
SM234775,39.4004,39.1636,Kirmanjki,LANGUAGE,LANGUAGE
SM1830,50,58,Kirov,ADM1,DISTRICT
SM13198,68.1667,40.9167,Kirov Audany,ADM2,DISTRICT
SM16949,50,58.5,Kirovo-Chepetskiy Rayon,ADM2,DISTRICT
SM1831,32.266,48.5105,Kirovohrad,ADM1,DISTRICT
SM299525,32.22,48.5205,Kirovohrads'kyi,ADM2,DISTRICT
SM299938,38.3633,48.1514,Kirovs'ka,ADM2,DISTRICT
SM299582,38.6475,48.6349,Kirovs'ka,ADM2,DISTRICT
SM299881,35.177,45.1552,Kirovs'kyi,ADM2,DISTRICT
SM11319,44.75,41.7,Kirovskiy Rayon,ADM2,DISTRICT
SM9743,49.7833,40.5,Kirovskiy Rayon,ADM2,DISTRICT
SM16950,34.4167,54.0833,Kirovskiy Rayon (Kaluga),ADM2,DISTRICT
SM16951,31,59.8333,Kirovskiy Rayon (Leningrad),ADM2,DISTRICT
SM16952,30.2833,59.8667,Kirovskiy Rayon (Sankt-Peterburg),ADM2,DISTRICT
SM294088,133.6667,45,Kirovskiy rayon,ADM2,DISTRICT
SM293812,44.3333,43.3333,Kirovskiy rayon,ADM2,DISTRICT
SM294596,42.55,52.6167,Kirsanovskiy rayon,ADM2,DISTRICT
SM1832,34.1667,39.3333,Kirsehir,ADM1,DISTRICT
SM284869,8.149,11.543,Kiru,ADM2,DISTRICT
SM295350,157.2401,-7.2456,Kirugela,ADM2,DISTRICT
SM4736,30.9167,-0.25,Kiruhara (2005-2019),ADM1:ADM2,DISTRICT
SM296402,20.5486,68.1701,Kiruna,ADM2,DISTRICT
SM261968,30.0972,-2.5847,Kirundo,ADM2,DISTRICT
SM1833,30.1667,-2.5833,Kirundo,ADM1,DISTRICT
SM234780,13.36,10.48,Kirya-Konzel,LANGUAGE,LANGUAGE
SM278088,139.3096,36.457,Kiryū,ADM2,DISTRICT
SM294896,38.9167,56.0833,Kirzhachskiy rayon,ADM2,DISTRICT
SM234796,143.96,-3.8391,Kis,LANGUAGE,LANGUAGE
SM234784,34.5708,0.1529,Kisa,LANGUAGE,LANGUAGE
SM269723,25.2,0.5,Kisangani,ADM2,DISTRICT
SM234785,35.6751,-8.5411,Kisankasa,LANGUAGE,LANGUAGE
SM234785,35.6751,-8.5411,Kisankasa,LANGUAGE,LANGUAGE
SM234785,36.5977,-2.7805,Kisankasa,LANGUAGE,LANGUAGE
SM234785,36.5977,-2.7805,Kisankasa,LANGUAGE,LANGUAGE
SM234787,127.18,-8.0512,Kisar,LANGUAGE,LANGUAGE
SM254764,127.41,-7.56,Kisar,ETHNICITY,ETHNICITY
SM299152,38.6952,-7.1459,Kisarawe,ADM2,DISTRICT
SM279359,139.9875,35.3698,Kisarazu,ADM2,DISTRICT
SM276138,18.0214,47.4872,Kisbér,ADM2,DISTRICT
SM1834,21.5167,41.95,Kisela Voda,ADM1:ADM2,DISTRICT
SM277411,88,26.3,Kishanganj,ADM2,DISTRICT
SM299170,33.8,-3.731,Kishapu,ADM2,DISTRICT
SM9556,70.1326,36.7722,Kishim (2005-),ADM2,DISTRICT
SM12973,130.1472,33.1816,Kishima-gun,ADM2,DISTRICT
SM9557,66.9223,36.0052,Kishindeh (2005-),ADM2,DISTRICT
SM279100,135.4161,34.4275,Kishiwada,ADM2,DISTRICT
SM262254,90.95,24.4167,Kishoreganj,ADM2,DISTRICT
SM276878,76.0146,33.5296,Kishtwar,ADM2,DISTRICT
SM234792,34.4825,-9.93,Kisi,LANGUAGE,LANGUAGE
SM250371,34.31,-0.09,Kisi,ETHNICITY,ETHNICITY
SM250277,34.41,-0.98,Kisii,ETHNICITY,ETHNICITY
SM250277,34.41,-0.98,Kisii,ETHNICITY,ETHNICITY
SM250277,35,-1,Kisii,ETHNICITY,ETHNICITY
SM250277,35,-1,Kisii,ETHNICITY,ETHNICITY
SM5289,34.8333,-0.75,"Kisii (1989-1998, 2010-)",ADM1:ADM2,DISTRICT
SM276119,19.7701,46.6572,Kiskunfélegyház,ADM2,DISTRICT
SM276120,19.5307,46.3583,Kiskunhalas,ADM2,DISTRICT
SM276121,19.7232,46.4961,Kiskunmajsa,ADM2,DISTRICT
SM276118,19.3373,46.6331,Kiskőrös,ADM2,DISTRICT
SM295771,42.0094,-0.3767,Kismaayo,ADM2,DISTRICT
SM12974,137.6332,35.803,Kiso-gun,ADM2,DISTRICT
SM1836,29.6811,-1.2066,Kisoro,ADM1:ADM2,DISTRICT
SM278036,136.7415,35.0657,Kisosaki,ADM2,DISTRICT
SM250582,-8.98,10.11,Kissi (Northern),ETHNICITY,ETHNICITY
SM250582,-8.98,10.11,Kissi (Northern),ETHNICITY,ETHNICITY
SM250582,-10,9,Kissi (Northern),ETHNICITY,ETHNICITY
SM250582,-10,9,Kissi (Northern),ETHNICITY,ETHNICITY
SM274982,-9.9167,9.25,Kissidougou,ADM1:ADM2,DISTRICT
SM10796,32.401,34.8343,Kissonerga,ADM2,DISTRICT
SM10797,32.7968,34.808,Kissousa,ADM2,DISTRICT
SM234797,38.4763,8.3356,Kistane,LANGUAGE,LANGUAGE
SM305445,15.9633,43.9811,Kistanje,ADM2,DISTRICT
SM276249,19.9957,46.4883,Kistelek,ADM2,DISTRICT
SM1837,34.64,-0.069,Kisumu,ADM1:ADM2,DISTRICT
SM11576,22.0642,48.221,Kisvardai Jaras,ADM2,DISTRICT
SM254765,-52.79,-12.02,Kisêdjê,ETHNICITY,ETHNICITY
SM254765,-51.2,-9.67,Kisêdjê,ETHNICITY,ETHNICITY
SM303344,-102.6029,39.3054,Kit Carson,ADM2,DISTRICT
SM283350,-9.3971,13.2821,Kita,ADM2,DISTRICT
SM279441,139.7335,35.7526,Kita,ADM2,DISTRICT
SM254769,146.57,-8.57,Kita,ETHNICITY,ETHNICITY
SM234801,-9.4915,13.1798,Kita Maninkakan,LANGUAGE,LANGUAGE
SM250372,-9.94,12.96,Kita Maninkanan,ETHNICITY,ETHNICITY
SM278562,140.3998,40.2345,Kitaakita,ADM2,DISTRICT
SM12975,137.8813,36.7195,Kitaazumi Gun,ADM2,DISTRICT
SM14058,-7.6333,5.25,Kitabo,ADM2,DISTRICT
SM304931,44.3962,17.0808,Kitaf wa Al Boqe'e,ADM2,DISTRICT
SM279797,136.6897,35.4304,Kitagata,ADM2,DISTRICT
SM12976,138.9723,36.4479,Kitagumma-gun,ADM2,DISTRICT
SM278283,141.5568,42.9858,Kitahiroshima,ADM2,DISTRICT
SM278380,140.6874,36.8399,Kitaibaraki,ADM2,DISTRICT
SM278437,141.008,39.3077,Kitakami,ADM2,DISTRICT
SM279744,139.9,37.6833,Kitakata,ADM2,DISTRICT
SM14096,135.7297,34.5675,Kitakatsuragi-gun,ADM2,DISTRICT
SM12977,139.7426,36.0398,Kitakatsushika Gun,ADM2,DISTRICT
SM279678,130.8466,33.8518,Kitakyūshū,ADM2,DISTRICT
SM12978,129.6579,33.2387,Kitamatsuura-gun,ADM2,DISTRICT
SM278284,143.7152,43.8516,Kitami,ADM2,DISTRICT
SM279173,139.5304,36.0262,Kitamoto,ADM2,DISTRICT
SM234799,115.986,44.3773,Kitan,LANGUAGE,LANGUAGE
SM278037,136.8729,35.2464,Kitanagoya,ADM2,DISTRICT
SM254770,-117.73,35.08,Kitanemuk,ETHNICITY,ETHNICITY
SM12979,138.5041,36.315,Kitasaku-gun,ADM2,DISTRICT
SM12980,137.6356,35.1285,Kitashitara-gun,ADM2,DISTRICT
SM12981,140.427,40.96,Kitatsugaru Gun,ADM2,DISTRICT
SM12982,138.918,35.7841,Kitatsuru-gun,ADM2,DISTRICT
SM12983,132.7383,33.2734,Kitauwa-gun,ADM2,DISTRICT
SM234805,-62.75,-16,Kitemoka,LANGUAGE,LANGUAGE
SM299091,36.6954,-5.2405,Kiteto,ADM2,DISTRICT
SM299302,33.336,3.406,Kitgum (2009-),ADM1:ADM2,DISTRICT
SM10798,33.5748,34.8469,Kiti,ADM2,DISTRICT
SM268749,-129.0036,55.3329,Kitimat-Stikine,ADM2,DISTRICT
SM234809,127.392,-17.5758,Kitja,LANGUAGE,LANGUAGE
SM303521,67.0306,39.1607,Kitob,ADM2,DISTRICT
SM234810,-96.5,31.83,Kitsai,LANGUAGE,LANGUAGE
SM303078,-122.649,47.6398,Kitsap,ADM2,DISTRICT
SM323763,-110.3328,53.3407,Kitscoty,ADM2:ADM3,DISTRICT
SM299874,25.7603,48.4421,Kitsmans'kyi,ADM2,DISTRICT
SM12463,-77.0403,18.0349,Kitson Town,ADM2,DISTRICT
SM254771,-76.53,3.21,Kitsua,ETHNICITY,ETHNICITY
SM279017,131.5367,33.4473,Kitsuki,ADM2,DISTRICT
SM14936,158.1608,6.8249,Kitti Municipality,ADM2,DISTRICT
SM303079,-120.6797,47.1242,Kittitas,ADM2,DISTRICT
SM301366,-96.7829,48.7766,Kittson,ADM2,DISTRICT
SM234811,14.5648,-4.1565,Kituba (Congo),LANGUAGE,LANGUAGE
SM234812,17.1548,-2.296,Kituba (Democratic Republic of Congo),LANGUAGE,LANGUAGE
SM1839,38.451,-1.835,Kitui,ADM1:ADM2,DISTRICT
SM305165,28.25,-12.85,Kitwe,ADM2,DISTRICT
SM261868,12.3833,47.4333,Kitzbühel,ADM2,DISTRICT
SM268310,114.7,4.6667,Kiudang,ADM2,DISTRICT
SM10799,33.5129,34.8279,Kivisili,ADM2,DISTRICT
SM458072,125.9527,-23.5104,Kiyajarra,ETHNICITY,ETHNICITY
SM294826,53.1667,56.3333,Kiyasovskiy rayon,ADM2,DISTRICT
SM284792,9.537,11.8415,Kiyawa,ADM2,DISTRICT
SM279442,139.5263,35.7855,Kiyose,ADM2,DISTRICT
SM12984,136.8477,35.2133,Kiyosu-shi (2009-),ADM2,DISTRICT
SM458867,130.0535,-13.1682,Kiyuk,ETHNICITY,ETHNICITY
SM461515,86.38,50.7,Kizhi,ETHNICITY,ETHNICITY
SM295135,110.5,51.8333,Kizhinginskiy rayon,ADM2,DISTRICT
SM292748,59.4167,52.75,Kizil'skiy rayon,ADM2,DISTRICT
SM269345,76.2934,39.8919,Kizilsu Kirghiz,ADM2,DISTRICT
SM16953,46.9167,43.25,Kizilyurtovskiy Rayon,ADM2,DISTRICT
SM292839,46.7131,43.8478,Kizlyar,ADM2,DISTRICT
SM292840,47,44,Kizlyarskiy rayon,ADM2,DISTRICT
SM278691,135.8213,34.7378,Kizugawa,ADM2,DISTRICT
SM1813,11,2.05,Kié-Ntem,ADM1,DISTRICT
SM1814,105.1667,10,Kiên Giang,ADM1,DISTRICT
SM304234,104.6156,9.8397,Kiên Hải,ADM2,DISTRICT
SM304221,104.59,10.2489,Kiên Lương,ADM2,DISTRICT
SM298204,40.35,39.3089,Kiğı,ADM2,DISTRICT
SM304088,106.6361,20.8059,Kiến An,ADM2,DISTRICT
SM304075,106.6646,20.7299,Kiến Thuỵ,ADM2,DISTRICT
SM304297,105.9031,10.7787,Kiến Tường,ADM2,DISTRICT
SM304491,106.4203,20.4008,Kiến Xương,ADM2,DISTRICT
SM277784,-21.4992,64.3064,Kjósarhreppur,ADM2,DISTRICT
SM296053,-55.0781,5.1774,Klaaskreek,ADM2,DISTRICT
SM271188,14.1667,50.1667,Kladno,ADM2,DISTRICT
SM297460,101.6567,12.7718,Klaeng,ADM2,DISTRICT
SM261797,14.3,46.6333,Klagenfurt,ADM2,DISTRICT
SM261796,14.293,46.5672,Klagenfurt Land,ADM2,DISTRICT
SM254772,-125,51,Klahoose,ETHNICITY,ETHNICITY
SM254772,-125,51,Klahoose,ETHNICITY,ETHNICITY
SM254772,-124.5,50.5,Klahoose,ETHNICITY,ETHNICITY
SM254772,-124.5,50.5,Klahoose,ETHNICITY,ETHNICITY
SM14142,21.1167,55.7167,Klaipeda,ADM2,DISTRICT
SM1840,21.3333,55.6667,Klaipedos,ADM1,DISTRICT
SM14143,21.4,55.7167,Klaipedos rajonas (excl. Klaipeda city),ADM2,DISTRICT
SM305586,18.1333,45.0976,Klakar,ADM2,DISTRICT
SM274527,-6.5492,62.2264,Klaksvík,ADM2,DISTRICT
SM254773,113.32,1.5,Klamantan,ETHNICITY,ETHNICITY
SM302258,-121.6501,42.6863,Klamath,ADM2,DISTRICT
SM254774,-121.74,42.6,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.74,42.6,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.74,42.6,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.74,42.6,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.74,42.6,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.74,42.6,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.5,42.55,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.5,42.55,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.5,42.55,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.5,42.55,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.5,42.55,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.5,42.55,Klamath,ETHNICITY,ETHNICITY
SM254774,-122,43,Klamath,ETHNICITY,ETHNICITY
SM254774,-122,43,Klamath,ETHNICITY,ETHNICITY
SM254774,-122,43,Klamath,ETHNICITY,ETHNICITY
SM254774,-122,43,Klamath,ETHNICITY,ETHNICITY
SM254774,-122,43,Klamath,ETHNICITY,ETHNICITY
SM254774,-122,43,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.5,42.62,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.5,42.62,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.5,42.62,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.5,42.62,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.5,42.62,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.5,42.62,Klamath,ETHNICITY,ETHNICITY
SM254774,-122,42.5,Klamath,ETHNICITY,ETHNICITY
SM254774,-122,42.5,Klamath,ETHNICITY,ETHNICITY
SM254774,-122,42.5,Klamath,ETHNICITY,ETHNICITY
SM254774,-122,42.5,Klamath,ETHNICITY,ETHNICITY
SM254774,-122,42.5,Klamath,ETHNICITY,ETHNICITY
SM254774,-122,42.5,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.67,42.63,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.67,42.63,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.67,42.63,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.67,42.63,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.67,42.63,Klamath,ETHNICITY,ETHNICITY
SM254774,-121.67,42.63,Klamath,ETHNICITY,ETHNICITY
SM234831,-121.704,42.5861,Klamath-Modoc,LANGUAGE,LANGUAGE
SM239464,124.192,-8.2878,Klamu,LANGUAGE,LANGUAGE
SM305408,14.3765,45.4462,Klana,ADM2,DISTRICT
SM284386,101.4167,3.0833,Klang,ADM2,DISTRICT
SM276060,15.7462,46.0498,Klanjec,ADM2,DISTRICT
SM234835,-8.85,5.05,Klao,LANGUAGE,LANGUAGE
SM276308,110.6167,-7.6833,Klaten,ADM2,DISTRICT
SM271155,13.4167,49.25,Klatovy,ADM2,DISTRICT
SM10800,33.5151,34.8919,Klavdia,ADM2,DISTRICT
SM14059,-10.8744,6.6942,Klay (geonames),ADM2,DISTRICT
SM302681,-97.6684,27.4267,Kleberg,ADM2,DISTRICT
SM370141,-7.75,4.5167,Kleebo,ADM2:ADM3,DISTRICT
SM305503,16.0761,46.2703,Klenovnik,ADM2,DISTRICT
SM294230,40.0833,55.0833,Klepikovskiy rayon,ADM2,DISTRICT
SM10801,33.4341,35.3068,Klepini,ADM2,DISTRICT
SM286130,5.6301,58.7766,Klepp,ADM2,DISTRICT
SM10302,15.7735,13.8208,Kleta,ADM2,DISTRICT
SM295035,33.1667,53.3333,Kletnyanskiy rayon,ADM2,DISTRICT
SM294907,43.0833,49.1667,Kletskiy rayon,ADM2,DISTRICT
SM234841,115.582,5.3945,Klias River Kadazan,LANGUAGE,LANGUAGE
SM262626,29.3333,53.5,Klichaw,ADM2,DISTRICT
SM303081,-120.7893,45.8738,Klickitat,ADM2,DISTRICT
SM254775,-120.89,45.22,Klikitat,ETHNICITY,ETHNICITY
SM254775,-120.89,45.22,Klikitat,ETHNICITY,ETHNICITY
SM254775,-120.89,45.22,Klikitat,ETHNICITY,ETHNICITY
SM254775,-122,46,Klikitat,ETHNICITY,ETHNICITY
SM254775,-122,46,Klikitat,ETHNICITY,ETHNICITY
SM254775,-122,46,Klikitat,ETHNICITY,ETHNICITY
SM254775,-121.5,45.8,Klikitat,ETHNICITY,ETHNICITY
SM254775,-121.5,45.8,Klikitat,ETHNICITY,ETHNICITY
SM254775,-121.5,45.8,Klikitat,ETHNICITY,ETHNICITY
SM262627,32,53.5833,Klimavichy,ADM2,DISTRICT
SM295006,32.1667,52.25,Klimovskiy rayon,ADM2,DISTRICT
SM304746,20.6,42.5833,Klina,ADM2,DISTRICT
SM16954,36.8333,56.4167,Klinskiy Rayon,ADM2,DISTRICT
SM13604,25.5942,56.6245,Klintaines pagasts,ADM2,DISTRICT
SM16955,32.1667,52.75,Klintsovskiy Rayon,ADM2,DISTRICT
SM305615,15.7423,45.6912,Klinča Sela,ADM2,DISTRICT
SM296433,13.2384,56.1114,Klippan,ADM2,DISTRICT
SM10802,33.1823,35.0121,Klirou,ADM2,DISTRICT
SM305463,16.5209,43.5607,Klis,ADM2,DISTRICT
SM234383,124.429,-8.4069,Klon,LANGUAGE,LANGUAGE
SM10803,33.1785,34.829,Klonari,ADM2,DISTRICT
SM296956,99.6765,16.2219,Klong Khlung,ADM2,DISTRICT
SM296957,99.2181,16.2599,Klong Lan,ADM2,DISTRICT
SM262170,1.8422,6.9803,Klouékanmè,ADM2,DISTRICT
SM305616,16.413,45.7375,Kloštar Ivanić,ADM2,DISTRICT
SM305705,17.1567,45.9834,Kloštar Podravski,ADM2,DISTRICT
SM254776,-146.9,67.32,Kluane,ETHNICITY,ETHNICITY
SM288999,18.1526,50.9858,Kluczbork,ADM2,DISTRICT
SM276480,115.4,-8.55,Klungkung,ADM2,DISTRICT
SM262661,26.6667,53,Klyetsak,ADM2,DISTRICT
SM292965,79.5,52.3333,Klyuchevskiy rayon,ADM2,DISTRICT
SM295401,159.7034,-8.2856,Kmaga,ADM2,DISTRICT
SM238291,97.0444,28.4732,Kman,LANGUAGE,LANGUAGE
SM323900,-113.2658,51.6634,Kneehill County,ADM2:ADM3,DISTRICT
SM459047,142.8594,-37.0667,Knenknenwurrung,ETHNICITY,ETHNICITY
SM262338,24.1167,43.5,Knezha,ADM2,DISTRICT
SM305372,18.736,45.7517,Kneževi Vinogradi,ADM2,DISTRICT
SM275986,16.1967,44.0406,Knin,ADM2,DISTRICT
SM296499,17.7814,59.737,Knivsta,ADM2,DISTRICT
SM12464,-77.4927,17.9903,Knockpatrick,ADM2,DISTRICT
SM10804,33.6551,35.2692,Knodara,ADM2,DISTRICT
SM301065,-82.9541,37.3541,Knott,ADM2,DISTRICT
SM274644,-2.8333,53.4167,Knowsley,ADM1:ADM2,DISTRICT
SM302682,-99.7414,33.6061,Knox,ADM2,DISTRICT
SM302585,-83.9371,35.9932,Knox,ADM2,DISTRICT
SM302119,-82.4215,40.3988,Knox,ADM2,DISTRICT
SM301727,-97.8919,42.6368,Knox,ADM2,DISTRICT
SM301570,-92.1481,40.1283,Knox,ADM2,DISTRICT
SM301211,-69.126,44.0758,Knox,ADM2,DISTRICT
SM301066,-83.854,36.8907,Knox,ADM2,DISTRICT
SM300727,-87.418,38.6891,Knox,ADM2,DISTRICT
SM300634,-90.2133,40.9318,Knox,ADM2,DISTRICT
SM261245,145.2692,-37.899,Knox,ADM2,DISTRICT
SM293761,45.1667,55.8333,Knyagininskiy rayon,ADM2,DISTRICT
SM293320,50.9167,62.5833,Knyazhpogostskiy rayon,ADM2,DISTRICT
SM235087,31.5399,10.7321,Ko,LANGUAGE,LANGUAGE
SM254779,101.52,19.82,Ko,ETHNICITY,ETHNICITY
SM254779,101.52,19.82,Ko,ETHNICITY,ETHNICITY
SM254779,98.76,18.96,Ko,ETHNICITY,ETHNICITY
SM254779,98.76,18.96,Ko,ETHNICITY,ETHNICITY
SM254779,100,21,Ko,ETHNICITY,ETHNICITY
SM254779,100,21,Ko,ETHNICITY,ETHNICITY
SM297015,99.3523,18.1441,Ko Kha,ADM2,DISTRICT
SM297004,99.0781,7.7127,Ko Lanta,ADM2,DISTRICT
SM297620,100.0265,9.7494,Ko Phangan,ADM2,DISTRICT
SM297621,99.9964,9.5084,Ko Samui,ADM2,DISTRICT
SM296920,100.8116,13.1529,Ko Sichang,ADM2,DISTRICT
SM297302,98.5785,8.0655,Ko Yao,ADM2,DISTRICT
SM250373,31,12,Koalib,ETHNICITY,ETHNICITY
SM234849,30.4717,11.6965,Koalib-Rere,LANGUAGE,LANGUAGE
SM254780,-61.93,-13.88,Koaratira,ETHNICITY,ETHNICITY
SM234850,-92.709,30.4096,Koasati,LANGUAGE,LANGUAGE
SM254781,-84.85,34.5,Koasati,ETHNICITY,ETHNICITY
SM234852,134.61,-6.479,Koba,LANGUAGE,LANGUAGE
SM296256,13.6443,46.2249,Kobarid,ADM1:ADM2,DISTRICT
SM12985,131.0276,32.0456,Kobayashi Shi,ADM2,DISTRICT
SM278340,135.1335,34.7442,Kobe,ADM2,DISTRICT
SM299664,34.2022,49.15,Kobeliats'kyi,ADM2,DISTRICT
SM284018,-9.4531,15.9175,Kobenni,ADM2,DISTRICT
SM234858,-14.4527,13.5269,Kobiana,LANGUAGE,LANGUAGE
SM296383,16.3933,46.6858,Kobilje,ADM1:ADM2,DISTRICT
SM299312,31,3.5,Koboko,ADM1:ADM2,DISTRICT
SM234861,145.284,-4.7961,Kobol,LANGUAGE,LANGUAGE
SM234862,144.46,-5.1645,Kobon,LANGUAGE,LANGUAGE
SM262583,24.5,52.1667,Kobryn',ADM2,DISTRICT
SM254783,-156.94,66.95,Kobuk,ETHNICITY,ETHNICITY
SM274788,41.8333,41.75,Kobuleti,ADM2,DISTRICT
SM274788,41.8333,41.75,Kobuleti,ADM2,DISTRICT
SM274788,41.8978,41.8122,Kobuleti,ADM2,DISTRICT
SM274788,41.8978,41.8122,Kobuleti,ADM2,DISTRICT
SM294305,127,64,Kobyayskiy rayon,ADM2,DISTRICT
SM298847,30.8582,41.0636,Kocaali,ADM2,DISTRICT
SM1841,29.9167,40.9167,Kocaeli (1995-),ADM1,DISTRICT
SM298320,40.4985,38.288,Kocaköy,ADM2,DISTRICT
SM298621,35.3554,38.8796,Kocasinan,ADM2,DISTRICT
SM4043,25.1906,57.5375,Kocenu Novads,ADM1,DISTRICT
SM13605,25.2788,57.5099,Kocenu pagasts,ADM2,DISTRICT
SM296268,14.9069,45.6303,Kocevje,ADM1:ADM2,DISTRICT
SM234868,90.2566,25.7962,Koch,LANGUAGE,LANGUAGE
SM277357,89.5,26.25,Koch Bihar,ADM2,DISTRICT
SM234870,85.9101,27.0945,Kochariya-East Danuwar,LANGUAGE,LANGUAGE
SM293861,82,55,Kochenevskiy rayon,ADM2,DISTRICT
SM262537,23.0294,42.0991,Kocherinovo,ADM2,DISTRICT
SM294047,54.25,59.75,Kochevskiy rayon,ADM2,DISTRICT
SM10805,33.5162,34.9731,Kochi,ADM2,DISTRICT
SM1843,133.3667,33.5833,Kochi,ADM1:ADMX,DISTRICT
SM234865,84.6301,26.7251,Kochila Tharu,LANGUAGE,LANGUAGE
SM280328,75.75,42.2167,Kochkor,ADM2,DISTRICT
SM293862,80.5,54.3333,Kochkovskiy rayon,ADM2,DISTRICT
SM293637,45.4167,54,Kochkurovskiy rayon,ADM2,DISTRICT
SM234871,87.479,23.234,Koda,LANGUAGE,LANGUAGE
SM276933,75.75,12.4167,Kodagu,ADM2,DISTRICT
SM279443,139.4817,35.7274,Kodaira,ADM2,DISTRICT
SM234872,83.8454,23.5757,Kodaku,LANGUAGE,LANGUAGE
SM12990,139.1714,36.172,Kodama-gun,ADM2,DISTRICT
SM276903,85.5956,24.437,Kodarma,ADM2,DISTRICT
SM234873,75.9161,12.2443,Kodava,LANGUAGE,LANGUAGE
SM254787,76,12,Kodava,ETHNICITY,ETHNICITY
SM234875,120.937,-3.3957,Kodeoha,LANGUAGE,LANGUAGE
SM254788,119.49,-9.63,Kodi,ETHNICITY,ETHNICITY
SM254788,119.49,-9.63,Kodi,ETHNICITY,ETHNICITY
SM254788,119.07,-9.66,Kodi,ETHNICITY,ETHNICITY
SM254788,119.07,-9.66,Kodi,ETHNICITY,ETHNICITY
SM234877,119.114,-9.6373,Kodi-Gaura,LANGUAGE,LANGUAGE
SM234876,-6.3191,5.4967,Kodia,LANGUAGE,LANGUAGE
SM301176,-153.3333,57.3333,Kodiak Island,ADM2,DISTRICT
SM299611,29.1253,48.0958,Kodyms'kyi,ADM2,DISTRICT
SM234883,9.4662,9.0113,Koenoem,LANGUAGE,LANGUAGE
SM370501,5.95,49.67,Koerich,ADM1:ADM2:ADM3,DISTRICT
SM11577,16.5329,46.9216,Koermendi Jaras,ADM2,DISTRICT
SM296060,-55.291,5.7648,Koewarasan,ADM2,DISTRICT
SM234884,12.8757,9.7607,Kofa,LANGUAGE,LANGUAGE
SM234885,136.8136,-2.4144,Kofei,LANGUAGE,LANGUAGE
SM10806,33.4,34.8386,Kofinou,ADM2,DISTRICT
SM277970,35.6766,32.2496,Kofranjah,ADM2,DISTRICT
SM298646,27.1717,41.9547,Kofçaz,ADM2,DISTRICT
SM278381,139.7753,36.1798,Koga,ADM2,DISTRICT
SM279679,130.4995,33.7214,Koga,ADM2,DISTRICT
SM279444,139.511,35.7011,Koganei,ADM2,DISTRICT
SM1844,6.75,7.75,Kogi,ADM1,DISTRICT
SM254795,-73.48,10.7,Kogi,ETHNICITY,ETHNICITY
SM254795,-73.48,10.7,Kogi,ETHNICITY,ETHNICITY
SM254795,-73.48,10.7,Kogi,ETHNICITY,ETHNICITY
SM254795,-74,11.3,Kogi,ETHNICITY,ETHNICITY
SM254795,-74,11.3,Kogi,ETHNICITY,ETHNICITY
SM254795,-74,11.3,Kogi,ETHNICITY,ETHNICITY
SM254795,-74,11,Kogi,ETHNICITY,ETHNICITY
SM254795,-74,11,Kogi,ETHNICITY,ETHNICITY
SM254795,-74,11,Kogi,ETHNICITY,ETHNICITY
SM254796,-114.62,66.94,Kogluktogmiut,ETHNICITY,ETHNICITY
SM18408,64.5572,39.7272,Kogon Shahri,ADM2,DISTRICT
SM18409,64.5676,39.7321,Kogon Tumani,ADM2,DISTRICT
SM375424,71.5439,33.4491,Kohat,ADM2:ADM3,DISTRICT
SM15308,71.5921,33.664,Kohat Tribal Area,ADM2,DISTRICT
SM260062,69.4734,35.1058,Kohband,ADM2,DISTRICT
SM1845,50.6667,30.8333,Kohgiluyeh and Buyer Ahmad,ADM1,DISTRICT
SM260180,69.4722,34.7606,Kohi Safi,ADM2,DISTRICT
SM274126,24.7334,59.1528,Kohila,ADM2,DISTRICT
SM277099,94.1667,25.75,Kohima (2003-),ADM2,DISTRICT
SM234898,111.905,-1.4397,Kohin,LANGUAGE,LANGUAGE
SM9558,70.6563,37.4212,Kohistan,ADM2,DISTRICT
SM260286,64.9229,35.3341,Kohistan,ADM2,DISTRICT
SM260203,65.9365,35.4383,Kohistanat,ADM2,DISTRICT
SM254797,73,35,Kohistani,ETHNICITY,ETHNICITY
SM234896,73.3207,35.1741,Kohistani Shina,LANGUAGE,LANGUAGE
SM234902,108.057,11.6444,Koho,LANGUAGE,LANGUAGE
SM254800,107.82,12.11,Koho,ETHNICITY,ETHNICITY
SM274232,27.2811,59.3954,Kohtla-Järve,ADM2,DISTRICT
SM254801,-115.53,32.05,Kohuana,ETHNICITY,ETHNICITY
SM234905,7.962,5.926,Kohumono,LANGUAGE,LANGUAGE
SM234909,86.8531,27.1941,Koi,LANGUAGE,LANGUAGE
SM254802,147.96,-9.37,Koiari,ETHNICITY,ETHNICITY
SM254802,147.96,-9.37,Koiari,ETHNICITY,ETHNICITY
SM254802,148,-9,Koiari,ETHNICITY,ETHNICITY
SM254802,148,-9,Koiari,ETHNICITY,ETHNICITY
SM461522,90,53,Koibal,ETHNICITY,ETHNICITY
SM10807,34.1339,35.4919,Koilanemos,ADM2,DISTRICT
SM10808,32.8545,34.8421,Koilani,ADM2,DISTRICT
SM10809,32.4487,34.8662,Koili,ADM2,DISTRICT
SM10810,32.6479,34.882,Koilineia,ADM2,DISTRICT
SM346784,29.0474,65.8428,Koillismaa,ADM2:ADM3,DISTRICT
SM4419,-11.346,9.5099,Koinandugu (2017-),ADM2,DISTRICT
SM254803,149.81,-21.71,Koinjmal,ETHNICITY,ETHNICITY
SM254803,149.81,-21.71,Koinjmal,ETHNICITY,ETHNICITY
SM254803,149.7962,-22.2797,Koinjmal,ETHNICITY,ETHNICITY
SM254803,149.7962,-22.2797,Koinjmal,ETHNICITY,ETHNICITY
SM234911,94.2141,25.2857,Koireng,LANGUAGE,LANGUAGE
SM305836,44.6333,36.0831,Koisnjaq,ADM2,DISTRICT
SM254807,147.5,-9.51,Koita,ETHNICITY,ETHNICITY
SM254807,147.5,-9.51,Koita,ETHNICITY,ETHNICITY
SM254807,147,-9,Koita,ETHNICITY,ETHNICITY
SM254807,147,-9,Koita,ETHNICITY,ETHNICITY
SM234913,147.079,-9.3688,Koitabu,LANGUAGE,LANGUAGE
SM234914,143.62,-4.0342,Koiwat,LANGUAGE,LANGUAGE
SM261398,117.0398,-33.9255,Kojonup,ADM2,DISTRICT
SM234918,91.4673,23.1916,Kok Borok,LANGUAGE,LANGUAGE
SM459283,141.7235,-15.3951,Kok Wap,ETHNICITY,ETHNICITY
SM234924,141.608,-16.181,Kok-Nar,LANGUAGE,LANGUAGE
SM234917,135.55,-29.94,Kokata,LANGUAGE,LANGUAGE
SM458120,133.4154,-29.6594,Kokatha,ETHNICITY,ETHNICITY
SM234920,18.4121,10.6829,Koke,LANGUAGE,LANGUAGE
SM234921,140.599,-5.5519,Kokenop Mandobo,LANGUAGE,LANGUAGE
SM254810,30.57,-0.42,Koki,ETHNICITY,ETHNICITY
SM459261,141.5859,-16.4757,Kokiny,ETHNICITY,ETHNICITY
SM10811,32.6033,35.1673,Kokkina,ADM2,DISTRICT
SM10812,33.201,35.1527,Kokkinotrimithia,ADM2,DISTRICT
SM346810,23.5856,63.8541,Kokkola,ADM2:ADM3,DISTRICT
SM4050,25.4633,56.7103,Kokneses Novads,ADM1,DISTRICT
SM13606,25.4239,56.6653,Kokneses pagasts,ADM2,DISTRICT
SM250061,11,4,Koko,ETHNICITY,ETHNICITY
SM459277,141.4834,-15.8158,Koko Babangk,ETHNICITY,ETHNICITY
SM234932,141.4802,-15.826,Koko Babangk,LANGUAGE,LANGUAGE
SM459439,141.6206,-15.6349,Koko Bera,ETHNICITY,ETHNICITY
SM234931,141.8062,-16.0509,Koko Dhawa,LANGUAGE,LANGUAGE
SM459447,141.8062,-16.0509,Koko Dhawa,ETHNICITY,ETHNICITY
SM459176,145.4506,-16.2473,Koko Kai-kai,ETHNICITY,ETHNICITY
SM284936,4.4245,11.4876,Koko/Bes,ADM2,DISTRICT
SM284936,4.4245,11.4876,Koko/Bes,ADM2,DISTRICT
SM284936,4.4378,11.4586,Koko/Bes,ADM2,DISTRICT
SM284936,4.4378,11.4586,Koko/Bes,ADM2,DISTRICT
SM234925,132.449,-2.1287,Kokoda,LANGUAGE,LANGUAGE
SM234926,35.5767,-16.2681,Kokola,LANGUAGE,LANGUAGE
SM285019,8.0611,8.8556,Kokona,ADM2,DISTRICT
SM288623,152.2833,-4.4,Kokopo,ADM2,DISTRICT
SM250181,18.49,17.64,Kokorda,ETHNICITY,ETHNICITY
SM234928,159.191,-8.1648,Kokota,LANGUAGE,LANGUAGE
SM295402,159.1898,-7.9887,Kokota,ADM2,DISTRICT
SM370262,-9.5671,6.5362,Kokoyah Clan,ADM2:ADM3,DISTRICT
SM13199,82.75,48.5,Kokpekti Audany (excl. Samarskoe),ADM2,DISTRICT
SM277386,90.2,26.6,Kokrajhar (2004-),ADM2,DISTRICT
SM289141,126.6967,38.7492,Koksan,ADM2,DISTRICT
SM13200,69.3333,53.3333,Kokshetau Audany,ADM2,DISTRICT
SM254812,-68.81,58.39,Koksoakmiut,ETHNICITY,ETHNICITY
SM279831,77.75,44.8333,Koksuskiy,ADM2,DISTRICT
SM279445,139.4613,35.7055,Kokubunji,ADM2,DISTRICT
SM234941,88.337,24.5318,Kol (Bangladesh),LANGUAGE,LANGUAGE
SM234942,12.8574,3.6599,Kol (Cameroon),LANGUAGE,LANGUAGE
SM234961,151.583,-5.2104,Kol (Papua New Guinea),LANGUAGE,LANGUAGE
SM254815,80.44,25.12,Kol Upmp,ETHNICITY,ETHNICITY
SM254815,80.44,25.12,Kol Upmp,ETHNICITY,ETHNICITY
SM254815,80.44,25.12,Kol Upmp,ETHNICITY,ETHNICITY
SM254815,85,24,Kol Upmp,ETHNICITY,ETHNICITY
SM254815,85,24,Kol Upmp,ETHNICITY,ETHNICITY
SM254815,85,24,Kol Upmp,ETHNICITY,ETHNICITY
SM254815,85,22,Kol Upmp,ETHNICITY,ETHNICITY
SM254815,85,22,Kol Upmp,ETHNICITY,ETHNICITY
SM254815,85,22,Kol Upmp,ETHNICITY,ETHNICITY
SM16956,39.4167,56.25,Kol'chuginskiy Rayon,ADM2,DISTRICT
SM293743,32,68.5,Kol'skiy rayon,ADM2,DISTRICT
SM234935,134.592,-5.4883,Kola,LANGUAGE,LANGUAGE
SM295380,159.9698,-9.446,Kola'a,ADM2,DISTRICT
SM280985,-10.2167,8.0667,Kolahun (pre-2015),ADM2,DISTRICT
SM280985,-10.2167,8.0667,Kolahun (pre-2015),ADM2,DISTRICT
SM280985,-10.1611,8.1036,Kolahun (pre-2015),ADM2,DISTRICT
SM280985,-10.1611,8.1036,Kolahun (pre-2015),ADM2,DISTRICT
SM276613,121.1243,-3.1045,Kolaka Utara,ADM2,DISTRICT
SM254816,78.34,18.94,Kolam,ETHNICITY,ETHNICITY
SM10527,14.959,44.4963,Kolan,ADM2,DISTRICT
SM276935,78.23,13.13,Kolar (2007-),ADM2,DISTRICT
SM277089,92.677,24.2222,Kolasib,ADM2,DISTRICT
SM1847,19.5156,42.8372,Kolašin,ADM1,DISTRICT
SM234940,13.2707,8.268,Kolbila,LANGUAGE,LANGUAGE
SM289026,21.8132,50.3163,Kolbuszowa,ADM2,DISTRICT
SM295282,-14.95,12.883,Kolda,ADM2,DISTRICT
SM1848,-14.4167,13,Kolda (2008-),ADM1,DISTRICT
SM271738,9.4581,55.4501,Kolding (2007-),ADM2,DISTRICT
SM299283,32.744,2.297,Kole,ADM1:ADM2,DISTRICT
SM254818,7.72,4.86,Kole,ETHNICITY,ETHNICITY
SM346079,38.6899,9.011,Kolfe - Keran,ADM2:ADM3,DISTRICT
SM277038,74.2298,16.6901,Kolhapur (1989-),ADM2,DISTRICT
SM254822,77.02,16.22,Koli,ETHNICITY,ETHNICITY
SM254822,71.38,23.2,Koli,ETHNICITY,ETHNICITY
SM254822,74.92,18.87,Koli,ETHNICITY,ETHNICITY
SM234949,122.567,7.7721,Kolibugan Subanon,LANGUAGE,LANGUAGE
SM13607,22.445,57.6961,Kolkas pagasts,ADM2,DISTRICT
SM277358,88.3737,22.5473,Kolkata,ADM2,DISTRICT
SM234950,88.3942,22.606,Kolkata Indian Sign Language,DIALECT,LANGUAGE
SM305972,68.8153,37.5728,Kolkhozobod,ADM2,DISTRICT
SM276963,76.8527,8.9569,Kollam,ADM2,DISTRICT
SM284622,2.452,13.576,Kollo,ADM2,DISTRICT
SM288693,21.9363,53.3919,Kolno,ADM2,DISTRICT
SM17989,-175.1832,-21.1448,Kolofo'ou,ADM2,DISTRICT
SM293356,44.25,58.9167,Kologrivskiy rayon,ADM2,DISTRICT
SM283361,-8.167,13.75,Kolokani,ADM2,DISTRICT
SM295373,160.4058,-9.6412,Kolokarako,ADM2,DISTRICT
SM285332,6.264,5.0986,Kolokuma/Opokuma,ADM2,DISTRICT
SM234959,146.212,-5.5783,Kolom,LANGUAGE,LANGUAGE
SM295338,157.5754,-8.1293,Kolombaghea,ADM2,DISTRICT
SM16957,38.75,55.0833,Kolomenskiy Rayon,ADM2,DISTRICT
SM295403,159.5822,-8.3042,Kolomola,ADM2,DISTRICT
SM17990,-175.2411,-21.1433,Kolomotu'a,ADM2,DISTRICT
SM299989,25.0323,48.5227,Kolomyis'kyi,ADM2,DISTRICT
SM283381,-6.69,10.942,Kolondiéba,ADM2,DISTRICT
SM14937,158.2058,6.964,Kolonia Municipality,ADM2,DISTRICT
SM260494,20.6667,40.25,Kolonjës,ADM2,DISTRICT
SM260494,20.6667,40.25,Kolonjës,ADM2,DISTRICT
SM260494,20.6148,40.3142,Kolonjës,ADM2,DISTRICT
SM260494,20.6148,40.3142,Kolonjës,ADM2,DISTRICT
SM248113,120.703,-8.7865,Kolor,LANGUAGE,LANGUAGE
SM293892,73.5833,54.5833,Kolosovskiy rayon,ADM2,DISTRICT
SM10813,32.9331,34.6681,Kolossi,ADM2,DISTRICT
SM295389,159.5326,-8.3375,Kolotubi,ADM2,DISTRICT
SM17991,-175.343,-21.1116,Kolovai,ADM2,DISTRICT
SM16958,30.5863,59.7804,Kolpinskiy Rayon,ADM2,DISTRICT
SM1849,19.8875,44.2633,Kolubarski,ADM1:ADM2,DISTRICT
SM234967,-3.8795,14.3617,Kolum So Dogon,LANGUAGE,LANGUAGE
SM234965,150.462,-9.4274,Koluwawa,LANGUAGE,LANGUAGE
SM269617,25.4993,-10.7099,Kolwezi City,ADM2,DISTRICT
SM293863,82.6667,55.8333,Kolyvanovskiy rayon,ADM2,DISTRICT
SM271189,15,50,Kolín,ADM2,DISTRICT
SM250374,10.8,5.07,Kom,ETHNICITY,ETHNICITY
SM250374,10.8,5.07,Kom,ETHNICITY,ETHNICITY
SM250374,10,6,Kom,ETHNICITY,ETHNICITY
SM250374,10,6,Kom,ETHNICITY,ETHNICITY
SM234985,10.3348,6.2596,Kom (Cameroon),LANGUAGE,LANGUAGE
SM234990,94.534,24.8688,Kom (India),LANGUAGE,LANGUAGE
SM254841,21.4,-14.7,Koma,ETHNICITY,ETHNICITY
SM450646,12.7,8.5,Koma,ETHNICITY,ETHNICITY
SM234972,12.7282,8.7098,Koma Ndera,LANGUAGE,LANGUAGE
SM10814,34.124,35.4251,Koma tou Gialou,ADM2,DISTRICT
SM279446,139.5772,35.6349,Komae,ADM2,DISTRICT
SM278807,137.9478,35.7277,Komagane,ADM2,DISTRICT
SM278039,136.912,35.291,Komaki,ADM2,DISTRICT
SM262218,0.6667,12.6667,Komandjoari,ADM2,DISTRICT
SM295009,34.75,52.4167,Komarichskiy rayon,ADM2,DISTRICT
SM278430,136.4984,36.3111,Komatsu,ADM2,DISTRICT
SM279406,134.5953,33.9807,Komatsushima,ADM2,DISTRICT
SM234981,147.28,-6.1616,Komba,LANGUAGE,LANGUAGE
SM234982,139.902,-5.5454,Kombai,LANGUAGE,LANGUAGE
SM254845,10.36,2.34,Kombe,ETHNICITY,ETHNICITY
SM254844,149.53,-4.67,Kombe,ETHNICITY,ETHNICITY
SM234980,142.694,-3.4847,Kombio,LANGUAGE,LANGUAGE
SM275072,-16.6667,13.25,Kombo Central,ADM2,DISTRICT
SM275073,-16.5167,13.2167,Kombo East,ADM2,DISTRICT
SM275074,-16.6458,13.4439,Kombo Saint Mary,ADM2,DISTRICT
SM275074,-16.6458,13.4439,Kombo Saint Mary,ADM2,DISTRICT
SM275074,-16.6667,13.3667,Kombo Saint Mary,ADM2,DISTRICT
SM275074,-16.6667,13.3667,Kombo Saint Mary,ADM2,DISTRICT
SM275075,-16.75,13.25,Kombo South,ADM2,DISTRICT
SM254846,153.72,-26.64,Kombobura,ETHNICITY,ETHNICITY
SM13608,27.2165,55.9828,Kombulu pagasts,ADM2,DISTRICT
SM296296,13.7489,45.8186,Komen,ADM1:ADM2,DISTRICT
SM296303,14.5446,46.2024,Komenda,ADM1:ADM2,DISTRICT
SM274889,-1.35,5.0833,Komenda-Edina-Eguafo-Abirem,ADM2,DISTRICT
SM234986,105.107,-3.7922,Komering,LANGUAGE,LANGUAGE
SM10815,33.9883,35.4125,Komi,ADM2,DISTRICT
SM1851,54,64,Komi,ADM1,DISTRICT
SM254847,42.93,61.07,Komi,ETHNICITY,ETHNICITY
SM234989,54.7959,59.6602,Komi-Permyak,LANGUAGE,LANGUAGE
SM234994,55.756,60.717,Komi-Yazva,LANGUAGE,LANGUAGE
SM234988,54.946,64.0527,Komi-Zyrian,LANGUAGE,LANGUAGE
SM234991,144.783,-4.6854,Kominimung,LANGUAGE,LANGUAGE
SM299612,30.9441,46.8181,Kominternivs'kyi,ADM2,DISTRICT
SM275996,16.0915,43.0449,Komiža,ADM2,DISTRICT
SM11578,18.2862,46.2202,Komloi Jaras,ADM2,DISTRICT
SM11532,-15.3412,11.2006,Komo,ADM2,DISTRICT
SM274547,10.351,0.246,Komo,ADM2,DISTRICT
SM250375,32.28,10.12,Komo,ETHNICITY,ETHNICITY
SM250375,32.28,10.12,Komo,ETHNICITY,ETHNICITY
SM250375,35,9,Komo,ETHNICITY,ETHNICITY
SM250375,35,9,Komo,ETHNICITY,ETHNICITY
SM235000,26.7144,-0.7633,Komo (Democratic Republic of Congo),LANGUAGE,LANGUAGE
SM234998,34.0939,8.9416,Komo (Sudan-Ethiopia),LANGUAGE,LANGUAGE
SM288647,143,-6.1167,Komo-Magarima,ADM2,DISTRICT
SM4314,9.634,0.124,Komo-Mondah (1996-),ADM2,DISTRICT
SM235001,119.477,-8.6452,Komodo,LANGUAGE,LANGUAGE
SM262200,-4.4167,10.3333,Komoe (1995-),ADM1:ADM2,DISTRICT
SM254852,139.04,-8.27,Komolom,ETHNICITY,ETHNICITY
SM269826,13.2664,-2.9372,Komono,ADM2,DISTRICT
SM278808,138.435,36.3391,Komoro,ADM2,DISTRICT
SM235006,134.742,-5.6435,Kompane,LANGUAGE,LANGUAGE
SM299526,32.2101,48.2502,Kompani‹vs'kyi,ADM2,DISTRICT
SM288641,144,-5.25,Kompiam-Ambum,ADM2,DISTRICT
SM262219,0.9167,11.4167,Kompienga,ADM2,DISTRICT
SM292792,47.5,55.25,Komsomol'skiy rayon,ADM2,DISTRICT
SM292955,40.4167,57.0833,Komsomol’skiy rayon,ADM2,DISTRICT
SM13350,20.2882,42.5391,Komuna e Decanit,ADM2,DISTRICT
SM13351,21.1517,42.5739,Komuna e Gracanices,ADM2,DISTRICT
SM13352,20.2126,42.5053,Komuna e Junikut,ADM2,DISTRICT
SM13353,21.25,42.2,Komuna e Kacanikut,ADM2,DISTRICT
SM13354,21.3937,42.3446,Komuna e Kllokotit,ADM2,DISTRICT
SM13355,20.7274,42.3261,Komuna e Mamushes,ADM2,DISTRICT
SM13356,20.8833,42.9167,Komuna e Mitrovices (2013-),ADM2,DISTRICT
SM13357,21.4774,42.4085,Komuna e Parteshit,ADM2,DISTRICT
SM13358,21.2833,42.7,Komuna e Prishtines,ADM2,DISTRICT
SM13359,21.6023,42.5074,Komuna e Ranillugut,ADM2,DISTRICT
SM13360,21.3667,42.3333,Komuna e Vitise,ADM2,DISTRICT
SM235009,140.213,-5.2545,Komyandaret,LANGUAGE,LANGUAGE
SM296145,18.1333,47.7667,Komárno,ADM2,DISTRICT
SM276139,18.0641,47.6855,Komárom,ADM2,DISTRICT
SM1850,18.3333,47.5833,Komárom-Esztergom,ADM1,DISTRICT
SM235054,100.278,21.6369,Kon Keu,LANGUAGE,LANGUAGE
SM304240,108.3206,14.7562,Kon Plông,ADM2,DISTRICT
SM304241,108.1713,14.5326,Kon Rẫy,ADM2,DISTRICT
SM304242,107.9789,14.3436,Kon Tum,ADM2,DISTRICT
SM1852,107.9167,14.75,Kon Tum,ADM1,DISTRICT
SM235010,142.069,-5.6601,Konai,LANGUAGE,LANGUAGE
SM254854,141.75,-6.05,Konai,ETHNICITY,ETHNICITY
SM18066,27.1441,38.4145,Konak (2008)-,ADM2,DISTRICT
SM16959,36.8333,56.6667,Konakovskiy Rayon,ADM2,DISTRICT
SM279249,136.0833,35.0167,Konan,ADM2,DISTRICT
SM305647,18.3394,42.5401,Konavle,ADM2,DISTRICT
SM276615,122.4485,-4.1919,Konawe Selatan,ADM2,DISTRICT
SM276616,121.9908,-3.4155,Konawe Utara,ADM2,DISTRICT
SM254857,131.73,-1.31,Konda,ETHNICITY,ETHNICITY
SM254860,82.15,17.03,Konda Kapu,ETHNICITY,ETHNICITY
SM254862,81.89,18.31,Konda Reddy,ETHNICITY,ETHNICITY
SM235015,82.934,18.2675,Konda-Dora,LANGUAGE,LANGUAGE
SM277448,81.6675,19.5952,Kondagaon (2012-),ADM2,DISTRICT
SM298000,10.2416,35.9108,Kondar,ADM2,DISTRICT
SM254865,32.08,-27.04,Konde,ETHNICITY,ETHNICITY
SM254865,32.08,-27.04,Konde,ETHNICITY,ETHNICITY
SM254865,40,-11,Konde,ETHNICITY,ETHNICITY
SM254865,40,-11,Konde,ETHNICITY,ETHNICITY
SM254866,82,18,Kondha,ETHNICITY,ETHNICITY
SM261399,119.0242,-32.4843,Kondinin,ADM2,DISTRICT
SM299211,35.8623,-4.7093,Kondoa,ADM2,DISTRICT
SM16960,34.3333,62.1667,Kondopozhskiy Rayon,ADM2,DISTRICT
SM285368,13.0028,11.5861,Konduga,ADM2,DISTRICT
SM235026,138.0675,-8.3742,Koneraw,LANGUAGE,LANGUAGE
SM297601,99.9741,16.9312,Kong Krailat,ADM2,DISTRICT
SM280487,104.6599,11.3165,Kong Pisei,ADM2,DISTRICT
SM297323,99.9572,7.4167,Kong Ra,ADM2,DISTRICT
SM299287,34.6678,1.2712,Kongasis,ADM1:ADM2,DISTRICT
SM280965,-10.5112,7.6733,Kongba,ADM2,DISTRICT
SM1854,14.3333,-5.3333,Kongo-Central,ADM1:ADM2,DISTRICT
SM269711,27,-5.3834,Kongolo (ville),ADM2,DISTRICT
SM286316,9.6465,59.6652,Kongsberg,ADM2,DISTRICT
SM286356,11.9992,60.1912,Kongsvinger,ADM2,DISTRICT
SM299212,36.417,-6.2,Kongwa,ADM2,DISTRICT
SM10816,32.4634,34.7808,Konia,ADM2,DISTRICT
SM254879,-153.16,57.93,Koniag,ETHNICITY,ETHNICITY
SM305985,70.3306,40.2688,Konibodom,ADM2,DISTRICT
SM303548,64.98,40.21,Konimex,ADM2,DISTRICT
SM288790,18.2962,52.2597,Konin,ADM2,DISTRICT
SM288789,18.2622,52.2562,Konin (City),ADM2,DISTRICT
SM254880,-62.86,57.87,Konith-Lushuamiut,ETHNICITY,ETHNICITY
SM305727,16.1776,46.0544,Konjšćina,ADM2,DISTRICT
SM254882,73.86,16.21,Konkani,ETHNICITY,ETHNICITY
SM254882,73.86,16.21,Konkani,ETHNICITY,ETHNICITY
SM254882,75.1,14.6,Konkani,ETHNICITY,ETHNICITY
SM254882,75.1,14.6,Konkani,ETHNICITY,ETHNICITY
SM235055,0.2783,9.8222,Konkomba,LANGUAGE,LANGUAGE
SM250377,0.18,9.51,Konkomba,ETHNICITY,ETHNICITY
SM250377,0.18,9.51,Konkomba,ETHNICITY,ETHNICITY
SM250377,0,10,Konkomba,ETHNICITY,ETHNICITY
SM250377,0,10,Konkomba,ETHNICITY,ETHNICITY
SM254885,151.1,-22.93,Konkubura,ETHNICITY,ETHNICITY
SM235058,-1.5438,10.2368,Konni,LANGUAGE,LANGUAGE
SM295486,-10.9337,8.7069,Kono,ADM1:ADM2,DISTRICT
SM250379,-6.51,9.37,Kono,ETHNICITY,ETHNICITY
SM235064,-8.2579,7.85,Kono (Guinea),LANGUAGE,LANGUAGE
SM235061,8.3219,10.436,Kono (Nigeria),LANGUAGE,LANGUAGE
SM235065,-10.9214,8.6704,Kono (Sierra Leone),LANGUAGE,LANGUAGE
SM14063,-7.8872,5.8518,Konobo (geonames),ADM2,DISTRICT
SM230150,-7.8545,5.8355,Konobo-Eastern Krahn,LANGUAGE,LANGUAGE
SM250378,31.42,-6.67,Konogo,ETHNICITY,ETHNICITY
SM235066,152.87,-4.1618,Konomala,LANGUAGE,LANGUAGE
SM235060,-123.3108,41.2634,Konomihu,LANGUAGE,LANGUAGE
SM235062,32.1803,-6.515,Konongo,LANGUAGE,LANGUAGE
SM293605,40.3333,61,Konoshskiy rayon,ADM2,DISTRICT
SM299730,33.2288,51.2084,Konotops'ka,ADM2,DISTRICT
SM285339,8.6604,7.0299,Konshish,ADM2,DISTRICT
SM235068,37.53,5.3911,Konso,LANGUAGE,LANGUAGE
SM274314,37.3333,5.3333,Konso,ADM2,DISTRICT
SM250380,36.72,5.32,Konso,ETHNICITY,ETHNICITY
SM250380,36.72,5.32,Konso,ETHNICITY,ETHNICITY
SM250380,36.72,5.32,Konso,ETHNICITY,ETHNICITY
SM250380,36.72,5.32,Konso,ETHNICITY,ETHNICITY
SM250380,37.5,5.25,Konso,ETHNICITY,ETHNICITY
SM250380,37.5,5.25,Konso,ETHNICITY,ETHNICITY
SM250380,37.5,5.25,Konso,ETHNICITY,ETHNICITY
SM250380,37.5,5.25,Konso,ETHNICITY,ETHNICITY
SM250380,37,6,Konso,ETHNICITY,ETHNICITY
SM250380,37,6,Konso,ETHNICITY,ETHNICITY
SM250380,37,6,Konso,ETHNICITY,ETHNICITY
SM250380,37,6,Konso,ETHNICITY,ETHNICITY
SM250380,37.5,5.5,Konso,ETHNICITY,ETHNICITY
SM250380,37.5,5.5,Konso,ETHNICITY,ETHNICITY
SM250380,37.5,5.5,Konso,ETHNICITY,ETHNICITY
SM250380,37.5,5.5,Konso,ETHNICITY,ETHNICITY
SM13609,27.429,56.069,Konstantinovas pagasts,ADM2,DISTRICT
SM293237,128.1667,49.75,Konstantinovskiy rayon,ADM2,DISTRICT
SM274315,36.5726,6.8707,Konta,ADM2,DISTRICT
SM10817,33.7256,35.1027,Kontea,ADM2,DISTRICT
SM10818,33.1335,35.2659,Kontemenos,ADM2,DISTRICT
SM285024,5.4795,10.4523,Kontogur,ADM2,DISTRICT
SM13610,25.3683,57.9422,Konu pagasts,ADM2,DISTRICT
SM1855,32.5,38.1667,Konya (1989-),ADM1,DISTRICT
SM18067,30.6271,36.8642,Konyaalti,ADM2,DISTRICT
SM254888,91.11,25.4,Konyak,ETHNICITY,ETHNICITY
SM235075,95.0598,26.6823,Konyak Naga,LANGUAGE,LANGUAGE
SM235077,-8.8997,8.0479,Konyanka Maninka,LANGUAGE,LANGUAGE
SM293496,35.25,51.9167,Konyshevskiy rayon,ADM2,DISTRICT
SM235079,29.8696,0.324,Konzo,LANGUAGE,LANGUAGE
SM284578,164.8663,-21.057,Koné,ADM2,DISTRICT
SM305325,17.1646,45.6381,Končanica,ADM2,DISTRICT
SM1853,22.4,41.5333,Konče,ADM1,DISTRICT
SM254911,149.78,-6.16,Koobe,ETHNICITY,ETHNICITY
SM301367,-93.7834,48.2453,Koochiching,ADM2,DISTRICT
SM235083,13.5415,3.2593,Koonzime,LANGUAGE,LANGUAGE
SM261400,117.4074,-30.6041,Koorda,ADM2,DISTRICT
SM235091,37.8679,5.8055,Koorete,LANGUAGE,LANGUAGE
SM254912,37.84,5.59,Koorete,ETHNICITY,ETHNICITY
SM254912,37.84,5.59,Koorete,ETHNICITY,ETHNICITY
SM254912,38,6,Koorete,ETHNICITY,ETHNICITY
SM254912,38,6,Koorete,ETHNICITY,ETHNICITY
SM459090,142.329,-38.1699,Koornkopanoot,ETHNICITY,ETHNICITY
SM300564,-116.7,47.6746,Kootenai,ADM2,DISTRICT
SM268750,-118.7523,49.3331,Kootenay Boundary,ADM2,DISTRICT
SM289047,125.9178,40.5644,Kop'ung,ADM2,DISTRICT
SM235094,144.466,-3.9826,Kopar,LANGUAGE,LANGUAGE
SM254914,143.81,-3.94,Kopar,ETHNICITY,ETHNICITY
SM4143,13.8267,45.5183,Koper (2011-),ADM1,DISTRICT
SM292750,61.5833,55.0833,Kopeysk gorsovet,ADM2,DISTRICT
SM235096,139.926,-4.9119,Kopkaka,LANGUAGE,LANGUAGE
SM282978,-89.9077,20.6426,Kopomá,ADM2,DISTRICT
SM276936,76.2,15.5,Koppal,ADM2,DISTRICT
SM276057,16.8333,46.1667,Koprivnica,ADM2,DISTRICT
SM305706,16.9013,46.1332,Koprivnički Bregi,ADM2,DISTRICT
SM305707,16.8163,46.1991,Koprivnički Ivanec,ADM2,DISTRICT
SM1856,16.8333,46.1667,Koprivničko-Križevačka,ADM1,DISTRICT
SM262420,24.3833,42.6167,Koprivshtitsa,ADM2,DISTRICT
SM370502,6.0731,49.6644,Kopstal,ADM1:ADM2:ADM3,DISTRICT
SM254916,141.68,-5.71,Kora,ETHNICITY,ETHNICITY
SM235101,149.267,-9.07,Korafe-Yegha,LANGUAGE,LANGUAGE
SM274380,44.3413,6.6642,Korahe,ADM2,DISTRICT
SM235103,145.514,-4.5998,Korak,LANGUAGE,LANGUAGE
SM10819,32.872,35.04,Korakou,ADM2,DISTRICT
SM235100,19.7711,-30.4207,Korana,LANGUAGE,LANGUAGE
SM250383,24.19,-29.85,Korana,ETHNICITY,ETHNICITY
SM235099,-2.4811,29.4957,Korandje,LANGUAGE,LANGUAGE
SM5114,67.1673,24.8555,Korangi,ADM2:ADM3,DISTRICT
SM250396,-10.98,9.76,Koranko,ETHNICITY,ETHNICITY
SM250396,-10.98,9.76,Koranko,ETHNICITY,ETHNICITY
SM250396,-11.06,10.15,Koranko,ETHNICITY,ETHNICITY
SM250396,-11.06,10.15,Koranko,ETHNICITY,ETHNICITY
SM250396,-11,9,Koranko,ETHNICITY,ETHNICITY
SM250396,-11,9,Koranko,ETHNICITY,ETHNICITY
SM235105,139.6802,-4.5357,Korapun-Bromley,LANGUAGE,LANGUAGE
SM277119,83,19,Koraput (1993-),ADM2,DISTRICT
SM297924,10.8043,36.6256,Korba,ADM2,DISTRICT
SM277449,82.6,22.5,Korba,ADM2,DISTRICT
SM10304,18.0089,9.9787,Korbol,ADM2,DISTRICT
SM13201,74.718,43.0388,Korday City,ADM2,DISTRICT
SM1858,47,35.6667,Kordestan,ADM1,DISTRICT
SM235113,128,37.5,Korean,LANGUAGE,LANGUAGE
SM254923,128.01,37.82,Korean,ETHNICITY,ETHNICITY
SM254923,128.01,37.82,Korean,ETHNICITY,ETHNICITY
SM254923,128.01,37.82,Korean,ETHNICITY,ETHNICITY
SM254923,128.01,37.82,Korean,ETHNICITY,ETHNICITY
SM254923,128.01,37.82,Korean,ETHNICITY,ETHNICITY
SM254923,127,37,Korean,ETHNICITY,ETHNICITY
SM254923,127,37,Korean,ETHNICITY,ETHNICITY
SM254923,127,37,Korean,ETHNICITY,ETHNICITY
SM254923,127,37,Korean,ETHNICITY,ETHNICITY
SM254923,127,37,Korean,ETHNICITY,ETHNICITY
SM254923,126.96,38.13,Korean,ETHNICITY,ETHNICITY
SM254923,126.96,38.13,Korean,ETHNICITY,ETHNICITY
SM254923,126.96,38.13,Korean,ETHNICITY,ETHNICITY
SM254923,126.96,38.13,Korean,ETHNICITY,ETHNICITY
SM254923,126.96,38.13,Korean,ETHNICITY,ETHNICITY
SM254923,126.42,37.6,Korean,ETHNICITY,ETHNICITY
SM254923,126.42,37.6,Korean,ETHNICITY,ETHNICITY
SM254923,126.42,37.6,Korean,ETHNICITY,ETHNICITY
SM254923,126.42,37.6,Korean,ETHNICITY,ETHNICITY
SM254923,126.42,37.6,Korean,ETHNICITY,ETHNICITY
SM254923,127,37.6,Korean,ETHNICITY,ETHNICITY
SM254923,127,37.6,Korean,ETHNICITY,ETHNICITY
SM254923,127,37.6,Korean,ETHNICITY,ETHNICITY
SM254923,127,37.6,Korean,ETHNICITY,ETHNICITY
SM254923,127,37.6,Korean,ETHNICITY,ETHNICITY
SM454401,-118,34,Korean Americans,ETHNICITY,ETHNICITY
SM235111,127.609,37.4718,Korean Sign Language,LANGUAGE,LANGUAGE
SM235116,-75.3475,0.9232,Koreguaje,LANGUAGE,LANGUAGE
SM250384,30.1,-19.05,Korekore,ETHNICITY,ETHNICITY
SM293497,35,51.4167,Korenevskiy rayon,ADM2,DISTRICT
SM293368,39.3482,45.5072,Korenovskiy rayon,ADM2,DISTRICT
SM10820,32.9593,34.7923,Korfi,ADM2,DISTRICT
SM279911,70,50.5833,Korgalzhynskiy,ADM2,DISTRICT
SM298802,37.3532,40.835,Korgan,ADM2,DISTRICT
SM298283,33.5241,40.7456,Korgun,ADM2,DISTRICT
SM254926,144.92,-7.41,Koriki,ETHNICITY,ETHNICITY
SM254926,144.92,-7.41,Koriki,ETHNICITY,ETHNICITY
SM254926,145,-7,Koriki,ETHNICITY,ETHNICITY
SM254926,145,-7,Koriki,ETHNICITY,ETHNICITY
SM277450,82.1504,23.4833,Koriya,ADM2,DISTRICT
SM292751,61.4,54.9,Korkino gorsovet,ADM2,DISTRICT
SM235126,77.5541,21.8358,Korku,LANGUAGE,LANGUAGE
SM298781,41.7869,38.7383,Korkut,ADM2,DISTRICT
SM299012,30.2029,37.0758,Korkuteli,ADM2,DISTRICT
SM235127,73.0099,19.0348,Korlai Portuguese,LANGUAGE,LANGUAGE
SM10821,32.9785,35.3424,Kormakitis,ADM2,DISTRICT
SM293893,74.0833,55,Kormilovskiy rayon,ADM2,DISTRICT
SM261826,16.2412,48.4434,Korneuburg,ADM2,DISTRICT
SM10822,33.5724,35.2741,Kornokipos,ADM2,DISTRICT
SM10823,33.3872,34.9052,Kornos,ADM2,DISTRICT
SM235145,92.633,27.2148,Koro,LANGUAGE,LANGUAGE
SM283368,-2.853,14.384,Koro,ADM2,DISTRICT
SM235135,-5.7357,8.1197,Koro (Côte d'Ivoire),LANGUAGE,LANGUAGE
SM250088,6.67,13.02,Koro (Nigeria),ETHNICITY,ETHNICITY
SM250088,6.67,13.02,Koro (Nigeria),ETHNICITY,ETHNICITY
SM250088,6.67,13.02,Koro (Nigeria),ETHNICITY,ETHNICITY
SM250088,8,8,Koro (Nigeria),ETHNICITY,ETHNICITY
SM250088,8,8,Koro (Nigeria),ETHNICITY,ETHNICITY
SM250088,8,8,Koro (Nigeria),ETHNICITY,ETHNICITY
SM250088,8,18,Koro (Nigeria),ETHNICITY,ETHNICITY
SM250088,8,18,Koro (Nigeria),ETHNICITY,ETHNICITY
SM250088,8,18,Koro (Nigeria),ETHNICITY,ETHNICITY
SM235137,167.467,-14.2943,Koro-Olrat,LANGUAGE,LANGUAGE
SM288648,142.5,-5.4167,Koroba-Kopiago,ADM2,DISTRICT
SM294676,37.25,50.8333,Korochanskiy rayon,ADM2,DISTRICT
SM299198,38.4192,-5.0827,Korogwe,ADM2,DISTRICT
SM299197,38.4772,-5.1527,Korogwe Township Authority,ADM2,DISTRICT
SM16961,37.8179,55.9173,Korolyov (excl. Mytishchi),ADM2,DISTRICT
SM254929,0.45,12.71,Koromba,ETHNICITY,ETHNICITY
SM235129,-1.9619,14.046,Koromfé,LANGUAGE,LANGUAGE
SM235142,155.742,-6.394,Koromira,LANGUAGE,LANGUAGE
SM235139,125.007,6.2053,Koronadal Blaan,LANGUAGE,LANGUAGE
SM454537,30,10,Korongo,ETHNICITY,ETHNICITY
SM235140,121.977,-2.6367,Koroni,LANGUAGE,LANGUAGE
SM235133,8.8086,5.1621,Korop,LANGUAGE,LANGUAGE
SM229106,-42.716,-21.3907,Koropó,LANGUAGE,LANGUAGE
SM1859,134.4785,7.339,Koror,ADM1,DISTRICT
SM235128,53.008,29.441,Koroshi,LANGUAGE,LANGUAGE
SM299842,28.6311,50.9584,Korostens'ka,ADM2,DISTRICT
SM299843,28.5,50.9167,Korostens'kyi,ADM2,DISTRICT
SM299844,29.0833,50.3333,Korostyshivs'kyi,ADM2,DISTRICT
SM10824,34.2745,35.5056,Koroveia,ADM2,DISTRICT
SM235141,140.047,-5.2695,Korowai,LANGUAGE,LANGUAGE
SM235152,75.2,12.9163,Korra Koraga,LANGUAGE,LANGUAGE
SM16962,143,46.8333,Korsakovskiy Rayon,ADM2,DISTRICT
SM299425,31.2517,49.4193,Korsun'-Shevchenkivs'kyi,ADM2,DISTRICT
SM293321,51.5333,61.8167,Kortkerosskiy rayon,ADM2,DISTRICT
SM235156,-70.5094,-5.3655,Korubo,LANGUAGE,LANGUAGE
SM235154,139.678,-4.535,Korupun-Sela,LANGUAGE,LANGUAGE
SM235157,83.8142,23.4102,Korwa,LANGUAGE,LANGUAGE
SM254930,82.76,25.06,Korwa,ETHNICITY,ETHNICITY
SM254930,84.58,24.57,Korwa,ETHNICITY,ETHNICITY
SM235158,166.695,63.8977,Koryak,LANGUAGE,LANGUAGE
SM254931,176.36,63.4,Koryak,ETHNICITY,ETHNICITY
SM254931,176.36,63.4,Koryak,ETHNICITY,ETHNICITY
SM254931,176.36,63.4,Koryak,ETHNICITY,ETHNICITY
SM254931,176.36,63.4,Koryak,ETHNICITY,ETHNICITY
SM254931,161.99,64.73,Koryak,ETHNICITY,ETHNICITY
SM254931,161.99,64.73,Koryak,ETHNICITY,ETHNICITY
SM254931,161.99,64.73,Koryak,ETHNICITY,ETHNICITY
SM254931,161.99,64.73,Koryak,ETHNICITY,ETHNICITY
SM254931,166,60.5,Koryak,ETHNICITY,ETHNICITY
SM254931,166,60.5,Koryak,ETHNICITY,ETHNICITY
SM254931,166,60.5,Koryak,ETHNICITY,ETHNICITY
SM254931,166,60.5,Koryak,ETHNICITY,ETHNICITY
SM254931,164.24,61.99,Koryak,ETHNICITY,ETHNICITY
SM254931,164.24,61.99,Koryak,ETHNICITY,ETHNICITY
SM254931,164.24,61.99,Koryak,ETHNICITY,ETHNICITY
SM254931,164.24,61.99,Koryak,ETHNICITY,ETHNICITY
SM254931,159.38,58.3,Koryak,ETHNICITY,ETHNICITY
SM254931,159.38,58.3,Koryak,ETHNICITY,ETHNICITY
SM254931,159.38,58.3,Koryak,ETHNICITY,ETHNICITY
SM254931,159.38,58.3,Koryak,ETHNICITY,ETHNICITY
SM1857,20.6667,40.6167,Korçë,ADM1,DISTRICT
SM260495,20.6667,40.6667,Korçës,ADM2,DISTRICT
SM276041,17.1318,42.9569,Korčula,ADM2,DISTRICT
SM235163,140.197,-3.7875,Kosadle,LANGUAGE,LANGUAGE
SM12991,137.5164,34.7309,Kosai-shi (2010-),ADM2,DISTRICT
SM278563,140.7726,40.3978,Kosaka,ADM2,DISTRICT
SM289183,127.4239,38.9028,Kosan,ADM2,DISTRICT
SM235161,139.645,-4.066,Kosarek Yale,LANGUAGE,LANGUAGE
SM300728,-85.8607,41.2441,Kosciusko,ADM2,DISTRICT
SM11172,25.1114,59.1859,Kose vald,ADM2,DISTRICT
SM235165,145.66,-6.4641,Kosena,LANGUAGE,LANGUAGE
SM292871,88,49.6667,Kosh-Agachskiy rayon,ADM2,DISTRICT
SM292715,40.5,44.8333,Koshekhabl'skiy rayon,ADM2,DISTRICT
SM18604,133.5071,33.5859,Koshi Shi,ADM2,DISTRICT
SM279176,139.7902,35.9011,Koshigaya,ADM2,DISTRICT
SM235168,10.299,6.5817,Koshin,LANGUAGE,LANGUAGE
SM294367,50.4167,54.25,Koshkinskiy rayon,ADM2,DISTRICT
SM292966,84.5833,53.4167,Kosikhinskiy rayon,ADM2,DISTRICT
SM294048,55,59.9167,Kosinskiy rayon,ADM2,DISTRICT
SM299990,25.0909,48.3202,Kosivs'kyi,ADM2,DISTRICT
SM254938,-118,36,Koso,ETHNICITY,ETHNICITY
SM254938,-118,36,Koso,ETHNICITY,ETHNICITY
SM254938,-117.7,36.12,Koso,ETHNICITY,ETHNICITY
SM254938,-117.7,36.12,Koso,ETHNICITY,ETHNICITY
SM284993,3.409,6.601,Kosofe,ADM2,DISTRICT
SM303522,65.7441,39.1308,Koson,ADM2,DISTRICT
SM303540,71.5828,41.233,Kosonsoy,ADM2,DISTRICT
SM304749,21.0397,42.6208,Kosovo Polje,ADM2,DISTRICT
SM1862,20.876,42.8957,Kosovska Mitrovica,ADM1,DISTRICT
SM1863,162.981,5.325,Kosrae,ADM1,DISTRICT
SM235174,162.98,5.31,Kosraean,LANGUAGE,LANGUAGE
SM254939,165.55,11.62,Kosraeans,ETHNICITY,ETHNICITY
SM254939,165.55,11.62,Kosraeans,ETHNICITY,ETHNICITY
SM254939,162.98,5.31,Kosraeans,ETHNICITY,ETHNICITY
SM254939,162.98,5.31,Kosraeans,ETHNICITY,ETHNICITY
SM262181,-3.8333,12.9167,Kossi,ADM1:ADM2,DISTRICT
SM300834,-94.2067,43.2041,Kossuth,ADM2,DISTRICT
SM4130,15.4191,45.834,Kostanjevica na Krki,ADM1,DISTRICT
SM296269,14.8711,45.4894,Kostel,ADM1:ADM2,DISTRICT
SM262421,23.8667,42.3,Kostenets,ADM2,DISTRICT
SM295209,32.31,12.73,Kosti,ADM2,DISTRICT
SM299939,37.7117,48.5255,Kostiantynivs'ka,ADM2,DISTRICT
SM299940,37.75,48.5,Kostiantynivs'kyi,ADM2,DISTRICT
SM262422,23.2,42.8833,Kostinbrod,ADM2,DISTRICT
SM305409,14.5101,45.2992,Kostrena,ADM2,DISTRICT
SM1864,44,58.5,Kostroma,ADM1,DISTRICT
SM16963,41,57.8333,Kostromskoy rayon,ADM2,DISTRICT
SM297079,102.9908,16.2388,Kosum Phisai,ADM2,DISTRICT
SM288823,16.3023,54.1097,Koszalin,ADM2,DISTRICT
SM288843,16.2166,54.2072,Koszalin (City),ADM2,DISTRICT
SM11579,16.6565,47.375,Koszegi Jaras,ADM2,DISTRICT
SM289184,128.1844,38.6547,Kosŏng,ADM2,DISTRICT
SM9559,70.5953,34.1532,Kot,ADM2,DISTRICT
SM254940,77.69,10.76,Kota,ETHNICITY,ETHNICITY
SM277179,75.8441,25.1751,Kota (1991-),ADM2,DISTRICT
SM235182,13.97,1.016,Kota (Gabon),LANGUAGE,LANGUAGE
SM235178,76.9387,11.4978,Kota (India),LANGUAGE,LANGUAGE
SM276760,107.6244,-6.9175,Kota Bandung,ADM2,DISTRICT
SM235183,116.297,-0.2705,Kota Bangun Kutai Malay,LANGUAGE,LANGUAGE
SM276403,116,-3,Kota Baru,ADM2,DISTRICT
SM268300,114.969,4.8887,Kota Batu,ADM2,DISTRICT
SM276761,106.9833,-6.2833,Kota Bekasi,ADM2,DISTRICT
SM284415,102.2645,6.0567,Kota Bharu,ADM2,DISTRICT
SM276485,118.7526,-8.4673,Kota Bima,ADM2,DISTRICT
SM276706,98.4803,3.5986,Kota Binjai,ADM2,DISTRICT
SM276341,112.1667,-8.1,Kota Blitar,ADM2,DISTRICT
SM276762,106.8,-6.5917,Kota Bogor,ADM2,DISTRICT
SM276763,108.55,-6.75,Kota Cirebon,ADM2,DISTRICT
SM276736,123.0431,0.5345,Kota Gorontalo,ADM2,DISTRICT
SM276526,140.7778,-2.6465,Kota Jayapura,ADM2,DISTRICT
SM276342,112.0167,-7.8333,Kota Kediri,ADM2,DISTRICT
SM276508,123.6,-10.2167,Kota Kupang,ADM2,DISTRICT
SM276343,111.5333,-7.6333,Kota Madiun,ADM2,DISTRICT
SM276309,110.225,-7.5,Kota Magelang,ADM2,DISTRICT
SM276344,112.6333,-7.975,Kota Malang,ADM2,DISTRICT
SM235187,116.853,6.4855,Kota Marudu Talantang,LANGUAGE,LANGUAGE
SM235186,116.946,6.377,Kota Marudu Tinagas,LANGUAGE,LANGUAGE
SM276707,98.6667,3.65,Kota Medan,ADM2,DISTRICT
SM276345,112.4333,-7.4667,Kota Mojokerto,ADM2,DISTRICT
SM276346,112.9,-7.65,Kota Pasuruan,ADM2,DISTRICT
SM276310,109.6833,-6.9,Kota Pekalongan,ADM2,DISTRICT
SM276386,109.3315,0.0083,Kota Pontianak,ADM2,DISTRICT
SM276347,113.2167,-7.7833,Kota Probolinggo,ADM2,DISTRICT
SM276311,110.3833,-7.0333,Kota Semarang,ADM2,DISTRICT
SM276714,106.15,-6.1256,Kota Serang,ADM2,DISTRICT
SM284404,100.4764,6.1379,Kota Setar,ADM2,DISTRICT
SM276655,100.6398,-0.7785,Kota Solok,ADM2,DISTRICT
SM276517,131.2515,-0.8651,Kota Sorong,ADM2,DISTRICT
SM276764,106.9333,-6.95,Kota Sukabumi,ADM2,DISTRICT
SM276715,106.6299,-6.1794,Kota Tangerang,ADM2,DISTRICT
SM276675,99.7917,2.9583,Kota Tanjungbalai,ADM2,DISTRICT
SM276765,108.2167,-7.35,Kota Tasikmalaya,ADM2,DISTRICT
SM276312,109.1129,-6.8686,Kota Tegal,ADM2,DISTRICT
SM284314,103.9667,1.8167,Kota Tinggi,ADM2,DISTRICT
SM276710,110.375,-7.8,Kota Yogyakarta,ADM2,DISTRICT
SM250265,12.53,0.4,Kota-generic,ETHNICITY,ETHNICITY
SM250265,12.53,0.4,Kota-generic,ETHNICITY,ETHNICITY
SM250265,14,1,Kota-generic,ETHNICITY,ETHNICITY
SM250265,14,1,Kota-generic,ETHNICITY,ETHNICITY
SM235181,1.7235,6.6403,Kotafon Gbe,LANGUAGE,LANGUAGE
SM276630,124.3268,0.6892,Kotamobagu,ADM2,DISTRICT
SM276415,111.7333,-2.4,Kotawaringin Barat,ADM2,DISTRICT
SM276416,112.75,-2.0833,Kotawaringin Timur,ADM2,DISTRICT
SM1865,44.75,40.4167,Kotayk,ADM1,DISTRICT
SM262389,26.45,42.8833,Kotel,ADM2,DISTRICT
SM293265,48.1667,58.3333,Kotel'nichskiy rayon,ADM2,DISTRICT
SM294908,43.25,47.75,Kotel'nikovskiy rayon,ADM2,DISTRICT
SM299638,34.7476,50.0656,Kotelevs'kyi,ADM2,DISTRICT
SM235190,39.7602,-16.4823,Koti,LANGUAGE,LANGUAGE
SM224920,82.8487,18.1046,Kotia-Adivasi Oriya-Desiya,LANGUAGE,LANGUAGE
SM299305,34.1,3,Kotido (2006-),ADM1:ADM2,DISTRICT
SM231776,-69.5705,1.0755,Kotiria,LANGUAGE,LANGUAGE
SM346793,27.2305,60.5844,Kotka-Hamina,ADM2:ADM3,DISTRICT
SM16964,46.3333,61.1667,Kotlasskiy Rayon,ADM2,DISTRICT
SM375368,73.9156,33.4456,Kotli,ADM2:ADM3,DISTRICT
SM254949,14.5,12.72,Koto,ETHNICITY,ETHNICITY
SM250408,15,11.5,Kotoko,ETHNICITY,ETHNICITY
SM250408,15,11.5,Kotoko,ETHNICITY,ETHNICITY
SM250408,15,11.5,Kotoko,ETHNICITY,ETHNICITY
SM250408,15,12,Kotoko,ETHNICITY,ETHNICITY
SM250408,15,12,Kotoko,ETHNICITY,ETHNICITY
SM250408,15,12,Kotoko,ETHNICITY,ETHNICITY
SM250408,14.8,11.8,Kotoko,ETHNICITY,ETHNICITY
SM250408,14.8,11.8,Kotoko,ETHNICITY,ETHNICITY
SM250408,14.8,11.8,Kotoko,ETHNICITY,ETHNICITY
SM284954,6.8571,8.1461,Kotonkar,ADM2,DISTRICT
SM1867,18.7667,42.4333,Kotor,ADM1,DISTRICT
SM313200,17.5025,44.5389,Kotor Varoš,ADM2:ADM3,DISTRICT
SM305341,16.8154,46.3561,Kotoriba,ADM2,DISTRICT
SM294909,44.8333,50.3333,Kotovskiy rayon,ADM2,DISTRICT
SM10825,33.3422,35.012,Kotsiatis,ADM2,DISTRICT
SM235201,94,55,Kott-Assan,LANGUAGE,LANGUAGE
SM276964,76.6667,9.6667,Kottayam,ADM2,DISTRICT
SM244646,145.599,-5.6434,Kou,LANGUAGE,LANGUAGE
SM262135,1.7147,10.4249,Kouandé,ADM2,DISTRICT
SM268567,20.4281,5.1199,Kouango,ADM2,DISTRICT
SM284579,165.8283,-21.3957,Kouaoua,ADM2,DISTRICT
SM10305,18.2752,15.7029,Kouba,ADM2,DISTRICT
SM274993,-11.8333,11.5833,Koubia,ADM1:ADM2,DISTRICT
SM1868,1.8333,7.0833,Kouffo,ADM1,DISTRICT
SM10306,17.0659,8.4142,Kouh-Est,ADM2,DISTRICT
SM10307,16.8744,8.1554,Kouh-Ouest,ADM2,DISTRICT
SM10240,15.3514,6.7141,Koui,ADM2,DISTRICT
SM1870,11.9998,-4.1958,Kouilou,ADM1,DISTRICT
SM10826,32.8928,34.8552,Kouka,ADM2,DISTRICT
SM18524,33.7567,35.1147,Kouklia (Famagusta),ADM2,DISTRICT
SM10827,32.606,34.6974,Kouklia (Paphos),ADM2,DISTRICT
SM10308,21.6917,11.9966,Koukou-Angarana,ADM2,DISTRICT
SM283362,-7.3033,13.2257,Koulikoro,ADM2,DISTRICT
SM1871,-7.5,13.8,Koulikoro,ADM1,DISTRICT
SM10309,15.2263,13.3274,Kouloudia,ADM2,DISTRICT
SM262203,0.1667,11.4167,Koulpélogo,ADM1:ADM2,DISTRICT
SM284580,164.2717,-20.555,Koumac,ADM2,DISTRICT
SM427813,17.4939,8.8476,Koumra,ADM2:ADM3,DISTRICT
SM280419,106.7514,13.5177,Koun Mom,ADM2,DISTRICT
SM274977,-13.1667,12.4167,Koundara,ADM1:ADM2,DISTRICT
SM269543,10.4745,5.3385,Koung Khi,ADM2,DISTRICT
SM295274,-14.8448,14.2361,Koungheul,ADM2,DISTRICT
SM1872,45.2059,-12.7351,Koungou,ADM1,DISTRICT
SM13371,105.5312,17.2039,Kounkham,ADM2,DISTRICT
SM250286,-1.16,18.59,Kounta,ETHNICITY,ETHNICITY
SM250286,-1.16,18.59,Kounta,ETHNICITY,ETHNICITY
SM250286,-1,19,Kounta,ETHNICITY,ETHNICITY
SM250286,-1,19,Kounta,ETHNICITY,ETHNICITY
SM295258,-14.3353,14.1312,Koupentoum,ADM2,DISTRICT
SM269549,9.78,5.0856,Koupé Manengouba,ADM2,DISTRICT
SM280920,35.8049,34.3202,Koura,ADM2,DISTRICT
SM10828,32.5384,34.861,Kourdaka,ADM2,DISTRICT
SM375227,3.6469,14.9739,Kourfeye,ADM2:ADM3,DISTRICT
SM262204,-0.3,12.2,Kouritenga,ADM1:ADM2,DISTRICT
SM10829,33.552,35.2231,Kourou Monastiri,ADM2,DISTRICT
SM274985,-9.9167,10.6667,Kouroussa,ADM1:ADM2,DISTRICT
SM262190,-1.7667,12.5833,Kourwéogo,ADM1:ADM2,DISTRICT
SM283382,-5.5839,12.3531,Koutiala,ADM2,DISTRICT
SM10830,33.4238,35.2655,Koutsoventis,ADM2,DISTRICT
SM346794,26.6807,60.9339,Kouvola,ADM2:ADM3,DISTRICT
SM235208,-6.5685,7.2738,Kouya,LANGUAGE,LANGUAGE
SM262325,22.8333,42.55,Kovachevtsi,ADM2,DISTRICT
SM235209,147.862,-5.6171,Kovai,LANGUAGE,LANGUAGE
SM298358,39.8652,38.7188,Kovancılar,ADM2,DISTRICT
SM293744,30.4,67.5667,Kovdorskiy rayon,ADM2,DISTRICT
SM235210,149.63,-5.5862,Kove,LANGUAGE,LANGUAGE
SM454400,149.53,-4.67,Kove,ETHNICITY,ETHNICITY
SM454400,149.53,-4.67,Kove,ETHNICITY,ETHNICITY
SM454400,148.95,-5.4667,Kove,ETHNICITY,ETHNICITY
SM454400,148.95,-5.4667,Kove,ETHNICITY,ETHNICITY
SM299811,24.7016,51.212,Kovel's'ka,ADM2,DISTRICT
SM293763,44,57.1667,Koverninskiy rayon,ADM2,DISTRICT
SM295390,159.6301,-8.2213,Koviloko,ADM2,DISTRICT
SM16965,41.3333,56.25,Kovrovskiy Rayon,ADM2,DISTRICT
SM16966,43.968,54.0263,Kovylkinskiy Rayon,ADM2,DISTRICT
SM235211,145.412,-4.6303,Kowaki,LANGUAGE,LANGUAGE
SM261637,141.8129,-15.2788,Kowanyama,ADM2,DISTRICT
SM235213,133.468,-4.2195,Kowiai,LANGUAGE,LANGUAGE
SM1873,114.1912,22.3287,Kowloon City,ADM1,DISTRICT
SM289130,127.1803,39.4458,Kowŏn,ADM2,DISTRICT
SM235225,44.6365,36.0451,Koy Sanjaq Jewish Neo-Aramaic,LANGUAGE,LANGUAGE
SM235215,81.2096,17.6772,Koya,LANGUAGE,LANGUAGE
SM254959,76.77,14.87,Koya,ETHNICITY,ETHNICITY
SM254959,76.77,14.87,Koya,ETHNICITY,ETHNICITY
SM254959,79.98,16.15,Koya,ETHNICITY,ETHNICITY
SM254959,79.98,16.15,Koya,ETHNICITY,ETHNICITY
SM254959,78,19,Koya,ETHNICITY,ETHNICITY
SM254959,78,19,Koya,ETHNICITY,ETHNICITY
SM235217,-6.2326,8.2918,Koyaga,LANGUAGE,LANGUAGE
SM293322,51,60.4167,Koygorodskiy rayon,ADM2,DISTRICT
SM235218,15.7508,-0.6969,Koyo,LANGUAGE,LANGUAGE
SM235221,-3.7396,16.192,Koyra Chiini Songhay,LANGUAGE,LANGUAGE
SM235223,-0.5519,16.9634,Koyraboro Senni Songhai,LANGUAGE,LANGUAGE
SM12992,131.1595,32.2235,Koyu-gun,ADM2,DISTRICT
SM235227,-154.229,65.6093,Koyukon,LANGUAGE,LANGUAGE
SM254968,-160.15,64.3,Koyukon,ETHNICITY,ETHNICITY
SM254968,-160.15,64.3,Koyukon,ETHNICITY,ETHNICITY
SM254968,-153.78,66.52,Koyukon,ETHNICITY,ETHNICITY
SM254968,-153.78,66.52,Koyukon,ETHNICITY,ETHNICITY
SM298924,37.8331,40.3011,Koyulhisar,ADM2,DISTRICT
SM296852,1.1667,9.5833,Kozah,ADM2,DISTRICT
SM298790,34.8558,39.232,Kozaklı,ADM2,DISTRICT
SM298133,35.75,37.5,Kozan,ADM2,DISTRICT
SM299639,33.8497,49.2186,Kozel'shchyns'kyi,ADM2,DISTRICT
SM293070,35.8333,54,Kozel'skiy rayon,ADM2,DISTRICT
SM276965,76,11.5,Kozhikode,ADM2,DISTRICT
SM299795,28.8391,49.7165,Koziatyns'kyi,ADM2,DISTRICT
SM288990,21.4958,51.6395,Kozienice,ADM2,DISTRICT
SM299749,25.1425,49.4327,Kozivs'kyi,ADM2,DISTRICT
SM296238,15.5569,46.0722,Kozje,ADM1:ADM2,DISTRICT
SM262491,23.7333,43.7167,Kozloduy,ADM2,DISTRICT
SM16967,48.1667,55.8333,Kozlovskiy Rayon,ADM2,DISTRICT
SM18068,31.7492,41.4324,Kozlu,ADM2,DISTRICT
SM298187,41.4871,38.1925,Kozluk,ADM2,DISTRICT
SM293435,91.3769,56.1828,Kozul'skiy rayon,ADM2,DISTRICT
SM298160,27.7114,37.7715,Koçarlı,ADM2,DISTRICT
SM1842,22.4,41.9833,Kočani,ADM1,DISTRICT
SM288788,18.781,52.2445,Koło,ADM2,DISTRICT
SM288842,15.6139,54.0501,Kołobrzeg,ADM2,DISTRICT
SM288762,20.3419,51.1514,Końskie,ADM2,DISTRICT
SM288791,16.7151,52.0662,Kościan,ADM2,DISTRICT
SM288714,18.004,54.0194,Kościerzyna,ADM2,DISTRICT
SM296138,21.25,48.7291,Košice I,ADM2,DISTRICT
SM296139,21.2227,48.6975,Košice II,ADM2,DISTRICT
SM296140,21.2905,48.7324,Košice III,ADM2,DISTRICT
SM296141,21.2657,48.6869,Košice IV,ADM2,DISTRICT
SM296137,21.4137,48.779,Košice-okolie,ADM2,DISTRICT
SM1861,21.3333,48.6667,Košický,ADM1,DISTRICT
SM305373,18.2843,45.5453,Koška,ADM2,DISTRICT
SM14064,-9.2326,6.9147,Kpaai District,ADM2,DISTRICT
SM235234,20.1584,4.9615,Kpagua,LANGUAGE,LANGUAGE
SM370190,-8.5314,6.6622,Kpaiplay,ADM2:ADM3,DISTRICT
SM235236,20.3108,4.5109,Kpala,LANGUAGE,LANGUAGE
SM235239,10.1642,7.5834,Kpan,LANGUAGE,LANGUAGE
SM4375,-0.0167,8.4667,Kpandai,ADM2,DISTRICT
SM4397,0.3,7,Kpandu,ADM2,DISTRICT
SM235243,11.231,8.7529,Kpasam,LANGUAGE,LANGUAGE
SM235246,10.1129,7.7061,Kpati,LANGUAGE,LANGUAGE
SM235246,10.1129,7.7061,Kpati,LANGUAGE,LANGUAGE
SM235246,9.9767,7.2529,Kpati,LANGUAGE,LANGUAGE
SM235246,9.9767,7.2529,Kpati,LANGUAGE,LANGUAGE
SM235244,21.8594,5.172,Kpatili,LANGUAGE,LANGUAGE
SM280951,-8.8261,5.1282,Kpayan,ADM2,DISTRICT
SM235248,-5.2677,10.7244,Kpeego,LANGUAGE,LANGUAGE
SM250424,-9.1,7,Kpelle (Liberia),ETHNICITY,ETHNICITY
SM250424,-9.1,7,Kpelle (Liberia),ETHNICITY,ETHNICITY
SM250424,-9.1,7,Kpelle (Liberia),ETHNICITY,ETHNICITY
SM250424,-10,7.25,Kpelle (Liberia),ETHNICITY,ETHNICITY
SM250424,-10,7.25,Kpelle (Liberia),ETHNICITY,ETHNICITY
SM250424,-10,7.25,Kpelle (Liberia),ETHNICITY,ETHNICITY
SM250424,-9,7,Kpelle (Liberia),ETHNICITY,ETHNICITY
SM250424,-9,7,Kpelle (Liberia),ETHNICITY,ETHNICITY
SM250424,-9,7,Kpelle (Liberia),ETHNICITY,ETHNICITY
SM3871,-10,7.25,Kpelle-Generic,ETHNICITY,ETHNICITY
SM235251,1.2108,8.0547,Kpessi,LANGUAGE,LANGUAGE
SM14066,-8.1415,5.0131,Kpi District,ADM2,DISTRICT
SM235253,-0.803,8.0665,Kplang,LANGUAGE,LANGUAGE
SM262143,2.021,6.461,Kpomassè,ADM2,DISTRICT
SM11408,-0.0594,5.7568,Kpone Katamanso,ADM2,DISTRICT
SM297443,98.8437,10.459,Kra Buri,ADM2,DISTRICT
SM11662,93.6337,27.6949,Kra Daadi,ADM2,DISTRICT
SM1874,99.0086,8.1979,Krabi,ADM1,DISTRICT
SM235261,0.0193,7.9348,Krache,LANGUAGE,LANGUAGE
SM280359,106.1943,12.567,Kracheh,ADM2,DISTRICT
SM280359,106.1943,12.567,Kracheh,ADM2,DISTRICT
SM280359,106.0396,12.4996,Kracheh,ADM2,DISTRICT
SM280359,106.0396,12.4996,Kracheh,ADM2,DISTRICT
SM274960,0.2245,7.6982,Krachi East,ADM2,DISTRICT
SM4384,-0.0852,8.1753,Krachi Nchumuru,ADM2,DISTRICT
SM4383,-0.0167,7.8,Krachi West,ADM2,DISTRICT
SM286219,9.411,58.8679,Kragerø,ADM2,DISTRICT
SM250808,-7.55,6.37,Krahn,ETHNICITY,ETHNICITY
SM250808,-7.55,6.37,Krahn,ETHNICITY,ETHNICITY
SM250808,-8,6,Krahn,ETHNICITY,ETHNICITY
SM250808,-8,6,Krahn,ETHNICITY,ETHNICITY
SM254969,-46.43,-9.69,Kraho,ETHNICITY,ETHNICITY
SM254969,-44.08,-8.35,Kraho,ETHNICITY,ETHNICITY
SM235262,-47.5774,-8.0117,Krahô,DIALECT,LANGUAGE
SM1876,15.5,49.4167,Kraj Vysočina,ADM1,DISTRICT
SM280383,104.19,12.48,Krakor,ADM2,DISTRICT
SM288948,19.9141,50.1435,Kraków,ADM2,DISTRICT
SM288947,19.9325,50.0726,Kraków (City),ADM2,DISTRICT
SM280432,103.4882,13.5741,Kralanh,ADM2,DISTRICT
SM305728,15.7232,45.9921,Kraljevec na Sutli,ADM2,DISTRICT
SM275970,14.569,45.2741,Kraljevica,ADM2,DISTRICT
SM461270,37.5493,48.7219,Kramators'ka,ADM2,DISTRICT
SM18235,37.4521,48.7261,Kramatorskyi Raion,ADM2,DISTRICT
SM296536,17.9227,62.9725,Kramfors,ADM2,DISTRICT
SM254971,142.1,-7.16,Kramo,ETHNICITY,ETHNICITY
SM296206,14.3565,46.2469,Kranj,ADM1:ADM2,DISTRICT
SM296207,13.8386,46.4603,Kranjska Gora,ADM1:ADM2,DISTRICT
SM296977,103.0804,16.7526,Kranuan,ADM2,DISTRICT
SM235266,106.539,12.8107,Kraol,LANGUAGE,LANGUAGE
SM13611,25.1972,56.7375,Krapes pagasts,ADM2,DISTRICT
SM276061,15.8726,46.1597,Krapina,ADM2,DISTRICT
SM305729,15.8382,46.0935,Krapinske Toplice,ADM2,DISTRICT
SM1878,16,46.0833,Krapinsko-Zagorska,ADM1,DISTRICT
SM293146,87.25,54.75,Krapivinskiy rayon,ADM2,DISTRICT
SM289000,17.9577,50.4526,Krapkowice,ADM2,DISTRICT
SM297595,100.3283,7.6125,Krasae Sinthu,ADM2,DISTRICT
SM297432,103.319,14.9417,Krasang,ADM2,DISTRICT
SM13612,27.1677,55.8984,Kraslava,ADM2,DISTRICT
SM3979,27.3198,55.9227,Kraslavas novads,ADM1,DISTRICT
SM13613,27.2376,55.9057,Kraslavas pagasts,ADM2,DISTRICT
SM262628,31.4167,53.3333,Krasnapolle,ADM2,DISTRICT
SM294677,38.5833,50.9167,Krasnenskiy rayon,ADM2,DISTRICT
SM293566,38.75,52.8333,Krasninskiy rayon,ADM2,DISTRICT
SM13202,70.0008,54.0025,Krasnoarmeysk Audany,ADM2,DISTRICT
SM16968,45.5,51,Krasnoarmeyskiy Rayon,ADM2,DISTRICT
SM292794,47.1667,55.75,Krasnoarmeyskiy rayon,ADM2,DISTRICT
SM293370,38.2426,45.3476,Krasnoarmeyskiy rayon,ADM2,DISTRICT
SM292752,62,55.4167,Krasnoarmeyskiy rayon,ADM2,DISTRICT
SM299943,37.1667,48.25,Krasnoarmiis'kyi,ADM2,DISTRICT
SM293764,45.25,57.1667,Krasnobakovskiy rayon,ADM2,DISTRICT
SM293609,46.25,61.75,Krasnoborskiy rayon,ADM2,DISTRICT
SM292795,46.25,55.6667,Krasnochetayskiy rayon,ADM2,DISTRICT
SM295084,108.9185,50.405,Krasnochikoyskiy rayon,ADM2,DISTRICT
SM1879,38.9768,44.9881,Krasnodar,ADM1,DISTRICT
SM299583,39.738,48.3081,Krasnodons'ka,ADM2,DISTRICT
SM299584,39.75,48.3333,Krasnodons'kyi,ADM2,DISTRICT
SM16969,37.3333,55.8333,Krasnogorskiy Rayon,ADM2,DISTRICT
SM295010,31.5,53,Krasnogorskiy rayon,ADM2,DISTRICT
SM292968,86.4167,52.3333,Krasnogorskiy rayon,ADM2,DISTRICT
SM16970,30.4706,59.9729,Krasnogvardeyskiy Rayon,ADM2,DISTRICT
SM292716,39.75,45.0833,Krasnogvardeyskiy rayon,ADM2,DISTRICT
SM294678,38.3333,50.6667,Krasnogvardeyskiy rayon,ADM2,DISTRICT
SM299882,34.2546,45.4023,Krasnohvardiis'kyi,ADM2,DISTRICT
SM16971,118.0167,50.05,Krasnokamenskiy Rayon,ADM2,DISTRICT
SM16972,54.2667,56.0833,Krasnokamskiy Rayon,ADM2,DISTRICT
SM294757,37.1667,58.0833,Krasnokholmskiy rayon,ADM2,DISTRICT
SM294412,47,51,Krasnokutskiy rayon,ADM2,DISTRICT
SM299555,38.8731,48.1311,Krasnoluts'ka,ADM2,DISTRICT
SM299944,37.8094,48.9888,Krasnolymans'ka,ADM2,DISTRICT
SM461268,37.8333,49,Krasnolymans’kyi (excl. Krasnolymans'ka),ADM2,DISTRICT
SM299615,29.461,47.5403,Krasnooknians'kyi,ADM2,DISTRICT
SM293765,45.5833,55.3333,Krasnooktyabr'skiy rayon,ADM2,DISTRICT
SM18236,33.8799,45.9699,Krasnoperekopsk Raion (excl. Krasnoperekopsk and Armyansk),ADM2,DISTRICT
SM295036,82,64.3333,Krasnosel'kupskiy rayon,ADM2,DISTRICT
SM16973,30.1894,59.8536,Krasnosel'skiy Rayon,ADM2,DISTRICT
SM293329,41.3333,57.5833,Krasnosel'skiy rayon,ADM2,DISTRICT
SM292969,83,51.6667,Krasnoshchekovskiy rayon,ADM2,DISTRICT
SM293640,43.75,54.4167,Krasnoslobodskiy rayon,ADM2,DISTRICT
SM294153,40.0759,47.8908,Krasnosulinskiy rayon,ADM2,DISTRICT
SM293436,91.6667,54.4167,Krasnoturanskiy rayon,ADM2,DISTRICT
SM294528,57.6667,56.5833,Krasnoufimskiy rayon,ADM2,DISTRICT
SM294050,58,60.25,Krasnovisherskiy rayon,ADM2,DISTRICT
SM1880,93,58,Krasnoyarsk (2007-),ADM1,DISTRICT
SM294369,50.5,53.5,Krasnoyarskiy rayon,ADM2,DISTRICT
SM293854,48.25,46.8333,Krasnoyarskiy rayon,ADM2,DISTRICT
SM294679,35.5,50.8333,Krasnoyaruzhskiy rayon,ADM2,DISTRICT
SM293864,79.25,54,Krasnozerskiy rayon,ADM2,DISTRICT
SM293051,22.5,54.9167,Krasnoznamenskiy rayon,ADM2,DISTRICT
SM288914,23.1751,50.9849,Krasnystaw,ADM2,DISTRICT
SM299448,26.9716,49.6538,Krasylivs'kyi,ADM2,DISTRICT
SM297518,100.2728,13.6557,Krathum Baen,ADM2,DISTRICT
SM1881,22.1667,42.0833,Kratovo,ADM1,DISTRICT
SM459064,148.3379,-37.5932,Krauatungalang,ETHNICITY,ETHNICITY
SM305652,16.0469,45.5856,Kravarsko,ADM2,DISTRICT
SM288913,22.1458,50.9153,Kraśnik,ADM2,DISTRICT
SM305617,15.517,45.6534,Krašić,ADM2,DISTRICT
SM250182,18.82,14.98,Kreda,ETHNICITY,ETHNICITY
SM254973,-55.89,-9.65,Kreenakaroke,ETHNICITY,ETHNICITY
SM250389,29.87,7.01,Kreish,ETHNICITY,ETHNICITY
SM18237,33.4094,49.0652,Kremenchuk Raion,ADM2,DISTRICT
SM299750,25.7272,50.0994,Kremenets'kyi,ADM2,DISTRICT
SM261827,15.5209,48.4658,Krems an der Donau Land,ADM2,DISTRICT
SM261804,15.6057,48.4104,Krems an der Donau Stadt,ADM2,DISTRICT
SM235270,-41.2299,-18.9988,Krenak,LANGUAGE,LANGUAGE
SM254974,-45.87,-5.06,Krene,ETHNICITY,ETHNICITY
SM262299,23.2333,41.7667,Kresna,ADM2,DISTRICT
SM293840,32.5,58.25,Krestetskiy rayon,ADM2,DISTRICT
SM281335,21.25,55.8833,Kretingos,ADM2,DISTRICT
SM268970,9.1646,47.6305,Kreuzlingen,ADM2,DISTRICT
SM239660,106.877,15.6211,Kriang-Khlor,LANGUAGE,LANGUAGE
SM262353,24.4724,42.0044,Krichim,ADM2,DISTRICT
SM10831,33.9562,35.394,Krideia,ADM2,DISTRICT
SM235275,-11.8393,7.2412,Krim,DIALECT,LANGUAGE
SM285659,4.5882,51.9146,Krimpen aan den IJssel,ADM2,DISTRICT
SM15166,4.7783,51.9817,Krimpenerwaard,ADM2,DISTRICT
SM4007,24.7931,57.2131,Krimuldas Novads,ADM1,DISTRICT
SM13614,24.7946,57.2132,Krimuldas pagasts,ADM2,DISTRICT
SM13615,23.3984,56.5654,Krimunu pagasts,ADM2,DISTRICT
SM10832,33.2469,35.2761,Krini,ADM2,DISTRICT
SM235279,-14.3204,10.8811,Krio,LANGUAGE,LANGUAGE
SM235278,137.669,-18.135,Kriol,LANGUAGE,LANGUAGE
SM277027,81,16.6667,Krishna,ADM2,DISTRICT
SM277220,77.96,12.58,Krishnagiri,ADM2,DISTRICT
SM13616,27.2977,56.8198,Krisjanu pagasts,ADM2,DISTRICT
SM461897,7.9969,58.1464,Kristiansand (2020-),ADM2,DISTRICT
SM296434,14.15,56,Kristianstad,ADM2,DISTRICT
SM461347,7.732,63.1115,Kristiansund (2008-),ADM2,DISTRICT
SM296505,14.1359,59.2529,Kristinehamn,ADM2,DISTRICT
SM10833,32.5814,34.9449,Kritou Marottou,ADM2,DISTRICT
SM10834,32.4333,34.9531,Kritou Tera,ADM2,DISTRICT
SM1882,22.3333,42.25,Kriva Palanka,ADM1,DISTRICT
SM262492,23.4833,43.3833,Krivodol,ADM2,DISTRICT
SM1883,21.3667,41.3333,Krivogaštani,ADM1,DISTRICT
SM305618,16.5198,45.664,Križ,ADM2,DISTRICT
SM276058,16.5667,46.0333,Križevci,ADM2,DISTRICT
SM296359,16.1178,46.5658,Križevci,ADM1:ADM2,DISTRICT
SM275971,14.5723,45.0283,Krk,ADM2,DISTRICT
SM305703,15.5988,45.3359,Krnjak,ADM2,DISTRICT
SM235283,-4.4774,5.8616,Krobu,LANGUAGE,LANGUAGE
SM297205,100.0182,15.5864,Krok Phra,ADM2,DISTRICT
SM296639,14.2,63.7333,Krokom,ADM2,DISTRICT
SM271202,17.5,49.3333,Kroměříž,ADM2,DISTRICT
SM10101,106.0919,11.065,Krong Bavet,ADM2,DISTRICT
SM10102,104.317,10.5227,Krong Kaeb (excl. Damnak Chang'aeur),ADM2,DISTRICT
SM1884,102.6,12.8667,Krong Pailin,ADM1,DISTRICT
SM10103,102.5992,13.6569,Krong Paoy Pet,ADM2,DISTRICT
SM1885,103.75,10.6667,Krong Preah Sihanouk,ADM1,DISTRICT
SM10104,104.9847,13.7426,Krong Preah Vihear,ADM2,DISTRICT
SM10105,105.6588,11.9073,Krong Suong,ADM2,DISTRICT
SM235285,30.003,10.514,Krongo,LANGUAGE,LANGUAGE
SM1886,14.6667,56.6667,Kronoberg,ADM1,DISTRICT
SM16974,29.753,59.9981,Kronshtadtskiy Rayon,ADM2,DISTRICT
SM289028,21.7694,49.5996,Krosno,ADM2,DISTRICT
SM289027,21.753,49.6893,Krosno (City),ADM2,DISTRICT
SM288922,14.9521,52.0099,Krosno Odrzańskie,ADM2,DISTRICT
SM288792,17.4213,51.7417,Krotoszyn,ADM2,DISTRICT
SM280461,105.676,12.1957,Krouch Chhmar,ADM2,DISTRICT
SM254978,-8.03,5.56,Kru,ETHNICITY,ETHNICITY
SM254978,-8.03,5.56,Kru,ETHNICITY,ETHNICITY
SM254978,-9,5,Kru,ETHNICITY,ETHNICITY
SM254978,-9,5,Kru,ETHNICITY,ETHNICITY
SM236271,-10.5284,6.423,Kru Pidgin English,LANGUAGE,LANGUAGE
SM262629,29.6667,54.1667,Kruhlaye,ADM2,DISTRICT
SM260482,19.75,41.55,Krujës,ADM2,DISTRICT
SM260482,19.75,41.55,Krujës,ADM2,DISTRICT
SM260482,19.7257,41.5009,Krujës,ADM2,DISTRICT
SM260482,19.7257,41.5009,Krujës,ADM2,DISTRICT
SM262531,25.7,41.45,Krumovgrad,ADM2,DISTRICT
SM235289,106.761,13.5699,Krung,DIALECT,LANGUAGE
SM313201,16.2753,44.9297,Krupa na Uni,ADM2:ADM3,DISTRICT
SM296121,19.0633,48.3574,Krupina,ADM2,DISTRICT
SM262662,29.1667,54.3333,Krupki,ADM2,DISTRICT
SM262507,27.75,43.8833,Krushari,ADM2,DISTRICT
SM4064,26.1728,56.5492,Krustpils Novads,ADM1,DISTRICT
SM13617,25.8278,56.5686,Krustpils pagasts,ADM2,DISTRICT
SM292970,81.2333,53.9667,Krutikhinskiy rayon,ADM2,DISTRICT
SM293894,71.5,56.25,Krutinskiy rayon,ADM2,DISTRICT
SM1887,21.2667,41.3667,Kruševo,ADM1,DISTRICT
SM262630,31.6667,53.75,Krychaw,ADM2,DISTRICT
SM293373,39.9667,46.3333,Krylovskiy rayon,ADM2,DISTRICT
SM235291,34.509,34.099,Krymchak,LANGUAGE,LANGUAGE
SM293375,37.8053,44.9053,Krymskiy rayon,ADM2,DISTRICT
SM299930,34.5,48.4167,Krynychans'kyi,ADM2,DISTRICT
SM299606,30.3446,47.9476,Kryvoozers'kyi,ADM2,DISTRICT
SM18238,33.3611,47.9006,Kryvyy Rih Raion,ADM2,DISTRICT
SM235292,48.1846,41.1811,Kryz,LANGUAGE,LANGUAGE
SM299796,28.8683,48.3813,Kryzhopil's'kyi,ADM2,DISTRICT
SM1877,15.9167,50.4167,Královéhradecký,ADM1,DISTRICT
SM1875,106.0833,12.6667,Krâchéh,ADM1,DISTRICT
SM303988,108.0833,12.5,Krông A Na,ADM2,DISTRICT
SM303989,108.5054,12.4642,Krông Bông,ADM2,DISTRICT
SM303976,108.2068,13.021,Krông Búk,ADM2,DISTRICT
SM303996,107.8345,12.3629,Krông Nô,ADM2,DISTRICT
SM303977,108.4167,13,Krông Năng,ADM2,DISTRICT
SM304050,108.6549,13.2315,Krông Pa,ADM2,DISTRICT
SM303978,108.3645,12.6929,Krông Pắc,ADM2,DISTRICT
SM286317,9.6212,60.1794,Krødsherad,ADM2,DISTRICT
SM305661,14.136,45.1715,Kršan,ADM2,DISTRICT
SM298085,8.883,34.4026,Ksar,ADM2,DISTRICT
SM14193,-15.955,18.1022,Ksar,ADM2,DISTRICT
SM457558,2.75,35.8333,Ksar El Boukhari District,ADM2,DISTRICT
SM297917,10.862,35.6412,Ksar Hellal,ADM2,DISTRICT
SM297918,10.8133,35.649,Ksibet El Mediouni,ADM2,DISTRICT
SM242862,103.931,20.8862,Ksingmul,LANGUAGE,LANGUAGE
SM297877,8.9511,35.8263,Ksour,ADM2,DISTRICT
SM297886,10.9901,35.3746,Ksour Essef,ADM2,DISTRICT
SM16975,44.0833,56.1833,Kstovskiy Rayon,ADM2,DISTRICT
SM304843,43.3599,15.8183,Ku'aydinah,ADM2,DISTRICT
SM261565,151.1488,-33.727,Ku-ring-gai,ADM2,DISTRICT
SM235306,103.33,26.45,Kua-nsi,LANGUAGE,LANGUAGE
SM284451,100.9,4.6333,Kuala Kangsar,ADM2,DISTRICT
SM284416,102.2141,5.5736,Kuala Krai,ADM2,DISTRICT
SM284387,101.5,2.75,Kuala Langat,ADM2,DISTRICT
SM1888,101.6865,3.1431,Kuala Lumpur,ADM1,DISTRICT
SM284405,100.3793,5.7104,Kuala Muda,ADM2,DISTRICT
SM284388,101.25,3.3333,Kuala Selangor,ADM2,DISTRICT
SM235302,103.33,26.35,Kuamasi,LANGUAGE,LANGUAGE
SM235303,105.31,18.0769,Kuan,LANGUAGE,LANGUAGE
SM13372,103.8442,20.0174,Kuan,ADM2,DISTRICT
SM235307,101.158,21.9802,Kuanhua,LANGUAGE,LANGUAGE
SM276562,101.4586,-0.4753,Kuantan Singingi,ADM2,DISTRICT
SM235305,152.114,-4.3099,Kuanua,LANGUAGE,LANGUAGE
SM235304,16.58,-17.7176,Kuanyama,LANGUAGE,LANGUAGE
SM450380,104.39,15.94,Kuay,ETHNICITY,ETHNICITY
SM235312,47.6018,42.085,Kubachi,LANGUAGE,LANGUAGE
SM461525,47.58,42.08,Kubachinian,ETHNICITY,ETHNICITY
SM284406,100.4398,6.4051,Kubang Pasu,ADM2,DISTRICT
SM284815,8.3829,10.8788,Kubau,ADM2,DISTRICT
SM235318,10.2746,10.6274,Kubi,LANGUAGE,LANGUAGE
SM235321,142.183,-5.9258,Kubo,LANGUAGE,LANGUAGE
SM254983,141.95,-6.29,Kubor,ETHNICITY,ETHNICITY
SM262358,26.4833,43.8667,Kubrat,ADM2,DISTRICT
SM235324,102.193,-2.4266,Kubu,LANGUAGE,LANGUAGE
SM276387,109.5187,-0.3192,Kubu Raya,ADM2,DISTRICT
SM13618,27.183,57.1659,Kubulu pagasts,ADM2,DISTRICT
SM296934,104.051,16.5198,Kuchinarai,ADM2,DISTRICT
SM284354,110.0833,1.4167,Kuching,ADM2,DISTRICT
SM235329,101.25,22.26,Kucong,LANGUAGE,LANGUAGE
SM279595,131.8868,34.0324,Kudamatsu,ADM2,DISTRICT
SM284816,7.799,11.273,Kudan,ADM2,DISTRICT
SM235334,75.4896,12.3301,Kudiya,LANGUAGE,LANGUAGE
SM235335,87.33,25.92,Kudmali,LANGUAGE,LANGUAGE
SM235336,9.0773,11.0819,Kudu-Camo,LANGUAGE,LANGUAGE
SM276313,110.8667,-6.8,Kudus,ADM2,DISTRICT
SM294052,54.5,59,Kudymkarskiy rayon,ADM2,DISTRICT
SM229184,-70.3059,-1.046,Kueretu,LANGUAGE,LANGUAGE
SM9560,70.6914,37.9431,Kuf Ab,ADM2,DISTRICT
SM261869,12.0333,47.5,Kufstein,ADM2,DISTRICT
SM294270,56.6667,52.5833,Kugarchinskiy rayon,ADM2,DISTRICT
SM235342,6.5633,4.7295,Kugbo,LANGUAGE,LANGUAGE
SM226836,-16.3947,12.6881,Kugere-Kuxinge,LANGUAGE,LANGUAGE
SM459299,141.6398,-14.6475,Kugu Mu'inh,ETHNICITY,ETHNICITY
SM459401,141.651,-14.4206,Kugu Muminh,ETHNICITY,ETHNICITY
SM459249,141.6347,-14.508,Kugu Ugbanh,ETHNICITY,ETHNICITY
SM459250,141.6513,-14.5589,Kugu Uwanh,ETHNICITY,ETHNICITY
SM459251,141.6549,-14.4867,Kugu Yi'anh,ETHNICITY,ETHNICITY
SM255002,51.28,32.22,Kuh-Giluye,ETHNICITY,ETHNICITY
SM305986,69.8,39.4,Kuhistoni Mastchoh,ADM2,DISTRICT
SM304845,43.697,15.7554,Kuhlan Affar,ADM2,DISTRICT
SM304846,43.4863,16.0231,Kuhlan Ash Sharaf,ADM2,DISTRICT
SM235347,52.45,32.7,Kuhpa'i,DIALECT,LANGUAGE
SM260010,61.1662,34.6133,Kuhsan,ADM2,DISTRICT
SM235349,83.5859,19.2043,Kui (India),LANGUAGE,LANGUAGE
SM235350,124.791,-8.382,Kui (Indonesia),LANGUAGE,LANGUAGE
SM297437,99.7546,12.1131,Kui Buri,ADM2,DISTRICT
SM299839,36.6539,47.3587,Kuibyshevs'kyi,ADM2,DISTRICT
SM235352,116.108,5.4718,Kuijau,LANGUAGE,LANGUAGE
SM235355,-53.2261,-12.3035,Kuikúro-Kalapálo,LANGUAGE,LANGUAGE
SM260410,17,-12.5,Kuito,ADM2,DISTRICT
SM1889,-46.0368,60.7191,Kujalleq,ADM1,DISTRICT
SM289208,126.0872,39.8819,Kujang,ADM2,DISTRICT
SM235356,22.1956,11.7473,Kujarge,LANGUAGE,LANGUAGE
SM1890,18.5,53.0833,Kujawsko-Pomorskie,ADM1,DISTRICT
SM284742,7.2705,8.659,Kuje,ADM2,DISTRICT
SM278438,141.6592,40.1436,Kuji,ADM2,DISTRICT
SM235366,10.1841,6.4376,Kuk,LANGUAGE,LANGUAGE
SM458485,140.3651,-18.1321,Kukatj,ETHNICITY,ETHNICITY
SM235360,127.666,-20.3321,Kukatja,LANGUAGE,LANGUAGE
SM458139,131.4281,-24.0428,Kukatja,ETHNICITY,ETHNICITY
SM458079,127.0239,-20.4894,Kukatja,ETHNICITY,ETHNICITY
SM285369,13.7381,13.1307,Kukawa,ADM2,DISTRICT
SM255004,151.12,-22.71,Kuke-Bura,ETHNICITY,ETHNICITY
SM235363,8.3934,6.7029,Kukele,LANGUAGE,LANGUAGE
SM255005,92,23,Kuki Generic,ETHNICITY,ETHNICITY
SM12993,139.6494,36.0766,Kuki-shi,ADM2,DISTRICT
SM305611,15.2461,44.0343,Kukljica,ADM2,DISTRICT
SM294616,50.6667,56.25,Kukmorskiy rayon,ADM2,DISTRICT
SM235368,73.5713,20.5657,Kukna,LANGUAGE,LANGUAGE
SM255006,-157.4,69.5,Kukparungmiut,ETHNICITY,ETHNICITY
SM285491,-83.7466,12.2405,Kukra Hill,ADM2,DISTRICT
SM235382,31.537,3.9613,Kuku,LANGUAGE,LANGUAGE
SM250390,32.47,2.34,Kuku,ETHNICITY,ETHNICITY
SM250390,32.47,2.34,Kuku,ETHNICITY,ETHNICITY
SM250390,32,4,Kuku,ETHNICITY,ETHNICITY
SM250390,32,4,Kuku,ETHNICITY,ETHNICITY
SM255008,145.32,-15.92,Kuku,ETHNICITY,ETHNICITY
SM255008,145.32,-15.92,Kuku,ETHNICITY,ETHNICITY
SM255008,144.6294,-16.4974,Kuku,ETHNICITY,ETHNICITY
SM255008,144.6294,-16.4974,Kuku,ETHNICITY,ETHNICITY
SM459440,144.9973,-15.7488,Kuku Bididji,ETHNICITY,ETHNICITY
SM459441,145.3425,-16.2193,Kuku Buyunji,ETHNICITY,ETHNICITY
SM459184,144.9688,-17.1059,Kuku Djangun / Djungan,ETHNICITY,ETHNICITY
SM459235,145.2107,-16.6497,Kuku Jakandji,ETHNICITY,ETHNICITY
SM459179,145.3437,-16.4324,Kuku Kulunggur,ETHNICITY,ETHNICITY
SM459448,143.4613,-16.0533,Kuku Mini,ETHNICITY,ETHNICITY
SM459444,145.2513,-15.7503,Kuku Nyungkul,ETHNICITY,ETHNICITY
SM459438,143.9143,-14.6603,Kuku Thaypan,ETHNICITY,ETHNICITY
SM459443,145.3479,-16.0167,Kuku Tungay,ETHNICITY,ETHNICITY
SM459180,144.2164,-16.6701,Kuku Wakura,ETHNICITY,ETHNICITY
SM459452,145.0767,-16.2263,Kuku Waldja / Wulpura,ETHNICITY,ETHNICITY
SM459237,144.5432,-16.1209,Kuku Yalaja,ETHNICITY,ETHNICITY
SM13619,26.0199,56.5191,Kuku pagasts,ADM2,DISTRICT
SM235376,141.824,-14.2866,Kuku-Mangk,LANGUAGE,LANGUAGE
SM235379,141.709,-14.201,Kuku-Mu'inh,LANGUAGE,LANGUAGE
SM235380,141.625,-14.0959,Kuku-Muminh,LANGUAGE,LANGUAGE
SM235378,141.569,-13.9882,Kuku-Ugbanh,LANGUAGE,LANGUAGE
SM235377,141.724,-14.0756,Kuku-Uwanh,LANGUAGE,LANGUAGE
SM459434,144.6123,-15.094,Kuku-Warra,ETHNICITY,ETHNICITY
SM235371,145.188,-16.0036,Kuku-Yalanji,LANGUAGE,LANGUAGE
SM295381,159.9795,-9.435,Kukum,ADM2,DISTRICT
SM1891,20.3667,42.2,Kukës,ADM1,DISTRICT
SM260498,20.4164,41.9822,Kukësit,ADM2,DISTRICT
SM260498,20.4164,41.9822,Kukësit,ADM2,DISTRICT
SM260498,20.3333,42,Kukësit,ADM2,DISTRICT
SM260498,20.3333,42,Kukësit,ADM2,DISTRICT
SM298739,28.6415,38.5677,Kula,ADM2,DISTRICT
SM262484,22.5667,43.9,Kula,ADM2,DISTRICT
SM255010,97.38,19.55,Kula,ETHNICITY,ETHNICITY
SM235392,125.004,-8.2384,Kula (Indonesia),LANGUAGE,LANGUAGE
SM249154,166.696,-15.2786,Kula (Vanuatu),LANGUAGE,LANGUAGE
SM305648,17.6003,43.0303,Kula Norinska,ADM2,DISTRICT
SM255012,125.92,5.8,Kulaman,ETHNICITY,ETHNICITY
SM250391,-3,7,Kulango,ETHNICITY,ETHNICITY
SM13620,23.0152,57.2493,Kulciema pagasts,ADM2,DISTRICT
SM13621,21.9689,56.9679,Kuldiga,ADM2,DISTRICT
SM3952,22,56.8333,Kuldigas novads,ADM1,DISTRICT
SM280390,104.6105,13.786,Kuleaen,ADM2,DISTRICT
SM293768,42.512,55.4348,Kulebaki,ADM2,DISTRICT
SM235402,8.85,9.18,Kulere,LANGUAGE,LANGUAGE
SM235403,18.9055,9.9633,Kulfa,LANGUAGE,LANGUAGE
SM276879,74.8606,33.5882,Kulgam,ADM2,DISTRICT
SM14183,100.6085,5.324,Kulim,ADM2,DISTRICT
SM261401,118.5754,-32.661,Kulin,ADM2,DISTRICT
SM255017,142.24,-36.66,Kulin,ETHNICITY,ETHNICITY
SM235407,-72.0438,-6.1772,Kulina Pano,LANGUAGE,LANGUAGE
SM292842,47.25,42,Kulinskiy rayon,ADM2,DISTRICT
SM235406,123.045,-4.5841,Kulisusu,LANGUAGE,LANGUAGE
SM459397,143.0394,-9.9418,Kulkalgaw Ya,ETHNICITY,ETHNICITY
SM458155,143.5315,-28.3661,Kullilli,ETHNICITY,ETHNICITY
SM276873,77.25,32,Kullu,ADM2,DISTRICT
SM235410,77.3772,31.8339,Kullu Pahari,LANGUAGE,LANGUAGE
SM268902,8.133,47.2876,Kulm,ADM2,DISTRICT
SM305950,69.8086,38.0132,Kulob,ADM2,DISTRICT
SM276711,110.1513,-7.8126,Kulon Progo,ADM2,DISTRICT
SM235411,120.976,23.7562,Kulon-Pazeh,LANGUAGE,LANGUAGE
SM298321,41.0107,38.4971,Kulp,ADM2,DISTRICT
SM230830,145.298,-5.5995,Kulsab,LANGUAGE,LANGUAGE
SM298681,33.0886,39.1054,Kulu,ADM2,DISTRICT
SM14068,-9.009,5.5714,Kulu Shaw Boe District,ADM2,DISTRICT
SM298711,37.6718,38.8824,Kuluncak,ADM2,DISTRICT
SM292971,79,52.5833,Kulundinskiy rayon,ADM2,DISTRICT
SM235413,87.5717,27.1606,Kulung (Nepal),LANGUAGE,LANGUAGE
SM235415,11.0465,9.2082,Kulung (Nigeria),LANGUAGE,LANGUAGE
SM235316,147.536,-6.4259,Kulungtfu-Yuanggeng-Tobo,LANGUAGE,LANGUAGE
SM458629,127.9779,-16.3771,Kuluwarrang,ETHNICITY,ETHNICITY
SM255022,144.55,-5.77,Kuma,ETHNICITY,ETHNICITY
SM255022,144.55,-5.77,Kuma,ETHNICITY,ETHNICITY
SM255022,144.55,-5.77,Kuma,ETHNICITY,ETHNICITY
SM255022,144.55,-5.77,Kuma,ETHNICITY,ETHNICITY
SM255022,142.667,-4.167,Kuma,ETHNICITY,ETHNICITY
SM255022,142.667,-4.167,Kuma,ETHNICITY,ETHNICITY
SM255022,142.667,-4.167,Kuma,ETHNICITY,ETHNICITY
SM255022,142.667,-4.167,Kuma,ETHNICITY,ETHNICITY
SM255022,142,-4,Kuma,ETHNICITY,ETHNICITY
SM255022,142,-4,Kuma,ETHNICITY,ETHNICITY
SM255022,142,-4,Kuma,ETHNICITY,ETHNICITY
SM255022,142,-4,Kuma,ETHNICITY,ETHNICITY
SM255022,142.67,-4.17,Kuma,ETHNICITY,ETHNICITY
SM255022,142.67,-4.17,Kuma,ETHNICITY,ETHNICITY
SM255022,142.67,-4.17,Kuma,ETHNICITY,ETHNICITY
SM255022,142.67,-4.17,Kuma,ETHNICITY,ETHNICITY
SM12994,130.8609,32.306,Kuma-gun,ADM2,DISTRICT
SM279178,139.3726,36.1603,Kumagaya,ADM2,DISTRICT
SM12995,130.6373,30.3887,Kumage-gun (Kagoshima),ADM2,DISTRICT
SM18523,132.0632,33.9132,Kumage-gun (Yamaguchi),ADM2,DISTRICT
SM279641,132.9833,33.6366,Kumakōgen,ADM2,DISTRICT
SM235423,146.57,-7.1135,Kumalu,LANGUAGE,LANGUAGE
SM235420,33.2116,1.7475,Kumam,LANGUAGE,LANGUAGE
SM1892,130.75,32.6167,Kumamoto,ADM1:ADMX,DISTRICT
SM12997,130.6917,32.7973,Kumamoto Shi (2010-),ADM2,DISTRICT
SM235425,144.977,-5.9059,Kuman,LANGUAGE,LANGUAGE
SM255025,145.05,-6.1,Kuman,ETHNICITY,ETHNICITY
SM235429,86.3233,52.8849,Kumandin,DIALECT,LANGUAGE
SM278694,136.0265,33.9229,Kumano,ADM2,DISTRICT
SM1893,21.7728,42.0894,Kumanovo,ADM1,DISTRICT
SM235418,80.0171,29.5622,Kumaoni,LANGUAGE,LANGUAGE
SM235419,87.2699,24.1217,Kumarbhag Paharia,LANGUAGE,LANGUAGE
SM11409,-1.6167,6.6833,Kumasi (2012-),ADM2,DISTRICT
SM235430,12.0416,8.8212,Kumba,LANGUAGE,LANGUAGE
SM235433,152.913,-29.8189,Kumbainggar,LANGUAGE,LANGUAGE
SM255033,152.25,-31.17,Kumbainggiri,ETHNICITY,ETHNICITY
SM255033,152.25,-31.17,Kumbainggiri,ETHNICITY,ETHNICITY
SM255033,152.4457,-30.0876,Kumbainggiri,ETHNICITY,ETHNICITY
SM255033,152.4457,-30.0876,Kumbainggiri,ETHNICITY,ETHNICITY
SM235439,123.154,-5.3449,Kumbewaha,LANGUAGE,LANGUAGE
SM284870,8.5163,11.9006,Kumbotso,ADM2,DISTRICT
SM255036,-2.96,10.75,Kumbu,ETHNICITY,ETHNICITY
SM4369,-1.0584,9.79,Kumbugu,ADM2,DISTRICT
SM12998,133.9416,34.9693,Kume-gun,ADM2,DISTRICT
SM293266,50.1667,58.1667,Kumenskiy rayon,ADM2,DISTRICT
SM16976,55.75,52.75,Kumertau Urban Okrug,ADM2,DISTRICT
SM305895,128.0306,38.5925,Kumgangsan,ADM2,DISTRICT
SM235442,84.1357,27.8297,Kumhali,LANGUAGE,LANGUAGE
SM299308,33.95,1.5,Kumi (2010-),ADM1:ADM2,DISTRICT
SM278692,135.7493,34.8909,Kumiyama,ADM2,DISTRICT
SM296411,15.1465,59.1272,Kumla,ADM2,DISTRICT
SM1896,20.75,60.3333,Kumlinge,ADM1:ADM2,DISTRICT
SM298465,36.4553,36.3651,Kumlu,ADM2,DISTRICT
SM299013,30.3,36.58,Kumluca,ADM2,DISTRICT
SM297729,102.994,17.0921,Kumphawapi,ADM2,DISTRICT
SM305730,15.68,46.0763,Kumrovec,ADM2,DISTRICT
SM298803,37.2761,40.8837,Kumru,ADM2,DISTRICT
SM16977,47.25,43.1667,Kumtorkalinskiy Rayon,ADM2,DISTRICT
SM250376,27,-1,Kumu,ETHNICITY,ETHNICITY
SM235449,147.411,-6.0313,Kumukio,LANGUAGE,LANGUAGE
SM11663,79.2868,19.3565,Kumuram Bheem Asifabad,ADM2,DISTRICT
SM235450,47,43,Kumyk,LANGUAGE,LANGUAGE
SM255041,48.37,42.21,Kumyk,ETHNICITY,ETHNICITY
SM255041,48.37,42.21,Kumyk,ETHNICITY,ETHNICITY
SM255041,48.37,42.21,Kumyk,ETHNICITY,ETHNICITY
SM255041,47.77,43.11,Kumyk,ETHNICITY,ETHNICITY
SM255041,47.77,43.11,Kumyk,ETHNICITY,ETHNICITY
SM255041,47.77,43.11,Kumyk,ETHNICITY,ETHNICITY
SM255041,48,42,Kumyk,ETHNICITY,ETHNICITY
SM255041,48,42,Kumyk,ETHNICITY,ETHNICITY
SM255041,48,42,Kumyk,ETHNICITY,ETHNICITY
SM255041,46.5833,43.25,Kumyk,ETHNICITY,ETHNICITY
SM255041,46.5833,43.25,Kumyk,ETHNICITY,ETHNICITY
SM255041,46.5833,43.25,Kumyk,ETHNICITY,ETHNICITY
SM16978,42.6667,49.8333,Kumylzhenskiy Rayon,ADM2,DISTRICT
SM235451,56.4202,26.1602,Kumzari,LANGUAGE,LANGUAGE
SM294139,31,56.25,Kun'inskiy rayon,ADM2,DISTRICT
SM286713,-79.0072,9.553,Kuna Yala,ADM2,DISTRICT
SM1897,-78.25,9.25,Kuna Yala,ADM1,DISTRICT
SM235457,37.5292,14.5879,Kunama,LANGUAGE,LANGUAGE
SM250392,37,15,Kunama,ETHNICITY,ETHNICITY
SM1898,71.1333,34.95,Kunar,ADM1,DISTRICT
SM292753,61.6667,55.8333,Kunashakskiy rayon,ADM2,DISTRICT
SM235462,133.723,-11.9133,Kunbarlang,LANGUAGE,LANGUAGE
SM458902,133.8939,-11.9322,Kunbarlang,ETHNICITY,ETHNICITY
SM284871,8.27,12.5,Kunchi,ADM2,DISTRICT
SM235469,73.8288,34.5588,Kundal Shahi,LANGUAGE,LANGUAGE
SM458815,133.4295,-12.6788,Kundedjnjenghmi,ETHNICITY,ETHNICITY
SM288620,145.05,-5.9167,Kundiawa-Gembogl,ADM2,DISTRICT
SM458905,132.8746,-12.9259,Kundjeyhmi,ETHNICITY,ETHNICITY
SM235465,76.2015,11.7535,Kunduvadi,LANGUAGE,LANGUAGE
SM260096,68.8439,36.7926,Kunduz,ADM2,DISTRICT
SM1899,68.75,36.75,Kunduz,ADM1,DISTRICT
SM458904,134.3866,-12.398,Kune,ETHNICITY,ETHNICITY
SM1900,13.75,-19.4167,Kunene,ADM1,DISTRICT
SM235478,10.2073,6.5388,Kung,LANGUAGE,LANGUAGE
SM458165,146.3952,-29.1055,Kungakuri,ETHNICITY,ETHNICITY
SM235477,130.881,-12.7,Kungarakany,LANGUAGE,LANGUAGE
SM458780,130.8252,-13.2333,Kungarakany,ETHNICITY,ETHNICITY
SM235480,141.1441,-17.6255,Kunggara,LANGUAGE,LANGUAGE
SM235476,143.222,-24.2182,Kunggari,LANGUAGE,LANGUAGE
SM296326,15.6097,46.6494,Kungota,ADM1:ADM2,DISTRICT
SM296632,12.1506,57.4506,Kungsbacka,ADM2,DISTRICT
SM296563,16.0775,59.4025,Kungsör,ADM2,DISTRICT
SM296579,11.8888,57.897,Kungälv,ADM2,DISTRICT
SM11580,20.592,47.4033,Kunhegyesi Jaras,ADM2,DISTRICT
SM235481,146.874,-8.6637,Kuni,LANGUAGE,LANGUAGE
SM235483,141.365,-6.975,Kuni-Boazi,LANGUAGE,LANGUAGE
SM235486,128.068,26.5911,Kunigami,LANGUAGE,LANGUAGE
SM12999,128.233,26.7325,Kunigami-gun,ADM2,DISTRICT
SM235485,146.908,-8.0937,Kunimaipa,LANGUAGE,LANGUAGE
SM255059,147.53,-8.69,Kunimaipa,ETHNICITY,ETHNICITY
SM276767,108.55,-7,Kuningan,ADM2,DISTRICT
SM279019,131.7316,33.5654,Kunisaki,ADM2,DISTRICT
SM279449,139.4388,35.6863,Kunitachi,ADM2,DISTRICT
SM235488,143.53,-16.6807,Kunjen,LANGUAGE,LANGUAGE
SM458487,139.288,-23.6377,Kunkalanya,ETHNICITY,ETHNICITY
SM269379,102.8185,25.2944,Kunming,ADM2,DISTRICT
SM235493,102.7227,25.0497,Kunming Chinese,DIALECT,LANGUAGE
SM13000,141.5828,40.2672,Kunohe-gun,ADM2,DISTRICT
SM274528,-6.6742,62.3196,Kunoy,ADM2,DISTRICT
SM276113,19.1556,46.9481,Kunszentmiklós,ADM2,DISTRICT
SM276131,20.2518,46.877,Kunszentmárton,ADM2,DISTRICT
SM458169,146.9079,-29.2973,Kuntakuri,ETHNICITY,ETHNICITY
SM295789,44.067,1.6746,Kuntuwaaray,ADM2,DISTRICT
SM458901,134.12,-12.43,Kunwinjku,ETHNICITY,ETHNICITY
SM458901,134.12,-12.43,Kunwinjku,ETHNICITY,ETHNICITY
SM458901,134.12,-12.43,Kunwinjku,ETHNICITY,ETHNICITY
SM458901,134.0414,-12.2677,Kunwinjku,ETHNICITY,ETHNICITY
SM458901,134.0414,-12.2677,Kunwinjku,ETHNICITY,ETHNICITY
SM458901,134.0414,-12.2677,Kunwinjku,ETHNICITY,ETHNICITY
SM458901,133.4827,-12.1735,Kunwinjku,ETHNICITY,ETHNICITY
SM458901,133.4827,-12.1735,Kunwinjku,ETHNICITY,ETHNICITY
SM458901,133.4827,-12.1735,Kunwinjku,ETHNICITY,ETHNICITY
SM235496,12.5197,-3.697,Kunyi,LANGUAGE,LANGUAGE
SM235499,-69,-23,Kunza,LANGUAGE,LANGUAGE
SM255065,-66.45,-20.13,Kunza,ETHNICITY,ETHNICITY
SM255065,-68.13,-22.95,Kunza,ETHNICITY,ETHNICITY
SM235500,15.4843,7.9777,Kuo,LANGUAGE,LANGUAGE
SM346766,27.7012,62.9893,Kuopio,ADM2:ADM3,DISTRICT
SM235501,151.537,-3.0695,Kuot,LANGUAGE,LANGUAGE
SM235502,6.3581,8.4844,Kupa,LANGUAGE,LANGUAGE
SM276509,123.8333,-9.9167,Kupang,ADM2,DISTRICT
SM235503,123.63,-10.2526,Kupang Malay,LANGUAGE,LANGUAGE
SM255066,124.38,-9.65,Kupangese,ETHNICITY,ETHNICITY
SM235504,79.5153,16.4708,Kupia,LANGUAGE,LANGUAGE
SM18239,37.6368,49.675,Kupianska Miskrada,ADM2,DISTRICT
SM293865,77.5,54.5,Kupinskiy rayon,ADM2,DISTRICT
SM281344,24.9667,55.8333,Kupiškio,ADM2,DISTRICT
SM13622,27.4884,57.2299,Kupravas pagasts,ADM2,DISTRICT
SM313181,17.1575,44.0803,Kupres,ADM2:ADM3,DISTRICT
SM235508,34.5991,1.332,Kupsabiny,LANGUAGE,LANGUAGE
SM250964,35,1,Kupsabiny,ETHNICITY,ETHNICITY
SM276880,74.2661,34.5319,Kupwara,ADM2,DISTRICT
SM375440,74.9239,35.3135,Kupwara (Gilgit Wazarat),ADM2:ADM3,DISTRICT
SM235535,131.986,-5.3408,Kur,LANGUAGE,LANGUAGE
SM292972,82.4167,51.4167,Kur'inskiy rayon,ADM2,DISTRICT
SM284872,8.4868,11.7878,Kura,ADM2,DISTRICT
SM235510,1.4951,9.434,Kura Ede Nago,LANGUAGE,LANGUAGE
SM293438,94,53.75,Kuraginskiy rayon,ADM2,DISTRICT
SM292843,47.75,41.5833,Kurakhskiy rayon,ADM2,DISTRICT
SM235511,7.9351,10.4766,Kurama,LANGUAGE,LANGUAGE
SM250395,7.26,13.26,Kurama,ETHNICITY,ETHNICITY
SM250395,7.26,13.26,Kurama,ETHNICITY,ETHNICITY
SM250395,9,10,Kurama,ETHNICITY,ETHNICITY
SM250395,9,10,Kurama,ETHNICITY,ETHNICITY
SM259987,70.6846,35.8505,Kuran Wa Munjan,ADM2,DISTRICT
SM235512,-10.6122,9.4933,Kuranko,LANGUAGE,LANGUAGE
SM279021,133.7483,34.5547,Kurashiki,ADM2,DISTRICT
SM13001,130.6802,33.7614,Kuraten-gun,ADM2,DISTRICT
SM279480,133.7458,35.3911,Kurayoshi,ADM2,DISTRICT
SM260500,19.7167,41.6333,Kurbinit,ADM2,DISTRICT
SM260500,19.7167,41.6333,Kurbinit,ADM2,DISTRICT
SM260500,19.7073,41.6222,Kurbinit,ADM2,DISTRICT
SM260500,19.7073,41.6222,Kurbinit,ADM2,DISTRICT
SM16979,46.1031,43.1996,Kurchaloyevskiy rayon,ADM2,DISTRICT
SM16980,35.6667,51.65,Kurchatovskiy Rayon,ADM2,DISTRICT
SM255070,44.8,36.32,Kurd,ETHNICITY,ETHNICITY
SM255070,44.8,36.32,Kurd,ETHNICITY,ETHNICITY
SM255070,44.8,36.32,Kurd,ETHNICITY,ETHNICITY
SM255070,44.8,36.32,Kurd,ETHNICITY,ETHNICITY
SM255070,44.8,36.32,Kurd,ETHNICITY,ETHNICITY
SM255070,37.19,38.76,Kurd,ETHNICITY,ETHNICITY
SM255070,37.19,38.76,Kurd,ETHNICITY,ETHNICITY
SM255070,37.19,38.76,Kurd,ETHNICITY,ETHNICITY
SM255070,37.19,38.76,Kurd,ETHNICITY,ETHNICITY
SM255070,37.19,38.76,Kurd,ETHNICITY,ETHNICITY
SM255070,45.63,40.31,Kurd,ETHNICITY,ETHNICITY
SM255070,45.63,40.31,Kurd,ETHNICITY,ETHNICITY
SM255070,45.63,40.31,Kurd,ETHNICITY,ETHNICITY
SM255070,45.63,40.31,Kurd,ETHNICITY,ETHNICITY
SM255070,45.63,40.31,Kurd,ETHNICITY,ETHNICITY
SM255070,45,37,Kurd,ETHNICITY,ETHNICITY
SM255070,45,37,Kurd,ETHNICITY,ETHNICITY
SM255070,45,37,Kurd,ETHNICITY,ETHNICITY
SM255070,45,37,Kurd,ETHNICITY,ETHNICITY
SM255070,45,37,Kurd,ETHNICITY,ETHNICITY
SM255070,44,32,Kurd,ETHNICITY,ETHNICITY
SM255070,44,32,Kurd,ETHNICITY,ETHNICITY
SM255070,44,32,Kurd,ETHNICITY,ETHNICITY
SM255070,44,32,Kurd,ETHNICITY,ETHNICITY
SM255070,44,32,Kurd,ETHNICITY,ETHNICITY
SM255070,44.5,36.5,Kurd,ETHNICITY,ETHNICITY
SM255070,44.5,36.5,Kurd,ETHNICITY,ETHNICITY
SM255070,44.5,36.5,Kurd,ETHNICITY,ETHNICITY
SM255070,44.5,36.5,Kurd,ETHNICITY,ETHNICITY
SM255070,44.5,36.5,Kurd,ETHNICITY,ETHNICITY
SM255070,39.42,44.58,Kurd,ETHNICITY,ETHNICITY
SM255070,39.42,44.58,Kurd,ETHNICITY,ETHNICITY
SM255070,39.42,44.58,Kurd,ETHNICITY,ETHNICITY
SM255070,39.42,44.58,Kurd,ETHNICITY,ETHNICITY
SM255070,39.42,44.58,Kurd,ETHNICITY,ETHNICITY
SM235516,44.2859,36.8295,Kurdish Sign Language,LANGUAGE,LANGUAGE
SM278128,132.6291,34.2245,Kure,ADM2,DISTRICT
SM458175,142.4217,-34.2314,Kureinji,ETHNICITY,ETHNICITY
SM284911,7.4626,12.7099,Kurfi,ADM2,DISTRICT
SM1901,64,55.5,Kurgan,ADM1,DISTRICT
SM293376,40.5156,44.99,Kurganinskiy rayon,ADM2,DISTRICT
SM235521,34.5049,-1.5064,Kuria,LANGUAGE,LANGUAGE
SM13313,173.3888,0.2652,Kuria,ADM2,DISTRICT
SM250898,34.16,-1.33,Kuria,ETHNICITY,ETHNICITY
SM250898,32.68,0.3,Kuria,ETHNICITY,ETHNICITY
SM5284,34.5957,-1.2437,Kuria District,ADM2,DISTRICT
SM235520,75.5203,11.8226,Kurichiya,LANGUAGE,LANGUAGE
SM262278,89.6667,25.75,Kurigram,ADM2,DISTRICT
SM278755,140.9403,38.809,Kurihara,ADM2,DISTRICT
SM235526,154.7224,49.4016,Kuril Ainu,LANGUAGE,LANGUAGE
SM16981,150,46,Kurilsky District,ADM2,DISTRICT
SM459079,151.3342,-33.4065,Kuringgai,ETHNICITY,ETHNICITY
SM13623,21.8543,56.9097,Kurmales pagasts,ADM2,DISTRICT
SM279943,49,47.5,Kurmangazinskiy,ADM2,DISTRICT
SM13624,24.8415,56.4632,Kurmenes pagasts,ADM2,DISTRICT
SM285257,10.587,7.2853,Kurmi,ADM2,DISTRICT
SM235530,7.9167,9.6,Kurmin Dangana,LANGUAGE,LANGUAGE
SM235528,87.3205,26.9175,Kurmukar,LANGUAGE,LANGUAGE
SM255080,148.14,-37.51,Kurnai,ETHNICITY,ETHNICITY
SM255080,148.14,-37.51,Kurnai,ETHNICITY,ETHNICITY
SM255080,148.14,-37.51,Kurnai,ETHNICITY,ETHNICITY
SM255080,147.42,-37.59,Kurnai,ETHNICITY,ETHNICITY
SM255080,147.42,-37.59,Kurnai,ETHNICITY,ETHNICITY
SM255080,147.42,-37.59,Kurnai,ETHNICITY,ETHNICITY
SM255080,146.9726,-37.9751,Kurnai,ETHNICITY,ETHNICITY
SM255080,146.9726,-37.9751,Kurnai,ETHNICITY,ETHNICITY
SM255080,146.9726,-37.9751,Kurnai,ETHNICITY,ETHNICITY
SM255081,145.03,-28.04,Kurnandaburi,ETHNICITY,ETHNICITY
SM255081,145.03,-28.04,Kurnandaburi,ETHNICITY,ETHNICITY
SM255081,142.5939,-26.6347,Kurnandaburi,ETHNICITY,ETHNICITY
SM255081,142.5939,-26.6347,Kurnandaburi,ETHNICITY,ETHNICITY
SM277028,78.3333,15.5833,Kurnool,ADM2,DISTRICT
SM279508,137.6216,36.7711,Kurobe,ADM2,DISTRICT
SM278970,140.7047,40.6171,Kuroishi,ADM2,DISTRICT
SM13002,140.8717,38.4323,Kurokawa-gun,ADM2,DISTRICT
SM16982,29.8739,60.176,Kurortnyy Rayon,ADM2,DISTRICT
SM375404,70.3401,33.7102,Kurram,ADM2:ADM3,DISTRICT
SM235533,117.51,-22.2134,Kurrama,LANGUAGE,LANGUAGE
SM13203,84.25,48.6667,Kurshim Audany (excl. Marqakol),ADM2,DISTRICT
SM13625,22.3894,56.5144,Kursisu pagasts,ADM2,DISTRICT
SM1902,36,51.5,Kursk,ADM1,DISTRICT
SM16983,36.1667,51.75,Kurskiy Rayon,ADM2,DISTRICT
SM298878,41.7008,37.9244,Kurtalan,ADM2,DISTRICT
SM293494,64.5,54.9167,Kurtamyshskiy rayon,ADM2,DISTRICT
SM255086,154.75,-5.25,Kurtatchi,ETHNICITY,ETHNICITY
SM255086,154.75,-5.25,Kurtatchi,ETHNICITY,ETHNICITY
SM255086,154.91,-5.6,Kurtatchi,ETHNICITY,ETHNICITY
SM255086,154.91,-5.6,Kurtatchi,ETHNICITY,ETHNICITY
SM235539,147.036,-2.0119,Kurti,LANGUAGE,LANGUAGE
SM13204,75.5,44.8333,Kurti Audany,ADM2,DISTRICT
SM458491,141.4176,-16.7473,Kurtjar,ETHNICITY,ETHNICITY
SM235537,90.9822,27.5365,Kurtokha,LANGUAGE,LANGUAGE
SM298181,32.7194,41.8288,Kurucaşile,ADM2,DISTRICT
SM235547,137.018,-1.8444,Kurudu,LANGUAGE,LANGUAGE
SM235544,86.4657,24.4644,Kurukh,LANGUAGE,LANGUAGE
SM276846,76.75,30,Kurukshetra,ADM2,DISTRICT
SM255092,77.21,11.9,Kurumba Jenu,ETHNICITY,ETHNICITY
SM255091,76.51,11.63,Kurumba tribe,ETHNICITY,ETHNICITY
SM233972,76.5256,11.3824,"Kurumba, Kannada",LANGUAGE,LANGUAGE
SM279685,130.5655,33.3036,Kurume,ADM2,DISTRICT
SM295112,110.3,54.3,Kurumkanskiy rayon,ADM2,DISTRICT
SM1903,80.25,7.75,Kurunegala,ADM1:ADM2,DISTRICT
SM11664,93.3924,27.9998,Kurung Kumey (2015-),ADM2,DISTRICT
SM235543,86.4657,24.4644,Kurux,FAMILY,LANGUAGE
SM255098,-53.37,-7.71,Kuruya,ETHNICITY,ETHNICITY
SM235551,-52.2303,-3.0956,Kuruáya,LANGUAGE,LANGUAGE
SM298284,33.2735,40.8474,Kurşunlu,ADM2,DISTRICT
SM235555,-0.3876,10.9703,Kusaal,LANGUAGE,LANGUAGE
SM250903,0,11,Kusaal,ETHNICITY,ETHNICITY
SM284912,7.9786,12.4576,Kusada,ADM2,DISTRICT
SM295339,157.4392,-8.0441,Kusaghe,ADM2,DISTRICT
SM235556,157.463,-8.0406,Kusaghe-Njela,LANGUAGE,LANGUAGE
SM286647,-81.8962,9.1712,Kusapín,ADM2,DISTRICT
SM279225,135.9384,35.0276,Kusatsu,ADM2,DISTRICT
SM304847,43.472,16.1709,Kushar,ADM2,DISTRICT
SM293377,39.5682,46.5806,Kushchevskiy rayon,ADM2,DISTRICT
SM235561,10.9396,9.4073,Kushi,LANGUAGE,LANGUAGE
SM278761,131.2649,31.4857,Kushima,ADM2,DISTRICT
SM277308,83.8889,26.7403,Kushinagar,ADM2,DISTRICT
SM278290,144.5318,43.0303,Kushiro,ADM2,DISTRICT
SM278291,144.0665,43.1591,Kushiro City,ADM2,DISTRICT
SM260011,62.2399,35.0044,Kushk,ADM2,DISTRICT
SM260012,62.6287,34.8857,Kushki Kuhna,ADM2,DISTRICT
SM294272,55.25,55,Kushnarenkovskiy rayon,ADM2,DISTRICT
SM262264,88.9167,23.8333,Kushtia,ADM2,DISTRICT
SM299206,39.5068,-6.3371,Kusini,ADM2,DISTRICT
SM292754,59.5833,55.4167,Kusinskiy rayon,ADM2,DISTRICT
SM255100,-161.55,61.01,Kuskowagmut,ETHNICITY,ETHNICITY
SM304917,43.6833,14.5474,Kusmah,ADM2,DISTRICT
SM13205,63.4167,53.3333,Kustanayskiy Rayon (excl. Kostanay city and Rudnyy),ADM2,DISTRICT
SM235563,-53.15,-14.9,Kustenau,LANGUAGE,LANGUAGE
SM235566,25.2923,-3.6146,Kusu,LANGUAGE,LANGUAGE
SM458000,131.1849,33.2508,Kusu District,ADM2,DISTRICT
SM297507,104.2668,17.354,Kusuman,ADM2,DISTRICT
SM235564,82.6,28.33,Kusunda,LANGUAGE,LANGUAGE
SM450423,82.543,28.0725,Kusunda,ETHNICITY,ETHNICITY
SM289186,125.2292,39.9614,Kusŏng,ADM2,DISTRICT
SM297508,103.8147,17.0821,Kut Bak,ADM2,DISTRICT
SM297730,102.5408,17.4401,Kut Chap,ADM2,DISTRICT
SM297762,104.3054,16.0462,Kut Chum,ADM2,DISTRICT
SM297691,105.0407,15.8191,Kut Khao Pun,ADM2,DISTRICT
SM276428,116.9814,-0.4402,Kutai Kartanegara,ADM2,DISTRICT
SM276429,117.8311,1.0377,Kutai Timur,ADM2,DISTRICT
SM274800,42.6,42.2667,Kutaisi,ADM2,DISTRICT
SM235569,84.93,28.3808,Kutang Ghale,LANGUAGE,LANGUAGE
SM255107,-144.09,69.29,Kutchin,ETHNICITY,ETHNICITY
SM255107,-144.09,69.29,Kutchin,ETHNICITY,ETHNICITY
SM255107,-144.09,69.29,Kutchin,ETHNICITY,ETHNICITY
SM255107,-144.09,69.29,Kutchin,ETHNICITY,ETHNICITY
SM255107,-140,67,Kutchin,ETHNICITY,ETHNICITY
SM255107,-140,67,Kutchin,ETHNICITY,ETHNICITY
SM255107,-140,67,Kutchin,ETHNICITY,ETHNICITY
SM255107,-140,67,Kutchin,ETHNICITY,ETHNICITY
SM255107,-135,66,Kutchin,ETHNICITY,ETHNICITY
SM255107,-135,66,Kutchin,ETHNICITY,ETHNICITY
SM255107,-135,66,Kutchin,ETHNICITY,ETHNICITY
SM255107,-135,66,Kutchin,ETHNICITY,ETHNICITY
SM255107,-135.23,65.94,Kutchin,ETHNICITY,ETHNICITY
SM255107,-135.23,65.94,Kutchin,ETHNICITY,ETHNICITY
SM255107,-135.23,65.94,Kutchin,ETHNICITY,ETHNICITY
SM255107,-135.23,65.94,Kutchin,ETHNICITY,ETHNICITY
SM235572,-115.065,48.7892,Kutenai,LANGUAGE,LANGUAGE
SM255108,-112.58,49.71,Kutenai,ETHNICITY,ETHNICITY
SM255108,-112.58,49.71,Kutenai,ETHNICITY,ETHNICITY
SM255108,-112.58,49.71,Kutenai,ETHNICITY,ETHNICITY
SM255108,-112.58,49.71,Kutenai,ETHNICITY,ETHNICITY
SM255108,-112.58,49.71,Kutenai,ETHNICITY,ETHNICITY
SM255108,-117,50,Kutenai,ETHNICITY,ETHNICITY
SM255108,-117,50,Kutenai,ETHNICITY,ETHNICITY
SM255108,-117,50,Kutenai,ETHNICITY,ETHNICITY
SM255108,-117,50,Kutenai,ETHNICITY,ETHNICITY
SM255108,-117,50,Kutenai,ETHNICITY,ETHNICITY
SM255108,-114.08,47.48,Kutenai,ETHNICITY,ETHNICITY
SM255108,-114.08,47.48,Kutenai,ETHNICITY,ETHNICITY
SM255108,-114.08,47.48,Kutenai,ETHNICITY,ETHNICITY
SM255108,-114.08,47.48,Kutenai,ETHNICITY,ETHNICITY
SM255108,-114.08,47.48,Kutenai,ETHNICITY,ETHNICITY
SM255108,-116.4,49,Kutenai,ETHNICITY,ETHNICITY
SM255108,-116.4,49,Kutenai,ETHNICITY,ETHNICITY
SM255108,-116.4,49,Kutenai,ETHNICITY,ETHNICITY
SM255108,-116.4,49,Kutenai,ETHNICITY,ETHNICITY
SM255108,-116.4,49,Kutenai,ETHNICITY,ETHNICITY
SM255108,-116.67,49,Kutenai,ETHNICITY,ETHNICITY
SM255108,-116.67,49,Kutenai,ETHNICITY,ETHNICITY
SM255108,-116.67,49,Kutenai,ETHNICITY,ETHNICITY
SM255108,-116.67,49,Kutenai,ETHNICITY,ETHNICITY
SM255108,-116.67,49,Kutenai,ETHNICITY,ETHNICITY
SM235571,10.0916,7.0748,Kutep,LANGUAGE,LANGUAGE
SM235574,140.958,-17.4566,Kuthant,LANGUAGE,LANGUAGE
SM275992,16.75,45.5,Kutina,ADM2,DISTRICT
SM459449,143.221,-17.312,Kutjal,ETHNICITY,ETHNICITY
SM275963,17.8841,45.4256,Kutjevo,ADM2,DISTRICT
SM288884,19.3971,52.2514,Kutno,ADM2,DISTRICT
SM271190,15.25,49.8333,Kutná Hora,ADM2,DISTRICT
SM255111,22,-4,Kutsu,ETHNICITY,ETHNICITY
SM235577,11.4953,10.7074,Kutto,LANGUAGE,LANGUAGE
SM14938,153.456,5.453,Kuttu Municipality,ADM2,DISTRICT
SM235580,37.8623,-7.2379,Kutu,LANGUAGE,LANGUAGE
SM255113,38.01,-8.39,Kutu,ETHNICITY,ETHNICITY
SM255114,143,-6,Kutubu,ETHNICITY,ETHNICITY
SM295238,24.6726,14.2111,Kutum,ADM2,DISTRICT
SM235579,7.9652,10.1485,Kuturmi,LANGUAGE,LANGUAGE
SM459425,141.787,-14.909,Kuuk Thaayorre,ETHNICITY,ETHNICITY
SM459290,141.6462,-14.9526,Kuuk Thaayunth,ETHNICITY,ETHNICITY
SM459274,141.7316,-14.8772,Kuuk Yak,ETHNICITY,ETHNICITY
SM235587,141.789,-14.8106,Kuuk-Yak,LANGUAGE,LANGUAGE
SM459242,142.8797,-12.1882,Kuuku I'yu,ETHNICITY,ETHNICITY
SM459295,143.0339,-12.5417,Kuuku Ya'u,ETHNICITY,ETHNICITY
SM459244,143.4315,-13.9216,Kuuku Yani,ETHNICITY,ETHNICITY
SM235585,143.165,-12.4947,Kuuku-Ya'u,LANGUAGE,LANGUAGE
SM235588,143.5365,-24.7511,Kuungkari of Barcoo River,LANGUAGE,LANGUAGE
SM274085,25.5964,59.4786,Kuusalu,ADM2,DISTRICT
SM346834,23.785,62.6177,Kuusiokunnat,ADM2:ADM3,DISTRICT
SM255115,121.38,24.58,Kuvalan,ETHNICITY,ETHNICITY
SM235590,12.7148,-15.3065,Kuvale,LANGUAGE,LANGUAGE
SM260321,16.3148,-14.2769,Kuvango,ADM2,DISTRICT
SM235592,83.7552,18.8832,Kuvi,LANGUAGE,LANGUAGE
SM294758,34.1667,57,Kuvshinovskiy rayon,ADM2,DISTRICT
SM235594,-9.9765,7.5489,Kuwaa,LANGUAGE,LANGUAGE
SM235593,-16.7483,12.4826,Kuwaataay,LANGUAGE,LANGUAGE
SM454399,47.583,29.4165,Kuwaiti Arab,ETHNICITY,ETHNICITY
SM235596,47.9812,29.3767,Kuwaiti Sign Language,LANGUAGE,LANGUAGE
SM278695,136.6835,35.0618,Kuwana,ADM2,DISTRICT
SM458042,120.3454,-28.182,Kuwarra,ETHNICITY,ETHNICITY
SM458126,132.3366,-16.7209,Kuwarranyji,ETHNICITY,ETHNICITY
SM458714,130.4251,-12.8574,Kuwema,ETHNICITY,ETHNICITY
SM235606,104.911,14.6698,Kuy,LANGUAGE,LANGUAGE
SM255118,137.64,-30.82,Kuyani,ETHNICITY,ETHNICITY
SM255118,137.64,-30.82,Kuyani,ETHNICITY,ETHNICITY
SM255118,137.9422,-29.9993,Kuyani,ETHNICITY,ETHNICITY
SM255118,137.9422,-29.9993,Kuyani,ETHNICITY,ETHNICITY
SM13206,66.5,53,Kuybyshev Audany,ADM2,DISTRICT
SM16984,78.25,55.5,Kuybyshevskiy Rayon,ADM2,DISTRICT
SM293071,33.9167,54.0833,Kuybyshevskiy rayon,ADM2,DISTRICT
SM292893,101.6667,54.5,Kuytunskiy rayon,ADM2,DISTRICT
SM235605,-64,-11.75,Kuyubi,LANGUAGE,LANGUAGE
SM298147,28.4643,37.9234,Kuyucak,ADM2,DISTRICT
SM255119,-119,40,Kuyuidokado,ETHNICITY,ETHNICITY
SM255119,-119,40,Kuyuidokado,ETHNICITY,ETHNICITY
SM255119,-119,40,Kuyuidokado,ETHNICITY,ETHNICITY
SM255119,-119.6,40,Kuyuidokado,ETHNICITY,ETHNICITY
SM255119,-119.6,40,Kuyuidokado,ETHNICITY,ETHNICITY
SM255119,-119.6,40,Kuyuidokado,ETHNICITY,ETHNICITY
SM255119,-119.5,40,Kuyuidokado,ETHNICITY,ETHNICITY
SM255119,-119.5,40,Kuyuidokado,ETHNICITY,ETHNICITY
SM255119,-119.5,40,Kuyuidokado,ETHNICITY,ETHNICITY
SM260118,70.6068,34.5593,Kuz Kunar,ADM2,DISTRICT
SM293630,49,56.75,Kuzhenerskiy rayon,ADM2,DISTRICT
SM296360,16.0953,46.8411,Kuzma,ADM1:ADM2,DISTRICT
SM294005,46.5833,53.0833,Kuznetskiy rayon,ADM2,DISTRICT
SM457942,19.9535,40.8249,Kuçovë Municipality (2015-),ADM2,DISTRICT
SM260468,19.9167,40.8333,Kuçovës District (pre-2015),ADM2,DISTRICT
SM298146,27.2697,37.8499,Kuşadası,ADM2,DISTRICT
SM286382,6.1434,60.3698,Kvam,ADM2,DISTRICT
SM293969,59.5,52.1667,Kvarkenskiy rayon,ADM2,DISTRICT
SM1906,44.5167,41.5167,Kvemo Kartli,ADM1,DISTRICT
SM235608,29.5434,69.3772,Kven Finnish,LANGUAGE,LANGUAGE
SM286260,6.7145,58.2389,Kvinesdal,ADM2,DISTRICT
SM286383,6.0123,59.9842,Kvinnherad,ADM2,DISTRICT
SM286220,8.4946,59.4019,Kviteseid,ADM2,DISTRICT
SM286131,5.4054,59.0647,Kvitsøy,ADM2,DISTRICT
SM286225,16.1729,68.772,Kvæfjord,ADM2,DISTRICT
SM286226,22.0494,69.9389,Kvænangen,ADM2,DISTRICT
SM274535,-7.0464,62.1301,Kvívík,ADM2,DISTRICT
SM235615,35.1828,-4.0074,Kw'adza,LANGUAGE,LANGUAGE
SM235612,10.3453,4.8827,Kwa',LANGUAGE,LANGUAGE
SM1911,30,-29,KwaZulu-Natal,ADM1,DISTRICT
SM235613,11.0192,10.4141,Kwaami,LANGUAGE,LANGUAGE
SM274902,-0.8333,6.0833,Kwabibirem,ADM2,DISTRICT
SM274844,-1.5667,6.7833,Kwabre,ADM2,DISTRICT
SM235614,12.1147,-15.8745,Kwadi,LANGUAGE,LANGUAGE
SM250398,12.28,-15.37,Kwadi,ETHNICITY,ETHNICITY
SM255121,0.1,6.82,Kwahu,ETHNICITY,ETHNICITY
SM11410,-0.3653,6.8956,Kwahu Afram Plains South,ADM2,DISTRICT
SM11411,-0.75,6.6667,Kwahu East,ADM2,DISTRICT
SM11412,-0.0794,7.0445,Kwahu North,ADM2,DISTRICT
SM11413,-0.648,6.6438,Kwahu South (2008-),ADM2,DISTRICT
SM274904,-0.7667,6.55,Kwahu West,ADM2,DISTRICT
SM1907,114.1,22.3529,Kwai Tsing,ADM1,DISTRICT
SM289166,125.0169,38.4472,Kwail,ADM2,DISTRICT
SM295429,160.7939,-8.8428,Kwaimela - Radefasu,ADM2,DISTRICT
SM235617,160.965,-8.9374,Kwaio,LANGUAGE,LANGUAGE
SM255122,161,-9,Kwaio,ETHNICITY,ETHNICITY
SM235621,11.06,6.6504,Kwaja-Ndaktup,LANGUAGE,LANGUAGE
SM4082,167.308,9.182,Kwajalein Atoll,ADM1,DISTRICT
SM235623,11.1087,6.6652,Kwak,LANGUAGE,LANGUAGE
SM235628,-127.308,50.9269,Kwak'wala,LANGUAGE,LANGUAGE
SM296054,-55.3431,5.2472,Kwakoegron,ADM2,DISTRICT
SM289187,125.0833,39.6667,Kwaksan,ADM2,DISTRICT
SM235625,13.6446,4.3439,Kwakum,LANGUAGE,LANGUAGE
SM255123,-126.65,51.19,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-126.65,51.19,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-126.65,51.19,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-126.65,51.19,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-127.61,50.65,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-127.61,50.65,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-127.61,50.65,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-127.61,50.65,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-127.49,51,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-127.49,51,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-127.49,51,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-127.49,51,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-126.67,50.42,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-126.67,50.42,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-126.67,50.42,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-126.67,50.42,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-127,50.86,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-127,50.86,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-127,50.86,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM255123,-127,50.86,Kwakwakaʼwakw,ETHNICITY,ETHNICITY
SM1908,39.2,-4.1333,Kwale,ADM1:ADM2,DISTRICT
SM255125,-122.78,46.53,Kwalhiokwa,ETHNICITY,ETHNICITY
SM235633,-123.2093,46.112,Kwalhioqua-Clatskanie,LANGUAGE,LANGUAGE
SM284743,6.9678,8.7356,Kwali,ADM2,DISTRICT
SM296707,31.2996,-26.481,Kwaluseni,ADM2,DISTRICT
SM255127,15.88,-16.93,Kwamatwi,ETHNICITY,ETHNICITY
SM235637,15.7294,-18.0339,Kwambi,LANGUAGE,LANGUAGE
SM235638,169.442,-19.5954,Kwamera,LANGUAGE,LANGUAGE
SM235636,27.4633,-1.5371,Kwami,LANGUAGE,LANGUAGE
SM284753,11.2099,10.4931,Kwami,ADM2,DISTRICT
SM235640,141.889,-3.4491,Kwamtim One,LANGUAGE,LANGUAGE
SM285340,9.4702,6.801,Kwande,ADM2,DISTRICT
SM255128,24.29,-15.85,Kwandi,ETHNICITY,ETHNICITY
SM235654,13.1192,-13.8015,Kwandu,LANGUAGE,LANGUAGE
SM235650,16.5129,10.1181,Kwang,LANGUAGE,LANGUAGE
SM235649,142.711,-3.7426,Kwanga,LANGUAGE,LANGUAGE
SM255129,25.31,-15.49,Kwanga,ETHNICITY,ETHNICITY
SM235644,19.256,-17.9026,Kwangali,LANGUAGE,LANGUAGE
SM250399,21.23,-16.83,Kwangali,ETHNICITY,ETHNICITY
SM250399,13.16,-18.36,Kwangali,ETHNICITY,ETHNICITY
SM250399,18.83,-16.52,Kwangali,ETHNICITY,ETHNICITY
SM1909,17.832,-6.542,Kwango,ADM1:ADM2,DISTRICT
SM255130,20.11,-6.39,Kwango River Groups,ETHNICITY,ETHNICITY
SM235647,11.6931,6.3029,Kwanja,LANGUAGE,LANGUAGE
SM255132,14.39,-17.09,Kwankhala,ETHNICITY,ETHNICITY
SM255133,15.68,-17.55,Kwanyama,ETHNICITY,ETHNICITY
SM1910,5,8.5,Kwara (1991-),ADM1,DISTRICT
SM235655,160.804,-8.7578,Kwara'ae,LANGUAGE,LANGUAGE
SM285221,5.2882,13.1698,Kware,ADM2,DISTRICT
SM255134,143.08,-7.24,Kware,ETHNICITY,ETHNICITY
SM295430,160.9352,-9.1257,Kwarekwareo,ADM2,DISTRICT
SM235657,10.0812,2.3935,Kwasio,LANGUAGE,LANGUAGE
SM235661,-115.386,30.049,Kwatl,LANGUAGE,LANGUAGE
SM235659,145.629,-5.538,Kwato,LANGUAGE,LANGUAGE
SM296061,-55.2734,5.8687,Kwatta,ADM2,DISTRICT
SM235664,33.71,-1.8074,Kwaya,LANGUAGE,LANGUAGE
SM285370,11.9909,10.4432,Kwaya Kusar,ADM2,DISTRICT
SM235667,-61.1833,-11.6667,Kwaza,LANGUAGE,LANGUAGE
SM299288,34.5746,1.3773,Kween,ADM1:ADM2,DISTRICT
SM235668,36.1702,5.4042,Kwegu,LANGUAGE,LANGUAGE
SM305225,29.5,-18.75,Kwekwe,ADM2,DISTRICT
SM250402,27.12,-23.68,Kwena,ETHNICITY,ETHNICITY
SM1912,25,-24,Kweneng,ADM1,DISTRICT
SM268550,25.6081,-23.7909,Kweneng North,ADM2,DISTRICT
SM268551,24.3533,-23.8888,Kweneng South,ADM2,DISTRICT
SM235678,140.012,-5.0201,Kwer,LANGUAGE,LANGUAGE
SM240998,138.486,-2.2137,Kwerba,LANGUAGE,LANGUAGE
SM255138,138,-2,Kwerba,ETHNICITY,ETHNICITY
SM235676,138.436,-2.5646,Kwerba Mamberamo,LANGUAGE,LANGUAGE
SM235674,38.4449,-6.5067,Kwere,LANGUAGE,LANGUAGE
SM250403,38.48,-6.18,Kwere,ETHNICITY,ETHNICITY
SM250403,38.48,-6.18,Kwere,ETHNICITY,ETHNICITY
SM250403,39,-7,Kwere,ETHNICITY,ETHNICITY
SM250403,39,-7,Kwere,ETHNICITY,ETHNICITY
SM235677,138.135,-2.9915,Kwerisa,LANGUAGE,LANGUAGE
SM235681,18.5759,-5.6045,Kwese,LANGUAGE,LANGUAGE
SM235682,138.853,-2.0362,Kwesten,LANGUAGE,LANGUAGE
SM288715,19.0311,53.7469,Kwidzyn,ADM2,DISTRICT
SM1913,18.8167,-5.0333,Kwilu,ADM1:ADM2,DISTRICT
SM299129,33.36,-3.062,Kwimba,ADM2,DISTRICT
SM261402,115.8279,-32.2339,Kwinana,ADM2,DISTRICT
SM235686,126.562,-14.2515,Kwini,LANGUAGE,LANGUAGE
SM235687,139.5137,-2.3038,Kwinsu,LANGUAGE,LANGUAGE
SM235688,-56.1266,5.1323,Kwinti,LANGUAGE,LANGUAGE
SM255139,12.36,-14.31,Kwisi,ETHNICITY,ETHNICITY
SM235691,142.664,-4.0872,Kwoma,LANGUAGE,LANGUAGE
SM235693,142.8428,-4.0723,Kwoma-Manambu Pidgin,LANGUAGE,LANGUAGE
SM240996,141.488,-3.5072,Kwomtari,LANGUAGE,LANGUAGE
SM1914,114.2258,22.3133,Kwun Tong,ADM1,DISTRICT
SM255140,20.56,-20.23,Kx'aoll'aea,ETHNICITY,ETHNICITY
SM255140,20.56,-20.23,Kx'aoll'aea,ETHNICITY,ETHNICITY
SM255140,20.5835,-21.3335,Kx'aoll'aea,ETHNICITY,ETHNICITY
SM255140,20.5835,-21.3335,Kx'aoll'aea,ETHNICITY,ETHNICITY
SM235700,22.5998,-16.5905,Kxoe,LANGUAGE,LANGUAGE
SM235703,11.2785,9.5152,Kyak,LANGUAGE,LANGUAGE
SM235704,144.119,-5.5246,Kyaka,LANGUAGE,LANGUAGE
SM299317,31.0106,0.4292,Kyaka,ADM1:ADM2,DISTRICT
SM255141,143.97,-5.93,Kyaka Enga,ETHNICITY,ETHNICITY
SM255141,143.75,-5.39,Kyaka Enga,ETHNICITY,ETHNICITY
SM295113,106.5,50.5,Kyakhtinskiy rayon,ADM2,DISTRICT
SM4788,31.6461,1.1237,Kyankwanzi,ADM1:ADM2,DISTRICT
SM15090,97.0074,22.898,Kyaukme District (excl. Mongmit),ADM2,DISTRICT
SM283514,96.2468,21.605,Kyaukse,ADM2,DISTRICT
SM283466,94.0311,19.9177,Kyaunkpyu,ADM2,DISTRICT
SM299107,33.8696,-9.5469,Kyela,ADM2,DISTRICT
SM235709,143.855,-4.6696,Kyenele,LANGUAGE,LANGUAGE
SM235708,3.5865,11.5953,Kyenga,LANGUAGE,LANGUAGE
SM299318,30.6599,0.657,Kyenjojo (2009-),ADM1:ADM2,DISTRICT
SM235710,85.4257,28.2922,Kyerung,LANGUAGE,LANGUAGE
SM299230,30.7956,-1.2604,Kyerwa,ADM2,DISTRICT
SM274393,26.9167,60.8333,Kymenlaakso,ADM1:ADM2,DISTRICT
SM10835,32.5107,35.0353,Kynousa,ADM2,DISTRICT
SM299323,32.8333,1.6333,Kyoga,ADM1:ADM2,DISTRICT
SM261566,152.7787,-28.6464,Kyogle,ADM2,DISTRICT
SM279362,139.8704,35.132,Kyonan,ADM2,DISTRICT
SM235715,128.3,35.3,Kyongsangdo,DIALECT,LANGUAGE
SM299389,31.5444,-0.6393,Kyotera,ADM1:ADM2,DISTRICT
SM278671,135.724,35.1074,Kyoto,ADM2,DISTRICT
SM1916,135.4333,35.25,Kyoto,ADM1:ADMX,DISTRICT
SM10836,32.9641,34.9454,Kyperounta,ADM2,DISTRICT
SM10837,33.0692,35.2147,Kyra,ADM2,DISTRICT
SM4244,33.25,35.2833,Kyrenia District,ADM1,DISTRICT
SM10838,33.315,35.3367,Kyrenia Municipality,ADM2,DISTRICT
SM255142,77.43,41.6,Kyrgyz,ETHNICITY,ETHNICITY
SM255142,77.43,41.6,Kyrgyz,ETHNICITY,ETHNICITY
SM255142,74.6167,42.8667,Kyrgyz,ETHNICITY,ETHNICITY
SM255142,74.6167,42.8667,Kyrgyz,ETHNICITY,ETHNICITY
SM295088,112,49.75,Kyrinskiy rayon,ADM2,DISTRICT
SM294485,44.6667,44,Kyrskiy rayon,ADM2,DISTRICT
SM346817,22.1709,62.9461,Kyrönmaa,ADM2:ADM3,DISTRICT
SM293869,77,56.75,Kyshtovskiy rayon,ADM2,DISTRICT
SM292755,60.5,55.6667,Kyshtym gorsovet,ADM2,DISTRICT
SM296186,18.786,49.3021,Kysucké Nové Mesto,ADM2,DISTRICT
SM10839,33.4797,35.2593,Kythrea,ADM2,DISTRICT
SM292973,85.4167,53.5,Kytmanovskiy rayon,ADM2,DISTRICT
SM365887,38.2108,-0.5574,Kyuso,ADM2:ADM3,DISTRICT
SM262538,22.6833,42.2833,Kyustendil,ADM2,DISTRICT
SM1917,22.9,42.25,Kyustendil,ADM1,DISTRICT
SM278668,135.3906,35.2227,Kyōtamba,ADM2,DISTRICT
SM279522,135.5779,33.8273,Kyōtanabe,ADM2,DISTRICT
SM278669,135.763,34.8078,Kyōtanabe,ADM2,DISTRICT
SM278670,135.0479,35.6309,Kyōtango,ADM2,DISTRICT
SM289108,129.6017,41.5919,Kyŏngsŏng,ADM2,DISTRICT
SM279944,53.85,48.28,Kzylkoginskiy,ADM2,DISTRICT
SM279944,53.85,48.28,Kzylkoginskiy,ADM2,DISTRICT
SM279944,53.9167,48.4167,Kzylkoginskiy,ADM2,DISTRICT
SM279944,53.9167,48.4167,Kzylkoginskiy,ADM2,DISTRICT
SM235489,141.424,-8.9229,Kánchá,LANGUAGE,LANGUAGE
SM1707,105.25,12.05,Kâmpóng Cham (2013-),ADM1,DISTRICT
SM1708,104.55,12.1667,Kâmpóng Chhnang,ADM1,DISTRICT
SM1709,104.3333,11.5,Kâmpóng Spœ,ADM1,DISTRICT
SM1710,105,12.8333,Kâmpóng Thum,ADM1,DISTRICT
SM1711,104.3333,10.8333,Kâmpôt (1995-),ADM1,DISTRICT
SM1717,105,11.4167,Kândal,ADM1,DISTRICT
SM234204,147.757,-6.4795,Kâte,LANGUAGE,LANGUAGE
SM1744,13.8333,46.75,Kärnten,ADM1,DISTRICT
SM296432,13.0736,55.7947,Kävlinge,ADM2,DISTRICT
SM286247,20.5346,69.604,Kåfjord,ADM2,DISTRICT
SM295285,-16.221,15.318,Kébémer,ADM2,DISTRICT
SM295279,-12.1783,12.8172,Kédougou,ADM2,DISTRICT
SM1774,-12.1759,12.5515,Kédougou,ADM1,DISTRICT
SM269813,14.2652,-0.0548,Kéllé (pre-1994),ADM2,DISTRICT
SM234368,10.3836,-0.7885,Kélé,LANGUAGE,LANGUAGE
SM1779,19.2,5.8667,Kémo,ADM1,DISTRICT
SM283349,-11,12.6667,Kéniéba,ADM2,DISTRICT
SM234433,121.256,-8.8704,Kéo,LANGUAGE,LANGUAGE
SM296851,1,10.0833,Kéran,ADM2,DISTRICT
SM262134,1.9915,10.9143,Kérou,ADM2,DISTRICT
SM274984,-9.0833,9.1667,Kérouané,ADM1:ADM2,DISTRICT
SM262120,2.626,7.45,Kétou,ADM2,DISTRICT
SM234997,141.5878,-8.7022,Kómnjo,DIALECT,LANGUAGE
SM277785,-21.868,64.0984,Kópavogur,ADM2,DISTRICT
SM304049,108.597,13.7352,Kông Chro,ADM2,DISTRICT
SM1846,21,59.9167,Kökar,ADM1:ADM2,DISTRICT
SM234962,6.927,50.947,Kölsch,LANGUAGE,LANGUAGE
SM296562,15.8722,59.5664,Köping,ADM2,DISTRICT
SM298738,28.4093,38.7593,Köprübaşı,ADM2,DISTRICT
SM298961,40.1228,40.8085,Köprübaşı,ADM2,DISTRICT
SM298406,41.868,39.9718,Köprüköy,ADM2,DISTRICT
SM298664,29.7944,40.7759,Körfez,ADM2,DISTRICT
SM298446,39.654,40.2103,Köse,ADM2,DISTRICT
SM298768,28.7242,36.978,Köyceğiz,ADM2,DISTRICT
SM298145,28.0565,37.8634,Köşk,ADM2,DISTRICT
SM271676,12.5653,55.6711,København,ADM2,DISTRICT
SM271732,12.0733,55.4567,Køge (2007-),ADM2,DISTRICT
SM261895,48.1667,40.25,Kürdəmir,ADM1:ADM2,DISTRICT
SM298593,33.7161,41.8164,Küre,ADM2,DISTRICT
SM298447,39.098,40.6997,Kürtün,ADM2,DISTRICT
SM268951,8.4444,47.0861,Küssnacht,ADM2,DISTRICT
SM1905,29.5,39.25,Kütahya,ADM1,DISTRICT
SM298510,28.7698,41.0695,Küçükçekmece,ADM2,DISTRICT
SM281331,23.9667,55.2833,Kėdainių,ADM2,DISTRICT
SM288998,18.1696,50.2938,Kędzierzyn-Koźle,ADM2,DISTRICT
SM288821,17.9593,51.2485,Kępno,ADM2,DISTRICT
SM288767,21.3122,54.1365,Kętrzyn,ADM2,DISTRICT
SM298219,31.8558,40.4178,Kıbrıscık,ADM2,DISTRICT
SM298538,27.3883,39.0969,Kınık,ADM2,DISTRICT
SM298737,27.6748,39.1161,Kırkağaç,ADM2,DISTRICT
SM298464,36.4364,36.5244,Kırıkhan,ADM2,DISTRICT
SM298891,32.6555,40.4801,Kızılcahamam,ADM2,DISTRICT
SM298743,40.5833,37.1927,Kızıltepe,ADM2,DISTRICT
SM298365,30.1575,38.2679,Kızılören,ADM2,DISTRICT
SM298282,33.9932,40.355,Kızılırmak,ADM2,DISTRICT
SM288723,18.8701,50.9327,Kłobuck,ADM2,DISTRICT
SM288678,16.6119,50.3888,Kłodzko,ADM2,DISTRICT
SM279598,138.6126,35.6961,Kōfu,ADM2,DISTRICT
SM279248,136.1667,34.9167,Kōka,ADM2,DISTRICT
SM278618,133.7855,33.5895,Kōnan,ADM2,DISTRICT
SM278040,136.8708,35.3314,Kōnan,ADM2,DISTRICT
SM279174,139.5201,36.0663,Kōnosu,ADM2,DISTRICT
SM279747,140.2951,37.4128,Kōriyama,ADM2,DISTRICT
SM278625,130.7687,32.8931,Kōshi,ADM2,DISTRICT
SM279599,138.7918,35.7265,Kōshū,ADM2,DISTRICT
SM278041,137.1658,34.8633,Kōta,ADM2,DISTRICT
SM279447,139.8167,35.6667,Kōtō,ADM2,DISTRICT
SM289142,126.5,38.1667,Kŭmch'ŏn,ADM2,DISTRICT
SM289113,127.2919,39.5431,Kŭmya,ADM2,DISTRICT
SM304452,105.9419,9.8177,Kế Sách,ADM2,DISTRICT
SM304180,106.2458,18.0581,Kỳ Anh,ADM2,DISTRICT
SM304179,106.2635,18.1235,Kỳ Anh (Thị xã),ADM2,DISTRICT
SM304337,104.222,19.424,Kỳ Sơn,ADM2,DISTRICT
SM304196,105.3966,20.8963,Kỳ Sơn,ADM2,DISTRICT
SM17442,-60.988,14.0188,L'Anse Road,ADM2,DISTRICT
SM277860,13.6667,42.0833,L'Aquila,ADM2,DISTRICT
SM1918,-72.5,19.3333,L'Artibonite,ADM1,DISTRICT
SM17443,-60.921,13.8067,L'Eau Mineau,ADM2,DISTRICT
SM17444,-60.9397,14.0652,L'Hermitage,ADM2,DISTRICT
SM17445,-60.9355,13.8563,L'Orangerie Road,ADM2,DISTRICT
SM17446,-60.9008,13.8765,L'Union,ADM2,DISTRICT
SM16985,35.25,51.5833,L'govskiy Rayon,ADM2,DISTRICT
SM1919,23.9167,49.75,L'viv,ADM1,DISTRICT
SM283405,14.2014,36.0681,L-Għarb,ADM1:ADM2,DISTRICT
SM283406,14.2217,36.0653,L-Għasri,ADM1:ADM2,DISTRICT
SM283400,14.4556,35.9097,L-Iklin,ADM1:ADM2,DISTRICT
SM283441,14.4053,35.8872,L-Imdina,ADM1:ADM2,DISTRICT
SM283422,14.4675,35.845,L-Imqabba,ADM1:ADM2,DISTRICT
SM283401,14.4839,35.8986,L-Imsida,ADM1:ADM2,DISTRICT
SM283442,14.3972,35.8906,L-Imtarfa,ADM1:ADM2,DISTRICT
SM283445,14.5196,35.8901,L-Isla,ADM1:ADM2,DISTRICT
SM280893,102.1327,20.9206,La,ADM2,DISTRICT
SM1921,-68.6333,18.5833,La Altagracia,ADM1,DISTRICT
SM282924,-96.3174,19.3162,La Antigua,ADM2,DISTRICT
SM10417,-75.301,8.0479,La Apartada,ADM2,DISTRICT
SM270087,-76.2048,2.1767,La Argentina,ADM2,DISTRICT
SM281816,-102.5263,20.3519,La Barca,ADM2,DISTRICT
SM270480,-74.0384,5.8901,La Belleza,ADM2,DISTRICT
SM327744,-96.518,49.4892,La Broquerie,ADM2:ADM3,DISTRICT
SM269056,6.9025,46.8221,La Broye,ADM2,DISTRICT
SM260946,-65.4167,-24.5833,La Caldera,ADM2,DISTRICT
SM270014,-73.9359,4.6868,La Calera,ADM2,DISTRICT
SM275724,-88.5609,14.4728,La Campa,ADM2,DISTRICT
SM260947,-65.0962,-26.11,La Candelaria,ADM2,DISTRICT
SM270780,-73.4808,5.0808,La Capilla,ADM2,DISTRICT
SM260992,-66.3202,-33.2754,La Capital,ADM2,DISTRICT
SM261035,-60.6667,-31.5,La Capital,ADM2,DISTRICT
SM17447,-60.9872,14.0181,La Carierre,ADM2,DISTRICT
SM17448,-60.9043,13.9329,La Caye,ADM2,DISTRICT
SM303796,-71.7667,10.8,La Cañada de Urdaneta,ADM2,DISTRICT
SM303779,-71.0142,9.4714,La Ceiba,ADM2,DISTRICT
SM275654,-86.76,15.67,La Ceiba,ADM2,DISTRICT
SM270230,-75.4331,6,La Ceja,ADM2,DISTRICT
SM270435,-76.0048,5,La Celia,ADM2,DISTRICT
SM268927,6.8406,47.1216,La Chaux-de-Fonds,ADM2,DISTRICT
SM286655,-79.85,8.95,La Chorrera,ADM2,DISTRICT
SM461308,-72.7294,-1.4889,La Chorrera (1997-),ADM2,DISTRICT
SM271868,-71.1338,18.0925,La Cienaga,ADM2,DISTRICT
SM17449,-60.9842,14.0185,La Clery,ADM2,DISTRICT
SM261080,-65.5711,-27.7778,La Cocha,ADM2,DISTRICT
SM289631,-56.8231,-25.9023,La Colmena,ADM2,DISTRICT
SM282578,-110.4021,28.7538,La Colorada,ADM2,DISTRICT
SM14309,-96.8384,16.5737,La Compania,ADM2,DISTRICT
SM285443,-86.2187,11.9528,La Concepción,ADM2,DISTRICT
SM285541,-86.178,13.2013,La Concordia,ADM2,DISTRICT
SM283153,-92.6833,16.1167,La Concordia,ADM2,DISTRICT
SM273557,-79.3947,0.0059,La Concordia,ADM2,DISTRICT
SM285506,-86.1872,11.7183,La Conquista,ADM2,DISTRICT
SM286908,-73,-12,La Convención,ADM2,DISTRICT
SM260782,-56.6875,-36.5772,La Costa,ADM2,DISTRICT
SM17450,-60.9277,13.8106,La Courville,ADM2,DISTRICT
SM17451,-60.9019,13.8738,La Croix,ADM2,DISTRICT
SM17452,-60.9403,14.0115,La Croix Chabourgh,ADM2,DISTRICT
SM17453,-61.0058,13.9657,La Croix Maingot,ADM2,DISTRICT
SM303246,-91.1152,43.9065,La Crosse,ADM2,DISTRICT
SM283242,-105.1365,27.8863,La Cruz,ADM2,DISTRICT
SM270928,-85.5992,10.9842,La Cruz,ADM2,DISTRICT
SM270320,-76.9219,1.5875,La Cruz,ADM2,DISTRICT
SM270619,-76.5862,3.6962,La Cumbre,ADM2,DISTRICT
SM11414,-0.1592,5.5868,La Dade Kotopon,ADM2,DISTRICT
SM17454,-61.0423,13.8219,La Dauphine,ADM2,DISTRICT
SM275504,-91.8834,15.6331,La Democracia,ADM2,DISTRICT
SM275431,-90.9471,14.1305,La Democracia,ADM2,DISTRICT
SM271771,-71.7516,18.608,La Descubierta,ADM2,DISTRICT
SM1922,55.8375,-4.3583,La Digue and Inner Islands,ADM1,DISTRICT
SM270861,-74.7,5.5333,La Dorada,ADM2,DISTRICT
SM275737,-89.0474,14.6667,La Encarnación,ADM2,DISTRICT
SM275672,-88.1219,14.2517,La Esperanza,ADM2,DISTRICT
SM275213,-91.5759,14.8768,La Esperanza,ADM2,DISTRICT
SM270370,-73.4073,7.686,La Esperanza (1993-),ADM2,DISTRICT
SM270231,-75.6526,6.1424,La Estrella,ADM2,DISTRICT
SM1923,-71.6333,19,La Estrelleta,ADM1,DISTRICT
SM17455,-61.0412,13.772,La Fargue,ADM2,DISTRICT
SM17456,-60.934,13.8352,La Fille,ADM2,DISTRICT
SM18335,-55.6689,-34.7584,La Floresta,ADM2,DISTRICT
SM270321,-77.4127,1.3569,La Florida,ADM2,DISTRICT
SM284592,165.8252,-21.7079,La Foa,ADM2,DISTRICT
SM303940,107.7891,10.7231,La Gi,ADM2,DISTRICT
SM269892,-73.6305,8.6116,La Gloria,ADM2,DISTRICT
SM15835,-66.0059,18.3257,La Gloria Barrio,ADM2,DISTRICT
SM269057,6.9167,46.6667,La Glâne,ADM2,DISTRICT
SM276106,-73.05,18.85,La Gonâve,ADM2,DISTRICT
SM18007,10.2827,36.8419,La Goulette (2001-),ADM2,DISTRICT
SM283154,-92.2228,15.5083,La Grandeza,ADM2,DISTRICT
SM269058,7.1074,46.6075,La Gruyère,ADM2,DISTRICT
SM270072,-67.0015,1.3958,La Guadalupe,ADM2,DISTRICT
SM1924,-72.5,11.5,La Guajira,ADM1,DISTRICT
SM17457,-60.9249,14.0219,La Guerre,ADM2,DISTRICT
SM17458,-60.9311,14.0239,La Guerre/Chicken Back Street,ADM2,DISTRICT
SM17459,-60.9121,13.8766,La Gwen Ba,ADM2,DISTRICT
SM271100,-82.3517,23.1298,La Habana Vieja,ADM2,DISTRICT
SM271099,-82.3255,23.1582,La Habana del Este,ADM2,DISTRICT
SM17460,-61.0001,13.7921,La Haut (Laborie),ADM2,DISTRICT
SM17461,-60.9068,13.8589,La Haut (Micoud),ADM2,DISTRICT
SM17462,-61.0558,13.8628,La Haut (Soufrière),ADM2,DISTRICT
SM282042,-101.8749,18.8878,La Huacana,ADM2,DISTRICT
SM281817,-104.8537,19.5027,La Huerta,ADM2,DISTRICT
SM275725,-88.4636,14.6644,La Iguala,ADM2,DISTRICT
SM283155,-91.7411,16.215,La Independencia,ADM2,DISTRICT
SM271819,-71.0833,19.8,La Isabela,ADM2,DISTRICT
SM269893,-73.3333,9.5667,La Jagua de Ibirico,ADM2,DISTRICT
SM10418,-73.0723,10.4581,La Jagua del Pilar,ADM2,DISTRICT
SM275861,-88.76,15.1,La Jigua,ADM2,DISTRICT
SM273531,-76.8904,-0.2789,La Joya de los Sachas,ADM2,DISTRICT
SM275738,-89.0268,14.4956,La Labor,ADM2,DISTRICT
SM295609,-88.915,14.1521,La Laguna,ADM2,DISTRICT
SM285524,-85.1885,12.3262,La Libertad,ADM2,DISTRICT
SM283157,-91.7499,17.5735,La Libertad,ADM2,DISTRICT
SM275856,-87.55,14.88,La Libertad,ADM2,DISTRICT
SM275939,-87.5042,13.7066,La Libertad,ADM2,DISTRICT
SM275505,-91.911,15.5633,La Libertad,ADM2,DISTRICT
SM295677,-89.3167,13.4833,La Libertad,ADM2,DISTRICT
SM273554,-80.8998,-2.2315,La Libertad,ADM2,DISTRICT
SM1927,-89.3833,13.75,La Libertad,ADM1,DISTRICT
SM1926,-78.5,-8,La Libertad,ADM1,DISTRICT
SM275887,-87.88,15.46,La Lima,ADM2,DISTRICT
SM271087,-82.4346,23.0657,La Lisa,ADM2,DISTRICT
SM270323,-77.7054,1.5123,La Llanada,ADM2,DISTRICT
SM270274,-74.0732,2.007,La Macarena,ADM2,DISTRICT
SM14310,-98.1939,19.2758,La Magdalena Tlaltelulco,ADM2,DISTRICT
SM282440,-98.1014,18.7613,La Magdalena Tlatlauquitepec,ADM2,DISTRICT
SM281818,-103.1143,20.0202,La Manzanilla de la Paz,ADM2,DISTRICT
SM273607,-79.1342,-0.7908,La Maná,ADM2,DISTRICT
SM11509,-91.5804,14.2809,La Maquina,ADM2,DISTRICT
SM286877,-73.8333,-12.9167,La Mar,ADM2,DISTRICT
SM298020,10.3029,36.8954,La Marsa,ADM2,DISTRICT
SM275655,-87.135,15.5987,La Masica,ADM2,DISTRICT
SM1928,1.4833,42.5542,La Massana,ADM1,DISTRICT
SM271857,-70.2086,19.0898,La Mata,ADM2,DISTRICT
SM260783,-58.5833,-34.75,La Matanza,ADM2,DISTRICT
SM270862,-75.5884,5.3806,La Merced,ADM2,DISTRICT
SM286671,-81.1777,8.1098,La Mesa,ADM2,DISTRICT
SM270015,-74.4732,4.6537,La Mesa,ADM2,DISTRICT
SM281682,-99.0715,21.0698,La Misión,ADM2,DISTRICT
SM270877,-75.3036,1.4639,La Montañita,ADM2,DISTRICT
SM10311,18.0363,8.1679,La Moula,ADM2,DISTRICT
SM269490,-3.7083,6,La Mé,ADM2,DISTRICT
SM11415,-0.1663,5.7451,La Nkwantanang Madina,ADM2,DISTRICT
SM295610,-89.1604,14.2984,La Palma,ADM2,DISTRICT
SM271033,-83.55,22.75,La Palma,ADM2,DISTRICT
SM270016,-74.43,5.3173,La Palma,ADM2,DISTRICT
SM15347,-54.562,-24.1938,La Paloma,ADM2,DISTRICT
SM1929,-66,-37,La Pampa,ADM1,DISTRICT
SM17463,-60.9819,14.0137,La Pansee,ADM2,DISTRICT
SM289697,-56.5502,-25.2508,La Pastora,ADM2,DISTRICT
SM300153,-56.2672,-34.7238,La Paz,ADM2,DISTRICT
SM301800,-113.9813,33.7293,La Paz,ADM2,DISTRICT
SM289571,-55.8973,-26.9779,La Paz,ADM2,DISTRICT
SM281934,-98.9452,19.3641,La Paz,ADM2,DISTRICT
SM275694,-87.7457,14.3176,La Paz,ADM2,DISTRICT
SM269894,-73.2206,10.1049,La Paz,ADM2,DISTRICT
SM270481,-73.6371,6.2027,La Paz,ADM2,DISTRICT
SM260894,-67.25,-33.6667,La Paz,ADM2,DISTRICT
SM261175,-59.4167,-30.8333,La Paz,ADM2,DISTRICT
SM260995,-65.25,-29.25,La Paz,ADM2,DISTRICT
SM1931,-87.9167,14.0833,La Paz,ADM1,DISTRICT
SM1932,-88.9833,13.4667,La Paz,ADM1,DISTRICT
SM1930,-68,-15.5,La Paz,ADM1,DISTRICT
SM14311,-110.3,24.1333,La Paz (1981-),ADM2,DISTRICT
SM285419,-86.6304,12.4366,La Paz Centro,ADM2,DISTRICT
SM285507,-86.111,11.8222,La Paz de Carazo,ADM2,DISTRICT
SM14312,-96.7893,16.6183,La Pe,ADM2,DISTRICT
SM17464,-61.0476,13.8574,La Pearle,ADM2,DISTRICT
SM269844,-70.2219,-1.656,La Pedrera,ADM2,DISTRICT
SM17465,-60.9057,13.941,La Pelle,ADM2,DISTRICT
SM10312,16.8835,8.9133,La Pende,ADM2,DISTRICT
SM10312,16.8835,8.9133,La Pende,ADM2,DISTRICT
SM10312,16.9587,8.6241,La Pende,ADM2,DISTRICT
SM10312,16.9587,8.6241,La Pende,ADM2,DISTRICT
SM282925,-97.1639,18.9754,La Perla,ADM2,DISTRICT
SM17466,-61.0209,13.7648,La Perle,ADM2,DISTRICT
SM270018,-74.4114,5.1984,La Peña,ADM2,DISTRICT
SM282044,-102.0311,20.3167,La Piedad,ADM2,DISTRICT
SM286692,-80.5395,8.718,La Pintada,ADM2,DISTRICT
SM10419,-75.6002,5.7508,La Pintada,ADM2,DISTRICT
SM303345,-107.8433,37.2866,La Plata,ADM2,DISTRICT
SM270088,-76.1045,2.3206,La Plata,ADM2,DISTRICT
SM260784,-58,-35,La Plata,ADM2,DISTRICT
SM270371,-73.1922,8.2583,La Playa de Belén,ADM2,DISTRICT
SM17469,-61.065,13.7944,La Pointe (Choiseul),ADM2,DISTRICT
SM17468,-60.8883,13.9153,La Pointe (Dennery),ADM2,DISTRICT
SM17467,-60.8933,13.8459,La Pointe (Micoud),ADM2,DISTRICT
SM260948,-66.1667,-24.1667,La Poma,ADM2,DISTRICT
SM270654,-69.4979,5.5146,La Primavera,ADM2,DISTRICT
SM14313,-97.8601,16.6578,La Reforma,ADM2,DISTRICT
SM275281,-91.8282,14.8049,La Reforma,ADM2,DISTRICT
SM295611,-89.1645,14.1925,La Reina,ADM2,DISTRICT
SM17470,-60.9624,13.748,La Resource,ADM2,DISTRICT
SM17471,-60.9159,13.9429,La Ressource,ADM2,DISTRICT
SM17472,-60.9663,13.7594,La Retraite,ADM2,DISTRICT
SM274052,-2.4246,42.4631,La Rioja,ADM2,DISTRICT
SM1934,-2.5,42.3,La Rioja,ADM1,DISTRICT
SM1933,-67,-29.5833,La Rioja,ADM1,DISTRICT
SM271784,-68.679,18.1562,La Romana,ADM2,DISTRICT
SM1935,-68.9667,18.5,La Romana,ADM1,DISTRICT
SM270892,-72.3444,6.1853,La Salina,ADM2,DISTRICT
SM302683,-99.0997,28.3451,La Salle,ADM2,DISTRICT
SM301128,-92.1604,31.6767,La Salle,ADM2,DISTRICT
SM300635,-88.8859,41.344,La Salle,ADM2,DISTRICT
SM269059,7.0999,46.7844,La Sarine,ADM2,DISTRICT
SM271041,-79.2681,21.7711,La Sierpe,ADM2,DISTRICT
SM269856,-76.7859,2.1967,La Sierra,ADM2,DISTRICT
SM17473,-60.8998,13.9735,La Sociere,ADM2,DISTRICT
SM277967,9.7,44.25,La Spezia,ADM2,DISTRICT
SM270423,-75.8148,4.4335,La Tebaida,ADM2,DISTRICT
SM4245,138.9913,-59.9986,La Terre-Adelie,ADM1,DISTRICT
SM17474,-60.9172,13.8274,La Tille,ADM2,DISTRICT
SM17475,-61.004,14.0082,La Toc,ADM2,DISTRICT
SM270324,-78.2068,2.3358,La Tola,ADM2,DISTRICT
SM15836,-66.8743,18.2525,La Torre Barrio,ADM2,DISTRICT
SM17476,-60.9652,13.7411,La Tourney/Cedar Heights,ADM2,DISTRICT
SM17477,-60.9973,13.9401,La Trielle,ADM2,DISTRICT
SM303792,-68.7695,10.2493,La Trinidad,ADM2,DISTRICT
SM285531,-86.1997,13.0178,La Trinidad,ADM2,DISTRICT
SM275857,-87.6629,14.7,La Trinidad,ADM2,DISTRICT
SM14314,-97.5022,17.7703,La Trinidad Vista Hermosa,ADM2,DISTRICT
SM283158,-91.9334,16.0176,La Trinitaria,ADM2,DISTRICT
SM273589,-79.3794,-2.444,La Troncal,ADM2,DISTRICT
SM1936,120.4167,16.5,La Union,ADM1:ADM2,DISTRICT
SM281615,-101.867,18.0005,La Union,ADM2,DISTRICT
SM286870,-72.8333,-15,La Unión,ADM2,DISTRICT
SM275699,-88.4245,14.7993,La Unión,ADM2,DISTRICT
SM275862,-88.9473,14.6935,La Unión,ADM2,DISTRICT
SM275746,-86.6891,15.0976,La Unión,ADM2,DISTRICT
SM275384,-89.275,14.9577,La Unión,ADM2,DISTRICT
SM295716,-87.8833,13.3667,La Unión,ADM2,DISTRICT
SM270917,-83.9833,9.9167,La Unión,ADM2,DISTRICT
SM1937,-87.9,13.55,La Unión,ADM1,DISTRICT
SM270620,-76.1066,4.5384,La Unión de Sucre,ADM2,DISTRICT
SM270550,-75.2958,8.7963,La Unión de Sucre,ADM2,DISTRICT
SM270325,-77.1592,1.6269,La Unión de Sucre,ADM2,DISTRICT
SM270232,-75.3592,5.9464,La Unión de Sucre,ADM2,DISTRICT
SM270275,-74.4361,3.0265,La Uribe,ADM2,DISTRICT
SM270781,-72.5645,6.2426,La Uvita,ADM2,DISTRICT
SM269857,-76.7681,2.062,La Vega,ADM2,DISTRICT
SM270019,-74.3448,4.9738,La Vega,ADM2,DISTRICT
SM1938,-70.65,19.05,La Vega,ADM1,DISTRICT
SM275940,-87.3287,13.7311,La Venta,ADM2,DISTRICT
SM269060,6.9109,46.5508,La Veveyse,ADM2,DISTRICT
SM270621,-75.9812,4.4762,La Victoria,ADM2,DISTRICT
SM270782,-74.2328,5.5228,La Victoria,ADM2,DISTRICT
SM10420,-71.0376,-0.1831,La Victoria,ADM2,DISTRICT
SM270436,-75.851,4.9058,La Virginia,ADM2,DISTRICT
SM275700,-88.6858,14.0716,La Virtud,ADM2,DISTRICT
SM260949,-65.6667,-25.5,La Viña,ADM2,DISTRICT
SM282150,-104.134,21.5492,La Yesca,ADM2,DISTRICT
SM235738,15.2895,7.8582,La'bi,LANGUAGE,LANGUAGE
SM297444,98.785,10.0969,La-Un,ADM2,DISTRICT
SM300729,-85.4265,41.6426,LaGrange,ADM2,DISTRICT
SM300732,-86.7424,41.549,LaPorte,ADM2,DISTRICT
SM235728,17.6143,10.0063,Laal,LANGUAGE,LANGUAGE
SM246264,94.0957,22.4996,Laamtuk Thet,LANGUAGE,LANGUAGE
SM4260,73.5729,2.1035,Laamu Atholhu,ADM1:ADM2,DISTRICT
SM285929,5.6128,51.5264,Laarbeek,ADM2,DISTRICT
SM235732,14.9,-4.25,Laari,LANGUAGE,LANGUAGE
SM17876,48.6957,10.5368,Laasqoray,ADM2,DISTRICT
SM15013,-12.3333,27.8333,Laayoune (2009-),ADM2,DISTRICT
SM4275,-12.2079,27.8583,Laayoune-Sakia El Hamra,ADM1,DISTRICT
SM235733,127.583,1.6668,Laba,LANGUAGE,LANGUAGE
SM255143,78.39,14.47,Labana,ETHNICITY,ETHNICITY
SM270373,-72.5529,7.2456,Labateca,ADM2,DISTRICT
SM17478,-60.9732,13.9404,Labayee,ADM2,DISTRICT
SM235735,152.678,-4.4697,Label,LANGUAGE,LANGUAGE
SM300934,-95.2976,37.1913,Labette,ADM2,DISTRICT
SM276043,14.1333,45.0833,Labin,ADM2,DISTRICT
SM16986,41.002,44.4768,Labinskiy Rayon,ADM2,DISTRICT
SM235739,9.5953,10.4847,Labir,LANGUAGE,LANGUAGE
SM235741,103.4,23.9,Labo Phowa,LANGUAGE,LANGUAGE
SM1944,-61.0031,13.7867,Laborie,ADM1,DISTRICT
SM255145,-65,58,Labrador Inuit,ETHNICITY,ETHNICITY
SM255145,-65,58,Labrador Inuit,ETHNICITY,ETHNICITY
SM255145,-62.32,57.83,Labrador Inuit,ETHNICITY,ETHNICITY
SM255145,-62.32,57.83,Labrador Inuit,ETHNICITY,ETHNICITY
SM270783,-72.6023,5.5424,Labranzagrande,ADM2,DISTRICT
SM235746,146.903,-6.75,Labu,LANGUAGE,LANGUAGE
SM1945,115.2,5.3333,Labuan,ADM1,DISTRICT
SM276678,100.1,2.2667,Labuhanbatu,ADM2,DISTRICT
SM276676,100.0976,1.983,Labuhanbatu Selatan,ADM2,DISTRICT
SM276677,99.6378,2.3335,Labuhanbatu Utara,ADM2,DISTRICT
SM235747,117.507,5.5192,Labuk-Kinabatangan Kadazan,LANGUAGE,LANGUAGE
SM15091,95.0422,16.2541,Labutta District,ADM2,DISTRICT
SM235752,33.658,2.6999,Labwor,DIALECT:LANGUAGE,LANGUAGE
SM299306,33.75,2.75,Labwor,ADM1:ADM2,DISTRICT
SM250407,34,3,Labwor,ETHNICITY,ETHNICITY
SM250407,34,3,Labwor,ETHNICITY,ETHNICITY
SM250407,18,9,Labwor,ETHNICITY,ETHNICITY
SM250407,18,9,Labwor,ETHNICITY,ETHNICITY
SM12465,-77.0323,18.3442,Labyrinth,ADM2,DISTRICT
SM274994,-12.3,11.366,Labé,ADM1:ADM2,DISTRICT
SM1943,-12.0867,11.61,Labé,ADM1,DISTRICT
SM269061,7.1409,46.9102,Lac,ADM2,DISTRICT
SM1946,14.5,13.5,Lac,ADM1,DISTRICT
SM296809,19.23,9.59,Lac Iro,ADM2,DISTRICT
SM10313,14.1605,9.7289,Lac Lere (2012-),ADM2,DISTRICT
SM10145,-114.6971,53.8465,Lac Ste. Anne County (2007-),ADM2,DISTRICT
SM10329,15.983,8.705,Lac Wey (2008-),ADM2,DISTRICT
SM370464,5.8333,49.9167,Lac de la Haute-Sûre,ADM1:ADM2:ADM3,DISTRICT
SM10183,-96.1681,50.25,Lac du Bonnet,ADM2,DISTRICT
SM10142,-111.8628,55.0469,Lac la Biche County (2012-),ADM2,DISTRICT
SM301368,-96.1735,44.9955,Lac qui Parle,ADM2,DISTRICT
SM10314,20.6743,18.9999,Lac-Ounianga,ADM2,DISTRICT
SM235753,-91.4235,17.0209,Lacandon,LANGUAGE,LANGUAGE
SM255147,-92,17,Lacandon,ETHNICITY,ETHNICITY
SM260929,-71.2,-40.25,Lacar,ADM2,DISTRICT
SM266186,-51.585,-27.2535,Lacerdópolis,ADM2,DISTRICT
SM235758,104.776,22.8549,Lachi,LANGUAGE,LANGUAGE
SM235760,-95.5367,16.5772,Lachiguiri Zapotec,LANGUAGE,LANGUAGE
SM261930,46.3991,39.6507,Lachin,ADM1:ADM2,DISTRICT
SM235759,-96.9318,16.7215,Lachixío Zapotec,LANGUAGE,LANGUAGE
SM261567,146.8756,-32.8,Lachlan,ADM2,DISTRICT
SM302313,-75.6092,41.4368,Lackawanna,ADM2,DISTRICT
SM301571,-92.5904,37.6583,Laclede,ADM2,DISTRICT
SM297801,125.9167,-8.55,Laclo,ADM2,DISTRICT
SM297802,125.9119,-8.7498,Laclubar,ADM2,DISTRICT
SM297810,126.1333,-8.8,Lacluta,ADM2,DISTRICT
SM323961,-113.7275,52.4721,Lacombe,ADM2:ADM3,DISTRICT
SM10147,-113.7245,52.4487,Lacombe County (2004-),ADM2,DISTRICT
SM12466,-77.7535,18.0884,Lacovia,ADM2,DISTRICT
SM296854,1.6667,6.3667,Lacs,ADM2,DISTRICT
SM1947,-4.4485,7.0313,Lacs,ADM1,DISTRICT
SM263587,-41.8326,-17.6437,Ladainha,ADM2,DISTRICT
SM3942,77.3469,34.0971,Ladakh,ADM1,DISTRICT
SM375441,76.4243,35.217,Ladakh (Leh),ADM2:ADM3,DISTRICT
SM459390,141.833,-12.8831,Ladamngid,ETHNICITY,ETHNICITY
SM292198,24.0435,44.8701,Ladesti,ADM2,DISTRICT
SM298862,35.901,40.9203,Ladik,ADM2,DISTRICT
SM235768,11.895,46.6065,Ladin,LANGUAGE,LANGUAGE
SM235769,28.6632,41.205,Ladino,LANGUAGE,LANGUAGE
SM262740,-67.4474,-19.3538,Ladislao Cabrera,ADM2,DISTRICT
SM299769,29.3887,48.8105,Ladyzhyns'ka,ADM2,DISTRICT
SM288584,146.8333,-6.5,Lae,ADM2,DISTRICT
SM4083,166.2333,8.9333,Lae Atoll,ADM1,DISTRICT
SM11173,24.0889,59.2593,Laeaene-Harju vald,ADM2,DISTRICT
SM11174,23.7449,58.9605,Laeaene-Nigula vald,ADM2,DISTRICT
SM11175,23.8963,58.5891,Laeaeneranna vald,ADM2,DISTRICT
SM235772,142.237,-3.6962,Laeko-Libuat,LANGUAGE,LANGUAGE
SM297686,102.3656,12.2203,Laem Ngop,ADM2,DISTRICT
SM297788,102.1426,12.4569,Laem Sing,ADM2,DISTRICT
SM267503,-40.2044,-13.6203,Lafaiete Coutinho,ADM2,DISTRICT
SM303247,-90.1317,42.6605,Lafayette,ADM2,DISTRICT
SM301455,-89.4849,34.3568,Lafayette,ADM2,DISTRICT
SM301572,-93.7855,39.0656,Lafayette,ADM2,DISTRICT
SM301129,-92.0639,30.2068,Lafayette,ADM2,DISTRICT
SM300339,-83.1811,29.9855,Lafayette,ADM2,DISTRICT
SM302382,-93.607,33.241,Lafayette,ADM2,DISTRICT
SM285020,8.6996,8.6401,Lafia,ADM2,DISTRICT
SM235774,30.7331,10.3357,Lafofa,LANGUAGE,LANGUAGE
SM17888,32.4691,5.0332,Lafon County,ADM2,DISTRICT
SM301131,-90.4026,29.5003,Lafourche,ADM2,DISTRICT
SM282441,-97.2975,19.3068,Lafragua,ADM2,DISTRICT
SM297825,126.5923,-8.4741,Laga,ADM2,DISTRICT
SM288642,143.1667,-5.3833,Lagaip-Porgera,ADM2,DISTRICT
SM263588,-46.701,-17.9771,Lagamar,ADM2,DISTRICT
SM293063,47.1667,45.3333,Laganskiy rayon,ADM2,DISTRICT
SM267102,-37.6799,-10.8984,Lagarto,ADM2,DISTRICT
SM10840,33.2422,34.8417,Lageia,ADM2,DISTRICT
SM285158,4.0469,7.4949,Lagelu,ADM2,DISTRICT
SM266187,-50.3525,-27.9449,Lages,ADM2,DISTRICT
SM1948,70.1833,34.7833,Laghman,ADM1,DISTRICT
SM1949,2.6667,33.5833,Laghouat,ADM1,DISTRICT
SM457561,3.25,33.5,Laghouat District,ADM2,DISTRICT
SM235780,158.961,-8.0051,Laghu,LANGUAGE,LANGUAGE
SM235779,103.929,22.2674,Laghuu,LANGUAGE,LANGUAGE
SM283958,34.8998,-12.2828,Lago,ADM2,DISTRICT
SM273562,-76.8667,0.1,Lago Agrio,ADM2,DISTRICT
SM261013,-72,-49.75,Lago Argentino,ADM2,DISTRICT
SM261014,-70.9167,-47,Lago Buenos Aires,ADM2,DISTRICT
SM262807,-44.8831,-3.9907,Lago Verde,ADM2,DISTRICT
SM263020,-45.0581,-4.5267,Lago da Pedra,ADM2,DISTRICT
SM262805,-44.9101,-4.4703,Lago do Junco,ADM2,DISTRICT
SM262806,-44.9784,-4.611,Lago dos Rodrigues,ADM2,DISTRICT
SM289390,-25.5403,37.7482,Lagoa,ADM2,DISTRICT
SM289513,-8.4597,37.1051,Lagoa,ADM2,DISTRICT
SM264227,-37.8499,-6.5952,Lagoa,ADM2,DISTRICT
SM265040,-42.5656,-4.4951,Lagoa Alegre,ADM2,DISTRICT
SM319287,-53.0347,-29.494,Lagoa Bonita,ADM2:ADM3,DISTRICT
SM263593,-46.2901,-18.7649,Lagoa Formosa,ADM2,DISTRICT
SM265025,-40.2536,-8.787,Lagoa Grande,ADM2,DISTRICT
SM262809,-45.3495,-4.9317,Lagoa Grande do Maranhão,ADM2,DISTRICT
SM265575,-36.5461,-6.1102,Lagoa Nova,ADM2,DISTRICT
SM267504,-42.217,-14.1099,Lagoa Real,ADM2,DISTRICT
SM265576,-35.4951,-6.1232,Lagoa Salgada,ADM2,DISTRICT
SM263595,-43.8799,-19.6268,Lagoa Santa,ADM2,DISTRICT
SM9953,-51.2589,-19.2121,Lagoa Santa,ADM2:ADM3,DISTRICT
SM264226,-35.8294,-7.1436,Lagoa Seca,ADM2,DISTRICT
SM9954,-51.4723,-28.22,Lagoa Vermelha (2001-),ADM2,DISTRICT
SM265377,-36.7481,-9.8072,Lagoa da Canoa,ADM2,DISTRICT
SM267250,-49.937,-10.9931,Lagoa da Confusão,ADM2,DISTRICT
SM263589,-45.4995,-19.998,Lagoa da Prata,ADM2,DISTRICT
SM265572,-35.6364,-6.3704,Lagoa de Anta,ADM2,DISTRICT
SM265574,-35.4532,-6.1661,Lagoa de Pedras,ADM2,DISTRICT
SM265041,-41.5917,-4.3547,Lagoa de São Francisco,ADM2,DISTRICT
SM264225,-35.3591,-6.6831,Lagoa de dentro,ADM2,DISTRICT
SM265042,-41.5481,-8.7354,Lagoa do Barro do Piauí,ADM2,DISTRICT
SM265021,-35.3364,-7.8542,Lagoa do Carro,ADM2,DISTRICT
SM265022,-35.2932,-7.9029,Lagoa do Itaenga,ADM2,DISTRICT
SM262808,-43.6311,-5.9554,Lagoa do Mato,ADM2,DISTRICT
SM265023,-36.4822,-9.1664,Lagoa do Ouro,ADM2,DISTRICT
SM265043,-42.5481,-5.4751,Lagoa do Piauí,ADM2,DISTRICT
SM265044,-41.4206,-6.5192,Lagoa do Sítio,ADM2,DISTRICT
SM267251,-47.4813,-10.3372,Lagoa do Tocantins,ADM2,DISTRICT
SM265024,-35.8898,-8.6797,Lagoa dos Gatos,ADM2,DISTRICT
SM263590,-44.6663,-17.0143,Lagoa dos Patos,ADM2,DISTRICT
SM265734,-52.8465,-28.5661,Lagoa dos Três Cantos,ADM2,DISTRICT
SM263592,-44.0651,-20.9103,Lagoa dourada,ADM2,DISTRICT
SM265577,-35.8231,-6.0082,Lagoas de Velhos,ADM2,DISTRICT
SM274809,46.1667,41.8333,Lagodekhi,ADM2,DISTRICT
SM266652,-45.1997,-23.0844,Lagoinha,ADM2,DISTRICT
SM265045,-42.628,-5.807,Lagoinha do Piauí,ADM2,DISTRICT
SM289514,-8.6766,37.0957,Lagos,ADM2,DISTRICT
SM1950,3.3522,6.5377,Lagos,ADM1,DISTRICT
SM281821,-101.9098,21.4573,Lagos de Moreno,ADM2,DISTRICT
SM284994,3.3904,6.4611,LagosIsland,ADM2,DISTRICT
SM10841,33.0244,34.974,Lagoudera,ADM2,DISTRICT
SM265737,-52.7669,-29.259,Lagoão,ADM2,DISTRICT
SM1951,121.3333,14.1667,Laguna,ADM1:ADM2,DISTRICT
SM255154,-107,35,Laguna,ETHNICITY,ETHNICITY
SM9955,-48.8383,-28.4707,Laguna (2013-),ADM2,DISTRICT
SM263062,-55.0825,-22.7104,Laguna Carapã,ADM2,DISTRICT
SM271889,-71.1333,19.7,Laguna Salada,ADM2,DISTRICT
SM15837,-67.1731,18.3389,Lagunas Barrio,ADM2,DISTRICT
SM255155,-102.67,24.76,Lagunero,ETHNICITY,ETHNICITY
SM1952,-4.2986,5.8707,Lagunes,ADM1,DISTRICT
SM303797,-71.3082,10.1964,Lagunillas,ADM2,DISTRICT
SM282484,-99.5953,21.6132,Lagunillas,ADM2,DISTRICT
SM282045,-101.4194,19.5707,Lagunillas,ADM2,DISTRICT
SM235786,14.9884,11.5592,Lagwan,LANGUAGE,LANGUAGE
SM235789,128.073,-3.7076,Laha (Indonesia),LANGUAGE,LANGUAGE
SM235788,103.8638,21.5515,Laha (Viet Nam),LANGUAGE,LANGUAGE
SM297411,102.8799,14.3219,Lahan Sai,ADM2,DISTRICT
SM235791,113.947,2.7273,Lahanan,LANGUAGE,LANGUAGE
SM276667,103.5428,-3.7864,Lahat,ADM2,DISTRICT
SM255158,33.14,29.53,Laheiwat,ETHNICITY,ETHNICITY
SM1953,44.5833,13.1667,Lahij,ADM1,DISTRICT
SM255159,146.23,-6.78,Lahiwapa,ETHNICITY,ETHNICITY
SM296633,13.1914,56.4917,Laholm,ADM2,DISTRICT
SM375461,74.3979,31.4863,Lahore,ADM2:ADM3,DISTRICT
SM262663,27.75,54.3333,Lahoysk,ADM2,DISTRICT
SM235797,97.3791,20.1797,Lahta Karen,LANGUAGE,LANGUAGE
SM249871,96.908,20.16,Lahta-Zayein Karen,LANGUAGE,LANGUAGE
SM11220,25.7276,61.2011,Lahti (2009-),ADM2,DISTRICT
SM235800,100.214,21.2701,Lahu,LANGUAGE,LANGUAGE
SM255161,98.26,18.07,Lahu,ETHNICITY,ETHNICITY
SM255161,98.26,18.07,Lahu,ETHNICITY,ETHNICITY
SM255161,98.26,18.07,Lahu,ETHNICITY,ETHNICITY
SM255161,100,22,Lahu,ETHNICITY,ETHNICITY
SM255161,100,22,Lahu,ETHNICITY,ETHNICITY
SM255161,100,22,Lahu,ETHNICITY,ETHNICITY
SM255161,100,22,Lahu,ETHNICITY,ETHNICITY
SM255161,100,22,Lahu,ETHNICITY,ETHNICITY
SM255161,100,22,Lahu,ETHNICITY,ETHNICITY
SM235799,100.97,21.2078,Lahu Shi,LANGUAGE,LANGUAGE
SM276874,77.8333,32.5,Lahul & Spiti,ADM2,DISTRICT
SM235798,77,32.583,Lahul Lohar,LANGUAGE,LANGUAGE
SM304256,103.1564,22.0692,Lai Châu,ADM2,DISTRICT
SM1954,103.25,22.2833,Lai Châu (2003-),ADM1,DISTRICT
SM304018,105.6633,10.2405,Lai Vung,ADM2,DISTRICT
SM269450,109.6333,23.7667,Laibin,ADM2,DISTRICT
SM13626,21.8407,56.7517,Laidu pagasts,ADM2,DISTRICT
SM13627,22.6384,57.2934,Laidzes pagasts,ADM2,DISTRICT
SM1955,36.7667,0.3333,Laikipia,ADM1:ADM2,DISTRICT
SM280307,69.75,39.8333,Lailak,ADM2,DISTRICT
SM235808,10.1427,6.2816,Laimbue,LANGUAGE,LANGUAGE
SM17889,30.7585,4.4926,Lainya County,ADM2,DISTRICT
SM294617,49.5833,55.5,Laishevskiy rayon,ADM2,DISTRICT
SM261192,-58.75,-26.5,Laishi,ADM2,DISTRICT
SM269305,117.6653,36.2825,Laiwu,ADM2,DISTRICT
SM235812,120.483,-6.4551,Laiyolo,LANGUAGE,LANGUAGE
SM268464,90.0142,27.0902,Lajab,ADM2,DISTRICT
SM461511,-77.8162,-6.4856,Lajalca,ETHNICITY,ETHNICITY
SM271083,-80.3,22.4167,Lajas,ADM2,DISTRICT
SM1956,-67.0399,18.0133,Lajas,ADM1,DISTRICT
SM15838,-67.0141,18.042,Lajas Arriba Barrio,ADM2,DISTRICT
SM15839,-67.0547,18.0539,Lajas Barrio,ADM2,DISTRICT
SM15840,-67.0596,18.0493,Lajas Barrio-Pueblo,ADM2,DISTRICT
SM267505,-39.2975,-13.1846,Laje,ADM2,DISTRICT
SM265275,-42.1327,-21.2376,Laje do Muriaé,ADM2,DISTRICT
SM9956,-52.0413,-29.4271,Lajeado (1996-),ADM2:ADM3,DISTRICT
SM266189,-52.5407,-26.8505,Lajeado Grande,ADM2,DISTRICT
SM262810,-46.9022,-6.1063,Lajeado Novo,ADM2,DISTRICT
SM318753,-53.2074,-27.7033,Lajeado do Bugre,ADM2:ADM3,DISTRICT
SM267506,-40.3112,-17.5711,Lajedao,ADM2,DISTRICT
SM267507,-41.151,-12.427,Lajedinho,ADM2,DISTRICT
SM264846,-36.2847,-8.6644,Lajedo,ADM2,DISTRICT
SM267508,-40.23,-13.3713,Lajedo do Tabocal,ADM2,DISTRICT
SM267252,-48.2806,-9.8693,Lajedão,ADM2,DISTRICT
SM265579,-36.1729,-5.7834,Lajes,ADM2,DISTRICT
SM265578,-36.1252,-6.1273,Lajes Pintadas,ADM2,DISTRICT
SM289391,-31.2173,39.4162,Lajes das Flores,ADM2,DISTRICT
SM289392,-28.174,38.428,Lajes do Pico,ADM2,DISTRICT
SM263596,-41.6153,-20.1241,Lajinha,ADM2,DISTRICT
SM235816,95.5811,26.8954,Laju,DIALECT,LANGUAGE
SM235836,47.0809,42.1328,Lak,LANGUAGE,LANGUAGE
SM255163,47.65,42.01,Lak,ETHNICITY,ETHNICITY
SM255163,47.65,42.01,Lak,ETHNICITY,ETHNICITY
SM255163,47.12,42.17,Lak,ETHNICITY,ETHNICITY
SM255163,47.12,42.17,Lak,ETHNICITY,ETHNICITY
SM297160,100.5689,13.8821,Lak Si,ADM2,DISTRICT
SM250409,17.78,7.5,Laka (Adamawa,ETHNICITY,ETHNICITY
SM250409,17.78,7.5,Laka (Adamawa,ETHNICITY,ETHNICITY
SM250409,16,8,Laka (Adamawa,ETHNICITY,ETHNICITY
SM250409,16,8,Laka (Adamawa,ETHNICITY,ETHNICITY
SM235820,16.0193,7.79,Laka (Chad),LANGUAGE,LANGUAGE
SM235818,11.228,9.1497,Laka of Lau (Nigeria),DIALECT,LANGUAGE
SM255164,135.25,-4.23,Lakahis,ETHNICITY,ETHNICITY
SM235821,125.716,-8.8637,Lakalei,LANGUAGE,LANGUAGE
SM10842,33.3076,35.115,Lakatamia,ADM2,DISTRICT
SM302510,-97.1294,44.022,Lake,ADM2,DISTRICT
SM302259,-120.3875,42.7934,Lake,ADM2,DISTRICT
SM302586,-89.4935,36.3354,Lake,ADM2,DISTRICT
SM302120,-81.2453,41.7139,Lake,ADM2,DISTRICT
SM301638,-114.0894,47.6459,Lake,ADM2,DISTRICT
SM301369,-91.4089,47.5232,Lake,ADM2,DISTRICT
SM301288,-85.8017,43.99,Lake,ADM2,DISTRICT
SM300730,-87.3764,41.4722,Lake,ADM2,DISTRICT
SM303171,-122.7532,39.0996,Lake,ADM2,DISTRICT
SM300637,-87.8618,42.3494,Lake,ADM2,DISTRICT
SM300340,-81.7113,28.7615,Lake,ADM2,DISTRICT
SM303346,-106.3448,39.2024,Lake,ADM2,DISTRICT
SM261404,119.4204,-33.1291,Lake Grace,ADM2,DISTRICT
SM255165,-116.63,49.76,Lake Indians,ETHNICITY,ETHNICITY
SM261568,151.5347,-33.0376,Lake Macquarie,ADM2,DISTRICT
SM235826,-122.621,39.0021,Lake Miwok,LANGUAGE,LANGUAGE
SM255166,-70.28,48.46,Lake St. John,ETHNICITY,ETHNICITY
SM258714,-119.333,35.167,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-119.333,35.167,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-119.333,35.167,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-119.333,35.167,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-120,36,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-120,36,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-120,36,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-120,36,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-119.83,36,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-119.83,36,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-119.83,36,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-119.83,36,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-119.5,35,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-119.5,35,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-119.5,35,Lake Yokuts,ETHNICITY,ETHNICITY
SM258714,-119.5,35,Lake Yokuts,ETHNICITY,ETHNICITY
SM301177,-158.0006,57.0006,Lake and Peninsula,ADM2,DISTRICT
SM301370,-94.905,48.7705,Lake of the Woods,ADM2,DISTRICT
SM1957,30,6.75,Lakes,ADM1,DISTRICT
SM10184,-99.7025,51.0832,Lakeshore,ADM2,DISTRICT
SM324004,-114.4585,53.5598,Lakeview,ADM2:ADM3,DISTRICT
SM235829,90.3336,27.6325,Lakha,LANGUAGE,LANGUAGE
SM457563,3.6667,36.5833,Lakhdaria District,ADM2,DISTRICT
SM293142,30.1833,61.5167,Lakhdenpokhskiy rayon,ADM2,DISTRICT
SM277388,94.25,27.35,Lakhimpur,ADM2,DISTRICT
SM277309,80.7167,28.1167,Lakhimpur Kheri,ADM2,DISTRICT
SM277412,86.2,25.2,Lakhisarai,ADM2,DISTRICT
SM280769,105.5975,15.8331,Lakhonepheng,ADM2,DISTRICT
SM235830,47.9398,34.0103,Laki,LANGUAGE,LANGUAGE
SM262354,24.85,41.8167,Laki,ADM2,DISTRICT
SM375412,70.8512,32.5992,Lakki Marwat,ADM2:ADM3,DISTRICT
SM15309,70.3769,32.6413,Lakki Marwat Tribal Area,ADM2,DISTRICT
SM235835,110.106,24.1169,Lakkia,LANGUAGE,LANGUAGE
SM255169,-123.44,45.05,Lakmiut,ETHNICITY,ETHNICITY
SM235838,167.4294,-14.299,Lakon,LANGUAGE,LANGUAGE
SM235841,-60.5,-13.2,Lakondê,LANGUAGE,LANGUAGE
SM235840,-103.95,46.3699,Lakota,LANGUAGE,LANGUAGE
SM235837,-5.4063,5.8601,Lakota Dida,LANGUAGE,LANGUAGE
SM276967,74.0458,11.2733,Lakshadweep,ADM2,DISTRICT
SM1958,72.2867,10.939,Lakshadweep,ADM1,DISTRICT
SM262240,90.8333,22.9167,Lakshmipur,ADM2,DISTRICT
SM292844,47.0833,42.1667,Lakskiy rayon,ADM2,DISTRICT
SM313182,17.3364,44.8767,Laktaši,ADM2:ADM3,DISTRICT
SM235843,151.25,-2.8833,Lakuramau,LANGUAGE,LANGUAGE
SM260119,71.0246,34.3334,Lal Pur,ADM2,DISTRICT
SM259990,66.2741,34.6043,Lal Wa Sarjangal,ADM2,DISTRICT
SM235851,146.749,-8.932,Lala,LANGUAGE,LANGUAGE
SM255172,30.54,-12.88,Lala (Zambia),ETHNICITY,ETHNICITY
SM255172,30.54,-12.88,Lala (Zambia),ETHNICITY,ETHNICITY
SM255172,30.47,-14.43,Lala (Zambia),ETHNICITY,ETHNICITY
SM255172,30.47,-14.43,Lala (Zambia),ETHNICITY,ETHNICITY
SM255172,31,-15,Lala (Zambia),ETHNICITY,ETHNICITY
SM255172,31,-15,Lala (Zambia),ETHNICITY,ETHNICITY
SM235847,30.2297,-12.945,Lala-Bisa,LANGUAGE,LANGUAGE
SM235844,12.169,9.8943,Lala-Roba,LANGUAGE,LANGUAGE
SM235853,-96.0065,17.5825,Lalana Chinantec,LANGUAGE,LANGUAGE
SM14170,47.2663,-21.4101,Lalangina,ADM2,DISTRICT
SM298345,26.7487,41.8506,Lalapaşa,ADM2,DISTRICT
SM283944,38.3492,-14.3865,Lalaua,ADM2,DISTRICT
SM297803,126.1667,-8.5333,Laleia,ADM2,DISTRICT
SM235855,23.3634,-1.1171,Lalia,DIALECT,LANGUAGE
SM7421,23,-1,Lalia,ETHNICITY,ETHNICITY
SM375298,85.3237,27.5483,Lalitpur,ADM2:ADM3,DISTRICT
SM277310,78.5,24.5,Lalitpur,ADM2,DISTRICT
SM262279,89.25,26,Lalmonirhat,ADM2,DISTRICT
SM262171,1.8833,6.9167,Lalo,ADM2,DISTRICT
SM292199,24.0469,44.5305,Lalosu,ADM2,DISTRICT
SM297282,100.7924,13.9774,Lam Luk Ka,ADM2,DISTRICT
SM297412,102.8748,15.0169,Lam Plai Mat,ADM2,DISTRICT
SM297057,101.3478,15.3283,Lam Son Thi,ADM2,DISTRICT
SM297005,99.3354,8.048,Lam Thap,ADM2,DISTRICT
SM235872,98.4694,27.6237,Lama (Myanmar),LANGUAGE,LANGUAGE
SM235863,0.8333,8.6695,Lama (Togo),LANGUAGE,LANGUAGE
SM283284,-101.8231,27.0901,Lamadrid,ADM2,DISTRICT
SM296925,99.0438,9.7557,Lamae,ADM2,DISTRICT
SM235865,122.803,-8.3644,Lamaholot,LANGUAGE,LANGUAGE
SM235922,143.977,-14.559,Lamalama,LANGUAGE,LANGUAGE
SM235873,107.336,14.3658,Lamam,LANGUAGE,LANGUAGE
SM276417,111.2869,-1.8383,Lamandau,ADM2,DISTRICT
SM235871,13.7362,11.0243,Lamang,LANGUAGE,LANGUAGE
SM275858,-87.6279,14.1633,Lamaní,ADM2,DISTRICT
SM302685,-95.5712,33.6673,Lamar,ADM2,DISTRICT
SM301456,-89.5087,31.2059,Lamar,ADM2,DISTRICT
SM300523,-84.1395,33.0765,Lamar,ADM2,DISTRICT
SM300277,-88.0969,33.7792,Lamar,ADM2,DISTRICT
SM280827,106.8171,15.4127,Lamarm,ADM2,DISTRICT
SM267510,-38.9034,-11.7762,Lamarão,ADM2,DISTRICT
SM286839,-76.6667,-6.3333,Lamas,ADM2,DISTRICT
SM235867,123.586,-8.3937,Lamatuka,LANGUAGE,LANGUAGE
SM17479,-61.0191,13.8065,Lamaze,ADM2,DISTRICT
SM302686,-102.3517,34.0686,Lamb,ADM2,DISTRICT
SM235885,27.9026,-12.3366,Lamba,LANGUAGE,LANGUAGE
SM250412,27.27,-12.29,Lamba,ETHNICITY,ETHNICITY
SM250412,27.27,-12.29,Lamba,ETHNICITY,ETHNICITY
SM250412,28,-13,Lamba,ETHNICITY,ETHNICITY
SM250412,28,-13,Lamba,ETHNICITY,ETHNICITY
SM235883,76.9717,16.5734,Lambadi,LANGUAGE,LANGUAGE
SM263597,-45.3517,-21.9859,Lambari,ADM2,DISTRICT
SM263177,-57.7881,-15.6546,Lambari d'Oeste,ADM2,DISTRICT
SM289733,-57.6158,-25.3536,Lambaré,ADM2,DISTRICT
SM286767,-80.0833,-6.0833,Lambayeque,ADM2,DISTRICT
SM1960,-80,-6.3333,Lambayeque,ADM1,DISTRICT
SM235890,-79.468,-6.1715,Lambayeque Quechua,LANGUAGE,LANGUAGE
SM235891,87.2168,27.0543,Lambichhong,LANGUAGE,LANGUAGE
SM235887,119.344,-9.7043,Lamboya,LANGUAGE,LANGUAGE
SM12467,-77.9141,18.2682,Lambs River,ADM2,DISTRICT
SM268860,-82.0831,42.7501,Lambton,ADM2,DISTRICT
SM11416,-2.7,10.8333,Lambussie Karni,ADM2,DISTRICT
SM235886,33.1585,-9.4937,Lambya,LANGUAGE,LANGUAGE
SM250413,32.7,-9.84,Lambya,ETHNICITY,ETHNICITY
SM297653,103.6991,14.7191,Lamduan,ADM2,DISTRICT
SM235894,8.7877,10.7229,Lame,LANGUAGE,LANGUAGE
SM255178,101,20,Lamed,ETHNICITY,ETHNICITY
SM255178,101,20,Lamed,ETHNICITY,ETHNICITY
SM255178,100.67,20,Lamed,ETHNICITY,ETHNICITY
SM255178,100.67,20,Lamed,ETHNICITY,ETHNICITY
SM289414,-7.8103,41.1023,Lamego,ADM2,DISTRICT
SM235896,168.171,-16.6098,Lamenu,LANGUAGE,LANGUAGE
SM235893,100.268,19.8829,Lamet,LANGUAGE,LANGUAGE
SM296708,31.075,-26.5614,Lamgabhi,ADM2,DISTRICT
SM263598,-43.4849,-20.7748,Lamim,ADM2,DISTRICT
SM255179,-77.23,-5.88,Lamisto,ETHNICITY,ETHNICITY
SM235905,12.1644,8.6377,Lamja-Dengsa-Tola,LANGUAGE,LANGUAGE
SM375357,84.4205,28.2829,Lamjung,ADM2:ADM3,DISTRICT
SM232268,85.6289,27.9212,Lamjung-Melamchi Yolmo,LANGUAGE,LANGUAGE
SM235908,94.2706,24.1523,Lamkang,LANGUAGE,LANGUAGE
SM235911,11.0684,6.3974,Lamnso',LANGUAGE,LANGUAGE
SM235915,97.4208,29.8924,Lamo,LANGUAGE,LANGUAGE
SM235914,149.321,-5.8707,Lamogai,LANGUAGE,LANGUAGE
SM302934,-72.6415,44.6058,Lamoille,ADM2,DISTRICT
SM276348,112.3167,-7.1333,Lamongan,ADM2,DISTRICT
SM323764,-112.7719,53.7586,Lamont,ADM2:ADM3,DISTRICT
SM323766,-112.478,53.7487,Lamont County,ADM2:ADM3,DISTRICT
SM255181,146.38,7.46,Lamotrek,ETHNICITY,ETHNICITY
SM255181,146.38,7.46,Lamotrek,ETHNICITY,ETHNICITY
SM255181,146.38,7.46,Lamotrek,ETHNICITY,ETHNICITY
SM255181,146.38,7.46,Lamotrek,ETHNICITY,ETHNICITY
SM14939,146.303,7.491,Lamotrek Municipality,ADM2,DISTRICT
SM302045,-98.5355,46.4569,Lamoure,ADM2,DISTRICT
SM286832,-70.5833,-15.4167,Lampa,ADM2,DISTRICT
SM1961,99.5,18.3333,Lampang,ADM1,DISTRICT
SM302687,-98.2415,31.1962,Lampasas,ADM2,DISTRICT
SM282172,-100.4041,27.0182,Lampazos de Naranjo,ADM2,DISTRICT
SM1962,98.9167,18.25,Lamphun,ADM1,DISTRICT
SM1963,105,-5,Lampung,ADM1,DISTRICT
SM235919,105.037,-5.7346,Lampung Api,LANGUAGE,LANGUAGE
SM235918,105.397,-4.4396,Lampung Nyo,LANGUAGE,LANGUAGE
SM276446,105.4974,-5.5397,Lampung Selatan,ADM2,DISTRICT
SM276447,105.2667,-4.8667,Lampung Tengah,ADM2,DISTRICT
SM276448,105.68,-5.1027,Lampung Timur,ADM2,DISTRICT
SM276449,104.8,-4.8167,Lampung Utara,ADM2,DISTRICT
SM235925,100.15,25.8,Lamu,LANGUAGE,LANGUAGE
SM1964,40.75,-2.0833,Lamu,ADM1:ADM2,DISTRICT
SM1964,40.75,-2.0833,Lamu,ADM1:ADM2,DISTRICT
SM1964,40.9,-2.28,Lamu,ADM1:ADM2,DISTRICT
SM1964,40.9,-2.28,Lamu,ADM1:ADM2,DISTRICT
SM284852,11.7179,9.5705,Lamurde,ADM2,DISTRICT
SM299303,32.7506,3.55,Lamwo,ADM1:ADM2,DISTRICT
SM296958,99.8652,16.6035,Lan Krabu,ADM2,DISTRICT
SM297735,99.4611,15.5229,Lan Sak,ADM2,DISTRICT
SM297211,99.7833,8.378,Lan Saka,ADM2,DISTRICT
SM1965,124,8,Lanao del Norte,ADM1:ADM2,DISTRICT
SM1966,124.4586,7.8525,Lanao del Sur,ADM1:ADM2,DISTRICT
SM268861,-76.3327,45.0834,Lanark,ADM2,DISTRICT
SM235927,116.577,5.392,Lanas Lobu,LANGUAGE,LANGUAGE
SM10213,-73.8325,46.7668,Lanaudiere,ADM2,DISTRICT
SM274645,-2.5,53.8333,Lancashire,ADM1:ADM2,DISTRICT
SM303035,-76.4202,37.7017,Lancaster,ADM2,DISTRICT
SM302314,-76.2477,40.0424,Lancaster,ADM2,DISTRICT
SM302453,-80.7054,34.6867,Lancaster,ADM2,DISTRICT
SM301728,-96.6878,40.7842,Lancaster,ADM2,DISTRICT
SM12468,-77.4613,17.936,Lancaster,ADM2,DISTRICT
SM12469,-78.2144,18.4382,Lances Bay,ADM2,DISTRICT
SM274791,41.95,42.1,Lanchkhuti,ADM2,DISTRICT
SM282448,-99.1777,21.2355,Landa de Matamoros,ADM2,DISTRICT
SM276388,109.7592,0.4237,Landak,ADM2,DISTRICT
SM260415,12.2385,-5.0382,Landana,ADM2,DISTRICT
SM261870,10.5668,47.1257,Landeck,ADM2,DISTRICT
SM11781,50.4247,30.9799,Landeh,ADM2,DISTRICT
SM303638,-66.75,10.0833,Lander,ADM2,DISTRICT
SM301787,-117.0379,39.9338,Lander,ADM2,DISTRICT
SM285930,5.6658,51.7115,Landerd,ADM2,DISTRICT
SM282926,-96.8501,19.7394,Landero y Coss,ADM2,DISTRICT
SM274444,-0.7424,43.9754,Landes,ADM2,DISTRICT
SM285866,6.0572,50.9133,Landgraaf,ADM2,DISTRICT
SM235937,24.03,45.78,Landler,DIALECT,LANGUAGE
SM235931,-14.0895,10.8753,Landoma,LANGUAGE,LANGUAGE
SM250415,-14.82,11.96,Landouma,ETHNICITY,ETHNICITY
SM268914,9.5637,46.964,Landquart,ADM2,DISTRICT
SM265046,-43.8646,-7.2327,Landri Sales,ADM2,DISTRICT
SM296435,12.8588,55.8862,Landskrona,ADM2,DISTRICT
SM285995,4.9157,52.431,Landsmeer,ADM2,DISTRICT
SM270482,-73.8513,6.3065,Landázuri,ADM2,DISTRICT
SM302261,-122.8766,43.9416,Lane,ADM2,DISTRICT
SM300935,-100.4664,38.4813,Lane,ADM2,DISTRICT
SM261569,151.1703,-33.8232,Lane Cove,ADM2,DISTRICT
SM10843,32.9185,34.8301,Laneia,ADM2,DISTRICT
SM304531,105.1507,20.1593,Lang Chánh,ADM2,DISTRICT
SM296926,99.0529,9.9339,Lang Suan,ADM2,DISTRICT
SM295431,160.7119,-8.7965,Langalanga,ADM2,DISTRICT
SM255185,146.1,-7.37,Langamar,ETHNICITY,ETHNICITY
SM11617,-15.1897,66.0519,Langanesbyggd,ADM2,DISTRICT
SM235947,20.109,4.8752,Langbashe,LANGUAGE,LANGUAGE
SM285996,4.7866,52.6915,Langedijk,ADM2,DISTRICT
SM271739,10.7722,54.9,Langeland,ADM2,DISTRICT
SM269143,116.6833,39.5167,Langfang,ADM2,DISTRICT
SM15092,97.9969,20.2398,Langhko District,ADM2,DISTRICT
SM235949,35.9517,-4.7141,Langi,LANGUAGE,LANGUAGE
SM255186,32.98,2.14,Langi,ETHNICITY,ETHNICITY
SM255186,32.98,2.14,Langi,ETHNICITY,ETHNICITY
SM255186,33,2,Langi,ETHNICITY,ETHNICITY
SM255186,33,2,Langi,ETHNICITY,ETHNICITY
SM276680,98.2167,3.7167,Langkat,ADM2,DISTRICT
SM284408,99.7838,6.3561,Langkawi,ADM2,DISTRICT
SM303250,-89.0719,45.2624,Langlade,ADM2,DISTRICT
SM235945,106.0169,23.5503,Langnian Buyang,LANGUAGE,LANGUAGE
SM235951,33.0412,4.0495,Lango (Sudan),LANGUAGE,LANGUAGE
SM235953,33.0553,2.1387,Lango (Uganda),LANGUAGE,LANGUAGE
SM277731,50.1167,37.15,Langrud,ADM2,DISTRICT
SM276287,97.93,4.47,Langsa,ADM2,DISTRICT
SM285195,9.8225,9.0565,Langtang North,ADM2,DISTRICT
SM285180,9.8103,8.6025,Langtang South,ADM2,DISTRICT
SM297550,99.7984,6.9039,Langu,ADM2,DISTRICT
SM275798,-87.6327,13.6603,Langue,ADM2,DISTRICT
SM226392,-8.0014,12.651,Langue de Signes Malienne,LANGUAGE,LANGUAGE
SM235961,17.7887,-3.6211,Langue des Signes Zairoise,LANGUAGE,LANGUAGE
SM235962,-3.5482,8.0659,Langue des Signes de Bouakako,LANGUAGE,LANGUAGE
SM235938,4.3131,50.8021,Langue des signes de Belgique Francophone,LANGUAGE,LANGUAGE
SM235939,2.5378,43.8899,Languedocian Occitan,DIALECT,LANGUAGE
SM261119,-70.6667,-43.3333,Languiñeo,ADM2,DISTRICT
SM255187,139.76,-3.41,Lani,ETHNICITY,ETHNICITY
SM300524,-83.0627,31.0379,Lanier,ADM2,DISTRICT
SM305662,14.082,45.4352,Lanišće,ADM2,DISTRICT
SM277691,51.2,32.3667,Lanjan,ADM2,DISTRICT
SM4952,68.1921,27.5211,"Lankara (ADM3,2004-)",ADM2:ADM3,DISTRICT
SM276527,138.2877,-3.9124,Lanny Jaya,ADM2,DISTRICT
SM250417,33.44,4.42,Lano,ETHNICITY,ETHNICITY
SM235976,100.986,4.9093,Lanoh,LANGUAGE,LANGUAGE
SM255188,100.1,6.75,Lanoh,ETHNICITY,ETHNICITY
SM299751,26.0901,49.8623,Lanovets'kyi,ADM2,DISTRICT
SM235977,99.2581,26.5159,Lanping,DIALECT,LANGUAGE
SM275169,-89.9906,15.5644,Lanquín,ADM2,DISTRICT
SM296795,16.6585,8.5786,Lanya,ADM2,DISTRICT
SM269413,103.7332,36.4744,Lanzhou,ADM2,DISTRICT
SM235979,103.8384,36.0805,Lanzhou Mandarin,DIALECT,LANGUAGE
SM260785,-58.4167,-34.6667,Lanús,ADM2,DISTRICT
SM235986,102.46,19,Lao,LANGUAGE,LANGUAGE
SM255189,102.83,15.96,Lao,ETHNICITY,ETHNICITY
SM255189,100.37,17.3,Lao,ETHNICITY,ETHNICITY
SM296969,99.6834,14.5949,Lao Khwan,ADM2,DISTRICT
SM280770,106.1411,15.4775,Lao Ngarm,ADM2,DISTRICT
SM1969,-7.4,53,Laoighis,ADM1:ADM2,DISTRICT
SM235983,100.1,21.95,Laomian,LANGUAGE,LANGUAGE
SM235987,100.079,20.7809,Laopang,LANGUAGE,LANGUAGE
SM235988,104.518,18.387,Laos Sign Language,LANGUAGE,LANGUAGE
SM9958,-49.874,-25.7914,Lapa,ADM2,DISTRICT
SM15841,-66.1976,18.0854,Lapa Barrio (Cayey),ADM2,DISTRICT
SM15842,-66.2543,18.0367,Lapa Barrio (Salinas),ADM2,DISTRICT
SM235993,-96.1636,16.0676,Lapaguía-Guivini Zapotec,LANGUAGE,LANGUAGE
SM17992,-175.0799,-21.1621,Lapaha,ADM2,DISTRICT
SM285025,6.6755,8.7982,Lapai,ADM2,DISTRICT
SM10844,33.8271,35.2743,Lapathos,ADM2,DISTRICT
SM301293,-83.2218,43.0902,Lapeer,ADM2,DISTRICT
SM10845,32.5929,34.9045,Lapithiou,ADM2,DISTRICT
SM10846,33.1668,35.3356,Lapithos,ADM2,DISTRICT
SM297743,100.0148,17.6574,Laplae,ADM2,DISTRICT
SM274389,26.5,67.75,Lapland,ADM1:ADM2,DISTRICT
SM13628,23.4652,57.0034,Lapmezciema pagasts,ADM2,DISTRICT
SM291539,26.428,45.1508,Lapos,ADM2,DISTRICT
SM11222,27.9164,61.038,Lappeenranta (2009-),ADM2,DISTRICT
SM260786,-60.75,-37.5,Laprida,ADM2,DISTRICT
SM298263,26.7066,40.3528,Lapseki,ADM2,DISTRICT
SM290765,22.4575,45.8922,Lapugiu De Jos,ADM2,DISTRICT
SM291011,24.0065,47.4936,Lapus,ADM2,DISTRICT
SM290024,22.2071,44.9868,Lapusnicel,ADM2,DISTRICT
SM290025,21.9332,44.8852,Lapusnicu Mare,ADM2,DISTRICT
SM235995,123.13,7.5345,Lapuyan Subanun,LANGUAGE,LANGUAGE
SM267511,-41.7052,-11.6158,Lapão,ADM2,DISTRICT
SM1971,-69.8333,10.1667,Lara,ADM1,DISTRICT
SM281434,-5.8333,35.1667,Larache,ADM2,DISTRICT
SM235996,130.922,-12.4111,Laragia,LANGUAGE,LANGUAGE
SM255196,131.26,-12.46,Laragia,ETHNICITY,ETHNICITY
SM255196,131.26,-12.46,Laragia,ETHNICITY,ETHNICITY
SM255196,131.26,-12.46,Laragia,ETHNICITY,ETHNICITY
SM255196,130.79,-12.6,Laragia,ETHNICITY,ETHNICITY
SM255196,130.79,-12.6,Laragia,ETHNICITY,ETHNICITY
SM255196,130.79,-12.6,Laragia,ETHNICITY,ETHNICITY
SM255196,130.8414,-12.5176,Laragia,ETHNICITY,ETHNICITY
SM255196,130.8414,-12.5176,Laragia,ETHNICITY,ETHNICITY
SM255196,130.8414,-12.5176,Laragia,ETHNICITY,ETHNICITY
SM303297,-104.6896,41.3071,Laramie,ADM2,DISTRICT
SM268001,-41.057,-19.8658,Laranja da Terra,ADM2,DISTRICT
SM264540,-52.4595,-24.9257,Laranjal,ADM2,DISTRICT
SM263599,-42.4566,-21.3592,Laranjal,ADM2,DISTRICT
SM266653,-47.8539,-23.0158,Laranjal Paulista,ADM2,DISTRICT
SM267300,-53.2295,0.9383,Laranjal do Jari,ADM2,DISTRICT
SM267103,-37.169,-10.807,Laranjeiras,ADM2,DISTRICT
SM264541,-52.3699,-25.3356,Laranjeiras do Sul,ADM2,DISTRICT
SM255197,123.56,-8.37,Larantuka,ETHNICITY,ETHNICITY
SM255197,123.56,-8.37,Larantuka,ETHNICITY,ETHNICITY
SM255197,122.69,-8.76,Larantuka,ETHNICITY,ETHNICITY
SM255197,122.69,-8.76,Larantuka,ETHNICITY,ETHNICITY
SM255197,122.85,-8.33,Larantuka,ETHNICITY,ETHNICITY
SM255197,122.85,-8.33,Larantuka,ETHNICITY,ETHNICITY
SM235998,122.7619,-8.4746,Larantuka Malay,LANGUAGE,LANGUAGE
SM457324,4.1667,36.6667,Larbaâ Nath Irathen District,ADM2,DISTRICT
SM236000,139.4343,-16.5615,Lardil,LANGUAGE,LANGUAGE
SM255198,139.44,-16.56,Lardil,ETHNICITY,ETHNICITY
SM255198,139.44,-16.56,Lardil,ETHNICITY,ETHNICITY
SM255198,139.44,-16.56,Lardil,ETHNICITY,ETHNICITY
SM255198,139.32,-16.48,Lardil,ETHNICITY,ETHNICITY
SM255198,139.32,-16.48,Lardil,ETHNICITY,ETHNICITY
SM255198,139.32,-16.48,Lardil,ETHNICITY,ETHNICITY
SM255198,139.4589,-16.5643,Lardil,ETHNICITY,ETHNICITY
SM255198,139.4589,-16.5643,Lardil,ETHNICITY,ETHNICITY
SM255198,139.4589,-16.5643,Lardil,ETHNICITY,ETHNICITY
SM262734,-68.189,-15.6293,Larecaja,ADM2,DISTRICT
SM285997,5.217,52.2452,Laren,ADM2,DISTRICT
SM1972,-66.8691,18.2702,Lares,ADM1,DISTRICT
SM15843,-66.844,18.2943,Lares Barrio,ADM2,DISTRICT
SM15844,-66.8814,18.296,Lares Barrio-Pueblo,ADM2,DISTRICT
SM236006,54.6345,27.6436,Larestani,LANGUAGE,LANGUAGE
SM236003,167.387,-16.1971,Larevat,LANGUAGE,LANGUAGE
SM289882,27.1626,44.9745,Largu,ADM2,DISTRICT
SM236008,127.956,-3.7652,Larike-Wakasihu,LANGUAGE,LANGUAGE
SM303347,-105.4612,40.6664,Larimer,ADM2,DISTRICT
SM324075,-113.7685,54.4387,Larkspur,ADM2:ADM3,DISTRICT
SM1973,33.5262,34.9152,Larnaca,ADM1,DISTRICT
SM10847,33.6215,34.9039,Larnaka,ADM2,DISTRICT
SM10848,33.1314,35.3074,Larnakas Lapithou,ADM2,DISTRICT
SM370536,6.2178,49.7848,Larochette,ADM1:ADM2:ADM3,DISTRICT
SM297973,9.4611,36.3654,Laroussa,ADM2,DISTRICT
SM14315,-92.7637,16.9137,Larrainzar (1999-),ADM2,DISTRICT
SM285420,-86.6111,12.6567,Larreynaga-Malpaisillo,ADM2,DISTRICT
SM236011,-0.0782,5.9367,Larteh,LANGUAGE,LANGUAGE
SM236012,4.4351,10.5756,Laru (Nigeria),LANGUAGE,LANGUAGE
SM236010,30.3824,11.3915,Laru (North Sudan),LANGUAGE,LANGUAGE
SM301067,-85.6979,37.5458,Larue,ADM2,DISTRICT
SM14184,100.8022,5.0606,Larut Matang & Selama,ADM2,DISTRICT
SM461887,10.027,59.0523,Larvik (2018-),ADM2,DISTRICT
SM303348,-104.0387,37.3159,Las Animas,ADM2,DISTRICT
SM271762,-70.5461,18.3918,Las Charcas,ADM2,DISTRICT
SM14316,-94.0041,17.6785,Las Choapas (1997-),ADM2,DISTRICT
SM261018,-61.1333,-31.5,Las Colonias,ADM2,DISTRICT
SM11510,-90.1839,16.6361,Las Cruces,ADM2,DISTRICT
SM275701,-88.6504,14.6793,Las Flores,ADM2,DISTRICT
SM260787,-59.0833,-36.0833,Las Flores,ADM2,DISTRICT
SM271900,-70.2239,19.201,Las Guaranas,ADM2,DISTRICT
SM260895,-69.5,-32.5,Las Heras,ADM2,DISTRICT
SM275859,-87.5833,14.9,Las Lajas,ADM2,DISTRICT
SM273626,-80.0585,-3.8056,Las Lajas,ADM2,DISTRICT
SM14317,-91.7403,16.3856,Las Margaritas (1999-),ADM2,DISTRICT
SM15845,-66.9908,18.2523,Las Marias Barrio-Pueblo,ADM2,DISTRICT
SM1974,-66.9924,18.2509,Las Marías,ADM1,DISTRICT
SM271845,-71.5,18.9167,Las Matas de Farfan,ADM2,DISTRICT
SM271799,-71.5,19.6333,Las Matas de Santa Cruz,ADM2,DISTRICT
SM303598,-66.4,9.1019,Las Mercedes,ADM2,DISTRICT
SM286707,-80.8225,7.7653,Las Minas,ADM2,DISTRICT
SM282928,-97.147,19.7045,Las Minas,ADM2,DISTRICT
SM273552,-79.3,-1.2833,Las Naves,ADM2,DISTRICT
SM274050,-14.0131,28.4204,Las Palmas,ADM2,DISTRICT
SM286672,-81.4285,8.1453,Las Palmas,ADM2,DISTRICT
SM15846,-66.6574,18.2362,Las Palmas Barrio,ADM2,DISTRICT
SM18336,-56.2012,-34.7056,Las Piedras,ADM2,DISTRICT
SM1975,-65.8704,18.1883,Las Piedras,ADM1,DISTRICT
SM15847,-65.866,18.1827,Las Piedras Barrio-Pueblo,ADM2,DISTRICT
SM283161,-92.3702,16.3566,Las Rosas,ADM2,DISTRICT
SM285425,-86.6201,13.3415,Las Sabanas,ADM2,DISTRICT
SM271869,-71.3444,18.2325,Las Salinas,ADM2,DISTRICT
SM286715,-80.2993,7.6484,Las Tablas,ADM2,DISTRICT
SM271828,-69.5546,19.2858,Las Terrenas,ADM2,DISTRICT
SM270996,-77.0235,20.9418,Las Tunas,ADM2,DISTRICT
SM1976,-76.9,21.1,Las Tunas,ADM1,DISTRICT
SM275767,-88.0723,14.8664,Las Vegas,ADM2,DISTRICT
SM282929,-97.0882,19.6327,Las Vigas de Ramírez,ADM2,DISTRICT
SM295612,-88.8697,14.0888,Las Vueltas,ADM2,DISTRICT
SM271763,-70.9997,18.604,Las Yayas de Viajama,ADM2,DISTRICT
SM10849,32.5253,34.9375,Lasa,ADM2,DISTRICT
SM236013,123.129,-5.236,Lasalimu,LANGUAGE,LANGUAGE
SM375380,66.6033,25.7863,Lasbela,ADM2:ADM3,DISTRICT
SM295795,47.6364,8.438,Lascaanod,ADM2,DISTRICT
SM276073,-71.8677,18.839,Lascahobas,ADM2,DISTRICT
SM236014,52.9987,35.4015,Lasgerdi,LANGUAGE,LANGUAGE
SM260274,61.3288,31.7203,Lash Wa Juwayn,ADM2,DISTRICT
SM255201,59.29,28.09,Lashari Baloch,ETHNICITY,ETHNICITY
SM236016,98.5481,26.0992,Lashi,LANGUAGE,LANGUAGE
SM15094,98.1501,22.8308,Lashio (2014-),ADM2,DISTRICT
SM260004,64.5402,31.4562,Lashkargah,ADM2,DISTRICT
SM236018,66.9137,25.3293,Lasi,LANGUAGE,LANGUAGE
SM305689,15.8585,45.5281,Lasinja,ADM2,DISTRICT
SM291726,24.6384,46.1497,Laslea,ADM2,DISTRICT
SM263600,-44.7086,-17.9101,Lassance,ADM2,DISTRICT
SM303172,-120.5943,40.6736,Lassen,ADM2,DISTRICT
SM255204,-122.65,39.41,Lassik,ETHNICITY,ETHNICITY
SM255204,-122.65,39.41,Lassik,ETHNICITY,ETHNICITY
SM255204,-122.65,39.41,Lassik,ETHNICITY,ETHNICITY
SM255204,-123,40,Lassik,ETHNICITY,ETHNICITY
SM255204,-123,40,Lassik,ETHNICITY,ETHNICITY
SM255204,-123,40,Lassik,ETHNICITY,ETHNICITY
SM255204,-123.16,40.18,Lassik,ETHNICITY,ETHNICITY
SM255204,-123.16,40.18,Lassik,ETHNICITY,ETHNICITY
SM255204,-123.16,40.18,Lassik,ETHNICITY,ETHNICITY
SM17480,-60.9832,14.0076,Lastic Hill,ADM2,DISTRICT
SM305649,16.9,42.75,Lastovo,ADM2,DISTRICT
SM264228,-38.1895,-6.5374,Lastro,ADM2,DISTRICT
SM297388,100.3489,14.1681,Lat Bua Luang,ADM2,DISTRICT
SM297161,100.7922,13.745,Lat Krabang,ADM2,DISTRICT
SM297283,100.4095,14.0446,Lat Lum Kaeo,ADM2,DISTRICT
SM297162,100.6067,13.8278,Lat Phrao,ADM2,DISTRICT
SM297206,99.8005,15.7753,Lat Yao,ADM2,DISTRICT
SM273608,-78.6167,-0.9333,Latacunga,ADM2,DISTRICT
SM300565,-116.7117,46.8161,Latah,ADM2,DISTRICT
SM296740,35.9415,35.7243,Latakia,ADM2,DISTRICT
SM236030,35.195,32.2764,Late Palestinian Aramaic,LANGUAGE,LANGUAGE
SM276904,84.4,23.75,Latehar,ADM2,DISTRICT
SM302204,-95.2504,34.8761,Latimer,ADM2,DISTRICT
SM236033,12.4502,41.9026,Latin,LANGUAGE,LANGUAGE
SM277961,12.901,41.4592,Latina,ADM2,DISTRICT
SM296096,-55.2053,5.7955,Latour,ADM2,DISTRICT
SM261766,146.4066,-41.2155,Latrobe,ADM2,DISTRICT
SM261246,146.4612,-38.2538,Latrobe,ADM2,DISTRICT
SM10850,33.3745,35.0947,Latsia,ADM2,DISTRICT
SM1978,36.0333,35.5667,Lattakia,ADM1,DISTRICT
SM236037,128.667,-3.413,Latu,LANGUAGE,LANGUAGE
SM236038,-60.4224,-12.6088,Latundê,LANGUAGE,LANGUAGE
SM277039,76.8,18.4,Latur,ADM2,DISTRICT
SM255206,24.75,56.2,Latvia,ETHNICITY,ETHNICITY
SM255206,24.75,56.2,Latvia,ETHNICITY,ETHNICITY
SM255206,24,57,Latvia,ETHNICITY,ETHNICITY
SM255206,24,57,Latvia,ETHNICITY,ETHNICITY
SM236040,24.3091,56.8261,Latvian,LANGUAGE,LANGUAGE
SM236039,24.4931,56.9335,Latvian Sign Language,LANGUAGE,LANGUAGE
SM236054,160.872,-8.5102,Lau,LANGUAGE,LANGUAGE
SM285243,11.4201,9.187,Lau,ADM2,DISTRICT
SM274412,-178.7925,-18.2049,Lau,ADM2,DISTRICT
SM297794,125.5821,-8.6231,Lau Lara,ADM2,DISTRICT
SM454397,160.64,-8.36,"Lau, Malaita",ETHNICITY,ETHNICITY
SM236044,149.2575,-10.1615,Laua,LANGUAGE,LANGUAGE
SM255207,-178.79,-18.21,Laua,ETHNICITY,ETHNICITY
SM255207,-178.79,-18.21,Laua,ETHNICITY,ETHNICITY
SM255207,-179.31,-17.74,Laua,ETHNICITY,ETHNICITY
SM255207,-179.31,-17.74,Laua,ETHNICITY,ETHNICITY
SM236042,-178.785,-18.2131,Lauan,LANGUAGE,LANGUAGE
SM13629,25.0142,56.8375,Lauberes pagasts,ADM2,DISTRICT
SM13630,26.5409,55.8152,Laucesas pagasts,ADM2,DISTRICT
SM13631,22.8294,57.2437,Laucienes pagasts,ADM2,DISTRICT
SM302587,-89.6314,35.761,Lauderdale,ADM2,DISTRICT
SM301457,-88.6625,32.4043,Lauderdale,ADM2,DISTRICT
SM300278,-87.654,34.9014,Lauderdale,ADM2,DISTRICT
SM13632,27.9628,56.322,Lauderu pagasts,ADM2,DISTRICT
SM13633,26.1658,56.692,Laudonas pagasts,ADM2,DISTRICT
SM269023,7.4875,47.4232,Laufen,ADM2,DISTRICT
SM268903,8.0388,47.5184,Laufenburg,ADM2,DISTRICT
SM236045,120.262,0.5071,Lauje,LANGUAGE,LANGUAGE
SM283484,98.654,23.8338,Lauking,ADM2,DISTRICT
SM261767,147.3124,-41.321,Launceston,ADM2,DISTRICT
SM13634,25.9317,57.3443,Launkalnes pagasts,ADM2,DISTRICT
SM236050,119.19,-9.4296,Laura,DIALECT,LANGUAGE
SM301068,-84.1178,37.1107,Laurel,ADM2,DISTRICT
SM289616,-57.583,-27.1764,Laureles,ADM2,DISTRICT
SM302455,-82.0059,34.4836,Laurens,ADM2,DISTRICT
SM300525,-82.9222,32.4637,Laurens,ADM2,DISTRICT
SM236051,-72.5,45.33,Laurentian,LANGUAGE,LANGUAGE
SM10214,-74.9826,46.4334,Laurentides,ADM2,DISTRICT
SM266190,-49.7341,-27.2085,Laurentino,ADM2,DISTRICT
SM286735,-76.7036,-10.2006,Lauricocha,ADM2,DISTRICT
SM266191,-49.4672,-28.3854,Lauro Muller,ADM2,DISTRICT
SM267512,-38.3273,-12.8425,Lauro de Freitas,ADM2,DISTRICT
SM269002,6.6333,46.5213,Lausanne,ADM2,DISTRICT
SM275695,-87.6579,13.8643,Lauterique,ADM2,DISTRICT
SM236052,93.4448,22.2009,Lautu,LANGUAGE,LANGUAGE
SM297851,126.9039,-8.3651,Lautém,ADM2,DISTRICT
SM1979,126.97,-8.5147,Lautém,ADM1,DISTRICT
SM302688,-96.9302,29.3844,Lavaca,ADM2,DISTRICT
SM15848,-67.1008,18.1343,Lavadero Barrio,ADM2,DISTRICT
SM268668,-73.7492,45.5834,Laval,ADM2,DISTRICT
SM261152,-58.9167,-29,Lavalle,ADM2,DISTRICT
SM260896,-68,-32.5,Lavalle,ADM2,DISTRICT
SM1980,-55,-34,Lavalleja,ADM1,DISTRICT
SM267253,-46.3931,-12.8366,Lavandeira,ADM2,DISTRICT
SM286227,17.8056,68.7472,Lavangen,ADM2,DISTRICT
SM236057,151.728,-3.219,Lavatbura-Lamusong,LANGUAGE,LANGUAGE
SM269003,6.7709,46.5362,Lavaux-Oron,ADM2,DISTRICT
SM236059,106.322,15.2726,Laven,LANGUAGE,LANGUAGE
SM261405,125.2713,-27.9379,Laverton,ADM2,DISTRICT
SM255210,150.19,-2.55,Lavongai,ETHNICITY,ETHNICITY
SM263601,-45.0419,-21.2674,Lavras,ADM2,DISTRICT
SM267783,-38.9764,-6.7308,Lavras da Mangabeira,ADM2,DISTRICT
SM265741,-54.0283,-30.8498,Lavras do Sul,ADM2,DISTRICT
SM266657,-44.8806,-22.5171,Lavrinhas,ADM2,DISTRICT
SM245989,101,26.4,Lavu-Yongsheng-Talu,LANGUAGE,LANGUAGE
SM236062,159.119,-9.0557,Lavukaleve,LANGUAGE,LANGUAGE
SM285026,5.6833,9.2735,Lavun,ADM2,DISTRICT
SM266656,-51.0518,-21.1707,Lavínia,ADM2,DISTRICT
SM236064,115.876,-1.8026,Lawangan,LANGUAGE,LANGUAGE
SM277090,92.9,22.53,Lawangtlai,ADM2,DISTRICT
SM304957,45.8008,13.8059,Lawdar,ADM2,DISTRICT
SM236069,106.7344,15.3067,Lawi,LANGUAGE,LANGUAGE
SM11417,-2.7998,10.7245,Lawra (2012-),ADM2,DISTRICT
SM302512,-103.7922,44.3586,Lawrence,ADM2,DISTRICT
SM302315,-80.3342,40.9913,Lawrence,ADM2,DISTRICT
SM302589,-87.3956,35.2173,Lawrence,ADM2,DISTRICT
SM302122,-82.5368,38.5985,Lawrence,ADM2,DISTRICT
SM301573,-93.8329,37.1064,Lawrence,ADM2,DISTRICT
SM301458,-90.107,31.5502,Lawrence,ADM2,DISTRICT
SM301069,-82.7348,38.0679,Lawrence,ADM2,DISTRICT
SM300733,-86.4835,38.8412,Lawrence,ADM2,DISTRICT
SM300639,-87.7267,38.72,Lawrence,ADM2,DISTRICT
SM302383,-91.1071,36.0413,Lawrence,ADM2,DISTRICT
SM300280,-87.311,34.5217,Lawrence,ADM2,DISTRICT
SM12470,-76.8474,18.1374,Lawrence Tavern,ADM2,DISTRICT
SM236073,101.4988,24.1339,Lawu,LANGUAGE,LANGUAGE
SM236072,155.129,-6.152,Lawunuia,LANGUAGE,LANGUAGE
SM276786,-4.404,54.2312,Laxey,ADM1:ADM2,DISTRICT
SM296412,14.5306,58.9091,Laxå,ADM2,DISTRICT
SM236075,89.7177,28.0499,Layakha,LANGUAGE,LANGUAGE
SM375446,70.943,30.968,Layyah,ADM2:ADM3,DISTRICT
SM236082,41.9096,41.5004,Laz,LANGUAGE,LANGUAGE
SM255212,41.78,40.5,Laz,ETHNICITY,ETHNICITY
SM255212,41.78,40.5,Laz,ETHNICITY,ETHNICITY
SM255212,40.84,40.93,Laz,ETHNICITY,ETHNICITY
SM255212,40.84,40.93,Laz,ETHNICITY,ETHNICITY
SM10851,33.2111,34.934,Lazanias,ADM2,DISTRICT
SM290694,25.5388,46.7663,Lazarea,ADM2,DISTRICT
SM292436,22.0669,46.8596,Lazareni,ADM2,DISTRICT
SM14318,-97.9907,19.5354,Lazaro Cardenas,ADM2,DISTRICT
SM13635,21.5287,56.7968,Lazas pagasts,ADM2,DISTRICT
SM281319,23.5167,54.2333,Lazdijų,ADM2,DISTRICT
SM13636,26.1922,56.8244,Lazdonas pagasts,ADM2,DISTRICT
SM13637,27.0992,56.9242,Lazdukalna pagasts,ADM2,DISTRICT
SM13638,27.4752,57.0304,Lazdulejas pagasts,ADM2,DISTRICT
SM236079,101.45,27.85,Laze,LANGUAGE,LANGUAGE
SM1981,12.7788,42.0776,Lazio,ADM1,DISTRICT
SM294090,134,43.25,Lazovskiy rayon,ADM2,DISTRICT
SM291668,22.8721,47.8914,Lazuri,ADM2,DISTRICT
SM292437,22.3968,46.5938,Lazuri De Beius,ADM2,DISTRICT
SM298297,34.9063,40.7779,Laçin,ADM2,DISTRICT
SM296239,15.2529,46.1356,Laško,ADM1:ADM2,DISTRICT
SM302206,-94.7034,34.9003,Le Flore,ADM2,DISTRICT
SM1982,8.75,36.0833,Le Kef,ADM1,DISTRICT
SM18008,10.2966,36.8492,Le Kram,ADM2,DISTRICT
SM268928,6.6964,47.0193,Le Locle,ADM2,DISTRICT
SM17481,-61.0482,13.7837,Le Riche,ADM2,DISTRICT
SM301373,-93.7301,44.3714,Le Sueur,ADM2,DISTRICT
SM301839,-103.4125,32.7922,Lea,ADM2,DISTRICT
SM301460,-89.5241,32.7535,Leake,ADM2,DISTRICT
SM236085,-95.9265,17.3162,Lealao Chinantec,LANGUAGE,LANGUAGE
SM261211,-170.8289,-14.3184,Lealataua,ADM2,DISTRICT
SM261081,-65.0833,-27.2,Leales,ADM2,DISTRICT
SM12471,-77.9274,18.2135,Leamington,ADM2,DISTRICT
SM263603,-45.0613,-19.6749,Leandro Ferreira,ADM2,DISTRICT
SM260899,-55.3333,-27.5667,Leandro N. Alem,ADM2,DISTRICT
SM260788,-61.5,-34.5,Leandro N. Alem,ADM2,DISTRICT
SM261212,-170.7706,-14.297,Leasina,ADM2,DISTRICT
SM300936,-95.0379,39.1993,Leavenworth,ADM2,DISTRICT
SM276716,106.2167,-6.65,Lebak,ADM2,DISTRICT
SM450356,35.75,33.5,Lebanese,ETHNICITY,ETHNICITY
SM450356,35.75,33.5,Lebanese,ETHNICITY,ETHNICITY
SM450356,35.75,33.5,Lebanese,ETHNICITY,ETHNICITY
SM450356,35.5,33.89,Lebanese,ETHNICITY,ETHNICITY
SM450356,35.5,33.89,Lebanese,ETHNICITY,ETHNICITY
SM450356,35.5,33.89,Lebanese,ETHNICITY,ETHNICITY
SM450356,35.5737,33.8157,Lebanese,ETHNICITY,ETHNICITY
SM450356,35.5737,33.8157,Lebanese,ETHNICITY,ETHNICITY
SM450356,35.5737,33.8157,Lebanese,ETHNICITY,ETHNICITY
SM302316,-76.4577,40.3672,Lebanon,ADM2,DISTRICT
SM255215,36.125,34.8335,Lebanon-Shi'a Muslims (Arab),ETHNICITY,ETHNICITY
SM293567,39.1667,53,Lebedyanskiy rayon,ADM2,DISTRICT
SM299734,34.4805,50.5942,Lebedyns'kyi,ADM2,DISTRICT
SM268959,7.4805,47.218,Lebern,ADM2,DISTRICT
SM286337,27.3513,70.9454,Lebesby,ADM2,DISTRICT
SM269550,9.9232,5.5604,Lebialem,ADM2,DISTRICT
SM266192,-50.6781,-26.8852,Lebon Régis,ADM2,DISTRICT
SM276728,102.3349,-3.2428,Lebong,ADM2,DISTRICT
SM255218,-16.55,15.18,Lebou,ETHNICITY,ETHNICITY
SM270483,-73.3157,7.1971,Lebrija,ADM2,DISTRICT
SM236093,-17.4903,14.7456,Lebu Wolof,LANGUAGE,LANGUAGE
SM293470,66.5,55.3333,Lebyazh'evskiy rayon,ADM2,DISTRICT
SM279992,78.1667,51.5,Lebyazhinskiy,ADM2,DISTRICT
SM293267,49.5,57.3333,Lebyazhskiy rayon,ADM2,DISTRICT
SM277926,18.1667,40.2167,Lecce,ADM2,DISTRICT
SM277870,9.39,45.854,Lecco,ADM2,DISTRICT
SM292499,24.3261,47.0198,Lechinta,ADM2,DISTRICT
SM236095,-67.8818,-15.0092,Leco,LANGUAGE,LANGUAGE
SM255219,-67.58,-15.56,Leco,ETHNICITY,ETHNICITY
SM260835,-64.8333,-23.6667,Ledesma,ADM2,DISTRICT
SM13639,24.988,56.7669,Ledmanes pagasts,ADM2,DISTRICT
SM236097,120.132,-1.0303,Ledo Kaili,LANGUAGE,LANGUAGE
SM324005,-113.5456,53.2657,Leduc,ADM2:ADM3,DISTRICT
SM10148,-113.8064,53.2357,Leduc County (2010-),ADM2,DISTRICT
SM13640,24.78,57.3222,Ledurgas pagasts,ADM2,DISTRICT
SM11418,-0.0667,5.6,Ledzekuku-Krowor,ADM2,DISTRICT
SM302689,-96.9657,30.3107,Lee,ADM2,DISTRICT
SM303037,-83.1285,36.7055,Lee,ADM2,DISTRICT
SM302456,-80.2545,34.1633,Lee,ADM2,DISTRICT
SM301979,-79.1714,35.4752,Lee,ADM2,DISTRICT
SM301461,-88.6804,34.2899,Lee,ADM2,DISTRICT
SM301070,-83.7163,37.5948,Lee,ADM2,DISTRICT
SM300835,-91.4793,40.642,Lee,ADM2,DISTRICT
SM300341,-81.8495,26.6254,Lee,ADM2,DISTRICT
SM300640,-89.3004,41.7462,Lee,ADM2,DISTRICT
SM300526,-84.1411,31.7795,Lee,ADM2,DISTRICT
SM302384,-90.7821,34.7806,Lee,ADM2,DISTRICT
SM300281,-85.3556,32.6011,Lee,ADM2,DISTRICT
SM274646,-1.5477,53.7964,Leeds,ADM1:ADM2,DISTRICT
SM12472,-77.6837,18.014,Leeds,ADM2,DISTRICT
SM268862,-75.666,44.8334,Leeds and Grenville,ADM2,DISTRICT
SM301294,-86.0385,45.1518,Leelanau,ADM2,DISTRICT
SM236100,11.1636,9.2915,Leelau,LANGUAGE,LANGUAGE
SM261571,146.2692,-34.5297,Leeton,ADM2,DISTRICT
SM14069,-8.9033,7.0732,Leewehpea-Mahn District,ADM2,DISTRICT
SM236104,11.114,5.0585,Lefa,LANGUAGE,LANGUAGE
SM10852,32.8339,35.0945,Lefka,ADM2,DISTRICT
SM11456,20.6667,38.75,Lefkada,ADM2,DISTRICT
SM10853,33.7328,35.2657,Lefkonoiko,ADM2,DISTRICT
SM10854,33.405,35.1796,Lefkosia,ADM2,DISTRICT
SM301462,-90.3011,33.5505,Leflore,ADM2,DISTRICT
SM250418,28,-3,Lega,ETHNICITY,ETHNICITY
SM250418,28,-3,Lega,ETHNICITY,ETHNICITY
SM250418,28,-3.5,Lega,ETHNICITY,ETHNICITY
SM250418,28,-3.5,Lega,ETHNICITY,ETHNICITY
SM236108,28.2621,-3.3901,Lega-Mwenga,LANGUAGE,LANGUAGE
SM236107,27.1424,-3.832,Lega-Shabunda,LANGUAGE,LANGUAGE
SM324007,-113.5913,53.9499,Legal,ADM2:ADM3,DISTRICT
SM236113,7.9839,6.0265,Legbo,LANGUAGE,LANGUAGE
SM236115,130.749,-0.3592,Legenyem,LANGUAGE,LANGUAGE
SM288991,20.9436,52.4518,Legionowo,ADM2,DISTRICT
SM288652,16.1074,51.2411,Legnica,ADM2,DISTRICT
SM288651,16.1583,51.2047,Legnica (City),ADM2,DISTRICT
SM305708,16.8549,46.2959,Legrad,ADM2,DISTRICT
SM15849,-67.0943,18.2422,Leguisamo Barrio,ADM2,DISTRICT
SM276881,77.4167,34.3333,Leh (Ladakh),ADM2,DISTRICT
SM235766,80.6013,33.468,Leh Ladakhi,LANGUAGE,LANGUAGE
SM236119,167.332,-13.5043,Lehali,LANGUAGE,LANGUAGE
SM236120,167.3433,-13.5427,Lehalurup,LANGUAGE,LANGUAGE
SM236118,-16.8816,14.9387,Lehar,LANGUAGE,LANGUAGE
SM302317,-75.5924,40.6127,Lehigh,ADM2,DISTRICT
SM289955,26.8273,44.4883,Lehliu,ADM2,DISTRICT
SM289956,26.8617,44.4218,Lehliu-gara,ADM2,DISTRICT
SM261861,15.5185,46.7957,Leibnitz,ADM2,DISTRICT
SM274648,-1.1333,52.6333,Leicester,ADM1:ADM2,DISTRICT
SM274649,-1,52.6667,Leicestershire,ADM1:ADM2,DISTRICT
SM285661,4.4836,52.1527,Leiden,ADM2,DISTRICT
SM285662,4.5432,52.1571,Leiderdorp,ADM2,DISTRICT
SM285664,4.4014,52.0783,Leidschendam-Voorburg,ADM2,DISTRICT
SM13641,25.904,56.2977,Leimanu pagasts,ADM2,DISTRICT
SM17993,-173.978,-18.5959,Leimatu'a,ADM2,DISTRICT
SM236124,95.4,26.4481,Leinong Naga,LANGUAGE,LANGUAGE
SM4173,-7.0212,53.1667,Leinster,ADM1,DISTRICT
SM236125,147.226,-1.9665,Leipon,LANGUAGE,LANGUAGE
SM286068,12.9367,66.0635,Leirfjord,ADM2,DISTRICT
SM289257,-8.8062,39.7464,Leiria,ADM2,DISTRICT
SM1985,-8.814,39.7205,Leiria,ADM1,DISTRICT
SM12473,-76.3433,17.8797,Leith Hall,ADM2,DISTRICT
SM1986,-8,54.1167,Leitrim,ADM1:ADM2,DISTRICT
SM270326,-77.3756,1.9615,Leiva,ADM2,DISTRICT
SM275840,-87.69,14.36,Lejamaní,ADM2,DISTRICT
SM270276,-74.0238,3.5292,Lejanías,ADM2,DISTRICT
SM13642,26.5045,57.2992,Lejasciema pagasts,ADM2,DISTRICT
SM271733,11.9223,55.6338,Lejre (2007-),ADM2,DISTRICT
SM305105,26.1265,-28.2398,Lejweleputswa,ADM2,DISTRICT
SM286470,11.7051,65.0924,Leka,ADM2,DISTRICT
SM4311,13.7095,-1.3104,Lekabi-Lewolo,ADM2,DISTRICT
SM296413,14.798,59.186,Lekeberg,ADM2,DISTRICT
SM305459,16.21,45.585,Lekenik,ADM2,DISTRICT
SM14940,153.819,5.507,Lekinioch Municipality,ADM2,DISTRICT
SM269507,11.4,4.2,Lekié,ADM2,DISTRICT
SM4322,13.097,-1.974,Lekoko (1996-),ADM2,DISTRICT
SM4310,13.903,-1.1118,Lekoni-Lekori,ADM2,DISTRICT
SM296553,14.9981,60.7323,Leksand,ADM2,DISTRICT
SM236135,114.029,4.1143,Lelak,LANGUAGE,LANGUAGE
SM250419,21.18,-4.73,Lele,ETHNICITY,ETHNICITY
SM250419,21.18,-4.73,Lele,ETHNICITY,ETHNICITY
SM255220,147,-2,Lele,ETHNICITY,ETHNICITY
SM250419,21,-5,Lele,ETHNICITY,ETHNICITY
SM250419,21,-5,Lele,ETHNICITY,ETHNICITY
SM236146,15.8115,9.158,Lele (Chad),LANGUAGE,LANGUAGE
SM236139,20.5674,-4.9467,Lele (Democratic Republic of Congo),LANGUAGE,LANGUAGE
SM236140,-10.371,8.9804,Lele (Guinea),LANGUAGE,LANGUAGE
SM236144,147.207,-2.0478,Lele (Papua New Guinea),LANGUAGE,LANGUAGE
SM291428,24.4371,44.7577,Leleasca,ADM2,DISTRICT
SM236138,0.5075,7.3455,Lelemi,LANGUAGE,LANGUAGE
SM236141,168.202,-17.6042,Lelepa,LANGUAGE,LANGUAGE
SM290766,22.6892,45.7375,Lelese,ADM2,DISTRICT
SM290636,23.1976,45.0988,Lelesti,ADM2,DISTRICT
SM14941,163.0192,5.3487,Lelu Municipality,ADM2,DISTRICT
SM296062,-55.2127,5.6929,Lelydorp,ADM2,DISTRICT
SM285723,5.4381,52.4754,Lelystad,ADM2,DISTRICT
SM454494,11,4.5,Lemande,ETHNICITY,ETHNICITY
SM276510,123.5523,-8.414,Lembata,ADM2,DISTRICT
SM236157,143.891,-4.995,Lembena,LANGUAGE,LANGUAGE
SM296048,6.4994,0.2812,Lembá,ADM1:ADM2,DISTRICT
SM266658,-47.3439,-22.1779,Leme,ADM2,DISTRICT
SM263604,-42.7459,-17.0525,Leme do Prado,ADM2,DISTRICT
SM236158,167.4207,-13.7397,Lemerig,LANGUAGE,LANGUAGE
SM10855,33.0285,34.6922,Lemesos,ADM2,DISTRICT
SM226917,114.933,4.9621,Lemeting,LANGUAGE,LANGUAGE
SM300567,-113.9332,44.9433,Lemhi,ADM2,DISTRICT
SM236163,146.441,-5.6141,Lemio,LANGUAGE,LANGUAGE
SM10856,32.8068,34.9501,Lemithou,ADM2,DISTRICT
SM1988,20.0833,60.0833,Lemland,ADM1:ADM2,DISTRICT
SM236167,120.27,-2.6331,Lemolang,LANGUAGE,LANGUAGE
SM10857,32.563,34.8531,Lemona,ADM2,DISTRICT
SM236166,8.7051,10.4366,Lemoro,LANGUAGE,LANGUAGE
SM10858,32.4068,34.8128,Lempa,ADM2,DISTRICT
SM1989,-88.5833,14.5,Lempira,ADM1,DISTRICT
SM271706,8.2993,56.4914,Lemvig (2007-),ADM2,DISTRICT
SM236192,142.1,-9.12,Len,LANGUAGE,LANGUAGE
SM236170,169.261,-19.4763,Lenakel,LANGUAGE,LANGUAGE
SM4148,15.8138,46.558,Lenart (2006-),ADM1,DISTRICT
SM291992,20.789,45.8904,Lenauheim,ADM2,DISTRICT
SM301296,-84.0664,41.8951,Lenawee,ADM2,DISTRICT
SM236174,-88.0422,13.9278,Lenca,LANGUAGE,LANGUAGE
SM255222,-88.21,13.89,Lenca,ETHNICITY,ETHNICITY
SM255222,-88.21,13.89,Lenca,ETHNICITY,ETHNICITY
SM255222,-86.63,14.18,Lenca,ETHNICITY,ETHNICITY
SM255222,-86.63,14.18,Lenca,ETHNICITY,ETHNICITY
SM255222,-88,14,Lenca,ETHNICITY,ETHNICITY
SM255222,-88,14,Lenca,ETHNICITY,ETHNICITY
SM236172,-87.4988,13.6673,Lenca-Honduras,LANGUAGE,LANGUAGE
SM236173,-88.114,13.5606,Lenca-Salvador,LANGUAGE,LANGUAGE
SM296361,16.4569,46.5575,Lendava,ADM1:ADM2,DISTRICT
SM236175,30.6794,2.1898,Lendu,LANGUAGE,LANGUAGE
SM250420,31.06,2.16,Lendu,ETHNICITY,ETHNICITY
SM250420,31.06,2.16,Lendu,ETHNICITY,ETHNICITY
SM250420,30.32,1.69,Lendu,ETHNICITY,ETHNICITY
SM250420,30.32,1.69,Lendu,ETHNICITY,ETHNICITY
SM250420,30,2,Lendu,ETHNICITY,ETHNICITY
SM250420,30,2,Lendu,ETHNICITY,ETHNICITY
SM13643,27.4976,56.5695,Lendzu pagasts,ADM2,DISTRICT
SM255224,34,-25,Lenge,ETHNICITY,ETHNICITY
SM236180,115.91,3.6964,Lengilu,LANGUAGE,LANGUAGE
SM236179,160.42,-9.5643,Lengo,LANGUAGE,LANGUAGE
SM236178,25.3343,-1.9587,Lengola,LANGUAGE,LANGUAGE
SM250421,25.23,-1.15,Lengola,ETHNICITY,ETHNICITY
SM236182,-58.9053,-22.6559,Lengua,FAMILY:LANGUAGE,LANGUAGE
SM255225,-59.47,-22.25,Lengua,ETHNICITY,ETHNICITY
SM255225,-59.47,-22.25,Lengua,ETHNICITY,ETHNICITY
SM255225,-59.47,-22.25,Lengua,ETHNICITY,ETHNICITY
SM255225,-59,-23,Lengua,ETHNICITY,ETHNICITY
SM255225,-59,-23,Lengua,ETHNICITY,ETHNICITY
SM255225,-59,-23,Lengua,ETHNICITY,ETHNICITY
SM255225,-58.5,-23,Lengua,ETHNICITY,ETHNICITY
SM255225,-58.5,-23,Lengua,ETHNICITY,ETHNICITY
SM255225,-58.5,-23,Lengua,ETHNICITY,ETHNICITY
SM270020,-73.7102,5.2853,Lenguazaque,ADM2,DISTRICT
SM1990,69,40,Leninabad,ADM1,DISTRICT
SM1991,32,60,Leningrad,ADM1,DISTRICT
SM293381,39.3973,46.152,Leningradskiy rayon,ADM2,DISTRICT
SM294618,52.5,54.6,Leninogorsk,ADM2,DISTRICT
SM279964,83.5333,50.3667,Leninogorsk,ADM2,DISTRICT
SM294619,52.0833,54.5833,Leninogorskiy rayon,ADM2,DISTRICT
SM299710,33.5333,44.6,Lenins'kyi,ADM2,DISTRICT
SM299884,36.0054,45.2469,Lenins'kyi,ADM2,DISTRICT
SM293147,86,54.6667,Leninsk-Kuznetskiy rayon,ADM2,DISTRICT
SM17934,68.8333,38.4167,Leninskiy Rayon,ADM2,DISTRICT
SM13365,74.5841,42.8549,Leninskiy Rayon,ADM2,DISTRICT
SM9723,49.9167,40.5,Leninskiy Rayon,ADM2,DISTRICT
SM9667,44.5,40.1833,Leninskiy Rayon,ADM2,DISTRICT
SM16988,37.5833,55.4167,"Leninskiy Rayon (Moskva, 2012-)",ADM2,DISTRICT
SM16987,37.5,54.25,Leninskiy Rayon (Tula),ADM2,DISTRICT
SM295072,132.25,48.1667,Leninskiy rayon,ADM2,DISTRICT
SM294910,45.5,48.75,Leninskiy rayon,ADM2,DISTRICT
SM236187,28.2581,-14.7192,Lenje,LANGUAGE,LANGUAGE
SM250422,27.9,-14.45,Lenje,ETHNICITY,ETHNICITY
SM236190,147.742,-2.3337,Lenkau,LANGUAGE,LANGUAGE
SM370487,6.3681,49.5986,Lenningen,ADM1:ADM2:ADM3,DISTRICT
SM268863,-76.9994,44.5001,Lennox and Addington,ADM2,DISTRICT
SM17482,-61.0591,13.8506,Lenny Hill,ADM2,DISTRICT
SM301980,-77.6413,35.2392,Lenoir,ADM2,DISTRICT
SM12474,-77.9721,18.2139,Lenox Bigwoods,ADM2,DISTRICT
SM293585,48.6667,62,Lenskiy rayon,ADM2,DISTRICT
SM11320,42.8667,42.8167,Lent'ekhis Munitsip'alit'et'I (excl. Lentekhi),ADM2,DISTRICT
SM11321,42.7333,42.7833,Lentekhi,ADM2,DISTRICT
SM276218,16.5744,46.6565,Lenti,ADM2,DISTRICT
SM236194,8.2895,6.0013,Lenyima,LANGUAGE,LANGUAGE
SM268904,8.1891,47.3718,Lenzburg,ADM2,DISTRICT
SM267513,-41.3082,-12.4726,Lençóis,ADM2,DISTRICT
SM266659,-48.8215,-22.6726,Lençóis Paulista,ADM2,DISTRICT
SM261862,14.9167,47.4167,Leoben,ADM2,DISTRICT
SM266193,-49.2512,-27.488,Leoberto Leal,ADM2,DISTRICT
SM302690,-95.9957,31.2965,Leon,ADM2,DISTRICT
SM300342,-84.2779,30.458,Leon,ADM2,DISTRICT
SM281616,-99.8317,17.6214,Leonardo Bravo,ADM2,DISTRICT
SM10859,34.124,35.4618,Leonarisso,ADM2,DISTRICT
SM286736,-76,-9.1667,Leoncio Prado,ADM2,DISTRICT
SM261406,121.2596,-28.3942,Leonora,ADM2,DISTRICT
SM263605,-42.6447,-21.5349,Leopoldina,ADM2,DISTRICT
SM268078,-48.906,-16.5814,Leopoldo de Bulhões,ADM2,DISTRICT
SM292576,26.4541,47.8186,Leorda,ADM2,DISTRICT
SM291253,25.163,44.7912,Leordeni,ADM2,DISTRICT
SM291012,24.264,47.7831,Leordina,ADM2,DISTRICT
SM1993,28.4167,46.5,Leova,ADM1,DISTRICT
SM304706,-171.5163,-14.0193,Lepa,ADM2,DISTRICT
SM275702,-88.5928,14.8149,Lepaera,ADM2,DISTRICT
SM275941,-87.4791,14.0559,Lepaterique,ADM2,DISTRICT
SM236200,88.5726,27.0869,Lepcha,LANGUAGE,LANGUAGE
SM255227,89,27,Lepcha,ETHNICITY,ETHNICITY
SM255227,89,27,Lepcha,ETHNICITY,ETHNICITY
SM255227,89,27,Lepcha,ETHNICITY,ETHNICITY
SM255227,89,28,Lepcha,ETHNICITY,ETHNICITY
SM255227,89,28,Lepcha,ETHNICITY,ETHNICITY
SM255227,89,28,Lepcha,ETHNICITY,ETHNICITY
SM255227,89,27.5,Lepcha,ETHNICITY,ETHNICITY
SM255227,89,27.5,Lepcha,ETHNICITY,ETHNICITY
SM255227,89,27.5,Lepcha,ETHNICITY,ETHNICITY
SM236201,140.464,-4.1059,Lepki,LANGUAGE,LANGUAGE
SM276008,16.0434,46.2074,Lepoglava,ADM2,DISTRICT
SM236203,8.92,45.98,Lepontic,LANGUAGE,LANGUAGE
SM304740,20.7833,43.1167,Leposavić,ADM2,DISTRICT
SM255228,116.79,3.21,Lepu Maut,ETHNICITY,ETHNICITY
SM297795,125.6361,-8.6914,Lequidoe,ADM2,DISTRICT
SM281500,-103.6027,25.4848,Lerdo,ADM2,DISTRICT
SM282930,-95.5085,18.638,Lerdo de Tejada,ADM2,DISTRICT
SM236209,8.9334,10.4789,Lere,LANGUAGE,LANGUAGE
SM284817,8.5673,10.3464,Lere,ADM2,DISTRICT
SM291254,25.0695,45.3279,Leresti,ADM2,DISTRICT
SM1994,28.2527,-29.0365,Leribe,ADM1,DISTRICT
SM261937,48.4167,38.75,Lerik,ADM1:ADM2,DISTRICT
SM281935,-99.4705,19.3555,Lerma,ADM2,DISTRICT
SM296580,12.2697,57.7745,Lerum,ADM2,DISTRICT
SM268919,7.0456,47.2494,Les Franches-Montagnes,ADM2,DISTRICT
SM1995,55.4699,-4.6514,Les Mamelles,ADM1,DISTRICT
SM11457,26.3333,39.1667,Lesbos,ADM2,DISTRICT
SM236214,29.3,1.3,Lese,LANGUAGE,LANGUAGE
SM250423,29,1.57,Lese,ETHNICITY,ETHNICITY
SM250423,29,1.57,Lese,ETHNICITY,ETHNICITY
SM250423,29,2,Lese,ETHNICITY,ETHNICITY
SM250423,29,2,Lese,ETHNICITY,ETHNICITY
SM255229,48.37,41.29,Lesgin,ETHNICITY,ETHNICITY
SM255229,48.37,41.29,Lesgin,ETHNICITY,ETHNICITY
SM255229,48.1833,42.1167,Lesgin,ETHNICITY,ETHNICITY
SM255229,48.1833,42.1167,Lesgin,ETHNICITY,ETHNICITY
SM269340,103.5634,29.2627,Leshan,ADM2,DISTRICT
SM293586,47,65,Leshukonskiy rayon,ADM2,DISTRICT
SM262318,24.15,42.3667,Lesichevo,ADM2,DISTRICT
SM236217,150.506,-6.1989,Lesing-Gelimi,LANGUAGE,LANGUAGE
SM286124,8.8626,62.1179,Lesja,ADM2,DISTRICT
SM16989,43.9414,43.3556,Leskenskiy rayon,ADM2,DISTRICT
SM289006,22.3691,49.3197,Lesko,ADM2,DISTRICT
SM301071,-83.3812,37.0941,Leslie,ADM2,DISTRICT
SM17483,-60.9867,14.0075,Leslie Land,ADM2,DISTRICT
SM294759,35.5,58.25,Lesnoy rayon,ADM2,DISTRICT
SM236219,27.5077,-29.3699,Lesotho Sign Language,LANGUAGE,LANGUAGE
SM290837,26.6881,47.3496,Lespezi,ADM2,DISTRICT
SM296668,15.3136,56.7639,Lessebo,ADM2,DISTRICT
SM13644,23.1341,56.7797,Lestenes pagasts,ADM2,DISTRICT
SM292500,24.7637,47.3108,Lesu,ADM2,DISTRICT
SM255230,151.66,-3.06,Lesu,ETHNICITY,ETHNICITY
SM255230,151.66,-3.06,Lesu,ETHNICITY,ETHNICITY
SM255230,151.66,-3.06,Lesu,ETHNICITY,ETHNICITY
SM255230,151,-2.71,Lesu,ETHNICITY,ETHNICITY
SM255230,151,-2.71,Lesu,ETHNICITY,ETHNICITY
SM255230,151,-2.71,Lesu,ETHNICITY,ETHNICITY
SM255230,151.65,-3.05,Lesu,ETHNICITY,ETHNICITY
SM255230,151.65,-3.05,Lesu,ETHNICITY,ETHNICITY
SM255230,151.65,-3.05,Lesu,ETHNICITY,ETHNICITY
SM288794,16.5012,51.8501,Leszno,ADM2,DISTRICT
SM288793,16.5759,51.8441,Leszno (City),ADM2,DISTRICT
SM291615,23.4287,47.3495,Letca,ADM2,DISTRICT
SM290580,25.7094,44.2333,Letca Noua,ADM2,DISTRICT
SM290839,27.413,47.1801,Letcani,ADM2,DISTRICT
SM301072,-82.8553,37.1212,Letcher,ADM2,DISTRICT
SM292117,26.9687,46.5424,Letea Veche,ADM2,DISTRICT
SM297848,125.4264,-8.8344,Letefoho,ADM2,DISTRICT
SM236223,167.566,-16.4339,Letemboi-Repanbitip,LANGUAGE,LANGUAGE
SM276219,16.765,46.5055,Letenye,ADM2,DISTRICT
SM323847,-112.8441,49.6876,Lethbridge,ADM2:ADM3,DISTRICT
SM323826,-112.8041,49.8297,Lethbridge County,ADM2:ADM3,DISTRICT
SM268523,24.952,-21.316,Lethlakane,ADM2,DISTRICT
SM255232,127.72,-8.45,Leti,ETHNICITY,ETHNICITY
SM236226,11.405,4.5746,Leti (Cameroon),LANGUAGE,LANGUAGE
SM236227,127.674,-8.1981,Leti (Indonesia),LANGUAGE,LANGUAGE
SM269845,-70.0282,-3.6583,Leticia,ADM2,DISTRICT
SM262544,25.0833,43.2833,Letnitsa,ADM2,DISTRICT
SM458820,132.2538,-14.1553,Letpurrirt,ETHNICITY,ETHNICITY
SM299449,27.6204,49.3808,Letychivs'kyi,ADM2,DISTRICT
SM10860,32.5226,34.8542,Letymvou,ADM2,DISTRICT
SM290376,24.0497,44.1721,Leu,ADM2,DISTRICT
SM255233,102.1,17.45,Leu,ETHNICITY,ETHNICITY
SM370515,6.0653,49.5683,Leudelange,ADM1:ADM2:ADM3,DISTRICT
SM268993,7.6729,46.3144,Leuk,ADM2,DISTRICT
SM280515,105.2015,11.1598,Leuk Daek,ADM2,DISTRICT
SM285591,5.4125,52.1242,Leusden,ADM2,DISTRICT
SM293568,39.5,53.25,Lev-Tolstovskiy rayon,ADM2,DISTRICT
SM286472,11.2999,63.7459,Levanger,ADM2,DISTRICT
SM305374,18.1795,45.3138,Levanjska Varoš,ADM2,DISTRICT
SM233452,36.0829,31.8202,Levantine Arabic Sign Language,LANGUAGE,LANGUAGE
SM292845,47.3333,42.4167,Levashinskiy rayon,ADM2,DISTRICT
SM268976,8.7554,46.4691,Leventina,ADM2,DISTRICT
SM296146,18.6,48.2167,Levice,ADM2,DISTRICT
SM296159,20.5769,49.0199,Levoca,ADM2,DISTRICT
SM293745,36,68,Levozerskiy rayon,ADM2,DISTRICT
SM262339,25.1,43.4333,Levski,ADM2,DISTRICT
SM300343,-82.7886,29.2822,Levy,ADM2,DISTRICT
SM303082,-122.3924,46.5777,Lewis,ADM2,DISTRICT
SM303121,-80.5022,38.9959,Lewis,ADM2,DISTRICT
SM302590,-87.4931,35.5273,Lewis,ADM2,DISTRICT
SM301885,-75.4488,43.7847,Lewis,ADM2,DISTRICT
SM301574,-91.7221,40.0969,Lewis,ADM2,DISTRICT
SM301073,-83.3781,38.5315,Lewis,ADM2,DISTRICT
SM300568,-116.4263,46.237,Lewis,ADM2,DISTRICT
SM301639,-112.3904,47.1223,Lewis and Clark,ADM2,DISTRICT
SM236236,168.334,-16.7612,Lewo,LANGUAGE,LANGUAGE
SM236237,123.652,-8.3574,Lewo Eleng,LANGUAGE,LANGUAGE
SM236238,122.747,-8.5457,Lewotobi,LANGUAGE,LANGUAGE
SM303038,-79.4524,37.7731,Lexington,ADM2,DISTRICT
SM302457,-81.2722,33.9023,Lexington,ADM2,DISTRICT
SM255234,26.38,-17.14,Leya,ETHNICITY,ETHNICITY
SM236248,8.2086,5.9883,Leyigha,LANGUAGE,LANGUAGE
SM1996,124.8267,11.0814,Leyte (1992-),ADM1:ADM2,DISTRICT
SM276784,-4.4199,54.3173,Lezayre,ADM1:ADM2,DISTRICT
SM236250,47.8951,41.5157,Lezgian,LANGUAGE,LANGUAGE
SM292929,40.9167,56.75,Lezhnevskiy rayon,ADM2,DISTRICT
SM1997,19.8667,41.8,Lezhë,ADM1,DISTRICT
SM260501,19.6667,41.8,Lezhës,ADM2,DISTRICT
SM260501,19.6667,41.8,Lezhës,ADM2,DISTRICT
SM260501,19.6412,41.8132,Lezhës,ADM2,DISTRICT
SM260501,19.6412,41.8132,Lezhës,ADM2,DISTRICT
SM17484,-60.9391,13.8037,Lezy,ADM2,DISTRICT
SM274069,-6,42.6667,León,ADM2,DISTRICT
SM285421,-86.8796,12.3953,León,ADM2,DISTRICT
SM281544,-101.645,21.0931,León,ADM2,DISTRICT
SM1992,-86.5833,12.5833,León,ADM1,DISTRICT
SM270978,-84.0478,9.683,León Cortés,ADM2,DISTRICT
SM264542,-50.7256,-23.025,Leópolis,ADM2,DISTRICT
SM305464,16.3456,43.6426,Lećevica,ADM2,DISTRICT
SM289007,22.4383,50.2616,Leżajsk,ADM2,DISTRICT
SM269359,91.3111,30.3116,Lhasa,ADM2,DISTRICT
SM4261,73.5842,5.2806,Lhaviyani Atholhu,ADM1:ADM2,DISTRICT
SM236253,89.3099,26.9135,Lhokpu,LANGUAGE,LANGUAGE
SM276288,97.1277,5.1827,Lhokseumawe,ADM2,DISTRICT
SM236255,87.7161,27.5948,Lhomi,LANGUAGE,LANGUAGE
SM1998,91.1833,27.75,Lhuentse,ADM1,DISTRICT
SM297032,98.8867,17.7864,Li,ADM2,DISTRICT
SM255238,109.29,18.76,Li,ETHNICITY,ETHNICITY
SM255238,109.29,18.76,Li,ETHNICITY,ETHNICITY
SM255238,109,19,Li,ETHNICITY,ETHNICITY
SM255238,109,19,Li,ETHNICITY,ETHNICITY
SM236362,121.832,-8.6862,Li'o,LANGUAGE,LANGUAGE
SM236257,122.699,-5.3227,Liabuku,LANGUAGE,LANGUAGE
SM236264,129.899,-3.1183,Liana-Seti,LANGUAGE,LANGUAGE
SM236267,116.7833,25.5667,Liancheng,DIALECT,LANGUAGE
SM236266,98.3031,24.7506,Lianghe Achang,LANGUAGE,LANGUAGE
SM236260,93.542,25.16,Liangmai Naga,LANGUAGE,LANGUAGE
SM10360,107.7938,30.6741,Liangping District,ADM2,DISTRICT
SM269341,102.1018,27.8764,Liangshan Yi,ADM2,DISTRICT
SM236265,110.1578,26.9379,Lianmin Yao,LANGUAGE,LANGUAGE
SM236261,112.05,24.5,Lianshan Zhuang,LANGUAGE,LANGUAGE
SM269215,119.1123,34.5484,Lianyungang,ADM2,DISTRICT
SM269306,115.9333,36.5181,Liaocheng,ADM2,DISTRICT
SM1999,122.6667,41.25,Liaoning,ADM1,DISTRICT
SM269243,123.33,41.2481,Liaoyang,ADM2,DISTRICT
SM269237,125.3239,42.9678,Liaoyuan,ADM2,DISTRICT
SM4084,167.38,8.3136,Lib Island,ADM1,DISTRICT
SM13645,22.5636,57.1762,Libagu pagasts,ADM2,DISTRICT
SM274381,40.5,4.75,Liben,ADM2,DISTRICT
SM15348,-56.3784,-24.1964,Liberacion,ADM2,DISTRICT
SM265742,-53.074,-27.5385,Liberato Salzano,ADM2,DISTRICT
SM263606,-44.3476,-22.0163,Liberdade,ADM2,DISTRICT
SM271228,15.0833,50.8333,Liberec,ADM2,DISTRICT
SM2000,15,50.75,Liberecký,ADM1,DISTRICT
SM270929,-85.4758,10.7015,Liberia,ADM2,DISTRICT
SM236272,-9.9613,6.9205,Liberia Kpelle,LANGUAGE,LANGUAGE
SM303737,-72.322,7.8272,Libertad,ADM2,DISTRICT
SM303699,-64.5833,9.75,Libertad,ADM2,DISTRICT
SM18337,-56.639,-34.6498,Libertad,ADM2,DISTRICT
SM261084,-59.3333,-27.3333,Libertad,ADM2,DISTRICT
SM303730,-63,10.5667,Libertador,ADM2,DISTRICT
SM303666,-62.6422,9.0072,Libertador,ADM2,DISTRICT
SM303738,-71.85,7.5833,Libertador,ADM2,DISTRICT
SM303876,-68.0656,10.1144,Libertador,ADM2,DISTRICT
SM303615,-71.1167,8.3333,Libertador,ADM2,DISTRICT
SM303562,-66.8747,10.4902,Libertador,ADM2,DISTRICT
SM303839,-67.5419,10.1739,Libertador,ADM2,DISTRICT
SM2001,-71,-34.5,Libertador General Bernardo O'Higgins,ADM1,DISTRICT
SM260993,-65.6667,-32.6667,Libertador General San Martín,ADM2,DISTRICT
SM260900,-54.9167,-26.9167,Libertador General San Martín,ADM2,DISTRICT
SM261085,-59.4167,-26.3333,Libertador General San Martín,ADM2,DISTRICT
SM302691,-94.8122,30.1516,Liberty,ADM2,DISTRICT
SM301640,-111.0246,48.5617,Liberty,ADM2,DISTRICT
SM300527,-81.4563,31.8072,Liberty,ADM2,DISTRICT
SM300344,-84.8829,30.2414,Liberty,ADM2,DISTRICT
SM236277,38.4997,8.0976,Libido,LANGUAGE,LANGUAGE
SM236275,18.8886,1.9773,Libinza,LANGUAGE,LANGUAGE
SM260445,14.9947,-10.0146,Libolo,ADM2,DISTRICT
SM236280,123.5,13.17,Libon,DIALECT,LANGUAGE
SM270233,-75.7821,6.7304,Liborina,ADM2,DISTRICT
SM260485,20.3333,41.1667,Librazhdit,ADM2,DISTRICT
SM14319,-97.6684,19.4739,Libres,ADM2,DISTRICT
SM3896,9.51,0.385,Libreville,ADM1:ADM2,DISTRICT
SM251106,13.19,32.88,Libya-Arab,ETHNICITY,ETHNICITY
SM255239,13.1833,32.9167,Libya-Jew,ETHNICITY,ETHNICITY
SM236282,15.0014,22.0032,Libyan Arabic,LANGUAGE,LANGUAGE
SM236281,17.573,29.512,Libyan Sign Language,LANGUAGE,LANGUAGE
SM303700,-64.6941,10.1977,Lic. Diego Bautista Urban,ADM2,DISTRICT
SM298322,40.6471,38.4613,Lice,ADM2,DISTRICT
SM271873,-70.6,19.45,Licey al Medio,ADM2,DISTRICT
SM15065,35.143,-13.3718,Lichinga District (excl. Lichinga),ADM2,DISTRICT
SM292119,27.2367,46.4138,Lichitiseni,ADM2,DISTRICT
SM302123,-82.4832,40.0916,Licking,ADM2,DISTRICT
SM2002,15.4167,44.6667,Licko-Senjska,ADM1,DISTRICT
SM290637,23.6264,44.8754,Licurici,ADM2,DISTRICT
SM267514,-42.5044,-14.7064,Licínio de Almeida,ADM2,DISTRICT
SM255240,-117,37,Lida Shoshoni,ETHNICITY,ETHNICITY
SM346080,38.7347,9.0114,Lideta,ADM2:ADM3,DISTRICT
SM264544,-51.6441,-24.0814,Lidianópolis,ADM2,DISTRICT
SM296493,18.151,59.363,Lidingö,ADM2,DISTRICT
SM296581,13,58.5,Lidköping,ADM2,DISTRICT
SM9795,25.25,53.6667,Lidski Rayon (excl. Lida),ADM2,DISTRICT
SM13646,28.0138,56.5799,Lidumnieku pagasts,ADM2,DISTRICT
SM288768,20.3805,54.1058,Lidzbark,ADM2,DISTRICT
SM291993,21.3353,45.5712,Liebling,ADM2,DISTRICT
SM13647,22.8493,56.5258,Lielauces pagasts,ADM2,DISTRICT
SM13648,23.6406,56.4514,Lielplatones pagasts,ADM2,DISTRICT
SM13649,24.8107,56.7191,Lielvarde,ADM2,DISTRICT
SM3997,24.9556,56.7175,Lielvardes Novads,ADM1,DISTRICT
SM13650,24.8951,56.7191,Lielvardes pagasts,ADM2,DISTRICT
SM299052,120.4955,26.37,Lienkiang,ADM2,DISTRICT
SM261871,12.5,46.9,Lienz,ADM2,DISTRICT
SM13651,21.0304,56.5403,Liepaja,ADM2,DISTRICT
SM3957,21.0367,56.5419,Liepaja,ADM1,DISTRICT
SM13652,25.4436,57.3908,Liepas pagasts,ADM2,DISTRICT
SM13653,27.5137,57.3488,Liepnas pagasts,ADM2,DISTRICT
SM13654,24.4617,57.4845,Liepupes pagasts,ADM2,DISTRICT
SM286318,10.2459,59.7866,Lier,ADM2,DISTRICT
SM286450,13.5925,64.4641,Lierne,ADM2,DISTRICT
SM269024,7.7271,47.4812,Liestal,ADM2,DISTRICT
SM290449,27.5413,45.6202,Liesti,ADM2,DISTRICT
SM261863,14.3431,47.5352,Liezen,ADM2,DISTRICT
SM13655,26.0358,57.0188,Liezeres pagasts,ADM2,DISTRICT
SM284561,167.25,-20.9667,Lifou,ADM2,DISTRICT
SM13656,25.0408,57.2384,Ligatne,ADM2,DISTRICT
SM4027,25.0592,57.1892,Ligatnes Novads,ADM1,DISTRICT
SM13657,25.0597,57.1872,Ligatnes pagasts,ADM2,DISTRICT
SM236288,-2.5635,7.9429,Ligbi,LANGUAGE,LANGUAGE
SM236289,22.4936,2.8617,Ligenza,LANGUAGE,LANGUAGE
SM261702,138.8437,-34.4098,Light,ADM2,DISTRICT
SM13658,26.6076,57.0054,Ligo pagasts,ADM2,DISTRICT
SM12475,-76.7683,18.021,Liguanea,ADM2,DISTRICT
SM2003,8.8333,44.5,Liguria,ADM1,DISTRICT
SM236293,7.9052,44.0816,Ligurian,LANGUAGE,LANGUAGE
SM236296,152.606,-3.189,Lihir,LANGUAGE,LANGUAGE
SM260843,-65.0833,-38.25,Lihuel Calel,ADM2,DISTRICT
SM260171,69.6338,33.8152,Lija Mangal,ADM2,DISTRICT
SM269380,100.5086,26.8534,Lijiang,ADM2,DISTRICT
SM236297,8.3556,8.5219,Lijili,LANGUAGE,LANGUAGE
SM269737,26.7333,-10.9833,Likasi,ADM2,DISTRICT
SM294761,35.4167,57.1667,Likhoslavl'skiy rayon,ADM2,DISTRICT
SM236302,138.731,-1.6065,Liki,LANGUAGE,LANGUAGE
SM4099,169.15,9.8833,Likiep Atoll,ADM1,DISTRICT
SM236298,27.6088,2.1378,Liko,LANGUAGE,LANGUAGE
SM2004,34.7361,-12.0607,Likoma,ADM1:ADM2,DISTRICT
SM2005,17.5,2,Likouala,ADM1,DISTRICT
SM13659,26.4484,55.9946,Liksnas pagasts,ADM2,DISTRICT
SM236310,16.4702,-1.2793,Likuba,LANGUAGE,LANGUAGE
SM236311,146.695,-2.1616,Likum,LANGUAGE,LANGUAGE
SM236313,16.5967,-0.4898,Likwala,LANGUAGE,LANGUAGE
SM236316,144.99,-4.3548,Lilau,LANGUAGE,LANGUAGE
SM261805,15.6,47.9167,Lilienfeld,ADM2,DISTRICT
SM296582,12.1435,58.1357,Lilla Edet,ADM2,DISTRICT
SM286125,10.4674,61.1146,Lillehammer,ADM2,DISTRICT
SM286302,8.3785,58.2498,Lillesand,ADM2,DISTRICT
SM15299,11.207,59.9484,Lillestrom,ADM2,DISTRICT
SM12476,-77.7769,18.5059,Lilliput,ADM2,DISTRICT
SM236322,-122.04,50.7272,Lillooet,LANGUAGE,LANGUAGE
SM255243,-125.99,50.16,Lillouet,ETHNICITY,ETHNICITY
SM255243,-125.99,50.16,Lillouet,ETHNICITY,ETHNICITY
SM255243,-125.99,50.16,Lillouet,ETHNICITY,ETHNICITY
SM255243,-125.99,50.16,Lillouet,ETHNICITY,ETHNICITY
SM255243,-123.19,49.85,Lillouet,ETHNICITY,ETHNICITY
SM255243,-123.19,49.85,Lillouet,ETHNICITY,ETHNICITY
SM255243,-123.19,49.85,Lillouet,ETHNICITY,ETHNICITY
SM255243,-123.19,49.85,Lillouet,ETHNICITY,ETHNICITY
SM255243,-124.64,50.63,Lillouet,ETHNICITY,ETHNICITY
SM255243,-124.64,50.63,Lillouet,ETHNICITY,ETHNICITY
SM255243,-124.64,50.63,Lillouet,ETHNICITY,ETHNICITY
SM255243,-124.64,50.63,Lillouet,ETHNICITY,ETHNICITY
SM255243,-124.78,50.02,Lillouet,ETHNICITY,ETHNICITY
SM255243,-124.78,50.02,Lillouet,ETHNICITY,ETHNICITY
SM255243,-124.78,50.02,Lillouet,ETHNICITY,ETHNICITY
SM255243,-124.78,50.02,Lillouet,ETHNICITY,ETHNICITY
SM255243,-123,50.46,Lillouet,ETHNICITY,ETHNICITY
SM255243,-123,50.46,Lillouet,ETHNICITY,ETHNICITY
SM255243,-123,50.46,Lillouet,ETHNICITY,ETHNICITY
SM255243,-123,50.46,Lillouet,ETHNICITY,ETHNICITY
SM255243,-122.5,50.3,Lillouet,ETHNICITY,ETHNICITY
SM255243,-122.5,50.3,Lillouet,ETHNICITY,ETHNICITY
SM255243,-122.5,50.3,Lillouet,ETHNICITY,ETHNICITY
SM255243,-122.5,50.3,Lillouet,ETHNICITY,ETHNICITY
SM2006,33.6841,-14.0196,Lilongwe,ADM1:ADM2,DISTRICT
SM289651,-56.5694,-23.9783,Lima,ADM2,DISTRICT
SM286768,-76.9043,-12.0179,Lima,ADM2,DISTRICT
SM303883,-68.439,9.826,Lima Blanco,ADM2,DISTRICT
SM262811,-44.4799,-4.553,Lima Campos,ADM2,DISTRICT
SM2007,-76.45,-11.85,Lima Department,ADM1,DISTRICT
SM263607,-43.8958,-21.7904,Lima Duarte,ADM2,DISTRICT
SM2008,-76.8333,-12,Lima Province,ADM1,DISTRICT
SM276656,100.5872,-0.0168,Lima Puluh Kota,ADM2,DISTRICT
SM15850,-66.8017,18.1624,Limani Barrio,ADM2,DISTRICT
SM288949,20.4244,49.7076,Limanowa,ADM2,DISTRICT
SM293843,47.25,45.8333,Limanskiy rayon,ADM2,DISTRICT
SM290171,28.5393,43.7802,Limanu,ADM2,DISTRICT
SM269111,-71,-30.75,Limarí,ADM2,DISTRICT
SM236323,16.1874,2.2681,Limassa,LANGUAGE,LANGUAGE
SM2009,32.9333,34.7667,Limassol,ADM1,DISTRICT
SM260844,-66.5,-37.3333,Limay Mahuida,ADM2,DISTRICT
SM284356,115.3333,4.1667,Limbang,ADM2,DISTRICT
SM255245,115.8,3.52,Limbang,ETHNICITY,ETHNICITY
SM13660,24.7168,57.511,Limbazi,ADM2,DISTRICT
SM3993,24.6667,57.5833,Limbazu novads,ADM1,DISTRICT
SM13661,24.6915,57.4655,Limbazu pagasts,ADM2,DISTRICT
SM236328,87.8859,27.2703,Limbu,LANGUAGE,LANGUAGE
SM255246,90.52,27.45,Limbu,ETHNICITY,ETHNICITY
SM236330,10.9802,6.5861,Limbum,LANGUAGE,LANGUAGE
SM255247,11,6,Limbum,ETHNICITY,ETHNICITY
SM255247,11,6,Limbum,ETHNICITY,ETHNICITY
SM255247,10.8,6.5,Limbum,ETHNICITY,ETHNICITY
SM255247,10.8,6.5,Limbum,ETHNICITY,ETHNICITY
SM262094,5.5,51,Limburg,ADM1:ADM2,DISTRICT
SM2010,6,51.25,Limburg,ADM1,DISTRICT
SM236327,5.8771,51.015,Limburgan,LANGUAGE,LANGUAGE
SM12477,-77.2105,18.3974,Lime Hall,ADM2,DISTRICT
SM12478,-77.3865,18.3455,Lime Tree Gardens,ADM2,DISTRICT
SM266660,-47.3802,-22.6113,Limeira,ADM2,DISTRICT
SM263608,-50.6128,-19.3879,Limeira do Oeste,ADM2,DISTRICT
SM2011,-8.6366,52.6464,Limerick,ADM1:ADM2,DISTRICT
SM302692,-96.5805,31.5455,Limestone,ADM2,DISTRICT
SM300282,-86.9814,34.8101,Limestone,ADM2,DISTRICT
SM236333,100.073,24.469,Limi,LANGUAGE,LANGUAGE
SM240750,131.845,-12.4294,Limilngan,LANGUAGE,LANGUAGE
SM458884,131.724,-12.5256,Limilngan,ETHNICITY,ETHNICITY
SM10861,32.9452,34.7976,Limnatis,ADM2,DISTRICT
SM10862,33.8515,35.1927,Limnia,ADM2,DISTRICT
SM264847,-35.4451,-7.8509,Limoeiro,ADM2,DISTRICT
SM265378,-36.4541,-9.7269,Limoeiro de Anadia,ADM2,DISTRICT
SM264182,-49.5102,-1.8965,Limoeiro do Ajuru,ADM2,DISTRICT
SM267784,-38.0266,-5.1741,Limoeiro do Norte,ADM2,DISTRICT
SM15851,-67.0661,18.1851,Limon Barrio (Mayagüez),ADM2,DISTRICT
SM15852,-66.6312,18.3251,Limon Barrio (Utuado),ADM2,DISTRICT
SM271010,-81.4916,22.9051,Limonar,ADM2,DISTRICT
SM15853,-65.8889,18.08,Limones Barrio,ADM2,DISTRICT
SM236337,-83.0905,9.9574,Limonese Creole,LANGUAGE,LANGUAGE
SM236336,121.381,17.6081,Limos Kalinga,LANGUAGE,LANGUAGE
SM236334,1.8017,46.0579,Limousin Occitan,DIALECT,LANGUAGE
SM289734,-57.4751,-25.1575,Limpio,ADM2,DISTRICT
SM2013,29.5,-24,Limpopo,ADM1,DISTRICT
SM18139,23.3571,-14.9803,Limulunga District,ADM2,DISTRICT
SM275833,-85.5081,15.7966,Limón,ADM2,DISTRICT
SM270943,-83.1667,9.7667,Limón,ADM2,DISTRICT
SM2012,-83.2167,10,Limón,ADM1,DISTRICT
SM273518,-78.4167,-2.9667,Limón Indanza,ADM2,DISTRICT
SM14320,-99.5641,24.8632,Linares,ADM2,DISTRICT
SM270327,-77.5442,1.4005,Linares,ADM2,DISTRICT
SM269077,-71.431,-36.0296,Linares,ADM2,DISTRICT
SM269381,99.5512,23.948,Lincang,ADM2,DISTRICT
SM303251,-89.7346,45.3375,Lincoln,ADM2,DISTRICT
SM303083,-118.4188,47.5762,Lincoln,ADM2,DISTRICT
SM303122,-82.0704,38.1754,Lincoln,ADM2,DISTRICT
SM303298,-110.656,42.264,Lincoln,ADM2,DISTRICT
SM302262,-123.9077,44.6457,Lincoln,ADM2,DISTRICT
SM302591,-86.5889,35.1405,Lincoln,ADM2,DISTRICT
SM302207,-96.8809,35.703,Lincoln,ADM2,DISTRICT
SM302513,-96.7218,43.2789,Lincoln,ADM2,DISTRICT
SM301981,-81.2239,35.4862,Lincoln,ADM2,DISTRICT
SM301840,-105.4593,33.7452,Lincoln,ADM2,DISTRICT
SM301772,-114.8776,37.6434,Lincoln,ADM2,DISTRICT
SM301729,-100.7452,41.0478,Lincoln,ADM2,DISTRICT
SM301641,-115.4052,48.5425,Lincoln,ADM2,DISTRICT
SM301463,-90.454,31.5324,Lincoln,ADM2,DISTRICT
SM301212,-69.5258,43.9978,Lincoln,ADM2,DISTRICT
SM301575,-90.9601,39.058,Lincoln,ADM2,DISTRICT
SM301374,-96.2671,44.4126,Lincoln,ADM2,DISTRICT
SM301074,-84.6608,37.4554,Lincoln,ADM2,DISTRICT
SM300937,-98.2077,39.0453,Lincoln,ADM2,DISTRICT
SM301132,-92.6648,32.6016,Lincoln,ADM2,DISTRICT
SM300569,-114.1383,43.0024,Lincoln,ADM2,DISTRICT
SM300529,-82.4512,33.7937,Lincoln,ADM2,DISTRICT
SM303349,-103.514,38.9881,Lincoln,ADM2,DISTRICT
SM302386,-91.7333,33.9575,Lincoln,ADM2,DISTRICT
SM260789,-61.5296,-34.8684,Lincoln,ADM2,DISTRICT
SM274650,-0.25,53.1667,Lincolnshire and North East Lincolnshire,ADM2,DISTRICT
SM323878,-113.4856,51.5907,Linden,ADM2:ADM3,DISTRICT
SM296414,15.3406,59.6412,Lindesberg,ADM2,DISTRICT
SM461888,7.3016,58.0844,Lindesnes (2020-),ADM2,DISTRICT
SM2014,38.5,-9.5,Lindi,ADM1,DISTRICT
SM299083,39.55,-9.6833,Lindi Rural,ADM2,DISTRICT
SM299084,39.6716,-9.9783,Lindi Urban,ADM2,DISTRICT
SM264545,-53.5687,-25.268,Lindoeste,ADM2,DISTRICT
SM265743,-51.2279,-29.5748,Lindolfo Collor,ADM2,DISTRICT
SM266661,-46.6535,-22.5125,Lindóia,ADM2,DISTRICT
SM266194,-52.0406,-27.0325,Lindóia do Sul,ADM2,DISTRICT
SM4247,-157.475,1.942,Line Islands,ADM1,DISTRICT
SM269315,111.3818,36.23,Linfen,ADM2,DISTRICT
SM285788,5.9458,51.8956,Lingewaard,ADM2,DISTRICT
SM276436,104.4145,-0.5091,Lingga,ADM2,DISTRICT
SM236357,87.9992,26.992,Lingkhim,LANGUAGE,LANGUAGE
SM247218,-46.38,-23.95,Lingua Geral Paulista,LANGUAGE,LANGUAGE
SM255273,-43.43,-19.05,Lingua Geral Paulista,ETHNICITY,ETHNICITY
SM255273,-46.91,-21.92,Lingua Geral Paulista,ETHNICITY,ETHNICITY
SM295286,-15.158,15.359,Linguère,ADM2,DISTRICT
SM268374,89.4328,27.8569,Lingzhi,ADM2,DISTRICT
SM265744,-51.2188,-29.4554,Linha Nova,ADM2,DISTRICT
SM268002,-40.1469,-19.3577,Linhares,ADM2,DISTRICT
SM296423,15.6187,58.4105,Linköping,ADM2,DISTRICT
SM302263,-122.5342,44.4886,Linn,ADM2,DISTRICT
SM301576,-93.1072,39.8702,Linn,ADM2,DISTRICT
SM300836,-91.599,42.079,Linn,ADM2,DISTRICT
SM300938,-94.8429,38.2123,Linn,ADM2,DISTRICT
SM236185,141.962,-12.7714,Linngithigh,LANGUAGE,LANGUAGE
SM459386,141.8219,-13.173,Linngithigh,ETHNICITY,ETHNICITY
SM10863,32.9047,35.0786,Linou,ADM2,DISTRICT
SM266662,-49.6933,-21.6692,Lins,ADM2,DISTRICT
SM12479,-77.0394,18.1534,Linstead,ADM2,DISTRICT
SM370537,6.1336,49.7158,Lintgen,ADM1:ADM2:ADM3,DISTRICT
SM12480,-77.4222,18.2608,Linton Park,ADM2,DISTRICT
SM269414,103.2408,35.666,Linxia Hui,ADM2,DISTRICT
SM284491,24.0156,-18.0722,Linyandi,ADM2,DISTRICT
SM269307,118.2533,35.3422,Linyi,ADM2,DISTRICT
SM261844,14.2833,48.3,Linz,ADM2,DISTRICT
SM261843,14.3,48.1667,Linz Land,ADM2,DISTRICT
SM12481,-77.234,17.8121,Lionel Town,ADM2,DISTRICT
SM255274,122.06,-8.3,Lionese,ETHNICITY,ETHNICITY
SM10864,33.8886,35.0025,Liopetri,ADM2,DISTRICT
SM236365,-105.819,33.2748,Lipan Apache,LANGUAGE,LANGUAGE
SM255275,-101.38,30.66,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-101.38,30.66,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-101.38,30.66,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-101.38,30.66,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-103.55,35.89,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-103.55,35.89,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-103.55,35.89,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-103.55,35.89,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-100,30,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-100,30,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-100,30,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-100,30,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-98.48,28.96,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-98.48,28.96,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-98.48,28.96,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-98.48,28.96,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-104,30,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-104,30,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-104,30,Lipan Apache,ETHNICITY,ETHNICITY
SM255275,-104,30,Lipan Apache,ETHNICITY,ETHNICITY
SM291540,26.0221,45.058,Lipanesti,ADM2,DISTRICT
SM295478,166.3528,-10.2725,Lipe-Temua,ADM2,DISTRICT
SM2015,39,52.5,Lipetsk,ADM1,DISTRICT
SM16990,39.5833,52.5833,Lipetskiy Rayon,ADM2,DISTRICT
SM275964,17.1604,45.4131,Lipik,ADM2,DISTRICT
SM2016,21.6,42.1667,Lipkovo,ADM1,DISTRICT
SM304750,21.1167,42.5,Lipljan,ADM2,DISTRICT
SM290172,27.5668,44.0907,Lipnita,ADM2,DISTRICT
SM288845,19.217,52.8157,Lipno,ADM2,DISTRICT
SM236368,101.302,25.226,Lipo,LANGUAGE,LANGUAGE
SM292120,27.2324,46.7202,Lipova,ADM2,DISTRICT
SM290512,21.6947,46.0906,Lipova,ADM2,DISTRICT
SM292291,27.6927,46.5635,Lipovat,ADM2,DISTRICT
SM305448,16.8944,45.3984,Lipovljani,ADM2,DISTRICT
SM290377,23.6274,44.1134,Lipovu,ADM2,DISTRICT
SM302693,-100.2731,36.2777,Lipscomb,ADM2,DISTRICT
SM288992,21.5675,51.1588,Lipsko,ADM2,DISTRICT
SM296187,19.6167,49.0833,Liptovský Mikuláš,ADM2,DISTRICT
SM297798,125.3325,-8.5904,Liquiçá,ADM2,DISTRICT
SM2017,125.3167,-8.65,Liquiçá,ADM1,DISTRICT
SM4794,32.953,2.274,Lira (2010-),ADM1:ADM2,DISTRICT
SM16318,17.6923,0.1045,Liranga,ADM2,DISTRICT
SM15854,-65.94,18.219,Lirios Barrio,ADM2,DISTRICT
SM291894,25.1492,43.7723,Lisa,ADM2,DISTRICT
SM292635,24.8688,45.7276,Lisa,ADM2,DISTRICT
SM236377,128.265,-2.8743,Lisabata-Nuniali,LANGUAGE,LANGUAGE
SM13207,62.75,52.65,Lisakovsk Qalasy,ADM2,DISTRICT
SM289271,-9.1484,38.7263,Lisboa,ADM2,DISTRICT
SM2019,-9.1333,39,Lisboa,ADM1,DISTRICT
SM274710,-6.0525,54.5082,Lisburn and Castlereagh,ADM1:ADM2,DISTRICT
SM236380,126.981,-3.2736,Lisela,LANGUAGE,LANGUAGE
SM236384,35.2225,31.7745,Lishana Deni,LANGUAGE,LANGUAGE
SM236382,44.0653,36.2133,Lishanid Noshan,LANGUAGE,LANGUAGE
SM269388,119.5333,28.4081,Lishui,ADM2,DISTRICT
SM236383,44.37,36.23,Lishán Didán,LANGUAGE,LANGUAGE
SM16991,39.5,50.9167,Liskinskiy Rayon,ADM2,DISTRICT
SM295717,-87.9103,13.8321,Lislique,ADM2,DISTRICT
SM261573,153.2667,-28.8,Lismore,ADM2,DISTRICT
SM285667,4.5453,52.256,Lisse,ADM2,DISTRICT
SM236388,98.94,26.91,Lisu,LANGUAGE,LANGUAGE
SM255278,98.85,18.14,Lisu,ETHNICITY,ETHNICITY
SM291895,24.8216,43.7955,Lita,ADM2,DISTRICT
SM303384,-73.2453,41.7925,Litchfield,ADM2,DISTRICT
SM12482,-77.5245,18.135,Litchfield,ADM2,DISTRICT
SM261608,131.1279,-12.5023,Litchfield,ADM2,DISTRICT
SM13662,27.0249,57.1806,Litenes pagasts,ADM2,DISTRICT
SM291810,26.536,47.5107,Liteni,ADM2,DISTRICT
SM236392,109.05,34.09,Literary Chinese,LANGUAGE,LANGUAGE
SM261574,150.1858,-33.3203,Lithgow,ADM2,DISTRICT
SM236394,23.9601,55.1429,Lithuanian,LANGUAGE,LANGUAGE
SM255279,22.53,54.38,Lithuanian,ETHNICITY,ETHNICITY
SM255279,22.53,54.38,Lithuanian,ETHNICITY,ETHNICITY
SM255279,22.53,54.38,Lithuanian,ETHNICITY,ETHNICITY
SM255279,24,55,Lithuanian,ETHNICITY,ETHNICITY
SM255279,24,55,Lithuanian,ETHNICITY,ETHNICITY
SM255279,24,55,Lithuanian,ETHNICITY,ETHNICITY
SM255279,23.9172,54.9019,Lithuanian,ETHNICITY,ETHNICITY
SM255279,23.9172,54.9019,Lithuanian,ETHNICITY,ETHNICITY
SM255279,23.9172,54.9019,Lithuanian,ETHNICITY,ETHNICITY
SM255280,23,55,Lithuanian Karaim,ETHNICITY,ETHNICITY
SM236393,23.8814,55.2487,Lithuanian Sign Language,LANGUAGE,LANGUAGE
SM255281,24,55,Lithuanian Tatar,ETHNICITY,ETHNICITY
SM4144,14.9072,46.0745,Litija (2002-),ADM1,DISTRICT
SM12483,-77.5951,17.9403,Lititz,ADM2,DISTRICT
SM236399,19.24,2.69,Litoka,LANGUAGE,LANGUAGE
SM271197,14.1667,50.5,Litoměřice,ADM2,DISTRICT
SM262741,-68.2429,-18.7607,Litoral,ADM2,DISTRICT
SM2020,9.75,1.5,Litoral,ADM1,DISTRICT
SM12484,-78.2265,18.2452,Little London,ADM2,DISTRICT
SM302387,-94.2343,33.7005,Little River,ADM2,DISTRICT
SM459122,147.9565,-42.4406,Little Swanport Tasmanian,ETHNICITY,ETHNICITY
SM14070,-7.6667,4.6167,Little Wrebo,ADM2,DISTRICT
SM2022,2.4167,6.3667,Littoral,ADM1,DISTRICT
SM2023,10.1333,4.2667,Littoral (incl. Douala),ADM1,DISTRICT
SM299770,28.0897,49.3246,Lityns'kyi,ADM2,DISTRICT
SM299845,27.75,49.9167,Liubars'kyi,ADM2,DISTRICT
SM299616,30.257,47.8323,Liubashivs'kyi,ADM2,DISTRICT
SM299813,25.3313,51.7964,Liubeshivs'kyi,ADM2,DISTRICT
SM299814,24.0116,51.2506,Liuboml's'ky,ADM2,DISTRICT
SM18241,35.9217,49.9411,Liubotynska Miskrada,ADM2,DISTRICT
SM236404,109.25,24.2,Liujiang Zhuang,LANGUAGE,LANGUAGE
SM269454,104.7518,26.2402,Liupanshui,ADM2,DISTRICT
SM236405,109.75,24.1,Liuqian Zhuang,LANGUAGE,LANGUAGE
SM275910,-87.0609,13.5449,Liure,ADM2,DISTRICT
SM269438,109.4,24.3167,Liuzhou,ADM2,DISTRICT
SM236414,22.0262,57.566,Liv,LANGUAGE,LANGUAGE
SM291670,23.1333,47.8667,Livada,ADM2,DISTRICT
SM290513,21.3875,46.2192,Livada,ADM2,DISTRICT
SM10865,32.603,35.0982,Livadi,ADM2,DISTRICT
SM292200,24.2342,45.2006,Livadia,ADM2,DISTRICT
SM18522,34.0346,35.3974,Livadia (Famagusta),ADM2,DISTRICT
SM10866,33.0139,34.9522,Livadia (Nicosia),ADM2,DISTRICT
SM13663,26.1788,56.3559,Livani,ADM2,DISTRICT
SM3985,26.3506,56.3714,Livanu Novads,ADM1,DISTRICT
SM13664,23.5627,56.7067,Livberzes pagasts,ADM2,DISTRICT
SM302694,-98.1248,28.3514,Live Oak,ADM2,DISTRICT
SM10867,32.95,35.3796,Livera,ADM2,DISTRICT
SM347471,-2.9167,53.4167,Liverpool,ADM1:ADM2:ADM3,DISTRICT
SM261575,150.9123,-33.9242,Liverpool,ADM2,DISTRICT
SM261576,150.42,-31.4702,Liverpool Plains,ADM2,DISTRICT
SM292201,23.8321,44.837,Livezi,ADM2,DISTRICT
SM292121,26.7244,46.3993,Livezi,ADM2,DISTRICT
SM292351,26.8135,45.9623,Livezile,ADM2,DISTRICT
SM292502,24.636,47.1774,Livezile,ADM2,DISTRICT
SM289804,23.5856,46.3756,Livezile,ADM2,DISTRICT
SM301886,-77.7755,42.7281,Livingston,ADM2,DISTRICT
SM301297,-83.9115,42.6029,Livingston,ADM2,DISTRICT
SM301577,-93.5483,39.7821,Livingston,ADM2,DISTRICT
SM301133,-90.7279,30.4401,Livingston,ADM2,DISTRICT
SM301076,-88.3538,37.2097,Livingston,ADM2,DISTRICT
SM300641,-88.5577,40.8916,Livingston,ADM2,DISTRICT
SM275507,-88.7535,15.8278,Livingston,ADM2,DISTRICT
SM305216,25.867,-17.802,Livingstone,ADM2,DISTRICT
SM9730,150.1166,-22.8216,Livingstone,ADM2,DISTRICT
SM296097,-55.1669,5.7725,Livorno,ADM2,DISTRICT
SM277911,10.5833,43.2333,Livorno,ADM2,DISTRICT
SM264229,-36.9321,-7.3401,Livramento,ADM2,DISTRICT
SM267515,-41.9971,-13.8068,Livramento do Brumado,ADM2,DISTRICT
SM255282,23,57,Livs,ETHNICITY,ETHNICITY
SM236413,29.0726,61.6565,Livvi,LANGUAGE,LANGUAGE
SM13131,35.7887,32.6779,Liwa' Bani Kinanah,ADM2,DISTRICT
SM13132,36.0685,31.9455,Liwa' Marka,ADM2,DISTRICT
SM13133,36.6378,30.3311,Liwa' Qasabat Maan and Lel,ADM2,DISTRICT
SM13134,35.9119,31.9453,Liwa' Qasabat Amman (excl. Wadi as Sir),ADM2,DISTRICT
SM13135,35.8253,31.1612,Liwa' Qasabat al Karak,ADM2,DISTRICT
SM13136,35.2286,29.9824,Liwa' Qasabat al Aqabah,ADM2,DISTRICT
SM13137,36.5776,31.8192,"Liwa' Qasabat az Zarqa' (excl. Hashimiyah, Rusayfah, and Zarqa)",ADM2,DISTRICT
SM13138,36.2319,31.9158,Liwa' Sahab (excl. Marka),ADM2,DISTRICT
SM13139,35.7908,31.9217,Liwa' Wadi as Sir,ADM2,DISTRICT
SM13140,35.5426,31.1192,Liwa' al Aghwar al Janubiyah,ADM2,DISTRICT
SM13141,35.5955,32.4709,Liwa' al Aghwar ash Shamaliyah,ADM2,DISTRICT
SM13142,36.2899,32.3646,Liwa' al Badiyah ash Shamaliyah al Gharbiyah,ADM2,DISTRICT
SM13143,36.1097,32.1386,Liwa' al Hashimiyah,ADM2,DISTRICT
SM13144,36.0451,31.1407,Liwa' al Qatranah,ADM2,DISTRICT
SM13145,36.0488,32.013,Liwa' ar Rusayfah,ADM2,DISTRICT
SM13146,35.557,30.5078,Liwa' ash Shawbak (excl. Shoubak),ADM2,DISTRICT
SM13147,35.693,32.5538,Liwa' at Tayyibah,ADM2,DISTRICT
SM299085,37.9184,-9.3274,Liwale,ADM2,DISTRICT
SM236415,35.1212,-9.5849,Liwengi,DIALECT,LANGUAGE
SM267254,-46.9768,-9.5234,Lizarda,ADM2,DISTRICT
SM15855,-65.9388,18.0217,Lizas Barrio,ADM2,DISTRICT
SM236420,101.3503,28.3334,Lizu,LANGUAGE,LANGUAGE
SM13665,26.3525,57.2128,Lizuma pagasts,ADM2,DISTRICT
SM262100,5.5654,50.6343,Liège,ADM1:ADM2,DISTRICT
SM304024,108.1243,16.1148,Liên Chiểu,ADM2,DISTRICT
SM305612,15.7582,43.9725,Lišane Ostrovičke,ADM2,DISTRICT
SM305663,13.9603,44.8277,Ližnjan,ADM2,DISTRICT
SM305504,16.3901,46.1739,Ljubešćica,ADM2,DISTRICT
SM313231,18.1089,42.9531,Ljubinje,ADM2:ADM3,DISTRICT
SM296305,14.5092,46.0592,Ljubljana,ADM1:ADM2,DISTRICT
SM296240,14.8394,46.3739,Ljubno,ADM1:ADM2,DISTRICT
SM296669,13.8272,56.8068,Ljungby,ADM2,DISTRICT
SM296623,15.507,61.8553,Ljusdal,ADM2,DISTRICT
SM296404,14.965,59.9262,Ljusnarsberg,ADM2,DISTRICT
SM296362,16.144,46.5135,Ljutomer,ADM1:ADM2,DISTRICT
SM15856,-66.972,18.4423,Llanadas Barrio,ADM2,DISTRICT
SM12485,-76.6225,17.9448,Llandewey,ADM2,DISTRICT
SM12486,-78.1389,18.2344,Llandilo,ADM2,DISTRICT
SM302696,-98.6841,30.7057,Llano,ADM2,DISTRICT
SM15857,-66.798,18.0574,Llano Barrio,ADM2,DISTRICT
SM15858,-66.2856,18.1505,Llanos Barrio (Aibonito),ADM2,DISTRICT
SM15859,-67.0988,17.9905,Llanos Barrio (Lajas),ADM2,DISTRICT
SM15860,-67.1443,17.9788,Llanos Costa Barrio,ADM2,DISTRICT
SM15861,-67.1275,18.0516,Llanos Tuna Barrio,ADM2,DISTRICT
SM269116,-72.658,-41.3429,Llanquihue,ADM2,DISTRICT
SM15862,-65.5235,18.0957,Llave Barrio,ADM2,DISTRICT
SM274078,0.5747,41.6188,Lleida,ADM2,DISTRICT
SM282649,-98.9184,23.2661,Llera,ADM2,DISTRICT
SM461342,-76.3738,5.6011,Lloro (1999-),ADM2,DISTRICT
SM323767,-110.035,53.2807,Lloydminster (Part),ADM2:ADM3,DISTRICT
SM12487,-76.5409,17.915,Lloyds,ADM2,DISTRICT
SM12488,-77.1685,18.1498,Lluidas Vale,ADM2,DISTRICT
SM236600,166.6343,-13.3349,Lo-Toga,LANGUAGE,LANGUAGE
SM291727,24.042,45.9547,Loamnes,ADM2,DISTRICT
SM264546,-52.9953,-22.9658,Loanda,ADM2,DISTRICT
SM236423,68.715,26.095,Loarki,LANGUAGE,LANGUAGE
SM262718,-67.5467,-16.9972,Loayza,ADM2,DISTRICT
SM236424,18.3667,2.0488,Lobala,LANGUAGE,LANGUAGE
SM296049,6.6384,0.3687,Lobata,ADM1:ADM2,DISTRICT
SM303739,-72.2667,7.9167,Lobatera,ADM2,DISTRICT
SM264547,-52.0071,-22.9639,Lobato,ADM2,DISTRICT
SM2024,25.6808,-25.2142,Lobatse,ADM1,DISTRICT
SM2025,17.6667,4.1667,Lobaye,ADM1,DISTRICT
SM250434,32.77,-23.53,Lobedu,ETHNICITY,ETHNICITY
SM250434,32.77,-23.53,Lobedu,ETHNICITY,ETHNICITY
SM250434,32.77,-23.53,Lobedu,ETHNICITY,ETHNICITY
SM250434,30.5,-23.5,Lobedu,ETHNICITY,ETHNICITY
SM250434,30.5,-23.5,Lobedu,ETHNICITY,ETHNICITY
SM250434,30.5,-23.5,Lobedu,ETHNICITY,ETHNICITY
SM250434,31,-24,Lobedu,ETHNICITY,ETHNICITY
SM250434,31,-24,Lobedu,ETHNICITY,ETHNICITY
SM250434,31,-24,Lobedu,ETHNICITY,ETHNICITY
SM260790,-58.75,-38.0833,Lobería,ADM2,DISTRICT
SM236427,-3.336,9.9612,Lobi,LANGUAGE,LANGUAGE
SM250435,-4,10,Lobi,ETHNICITY,ETHNICITY
SM4687,13.8561,-12.1348,Lobito (2011-),ADM2,DISTRICT
SM305731,16.0654,46.1503,Lobor,ADM2,DISTRICT
SM260792,-59.1667,-35.25,Lobos,ADM2,DISTRICT
SM268977,8.7599,46.1803,Locarno,ADM2,DISTRICT
SM261577,146.7935,-35.3219,Lockhart,ADM2,DISTRICT
SM261638,143.2502,-13.0188,Lockhart River,ADM2,DISTRICT
SM261639,152.2304,-27.6814,Lockyer Valley,ADM2,DISTRICT
SM255284,71.08,29.98,Lod,ETHNICITY,ETHNICITY
SM261247,143.8296,-36.4041,Loddon,ADM2,DISTRICT
SM13666,25.3583,58.0169,Lodes pagasts,ADM2,DISTRICT
SM16992,33.75,60.5,Lodeynopol'skiy Rayon,ADM2,DISTRICT
SM236435,84.9463,20.8954,Lodhi,LANGUAGE,LANGUAGE
SM255286,78.62,25.45,Lodhi Rajput,ETHNICITY,ETHNICITY
SM375467,71.6902,29.6869,Lodhran,ADM2:ADM3,DISTRICT
SM277871,9.5286,45.2964,Lodi,ADM2,DISTRICT
SM2027,101.5,17.4167,Loei,ADM1,DISTRICT
SM297763,104.5144,16.2043,Loeng Nok Tha,ADM2,DISTRICT
SM2028,-9.85,8.0333,Lofa,ADM1,DISTRICT
SM10868,32.8791,34.8033,Lofou,ADM2,DISTRICT
SM4146,14.3747,46.0154,Log-Dragomer,ADM1,DISTRICT
SM284606,3.5,13.6333,Loga,ADM2,DISTRICT
SM303123,-81.9353,37.8315,Logan,ADM2,DISTRICT
SM302208,-97.4433,35.9193,Logan,ADM2,DISTRICT
SM302124,-83.7659,40.3885,Logan,ADM2,DISTRICT
SM301730,-100.4829,41.5665,Logan,ADM2,DISTRICT
SM302046,-99.4775,46.4574,Logan,ADM2,DISTRICT
SM301077,-86.8789,36.8597,Logan,ADM2,DISTRICT
SM300939,-101.1484,38.9173,Logan,ADM2,DISTRICT
SM303350,-103.1101,40.7247,Logan,ADM2,DISTRICT
SM300642,-89.3676,40.1246,Logan,ADM2,DISTRICT
SM302388,-93.7163,35.2153,Logan,ADM2,DISTRICT
SM261640,153.0449,-27.7629,Logan,ADM2,DISTRICT
SM2029,69.25,34,Logar,ADM1,DISTRICT
SM296306,14.1958,45.9214,Logatec,ADM1:ADM2,DISTRICT
SM255287,147.49,-6.26,Logauleng,ETHNICITY,ETHNICITY
SM236446,29.8866,3.3261,Logo,LANGUAGE,LANGUAGE
SM285341,9.2505,7.6892,Logo,ADM2,DISTRICT
SM250436,29.75,2.82,Logo,ETHNICITY,ETHNICITY
SM250436,29.75,2.82,Logo,ETHNICITY,ETHNICITY
SM250436,30,3,Logo,ETHNICITY,ETHNICITY
SM250436,30,3,Logo,ETHNICITY,ETHNICITY
SM274009,38.6372,15.3642,Logo Anseba,ADM2,DISTRICT
SM236449,31.1236,10.9805,Logol,LANGUAGE,LANGUAGE
SM255288,34.75,0.05,Logoli,ETHNICITY,ETHNICITY
SM2030,15.8724,8.7529,Logone Occidental,ADM1,DISTRICT
SM2031,16.42,8.25,Logone Oriental,ADM1,DISTRICT
SM269520,14.8333,12.0833,Logone et Chari,ADM2,DISTRICT
SM236445,34.729,0.0949,Logooli,LANGUAGE,LANGUAGE
SM236448,29.8053,11.1739,Logorik,LANGUAGE,LANGUAGE
SM264230,-35.4286,-6.5527,Logradouro,ADM2,DISTRICT
SM273519,-78.0221,-2.7768,Logroño,ADM2,DISTRICT
SM236453,9.0996,40.49,Logudorese Sardinian,LANGUAGE,LANGUAGE
SM12489,-78.2962,18.3488,Logwood,ADM2,DISTRICT
SM276905,84.6,23.5,Lohardaga,ADM2,DISTRICT
SM277211,96.1952,27.8401,Lohit (2014-),ADM2,DISTRICT
SM240178,87.2157,27.5102,Lohorung,LANGUAGE,LANGUAGE
SM226378,18.1266,0.9378,Loi-Likila,LANGUAGE,LANGUAGE
SM283506,97.3418,19.4913,Loikaw,ADM2,DISTRICT
SM15095,97.9266,21.3136,Loilem District,ADM2,DISTRICT
SM346812,22.8087,60.7869,Loimaa,ADM2:ADM3,DISTRICT
SM274485,1.5,47.5,Loir-et-Cher,ADM2,DISTRICT
SM274432,4.433,45.4717,Loire,ADM2,DISTRICT
SM274458,-1.6576,47.3286,Loire-Atlantique,ADM2,DISTRICT
SM274486,2.3017,47.936,Loiret,ADM2,DISTRICT
SM15863,-65.8796,18.432,Loiza Barrio-Pueblo,ADM2,DISTRICT
SM273475,-79.2,-4,Loja,ADM2,DISTRICT
SM2033,-79.5,-4.1667,Loja,ADM1,DISTRICT
SM236462,-79.2915,-3.6935,Loja Highland Quichua,LANGUAGE,LANGUAGE
SM236464,8.0933,5.9148,Lokaa,LANGUAGE,LANGUAGE
SM299642,33.2712,50.3602,Lokhvyts'kyi,ADM2,DISTRICT
SM236473,-12.1583,9.1184,Loko,LANGUAGE,LANGUAGE
SM250438,-12.16,8.35,Loko,ETHNICITY,ETHNICITY
SM284955,6.5631,8.2049,Lokoja,ADM2,DISTRICT
SM225693,-56.9243,5.643,Lokono,LANGUAGE,LANGUAGE
SM9826,1.7416,6.698,Lokossa,ADM2,DISTRICT
SM236472,32.2625,4.6472,Lokoya,LANGUAGE,LANGUAGE
SM274086,25.7167,59.5784,Loksa,ADM2,DISTRICT
SM292974,81.5833,51,Loktevskiy rayon,ADM2,DISTRICT
SM305410,14.7515,45.3579,Lokve,ADM2,DISTRICT
SM305465,17.0914,43.4643,Lokvičići,ADM2,DISTRICT
SM236476,134.879,-6.3419,Lola,LANGUAGE,LANGUAGE
SM275003,-8.3333,7.8333,Lola,ADM1:ADM2,DISTRICT
SM236478,124.117,0.8554,Lolak,LANGUAGE,LANGUAGE
SM236481,123.085,-10.8069,Lole,LANGUAGE,LANGUAGE
SM271734,11.2952,54.8024,Lolland,ADM2,DISTRICT
SM236485,35.9451,-17.0232,Lolo,LANGUAGE,LANGUAGE
SM4320,12.2759,-0.8943,Lolo-Bouenguidi (1996-),ADM2,DISTRICT
SM236488,127.772,1.9546,Loloda-Laba,LANGUAGE,LANGUAGE
SM236483,101.3019,25.5005,Lolopo,LANGUAGE,LANGUAGE
SM295762,-88.3397,13.5926,Lolotique,ADM2,DISTRICT
SM295722,-88.0757,13.7296,Lolotiquillo,ADM2,DISTRICT
SM281683,-98.7312,21.0092,Lolotla,ADM2,DISTRICT
SM297833,125.2667,-9.1667,Lolotoi,ADM2,DISTRICT
SM286126,8.5681,61.8364,Lom,ADM2,DISTRICT
SM262309,23.2333,43.8167,Lom,ADM2,DISTRICT
SM297342,101.2619,16.9721,Lom Kao,ADM2,DISTRICT
SM297343,101.3114,16.7284,Lom Sak,ADM2,DISTRICT
SM269518,13.8333,5.1667,Lom et Djerem,ADM2,DISTRICT
SM236494,-3.4705,9.4017,Loma (Côte d'Ivoire),LANGUAGE,LANGUAGE
SM236496,-9.6991,7.9159,Loma (Liberia),LANGUAGE,LANGUAGE
SM14321,-95.8883,18.0188,Loma Bonita,ADM2,DISTRICT
SM289529,-57.2092,-25.1485,Loma Grande,ADM2,DISTRICT
SM15349,-59.6906,-22.3051,Loma Plata,ADM2,DISTRICT
SM271893,-71.5833,19.4167,Loma de Cabrera,ADM2,DISTRICT
SM296692,32.0077,-26.0664,Lomahasha,ADM2,DISTRICT
SM236497,179.306,-18.0001,Lomaiviti,LANGUAGE,LANGUAGE
SM274413,178.8,-17.6667,Lomaiviti,ADM2,DISTRICT
SM2034,24.4833,-6.1333,Lomami,ADM1:ADM2,DISTRICT
SM2034,24.4833,-6.1333,Lomami,ADM1:ADM2,DISTRICT
SM2034,24.6667,-6.2167,Lomami,ADM1:ADM2,DISTRICT
SM2034,24.6667,-6.2167,Lomami,ADM1:ADM2,DISTRICT
SM255299,26.75,-6.19,Lomami,ETHNICITY,ETHNICITY
SM15864,-65.8927,18.2816,Lomas Barrio (Canóvanas),ADM2,DISTRICT
SM15865,-66.5071,18.0599,Lomas Barrio (Juana Díaz),ADM2,DISTRICT
SM15866,-66.2686,18.3179,Lomas Barrio (Naranjito),ADM2,DISTRICT
SM273435,-80.0787,-1.8474,Lomas de Sargentillo,ADM2,DISTRICT
SM260793,-58.4167,-34.75,Lomas de Zamora,ADM2,DISTRICT
SM236493,44.095,41.5994,Lomavren,LANGUAGE,LANGUAGE
SM250440,28,0,Lomba,ETHNICITY,ETHNICITY
SM236501,9.3951,45.8817,Lombard,LANGUAGE,LANGUAGE
SM17485,-60.9151,13.8577,Lombard,ADM2,DISTRICT
SM2035,9.5,45.6667,Lombardia,ADM1,DISTRICT
SM236500,27.342,0.268,Lombi,LANGUAGE,LANGUAGE
SM236503,24.6862,0.8827,Lombo,LANGUAGE,LANGUAGE
SM274574,12.639,-1.4513,Lombo-Bouenguidi,ADM2,DISTRICT
SM276486,116.1167,-8.6958,Lombok Barat,ADM2,DISTRICT
SM276487,116.3,-8.7,Lombok Tengah,ADM2,DISTRICT
SM276488,116.5333,-8.5333,Lombok Timur,ADM2,DISTRICT
SM276489,116.4015,-8.3521,Lombok Utara,ADM2,DISTRICT
SM228799,-61.8178,-16.7696,Lomeriano-Ignaciano Chiquitano,LANGUAGE,LANGUAGE
SM296436,13.0732,55.7036,Lomma,ADM2,DISTRICT
SM323879,-112.6419,50.3477,Lomond,ADM2:ADM3,DISTRICT
SM293532,29.7399,59.8972,Lomonosovskiy rayon,ADM2,DISTRICT
SM250441,37.42,-15.82,Lomwe,ETHNICITY,ETHNICITY
SM276787,-4.4326,54.2149,Lonan,ADM1:ADM2,DISTRICT
SM236517,103.548,-0.0259,Loncong,LANGUAGE,LANGUAGE
SM260930,-70.3333,-38.0833,Loncopué,ADM2,DISTRICT
SM16319,13.4123,-4.7273,Londela-Kayes,ADM2,DISTRICT
SM17486,-60.9422,13.7974,London Road,ADM2,DISTRICT
SM17487,-61.0136,13.7762,Londonderry,ADM2,DISTRICT
SM264548,-51.0874,-23.5864,Londrina,ADM2,DISTRICT
SM260463,15.2851,-12.2186,Londuimbale,ADM2,DISTRICT
SM300530,-81.7458,31.7526,Long,ADM2,DISTRICT
SM297393,99.909,18.1426,Long,ADM2,DISTRICT
SM280875,100.831,20.985,Long,ADM2,DISTRICT
SM2036,106.1667,10.7,Long An,ADM1,DISTRICT
SM12490,-76.3251,18.0858,Long Bay,ADM2,DISTRICT
SM304139,105.8962,21.0414,Long Biên,ADM2,DISTRICT
SM304571,105.9848,10.2164,Long Hồ,ADM2,DISTRICT
SM2037,-75.1117,23.2167,Long Island,ADM1,DISTRICT
SM304003,107.2256,10.9365,Long Khánh,ADM2,DISTRICT
SM304092,105.5313,9.6512,Long Mỹ,ADM2,DISTRICT
SM304091,105.5784,9.6702,Long Mỹ (Thị xã),ADM2,DISTRICT
SM236522,94.9838,25.5578,Long Phuri Naga,LANGUAGE,LANGUAGE
SM304453,106.0823,9.6372,Long Phú,ADM2,DISTRICT
SM12491,-76.2948,18.0086,Long Road (Portland),ADM2,DISTRICT
SM18521,-76.7501,18.2134,Long Road (Saint Mary),ADM2,DISTRICT
SM304004,107.0269,10.7616,Long Thành,ADM2,DISTRICT
SM255303,-123.53,44.31,Long Tom Creek,ETHNICITY,ETHNICITY
SM303919,105.4234,10.3686,Long Xuyên,ADM2,DISTRICT
SM304598,107.2269,10.4514,Long Điền,ADM2,DISTRICT
SM224861,97.7438,24.3479,Longchuan Achang,LANGUAGE,LANGUAGE
SM277212,95.3229,26.8042,Longding,ADM2,DISTRICT
SM2038,-7.75,53.6667,Longford,ADM1:ADM2,DISTRICT
SM236541,160.663,-9.7526,Longgu,LANGUAGE,LANGUAGE
SM295374,160.6214,-9.6364,Longgu,ADM2,DISTRICT
SM299078,36.6951,-2.7317,Longido,ADM2,DISTRICT
SM236563,105.2333,26.4,Longjia,LANGUAGE,LANGUAGE
SM277100,94.8003,26.5554,Longleng,ADM2,DISTRICT
SM269415,105.3495,33.5345,Longnan,ADM2,DISTRICT
SM260464,15.2994,-13.0548,Longonjo,ADM2,DISTRICT
SM261641,143.8671,-23.876,Longreach,ADM2,DISTRICT
SM280821,102.8808,18.6178,Longsane,ADM1:ADM2,DISTRICT
SM236533,13.0568,8.3331,Longto,LANGUAGE,LANGUAGE
SM236535,11.8378,9.7579,Longuda,LANGUAGE,LANGUAGE
SM323907,-114.2335,50.5308,Longview,ADM2:ADM3,DISTRICT
SM12492,-77.1598,17.9119,Longville Park,ADM2,DISTRICT
SM12493,-77.2873,17.8075,Longwood,ADM2,DISTRICT
SM269394,116.6517,25.2998,Longyan,ADM2,DISTRICT
SM236568,147.35,-2.0612,Loniu,LANGUAGE,LANGUAGE
SM302389,-91.8887,34.7543,Lonoke,ADM2,DISTRICT
SM263609,-44.2782,-15.8394,Lontra,ADM2,DISTRICT
SM266196,-49.4992,-27.1793,Lontras,ADM2,DISTRICT
SM236570,168.016,-16.2286,Lonwolwol,LANGUAGE,LANGUAGE
SM236571,16.7259,-4.6144,Lonzo,DIALECT,LANGUAGE
SM236576,11.0511,9.4066,Loo,LANGUAGE,LANGUAGE
SM285932,5.0486,51.6378,Loon op Zand,ADM2,DISTRICT
SM2039,100.8861,15.1219,Lop Buri,ADM1,DISTRICT
SM236577,4.674,10.3756,Lopa,LANGUAGE,LANGUAGE
SM289805,23.8498,46.2778,Lopadea Noua,ADM2,DISTRICT
SM305411,14.7308,44.8311,Lopar,ADM2,DISTRICT
SM275972,14.7591,44.7577,Lopar/Supetarska Draga,ADM2,DISTRICT
SM313203,18.8367,44.6281,Lopare,ADM2:ADM3,DISTRICT
SM289883,26.5523,45.4877,Lopatari,ADM2,DISTRICT
SM236579,97.0833,25.0833,Lopi,LANGUAGE,LANGUAGE
SM285593,4.9485,51.9899,Lopik,ADM2,DISTRICT
SM236578,32.7534,4.6986,Lopit,LANGUAGE,LANGUAGE
SM236580,84.224,40.983,Lopnor,DIALECT,LANGUAGE
SM235138,147.143,-2.0343,Lopohan,LANGUAGE,LANGUAGE
SM286338,22.3514,70.2395,Loppa,ADM2,DISTRICT
SM285832,6.7215,53.3385,Loppersum,ADM2,DISTRICT
SM274570,11.893,-0.148,Lopé,ADM2,DISTRICT
SM302125,-82.1515,41.4525,Lorain,ADM2,DISTRICT
SM375398,68.8464,30.3025,Loralai,ADM2:ADM3,DISTRICT
SM236581,134.41,-6.3034,Lorang,LANGUAGE,LANGUAGE
SM277676,50.8333,31.4333,Lordakan,ADM2,DISTRICT
SM266663,-45.0608,-22.7824,Lorena,ADM2,DISTRICT
SM370538,6.1425,49.6947,Lorentzweiler,ADM1:ADM2:ADM3,DISTRICT
SM2040,48.5,33.5,Lorestan,ADM1,DISTRICT
SM286779,-75.1667,-4,Loreto,ADM2,DISTRICT
SM289523,-57.1512,-23.189,Loreto,ADM2,DISTRICT
SM283054,-101.929,22.2705,Loreto,ADM2,DISTRICT
SM14322,-111.4504,25.955,Loreto,ADM2,DISTRICT
SM273532,-77.3026,-0.6949,Loreto,ADM2,DISTRICT
SM262812,-45.2314,-7.1192,Loreto,ADM2,DISTRICT
SM261042,-64.3333,-28.55,Loreto,ADM2,DISTRICT
SM2041,-74.5,-4,Loreto (1980-),ADM1,DISTRICT
SM2042,44.5,41,Lori,ADM1,DISTRICT
SM255307,129.22,-27.55,Loridja,ETHNICITY,ETHNICITY
SM255307,129.22,-27.55,Loridja,ETHNICITY,ETHNICITY
SM255307,133.1519,-24.9471,Loridja,ETHNICITY,ETHNICITY
SM255307,133.1519,-24.9471,Loridja,ETHNICITY,ETHNICITY
SM10185,-98.7494,49.4192,Lorne,ADM2,DISTRICT
SM262185,-2.1667,13.9167,Loroum,ADM1:ADM2,DISTRICT
SM12494,-77.4921,18.2303,Lorrimers,ADM2,DISTRICT
SM301841,-106.3073,35.8694,Los Alamos,ADM2,DISTRICT
SM271883,-70.0364,18.5279,Los Alcarrizos,ADM2,DISTRICT
SM282175,-99.2411,26.1141,Los Aldamas,ADM2,DISTRICT
SM271858,-71.4422,19.3229,Los Almácigos,ADM2,DISTRICT
SM275508,-89.1009,15.2574,Los Amates,ADM2,DISTRICT
SM270328,-77.6796,1.6283,Los Andes,ADM2,DISTRICT
SM269090,-70.3352,-32.8256,Los Andes,ADM2,DISTRICT
SM262719,-68.4157,-16.354,Los Andes,ADM2,DISTRICT
SM260950,-67.3333,-24.5,Los Andes,ADM2,DISTRICT
SM303173,-118.261,34.198,Los Angeles,ADM2,DISTRICT
SM271011,-80.6634,22.6168,Los Arabos,ADM2,DISTRICT
SM14323,-109.7532,23.2766,Los Cabos,ADM2,DISTRICT
SM271833,-70.3251,18.5931,Los Cacaos,ADM2,DISTRICT
SM289669,-54.7513,-25.686,Los Cedrales,ADM2,DISTRICT
SM18338,-56.3888,-34.6224,Los Cerrillos,ADM2,DISTRICT
SM270909,-84.6649,10.879,Los Chiles,ADM2,DISTRICT
SM269955,-76.2909,8.8717,Los Córdobas,ADM2,DISTRICT
SM303877,-67.9364,10.1834,Los Guayos,ADM2,DISTRICT
SM282176,-99.4205,25.9101,Los Herreras,ADM2,DISTRICT
SM271820,-71.0333,19.75,Los Hidalgos,ADM2,DISTRICT
SM260916,-71.4167,-40.75,Los Lagos,ADM2,DISTRICT
SM2043,-73.9605,-42.5989,Los Lagos (2007-),ADM1,DISTRICT
SM271850,-69.5,18.6333,Los Llanos,ADM2,DISTRICT
SM15867,-66.4078,18.0573,Los Llanos Barrio,ADM2,DISTRICT
SM271034,-83.25,22.5833,Los Palacios,ADM2,DISTRICT
SM270551,-75.2065,9.4249,Los Palmitos,ADM2,DISTRICT
SM297852,126.9956,-8.5182,Los Palos,ADM2,DISTRICT
SM270374,-72.5692,7.7054,Los Patios,ADM2,DISTRICT
SM286708,-80.6431,7.7094,Los Pozos,ADM2,DISTRICT
SM282177,-99.6024,25.6566,Los Ramones,ADM2,DISTRICT
SM282931,-97.0385,18.6743,Los Reyes,ADM2,DISTRICT
SM282047,-102.4136,19.6491,Los Reyes,ADM2,DISTRICT
SM282442,-97.8254,18.9646,Los Reyes de Juárez,ADM2,DISTRICT
SM271810,-71.5752,18.5639,Los Rios,ADM2,DISTRICT
SM2044,-79.5833,-1.4167,Los Rios,ADM1,DISTRICT
SM2045,-72.5153,-40.015,Los Ríos,ADM1,DISTRICT
SM303639,-66.9518,10.3885,Los Salias,ADM2,DISTRICT
SM286716,-80.45,7.8833,Los Santos,ADM2,DISTRICT
SM270484,-73.1249,6.7973,Los Santos,ADM2,DISTRICT
SM2046,-80.3333,7.5833,Los Santos,ADM1,DISTRICT
SM303566,-70.2535,11.8231,Los Tanques,ADM2,DISTRICT
SM461504,150.8,-5.483,Losa,ETHNICITY,ETHNICITY
SM14942,152.739,6.894,Losap Municipality,ADM2,DISTRICT
SM285563,7.0011,52.2982,Losser,ADM2,DISTRICT
SM274457,1.6346,44.627,Lot,ADM2,DISTRICT
SM274434,0.5,44.3333,Lot-et-Garonne,ADM2,DISTRICT
SM236594,150.911,-5.954,Lote,LANGUAGE,LANGUAGE
SM236596,94.0436,26.014,Lotha Naga,LANGUAGE,LANGUAGE
SM293686,35.6667,56.25,Lotoshinskiy rayon,ADM2,DISTRICT
SM12495,-77.807,18.415,Lottery,ADM2,DISTRICT
SM236603,116.232,6.1373,Lotud,LANGUAGE,LANGUAGE
SM236614,147.346,-2.3866,Lou,LANGUAGE,LANGUAGE
SM2047,101.3333,20.9167,Louang Namtha,ADM1,DISTRICT
SM280883,102.1521,19.8226,Louangphrabang,ADM2,DISTRICT
SM2048,102.6667,20.25,Louangphrabang,ADM1,DISTRICT
SM236604,111.9282,27.7333,Loudi,DIALECT,LANGUAGE
SM269205,111.6764,27.7529,Loudi,ADM2,DISTRICT
SM269795,12.943,-3.988,Loudima,ADM2,DISTRICT
SM302592,-84.3119,35.7348,Loudon,ADM2,DISTRICT
SM303039,-77.6357,39.0907,Loudoun,ADM2,DISTRICT
SM4317,12.2867,-2.1733,Louetsi-Bibaka,ADM2,DISTRICT
SM274561,11.516,-2.18,Louetsi-Wano,ADM2,DISTRICT
SM296831,16.7679,10.5786,Loug Chari,ADM2,DISTRICT
SM295287,-16,15.75,Louga,ADM2,DISTRICT
SM2049,-15.5,15.4167,Louga,ADM1,DISTRICT
SM323937,-111.5382,52.7478,Lougheed,ADM2:ADM3,DISTRICT
SM16320,14.6486,-4.5108,Louingui,ADM2,DISTRICT
SM303040,-77.963,37.9782,Louisa,ADM2,DISTRICT
SM300837,-91.2596,41.2185,Louisa,ADM2,DISTRICT
SM10186,-98.9014,49.1631,Louise (2015-),ADM2,DISTRICT
SM2051,-92.0004,31.0005,Louisiana,ADM1,DISTRICT
SM236606,-90.7475,30.0412,Louisiana Creole French,LANGUAGE,LANGUAGE
SM293118,33.5,66,Loukhskiy rayon,ADM2,DISTRICT
SM269816,17.1302,-0.8634,Loukoléla,ADM2,DISTRICT
SM262545,24.25,43.1833,Loukovit,ADM2,DISTRICT
SM10869,32.4724,34.9577,Loukrounou,ADM2,DISTRICT
SM289515,-8.0235,37.144,Loulé,ADM2,DISTRICT
SM16321,14.4745,-4.6677,Loumo,ADM2,DISTRICT
SM236608,128.912,-2.8778,Loun,LANGUAGE,LANGUAGE
SM271198,13.6667,50.3333,Louny,ADM2,DISTRICT
SM301731,-99.4544,41.9138,Loup,ADM2,DISTRICT
SM236610,-72.731,42.364,Loup A,LANGUAGE,LANGUAGE
SM270375,-72.8452,7.9677,Lourdes,ADM2,DISTRICT
SM266664,-50.238,-20.9435,Lourdes,ADM2,DISTRICT
SM289272,-9.1334,38.8302,Loures,ADM2,DISTRICT
SM289273,-9.3119,39.2475,Lourinhã,ADM2,DISTRICT
SM10870,33.482,35.0204,Louroukina,ADM2,DISTRICT
SM289313,-8.2744,41.2836,Lousada,ADM2,DISTRICT
SM289484,-8.2405,40.1409,Lousã,ADM2,DISTRICT
SM2052,-6.5,53.8333,Louth,ADM1:ADM2,DISTRICT
SM10871,32.7607,35.1556,Loutros,ADM2,DISTRICT
SM16322,12.584,-3.996,Louvakou,ADM2,DISTRICT
SM10872,33.0416,34.8311,Louvaras,ADM2,DISTRICT
SM266665,-46.9331,-23.0825,Louveira,ADM2,DISTRICT
SM17488,-60.887,13.9593,Louvet,ADM2,DISTRICT
SM305539,19.168,45.2262,Lovas,ADM2,DISTRICT
SM302209,-97.2442,33.9499,Love,ADM2,DISTRICT
SM262546,24.8,43.1667,Lovech,ADM2,DISTRICT
SM2053,24.5667,43.0333,Lovech,ADM1,DISTRICT
SM260845,-65.5,-36.5,Loventué,ADM2,DISTRICT
SM346791,26.1931,60.4908,Loviisa,ADM2:ADM3,DISTRICT
SM305756,15.6868,44.392,Lovinac,ADM2,DISTRICT
SM302697,-103.58,31.8492,Loving,ADM2,DISTRICT
SM247915,166.7984,-11.6107,Lovono,LANGUAGE,LANGUAGE
SM305413,14.2725,45.2963,Lovran,ADM2,DISTRICT
SM296328,15.3854,46.5227,Lovrenc na Pohorju,ADM1:ADM2,DISTRICT
SM305466,16.9859,43.4886,Lovreć,ADM2,DISTRICT
SM295306,158.7413,-9.0212,Lovukol,ADM2,DISTRICT
SM236619,83.8959,29.0324,Lowa,LANGUAGE,LANGUAGE
SM275059,-16.05,13.5,Lower Baddibu,ADM2,DISTRICT
SM18581,12.8333,48.75,Lower Bavaria,ADM2,DISTRICT
SM370375,-8.1695,4.841,Lower Buah,ADM2:ADM3,DISTRICT
SM236662,-124.004,46.7736,Lower Chehalis,LANGUAGE,LANGUAGE
SM255309,-123.67,46.97,Lower Chehalis,ETHNICITY,ETHNICITY
SM255309,-123.67,46.97,Lower Chehalis,ETHNICITY,ETHNICITY
SM255309,-123.67,46.87,Lower Chehalis,ETHNICITY,ETHNICITY
SM255309,-123.67,46.87,Lower Chehalis,ETHNICITY,ETHNICITY
SM454632,-122.7,53,Lower Dakelh,ETHNICITY,ETHNICITY
SM236640,141.5854,-33.9471,Lower Darling-Lower Lachlan,LANGUAGE,LANGUAGE
SM277213,95.8804,28.3726,Lower Dibang Valley,ADM2,DISTRICT
SM5125,71.8534,34.8519,Lower Dir,ADM2:ADM3,DISTRICT
SM261703,135.7011,-34.4881,Lower Eyre Peninsula,ADM2,DISTRICT
SM236652,138.992,-4.2568,Lower Grand Valley Dani,LANGUAGE,LANGUAGE
SM286520,174.9675,-41.2848,Lower Hutt,ADM2,DISTRICT
SM14071,-8.5624,4.8783,Lower Jloh,ADM2,DISTRICT
SM15310,73.0049,35.1103,Lower Kohistan District,ADM2,DISTRICT
SM11420,0.0148,6.1668,Lower Manya Krobo,ADM2,DISTRICT
SM275060,-16.4167,13.5333,Lower Nuimi,ADM2,DISTRICT
SM236671,153.4861,-28.937,Lower Richmond,LANGUAGE,LANGUAGE
SM2054,-15.66,13.4,Lower River,ADM1,DISTRICT
SM236639,140.5,-34.6,Lower Riverland,LANGUAGE,LANGUAGE
SM275050,-15.3667,13.7167,Lower Saloum,ADM2,DISTRICT
SM11665,95.0157,28.0671,Lower Siang,ADM2,DISTRICT
SM236625,15.9667,50.6963,Lower Silesian,LANGUAGE,LANGUAGE
SM236622,13.9407,51.6621,Lower Sorbian,LANGUAGE,LANGUAGE
SM236664,135.4,-26.3,Lower Southern Aranda,LANGUAGE,LANGUAGE
SM255310,135.52,-26.09,Lower Southern Aranda,ETHNICITY,ETHNICITY
SM255310,135.52,-26.09,Lower Southern Aranda,ETHNICITY,ETHNICITY
SM255310,135.3928,-25.9546,Lower Southern Aranda,ETHNICITY,ETHNICITY
SM255310,135.3928,-25.9546,Lower Southern Aranda,ETHNICITY,ETHNICITY
SM277197,93.9391,27.6255,Lower Subansiri (2000-),ADM2,DISTRICT
SM236632,106.873,15.9083,Lower Ta'oih,LANGUAGE,LANGUAGE
SM236660,-149.451,64.8834,Lower Tanana,LANGUAGE,LANGUAGE
SM236644,20.5888,-25.5452,Lower-Nosop,LANGUAGE,LANGUAGE
SM255311,-2.61,11.59,Lowiili,ETHNICITY,ETHNICITY
SM255311,-2.61,11.59,Lowiili,ETHNICITY,ETHNICITY
SM255311,-3,11,Lowiili,ETHNICITY,ETHNICITY
SM255311,-3,11,Lowiili,ETHNICITY,ETHNICITY
SM236685,-95.6876,15.9497,Lowland Oaxaca Chontal,LANGUAGE,LANGUAGE
SM236688,-107.854,27.4623,Lowland Tarahumara,LANGUAGE,LANGUAGE
SM301464,-88.4433,33.4729,Lowndes,ADM2,DISTRICT
SM300531,-83.2677,30.8339,Lowndes,ADM2,DISTRICT
SM300283,-86.6501,32.1548,Lowndes,ADM2,DISTRICT
SM236698,-96.6997,15.8442,Loxicha Zapotec,LANGUAGE,LANGUAGE
SM261704,140.2835,-34.4816,Loxton Waikerie,ADM2,DISTRICT
SM262585,30.6667,52,Loyew,ADM2,DISTRICT
SM236700,24.7398,-17.7052,Lozi,LANGUAGE,LANGUAGE
SM250443,23.71,-15.81,Lozi,ETHNICITY,ETHNICITY
SM250443,23.71,-15.81,Lozi,ETHNICITY,ETHNICITY
SM250443,23.71,-15.81,Lozi,ETHNICITY,ETHNICITY
SM250443,23.73,-17.36,Lozi,ETHNICITY,ETHNICITY
SM250443,23.73,-17.36,Lozi,ETHNICITY,ETHNICITY
SM250443,23.73,-17.36,Lozi,ETHNICITY,ETHNICITY
SM250443,23,-15,Lozi,ETHNICITY,ETHNICITY
SM250443,23,-15,Lozi,ETHNICITY,ETHNICITY
SM250443,23,-15,Lozi,ETHNICITY,ETHNICITY
SM250443,23.5,-16,Lozi,ETHNICITY,ETHNICITY
SM250443,23.5,-16,Lozi,ETHNICITY,ETHNICITY
SM250443,23.5,-16,Lozi,ETHNICITY,ETHNICITY
SM300003,36.3531,48.8907,Lozivs'kyi,ADM2,DISTRICT
SM291616,23.4851,47.3151,Lozna,ADM2,DISTRICT
SM262359,26.5833,43.4167,Loznitsa,ADM2,DISTRICT
SM2055,21.9333,41.75,Lozovo,ADM1,DISTRICT
SM274446,3.5,44.5,Lozère,ADM2,DISTRICT
SM2032,-65.8927,18.4341,Loíza,ADM1,DISTRICT
SM296290,14.5042,45.6661,Loška Dolina,ADM1:ADM2,DISTRICT
SM296270,14.6453,45.66,Loški Potok,ADM1:ADM2,DISTRICT
SM296867,104.7112,15.7078,Lu Amnat,ADM2,DISTRICT
SM269122,116.3029,31.7162,Lu'an,ADM2,DISTRICT
SM236703,101.217,19.1198,Lua',LANGUAGE,LANGUAGE
SM2056,25.4667,-10.7167,Lualaba,ADM1:ADM2,DISTRICT
SM18140,24.7444,-15.2907,Luampa District,ADM2,DISTRICT
SM4462,13.3392,-8.9167,Luanda DHS 2015,ADM2,DISTRICT
SM461304,13.3392,-8.9167,Luanda province (2011-),ADM1,DISTRICT
SM236714,127.944,-8.1838,Luang,LANGUAGE,LANGUAGE
SM241710,159.701,-5.4903,Luangiua,LANGUAGE,LANGUAGE
SM305187,30.0103,-15.4149,Luangwa,ADM2,DISTRICT
SM295432,159.5677,-5.5256,Luaniua,ADM2,DISTRICT
SM255314,28.3,-13.82,Luano,ETHNICITY,ETHNICITY
SM18141,29.5537,-14.4782,Luano District,ADM2,DISTRICT
SM305166,28.3608,-13.0592,Luanshya,ADM2,DISTRICT
SM2058,29,-11,Luapula,ADM1,DISTRICT
SM250453,28.41,-11.82,Luapula Lunda,ETHNICITY,ETHNICITY
SM250453,28.41,-11.82,Luapula Lunda,ETHNICITY,ETHNICITY
SM250453,26.92,-10.45,Luapula Lunda,ETHNICITY,ETHNICITY
SM250453,26.92,-10.45,Luapula Lunda,ETHNICITY,ETHNICITY
SM250453,29,-10,Luapula Lunda,ETHNICITY,ETHNICITY
SM250453,29,-10,Luapula Lunda,ETHNICITY,ETHNICITY
SM260362,21.98,-10.9184,Luau,ADM2,DISTRICT
SM275118,8.5538,3.4582,Luba,ADM2,DISTRICT
SM236717,25.8918,-8.2816,Luba-Katanga,LANGUAGE,LANGUAGE
SM250444,21.69,-11.08,Luba-Katanga,ETHNICITY,ETHNICITY
SM250444,21.69,-11.08,Luba-Katanga,ETHNICITY,ETHNICITY
SM250444,21.69,-11.08,Luba-Katanga,ETHNICITY,ETHNICITY
SM250444,25.8,-12.55,Luba-Katanga,ETHNICITY,ETHNICITY
SM250444,25.8,-12.55,Luba-Katanga,ETHNICITY,ETHNICITY
SM250444,25.8,-12.55,Luba-Katanga,ETHNICITY,ETHNICITY
SM250444,26,-8,Luba-Katanga,ETHNICITY,ETHNICITY
SM250444,26,-8,Luba-Katanga,ETHNICITY,ETHNICITY
SM250444,26,-8,Luba-Katanga,ETHNICITY,ETHNICITY
SM250444,25,-9,Luba-Katanga,ETHNICITY,ETHNICITY
SM250444,25,-9,Luba-Katanga,ETHNICITY,ETHNICITY
SM250444,25,-9,Luba-Katanga,ETHNICITY,ETHNICITY
SM236716,22.4422,-5.7272,Luba-Lulua,LANGUAGE,LANGUAGE
SM289008,23.1733,50.1959,Lubaczów,ADM2,DISTRICT
SM260337,19.1709,-9.1158,Lubalo,ADM2,DISTRICT
SM13667,26.7203,56.8985,Lubana,ADM2,DISTRICT
SM4035,26.7356,56.9275,Lubanas Novads,ADM1,DISTRICT
SM4461,13.6989,-14.7877,Lubango (2011-),ADM2,DISTRICT
SM288893,22.6059,51.535,Lubartów,ADM2,DISTRICT
SM288653,15.2774,51.0451,Lubań,ADM2,DISTRICT
SM302698,-101.8206,33.6102,Lubbock,ADM2,DISTRICT
SM2059,22.9,51.2333,Lubelskie,ADM1,DISTRICT
SM269655,29.2333,-0.1667,Lubero,ADM2,DISTRICT
SM13668,22.6417,57.45,Lubes pagasts,ADM2,DISTRICT
SM236723,8.292,5.4601,Lubila,LANGUAGE,LANGUAGE
SM288654,16.2432,51.4417,Lubiń,ADM2,DISTRICT
SM288895,22.4665,51.1342,Lublin,ADM2,DISTRICT
SM288894,22.5639,51.2182,Lublin (City),ADM2,DISTRICT
SM288724,18.7938,50.6737,Lubliniec,ADM2,DISTRICT
SM18242,32.9991,50.0038,Lubny Raion,ADM2,DISTRICT
SM2060,31.87,-26.5,Lubombo,ADM1,DISTRICT
SM236725,99.7455,0.6399,Lubu,LANGUAGE,LANGUAGE
SM236727,121.0365,17.3879,Lubuagan Kalinga,LANGUAGE,LANGUAGE
SM276668,102.855,-3.2931,Lubuklinggau,ADM2,DISTRICT
SM269738,27.5173,-11.7221,Lubumbashi,ADM2,DISTRICT
SM2061,15.3333,52.2,Lubuskie,ADM1,DISTRICT
SM260437,15.2438,-9.166,Lucala,ADM2,DISTRICT
SM286878,-74.3333,-14.5,Lucanas,ADM2,DISTRICT
SM260363,21.6719,-11.2363,Lucano,ADM2,DISTRICT
SM260338,20.2705,-8.6834,Lucapa,ADM2,DISTRICT
SM302126,-83.4683,41.6842,Lucas,ADM2,DISTRICT
SM300838,-93.3277,41.0294,Lucas,ADM2,DISTRICT
SM263178,-56.1558,-13.041,Lucas do Rio Verde,ADM2,DISTRICT
SM255315,-77.21,26.41,Lucayans,ETHNICITY,ETHNICITY
SM255315,-72.94,22.58,Lucayans,ETHNICITY,ETHNICITY
SM277912,10.45,44.0333,Lucca,ADM2,DISTRICT
SM301298,-85.5093,46.3678,Luce,ADM2,DISTRICT
SM296210,14.733,46.3512,Luce,ADM1:ADM2,DISTRICT
SM12496,-78.1667,18.422,Lucea East,ADM2,DISTRICT
SM12497,-78.1866,18.4424,Lucea West,ADM2,DISTRICT
SM264231,-34.9027,-6.9246,Lucena,ADM2,DISTRICT
SM296122,19.6667,48.3333,Lucenec,ADM2,DISTRICT
SM275739,-88.9634,14.606,Lucerna,ADM2,DISTRICT
SM2062,8.1667,47.1667,Lucerne,ADM1,DISTRICT
SM17920,8.2927,47.0405,Lucerne-Land District,ADM2,DISTRICT
SM17921,8.3106,47.0533,Lucerne-Stadt District,ADM2,DISTRICT
SM260364,19.4827,-13.6851,Luchazes,ADM2,DISTRICT
SM236730,22.2437,-14.0648,Luchazi,LANGUAGE,LANGUAGE
SM250445,24.25,-13.67,Luchazi,ETHNICITY,ETHNICITY
SM250445,24.25,-13.67,Luchazi,ETHNICITY,ETHNICITY
SM250445,21.03,-12.74,Luchazi,ETHNICITY,ETHNICITY
SM250445,21.03,-12.74,Luchazi,ETHNICITY,ETHNICITY
SM250445,23,-13,Luchazi,ETHNICITY,ETHNICITY
SM250445,23,-13,Luchazi,ETHNICITY,ETHNICITY
SM266668,-49.5451,-22.4839,Lucianópolis,ADM2,DISTRICT
SM289884,27.0578,44.9751,Luciu,ADM2,DISTRICT
SM263179,-50.943,-11.0142,Luciára,ADM2,DISTRICT
SM277311,81,26.75,Lucknow,ADM2,DISTRICT
SM12498,-77.0168,18.3025,Lucky Hill,ADM2,DISTRICT
SM265580,-37.8244,-6.1036,Lucrécia,ADM2,DISTRICT
SM236732,-82.441,22.9514,Lucumi,LANGUAGE,LANGUAGE
SM266667,-51.0004,-21.6364,Lucélia,ADM2,DISTRICT
SM276009,16.6333,46.25,Ludbreg,ADM2,DISTRICT
SM284458,15.7611,-26.7044,Luderitz,ADM2,DISTRICT
SM290279,25.2256,44.904,Ludesti,ADM2,DISTRICT
SM299136,34.75,-10,Ludewa,ADM2,DISTRICT
SM277144,75.82,30.84,Ludhiana (1992-),ADM2,DISTRICT
SM236734,33.7346,61.853,Ludian,LANGUAGE,LANGUAGE
SM291728,23.9007,45.9334,Ludos,ADM2,DISTRICT
SM291161,24.0823,46.5028,Ludus,ADM2,DISTRICT
SM296554,14.7264,60.1711,Ludvika,ADM2,DISTRICT
SM13669,27.7117,56.5448,Ludza,ADM2,DISTRICT
SM3982,27.75,56.5,Ludzas novads,ADM1,DISTRICT
SM296710,31.3141,-26.3971,Ludzeludze,ADM2,DISTRICT
SM11176,27.0764,59.3926,Lueganuse vald,ADM2,DISTRICT
SM15066,33.1186,-16.8676,Luenha,ADM2,DISTRICT
SM290695,25.5372,46.2872,Lueta,ADM2,DISTRICT
SM288636,145.25,-6.45,Lufa,ADM2,DISTRICT
SM255316,145.15,-6.79,Lufa,ETHNICITY,ETHNICITY
SM236737,10.9499,7.2556,Lufu,LANGUAGE,LANGUAGE
SM305167,27.4174,-12.952,Lufwanyama,ADM2,DISTRICT
SM250245,31.78,0.38,Luganda,ETHNICITY,ETHNICITY
SM250245,31.78,0.38,Luganda,ETHNICITY,ETHNICITY
SM250245,31.78,0.38,Luganda,ETHNICITY,ETHNICITY
SM250245,31.78,0.38,Luganda,ETHNICITY,ETHNICITY
SM250245,32.583,0.317,Luganda,ETHNICITY,ETHNICITY
SM250245,32.583,0.317,Luganda,ETHNICITY,ETHNICITY
SM250245,32.583,0.317,Luganda,ETHNICITY,ETHNICITY
SM250245,32.583,0.317,Luganda,ETHNICITY,ETHNICITY
SM250245,32,1,Luganda,ETHNICITY,ETHNICITY
SM250245,32,1,Luganda,ETHNICITY,ETHNICITY
SM250245,32,1,Luganda,ETHNICITY,ETHNICITY
SM250245,32,1,Luganda,ETHNICITY,ETHNICITY
SM250245,32.5,0.33,Luganda,ETHNICITY,ETHNICITY
SM250245,32.5,0.33,Luganda,ETHNICITY,ETHNICITY
SM250245,32.5,0.33,Luganda,ETHNICITY,ETHNICITY
SM250245,32.5,0.33,Luganda,ETHNICITY,ETHNICITY
SM268978,8.938,46.0232,Lugano,ADM2,DISTRICT
SM462129,35.015,0.688,Lugari District,ADM1:ADM2,DISTRICT
SM292438,22.3293,47.0699,Lugasu De Jos,ADM2,DISTRICT
SM255317,113.25,2.51,Lugats,ETHNICITY,ETHNICITY
SM236739,30.8642,3.0225,Lugbara,LANGUAGE,LANGUAGE
SM250446,30.78,2.75,Lugbara,ETHNICITY,ETHNICITY
SM250446,30.78,2.75,Lugbara,ETHNICITY,ETHNICITY
SM250446,30.78,2.75,Lugbara,ETHNICITY,ETHNICITY
SM250446,31,3,Lugbara,ETHNICITY,ETHNICITY
SM250446,31,3,Lugbara,ETHNICITY,ETHNICITY
SM250446,31,3,Lugbara,ETHNICITY,ETHNICITY
SM250446,32,3,Lugbara,ETHNICITY,ETHNICITY
SM250446,32,3,Lugbara,ETHNICITY,ETHNICITY
SM250446,32,3,Lugbara,ETHNICITY,ETHNICITY
SM283889,36.7256,-16.417,Lugela,ADM2,DISTRICT
SM295766,43.579,10.6477,Lughaya,ADM2,DISTRICT
SM295461,160.2324,-11.6606,Lughu,ADM2,DISTRICT
SM274046,-7.5,43,Lugo,ADM2,DISTRICT
SM291995,21.9599,45.6768,Lugoj,ADM2,DISTRICT
SM279903,72.5,43,Lugovskoy,ADM2,DISTRICT
SM255318,-43.46,61.48,Lugsiatsiak,ETHNICITY,ETHNICITY
SM236741,37.5557,-6.8683,Luguru,LANGUAGE,LANGUAGE
SM250447,37.6,-6.84,Luguru,ETHNICITY,ETHNICITY
SM250447,37.6,-6.84,Luguru,ETHNICITY,ETHNICITY
SM250447,37.6,-6.84,Luguru,ETHNICITY,ETHNICITY
SM250447,37.6,-6.84,Luguru,ETHNICITY,ETHNICITY
SM250447,37.6665,-6.917,Luguru,ETHNICITY,ETHNICITY
SM250447,37.6665,-6.917,Luguru,ETHNICITY,ETHNICITY
SM250447,37.6665,-6.917,Luguru,ETHNICITY,ETHNICITY
SM250447,37.6665,-6.917,Luguru,ETHNICITY,ETHNICITY
SM250447,38,-8,Luguru,ETHNICITY,ETHNICITY
SM250447,38,-8,Luguru,ETHNICITY,ETHNICITY
SM250447,38,-8,Luguru,ETHNICITY,ETHNICITY
SM250447,38,-8,Luguru,ETHNICITY,ETHNICITY
SM250447,37.67,-6.83,Luguru,ETHNICITY,ETHNICITY
SM250447,37.67,-6.83,Luguru,ETHNICITY,ETHNICITY
SM250447,37.67,-6.83,Luguru,ETHNICITY,ETHNICITY
SM250447,37.67,-6.83,Luguru,ETHNICITY,ETHNICITY
SM2063,39,49,Luhans'k,ADM1,DISTRICT
SM299557,39.3548,48.5808,Luhans'ka,ADM2,DISTRICT
SM299557,39.3548,48.5808,Luhans'ka,ADM2,DISTRICT
SM299557,39.3308,48.6209,Luhans'ka,ADM2,DISTRICT
SM299557,39.3308,48.6209,Luhans'ka,ADM2,DISTRICT
SM236744,127.966,-3.1319,Luhu-Piru,LANGUAGE,LANGUAGE
SM250906,34.6,0.4,Luhya,ETHNICITY,ETHNICITY
SM250906,34.6,0.4,Luhya,ETHNICITY,ETHNICITY
SM250906,34.6,0.4,Luhya,ETHNICITY,ETHNICITY
SM250906,36.8,1.3,Luhya,ETHNICITY,ETHNICITY
SM250906,36.8,1.3,Luhya,ETHNICITY,ETHNICITY
SM250906,36.8,1.3,Luhya,ETHNICITY,ETHNICITY
SM250906,34.5,0.5,Luhya,ETHNICITY,ETHNICITY
SM250906,34.5,0.5,Luhya,ETHNICITY,ETHNICITY
SM250906,34.5,0.5,Luhya,ETHNICITY,ETHNICITY
SM299846,28.3333,51.0833,Luhyns'kyi,ADM2,DISTRICT
SM236745,99.6619,21.3635,Lui,LANGUAGE,LANGUAGE
SM289957,26.6007,44.2413,Luica,ADM2,DISTRICT
SM255319,121.38,24.58,Luilang,ETHNICITY,ETHNICITY
SM236746,17.5385,-11.2221,Luimbi,LANGUAGE,LANGUAGE
SM250448,19.43,-10.76,Luimbi,ETHNICITY,ETHNICITY
SM250448,19.43,-10.76,Luimbi,ETHNICITY,ETHNICITY
SM250448,18,-12,Luimbi,ETHNICITY,ETHNICITY
SM250448,18,-12,Luimbi,ETHNICITY,ETHNICITY
SM262691,-63.5,-20.6667,Luis Calvo,ADM2,DISTRICT
SM9959,-46.1146,-12.2321,Luis Eduardo Magalhaes,ADM2:ADM3,DISTRICT
SM283055,-102.2507,22.4376,Luis Moya,ADM2,DISTRICT
SM263610,-42.0729,-20.4464,Luisburgo,ADM2,DISTRICT
SM255320,-117.08,33.31,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117.08,33.31,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117.08,33.31,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117.08,33.31,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117,33,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117,33,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117,33,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117,33,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117.3,33.42,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117.3,33.42,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117.3,33.42,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117.3,33.42,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117.3,33.3,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117.3,33.3,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117.3,33.3,Luiseno,ETHNICITY,ETHNICITY
SM255320,-117.3,33.3,Luiseno,ETHNICITY,ETHNICITY
SM236747,-116.939,33.3184,Luiseno-Juaneño,LANGUAGE,LANGUAGE
SM266670,-50.3531,-21.6824,Luisiania,ADM2,DISTRICT
SM263611,-44.6097,-16.1992,Luislândia,ADM2,DISTRICT
SM266197,-48.8853,-26.7363,Luiz Alves,ADM2,DISTRICT
SM292122,26.8401,46.536,Luizi-calugara,ADM2,DISTRICT
SM264549,-52.2704,-24.3408,Luiziânia,ADM2,DISTRICT
SM260794,-59.1667,-34.5833,Luján,ADM2,DISTRICT
SM260897,-69.3333,-33,Luján de Cuyo,ADM2,DISTRICT
SM305619,15.8198,45.9588,Luka,ADM2,DISTRICT
SM11123,15.5697,-5.1721,Lukaya,ADM2,DISTRICT
SM305532,17.4194,45.8741,Lukač,ADM2,DISTRICT
SM293687,39.0833,54.9167,Lukhovitskiy rayon,ADM2,DISTRICT
SM292930,42.4167,57,Lukhskiy rayon,ADM2,DISTRICT
SM296307,14.7686,46.1833,Lukovica,ADM1:ADM2,DISTRICT
SM293770,44.5,55,Lukoyanovskiy rayon,ADM2,DISTRICT
SM236751,1.6695,9.5195,Lukpa,LANGUAGE,LANGUAGE
SM255321,-66.4,58.58,Lukshuamiut,ETHNICITY,ETHNICITY
SM18142,23.8294,-14.23,Lukulu District (2012-),ADM2,DISTRICT
SM11124,15.3032,-4.3054,Lukunga District,ADM2,DISTRICT
SM236753,-61.8878,-25.2218,Lule,LANGUAGE,LANGUAGE
SM255322,-64.7,-23.69,Lule,ETHNICITY,ETHNICITY
SM236754,17.8057,67.2759,Lule Saami,LANGUAGE,LANGUAGE
SM261066,-65.3385,-26.9289,Lules,ADM2,DISTRICT
SM296390,22.1667,65.6833,Luleå,ADM2,DISTRICT
SM269316,111.3054,37.7356,Luliang,ADM2,DISTRICT
SM250092,20.84,-5.74,Lulua,ETHNICITY,ETHNICITY
SM250092,20.84,-5.74,Lulua,ETHNICITY,ETHNICITY
SM250092,23,-6,Lulua,ETHNICITY,ETHNICITY
SM250092,23,-6,Lulua,ETHNICITY,ETHNICITY
SM17994,-174.7219,-19.8109,Lulunga,ADM2,DISTRICT
SM276349,113.15,-8.1167,Lumajang,ADM2,DISTRICT
SM268389,91.5366,27.1283,Lumang,ADM2,DISTRICT
SM10049,114.9113,4.8253,Lumapas (excl. Bandar Seri Begawan),ADM2,DISTRICT
SM236762,87.3146,27.232,Lumba-Yakkha,LANGUAGE,LANGUAGE
SM260365,21.3611,-14.1948,Lumbala-Nguimbo,ADM2,DISTRICT
SM305650,17.1697,42.9218,Lumbarda,ADM2,DISTRICT
SM236759,-78.4531,35.6171,Lumbee,LANGUAGE,LANGUAGE
SM236761,11.124,-3.3792,Lumbu,LANGUAGE,LANGUAGE
SM451080,26.43,-15.96,Lumbu (Ila),ETHNICITY,ETHNICITY
SM17489,-60.8889,13.9393,Lumiere,ADM2,DISTRICT
SM263612,-44.9262,-21.5153,Luminárias,ADM2,DISTRICT
SM255323,-122.38,48.72,Lummi,ETHNICITY,ETHNICITY
SM255323,-122.38,48.72,Lummi,ETHNICITY,ETHNICITY
SM255323,-122.38,48.72,Lummi,ETHNICITY,ETHNICITY
SM255323,-122.38,48.72,Lummi,ETHNICITY,ETHNICITY
SM255323,-123.27,48.84,Lummi,ETHNICITY,ETHNICITY
SM255323,-123.27,48.84,Lummi,ETHNICITY,ETHNICITY
SM255323,-123.27,48.84,Lummi,ETHNICITY,ETHNICITY
SM255323,-123.27,48.84,Lummi,ETHNICITY,ETHNICITY
SM255323,-122.34,48.84,Lummi,ETHNICITY,ETHNICITY
SM255323,-122.34,48.84,Lummi,ETHNICITY,ETHNICITY
SM255323,-122.34,48.84,Lummi,ETHNICITY,ETHNICITY
SM255323,-122.34,48.84,Lummi,ETHNICITY,ETHNICITY
SM255323,-123.7,48.7,Lummi,ETHNICITY,ETHNICITY
SM255323,-123.7,48.7,Lummi,ETHNICITY,ETHNICITY
SM255323,-123.7,48.7,Lummi,ETHNICITY,ETHNICITY
SM255323,-123.7,48.7,Lummi,ETHNICITY,ETHNICITY
SM2064,20.25,60.1167,Lumparland,ADM1:ADM2,DISTRICT
SM280420,107.0847,13.4231,Lumphat,ADM2,DISTRICT
SM300532,-84.0027,34.5722,Lumpkin,ADM2,DISTRICT
SM236768,30.253,10.8308,Lumun,LANGUAGE,LANGUAGE
SM236771,23.5426,-5.0869,Luna,LANGUAGE,LANGUAGE
SM301842,-107.7498,32.1822,Luna,ADM2,DISTRICT
SM290108,23.9493,46.4752,Luna,ADM2,DISTRICT
SM236770,90.1257,28.1288,Lunanakha,LANGUAGE,LANGUAGE
SM264550,-51.76,-24.0721,Lunardelli,ADM2,DISTRICT
SM292577,26.9924,47.6275,Lunca,ADM2,DISTRICT
SM292439,22.4549,46.5165,Lunca,ADM2,DISTRICT
SM292292,28.1979,46.5664,Lunca Banului,ADM2,DISTRICT
SM291164,25.1121,46.9599,Lunca Bradului,ADM2,DISTRICT
SM290767,22.5844,45.643,Lunca Cernii De Jos,ADM2,DISTRICT
SM291255,24.7606,44.6836,Lunca Corbului,ADM2,DISTRICT
SM290696,25.9658,46.5987,Lunca De Jos,ADM2,DISTRICT
SM290697,25.9557,46.5185,Lunca De Sus,ADM2,DISTRICT
SM292503,24.9711,47.3641,Lunca Ilvei,ADM2,DISTRICT
SM289886,26.2447,45.5087,Lunca Jaristei,ADM2,DISTRICT
SM289806,23.9327,46.4294,Lunca Muresului,ADM2,DISTRICT
SM290026,22.2535,45.0852,Luncavita,ADM2,DISTRICT
SM290768,22.7911,46.0681,Luncoiu De Jos,ADM2,DISTRICT
SM296437,13.3641,55.669,Lund,ADM2,DISTRICT
SM286132,6.5487,58.4572,Lund,ADM2,DISTRICT
SM236777,23.8662,-11.1793,Lunda,LANGUAGE,LANGUAGE
SM250450,25.4,-11.48,Lunda (both NW & Luapala),ETHNICITY,ETHNICITY
SM250450,23.7,-13.51,Lunda (both NW & Luapala),ETHNICITY,ETHNICITY
SM2065,19.6485,-8.6354,Lunda Norte,ADM1,DISTRICT
SM2066,20.6249,-10.2592,Lunda Sul,ADM1,DISTRICT
SM250550,24.41,-11.48,Lunda-Ndembu,ETHNICITY,ETHNICITY
SM250550,24.41,-11.48,Lunda-Ndembu,ETHNICITY,ETHNICITY
SM250550,26,-11,Lunda-Ndembu,ETHNICITY,ETHNICITY
SM250550,26,-11,Lunda-Ndembu,ETHNICITY,ETHNICITY
SM236781,115.5,4.2506,Lundayeh,LANGUAGE,LANGUAGE
SM305174,32.7207,-12.5011,Lundazi,ADM2,DISTRICT
SM303041,-78.2406,36.9462,Lunenburg,ADM2,DISTRICT
SM268825,-64.5988,44.4335,Lunenburg,ADM2,DISTRICT
SM18143,30.0924,-11.538,Lunga District,ADM2,DISTRICT
SM290840,27.1575,47.1756,Lungani,ADM2,DISTRICT
SM292202,24.1789,44.6027,Lungesti,ADM2,DISTRICT
SM236788,156.573,-8.1747,Lungga,LANGUAGE,LANGUAGE
SM255324,127.82,-17.23,Lungga,ETHNICITY,ETHNICITY
SM255324,127.82,-17.23,Lungga,ETHNICITY,ETHNICITY
SM255324,127.82,-17.23,Lungga,ETHNICITY,ETHNICITY
SM255324,127.83,-16.91,Lungga,ETHNICITY,ETHNICITY
SM255324,127.83,-16.91,Lungga,ETHNICITY,ETHNICITY
SM255324,127.83,-16.91,Lungga,ETHNICITY,ETHNICITY
SM255324,127.3911,-17.424,Lungga,ETHNICITY,ETHNICITY
SM255324,127.3911,-17.424,Lungga,ETHNICITY,ETHNICITY
SM255324,127.3911,-17.424,Lungga,ETHNICITY,ETHNICITY
SM277091,92.75,22.9,Lunglei,ADM2,DISTRICT
SM250650,29.09,-6.89,Lungu,ETHNICITY,ETHNICITY
SM250650,30.34,-8.98,Lungu,ETHNICITY,ETHNICITY
SM250442,12,10,Lunguda,ETHNICITY,ETHNICITY
SM290280,25.6434,44.616,Lunguletu,ADM2,DISTRICT
SM262569,26.8085,52.334,Luninets,ADM2,DISTRICT
SM286102,10.6086,60.2946,Lunner,ADM2,DISTRICT
SM236798,9.9126,5.9072,Luo (Cameroon),LANGUAGE,LANGUAGE
SM236800,34.4896,-0.933,Luo (Kenya and Tanzania),LANGUAGE,LANGUAGE
SM250451,35.08,-0.23,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,35.08,-0.23,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,35.08,-0.23,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,35.08,-0.23,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,30.69,5.54,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,30.69,5.54,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,30.69,5.54,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,30.69,5.54,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,34,-1,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,34,-1,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,34,-1,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,34,-1,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,34.3,-0.1,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,34.3,-0.1,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,34.3,-0.1,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,34.3,-0.1,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,34.5,-0.5,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,34.5,-0.5,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,34.5,-0.5,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM250451,34.5,-0.5,Luo (Tanzania and Kenya),ETHNICITY,ETHNICITY
SM269174,113.9131,33.6881,Luohe,ADM2,DISTRICT
SM236802,107.158,26.8183,Luopohe Hmong,LANGUAGE,LANGUAGE
SM269175,112.0625,34.3581,Luoyang,ADM2,DISTRICT
SM290027,21.8084,45.2522,Lupac,ADM2,DISTRICT
SM305269,27.991,-18.8361,Lupane,ADM2,DISTRICT
SM290698,25.2191,46.3957,Lupeni,ADM2,DISTRICT
SM290770,23.2216,45.3565,Lupeni,ADM2,DISTRICT
SM266671,-49.8123,-22.4277,Lupercio,ADM2,DISTRICT
SM271821,-70.9744,19.8567,Luperon,ADM2,DISTRICT
SM264551,-51.6839,-22.7385,Lupionópolis,ADM2,DISTRICT
SM305664,14.1085,45.352,Lupoglav,ADM2,DISTRICT
SM289807,23.2046,46.3489,Lupsa,ADM2,DISTRICT
SM289958,26.9484,44.3808,Lupsanu,ADM2,DISTRICT
SM289716,-57.4571,-25.2505,Luque,ADM2,DISTRICT
SM260356,17.4012,-10.7466,Luquembo,ADM2,DISTRICT
SM2067,-65.7246,18.3397,Luquillo,ADM1,DISTRICT
SM15868,-65.7159,18.3752,Luquillo Barrio-Pueblo,ADM2,DISTRICT
SM255327,136.42,-28.57,Lurabunna,ETHNICITY,ETHNICITY
SM255327,136.42,-28.57,Lurabunna,ETHNICITY,ETHNICITY
SM255327,136.0837,-28.4169,Lurabunna,ETHNICITY,ETHNICITY
SM255327,136.0837,-28.4169,Lurabunna,ETHNICITY,ETHNICITY
SM236806,105.664,27.285,Luren,LANGUAGE,LANGUAGE
SM236808,9.5975,10.059,Luri,LANGUAGE,LANGUAGE
SM297853,126.83,-8.5436,Luro,ADM2,DISTRICT
SM255328,49.63,31.18,Lurs,ETHNICITY,ETHNICITY
SM255328,49.63,31.18,Lurs,ETHNICITY,ETHNICITY
SM255328,47.43,35.3,Lurs,ETHNICITY,ETHNICITY
SM255328,47.43,35.3,Lurs,ETHNICITY,ETHNICITY
SM255328,48.339,33.465,Lurs,ETHNICITY,ETHNICITY
SM255328,48.339,33.465,Lurs,ETHNICITY,ETHNICITY
SM270678,-75.1458,10.6392,Luruaco,ADM2,DISTRICT
SM286070,12.8425,66.4189,Lurøy,ADM2,DISTRICT
SM305188,28.3442,-15.4307,Lusaka,ADM2,DISTRICT
SM461266,29,-15.4167,Lusaka (2012-2018),ADM1,DISTRICT
SM236813,18.3991,0.1699,Lusengo,LANGUAGE,LANGUAGE
SM236811,11.4286,46.8777,Lusernese Cimbrian,DIALECT,LANGUAGE
SM255329,26.03,-14.62,Lushange,ETHNICITY,ETHNICITY
SM255330,95.36,19.7,Lushei,ETHNICITY,ETHNICITY
SM255330,95.36,19.7,Lushei,ETHNICITY,ETHNICITY
SM255330,92.5,23,Lushei,ETHNICITY,ETHNICITY
SM255330,92.5,23,Lushei,ETHNICITY,ETHNICITY
SM236820,9.0706,10.0596,Lushi,DIALECT,LANGUAGE
SM260488,19.65,40.9167,Lushnjës,ADM2,DISTRICT
SM299199,38.4393,-4.5453,Lushoto,ADM2,DISTRICT
SM236823,149.148,-5.5503,Lusi,LANGUAGE,LANGUAGE
SM236822,-8.1053,39.944,Lusitanian,LANGUAGE,LANGUAGE
SM286164,7.2979,61.4031,Luster,ADM2,DISTRICT
SM459196,142.1165,-11.9285,Luthigh,ETHNICITY,ETHNICITY
SM274651,-0.4279,51.8911,Luton,ADM1:ADM2,DISTRICT
SM236830,19.0071,8.5207,Lutos,LANGUAGE,LANGUAGE
SM13670,22.3348,56.7382,Lutrinu pagasts,ADM2,DISTRICT
SM299798,25.342,50.7488,Luts'ka,ADM2,DISTRICT
SM266672,-50.3782,-22.3065,Lutécia,ADM2,DISTRICT
SM295831,42.6976,3.7929,Luuk,ADM2,DISTRICT
SM299260,33.3325,0.8241,Luuka,ADM1:ADM2,DISTRICT
SM274166,26.9557,58.3918,Luunja,ADM2,DISTRICT
SM283812,105.7558,45.5018,Luus,ADM2,DISTRICT
SM295479,165.7897,-10.7301,Luva Station,ADM2,DISTRICT
SM236836,22.2924,-13.5382,Luvale,LANGUAGE,LANGUAGE
SM250454,23.19,-10.94,Luvale,ETHNICITY,ETHNICITY
SM250454,23.19,-10.94,Luvale,ETHNICITY,ETHNICITY
SM250454,20.68,-11.97,Luvale,ETHNICITY,ETHNICITY
SM250454,20.68,-11.97,Luvale,ETHNICITY,ETHNICITY
SM250454,21.66,-13.42,Luvale,ETHNICITY,ETHNICITY
SM250454,21.66,-13.42,Luvale,ETHNICITY,ETHNICITY
SM250454,22,-12,Luvale,ETHNICITY,ETHNICITY
SM250454,22,-12,Luvale,ETHNICITY,ETHNICITY
SM14324,-100.4245,18.9455,Luvianos,ADM2,DISTRICT
SM236840,58.4916,23.6322,Luwati,LANGUAGE,LANGUAGE
SM4795,32.614,0.82,Luwero (2005-),ADM1:ADM2,DISTRICT
SM305204,29.9182,-10.4883,Luwingu,ADM2,DISTRICT
SM236842,28.1378,7.3509,Luwo,LANGUAGE,LANGUAGE
SM250455,30.43,5.87,Luwo (Sudan),ETHNICITY,ETHNICITY
SM250455,30.43,5.87,Luwo (Sudan),ETHNICITY,ETHNICITY
SM250455,28,8,Luwo (Sudan),ETHNICITY,ETHNICITY
SM250455,28,8,Luwo (Sudan),ETHNICITY,ETHNICITY
SM276572,120.2213,-3.3484,Luwu,ADM2,DISTRICT
SM276593,121.1231,-2.5321,Luwu Timur,ADM2,DISTRICT
SM276571,120.25,-2.6,Luwu Utara,ADM2,DISTRICT
SM370523,6.132,49.6093,Luxembourg,ADM1:ADM2:ADM3,DISTRICT
SM262101,5.5,50,Luxembourg,ADM1:ADM2,DISTRICT
SM281375,6.1729,49.6143,Luxembourg,ADM1:ADM2,DISTRICT
SM236847,98.6,24.45,Luxi Achang,LANGUAGE,LANGUAGE
SM273727,32.5902,25.6299,Luxor,ADM2,DISTRICT
SM286725,-78,-6.4167,Luya,ADM2,DISTRICT
SM236849,22.67,-15.01,Luyi,LANGUAGE,LANGUAGE
SM263614,-45.6754,-19.8419,Luz,ADM2,DISTRICT
SM266198,-51.4935,-27.0766,Luzerna,ADM2,DISTRICT
SM302319,-75.989,41.177,Luzerne,ADM2,DISTRICT
SM269321,105.519,28.5674,Luzhou,ADM2,DISTRICT
SM16993,29.75,58.75,Luzhskiy Rayon,ADM2,DISTRICT
SM265048,-42.3646,-3.6074,Luzilândia,ADM2,DISTRICT
SM267256,-47.8305,-6.2064,Luzinópolis,ADM2,DISTRICT
SM268079,-47.9355,-16.4951,Luziânia,ADM2,DISTRICT
SM13671,27.2786,56.3599,Luznavas pagasts,ADM2,DISTRICT
SM293268,47.25,60.5833,Luzskiy rayon,ADM2,DISTRICT
SM266669,-47.8116,-21.56,Luís Antônio,ADM2,DISTRICT
SM265047,-41.5167,-3.0312,Luís Correia,ADM2,DISTRICT
SM262813,-45.8379,-1.2696,Luís Domingues,ADM2,DISTRICT
SM265581,-38.419,-6.3863,Luís Gomes,ADM2,DISTRICT
SM280516,105.1248,11.522,Lvea Aem,ADM2,DISTRICT
SM18243,24.0147,49.8448,Lvivska Miskrada,ADM2,DISTRICT
SM18244,23.926,49.873,Lvivskyi Raion (excl. Lviv),ADM2,DISTRICT
SM236852,22.1097,-7.1639,Lwalu,LANGUAGE,LANGUAGE
SM236853,35.2764,-9.3404,Lwanzali,DIALECT,LANGUAGE
SM236854,20.6314,-4.3848,Lwel,LANGUAGE,LANGUAGE
SM4740,31.4514,-0.426,Lwengo,ADM1:ADM2,DISTRICT
SM288655,15.5214,51.0156,Lwówek Śląski,ADM2,DISTRICT
SM262570,26.1667,52.9167,Lyakhavichy,ADM2,DISTRICT
SM293641,45.25,54.3333,Lyambirskiy rayon,ADM2,DISTRICT
SM255331,31.78,-8.31,Lyangalile,ETHNICITY,ETHNICITY
SM262469,25.85,43.1333,Lyaskovets,ADM2,DISTRICT
SM236856,29.764,36.517,Lycian A,LANGUAGE,LANGUAGE
SM296528,18.4893,64.6244,Lycksele,ADM2,DISTRICT
SM302320,-77.0645,41.3434,Lycoming,ADM2,DISTRICT
SM236859,28.599,40.287,Lydian,LANGUAGE,LANGUAGE
SM262586,28.25,51.8333,Lyel'chytsy,ADM2,DISTRICT
SM9796,27.5878,53.8594,Lyeninski Rayon,ADM2,DISTRICT
SM9797,28.6667,54.8333,Lyepyel'ski Rayon (excl. Lyepyel'),ADM2,DISTRICT
SM302514,-99.8474,43.8958,Lyman,ADM2,DISTRICT
SM10873,33.4618,34.9853,Lympia,ADM2,DISTRICT
SM303042,-79.1423,37.4138,Lynchburg,ADM2,DISTRICT
SM271677,12.5051,55.7846,Lyngby-Taarbæk,ADM2,DISTRICT
SM461869,7.0684,58.1383,Lyngdal (2020-),ADM2,DISTRICT
SM286229,20.217,69.5762,Lyngen,ADM2,DISTRICT
SM302699,-101.8161,33.1768,Lynn,ADM2,DISTRICT
SM301773,-119.1892,39.0204,Lyon,ADM2,DISTRICT
SM301375,-95.839,44.4135,Lyon,ADM2,DISTRICT
SM301078,-88.0833,37.0191,Lyon,ADM2,DISTRICT
SM300840,-96.2103,43.3805,Lyon,ADM2,DISTRICT
SM300940,-96.1526,38.4562,Lyon,ADM2,DISTRICT
SM236864,2.3681,48.5642,Lyons Sign Language,LANGUAGE,LANGUAGE
SM262666,30.6667,55,Lyozna,ADM2,DISTRICT
SM299771,29.0611,49.2267,Lypovets'kyi,ADM2,DISTRICT
SM296583,11.4669,58.3472,Lysekil,ADM2,DISTRICT
SM10874,33.6755,35.0884,Lysi,ADM2,DISTRICT
SM299426,30.8264,49.2512,Lysians'kyi,ADM2,DISTRICT
SM293771,45.1667,56.1667,Lyskovskiy rayon,ADM2,DISTRICT
SM10875,32.5821,35.016,Lysos,ADM2,DISTRICT
SM12499,-76.3796,17.8871,Lyssons,ADM2,DISTRICT
SM299559,38.39,48.9275,Lysychans'ka,ADM2,DISTRICT
SM10876,34.1724,35.4679,Lythragkomi,ADM2,DISTRICT
SM10877,33.2862,34.9434,Lythrodontas,ADM2,DISTRICT
SM262664,28,52.75,Lyuban',ADM2,DISTRICT
SM16994,37.9167,55.6667,Lyuberetskiy Rayon,ADM2,DISTRICT
SM262521,26.05,41.8167,Lyubimets,ADM2,DISTRICT
SM295067,40.75,58.3333,Lyubimskiy rayon,ADM2,DISTRICT
SM293895,72.5,55.25,Lyubinskiy rayon,ADM2,DISTRICT
SM293841,33.5,58.8333,Lyubytinskiy rayon,ADM2,DISTRICT
SM16995,34.5,53.9167,Lyudinovskiy Rayon,ADM2,DISTRICT
SM236860,-2.5923,12.4305,Lyélé,LANGUAGE,LANGUAGE
SM304280,103.995,22.4194,Lào Cai,ADM2,DISTRICT
SM1968,104.1667,22.3,Lào Cai (2003-),ADM1,DISTRICT
SM267331,-66.075,-8.1844,Lábrea,ADM2,DISTRICT
SM282471,-87.4428,20.999,Lázaro Cárdenas,ADM2,DISTRICT
SM282046,-102.3822,18.0713,Lázaro Cárdenas,ADM2,DISTRICT
SM235727,-3.1774,11.6524,Láá Láá Bwamu,LANGUAGE,LANGUAGE
SM304564,105.219,22.4641,Lâm Bình,ADM2,DISTRICT
SM304267,108.2122,11.8189,Lâm Hà,ADM2,DISTRICT
SM304361,105.3112,21.2997,Lâm Thao,ADM2,DISTRICT
SM1959,108.0833,11.6667,Lâm Đồng,ADM1,DISTRICT
SM1939,23.55,58.9333,Lääne,ADM1:ADMX,DISTRICT
SM1940,26.3333,59.25,Lääne-Viru,ADM1:ADMX,DISTRICT
SM286162,7.481,61.0984,Lærdal,ADM2,DISTRICT
SM271715,11.0227,57.2677,Læsø,ADM2,DISTRICT
SM274550,13.181,-1.481,Léboumbi-Leyou,ADM2,DISTRICT
SM269841,14.4684,-2.3293,Lékana,ADM2,DISTRICT
SM1987,13.5333,-3.1,Lékoumou,ADM1,DISTRICT
SM274995,-12.6667,11.4167,Lélouma,ADM1:ADM2,DISTRICT
SM276107,-72.7548,18.3771,Léogâne,ADM2,DISTRICT
SM262201,-5.2,10.6667,Léraba,ADM2,DISTRICT
SM270568,-74.9178,4.8729,Lérida,ADM2,DISTRICT
SM260361,20.5784,-11.7448,Léua,ADM2,DISTRICT
SM304076,106.6736,20.8465,Lê Chân,ADM2,DISTRICT
SM270569,-75.1083,4.8356,Líbano,ADM2,DISTRICT
SM283243,-105.044,26.9831,López,ADM2,DISTRICT
SM269858,-77.25,3,López de Micay,ADM2,DISTRICT
SM269483,-5.4767,5.8248,Lôh-Djiboua,ADM2,DISTRICT
SM286069,15.9901,68.41,Lødingen,ADM2,DISTRICT
SM286037,10.9608,59.9321,Lørenskog,ADM2,DISTRICT
SM286357,11.3437,60.8177,Løten,ADM2,DISTRICT
SM236834,101.282,21.0321,Lü,LANGUAGE,LANGUAGE
SM298647,27.3733,41.415,Lüleburgaz,ADM2,DISTRICT
SM283708,105.2501,47.8627,Lün,ADM2,DISTRICT
SM304165,106.0946,20.5642,Lý Nhân,ADM2,DISTRICT
SM304410,109.0971,15.4061,Lý Sơn,ADM2,DISTRICT
SM288697,17.6518,54.5683,Lębork,ADM2,DISTRICT
SM304200,105.5122,20.8765,Lương Sơn,ADM2,DISTRICT
SM304432,106.2262,21.0241,Lương Tài,ADM2,DISTRICT
SM261935,48.7555,38.7007,Lənkəran,ADM1:ADM2,DISTRICT
SM261936,48.8333,38.7333,Lənkəran City,ADM1:ADM2,DISTRICT
SM304266,108.5311,12.1237,Lạc Dương,ADM2,DISTRICT
SM304198,105.5017,20.5019,Lạc Sơn,ADM2,DISTRICT
SM304199,105.7409,20.4979,Lạc Thủy,ADM2,DISTRICT
SM304184,106.2587,21.3774,Lạng Giang,ADM2,DISTRICT
SM304253,106.7431,21.849,Lạng Sơn,ADM2,DISTRICT
SM1920,106.5833,21.8333,Lạng Sơn,ADM1,DISTRICT
SM304017,105.6061,10.3592,Lấp Vò,ADM2,DISTRICT
SM304578,105.485,21.4188,Lập Thạch,ADM2,DISTRICT
SM303979,108.2104,12.3199,Lắk,ADM2,DISTRICT
SM304378,106.7047,17.1124,Lệ Thủy,ADM2,DISTRICT
SM304254,106.9439,21.7076,Lộc Bình,ADM2,DISTRICT
SM304169,105.887,18.3626,Lộc Hà,ADM2,DISTRICT
SM303933,106.5785,11.8585,Lộc Ninh,ADM2,DISTRICT
SM304185,106.4652,21.2755,Lục Nam,ADM2,DISTRICT
SM304186,106.6585,21.4372,Lục Ngạn,ADM2,DISTRICT
SM304586,104.7254,22.1024,Lục Yên,ADM2,DISTRICT
SM284002,-13.7747,16.2575,M'Bagne,ADM2,DISTRICT
SM260389,14.178,-6.3145,M'Banza Congo,ADM2,DISTRICT
SM268594,17.9335,3.9715,M'Baïki,ADM2,DISTRICT
SM284006,-12.5262,15.9953,M'Bout,ADM2,DISTRICT
SM298053,10.1174,36.6471,M'Hamdia,ADM2,DISTRICT
SM298001,10.5232,35.695,M'Saken,ADM2,DISTRICT
SM2071,4.3333,35.3333,M'Sila,ADM1,DISTRICT
SM457567,4.55,35.7,M'Sila District,ADM2,DISTRICT
SM4689,-5.3586,35.8574,M'diq-Fnideq (2010-),ADM2,DISTRICT
SM2072,45.0845,-12.7598,M'tsangamouji,ADM1,DISTRICT
SM303980,108.8226,12.6983,M'Đrắk,ADM2,DISTRICT
SM458857,130.0737,-13.6528,MARRANJ,ETHNICITY,ETHNICITY
SM305169,28.1667,-13.5083,MPongwe,ADM2,DISTRICT
SM458156,145.7981,-29.0682,MURUWARI,ETHNICITY,ETHNICITY
SM255332,142.04,-3.85,Ma,ETHNICITY,ETHNICITY
SM236942,26.9939,3.6935,Ma (Democratic Republic of Congo),LANGUAGE,LANGUAGE
SM237515,146.364,-5.7387,Ma (Papua New Guinea),LANGUAGE,LANGUAGE
SM243985,146.759,-6.3196,Ma Manda,LANGUAGE,LANGUAGE
SM261200,-170.7033,-14.2578,Ma'Oputasi,ADM2,DISTRICT
SM305094,44.1659,15.3706,Ma'ain,ADM2,DISTRICT
SM269123,118.6201,31.5232,Ma'anshan,ADM2,DISTRICT
SM236871,115.092,-2.1239,Ma'anyan,LANGUAGE,LANGUAGE
SM255335,115.85,-2.43,Ma'anyan,ETHNICITY,ETHNICITY
SM255335,115.85,-2.43,Ma'anyan,ETHNICITY,ETHNICITY
SM255335,115,-1,Ma'anyan,ETHNICITY,ETHNICITY
SM255335,115,-1,Ma'anyan,ETHNICITY,ETHNICITY
SM296737,36.7919,35.5386,Ma'arrat al-Numan,ADM2,DISTRICT
SM236950,31.8471,3.625,Ma'di,LANGUAGE,LANGUAGE
SM2073,45.3253,15.4591,Ma'rib,ADM1,DISTRICT
SM4235,-157.3368,-20.1673,Ma'uke,ADM1,DISTRICT
SM324009,-113.9605,52.9755,Ma-Me-O Beach,ADM2:ADM3,DISTRICT
SM2074,36.5833,30.3333,Maan,ADM1,DISTRICT
SM236868,107.339,11.5205,Maa,LANGUAGE,LANGUAGE
SM236870,11.9035,11.5007,Maaka,LANGUAGE,LANGUAGE
SM4262,73.5083,4.175,Maale,ADM1:ADM2,DISTRICT
SM236872,105.7009,23.5551,Maang,LANGUAGE,LANGUAGE
SM14943,138.1712,9.5898,Maap Municipality,ADM2,DISTRICT
SM274087,24.9735,59.4639,Maardu,ADM2,DISTRICT
SM454548,37.1,1.5,Maasai Ilchamus,ETHNICITY,ETHNICITY
SM454550,36.8,0.3,Maasai Ilgwesi,ETHNICITY,ETHNICITY
SM454549,37,0.6,Maasai Mumonyot,ETHNICITY,ETHNICITY
SM285790,5.2971,51.7734,Maasdriel,ADM2,DISTRICT
SM236874,-3.6476,11.1324,Maasina Fulfulde,LANGUAGE,LANGUAGE
SM285669,4.2472,51.9233,Maassluis,ADM2,DISTRICT
SM285869,5.7,50.85,Maastricht,ADM2,DISTRICT
SM236875,43.6182,3.2229,Maay,LANGUAGE,LANGUAGE
SM250226,32.25,28.66,Maaza,ETHNICITY,ETHNICITY
SM236883,20.8889,12.8915,Maba (Chad),LANGUAGE,LANGUAGE
SM236884,128.418,0.5901,Maba (Indonesia),LANGUAGE,LANGUAGE
SM236876,20.8759,2.1194,Mabaale,LANGUAGE,LANGUAGE
SM236879,33.5404,9.8146,Mabaan,LANGUAGE,LANGUAGE
SM250458,30.84,10.88,Mabaan,ETHNICITY,ETHNICITY
SM250458,30.84,10.88,Mabaan,ETHNICITY,ETHNICITY
SM250458,34,10,Mabaan,ETHNICITY,ETHNICITY
SM250458,34,10,Mabaan,ETHNICITY,ETHNICITY
SM236885,121.073,17.6683,Mabaka Valley Kalinga,LANGUAGE,LANGUAGE
SM283900,32.7271,-23.4959,Mabalane,ADM2,DISTRICT
SM275113,5.6355,-1.457,Mabana,ADM2,DISTRICT
SM261974,29.7682,-4.3035,Mabanda,ADM2,DISTRICT
SM262061,29.2479,-2.7208,Mabayi,ADM2,DISTRICT
SM236886,-68.2777,-11.5438,Mabenaro,LANGUAGE,LANGUAGE
SM7515,10.3,2.9,Mabi,ETHNICITY,ETHNICITY
SM255337,23.49,2.96,Mabinza,ETHNICITY,ETHNICITY
SM236888,18.6282,11.698,Mabire,LANGUAGE,LANGUAGE
SM16323,13.6738,-4.0285,Mabombo,ADM2,DISTRICT
SM283912,33.8179,-22.0227,Mabote,ADM2,DISTRICT
SM15869,-65.8373,18.1661,Mabu Barrio,ADM2,DISTRICT
SM304848,43.551,15.7717,Mabyan,ADM2,DISTRICT
SM261593,133.4998,-24.4267,MacDonnell,ADM2,DISTRICT
SM236893,-57.3694,-25.0119,Maca,LANGUAGE,LANGUAGE
SM255338,-60.23,-23.95,Maca,ETHNICITY,ETHNICITY
SM236894,-75.8333,0.5,Macaguaje,LANGUAGE,LANGUAGE
SM236892,-71.2671,6.3458,Macaguán,LANGUAGE,LANGUAGE
SM267516,-40.302,-12.1437,Macajuba,ADM2,DISTRICT
SM15870,-66.7776,18.0506,Macana Barrio (Guayanilla),ADM2,DISTRICT
SM15871,-66.7618,18.0778,Macana Barrio (Peñuelas),ADM2,DISTRICT
SM270785,-73.3203,4.9505,Macanal,ADM2,DISTRICT
SM236895,114.12,22.4181,Macanese,LANGUAGE,LANGUAGE
SM236895,114.12,22.4181,Macanese,LANGUAGE,LANGUAGE
SM236895,113.5379,22.1902,Macanese,LANGUAGE,LANGUAGE
SM236895,113.5379,22.1902,Macanese,LANGUAGE,LANGUAGE
SM283872,33.5349,-14.8021,Macanga,ADM2,DISTRICT
SM236897,113.5501,22.1966,Macao Pidgin Portuguese,LANGUAGE,LANGUAGE
SM267301,-50.9627,0.5877,Macapa,ADM2,DISTRICT
SM264848,-35.4443,-7.5287,Macaparana,ADM2,DISTRICT
SM286717,-80.5353,7.6582,Macaracas,ADM2,DISTRICT
SM267517,-40.3905,-15.557,Macarani,ADM2,DISTRICT
SM270486,-72.6107,6.4973,Macaravita,ADM2,DISTRICT
SM273476,-79.95,-4.3833,Macará,ADM2,DISTRICT
SM266673,-48.7109,-22.4929,Macatuba,ADM2,DISTRICT
SM265583,-36.5829,-5.1492,Macau,ADM2,DISTRICT
SM266674,-49.9853,-20.8627,Macaubal,ADM2,DISTRICT
SM265276,-42.0122,-22.308,Macaé,ADM2,DISTRICT
SM265582,-35.3983,-5.9409,Macaíba,ADM2,DISTRICT
SM267518,-42.6833,-13.1917,Macaúbas,ADM2,DISTRICT
SM2076,-14.9167,13.5833,Maccarthy Island,ADM1,DISTRICT
SM17490,-60.9851,13.775,Macdomel,ADM2,DISTRICT
SM327635,-97.5007,49.6667,Macdonald,ADM2:ADM3,DISTRICT
SM290514,21.3319,46.4009,Macea,ADM2,DISTRICT
SM289454,-6.9336,41.5513,Macedo de Cavaleiros,ADM2,DISTRICT
SM261248,144.6158,-37.3334,Macedon Ranges,ADM2,DISTRICT
SM266675,-50.1732,-20.0883,Macedonia,ADM2,DISTRICT
SM236898,21.7932,41.5957,Macedonian,LANGUAGE,LANGUAGE
SM265379,-35.6899,-9.5714,Maceió (capital),ADM2,DISTRICT
SM275004,-9.4167,8.5,Macenta,ADM1:ADM2,DISTRICT
SM270234,-74.7328,6.5446,Maceo,ADM2,DISTRICT
SM277878,13.1667,43.2,Macerata,ADM2,DISTRICT
SM290378,23.6895,43.8797,Macesu De Jos,ADM2,DISTRICT
SM290379,23.7113,43.9167,Macesu De Sus,ADM2,DISTRICT
SM250508,37,9,Macha,ETHNICITY,ETHNICITY
SM263615,-40.7183,-17.0881,Machacalis,ADM2,DISTRICT
SM266089,-62.1124,-9.2395,Machadinho,ADM2,DISTRICT
SM265746,-51.6876,-27.5918,Machadinho,ADM2,DISTRICT
SM263616,-45.8881,-21.6955,Machado,ADM2,DISTRICT
SM264849,-35.5188,-7.7148,Machados,ADM2,DISTRICT
SM2078,37.408,-1.282,Machakos (1990-),ADM1:ADM2,DISTRICT
SM273627,-79.95,-3.2667,Machala,ADM2,DISTRICT
SM236904,37.0703,-3.2939,Machame,LANGUAGE,LANGUAGE
SM268524,27.1433,-23.1362,Machaneng,ADM2,DISTRICT
SM283976,34.9974,-20.9693,Machanga,ADM2,DISTRICT
SM283918,33.4139,-20.8021,Machaze,ADM2,DISTRICT
SM15872,-66.1215,17.9554,Machete Barrio,ADM2,DISTRICT
SM270021,-73.6167,5.0833,Machetá,ADM2,DISTRICT
SM289279,-16.7077,32.7447,Machico,ADM2,DISTRICT
SM279450,139.43,35.5763,Machida,ADM2,DISTRICT
SM236905,-72.5017,-12.1291,Machiguenga,LANGUAGE,LANGUAGE
SM255343,-73.18,-12.08,Machiguenga,ETHNICITY,ETHNICITY
SM285260,9.9853,13.0452,Machina,ADM2,DISTRICT
SM275135,9.9539,1.8807,Machinda,ADM2,DISTRICT
SM236906,-69.7683,-10.4804,Machinere,LANGUAGE,LANGUAGE
SM236906,-69.7683,-10.4804,Machinere,LANGUAGE,LANGUAGE
SM236906,-70.2081,-10.6617,Machinere,LANGUAGE,LANGUAGE
SM236906,-70.2081,-10.6617,Machinere,LANGUAGE,LANGUAGE
SM236903,39.4268,-9.371,Machinga,LANGUAGE,LANGUAGE
SM2079,35.5737,-14.9458,Machinga,ADM1:ADM2,DISTRICT
SM303798,-72.545,10.0644,Machiques de Perijá,ADM2,DISTRICT
SM15873,-65.6187,18.2547,Machos Barrio,ADM2,DISTRICT
SM15874,-66.9409,18.0565,Machuchal Barrio,ADM2,DISTRICT
SM15875,-66.5962,18.0254,Machuelo Abajo Barrio,ADM2,DISTRICT
SM15876,-66.5988,18.062,Machuelo Arriba Barrio,ADM2,DISTRICT
SM266199,-51.3434,-26.8107,Macieira,ADM2,DISTRICT
SM289705,-56.5133,-26.2206,Maciel,ADM2,DISTRICT
SM292055,28.1383,45.2474,Macin,ADM2,DISTRICT
SM283374,-5.429,13.9914,Macina,ADM2,DISTRICT
SM261643,149.0427,-21.0819,Mackay,ADM2,DISTRICT
SM286541,170.5191,-43.9399,Mackenzie,ADM2,DISTRICT
SM10149,-117.0461,58.6899,Mackenzie County,ADM2,DISTRICT
SM12500,-78.0247,18.3116,Mackfield,ADM2,DISTRICT
SM301299,-84.7623,45.8718,Mackinac,ADM2,DISTRICT
SM12501,-77.2802,18.2119,Macknie,ADM2,DISTRICT
SM236912,-66.5813,4.5621,Maco,LANGUAGE,LANGUAGE
SM255346,-66.18,5.71,Maco,ETHNICITY,ETHNICITY
SM255345,-71.17,-0.09,Maco,ETHNICITY,ETHNICITY
SM255345,-64.89,-0.83,Maco,ETHNICITY,ETHNICITY
SM255345,-66.94,-1.16,Maco,ETHNICITY,ETHNICITY
SM255345,-66.51,-0.21,Maco,ETHNICITY,ETHNICITY
SM301300,-82.9102,42.6728,Macomb,ADM2,DISTRICT
SM302593,-86.0073,36.532,Macon,ADM2,DISTRICT
SM301982,-83.4221,35.1504,Macon,ADM2,DISTRICT
SM301579,-92.5646,39.8308,Macon,ADM2,DISTRICT
SM300533,-84.0425,32.3584,Macon,ADM2,DISTRICT
SM300643,-88.9616,39.86,Macon,ADM2,DISTRICT
SM300284,-85.6927,32.386,Macon,ADM2,DISTRICT
SM255347,-81.55,22.82,Macorize,ETHNICITY,ETHNICITY
SM283919,33.9369,-17.8915,Macossa,ADM2,DISTRICT
SM300644,-89.9244,39.261,Macoupin,ADM2,DISTRICT
SM459127,145.3374,-42.4761,Macquarie Harbour Tasmanian,ETHNICITY,ETHNICITY
SM274416,179.25,-16.5,Macuata,ADM2,DISTRICT
SM265277,-42.2737,-22.0243,Macuco,ADM2,DISTRICT
SM285470,-86.612,13.6861,Macuelizo,ADM2,DISTRICT
SM275768,-88.66,15.26,Macuelizo,ADM2,DISTRICT
SM236926,-70.0749,-0.1446,Macuna,LANGUAGE,LANGUAGE
SM236924,-39.8094,-17.9048,Macuni,LANGUAGE,LANGUAGE
SM267519,-38.9146,-9.2809,Macururé,ADM2,DISTRICT
SM236925,-60.2209,4.3186,Macushi,LANGUAGE,LANGUAGE
SM255348,-58.27,4.2,Macushi,ETHNICITY,ETHNICITY
SM255348,-58.27,4.2,Macushi,ETHNICITY,ETHNICITY
SM255348,-59,4,Macushi,ETHNICITY,ETHNICITY
SM255348,-59,4,Macushi,ETHNICITY,ETHNICITY
SM259528,-60.1625,4.595,Macushi Sign Language,LANGUAGE,LANGUAGE
SM282623,-92.4702,17.8148,Macuspana,ADM2,DISTRICT
SM280805,102.2875,18.4825,Mad,ADM2,DISTRICT
SM236937,14.1142,10.8806,Mada (Cameroon),LANGUAGE,LANGUAGE
SM236932,8.3412,9.0229,Mada (Nigeria),LANGUAGE,LANGUAGE
SM250460,10.09,9.61,Mada (Nigeria),ETHNICITY,ETHNICITY
SM251094,14.1,10.8,Mada Cameroon,ETHNICITY,ETHNICITY
SM278020,35.7162,31.7058,Madaba,ADM2,DISTRICT
SM278020,35.7162,31.7058,Madaba,ADM2,DISTRICT
SM278020,35.7103,31.5386,Madaba,ADM2,DISTRICT
SM278020,35.7103,31.5386,Madaba,ADM2,DISTRICT
SM2081,35.6667,31.5833,Madaba,ADM1,DISTRICT
SM284833,13.5489,10.8522,Madagali,ADM2,DISTRICT
SM236929,46.87,-19.04,Madagascar Sign Language,LANGUAGE,LANGUAGE
SM236935,151.967,-3.3434,Madak,LANGUAGE,LANGUAGE
SM289393,-28.5234,38.5356,Madalena,ADM2,DISTRICT
SM267785,-39.4759,-4.8762,Madalena,ADM2,DISTRICT
SM262412,24.9667,41.5333,Madan,ADM2,DISTRICT
SM288570,145.7889,-5.2337,Madang,ADM2,DISTRICT
SM2082,145.5,-5,Madang,ADM1,DISTRICT
SM284618,6.1001,13.9999,Madaoua,ADM2,DISTRICT
SM292441,21.7208,46.843,Madaras,ADM2,DISTRICT
SM262255,90.2,23.25,Madaripur,ADM2,DISTRICT
SM290841,27.2772,47.0409,Madarjac,ADM2,DISTRICT
SM15247,7.155,13.2825,Madarounfa,ADM2,DISTRICT
SM268806,-68.2059,47.4253,Madawaska,ADM2,DISTRICT
SM2083,-16.947,32.7674,Madeira,ADM1,DISTRICT
SM265050,-42.5111,-3.5466,Madeiro,ADM2,DISTRICT
SM236943,130.833,-1.0533,Maden,LANGUAGE,LANGUAGE
SM298359,39.6725,38.3935,Maden,ADM2,DISTRICT
SM303175,-119.7627,37.218,Madera,ADM2,DISTRICT
SM283245,-108.3785,29.2599,Madera,ADM2,DISTRICT
SM282048,-101.1548,19.3355,Madero,ADM2,DISTRICT
SM286572,56.3159,25.2816,Madha,ADM2,DISTRICT
SM277413,87,25.8,Madhepura,ADM2,DISTRICT
SM255353,44.94,15.47,Madhhij,ETHNICITY,ETHNICITY
SM236947,143.5,-34.5,Madhi Madhi,DIALECT,LANGUAGE
SM277415,86.0833,26.3333,Madhubani,ADM2,DISTRICT
SM2084,78.5,23.5,Madhya Pradesh (2000-),ADM1,DISTRICT
SM233210,-66.5662,-7.6222,Madi,LANGUAGE,LANGUAGE
SM250461,32.06,3.03,Madi,ETHNICITY,ETHNICITY
SM250461,32.06,3.03,Madi,ETHNICITY,ETHNICITY
SM250461,32,4,Madi,ETHNICITY,ETHNICITY
SM250461,32,4,Madi,ETHNICITY,ETHNICITY
SM236951,146.407,-5.6302,Madi (Papua New Guinea),LANGUAGE,LANGUAGE
SM2085,51.2,25.9833,Madinat ash Shamal,ADM1,DISTRICT
SM16324,11.6444,-4.3344,Madingo-Kayes,ADM2,DISTRICT
SM269796,13.603,-4.198,Madingou,ADM2,DISTRICT
SM255359,142.66,-8.55,Madiri,ETHNICITY,ETHNICITY
SM302700,-95.9284,30.9655,Madison,ADM2,DISTRICT
SM303043,-78.2792,38.4137,Madison,ADM2,DISTRICT
SM302594,-88.8385,35.6081,Madison,ADM2,DISTRICT
SM301983,-82.7058,35.8581,Madison,ADM2,DISTRICT
SM302128,-83.4002,39.894,Madison,ADM2,DISTRICT
SM301887,-75.6697,42.9128,Madison,ADM2,DISTRICT
SM301732,-97.6008,41.9167,Madison,ADM2,DISTRICT
SM301465,-90.0338,32.6347,Madison,ADM2,DISTRICT
SM301580,-90.345,37.4781,Madison,ADM2,DISTRICT
SM301642,-111.9203,45.3007,Madison,ADM2,DISTRICT
SM300841,-94.0156,41.3307,Madison,ADM2,DISTRICT
SM301079,-84.278,37.7202,Madison,ADM2,DISTRICT
SM300734,-85.7194,40.1617,Madison,ADM2,DISTRICT
SM301134,-91.2426,32.3644,Madison,ADM2,DISTRICT
SM300570,-111.6593,43.7842,Madison,ADM2,DISTRICT
SM300645,-89.9052,38.8299,Madison,ADM2,DISTRICT
SM300534,-83.209,34.1278,Madison,ADM2,DISTRICT
SM300345,-83.4701,30.4441,Madison,ADM2,DISTRICT
SM302390,-93.7246,36.011,Madison,ADM2,DISTRICT
SM300285,-86.5502,34.7631,Madison,ADM2,DISTRICT
SM276351,111.65,-7.6167,Madiun,ADM2,DISTRICT
SM459195,145.857,-17.263,Madjay,ETHNICITY,ETHNICITY
SM296681,31.5501,-26.0901,Madlangempisi,ADM2,DISTRICT
SM13672,25.1949,56.8134,Madlienas pagasts,ADM2,DISTRICT
SM236956,130.6,-13.9479,Madngele,LANGUAGE,LANGUAGE
SM284873,8.3847,11.8242,Madobi,ADM2,DISTRICT
SM14944,158.3128,6.8676,Madolenihm Municipality,ADM2,DISTRICT
SM13673,26.2239,56.8546,Madona,ADM2,DISTRICT
SM4036,26.25,56.8333,Madona Municipality,ADM1,DISTRICT
SM12502,-77.4488,18.2911,Madras,ADM2,DISTRICT
SM262746,-67.0019,-11.5758,Madre de Dios,ADM2,DISTRICT
SM2086,-70.25,-12,Madre de Dios,ADM1,DISTRICT
SM267521,-38.6254,-12.7381,Madre de deus,ADM2,DISTRICT
SM263617,-44.3326,-21.4846,Madre de deus de Minas,ADM2,DISTRICT
SM274082,-3.7103,40.4023,Madrid,ADM2,DISTRICT
SM270022,-74.2771,4.7556,Madrid,ADM2,DISTRICT
SM2087,-86.4167,13.5,Madriz,ADM1,DISTRICT
SM271019,-81.808,22.9207,Madruga,ADM2,DISTRICT
SM236963,121.236,17.1794,Madukayang Kalinga,LANGUAGE,LANGUAGE
SM292203,24.1012,44.6782,Madulari,ADM2,DISTRICT
SM255361,113.32,-7,Madura,ETHNICITY,ETHNICITY
SM255361,110.94,-7.65,Madura,ETHNICITY,ETHNICITY
SM277221,78.03,9.89,Madurai (1997-),ADM2,DISTRICT
SM240872,113,-7,Madurese,LANGUAGE,LANGUAGE
SM262522,25.9,41.6833,Madzharovo,ADM2,DISTRICT
SM296874,99.3398,19.9914,Mae Ai,ADM2,DISTRICT
SM297334,99.8014,19.3793,Mae Chai,ADM2,DISTRICT
SM296910,99.8847,20.1991,Mae Chan,ADM2,DISTRICT
SM297243,101.1101,18.7134,Mae Charim,ADM2,DISTRICT
SM296911,99.6415,20.2223,Mae Fa Luang,ADM2,DISTRICT
SM2088,97.9167,18.75,Mae Hong Son,ADM1,DISTRICT
SM297063,97.983,18.4782,Mae La Noi,ADM2,DISTRICT
SM297293,101.2315,6.6683,Mae Lan,ADM2,DISTRICT
SM296912,99.7113,19.7722,Mae Lao,ADM2,DISTRICT
SM297016,99.8214,18.4024,Mae Mo,ADM2,DISTRICT
SM297017,99.0585,17.5045,Mae Phrik,ADM2,DISTRICT
SM297656,98.5864,17.1118,Mae Ramat,ADM2,DISTRICT
SM296876,98.8867,18.9414,Mae Rim,ADM2,DISTRICT
SM296913,99.926,20.365,Mae Sai,ADM2,DISTRICT
SM297064,97.7706,18.2647,Mae Sariang,ADM2,DISTRICT
SM297657,98.7107,16.7215,Mae Sot,ADM2,DISTRICT
SM296897,99.4846,19.6904,Mae Suai,ADM2,DISTRICT
SM296877,98.8333,19.1667,Mae Taeng,ADM2,DISTRICT
SM297018,99.5783,18.1112,Mae Tha,ADM2,DISTRICT
SM297033,99.0923,18.3954,Mae Tha,ADM2,DISTRICT
SM296878,98.6871,18.6746,Mae Wang,ADM2,DISTRICT
SM297207,99.4079,15.8354,Mae Wong,ADM2,DISTRICT
SM13003,139.1311,36.4143,Maebashi Shi (2009-),ADM2,DISTRICT
SM2089,38.9167,15.3333,Maekel,ADM1,DISTRICT
SM236967,120.718,17.2453,Maeng Itneg,LANGUAGE,LANGUAGE
SM289049,126.5833,39.6667,Maengsan,ADM2,DISTRICT
SM291617,22.7544,47.2989,Maeriste,ADM2,DISTRICT
SM11177,24.3942,58.9026,Maerjamaa vald,ADM2,DISTRICT
SM267522,-41.4906,-14.6653,Maetinga,ADM2,DISTRICT
SM370991,46.8127,-17.228,Maevatanana,ADM2:ADM3,DISTRICT
SM236973,13.8181,10.943,Mafa,LANGUAGE,LANGUAGE
SM285373,13.537,12.002,Mafa,ADM2,DISTRICT
SM250462,14.17,11.26,Mafa,ETHNICITY,ETHNICITY
SM250462,14.17,11.26,Mafa,ETHNICITY,ETHNICITY
SM250462,14.17,11.26,Mafa,ETHNICITY,ETHNICITY
SM250462,14,9,Mafa,ETHNICITY,ETHNICITY
SM250462,14,9,Mafa,ETHNICITY,ETHNICITY
SM250462,14,9,Mafa,ETHNICITY,ETHNICITY
SM250462,14,11,Mafa,ETHNICITY,ETHNICITY
SM250462,14,11,Mafa,ETHNICITY,ETHNICITY
SM250462,14,11,Mafa,ETHNICITY,ETHNICITY
SM236974,167.241,-15.4316,Mafea,LANGUAGE,LANGUAGE
SM2090,27.5026,-29.8041,Mafeteng,ADM1,DISTRICT
SM299153,39.7678,-7.8554,Mafia,ADM2,DISTRICT
SM18145,33.3987,-10.3765,Mafinga,ADM2,DISTRICT
SM299224,35.2236,-8.3529,Mafinga Township Authority,ADM2,DISTRICT
SM289259,-9.3264,38.9411,Mafra,ADM2,DISTRICT
SM266200,-49.8893,-26.2009,Mafra,ADM2,DISTRICT
SM2091,37.9167,32.3333,Mafraq,ADM1,DISTRICT
SM296711,31.52,-26.44,Mafutseni,ADM2,DISTRICT
SM236980,120.459,15.1954,Mag-Anchi Ayta,LANGUAGE,LANGUAGE
SM236991,120.182,14.9617,Mag-Indi Ayta,LANGUAGE,LANGUAGE
SM2092,154,63,Maga Buryatdan,ADM1,DISTRICT
SM293611,150.7984,59.5603,Magadan gorsovet,ADM2,DISTRICT
SM305375,18.1868,45.6616,Magadenovac,ADM2,DISTRICT
SM236977,85.3335,25.0915,Magahi,LANGUAGE,LANGUAGE
SM264183,-47.6299,-0.8184,Magalhães Barata,ADM2,DISTRICT
SM262814,-42.1285,-3.3233,Magalhães de Almeida,ADM2,DISTRICT
SM269072,-71.0833,-52.9167,Magallanes,ADM2,DISTRICT
SM261015,-68.5,-48.75,Magallanes,ADM2,DISTRICT
SM2093,-73.0007,-52.5003,Magallanes y Antártica Chilena,ADM1,DISTRICT
SM285027,5.05,10.4667,Magama,ADM2,DISTRICT
SM10421,-74.7803,9.1248,Magangue (1994-),ADM2,DISTRICT
SM17491,-60.9851,13.7945,Maganier,ADM2,DISTRICT
SM15067,37.5054,-17.3073,Maganja da Costa District  (2013-),ADM2,DISTRICT
SM255364,81.02,29.57,Magar,ETHNICITY,ETHNICITY
SM255364,81.02,29.57,Magar,ETHNICITY,ETHNICITY
SM255364,84,28,Magar,ETHNICITY,ETHNICITY
SM255364,84,28,Magar,ETHNICITY,ETHNICITY
SM292846,48.3333,41.6667,Magaramkentskiy rayon,ADM2,DISTRICT
SM15248,8.9097,12.9981,Magaria Department,ADM2,DISTRICT
SM15877,-66.7759,18.0267,Magas Barrio,ADM2,DISTRICT
SM458806,129.7153,-14.0876,Magati Ke,ETHNICITY,ETHNICITY
SM266676,-50.2241,-20.576,Magda,ADM2,DISTRICT
SM293238,126,53.3333,Magdagachinskiy rayon,ADM2,DISTRICT
SM282731,-97.0439,18.7607,Magdalena,ADM2,DISTRICT
SM282579,-111.018,30.68,Magdalena,ADM2,DISTRICT
SM281822,-104.0083,20.9171,Magdalena,ADM2,DISTRICT
SM275673,-88.3668,13.9245,Magdalena,ADM2,DISTRICT
SM2094,-74.5,10,Magdalena,ADM1,DISTRICT
SM14325,-96.8247,17.2356,Magdalena Apasco,ADM2,DISTRICT
SM283326,-99.2407,19.315,Magdalena Contreras,ADM2,DISTRICT
SM14326,-97.2267,17.2896,Magdalena Jaltepec,ADM2,DISTRICT
SM275275,-90.6755,14.5421,Magdalena Milpas Altas,ADM2,DISTRICT
SM14327,-96.9172,16.8905,Magdalena Mixtepec,ADM2,DISTRICT
SM14328,-96.7118,16.7089,Magdalena Ocotlan,ADM2,DISTRICT
SM14329,-97.5614,17.2383,Magdalena Penasco,ADM2,DISTRICT
SM236988,-97.5413,17.2837,Magdalena Peñasco Mixtec,LANGUAGE,LANGUAGE
SM14330,-96.5618,16.9023,Magdalena Teitipac,ADM2,DISTRICT
SM14331,-95.6207,16.405,Magdalena Tequisistlan,ADM2,DISTRICT
SM14332,-95.201,16.509,Magdalena Tlacotepec,ADM2,DISTRICT
SM14333,-97.3702,17.3753,Magdalena Yodocono de Porfirio Diaz,ADM2,DISTRICT
SM14334,-97.2271,17.3914,Magdalena Zahuatlan,ADM2,DISTRICT
SM276315,110.1619,-7.4275,Magelang,ADM2,DISTRICT
SM292442,22.4533,47.0093,Magesti,ADM2,DISTRICT
SM276352,111.3592,-7.6447,Magetan,ADM2,DISTRICT
SM12503,-77.7668,18.1734,Maggotty,ADM2,DISTRICT
SM284007,-12.8289,15.5404,Maghama,ADM2,DISTRICT
SM299214,39.2473,-6.1651,Magharibi,ADM2,DISTRICT
SM304801,44.1233,14.4653,Maghirib Ans,ADM2,DISTRICT
SM457569,-1.5,34.75,Maghnia District,ADM2,DISTRICT
SM461929,-12.38,27.05,Maghrebi,ETHNICITY,ETHNICITY
SM236993,145.1091,-5.1385,Magi,LANGUAGE,LANGUAGE
SM292123,26.5373,46.5097,Magiresti,ADM2,DISTRICT
SM236994,144.88,-4.88,Magiyi,LANGUAGE,LANGUAGE
SM290380,23.0985,44.0434,Maglavit,ADM2,DISTRICT
SM262445,25.6167,42.6333,Maglizh,ADM2,DISTRICT
SM292756,59.0833,53.4167,Magnitogorsk gorsovet,ADM2,DISTRICT
SM283873,31.3664,-15.9035,Magoe,ADM2,DISTRICT
SM301080,-83.0649,37.7065,Magoffin,ADM2,DISTRICT
SM236995,33.9826,-9.235,Magoma,LANGUAGE,LANGUAGE
SM236997,149.26,-10.2321,Magori,LANGUAGE,LANGUAGE
SM15878,-66.9436,18.3084,Magos Barrio,ADM2,DISTRICT
SM323851,-112.8674,49.4159,Magrath,ADM2:ADM3,DISTRICT
SM284003,-12.9182,17.648,Magta-Lahjar,ADM2,DISTRICT
SM299131,33.4025,-2.5938,Magu,ADM2,DISTRICT
SM255371,-76.21,20.34,Maguan,ETHNICITY,ETHNICITY
SM255372,-69.87,19,Maguana,ETHNICITY,ETHNICITY
SM283246,-107.9687,27.8166,Maguarichic,ADM2,DISTRICT
SM15879,-66.2811,18.4172,Maguayo Barrio,ADM2,DISTRICT
SM283927,32.6515,-25.0239,Magude,ADM2,DISTRICT
SM15880,-66.3691,18.2762,Magueyes Barrio (Corozal),ADM2,DISTRICT
SM15881,-66.6474,18.0621,Magueyes Barrio (Ponce),ADM2,DISTRICT
SM15882,-66.6379,18.0262,Magueyes Urbano Barrio,ADM2,DISTRICT
SM237002,124.509,6.9453,Maguindanao,LANGUAGE,LANGUAGE
SM4887,124.3,7.1333,Maguindanao (excl. Cotabato City),ADM2,DISTRICT
SM285374,12.6311,12.1731,Magumeri,ADM2,DISTRICT
SM291897,25.3959,44.0405,Magura,ADM2,DISTRICT
SM292124,26.839,46.5607,Magura,ADM2,DISTRICT
SM262265,89.4333,23.4333,Magura,ADM2,DISTRICT
SM291541,26.0496,45.0948,Magurele,ADM2,DISTRICT
SM290964,26.014,44.3393,Magurele,ADM2,DISTRICT
SM291542,25.7623,45.052,Magureni,ADM2,DISTRICT
SM290109,23.1949,46.6395,Maguri-racatau,ADM2,DISTRICT
SM250294,11.16,13.4,Maguzawa,ETHNICITY,ETHNICITY
SM250294,11.16,13.4,Maguzawa,ETHNICITY,ETHNICITY
SM250294,11.16,13.4,Maguzawa,ETHNICITY,ETHNICITY
SM250294,8.517,12,Maguzawa,ETHNICITY,ETHNICITY
SM250294,8.517,12,Maguzawa,ETHNICITY,ETHNICITY
SM250294,8.517,12,Maguzawa,ETHNICITY,ETHNICITY
SM250294,10,12,Maguzawa,ETHNICITY,ETHNICITY
SM250294,10,12,Maguzawa,ETHNICITY,ETHNICITY
SM250294,10,12,Maguzawa,ETHNICITY,ETHNICITY
SM2097,94.75,20.5,Magway,ADM1,DISTRICT
SM283510,95.25,20.25,Magwe Minbu,ADM2,DISTRICT
SM296009,32.3015,4.1253,Magwi,ADM2,DISTRICT
SM265278,-43.1206,-22.6349,Magé,ADM2,DISTRICT
SM270329,-78.0492,1.905,Magüí,ADM2,DISTRICT
SM297764,104.261,15.5127,Maha Chana Chai,ADM2,DISTRICT
SM297389,100.5409,14.5706,Maha Rat,ADM2,DISTRICT
SM2098,103.1723,16.0057,Maha Sarakham,ADM1,DISTRICT
SM277645,45.7333,36.7667,Mahabad,ADM2,DISTRICT
SM371045,45.165,-20.636,Mahabo,ADM2:ADM3,DISTRICT
SM11666,80.0054,17.6004,Mahabubabad,ADM2,DISTRICT
SM250463,43.93,-24.22,Mahafaly,ETHNICITY,ETHNICITY
SM237020,44.5,-24.2,Mahafaly Malagasy,LANGUAGE,LANGUAGE
SM255379,-117,39,Mahaguaduka,ETHNICITY,ETHNICITY
SM255379,-117,39,Mahaguaduka,ETHNICITY,ETHNICITY
SM255379,-117,39,Mahaguaduka,ETHNICITY,ETHNICITY
SM255379,-117.32,39.25,Mahaguaduka,ETHNICITY,ETHNICITY
SM255379,-117.32,39.25,Mahaguaduka,ETHNICITY,ETHNICITY
SM255379,-117.32,39.25,Mahaguaduka,ETHNICITY,ETHNICITY
SM255379,-117.3,39.3,Mahaguaduka,ETHNICITY,ETHNICITY
SM255379,-117.3,39.3,Mahaguaduka,ETHNICITY,ETHNICITY
SM255379,-117.3,39.3,Mahaguaduka,ETHNICITY,ETHNICITY
SM2099,-57.8333,6.25,Mahaica-Berbice,ADM1,DISTRICT
SM370994,46.8114,-15.6794,Mahajanga Rural,ADM2:ADM3,DISTRICT
SM370995,46.3506,-15.6688,Mahajanga Urban,ADM2:ADM3,DISTRICT
SM268525,26.3254,-23.2169,Mahalapye,ADM2,DISTRICT
SM277567,50.4167,33.9167,Mahalat,ADM2,DISTRICT
SM237009,86.7445,22.1698,Mahali,LANGUAGE,LANGUAGE
SM14185,100.7795,5.4317,Mahang,ADM2,DISTRICT
SM371024,48.5177,-20.0316,Mahanoro,ADM2:ADM3,DISTRICT
SM277313,83.5067,27.1695,Maharajganj,ADM2,DISTRICT
SM2101,76,19.5,Maharashtra,ADM1,DISTRICT
SM235053,73.9166,19.2414,Maharashtrian Konkani,LANGUAGE,LANGUAGE
SM235053,73.9166,19.2414,Maharashtrian Konkani,LANGUAGE,LANGUAGE
SM235053,73.994,18.404,Maharashtrian Konkani,LANGUAGE,LANGUAGE
SM235053,73.994,18.404,Maharashtrian Konkani,LANGUAGE,LANGUAGE
SM237017,74.71,19.18,Maharastri Prakrit,LANGUAGE,LANGUAGE
SM277451,82.5,21.2,Mahasamund,ADM2,DISTRICT
SM300842,-92.6409,41.3352,Mahaska,ADM2,DISTRICT
SM237005,77.5317,31.0088,Mahasu Pahari,LANGUAGE,LANGUAGE
SM270685,-75.1667,10.1667,Mahates,ADM2,DISTRICT
SM17492,-60.954,13.8439,Mahaut,ADM2,DISTRICT
SM280868,105.315,17.3632,Mahaxay,ADM2,DISTRICT
SM11667,77.7556,16.6317,Mahbubnagar (2016-),ADM2,DISTRICT
SM299933,34.9676,48.9761,Mahdalynivs'kyi,ADM2,DISTRICT
SM297887,10.955,35.4914,Mahdia,ADM2,DISTRICT
SM2102,10.5833,35.3333,Mahdia,ADM1,DISTRICT
SM11782,53.3508,35.7081,Mahdishahr,ADM2,DISTRICT
SM277138,75.5333,11.7,Mahe,ADM2,DISTRICT
SM237022,100.017,21.6558,Mahei,LANGUAGE,LANGUAGE
SM276847,76.1667,28.25,Mahendragarh,ADM2,DISTRICT
SM276820,72.5,23.6667,Mahesana (2000-),ADM2,DISTRICT
SM255397,2.41,8.86,Mahi,ETHNICITY,ETHNICITY
SM237024,143.2349,-7.6847,Mahigi,LANGUAGE,LANGUAGE
SM9798,30.3364,53.9139,Mahilyow,ADM2,DISTRICT
SM2103,30.75,54,Mahilyow,ADM1,DISTRICT
SM9799,30.25,53.8333,Mahilyowski Rayon (excl. Mahilyow),ADM2,DISTRICT
SM11245,-149.49,-17.5085,Mahina,ADM2,DISTRICT
SM276821,73.6085,23.1306,Mahisagar,ADM2,DISTRICT
SM296712,31.2,-26.7833,Mahlangatja,ADM2,DISTRICT
SM304910,45.1832,14.6821,Mahliyah,ADM2,DISTRICT
SM255404,101.64,3.2,Mahmeri,ETHNICITY,ETHNICITY
SM260064,69.4209,35.0048,Mahmud Raqi,ADM2,DISTRICT
SM277587,52.3667,36.6,Mahmudabad,ADM2,DISTRICT
SM305860,44.25,33.0833,Mahmudiya,ADM2,DISTRICT
SM298417,30.9925,39.5072,Mahmudiye,ADM2,DISTRICT
SM277669,47.5667,36.6667,Mahneshan,ADM2,DISTRICT
SM301376,-95.8091,47.3252,Mahnomen,ADM2,DISTRICT
SM277314,79.8724,25.2921,Mahoba,ADM2,DISTRICT
SM237026,14.0472,0.6436,Mahongwe,LANGUAGE,LANGUAGE
SM302129,-80.7763,41.0146,Mahoning,ADM2,DISTRICT
SM375304,85.7975,26.8849,Mahottari,ADM2:ADM3,DISTRICT
SM237027,-7.5711,8.3548,Mahou,LANGUAGE,LANGUAGE
SM255407,-6.23,10.28,Mahou,ETHNICITY,ETHNICITY
SM255408,52.64,17.15,Mahra,ETHNICITY,ETHNICITY
SM297951,10.481,34.549,Mahres,ADM2,DISTRICT
SM255410,25.61,-20.87,Mahura,ETHNICITY,ETHNICITY
SM304201,105.0315,20.663,Mai Châu,ADM2,DISTRICT
SM297294,101.6786,6.616,Mai Kaen,ADM2,DISTRICT
SM304464,104.0482,21.1588,Mai Sơn,ADM2,DISTRICT
SM284913,8.2117,13.1906,Mai'Adua,ADM2,DISTRICT
SM237034,145.096,-4.5021,Maia,LANGUAGE,LANGUAGE
SM289314,-8.6026,41.2425,Maia,ADM2,DISTRICT
SM255414,114.02,-23.99,Maia,ETHNICITY,ETHNICITY
SM255414,114.02,-23.99,Maia,ETHNICITY,ETHNICITY
SM255414,114.3321,-24.422,Maia,ETHNICITY,ETHNICITY
SM255414,114.3321,-24.422,Maia,ETHNICITY,ETHNICITY
SM237031,150.76,-9.4713,Maiadomu,LANGUAGE,LANGUAGE
SM13329,172.9976,0.9187,Maiana,ADM2,DISTRICT
SM237033,145.239,-4.5507,Maiani,LANGUAGE,LANGUAGE
SM458684,141.5875,-23.4192,Maiawali,ETHNICITY,ETHNICITY
SM279227,136.3774,35.3846,Maibara,ADM2,DISTRICT
SM292352,27.4612,45.5007,Maicanesti,ADM2,DISTRICT
SM10422,-72.2868,11.3995,Maicao (2000-),ADM2,DISTRICT
SM255415,60.4,25.62,Maid,ETHNICITY,ETHNICITY
SM12504,-77.5728,18.1017,Maidstone,ADM2,DISTRICT
SM255417,-121.1,39.7,Maidu,ETHNICITY,ETHNICITY
SM255417,-121.1,39.7,Maidu,ETHNICITY,ETHNICITY
SM255417,-121.1,39.7,Maidu,ETHNICITY,ETHNICITY
SM255417,-121.1,39.7,Maidu,ETHNICITY,ETHNICITY
SM255417,-121,40,Maidu,ETHNICITY,ETHNICITY
SM255417,-121,40,Maidu,ETHNICITY,ETHNICITY
SM255417,-121,40,Maidu,ETHNICITY,ETHNICITY
SM255417,-121,40,Maidu,ETHNICITY,ETHNICITY
SM255417,-120.56,40.28,Maidu,ETHNICITY,ETHNICITY
SM255417,-120.56,40.28,Maidu,ETHNICITY,ETHNICITY
SM255417,-120.56,40.28,Maidu,ETHNICITY,ETHNICITY
SM255417,-120.56,40.28,Maidu,ETHNICITY,ETHNICITY
SM255417,-121,40.2,Maidu,ETHNICITY,ETHNICITY
SM255417,-121,40.2,Maidu,ETHNICITY,ETHNICITY
SM255417,-121,40.2,Maidu,ETHNICITY,ETHNICITY
SM255417,-121,40.2,Maidu,ETHNICITY,ETHNICITY
SM255418,-121.3,39.8,Maidu (Foothills),ETHNICITY,ETHNICITY
SM255419,-121.9,39.7,Maidu (Valley),ETHNICITY,ETHNICITY
SM285375,13.1517,11.8354,Maidugur,ADM2,DISTRICT
SM292505,24.7568,47.4074,Maieru,ADM2,DISTRICT
SM292636,25.4971,45.8936,Maierus,ADM2,DISTRICT
SM284794,9.5859,12.7254,Maigatari,ADM2,DISTRICT
SM284834,13.2048,9.8698,Maiha,ADM2,DISTRICT
SM237039,168.211,-16.7388,Maii,LANGUAGE,LANGUAGE
SM241748,-72.4926,-2.8632,Maijiki,LANGUAGE,LANGUAGE
SM17493,-61.0277,13.8179,Mailly Motete,ADM2,DISTRICT
SM237040,149.521,-10.3657,Mailu,LANGUAGE,LANGUAGE
SM255422,149.64,-10.39,Mailu,ETHNICITY,ETHNICITY
SM255422,149.64,-10.39,Mailu,ETHNICITY,ETHNICITY
SM255422,149,-10,Mailu,ETHNICITY,ETHNICITY
SM255422,149,-10,Mailu,ETHNICITY,ETHNICITY
SM271795,-70.3,18.8833,Maimón,ADM2,DISTRICT
SM237047,36.5941,-18.3143,Maindo,LANGUAGE,LANGUAGE
SM2105,-69.2498,45.5003,Maine,ADM1,DISTRICT
SM274459,-0.4998,47.3891,Maine-et-Loire,ADM2,DISTRICT
SM282650,-99.5631,24.585,Mainero,ADM2,DISTRICT
SM284995,3.3729,6.4794,Mainland,ADM2,DISTRICT
SM277315,79,27.1667,Mainpuri,ADM2,DISTRICT
SM371002,44.2286,-17.8803,Maintirano,ADM2:ADM3,DISTRICT
SM2106,-23.1667,15.2167,Maio,ADM1,DISTRICT
SM269084,-70.75,-33.8333,Maipo,ADM2,DISTRICT
SM237059,-67.5,4.8,Maipure,LANGUAGE,LANGUAGE
SM237060,-67.5,4.8,Maipure-Avane,FAMILY,LANGUAGE
SM260881,-68.5833,-33,Maipú,ADM2,DISTRICT
SM260796,-57.5,-36.8333,Maipú,ADM2,DISTRICT
SM261086,-60.4167,-26.3333,Maipú,ADM2,DISTRICT
SM267523,-40.2641,-15.699,Maiquinique,ADM2,DISTRICT
SM240997,134.071,-3.4511,Mairasi,LANGUAGE,LANGUAGE
SM267524,-40.2134,-11.6349,Mairi,ADM2,DISTRICT
SM266678,-47.2344,-23.5105,Mairinque,ADM2,DISTRICT
SM266679,-46.5599,-23.3213,Mairiporã,ADM2,DISTRICT
SM268080,-49.5079,-17.3269,Mairipotaba,ADM2,DISTRICT
SM237062,148.731,-9.2285,Maisin,LANGUAGE,LANGUAGE
SM271120,-74.1487,20.2473,Maisí,ADM2,DISTRICT
SM237065,86.2211,26.3817,Maithili,LANGUAGE,LANGUAGE
SM261578,151.55,-32.7333,Maitland,ADM2,DISTRICT
SM255427,146.35,-8.36,Maiva,ETHNICITY,ETHNICITY
SM237072,119.978,-3.5944,Maiwa (Indonesia),LANGUAGE,LANGUAGE
SM237073,149.274,-9.6984,Maiwa (Papua New Guinea),LANGUAGE,LANGUAGE
SM237071,150.33,-10.3282,Maiwala,LANGUAGE,LANGUAGE
SM284937,4.3539,11.9905,Maiyama,ADM2,DISTRICT
SM15883,-65.7591,18.2339,Maizales Barrio,ADM2,DISTRICT
SM278672,135.34,35.4701,Maizuru,ADM2,DISTRICT
SM271075,-78.9781,21.8785,Majagua,ADM2,DISTRICT
SM270529,-74.715,8.535,Majagual,ADM2,DISTRICT
SM276768,108.2833,-6.8167,Majalengka,ADM2,DISTRICT
SM237077,34.97,7.71,Majang,LANGUAGE,LANGUAGE
SM274356,35.1279,7.3458,Majang,ADM2,DISTRICT
SM298111,8.543,34.7677,Majel Belabbes,ADM2,DISTRICT
SM276565,118.8667,-3.15,Majene,ADM2,DISTRICT
SM237079,15.044,11.1433,Majera,LANGUAGE,LANGUAGE
SM12505,-76.833,18.0002,Majestic Gardens,ADM2,DISTRICT
SM237083,80.3788,29.4559,Majhi,LANGUAGE,LANGUAGE
SM237080,83.6138,22.8792,Majhwar,LANGUAGE,LANGUAGE
SM270997,-76.7178,20.9419,Majibacoa,ADM2,DISTRICT
SM302210,-98.536,36.3116,Major,ADM2,DISTRICT
SM266201,-49.0574,-27.4233,Major Gercino,ADM2,DISTRICT
SM265380,-36.9649,-9.5169,Major Isidoro,ADM2,DISTRICT
SM265585,-38.3097,-6.4062,Major Sales,ADM2,DISTRICT
SM266202,-50.3271,-26.4948,Major Vieira,ADM2,DISTRICT
SM11668,94.2619,27.001,Majuli,ADM2,DISTRICT
SM283960,36.472,-13.316,Majune,ADM2,DISTRICT
SM305449,16.5295,45.2551,Majur,ADM2,DISTRICT
SM4100,171.2,7.15,Majuro Atoll,ADM1,DISTRICT
SM304932,43.5104,17.0923,Majz,ADM2,DISTRICT
SM304911,44.7941,15.8277,Majzar,ADM2,DISTRICT
SM296329,15.7582,46.3323,Majšperk,ADM1:ADM2,DISTRICT
SM237109,107.726,25.5946,Mak (China),LANGUAGE,LANGUAGE
SM237125,11.1337,9.373,Mak (Nigeria),LANGUAGE,LANGUAGE
SM237091,13.0789,4.1547,Makaa,LANGUAGE,LANGUAGE
SM16325,12.5308,-3.5849,Makabana,ADM2,DISTRICT
SM237104,-124.616,48.3258,Makah,LANGUAGE,LANGUAGE
SM255437,-124.47,48.18,Makah,ETHNICITY,ETHNICITY
SM255437,-124.47,48.18,Makah,ETHNICITY,ETHNICITY
SM255437,-124.47,48.18,Makah,ETHNICITY,ETHNICITY
SM255437,-124.62,48.33,Makah,ETHNICITY,ETHNICITY
SM255437,-124.62,48.33,Makah,ETHNICITY,ETHNICITY
SM255437,-124.62,48.33,Makah,ETHNICITY,ETHNICITY
SM255437,-124.58,48.22,Makah,ETHNICITY,ETHNICITY
SM255437,-124.58,48.22,Makah,ETHNICITY,ETHNICITY
SM255437,-124.58,48.22,Makah,ETHNICITY,ETHNICITY
SM11125,15.3222,-4.3798,Makala District,ADM2,DISTRICT
SM261975,29.805,-4.1347,Makamba,ADM2,DISTRICT
SM2107,29.7528,-4.1667,Makamba,ADM1,DISTRICT
SM299137,34.9065,-8.8708,Makambako Township Authority,ADM2,DISTRICT
SM237108,34.7964,-9.524,Makanjaula,DIALECT,LANGUAGE
SM293330,44.1667,57.75,Makar'evskiy rayon,ADM2,DISTRICT
SM284818,7.9692,11.298,Makarfi,ADM2,DISTRICT
SM275997,17.0333,43.3,Makarska,ADM2,DISTRICT
SM237102,126.507,-8.6584,Makasae-Makalero,LANGUAGE,LANGUAGE
SM237098,119.838,-5.6555,Makasar,LANGUAGE,LANGUAGE
SM255440,119.97,-3.99,Makasar,ETHNICITY,ETHNICITY
SM255440,119.97,-3.99,Makasar,ETHNICITY,ETHNICITY
SM255440,119.69,-5.31,Makasar,ETHNICITY,ETHNICITY
SM255440,119.69,-5.31,Makasar,ETHNICITY,ETHNICITY
SM276573,119.45,-5.15,Makassar,ADM2,DISTRICT
SM237092,119.408,-5.1611,Makassar Malay,LANGUAGE,LANGUAGE
SM279945,53,47.5,Makatskiy,ADM2,DISTRICT
SM237101,142.706,-8.3731,Makayam,LANGUAGE,LANGUAGE
SM262074,30.0019,-3.5967,Makebuko,ADM2,DISTRICT
SM2108,22.5833,42.05,Makedonska Kamenica,ADM1,DISTRICT
SM2109,21.2333,41.6556,Makedonski Brod,ADM1,DISTRICT
SM11246,-143.7166,-16.6008,Makemo,ADM2,DISTRICT
SM299138,34.1422,-9.2352,Makete,ADM2,DISTRICT
SM292847,47.4167,43,Makhachkala gorsovet,ADM2,DISTRICT
SM13978,27.7257,-29.7,Makhaleng Constituency,ADM2,DISTRICT
SM297789,102.2167,12.7333,Makham,ADM2,DISTRICT
SM279946,51,47.5,Makhambetskiy,ADM2,DISTRICT
SM255441,30.6,-29.78,Makhanya,ETHNICITY,ETHNICITY
SM305837,43.5949,35.8021,Makhmur,ADM2,DISTRICT
SM13979,28.0351,-29.1452,Makhoroana,ADM2,DISTRICT
SM237118,38.8052,-14.8509,Makhuwa,LANGUAGE,LANGUAGE
SM237116,39.1203,-16.9394,Makhuwa-Marrevone,LANGUAGE,LANGUAGE
SM237115,38.6874,-11.9553,Makhuwa-Meetto,LANGUAGE,LANGUAGE
SM237119,38.093,-17.294,Makhuwa-Moniga,LANGUAGE,LANGUAGE
SM237120,39.5323,-13.6443,Makhuwa-Saka,LANGUAGE,LANGUAGE
SM237114,36.5249,-14.251,Makhuwa-Shirima,LANGUAGE,LANGUAGE
SM255442,127.82,0.56,Makianese,ETHNICITY,ETHNICITY
SM255443,140.17,-7.54,Makiewahin,ETHNICITY,ETHNICITY
SM13330,172.9966,3.3882,Makin,ADM2,DISTRICT
SM279286,138.1857,34.7323,Makinohara,ADM2,DISTRICT
SM2110,161.7852,-10.5575,Makira Ulawa,ADM1,DISTRICT
SM299947,38.0308,48.0629,Maki‹vs'ka,ADM2,DISTRICT
SM2111,41.2042,21.6243,Makkah,ADM1,DISTRICT
SM17796,39.8,21.25,Makkah al Mukarramah,ADM2,DISTRICT
SM237124,139.273,-7.7442,Maklew,LANGUAGE,LANGUAGE
SM255445,139.66,-7.01,Maklew,ETHNICITY,ETHNICITY
SM255446,14.01,3.65,Mako,ETHNICITY,ETHNICITY
SM250908,43.81,-22.32,Makoa,ETHNICITY,ETHNICITY
SM284874,8.433,12.419,Makoda,ADM2,DISTRICT
SM4154,15.6763,46.3096,Makole,ADM1,DISTRICT
SM237126,151.709,-4.88,Makolkol,LANGUAGE,LANGUAGE
SM237127,39.5133,-10.9906,Makonde,LANGUAGE,LANGUAGE
SM305257,30.0833,-17.1667,Makonde,ADM2,DISTRICT
SM250468,38.26,-12.89,Makonde,ETHNICITY,ETHNICITY
SM250468,38.26,-12.89,Makonde,ETHNICITY,ETHNICITY
SM250468,39.19,-10.68,Makonde,ETHNICITY,ETHNICITY
SM250468,39.19,-10.68,Makonde,ETHNICITY,ETHNICITY
SM250468,40,-11,Makonde,ETHNICITY,ETHNICITY
SM250468,40,-11,Makonde,ETHNICITY,ETHNICITY
SM305233,32.1465,-18.3355,Makoni,ADM2,DISTRICT
SM13674,27.3837,56.2681,Makonkalna pagasts,ADM2,DISTRICT
SM16326,16.3921,-1.7303,Makotimpoko,ADM2,DISTRICT
SM16327,15.6119,-0.0045,Makoua,ADM2,DISTRICT
SM10878,32.4855,35.0494,Makounta,ADM2,DISTRICT
SM255448,64,26,Makrani,ETHNICITY,ETHNICITY
SM255448,64,26,Makrani,ETHNICITY,ETHNICITY
SM255448,20,25,Makrani,ETHNICITY,ETHNICITY
SM255448,20,25,Makrani,ETHNICITY,ETHNICITY
SM10879,33.7545,35.0721,Makrasyka,ADM2,DISTRICT
SM262485,22.5667,43.7833,Makresh,ADM2,DISTRICT
SM294762,35.9167,57.8333,Maksatikhinskiy rayon,ADM2,DISTRICT
SM297974,9.1546,35.892,Makthar,ADM2,DISTRICT
SM237142,127.239,-8.4037,Maku'a,LANGUAGE,LANGUAGE
SM250467,39.46,-12.69,Makua,ETHNICITY,ETHNICITY
SM250467,39.46,-12.69,Makua,ETHNICITY,ETHNICITY
SM250467,39.06,-15.63,Makua,ETHNICITY,ETHNICITY
SM250467,39.06,-15.63,Makua,ETHNICITY,ETHNICITY
SM250467,37.38,-13.67,Makua,ETHNICITY,ETHNICITY
SM250467,37.38,-13.67,Makua,ETHNICITY,ETHNICITY
SM250467,39,-15,Makua,ETHNICITY,ETHNICITY
SM250467,39,-15,Makua,ETHNICITY,ETHNICITY
SM2112,37.872,-2.222,Makueni County,ADM1:ADM2,DISTRICT
SM14945,150.126,8.985,Makur Municipality,ADM2,DISTRICT
SM278493,130.3068,31.2914,Makurazaki,ADM2,DISTRICT
SM285342,8.5359,7.7042,Makurdi,ADM2,DISTRICT
SM285342,8.5359,7.7042,Makurdi,ADM2,DISTRICT
SM285342,8.523,7.7375,Makurdi,ADM2,DISTRICT
SM285342,8.523,7.7375,Makurdi,ADM2,DISTRICT
SM237138,94.95,26.6,Makuri Naga,LANGUAGE,LANGUAGE
SM237143,-64.3201,-11.8701,Makuráp,LANGUAGE,LANGUAGE
SM293471,67.25,55.25,Makushinskiy rayon,ADM2,DISTRICT
SM375309,85.0536,27.4371,Makwanpur,ADM2:ADM3,DISTRICT
SM237145,40.0274,-10.4673,Makwe,LANGUAGE,LANGUAGE
SM237146,95.6045,26.3037,Makyan Naga,LANGUAGE,LANGUAGE
SM276250,20.542,46.267,Makó,ADM2,DISTRICT
SM288953,21.1795,52.9477,Maków,ADM2,DISTRICT
SM237239,101.204,19.273,Mal,LANGUAGE,LANGUAGE
SM17494,-61.0483,13.8736,Mal Maison,ADM2,DISTRICT
SM15884,-67.1694,18.366,Mal Paso Barrio,ADM2,DISTRICT
SM10241,19.6313,6.2104,Mala,ADM2,DISTRICT
SM255452,78.17,18.67,Mala,ETHNICITY,ETHNICITY
SM237167,8.0984,10.5776,Mala (Nigeria),LANGUAGE,LANGUAGE
SM237185,145.04,-4.3589,Mala (Papua New Guinea),LANGUAGE,LANGUAGE
SM237154,76.8708,10.5007,Mala Malasar,LANGUAGE,LANGUAGE
SM305342,16.527,46.3756,Mala Subotica,ADM2,DISTRICT
SM237199,81.2119,8.5867,Malabar-Sri Lanka Portuguese,LANGUAGE,LANGUAGE
SM275116,8.7425,3.7504,Malabo,ADM2,DISTRICT
SM263618,-42.0889,-17.838,Malacacheta,ADM2,DISTRICT
SM275476,-91.4705,15.2155,Malacatancito,ADM2,DISTRICT
SM275282,-92.1055,14.9183,Malacatán,ADM2,DISTRICT
SM237190,102.2621,2.1881,Malacca-Batavia Portuguese Creole,LANGUAGE,LANGUAGE
SM237175,102.2405,2.2027,Malaccan Creole Malay,LANGUAGE,LANGUAGE
SM296131,17.0217,48.4346,Malacky,ADM2,DISTRICT
SM9800,26.8333,54.25,Maladzyechanski Rayon (excl. Maladzyechna),ADM2,DISTRICT
SM280349,102.6016,13.499,Malai,ADM2,DISTRICT
SM255464,77.25,9.25,Malai Pandaram,ETHNICITY,ETHNICITY
SM292204,24.0446,45.3643,Malaia,ADM2,DISTRICT
SM2113,161,-9,Malaita,ADM1,DISTRICT
SM454467,161,-9,Malaitan,ETHNICITY,ETHNICITY
SM457944,124.8948,-9.5632,Malaka Regency,ADM2,DISTRICT
SM375427,71.8847,34.5364,Malakand P.A.,ADM2:ADM3,DISTRICT
SM237150,69.0719,33.0726,Malakhel,LANGUAGE,LANGUAGE
SM237181,146.642,-5.7749,Malalamai,LANGUAGE,LANGUAGE
SM237151,-42.2827,-16.834,Malali,LANGUAGE,LANGUAGE
SM284795,9.986,12.5601,MalamMad,ADM2,DISTRICT
SM270679,-74.8077,10.8496,Malambo,ADM2,DISTRICT
SM2114,167.5858,-16.312,Malampa,ADM1,DISTRICT
SM276353,112.6667,-8.1667,Malang,ADM2,DISTRICT
SM237177,160.118,-9.6138,Malango,LANGUAGE,LANGUAGE
SM295375,159.9985,-9.556,Malango,ADM2,DISTRICT
SM260357,16.3333,-9.5,Malanje,ADM2,DISTRICT
SM2115,16.8255,-9.6755,Malanje,ADM1,DISTRICT
SM237156,77.093,8.4506,Malankuravan,LANGUAGE,LANGUAGE
SM262107,3.2637,11.6664,Malanville,ADM2,DISTRICT
SM237164,77.272,8.5923,Malapandaram,LANGUAGE,LANGUAGE
SM276966,76.1667,11,Malappuram,ADM2,DISTRICT
SM260882,-69.25,-36.25,Malargüe,ADM2,DISTRICT
SM237162,76.5586,10.4468,Malaryan,LANGUAGE,LANGUAGE
SM262571,24.0833,51.8333,Malaryta,ADM2,DISTRICT
SM237186,145.621,-4.7669,Malas,LANGUAGE,LANGUAGE
SM237179,147.058,-5.9401,Malasanga,LANGUAGE,LANGUAGE
SM237155,76.833,10.467,Malasar,LANGUAGE,LANGUAGE
SM2116,38,38.5,Malatya,ADM1,DISTRICT
SM237159,77.2038,8.8552,Malavedan,LANGUAGE,LANGUAGE
SM237191,121.3913,17.8448,Malaweg,LANGUAGE,LANGUAGE
SM237147,35.4335,-15.5673,Malawi Lomwe,LANGUAGE,LANGUAGE
SM237171,34.991,-16.6183,Malawi Sena,LANGUAGE,LANGUAGE
SM237160,76.7651,9.5921,Malayalam,LANGUAGE,LANGUAGE
SM255472,77.42,8.56,Malayalam,ETHNICITY,ETHNICITY
SM255472,77.42,8.56,Malayalam,ETHNICITY,ETHNICITY
SM255472,77.42,8.56,Malayalam,ETHNICITY,ETHNICITY
SM255472,77.42,8.56,Malayalam,ETHNICITY,ETHNICITY
SM255472,75.86,11.49,Malayalam,ETHNICITY,ETHNICITY
SM255472,75.86,11.49,Malayalam,ETHNICITY,ETHNICITY
SM255472,75.86,11.49,Malayalam,ETHNICITY,ETHNICITY
SM255472,75.86,11.49,Malayalam,ETHNICITY,ETHNICITY
SM255472,77.62,12.31,Malayalam,ETHNICITY,ETHNICITY
SM255472,77.62,12.31,Malayalam,ETHNICITY,ETHNICITY
SM255472,77.62,12.31,Malayalam,ETHNICITY,ETHNICITY
SM255472,77.62,12.31,Malayalam,ETHNICITY,ETHNICITY
SM255472,75.75,12,Malayalam,ETHNICITY,ETHNICITY
SM255472,75.75,12,Malayalam,ETHNICITY,ETHNICITY
SM255472,75.75,12,Malayalam,ETHNICITY,ETHNICITY
SM255472,75.75,12,Malayalam,ETHNICITY,ETHNICITY
SM255472,76,11,Malayalam,ETHNICITY,ETHNICITY
SM255472,76,11,Malayalam,ETHNICITY,ETHNICITY
SM255472,76,11,Malayalam,ETHNICITY,ETHNICITY
SM255472,76,11,Malayalam,ETHNICITY,ETHNICITY
SM255472,76.3,10,Malayalam,ETHNICITY,ETHNICITY
SM255472,76.3,10,Malayalam,ETHNICITY,ETHNICITY
SM255472,76.3,10,Malayalam,ETHNICITY,ETHNICITY
SM255472,76.3,10,Malayalam,ETHNICITY,ETHNICITY
SM277480,48.8333,34.3333,Malayer,ADM2,DISTRICT
SM237173,111.136,-2.7838,Malayic Dayak,LANGUAGE,LANGUAGE
SM237182,121.919,11.8564,Malaynon,LANGUAGE,LANGUAGE
SM237187,-73.4056,10.9286,Malayo,LANGUAGE,LANGUAGE
SM237149,101.366,2.946,Malaysian Sign Language,LANGUAGE,LANGUAGE
SM298782,42.5454,39.1509,Malazgirt,ADM2,DISTRICT
SM237207,-2.9062,9.7846,Malba Birifor,LANGUAGE,LANGUAGE
SM15249,5.5594,14.0409,Malbaza,ADM2,DISTRICT
SM288698,19.0442,54.0633,Malbork,ADM2,DISTRICT
SM283740,93.2672,49.7101,Malchin,ADM2,DISTRICT
SM291898,24.9233,44.1266,Maldaeni,ADM2,DISTRICT
SM277359,88,25,Maldah,ADM2,DISTRICT
SM292206,24.0061,45.1026,Maldaresti,ADM2,DISTRICT
SM13331,-154.9295,-4.0187,Malden,ADM2,DISTRICT
SM12506,-77.81,18.3383,Maldon,ADM2,DISTRICT
SM18339,-54.983,-34.8439,Maldonado,ADM2,DISTRICT
SM2117,-54.8333,-34.5833,Maldonado,ADM1,DISTRICT
SM255481,37,6,Male,ETHNICITY,ETHNICITY
SM237211,36.9892,5.9197,Male (Ethiopia),LANGUAGE,LANGUAGE
SM237215,145.757,-5.4998,Male (Papua New Guinea),LANGUAGE,LANGUAGE
SM255482,-67.57,45.77,Malecite,ETHNICITY,ETHNICITY
SM237216,-67.4081,45.4945,Malecite-Passamaquoddy,LANGUAGE,LANGUAGE
SM255484,167.52,-16.4,Malekula,ETHNICITY,ETHNICITY
SM255484,167.52,-16.4,Malekula,ETHNICITY,ETHNICITY
SM255484,167.333,-16,Malekula,ETHNICITY,ETHNICITY
SM255484,167.333,-16,Malekula,ETHNICITY,ETHNICITY
SM14946,163.0169,5.2751,Malem Municipality,ADM2,DISTRICT
SM283945,37.4092,-14.7938,Malema,ADM2,DISTRICT
SM255485,-161.05,66,Malemiut,ETHNICITY,ETHNICITY
SM255485,-161.05,66,Malemiut,ETHNICITY,ETHNICITY
SM255485,-161.53,66.77,Malemiut,ETHNICITY,ETHNICITY
SM255485,-161.53,66.77,Malemiut,ETHNICITY,ETHNICITY
SM237209,105.5275,17.9135,Maleng,LANGUAGE,LANGUAGE
SM237213,148.445,-5.6161,Maleu-Kilenge,LANGUAGE,LANGUAGE
SM276802,-4.6313,54.1056,Malew,ADM1:ADM2,DISTRICT
SM15885,-67.1216,18.4999,Maleza Alta Barrio,ADM2,DISTRICT
SM15886,-67.1379,18.4964,Maleza Baja Barrio,ADM2,DISTRICT
SM15887,-67.1054,18.1672,Malezas Barrio,ADM2,DISTRICT
SM237220,114.245,-26.211,Malgana,LANGUAGE,LANGUAGE
SM459135,114.47,-26.58,Malgana,ETHNICITY,ETHNICITY
SM459135,114.47,-26.58,Malgana,ETHNICITY,ETHNICITY
SM459135,114.3835,-26.3669,Malgana,ETHNICITY,ETHNICITY
SM459135,114.3835,-26.3669,Malgana,ETHNICITY,ETHNICITY
SM237221,14.8475,12.5342,Malgbe,LANGUAGE,LANGUAGE
SM292877,44.5833,43.5,Malgobekskiy rayon,ADM2,DISTRICT
SM17495,-60.9041,13.842,Malgretoute (Micoud),ADM2,DISTRICT
SM17496,-61.06,13.8423,Malgretoute (Soufrière),ADM2,DISTRICT
SM237222,13.61,11.77,Malgwa,LANGUAGE,LANGUAGE
SM267526,-43.6315,-14.1915,Malhada,ADM2,DISTRICT
SM267525,-41.8921,-14.2777,Malhada de Pedras,ADM2,DISTRICT
SM267105,-36.9357,-10.3281,Malhada dos Bois,ADM2,DISTRICT
SM267106,-37.2691,-10.692,Malhador,ADM2,DISTRICT
SM302264,-117.6231,43.1934,Malheur,ADM2,DISTRICT
SM237233,152.214,-4.7183,Mali,LANGUAGE,LANGUAGE
SM274996,-12.0833,12.0833,Mali,ADM1:ADM2,DISTRICT
SM255491,142.54,-4.83,Mali,ETHNICITY,ETHNICITY
SM305505,16.736,46.2879,Mali Bukovec,ADM2,DISTRICT
SM275973,14.4729,44.5313,Mali Lošinj,ADM2,DISTRICT
SM10880,32.7654,34.8065,Malia,ADM2,DISTRICT
SM297834,125.2167,-8.9833,Maliana,ADM2,DISTRICT
SM13675,27.1543,57.3452,Malienas pagasts,ADM2,DISTRICT
SM237236,15.7421,-7.931,Maligo,LANGUAGE,LANGUAGE
SM237228,33.339,-9.1662,Malila,LANGUAGE,LANGUAGE
SM237229,9.6719,3.677,Malimba,LANGUAGE,LANGUAGE
SM237232,119.76,-3.6996,Malimpung,LANGUAGE,LANGUAGE
SM281936,-99.482,18.8904,Malinalco,ADM2,DISTRICT
SM14335,-98.7057,17.1782,Malinaltepec (2005-),ADM2,DISTRICT
SM237234,-98.6322,17.2122,Malinaltepec Me'phaa,LANGUAGE,LANGUAGE
SM276430,115.6833,2.45,Malinau,ADM2,DISTRICT
SM280050,39.84,-2.921,Malindi,ADM2,DISTRICT
SM291811,26.0149,47.4032,Malini,ADM2,DISTRICT
SM6021,-9,11,"Malinke, Maninka, Mandinka (excl. Kita)",ETHNICITY,ETHNICITY
SM13676,26.6714,55.9938,Malinovas pagasts,ADM2,DISTRICT
SM305415,14.5287,45.1228,Malinska-Dubašnica,ADM2,DISTRICT
SM458702,142.9529,-23.5345,Malintji,ETHNICITY,ETHNICITY
SM375492,67.3138,25.1459,Malir,ADM2:ADM3,DISTRICT
SM260290,67.2432,33.2984,Malistan,ADM2,DISTRICT
SM292057,29.0483,45.1833,Maliuc,ADM2,DISTRICT
SM304756,20.7467,42.4886,Mališevo,ADM2,DISTRICT
SM277120,81.95,18.25,Malkangiri,ADM2,DISTRICT
SM298942,26.9137,40.9005,Malkara,ADM2,DISTRICT
SM262401,27.5333,42.0833,Malko Tarnovo,ADM2,DISTRICT
SM260489,19.7735,40.5567,Mallakastrës,ADM2,DISTRICT
SM260489,19.7735,40.5567,Mallakastrës,ADM2,DISTRICT
SM260489,19.7833,40.55,Mallakastrës,ADM2,DISTRICT
SM260489,19.7833,40.55,Mallakastrës,ADM2,DISTRICT
SM261706,138.4278,-34.5104,Mallala,ADM2,DISTRICT
SM270330,-77.8469,1.1589,Mallama,ADM2,DISTRICT
SM269100,-72.1303,-38.2359,Malleco,ADM2,DISTRICT
SM264552,-50.8305,-25.9021,Mallet,ADM2,DISTRICT
SM293269,50.9167,56.5833,Malmyzhskiy rayon,ADM2,DISTRICT
SM296438,13.0187,55.5651,Malmö,ADM2,DISTRICT
SM13677,27.7665,56.7982,Malnavas pagasts,ADM2,DISTRICT
SM458626,129.3537,-17.2352,Malngin,ETHNICITY,ETHNICITY
SM255502,143.27,-13.19,Malnkanidji,ETHNICITY,ETHNICITY
SM293064,44.6833,47.9333,Maloderbetovskiy rayon,ADM2,DISTRICT
SM4101,171.05,8.75,Maloelap Atoll,ADM1,DISTRICT
SM255503,114.26,1.09,Maloh,ETHNICITY,ETHNICITY
SM268915,9.8314,46.4745,Maloja,ADM2,DISTRICT
SM16996,42.5,43.75,Malokarachayevskiy Rayon,ADM2,DISTRICT
SM237245,142.244,-3.117,Malol,LANGUAGE,LANGUAGE
SM294829,53.0348,56.5577,Malopurginskiy rayon,ADM2,DISTRICT
SM10881,33.1791,35.0447,Malounta,ADM2,DISTRICT
SM291085,22.7455,44.7216,Malovat,ADM2,DISTRICT
SM293818,32.5,58.75,Malovisherskiy rayon,ADM2,DISTRICT
SM299527,31.6326,48.6464,Malovyskivs'kyi,ADM2,DISTRICT
SM293074,36.5,54.9167,Maloyaroslavetskiy rayon,ADM2,DISTRICT
SM4008,24.9731,56.9944,Malpils Novads,ADM1,DISTRICT
SM13678,24.9726,56.9938,Malpils novads,ADM2,DISTRICT
SM264233,-37.5125,-6.904,Malta,ADM2,DISTRICT
SM255504,14.47,35.89,Malta,ETHNICITY,ETHNICITY
SM255504,14.47,35.89,Malta,ETHNICITY,ETHNICITY
SM255504,14.47,35.89,Malta,ETHNICITY,ETHNICITY
SM255504,14.367,36.1665,Malta,ETHNICITY,ETHNICITY
SM255504,14.367,36.1665,Malta,ETHNICITY,ETHNICITY
SM255504,14.367,36.1665,Malta,ETHNICITY,ETHNICITY
SM255504,14.38,35.94,Malta,ETHNICITY,ETHNICITY
SM255504,14.38,35.94,Malta,ETHNICITY,ETHNICITY
SM255504,14.38,35.94,Malta,ETHNICITY,ETHNICITY
SM13679,27.1875,56.315,Maltas pagasts,ADM2,DISTRICT
SM298511,29.1787,40.9589,Maltepe,ADM2,DISTRICT
SM237251,14.4508,35.8884,Maltese,LANGUAGE,LANGUAGE
SM237248,14.4824,35.907,Maltese Sign Language,LANGUAGE,LANGUAGE
SM282732,-97.2732,18.8383,Maltrata,ADM2,DISTRICT
SM290281,25.2259,45.1562,Malu Cu Flori,ADM2,DISTRICT
SM295433,160.646,-8.3574,Malu'u,ADM2,DISTRICT
SM237253,167.208,-15.9813,Malua Bay,LANGUAGE,LANGUAGE
SM2119,130.1453,-3.2385,Maluku (1999-),ADM1,DISTRICT
SM276468,126.1739,-7.8296,Maluku Barat Daya,ADM2,DISTRICT
SM276469,128.9675,-3.2917,Maluku Tengah,ADM2,DISTRICT
SM276471,132.7333,-5.75,Maluku Tenggara,ADM2,DISTRICT
SM276470,131.38,-7.6119,Maluku Tenggara Barat,ADM2,DISTRICT
SM2120,127.8424,0.6262,Maluku Utara,ADM1,DISTRICT
SM284914,7.656,11.8592,Malumfashi,ADM2,DISTRICT
SM296555,13.6667,60.5667,Malung,ADM2,DISTRICT
SM13680,27.2889,57.3175,Malupes pagasts,ADM2,DISTRICT
SM291256,24.7788,45.0478,Malureni,ADM2,DISTRICT
SM292293,27.9155,46.1759,Malusteni,ADM2,DISTRICT
SM296034,32.4915,10.6427,Malut,ADM2,DISTRICT
SM12507,-77.6939,17.9703,Malvern,ADM2,DISTRICT
SM237259,75.562,23.488,Malvi,LANGUAGE,LANGUAGE
SM286204,10.7938,63.4106,Malvik,ADM2,DISTRICT
SM237262,142,-29.43,Malyangapa,DIALECT,LANGUAGE
SM458712,141.5673,-30.5562,Malyangapa,ETHNICITY,ETHNICITY
SM18245,29.1993,50.8308,Malyn Raion (excl. Malyn city),ADM2,DISTRICT
SM18246,29.2322,50.7744,Malynska Miskrada,ADM2,DISTRICT
SM296529,18.75,65.2,Malå,ADM2,DISTRICT
SM295275,-15.2371,14.386,Malème Hodar,ADM2,DISTRICT
SM237218,-84.7318,10.7485,Maléku Jaíka,LANGUAGE,LANGUAGE
SM260470,19.5898,42.368,Malësi e Madhe,ADM2,DISTRICT
SM260470,19.5898,42.368,Malësi e Madhe,ADM2,DISTRICT
SM260470,19.5833,42.3333,Malësi e Madhe,ADM2,DISTRICT
SM260470,19.5833,42.3333,Malësi e Madhe,ADM2,DISTRICT
SM237294,-91.7231,14.8034,Mam,LANGUAGE,LANGUAGE
SM255507,-91.23,15.85,Mam,ETHNICITY,ETHNICITY
SM255507,-91.23,15.85,Mam,ETHNICITY,ETHNICITY
SM255507,-91.23,15.85,Mam,ETHNICITY,ETHNICITY
SM255507,-84.53,15.07,Mam,ETHNICITY,ETHNICITY
SM255507,-84.53,15.07,Mam,ETHNICITY,ETHNICITY
SM255507,-84.53,15.07,Mam,ETHNICITY,ETHNICITY
SM255507,-91.75,15.5,Mam,ETHNICITY,ETHNICITY
SM255507,-91.75,15.5,Mam,ETHNICITY,ETHNICITY
SM255507,-91.75,15.5,Mam,ETHNICITY,ETHNICITY
SM255507,-92,15,Mam,ETHNICITY,ETHNICITY
SM255507,-92,15,Mam,ETHNICITY,ETHNICITY
SM255507,-92,15,Mam,ETHNICITY,ETHNICITY
SM237266,8.786,8.8062,Mama,LANGUAGE,LANGUAGE
SM282979,-89.3769,20.4967,Mama,ADM2,DISTRICT
SM237271,146.576,-6.3556,Mamaa,LANGUAGE,LANGUAGE
SM294620,51.25,55.6667,Mamadyshskiy rayon,ADM2,DISTRICT
SM237272,-60.1011,-12.9864,Mamaindé,LANGUAGE,LANGUAGE
SM298892,32.9081,39.9459,Mamak,ADM2,DISTRICT
SM264235,-35.151,-6.7065,Mamanguape,ADM2,DISTRICT
SM255509,125.82,9.25,Mamanua,ETHNICITY,ETHNICITY
SM255509,125.82,9.25,Mamanua,ETHNICITY,ETHNICITY
SM255509,125.51,9.76,Mamanua,ETHNICITY,ETHNICITY
SM255509,125.51,9.76,Mamanua,ETHNICITY,ETHNICITY
SM237269,125.548,9.4474,Mamanwa,LANGUAGE,LANGUAGE
SM237265,-5.2825,12.4871,Mamara Senoufo,LANGUAGE,LANGUAGE
SM237270,119.484,-3.0811,Mamasa,LANGUAGE,LANGUAGE
SM276566,119.3063,-2.9649,Mamasa,ADM2,DISTRICT
SM11783,51.3833,30.0333,Mamasani,ADM2,DISTRICT
SM255510,51.77,30.89,Mamassani,ETHNICITY,ETHNICITY
SM13980,27.8422,-29.1065,Mamathe,ADM2,DISTRICT
SM237284,125.475,-9.1955,Mambae,LANGUAGE,LANGUAGE
SM280993,-10.519,6.2542,Mambah-Kaba,ADM2,DISTRICT
SM280993,-10.519,6.2542,Mambah-Kaba,ADM2,DISTRICT
SM280993,-10.5103,6.2665,Mambah-Kaba,ADM2,DISTRICT
SM280993,-10.5103,6.2665,Mambah-Kaba,ADM2,DISTRICT
SM237275,14.0013,9.6121,Mambai,LANGUAGE,LANGUAGE
SM458673,141.763,-28.6017,Mambangurra,ETHNICITY,ETHNICITY
SM268081,-46.0581,-14.4422,Mambaí,ADM2,DISTRICT
SM276528,137.7823,-2.2356,Mamberamo Raya,ADM2,DISTRICT
SM276529,139.0648,-3.6273,Mamberamo Tengah,ADM2,DISTRICT
SM15888,-65.8101,18.1974,Mambiche Barrio,ADM2,DISTRICT
SM250142,12,7,Mambila  Cameroonian,ETHNICITY,ETHNICITY
SM237283,119.55,-9.423,Mamboru,LANGUAGE,LANGUAGE
SM264553,-52.6169,-24.4061,Mamborê,ADM2,DISTRICT
SM305175,31.9287,-13.3421,Mambwe,ADM2,DISTRICT
SM251040,31.54,-10.24,Mambwe,ETHNICITY,ETHNICITY
SM251040,31.54,-10.24,Mambwe,ETHNICITY,ETHNICITY
SM251040,31.54,-10.24,Mambwe,ETHNICITY,ETHNICITY
SM251040,32,-9,Mambwe,ETHNICITY,ETHNICITY
SM251040,32,-9,Mambwe,ETHNICITY,ETHNICITY
SM251040,32,-9,Mambwe,ETHNICITY,ETHNICITY
SM251040,32,9,Mambwe,ETHNICITY,ETHNICITY
SM251040,32,9,Mambwe,ETHNICITY,ETHNICITY
SM251040,32,9,Mambwe,ETHNICITY,ETHNICITY
SM237277,31.2269,-8.8682,Mambwe-Lungu,LANGUAGE,LANGUAGE
SM2121,16,4.5,Mambéré-Kadéï,ADM1,DISTRICT
SM296790,14.7053,13.4741,Mamdi,ADM2,DISTRICT
SM370503,6.0233,49.6275,Mamer,ADM1:ADM2:ADM3,DISTRICT
SM15889,-67.1386,18.3765,Mamey Barrio (Aguada),ADM2,DISTRICT
SM15890,-66.1157,18.3143,Mamey Barrio (Guaynabo),ADM2,DISTRICT
SM15891,-65.9566,18.2444,Mamey Barrio (Gurabo),ADM2,DISTRICT
SM15892,-65.9344,18.2398,Mamey Barrio (Juncos),ADM2,DISTRICT
SM15893,-66.0026,18.0146,Mamey Barrio (Patillas),ADM2,DISTRICT
SM15894,-66.2366,18.4637,Mameyal Barrio,ADM2,DISTRICT
SM15895,-66.5935,18.3028,Mameyes Abajo Barrio,ADM2,DISTRICT
SM15896,-66.5778,18.2625,Mameyes Arriba Barrio,ADM2,DISTRICT
SM15897,-65.7534,18.3579,Mameyes I Barrio,ADM2,DISTRICT
SM15898,-65.7676,18.3334,Mameyes II Barrio,ADM2,DISTRICT
SM17497,-60.9018,13.8674,Mamiku,ADM2,DISTRICT
SM277092,92.4694,23.7849,Mamit,ADM2,DISTRICT
SM279979,68.25,54.8333,Mamlyutskiy,ADM2,DISTRICT
SM10882,33.2039,35.1867,Mammari,ADM2,DISTRICT
SM263619,-42.9451,-15.033,Mamonas,ADM2,DISTRICT
SM10883,32.627,34.7682,Mamonia,ADM2,DISTRICT
SM292975,81.75,52.6667,Mamontovskiy rayon,ADM2,DISTRICT
SM262712,-64.6812,-13.1537,Mamoré,ADM2,DISTRICT
SM274999,-11.815,10.459,Mamou,ADM1:ADM2,DISTRICT
SM2122,-12.2944,10.6944,Mamou,ADM1,DISTRICT
SM2123,45.2283,-12.7797,Mamoudzou,ADM1,DISTRICT
SM10884,32.6039,34.9139,Mamountali,ADM2,DISTRICT
SM371008,47.6733,-16.1617,Mampikony,ADM2:ADM3,DISTRICT
SM265747,-50.0107,-29.2615,Mampituba,ADM2,DISTRICT
SM11421,-1.4,7.0667,Mampong,ADM2,DISTRICT
SM4688,-1.2818,10.233,Mamprugu-Moagduri,ADM2,DISTRICT
SM237298,-0.7468,10.3884,Mampruli,LANGUAGE,LANGUAGE
SM250471,-0.1,10.43,Mampruli,ETHNICITY,ETHNICITY
SM250471,-0.1,10.43,Mampruli,ETHNICITY,ETHNICITY
SM250471,-1,10,Mampruli,ETHNICITY,ETHNICITY
SM250471,-1,10,Mampruli,ETHNICITY,ETHNICITY
SM292894,112.5,58,Mamsko-Chuyskiy rayon,ADM2,DISTRICT
SM255513,145.84,-17.64,Mamu,ETHNICITY,ETHNICITY
SM255513,145.84,-17.64,Mamu,ETHNICITY,ETHNICITY
SM255513,145.7485,-17.64,Mamu,ETHNICITY,ETHNICITY
SM255513,145.7485,-17.64,Mamu,ETHNICITY,ETHNICITY
SM237306,119.082,-2.5076,Mamuju,LANGUAGE,LANGUAGE
SM276567,119.4214,-1.5164,Mamuju Utara,ADM2,DISTRICT
SM237305,151.033,-5.8478,Mamusi,LANGUAGE,LANGUAGE
SM461498,151.1,-5.867,Mamusi,ETHNICITY,ETHNICITY
SM461498,151.1,-5.867,Mamusi,ETHNICITY,ETHNICITY
SM461498,151.1,-5.867,Mamusi,ETHNICITY,ETHNICITY
SM461498,151.0833,-5.7333,Mamusi,ETHNICITY,ETHNICITY
SM461498,151.0833,-5.7333,Mamusi,ETHNICITY,ETHNICITY
SM461498,151.0833,-5.7333,Mamusi,ETHNICITY,ETHNICITY
SM461498,150.9,-5.95,Mamusi,ETHNICITY,ETHNICITY
SM461498,150.9,-5.95,Mamusi,ETHNICITY,ETHNICITY
SM461498,150.9,-5.95,Mamusi,ETHNICITY,ETHNICITY
SM237309,28.9862,2.6817,Mamvu,LANGUAGE,LANGUAGE
SM250472,29,3,Mamvu,ETHNICITY,ETHNICITY
SM255514,105,22,Man,ETHNICITY,ETHNICITY
SM237458,100.842,22.0743,Man Met,LANGUAGE,LANGUAGE
SM305073,50.9879,16.5505,Man'ar,ADM2,DISTRICT
SM15264,125.65,39.0333,Man'gyongdae,ADM2,DISTRICT
SM299427,30.3366,48.9638,Man'kivs'kyi,ADM2,DISTRICT
SM15899,-66.314,18.2637,Mana Barrio,ADM2,DISTRICT
SM2124,-80.0833,-0.6667,Manabi,ADM1,DISTRICT
SM255518,126,8,Manabo,ETHNICITY,ETHNICITY
SM267332,-61.0079,-3.2475,Manacapuru,ADM2,DISTRICT
SM276631,124.8833,1.5167,Manado,ADM2,DISTRICT
SM237174,124.843,1.481,Manado Malay,LANGUAGE,LANGUAGE
SM4753,34.3333,0.8833,Manafwa (2017-),ADM1:ADM2,DISTRICT
SM2125,-86.4167,12,Managua,ADM1,DISTRICT
SM370986,47.8499,-21.9336,Manakara-Sud,ADM2:ADM3,DISTRICT
SM304938,43.6862,15.0717,Manakhah,ADM2,DISTRICT
SM237319,145.033,-4.0792,Manam,LANGUAGE,LANGUAGE
SM260542,56.0026,25.3257,Manama,ADM2,DISTRICT
SM255519,145.03,-4.08,Manama,ETHNICITY,ETHNICITY
SM237321,142.862,-4.1908,Manambu,LANGUAGE,LANGUAGE
SM371017,49.5483,-16.216,Mananara,ADM2:ADM3,DISTRICT
SM370970,47.0108,-20.6947,Manandriana,ADM2:ADM3,DISTRICT
SM375358,84.1691,28.6728,Manang,ADM2:ADM3,DISTRICT
SM237312,84.0515,28.6416,Manange,LANGUAGE,LANGUAGE
SM237310,132.352,-11.361,Manangkari,LANGUAGE,LANGUAGE
SM458827,133.1725,-11.5555,Manangkarri,ETHNICITY,ETHNICITY
SM370987,48.0866,-21.1979,Mananjary,ADM2:ADM3,DISTRICT
SM237322,-60.1071,-3.1992,Manao,LANGUAGE,LANGUAGE
SM237322,-60.1071,-3.1992,Manao,LANGUAGE,LANGUAGE
SM255520,-63.31,-1.18,Manao,ETHNICITY,ETHNICITY
SM237322,-64.5186,-0.5401,Manao,LANGUAGE,LANGUAGE
SM237322,-64.5186,-0.5401,Manao,LANGUAGE,LANGUAGE
SM303559,-65.5444,5.0745,Manapiare,ADM2,DISTRICT
SM267333,-60.7072,-3.7905,Manaquiri,ADM2,DISTRICT
SM255521,84.49,26.55,Manar,ETHNICITY,ETHNICITY
SM264850,-37.5876,-8.8818,Manari,ADM2,DISTRICT
SM280341,71.6,42.7231,Manas,ADM2,DISTRICT
SM255522,-62.75,-16.01,Manasi,ETHNICITY,ETHNICITY
SM290919,26.6735,44.7065,Manasia,ADM2,DISTRICT
SM303044,-77.4753,38.751,Manassas,ADM2,DISTRICT
SM303045,-77.4697,38.784,Manassas Park,ADM2,DISTRICT
SM289960,26.8794,44.2395,Manastirea,ADM2,DISTRICT
SM292125,26.695,46.1438,Manastirea Casin,ADM2,DISTRICT
SM291812,25.8221,47.6284,Manastirea Humorului,ADM2,DISTRICT
SM290110,23.0987,46.7913,Manastireni,ADM2,DISTRICT
SM291996,22.0533,45.8681,Manastiur,ADM2,DISTRICT
SM242314,144.879,-4.943,Manat,LANGUAGE,LANGUAGE
SM300347,-82.3575,27.4775,Manatee,ADM2,DISTRICT
SM15900,-66.4863,18.431,Manati Barrio-Pueblo,ADM2,DISTRICT
SM297804,126.0152,-8.5221,Manatuto,ADM2,DISTRICT
SM2127,125.95,-8.7167,Manatuto,ADM1,DISTRICT
SM270998,-76.9378,21.3144,Manatí,ADM2,DISTRICT
SM270680,-74.9822,10.464,Manatí,ADM2,DISTRICT
SM2126,-66.4927,18.4188,Manatí,ADM1,DISTRICT
SM269895,-72.931,10.4014,Manaure,ADM2,DISTRICT
SM270122,-72.5652,11.5817,Manaure,ADM2,DISTRICT
SM9960,-60.0128,-3.0436,Manaus,ADM2,DISTRICT
SM299014,31.4629,36.7901,Manavgat,ADM2,DISTRICT
SM286553,175.6743,-40.1198,Manawatu,ADM2,DISTRICT
SM2128,175.0167,-39.95,Manawatu-Wanganui,ADM1,DISTRICT
SM264236,-38.2295,-7.7025,Manaíra,ADM2,DISTRICT
SM10317,15.5311,9.1681,Manbague,ADM2,DISTRICT
SM296764,37.9174,36.0669,Manbij,ADM2,DISTRICT
SM296978,102.5197,16.2124,Mancha Khiri,ADM2,DISTRICT
SM274514,-1.3187,49.0382,Manche,ADM2,DISTRICT
SM11669,79.4897,19.0736,Mancherial,ADM2,DISTRICT
SM274652,-2.25,53.4167,Manchester,ADM1:ADM2,DISTRICT
SM2129,-77.5333,18.05,Manchester,ADM1,DISTRICT
SM12508,-76.301,18.046,Manchioneal,ADM2,DISTRICT
SM237330,126.557,47.3122,Manchu,LANGUAGE,LANGUAGE
SM255525,120,41.38,Manchu,ETHNICITY,ETHNICITY
SM255525,120,41.38,Manchu,ETHNICITY,ETHNICITY
SM255525,120,41.38,Manchu,ETHNICITY,ETHNICITY
SM255525,125.5,50,Manchu,ETHNICITY,ETHNICITY
SM255525,125.5,50,Manchu,ETHNICITY,ETHNICITY
SM255525,125.5,50,Manchu,ETHNICITY,ETHNICITY
SM255525,126,45,Manchu,ETHNICITY,ETHNICITY
SM255525,126,45,Manchu,ETHNICITY,ETHNICITY
SM255525,126,45,Manchu,ETHNICITY,ETHNICITY
SM262720,-69.0008,-16.1668,Manco Kapac,ADM2,DISTRICT
SM225905,144.806,-5.0814,Mand,LANGUAGE,LANGUAGE
SM255526,32.97,-7.61,Manda,ETHNICITY,ETHNICITY
SM237339,129.885,-13.6481,Manda (Australia),LANGUAGE,LANGUAGE
SM237336,83.6749,20.0962,Manda (India),LANGUAGE,LANGUAGE
SM237346,34.7424,-10.3991,Manda-Matumba,LANGUAGE,LANGUAGE
SM264557,-51.7236,-23.4881,Mandaguari,ADM2,DISTRICT
SM264556,-52.0298,-23.2644,Mandaguaçu,ADM2,DISTRICT
SM237368,-66.7231,1.0664,Mandahuaca,LANGUAGE,LANGUAGE
SM237368,-66.7231,1.0664,Mandahuaca,LANGUAGE,LANGUAGE
SM237368,-66.52,1.48,Mandahuaca,LANGUAGE,LANGUAGE
SM237368,-66.52,1.48,Mandahuaca,LANGUAGE,LANGUAGE
SM241098,48.8128,31.3443,Mandaic,LANGUAGE,LANGUAGE
SM276681,99.2549,0.7838,Mandailing Natal,ADM2,DISTRICT
SM255528,151.7,-3.02,Mandak,ETHNICITY,ETHNICITY
SM255528,151.7,-3.02,Mandak,ETHNICITY,ETHNICITY
SM255528,151.7,-3.1,Mandak,ETHNICITY,ETHNICITY
SM255528,151.7,-3.1,Mandak,ETHNICITY,ETHNICITY
SM283810,108.25,44.3943,Mandakh,ADM2,DISTRICT
SM283669,106.8788,48.8458,Mandal,ADM2,DISTRICT
SM283669,106.8788,48.8458,Mandal,ADM2,DISTRICT
SM283669,106.4575,48.8412,Mandal,ADM2,DISTRICT
SM283669,106.4575,48.8412,Mandal,ADM2,DISTRICT
SM283634,104.05,44.6426,Mandal-Ovoo,ADM2,DISTRICT
SM283515,96.1235,21.9494,Mandalay,ADM2,DISTRICT
SM2130,96,21,Mandalay (2008-),ADM1,DISTRICT
SM259522,96.1078,21.9358,Mandalay Myanmar Sign Language,LANGUAGE,LANGUAGE
SM458434,149.3824,-24.466,Mandalgu,ETHNICITY,ETHNICITY
SM295434,160.7109,-8.4671,Mandalua - Folotana,ADM2,DISTRICT
SM237366,-102.376,47.6631,Mandan,LANGUAGE,LANGUAGE
SM255531,-100.49,46.81,Mandan,ETHNICITY,ETHNICITY
SM255531,-100.49,46.81,Mandan,ETHNICITY,ETHNICITY
SM255531,-101,47,Mandan,ETHNICITY,ETHNICITY
SM255531,-101,47,Mandan,ETHNICITY,ETHNICITY
SM237340,148.978,-26.573,Mandandanyi,LANGUAGE,LANGUAGE
SM237362,119.068,-3.3472,Mandar,LANGUAGE,LANGUAGE
SM237361,151.956,-2.7828,Mandara,LANGUAGE,LANGUAGE
SM458105,119.2811,-23.3644,Mandara,ETHNICITY,ETHNICITY
SM237347,31.2327,5.8366,Mandari,LANGUAGE,LANGUAGE
SM237338,116.228,40.0209,Mandarin Chinese,LANGUAGE,LANGUAGE
SM234066,126.409,7.5048,Mandaya,LANGUAGE,LANGUAGE
SM255532,126.2,7.58,Mandaya,ETHNICITY,ETHNICITY
SM237332,77.0419,31.5425,Mandeali,LANGUAGE,LANGUAGE
SM17498,-60.885,13.8955,Mandele,ADM2,DISTRICT
SM237363,138.751,-2.2948,Mander,LANGUAGE,LANGUAGE
SM2131,40.7,3.3667,Mandera,ADM1:ADM2,DISTRICT
SM12509,-77.5057,18.0445,Mandeville Proper,ADM2,DISTRICT
SM276875,77,31.75,Mandi,ADM2,DISTRICT
SM274986,-8.615,10.658,Mandiana,ADM1:ADM2,DISTRICT
SM283961,35.9044,-14.2039,Mandimba,ADM2,DISTRICT
SM237357,-15.654,12.8165,Mandinka,LANGUAGE,LANGUAGE
SM250907,-12,12,"Mandinka (Senegal, Gambia)",ETHNICITY,ETHNICITY
SM250907,-12,12,"Mandinka (Senegal, Gambia)",ETHNICITY,ETHNICITY
SM250907,-12,12,"Mandinka (Senegal, Gambia)",ETHNICITY,ETHNICITY
SM250907,-12,12,"Mandinka (Senegal, Gambia)",ETHNICITY,ETHNICITY
SM264558,-49.327,-25.8468,Mandirituba,ADM2,DISTRICT
SM237342,-15.9217,12.829,Mandjak,LANGUAGE,LANGUAGE
SM250481,19.28,6.93,Mandjia,ETHNICITY,ETHNICITY
SM250481,19.28,6.93,Mandjia,ETHNICITY,ETHNICITY
SM250481,18,6,Mandjia,ETHNICITY,ETHNICITY
SM250481,18,6,Mandjia,ETHNICITY,ETHNICITY
SM255534,124.15,-20.75,Mandjildjara,ETHNICITY,ETHNICITY
SM255534,124.15,-20.75,Mandjildjara,ETHNICITY,ETHNICITY
SM255534,124.9062,-22.5738,Mandjildjara,ETHNICITY,ETHNICITY
SM255534,124.9062,-22.5738,Mandjildjara,ETHNICITY,ETHNICITY
SM255535,125.92,-28.04,Mandjindja,ETHNICITY,ETHNICITY
SM255535,125.92,-28.04,Mandjindja,ETHNICITY,ETHNICITY
SM255535,126.0846,-27.2539,Mandjindja,ETHNICITY,ETHNICITY
SM255535,126.0846,-27.2539,Mandjindja,ETHNICITY,ETHNICITY
SM276987,80.5134,22.6404,Mandla (1998-),ADM2,DISTRICT
SM283901,33.9922,-24.5937,Mandlakazi,ADM2,DISTRICT
SM260074,69.7801,33.3166,Mando Zayi,ADM2,DISTRICT
SM237364,140.624,-5.6936,Mandobo Atas,LANGUAGE,LANGUAGE
SM237365,140.451,-6.3726,Mandobo Bawah,LANGUAGE,LANGUAGE
SM260142,70.2476,35.4014,Mandol,ADM2,DISTRICT
SM14171,46.1977,-19.6827,Mandoto,ADM2,DISTRICT
SM2132,17.9157,8.3294,Mandoul,ADM1,DISTRICT
SM296800,17.31,8.66,Mandoul Occidental,ADM2,DISTRICT
SM10318,17.6307,9.174,"Mandoul Oriental (excl. Koumra, Bedaya, Bessada)",ADM2,DISTRICT
SM292637,25.0326,45.8122,Mandra,ADM2,DISTRICT
SM18520,33.8122,35.3526,Mandres (Famagusta),ADM2,DISTRICT
SM10885,33.3845,35.2249,Mandres (Nicosia),ADM2,DISTRICT
SM18519,32.8367,34.8678,Mandria (Limassol),ADM2,DISTRICT
SM10886,32.5373,34.7121,Mandria (Paphos),ADM2,DISTRICT
SM371009,48.7165,-15.9879,Mandritsara,ADM2:ADM3,DISTRICT
SM276988,75.25,24.3333,Mandsaur (1998-),ADM2,DISTRICT
SM261407,115.6654,-32.5784,Mandurah,ADM2,DISTRICT
SM266680,-49.3026,-23.0539,Manduri,ADM2,DISTRICT
SM276937,76.6667,12.5833,Mandya,ADM2,DISTRICT
SM291543,25.9855,45.3371,Maneciu-ungureni,ADM2,DISTRICT
SM303677,-63.8333,11,Maneiro,ADM2,DISTRICT
SM237383,141.02,-2.9915,Manem,LANGUAGE,LANGUAGE
SM6023,-9.99,7.98,Manenkan,ETHNICITY,ETHNICITY
SM6023,-9.99,7.98,Manenkan,ETHNICITY,ETHNICITY
SM6023,-9,11,Manenkan,ETHNICITY,ETHNICITY
SM6023,-9,11,Manenkan,ETHNICITY,ETHNICITY
SM290282,25.3003,44.9503,Manesti,ADM2,DISTRICT
SM264559,-53.3583,-26.1001,Manfrinópolis,ADM2,DISTRICT
SM237389,102.133,21.4305,Mang,LANGUAGE,LANGUAGE
SM304572,106.0747,10.1873,Mang Thít,ADM2,DISTRICT
SM304051,108.3025,13.9284,Mang Yang,ADM2,DISTRICT
SM10319,17.0311,9.4698,Manga,ADM2,DISTRICT
SM263620,-44.1262,-14.6587,Manga,ADM2,DISTRICT
SM237408,10.701,13.557,Manga Kanuri,LANGUAGE,LANGUAGE
SM4236,-157.9185,-21.926,Mangaia,ADM1,DISTRICT
SM237393,123.769,-18.6597,Mangala,LANGUAGE,LANGUAGE
SM458078,123.9677,-19.6399,Mangala,ETHNICITY,ETHNICITY
SM290174,28.5978,43.8509,Mangalia,ADM2,DISTRICT
SM296838,19.6084,12.5365,Mangalmé,ADM2,DISTRICT
SM237422,8.7361,9.068,Mangar,LANGUAGE,LANGUAGE
SM265280,-44.1206,-22.9438,Mangaratiba,ADM2,DISTRICT
SM237410,-134.97,-23.1095,Mangareva,LANGUAGE,LANGUAGE
SM255543,-134.98,-23.13,Mangareva,ETHNICITY,ETHNICITY
SM255543,-134.98,-23.13,Mangareva,ETHNICITY,ETHNICITY
SM255543,-134.97,-23.12,Mangareva,ETHNICITY,ETHNICITY
SM255543,-134.97,-23.12,Mangareva,ETHNICITY,ETHNICITY
SM255545,-158.03,-9.02,Mangarongaro,ETHNICITY,ETHNICITY
SM237391,133.5,-14.8,Mangarrayi,LANGUAGE,LANGUAGE
SM255546,133.44,-14.53,Mangarrayi,ETHNICITY,ETHNICITY
SM255546,133.44,-14.53,Mangarrayi,ETHNICITY,ETHNICITY
SM255546,133.6192,-14.8912,Mangarrayi,ETHNICITY,ETHNICITY
SM255546,133.6192,-14.8912,Mangarrayi,ETHNICITY,ETHNICITY
SM237421,9.761,10.0839,Mangas,LANGUAGE,LANGUAGE
SM305106,26.3512,-29.1563,Mangaung,ADM2,DISTRICT
SM237397,25.9024,8.3802,Mangayat,LANGUAGE,LANGUAGE
SM237404,27.0712,2.5511,Mangbetu,LANGUAGE,LANGUAGE
SM250476,28.79,3.8,Mangbetu,ETHNICITY,ETHNICITY
SM250476,28.79,3.8,Mangbetu,ETHNICITY,ETHNICITY
SM250476,28,3,Mangbetu,ETHNICITY,ETHNICITY
SM250476,28,3,Mangbetu,ETHNICITY,ETHNICITY
SM237405,29.7358,2.7478,Mangbutu,LANGUAGE,LANGUAGE
SM296713,30.9969,-26.5835,Mangcongco,ADM2,DISTRICT
SM237392,132.973,-12.2391,Mangerr,LANGUAGE,LANGUAGE
SM237413,146.685,-6.9989,Mangga Buang,LANGUAGE,LANGUAGE
SM237414,120.329,-8.5575,Manggarai,LANGUAGE,LANGUAGE
SM276493,120.4167,-8.5667,Manggarai,ADM2,DISTRICT
SM255548,120.14,-8.69,Manggarai,ETHNICITY,ETHNICITY
SM276511,119.8828,-8.6448,Manggarai Barat,ADM2,DISTRICT
SM276512,120.5976,-8.5553,Manggarai Timur,ADM2,DISTRICT
SM2133,54,44,Mangghystau,ADM1:ADMX,DISTRICT
SM279968,53.3333,44.5,Manghystauskiy,ADM2,DISTRICT
SM2134,144.8007,13.4477,Mangilao,ADM1,DISTRICT
SM237407,16.9082,8.7588,Mango,LANGUAGE,LANGUAGE
SM237429,105.4314,23.7423,Mango (China),LANGUAGE,LANGUAGE
SM12510,-76.9769,18.3748,Mango Valley,ADM2,DISTRICT
SM2135,35.3466,-14.393,Mangochi,ADM1:ADM2,DISTRICT
SM237417,125.822,-1.8794,Mangole,LANGUAGE,LANGUAGE
SM2136,-77.7803,24.16,Mangrove Cay,ADM1,DISTRICT
SM237412,150.68,-5.8975,Mangseng,LANGUAGE,LANGUAGE
SM461499,150.7,-5.933,Mangseng,ETHNICITY,ETHNICITY
SM285181,9.1797,9.3905,Mangu,ADM2,DISTRICT
SM458057,119.775,-25.5799,Mangu,ETHNICITY,ETHNICITY
SM289415,-7.7173,40.5956,Mangualde,ADM2,DISTRICT
SM238641,-85.1746,13.5343,Mangue,LANGUAGE,LANGUAGE
SM264560,-52.2226,-26.0365,Mangueirinha,ADM2,DISTRICT
SM275747,-86.83,15.0833,Mangulile,ADM2,DISTRICT
SM305277,27.9829,-20.9555,Mangwe (South),ADM2,DISTRICT
SM15068,32.8423,-25.3404,Manhica (excl. Manhica city),ADM2,DISTRICT
SM263621,-42.0865,-20.1865,Manhuaçu,ADM2,DISTRICT
SM263622,-41.9356,-20.3475,Manhumirim,ADM2,DISTRICT
SM284915,7.9257,12.8782,Mani,ADM2,DISTRICT
SM2137,33.5,-19,Manica,ADM1,DISTRICT
SM2138,32.4167,-19,Manicaland,ADM1,DISTRICT
SM271064,-79.9758,22.1464,Manicaragua,ADM2,DISTRICT
SM267334,-61.557,-7.0664,Manicore,ADM2,DISTRICT
SM2139,28.6593,-1.1723,Maniema,ADM1:ADM2,DISTRICT
SM2139,28.6593,-1.1723,Maniema,ADM1:ADM2,DISTRICT
SM2139,28.6593,-1.1723,Maniema,ADM1:ADM2,DISTRICT
SM2139,26.4886,-3.3484,Maniema,ADM1:ADM2,DISTRICT
SM2139,26.4886,-3.3484,Maniema,ADM1:ADM2,DISTRICT
SM2139,26.4886,-3.3484,Maniema,ADM1:ADM2,DISTRICT
SM2139,26,-3,Maniema,ADM1:ADM2,DISTRICT
SM2139,26,-3,Maniema,ADM1:ADM2,DISTRICT
SM2139,26,-3,Maniema,ADM1:ADM2,DISTRICT
SM237440,1.6952,8.8334,Manigri-Kambolé Ede Nago,LANGUAGE,LANGUAGE
SM11247,-146.0258,-14.4311,Manihi,ADM2,DISTRICT
SM4237,-160.9799,-10.426,Manihiki,ADM1,DISTRICT
SM262256,89.95,23.8667,Manikganj,ADM2,DISTRICT
SM255553,133.97,-1.83,Manikons,ETHNICITY,ETHNICITY
SM287826,120.9899,14.5982,Manila,ADM2,DISTRICT
SM237441,127.565,-3.3034,Manipa,LANGUAGE,LANGUAGE
SM255554,-74.88,3.13,Manipos,ETHNICITY,ETHNICITY
SM2140,94,25,Manipur,ADM1,DISTRICT
SM237438,93.3426,24.4409,Manipuri,LANGUAGE,LANGUAGE
SM246878,100.102,6.8814,Maniq,LANGUAGE,LANGUAGE
SM2141,28.1667,38.8333,Manisa,ADM1,DISTRICT
SM301301,-86.288,44.2383,Manistee,ADM2,DISTRICT
SM2142,-97.0005,55.0002,Manitoba,ADM1,DISTRICT
SM268864,-82.4999,45.7501,Manitoulin,ADM2,DISTRICT
SM303252,-87.6997,44.0905,Manitowoc,ADM2,DISTRICT
SM279022,133.6936,35.1355,Maniwa,ADM2,DISTRICT
SM270863,-75.5,5.0833,Manizales,ADM2,DISTRICT
SM371046,44.182,-21.436,Manja,ADM2:ADM3,DISTRICT
SM370946,47.7596,-18.8376,Manjakandriana,ADM2:ADM3,DISTRICT
SM261408,116.2657,-34.5979,Manjimup,ADM2,DISTRICT
SM299341,34.4,1.05,Manjiya,ADM1:ADM2,DISTRICT
SM237451,-15.7439,12.2615,Mankanya,LANGUAGE,LANGUAGE
SM283581,92.2242,47.4197,Mankhan,ADM2,DISTRICT
SM237456,73.2505,34.2922,Mankiyali,LANGUAGE,LANGUAGE
SM283621,106.8702,44.074,Manlai,ADM2,DISTRICT
SM12511,-76.7712,17.9691,Manley Meadows,ADM2,DISTRICT
SM282733,-96.3411,19.1003,Manlio Fabio Altamirano,ADM2,DISTRICT
SM237462,-8.8641,7.0462,Mann,LANGUAGE,LANGUAGE
SM237459,83.1908,18.2775,Manna-Dora,DIALECT,LANGUAGE
SM237460,76.97,9.85,Mannan,LANGUAGE,LANGUAGE
SM2143,80.0833,8.8667,Mannar,ADM1:ADM2,DISTRICT
SM324170,-117.6255,56.9257,Manning,ADM2:ADM3,DISTRICT
SM261250,145.1805,-37.7571,Manningham,ADM2,DISTRICT
SM12512,-76.8129,18.0705,Mannings Hill,ADM2,DISTRICT
SM12513,-76.8233,18.0817,Mannings Hill (Rural),ADM2,DISTRICT
SM323769,-111.1775,53.3376,Mannville,ADM2:ADM3,DISTRICT
SM265051,-43.8336,-8.1647,Manoel Emídio,ADM2,DISTRICT
SM264561,-51.6311,-24.524,Manoel Ribas,ADM2,DISTRICT
SM262783,-70.0124,-9.4895,Manoel Urbano,ADM2,DISTRICT
SM265748,-55.5687,-29.407,Manoel Viana,ADM2,DISTRICT
SM267527,-40.4801,-14.0049,Manoel Vitorino,ADM2,DISTRICT
SM292578,27.0795,48.0085,Manoleasa,ADM2,DISTRICT
SM237464,134.329,-5.9447,Manombai,LANGUAGE,LANGUAGE
SM269713,27.4545,-7.2947,Manono (ville),ADM2,DISTRICT
SM297666,100.1568,15.3189,Manorom,ADM2,DISTRICT
SM297894,10.0947,36.8091,Manouba,ADM2,DISTRICT
SM289031,126.3333,41.1667,Manp'o,ADM2,DISTRICT
SM13332,-171.2442,-4.4556,Manra,ADM2,DISTRICT
SM277145,75.43,29.94,Mansa,ADM2,DISTRICT
SM255571,38.59,16.15,Mansa,ETHNICITY,ETHNICITY
SM18146,28.9578,-11.1167,Mansa District (2012-),ADM2,DISTRICT
SM275102,-15.15,12.318,Mansaba,ADM2,DISTRICT
SM275102,-15.15,12.318,Mansaba,ADM2,DISTRICT
SM275102,-15.18,12.29,Mansaba,ADM2,DISTRICT
SM275102,-15.18,12.29,Mansaba,ADM2,DISTRICT
SM237471,126.181,6.7922,Mansaka,LANGUAGE,LANGUAGE
SM5131,73.4647,34.6483,Mansehra (2011-),ADM2:ADM3,DISTRICT
SM12514,-77.0949,18.4027,Mansfield,ADM2,DISTRICT
SM261251,146.148,-37.2413,Mansfield,ADM2,DISTRICT
SM255573,62,63,Mansi,ETHNICITY,ETHNICITY
SM255573,62,63,Mansi,ETHNICITY,ETHNICITY
SM255573,66.47,60.2,Mansi,ETHNICITY,ETHNICITY
SM255573,66.47,60.2,Mansi,ETHNICITY,ETHNICITY
SM267528,-44.1411,-11.2078,Mansidão,ADM2,DISTRICT
SM237469,134.0111,-0.9151,Mansim,LANGUAGE,LANGUAGE
SM255574,133.81,-1.13,Mansim,ETHNICITY,ETHNICITY
SM293439,93.6667,55.4167,Manskiy rayon,ADM2,DISTRICT
SM255575,-107.78,33.28,Manso,ETHNICITY,ETHNICITY
SM275103,-15.2155,12.0549,Mansoa,ADM2,DISTRICT
SM237468,-15.9202,12.8218,Mansoanka,LANGUAGE,LANGUAGE
SM10887,32.6369,35.1871,Mansoura,ADM2,DISTRICT
SM274010,38.2159,15.5304,Mansura,ADM2,DISTRICT
SM237478,9.5949,6.0213,Manta,LANGUAGE,LANGUAGE
SM273492,-80.7333,-0.95,Manta,ADM2,DISTRICT
SM270023,-73.5858,4.9572,Manta,ADM2,DISTRICT
SM255577,-80.44,-0.92,Manta,ETHNICITY,ETHNICITY
SM289240,-7.5007,40.4063,Manteigas,ADM2,DISTRICT
SM263623,-41.1076,-18.6855,Mantena,ADM2,DISTRICT
SM268003,-41.1563,-18.8661,Mantenópolis,ADM2,DISTRICT
SM370480,6.4264,49.7028,Manternach,ADM1:ADM2:ADM3,DISTRICT
SM255578,134.15,-2.47,Mantions,ETHNICITY,ETHNICITY
SM275748,-86.4227,14.8697,Manto,ADM2,DISTRICT
SM237476,105.405,23.1829,Mantsi,LANGUAGE,LANGUAGE
SM277872,10.7833,45.1667,Mantua,ADM2,DISTRICT
SM271035,-84.2399,22.3283,Mantua,ADM2,DISTRICT
SM16997,44.75,58.25,Manturovskiy rayon,ADM2,DISTRICT
SM293504,37.1333,51.45,Manturovskiy rayon,ADM2,DISTRICT
SM286784,-71,-12.25,Manu,ADM2,DISTRICT
SM2144,-169.4746,-14.2333,Manu'a,ADM1,DISTRICT
SM2145,9.8333,36.8333,Manubah,ADM1,DISTRICT
SM283247,-103.9189,28.9443,Manuel Benavides,ADM2,DISTRICT
SM281546,-101.856,20.7209,Manuel Doblado,ADM2,DISTRICT
SM281823,-102.8498,19.5392,Manuel M. Dieguez,ADM2,DISTRICT
SM262773,-64.4654,-17.8352,Manuel María Caballero,ADM2,DISTRICT
SM303793,-68.6725,10.5973,Manuel Monge,ADM2,DISTRICT
SM271121,-75.0552,20.1787,Manuel Tames,ADM2,DISTRICT
SM2146,125.7778,-8.9986,Manufahi,ADM1,DISTRICT
SM237483,96.9531,19.165,Manumanaw Karen,LANGUAGE,LANGUAGE
SM295480,166.3296,-10.2831,Manuopo,ADM2,DISTRICT
SM262747,-67.4925,-11.3034,Manuripi,ADM2,DISTRICT
SM288575,146.9661,-2.0963,Manus,ADM2,DISTRICT
SM2147,147,-2,Manus,ADM1,DISTRICT
SM255579,146.88,-2.04,Manus,ETHNICITY,ETHNICITY
SM255579,146.88,-2.04,Manus,ETHNICITY,ETHNICITY
SM255579,146.88,-2.04,Manus,ETHNICITY,ETHNICITY
SM255579,146.88,-2.04,Manus,ETHNICITY,ETHNICITY
SM255579,147,-2.167,Manus,ETHNICITY,ETHNICITY
SM255579,147,-2.167,Manus,ETHNICITY,ETHNICITY
SM255579,147,-2.167,Manus,ETHNICITY,ETHNICITY
SM255579,147,-2.167,Manus,ETHNICITY,ETHNICITY
SM255579,147,-2,Manus,ETHNICITY,ETHNICITY
SM255579,147,-2,Manus,ETHNICITY,ETHNICITY
SM255579,147,-2,Manus,ETHNICITY,ETHNICITY
SM255579,147,-2,Manus,ETHNICITY,ETHNICITY
SM255579,147.17,-2.17,Manus,ETHNICITY,ETHNICITY
SM255579,147.17,-2.17,Manus,ETHNICITY,ETHNICITY
SM255579,147.17,-2.17,Manus,ETHNICITY,ETHNICITY
SM255579,147.17,-2.17,Manus,ETHNICITY,ETHNICITY
SM227663,147.1427,-2.0343,Manus Koro,FAMILY,LANGUAGE
SM237484,129.429,-3.1109,Manusela,LANGUAGE,LANGUAGE
SM237491,-4.4544,54.2604,Manx,LANGUAGE,LANGUAGE
SM237492,-4.5733,54.1781,Manx English,DIALECT,LANGUAGE
SM237498,-9.4426,8.5619,Manya,LANGUAGE,LANGUAGE
SM2148,36.8333,-4.5,Manyara,ADM1,DISTRICT
SM298166,27.9783,40.0556,Manyas,ADM2,DISTRICT
SM237496,36.8459,-16.4963,Manyawa,LANGUAGE,LANGUAGE
SM237495,32.8052,-18.1927,Manyika,LANGUAGE,LANGUAGE
SM18147,24.3206,-12.9722,Manyinga District,ADM2,DISTRICT
SM237493,123.8402,-22.5176,Manyjilyjara,DIALECT,LANGUAGE
SM299182,34.5368,-6.3108,Manyoni,ADM2,DISTRICT
SM269551,9.37,5.82,Manyu,ADM2,DISTRICT
SM237501,18.7935,6.5122,Manza,LANGUAGE,LANGUAGE
SM289888,26.6456,45.5041,Manzalesti,ADM2,DISTRICT
SM270840,-75.1517,5.2331,Manzanares,ADM2,DISTRICT
SM283322,-104.3135,19.1086,Manzanillo,ADM2,DISTRICT
SM271112,-77.1027,20.2473,Manzanillo,ADM2,DISTRICT
SM2149,31.25,-26.5833,Manzini,ADM1,DISTRICT
SM296714,31.3909,-26.4998,Manzini North,ADM2,DISTRICT
SM296700,31.3667,-26.4833,Manzini South,ADM2,DISTRICT
SM282980,-89.3776,20.371,Maní,ADM2,DISTRICT
SM270893,-72.25,4.7,Maní,ADM2,DISTRICT
SM271890,-71.0833,19.5833,Mao,ADM2,DISTRICT
SM250305,34.6665,9.4165,Mao,ETHNICITY,ETHNICITY
SM250305,34.6665,9.4165,Mao,ETHNICITY,ETHNICITY
SM250305,34.6665,9.4165,Mao,ETHNICITY,ETHNICITY
SM250305,35,9,Mao,ETHNICITY,ETHNICITY
SM250305,35,9,Mao,ETHNICITY,ETHNICITY
SM250305,35,9,Mao,ETHNICITY,ETHNICITY
SM250305,34.67,9.27,Mao,ETHNICITY,ETHNICITY
SM250305,34.67,9.27,Mao,ETHNICITY,ETHNICITY
SM250305,34.67,9.27,Mao,ETHNICITY,ETHNICITY
SM237503,94.0035,25.3836,Mao Naga,LANGUAGE,LANGUAGE
SM269436,110.9071,22.0711,Maoming,ADM2,DISTRICT
SM237506,108.024,25.0169,Maonan,LANGUAGE,LANGUAGE
SM255582,-57.28,0.54,Maopityan,ETHNICITY,ETHNICITY
SM237511,45.1549,-12.8303,Maore Comorian,LANGUAGE,LANGUAGE
SM237513,176.541,-38.2881,Maori,LANGUAGE,LANGUAGE
SM255583,175.75,-39.26,Maori,ETHNICITY,ETHNICITY
SM255583,175.75,-39.26,Maori,ETHNICITY,ETHNICITY
SM255583,175.75,-39.26,Maori,ETHNICITY,ETHNICITY
SM255583,176.31,-38.41,Maori,ETHNICITY,ETHNICITY
SM255583,176.31,-38.41,Maori,ETHNICITY,ETHNICITY
SM255583,176.31,-38.41,Maori,ETHNICITY,ETHNICITY
SM255583,174.17,-35.33,Maori,ETHNICITY,ETHNICITY
SM255583,174.17,-35.33,Maori,ETHNICITY,ETHNICITY
SM255583,174.17,-35.33,Maori,ETHNICITY,ETHNICITY
SM283162,-92.9492,15.4198,Mapastepec,ADM2,DISTRICT
SM237516,147.682,-6.5932,Mape,LANGUAGE,LANGUAGE
SM297838,125.4506,-9.1575,Mape,ADM2,DISTRICT
SM237517,149.495,-9.778,Mapena,LANGUAGE,LANGUAGE
SM237519,134.3047,0.9089,Mapia,LANGUAGE,LANGUAGE
SM237521,-59.6155,1.9145,Mapidian-Mawayana,LANGUAGE,LANGUAGE
SM237521,-59.6155,1.9145,Mapidian-Mawayana,LANGUAGE,LANGUAGE
SM237521,-58.586,1.1902,Mapidian-Mawayana,LANGUAGE,LANGUAGE
SM237521,-58.586,1.1902,Mapidian-Mawayana,LANGUAGE,LANGUAGE
SM281501,-104.0546,26.191,Mapimí,ADM2,DISTRICT
SM10423,-70.2857,2.81,Mapiripana,ADM2,DISTRICT
SM270277,-71.9496,3.0971,Mapiripán,ADM2,DISTRICT
SM280618,126.9093,37.5544,Mapo,ADM2,DISTRICT
SM261644,141.9022,-12.1096,Mapoon,ADM2,DISTRICT
SM237522,146.774,-6.8667,Mapos Buang,LANGUAGE,LANGUAGE
SM13981,27.9622,-29.1225,Mapoteng,ADM2,DISTRICT
SM237526,-67.2581,5.9695,Mapoyo,LANGUAGE,LANGUAGE
SM276530,139.3444,-6.4997,Mappi,ADM2,DISTRICT
SM288628,143,-3.75,Maprik,ADM2,DISTRICT
SM255586,-73.43,-40.22,Mapuche,ETHNICITY,ETHNICITY
SM255586,-73.43,-40.22,Mapuche,ETHNICITY,ETHNICITY
SM255586,-73.43,-40.22,Mapuche,ETHNICITY,ETHNICITY
SM255586,-73.43,-40.22,Mapuche,ETHNICITY,ETHNICITY
SM255586,-72.79,-39.83,Mapuche,ETHNICITY,ETHNICITY
SM255586,-72.79,-39.83,Mapuche,ETHNICITY,ETHNICITY
SM255586,-72.79,-39.83,Mapuche,ETHNICITY,ETHNICITY
SM255586,-72.79,-39.83,Mapuche,ETHNICITY,ETHNICITY
SM255586,-72.817,-38.65,Mapuche,ETHNICITY,ETHNICITY
SM255586,-72.817,-38.65,Mapuche,ETHNICITY,ETHNICITY
SM255586,-72.817,-38.65,Mapuche,ETHNICITY,ETHNICITY
SM255586,-72.817,-38.65,Mapuche,ETHNICITY,ETHNICITY
SM255586,-68,-39,Mapuche,ETHNICITY,ETHNICITY
SM255586,-68,-39,Mapuche,ETHNICITY,ETHNICITY
SM255586,-68,-39,Mapuche,ETHNICITY,ETHNICITY
SM255586,-68,-39,Mapuche,ETHNICITY,ETHNICITY
SM255586,-72.58,-38.5,Mapuche,ETHNICITY,ETHNICITY
SM255586,-72.58,-38.5,Mapuche,ETHNICITY,ETHNICITY
SM255586,-72.58,-38.5,Mapuche,ETHNICITY,ETHNICITY
SM255586,-72.58,-38.5,Mapuche,ETHNICITY,ETHNICITY
SM237531,-71.277,-38.7392,Mapudungun,LANGUAGE,LANGUAGE
SM275703,-88.6244,14.0429,Mapulaca,ADM2,DISTRICT
SM237530,118.75,6.0906,Mapun,LANGUAGE,LANGUAGE
SM255587,32.32,-26.37,Maputa,ETHNICITY,ETHNICITY
SM2150,32.3333,-25.5,Maputo,ADM1,DISTRICT
SM2151,32.5892,-25.9653,Maputo City,ADM1,DISTRICT
SM13208,82.25,46.75,Maqanshy Audany,ADM2,DISTRICT
SM304984,43.6674,13.6212,Maqbanah,ADM2,DISTRICT
SM13209,68.5833,40.75,Maqtaaral Audany (excl. Kirov),ADM2,DISTRICT
SM260385,15.5458,-6.1236,Maquela do Zombo,ADM2,DISTRICT
SM265749,-50.2599,-29.5761,Maquiné,ADM2,DISTRICT
SM237532,-64.3877,4.7071,Maquiritari,LANGUAGE,LANGUAGE
SM255588,-65,3,Maquiritari,ETHNICITY,ETHNICITY
SM260797,-57.75,-37.5,Mar Chiquita,ADM2,DISTRICT
SM237247,87.3507,24.087,Mar Paharia of Dumka,LANGUAGE,LANGUAGE
SM265381,-36.3504,-9.4925,Mar Vermelho,ADM2,DISTRICT
SM263625,-43.0219,-21.876,Mar de Espanha,ADM2,DISTRICT
SM293896,72.5833,54.9167,Mar'yanovskiy rayon,ADM2,DISTRICT
SM303799,-72.0833,10.9167,Mara,ADM2,DISTRICT
SM2152,34.4167,-1.8333,Mara,ADM1,DISTRICT
SM255589,92.47,24.7,Mara,ETHNICITY,ETHNICITY
SM255589,92.47,24.7,Mara,ETHNICITY,ETHNICITY
SM255589,92.47,24.7,Mara,ETHNICITY,ETHNICITY
SM255589,93,22,Mara,ETHNICITY,ETHNICITY
SM255589,93,22,Mara,ETHNICITY,ETHNICITY
SM255589,93,22,Mara,ETHNICITY,ETHNICITY
SM255589,93,22.33,Mara,ETHNICITY,ETHNICITY
SM255589,93,22.33,Mara,ETHNICITY,ETHNICITY
SM255589,93,22.33,Mara,ETHNICITY,ETHNICITY
SM237544,93.0387,22.1138,Mara Chin,LANGUAGE,LANGUAGE
SM268082,-49.3951,-14.0129,Mara Rosa,ADM2,DISTRICT
SM264184,-50.0641,-5.621,Marabá,ADM2,DISTRICT
SM266682,-52.0513,-22.1173,Marabá Paulista,ADM2,DISTRICT
SM303800,-72.1667,10.6667,Maracaibo,ADM2,DISTRICT
SM263063,-55.5458,-21.425,Maracaju,ADM2,DISTRICT
SM266203,-49.4644,-28.8519,Maracajá,ADM2,DISTRICT
SM267786,-38.6312,-3.8883,Maracanaú,ADM2,DISTRICT
SM264185,-47.4876,-0.7582,Maracanã,ADM2,DISTRICT
SM262816,-45.9768,-2.0211,Maracaçumé,ADM2,DISTRICT
SM266683,-50.798,-22.6625,Maracaí,ADM2,DISTRICT
SM4764,30.9252,3.2509,Maracha-Terego,ADM1:ADM2,DISTRICT
SM237552,34.22,0.34,Marachi,LANGUAGE,LANGUAGE
SM289889,26.7939,45.189,Maracineni,ADM2,DISTRICT
SM291257,24.8748,44.9086,Maracineni,ADM2,DISTRICT
SM267529,-40.6199,-13.6501,Maracás,ADM2,DISTRICT
SM260846,-63.75,-35.75,Maracó,ADM2,DISTRICT
SM2153,7.25,14.25,Maradi,ADM1,DISTRICT
SM285288,6.245,12.7779,Maradun,ADM2,DISTRICT
SM237536,49.9665,36.8259,Maraghei,LANGUAGE,LANGUAGE
SM265382,-35.276,-8.9586,Maragogi,ADM2,DISTRICT
SM267530,-38.9402,-12.8351,Maragogipe,ADM2,DISTRICT
SM237567,-67.5128,-3,Maragua,LANGUAGE,LANGUAGE
SM5277,37.065,-0.851,Maragua District,ADM2,DISTRICT
SM15901,-66.5912,18.1006,Maragueez Barrio,ADM2,DISTRICT
SM237558,167.33,-16.0695,Maragus,LANGUAGE,LANGUAGE
SM269495,-5.8022,7.0325,Marahoué,ADM2,DISTRICT
SM264851,-35.7853,-8.8449,Maraial,ADM2,DISTRICT
SM275915,-87.0563,13.872,Maraita,ADM2,DISTRICT
SM237572,-49.6417,-0.9629,Marajo Sign Language,LANGUAGE,LANGUAGE
SM262817,-45.6314,-4.7001,Marajá do Sena,ADM2,DISTRICT
SM13333,173.2764,2.0108,Marakei,ADM2,DISTRICT
SM454507,35.5,1.3,Marakwet,ETHNICITY,ETHNICITY
SM275916,-87.0989,14.9411,Marale,ADM2,DISTRICT
SM261707,130.8444,-29.6009,Maralinga Tjarutja,ADM2,DISTRICT
SM237541,94.2205,25.4378,Maram Naga,LANGUAGE,LANGUAGE
SM237551,34.49,0.19,Marama,LANGUAGE,LANGUAGE
SM458802,130.4182,-13.9706,Maramanindji,ETHNICITY,ETHNICITY
SM237565,143.735,-4.2391,Maramba,LANGUAGE,LANGUAGE
SM2154,24,47.6667,Maramureș,ADM1,DISTRICT
SM237562,124.175,7.7941,Maranao,LANGUAGE,LANGUAGE
SM255593,148.21,-28.1,Maranao,ETHNICITY,ETHNICITY
SM255593,148.21,-28.1,Maranao,ETHNICITY,ETHNICITY
SM255592,125.02,8.42,Maranao,ETHNICITY,ETHNICITY
SM255593,148.5017,-27.0177,Maranao,ETHNICITY,ETHNICITY
SM255593,148.5017,-27.0177,Maranao,ETHNICITY,ETHNICITY
SM11784,45.6334,38.5833,Marand,ADM2,DISTRICT
SM458650,122.9972,-17.9701,Marangan,ETHNICITY,ETHNICITY
SM261999,29.9623,-2.7342,Marangara,ADM2,DISTRICT
SM267787,-38.7297,-3.9958,Maranguape,ADM2,DISTRICT
SM2155,-45,-5,Maranhão,ADM1,DISTRICT
SM262818,-45.9885,-2.4525,Maranhãozinho,ADM2,DISTRICT
SM261645,148.431,-26.3929,Maranoa,ADM2,DISTRICT
SM237546,130.12,-13.6754,Maranunggu,LANGUAGE,LANGUAGE
SM458768,130.2716,-13.7585,Maranunggu,ETHNICITY,ETHNICITY
SM264186,-47.709,-0.8406,Marapanim,ADM2,DISTRICT
SM266684,-49.1486,-21.2576,Marapoama,ADM2,DISTRICT
SM237555,21.6408,14.087,Mararit,LANGUAGE,LANGUAGE
SM292353,27.2231,45.9045,Marasesti,ADM2,DISTRICT
SM292680,27.9806,45.0081,Marasu,ADM2,DISTRICT
SM268004,-40.899,-21.0948,Marataízes,ADM2,DISTRICT
SM10888,33.7778,35.2073,Maratha,ADM2,DISTRICT
SM237540,76.6665,17.9344,Marathi,LANGUAGE,LANGUAGE
SM303253,-89.7591,44.8983,Marathon,ADM2,DISTRICT
SM10889,32.497,34.7792,Marathounta,ADM2,DISTRICT
SM10890,33.6318,35.2157,Marathovounos,ADM2,DISTRICT
SM250916,73.99,17.39,Marati,ETHNICITY,ETHNICITY
SM250916,73.99,17.39,Marati,ETHNICITY,ETHNICITY
SM250916,75.3,20.76,Marati,ETHNICITY,ETHNICITY
SM250916,75.3,20.76,Marati,ETHNICITY,ETHNICITY
SM250916,75.9,19.8,Marati,ETHNICITY,ETHNICITY
SM250916,75.9,19.8,Marati,ETHNICITY,ETHNICITY
SM237534,-98.5767,24.3391,Maratino,LANGUAGE,LANGUAGE
SM265750,-51.5569,-29.5492,Maratá,ADM2,DISTRICT
SM237556,135.797,-1.6674,Marau,LANGUAGE,LANGUAGE
SM237571,162.1901,-10.8259,Marau Wawa,LANGUAGE,LANGUAGE
SM282049,-100.4237,19.8902,Maravatío,ADM2,DISTRICT
SM283874,32.0655,-15.0931,Maravia,ADM2,DISTRICT
SM266204,-53.1828,-26.758,Maravilha,ADM2,DISTRICT
SM265384,-37.3794,-9.2231,Maravilha,ADM2,DISTRICT
SM263626,-44.68,-19.5123,Maravilhas,ADM2,DISTRICT
SM15902,-66.9809,18.2618,Maravilla Este Barrio,ADM2,DISTRICT
SM15903,-66.9961,18.266,Maravilla Norte Barrio,ADM2,DISTRICT
SM15904,-66.998,18.23,Maravilla Sur Barrio,ADM2,DISTRICT
SM14336,-91.2609,16.2039,Maravilla Tenejapa,ADM2,DISTRICT
SM255601,-66.34,-2.61,Marawa,ETHNICITY,ETHNICITY
SM458047,125.3464,-28.7715,Marawa,ETHNICITY,ETHNICITY
SM237566,-51.8,3.9,Marawan,LANGUAGE,LANGUAGE
SM255602,9.55,32.63,Marazig,ETHNICITY,ETHNICITY
SM267335,-64.8042,-2.6379,Maraã,ADM2,DISTRICT
SM286737,-76.6667,-8.75,Marañón,ADM2,DISTRICT
SM265752,-52.2741,-28.4422,Maraú,ADM2,DISTRICT
SM267532,-39.1474,-14.1541,Maraú,ADM2,DISTRICT
SM237575,15.8,9.54,Marba,LANGUAGE,LANGUAGE
SM458459,151.8741,-29.1135,Marbal,ETHNICITY,ETHNICITY
SM262713,-64.5582,-15.4643,Marbán,ADM2,DISTRICT
SM17499,-60.9572,13.9569,Marc,ADM2,DISTRICT
SM291618,22.5509,47.235,Marca,ADM2,DISTRICT
SM273628,-79.9232,-3.7757,Marcabelí,ADM2,DISTRICT
SM275696,-88.0241,14.1485,Marcala,ADM2,DISTRICT
SM276171,17.392,46.5438,Marcali,ADM2,DISTRICT
SM303678,-63.9641,11.0831,Marcano,ADM2,DISTRICT
SM264237,-34.991,-6.7472,Marcação,ADM2,DISTRICT
SM265586,-38.1428,-6.3218,Marcelino Vieira,ADM2,DISTRICT
SM263181,-54.1332,-11.0037,Marcelândia,ADM2,DISTRICT
SM268952,8.911,47.1613,March,ADM2,DISTRICT
SM12515,-78.2766,18.3295,March Town,ADM2,DISTRICT
SM17500,-60.9853,14.0042,Marchand,ADM2,DISTRICT
SM2156,13.25,43.5,Marche,ADM1,DISTRICT
SM17501,-61.0147,13.8223,Marche Gaye,ADM2,DISTRICT
SM13681,26.1015,56.7737,Marcienas pagasts,ADM2,DISTRICT
SM265753,-51.9507,-27.4785,Marcionilio Dias,ADM2,DISTRICT
SM267533,-40.6292,-13.1158,Marcionílio Souza,ADM2,DISTRICT
SM267788,-40.2628,-3.1894,Marco,ADM2,DISTRICT
SM289315,-8.1695,41.1555,Marco de Canaveses,ADM2,DISTRICT
SM265052,-40.7495,-7.4089,Marcolândia,ADM2,DISTRICT
SM282050,-102.9727,20.0351,Marcos Castellanos,ADM2,DISTRICT
SM261147,-62.25,-33,Marcos Juárez,ADM2,DISTRICT
SM265053,-43.9166,-7.0541,Marcos Parente,ADM2,DISTRICT
SM260798,-58.8333,-34.8333,Marcos Paz,ADM2,DISTRICT
SM275826,-87.35,13.25,Marcovia,ADM2,DISTRICT
SM375431,72.0904,34.3067,Mardan,ADM2:ADM3,DISTRICT
SM459039,141.6431,-36.9678,Mardidjali,ETHNICITY,ETHNICITY
SM2157,40.7153,37.3177,Mardin (1990-),ADM1,DISTRICT
SM18069,40.7344,37.3126,Mardin Merkez (2014-),ADM2,DISTRICT
SM237580,40.7378,37.3167,Mardin Sign Language,LANGUAGE,LANGUAGE
SM255603,115.64,-22.02,Mardudhunera,ETHNICITY,ETHNICITY
SM255603,115.64,-22.02,Mardudhunera,ETHNICITY,ETHNICITY
SM255603,116.4509,-21.4055,Mardudhunera,ETHNICITY,ETHNICITY
SM255603,116.4509,-21.4055,Mardudhunera,ETHNICITY,ETHNICITY
SM260030,66.3288,37.0522,Mardyan,ADM2,DISTRICT
SM264562,-54.1467,-24.6073,Marechal Cândido Rondon,ADM2,DISTRICT
SM268005,-40.7653,-20.4322,Marechal Floriano,ADM2,DISTRICT
SM262784,-72.5685,-9.1899,Marechal Thaumaturgo,ADM2,DISTRICT
SM265385,-35.9031,-9.709,Marechal deodoro,ADM2,DISTRICT
SM296055,-55.1463,4.7928,Marechalskreek,ADM2,DISTRICT
SM9731,143.9242,-17.1294,Mareeba,ADM2,DISTRICT
SM295436,161.2828,-9.4639,Mareho,ADM2,DISTRICT
SM266205,-52.6205,-26.7977,Marema,ADM2,DISTRICT
SM237590,139.673,-2.3192,Maremgi,LANGUAGE,LANGUAGE
SM300286,-87.7895,32.2476,Marengo,ADM2,DISTRICT
SM237589,35.7425,-16.2129,Marenje,LANGUAGE,LANGUAGE
SM15905,-67.0668,18.0795,Maresua Barrio,ADM2,DISTRICT
SM298075,10.2444,33.6537,Mareth,ADM2,DISTRICT
SM293819,32.25,57.3333,Marevskiy rayon,ADM2,DISTRICT
SM237591,20.7882,13.1568,Marfa,LANGUAGE,LANGUAGE
SM18410,71.7247,40.4711,Margilon Shahri,ADM2,DISTRICT
SM290028,22.5154,45.5149,Marga,ADM2,DISTRICT
SM237594,144.892,-26.789,Margany,LANGUAGE,LANGUAGE
SM458162,144.5774,-27.2729,Margany,ETHNICITY,ETHNICITY
SM296066,-55.132,5.9471,Margaretha,ADM2,DISTRICT
SM270686,-74.2813,9.0633,Margarita,ADM2,DISTRICT
SM289890,26.8362,45.435,Margaritesti,ADM2,DISTRICT
SM290112,22.9443,46.7568,Margau,ADM2,DISTRICT
SM250483,13,11,Marghi,ETHNICITY,ETHNICITY
SM237596,13.0521,10.9385,Marghi Central,LANGUAGE,LANGUAGE
SM237597,12.9952,10.5385,Marghi South,LANGUAGE,LANGUAGE
SM292443,22.3345,47.3453,Marghita,ADM2,DISTRICT
SM10891,33.3241,35.0238,Margi,ADM2,DISTRICT
SM2158,-10.3167,6.55,Margibi,ADM1,DISTRICT
SM291997,22.2723,45.8616,Margina,ADM2,DISTRICT
SM291814,25.823,47.8093,Marginea,ADM2,DISTRICT
SM291344,26.6574,46.8924,Margineni,ADM2,DISTRICT
SM292126,26.8051,46.5978,Margineni,ADM2,DISTRICT
SM10892,33.4849,35.1058,Margo,ADM2,DISTRICT
SM237595,-76.4981,-9.8446,Margos-Yarowilca-Lauricocha Quechua,LANGUAGE,LANGUAGE
SM237592,132.587,-11.1823,Margu,LANGUAGE,LANGUAGE
SM303406,72.3167,40.5,Marhamat,ADM2,DISTRICT
SM10893,33.2911,34.7341,Mari,ADM2,DISTRICT
SM264238,-35.3026,-7.0714,Mari,ADM2,DISTRICT
SM255605,46,43,Mari,ETHNICITY,ETHNICITY
SM255606,69,30,Mari,ETHNICITY,ETHNICITY
SM237622,142.963,-4.4644,Mari (East Sepik Province),LANGUAGE,LANGUAGE
SM237619,145.828,-5.974,Mari (Madang Province),LANGUAGE,LANGUAGE
SM293631,49.8333,56.75,Mari-Turekskiy rayon,ADM2,DISTRICT
SM237605,79.9144,19.7875,Maria (India),LANGUAGE,LANGUAGE
SM237628,148.303,-9.9298,Maria (Papua New Guinea),LANGUAGE,LANGUAGE
SM264563,-53.2315,-23.5535,Maria Helena,ADM2,DISTRICT
SM263627,-45.3172,-22.3272,Maria da Fé,ADM2,DISTRICT
SM271716,9.8472,56.6972,Mariagerfjord municipality,ADM2,DISTRICT
SM264564,-51.8679,-23.5306,Marialva,ADM2,DISTRICT
SM263628,-43.3066,-20.324,Mariana,ADM2,DISTRICT
SM15907,-65.8527,18.1262,Mariana Barrio (Humacao),ADM2,DISTRICT
SM15906,-65.7043,18.2274,Mariana Barrio (Naguabo),ADM2,DISTRICT
SM265754,-51.5813,-30.3022,Mariana Pimentel,ADM2,DISTRICT
SM271088,-82.3841,23.07,Marianao,ADM2,DISTRICT
SM255608,145.65,14.98,Marianas Islands,ETHNICITY,ETHNICITY
SM282734,-97.1684,18.9317,Mariano Escobedo,ADM2,DISTRICT
SM265755,-52.1836,-27.3501,Mariano Moro,ADM2,DISTRICT
SM289717,-57.5312,-25.2013,Mariano Roque Alonso,ADM2,DISTRICT
SM267257,-49.7161,-9.8144,Marianópolis do Tocantins,ADM2,DISTRICT
SM15909,-67.1382,18.3594,Marias Barrio (Aguada),ADM2,DISTRICT
SM15908,-67.14,18.3,Marias Barrio (Añasco),ADM2,DISTRICT
SM15910,-67.1234,18.362,Marias Barrio (Moca),ADM2,DISTRICT
SM15316,-81.0041,7.6781,Mariato District,ADM2,DISTRICT
SM304912,46.0111,15.6213,Marib,ADM2,DISTRICT
SM304900,45.3034,15.4156,Marib City,ADM2,DISTRICT
SM265386,-36.3403,-9.5509,Maribondo,ADM2,DISTRICT
SM296330,15.6431,46.5561,Maribor,ADM1:ADM2,DISTRICT
SM261252,144.8779,-37.7914,Maribyrnong,ADM2,DISTRICT
SM2160,-66.9455,18.173,Maricao,ADM1,DISTRICT
SM15911,-66.9827,18.178,Maricao Afuera Barrio,ADM2,DISTRICT
SM15912,-66.3303,18.3844,Maricao Barrio,ADM2,DISTRICT
SM15913,-66.9797,18.1821,Maricao Barrio-Pueblo,ADM2,DISTRICT
SM237630,-111.749,33.5123,Maricopa,LANGUAGE,LANGUAGE
SM301801,-112.4912,33.3488,Maricopa,ADM2,DISTRICT
SM255609,-113.42,32.93,Maricopa,ETHNICITY,ETHNICITY
SM255609,-113.42,32.93,Maricopa,ETHNICITY,ETHNICITY
SM255609,-113.42,32.93,Maricopa,ETHNICITY,ETHNICITY
SM255609,-113,33,Maricopa,ETHNICITY,ETHNICITY
SM255609,-113,33,Maricopa,ETHNICITY,ETHNICITY
SM255609,-113,33,Maricopa,ETHNICITY,ETHNICITY
SM255609,-112.5,33.3,Maricopa,ETHNICITY,ETHNICITY
SM255609,-112.5,33.3,Maricopa,ETHNICITY,ETHNICITY
SM255609,-112.5,33.3,Maricopa,ETHNICITY,ETHNICITY
SM265281,-42.822,-22.9163,Maricá,ADM2,DISTRICT
SM237613,130.079,-14.0542,Maridan,LANGUAGE,LANGUAGE
SM237612,129.844,-13.8754,Maridjabin,LANGUAGE,LANGUAGE
SM237634,-61.2661,15.9369,Marie Galante Creole French,DIALECT,LANGUAGE
SM2161,19.9333,60.1,Mariehamn,ADM1:ADM2,DISTRICT
SM2161,19.9333,60.1,Mariehamn,ADM1:ADM2,DISTRICT
SM2161,19.9431,60.0835,Mariehamn,ADM1:ADM2,DISTRICT
SM2161,19.9431,60.0835,Mariehamn,ADM1:ADM2,DISTRICT
SM271145,-82.8032,22.9652,Mariel,ADM2,DISTRICT
SM255610,-81.55,22.82,Marien,ETHNICITY,ETHNICITY
SM284501,17.9667,-24.6333,Mariental Rural,ADM2,DISTRICT
SM301581,-91.9249,38.1616,Maries,ADM2,DISTRICT
SM296584,13.8542,58.7086,Mariestad,ADM2,DISTRICT
SM285028,5.8404,10.6992,Mariga,ADM2,DISTRICT
SM17502,-61.0221,13.9621,Marigot,ADM2,DISTRICT
SM16998,87.5833,56.3333,Mariinskiy Rayon,ADM2,DISTRICT
SM292796,47.8333,55.9167,Mariinsko-Posadskiy rayon,ADM2,DISTRICT
SM305733,16.1178,46.0057,Marija Bistrica,ADM2,DISTRICT
SM305620,15.7256,45.9107,Marija Gorica,ADM2,DISTRICT
SM14144,23.35,54.5667,Marijampole Municipality,ADM2,DISTRICT
SM2162,23.25,54.75,Marijampoles,ADM1,DISTRICT
SM14145,23.35,54.5667,Marijampoles Rajonas (2000-),ADM2,DISTRICT
SM305376,18.2928,45.6665,Marijanci,ADM2,DISTRICT
SM237618,145.59,-5.349,Marik,LANGUAGE,LANGUAGE
SM263629,-42.0656,-18.4952,Marilac,ADM2,DISTRICT
SM264567,-53.0817,-22.7274,Marilena,ADM2,DISTRICT
SM264568,-53.2298,-24.0618,Mariluz,ADM2,DISTRICT
SM268006,-40.5202,-19.422,Marilândia,ADM2,DISTRICT
SM264565,-51.2941,-23.7438,Marilândia do Sul,ADM2,DISTRICT
SM237608,130.561,-14.1788,Marimanindji,LANGUAGE,LANGUAGE
SM260345,17.1044,-8.1559,Marimba,ADM2,DISTRICT
SM303176,-122.7489,38.0552,Marin,ADM2,DISTRICT
SM15914,-66.0056,18.0387,Marin Barrio,ADM2,DISTRICT
SM305468,16.1077,43.5142,Marina,ADM2,DISTRICT
SM241023,140.009,-8.1565,Marind,LANGUAGE,LANGUAGE
SM255612,139,-8,Marind-Amin,ETHNICITY,ETHNICITY
SM2163,121.95,13.4167,Marinduque,ADM1:ADM2,DISTRICT
SM303255,-88.0022,45.3509,Marinette,ADM2,DISTRICT
SM237625,144.642,-5.4402,Maring,LANGUAGE,LANGUAGE
SM255613,143.97,-5.22,Maring,ETHNICITY,ETHNICITY
SM237607,94.0723,24.2854,Maring Naga,LANGUAGE,LANGUAGE
SM237610,130.25,-14.3819,Maringarr,LANGUAGE,LANGUAGE
SM283977,34.3922,-17.9636,Maringue,ADM2,DISTRICT
SM264569,-51.9573,-23.3839,Maringá,ADM2,DISTRICT
SM289243,-8.95,39.7768,Marinha Grande,ADM2,DISTRICT
SM270235,-75.3031,6.1961,Marinilla,ADM2,DISTRICT
SM266687,-50.8347,-20.4878,Marinópolis,ADM2,DISTRICT
SM303840,-67.6172,10.3031,Mario Briceño Iragorry,ADM2,DISTRICT
SM303124,-80.2434,39.51,Marion,ADM2,DISTRICT
SM302701,-94.3572,32.798,Marion,ADM2,DISTRICT
SM302458,-79.3625,34.0801,Marion,ADM2,DISTRICT
SM302265,-122.5847,44.9032,Marion,ADM2,DISTRICT
SM302595,-85.622,35.1294,Marion,ADM2,DISTRICT
SM302130,-83.1609,40.5872,Marion,ADM2,DISTRICT
SM301582,-91.6224,39.806,Marion,ADM2,DISTRICT
SM301466,-89.8224,31.2308,Marion,ADM2,DISTRICT
SM300941,-97.0969,38.3589,Marion,ADM2,DISTRICT
SM301081,-85.2696,37.5525,Marion,ADM2,DISTRICT
SM300843,-93.0994,41.3344,Marion,ADM2,DISTRICT
SM300736,-86.1385,39.7817,Marion,ADM2,DISTRICT
SM300535,-84.5246,32.3534,Marion,ADM2,DISTRICT
SM300348,-82.0567,29.2102,Marion,ADM2,DISTRICT
SM300646,-88.919,38.6496,Marion,ADM2,DISTRICT
SM300287,-87.8871,34.1366,Marion,ADM2,DISTRICT
SM302391,-92.6842,36.2684,Marion,ADM2,DISTRICT
SM261708,138.5534,-35.0132,Marion,ADM2,DISTRICT
SM303177,-119.9055,37.5815,Mariposa,ADM2,DISTRICT
SM264571,-53.8018,-24.4627,Maripá,ADM2,DISTRICT
SM263631,-42.9593,-21.701,Maripá de Minas,ADM2,DISTRICT
SM270786,-74.0544,5.5573,Maripí,ADM2,DISTRICT
SM237620,134.8811,-6.151,Mariri,LANGUAGE,LANGUAGE
SM286840,-77.1822,-7.2739,Mariscal Cáceres,ADM2,DISTRICT
SM15351,-61.2868,-21.9642,Mariscal Estigarribia (2005-),ADM2,DISTRICT
SM286794,-77.25,-8.8333,Mariscal Luzuriaga,ADM2,DISTRICT
SM286789,-71.0033,-17.0836,Mariscal Nieto,ADM2,DISTRICT
SM286780,-71.7006,-3.9268,Mariscal Ramón Castilla,ADM2,DISTRICT
SM14337,-98.1362,17.8478,Mariscala de Juarez,ADM2,DISTRICT
SM290113,23.1147,46.6842,Marisel,ADM2,DISTRICT
SM292506,24.5126,47.0107,Mariselu,ADM2,DISTRICT
SM255614,-76.21,20.34,Marisi,ETHNICITY,ETHNICITY
SM17503,-60.9706,14.0462,Marisule,ADM2,DISTRICT
SM17504,-60.9712,14.0506,Marisule/Bon Air,ADM2,DISTRICT
SM17505,-60.9685,14.0541,Marisule/East Winds,ADM2,DISTRICT
SM17506,-60.9718,14.0571,Marisule/La Brellotte,ADM2,DISTRICT
SM17507,-60.9689,14.0439,Marisule/Top Of The World,ADM2,DISTRICT
SM17508,-60.9669,14.0438,Marisule/Top Of The World/Belle Ville,ADM2,DISTRICT
SM237615,130.237,-13.8818,Marithiel,LANGUAGE,LANGUAGE
SM2164,1.3333,6.5,Maritime,ADM1,DISTRICT
SM454396,60,10,Maritime Arabs,ETHNICITY,ETHNICITY
SM237636,-148.4267,-16.5938,Maritime Polynesian Pidgin,LANGUAGE,LANGUAGE
SM237601,-63.881,45.0427,Maritime Sign Language,LANGUAGE,LANGUAGE
SM262355,24.8,42.2333,Maritsa,ADM2,DISTRICT
SM237632,-53.9667,-11.5833,Maritsauá,LANGUAGE,LANGUAGE
SM264187,-48.3142,-1.374,Marituba,ADM2,DISTRICT
SM18247,37.5476,47.1402,Mariupol City Council,ADM2,DISTRICT
SM18248,37.4333,47.2644,Mariupolskyi Raion (2020-),ADM2,DISTRICT
SM255615,-61.45,8.76,Mariusa,ETHNICITY,ETHNICITY
SM255616,129.99,-14.55,Mariwunda,ETHNICITY,ETHNICITY
SM255616,129.99,-14.55,Mariwunda,ETHNICITY,ETHNICITY
SM255616,129.99,-14.55,Mariwunda,ETHNICITY,ETHNICITY
SM255616,130.25,-15.2,Mariwunda,ETHNICITY,ETHNICITY
SM255616,130.25,-15.2,Mariwunda,ETHNICITY,ETHNICITY
SM255616,130.25,-15.2,Mariwunda,ETHNICITY,ETHNICITY
SM255616,130,-14,Mariwunda,ETHNICITY,ETHNICITY
SM255616,130,-14,Mariwunda,ETHNICITY,ETHNICITY
SM255616,130,-14,Mariwunda,ETHNICITY,ETHNICITY
SM255616,130.0035,-14.7473,Mariwunda,ETHNICITY,ETHNICITY
SM255616,130.0035,-14.7473,Mariwunda,ETHNICITY,ETHNICITY
SM255616,130.0035,-14.7473,Mariwunda,ETHNICITY,ETHNICITY
SM2165,48,56.5,Mariy-El,ADM1,DISTRICT
SM237614,129.876,-13.7687,Mariyedi,LANGUAGE,LANGUAGE
SM458807,130.0513,-13.721,Mariyedi,ETHNICITY,ETHNICITY
SM264239,-38.335,-6.8213,Marizópolis,ADM2,DISTRICT
SM266685,-51.1675,-21.7794,Mariápolis,ADM2,DISTRICT
SM303717,-62.5333,10.6167,Mariño,ADM2,DISTRICT
SM303679,-63.8833,10.95,Mariño,ADM2,DISTRICT
SM264570,-52.5742,-26.3163,Mariópolis,ADM2,DISTRICT
SM13974,35.5318,33.3458,Marjayoun City,ADM2,DISTRICT
SM296585,12.5946,57.476,Mark,ADM2,DISTRICT
SM237639,-3.5836,12.7178,Marka,LANGUAGE,LANGUAGE
SM295790,44.7223,1.7402,Marka,ADM2,DISTRICT
SM13682,27.21,57.5062,Markalnes pagasts,ADM2,DISTRICT
SM296670,13.6235,56.5443,Markaryd,ADM2,DISTRICT
SM11133,31.3193,30.8998,Markaz Aja (excl. Mit Yazid),ADM2,DISTRICT
SM11134,31.7851,26.5721,Markaz Akhmim,ADM2,DISTRICT
SM11135,32.8849,24.056,Markaz Aswan,ADM2,DISTRICT
SM11136,32.05,26.2833,Markaz Awlad Tawq Sharq,ADM2,DISTRICT
SM11137,31.143,30.1272,Markaz Awsim,ADM2,DISTRICT
SM11138,31.071,29.0912,Markaz Bani Suwayf,ADM2,DISTRICT
SM11139,31.1167,29.15,Markaz Bush,ADM2,DISTRICT
SM11140,31.9786,26.3128,Markaz Dar as Salam (excl. Awlad Tawq Sharq),ADM2,DISTRICT
SM11141,31.0256,30.1801,Markaz Imbabah (excl. Osim),ADM2,DISTRICT
SM11142,30.9672,31.137,Markaz Kafr ash Shaykh (excl. Kafr El Sheikh),ADM2,DISTRICT
SM11143,30.8011,27.7621,Markaz Mallawi,ADM2,DISTRICT
SM11144,31.1006,29.172,Markaz Nasir,ADM2,DISTRICT
SM296748,36.2875,33.3466,Markaz Rif Dimashq,ADM2,DISTRICT
SM11145,31.6752,26.5395,Markaz Suhaj,ADM2,DISTRICT
SM11146,32.0243,30.8439,Markaz al Husayniyah (excl. Bahr El Bakar),ADM2,DISTRICT
SM11147,32.2057,30.5367,Markaz al Ismailiyah,ADM2,DISTRICT
SM11148,31.4786,31.0417,Markaz al Mansurah,ADM2,DISTRICT
SM11149,30.7045,28.0858,Markaz al Minya,ADM2,DISTRICT
SM11150,33.7661,31.0196,Markaz al Arish,ADM2,DISTRICT
SM11151,31.499,30.5856,Markaz az Zaqaziq,ADM2,DISTRICT
SM2166,49.8333,34.3333,Markazi,ADM1,DISTRICT
SM260230,67.7974,34.4039,Markazi Bihsud,ADM2,DISTRICT
SM286279,11.6592,59.4795,Marker,ADM2,DISTRICT
SM288585,146.8604,-6.6415,Markham,ADM2,DISTRICT
SM10242,17.2568,7.5291,Markounda (2002-),ADM2,DISTRICT
SM296331,15.9507,46.3921,Markovci,ADM1:ADM2,DISTRICT
SM16999,46.8333,51.6667,Marksovskiy Rayon,ADM2,DISTRICT
SM305540,18.7052,45.3731,Markušica,ADM2,DISTRICT
SM237638,35.5866,1.1502,Markweeta,LANGUAGE,LANGUAGE
SM302459,-79.6786,34.602,Marlboro,ADM2,DISTRICT
SM286559,173.5883,-41.5693,Marlborough,ADM2,DISTRICT
SM2167,173.5,-41.6667,Marlborough,ADM1:ADMX,DISTRICT
SM255619,132.59,-11.06,Marlgu,ETHNICITY,ETHNICITY
SM255619,132.59,-11.06,Marlgu,ETHNICITY,ETHNICITY
SM255619,132.5602,-11.1577,Marlgu,ETHNICITY,ETHNICITY
SM255619,132.5602,-11.1577,Marlgu,ETHNICITY,ETHNICITY
SM12516,-77.5083,17.8669,Marlie Hill,ADM2,DISTRICT
SM263632,-42.6272,-19.6924,Marliéria,ADM2,DISTRICT
SM237641,92.2108,22.76,Marma,LANGUAGE,LANGUAGE
SM298167,27.5553,40.5875,Marmara,ADM2,DISTRICT
SM298943,27.941,40.9802,Marmaraereğlisi,ADM2,DISTRICT
SM298769,28.1791,36.8105,Marmaris,ADM2,DISTRICT
SM270841,-75.5971,5.4755,Marmato,ADM2,DISTRICT
SM276086,-72.3667,19.3333,Marmelade,ADM2,DISTRICT
SM264572,-53.0975,-26.2379,Marmeleiro,ADM2,DISTRICT
SM263633,-45.1697,-22.4597,Marmelópolis,ADM2,DISTRICT
SM260244,67.335,36.6347,Marmul,ADM2,DISTRICT
SM274495,4.2597,48.9912,Marne,ADM2,DISTRICT
SM11322,44.8108,41.4636,Marneuli,ADM2,DISTRICT
SM11323,44.85,41.3833,Marneulis Munitsip'alit'et'I (excl. Marneuli),ADM2,DISTRICT
SM303560,-66.7859,2.7016,Maroa,ADM2,DISTRICT
SM371018,49.5648,-15.3835,Maroansetra,ADM2:ADM3,DISTRICT
SM371025,47.9667,-20.0921,Marolambo,ADM2:ADM3,DISTRICT
SM10894,32.6679,34.7703,Maronas,ADM2,DISTRICT
SM305246,31.5125,-18.2921,Marondera,ADM2,DISTRICT
SM10895,33.367,34.7531,Maroni,ADM2,DISTRICT
SM12517,-77.797,18.3563,Maroon Town,ADM2,DISTRICT
SM261253,145.266,-37.8029,Maroondah,ADM2,DISTRICT
SM238721,140.688,-8.5692,Marori,LANGUAGE,LANGUAGE
SM276574,119.7167,-5.05,Maros,ADM2,DISTRICT
SM237644,157.747,-8.3794,Marovo,LANGUAGE,LANGUAGE
SM370996,46.6167,-16.15,Marovoay,ADM2:ADM3,DISTRICT
SM2168,-54.3333,5.5833,Marowijne,ADM1,DISTRICT
SM276797,-4.5739,54.1739,Marown,ADM1:ADM2,DISTRICT
SM291730,24.5154,45.871,Marpod,ADM2,DISTRICT
SM13210,85.5,48.5,Marqakol Audany,ADM2,DISTRICT
SM14338,-98.8007,16.6238,Marquelia,ADM2,DISTRICT
SM14339,-90.7592,16.2147,Marques de Comillas,ADM2,DISTRICT
SM265756,-52.1572,-29.2733,Marques de Souza,ADM2,DISTRICT
SM255621,-140.13,-8.89,Marquesas,ETHNICITY,ETHNICITY
SM255621,-140.13,-8.89,Marquesas,ETHNICITY,ETHNICITY
SM255621,-140.13,-8.89,Marquesas,ETHNICITY,ETHNICITY
SM255621,-140.08,-8.9,Marquesas,ETHNICITY,ETHNICITY
SM255621,-140.08,-8.9,Marquesas,ETHNICITY,ETHNICITY
SM255621,-140.08,-8.9,Marquesas,ETHNICITY,ETHNICITY
SM255621,-140.17,-8.92,Marquesas,ETHNICITY,ETHNICITY
SM255621,-140.17,-8.92,Marquesas,ETHNICITY,ETHNICITY
SM255621,-140.17,-8.92,Marquesas,ETHNICITY,ETHNICITY
SM270842,-75.0803,5.2907,Marquetalia,ADM2,DISTRICT
SM303256,-89.3988,43.8196,Marquette,ADM2,DISTRICT
SM301302,-87.5735,46.663,Marquette,ADM2,DISTRICT
SM264573,-52.2568,-25.1057,Marquinho,ADM2,DISTRICT
SM17509,-60.9073,14.0296,Marquis Estate,ADM2,DISTRICT
SM237545,134.865,-14.7927,Marra,LANGUAGE,LANGUAGE
SM458727,135.4116,-15.0413,Marra,ETHNICITY,ETHNICITY
SM283929,32.6744,-25.7394,Marracuene,ADM2,DISTRICT
SM458501,140.1666,-18.2706,Marrago,ETHNICITY,ETHNICITY
SM281459,-8.0104,31.6362,Marrakech,ADM2,DISTRICT
SM4276,-7.9857,31.6349,Marrakesh-Safi,ADM1,DISTRICT
SM459229,144.1742,-14.3936,Marrett River language,ETHNICITY,ETHNICITY
SM458805,129.81,-13.8117,Marri Amu,ETHNICITY,ETHNICITY
SM458717,129.8507,-14.2107,Marri Ngarr,ETHNICITY,ETHNICITY
SM458804,129.8491,-13.8928,Marri Tjevin,ETHNICITY,ETHNICITY
SM237652,130.5,-12.28,Marriammu,LANGUAGE,LANGUAGE
SM459018,130.1768,-13.9993,Marridan,ETHNICITY,ETHNICITY
SM458903,130.0129,-13.8346,Marrithiyel^,ETHNICITY,ETHNICITY
SM283967,35.741,-18.4253,Marromeu,ADM2,DISTRICT
SM283962,37.543,-13.016,Marrupa,ADM2,DISTRICT
SM290582,25.5597,44.3736,Marsa,ADM2,DISTRICT
SM273844,27.0791,30.1569,Marsa Matruh,ADM2,DISTRICT
SM2170,37.6,2.9667,Marsabit,ADM1:ADM2,DISTRICT
SM290383,24.0192,44.0116,Marsani,ADM2,DISTRICT
SM283446,14.5514,35.8417,Marsaxlokk,ADM1:ADM2,DISTRICT
SM237661,5.3702,43.2923,Marseille Sign Language,DIALECT,LANGUAGE
SM270437,-75.7527,4.9524,Marsella,ADM2,DISTRICT
SM255623,47,31,Marsh Arabs,ETHNICITY,ETHNICITY
SM303125,-80.6634,39.8606,Marshall,ADM2,DISTRICT
SM302211,-96.7691,34.0245,Marshall,ADM2,DISTRICT
SM302596,-86.765,35.4689,Marshall,ADM2,DISTRICT
SM302515,-97.5985,45.7587,Marshall,ADM2,DISTRICT
SM301467,-89.5031,34.7623,Marshall,ADM2,DISTRICT
SM301377,-96.3685,48.3581,Marshall,ADM2,DISTRICT
SM300942,-96.5229,39.7836,Marshall,ADM2,DISTRICT
SM301082,-88.3294,36.8835,Marshall,ADM2,DISTRICT
SM300737,-86.2618,41.3249,Marshall,ADM2,DISTRICT
SM300844,-92.9988,42.0359,Marshall,ADM2,DISTRICT
SM300648,-89.3448,41.0332,Marshall,ADM2,DISTRICT
SM300288,-86.3066,34.367,Marshall,ADM2,DISTRICT
SM255624,170.02,6.97,Marshall Islands,ETHNICITY,ETHNICITY
SM255624,170.02,6.97,Marshall Islands,ETHNICITY,ETHNICITY
SM255624,170.02,6.97,Marshall Islands,ETHNICITY,ETHNICITY
SM255624,165.29,11.7,Marshall Islands,ETHNICITY,ETHNICITY
SM255624,165.29,11.7,Marshall Islands,ETHNICITY,ETHNICITY
SM255624,165.29,11.7,Marshall Islands,ETHNICITY,ETHNICITY
SM255624,169.49,6.28,Marshall Islands,ETHNICITY,ETHNICITY
SM255624,169.49,6.28,Marshall Islands,ETHNICITY,ETHNICITY
SM255624,169.49,6.28,Marshall Islands,ETHNICITY,ETHNICITY
SM237664,167.73,8.72,Marshallese,LANGUAGE,LANGUAGE
SM13683,25.5745,57.4241,Marsnenu pagasts,ADM2,DISTRICT
SM11324,42.3667,42.5333,Mart'vilis Munitsip'alit'et'I (excl. Martvili),ADM2,DISTRICT
SM285376,13.8728,12.4096,Marte,ADM2,DISTRICT
SM291258,24.7528,44.7279,Martesti,ADM2,DISTRICT
SM12518,-77.6591,18.4696,Martha Brae,ADM2,DISTRICT
SM237665,-70.6253,41.4145,Martha's Vineyard Sign Language,LANGUAGE,LANGUAGE
SM237667,129.837,-14.0058,Marti Ke,LANGUAGE,LANGUAGE
SM268994,7.1035,46.1239,Martigny,ADM2,DISTRICT
SM302702,-101.9512,32.306,Martin,ADM2,DISTRICT
SM301984,-77.1071,35.8416,Martin,ADM2,DISTRICT
SM301378,-94.5511,43.6744,Martin,ADM2,DISTRICT
SM301083,-82.5133,37.8016,Martin,ADM2,DISTRICT
SM300738,-86.8031,38.708,Martin,ADM2,DISTRICT
SM300349,-80.3985,27.0816,Martin,ADM2,DISTRICT
SM296188,18.9333,49.0667,Martin,ADM2,DISTRICT
SM17510,-61.0454,13.7894,Martin,ADM2,DISTRICT
SM12519,-76.8726,18.3012,Martin,ADM2,DISTRICT
SM15915,-65.9727,18.3758,Martin Gonzalez Barrio,ADM2,DISTRICT
SM290771,23.12,45.7928,Martinesti,ADM2,DISTRICT
SM263634,-45.1797,-19.4201,Martinho Campos,ADM2,DISTRICT
SM138,-60.9999,14.6664,Martinique,ADM0:ADM1:ADM2,DISTRICT
SM138,-60.9999,14.6664,Martinique,ADM0:ADM1:ADM2,DISTRICT
SM138,-60.9999,14.6664,Martinique,ADM0:ADM1:ADM2,DISTRICT
SM138,-60.9996,14.6666,Martinique,ADM0:ADM1:ADM2,DISTRICT
SM138,-60.9996,14.6666,Martinique,ADM0:ADM1:ADM2,DISTRICT
SM138,-60.9996,14.6666,Martinique,ADM0:ADM1:ADM2,DISTRICT
SM138,-61,14.6667,Martinique,ADM0:ADM1:ADM2,DISTRICT
SM138,-61,14.6667,Martinique,ADM0:ADM1:ADM2,DISTRICT
SM138,-61,14.6667,Martinique,ADM0:ADM1:ADM2,DISTRICT
SM237671,-61.0194,14.6369,Martinique Creole French,DIALECT,LANGUAGE
SM290699,25.3876,46.23,Martinis,ADM2,DISTRICT
SM265587,-37.9247,-6.0921,Martins,ADM2,DISTRICT
SM263636,-41.8398,-20.2599,Martins Soares,ADM2,DISTRICT
SM305450,16.3747,45.5867,Martinska Ves,ADM2,DISTRICT
SM303046,-79.8725,36.6915,Martinsville,ADM2,DISTRICT
SM267789,-40.6095,-3.1976,Martinópole,ADM2,DISTRICT
SM266689,-51.137,-22.1962,Martinópolis,ADM2,DISTRICT
SM14341,-98.2417,16.5462,Martires de Tacubaya,ADM2,DISTRICT
SM237669,123.6684,-23.3509,Martu Wangka,LANGUAGE,LANGUAGE
SM458096,123.6684,-23.3509,Martu Wangka,ETHNICITY,ETHNICITY
SM279939,56.5,50.75,Martukskiy,ADM2,DISTRICT
SM237668,116.607,-20.9294,Martuthunira,LANGUAGE,LANGUAGE
SM11325,42.3667,42.4167,Martvili,ADM2,DISTRICT
SM294157,41.4979,47.2487,Martynovskiy rayon,ADM2,DISTRICT
SM271012,-80.8504,22.9859,Martí,ADM2,DISTRICT
SM282735,-97.0466,20.1191,Martínez de la Torre (pre-2003),ADM2,DISTRICT
SM237672,99.8338,24.9425,Maru,LANGUAGE,LANGUAGE
SM285276,6.2789,11.5381,Maru,ADM2,DISTRICT
SM15916,-66.668,18.082,Marueno Barrio,ADM2,DISTRICT
SM260058,67.2527,31.4463,Maruf,ADM2,DISTRICT
SM278476,133.8154,34.2856,Marugame,ADM2,DISTRICT
SM267107,-37.1102,-10.7269,Maruim,ADM2,DISTRICT
SM255627,141.6,-26.61,Marula,ETHNICITY,ETHNICITY
SM255627,141.6,-26.61,Marula,ETHNICITY,ETHNICITY
SM255627,141.0307,-24.9599,Marula,ETHNICITY,ETHNICITY
SM255627,141.0307,-24.9599,Marula,ETHNICITY,ETHNICITY
SM270843,-75.3134,5.2185,Marulanda,ADM2,DISTRICT
SM264574,-51.6606,-23.7666,Marumbi,ADM2,DISTRICT
SM278756,140.7552,37.8735,Marumori,ADM2,DISTRICT
SM291429,24.4658,44.2368,Maruntei,ADM2,DISTRICT
SM458690,139.5024,-24.938,Marunuda,ETHNICITY,ETHNICITY
SM4009,23.9589,56.8864,Marupes Novads,ADM1,DISTRICT
SM13684,23.9591,56.8865,Marupes novads,ADM2,DISTRICT
SM305506,16.1863,46.2826,Maruševec,ADM2,DISTRICT
SM11785,52.6667,30.0833,Marvdasht,ADM2,DISTRICT
SM12520,-76.8265,18.0268,Marverley,ADM2,DISTRICT
SM289286,-7.3585,39.4052,Marvão,ADM2,DISTRICT
SM260089,71.2289,34.8618,Marwara,ADM2,DISTRICT
SM255630,74.3,26.7,Marwari,ETHNICITY,ETHNICITY
SM237681,75.0656,27.002,Marwari (India),LANGUAGE,LANGUAGE
SM237677,71.5671,28.3242,Marwari (Pakistan),LANGUAGE,LANGUAGE
SM323770,-110.3312,53.5142,Marwayne,ADM2:ADM3,DISTRICT
SM2171,62.5,37,Mary,ADM1,DISTRICT
SM2173,-76.75,39.0004,Maryland,ADM1,DISTRICT
SM2172,-7.75,4.75,Maryland,ADM1,DISTRICT
SM18518,-78.1283,18.3868,Maryland (Hanover),ADM2,DISTRICT
SM12521,-76.7432,18.0517,Maryland (Saint Andrew),ADM2,DISTRICT
SM268083,-48.6832,-17.991,Marzagão,ADM2,DISTRICT
SM291899,25.4623,43.9315,Marzanesti,ADM2,DISTRICT
SM284562,167.9974,-21.4911,Maré,ADM2,DISTRICT
SM2159,-70,19.4167,María Trinidad Sánchez,ADM1,DISTRICT
SM270687,-75.3436,9.9923,María la Baja,ADM2,DISTRICT
SM266686,-49.9418,-22.2088,Marília,ADM2,DISTRICT
SM282178,-100.0139,25.8605,Marín,ADM2,DISTRICT
SM237675,-72.4027,-6.607,Marúbo,LANGUAGE,LANGUAGE
SM305665,13.956,44.9565,Marčana,ADM2,DISTRICT
SM299948,37.3573,47.9057,Mar‹ns'kyi,ADM2,DISTRICT
SM15917,-65.9626,18.2938,Masa Barrio,ADM2,DISTRICT
SM255632,32.71,30.45,Masa'id,ETHNICITY,ETHNICITY
SM237687,34.2829,1.0591,Masaaba,LANGUAGE,LANGUAGE
SM250485,33.68,1.06,Masaba,ETHNICITY,ETHNICITY
SM250485,33.68,1.06,Masaba,ETHNICITY,ETHNICITY
SM250485,34,1,Masaba,ETHNICITY,ETHNICITY
SM250485,34,1,Masaba,ETHNICITY,ETHNICITY
SM237699,-41.5,-17,Masacara,LANGUAGE,LANGUAGE
SM237695,120.829,17.4501,Masadiit Itneg,LANGUAGE,LANGUAGE
SM275433,-90.809,14.0851,Masagua,ADM2,DISTRICT
SM275674,-87.9825,14.4043,Masaguara,ADM2,DISTRICT
SM295556,-89.4167,14.1667,Masahuat,ADM2,DISTRICT
SM237688,36.4573,-3.1419,Masai,LANGUAGE,LANGUAGE
SM306091,36.92,-1.97,Masai-Generic,ETHNICITY,ETHNICITY
SM306091,36.92,-1.97,Masai-Generic,ETHNICITY,ETHNICITY
SM306091,33.49,-4.9,Masai-Generic,ETHNICITY,ETHNICITY
SM306091,33.49,-4.9,Masai-Generic,ETHNICITY,ETHNICITY
SM306091,38.17,-0.59,Masai-Generic,ETHNICITY,ETHNICITY
SM306091,38.17,-0.59,Masai-Generic,ETHNICITY,ETHNICITY
SM306091,36.75,-3,Masai-Generic,ETHNICITY,ETHNICITY
SM306091,36.75,-3,Masai-Generic,ETHNICITY,ETHNICITY
SM255633,36,-2,Masai-Kenya,ETHNICITY,ETHNICITY
SM255633,36,-2,Masai-Kenya,ETHNICITY,ETHNICITY
SM255633,35.2,-1.5,Masai-Kenya,ETHNICITY,ETHNICITY
SM255633,35.2,-1.5,Masai-Kenya,ETHNICITY,ETHNICITY
SM250486,36.75,-3.5,Masai-Tanzania,ETHNICITY,ETHNICITY
SM250486,36.75,-3.5,Masai-Tanzania,ETHNICITY,ETHNICITY
SM250486,37,-4,Masai-Tanzania,ETHNICITY,ETHNICITY
SM250486,37,-4,Masai-Tanzania,ETHNICITY,ETHNICITY
SM305168,28.719,-13.409,Masaiti,ADM2,DISTRICT
SM4741,31.7475,-0.3308,Masaka (2010-),ADM1:ADM2,DISTRICT
SM240854,22.1752,13.2733,Masalit,LANGUAGE,LANGUAGE
SM261938,48.6667,39,Masallı,ADM1:ADM2,DISTRICT
SM237701,15.4748,10.0606,Masana,LANGUAGE,LANGUAGE
SM10896,33.0767,35.1839,Masari,ADM2,DISTRICT
SM299122,38.9829,-10.7616,Masasi,ADM2,DISTRICT
SM299121,38.8501,-10.7218,Masasi Township Authority,ADM2,DISTRICT
SM285444,-86.1495,11.9057,Masatepe,ADM2,DISTRICT
SM285445,-86.0688,11.9992,Masaya,ADM2,DISTRICT
SM2175,-86.1667,12,Masaya,ADM1,DISTRICT
SM2176,123.5833,12.1667,Masbate,ADM1:ADM2,DISTRICT
SM237706,124.002,12.8438,Masbate Sorsogon,LANGUAGE,LANGUAGE
SM255636,123.44,12.34,Masbateno,ETHNICITY,ETHNICITY
SM237707,123.6358,12.2145,Masbatenyo,LANGUAGE,LANGUAGE
SM2177,0.1667,35.4167,Mascara,ADM1,DISTRICT
SM457578,0.25,34.8333,Mascara District,ADM2,DISTRICT
SM281824,-104.8053,20.5494,Mascota,ADM2,DISTRICT
SM267534,-39.428,-15.6404,Mascote,ADM2,DISTRICT
SM2178,27.8333,-29.5833,Maseru,ADM1,DISTRICT
SM13982,27.475,-29.3064,Maseru Central,ADM2,DISTRICT
SM296723,31.1674,-27.1477,Maseyisini,ADM2,DISTRICT
SM286385,5.3004,60.7995,Masfjorden,ADM2,DISTRICT
SM260543,56.0516,24.8398,Masfut,ADM2,DISTRICT
SM284508,20.1336,-17.9023,Mashare,ADM2,DISTRICT
SM255638,-70.97,-11.98,Mashco,ETHNICITY,ETHNICITY
SM237721,-70.7953,-11.4745,Mashco Piro,LANGUAGE,LANGUAGE
SM285029,5.2339,9.9299,Mashegu,ADM2,DISTRICT
SM11786,59.8,36.2833,Mashhad,ADM2,DISTRICT
SM284916,7.9933,13.1323,Mashi,ADM2,DISTRICT
SM250489,23.05,-16.52,Mashi,ETHNICITY,ETHNICITY
SM250489,22.11,-16.99,Mashi,ETHNICITY,ETHNICITY
SM237720,10.2778,6.9172,Mashi (Nigeria),LANGUAGE,LANGUAGE
SM237718,22.5983,-16.7256,Mashi (Zambia),LANGUAGE,LANGUAGE
SM278298,141.5372,43.7894,Mashike,ADM2,DISTRICT
SM299645,34.8775,49.4444,Mashivs'kyi,ADM2,DISTRICT
SM2179,31.2,-16.7,Mashonaland Central,ADM1,DISTRICT
SM2180,31.6667,-17.9667,Mashonaland East,ADM1,DISTRICT
SM2181,29.75,-17.1667,Mashonaland West,ADM1,DISTRICT
SM304985,43.9983,13.5417,Mashra'a Wa Hadnan,ADM2,DISTRICT
SM237730,43.8156,-22.5582,Masikoro Malagasy,LANGUAGE,LANGUAGE
SM237727,139.04,-2.0072,Masimasi,LANGUAGE,LANGUAGE
SM299336,31.743,1.569,Masindi (2010-),ADM1:ADM2,DISTRICT
SM269656,28.8167,-1.4,Masisi,ADM2,DISTRICT
SM237728,130.601,-3.2955,Masiwang,LANGUAGE,LANGUAGE
SM237737,167.818,-16.5073,Maskelynes,LANGUAGE,LANGUAGE
SM9801,27.5028,53.8742,Maskowski Rayon,ADM2,DISTRICT
SM237736,-61.901,-22.234,Maskoy Pidgin,LANGUAGE,LANGUAGE
SM237738,14.8873,12.1918,Maslam,LANGUAGE,LANGUAGE
SM293870,84.5,54.5,Maslyaninskiy rayon,ADM2,DISTRICT
SM237739,19.2769,13.076,Masmaje,LANGUAGE,LANGUAGE
SM303084,-123.1831,47.3505,Mason,ADM2,DISTRICT
SM303126,-82.0265,38.7697,Mason,ADM2,DISTRICT
SM302703,-99.2261,30.7177,Mason,ADM2,DISTRICT
SM301303,-86.4226,43.9563,Mason,ADM2,DISTRICT
SM301084,-83.8243,38.5952,Mason,ADM2,DISTRICT
SM300649,-89.9168,40.2397,Mason,ADM2,DISTRICT
SM12522,-76.9206,18.3966,Mason Hall,ADM2,DISTRICT
SM250487,15.25,10.333,Massa,ETHNICITY,ETHNICITY
SM250487,15.25,10.333,Massa,ETHNICITY,ETHNICITY
SM250487,15.25,10.333,Massa,ETHNICITY,ETHNICITY
SM250487,15.25,10.333,Massa,ETHNICITY,ETHNICITY
SM250487,16,10,Massa,ETHNICITY,ETHNICITY
SM250487,16,10,Massa,ETHNICITY,ETHNICITY
SM250487,16,10,Massa,ETHNICITY,ETHNICITY
SM250487,16,10,Massa,ETHNICITY,ETHNICITY
SM250487,15.5,10.5,Massa,ETHNICITY,ETHNICITY
SM250487,15.5,10.5,Massa,ETHNICITY,ETHNICITY
SM250487,15.5,10.5,Massa,ETHNICITY,ETHNICITY
SM250487,15.5,10.5,Massa,ETHNICITY,ETHNICITY
SM250487,15.3,10.3,Massa,ETHNICITY,ETHNICITY
SM250487,15.3,10.3,Massa,ETHNICITY,ETHNICITY
SM250487,15.3,10.3,Massa,ETHNICITY,ETHNICITY
SM250487,15.3,10.3,Massa,ETHNICITY,ETHNICITY
SM277913,10.05,44.25,Massa Carrara,ADM2,DISTRICT
SM300650,-88.7077,37.219,Massac,ADM2,DISTRICT
SM255641,-71.14,42.43,Massachusett,ETHNICITY,ETHNICITY
SM2183,-71.1083,42.3657,Massachusetts,ADM1,DISTRICT
SM17511,-61.0354,13.9487,Massacre,ADM2,DISTRICT
SM17512,-60.9495,14.0829,Massade,ADM2,DISTRICT
SM237742,19.7348,13.5157,Massalat,LANGUAGE,LANGUAGE
SM283902,32.6566,-21.742,Massangena,ADM2,DISTRICT
SM260346,16.5737,-7.8006,Massango,ADM2,DISTRICT
SM267790,-40.3665,-3.5091,Massapê,ADM2,DISTRICT
SM265054,-41.0176,-7.5388,Massapê do Piauí,ADM2,DISTRICT
SM266207,-48.9821,-26.6198,Massaranduba,ADM2,DISTRICT
SM264240,-35.7838,-7.2048,Massaranduba,ADM2,DISTRICT
SM237743,138.294,-1.7468,Massep,LANGUAGE,LANGUAGE
SM283913,35.2617,-23.0323,Massinga,ADM2,DISTRICT
SM283893,32.0522,-23.8236,Massingir,ADM2,DISTRICT
SM290450,28.0418,45.767,Mastacani,ADM2,DISTRICT
SM286521,175.6667,-40.95,Masterton,ADM2,DISTRICT
SM375381,66.7207,29.7946,Mastung,ADM2:ADM3,DISTRICT
SM262621,24.5,53.4167,Masty,ADM2,DISTRICT
SM279251,131.9459,34.6291,Masuda,ADM2,DISTRICT
SM268531,27.5664,-21.0174,Masungu,ADM2,DISTRICT
SM305263,30.9001,-20.3148,Masvingo,ADM2,DISTRICT
SM2184,31.4,-20.6,Masvingo,ADM1,DISTRICT
SM299178,33.786,-3.2,Maswa,ADM2,DISTRICT
SM304778,43.6925,15.5986,Maswar,ADM2,DISTRICT
SM305005,45.7033,14.4027,Maswarah,ADM2,DISTRICT
SM296780,36.3392,35.1034,Masyaf,ADM2,DISTRICT
SM265757,-54.4455,-29.549,Mata,ADM2,DISTRICT
SM265387,-37.7615,-8.9854,Mata Grande,ADM2,DISTRICT
SM260156,68.8861,33.2971,Mata Khan,ADM2,DISTRICT
SM262819,-43.2318,-3.5822,Mata Roma,ADM2,DISTRICT
SM263637,-40.6988,-15.7679,Mata Verde,ADM2,DISTRICT
SM15918,-66.3708,18.2581,Mata de Canas Barrio,ADM2,DISTRICT
SM15919,-65.7327,18.3673,Mata de Platano Barrio,ADM2,DISTRICT
SM267535,-38.1555,-12.4824,Mata de São João,ADM2,DISTRICT
SM2185,27.4167,-18.8333,Matabeleland North,ADM1,DISTRICT
SM2186,28.8,-20.9,Matabeleland South,ADM1,DISTRICT
SM283248,-107.7123,28.8206,Matachic,ADM2,DISTRICT
SM261193,-62.1667,-23.8333,Matacos,ADM2,DISTRICT
SM283786,115.5333,47.1451,Matad,ADM2,DISTRICT
SM269567,13.488,-5.8325,Matadi,ADM2,DISTRICT
SM237757,-86,13.0333,Matagalpa,LANGUAGE,LANGUAGE
SM285457,-85.9355,12.8968,Matagalpa,ADM2,DISTRICT
SM2187,-85.6667,12.9167,Matagalpa,ADM1,DISTRICT
SM302704,-96.004,28.7857,Matagorda,ADM2,DISTRICT
SM295437,160.6944,-8.3917,Matakwalao,ADM2,DISTRICT
SM237761,14.0474,10.9284,Matal,LANGUAGE,LANGUAGE
SM260311,15.1725,-15.2397,Matala,ADM2,DISTRICT
SM2188,80.7073,7.6598,Matale,ADM1:ADM2,DISTRICT
SM295289,-13.6339,15.7319,Matam,ADM2,DISTRICT
SM2189,-13.6667,15.1667,Matam,ADM1,DISTRICT
SM286511,175.6725,-37.6855,Matamata-Piako,ADM2,DISTRICT
SM284629,8.6,13.4,Matameye,ADM2,DISTRICT
SM282651,-97.6014,25.7217,Matamoros,ADM2,DISTRICT
SM283249,-105.5892,26.7497,Matamoros,ADM2,DISTRICT
SM283285,-103.2476,25.5566,Matamoros,ADM2,DISTRICT
SM262049,29.6706,-3.7431,Matana,ADM2,DISTRICT
SM237750,-58.9886,-4.851,Matanawí,LANGUAGE,LANGUAGE
SM295462,159.8173,-11.305,Matangi,ADM2,DISTRICT
SM295382,159.9591,-9.4455,Mataniko,ADM2,DISTRICT
SM301178,-150.0056,62.5006,Matanuska-Susitna,ADM2,DISTRICT
SM270488,-73.053,7.3561,Matanza,ADM2,DISTRICT
SM271001,-81.5833,23.05,Matanzas,ADM2,DISTRICT
SM2191,-81.1667,22.6667,Matanzas,ADM1,DISTRICT
SM275175,-90.2001,14.5478,Mataquescuintla,ADM2,DISTRICT
SM2192,80.528,6.1329,Matara,ADM1:ADM2,DISTRICT
SM264241,-35.0369,-6.5548,Mataraca,ADM2,DISTRICT
SM276490,116.1167,-8.5833,Mataram,ADM2,DISTRICT
SM290638,23.0682,44.8576,Matasari,ADM2,DISTRICT
SM291260,25.1005,45.234,Matau,ADM2,DISTRICT
SM273660,30.7175,28.4046,Matay,ADM2,DISTRICT
SM284917,7.6178,12.259,Matazu,ADM2,DISTRICT
SM237765,130.075,-1.8801,Matbat,LANGUAGE,LANGUAGE
SM290451,27.5366,45.8569,Matca,ADM2,DISTRICT
SM305974,69.4921,40.6715,Matchin,ADM2,DISTRICT
SM285436,-86.4046,12.2037,Mateare,ADM2,DISTRICT
SM292207,23.8549,45.0678,Mateesti,ADM2,DISTRICT
SM462118,35.2248,31.8983,Mateh Binyamin Regional Council,ADM2,DISTRICT
SM282486,-100.6207,23.5783,Matehuala,ADM2,DISTRICT
SM292507,24.2603,46.9993,Matei,ADM2,DISTRICT
SM268084,-50.6184,-18.7749,Mateira,ADM2,DISTRICT
SM267258,-46.4838,-10.418,Mateiros,ADM2,DISTRICT
SM264575,-53.8784,-25.3922,Matelândia,ADM2,DISTRICT
SM237771,35.0056,-11.554,Matengo,LANGUAGE,LANGUAGE
SM250490,34.99,-10.63,Matengo,ETHNICITY,ETHNICITY
SM237773,145.617,-5.1386,Matepi,LANGUAGE,LANGUAGE
SM234387,110.468,0.6618,Mateq,LANGUAGE,LANGUAGE
SM277935,16.4167,40.5,Matera,ADM2,DISTRICT
SM263638,-43.0144,-18.4466,Materlândia,ADM2,DISTRICT
SM262820,-44.4075,-3.7788,Mates do Norte,ADM2,DISTRICT
SM298067,9.6667,37,Mateur,ADM2,DISTRICT
SM263639,-44.4342,-20.0163,Mateus Leme,ADM2,DISTRICT
SM303048,-76.2713,37.4173,Mathews,ADM2,DISTRICT
SM263640,-41.9619,-18.6048,Mathias Lobato,ADM2,DISTRICT
SM10897,33.3399,34.9637,Mathiatis,ADM2,DISTRICT
SM10898,33.0605,34.7824,Mathikoloni,ADM2,DISTRICT
SM277316,77.5833,27.6333,Mathura,ADM2,DISTRICT
SM375484,68.4134,25.8758,Matiari,ADM2:ADM3,DISTRICT
SM263641,-43.3051,-21.8748,Matias Barbosa,ADM2,DISTRICT
SM263642,-43.6979,-14.8456,Matias Cardoso,ADM2,DISTRICT
SM265055,-42.607,-3.6877,Matias Olímpio,ADM2,DISTRICT
SM14342,-95.0217,17.2016,Matias Romero Avendano,ADM2,DISTRICT
SM237777,125.16,7.7212,Matigsalug Manobo,LANGUAGE,LANGUAGE
SM285458,-85.3867,12.8708,Matiguás,ADM2,DISTRICT
SM270944,-83.2643,10.0135,Matina,ADM2,DISTRICT
SM267536,-42.9828,-13.8934,Matina,ADM2,DISTRICT
SM262821,-44.9921,-3.0959,Matinha,ADM2,DISTRICT
SM264242,-35.7607,-7.1243,Matinhas,ADM2,DISTRICT
SM264576,-48.5729,-25.7611,Matinhos,ADM2,DISTRICT
SM237780,-53.3994,-12.0456,Matipuhy,LANGUAGE,LANGUAGE
SM263643,-42.3161,-20.3023,Matipó,ADM2,DISTRICT
SM13685,25.1485,57.7129,Matisu pagasts,ADM2,DISTRICT
SM260480,20.0833,41.5833,Matit,ADM2,DISTRICT
SM458688,139.9625,-27.3228,Matja,ETHNICITY,ETHNICITY
SM13686,22.5529,56.9847,Matkules pagasts,ADM2,DISTRICT
SM5207,98.896,22.2654,Matman,ADM2,DISTRICT
SM298076,9.8019,33.4519,Matmata,ADM2,DISTRICT
SM298077,9.8852,33.666,Matmata Nouvelle,ADM2,DISTRICT
SM458759,130.4336,-13.7886,Matngala,ETHNICITY,ETHNICITY
SM237788,146.859,-5.9164,Mato,LANGUAGE,LANGUAGE
SM265758,-52.1763,-28.2725,Mato Castelhano,ADM2,DISTRICT
SM264243,-37.7507,-6.5473,Mato Grosso,ADM2,DISTRICT
SM263182,-60.0578,-14.8915,Mato Grosso,ADM2,DISTRICT
SM2193,-56,-13,Mato Grosso,ADM1,DISTRICT
SM237789,-60.6289,-9.1726,Mato Grosso Arára,LANGUAGE,LANGUAGE
SM2194,-55,-20.5,Mato Grosso do Sul,ADM1,DISTRICT
SM265759,-52.1386,-29.5146,Mato Leitão,ADM2,DISTRICT
SM9961,-54.6711,-28.2568,Mato Queimado,ADM2:ADM3,DISTRICT
SM264578,-52.2409,-24.7069,Mato Rico,ADM2,DISTRICT
SM263644,-42.8746,-15.4288,Mato Verde,ADM2,DISTRICT
SM305278,28.4946,-20.9555,Matobo,ADM2,DISTRICT
SM15920,-66.2075,18.1262,Maton Abajo Barrio,ADM2,DISTRICT
SM15921,-66.1941,18.1072,Maton Arriba Barrio,ADM2,DISTRICT
SM262088,29.6119,-3.0894,Matongo,ADM2,DISTRICT
SM237786,102.856,53.4349,Mator-Taigi-Karagas,LANGUAGE,LANGUAGE
SM266208,-51.1629,-26.4836,Matos Costa,ADM2,DISTRICT
SM289316,-8.6674,41.2077,Matosinhos,ADM2,DISTRICT
SM263645,-44.0503,-19.5207,Matozinhos,ADM2,DISTRICT
SM268085,-50.8266,-15.3382,Matrinchã,ADM2,DISTRICT
SM265388,-35.5761,-9.1383,Matriz de Camaragibe,ADM2,DISTRICT
SM2195,27.5,29.7,Matrouh,ADM1,DISTRICT
SM296695,31.7198,-27.2397,Matsanjeni North,ADM2,DISTRICT
SM296724,31.7231,-27.2159,Matsanjeni South,ADM2,DISTRICT
SM279103,135.5521,34.5773,Matsubara,ADM2,DISTRICT
SM279363,139.9291,35.7957,Matsudo,ADM2,DISTRICT
SM13004,133.0592,35.477,Matsue Shi (2011-),ADM2,DISTRICT
SM13005,140.1442,41.5288,Matsumae-gun,ADM2,DISTRICT
SM13006,137.8142,36.2042,Matsumoto Shi (2010-),ADM2,DISTRICT
SM278696,136.5333,34.5667,Matsusaka,ADM2,DISTRICT
SM278891,129.735,33.3533,Matsuura,ADM2,DISTRICT
SM279625,132.7622,33.8357,Matsuyama,ADM2,DISTRICT
SM237792,-72.6281,-5.7391,Matsés,LANGUAGE,LANGUAGE
SM261788,16.3949,47.7382,Mattersburg,ADM2,DISTRICT
SM255653,-124.14,40.2,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.14,40.2,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.14,40.2,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.14,40.2,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.14,40.2,Mattole,ETHNICITY,ETHNICITY
SM255653,-124,40,Mattole,ETHNICITY,ETHNICITY
SM255653,-124,40,Mattole,ETHNICITY,ETHNICITY
SM255653,-124,40,Mattole,ETHNICITY,ETHNICITY
SM255653,-124,40,Mattole,ETHNICITY,ETHNICITY
SM255653,-124,40,Mattole,ETHNICITY,ETHNICITY
SM255653,-123.47,40.35,Mattole,ETHNICITY,ETHNICITY
SM255653,-123.47,40.35,Mattole,ETHNICITY,ETHNICITY
SM255653,-123.47,40.35,Mattole,ETHNICITY,ETHNICITY
SM255653,-123.47,40.35,Mattole,ETHNICITY,ETHNICITY
SM255653,-123.47,40.35,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.04,40.17,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.04,40.17,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.04,40.17,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.04,40.17,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.04,40.17,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.1,40.4,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.1,40.4,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.1,40.4,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.1,40.4,Mattole,ETHNICITY,ETHNICITY
SM255653,-124.1,40.4,Mattole,ETHNICITY,ETHNICITY
SM237794,-122.863,40.4321,Mattole-Bear River,LANGUAGE,LANGUAGE
SM237800,145.779,-4.9078,Matukar,LANGUAGE,LANGUAGE
SM305416,14.3258,45.3642,Matulji,ADM2,DISTRICT
SM237798,39.0409,-8.5373,Matumbi,LANGUAGE,LANGUAGE
SM280098,37.3,-1.25,Matungulu,ADM2,DISTRICT
SM263183,-54.355,-10.0153,Matupá,ADM2,DISTRICT
SM264244,-37.3394,-7.2718,Maturéia,ADM2,DISTRICT
SM303667,-63.0333,9.4,Maturín,ADM2,DISTRICT
SM263647,-45.9956,-19.1936,Matutina,ADM2,DISTRICT
SM283930,32.5882,-26.4342,Matutuíne,ADM2,DISTRICT
SM15922,-65.9703,18.0433,Matuyas Alto Barrio,ADM2,DISTRICT
SM15923,-65.9475,18.0334,Matuyas Bajo Barrio,ADM2,DISTRICT
SM237805,-3.2513,13.1027,Matya Samo,LANGUAGE,LANGUAGE
SM266690,-48.4503,-21.5955,Matão,ADM2,DISTRICT
SM262136,1.0276,10.7986,Matéri,ADM2,DISTRICT
SM237782,-70.2079,-4.3553,Matís,LANGUAGE,LANGUAGE
SM262822,-43.4005,-5.4154,Matões,ADM2,DISTRICT
SM277317,83.5076,26.0294,Mau,ADM2,DISTRICT
SM255656,36.7,34.4,Mauali Bedu,ETHNICITY,ETHNICITY
SM255656,38.76,33.2,Mauali Bedu,ETHNICITY,ETHNICITY
SM297799,125.2,-8.6167,Maubara,ADM2,DISTRICT
SM283461,95.5562,16.9604,Maubin,ADM2,DISTRICT
SM297818,125.6301,-8.8423,Maubisse,ADM2,DISTRICT
SM17961,125.2298,-9.2177,Maucatar (excl. Suai),ADM2,DISTRICT
SM9962,-58.3029,-5.0633,Maues,ADM2,DISTRICT
SM15288,145.22,20.015,Maug Islands,ADM2,DISTRICT
SM276788,-4.3199,54.2976,Maughold,ADM1:ADM2,DISTRICT
SM300543,-156.6171,20.8677,Maui,ADM2,DISTRICT
SM2196,-71.5,-35.5,Maule,ADM1,DISTRICT
SM262285,91.8333,24.5,Maulvibazar,ADM2,DISTRICT
SM2197,-65.9274,18.0169,Maunabo,ADM1,DISTRICT
SM15924,-65.9007,18.0078,Maunabo Barrio-Pueblo,ADM2,DISTRICT
SM259529,87.4386,26.9225,Maunabudhuk and Bodhe Local Sign,LANGUAGE,LANGUAGE
SM15096,92.3762,21.0038,Maungdaw District,ADM2,DISTRICT
SM11248,-152.256,-16.4461,Maupiti,ADM2,DISTRICT
SM2198,9.559,47.2151,Mauren,ADM1,DISTRICT
SM290029,21.5135,45.4317,Maureni,ADM2,DISTRICT
SM10215,-73.4158,47.5501,Mauricie,ADM2,DISTRICT
SM268086,-50.3294,-18.0504,Maurilândia,ADM2,DISTRICT
SM267259,-47.5799,-5.9861,Maurilândia do Tocantins,ADM2,DISTRICT
SM237812,57.5167,-20.1667,Mauritian Sign Language,LANGUAGE,LANGUAGE
SM303567,-71,10.8333,Mauroa,ADM2,DISTRICT
SM302597,-87.077,35.6169,Maury,ADM2,DISTRICT
SM237815,145.394,-4.5242,Mauwake,LANGUAGE,LANGUAGE
SM266691,-46.4466,-23.6636,Mauá,ADM2,DISTRICT
SM264579,-51.1803,-23.9111,Mauá da Serra,ADM2,DISTRICT
SM283964,36.4064,-12.2353,Mavago,ADM2,DISTRICT
SM17513,-61.0338,13.8068,Mavay,ADM2,DISTRICT
SM302705,-100.3145,28.7426,Maverick,ADM2,DISTRICT
SM15925,-66.3467,18.363,Mavilla Barrio,ADM2,DISTRICT
SM260421,20.17,-16.0777,Mavinga,ADM2,DISTRICT
SM12523,-76.6588,18.0384,Mavis Bank,ADM2,DISTRICT
SM2199,20.6839,41.6578,Mavrovo and Rostuša,ADM1,DISTRICT
SM237825,18.2999,11.7461,Mawa (Chad),LANGUAGE,LANGUAGE
SM237818,9.7241,10.8527,Mawa (Nigeria),LANGUAGE,LANGUAGE
SM237822,145.283,-4.6182,Mawak,LANGUAGE,LANGUAGE
SM237824,-66.2384,3.11,Mawakwa,LANGUAGE,LANGUAGE
SM237823,145.589,-5.2219,Mawan,LANGUAGE,LANGUAGE
SM237826,-58.5046,1.9882,Mawayana,LANGUAGE,LANGUAGE
SM237827,73.9956,21.5724,Mawchi,LANGUAGE,LANGUAGE
SM255666,-55.35,-2.75,Mawe,ETHNICITY,ETHNICITY
SM255666,-55.35,-2.75,Mawe,ETHNICITY,ETHNICITY
SM255666,-55.35,-2.75,Mawe,ETHNICITY,ETHNICITY
SM255666,-56.21,-3.4,Mawe,ETHNICITY,ETHNICITY
SM255666,-56.21,-3.4,Mawe,ETHNICITY,ETHNICITY
SM255666,-56.21,-3.4,Mawe,ETHNICITY,ETHNICITY
SM255666,-57,-4,Mawe,ETHNICITY,ETHNICITY
SM255666,-57,-4,Mawe,ETHNICITY,ETHNICITY
SM255666,-57,-4,Mawe,ETHNICITY,ETHNICITY
SM237828,139.752,-2.3694,Mawes,LANGUAGE,LANGUAGE
SM304986,44.3191,13.5846,Mawiyah,ADM2,DISTRICT
SM283520,97.8518,15.7614,Mawlamyine,ADM2,DISTRICT
SM283473,94.7174,23.9276,Mawleik,ADM2,DISTRICT
SM237810,133.376,-11.66,Mawng,LANGUAGE,LANGUAGE
SM458900,133.364,-11.8139,Mawng,ETHNICITY,ETHNICITY
SM304987,43.6145,13.3782,Mawza,ADM2,DISTRICT
SM237832,-40.5942,-16.8637,Maxakali Sign Language,LANGUAGE,LANGUAGE
SM237831,-40.7891,-16.9402,Maxakalí,LANGUAGE,LANGUAGE
SM265588,-35.3566,-5.4687,Maxaranguape,ADM2,DISTRICT
SM282981,-90.0498,20.6248,Maxcanú,ADM2,DISTRICT
SM12524,-76.808,17.9994,Maxfield Park,ADM2,DISTRICT
SM237833,1.7818,7.5729,Maxi Gbe,LANGUAGE,LANGUAGE
SM265760,-51.8036,-27.588,Maximiliano de Almaeida,ADM2,DISTRICT
SM292681,27.6647,45.418,Maxineni,ADM2,DISTRICT
SM255668,19.9,-7.84,Maxinje,ETHNICITY,ETHNICITY
SM15069,35.3095,-23.8817,Maxixe,ADM2,DISTRICT
SM280764,102.7259,21.3847,May,ADM2,DISTRICT
SM237865,105.6222,17.9815,May,DIALECT,LANGUAGE
SM12525,-77.4761,18.0095,May Day,ADM2,DISTRICT
SM274019,38.5008,14.552,May Mine,ADM2,DISTRICT
SM12526,-77.2391,17.9703,May Pen Proper,ADM2,DISTRICT
SM237838,-2.7273,13.1811,Maya Samo,LANGUAGE,LANGUAGE
SM2200,-82.1472,22.9616,Mayabeque,ADM1,DISTRICT
SM296777,40.3041,34.8565,Mayadin,ADM2,DISTRICT
SM2201,-72.9539,22.3842,Mayaguana,ADM1,DISTRICT
SM237837,140.337,-18.1147,Mayaguduna,LANGUAGE,LANGUAGE
SM15926,-67.1196,18.2041,Mayagueez Arriba Barrio,ADM2,DISTRICT
SM15927,-67.1464,18.2036,Mayagueez Barrio-Pueblo,ADM2,DISTRICT
SM255669,-66.5,18.21,Mayaguez,ETHNICITY,ETHNICITY
SM2202,-67.1024,18.205,Mayagüez,ADM1,DISTRICT
SM284611,7.6,14.1,Mayahi,ADM2,DISTRICT
SM458887,132.4537,-14.0803,Mayali,ETHNICITY,ETHNICITY
SM269804,14.8917,-3.8472,Mayama,ADM2,DISTRICT
SM237841,-84.3439,14.1259,Mayangna,LANGUAGE,LANGUAGE
SM282982,-89.1911,20.4948,Mayapán,ADM2,DISTRICT
SM2203,-61.0833,10.1667,Mayaro/Rio Claro (1992-),ADM1,DISTRICT
SM271125,-75.6833,20.65,Mayarí,ADM2,DISTRICT
SM276519,132.3509,-1.2155,Maybrat,ADM2,DISTRICT
SM237035,132.591,-1.3679,Maybrat-Karon,LANGUAGE,LANGUAGE
SM260231,68.8247,34.4439,Maydan Shahr,ADM2,DISTRICT
SM255671,-51.58,3.91,Maye,ETHNICITY,ETHNICITY
SM237845,17.8225,-1.1004,Mayeka,LANGUAGE,LANGUAGE
SM237845,17.8225,-1.1004,Mayeka,LANGUAGE,LANGUAGE
SM237845,24,4,Mayeka,LANGUAGE,LANGUAGE
SM237845,24,4,Mayeka,LANGUAGE,LANGUAGE
SM274460,-0.6667,48.1349,Mayenne,ADM2,DISTRICT
SM324076,-115.1346,53.9483,Mayerthorpe,ADM2:ADM3,DISTRICT
SM302212,-95.2309,36.3019,Mayes,ADM2,DISTRICT
SM16328,13.6377,-3.6204,Mayeye,ADM2,DISTRICT
SM304964,47.7404,14.3978,Mayfa'a,ADM2,DISTRICT
SM304802,44.6106,14.5152,Mayfa'at Anss,ADM2,DISTRICT
SM237848,141.46,-18.56,Mayi-Kulan,DIALECT,LANGUAGE
SM458482,141.8124,-18.7804,Mayi-Kulan,ETHNICITY,ETHNICITY
SM458481,139.8963,-19.1208,Mayi-Kutuna,ETHNICITY,ETHNICITY
SM237846,140.63,-20,Mayi-Thakurti,DIALECT,LANGUAGE
SM458472,140.6895,-19.9469,Mayi-Thakurti / Mitakoodi,ETHNICITY,ETHNICITY
SM458500,139.7479,-18.9415,Mayi-Yali,ETHNICITY,ETHNICITY
SM237847,140.61,-19.25,Mayi-Yapi,DIALECT,LANGUAGE
SM458477,140.4472,-19.0756,Mayi-Yapi,ETHNICITY,ETHNICITY
SM296682,31.5181,-25.8645,Mayiwane,ADM2,DISTRICT
SM292717,40.1234,44.611,Maykop,ADM2,DISTRICT
SM292718,40.25,44.3333,Maykopskiy rayon,ADM2,DISTRICT
SM237849,140.481,-20.4352,Maykulan,LANGUAGE,LANGUAGE
SM260287,64.75,35.9333,Maymana,ADM2,DISTRICT
SM292872,86,51.8333,Mayminskiy rayon,ADM2,DISTRICT
SM17514,-60.9897,14.0031,Maynard Hill,ADM2,DISTRICT
SM294877,47.6667,54.1667,Maynskiy rayon,ADM2,DISTRICT
SM237854,-108.981,26.4522,Mayo,LANGUAGE,LANGUAGE
SM297295,101.4036,6.7076,Mayo,ADM2,DISTRICT
SM2204,-9.25,53.9,Mayo,ADM1:ADM2,DISTRICT
SM255672,-108.55,25.78,Mayo,ETHNICITY,ETHNICITY
SM269498,11.7352,6.5814,Mayo Banyo,ADM2,DISTRICT
SM269521,15,10.5,Mayo Danay,ADM2,DISTRICT
SM269522,14.5186,10.2243,Mayo Kani,ADM2,DISTRICT
SM269538,13.7274,9.9658,Mayo Louti,ADM2,DISTRICT
SM269539,14.6146,8.1263,Mayo Rey,ADM2,DISTRICT
SM269523,14.2056,11.1068,Mayo Sava,ADM2,DISTRICT
SM269524,13.7935,10.5822,Mayo Tsanaga,ADM2,DISTRICT
SM284835,11.9768,8.971,Mayo-Bel,ADM2,DISTRICT
SM10320,14.4943,9.8857,Mayo-Binder,ADM2,DISTRICT
SM10321,15.5943,10.4113,Mayo-Boneye (2008-),ADM2,DISTRICT
SM10322,14.8434,9.3425,Mayo-Dallah (excl. Gagal),ADM2,DISTRICT
SM2205,15.83,10,Mayo-Kebbi Est,ADM1,DISTRICT
SM2206,14.75,9.33,Mayo-Kebbi Ouest,ADM1,DISTRICT
SM10323,15.5335,10.862,Mayo-Lemie,ADM2,DISTRICT
SM237851,27.8282,2.7983,Mayogo,LANGUAGE,LANGUAGE
SM16329,12.8083,-2.2847,Mayoko (1994-),ADM2,DISTRICT
SM255673,-66.58,6.43,Mayop,ETHNICITY,ETHNICITY
SM289617,-58.1456,-27.2009,Mayor José J. Martinez,ADM2,DISTRICT
SM289572,-54.7689,-26.2784,Mayor Julio D. Otaño,ADM2,DISTRICT
SM261087,-60.6667,-27.75,Mayor Luis J. Fontana,ADM2,DISTRICT
SM255674,-73.18,-5.46,Mayoruna,ETHNICITY,ETHNICITY
SM237859,-70.2805,-4.3229,Mayoruna-Amazon,LANGUAGE,LANGUAGE
SM237858,-70.0388,-4.9799,Mayoruna-Jandiatuba,LANGUAGE,LANGUAGE
SM237860,-69.874,-4.29,Mayoruna-Tabatinga,LANGUAGE,LANGUAGE
SM237852,121.29,16.9984,Mayoyao Ifugao,LANGUAGE,LANGUAGE
SM280793,102.9377,18.1397,Mayparkngum,ADM2,DISTRICT
SM2207,47.0667,31.9,Maysan,ADM1,DISTRICT
SM279993,77.5,51,Mayskiy,ADM2,DISTRICT
SM17000,44.0833,43.6667,Mayskiy Rayon,ADM2,DISTRICT
SM2208,33.561,0.269,Mayuge,ADM1:ADM2,DISTRICT
SM277121,86.5,21.75,Mayurbhanj,ADM2,DISTRICT
SM9561,64.9933,31.5836,Maywand (2005-),ADM2,DISTRICT
SM237875,105.5163,23.5461,Maza,LANGUAGE,LANGUAGE
SM18148,27.7161,-15.8521,Mazabuka District (2012-),ADM2,DISTRICT
SM237873,13.6873,10.175,Mazagway,LANGUAGE,LANGUAGE
SM267302,-52.1163,0.0399,Mazagão,ADM2,DISTRICT
SM237869,-96.8791,17.1547,Mazaltepec Zapotec,LANGUAGE,LANGUAGE
SM281825,-103.0512,19.8865,Mazamitla,ADM2,DISTRICT
SM2209,52.3333,36.25,Mazandaran (1997-),ADM1,DISTRICT
SM461491,52.671,36.529,Mazandarani,ETHNICITY,ETHNICITY
SM237866,52.3009,36.241,Mazanderani,LANGUAGE,LANGUAGE
SM293213,129.5,52.1667,Mazanovskiy rayon,ADM2,DISTRICT
SM283163,-92.1876,15.3646,Mazapa de Madero,ADM2,DISTRICT
SM283056,-101.8934,24.4337,Mazapil,ADM2,DISTRICT
SM282443,-97.6797,19.1303,Mazapiltepec de Juárez,ADM2,DISTRICT
SM278012,35.7561,31.0081,Mazar Janoobi,ADM2,DISTRICT
SM278002,35.797,32.458,Mazar Shamali,ADM2,DISTRICT
SM260245,67.1168,36.7097,Mazar-i-Sharif,ADM2,DISTRICT
SM255676,-97,18,Mazatec,ETHNICITY,ETHNICITY
SM282689,-98.1775,19.1866,Mazatecochco de José María Morelos,ADM2,DISTRICT
SM275364,-91.5292,14.5273,Mazatenango,ADM2,DISTRICT
SM282103,-99.3652,18.7113,Mazatepec,ADM2,DISTRICT
SM14343,-96.9222,18.035,Mazatlan Villa de Flores,ADM2,DISTRICT
SM282537,-106.42,23.22,Mazatlán,ADM2,DISTRICT
SM237871,-96.8889,18.0829,Mazatlán Mazatec,LANGUAGE,LANGUAGE
SM237867,-95.2833,17.0156,Mazatlán Mixe,LANGUAGE,LANGUAGE
SM282580,-110.1517,28.9831,Mazatán,ADM2,DISTRICT
SM283164,-92.4677,14.8795,Mazatán,ADM2,DISTRICT
SM298980,39.6047,39.0191,Mazgirt,ADM2,DISTRICT
SM304918,43.7607,14.5709,Mazhar,ADM2,DISTRICT
SM255679,55.5,28.78,Mazidi,ETHNICITY,ETHNICITY
SM10899,33.4935,34.7986,Mazotos,ADM2,DISTRICT
SM305240,30.9323,-17.2808,Mazowe,ADM2,DISTRICT
SM2210,21.1,52.3667,Mazowieckie,ADM1,DISTRICT
SM13687,25.4609,56.9186,Mazozolu pagasts,ADM2,DISTRICT
SM13688,25.0521,57.8622,Mazsalaca,ADM2,DISTRICT
SM4044,25.0331,57.9119,Mazsalacas Novads,ADM1,DISTRICT
SM13689,25.0761,57.8919,Mazsalacas pagasts,ADM2,DISTRICT
SM9802,29,52,Mazyrski Rayon (excl. Mazyr),ADM2,DISTRICT
SM13690,25.0135,56.3934,Mazzalves pagasts,ADM2,DISTRICT
SM297958,9.7534,34.466,Mazzouna,ADM2,DISTRICT
SM298744,40.4985,37.4854,Mazıdağı,ADM2,DISTRICT
SM267104,-37.6,-10.6855,Maçambara,ADM2,DISTRICT
SM265745,-55.611,-29.0582,Maçambara,ADM2,DISTRICT
SM298962,39.5979,40.7213,Maçka,ADM2,DISTRICT
SM289322,-7.9977,39.5933,Mação,ADM2,DISTRICT
SM2104,19.335,-2.591,Maï-Ndombe,ADM1:ADM2,DISTRICT
SM283963,37.1661,-13.8721,Maúa,ADM2,DISTRICT
SM305732,16.042,46.0934,Mače,ADM2,DISTRICT
SM2080,19.4722,44.6147,Mačvanski,ADM1:ADM2,DISTRICT
SM2118,20.2667,49.8667,Małopolskie,ADM1,DISTRICT
SM281357,22.3333,56.3167,Mažeikių,ADM2,DISTRICT
SM237881,25.143,1.0689,Mba,LANGUAGE,LANGUAGE
SM250493,25.98,0.79,Mba,ETHNICITY,ETHNICITY
SM237884,143.438,-20.1389,Mbabaram,LANGUAGE,LANGUAGE
SM459191,145.0777,-17.265,Mbabaram,ETHNICITY,ETHNICITY
SM295268,-15.8645,14.8083,Mbacké,ADM2,DISTRICT
SM284757,7.0394,5.5895,Mbaitoli,ADM2,DISTRICT
SM284757,7.0394,5.5895,Mbaitoli,ADM2,DISTRICT
SM284757,7.0212,5.59,Mbaitoli,ADM2,DISTRICT
SM284757,7.0212,5.59,Mbaitoli,ADM2,DISTRICT
SM237896,18.041,-5.1189,Mbala,LANGUAGE,LANGUAGE
SM305205,31.622,-9.195,Mbala,ADM2,DISTRICT
SM250494,19.17,-5.35,Mbala,ETHNICITY,ETHNICITY
SM250494,19.17,-5.35,Mbala,ETHNICITY,ETHNICITY
SM250494,18,-5,Mbala,ETHNICITY,ETHNICITY
SM250494,18,-5,Mbala,ETHNICITY,ETHNICITY
SM237894,16.0275,-17.5789,Mbalanhu,LANGUAGE,LANGUAGE
SM4750,34.191,1.016,Mbale (2005-),ADM1:ADM2,DISTRICT
SM250919,12.25,4.13,Mbam,ETHNICITY,ETHNICITY
SM269508,10.9697,4.7375,Mbam et Inoubou,ADM2,DISTRICT
SM269509,11.641,4.4416,Mbam et Kim,ADM2,DISTRICT
SM16330,14.8804,-0.5071,Mbama,ADM2,DISTRICT
SM269669,18.2667,0.0667,Mbandaka,ADM2,DISTRICT
SM237907,19.4455,3.98,Mbandja,LANGUAGE,LANGUAGE
SM250495,21,4,Mbandja,ETHNICITY,ETHNICITY
SM16331,14.9733,-4.5008,Mbandza-Ndounga,ADM2,DISTRICT
SM237908,17.4086,-9.1559,Mbangala,LANGUAGE,LANGUAGE
SM250496,16.53,-8.71,Mbangala,ETHNICITY,ETHNICITY
SM250496,19.52,-8.17,Mbangala,ETHNICITY,ETHNICITY
SM237906,22.0645,4.6997,Mbangi,LANGUAGE,LANGUAGE
SM237911,13.5956,-2.2873,Mbangwe,LANGUAGE,LANGUAGE
SM295307,159.1903,-9.0803,Mbanika,ADM2,DISTRICT
SM458478,143.3951,-19.8499,Mbara,ETHNICITY,ETHNICITY
SM237917,15.54,10.91,Mbara (Chad),LANGUAGE,LANGUAGE
SM237915,142.4759,-19.29,Mbara-Yanga,LANGUAGE,LANGUAGE
SM289670,-54.6892,-25.0153,Mbaracayú,ADM2,DISTRICT
SM299109,34.1345,-8.5919,Mbarali,ADM2,DISTRICT
SM4737,30.6,-0.5,Mbarara (2005-2019),ADM1:ADM2,DISTRICT
SM459211,143.8343,-14.8816,Mbarrumbathama,ETHNICITY,ETHNICITY
SM237921,17.7529,3.9418,Mbati,LANGUAGE,LANGUAGE
SM237920,-3.7042,5.3442,Mbato,LANGUAGE,LANGUAGE
SM255681,178.583,-18,Mbau Fijians,ETHNICITY,ETHNICITY
SM255681,178.583,-18,Mbau Fijians,ETHNICITY,ETHNICITY
SM255681,178.583,-18,Mbau Fijians,ETHNICITY,ETHNICITY
SM255681,178.32,-17.81,Mbau Fijians,ETHNICITY,ETHNICITY
SM255681,178.32,-17.81,Mbau Fijians,ETHNICITY,ETHNICITY
SM255681,178.32,-17.81,Mbau Fijians,ETHNICITY,ETHNICITY
SM255681,178.58,-18,Mbau Fijians,ETHNICITY,ETHNICITY
SM255681,178.58,-18,Mbau Fijians,ETHNICITY,ETHNICITY
SM255681,178.58,-18,Mbau Fijians,ETHNICITY,ETHNICITY
SM237925,15.7328,8.2307,Mbay,LANGUAGE,LANGUAGE
SM255682,-54,-24.28,Mbaya,ETHNICITY,ETHNICITY
SM255682,-54,-24.28,Mbaya,ETHNICITY,ETHNICITY
SM255682,-57,-22,Mbaya,ETHNICITY,ETHNICITY
SM255682,-57,-22,Mbaya,ETHNICITY,ETHNICITY
SM237930,8.83,6.5707,Mbe,LANGUAGE,LANGUAGE
SM237931,11.0906,6.2584,Mbe',LANGUAGE,LANGUAGE
SM237928,13.9322,10.466,Mbedam,LANGUAGE,LANGUAGE
SM5275,37.6861,-0.6961,Mbeere District,ADM2,DISTRICT
SM255684,-59.73,-33.07,Mbegua,ETHNICITY,ETHNICITY
SM237933,0.9565,10.4532,Mbelime,LANGUAGE,LANGUAGE
SM237937,14.2877,-0.4775,Mbere,LANGUAGE,LANGUAGE
SM305226,30.0096,-20.7258,Mberengwa,ADM2,DISTRICT
SM255685,12.32,-2.18,Mberenzabi,ETHNICITY,ETHNICITY
SM237941,22.8389,1.6207,Mbesa,LANGUAGE,LANGUAGE
SM259101,10.407,6.31,Mbessa,LANGUAGE,LANGUAGE
SM4161,33.3333,-8.2,Mbeya Region (2016-),ADM1,DISTRICT
SM299110,33.3566,-8.9617,Mbeya Rural,ADM2,DISTRICT
SM299111,33.4762,-8.8936,Mbeya Urban,ADM2,DISTRICT
SM16332,12.8392,-2.0774,Mbinda,ADM2,DISTRICT
SM299162,34.9691,-10.7506,Mbinga,ADM2,DISTRICT
SM275136,9.7491,1.5327,Mbini,ADM2,DISTRICT
SM237949,142.2711,-12.8962,Mbiywom,LANGUAGE,LANGUAGE
SM459399,142.1704,-12.9683,Mbiywom,ETHNICITY,ETHNICITY
SM227353,141.226,-8.5616,Mblafe-Ránmo,LANGUAGE,LANGUAGE
SM285093,8.2554,4.6433,Mbo,ADM2,DISTRICT
SM255686,27.36,3.92,Mbo,ETHNICITY,ETHNICITY
SM237953,9.9277,5.1398,Mbo (Cameroon),LANGUAGE,LANGUAGE
SM250921,9.8345,5.3495,Mbo (Cameroon),ETHNICITY,ETHNICITY
SM237955,28.018,1.116,Mbo (Democratic Republic of Congo),LANGUAGE,LANGUAGE
SM289549,-56.3658,-25.7077,Mbocayaty del Guairá,ADM2,DISTRICT
SM289530,-56.6923,-25.3192,Mbocayaty del Yhaguy,ADM2,DISTRICT
SM299219,32.2254,-3.4593,Mbogwe,ADM2,DISTRICT
SM237956,12.545,9.8695,Mboi,LANGUAGE,LANGUAGE
SM237959,14.7935,0.4354,Mboko,LANGUAGE,LANGUAGE
SM237961,24.0443,-0.5718,Mbole,LANGUAGE,LANGUAGE
SM269814,14.6842,0.6085,Mbomo,ADM2,DISTRICT
SM2214,23.3333,5.5,Mbomou,ADM1,DISTRICT
SM16334,15.0168,-2.0192,Mbon,ADM2,DISTRICT
SM237965,13.6471,5.7517,Mbonga,LANGUAGE,LANGUAGE
SM255688,144.18,-6.11,Mbonggu,ETHNICITY,ETHNICITY
SM237966,11.1316,6.8254,Mbongno,LANGUAGE,LANGUAGE
SM237968,15.5322,-1.2302,Mbosi,LANGUAGE,LANGUAGE
SM259962,13.96,-0.58,Mbosi-generic,ETHNICITY,ETHNICITY
SM250504,14,7,Mboum,ETHNICITY,ETHNICITY
SM250504,14,7,Mboum,ETHNICITY,ETHNICITY
SM250504,13.5,5.5,Mboum,ETHNICITY,ETHNICITY
SM250504,13.5,5.5,Mboum,ETHNICITY,ETHNICITY
SM295260,-16.8556,14.4057,Mbour,ADM2,DISTRICT
SM237971,-16.9701,14.4207,Mbour Sign Language,LANGUAGE,LANGUAGE
SM237973,23.9819,-14.2941,Mbowe,LANGUAGE,LANGUAGE
SM299112,32.9261,-8.9457,Mbozi,ADM2,DISTRICT
SM237977,-5.7294,8.2093,Mbre,LANGUAGE,LANGUAGE
SM268555,19.8523,6.9714,Mbrès,ADM2,DISTRICT
SM255691,24.02,1.99,Mbudja,ETHNICITY,ETHNICITY
SM237980,38.3519,-4.7286,Mbugu,LANGUAGE,LANGUAGE
SM250500,38.5,-4.8,Mbugu,ETHNICITY,ETHNICITY
SM237981,35.8133,-3.7576,Mbugwe,LANGUAGE,LANGUAGE
SM250501,36,-4,Mbugwe,ETHNICITY,ETHNICITY
SM250501,36,-4,Mbugwe,ETHNICITY,ETHNICITY
SM250501,35.8,-3.8,Mbugwe,ETHNICITY,ETHNICITY
SM250501,35.8,-3.8,Mbugwe,ETHNICITY,ETHNICITY
SM255692,14.95,-10.62,Mbui,ETHNICITY,ETHNICITY
SM269781,23.6,-6.15,Mbuji-Mayi,ADM2,DISTRICT
SM237986,10.4151,6.5356,Mbuk,LANGUAGE,LANGUAGE
SM458886,132.3666,-12.983,Mbukarla,ETHNICITY,ETHNICITY
SM237985,14.1677,10.7788,Mbuko,LANGUAGE,LANGUAGE
SM237983,22.5026,-17.6827,Mbukushu,LANGUAGE,LANGUAGE
SM250502,22.97,-17.82,Mbukushu,ETHNICITY,ETHNICITY
SM250502,22.97,-17.82,Mbukushu,ETHNICITY,ETHNICITY
SM250502,22.7957,-16.8571,Mbukushu,ETHNICITY,ETHNICITY
SM250502,22.7957,-16.8571,Mbukushu,ETHNICITY,ETHNICITY
SM237992,148.041,-5.6697,Mbula,LANGUAGE,LANGUAGE
SM237989,12.2439,9.6077,Mbula-Bwazza,LANGUAGE,LANGUAGE
SM237991,10.9924,4.5064,Mbule,LANGUAGE,LANGUAGE
SM299093,35.311,-3.9503,Mbulu,ADM2,DISTRICT
SM237987,-14.7372,10.8553,Mbulungish,LANGUAGE,LANGUAGE
SM237993,14.2722,7.5789,Mbum,LANGUAGE,LANGUAGE
SM295383,159.9443,-9.438,Mbumburu,ADM2,DISTRICT
SM237998,21.9768,-14.603,Mbunda,LANGUAGE,LANGUAGE
SM250506,22.58,-14.86,Mbunda,ETHNICITY,ETHNICITY
SM250506,23.52,-14.22,Mbunda,ETHNICITY,ETHNICITY
SM250506,23.64,-16.17,Mbunda,ETHNICITY,ETHNICITY
SM237997,36.5362,-8.1966,Mbunga,LANGUAGE,LANGUAGE
SM250505,37.36,-8.59,Mbunga,ETHNICITY,ETHNICITY
SM237999,9.8137,10.9276,Mburku,LANGUAGE,LANGUAGE
SM261153,-58.25,-28,Mburucuyá,ADM2,DISTRICT
SM257750,28.9,-0.28,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.9,-0.28,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.9,-0.28,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.9,-0.28,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.9,-0.28,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.3335,1.75,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.3335,1.75,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.3335,1.75,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.3335,1.75,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.3335,1.75,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28,2,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28,2,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28,2,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28,2,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28,2,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.61,1.54,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.61,1.54,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.61,1.54,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.61,1.54,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.61,1.54,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.33,1.5,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.33,1.5,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.33,1.5,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.33,1.5,Mbuti proper,ETHNICITY,ETHNICITY
SM257750,28.33,1.5,Mbuti proper,ETHNICITY,ETHNICITY
SM238801,19.4269,-5.2113,Mbuun,LANGUAGE,LANGUAGE
SM250530,20.06,-4.87,Mbuun,ETHNICITY,ETHNICITY
SM250530,20.06,-4.87,Mbuun,ETHNICITY,ETHNICITY
SM250530,20.06,-4.87,Mbuun,ETHNICITY,ETHNICITY
SM250530,20.31,-5.14,Mbuun,ETHNICITY,ETHNICITY
SM250530,20.31,-5.14,Mbuun,ETHNICITY,ETHNICITY
SM250530,20.31,-5.14,Mbuun,ETHNICITY,ETHNICITY
SM250530,19,-4,Mbuun,ETHNICITY,ETHNICITY
SM250530,19,-4,Mbuun,ETHNICITY,ETHNICITY
SM250530,19,-4,Mbuun,ETHNICITY,ETHNICITY
SM250530,19,-5,Mbuun,ETHNICITY,ETHNICITY
SM250530,19,-5,Mbuun,ETHNICITY,ETHNICITY
SM250530,19,-5,Mbuun,ETHNICITY,ETHNICITY
SM289632,-56.811,-26.2871,Mbuyapey,ADM2,DISTRICT
SM261980,29.7471,-3.2401,Mbuye,ADM2,DISTRICT
SM255695,145.49,-7.38,Mbwei,ETHNICITY,ETHNICITY
SM238003,18.2243,-13.3267,Mbwela,LANGUAGE,LANGUAGE
SM250507,21.65,-16.07,Mbwele,ETHNICITY,ETHNICITY
SM250507,19.32,-16.27,Mbwele,ETHNICITY,ETHNICITY
SM255696,27.34,-13.88,Mbwera (Lozi),ETHNICITY,ETHNICITY
SM255697,-55,-23.5,Mbya Guarani,ETHNICITY,ETHNICITY
SM255697,-55,-23.5,Mbya Guarani,ETHNICITY,ETHNICITY
SM255697,-54,-26,Mbya Guarani,ETHNICITY,ETHNICITY
SM255697,-54,-26,Mbya Guarani,ETHNICITY,ETHNICITY
SM238007,-52.711,-26.0188,Mbyá Guaraní,LANGUAGE,LANGUAGE
SM269499,14.243,6.434,Mbéré,ADM2,DISTRICT
SM302321,-78.569,41.8078,Mc Kean,ADM2,DISTRICT
SM302213,-97.4443,35.0093,McClain,ADM2,DISTRICT
SM301643,-105.7953,47.6452,McCone,ADM2,DISTRICT
SM302516,-97.3684,43.6743,McCook,ADM2,DISTRICT
SM302460,-82.3099,33.8996,McCormick,ADM2,DISTRICT
SM301085,-88.7127,37.0541,McCracken,ADM2,DISTRICT
SM301087,-84.4842,36.7371,McCreary,ADM2,DISTRICT
SM10187,-99.3341,50.75,McCreary,ADM2,DISTRICT
SM302707,-99.3475,31.1989,McCulloch,ADM2,DISTRICT
SM302214,-94.7713,34.1153,McCurtain,ADM2,DISTRICT
SM301583,-94.3484,36.6287,McDonald,ADM2,DISTRICT
SM300651,-90.6779,40.4562,McDonough,ADM2,DISTRICT
SM303127,-81.6536,37.3785,McDowell,ADM2,DISTRICT
SM301985,-82.0487,35.6812,McDowell,ADM2,DISTRICT
SM300536,-82.4814,33.4829,McDuffie,ADM2,DISTRICT
SM302047,-100.6363,48.2346,McHenry,ADM2,DISTRICT
SM300652,-88.4525,42.3244,McHenry,ADM2,DISTRICT
SM302215,-95.6668,35.3737,McIntosh,ADM2,DISTRICT
SM302048,-99.4412,46.1118,McIntosh,ADM2,DISTRICT
SM300537,-81.3756,31.4838,McIntosh,ADM2,DISTRICT
SM13334,-174.1229,-3.5956,McKean,ADM2,DISTRICT
SM302049,-103.3953,47.7402,McKenzie,ADM2,DISTRICT
SM261646,141.6745,-20.5941,McKinlay,ADM2,DISTRICT
SM301843,-108.2619,35.5806,McKinley,ADM2,DISTRICT
SM302050,-101.3218,47.607,McLean,ADM2,DISTRICT
SM301088,-87.2636,37.5292,McLean,ADM2,DISTRICT
SM300653,-88.8473,40.4909,McLean,ADM2,DISTRICT
SM302708,-97.2018,31.5524,McLennan,ADM2,DISTRICT
SM323800,-116.9023,55.7081,McLennan,ADM2:ADM3,DISTRICT
SM301379,-94.2724,44.8235,McLeod,ADM2,DISTRICT
SM302598,-84.6175,35.4248,McMinn,ADM2,DISTRICT
SM302709,-98.5678,28.3527,McMullen,ADM2,DISTRICT
SM302600,-88.5636,35.1755,McNairy,ADM2,DISTRICT
SM302517,-99.2215,45.7664,McPherson,ADM2,DISTRICT
SM301734,-101.0605,41.5681,McPherson,ADM2,DISTRICT
SM300943,-97.6481,38.3917,McPherson,ADM2,DISTRICT
SM15352,-55.1801,-25.1795,Mcal. Francisco Solano Lopez 2011-),ADM2,DISTRICT
SM2215,33.08,-13.7623,Mchinji,ADM1:ADM2,DISTRICT
SM298086,8.6978,34.2029,Mdhilla,ADM2,DISTRICT
SM280402,105.5724,11.3553,Me Sang,ADM2,DISTRICT
SM264188,-47.5157,-1.9833,Me do Rio,ADM2,DISTRICT
SM238030,35.6255,6.6321,Me'en,LANGUAGE,LANGUAGE
SM255701,35,5,Me'en,ETHNICITY,ETHNICITY
SM238010,165.771,-21.4939,Mea,DIALECT,LANGUAGE
SM302518,-102.7169,44.5668,Meade,ADM2,DISTRICT
SM300945,-100.3662,37.2382,Meade,ADM2,DISTRICT
SM301089,-86.2172,37.9698,Meade,ADM2,DISTRICT
SM12527,-76.8155,18.0471,Meadowbrook,ADM2,DISTRICT
SM12528,-76.8296,18.0374,Meadowbrook Estate,ADM2,DISTRICT
SM301645,-110.8856,46.5982,Meagher,ADM2,DISTRICT
SM238011,143.783,-4.833,Meakambut,LANGUAGE,LANGUAGE
SM289210,-8.4478,40.3627,Mealhada,ADM2,DISTRICT
SM261769,146.5457,-41.6495,Meander Valley,ADM2,DISTRICT
SM295723,-88.1333,13.8667,Meanguera,ADM2,DISTRICT
SM295701,-87.7095,13.1998,Meanguera del Golfo,ADM2,DISTRICT
SM12529,-78.0287,18.1886,Mearnsville,ADM2,DISTRICT
SM2216,-6.6667,53.6667,Meath,ADM1:ADM2,DISTRICT
SM299179,34.52,-3.425,Meatu,ADM2,DISTRICT
SM283965,35.8849,-15.1989,Mecanhelas,ADM2,DISTRICT
SM282736,-97.6574,20.2135,Mecatlán,ADM2,DISTRICT
SM14344,-94.8161,18.2895,Mecayapan (1997-),ADM2,DISTRICT
SM457330,-1,33.75,Mecharia District,ADM2,DISTRICT
SM294274,58.25,55.9167,Mechetlinskiy rayon,ADM2,DISTRICT
SM298298,35.2877,40.5277,Mecitözü,ADM2,DISTRICT
SM303049,-78.3627,36.6804,Mecklenburg,ADM2,DISTRICT
SM301987,-80.8328,35.2467,Mecklenburg,ADM2,DISTRICT
SM2217,12.5,53.8333,Mecklenburg-Vorpommern,ADM1,DISTRICT
SM283946,39.6466,-15.2819,Meconta,ADM2,DISTRICT
SM301304,-85.3246,43.6408,Mecosta,ADM2,DISTRICT
SM283947,38.929,-14.4932,Mecuburi,ADM2,DISTRICT
SM283859,40.4033,-13.2385,Mecufi,ADM2,DISTRICT
SM283952,37.5655,-11.9579,Mecula,ADM2,DISTRICT
SM11670,78.25,17.75,Medak (2016-),ADM2,DISTRICT
SM11671,78.5401,17.6064,Medchal Malkajgiri,ADM2,DISTRICT
SM238018,145.425,-4.4992,Medebur,LANGUAGE,LANGUAGE
SM263648,-46.358,-20.0114,Medeiros,ADM2,DISTRICT
SM267537,-40.2757,-17.3902,Medeiros Neto,ADM2,DISTRICT
SM282738,-96.1584,19.0042,Medellín,ADM2,DISTRICT
SM270236,-75.5833,6.25,Medellín,ADM2,DISTRICT
SM304901,45.0038,15.6384,Medghal,ADM2,DISTRICT
SM290175,28.268,44.228,Medgidia,ADM2,DISTRICT
SM238020,-78.5392,-1.0452,Media Lengua,LANGUAGE,LANGUAGE
SM271113,-77.4319,20.1426,Media Luna,ADM2,DISTRICT
SM15928,-66.2446,18.4237,Media Luna Barrio,ADM2,DISTRICT
SM238021,37.8782,-8.5276,Mediak,LANGUAGE,LANGUAGE
SM238021,37.8782,-8.5276,Mediak,LANGUAGE,LANGUAGE
SM238021,36.5977,-2.7805,Mediak,LANGUAGE,LANGUAGE
SM238021,36.5977,-2.7805,Mediak,LANGUAGE,LANGUAGE
SM264580,-54.1132,-25.2484,Medianeira,ADM2,DISTRICT
SM15929,-65.8406,18.424,Mediania Alta Barrio,ADM2,DISTRICT
SM15930,-65.86,18.4255,Mediania Baja Barrio,ADM2,DISTRICT
SM291731,24.3538,46.1366,Medias,ADM2,DISTRICT
SM264190,-53.1777,-3.1567,Medicilândia,ADM2,DISTRICT
SM323823,-110.7015,50.0458,Medicine Hat,ADM2:ADM3,DISTRICT
SM291671,23.1536,47.7945,Mediesu Aurit,ADM2,DISTRICT
SM238023,28.9556,41.0139,Medieval Greek,LANGUAGE,LANGUAGE
SM2220,16.5,46.4167,Medimurska,ADM1,DISTRICT
SM302710,-99.1101,29.3557,Medina,ADM2,DISTRICT
SM302131,-81.8997,41.1176,Medina,ADM2,DISTRICT
SM12530,-77.5964,18.1224,Medina,ADM2,DISTRICT
SM270024,-73.4049,4.501,Medina,ADM2,DISTRICT
SM263649,-41.5382,-16.3015,Medina,ADM2,DISTRICT
SM10424,-76.7825,5.995,Medio Atrato,ADM2,DISTRICT
SM10425,-77.05,5.05,Medio Baudo,ADM2,DISTRICT
SM10426,-76.6953,5.0928,Medio San Juan,ADM2,DISTRICT
SM4431,-7.5035,33.5201,Mediouna,ADM2,DISTRICT
SM236349,10.8531,33.8613,Mediterranean Lingua Franca,LANGUAGE,LANGUAGE
SM262310,23.1833,43.65,Medkovets,ADM2,DISTRICT
SM13691,27.6229,57.1208,Mednevas pagasts,ADM2,DISTRICT
SM238024,167.782,54.6601,Mednyj Aleut,LANGUAGE,LANGUAGE
SM305666,13.9362,44.8214,Medulin,ADM2,DISTRICT
SM238027,10.6118,5.109,Medumba,LANGUAGE,LANGUAGE
SM13692,26.3574,55.7721,Medumu pagasts,ADM2,DISTRICT
SM17001,47.75,56.6667,Medvedevskiy Rayon,ADM2,DISTRICT
SM293505,36.1167,51.4167,Medvenskiy rayon,ADM2,DISTRICT
SM293119,35,63,Medvezh'egorskiy rayon,ADM2,DISTRICT
SM296308,14.4027,46.1305,Medvode,ADM1:ADM2,DISTRICT
SM274653,0.5,51.4167,Medway,ADM1:ADM2,DISTRICT
SM293075,35.8333,55.0833,Medynskiy rayon,ADM2,DISTRICT
SM13693,21.1549,56.6101,Medzes pagasts,ADM2,DISTRICT
SM296160,21.902,49.2706,Medzilaborce,ADM2,DISTRICT
SM261409,118.9997,-25.3033,Meekatharra,ADM2,DISTRICT
SM301380,-94.5273,45.1231,Meeker,ADM2,DISTRICT
SM4263,73.3519,2.7753,Meemu Atholhu,ADM1:ADM2,DISTRICT
SM255702,74.41,24.63,Meena,ETHNICITY,ETHNICITY
SM285872,5.7571,50.8952,Meerssen,ADM2,DISTRICT
SM277318,77.6833,28.9167,Meerut (1998-),ADM2,DISTRICT
SM238031,13.8201,10.677,Mefele,LANGUAGE,LANGUAGE
SM269510,11.93,3.96,Mefou et Afamba,ADM2,DISTRICT
SM269511,11.3609,3.5871,Mefou et Akono,ADM2,DISTRICT
SM255704,28.36,12.78,Meganin,ETHNICITY,ETHNICITY
SM255705,17.44,29.68,Megarha,ETHNICITY,ETHNICITY
SM2221,91.3333,25.5,Meghalaya,ADM1,DISTRICT
SM255709,145.58,-4.87,Megiar,ETHNICITY,ETHNICITY
SM294307,130.5,61.9167,Megino-Kangalasskiy rayon,ADM2,DISTRICT
SM238038,22.3782,41.1174,Megleno Romanian,LANGUAGE,LANGUAGE
SM10324,21.8541,15.5356,Megri,ADM2,DISTRICT
SM279451,139.6982,35.6415,Meguro,ADM2,DISTRICT
SM290030,22.3718,44.9551,Mehadia,ADM2,DISTRICT
SM290031,22.2648,45.0389,Mehadica,ADM2,DISTRICT
SM274330,38.8206,13.7851,Mehakelegnaw,ADM2,DISTRICT
SM2222,22.8333,44.6667,Mehedinți,ADM1,DISTRICT
SM238042,142.498,-3.7093,Mehek,LANGUAGE,LANGUAGE
SM238044,142.5016,-3.6986,Mehek Sign Language,LANGUAGE,LANGUAGE
SM238044,142.5016,-3.6986,Mehek Sign Language,LANGUAGE,LANGUAGE
SM238044,142.5109,-3.7279,Mehek Sign Language,LANGUAGE,LANGUAGE
SM238044,142.5109,-3.7279,Mehek Sign Language,LANGUAGE,LANGUAGE
SM262266,88.7,23.75,Meherpur,ADM2,DISTRICT
SM255712,-52.18,-13.17,Mehinaku,ETHNICITY,ETHNICITY
SM238045,-53.521,-12.5364,Mehináku,LANGUAGE,LANGUAGE
SM18411,68.6667,40.3333,Mehnatobod Tumani,ADM2,DISTRICT
SM255713,56.98,29.36,Mehni,ETHNICITY,ETHNICITY
SM238046,52.473,16.793,Mehri,LANGUAGE,LANGUAGE
SM15168,5.5028,51.5997,Meierijstad,ADM2,DISTRICT
SM302601,-84.8134,35.5128,Meigs,ADM2,DISTRICT
SM302132,-82.0229,39.0822,Meigs,ADM2,DISTRICT
SM283516,95.9562,20.957,Meiktila,ADM2,DISTRICT
SM269019,8.6626,47.2792,Meilen,ADM2,DISTRICT
SM14072,-9.0622,7.0181,Meinpea-Mahn District,ADM2,DISTRICT
SM269322,104.04,30.03,Meishan,ADM2,DISTRICT
SM249734,115.8903,24.3523,Meixian,DIALECT,LANGUAGE
SM269417,116.0404,24.1593,Meizhou,ADM2,DISTRICT
SM297980,9.5991,36.7025,Mejez El Bab,ADM2,DISTRICT
SM295511,-89.2187,13.7353,Mejicanos,ADM2,DISTRICT
SM4102,170.8692,10.2775,Mejit Island,ADM1,DISTRICT
SM303718,-63.7738,10.3778,Mejía,ADM2,DISTRICT
SM273540,-78.7445,-0.3508,Mejía,ADM2,DISTRICT
SM250465,11.84,4,Meka,ETHNICITY,ETHNICITY
SM11213,39.5439,13.4978,Mekelle,ADM2,DISTRICT
SM243552,-62.0927,-13.054,Mekens,LANGUAGE,LANGUAGE
SM238056,146.586,-8.2385,Mekeo,LANGUAGE,LANGUAGE
SM255722,146.6,-8.05,Mekeo,ETHNICITY,ETHNICITY
SM255722,146.6,-8.05,Mekeo,ETHNICITY,ETHNICITY
SM255722,147,-9,Mekeo,ETHNICITY,ETHNICITY
SM255722,147,-9,Mekeo,ETHNICITY,ETHNICITY
SM238059,143.75,-4.4702,Mekmek,LANGUAGE,LANGUAGE
SM297959,9.6247,34.6233,Meknassi,ADM2,DISTRICT
SM281464,-5.525,33.9776,Meknès,ADM2,DISTRICT
SM255723,-51.01,-5.52,Mekranoti,ETHNICITY,ETHNICITY
SM255724,27.93,-23.47,Mekwa,ETHNICITY,ETHNICITY
SM238061,140.326,-2.5226,Mekwei,LANGUAGE,LANGUAGE
SM10900,32.5067,34.9867,Meladeia,ADM2,DISTRICT
SM2224,102.25,2.25,Melaka,ADM1,DISTRICT
SM281478,44.8,-17.6,Melaky,ADM1:ADM2,DISTRICT
SM461500,151.25,-5,Melamela,ETHNICITY,ETHNICITY
SM10901,34.2028,35.5081,Melanarga,ADM2,DISTRICT
SM255726,112.19,2.73,Melanau,ETHNICITY,ETHNICITY
SM10902,32.5381,34.9898,Melandra,ADM2,DISTRICT
SM276389,111.698,-0.3362,Melawi,ADM2,DISTRICT
SM255728,103,5,Melayu,ETHNICITY,ETHNICITY
SM255728,103,5,Melayu,ETHNICITY,ETHNICITY
SM255728,103.82,1.35,Melayu,ETHNICITY,ETHNICITY
SM255728,103.82,1.35,Melayu,ETHNICITY,ETHNICITY
SM261254,144.9442,-37.8131,Melbourne,ADM2,DISTRICT
SM283057,-101.7925,24.8343,Melchor Ocampo,ADM2,DISTRICT
SM282179,-99.5215,26.0555,Melchor Ocampo,ADM2,DISTRICT
SM281937,-99.1429,19.7035,Melchor Ocampo,ADM2,DISTRICT
SM275204,-89.2475,17.2134,Melchor de Mencos,ADM2,DISTRICT
SM238068,168.275,-17.6841,Mele-Fila,LANGUAGE,LANGUAGE
SM266209,-49.5997,-28.8579,Meleiro,ADM2,DISTRICT
SM2225,134.6345,7.494,Melekeok,ADM1,DISTRICT
SM271020,-82.1511,22.7882,Melena del Sur,ADM2,DISTRICT
SM294879,41.5,55.3333,Melenkovskiy rayon,ADM2,DISTRICT
SM17002,56,53.0833,Meleuzovskiy Rayon,ADM2,DISTRICT
SM12066,-51.0588,-1.6616,Melgaco,ADM2,DISTRICT
SM286833,-70.75,-14.75,Melgar,ADM2,DISTRICT
SM270570,-74.6721,4.1785,Melgar,ADM2,DISTRICT
SM289355,-8.2484,42.0807,Melgaço,ADM2,DISTRICT
SM286182,10.2769,63.2876,Melhus,ADM2,DISTRICT
SM298622,35.6608,38.7793,Melikgazi,ADM2,DISTRICT
SM274081,-2.9422,35.2955,Melilla,ADM1:ADM2,DISTRICT
SM274081,-2.9422,35.2955,Melilla,ADM1:ADM2,DISTRICT
SM274081,-2.9443,35.2922,Melilla,ADM1:ADM2,DISTRICT
SM274081,-2.9443,35.2922,Melilla,ADM1:ADM2,DISTRICT
SM290384,23.6953,44.5631,Melinesti,ADM2,DISTRICT
SM10903,33.1522,34.866,Melini,ADM2,DISTRICT
SM269085,-71.1667,-33.75,Melipilla,ADM2,DISTRICT
SM18249,35.3576,46.836,Melitopol Raion,ADM2,DISTRICT
SM261806,15.2428,48.2131,Melk,ADM2,DISTRICT
SM271772,-71.4448,18.31,Mella,ADM2,DISTRICT
SM271048,-75.906,20.3636,Mella,ADM2,DISTRICT
SM303599,-67.0582,9.3863,Mellado,ADM2,DISTRICT
SM296586,12.414,58.6911,Mellerud,ADM2,DISTRICT
SM302519,-100.76,43.5813,Mellette,ADM2,DISTRICT
SM283443,14.3514,35.9583,Mellieħa,ADM1:ADM2,DISTRICT
SM297888,10.9806,35.1706,Melloulech,ADM2,DISTRICT
SM238077,36.811,6.5392,Melo,LANGUAGE,LANGUAGE
SM10904,33.6899,35.3258,Melounta,ADM2,DISTRICT
SM10905,33.5867,35.078,Melouseia,ADM2,DISTRICT
SM238079,144.34,-5.6264,Melpa,LANGUAGE,LANGUAGE
SM261255,144.6229,-37.6814,Melton,ADM2,DISTRICT
SM283860,39.6239,-12.4653,Meluco,ADM2,DISTRICT
SM261410,115.8341,-32.0433,Melville,ADM2,DISTRICT
SM286071,13.7076,66.872,Meløy,ADM2,DISTRICT
SM283948,40.3944,-13.9659,Memba,ADM2,DISTRICT
SM15931,-66.8708,18.4813,Membrillo Barrio,ADM2,DISTRICT
SM269552,9.3333,4.8333,Meme,ADM2,DISTRICT
SM238083,67.007,24.853,Memoni,LANGUAGE,LANGUAGE
SM280462,106.2028,11.8822,Memot,ADM2,DISTRICT
SM281486,45.07,-20.2,Menabe,ADM1:ADM2,DISTRICT
SM302711,-99.8206,30.8898,Menard,ADM2,DISTRICT
SM300654,-89.8022,40.0274,Menard,ADM2,DISTRICT
SM269532,10.1667,6.5,Menchum,ADM2,DISTRICT
SM238091,113.286,0.9879,Mendalam Kayan,LANGUAGE,LANGUAGE
SM238085,10.2307,6.0003,Mendankwe-Nkwen,LANGUAGE,LANGUAGE
SM250511,-10.65,7.25,Mende,ETHNICITY,ETHNICITY
SM250511,-10.65,7.25,Mende,ETHNICITY,ETHNICITY
SM250511,-10.65,7.25,Mende,ETHNICITY,ETHNICITY
SM250511,-10.65,7.25,Mende,ETHNICITY,ETHNICITY
SM250511,-10.65,7.25,Mende,ETHNICITY,ETHNICITY
SM250511,-11.06,8.39,Mende,ETHNICITY,ETHNICITY
SM250511,-11.06,8.39,Mende,ETHNICITY,ETHNICITY
SM250511,-11.06,8.39,Mende,ETHNICITY,ETHNICITY
SM250511,-11.06,8.39,Mende,ETHNICITY,ETHNICITY
SM250511,-11.06,8.39,Mende,ETHNICITY,ETHNICITY
SM250511,-11.25,8.5,Mende,ETHNICITY,ETHNICITY
SM250511,-11.25,8.5,Mende,ETHNICITY,ETHNICITY
SM250511,-11.25,8.5,Mende,ETHNICITY,ETHNICITY
SM250511,-11.25,8.5,Mende,ETHNICITY,ETHNICITY
SM250511,-11.25,8.5,Mende,ETHNICITY,ETHNICITY
SM250511,-11,8,Mende,ETHNICITY,ETHNICITY
SM250511,-11,8,Mende,ETHNICITY,ETHNICITY
SM250511,-11,8,Mende,ETHNICITY,ETHNICITY
SM250511,-11,8,Mende,ETHNICITY,ETHNICITY
SM250511,-11,8,Mende,ETHNICITY,ETHNICITY
SM250511,-12,7.83,Mende,ETHNICITY,ETHNICITY
SM250511,-12,7.83,Mende,ETHNICITY,ETHNICITY
SM250511,-12,7.83,Mende,ETHNICITY,ETHNICITY
SM250511,-12,7.83,Mende,ETHNICITY,ETHNICITY
SM250511,-12,7.83,Mende,ETHNICITY,ETHNICITY
SM250511,-13.2,8.5,Mende,ETHNICITY,ETHNICITY
SM250511,-13.2,8.5,Mende,ETHNICITY,ETHNICITY
SM250511,-13.2,8.5,Mende,ETHNICITY,ETHNICITY
SM250511,-13.2,8.5,Mende,ETHNICITY,ETHNICITY
SM250511,-13.2,8.5,Mende,ETHNICITY,ETHNICITY
SM238092,142.683,-3.6723,Mende (Papua New Guinea),LANGUAGE,LANGUAGE
SM238090,-10.9926,7.9094,Mende (Sierra Leone),LANGUAGE,LANGUAGE
SM274020,38.8167,14.8833,Mendefera,ADM2,DISTRICT
SM294621,52.3333,55.9167,Mendeleyevskiy rayon,ADM2,DISTRICT
SM298542,27.119,38.1553,Menderes,ADM2,DISTRICT
SM265282,-43.749,-22.5432,Mendes,ADM2,DISTRICT
SM263650,-41.3487,-18.6264,Mendes Pimentel,ADM2,DISTRICT
SM255733,143.73,-6.64,Mendi,ETHNICITY,ETHNICITY
SM288601,143.6667,-6.1167,Mendi-Munihu,ADM2,DISTRICT
SM303178,-123.4316,39.4336,Mendocino,ADM2,DISTRICT
SM266692,-49.5643,-21.1962,Mendonça,ADM2,DISTRICT
SM2226,-68.5,-34.5,Mendoza,ADM1,DISTRICT
SM255734,101.31,5.95,Mendrio,ETHNICITY,ETHNICITY
SM268979,8.9993,45.8659,Mendrisio,ADM2,DISTRICT
SM279849,64.1667,54,Mendykarinskiy,ADM2,DISTRICT
SM298543,27.0632,38.6161,Menemen,ADM2,DISTRICT
SM2227,166.9379,-0.5524,Meneng,ADM1,DISTRICT
SM10906,33.6044,34.8569,Meneou,ADM2,DISTRICT
SM238096,10.3377,5.714,Mengaka,LANGUAGE,LANGUAGE
SM13694,25.4089,56.8116,Mengeles pagasts,ADM2,DISTRICT
SM238099,151.373,-5.4704,Mengen,LANGUAGE,LANGUAGE
SM298220,32.0654,40.9485,Mengen,ADM2,DISTRICT
SM461501,151.4,-5.1,Mengen,ETHNICITY,ETHNICITY
SM458894,133.85,-12.59,Mengerrdji,ETHNICITY,ETHNICITY
SM458894,133.85,-12.59,Mengerrdji,ETHNICITY,ETHNICITY
SM458894,133.0238,-12.351,Mengerrdji,ETHNICITY,ETHNICITY
SM458894,133.0238,-12.351,Mengerrdji,ETHNICITY,ETHNICITY
SM296309,14.5625,46.1615,Mengeš,ADM1:ADM2,DISTRICT
SM238095,11.3947,4.5932,Mengisa,LANGUAGE,LANGUAGE
SM238105,-39.0532,-15.8815,Menien,LANGUAGE,LANGUAGE
SM301090,-83.5989,37.9414,Menifee,ADM2,DISTRICT
SM255735,-65.46,54.52,Menihek Lakes,ETHNICITY,ETHNICITY
SM10907,33.1436,35.0989,Meniko,ADM2,DISTRICT
SM238106,9.6916,5.9464,Menka,LANGUAGE,LANGUAGE
SM10908,33.4342,34.8472,Menogeia,ADM2,DISTRICT
SM238109,-88.6395,44.9794,Menominee,LANGUAGE,LANGUAGE
SM303257,-88.71,45.0044,Menominee,ADM2,DISTRICT
SM301305,-87.5097,45.5251,Menominee,ADM2,DISTRICT
SM255738,-87.51,45.67,Menomini,ETHNICITY,ETHNICITY
SM255738,-87.51,45.67,Menomini,ETHNICITY,ETHNICITY
SM255738,-87.51,45.67,Menomini,ETHNICITY,ETHNICITY
SM255738,-88.52,46.36,Menomini,ETHNICITY,ETHNICITY
SM255738,-88.52,46.36,Menomini,ETHNICITY,ETHNICITY
SM255738,-88.52,46.36,Menomini,ETHNICITY,ETHNICITY
SM255738,-86,46,Menomini,ETHNICITY,ETHNICITY
SM255738,-86,46,Menomini,ETHNICITY,ETHNICITY
SM255738,-86,46,Menomini,ETHNICITY,ETHNICITY
SM255738,-88,46,Menomini,ETHNICITY,ETHNICITY
SM255738,-88,46,Menomini,ETHNICITY,ETHNICITY
SM255738,-88,46,Menomini,ETHNICITY,ETHNICITY
SM260422,17.6995,-15.1068,Menongue,ADM2,DISTRICT
SM269544,10.0667,5.45,Menoua,ADM2,DISTRICT
SM238114,98.9401,-1.4109,Mentawai,LANGUAGE,LANGUAGE
SM255739,99.02,-1.32,Mentawai,ETHNICITY,ETHNICITY
SM255739,99.02,-1.32,Mentawai,ETHNICITY,ETHNICITY
SM255739,100.23,-2.93,Mentawai,ETHNICITY,ETHNICITY
SM255739,100.23,-2.93,Mentawai,ETHNICITY,ETHNICITY
SM238116,110.6705,-0.2932,Menterap,LANGUAGE,LANGUAGE
SM458899,129.9207,-13.6377,Menthe,ETHNICITY,ETHNICITY
SM268302,115.0021,4.9748,Mentiri,ADM2,DISTRICT
SM238120,146.071,-7.1743,Menya,LANGUAGE,LANGUAGE
SM255740,145.82,-7.37,Menya,ETHNICITY,ETHNICITY
SM288586,146.1667,-7.25,Menyamya,ADM2,DISTRICT
SM298068,9.7949,37.1338,Menzel Bourguiba,ADM2,DISTRICT
SM297960,9.411,34.6419,Menzel Bouzaiene,ADM2,DISTRICT
SM297925,10.6766,36.6682,Menzel Bouzelfa,ADM2,DISTRICT
SM297952,10.4022,34.9958,Menzel Chaker,ADM2,DISTRICT
SM298078,9.5966,34.2303,Menzel Habib,ADM2,DISTRICT
SM18009,9.9511,37.2255,Menzel Jemil (excl. Zarzouna),ADM2,DISTRICT
SM297926,10.9079,36.7937,Menzel Temime,ADM2,DISTRICT
SM294622,53,55.75,Menzelinskiy rayon,ADM2,DISTRICT
SM261411,123.7188,-29.3918,Menzies,ADM2,DISTRICT
SM283250,-105.5277,28.3393,Meoqui,ADM2,DISTRICT
SM238122,134.378,-2.0783,Meoswar,LANGUAGE,LANGUAGE
SM285556,6.1973,52.7089,Meppel,ADM2,DISTRICT
SM258888,-68.2489,-1.1503,Mepuri,LANGUAGE,LANGUAGE
SM238140,134.731,-3.3828,Mer,LANGUAGE,LANGUAGE
SM292355,26.9342,45.7733,Mera,ADM2,DISTRICT
SM298682,32.4711,37.8398,Meram,ADM2,DISTRICT
SM238124,151.528,-4.9747,Meramera,LANGUAGE,LANGUAGE
SM276750,102.133,-2.0693,Merangin,ADM2,DISTRICT
SM276531,139.6667,-7.6667,Merauke,ADM2,DISTRICT
SM295175,31.94,18.31,Merawi,ADM2,DISTRICT
SM269859,-77.1828,1.805,Mercaderes (1993-),ADM2,DISTRICT
SM303179,-120.7177,37.1919,Merced,ADM2,DISTRICT
SM275740,-88.9833,14.3167,Mercedes,ADM2,DISTRICT
SM264581,-54.1768,-24.426,Mercedes,ADM2,DISTRICT
SM261154,-57.8333,-29,Mercedes,ADM2,DISTRICT
SM260799,-59.4167,-34.6667,Mercedes,ADM2,DISTRICT
SM295694,-88.9116,13.644,Mercedes La Ceiba,ADM2,DISTRICT
SM295576,-88.5051,13.5644,Mercedes Umaña,ADM2,DISTRICT
SM275697,-87.778,13.9094,Mercedes de Oriente,ADM2,DISTRICT
SM303128,-81.1114,37.4055,Mercer,ADM2,DISTRICT
SM302322,-80.2577,41.3022,Mercer,ADM2,DISTRICT
SM302051,-101.8315,47.3092,Mercer,ADM2,DISTRICT
SM302133,-84.6294,40.54,Mercer,ADM2,DISTRICT
SM301815,-74.7017,40.2834,Mercer,ADM2,DISTRICT
SM301584,-93.5686,40.4223,Mercer,ADM2,DISTRICT
SM300655,-90.7414,41.2053,Mercer,ADM2,DISTRICT
SM301091,-84.8744,37.811,Mercer,ADM2,DISTRICT
SM263651,-43.3366,-21.1872,Mercês,ADM2,DISTRICT
SM13695,27.7506,56.6966,Merdzenes pagasts,ADM2,DISTRICT
SM238130,166.905,-15.2839,Merei,LANGUAGE,LANGUAGE
SM289891,26.6613,45.131,Merei,ADM2,DISTRICT
SM290176,28.3356,44.0155,Mereni,ADM2,DISTRICT
SM291902,25.6443,44.2288,Merenii De Jos,ADM2,DISTRICT
SM290701,25.461,46.2346,Meresti,ADM2,DISTRICT
SM238131,14.09,10.7458,Merey,LANGUAGE,LANGUAGE
SM305838,44.1726,36.9838,Mergasur,ADM2,DISTRICT
SM305838,44.1726,36.9838,Mergasur,ADM2,DISTRICT
SM305838,44.2047,36.9821,Mergasur,ADM2,DISTRICT
SM305838,44.2047,36.9821,Mergasur,ADM2,DISTRICT
SM291732,24.7132,45.9753,Merghindeal,ADM2,DISTRICT
SM283491,99.0535,12.4241,Mergui,ADM2,DISTRICT
SM238135,143.773,-9.5958,Meriam,LANGUAGE,LANGUAGE
SM255743,144.06,-9.91,Meriam,ETHNICITY,ETHNICITY
SM255743,144.06,-9.91,Meriam,ETHNICITY,ETHNICITY
SM255743,143.7754,-9.6831,Meriam,ETHNICITY,ETHNICITY
SM255743,143.7754,-9.6831,Meriam,ETHNICITY,ETHNICITY
SM266693,-50.2054,-20.4177,Meridiano,ADM2,DISTRICT
SM250512,46.58,-17.96,Merina,ETHNICITY,ETHNICITY
SM250512,46.58,-17.96,Merina,ETHNICITY,ETHNICITY
SM250512,46,-19,Merina,ETHNICITY,ETHNICITY
SM250512,46,-19,Merina,ETHNICITY,ETHNICITY
SM291261,24.7448,44.9667,Merisani,ADM2,DISTRICT
SM300538,-84.6883,33.0407,Meriwether,ADM2,DISTRICT
SM2229,144.6746,13.2713,Merizo,ADM1,DISTRICT
SM298346,26.435,41.2017,Meriç,ADM2,DISTRICT
SM279904,73.25,43.1667,Merkenskiy,ADM2,DISTRICT
SM299008,29.2635,40.6067,Merkez,ADM2,DISTRICT
SM298783,41.4833,38.8379,Merkez,ADM2,DISTRICT
SM298879,41.9147,37.9203,Merkez,ADM2,DISTRICT
SM298712,38.4246,38.333,Merkez,ADM2,DISTRICT
SM298944,27.3038,40.9799,Merkez,ADM2,DISTRICT
SM298625,37.1235,36.7152,Merkez,ADM2,DISTRICT
SM298981,39.5557,39.1711,Merkez,ADM2,DISTRICT
SM298770,28.3617,37.2145,Merkez,ADM2,DISTRICT
SM298791,34.6854,38.6004,Merkez,ADM2,DISTRICT
SM298989,29.2946,38.6846,Merkez,ADM2,DISTRICT
SM298912,35.0069,41.9183,Merkez,ADM2,DISTRICT
SM298919,42.3892,37.5328,Merkez,ADM2,DISTRICT
SM298797,34.8018,38.1033,Merkez,ADM2,DISTRICT
SM298925,36.977,39.7161,Merkez,ADM2,DISTRICT
SM298230,38.268,37.7445,Merkez,ADM2,DISTRICT
SM298366,30.5195,38.797,Merkez,ADM2,DISTRICT
SM18070,40.1938,37.9952,Merkez,ADM2,DISTRICT
SM298299,34.8579,40.5091,Merkez,ADM2,DISTRICT
SM298213,42.1007,38.317,Merkez,ADM2,DISTRICT
SM298360,39.2157,38.6679,Merkez,ADM2,DISTRICT
SM298148,27.8456,37.8384,Merkez,ADM2,DISTRICT
SM298775,35.8702,40.6194,Merkez,ADM2,DISTRICT
SM298448,39.6744,40.4684,Merkez,ADM2,DISTRICT
SM298432,38.3609,40.8266,Merkez,ADM2,DISTRICT
SM298488,43.051,39.713,Merkez,ADM2,DISTRICT
SM298264,26.4949,40.0509,Merkez,ADM2,DISTRICT
SM298244,30.2041,37.6276,Merkez,ADM2,DISTRICT
SM298188,41.1834,37.8436,Merkez,ADM2,DISTRICT
SM298205,40.528,38.9502,Merkez,ADM2,DISTRICT
SM461273,41.31,40.0013,Merkez (2008-),ADM2,DISTRICT
SM18084,34.0284,38.3706,"Merkez (Aksaray, 2017-)",ADM2,DISTRICT
SM18075,30.6205,37.007,"Merkez (Antalya, 2008-)",ADM2,DISTRICT
SM18083,27.8636,39.6468,"Merkez (Balikesir, 2014-)",ADM2,DISTRICT
SM18082,29.1168,37.8264,"Merkez (Denizli, 2014-)",ADM2,DISTRICT
SM18074,30.5192,39.7748,"Merkez (Eskişehir, 2008-)",ADM2,DISTRICT
SM18081,36.8234,37.6877,"Merkez (Kahramanmaraş, 2014-)",ADM2,DISTRICT
SM18073,30.0061,40.7833,"Merkez (Kocaeli, 2008-)",ADM2,DISTRICT
SM18080,27.3911,38.7052,"Merkez (Manisa, 2014-)",ADM2,DISTRICT
SM18079,37.8857,40.9218,"Merkez (Ordu, 2014-)",ADM2,DISTRICT
SM18072,30.3823,40.7891,Merkez (Sakarya) (2008-),ADM2,DISTRICT
SM18071,36.2117,41.2571,"Merkez (Samsun, 2008-)",ADM2,DISTRICT
SM18077,39.7355,40.9387,"Merkez (Trabzon, 2014-)",ADM2,DISTRICT
SM18076,43.3617,38.5143,"Merkez (Van, 2014-)",ADM2,DISTRICT
SM18078,39.1496,37.0822,"Merkez (Şanlıurfa, 2014-)",ADM2,DISTRICT
SM18085,29.0156,37.8061,Merkezefendi Ilcesi,ADM2,DISTRICT
SM304965,45.9299,14.4847,Merkhah Al Ulya,ADM2,DISTRICT
SM304966,46.1563,14.6999,Merkhah As Sufla,ADM2,DISTRICT
SM238137,168.0535,-14.4507,Merlav,LANGUAGE,LANGUAGE
SM260800,-58.7283,-34.6658,Merlo,ADM2,DISTRICT
SM238138,33.7333,16.9333,Meroitic,LANGUAGE,LANGUAGE
SM457590,5.9167,35.5833,Merouana District,ADM2,DISTRICT
SM261412,118.3851,-31.4605,Merredin,ADM2,DISTRICT
SM301735,-98.0377,41.1698,Merrick,ADM2,DISTRICT
SM301810,-71.6802,43.2977,Merrimack,ADM2,DISTRICT
SM12531,-77.8377,18.2179,Merrywood,ADM2,DISTRICT
SM370539,6.1061,49.7489,Mersch,ADM1:ADM2:ADM3,DISTRICT
SM281376,6.1272,49.7564,Mersch,ADM1:ADM2,DISTRICT
SM2230,34.6509,36.862,Mersin,ADM1,DISTRICT
SM284317,103.7167,2.3167,Mersing,ADM2,DISTRICT
SM3966,23.0333,57.3333,Mersraga Novads,ADM1,DISTRICT
SM13696,23.0403,57.3251,Mersraga novads,ADM2,DISTRICT
SM370481,6.4804,49.701,Mertert,ADM1:ADM2:ADM3,DISTRICT
SM274748,-3.3333,51.75,Merthyr Tydfil,ADM1:ADM2,DISTRICT
SM370433,6.0075,49.8339,Mertzig,ADM1:ADM2:ADM3,DISTRICT
SM238141,37.7396,0.2648,Meru,LANGUAGE,LANGUAGE
SM299079,36.801,-3.305,Meru,ADM2,DISTRICT
SM2231,37.6333,0.05,Meru (2010-),ADM1:ADM2,DISTRICT
SM2231,37.6333,0.05,Meru (2010-),ADM1:ADM2,DISTRICT
SM2231,37.518,0,Meru (2010-),ADM1:ADM2,DISTRICT
SM2231,37.518,0,Meru (2010-),ADM1:ADM2,DISTRICT
SM250513,41.51,3.22,Meru (Kenya),ETHNICITY,ETHNICITY
SM250513,41.51,3.22,Meru (Kenya),ETHNICITY,ETHNICITY
SM250513,41.51,3.22,Meru (Kenya),ETHNICITY,ETHNICITY
SM250513,38,0,Meru (Kenya),ETHNICITY,ETHNICITY
SM250513,38,0,Meru (Kenya),ETHNICITY,ETHNICITY
SM250513,38,0,Meru (Kenya),ETHNICITY,ETHNICITY
SM250513,35,0,Meru (Kenya),ETHNICITY,ETHNICITY
SM250513,35,0,Meru (Kenya),ETHNICITY,ETHNICITY
SM250513,35,0,Meru (Kenya),ETHNICITY,ETHNICITY
SM5273,37.93,0.23,Meru North,ADM2,DISTRICT
SM5270,37.6608,-0.3268,Meru South,ADM2,DISTRICT
SM267791,-40.4673,-3.586,Meruoca,ADM2,DISTRICT
SM238142,74.095,26.771,Merwari,LANGUAGE,LANGUAGE
SM298776,35.4712,40.8835,Merzifon,ADM2,DISTRICT
SM286452,11.7456,63.4142,Meråker,ADM2,DISTRICT
SM303352,-108.4665,39.0183,Mesa,ADM2,DISTRICT
SM10909,32.4686,34.8101,Mesa Chorio,ADM2,DISTRICT
SM10910,33.0437,34.7006,Mesa Geitonia,ADM2,DISTRICT
SM238143,9.8723,6.3268,Mesaka,LANGUAGE,LANGUAGE
SM454536,30,11,Mesakin,ETHNICITY,ETHNICITY
SM10911,32.7119,34.8468,Mesana,ADM2,DISTRICT
SM238146,-105.566,33.1291,Mescalero-Chiricahua Apache,LANGUAGE,LANGUAGE
SM238148,147.137,-6.5607,Mese,LANGUAGE,LANGUAGE
SM291619,22.9913,47.1457,Mesesenii De Jos,ADM2,DISTRICT
SM270278,-74.0994,3.1556,Mesetas,ADM2,DISTRICT
SM293076,35.4167,54.3333,Meshchovskiy rayon,ADM2,DISTRICT
SM238154,-89,44,Meskwaki,LANGUAGE,LANGUAGE
SM238156,15.7018,9.2889,Mesme,LANGUAGE,LANGUAGE
SM238157,37.5742,7.5383,Mesmes,LANGUAGE,LANGUAGE
SM10912,32.4587,34.818,Mesogi,ADM2,DISTRICT
SM14345,-97.9976,16.9221,Mesones Hidalgo,ADM2,DISTRICT
SM238161,38.3386,8.1062,Mesqan,LANGUAGE,LANGUAGE
SM263652,-42.616,-19.2496,Mesquita,ADM2,DISTRICT
SM9963,-43.4597,-22.7899,Mesquita,ADM2,DISTRICT
SM238162,17.8603,40.5383,Messapic,LANGUAGE,LANGUAGE
SM265389,-35.8222,-9.3212,Messias,ADM2,DISTRICT
SM277902,14.8667,38.05,Messina,ADM2,DISTRICT
SM250153,28,11,Messiria,ETHNICITY,ETHNICITY
SM11326,42.55,43.05,Mest'iis Munitsip'alit'et'I (excl. Mestia),ADM2,DISTRICT
SM11327,42.75,43,Mestia,ADM2,DISTRICT
SM255748,-99,19,Mestizo- Mexico,ETHNICITY,ETHNICITY
SM4139,15.1983,45.7851,Mestna Obcina Novo mesto (2006-),ADM1,DISTRICT
SM298805,37.7742,40.4642,Mesudiye,ADM2,DISTRICT
SM276450,105.4013,-4.0439,Mesuji,ADM2,DISTRICT
SM289370,-7.8728,41.1619,Mesão Frio,ADM2,DISTRICT
SM266694,-50.6094,-19.9581,Mesópolis,ADM2,DISTRICT
SM2232,-73,3.5,Meta,ADM1,DISTRICT
SM238165,9.8742,5.8894,Meta',LANGUAGE,LANGUAGE
SM283165,-92.1978,14.8251,Metapa,ADM2,DISTRICT
SM295557,-89.4333,14.3,Metapán,ADM2,DISTRICT
SM283953,36.8035,-14.3293,Metarica,ADM2,DISTRICT
SM301092,-85.6293,36.9905,Metcalfe,ADM2,DISTRICT
SM274353,36,11,Metekel,ADM2,DISTRICT
SM274353,36,11,Metekel,ADM2,DISTRICT
SM274353,35.7198,10.4267,Metekel,ADM2,DISTRICT
SM274353,35.7198,10.4267,Metekel,ADM2,DISTRICT
SM281938,-99.5894,19.2392,Metepec,ADM2,DISTRICT
SM281684,-98.3437,20.2638,Metepec,ADM2,DISTRICT
SM289809,23.4171,46.1054,Metes,ADM2,DISTRICT
SM297844,125.7509,-8.5331,Metinaro,ADM2,DISTRICT
SM305634,17.6486,43.053,Metković,ADM2,DISTRICT
SM298087,8.4022,34.2389,Metlaoui,ADM2,DISTRICT
SM281619,-98.5581,17.1236,Metlatónoc (pre-2003),ADM2,DISTRICT
SM238169,-98.3514,17.1947,Metlatónoc Mixtec,LANGUAGE,LANGUAGE
SM296271,15.3108,45.6565,Metlika,ADM1:ADM2,DISTRICT
SM255751,-73.69,40.72,Metoac,ETHNICITY,ETHNICITY
SM298070,9.9538,34.0646,Metouia,ADM2,DISTRICT
SM13697,26.356,56.6857,Metrienas pagasts,ADM2,DISTRICT
SM276451,105.2995,-5.1186,Metro,ADM2,DISTRICT
SM2233,121.046,14.5778,Metropolitan Manila,ADM1,DISTRICT
SM15070,40.3698,-12.9837,Metuge District (excl. Pemba),ADM2,DISTRICT
SM281685,-98.797,20.5675,Metztitlán,ADM2,DISTRICT
SM260951,-64.6667,-25.5,Metán,ADM2,DISTRICT
SM13373,103.4219,18.6379,Meun,ADM2,DISTRICT
SM280837,100.5264,20.6759,Meung,ADM2,DISTRICT
SM274496,6.1566,48.6508,Meurthe-et-Moselle,ADM2,DISTRICT
SM274497,5.3637,48.9718,Meuse,ADM2,DISTRICT
SM268375,89.5685,27.3994,Mewang,ADM2,DISTRICT
SM238175,73.68,24.57,Mewari,LANGUAGE,LANGUAGE
SM276848,77.0466,27.9817,Mewat,ADM2,DISTRICT
SM324077,-112.743,54.6125,Mewatha Beach,ADM2:ADM3,DISTRICT
SM238176,76.947,27.736,Mewati,LANGUAGE,LANGUAGE
SM255757,89.18,33.89,Mewu Fantzu,ETHNICITY,ETHNICITY
SM282725,-115.1477,32.5051,Mexicali,ADM2,DISTRICT
SM281939,-99.5859,19.209,Mexicaltzingo,ADM2,DISTRICT
SM238181,-98.5,19.75,Mexican Sign Language,LANGUAGE,LANGUAGE
SM454466,-99.1,19.433,Mexico City Residents,ETHNICITY,ETHNICITY
SM282487,-101.122,22.2846,Mexquitic de Carmona,ADM2,DISTRICT
SM281826,-102.7551,21.2671,Mexticacán,ADM2,DISTRICT
SM255760,-97.77,21.21,Mextitlaneca,ETHNICITY,ETHNICITY
SM255761,132.83,-0.66,Meyach,ETHNICITY,ETHNICITY
SM238182,133.618,-1.1802,Meyah,LANGUAGE,LANGUAGE
SM11787,53.9333,32.1833,Meybod,ADM2,DISTRICT
SM238184,53.24,35.34,Meyma'i,DIALECT,LANGUAGE
SM269533,10.1478,5.9785,Mezam,ADM2,DISTRICT
SM13698,26.2208,56.5556,Mezares pagasts,ADM2,DISTRICT
SM262493,23.7333,43.1333,Mezdra,ADM2,DISTRICT
SM293587,44,65.75,Mezenskiy rayon,ADM2,DISTRICT
SM294941,41,59.25,Mezhdurechenskiy rayon,ADM2,DISTRICT
SM293333,45,58.8333,Mezhevskiy rayon,ADM2,DISTRICT
SM18086,34.4299,36.894,Mezitli Ilcesi,ADM2,DISTRICT
SM238186,-97.5879,18.1442,Mezontla Popoloca,LANGUAGE,LANGUAGE
SM13699,24.1022,56.4711,Mezotnes pagasts,ADM2,DISTRICT
SM281502,-104.5535,22.884,Mezquital,ADM2,DISTRICT
SM238187,-99.5564,20.2274,Mezquital Otomi,LANGUAGE,LANGUAGE
SM283058,-103.3489,21.2054,Mezquital del Oro,ADM2,DISTRICT
SM281827,-104.0453,22.2061,Mezquitic,ADM2,DISTRICT
SM13700,27.5646,56.7,Mezvidu pagasts,ADM2,DISTRICT
SM276242,20.9169,47.7984,Mezőcsáti,ADM2,DISTRICT
SM276226,21.0032,46.4089,Mezőkovácsház,ADM2,DISTRICT
SM276243,20.6362,47.8488,Mezőkövesdi,ADM2,DISTRICT
SM276132,20.5905,47.0249,Mezőtúri,ADM2,DISTRICT
SM275841,-87.7842,14.8205,Meámbar,ADM2,DISTRICT
SM296280,14.855,46.5228,Mežica,ADM1:ADM2,DISTRICT
SM11422,-1.0667,5.2,Mfatseman (2012-),ADM2,DISTRICT
SM238190,16.2559,-4.2657,Mfinu,LANGUAGE,LANGUAGE
SM269797,13.8188,-4.3618,Mfouati,ADM2,DISTRICT
SM269512,11.5167,3.8667,Mfoundi,ADM1:ADM2,DISTRICT
SM295011,32.8333,53.1667,Mglinskiy rayon,ADM2,DISTRICT
SM296685,31.1147,-26.305,Mhlangatane,ADM2,DISTRICT
SM274331,37.1071,13.8568,Mi'irabawi,ADM2,DISTRICT
SM274331,37.1071,13.8568,Mi'irabawi,ADM2,DISTRICT
SM274331,37,13.75,Mi'irabawi,ADM2,DISTRICT
SM274331,37,13.75,Mi'irabawi,ADM2,DISTRICT
SM238300,-65.5766,45.9941,Mi'kmaq,LANGUAGE,LANGUAGE
SM10913,33.4203,35.2202,Mia Milia,ADM2,DISTRICT
SM282104,-99.3539,18.781,Miacatlán,ADM2,DISTRICT
SM14347,-96.6123,16.3449,Miahuatlan de Porfirio Diaz,ADM2,DISTRICT
SM238203,-90,40,Miami,LANGUAGE,LANGUAGE
SM302134,-84.2289,40.0535,Miami,ADM2,DISTRICT
SM300739,-86.045,40.7695,Miami,ADM2,DISTRICT
SM300946,-94.8381,38.5636,Miami,ADM2,DISTRICT
SM255765,-87.85,41.38,Miami,ETHNICITY,ETHNICITY
SM255765,-87.85,41.38,Miami,ETHNICITY,ETHNICITY
SM255765,-86,40,Miami,ETHNICITY,ETHNICITY
SM255765,-86,40,Miami,ETHNICITY,ETHNICITY
SM300350,-80.4987,25.609,Miami-Dade,ADM2,DISTRICT
SM17515,-60.9695,13.923,Miami/Bexon,ADM2,DISTRICT
SM238207,141.523,-4.7088,Mian,LANGUAGE,LANGUAGE
SM371047,45.3873,-19.1789,Miandrivazo,ADM2:ADM3,DISTRICT
SM277689,47.7,37.4167,Mianeh,ADM2,DISTRICT
SM238205,145.276,-4.4212,Miani,LANGUAGE,LANGUAGE
SM250469,-6,12,Mianka,ETHNICITY,ETHNICITY
SM375478,71.5133,32.7013,Mianwali,ADM2:ADM3,DISTRICT
SM269323,104.7536,31.9112,Mianyang,ADM2,DISTRICT
SM255768,103.55,26.84,Miao,ETHNICITY,ETHNICITY
SM255768,103.55,26.84,Miao,ETHNICITY,ETHNICITY
SM255768,103.55,26.84,Miao,ETHNICITY,ETHNICITY
SM255768,103.55,26.84,Miao,ETHNICITY,ETHNICITY
SM255768,103.55,26.84,Miao,ETHNICITY,ETHNICITY
SM255768,110,19.333,Miao,ETHNICITY,ETHNICITY
SM255768,110,19.333,Miao,ETHNICITY,ETHNICITY
SM255768,110,19.333,Miao,ETHNICITY,ETHNICITY
SM255768,110,19.333,Miao,ETHNICITY,ETHNICITY
SM255768,110,19.333,Miao,ETHNICITY,ETHNICITY
SM255768,107,26,Miao,ETHNICITY,ETHNICITY
SM255768,107,26,Miao,ETHNICITY,ETHNICITY
SM255768,107,26,Miao,ETHNICITY,ETHNICITY
SM255768,107,26,Miao,ETHNICITY,ETHNICITY
SM255768,107,26,Miao,ETHNICITY,ETHNICITY
SM255768,109,28,Miao,ETHNICITY,ETHNICITY
SM255768,109,28,Miao,ETHNICITY,ETHNICITY
SM255768,109,28,Miao,ETHNICITY,ETHNICITY
SM255768,109,28,Miao,ETHNICITY,ETHNICITY
SM255768,109,28,Miao,ETHNICITY,ETHNICITY
SM255768,109,28,Miao,ETHNICITY,ETHNICITY
SM255768,109,28,Miao,ETHNICITY,ETHNICITY
SM255768,109,28,Miao,ETHNICITY,ETHNICITY
SM255768,109,28,Miao,ETHNICITY,ETHNICITY
SM255768,109,28,Miao,ETHNICITY,ETHNICITY
SM299071,120.9064,24.4897,Miaoli,ADM2,DISTRICT
SM370950,46.8183,-18.8748,Miarinarivo,ADM2:ADM3,DISTRICT
SM238210,-53.42,-12.5,Miarrã,LANGUAGE,LANGUAGE
SM292757,60,55.0833,Miass gorsovet,ADM2,DISTRICT
SM291166,24.4109,46.3558,Mica,ADM2,DISTRICT
SM290114,23.9759,47.1444,Mica,ADM2,DISTRICT
SM291733,24.1326,46.1061,Micasasa,ADM2,DISTRICT
SM291262,24.8558,44.9737,Micesti,ADM2,DISTRICT
SM292508,24.3157,46.8431,Micestii De Campie,ADM2,DISTRICT
SM276794,-4.5899,54.2833,Michael,ADM1:ADM2,DISTRICT
SM296142,21.9333,48.75,Michalovce,ADM2,DISTRICT
SM303740,-72.1833,7.9833,Michelena,ADM2,DISTRICT
SM271904,-69,18.9167,Miches,ADM2,DISTRICT
SM299142,39.7761,-4.9714,Micheweni,ADM2,DISTRICT
SM238213,-99.9119,49.1681,Michif,LANGUAGE,LANGUAGE
SM255770,-90.34,38.52,Michigamea,ETHNICITY,ETHNICITY
SM2236,-85.5003,44.2503,Michigan,ADM1,DISTRICT
SM284836,13.3977,10.5807,Michika,ADM2,DISTRICT
SM255771,-63.58,55.53,Michikamau,ETHNICITY,ETHNICITY
SM2237,-101.8333,19.1667,Michoacán,ADM1,DISTRICT
SM238215,-100.293,19.7239,Michoacán Mazahua,LANGUAGE,LANGUAGE
SM238214,-103.361,18.3412,Michoacán Nahuatl,LANGUAGE,LANGUAGE
SM13211,72.75,49.5,Michurin Audany,ADM2,DISTRICT
SM292294,27.8422,46.822,Miclesti,ADM2,DISTRICT
SM255772,-64.22,46.01,Micmac,ETHNICITY,ETHNICITY
SM255772,-64.22,46.01,Micmac,ETHNICITY,ETHNICITY
SM255772,-64.22,46.01,Micmac,ETHNICITY,ETHNICITY
SM255772,-64.22,46.01,Micmac,ETHNICITY,ETHNICITY
SM255772,-67.37,47.77,Micmac,ETHNICITY,ETHNICITY
SM255772,-67.37,47.77,Micmac,ETHNICITY,ETHNICITY
SM255772,-67.37,47.77,Micmac,ETHNICITY,ETHNICITY
SM255772,-67.37,47.77,Micmac,ETHNICITY,ETHNICITY
SM255772,-64,47,Micmac,ETHNICITY,ETHNICITY
SM255772,-64,47,Micmac,ETHNICITY,ETHNICITY
SM255772,-64,47,Micmac,ETHNICITY,ETHNICITY
SM255772,-64,47,Micmac,ETHNICITY,ETHNICITY
SM255772,-65,47,Micmac,ETHNICITY,ETHNICITY
SM255772,-65,47,Micmac,ETHNICITY,ETHNICITY
SM255772,-65,47,Micmac,ETHNICITY,ETHNICITY
SM255772,-65,47,Micmac,ETHNICITY,ETHNICITY
SM255772,-65,46.99,Micmac,ETHNICITY,ETHNICITY
SM255772,-65,46.99,Micmac,ETHNICITY,ETHNICITY
SM255772,-65,46.99,Micmac,ETHNICITY,ETHNICITY
SM255772,-65,46.99,Micmac,ETHNICITY,ETHNICITY
SM275127,10.6829,2.1076,Micomeseng,ADM2,DISTRICT
SM2238,-60.9456,13.8204,Micoud,ADM1,DISTRICT
SM291672,22.9365,47.9169,Micula,ADM2,DISTRICT
SM238257,138.945,-4.0632,Mid Grand Valley Dani,LANGUAGE,LANGUAGE
SM261709,139.4164,-34.4157,Mid Murray,ADM2,DISTRICT
SM274712,-6.791,54.6219,Mid Ulster,ADM1:ADM2,DISTRICT
SM274711,-6.1014,54.8744,Mid and East Antrim,ADM1:ADM2,DISTRICT
SM18561,152.0447,-32.0721,Mid-Coast,ADM2,DISTRICT
SM238270,22.4666,5.8789,Mid-Southern Banda,LANGUAGE,LANGUAGE
SM261582,149.7675,-32.602,Mid-Western Regional,ADM2,DISTRICT
SM285625,3.6546,51.4972,Middelburg,ADM2,DISTRICT
SM271740,9.881,55.4578,Middelfart (2007-),ADM2,DISTRICT
SM285557,6.5384,52.8672,Midden-Drenthe,ADM2,DISTRICT
SM15169,6.8331,53.1511,Midden-Groningen,ADM2,DISTRICT
SM238253,35.195,32.2764,Middle Aramaic,LANGUAGE,LANGUAGE
SM238233,108.9,34.27,Middle Chinese,LANGUAGE,LANGUAGE
SM238244,152.7803,-29.0427,Middle Clarence Bandjalang,LANGUAGE,LANGUAGE
SM255775,152.78,-29.59,Middle Clarence Bandjalang,ETHNICITY,ETHNICITY
SM255775,152.78,-29.59,Middle Clarence Bandjalang,ETHNICITY,ETHNICITY
SM255775,153.0752,-29.2513,Middle Clarence Bandjalang,ETHNICITY,ETHNICITY
SM255775,153.0752,-29.2513,Middle Clarence Bandjalang,ETHNICITY,ETHNICITY
SM238223,5.34,51.656,Middle Dutch,LANGUAGE,LANGUAGE
SM238220,-1.11,51.65,Middle English,LANGUAGE,LANGUAGE
SM288613,142.0333,-7.1667,Middle Fly,ADM2,DISTRICT
SM238232,10.599,48.254,Middle High German,LANGUAGE,LANGUAGE
SM238239,78.97,26.61,Middle Indo-Aryan,LANGUAGE,LANGUAGE
SM346764,29.9979,62.1264,Middle Karelia,ADM2:ADM3,DISTRICT
SM238221,9.67,53.14,Middle Low German,LANGUAGE,LANGUAGE
SM238240,110.006,48.2,Middle Mongol,LANGUAGE,LANGUAGE
SM12532,-77.8381,18.1146,Middle Quarters,ADM2,DISTRICT
SM288571,144.75,-4.9167,Middle Ramu,ADM2,DISTRICT
SM238229,146.3991,-6.8825,Middle Watut,LANGUAGE,LANGUAGE
SM274654,-1.2,54.5333,Middlesbrough,ADM1:ADM2,DISTRICT
SM303050,-76.5781,37.5974,Middlesex,ADM2,DISTRICT
SM301816,-74.4089,40.44,Middlesex,ADM2,DISTRICT
SM301250,-71.3918,42.4856,Middlesex,ADM2,DISTRICT
SM303385,-72.5231,41.4354,Middlesex,ADM2,DISTRICT
SM268866,-81.4165,43.0001,Middlesex,ADM2,DISTRICT
SM12533,-76.4356,17.9397,Middleton,ADM2,DISTRICT
SM4425,-4.7369,32.6805,Midelt (2009-),ADM2,DISTRICT
SM304849,42.8891,16.192,Midi,ADM2,DISTRICT
SM458691,135.3838,-29.1867,Midlaliri,ETHNICITY,ETHNICITY
SM302712,-102.0316,31.8692,Midland,ADM2,DISTRICT
SM301307,-84.3881,43.6469,Midland,ADM2,DISTRICT
SM2242,29.8,-19.2,Midlands,ADM1,DISTRICT
SM274723,-3.0933,55.8235,Midlothian,ADM1:ADM2,DISTRICT
SM238268,27.2236,14.789,Midob,LANGUAGE,LANGUAGE
SM250515,28.56,15.39,Midob,ETHNICITY,ETHNICITY
SM250515,28.56,15.39,Midob,ETHNICITY,ETHNICITY
SM250515,27,16,Midob,ETHNICITY,ETHNICITY
SM250515,27,16,Midob,ETHNICITY,ETHNICITY
SM370973,46.9967,-23.3431,Midongy-Sud,ADM2:ADM3,DISTRICT
SM278093,139.3473,36.5304,Midori,ADM2,DISTRICT
SM15300,9.1661,59.4394,Midt-Telemark,ADM2,DISTRICT
SM2243,9.5,56.1667,Midtjylland,ADM1,DISTRICT
SM286183,10.287,63.039,Midtre Gauldal,ADM2,DISTRICT
SM2244,-177.3781,28.2094,Midway,ADM0:ADM1,DISTRICT
SM298746,41.375,37.4006,Midyat,ADM2,DISTRICT
SM2245,136.383,34.5212,Mie,ADM1:ADMX,DISTRICT
SM13007,136.5091,35.0435,Mie-gun,ADM2,DISTRICT
SM288950,20.0329,50.3572,Miechów,ADM2,DISTRICT
SM289009,21.3914,50.3139,Mielec,ADM2,DISTRICT
SM282653,-99.2326,26.4313,Mier,ADM2,DISTRICT
SM282180,-100.1728,23.4267,Mier y Noriega,ADM2,DISTRICT
SM290702,25.7999,46.3609,Miercurea Ciuc,ADM2,DISTRICT
SM291167,24.7549,46.5476,Miercurea Nirajului,ADM2,DISTRICT
SM291734,23.7977,45.8625,Miercurea Sibiului,ADM2,DISTRICT
SM302323,-77.617,40.6104,Mifflin,ADM2,DISTRICT
SM269545,10.4,5.4833,Mifi,ADM2,DISTRICT
SM284796,9.6903,12.2313,Miga,ADM2,DISTRICT
SM238282,18.9614,12.2367,Migaama,LANGUAGE,LANGUAGE
SM238281,147.713,-6.3324,Migabac,LANGUAGE,LANGUAGE
SM17516,-61.0181,13.8417,Migny,ADM2,DISTRICT
SM2246,34.409,-0.982,"Migori District (1992-1993, 2010-)",ADM1:ADM2,DISTRICT
SM282654,-99.062,26.2671,Miguel Alemán,ADM2,DISTRICT
SM265056,-42.7726,-4.1579,Miguel Alves,ADM2,DISTRICT
SM283059,-103.4641,24.149,Miguel Auza,ADM2,DISTRICT
SM267538,-40.579,-11.5255,Miguel Calmon,ADM2,DISTRICT
SM283327,-99.1921,19.4082,Miguel Hidalgo,ADM2,DISTRICT
SM265057,-42.6894,-5.7287,Miguel Leão,ADM2,DISTRICT
SM265283,-43.4575,-22.5163,Miguel Pereira,ADM2,DISTRICT
SM243775,-60.9609,-16.6696,Migueleño Chiquitano,LANGUAGE,LANGUAGE
SM266695,-48.1631,-20.188,Miguelópolis,ADM2,DISTRICT
SM18340,-55.6399,-34.4865,Migues,ADM2,DISTRICT
SM291430,24.7966,44.1187,Mihaesti,ADM2,DISTRICT
SM291263,25.0194,45.1156,Mihaesti,ADM2,DISTRICT
SM292058,28.655,44.9597,Mihai Bravu,ADM2,DISTRICT
SM290583,26.0581,44.1413,Mihai Bravu,ADM2,DISTRICT
SM290115,23.716,46.5348,Mihai Viteazu,ADM2,DISTRICT
SM290177,28.7046,44.6373,Mihai Viteazu,ADM2,DISTRICT
SM292060,28.7286,45.0223,Mihail Kogalniceanu,ADM2,DISTRICT
SM290178,28.5192,44.4048,Mihail Kogalniceanu,ADM2,DISTRICT
SM291735,24.3662,46,Mihaileni,ADM2,DISTRICT
SM290703,25.8303,46.4887,Mihaileni,ADM2,DISTRICT
SM290584,25.941,44.3167,Mihailesti,ADM2,DISTRICT
SM292581,27.0962,47.8796,Mihalaseni,ADM2,DISTRICT
SM298419,30.5838,40.0371,Mihalgazi,ADM2,DISTRICT
SM289810,23.7384,46.1764,Mihalt,ADM2,DISTRICT
SM298408,31.5009,39.8756,Mihalıçcık,ADM2,DISTRICT
SM279659,135.974,35.5969,Mihama,ADM2,DISTRICT
SM278129,133.004,34.4582,Mihara,ADM2,DISTRICT
SM291168,24.1931,46.6702,Mihesu De Campie,ADM2,DISTRICT
SM305734,15.9703,46.1335,Mihovljan,ADM2,DISTRICT
SM260108,70.0951,34.6518,Mihtarlam,ADM2,DISTRICT
SM282739,-96.8806,19.7363,Mihuatlán,ADM2,DISTRICT
SM238285,22.69,14.16,Miisiirii,LANGUAGE,LANGUAGE
SM458088,118.1453,-22.9997,Mijaranypa,ETHNICITY,ETHNICITY
SM285182,9.6085,9.0069,Mikang,ADM2,DISTRICT
SM278301,141.9927,43.2398,Mikasa,ADM2,DISTRICT
SM238293,-80.9085,26.0814,Mikasuki,LANGUAGE,LANGUAGE
SM13008,134.5467,35.5387,Mikata-gun,ADM2,DISTRICT
SM238296,15.9518,1.4883,Mikaya-Bambengangale-Baluma,LANGUAGE,LANGUAGE
SM12534,-77.5549,18.0425,Mike Town,ADM2,DISTRICT
SM17003,43.4167,50,Mikhaylovka Urban Okrug,ADM2,DISTRICT
SM294092,132,44,Mikhaylovskiy rayon,ADM2,DISTRICT
SM292976,79.6667,51.75,Mikhaylovskiy rayon,ADM2,DISTRICT
SM293214,128.75,49.75,Mikhaylovskiy rayon,ADM2,DISTRICT
SM278477,134.1385,34.2315,Miki,ADM2,DISTRICT
SM278341,135.0582,34.8355,Miki,ADM2,DISTRICT
SM458692,137.1439,-25.7302,Mikiri-nganha,ETHNICITY,ETHNICITY
SM11223,27.2667,61.6833,Mikkeli (2009-),ADM2,DISTRICT
SM296332,15.7095,46.4863,Miklavž na Dravskem Polju,ADM1:ADM2,DISTRICT
SM305533,17.8084,45.6142,Mikleuš,ADM2,DISTRICT
SM288725,18.8369,50.1513,Mikołów,ADM2,DISTRICT
SM255778,142.31,-9.06,Mikud,ETHNICITY,ETHNICITY
SM293101,159,55,Mil'kovskiy rayon,ADM2,DISTRICT
SM2247,6.1667,36.4167,Mila,ADM1,DISTRICT
SM267793,-38.9624,-7.2606,Milagres,ADM2,DISTRICT
SM267539,-39.8178,-12.9559,Milagres,ADM2,DISTRICT
SM262823,-42.8647,-3.5055,Milagres do Maranhão,ADM2,DISTRICT
SM273436,-79.6,-2.1667,Milagro,ADM2,DISTRICT
SM302713,-96.9769,30.7863,Milam,ADM2,DISTRICT
SM238303,95.1861,28.554,Milang,LANGUAGE,LANGUAGE
SM15071,35.7758,-16.0999,Milange District,ADM2,DISTRICT
SM277873,9.1459,45.4519,Milano,ADM2,DISTRICT
SM298771,27.7952,37.327,Milas,ADM2,DISTRICT
SM292509,24.4337,46.8225,Milas,ADM2,DISTRICT
SM292208,24.4679,45.0405,Milcoiu,ADM2,DISTRICT
SM261256,141.8523,-34.8645,Mildura,ADM2,DISTRICT
SM12535,-77.5656,18.134,Mile Gully (Manchester),ADM2,DISTRICT
SM18517,-76.9605,18.318,Mile Gully (Saint Mary),ADM2,DISTRICT
SM292582,26.7297,48.0912,Mileanca,ADM2,DISTRICT
SM18300,55.9128,25.101,Milehah,ADM2,DISTRICT
SM305181,29.1681,-11.8488,Milenge,ADM2,DISTRICT
SM305084,43.3318,15.3224,Milhan,ADM2,DISTRICT
SM267794,-39.2253,-5.669,Milhã,ADM2,DISTRICT
SM238305,100.611,24.115,Mili,LANGUAGE,LANGUAGE
SM4103,171.9167,6.1333,Mili Atoll,ADM1,DISTRICT
SM10914,33.7972,35.2367,Milia,ADM2,DISTRICT
SM457596,2.1667,36.25,Miliana District,ADM2,DISTRICT
SM288656,17.2829,51.5079,Milicz,ADM2,DISTRICT
SM10915,32.461,34.94,Miliou,ADM2,DISTRICT
SM313236,19.0825,44.1439,Milići,ADM2:ADM3,DISTRICT
SM12536,-77.3514,17.8756,Milk River,ADM2,DISTRICT
SM323827,-112.0845,49.1542,Milk River,ADM2:ADM3,DISTRICT
SM285936,5.7708,51.6871,Mill en Sint Hubert,ADM2,DISTRICT
SM302898,-113.1005,39.0738,Millard,ADM2,DISTRICT
SM238307,-67.5696,-31.7973,Millcayac,LANGUAGE,LANGUAGE
SM255779,-69.48,-31.45,Millcayac,ETHNICITY,ETHNICITY
SM301381,-93.6301,45.938,Mille Lacs,ADM2,DISTRICT
SM301585,-92.4284,38.2145,Miller,ADM2,DISTRICT
SM300382,-84.7307,31.164,Miller,ADM2,DISTRICT
SM302392,-93.8916,33.3122,Miller,ADM2,DISTRICT
SM17517,-60.9888,13.9099,Millet,ADM2,DISTRICT
SM324010,-113.4806,53.0887,Millet,ADM2:ADM3,DISTRICT
SM302714,-98.5955,31.4952,Mills,ADM2,DISTRICT
SM300845,-95.6213,41.0335,Mills,ADM2,DISTRICT
SM305469,16.4495,43.3271,Milna,ADM2,DISTRICT
SM2248,150.3149,-10.5526,Milne Bay,ADM1,DISTRICT
SM323880,-112.8831,50.5678,Milo,ADM2:ADM3,DISTRICT
SM290921,27.2034,44.7582,Milosesti,ADM2,DISTRICT
SM283328,-99.0215,19.1941,Milpa Alta,ADM2,DISTRICT
SM255780,143.75,-32.13,Milpulko,ETHNICITY,ETHNICITY
SM255780,143.75,-32.13,Milpulko,ETHNICITY,ETHNICITY
SM255780,142.4142,-31.8085,Milpulko,ETHNICITY,ETHNICITY
SM255780,142.4142,-31.8085,Milpulko,ETHNICITY,ETHNICITY
SM265058,-41.5946,-4.7402,Milton Brandão,ADM2,DISTRICT
SM274655,-0.75,52.0833,Milton Keynes,ADM1:ADM2,DISTRICT
SM238309,17.4126,10.2347,Miltu,LANGUAGE,LANGUAGE
SM238310,-124.258,43.3215,Miluk,LANGUAGE,LANGUAGE
SM260386,16.6785,-7.0385,Milunga,ADM2,DISTRICT
SM303258,-87.9291,43.0217,Milwaukee,ADM2,DISTRICT
SM238311,29.3186,36.3561,Milyan,LANGUAGE,LANGUAGE
SM294161,41.7498,48.6655,Milyutinskiy rayon,ADM2,DISTRICT
SM270878,-75.5461,1.1538,Milán,ADM2,DISTRICT
SM279408,134.1433,34.0189,Mima,ADM2,DISTRICT
SM4189,121.134,12.8701,Mimaropa,ADM1,DISTRICT
SM279023,134.2305,35.0442,Mimasaka,ADM2,DISTRICT
SM278762,131.1651,31.7182,Mimata,ADM2,DISTRICT
SM238313,21.1132,13.8275,Mimi-Gaudefroy,LANGUAGE,LANGUAGE
SM238314,20.7241,14.3021,Mimi-Nachtigal,LANGUAGE,LANGUAGE
SM276532,136.5656,-4.5436,Mimika,ADM2,DISTRICT
SM268087,-48.3445,-15.0483,Mimoso de Goiás,ADM2,DISTRICT
SM268007,-41.3757,-21.1047,Mimoso do Sul,ADM2,DISTRICT
SM238332,118.32,27.05,Min Bei Chinese,LANGUAGE,LANGUAGE
SM297163,100.7533,13.8125,Min Buri,ADM2,DISTRICT
SM238333,119.5,26,Min Dong Chinese,LANGUAGE,LANGUAGE
SM238362,118.17,24.5,Min Nan Chinese,LANGUAGE,LANGUAGE
SM255781,115,24,Min Nan Chinese (hinterland of Swatow),ETHNICITY,ETHNICITY
SM238375,117.376,26.0686,Min Zhong Chinese,LANGUAGE,LANGUAGE
SM282181,-100.7648,26.2171,Mina,ADM2,DISTRICT
SM238325,13.8405,10.3805,Mina (Cameroon),LANGUAGE,LANGUAGE
SM238317,74.5977,23.7425,Mina (India),LANGUAGE,LANGUAGE
SM265390,-36.8863,-9.3186,Minador do Negrão,ADM2,DISTRICT
SM238326,44.6728,16.1667,Minaean,LANGUAGE,LANGUAGE
SM276621,124.83,1.2537,Minahasa,ADM2,DISTRICT
SM255782,124.77,1.11,Minahasa,ETHNICITY,ETHNICITY
SM276618,124.5971,1.2129,Minahasa Selatan,ADM2,DISTRICT
SM276619,124.7925,1.0563,Minahasa Tenggara,ADM2,DISTRICT
SM276620,124.96,1.4025,Minahasa Utara,ADM2,DISTRICT
SM278631,130.4621,32.1706,Minamata,ADM2,DISTRICT
SM279602,138.3541,35.6507,Minami-Alps,ADM2,DISTRICT
SM13009,139.5156,37.1858,Minamiaizu-gun,ADM2,DISTRICT
SM13010,140.1137,39.9547,Minamiakita-gun,ADM2,DISTRICT
SM278535,139.0702,35.3099,Minamiashigara,ADM2,DISTRICT
SM278342,134.7647,34.2753,Minamiawaji,ADM2,DISTRICT
SM279364,139.9285,35.0366,Minamibōsō,ADM2,DISTRICT
SM279660,136.2133,35.7721,Minamiechizen,ADM2,DISTRICT
SM13011,135.6487,34.4686,Minamikawachi-gun,ADM2,DISTRICT
SM13012,138.3991,35.4177,Minamikoma-gun,ADM2,DISTRICT
SM278494,130.4368,31.3404,Minamikyūshū,ADM2,DISTRICT
SM13013,135.9913,33.813,Minamimuro-gun,ADM2,DISTRICT
SM13014,138.5294,36.0258,Minamisaku-gun,ADM2,DISTRICT
SM278496,130.2696,31.3879,Minamisatsuma,ADM2,DISTRICT
SM278870,130.257,32.6757,Minamishimabara,ADM2,DISTRICT
SM279752,140.9285,37.6311,Minamisōma,ADM2,DISTRICT
SM13015,140.5412,40.5338,Minamitsugaru Gun,ADM2,DISTRICT
SM13016,138.7436,35.4976,Minamitsuru-gun,ADM2,DISTRICT
SM278934,138.9374,37.0559,Minamiuonuma,ADM2,DISTRICT
SM255783,101.4,-1.22,Minang,ETHNICITY,ETHNICITY
SM255783,101.4,-1.22,Minang,ETHNICITY,ETHNICITY
SM255783,99.57,0.7,Minang,ETHNICITY,ETHNICITY
SM255783,99.57,0.7,Minang,ETHNICITY,ETHNICITY
SM255783,101,-1,Minang,ETHNICITY,ETHNICITY
SM255783,101,-1,Minang,ETHNICITY,ETHNICITY
SM238318,100.44,-0.896,Minangkabau,LANGUAGE,LANGUAGE
SM238324,144.011,-7.6172,Minanibai-Foia Foia,LANGUAGE,LANGUAGE
SM270989,-77.6103,21.4875,Minas,ADM2,DISTRICT
SM260917,-70.8333,-36.8333,Minas,ADM2,DISTRICT
SM261123,-65.3333,-31,Minas,ADM2,DISTRICT
SM2249,-44,-18,Minas Gerais,ADM1,DISTRICT
SM263653,-42.4089,-17.355,Minas Novas,ADM2,DISTRICT
SM271036,-83.9469,22.5845,Minas de Matahambre,ADM2,DISTRICT
SM275842,-87.3484,14.7988,Minas de Oro,ADM2,DISTRICT
SM265761,-52.12,-30.0719,Minas do Leão,ADM2,DISTRICT
SM14348,-94.4122,17.7802,Minatitlan (1997-),ADM2,DISTRICT
SM283323,-104.0583,19.3717,Minatitlán,ADM2,DISTRICT
SM279452,139.7333,35.65,Minato,ADM2,DISTRICT
SM238319,150.501,-9.5992,Minaveha,LANGUAGE,LANGUAGE
SM268089,-48.3878,-13.4268,Minaçu,ADM2,DISTRICT
SM283511,94.4903,20.3796,Minbu,ADM2,DISTRICT
SM255784,100,26,Minchia,ETHNICITY,ETHNICITY
SM283500,93.3984,21.4846,Mindat,ADM2,DISTRICT
SM238340,-8.6887,39.5144,Minderico,LANGUAGE,LANGUAGE
SM238335,146.15,-5.5,Mindiri,LANGUAGE,LANGUAGE
SM269805,14.3811,-4.1656,Mindouli,ADM2,DISTRICT
SM263654,-44.5948,-21.6684,Minduri,ADM2,DISTRICT
SM17518,-60.9095,13.8731,Mine,ADM2,DISTRICT
SM279577,131.2517,34.2108,Mine,ADM2,DISTRICT
SM268090,-52.5511,-17.5694,Mineiros,ADM2,DISTRICT
SM266696,-48.4407,-22.4486,Mineiros do Tietê,ADM2,DISTRICT
SM302520,-97.6102,44.0219,Miner,ADM2,DISTRICT
SM303129,-78.9438,39.4147,Mineral,ADM2,DISTRICT
SM301774,-118.4352,38.5388,Mineral,ADM2,DISTRICT
SM301646,-114.9985,47.1473,Mineral,ADM2,DISTRICT
SM303353,-106.9241,37.669,Mineral,ADM2,DISTRICT
SM12537,-77.2328,17.9418,Mineral Heights,ADM2,DISTRICT
SM281686,-98.73,20.0744,Mineral de la Reforma,ADM2,DISTRICT
SM281687,-98.7486,20.2197,Mineral del Chico,ADM2,DISTRICT
SM281689,-98.6653,20.1454,Mineral del Monte,ADM2,DISTRICT
SM262523,25.3333,41.9,Mineralni Bani,ADM2,DISTRICT
SM289671,-54.8415,-25.5034,Minga Guazú,ADM2,DISTRICT
SM289655,-54.9167,-24.8667,Minga Porá,ADM2,DISTRICT
SM260031,65.9891,37.0553,Mingajik,ADM2,DISTRICT
SM268583,21.6379,5.1528,Mingala,ADM2,DISTRICT
SM255785,-62.79,50.59,Mingan,ETHNICITY,ETHNICITY
SM238344,11.295,9.2594,Mingang Doso,LANGUAGE,LANGUAGE
SM303541,71.2285,40.7373,Mingbuloq,ADM2,DISTRICT
SM255786,144.89,-5.8,Mingendi,ETHNICITY,ETHNICITY
SM261413,115.5012,-29.1452,Mingenew,ADM2,DISTRICT
SM458483,139.5527,-18.0225,Mingginda,ETHNICITY,ETHNICITY
SM303131,-82.1348,37.7264,Mingo,ADM2,DISTRICT
SM238342,41.8719,42.5129,Mingrelian,LANGUAGE,LANGUAGE
SM255787,42.7,42.05,Mingrelian,ETHNICITY,ETHNICITY
SM261896,47,40.7667,Mingəçevir,ADM1:ADM2,DISTRICT
SM304379,105.8806,17.7776,Minh Hóa,ADM2,DISTRICT
SM304412,108.6772,14.9538,Minh Long,ADM2,DISTRICT
SM238348,102.8441,35.9752,Minhe,LANGUAGE,LANGUAGE
SM238355,-73.1107,-1.5454,Minica Huitoto,LANGUAGE,LANGUAGE
SM238352,142.33,-3.5941,Minidien,LANGUAGE,LANGUAGE
SM300571,-113.6375,42.8544,Minidoka,ADM2,DISTRICT
SM280921,35.9378,34.4653,Minieh-Danieh,ADM2,DISTRICT
SM280921,35.9378,34.4653,Minieh-Danieh,ADM2,DISTRICT
SM280921,35.9378,34.4653,Minieh-Danieh,ADM2,DISTRICT
SM280921,36.0239,34.3992,Minieh-Danieh,ADM2,DISTRICT
SM280921,36.0239,34.3992,Minieh-Danieh,ADM2,DISTRICT
SM280921,36.0239,34.3992,Minieh-Danieh,ADM2,DISTRICT
SM280921,36.1557,34.4906,Minieh-Danieh,ADM2,DISTRICT
SM280921,36.1557,34.4906,Minieh-Danieh,ADM2,DISTRICT
SM280921,36.1557,34.4906,Minieh-Danieh,ADM2,DISTRICT
SM238350,152.005,-4.318,Minigir,LANGUAGE,LANGUAGE
SM15932,-66.1546,18.3601,Minillas Barrio (Bayamón),ADM2,DISTRICT
SM15933,-66.9902,18.0858,Minillas Barrio (San Germán),ADM2,DISTRICT
SM266090,-61.569,-11.1603,Ministro Andreazza,ADM2,DISTRICT
SM459106,146.6342,-36.8102,Minjambuta,ETHNICITY,ETHNICITY
SM284876,8.6125,12.1803,Minjibir,ADM2,DISTRICT
SM458698,142.6017,-28.8731,Minkabari,ETHNICITY,ETHNICITY
SM238361,139.5419,-17.7367,Minkin,LANGUAGE,LANGUAGE
SM302521,-96.7914,43.6742,Minnehaha,ADM2,DISTRICT
SM2250,-94.2506,46.2502,Minnesota,ADM1,DISTRICT
SM279799,136.9012,35.5789,Mino,ADM2,DISTRICT
SM238366,24.9994,35.2072,Minoan,LANGUAGE,LANGUAGE
SM279800,137.0266,35.4839,Minokamo,ADM2,DISTRICT
SM238367,116.759,5.4335,Minokok,LANGUAGE,LANGUAGE
SM279104,135.4793,34.8559,Minoo,ADM2,DISTRICT
SM238369,102.047,5.0111,Minriq,LANGUAGE,LANGUAGE
SM3886,27.5667,53.9,Minsk City,ADM1,DISTRICT
SM3887,28,54,Minsk region (excl. Minsk city),ADM1,DISTRICT
SM9803,27.5833,53.8333,Minski Rayon (excl. Zaslawye and Minsk),ADM2,DISTRICT
SM238370,101.986,4.5938,Mintil,LANGUAGE,LANGUAGE
SM290116,23.9273,47.0732,Mintiu Gherlii,ADM2,DISTRICT
SM255789,-59.16,-31.99,Minuane,ETHNICITY,ETHNICITY
SM255790,117.61,-34.2,Minung,ETHNICITY,ETHNICITY
SM255790,117.61,-34.2,Minung,ETHNICITY,ETHNICITY
SM255790,117.5646,-34.7218,Minung,ETHNICITY,ETHNICITY
SM255790,117.5646,-34.7218,Minung,ETHNICITY,ETHNICITY
SM17004,92,53.75,Minusinskiy Rayon,ADM2,DISTRICT
SM273747,31.3699,30.5082,Minya al-Qamh,ADM2,DISTRICT
SM458199,153.4981,-28.5544,Minyangbal,ETHNICITY,ETHNICITY
SM273644,31.7255,31.1708,Minyat an-Nasr,ADM2,DISTRICT
SM238373,95.0537,28.2632,Minyong,DIALECT,LANGUAGE
SM238376,105.5,23.33,Minz Zhuang,LANGUAGE,LANGUAGE
SM4373,-0.2659,9.4035,Mion,ADM2,DISTRICT
SM291264,24.9427,44.959,Mioveni,ADM2,DISTRICT
SM238378,102.301,25.3,Miqie,LANGUAGE,LANGUAGE
SM2252,-56.3266,46.9561,Miquelon-Langlade,ADM1,DISTRICT
SM282655,-99.7792,23.6025,Miquihuana,ADM2,DISTRICT
SM9562,66.943,33.8253,Mir Amor,ADM2,DISTRICT
SM9563,69.1182,34.7273,Mir Bachah Kot (2005-),ADM2,DISTRICT
SM289485,-8.7385,40.4456,Mira,ADM2,DISTRICT
SM273593,-78.0333,0.55,Mira,ADM2,DISTRICT
SM266697,-50.1304,-19.9724,Mira Estrela,ADM2,DISTRICT
SM274360,38.9113,7.0869,Mirab Arsi,ADM2,DISTRICT
SM274347,37.175,11.12,Mirab Gojjam,ADM2,DISTRICT
SM274347,37.175,11.12,Mirab Gojjam,ADM2,DISTRICT
SM274347,37.3694,11.038,Mirab Gojjam,ADM2,DISTRICT
SM274347,37.3694,11.038,Mirab Gojjam,ADM2,DISTRICT
SM274361,40.8489,8.6725,Mirab Hararghe,ADM2,DISTRICT
SM274361,40.8489,8.6725,Mirab Hararghe,ADM2,DISTRICT
SM274361,40.7574,8.6566,Mirab Hararghe,ADM2,DISTRICT
SM274361,40.7574,8.6566,Mirab Hararghe,ADM2,DISTRICT
SM274362,37.8502,9.1395,Mirab Shewa,ADM2,DISTRICT
SM274363,35.0528,9.1728,Mirab Welega,ADM2,DISTRICT
SM15934,-66.9576,18.2781,Mirabales Barrio,ADM2,DISTRICT
SM263655,-44.1671,-16.2791,Mirabela,ADM2,DISTRICT
SM266698,-47.3658,-24.1751,Miracatu,ADM2,DISTRICT
SM265284,-42.1505,-21.3744,Miracema,ADM2,DISTRICT
SM267260,-48.5835,-9.7686,Miracema do Tocantins,ADM2,DISTRICT
SM15935,-67.1782,18.0907,Miradero Barrio (Cabo Rojo),ADM2,DISTRICT
SM15936,-67.1397,18.225,Miradero Barrio (Mayagüez),ADM2,DISTRICT
SM264582,-52.7535,-23.2128,Mirador,ADM2,DISTRICT
SM262824,-45.1131,-6.4727,Mirador,ADM2,DISTRICT
SM263656,-42.3871,-20.8519,Miradouro,ADM2,DISTRICT
SM270078,-72.0419,1.3098,Miraflores,ADM2,DISTRICT
SM270787,-73.2121,5.1222,Miraflores,ADM2,DISTRICT
SM15938,-66.6472,18.4168,Miraflores Barrio (Arecibo),ADM2,DISTRICT
SM15937,-67.0802,18.2788,Miraflores Barrio (Añasco),ADM2,DISTRICT
SM276089,-73.1255,18.4042,Miragoâne,ADM2,DISTRICT
SM265763,-53.7555,-27.4866,Miraguaí,ADM2,DISTRICT
SM303761,-70.7339,9.4745,Miranda,ADM2,DISTRICT
SM303801,-71.25,10.75,Miranda,ADM2,DISTRICT
SM303600,-67.4293,8.9242,Miranda,ADM2,DISTRICT
SM303878,-68.3942,10.1499,Miranda,ADM2,DISTRICT
SM303616,-70.75,9,Miranda,ADM2,DISTRICT
SM303701,-64.7105,8.8436,Miranda,ADM2,DISTRICT
SM303568,-69.8667,11.3167,Miranda,ADM2,DISTRICT
SM269860,-76.2209,3.2377,Miranda,ADM2,DISTRICT
SM263064,-56.5262,-20.2089,Miranda,ADM2,DISTRICT
SM2253,-66.4167,10.25,Miranda,ADM1,DISTRICT
SM289486,-8.3268,40.1091,Miranda do Corvo,ADM2,DISTRICT
SM289455,-6.3545,41.519,Miranda do Douro,ADM2,DISTRICT
SM262825,-44.5269,-3.5314,Miranda do Norte,ADM2,DISTRICT
SM289456,-7.1919,41.501,Mirandela,ADM2,DISTRICT
SM238379,-6.3837,41.5375,Mirandese,LANGUAGE,LANGUAGE
SM264852,-38.7409,-8.1345,Mirandiba,ADM2,DISTRICT
SM266700,-51.1512,-21.1246,Mirandópolis,ADM2,DISTRICT
SM267540,-40.6706,-10.8036,Mirangaba,ADM2,DISTRICT
SM267261,-48.666,-9.3911,Miranorte,ADM2,DISTRICT
SM267541,-40.7831,-14.1861,Mirante,ADM2,DISTRICT
SM266091,-62.8615,-11.1371,Mirante da Serra,ADM2,DISTRICT
SM266701,-51.9713,-22.3511,Mirante do Paranapanema,ADM2,DISTRICT
SM264583,-51.4704,-22.9766,Miraselva,ADM2,DISTRICT
SM289811,23.6933,46.3866,Miraslau,ADM2,DISTRICT
SM15939,-66.8504,18.2442,Mirasol Barrio,ADM2,DISTRICT
SM266703,-49.5088,-20.8416,Mirassol,ADM2,DISTRICT
SM263184,-58.0051,-15.6352,Mirassol d'Oeste,ADM2,DISTRICT
SM266702,-49.4843,-20.5929,Mirassolândia,ADM2,DISTRICT
SM263659,-44.4206,-14.7529,Miravânia,ADM2,DISTRICT
SM263658,-42.6008,-21.1458,Miraí,ADM2,DISTRICT
SM267795,-39.9039,-3.5651,Miraíma,ADM2,DISTRICT
SM9744,47,40.3333,Mirbasir Rayonu,ADM2,DISTRICT
SM292683,27.3826,45.1289,Mircea Voda,ADM2,DISTRICT
SM260502,19.9902,41.8095,Mirditës,ADM2,DISTRICT
SM260502,19.9902,41.8095,Mirditës,ADM2,DISTRICT
SM260502,20,41.8333,Mirditës,ADM2,DISTRICT
SM260502,20,41.8333,Mirditës,ADM2,DISTRICT
SM238384,16.9932,9.6913,Mire,LANGUAGE,LANGUAGE
SM276074,-72.1469,18.8812,Mirebalais,ADM2,DISTRICT
SM296257,13.6393,45.8514,Miren-Kostanjevica,ADM1:ADM2,DISTRICT
SM291013,23.3642,47.5001,Miresu Mare,ADM2,DISTRICT
SM238385,81.336,23.4306,Mirgan,LANGUAGE,LANGUAGE
SM284362,114.5,3.75,Miri,ADM2,DISTRICT
SM266210,-50.1887,-27.1732,Mirim doce,ADM2,DISTRICT
SM255794,127.59,-31.87,Miring,ETHNICITY,ETHNICITY
SM255794,127.59,-31.87,Miring,ETHNICITY,ETHNICITY
SM255794,127.59,-31.87,Miring,ETHNICITY,ETHNICITY
SM255794,117.81,-34.95,Miring,ETHNICITY,ETHNICITY
SM255794,117.81,-34.95,Miring,ETHNICITY,ETHNICITY
SM255794,117.81,-34.95,Miring,ETHNICITY,ETHNICITY
SM255794,128.1535,-31.293,Miring,ETHNICITY,ETHNICITY
SM255794,128.1535,-31.293,Miring,ETHNICITY,ETHNICITY
SM255794,128.1535,-31.293,Miring,ETHNICITY,ETHNICITY
SM262827,-44.774,-2.0477,Mirinzal,ADM2,DISTRICT
SM18412,65.0232,38.818,Mirishkar,ADM2,DISTRICT
SM18413,64.9162,38.9007,Mirishkor Tumani,ADM2,DISTRICT
SM238390,-69.7432,0.589,Miriti,LANGUAGE,LANGUAGE
SM10427,-70.7893,-0.8186,Miriti - Parana (1996-),ADM2,DISTRICT
SM255795,128.1,-16.04,Miriwong,ETHNICITY,ETHNICITY
SM255795,128.1,-16.04,Miriwong,ETHNICITY,ETHNICITY
SM255795,128.8677,-15.8863,Miriwong,ETHNICITY,ETHNICITY
SM255795,128.8677,-15.8863,Miriwong,ETHNICITY,ETHNICITY
SM238393,128.7386,-15.7736,Miriwoong Sign Language,LANGUAGE,LANGUAGE
SM238386,128.889,-16.3596,Miriwung,LANGUAGE,LANGUAGE
SM11788,61.4505,29.0175,Mirjaveh,ADM2,DISTRICT
SM262423,23.9667,42.6667,Mirkovo,ADM2,DISTRICT
SM4134,15.0627,45.9555,Mirna,ADM1,DISTRICT
SM296272,15.0843,45.8534,Mirna Pec,ADM1:ADM2,DISTRICT
SM17005,113.8833,62.5,Mirninskiy Rayon,ADM2,DISTRICT
SM18414,69.3028,41.2883,Mirobod Tumani,ADM2,DISTRICT
SM290843,27.4252,46.9987,Mironeasa,ADM2,DISTRICT
SM286648,-81.8306,8.4047,Mironó,ADM2,DISTRICT
SM291265,24.9517,44.4032,Mirosi,ADM2,DISTRICT
SM290844,27.5104,47.1305,Miroslava,ADM2,DISTRICT
SM375497,69.0031,25.5508,Mirphurkhas,ADM2:ADM3,DISTRICT
SM375369,73.7514,33.2156,Mirpur,ADM2:ADM3,DISTRICT
SM238398,74.3713,33.5582,Mirpur Panjabi,DIALECT,LANGUAGE
SM15250,9.15,13.7072,Mirriah Department,ADM2,DISTRICT
SM291620,23.1431,47.2313,Mirsid,ADM2,DISTRICT
SM280547,128.7869,35.4975,Miryang,ADM2,DISTRICT
SM303495,68.0833,40.6667,Mirzacho'l,ADM2,DISTRICT
SM18415,68.5833,40.5,Mirzaobod Tumani (pre-2004),ADM2,DISTRICT
SM277319,82.6089,25.1146,Mirzapur,ADM2,DISTRICT
SM18416,69.3506,41.3333,Mirzo Ulugbek Tumani,ADM2,DISTRICT
SM2254,123.7,8.3333,Misamis Occidental,ADM1:ADM2,DISTRICT
SM2255,125,8.75,Misamis Oriental,ADM1:ADM2,DISTRICT
SM282742,-96.8925,19.982,Misantla,ADM2,DISTRICT
SM278634,130.8726,32.6189,Misato,ADM2,DISTRICT
SM278564,140.5959,39.427,Misato,ADM2,DISTRICT
SM279183,139.8719,35.8322,Misato City,ADM2,DISTRICT
SM285315,10.4703,11.3163,Misau,ADM2,DISTRICT
SM278971,141.3812,40.7465,Misawa,ADM2,DISTRICT
SM290515,21.6535,46.6202,Misca,ADM2,DISTRICT
SM290385,23.8624,44.4209,Mischii,ADM2,DISTRICT
SM262058,30.7268,-3.0676,Mishiha,ADM2,DISTRICT
SM279289,138.9488,35.1394,Mishima,ADM2,DISTRICT
SM238404,9.3375,9.2048,Miship,LANGUAGE,LANGUAGE
SM293472,63.9167,55.25,Mishkinskiy rayon,ADM2,DISTRICT
SM294277,56,55.5833,Mishkinskiy rayon,ADM2,DISTRICT
SM255800,88.08,26.96,Mishmi,ETHNICITY,ETHNICITY
SM238406,152.696,-10.6821,Misima-Paneati,LANGUAGE,LANGUAGE
SM238405,94.69,27.418,Mising-Padam-Miri-Minyong,LANGUAGE,LANGUAGE
SM2257,-57,-27,Misiones,ADM1,DISTRICT
SM2256,-54.3439,-26.8143,Misiones,ADM1,DISTRICT
SM276244,20.7735,48.0868,Miskolci,ADM1:ADM2,DISTRICT
SM296281,15.2239,46.4511,Mislinja,ADM1:ADM2,DISTRICT
SM237839,130.909,-1.2233,Misool-Salawati Ma'ya,LANGUAGE,LANGUAGE
SM255804,-83.44,14.5,Misquito,ETHNICITY,ETHNICITY
SM255804,-83.44,14.5,Misquito,ETHNICITY,ETHNICITY
SM255804,-83.44,14.5,Misquito,ETHNICITY,ETHNICITY
SM255804,-83,15,Misquito,ETHNICITY,ETHNICITY
SM255804,-83,15,Misquito,ETHNICITY,ETHNICITY
SM255804,-83,15,Misquito,ETHNICITY,ETHNICITY
SM255804,-85,13,Misquito,ETHNICITY,ETHNICITY
SM255804,-85,13,Misquito,ETHNICITY,ETHNICITY
SM255804,-85,13,Misquito,ETHNICITY,ETHNICITY
SM274348,38.0227,10.474,Misraq Gojjam,ADM2,DISTRICT
SM274348,38.0227,10.474,Misraq Gojjam,ADM2,DISTRICT
SM274348,37.8528,10.5291,Misraq Gojjam,ADM2,DISTRICT
SM274348,37.8528,10.5291,Misraq Gojjam,ADM2,DISTRICT
SM274364,41.9859,8.8965,Misraq Harerge,ADM2,DISTRICT
SM274364,41.9859,8.8965,Misraq Harerge,ADM2,DISTRICT
SM274364,42.0024,8.7011,Misraq Harerge,ADM2,DISTRICT
SM274364,42.0024,8.7011,Misraq Harerge,ADM2,DISTRICT
SM274365,38.8481,8.2135,Misraq Shewa,ADM2,DISTRICT
SM274365,38.8481,8.2135,Misraq Shewa,ADM2,DISTRICT
SM274365,38.6667,8,Misraq Shewa,ADM2,DISTRICT
SM274365,38.6667,8,Misraq Shewa,ADM2,DISTRICT
SM274366,36.7576,9.5193,Misraq Wellega,ADM2,DISTRICT
SM274332,39.587,14.1154,Misraqawi,ADM2,DISTRICT
SM2258,15.0732,32.3104,Misratah,ADM1,DISTRICT
SM264584,-54.2579,-25.1112,Missal,ADM2,DISTRICT
SM301308,-85.0947,44.3373,Missaukee,ADM2,DISTRICT
SM299232,31.438,-1.1322,Missenyi,ADM2,DISTRICT
SM301586,-89.2912,36.8281,Mississippi,ADM2,DISTRICT
SM302393,-90.0542,35.7639,Mississippi,ADM2,DISTRICT
SM2259,-89.7504,32.7504,Mississippi,ADM1,DISTRICT
SM267796,-39.112,-7.2371,Misso Velha,ADM2,DISTRICT
SM301647,-113.9237,47.0365,Missoula,ADM2,DISTRICT
SM2260,-92.5005,38.2503,Missouri,ADM1,DISTRICT
SM261807,16.5473,48.5944,Mistelbach,ADM2,DISTRICT
SM255806,-72,52,Mistissini Cree,ETHNICITY,ETHNICITY
SM255806,-72,52,Mistissini Cree,ETHNICITY,ETHNICITY
SM255806,-72.66,51.75,Mistissini Cree,ETHNICITY,ETHNICITY
SM255806,-72.66,51.75,Mistissini Cree,ETHNICITY,ETHNICITY
SM270438,-75.9474,5.4182,Mistrato,ADM2,DISTRICT
SM299132,33.0833,-2.85,Misungwi,ADM2,DISTRICT
SM273645,31.3233,30.7125,Mit Ghamr,ADM2,DISTRICT
SM11152,31.3097,30.9201,Mit Yazid,ADM2,DISTRICT
SM279453,139.5596,35.6835,Mitaka,ADM2,DISTRICT
SM279801,137.1545,35.4335,Mitake,ADM2,DISTRICT
SM261710,138.6222,-35.0069,Mitcham,ADM2,DISTRICT
SM302715,-100.9212,32.3062,Mitchell,ADM2,DISTRICT
SM301988,-82.1635,36.0133,Mitchell,ADM2,DISTRICT
SM300947,-98.2094,39.3933,Mitchell,ADM2,DISTRICT
SM300846,-92.789,43.3564,Mitchell,ADM2,DISTRICT
SM300383,-84.1943,31.2253,Mitchell,ADM2,DISTRICT
SM261257,144.98,-37.1754,Mitchell,ADM2,DISTRICT
SM12538,-77.2053,17.8128,Mitchell Town,ADM2,DISTRICT
SM18149,22.6472,-14.1004,Mitete District,ADM2,DISTRICT
SM238418,139.5985,-25.1361,Mithaka,LANGUAGE,LANGUAGE
SM458679,139.7383,-25.273,Mithaka,ETHNICITY,ETHNICITY
SM375498,70.18,24.8209,Mithi,ADM2:ADM3,DISTRICT
SM4238,-157.709,-19.8686,Mitiaro,ADM1,DISTRICT
SM238422,-96.2943,16.8685,Mitla Zapotec,LANGUAGE,LANGUAGE
SM238421,-97.1757,17.16,Mitlatongo Mixtec,LANGUAGE,LANGUAGE
SM278383,140.4356,36.3705,Mito,ADM2,DISTRICT
SM292583,27.0042,48.1129,Mitoc,ADM2,DISTRICT
SM291817,26.2399,47.7348,Mitocu Dragomirnei,ADM2,DISTRICT
SM283166,-92.5713,16.8754,Mitontic,ADM2,DISTRICT
SM278478,133.7228,34.1618,Mitoyo,ADM2,DISTRICT
SM261043,-62.8333,-29.5,Mitre,ADM2,DISTRICT
SM289961,26.6145,44.1634,Mitreni,ADM2,DISTRICT
SM13361,20.8649,42.895,Mitrovica Veriore,ADM2,DISTRICT
SM10916,33.1213,35.0385,Mitsero,ADM2,DISTRICT
SM370997,45.9273,-16.0848,Mitsinjo,ADM2:ADM3,DISTRICT
SM278935,138.9371,37.5247,Mitsuke,ADM2,DISTRICT
SM273991,39.4453,15.6092,Mitswae City,ADM2,DISTRICT
SM280365,103.5311,10.6248,Mittakpheap,ADM2,DISTRICT
SM238423,29.0002,7.4562,Mittu,LANGUAGE,LANGUAGE
SM250516,29.54,5.15,Mittu,ETHNICITY,ETHNICITY
SM238426,25.104,-1.5703,Mituku,LANGUAGE,LANGUAGE
SM4732,32.067,0.4321,Mityana District,ADM1:ADM2,DISTRICT
SM270648,-70.4064,0.9724,Mitú,ADM2,DISTRICT
SM238430,149.557,-5.8804,Miu,LANGUAGE,LANGUAGE
SM278536,139.641,35.1675,Miura,ADM2,DISTRICT
SM238431,126.921,-14.3484,Miwa,LANGUAGE,LANGUAGE
SM255813,127.41,-14.25,Miwa,ETHNICITY,ETHNICITY
SM255813,127.41,-14.25,Miwa,ETHNICITY,ETHNICITY
SM255813,127.0418,-14.1273,Miwa,ETHNICITY,ETHNICITY
SM255813,127.0418,-14.1273,Miwa,ETHNICITY,ETHNICITY
SM255814,-120.29,38.39,Miwok (Central),ETHNICITY,ETHNICITY
SM255814,-120.29,38.39,Miwok (Central),ETHNICITY,ETHNICITY
SM255814,-120.29,38.39,Miwok (Central),ETHNICITY,ETHNICITY
SM255814,-120,38,Miwok (Central),ETHNICITY,ETHNICITY
SM255814,-120,38,Miwok (Central),ETHNICITY,ETHNICITY
SM255814,-120,38,Miwok (Central),ETHNICITY,ETHNICITY
SM255814,-119.77,38,Miwok (Central),ETHNICITY,ETHNICITY
SM255814,-119.77,38,Miwok (Central),ETHNICITY,ETHNICITY
SM255814,-119.77,38,Miwok (Central),ETHNICITY,ETHNICITY
SM255815,-122.71,38.25,Miwok Coastal,ETHNICITY,ETHNICITY
SM255815,-122.71,38.25,Miwok Coastal,ETHNICITY,ETHNICITY
SM255815,-122.88,38.24,Miwok Coastal,ETHNICITY,ETHNICITY
SM255815,-122.88,38.24,Miwok Coastal,ETHNICITY,ETHNICITY
SM255816,-123,38,Miwok Lake,ETHNICITY,ETHNICITY
SM255816,-123,38,Miwok Lake,ETHNICITY,ETHNICITY
SM255816,-123,38,Miwok Lake,ETHNICITY,ETHNICITY
SM255816,-123.2,38.75,Miwok Lake,ETHNICITY,ETHNICITY
SM255816,-123.2,38.75,Miwok Lake,ETHNICITY,ETHNICITY
SM255816,-123.2,38.75,Miwok Lake,ETHNICITY,ETHNICITY
SM255816,-122.48,38.79,Miwok Lake,ETHNICITY,ETHNICITY
SM255816,-122.48,38.79,Miwok Lake,ETHNICITY,ETHNICITY
SM255816,-122.48,38.79,Miwok Lake,ETHNICITY,ETHNICITY
SM255817,-121,38.5,Miwok Northern,ETHNICITY,ETHNICITY
SM255818,-119.5,37.5,Miwok Southern,ETHNICITY,ETHNICITY
SM275470,-90.5942,14.6391,Mixco,ADM2,DISTRICT
SM255819,-94.18,16.97,Mixe,ETHNICITY,ETHNICITY
SM255819,-94.18,16.97,Mixe,ETHNICITY,ETHNICITY
SM255819,-94.18,16.97,Mixe,ETHNICITY,ETHNICITY
SM255819,-89.3,13.84,Mixe,ETHNICITY,ETHNICITY
SM255819,-89.3,13.84,Mixe,ETHNICITY,ETHNICITY
SM255819,-89.3,13.84,Mixe,ETHNICITY,ETHNICITY
SM255819,-95,17,Mixe,ETHNICITY,ETHNICITY
SM255819,-95,17,Mixe,ETHNICITY,ETHNICITY
SM255819,-95,17,Mixe,ETHNICITY,ETHNICITY
SM255819,-96.5833,16.95,Mixe,ETHNICITY,ETHNICITY
SM255819,-96.5833,16.95,Mixe,ETHNICITY,ETHNICITY
SM255819,-96.5833,16.95,Mixe,ETHNICITY,ETHNICITY
SM238438,92.74,11.67,Mixed Great Andamanese,DIALECT,LANGUAGE
SM238439,-14.385,10.6822,Mixifore,LANGUAGE,LANGUAGE
SM14349,-96.1067,17.1467,Mixistlan de la Reforma,ADM2,DISTRICT
SM281690,-99.1833,20.2219,Mixquiahuala de Juárez,ADM2,DISTRICT
SM255822,-98.33,17.27,Mixtec,ETHNICITY,ETHNICITY
SM255822,-98.33,17.27,Mixtec,ETHNICITY,ETHNICITY
SM255822,-98.33,17.27,Mixtec,ETHNICITY,ETHNICITY
SM255822,-98,18,Mixtec,ETHNICITY,ETHNICITY
SM255822,-98,18,Mixtec,ETHNICITY,ETHNICITY
SM255822,-98,18,Mixtec,ETHNICITY,ETHNICITY
SM255822,-97.3667,15.95,Mixtec,ETHNICITY,ETHNICITY
SM255822,-97.3667,15.95,Mixtec,ETHNICITY,ETHNICITY
SM255822,-97.3667,15.95,Mixtec,ETHNICITY,ETHNICITY
SM238443,-97.8791,17.3317,Mixtepec Mixtec,LANGUAGE,LANGUAGE
SM238444,-96.2372,16.1724,Mixtepec Zapotec,LANGUAGE,LANGUAGE
SM282444,-97.8979,18.9115,Mixtla,ADM2,DISTRICT
SM282743,-96.9767,18.591,Mixtla de Altamirano,ADM2,DISTRICT
SM281828,-104.4444,20.4837,Mixtlán,ADM2,DISTRICT
SM238451,9.7151,10.9234,Miya,LANGUAGE,LANGUAGE
SM2261,140.9262,38.4494,Miyagi,ADM1:ADMX,DISTRICT
SM13017,141.0361,38.364,Miyagi Gun,ADM2,DISTRICT
SM255825,125.32,24.79,Miyakans,ETHNICITY,ETHNICITY
SM13018,139.5154,34.0754,Miyake Shicho,ADM2,DISTRICT
SM13019,130.4571,33.353,Miyaki-gun,ADM2,DISTRICT
SM294279,54.8333,53.5833,Miyakinskiy rayon,ADM2,DISTRICT
SM238449,125.363,24.7564,Miyako,LANGUAGE,LANGUAGE
SM13020,130.9429,33.6623,Miyako-gun,ADM2,DISTRICT
SM13021,141.8514,39.6572,Miyako-shi (2010-),ADM2,DISTRICT
SM279059,125.3013,24.7857,Miyakojima,ADM2,DISTRICT
SM278764,131.079,31.8092,Miyakonojō,ADM2,DISTRICT
SM238453,133.0749,34.1782,Miyakubo Sign Language,LANGUAGE,LANGUAGE
SM279688,130.4954,33.1237,Miyama,ADM2,DISTRICT
SM255826,79.35,26.18,Miyana,ETHNICITY,ETHNICITY
SM279184,139.7255,36.0187,Miyashiro,ADM2,DISTRICT
SM279689,130.6151,33.7156,Miyawaka,ADM2,DISTRICT
SM2262,131.3,32.2,Miyazaki,ADM1:ADMX,DISTRICT
SM13022,131.3846,31.9619,Miyazaki-shi,ADM2,DISTRICT
SM278674,135.1766,35.556,Miyazu,ADM2,DISTRICT
SM238455,1.333,9.9437,Miyobe,LANGUAGE,LANGUAGE
SM278130,132.8644,34.792,Miyoshi,ADM2,DISTRICT
SM278048,137.0887,35.0948,Miyoshi,ADM2,DISTRICT
SM13023,133.8518,33.9335,Miyoshi District,ADM2,DISTRICT
SM260237,66.5063,32.1789,Mizan,ADM2,DISTRICT
SM299762,23.4994,48.5253,Mizhhirs'kyi,ADM2,DISTRICT
SM291546,26.4441,45.0084,Mizil,ADM2,DISTRICT
SM262494,23.85,43.6333,Miziya,ADM2,DISTRICT
SM236816,92.6247,22.6138,Mizo,LANGUAGE,LANGUAGE
SM255828,91.2,23.7,Mizo,ETHNICITY,ETHNICITY
SM2263,93,23,Mizoram,ADM1,DISTRICT
SM262697,-65.3944,-17.9066,Mizque,ADM2,DISTRICT
SM279802,136.6702,35.3966,Mizuho,ADM2,DISTRICT
SM279803,137.2736,35.3894,Mizunami,ADM2,DISTRICT
SM288795,16.0574,52.6019,Międzychód,ADM2,DISTRICT
SM288923,15.5828,52.4989,Międzyrzecz,ADM2,DISTRICT
SM288954,21.6089,52.1843,Mińsk,ADM2,DISTRICT
SM299215,39.2035,-6.1656,Mjini,ADM2,DISTRICT
SM296424,15.1385,58.3458,Mjölby,ADM2,DISTRICT
SM299183,34.7167,-4.2137,Mkalama,ADM2,DISTRICT
SM296702,31.6046,-26.2411,Mkhiweni,ADM2,DISTRICT
SM299200,38.9344,-4.7351,Mkinga,ADM2,DISTRICT
SM299145,39.718,-5.373,Mkoani,ADM2,DISTRICT
SM285094,7.7486,4.7336,Mkpat Enin,ADM2,DISTRICT
SM299154,39.2,-7.27,Mkuranga,ADM2,DISTRICT
SM18150,29.4975,-13.6996,Mkushi District (2012-),ADM2,DISTRICT
SM238464,101.163,18.5533,Mlabri,LANGUAGE,LANGUAGE
SM255830,100.47,18.41,Mlabri,ETHNICITY,ETHNICITY
SM271192,14.9167,50.4167,Mladá Boleslav,ADM2,DISTRICT
SM238465,41.2209,37.0311,Mlahsô,LANGUAGE,LANGUAGE
SM238467,140.264,-2.5732,Mlap,LANGUAGE,LANGUAGE
SM299237,31.7646,-6.5903,Mlele,ADM2,DISTRICT
SM305635,17.5364,42.7444,Mljet,ADM2,DISTRICT
SM238469,-16.5383,12.8318,Mlomp,LANGUAGE,LANGUAGE
SM238470,11.0599,4.5219,Mmaala,LANGUAGE,LANGUAGE
SM238472,10.2541,6.4321,Mmen,LANGUAGE,LANGUAGE
SM297858,10.1122,36.8662,Mnihla,ADM2,DISTRICT
SM255831,106.79,13.17,Mnong,ETHNICITY,ETHNICITY
SM255831,106.79,13.17,Mnong,ETHNICITY,ETHNICITY
SM255831,107,12,Mnong,ETHNICITY,ETHNICITY
SM255831,107,12,Mnong,ETHNICITY,ETHNICITY
SM238494,29.7355,6.1463,Mo'da,LANGUAGE,LANGUAGE
SM303510,59.6707,43.8258,Mo'ynoq,ADM2,DISTRICT
SM271126,-74.9482,20.6578,Moa,ADM2,DISTRICT
SM255832,-105,37,Moache,ETHNICITY,ETHNICITY
SM283931,32.2432,-25.6093,Moamba,ADM2,DISTRICT
SM255833,144.82,-4.62,Moando,ETHNICITY,ETHNICITY
SM255834,-112,39,Moanunts,ETHNICITY,ETHNICITY
SM255835,-115,36,Moapa,ETHNICITY,ETHNICITY
SM291818,26.1957,47.5804,Moara Nica,ADM2,DISTRICT
SM290965,26.1894,44.6342,Moara Vlasiei,ADM2,DISTRICT
SM15072,34.0025,-15.9732,Moatize (excl. Tete City),ADM2,DISTRICT
SM238482,0.1596,10.9496,Moba,LANGUAGE,LANGUAGE
SM284711,5.1319,8.001,Moba,ADM2,DISTRICT
SM279365,140.3016,35.4498,Mobara,ADM2,DISTRICT
SM277693,51.4833,32.2667,Mobarakeh,ADM2,DISTRICT
SM10243,21.2248,4.4787,Mobaye,ADM2,DISTRICT
SM285377,12.6966,13.0917,Mobbar,ADM2,DISTRICT
SM300289,-88.1975,30.6852,Mobile,ADM2,DISTRICT
SM255836,-87.3,30.95,Mobile,ETHNICITY,ETHNICITY
SM238483,-91.75,30.47,Mobilian,LANGUAGE,LANGUAGE
SM238485,-4.473,5.2685,Mobumrin Aizi,LANGUAGE,LANGUAGE
SM238486,96.6027,19.0443,Mobwa Karen,LANGUAGE,LANGUAGE
SM271909,-70.5,19.5,Moca,ADM2,DISTRICT
SM2264,-67.0844,18.3855,Moca,ADM1,DISTRICT
SM15940,-67.1137,18.3929,Moca Barrio-Pueblo,ADM2,DISTRICT
SM273485,-79.5444,-1.1868,Mocache,ADM2,DISTRICT
SM264193,-49.4362,-2.5617,Mocajuba,ADM2,DISTRICT
SM14947,153.5385,5.4918,Moch Municipality,ADM2,DISTRICT
SM17519,-61.025,13.8312,Mocha,ADM2,DISTRICT
SM273569,-78.6923,-1.4155,Mocha,ADM2,DISTRICT
SM238488,37.3357,-3.3374,Mochi,LANGUAGE,LANGUAGE
SM238491,-79,-8.0167,Mochica,LANGUAGE,LANGUAGE
SM255839,-79.4,-7.09,Mochica,ETHNICITY,ETHNICITY
SM281620,-99.3673,17.3835,Mochitlán,ADM2,DISTRICT
SM238489,-92.1734,15.4538,Mocho,LANGUAGE,LANGUAGE
SM12539,-77.8258,18.2914,Mocho,ADM2,DISTRICT
SM283861,40.1652,-11.434,Mocimboa da Praia,ADM2,DISTRICT
SM290117,24.0285,46.7923,Mociu,ADM2,DISTRICT
SM266704,-47.0131,-21.4425,Mococa,ADM2,DISTRICT
SM282985,-89.454,21.111,Mocochá,ADM2,DISTRICT
SM255840,-82.03,27.27,Mococo,ETHNICITY,ETHNICITY
SM282538,-107.7744,25.3219,Mocorito,ADM2,DISTRICT
SM255841,-60.51,-26.93,Mocovi,ETHNICITY,ETHNICITY
SM255841,-60.51,-26.93,Mocovi,ETHNICITY,ETHNICITY
SM255841,-60,-27.5,Mocovi,ETHNICITY,ETHNICITY
SM255841,-60,-27.5,Mocovi,ETHNICITY,ETHNICITY
SM238493,-60.4145,-28.097,Mocoví,LANGUAGE,LANGUAGE
SM282581,-109.6753,29.7591,Moctezuma,ADM2,DISTRICT
SM282488,-101.0758,22.6926,Moctezuma,ADM2,DISTRICT
SM283877,36.9871,-16.8368,Mocuba,ADM2,DISTRICT
SM286387,5.8079,60.8174,Modalen,ADM2,DISTRICT
SM238496,116.573,0.8079,Modang,LANGUAGE,LANGUAGE
SM255844,42.74,38.4,Modeki,ETHNICITY,ETHNICITY
SM266211,-53.0504,-26.7729,Modelo,ADM2,DISTRICT
SM289962,27.3896,44.1966,Modelu,ADM2,DISTRICT
SM277950,10.9,44.5,Modena,ADM2,DISTRICT
SM238498,23.13,38.36,Modern Greek,LANGUAGE,LANGUAGE
SM232254,35.0179,31.1056,Modern Hebrew,LANGUAGE,LANGUAGE
SM241815,44.61,42.98,Modern Ossetic,FAMILY:LANGUAGE,LANGUAGE
SM262762,-65.6508,-21.9354,Modesto Omiste,ADM2,DISTRICT
SM303180,-120.725,41.5899,Modoc,ADM2,DISTRICT
SM255850,-121.23,41.17,Modoc,ETHNICITY,ETHNICITY
SM255850,-121.23,41.17,Modoc,ETHNICITY,ETHNICITY
SM255850,-121.23,41.17,Modoc,ETHNICITY,ETHNICITY
SM255850,-121.23,41.17,Modoc,ETHNICITY,ETHNICITY
SM255850,-122,42,Modoc,ETHNICITY,ETHNICITY
SM255850,-122,42,Modoc,ETHNICITY,ETHNICITY
SM255850,-122,42,Modoc,ETHNICITY,ETHNICITY
SM255850,-122,42,Modoc,ETHNICITY,ETHNICITY
SM255850,-121.31,42,Modoc,ETHNICITY,ETHNICITY
SM255850,-121.31,42,Modoc,ETHNICITY,ETHNICITY
SM255850,-121.31,42,Modoc,ETHNICITY,ETHNICITY
SM255850,-121.31,42,Modoc,ETHNICITY,ETHNICITY
SM255850,-121.7,42,Modoc,ETHNICITY,ETHNICITY
SM255850,-121.7,42,Modoc,ETHNICITY,ETHNICITY
SM255850,-121.7,42,Modoc,ETHNICITY,ETHNICITY
SM255850,-121.7,42,Modoc,ETHNICITY,ETHNICITY
SM238514,127.752,1.402,Modole,LANGUAGE,LANGUAGE
SM313209,18.3036,44.9544,Modriča,ADM2:ADM3,DISTRICT
SM313209,18.3036,44.9544,Modriča,ADM2:ADM3,DISTRICT
SM313209,18.2528,44.9158,Modriča,ADM2:ADM3,DISTRICT
SM313209,18.2528,44.9158,Modriča,ADM2:ADM3,DISTRICT
SM286319,10.0047,59.9735,Modum,ADM2,DISTRICT
SM263660,-44.0166,-20.3327,Moeda,ADM2,DISTRICT
SM297482,104.1114,16.3716,Moei Wadi,ADM2,DISTRICT
SM263661,-45.4212,-19.8429,Moema,ADM2,DISTRICT
SM296075,-54.3981,5.6105,Moengo,ADM2,DISTRICT
SM296076,-54.2502,5.5728,Moengotapoe,ADM2,DISTRICT
SM255851,28.78,-7.07,Moer,ETHNICITY,ETHNICITY
SM285937,4.5638,51.6393,Moerdijk,ADM2,DISTRICT
SM238516,145.458,-4.6225,Moere,LANGUAGE,LANGUAGE
SM303354,-108.2073,40.6184,Moffat,ADM2,DISTRICT
SM291673,22.6,47.6833,Moftinu Mic,ADM2,DISTRICT
SM238519,14.0314,10.567,Mofu-Gudur,LANGUAGE,LANGUAGE
SM238522,14.5,11,Mofu-Gudur Sign Language,LANGUAGE,LANGUAGE
SM277146,75.1494,30.8038,Moga,ADM2,DISTRICT
SM289457,-6.7193,41.3419,Mogadouro,ADM2,DISTRICT
SM13024,140.1556,38.7803,Mogami-gun,ADM2,DISTRICT
SM292210,23.8858,45.124,Mogesti,ADM2,DISTRICT
SM255855,63,34,Moghol,ETHNICITY,ETHNICITY
SM238527,62.2802,34.2504,Mogholi,LANGUAGE,LANGUAGE
SM266706,-47.0098,-22.2499,Mogi Guaçu,ADM2,DISTRICT
SM266707,-46.9897,-22.4498,Mogi Mirim,ADM2,DISTRICT
SM266705,-46.1877,-23.5681,Mogi das Cruzes,ADM2,DISTRICT
SM2265,21.4356,41.1581,Mogila,ADM1,DISTRICT
SM288846,18.0742,52.6616,Mogilno,ADM2,DISTRICT
SM295089,120.3333,53.6667,Mogochinskiy rayon,ADM2,DISTRICT
SM283765,102.9812,48.271,Mogod,ADM2,DISTRICT
SM273999,37.5842,15.267,Mogolo,ADM2,DISTRICT
SM289812,23.3187,46.2836,Mogos,ADM2,DISTRICT
SM290283,25.3877,44.6832,Mogosani,ADM2,DISTRICT
SM290846,27.4849,47.042,Mogosesti,ADM2,DISTRICT
SM290847,26.771,47.1319,Mogosesti-siret,ADM2,DISTRICT
SM290966,26.0018,44.529,Mogosoaia,ADM2,DISTRICT
SM270489,-72.9594,6.509,Mogotes,ADM2,DISTRICT
SM283949,39.2597,-15.7376,Mogovolas,ADM2,DISTRICT
SM238530,18.6082,11.5199,Mogum,LANGUAGE,LANGUAGE
SM11581,18.68,46.02,Mohacsi Jaras,ADM2,DISTRICT
SM2266,27.8,-30.0667,Mohale's Hoek,ADM1,DISTRICT
SM457209,-0.0833,35.5833,Mohammadia,ADM2,DISTRICT
SM281449,-7.4,33.69,Mohammadia (excl. Ain Harrouda and Ahl Laghlam),ADM2,DISTRICT
SM238534,-114.599,34.8866,Mohave,LANGUAGE,LANGUAGE
SM301802,-113.7579,35.704,Mohave,ADM2,DISTRICT
SM255860,-114.88,35.19,Mohave,ETHNICITY,ETHNICITY
SM255860,-114.88,35.19,Mohave,ETHNICITY,ETHNICITY
SM255860,-114.88,35.19,Mohave,ETHNICITY,ETHNICITY
SM255860,-114.88,35.19,Mohave,ETHNICITY,ETHNICITY
SM255860,-114.4165,34.625,Mohave,ETHNICITY,ETHNICITY
SM255860,-114.4165,34.625,Mohave,ETHNICITY,ETHNICITY
SM255860,-114.4165,34.625,Mohave,ETHNICITY,ETHNICITY
SM255860,-114.4165,34.625,Mohave,ETHNICITY,ETHNICITY
SM255860,-114,35,Mohave,ETHNICITY,ETHNICITY
SM255860,-114,35,Mohave,ETHNICITY,ETHNICITY
SM255860,-114,35,Mohave,ETHNICITY,ETHNICITY
SM255860,-114,35,Mohave,ETHNICITY,ETHNICITY
SM255860,-114.5,34.5,Mohave,ETHNICITY,ETHNICITY
SM255860,-114.5,34.5,Mohave,ETHNICITY,ETHNICITY
SM255860,-114.5,34.5,Mohave,ETHNICITY,ETHNICITY
SM255860,-114.5,34.5,Mohave,ETHNICITY,ETHNICITY
SM238536,-74.6684,43.72,Mohawk,LANGUAGE,LANGUAGE
SM255861,-73.73,43.6,Mohawk,ETHNICITY,ETHNICITY
SM238537,-72.1701,41.8581,Mohegan-Montauk-Narragansett,LANGUAGE,LANGUAGE
SM237023,-74.266,43.903,Mohican,LANGUAGE,LANGUAGE
SM255863,-73.96,43.06,Mohican,ETHNICITY,ETHNICITY
SM375406,71.3512,34.4639,Mohmand,ADM2:ADM3,DISTRICT
SM15097,96.5977,25.1951,Mohnyin District,ADM2,DISTRICT
SM286834,-69.3803,-15.3351,Moho,ADM2,DISTRICT
SM11789,52.7667,27.6167,Mohr,ADM2,DISTRICT
SM11451,27.8,48.4419,Mohyliv-Podilskyy Raion (2020-),ADM2,DISTRICT
SM238543,16.4556,-1.7241,Moi (Congo),LANGUAGE,LANGUAGE
SM238544,131.52,-0.9653,Moi (Indonesia),LANGUAGE,LANGUAGE
SM292638,25.3096,45.4885,Moieciu De Jos,ADM2,DISTRICT
SM238547,148.312,-9.6023,Moikodi,LANGUAGE,LANGUAGE
SM289416,-7.6296,40.9713,Moimenta da Beira,ADM2,DISTRICT
SM284593,165.6804,-21.6986,Moindou,ADM2,DISTRICT
SM292127,26.488,46.4743,Moinesti,ADM2,DISTRICT
SM238548,23.5035,1.2062,Moingi,LANGUAGE,LANGUAGE
SM268091,-50.7681,-16.5017,Moiporá,ADM2,DISTRICT
SM261258,145.5382,-36.0506,Moira,ADM2,DISTRICT
SM291014,24.5399,47.6561,Moisei,ADM2,DISTRICT
SM255866,-64.62,51.51,Moisie,ETHNICITY,ETHNICITY
SM289348,-9.0104,38.6585,Moita,ADM2,DISTRICT
SM267108,-37.3363,-10.5908,Moita Bonita,ADM2,DISTRICT
SM238552,104.15,23.2,Moji,LANGUAGE,LANGUAGE
SM2267,19.5883,42.9592,Mojkovac,ADM1,DISTRICT
SM255867,-66.01,-14.93,Mojo,ETHNICITY,ETHNICITY
SM276354,112.5,-7.55,Mojokerto,ADM2,DISTRICT
SM264194,-49.041,-2.5871,Moju,ADM2,DISTRICT
SM9964,-54.5752,-3.0704,Mojui Dos Campos,ADM2:ADM3,DISTRICT
SM238560,99.5821,18.9117,Mok,LANGUAGE,LANGUAGE
SM2268,57.5667,-20.25,Moka,ADM1,DISTRICT
SM16335,15.872,1.3173,Mokeko,ADM2,DISTRICT
SM238555,98.6765,10.1474,Moken,LANGUAGE,LANGUAGE
SM255869,98.3,12.03,Moken,ETHNICITY,ETHNICITY
SM255869,98.3,12.03,Moken,ETHNICITY,ETHNICITY
SM255869,98.28,11.78,Moken,ETHNICITY,ETHNICITY
SM255869,98.28,11.78,Moken,ETHNICITY,ETHNICITY
SM238553,147.423,-2.016,Mokerang,LANGUAGE,LANGUAGE
SM13983,28.004,-29.0815,Mokhachane,ADM2,DISTRICT
SM2269,29.0333,-29.2167,Mokhotlong,ADM1,DISTRICT
SM14948,159.7661,6.6892,Mokil Municipality,ADM2,DISTRICT
SM238556,159.776,6.6565,Mokilese,LANGUAGE,LANGUAGE
SM238561,98.3176,8.1676,Moklen,LANGUAGE,LANGUAGE
SM297919,10.8498,35.5298,Moknine,ADM2,DISTRICT
SM277101,94.5168,26.3139,Mokokchung,ADM2,DISTRICT
SM238562,3.06,11.0438,Mokole,LANGUAGE,LANGUAGE
SM13984,27.9573,-29.0236,Mokomahatsi,ADM2,DISTRICT
SM238568,25.0437,-1.9508,Mokpa,LANGUAGE,LANGUAGE
SM458005,126.3918,34.8123,Mokpo,ADM2,DISTRICT
SM238569,9.2389,4.2418,Mokpwe,LANGUAGE,LANGUAGE
SM4135,15.1611,45.9153,Mokronog-Trebelno,ADM1,DISTRICT
SM293473,67,55.75,Mokroysovskiy rayon,ADM2,DISTRICT
SM238571,128.654,-3.098,Moksela,LANGUAGE,LANGUAGE
SM238570,42.6728,54.1855,Moksha,LANGUAGE,LANGUAGE
SM461526,43.13,54.08,Moksha,ETHNICITY,ETHNICITY
SM285030,5.3333,9.2,Mokwa,ADM2,DISTRICT
SM225375,142.532,-3.4684,Mol,LANGUAGE,LANGUAGE
SM270490,-72.8523,6.6263,Molagavita,ADM2,DISTRICT
SM238572,-122.857,44.556,Molale,LANGUAGE,LANGUAGE
SM255870,-122.27,44.76,Molalla,ETHNICITY,ETHNICITY
SM255870,-122.78,42.74,Molalla,ETHNICITY,ETHNICITY
SM281691,-98.7611,20.8468,Molango de Escamilla,ADM2,DISTRICT
SM238573,117.0305,7.9567,Molbog,LANGUAGE,LANGUAGE
SM282445,-97.8954,18.7068,Molcaxac,ADM2,DISTRICT
SM294656,84.3333,57.75,Molchanovskiy rayon,ADM2,DISTRICT
SM461348,7.1588,62.7381,Molde (2020-),ADM2,DISTRICT
SM290032,21.6549,44.7505,Moldova Noua,ADM2,DISTRICT
SM238575,28.694,47.107,Moldova Sign Language,LANGUAGE,LANGUAGE
SM291819,25.2542,47.679,Moldova-sulita,ADM2,DISTRICT
SM255871,27.87,46.23,Moldovan,ETHNICITY,ETHNICITY
SM255871,27.87,46.23,Moldovan,ETHNICITY,ETHNICITY
SM255871,27.87,46.23,Moldovan,ETHNICITY,ETHNICITY
SM255871,29,47,Moldovan,ETHNICITY,ETHNICITY
SM255871,29,47,Moldovan,ETHNICITY,ETHNICITY
SM255871,29,47,Moldovan,ETHNICITY,ETHNICITY
SM255871,29.87,46.38,Moldovan,ETHNICITY,ETHNICITY
SM255871,29.87,46.38,Moldovan,ETHNICITY,ETHNICITY
SM255871,29.87,46.38,Moldovan,ETHNICITY,ETHNICITY
SM290118,23.6891,46.4735,Moldovenesti,ADM2,DISTRICT
SM291345,26.7879,46.8213,Moldoveni,ADM2,DISTRICT
SM291820,25.5069,47.7233,Moldovita,ADM2,DISTRICT
SM238577,9.6129,1.3718,Molengue,LANGUAGE,LANGUAGE
SM15170,4.8452,51.8847,Molenlanden,ADM2,DISTRICT
SM238578,146.5252,-5.6631,Molet,LANGUAGE,LANGUAGE
SM295355,160.4952,-9.8703,Moli,ADM2,DISTRICT
SM238583,150.614,-9.5746,Molima,LANGUAGE,LANGUAGE
SM260952,-66.4167,-25.5833,Molinos,ADM2,DISTRICT
SM2270,14.5,41.6667,Molise,ADM1,DISTRICT
SM238586,141.806,-3.3813,Molmo One,LANGUAGE,LANGUAGE
SM238587,33.5677,10.793,Molo,LANGUAGE,LANGUAGE
SM282744,-94.2784,17.9768,Moloacán,ADM2,DISTRICT
SM9804,26.75,54.75,Molodechnenskij Rajon,ADM2,DISTRICT
SM13212,73.75,50.75,Molodezhnyy Audany,ADM2,DISTRICT
SM238592,140.557,-3.4195,Molof,LANGUAGE,LANGUAGE
SM238593,14.2521,10.7933,Moloko,LANGUAGE,LANGUAGE
SM305709,17.0363,46.106,Molve,ADM2,DISTRICT
SM12540,-76.8111,18.0104,Molynes Four Roads,ADM2,DISTRICT
SM12541,-76.8162,18.0198,Molynes Gardens,ADM2,DISTRICT
SM281362,25.4167,55.2333,Molėtų,ADM2,DISTRICT
SM238608,12.7473,8.8658,Mom Jango,LANGUAGE,LANGUAGE
SM238598,120.009,-1.4313,Moma,LANGUAGE,LANGUAGE
SM238599,147.79,-6.3409,Momare,LANGUAGE,LANGUAGE
SM283061,-103.2951,21.934,Momax,ADM2,DISTRICT
SM299113,32.3632,-8.8118,Momba,ADM2,DISTRICT
SM2271,39.6667,-4.02,Mombasa,ADM1:ADM2,DISTRICT
SM266708,-47.6024,-22.9511,Mombaça,ADM2,DISTRICT
SM267797,-39.7799,-5.7922,Mombaça,ADM2,DISTRICT
SM238603,-3.6648,14.3762,Mombo Dogon,LANGUAGE,LANGUAGE
SM240865,138.773,-8.2714,Mombum,LANGUAGE,LANGUAGE
SM262532,25.5,41.5333,Momchilgrad,ADM2,DISTRICT
SM269956,-75.6357,9.2347,Momil,ADM2,DISTRICT
SM238607,139.821,-4.812,Momina,LANGUAGE,LANGUAGE
SM269534,9.9744,5.9005,Momo,ADM2,DISTRICT
SM240335,138.405,-4.8985,Momogo-Pupis-Irogo,LANGUAGE,LANGUAGE
SM275371,-91.4063,15.0473,Momostenango,ADM2,DISTRICT
SM370472,6.465,49.7461,Mompach,ADM1:ADM2:ADM3,DISTRICT
SM255877,-75.39,8.36,Mompox,ETHNICITY,ETHNICITY
SM270688,-74.5451,9.1399,Mompós,ADM2,DISTRICT
SM294310,144,66,Momskiy rayon,ADM2,DISTRICT
SM230880,141.64,-3.1087,Momu-Fas,LANGUAGE,LANGUAGE
SM238610,139.26,-5.0443,Momuna,LANGUAGE,LANGUAGE
SM238648,98.1225,15.4939,Mon,LANGUAGE,LANGUAGE
SM277102,94.8333,26.75,Mon,ADM2,DISTRICT
SM2272,97.75,16.25,Mon,ADM1,DISTRICT
SM255879,99.74,14,Mon,ETHNICITY,ETHNICITY
SM255879,97.92,16.24,Mon,ETHNICITY,ETHNICITY
SM255879,100.22,16.11,Mon,ETHNICITY,ETHNICITY
SM17520,-60.8959,13.8587,Mon Repos,ADM2,DISTRICT
SM12542,-76.7509,18.0133,Mona Heights,ADM2,DISTRICT
SM304933,43.2791,17.1693,Monabbih,ADM2,DISTRICT
SM15941,-66.066,18.3605,Monacillo Barrio,ADM2,DISTRICT
SM15942,-66.0824,18.3807,Monacillo Urbano Barrio,ADM2,DISTRICT
SM303702,-64.7188,7.7387,Monagas,ADM2,DISTRICT
SM303601,-66.3814,9.8601,Monagas,ADM2,DISTRICT
SM2274,-63,9.3333,Monagas,ADM1,DISTRICT
SM2275,-7,54.25,Monaghan,ADM1:ADM2,DISTRICT
SM10917,32.9132,34.7934,Monagri,ADM2,DISTRICT
SM10918,33.2157,34.7472,Monagroulli,ADM2,DISTRICT
SM283934,40.1353,-14.7919,Monapo,ADM2,DISTRICT
SM10919,33.9426,35.3157,Monarga,ADM2,DISTRICT
SM261259,145.1471,-37.8968,Monash,ADM2,DISTRICT
SM238612,12.452,41.9026,Monastic Sign Language,LANGUAGE,LANGUAGE
SM297920,10.7934,35.7561,Monastir,ADM2,DISTRICT
SM2276,10.75,35.6167,Monastir,ADM1,DISTRICT
SM299752,25.1711,49.0907,Monastyrys'kyi,ADM2,DISTRICT
SM299428,29.8078,48.995,Monastyryshchens'kyi,ADM2,DISTRICT
SM278303,143.2929,44.2032,Monbetsu,ADM2,DISTRICT
SM13025,143.0311,44.1938,Monbetsu Gun,ADM2,DISTRICT
SM295763,-88.265,13.5465,Moncagua,ADM2,DISTRICT
SM289516,-8.5834,37.3166,Monchique,ADM2,DISTRICT
SM17521,-60.9262,14.055,Monchy,ADM2,DISTRICT
SM17522,-60.92,14.0616,Monchy/Cardinal,ADM2,DISTRICT
SM17523,-60.9507,14.0593,Monchy/Careffe,ADM2,DISTRICT
SM17524,-60.9238,14.0514,Monchy/Cletus Village,ADM2,DISTRICT
SM17525,-60.9196,14.0463,Monchy/La Borne,ADM2,DISTRICT
SM17526,-60.8996,14.045,Monchy/La Borne/Sans Souci,ADM2,DISTRICT
SM17527,-60.9456,14.0642,Monchy/La Retraite,ADM2,DISTRICT
SM17528,-60.9409,14.0595,Monchy/Lafeuillee,ADM2,DISTRICT
SM17529,-60.9291,14.058,Monchy/Lawi Fwen,ADM2,DISTRICT
SM17530,-60.9191,14.0411,Monchy/Malgretoute,ADM2,DISTRICT
SM17531,-60.9503,14.0628,Monchy/Moulin A Vent,ADM2,DISTRICT
SM17532,-60.9224,14.0692,Monchy/Mount Layau,ADM2,DISTRICT
SM17533,-60.9251,14.0479,Monchy/Ravine Macock,ADM2,DISTRICT
SM17534,-60.9403,14.0499,Monchy/Riviere Mitan,ADM2,DISTRICT
SM17535,-60.9323,14.0408,Monchy/Ti Dauphin,ADM2,DISTRICT
SM17536,-60.9499,14.05,Monchy/Vieux Sucreic,ADM2,DISTRICT
SM17537,-60.9515,14.0561,Monchy/Vieux Sucreic/Bois D'Inde,ADM2,DISTRICT
SM17538,-60.9495,14.0565,Monchy/Vieux Sucreic/Careffe,ADM2,DISTRICT
SM271859,-71.1667,19.4667,Monción,ADM2,DISTRICT
SM283286,-101.3163,26.948,Monclova,ADM2,DISTRICT
SM266212,-53.4397,-27.084,Mondaí,ADM2,DISTRICT
SM370516,5.9883,49.5331,Mondercange,ADM1:ADM2:ADM3,DISTRICT
SM289371,-7.9548,41.4135,Mondim de Basto,ADM2,DISTRICT
SM280523,103.0116,11.8129,Mondol Seima,ADM2,DISTRICT
SM370488,6.2811,49.505,Mondorf-les-Bains,ADM1:ADM2:ADM3,DISTRICT
SM238615,146.931,-2.0136,Mondropolon,LANGUAGE,LANGUAGE
SM299080,36.4252,-3.2722,Monduli,ADM2,DISTRICT
SM238617,105.4163,23.4461,Mondzi,LANGUAGE,LANGUAGE
SM12543,-77.1386,18.2831,Moneague,ADM2,DISTRICT
SM290516,22.2532,46.4444,Moneasa,ADM2,DISTRICT
SM2278,81.3333,6.6667,Moneragala,ADM1:ADM2,DISTRICT
SM255882,-81.89,39.08,Moneton,ETHNICITY,ETHNICITY
SM255882,-83.89,35.25,Moneton,ETHNICITY,ETHNICITY
SM289287,-7.4443,39.0496,Monforte,ADM2,DISTRICT
SM266711,-46.6684,-24.0574,Mongaguá,ADM2,DISTRICT
SM2279,21.5167,2.0667,Mongala,ADM1:ADM2,DISTRICT
SM264246,-35.4873,-7.2679,Mongeiro,ADM2,DISTRICT
SM2280,91.2333,27.25,Monggar,ADM1,DISTRICT
SM17539,-60.9572,14.0629,Mongiraud,ADM2,DISTRICT
SM280350,102.9919,13.4553,Mongkol Borei,ADM2,DISTRICT
SM15098,96.6604,23.4573,Mongmit,ADM2,DISTRICT
SM2281,144.7819,13.4686,Mongmong-Toto-Maite,ADM1,DISTRICT
SM238630,20.4259,-1.5423,Mongo,LANGUAGE,LANGUAGE
SM11287,11.6758,-3.3775,Mongo,ADM2,DISTRICT
SM250521,20.98,-0.05,Mongo,ETHNICITY,ETHNICITY
SM250521,20.98,-0.05,Mongo,ETHNICITY,ETHNICITY
SM250521,22,1,Mongo,ETHNICITY,ETHNICITY
SM250521,22,1,Mongo,ETHNICITY,ETHNICITY
SM238636,29.5637,2.8098,Mongoba-Kazibati,LANGUAGE,LANGUAGE
SM238623,109.005,48.7686,Mongolia Buriat,LANGUAGE,LANGUAGE
SM238621,107.528,48.6852,Mongolian Sign Language,LANGUAGE,LANGUAGE
SM255884,97.48,45.02,Mongolian-Mongols,ETHNICITY,ETHNICITY
SM275142,11.0342,1.6944,Mongomeyen,ADM2,DISTRICT
SM275143,11.162,1.6516,Mongomo,ADM2,DISTRICT
SM238633,124.114,0.6462,Mongondow,LANGUAGE,LANGUAGE
SM255885,124.77,1.11,Mongondow,ETHNICITY,ETHNICITY
SM17540,-61.0452,13.803,Mongouge,ADM2,DISTRICT
SM268595,18.4759,3.7264,Mongoumba,ADM2,DISTRICT
SM283487,99.0562,20.3752,Mongsat,ADM2,DISTRICT
SM18151,23.5325,-15.3744,Mongu District (2012-),ADM2,DISTRICT
SM270788,-72.7173,5.6745,Mongua,ADM2,DISTRICT
SM284008,-12.9624,16.4443,Monguel,ADM2,DISTRICT
SM285378,13.6173,12.5785,Monguno,ADM2,DISTRICT
SM270789,-72.8336,5.6975,Monguí,ADM2,DISTRICT
SM238645,136.886,-3.7296,Moni,LANGUAGE,LANGUAGE
SM255886,136.84,-3.98,Moni,ETHNICITY,ETHNICITY
SM10920,32.9064,34.883,Moniatis,ADM2,DISTRICT
SM17541,-60.941,14.0295,Monier,ADM2,DISTRICT
SM270790,-73.5778,5.8431,Moniquirá,ADM2,DISTRICT
SM301587,-92.5831,38.6328,Moniteau,ADM2,DISTRICT
SM14350,-96.6341,16.3936,Monjas,ADM2,DISTRICT
SM275176,-89.8956,14.5127,Monjas,ADM2,DISTRICT
SM263662,-44.026,-18.3756,Monjolos,ADM2,DISTRICT
SM17542,-61.0062,13.9889,Monkey Town Ciceron,ADM2,DISTRICT
SM301817,-74.1582,40.2876,Monmouth,ADM2,DISTRICT
SM274749,-2.8333,51.75,Monmouthshire,ADM1:ADM2,DISTRICT
SM303181,-118.8867,37.939,Mono,ADM2,DISTRICT
SM255887,-118.81,37.5,Mono,ETHNICITY,ETHNICITY
SM255887,-118.81,37.5,Mono,ETHNICITY,ETHNICITY
SM255887,-118.81,37.5,Mono,ETHNICITY,ETHNICITY
SM255887,-118.81,37.5,Mono,ETHNICITY,ETHNICITY
SM255887,-118,37,Mono,ETHNICITY,ETHNICITY
SM255887,-118,37,Mono,ETHNICITY,ETHNICITY
SM255887,-118,37,Mono,ETHNICITY,ETHNICITY
SM255887,-118,37,Mono,ETHNICITY,ETHNICITY
SM255887,-119,37,Mono,ETHNICITY,ETHNICITY
SM255887,-119,37,Mono,ETHNICITY,ETHNICITY
SM255887,-119,37,Mono,ETHNICITY,ETHNICITY
SM255887,-119,37,Mono,ETHNICITY,ETHNICITY
SM255887,-117.08,36.6,Mono,ETHNICITY,ETHNICITY
SM255887,-117.08,36.6,Mono,ETHNICITY,ETHNICITY
SM255887,-117.08,36.6,Mono,ETHNICITY,ETHNICITY
SM255887,-117.08,36.6,Mono,ETHNICITY,ETHNICITY
SM2282,1.8333,6.5833,Mono (1999-),ADM1,DISTRICT
SM238651,14.2254,8.7837,Mono (Cameroon),LANGUAGE,LANGUAGE
SM238652,19.8264,4.5792,Mono (Democratic Republic of Congo),LANGUAGE,LANGUAGE
SM238656,-119.347,37.1935,Mono (USA),LANGUAGE,LANGUAGE
SM255888,-118.45,38.11,Mono Lake Northern Paiute,ETHNICITY,ETHNICITY
SM238654,155.76,-7.051,Mono-Alu,LANGUAGE,LANGUAGE
SM238650,108.348,14.5948,Monom,LANGUAGE,LANGUAGE
SM255889,108.3,14.38,Monom,ETHNICITY,ETHNICITY
SM300847,-95.9599,42.0517,Monona,ADM2,DISTRICT
SM303132,-80.0465,39.6303,Monongalia,ADM2,DISTRICT
SM292510,24.7011,46.9548,Monor,ADM2,DISTRICT
SM11582,19.4768,47.3499,Monori Jaras,ADM2,DISTRICT
SM303133,-80.5506,37.5604,Monroe,ADM2,DISTRICT
SM303259,-90.6179,43.9457,Monroe,ADM2,DISTRICT
SM302602,-84.2528,35.4427,Monroe,ADM2,DISTRICT
SM302324,-75.3395,41.0581,Monroe,ADM2,DISTRICT
SM302135,-81.0829,39.7274,Monroe,ADM2,DISTRICT
SM301888,-77.6363,43.1651,Monroe,ADM2,DISTRICT
SM301588,-92.0007,39.4955,Monroe,ADM2,DISTRICT
SM301309,-83.4943,41.9214,Monroe,ADM2,DISTRICT
SM301468,-88.4805,33.8922,Monroe,ADM2,DISTRICT
SM300656,-90.1774,38.2787,Monroe,ADM2,DISTRICT
SM301093,-85.7165,36.7122,Monroe,ADM2,DISTRICT
SM300740,-86.5231,39.1609,Monroe,ADM2,DISTRICT
SM300848,-92.869,41.0298,Monroe,ADM2,DISTRICT
SM300384,-83.9187,33.0141,Monroe,ADM2,DISTRICT
SM300351,-80.9171,25.5573,Monroe,ADM2,DISTRICT
SM300291,-87.3654,31.5708,Monroe,ADM2,DISTRICT
SM302394,-91.2039,34.6778,Monroe,ADM2,DISTRICT
SM238660,94.2164,24.5322,Monsang Naga,LANGUAGE,LANGUAGE
SM265059,-42.5488,-5.6416,Monsenhor Gil,ADM2,DISTRICT
SM265061,-41.0316,-6.9498,Monsenhor Hipólito,ADM2,DISTRICT
SM263663,-45.468,-21.7227,Monsenhor Paulo,ADM2,DISTRICT
SM267798,-40.0673,-4.9676,Monsenhor Tabosa,ADM2,DISTRICT
SM303569,-68.2772,10.9333,Monseñor Iturriza,ADM2,DISTRICT
SM303690,-69.9475,9.4337,Monseñor José Vicente de Unda,ADM2,DISTRICT
SM2283,-70.4167,18.9167,Monseñor Nouel,ADM1,DISTRICT
SM11126,15.3367,-4.3628,Mont Amba District,ADM2,DISTRICT
SM2284,55.441,-4.6177,Mont Buxton,ADM1,DISTRICT
SM2285,55.4583,-4.6333,Mont Fleuri,ADM1,DISTRICT
SM296804,15.154,9.8703,Mont Illi,ADM2,DISTRICT
SM284594,166.5805,-22.2799,Mont-Dore,ADM2,DISTRICT
SM264247,-35.903,-7.0895,Montadas,ADM2,DISTRICT
SM238661,-68.7376,49.0533,Montagnais,LANGUAGE,LANGUAGE
SM2286,-7.629,6.9543,Montagnes,ADM1,DISTRICT
SM302716,-97.7247,33.6756,Montague,ADM2,DISTRICT
SM303865,-68.35,10.1667,Montalbán,ADM2,DISTRICT
SM289372,-7.79,41.8318,Montalegre,ADM2,DISTRICT
SM15943,-66.9477,17.9541,Montalva Barrio,ADM2,DISTRICT
SM273486,-79.317,-1.803,Montalvo,ADM2,DISTRICT
SM263664,-44.4421,-14.4109,Montalvânia,ADM2,DISTRICT
SM262311,23.2,43.4667,Montana,ADM2,DISTRICT
SM2287,23.1833,43.5,Montana,ADM1,DISTRICT
SM2288,-109.751,47.0003,Montana,ADM1,DISTRICT
SM15944,-67.097,18.4975,Montana Barrio,ADM2,DISTRICT
SM268008,-40.261,-18.1361,Montanha,ADM2,DISTRICT
SM265589,-35.304,-6.4887,Montanhas,ADM2,DISTRICT
SM255893,-73.69,40.72,Montauk,ETHNICITY,ETHNICITY
SM265764,-52.0539,-28.665,Montauri,ADM2,DISTRICT
SM301310,-85.1525,43.311,Montcalm,ADM2,DISTRICT
SM327761,-97.3401,49.165,Montcalm,ADM2:ADM3,DISTRICT
SM260801,-58.75,-35.5,Monte,ADM2,DISTRICT
SM265590,-35.4372,-6.0843,Monte Alegre,ADM2,DISTRICT
SM264195,-54.4533,-0.8447,Monte Alegre,ADM2,DISTRICT
SM268092,-46.8427,-13.2615,Monte Alegre de Goiás,ADM2,DISTRICT
SM263665,-48.9182,-18.8188,Monte Alegre de Minas,ADM2,DISTRICT
SM267110,-37.6047,-10.0726,Monte Alegre de Sergipe,ADM2,DISTRICT
SM265062,-44.9827,-9.7421,Monte Alegre do Piauí,ADM2,DISTRICT
SM266712,-46.6718,-22.71,Monte Alegre do Sul,ADM2,DISTRICT
SM265765,-50.804,-28.7018,Monte Alegre dos Campos,ADM2,DISTRICT
SM266713,-48.5579,-21.2501,Monte Alto,ADM2,DISTRICT
SM266714,-49.691,-20.726,Monte Aprazível,ADM2,DISTRICT
SM263666,-43.0233,-15.2127,Monte Azul,ADM2,DISTRICT
SM266715,-48.6896,-20.9202,Monte Azul Paulista,ADM2,DISTRICT
SM263667,-46.3352,-21.32,Monte Belo,ADM2,DISTRICT
SM265766,-51.6429,-29.1318,Monte Belo do Sul,ADM2,DISTRICT
SM266213,-50.9211,-27.1868,Monte Carlo,ADM2,DISTRICT
SM303762,-70.8156,9.1858,Monte Carmelo,ADM2,DISTRICT
SM263670,-47.4979,-18.6396,Monte Carmelo,ADM2,DISTRICT
SM261155,-57.8333,-30.25,Monte Caseros,ADM2,DISTRICT
SM266716,-51.5714,-21.2277,Monte Castelo,ADM2,DISTRICT
SM266214,-50.2885,-26.6166,Monte Castelo,ADM2,DISTRICT
SM2289,-71.4667,19.7167,Monte Cristi,ADM1,DISTRICT
SM283062,-103.4908,22.3886,Monte Escobedo,ADM2,DISTRICT
SM263671,-41.2713,-16.876,Monte Formoso,ADM2,DISTRICT
SM15945,-67.1282,18.0777,Monte Grande Barrio,ADM2,DISTRICT
SM260803,-61.2967,-38.9794,Monte Hermoso,ADM2,DISTRICT
SM264248,-38.525,-7.2079,Monte Horebe,ADM2,DISTRICT
SM15948,-66.1396,18.1145,Monte Llano Barrio (Cayey),ADM2,DISTRICT
SM15949,-66.152,18.2045,Monte Llano Barrio (Cidra),ADM2,DISTRICT
SM15946,-66.393,18.3225,Monte Llano Barrio (Morovis),ADM2,DISTRICT
SM15947,-66.6182,18.1005,Monte Llano Barrio (Ponce),ADM2,DISTRICT
SM266717,-47.3021,-22.9507,Monte Mor,ADM2,DISTRICT
SM266092,-63.354,-10.3045,Monte Negro,ADM2,DISTRICT
SM271805,-69.85,18.75,Monte Plata,ADM2,DISTRICT
SM2290,-69.8333,18.8333,Monte Plata,ADM1,DISTRICT
SM295651,-88.941,13.7731,Monte San Juan,ADM2,DISTRICT
SM267543,-39.5684,-10.2665,Monte Santo,ADM2,DISTRICT
SM263672,-46.954,-21.2043,Monte Santo de Minas,ADM2,DISTRICT
SM267263,-49.1275,-10.0097,Monte Santo do Tocantins,ADM2,DISTRICT
SM263673,-46.568,-22.4313,Monte Sião,ADM2,DISTRICT
SM238664,-97.7215,17.5338,Monte Verde Mixtec,DIALECT,LANGUAGE
SM265591,-35.7989,-6.4345,Monte das Gameleiras,ADM2,DISTRICT
SM267262,-48.0181,-10.7203,Monte do Carmo,ADM2,DISTRICT
SM270237,-75.5274,5.937,Montebello,ADM2,DISTRICT
SM260901,-54.5833,-26.7167,Montecarlo,ADM2,DISTRICT
SM273493,-80.6833,-1.05,Montecristi,ADM2,DISTRICT
SM270689,-74.4582,7.9957,Montecristo,ADM2,DISTRICT
SM14351,-92.644,15.6753,Montecristo de Guerrero,ADM2,DISTRICT
SM2291,12.4844,43.9113,Montegiardino,ADM1,DISTRICT
SM12544,-77.8815,18.4804,Montego Hill,ADM2,DISTRICT
SM264249,-37.175,-7.9164,Monteiro,ADM2,DISTRICT
SM266718,-45.8053,-22.933,Monteiro Lobato,ADM2,DISTRICT
SM265391,-37.2935,-9.6202,Monteirópolis,ADM2,DISTRICT
SM10444,-75.9217,7.7613,Montelibano (2007-),ADM2,DISTRICT
SM271822,-70.5926,19.7083,Montellano,ADM2,DISTRICT
SM289497,-8.2129,38.648,Montemor-o-Novo,ADM2,DISTRICT
SM289471,-8.667,40.1939,Montemor-o-Velho,ADM2,DISTRICT
SM282182,-99.8443,25.167,Montemorelos,ADM2,DISTRICT
SM255894,20.95,42.05,Montenegrin,ETHNICITY,ETHNICITY
SM270424,-75.8227,4.5268,Montenegro,ADM2,DISTRICT
SM265767,-51.4796,-29.6998,Montenegro,ADM2,DISTRICT
SM283862,38.766,-12.537,Montepuez,ADM2,DISTRICT
SM10216,-73.0992,45.3834,Monteregie,ADM2,DISTRICT
SM303182,-121.3089,36.2398,Monterey,ADM2,DISTRICT
SM261067,-65.5833,-27.1667,Monteros,ADM2,DISTRICT
SM282183,-100.2839,25.5768,Monterrey,ADM2,DISTRICT
SM270894,-72.879,4.8202,Monterrey,ADM2,DISTRICT
SM269934,-75.9721,8.6314,Montería,ADM2,DISTRICT
SM303719,-63.9167,10.25,Montes,ADM2,DISTRICT
SM18341,-55.5496,-34.5197,Montes,ADM2,DISTRICT
SM262829,-47.0801,-5.8553,Montes Altos,ADM2,DISTRICT
SM263674,-43.8999,-16.5879,Montes Claros,ADM2,DISTRICT
SM268093,-51.506,-15.9625,Montes Claros de Goiás,ADM2,DISTRICT
SM270960,-84.0301,9.9396,Montes de Oca,ADM2,DISTRICT
SM270957,-84.7415,10.1085,Montes de Oro,ADM2,DISTRICT
SM2292,-56.2,-34.825,Montevideo,ADM1,DISTRICT
SM303355,-108.5967,37.3384,Montezuma,ADM2,DISTRICT
SM263675,-42.4813,-15.1925,Montezuma,ADM2,DISTRICT
SM285597,4.9484,52.0477,Montfoort,ADM2,DISTRICT
SM302718,-95.503,30.3002,Montgomery,ADM2,DISTRICT
SM303051,-80.387,37.174,Montgomery,ADM2,DISTRICT
SM302603,-87.3829,36.4969,Montgomery,ADM2,DISTRICT
SM302325,-75.3673,40.2108,Montgomery,ADM2,DISTRICT
SM302136,-84.2907,39.7546,Montgomery,ADM2,DISTRICT
SM301890,-74.4397,42.9023,Montgomery,ADM2,DISTRICT
SM301989,-79.9055,35.3325,Montgomery,ADM2,DISTRICT
SM301220,-77.2042,39.1364,Montgomery,ADM2,DISTRICT
SM301590,-91.4702,38.9415,Montgomery,ADM2,DISTRICT
SM301469,-89.6164,33.4941,Montgomery,ADM2,DISTRICT
SM300657,-89.4789,39.231,Montgomery,ADM2,DISTRICT
SM300849,-95.1564,41.0301,Montgomery,ADM2,DISTRICT
SM301094,-83.9131,38.0335,Montgomery,ADM2,DISTRICT
SM300741,-86.8933,40.0404,Montgomery,ADM2,DISTRICT
SM300948,-95.7429,37.1925,Montgomery,ADM2,DISTRICT
SM300385,-82.5348,32.1734,Montgomery,ADM2,DISTRICT
SM302395,-93.6595,34.5388,Montgomery,ADM2,DISTRICT
SM300292,-86.2076,32.2203,Montgomery,ADM2,DISTRICT
SM268995,6.9027,46.2704,Monthey,ADM2,DISTRICT
SM289349,-8.6936,38.7299,Montijo,ADM2,DISTRICT
SM268095,-51.1574,-17.2582,Montividiu,ADM2,DISTRICT
SM268094,-48.7416,-13.0923,Montividiu do Norte,ADM2,DISTRICT
SM301311,-84.1272,45.0276,Montmorency,ADM2,DISTRICT
SM238665,9.6501,9.0178,Montol,LANGUAGE,LANGUAGE
SM15950,-65.9014,18.1549,Montones Barrio,ADM2,DISTRICT
SM15951,-67.0187,18.1757,Montoso Barrio (Maricao),ADM2,DISTRICT
SM15952,-67.035,18.193,Montoso Barrio (Mayagüez),ADM2,DISTRICT
SM302326,-76.6586,41.0279,Montour,ADM2,DISTRICT
SM12545,-77.9136,18.3761,Montpelier,ADM2,DISTRICT
SM303356,-108.2694,38.4022,Montrose,ADM2,DISTRICT
SM296796,15.8929,7.9082,Monts de Lam,ADM2,DISTRICT
SM2293,-10.5667,6.5,Montserrado,ADM1,DISTRICT
SM273594,-77.8333,0.6,Montúfar,ADM2,DISTRICT
SM240870,144.95,-4.2717,Monumbo,LANGUAGE,LANGUAGE
SM15099,95.2638,22.2573,Monywa (excl. Yinmabin),ADM2,DISTRICT
SM277874,9.2162,45.5967,Monza and Brianza,ADM2,DISTRICT
SM305218,27.3599,-16.1044,Monze,ADM2,DISTRICT
SM17543,-61.023,13.8067,Monzie,ADM2,DISTRICT
SM238668,18.5129,3.1901,Monzombo,LANGUAGE,LANGUAGE
SM289356,-8.4849,42.0726,Monção,ADM2,DISTRICT
SM262828,-45.3069,-3.5329,Monção,ADM2,DISTRICT
SM266709,-50.0833,-20.8688,Monções,ADM2,DISTRICT
SM238674,11.204,9.4408,Moo,LANGUAGE,LANGUAGE
SM302523,-96.6709,44.022,Moody,ADM2,DISTRICT
SM285874,5.902,51.7478,Mook en Middelaar,ADM2,DISTRICT
SM13026,140.05,36.45,Mooka-shi (2009-),ADM2,DISTRICT
SM458202,153.4691,-27.4722,Moondjan,ETHNICITY,ETHNICITY
SM261261,144.8933,-37.7494,Moonee Valley,ADM2,DISTRICT
SM280852,105.5683,14.2892,Moonlapamok,ADM2,DISTRICT
SM261415,116.2098,-30.5122,Moora,ADM2,DISTRICT
SM261262,144.2195,-37.6334,Moorabool,ADM2,DISTRICT
SM302719,-101.8929,35.8377,Moore,ADM2,DISTRICT
SM302604,-86.3588,35.2846,Moore,ADM2,DISTRICT
SM301990,-79.4813,35.3107,Moore,ADM2,DISTRICT
SM12546,-76.3749,18.067,Moore Town,ADM2,DISTRICT
SM2294,-77.5542,26.3008,Moore's Island,ADM1,DISTRICT
SM11249,-149.8353,-17.4983,Moorea-Maiao,ADM2,DISTRICT
SM12547,-77.1854,18.0293,Moores,ADM2,DISTRICT
SM238677,-80.6912,51.0686,Moose Cree,LANGUAGE,LANGUAGE
SM7673,-83,53,Moose Cree,ETHNICITY,ETHNICITY
SM284956,5.8785,8.1033,Mopa-Muro,ADM2,DISTRICT
SM305126,30.836,-23.7666,Mopani,ADM2,DISTRICT
SM283878,35.715,-17.98,Mopeia,ADM2,DISTRICT
SM283878,35.715,-17.98,Mopeia,ADM2,DISTRICT
SM283878,36.0626,-17.8329,Mopeia,ADM2,DISTRICT
SM283878,36.0626,-17.8329,Mopeia,ADM2,DISTRICT
SM283369,-4.093,14.78,Mopti,ADM2,DISTRICT
SM2295,-3.4,14.7,Mopti,ADM1,DISTRICT
SM238679,-89.0673,16.3533,Mopán Maya,LANGUAGE,LANGUAGE
SM2296,-70.9199,-16.8366,Moquegua,ADM1,DISTRICT
SM255899,-70.98,-17.31,Moquegua,ETHNICITY,ETHNICITY
SM238689,132.845,-2.8575,Mor (Bomberai Peninsula),LANGUAGE,LANGUAGE
SM238712,135.95,-2.8706,Mor (Mor Islands),LANGUAGE,LANGUAGE
SM301844,-104.9446,36.01,Mora,ADM2,DISTRICT
SM296542,14.5376,61.0066,Mora,ADM2,DISTRICT
SM289498,-8.0997,38.9217,Mora,ADM2,DISTRICT
SM10921,33.5385,35.17,Mora,ADM2,DISTRICT
SM270961,-84.2504,9.8807,Mora,ADM2,DISTRICT
SM15953,-67.0187,18.4823,Mora Barrio,ADM2,DISTRICT
SM267799,-38.4347,-5.0369,Morada Nova,ADM2,DISTRICT
SM263676,-45.4722,-18.5661,Morada Nova de Minas,ADM2,DISTRICT
SM277320,78.797,28.8525,Moradabad (2012-),ADM2,DISTRICT
SM371003,44.9593,-17.9347,Morafanobe,ADM2:ADM3,DISTRICT
SM238682,131.965,-1.0408,Moraid,LANGUAGE,LANGUAGE
SM275509,-88.7665,15.4449,Morales,ADM2,DISTRICT
SM269862,-76.7694,2.7819,Morales,ADM2,DISTRICT
SM10428,-73.9731,8.2523,Morales (1996-),ADM2,DISTRICT
SM371015,48.2597,-18.802,Moramanga,ADM2:ADM3,DISTRICT
SM15265,125.7518,39.0516,Moranbong,ADM2,DISTRICT
SM375314,87.4732,26.6107,Morang,ADM2:ADM3,DISTRICT
SM12548,-76.4066,17.9008,Morant Bay,ADM2,DISTRICT
SM255903,140.48,-7.82,Moraori,ETHNICITY,ETHNICITY
SM291266,24.5608,45.0076,Moraresti,ADM2,DISTRICT
SM255904,-78.21,36.18,Moratuc,ETHNICITY,ETHNICITY
SM296310,14.755,46.1336,Moravce,ADM1:ADM2,DISTRICT
SM270962,-84,10.04,Moravia,ADM2,DISTRICT
SM292000,21.2557,45.2803,Moravita,ADM2,DISTRICT
SM2297,20.1781,43.8039,Moravički,ADM1:ADM2,DISTRICT
SM296363,16.2736,46.7175,Moravske Toplice,ADM1:ADM2,DISTRICT
SM2298,18,49.8333,Moravskoslezský,ADM1,DISTRICT
SM238686,148.671,-10.1321,Morawa,LANGUAGE,LANGUAGE
SM261416,116.0564,-29.0355,Morawa,ADM2,DISTRICT
SM274725,-3.25,57.4167,Moray,ADM1:ADM2,DISTRICT
SM275808,-87.5518,15.3309,Morazán,ADM2,DISTRICT
SM275418,-90.1569,14.9663,Morazán,ADM2,DISTRICT
SM2299,-88.1,13.8,Morazán,ADM1,DISTRICT
SM267800,-40.6996,-3.4601,Moraújo,ADM2,DISTRICT
SM276823,70.8229,22.8147,Morbi,ADM2,DISTRICT
SM274480,-2.7776,47.8477,Morbihan,ADM2,DISTRICT
SM2300,44,54.5,Mordovia,ADM1,DISTRICT
SM255906,42.88,51.57,Mordvinian,ETHNICITY,ETHNICITY
SM255906,42.88,51.57,Mordvinian,ETHNICITY,ETHNICITY
SM255906,45.1833,54.1833,Mordvinian,ETHNICITY,ETHNICITY
SM255906,45.1833,54.1833,Mordvinian,ETHNICITY,ETHNICITY
SM255907,-66.29,-12.05,More,ETHNICITY,ETHNICITY
SM4181,7.1506,62.7345,More og Romsdal fylke (2020-),ADM1,DISTRICT
SM17544,-60.9424,13.8268,Moreau,ADM2,DISTRICT
SM261583,149.5812,-29.3213,Moree Plains,ADM2,DISTRICT
SM301135,-91.8018,32.8202,Morehouse,ADM2,DISTRICT
SM264585,-52.9551,-24.0146,Moreira Sales,ADM2,DISTRICT
SM261263,144.9375,-37.7356,Moreland,ADM2,DISTRICT
SM282051,-101.252,19.6534,Morelia,ADM2,DISTRICT
SM270865,-75.7205,1.4823,Morelia,ADM2,DISTRICT
SM283063,-102.6447,22.8649,Morelos,ADM2,DISTRICT
SM281940,-99.6469,19.7454,Morelos,ADM2,DISTRICT
SM282052,-101.4365,19.9927,Morelos,ADM2,DISTRICT
SM283251,-107.7121,26.6003,Morelos,ADM2,DISTRICT
SM283287,-100.9167,28.3822,Morelos,ADM2,DISTRICT
SM2302,-99.0667,18.75,Morelos,ADM1,DISTRICT
SM238696,-99.1322,18.9161,Morelos Nahuatl,LANGUAGE,LANGUAGE
SM276989,77.5,26.1667,Morena (1998-),ADM2,DISTRICT
SM290284,25.6438,44.9801,Moreni,ADM2,DISTRICT
SM264853,-35.128,-8.1531,Moreno,ADM2,DISTRICT
SM261044,-62.5,-27.3333,Moreno,ADM2,DISTRICT
SM260804,-58.7906,-34.6349,Moreno,ADM2,DISTRICT
SM238697,-61.3858,-8.6682,Morerebi,LANGUAGE,LANGUAGE
SM243326,166.843,-15.4048,Mores,LANGUAGE,LANGUAGE
SM13701,25.062,57.0752,Mores pagasts,ADM2,DISTRICT
SM238695,144.988,-4.575,Moresada,LANGUAGE,LANGUAGE
SM261647,152.9294,-27.1073,Moreton Bay,ADM2,DISTRICT
SM10922,32.9765,35.2192,Morfou,ADM2,DISTRICT
SM303134,-78.2577,39.5604,Morgan,ADM2,DISTRICT
SM302899,-111.5731,41.0893,Morgan,ADM2,DISTRICT
SM302605,-84.6492,36.135,Morgan,ADM2,DISTRICT
SM302137,-81.8527,39.6204,Morgan,ADM2,DISTRICT
SM301591,-92.886,38.4237,Morgan,ADM2,DISTRICT
SM300659,-90.2015,39.7156,Morgan,ADM2,DISTRICT
SM301095,-83.2589,37.9223,Morgan,ADM2,DISTRICT
SM300742,-86.4462,39.4816,Morgan,ADM2,DISTRICT
SM300386,-83.4924,33.5908,Morgan,ADM2,DISTRICT
SM303357,-103.8098,40.2627,Morgan,ADM2,DISTRICT
SM300293,-86.8529,34.4535,Morgan,ADM2,DISTRICT
SM12549,-77.4102,18.1909,Morgans Forest,ADM2,DISTRICT
SM12550,-77.2749,18.1212,Morgans Pass,ADM2,DISTRICT
SM292797,46.75,56,Morgaushskiy rayon,ADM2,DISTRICT
SM269004,6.4866,46.5358,Morges,ADM2,DISTRICT
SM279290,137.9476,34.885,Mori,ADM2,DISTRICT
SM238704,121.035,-2.2555,Mori Atas,LANGUAGE,LANGUAGE
SM238703,121.37,-2.5602,Mori Bawah,LANGUAGE,LANGUAGE
SM255908,121.27,-2.21,Mori-Laki,ETHNICITY,ETHNICITY
SM10429,-69.8328,2.4298,Morichal,ADM2,DISTRICT
SM277389,92.3424,26.2521,Morigaon,ADM2,DISTRICT
SM238706,143.88,-7.6296,Morigi,LANGUAGE,LANGUAGE
SM279106,135.5695,34.7444,Moriguchi,ADM2,DISTRICT
SM238708,-73.1268,-5.6346,Morike,LANGUAGE,LANGUAGE
SM324011,-113.6384,53.7982,Morinville,ADM2:ADM3,DISTRICT
SM278443,141.2692,39.7455,Morioka,ADM2,DISTRICT
SM238702,-176.4333,-44.0333,Moriori,DIALECT,LANGUAGE
SM283252,-108.7272,28.1342,Moris,ADM2,DISTRICT
SM238710,57.5517,-20.2613,Morisyen,LANGUAGE,LANGUAGE
SM278384,139.9754,35.9514,Moriya,ADM2,DISTRICT
SM279228,135.9687,35.0882,Moriyama,ADM2,DISTRICT
SM293633,49,56.5,Morkinskiy rayon,ADM2,DISTRICT
SM280831,104.0167,18.9167,Morkmay,ADM2,DISTRICT
SM265768,-52.6693,-28.69,Mormaço,ADM2,DISTRICT
SM454472,-111.5,39.5,Mormon,ETHNICITY,ETHNICITY
SM298044,10.2572,36.5975,Mornag,ADM2,DISTRICT
SM297895,9.9641,36.6804,Mornaguia,ADM2,DISTRICT
SM17545,-60.9324,13.9908,Morne Assau/Babonneau,ADM2,DISTRICT
SM17546,-61.0329,13.8317,Morne Bonin,ADM2,DISTRICT
SM17547,-60.9551,13.7835,Morne Cayenne,ADM2,DISTRICT
SM17548,-61.0033,13.936,Morne Ciseaux,ADM2,DISTRICT
SM17549,-60.9281,14.03,Morne Citon,ADM2,DISTRICT
SM17550,-61.006,13.9493,Morne D'Or,ADM2,DISTRICT
SM17551,-60.9638,14.0119,Morne Dudon,ADM2,DISTRICT
SM17552,-60.9952,13.7655,Morne Gomier,ADM2,DISTRICT
SM17553,-61.0317,13.8116,Morne Jacques,ADM2,DISTRICT
SM17554,-61.0615,13.8176,Morne La Croix,ADM2,DISTRICT
SM17555,-61.0589,13.8663,Morne Lastic/Desruisseaux,ADM2,DISTRICT
SM17556,-60.9991,13.7587,Morne Le Blanc,ADM2,DISTRICT
SM17557,-61.0256,13.7772,Morne Lezard Estate,ADM2,DISTRICT
SM17558,-61.0404,13.7879,Morne Palama,ADM2,DISTRICT
SM17559,-60.9365,13.9217,Morne Panache,ADM2,DISTRICT
SM17560,-61.0084,13.7661,Morne Paul,ADM2,DISTRICT
SM17561,-60.9969,14.0054,Morne Road,ADM2,DISTRICT
SM17562,-60.9697,13.9584,Morne Rouge/Marc,ADM2,DISTRICT
SM17563,-61.0547,13.7925,Morne Sion,ADM2,DISTRICT
SM17564,-61.0641,13.891,Morne Siquot,ADM2,DISTRICT
SM17565,-61.0321,13.8729,Morne Tabac,ADM2,DISTRICT
SM17566,-60.9762,13.779,Morne Vert,ADM2,DISTRICT
SM17567,-60.9524,13.8065,Morne Vient,ADM2,DISTRICT
SM261648,139.4668,-16.6844,Mornington,ADM2,DISTRICT
SM261264,145.0546,-38.34,Mornington Peninsula,ADM2,DISTRICT
SM238718,30.2138,10.9194,Moro,LANGUAGE,LANGUAGE
SM284976,4.5567,8.8449,Moro,ADM2,DISTRICT
SM255909,123.2,7.89,Moro,ETHNICITY,ETHNICITY
SM454541,30,11,Moro,ETHNICITY,ETHNICITY
SM2303,146.9672,-7.0106,Morobe,ADM1,DISTRICT
SM17890,30.8766,3.6693,Morobo County,ADM2,DISTRICT
SM251185,-7.58,33.53,Moroccan,ETHNICITY,ETHNICITY
SM238724,-7.5,32.5,Moroccan Arabic,LANGUAGE,LANGUAGE
SM6026,-7,33,Moroccan Imazighen,ETHNICITY,ETHNICITY
SM6026,-7,33,Moroccan Imazighen,ETHNICITY,ETHNICITY
SM6026,5.1,33.5,Moroccan Imazighen,ETHNICITY,ETHNICITY
SM6026,5.1,33.5,Moroccan Imazighen,ETHNICITY,ETHNICITY
SM238713,-7.6961,32.139,Moroccan Sign Language,LANGUAGE,LANGUAGE
SM251084,-8,33,Morocco-Arab,ETHNICITY,ETHNICITY
SM275911,-86.8333,14.1359,Morocelí,ADM2,DISTRICT
SM290285,25.4447,45.2341,Moroeni,ADM2,DISTRICT
SM2304,37.25,-7.9167,Morogoro,ADM1,DISTRICT
SM299117,38.1167,-6.75,Morogoro Rural,ADM2,DISTRICT
SM299118,37.6667,-6.8167,Morogoro Urban,ADM2,DISTRICT
SM238717,30.0229,5.5103,Morokodo,LANGUAGE,LANGUAGE
SM281547,-101.2308,20.0963,Moroleón,ADM2,DISTRICT
SM275827,-86.8882,13.568,Morolica,ADM2,DISTRICT
SM238715,17.6465,12.1461,Morom,LANGUAGE,LANGUAGE
SM371040,43.774,-22.096,Morombe,ADM2:ADM3,DISTRICT
SM273520,-78.1,-2.3167,Morona,ADM2,DISTRICT
SM2305,-77.9167,-2.5,Morona Santiago,ADM1,DISTRICT
SM371048,44.368,-20.527,Morondava,ADM2:ADM3,DISTRICT
SM238720,121.824,-4.6236,Moronene,LANGUAGE,LANGUAGE
SM269486,-4.1316,6.6683,Moronou,ADM2,DISTRICT
SM233136,140.823,-5.1104,Morop-Dintere,LANGUAGE,LANGUAGE
SM255910,128.49,2.27,Morotai,ETHNICITY,ETHNICITY
SM4734,34.6439,2.6389,Moroto (2010-),ADM1:ADM2,DISTRICT
SM238719,166.891,-15.3498,Morouas,LANGUAGE,LANGUAGE
SM2307,-66.4177,18.3188,Morovis,ADM1,DISTRICT
SM15954,-66.4079,18.326,Morovis Barrio-Pueblo,ADM2,DISTRICT
SM15955,-66.4076,18.3416,Morovis Norte Barrio,ADM2,DISTRICT
SM15956,-66.4118,18.3145,Morovis Sud Barrio,ADM2,DISTRICT
SM11740,121.9527,-2.7606,Morowali Regency (2013-),ADM2,DISTRICT
SM267544,-43.0913,-11.7526,Morpará,ADM2,DISTRICT
SM264586,-48.8743,-25.5308,Morretes,ADM2,DISTRICT
SM301736,-103.0106,41.716,Morrill,ADM2,DISTRICT
SM323881,-112.764,51.6586,Morrin,ADM2:ADM3,DISTRICT
SM268096,-49.0923,-17.7992,Morrinhos,ADM2,DISTRICT
SM267801,-40.0913,-3.2842,Morrinhos,ADM2,DISTRICT
SM265769,-49.9644,-29.3436,Morrinhos do Sul,ADM2,DISTRICT
SM302720,-94.7327,33.1135,Morris,ADM2,DISTRICT
SM301818,-74.5444,40.862,Morris,ADM2,DISTRICT
SM300949,-96.6499,38.6874,Morris,ADM2,DISTRICT
SM327763,-97.459,49.4,Morris,ADM2:ADM3,DISTRICT
SM301383,-94.2684,46.0126,Morrison,ADM2,DISTRICT
SM285476,-84.9404,11.6374,Morrito,ADM2,DISTRICT
SM266719,-48.1889,-20.636,Morro Agudo,ADM2,DISTRICT
SM268097,-50.0158,-15.3231,Morro Agudo de Goiás,ADM2,DISTRICT
SM265063,-43.9066,-9.8771,Morro Cabeça No Tempo,ADM2,DISTRICT
SM266216,-49.7479,-28.7255,Morro Grande,ADM2,DISTRICT
SM265770,-52.6357,-31.6383,Morro Redondo,ADM2,DISTRICT
SM265771,-51.0186,-29.5157,Morro Reuter,ADM2,DISTRICT
SM266215,-49.2624,-28.6386,Morro da Fumaça,ADM2,DISTRICT
SM263677,-44.6324,-18.6175,Morro da Garça,ADM2,DISTRICT
SM267545,-41.3636,-11.2541,Morro do Chapéu,ADM2,DISTRICT
SM265064,-42.2219,-3.6869,Morro do Chapéu do Piauí,ADM2,DISTRICT
SM263678,-43.4014,-19.2337,Morro do Pilar,ADM2,DISTRICT
SM270530,-75.3303,9.3961,Morroa,ADM2,DISTRICT
SM459413,143.535,-14.2519,Morrobolam,ETHNICITY,ETHNICITY
SM286815,-80,-5.25,Morropón,ADM2,DISTRICT
SM262830,-43.8772,-2.9616,Morros,ADM2,DISTRICT
SM302266,-119.5843,45.4188,Morrow,ADM2,DISTRICT
SM302139,-82.7941,40.5241,Morrow,ADM2,DISTRICT
SM15073,35.591,-17.329,Morrumbala District (2013-),ADM2,DISTRICT
SM283914,35.3236,-23.6175,Morrumbene,ADM2,DISTRICT
SM18251,23.8694,49.1544,Morshyns'ka Mis'krada,ADM2,DISTRICT
SM271717,8.7327,56.7962,Morsø,ADM2,DISTRICT
SM290286,25.2521,44.6622,Morteni,ADM2,DISTRICT
SM238729,153.7108,5.5074,Mortlockese,LANGUAGE,LANGUAGE
SM302053,-101.2812,46.7161,Morton,ADM2,DISTRICT
SM300950,-101.7993,37.1914,Morton,ADM2,DISTRICT
SM267546,-42.4056,-14.9848,Mortugaba,ADM2,DISTRICT
SM289417,-8.2581,40.3943,Mortágua,ADM2,DISTRICT
SM238733,30.3287,5.1232,Moru,LANGUAGE,LANGUAGE
SM250525,29.97,5.53,Moru,ETHNICITY,ETHNICITY
SM250525,29.97,5.53,Moru,ETHNICITY,ETHNICITY
SM250525,30,5,Moru,ETHNICITY,ETHNICITY
SM250525,30,5,Moru,ETHNICITY,ETHNICITY
SM266720,-46.787,-22.8832,Morungaba,ADM2,DISTRICT
SM291431,24.1169,44.4727,Morunglav,ADM2,DISTRICT
SM280942,-9.4167,5.9167,Morweh,ADM2,DISTRICT
SM370202,-9.2452,6.2619,Morweh Doedian,ADM2:ADM3,DISTRICT
SM303607,-69.8333,9.6667,Morán,ADM2,DISTRICT
SM271076,-78.5688,22.2998,Morón,ADM2,DISTRICT
SM293077,34.8333,54.5833,Mosal'skiy rayon,ADM2,DISTRICT
SM461274,37.6067,55.7617,Moscow City (2012-),ADM1,DISTRICT
SM274498,6.5836,49.0418,Moselle,ADM2,DISTRICT
SM236844,6.1493,49.6839,Moselle Franconian,LANGUAGE,LANGUAGE
SM284519,17.039,-22.5074,Moses Garoeb,ADM2,DISTRICT
SM255912,-67.11,-15.08,Moseten,ETHNICITY,ETHNICITY
SM238736,-67.8376,-14.6445,Mosetén-Chimané,LANGUAGE,LANGUAGE
SM277786,-21.65,64.15,Mosfellsbær,ADM2,DISTRICT
SM10923,32.6259,35.178,Mosfili,ADM2,DISTRICT
SM10924,33.4308,34.9526,Mosfiloti,ADM2,DISTRICT
SM293820,34.75,58.5,Moshenskiy rayon,ADM2,DISTRICT
SM299250,37.459,-3.362,Moshi Rural,ADM2,DISTRICT
SM299251,37.341,-3.352,Moshi Urban,ADM2,DISTRICT
SM293871,83.6167,55.3,Moshkovskiy rayon,ADM2,DISTRICT
SM238739,145.605,-4.9523,Mosimo,LANGUAGE,LANGUAGE
SM286072,13.0911,67.9347,Moskenes,ADM2,DISTRICT
SM238740,133.145,-1.622,Moskona,LANGUAGE,LANGUAGE
SM17006,30.3298,59.8545,Moskovskiy Rayon,ADM2,DISTRICT
SM280313,74.1259,42.7446,Moskovsky,ADM2,DISTRICT
SM305951,69.6521,37.7036,Moskva,ADM2,DISTRICT
SM461275,36.7163,55.8136,Moskva (2012-),ADM1,DISTRICT
SM261584,151.2487,-33.8284,Mosman,ADM2,DISTRICT
SM261417,115.7661,-32.0142,Mosman Park,ADM2,DISTRICT
SM291736,24.4251,46.0753,Mosna,ADM2,DISTRICT
SM290848,27.9596,46.9219,Mosna,ADM2,DISTRICT
SM292001,21.324,45.714,Mosnita Noua,ADM2,DISTRICT
SM291267,24.7996,44.8301,Mosoaia,ADM2,DISTRICT
SM276267,17.2821,47.8577,Mosonmagyaróvár,ADM2,DISTRICT
SM255913,-86.4,40.14,Mosopelea,ETHNICITY,ETHNICITY
SM270331,-78.4431,2.4914,Mosquera,ADM2,DISTRICT
SM270025,-74.236,4.6894,Mosquera,ADM2,DISTRICT
SM267264,-47.6626,-6.613,Mosquito,ADM2,DISTRICT
SM15957,-65.5194,18.1257,Mosquito Barrio,ADM2,DISTRICT
SM461894,10.6597,59.4344,Moss (2020-),ADM2,DISTRICT
SM16336,16.5847,-0.7276,Mossaka (1994-),ADM2,DISTRICT
SM10188,-100.0008,51.6667,Mossey River,ADM2,DISTRICT
SM238741,-1.32,11.95,Mossi,LANGUAGE,LANGUAGE
SM251056,-0.66,12.7,Mossi,ETHNICITY,ETHNICITY
SM251056,-0.66,12.7,Mossi,ETHNICITY,ETHNICITY
SM251056,-0.66,12.7,Mossi,ETHNICITY,ETHNICITY
SM251056,-2,13,Mossi,ETHNICITY,ETHNICITY
SM251056,-2,13,Mossi,ETHNICITY,ETHNICITY
SM251056,-2,13,Mossi,ETHNICITY,ETHNICITY
SM251056,-2,12,Mossi,ETHNICITY,ETHNICITY
SM251056,-2,12,Mossi,ETHNICITY,ETHNICITY
SM251056,-2,12,Mossi,ETHNICITY,ETHNICITY
SM255914,-2,13,Mossi (Yatenga),ETHNICITY,ETHNICITY
SM265592,-37.2785,-5.1381,Mossoró,ADM2,DISTRICT
SM15074,40.5144,-15.0618,Mossuril (2013-),ADM2,DISTRICT
SM283921,32.9437,-20.5126,Mossurize,ADM2,DISTRICT
SM268098,-50.17,-16.165,Mossâmedes,ADM2,DISTRICT
SM271199,13.6333,50.55,Most,ADM2,DISTRICT
SM2310,0.3333,36,Mostaganem,ADM1,DISTRICT
SM457598,0.6667,35.9167,Mostaganem District,ADM2,DISTRICT
SM313232,18.0083,43.4206,Mostar,ADM2:ADM3,DISTRICT
SM265772,-50.8542,-30.9223,Mostardas,ADM2,DISTRICT
SM2311,-24.35,15,Mosteiros,ADM1,DISTRICT
SM291903,25.5085,44.1979,Mosteni,ADM2,DISTRICT
SM293382,40.6459,44.275,Mostovskiy rayon,ADM2,DISTRICT
SM305799,43.0994,36.3579,Mosul,ADM2,DISTRICT
SM238746,167.6909,-13.8354,Mota,LANGUAGE,LANGUAGE
SM255915,167.7,-13.84,Mota,ETHNICITY,ETHNICITY
SM290287,25.4105,45.1063,Motaieni,ADM2,DISTRICT
SM296425,15.1911,58.6513,Motala,ADM2,DISTRICT
SM290386,23.1886,44.0845,Motatei,ADM2,DISTRICT
SM303763,-70.5912,9.39,Motatán,ADM2,DISTRICT
SM270791,-73.3677,5.5784,Motavita,ADM2,DISTRICT
SM290850,26.6163,47.2241,Motca,ADM2,DISTRICT
SM246362,20.3685,2.0246,Motembo-Kunda-Buja,LANGUAGE,LANGUAGE
SM17568,-61.0232,13.8133,Motete,ADM2,DISTRICT
SM13985,28.584,-28.8648,Motete,ADM2,DISTRICT
SM10925,33.2109,35.3314,Motides,ADM2,DISTRICT
SM296686,31.0226,-26.2976,Motjane,ADM2,DISTRICT
SM302721,-100.7798,34.0741,Motley,ADM2,DISTRICT
SM279754,140.3984,37.5122,Motomiya,ADM2,DISTRICT
SM292128,27.4172,46.3278,Motoseni,ADM2,DISTRICT
SM279804,136.6199,35.6555,Motosu,ADM2,DISTRICT
SM305668,13.8298,45.3363,Motovun,ADM2,DISTRICT
SM13027,141.4384,38.6805,Motoyoshi Gun,ADM2,DISTRICT
SM283168,-92.3434,15.2975,Motozintla,ADM2,DISTRICT
SM290639,22.9823,44.8142,Motru,ADM2,DISTRICT
SM238752,147.424,-9.6749,Motu,LANGUAGE,LANGUAGE
SM17995,-174.0999,-18.7451,Motu,ADM2,DISTRICT
SM255918,147.15,-9.3,Motu,ETHNICITY,ETHNICITY
SM255918,147.15,-9.3,Motu,ETHNICITY,ETHNICITY
SM255918,147,-9,Motu,ETHNICITY,ETHNICITY
SM255918,147,-9,Motu,ETHNICITY,ETHNICITY
SM266722,-48.1567,-21.4894,Motuca,ADM2,DISTRICT
SM282986,-89.285,21.121,Motul,ADM2,DISTRICT
SM293442,94.6667,58.1667,Motyginskiy rayon,ADM2,DISTRICT
SM283982,-12.1997,17.8677,Moudjéria,ADM2,DISTRICT
SM4319,10.7499,-2.0775,Mougalaba,ADM2,DISTRICT
SM4312,11.237,-2.974,Mougoutsi (1996-),ADM2,DISTRICT
SM262182,-3.4167,12.25,Mouhoun (1995-),ADM1:ADM2,DISTRICT
SM238755,149.158,-5.7116,Mouk-Aria,LANGUAGE,LANGUAGE
SM17569,-60.9484,13.7146,Moule A Chique,ADM2,DISTRICT
SM274575,13.009,-0.541,Mouloudnou,ADM2,DISTRICT
SM300660,-88.6193,39.6415,Moultrie,ADM2,DISTRICT
SM255921,14,10,Moundang,ETHNICITY,ETHNICITY
SM10107,103.42,12.78,Moung Ruessei,ADM2,DISTRICT
SM269525,9.7686,4.673,Moungo,ADM2,DISTRICT
SM16337,12.6168,-1.9908,Moungoundou-Nord,ADM2,DISTRICT
SM16338,12.7314,-2.6759,Moungoundou-Sud,ADM2,DISTRICT
SM12551,-77.3636,18.0184,Mount Airy (Clarendon),ADM2,DISTRICT
SM18516,-78.3228,18.2474,Mount Airy (Westmoreland),ADM2,DISTRICT
SM261265,144.1883,-37.0761,Mount Alexander,ADM2,DISTRICT
SM261711,138.8694,-35.0691,Mount Barker,ADM2,DISTRICT
SM17570,-60.934,13.9065,Mount Beaujolais,ADM2,DISTRICT
SM12552,-77.9437,18.3889,Mount Carey,ADM2,DISTRICT
SM305241,31.6858,-16.6079,Mount Darwin,ADM2,DISTRICT
SM17571,-60.912,13.8602,Mount Erole,ADM2,DISTRICT
SM261712,140.7819,-37.8247,Mount Gambier,ADM2,DISTRICT
SM288610,144.2833,-5.8333,Mount Hagen,ADM2,DISTRICT
SM12553,-77.8668,18.3332,Mount Horeb,ADM2,DISTRICT
SM261649,139.0144,-19.7483,Mount Isa,ADM2,DISTRICT
SM12554,-76.7739,18.1207,Mount James,ADM2,DISTRICT
SM2312,35.6667,33.9167,Mount Lebanon,ADM1,DISTRICT
SM12555,-76.5389,17.9754,Mount Lebanus,ADM2,DISTRICT
SM261418,117.9666,-28.2908,Mount Magnet,ADM2,DISTRICT
SM261419,118.0006,-30.2938,Mount Marshall,ADM2,DISTRICT
SM12556,-77.4409,18.2062,Mount Moriah,ADM2,DISTRICT
SM12557,-78.0229,18.3575,Mount Peto,ADM2,DISTRICT
SM17572,-60.9858,14.0149,Mount Pleasant,ADM2,DISTRICT
SM12558,-76.5047,18.1633,Mount Pleasant,ADM2,DISTRICT
SM261713,138.1713,-32.7181,Mount Remarkable,ADM2,DISTRICT
SM12559,-77.9079,18.466,Mount Salem,ADM2,DISTRICT
SM12560,-77.9927,18.3041,Mount Stewart,ADM2,DISTRICT
SM268751,-126.0851,51.2885,Mount Waddington,ADM2,DISTRICT
SM12561,-77.2803,18.4338,Mount Zion,ADM2,DISTRICT
SM255922,-125.37,63.68,Mountain Dene,ETHNICITY,ETHNICITY
SM238758,147.384,-9.0697,Mountain Koiali,LANGUAGE,LANGUAGE
SM256268,141.06,-5.25,Mountain Ok,ETHNICITY,ETHNICITY
SM2313,121.1667,17.0833,Mountain Province,ADM1:ADM2,DISTRICT
SM323908,-114.2387,51.6787,Mountain View County,ADM2:ADM3,DISTRICT
SM12562,-76.7607,17.9919,Mountain View Gardens,ADM2,DISTRICT
SM12563,-77.7479,17.9808,Mountainside,ADM2,DISTRICT
SM302054,-102.3557,48.2013,Mountrail,ADM2,DISTRICT
SM289420,-7.2968,38.1315,Moura,ADM2,DISTRICT
SM10325,22.5687,17.8295,Mourdi,ADM2,DISTRICT
SM255924,24.05,19.37,Mourdia,ETHNICITY,ETHNICITY
SM10326,20.8513,15.9439,Mourtcha,ADM2,DISTRICT
SM289499,-7.2223,38.2978,Mourão,ADM2,DISTRICT
SM10926,32.7082,34.7651,Mousere,ADM2,DISTRICT
SM250536,15,11,Mousgoum,ETHNICITY,ETHNICITY
SM10927,33.6435,35.1916,Mousoulita,ADM2,DISTRICT
SM16339,12.6359,-3.0817,Moutamba,ADM2,DISTRICT
SM10928,32.8335,34.9823,Moutoutllas,ADM2,DISTRICT
SM10929,33.1051,34.7221,Mouttagiaka,ADM2,DISTRICT
SM238764,143.808,-7.442,Mouwase,LANGUAGE,LANGUAGE
SM16340,13.955,-3.919,Mouyondzi,ADM2,DISTRICT
SM238766,145.42,-6.358,Move,DIALECT,LANGUAGE
SM290922,27.7177,44.5375,Movila,ADM2,DISTRICT
SM289893,26.703,44.9761,Movila Banului,ADM2,DISTRICT
SM292684,27.6037,45.1957,Movila Miresii,ADM2,DISTRICT
SM291432,24.6408,44.3837,Movileni,ADM2,DISTRICT
SM290851,27.3802,47.3203,Movileni,ADM2,DISTRICT
SM290452,27.3759,45.7658,Movileni,ADM2,DISTRICT
SM292357,27.1049,45.9575,Movilita,ADM2,DISTRICT
SM238767,-65.6348,-13.8115,Movima,LANGUAGE,LANGUAGE
SM255925,-65.93,-13.94,Movima,ETHNICITY,ETHNICITY
SM246091,166.958,-15.5643,Movono,LANGUAGE,LANGUAGE
SM277984,36.2475,31.7952,Mowaqqar,ADM2,DISTRICT
SM301384,-92.7525,43.6715,Mower,ADM2,DISTRICT
SM260366,19.7338,-12.2362,Moxico,ADM2,DISTRICT
SM2314,20.9327,-12.8487,Moxico,ADM1,DISTRICT
SM262714,-65.6762,-15.5003,Moxos,ADM2,DISTRICT
SM238772,120.702,17.4232,Moyadan Itneg,LANGUAGE,LANGUAGE
SM283064,-103.1677,21.1793,Moyahua de Estrada,ADM2,DISTRICT
SM280117,38.844,3.093,Moyale,ADM2,DISTRICT
SM295494,-12.444,8.0629,Moyamba,ADM1:ADM2,DISTRICT
SM2315,18.5,9.3333,Moyen-Chari (2008-),ADM1,DISTRICT
SM2316,10.5833,-0.4167,Moyen-Ogooué,ADM1,DISTRICT
SM261266,142.4622,-38.1879,Moyne,ADM2,DISTRICT
SM2317,31.72,3.65,Moyo (1997-),ADM1:ADM2,DISTRICT
SM286841,-77.25,-5.75,Moyobamba,ADM2,DISTRICT
SM285483,-85.6733,11.5263,Moyogalpa,ADM2,DISTRICT
SM238773,94.3421,24.4793,Moyon,LANGUAGE,LANGUAGE
SM275183,-90.1267,13.9051,Moyuta,ADM2,DISTRICT
SM279905,73,45,Moyynkumskiy,ADM2,DISTRICT
SM291268,25.1749,44.5637,Mozaceni,ADM2,DISTRICT
SM259047,38.892,-12.923,Mozambican Ngoni,LANGUAGE,LANGUAGE
SM238776,34.613,-19.811,Mozambican Sign Language,LANGUAGE,LANGUAGE
SM236512,37.2437,-16.0381,Mozambique Lomwe,LANGUAGE,LANGUAGE
SM238777,-1.7972,37.8763,Mozarabic,LANGUAGE,LANGUAGE
SM268100,-50.6296,-14.765,Mozarlândia,ADM2,DISTRICT
SM293813,44.5833,43.75,Mozdokskiy rayon,ADM2,DISTRICT
SM17007,36,55.5,Mozhayskiy Rayon,ADM2,DISTRICT
SM4128,14.958,46.3612,Mozirje (2006-),ADM1,DISTRICT
SM285471,-86.4134,13.6751,Mozonte,ADM2,DISTRICT
SM268916,9.1787,46.352,Moësa,ADM2,DISTRICT
SM269932,-76.1359,9.2262,Moñitos,ADM2,DISTRICT
SM288680,22.7814,53.4456,Mońki,ADM2,DISTRICT
SM305417,14.2536,45.24,Mošćenička Draga,ADM2,DISTRICT
SM238780,14.4469,12.5614,Mpade,LANGUAGE,LANGUAGE
SM459259,141.9332,-12.284,Mpakwithi,ETHNICITY,ETHNICITY
SM459385,142.2782,-12.1023,Mpalitjanh,ETHNICITY,ETHNICITY
SM238782,142.4853,-12.1673,Mpalityanh-Luthigh,LANGUAGE,LANGUAGE
SM238785,17.6857,-0.7392,Mpama,LANGUAGE,LANGUAGE
SM299239,30.5835,-6.026,Mpanda,ADM2,DISTRICT
SM261961,29.4,-3.2,Mpanda,ADM2,DISTRICT
SM299238,30.9854,-6.4029,Mpanda Urban,ADM2,DISTRICT
SM274553,13.61,-1.711,Mpassa,ADM2,DISTRICT
SM296687,31.3679,-26.201,Mphalaleni,ADM2,DISTRICT
SM296697,31.8292,-26.5889,Mpholonjeni,ADM2,DISTRICT
SM238789,100.21,18.1717,Mpi,LANGUAGE,LANGUAGE
SM238787,15.5246,3.4578,Mpiemo,LANGUAGE,LANGUAGE
SM299359,32.199,0.0729,Mpigi (2010-),ADM1:ADM2,DISTRICT
SM305192,31.2647,-12.0212,Mpika,ADM2,DISTRICT
SM238790,13.72,-10.76,Mpinda,LANGUAGE,LANGUAGE
SM262008,30.1399,-3.8049,Mpinga-Kayove,ADM2,DISTRICT
SM4408,-1.8539,5.0614,Mpohor,ADM2,DISTRICT
SM255931,29.39,-31.02,Mpondomise,ETHNICITY,ETHNICITY
SM238795,14.866,3.1402,Mpongmpong,LANGUAGE,LANGUAGE
SM250529,9.91,0.82,Mpongwe,ETHNICITY,ETHNICITY
SM250529,9.91,0.82,Mpongwe,ETHNICITY,ETHNICITY
SM250529,10,-2,Mpongwe,ETHNICITY,ETHNICITY
SM250529,10,-2,Mpongwe,ETHNICITY,ETHNICITY
SM305206,30.1978,-9.6869,Mporokoso,ADM2,DISTRICT
SM238798,34.7127,-11.1377,Mpoto,LANGUAGE,LANGUAGE
SM238799,167.232,-15.8988,Mpotovoro,LANGUAGE,LANGUAGE
SM16341,16.0241,-2.6394,Mpouya,ADM2,DISTRICT
SM238800,-1.25,8.867,Mpra,LANGUAGE,LANGUAGE
SM305207,30.794,-8.982,Mpulungu,ADM2,DISTRICT
SM2319,30,-26,Mpumalanga,ADM1,DISTRICT
SM284509,18.2333,-17.6667,Mpungu,ADM2,DISTRICT
SM238802,133.166,-0.7489,Mpur,LANGUAGE,LANGUAGE
SM255932,132.37,-0.51,Mpur,ETHNICITY,ETHNICITY
SM299213,36.3215,-6.6826,Mpwapwa,ADM2,DISTRICT
SM283468,93.355,20.4766,Mrauk-U,ADM2,DISTRICT
SM9862,16.8856,44.2306,Mrkonjic Grad Jug Opcina,ADM2,DISTRICT
SM305418,14.8518,45.3142,Mrkopalj,ADM2,DISTRICT
SM238810,93.995,19.71,Mro Chin,LANGUAGE,LANGUAGE
SM238813,90.6317,24.154,Mru,LANGUAGE,LANGUAGE
SM255934,91.77,22.86,Mru,ETHNICITY,ETHNICITY
SM288769,21.3976,53.8106,Mrągowo,ADM2,DISTRICT
SM262632,31.5,54,Ms'tsislaw,ADM2,DISTRICT
SM238814,15.0373,11.9765,Mser,LANGUAGE,LANGUAGE
SM238817,123.552,13.4588,Mt. Iraya Agta,LANGUAGE,LANGUAGE
SM238816,123.463,13.4566,Mt. Iriga Agta,LANGUAGE,LANGUAGE
SM296726,31.3967,-26.9644,Mtsambama,ADM2,DISTRICT
SM2320,45.0692,-12.7001,Mtsamboro,ADM1,DISTRICT
SM11328,44.7061,41.8407,Mtskheta,ADM2,DISTRICT
SM2321,44.75,42.2833,Mtskheta-Mtianeti,ADM1,DISTRICT
SM11329,44.6667,41.8667,Mtskhetis Munitsip'alit'et'I (excl. Mtskheta),ADM2,DISTRICT
SM2322,39.1333,-10.8,Mtwara,ADM1,DISTRICT
SM299123,39.9881,-10.4794,Mtwara Rural,ADM2,DISTRICT
SM299124,40.166,-10.2933,Mtwara Urban,ADM2,DISTRICT
SM17996,-174.6293,-20.2786,Mu'omu'a,ADM2,DISTRICT
SM304689,-176.2067,-13.321,Mua,ADM2,DISTRICT
SM245887,100.985,21.4293,Muak,LANGUAGE,LANGUAGE
SM297542,101.2715,14.7771,Muak Lek,ADM2,DISTRICT
SM238820,111.077,0.3108,Mualang,LANGUAGE,LANGUAGE
SM458006,126.4404,34.9564,Muan,ADM2,DISTRICT
SM296868,104.6428,15.8786,Muang Amnat Charoen,ADM2,DISTRICT
SM297023,100.4439,14.5782,Muang Ang Thong,ADM2,DISTRICT
SM297413,103.0624,14.9408,Muang Buri Ram,ADM2,DISTRICT
SM297530,101.0215,13.7176,Muang Chachoengsao,ADM2,DISTRICT
SM297667,100.1354,15.191,Muang Chai Nat,ADM2,DISTRICT
SM297768,102.0372,15.8616,Muang Chaiyaphum,ADM2,DISTRICT
SM297555,104.0247,15.1825,Muang Chan,ADM2,DISTRICT
SM297790,102.1226,12.5954,Muang Chanthaburi,ADM2,DISTRICT
SM296879,98.9687,18.7901,Muang Chiang Mai,ADM2,DISTRICT
SM296898,99.773,19.9082,Muang Chiang Rai,ADM2,DISTRICT
SM296921,101.0011,13.3427,Muang Chon Buri,ADM2,DISTRICT
SM296927,99.1061,10.4608,Muang Chumphon,ADM2,DISTRICT
SM280859,103.85,20.71,Muang Et,ADM2,DISTRICT
SM13374,104.5953,17.8767,Muang Hinboun (excl. Kounkham),ADM2,DISTRICT
SM296935,103.5498,16.5092,Muang Kalasin,ADM2,DISTRICT
SM296970,99.3277,14.0699,Muang Kanchanaburi,ADM2,DISTRICT
SM13375,104.9214,18.3523,Muang Khamkeut (excl. Xaychamphone),ADM2,DISTRICT
SM296979,102.8087,16.4605,Muang Khon Kaen,ADM2,DISTRICT
SM297006,98.8666,8.1595,Muang Krabi,ADM2,DISTRICT
SM297019,99.5439,18.389,Muang Lampang,ADM2,DISTRICT
SM297034,99.0591,18.5522,Muang Lamphun,ADM2,DISTRICT
SM297046,101.7193,17.5421,Muang Loei,ADM2,DISTRICT
SM297058,100.6756,14.8233,Muang Lop Buri,ADM2,DISTRICT
SM297065,98.0061,19.2886,Muang Mae Hong Son,ADM2,DISTRICT
SM297080,103.3116,16.1188,Muang Maha Sarakam,ADM2,DISTRICT
SM297085,104.6461,16.5511,Muang Mukdahan,ADM2,DISTRICT
SM297090,101.2338,14.2773,Muang Nakhon Nayok,ADM2,DISTRICT
SM297096,100.0238,13.8194,Muang Nakhon Pathom,ADM2,DISTRICT
SM297105,104.6823,17.3197,Muang Nakhon Phanom,ADM2,DISTRICT
SM297124,102.0893,14.9568,Muang Nakhon Ratchasima,ADM2,DISTRICT
SM297208,100.1007,15.7199,Muang Nakhon Sawan,ADM2,DISTRICT
SM297212,99.9854,8.4401,Muang Nakhon Si Thammarat,ADM2,DISTRICT
SM297244,100.6747,18.8901,Muang Nan,ADM2,DISTRICT
SM297254,101.8132,6.3939,Muang Narathiwat,ADM2,DISTRICT
SM297260,102.3986,17.1582,Muang Nong Bua Lam Phu,ADM2,DISTRICT
SM297270,102.7891,17.8466,Muang Nong Khai,ADM2,DISTRICT
SM297278,100.4926,13.8558,Muang Nonthaburi,ADM2,DISTRICT
SM297284,100.5355,13.9911,Muang Pathum Thani,ADM2,DISTRICT
SM297296,101.2676,6.8558,Muang Pattani,ADM2,DISTRICT
SM297303,98.5078,8.4935,Muang Phangnga,ADM2,DISTRICT
SM297324,100.0743,7.6025,Muang Phatthalung,ADM2,DISTRICT
SM297335,99.8733,19.1486,Muang Phayao,ADM2,DISTRICT
SM297344,101.1765,16.3822,Muang Phetchabun,ADM2,DISTRICT
SM297353,99.9672,13.0817,Muang Phetchaburi,ADM2,DISTRICT
SM297363,100.3458,16.4099,Muang Phichit,ADM2,DISTRICT
SM297371,100.273,16.8404,Muang Phitsanulok,ADM2,DISTRICT
SM297394,100.2562,18.1226,Muang Phrae,ADM2,DISTRICT
SM297401,98.3718,7.8561,Muang Phuket,ADM2,DISTRICT
SM297405,101.3978,14.0934,Muang Prachin Buri,ADM2,DISTRICT
SM297438,99.7356,11.8714,Muang Prachuap Khiri Khan,ADM2,DISTRICT
SM297445,98.6223,9.8624,Muang Ranong,ADM2,DISTRICT
SM297452,99.7768,13.5456,Muang Ratchaburi,ADM2,DISTRICT
SM297461,101.3449,12.7186,Muang Rayong,ADM2,DISTRICT
SM297483,103.6158,16.015,Muang Roi Et,ADM2,DISTRICT
SM297489,102.1063,13.9297,Muang Sa Kaeo,ADM2,DISTRICT
SM297509,104.1052,17.1616,Muang Sakon Nakhon,ADM2,DISTRICT
SM297692,104.7166,15.5264,Muang Samsip,ADM2,DISTRICT
SM297514,100.6515,13.5686,Muang Samut Prakan,ADM2,DISTRICT
SM297519,100.2533,13.5316,Muang Samut Sakhon,ADM2,DISTRICT
SM297522,99.9993,13.3959,Muang Samut Songkhram,ADM2,DISTRICT
SM297543,100.9383,14.4972,Muang Saraburi,ADM2,DISTRICT
SM297551,100.0668,6.6231,Muang Satun,ADM2,DISTRICT
SM297556,104.3513,15.0817,Muang Si Sa Ket,ADM2,DISTRICT
SM297579,100.3973,14.9024,Muang Sing Buri,ADM2,DISTRICT
SM297596,100.6081,7.1108,Muang Songkhla,ADM2,DISTRICT
SM297465,103.7507,15.7862,Muang Suang,ADM2,DISTRICT
SM297602,99.7786,17.0232,Muang Sukhothai,ADM2,DISTRICT
SM297613,100.0746,14.4919,Muang Suphanburi,ADM2,DISTRICT
SM297622,99.3261,9.1013,Muang Surat Thani,ADM2,DISTRICT
SM297638,103.5127,14.8836,Muang Surin,ADM2,DISTRICT
SM297658,99.1662,16.8765,Muang Tak,ADM2,DISTRICT
SM13377,100.2962,20.5347,Muang Tonpheung,ADM2,DISTRICT
SM297675,99.6177,7.6094,Muang Trang,ADM2,DISTRICT
SM297687,102.6095,12.2667,Muang Trat,ADM2,DISTRICT
SM297693,104.8305,15.2966,Muang Ubon Ratchatani,ADM2,DISTRICT
SM297731,102.7891,17.3909,Muang Udon Thani,ADM2,DISTRICT
SM297736,100.0279,15.4086,Muang Uthai Thani,ADM2,DISTRICT
SM297744,100.1996,17.6814,Muang Uttaradit,ADM2,DISTRICT
SM13378,103.0411,20.4911,Muang Viangkhan (excl. Phonthong),ADM2,DISTRICT
SM13379,104.5456,19.9325,Muang Xam-Tai (excl. Kuan),ADM2,DISTRICT
SM297754,101.2511,6.5438,Muang Yala,ADM2,DISTRICT
SM238821,105.1823,23.632,Muangphe,LANGUAGE,LANGUAGE
SM283968,34.7968,-18.8996,Muanza,ADM2,DISTRICT
SM264196,-49.3167,-1.2489,Muaná,ADM2,DISTRICT
SM284318,102.75,2.25,Muar,ADM2,DISTRICT
SM276751,103.8216,-1.5521,Muaro Jambi,ADM2,DISTRICT
SM238823,142.998,-7.5501,Mubami,LANGUAGE,LANGUAGE
SM2323,48.05,29.225,Mubarak Al-Kabeer,ADM1,DISTRICT
SM4733,31.5,0.5,Mubende (2005-),ADM1:ADM2,DISTRICT
SM238825,19.5902,12.6893,Mubi,LANGUAGE,LANGUAGE
SM284837,13.4065,10.3498,Mubi North,ADM2,DISTRICT
SM284838,13.3958,10.1874,Mubi South,ADM2,DISTRICT
SM262040,29.484,-3.2957,Mubimbi,ADM2,DISTRICT
SM303523,65.2705,39.3172,Muborak,ADM2,DISTRICT
SM260387,15.0884,-7.1652,Mucaba,ADM2,DISTRICT
SM266138,-62.0634,2.5296,Mucajaí,ADM2,DISTRICT
SM267802,-40.7694,-3.9078,Mucambo,ADM2,DISTRICT
SM15958,-66.2237,18.3836,Mucarabones Barrio,ADM2,DISTRICT
SM260347,16.902,-9.4692,Mucari,ADM2,DISTRICT
SM2325,32.0773,-10.5439,Muchinga,ADM1,DISTRICT
SM260342,21.4418,-10.0454,Muconda,ADM2,DISTRICT
SM267547,-41.5174,-13.1487,Mucugê,ADM2,DISTRICT
SM298658,34.3867,39.0715,Mucur,ADM2,DISTRICT
SM267548,-39.7981,-18.0058,Mucuri,ADM2,DISTRICT
SM268010,-40.5253,-18.0137,Mucurici,ADM2,DISTRICT
SM238829,100.6,21.95,Muda,LANGUAGE,LANGUAGE
SM458052,123.6549,-27.7522,Mudalga,ETHNICITY,ETHNICITY
SM269158,130.0134,44.635,Mudanjiang,ADM2,DISTRICT
SM298246,28.7722,40.3334,Mudanya,ADM2,DISTRICT
SM255937,131.9096,-17.4403,Mudbara,ETHNICITY,ETHNICITY
SM238830,132.329,-16.7694,Mudburra,LANGUAGE,LANGUAGE
SM304874,43.9687,13.8661,Mudhaykhirah,ADM2,DISTRICT
SM238831,83.378,18.276,Mudhili Gadaba,LANGUAGE,LANGUAGE
SM304958,46.2196,13.9096,Mudiyah,ADM2,DISTRICT
SM238835,75.1061,12.6224,Mudu Koraga,LANGUAGE,LANGUAGE
SM238836,149.489,-4.6975,Muduapa,LANGUAGE,LANGUAGE
SM2326,48,7,Mudug (pre-2016),ADM1,DISTRICT
SM238833,76.5627,11.062,Muduga,LANGUAGE,LANGUAGE
SM298222,31.2141,40.4834,Mudurnu,ADM2,DISTRICT
SM305247,32.6528,-17.0471,Mudzi,ADM2,DISTRICT
SM296959,99.4883,16.4325,Mueang Kamphaeng Phet,ADM2,DISTRICT
SM297020,99.4541,18.7909,Mueang Pan,ADM2,DISTRICT
SM283937,39.6273,-14.8864,Muecate,ADM2,DISTRICT
SM283863,39.5534,-11.6624,Mueda,ADM2,DISTRICT
SM285494,-84.5838,12.1349,Muelle de los Bueyes,ADM2,DISTRICT
SM283954,35.6489,-13.0842,Muembe,ADM2,DISTRICT
SM292211,24.2973,45.2036,Muereasca,ADM2,DISTRICT
SM238840,142.955,-3.5793,Mufian,LANGUAGE,LANGUAGE
SM299225,35.2082,-8.5953,Mufindi,ADM2,DISTRICT
SM305170,28.2385,-12.5244,Mufulira,ADM2,DISTRICT
SM305197,25.1439,-13.7621,Mufumbwe,ADM2,DISTRICT
SM262050,29.5849,-3.6723,Mugamba,ADM2,DISTRICT
SM13213,58.1667,48.8333,Mughalzhar Audany (excl. Oktyabr'sk),ADM2,DISTRICT
SM255943,19.59,29.65,Mugharbah,ETHNICITY,ETHNICITY
SM295463,160.0153,-11.5278,Mugihenua,ADM2,DISTRICT
SM262062,29.1281,-2.7942,Mugina,ADM2,DISTRICT
SM2327,28.3557,37.2303,Mugla,ADM1,DISTRICT
SM238849,79.4664,30.8983,Mugom,LANGUAGE,LANGUAGE
SM262041,29.5485,-3.4414,Mugongomanga,ADM2,DISTRICT
SM375346,82.5308,29.6783,Mugu,ADM2:ADM3,DISTRICT
SM17797,41.9333,18.5,Muha'il,ADM2,DISTRICT
SM260113,69.1796,34.2097,Muhammad Agha,ADM2,DISTRICT
SM295144,29.7181,-1.9391,Muhanga,ADM1:ADM2,DISTRICT
SM262089,29.8034,-3.0445,Muhanga,ADM2,DISTRICT
SM296781,36.5484,35.3002,Muhardeh,ADM2,DISTRICT
SM461323,50.6167,26.25,Muharraq (2014-),ADM1,DISTRICT
SM299201,38.7955,-5.173,Muheza,ADM2,DISTRICT
SM301096,-87.142,37.2158,Muhlenberg,ADM2,DISTRICT
SM260120,70.9842,34.2048,Muhmand Dara,ADM2,DISTRICT
SM274144,23.2561,58.5849,Muhu,ADM2,DISTRICT
SM250306,28,-2,Muhunde,ETHNICITY,ETHNICITY
SM262042,29.4183,-3.6223,Muhuta,ADM2,DISTRICT
SM283850,39.8369,-11.8338,Muidumbe,ADM2,DISTRICT
SM238855,-72.4249,-0.8652,Muinane,LANGUAGE,LANGUAGE
SM252799,-73.91,6.9,Muisca,ETHNICITY,ETHNICITY
SM252799,-73.91,6.9,Muisca,ETHNICITY,ETHNICITY
SM252799,-73.91,6.9,Muisca,ETHNICITY,ETHNICITY
SM252799,-74,5,Muisca,ETHNICITY,ETHNICITY
SM252799,-74,5,Muisca,ETHNICITY,ETHNICITY
SM252799,-74,5,Muisca,ETHNICITY,ETHNICITY
SM252799,-72,5,Muisca,ETHNICITY,ETHNICITY
SM252799,-72,5,Muisca,ETHNICITY,ETHNICITY
SM252799,-72,5,Muisca,ETHNICITY,ETHNICITY
SM238735,95.9001,27.0964,Muishaung,DIALECT,LANGUAGE
SM273632,-79.9544,0.5809,Muisne,ADM2,DISTRICT
SM265775,-51.2469,-28.4345,Muitos Capões,ADM2,DISTRICT
SM280576,127.7112,35.9317,Muju,ADM2,DISTRICT
SM18252,22.7231,48.437,Mukachevo Raion,ADM2,DISTRICT
SM305006,45.7964,14.032,Mukayras,ADM2,DISTRICT
SM2329,104.5,16.5833,Mukdahan,ADM1,DISTRICT
SM280517,104.9409,11.7544,Mukh Kampul,ADM2,DISTRICT
SM238860,81.5663,17.4467,Mukha-Dora,LANGUAGE,LANGUAGE
SM295114,107.75,51.0833,Mukhorshibirskiy rayon,ADM2,DISTRICT
SM262043,29.5139,-3.5646,Mukike,ADM2,DISTRICT
SM261420,118.2865,-30.6368,Mukinbudin,ADM2,DISTRICT
SM255951,142.14,-37.39,Mukjarawaint,ETHNICITY,ETHNICITY
SM255951,142.14,-37.39,Mukjarawaint,ETHNICITY,ETHNICITY
SM255951,142.5689,-36.7233,Mukjarawaint,ETHNICITY,ETHNICITY
SM255951,142.5689,-36.7233,Mukjarawaint,ETHNICITY,ETHNICITY
SM255955,-84.89,32.84,Muklasa,ETHNICITY,ETHNICITY
SM238862,95.7121,26.9944,Muklom,DIALECT,LANGUAGE
SM238861,96.4273,27.6261,Muklomic,LANGUAGE,LANGUAGE
SM458830,130.5375,-12.9276,Mukngirru,ETHNICITY,ETHNICITY
SM276729,101.5477,-3.0744,Mukomuko,ADM2,DISTRICT
SM4731,32.775,0.3,Mukono (2010-),ADM1:ADM2,DISTRICT
SM277147,74.5349,30.2874,Muktsar,ADM2,DISTRICT
SM238866,18.1822,11.8644,Mukulu,LANGUAGE,LANGUAGE
SM278675,135.7027,34.9498,Mukō,ADM2,DISTRICT
SM288611,144.15,-5.5333,Mul-Baiyer,ADM2,DISTRICT
SM15959,-66.1284,18.2483,Mula Barrio,ADM2,DISTRICT
SM238869,147.45,-9.6833,Mulaha,LANGUAGE,LANGUAGE
SM238868,108.928,24.8151,Mulam,LANGUAGE,LANGUAGE
SM2331,35.4999,-15.9344,Mulanje,ADM1:ADM2,DISTRICT
SM15960,-66.0273,18.0635,Mulas Barrio,ADM2,DISTRICT
SM299233,31.5439,-1.8566,Muleba,ADM2,DISTRICT
SM282203,-112.3333,26.6,Mulegé,ADM2,DISTRICT
SM11178,25.4244,58.1282,Mulgi vald,ADM2,DISTRICT
SM12564,-77.8202,18.2116,Mulgrave,ADM2,DISTRICT
SM459227,146.6225,-18.7341,Mulgu,ETHNICITY,ETHNICITY
SM15961,-66.1444,18.2532,Mulita Barrio,ADM2,DISTRICT
SM265776,-51.7865,-28.3124,Muliterno,ADM2,DISTRICT
SM2332,80.5499,9.25,Mullaitivu,ADM1:ADM2,DISTRICT
SM296588,13.8224,57.9543,Mullsjö,ADM2,DISTRICT
SM238874,76.7,11.29,Mullu Kurumba,LANGUAGE,LANGUAGE
SM255965,130.48,-13.14,Mulluk Mulluk,ETHNICITY,ETHNICITY
SM255965,130.48,-13.14,Mulluk Mulluk,ETHNICITY,ETHNICITY
SM255965,130.48,-13.14,Mulluk Mulluk,ETHNICITY,ETHNICITY
SM255965,130.58,-13.58,Mulluk Mulluk,ETHNICITY,ETHNICITY
SM255965,130.58,-13.58,Mulluk Mulluk,ETHNICITY,ETHNICITY
SM255965,130.58,-13.58,Mulluk Mulluk,ETHNICITY,ETHNICITY
SM255965,130.5524,-13.5488,Mulluk Mulluk,ETHNICITY,ETHNICITY
SM255965,130.5524,-13.5488,Mulluk Mulluk,ETHNICITY,ETHNICITY
SM255965,130.5524,-13.5488,Mulluk Mulluk,ETHNICITY,ETHNICITY
SM238873,130.664,-13.427,Mullukmulluk,LANGUAGE,LANGUAGE
SM18152,24.7201,-16.2138,Mulobezi District,ADM2,DISTRICT
SM375468,71.3861,29.9331,Multan,ADM2:ADM3,DISTRICT
SM302267,-122.4153,45.5469,Multnomah,ADM2,DISTRICT
SM255967,-122.98,45.7,Multnomah,ETHNICITY,ETHNICITY
SM15191,-84.9619,13.1698,Mulukuku,ADM2,DISTRICT
SM264250,-35.4187,-6.9803,Mulungu,ADM2,DISTRICT
SM267804,-38.9896,-4.3064,Mulungu,ADM2,DISTRICT
SM267549,-41.4258,-12.008,Mulungu do Morro,ADM2,DISTRICT
SM459451,145.3498,-17.1186,Muluridji,ETHNICITY,ETHNICITY
SM238880,145.329,-17.0601,Muluridyi,LANGUAGE,LANGUAGE
SM238883,145.059,-4.8346,Mum,LANGUAGE,LANGUAGE
SM277040,72.8496,18.9596,Mumbai City,ADM2,DISTRICT
SM277041,72.849,19.1264,Mumbai Suburban,ADM2,DISTRICT
SM18153,26.6311,-14.9402,Mumbwa District (2012-),ADM2,DISTRICT
SM17935,70,38.0833,Muminobodskiy Rayon,ADM2,DISTRICT
SM240696,11.7155,8.9035,Mumuye,LANGUAGE,LANGUAGE
SM250592,12,9,Mumuye,ETHNICITY,ETHNICITY
SM238887,71.3439,35.6736,Mumviri,DIALECT,LANGUAGE
SM238890,122.541,-5.4042,Muna,LANGUAGE,LANGUAGE
SM282987,-89.7228,20.4815,Muna,ADM2,DISTRICT
SM255969,122.35,-5.36,Muna,ETHNICITY,ETHNICITY
SM13214,51.325,43.7,Munaily,ADM2,DISTRICT
SM279691,130.55,33.8,Munakata,ADM2,DISTRICT
SM289170,127.3078,39.2675,Munchŏn,ADM2,DISTRICT
SM238893,87.1792,25.0391,Munda,LANGUAGE,LANGUAGE
SM295315,157.2684,-8.328,Munda,ADM2,DISTRICT
SM255970,81.98,22.09,Munda,ETHNICITY,ETHNICITY
SM255970,85.85,22.46,Munda,ETHNICITY,ETHNICITY
SM255970,89.43,24.74,Munda,ETHNICITY,ETHNICITY
SM238900,10.2704,6.5997,Mundabli,LANGUAGE,LANGUAGE
SM238897,14.4751,9.832,Mundang,LANGUAGE,LANGUAGE
SM238899,9.9153,5.6807,Mundani,LANGUAGE,LANGUAGE
SM323773,-112.3464,53.589,Mundare,ADM2:ADM3,DISTRICT
SM238892,85.85,23.03,Mundari,LANGUAGE,LANGUAGE
SM250474,31.03,5.35,Mundari,ETHNICITY,ETHNICITY
SM250474,31.03,5.35,Mundari,ETHNICITY,ETHNICITY
SM250474,31.03,5.35,Mundari,ETHNICITY,ETHNICITY
SM250474,31,6,Mundari,ETHNICITY,ETHNICITY
SM250474,31,6,Mundari,ETHNICITY,ETHNICITY
SM250474,31,6,Mundari,ETHNICITY,ETHNICITY
SM250474,13,9,Mundari,ETHNICITY,ETHNICITY
SM250474,13,9,Mundari,ETHNICITY,ETHNICITY
SM250474,13,9,Mundari,ETHNICITY,ETHNICITY
SM261422,116.2155,-31.8846,Mundaring,ADM2,DISTRICT
SM238903,8.7688,9.1515,Mundat,LANGUAGE,LANGUAGE
SM296098,-55.1824,5.8385,Munder,ADM2,DISTRICT
SM263066,-54.2575,-23.9263,Mundo Novo,ADM2,DISTRICT
SM268101,-50.2372,-13.7165,Mundo Novo,ADM2,DISTRICT
SM267550,-40.5231,-11.9193,Mundo Novo,ADM2,DISTRICT
SM250533,30.21,4.1,Mundu,ETHNICITY,ETHNICITY
SM255974,143.32,-4.8,Mundugumor,ETHNICITY,ETHNICITY
SM255975,-57.92,-3.32,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-57.92,-3.32,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-57.92,-3.32,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-57.92,-3.32,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-59.09,-6.8,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-59.09,-6.8,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-59.09,-6.8,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-59.09,-6.8,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-55.43,-9.12,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-55.43,-9.12,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-55.43,-9.12,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-55.43,-9.12,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-57,-7,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-57,-7,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-57,-7,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-57,-7,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-58,-6,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-58,-6,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-58,-6,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-58,-6,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-56.5,-6.5,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-56.5,-6.5,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-56.5,-6.5,Mundurucu,ETHNICITY,ETHNICITY
SM255975,-56.5,-6.5,Mundurucu,ETHNICITY,ETHNICITY
SM238902,-57.691,-8.411,Mundurukú,LANGUAGE,LANGUAGE
SM289050,125.5097,39.5,Mundŏk,ADM2,DISTRICT
SM238912,10.0294,5.8975,Mungaka,LANGUAGE,LANGUAGE
SM224800,10.2267,6.5805,Mungbam,LANGUAGE,LANGUAGE
SM277452,81.6818,22.068,Mungeli,ADM2,DISTRICT
SM277416,86.4652,25.361,Munger (1999-),ADM2,DISTRICT
SM238915,135.918,-1.6677,Munggui,LANGUAGE,LANGUAGE
SM238918,146.747,-6.4621,Mungkip,LANGUAGE,LANGUAGE
SM260465,16.1736,-11.7482,Mungo,ADM2,DISTRICT
SM255979,11.2,3.42,Mungo,ETHNICITY,ETHNICITY
SM255979,9.69,4.1,Mungo,ETHNICITY,ETHNICITY
SM255980,144.18,-19.46,Mungoobra,ETHNICITY,ETHNICITY
SM255980,144.18,-19.46,Mungoobra,ETHNICITY,ETHNICITY
SM255980,146.048,-20.7668,Mungoobra,ETHNICITY,ETHNICITY
SM255980,146.048,-20.7668,Mungoobra,ETHNICITY,ETHNICITY
SM305208,31.735,-9.93,Mungwi,ADM2,DISTRICT
SM280736,128.1862,36.5876,Mungyeong,ADM2,DISTRICT
SM263679,-46.3089,-22.629,Munhoz,ADM2,DISTRICT
SM264587,-51.7319,-23.1129,Munhoz de Melo,ADM2,DISTRICT
SM238923,-76.4267,-5.7442,Muniche,LANGUAGE,LANGUAGE
SM10150,-116.0035,55.287,Municipal District of Big Lakes No. 125,ADM2,DISTRICT
SM10151,-115.0809,51.3582,Municipal District of Bighorn No. 8,ADM2,DISTRICT
SM10153,-110.7532,54.2515,Municipal District of Bonnyville No. 87 (1998-2021),ADM2,DISTRICT
SM10155,-119.0401,56.7694,Municipal District of Clear Hills No. 21,ADM2,DISTRICT
SM10156,-114.0262,50.6157,Municipal District of Foothills No. 31,ADM2,DISTRICT
SM10157,-118.0026,54.625,Municipal District of Greenview No. 16,ADM2,DISTRICT
SM10158,-114.3369,55.2288,Municipal District of Lesser Slave River No. 124,ADM2,DISTRICT
SM10159,-118.0025,57.4682,Municipal District of Northern Lights No. 22,ADM2,DISTRICT
SM10160,-113.7947,56.2469,Municipal District of Opportunity No. 17,ADM2,DISTRICT
SM10161,-117.7549,56.1307,Municipal District of Peace No. 135,ADM2,DISTRICT
SM10162,-114.047,49.48,Municipal District of Pincher Creek No. 9,ADM2,DISTRICT
SM10163,-114.2523,51.2327,Municipal District of Rocky View No. 44,ADM2,DISTRICT
SM18342,-56.1485,-34.9029,Municipio  Ch,ADM2,DISTRICT
SM18343,-56.3155,-34.8431,Municipio A,ADM2,DISTRICT
SM18344,-56.1839,-34.9051,Municipio B,ADM2,DISTRICT
SM18345,-56.1874,-34.8717,Municipio C,ADM2,DISTRICT
SM9673,17.75,-12.5,Municipio Camacupa (exc. Camacupa City),ADM2,DISTRICT
SM18346,-56.1499,-34.7879,Municipio D,ADM2,DISTRICT
SM18347,-56.0896,-34.8832,Municipio E,ADM2,DISTRICT
SM15192,-84.8167,12.5,Municipio El Ayote,ADM2,DISTRICT
SM15193,-85.2443,13.8074,Municipio El Cua,ADM2,DISTRICT
SM15194,-84.2002,12.8208,Municipio El Tortuguero,ADM2,DISTRICT
SM18348,-56.0909,-34.8227,Municipio F,ADM2,DISTRICT
SM18349,-56.2567,-34.7761,Municipio G,ADM2,DISTRICT
SM15195,-85.4232,11.1491,Municipio de Cardenas,ADM2,DISTRICT
SM11511,-91.4286,15.4487,Municipio de Chiantla (2005-),ADM2,DISTRICT
SM11512,-90.3191,15.8698,Municipio de Chisec (2008-),ADM2,DISTRICT
SM11513,-91.6656,15.6297,Municipio de Concepcion Huista (2015-),ADM2,DISTRICT
SM15196,-83.0455,12.177,Municipio de Corn Island,ADM2,DISTRICT
SM11514,-91.5663,14.4924,Municipio de Cuyotenango (2014-),ADM2,DISTRICT
SM11515,-89.352,16.5814,Municipio de Dolores (2014-),ADM2,DISTRICT
SM11516,-89.7273,16.5303,Municipio de El Chal,ADM2,DISTRICT
SM11517,-90.5409,14.6138,Municipio de Guatemala,ADM2,DISTRICT
SM15197,-85.391,12.0621,Municipio de Juigalpa,ADM2,DISTRICT
SM11518,-92.121,14.5456,Municipio de La Blanca,ADM2,DISTRICT
SM15198,-84.3653,13.0956,Municipio de La Cruz de Rio Grande,ADM2,DISTRICT
SM11519,-91.1095,14.0787,Municipio de La Gomera (2015-),ADM2,DISTRICT
SM11520,-90.5822,17.004,Municipio de La Libertad (2011-),ADM2,DISTRICT
SM15199,-83.6716,12.3419,Municipio de Laguna,ADM2,DISTRICT
SM15200,-86.2424,12.1125,Municipio de Managua,ADM2,DISTRICT
SM11521,-92.1774,14.553,Municipio de Ocos (2014-),ADM2,DISTRICT
SM11522,-89.6556,15.3087,Municipio de Panzos (1999-),ADM2,DISTRICT
SM15201,-84.6664,11.1674,Municipio de San Carlos,ADM2,DISTRICT
SM15202,-85.3833,12.2667,Municipio de San Francisco de Cuapa,ADM2,DISTRICT
SM11523,-89.5965,14.9069,Municipio de San Jorge,ADM2,DISTRICT
SM15203,-85.5333,13.5333,Municipio de San Jose de Bocay,ADM2,DISTRICT
SM15204,-85.7829,11.2237,Municipio de San Juan del Sur,ADM2,DISTRICT
SM15205,-84.7865,13.7417,Municipio de Siuna,ADM2,DISTRICT
SM15206,-86.0079,11.4601,Municipio de Tola,ADM2,DISTRICT
SM11524,-91.7357,15.5869,Municipio de Union Cantinil,ADM2,DISTRICT
SM11525,-89.4648,14.9714,Municipio de Zacapa (2014-),ADM2,DISTRICT
SM289851,26.106,44.432,Municipiul Bucuresti,ADM2,DISTRICT
SM238922,145.548,-5.2433,Munit,LANGUAGE,LANGUAGE
SM267551,-39.106,-13.0015,Muniz Ferreira,ADM2,DISTRICT
SM268011,-41.4258,-20.4227,Muniz Freire,ADM2,DISTRICT
SM238925,70.9404,35.9321,Munji,LANGUAGE,LANGUAGE
SM296590,11.701,58.5733,Munkedal,ADM2,DISTRICT
SM296506,13.4946,59.8163,Munkfors,ADM2,DISTRICT
SM255982,146.96,-22.45,Munkibura,ETHNICITY,ETHNICITY
SM255982,146.96,-22.45,Munkibura,ETHNICITY,ETHNICITY
SM255982,146.1838,-21.8125,Munkibura,ETHNICITY,ETHNICITY
SM255982,146.1838,-21.8125,Munkibura,ETHNICITY,ETHNICITY
SM15962,-66.05,18.0786,Munoz Rivera Barrio,ADM2,DISTRICT
SM238931,-81.9166,42.5968,Munsee,LANGUAGE,LANGUAGE
SM262257,90.3667,23.55,Munshiganj,ADM2,DISTRICT
SM323882,-112.7356,51.5613,Munson,ADM2:ADM3,DISTRICT
SM4176,-8.7167,52.3433,Munster,ADM1,DISTRICT
SM290924,26.9729,44.6384,Munteni-buzau,ADM2,DISTRICT
SM292295,27.7695,46.6041,Muntenii De Jos,ADM2,DISTRICT
SM238934,126.7544,-14.7008,Munumburru,DIALECT,LANGUAGE
SM458622,127.5277,-15.2778,Munumburu,ETHNICITY,ETHNICITY
SM458444,152.9147,-28.1265,Mununjali,ETHNICITY,ETHNICITY
SM255986,152.75,-26.09,Munyabora,ETHNICITY,ETHNICITY
SM238938,105.186,20.7374,Muong,LANGUAGE,LANGUAGE
SM255988,105,21,Muong,ETHNICITY,ETHNICITY
SM9564,67.7818,32.9404,Muqer (2005-),ADM2,DISTRICT
SM268012,-41.3009,-20.9152,Muqui,ADM2,DISTRICT
SM260101,63.1051,35.1701,Muqur,ADM2,DISTRICT
SM267552,-43.5131,-12.1989,Muquém de São Francisco,ADM2,DISTRICT
SM238957,146.522,-5.6642,Mur Pano,LANGUAGE,LANGUAGE
SM255991,-62.27,-4.6,Mura,ETHNICITY,ETHNICITY
SM18087,43.7537,38.9789,Muradiye / Berkri,ADM2,DISTRICT
SM18088,43.7655,38.9969,Muradiye Ilcesi,ADM2,DISTRICT
SM278936,139.6173,38.3102,Murakami,ADM2,DISTRICT
SM458862,134.1824,-12.2412,Muralidban,ETHNICITY,ETHNICITY
SM261981,29.6182,-3.2682,Muramvya,ADM2,DISTRICT
SM2333,29.6667,-3.25,Muramvya,ADM1,DISTRICT
SM2334,36.994,-0.684,Murang'a (pre-1996),ADM1:ADM2,DISTRICT
SM293270,48.75,59.3333,Murashinskiy rayon,ADM2,DISTRICT
SM238942,146.54,-5.7442,Muratayak,LANGUAGE,LANGUAGE
SM298945,27.5145,41.1835,Muratlı,ADM2,DISTRICT
SM255995,-59.833,7.417,Murato,ETHNICITY,ETHNICITY
SM255995,-59.833,7.417,Murato,ETHNICITY,ETHNICITY
SM255995,-59.833,7.417,Murato,ETHNICITY,ETHNICITY
SM255995,-59,5,Murato,ETHNICITY,ETHNICITY
SM255995,-59,5,Murato,ETHNICITY,ETHNICITY
SM255995,-59,5,Murato,ETHNICITY,ETHNICITY
SM255995,-60.17,7.42,Murato,ETHNICITY,ETHNICITY
SM255995,-60.17,7.42,Murato,ETHNICITY,ETHNICITY
SM255995,-60.17,7.42,Murato,ETHNICITY,ETHNICITY
SM18089,30.7686,36.8791,Muratpasa Ilcesi,ADM2,DISTRICT
SM261864,14.2,47.1333,Murau,ADM2,DISTRICT
SM279544,140.3352,38.5167,Murayama,ADM2,DISTRICT
SM261423,116.4121,-27.0089,Murchison,ADM2,DISTRICT
SM274057,-1.1415,37.9866,Murcia,ADM2,DISTRICT
SM250269,-13.99,28.38,Murd Guachey,ETHNICITY,ETHNICITY
SM250269,-13.99,28.38,Murd Guachey,ETHNICITY,ETHNICITY
SM250269,-15.62,27.99,Murd Guachey,ETHNICITY,ETHNICITY
SM250269,-15.62,27.99,Murd Guachey,ETHNICITY,ETHNICITY
SM238947,-62.2021,-13.6367,Mure,LANGUAGE,LANGUAGE
SM305248,31.8308,-17.8006,Murehwa,ADM2,DISTRICT
SM2335,24.5833,46.6667,Mureș,ADM1,DISTRICT
SM290181,28.3819,44.1709,Murfatlar,ADM2,DISTRICT
SM292296,28.0042,46.2016,Murgeni,ADM2,DISTRICT
SM289894,26.8888,45.4061,Murgesti,ADM2,DISTRICT
SM260102,63.4722,35.4674,Murghab,ADM2,DISTRICT
SM305944,73.7161,38.381,Murghob,ADM2,DISTRICT
SM298141,41.5629,41.2793,Murgul,ADM2,DISTRICT
SM268905,8.3536,47.2523,Muri,ADM2,DISTRICT
SM255998,81.1335,19,Muria,ETHNICITY,ETHNICITY
SM255998,81.1335,19,Muria,ETHNICITY,ETHNICITY
SM255998,81,20,Muria,ETHNICITY,ETHNICITY
SM255998,81,20,Muria,ETHNICITY,ETHNICITY
SM263681,-42.4446,-21.0783,Muriaé,ADM2,DISTRICT
SM267111,-36.9763,-10.3893,Muribeca,ADM2,DISTRICT
SM265392,-35.9,-9.2983,Murici,ADM2,DISTRICT
SM265065,-42.0022,-3.365,Murici dos Portelas,ADM2,DISTRICT
SM267265,-48.7917,-6.986,Muricilândia,ADM2,DISTRICT
SM292061,29.1518,45.025,Murighiol,ADM2,DISTRICT
SM238950,114.434,3.7519,Murik (Malaysia),LANGUAGE,LANGUAGE
SM238951,144.166,-3.8696,Murik (Papua New Guinea),LANGUAGE,LANGUAGE
SM270571,-75.2788,4.8171,Murillo,ADM2,DISTRICT
SM255999,152.34,8.69,Murilo,ETHNICITY,ETHNICITY
SM14949,152.342,8.692,Murilo Municipality,ADM2,DISTRICT
SM270239,-76.7154,6.847,Murindó,ADM2,DISTRICT
SM267554,-39.0665,-12.6346,Muritiba,ADM2,DISTRICT
SM238954,140.913,-4.0755,Murkim,LANGUAGE,LANGUAGE
SM238955,33.988,6.6976,Murle,LANGUAGE,LANGUAGE
SM250535,32.53,5.66,Murle,ETHNICITY,ETHNICITY
SM2336,34,68,Murmansk,ADM1,DISTRICT
SM13702,26.572,56.6517,Murmastienes pagasts,ADM2,DISTRICT
SM17008,41.9167,55.5833,Muromskiy Rayon,ADM2,DISTRICT
SM293898,75.5,56.4167,Muromtsevskiy rayon,ADM2,DISTRICT
SM278308,140.9885,42.3666,Muroran,ADM2,DISTRICT
SM278588,134.146,33.3885,Muroto,ADM2,DISTRICT
SM299774,27.5307,48.7173,Murovano-Kurylovets'kyi,ADM2,DISTRICT
SM285472,-85.9472,13.8026,Murra,ADM2,DISTRICT
SM302217,-97.0679,34.4823,Murray,ADM2,DISTRICT
SM301385,-95.7633,44.0221,Murray,ADM2,DISTRICT
SM300387,-84.7481,34.7885,Murray,ADM2,DISTRICT
SM261424,115.9654,-32.6239,Murray,ADM2,DISTRICT
SM261714,139.3209,-35.2002,Murray Bridge,ADM2,DISTRICT
SM16829,144.0054,-35.2304,Murray River,ADM2,DISTRICT
SM261267,145.6744,-37.281,Murrindindi,ADM2,DISTRICT
SM458800,129.9465,-14.7159,Murrinh-kura,ETHNICITY,ETHNICITY
SM458801,129.8169,-14.4051,Murrinhdiminin,ETHNICITY,ETHNICITY
SM238959,129.7015,-14.7485,Murriny Patha,LANGUAGE,LANGUAGE
SM457948,145.8126,-35.0103,Murrumbidgee (2016-),ADM2,DISTRICT
SM283938,38.6612,-15.4007,Murrupula,ADM2,DISTRICT
SM277360,88.25,24.1,Murshidabad,ADM2,DISTRICT
SM238964,36.0971,5.698,Mursi,LANGUAGE,LANGUAGE
SM296364,16.1606,46.6514,Murska Sobota,ADM1:ADM2,DISTRICT
SM275953,16.4384,46.5132,Mursko Središće,ADM2,DISTRICT
SM261853,14.6647,47.1696,Murtal,ADM2,DISTRICT
SM305446,15.6002,43.8096,Murter,ADM2,DISTRICT
SM289211,-8.6402,40.743,Murtosa,ADM2,DISTRICT
SM256002,153.62,-25.36,Murubura,ETHNICITY,ETHNICITY
SM238972,-73.8331,-1.1367,Murui Huitoto,LANGUAGE,LANGUAGE
SM276405,114.2667,-0.0167,Murung Raya,ADM2,DISTRICT
SM458090,126.0307,-30.3024,Murunitja,ETHNICITY,ETHNICITY
SM238971,145.606,-5.0245,Murupi,LANGUAGE,LANGUAGE
SM256003,117.34,3.76,Murut,ETHNICITY,ETHNICITY
SM256003,117.34,3.76,Murut,ETHNICITY,ETHNICITY
SM256003,115.14,4.62,Murut,ETHNICITY,ETHNICITY
SM256003,115.14,4.62,Murut,ETHNICITY,ETHNICITY
SM262090,29.558,-2.9684,Muruta,ADM2,DISTRICT
SM266723,-51.3038,-20.9964,Murutinga do Sul,ADM2,DISTRICT
SM238966,146.546,-28.9618,Muruwari,LANGUAGE,LANGUAGE
SM261650,146.6041,-26.1361,Murweh,ADM2,DISTRICT
SM262063,29.2497,-2.9029,Murwi,ADM2,DISTRICT
SM2337,15.6,24.6,Murzuq,ADM1,DISTRICT
SM289373,-7.4512,41.4183,Murça,ADM2,DISTRICT
SM2338,41.75,39,Mus,ADM1,DISTRICT
SM260075,69.6819,33.5839,Musa Khel,ADM2,DISTRICT
SM260005,64.7363,32.4691,Musa Qala,ADM2,DISTRICT
SM298626,36.9628,36.9047,Musabeyli,ADM2,DISTRICT
SM262020,29.3759,-3.4121,Musaga,ADM2,DISTRICT
SM375399,69.9007,30.8494,Musakhel,ADM2:ADM3,DISTRICT
SM289109,129.2121,42.2187,Musan,ADM2,DISTRICT
SM2339,56.3,26.0333,Musandam,ADM1,DISTRICT
SM295139,29.6066,-1.5076,Musanze,ADM1:ADM2,DISTRICT
SM238978,145.413,-4.7026,Musar,LANGUAGE,LANGUAGE
SM238976,87.5304,26.7329,Musasa,LANGUAGE,LANGUAGE
SM279455,139.3839,35.7504,Musashimurayama,ADM2,DISTRICT
SM279456,139.566,35.7177,Musashino,ADM2,DISTRICT
SM284918,7.6889,12.0847,Musawa,ADM2,DISTRICT
SM260035,69.2184,34.3691,Musayi,ADM2,DISTRICT
SM2340,58.4037,23.5835,Muscat,ADM1,DISTRICT
SM300851,-91.1128,41.4839,Muscatine,ADM2,DISTRICT
SM300388,-84.877,32.51,Muscogee,ADM2,DISTRICT
SM283488,98.0343,23.7053,Muse,ADM2,DISTRICT
SM290640,23.4502,45.1523,Musetesti,ADM2,DISTRICT
SM238981,15.4115,9.8161,Musey,LANGUAGE,LANGUAGE
SM238983,15.1065,10.8439,Musgu,LANGUAGE,LANGUAGE
SM284996,3.3631,6.5264,Mushin,ADM2,DISTRICT
SM238985,42.6626,0.0069,Mushungulu,DIALECT,LANGUAGE
SM238989,103.945,-2.9399,Musi,LANGUAGE,LANGUAGE
SM276670,103.75,-2.4167,Musi Banyuasin,ADM2,DISTRICT
SM261962,29.4333,-3.0667,Musigati,ADM2,DISTRICT
SM301312,-86.2125,43.2192,Muskegon,ADM2,DISTRICT
SM302140,-81.9444,39.9654,Muskingum,ADM2,DISTRICT
SM302218,-95.3795,35.6161,Muskogee,ADM2,DISTRICT
SM268867,-79.4996,45.1668,Muskoka,ADM2,DISTRICT
SM238992,15.4113,11.5697,Muskum,LANGUAGE,LANGUAGE
SM238993,46.6456,41.4251,Muslim Tat,LANGUAGE,LANGUAGE
SM294624,53.25,55.25,Muslyumovskiy rayon,ADM2,DISTRICT
SM238995,146.93,-6.5382,Musom,LANGUAGE,LANGUAGE
SM299098,33.5896,-1.8461,Musoma Rural,ADM2,DISTRICT
SM299099,33.7972,-1.5201,Musoma Urban,ADM2,DISTRICT
SM262009,30.0696,-3.7552,Musongati,ADM2,DISTRICT
SM256012,-123.24,49.37,Musqueam,ETHNICITY,ETHNICITY
SM461502,149.733,-1.583,Mussau,ETHNICITY,ETHNICITY
SM239000,149.624,-1.4411,Mussau-Emira,LANGUAGE,LANGUAGE
SM301648,-108.3977,46.4966,Musselshell,ADM2,DISTRICT
SM260446,15.9764,-10.213,Mussende,ADM2,DISTRICT
SM304850,43.2685,16.2736,Mustaba,ADM2,DISTRICT
SM298247,28.4183,40.0497,Mustafa Kemalpaşa,ADM2,DISTRICT
SM375352,83.9006,28.9479,Mustang,ADM2:ADM3,DISTRICT
SM11179,26.8744,58.8561,Mustvee vald,ADM2,DISTRICT
SM261587,150.6984,-32.4894,Muswellbrook,ADM2,DISTRICT
SM298758,33.3872,36.7184,Mut,ADM2,DISTRICT
SM296282,15.1361,46.6306,Muta,ADM1:ADM2,DISTRICT
SM256017,146.21,-23.21,Mutabura,ETHNICITY,ETHNICITY
SM256017,146.21,-23.21,Mutabura,ETHNICITY,ETHNICITY
SM256017,143.08,-22.72,Mutabura,ETHNICITY,ETHNICITY
SM256017,143.08,-22.72,Mutabura,ETHNICITY,ETHNICITY
SM256017,144.8747,-23.0856,Mutabura,ETHNICITY,ETHNICITY
SM256017,144.8747,-23.0856,Mutabura,ETHNICITY,ETHNICITY
SM262075,29.8701,-3.1441,Mutaho,ADM2,DISTRICT
SM256018,43.99,24.23,Mutair,ETHNICITY,ETHNICITY
SM256018,43.99,24.23,Mutair,ETHNICITY,ETHNICITY
SM256018,47,28,Mutair,ETHNICITY,ETHNICITY
SM256018,47,28,Mutair,ETHNICITY,ETHNICITY
SM262033,29.4328,-3.5285,Mutambu,ADM2,DISTRICT
SM305234,32.4433,-19.2551,Mutare,ADM2,DISTRICT
SM305235,32.7455,-18.6628,Mutasa,ADM2,DISTRICT
SM270240,-76.5,7.3482,Mutatá,ADM2,DISTRICT
SM256021,145.45,-33.33,Muthi-Muthi,ETHNICITY,ETHNICITY
SM256021,145.45,-33.33,Muthi-Muthi,ETHNICITY,ETHNICITY
SM256021,142.7217,-33.7532,Muthi-Muthi,ETHNICITY,ETHNICITY
SM256021,142.7217,-33.7532,Muthi-Muthi,ETHNICITY,ETHNICITY
SM239004,76.7328,10.3524,Muthuvan,LANGUAGE,LANGUAGE
SM262034,29.325,-3.293,Mutimbuzi,ADM2,DISTRICT
SM270376,-72.7988,7.2981,Mutiscua,ADM2,DISTRICT
SM298214,41.923,38.4098,Mutki,ADM2,DISTRICT
SM305249,32.2942,-17.4662,Mutoko,ADM2,DISTRICT
SM269619,25.635,-10.495,Mutshatsha,ADM2,DISTRICT
SM278972,141.0333,41.2909,Mutsu,ADM2,DISTRICT
SM239009,148.014,-5.7941,Mutu,LANGUAGE,LANGUAGE
SM273838,30.5089,31.3652,Mutubis,ADM2,DISTRICT
SM263682,-41.4484,-19.9268,Mutum,ADM2,DISTRICT
SM262080,30.2425,-3.2021,Mutumba,ADM2,DISTRICT
SM268102,-49.3,-13.7026,Mutunópolis,ADM2,DISTRICT
SM14133,9.6899,30.0596,Mutusarrifiyat Ghadamis,ADM2,DISTRICT
SM267555,-39.5085,-13.3328,Mutuípe,ADM2,DISTRICT
SM459260,142.7867,-11.9165,Mutyanthi,ETHNICITY,ETHNICITY
SM260307,13.8248,-9.8177,Muxima,ADM2,DISTRICT
SM282988,-89.3281,21.0473,Muxupip,ADM2,DISTRICT
SM285459,-85.5893,12.7618,Muy Muy,ADM2,DISTRICT
SM239013,102.205,29.8226,Muya,FAMILY,LANGUAGE
SM285031,7.0173,9.8098,Muya,ADM2,DISTRICT
SM239014,14.2125,10.8605,Muyang,LANGUAGE,LANGUAGE
SM293120,31.6667,64,Muyezerskiy rayon,ADM2,DISTRICT
SM261988,30.3852,-2.8095,Muyinga,ADM2,DISTRICT
SM2341,30.3333,-2.8333,Muyinga,ADM1,DISTRICT
SM295115,115,56.3333,Muyskiy,ADM2,DISTRICT
SM239015,152.731,-9.195,Muyuw,LANGUAGE,LANGUAGE
SM256025,152.63,-9.01,Muyuw,ETHNICITY,ETHNICITY
SM5110,73.5899,34.3428,Muzaffarabad (2009-),ADM2:ADM3,DISTRICT
SM375447,71.0588,30.0374,Muzaffargarh,ADM2:ADM3,DISTRICT
SM277321,77.5833,29.45,Muzaffarnagar (2011-),ADM2,DISTRICT
SM277417,85.4167,26.1667,Muzaffarpur,ADM2,DISTRICT
SM263683,-46.5184,-21.3524,Muzambinho,ADM2,DISTRICT
SM256026,34.01,28.09,Muzeina,ETHNICITY,ETHNICITY
SM239016,103.464,23.086,Muzi,LANGUAGE,LANGUAGE
SM270792,-74.1013,5.529,Muzo,ADM2,DISTRICT
SM303440,66.8333,37.4167,Muzrabot,ADM2,DISTRICT
SM265774,-51.8233,-29.1402,Muçum,ADM2,DISTRICT
SM262721,-68.7941,-15.4458,Muñecas,ADM2,DISTRICT
SM303818,-69.4167,7.5833,Muñoz,ADM2,DISTRICT
SM282690,-98.2127,19.4957,Muñoz de Domingo Arenas,ADM2,DISTRICT
SM305470,16.4727,43.6933,Muć,ADM2,DISTRICT
SM454506,12,3,Mvae,ETHNICITY,ETHNICITY
SM239020,11.1843,6.7572,Mvano,LANGUAGE,LANGUAGE
SM269502,11.3943,2.798,Mvila,ADM2,DISTRICT
SM299119,37.45,-6.3,Mvomero,ADM2,DISTRICT
SM274571,12.1463,0.3767,Mvoung,ADM2,DISTRICT
SM269824,12.3464,-4.3767,Mvouti,ADM2,DISTRICT
SM239027,29.6656,0.7398,Mvuba,LANGUAGE,LANGUAGE
SM239030,8.9652,9.4721,Mwaghavul,LANGUAGE,LANGUAGE
SM238635,143.965,-4.3004,Mwakai,LANGUAGE,LANGUAGE
SM261989,30.3117,-3.0959,Mwakiro,ADM2,DISTRICT
SM2342,43.7362,-12.3427,Mwali,ADM1,DISTRICT
SM239032,43.741,-12.3164,Mwali Comorian,LANGUAGE,LANGUAGE
SM239040,-5.9458,7.8753,Mwan,LANGUAGE,LANGUAGE
SM18154,24.631,-17.091,Mwandi District,ADM2,DISTRICT
SM299252,37.623,-3.7035,Mwanga,ADM2,DISTRICT
SM239037,40.4601,-12.5821,Mwani,LANGUAGE,LANGUAGE
SM18155,28.7808,-9.8781,Mwansabombwe District,ADM2,DISTRICT
SM2343,34.5168,-15.6334,Mwanza,ADM1:ADM2,DISTRICT
SM2344,33.0833,-2.75,Mwanza (2012-),ADM1,DISTRICT
SM2345,29.6667,-3.5,Mwaro,ADM1,DISTRICT
SM239041,151.066,-10.0271,Mwatebu,LANGUAGE,LANGUAGE
SM305264,30.7067,-21.3584,Mwenezi,ADM2,DISTRICT
SM269697,28.4333,-3.0333,Mwenga,ADM2,DISTRICT
SM18156,28.7331,-10.4399,Mwense District (2012-),ADM2,DISTRICT
SM239042,22.4505,-14.7338,Mwenyi,DIALECT,LANGUAGE
SM239044,38.8762,-9.7564,Mwera (Chimwera),LANGUAGE,LANGUAGE
SM239043,34.8959,-11.3304,Mwera (Nyasa),LANGUAGE,LANGUAGE
SM250538,38.27,-10.13,Mwera-Inland,ETHNICITY,ETHNICITY
SM239047,13.3004,0.0861,Mwesa,LANGUAGE,LANGUAGE
SM256031,14.87,-14.52,Mwila,ETHNICITY,ETHNICITY
SM239053,37.5939,-0.1197,Mwimbi-Muthambi,LANGUAGE,LANGUAGE
SM5264,38.07,-0.93,Mwingi,ADM2,DISTRICT
SM239055,43.5606,0.9344,Mwini,DIALECT,LANGUAGE
SM18157,24.5847,-12.0008,Mwinilunga District (2011-),ADM2,DISTRICT
SM238749,167.7063,-13.655,Mwotlap,LANGUAGE,LANGUAGE
SM262000,29.8006,-2.825,Mwumba,ADM2,DISTRICT
SM9565,66.3365,32.381,Mya Neshin,ADM2,DISTRICT
SM262646,27,54.8333,Myadzyel,ADM2,DISTRICT
SM375353,83.4634,28.546,Myagdi,ADM2:ADM3,DISTRICT
SM283584,91.9233,48.2281,Myangad,ADM2,DISTRICT
SM15101,95.0958,16.6372,Myaungmya District (2008-),ADM2,DISTRICT
SM283509,98.5298,16.5411,Myawady,ADM2,DISTRICT
SM239056,22.82,37.73,Mycenaean Greek,LANGUAGE,LANGUAGE
SM239057,9.5025,-1.4874,Myene,LANGUAGE,LANGUAGE
SM12565,-77.6243,17.997,Myersville,ADM2,DISTRICT
SM17573,-60.9044,13.8186,Myette Gardens,ADM2,DISTRICT
SM15102,95.4842,21.2776,Myingyan District,ADM2,DISTRICT
SM15103,97.1453,25.7855,Myitkyina District (excl. Mohnyin),ADM2,DISTRICT
SM296167,17.5833,48.75,Myjava,ADM2,DISTRICT
SM299818,35.2209,47.2657,Mykhailivs'kyi,ADM2,DISTRICT
SM2346,31.9976,46.9746,Mykolayiv,ADM1,DISTRICT
SM299586,31.993,46.9763,Mykola‹vs'ka,ADM2,DISTRICT
SM299587,31.9488,46.9928,Mykola‹vs'kyi,ADM2,DISTRICT
SM299617,30.7608,47.5407,Mykola‹vs'kyi,ADM2,DISTRICT
SM299531,24.1172,49.4714,Mykola‹vs'kyi,ADM2,DISTRICT
SM10930,32.7274,34.9493,Mylikouri,ADM2,DISTRICT
SM262242,90.4,24.75,Mymensingh,ADM2,DISTRICT
SM3924,90.3811,24.7294,Mymensingh Division,ADM1,DISTRICT
SM262667,27.8333,55.5833,Myory,ADM2,DISTRICT
SM13028,134.3499,33.9919,Myozai Gun,ADM2,DISTRICT
SM299646,33.6082,49.9686,Myrhorods'kyi,ADM2,DISTRICT
SM323774,-111.225,53.6612,Myrnam,ADM2:ADM3,DISTRICT
SM18253,37.271,48.2941,Myrnohrad Municipality,ADM2,DISTRICT
SM10931,33.0762,35.3219,Myrtou,ADM2,DISTRICT
SM295068,38.4167,57.8333,Myshkinskiy rayon,ADM2,DISTRICT
SM276938,76.42,12.23,Mysore (1997-),ADM2,DISTRICT
SM288728,19.3513,50.6055,Myszków,ADM2,DISTRICT
SM288726,19.1539,50.2096,Mysłowice,ADM2,DISTRICT
SM17009,37.6667,56,Mytishchi Urban Okrug,ADM2,DISTRICT
SM278937,138.25,37,Myōkō,ADM2,DISTRICT
SM289110,129.5714,41.04,Myŏngch'ŏn,ADM2,DISTRICT
SM288930,19.9448,49.8339,Myślenice,ADM2,DISTRICT
SM288825,14.8845,52.8547,Myślibórz,ADM2,DISTRICT
SM250776,2.07,31.98,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,2.07,31.98,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,2.07,31.98,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,2.07,31.98,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,4,33,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,4,33,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,4,33,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,4,33,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,3,32,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,3,32,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,3,32,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,3,32,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,3,32,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,3,32,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,3,32,Mzab (Algeria),ETHNICITY,ETHNICITY
SM250776,3,32,Mzab (Algeria),ETHNICITY,ETHNICITY
SM6025,-6.8,32.99,Mzab (Morocco),ETHNICITY,ETHNICITY
SM239060,93.71,25.4779,Mzieme Naga,LANGUAGE,LANGUAGE
SM2347,33.6078,-11.8543,Mzimba,ADM1:ADM2,DISTRICT
SM236990,11.0232,9.2888,Mághdì,LANGUAGE,LANGUAGE
SM237135,-64.2359,3.9692,Máku,LANGUAGE,LANGUAGE
SM274038,-4.75,36.8,Málaga,ADM2,DISTRICT
SM270487,-72.771,6.7075,Málaga,ADM2,DISTRICT
SM263630,-44.176,-20.0723,Mário Campos,ADM2,DISTRICT
SM281618,-99.3481,17.8161,Mártir de Cuilapan,ADM2,DISTRICT
SM261120,-67.1667,-43.8333,Mártires,ADM2,DISTRICT
SM276181,22.3189,47.9296,Mátészalka,ADM2,DISTRICT
SM262782,-73.4583,-7.5396,Mâncio Lima,ADM2,DISTRICT
SM264232,-37.4528,-7.2199,Mãe d'Água,ADM2,DISTRICT
SM286230,18.615,69.1239,Målselv,ADM2,DISTRICT
SM286339,24.6615,70.9965,Måsøy,ADM2,DISTRICT
SM304128,105.4384,23.1408,Mèo Vạc,ADM2,DISTRICT
SM296050,6.6667,0.25,Mé-Zóchi,ADM1:ADM2,DISTRICT
SM2219,11,33.3333,Médenine,ADM1,DISTRICT
SM297903,10.4462,33.3952,Médenine Nord,ADM2,DISTRICT
SM297904,10.645,33.3861,Médenine Sud,ADM2,DISTRICT
SM283990,-15.659,17.141,Méderdra,ADM2,DISTRICT
SM298021,10.1695,36.797,Médina,ADM2,DISTRICT
SM295283,-14.8577,13.2256,Médina Yoro Foula,ADM2,DISTRICT
SM2218,3,36.0833,Médéa,ADM1,DISTRICT
SM298054,10.2301,36.7731,Mégrine,ADM2,DISTRICT
SM283345,2.7023,16.6617,Ménaka,ADM1:ADM2,DISTRICT
SM283345,2.7023,16.6617,Ménaka,ADM1:ADM2,DISTRICT
SM283345,2.402,15.9153,Ménaka,ADM1:ADM2,DISTRICT
SM283345,2.402,15.9153,Ménaka,ADM1:ADM2,DISTRICT
SM282652,-98.4478,25.235,Méndez,ADM2,DISTRICT
SM282984,-89.6162,20.9952,Mérida,ADM2,DISTRICT
SM2228,-71.1667,8.5,Mérida,ADM1,DISTRICT
SM289432,-7.6643,37.6592,Mértola,ADM2,DISTRICT
SM2234,-99.6667,19.3667,México,ADM1,DISTRICT
SM304141,105.7114,21.1825,Mê Linh,ADM2,DISTRICT
SM289241,-7.265,40.9324,Mêda,ADM2,DISTRICT
SM238408,-84.2942,15.1576,Mískito,LANGUAGE,LANGUAGE
SM238487,10.8087,46.4916,Mòcheno,LANGUAGE,LANGUAGE
SM238487,10.8087,46.4916,Mòcheno,LANGUAGE,LANGUAGE
SM238487,11.315,46.1064,Mòcheno,LANGUAGE,LANGUAGE
SM238487,11.315,46.1064,Mòcheno,LANGUAGE,LANGUAGE
SM304417,107.8661,21.5068,Móng Cái,ADM2,DISTRICT
SM276262,18.1984,47.3469,Mór,ADM2,DISTRICT
SM276251,19.8226,46.2684,Mórahalm,ADM2,DISTRICT
SM276094,-73.2188,19.7716,Môle Saint-Nicolas,ADM2,DISTRICT
SM2277,107,12.75,Môndól Kiri,ADM1,DISTRICT
SM261808,16.2412,48.0852,Mödling,ADM2,DISTRICT
SM296587,12.0989,57.6026,Mölndal,ADM2,DISTRICT
SM283710,108.483,48.195,Möngönmorit,ADM2,DISTRICT
SM283582,91.7757,46.9795,Mönkhkhairkhan,ADM2,DISTRICT
SM283582,91.7757,46.9795,Mönkhkhairkhan,ADM2,DISTRICT
SM283582,92.1168,46.9091,Mönkhkhairkhan,ADM2,DISTRICT
SM283582,92.1168,46.9091,Mönkhkhairkhan,ADM2,DISTRICT
SM296662,16.3862,57.0663,Mönsterås,ADM2,DISTRICT
SM296663,16.5,56.5,Mörbylånga,ADM2,DISTRICT
SM283599,100.163,49.625,Mörön,ADM2,DISTRICT
SM283599,100.163,49.625,Mörön,ADM2,DISTRICT
SM283599,100.121,49.6461,Mörön,ADM2,DISTRICT
SM283599,100.121,49.6461,Mörön,ADM2,DISTRICT
SM283567,110.3166,47.3756,Mörön,ADM2,DISTRICT
SM283583,92.7852,46.6771,Möst,ADM2,DISTRICT
SM304587,104.12,21.7882,Mù Căng Chải,ADM2,DISTRICT
SM282053,-102.1213,18.9859,Múgica,ADM2,DISTRICT
SM283289,-101.6663,28.0737,Múzquiz,ADM2,DISTRICT
SM238891,94.3929,20.289,Mün Chin,LANGUAGE,LANGUAGE
SM286649,-81.6722,8.3504,Müna,ADM2,DISTRICT
SM268971,8.9881,47.4732,Münchwilen,ADM2,DISTRICT
SM238898,29.9967,4.2276,Mündü,LANGUAGE,LANGUAGE
SM277808,-19,63.5,Mýrdalshreppur,ADM2,DISTRICT
SM242378,-157.9229,-9.0822,Māngarongaro,LANGUAGE,LANGUAGE
SM271191,14.5,50.3333,Mělník,ADM2,DISTRICT
SM283433,14.3639,35.9194,Mġarr,ADM1:ADM2,DISTRICT
SM288955,20.3771,53.055,Mława,ADM2,DISTRICT
SM304040,103.0593,21.8569,Mường Chà,ADM2,DISTRICT
SM304281,104.1177,22.6711,Mường Khương,ADM2,DISTRICT
SM304465,104.1111,21.5296,Mường La,ADM2,DISTRICT
SM304532,104.6294,20.5276,Mường Lát,ADM2,DISTRICT
SM304041,102.5847,22.1035,Mường Nhé,ADM2,DISTRICT
SM304257,102.7384,22.3744,Mường Tè,ADM2,DISTRICT
SM304039,103.2633,21.5258,Mường Ảng,ADM2,DISTRICT
SM304559,106.3036,10.1783,Mỏ Cày Bắc,ADM2,DISTRICT
SM304560,106.3636,10.0697,Mỏ Cày Nam,ADM2,DISTRICT
SM304411,108.8884,14.9552,Mộ Đức,ADM2,DISTRICT
SM304463,104.75,21.8333,Mộc Châu,ADM2,DISTRICT
SM304298,106.0173,10.7517,Mộc Hóa,ADM2,DISTRICT
SM304207,106.1063,20.9321,Mỹ Hào,ADM2,DISTRICT
SM304311,106.1232,20.4608,Mỹ Lộc,ADM2,DISTRICT
SM304543,106.3682,10.3622,Mỹ Tho,ADM2,DISTRICT
SM304454,105.8068,9.6085,Mỹ Tú,ADM2,DISTRICT
SM304455,105.8855,9.442,Mỹ Xuyên,ADM2,DISTRICT
SM304140,105.7143,20.6792,Mỹ Đức,ADM2,DISTRICT
SM262151,2.4503,9.7303,N'Dali,ADM2,DISTRICT
SM296832,15.3,12.05,N'Djamena,ADM2,DISTRICT
SM15251,13.4992,16.44,N'Gourti,ADM2,DISTRICT
SM260390,13.4096,-7.3163,N'Zeto,ADM2,DISTRICT
SM283955,35.2997,-13.9063,N'gauma,ADM2,DISTRICT
SM269487,-4.5813,6.828,N'zi,ADM2,DISTRICT
SM375407,69.9576,32.9509,N. Waziristan,ADM2:ADM3,DISTRICT
SM4907,121.0288,14.5185,NCR - Fourth District,ADM2,DISTRICT
SM4905,121.0838,14.5804,NCR - Second District,ADM2,DISTRICT
SM4906,121.0134,14.7342,NCR - Third District,ADM2,DISTRICT
SM2394,77.1,28.6667,NCT of Delhi (pre-1996),ADM1:ADM2,DISTRICT
SM239515,147.1884,-41.0586,NE Tasmanian Dialect Chain,LANGUAGE,LANGUAGE
SM458799,130.3643,-14.2284,NGAN'GI,ETHNICITY,ETHNICITY
SM458118,130.2552,-16.5904,NGARINYMAN,ETHNICITY,ETHNICITY
SM239062,93.2489,28.4315,Na,LANGUAGE,LANGUAGE
SM297213,99.5569,8.2698,Na Bon,ADM2,DISTRICT
SM297694,105.2237,14.567,Na Chaluai,ADM2,DISTRICT
SM297081,103.0573,15.8018,Na Chuak,ADM2,DISTRICT
SM297406,101.8456,14.1925,Na Di,ADM2,DISTRICT
SM297047,101.9964,17.5383,Na Duang,ADM2,DISTRICT
SM297070,103.23,15.7195,Na Dun,ADM2,DISTRICT
SM297048,100.9965,17.4394,Na Haeo,ADM2,DISTRICT
SM297106,104.4935,16.9574,Na Kae,ADM2,DISTRICT
SM297261,102.2146,17.3247,Na Klang,ADM2,DISTRICT
SM297597,100.5716,6.9599,Na Mom,ADM2,DISTRICT
SM296936,103.7802,16.5699,Na Mon,ADM2,DISTRICT
SM297245,100.6027,18.1252,Na Mun,ADM2,DISTRICT
SM297246,100.7239,18.3027,Na Noi,ADM2,DISTRICT
SM297414,102.9386,15.6957,Na Pho,ADM2,DISTRICT
SM304304,106.1122,22.1788,Na Rì,ADM2,DISTRICT
SM297598,100.6817,6.6385,Na Thawi,ADM2,DISTRICT
SM297107,104.1087,17.8213,Na Thom,ADM2,DISTRICT
SM297108,104.1183,17.4992,Na Wa,ADM2,DISTRICT
SM297262,102.0716,17.3429,Na Wang,ADM2,DISTRICT
SM297676,99.7479,7.5626,Na Yong,ADM2,DISTRICT
SM297732,102.1342,17.9347,Na Yung,ADM2,DISTRICT
SM237219,167.509,-16.5499,Na'ahai,LANGUAGE,LANGUAGE
SM305007,45.5286,14.5939,Na'man,ADM2,DISTRICT
SM277987,35.8138,31.8261,Na'ur,ADM2,DISTRICT
SM239064,87.3409,27.6926,Naaba,LANGUAGE,LANGUAGE
SM458016,114.7645,-28.1355,Naaguja,ETHNICITY,ETHNICITY
SM239067,155.632,-6.4602,Naasioi,LANGUAGE,LANGUAGE
SM239069,18.0777,12.7868,Naba,LANGUAGE,LANGUAGE
SM239072,146.966,-6.4271,Nabak,LANGUAGE,LANGUAGE
SM277122,82.55,19.2333,Nabarangapur,ADM2,DISTRICT
SM278700,136.1083,34.6264,Nabari,ADM2,DISTRICT
SM2350,35.5,33.25,Nabatiyeh,ADM1,DISTRICT
SM11423,-0.6521,10.8361,Nabdam,ADM2,DISTRICT
SM294625,52.3993,55.742,Naberezhnye Chelny,ADM2,DISTRICT
SM297927,10.6785,36.4802,Nabeul,ADM2,DISTRICT
SM2351,10.6667,36.6667,Nabeul,ADM1,DISTRICT
SM235522,134.098,-2.8068,Nabi,LANGUAGE,LANGUAGE
SM239077,142.329,-3.5283,Nabi-Metan,LANGUAGE,LANGUAGE
SM276533,135.5551,-3.5402,Nabire,ADM2,DISTRICT
SM239078,-0.6711,10.8578,Nabit,LANGUAGE,LANGUAGE
SM289747,35.2871,32.2406,Nablus,ADM1:ADM2,DISTRICT
SM273429,-79.0652,-3.3236,Nabón,ADM2,DISTRICT
SM282624,-92.9603,18.1386,Nacajuca,ADM2,DISTRICT
SM15075,40.6728,-14.5428,Nacala-Porto,ADM2,DISTRICT
SM15076,40.4835,-14.5624,Nacala-a-Velha (exc. Nacala Porto),ADM2,DISTRICT
SM275799,-87.5248,13.5221,Nacaome,ADM2,DISTRICT
SM239080,86.7001,27.4202,Nachering,LANGUAGE,LANGUAGE
SM299086,38.3442,-10.404,Nachingwea,ADM2,DISTRICT
SM263684,-42.1751,-18.4745,Nacip Raydan,ADM2,DISTRICT
SM296494,18.1755,59.3108,Nacka,ADM2,DISTRICT
SM282582,-109.9751,31.2592,Naco,ADM2,DISTRICT
SM302722,-94.6159,31.616,Nacogdoches,ADM2,DISTRICT
SM282585,-109.6209,30.4155,Nacozari de García,ADM2,DISTRICT
SM461279,-54.7816,-26.0695,Nacunday (1993-),ADM2,DISTRICT
SM260006,63.7907,31.578,Nad Ali,ADM2,DISTRICT
SM283290,-101.6334,27.0711,Nadadores,ADM2,DISTRICT
SM291169,24.7285,46.3309,Nades,ADM2,DISTRICT
SM294094,132,43.4167,Nadezhdinskiy rayon,ADM2,DISTRICT
SM298039,9.9961,36.1118,Nadhour,ADM2,DISTRICT
SM277361,88.5,23.25,Nadia,ADM2,DISTRICT
SM260076,69.6827,33.3812,Nadir Shah Kot,ADM2,DISTRICT
SM290518,20.7487,46.1666,Nadlac,ADM2,DISTRICT
SM4450,-3,35,Nador (2009-),ADM2,DISTRICT
SM11424,-2.6089,10.2884,Nadowli (2012-),ADM2,DISTRICT
SM292002,22.1538,45.6506,Nadrag,ADM2,DISTRICT
SM274419,177.6667,-18,Nadroga/Navosa,ADM2,DISTRICT
SM292736,45.25,43.5833,Nadterechnyy rayon,ADM2,DISTRICT
SM18254,24.5673,48.6339,Nadvirna Raion,ADM2,DISTRICT
SM17010,73,66,Nadymskiy Rayon,ADM2,DISTRICT
SM239082,-66.3068,-1.2445,Nadëb,LANGUAGE,LANGUAGE
SM289895,26.4894,45.0997,Naeni,ADM2,DISTRICT
SM15301,11.7865,64.9279,Naeroysund,ADM2,DISTRICT
SM239089,-2.51,8.0052,Nafaanra,LANGUAGE,LANGUAGE
SM284754,11.2157,11.0907,Nafada,ADM2,DISTRICT
SM256051,55.33,30.09,Nafar,ETHNICITY,ETHNICITY
SM239091,146.815,-6.4331,Nafi,LANGUAGE,LANGUAGE
SM295438,160.8878,-8.7387,Nafinua,ADM2,DISTRICT
SM239092,140.727,-2.6504,Nafri,LANGUAGE,LANGUAGE
SM261924,46.8116,40.5052,Naftalan,ADM1:ADM2,DISTRICT
SM239093,9.8333,33.4398,Nafusi,LANGUAGE,LANGUAGE
SM256052,91.11,26.02,Naga,ETHNICITY,ETHNICITY
SM256052,92.3,26.21,Naga,ETHNICITY,ETHNICITY
SM256054,120.87,23.29,Naga,ETHNICITY,ETHNICITY
SM256055,94,26,Naga Angami,ETHNICITY,ETHNICITY
SM256056,94,27,Naga Ao,ETHNICITY,ETHNICITY
SM14186,100.5575,5.4942,Naga Lilit,ADM2,DISTRICT
SM256062,94,27,Naga Lotha,ETHNICITY,ETHNICITY
SM239094,93.4438,25.2508,Naga Pidgin,LANGUAGE,LANGUAGE
SM256069,94,26,Naga Rengma,ETHNICITY,ETHNICITY
SM256071,95,26,Naga Sumi,ETHNICITY,ETHNICITY
SM13029,136.2627,35.4226,Nagahama-shi (2010-),ADM2,DISTRICT
SM279545,140,38.1667,Nagai,ADM2,DISTRICT
SM278049,137.049,35.1834,Nagakute,ADM2,DISTRICT
SM2352,94.5,26.0833,Nagaland,ADM1,DISTRICT
SM276289,96.5167,4.1667,Nagan Raya,ADM2,DISTRICT
SM2353,138.0408,36.1346,Nagano,ADM1:ADMX,DISTRICT
SM13030,138.1169,36.6526,Nagano Shi (2010-),ADM2,DISTRICT
SM13031,133.6798,33.7931,Nagaoka Gun,ADM2,DISTRICT
SM13032,138.8568,37.4452,Nagaoka Shi,ADM2,DISTRICT
SM278676,135.6788,34.9284,Nagaokakyō,ADM2,DISTRICT
SM11672,92.6923,26.3504,Nagaon (2015-),ADM2,DISTRICT
SM277222,79.74,10.85,Nagappattinam,ADM2,DISTRICT
SM5138,74.6235,36.2733,Nagar (2015-),ADM2:ADM3,DISTRICT
SM239098,75.8519,25.5399,Nagarchal,LANGUAGE,LANGUAGE
SM279370,139.9027,35.8563,Nagareyama,ADM2,DISTRICT
SM11673,78.3296,16.4839,Nagarkurnool,ADM2,DISTRICT
SM285422,-86.6247,12.2003,Nagarote,ADM2,DISTRICT
SM256083,147,-6.51,Nagarwapuna,ETHNICITY,ETHNICITY
SM278871,129.8272,32.7821,Nagasaki,ADM2,DISTRICT
SM279578,131.147,34.3488,Nagato,ADM2,DISTRICT
SM277180,74.25,27,Nagaur,ADM2,DISTRICT
SM292758,59.75,53.5833,Nagaybakskiy rayon,ADM2,DISTRICT
SM269360,88.9315,32.6636,Nagchu,ADM2,DISTRICT
SM239108,121.31,-8.7051,Nage,LANGUAGE,LANGUAGE
SM276494,121.2096,-8.8721,Nagekeo,ADM2,DISTRICT
SM12566,-76.8882,17.9642,Naggo Head,ADM2,DISTRICT
SM13703,26.8993,56.7222,Naglu pagasts,ADM2,DISTRICT
SM279062,128.0247,26.5851,Nago,ADM2,DISTRICT
SM293271,50.8333,59.5,Nagorskiy rayon,ADM2,DISTRICT
SM278050,136.9064,35.1815,Nagoya,ADM2,DISTRICT
SM277042,79.1075,21.1491,Nagpur,ADM2,DISTRICT
SM271792,-70,19.3667,Nagua,ADM2,DISTRICT
SM2354,-65.7599,18.2314,Naguabo,ADM1,DISTRICT
SM15963,-65.7351,18.2115,Naguabo Barrio-Pueblo,ADM2,DISTRICT
SM303866,-68.0189,10.2565,Naguanagua,ADM2,DISTRICT
SM239121,13.4264,8.7162,Nagumi,LANGUAGE,LANGUAGE
SM276172,17.3752,46.252,Nagyatád,ADM2,DISTRICT
SM276220,17.0443,46.5187,Nagykanizsai/Zalakaros,ADM1:ADM2,DISTRICT
SM11583,19.7926,47.0356,Nagykoroesi Jaras,ADM2,DISTRICT
SM276182,21.8475,47.7999,Nagykálló,ADM2,DISTRICT
SM276163,19.7392,47.3911,Nagykáta,ADM2,DISTRICT
SM256091,-111.13,68.98,Nagyuktogmiut,ETHNICITY,ETHNICITY
SM295384,159.9819,-9.433,Naha,ADM2,DISTRICT
SM279063,127.6865,26.2108,Naha,ADM2,DISTRICT
SM239125,74.827,21.43,Nahali-Baglani,LANGUAGE,LANGUAGE
SM256093,-129.5,58.69,Nahane,ETHNICITY,ETHNICITY
SM239126,81.9775,20.4118,Nahari,LANGUAGE,LANGUAGE
SM277481,48.2333,34.2333,Nahavand,ADM2,DISTRICT
SM245124,167.477,-16.5116,Nahavaq,LANGUAGE,LANGUAGE
SM256094,167.47,-16.51,Nahavaq,ETHNICITY,ETHNICITY
SM256095,48.04,16.45,Nahd,ETHNICITY,ETHNICITY
SM239130,96.0389,27.2381,Nahenic,LANGUAGE,LANGUAGE
SM268424,89.8234,27.4246,Nahi,ADM2,DISTRICT
SM262213,-1.25,11.25,Nahouri,ADM1:ADM2,DISTRICT
SM296024,28.2861,9.1106,Nahr Lol,ADM2,DISTRICT
SM260007,64.6124,31.8348,Nahri Sarraj,ADM2,DISTRICT
SM260246,67.215,36.8466,Nahri Shahi,ADM2,DISTRICT
SM260191,69.0284,35.9982,Nahrin,ADM2,DISTRICT
SM275336,-91.4162,14.7755,Nahualá,ADM2,DISTRICT
SM256097,-105.31,20.86,Nahuatl,ETHNICITY,ETHNICITY
SM256097,-103.75,19.7,Nahuatl,ETHNICITY,ETHNICITY
SM256097,-100.93,19.64,Nahuatl,ETHNICITY,ETHNICITY
SM256097,-98.42,20.51,Nahuatl,ETHNICITY,ETHNICITY
SM256097,-95.78,17.67,Nahuatl,ETHNICITY,ETHNICITY
SM245228,-97.1266,18.3969,"Nahuatl, Southeastern Puebla",LANGUAGE,LANGUAGE
SM282055,-101.896,19.6303,Nahuatzen,ADM2,DISTRICT
SM295571,-89.7022,13.8059,Nahuizalco,ADM2,DISTRICT
SM295572,-89.7231,13.6692,Nahulingo,ADM2,DISTRICT
SM239136,141.273,-3.7303,Nai,LANGUAGE,LANGUAGE
SM290033,21.5681,44.8758,Naidas,ADM2,DISTRICT
SM250168,5,35,Nail,ETHNICITY,ETHNICITY
SM12567,-77.6014,17.9621,Nain,ADM2,DISTRICT
SM17962,125.5878,-8.56,Nain Feto,ADM2,DISTRICT
SM277354,79.4167,29.2,Nainital (1997-),ADM2,DISTRICT
SM256106,76.49,10.34,Nair,ETHNICITY,ETHNICITY
SM256106,76.49,10.34,Nair,ETHNICITY,ETHNICITY
SM256106,76,11,Nair,ETHNICITY,ETHNICITY
SM256106,76,11,Nair,ETHNICITY,ETHNICITY
SM2355,36.82,-1.29,Nairobi,ADM1:ADM2,DISTRICT
SM2355,36.82,-1.29,Nairobi,ADM1:ADM2,DISTRICT
SM2355,36.8333,-1.2833,Nairobi,ADM1:ADM2,DISTRICT
SM2355,36.8333,-1.2833,Nairobi,ADM1:ADM2,DISTRICT
SM274406,178.25,-17.8333,Naitasiri,ADM2,DISTRICT
SM273857,32.2814,26.0499,Naj' Hammadi,ADM2,DISTRICT
SM305833,43.7188,31.0213,Najaf,ADM2,DISTRICT
SM227530,-3.3264,14.9066,Najamba-Kindige,LANGUAGE,LANGUAGE
SM270990,-77.7468,21.0721,Najasa,ADM2,DISTRICT
SM239147,42.1773,30.3371,Najdi Arabic,LANGUAGE,LANGUAGE
SM304851,43.5452,15.6527,Najrah,ADM2,DISTRICT
SM17798,44.0833,17.55,Najran,ADM2,DISTRICT
SM2356,46.9167,18.4167,Najran,ADM1,DISTRICT
SM280595,126.6736,35.0568,Naju,ADM2,DISTRICT
SM279412,134.2607,33.8007,Naka,ADM2,DISTRICT
SM239154,128.516,-2.8554,Naka'ela,LANGUAGE,LANGUAGE
SM18515,140.5806,36.4608,Naka-gun (Ibaraki),ADM2,DISTRICT
SM13033,139.2746,35.3155,Naka-gun (Kanagawa),ADM2,DISTRICT
SM13034,127.7772,26.3092,Nakagami-gun,ADM2,DISTRICT
SM279692,130.422,33.4663,Nakagawa,ADM2,DISTRICT
SM13035,143.4474,42.8885,Nakagawa (Teshio),ADM2,DISTRICT
SM13036,142.2546,44.6402,Nakagawa (Tokachi),ADM2,DISTRICT
SM239156,140.512,-5.1938,Nakai,LANGUAGE,LANGUAGE
SM280869,105.2915,17.8689,Nakai,ADM2,DISTRICT
SM256110,128.19,-27.31,Nakako,ETHNICITY,ETHNICITY
SM256110,128.19,-27.31,Nakako,ETHNICITY,ETHNICITY
SM256110,128.5109,-27.2228,Nakako,ETHNICITY,ETHNICITY
SM256110,128.5109,-27.2228,Nakako,ETHNICITY,ETHNICITY
SM239155,146.782,-6.4142,Nakama,LANGUAGE,LANGUAGE
SM279693,130.7086,33.8208,Nakama,ADM2,DISTRICT
SM324078,-114.2133,53.8806,Nakamun Park,ADM2:ADM3,DISTRICT
SM239153,150.888,-5.5993,Nakanai,LANGUAGE,LANGUAGE
SM256111,151.78,-5.26,Nakanai,ETHNICITY,ETHNICITY
SM256111,151.78,-5.26,Nakanai,ETHNICITY,ETHNICITY
SM256111,150.81,-5.5,Nakanai,ETHNICITY,ETHNICITY
SM256111,150.81,-5.5,Nakanai,ETHNICITY,ETHNICITY
SM13037,137.4942,36.6327,Nakaniikawa Gun,ADM2,DISTRICT
SM279457,139.6625,35.7109,Nakano,ADM2,DISTRICT
SM278825,138.3472,36.7694,Nakano,ADM2,DISTRICT
SM278413,136.8947,36.9767,Nakanoto,ADM2,DISTRICT
SM4729,34.596,1.9072,Nakapiripirit (2009-),ADM1:ADM2,DISTRICT
SM239151,134.3273,-12.0765,Nakara,LANGUAGE,LANGUAGE
SM256112,133.37,-11.42,Nakara,ETHNICITY,ETHNICITY
SM256112,133.37,-11.42,Nakara,ETHNICITY,ETHNICITY
SM256112,134.3642,-12.0845,Nakara,ETHNICITY,ETHNICITY
SM256112,134.3642,-12.0845,Nakara,ETHNICITY,ETHNICITY
SM299329,32.1897,1.0406,Nakaseke,ADM1:ADM2,DISTRICT
SM2358,32.4161,1.389,Nakasongola,ADM1:ADM2,DISTRICT
SM13038,133.8833,34.15,Nakatado-gun,ADM2,DISTRICT
SM279004,131.1264,33.4646,Nakatsu,ADM2,DISTRICT
SM279805,137.4743,35.5666,Nakatsugawa,ADM2,DISTRICT
SM239160,145.655,-5.0909,Nake,LANGUAGE,LANGUAGE
SM14073,-8.5,5,Nake,ADM2,DISTRICT
SM273992,38.4667,16.6667,Nakfa,ADM2,DISTRICT
SM2359,45.4182,39.2099,Nakhchivan,ADM1,DISTRICT
SM9745,45.4167,39.2,Nakhchivan (excl. Nakhchivan City),ADM2,DISTRICT
SM9746,45.4175,39.2094,Nakhchivan City,ADM2,DISTRICT
SM299711,33.5809,44.695,Nakhimovs'kyi,ADM2,DISTRICT
SM297097,100.1816,13.8117,Nakhon Chaisi,ADM2,DISTRICT
SM297390,100.6257,14.467,Nakhon Luang,ADM2,DISTRICT
SM2360,101.1,14.1667,Nakhon Nayok,ADM1,DISTRICT
SM2361,100.0833,13.9833,Nakhon Pathom,ADM1,DISTRICT
SM2362,104.4833,17.4861,Nakhon Phanom (1982-),ADM1,DISTRICT
SM2363,102.1667,15,Nakhon Ratchasima,ADM1,DISTRICT
SM2364,100.0833,15.7,Nakhon Sawan,ADM1,DISTRICT
SM2365,99.822,8.391,Nakhon Si Thammarat,ADM1,DISTRICT
SM297372,100.8644,17.0943,Nakhon Thai,ADM2,DISTRICT
SM239164,10.2378,6.6278,Naki,LANGUAGE,LANGUAGE
SM296208,14.2867,46.2936,Naklo,ADM1:ADM2,DISTRICT
SM305193,32.6485,-9.4866,Nakonde,ADM2,DISTRICT
SM2366,36.1713,-0.484,Nakuru,ADM1:ADM2,DISTRICT
SM239168,141.601,-4.2741,Nakwi,LANGUAGE,LANGUAGE
SM288847,17.5899,53.0948,Nakło,ADM2,DISTRICT
SM280876,101.4167,20.5,Nalae,ADM2,DISTRICT
SM277418,85.5833,25.25,Nalanda,ADM2,DISTRICT
SM292062,28.5983,45.0412,Nalbant,ADM2,DISTRICT
SM277391,91.4,26.5,Nalbari (2004-),ADM2,DISTRICT
SM458458,152.8859,-26.7693,Nalbo,ETHNICITY,ETHNICITY
SM239169,139.883,-4.2607,Nalca,LANGUAGE,LANGUAGE
SM17011,43.5833,43.5,Nalchik Urban Okrug,ADM2,DISTRICT
SM11674,79.5,17.1667,Nalgonda (2016-),ADM2,DISTRICT
SM239174,147.202,-2.1427,Nali,LANGUAGE,LANGUAGE
SM260090,71.5407,35.2672,Nali,ADM2,DISTRICT
SM239173,151.332,-2.9441,Nalik,LANGUAGE,LANGUAGE
SM298894,31.3632,40.1944,Nallıhan,ADM2,DISTRICT
SM18158,22.9954,-15.7739,Nalolo District,ADM2,DISTRICT
SM239177,-14.8188,11.0397,Nalu,LANGUAGE,LANGUAGE
SM250542,-14.37,11.29,Nalu,ETHNICITY,ETHNICITY
SM239176,102.598,25.97,Naluo Yi,LANGUAGE,LANGUAGE
SM2367,10.8333,30.1833,Nalut,ADM1,DISTRICT
SM239175,165.8,-10.8,Nalögo,LANGUAGE,LANGUAGE
SM239208,99.43,36.11,Nam,LANGUAGE,LANGUAGE
SM280673,128.588,35.8412,Nam,ADM2,DISTRICT
SM280639,129.3292,35.5438,Nam,ADM2,DISTRICT
SM280568,126.65,37.4636,Nam,ADM2,DISTRICT
SM280541,129.0827,35.1365,Nam,ADM2,DISTRICT
SM304400,107.6027,15.632,Nam Giang,ADM2,DISTRICT
SM297557,104.5255,14.9102,Nam Kliang,ADM2,DISTRICT
SM297345,101.6008,16.8449,Nam Nao,ADM2,DISTRICT
SM297745,100.7274,17.7003,Nam Pat,ADM2,DISTRICT
SM296980,102.8813,16.6995,Nam Phong,ADM2,DISTRICT
SM297714,102.1733,17.7434,Nam Som,ADM2,DISTRICT
SM304072,106.3404,21.0048,Nam Sách,ADM2,DISTRICT
SM304401,108.0833,15.25,Nam Trà My,ADM2,DISTRICT
SM304313,106.2082,20.3411,Nam Trực,ADM2,DISTRICT
SM304142,105.7632,21.0364,Nam Từ Liêm,ADM2,DISTRICT
SM297695,105.0027,14.4867,Nam Yun,ADM2,DISTRICT
SM304338,105.5258,18.6704,Nam Đàn,ADM2,DISTRICT
SM304484,107.6927,16.124,Nam Đông,ADM2,DISTRICT
SM304312,106.1719,20.4297,Nam Định,ADM2,DISTRICT
SM2368,106.25,20.25,Nam Định,ADM1,DISTRICT
SM17883,126.9094,35.1216,Nam-gu,ADM2,DISTRICT
SM250543,15.62,-25.49,Nama,ETHNICITY,ETHNICITY
SM250543,15.62,-25.49,Nama,ETHNICITY,ETHNICITY
SM250543,15.62,-25.49,Nama,ETHNICITY,ETHNICITY
SM250543,15.62,-25.49,Nama,ETHNICITY,ETHNICITY
SM250543,18.47,-27.34,Nama,ETHNICITY,ETHNICITY
SM250543,18.47,-27.34,Nama,ETHNICITY,ETHNICITY
SM250543,18.47,-27.34,Nama,ETHNICITY,ETHNICITY
SM250543,18.47,-27.34,Nama,ETHNICITY,ETHNICITY
SM250543,20.68,-30.81,Nama,ETHNICITY,ETHNICITY
SM250543,20.68,-30.81,Nama,ETHNICITY,ETHNICITY
SM250543,20.68,-30.81,Nama,ETHNICITY,ETHNICITY
SM250543,20.68,-30.81,Nama,ETHNICITY,ETHNICITY
SM250543,18,-26,Nama,ETHNICITY,ETHNICITY
SM250543,18,-26,Nama,ETHNICITY,ETHNICITY
SM250543,18,-26,Nama,ETHNICITY,ETHNICITY
SM250543,18,-26,Nama,ETHNICITY,ETHNICITY
SM250543,17,-27.5,Nama,ETHNICITY,ETHNICITY
SM250543,17,-27.5,Nama,ETHNICITY,ETHNICITY
SM250543,17,-27.5,Nama,ETHNICITY,ETHNICITY
SM250543,17,-27.5,Nama,ETHNICITY,ETHNICITY
SM250543,17.2609,-24.2661,Nama,ETHNICITY,ETHNICITY
SM250543,17.2609,-24.2661,Nama,ETHNICITY,ETHNICITY
SM250543,17.2609,-24.2661,Nama,ETHNICITY,ETHNICITY
SM250543,17.2609,-24.2661,Nama,ETHNICITY,ETHNICITY
SM239180,17.9767,-25.0747,Nama (Namibia),LANGUAGE,LANGUAGE
SM239182,141.706,-8.664,Nama (Papua New Guinea),LANGUAGE,LANGUAGE
SM283932,32.18,-26.109,Namaacha,ADM2,DISTRICT
SM283880,37.0261,-17.4914,Namacurra,ADM2,DISTRICT
SM9566,69.7147,36.4715,Namak Ab,ADM2,DISTRICT
SM277223,78.13,11.3,Namakkal,ADM2,DISTRICT
SM260454,16.5475,-17.0876,Namakunde,ADM2,DISTRICT
SM239184,168.569,-16.8979,Namakura,LANGUAGE,LANGUAGE
SM305139,19.4289,-30.5356,Namakwa,ADM2,DISTRICT
SM236402,167.415,-16.1331,Naman,LANGUAGE,LANGUAGE
SM303542,71.6472,40.9201,Namangan,ADM2,DISTRICT
SM2369,71.2478,40.9975,Namangan,ADM1,DISTRICT
SM283940,39.7731,-13.9186,Namapa,ADM2,DISTRICT
SM283881,36.8706,-15.9528,Namarroi,ADM2,DISTRICT
SM275828,-87.1357,13.1659,Namasigue,ADM2,DISTRICT
SM239183,141.641,-8.827,Namat,LANGUAGE,LANGUAGE
SM288591,152.8333,-4.3333,Namatanai,ADM2,DISTRICT
SM4766,33.809,0.278,Namayingo,ADM1:ADM2,DISTRICT
SM280884,102.3969,20.6102,Nambak,ADM2,DISTRICT
SM256127,-105.9,35.9,Nambe,ETHNICITY,ETHNICITY
SM256130,-61.02,-12.6,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-61.02,-12.6,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-61.02,-12.6,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-61.02,-12.6,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-61.02,-12.6,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59,-12.5,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59,-12.5,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59,-12.5,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59,-12.5,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59,-12.5,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59,-12,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59,-12,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59,-12,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59,-12,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59,-12,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59.55,-12.73,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59.55,-12.73,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59.55,-12.73,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59.55,-12.73,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-59.55,-12.73,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-58.75,-13,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-58.75,-13,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-58.75,-13,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-58.75,-13,Nambicuara,ETHNICITY,ETHNICITY
SM256130,-58.75,-13,Nambicuara,ETHNICITY,ETHNICITY
SM239192,141.931,-8.745,Nambo,LANGUAGE,LANGUAGE
SM260308,14.3372,-8.0483,Nambuangongo,ADM2,DISTRICT
SM261588,152.7102,-30.7083,Nambucca,ADM2,DISTRICT
SM239190,26.0394,-19.0899,Nambya,LANGUAGE,LANGUAGE
SM280569,126.7184,37.4183,Namdong,ADM2,DISTRICT
SM4085,168.1167,5.6,Namdrik Atoll,ADM1,DISTRICT
SM278386,140.4717,36.0653,Namegata,ADM2,DISTRICT
SM262206,-0.5,13.25,Namentenga,ADM1:ADM2,DISTRICT
SM279509,137.3813,36.7493,Namerikawa,ADM2,DISTRICT
SM280548,127.9271,34.8043,Namhae,ADM2,DISTRICT
SM239202,141.788,-3.8554,Namia,LANGUAGE,LANGUAGE
SM239204,148.166,-9.1809,Namiae,LANGUAGE,LANGUAGE
SM260369,12.463,-14.6127,Namibe,ADM2,DISTRICT
SM2370,12.66,-15.3321,Namibe,ADM1,DISTRICT
SM239200,17.048,-22.6,Namibian Sign Language,LANGUAGE,LANGUAGE
SM256137,141.36,-3.73,Namie,ETHNICITY,ETHNICITY
SM283253,-107.3229,29.1587,Namiquipa,ADM2,DISTRICT
SM4752,34.3825,0.8163,Namisindwa,ADM1:ADM2,DISTRICT
SM239207,140.66,-3.58,Namla,LANGUAGE,LANGUAGE
SM239211,141.824,-9.1242,Namo,LANGUAGE,LANGUAGE
SM290454,27.5631,45.5238,Namoloasa,ADM2,DISTRICT
SM256138,153.12,5.92,Namoluk,ETHNICITY,ETHNICITY
SM256138,153.12,5.92,Namoluk,ETHNICITY,ETHNICITY
SM256138,152.74,6.89,Namoluk,ETHNICITY,ETHNICITY
SM256138,152.74,6.89,Namoluk,ETHNICITY,ETHNICITY
SM14950,153.136,5.915,Namoluk Municipality,ADM2,DISTRICT
SM239212,149.6852,8.5846,Namonuito,LANGUAGE,LANGUAGE
SM256139,149.87,8.63,Namonuito,ETHNICITY,ETHNICITY
SM280894,101.7277,20.8908,Namor,ADM2,DISTRICT
SM274407,178.1333,-18.05,Namosi,ADM2,DISTRICT
SM239213,177.637,-18.5308,Namosi-Naitasiri-Serua,LANGUAGE,LANGUAGE
SM324171,-117.1346,56.0384,Nampa,ADM2:ADM3,DISTRICT
SM2371,39,-15,Nampula,ADM1,DISTRICT
SM15077,39.133,-15.1307,Nampula District  (excl. Nampula city),ADM2,DISTRICT
SM277198,95.8618,27.6673,Namsai,ADM2,DISTRICT
SM294311,129.4167,63,Namskiy rayon,ADM2,DISTRICT
SM461881,11.5418,64.4784,Namsos (2020-),ADM2,DISTRICT
SM286456,13.1593,64.926,Namsskogan,ADM2,DISTRICT
SM280877,101.4479,21.0042,Namtha,ADM2,DISTRICT
SM299163,36.1301,-10.4664,Namtumbo,ADM2,DISTRICT
SM4086,168.1667,8,Namu Atoll,ADM1,DISTRICT
SM283851,38.819,-13.621,Namuno,ADM2,DISTRICT
SM262102,4.8333,50.3333,Namur,ADM1:ADM2,DISTRICT
SM239221,120.9754,-8.579,Namut-Nginamanu,LANGUAGE,LANGUAGE
SM239218,101.898,28.5909,Namuyi,LANGUAGE,LANGUAGE
SM305219,26.7646,-15.9107,Namwala,ADM2,DISTRICT
SM250609,32.07,-10.03,Namwanga,ETHNICITY,ETHNICITY
SM250609,32.07,-10.03,Namwanga,ETHNICITY,ETHNICITY
SM250609,32.94,-10.35,Namwanga,ETHNICITY,ETHNICITY
SM250609,32.94,-10.35,Namwanga,ETHNICITY,ETHNICITY
SM250609,32,-10,Namwanga,ETHNICITY,ETHNICITY
SM250609,32,-10,Namwanga,ETHNICITY,ETHNICITY
SM280577,127.3801,35.4482,Namwon,ADM2,DISTRICT
SM280710,127.2401,37.6522,Namyangju,ADM2,DISTRICT
SM289001,17.763,51.0163,Namysłów,ADM2,DISTRICT
SM280885,101.9456,19.4001,Nan,ADM2,DISTRICT
SM2372,100.6667,18.8333,Nan,ADM1,DISTRICT
SM279547,140.1561,38.1032,Nan'yo,ADM2,DISTRICT
SM289963,26.592,44.2677,Nana,ADM2,DISTRICT
SM370225,-8.7167,4.8333,Nana Kru,ADM2:ADM3,DISTRICT
SM10244,17.3792,6.9723,Nana-Bakassa,ADM2,DISTRICT
SM2373,19.3333,7.0833,Nana-Grébizi,ADM1,DISTRICT
SM2374,15.2628,5.9712,Nana-Mambéré,ADM1,DISTRICT
SM239226,-0.95,5.2456,Nanabin Sign Language,LANGUAGE,LANGUAGE
SM282692,-98.5333,19.5055,Nanacamilpa de Mariano Arista,ADM2,DISTRICT
SM458060,123.4876,-26.0641,Nanadjara,ETHNICITY,ETHNICITY
SM278317,140.7031,41.9543,Nanae,ADM2,DISTRICT
SM239222,134.797,48.4343,Nanai,LANGUAGE,LANGUAGE
SM256141,134.54,46,Nanaian,ETHNICITY,ETHNICITY
SM256141,134.54,46,Nanaian,ETHNICITY,ETHNICITY
SM256141,134.54,46,Nanaian,ETHNICITY,ETHNICITY
SM256141,134.54,46,Nanaian,ETHNICITY,ETHNICITY
SM256141,134.54,46,Nanaian,ETHNICITY,ETHNICITY
SM256141,132,47,Nanaian,ETHNICITY,ETHNICITY
SM256141,132,47,Nanaian,ETHNICITY,ETHNICITY
SM256141,132,47,Nanaian,ETHNICITY,ETHNICITY
SM256141,132,47,Nanaian,ETHNICITY,ETHNICITY
SM256141,132,47,Nanaian,ETHNICITY,ETHNICITY
SM256141,136.51,49.44,Nanaian,ETHNICITY,ETHNICITY
SM256141,136.51,49.44,Nanaian,ETHNICITY,ETHNICITY
SM256141,136.51,49.44,Nanaian,ETHNICITY,ETHNICITY
SM256141,136.51,49.44,Nanaian,ETHNICITY,ETHNICITY
SM256141,136.51,49.44,Nanaian,ETHNICITY,ETHNICITY
SM256141,133.5,47.4976,Nanaian,ETHNICITY,ETHNICITY
SM256141,133.5,47.4976,Nanaian,ETHNICITY,ETHNICITY
SM256141,133.5,47.4976,Nanaian,ETHNICITY,ETHNICITY
SM256141,133.5,47.4976,Nanaian,ETHNICITY,ETHNICITY
SM256141,133.5,47.4976,Nanaian,ETHNICITY,ETHNICITY
SM256141,133.5,47.5,Nanaian,ETHNICITY,ETHNICITY
SM256141,133.5,47.5,Nanaian,ETHNICITY,ETHNICITY
SM256141,133.5,47.5,Nanaian,ETHNICITY,ETHNICITY
SM256141,133.5,47.5,Nanaian,ETHNICITY,ETHNICITY
SM256141,133.5,47.5,Nanaian,ETHNICITY,ETHNICITY
SM268752,-124.3361,49.2496,Nanaimo,ADM2,DISTRICT
SM256143,-124.25,49.34,Nanamo,ETHNICITY,ETHNICITY
SM278414,136.9286,37.0784,Nanao,ADM2,DISTRICT
SM15353,-57.7006,-25.2828,Nanawa,ADM2,DISTRICT
SM301737,-97.9923,41.3973,Nance,ADM2,DISTRICT
SM239228,16.1282,9.3198,Nancere,LANGUAGE,LANGUAGE
SM239229,115.8575,28.7066,Nanchang,DIALECT,LANGUAGE
SM269229,115.9747,28.9316,Nanchang,ADM2,DISTRICT
SM14352,-94.3895,18.0588,Nanchital de Lazaro Cardenas del Rio,ADM2,DISTRICT
SM269324,106.2317,31.2102,Nanchong,ADM2,DISTRICT
SM260423,18.7388,-16.3968,Nancova,ADM2,DISTRICT
SM285538,-86.0193,11.7145,Nandaime,ADM2,DISTRICT
SM285446,-86.1167,11.9333,Nandasmo,ADM2,DISTRICT
SM270930,-85.3116,9.8965,Nandayure,ADM2,DISTRICT
SM239231,29.0243,-0.249,Nande,LANGUAGE,LANGUAGE
SM277044,77.5,18.9167,Nanded,ADM2,DISTRICT
SM239232,35.4085,0.7937,Nandi,LANGUAGE,LANGUAGE
SM250544,36.53,1,Nandi,ETHNICITY,ETHNICITY
SM250544,36.53,1,Nandi,ETHNICITY,ETHNICITY
SM250544,36.53,1,Nandi,ETHNICITY,ETHNICITY
SM250544,35.69,-0.21,Nandi,ETHNICITY,ETHNICITY
SM250544,35.69,-0.21,Nandi,ETHNICITY,ETHNICITY
SM250544,35.69,-0.21,Nandi,ETHNICITY,ETHNICITY
SM250544,35,0,Nandi,ETHNICITY,ETHNICITY
SM250544,35,0,Nandi,ETHNICITY,ETHNICITY
SM250544,35,0,Nandi,ETHNICITY,ETHNICITY
SM250544,35.5,0,Nandi,ETHNICITY,ETHNICITY
SM250544,35.5,0,Nandi,ETHNICITY,ETHNICITY
SM250544,35.5,0,Nandi,ETHNICITY,ETHNICITY
SM2375,35.0831,0.3085,"Nandi (pre-2002, 2010-)",ADM1:ADM2,DISTRICT
SM2375,35.0831,0.3085,"Nandi (pre-2002, 2010-)",ADM1:ADM2,DISTRICT
SM2375,35.193,0.055,"Nandi (pre-2002, 2010-)",ADM1:ADM2,DISTRICT
SM2375,35.193,0.055,"Nandi (pre-2002, 2010-)",ADM1:ADM2,DISTRICT
SM11425,-2.7931,10.854,Nandom,ADM2,DISTRICT
SM277045,74.2,21.37,Nandurbar,ADM2,DISTRICT
SM14074,-8.1438,5.1747,Nanee District,ADM2,DISTRICT
SM239233,-4.9215,11.7548,Nanerigé Sénoufo,LANGUAGE,LANGUAGE
SM292358,27.4999,45.578,Nanesti,ADM2,DISTRICT
SM297415,102.7667,14.6333,Nang Rong,ADM2,DISTRICT
SM239239,-3.02,14.49,Nanga,LANGUAGE,LANGUAGE
SM283852,39.7755,-11.1574,Nangade,ADM2,DISTRICT
SM239235,70.909,34.9784,Nangalami,DIALECT,LANGUAGE
SM2376,70.4167,34.3333,Nangarhar,ADM1,DISTRICT
SM273578,-78.7564,-4.3262,Nangaritza,ADM2,DISTRICT
SM285261,10.9714,11.8204,Nangere,ADM2,DISTRICT
SM239240,165.887,-10.7441,Nanggu,LANGUAGE,LANGUAGE
SM295481,166.0514,-10.7558,Nanggu-Lord Howe,ADM2,DISTRICT
SM10245,16.9197,7.0639,Nangha Boguila,ADM2,DISTRICT
SM239238,131.237,-14.268,Nangikurrunggurr,LANGUAGE,LANGUAGE
SM268447,90.8076,27.2011,Nangkor,ADM2,DISTRICT
SM226881,17.2691,8.6001,Nangnda,LANGUAGE,LANGUAGE
SM269216,118.8729,31.9657,Nanjing,ADM2,DISTRICT
SM239244,118.4576,32.099,Nanjing Mandarin,DIALECT,LANGUAGE
SM279066,127.7787,26.1637,Nanjō,ADM2,DISTRICT
SM256155,-1,10,Nankana,ETHNICITY,ETHNICITY
SM375462,73.6988,31.482,Nankana Sahib,ADM2:ADM3,DISTRICT
SM239247,146.464,-5.808,Nankina,LANGUAGE,LANGUAGE
SM278591,133.623,33.613,Nankoku,ADM2,DISTRICT
SM286038,11.012,60.2171,Nannestad,ADM2,DISTRICT
SM269439,108.316,22.816,Nanning,ADM2,DISTRICT
SM239250,108.3662,22.8487,Nanning Pinghua,DIALECT,LANGUAGE
SM261426,115.6738,-34.1347,Nannup,ADM2,DISTRICT
SM12568,-76.7695,17.9968,Nannyville,ADM2,DISTRICT
SM256156,-44.34,60.39,Nanoptaim,ETHNICITY,ETHNICITY
SM11506,-44.9654,60.1172,Nanortalik Municipality,ADM2,DISTRICT
SM291904,25.2889,43.9917,Nanov,ADM2,DISTRICT
SM269395,118.1981,27.304,Nanping,ADM2,DISTRICT
SM278677,135.5594,35.2274,Nantan,ADM2,DISTRICT
SM266724,-51.2084,-22.6033,Nantes,ADM2,DISTRICT
SM239256,-72.3507,-12.1018,Nanti,LANGUAGE,LANGUAGE
SM239255,-74.9865,39.6778,Nanticoke,LANGUAGE,LANGUAGE
SM256157,-74.5,39.62,Nanticoke,ETHNICITY,ETHNICITY
SM279510,136.9143,36.4526,Nanto,ADM2,DISTRICT
SM323852,-113.7742,50.3483,Nanton,ADM2:ADM3,DISTRICT
SM269217,121.0368,32.2626,Nantong,ADM2,DISTRICT
SM299072,120.927,23.8342,Nantou,ADM2,DISTRICT
SM301238,-70.0217,41.2696,Nantucket,ADM2,DISTRICT
SM239258,143.937,-4.7173,Nanubae,LANGUAGE,LANGUAGE
SM2377,176.3196,-6.2897,Nanumanga,ADM1,DISTRICT
SM274940,-0.0747,8.9245,Nanumba North,ADM2,DISTRICT
SM274941,0.1121,8.7922,Nanumba South,ADM2,DISTRICT
SM2378,176.0882,-5.6555,Nanumea,ADM1,DISTRICT
SM256158,-0.3,9.14,Nanuni,ETHNICITY,ETHNICITY
SM263685,-40.4444,-17.7357,Nanuque,ADM2,DISTRICT
SM269176,112.2744,33.1578,Nanyang,ADM2,DISTRICT
SM299125,38.3298,-11.0667,Nanyumbu,ADM2,DISTRICT
SM2379,129.6,33.2333,Naoasaki,ADM1:ADMX,DISTRICT
SM262270,88.9466,24.8118,Naogaon,ADM2,DISTRICT
SM282745,-96.8525,19.6367,Naolinco,ADM2,DISTRICT
SM279026,133.9846,34.4607,Naoshima,ADM2,DISTRICT
SM303183,-122.3305,38.5065,Napa,ADM2,DISTRICT
SM239264,-63.1,-15.3,Napeca,LANGUAGE,LANGUAGE
SM286550,176.8689,-39.4793,Napier,ADM2,DISTRICT
SM2380,-77.8316,-0.6292,Napo (1998-),ADM1,DISTRICT
SM239266,-76.3889,-0.0725,Napo Lowland Quechua,LANGUAGE,LANGUAGE
SM277945,14.4167,40.8833,Napoli,ADM2,DISTRICT
SM250613,35.03,4.7,Napore,ETHNICITY,ETHNICITY
SM291621,23.316,47.345,Napradea,ADM2,DISTRICT
SM261651,142.0656,-12.5532,Napranum,ADM2,DISTRICT
SM239269,120.338,-1.4371,Napu,LANGUAGE,LANGUAGE
SM11250,-141.2444,-14.1777,Napuka,ADM2,DISTRICT
SM277648,45.4,36.95,Naqadeh,ADM2,DISTRICT
SM263686,-42.3384,-19.1832,Naque,ADM2,DISTRICT
SM239283,84.2285,28.7671,Nar Phu,LANGUAGE,LANGUAGE
SM239273,37.499,15.3251,Nara,LANGUAGE,LANGUAGE
SM283363,-7.2902,15.1685,Nara,ADM2,DISTRICT
SM278905,135.8048,34.6851,Nara,ADM2,DISTRICT
SM2381,135.8329,34.6853,Nara,ADM1:ADMX,DISTRICT
SM250545,38,16,Nara,ETHNICITY,ETHNICITY
SM261715,140.5279,-36.9954,Naracoorte and Lucindale,ADM2,DISTRICT
SM262267,89.6,23.1333,Narail,ADM2,DISTRICT
SM239275,144.754,-5.5591,Narak,LANGUAGE,LANGUAGE
SM256161,143.94,-5.59,Narak,ETHNICITY,ETHNICITY
SM283681,113.6838,45.1302,Naran,ADM2,DISTRICT
SM283741,92.5674,49.3529,Naranbulag,ADM2,DISTRICT
SM266725,-51.5098,-22.534,Narandiba,ADM2,DISTRICT
SM260080,71.0045,34.8018,Narang Wa Badil,ADM2,DISTRICT
SM239274,167.047,-15.5259,Narango,LANGUAGE,LANGUAGE
SM282746,-96.9597,18.7984,Naranjal,ADM2,DISTRICT
SM273437,-79.6167,-2.6667,Naranjal,ADM2,DISTRICT
SM15355,-55.2035,-25.9566,Naranjal (2012-),ADM2,DISTRICT
SM15964,-67.0104,18.2077,Naranjales Barrio (Las Marías),ADM2,DISTRICT
SM15965,-67.0461,18.2082,Naranjales Barrio (Mayagüez),ADM2,DISTRICT
SM275769,-88.6,14.96,Naranjito,ADM2,DISTRICT
SM273438,-79.4833,-2.2167,Naranjito,ADM2,DISTRICT
SM2382,-66.2552,18.2897,Naranjito,ADM1,DISTRICT
SM15966,-66.8086,18.4364,Naranjito Barrio,ADM2,DISTRICT
SM15967,-66.2498,18.3015,Naranjito Barrio-Pueblo,ADM2,DISTRICT
SM270910,-84.393,10.1079,Naranjo,ADM2,DISTRICT
SM15968,-67.1577,18.3454,Naranjo Barrio (Aguada),ADM2,DISTRICT
SM15969,-66.1921,18.2288,Naranjo Barrio (Comerío),ADM2,DISTRICT
SM15970,-65.6933,18.3159,Naranjo Barrio (Fajardo),ADM2,DISTRICT
SM15971,-67.1116,18.3425,Naranjo Barrio (Moca),ADM2,DISTRICT
SM15972,-66.8545,18.1163,Naranjo Barrio (Yauco),ADM2,DISTRICT
SM279371,140.0342,35.6809,Narashino,ADM2,DISTRICT
SM2383,101.7201,6.2034,Narathiwat,ADM1,DISTRICT
SM239276,139.876,-3.4359,Narau,LANGUAGE,LANGUAGE
SM262243,90.5833,23.75,Narayanganj,ADM2,DISTRICT
SM277454,81.0812,19.6043,Narayanpur,ADM2,DISTRICT
SM262698,-64.852,-18.2884,Narciso Campero,ADM2,DISTRICT
SM261427,118.6899,-32.0221,Narembeen,ADM2,DISTRICT
SM458189,144.4,-34.4187,Nari Nari,ETHNICITY,ETHNICITY
SM239278,144.3451,-34.5309,Nari Nari,DIALECT,LANGUAGE
SM283640,101.4598,45.9595,Nariinteel,ADM2,DISTRICT
SM239277,33.3312,4.8033,Narim,LANGUAGE,LANGUAGE
SM293844,47.5,46.4167,Narimanovskiy rayon,ADM2,DISTRICT
SM10430,-77.346,1.3029,Narino,ADM2,DISTRICT
SM279372,140.3576,35.8155,Narita,ADM2,DISTRICT
SM270241,-75.1933,5.5969,Nariño,ADM2,DISTRICT
SM270026,-74.8224,4.3991,Nariño,ADM2,DISTRICT
SM2384,-78,1.5,Nariño,ADM1,DISTRICT
SM298544,27.0243,38.3896,Narlıdere,ADM2,DISTRICT
SM276824,73.4953,21.8738,Narmada,ADM2,DISTRICT
SM298390,41.87,40.3449,Narman,ADM2,DISTRICT
SM239280,20.7458,-22.0939,Naro,LANGUAGE,LANGUAGE
SM17012,36.75,55.4167,Naro-Fominskiy Rayon (2012-),ADM2,DISTRICT
SM299848,29.0833,51.25,Narodyts'kyi,ADM2,DISTRICT
SM2385,35.7356,-1.2408,"Narok (pre-1994, 2010-)",ADM1:ADM2,DISTRICT
SM239282,114.011,4.4212,Narom,LANGUAGE,LANGUAGE
SM250546,21.46,-21.44,Naron,ETHNICITY,ETHNICITY
SM250546,21.46,-21.44,Naron,ETHNICITY,ETHNICITY
SM250546,21.46,-21.44,Naron,ETHNICITY,ETHNICITY
SM250546,21.46,-21.44,Naron,ETHNICITY,ETHNICITY
SM250546,21.46,-21.44,Naron,ETHNICITY,ETHNICITY
SM250546,21,-22,Naron,ETHNICITY,ETHNICITY
SM250546,21,-22,Naron,ETHNICITY,ETHNICITY
SM250546,21,-22,Naron,ETHNICITY,ETHNICITY
SM250546,21,-22,Naron,ETHNICITY,ETHNICITY
SM250546,21,-22,Naron,ETHNICITY,ETHNICITY
SM250546,24,-20,Naron,ETHNICITY,ETHNICITY
SM250546,24,-20,Naron,ETHNICITY,ETHNICITY
SM250546,24,-20,Naron,ETHNICITY,ETHNICITY
SM250546,24,-20,Naron,ETHNICITY,ETHNICITY
SM250546,24,-20,Naron,ETHNICITY,ETHNICITY
SM250546,21.61,-21.64,Naron,ETHNICITY,ETHNICITY
SM250546,21.61,-21.64,Naron,ETHNICITY,ETHNICITY
SM250546,21.61,-21.64,Naron,ETHNICITY,ETHNICITY
SM250546,21.61,-21.64,Naron,ETHNICITY,ETHNICITY
SM250546,21.61,-21.64,Naron,ETHNICITY,ETHNICITY
SM250546,21.5841,-22.0321,Naron,ETHNICITY,ETHNICITY
SM250546,21.5841,-22.0321,Naron,ETHNICITY,ETHNICITY
SM250546,21.5841,-22.0321,Naron,ETHNICITY,ETHNICITY
SM250546,21.5841,-22.0321,Naron,ETHNICITY,ETHNICITY
SM250546,21.5841,-22.0321,Naron,ETHNICITY,ETHNICITY
SM4944,74.9532,32.2111,Narowal,ADM2:ADM3,DISTRICT
SM262588,29.4167,51.6667,Narowlya,ADM2,DISTRICT
SM18417,66,39.9167,Narpay Tumani,ADM2,DISTRICT
SM261589,149.6024,-30.3278,Narrabri,ADM2,DISTRICT
SM256164,-71.09,41.65,Narraganset,ETHNICITY,ETHNICITY
SM261590,146.532,-34.5911,Narrandera,ADM2,DISTRICT
SM239285,140.158,-36.2301,Narrinyeri,LANGUAGE,LANGUAGE
SM256165,139.97,-36,Narrinyeri,ETHNICITY,ETHNICITY
SM256165,139.97,-36,Narrinyeri,ETHNICITY,ETHNICITY
SM256165,139.2691,-35.7582,Narrinyeri,ETHNICITY,ETHNICITY
SM256165,139.2691,-35.7582,Narrinyeri,ETHNICITY,ETHNICITY
SM9732,117.2637,-32.997,Narrogin,ADM2,DISTRICT
SM261591,147.9759,-32.2317,Narromine,ADM2,DISTRICT
SM11507,-45.1055,60.2668,Narsaq Municipality,ADM2,DISTRICT
SM276991,79.1667,22.9167,Narsimhapur,ADM2,DISTRICT
SM262244,90.8333,24,Narsingdi,ADM2,DISTRICT
SM249666,100.9,27.8,Narua,LANGUAGE,LANGUAGE
SM292359,26.7662,45.8231,Naruja,ADM2,DISTRICT
SM256168,138.25,-33.94,Narunga,ETHNICITY,ETHNICITY
SM256168,138.25,-33.94,Narunga,ETHNICITY,ETHNICITY
SM256168,137.591,-34.4819,Narunga,ETHNICITY,ETHNICITY
SM256168,137.591,-34.4819,Narunga,ETHNICITY,ETHNICITY
SM239292,137.535,-34.69,Narungga,LANGUAGE,LANGUAGE
SM279414,134.6136,34.1788,Naruto,ADM2,DISTRICT
SM274219,28.1102,59.3696,Narva,ADM2,DISTRICT
SM11180,28.0214,59.4413,Narva-Joesuu linn,ADM2,DISTRICT
SM461872,17.4278,68.439,Narvik (2020-),ADM2,DISTRICT
SM280329,76,41.4333,Naryn,ADM2,DISTRICT
SM2386,75.9667,41.4333,Naryn,ADM1,DISTRICT
SM239294,103.5719,-4.8652,Nasal,LANGUAGE,LANGUAGE
SM285021,7.5974,8.2694,Nasarawa,ADM2,DISTRICT
SM239295,167.414,-16.3497,Nasarian,LANGUAGE,LANGUAGE
SM292511,24.4148,47.2749,Nasaud,ADM2,DISTRICT
SM301991,-77.9865,35.9672,Nash,ADM2,DISTRICT
SM277046,73.7894,19.983,Nashik,ADM2,DISTRICT
SM454392,155.57,-6.23,Nasioi,ETHNICITY,ETHNICITY
SM454392,155.57,-6.23,Nasioi,ETHNICITY,ETHNICITY
SM454392,155.83,-6.48,Nasioi,ETHNICITY,ETHNICITY
SM454392,155.83,-6.48,Nasioi,ETHNICITY,ETHNICITY
SM375388,68.1293,28.6264,Nasirabad,ADM2:ADM3,DISTRICT
SM239300,-61.1318,55.9316,Naskapi,LANGUAGE,LANGUAGE
SM256171,-66.71,56.22,Naskapi,ETHNICITY,ETHNICITY
SM256171,-66.71,56.22,Naskapi,ETHNICITY,ETHNICITY
SM256171,-66.71,56.22,Naskapi,ETHNICITY,ETHNICITY
SM256171,-70,58,Naskapi,ETHNICITY,ETHNICITY
SM256171,-70,58,Naskapi,ETHNICITY,ETHNICITY
SM256171,-70,58,Naskapi,ETHNICITY,ETHNICITY
SM256171,-68.92,56.19,Naskapi,ETHNICITY,ETHNICITY
SM256171,-68.92,56.19,Naskapi,ETHNICITY,ETHNICITY
SM256171,-68.92,56.19,Naskapi,ETHNICITY,ETHNICITY
SM275128,10.9395,2.0238,Nasng,ADM2,DISTRICT
SM298109,9.8116,35.3268,Nasrallah,ADM2,DISTRICT
SM2387,36.7,-13,Nassa,ADM1,DISTRICT
SM284877,8.5549,12.0051,Nassaraw,ADM2,DISTRICT
SM2388,8.25,8.5,Nassarawa,ADM1,DISTRICT
SM285010,8.4404,8.7495,Nassarawa Egon,ADM2,DISTRICT
SM301891,-73.5855,40.7322,Nassau,ADM2,DISTRICT
SM300352,-81.7714,30.6106,Nassau,ADM2,DISTRICT
SM291905,25.4386,43.6663,Nasturelu,ADM2,DISTRICT
SM13039,140.1243,36.9441,Nasu-gun,ADM2,DISTRICT
SM279320,140.1341,36.6627,Nasukarasuyama,ADM2,DISTRICT
SM279321,139.9105,36.9909,Nasushiobara,ADM2,DISTRICT
SM239305,167.6396,-16.4582,Nasvang,LANGUAGE,LANGUAGE
SM10932,32.5646,34.7776,Nata,ADM2,DISTRICT
SM270572,-75.1135,3.5521,Natagaima,ADM2,DISTRICT
SM239307,-75.0333,4.1667,Natagaimas,LANGUAGE,LANGUAGE
SM265593,-35.2267,-5.8101,Natal,ADM2,DISTRICT
SM289550,-56.2845,-25.6644,Natalicio Talavera,ADM2,DISTRICT
SM289573,-55.1393,-26.7344,Natalio,ADM2,DISTRICT
SM263687,-46.5139,-16.5631,Natalândia,ADM2,DISTRICT
SM277694,51.8667,33.55,Natanz,ADM2,DISTRICT
SM239308,51.9538,33.4593,Natanzic,LANGUAGE,LANGUAGE
SM256176,-59.59,51.28,Natashquan,ETHNICITY,ETHNICITY
SM239314,-91.33,31.75,Natchez,LANGUAGE,LANGUAGE
SM256178,-92.77,32.5,Natchez,ETHNICITY,ETHNICITY
SM256178,-92.77,32.5,Natchez,ETHNICITY,ETHNICITY
SM256178,-92.77,32.5,Natchez,ETHNICITY,ETHNICITY
SM256178,-92.77,32.5,Natchez,ETHNICITY,ETHNICITY
SM256178,-91.417,31.5,Natchez,ETHNICITY,ETHNICITY
SM256178,-91.417,31.5,Natchez,ETHNICITY,ETHNICITY
SM256178,-91.417,31.5,Natchez,ETHNICITY,ETHNICITY
SM256178,-91.417,31.5,Natchez,ETHNICITY,ETHNICITY
SM256178,-91,32,Natchez,ETHNICITY,ETHNICITY
SM256178,-91,32,Natchez,ETHNICITY,ETHNICITY
SM256178,-91,32,Natchez,ETHNICITY,ETHNICITY
SM256178,-91,32,Natchez,ETHNICITY,ETHNICITY
SM256178,-91.42,31.5,Natchez,ETHNICITY,ETHNICITY
SM256178,-91.42,31.5,Natchez,ETHNICITY,ETHNICITY
SM256178,-91.42,31.5,Natchez,ETHNICITY,ETHNICITY
SM256178,-91.42,31.5,Natchez,ETHNICITY,ETHNICITY
SM301136,-93.0962,31.7235,Natchitoches,ADM2,DISTRICT
SM239315,1.2188,10.5191,Nateni,LANGUAGE,LANGUAGE
SM239316,39.9062,-16.0973,Nathembo,LANGUAGE,LANGUAGE
SM239318,167.4961,-16.4687,Nati,LANGUAGE,LANGUAGE
SM305008,45.585,14.5539,Nati',ADM2,DISTRICT
SM458104,118.367,-29.3738,Natingero,ETHNICITY,ETHNICITY
SM288589,147.1664,-9.4226,National Capital District,ADM2,DISTRICT
SM2389,147.1667,-9.4167,National Capital District,ADM1,DISTRICT
SM239317,-5.1952,10.7176,Natioro,LANGUAGE,LANGUAGE
SM262137,1.414,10.2575,Natitingou,ADM2,DISTRICT
SM14353,-96.4319,17.2964,Natividad,ADM2,DISTRICT
SM267267,-47.6449,-11.7589,Natividade,ADM2,DISTRICT
SM265285,-41.938,-21.0308,Natividade,ADM2,DISTRICT
SM266726,-45.3937,-23.3945,Natividade da Serra,ADM2,DISTRICT
SM262271,89.0833,24.35,Natore,ADM2,DISTRICT
SM278727,140.8667,38.1673,Natori,ADM2,DISTRICT
SM303299,-106.7985,42.9622,Natrona,ADM2,DISTRICT
SM264251,-35.5463,-7.5581,Natuba,ADM2,DISTRICT
SM276437,107.9764,4.7142,Natuna,ADM2,DISTRICT
SM286693,-80.6093,8.3442,Natá,ADM2,DISTRICT
SM263688,-45.4969,-22.1323,Natércia,ADM2,DISTRICT
SM282693,-98.3322,19.2308,Natívitas (pre-1995),ADM2,DISTRICT
SM239323,-36.8493,-10.1857,Natú,LANGUAGE,LANGUAGE
SM239321,165.835,-10.6771,Natügu,LANGUAGE,LANGUAGE
SM239324,165.835,-10.6771,Natügu (Retired),LANGUAGE,LANGUAGE
SM256186,146.15,-31.12,Naualko,ETHNICITY,ETHNICITY
SM256186,146.15,-31.12,Naualko,ETHNICITY,ETHNICITY
SM256186,144.1662,-30.9705,Naualko,ETHNICITY,ETHNICITY
SM256186,144.1662,-30.9705,Naualko,ETHNICITY,ETHNICITY
SM281942,-99.2337,19.4786,Naucalpan de Juárez,ADM2,DISTRICT
SM13704,23.1555,56.5563,Naudites pagasts,ADM2,DISTRICT
SM239326,126.741,-8.7073,Nauete,LANGUAGE,LANGUAGE
SM13705,26.7443,55.9285,Naujenes pagasts,ADM2,DISTRICT
SM461518,169.71,66.02,Naukan,ETHNICITY,ETHNICITY
SM239327,-171.755,65.5184,Naukan Yupik,LANGUAGE,LANGUAGE
SM4045,25.4503,57.915,Nauksenu Novads,ADM1,DISTRICT
SM13706,25.5277,57.8909,Nauksenu pagasts,ADM2,DISTRICT
SM239329,148.201,-2.2121,Nauna,LANGUAGE,LANGUAGE
SM239330,135.828,-33.7966,Nauo,LANGUAGE,LANGUAGE
SM256187,135.09,-33.07,Nauo,ETHNICITY,ETHNICITY
SM256187,135.09,-33.07,Nauo,ETHNICITY,ETHNICITY
SM256187,135.4397,-33.704,Nauo,ETHNICITY,ETHNICITY
SM256187,135.4397,-33.704,Nauo,ETHNICITY,ETHNICITY
SM282446,-98.1059,20.2329,Naupan,ADM2,DISTRICT
SM292737,45.5,43.6667,Naurskiy rayon,ADM2,DISTRICT
SM239331,166.922,-0.5296,Nauru,LANGUAGE,LANGUAGE
SM256189,166.92,-0.53,Nauru,ETHNICITY,ETHNICITY
SM256189,166.92,-0.53,Nauru,ETHNICITY,ETHNICITY
SM256189,166.92,-0.53,Nauru,ETHNICITY,ETHNICITY
SM256189,166.93,-0.52,Nauru,ETHNICITY,ETHNICITY
SM256189,166.93,-0.52,Nauru,ETHNICITY,ETHNICITY
SM256189,166.93,-0.52,Nauru,ETHNICITY,ETHNICITY
SM256189,166.92,-0.53,Nauru,ETHNICITY,ETHNICITY
SM256189,166.92,-0.53,Nauru,ETHNICITY,ETHNICITY
SM256189,166.92,-0.53,Nauru,ETHNICITY,ETHNICITY
SM279850,64,51.4167,Naurzumskiy,ADM2,DISTRICT
SM256190,-69.92,41.75,Nauset,ETHNICITY,ETHNICITY
SM375504,68.1207,26.8756,Naushahro Firoz,ADM2:ADM3,DISTRICT
SM256191,80.33,27.9,Nauthars,ETHNICITY,ETHNICITY
SM282747,-96.7881,20.1199,Nautla,ADM2,DISTRICT
SM13707,27.3688,56.7382,Nautrenu pagasts,ADM2,DISTRICT
SM282236,-97.5903,19.9657,Nauzontla,ADM2,DISTRICT
SM283291,-100.6137,28.5153,Nava,ADM2,DISTRICT
SM256193,146.93,-8.75,Nava,ETHNICITY,ETHNICITY
SM239335,-110.082,36.2106,Navajo,LANGUAGE,LANGUAGE
SM239335,-110.082,36.2106,Navajo,LANGUAGE,LANGUAGE
SM301789,-110.3214,35.3996,Navajo,ADM2,DISTRICT
SM256194,-108.71,36.82,Navajo,ETHNICITY,ETHNICITY
SM256194,-108.71,36.82,Navajo,ETHNICITY,ETHNICITY
SM256194,-108.71,36.82,Navajo,ETHNICITY,ETHNICITY
SM256194,-108.5,35,Navajo,ETHNICITY,ETHNICITY
SM256194,-108.5,35,Navajo,ETHNICITY,ETHNICITY
SM256194,-108.5,35,Navajo,ETHNICITY,ETHNICITY
SM256194,-110,37,Navajo,ETHNICITY,ETHNICITY
SM256194,-110,37,Navajo,ETHNICITY,ETHNICITY
SM256194,-110,37,Navajo,ETHNICITY,ETHNICITY
SM239335,-109.38,36.33,Navajo,LANGUAGE,LANGUAGE
SM239335,-109.38,36.33,Navajo,LANGUAGE,LANGUAGE
SM256195,-108.7,35.5,Navajo (Eastern),ETHNICITY,ETHNICITY
SM256196,-112,36,Navajo (Western),ETHNICITY,ETHNICITY
SM274083,-1.6514,42.8233,Navarra,ADM2,DISTRICT
SM302723,-96.4725,32.0469,Navarro,ADM2,DISTRICT
SM260806,-59.5,-35,Navarro,ADM2,DISTRICT
SM15973,-65.9987,18.2276,Navarro Barrio,ADM2,DISTRICT
SM293772,42.1872,55.5425,Navashinskiy rayon,ADM2,DISTRICT
SM2390,-75.012,18.403,Navassa,ADM0:ADM1,DISTRICT
SM303549,65.3031,40.22,Navbahor,ADM2,DISTRICT
SM303549,65.3031,40.22,Navbahor,ADM2,DISTRICT
SM303549,65.3049,40.2492,Navbahor,ADM2,DISTRICT
SM303549,65.3049,40.2492,Navbahor,ADM2,DISTRICT
SM266218,-48.7347,-26.8177,Navegantes,ADM2,DISTRICT
SM263067,-54.0311,-23.1075,Naviraí,ADM2,DISTRICT
SM295012,34.4167,52.8333,Navlinskiy rayon,ADM2,DISTRICT
SM290182,28.6141,44.3051,Navodari,ADM2,DISTRICT
SM2391,64,42,Navoi,ADM1,DISTRICT
SM282586,-109.4818,27.0817,Navojoa,ADM2,DISTRICT
SM14355,-107.6828,24.7346,Navolato,ADM2,DISTRICT
SM276825,72.92,20.95,Navsari,ADM2,DISTRICT
SM239336,166.798,-15.3169,Navut,LANGUAGE,LANGUAGE
SM239337,167.5544,-16.5029,Navwien,LANGUAGE,LANGUAGE
SM260008,64.482,32.2948,Naw Zad,ADM2,DISTRICT
SM239338,-72,-7.68,Nawa,LANGUAGE,LANGUAGE
SM269476,-6.6031,5.8011,Nawa,ADM2,DISTRICT
SM260292,67.8975,32.3269,Nawa,ADM2,DISTRICT
SM259997,64.2149,31.3321,Nawa-i-Barak Zayi,ADM2,DISTRICT
SM375505,68.3177,26.3666,Nawab Shah,ADM2:ADM3,DISTRICT
SM262272,88.25,24.6833,Nawabganj,ADM2,DISTRICT
SM277419,85.5,24.75,Nawada,ADM2,DISTRICT
SM288587,146.9667,-6.4667,Nawae,ADM2,DISTRICT
SM4690,84.0321,27.654,Nawalpari,ADM2,DISTRICT
SM239340,148.237,-9.3794,Nawaru,LANGUAGE,LANGUAGE
SM256096,-91.5,15.47,Nawat,ETHNICITY,ETHNICITY
SM239341,-104.45,41.316,Nawathinehena,LANGUAGE,LANGUAGE
SM239343,0.5855,7.1704,Nawdm,LANGUAGE,LANGUAGE
SM260293,67.7399,33.8458,Nawur,ADM2,DISTRICT
SM239345,0.0495,8.4512,Nawuri,LANGUAGE,LANGUAGE
SM280794,102.444,18.1747,Naxaithong,ADM2,DISTRICT
SM239347,100,27.5,Naxi,LANGUAGE,LANGUAGE
SM256197,100,26,Naxi,ETHNICITY,ETHNICITY
SM256197,100,26,Naxi,ETHNICITY,ETHNICITY
SM256197,100,26,Naxi,ETHNICITY,ETHNICITY
SM256197,100,26,Naxi,ETHNICITY,ETHNICITY
SM239348,100.1817,27.4599,Naxi (Retired),LANGUAGE,LANGUAGE
SM15104,96.1384,20.0478,Nay Pyi Taw (North),ADM2,DISTRICT
SM15105,96.1685,19.6402,Nay Pyi Taw (South),ADM2,DISTRICT
SM277123,85.1,20.13,Nayagarh,ADM2,DISTRICT
SM262183,-3,12.6667,Nayala,ADM1:ADM2,DISTRICT
SM2392,-104.8333,21.8333,Nayarit,ADM1,DISTRICT
SM239352,36.3813,6.882,Nayi,LANGUAGE,LANGUAGE
SM239351,53.521,34.2212,Nayinic,LANGUAGE,LANGUAGE
SM278319,142.4593,44.3395,Nayoro,ADM2,DISTRICT
SM2393,96.1418,19.8822,Naypyitaw,ADM1,DISTRICT
SM277473,50.5169,35.9137,Nazarabad,ADM2,DISTRICT
SM263689,-44.6085,-21.2079,Nazareno,ADM2,DISTRICT
SM14356,-96.8326,17.1767,Nazareno Etla,ADM2,DISTRICT
SM264252,-38.3259,-6.9199,Nazarezinho,ADM2,DISTRICT
SM9966,-42.8684,-5.4455,Nazaria,ADM2,DISTRICT
SM296212,14.9092,46.2817,Nazarje,ADM1:ADM2,DISTRICT
SM293443,90.625,56,Nazarovskiy rayon,ADM2,DISTRICT
SM289244,-9.0716,39.5996,Nazaré,ADM2,DISTRICT
SM267268,-47.7894,-6.3131,Nazaré,ADM2,DISTRICT
SM267556,-38.9787,-12.95,Nazaré,ADM2,DISTRICT
SM266727,-46.362,-23.182,Nazaré Paulista,ADM2,DISTRICT
SM264854,-35.1995,-7.7341,Nazaré da Mata,ADM2,DISTRICT
SM265066,-42.7322,-7.0328,Nazaré do Piauí,ADM2,DISTRICT
SM281503,-104.0893,25.2411,Nazas,ADM2,DISTRICT
SM286741,-75.0833,-15,Nazca,ADM2,DISTRICT
SM298149,28.3061,37.9357,Nazilli,ADM2,DISTRICT
SM292878,44.8333,43.25,Nazranovskiy rayon,ADM2,DISTRICT
SM260121,70.8258,34.0559,Nazyan,ADM2,DISTRICT
SM268103,-49.8654,-16.5801,Nazário,ADM2,DISTRICT
SM298982,39.8281,39.1795,Nazımiye,ADM2,DISTRICT
SM2349,-0.8333,33.5,Naâma,ADM1,DISTRICT
SM275958,18.1,45.5,Našice,ADM2,DISTRICT
SM239356,10.5312,6.507,Ncane,LANGUAGE,LANGUAGE
SM305183,28.7487,-9.3048,Nchelenge,ADM2,DISTRICT
SM239359,-1.3088,7.6302,Nchumbulu,LANGUAGE,LANGUAGE
SM239373,10.3942,5.1472,Nda'nda',LANGUAGE,LANGUAGE
SM239362,14.2888,8.0904,Ndai,LANGUAGE,LANGUAGE
SM239363,27.9693,1.5334,Ndaka,LANGUAGE,LANGUAGE
SM239364,11.03,6.7,Ndaktup,DIALECT,LANGUAGE
SM239366,33.6118,-9.8895,Ndali,LANGUAGE,LANGUAGE
SM250930,33.28,-9.76,Ndali,ETHNICITY,ETHNICITY
SM239369,17.326,9.7937,Ndam,LANGUAGE,LANGUAGE
SM239368,36.0271,-8.4049,Ndamba,LANGUAGE,LANGUAGE
SM239372,12.77,-0.82,Ndambomo,LANGUAGE,LANGUAGE
SM239375,13.3695,-2.449,Ndasa,LANGUAGE,LANGUAGE
SM239378,33.1739,-20.1239,Ndau,LANGUAGE,LANGUAGE
SM250549,34.83,-20.92,Ndau,ETHNICITY,ETHNICITY
SM250549,34.83,-20.92,Ndau,ETHNICITY,ETHNICITY
SM250549,34,-20,Ndau,ETHNICITY,ETHNICITY
SM250549,34,-20,Ndau,ETHNICITY,ETHNICITY
SM261993,29.7534,-3.3916,Ndava,ADM2,DISTRICT
SM239383,10.47,7.065,Nde-Gbite,LANGUAGE,LANGUAGE
SM239391,8.4984,6.1743,Nde-Nsele-Nta,LANGUAGE,LANGUAGE
SM250584,28,-20,Ndebele North,ETHNICITY,ETHNICITY
SM251093,29.06,-25.26,Ndebele South,ETHNICITY,ETHNICITY
SM239388,10.2197,4.5806,Ndemli,LANGUAGE,LANGUAGE
SM239390,36.0105,-10.4203,Ndendeule,LANGUAGE,LANGUAGE
SM256205,21.86,-3.42,Ndenge,ETHNICITY,ETHNICITY
SM239389,38.958,-7.5463,Ndengereko,LANGUAGE,LANGUAGE
SM250932,38.69,-7.48,Ndengereko,ETHNICITY,ETHNICITY
SM256206,18.5,5.88,Ndere,ETHNICITY,ETHNICITY
SM269553,8.8333,4.8333,Ndian,ADM2,DISTRICT
SM256207,15.88,-4.4,Ndibu,ETHNICITY,ETHNICITY
SM256207,12.32,-3.68,Ndibu,ETHNICITY,ETHNICITY
SM239402,30.7306,10.2741,Nding,LANGUAGE,LANGUAGE
SM256208,117.45,-21.33,Ndjiband,ETHNICITY,ETHNICITY
SM256208,117.45,-21.33,Ndjiband,ETHNICITY,ETHNICITY
SM256208,121.28,-22.74,Ndjiband,ETHNICITY,ETHNICITY
SM256208,121.28,-22.74,Ndjiband,ETHNICITY,ETHNICITY
SM256208,142.4059,-12.7509,Ndjiband,ETHNICITY,ETHNICITY
SM256208,142.4059,-12.7509,Ndjiband,ETHNICITY,ETHNICITY
SM10246,19.4241,5.3064,Ndjoukou,ADM2,DISTRICT
SM458907,134.2744,-12.1555,Ndjébbana,ETHNICITY,ETHNICITY
SM239428,30.5652,2.6855,Ndo,LANGUAGE,LANGUAGE
SM239408,18.6151,1.3254,Ndobo,LANGUAGE,LANGUAGE
SM239409,8.6913,6.0813,Ndoe,LANGUAGE,LANGUAGE
SM239411,27.5588,7.7303,Ndogo,LANGUAGE,LANGUAGE
SM284651,6.5077,5.6703,Ndokwa East,ADM2,DISTRICT
SM284652,6.3922,5.7732,Ndokwa West,ADM2,DISTRICT
SM250553,11,7,Ndola,ETHNICITY,ETHNICITY
SM18159,28.549,-12.9416,Ndola District,ADM2,DISTRICT
SM18160,27.7252,-13.2679,Ndola Rural District,ADM2,DISTRICT
SM239419,19.4087,2.151,Ndolo,LANGUAGE,LANGUAGE
SM274562,10.266,-1.526,Ndolou,ADM2,DISTRICT
SM239421,138.019,-7.9355,Ndom,LANGUAGE,LANGUAGE
SM239420,13.371,-12.9655,Ndombe,LANGUAGE,LANGUAGE
SM250551,13.38,-12.96,Ndombe,ETHNICITY,ETHNICITY
SM239423,38.1253,-10.5584,Ndonde Hamba,LANGUAGE,LANGUAGE
SM239425,17.35,-17.22,Ndonga,LANGUAGE,LANGUAGE
SM239427,11.3096,7.2884,Ndoola,LANGUAGE,LANGUAGE
SM256210,11.64,4.93,Ndop,ETHNICITY,ETHNICITY
SM274578,10.111,-2.372,Ndougou,ADM2,DISTRICT
SM239433,30.8702,2.2501,Ndrulo,LANGUAGE,LANGUAGE
SM256211,30.29,2.34,Ndu Lendu,ETHNICITY,ETHNICITY
SM239435,138.411,-4.3404,Nduga,LANGUAGE,LANGUAGE
SM276534,138.1009,-4.4509,Nduga,ADM2,DISTRICT
SM256212,138.43,-4.67,Nduga,ETHNICITY,ETHNICITY
SM239438,13.7279,-1.6703,Ndumu,LANGUAGE,LANGUAGE
SM239444,11.1105,6.7321,Ndunda,LANGUAGE,LANGUAGE
SM256213,22.05,-15.76,Ndundulu,ETHNICITY,ETHNICITY
SM239442,21.5755,2.3886,Ndunga,LANGUAGE,LANGUAGE
SM225045,8.5621,9.1764,Ndunic,LANGUAGE,LANGUAGE
SM239446,-16.9811,14.925,Ndut,LANGUAGE,LANGUAGE
SM239451,-56.0182,2.9479,Ndyuka-Trio Pidgin,LANGUAGE,LANGUAGE
SM259094,10.66,6.07,Ndzerem,LANGUAGE,LANGUAGE
SM239455,44.3709,-12.2123,Ndzwani Comorian,LANGUAGE,LANGUAGE
SM269546,10.5167,5.15,Ndé,ADM2,DISTRICT
SM268553,20.8911,8.7429,Ndélé,ADM2,DISTRICT
SM10933,32.5395,35.137,Nea Dimmata,ADM2,DISTRICT
SM295482,165.796,-10.8237,Nea-Noole,ADM2,DISTRICT
SM291821,25.3332,47.2647,Neagra Sarului,ADM2,DISTRICT
SM282237,-98.4539,19.0603,Nealtican,ADM2,DISTRICT
SM2395,26.5,47,Neamț,ADM1,DISTRICT
SM256215,14.25,40.89,Neapolitan,ETHNICITY,ETHNICITY
SM274750,-3.75,51.6667,Neath Port Talbot,ADM1:ADM2,DISTRICT
SM291170,24.8379,46.4867,Neaua,ADM2,DISTRICT
SM275235,-91.1733,15.5614,Nebaj,ADM2,DISTRICT
SM299374,31.2629,2.4673,Nebbi (2017-),ADM1:ADM2,DISTRICT
SM297878,8.7728,36.3182,Nebeur,ADM2,DISTRICT
SM2397,-99.7507,41.5003,Nebraska,ADM1,DISTRICT
SM270242,-74.8578,7.9981,Nechí,ADM2,DISTRICT
SM260807,-59.25,-38.25,Necochea,ADM2,DISTRICT
SM270243,-76.7711,8.4296,Necoclí,ADM2,DISTRICT
SM291906,25.1323,44.2609,Necsesti,ADM2,DISTRICT
SM262413,25.0948,41.4689,Nedelino,ADM2,DISTRICT
SM305343,16.3849,46.3763,Nedelišće,ADM2,DISTRICT
SM285875,5.7716,51.2881,Nederweert,ADM2,DISTRICT
SM261430,115.7994,-31.9799,Nedlands,ADM2,DISTRICT
SM14075,-9.75,6,Neekreen District,ADM2,DISTRICT
SM14075,-9.75,6,Neekreen District,ADM2,DISTRICT
SM14075,-9.9246,5.9385,Neekreen District,ADM2,DISTRICT
SM14075,-9.9246,5.9385,Neekreen District,ADM2,DISTRICT
SM375371,74.504,34.93,Neelum,ADM2:ADM3,DISTRICT
SM276992,74.87,24.47,Neemuch,ADM2,DISTRICT
SM277576,47.75,38.4333,Neer,ADM2,DISTRICT
SM370363,-8.5044,6.0733,Neezoe,ADM2:ADM3,DISTRICT
SM239468,96.349,27.688,Nefamese,LANGUAGE,LANGUAGE
SM346081,38.7293,8.9485,Nefas Silk,ADM2:ADM3,DISTRICT
SM12068,34.85,30.6833,Nefat Be'er Sheva,ADM2,DISTRICT
SM12069,34.9167,32.3167,Nefat HaSharon,ADM2,DISTRICT
SM12070,34.9833,32.5167,Nefat Hadera,ADM2,DISTRICT
SM12071,35.5333,32.7667,Nefat Kinneret,ADM2,DISTRICT
SM12072,34.9167,32.1167,Nefat Petah Tiqvva,ADM2,DISTRICT
SM12073,34.9167,31.9333,Nefat Ramla,ADM2,DISTRICT
SM12074,34.7667,31.9,Nefat Rehovot,ADM2,DISTRICT
SM12076,35.55,33.05,Nefat Tsefat,ADM2,DISTRICT
SM12078,35.3333,32.6167,Nefat Yizreel,ADM2,DISTRICT
SM298011,7.8793,33.8489,Nefta,ADM2,DISTRICT
SM294370,50.8333,52.8333,Neftegorskiy rayon,ADM2,DISTRICT
SM261897,49,39.3333,Neftçala,ADM1:ADM2,DISTRICT
SM297981,9.0758,36.9868,Nefza,ADM2,DISTRICT
SM260373,15.3333,-7.8333,Negage,ADM2,DISTRICT
SM239471,-64.8922,18.3416,Negerhollands,LANGUAGE,LANGUAGE
SM2399,102.1667,2.75,Negeri Sembilan,ADM1,DISTRICT
SM239469,102.291,2.665,Negeri Sembilan Malay,LANGUAGE,LANGUAGE
SM256217,102.25,2.5835,Negeri Sembilan Malay,ETHNICITY,ETHNICITY
SM256217,102.25,2.5835,Negeri Sembilan Malay,ETHNICITY,ETHNICITY
SM256217,102.25,2.58,Negeri Sembilan Malay,ETHNICITY,ETHNICITY
SM256217,102.25,2.58,Negeri Sembilan Malay,ETHNICITY,ETHNICITY
SM239472,139.206,52.9658,Negidal,LANGUAGE,LANGUAGE
SM256219,134,52,Negidal,ETHNICITY,ETHNICITY
SM290642,23.2274,44.7859,Negomir,ADM2,DISTRICT
SM305541,18.9972,45.278,Negoslavci,ADM2,DISTRICT
SM2400,22.15,41.5167,Negotino,ADM1,DISTRICT
SM291269,25.124,44.6144,Negrasi,ADM2,DISTRICT
SM292297,27.4781,46.8319,Negresti,ADM2,DISTRICT
SM291674,23.4182,47.868,Negresti-oas,ADM2,DISTRICT
SM292130,26.9722,46.705,Negri,ADM2,DISTRICT
SM12569,-78.3393,18.2746,Negril,ADM2,DISTRICT
SM256220,120.26,15.61,Negrito,ETHNICITY,ETHNICITY
SM256220,122.26,16.53,Negrito,ETHNICITY,ETHNICITY
SM256220,122.22,18.1,Negrito,ETHNICITY,ETHNICITY
SM15974,-66.3321,18.2899,Negros Barrio,ADM2,DISTRICT
SM2401,123,10.4167,Negros Occidental,ADM1:ADM2,DISTRICT
SM2402,123,9.75,Negros Oriental,ADM1:ADM2,DISTRICT
SM290183,28.2804,43.7953,Negru Voda,ADM2,DISTRICT
SM239478,154.2,-4.5688,Nehan,LANGUAGE,LANGUAGE
SM289897,26.3209,45.4154,Nehoiu,ADM2,DISTRICT
SM2403,114.75,43.5,Nei Mongol,ADM1,DISTRICT
SM17997,-173.9812,-18.6517,Neiafu,ADM2,DISTRICT
SM12075,104.8653,29.652,Neijiang Shi,ADM2,DISTRICT
SM270844,-75.5241,5.1753,Neira,ADM2,DISTRICT
SM270090,-75.3094,3.0144,Neiva,ADM2,DISTRICT
SM295512,-89.2218,13.8418,Nejapa,ADM2,DISTRICT
SM14357,-95.9931,16.5632,Nejapa de Madero,ADM2,DISTRICT
SM239484,146.841,-6.377,Nek,LANGUAGE,LANGUAGE
SM239483,146.253,-5.6436,Nekgini,LANGUAGE,LANGUAGE
SM294914,41.75,50.4167,Nekhaevskiy rayon,ADM2,DISTRICT
SM239486,146.306,-5.6003,Neko,LANGUAGE,LANGUAGE
SM295069,38,58,Nekouzskiy rayon,ADM2,DISTRICT
SM295070,40.4167,57.6667,Nekrasovskiy rayon,ADM2,DISTRICT
SM239487,165.578,-21.6524,Neku,LANGUAGE,LANGUAGE
SM289418,-7.8826,40.5295,Nelas,ADM2,DISTRICT
SM277029,79.5833,14.0833,Nellore,ADM2,DISTRICT
SM303052,-78.8868,37.7874,Nelson,ADM2,DISTRICT
SM302055,-98.192,47.9217,Nelson,ADM2,DISTRICT
SM301098,-85.466,37.8051,Nelson,ADM2,DISTRICT
SM286560,173.3876,-41.2223,Nelson,ADM2,DISTRICT
SM2404,173.284,-41.2709,Nelson,ADM1:ADMX,DISTRICT
SM305102,25.4921,-33.804,Nelson Mandela Bay,ADM2,DISTRICT
SM14951,152.574,6.993,Nema Municipality,ADM2,DISTRICT
SM256223,-15.08,21.81,Nemadi,ETHNICITY,ETHNICITY
SM301738,-95.8498,40.3877,Nemaha,ADM2,DISTRICT
SM300951,-96.0141,39.7835,Nemaha,ADM2,DISTRICT
SM293052,22.0333,55.0333,Neman,ADM2,DISTRICT
SM293037,22,54.9167,Nemanskiy rayon,ADM2,DISTRICT
SM285333,6.2944,4.5247,Nembe,ADM2,DISTRICT
SM239491,141.849,-8.4488,Neme,LANGUAGE,LANGUAGE
SM256225,8.95,35.12,Nemencha,ETHNICITY,ETHNICITY
SM292977,79,53.25,Nemetskiy Natsional'nyy rayon,ADM2,DISTRICT
SM239492,164.797,-20.6791,Nemi,LANGUAGE,LANGUAGE
SM370378,-7.85,4.5,Nemia,ADM2:ADM3,DISTRICT
SM370378,-7.85,4.5,Nemia,ADM2:ADM3,DISTRICT
SM370378,-7.8333,4.55,Nemia,ADM2:ADM3,DISTRICT
SM370378,-7.8333,4.55,Nemia,ADM2:ADM3,DISTRICT
SM270027,-73.9061,5.0697,Nemocón,ADM2,DISTRICT
SM293273,50.6667,57.5,Nemskiy rayon,ADM2,DISTRICT
SM278320,145.6167,43.3333,Nemuro,ADM2,DISTRICT
SM299775,28.8352,48.9683,Nemyrivs'kyi,ADM2,DISTRICT
SM239499,142.032,-8.6218,Nen,LANGUAGE,LANGUAGE
SM239493,144.886,-5.0427,Nend,LANGUAGE,LANGUAGE
SM2405,54,67.5,Nenets,ADM1,DISTRICT
SM256226,79.88,71.41,Nenets,ETHNICITY,ETHNICITY
SM256226,79.88,71.41,Nenets,ETHNICITY,ETHNICITY
SM256226,79.88,71.41,Nenets,ETHNICITY,ETHNICITY
SM256226,48.6,66.26,Nenets,ETHNICITY,ETHNICITY
SM256226,48.6,66.26,Nenets,ETHNICITY,ETHNICITY
SM256226,48.6,66.26,Nenets,ETHNICITY,ETHNICITY
SM256226,39.59,63.83,Nenets,ETHNICITY,ETHNICITY
SM256226,39.59,63.83,Nenets,ETHNICITY,ETHNICITY
SM256226,39.59,63.83,Nenets,ETHNICITY,ETHNICITY
SM256226,62.17,68.7,Nenets,ETHNICITY,ETHNICITY
SM256226,62.17,68.7,Nenets,ETHNICITY,ETHNICITY
SM256226,62.17,68.7,Nenets,ETHNICITY,ETHNICITY
SM256226,54.12,72.31,Nenets,ETHNICITY,ETHNICITY
SM256226,54.12,72.31,Nenets,ETHNICITY,ETHNICITY
SM256226,54.12,72.31,Nenets,ETHNICITY,ETHNICITY
SM256226,70.5,69.23,Nenets,ETHNICITY,ETHNICITY
SM256226,70.5,69.23,Nenets,ETHNICITY,ETHNICITY
SM256226,70.5,69.23,Nenets,ETHNICITY,ETHNICITY
SM256226,70.55,71.99,Nenets,ETHNICITY,ETHNICITY
SM256226,70.55,71.99,Nenets,ETHNICITY,ETHNICITY
SM256226,70.55,71.99,Nenets,ETHNICITY,ETHNICITY
SM256226,75,68,Nenets,ETHNICITY,ETHNICITY
SM256226,75,68,Nenets,ETHNICITY,ETHNICITY
SM256226,75,68,Nenets,ETHNICITY,ETHNICITY
SM256226,51.5,68,Nenets,ETHNICITY,ETHNICITY
SM256226,51.5,68,Nenets,ETHNICITY,ETHNICITY
SM256226,51.5,68,Nenets,ETHNICITY,ETHNICITY
SM239497,167.964,-21.5305,Nengone,LANGUAGE,LANGUAGE
SM2406,34.7003,-15.4505,Neno,ADM1:ADM2,DISTRICT
SM275477,-91.7219,15.9235,Nentón,ADM2,DISTRICT
SM295483,166.3163,-10.3067,Nenumpo,ADM2,DISTRICT
SM295468,165.8097,-10.677,Neo,ADM2,DISTRICT
SM10934,33.4572,35.2187,Neo Chorio (Nicosia),ADM2,DISTRICT
SM18514,32.3377,35.0492,Neo Chorio (Paphos),ADM2,DISTRICT
SM300952,-95.3068,37.5585,Neosho,ADM2,DISTRICT
SM239505,85.3041,27.6993,Nepalese Sign Language,LANGUAGE,LANGUAGE
SM239507,85,28,Nepali,LANGUAGE,LANGUAGE
SM256234,87,27,Nepali,ETHNICITY,ETHNICITY
SM239506,86.0026,26.7206,Nepali Kurux,LANGUAGE,LANGUAGE
SM263690,-45.2402,-21.2403,Nepomuceno,ADM2,DISTRICT
SM458445,153.2408,-28.0826,Nerang Creek,ETHNICITY,ETHNICITY
SM295091,116.25,52.3333,Nerchinskiy rayon,ADM2,DISTRICT
SM295092,119.6,51.3167,Nerchinsko-Zavodskiy rayon,ADM2,DISTRICT
SM292360,26.6979,45.7083,Nereju,ADM2,DISTRICT
SM17013,40.75,57.4167,Nerekhtskiy rayon,ADM2,DISTRICT
SM4051,25.155,56.3153,Neretas Novads,ADM1,DISTRICT
SM13708,25.315,56.2201,Neretas pagasts,ADM2,DISTRICT
SM305471,16.5774,43.3296,Nerežišća,ADM2,DISTRICT
SM279458,139.6517,35.7355,Nerima,ADM2,DISTRICT
SM281336,21.0667,55.3667,Neringos,ADM2,DISTRICT
SM268104,-49.1812,-16.4388,Nerópolis,ADM2,DISTRICT
SM15302,11.4657,60.1225,Nes,ADM2,DISTRICT
SM274520,-6.7209,62.0955,Nes,ADM2,DISTRICT
SM286321,9.0725,60.5514,Nesbyen,ADM2,DISTRICT
SM239512,167.2228,-15.9526,Nese,LANGUAGE,LANGUAGE
SM262402,27.7333,42.7833,Nesebar,ADM2,DISTRICT
SM260223,65.7051,32.3886,Nesh,ADM2,DISTRICT
SM301471,-89.1176,32.7535,Neshoba,ADM2,DISTRICT
SM286074,13.0223,66.198,Nesna,ADM2,DISTRICT
SM15303,10.6494,59.8422,Nesodden (1915-),ADM2,DISTRICT
SM300953,-99.9162,38.4794,Ness,ADM2,DISTRICT
SM286340,28.4337,69.8854,Nesseby,ADM2,DISTRICT
SM293038,22.5833,54.5833,Nesterovskiy rayon,ADM2,DISTRICT
SM10935,34.2203,35.4683,Neta,ADM2,DISTRICT
SM256237,-56.61,53.58,Netcetumiut,ETHNICITY,ETHNICITY
SM239516,143.029,-5.0237,Nete,LANGUAGE,LANGUAGE
SM236583,167.1663,-15.3348,Nethalp,LANGUAGE,LANGUAGE
SM262245,90.8667,24.8667,Netrakona,ADM2,DISTRICT
SM305690,15.4121,45.5086,Netretić,ADM2,DISTRICT
SM256239,-93.16,69.9,Netsilik,ETHNICITY,ETHNICITY
SM256239,-93.16,69.9,Netsilik,ETHNICITY,ETHNICITY
SM256239,-93.16,69.9,Netsilik,ETHNICITY,ETHNICITY
SM256239,-96,69,Netsilik,ETHNICITY,ETHNICITY
SM256239,-96,69,Netsilik,ETHNICITY,ETHNICITY
SM256239,-96,69,Netsilik,ETHNICITY,ETHNICITY
SM256239,-94.93,71.46,Netsilik,ETHNICITY,ETHNICITY
SM256239,-94.93,71.46,Netsilik,ETHNICITY,ETHNICITY
SM256239,-94.93,71.46,Netsilik,ETHNICITY,ETHNICITY
SM14952,158.2244,6.9515,Nett Municipality,ADM2,DISTRICT
SM256241,103.65,18.67,Neua,ETHNICITY,ETHNICITY
SM268929,6.9934,47.018,Neuchâtel,ADM2,DISTRICT
SM2407,6.7977,46.9909,Neuchâtel,ADM1,DISTRICT
SM261809,15.9378,47.6942,Neunkirchen,ADM2,DISTRICT
SM2408,-70,-39,Neuquén,ADM1,DISTRICT
SM261789,16.9247,47.8885,Neusiedl am See,ADM2,DISTRICT
SM256242,-76.9,34.46,Neusiok,ETHNICITY,ETHNICITY
SM10327,15.0919,12.0493,Neuvieme Arrondissement,ADM2,DISTRICT
SM256243,-74.69,-1.23,Neva,ETHNICITY,ETHNICITY
SM302397,-93.3071,33.664,Nevada,ADM2,DISTRICT
SM303184,-120.7688,39.3014,Nevada,ADM2,DISTRICT
SM2409,-116.7512,39.2502,Nevada,ADM1,DISTRICT
SM248011,167.427,-16.2253,Neve'ei,LANGUAGE,LANGUAGE
SM17014,142,46.5,Nevel'skiy Rayon,ADM2,DISTRICT
SM295469,165.7736,-10.7715,Nevenema,ADM2,DISTRICT
SM294012,46.6667,52.75,Neverkinskiy rayon,ADM2,DISTRICT
SM236354,167.499,-16.1986,Neverver,LANGUAGE,LANGUAGE
SM266728,-49.6485,-20.8744,Neves Paulista,ADM2,DISTRICT
SM313233,18.147,43.252,Nevesinje,ADM2:ADM3,DISTRICT
SM262539,22.8333,42.1667,Nevestino,ADM2,DISTRICT
SM2410,34.6667,38.9167,Nevsehir,ADM1,DISTRICT
SM17015,30.4534,59.892,Nevskiy Rayon,ADM2,DISTRICT
SM2411,-65.9988,46.5001,New Brunswick,ADM1,DISTRICT
SM256245,165.11,-21.13,New Caledonia,ETHNICITY,ETHNICITY
SM239528,166.445,-22.3001,New Caledonian Javanese,LANGUAGE,LANGUAGE
SM303391,-75.639,39.5783,New Castle,ADM2,DISTRICT
SM5858,77.2254,28.6357,New Delhi,ADM2,DISTRICT
SM12570,-77.271,17.9856,New Denbigh,ADM2,DISTRICT
SM17574,-61.0479,13.8618,New Development,ADM2,DISTRICT
SM256246,-72,42,New Englanders,ETHNICITY,ETHNICITY
SM256246,-72,42,New Englanders,ETHNICITY,ETHNICITY
SM256246,-73,42,New Englanders,ETHNICITY,ETHNICITY
SM256246,-73,42,New Englanders,ETHNICITY,ETHNICITY
SM17575,-61.0522,13.7882,New Field/Fiette,ADM2,DISTRICT
SM12571,-77.5587,17.9201,New Forest,ADM2,DISTRICT
SM12572,-77.5257,18.0766,New Green,ADM2,DISTRICT
SM2412,-71.4998,43.667,New Hampshire,ADM1,DISTRICT
SM301992,-77.8656,34.1814,New Hanover,ADM2,DISTRICT
SM303386,-72.8999,41.3488,New Haven,ADM2,DISTRICT
SM12573,-76.8542,18.0295,New Haven,ADM2,DISTRICT
SM2413,151.5,-3,New Ireland,ADM1,DISTRICT
SM256248,149.03,-5.9,New Ireland,ETHNICITY,ETHNICITY
SM256248,149.03,-5.9,New Ireland,ETHNICITY,ETHNICITY
SM256248,151.79,-3.18,New Ireland,ETHNICITY,ETHNICITY
SM256248,151.79,-3.18,New Ireland,ETHNICITY,ETHNICITY
SM2414,-74.4999,40.1671,New Jersey,ADM1,DISTRICT
SM274906,-0.25,6.0833,New Juaben,ADM2,DISTRICT
SM303053,-76.9971,37.5051,New Kent,ADM2,DISTRICT
SM12574,-76.7859,18.0052,New Kingston,ADM2,DISTRICT
SM303387,-72.1065,41.4668,New London,ADM2,DISTRICT
SM12575,-77.2305,18.0307,New Longsville,ADM2,DISTRICT
SM301592,-89.6518,36.5947,New Madrid,ADM2,DISTRICT
SM12576,-77.9096,18.1536,New Market,ADM2,DISTRICT
SM12577,-78.1381,18.2137,New Market Oval,ADM2,DISTRICT
SM2415,-106.0009,34.5003,New Mexico,ADM1,DISTRICT
SM12578,-76.5733,17.9916,New Monkland,ADM2,DISTRICT
SM286504,174.1031,-39.0587,New Plymouth,ADM2,DISTRICT
SM2416,-77.2833,25.0333,New Providence,ADM1,DISTRICT
SM239533,-123.1796,41.1001,New River Shasta,LANGUAGE,LANGUAGE
SM12579,-77.9361,18.1912,New Roads,ADM2,DISTRICT
SM2417,146,-33,New South Wales,ADM1,DISTRICT
SM2418,121.5818,24.947,New Taipei,ADM1:ADM2,DISTRICT
SM17576,-60.9846,14.011,New Village (Castries),ADM2,DISTRICT
SM17577,-60.8986,13.8245,New Village (Micoud),ADM2,DISTRICT
SM12580,-77.9665,18.1725,New Works,ADM2,DISTRICT
SM301892,-73.9698,40.7743,New York,ADM2,DISTRICT
SM2419,-75.4999,43.0004,New York,ADM1,DISTRICT
SM239538,175.773,-39.2923,New Zealand Sign Language,LANGUAGE,LANGUAGE
SM299126,39.2798,-10.7249,Newala,ADM2,DISTRICT
SM301313,-85.8009,43.5542,Newaygo,ADM2,DISTRICT
SM302461,-81.6001,34.2898,Newberry,ADM2,DISTRICT
SM261441,151.7084,-32.8763,Newcastle,ADM2,DISTRICT
SM274656,-1.6667,55,Newcastle upon Tyne,ADM1:ADM2,DISTRICT
SM12581,-77.7465,17.921,Newell,ADM2,DISTRICT
SM2420,-56,52,Newfoundland and Labrador,ADM1,DISTRICT
SM302429,-71.281,41.4998,Newport,ADM2,DISTRICT
SM274751,-3,51.5833,Newport,ADM1:ADM2,DISTRICT
SM12582,-77.5049,17.9629,Newport,ADM2,DISTRICT
SM12583,-76.8066,17.9742,Newport East,ADM2,DISTRICT
SM303054,-76.508,37.0627,Newport News,ADM2,DISTRICT
SM274703,-6.0669,54.2362,"Newry, Mourne and Down",ADM1:ADM2,DISTRICT
SM302724,-93.7447,30.7863,Newton,ADM2,DISTRICT
SM301472,-89.1188,32.4002,Newton,ADM2,DISTRICT
SM301593,-94.3393,36.9055,Newton,ADM2,DISTRICT
SM300743,-87.3975,40.9559,Newton,ADM2,DISTRICT
SM302398,-93.2179,35.92,Newton,ADM2,DISTRICT
SM300389,-83.8502,33.5551,Newton,ADM2,DISTRICT
SM12584,-77.7235,18.111,Newton,ADM2,DISTRICT
SM12585,-76.7721,17.9735,Newton Square,ADM2,DISTRICT
SM281943,-99.0751,19.7393,Nextlalpan,ADM2,DISTRICT
SM279107,135.627,34.7654,Neyagawa,ADM2,DISTRICT
SM271811,-71.4167,18.5,Neyba,ADM2,DISTRICT
SM239540,-6.0804,4.9505,Neyo,LANGUAGE,LANGUAGE
SM17016,43.9,58.3,Neyskiy rayon,ADM2,DISTRICT
SM239542,-116.408,46.3073,Nez Perce,LANGUAGE,LANGUAGE
SM300572,-116.7502,46.3268,Nez Perce,ADM2,DISTRICT
SM256256,-117.4,46.01,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-117.4,46.01,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-117.4,46.01,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-117.4,46.01,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-115,45,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-115,45,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-115,45,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-115,45,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-116.46,46.25,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-116.46,46.25,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-116.46,46.25,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-116.46,46.25,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-117,46,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-117,46,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-117,46,Nez Perce,ETHNICITY,ETHNICITY
SM256256,-117,46,Nez Perce,ETHNICITY,ETHNICITY
SM281944,-99.0248,19.4112,Nezahualcóyotl,ADM2,DISTRICT
SM267112,-36.6687,-10.357,Neópolis,ADM2,DISTRICT
SM280895,101.9516,20.1739,Nga,ADM2,DISTRICT
SM239560,92.83,23.312,Nga La,LANGUAGE,LANGUAGE
SM304508,105.9898,20.0156,Nga Sơn,ADM2,DISTRICT
SM250534,10.66,4.42,Nga'ka,ETHNICITY,ETHNICITY
SM250534,10.66,4.42,Nga'ka,ETHNICITY,ETHNICITY
SM250534,10,6,Nga'ka,ETHNICITY,ETHNICITY
SM250534,10,6,Nga'ka,ETHNICITY,ETHNICITY
SM239544,8.2163,34.5631,Ngaalam,LANGUAGE,LANGUAGE
SM239544,8.2163,34.5631,Ngaalam,LANGUAGE,LANGUAGE
SM239544,34.5631,8.2163,Ngaalam,LANGUAGE,LANGUAGE
SM239544,34.5631,8.2163,Ngaalam,LANGUAGE,LANGUAGE
SM239543,127.602,-25.7514,Ngaanyatjarra,LANGUAGE,LANGUAGE
SM261431,126.8349,-25.0887,Ngaanyatjarraku,ADM2,DISTRICT
SM458066,128.542,-25.0811,Ngaatjatjarra,ETHNICITY,ETHNICITY
SM18161,27.4418,-14.0313,Ngabwe District,ADM2,DISTRICT
SM269806,15.7761,-3.2961,Ngabé,ADM2,DISTRICT
SM239553,120.976,-8.7951,Ngad'a,LANGUAGE,LANGUAGE
SM276495,121,-8.6667,Ngada,ADM2,DISTRICT
SM256258,120.86,-8.62,Ngada,ETHNICITY,ETHNICITY
SM256258,120.86,-8.62,Ngada,ETHNICITY,ETHNICITY
SM256258,122,-8.75,Ngada,ETHNICITY,ETHNICITY
SM256258,122,-8.75,Ngada,ETHNICITY,ETHNICITY
SM459233,142.3486,-12.0784,Ngadhugudi,ETHNICITY,ETHNICITY
SM256259,129.79,-20.58,Ngadi,ETHNICITY,ETHNICITY
SM256259,129.79,-20.58,Ngadi,ETHNICITY,ETHNICITY
SM256259,129.0465,-20.7093,Ngadi,ETHNICITY,ETHNICITY
SM256259,129.0465,-20.7093,Ngadi,ETHNICITY,ETHNICITY
SM256260,114.62,-1.39,Ngadju,ETHNICITY,ETHNICITY
SM458055,123.7412,-32.3247,Ngadjumaya / Ngadju,ETHNICITY,ETHNICITY
SM239550,124.466,-32.0245,Ngadjunmaya,LANGUAGE,LANGUAGE
SM239549,139.0812,-32.9302,Ngadjuri,LANGUAGE,LANGUAGE
SM256261,138.31,-33,Ngadjuri,ETHNICITY,ETHNICITY
SM256261,138.31,-33,Ngadjuri,ETHNICITY,ETHNICITY
SM256261,139.0812,-32.9302,Ngadjuri,ETHNICITY,ETHNICITY
SM256261,139.0812,-32.9302,Ngadjuri,ETHNICITY,ETHNICITY
SM458882,132.596,-12.2117,Ngaduk,ETHNICITY,ETHNICITY
SM262021,29.359,-3.357,Ngagara,ADM2,DISTRICT
SM256262,118.86,-26.01,Ngaiawongga,ETHNICITY,ETHNICITY
SM256262,118.86,-26.01,Ngaiawongga,ETHNICITY,ETHNICITY
SM256262,119.5797,-26.4017,Ngaiawongga,ETHNICITY,ETHNICITY
SM256262,119.5797,-26.4017,Ngaiawongga,ETHNICITY,ETHNICITY
SM239557,146.343,-5.6513,Ngaing,LANGUAGE,LANGUAGE
SM239558,112.794,-1.5846,Ngaju,LANGUAGE,LANGUAGE
SM305136,25.8078,-25.9556,Ngaka Modiri Molema,ADM2,DISTRICT
SM458416,152.934,-31.0695,Ngaku,ETHNICITY,ETHNICITY
SM239569,142.5,-4.2663,Ngala,LANGUAGE,LANGUAGE
SM285379,14.1832,12.2153,Ngala,ADM2,DISTRICT
SM256263,119.76,-20.3,Ngala,ETHNICITY,ETHNICITY
SM256263,119.76,-20.3,Ngala,ETHNICITY,ETHNICITY
SM256263,119.0783,-24.4558,Ngala,ETHNICITY,ETHNICITY
SM256263,119.0783,-24.4558,Ngala,ETHNICITY,ETHNICITY
SM239570,14.188,12.3576,Ngala of Lake Chad,LANGUAGE,LANGUAGE
SM239565,24.6185,6.6319,Ngala-Santandrea,LANGUAGE,LANGUAGE
SM256264,133.99,-13.32,Ngalakan,ETHNICITY,ETHNICITY
SM256264,133.99,-13.32,Ngalakan,ETHNICITY,ETHNICITY
SM256264,134.2708,-14.284,Ngalakan,ETHNICITY,ETHNICITY
SM256264,134.2708,-14.284,Ngalakan,ETHNICITY,ETHNICITY
SM239562,134.716,-14.4281,Ngalakgan,LANGUAGE,LANGUAGE
SM458870,133.1973,-15.9009,Ngalarrunga,ETHNICITY,ETHNICITY
SM256265,131.48,-23.24,Ngalia,ETHNICITY,ETHNICITY
SM256265,131.48,-23.24,Ngalia,ETHNICITY,ETHNICITY
SM458114,129.48,-29.1984,Ngalia,ETHNICITY,ETHNICITY
SM256265,131.1867,-21.8087,Ngalia,ETHNICITY,ETHNICITY
SM256265,131.1867,-21.8087,Ngalia,ETHNICITY,ETHNICITY
SM256266,138.94,-3.27,Ngalik,ETHNICITY,ETHNICITY
SM458831,130.5423,-16.0726,Ngaliwurru,ETHNICITY,ETHNICITY
SM239563,130.8759,-15.7894,Ngaliwurru,DIALECT,LANGUAGE
SM239561,133.936,-13.9783,Ngalkbun,LANGUAGE,LANGUAGE
SM256267,87.21,25.52,Ngalong,ETHNICITY,ETHNICITY
SM256267,87.21,25.52,Ngalong,ETHNICITY,ETHNICITY
SM256267,90,27.5,Ngalong,ETHNICITY,ETHNICITY
SM256267,90,27.5,Ngalong,ETHNICITY,ETHNICITY
SM239567,141.03,-4.6768,Ngalum,LANGUAGE,LANGUAGE
SM461988,141,-5,Ngalum,ETHNICITY,ETHNICITY
SM256269,117.16,-21.01,Ngaluma,ETHNICITY,ETHNICITY
SM256269,117.16,-21.01,Ngaluma,ETHNICITY,ETHNICITY
SM256269,117.5564,-20.9822,Ngaluma,ETHNICITY,ETHNICITY
SM256269,117.5564,-20.9822,Ngaluma,ETHNICITY,ETHNICITY
SM239575,18.4648,8.2596,Ngam,LANGUAGE,LANGUAGE
SM239573,10.0779,5.8797,Ngamambo,LANGUAGE,LANGUAGE
SM458422,152.8905,-30.8151,Ngambaa,ETHNICITY,ETHNICITY
SM239574,15.414,8.5705,Ngambay,LANGUAGE,LANGUAGE
SM454527,16,9,Ngambaye,ETHNICITY,ETHNICITY
SM256270,14.77,-14.99,Ngambwe,ETHNICITY,ETHNICITY
SM256271,138.02,-27.63,Ngameni,ETHNICITY,ETHNICITY
SM256271,138.02,-27.63,Ngameni,ETHNICITY,ETHNICITY
SM256271,138.9612,-26.9438,Ngameni,ETHNICITY,ETHNICITY
SM256271,138.9612,-26.9438,Ngameni,ETHNICITY,ETHNICITY
SM268532,23.6893,-19.7373,Ngamiland East,ADM2,DISTRICT
SM268533,21.9486,-19.4988,Ngamiland West,ADM2,DISTRICT
SM239571,138.701,-27.1797,Ngamini-Yarluyandi-Karangura,LANGUAGE,LANGUAGE
SM239580,11.2108,12.0048,Ngamo,LANGUAGE,LANGUAGE
SM458911,130.1037,-14.1856,Ngan'gikurunggurr,ETHNICITY,ETHNICITY
SM458803,130.6119,-14.0046,Ngan'gimerri,ETHNICITY,ETHNICITY
SM458689,140.9601,-26.3392,Ngananhina,ETHNICITY,ETHNICITY
SM239583,86.2106,73.1354,Nganasan,LANGUAGE,LANGUAGE
SM256273,114.55,72.36,Nganasans,ETHNICITY,ETHNICITY
SM256273,114.55,72.36,Nganasans,ETHNICITY,ETHNICITY
SM256273,114.55,72.36,Nganasans,ETHNICITY,ETHNICITY
SM256273,114.55,72.36,Nganasans,ETHNICITY,ETHNICITY
SM256273,114.55,72.36,Nganasans,ETHNICITY,ETHNICITY
SM256273,114.55,72.36,Nganasans,ETHNICITY,ETHNICITY
SM256273,93.93,74.32,Nganasans,ETHNICITY,ETHNICITY
SM256273,93.93,74.32,Nganasans,ETHNICITY,ETHNICITY
SM256273,93.93,74.32,Nganasans,ETHNICITY,ETHNICITY
SM256273,93.93,74.32,Nganasans,ETHNICITY,ETHNICITY
SM256273,93.93,74.32,Nganasans,ETHNICITY,ETHNICITY
SM256273,93.93,74.32,Nganasans,ETHNICITY,ETHNICITY
SM256273,90,72,Nganasans,ETHNICITY,ETHNICITY
SM256273,90,72,Nganasans,ETHNICITY,ETHNICITY
SM256273,90,72,Nganasans,ETHNICITY,ETHNICITY
SM256273,90,72,Nganasans,ETHNICITY,ETHNICITY
SM256273,90,72,Nganasans,ETHNICITY,ETHNICITY
SM256273,90,72,Nganasans,ETHNICITY,ETHNICITY
SM256273,90,73.83,Nganasans,ETHNICITY,ETHNICITY
SM256273,90,73.83,Nganasans,ETHNICITY,ETHNICITY
SM256273,90,73.83,Nganasans,ETHNICITY,ETHNICITY
SM256273,90,73.83,Nganasans,ETHNICITY,ETHNICITY
SM256273,90,73.83,Nganasans,ETHNICITY,ETHNICITY
SM256273,90,73.83,Nganasans,ETHNICITY,ETHNICITY
SM256273,95,71,Nganasans,ETHNICITY,ETHNICITY
SM256273,95,71,Nganasans,ETHNICITY,ETHNICITY
SM256273,95,71,Nganasans,ETHNICITY,ETHNICITY
SM256273,95,71,Nganasans,ETHNICITY,ETHNICITY
SM256273,95,71,Nganasans,ETHNICITY,ETHNICITY
SM256273,95,71,Nganasans,ETHNICITY,ETHNICITY
SM256273,94.5353,70.9763,Nganasans,ETHNICITY,ETHNICITY
SM256273,94.5353,70.9763,Nganasans,ETHNICITY,ETHNICITY
SM256273,94.5353,70.9763,Nganasans,ETHNICITY,ETHNICITY
SM256273,94.5353,70.9763,Nganasans,ETHNICITY,ETHNICITY
SM256273,94.5353,70.9763,Nganasans,ETHNICITY,ETHNICITY
SM256273,94.5353,70.9763,Nganasans,ETHNICITY,ETHNICITY
SM256273,92.8223,71.1132,Nganasans,ETHNICITY,ETHNICITY
SM256273,92.8223,71.1132,Nganasans,ETHNICITY,ETHNICITY
SM256273,92.8223,71.1132,Nganasans,ETHNICITY,ETHNICITY
SM256273,92.8223,71.1132,Nganasans,ETHNICITY,ETHNICITY
SM256273,92.8223,71.1132,Nganasans,ETHNICITY,ETHNICITY
SM256273,92.8223,71.1132,Nganasans,ETHNICITY,ETHNICITY
SM256274,114.31,-27.22,Nganda,ETHNICITY,ETHNICITY
SM256274,114.31,-27.22,Nganda,ETHNICITY,ETHNICITY
SM256274,113.9744,-26.7246,Nganda,ETHNICITY,ETHNICITY
SM256274,113.9744,-26.7246,Nganda,ETHNICITY,ETHNICITY
SM458677,143.2389,-26.8235,Ngandangara,ETHNICITY,ETHNICITY
SM239585,134.649,-12.8448,Ngandi,LANGUAGE,LANGUAGE
SM459019,134.9064,-14.0086,Ngandi,ETHNICITY,ETHNICITY
SM239592,17.6499,3.7167,Ngando (Central African Republic),LANGUAGE,LANGUAGE
SM239591,22.6231,-0.1436,Ngando-Lalia,LANGUAGE,LANGUAGE
SM458446,153.3036,-28.3516,Nganduwal,ETHNICITY,ETHNICITY
SM239590,21.1928,-16.6359,Ngandyera,LANGUAGE,LANGUAGE
SM239589,0.7051,10.2523,Ngangam,LANGUAGE,LANGUAGE
SM459076,139.4514,-34.8315,Ngangaruku,ETHNICITY,ETHNICITY
SM276355,111.9333,-7.6,Nganjuk,ADM2,DISTRICT
SM239586,151.313,-29.581,Nganyaywana,LANGUAGE,LANGUAGE
SM285380,13.1041,12.5088,Nganzai,ADM2,DISTRICT
SM297021,99.953,18.7595,Ngao,ADM2,DISTRICT
SM10247,15.6627,7.2765,Ngaoundaye,ADM2,DISTRICT
SM256276,20.64,8.11,Ngapou,ETHNICITY,ETHNICITY
SM299234,30.6614,-2.6436,Ngara,ADM2,DISTRICT
SM2421,134.625,7.62,Ngaraard,ADM1,DISTRICT
SM458460,152.6829,-29.4857,Ngarabal,ETHNICITY,ETHNICITY
SM458447,153.3279,-27.8861,Ngarahgwal,ETHNICITY,ETHNICITY
SM459058,139.2284,-35.0189,Ngaralda,ETHNICITY,ETHNICITY
SM256277,146,-6,Ngarawapum,ETHNICITY,ETHNICITY
SM458436,151.7815,-29.5278,Ngarbal,ETHNICITY,ETHNICITY
SM2422,134.62,7.708,Ngarchelong,ADM1,DISTRICT
SM458823,131.9323,-11.2898,Ngardawuli,ETHNICITY,ETHNICITY
SM239607,129.0308,-21.0231,Ngardi,LANGUAGE,LANGUAGE
SM458129,129.7356,-19.6235,Ngardilypa,ETHNICITY,ETHNICITY
SM2423,134.573,7.6,Ngardmau,ADM1,DISTRICT
SM269361,82.5751,33.2184,Ngari,ADM2,DISTRICT
SM256279,149.01,-36.82,Ngarigo,ETHNICITY,ETHNICITY
SM256279,149.01,-36.82,Ngarigo,ETHNICITY,ETHNICITY
SM256279,148.7674,-35.9022,Ngarigo,ETHNICITY,ETHNICITY
SM256279,148.7674,-35.9022,Ngarigo,ETHNICITY,ETHNICITY
SM239615,149.13,-36.23,Ngarigu,DIALECT,LANGUAGE
SM239601,130.651,-15.4661,Ngarinman,LANGUAGE,LANGUAGE
SM239603,126.433,-16.4006,Ngarinyin,LANGUAGE,LANGUAGE
SM459105,140.3522,-35.3611,Ngarkat,ETHNICITY,ETHNICITY
SM239614,119.3058,-20.2075,Ngarla,LANGUAGE,LANGUAGE
SM459146,119.7052,-20.2632,Ngarla,ETHNICITY,ETHNICITY
SM458068,117.6074,-23.7997,Ngarla,ETHNICITY,ETHNICITY
SM239605,118.8259,-20.3736,Ngarla on the Ashburton river south of the Hamersley range,LANGUAGE,LANGUAGE
SM458821,132.36,-14.0884,Ngarlahmi,ETHNICITY,ETHNICITY
SM239606,117.203,-20.9235,Ngarluma,LANGUAGE,LANGUAGE
SM239604,127.452,-15.7001,Ngarnawu,DIALECT,LANGUAGE
SM458648,127.2888,-16.1876,Ngarnawu,ETHNICITY,ETHNICITY
SM239602,134.73,-17.42,Ngarnka,LANGUAGE,LANGUAGE
SM458760,134.8263,-16.4517,Ngarnka,ETHNICITY,ETHNICITY
SM458428,148.9347,-20.2955,Ngaro,ETHNICITY,ETHNICITY
SM239618,9.4615,9.3204,Ngas,LANGUAGE,LANGUAGE
SM239617,37.528,-2.9772,Ngasa,LANGUAGE,LANGUAGE
SM284938,4.7178,10.4071,Ngaski,ADM2,DISTRICT
SM239621,157.1561,5.7875,Ngatik Men's Creole,LANGUAGE,LANGUAGE
SM256280,145.56,-17.36,Ngatjan,ETHNICITY,ETHNICITY
SM256280,145.56,-17.36,Ngatjan,ETHNICITY,ETHNICITY
SM256280,145.7048,-17.3638,Ngatjan,ETHNICITY,ETHNICITY
SM256280,145.7048,-17.3638,Ngatjan,ETHNICITY,ETHNICITY
SM2424,134.5198,7.4869,Ngatpang,ADM1,DISTRICT
SM269327,102.6366,32.4188,Ngawa Tibetan and Qiang,ADM2,DISTRICT
SM459034,140.2256,-34.2245,Ngawadj,ETHNICITY,ETHNICITY
SM276356,111.3344,-7.4744,Ngawi,ADM2,DISTRICT
SM239623,93.5835,21.9303,Ngawn Chin,LANGUAGE,LANGUAGE
SM239624,141.471,-19.4484,Ngawun,LANGUAGE,LANGUAGE
SM458473,141.9629,-19.8027,Ngawun,ETHNICITY,ETHNICITY
SM459084,139.5016,-34.4033,Ngayawang,ETHNICITY,ETHNICITY
SM459291,145.3759,-17.3853,Ngaygungu,ETHNICITY,ETHNICITY
SM239627,43.36,-11.6129,Ngazidja Comorian,LANGUAGE,LANGUAGE
SM239631,18.12,4,Ngbaka Ma'bo,LANGUAGE,LANGUAGE
SM239634,18.4119,5.3017,Ngbaka Manza,LANGUAGE,LANGUAGE
SM239632,18.994,2.9889,Ngbaka Minagende,LANGUAGE,LANGUAGE
SM16342,14.9555,1.9483,Ngbala,ADM2,DISTRICT
SM250583,25.04,3.16,Ngbandi,ETHNICITY,ETHNICITY
SM250583,25.04,3.16,Ngbandi,ETHNICITY,ETHNICITY
SM250583,22,4,Ngbandi,ETHNICITY,ETHNICITY
SM250583,22,4,Ngbandi,ETHNICITY,ETHNICITY
SM239639,27.6529,3.6045,Ngbee,LANGUAGE,LANGUAGE
SM239641,23.5669,3.2725,Ngbinda,LANGUAGE,LANGUAGE
SM239643,18.7183,3.8225,Ngbundu,LANGUAGE,LANGUAGE
SM2425,134.6,7.47,Ngchesar,ADM1,DISTRICT
SM239646,24.8754,1.8131,Ngelima,LANGUAGE,LANGUAGE
SM239650,10.1469,5.9786,Ngemba,LANGUAGE,LANGUAGE
SM244324,167.1389,-15.2553,Ngen,LANGUAGE,LANGUAGE
SM239658,-3.97,7.76,Ngen of Djonkro,LANGUAGE,LANGUAGE
SM458813,130.4613,-14.2456,Ngen'giwumirri,ETHNICITY,ETHNICITY
SM239659,13.128,-13.8875,Ngendelengo,LANGUAGE,LANGUAGE
SM2426,134.5383,7.5365,Ngeremlengui,ADM1,DISTRICT
SM239664,15.2905,9.319,Ngete,LANGUAGE,LANGUAGE
SM280815,101.1355,19.7205,Ngeun,ADM2,DISTRICT
SM250561,10,12,Ngezim,ETHNICITY,ETHNICITY
SM295316,158.1775,-8.7532,Nggatokae,ADM2,DISTRICT
SM461528,160.1471,-9.1016,Nggela,ETHNICITY,ETHNICITY
SM239667,139.011,-3.6274,Nggem,LANGUAGE,LANGUAGE
SM295385,159.9285,-9.4321,Nggosi,ADM2,DISTRICT
SM239670,12.9234,10.6058,Nggwahyi,LANGUAGE,LANGUAGE
SM304319,105.6241,18.8313,Nghi Lộc,ADM2,DISTRICT
SM304170,105.7683,18.6242,Nghi Xuân,ADM2,DISTRICT
SM304413,108.8004,14.9871,Nghĩa Hành,ADM2,DISTRICT
SM304314,106.1735,20.106,Nghĩa Hưng,ADM2,DISTRICT
SM304588,104.504,21.5988,Nghĩa Lộ,ADM2,DISTRICT
SM304320,105.4407,19.3823,Nghĩa Đàn,ADM2,DISTRICT
SM2427,104.9167,19.25,Nghệ An,ADM1,DISTRICT
SM239673,9.7939,5.9385,Ngie,LANGUAGE,LANGUAGE
SM239672,10.1266,5.5678,Ngiemboon,LANGUAGE,LANGUAGE
SM239676,29.997,10.7194,Ngile,LANGUAGE,LANGUAGE
SM239677,37.9832,-9.7815,Ngindo,LANGUAGE,LANGUAGE
SM250560,37.29,-10.6,Ngindo,ETHNICITY,ETHNICITY
SM459040,141.1084,-34.373,Ngintait,ETHNICITY,ETHNICITY
SM239689,30.2242,1.3046,Ngiti,LANGUAGE,LANGUAGE
SM2428,134.621,7.558,Ngiwal,ADM1,DISTRICT
SM248272,146.18,-30.6481,Ngiyambaa,LANGUAGE,LANGUAGE
SM239690,11.0178,12.1051,Ngizim,LANGUAGE,LANGUAGE
SM226165,140.753,-8.853,Ngkontar Baedi,LANGUAGE,LANGUAGE
SM239692,140.818,-8.8704,Ngkontar Ngkolmpu,LANGUAGE,LANGUAGE
SM239697,141.8868,-12.7406,Ngkoth,LANGUAGE,LANGUAGE
SM459394,142.0521,-12.8276,Ngkoth,ETHNICITY,ETHNICITY
SM16343,15.4685,-2.5608,Ngo,ADM2,DISTRICT
SM269535,10.5746,6.0073,Ngo Ketunjia,ADM2,DISTRICT
SM239704,97.4333,25.35,Ngochang,LANGUAGE,LANGUAGE
SM458203,152.3593,-27.0691,Ngoera,ETHNICITY,ETHNICITY
SM280886,102.7515,20.7339,Ngoi,ADM2,DISTRICT
SM256287,27.44,-32.52,Ngoika,ETHNICITY,ETHNICITY
SM256287,28.76,-32.06,Ngoika,ETHNICITY,ETHNICITY
SM16344,15.2687,-0.6012,Ngoko,ADM2,DISTRICT
SM239717,14.1345,0.8829,Ngom,LANGUAGE,LANGUAGE
SM295153,30.4696,-2.1853,Ngoma,ADM1:ADM2,DISTRICT
SM256288,32.25,-25.84,Ngomane,ETHNICITY,ETHNICITY
SM239719,10.237,5.6307,Ngomba,LANGUAGE,LANGUAGE
SM239718,10.1446,5.7031,Ngombale,LANGUAGE,LANGUAGE
SM250562,18.86,-0.85,Ngombe,ETHNICITY,ETHNICITY
SM250562,18.86,-0.85,Ngombe,ETHNICITY,ETHNICITY
SM250562,20,2,Ngombe,ETHNICITY,ETHNICITY
SM250562,20,2,Ngombe,ETHNICITY,ETHNICITY
SM239713,15.2154,3.9066,Ngombe (Central African Republic),LANGUAGE,LANGUAGE
SM239715,20.1415,1.4536,Ngombe (Democratic Republic of Congo),LANGUAGE,LANGUAGE
SM226489,14.7506,2.1872,Ngombe-Bangandu,LANGUAGE,LANGUAGE
SM458883,132.1296,-12.7936,Ngomburr,ETHNICITY,ETHNICITY
SM256289,33.28,-10.23,Ngonde,ETHNICITY,ETHNICITY
SM256289,33.28,-10.23,Ngonde,ETHNICITY,ETHNICITY
SM256289,33.28,-10.23,Ngonde,ETHNICITY,ETHNICITY
SM256289,33.5,-9.5,Ngonde,ETHNICITY,ETHNICITY
SM256289,33.5,-9.5,Ngonde,ETHNICITY,ETHNICITY
SM256289,33.5,-9.5,Ngonde,ETHNICITY,ETHNICITY
SM256289,34,-10,Ngonde,ETHNICITY,ETHNICITY
SM256289,34,-10,Ngonde,ETHNICITY,ETHNICITY
SM256289,34,-10,Ngonde,ETHNICITY,ETHNICITY
SM239732,13.4286,8.7887,Ngong,LANGUAGE,LANGUAGE
SM239724,18.2,-5.5,Ngongo,LANGUAGE,LANGUAGE
SM260426,14.9167,-8.9167,Ngonguembo,ADM2,DISTRICT
SM239726,37.4118,-11.7794,Ngoni,FAMILY:LANGUAGE,LANGUAGE
SM250999,35.41,-11.53,Ngoni,ETHNICITY,ETHNICITY
SM250999,35.41,-11.53,Ngoni,ETHNICITY,ETHNICITY
SM250999,31.5,-14.03,Ngoni,ETHNICITY,ETHNICITY
SM250999,31.5,-14.03,Ngoni,ETHNICITY,ETHNICITY
SM250999,33,-12,Ngoni,ETHNICITY,ETHNICITY
SM250999,33,-12,Ngoni,ETHNICITY,ETHNICITY
SM284758,7.1589,5.3202,Ngor-Okp,ADM2,DISTRICT
SM299309,33.7583,1.482,Ngora,ADM1:ADM2,DISTRICT
SM239773,34.6173,-1.8485,Ngoreme,LANGUAGE,LANGUAGE
SM299081,35.5366,-2.6883,Ngorongoro,ADM2,DISTRICT
SM295157,29.569,-1.8782,Ngororero,ADM1:ADM2,DISTRICT
SM458822,131.678,-13.4518,Ngorrkgowo,ETHNICITY,ETHNICITY
SM239738,9.8319,6.0462,Ngoshie,LANGUAGE,LANGUAGE
SM2430,11.1667,-1.6333,Ngounié,ADM1,DISTRICT
SM10328,16.5146,12.7333,Ngoura,ADM2,DISTRICT
SM296794,16.38,8.97,Ngourkosso,ADM2,DISTRICT
SM262001,29.8305,-2.9481,Ngozi,ADM2,DISTRICT
SM2431,29.925,-2.875,Ngozi,ADM1,DISTRICT
SM239744,10.7681,-3.4334,Ngubi,LANGUAGE,LANGUAGE
SM458475,139.0358,-18.5336,Nguburinji,ETHNICITY,ETHNICITY
SM296727,31.5511,-27.0358,Ngudzeni,ADM2,DISTRICT
SM239750,19.5129,-4.0982,Ngul,LANGUAGE,LANGUAGE
SM239749,37.6823,-5.7973,Ngulu,LANGUAGE,LANGUAGE
SM14953,137.481,8.45,Ngulu Municipality,ADM2,DISTRICT
SM458496,139.8176,-24.0564,Ngulupulu,ETHNICITY,ETHNICITY
SM239748,137.34,8.45,Nguluwan,LANGUAGE,LANGUAGE
SM250400,11,3,Ngumba,ETHNICITY,ETHNICITY
SM250400,11,3,Ngumba,ETHNICITY,ETHNICITY
SM250400,10.3,3,Ngumba,ETHNICITY,ETHNICITY
SM250400,10.3,3,Ngumba,ETHNICITY,ETHNICITY
SM458635,122.4915,-17.5152,Ngumbarl,ETHNICITY,ETHNICITY
SM239752,122.23,-17.4912,Ngumbarl,LANGUAGE,LANGUAGE
SM239754,9.7361,1.721,Ngumbi,LANGUAGE,LANGUAGE
SM251066,33.31,-14.79,Ngumbo,ETHNICITY,ETHNICITY
SM251066,31.18,-11.01,Ngumbo,ETHNICITY,ETHNICITY
SM239766,148.77,-36.25,Ngunawal,DIALECT,LANGUAGE
SM458154,149.0871,-34.9803,Ngunawal / Ngunnawal,ETHNICITY,ETHNICITY
SM239759,16.125,1.6392,Ngundi,LANGUAGE,LANGUAGE
SM239759,16.125,1.6392,Ngundi,LANGUAGE,LANGUAGE
SM239759,16.2146,3.0211,Ngundi,LANGUAGE,LANGUAGE
SM239759,16.2146,3.0211,Ngundi,LANGUAGE,LANGUAGE
SM239756,20.4218,4.8863,Ngundu,LANGUAGE,LANGUAGE
SM239761,15.6218,-1.8206,Ngungwel,LANGUAGE,LANGUAGE
SM256294,34.49,-15.19,Nguni,ETHNICITY,ETHNICITY
SM239771,143.216,-29.2411,Ngura,LANGUAGE,LANGUAGE
SM239771,143.216,-29.2411,Ngura,LANGUAGE,LANGUAGE
SM239771,142.45,-27.58,Ngura,LANGUAGE,LANGUAGE
SM239771,142.45,-27.58,Ngura,LANGUAGE,LANGUAGE
SM459098,145.0817,-36.4728,Ngurai-illamwurrung,ETHNICITY,ETHNICITY
SM256295,140.05,-27.32,Ngurawola,ETHNICITY,ETHNICITY
SM458164,147.8024,-25.6023,Nguri,ETHNICITY,ETHNICITY
SM458083,122.3285,-21.2433,Ngurlipartu,ETHNICITY,ETHNICITY
SM239770,132.508,-12.6483,Ngurmbur,LANGUAGE,LANGUAGE
SM285262,10.3991,12.9797,Nguru,ADM2,DISTRICT
SM458705,140.4829,-30.7332,Ngurunta,ETHNICITY,ETHNICITY
SM250568,37.6,-5.65,Nguu,ETHNICITY,ETHNICITY
SM250568,37.6,-5.65,Nguu,ETHNICITY,ETHNICITY
SM250568,38,-6,Nguu,ETHNICITY,ETHNICITY
SM250568,38,-6,Nguu,ETHNICITY,ETHNICITY
SM303972,105.9325,22.624,Nguyên Bình,ADM2,DISTRICT
SM239768,105.932,17.7243,Nguôn,LANGUAGE,LANGUAGE
SM239779,12.7942,10.0921,Ngwaba,LANGUAGE,LANGUAGE
SM250569,26.42,-23.97,Ngwaketse,ETHNICITY,ETHNICITY
SM268537,24.8644,-25.0334,Ngwaketse Central,ADM2,DISTRICT
SM268538,24.1011,-24.5944,Ngwaketse North,ADM2,DISTRICT
SM268539,24.8079,-25.4537,Ngwaketse South,ADM2,DISTRICT
SM250570,25.85,-21.82,Ngwatu,ETHNICITY,ETHNICITY
SM250570,24.64,-19.86,Ngwatu,ETHNICITY,ETHNICITY
SM239780,9.9028,5.4979,Ngwe,LANGUAGE,LANGUAGE
SM296704,31.2888,-26.6999,Ngwenpisi,ADM2,DISTRICT
SM239782,9.8437,6.1774,Ngwo,LANGUAGE,LANGUAGE
SM304305,106.0103,22.4296,Ngân Sơn,ADM2,DISTRICT
SM304093,105.8192,9.8319,Ngã Bảy,ADM2,DISTRICT
SM304456,105.5948,9.5613,Ngã Năm,ADM2,DISTRICT
SM239548,-82.3911,8.8251,Ngäbere,LANGUAGE,LANGUAGE
SM304077,106.7017,20.8577,Ngô Quyền,ADM2,DISTRICT
SM2429,-81.7308,8.4862,Ngöbe Buglé,ADM1,DISTRICT
SM304025,108.25,16,Ngũ Hành Sơn,ADM2,DISTRICT
SM303957,104.9969,8.6622,Ngọc Hiển,ADM2,DISTRICT
SM304243,107.6264,14.7194,Ngọc Hồi,ADM2,DISTRICT
SM304507,105.3739,20.0678,Ngọc Lặc,ADM2,DISTRICT
SM304217,109.1666,12.2546,Nha Trang,ADM2,DISTRICT
SM275104,-15.505,11.969,Nhacra,ADM2,DISTRICT
SM283969,34.268,-19.349,Nhamatanda,ADM2,DISTRICT
SM267338,-57.6563,-1.6805,Nhamundá,ADM2,DISTRICT
SM239783,114.268,-27.4252,Nhanda,LANGUAGE,LANGUAGE
SM266729,-50.0561,-20.6614,Nhandeara,ADM2,DISTRICT
SM250611,14,-15,Nhaneca,ETHNICITY,ETHNICITY
SM458853,135.7022,-11.8532,Nhangu,ETHNICITY,ETHNICITY
SM458102,115.275,-29.2176,Nhanhagardi,ETHNICITY,ETHNICITY
SM260411,17.21,-11.2663,Nharea,ADM2,DISTRICT
SM459254,146.7891,-19.2353,Nhawalgaba,ETHNICITY,ETHNICITY
SM239785,-66.9646,1.045,Nhengatu,LANGUAGE,LANGUAGE
SM458687,140.8402,-26.9553,Nhirrpi,ETHNICITY,ETHNICITY
SM239787,141.276,-28.6291,Nhirrpi,DIALECT,LANGUAGE
SM15078,32.5572,-25.9452,Nhlamankulo,ADM2,DISTRICT
SM296705,31.3983,-26.6453,Nhlambeni,ADM2,DISTRICT
SM256298,28.09,-29.17,Nhlangwini,ETHNICITY,ETHNICITY
SM304342,105.7544,20.2761,Nho Quan,ADM2,DISTRICT
SM280870,105.2649,17.5598,Nhommalath,ADM2,DISTRICT
SM280765,101.874,22.1959,Nhot Ou,ADM2,DISTRICT
SM458082,115.4762,-25.805,Nhugarn,ETHNICITY,ETHNICITY
SM239789,115.7454,-21.3815,Nhuwala,LANGUAGE,LANGUAGE
SM459141,115.686,-21.5388,Nhuwala,ETHNICITY,ETHNICITY
SM304119,106.734,10.6455,Nhà Bè,ADM2,DISTRICT
SM304005,106.8821,10.6969,Nhơn Trạch,ADM2,DISTRICT
SM304509,105.5562,19.578,Như Thanh,ADM2,DISTRICT
SM304510,105.3886,19.6253,Như Xuân,ADM2,DISTRICT
SM14001,-8.0839,6.2569,Niabo,ADM2,DISTRICT
SM283389,-3.821,15.889,Niafunké,ADM2,DISTRICT
SM301893,-78.691,43.1731,Niagara,ADM2,DISTRICT
SM12586,-77.8188,18.2653,Niagara,ADM2,DISTRICT
SM268868,-79.2496,43.0001,Niagara,ADM2,DISTRICT
SM284613,2.2,13.5333,Niamey,ADM2,DISTRICT
SM2432,2.1053,13.5214,Niamey,ADM1,DISTRICT
SM275051,-15.3333,13.5833,Niamina Dankunku,ADM2,DISTRICT
SM275052,-15.0833,13.6333,Niamina East,ADM2,DISTRICT
SM275053,-15.25,13.5833,Niamina West,ADM2,DISTRICT
SM275054,-14.9167,13.6667,Niani,ADM2,DISTRICT
SM275055,-15.091,13.729,Nianija,ADM2,DISTRICT
SM256300,-71.87,41.52,Niantic,ETHNICITY,ETHNICITY
SM2433,12.3486,-3.0144,Niari,ADM1,DISTRICT
SM239795,97.4961,0.9069,Nias,LANGUAGE,LANGUAGE
SM276686,97.7667,1.0333,Nias,ADM2,DISTRICT
SM276682,97.5861,1.0597,Nias Barat,ADM2,DISTRICT
SM276683,97.75,0.77,Nias Selatan,ADM2,DISTRICT
SM276684,97.3196,1.3304,Nias Utara,ADM2,DISTRICT
SM256302,97.6,1.11,Niassan,ETHNICITY,ETHNICITY
SM2434,166.9223,-0.5173,Nibok,ADM1,DISTRICT
SM239801,-83.7386,12.4402,Nicaragua Creole English,LANGUAGE,LANGUAGE
SM239798,-86.3399,12.2509,Nicaraguan Sign Language,LANGUAGE,LANGUAGE
SM3958,21.09,56.3492,Nicas Novads,ADM1,DISTRICT
SM13709,21.072,56.3176,Nicas pagasts,ADM2,DISTRICT
SM271122,-75.3064,20.0864,Niceto Pérez,ADM2,DISTRICT
SM13710,26.4025,56.1511,Nicgales pagasts,ADM2,DISTRICT
SM13040,131.3341,31.6374,Nichinan Shi,ADM2,DISTRICT
SM303135,-80.7993,38.2917,Nicholas,ADM2,DISTRICT
SM301099,-84.0153,38.3356,Nicholas,ADM2,DISTRICT
SM2435,-56.8333,5.6667,Nickerie,ADM1,DISTRICT
SM276806,93.7903,7.03,Nicobar Islands,ADM2,DISTRICT
SM256306,92.77,9.17,Nicobarese,ETHNICITY,ETHNICITY
SM256306,92.77,9.17,Nicobarese,ETHNICITY,ETHNICITY
SM256306,93.75,7,Nicobarese,ETHNICITY,ETHNICITY
SM256306,93.75,7,Nicobarese,ETHNICITY,ETHNICITY
SM15079,36.814,-17.603,Nicodala District  (2013-),ADM2,DISTRICT
SM290184,28.3532,44.3983,Nicolae Balcescu,ADM2,DISTRICT
SM289964,26.7584,44.4451,Nicolae Balcescu,ADM2,DISTRICT
SM265777,-52.4444,-28.5244,Nicolau Vergueiro,ADM2,DISTRICT
SM18350,-56.0148,-34.8059,Nicolich,ADM2,DISTRICT
SM301386,-94.2473,44.3499,Nicollet,ADM2,DISTRICT
SM282238,-97.3323,18.6198,Nicolás Bravo,ADM2,DISTRICT
SM281692,-99.1732,20.7943,Nicolás Flores,ADM2,DISTRICT
SM281945,-99.3114,19.623,Nicolás Romero,ADM2,DISTRICT
SM283169,-92.6036,16.4483,Nicolás Ruíz,ADM2,DISTRICT
SM2436,33.1,35.0667,Nicosia,ADM1,DISTRICT
SM10936,33.3608,35.1694,Nicosia Municipality,ADM2,DISTRICT
SM270931,-85.5081,10.1116,Nicoya,ADM2,DISTRICT
SM292584,26.6602,47.8617,Nicseni,ADM2,DISTRICT
SM290289,25.9635,44.6866,Niculesti,ADM2,DISTRICT
SM292063,28.4814,45.1825,Niculitel,ADM2,DISTRICT
SM268932,8.38,46.9536,Nidwalden,ADM2,DISTRICT
SM2438,8.4103,46.9274,Nidwalden,ADM1,DISTRICT
SM288770,20.4986,53.3724,Nidzica,ADM2,DISTRICT
SM370524,6.26,49.6475,Niederanven,ADM1:ADM2:ADM3,DISTRICT
SM2440,9.1667,52.8333,Niedersachsen,ADM1,DISTRICT
SM2439,15.75,48.3333,Niederösterreich,ADM1,DISTRICT
SM275123,10.2315,1.8829,Niefang,ADM2,DISTRICT
SM239807,17.8135,9.7036,Niellim,LANGUAGE,LANGUAGE
SM285598,5.0954,52.0284,Nieuwegein,ADM2,DISTRICT
SM2441,34.75,37.8333,Nigde (1989-),ADM1,DISTRICT
SM2442,6,10,Niger,ADM1,DISTRICT
SM250879,12,7,Nigerian Fulfulde,ETHNICITY,ETHNICITY
SM250879,12,7,Nigerian Fulfulde,ETHNICITY,ETHNICITY
SM250879,11,11,Nigerian Fulfulde,ETHNICITY,ETHNICITY
SM250879,11,11,Nigerian Fulfulde,ETHNICITY,ETHNICITY
SM239816,4.2107,9.6587,Nigerian Pidgin,LANGUAGE,LANGUAGE
SM239812,3.1666,6.5,Nigerian Sign Language,LANGUAGE,LANGUAGE
SM239821,142.55,-4.45,Nigilu,LANGUAGE,LANGUAGE
SM13711,22.0475,56.4588,Nigrandes pagasts,ADM2,DISTRICT
SM271834,-70.0822,18.3593,Nigua,ADM2,DISTRICT
SM239822,76.3318,21.1886,Nihali,LANGUAGE,LANGUAGE
SM304939,44.5746,15.7516,Nihm,ADM2,DISTRICT
SM279758,140.4954,37.584,Nihonmatsu,ADM2,DISTRICT
SM239824,144.518,-5.763,Nii,LANGUAGE,LANGUAGE
SM278939,139.033,37.8274,Niigata,ADM2,DISTRICT
SM2443,138.9175,37.5234,Niigata,ADM1:ADMX,DISTRICT
SM279626,133.3325,33.9067,Niihama,ADM2,DISTRICT
SM278321,142.4715,42.5465,Niikappu,ADM2,DISTRICT
SM279027,133.449,35.0157,Niimi,ADM2,DISTRICT
SM279190,139.5656,35.7935,Niiza,ADM2,DISTRICT
SM239826,120.8306,-22.8558,Nijadali,LANGUAGE,LANGUAGE
SM305542,19.0352,45.139,Nijemci,ADM2,DISTRICT
SM285795,5.483,52.217,Nijkerk,ADM2,DISTRICT
SM285796,5.8381,51.8417,Nijmegen,ADM2,DISTRICT
SM260065,69.646,35.0463,Nijrab,ADM2,DISTRICT
SM260157,69.24,33.2065,Nika,ADM2,DISTRICT
SM278566,139.9731,39.1968,Nikaho,ADM2,DISTRICT
SM297086,104.5586,16.3538,Nikhom Kham Soi,ADM2,DISTRICT
SM297510,103.7351,17.1735,Nikhom Nam Un,ADM2,DISTRICT
SM10937,32.9753,35.0513,Nikitari,ADM2,DISTRICT
SM10938,32.9543,35.1723,Nikitas,ADM2,DISTRICT
SM262152,3.156,9.9534,Nikki,ADM2,DISTRICT
SM279322,139.7,36.75,Nikkō,ADM2,DISTRICT
SM10939,32.5725,34.7299,Nikokleia,ADM2,DISTRICT
SM262376,27.2667,43.5833,Nikola Kozlevo,ADM2,DISTRICT
SM262446,25.7833,42.6,Nikolaevo,ADM2,DISTRICT
SM294915,45.8333,50,Nikolaevskiy rayon,ADM2,DISTRICT
SM262340,24.9333,43.6,Nikopol,ADM2,DISTRICT
SM18255,34.3983,47.5671,Nikopol Raion,ADM2,DISTRICT
SM13712,21.9283,56.561,Nikraces pagasts,ADM2,DISTRICT
SM298956,36.9717,40.6051,Niksar,ADM2,DISTRICT
SM239829,142.45,-4.8258,Niksek,LANGUAGE,LANGUAGE
SM277621,60,26.25,Nikshahr,ADM2,DISTRICT
SM13335,-174.5225,-4.6741,Nikumaroro,ADM2,DISTRICT
SM13336,176.4641,-1.3657,Nikunau,ADM2,DISTRICT
SM2444,18.9203,42.7994,Nikšic,ADM1,DISTRICT
SM239833,129.5121,-6.735,Nila,LANGUAGE,LANGUAGE
SM239831,34.3068,-4.5,Nilamba,LANGUAGE,LANGUAGE
SM9567,66.0762,33.7633,Nili,ADM2,DISTRICT
SM261268,145.2171,-37.6147,Nillumbik,ADM2,DISTRICT
SM270029,-74.6057,4.3134,Nilo,ADM2,DISTRICT
SM267557,-39.2388,-13.6481,Nilo Peçanha,ADM2,DISTRICT
SM262280,88.95,25.9667,Nilphamari,ADM2,DISTRICT
SM265286,-43.427,-22.8211,Nilópolis,ADM2,DISTRICT
SM298248,28.9846,40.2138,Nilüfer,ADM2,DISTRICT
SM454620,-120.167,36.1665,Nim Yokuts,ETHNICITY,ETHNICITY
SM239838,76.1264,22.1263,Nimadi,LANGUAGE,LANGUAGE
SM270030,-74.4034,5.1174,Nimaima,ADM2,DISTRICT
SM239839,123.228,-17.374,Nimanbur,LANGUAGE,LANGUAGE
SM256313,123.24,-17.62,Nimanburu,ETHNICITY,ETHNICITY
SM256313,123.24,-17.62,Nimanburu,ETHNICITY,ETHNICITY
SM256313,123.1342,-17.2647,Nimanburu,ETHNICITY,ETHNICITY
SM256313,123.1342,-17.2647,Nimanburu,ETHNICITY,ETHNICITY
SM2445,-8.7833,6.8167,Nimba,ADM1,DISTRICT
SM239841,13.8266,9.6758,Nimbari,LANGUAGE,LANGUAGE
SM241033,140.179,-2.5803,Nimboran,LANGUAGE,LANGUAGE
SM239844,146.679,-6.3407,Nimi,LANGUAGE,LANGUAGE
SM292513,24.3062,47.247,Nimigea De Jos,ADM2,DISTRICT
SM239847,141.578,-4.3582,Nimo,LANGUAGE,LANGUAGE
SM239846,153.158,-11.2297,Nimoa,LANGUAGE,LANGUAGE
SM2446,62.5,31,Nimroz,ADM1,DISTRICT
SM11790,61.416,31.1033,Nimruz,ADM2,DISTRICT
SM10528,15.184,44.2422,Nin (2006-),ADM2,DISTRICT
SM262831,-43.7717,-3.4638,Nina Rodrigues,ADM2,DISTRICT
SM239850,-62.7801,4.0389,Ninam,LANGUAGE,LANGUAGE
SM256314,-64.01,4.63,Ninam,ETHNICITY,ETHNICITY
SM256314,-64.01,4.63,Ninam,ETHNICITY,ETHNICITY
SM256314,-64.01,4.63,Ninam,ETHNICITY,ETHNICITY
SM256314,-64.01,4.63,Ninam,ETHNICITY,ETHNICITY
SM256314,-63.75,2.5,Ninam,ETHNICITY,ETHNICITY
SM256314,-63.75,2.5,Ninam,ETHNICITY,ETHNICITY
SM256314,-63.75,2.5,Ninam,ETHNICITY,ETHNICITY
SM256314,-63.75,2.5,Ninam,ETHNICITY,ETHNICITY
SM256314,-63,4,Ninam,ETHNICITY,ETHNICITY
SM256314,-63,4,Ninam,ETHNICITY,ETHNICITY
SM256314,-63,4,Ninam,ETHNICITY,ETHNICITY
SM256314,-63,4,Ninam,ETHNICITY,ETHNICITY
SM256314,-66.17,3.86,Ninam,ETHNICITY,ETHNICITY
SM256314,-66.17,3.86,Ninam,ETHNICITY,ETHNICITY
SM256314,-66.17,3.86,Ninam,ETHNICITY,ETHNICITY
SM256314,-66.17,3.86,Ninam,ETHNICITY,ETHNICITY
SM2447,42.5,36.0833,Ninawa,ADM1,DISTRICT
SM235742,167.442,-16.4291,Ninde,LANGUAGE,LANGUAGE
SM239854,36.3116,-11.2569,Nindi,LANGUAGE,LANGUAGE
SM285447,-86.1391,12.049,Nindirí,ADM2,DISTRICT
SM239869,121.5333,29.8667,Ningbo,DIALECT,LANGUAGE
SM269389,121.55,29.8,Ningbo,ADM2,DISTRICT
SM269396,119.5227,27.0184,Ningde,ADM2,DISTRICT
SM239864,141.454,-2.7715,Ningera,LANGUAGE,LANGUAGE
SM239863,141.158,-5.4319,Ninggerum,LANGUAGE,LANGUAGE
SM285316,9.2049,11.0623,Ningi,ADM2,DISTRICT
SM239862,142.251,-3.4973,Ningil,LANGUAGE,LANGUAGE
SM11427,0.1714,5.8126,Ningo Prampram,ADM2,DISTRICT
SM2448,106,37.25,Ningxia Hui,ADM1,DISTRICT
SM239868,106.2401,38.5467,Ningxia Mandarin,DIALECT,LANGUAGE
SM239860,8.4356,9.2934,Ningye,DIALECT,LANGUAGE
SM304343,105.9719,20.2474,Ninh Bình,ADM2,DISTRICT
SM2449,105.9,20.2333,Ninh Bình,ADM1,DISTRICT
SM304073,106.3333,20.7501,Ninh Giang,ADM2,DISTRICT
SM304218,109.0606,12.538,Ninh Hòa,ADM2,DISTRICT
SM304348,109.1271,11.6106,Ninh Hải,ADM2,DISTRICT
SM303947,105.7625,10.0291,Ninh Kiều,ADM2,DISTRICT
SM304349,108.8804,11.5612,Ninh Phước,ADM2,DISTRICT
SM304350,108.7708,11.7083,Ninh Sơn,ADM2,DISTRICT
SM2450,108.8333,11.75,Ninh Thuận,ADM1,DISTRICT
SM263692,-41.6578,-15.4223,Ninheira,ADM2,DISTRICT
SM239872,139.39,-4.4737,Ninia Yali,LANGUAGE,LANGUAGE
SM278444,141.1958,40.238,Ninohe Town,ADM2,DISTRICT
SM11330,43.7,41.25,Ninots'mindis Munitsip'alit'et'I (excl. Ninotsminda),ADM2,DISTRICT
SM11331,43.5888,41.2636,Ninotsminda,ADM2,DISTRICT
SM239878,8.4733,8.9579,Ninzo,LANGUAGE,LANGUAGE
SM256317,11.84,9.82,Ninzom,ETHNICITY,ETHNICITY
SM263068,-55.7586,-21.1815,Nioaque,ADM2,DISTRICT
SM303300,-104.4754,43.0565,Niobrara,ADM2,DISTRICT
SM283375,-6.0268,14.8666,Niono,ADM2,DISTRICT
SM283351,-10.3,14.7,Nioro,ADM2,DISTRICT
SM295278,-15.8393,13.7351,Nioro du Rip,ADM2,DISTRICT
SM288602,143.3333,-6.3333,Nipa-Kutubu,ADM2,DISTRICT
SM283956,37.8623,-14.0214,Nipepe,ADM2,DISTRICT
SM256318,-88,50,Nipigon,ETHNICITY,ETHNICITY
SM256318,-88,50,Nipigon,ETHNICITY,ETHNICITY
SM256318,-88.31,49.34,Nipigon,ETHNICITY,ETHNICITY
SM256318,-88.31,49.34,Nipigon,ETHNICITY,ETHNICITY
SM268869,-78.9996,46.0001,Nipissing,ADM2,DISTRICT
SM256319,-71.63,42.27,Nipmuc,ETHNICITY,ETHNICITY
SM266730,-49.7813,-20.8937,Nipoã,ADM2,DISTRICT
SM2451,-73.4285,18.4484,Nippes,ADM1,DISTRICT
SM239885,139.753,-4.1442,Nipsan,LANGUAGE,LANGUAGE
SM295470,166.3307,-10.2686,Nipua-Nopoli,ADM2,DISTRICT
SM268105,-48.392,-14.5037,Niquelândia,ADM2,DISTRICT
SM271102,-77.5708,19.9391,Niquero,ADM2,DISTRICT
SM285448,-86.1004,11.8823,Niquinohomo,ADM2,DISTRICT
SM256320,-84.64,10.99,Niquiran,ETHNICITY,ETHNICITY
SM279606,138.412,35.7177,Nirasaki,ADM2,DISTRICT
SM303794,-68.6667,10.0833,Nirgua,ADM2,DISTRICT
SM260232,68.7396,34.3565,Nirkh,ADM2,DISTRICT
SM11675,78.341,19.0991,Nirmal,ADM2,DISTRICT
SM13713,27.9206,56.3898,Nirzas pagasts,ADM2,DISTRICT
SM289288,-7.675,39.5183,Nisa,ADM2,DISTRICT
SM239888,137.6,-1.9539,Nisa-Anasi,LANGUAGE,LANGUAGE
SM304967,46.4477,14.5257,Nisab,ADM2,DISTRICT
SM239889,-120.393,38.047,Nisenan,LANGUAGE,LANGUAGE
SM256323,-121.42,38.99,Nisenan,ETHNICITY,ETHNICITY
SM256323,-121.42,38.99,Nisenan,ETHNICITY,ETHNICITY
SM256323,-121.42,38.99,Nisenan,ETHNICITY,ETHNICITY
SM256323,-121,39,Nisenan,ETHNICITY,ETHNICITY
SM256323,-121,39,Nisenan,ETHNICITY,ETHNICITY
SM256323,-121,39,Nisenan,ETHNICITY,ETHNICITY
SM256323,-121.15,39.03,Nisenan,ETHNICITY,ETHNICITY
SM256323,-121.15,39.03,Nisenan,ETHNICITY,ETHNICITY
SM256323,-121.15,39.03,Nisenan,ETHNICITY,ETHNICITY
SM256324,-121.1,39.4,Nisenan (Foothill),ETHNICITY,ETHNICITY
SM256325,-120.9,39.3,Nisenan (Mountain),ETHNICITY,ETHNICITY
SM256326,-121,38.8,Nisenan (Southern),ETHNICITY,ETHNICITY
SM239890,-129.192,55.199,Nisga'a,LANGUAGE,LANGUAGE
SM256327,89.29,26.08,Nishi,ETHNICITY,ETHNICITY
SM279028,134.341,35.19,Nishiawakura,ADM2,DISTRICT
SM278976,140.2337,40.5128,Nishimeya,ADM2,DISTRICT
SM13041,140.0612,38.3784,Nishimurayama-gun,ADM2,DISTRICT
SM13042,135.5111,33.622,Nishimuro-gun,ADM2,DISTRICT
SM278343,135.3025,34.7819,Nishinomiya,ADM2,DISTRICT
SM278501,131.0152,30.7054,Nishinoomote,ADM2,DISTRICT
SM13043,137.0543,34.8622,Nishio-shi (2011-),ADM2,DISTRICT
SM13044,139.8455,38.033,Nishiokitama-gun,ADM2,DISTRICT
SM13045,140.1779,37.1713,Nishishirakawa-gun,ADM2,DISTRICT
SM13046,129.8602,32.8344,Nishisonogi-gun,ADM2,DISTRICT
SM13047,139.0951,35.7883,Nishitama-gun,ADM2,DISTRICT
SM13048,140.087,40.6293,Nishitsugaru-gun,ADM2,DISTRICT
SM279460,139.5462,35.7349,Nishitōkyō,ADM2,DISTRICT
SM13049,131.3263,32.698,Nishiusuki-gun,ADM2,DISTRICT
SM278445,140.7908,39.3908,Nishiwaga,ADM2,DISTRICT
SM278344,134.9965,35.011,Nishiwaki,ADM2,DISTRICT
SM303524,65.5833,38.5833,Nishon,ADM2,DISTRICT
SM239893,104.85,23.418,Nisi (China),LANGUAGE,LANGUAGE
SM256328,-130.08,54.51,Niska,ETHNICITY,ETHNICITY
SM289010,22.2765,50.4641,Nisko,ADM2,DISTRICT
SM10940,33.3872,35.0346,Nisou,ADM2,DISTRICT
SM2453,28.1667,47.0833,Nisporeni,ADM1,DISTRICT
SM256329,-122.77,46.88,Nisqually,ETHNICITY,ETHNICITY
SM286221,8.5213,59.0196,Nissedal,ADM2,DISTRICT
SM15171,4.2754,51.8372,Nissewaard,ADM2,DISTRICT
SM278052,137.0393,35.1311,Nisshin,ADM2,DISTRICT
SM292361,26.6773,45.8158,Nistoresti,ADM2,DISTRICT
SM239899,167.7856,-16.4131,Nisvai,LANGUAGE,LANGUAGE
SM13714,25.2229,57.0819,Nitaures pagasts,ADM2,DISTRICT
SM292003,21.5227,45.5738,Nitchidorf,ADM2,DISTRICT
SM265287,-43.0839,-22.9172,Niterói,ADM2,DISTRICT
SM297819,124.2333,-9.348,Nitibe,ADM2,DISTRICT
SM239901,167.46,-16.2,Nitita,LANGUAGE,LANGUAGE
SM296147,18.0833,48.3167,Nitra,ADM2,DISTRICT
SM2454,18.3333,48.1667,Nitriansky,ADM1,DISTRICT
SM286040,10.8817,60.0428,Nittedal,ADM2,DISTRICT
SM239904,-175.64,-15.6014,Niuafo'ou,LANGUAGE,LANGUAGE
SM17998,-175.6081,-15.6097,Niuafo'ou,ADM2,DISTRICT
SM2455,-173.7896,-15.9544,Niuas,ADM1,DISTRICT
SM239905,-173.7783,-15.9577,Niuatoputapu,LANGUAGE,LANGUAGE
SM17999,-173.7783,-15.9567,Niuatoputapu,ADM2,DISTRICT
SM239906,-169.86,-19.06,Niuean,LANGUAGE,LANGUAGE
SM256330,-169.86,-19.05,Niuean,ETHNICITY,ETHNICITY
SM18118,179.466,-10.788,Niulakita,ADM1:ADM2,DISTRICT
SM2457,177.3422,-6.1072,Niutao,ADM1,DISTRICT
SM256331,-61.2,-22.8,Nivacle,ETHNICITY,ETHNICITY
SM239907,-61.7606,-22.8557,Nivaclé,LANGUAGE,LANGUAGE
SM346785,25.5184,63.766,Nivala-Haapajärvi,ADM2:ADM3,DISTRICT
SM256332,142.54,53.32,Nivkhi,ETHNICITY,ETHNICITY
SM256332,142.54,53.32,Nivkhi,ETHNICITY,ETHNICITY
SM256332,142.54,53.32,Nivkhi,ETHNICITY,ETHNICITY
SM256332,142.54,53.32,Nivkhi,ETHNICITY,ETHNICITY
SM256332,142,53,Nivkhi,ETHNICITY,ETHNICITY
SM256332,142,53,Nivkhi,ETHNICITY,ETHNICITY
SM256332,142,53,Nivkhi,ETHNICITY,ETHNICITY
SM256332,142,53,Nivkhi,ETHNICITY,ETHNICITY
SM256332,140,51.54,Nivkhi,ETHNICITY,ETHNICITY
SM256332,140,51.54,Nivkhi,ETHNICITY,ETHNICITY
SM256332,140,51.54,Nivkhi,ETHNICITY,ETHNICITY
SM256332,140,51.54,Nivkhi,ETHNICITY,ETHNICITY
SM256332,141.25,52.85,Nivkhi,ETHNICITY,ETHNICITY
SM256332,141.25,52.85,Nivkhi,ETHNICITY,ETHNICITY
SM256332,141.25,52.85,Nivkhi,ETHNICITY,ETHNICITY
SM256332,141.25,52.85,Nivkhi,ETHNICITY,ETHNICITY
SM13050,136.9125,35.3461,Niwa-gun,ADM2,DISTRICT
SM239910,153.2,-3.47,Niwer Mil,DIALECT,LANGUAGE
SM245603,12.3779,7.1291,Nizaa,LANGUAGE,LANGUAGE
SM11676,78.25,18.75,Nizamabad (2016-),ADM2,DISTRICT
SM9747,49.9193,40.4097,Nizami Rayonu,ADM2,DISTRICT
SM9748,49.8333,40.3833,Nizaminskiy Rayon,ADM2,DISTRICT
SM271815,-70.2167,18.25,Nizao,ADM2,DISTRICT
SM2458,45,56,Nizhegorod,ADM1,DISTRICT
SM294976,38.4167,51.5,Nizhnedevitskiy rayon,ADM2,DISTRICT
SM292895,103.5,57,Nizhneilimskiy rayon,ADM2,DISTRICT
SM293445,97,56.25,Nizhneingashskiy rayon,ADM2,DISTRICT
SM294626,51.8167,55.6333,Nizhnekamskiy,ADM2,DISTRICT
SM294627,51.5833,55.4167,Nizhnekamskiy rayon,ADM2,DISTRICT
SM293901,74.9167,55.5,Nizhneomskiy rayon,ADM2,DISTRICT
SM17017,98.5,54.3333,Nizhneudinskiy Rayon,ADM2,DISTRICT
SM293774,43.8212,56.3206,Nizhniy Novgorod gorsovet,ADM2,DISTRICT
SM293774,43.8212,56.3206,Nizhniy Novgorod gorsovet,ADM2,DISTRICT
SM293774,43.9463,56.2975,Nizhniy Novgorod gorsovet,ADM2,DISTRICT
SM293774,43.9463,56.2975,Nizhniy Novgorod gorsovet,ADM2,DISTRICT
SM299687,31.8811,51.0468,Nizhyns'ka,ADM2,DISTRICT
SM298423,37.795,37.011,Nizip,ADM2,DISTRICT
SM274473,3.4919,47.117,Nièvre,ADM2,DISTRICT
SM2452,21.8944,43.3231,Nišavski,ADM1:ADM2,DISTRICT
SM284759,7.0082,5.7059,Njaba,ADM2,DISTRICT
SM239915,26.1147,8.7075,Njalgulgule,LANGUAGE,LANGUAGE
SM239917,11.51,6.41,Njanga,LANGUAGE,LANGUAGE
SM458094,131.4921,-30.6822,Njangadjadjara,ETHNICITY,ETHNICITY
SM2459,43.3333,-11.5833,Njazídja,ADM1,DISTRICT
SM239921,12.7063,-2.1812,Njebi,LANGUAGE,LANGUAGE
SM239928,9.9044,5.7517,Njen,LANGUAGE,LANGUAGE
SM239930,11.521,6.8218,Njerep,LANGUAGE,LANGUAGE
SM285293,6.9766,6.1842,Njikoka,ADM2,DISTRICT
SM256335,129.83,-16.5,Njinin,ETHNICITY,ETHNICITY
SM256335,129.83,-16.5,Njinin,ETHNICITY,ETHNICITY
SM256335,126.46,-17.7,Njinin,ETHNICITY,ETHNICITY
SM256335,126.46,-17.7,Njinin,ETHNICITY,ETHNICITY
SM256335,128.5857,-18.0693,Njinin,ETHNICITY,ETHNICITY
SM256335,128.5857,-18.0693,Njinin,ETHNICITY,ETHNICITY
SM299140,35.1325,-9.2091,Njombe,ADM2,DISTRICT
SM2460,34.75,-9.4167,Njombe,ADM1,DISTRICT
SM299139,34.841,-9.479,Njombe Urban,ADM2,DISTRICT
SM239937,14.1175,2.3048,Njyem,LANGUAGE,LANGUAGE
SM269526,10.1667,4.5,Nkam,ADM2,DISTRICT
SM239942,-0.0372,7.0556,Nkami,LANGUAGE,LANGUAGE
SM239943,19.0476,-13.3688,Nkangala,LANGUAGE,LANGUAGE
SM305132,29.4243,-25.7122,Nkangala,ADM2,DISTRICT
SM284723,7.6242,6.3152,Nkanu East,ADM2,DISTRICT
SM284724,7.509,6.311,Nkanu West,ADM2,DISTRICT
SM239944,7.7635,5.439,Nkari,LANGUAGE,LANGUAGE
SM299159,31,-7.5833,Nkasi,ADM2,DISTRICT
SM256336,31.29,-7.45,Nkasi,ETHNICITY,ETHNICITY
SM305270,28.7122,-18.9247,Nkayi,ADM2,DISTRICT
SM269799,13.2842,-4.1812,Nkayi District,ADM2,DISTRICT
SM239945,8.6867,6.5916,Nkem-Nkum,LANGUAGE,LANGUAGE
SM18162,25.1493,-14.8508,Nkeyema District,ADM2,DISTRICT
SM296676,31.1639,-26.1573,Nkhaba,ADM2,DISTRICT
SM2461,34.0532,-11.653,Nkhata Bay,ADM1:ADM2,DISTRICT
SM2462,34.083,-12.8042,Nkhotakota,ADM1:ADM2,DISTRICT
SM239947,13.9704,-15.4126,Nkhumbi,LANGUAGE,LANGUAGE
SM275124,10.3452,1.9317,Nkimi,ADM2,DISTRICT
SM256337,9.71,-1.71,Nkomi,ETHNICITY,ETHNICITY
SM239956,9.801,5.3385,Nkongho,LANGUAGE,LANGUAGE
SM239957,0.3209,7.2032,Nkonya,LANGUAGE,LANGUAGE
SM239954,0.3209,7.2032,Nkonya-Nkami,FAMILY,LANGUAGE
SM4352,-1.6618,7.685,Nkoranza North,ADM2,DISTRICT
SM4351,-1.7,7.5667,Nkoranza South,ADM2,DISTRICT
SM239959,7.458,4.5764,Nkoroo,LANGUAGE,LANGUAGE
SM256339,31.37,-25.5,Nkosi,ETHNICITY,ETHNICITY
SM239963,24.7649,-14.8513,Nkoya,LANGUAGE,LANGUAGE
SM250575,28.17,-16.13,Nkoya,ETHNICITY,ETHNICITY
SM250575,24.53,-14.67,Nkoya,ETHNICITY,ETHNICITY
SM250575,26.57,-14.04,Nkoya,ETHNICITY,ETHNICITY
SM250575,22.97,-15.52,Nkoya,ETHNICITY,ETHNICITY
SM275129,10.4698,2.0124,Nkue,ADM2,DISTRICT
SM239965,8.2805,5.7562,Nkukoli,LANGUAGE,LANGUAGE
SM250577,19.1665,-0.75,Nkundo,ETHNICITY,ETHNICITY
SM250577,19.1665,-0.75,Nkundo,ETHNICITY,ETHNICITY
SM250577,19.1665,-0.75,Nkundo,ETHNICITY,ETHNICITY
SM250577,20,0,Nkundo,ETHNICITY,ETHNICITY
SM250577,20,0,Nkundo,ETHNICITY,ETHNICITY
SM250577,20,0,Nkundo,ETHNICITY,ETHNICITY
SM250577,19.17,-0.75,Nkundo,ETHNICITY,ETHNICITY
SM250577,19.17,-0.75,Nkundo,ETHNICITY,ETHNICITY
SM250577,19.17,-0.75,Nkundo,ETHNICITY,ETHNICITY
SM239971,22.6802,-3.6743,Nkutu,LANGUAGE,LANGUAGE
SM250578,20.55,-4.02,Nkutu,ETHNICITY,ETHNICITY
SM4381,0.3063,8.6092,Nkwanta North,ADM2,DISTRICT
SM4382,0.5163,8.2538,Nkwanta South,ADM2,DISTRICT
SM296728,31.2497,-26.8978,Nkwene,ADM2,DISTRICT
SM284760,7.106,5.7532,Nkwerre,ADM2,DISTRICT
SM258061,-122,50,Nlakapamux,ETHNICITY,ETHNICITY
SM258061,-122,50,Nlakapamux,ETHNICITY,ETHNICITY
SM258061,-122,50,Nlakapamux,ETHNICITY,ETHNICITY
SM258061,-121.75,51.26,Nlakapamux,ETHNICITY,ETHNICITY
SM258061,-121.75,51.26,Nlakapamux,ETHNICITY,ETHNICITY
SM258061,-121.75,51.26,Nlakapamux,ETHNICITY,ETHNICITY
SM258061,-121.5,50.3,Nlakapamux,ETHNICITY,ETHNICITY
SM258061,-121.5,50.3,Nlakapamux,ETHNICITY,ETHNICITY
SM258061,-121.5,50.3,Nlakapamux,ETHNICITY,ETHNICITY
SM239978,8.4898,6.3132,Nnam,LANGUAGE,LANGUAGE
SM285294,6.9018,5.9999,NnewiNort,ADM2,DISTRICT
SM285295,6.9231,5.9332,NnewiSou,ADM2,DISTRICT
SM256341,-116.3,68.39,Noahonirmiut,ETHNICITY,ETHNICITY
SM262231,91.1667,22.75,Noakhali,ADM2,DISTRICT
SM15172,6.021,53.3321,Noardeast-Fryslan,ADM2,DISTRICT
SM256343,21.51,15.72,Noarma,ETHNICITY,ETHNICITY
SM256344,-160,68.09,Noatak,ETHNICITY,ETHNICITY
SM278771,131.6431,32.6724,Nobeoka,ADM2,DISTRICT
SM239982,31.8376,22.8142,Nobiin,LANGUAGE,LANGUAGE
SM302219,-97.2305,36.3886,Noble,ADM2,DISTRICT
SM302141,-81.4556,39.766,Noble,ADM2,DISTRICT
SM300744,-85.4175,41.3986,Noble,ADM2,DISTRICT
SM323829,-113.0541,49.8847,Nobleford,ADM2:ADM3,DISTRICT
SM301387,-95.7534,43.6742,Nobles,ADM2,DISTRICT
SM273439,-80.0529,-1.9688,Nobol,ADM2,DISTRICT
SM239983,145.712,-5.0695,Nobonob,LANGUAGE,LANGUAGE
SM278325,141.0758,42.4656,Noboribetsu,ADM2,DISTRICT
SM263185,-55.775,-14.3858,Nobres,ADM2,DISTRICT
SM270793,-72.956,5.7473,Nobsa,ADM2,DISTRICT
SM270031,-74.4047,5.0477,Nocaima,ADM2,DISTRICT
SM239985,-73.1667,-8.9167,Nocamán,LANGUAGE,LANGUAGE
SM283065,-102.8502,21.4418,Nochistlán de Mejía,ADM2,DISTRICT
SM291737,24.4331,45.8708,Nocrich,ADM2,DISTRICT
SM239987,95.3903,27.152,Nocte Naga,LANGUAGE,LANGUAGE
SM282056,-101.2125,19.113,Nocupétaro,ADM2,DISTRICT
SM279373,139.8622,35.9852,Noda,ADM2,DISTRICT
SM301594,-94.8834,40.3608,Nodaway,ADM2,DISTRICT
SM297373,100.71,16.564,Noen Maprang,ADM2,DISTRICT
SM297769,101.9941,15.5517,Noen Sa-Nga,ADM2,DISTRICT
SM239990,46.6618,43.5917,Nogai,LANGUAGE,LANGUAGE
SM256346,45.1,44.49,Nogai,ETHNICITY,ETHNICITY
SM256346,45.1,44.49,Nogai,ETHNICITY,ETHNICITY
SM256346,45.1,44.49,Nogai,ETHNICITY,ETHNICITY
SM256346,45.1,44.49,Nogai,ETHNICITY,ETHNICITY
SM256346,41.9376,44.3725,Nogai,ETHNICITY,ETHNICITY
SM256346,41.9376,44.3725,Nogai,ETHNICITY,ETHNICITY
SM256346,41.9376,44.3725,Nogai,ETHNICITY,ETHNICITY
SM256346,41.9376,44.3725,Nogai,ETHNICITY,ETHNICITY
SM256346,48.01,46.2,Nogai,ETHNICITY,ETHNICITY
SM256346,48.01,46.2,Nogai,ETHNICITY,ETHNICITY
SM256346,48.01,46.2,Nogai,ETHNICITY,ETHNICITY
SM256346,48.01,46.2,Nogai,ETHNICITY,ETHNICITY
SM256346,41.5,44.4,Nogai,ETHNICITY,ETHNICITY
SM256346,41.5,44.4,Nogai,ETHNICITY,ETHNICITY
SM256346,41.5,44.4,Nogai,ETHNICITY,ETHNICITY
SM256346,41.5,44.4,Nogai,ETHNICITY,ETHNICITY
SM457939,41.95,44.3667,Nogaisky Rayon,ADM2,DISTRICT
SM282748,-97.1982,18.8234,Nogales,ADM2,DISTRICT
SM282587,-110.9744,31.2039,Nogales,ADM2,DISTRICT
SM17018,46,44.5,Nogayskiy Rayon (1988-),ADM2,DISTRICT
SM17019,38.4167,55.9167,Noginskiy Rayon,ADM2,DISTRICT
SM274382,42.3931,7.7679,Nogob,ADM2,DISTRICT
SM283696,90.2424,49.6089,Nogoonnuur,ADM2,DISTRICT
SM261176,-59.8333,-32.1667,Nogoyá,ADM2,DISTRICT
SM17936,68.7888,39.2954,Nohiyai Ayni (excl. Ayni city),ADM2,DISTRICT
SM17937,69.744,40.4272,Nohiyai Bobojon Ghafurov (excl. Khudjen city),ADM2,DISTRICT
SM17938,70.7817,38.5085,Nohiyai Darvoz (excl. Darvoz),ADM2,DISTRICT
SM17939,68.5416,38.5299,Nohiyai Hisor (excl. Gissar City),ADM2,DISTRICT
SM17940,70.1059,38.4085,Nohiyai Khovaling  (excl. Khovaling),ADM2,DISTRICT
SM17942,70.0256,38.141,Nohiyai Mu'minobod (excl. Muminobod City),ADM2,DISTRICT
SM17943,67.7916,39.3745,Nohiyai Panjakent (excl. Pendzhikent city),ADM2,DISTRICT
SM17944,68.5854,38.351,Nohiyai Rudaki (excl. Lenin City),ADM2,DISTRICT
SM17945,72.0547,38.1466,Nohiyai Rushon (excl. Rushon city),ADM2,DISTRICT
SM17946,69.5678,38.176,Nohiyai Temurmalik (excl. Sovet city),ADM2,DISTRICT
SM17947,71.7627,38.4184,Nohiyai Vanj (excl. Vanj city),ADM2,DISTRICT
SM239996,73.5486,22.8781,Noiri,LANGUAGE,LANGUAGE
SM292444,21.8775,46.9714,Nojorid,ADM2,DISTRICT
SM459157,115.168,-27.2434,Nokaan,ETHNICITY,ETHNICITY
SM256348,152.84,-3.78,Nokon,ETHNICITY,ETHNICITY
SM240001,166.603,-14.8974,Nokuku,LANGUAGE,LANGUAGE
SM10248,16.028,3.5648,Nola,ADM2,DISTRICT
SM302725,-100.406,32.3035,Nolan,ADM2,DISTRICT
SM256349,41.19,8.12,Nole,ETHNICITY,ETHNICITY
SM286650,-81.8153,8.3268,Nole Duima,ADM2,DISTRICT
SM293274,49.9167,57.5833,Nolinskiy rayon,ADM2,DISTRICT
SM240003,10.9157,4.5893,Nomaande,LANGUAGE,LANGUAGE
SM240005,145.098,-6.3022,Nomane,LANGUAGE,LANGUAGE
SM11458,23.8886,38.0335,Nomarchia Anatolikis Attikis,ADM2,DISTRICT
SM11459,23.75,37.9667,Nomarchia Athinas,ADM2,DISTRICT
SM240006,-74.4371,-11.5349,Nomatsiguenga,LANGUAGE,LANGUAGE
SM281504,-104.1666,23.8314,Nombre de Dios,ADM2,DISTRICT
SM295613,-88.7224,14.0232,Nombre de Jesús,ADM2,DISTRICT
SM301179,-163.7506,64.8333,Nome,ADM2,DISTRICT
SM286222,9.2568,59.2814,Nome,ADM2,DISTRICT
SM283622,105.0564,42.4146,Nomgon,ADM2,DISTRICT
SM278415,136.5446,36.4359,Nomi,ADM2,DISTRICT
SM278415,136.5446,36.4359,Nomi,ADM2,DISTRICT
SM278415,136.5366,36.4692,Nomi,ADM2,DISTRICT
SM278415,136.5366,36.4692,Nomi,ADM2,DISTRICT
SM256352,-121.66,39.48,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-121.66,39.48,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-121.66,39.48,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-121.66,39.48,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-122,39,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-122,39,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-122,39,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-122,39,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-122.56,40,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-122.56,40,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-122.56,40,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-122.56,40,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-122.5,39.5,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-122.5,39.5,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-122.5,39.5,Nomlaki,ETHNICITY,ETHNICITY
SM256352,-122.5,39.5,Nomlaki,ETHNICITY,ETHNICITY
SM370530,6.1747,49.7869,Nommern,ADM1:ADM2:ADM3,DISTRICT
SM11460,22,38,Nomos Achaias,ADM2,DISTRICT
SM11461,21.5,38.5,Nomos Aitolias kai Akarnanias,ADM2,DISTRICT
SM11462,22.8333,37.6667,Nomos Argolidos,ADM2,DISTRICT
SM11463,22.25,37.5833,Nomos Arkadias,ADM2,DISTRICT
SM11464,21,39.1667,Nomos Artas,ADM2,DISTRICT
SM11465,23.5,38.0833,Nomos Attikis,ADM2,DISTRICT
SM11466,23.5,40.4167,Nomos Chalkidikis,ADM2,DISTRICT
SM11467,24,35.3333,Nomos Chanias,ADM2,DISTRICT
SM11468,24.25,41.25,Nomos Dramas,ADM2,DISTRICT
SM11469,26,41,Nomos Evrou,ADM2,DISTRICT
SM11470,21.6667,39,Nomos Evrytanias,ADM2,DISTRICT
SM11471,24,38.5,Nomos Evvoias,ADM2,DISTRICT
SM11472,21.4167,40.75,Nomos Florinis,ADM2,DISTRICT
SM11473,22.25,38.5,Nomos Fokidos,ADM2,DISTRICT
SM11474,22.4167,38.8333,Nomos Fthiotidos,ADM2,DISTRICT
SM11475,21.4167,40.0833,Nomos Grevenon,ADM2,DISTRICT
SM11476,21.5833,37.75,Nomos Ileias,ADM2,DISTRICT
SM11477,22.25,40.5,Nomos Imathias,ADM2,DISTRICT
SM11478,20.6667,39.75,Nomos Ioanninon,ADM2,DISTRICT
SM11479,21.75,39.3333,Nomos Kardhitsas,ADM2,DISTRICT
SM11480,21.1667,40.5,Nomos Kastorias,ADM2,DISTRICT
SM11481,24.4096,40.9521,Nomos Kavalas,ADM2,DISTRICT
SM11482,19.75,39.6667,Nomos Kerkyras,ADM2,DISTRICT
SM11483,22.6667,41,Nomos Kilkis,ADM2,DISTRICT
SM11484,22.6667,37.9167,Nomos Korinthias,ADM2,DISTRICT
SM11485,21.7167,40.3333,Nomos Kozanis,ADM2,DISTRICT
SM11486,24.9167,37.4167,Nomos Kykladon,ADM2,DISTRICT
SM11487,22.5833,37,Nomos Lakonias,ADM2,DISTRICT
SM11488,22.5,39.5,Nomos Larisis,ADM2,DISTRICT
SM11489,25.8333,35.0833,Nomos Lasithiou,ADM2,DISTRICT
SM11490,22.75,39.25,Nomos Magnisias,ADM2,DISTRICT
SM11491,21.8333,37.25,Nomos Messinias,ADM2,DISTRICT
SM11492,22.25,40.8333,Nomos Pellis,ADM2,DISTRICT
SM11493,22.4167,40.25,Nomos Pierias,ADM2,DISTRICT
SM11494,23.4167,37.5,Nomos Piraios,ADM2,DISTRICT
SM11495,20.6667,39.1667,Nomos Prevezis,ADM2,DISTRICT
SM11496,24.5833,35.25,Nomos Rethymnis,ADM2,DISTRICT
SM11497,25.5,41.0833,Nomos Rodopis,ADM2,DISTRICT
SM11498,26.75,37.75,Nomos Samou,ADM2,DISTRICT
SM11499,23.5,41.1667,Nomos Serron,ADM2,DISTRICT
SM11500,20.3333,39.5,Nomos Thesprotias,ADM2,DISTRICT
SM11501,23,40.6667,Nomos Thessalonikis,ADM2,DISTRICT
SM11502,23,38.3333,Nomos Voiotias,ADM2,DISTRICT
SM11503,24.8333,41.1667,Nomos Xanthis,ADM2,DISTRICT
SM11504,20.75,37.75,Nomos Zakynthou,ADM2,DISTRICT
SM240009,147.5,-6.1043,Nomu,LANGUAGE,LANGUAGE
SM14954,151.745,8.43,Nomwin Municipality,ADM2,DISTRICT
SM297125,102.5457,15.4448,Non Daeng,ADM2,DISTRICT
SM297416,102.6823,14.2201,Non Din Daeng,ADM2,DISTRICT
SM297558,104.6956,14.9019,Non Khun,ADM2,DISTRICT
SM297715,102.8999,16.95,Non Sa-at,ADM2,DISTRICT
SM297263,102.5308,16.9022,Non Sang,ADM2,DISTRICT
SM297126,102.284,15.2089,Non Sung,ADM2,DISTRICT
SM297417,102.5834,14.5551,Non Suwan,ADM2,DISTRICT
SM297127,102.03,15.1811,Non Thai,ADM2,DISTRICT
SM11677,93.3856,24.6569,Noney,ADM2,DISTRICT
SM280786,106.5042,16.3846,Nong,ADM2,DISTRICT
SM297195,100.6286,15.8659,Nong Bua,ADM2,DISTRICT
SM297770,101.6263,16.1968,Nong Bua Daeng,ADM2,DISTRICT
SM2465,102.25,17.25,Nong Bua Lam Phu (1993-),ADM1,DISTRICT
SM297771,101.6305,15.8283,Nong Bua Rawae,ADM2,DISTRICT
SM297128,102.3745,14.713,Nong Bunnak,ADM2,DISTRICT
SM297737,99.7727,15.3676,Nong Chang,ADM2,DISTRICT
SM297297,101.1706,6.8001,Nong Chik,ADM2,DISTRICT
SM297164,100.8579,13.8528,Nong Chok,ADM2,DISTRICT
SM297544,100.6853,14.6888,Nong Don,ADM2,DISTRICT
SM297716,103.1151,17.3685,Nong Han,ADM2,DISTRICT
SM297418,102.6708,14.8615,Nong Hong,ADM2,DISTRICT
SM297545,100.8645,14.3813,Nong Khae,ADM2,DISTRICT
SM2466,102.75,17.75,Nong Khai (2011-),ADM1,DISTRICT
SM297738,99.9471,15.3489,Nong Khayang,ADM2,DISTRICT
SM297419,102.558,14.727,Nong Ki,ADM2,DISTRICT
SM296937,103.3192,16.7193,Nong Kung Si,ADM2,DISTRICT
SM297059,100.7041,15.2774,Nong Muang,ADM2,DISTRICT
SM297395,100.1523,18.2885,Nong Muang Kai,ADM2,DISTRICT
SM297346,101.1504,16.0116,Nong Phai,ADM2,DISTRICT
SM297466,104.2214,16.3036,Nong Phok,ADM2,DISTRICT
SM296971,99.4107,14.678,Nong Prue,ADM2,DISTRICT
SM296981,102.4457,16.4917,Nong Rua,ADM2,DISTRICT
SM297717,102.7859,17.1417,Nong Saeng,ADM2,DISTRICT
SM297546,100.8046,14.4828,Nong Saeng,ADM2,DISTRICT
SM296982,102.7917,15.7642,Nong Song Hong,ADM2,DISTRICT
SM297285,100.8393,14.1603,Nong Sua,ADM2,DISTRICT
SM297087,104.3365,16.4476,Nong Sung,ADM2,DISTRICT
SM297718,102.5899,17.1869,Nong Wua So,ADM2,DISTRICT
SM297354,99.4688,13.1259,Nong Ya Plong,ADM2,DISTRICT
SM297614,99.8575,14.7764,Nong Ya Sai,ADM2,DISTRICT
SM296922,101.3747,13.1177,Nong Yai,ADM2,DISTRICT
SM240015,104.75,23.6,Nong Zhuang,LANGUAGE,LANGUAGE
SM256354,144.1,-30.48,Nongait,ETHNICITY,ETHNICITY
SM256354,144.1,-30.48,Nongait,ETHNICITY,ETHNICITY
SM256354,142.6847,-30.4904,Nongait,ETHNICITY,ETHNICITY
SM256354,142.6847,-30.4904,Nongait,ETHNICITY,ETHNICITY
SM256356,-123.1,40.55,Nongatl,ETHNICITY,ETHNICITY
SM256356,-123.1,40.55,Nongatl,ETHNICITY,ETHNICITY
SM256356,-123.6,40.4,Nongatl,ETHNICITY,ETHNICITY
SM256356,-123.6,40.4,Nongatl,ETHNICITY,ETHNICITY
SM280871,104.8239,17.0613,Nongbok,ADM2,DISTRICT
SM280832,103.9236,19.5605,Nonghed,ADM2,DISTRICT
SM297165,100.3536,13.6998,Nongkheam,ADM2,DISTRICT
SM295317,157.9326,-8.6857,Nono,ADM2,DISTRICT
SM265778,-52.8759,-27.369,Nonoai,ADM2,DISTRICT
SM283254,-106.7329,27.4385,Nonoava,ADM2,DISTRICT
SM278416,136.6059,36.5229,Nonoichi,ADM2,DISTRICT
SM256357,-4,14,Nononke,ETHNICITY,ETHNICITY
SM13337,174.35,-0.6667,Nonouti,ADM2,DISTRICT
SM280655,127.1214,36.1977,Nonsan,ADM2,DISTRICT
SM12587,-76.4047,18.1382,Nonsuch,ADM2,DISTRICT
SM2467,100.4034,13.9043,Nonthaburi,ADM1,DISTRICT
SM240024,-72.5,-1.25,Nonuya,LANGUAGE,LANGUAGE
SM280337,72.68,40.07,Nookat,ADM2,DISTRICT
SM280321,72.6321,41.062,Nooken,ADM2,DISTRICT
SM240026,-121.973,48.2201,Nooksack,LANGUAGE,LANGUAGE
SM240029,-16.9205,14.792,Noon,LANGUAGE,LANGUAGE
SM240030,10.5707,6.3726,Noone,LANGUAGE,LANGUAGE
SM4264,73.3887,5.9649,Noonu Atoll,ADM1:ADM2,DISTRICT
SM296086,-55.238,5.6266,Noord,ADM2,DISTRICT
SM285626,3.7835,51.5723,Noord-Beveland,ADM2,DISTRICT
SM2468,5,51.6667,Noord-Brabant,ADM1,DISTRICT
SM2469,4.9167,52.5833,Noord-Holland,ADM1,DISTRICT
SM285547,6.4462,53.1149,Noordenveld,ADM2,DISTRICT
SM285724,5.7725,52.717,Noordoostpolder,ADM2,DISTRICT
SM285679,4.4635,52.2695,Noordwijk,ADM2,DISTRICT
SM9733,152.9308,-26.3182,Noosa,ADM2,DISTRICT
SM240034,-126.086,49.6212,Nootka,LANGUAGE,LANGUAGE
SM256359,-121.7,48.77,Nootsak,ETHNICITY,ETHNICITY
SM240035,-97.1339,16.0954,Nopala Chatino,LANGUAGE,LANGUAGE
SM281693,-99.6815,20.2312,Nopala de Villagrán,ADM2,DISTRICT
SM281946,-98.7089,19.7775,Nopaltepec,ADM2,DISTRICT
SM282239,-97.8239,19.2011,Nopalucan,ADM2,DISTRICT
SM256360,-85.98,46.42,Noquet,ETHNICITY,ETHNICITY
SM260391,13.5813,-6.1429,Noqui,ADM2,DISTRICT
SM262749,-65.6718,-20.6036,Nor Chichas,ADM2,DISTRICT
SM262692,-65.0056,-20.3483,Nor Cinti,ADM2,DISTRICT
SM296405,14.9078,59.5346,Nora,ADM2,DISTRICT
SM256361,-51.47,3.14,Norague,ETHNICITY,ETHNICITY
SM305953,69.3249,38.375,Norak,ADM2,DISTRICT
SM296564,15.9597,60.09,Norberg,ADM2,DISTRICT
SM12588,-76.785,18.0548,Norbrook,ADM2,DISTRICT
SM10431,-74.8551,5.6373,Norcasia,ADM2,DISTRICT
SM274500,3.362,50.3333,Nord,ADM2,DISTRICT
SM2472,-72.3,19.6,Nord,ADM1,DISTRICT
SM2471,14,8.5,Nord,ADM1,DISTRICT
SM2470,-2.4212,13.5881,Nord,ADM1,DISTRICT
SM2473,164.9,-21.05,Nord,ADM1,DISTRICT
SM286103,9.2366,60.9853,Nord-Aurdal,ADM2,DISTRICT
SM2474,-71.8571,19.489,Nord-Est,ADM1,DISTRICT
SM286104,9.7483,61.5956,Nord-Fron,ADM2,DISTRICT
SM10330,15.2596,15.4013,Nord-Kanem,ADM2,DISTRICT
SM2475,29.034,-0.6552,Nord-Kivu,ADM1:ADM2,DISTRICT
SM2475,29.034,-0.6552,Nord-Kivu,ADM1:ADM2,DISTRICT
SM2475,28.75,-0.6667,Nord-Kivu,ADM1:ADM2,DISTRICT
SM2475,28.75,-0.6667,Nord-Kivu,ADM1:ADM2,DISTRICT
SM286358,11.5415,60.392,Nord-Odal,ADM2,DISTRICT
SM2477,-73,19.8333,Nord-Ouest,ADM1,DISTRICT
SM2476,10.3667,6.3667,Nord-Ouest,ADM1,DISTRICT
SM2479,21.0167,4.2833,Nord-Ubangi,ADM1:ADM2,DISTRICT
SM2479,21.0167,4.2833,Nord-Ubangi,ADM1:ADM2,DISTRICT
SM2479,21.0167,4.2833,Nord-Ubangi,ADM1:ADM2,DISTRICT
SM2479,21.0523,3.8859,Nord-Ubangi,ADM1:ADM2,DISTRICT
SM2479,21.0523,3.8859,Nord-Ubangi,ADM1:ADM2,DISTRICT
SM2479,21.0523,3.8859,Nord-Ubangi,ADM1:ADM2,DISTRICT
SM2479,21.2667,3.65,Nord-Ubangi,ADM1:ADM2,DISTRICT
SM2479,21.2667,3.65,Nord-Ubangi,ADM1:ADM2,DISTRICT
SM2479,21.2667,3.65,Nord-Ubangi,ADM1:ADM2,DISTRICT
SM268707,-74.8961,51.962,Nord-du-Québec,ADM2,DISTRICT
SM296624,16.9138,62.0526,Nordanstig,ADM2,DISTRICT
SM271707,10.7666,56.4412,Norddjurs municipality,ADM2,DISTRICT
SM2480,-6.5833,62.2833,Norderøerne,ADM1,DISTRICT
SM289394,-25.2286,37.8272,Nordeste,ADM2,DISTRICT
SM267558,-39.4514,-10.8969,Nordestina,ADM2,DISTRICT
SM271741,10.2222,55.5222,Nordfyns,ADM2,DISTRICT
SM2481,9.6667,57,Nordjylland,ADM1,DISTRICT
SM286341,25.9747,70.9781,Nordkapp,ADM2,DISTRICT
SM3925,14.7625,66.914,Nordland Fylke (2020-),ADM1,DISTRICT
SM296530,19.3678,63.6854,Nordmaling,ADM2,DISTRICT
SM15304,10.8797,59.7413,Nordre Follo,ADM2,DISTRICT
SM286105,10.0754,60.8347,Nordre Land,ADM2,DISTRICT
SM286231,21.0257,69.7683,Nordreisa,ADM2,DISTRICT
SM2483,6.7634,51.219,Nordrhein-Westfalen,ADM1,DISTRICT
SM11619,-16.4354,66.0243,Nordurthing (2006-),ADM2,DISTRICT
SM286322,8.9464,60.2671,Nore og Uvdal,ADM2,DISTRICT
SM303055,-76.2619,36.8913,Norfolk,ADM2,DISTRICT
SM301239,-71.1838,42.171,Norfolk,ADM2,DISTRICT
SM274657,1,52.6667,Norfolk,ADM1:ADM2,DISTRICT
SM323965,-114.1189,52.3212,Norglenwold,ADM2:ADM3,DISTRICT
SM283066,-101.8949,22.3845,Noria de Angeles,ADM2,DISTRICT
SM240049,15.8694,46.4194,Noric,LANGUAGE,LANGUAGE
SM303543,72,40.9167,Norin,ADM2,DISTRICT
SM301388,-96.4553,47.3265,Norman,ADM2,DISTRICT
SM12589,-76.7594,17.978,Norman Gardens,ADM2,DISTRICT
SM240053,-0.6591,49.081,Normand,DIALECT,LANGUAGE
SM266125,-60.0424,3.8785,Normandia,ADM2,DISTRICT
SM2485,0.4999,49.1991,Normandie,ADM1,DISTRICT
SM295318,157.1998,-8.2227,Noro,ADM2,DISTRICT
SM10432,-74.1156,8.5064,Norosi,ADM2,DISTRICT
SM283568,111.9946,48.6848,Norovlin,ADM2,DISTRICT
SM240055,98.4502,27.5088,Norra,LANGUAGE,LANGUAGE
SM2486,20,67,Norrbotten,ADM1,DISTRICT
SM324013,-114.0201,52.9681,Norris Beach,ADM2:ADM3,DISTRICT
SM296426,16.3209,58.6114,Norrköping,ADM2,DISTRICT
SM296495,18.7057,59.8612,Norrtälje,ADM2,DISTRICT
SM458108,121.7626,-32.2415,Norseman,ETHNICITY,ETHNICITY
SM296531,19.5556,64.9299,Norsjö,ADM2,DISTRICT
SM2487,-73,8,Norte de Santander,ADM1,DISTRICT
SM263186,-56.7415,-14.4279,Nortelândia,ADM2,DISTRICT
SM2488,114.1381,22.4947,North,ADM1,DISTRICT
SM2489,35.9167,34.3333,North,ADM1,DISTRICT
SM277362,88.7108,22.71,North 24 Parganas,ADM2,DISTRICT
SM2495,-77.4369,26.7894,North Abaco (pre-2008),ADM1,DISTRICT
SM275145,26.8196,37.7194,North Aegean,ADM1:ADM2,DISTRICT
SM240360,-143.77,69.582,North Alaskan Inupiatun,LANGUAGE,LANGUAGE
SM256362,-83.1,51.22,North Albany Ojibwa,ETHNICITY,ETHNICITY
SM240270,168.14,-16.1741,North Ambrym,LANGUAGE,LANGUAGE
SM454387,-73.6,45.5,North American Armenians,ETHNICITY,ETHNICITY
SM454388,-106.667,44.35,North American Basques,ETHNICITY,ETHNICITY
SM454389,-75.167,40,North American Hmong,ETHNICITY,ETHNICITY
SM454390,-75.75,45,North American Rom,ETHNICITY,ETHNICITY
SM2496,-78.0961,24.7669,North Andros,ADM1,DISTRICT
SM240336,140.068,-5.9365,North Awyu,LANGUAGE,LANGUAGE
SM274726,-4.7337,55.6969,North Ayrshire,ADM1:ADM2,DISTRICT
SM240150,46.4737,40.9777,North Azerbaijani,LANGUAGE,LANGUAGE
SM240287,129.743,-7.8431,North Babar,LANGUAGE,LANGUAGE
SM2497,27,8.85,North Bahr-al-Ghazal,ADM1,DISTRICT
SM2498,-16,13.5,North Bank,ADM1,DISTRICT
SM240096,11.86,49.49,North Bavarian,DIALECT,LANGUAGE
SM240390,-68.7894,-14.4014,North Bolivian Quechua,LANGUAGE,LANGUAGE
SM288564,154.6728,-5.4219,North Bougainville,ADM2,DISTRICT
SM11428,-1.2906,10.7347,North Builsa District,ADM2,DISTRICT
SM261652,151.323,-25.2444,North Burnett,ADM2,DISTRICT
SM2500,-80.0003,35.5007,North Carolina,ADM1,DISTRICT
SM240357,-95.7032,17.1795,North Central Mixe,LANGUAGE,LANGUAGE
SM4212,80.5,8.3333,North Central Province-Sri Lanka,ADM1,DISTRICT
SM240413,54.018,12.649,North Central Soqotri,DIALECT,LANGUAGE
SM2501,125.0833,7.0167,North Cotabato,ADM1:ADM2,DISTRICT
SM2502,-100.0007,47.5003,North Dakota,ADM1,DISTRICT
SM2503,25.5,16,North Darfur,ADM1,DISTRICT
SM4398,0.3255,6.8651,North Dayi,ADM2,DISTRICT
SM5859,77.2227,28.6692,North Delhi,ADM2,DISTRICT
SM274704,-5.6295,54.5344,North Down and Ards,ADM1:ADM2,DISTRICT
SM3933,-0.6,10.4,North East,ADM1,DISTRICT
SM5999,77.302,28.6869,North East Delhi (2012-),ADM2,DISTRICT
SM295308,160.323,-9.1021,North East Gela,ADM2,DISTRICT
SM347407,-0.0833,53.5333,North East Lincolnshire,ADM2:ADM3,DISTRICT
SM295471,165.99,-10.6813,North East Santa Cruz,ADM2,DISTRICT
SM346767,28.5013,63.0719,North Eastern Savonia,ADM2:ADM3,DISTRICT
SM11155,38.8981,15.485,North Eastern Subregion,ADM2,DISTRICT
SM240267,168.375,-17.5774,North Efate,LANGUAGE,LANGUAGE
SM2504,-76.6756,25.4628,North Eleuthera,ADM1,DISTRICT
SM240215,13.6567,9.9249,North Fali,LANGUAGE,LANGUAGE
SM288614,141.3748,-5.729,North Fly,ADM2,DISTRICT
SM256364,-119.07,37,North Fork Mono,ETHNICITY,ETHNICITY
SM256364,-119.07,37,North Fork Mono,ETHNICITY,ETHNICITY
SM256364,-119,37.5,North Fork Mono,ETHNICITY,ETHNICITY
SM256364,-119,37.5,North Fork Mono,ETHNICITY,ETHNICITY
SM277081,90.616,25.8968,North Garo Hills,ADM2,DISTRICT
SM240405,14.219,10.6755,North Giziga,LANGUAGE,LANGUAGE
SM276809,73.9641,15.534,North Goa,ADM2,DISTRICT
SM4377,-1.5378,9.7056,North Gonja,ADM2,DISTRICT
SM4223,-63.0761,18.206,North Hill,ADM1,DISTRICT
SM280118,37.51,2.925,North Horr,ADM2,DISTRICT
SM240394,-75.8128,-11.2132,North Junín Quechua,LANGUAGE,LANGUAGE
SM3944,116.532,3.3599,North Kalimantan,ADM1,DISTRICT
SM274385,29.9167,62.8333,North Karelia,ADM1:ADM2,DISTRICT
SM2505,69.5,54.3333,North Kazakhstan (1997-),ADM1:ADMX,DISTRICT
SM2506,57,37.5,North Khorasan,ADM1,DISTRICT
SM295319,157.007,-7.9555,North Kolombangara,ADM2,DISTRICT
SM2507,29.9333,14.6833,"North Kordufan  (pre-2005, 2013-)",ADM1,DISTRICT
SM274727,-3.9167,55.8667,North Lanarkshire,ADM1:ADM2,DISTRICT
SM240425,36.0468,34.1709,North Levantine Arabic,LANGUAGE,LANGUAGE
SM274659,-0.5,53.5833,North Lincolnshire,ADM1:ADM2,DISTRICT
SM240274,-140.108,-8.8941,North Marquesan,LANGUAGE,LANGUAGE
SM240428,42.0047,36.2986,North Mesopotamian Arabic,LANGUAGE,LANGUAGE
SM459126,147.1881,-41.6677,North Midlands Tasmanian,ETHNICITY,ETHNICITY
SM240404,14.1117,10.6834,North Mofu,LANGUAGE,LANGUAGE
SM240261,128.323,-0.8644,North Moluccan Malay,LANGUAGE,LANGUAGE
SM240334,140.84,-5.5532,North Muyu,LANGUAGE,LANGUAGE
SM10189,-98.84,49.9164,North Norfolk,ADM2,DISTRICT
SM256365,-114.95,41.13,North Northern Paiute,ETHNICITY,ETHNICITY
SM240293,129.277,-2.9315,North Nuaulu,LANGUAGE,LANGUAGE
SM268753,-118.7523,50.4165,North Okanagan,ADM2,DISTRICT
SM2508,44.25,43,North Ossetia,ADM1,DISTRICT
SM461908,125.2667,40.05,North Pyonganin (pre-2002),ADM1,DISTRICT
SM295320,156.5425,-8.0125,North Ranongga,ADM2,DISTRICT
SM295321,157.2594,-8.5249,North Rendova,ADM2,DISTRICT
SM240129,22.1113,68.725,North Saami,LANGUAGE,LANGUAGE
SM295309,159.8032,-9.1223,North Savo,ADM2,DISTRICT
SM274386,27.5,63.1667,North Savonia,ADM1:ADM2,DISTRICT
SM274367,38.8124,9.6692,North Shewa,ADM2,DISTRICT
SM274349,39.668,9.769,North Shewa,ADM2,DISTRICT
SM4224,-63.0468,18.2293,North Side,ADM1,DISTRICT
SM2509,-81.2085,19.3482,North Side,ADM1,DISTRICT
SM277215,88.5,27.6667,North Sikkim,ADM2,DISTRICT
SM10201,-116.043,62.8018,North Slave Region,ADM2,DISTRICT
SM240359,-123.641,63.2671,North Slavey,LANGUAGE,LANGUAGE
SM301180,-152.0006,69.2506,North Slope,ADM2,DISTRICT
SM274660,-2.8333,51.3333,North Somerset,ADM1:ADM2,DISTRICT
SM261442,151.2102,-33.8342,North Sydney,ADM2,DISTRICT
SM240338,145.959,-6.5156,North Tairora,LANGUAGE,LANGUAGE
SM240276,169.303,-19.3733,North Tanna,LANGUAGE,LANGUAGE
SM454500,11.5,6.3,North Tikar,ETHNICITY,ETHNICITY
SM4392,0.354,6.103,North Tongu (2012-),ADM2,DISTRICT
SM277263,92.1555,24.1305,North Tripura (2012-),ADM2,DISTRICT
SM274661,-1.5,55.0333,North Tyneside,ADM1:ADM2,DISTRICT
SM295423,161.9683,-9.7456,North Ulawa,ADM2,DISTRICT
SM295322,158.045,-8.5691,North Vangunu,ADM2,DISTRICT
SM288568,144.6833,-5.8,North Waghi,ADM2,DISTRICT
SM240339,144.804,-5.7536,North Wahgi,LANGUAGE,LANGUAGE
SM240284,146.218,-6.6408,North Watut,LANGUAGE,LANGUAGE
SM2510,26,-26.5,North West,ADM1,DISTRICT
SM5861,77.1015,28.7011,North West Delhi,ADM2,DISTRICT
SM295310,160.2616,-9.0627,North West Gela,ADM2,DISTRICT
SM4124,-12.701,9.1238,North West Province-Sierra Leone,ADM1,DISTRICT
SM346822,23.068,61.9773,North Western Pirkanm,ADM2:ADM3,DISTRICT
SM4208,80.1667,7.75,North Western Province-Sri Lanka,ADM1,DISTRICT
SM274662,-1.5,54.1667,North Yorkshire,ADM1:ADM2,DISTRICT
SM276807,92.8675,12.8379,North and Middle Andaman,ADM2,DISTRICT
SM7374,19.6826,-18.6907,North-Central !Kung,ETHNICITY,ETHNICITY
SM229382,47.4388,42.4257,North-Central Dargwa,LANGUAGE,LANGUAGE
SM235479,18,-21.92,North-Central Ju,LANGUAGE,LANGUAGE
SM245992,48.6349,38.4552,North-Central Talysh,LANGUAGE,LANGUAGE
SM240564,94.2047,25.1567,North-Central Tangkhul Naga,LANGUAGE,LANGUAGE
SM2491,27.5,-21,North-East,ADM1,DISTRICT
SM293663,37.5791,55.8871,North-Eastern,ADM2,DISTRICT
SM2493,22.5,-20,North-West,ADM1,DISTRICT
SM293664,37.4167,55.8333,North-Western,ADM2,DISTRICT
SM2494,25,-13,North-Western,ADM1,DISTRICT
SM459125,144.8721,-41.2456,North-western Tasmanian,ETHNICITY,ETHNICITY
SM261432,116.6496,-31.699,Northam,ADM2,DISTRICT
SM303056,-75.9285,37.3008,Northampton,ADM2,DISTRICT
SM302327,-75.3074,40.7542,Northampton,ADM2,DISTRICT
SM301993,-77.3967,36.4177,Northampton,ADM2,DISTRICT
SM261433,114.4959,-28.0496,Northampton,ADM2,DISTRICT
SM274663,-0.8333,52.25,Northamptonshire,ADM1:ADM2,DISTRICT
SM303896,-64.7924,17.7533,Northcentral,ADM2,DISTRICT
SM240525,-15.835,13.213,Northeast Bainounk,LANGUAGE,LANGUAGE
SM230410,162.4862,57.5961,Northeast Itelmen,LANGUAGE,LANGUAGE
SM240348,144.667,-7.3922,Northeast Kiwai,LANGUAGE,LANGUAGE
SM240367,-120.645,40.4102,Northeast Maidu,LANGUAGE,LANGUAGE
SM240124,70.4317,34.66,Northeast Pashayi,LANGUAGE,LANGUAGE
SM240124,70.4317,34.66,Northeast Pashayi,LANGUAGE,LANGUAGE
SM240124,70.8909,34.8783,Northeast Pashayi,LANGUAGE,LANGUAGE
SM240124,70.8909,34.8783,Northeast Pashayi,LANGUAGE,LANGUAGE
SM248212,-118.424,45.7206,Northeast Sahaptin,LANGUAGE,LANGUAGE
SM240250,31.8392,9.28,Northeastern Dinka,LANGUAGE,LANGUAGE
SM454386,151.983,-8.974,Northeastern Massim,ETHNICITY,ETHNICITY
SM240382,-122.509,39.2894,Northeastern Russian River Pomo,LANGUAGE,LANGUAGE
SM240189,103.67,16.03,Northeastern Thai,LANGUAGE,LANGUAGE
SM235273,-44.8295,-4.1992,Northeastern Timbira,LANGUAGE,LANGUAGE
SM293665,37.5128,55.8711,Northern,ADM2,DISTRICT
SM2512,179.5,-16.5,Northern,ADM1,DISTRICT
SM2514,29.5,19.3,Northern,ADM1,DISTRICT
SM2516,30.75,-9.75,Northern (2011-),ADM1,DISTRICT
SM461321,50.4667,26.15,Northern (2014-),ADM1,DISTRICT
SM17904,30,19,Northern (excl. Merawi),ADM2,DISTRICT
SM256366,120.1,15,Northern Agta,ETHNICITY,ETHNICITY
SM240538,12.8508,8.8298,Northern Alantika Vere,LANGUAGE,LANGUAGE
SM240300,121.454,15.6913,Northern Alta,LANGUAGE,LANGUAGE
SM240139,88.0672,50.8998,Northern Altai,LANGUAGE,LANGUAGE
SM240353,129.453,28.2099,Northern Amami-Oshima,LANGUAGE,LANGUAGE
SM239310,121.59,23.9386,Northern Amis,DIALECT,LANGUAGE
SM458180,151.4763,-30.1276,Northern Anaiwan,ETHNICITY,ETHNICITY
SM261717,138.4775,-33.3007,Northern Areas,ADM2,DISTRICT
SM2517,75,36,Northern Areas,ADM1,DISTRICT
SM240176,99.656,26.925,Northern Bai,LANGUAGE,LANGUAGE
SM18563,151.2436,-33.6979,Northern Beaches,ADM2,DISTRICT
SM240311,48.812,-19.9184,Northern Betsimisaraka Malagasy,LANGUAGE,LANGUAGE
SM234137,122.816,9.992,Northern Binukidnon,LANGUAGE,LANGUAGE
SM240253,-4.4754,12.4182,Northern Bobo Madaré,LANGUAGE,LANGUAGE
SM2518,22,-29.5,Northern Cape,ADM1,DISTRICT
SM240305,124.27,13.9032,Northern Catanduanes Bicolano,LANGUAGE,LANGUAGE
SM252817,94.56,42.82,Northern Chinese,ETHNICITY,ETHNICITY
SM252817,94.56,42.82,Northern Chinese,ETHNICITY,ETHNICITY
SM252817,94.56,42.82,Northern Chinese,ETHNICITY,ETHNICITY
SM252817,118,37,Northern Chinese,ETHNICITY,ETHNICITY
SM252817,118,37,Northern Chinese,ETHNICITY,ETHNICITY
SM252817,118,37,Northern Chinese,ETHNICITY,ETHNICITY
SM252817,114,32.2657,Northern Chinese,ETHNICITY,ETHNICITY
SM252817,114,32.2657,Northern Chinese,ETHNICITY,ETHNICITY
SM252817,114,32.2657,Northern Chinese,ETHNICITY,ETHNICITY
SM240393,-77.4146,-8.9736,Northern Conchucos Ancash Quechua,LANGUAGE,LANGUAGE
SM240223,-3.0124,11.0441,Northern Dagara,LANGUAGE,LANGUAGE
SM251026,-2.61,11.59,Northern Dagara,ETHNICITY,ETHNICITY
SM251026,-2.61,11.59,Northern Dagara,ETHNICITY,ETHNICITY
SM251026,-3,11,Northern Dagara,ETHNICITY,ETHNICITY
SM251026,-3,11,Northern Dagara,ETHNICITY,ETHNICITY
SM240185,109.204,26.9944,Northern Dong,LANGUAGE,LANGUAGE
SM240067,-77.7505,55.1928,Northern East Cree,LANGUAGE,LANGUAGE
SM240387,-77.574,7.1276,Northern Emberá,LANGUAGE,LANGUAGE
SM256367,-120.16,36.44,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120.16,36.44,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120.16,36.44,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120.16,36.44,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120.33,37.06,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120.33,37.06,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120.33,37.06,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120.33,37.06,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120,36,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120,36,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120,36,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120,36,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120.44,37.44,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120.44,37.44,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120.44,37.44,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-120.44,37.44,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-119.8,37.2,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-119.8,37.2,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-119.8,37.2,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM256367,-119.8,37.2,Northern Foothill Yokuts,ETHNICITY,ETHNICITY
SM240089,8.5849,54.6409,Northern Frisian,LANGUAGE,LANGUAGE
SM240162,84.929,28.2332,Northern Ghale,LANGUAGE,LANGUAGE
SM240155,81.3842,18.1632,Northern Gondi,LANGUAGE,LANGUAGE
SM261269,142.873,-36.8496,Northern Grampians,ADM2,DISTRICT
SM240245,-8.1409,5.171,Northern Grebo,LANGUAGE,LANGUAGE
SM240062,106.682,26.7238,Northern Guiyang Hmong,LANGUAGE,LANGUAGE
SM231898,35.93,11.05,Northern Gumuz,LANGUAGE,LANGUAGE
SM233360,102.2288,31.603,Northern Gyalrong,FAMILY,LANGUAGE
SM240356,-132.0793,54.0045,Northern Haida,LANGUAGE,LANGUAGE
SM240120,73.3844,34.3202,Northern Hindko,LANGUAGE,LANGUAGE
SM240522,104.1919,24.0419,Northern Hmong,LANGUAGE,LANGUAGE
SM240197,107.102,26.3259,Northern Huishui Hmong,LANGUAGE,LANGUAGE
SM240208,149.1,-34.85,Northern Inland Yuin,LANGUAGE,LANGUAGE
SM2519,-6.5,54.5,Northern Ireland,ADM1,DISTRICT
SM2520,145.771,18.11,Northern Islands,ADM1,DISTRICT
SM244624,95.606,27.296,Northern Jinghpaw,LANGUAGE,LANGUAGE
SM247933,18.1864,-18.2675,Northern Ju,LANGUAGE,LANGUAGE
SM240301,120.786,17.1057,Northern Kankanay,LANGUAGE,LANGUAGE
SM240548,106.4588,16.1823,Northern Katang,LANGUAGE,LANGUAGE
SM240542,66.676,63.7047,Northern Khanty,FAMILY,LANGUAGE
SM240138,103.863,14.4674,Northern Khmer,LANGUAGE,LANGUAGE
SM240212,-10.4063,8.7283,Northern Kissi,LANGUAGE,LANGUAGE
SM240100,43,37,Northern Kurdish,LANGUAGE,LANGUAGE
SM346778,27.176,68.7132,Northern Lapland,ADM2:ADM3,DISTRICT
SM240386,-58.98,-22.54,Northern Lengua,LANGUAGE,LANGUAGE
SM240104,47.916,32.5438,Northern Luri,LANGUAGE,LANGUAGE
SM236819,-122.529,48.42,Northern Lushootseed,LANGUAGE,LANGUAGE
SM237467,61.344,64.4118,Northern Mansi,LANGUAGE,LANGUAGE
SM240063,106.53,25.7723,Northern Mashan Hmong,LANGUAGE,LANGUAGE
SM261770,147.4578,-41.8799,Northern Midlands,ADM2,DISTRICT
SM4195,125.2267,8.5262,Northern Mindanao (2001-),ADM1,DISTRICT
SM240168,103.4187,23.0312,Northern Muji,LANGUAGE,LANGUAGE
SM240218,21.8525,3.5636,Northern Ngbandi,LANGUAGE,LANGUAGE
SM240170,102.456,24.408,Northern Nisu,LANGUAGE,LANGUAGE
SM240227,-2.9821,12.131,Northern Nuni,LANGUAGE,LANGUAGE
SM240373,-96.9748,18.1884,Northern Oaxaca Nahuatl,LANGUAGE,LANGUAGE
SM240384,-121.8277,37.4365,Northern Ohlone,LANGUAGE,LANGUAGE
SM256439,-92.65,52.18,Northern Ojibwa,ETHNICITY,ETHNICITY
SM256439,-92.65,52.18,Northern Ojibwa,ETHNICITY,ETHNICITY
SM256439,-90.81,55.56,Northern Ojibwa,ETHNICITY,ETHNICITY
SM256439,-90.81,55.56,Northern Ojibwa,ETHNICITY,ETHNICITY
SM256439,-91.5,51.5,Northern Ojibwa,ETHNICITY,ETHNICITY
SM256439,-91.5,51.5,Northern Ojibwa,ETHNICITY,ETHNICITY
SM240325,142.006,-3.2554,Northern One,LANGUAGE,LANGUAGE
SM274391,26.4167,65,Northern Ostrobothnia,ADM1:ADM2,DISTRICT
SM240369,-119.536,40.0799,Northern Paiute,LANGUAGE,LANGUAGE
SM454385,-120,42,Northern Paiute,ETHNICITY,ETHNICITY
SM240397,-99.6434,22.0968,Northern Pame,LANGUAGE,LANGUAGE
SM240105,71.33,34,Northern Pashto,LANGUAGE,LANGUAGE
SM240388,-76.0389,-1.8857,Northern Pastaza Quichua,LANGUAGE,LANGUAGE
SM261654,142.3536,-10.9719,Northern Peninsula Area,ADM2,DISTRICT
SM240546,110.045,25.218,Northern Pinghua,LANGUAGE,LANGUAGE
SM240381,-123.209,39.1084,Northern Pomo,LANGUAGE,LANGUAGE
SM256369,-123.417,39.42,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123.417,39.42,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123.417,39.42,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123.417,39.42,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123,39,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123,39,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123,39,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123,39,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123.29,39.34,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123.29,39.34,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123.29,39.34,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123.29,39.34,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123.3,39.6,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123.3,39.6,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123.3,39.6,Northern Pomo,ETHNICITY,ETHNICITY
SM256369,-123.3,39.6,Northern Pomo,ETHNICITY,ETHNICITY
SM4125,-11.5333,9.15,Northern Province-Sierra Leone (2017-),ADM1,DISTRICT
SM4209,80.4167,9.1667,Northern Province-Sri Lanka,ADM1,DISTRICT
SM240372,-97.9814,20.1356,Northern Puebla Nahuatl,LANGUAGE,LANGUAGE
SM240175,101.305,28.6626,Northern Pumi,LANGUAGE,LANGUAGE
SM240195,108.162,26.6338,Northern Qiandong Miao,LANGUAGE,LANGUAGE
SM240174,103.192,32.2766,Northern Qiang,LANGUAGE,LANGUAGE
SM4179,34,-11,Northern Region,ADM1:ADMX,DISTRICT
SM3934,-0.1,9.45,Northern Region (2019-),ADM1,DISTRICT
SM4216,31.8864,2.7373,Northern Region-Uganda,ADM1,DISTRICT
SM240177,94.5683,26.4492,Northern Rengma Naga,LANGUAGE,LANGUAGE
SM268755,-123.7541,59.002,Northern Rockies,ADM2,DISTRICT
SM240402,108.849,12.1967,Northern Roglai,LANGUAGE,LANGUAGE
SM2522,124.6667,12.3333,Northern Samar,ADM1:ADM2,DISTRICT
SM346828,22.3479,61.8703,Northern Satakunta,ADM2:ADM3,DISTRICT
SM256370,-98,52,Northern Saulteaux,ETHNICITY,ETHNICITY
SM256370,-98,52,Northern Saulteaux,ETHNICITY,ETHNICITY
SM256370,-98,52,Northern Saulteaux,ETHNICITY,ETHNICITY
SM256370,-96.78,52.28,Northern Saulteaux,ETHNICITY,ETHNICITY
SM256370,-96.78,52.28,Northern Saulteaux,ETHNICITY,ETHNICITY
SM256370,-96.78,52.28,Northern Saulteaux,ETHNICITY,ETHNICITY
SM256370,-95.5,52,Northern Saulteaux,ETHNICITY,ETHNICITY
SM256370,-95.5,52,Northern Saulteaux,ETHNICITY,ETHNICITY
SM256370,-95.5,52,Northern Saulteaux,ETHNICITY,ETHNICITY
SM240383,-120.588,38.4179,Northern Sierra Miwok,LANGUAGE,LANGUAGE
SM240411,53.837,12.593,Northern Soqotri,DIALECT,LANGUAGE
SM245563,-123.7571,48.5161,Northern Straits Salish,LANGUAGE,LANGUAGE
SM240307,123.588,8.5232,Northern Subanen,LANGUAGE,LANGUAGE
SM240204,142.2736,-34.1901,Northern Sunraysia,LANGUAGE,LANGUAGE
SM10164,-116.077,56.6391,Northern Sunrise County,ADM2,DISTRICT
SM240563,94.552,25.2479,Northern Tangkhul Naga,LANGUAGE,LANGUAGE
SM240371,-107.658,28.6077,Northern Tarahumara,LANGUAGE,LANGUAGE
SM459116,146.0336,-41.3251,Northern Tasmanian,ETHNICITY,ETHNICITY
SM240374,-107.379,26.4348,Northern Tepehuan,LANGUAGE,LANGUAGE
SM2523,134,-20,Northern Territory,ADM1,DISTRICT
SM240188,100.2074,18.8575,Northern Thai,LANGUAGE,LANGUAGE
SM256372,99.66,17.18,Northern Thai,ETHNICITY,ETHNICITY
SM240540,117.5455,5.8606,Northern Tidung,LANGUAGE,LANGUAGE
SM240398,-97.8349,17.4806,Northern Tlaxiaco Mixtec,LANGUAGE,LANGUAGE
SM246946,20,41,Northern Tosk Albanian,LANGUAGE,LANGUAGE
SM240230,-4.8827,11.133,Northern Toussian,LANGUAGE,LANGUAGE
SM247069,26.9638,-19.7974,Northern Tshwa,LANGUAGE,LANGUAGE
SM240183,109.759,29.3016,Northern Tujia,LANGUAGE,LANGUAGE
SM240358,-136.644,62.8235,Northern Tutchone,LANGUAGE,LANGUAGE
SM240143,69.211,40.886,Northern Uzbek,LANGUAGE,LANGUAGE
SM249649,-119.721,36.3167,Northern Yokuts,LANGUAGE,LANGUAGE
SM240193,157.853,70.498,Northern Yukaghir,LANGUAGE,LANGUAGE
SM454511,147.9,70.6,Northern Yukaghir,ETHNICITY,ETHNICITY
SM249753,-123.296,39.8389,Northern Yukian,LANGUAGE,LANGUAGE
SM2524,174,-35.5,Northland,ADM1,DISTRICT
SM303907,-64.9356,18.3609,Northside,ADM2,DISTRICT
SM303057,-76.4717,37.8824,Northumberland,ADM2,DISTRICT
SM302328,-76.7093,40.852,Northumberland,ADM2,DISTRICT
SM274664,-2,55.25,Northumberland,ADM1:ADM2,DISTRICT
SM268870,-77.9995,44.1668,Northumberland,ADM2,DISTRICT
SM268807,-65.9988,46.9168,Northumberland,ADM2,DISTRICT
SM303897,-64.8533,17.7373,Northwest,ADM2,DISTRICT
SM301181,-161.0006,66.8333,Northwest Arctic,ADM2,DISTRICT
SM240412,53.49,12.69,Northwest Central Soqotri,DIALECT,LANGUAGE
SM240219,15.2569,5.9503,Northwest Gbaya,LANGUAGE,LANGUAGE
SM454544,22.29,3.13,Northwest Gbaya,ETHNICITY,ETHNICITY
SM454544,22.29,3.13,Northwest Gbaya,ETHNICITY,ETHNICITY
SM454544,22.29,3.13,Northwest Gbaya,ETHNICITY,ETHNICITY
SM454544,16,6,Northwest Gbaya,ETHNICITY,ETHNICITY
SM454544,16,6,Northwest Gbaya,ETHNICITY,ETHNICITY
SM454544,16,6,Northwest Gbaya,ETHNICITY,ETHNICITY
SM454544,15,5,Northwest Gbaya,ETHNICITY,ETHNICITY
SM454544,15,5,Northwest Gbaya,ETHNICITY,ETHNICITY
SM454544,15,5,Northwest Gbaya,ETHNICITY,ETHNICITY
SM240366,-121.368,39.6721,Northwest Maidu,LANGUAGE,LANGUAGE
SM240399,-98.0574,17.8287,Northwest Oaxaca Mixtec,LANGUAGE,LANGUAGE
SM240123,69.9936,35.1678,Northwest Pashayi,LANGUAGE,LANGUAGE
SM240123,69.9936,35.1678,Northwest Pashayi,LANGUAGE,LANGUAGE
SM240123,70.0876,34.9318,Northwest Pashayi,LANGUAGE,LANGUAGE
SM240123,70.0876,34.9318,Northwest Pashayi,LANGUAGE,LANGUAGE
SM256374,-57.13,53.05,Northwest River Indians,ETHNICITY,ETHNICITY
SM249324,-120.756,46.2655,Northwest Sahaptin,LANGUAGE,LANGUAGE
SM2525,-121.5045,69.5006,Northwest Territories,ADM1,DISTRICT
SM240251,29.2998,9.8148,Northwestern Dinka,LANGUAGE,LANGUAGE
SM240099,52.925,29.643,Northwestern Fars,LANGUAGE,LANGUAGE
SM240152,78.4934,20.1022,Northwestern Kolami,LANGUAGE,LANGUAGE
SM240617,19.7829,1.4033,Northwestern Lingala,LANGUAGE,LANGUAGE
SM240376,-87.7862,51.6192,Northwestern Ojibwa,LANGUAGE,LANGUAGE
SM240429,85.1648,27.9087,Northwestern Tamang,LANGUAGE,LANGUAGE
SM303059,-82.6291,36.9334,Norton,ADM2,DISTRICT
SM300954,-99.9035,39.7844,Norton,ADM2,DISTRICT
SM240631,10.71,59.92,Norwegian,LANGUAGE,LANGUAGE
SM256375,7.28,61.64,Norwegian,ETHNICITY,ETHNICITY
SM256375,7.28,61.64,Norwegian,ETHNICITY,ETHNICITY
SM256375,5.358,60.359,Norwegian,ETHNICITY,ETHNICITY
SM256375,5.358,60.359,Norwegian,ETHNICITY,ETHNICITY
SM240632,8.886,61.112,Norwegian Bokmål,DIALECT,LANGUAGE
SM240630,10.242,60.7733,Norwegian Sign Language,LANGUAGE,LANGUAGE
SM14077,-9.5992,5.8508,Norwein District,ADM2,DISTRICT
SM12590,-76.4771,18.1882,Norwich,ADM2,DISTRICT
SM12591,-77.8863,18.4922,Norwood,ADM2,DISTRICT
SM261718,138.6356,-34.908,Norwood Payneham St Peters,ADM2,DISTRICT
SM2484,-20,65.3333,Norðurland vestra,ADM1,DISTRICT
SM278567,140.1449,40.2029,Noshiro,ADM2,DISTRICT
SM370962,48.2578,-13.342,Nosibe,ADM2:ADM3,DISTRICT
SM305954,67.92,37.2095,Nosir Khusrav,ADM2,DISTRICT
SM289813,23.9767,46.413,Noslac,ADM2,DISTRICT
SM267113,-37.4881,-10.2985,Nossa Senhora Aprecido,ADM2,DISTRICT
SM10061,-24.3389,15.0004,Nossa Senhora da Ajuda,ADM2,DISTRICT
SM10062,-24.43,14.89,Nossa Senhora da Conceicao,ADM2,DISTRICT
SM267114,-37.5329,-10.1929,Nossa Senhora da Glória,ADM2,DISTRICT
SM10063,-23.5209,14.9501,Nossa Senhora da Graca,ADM2,DISTRICT
SM10064,-24.3291,16.6564,Nossa Senhora da Lapa,ADM2,DISTRICT
SM10065,-23.1606,15.2153,Nossa Senhora da Luz (Maio),ADM2,DISTRICT
SM18512,-23.478,15.0227,Nossa Senhora da Luz (São Domingos),ADM2,DISTRICT
SM18513,-24.9281,16.8283,Nossa Senhora da Luz (São Vicente),ADM2,DISTRICT
SM10066,-22.9315,16.737,Nossa Senhora das Dores,ADM2,DISTRICT
SM267115,-37.2468,-10.4536,Nossa Senhora das Dores,ADM2,DISTRICT
SM264589,-51.8024,-22.9304,Nossa Senhora das Graças,ADM2,DISTRICT
SM281406,113.5454,22.213,Nossa Senhora de Fátima,ADM1:ADM2,DISTRICT
SM267116,-37.0277,-10.1024,Nossa Senhora de Lourdes,ADM2,DISTRICT
SM265067,-42.1902,-4.6384,Nossa Senhora de Nazaré,ADM2,DISTRICT
SM281404,113.5567,22.1582,Nossa Senhora do Carmo,ADM1:ADM2,DISTRICT
SM10067,-25.1102,17.1823,Nossa Senhora do Livramento,ADM2,DISTRICT
SM263187,-56.5459,-15.9345,Nossa Senhora do Livramento,ADM2,DISTRICT
SM10068,-24.7234,14.8617,Nossa Senhora do Monte,ADM2,DISTRICT
SM18511,-24.1919,16.5954,Nossa Senhora do Rosario (Ribeira Brava),ADM2,DISTRICT
SM10069,-25.06,17.16,Nossa Senhora do Rosario (Ribeira Grande),ADM2,DISTRICT
SM267117,-37.1733,-10.8644,Nossa Senhora do Socorro,ADM2,DISTRICT
SM265068,-42.6065,-4.0577,Nossa Senhora dos Remédios,ADM2,DISTRICT
SM370988,48.1734,-20.5653,Nosy varika,ADM2:ADM3,DISTRICT
SM371019,49.8978,-16.9016,Nosy-Boraha (St. Marie),ADM2:ADM3,DISTRICT
SM286206,9.2605,59.5599,Notodden,ADM2,DISTRICT
SM240637,1.2744,10.7025,Notre,LANGUAGE,LANGUAGE
SM240638,151.661,-3.0557,Notsi,LANGUAGE,LANGUAGE
SM274665,-1.15,52.9667,Nottingham,ADM1:ADM2,DISTRICT
SM274666,-1,53.1667,Nottinghamshire,ADM1:ADM2,DISTRICT
SM240639,-77.115,36.72,Nottoway,LANGUAGE,LANGUAGE
SM303060,-78.0513,37.1431,Nottoway,ADM2,DISTRICT
SM4436,-7.6473,33.4584,Nouaceur,ADM2,DISTRICT
SM14194,-16.1723,20.9233,Nouadhibou (2013-),ADM2,DISTRICT
SM4107,-15.9167,18.2333,Nouakchott Nord,ADM1,DISTRICT
SM4108,-15.9833,18.2667,Nouakchott Ouest,ADM1,DISTRICT
SM4109,-15.95,17.9667,Nouakchott Sud,ADM1,DISTRICT
SM240640,-4.033,5.317,Nouchi,LANGUAGE,LANGUAGE
SM262198,-3,9.8333,Noumbiel,ADM2,DISTRICT
SM284595,166.4667,-22.2667,Nouméa,ADM2,DISTRICT
SM269547,10.9184,5.6412,Noun,ADM2,DISTRICT
SM298045,10.2378,36.7389,Nouvelle Médina,ADM2,DISTRICT
SM2528,1.2689,46.0917,Nouvelle-Aquitaine,ADM1,DISTRICT
SM266731,-49.5259,-21.0659,Nova Aliança,ADM2,DISTRICT
SM264590,-52.6257,-23.1867,Nova Aliança do Ivaí,ADM2,DISTRICT
SM265779,-52.1662,-28.705,Nova Alvorada,ADM2,DISTRICT
SM263069,-54.1526,-21.4808,Nova Alvorada do Sul,ADM2,DISTRICT
SM268106,-49.925,-15.051,Nova América,ADM2,DISTRICT
SM264591,-50.6962,-23.3338,Nova América da Colina,ADM2,DISTRICT
SM263070,-53.4664,-21.8821,Nova Andradina,ADM2,DISTRICT
SM265780,-51.7349,-28.6441,Nova Araçá,ADM2,DISTRICT
SM264592,-53.2967,-24.5095,Nova Aurora,ADM2,DISTRICT
SM268107,-48.2766,-18.1011,Nova Aurora,ADM2,DISTRICT
SM263188,-58.0184,-9.6857,Nova Bandeirantes,ADM2,DISTRICT
SM265781,-51.8054,-28.7029,Nova Bassano,ADM2,DISTRICT
SM263693,-41.1188,-18.4998,Nova Belém,ADM2,DISTRICT
SM265782,-52.9821,-28.0089,Nova Boa Vista,ADM2,DISTRICT
SM263189,-55.1053,-14.7574,Nova Brasilândia,ADM2,DISTRICT
SM266093,-62.165,-11.4911,Nova Brasilândia d'Oeste,ADM2,DISTRICT
SM265783,-52.0482,-29.2021,Nova Bréscia (pre-1996),ADM2,DISTRICT
SM305534,17.7709,45.6618,Nova Bukovica,ADM2,DISTRICT
SM266733,-48.9846,-24.2092,Nova Campina,ADM2,DISTRICT
SM267559,-40.1783,-14.8462,Nova Canaã,ADM2,DISTRICT
SM266734,-50.9062,-20.3702,Nova Canaã Paulista,ADM2,DISTRICT
SM263190,-56.0344,-10.7199,Nova Canaã do Norte,ADM2,DISTRICT
SM265785,-54.1217,-27.5934,Nova Candelária,ADM2,DISTRICT
SM264593,-52.5632,-24.6565,Nova Cantu,ADM2,DISTRICT
SM266735,-50.3496,-20.7853,Nova Castilho,ADM2,DISTRICT
SM262832,-46.2918,-7.2666,Nova Colinas,ADM2,DISTRICT
SM268108,-50.5765,-14.2768,Nova Crixás,ADM2,DISTRICT
SM265596,-35.4546,-6.4681,Nova Cruz,ADM2,DISTRICT
SM263694,-43.014,-19.7097,Nova Era,ADM2,DISTRICT
SM266219,-52.8985,-26.913,Nova Erechim,ADM2,DISTRICT
SM264595,-52.2545,-23.1831,Nova Esperança,ADM2,DISTRICT
SM264197,-46.9738,-2.4536,Nova Esperança do Piriá,ADM2,DISTRICT
SM264594,-53.2603,-25.8885,Nova Esperança do Sudoeste,ADM2,DISTRICT
SM265786,-54.8346,-29.3923,Nova Esperança do Sul,ADM2,DISTRICT
SM266736,-48.5688,-21.7921,Nova Europa,ADM2,DISTRICT
SM264253,-36.201,-6.4879,Nova Floresta,ADM2,DISTRICT
SM265288,-42.4956,-22.3332,Nova Friburgo,ADM2,DISTRICT
SM264596,-50.5356,-23.3958,Nova Fátima,ADM2,DISTRICT
SM267560,-39.5861,-11.5661,Nova Fátima,ADM2,DISTRICT
SM268109,-49.4835,-15.0636,Nova Glória,ADM2,DISTRICT
SM4132,13.7281,45.9652,Nova Gorica (2006-),ADM1,DISTRICT
SM276022,17.4,45.2667,Nova Gradiška,ADM2,DISTRICT
SM266737,-49.3155,-20.4572,Nova Granada,ADM2,DISTRICT
SM263192,-55.3373,-10.2853,Nova Guarita,ADM2,DISTRICT
SM266738,-51.6432,-21.319,Nova Guataporanga,ADM2,DISTRICT
SM265787,-50.9084,-29.5964,Nova Hartz,ADM2,DISTRICT
SM267561,-39.5888,-13.8623,Nova Ibiá,ADM2,DISTRICT
SM9968,-43.506,-22.6863,Nova Iguacu (1999-),ADM2,DISTRICT
SM268111,-49.3119,-14.2973,Nova Iguaçu de Goiás,ADM2,DISTRICT
SM266739,-51.5276,-21.1548,Nova Independência,ADM2,DISTRICT
SM262833,-44.0398,-6.7496,Nova Iorque,ADM2,DISTRICT
SM264198,-49.1986,-4.9878,Nova Ipixuna,ADM2,DISTRICT
SM266220,-52.8401,-26.9316,Nova Itaberaba,ADM2,DISTRICT
SM267562,-40.0078,-13.0403,Nova Itarana,ADM2,DISTRICT
SM305564,17.6441,45.1956,Nova Kapela,ADM2,DISTRICT
SM263193,-59.7928,-14.2673,Nova Lacerda,ADM2,DISTRICT
SM264597,-52.5775,-25.2194,Nova Laranjeiras,ADM2,DISTRICT
SM263695,-43.8909,-20.0696,Nova Lima,ADM2,DISTRICT
SM264598,-52.9548,-22.7869,Nova Londrina,ADM2,DISTRICT
SM266740,-50.243,-20.873,Nova Luzitânia,ADM2,DISTRICT
SM266094,-64.4943,-10.5384,Nova Mamoré,ADM2,DISTRICT
SM263194,-57.2609,-14.3126,Nova Marilândia,ADM2,DISTRICT
SM263195,-57.1917,-12.9838,Nova Maringá,ADM2,DISTRICT
SM263196,-57.2977,-9.9974,Nova Monte Verde,ADM2,DISTRICT
SM18573,-56.1519,-13.5891,Nova Mutum (1999-),ADM2,DISTRICT
SM263696,-41.513,-18.4501,Nova Módica,ADM2,DISTRICT
SM314078,-51.7982,-14.1465,Nova Nazare de Borecaia,ADM2:ADM3,DISTRICT
SM266741,-47.2713,-22.7816,Nova Odessa,ADM2,DISTRICT
SM267269,-48.2815,-7.6897,Nova Olinda,ADM2,DISTRICT
SM264254,-38.0112,-7.4523,Nova Olinda,ADM2,DISTRICT
SM267805,-39.672,-7.0857,Nova Olinda,ADM2,DISTRICT
SM262834,-45.9201,-2.845,Nova Olinda do Maranhão,ADM2,DISTRICT
SM267339,-58.5975,-3.9464,Nova Olinda do Norte,ADM2,DISTRICT
SM264600,-53.039,-23.4321,Nova Olímpia,ADM2,DISTRICT
SM263198,-57.4289,-14.7989,Nova Olímpia,ADM2,DISTRICT
SM457976,-53.4324,-29.4071,Nova Palma,ADM2,DISTRICT
SM265788,-51.3145,-29.0122,Nova Palma Part,ADM2,DISTRICT
SM264255,-36.4419,-6.6735,Nova Palmeira,ADM2,DISTRICT
SM457977,-51.1027,-29.3667,Nova Petropolis,ADM2,DISTRICT
SM263697,-47.7137,-19.2865,Nova Ponte,ADM2,DISTRICT
SM263698,-43.2816,-15.7158,Nova Porteirinha,ADM2,DISTRICT
SM457978,-51.579,-28.7274,Nova Prata,ADM2,DISTRICT
SM264601,-53.3805,-25.6085,Nova Prata do Iguaçu,ADM2,DISTRICT
SM265792,-53.695,-28.093,Nova Ramada,ADM2,DISTRICT
SM305326,16.9529,45.8005,Nova Rača,ADM2,DISTRICT
SM267563,-41.1234,-12.874,Nova Redenção,ADM2,DISTRICT
SM263699,-46.4168,-21.099,Nova Resende,ADM2,DISTRICT
SM268112,-47.0083,-13.8408,Nova Roma,ADM2,DISTRICT
SM265793,-51.4144,-28.9909,Nova Roma do Sul,ADM2,DISTRICT
SM267270,-48.9705,-10.5628,Nova Rosalândia,ADM2,DISTRICT
SM267806,-40.5955,-4.7228,Nova Russas,ADM2,DISTRICT
SM264602,-50.7545,-23.6024,Nova Santa Bárbara,ADM2,DISTRICT
SM9970,-54.9064,-10.9204,Nova Santa Helena,ADM2:ADM3,DISTRICT
SM265794,-51.2743,-29.8327,Nova Santa Rita,ADM2,DISTRICT
SM264603,-53.9955,-24.4469,Nova Santa Rosa,ADM2,DISTRICT
SM2529,-62.9987,45.0002,Nova Scotia,ADM1,DISTRICT
SM263700,-44.9767,-19.8453,Nova Serrana,ADM2,DISTRICT
SM267565,-38.4621,-11.3189,Nova Soure,ADM2,DISTRICT
SM264604,-51.9458,-24.4308,Nova Tebas,ADM2,DISTRICT
SM264199,-47.4209,-1.1206,Nova Timboteua,ADM2,DISTRICT
SM266221,-49.0426,-27.3047,Nova Trento,ADM2,DISTRICT
SM263199,-54.4773,-12.6823,Nova Ubiratã,ADM2,DISTRICT
SM266096,-62.5109,-10.9494,Nova União,ADM2,DISTRICT
SM263701,-43.578,-19.6436,Nova União,ADM2,DISTRICT
SM266222,-49.5878,-28.7045,Nova Veneza,ADM2,DISTRICT
SM268113,-49.3,-16.3546,Nova Veneza,ADM2,DISTRICT
SM268013,-40.5228,-18.6984,Nova Venécia,ADM2,DISTRICT
SM267566,-39.6652,-17.8745,Nova Viçosa,ADM2,DISTRICT
SM263200,-52.3258,-14.5875,Nova Xavantina,ADM2,DISTRICT
SM262390,26.05,42.45,Nova Zagora,ADM2,DISTRICT
SM290643,23.6684,45.1646,Novaci,ADM2,DISTRICT
SM13715,22.4082,56.6103,Novadnieku pagasts,ADM2,DISTRICT
SM266742,-48.9099,-20.9766,Novais,ADM2,DISTRICT
SM276069,14.8854,44.5563,Novalja,ADM2,DISTRICT
SM277886,8.5778,45.5853,Novara,ADM2,DISTRICT
SM2530,21.6675,41.0194,Novatsi,ADM1,DISTRICT
SM2531,32.5,58.5,Novgorod,ADM1,DISTRICT
SM17020,31.25,58.75,Novgorodskiy Rayon,ADM2,DISTRICT
SM299690,33.254,52.0128,Novhorod-Sivers'kyi,ADM2,DISTRICT
SM299528,32.6578,48.364,Novhorodkivs'kyi,ADM2,DISTRICT
SM305736,15.9831,46.1717,Novi Golubovec,ADM2,DISTRICT
SM313184,16.4964,45.0317,Novi Grad,ADM2:ADM3,DISTRICT
SM276010,16.3333,46.1667,Novi Marof,ADM2,DISTRICT
SM262377,27.2167,43.4167,Novi Pazar,ADM2,DISTRICT
SM275974,14.7901,45.1287,Novi Vinodolski,ADM2,DISTRICT
SM305587,15.5567,44.1942,Novigrad,ADM2,DISTRICT
SM276044,13.5616,45.3167,Novigrad,ADM2,DISTRICT
SM305710,16.9496,46.0761,Novigrad Podravski,ADM2,DISTRICT
SM269917,-76.6189,4.8708,Novita,ADM2,DISTRICT
SM267271,-47.4716,-10.152,Novo Acordo,ADM2,DISTRICT
SM267340,-61.7851,-1.9839,Novo Airão,ADM2,DISTRICT
SM267272,-46.5694,-12.8731,Novo Alegre,ADM2,DISTRICT
SM267341,-60.6302,-7.0011,Novo Aripuanã,ADM2,DISTRICT
SM265796,-53.1122,-27.8915,Novo Barreiro,ADM2,DISTRICT
SM268114,-50.6364,-16.0481,Novo Brazil,ADM2,DISTRICT
SM304751,21.4,42.5667,Novo Brdo,ADM2,DISTRICT
SM265797,-52.984,-29.7616,Novo Cabrais,ADM2,DISTRICT
SM263703,-41.9698,-17.3923,Novo Cruzeiro,ADM2,DISTRICT
SM268115,-48.0704,-16.1254,Novo Gama,ADM2,DISTRICT
SM313217,19.0253,43.6622,Novo Goražde,ADM2:ADM3,DISTRICT
SM265798,-51.0567,-29.723,Novo Hamburgo,ADM2,DISTRICT
SM266744,-49.3026,-21.4521,Novo Horizonte,ADM2,DISTRICT
SM266223,-52.781,-26.4966,Novo Horizonte,ADM2,DISTRICT
SM267567,-42.1383,-12.8789,Novo Horizonte,ADM2,DISTRICT
SM263201,-57.2836,-11.3792,Novo Horizonte do Norte,ADM2,DISTRICT
SM266097,-62.0799,-11.7077,Novo Horizonte do Oeste,ADM2,DISTRICT
SM263071,-53.7364,-22.6307,Novo Horizonte do Sul,ADM2,DISTRICT
SM264605,-51.5387,-23.7756,Novo Itacolomi,ADM2,DISTRICT
SM267273,-46.5414,-11.7515,Novo Jardim,ADM2,DISTRICT
SM265393,-35.6195,-8.8809,Novo Lino,ADM2,DISTRICT
SM265799,-54.5361,-27.5548,Novo Machado,ADM2,DISTRICT
SM263203,-55.3135,-9.8366,Novo Mundo,ADM2,DISTRICT
SM267807,-40.7401,-5.5828,Novo Oriente,ADM2,DISTRICT
SM263704,-41.2293,-17.2517,Novo Oriente de Minas,ADM2,DISTRICT
SM265070,-41.9856,-6.5503,Novo Oriente do Piauí,ADM2,DISTRICT
SM268116,-49.761,-13.3836,Novo Planalto,ADM2,DISTRICT
SM264201,-55.5134,-7.754,Novo Progresso,ADM2,DISTRICT
SM264202,-50.5222,-4.7003,Novo Repartimento,ADM2,DISTRICT
SM9971,-50.9143,-12.3261,Novo Santo Antonio,ADM2,DISTRICT
SM265072,-41.9626,-5.3285,Novo Santo Antônio,ADM2,DISTRICT
SM9972,-53.2958,-15.0957,Novo Sao Joaquim (1998-),ADM2,DISTRICT
SM262486,22.8333,44.1167,Novo Selo,ADM2,DISTRICT
SM2532,22.9,41.4167,Novo Selo,ADM1,DISTRICT
SM265800,-53.1557,-27.5595,Novo Tiradentes,ADM2,DISTRICT
SM267568,-38.4021,-10.3479,Novo Triunfo,ADM2,DISTRICT
SM305711,17.1522,46.0983,Novo Virje,ADM2,DISTRICT
SM9973,-53.0404,-27.7456,Novo Xingu,ADM2,DISTRICT
SM17021,42.6667,50.5,Novoanninskiy Rayon,ADM2,DISTRICT
SM299509,30.8047,48.6637,Novoarkhanhel's'kyi,ADM2,DISTRICT
SM299951,38,47.1667,Novoazovs'kyi,ADM2,DISTRICT
SM299588,32.514,47.6904,Novobuz'kyi,ADM2,DISTRICT
SM292980,81.4167,52.1667,Novochikhinskiy rayon,ADM2,DISTRICT
SM9805,25.75,53.6667,Novogrudskiy Rayon (excl. Navahradak),ADM2,DISTRICT
SM299849,27.6277,50.592,Novohrad-Volyns'ka,ADM2,DISTRICT
SM299952,37.3314,48.2084,Novohrodivs'ka,ADM2,DISTRICT
SM294977,41.5833,51.0833,Novokhoperskiy rayon,ADM2,DISTRICT
SM17022,41.0447,45.088,Novokubanskiy Rayon,ADM2,DISTRICT
SM17023,87.0833,53.75,Novokuznetskiy Rayon,ADM2,DISTRICT
SM292849,46.5,43.1667,Novolakskiy rayon,ADM2,DISTRICT
SM18256,35.2585,48.6273,Novomoskovsk Raion,ADM2,DISTRICT
SM17024,37.4497,55.5752,Novomoskovsky Administrative Okrug,ADM2,DISTRICT
SM299819,35.8965,47.9787,Novomykola‹vs'kyi,ADM2,DISTRICT
SM299510,31.6501,48.7937,Novomyrhorods'kyi,ADM2,DISTRICT
SM294917,42.5833,51,Novonikolaevskiy rayon,ADM2,DISTRICT
SM299589,31.7717,47.312,Novoodes'kyi,ADM2,DISTRICT
SM293972,59,51.5,Novoorskiy rayon,ADM2,DISTRICT
SM294680,37.8333,50.75,Novooskol'skiy rayon,ADM2,DISTRICT
SM293384,40.6665,45.9919,Novopokrovskiy rayon,ADM2,DISTRICT
SM263705,-42.3889,-16.0173,Novorizonte,ADM2,DISTRICT
SM294142,29.4167,57.0833,Novorzhevskiy rayon,ADM2,DISTRICT
SM299648,34.3156,49.3356,Novosanzhars'kyi,ADM2,DISTRICT
SM299875,26.2677,48.2176,Novoselits'kyi,ADM2,DISTRICT
SM293446,91.25,55,Novoselovskiy rayon,ADM2,DISTRICT
SM294628,51.25,55.0833,Novosheshminskiy rayon,ADM2,DISTRICT
SM13215,81.3333,50.55,Novoshul'ba Audany,ADM2,DISTRICT
SM2533,80,55,Novosibirsk,ADM1,DISTRICT
SM17025,83,55,Novosibirskiy Rayon,ADM2,DISTRICT
SM294116,30,56.4167,Novosokol'nicheskiy rayon,ADM2,DISTRICT
SM293634,48.6667,57.0833,Novotor'yal'skiy rayon,ADM2,DISTRICT
SM300025,34.3339,46.3512,Novotro‹ts'kyi,ADM2,DISTRICT
SM299511,31.522,48.3169,Novoukra‹ns'kyi,ADM2,DISTRICT
SM299450,27.2716,48.8365,Novoushyts'kyi,ADM2,DISTRICT
SM294978,39.4167,51.5833,Novousmanskiy rayon,ADM2,DISTRICT
SM299801,24.1683,50.7331,Novovolyns'ka,ADM2,DISTRICT
SM300026,33.9256,47.5048,Novovorontsovs'kyi,ADM2,DISTRICT
SM295013,31.9333,52.5333,Novozybkov,ADM2,DISTRICT
SM295014,31.8333,52.5833,Novozybkovskiy rayon,ADM2,DISTRICT
SM275987,17,45.3333,Novska,ADM2,DISTRICT
SM296168,17.8351,48.7547,Nové Mesto nad Váhom,ADM2,DISTRICT
SM296148,18.1667,47.9833,Nové Zámky,ADM2,DISTRICT
SM271233,18,49.6667,Nový Jičín,ADM2,DISTRICT
SM9568,67.5707,32.2172,Now Bahar,ADM2,DISTRICT
SM288924,15.727,51.8422,Nowa Sól,ADM2,DISTRICT
SM302220,-95.6174,36.7985,Nowata,ADM2,DISTRICT
SM288771,19.5466,53.4357,Nowe Miasto,ADM2,DISTRICT
SM280619,127.0672,37.6605,Nowon,ADM2,DISTRICT
SM11791,51.6,36.4333,Nowshahr,ADM2,DISTRICT
SM375434,71.9809,33.9254,Nowshera,ADM2:ADM3,DISTRICT
SM288699,19.2427,54.2873,Nowy Dwór Gdański,ADM2,DISTRICT
SM288956,20.6641,52.4659,Nowy Dwór Mazowiecki,ADM2,DISTRICT
SM288932,20.73,49.5513,Nowy Sącz,ADM2,DISTRICT
SM288931,20.6982,49.6347,Nowy Sącz (City),ADM2,DISTRICT
SM288933,20.0431,49.4766,Nowy Targ,ADM2,DISTRICT
SM288796,16.1585,52.3484,Nowy Tomyśl,ADM2,DISTRICT
SM301473,-88.5698,33.1101,Noxubee,ADM2,DISTRICT
SM240649,17.9136,9.425,Noy,LANGUAGE,LANGUAGE
SM274549,10.0042,0.7802,Noya,ADM2,DISTRICT
SM283623,102.132,43.1688,Noyon,ADM2,DISTRICT
SM292723,46.4167,43.0833,Nozhay-Yurtovskiy rayon,ADM2,DISTRICT
SM14078,-8.3262,4.8309,Nrokwia-Wesldow District,ADM2,DISTRICT
SM18163,29.9717,-8.7561,Nsama District,ADM2,DISTRICT
SM2534,35.1667,-16.75,Nsanje,ADM1:ADM2,DISTRICT
SM240653,10.5993,6.6301,Nsari,LANGUAGE,LANGUAGE
SM11429,-0.3469,5.8274,Nsawam Adoagyiri,ADM2,DISTRICT
SM240656,31.3544,-15.3946,Nsenga,LANGUAGE,LANGUAGE
SM250589,30.24,-15.7,Nsenga,ETHNICITY,ETHNICITY
SM240657,10.58,7.22,Nshi,LANGUAGE,LANGUAGE
SM285095,8.0259,4.8177,Nsit Atai,ADM2,DISTRICT
SM285097,7.8931,4.9009,Nsit Ibom,ADM2,DISTRICT
SM285098,7.9488,4.7427,Nsit Ubium,ADM2,DISTRICT
SM250414,11.52,5.27,Nso,ETHNICITY,ETHNICITY
SM250414,11.52,5.27,Nso,ETHNICITY,ETHNICITY
SM250414,11,6,Nso,ETHNICITY,ETHNICITY
SM250414,11,6,Nso,ETHNICITY,ETHNICITY
SM275130,11.0365,1.935,Nsok-Nsomo,ADM2,DISTRICT
SM275130,11.0365,1.935,Nsok-Nsomo,ADM2,DISTRICT
SM275130,10.979,2.0022,Nsok-Nsomo,ADM2,DISTRICT
SM275130,10.979,2.0022,Nsok-Nsomo,ADM2,DISTRICT
SM244832,18.4978,-4.5506,Nsong,LANGUAGE,LANGUAGE
SM240661,16.7348,-10.5166,Nsongo,LANGUAGE,LANGUAGE
SM275144,11.2353,1.197,Nsork,ADM2,DISTRICT
SM284725,7.348,6.8533,Nsukka,ADM2,DISTRICT
SM256377,-4.33,30.82,Nsula,ETHNICITY,ETHNICITY
SM240668,0.5993,9.2397,Ntcham,LANGUAGE,LANGUAGE
SM250591,1,9,Ntcham,ETHNICITY,ETHNICITY
SM2535,34.75,-14.75,Ntcheu,ADM1:ADM2,DISTRICT
SM2536,33.908,-13.2957,Ntchisi,ADM1:ADM2,DISTRICT
SM261969,29.9916,-2.5905,Ntega,ADM2,DISTRICT
SM274581,11.4889,2.0778,Ntem,ADM2,DISTRICT
SM241091,9.086,9.007,Nteng,LANGUAGE,LANGUAGE
SM240670,0.0979,8.8316,Nterato,LANGUAGE,LANGUAGE
SM11210,31.3212,-25.8263,Ntfonjeni (excl. Emkhuzweni Mission),ADM2,DISTRICT
SM16345,16.1788,0.0482,Ntokou,ADM2,DISTRICT
SM240675,18.265,-1.9906,Ntomba,LANGUAGE,LANGUAGE
SM296706,31.2256,-26.635,Ntondozi,ADM2,DISTRICT
SM299381,30.3588,1.0067,Ntoroko,ADM1:ADM2,DISTRICT
SM459396,142.1093,-12.5021,Ntra'ngith,ETHNICITY,ETHNICITY
SM459387,141.6381,-13.1007,Ntrwa'ngayth,ETHNICITY,ETHNICITY
SM243623,17.1658,-6.2134,Ntsambaan,LANGUAGE,LANGUAGE
SM454505,10.5,2.3,Ntumu,ETHNICITY,ETHNICITY
SM2537,30.3048,-0.937,Ntungamo,ADM1:ADM2,DISTRICT
SM297007,99.0404,8.0606,Nua Khlong,ADM2,DISTRICT
SM370226,-8.7833,4.8667,Nua Point,ADM2:ADM3,DISTRICT
SM277124,82.5,20.6,Nuapada,ADM2,DISTRICT
SM256378,31.27,10.6,Nuba,ETHNICITY,ETHNICITY
SM240685,11.029,4.357,Nubaca,LANGUAGE,LANGUAGE
SM240688,34.7552,-0.1832,Nubi,LANGUAGE,LANGUAGE
SM268396,90.4733,27.5732,Nubi,ADM2,DISTRICT
SM251135,31.05,26.07,Nubian,ETHNICITY,ETHNICITY
SM290290,25.5543,44.8033,Nucet,ADM2,DISTRICT
SM292445,22.589,46.485,Nucet,ADM2,DISTRICT
SM290967,26.3177,44.7091,Nuci,ADM2,DISTRICT
SM301739,-98.0472,40.1764,Nuckolls,ADM2,DISTRICT
SM241147,-8.15,7.27,Nuclear Dan,DIALECT,LANGUAGE
SM240899,177.539,-18.1346,Nuclear Western Fijian,DIALECT,LANGUAGE
SM241049,-122.5,41,Nuclear Wintu,DIALECT:LANGUAGE,LANGUAGE
SM291271,24.7894,45.338,Nucsoara,ADM2,DISTRICT
SM302726,-97.5163,27.7351,Nueces,ADM2,DISTRICT
SM285938,5.5333,51.4667,Nuenen c.a.,ADM2,DISTRICT
SM256379,147.49,-42.62,Nuenonne,ETHNICITY,ETHNICITY
SM256379,147.49,-42.62,Nuenonne,ETHNICITY,ETHNICITY
SM256379,146.9977,-43.2255,Nuenonne,ETHNICITY,ETHNICITY
SM256379,146.9977,-43.2255,Nuenonne,ETHNICITY,ETHNICITY
SM241175,32.3829,8.1391,Nuer,LANGUAGE,LANGUAGE
SM274357,33.4435,8.3343,Nuer,ADM2,DISTRICT
SM289575,-55.6532,-27.2634,Nueva Alborada,ADM2,DISTRICT
SM275863,-88.69,15.08,Nueva Arcadia,ADM2,DISTRICT
SM275917,-87.1531,13.75,Nueva Armenia,ADM2,DISTRICT
SM289531,-57.2721,-25.1594,Nueva Colombia,ADM2,DISTRICT
SM275434,-91.2925,14.1249,Nueva Concepción,ADM2,DISTRICT
SM295614,-89.3047,14.1141,Nueva Concepción,ADM2,DISTRICT
SM2539,121,15.5833,Nueva Ecija,ADM1:ADM2,DISTRICT
SM295702,-87.8442,13.7972,Nueva Esparta,ADM2,DISTRICT
SM2540,-64,11,Nueva Esparta,ADM1,DISTRICT
SM15356,-54.6539,-24.5817,Nueva Esperanza,ADM2,DISTRICT
SM275770,-88.56,15.21,Nueva Frontera,ADM2,DISTRICT
SM15357,-56.6718,-23.86,Nueva Germania (1992-),ADM2,DISTRICT
SM295577,-88.4456,13.6374,Nueva Granada,ADM2,DISTRICT
SM10433,-74.3289,9.7359,Nueva Granada,ADM2,DISTRICT
SM295745,-88.3334,13.53,Nueva Guadalupe,ADM2,DISTRICT
SM285495,-84.45,11.6833,Nueva Guinea,ADM2,DISTRICT
SM300189,-57.1944,-34.3881,Nueva Helvecia,ADM2,DISTRICT
SM300189,-57.1944,-34.3881,Nueva Helvecia,ADM2,DISTRICT
SM300189,-57.2071,-34.3031,Nueva Helvecia,ADM2,DISTRICT
SM300189,-57.2071,-34.3031,Nueva Helvecia,ADM2,DISTRICT
SM289719,-57.4769,-25.7731,Nueva Italia,ADM2,DISTRICT
SM289681,-56.5661,-25.42,Nueva Londres,ADM2,DISTRICT
SM271021,-81.75,22.7667,Nueva Paz,ADM2,DISTRICT
SM283292,-101.3108,27.9605,Nueva Rosita,ADM2,DISTRICT
SM295657,-89.3667,13.6333,Nueva San Salvador,ADM2,DISTRICT
SM275319,-90.2663,14.3645,Nueva Santa Rosa,ADM2,DISTRICT
SM2541,-86.1667,13.7,Nueva Segovia,ADM1,DISTRICT
SM15358,-55.4904,-24.9742,Nueva Toledo,ADM2,DISTRICT
SM295615,-88.7943,14.082,Nueva Trinidad,ADM2,DISTRICT
SM2542,121.3333,16.3333,Nueva Vizcaya,ADM1:ADM2,DISTRICT
SM261019,-61.3333,-28.8333,Nueve de Julio,ADM2,DISTRICT
SM260942,-67.25,-41,Nueve de Julio,ADM2,DISTRICT
SM261088,-61.2763,-26.9533,Nueve de Julio,ADM2,DISTRICT
SM260808,-61,-35.5,Nueve de Julio,ADM2,DISTRICT
SM270991,-77.2667,21.55,Nuevitas,ADM2,DISTRICT
SM15975,-66.1921,18.2831,Nuevo Barrio (Bayamón),ADM2,DISTRICT
SM15976,-66.222,18.2897,Nuevo Barrio (Naranjito),ADM2,DISTRICT
SM283256,-107.8486,30.4895,Nuevo Casas Grandes,ADM2,DISTRICT
SM275771,-88.3673,14.9755,Nuevo Celilac,ADM2,DISTRICT
SM270794,-73.4497,5.3538,Nuevo Colón,ADM2,DISTRICT
SM295658,-89.2628,13.642,Nuevo Cuscatlán,ADM2,DISTRICT
SM295746,-88.474,13.7942,Nuevo Edén de San Juan,ADM2,DISTRICT
SM14358,-105.0211,24.8792,Nuevo Ideal,ADM2,DISTRICT
SM282656,-99.627,27.4545,Nuevo Laredo,ADM2,DISTRICT
SM2543,-99.75,25.5833,Nuevo León,ADM1,DISTRICT
SM282657,-99.2016,22.5011,Nuevo Morelos,ADM2,DISTRICT
SM282057,-102.1839,19.3922,Nuevo Paranguricutiro,ADM2,DISTRICT
SM275283,-91.9044,14.7939,Nuevo Progreso,ADM2,DISTRICT
SM275257,-91.6913,14.6182,Nuevo San Carlos,ADM2,DISTRICT
SM282058,-101.8613,19.1542,Nuevo Urecho,ADM2,DISTRICT
SM14359,-96.6215,17.2836,Nuevo Zoquiapam,ADM2,DISTRICT
SM292064,28.9245,45.1433,Nufaru,ADM2,DISTRICT
SM2544,49,8.1667,Nugaal,ADM1,DISTRICT
SM256380,-65.25,77.47,Nugsuak,ETHNICITY,ETHNICITY
SM241178,133.908,-31.9899,Nugunu (Australia),LANGUAGE,LANGUAGE
SM241179,11.1837,4.631,Nugunu (Cameroon),LANGUAGE,LANGUAGE
SM2545,177.1521,-7.2325,Nui,ADM1,DISTRICT
SM269367,99.1406,26.6174,Nujiang Lisu,ADM2,DISTRICT
SM241187,146.875,-6.4074,Nuk,LANGUAGE,LANGUAGE
SM256381,-71.81,2.56,Nukak,ETHNICITY,ETHNICITY
SM241185,-71.4629,2.6594,Nukak Makú,LANGUAGE,LANGUAGE
SM241188,146.969,-6.0177,Nukna,LANGUAGE,LANGUAGE
SM13716,27.7096,56.4328,Nuksu pagasts,ADM2,DISTRICT
SM288595,142.4167,-3.6667,Nuku,ADM2,DISTRICT
SM11251,-140.1794,-8.8514,Nuku-Hiva,ADM2,DISTRICT
SM256383,151.53,-26.41,Nuku-Nukubura,ETHNICITY,ETHNICITY
SM256383,151.53,-26.41,Nuku-Nukubura,ETHNICITY,ETHNICITY
SM256383,151.903,-26.4513,Nuku-Nukubura,ETHNICITY,ETHNICITY
SM256383,151.903,-26.4513,Nuku-Nukubura,ETHNICITY,ETHNICITY
SM2546,178.3711,-8.0003,Nukufetau,ADM1,DISTRICT
SM241194,-73.5044,-7.339,Nukuini,LANGUAGE,LANGUAGE
SM2547,179.8456,-9.3841,Nukulaelae,ADM1,DISTRICT
SM256382,27.44,-11,Nukulo,ETHNICITY,ETHNICITY
SM241190,159.4854,-4.5763,Nukumanu,LANGUAGE,LANGUAGE
SM2548,-171.83,-9.169,Nukunonu,ADM1,DISTRICT
SM458683,138.0694,-32.8178,Nukunu,ETHNICITY,ETHNICITY
SM18000,-175.3053,-21.1599,Nukunuku,ADM2,DISTRICT
SM241192,154.975,3.84,Nukuoro,LANGUAGE,LANGUAGE
SM14955,154.93,3.841,Nukuoro Municipality,ADM2,DISTRICT
SM241191,154.731,-3.4004,Nukuria,LANGUAGE,LANGUAGE
SM303511,59.6909,42.4804,Nukus,ADM2,DISTRICT
SM11252,-138.7817,-19.2807,Nukutavake,ADM2,DISTRICT
SM292898,102.8333,53.75,Nukutskiy rayon,ADM2,DISTRICT
SM284839,11.8367,9.4537,Numan,ADM2,DISTRICT
SM241197,8.4657,9.1656,Numana,LANGUAGE,LANGUAGE
SM241198,146.719,-6.3931,Numanggang,LANGUAGE,LANGUAGE
SM282059,-101.963,20.2726,Numarán,ADM2,DISTRICT
SM278099,139.1835,36.6737,Numata,ADM2,DISTRICT
SM279293,138.8667,35.0949,Numazu,ADM2,DISTRICT
SM241201,147.298,-7.5281,Numbami,LANGUAGE,LANGUAGE
SM241203,167.5935,-14.2302,Nume,LANGUAGE,LANGUAGE
SM241204,166.705,-22.2689,Numee,LANGUAGE,LANGUAGE
SM256386,134.9,-1.03,Numfor,ETHNICITY,ETHNICITY
SM256387,-117.91,41.11,Numic,ETHNICITY,ETHNICITY
SM256387,-114.15,38.16,Numic,ETHNICITY,ETHNICITY
SM370245,-8.9167,5.1667,Numopoh,ADM2:ADM3,DISTRICT
SM250603,-3,12,Nunama,ETHNICITY,ETHNICITY
SM256388,-159.73,68.97,Nunatagmiut,ETHNICITY,ETHNICITY
SM256388,-159.73,68.97,Nunatagmiut,ETHNICITY,ETHNICITY
SM256388,-159.73,68.97,Nunatagmiut,ETHNICITY,ETHNICITY
SM256388,-152,68,Nunatagmiut,ETHNICITY,ETHNICITY
SM256388,-152,68,Nunatagmiut,ETHNICITY,ETHNICITY
SM256388,-152,68,Nunatagmiut,ETHNICITY,ETHNICITY
SM256388,-151.71,68.18,Nunatagmiut,ETHNICITY,ETHNICITY
SM256388,-151.71,68.18,Nunatagmiut,ETHNICITY,ETHNICITY
SM256388,-151.71,68.18,Nunatagmiut,ETHNICITY,ETHNICITY
SM2549,-100.0781,66.0348,Nunavut,ADM1,DISTRICT
SM270895,-72.1014,5.5444,Nunchía,ADM2,DISTRICT
SM459102,141.8005,-37.3893,Nundadjali,ETHNICITY,ETHNICITY
SM256390,-62.04,57.03,Nunenumiut,ETHNICITY,ETHNICITY
SM241211,98.7333,27.2887,Nung (Myanmar),LANGUAGE,LANGUAGE
SM241212,104.286,20.8965,Nung (Viet Nam),LANGUAGE,LANGUAGE
SM256392,134.99,-14.61,Nungabuya,ETHNICITY,ETHNICITY
SM256392,134.99,-14.61,Nungabuya,ETHNICITY,ETHNICITY
SM256392,134.99,-14.61,Nungabuya,ETHNICITY,ETHNICITY
SM256392,135.98,-13.76,Nungabuya,ETHNICITY,ETHNICITY
SM256392,135.98,-13.76,Nungabuya,ETHNICITY,ETHNICITY
SM256392,135.98,-13.76,Nungabuya,ETHNICITY,ETHNICITY
SM256392,135.5886,-13.8698,Nungabuya,ETHNICITY,ETHNICITY
SM256392,135.5886,-13.8698,Nungabuya,ETHNICITY,ETHNICITY
SM256392,135.5886,-13.8698,Nungabuya,ETHNICITY,ETHNICITY
SM241220,131.641,-14.9109,Nungali,LANGUAGE,LANGUAGE
SM458874,130.8571,-15.5902,Nungali,ETHNICITY,ETHNICITY
SM458070,120.595,-30.2887,Nungara,ETHNICITY,ETHNICITY
SM261434,118.2177,-31.1371,Nungarin,ADM2,DISTRICT
SM241221,8.6216,8.9548,Nungu,LANGUAGE,LANGUAGE
SM256389,-166,60,Nunivak,ETHNICITY,ETHNICITY
SM256389,-166,60,Nunivak,ETHNICITY,ETHNICITY
SM256389,-166.3,60.03,Nunivak,ETHNICITY,ETHNICITY
SM256389,-166.3,60.03,Nunivak,ETHNICITY,ETHNICITY
SM285797,5.7877,52.3352,Nunspeet,ADM2,DISTRICT
SM458166,145.9524,-29.8717,Nuntukuri,ETHNICITY,ETHNICITY
SM276431,116.7,4.1333,Nunukan,ADM2,DISTRICT
SM239349,110.2222,27.0769,Nuoxi Yao,LANGUAGE,LANGUAGE
SM241234,90.534,27.4301,Nupbikha,LANGUAGE,LANGUAGE
SM251068,5.79,11.33,Nupe,ETHNICITY,ETHNICITY
SM251068,5.79,11.33,Nupe,ETHNICITY,ETHNICITY
SM251068,5.79,11.33,Nupe,ETHNICITY,ETHNICITY
SM251068,5.4165,9,Nupe,ETHNICITY,ETHNICITY
SM251068,5.4165,9,Nupe,ETHNICITY,ETHNICITY
SM251068,5.4165,9,Nupe,ETHNICITY,ETHNICITY
SM251068,6,9,Nupe,ETHNICITY,ETHNICITY
SM251068,6,9,Nupe,ETHNICITY,ETHNICITY
SM251068,6,9,Nupe,ETHNICITY,ETHNICITY
SM241236,5.4003,9.184,Nupe-Nupe-Tako,LANGUAGE,LANGUAGE
SM266745,-47.7514,-20.7326,Nuporanga,ADM2,DISTRICT
SM269918,-77.2875,5.6701,Nuquí,ADM2,DISTRICT
SM277588,51.9833,36.3167,Nur,ADM2,DISTRICT
SM13216,71.25,50,Nura Audany (excl. Tengiz),ADM2,DISTRICT
SM298424,36.736,37.184,Nurdağı,ADM2,DISTRICT
SM9569,70.731,34.6938,Nurgal (2005-),ADM2,DISTRICT
SM9570,70.4686,35.0743,Nurgaram,ADM2,DISTRICT
SM298566,37.4411,37.9664,Nurhak,ADM2,DISTRICT
SM294281,56.6667,54.75,Nurimanovskiy rayon,ADM2,DISTRICT
SM2550,70.8333,35.3,Nuristan,ADM1,DISTRICT
SM256396,73.48,34.42,Nuristani,ETHNICITY,ETHNICITY
SM256396,73.48,34.42,Nuristani,ETHNICITY,ETHNICITY
SM256396,71,36,Nuristani,ETHNICITY,ETHNICITY
SM256396,71,36,Nuristani,ETHNICITY,ETHNICITY
SM17026,50.7667,54.4333,Nurlat City,ADM2,DISTRICT
SM17027,50.75,54.5,Nurlatskiy Rayon (excl. Nurlat),ADM2,DISTRICT
SM303418,65.8511,39.65,Nurobod,ADM2,DISTRICT
SM305994,69.94,38.8,Nurobod,ADM2,DISTRICT
SM303550,65.8675,40.4829,Nurota,ADM2,DISTRICT
SM241247,128.776,-3.6773,Nusa Laut,LANGUAGE,LANGUAGE
SM295323,157.2852,-8.3192,Nusa Roviana,ADM2,DISTRICT
SM2551,117.5333,-8.74,Nusa Tenggara Barat,ADM1,DISTRICT
SM2552,121.0794,-8.6574,Nusa Tenggara Timur,ADM1,DISTRICT
SM298747,41.3414,37.1569,Nusaybin,ADM2,DISTRICT
SM292514,24.1752,47.0906,Nuseni,ADM2,DISTRICT
SM5128,65.6667,29.5,Nushki,ADM2:ADM3,DISTRICT
SM241249,98.8801,26.7292,Nusu,LANGUAGE,LANGUAGE
SM256397,150.95,-2.83,Nusu,ETHNICITY,ETHNICITY
SM256358,-127.62,50.2,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-127.62,50.2,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-127.62,50.2,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-127.62,50.2,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125.64,49.22,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125.64,49.22,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125.64,49.22,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125.64,49.22,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-124.62,48.77,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-124.62,48.77,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-124.62,48.77,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-124.62,48.77,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125,49,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125,49,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125,49,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125,49,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125.85,49.16,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125.85,49.16,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125.85,49.16,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125.85,49.16,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125.74,49.34,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125.74,49.34,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125.74,49.34,Nuu chah nulth,ETHNICITY,ETHNICITY
SM256358,-125.74,49.34,Nuu chah nulth,ETHNICITY,ETHNICITY
SM241250,-126.67,49.67,Nuu-chah-nulth,LANGUAGE,LANGUAGE
SM256399,-76.89,61.9,Nuvugmiut,ETHNICITY,ETHNICITY
SM375299,85.2487,27.9259,Nuwakot,ADM2:ADM3,DISTRICT
SM2553,80.7133,6.9785,Nuwara Eliya,ADM1:ADM2,DISTRICT
SM250602,32.01,6.89,Nuwer,ETHNICITY,ETHNICITY
SM250602,32.01,6.89,Nuwer,ETHNICITY,ETHNICITY
SM250602,32.01,6.89,Nuwer,ETHNICITY,ETHNICITY
SM250602,32.01,6.89,Nuwer,ETHNICITY,ETHNICITY
SM250602,31.25,6.8335,Nuwer,ETHNICITY,ETHNICITY
SM250602,31.25,6.8335,Nuwer,ETHNICITY,ETHNICITY
SM250602,31.25,6.8335,Nuwer,ETHNICITY,ETHNICITY
SM250602,31.25,6.8335,Nuwer,ETHNICITY,ETHNICITY
SM250602,32,8,Nuwer,ETHNICITY,ETHNICITY
SM250602,32,8,Nuwer,ETHNICITY,ETHNICITY
SM250602,32,8,Nuwer,ETHNICITY,ETHNICITY
SM250602,32,8,Nuwer,ETHNICITY,ETHNICITY
SM250602,31,8.5,Nuwer,ETHNICITY,ETHNICITY
SM250602,31,8.5,Nuwer,ETHNICITY,ETHNICITY
SM250602,31,8.5,Nuwer,ETHNICITY,ETHNICITY
SM250602,31,8.5,Nuwer,ETHNICITY,ETHNICITY
SM256400,-127.11,52.1,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127.11,52.1,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127.11,52.1,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127.11,52.1,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127.11,52.1,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127,52,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127,52,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127,52,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127,52,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127,52,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-126.58,52.32,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-126.58,52.32,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-126.58,52.32,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-126.58,52.32,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-126.58,52.32,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127.5,52.5,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127.5,52.5,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127.5,52.5,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127.5,52.5,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-127.5,52.5,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-126.5,52.33,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-126.5,52.33,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-126.5,52.33,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-126.5,52.33,Nuxalk,ETHNICITY,ETHNICITY
SM256400,-126.5,52.33,Nuxalk,ETHNICITY,ETHNICITY
SM241253,-97.7667,17.0147,Nuyoo,DIALECT,LANGUAGE
SM305543,18.8401,45.3322,Nuštar,ADM2,DISTRICT
SM296080,-56.9956,5.9288,Nw . Nickerie,ADM2,DISTRICT
SM296068,-55.0812,5.8819,Nw. Amsterdam,ADM2,DISTRICT
SM284761,7.1259,5.7103,Nwangele,ADM2,DISTRICT
SM256401,22.31,-15.34,Nwenyi,ETHNICITY,ETHNICITY
SM299418,31.8845,2.5097,Nwoya,ADM1:ADM2,DISTRICT
SM296797,16.4351,8.1693,Nya Pendé,ADM2,DISTRICT
SM296797,16.4351,8.1693,Nya Pendé,ADM2,DISTRICT
SM296797,16.55,7.99,Nya Pendé,ADM2,DISTRICT
SM296797,16.55,7.99,Nya Pendé,ADM2,DISTRICT
SM256402,127.21,-26.46,Nyaanyadjarra,ETHNICITY,ETHNICITY
SM256402,127.21,-26.46,Nyaanyadjarra,ETHNICITY,ETHNICITY
SM256402,127.21,-26.46,Nyaanyadjarra,ETHNICITY,ETHNICITY
SM256402,127.27,-25.32,Nyaanyadjarra,ETHNICITY,ETHNICITY
SM256402,127.27,-25.32,Nyaanyadjarra,ETHNICITY,ETHNICITY
SM256402,127.27,-25.32,Nyaanyadjarra,ETHNICITY,ETHNICITY
SM256402,127.3535,-25.8004,Nyaanyadjarra,ETHNICITY,ETHNICITY
SM256402,127.3535,-25.8004,Nyaanyadjarra,ETHNICITY,ETHNICITY
SM256402,127.3535,-25.8004,Nyaanyadjarra,ETHNICITY,ETHNICITY
SM261994,29.811,-3.4495,Nyabihanga,ADM2,DISTRICT
SM295158,29.5068,-1.6528,Nyabihu,ADM1:ADM2,DISTRICT
SM262081,30.1412,-3.2645,Nyabikere,ADM2,DISTRICT
SM262035,29.4752,-3.4576,Nyabiraba,ADM2,DISTRICT
SM262016,30.2704,-3.5781,Nyabitsinda,ADM2,DISTRICT
SM241262,-6.8443,6.8762,Nyabwa,LANGUAGE,LANGUAGE
SM14079,-10.0833,6.8333,Nyafokole,ADM2,DISTRICT
SM459236,145.4523,-16.5483,Nyagali,ETHNICITY,ETHNICITY
SM295154,30.3724,-1.3146,Nyagatare,ADM1:ADM2,DISTRICT
SM256403,121.31,-32.97,Nyaginyagi,ETHNICITY,ETHNICITY
SM256403,121.31,-32.97,Nyaginyagi,ETHNICITY,ETHNICITY
SM256403,118.7551,-32.3909,Nyaginyagi,ETHNICITY,ETHNICITY
SM256403,118.7551,-32.3909,Nyaginyagi,ETHNICITY,ETHNICITY
SM241270,106.543,15.1034,Nyaheun,LANGUAGE,LANGUAGE
SM241271,101.515,15.8909,Nyahkur,LANGUAGE,LANGUAGE
SM262022,29.3798,-3.376,Nyakabiga,ADM2,DISTRICT
SM8843,33.64,-9.57,Nyakyusa,ETHNICITY,ETHNICITY
SM8843,33.64,-9.57,Nyakyusa,ETHNICITY,ETHNICITY
SM8843,33.64,-9.57,Nyakyusa,ETHNICITY,ETHNICITY
SM8843,34,-9,Nyakyusa,ETHNICITY,ETHNICITY
SM8843,34,-9,Nyakyusa,ETHNICITY,ETHNICITY
SM8843,34,-9,Nyakyusa,ETHNICITY,ETHNICITY
SM8843,34,-9.5,Nyakyusa,ETHNICITY,ETHNICITY
SM8843,34,-9.5,Nyakyusa,ETHNICITY,ETHNICITY
SM8843,34,-9.5,Nyakyusa,ETHNICITY,ETHNICITY
SM241275,33.7763,-9.7102,Nyakyusa-Ngonde,LANGUAGE,LANGUAGE
SM17905,24.8902,12.045,Nyala (pre-2012),ADM2,DISTRICT
SM241280,30.0201,1.921,Nyali,LANGUAGE,LANGUAGE
SM241298,10.8365,9.1802,Nyam,LANGUAGE,LANGUAGE
SM295145,29.468,-2.4082,Nyamagabe,ADM1:ADM2,DISTRICT
SM299133,32.8899,-2.5253,Nyamagana,ADM2,DISTRICT
SM241287,119.693,-21.6092,Nyamal,LANGUAGE,LANGUAGE
SM256405,119.64,-20.73,Nyamal,ETHNICITY,ETHNICITY
SM256405,119.64,-20.73,Nyamal,ETHNICITY,ETHNICITY
SM256405,120.0294,-21.2202,Nyamal,ETHNICITY,ETHNICITY
SM256405,120.0294,-21.2202,Nyamal,ETHNICITY,ETHNICITY
SM295159,29.1479,-2.3615,Nyamasheke,ADM1:ADM2,DISTRICT
SM241293,30.9156,-1.6096,Nyambo,LANGUAGE,LANGUAGE
SM250933,30.9,-1.06,Nyambo,ETHNICITY,ETHNICITY
SM2554,35,-0.75,Nyamira,ADM1:ADM2,DISTRICT
SM262002,29.881,-2.8136,Nyamurenza,ADM2,DISTRICT
SM241295,30.3658,5.8971,Nyamusa-Molo,LANGUAGE,LANGUAGE
SM241291,32.3163,-9.1126,Nyamwanga,LANGUAGE,LANGUAGE
SM241292,32.9445,-5.0856,Nyamwezi,LANGUAGE,LANGUAGE
SM250610,31.85,-5.58,Nyamwezi,ETHNICITY,ETHNICITY
SM250610,31.85,-5.58,Nyamwezi,ETHNICITY,ETHNICITY
SM250610,33,-5,Nyamwezi,ETHNICITY,ETHNICITY
SM250610,33,-5,Nyamwezi,ETHNICITY,ETHNICITY
SM14080,-7.8333,4.6667,Nyanbo,ADM2,DISTRICT
SM2555,36.5,-0.344,Nyandarua,ADM1:ADM2,DISTRICT
SM280070,34.981,-0.159,Nyando,ADM2,DISTRICT
SM293589,40.6667,62,Nyandomskiy rayon,ADM2,DISTRICT
SM241305,14.94,-15.9616,Nyaneka,LANGUAGE,LANGUAGE
SM241313,33.7365,3.4997,Nyang'i,LANGUAGE,LANGUAGE
SM299220,32.6325,-3.1375,Nyang'wale,ADM2,DISTRICT
SM241304,28.3155,-1.2936,Nyanga,LANGUAGE,LANGUAGE
SM305236,32.7656,-17.9295,Nyanga,ADM2,DISTRICT
SM16346,11.8496,-2.7627,Nyanga,ADM2,DISTRICT
SM2556,11,-3,Nyanga,ADM1,DISTRICT
SM256406,28.12,0.08,Nyanga,ETHNICITY,ETHNICITY
SM241303,29.1805,2.9763,Nyanga-li,LANGUAGE,LANGUAGE
SM256407,121.38,-19.64,Nyangamada,ETHNICITY,ETHNICITY
SM256407,121.38,-19.64,Nyangamada,ETHNICITY,ETHNICITY
SM256407,122.029,-20.4794,Nyangamada,ETHNICITY,ETHNICITY
SM256407,122.029,-20.4794,Nyangamada,ETHNICITY,ETHNICITY
SM241315,35.6232,5.3981,Nyangatom,LANGUAGE,LANGUAGE
SM458443,153.4218,-28.9215,Nyangbal,ETHNICITY,ETHNICITY
SM256408,30.71,4.15,Nyangbara,ETHNICITY,ETHNICITY
SM241302,0.3715,6.8265,Nyangbo,LANGUAGE,LANGUAGE
SM241301,121.391,-20.6271,Nyangumarta,LANGUAGE,LANGUAGE
SM241308,32.8108,-14.8047,Nyanja,LANGUAGE,LANGUAGE
SM250595,36,-16,Nyanja,ETHNICITY,ETHNICITY
SM241311,11.6757,6.6346,Nyanjang,DIALECT,LANGUAGE
SM241307,30.548,-0.4636,Nyankole,LANGUAGE,LANGUAGE
SM262066,29.8302,-3.5681,Nyanrusange,ADM2,DISTRICT
SM295146,29.7945,-2.3369,Nyanza,ADM1:ADM2,DISTRICT
SM261976,29.6466,-4.3025,Nyanza-Lac,ADM2,DISTRICT
SM241321,-5.1169,9.6693,Nyarafolo Senoufo,LANGUAGE,LANGUAGE
SM458100,125.2755,-18.623,Nyardi,ETHNICITY,ETHNICITY
SM454538,31,11,Nyaro,ETHNICITY,ETHNICITY
SM295165,30.0213,-1.991,Nyarugenge,ADM1:ADM2,DISTRICT
SM295147,29.5237,-2.6984,Nyaruguru,ADM1:ADM2,DISTRICT
SM262647,26.6667,53.25,Nyas'vizh,ADM2,DISTRICT
SM299164,34.7658,-11.1574,Nyasa,ADM2,DISTRICT
SM250537,35,-13,Nyasa,ETHNICITY,ETHNICITY
SM241330,34.6657,-5.1226,Nyaturu,LANGUAGE,LANGUAGE
SM15106,95.0364,21.0881,Nyaung-U District,ADM2,DISTRICT
SM241334,104.151,17.1213,Nyaw,DIALECT,LANGUAGE
SM241335,146.216,-18.8743,Nyawaygi,LANGUAGE,LANGUAGE
SM292759,59.5833,56,Nyazepetrovskiy rayon,ADM2,DISTRICT
SM271742,10.7031,55.2947,Nyborg (2007-),ADM2,DISTRICT
SM296664,15.8897,56.8244,Nybro,ADM2,DISTRICT
SM301775,-116.4719,38.0424,Nye,ADM2,DISTRICT
SM241340,18.2515,-15.709,Nyemba,LANGUAGE,LANGUAGE
SM256412,17.87,-14.36,Nyemba,ETHNICITY,ETHNICITY
SM14081,-7.9809,5.1462,Nyenawliken District,ADM2,DISTRICT
SM241344,22.4787,-17.2314,Nyengo,LANGUAGE,LANGUAGE
SM256413,21.94,-15.28,Nyengo,ETHNICITY,ETHNICITY
SM2557,36.95,-0.42,Nyeri,ADM1:ADM2,DISTRICT
SM256414,40.32,0.97,Nyeri,ETHNICITY,ETHNICITY
SM241349,104.33,14.8817,Nyeu,LANGUAGE,LANGUAGE
SM256416,118.18,-21.61,Nyiabali,ETHNICITY,ETHNICITY
SM256416,118.18,-21.61,Nyiabali,ETHNICITY,ETHNICITY
SM256416,120.1965,-22.9242,Nyiabali,ETHNICITY,ETHNICITY
SM256416,120.1965,-22.9242,Nyiabali,ETHNICITY,ETHNICITY
SM256417,124.72,-19.14,Nyigena,ETHNICITY,ETHNICITY
SM256417,124.72,-19.14,Nyigena,ETHNICITY,ETHNICITY
SM256417,124.0608,-18.2594,Nyigena,ETHNICITY,ETHNICITY
SM256417,124.0608,-18.2594,Nyigena,ETHNICITY,ETHNICITY
SM241351,124.348,-17.9594,Nyigina,LANGUAGE,LANGUAGE
SM241352,33.0995,-9.5372,Nyiha (Malawi),LANGUAGE,LANGUAGE
SM241353,32.9057,-9.4472,Nyiha (Tanzania),LANGUAGE,LANGUAGE
SM256418,33.65,-8.84,Nyika,ETHNICITY,ETHNICITY
SM241356,33.481,-10.0231,Nyika (Malawi and Zambia),LANGUAGE,LANGUAGE
SM241357,31.8127,-8.133,Nyika (Tanzania),LANGUAGE,LANGUAGE
SM250615,29,12,Nyima,ETHNICITY,ETHNICITY
SM250615,29,12,Nyima,ETHNICITY,ETHNICITY
SM250615,29.5,12.3,Nyima,ETHNICITY,ETHNICITY
SM250615,29.5,12.3,Nyima,ETHNICITY,ETHNICITY
SM305176,30.5971,-14.3463,Nyimba,ADM2,DISTRICT
SM241366,146.627,-2.1143,Nyindrou,LANGUAGE,LANGUAGE
SM241364,28.6434,-3.038,Nyindu,LANGUAGE,LANGUAGE
SM269362,94.4241,29.6009,Nyingtri,ADM2,DISTRICT
SM11584,22.0138,47.5787,Nyiradonyi Jaras,ADM2,DISTRICT
SM269657,29.286,-1.5552,Nyiragongo,ADM2,DISTRICT
SM241369,93.6755,27.2783,Nyishi-Hill Miri,LANGUAGE,LANGUAGE
SM241368,103.5981,24.6873,Nyisu,LANGUAGE,LANGUAGE
SM458135,129.6823,-16.3392,Nyiwanawu,ETHNICITY,ETHNICITY
SM239825,120.831,-22.8558,Nyiyaparli,LANGUAGE,LANGUAGE
SM296471,17.4063,59.1948,Nykvarn,ADM2,DISTRICT
SM296465,16.8995,58.8176,Nyköping,ADM2,DISTRICT
SM271193,15.1667,50.1667,Nymburk,ADM2,DISTRICT
SM296472,17.8869,58.9561,Nynäshamn,ADM2,DISTRICT
SM241374,10.5,4.75,Nyokon,LANGUAGE,LANGUAGE
SM241375,33.9181,0.8807,Nyole,LANGUAGE,LANGUAGE
SM250947,33.78,1.7,Nyole,ETHNICITY,ETHNICITY
SM269005,6.2218,46.42,Nyon,ADM2,DISTRICT
SM241378,11.0615,7.2742,Nyong,LANGUAGE,LANGUAGE
SM269513,10.8333,3.8333,Nyong et Kéllé,ADM2,DISTRICT
SM269514,12.4,4,Nyong et Mfoumou,ADM2,DISTRICT
SM269506,11.5,3.4,Nyong et So'o,ADM2,DISTRICT
SM289051,126.6161,39.9694,Nyongwon,ADM2,DISTRICT
SM241380,34.6166,0.0752,Nyore,LANGUAGE,LANGUAGE
SM14082,-7.8681,5.0087,Nyorken District,ADM2,DISTRICT
SM241381,31.3493,0.9529,Nyoro,LANGUAGE,LANGUAGE
SM289002,17.3355,50.4605,Nysa,ADM2,DISTRICT
SM241386,122.851,-16.9129,Nyulnyul,LANGUAGE,LANGUAGE
SM458615,122.7057,-17.028,Nyulnyul,ETHNICITY,ETHNICITY
SM241389,118.151,-33.1199,Nyunga,LANGUAGE,LANGUAGE
SM241390,33.0233,-16.4757,Nyungwe,LANGUAGE,LANGUAGE
SM294315,118.4167,63.25,Nyurbinskiy rayon,ADM2,DISTRICT
SM459284,142.3132,-12.4089,Nyuwathayi,ETHNICITY,ETHNICITY
SM459204,146.392,-19.1474,Nywaigi,ETHNICITY,ETHNICITY
SM299885,34.768,45.4843,Nyzhn'ohirs'kyi,ADM2,DISTRICT
SM300027,34.3768,46.8477,Nyzhn'osirohoz'kyi,ADM2,DISTRICT
SM276183,22.1176,47.7755,Nyírbátor,ADM2,DISTRICT
SM276184,21.6446,47.9769,Nyíregyháza,ADM1:ADM2,DISTRICT
SM289188,125.75,39.8333,Nyŏngbyŏn,ADM2,DISTRICT
SM241392,20.1945,-4.1022,Nzadi,LANGUAGE,LANGUAGE
SM241393,15.6675,7.6675,Nzakambay,LANGUAGE,LANGUAGE
SM241394,22.8254,4.7957,Nzakara,LANGUAGE,LANGUAGE
SM250617,24.31,5.39,Nzakara,ETHNICITY,ETHNICITY
SM16347,11.4291,-3.8842,Nzambi,ADM2,DISTRICT
SM241396,13.3343,10.0666,Nzanyi,LANGUAGE,LANGUAGE
SM299188,33.0479,-4.3386,Nzega,ADM2,DISTRICT
SM4407,-2.299,5.124,Nzema East (2008-),ADM2,DISTRICT
SM241400,-2.8112,5.1785,Nzima,LANGUAGE,LANGUAGE
SM2559,44.4167,-12.25,Nzwani,ADM1,DISTRICT
SM275005,-8.783,7.945,Nzérékoré,ADM1:ADM2,DISTRICT
SM2558,-9.0694,8.2083,Nzérékoré,ADM1,DISTRICT
SM241251,19.0841,-29.7054,N||ng,LANGUAGE,LANGUAGE
SM250606,19.82,-27.37,N||ng,ETHNICITY,ETHNICITY
SM250606,19.82,-27.37,N||ng,ETHNICITY,ETHNICITY
SM250606,21.15,-28.25,N||ng,ETHNICITY,ETHNICITY
SM250606,21.15,-28.25,N||ng,ETHNICITY,ETHNICITY
SM304565,105.4838,22.4752,Nà Hang,ADM2,DISTRICT
SM239199,106.435,22.4189,Ná-Meo,LANGUAGE,LANGUAGE
SM271223,16.1667,50.4167,Náchod,ADM2,DISTRICT
SM282584,-108.8245,29.676,Nácori Chico,ADM2,DISTRICT
SM296189,19.4767,49.4065,Námestovo,ADM2,DISTRICT
SM270089,-75.7868,2.5785,Nátaga,ADM2,DISTRICT
SM296650,14.6404,57.6089,Nässjö,ADM2,DISTRICT
SM271735,11.7462,55.2586,Næstved (2007-),ADM2,DISTRICT
SM284014,-7.3026,16.4328,Néma,ADM2,DISTRICT
SM235421,164.193,-20.4035,Nêlêmwa-Nixumwak,LANGUAGE,LANGUAGE
SM265594,-35.1691,-6.0624,Nísia Floresta,ADM2,DISTRICT
SM2464,19.5833,48,Nógrád,ADM1,DISTRICT
SM304511,105.6828,19.6138,Nông Cống,ADM2,DISTRICT
SM304385,108.005,15.6713,Nông Sơn,ADM2,DISTRICT
SM274148,26.525,58.242,Nõo,ADM2,DISTRICT
SM283848,96.9629,48.8684,Nömrög,ADM2,DISTRICT
SM304386,108.5744,15.4335,Núi Thành,ADM2,DISTRICT
SM241240,-70.9559,-2.345,Nüpode Huitoto,LANGUAGE,LANGUAGE
SM303956,105.0557,8.8153,Năm Căn,ADM2,DISTRICT
SM279696,130.7459,33.7512,Nōgata,ADM2,DISTRICT
SM304258,103.0113,22.2045,Nậm Nhùn,ADM2,DISTRICT
SM304042,102.7771,21.9201,Nậm Pồ,ADM2,DISTRICT
SM300852,-95.6249,43.0838,O'Brien,ADM2,DISTRICT
SM261089,-60.6667,-27.25,O'Higgins,ADM2,DISTRICT
SM241435,7.1384,4.9413,O'chi'chi',LANGUAGE,LANGUAGE
SM241448,104.106,19.3497,O'du,LANGUAGE,LANGUAGE
SM303455,69.3333,41.0833,O'rtachirchiq,ADM2,DISTRICT
SM303483,70.8333,40.3333,O'zbekiston,ADM2,DISTRICT
SM305103,29.0525,-31.4674,O.R.Tambo,ADM2,DISTRICT
SM301314,-83.3858,42.6604,Oakland,ADM2,DISTRICT
SM12592,-76.7956,18.0356,Oaklands,ADM2,DISTRICT
SM10190,-100.0587,50.1505,Oakview,ADM2,DISTRICT
SM290456,28.1112,45.9065,Oancea,ADM2,DISTRICT
SM291272,24.9694,44.7573,Oarja,ADM2,DISTRICT
SM291015,23.1,47.45,Oarta De Jos,ADM2,DISTRICT
SM2560,-96.5,17,Oaxaca,ADM1,DISTRICT
SM14360,-96.7356,17.1135,Oaxaca de Juarez,ADM2,DISTRICT
SM256421,74,62,Ob Ostyak,ETHNICITY,ETHNICITY
SM285053,3.484,6.9691,Obafemi-Owode,ADM2,DISTRICT
SM279645,135.7,35.45,Obama,ADM2,DISTRICT
SM279549,140.4768,38.5936,Obanazawa,ADM2,DISTRICT
SM270622,-75.9488,4.5959,Obando,ADM2,DISTRICT
SM241405,9.2716,6.4794,Obanliku,LANGUAGE,LANGUAGE
SM285392,9.307,6.5373,Obanliku,ADM2,DISTRICT
SM291435,24.3219,43.8906,Obarsia,ADM2,DISTRICT
SM291088,22.9708,44.1751,Obarsia De Camp,ADM2,DISTRICT
SM291086,22.6833,45.0167,Obarsia-closani,ADM2,DISTRICT
SM4160,15.8738,46.6945,Obcina Apace,ADM1,DISTRICT
SM4127,14.1101,46.3683,Obcina Bled (2006-),ADM1,DISTRICT
SM4158,16.0116,46.405,Obcina Gorisnica  (2006-),ADM1,DISTRICT
SM4131,15.4606,45.9514,Obcina Krsko (2006-),ADM1,DISTRICT
SM4151,16.141,46.4363,Obcina Ormoz  (2006-),ADM1,DISTRICT
SM4155,15.5979,46.3028,Obcina Poljcane,ADM1,DISTRICT
SM4129,14.9052,46.3275,Obcina Recica ob Savinji,ADM1,DISTRICT
SM4133,13.6756,45.8936,Obcina Rence-Vogrsko,ADM1,DISTRICT
SM4136,15.09,45.9826,Obcina Sentrupert,ADM1,DISTRICT
SM4140,15.245,45.883,Obcina Smarjeske Toplice,ADM1,DISTRICT
SM4145,14.8426,46.0263,Obcina Smartno pri Litiji,ADM1,DISTRICT
SM4152,16.2545,46.3998,Obcina Sredisce ob Dravi,ADM1,DISTRICT
SM4141,15.0887,45.7774,Obcina Straza,ADM1,DISTRICT
SM4153,16.062,46.4794,Obcina Sveti Tomaz,ADM1,DISTRICT
SM241408,67.09,66.31,Obdorsk Khanty,LANGUAGE,LANGUAGE
SM260013,63.2504,34.3606,Obe,ADM2,DISTRICT
SM17922,7.7455,47.192,Oberaargau,ADM2,DISTRICT
SM268942,8.5177,47.6989,Oberklettgau,ADM2,DISTRICT
SM261443,149.818,-33.8453,Oberon,ADM2,DISTRICT
SM261790,16.5059,47.5018,Oberpullendorf,ADM2,DISTRICT
SM17923,7.358,46.4793,Obersimmental-Saanen District,ADM2,DISTRICT
SM261791,16.2086,47.2885,Oberwart,ADM2,DISTRICT
SM260902,-55.0833,-27.55,Oberá,ADM2,DISTRICT
SM2562,14,48.25,Oberösterreich,ADM1,DISTRICT
SM285011,8.7277,8.3474,Obi,ADM2,DISTRICT
SM285343,8.2939,7.0218,Obi,ADM2,DISTRICT
SM256423,127.82,-1.54,Obi,ETHNICITY,ETHNICITY
SM278149,143.0306,42.7574,Obihiro,ADM2,DISTRICT
SM304752,21.0333,42.6833,Obilić,ADM2,DISTRICT
SM285202,6.9891,4.8315,Obio/Akp,ADM2,DISTRICT
SM302606,-89.1488,36.3583,Obion,ADM2,DISTRICT
SM278150,141.8375,44.0647,Obira,ADM2,DISTRICT
SM241416,-120.669,35.2873,Obispeño,LANGUAGE,LANGUAGE
SM303617,-71.4656,8.8192,Obispo Ramos de Lora,ADM2,DISTRICT
SM262764,-63.3595,-16.8036,Obispo Santistevan,ADM2,DISTRICT
SM303852,-70.1045,8.6061,Obispos,ADM2,DISTRICT
SM289576,-55.7084,-26.9218,Obligado,ADM2,DISTRICT
SM241417,14.29,8.1894,Oblo,LANGUAGE,LANGUAGE
SM295073,132,49,Obluchenskiy rayon,ADM2,DISTRICT
SM10249,26.3499,5.4991,Obo,ADM2,DISTRICT
SM241423,125.09,7.2914,Obo Manobo,LANGUAGE,LANGUAGE
SM271654,43.17,12.22,Obock,ADM2,DISTRICT
SM2563,43.0837,12.2501,Obock,ADM1:ADMX,DISTRICT
SM241419,137.924,-2.8803,Obokuitai,LANGUAGE,LANGUAGE
SM285132,4.7628,7.787,Obokun,ADM2,DISTRICT
SM241421,7.8199,4.6285,Obolo,LANGUAGE,LANGUAGE
SM299473,30.5054,50.5401,Obolons'kyi,ADM2,DISTRICT
SM284648,7.4558,5.1271,Oboma Ngwa,ADM2,DISTRICT
SM288797,16.8544,52.7171,Oborniki,ADM2,DISTRICT
SM285099,7.6046,5.2467,Obot Akara,ADM2,DISTRICT
SM284762,7.3601,5.5493,Obowo,ADM2,DISTRICT
SM293507,36.1667,51.25,Oboyanskiy rayon,ADM2,DISTRICT
SM290035,22.2557,45.4687,Obreja,ADM2,DISTRICT
SM17578,-60.9731,13.7683,Obrier,ADM2,DISTRICT
SM276026,15.6825,44.2001,Obrovac,ADM2,DISTRICT
SM10055,24.6667,42.6833,Obshtina Karlovo (2003-),ADM2,DISTRICT
SM10056,24.7795,42.0373,Obshtina Kuklen,ADM2,DISTRICT
SM10057,24.6833,42.0333,Obshtina Rodopi (2001-),ADM2,DISTRICT
SM10058,24.7431,42.6521,Obshtina Sopot,ADM2,DISTRICT
SM10059,23.9333,41.9667,Obshtina Velingrad (2015-),ADM2,DISTRICT
SM274845,-1.6658,6.2113,Obuasi Municipal,ADM2,DISTRICT
SM285393,8.3788,5.9429,Obubra,ADM2,DISTRICT
SM285394,9.0454,6.5433,Obudu,ADM2,DISTRICT
SM18257,30.6667,50.1667,Obukhiv Raion (excl. Rzhyshchiv),ADM2,DISTRICT
SM299487,30.6329,50.1143,Obukhivs'kyi,ADM2,DISTRICT
SM241426,7.1167,4.7291,Obulom,LANGUAGE,LANGUAGE
SM241427,7.1167,4.7291,Obulomic,FAMILY,LANGUAGE
SM288637,145.9733,-6.5543,Obura-Wonenara,ADM2,DISTRICT
SM268933,8.2656,46.8798,Obwalden,ADM2,DISTRICT
SM2564,8.2098,46.8587,Obwalden,ADM1,DISTRICT
SM241429,-72.1422,-2.159,Ocaina,LANGUAGE,LANGUAGE
SM256425,-82.37,28.64,Ocale,ETHNICITY,ETHNICITY
SM270491,-73.1368,6.3438,Ocamonte,ADM2,DISTRICT
SM282658,-99.2828,22.8361,Ocampo,ADM2,DISTRICT
SM282060,-100.3422,19.5844,Ocampo,ADM2,DISTRICT
SM283257,-108.2216,28.1583,Ocampo,ADM2,DISTRICT
SM283293,-102.836,27.9768,Ocampo,ADM2,DISTRICT
SM281548,-101.4681,21.6005,Ocampo,ADM2,DISTRICT
SM281505,-105.76,26.5525,Ocampo,ADM2,DISTRICT
SM267808,-38.5158,-4.5355,Ocara,ADM2,DISTRICT
SM266746,-49.9517,-22.4303,Ocauçu,ADM2,DISTRICT
SM270377,-73.3492,8.2164,Ocaña,ADM2,DISTRICT
SM2565,120.9167,13,Occidental Mindoro,ADM1:ADM2,DISTRICT
SM241430,6.8298,44.1415,Occitan,LANGUAGE,LANGUAGE
SM2566,1.6356,44.0272,Occitanie,ADM1,DISTRICT
SM256426,2.44,42.47,Occitanie,ETHNICITY,ETHNICITY
SM256426,2.44,42.47,Occitanie,ETHNICITY,ETHNICITY
SM256426,4,44,Occitanie,ETHNICITY,ETHNICITY
SM256426,4,44,Occitanie,ETHNICITY,ETHNICITY
SM301819,-74.25,39.866,Ocean,ADM2,DISTRICT
SM301315,-86.3168,43.6818,Oceana,ADM2,DISTRICT
SM299590,31.5454,46.615,Ochakivs'kyi,ADM2,DISTRICT
SM11332,41.4844,42.748,Ochamchira District (1995-),ADM2,DISTRICT
SM13051,132.5314,34.9463,Ochi Gun,ADM2,DISTRICT
SM302727,-100.8157,36.2784,Ochiltree,ADM2,DISTRICT
SM12593,-77.1342,18.3984,Ocho Rios,ADM2,DISTRICT
SM296625,16.57,60.9418,Ockelbo,ADM2,DISTRICT
SM290705,25.4256,46.1624,Ocland,ADM2,DISTRICT
SM290036,21.775,45.342,Ocna De Fier,ADM2,DISTRICT
SM289814,23.8407,46.3847,Ocna Mures,ADM2,DISTRICT
SM291738,24.001,45.8831,Ocna Sibiului,ADM2,DISTRICT
SM291016,23.9127,47.7749,Ocna Sugatag,ADM2,DISTRICT
SM290291,25.5559,44.9901,Ocnita,ADM2,DISTRICT
SM2567,27.5,48.4167,Ocniţa,ADM1,DISTRICT
SM289815,23.4658,46.4964,Ocolis,ADM2,DISTRICT
SM302462,-83.0659,34.7535,Oconee,ADM2,DISTRICT
SM300390,-83.4371,33.8349,Oconee,ADM2,DISTRICT
SM303260,-88.2206,44.9985,Oconto,ADM2,DISTRICT
SM14361,-91.802,16.8721,Ocosingo (1999-),ADM2,DISTRICT
SM285473,-86.4912,13.642,Ocotal,ADM2,DISTRICT
SM282240,-97.6551,19.549,Ocotepec,ADM2,DISTRICT
SM283171,-93.1724,17.2192,Ocotepec,ADM2,DISTRICT
SM241438,-97.7632,17.1442,Ocotepec Mixtec,LANGUAGE,LANGUAGE
SM275741,-89.1833,14.4333,Ocotepeque,ADM2,DISTRICT
SM2568,-89,14.5,Ocotepeque,ADM1,DISTRICT
SM14363,-96.674,16.7815,Ocotlan de Morelos,ADM2,DISTRICT
SM281829,-102.7389,20.3906,Ocotlán,ADM2,DISTRICT
SM241439,-96.7263,16.8258,Ocotlán Zapotec,LANGUAGE,LANGUAGE
SM281947,-99.4352,19.2729,Ocoyoacac,ADM2,DISTRICT
SM282241,-98.316,18.957,Ocoyucan,ADM2,DISTRICT
SM283172,-93.4343,16.7862,Ocozocoautla de Espinosa,ADM2,DISTRICT
SM286795,-77.4295,-10.4891,Ocros,ADM2,DISTRICT
SM281948,-99.4091,18.9904,Ocuilan,ADM2,DISTRICT
SM282105,-98.7804,18.8825,Ocuituco,ADM2,DISTRICT
SM303824,-67.7687,10.4613,Ocumare de la Costa de Oro,ADM2,DISTRICT
SM269503,10.25,2.75,Océan,ADM2,DISTRICT
SM286709,-80.7975,7.9064,Ocú,ADM2,DISTRICT
SM241441,67.8475,27.5364,Od,LANGUAGE,LANGUAGE
SM289898,26.5397,45.3846,Odaile,ADM2,DISTRICT
SM278540,139.1333,35.25,Odawara,ADM2,DISTRICT
SM271690,10.153,55.9297,Odder,ADM2,DISTRICT
SM285054,3.5203,7.2965,Odeda,ADM2,DISTRICT
SM289421,-8.573,37.6272,Odemira,ADM2,DISTRICT
SM271743,10.3776,55.3957,Odense,ADM2,DISTRICT
SM18259,30.4818,46.4176,Odeskyi Raion (excl. Odessa),ADM2,DISTRICT
SM2569,30.7484,46.4577,Odessa,ADM1,DISTRICT
SM18260,30.7442,46.4854,Odessa urban raion,ADM2,DISTRICT
SM241760,85,21,Odia,LANGUAGE,LANGUAGE
SM241442,141.336,-3.8228,Odiai,LANGUAGE,LANGUAGE
SM285071,4.727,6.765,Odigbo,ADM2,DISTRICT
SM17028,36.9167,55.6667,Odintsovskiy Rayon,ADM2,DISTRICT
SM2570,84.4167,20.5,Odisha,ADM1,DISTRICT
SM289260,-9.1911,38.7971,Odivelas,ADM2,DISTRICT
SM285133,4.7,8.0167,Odo0tin,ADM2,DISTRICT
SM292362,27.0875,45.7501,Odobesti,ADM2,DISTRICT
SM290292,25.5408,44.5988,Odobesti,ADM2,DISTRICT
SM285055,3.8051,6.786,Odogbolu,ADM2,DISTRICT
SM280488,104.6122,11.6685,Odongk,ADM2,DISTRICT
SM241445,142.486,-6.5922,Odoodee,LANGUAGE,LANGUAGE
SM291675,22.9964,47.7943,Odoreu,ADM2,DISTRICT
SM290706,25.2955,46.3065,Odorheiu Secuiesc,ADM2,DISTRICT
SM10941,33.1577,34.8911,Odou,ADM2,DISTRICT
SM296365,16.2731,46.5867,Odranci,ADM1:ADM2,DISTRICT
SM17579,-60.9832,13.9748,Odsan,ADM2,DISTRICT
SM271720,11.5944,55.8833,Odsherred,ADM2,DISTRICT
SM241446,6.4149,4.8327,Odual,LANGUAGE,LANGUAGE
SM285395,8.1331,5.185,Odukpani,ADM2,DISTRICT
SM18091,30.5411,39.767,Odunpazari,ADM2,DISTRICT
SM241447,8.2725,5.2032,Odut,LANGUAGE,LANGUAGE
SM297820,124.35,-9.3667,Oe Silo,ADM2,DISTRICT
SM285681,4.4668,52.1826,Oegstgeest,ADM2,DISTRICT
SM289261,-9.2683,38.7137,Oeiras,ADM2,DISTRICT
SM265073,-42.1753,-6.9069,Oeiras,ADM2,DISTRICT
SM264204,-49.8815,-2.2963,Oeiras do Pará,ADM2,DISTRICT
SM298964,40.2694,40.9469,Of,ADM2,DISTRICT
SM257510,-52.25,-19.38,Ofaye,ETHNICITY,ETHNICITY
SM241452,-53.7227,-22.278,Ofayé,LANGUAGE,LANGUAGE
SM284977,4.688,8.1428,Offa,ADM2,DISTRICT
SM2571,-7.5,53.25,Offaly,ADM1:ADM2,DISTRICT
SM11430,-1.69,6.989,Offinso Municipal District (2008-),ADM2,DISTRICT
SM11431,-1.95,7.4,Offinso North,ADM2,DISTRICT
SM4321,11.8343,-0.9986,Offoue-Onoye,ADM2,DISTRICT
SM241457,-91.75,30.43,Ofo,LANGUAGE,LANGUAGE
SM284957,7.0382,7.3028,Ofu,ADM2,DISTRICT
SM261206,-169.6715,-14.1737,Ofu,ADM2,DISTRICT
SM278569,139.8361,39.9379,Oga,ADM2,DISTRICT
SM13052,140.3631,39.1733,Ogachi-gun,ADM2,DISTRICT
SM250618,43.24,2.92,Ogadein,ETHNICITY,ETHNICITY
SM13053,136.6019,35.3648,Ogaki-shi,ADM2,DISTRICT
SM241460,125.3077,24.9159,Ogami,DIALECT,LANGUAGE
SM276672,104.6273,-3.4319,Ogan Ilir,ADM2,DISTRICT
SM276673,105.3667,-3.3667,Ogan Komering Ilir,ADM2,DISTRICT
SM276662,104.0333,-4.1333,Ogan Komering Ulu,ADM2,DISTRICT
SM276660,104.0066,-4.6573,Ogan Komering Ulu Selatan,ADM2,DISTRICT
SM276661,104.7521,-3.8568,Ogan Komering Ulu Timur,ADM2,DISTRICT
SM13054,142.2125,27.0591,Ogasawara Shicho,ADM2,DISTRICT
SM285203,6.6556,5.3417,Ogba/Egbe,ADM2,DISTRICT
SM285344,7.6499,7.0892,Ogbadibo,ADM2,DISTRICT
SM241463,6.539,5.2506,Ogbah,LANGUAGE,LANGUAGE
SM285296,6.7446,6.0158,Ogbaru,ADM2,DISTRICT
SM241468,6.3403,4.7535,Ogbia,LANGUAGE,LANGUAGE
SM285334,6.3269,4.7741,Ogbia,ADM2,DISTRICT
SM241470,6.5471,4.9094,Ogbogolo,LANGUAGE,LANGUAGE
SM285159,4.2644,8.1567,Ogbomosho North,ADM2,DISTRICT
SM285160,4.2366,8.0732,Ogbomosho South,ADM2,DISTRICT
SM241472,6.9058,4.7484,Ogbronuagum,LANGUAGE,LANGUAGE
SM241473,145.71,-5.3777,Ogea,LANGUAGE,LANGUAGE
SM301316,-84.1264,44.3349,Ogemaw,ADM2,DISTRICT
SM459271,144.0763,-14.6808,Ogh Alungul,ETHNICITY,ETHNICITY
SM459275,143.0044,-16.0731,Ogh Awarrangg,ETHNICITY,ETHNICITY
SM459245,142.1574,-16.4026,Ogh Igadj,ETHNICITY,ETHNICITY
SM459270,143.9419,-14.6334,Ogh Ikarranggal,ETHNICITY,ETHNICITY
SM459280,142.3918,-16.2657,Ogh Undjan,ETHNICITY,ETHNICITY
SM279135,130.2,33.2667,Ogi,ADM2,DISTRICT
SM300661,-89.3207,42.0426,Ogle,ADM2,DISTRICT
SM300391,-83.0807,33.8807,Oglethorpe,ADM2,DISTRICT
SM285161,4.2148,7.9901,Ogo-Oluw,ADM2,DISTRICT
SM285396,8.8026,6.5967,Ogoja,ADM2,DISTRICT
SM4323,13.4673,-2.2094,Ogooue-Letili,ADM2,DISTRICT
SM274557,10.2167,-0.7,Ogooué et des Lacs,ADM2,DISTRICT
SM2572,12.8333,0.6667,Ogooué-Ivindo,ADM1,DISTRICT
SM2573,12.6667,-0.8333,Ogooué-Lolo,ADM1,DISTRICT
SM2574,9.5833,-1.5833,Ogooué-Maritime (incl. Port-Gentil),ADM1,DISTRICT
SM284958,6.1636,7.4667,Ogori/Magongo,ADM2,DISTRICT
SM274563,11.517,-1.407,Ogoulou,ADM2,DISTRICT
SM291171,24.3231,46.4414,Ogra,ADM2,DISTRICT
SM13717,24.6178,56.8129,Ogre,ADM2,DISTRICT
SM3998,25.1031,56.8758,Ogres novads,ADM1,DISTRICT
SM13718,24.7239,56.8071,Ogresgala pagasts,ADM2,DISTRICT
SM290585,25.7789,44.3973,Ogrezeni,ADM2,DISTRICT
SM285204,7.2027,4.6694,Ogu/Bolo,ADM2,DISTRICT
SM285204,7.2027,4.6694,Ogu/Bolo,ADM2,DISTRICT
SM285204,7.1865,4.6477,Ogu/Bolo,ADM2,DISTRICT
SM285204,7.1865,4.6477,Ogu/Bolo,ADM2,DISTRICT
SM276052,15.2333,45.2667,Ogulin,ADM2,DISTRICT
SM2575,3.5833,7,Ogun,ADM1,DISTRICT
SM285056,4.3862,6.4845,OgunWaterside,ADM2,DISTRICT
SM284763,6.7999,5.6693,Oguta,ADM2,DISTRICT
SM279697,130.5667,33.3833,Ogōri,ADM2,DISTRICT
SM289816,23.8011,46.0826,Ohaba,ADM2,DISTRICT
SM292004,21.9909,45.9181,Ohaba Lunga,ADM2,DISTRICT
SM284633,7.7694,5.6584,Ohafia Abia,ADM2,DISTRICT
SM284764,6.8245,5.4024,Ohaji/Eg,ADM2,DISTRICT
SM18418,69.6383,40.9064,Ohangaron Shahri,ADM2,DISTRICT
SM18419,70,41,Ohangaron Tumani,ADM2,DISTRICT
SM2576,16.75,-17.5833,Ohangwena,ADM1,DISTRICT
SM284676,7.7775,6.0109,Ohaozara,ADM2,DISTRICT
SM284677,8.023,6.5329,Ohaukwu,ADM2,DISTRICT
SM250176,24.6,-21.06,Ohekwe,ETHNICITY,ETHNICITY
SM244837,21.4223,-3.8104,Ohendo,LANGUAGE,LANGUAGE
SM285345,7.9499,7.2074,Ohimini,ADM2,DISTRICT
SM250699,23.45,-2.4,Ohindo,ETHNICITY,ETHNICITY
SM303136,-80.6191,40.0969,Ohio,ADM2,DISTRICT
SM300745,-84.965,38.9501,Ohio,ADM2,DISTRICT
SM301100,-86.8489,37.4782,Ohio,ADM2,DISTRICT
SM2577,-83.0002,40.2503,Ohio,ADM1,DISTRICT
SM257327,-106.1,36.1,Ohkay Owingeh,ETHNICITY,ETHNICITY
SM2578,20.8703,41.1308,Ohrid,ADM1,DISTRICT
SM13055,135.4931,35.4924,Oi-gun,ADM2,DISTRICT
SM267303,-52.1696,2.708,Oiapoque,ADM2,DISTRICT
SM270492,-73.2787,6.2297,Oiba,ADM2,DISTRICT
SM256436,141.82,-7.02,Oiba,ETHNICITY,ETHNICITY
SM270796,-73.3114,5.5748,Oicatá,ADM2,DISTRICT
SM269658,29.5507,0.4185,Oicha,ADM2,DISTRICT
SM10942,32.8406,35.0036,Oikos,ADM2,DISTRICT
SM290586,26.034,43.9561,Oinacu,ADM2,DISTRICT
SM2579,-15.25,12.25,Oio,ADM1,DISTRICT
SM233803,45.3167,46.5667,Oirad-Kalmyk-Darkhat,LANGUAGE,LANGUAGE
SM241494,127.201,-8.0904,Oirata,LANGUAGE,LANGUAGE
SM285939,5.2896,51.4906,Oirschot,ADM2,DISTRICT
SM274501,2.5,49.5,Oise,ADM2,DISTRICT
SM285940,5.2019,51.5529,Oisterwijk,ADM2,DISTRICT
SM2580,131.4335,33.199,Oita,ADM1:ADMX,DISTRICT
SM292132,26.5604,46.1905,Oituz,ADM2,DISTRICT
SM290223,26.2477,45.9787,Ojdula,ADM2,DISTRICT
SM284726,7.2772,6.1703,Oji-River,ADM2,DISTRICT
SM8135,-81,46.5,Ojibwa,ETHNICITY,ETHNICITY
SM283258,-104.4484,29.3137,Ojinaga,ADM2,DISTRICT
SM241503,-96.3369,18.075,Ojitlán Chinantec,LANGUAGE,LANGUAGE
SM278940,138.8,37.3,Ojiya,ADM2,DISTRICT
SM256440,48.99,24.19,Ojman,ETHNICITY,ETHNICITY
SM284997,3.1483,6.4651,Ojo,ADM2,DISTRICT
SM275843,-87.6501,14.795,Ojo de Agua,ADM2,DISTRICT
SM261045,-64.25,-29.25,Ojo de Agua,ADM2,DISTRICT
SM283067,-102.2401,22.5769,Ojocaliente,ADM2,DISTRICT
SM275918,-87.3398,13.9311,Ojojona,ADM2,DISTRICT
SM295616,-88.8675,14.1386,Ojos de Agua,ADM2,DISTRICT
SM285346,8.382,6.8588,Oju,ADM2,DISTRICT
SM281830,-101.6852,21.8137,Ojuelos de Jalisco,ADM2,DISTRICT
SM284481,16.9165,-21.9856,Okahandja,ADM2,DISTRICT
SM284556,15.0715,-17.8922,Okahao,ADM2,DISTRICT
SM284460,15.9156,-17.8546,Okaku,ADM2,DISTRICT
SM300353,-86.5922,30.665,Okaloosa,ADM2,DISTRICT
SM241508,-119.294,49.2745,Okanagan,LANGUAGE,LANGUAGE
SM256442,-120.35,49.49,Okanagan,ETHNICITY,ETHNICITY
SM268756,-120.0025,49.4165,Okanagan-Similkameen,ADM2,DISTRICT
SM274582,11.5667,0.7833,Okano,ADM2,DISTRICT
SM303085,-119.7408,48.5489,Okanogan,ADM2,DISTRICT
SM288638,145.5,-6.6333,Okapa,ADM2,DISTRICT
SM6008,73.6888,30.7094,Okara,ADM2:ADM3,DISTRICT
SM278830,138.0481,36.0792,Okaya,ADM2,DISTRICT
SM279029,133.9233,34.7125,Okayama,ADM2,DISTRICT
SM2581,133.8102,34.902,Okayama,ADM1:ADMX,DISTRICT
SM454384,133.833,34.667,Okayaman,ETHNICITY,ETHNICITY
SM278057,137.2578,34.9525,Okazaki,ADM2,DISTRICT
SM280643,127.5832,36.2664,Okcheon,ADM2,DISTRICT
SM284978,5.2345,8.17,Oke-Ero,ADM2,DISTRICT
SM300354,-80.8886,27.3863,Okeechobee,ADM2,DISTRICT
SM279194,139.55,36,Okegawa,ADM2,DISTRICT
SM284959,6.2854,7.6819,Okehi,ADM2,DISTRICT
SM284960,6.2167,7.4833,Okene,ADM2,DISTRICT
SM302221,-96.3228,35.4655,Okfuskee,ADM2,DISTRICT
SM375323,86.4484,27.3365,Okhaldhunga,ADM2:ADM3,DISTRICT
SM17029,143,53.5,Okhinskiy Rayon,ADM2,DISTRICT
SM299715,34.9002,50.3124,Okhtyrs'ka,ADM2,DISTRICT
SM241518,128.619,27.3738,Oki-No-Erabu,LANGUAGE,LANGUAGE
SM13056,133.2206,36.2013,Oki-gun,ADM2,DISTRICT
SM241514,36.5977,-2.7805,Okiek,LANGUAGE,LANGUAGE
SM454528,36,0.3,Okiek,ETHNICITY,ETHNICITY
SM284765,7.3256,5.8109,Okigwe,ADM2,DISTRICT
SM279069,127.8075,26.3531,Okinawa,ADM2,DISTRICT
SM2582,127.9333,26.5,Okinawa,ADM1:ADMX,DISTRICT
SM256444,127.93,26.5,Okinawan,ETHNICITY,ETHNICITY
SM256444,127.93,26.5,Okinawan,ETHNICITY,ETHNICITY
SM256444,127.8,26.16,Okinawan,ETHNICITY,ETHNICITY
SM256444,127.8,26.16,Okinawan,ETHNICITY,ETHNICITY
SM295116,100.5,52.5,Okinskiy rayon,ADM2,DISTRICT
SM285072,4.6989,6.548,Okitipupa,ADM2,DISTRICT
SM302222,-97.4072,35.5515,Oklahoma,ADM2,DISTRICT
SM2583,-97.5033,35.4921,Oklahoma,ADM1,DISTRICT
SM302223,-95.9643,35.6467,Okmulgee,ADM2,DISTRICT
SM241522,6.1573,7.5391,Oko-Eni-Osayen,LANGUAGE,LANGUAGE
SM241520,8.087,5.0204,Okobo,LANGUAGE,LANGUAGE
SM285100,8.132,4.8271,Okobo,ADM2,DISTRICT
SM241521,6.4411,5.2924,Okodia,LANGUAGE,LANGUAGE
SM241523,92.8753,13.0472,Okojuwoi,LANGUAGE,LANGUAGE
SM241526,115.753,4.5399,Okolod,LANGUAGE,LANGUAGE
SM293905,75.25,54.75,Okoneshnikovskiy rayon,ADM2,DISTRICT
SM299373,30.8882,2.5203,Okoro,ADM1:ADM2,DISTRICT
SM323909,-113.9654,50.724,Okotoks,ADM2:ADM3,DISTRICT
SM269819,14.765,-1.409,Okoyo,ADM2,DISTRICT
SM241537,5.9784,7.3371,Okpamheri,LANGUAGE,LANGUAGE
SM284653,5.7833,5.6863,Okpe,ADM2,DISTRICT
SM241540,6.2472,7.2952,Okpe (Northwestern Edo),LANGUAGE,LANGUAGE
SM241541,5.9489,7.2241,Okpe (Southwestern Edo),LANGUAGE,LANGUAGE
SM285347,7.851,7.0109,Okpokwu,ADM2,DISTRICT
SM285205,7.0816,4.6383,Okrika,ADM2,DISTRICT
SM305472,16.2673,43.4964,Okrug,ADM2,DISTRICT
SM241546,142.177,-5.208,Oksapmin,LANGUAGE,LANGUAGE
SM301474,-88.8793,33.425,Oktibbeha,ADM2,DISTRICT
SM13217,65.3333,52,Oktyabr' Audany,ADM2,DISTRICT
SM13218,57.5,49.5,Oktyabr'sk Audany,ADM2,DISTRICT
SM295074,131.4167,48.1667,Oktyabr'skiy,ADM2,DISTRICT
SM294918,43.8333,48,Oktyabr'skiy,ADM2,DISTRICT
SM293339,47.0833,59,Oktyabr'skiy,ADM2,DISTRICT
SM293065,45.5167,47.8,Oktyabr'skiy,ADM2,DISTRICT
SM293508,35.9333,51.65,Oktyabr'skiy,ADM2,DISTRICT
SM293215,129,50.3333,Oktyabr'skiy,ADM2,DISTRICT
SM292761,62.75,54.4167,Oktyabr'skiy,ADM2,DISTRICT
SM17949,68.8,38.55,Oktyabr'skiy Rayon,ADM2,DISTRICT
SM13366,74.6203,42.8596,Oktyabr'skiy Rayon,ADM2,DISTRICT
SM11333,44.75,41.7,Oktyabr'skiy Rayon,ADM2,DISTRICT
SM9749,49.8,40.3833,Oktyabr'skiy Rayon,ADM2,DISTRICT
SM241552,10.5096,6.2371,Oku,LANGUAGE,LANGUAGE
SM279257,133.039,35.16,Okuizumo,ADM2,DISTRICT
SM293822,33.25,58.4167,Okulovskiy rayon,ADM2,DISTRICT
SM278153,139.4833,42.1667,Okushiri,ADM2,DISTRICT
SM305565,17.1985,45.2604,Okučani,ADM2,DISTRICT
SM292899,106.5,53,Ol'khonskiy rayon,ADM2,DISTRICT
SM294979,39.25,50.25,Ol'khovatskiy rayon,ADM2,DISTRICT
SM294919,44.5,49.8333,Ol'khovskiy rayon,ADM2,DISTRICT
SM293612,153,60,Ol'skiy rayon,ADM2,DISTRICT
SM285134,4.2167,7.7833,Ola-Oluwa,ADM2,DISTRICT
SM13719,23.9257,56.7904,Olaine,ADM2,DISTRICT
SM4010,23.9481,56.7914,Olaines Novads,ADM1,DISTRICT
SM13720,23.9864,56.7767,Olaines pagasts,ADM2,DISTRICT
SM284961,7.5283,7.1869,Olamabor,ADM2,DISTRICT
SM275809,-86.6238,15.4737,Olanchito,ADM2,DISTRICT
SM2584,-86,14.8333,Olancho,ADM1,DISTRICT
SM292212,24.2917,44.8742,Olanu,ADM2,DISTRICT
SM290519,21.5452,46.3867,Olari,ADM2,DISTRICT
SM263706,-43.9685,-21.9064,Olaria,ADM2,DISTRICT
SM260809,-60.75,-36.75,Olavarría,ADM2,DISTRICT
SM270244,-75.7861,6.6035,Olaya,ADM2,DISTRICT
SM270332,-78.3224,2.2193,Olaya Herrera,ADM2,DISTRICT
SM292446,21.9845,46.6609,Olcea,ADM2,DISTRICT
SM241559,37,32.84,Old Aramaic-Sam'alian,LANGUAGE,LANGUAGE
SM241563,100.4942,13.7525,Old Bangkok Sign Language,LANGUAGE,LANGUAGE
SM241562,94.8973,21.162,Old Burmese,LANGUAGE,LANGUAGE
SM241567,-81.2167,19.3333,Old Cayman Sign Language,LANGUAGE,LANGUAGE
SM241565,114.32,36.12,Old Chinese,LANGUAGE,LANGUAGE
SM305095,44.2145,15.3551,Old City,ADM2,DISTRICT
SM12594,-77.267,17.9612,Old Denbigh,ADM2,DISTRICT
SM241573,5.2,52.16,Old Dutch-Old Frankish,LANGUAGE,LANGUAGE
SM12595,-77.4602,17.985,Old England,ADM2,DISTRICT
SM241576,-1.31,51.06,Old English (ca. 450-1100),LANGUAGE,LANGUAGE
SM231010,9.9839,52.896,Old Frankish,DIALECT,LANGUAGE
SM241580,1.11,48.8,Old French (842-ca. 1400),LANGUAGE,LANGUAGE
SM241581,6.8,53.35,Old Frisian,LANGUAGE,LANGUAGE
SM241582,43.0615,41.6043,Old Georgian,LANGUAGE,LANGUAGE
SM12596,-77.1037,17.9584,Old Harbour,ADM2,DISTRICT
SM12597,-77.0991,17.9101,Old Harbour Bay,ADM2,DISTRICT
SM12598,-77.0273,17.958,Old Harbour Road,ADM2,DISTRICT
SM241583,10,52,Old High German (ca. 750-1050),LANGUAGE,LANGUAGE
SM241587,135.8,34.6833,Old Japanese,LANGUAGE,LANGUAGE
SM241590,75.82,13.244,Old Kannada,LANGUAGE,LANGUAGE
SM241588,0.9837,51.1198,Old Kentish Sign Language,LANGUAGE,LANGUAGE
SM241589,103.8667,13.4125,Old Khmer,LANGUAGE,LANGUAGE
SM241592,12.4883,41.9011,Old Latin,LANGUAGE,LANGUAGE
SM241595,103.3535,-0.6483,Old Malay,LANGUAGE,LANGUAGE
SM241596,75,18,Old Marathi,LANGUAGE,LANGUAGE
SM241594,100.5,14.83,Old Mon,LANGUAGE,LANGUAGE
SM241600,10.38,63.42,Old Norse,LANGUAGE,LANGUAGE
SM241601,31.7487,18.7607,Old Nubian,LANGUAGE,LANGUAGE
SM241603,39.46,48.48,Old Ossetic,LANGUAGE,LANGUAGE
SM241605,54,32,Old Persian (ca. 600-400 B.C.),LANGUAGE,LANGUAGE
SM241604,0.6,43.5,Old Provençal,LANGUAGE,LANGUAGE
SM242795,14.757,52.394,Old Prussian,LANGUAGE,LANGUAGE
SM243101,-144.338,-27.6304,Old Rapa,LANGUAGE,LANGUAGE
SM241610,31.827,54.813,Old Russian,LANGUAGE,LANGUAGE
SM243461,13.463,43.538,Old Sabellic,LANGUAGE,LANGUAGE
SM241612,9.72,52.37,Old Saxon,LANGUAGE,LANGUAGE
SM244673,-173.925,64.5192,Old Sirenik,LANGUAGE,LANGUAGE
SM241611,-3.9,41.5,Old Spanish,LANGUAGE,LANGUAGE
SM241617,78,11,Old Tamil,LANGUAGE,LANGUAGE
SM241618,80.7807,16.1026,Old Telugu,LANGUAGE,LANGUAGE
SM241619,89.5292,42.8528,Old Turkic,LANGUAGE,LANGUAGE
SM241616,59,37,Old Turkish,LANGUAGE,LANGUAGE
SM241620,71.4553,38.3731,Old Wanji,LANGUAGE,LANGUAGE
SM241621,-4.089,52.582,Old-Middle Welsh,LANGUAGE,LANGUAGE
SM285798,5.9009,52.445,Oldebroek,ADM2,DISTRICT
SM285564,6.9075,52.3075,Oldenzaal,ADM2,DISTRICT
SM241577,10.3765,55.2718,Older Runic,LANGUAGE,LANGUAGE
SM302729,-102.6029,35.405,Oldham,ADM2,DISTRICT
SM301101,-85.4484,38.3994,Oldham,ADM2,DISTRICT
SM274667,-2.0833,53.55,Oldham,ADM1:ADM2,DISTRICT
SM323910,-114.1169,51.7908,Olds,ADM2:ADM3,DISTRICT
SM288772,22.417,54.0513,Olecko,ADM2,DISTRICT
SM289466,-7.8859,39.9495,Oleiros,ADM2,DISTRICT
SM241628,90.5546,27.2079,Olekha,LANGUAGE,LANGUAGE
SM294316,121,59,Olekminskiy rayon,ADM2,DISTRICT
SM18261,33.1122,48.6662,Oleksandrivka Raion,ADM2,DISTRICT
SM18261,33.1122,48.6662,Oleksandrivka Raion,ADM2,DISTRICT
SM18261,32.2356,48.9648,Oleksandrivka Raion,ADM2,DISTRICT
SM18261,32.2356,48.9648,Oleksandrivka Raion,ADM2,DISTRICT
SM294317,112.5934,68.3202,Olenekskiy rayon,ADM2,DISTRICT
SM289003,18.4604,50.8789,Olesno,ADM2,DISTRICT
SM288658,17.5168,51.2546,Oleśnica,ADM2,DISTRICT
SM264257,-37.7324,-7.2611,Olho d'Água,ADM2,DISTRICT
SM265397,-36.7952,-10.0562,Olho d'Água Grande,ADM2,DISTRICT
SM262835,-45.0483,-4.0263,Olho d'Água das Cunhãs,ADM2,DISTRICT
SM265395,-37.2521,-9.5403,Olho d'Água das Flores,ADM2,DISTRICT
SM265396,-37.835,-9.4565,Olho d'Água do Casado,ADM2,DISTRICT
SM265074,-42.5394,-5.8485,Olho d'água do Piauí,ADM2,DISTRICT
SM265597,-37.7363,-5.9966,Olho-d'Água do Borges,ADM2,DISTRICT
SM263707,-43.5435,-17.4889,Olhos-d'Água,ADM2,DISTRICT
SM289502,-7.8103,37.0451,Olhão,ADM2,DISTRICT
SM241630,9.8,4.33,Oli-Bidiman,LANGUAGE,LANGUAGE
SM17580,-60.9993,13.7763,Olibo,ADM2,DISTRICT
SM281621,-98.7515,17.8644,Olinalá,ADM2,DISTRICT
SM264855,-34.8731,-7.9974,Olinda,ADM2,DISTRICT
SM262836,-44.9657,-2.9976,Olinda Nova do Maranhão,ADM2,DISTRICT
SM267569,-38.3859,-11.3724,Olindina,ADM2,DISTRICT
SM275214,-91.5349,14.8926,Olintepeque,ADM2,DISTRICT
SM282242,-97.6711,20.1144,Olintla,ADM2,DISTRICT
SM264258,-36.2382,-6.994,Olivedos,ADM2,DISTRICT
SM263710,-44.7161,-20.7382,Oliveira,ADM2,DISTRICT
SM263709,-43.5199,-21.3387,Oliveira Fortes,ADM2,DISTRICT
SM289212,-8.4695,40.8508,Oliveira de Azeméis,ADM2,DISTRICT
SM289396,-8.1752,40.7464,Oliveira de Frades,ADM2,DISTRICT
SM267274,-48.9078,-10.6705,Oliveira de Fátima,ADM2,DISTRICT
SM289213,-8.5637,40.5098,Oliveira do Bairro,ADM2,DISTRICT
SM289472,-7.8648,40.3552,Oliveira do Hospital,ADM2,DISTRICT
SM265398,-37.1668,-9.4695,Olivença,ADM2,DISTRICT
SM302056,-101.3404,47.1153,Oliver,ADM2,DISTRICT
SM267570,-42.6963,-12.3063,Oliveria dos Brejinhos,ADM2,DISTRICT
SM241633,142.4,-15.2,Olkol,LANGUAGE,LANGUAGE
SM288934,19.5577,50.2793,Olkusz,ADM2,DISTRICT
SM16348,15.9549,-1.3386,Ollombo,ADM2,DISTRICT
SM18420,69.5983,40.8447,Olmaliq Shahri,ADM2,DISTRICT
SM273494,-80.2093,-1.3525,Olmedo,ADM2,DISTRICT
SM273477,-79.6251,-3.9305,Olmedo,ADM2,DISTRICT
SM301389,-92.4018,44.0037,Olmsted,ADM2,DISTRICT
SM241644,142.131,-3.2929,Olo,LANGUAGE,LANGUAGE
SM295695,-89.1,13.5,Olocuilta,ADM2,DISTRICT
SM296386,14.5656,56.3231,Olofström,ADM2,DISTRICT
SM241640,6.0277,7.0128,Oloma,LANGUAGE,LANGUAGE
SM271237,17.25,49.6667,Olomouc,ADM2,DISTRICT
SM2585,17.1667,49.75,Olomoucký,ADM1,DISTRICT
SM293121,33,61,Olonetskiy rayon,ADM2,DISTRICT
SM275413,-89.3204,14.6979,Olopa,ADM2,DISTRICT
SM285135,4.5833,7.8667,Olorunda,ADM2,DISTRICT
SM285162,4.1333,8.75,Olorunsogo,ADM2,DISTRICT
SM261207,-169.6154,-14.168,Olosega,ADM2,DISTRICT
SM18421,64.1667,39.1667,Olot Tumani,ADM2,DISTRICT
SM295093,116.1667,51,Olovyanninskiy rayon,ADM2,DISTRICT
SM241646,167.433,-14.313,Olrat,DIALECT,LANGUAGE
SM285565,6.1348,52.3573,Olst-Wijhe,ADM2,DISTRICT
SM288774,20.5664,53.7292,Olsztyn,ADM2,DISTRICT
SM288773,20.4666,53.7766,Olsztyn (City),ADM2,DISTRICT
SM2586,24.5,44.3333,Olt,ADM1,DISTRICT
SM268960,7.9076,47.3443,Olten,ADM2,DISTRICT
SM292298,27.9018,46.5844,Oltenesti,ADM2,DISTRICT
SM292213,24.3659,45.1624,Olteni,ADM2,DISTRICT
SM291907,25.278,44.1898,Olteni,ADM2,DISTRICT
SM289965,26.6418,44.092,Oltenita,ADM2,DISTRICT
SM18422,71.5,40.4167,Oltiariq Tumani,ADM2,DISTRICT
SM290185,27.6488,44.1388,Oltina,ADM2,DISTRICT
SM303407,72.1667,40.8,Oltinko'l,ADM2,DISTRICT
SM303441,67.75,38.2,Oltinsoy,ADM2,DISTRICT
SM298391,41.9958,40.5456,Oltu,ADM2,DISTRICT
SM241648,31.9103,4.6242,Olu'bo,LANGUAGE,LANGUAGE
SM284474,16.0167,-17.9833,Olukonda,ADM2,DISTRICT
SM241649,8.5655,5.9771,Olulumo-Ikom,LANGUAGE,LANGUAGE
SM256451,153.62,-25.36,Olungbura,ETHNICITY,ETHNICITY
SM298392,42.1323,40.8266,Olur,ADM2,DISTRICT
SM282749,-94.8923,17.917,Oluta,ADM2,DISTRICT
SM241653,-94.8863,17.8937,Oluta Popoluca,LANGUAGE,LANGUAGE
SM285163,3.8667,7.2333,Oluyole,ADM2,DISTRICT
SM293102,169,61,Olyutorskiy rayon,ADM2,DISTRICT
SM286694,-80.6532,8.4805,Olá,ADM2,DISTRICT
SM266748,-48.9917,-20.6962,Olímpia,ADM2,DISTRICT
SM263708,-45.2873,-22.0855,Olímpio Noronha,ADM2,DISTRICT
SM279297,138.2167,34.6,Omaezaki,ADM2,DISTRICT
SM241657,-69.1667,-3.526,Omagua,LANGUAGE,LANGUAGE
SM256452,-64.99,-21.31,Omaguaca,ETHNICITY,ETHNICITY
SM256453,-97.54,45.47,Omaha,ETHNICITY,ETHNICITY
SM256453,-97.54,45.47,Omaha,ETHNICITY,ETHNICITY
SM256453,-97.54,45.47,Omaha,ETHNICITY,ETHNICITY
SM256453,-97.54,45.47,Omaha,ETHNICITY,ETHNICITY
SM256453,-96.29,43.28,Omaha,ETHNICITY,ETHNICITY
SM256453,-96.29,43.28,Omaha,ETHNICITY,ETHNICITY
SM256453,-96.29,43.28,Omaha,ETHNICITY,ETHNICITY
SM256453,-96.29,43.28,Omaha,ETHNICITY,ETHNICITY
SM256453,-97.5,41.417,Omaha,ETHNICITY,ETHNICITY
SM256453,-97.5,41.417,Omaha,ETHNICITY,ETHNICITY
SM256453,-97.5,41.417,Omaha,ETHNICITY,ETHNICITY
SM256453,-97.5,41.417,Omaha,ETHNICITY,ETHNICITY
SM256453,-96,41,Omaha,ETHNICITY,ETHNICITY
SM256453,-96,41,Omaha,ETHNICITY,ETHNICITY
SM256453,-96,41,Omaha,ETHNICITY,ETHNICITY
SM256453,-96,41,Omaha,ETHNICITY,ETHNICITY
SM256453,-96.5,41.43,Omaha,ETHNICITY,ETHNICITY
SM256453,-96.5,41.43,Omaha,ETHNICITY,ETHNICITY
SM256453,-96.5,41.43,Omaha,ETHNICITY,ETHNICITY
SM256453,-96.5,41.43,Omaha,ETHNICITY,ETHNICITY
SM241658,-96.5295,42.0944,Omaha-Ponca,LANGUAGE,LANGUAGE
SM2587,19.4167,-21.75,Omaheke,ADM1,DISTRICT
SM284962,7.541,7.7996,Omala,ADM2,DISTRICT
SM241661,58.5,23.25,Omani Arabic,LANGUAGE,LANGUAGE
SM262723,-68.6438,-16.0009,Omasuyos,ADM2,DISTRICT
SM284483,17.7333,-20.6333,Omatako,ADM2,DISTRICT
SM241662,144.005,-7.3981,Omati,LANGUAGE,LANGUAGE
SM260455,14.8879,-16.7006,Ombadja,ADM2,DISTRICT
SM241663,13.617,-2.7534,Ombamba,LANGUAGE,LANGUAGE
SM2588,18,5.0833,"Ombella-M'Poko (excl. Bangui, 1964-)",ADM1,DISTRICT
SM241667,25.1748,-2.3216,Ombo,LANGUAGE,LANGUAGE
SM295232,32.2943,15.7553,Omdurman,ADM2,DISTRICT
SM282750,-96.7384,18.7204,Omealca,ADM2,DISTRICT
SM241668,-70,1,Omejes,LANGUAGE,LANGUAGE
SM281622,-98.3827,16.6804,Ometepec,ADM2,DISTRICT
SM241671,-98.427,16.7786,Ometepec Nahuatl,LANGUAGE,LANGUAGE
SM241674,30.5385,3.2542,Omi,LANGUAGE,LANGUAGE
SM13057,136.0696,35.1525,Omihachiman-shi (2010-),ADM2,DISTRICT
SM278388,140.3598,36.204,Omitama,ADM2,DISTRICT
SM281694,-98.6363,20.1785,Omitlán de Juárez,ADM2,DISTRICT
SM275998,16.7,43.4667,Omiš,ADM2,DISTRICT
SM305421,14.5573,45.2111,Omišalj,ADM2,DISTRICT
SM296880,98.3543,17.6984,Omkoi,ADM2,DISTRICT
SM285566,6.4563,52.5166,Ommen,ADM2,DISTRICT
SM260158,68.8133,32.8942,Omna,ADM2,DISTRICT
SM275888,-88.1287,15.6587,Omoa,ADM2,DISTRICT
SM10943,32.8068,34.8526,Omodos,ADM2,DISTRICT
SM241676,-118.9728,35.5922,Omomil,LANGUAGE,LANGUAGE
SM299419,32.3562,2.5768,Omoro,ADM1:ADM2,DISTRICT
SM241678,35.6296,-1.344,Omotik,LANGUAGE,LANGUAGE
SM298022,10.1552,36.8195,Omrane,ADM2,DISTRICT
SM298023,10.1306,36.8329,Omrane Supérieur,ADM2,DISTRICT
SM2590,73,56,Omsk,ADM1,DISTRICT
SM293613,156.3333,63,Omsukchanskiy rayon,ADM2,DISTRICT
SM285206,7.2349,5.0907,Omumma,ADM2,DISTRICT
SM284475,16.2333,-18.2333,Omuntele,ADM2,DISTRICT
SM241681,-75.4,-4.8167,Omurano,LANGUAGE,LANGUAGE
SM262450,26.4833,43.0833,Omurtag,ADM2,DISTRICT
SM2591,14.8333,-18.3333,Omusati,ADM1,DISTRICT
SM284476,16.2167,-17.7167,Omuthiyagwipundi,ADM2,DISTRICT
SM294800,67.5833,56.4167,Omutinskiy rayon,ADM2,DISTRICT
SM293275,52.3333,58.8333,Omutninskiy rayon,ADM2,DISTRICT
SM256456,-68.4,-53.7,Ona,ETHNICITY,ETHNICITY
SM256456,-68.4,-53.7,Ona,ETHNICITY,ETHNICITY
SM256456,-68.4,-53.7,Ona,ETHNICITY,ETHNICITY
SM256456,-69,-54,Ona,ETHNICITY,ETHNICITY
SM256456,-69,-54,Ona,ETHNICITY,ETHNICITY
SM256456,-69,-54,Ona,ETHNICITY,ETHNICITY
SM256456,-68.62,-53.9,Ona,ETHNICITY,ETHNICITY
SM256456,-68.62,-53.9,Ona,ETHNICITY,ETHNICITY
SM256456,-68.62,-53.9,Ona,ETHNICITY,ETHNICITY
SM285164,4.0333,7.2833,Ona-Ara,ADM2,DISTRICT
SM256457,143,-6.66,Onabasulu,ETHNICITY,ETHNICITY
SM278730,141.432,38.4327,Onagawa,ADM2,DISTRICT
SM282588,-109.4657,28.452,Onavas,ADM2,DISTRICT
SM284477,16.1916,-17.9496,Onayena,ADM2,DISTRICT
SM292133,27.2584,46.4754,Oncesti,ADM2,DISTRICT
SM289052,125.2097,38.8856,Onch'ŏn,ADM2,DISTRICT
SM276798,-4.4552,54.1748,Onchan,ADM1:ADM2,DISTRICT
SM266749,-49.2447,-20.6104,Onda Verde,ADM2,DISTRICT
SM256459,4.69,7,Ondo,ETHNICITY,ETHNICITY
SM2592,5.0833,7.1667,Ondo (1996-),ADM1,DISTRICT
SM285073,4.9605,7.0915,Ondo East,ADM2,DISTRICT
SM285074,4.7456,7.0164,Ondo West,ADM2,DISTRICT
SM298850,36.073,41.5119,Ondokuz Mayıs,ADM2,DISTRICT
SM241690,-75.7081,43.4387,Oneida,LANGUAGE,LANGUAGE
SM303261,-89.5217,45.7056,Oneida,ADM2,DISTRICT
SM301894,-75.4358,43.2418,Oneida,ADM2,DISTRICT
SM300573,-112.5396,42.1949,Oneida,ADM2,DISTRICT
SM256460,-75.53,43.49,Oneida,ETHNICITY,ETHNICITY
SM14956,153.709,5.5066,Oneop Municipality,ADM2,DISTRICT
SM292134,26.75,46.25,Onesti,ADM2,DISTRICT
SM17030,38,63.3333,Onezhskiy Rayon,ADM2,DISTRICT
SM241694,106.001,15.1225,Ong-Ir,LANGUAGE,LANGUAGE
SM13058,130.6363,33.857,Onga-gun,ADM2,DISTRICT
SM256461,92.47,10.69,Onges,ETHNICITY,ETHNICITY
SM256461,92.47,10.69,Onges,ETHNICITY,ETHNICITY
SM256461,92.47,10.69,Onges,ETHNICITY,ETHNICITY
SM256461,92.47,10.7,Onges,ETHNICITY,ETHNICITY
SM256461,92.47,10.7,Onges,ETHNICITY,ETHNICITY
SM256461,92.47,10.7,Onges,ETHNICITY,ETHNICITY
SM256461,92.5,10.75,Onges,ETHNICITY,ETHNICITY
SM256461,92.5,10.75,Onges,ETHNICITY,ETHNICITY
SM256461,92.5,10.75,Onges,ETHNICITY,ETHNICITY
SM280570,126.1231,37.2336,Ongjin,ADM2,DISTRICT
SM289167,125.2853,37.9653,Ongjin,ADM2,DISTRICT
SM297091,101.0089,14.0755,Ongkharak,ADM2,DISTRICT
SM241695,83.0456,46.7774,Ongkor Solon,DIALECT,LANGUAGE
SM16349,16.0218,-1.5578,Ongogni,ADM2,DISTRICT
SM283682,113.0833,45.6589,Ongon,ADM2,DISTRICT
SM256462,16.27,-16.51,Ongona,ETHNICITY,ETHNICITY
SM227277,37.025,5.2981,Ongota,LANGUAGE,LANGUAGE
SM292873,86.5,50.6667,Ongudayskiy rayon,ADM2,DISTRICT
SM284466,15.7667,-17.7833,Ongwediva,ADM2,DISTRICT
SM274826,43.5167,42.65,Oni,ADM2,DISTRICT
SM291346,27.1634,46.7893,Oniceni,ADM2,DISTRICT
SM284678,7.8413,6.099,Onicha,ADM2,DISTRICT
SM18092,36.9055,37.59,Onikisubat,ADM2,DISTRICT
SM241699,132.062,-2.7598,Onin,LANGUAGE,LANGUAGE
SM241697,132.083,-2.83,Onin Pidgin,LANGUAGE,LANGUAGE
SM285297,6.7997,6.1608,Onitsha North,ADM2,DISTRICT
SM285298,6.7774,6.1267,Onitsha South,ADM2,DISTRICT
SM241702,149.121,-9.3182,Onjob,LANGUAGE,LANGUAGE
SM261719,138.5778,-35.1868,Onkaparinga,ADM2,DISTRICT
SM285101,7.8487,4.616,Onna,ADM2,DISTRICT
SM241705,147.579,-6.1922,Ono,LANGUAGE,LANGUAGE
SM278345,134.9513,34.8575,Ono,ADM2,DISTRICT
SM241703,142.776,-6.3088,Onobasulu,LANGUAGE,LANGUAGE
SM278131,133.1633,34.4263,Onomichi,ADM2,DISTRICT
SM241704,-76.12,42.92,Onondaga,LANGUAGE,LANGUAGE
SM301895,-76.1946,43.0058,Onondaga,ADM2,DISTRICT
SM256463,-76.54,43.31,Onondaga,ETHNICITY,ETHNICITY
SM295094,115,50.3333,Ononskiy rayon,ADM2,DISTRICT
SM13338,175.5639,-1.8859,Onotoa,ADM2,DISTRICT
SM14957,150.29,8.799,Onou Municipality,ADM2,DISTRICT
SM14958,149.6855,8.575,Onoun Municipality,ADM2,DISTRICT
SM324079,-114.1891,53.7064,Onoway,ADM2:ADM3,DISTRICT
SM301994,-77.3932,34.7631,Onslow,ADM2,DISTRICT
SM289096,129.9245,42.8769,Onsŏng,ADM2,DISTRICT
SM301896,-77.2998,42.8528,Ontario,ADM2,DISTRICT
SM2593,-84.4998,49.2501,Ontario,ADM1,DISTRICT
SM241708,145.765,-6.4591,Ontenu,LANGUAGE,LANGUAGE
SM301318,-89.3051,46.7775,Ontonagon,ADM2,DISTRICT
SM256465,159.41,-5.09,Ontong Java,ETHNICITY,ETHNICITY
SM256465,159.41,-5.09,Ontong Java,ETHNICITY,ETHNICITY
SM256465,159.41,-5.09,Ontong Java,ETHNICITY,ETHNICITY
SM256465,159.7,-5.48,Ontong Java,ETHNICITY,ETHNICITY
SM256465,159.7,-5.48,Ontong Java,ETHNICITY,ETHNICITY
SM256465,159.7,-5.48,Ontong Java,ETHNICITY,ETHNICITY
SM256465,159.4,-5.3,Ontong Java,ETHNICITY,ETHNICITY
SM256465,159.4,-5.3,Ontong Java,ETHNICITY,ETHNICITY
SM256465,159.4,-5.3,Ontong Java,ETHNICITY,ETHNICITY
SM299514,33.4491,48.9,Onufri‹vs'kyi,ADM2,DISTRICT
SM13059,139.6437,37.4009,Onuma-gun,ADM2,DISTRICT
SM244123,110.527,-0.7519,Onya Darat,LANGUAGE,LANGUAGE
SM270493,-72.8324,6.3244,Onzaga,ADM2,DISTRICT
SM263711,-44.7465,-19.7104,Onça de Pitangui,ADM2,DISTRICT
SM295800,45.011,9.2574,Oodweyne,ADM2,DISTRICT
SM241713,149.1006,-10.1351,Ooku,LANGUAGE,LANGUAGE
SM241714,23.0145,-28.3845,Oorlams,LANGUAGE,LANGUAGE
SM296087,-55.1057,5.5042,Oost,ADM2,DISTRICT
SM15173,6.5496,52.0043,Oost Gelre,ADM2,DISTRICT
SM262095,3.75,51,Oost-Vlaanderen,ADM1:ADM2,DISTRICT
SM285941,4.864,51.6344,Oosterhout,ADM2,DISTRICT
SM285739,6.2752,52.9826,Ooststellingwerf,ADM2,DISTRICT
SM286005,4.876,52.4378,Oostzaan,ADM2,DISTRICT
SM262451,26.15,43.4667,Opaka,ADM2,DISTRICT
SM262447,25.7,42.2167,Opan,ADM2,DISTRICT
SM241720,145.751,-7.8599,Opao,LANGUAGE,LANGUAGE
SM293276,47.8333,59.6667,Oparinskiy rayon,ADM2,DISTRICT
SM256467,-109.66,29.92,Opata,ETHNICITY,ETHNICITY
SM256467,-109.66,29.92,Opata,ETHNICITY,ETHNICITY
SM256467,-110,30,Opata,ETHNICITY,ETHNICITY
SM256467,-110,30,Opata,ETHNICITY,ETHNICITY
SM275975,14.2667,45.3333,Opatija/Veprinac,ADM2,DISTRICT
SM275680,-87.8798,14.0245,Opatoro,ADM2,DISTRICT
SM288763,21.4453,50.7964,Opatów,ADM2,DISTRICT
SM256468,145.38,-7.76,Opau,ETHNICITY,ETHNICITY
SM271234,17.9167,49.8333,Opava,ADM2,DISTRICT
SM17834,14.5333,46.0833,Opcina Ljubljana-Bezigrad,ADM2,DISTRICT
SM10529,13.7432,45.2653,Opcina Visnjan (excl. Visnjan),ADM2,DISTRICT
SM10530,13.7426,45.3286,Opcina Vizinada (excl. Vizinada),ADM2,DISTRICT
SM282989,-89.8724,20.5577,Opichén,ADM2,DISTRICT
SM295659,-89.4,13.8667,Opico,ADM2,DISTRICT
SM296333,15.4523,46.3788,Oplotnica,ADM1:ADM2,DISTRICT
SM286006,4.9533,52.7147,Opmeer,ADM2,DISTRICT
SM241731,33.853,8.4753,Opo,LANGUAGE,LANGUAGE
SM285208,7.444,4.5614,Opobo/Nkoro,ADM2,DISTRICT
SM294117,28.8333,56.6667,Opochetskiy rayon,ADM2,DISTRICT
SM288890,20.1943,51.3927,Opoczno,ADM2,DISTRICT
SM282589,-110.7654,30.0479,Opodepe,ADM2,DISTRICT
SM288994,17.9846,50.7369,Opole,ADM2,DISTRICT
SM289004,17.9307,50.6562,Opole (City),ADM2,DISTRICT
SM288897,22.0094,51.129,Opole Lubelskie,ADM2,DISTRICT
SM2594,17.9,50.6667,Opolskie,ADM1,DISTRICT
SM270092,-76.0413,2.0577,Oporapa,ADM2,DISTRICT
SM291437,24.4241,44.5868,Oporelu,ADM2,DISTRICT
SM286531,177.2833,-38,Opotiki,ADM2,DISTRICT
SM286184,9.6876,62.595,Oppdal,ADM2,DISTRICT
SM305566,18.2162,45.1463,Oprisavci,ADM2,DISTRICT
SM291089,23.0865,44.301,Oprisor,ADM2,DISTRICT
SM305669,13.8241,45.3823,Oprtalj,ADM2,DISTRICT
SM285740,6.1213,53.0485,Opsterland,ADM2,DISTRICT
SM17835,14.5333,46.05,Opstina Ljubljana-Center,ADM2,DISTRICT
SM17836,14.6167,46.0667,Opstina Ljubljana-Moste-Polje,ADM2,DISTRICT
SM17837,14.4167,46.0333,Opstina Ljubljana-Vic-Rudnik,ADM2,DISTRICT
SM9863,17.0372,44.4169,Opstina Mrkonjic Grad (excl. Mrkonjic Grad Jug),ADM2,DISTRICT
SM17838,14.4333,46.1667,Opstina [historical] Ljubljana-Siska,ADM2,DISTRICT
SM284528,13.5162,-18.5425,Opuwo,ADM2,DISTRICT
SM276038,17.5635,43.0172,Opuzen,ADM2,DISTRICT
SM241726,-74.0849,6.4732,Opón-Carare,LANGUAGE,LANGUAGE
SM303419,66.8,39.8333,Oqdaryo,ADM2,DISTRICT
SM303425,68.3333,40.5,Oqoltin,ADM2,DISTRICT
SM303457,69.0833,40.9,Oqqo'rg'on,ADM2,DISTRICT
SM18423,65.9256,39.9269,Oqtosh Shahri,ADM2,DISTRICT
SM282590,-111.7125,30.7494,Oquitoa,ADM2,DISTRICT
SM10944,33.2024,34.8606,Ora,ADM2,DISTRICT
SM256469,4.14,8.9,Ora,ETHNICITY,ETHNICITY
SM13060,139.4377,36.2377,Ora-gun,ADM2,DISTRICT
SM12599,-76.9717,18.3968,Oracabessa,ADM2,DISTRICT
SM292447,21.9363,47.0535,Oradea,ADM2,DISTRICT
SM304735,20.6167,42.3833,Orahovac,ADM2,DISTRICT
SM276016,17.9,45.5333,Orahovica,ADM2,DISTRICT
SM375408,71.0024,33.7078,Orakzai,ADM2:ADM3,DISTRICT
SM13219,51.2833,51.3667,Oral Qalasy,ADM2,DISTRICT
SM256470,150.83,-23.99,Orambul,ETHNICITY,ETHNICITY
SM256470,150.83,-23.99,Orambul,ETHNICITY,ETHNICITY
SM256470,150.8852,-23.6437,Orambul,ETHNICITY,ETHNICITY
SM256470,150.8852,-23.6437,Orambul,ETHNICITY,ETHNICITY
SM2596,-0.5,35.6667,Oran,ADM1,DISTRICT
SM457608,-0.5,35.5,Oran District,ADM2,DISTRICT
SM241737,104.008,1.883,Orang Kanaq,LANGUAGE,LANGUAGE
SM256471,103.85,2.23,Orang Kanaq,ETHNICITY,ETHNICITY
SM256472,103.27,2.08,Orang Laut,ETHNICITY,ETHNICITY
SM241738,103.766,1.4584,Orang Seletar,LANGUAGE,LANGUAGE
SM303061,-78.0135,38.2462,Orange,ADM2,DISTRICT
SM302730,-93.8939,30.1213,Orange,ADM2,DISTRICT
SM302935,-72.3766,44.0056,Orange,ADM2,DISTRICT
SM301897,-74.3056,41.4021,Orange,ADM2,DISTRICT
SM301995,-79.1206,36.0613,Orange,ADM2,DISTRICT
SM300747,-86.4951,38.5418,Orange,ADM2,DISTRICT
SM300355,-81.3235,28.5144,Orange,ADM2,DISTRICT
SM303186,-117.7762,33.6769,Orange,ADM2,DISTRICT
SM12600,-77.8278,18.454,Orange,ADM2,DISTRICT
SM261444,149.1229,-33.3283,Orange,ADM2,DISTRICT
SM12601,-78.3201,18.3427,Orange Bay (Hanover),ADM2,DISTRICT
SM18510,-76.6225,18.2031,Orange Bay (Portland),ADM2,DISTRICT
SM12602,-77.3594,18.1179,Orange Hill (Clarendon),ADM2,DISTRICT
SM18509,-78.3028,18.2342,Orange Hill (Westmoreland),ADM2,DISTRICT
SM2597,-88.8,17.7667,Orange Walk,ADM1,DISTRICT
SM302463,-80.8003,33.439,Orangeburg,ADM2,DISTRICT
SM284459,16.8323,-28.0408,Oranjemund,ADM2,DISTRICT
SM256473,83.2,22.94,Oraon,ETHNICITY,ETHNICITY
SM256473,83.2,22.94,Oraon,ETHNICITY,ETHNICITY
SM256473,85,23,Oraon,ETHNICITY,ETHNICITY
SM256473,85,23,Oraon,ETHNICITY,ETHNICITY
SM241740,88.9157,24.7458,Oraon Sadri,LANGUAGE,LANGUAGE
SM16823,25.8349,47.7008,Oras Solca (2007-),ADM2,DISTRICT
SM16824,25.0849,46.609,Oras Sovata (2004-),ADM2,DISTRICT
SM16825,28.5954,44.055,Oras Techirghiol,ADM2,DISTRICT
SM290772,23.1989,45.8384,Orastie,ADM2,DISTRICT
SM290773,23.1821,45.7015,Orastioara De Sus,ADM2,DISTRICT
SM299776,29.5329,49.1879,Orativs'kyi,ADM2,DISTRICT
SM275320,-90.119,14.1135,Oratorio,ADM2,DISTRICT
SM295652,-89.0535,13.8261,Oratorio de Concepción,ADM2,DISTRICT
SM263712,-42.7974,-20.4373,Oratórios,ADM2,DISTRICT
SM290037,21.7074,45.0592,Oravita,ADM2,DISTRICT
SM291908,25.3271,44.1161,Orbeasca De Jos,ADM2,DISTRICT
SM292135,27.0187,46.2693,Orbeni,ADM2,DISTRICT
SM454504,-3,59,Orcadian,ETHNICITY,ETHNICITY
SM454504,-3,59,Orcadian,ETHNICITY,ETHNICITY
SM454504,-3,59,Orcadian,ETHNICITY,ETHNICITY
SM454504,-3,59,Orcadian,ETHNICITY,ETHNICITY
SM279882,69.25,42.5833,Ordabasynskiy,ADM2,DISTRICT
SM2598,1.5257,42.5976,Ordino,ADM1,DISTRICT
SM269264,108.8095,39.4614,Ordos,ADM2,DISTRICT
SM241742,109.781,39.612,Ordos,DIALECT,LANGUAGE
SM2599,37.6845,40.9086,Ordu,ADM1,DISTRICT
SM261943,45.9167,39.0833,Ordubad,ADM1:ADM2,DISTRICT
SM293874,81.6667,54.3333,Ordynskiy rayon,ADM2,DISTRICT
SM11334,44.75,41.7,Ordzhonikidzevskiy Rayon,ADM2,DISTRICT
SM9724,50.0333,40.4,Ordzhonikidzevskiy Rayon,ADM2,DISTRICT
SM9671,44.5,40.1833,Ordzhonikidzevskiy Rayon,ADM2,DISTRICT
SM293208,89.1667,55,Ordzhonikidzevskiy rayon,ADM2,DISTRICT
SM270918,-83.8523,9.9636,Oreamuno,ADM2,DISTRICT
SM305636,17.1685,42.9858,Orebić,ADM2,DISTRICT
SM2600,15,59.5,Orebro,ADM1,DISTRICT
SM284690,5.5511,6.2358,Oredo Edo,ADM2,DISTRICT
SM301595,-91.4033,36.6867,Oregon,ADM2,DISTRICT
SM2601,-120.5014,44.0001,Oregon,ADM1,DISTRICT
SM305344,16.5084,46.3307,Orehovica,ADM2,DISTRICT
SM17031,39,55.75,Orekhovo-Zuyevskiy Rayon,ADM2,DISTRICT
SM2602,36.25,53,Orel,ADM1,DISTRICT
SM273533,-76.9667,-0.4667,Orellana,ADM2,DISTRICT
SM2603,-76.4167,-0.75,Orellana,ADM1,DISTRICT
SM285165,3.75,8.8333,Orelope,ADM2,DISTRICT
SM256474,-64.12,2.39,Oremano,ETHNICITY,ETHNICITY
SM2604,56,52,Orenburg,ADM1,DISTRICT
SM10945,33.0315,35.3594,Orga,ADM2,DISTRICT
SM298249,28.9979,39.9141,Orhaneli,ADM2,DISTRICT
SM298250,29.3121,40.4998,Orhangazi,ADM2,DISTRICT
SM2605,28.8333,47.4167,Orhei,ADM1,DISTRICT
SM284691,5.9841,6.2535,Orhionmw,ADM2,DISTRICT
SM2606,104.1627,49.0631,Orhon,ADM1,DISTRICT
SM15006,103.5336,48.6212,Orhon Sum (excl. Bulgan City),ADM2,DISTRICT
SM15006,103.5336,48.6212,Orhon Sum (excl. Bulgan City),ADM2,DISTRICT
SM15006,103.4699,48.6382,Orhon Sum (excl. Bulgan City),ADM2,DISTRICT
SM15006,103.4699,48.6382,Orhon Sum (excl. Bulgan City),ADM2,DISTRICT
SM285166,4.1442,8.3342,Ori-Ire,ADM2,DISTRICT
SM285136,4.8667,7.5833,Oriade,ADM2,DISTRICT
SM275919,-86.9576,14.8065,Orica,ADM2,DISTRICT
SM293277,48.75,58.25,Orichevskiy rayon,ADM2,DISTRICT
SM282243,-97.5868,19.3478,Oriental,ADM2,DISTRICT
SM4277,-2.1258,34.827,Oriental (2015-),ADM1,DISTRICT
SM2608,121.268,13.1556,Oriental Mindoro,ADM1:ADM2,DISTRICT
SM266750,-50.0968,-22.1501,Oriente,ADM2,DISTRICT
SM15977,-66.0348,18.4145,Oriente Barrio,ADM2,DISTRICT
SM241755,-84.0105,9.9351,Original Costa Rican Sign Language,LANGUAGE,LANGUAGE
SM299820,35.7846,47.5752,Orikhivs'kyi,ADM2,DISTRICT
SM266751,-49.3759,-20.2224,Orindiúva,ADM2,DISTRICT
SM241757,8.1201,6.6875,Oring,LANGUAGE,LANGUAGE
SM305567,17.7554,45.1638,Oriovac,ADM2,DISTRICT
SM270405,-76.9347,0.6682,Orito,ADM2,DISTRICT
SM256476,149.09,-9.92,Oriwa,ETHNICITY,ETHNICITY
SM264205,-56.8474,0.5659,Oriximiná,ADM2,DISTRICT
SM256478,85,20,Oriya,ETHNICITY,ETHNICITY
SM282751,-97.1093,18.8569,Orizaba,ADM2,DISTRICT
SM241761,-96.9765,18.64,Orizaba Nahuatl,LANGUAGE,LANGUAGE
SM268117,-48.1527,-16.9923,Orizona,ADM2,DISTRICT
SM263714,-42.2116,-20.5055,Orizânia,ADM2,DISTRICT
SM283670,105.3881,49.1379,Orkhon,ADM2,DISTRICT
SM283781,106.1595,49.8347,Orkhon,ADM2,DISTRICT
SM283671,104.9754,48.943,Orkhontuul,ADM2,DISTRICT
SM283671,104.9754,48.943,Orkhontuul,ADM2,DISTRICT
SM283671,104.9944,48.8282,Orkhontuul,ADM2,DISTRICT
SM283671,104.9944,48.8282,Orkhontuul,ADM2,DISTRICT
SM4691,9.6931,63.2302,Orkland (2020-),ADM2,DISTRICT
SM274728,-3,59,Orkney Islands,ADM1:ADM2,DISTRICT
SM241764,168.2121,-16.2105,Orkon-Fanbak,LANGUAGE,LANGUAGE
SM266752,-47.8953,-20.6891,Orlandia,ADM2,DISTRICT
SM291739,23.9686,45.7555,Orlat,ADM2,DISTRICT
SM305653,16.2341,45.6847,Orle,ADM2,DISTRICT
SM266224,-49.3542,-28.3001,Orleaes,ADM2,DISTRICT
SM302923,-72.2438,44.8288,Orleans,ADM2,DISTRICT
SM301898,-78.189,43.2507,Orleans,ADM2,DISTRICT
SM301137,-89.9281,30.0686,Orleans,ADM2,DISTRICT
SM292214,24.2462,44.7813,Orlesti,ADM2,DISTRICT
SM293278,48.7422,58.6631,Orlovskiy rayon,ADM2,DISTRICT
SM284767,7.0389,5.8072,Orlu,ADM2,DISTRICT
SM241767,39.6075,-1.7949,Orma,LANGUAGE,LANGUAGE
SM10946,33.7815,34.9913,Ormideia,ADM2,DISTRICT
SM241769,140.6015,-2.5136,Ormu,LANGUAGE,LANGUAGE
SM241768,69.3444,32.6819,Ormuri,LANGUAGE,LANGUAGE
SM256482,140.94,-2.78,Ormut,ETHNICITY,ETHNICITY
SM274515,0.0833,48.6667,Orne,ADM2,DISTRICT
SM256483,-76.97,20.96,Ornoray,ETHNICITY,ETHNICITY
SM241782,8.1139,4.8613,Oro,LANGUAGE,LANGUAGE
SM2609,148.5,-9,Oro,ADM1,DISTRICT
SM241789,-64.0299,-10.737,Oro Win,LANGUAGE,LANGUAGE
SM9974,-35.5994,-7.7118,Orobo,ADM2,DISTRICT
SM241771,137.851,50.3235,Oroch,LANGUAGE,LANGUAGE
SM256484,134.57,49.48,Oroch,ETHNICITY,ETHNICITY
SM256484,134.57,49.48,Oroch,ETHNICITY,ETHNICITY
SM256484,138,51,Oroch,ETHNICITY,ETHNICITY
SM256484,138,51,Oroch,ETHNICITY,ETHNICITY
SM9975,-39.5779,-8.4941,Oroco,ADM2:ADM3,DISTRICT
SM2610,-66.4371,18.2189,Orocovis,ADM1,DISTRICT
SM15978,-66.3816,18.2222,Orocovis Barrio,ADM2,DISTRICT
SM15979,-66.3918,18.2252,Orocovis Barrio-Pueblo,ADM2,DISTRICT
SM275814,-87.1317,13.4922,Orocuina,ADM2,DISTRICT
SM270896,-71.5972,4.9219,Orocué,ADM2,DISTRICT
SM290388,23.2739,44.2641,Orodel,ADM2,DISTRICT
SM241772,161.513,-9.7386,Oroha,LANGUAGE,LANGUAGE
SM241774,143.128,49.3639,Orok,LANGUAGE,LANGUAGE
SM256485,142.64,52.76,Orok,ETHNICITY,ETHNICITY
SM256485,142.64,52.76,Orok,ETHNICITY,ETHNICITY
SM256485,142.71,54.01,Orok,ETHNICITY,ETHNICITY
SM256485,142.71,54.01,Orok,ETHNICITY,ETHNICITY
SM241778,148.09,-8.7902,Orokaiva,LANGUAGE,LANGUAGE
SM256486,148.14,-8.54,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148.14,-8.54,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148.14,-8.54,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148.14,-8.54,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148.25,-8.75,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148.25,-8.75,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148.25,-8.75,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148.25,-8.75,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148,-9,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148,-9,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148,-9,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148,-9,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148,-8.5,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148,-8.5,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148,-8.5,Orokaiva,ETHNICITY,ETHNICITY
SM256486,148,-8.5,Orokaiva,ETHNICITY,ETHNICITY
SM241775,9.1521,4.895,Oroko,LANGUAGE,LANGUAGE
SM241776,145.34,-7.8131,Orokolo,LANGUAGE,LANGUAGE
SM256487,145.04,-7.65,Orokolo,ETHNICITY,ETHNICITY
SM256487,145.04,-7.65,Orokolo,ETHNICITY,ETHNICITY
SM256487,145.31,-7.82,Orokolo,ETHNICITY,ETHNICITY
SM256487,145.31,-7.82,Orokolo,ETHNICITY,ETHNICITY
SM285137,4.4728,7.9016,Orolu,ADM2,DISTRICT
SM14959,155.3,7.516,Oroluk Municipality,ADM2,DISTRICT
SM274350,40,10.6667,Oromia,ADM2,DISTRICT
SM274350,40,10.6667,Oromia,ADM2,DISTRICT
SM274350,40.0241,10.7006,Oromia,ADM2,DISTRICT
SM274350,40.0241,10.7006,Oromia,ADM2,DISTRICT
SM2611,39,8,Oromia,ADM1,DISTRICT
SM256488,35.19,7.93,Oromo,ETHNICITY,ETHNICITY
SM256488,35.19,7.93,Oromo,ETHNICITY,ETHNICITY
SM256488,45.27,7.97,Oromo,ETHNICITY,ETHNICITY
SM256488,45.27,7.97,Oromo,ETHNICITY,ETHNICITY
SM256488,42.71,6.08,Oromo,ETHNICITY,ETHNICITY
SM256488,42.71,6.08,Oromo,ETHNICITY,ETHNICITY
SM256488,39.62,7.23,Oromo,ETHNICITY,ETHNICITY
SM256488,39.62,7.23,Oromo,ETHNICITY,ETHNICITY
SM256488,36.5,9,Oromo,ETHNICITY,ETHNICITY
SM256488,36.5,9,Oromo,ETHNICITY,ETHNICITY
SM285102,8.2301,4.8029,Oron,ADM2,DISTRICT
SM13339,-172.1833,-4.5167,Orona,ADM2,DISTRICT
SM262693,-65.2472,-18.8916,Oropeza,ADM2,DISTRICT
SM275912,-86.8321,13.8109,Oropolí,ADM2,DISTRICT
SM241784,125.017,50.6319,Oroqen,LANGUAGE,LANGUAGE
SM256490,126.27,52.2,Oroqen,ETHNICITY,ETHNICITY
SM256490,126.27,52.2,Oroqen,ETHNICITY,ETHNICITY
SM256490,126.27,52.2,Oroqen,ETHNICITY,ETHNICITY
SM256490,126.27,52.2,Oroqen,ETHNICITY,ETHNICITY
SM256490,122.5,51.91,Oroqen,ETHNICITY,ETHNICITY
SM256490,122.5,51.91,Oroqen,ETHNICITY,ETHNICITY
SM256490,122.5,51.91,Oroqen,ETHNICITY,ETHNICITY
SM256490,122.5,51.91,Oroqen,ETHNICITY,ETHNICITY
SM256490,126.5,50.4339,Oroqen,ETHNICITY,ETHNICITY
SM256490,126.5,50.4339,Oroqen,ETHNICITY,ETHNICITY
SM256490,126.5,50.4339,Oroqen,ETHNICITY,ETHNICITY
SM256490,126.5,50.4339,Oroqen,ETHNICITY,ETHNICITY
SM256490,126.5,50.5,Oroqen,ETHNICITY,ETHNICITY
SM256490,126.5,50.5,Oroqen,ETHNICITY,ETHNICITY
SM256490,126.5,50.5,Oroqen,ETHNICITY,ETHNICITY
SM256490,126.5,50.5,Oroqen,ETHNICITY,ETHNICITY
SM241785,72.7418,38.388,Oroshor,DIALECT,LANGUAGE
SM276227,20.7336,46.5636,Orosház,ADM2,DISTRICT
SM276062,15.9155,45.9963,Oroslavje,ADM2,DISTRICT
SM276140,18.2564,47.5025,Oroszlány,ADM2,DISTRICT
SM270911,-84.5787,9.8848,Orotina,ADM2,DISTRICT
SM256491,-83.54,8.65,Orotina,ETHNICITY,ETHNICITY
SM10947,33.0821,35.093,Orounta,ADM2,DISTRICT
SM241788,165.473,-21.6269,Orowe,LANGUAGE,LANGUAGE
SM261720,138.6647,-32.5318,Orroroo/Carrieton,ADM2,DISTRICT
SM296543,14.75,61.3,Orsa,ADM2,DISTRICT
SM293620,47.9167,56.9167,Orshanskiy rayon,ADM2,DISTRICT
SM291090,22.3977,44.7252,Orsova,ADM2,DISTRICT
SM4692,6.1308,62.1994,Orsta (2020-),ADM2,DISTRICT
SM284768,6.9976,5.8615,Orsu,ADM2,DISTRICT
SM298275,33.1146,40.6341,Orta,ADM2,DISTRICT
SM298761,28.7773,36.8389,Ortaca,ADM2,DISTRICT
SM18093,39.89,40.8803,Ortahisar,ADM2,DISTRICT
SM10948,33.3347,35.1997,Ortakioi,ADM2,DISTRICT
SM298642,34.0435,38.7477,Ortaköy,ADM2,DISTRICT
SM298288,35.2588,40.283,Ortaköy,ADM2,DISTRICT
SM270573,-75.2725,3.9451,Ortega,ADM2,DISTRICT
SM264606,-51.0007,-24.1272,Ortigueira,ADM2,DISTRICT
SM292005,21.2237,45.9521,Ortisoara,ADM2,DISTRICT
SM15980,-66.2149,18.3485,Ortiz Barrio,ADM2,DISTRICT
SM303602,-67.2905,9.6217,Ortíz,ADM2,DISTRICT
SM256493,5.81,5.48,Oru,ETHNICITY,ETHNICITY
SM284769,6.9602,5.7056,Oru East,ADM2,DISTRICT
SM284770,6.9109,5.7422,Oru West,ADM2,DISTRICT
SM285103,7.7167,4.9,Oruk-Ana,ADM2,DISTRICT
SM241792,6.383,4.9377,Oruma,LANGUAGE,LANGUAGE
SM285299,7.0833,6.0667,OrumbaNo,ADM2,DISTRICT
SM285301,7.2167,5.9667,OrumbaSo,ADM2,DISTRICT
SM277649,45,37.55,Orumiyeh,ADM2,DISTRICT
SM2612,-67.5,-18.6667,Oruro,ADM1,DISTRICT
SM296592,11.6342,58.1803,Orust,ADM2,DISTRICT
SM241799,139.89,-2.7648,Orya,LANGUAGE,LANGUAGE
SM262495,23.9864,43.6904,Oryahovo,ADM2,DISTRICT
SM299649,32.7001,49.791,Orzhyts'kyi,ADM2,DISTRICT
SM260953,-64.25,-23.3333,Orán,ADM2,DISTRICT
SM267809,-38.9676,-6.255,Orós,ADM2,DISTRICT
SM286359,11.2281,62.4954,Os,ADM2,DISTRICT
SM270958,-83.4193,8.8418,Osa,ADM2,DISTRICT
SM241800,-96.2949,36.7828,Osage,LANGUAGE,LANGUAGE
SM302224,-96.3985,36.6292,Osage,ADM2,DISTRICT
SM301596,-91.8618,38.4604,Osage,ADM2,DISTRICT
SM300956,-95.727,38.6523,Osage,ADM2,DISTRICT
SM256496,-92.23,37.5,Osage,ETHNICITY,ETHNICITY
SM256496,-95.14,35.77,Osage,ETHNICITY,ETHNICITY
SM279109,135.5022,34.6937,Osaka,ADM2,DISTRICT
SM2613,135.5197,34.6863,Osaka,ADM1:ADMX,DISTRICT
SM13220,73.5,50.5,Osakarovka Audany (excl. Molodezhny),ADM2,DISTRICT
SM241803,13.61,-0.012,Osamayi,LANGUAGE,LANGUAGE
SM280711,127.0525,37.1623,Osan,ADM2,DISTRICT
SM266753,-46.7884,-23.5263,Osasco,ADM2,DISTRICT
SM241804,9.8019,6.2083,Osatu,LANGUAGE,LANGUAGE
SM300957,-98.768,39.3503,Osborne,ADM2,DISTRICT
SM12603,-77.3283,17.9589,Osbourne Store,ADM2,DISTRICT
SM296441,14.1184,56.4408,Osby,ADM2,DISTRICT
SM241806,15.67,40.98,Oscan,LANGUAGE,LANGUAGE
SM266755,-50.2565,-22.2903,Oscar Bressane,ADM2,DISTRICT
SM301319,-85.3253,43.9899,Osceola,ADM2,DISTRICT
SM300853,-95.6237,43.3786,Osceola,ADM2,DISTRICT
SM300356,-81.1495,28.0627,Osceola,ADM2,DISTRICT
SM301320,-84.1297,44.6818,Oscoda,ADM2,DISTRICT
SM285075,5.7433,7.055,Ose,ADM2,DISTRICT
SM286187,10.5128,64.2988,Osen,ADM2,DISTRICT
SM256497,138.92,-7.04,Oser,ETHNICITY,ETHNICITY
SM292299,27.4483,46.7638,Osesti,ADM2,DISTRICT
SM256498,45.43,41.94,Osetian,ETHNICITY,ETHNICITY
SM256498,45.43,41.94,Osetian,ETHNICITY,ETHNICITY
SM256498,45.43,41.94,Osetian,ETHNICITY,ETHNICITY
SM256498,45.43,41.94,Osetian,ETHNICITY,ETHNICITY
SM256498,44,43,Osetian,ETHNICITY,ETHNICITY
SM256498,44,43,Osetian,ETHNICITY,ETHNICITY
SM256498,44,43,Osetian,ETHNICITY,ETHNICITY
SM256498,44,43,Osetian,ETHNICITY,ETHNICITY
SM256498,44.65,43.0167,Osetian,ETHNICITY,ETHNICITY
SM256498,44.65,43.0167,Osetian,ETHNICITY,ETHNICITY
SM256498,44.65,43.0167,Osetian,ETHNICITY,ETHNICITY
SM256498,44.65,43.0167,Osetian,ETHNICITY,ETHNICITY
SM256498,43.55,43.12,Osetian,ETHNICITY,ETHNICITY
SM256498,43.55,43.12,Osetian,ETHNICITY,ETHNICITY
SM256498,43.55,43.12,Osetian,ETHNICITY,ETHNICITY
SM256498,43.55,43.12,Osetian,ETHNICITY,ETHNICITY
SM2614,73,40,Osh,ADM1,DISTRICT
SM2615,72.7939,40.5445,Osh (city),ADM1,DISTRICT
SM284467,15.6833,-17.7833,Oshakati East,ADM2,DISTRICT
SM284468,15.6833,-17.7833,Oshakati West,ADM2,DISTRICT
SM278155,140.3027,42.5298,Oshamanbe,ADM2,DISTRICT
SM2616,15.75,-18.5,Oshana,ADM1,DISTRICT
SM284533,15.9623,-17.4268,Oshikango,ADM2,DISTRICT
SM2617,17.0833,-18.5,Oshikoto,ADM1,DISTRICT
SM284559,15.473,-17.6556,Oshikuku,ADM2,DISTRICT
SM13061,139.4096,34.7405,Oshima Shicho,ADM2,DISTRICT
SM13062,129.2466,28.2476,Oshima-gun,ADM2,DISTRICT
SM284654,6.6445,6.3227,Oshimili North,ADM2,DISTRICT
SM284655,6.6817,6.0921,Oshimili South,ADM2,DISTRICT
SM250552,15.02,-17.96,Oshivambo,ETHNICITY,ETHNICITY
SM250552,15.02,-17.96,Oshivambo,ETHNICITY,ETHNICITY
SM250552,15.02,-17.96,Oshivambo,ETHNICITY,ETHNICITY
SM250552,16,-17,Oshivambo,ETHNICITY,ETHNICITY
SM250552,16,-17,Oshivambo,ETHNICITY,ETHNICITY
SM250552,16,-17,Oshivambo,ETHNICITY,ETHNICITY
SM250552,18.06,-17.73,Oshivambo,ETHNICITY,ETHNICITY
SM250552,18.06,-17.73,Oshivambo,ETHNICITY,ETHNICITY
SM250552,18.06,-17.73,Oshivambo,ETHNICITY,ETHNICITY
SM277650,45.0833,37.05,Oshnaviyeh,ADM2,DISTRICT
SM284998,3.3353,6.5607,Oshodi/Isolo,ADM2,DISTRICT
SM284998,3.3353,6.5607,Oshodi/Isolo,ADM2,DISTRICT
SM284998,3.3024,6.5592,Oshodi/Isolo,ADM2,DISTRICT
SM284998,3.3024,6.5592,Oshodi/Isolo,ADM2,DISTRICT
SM295724,-88.1167,13.7833,Osicala,ADM2,DISTRICT
SM275959,18.6667,45.5833,Osijek,ADM2,DISTRICT
SM296274,14.7283,45.5489,Osilnica,ADM1:ADM2,DISTRICT
SM241808,114.292,-8.2526,Osing,LANGUAGE,LANGUAGE
SM292900,104,53.5833,Osinskiy rayon,ADM2,DISTRICT
SM284634,7.3307,5.1993,Osisioma Ngwa,ADM2,DISTRICT
SM2618,18.6298,45.54,Osjecko-Baranjska,ADM1,DISTRICT
SM296665,16.383,57.3734,Oskarshamn,ADM2,DISTRICT
SM13221,82.6667,49.9667,Oskemen Qalasy,ADM2,DISTRICT
SM2619,10.7336,59.9119,Oslo,ADM1,DISTRICT
SM286127,10.7337,59.9118,Oslo (1948-),ADM2,DISTRICT
SM313237,18.9331,44.3925,Osmaci,ADM2:ADM3,DISTRICT
SM277047,76.1667,18.25,Osmanabad (1989-),ADM2,DISTRICT
SM298289,34.7824,41.0024,Osmancık,ADM2,DISTRICT
SM298197,30.0268,40.3674,Osmaneli,ADM2,DISTRICT
SM298251,29.0875,40.1564,Osmangazi,ADM2,DISTRICT
SM2621,36.246,37.2353,Osmaniye,ADM1,DISTRICT
SM256500,-83.17,30.67,Osochi,ETHNICITY,ETHNICITY
SM285139,4.5763,7.7596,Osogbo,ADM2,DISTRICT
SM290852,27.7867,47.07,Osoi,ADM2,DISTRICT
SM292448,22.0449,47.0275,Osorhei,ADM2,DISTRICT
SM269117,-72.953,-40.7794,Osorno,ADM2,DISTRICT
SM241814,6.2151,7.4287,Ososo,LANGUAGE,LANGUAGE
SM270334,-77.5525,1.0296,Ospina,ADM2,DISTRICT
SM303691,-69.4167,9.3333,Ospino,ADM2,DISTRICT
SM297811,126.3832,-8.7348,Ossu,ADM2,DISTRICT
SM17032,33.25,57.1667,Ostashkovskiy Rayon,ADM2,DISTRICT
SM286392,5.5055,60.5239,Osterøy,ADM2,DISTRICT
SM237043,8.621,50.005,Ostfränkisch,LANGUAGE,LANGUAGE
SM291822,25.7559,47.4048,Ostra,ADM2,DISTRICT
SM271235,18.25,49.8333,Ostrava,ADM2,DISTRICT
SM274400,22.1667,63.0833,Ostrobothnia,ADM1:ADM2,DISTRICT
SM294981,39.0833,50.75,Ostrogozhskiy rayon,ADM2,DISTRICT
SM292065,28.1464,44.9105,Ostrov,ADM2,DISTRICT
SM290186,27.4205,44.0815,Ostrov,ADM2,DISTRICT
SM290389,23.9079,43.8212,Ostroveni,ADM2,DISTRICT
SM17033,28.3333,57.25,Ostrovskiy Rayon,ADM2,DISTRICT
SM293340,42.25,57.6667,Ostrovskiy rayon,ADM2,DISTRICT
SM288764,21.4074,50.9423,Ostrowiec,ADM2,DISTRICT
SM299666,26.5177,50.3273,Ostroz'kyi,ADM2,DISTRICT
SM288958,21.507,53.14,Ostrołęka,ADM2,DISTRICT
SM288957,21.5891,53.0779,Ostrołęka (City),ADM2,DISTRICT
SM288800,18.0433,51.4377,Ostrzeszów,ADM2,DISTRICT
SM288775,19.9144,53.7291,Ostróda,ADM2,DISTRICT
SM288959,22.0325,52.795,Ostrów Mazowiecka,ADM2,DISTRICT
SM288799,17.8503,51.608,Ostrów Wielkopolski,ADM2,DISTRICT
SM283173,-93.4171,17.4408,Ostuacán,ADM2,DISTRICT
SM275215,-91.6884,14.8748,Ostuncalco,ADM2,DISTRICT
SM283174,-93.0701,16.8878,Osumacinta,ADM2,DISTRICT
SM2624,4.5,7.5,Osun,ADM1,DISTRICT
SM13721,26.7697,56.8161,Osupes pagasts,ADM2,DISTRICT
SM266756,-50.842,-21.675,Osvaldo Cruz,ADM2,DISTRICT
SM301899,-76.2087,43.4639,Oswego,ADM2,DISTRICT
SM265801,-50.2347,-29.8945,Osório,ADM2,DISTRICT
SM241842,113.469,-0.3955,Ot Danum,LANGUAGE,LANGUAGE
SM256506,113.85,-0.72,Ot-Danom Dayak,ETHNICITY,ETHNICITY
SM266225,-49.949,-27.5145,Otacílio Costa,ADM2,DISTRICT
SM2625,169.535,-45.2639,Otago,ADM1,DISTRICT
SM270797,-74.2031,5.7753,Otanche,ADM2,DISTRICT
SM241838,9.3402,6.5481,Otank,LANGUAGE,LANGUAGE
SM13722,21.1236,56.4089,Otanku pagasts,ADM2,DISTRICT
SM278156,141.0257,43.1563,Otaru,ADM2,DISTRICT
SM282753,-96.0347,18.1637,Otatitlán,ADM2,DISTRICT
SM273459,-78.1726,0.2241,Otavalo,ADM2,DISTRICT
SM284484,17.3314,-19.6381,Otavi,ADM2,DISTRICT
SM279328,140.1192,36.8613,Otawara,ADM2,DISTRICT
SM2626,103.5,14.1667,Otdar Mean Chey,ADM1,DISTRICT
SM282754,-94.6779,17.9788,Oteapan,ADM2,DISTRICT
SM290853,27.0273,47.0857,Oteleni,ADM2,DISTRICT
SM290038,22.3814,45.5164,Otelu Rosu,ADM2,DISTRICT
SM11181,26.4531,58.0218,Otepaeae vald,ADM2,DISTRICT
SM301846,-105.7417,32.6132,Otero,ADM2,DISTRICT
SM303358,-103.7165,37.9027,Otero,ADM2,DISTRICT
SM292215,24.0399,45.0481,Otesani,ADM2,DISTRICT
SM283827,97.6063,47.2032,Otgon,ADM2,DISTRICT
SM282472,-88.3867,18.5231,Othón P. Blanco (pre-2011),ADM2,DISTRICT
SM241843,-50.558,-24.3153,Oti,LANGUAGE,LANGUAGE
SM3936,0.3,7.9,Oti,ADM1,DISTRICT
SM284547,20.75,-21.8333,Otjombinde,ADM2,DISTRICT
SM2628,18,-20.3333,Otjozondjupa,ADM1,DISTRICT
SM298384,40.0214,39.9721,Otlukbeli,ADM2,DISTRICT
SM256569,-93.87,42.93,Oto,ETHNICITY,ETHNICITY
SM256569,-93.87,42.93,Oto,ETHNICITY,ETHNICITY
SM256569,-95,40,Oto,ETHNICITY,ETHNICITY
SM256569,-95,40,Oto,ETHNICITY,ETHNICITY
SM278157,140.1776,42.0377,Otobe,ADM2,DISTRICT
SM301740,-96.1348,40.6485,Otoe,ADM2,DISTRICT
SM305473,16.7349,43.6866,Otok,ADM2,DISTRICT
SM276018,18.8833,45.1459,Otok,ADM2,DISTRICT
SM256570,-65.46,8.65,Otomac,ETHNICITY,ETHNICITY
SM241853,-66.499,7.6198,Otomaco,LANGUAGE,LANGUAGE
SM241849,-70.9973,7.8699,Otomaco-Taparita,FAMILY,LANGUAGE
SM256571,-99.05,21.69,Otomi,ETHNICITY,ETHNICITY
SM256571,-99.05,21.69,Otomi,ETHNICITY,ETHNICITY
SM256571,-99,20,Otomi,ETHNICITY,ETHNICITY
SM256571,-99,20,Otomi,ETHNICITY,ETHNICITY
SM290969,26.0653,44.5422,Otopeni,ADM2,DISTRICT
SM241857,30.573,11.1187,Otoro,LANGUAGE,LANGUAGE
SM256572,30.667,11.333,Otoro,ETHNICITY,ETHNICITY
SM256572,30.667,11.333,Otoro,ETHNICITY,ETHNICITY
SM256572,30.667,11.333,Otoro,ETHNICITY,ETHNICITY
SM256572,31,12,Otoro,ETHNICITY,ETHNICITY
SM256572,31,12,Otoro,ETHNICITY,ETHNICITY
SM256572,31,12,Otoro,ETHNICITY,ETHNICITY
SM256572,30.67,11.33,Otoro,ETHNICITY,ETHNICITY
SM256572,30.67,11.33,Otoro,ETHNICITY,ETHNICITY
SM256572,30.67,11.33,Otoro,ETHNICITY,ETHNICITY
SM286512,175.3333,-38.25,Otorohanga,ADM2,DISTRICT
SM276070,15.2375,44.8688,Otočac,ADM2,DISTRICT
SM293386,41.5794,44.2203,Otradnenskiy rayon,ADM2,DISTRICT
SM279883,67.5,42.6667,Otrarskiy,ADM2,DISTRICT
SM301901,-75.0326,42.6338,Otsego,ADM2,DISTRICT
SM301321,-84.599,45.0214,Otsego,ADM2,DISTRICT
SM241859,-83.226,44.5988,Ottawa,LANGUAGE,LANGUAGE
SM302225,-94.8104,36.8355,Ottawa,ADM2,DISTRICT
SM302142,-82.9413,41.4968,Ottawa,ADM2,DISTRICT
SM301322,-86.1795,43.0026,Ottawa,ADM2,DISTRICT
SM300958,-97.6502,39.1325,Ottawa,ADM2,DISTRICT
SM268871,-75.6972,45.4186,Ottawa,ADM2,DISTRICT
SM256573,-83.9,46.76,Ottawa,ETHNICITY,ETHNICITY
SM256573,-83.9,46.76,Ottawa,ETHNICITY,ETHNICITY
SM256573,-82.55,45.52,Ottawa,ETHNICITY,ETHNICITY
SM256573,-82.55,45.52,Ottawa,ETHNICITY,ETHNICITY
SM256573,-82,46,Ottawa,ETHNICITY,ETHNICITY
SM256573,-82,46,Ottawa,ETHNICITY,ETHNICITY
SM301390,-95.708,46.4088,Otter Tail,ADM2,DISTRICT
SM250625,32.53,4.03,Otuha,ETHNICITY,ETHNICITY
SM250625,32.53,4.03,Otuha,ETHNICITY,ETHNICITY
SM250625,33,4,Otuha,ETHNICITY,ETHNICITY
SM250625,33,4,Otuha,ETHNICITY,ETHNICITY
SM241863,32.6641,4.1638,Otuho,LANGUAGE,LANGUAGE
SM241864,-59.6986,-15.2698,Otuke,LANGUAGE,LANGUAGE
SM299326,33.342,2.4837,Otuke,ADM1:ADM2,DISTRICT
SM256574,-62.01,-17.83,Otuke,ETHNICITY,ETHNICITY
SM281949,-98.7556,19.7001,Otumba,ADM2,DISTRICT
SM285348,8.0749,7.3074,Oturkpo,ADM2,DISTRICT
SM286761,-78.5636,-7.9046,Otuzco,ADM2,DISTRICT
SM288960,21.3725,52.049,Otwock,ADM2,DISTRICT
SM281950,-100.3351,19.1149,Otzoloapan,ADM2,DISTRICT
SM281951,-99.5441,19.4353,Otzolotepec,ADM2,DISTRICT
SM281506,-106.0097,24.7042,Otáez,ADM2,DISTRICT
SM10108,102.82,13.6672,Ou Chrov District (excl. Paoy Pet),ADM2,DISTRICT
SM280421,107.0347,13.8407,Ou Chum,ADM2,DISTRICT
SM280370,107.3244,12.3956,Ou Reang,ADM2,DISTRICT
SM280463,105.5388,11.8128,Ou Reang Ov,ADM2,DISTRICT
SM280422,107.4464,13.5433,Ou Ya Dav,ADM2,DISTRICT
SM301138,-92.1549,32.4783,Ouachita,ADM2,DISTRICT
SM302399,-92.8819,33.5934,Ouachita,ADM2,DISTRICT
SM283991,-15.474,18.352,Ouad-Naga,ADM2,DISTRICT
SM14195,-10.9491,21.4596,Ouadane,ADM2,DISTRICT
SM268589,22.4846,8.0085,Ouadda,ADM2,DISTRICT
SM250459,20.56,14.99,Ouaddai,ETHNICITY,ETHNICITY
SM2629,21.25,13.5,Ouaddaï,ADM1:ADMX,DISTRICT
SM10331,19.0531,13.5552,Ouadi Rime,ADM2,DISTRICT
SM2630,20.75,6.25,Ouaka,ADM1,DISTRICT
SM262166,1.4371,9.5996,Ouaké,ADM2,DISTRICT
SM14196,-7.0117,17.4463,Oualata (excl. Dhar),ADM2,DISTRICT
SM276092,-71.7652,19.4706,Ouanaminthe,ADM2,DISTRICT
SM268579,22.4274,9.0588,Ouanda Djallé,ADM2,DISTRICT
SM2631,45.1369,-12.8473,Ouangani,ADM1,DISTRICT
SM10250,22.5347,4.4135,Ouango,ADM2,DISTRICT
SM10332,20.7,14.1833,Ouara (excl. Abougoudam),ADM2,DISTRICT
SM2632,6.1667,30.5,Ouargla,ADM1,DISTRICT
SM457611,6.5,30.75,Ouargla District,ADM2,DISTRICT
SM245846,5.2844,31.9454,Ouargli,LANGUAGE,LANGUAGE
SM256577,0.4,35.58,Ouarsenis,ETHNICITY,ETHNICITY
SM4433,-6.9029,30.9413,Ouarzazate (2009-),ADM2,DISTRICT
SM262191,-1.3333,12.6667,Oubritenga (1995-),ADM1:ADM2,DISTRICT
SM256578,-65.46,50.45,Ouchestigouek,ETHNICITY,ETHNICITY
SM262192,-0.3333,14.6667,Oudalan,ADM1:ADM2,DISTRICT
SM15174,6.3808,51.8996,Oude IJsselstreek,ADM2,DISTRICT
SM286007,4.9143,52.3066,Ouder-Amstel,ADM2,DISTRICT
SM285599,4.8624,52.0284,Oudewater,ADM2,DISTRICT
SM2633,101.5,20.1667,Oudômxai,ADM1,DISTRICT
SM297896,10.0274,36.8215,Oued Ellil,ADM2,DISTRICT
SM298097,8.6111,36.4649,Oued Mliz,ADM2,DISTRICT
SM457617,1,36,Oued Rhiou District,ADM2,DISTRICT
SM246357,6.0656,33.3489,Oued Righ,LANGUAGE,LANGUAGE
SM2634,-15.9178,23.7513,Oued el Dahab,ADM1,DISTRICT
SM15014,-14.4493,23.642,Oued-Ed-Dahab,ADM2,DISTRICT
SM297921,10.672,35.6943,Ouerdanine,ADM2,DISTRICT
SM298100,9.6738,35.9065,Oueslatia,ADM2,DISTRICT
SM16350,16.0524,1.614,Ouesso,ADM2,DISTRICT
SM2637,-72.2914,18.6638,Ouest,ADM1,DISTRICT
SM2636,10.6667,5.5,Ouest,ADM1,DISTRICT
SM269006,6.57,46.5459,Ouest lausannois,ADM2,DISTRICT
SM4455,-5.586,34.7976,Ouezzane,ADM2,DISTRICT
SM2638,18,7,Ouham,ADM1,DISTRICT
SM2639,16.0833,6.75,Ouham-Pendé,ADM1,DISTRICT
SM256579,-68.56,4.8,Ouiba,ETHNICITY,ETHNICITY
SM262144,2.113,6.371,Ouidah,ADM2,DISTRICT
SM262128,2.468,7.068,Ouinhi,ADM2,DISTRICT
SM281418,-2.294,34.236,Oujda Angad,ADM2,DISTRICT
SM284009,-11.8897,15.676,Ould Yengé,ADM2,DISTRICT
SM297889,10.3359,35.4573,Ouled Chamekh,ADM2,DISTRICT
SM256580,-2.26,33.09,Ouled Djerir,ETHNICITY,ETHNICITY
SM297961,9.8562,35.1027,Ouled Haffouz,ADM2,DISTRICT
SM346786,25.8113,64.9961,Oulu,ADM2:ADM3,DISTRICT
SM346787,26.7527,65.3474,Oulunkaari,ADM2:ADM3,DISTRICT
SM298088,8.3742,34.549,Oum Larais,ADM2,DISTRICT
SM2641,7.0833,35.8333,Oum el Bouaghi,ADM1,DISTRICT
SM241876,149.101,-10.2288,Ouma,LANGUAGE,LANGUAGE
SM256581,-67.4,49.93,Oumamiouek,ETHNICITY,ETHNICITY
SM241879,155.708,-6.3607,Ounge,LANGUAGE,LANGUAGE
SM256582,22.43,20.6,Ounie,ETHNICITY,ETHNICITY
SM303359,-107.7693,38.1555,Ouray,ADM2,DISTRICT
SM274047,-7.5,42.1667,Ourense,ADM2,DISTRICT
SM256584,19.36,21.84,Ouria,ETHNICITY,ETHNICITY
SM264858,-40.1296,-8.0366,Ouricuri,ADM2,DISTRICT
SM264207,-51.432,-7.5957,Ourilândia do Norte,ADM2,DISTRICT
SM266757,-49.8611,-22.9633,Ourinhos,ADM2,DISTRICT
SM289422,-8.3103,37.6916,Ourique,ADM2,DISTRICT
SM264607,-52.2427,-23.4836,Ourizona,ADM2,DISTRICT
SM267571,-38.6521,-12.0034,Ouriçangas,ADM2,DISTRICT
SM266227,-51.6816,-27.287,Ouro,ADM2,DISTRICT
SM265598,-36.9146,-6.656,Ouro Branco,ADM2,DISTRICT
SM263715,-43.6942,-20.5299,Ouro Branco,ADM2,DISTRICT
SM265399,-37.3737,-9.129,Ouro Branco,ADM2,DISTRICT
SM263716,-46.3738,-22.2569,Ouro Fino,ADM2,DISTRICT
SM263717,-43.6419,-20.393,Ouro Preto,ADM2,DISTRICT
SM266098,-61.9224,-10.3666,Ouro Preto do Oeste,ADM2,DISTRICT
SM264259,-37.1357,-7.6055,Ouro Velho,ADM2,DISTRICT
SM266758,-51.7512,-21.5277,Ouro Verde,ADM2,DISTRICT
SM266226,-52.2715,-26.7326,Ouro Verde,ADM2,DISTRICT
SM268118,-49.2277,-16.236,Ouro Verde de Goiás,ADM2,DISTRICT
SM263718,-41.2947,-18.0343,Ouro Verde de Minas,ADM2,DISTRICT
SM264608,-53.9368,-24.7969,Ouro Verde do Oeste,ADM2,DISTRICT
SM266759,-50.4146,-19.9376,Ouroeste,ADM2,DISTRICT
SM267572,-41.0284,-10.838,Ourolândia,ADM2,DISTRICT
SM289323,-8.579,39.6618,Ourém,ADM2,DISTRICT
SM264206,-47.1656,-1.5416,Ourém,ADM2,DISTRICT
SM295264,-16.6165,12.4855,Oussouye,ADM2,DISTRICT
SM303262,-88.4649,44.4161,Outagamie,ADM2,DISTRICT
SM10217,-76.3161,46.2668,Outaouais,ADM2,DISTRICT
SM2642,51.034,-9.9724,Outer Islands,ADM1,DISTRICT
SM295324,155.5638,-7.3648,Outer Shortlands,ADM2,DISTRICT
SM280787,105.05,16.6667,Outhoomphone,ADM2,DISTRICT
SM268119,-47.7151,-18.2011,Ouvidor,ADM2,DISTRICT
SM284563,166.5531,-20.6617,Ouvéa,ADM2,DISTRICT
SM262160,2.523,8.561,Ouèssè,ADM2,DISTRICT
SM284581,164.4262,-20.3485,Ouégoa,ADM2,DISTRICT
SM2635,2.5167,6.6167,Ouémé (1999-),ADM1,DISTRICT
SM298983,39.2163,39.3578,Ovacık,ADM2,DISTRICT
SM298571,32.9322,41.0803,Ovacık,ADM2,DISTRICT
SM296626,15.7807,61.3333,Ovanåker,ADM2,DISTRICT
SM289214,-8.6123,40.8942,Ovar,ADM2,DISTRICT
SM270531,-75.1833,9.5408,Ovejas,ADM2,DISTRICT
SM15981,-67.1137,18.2615,Ovejas Barrio,ADM2,DISTRICT
SM305146,19.9399,-34.2613,Overberg,ADM2,DISTRICT
SM285799,5.7869,51.9184,Overbetuwe,ADM2,DISTRICT
SM286457,11.9475,64.4938,Overhalla,ADM2,DISTRICT
SM2643,6.5,52.4167,Overijssel,ADM1,DISTRICT
SM302607,-85.2881,36.345,Overton,ADM2,DISTRICT
SM292217,24.0241,44.7581,Oveselu,ADM2,DISTRICT
SM10949,33.9355,35.3796,Ovgoros,ADM2,DISTRICT
SM284692,5.5799,6.6447,OviaNort,ADM2,DISTRICT
SM284693,5.272,6.396,OviaSouth-West,ADM2,DISTRICT
SM299620,30.4418,46.2448,Ovidiopol's'kyi,ADM2,DISTRICT
SM11115,-71.5,17.8333,Oviedo,ADM2,DISTRICT
SM250785,19.82,-13.32,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,19.82,-13.32,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,19.82,-13.32,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,19.82,-13.32,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,16.65,-12.37,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,16.65,-12.37,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,16.65,-12.37,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,16.65,-12.37,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,15.95,-11.1,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,15.95,-11.1,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,15.95,-11.1,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,15.95,-11.1,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,15.72,-13.02,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,15.72,-13.02,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,15.72,-13.02,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,15.72,-13.02,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,15,-13,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,15,-13,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,15,-13,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,15,-13,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,16,-12,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,16,-12,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,16,-12,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,16,-12,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,16.5,-12.25,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,16.5,-12.25,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,16.5,-12.25,Ovimbundu,ETHNICITY,ETHNICITY
SM250785,16.5,-12.25,Ovimbundu,ETHNICITY,ETHNICITY
SM299853,28.8333,51.3333,Ovruts'kyi,ADM2,DISTRICT
SM294734,92,50.8333,Ovyurskiy rayon,ADM2,DISTRICT
SM241892,162.3047,-10.8255,Owa,LANGUAGE,LANGUAGE
SM284694,6.0794,7.0316,Owan East,ADM2,DISTRICT
SM284695,5.8938,6.9293,OwanWest,ADM2,DISTRICT
SM16351,15.974,-0.665,Owando,ADM2,DISTRICT
SM278059,137.0354,35.2165,Owariasahi,ADM2,DISTRICT
SM278702,136.1831,34.0369,Owase,ADM2,DISTRICT
SM241896,145.817,-6.7045,Oweina,LANGUAGE,LANGUAGE
SM300748,-86.8377,39.3128,Owen,ADM2,DISTRICT
SM301102,-84.8281,38.5196,Owen,ADM2,DISTRICT
SM256586,-117.76,36.88,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-117.76,36.88,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-117.76,36.88,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-117.76,36.88,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-118,37,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-118,37,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-118,37,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-118,37,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-118.18,36.88,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-118.18,36.88,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-118.18,36.88,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-118.18,36.88,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-119,38,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-119,38,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-119,38,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM256586,-119,38,Owens Valley Paiute,ETHNICITY,ETHNICITY
SM280975,-10.2839,6.1974,Owensgrove,ADM2,DISTRICT
SM284771,7.0168,5.4766,Owerri Municipal,ADM2,DISTRICT
SM284772,7.1027,5.4567,Owerri North,ADM2,DISTRICT
SM284773,6.9804,5.4323,Owerri West,ADM2,DISTRICT
SM241898,141.697,-4.4748,Owiniga,LANGUAGE,LANGUAGE
SM285076,5.5801,7.1665,Owo,ADM2,DISTRICT
SM301103,-83.6831,37.4192,Owsley,ADM2,DISTRICT
SM300574,-116.17,42.5815,Owyhee,ADM2,DISTRICT
SM286811,-75.0833,-10.3333,Oxapampa,ADM2,DISTRICT
SM283175,-92.2976,16.7988,Oxchuc,ADM2,DISTRICT
SM296466,17.0841,58.6752,Oxelösund,ADM2,DISTRICT
SM301213,-70.7566,44.4998,Oxford,ADM2,DISTRICT
SM268872,-80.833,43.1334,Oxford,ADM2,DISTRICT
SM274668,-1.25,51.8333,Oxfordshire,ADM1:ADM2,DISTRICT
SM282990,-89.446,20.2409,Oxkutzcab,ADM2,DISTRICT
SM18424,71.5751,40.5332,Oxunboboyev Tumani,ADM2,DISTRICT
SM241911,106.658,15.42,Oy,LANGUAGE,LANGUAGE
SM241902,150.658,-10.5392,Oya'oya,LANGUAGE,LANGUAGE
SM279498,136.855,36.6635,Oyabe,ADM2,DISTRICT
SM299286,32.5007,2.3813,Oyam,ADM1:ADM2,DISTRICT
SM279329,139.802,36.308,Oyama,ADM2,DISTRICT
SM256589,-52.6,2.9,Oyampi,ETHNICITY,ETHNICITY
SM256589,-54.04,1.87,Oyampi,ETHNICITY,ETHNICITY
SM256590,-53.93,3.99,Oyana,ETHNICITY,ETHNICITY
SM256590,-53.93,3.99,Oyana,ETHNICITY,ETHNICITY
SM256590,-53.4,3.26,Oyana,ETHNICITY,ETHNICITY
SM256590,-53.4,3.26,Oyana,ETHNICITY,ETHNICITY
SM256590,-55,3,Oyana,ETHNICITY,ETHNICITY
SM256590,-55,3,Oyana,ETHNICITY,ETHNICITY
SM241903,36.8541,6.1332,Oyda,LANGUAGE,LANGUAGE
SM284712,5.3448,7.8894,Oye,ADM2,DISTRICT
SM323873,-110.4859,51.3462,Oyen,ADM2:ADM3,DISTRICT
SM285302,6.8619,6.1767,Oyi,ADM2,DISTRICT
SM285209,7.1283,4.8781,Oyigbo,ADM2,DISTRICT
SM294318,144,63,Oymyakonskiy rayon,ADM2,DISTRICT
SM16352,15.8959,-1.0012,Oyo,ADM2,DISTRICT
SM256592,4,8,Oyo,ETHNICITY,ETHNICITY
SM2645,4,8,Oyo (1991-),ADM1,DISTRICT
SM285167,4.0213,7.8788,Oyo East,ADM2,DISTRICT
SM285169,3.8218,7.9416,Oyo West,ADM2,DISTRICT
SM286776,-76.8763,-10.7587,Oyon,ADM2,DISTRICT
SM459124,147.7687,-42.5036,Oyster Bay Tasmanian,ETHNICITY,ETHNICITY
SM241910,147.5006,-42.3074,Oyster Bay-Big River-Little Swanport,LANGUAGE,LANGUAGE
SM284979,4.6521,8.1683,Oyun,ADM2,DISTRICT
SM276053,15.4667,45.6,Ozalj,ADM2,DISTRICT
SM301597,-92.4447,36.6493,Ozark,ADM2,DISTRICT
SM295578,-88.5158,13.3787,Ozatlán,ADM2,DISTRICT
SM303263,-87.8906,43.4031,Ozaukee,ADM2,DISTRICT
SM11585,20.2487,48.1813,Ozdi Jaras,ADM2,DISTRICT
SM293039,22,54.4167,Ozerskiy rayon,ADM2,DISTRICT
SM256593,-124.3,47.95,Ozette,ETHNICITY,ETHNICITY
SM13723,27.2501,56.4352,Ozolaines pagasts,ADM2,DISTRICT
SM13724,27.2501,56.499,Ozolmuizas pagasts,ADM2,DISTRICT
SM4069,23.9281,56.6514,Ozolnieku Novads,ADM1,DISTRICT
SM13725,23.7733,56.6916,Ozolnieku pagasts,ADM2,DISTRICT
SM241913,-96.3686,16.04,Ozolotepec Zapotec,LANGUAGE,LANGUAGE
SM282755,-97.9093,21.6803,Ozuluama,ADM2,DISTRICT
SM241915,-96.2249,17.6976,Ozumacín Chinantec,LANGUAGE,LANGUAGE
SM281953,-98.7977,19.039,Ozumba,ADM2,DISTRICT
SM290224,25.8826,45.7833,Ozun,ADM2,DISTRICT
SM274792,42.05,41.9333,Ozurgeti,ADM2,DISTRICT
SM17034,38.5833,54.8333,Ozyory Urban Okrug,ADM2,DISTRICT
SM273430,-79.1299,-3.4857,Oña,ADM2,DISTRICT
SM261953,47.5,41.0833,Oğuz,ADM1:ADM2,DISTRICT
SM298425,37.5628,36.8817,Oğuzeli,ADM2,DISTRICT
SM298287,34.7081,40.7632,Oğuzlar,ADM2,DISTRICT
SM288657,17.306,50.9606,Oława,ADM2,DISTRICT
SM288935,19.2277,50.038,Oświęcim,ADM2,DISTRICT
SM313185,16.8286,44.7328,Oštra Luka,ADM2:ADM3,DISTRICT
SM289171,126.9508,38.7439,P'an'gyo,ADM2,DISTRICT
SM289189,124.6667,40.04,P'ihyŏn,ADM2,DISTRICT
SM289090,128,40.9167,P'ungsŏ,ADM2,DISTRICT
SM242910,126.16,39.44,P'yong'ando,DIALECT,LANGUAGE
SM15266,125.7203,39.0053,P'yongch'on-guyok,ADM2,DISTRICT
SM2647,126.1667,39.5,P'yŏngan-namdo,ADM1,DISTRICT
SM289172,127.2389,38.4597,P'yŏnggang,ADM2,DISTRICT
SM289143,126.4167,38.3333,P'yŏngsan,ADM2,DISTRICT
SM289053,125.8633,39.2828,P'yŏngsŏng,ADM2,DISTRICT
SM289054,125.5531,39.2667,P'yŏngwŏn,ADM2,DISTRICT
SM2648,125.8333,39.0833,P'yŏngyang,ADM1,DISTRICT
SM297325,100.1308,7.2199,Pa Bon,ADM2,DISTRICT
SM296899,99.9762,19.5064,Pa Daet,ADM2,DISTRICT
SM241941,103.486,26.3112,Pa Di,LANGUAGE,LANGUAGE
SM297420,102.6667,14.4,Pa Kham,ADM2,DISTRICT
SM297024,100.4573,14.4862,Pa Mok,ADM2,DISTRICT
SM242104,110.0967,26.2638,Pa Na,LANGUAGE,LANGUAGE
SM256595,107.77,14.17,Pa Na,ETHNICITY,ETHNICITY
SM297326,99.8745,7.8315,Pa Payom,ADM2,DISTRICT
SM297035,98.8828,18.414,Pa Sang,ADM2,DISTRICT
SM297765,104.3999,15.8442,Pa Tiu,ADM2,DISTRICT
SM241916,9.3009,11.0175,Pa'a,LANGUAGE,LANGUAGE
SM256597,13.1,12.54,Pa'awa,ETHNICITY,ETHNICITY
SM242161,97.5969,20.211,Pa'o Karen,LANGUAGE,LANGUAGE
SM241969,105.165,22.8049,Pa-Hng,LANGUAGE,LANGUAGE
SM229390,142.695,-32.7021,Paakantyi,LANGUAGE,LANGUAGE
SM241919,168.2369,-16.4755,Paama,LANGUAGE,LANGUAGE
SM241921,-1.8048,10.2004,Paasaal,LANGUAGE,LANGUAGE
SM14960,151.583,7.376,Paata-Tupunion Municipality,ADM2,DISTRICT
SM281492,-102.2667,22.15,Pabellón de Arteaga,ADM2,DISTRICT
SM288868,19.2378,51.6774,Pabianice,ADM2,DISTRICT
SM273521,-78.2954,-1.8536,Pablo Sexto,ADM2,DISTRICT
SM262273,89.3353,24.0063,Pabna,ADM2,DISTRICT
SM266760,-51.2695,-21.5033,Pacaembu,ADM2,DISTRICT
SM241925,-65.5568,-11.9968,Pacahuara,LANGUAGE,LANGUAGE
SM264208,-50.7784,-3.7017,Pacajá,ADM2,DISTRICT
SM267810,-38.5182,-4.1776,Pacajús,ADM2,DISTRICT
SM266126,-60.8687,4.1978,Pacaraima,ADM2,DISTRICT
SM241924,-76.7305,-11.1428,Pacaraos Quechua,LANGUAGE,LANGUAGE
SM286762,-79.5,-7.25,Pacasmayo,ADM2,DISTRICT
SM267118,-36.6038,-10.4947,Pacatuba,ADM2,DISTRICT
SM267811,-38.6292,-3.9762,Pacatuba,ADM2,DISTRICT
SM275236,-90.6544,14.918,Pachalúm,ADM2,DISTRICT
SM270798,-73.3994,5.1486,Pachavita,ADM2,DISTRICT
SM260122,70.2806,34.0878,Pachir Wa Agam,ADM2,DISTRICT
SM286738,-75.8139,-9.9526,Pachitea,ADM2,DISTRICT
SM10950,32.7853,34.7693,Pachna,ADM2,DISTRICT
SM270032,-74.1807,5.1499,Pacho,ADM2,DISTRICT
SM281695,-98.7862,20.1012,Pachuca de Soto,ADM2,DISTRICT
SM10951,32.5851,35.165,Pachyammos,ADM2,DISTRICT
SM303086,-123.7789,46.5513,Pacific,ADM2,DISTRICT
SM241929,-148.043,60.4238,Pacific Gulf Yupik,LANGUAGE,LANGUAGE
SM276357,111.1667,-8.1333,Pacitan,ADM2,DISTRICT
SM260996,-65.6667,-28.0833,Paclín,ADM2,DISTRICT
SM270649,-70.8927,0.1564,Pacoa,ADM2,DISTRICT
SM241931,107.094,16.3353,Pacoh,LANGUAGE,LANGUAGE
SM267812,-38.9025,-4.1958,Pacoti,ADM2,DISTRICT
SM276221,16.83,46.8,Pacsa/Zalaegerszeg,ADM2,DISTRICT
SM267813,-40.6769,-3.9675,Pacujá,ADM2,DISTRICT
SM281696,-99.3216,20.9706,Pacula,ADM2,DISTRICT
SM291547,26.14,45.147,Pacureti,ADM2,DISTRICT
SM241934,95.3668,28.2463,Padam,DIALECT,LANGUAGE
SM276640,100.45,-0.9833,Padang,ADM2,DISTRICT
SM276688,99.8114,1.1566,Padang Lawas,ADM2,DISTRICT
SM276687,99.6735,1.4601,Padang Lawas Utara,ADM2,DISTRICT
SM276657,100.4036,-0.4695,Padang Panjang,ADM2,DISTRICT
SM276658,100.2833,-0.6,Padang Pariaman,ADM2,DISTRICT
SM284409,100.7715,6.2473,Padang Terap,ADM2,DISTRICT
SM276689,99.2684,1.3738,Padangsidimpuan,ADM2,DISTRICT
SM299379,32.8,2.956,Pader (2010-),ADM1:ADM2,DISTRICT
SM282660,-98.8967,24.0557,Padilla,ADM2,DISTRICT
SM269863,-76.3569,3.1997,Padilla,ADM2,DISTRICT
SM15982,-66.3515,18.3121,Padilla Barrio,ADM2,DISTRICT
SM289899,27.1157,44.8708,Padina,ADM2,DISTRICT
SM291091,23.0143,44.4378,Padina Mare,ADM2,DISTRICT
SM256606,-71.7,70.23,Padlimiut,ETHNICITY,ETHNICITY
SM241945,121.236,-2.6064,Padoe,LANGUAGE,LANGUAGE
SM256610,-101.58,40.91,Padouca,ETHNICITY,ETHNICITY
SM286854,-75.4285,-8.7968,Padre Abad,ADM2,DISTRICT
SM268120,-48.3137,-15.3412,Padre Bernardo,ADM2,DISTRICT
SM263719,-42.6025,-16.2632,Padre Carvalho,ADM2,DISTRICT
SM271764,-70.8833,18.75,Padre Las Casas,ADM2,DISTRICT
SM265075,-40.9342,-7.3653,Padre Marcos,ADM2,DISTRICT
SM303618,-71.4647,7.7141,Padre Noguera,ADM2,DISTRICT
SM263720,-41.5383,-17.061,Padre Paraíso,ADM2,DISTRICT
SM303861,-61.8819,8.0246,Padre Pedro Chien,ADM2,DISTRICT
SM277929,11.8167,45.35,Padua,ADM2,DISTRICT
SM292300,28.0913,46.5929,Padureni,ADM2,DISTRICT
SM13726,21.8627,57.0364,Padures pagasts,ADM2,DISTRICT
SM11253,-149.5841,-17.6927,Paea,ADM2,DISTRICT
SM289091,128.8031,41.5919,Paegam,ADM2,DISTRICT
SM289168,126.3042,37.9797,Paekch'ŏn,ADM2,DISTRICT
SM11182,24.5278,58.379,Paernu linn,ADM2,DISTRICT
SM265076,-42.3509,-7.7746,Paes Landim,ADM2,DISTRICT
SM256612,-75.67,3.25,Paez,ETHNICITY,ETHNICITY
SM256612,-75.67,3.25,Paez,ETHNICITY,ETHNICITY
SM256612,-76,3,Paez,ETHNICITY,ETHNICITY
SM256612,-76,3,Paez,ETHNICITY,ETHNICITY
SM10952,32.4283,34.7766,Pafos,ADM2,DISTRICT
SM10531,15.0575,44.4436,Pag (2003-),ADM2,DISTRICT
SM15289,145.772,18.11,Pagan Island,ADM2,DISTRICT
SM276663,103.2682,-4.1306,Pagar Alam,ADM2,DISTRICT
SM303062,-78.4841,38.62,Page,ADM2,DISTRICT
SM300854,-95.1502,40.7391,Page,ADM2,DISTRICT
SM14146,21.9045,55.134,Pagegiai,ADM2,DISTRICT
SM2650,-64.7817,32.2783,Paget,ADM1,DISTRICT
SM260036,68.9372,34.5481,Paghman,ADM2,DISTRICT
SM241954,141.246,-3.0465,Pagi,LANGUAGE,LANGUAGE
SM241953,22.1212,3.1564,Pagibete,LANGUAGE,LANGUAGE
SM241956,127.78,1.2134,Pagu,LANGUAGE,LANGUAGE
SM296044,7.3905,1.6165,Pagué,ADM2,DISTRICT
SM2651,102.75,3.5,Pahang,ADM1,DISTRICT
SM256617,78,30,Pahari,ETHNICITY,ETHNICITY
SM241958,73.8071,33.6424,Pahari Potwari,LANGUAGE,LANGUAGE
SM241966,142.299,-3.7994,Pahi,LANGUAGE,LANGUAGE
SM241967,62.1978,31.3267,Pahlavani,LANGUAGE,LANGUAGE
SM241968,52.7,30.06,Pahlavi,LANGUAGE,LANGUAGE
SM282244,-98.145,20.2926,Pahuatlán,ADM2,DISTRICT
SM256621,-110.98,39.08,Pahvant,ETHNICITY,ETHNICITY
SM256621,-110.98,39.08,Pahvant,ETHNICITY,ETHNICITY
SM256621,-111.71,38.04,Pahvant,ETHNICITY,ETHNICITY
SM256621,-111.71,38.04,Pahvant,ETHNICITY,ETHNICITY
SM256621,-113,39,Pahvant,ETHNICITY,ETHNICITY
SM256621,-113,39,Pahvant,ETHNICITY,ETHNICITY
SM297066,98.4064,19.3604,Pai,ADM2,DISTRICT
SM256622,31.87,-25.42,Pai,ETHNICITY,ETHNICITY
SM263721,-43.1951,-15.3273,Pai Pedro,ADM2,DISTRICT
SM241987,-56.1176,-23.1595,Pai Tavytera,LANGUAGE,LANGUAGE
SM241981,103.5,31.8,Pai-lang,LANGUAGE,LANGUAGE
SM266229,-52.4755,-27.203,Paial,ADM2,DISTRICT
SM270093,-75.7709,2.4005,Paicol,ADM2,DISTRICT
SM241974,-60.5,-15,Paiconeca,LANGUAGE,LANGUAGE
SM241973,165.15,-21.096,Paicî,LANGUAGE,LANGUAGE
SM11183,25.5602,58.885,Paide linn,ADM2,DISTRICT
SM4170,25.8333,61.25,Paijat-Hame Region,ADM1,DISTRICT
SM256625,-63.97,-15.12,Paikoneka,ETHNICITY,ETHNICITY
SM285032,6.8557,9.4681,Paikoro,ADM2,DISTRICT
SM280363,102.6285,12.7869,Pailin,ADM2,DISTRICT
SM269896,-73.5929,8.9255,Pailitas,ADM2,DISTRICT
SM265802,-51.781,-27.7298,Paim Filho,ADM2,DISTRICT
SM270033,-74.1667,5.389,Paime,ADM2,DISTRICT
SM263722,-45.5374,-18.9182,Paineiras,ADM2,DISTRICT
SM266230,-50.0598,-27.9747,Painel,ADM2,DISTRICT
SM263723,-45.7314,-20.3737,Pains,ADM2,DISTRICT
SM323938,-111.6962,52.2162,Paintearth County No. 18,ADM2:ADM3,DISTRICT
SM270800,-73.2309,5.8298,Paipa,ADM2,DISTRICT
SM241982,-115.871,31.6063,Paipai,LANGUAGE,LANGUAGE
SM256626,-115.8,31.5,Paipai,ETHNICITY,ETHNICITY
SM241983,75.38,19.48,Paisaci Prakrit,LANGUAGE,LANGUAGE
SM12604,-77.2237,17.9629,Paisley,ADM2,DISTRICT
SM286816,-81.097,-5.1019,Paita,ADM2,DISTRICT
SM241985,93.6136,24.0381,Paite Chin,LANGUAGE,LANGUAGE
SM263725,-43.4172,-21.2881,Paiva,ADM2,DISTRICT
SM241990,120.806,22.3271,Paiwan,LANGUAGE,LANGUAGE
SM256628,120.87,23.29,Paiwan,ETHNICITY,ETHNICITY
SM256628,120.87,23.29,Paiwan,ETHNICITY,ETHNICITY
SM256628,120.87,23.29,Paiwan,ETHNICITY,ETHNICITY
SM256628,121,22.44,Paiwan,ETHNICITY,ETHNICITY
SM256628,121,22.44,Paiwan,ETHNICITY,ETHNICITY
SM256628,121,22.44,Paiwan,ETHNICITY,ETHNICITY
SM256628,120.77,22.44,Paiwan,ETHNICITY,ETHNICITY
SM256628,120.77,22.44,Paiwan,ETHNICITY,ETHNICITY
SM256628,120.77,22.44,Paiwan,ETHNICITY,ETHNICITY
SM17581,-60.9385,14.0176,Paix Bouche (Gros-Islet),ADM2,DISTRICT
SM17582,-60.9407,13.8156,Paix Bouche (Micoud),ADM2,DISTRICT
SM264609,-52.1506,-23.4741,Paiçandu,ADM2,DISTRICT
SM282061,-102.5259,20.1348,Pajacuarán,ADM2,DISTRICT
SM296393,22.9167,67.2407,Pajala,ADM2,DISTRICT
SM282756,-94.6622,18.1744,Pajapan,ADM2,DISTRICT
SM275285,-92.0496,14.7339,Pajapita,ADM2,DISTRICT
SM270801,-72.7208,5.3475,Pajarito,ADM2,DISTRICT
SM15983,-66.1863,18.3759,Pajaros Barrio,ADM2,DISTRICT
SM265077,-42.8663,-8.0536,Pajeú do Piauí,ADM2,DISTRICT
SM280712,126.7777,37.7595,Paju,ADM2,DISTRICT
SM273495,-80.4333,-1.55,Paján,ADM2,DISTRICT
SM288869,19.0275,51.1412,Pajęczno,ADM2,DISTRICT
SM297049,101.9578,17.911,Pak Chom,ADM2,DISTRICT
SM297129,101.4481,14.6192,Pak Chong,ADM2,DISTRICT
SM297311,103.3414,18.2774,Pak Khat,ADM2,DISTRICT
SM297279,100.504,13.9163,Pak Kret,ADM2,DISTRICT
SM297214,100.1736,8.3071,Pak Phanang,ADM2,DISTRICT
SM297327,100.2954,7.2962,Pak Phayun,ADM2,DISTRICT
SM297092,101.3448,14.225,Pak Phli,ADM2,DISTRICT
SM297453,99.677,13.3876,Pak Tho,ADM2,DISTRICT
SM297130,101.9423,14.6623,Pak Thong Chai,ADM2,DISTRICT
SM280887,102.6554,20.1879,Pak Xeng,ADM2,DISTRICT
SM242002,147.767,-2.0527,Pak-Tong,LANGUAGE,LANGUAGE
SM256631,-67.68,-10.83,Pakaguara,ETHNICITY,ETHNICITY
SM459420,142.3477,-14.5914,Pakanh,ETHNICITY,ETHNICITY
SM241992,142.74,-15.0908,Pakanha,LANGUAGE,LANGUAGE
SM280896,101.0648,19.9357,Pakbeng,ADM2,DISTRICT
SM289190,125.625,39.6875,Pakch'ŏn,ADM2,DISTRICT
SM277631,51.753,35.4945,Pakdasht,ADM2,DISTRICT
SM241995,67.8843,28.0667,Pakistan Sign Language,LANGUAGE,LANGUAGE
SM280844,104.1846,18.2875,Pakkading,ADM2,DISTRICT
SM256637,107.36,16.21,Pako,ETHNICITY,ETHNICITY
SM15107,94.5209,21.559,Pakokku District (2003-),ADM2,DISTRICT
SM305588,15.5095,43.9099,Pakoštane,ADM2,DISTRICT
SM276690,98.2833,2.5667,Pakpak Barat,ADM2,DISTRICT
SM375469,73.2601,30.301,Pakpattan,ADM2:ADM3,DISTRICT
SM275965,17.2,45.4333,Pakrac,ADM2,DISTRICT
SM281351,23.8667,55.9667,Pakruojo,ADM2,DISTRICT
SM276187,18.7857,46.6604,Paks,ADM2,DISTRICT
SM280845,103.6604,18.3955,Paksane,ADM2,DISTRICT
SM280853,106.434,15.0981,Paksong,ADM2,DISTRICT
SM280839,100.5994,20.0911,Paktha,ADM2,DISTRICT
SM2653,68.75,32.4167,Paktika,ADM1,DISTRICT
SM2654,69.3333,33.6667,Paktya,ADM1,DISTRICT
SM242004,115.087,-1.9063,Paku,LANGUAGE,LANGUAGE
SM242003,97.1203,18.8137,Paku Karen,LANGUAGE,LANGUAGE
SM276906,87.6708,24.5706,Pakur,ADM2,DISTRICT
SM280854,105.8161,15.1314,Pakxe,ADM2,DISTRICT
SM242046,145.24,-4.756,Pal,LANGUAGE,LANGUAGE
SM285426,-86.3934,13.4615,Palacagüina,ADM2,DISTRICT
SM242007,33.499,41.334,Palaic,LANGUAGE,LANGUAGE
SM10953,33.1139,34.9376,Palaichori,ADM2,DISTRICT
SM10954,33.1,34.9461,Palaichori Morfou,ADM2,DISTRICT
SM10955,33.4906,35.1846,Palaikythro,ADM2,DISTRICT
SM10956,33.2067,35.1297,Palaiometocho,ADM2,DISTRICT
SM10957,32.8257,34.9291,Palaiomylos,ADM2,DISTRICT
SM10958,33.2094,35.3173,Palaiosofos,ADM2,DISTRICT
SM242015,-4.9271,9.5382,Palaka Senoufo,LANGUAGE,LANGUAGE
SM276954,76.651,10.775,Palakkad,ADM2,DISTRICT
SM276907,84.0833,23.9167,Palamu (2001-),ADM2,DISTRICT
SM292136,26.1124,46.5065,Palanca,ADM2,DISTRICT
SM273579,-79.0933,-4.5574,Palanda,ADM2,DISTRICT
SM18094,41.2798,39.8556,Palandoeken,ADM2,DISTRICT
SM276406,113.7313,-1.7698,Palangka Raya,ADM2,DISTRICT
SM281337,21.05,55.9167,Palangos,ADM2,DISTRICT
SM242023,-96.3407,17.884,Palantla Chinantec,LANGUAGE,LANGUAGE
SM268526,27.4725,-22.5196,Palapye,ADM2,DISTRICT
SM290119,23.9829,46.8736,Palatca,ADM2,DISTRICT
SM256642,134.58,7.59,Palau,ETHNICITY,ETHNICITY
SM256642,134.58,7.59,Palau,ETHNICITY,ETHNICITY
SM256642,134.58,7.59,Palau,ETHNICITY,ETHNICITY
SM256642,134.54,7.45,Palau,ETHNICITY,ETHNICITY
SM256642,134.54,7.45,Palau,ETHNICITY,ETHNICITY
SM256642,134.54,7.45,Palau,ETHNICITY,ETHNICITY
SM256642,134.5,7.5,Palau,ETHNICITY,ETHNICITY
SM256642,134.5,7.5,Palau,ETHNICITY,ETHNICITY
SM256642,134.5,7.5,Palau,ETHNICITY,ETHNICITY
SM242017,134.4767,7.3414,Palauan,LANGUAGE,LANGUAGE
SM2655,-172.4667,-13.7,Palauli,ADM1,DISTRICT
SM256643,97.87,20.79,Palaung,ETHNICITY,ETHNICITY
SM256643,97.87,20.79,Palaung,ETHNICITY,ETHNICITY
SM256643,97,23,Palaung,ETHNICITY,ETHNICITY
SM256643,97,23,Palaung,ETHNICITY,ETHNICITY
SM303608,-69.1667,9.8333,Palavecino,ADM2,DISTRICT
SM2656,118.75,10,Palawan,ADM1:ADM2,DISTRICT
SM256644,117.46,9.56,Palawan,ETHNICITY,ETHNICITY
SM256645,119.63,10.49,Palawan Batak,ETHNICITY,ETHNICITY
SM256645,119.63,10.49,Palawan Batak,ETHNICITY,ETHNICITY
SM256645,119.09,10.03,Palawan Batak,ETHNICITY,ETHNICITY
SM256645,119.09,10.03,Palawan Batak,ETHNICITY,ETHNICITY
SM313224,18.6244,43.8,Pale,ADM2:ADM3,DISTRICT
SM292931,42,56.8333,Palekhskiy rayon,ADM2,DISTRICT
SM276664,104.7167,-3,Palembang,ADM2,DISTRICT
SM242032,-5.3591,10.6201,Palen,LANGUAGE,LANGUAGE
SM269118,-72.4782,-43.1621,Palena,ADM2,DISTRICT
SM274070,-4.5,42.4167,Palencia,ADM2,DISTRICT
SM275471,-90.3231,14.6612,Palencia,ADM2,DISTRICT
SM283176,-91.8954,17.5044,Palenque,ADM2,DISTRICT
SM273487,-79.711,-1.3254,Palenque,ADM2,DISTRICT
SM256648,-64.22,9.02,Palenque,ETHNICITY,ETHNICITY
SM242030,-75.2949,10.3881,Palenquero,LANGUAGE,LANGUAGE
SM277903,13.5833,37.8167,Palermo,ADM2,DISTRICT
SM270094,-75.4486,2.8792,Palermo,ADM2,DISTRICT
SM13727,24.7146,57.695,Pales pagasts,ADM2,DISTRICT
SM273440,-79.9183,-1.6336,Palestina,ADM2,DISTRICT
SM270095,-76.1692,1.6859,Palestina,ADM2,DISTRICT
SM270846,-75.6955,5.0457,Palestina,ADM2,DISTRICT
SM266761,-49.52,-20.2991,Palestina,ADM2,DISTRICT
SM265400,-37.331,-9.675,Palestina,ADM2,DISTRICT
SM268122,-51.4585,-16.7285,Palestina de Goiás,ADM2,DISTRICT
SM275216,-91.6759,14.9323,Palestina de Los Altos,ADM2,DISTRICT
SM264209,-48.3854,-5.9752,Palestina do Pará,ADM2,DISTRICT
SM256650,35.09,32.28,Palestine,ETHNICITY,ETHNICITY
SM256650,35.09,32.28,Palestine,ETHNICITY,ETHNICITY
SM256650,35.09,32.28,Palestine,ETHNICITY,ETHNICITY
SM256650,35,32,Palestine,ETHNICITY,ETHNICITY
SM256650,35,32,Palestine,ETHNICITY,ETHNICITY
SM256650,35,32,Palestine,ETHNICITY,ETHNICITY
SM256650,35,32,Palestine,ETHNICITY,ETHNICITY
SM256650,35,32,Palestine,ETHNICITY,ETHNICITY
SM256650,35,32,Palestine,ETHNICITY,ETHNICITY
SM242029,-119.0738,35.5567,Palewyami Yokuts,LANGUAGE,LANGUAGE
SM277048,72.7667,19.6927,Palghar,ADM2,DISTRICT
SM267815,-38.0445,-4.6606,Palhano,ADM2,DISTRICT
SM266231,-48.6591,-27.802,Palhoça,ADM2,DISTRICT
SM242035,82.251,24.5271,Pali,LANGUAGE,LANGUAGE
SM277181,73.5,25.75,Pali,ADM2,DISTRICT
SM297677,99.7949,7.2334,Palian,ADM2,DISTRICT
SM256651,-51.93,4.24,Palikur,ETHNICITY,ETHNICITY
SM256651,-51.93,4.24,Palikur,ETHNICITY,ETHNICITY
SM256651,-52,3,Palikur,ETHNICITY,ETHNICITY
SM256651,-52,3,Palikur,ETHNICITY,ETHNICITY
SM242041,-51.6246,3.6399,Palikúr,LANGUAGE,LANGUAGE
SM14083,-7.6375,5.2864,Palipo,ADM2,DISTRICT
SM256652,-7.05,6.04,Palipo,ETHNICITY,ETHNICITY
SM256653,33.64,1.99,Palissa,ETHNICITY,ETHNICITY
SM242036,77.1863,10.087,Paliyan,LANGUAGE,LANGUAGE
SM256655,77.05,9.73,Paliyan,ETHNICITY,ETHNICITY
SM256655,77.05,9.73,Paliyan,ETHNICITY,ETHNICITY
SM256655,77.5,9.75,Paliyan,ETHNICITY,ETHNICITY
SM256655,77.5,9.75,Paliyan,ETHNICITY,ETHNICITY
SM283100,-92.0778,18.2526,Palizada,ADM2,DISTRICT
SM294120,28,57.5,Palkinskiy rayon,ADM2,DISTRICT
SM242045,146.91,-36.08,Pallanganmiddang,LANGUAGE,LANGUAGE
SM286796,-77.9167,-8.3333,Pallasca,ADM2,DISTRICT
SM294920,46.5,49.5,Pallasovskiy rayon,ADM2,DISTRICT
SM273605,-78.927,-2.0139,Pallatanga,ADM2,DISTRICT
SM256657,-95.62,60.32,Pallirmiut,ETHNICITY,ETHNICITY
SM299385,33.6959,1.1632,Pallisa (2017-),ADM1:ADM2,DISTRICT
SM300358,-80.4365,26.6476,Palm Beach,ADM2,DISTRICT
SM261655,146.6038,-18.7489,Palm Island,ADM2,DISTRICT
SM283853,40.3198,-10.8643,Palma,ADM2,DISTRICT
SM457968,-42.3207,-21.4311,Palma,ADM2,DISTRICT
SM15984,-66.9653,18.226,Palma Escrita Barrio,ADM2,DISTRICT
SM303570,-68.5458,10.5933,Palma Sola,ADM2,DISTRICT
SM266232,-53.3317,-26.3915,Palma Sola,ADM2,DISTRICT
SM271049,-76.0578,20.209,Palma Soriano,ADM2,DISTRICT
SM270494,-73.3074,6.4974,Palmar,ADM2,DISTRICT
SM15985,-67.1372,18.3997,Palmar Barrio,ADM2,DISTRICT
SM282245,-97.5358,18.8401,Palmar de Bravo,ADM2,DISTRICT
SM270660,-74.769,10.6961,Palmar de Varela,ADM2,DISTRICT
SM15986,-66.326,18.0659,Palmarejo Barrio (Coamo),ADM2,DISTRICT
SM15987,-66.2922,18.3291,Palmarejo Barrio (Corozal),ADM2,DISTRICT
SM15988,-67.0757,17.9938,Palmarejo Barrio (Lajas),ADM2,DISTRICT
SM270912,-84.4369,10.0404,Palmares,ADM2,DISTRICT
SM264859,-35.6377,-8.6339,Palmares,ADM2,DISTRICT
SM266762,-48.8203,-21.1015,Palmares Paulista,ADM2,DISTRICT
SM265803,-50.4492,-30.3625,Palmares do Sul,ADM2,DISTRICT
SM15989,-66.3431,18.2601,Palmarito Barrio,ADM2,DISTRICT
SM267275,-48.0777,-10.1159,Palmas,ADM2,DISTRICT
SM264611,-51.8203,-26.4057,Palmas,ADM2,DISTRICT
SM15990,-66.5571,18.4787,Palmas Altas Barrio,ADM2,DISTRICT
SM15991,-66.0415,17.9711,Palmas Barrio (Arroyo),ADM2,DISTRICT
SM15992,-66.1424,18.4408,Palmas Barrio (Cataño),ADM2,DISTRICT
SM15993,-66.1316,18.0133,Palmas Barrio (Guayama),ADM2,DISTRICT
SM15994,-66.2215,18.0633,Palmas Barrio (Salinas),ADM2,DISTRICT
SM267573,-43.2199,-14.1738,Palmas de Monte Alto,ADM2,DISTRICT
SM270495,-73.2815,6.3729,Palmas del Socorro,ADM2,DISTRICT
SM266233,-50.165,-27.5565,Palmeira,ADM2,DISTRICT
SM264612,-50.0803,-25.4382,Palmeira,ADM2,DISTRICT
SM265804,-53.3514,-27.9256,Palmeira das Missões (pre-1996),ADM2,DISTRICT
SM266763,-50.7518,-20.4505,Palmeira do Oeste,ADM2,DISTRICT
SM265078,-44.4364,-8.5155,Palmeira do Piauí,ADM2,DISTRICT
SM265401,-36.6065,-9.409,Palmeira dos índios,ADM2,DISTRICT
SM265079,-43.0243,-5.7855,Palmeirais,ADM2,DISTRICT
SM267276,-48.1758,-7.8694,Palmeirante,ADM2,DISTRICT
SM267574,-41.5771,-12.5148,Palmeiras,ADM2,DISTRICT
SM268123,-49.8982,-16.8682,Palmeiras de Goiás,ADM2,DISTRICT
SM264860,-36.242,-9.0296,Palmeirina,ADM2,DISTRICT
SM262839,-45.0671,-2.6592,Palmeirândia,ADM2,DISTRICT
SM267278,-48.3887,-13.0603,Palmeirópolis,ADM2,DISTRICT
SM289350,-8.9,38.5842,Palmela,ADM2,DISTRICT
SM242048,-63.1242,-12.6289,Palmela,LANGUAGE,LANGUAGE
SM268124,-48.3943,-17.3194,Palmelo,ADM2,DISTRICT
SM12605,-77.2034,17.95,Palmers Cross,ADM2,DISTRICT
SM261594,130.978,-12.4962,Palmerston,ADM2,DISTRICT
SM4239,-163.1823,-18.0586,Palmerston,ADM1,DISTRICT
SM286554,175.644,-40.4019,Palmerston North,ADM2,DISTRICT
SM282661,-99.557,23.2443,Palmillas,ADM2,DISTRICT
SM268125,-50.2427,-16.8316,Palminópolis,ADM2,DISTRICT
SM271084,-80.4407,22.2821,Palmira,ADM2,DISTRICT
SM270623,-76.2261,3.5397,Palmira,ADM2,DISTRICT
SM17583,-61.0558,13.8584,Palmiste,ADM2,DISTRICT
SM17584,-60.9601,13.8538,Palmiste Estate,ADM2,DISTRICT
SM266764,-50.2295,-22.816,Palmital,ADM2,DISTRICT
SM264613,-52.2537,-24.8426,Palmital,ADM2,DISTRICT
SM265805,-53.5922,-27.3227,Palmitinhos,ADM2,DISTRICT
SM266234,-53.1781,-27.0807,Palmitos,ADM2,DISTRICT
SM457969,-40.3748,-16.7784,Palmopolis,ADM2,DISTRICT
SM296787,38.6458,34.424,Palmyra,ADM2,DISTRICT
SM2658,-162.0743,5.8773,Palmyra,ADM0:ADM1,DISTRICT
SM267816,-38.8426,-4.1296,Palmácia,ADM2,DISTRICT
SM300855,-94.6781,43.0821,Palo Alto,ADM2,DISTRICT
SM15995,-66.3497,18.1909,Palo Hincado Barrio,ADM2,DISTRICT
SM302731,-98.313,32.7532,Palo Pinto,ADM2,DISTRICT
SM15997,-65.938,18.0041,Palo Seco Barrio (Maunabo),ADM2,DISTRICT
SM15996,-66.1476,18.4564,Palo Seco Barrio (Toa Baja),ADM2,DISTRICT
SM10434,-75.0184,5.0942,Palocabildo,ADM2,DISTRICT
SM10959,32.9892,34.7441,Palodeia,ADM2,DISTRICT
SM15998,-66.2429,18.2331,Palomas Barrio,ADM2,DISTRICT
SM276575,120.1108,-2.9784,Palopo,ADM2,DISTRICT
SM242051,-16.9557,14.8628,Palor,LANGUAGE,LANGUAGE
SM273522,-77.9333,-1.7,Palora,ADM2,DISTRICT
SM15999,-66.3007,18.2913,Palos Blancos Barrio,ADM2,DISTRICT
SM264614,-53.7706,-24.2195,Palotina,ADM2,DISTRICT
SM256660,-116.88,46.92,Palouse,ETHNICITY,ETHNICITY
SM242053,83.3928,27.8741,Palpa,LANGUAGE,LANGUAGE
SM286742,-75.1667,-14.4167,Palpa,ADM2,DISTRICT
SM375364,83.6305,27.8106,Palpa,ADM2:ADM3,DISTRICT
SM260836,-65.1384,-24.246,Palpalá,ADM2,DISTRICT
SM13728,26.1668,57.3825,Palsmanes pagasts,ADM2,DISTRICT
SM256662,-79.25,-4.96,Palta,ETHNICITY,ETHNICITY
SM273463,-79.6333,-4.0667,Paltas,ADM2,DISTRICT
SM292585,26.6856,48.2349,Paltinis,ADM2,DISTRICT
SM290039,22.1123,45.4061,Paltinis,ADM2,DISTRICT
SM291823,25.9714,47.541,Paltinoasa,ADM2,DISTRICT
SM242058,97.1666,18.5,Palu,LANGUAGE,LANGUAGE
SM298351,39.9265,38.6929,Palu,ADM2,DISTRICT
SM276601,119.9,-0.8697,Palu,ADM2,DISTRICT
SM242056,121.718,-8.3288,Palu'e,LANGUAGE,LANGUAGE
SM226389,147.282,-2.5604,Paluai,LANGUAGE,LANGUAGE
SM242057,116.346,5.0272,Paluan,LANGUAGE,LANGUAGE
SM242436,71.8421,35.5096,Palula,LANGUAGE,LANGUAGE
SM242054,128.762,-3.024,Palumata,LANGUAGE,LANGUAGE
SM276849,77.3184,28.0475,Palwal,ADM2,DISTRICT
SM242060,75.233,21.937,Palya Bareli,LANGUAGE,LANGUAGE
SM458074,120.1608,-22.2391,Palyku,ETHNICITY,ETHNICITY
SM275435,-90.7003,14.3913,Palín,ADM2,DISTRICT
SM242055,11.3522,46.1297,Palú,DIALECT,LANGUAGE
SM242074,14.285,7.9778,Pam (Cameroon),LANGUAGE,LANGUAGE
SM2659,45.282,-12.7949,Pamandzi,ADM1,DISTRICT
SM242065,26.61,3.739,Pambia,LANGUAGE,LANGUAGE
SM256666,28.27,6.64,Pambia,ETHNICITY,ETHNICITY
SM256667,-99.95,23.24,Pame,ETHNICITY,ETHNICITY
SM276358,113.5,-7.0667,Pamekasan,ADM2,DISTRICT
SM256668,-43.59,60.46,Pamiagikuk,ETHNICITY,ETHNICITY
SM301996,-76.6672,35.1515,Pamlico,ADM2,DISTRICT
SM242077,121.268,-1.6266,Pamona,LANGUAGE,LANGUAGE
SM242078,145.288,-4.6987,Pamosu,LANGUAGE,LANGUAGE
SM13729,22.1591,56.56,Pampalu pagasts,ADM2,DISTRICT
SM242081,120.502,14.9603,Pampanga,LANGUAGE,LANGUAGE
SM2660,120.6667,15.0667,Pampanga,ADM1:ADM2,DISTRICT
SM303765,-70.4959,9.4115,Pampanito,ADM2,DISTRICT
SM12606,-76.5174,17.8757,Pamphret,ADM2,DISTRICT
SM289473,-7.9099,40.08,Pampilhosa da Serra,ADM2,DISTRICT
SM2661,57.5597,-20.0958,Pamplemousses,ADM1,DISTRICT
SM270378,-72.6995,7.3494,Pamplona,ADM2,DISTRICT
SM242082,121.3,18.4738,Pamplona Atta,LANGUAGE,LANGUAGE
SM270379,-72.6597,7.4581,Pamplonita,ADM2,DISTRICT
SM303764,-70.4776,9.4448,Pampán,ADM2,DISTRICT
SM18095,29.0864,37.9671,Pamukkale Ilcesi,ADM2,DISTRICT
SM298837,30.1729,40.5181,Pamukova,ADM2,DISTRICT
SM234888,9.1528,8.7995,Pan,LANGUAGE,LANGUAGE
SM18351,-55.2047,-34.7024,Pan De Azucar,ADM2,DISTRICT
SM242089,-3.3669,13.3501,Pana (Burkina Faso),LANGUAGE,LANGUAGE
SM242087,13.6009,8.7152,Pana (Central African Republic),LANGUAGE,LANGUAGE
SM270073,-69.126,1.9625,Pana Pana,ADM2,DISTRICT
SM282991,-88.296,21.3465,Panabá,ADM2,DISTRICT
SM291825,25.4317,47.1958,Panaci,ADM2,DISTRICT
SM10960,33.0679,35.3431,Panagra,ADM2,DISTRICT
SM262319,24.1667,42.5,Panagyurishte,ADM2,DISTRICT
SM275337,-91.1494,14.7468,Panajachel,ADM2,DISTRICT
SM242102,-79.5333,8.9667,Panamanian Sign Language,LANGUAGE,LANGUAGE
SM265807,-53.5542,-28.3112,Panambi,ADM2,DISTRICT
SM303741,-72,8.4167,Panamericano,ADM2,DISTRICT
SM242099,-117.901,36.0007,Panamint,LANGUAGE,LANGUAGE
SM256677,-117.47,35.75,Panamint,ETHNICITY,ETHNICITY
SM256677,-117.47,35.75,Panamint,ETHNICITY,ETHNICITY
SM256677,-117,36,Panamint,ETHNICITY,ETHNICITY
SM256677,-117,36,Panamint,ETHNICITY,ETHNICITY
SM286664,-79.4525,9.3061,Panamá,ADM2,DISTRICT
SM268126,-49.3889,-18.2013,Panamá,ADM2,DISTRICT
SM2662,-78.9,9.0833,Panamá (2014-),ADM1,DISTRICT
SM2663,-79.9,8.8,Panamá Oeste,ADM1,DISTRICT
SM242086,86.8317,30.7272,Panang,LANGUAGE,LANGUAGE
SM242100,-75.4382,-9.8589,Panao Huánuco Quechua,LANGUAGE,LANGUAGE
SM461497,151.5,-3,Panara,ETHNICITY,ETHNICITY
SM461497,151.5,-3,Panara,ETHNICITY,ETHNICITY
SM461497,151.7,-3.067,Panara,ETHNICITY,ETHNICITY
SM461497,151.7,-3.067,Panara,ETHNICITY,ETHNICITY
SM230635,-65.8483,6.9504,Panare,LANGUAGE,LANGUAGE
SM297298,101.5125,6.8121,Panare,ADM2,DISTRICT
SM256680,-65.25,7.35,Panare,ETHNICITY,ETHNICITY
SM256680,-65.25,7.35,Panare,ETHNICITY,ETHNICITY
SM256680,-66,6,Panare,ETHNICITY,ETHNICITY
SM256680,-66,6,Panare,ETHNICITY,ETHNICITY
SM242101,-53,-10.58,Panará,LANGUAGE,LANGUAGE
SM242097,119.65,-2.2405,Panasuan,LANGUAGE,LANGUAGE
SM289900,26.3916,45.3052,Panatau,ADM2,DISTRICT
SM295386,159.9959,-9.4359,Panatina,ADM2,DISTRICT
SM242092,8.9862,10.0081,Panawa,LANGUAGE,LANGUAGE
SM242109,122.968,-5.2959,Pancana,LANGUAGE,LANGUAGE
SM268014,-40.8176,-19.1554,Pancas,ADM2,DISTRICT
SM256681,-76.43,8.4,Pancenu,ETHNICITY,ETHNICITY
SM292137,27.1138,46.3646,Pancesti,ADM2,DISTRICT
SM276826,73.6,22.75,Panch Mahals (2013-),ADM2,DISTRICT
SM262281,88.5667,26.3333,Panchagarh,ADM2,DISTRICT
SM256688,-74.6,5.47,Panche,ETHNICITY,ETHNICITY
SM295513,-89.1658,13.5715,Panchimalco,ADM2,DISTRICT
SM276850,76.9472,30.7288,Panchkula,ADM2,DISTRICT
SM242108,85.7966,23.447,Panchpargania,LANGUAGE,LANGUAGE
SM375320,87.8552,27.149,Panchthar,ADM2:ADM3,DISTRICT
SM292364,27.0936,45.9066,Panciu,ADM2,DISTRICT
SM290520,21.7016,46.3121,Pancota,ADM2,DISTRICT
SM283904,34.4015,-24.0145,Panda,ADM2,DISTRICT
SM242114,16.2275,3.5794,Pande,LANGUAGE,LANGUAGE
SM250626,17.34,3.98,Pande,ETHNICITY,ETHNICITY
SM276717,105.75,-6.6333,Pandeglang,ADM2,DISTRICT
SM270034,-74.471,4.1803,Pandi,ADM2,DISTRICT
SM18352,-55.9545,-34.7085,Pando,ADM2,DISTRICT
SM2664,-67.6667,-11.3333,Pando,ADM1,DISTRICT
SM264861,-36.0478,-8.6665,Panelas,ADM2,DISTRICT
SM291172,24.4634,46.5579,Panet,ADM2,DISTRICT
SM2665,24.8333,55.9167,Panevezio,ADM1,DISTRICT
SM14147,24.3435,55.736,Panevezys City,ADM2,DISTRICT
SM14148,24.3761,55.733,Panevezys District Municipality (excl. Panevezys City),ADM2,DISTRICT
SM280314,73.6833,42.8167,Panfilov,ADM2,DISTRICT
SM279832,79.75,44.25,Panfilovskiy,ADM2,DISTRICT
SM297067,98.2056,19.6045,Pang Ma Pha,ADM2,DISTRICT
SM296960,99.3559,16.0253,Pang Sila Thong,ADM2,DISTRICT
SM18001,-174.3304,-19.7569,Pangai,ADM2,DISTRICT
SM18002,-173.9986,-18.6824,Pangaimotu,ADM2,DISTRICT
SM299202,38.822,-5.5969,Pangani,ADM2,DISTRICT
SM291348,26.15,46.9333,Pangarati,ADM2,DISTRICT
SM242126,120.365,15.9377,Pangasinan,LANGUAGE,LANGUAGE
SM2666,120.3333,15.9167,Pangasinan,ADM1:ADM2,DISTRICT
SM276576,119.5506,-4.7827,Pangkajene Dan Kepulauan,ADM2,DISTRICT
SM276638,106.1333,-2.1333,Pangkalpinang,ADM2,DISTRICT
SM260427,14.5,-9.75,Pango Aluquém,ADM2,DISTRICT
SM242122,11.196,9.304,Pangseng,LANGUAGE,LANGUAGE
SM273609,-79.1592,-1.0821,Pangua,ADM2,DISTRICT
SM256702,-112,38,Panguitch,ETHNICITY,ETHNICITY
SM242127,120.536,6.2717,Pangutaran Sama,LANGUAGE,LANGUAGE
SM242123,34.8039,-9.9678,Pangwa,LANGUAGE,LANGUAGE
SM250939,34.96,-10.1,Pangwa,ETHNICITY,ETHNICITY
SM246202,96.3855,27.2572,Pangwa Naga,LANGUAGE,LANGUAGE
SM242118,76.5569,32.9753,Pangwali,LANGUAGE,LANGUAGE
SM276536,136.6,-3.9,Paniai,ADM2,DISTRICT
SM242133,145.752,-5.2139,Panim,LANGUAGE,LANGUAGE
SM282062,-101.7646,20.0116,Panindícuaro,ADM2,DISTRICT
SM294982,40.1667,51.6667,Paninskiy rayon,ADM2,DISTRICT
SM276851,76.9263,29.3326,Panipat (1996-),ADM2,DISTRICT
SM242131,76.0779,11.7318,Paniya,LANGUAGE,LANGUAGE
SM305955,69.1668,37.3161,Panj,ADM2,DISTRICT
SM260258,66.9305,34.4221,Panjab,ADM2,DISTRICT
SM375384,64.2038,26.7375,Panjgur,ADM2:ADM3,DISTRICT
SM269244,121.9936,41.1317,Panjin,ADM2,DISTRICT
SM2667,69.9829,35.4025,Panjshir,ADM1,DISTRICT
SM260060,65.159,31.0731,Panjwayi,ADM2,DISTRICT
SM242142,-39.6667,-9.0833,Pankararé,LANGUAGE,LANGUAGE
SM242144,-37.0167,-9,Pankararú,LANGUAGE,LANGUAGE
SM242143,92.9461,22.6797,Pankhu,LANGUAGE,LANGUAGE
SM242143,92.9461,22.6797,Pankhu,LANGUAGE,LANGUAGE
SM242143,92.4581,22.4124,Pankhu,LANGUAGE,LANGUAGE
SM242143,92.4581,22.4124,Pankhu,LANGUAGE,LANGUAGE
SM292981,80.3333,53.8333,Pankrushikhinskiy rayon,ADM2,DISTRICT
SM285183,9.3988,9.216,Pankshin,ADM2,DISTRICT
SM305894,126.5833,37.9167,Panmun,ADM2,DISTRICT
SM276993,80.25,24.5,Panna,ADM2,DISTRICT
SM242145,119.191,-3.2197,Pannei,LANGUAGE,LANGUAGE
SM276268,17.8134,47.4557,Pannonhalm,ADM2,DISTRICT
SM10961,32.4469,34.9432,Pano Akourdaleia,ADM2,DISTRICT
SM10962,32.6668,34.7404,Pano Archimandrita,ADM2,DISTRICT
SM10963,32.3896,34.9283,Pano Arodes,ADM2,DISTRICT
SM10964,33.2636,35.0857,Pano Deftera,ADM2,DISTRICT
SM10965,33.3203,35.2848,Pano Dikomo,ADM2,DISTRICT
SM10966,32.8419,34.7509,Pano Kivides,ADM2,DISTRICT
SM10967,32.9959,35.0982,Pano Koutrafas,ADM2,DISTRICT
SM10968,33.3057,34.8637,Pano Lefkara,ADM2,DISTRICT
SM10969,32.6448,34.9517,Pano Panagia,ADM2,DISTRICT
SM10970,32.8791,34.9113,Pano Platres,ADM2,DISTRICT
SM10971,32.9918,34.7111,Pano Polemidia,ADM2,DISTRICT
SM10972,32.67,35.1319,Pano Pyrgos,ADM2,DISTRICT
SM10973,33.0021,35.1449,Pano Zodeia,ADM2,DISTRICT
SM242146,-71.5615,-7.9746,Panoan Katukína,LANGUAGE,LANGUAGE
SM242147,-75,-7.1667,Panobo,LANGUAGE,LANGUAGE
SM302732,-94.3057,32.1624,Panola,ADM2,DISTRICT
SM301475,-89.9506,34.3639,Panola,ADM2,DISTRICT
SM297467,104.0755,15.693,Panom Phrai,ADM2,DISTRICT
SM266767,-51.8719,-21.5083,Panorama,ADM2,DISTRICT
SM282694,-98.285,19.3441,Panotla,ADM2,DISTRICT
SM270802,-72.4752,6.4049,Panqueba,ADM2,DISTRICT
SM14084,-9.2332,7.0773,Panta (geonames),ADM2,DISTRICT
SM256714,-75.44,6.62,Pantagoro,ETHNICITY,ETHNICITY
SM262742,-66.8173,-18.2293,Pantaleón Dalence,ADM2,DISTRICT
SM265808,-52.3417,-30.2582,Pantano Grande,ADM2,DISTRICT
SM297821,124.3808,-9.2002,Pante Macassar,ADM2,DISTRICT
SM283177,-92.4552,17.0637,Pantelhó,ADM2,DISTRICT
SM290970,26.2068,44.4524,Pantelimon,ADM2,DISTRICT
SM282247,-97.8745,20.5515,Pantepec,ADM2,DISTRICT
SM283179,-93.0542,17.1872,Pantepec,ADM2,DISTRICT
SM290120,23.5564,47.0408,Panticeu,ADM2,DISTRICT
SM458087,118.5043,-22.5578,Pantikura,ETHNICITY,ETHNICITY
SM242156,118.723,-22.7816,Panytyima,LANGUAGE,LANGUAGE
SM256721,-77.96,-1.33,Panzaleo,ETHNICITY,ETHNICITY
SM269328,101.5797,26.8589,Panzhihua,ADM2,DISTRICT
SM242163,80.9619,24.0622,Pao,LANGUAGE,LANGUAGE
SM303884,-68.1288,9.6375,Pao de San Juan Bautista,ADM2,DISTRICT
SM256722,102.33,35.36,Paoan,ETHNICITY,ETHNICITY
SM268570,16.4281,7.1564,Paoua,ADM2,DISTRICT
SM263728,-44.695,-19.3969,Papagaios,ADM2,DISTRICT
SM281954,-98.8568,19.5635,Papalotla,ADM2,DISTRICT
SM282695,-98.1973,19.1723,Papalotla de Xicohténcatl,ADM2,DISTRICT
SM266235,-50.1747,-26.4763,Papanduva,ADM2,DISTRICT
SM282758,-97.3087,20.44,Papantla,ADM2,DISTRICT
SM242165,-97.2554,20.5671,Papantla Totonac,LANGUAGE,LANGUAGE
SM242168,155.289,-5.9228,Papapana,LANGUAGE,LANGUAGE
SM242171,115.58,5.5738,Papar,LANGUAGE,LANGUAGE
SM11254,-149.5548,-17.7502,Papara,ADM2,DISTRICT
SM256725,-78.44,8.84,Paparo,ETHNICITY,ETHNICITY
SM242172,138.605,-2.8754,Papasena,LANGUAGE,LANGUAGE
SM242166,-72,-9.5,Papavô,LANGUAGE,LANGUAGE
SM11255,-149.568,-17.535,Papeete,ADM2,DISTRICT
SM242174,-15.8388,11.8977,Papel,LANGUAGE,LANGUAGE
SM250627,-15.7085,11.917,Papel,ETHNICITY,ETHNICITY
SM250627,-15.7085,11.917,Papel,ETHNICITY,ETHNICITY
SM250627,-16,12,Papel,ETHNICITY,ETHNICITY
SM250627,-16,12,Papel,ETHNICITY,ETHNICITY
SM303693,-69.4602,8.9411,Papelón,ADM2,DISTRICT
SM285686,4.6913,51.8318,Papendrecht,ADM2,DISTRICT
SM2668,32.5167,34.9,Paphos,ADM1,DISTRICT
SM242177,142.011,-4.4725,Papi,LANGUAGE,LANGUAGE
SM242175,-69.9399,12.4849,Papiamento,LANGUAGE,LANGUAGE
SM256726,-68.3,50.25,Papinachos,ETHNICITY,ETHNICITY
SM12607,-76.7387,18.0154,Papine,ADM2,DISTRICT
SM242176,147.417,-2.0622,Papitalai,LANGUAGE,LANGUAGE
SM291174,24.218,46.5596,Papiu Ilarian,ADM2,DISTRICT
SM259530,-63.2458,2.5237,Papiu Yanomama Sign Language,LANGUAGE,LANGUAGE
SM242178,120.577,24.324,Papora-Hoanya,LANGUAGE,LANGUAGE
SM2669,138,-4.75,Papua (2003-),ADM1,DISTRICT
SM242184,147.1167,-9.5,Papua New Guinean Sign Language,LANGUAGE,LANGUAGE
SM242180,139.651,-2.5348,Papuan Malay,LANGUAGE,LANGUAGE
SM277199,93.5969,27.2888,Papum Pare,ADM2,DISTRICT
SM242181,135.907,-1.773,Papuma,LANGUAGE,LANGUAGE
SM270650,-70.7097,1.6834,Papunahua,ADM2,DISTRICT
SM265080,-41.6472,-7.1075,Paquetá,ADM2,DISTRICT
SM273580,-78.6772,-3.933,Paquisha,ADM2,DISTRICT
SM2671,-55.3333,5.4167,Para,ADM1,DISTRICT
SM256728,-50.19,-2.12,Para Arara,ETHNICITY,ETHNICITY
SM242190,95.1618,26.2072,Para Naga,LANGUAGE,LANGUAGE
SM265291,-43.7352,-22.6193,Paracambi,ADM2,DISTRICT
SM263730,-46.8826,-17.1329,Paracatu,ADM2,DISTRICT
SM242187,70.8805,36.0432,Parachi,LANGUAGE,LANGUAGE
SM282063,-102.0651,19.6483,Paracho,ADM2,DISTRICT
SM267817,-39.0427,-3.4866,Paracuru,ADM2,DISTRICT
SM12608,-77.9098,18.494,Paradise,ADM2,DISTRICT
SM323777,-110.297,53.0314,Paradise Valley,ADM2:ADM3,DISTRICT
SM264210,-47.6814,-3.2263,Paragominas,ADM2,DISTRICT
SM289633,-57.1421,-25.6799,Paraguarí,ADM2,DISTRICT
SM2673,-57.1667,-26,Paraguarí,ADM1,DISTRICT
SM242199,-57.0882,-25.6055,Paraguayan Guaraní,LANGUAGE,LANGUAGE
SM242206,-57.6667,-25.2667,Paraguayan Sign Language,LANGUAGE,LANGUAGE
SM263731,-45.743,-21.5808,Paraguaçu,ADM2,DISTRICT
SM266768,-50.6453,-22.4702,Paraguaçu Paulista,ADM2,DISTRICT
SM262840,-43.8471,-6.4095,Paraibano,ADM2,DISTRICT
SM266769,-45.6378,-23.4812,Paraibuna,ADM2,DISTRICT
SM267818,-39.1698,-3.4134,Paraipaba,ADM2,DISTRICT
SM271870,-71.2089,18.0265,Paraiso,ADM2,DISTRICT
SM9978,-53.1307,-19.2273,Paraiso das Aguas,ADM2,DISTRICT
SM263732,-45.8458,-22.5767,Paraisópolis,ADM2,DISTRICT
SM256737,-62.5,2.99,Paraitiry,ETHNICITY,ETHNICITY
SM256740,-50.07,-3.32,Parakana,ETHNICITY,ETHNICITY
SM242200,-50.0621,-4.6468,Parakanã,LANGUAGE,LANGUAGE
SM262153,2.6226,9.3499,Parakou,ADM2,DISTRICT
SM10974,34.0125,35.025,Paralimni,ADM2,DISTRICT
SM256741,-54,5,Paramaccaans,ETHNICITY,ETHNICITY
SM10975,32.7979,34.6869,Paramali,ADM2,DISTRICT
SM2675,-55.1667,5.8667,Paramaribo,ADM1,DISTRICT
SM267819,-40.6088,-6.2541,Parambu,ADM2,DISTRICT
SM267576,-42.2312,-13.5143,Paramirim,ADM2,DISTRICT
SM267820,-39.3622,-4.1474,Paramoti,ADM2,DISTRICT
SM10976,32.9725,34.7561,Paramytha,ADM2,DISTRICT
SM293621,49.4167,56.6667,Paran'ginskiy rayon,ADM2,DISTRICT
SM264616,-52.1346,-22.8465,Paranacity,ADM2,DISTRICT
SM264617,-48.5193,-25.5673,Paranaguá,ADM2,DISTRICT
SM242194,122.372,17,Paranan,LANGUAGE,LANGUAGE
SM242208,122.3703,17.0411,Paranan-Pahanan,FAMILY,LANGUAGE
SM266771,-48.7593,-23.4886,Paranaparema,ADM2,DISTRICT
SM264618,-52.076,-22.6562,Paranapoema,ADM2,DISTRICT
SM266772,-50.5968,-20.0594,Paranapuã,ADM2,DISTRICT
SM264862,-36.682,-8.8981,Paranatama,ADM2,DISTRICT
SM263206,-54.0155,-13.3433,Paranatinga,ADM2,DISTRICT
SM264619,-52.5375,-22.9003,Paranavaí,ADM2,DISTRICT
SM242201,-59.053,-9.3424,Paranawát,LANGUAGE,LANGUAGE
SM263072,-51.3983,-19.526,Paranaíba,ADM2,DISTRICT
SM263205,-56.7654,-9.5637,Paranaíta,ADM2,DISTRICT
SM263073,-55.3545,-23.7217,Paranhos,ADM2,DISTRICT
SM267280,-47.9434,-12.7388,Paraná,ADM2,DISTRICT
SM265599,-38.2998,-6.447,Paraná,ADM2,DISTRICT
SM261177,-60,-31.6667,Paraná,ADM2,DISTRICT
SM2676,-51.3333,-24.5,Paraná,ADM1,DISTRICT
SM263733,-44.4527,-19.2723,Paraopeba,ADM2,DISTRICT
SM266773,-50.8341,-21.8448,Parapuã,ADM2,DISTRICT
SM264260,-36.677,-7.3114,Parari,ADM2,DISTRICT
SM4693,83.738,27.52,Parasi,ADM2,DISTRICT
SM270035,-73.1856,4.4169,Paratebueno,ADM2,DISTRICT
SM265293,-44.7062,-23.1484,Parati,ADM2,DISTRICT
SM267577,-43.0698,-12.7356,Paratinga,ADM2,DISTRICT
SM292640,25.2158,45.8514,Parau,ADM2,DISTRICT
SM258894,-61.8486,0.2265,Parauana,LANGUAGE,LANGUAGE
SM264212,-50.5547,-6.1856,Parauapebas,ADM2,DISTRICT
SM242204,-71.8399,10.8427,Paraujano,LANGUAGE,LANGUAGE
SM256746,-72,11,Paraujano,ETHNICITY,ETHNICITY
SM256746,-72,11,Paraujano,ETHNICITY,ETHNICITY
SM256746,-72.41,9.9,Paraujano,ETHNICITY,ETHNICITY
SM256746,-72.41,9.9,Paraujano,ETHNICITY,ETHNICITY
SM292138,26.9679,46.3099,Parava,ADM2,DISTRICT
SM242197,-60.4833,3.1167,Paravilhana,LANGUAGE,LANGUAGE
SM242195,145.418,-5.0112,Parawen,LANGUAGE,LANGUAGE
SM265601,-35.9324,-5.2772,Parazinho,ADM2,DISTRICT
SM265809,-51.7973,-28.5958,Paraí,ADM2,DISTRICT
SM2674,-36.5,-7.25,Paraíba,ADM1,DISTRICT
SM265292,-43.2621,-22.1872,Paraíba do Sul,ADM2,DISTRICT
SM282625,-93.1999,18.376,Paraíso,ADM2,DISTRICT
SM270919,-83.8,9.75,Paraíso,ADM2,DISTRICT
SM266770,-48.7714,-21.0067,Paraíso,ADM2,DISTRICT
SM266236,-53.6855,-26.6687,Paraíso,ADM2,DISTRICT
SM295696,-88.9608,13.6335,Paraíso de Osorio,ADM2,DISTRICT
SM264615,-52.6363,-23.2541,Paraíso do Norte,ADM2,DISTRICT
SM265810,-53.1116,-29.724,Paraíso do Sul,ADM2,DISTRICT
SM267279,-48.8803,-10.2321,Paraíso do Tocantins,ADM2,DISTRICT
SM265600,-37.1366,-5.7322,Paraú,ADM2,DISTRICT
SM268127,-50.6129,-17.0865,Paraúna,ADM2,DISTRICT
SM375354,83.7286,28.2026,Parbat,ADM2:ADM3,DISTRICT
SM277049,76.75,19.5,Parbhani (1999-),ADM2,DISTRICT
SM17585,-61.0161,13.7964,Parc Estate,ADM2,DISTRICT
SM9428,6.0907,49.9974,Parc Hosingen,ADM1:ADM2,DISTRICT
SM288898,22.9963,51.5998,Parczew,ADM2,DISTRICT
SM242215,79.7773,22.1666,Pardhan,LANGUAGE,LANGUAGE
SM242213,77.1234,19.9989,Pardhi,LANGUAGE,LANGUAGE
SM292066,28.9569,45.3078,Pardina,ADM2,DISTRICT
SM266774,-48.3842,-23.0899,Pardinho,ADM2,DISTRICT
SM11792,51.7771,35.7417,Pardis,ADM2,DISTRICT
SM289901,26.8793,45.4499,Pardosi,ADM2,DISTRICT
SM271242,15.75,50.0833,Pardubice,ADM2,DISTRICT
SM2677,16.0833,49.8333,Pardubický,ADM1,DISTRICT
SM242221,141.658,-6.4688,Pare,LANGUAGE,LANGUAGE
SM250043,38.86,-2.67,Pare,ETHNICITY,ETHNICITY
SM250043,38.86,-2.67,Pare,ETHNICITY,ETHNICITY
SM250043,38.86,-2.67,Pare,ETHNICITY,ETHNICITY
SM250043,38,-4,Pare,ETHNICITY,ETHNICITY
SM250043,38,-4,Pare,ETHNICITY,ETHNICITY
SM250043,38,-4,Pare,ETHNICITY,ETHNICITY
SM250043,38,-4.5,Pare,ETHNICITY,ETHNICITY
SM250043,38,-4.5,Pare,ETHNICITY,ETHNICITY
SM250043,38,-4.5,Pare,ETHNICITY,ETHNICITY
SM265811,-51.4269,-29.6115,Pareci Novo,ADM2,DISTRICT
SM266099,-61.3166,-12.2866,Parecis,ADM2,DISTRICT
SM242222,-57.4059,-14.5929,Parecís,LANGUAGE,LANGUAGE
SM289301,-8.3751,41.205,Paredes,ADM2,DISTRICT
SM289357,-8.5703,41.91,Paredes de Coura,ADM2,DISTRICT
SM10977,33.1544,34.7577,Parekklisia,ADM2,DISTRICT
SM265602,-36.6083,-6.729,Parelhas,ADM2,DISTRICT
SM14961,151.789,7.362,Parem Municipality,ADM2,DISTRICT
SM276577,119.65,-4.0333,Parepare,ADM2,DISTRICT
SM256755,-59.56,-14.14,Paressi,ETHNICITY,ETHNICITY
SM256755,-59.56,-14.14,Paressi,ETHNICITY,ETHNICITY
SM256755,-58,-15,Paressi,ETHNICITY,ETHNICITY
SM256755,-58,-15,Paressi,ETHNICITY,ETHNICITY
SM293341,43.5,58.6667,Parfen'evskiy rayon,ADM2,DISTRICT
SM293823,31.6667,58,Parfinskiy rayon,ADM2,DISTRICT
SM292139,26.6502,46.2441,Pargaresti,ADM2,DISTRICT
SM4028,25.0661,57.3683,Pargaujas Novads,ADM1,DISTRICT
SM16000,-67.0433,17.9763,Parguera Barrio,ADM2,DISTRICT
SM250628,33,5,Pari,ETHNICITY,ETHNICITY
SM276641,100.1205,-0.6268,Pariaman,ADM2,DISTRICT
SM9571,70.0433,35.6475,Parian,ADM2,DISTRICT
SM265403,-38.0167,-9.2204,Pariconha,ADM2,DISTRICT
SM276602,120.1784,0.3368,Parigi Moutong,ADM2,DISTRICT
SM286879,-73.6349,-15.0297,Parinacochas,ADM2,DISTRICT
SM269102,-69.5716,-18.198,Parinacota,ADM2,DISTRICT
SM292141,27.1288,46.4784,Parincea,ADM2,DISTRICT
SM256761,152.75,-26.09,Paringnoga,ETHNICITY,ETHNICITY
SM267342,-56.9341,-2.6635,Parintins,ADM2,DISTRICT
SM295356,160.3255,-9.5726,Paripao,ADM2,DISTRICT
SM267578,-37.886,-10.5828,Paripiranga,ADM2,DISTRICT
SM265404,-35.5898,-9.4294,Paripueira,ADM2,DISTRICT
SM266775,-47.8455,-24.6639,Pariquera-Açu,ADM2,DISTRICT
SM274506,2.3486,48.8534,Paris,ADM2,DISTRICT
SM16001,-67.1012,18.0499,Paris Barrio,ADM2,DISTRICT
SM266776,-50.039,-20.2655,Parisi,ADM2,DISTRICT
SM286710,-80.5637,8.0624,Parita,ADM2,DISTRICT
SM292142,26.609,46.5901,Parjol,ADM2,DISTRICT
SM303301,-109.5885,44.5206,Park,ADM2,DISTRICT
SM301649,-110.5263,45.4883,Park,ADM2,DISTRICT
SM303360,-105.7172,39.1193,Park,ADM2,DISTRICT
SM280888,102.326,20.1671,Park Ou,ADM2,DISTRICT
SM242231,69.9423,24.486,Parkari Koli,LANGUAGE,LANGUAGE
SM300749,-87.2064,39.7736,Parke,ADM2,DISTRICT
SM303458,69.6667,41.3,Parkent,ADM2,DISTRICT
SM302733,-97.8051,32.7777,Parker,ADM2,DISTRICT
SM17586,-60.9905,14.0038,Parker'S Hill,ADM2,DISTRICT
SM261446,148.0251,-32.8232,Parkes,ADM2,DISTRICT
SM280816,101.5039,18.3947,Parklai,ADM2,DISTRICT
SM10165,-114.0652,52.607,Parkland Beach,ADM2,DISTRICT
SM10166,-114.3715,53.4829,Parkland County,ADM2,DISTRICT
SM12609,-76.8594,18.098,Parks Road,ADM2,DISTRICT
SM242232,14.0964,11.0206,Parkwa,LANGUAGE,LANGUAGE
SM458706,143.0399,-26.2572,Parlpakurnu,ETHNICITY,ETHNICITY
SM277951,10.0833,44.7,Parma,ADM2,DISTRICT
SM302734,-102.7845,34.5301,Parmer,ADM2,DISTRICT
SM265081,-44.5601,-10.1215,Parnaguá,ADM2,DISTRICT
SM265603,-35.2057,-5.9245,Parnamirim,ADM2,DISTRICT
SM264863,-39.7563,-8.204,Parnamirim,ADM2,DISTRICT
SM262841,-43.6035,-5.5291,Parnarama,ADM2,DISTRICT
SM265083,-41.7354,-2.9228,Parnaíba,ADM2,DISTRICT
SM458497,138.8105,-21.2603,Parnkarra,ETHNICITY,ETHNICITY
SM2679,89.4,27.5167,Paro,ADM1,DISTRICT
SM265812,-50.8567,-29.6715,Parobé,ADM2,DISTRICT
SM261656,145.7176,-27.9874,Paroo,ADM2,DISTRICT
SM12610,-77.8177,17.9617,Parottee,ADM2,DISTRICT
SM18353,-55.7226,-34.756,Parque Del Plata,ADM2,DISTRICT
SM261447,151.0026,-33.8125,Parramatta,ADM2,DISTRICT
SM275400,-90.8198,14.5875,Parramos,ADM2,DISTRICT
SM283294,-102.1172,25.5136,Parras,ADM2,DISTRICT
SM270949,-84.3175,9.55,Parrita,ADM2,DISTRICT
SM268873,-79.833,45.7501,Parry Sound,ADM2,DISTRICT
SM12611,-77.1035,18.3836,Parry Town,ADM2,DISTRICT
SM375310,84.8271,27.2235,Parsa,ADM2:ADM3,DISTRICT
SM277577,47.9167,39.5833,Parsabad,ADM2,DISTRICT
SM289902,26.5528,45.3076,Parscov,ADM2,DISTRICT
SM242235,74.438,20.625,Parsi,LANGUAGE,LANGUAGE
SM256767,72.8,19,Parsi,ETHNICITY,ETHNICITY
SM242236,58.624,32.994,Parsi-Dari,LANGUAGE,LANGUAGE
SM12612,-77.4097,18.0802,Part Of Banana Ground,ADM2,DISTRICT
SM12613,-77.579,18.0064,Part Of Gutters,ADM2,DISTRICT
SM12614,-77.4213,18.0791,Part of Banana Ground,ADM2,DISTRICT
SM12615,-77.2754,18.187,Part of Douglas Castle,ADM2,DISTRICT
SM12616,-76.9908,18.2453,Part of Guys Hill,ADM2,DISTRICT
SM12617,-77.2512,18.1716,Part of Kellits,ADM2,DISTRICT
SM12618,-76.9511,18.0397,Part of Keystone,ADM2,DISTRICT
SM12619,-76.888,18.0912,Part of Rock Hall,ADM2,DISTRICT
SM12620,-77.4663,18.1582,Part of Spaldings,ADM2,DISTRICT
SM291826,25.9669,47.6231,Partestii De Jos,ADM2,DISTRICT
SM291827,25.8667,47.6555,Partestii De Sus,ADM2,DISTRICT
SM242238,56.056,37.052,Parthian,LANGUAGE,LANGUAGE
SM271894,-71.5181,19.5012,Partido,ADM2,DISTRICT
SM9702,-58.75,-35.0833,Partido de Canuelas,ADM2,DISTRICT
SM9703,-57.9776,-35.4775,Partido de Chascomus (2009-),ADM2,DISTRICT
SM9704,-58.4658,-34.8192,Partido de Esteban Echeverria (1994-),ADM2,DISTRICT
SM9705,-58.5247,-34.8542,Partido de Ezeiza,ADM2,DISTRICT
SM9706,-58.25,-34.8333,Partido de Florencio Varela,ADM2,DISTRICT
SM9707,-58.6281,-34.5869,Partido de Hurlingham,ADM2,DISTRICT
SM9708,-58.6661,-34.6603,Partido de Ituzaingo,ADM2,DISTRICT
SM9709,-58.7656,-34.5198,Partido de Jose C. Paz,ADM2,DISTRICT
SM9710,-57.8969,-35.8802,Partido de Lezama,ADM2,DISTRICT
SM9711,-57.6863,-35.1849,Partido de Magdalena (1994-),ADM2,DISTRICT
SM9712,-58.6956,-34.5077,Partido de Malvinas Argentinas,ADM2,DISTRICT
SM9713,-58.6219,-34.6514,Partido de Moron,ADM2,DISTRICT
SM9714,-58.8333,-34.4167,Partido de Pilar,ADM2,DISTRICT
SM9715,-58.3792,-34.9178,Partido de Presidente Peron,ADM2,DISTRICT
SM9716,-57.3383,-35.3872,Partido de Punta Indio,ADM2,DISTRICT
SM9717,-58.542,-34.4454,Partido de San Fernando,ADM2,DISTRICT
SM9718,-58.6917,-34.5521,Partido de San Miguel,ADM2,DISTRICT
SM9719,-58.5,-35.0833,Partido de San Vicente,ADM2,DISTRICT
SM296593,12.1307,57.7331,Partille,ADM2,DISTRICT
SM293447,94.1667,55,Partizanskiy rayon,ADM2,DISTRICT
SM296169,18.3796,48.6261,Partizánske,ADM2,DISTRICT
SM9806,27.67,53.8803,Partyzanski Rayon,ADM2,DISTRICT
SM256768,145,-30.37,Paruinji,ETHNICITY,ETHNICITY
SM256768,145,-30.37,Paruinji,ETHNICITY,ETHNICITY
SM256768,144.3146,-29.4582,Paruinji,ETHNICITY,ETHNICITY
SM256768,144.3146,-29.4582,Paruinji,ETHNICITY,ETHNICITY
SM457940,70.802,35.4079,Parun,ADM2,DISTRICT
SM286897,-71.9167,-13.9167,Paruro,ADM2,DISTRICT
SM292515,24.5422,47.396,Parva,ADM2,DISTRICT
SM262356,25.2,42.0333,Parvomay,ADM2,DISTRICT
SM2680,68.9167,35,Parwan,ADM1,DISTRICT
SM242241,69.26,36.4922,Parya,LANGUAGE,LANGUAGE
SM2672,-53,-4,Pará (1943-),ADM1,DISTRICT
SM242198,-53.0657,-3.7126,Pará Arára,LANGUAGE,LANGUAGE
SM263729,-44.6129,-19.8212,Pará de Minas,ADM2,DISTRICT
SM242203,-49.0217,-4.9116,Pará-Maranhão Gavião,LANGUAGE,LANGUAGE
SM282064,-102.2236,19.048,Parácuaro,ADM2,DISTRICT
SM282185,-99.5821,26.5253,Parás,ADM2,DISTRICT
SM274502,2.2866,50.4828,Pas-de-Calais,ADM2,DISTRICT
SM259991,65.0898,33.5895,Pasaband,ADM2,DISTRICT
SM275184,-90.2118,13.9282,Pasaco,ADM2,DISTRICT
SM273616,-79.8167,-3.3333,Pasaje,ADM2,DISTRICT
SM276643,99.9461,0.425,Pasaman,ADM2,DISTRICT
SM276642,99.5197,0.2815,Pasaman Barat,ADM2,DISTRICT
SM295703,-87.818,13.5106,Pasaquina,ADM2,DISTRICT
SM291175,24.705,46.4858,Pasareni,ADM2,DISTRICT
SM270036,-74.2751,4.3023,Pasca,ADM2,DISTRICT
SM17587,-60.8915,13.9014,Pascal,ADM2,DISTRICT
SM290854,26.7124,47.2474,Pascani,ADM2,DISTRICT
SM11678,86.9785,23.6813,Paschim Bardhaman,ADM2,DISTRICT
SM300359,-82.4389,28.3067,Pasco,ADM2,DISTRICT
SM286812,-76.0833,-10.6667,Pasco,ADM2,DISTRICT
SM2681,-75.25,-10.5,Pasco,ADM1,DISTRICT
SM276419,116.2354,-1.4352,Paser,ADM2,DISTRICT
SM277420,84.5,27,Pashchim Champaran,ADM2,DISTRICT
SM277363,87.3811,22.4081,Pashchim Medinipur (pre-2017),ADM2,DISTRICT
SM276908,85.5,22.5,Pashchim Singhbhum (2001-),ADM2,DISTRICT
SM260288,64.7781,35.8099,Pashtun Kot,ADM2,DISTRICT
SM260014,62.7546,34.1843,Pashtun Zarghun,ADM2,DISTRICT
SM242255,142.307,-3.952,Pasi,LANGUAGE,LANGUAGE
SM13730,28.1459,56.2356,Pasienes pagasts,ADM2,DISTRICT
SM298393,41.6785,39.9774,Pasinler,ADM2,DISTRICT
SM18354,-56.0319,-34.8494,Paso Carrasco,ADM2,DISTRICT
SM16002,-66.6454,18.2093,Paso Palma Barrio,ADM2,DISTRICT
SM15359,-55.9133,-25.7392,Paso Yobai,ADM2,DISTRICT
SM261107,-68.8333,-44,Paso de Indios,ADM2,DISTRICT
SM282759,-96.4129,19.2604,Paso de Ovejas,ADM2,DISTRICT
SM289603,-58.5145,-27.2273,Paso de Patria,ADM2,DISTRICT
SM261156,-57.25,-29.6667,Paso de los Libres,ADM2,DISTRICT
SM300129,-56.5119,-32.7163,Paso de los Toros,ADM2,DISTRICT
SM282760,-96.6817,18.9502,Paso del Macho,ADM2,DISTRICT
SM301998,-76.2491,36.2649,Pasquotank,ADM2,DISTRICT
SM242258,139.26,-3.7687,Pass Valley Yali,LANGUAGE,LANGUAGE
SM263734,-44.9599,-22.4087,Passa Quatro,ADM2,DISTRICT
SM265813,-52.8651,-29.4306,Passa Sete,ADM2,DISTRICT
SM263736,-44.4986,-20.6435,Passa Tempo,ADM2,DISTRICT
SM263737,-44.26,-22.1788,Passa Vinte,ADM2,DISTRICT
SM265604,-35.6263,-6.453,Passa e Fica,ADM2,DISTRICT
SM297822,124.3498,-9.467,Passabe,ADM2,DISTRICT
SM265605,-35.3953,-6.2725,Passabém,ADM2,DISTRICT
SM264261,-37.0367,-7.1189,Passabém,ADM2,DISTRICT
SM263738,-43.1874,-19.3565,Passabém,ADM2,DISTRICT
SM12621,-76.8679,17.9779,Passage Fort,ADM2,DISTRICT
SM262842,-43.7535,-6.1075,Passagem Franca,ADM2,DISTRICT
SM265084,-42.4058,-5.8273,Passagem Franca do Piauí,ADM2,DISTRICT
SM301820,-74.3003,41.0337,Passaic,ADM2,DISTRICT
SM242261,-67.4185,-1.4154,Passe,LANGUAGE,LANGUAGE
SM242261,-67.4185,-1.4154,Passe,LANGUAGE,LANGUAGE
SM242261,-68.4686,-2.597,Passe,LANGUAGE,LANGUAGE
SM242261,-68.4686,-2.597,Passe,LANGUAGE,LANGUAGE
SM264864,-35.571,-8.0009,Passira,ADM2,DISTRICT
SM12622,-76.7765,17.973,Passmore Town,ADM2,DISTRICT
SM265815,-52.4409,-28.2877,Passo Fundo,ADM2,DISTRICT
SM265406,-35.4279,-9.2987,Passo de Camaragibe,ADM2,DISTRICT
SM266237,-49.7409,-29.2939,Passo de Torres,ADM2,DISTRICT
SM265814,-52.2318,-29.7313,Passo do Sobrado,ADM2,DISTRICT
SM262186,-2.1667,12.9167,Passoré,ADM1:ADM2,DISTRICT
SM263739,-46.6217,-20.7642,Passos,ADM2,DISTRICT
SM266238,-51.9472,-26.7077,Passos Maia,ADM2,DISTRICT
SM262668,26.9167,55.0833,Pastavy,ADM2,DISTRICT
SM273537,-76.9167,-1.75,Pastaza,ADM2,DISTRICT
SM2682,-76.8333,-1.6667,Pastaza,ADM1,DISTRICT
SM18425,66.6667,39.65,Pastdargom Tumani,ADM2,DISTRICT
SM18426,66.6598,39.664,Pastdargom,ADM2,DISTRICT
SM256776,-77.94,1.47,Pasto,ETHNICITY,ETHNICITY
SM10435,-77.2787,1.2147,Pasto (1999-),ADM2,DISTRICT
SM16003,-66.2716,18.1208,Pasto Barrio (Aibonito),ADM2,DISTRICT
SM16004,-66.3502,18.1092,Pasto Barrio (Coamo),ADM2,DISTRICT
SM16005,-66.7934,18.1147,Pasto Barrio (Guayanilla),ADM2,DISTRICT
SM16006,-66.4324,18.2761,Pasto Barrio (Morovis),ADM2,DISTRICT
SM16007,-66.2155,18.0964,Pasto Viejo Barrio,ADM2,DISTRICT
SM275276,-90.7704,14.5958,Pastores,ADM2,DISTRICT
SM262843,-44.2423,-6.766,Pastos Bons,ADM2,DISTRICT
SM291349,26.5762,47.1594,Pastraveni,ADM2,DISTRICT
SM276359,112.8333,-7.7333,Pasuruan,ADM2,DISTRICT
SM281346,24.4,56.0667,Pasvalio,ADM2,DISTRICT
SM260810,-62.9167,-40.25,Patagones,ADM2,DISTRICT
SM296077,-54.4358,5.4038,Patamacca,ADM2,DISTRICT
SM242271,-59.5772,4.7583,Patamona,LANGUAGE,LANGUAGE
SM256779,-58.29,5.7,Patamona,ETHNICITY,ETHNICITY
SM276827,71.8,23.7,Patan,ADM2,DISTRICT
SM242269,128.634,0.341,Patani,LANGUAGE,LANGUAGE
SM284656,6.0977,5.1793,Patani,ADM2,DISTRICT
SM289903,26.3454,45.3204,Patarlagele,ADM2,DISTRICT
SM256783,-39.35,-16.25,Patasho,ETHNICITY,ETHNICITY
SM273570,-78.5,-1.3167,Patate,ADM2,DISTRICT
SM242270,-40.0333,-15.9167,Pataxó Hã-Ha-Hãe,LANGUAGE,LANGUAGE
SM286763,-77.3333,-8.25,Pataz,ADM2,DISTRICT
SM14085,-7.9167,4.5667,Pate,ADM2,DISTRICT
SM284980,5.75,8.73,Pategi,ADM2,DISTRICT
SM242275,146.639,-6.9199,Patep,LANGUAGE,LANGUAGE
SM256785,68.78,27.86,Pathan,ETHNICITY,ETHNICITY
SM256785,68.78,27.86,Pathan,ETHNICITY,ETHNICITY
SM256785,68.78,27.86,Pathan,ETHNICITY,ETHNICITY
SM256785,68.78,27.86,Pathan,ETHNICITY,ETHNICITY
SM256785,69.65,31,Pathan,ETHNICITY,ETHNICITY
SM256785,69.65,31,Pathan,ETHNICITY,ETHNICITY
SM256785,69.65,31,Pathan,ETHNICITY,ETHNICITY
SM256785,69.65,31,Pathan,ETHNICITY,ETHNICITY
SM256785,67,35.5,Pathan,ETHNICITY,ETHNICITY
SM256785,67,35.5,Pathan,ETHNICITY,ETHNICITY
SM256785,67,35.5,Pathan,ETHNICITY,ETHNICITY
SM256785,67,35.5,Pathan,ETHNICITY,ETHNICITY
SM256785,70.5,33.487,Pathan,ETHNICITY,ETHNICITY
SM256785,70.5,33.487,Pathan,ETHNICITY,ETHNICITY
SM256785,70.5,33.487,Pathan,ETHNICITY,ETHNICITY
SM256785,70.5,33.487,Pathan,ETHNICITY,ETHNICITY
SM256785,20,25,Pathan,ETHNICITY,ETHNICITY
SM256785,20,25,Pathan,ETHNICITY,ETHNICITY
SM256785,20,25,Pathan,ETHNICITY,ETHNICITY
SM256785,20,25,Pathan,ETHNICITY,ETHNICITY
SM276955,76.8697,9.2807,Pathanamthitta,ADM2,DISTRICT
SM277148,75.64,32.33,Pathankot,ADM2,DISTRICT
SM296928,99.3394,10.8101,Pathiu,ADM2,DISTRICT
SM242276,76.3397,11.6368,Pathiya,LANGUAGE,LANGUAGE
SM280855,106.0636,14.6845,Pathoomphone,ADM2,DISTRICT
SM297468,103.3873,15.6237,Pathum Rat,ADM2,DISTRICT
SM296869,104.8961,15.8954,Pathum Ratwongsa,ADM2,DISTRICT
SM2683,100.6396,14.0455,Pathum Thani,ADM1,DISTRICT
SM297167,100.534,13.7427,Pathum Wan,ADM2,DISTRICT
SM276316,111.1,-6.7667,Pati,ADM2,DISTRICT
SM277149,76.4,30.35,Patiala (2006-),ADM2,DISTRICT
SM17588,-60.9087,13.85,Patience,ADM2,DISTRICT
SM2684,-66.0177,18.0416,Patillas,ADM1,DISTRICT
SM16008,-66.0149,18.0085,Patillas Barrio-Pueblo,ADM2,DISTRICT
SM263740,-44.1114,-16.058,Patis,ADM2,DISTRICT
SM261194,-60,-24.75,Patiño,ADM2,DISTRICT
SM277421,85.1667,25.4167,Patna,ADM2,DISTRICT
SM298489,42.8674,39.243,Patnos,ADM2,DISTRICT
SM264620,-54.2275,-24.6432,Pato Bragado,ADM2,DISTRICT
SM264622,-52.6634,-26.1702,Pato Branco,ADM2,DISTRICT
SM264262,-37.3429,-6.9969,Patos,ADM2,DISTRICT
SM263741,-46.5032,-18.6105,Patos de Minas,ADM2,DISTRICT
SM265085,-41.2946,-7.6092,Patos do Piauí,ADM2,DISTRICT
SM242281,152.493,-3.7471,Patpatar,LANGUAGE,LANGUAGE
SM291828,26.1884,47.721,Patrauti,ADM2,DISTRICT
SM303063,-80.2844,36.6783,Patrick,ADM2,DISTRICT
SM276790,-4.6931,54.2049,Patrick,ADM1:ADM2,DISTRICT
SM12623,-76.8377,18.0319,Patrick City,ADM2,DISTRICT
SM10978,33.985,35.3615,Patriki,ADM2,DISTRICT
SM263743,-47.0679,-19.0131,Patrocínio,ADM2,DISTRICT
SM266778,-47.2844,-20.6871,Patrocínio Paulista,ADM2,DISTRICT
SM263742,-42.2557,-21.1671,Patrocínio do Muriaé,ADM2,DISTRICT
SM242284,76.5966,32.6963,Pattani,LANGUAGE,LANGUAGE
SM2685,101.375,6.7472,Pattani,ADM1,DISTRICT
SM17589,-60.9847,14.0066,Patterson'S Gap,ADM2,DISTRICT
SM265440,-37.6257,-6.1231,Patu,ADM2,DISTRICT
SM262228,90.3333,22.3333,Patuakhali,ADM2,DISTRICT
SM275749,-85.97,14.36,Patuca,ADM2,DISTRICT
SM291092,22.8037,44.3498,Patulele,ADM2,DISTRICT
SM275365,-91.1761,14.3718,Patulul,ADM2,DISTRICT
SM242290,-122.0094,39.2143,Patwin,LANGUAGE,LANGUAGE
SM256812,-121.6,38.55,Patwin,ETHNICITY,ETHNICITY
SM256812,-121.6,38.55,Patwin,ETHNICITY,ETHNICITY
SM256812,-121.6,38.55,Patwin,ETHNICITY,ETHNICITY
SM256812,-121.6,38.55,Patwin,ETHNICITY,ETHNICITY
SM256812,-122,39,Patwin,ETHNICITY,ETHNICITY
SM256812,-122,39,Patwin,ETHNICITY,ETHNICITY
SM256812,-122,39,Patwin,ETHNICITY,ETHNICITY
SM256812,-122,39,Patwin,ETHNICITY,ETHNICITY
SM256812,-122.05,39.08,Patwin,ETHNICITY,ETHNICITY
SM256812,-122.05,39.08,Patwin,ETHNICITY,ETHNICITY
SM256812,-122.05,39.08,Patwin,ETHNICITY,ETHNICITY
SM256812,-122.05,39.08,Patwin,ETHNICITY,ETHNICITY
SM256812,-122,38.7,Patwin,ETHNICITY,ETHNICITY
SM256812,-122,38.7,Patwin,ETHNICITY,ETHNICITY
SM256812,-122,38.7,Patwin,ETHNICITY,ETHNICITY
SM256812,-122,38.7,Patwin,ETHNICITY,ETHNICITY
SM265294,-43.4136,-22.4075,Paty do Alferes,ADM2,DISTRICT
SM275401,-90.9356,14.6388,Patzicía,ADM2,DISTRICT
SM275237,-91.1972,14.971,Patzité,ADM2,DISTRICT
SM275402,-91.0344,14.6462,Patzún,ADM2,DISTRICT
SM269865,-77.0174,2.1402,Patía,ADM2,DISTRICT
SM267579,-39.6897,-15.4432,Pau Brazil,ADM2,DISTRICT
SM9979,-42.4547,-5.2451,Pau D'Arco Do Piaui,ADM2,DISTRICT
SM267281,-48.937,-7.5588,Pau d'Arco,ADM2,DISTRICT
SM264213,-50.1464,-7.7391,Pau d'Arco,ADM2,DISTRICT
SM265441,-38.1965,-6.1163,Pau dos Ferros,ADM2,DISTRICT
SM291741,23.917,46.0021,Pauca,ADM2,DISTRICT
SM286880,-73.2618,-15.1228,Paucar del Sara Sara,ADM2,DISTRICT
SM286898,-71.4167,-13.1667,Paucartambo,ADM2,DISTRICT
SM264865,-35.1356,-7.943,Paudalho,ADM2,DISTRICT
SM267343,-68.1422,-7.6893,Pauini,ADM2,DISTRICT
SM256813,-61.89,1.96,Pauishana,ETHNICITY,ETHNICITY
SM256813,-61.76,2.9,Pauishana,ETHNICITY,ETHNICITY
SM12624,-78.1861,18.272,Paul Island,ADM2,DISTRICT
SM263744,-42.9815,-20.8603,Paula Cândido,ADM2,DISTRICT
SM264623,-50.8481,-26.1781,Paula Freitas,ADM2,DISTRICT
SM302143,-84.5802,41.1166,Paulding,ADM2,DISTRICT
SM300393,-84.8673,33.9206,Paulding,ADM2,DISTRICT
SM290707,25.8408,46.4007,Pauleni-ciuc,ADM2,DISTRICT
SM291677,22.9474,47.7499,Paulesti,ADM2,DISTRICT
SM291548,25.9699,45.0055,Paulesti,ADM2,DISTRICT
SM266779,-51.7542,-21.1717,Paulicéia,ADM2,DISTRICT
SM262844,-42.5881,-2.8859,Paulino Neves,ADM2,DISTRICT
SM290521,21.6025,46.1251,Paulis,ADM2,DISTRICT
SM264866,-34.8833,-7.9282,Paulista,ADM2,DISTRICT
SM264263,-37.6097,-6.595,Paulista,ADM2,DISTRICT
SM265086,-41.2275,-8.2253,Paulistana,ADM2,DISTRICT
SM263745,-42.866,-18.4339,Paulistas,ADM2,DISTRICT
SM266781,-49.2959,-22.5782,Paulistânia,ADM2,DISTRICT
SM267580,-38.1691,-9.5523,Paulo Afonso,ADM2,DISTRICT
SM9980,-52.3967,-27.7367,Paulo Bento,ADM2:ADM3,DISTRICT
SM264624,-50.7477,-26.0428,Paulo Frontin,ADM2,DISTRICT
SM265407,-36.3929,-9.3684,Paulo Jacinto,ADM2,DISTRICT
SM266240,-48.7406,-27.9468,Paulo Lopez,ADM2,DISTRICT
SM262845,-45.2376,-4.4488,Paulo Ramos,ADM2,DISTRICT
SM266782,-49.4885,-20.0964,Paulo de Faria,ADM2,DISTRICT
SM242292,128.779,-3.254,Paulohi,LANGUAGE,LANGUAGE
SM266780,-47.1409,-22.7392,Paulínia,ADM2,DISTRICT
SM242293,-64.2803,-5.784,Paumari,LANGUAGE,LANGUAGE
SM256814,-67.37,-6.83,Paumari,ETHNICITY,ETHNICITY
SM270803,-74.0262,5.6758,Pauna,ADM2,DISTRICT
SM242296,-61.4542,-16.7708,Paunaka,LANGUAGE,LANGUAGE
SM242296,-61.4542,-16.7708,Paunaka,LANGUAGE,LANGUAGE
SM242296,-63.087,-14.3538,Paunaka,LANGUAGE,LANGUAGE
SM242296,-63.087,-14.3538,Paunaka,LANGUAGE,LANGUAGE
SM292366,27.1034,46.0407,Paunesti,ADM2,DISTRICT
SM242297,74.337,21.228,Pauri Bareli,LANGUAGE,LANGUAGE
SM277352,78.9285,29.9637,Pauri Garhwal (1997-),ADM2,DISTRICT
SM256816,-62.24,-14.93,Pauserna,ETHNICITY,ETHNICITY
SM292218,24.1355,45.0734,Pausesti,ADM2,DISTRICT
SM292219,24.2447,45.1417,Pausesti-maglasi,ADM2,DISTRICT
SM273417,-78.7333,-2.7833,Paute,ADM2,DISTRICT
SM242300,137.8,-1.65,Pauwi,LANGUAGE,LANGUAGE
SM242302,-55.5588,-1.69,Pauxi,LANGUAGE,LANGUAGE
SM242303,-62.5186,1.4517,Pauxiana,LANGUAGE,LANGUAGE
SM17590,-60.9919,14.0028,Pavee,ADM2,DISTRICT
SM262448,25.1333,42.6333,Pavel Banya,ADM2,DISTRICT
SM265816,-51.7234,-29.5956,Paverama,ADM2,DISTRICT
SM277864,9.1333,45.1167,Pavia,ADM2,DISTRICT
SM13731,21.1986,56.8853,Pavilosta,ADM2,DISTRICT
SM3959,21.2239,56.8064,Pavilostas Novads,ADM1,DISTRICT
SM293342,46,59.0833,Pavinskiy rayon,ADM2,DISTRICT
SM262470,25.35,43.2833,Pavlikeni,ADM2,DISTRICT
SM2687,76.25,52.0833,Pavlodar,ADM1:ADMX,DISTRICT
SM13222,77.5,52.4167,Pavlodar Audany (excl. Pavlodar),ADM2,DISTRICT
SM13223,76.95,52.3,Pavlodar Qalasy,ADM2,DISTRICT
SM18263,35.8701,48.5264,Pavlohrad Raion,ADM2,DISTRICT
SM17035,38.6667,55.75,Pavlovo-Posadskiy Rayon,ADM2,DISTRICT
SM17036,43,56,Pavlovskiy Rayon,ADM2,DISTRICT
SM294858,47.0833,52.6667,Pavlovskiy rayon,ADM2,DISTRICT
SM294983,40.1667,50.4167,Pavlovskiy rayon,ADM2,DISTRICT
SM293387,39.7315,46.0898,Pavlovskiy rayon,ADM2,DISTRICT
SM292982,83,53.25,Pavlovskiy rayon,ADM2,DISTRICT
SM265087,-43.1969,-7.8865,Pavussu,ADM2,DISTRICT
SM295311,159.1211,-9.0602,Pavuvu,ADM2,DISTRICT
SM263747,-41.0774,-17.4678,Pavão,ADM2,DISTRICT
SM242306,145.081,-6.8802,Pawaia,LANGUAGE,LANGUAGE
SM256817,145.03,-7.06,Pawaia,ETHNICITY,ETHNICITY
SM242310,-96.8937,36.2862,Pawnee,LANGUAGE,LANGUAGE
SM302226,-96.6993,36.3169,Pawnee,ADM2,DISTRICT
SM301741,-96.2371,40.1315,Pawnee,ADM2,DISTRICT
SM300959,-99.2367,38.1813,Pawnee,ADM2,DISTRICT
SM256819,-99.14,40.09,Pawnee,ETHNICITY,ETHNICITY
SM256819,-99.14,40.09,Pawnee,ETHNICITY,ETHNICITY
SM256819,-100,42,Pawnee,ETHNICITY,ETHNICITY
SM256819,-100,42,Pawnee,ETHNICITY,ETHNICITY
SM303421,65.75,40,Paxtachi,ADM2,DISTRICT
SM303496,68,40.3333,Paxtakor,ADM2,DISTRICT
SM303395,72.5,40.9333,Paxtaobod,ADM2,DISTRICT
SM270804,-72.3747,5.664,Paya,ADM2,DISTRICT
SM242311,-58.1586,-20.2122,Payagua,LANGUAGE,LANGUAGE
SM276644,100.6333,-0.2333,Payakumbuh,ADM2,DISTRICT
SM256821,-76.39,-7.32,Payanso,ETHNICITY,ETHNICITY
SM18427,66.9167,40,Payariq Tumani,ADM2,DISTRICT
SM18096,36.2134,36.7643,Payas,ADM2,DISTRICT
SM300575,-116.7608,44.0067,Payette,ADM2,DISTRICT
SM302228,-96.9758,36.0773,Payne,ADM2,DISTRICT
SM2688,-0.8333,47.5,Pays de la Loire,ADM1,DISTRICT
SM268998,6.885,46.4646,Pays-d'Enhaut,ADM2,DISTRICT
SM2689,-57.25,-32.0833,Paysandú,ADM1,DISTRICT
SM303641,-66.6667,10.3333,Paz Castillo,ADM2,DISTRICT
SM270897,-71.9,5.8833,Paz de Ariporo,ADM2,DISTRICT
SM270805,-72.7886,6.0022,Paz de Río,ADM2,DISTRICT
SM298827,40.884,41.1729,Pazar,ADM2,DISTRICT
SM298957,36.2919,40.287,Pazar,ADM2,DISTRICT
SM298567,36.9317,37.5947,Pazarcık,ADM2,DISTRICT
SM262320,24.3333,42.2,Pazardzhik,ADM2,DISTRICT
SM2690,24.1667,42.0833,Pazardzhik,ADM1,DISTRICT
SM298698,29.1359,39.0051,Pazarlar,ADM2,DISTRICT
SM298198,29.9205,40.0044,Pazaryeri,ADM2,DISTRICT
SM298394,40.7725,40.4138,Pazaryolu,ADM2,DISTRICT
SM276045,13.9333,45.2333,Pazin,ADM2,DISTRICT
SM262838,-44.1105,-2.4802,Paço do Lumiar,ADM2,DISTRICT
SM289317,-8.3758,41.2896,Paços de Ferreira,ADM2,DISTRICT
SM2652,-2.75,43,País Vasco,ADM1,DISTRICT
SM284583,166.3639,-22.1299,Païta,ADM2,DISTRICT
SM2686,-25.0167,17.1167,Paúl,ADM1,DISTRICT
SM305589,15.3857,43.9556,Pašman,ADM2,DISTRICT
SM242324,9.5644,9.2719,Pe,LANGUAGE,LANGUAGE
SM280403,105.239,11.6519,Pea Reang,ADM2,DISTRICT
SM264625,-52.2903,-23.966,Peabiru,ADM2,DISTRICT
SM323802,-117.3149,56.2308,Peace River,ADM2:ADM3,DISTRICT
SM268757,-122.753,56.6665,Peace River,ADM2,DISTRICT
SM300394,-83.8269,32.5688,Peach,ADM2,DISTRICT
SM280404,105.271,11.0105,Peam Chor,ADM2,DISTRICT
SM280405,105.3197,11.3506,Peam Ro,ADM2,DISTRICT
SM242316,105.028,13.593,Pear,LANGUAGE,LANGUAGE
SM256823,103.61,11.5,Pear,ETHNICITY,ETHNICITY
SM12625,-76.3168,17.9116,Pear Tree,ADM2,DISTRICT
SM12626,-76.9529,18.2252,Pear Tree Grove,ADM2,DISTRICT
SM301476,-89.5898,30.7686,Pearl River,ADM2,DISTRICT
SM17591,-60.986,14.0104,Peart'S Gap,ADM2,DISTRICT
SM242320,-72,-3.5,Peba,LANGUAGE,LANGUAGE
SM283883,38.137,-17.2638,Pebane,ADM2,DISTRICT
SM292067,28.1487,45.0085,Peceneaga,ADM2,DISTRICT
SM242321,-85.558,15.8033,Pech,LANGUAGE,LANGUAGE
SM256824,-84.85,14.59,Pech (paya),ETHNICITY,ETHNICITY
SM290457,27.8276,45.643,Pechea,ADM2,DISTRICT
SM242322,33.5227,47.3933,Pecheneg,LANGUAGE,LANGUAGE
SM293734,30,69,Pechengskiy rayon,ADM2,DISTRICT
SM299474,30.566,50.419,Pechers'kyi,ADM2,DISTRICT
SM294121,27.75,57.75,Pechorskiy rayon,ADM2,DISTRICT
SM280371,107.0948,12.7278,Pechr Chenda,ADM2,DISTRICT
SM290522,21.0663,46.1668,Pecica,ADM2,DISTRICT
SM290187,28.5101,43.8868,Pecineaga,ADM2,DISTRICT
SM292006,21.0082,45.6207,Peciu Nou,ADM2,DISTRICT
SM12627,-77.3977,18.1715,Peckham,ADM2,DISTRICT
SM302735,-102.7236,30.7811,Pecos,ADM2,DISTRICT
SM256825,-105.66,35.5,Pecos,ETHNICITY,ETHNICITY
SM286718,-80.1275,7.4853,Pedasí,ADM2,DISTRICT
SM11680,79.3825,18.6163,Peddapalli,ADM2,DISTRICT
SM13732,27.4436,57.4816,Pededzes pagasts,ADM2,DISTRICT
SM303890,-62.2541,9.9698,Pedernales,ADM2,DISTRICT
SM273496,-80.0536,0.0701,Pedernales,ADM2,DISTRICT
SM2693,-71.5667,18,Pedernales,ADM1,DISTRICT
SM11116,-71.6,18.0833,Pedernales (excl. Oviedo),ADM2,DISTRICT
SM16009,-67.181,18.0506,Pedernales Barrio,ADM2,DISTRICT
SM266783,-48.8656,-22.3088,Pederneiras,ADM2,DISTRICT
SM242323,29.4718,-24.7993,Pedi,LANGUAGE,LANGUAGE
SM250630,29.38,-26.43,Pedi,ETHNICITY,ETHNICITY
SM250630,29.38,-26.43,Pedi,ETHNICITY,ETHNICITY
SM250630,30,-25,Pedi,ETHNICITY,ETHNICITY
SM250630,30,-25,Pedi,ETHNICITY,ETHNICITY
SM10979,32.8321,34.9637,Pedoulas,ADM2,DISTRICT
SM264868,-36.8786,-8.6652,Pedra,ADM2,DISTRICT
SM263749,-41.2215,-15.964,Pedra Azul,ADM2,DISTRICT
SM266784,-46.4371,-22.764,Pedra Bela,ADM2,DISTRICT
SM263750,-42.3776,-20.4667,Pedra Bonita,ADM2,DISTRICT
SM264264,-38.08,-7.4607,Pedra Branca,ADM2,DISTRICT
SM267821,-39.8526,-5.4934,Pedra Branca,ADM2,DISTRICT
SM267289,-52.4012,1.1484,Pedra Branca do Amaparí,ADM2,DISTRICT
SM265442,-35.8509,-5.0938,Pedra Grande,ADM2,DISTRICT
SM264265,-36.442,-6.7802,Pedra Lavadra,ADM2,DISTRICT
SM267119,-37.685,-10.6649,Pedra Mole,ADM2,DISTRICT
SM265443,-36.0748,-5.5279,Pedra Preta,ADM2,DISTRICT
SM263207,-54.1634,-16.7638,Pedra Preta,ADM2,DISTRICT
SM263751,-42.7125,-20.598,Pedra do Anta,ADM2,DISTRICT
SM263752,-45.2186,-20.282,Pedra do Indaiá,ADM2,DISTRICT
SM263753,-42.1515,-20.8264,Pedra dourada,ADM2,DISTRICT
SM263754,-45.4532,-22.2507,Pedralva,ADM2,DISTRICT
SM266785,-50.0899,-20.1999,Pedranópolis,ADM2,DISTRICT
SM9981,-53.6327,-31.8328,Pedras Altas,ADM2,DISTRICT
SM266241,-49.2203,-28.4827,Pedras Grandes,ADM2,DISTRICT
SM264266,-35.0707,-7.3758,Pedras de Fogo,ADM2,DISTRICT
SM263755,-44.3084,-15.6335,Pedras de Maria da Cruz,ADM2,DISTRICT
SM303842,-70.5698,8.3532,Pedraza,ADM2,DISTRICT
SM10436,-74.8363,10.1494,Pedraza (2000-),ADM2,DISTRICT
SM266786,-47.4433,-20.1889,Pedregulho,ADM2,DISTRICT
SM266787,-46.8909,-22.7508,Pedreira,ADM2,DISTRICT
SM262846,-44.5992,-4.5827,Pedreiras,ADM2,DISTRICT
SM267121,-37.6566,-11.2167,Pedrinhas,ADM2,DISTRICT
SM266789,-50.8206,-22.8073,Pedrinhas Paulista,ADM2,DISTRICT
SM263756,-47.5021,-19.1906,Pedrinópolis,ADM2,DISTRICT
SM267282,-47.9781,-9.2062,Pedro Afonso,ADM2,DISTRICT
SM267583,-37.9149,-9.9769,Pedro Alexandre,ADM2,DISTRICT
SM281623,-99.8635,18.5473,Pedro Ascencio Alquisiras,ADM2,DISTRICT
SM265444,-36.3459,-5.4402,Pedro Avelino,ADM2,DISTRICT
SM16010,-66.1952,18.0979,Pedro Avila Barrio,ADM2,DISTRICT
SM271002,-81.3182,22.6926,Pedro Betancourt,ADM2,DISTRICT
SM271884,-70.0908,18.6197,Pedro Brand,ADM2,DISTRICT
SM303820,-67.9167,6.5,Pedro Camejo,ADM2,DISTRICT
SM268015,-40.0316,-18.2036,Pedro Canário,ADM2,DISTRICT
SM273441,-80.2879,-1.8538,Pedro Carbo,ADM2,DISTRICT
SM262725,-68.0525,-16.3652,Pedro Domingo Murillo,ADM2,DISTRICT
SM282449,-100.1546,20.4954,Pedro Escobedo,ADM2,DISTRICT
SM16011,-66.4046,18.1452,Pedro Garcia Barrio,ADM2,DISTRICT
SM263074,-54.1294,-17.8388,Pedro Gomes,ADM2,DISTRICT
SM303642,-65.7009,10.161,Pedro Gual,ADM2,DISTRICT
SM15360,-55.9834,-22.7929,Pedro Juan Caballero (2011-),ADM2,DISTRICT
SM265088,-42.2354,-8.1177,Pedro Laurentino,ADM2,DISTRICT
SM263758,-44.0394,-19.6447,Pedro Leopoldo,ADM2,DISTRICT
SM265089,-41.3996,-4.4903,Pedro Li,ADM2,DISTRICT
SM303743,-72.4,7.9333,Pedro María Ureña,ADM2,DISTRICT
SM273541,-78.2731,0.0224,Pedro Moncayo,ADM2,DISTRICT
SM265818,-52.8942,-31.9576,Pedro Osório,ADM2,DISTRICT
SM12628,-77.7134,17.8857,Pedro Plains,ADM2,DISTRICT
SM264268,-35.3103,-6.6705,Pedro Régis,ADM2,DISTRICT
SM271782,-71.55,19.1667,Pedro Santana,ADM2,DISTRICT
SM263759,-43.7205,-21.728,Pedro Teixeira,ADM2,DISTRICT
SM265445,-35.248,-6.4531,Pedro Velho,ADM2,DISTRICT
SM273542,-79,0.1667,Pedro Vicente Maldonado,ADM2,DISTRICT
SM266790,-47.1724,-24.1702,Pedro de Toledo,ADM2,DISTRICT
SM262847,-45.4525,-2.9739,Pedro do Rosário,ADM2,DISTRICT
SM267582,-38.6062,-12.1331,Pedrão,ADM2,DISTRICT
SM289246,-8.2009,39.9268,Pedrógão Grande,ADM2,DISTRICT
SM256828,-79.89,33.67,Pee Dee,ETHNICITY,ETHNICITY
SM268874,-79.7829,43.7501,Peel,ADM2,DISTRICT
SM276791,-4.6879,54.2218,Peel,ADM1:ADM2,DISTRICT
SM242327,12.2518,7.8219,Peere,LANGUAGE,LANGUAGE
SM10980,32.3643,34.8783,Pegeia,ADM2,DISTRICT
SM283496,96.5506,17.3192,Pegu,ADM2,DISTRICT
SM276537,140.2954,-4.5217,Pegunungan Bintang,ADM2,DISTRICT
SM298649,26.9345,41.3592,Pehlivanköy,ADM2,DISTRICT
SM260811,-61.75,-35.9167,Pehuajó,ADM2,DISTRICT
SM256830,-70.5,-40.3,Pehuenche,ETHNICITY,ETHNICITY
SM260919,-69.3333,-37.5,Pehuenches,ADM2,DISTRICT
SM2694,22.9167,41.7833,Pehčevo,ADM1,DISTRICT
SM242332,142.247,-4.4397,Pei,LANGUAGE,LANGUAGE
SM11184,27.1844,58.5156,Peipsiaeaere vald,ADM2,DISTRICT
SM267283,-48.5328,-11.9961,Peixe,ADM2,DISTRICT
SM264214,-47.2726,-1.1017,Peixe Boi,ADM2,DISTRICT
SM263208,-53.5864,-10.1472,Peixoto de Azevedo,ADM2,DISTRICT
SM265819,-53.6104,-28.4544,Pejuçara,ADM2,DISTRICT
SM280833,103.2739,19.5137,Pek,ADM2,DISTRICT
SM242333,101.691,-3.0225,Pekal,LANGUAGE,LANGUAGE
SM276317,109.624,-7.0319,Pekalongan,ADM2,DISTRICT
SM276563,101.4667,0.5333,Pekanbaru,ADM2,DISTRICT
SM256831,-94,52,Pekangekum,ETHNICITY,ETHNICITY
SM256831,-94,52,Pekangekum,ETHNICITY,ETHNICITY
SM256831,-93.85,51.84,Pekangekum,ETHNICITY,ETHNICITY
SM256831,-93.85,51.84,Pekangekum,ETHNICITY,ETHNICITY
SM285835,6.9651,53.0597,Pekela,ADM2,DISTRICT
SM242337,97.9183,24.2142,Pela,LANGUAGE,LANGUAGE
SM313210,18.5381,44.8789,Pelagićevo,ADM2:ADM3,DISTRICT
SM276564,102.1861,0.2082,Pelalawan,ADM2,DISTRICT
SM10981,32.4745,35.0306,Pelathousa,ADM2,DISTRICT
SM295439,159.4037,-5.0956,Pelau,ADM2,DISTRICT
SM269897,-73.6109,8.7707,Pelaya,ADM2,DISTRICT
SM13733,22.0005,56.9059,Pelcu pagasts,ADM2,DISTRICT
SM242342,151.05,-5.5805,Pele-Ata,LANGUAGE,LANGUAGE
SM13734,26.7519,56.147,Pelecu pagasts,ADM2,DISTRICT
SM2695,134.231,6.993,Peleliu,ADM1,DISTRICT
SM242341,17.3142,-4.9088,Pelende,DIALECT,LANGUAGE
SM10982,32.952,34.894,Pelendri,ADM2,DISTRICT
SM271218,15.1667,49.4167,Pelhřimov,ADM2,DISTRICT
SM324049,-110.8925,54.2613,Pelican Narrows,ADM2:ADM3,DISTRICT
SM12629,-78.219,18.3789,Pell River,ADM2,DISTRICT
SM261046,-64.25,-26.1667,Pellegrini,ADM2,DISTRICT
SM260812,-63.1185,-36.2628,Pellegrini,ADM2,DISTRICT
SM16012,-66.7082,18.2084,Pellejas Barrio (Adjuntas),ADM2,DISTRICT
SM16013,-66.4441,18.2192,Pellejas Barrio (Orocovis),ADM2,DISTRICT
SM275155,22.4396,37.6273,Peloponnese,ADM1:ADM2,DISTRICT
SM9982,-52.3412,-31.5812,Pelotas (1996-),ADM2,DISTRICT
SM17592,-60.9177,13.8783,Pelouze,ADM2,DISTRICT
SM2697,91.3667,27,Pemagatshel,ADM1,DISTRICT
SM276318,109.4,-7.0333,Pemalang,ADM2,DISTRICT
SM276691,99.05,2.9667,Pematangsiantar,ADM2,DISTRICT
SM18164,27.3868,-16.6727,Pemba District,ADM2,DISTRICT
SM2698,39.7667,-5.05,Pemba North,ADM1,DISTRICT
SM2699,39.75,-5.3333,Pemba South,ADM1,DISTRICT
SM302057,-97.5518,48.7675,Pembina,ADM2,DISTRICT
SM327773,-98.4869,49.1672,Pembina,ADM2:ADM3,DISTRICT
SM2700,-64.7928,32.2994,Pembroke,ADM1,DISTRICT
SM283434,14.4764,35.9306,Pembroke,ADM1:ADM2,DISTRICT
SM12630,-76.83,18.0311,Pembroke Hall,ADM2,DISTRICT
SM274752,-4.9167,51.8333,Pembrokeshire,ADM1:ADM2,DISTRICT
SM301598,-89.7854,36.2115,Pemiscot,ADM2,DISTRICT
SM242352,-61.4033,4.9703,Pemon,LANGUAGE,LANGUAGE
SM454382,-61.7,5.6585,Pemon,ETHNICITY,ETHNICITY
SM16014,-65.8163,18.2218,Pena Pobre Barrio,ADM2,DISTRICT
SM289474,-8.2478,40.3,Penacova,ADM2,DISTRICT
SM289302,-8.27,41.2093,Penafiel,ADM2,DISTRICT
SM267822,-39.047,-7.8005,Penaforte,ADM2,DISTRICT
SM276420,116.5717,-1.2033,Penajam Paser Utara,ADM2,DISTRICT
SM2701,-61.4652,10.1678,Penal-Debe (1992-),ADM1,DISTRICT
SM262849,-45.1307,-3.2323,Penalva,ADM2,DISTRICT
SM289397,-7.6602,40.6679,Penalva do Castelo,ADM2,DISTRICT
SM2702,167.8045,-15.3974,Penama,ADM1,DISTRICT
SM289467,-7.1715,40.155,Penamacor,ADM2,DISTRICT
SM256833,114,3,Penan,ETHNICITY,ETHNICITY
SM242353,100.254,5.375,Penang Sign Language,LANGUAGE,LANGUAGE
SM242363,-4.03,14.38,Penange,LANGUAGE,LANGUAGE
SM242365,147.81,-2.2989,Penchal,LANGUAGE,LANGUAGE
SM303087,-117.274,48.5323,Pend Oreille,ADM2,DISTRICT
SM256834,-113.73,48.88,Pend d'Oreilles,ETHNICITY,ETHNICITY
SM284410,100.6073,5.9846,Pendang,ADM2,DISTRICT
SM242367,119.862,-0.114,Pendau,LANGUAGE,LANGUAGE
SM250632,20,-6,Pende,ETHNICITY,ETHNICITY
SM301999,-77.8889,34.5149,Pender,ADM2,DISTRICT
SM298512,29.3302,40.9458,Pendik,ADM2,DISTRICT
SM303137,-79.3509,38.6807,Pendleton,ADM2,DISTRICT
SM301104,-84.3603,38.6956,Pendleton,ADM2,DISTRICT
SM17950,67.8308,39.3228,Pendzhikentskiy Rayon,ADM2,DISTRICT
SM265446,-36.6361,-5.2331,Pendências,ADM2,DISTRICT
SM265408,-36.4699,-10.2445,Penedo,ADM2,DISTRICT
SM289398,-7.394,40.993,Penedono,ADM2,DISTRICT
SM289475,-8.3819,40.0122,Penela,ADM2,DISTRICT
SM299059,119.6114,23.5711,Penghu,ADM2,DISTRICT
SM268303,114.8476,4.8463,Pengkalan Batu,ADM2,DISTRICT
SM242370,83.6552,19.3435,Pengo,LANGUAGE,LANGUAGE
SM266242,-48.6271,-26.8023,Penha,ADM2,DISTRICT
SM323968,-113.8725,52.1361,Penhold,ADM2:ADM3,DISTRICT
SM289247,-9.3687,39.3617,Peniche,ADM2,DISTRICT
SM303670,-64.2833,11.0167,Peninsula de Macanao,ADM2,DISTRICT
SM273606,-78.4481,-1.5623,Penipe,ADM2,DISTRICT
SM282067,-101.9126,20.1341,Penjamillo,ADM2,DISTRICT
SM305847,45.9495,35.6237,Penjwin,ADM2,DISTRICT
SM305847,45.9495,35.6237,Penjwin,ADM2,DISTRICT
SM305847,45.9835,35.6922,Penjwin,ADM2,DISTRICT
SM305847,45.9835,35.6922,Penjwin,ADM2,DISTRICT
SM13735,23.2059,56.4882,Penkules pagasts,ADM2,DISTRICT
SM256838,-71.64,43.46,Pennacook,ETHNICITY,ETHNICITY
SM12631,-77.3131,18.1103,Pennants,ADM2,DISTRICT
SM302524,-102.8238,44.0037,Pennington,ADM2,DISTRICT
SM301391,-96.0367,48.0662,Pennington,ADM2,DISTRICT
SM2703,-77.7,40.8,Pennsylvania,ADM1,DISTRICT
SM242374,-76.3211,40.0305,Pennsylvania German,LANGUAGE,LANGUAGE
SM301199,-68.6494,45.4005,Penobscot,ADM2,DISTRICT
SM256839,-68.93,44.87,Penobscot,ETHNICITY,ETHNICITY
SM256839,-68.93,44.87,Penobscot,ETHNICITY,ETHNICITY
SM256839,-68,45,Penobscot,ETHNICITY,ETHNICITY
SM256839,-68,45,Penobscot,ETHNICITY,ETHNICITY
SM286695,-80.2833,8.63,Penonomé,ADM2,DISTRICT
SM294769,32.75,56.9167,Penovskiy rayon,ADM2,DISTRICT
SM4240,-157.9656,-8.9948,Penrhyn,ADM1,DISTRICT
SM261448,150.7,-33.75,Penrith,ADM2,DISTRICT
SM256840,-86.3,30.85,Pensacola,ETHNICITY,ETHNICITY
SM270847,-75.2042,5.3884,Pensilvania,ADM2,DISTRICT
SM10983,32.8858,35.1509,Pentageia,ADM2,DISTRICT
SM10984,33.2503,34.7315,Pentakomo,ADM2,DISTRICT
SM10985,32.6202,34.8537,Pentalia,ADM2,DISTRICT
SM454381,168.2,-15.7,Pentecost,ETHNICITY,ETHNICITY
SM267823,-39.1985,-3.8512,Pentecoste,ADM2,DISTRICT
SM242380,-124.689,49.1936,Pentlatch,LANGUAGE,LANGUAGE
SM256843,-124.94,49.49,Pentlatch,ETHNICITY,ETHNICITY
SM256843,-124.94,49.49,Pentlatch,ETHNICITY,ETHNICITY
SM256843,-125,49.5,Pentlatch,ETHNICITY,ETHNICITY
SM256843,-125,49.5,Pentlatch,ETHNICITY,ETHNICITY
SM16015,-66.7226,18.0571,Penuelas Barrio-Pueblo,ADM2,DISTRICT
SM12632,-76.8309,18.0195,Penwood,ADM2,DISTRICT
SM2705,44.5,53,Penza,ADM1,DISTRICT
SM293103,167,63,Penzhinskiy rayon,ADM2,DISTRICT
SM266791,-50.1099,-21.3911,Penápolis,ADM2,DISTRICT
SM454465,77.5,26.5,People of Uttar Pradesh,ETHNICITY,ETHNICITY
SM300662,-89.76,40.7881,Peoria,ADM2,DISTRICT
SM256844,144.12,-7.62,Pepeha,ETHNICITY,ETHNICITY
SM271800,-71.6667,19.6667,Pepillo Salcedo,ADM2,DISTRICT
SM303264,-92.1497,44.4827,Pepin,ADM2,DISTRICT
SM12633,-77.6128,18.0211,Pepper,ADM2,DISTRICT
SM261435,115.767,-31.9989,Peppermint Grove,ADM2,DISTRICT
SM260486,19.8065,41.0711,Peqinit,ADM2,DISTRICT
SM260486,19.8065,41.0711,Peqinit,ADM2,DISTRICT
SM260486,19.8,41.0667,Peqinit,ADM2,DISTRICT
SM260486,19.8,41.0667,Peqinit,ADM2,DISTRICT
SM263760,-43.1401,-21.8162,Pequeri,ADM2,DISTRICT
SM263761,-44.6336,-19.6104,Pequi,ADM2,DISTRICT
SM267284,-48.9415,-8.4098,Pequizeiro,ADM2,DISTRICT
SM256845,-72,41.8,Pequot,ETHNICITY,ETHNICITY
SM242384,-72,41.55,Pequot-Mohegan,DIALECT,LANGUAGE
SM270246,-75.8921,7.0407,Pequé,ADM2,DISTRICT
SM10986,33.2742,35.0319,Pera,ADM2,DISTRICT
SM10987,33.3693,35.021,Pera Chorio,ADM2,DISTRICT
SM10988,32.8707,34.8647,Pera Pedi,ADM2,DISTRICT
SM242389,126.576,-7.6235,Perai,LANGUAGE,LANGUAGE
SM2706,101,5,Perak,ADM1,DISTRICT
SM271765,-70.8,18.6167,Peralta,ADM2,DISTRICT
SM459066,138.8922,-34.8626,Peramangk,ETHNICITY,ETHNICITY
SM277224,78.8738,11.272,Perambalur (2007-),ADM2,DISTRICT
SM10050,114.9222,4.8721,Peramu,ADM2,DISTRICT
SM242388,112.619,-7.982,Peranakan Indonesian,LANGUAGE,LANGUAGE
SM2707,-70.3667,18.3333,Peravia (2000-),ADM1,DISTRICT
SM16016,-66.9272,18.289,Perchas 1 Barrio,ADM2,DISTRICT
SM16017,-66.9284,18.2722,Perchas 2 Barrio,ADM2,DISTRICT
SM16018,-66.4039,18.2777,Perchas Barrio,ADM2,DISTRICT
SM263762,-45.0807,-19.9214,Perdigão,ADM2,DISTRICT
SM263763,-47.1599,-19.3652,Perdizes,ADM2,DISTRICT
SM263764,-45.0671,-21.0709,Perdões,ADM2,DISTRICT
SM299765,22.4773,48.7352,Perechyns'kyi,ADM2,DISTRICT
SM290523,20.9061,46.2338,Peregu Mare,ADM2,DISTRICT
SM299488,31.4495,50.0745,Pereiaslav-Khmel'nyts'ka,ADM2,DISTRICT
SM299490,31.5,50.0833,Pereiaslav-Khmel'nyts'kyi,ADM2,DISTRICT
SM270426,-75.7222,4.769,Pereira,ADM2,DISTRICT
SM266792,-51.0871,-20.6478,Pereira Barreto,ADM2,DISTRICT
SM266793,-47.9761,-23.1215,Pereiras,ADM2,DISTRICT
SM267824,-38.4889,-6.0409,Pereiro,ADM2,DISTRICT
SM293080,36.0833,54.25,Peremyshl'skiy rayon,ADM2,DISTRICT
SM277103,93.7372,25.5128,Peren,ADM2,DISTRICT
SM261298,116.5354,-29.3978,Perenjori,ADM2,DISTRICT
SM295049,38.8333,56.75,Pereslavskiy rayon,ADM2,DISTRICT
SM291909,25.0929,44.0437,Peretu,ADM2,DISTRICT
SM293777,44.5458,55.5919,Perevozskiy rayon,ADM2,DISTRICT
SM261845,14.718,48.2572,Perg,ADM2,DISTRICT
SM260814,-60.5833,-33.75,Pergamino,ADM2,DISTRICT
SM10989,33.6953,35.0447,Pergamos,ADM2,DISTRICT
SM262850,-44.9338,-2.5423,Peri-Mirim,ADM2,DISTRICT
SM282068,-102.4277,19.5044,Peribán,ADM2,DISTRICT
SM291624,22.8764,47.2464,Pericei,ADM2,DISTRICT
SM271003,-80.9962,22.7885,Perico,ADM2,DISTRICT
SM256846,-109.69,23.31,Pericu,ETHNICITY,ETHNICITY
SM242395,-109.6148,23.3167,Pericú,LANGUAGE,LANGUAGE
SM290925,27.2603,44.5719,Perieti,ADM2,DISTRICT
SM291442,24.5561,44.4047,Perieti (pre-2002),ADM2,DISTRICT
SM235866,123.442,-8.5323,Peripheral Lembata,LANGUAGE,LANGUAGE
SM242396,110.157,42.4208,Peripheral Mongolian,LANGUAGE,LANGUAGE
SM263765,-42.2161,-19.047,Periquito,ADM2,DISTRICT
SM290971,26.0147,44.7086,Peris,ADM2,DISTRICT
SM290390,23.4921,44.1584,Perisor,ADM2,DISTRICT
SM289966,27.502,44.4327,Perisoru,ADM2,DISTRICT
SM10990,33.7571,35.203,Peristerona (Famagusta),ADM2,DISTRICT
SM18508,33.0761,35.1305,Peristerona (Nicosia),ADM2,DISTRICT
SM10991,32.8625,35.1398,Peristeronari,ADM2,DISTRICT
SM266243,-51.8869,-27.3485,Peritiba,ADM2,DISTRICT
SM262851,-44.2818,-4.4405,Peritoró,ADM2,DISTRICT
SM10992,33.9125,35.2831,Perivolia,ADM2,DISTRICT
SM302525,-102.4756,45.4905,Perkins,ADM2,DISTRICT
SM301742,-101.6496,40.8509,Perkins,ADM2,DISTRICT
SM2708,100.25,6.5,Perlis,ADM1,DISTRICT
SM370376,-8.176,5.0817,Perluken,ADM2:ADM3,DISTRICT
SM2709,57,59,Perm' (2005-),ADM1,DISTRICT
SM2710,-37.75,-8.3333,Pernambuco,ADM1,DISTRICT
SM262326,23.0833,42.6167,Pernik,ADM2,DISTRICT
SM2711,22.8833,42.6333,Pernik,ADM1,DISTRICT
SM242409,11.044,9.6209,Pero,LANGUAGE,LANGUAGE
SM264626,-53.3238,-23.9702,Perobal,ADM2,DISTRICT
SM268128,-52.0642,-17.5286,Perolândia,ADM2,DISTRICT
SM282761,-97.2583,19.5428,Perote,ADM2,DISTRICT
SM17593,-61.0073,13.9596,Perou,ADM2,DISTRICT
SM302000,-76.4077,36.1772,Perquimans,ADM2,DISTRICT
SM295725,-88.1364,13.9708,Perquín,ADM2,DISTRICT
SM302330,-77.2623,40.3984,Perry,ADM2,DISTRICT
SM302608,-87.859,35.6426,Perry,ADM2,DISTRICT
SM302144,-82.2361,39.7372,Perry,ADM2,DISTRICT
SM301599,-89.8244,37.7071,Perry,ADM2,DISTRICT
SM301477,-88.9923,31.172,Perry,ADM2,DISTRICT
SM300750,-86.638,38.0797,Perry,ADM2,DISTRICT
SM301105,-83.2215,37.2443,Perry,ADM2,DISTRICT
SM300663,-89.367,38.0838,Perry,ADM2,DISTRICT
SM302400,-92.9315,34.9474,Perry,ADM2,DISTRICT
SM300294,-87.2944,32.6385,Perry,ADM2,DISTRICT
SM301776,-118.4044,40.4404,Pershing,ADM2,DISTRICT
SM299954,37.25,47.0833,Pershotravnevyi,ADM2,DISTRICT
SM302001,-78.9717,36.3901,Person,ADM2,DISTRICT
SM296443,13.3768,56.1886,Perstorp,ADM2,DISTRICT
SM298984,39.3273,38.865,Pertek,ADM2,DISTRICT
SM268875,-81.083,43.5001,Perth,ADM2,DISTRICT
SM261299,115.8538,-31.9498,Perth,ADM2,DISTRICT
SM12634,-77.6258,18.4311,Perth Town,ADM2,DISTRICT
SM274729,-3.75,56.5,Perthshire and Kinross,ADM1:ADM2,DISTRICT
SM277919,12.55,43.05,Perugia,ADM2,DISTRICT
SM262342,24.55,42.0667,Perushtitsa,ADM2,DISTRICT
SM236582,-73.6507,-4.7456,Peruvian Amazonian Spanish,LANGUAGE,LANGUAGE
SM242412,-76.8213,-11.9008,Peruvian Sign Language,LANGUAGE,LANGUAGE
SM266794,-47.0128,-24.2789,Peruíbe,ADM2,DISTRICT
SM305757,15.3831,44.6487,Perušić,ADM2,DISTRICT
SM298880,42.5711,37.8722,Pervari,ADM2,DISTRICT
SM299886,33.8325,45.5983,Pervomais'kyi,ADM2,DISTRICT
SM18264,36.224,49.3763,Pervomaiska Miskrada,ADM2,DISTRICT
SM18265,36.2704,49.3545,Pervomaiskyi Raion,ADM2,DISTRICT
SM18266,30.851,48.0515,Pervomaysk Raion,ADM2,DISTRICT
SM17037,84.0833,53.4167,Pervomayskiy Rayon,ADM2,DISTRICT
SM13367,74.5821,42.887,Pervomayskiy Rayon,ADM2,DISTRICT
SM11335,44.75,41.7,Pervomayskiy Rayon,ADM2,DISTRICT
SM295050,39.75,58.6667,Pervomayskiy rayon,ADM2,DISTRICT
SM293778,43.35,56.6049,Pervomayskiy rayon,ADM2,DISTRICT
SM298806,37.7638,41.0607,Perşembe,ADM2,DISTRICT
SM277879,12.6333,43.6667,Pesaro E Urbino,ADM2,DISTRICT
SM16019,-66.4933,18.3019,Pesas Barrio,ADM2,DISTRICT
SM276452,105.179,-5.4298,Pesawaran,ADM2,DISTRICT
SM270807,-73.0879,5.5021,Pesca,ADM2,DISTRICT
SM263766,-41.5516,-18.3212,Pescador,ADM2,DISTRICT
SM277861,13.95,42.3333,Pescara,ADM2,DISTRICT
SM9983,-48.8851,-28.4013,Pescaria Brava,ADM2:ADM3,DISTRICT
SM292221,24.128,44.8817,Pesceana,ADM2,DISTRICT
SM375435,71.5578,33.9851,Peshawar,ADM2:ADM3,DISTRICT
SM15311,71.746,33.741,Peshawar Tribal Area,ADM2,DISTRICT
SM303467,63.8333,40.4167,Peshku,ADM2,DISTRICT
SM262321,24.3,42.0333,Peshtera,ADM2,DISTRICT
SM276645,100.85,-1.5833,Pesisir Selatan,ADM2,DISTRICT
SM296336,15.7128,46.6258,Pesnica,ADM1:ADM2,DISTRICT
SM289374,-7.8367,41.1916,Peso da Régua,ADM2,DISTRICT
SM275815,-87.3157,13.5657,Pespire,ADM2,DISTRICT
SM264869,-36.7568,-8.4126,Pesqueira,ADM2,DISTRICT
SM282186,-100.0221,25.7529,Pesquería,ADM2,DISTRICT
SM256857,-68.36,49.23,Pessamit,ETHNICITY,ETHNICITY
SM2712,19.3333,47.4167,Pest,ADM1,DISTRICT
SM290188,28.1072,44.1895,Pestera,ADM2,DISTRICT
SM290644,23.049,45.0706,Pestisani,ADM2,DISTRICT
SM290774,22.8355,45.8018,Pestisu Mic,ADM2,DISTRICT
SM293824,35.6667,58.6667,Pestovskiy rayon,ADM2,DISTRICT
SM294630,49.75,55.75,Pestrechinskiy rayon,ADM2,DISTRICT
SM292932,42.6667,56.5833,Pestyakovskiy rayon,ADM2,DISTRICT
SM286711,-80.6326,7.8819,Pesé,ADM2,DISTRICT
SM284389,101.5833,3.0833,Petaling,ADM2,DISTRICT
SM256858,142.24,-6.61,Petamini,ETHNICITY,ETHNICITY
SM275472,-90.5555,14.5079,Petapa,ADM2,DISTRICT
SM242419,-95.1118,16.8023,Petapa Zapotec,LANGUAGE,LANGUAGE
SM11526,-91.7271,15.6235,Petatan,ADM2,DISTRICT
SM281625,-101.1702,17.6082,Petatlán,ADM2,DISTRICT
SM242417,154.593,-5.3407,Petats,LANGUAGE,LANGUAGE
SM18165,31.405,-14.1264,Petauke District (2012-2018),ADM2,DISTRICT
SM291176,24.7515,46.7196,Petelea,ADM2,DISTRICT
SM305712,16.8886,46.1932,Peteranec,ADM2,DISTRICT
SM274670,-0.25,52.5833,Peterborough,ADM1:ADM2,DISTRICT
SM268877,-78.2495,44.5501,Peterborough,ADM2,DISTRICT
SM261721,139.0261,-32.8479,Peterborough,ADM2,DISTRICT
SM303064,-77.4019,37.2279,Petersburg,ADM2,DISTRICT
SM18315,-133.1931,56.7653,Petersburg Borough (pre-2013),ADM2,DISTRICT
SM12635,-78.0576,18.2659,Petersfield,ADM2,DISTRICT
SM12636,-77.9505,18.137,Petersville,ADM2,DISTRICT
SM303571,-69.4167,11.05,Petit,ADM2,DISTRICT
SM256860,-65.36,55.42,Petitsikapau,ETHNICITY,ETHNICITY
SM242422,106.8,-6.1659,Petjo,LANGUAGE,LANGUAGE
SM242423,-98.491,17.376,Petlacalancingo Mixtec,DIALECT,LANGUAGE
SM282248,-97.9214,18.0686,Petlalcingo,ADM2,DISTRICT
SM305378,18.5281,45.7574,Petlovac,ADM2,DISTRICT
SM4111,20.014,42.947,Petnjica,ADM1,DISTRICT
SM282992,-88.8443,20.0742,Peto,ADM2,DISTRICT
SM275772,-88.24,15.26,Petoa,ADM2,DISTRICT
SM269091,-71,-32.3333,Petorca,ADM2,DISTRICT
SM10993,32.9115,35.108,Petra,ADM2,DISTRICT
SM10994,33.5523,35.2371,Petra tou Digeni,ADM2,DISTRICT
SM290972,26.3111,44.5795,Petrachioaia,ADM2,DISTRICT
SM291678,22.3597,47.5899,Petresti,ADM2,DISTRICT
SM290293,25.3146,44.6676,Petresti,ADM2,DISTRICT
SM290121,23.6219,46.5816,Petrestii De Jos,ADM2,DISTRICT
SM291350,26.4411,47.153,Petricani,ADM2,DISTRICT
SM262300,23.1667,41.45,Petrich,ADM2,DISTRICT
SM305507,16.2288,46.3454,Petrijanec,ADM2,DISTRICT
SM305379,18.5379,45.6134,Petrijevci,ADM2,DISTRICT
SM290775,23.437,45.4535,Petrila,ADM2,DISTRICT
SM275988,16.2667,45.4333,Petrinja,ADM2,DISTRICT
SM290524,22.3952,46.0677,Petris,ADM2,DISTRICT
SM299515,33.2646,48.3365,Petrivs'kyi,ADM2,DISTRICT
SM17038,29.8447,59.8869,Petrodvortsovyy Rayon,ADM2,DISTRICT
SM10995,33.5065,35.0368,Petrofani,ADM2,DISTRICT
SM17039,30.3,59.9667,Petrogradskiy Rayon,ADM2,DISTRICT
SM301650,-108.2501,47.1175,Petroleum,ADM2,DISTRICT
SM264871,-40.5703,-9.0662,Petrolina,ADM2,DISTRICT
SM268129,-49.2875,-16.1112,Petrolina de Goiás,ADM2,DISTRICT
SM266244,-49.6727,-27.5374,Petrolândia,ADM2,DISTRICT
SM264870,-38.3035,-8.8427,Petrolândia,ADM2,DISTRICT
SM9984,-42.0136,-8.112,Petronio Portela,ADM2,DISTRICT
SM299912,36.4281,48.4631,Petropavlivs'kyi,ADM2,DISTRICT
SM294984,40.8333,50.0833,Petropavlovskiy rayon,ADM2,DISTRICT
SM292984,84.25,52.1667,Petropavlovskiy rayon,ADM2,DISTRICT
SM290776,23.3569,45.4196,Petrosani,ADM2,DISTRICT
SM291017,24.216,47.83,Petrova,ADM2,DISTRICT
SM313186,16.5644,44.4836,Petrovac,ADM2:ADM3,DISTRICT
SM2714,21.7167,41.9167,Petrovec,ADM1,DISTRICT
SM313211,18.2842,44.6317,Petrovo,ADM2:ADM3,DISTRICT
SM295095,109,51,Petrovsk-Zabaykal'skiy rayon,ADM2,DISTRICT
SM305737,15.8421,46.1678,Petrovsko,ADM2,DISTRICT
SM265295,-43.1591,-22.3822,Petrópolis,ADM2,DISTRICT
SM301601,-93.2851,38.7283,Pettis,ADM2,DISTRICT
SM370246,-8.8333,4.9997,Petu,ADM2:ADM3,DISTRICT
SM293474,68,55.0833,Petukhovskiy rayon,ADM2,DISTRICT
SM294882,40,55.9167,Petushinskiy rayon,ADM2,DISTRICT
SM2713,-90,16.8333,Petén,ADM1:ADMX,DISTRICT
SM242429,14.441,9.1611,Peve,LANGUAGE,LANGUAGE
SM254487,14.5,9,Peve,ETHNICITY,ETHNICITY
SM296132,17.268,48.2879,Pezinok,ADM2,DISTRICT
SM16020,-66.8938,18.2447,Pezuela Barrio,ADM2,DISTRICT
SM263748,-42.4939,-18.5454,Peçanha,ADM2,DISTRICT
SM303782,-69.1261,10.08,Peña,ADM2,DISTRICT
SM282450,-99.8363,21.0957,Peñamiller,ADM2,DISTRICT
SM270245,-75.2257,6.2343,Peñol,ADM2,DISTRICT
SM242377,-97.0101,17.0671,Peñoles Mixtec,LANGUAGE,LANGUAGE
SM2704,-66.7177,18.0702,Peñuelas,ADM1,DISTRICT
SM281509,-104.0203,24.757,Peñón Blanco,ADM2,DISTRICT
SM304747,20.29,42.6597,Peć,ADM2,DISTRICT
SM2692,20.3007,42.6632,Pećki (2000-),ADM1,DISTRICT
SM242006,6.846,49.701,Pfaelzisch-Lothringisch,LANGUAGE,LANGUAGE
SM269020,8.7721,47.4091,Pfäffikon,ADM2,DISTRICT
SM297050,102.0158,17.0564,Pha Khao,ADM2,DISTRICT
SM280840,100.926,20.1874,Pha Oudom,ADM2,DISTRICT
SM297391,100.7278,14.438,Phachi,ADM2,DISTRICT
SM297196,100.6853,15.5872,Phaisali,ADM2,DISTRICT
SM297378,100.3395,14.4507,Phak Hai,ADM2,DISTRICT
SM297772,101.4112,15.9685,Phakdi Chumphol,ADM2,DISTRICT
SM242435,95.8131,27.8197,Phake,LANGUAGE,LANGUAGE
SM242438,102.505,23.354,Phala,LANGUAGE,LANGUAGE
SM242437,98.789,18.9582,Phalo,DIALECT,LANGUAGE
SM2715,35.6667,-15.6667,Phalombe,ADM1:ADM2,DISTRICT
SM296900,99.773,19.5745,Phan,ADM2,DISTRICT
SM304351,108.9746,11.5958,Phan Rang-Tháp Chàm,ADM2,DISTRICT
SM303941,108.1578,10.937,Phan Thiết,ADM2,DISTRICT
SM296923,101.0913,13.463,Phan Thong,ADM2,DISTRICT
SM296870,104.8568,15.669,Phana,ADM2,DISTRICT
SM242443,101.978,21.9387,Phana',LANGUAGE,LANGUAGE
SM296924,101.2143,13.4643,Phanat Nikhom,ADM2,DISTRICT
SM297494,103.7515,17.3827,Phang Khon,ADM2,DISTRICT
SM242445,87.3537,27.1595,Phangduwali,LANGUAGE,LANGUAGE
SM2716,98.4167,8.6333,Phangnga,ADM1,DISTRICT
SM297495,103.8979,17.3137,Phanna Nikhom,ADM2,DISTRICT
SM297623,98.7171,8.8225,Phanom,ADM2,DISTRICT
SM297531,101.412,13.7381,Phanom Sarakham,ADM2,DISTRICT
SM296972,99.669,14.1594,Phanom Thuan,ADM2,DISTRICT
SM283463,95.5513,16.1803,Pharpon,ADM2,DISTRICT
SM297168,100.441,13.726,Phasi Charoen,ADM2,DISTRICT
SM296929,98.8018,9.8107,Phato,ADM2,DISTRICT
SM2717,100.0833,7.5,Phatthalung,ADM1,DISTRICT
SM297060,101.0267,14.9092,Phatthana Nikhom,ADM2,DISTRICT
SM280834,103.0907,19.2581,Phaxay,ADM2,DISTRICT
SM296901,100.1626,19.8732,Phaya Mengrai,ADM2,DISTRICT
SM297169,100.5431,13.7844,Phaya Thai,ADM2,DISTRICT
SM297071,103.2327,15.5196,Phayakkhaphum Phisai,ADM2,DISTRICT
SM2718,100.25,19.25,Phayao,ADM1,DISTRICT
SM242447,93.817,24.8462,Phayeng,LANGUAGE,LANGUAGE
SM297559,104.3829,14.9064,Phayu,ADM2,DISTRICT
SM297197,100.2123,15.5078,Phayuha Khiri,ADM2,DISTRICT
SM277104,94.5,25.75,Phek,ADM2,DISTRICT
SM301743,-99.4145,40.5111,Phelps,ADM2,DISTRICT
SM301602,-91.7924,37.8771,Phelps,ADM2,DISTRICT
SM297719,102.9305,17.6603,Phen,ADM2,DISTRICT
SM242452,19.8275,-5.6474,Phende,LANGUAGE,LANGUAGE
SM11681,93.1883,24.2613,Pherzawl,ADM2,DISTRICT
SM2719,101.0833,16.25,Phetchabun,ADM1,DISTRICT
SM2720,99.5833,12.9667,Phetchaburi,ADM1,DISTRICT
SM297215,99.5953,8.6029,Phi Pun,ADM2,DISTRICT
SM280817,101.47,18.8768,Phiang,ADM2,DISTRICT
SM297696,105.2409,15.1459,Phibun Mangsahan,ADM2,DISTRICT
SM297720,103.075,17.5263,Phibun Rak,ADM2,DISTRICT
SM297746,100.1128,17.3019,Phichai,ADM2,DISTRICT
SM2721,100.4167,16.25,Phichit,ADM1,DISTRICT
SM302331,-75.134,40.0076,Philadelphia,ADM2,DISTRICT
SM12637,-77.3274,18.4001,Philadelphia,ADM2,DISTRICT
SM242454,121.512,16.916,Philippine Sign Language,LANGUAGE,LANGUAGE
SM301651,-107.9133,48.2591,Phillips,ADM2,DISTRICT
SM300960,-99.347,39.7846,Phillips,ADM2,DISTRICT
SM303361,-102.3576,40.5939,Phillips,ADM2,DISTRICT
SM302401,-90.848,34.4283,Phillips,ADM2,DISTRICT
SM297131,102.5422,15.2462,Phimai,ADM2,DISTRICT
SM242457,33.3514,-15.8364,Phimbi,LANGUAGE,LANGUAGE
SM280788,106.0302,16.4432,Phine,ADM2,DISTRICT
SM2722,100.5,16.9167,Phitsanulok,ADM1,DISTRICT
SM297422,103.1702,14.733,Phlapphlachai,ADM2,DISTRICT
SM2723,104.9139,11.5749,Phnom Penh,ADM1,DISTRICT
SM280384,103.8261,12.1763,Phnum Kravanh,ADM2,DISTRICT
SM280352,103.3206,13.8205,Phnum Srok,ADM2,DISTRICT
SM280489,104.2397,11.3089,Phnum Sruoch,ADM2,DISTRICT
SM297469,103.79,16.3097,Pho Chai,ADM2,DISTRICT
SM297364,100.199,16.3129,Pho Prathap Chan,ADM2,DISTRICT
SM297697,105.3434,15.7674,Pho Si,ADM2,DISTRICT
SM297365,100.2458,16.0589,Pho Thale,ADM2,DISTRICT
SM297025,100.3457,14.6734,Pho Thong,ADM2,DISTRICT
SM242461,35.65,34.12,Phoenician,LANGUAGE,LANGUAGE
SM4248,-171.678,-2.808,Phoenix Islands,ADM1,DISTRICT
SM256868,-3.86,-172.55,Phoenix Islands,ETHNICITY,ETHNICITY
SM256869,33.69,-10.7,Phoka,ETHNICITY,ETHNICITY
SM242464,102.228,23.4674,Phola,LANGUAGE,LANGUAGE
SM242462,104.238,23.667,Pholo,LANGUAGE,LANGUAGE
SM242465,94.7476,26.4715,Phom Naga,LANGUAGE,LANGUAGE
SM296983,102.5911,15.8067,Phon,ADM2,DISTRICT
SM297312,103.6545,18.0797,Phon Charoen,ADM2,DISTRICT
SM297496,104.307,17.2125,Phon Na Kaeo,ADM2,DISTRICT
SM297271,103.1228,17.9544,Phon Phisai,ADM2,DISTRICT
SM297470,103.9414,15.4924,Phon Sai,ADM2,DISTRICT
SM297109,104.4223,17.4687,Phon Sawan,ADM2,DISTRICT
SM297471,103.9639,16.2901,Phon Thong,ADM2,DISTRICT
SM242466,104.9226,18.6093,Phong,DIALECT,LANGUAGE
SM304259,103.3915,22.6065,Phong Thổ,ADM2,DISTRICT
SM304485,107.2889,16.4877,Phong Điền,ADM2,DISTRICT
SM303949,105.6702,9.9996,Phong Điền,ADM2,DISTRICT
SM242467,104.088,19.9622,Phong-Kniang,LANGUAGE,LANGUAGE
SM280766,102.2008,21.864,Phongsaly,ADM2,DISTRICT
SM280806,102.3849,18.4124,Phonhong,ADM2,DISTRICT
SM280856,105.6489,15.1067,Phonthong,ADM2,DISTRICT
SM13380,103.1122,20.8415,Phonthong,ADM2,DISTRICT
SM280889,102.7311,19.8882,Phonxay,ADM2,DISTRICT
SM280835,103.0186,19.6259,Phookood,ADM2,DISTRICT
SM297659,98.835,16.4722,Phop Phra,ADM2,DISTRICT
SM297454,99.7562,13.7071,Photharam,ADM2,DISTRICT
SM280890,102.5961,19.5072,Phoukhoune,ADM2,DISTRICT
SM280756,107.0109,14.5682,Phouvong,ADM2,DISTRICT
SM297170,100.4961,13.7583,Phra Nakhon,ADM2,DISTRICT
SM297379,100.5672,14.3517,Phra Nakhon Si Ayutthaya,ADM2,DISTRICT
SM2725,100.5,14.2833,Phra Nakhon Si Ayutthaya,ADM1,DISTRICT
SM297216,99.9324,8.3265,Phra Phrom,ADM2,DISTRICT
SM297535,100.8062,14.7191,Phra Phutthabat,ADM2,DISTRICT
SM297515,100.5527,13.6525,Phra Pra Daeng,ADM2,DISTRICT
SM297516,100.5149,13.5562,Phra Samut Jadee,ADM2,DISTRICT
SM296984,102.6711,16.3082,Phra Yun,ADM2,DISTRICT
SM2726,100,18.0833,Phrae,ADM1,DISTRICT
SM242471,99.6382,18.0048,Phrae Pwo Karen,LANGUAGE,LANGUAGE
SM297560,104.366,14.7799,Phrai Bung,ADM2,DISTRICT
SM296961,99.537,16.7054,Phran Kratai,ADM2,DISTRICT
SM296881,99.2228,19.2846,Phrao,ADM2,DISTRICT
SM297639,103.4157,14.626,Phrasat,ADM2,DISTRICT
SM297580,100.45,14.7833,Phrom Buri,ADM2,DISTRICT
SM297374,100.1535,17.0689,Phrom Phiram,ADM2,DISTRICT
SM297217,99.7923,8.5424,Phrommakhiri,ADM2,DISTRICT
SM242472,31.28,39.613,Phrygian,LANGUAGE,LANGUAGE
SM297773,102.1619,16.3542,Phu Khieo,ADM2,DISTRICT
SM297038,101.8456,16.9019,Phu Kradung,ADM2,DISTRICT
SM297039,101.6441,17.0978,Phu Luang,ADM2,DISTRICT
SM297497,103.924,16.9286,Phu Phan,ADM2,DISTRICT
SM297040,101.4133,17.3508,Phu Rua,ADM2,DISTRICT
SM297561,104.156,14.4887,Phu Sing,ADM2,DISTRICT
SM242485,104.6842,17.7545,Phu Thai,LANGUAGE,LANGUAGE
SM296985,102.3989,16.6791,Phu Wiang,ADM2,DISTRICT
SM242473,102.191,17.5207,Phuan,LANGUAGE,LANGUAGE
SM242474,73.3684,18.7153,Phudagi,LANGUAGE,LANGUAGE
SM268434,89.4232,26.9139,Phuentsholing,ADM2,DISTRICT
SM242475,-3.4717,11.0235,Phuie,LANGUAGE,LANGUAGE
SM2729,98.351,7.9715,Phuket,ADM1,DISTRICT
SM242476,106.148,25.5277,Phukha,LANGUAGE,LANGUAGE
SM242477,104.05,23.1,Phuma,LANGUAGE,LANGUAGE
SM280822,103.1427,18.8939,Phun,ADM1:ADM2,DISTRICT
SM242479,100.957,20.7996,Phunoi,LANGUAGE,LANGUAGE
SM297624,99.143,9.0247,Phunphin,ADM2,DISTRICT
SM242480,107.467,16.2118,Phuong,LANGUAGE,LANGUAGE
SM242484,103.4,23,Phupa,LANGUAGE,LANGUAGE
SM296987,101.8708,16.7186,Phupa Man,ADM2,DISTRICT
SM242482,102.3,22.95,Phupha,LANGUAGE,LANGUAGE
SM242487,27.7329,-30.5728,Phuthi,DIALECT,LANGUAGE
SM297098,100.2924,13.8256,Phuttha Mon Thon,ADM2,DISTRICT
SM297423,102.987,15.5325,Phutthaisong,ADM2,DISTRICT
SM242489,103.4,23.05,Phuza,LANGUAGE,LANGUAGE
SM2724,102.3333,21.75,Phôngsali,ADM1,DISTRICT
SM304608,109.0806,14.0495,Phù Cát,ADM2,DISTRICT
SM304208,106.1974,20.71,Phù Cừ,ADM2,DISTRICT
SM304609,109.0861,14.223,Phù Mỹ,ADM2,DISTRICT
SM304362,105.3073,21.448,Phù Ninh,ADM2,DISTRICT
SM304466,104.6899,21.2141,Phù Yên,ADM2,DISTRICT
SM304501,105.9634,21.4902,Phú Bình,ADM2,DISTRICT
SM304618,106.7658,11.2646,Phú Giáo,ADM2,DISTRICT
SM304369,109.2271,13.0967,Phú Hoà,ADM2,DISTRICT
SM304502,105.7235,21.7559,Phú Lương,ADM2,DISTRICT
SM304486,107.8855,16.2707,Phú Lộc,ADM2,DISTRICT
SM304120,106.6779,10.8012,Phú Nhuận,ADM2,DISTRICT
SM304387,108.4231,15.5206,Phú Ninh,ADM2,DISTRICT
SM303942,108.9438,10.5293,Phú Quí,ADM2,DISTRICT
SM304222,104.0146,10.2552,Phú Quốc,ADM2,DISTRICT
SM303924,106.8972,11.7347,Phú Riềng,ADM2,DISTRICT
SM304052,108.3218,13.4905,Phú Thiện,ADM2,DISTRICT
SM304363,105.2364,21.4166,Phú Thọ,ADM2,DISTRICT
SM2727,105.1333,21.3333,Phú Thọ,ADM1,DISTRICT
SM303958,104.8872,8.8934,Phú Tân,ADM2,DISTRICT
SM303920,105.2846,10.6572,Phú Tân,ADM2,DISTRICT
SM304487,107.7146,16.4701,Phú Vang,ADM2,DISTRICT
SM304144,105.9141,20.7263,Phú Xuyên,ADM2,DISTRICT
SM2728,109.0833,13.1667,Phú Yên,ADM1,DISTRICT
SM304145,105.5899,21.1255,Phúc Thọ,ADM2,DISTRICT
SM304579,105.7021,21.2341,Phúc Yên,ADM2,DISTRICT
SM303925,106.9967,11.8114,Phước Long,ADM2,DISTRICT
SM304033,105.4328,9.4052,Phước Long,ADM2,DISTRICT
SM304388,107.8577,15.3881,Phước Sơn,ADM2,DISTRICT
SM304500,105.8243,21.4121,Phổ Yên,ADM2,DISTRICT
SM303973,106.5104,22.5384,Phục Hoà,ADM2,DISTRICT
SM304094,105.6998,9.8006,Phụng Hiệp,ADM2,DISTRICT
SM304166,105.919,20.5402,Phủ Lý,ADM2,DISTRICT
SM266795,-50.6584,-21.573,Piacatu,ADM2,DISTRICT
SM277952,9.5833,44.8833,Piacenza,ADM2,DISTRICT
SM242490,166.738,-14.9959,Piamatsina,LANGUAGE,LANGUAGE
SM242491,142.784,-4.8584,Piame,LANGUAGE,LANGUAGE
SM10437,-76.3345,1.1928,Piamonte,ADM2,DISTRICT
SM264269,-37.9463,-7.2263,Piancó,ADM2,DISTRICT
SM256873,-55.27,1.79,Pianokoto,ETHNICITY,ETHNICITY
SM289817,23.4955,45.8713,Pianu De Sus,ADM2,DISTRICT
SM242494,-68.5953,4.3772,Piapoco,LANGUAGE,LANGUAGE
SM256874,-68.92,6.3,Piapoco,ETHNICITY,ETHNICITY
SM256874,-68.92,6.3,Piapoco,ETHNICITY,ETHNICITY
SM256874,-68.92,6.3,Piapoco,ETHNICITY,ETHNICITY
SM256874,-68,6,Piapoco,ETHNICITY,ETHNICITY
SM256874,-68,6,Piapoco,ETHNICITY,ETHNICITY
SM256874,-68,6,Piapoco,ETHNICITY,ETHNICITY
SM256874,-68,3,Piapoco,ETHNICITY,ETHNICITY
SM256874,-68,3,Piapoco,ETHNICITY,ETHNICITY
SM256874,-68,3,Piapoco,ETHNICITY,ETHNICITY
SM303668,-63.4167,10,Piar,ADM2,DISTRICT
SM303862,-62.5,6.1667,Piar,ADM2,DISTRICT
SM242496,-67.7574,4.4181,Piaroa,LANGUAGE,LANGUAGE
SM256875,-67.15,5.42,Piaroa,ETHNICITY,ETHNICITY
SM256875,-67.15,5.42,Piaroa,ETHNICITY,ETHNICITY
SM256875,-67,5,Piaroa,ETHNICITY,ETHNICITY
SM256875,-67,5,Piaroa,ETHNICITY,ETHNICITY
SM288961,20.9832,52.0163,Piaseczno,ADM2,DISTRICT
SM291910,25.1678,43.8106,Piatra,ADM2,DISTRICT
SM291352,26.4185,46.8244,Piatra Soimului,ADM2,DISTRICT
SM291351,26.3738,46.9234,Piatra-neamt,ADM2,DISTRICT
SM291443,24.2833,44.3756,Piatra-olt,ADM2,DISTRICT
SM300664,-88.5911,40.0104,Piatt,ADM2,DISTRICT
SM299915,33.7042,48.408,Piatykhats'kyi,ADM2,DISTRICT
SM267584,-41.8909,-13.121,Piatã,ADM2,DISTRICT
SM263767,-43.3147,-21.5021,Piau,ADM2,DISTRICT
SM2730,-42.3333,-7.25,Piauí,ADM1,DISTRICT
SM282249,-98.2199,18.084,Piaxtla,ADM2,DISTRICT
SM17594,-61.0228,13.7563,Piaye,ADM2,DISTRICT
SM265409,-36.419,-10.3739,Piaçabuçu,ADM2,DISTRICT
SM296016,33.9258,7.1647,Pibor,ADM2,DISTRICT
SM16021,-66.6452,18.165,Pica Barrio,ADM2,DISTRICT
SM265821,-51.1152,-29.4476,Picada Café,ADM2,DISTRICT
SM242498,3.2508,50.2831,Picard,LANGUAGE,LANGUAGE
SM275097,-13.9522,12.3251,Piche,ADM2,DISTRICT
SM230902,8.78,3.74,Pichi,LANGUAGE,LANGUAGE
SM260943,-64.5,-39.5,Pichi Mahuida,ADM2,DISTRICT
SM2731,-78.5,-0.0833,Pichincha,ADM1,DISTRICT
SM11130,-79.8396,-0.8829,Pichincha (2007-),ADM2,DISTRICT
SM242499,-74.6028,-10.4725,Pichis Ashéninka,LANGUAGE,LANGUAGE
SM283180,-93.1612,17.5393,Pichucalco,ADM2,DISTRICT
SM302145,-83.0244,39.6419,Pickaway,ADM2,DISTRICT
SM302464,-82.7253,34.8875,Pickens,ADM2,DISTRICT
SM300395,-84.4656,34.4643,Pickens,ADM2,DISTRICT
SM300295,-88.0887,33.2809,Pickens,ADM2,DISTRICT
SM302609,-85.075,36.5584,Pickett,ADM2,DISTRICT
SM370386,-8.3333,4.6667,Picnicess,ADM2:ADM3,DISTRICT
SM9985,-41.5127,-7.107,Picos (2005-),ADM2,DISTRICT
SM286842,-76.2557,-6.9022,Picota,ADM2,DISTRICT
SM242501,-3.9,56.374,Pictish,LANGUAGE,LANGUAGE
SM268826,-62.5819,45.5002,Pictou,ADM2,DISTRICT
SM323830,-112.7823,49.8739,Picture Butte,ADM2:ADM3,DISTRICT
SM260921,-70.25,-38.5333,Picunches,ADM2,DISTRICT
SM256878,-106,36,Picuris,ETHNICITY,ETHNICITY
SM242503,-105.2671,37.083,Picuris Northern Tiwa,LANGUAGE,LANGUAGE
SM264270,-36.3584,-6.5301,Picuí,ADM2,DISTRICT
SM260920,-69.5,-39.5,Picún Leufú,ADM2,DISTRICT
SM242517,19.1663,1.6779,Pidgin Bobangi,LANGUAGE,LANGUAGE
SM242505,-75.463,38.569,Pidgin Delaware,LANGUAGE,LANGUAGE
SM259482,36.5176,5.0652,Pidgin Hamer,LANGUAGE,LANGUAGE
SM242509,178.4541,-18.112,Pidgin Hindustani,LANGUAGE,LANGUAGE
SM242506,114.0843,-21.9488,Pidgin Ngarluma,LANGUAGE,LANGUAGE
SM299736,25.1278,49.2679,Pidhaiets'kyi,ADM2,DISTRICT
SM276292,96.11,5.08,Pidie,ADM2,DISTRICT
SM276290,96.2167,5.15,Pidie Jaya,ADM2,DISTRICT
SM299737,26.1451,49.5293,Pidvolochys'kyi,ADM2,DISTRICT
SM266796,-47.4351,-23.8079,Piedade,ADM2,DISTRICT
SM263769,-42.0394,-19.759,Piedade de Caratinga,ADM2,DISTRICT
SM263770,-42.7159,-20.2441,Piedade do Ponte Nova,ADM2,DISTRICT
SM263771,-44.1615,-21.5029,Piedade do Rio Grande,ADM2,DISTRICT
SM263772,-44.2531,-20.4808,Piedade dos Gerais,ADM2,DISTRICT
SM270498,-73.0228,6.9744,Piedecuesta,ADM2,DISTRICT
SM271796,-70.3403,18.8122,Piedra Blanca,ADM2,DISTRICT
SM16022,-66.8863,18.417,Piedra Gorda Barrio,ADM2,DISTRICT
SM270575,-74.9167,4.5,Piedras,ADM2,DISTRICT
SM16023,-66.2182,18.1184,Piedras Barrio,ADM2,DISTRICT
SM16024,-67.1863,18.3633,Piedras Blancas Barrio (Aguada),ADM2,DISTRICT
SM16025,-66.9713,18.3355,Piedras Blancas Barrio (San Sebastián),ADM2,DISTRICT
SM283295,-100.579,28.7537,Piedras Negras,ADM2,DISTRICT
SM13736,27.4923,55.8111,Piedrujas pagasts,ADM2,DISTRICT
SM256879,-114.27,49.71,Piegan,ETHNICITY,ETHNICITY
SM256879,-114.27,49.71,Piegan,ETHNICITY,ETHNICITY
SM256879,-114.27,49.71,Piegan,ETHNICITY,ETHNICITY
SM256879,-114.27,49.71,Piegan,ETHNICITY,ETHNICITY
SM256879,-110,48,Piegan,ETHNICITY,ETHNICITY
SM256879,-110,48,Piegan,ETHNICITY,ETHNICITY
SM256879,-110,48,Piegan,ETHNICITY,ETHNICITY
SM256879,-110,48,Piegan,ETHNICITY,ETHNICITY
SM256879,-115,49,Piegan,ETHNICITY,ETHNICITY
SM256879,-115,49,Piegan,ETHNICITY,ETHNICITY
SM256879,-115,49,Piegan,ETHNICITY,ETHNICITY
SM256879,-115,49,Piegan,ETHNICITY,ETHNICITY
SM256879,-111.21,49.34,Piegan,ETHNICITY,ETHNICITY
SM256879,-111.21,49.34,Piegan,ETHNICITY,ETHNICITY
SM256879,-111.21,49.34,Piegan,ETHNICITY,ETHNICITY
SM256879,-111.21,49.34,Piegan,ETHNICITY,ETHNICITY
SM288729,18.9284,50.3809,Piekary Śląskie,ADM2,DISTRICT
SM11224,27.5553,62.091,Pieksaemaeki (2009-),ADM2,DISTRICT
SM290391,23.9757,44.3563,Pielesti,ADM2,DISTRICT
SM346765,29.6681,63.4565,Pielisen Karelia,ADM2:ADM3,DISTRICT
SM2732,8,45,Piemonte,ADM1,DISTRICT
SM242525,8.3237,45.4633,Piemontese,LANGUAGE,LANGUAGE
SM269866,-76.5974,2.7021,Piendamó,ADM2,DISTRICT
SM303088,-122.1374,47.0376,Pierce,ADM2,DISTRICT
SM303266,-92.4225,44.7196,Pierce,ADM2,DISTRICT
SM301745,-97.6013,42.2644,Pierce,ADM2,DISTRICT
SM302058,-99.9718,48.2496,Pierce,ADM2,DISTRICT
SM300396,-82.2127,31.3588,Pierce,ADM2,DISTRICT
SM17595,-60.9438,13.7686,Pierrot,ADM2,DISTRICT
SM292222,24.1024,45.1159,Pietrari,ADM2,DISTRICT
SM292008,22.4181,45.8201,Pietroasa,ADM2,DISTRICT
SM292449,22.6337,46.5838,Pietroasa,ADM2,DISTRICT
SM289904,26.5914,45.1036,Pietroasele,ADM2,DISTRICT
SM291912,25.64,43.7136,Pietrosani,ADM2,DISTRICT
SM291273,24.8444,45.1538,Pietrosani,ADM2,DISTRICT
SM290294,25.4283,45.1877,Pietrosita,ADM2,DISTRICT
SM296177,17.8268,48.5906,Pieštany,ADM2,DISTRICT
SM10996,32.6508,35.1549,Pigenia,ADM2,DISTRICT
SM17596,-60.9572,14.0897,Pigeon Island,ADM2,DISTRICT
SM10997,33.7841,35.2126,Pigi,ADM2,DISTRICT
SM14962,150.418,8.569,Piherarh Municipality,ADM2,DISTRICT
SM281832,-103.3778,19.1893,Pihuamo,ADM2,DISTRICT
SM14963,152.7,6.834,Piis-Emwar Municipality,ADM2,DISTRICT
SM14964,151.7634,7.678,Piis-Panewu Municipality,ADM2,DISTRICT
SM242528,-75.3333,0.5,Pijao,LANGUAGE,LANGUAGE
SM270414,-75.6818,4.3059,Pijao,ADM2,DISTRICT
SM256880,-75.51,3.71,Pijao,ETHNICITY,ETHNICITY
SM242529,164.942,-20.7824,Pije,LANGUAGE,LANGUAGE
SM283181,-93.2029,15.6462,Pijijiapan,ADM2,DISTRICT
SM242530,160.137,-9.567,Pijin,LANGUAGE,LANGUAGE
SM10438,-74.1199,9.5353,Pijino del Carmen,ADM2,DISTRICT
SM285687,4.4194,52.0262,Pijnacker-Nootdorp,ADM2,DISTRICT
SM302332,-75.0338,41.332,Pike,ADM2,DISTRICT
SM302146,-83.0669,39.0774,Pike,ADM2,DISTRICT
SM301478,-90.4042,31.1749,Pike,ADM2,DISTRICT
SM301603,-91.1714,39.3438,Pike,ADM2,DISTRICT
SM300751,-87.2322,38.3988,Pike,ADM2,DISTRICT
SM300665,-90.8863,39.6225,Pike,ADM2,DISTRICT
SM301106,-82.3959,37.469,Pike,ADM2,DISTRICT
SM302402,-93.6563,34.1635,Pike,ADM2,DISTRICT
SM300397,-84.3892,33.0923,Pike,ADM2,DISTRICT
SM300296,-85.9409,31.8027,Pike,ADM2,DISTRICT
SM12638,-77.5379,18.2323,Pike,ADM2,DISTRICT
SM256881,147.65,8.1,Pikelot,ETHNICITY,ETHNICITY
SM295248,-17.352,14.7652,Pikine,ADM2,DISTRICT
SM16353,16.4423,0.6327,Pikounda,ADM2,DISTRICT
SM293779,45.9167,55.5833,Pil'ninskiy rayon,ADM2,DISTRICT
SM260815,-58.3333,-36.25,Pila,ADM2,DISTRICT
SM256882,-62.46,-23.56,Pilaga,ETHNICITY,ETHNICITY
SM256882,-62.46,-23.56,Pilaga,ETHNICITY,ETHNICITY
SM256882,-60,-25.5,Pilaga,ETHNICITY,ETHNICITY
SM256882,-60,-25.5,Pilaga,ETHNICITY,ETHNICITY
SM242534,-60.1537,-25.0058,Pilagá,LANGUAGE,LANGUAGE
SM261195,-58.75,-25.0833,Pilagás,ADM2,DISTRICT
SM289604,-58.2168,-26.9031,Pilar,ADM2,DISTRICT
SM264271,-35.2697,-7.2574,Pilar,ADM2,DISTRICT
SM265410,-36.0632,-9.6175,Pilar,ADM2,DISTRICT
SM268130,-49.5109,-14.53,Pilar de Goiás,ADM2,DISTRICT
SM266797,-47.719,-23.8532,Pilar do Sul,ADM2,DISTRICT
SM260932,-70.5,-40.75,Pilcaniyeu,ADM2,DISTRICT
SM281626,-99.6266,18.7113,Pilcaya,ADM2,DISTRICT
SM261196,-58.0833,-25.5,Pilcomayo,ADM2,DISTRICT
SM13737,27.7675,56.3667,Pildas pagasts,ADM2,DISTRICT
SM10998,33.2122,35.2904,Pileri,ADM2,DISTRICT
SM16026,-66.8823,18.335,Piletas Barrio,ADM2,DISTRICT
SM277322,80.1,28.55,Pilibhit,ADM2,DISTRICT
SM458098,127.6005,-19.6667,Pililuna,ETHNICITY,ETHNICITY
SM11586,18.8666,47.6093,Pilisvoeroesvari Jaras,ADM2,DISTRICT
SM18507,26.2374,56.0024,Pilskalnes pagasts (Ilūkstes),ADM2,DISTRICT
SM13738,25.1587,56.2214,Pilskalnes pagasts (Neretas),ADM2,DISTRICT
SM458694,136.0259,-27.7222,Piltapalta,ETHNICITY,ETHNICITY
SM13739,21.6998,57.2243,Piltene,ADM2,DISTRICT
SM13740,21.739,57.248,Piltenes pagasts,ADM2,DISTRICT
SM290525,21.3471,46.5973,Pilu,ADM2,DISTRICT
SM267585,-43.3937,-10.002,Pilão Arcado,ADM2,DISTRICT
SM271103,-77.3249,19.9034,Pilón,ADM2,DISTRICT
SM265447,-38.0191,-6.2883,Pilões,ADM2,DISTRICT
SM264272,-35.5943,-6.8945,Pilões,ADM2,DISTRICT
SM264273,-35.5446,-6.8527,Pilõezinhos,ADM2,DISTRICT
SM301790,-111.79,32.0974,Pima,ADM2,DISTRICT
SM242540,-108.326,28.654,Pima Bajo,LANGUAGE,LANGUAGE
SM256885,-111.86,30.7,Pima Lower,ETHNICITY,ETHNICITY
SM273460,-77.9667,0.4,Pimampiro,ADM2,DISTRICT
SM242542,31.4289,-6.8136,Pimbwe,LANGUAGE,LANGUAGE
SM250633,31,-7,Pimbwe,ETHNICITY,ETHNICITY
SM263773,-45.8334,-20.4851,Pimenta,ADM2,DISTRICT
SM266100,-60.7498,-11.7878,Pimenta Bueno,ADM2,DISTRICT
SM242543,-43.8832,-7.344,Pimenteira,LANGUAGE,LANGUAGE
SM265091,-41.2181,-6.3307,Pimenteiras,ADM2,DISTRICT
SM266101,-61.7376,-13.0127,Pimenteiras do Oeste,ADM2,DISTRICT
SM271901,-70.1667,19.2167,Pimentel,ADM2,DISTRICT
SM12639,-77.1138,18.3958,Pimento Walk,ADM2,DISTRICT
SM275889,-87.9667,15.265,Pimienta,ADM2,DISTRICT
SM242545,153.3045,-27.8217,Pimpama,LANGUAGE,LANGUAGE
SM242549,144.137,-5.1262,Pinai-Hagahai,LANGUAGE,LANGUAGE
SM301791,-111.3447,32.9043,Pinal,ADM2,DISTRICT
SM282451,-99.5885,21.1576,Pinal de Amoles,ADM2,DISTRICT
SM16027,-67.1727,18.3003,Pinales Barrio,ADM2,DISTRICT
SM260817,-56.855,-37.1103,Pinamar,ADM2,DISTRICT
SM271037,-83.5849,22.3697,Pinar del Río,ADM2,DISTRICT
SM2733,-83.8333,22.4167,Pinar del Río,ADM1,DISTRICT
SM16029,-66.2263,18.1951,Pinas Barrio (Comeria),ADM2,DISTRICT
SM16028,-66.2368,18.3616,Pinas Barrio (Toa Alta),ADM2,DISTRICT
SM323854,-113.9424,49.4912,Pincher Creek,ADM2:ADM3,DISTRICT
SM270499,-73.1888,6.5084,Pinchote,ADM2,DISTRICT
SM273464,-80.1064,-4.0807,Pindal,ADM2,DISTRICT
SM266798,-45.46,-22.8876,Pindamonhangaba,ADM2,DISTRICT
SM262852,-45.3938,-3.6252,Pindaré-Mirim,ADM2,DISTRICT
SM267588,-42.6782,-14.5232,Pindaí,ADM2,DISTRICT
SM256887,20.05,-5.85,Pinde,ETHNICITY,ETHNICITY
SM256887,24.38,-5.05,Pinde,ETHNICITY,ETHNICITY
SM458017,127.7663,-29.466,Pindiini,ETHNICITY,ETHNICITY
SM265411,-36.3034,-9.4749,Pindoba,ADM2,DISTRICT
SM267589,-40.3274,-10.7385,Pindobaçu,ADM2,DISTRICT
SM266800,-48.9111,-21.2069,Pindorama,ADM2,DISTRICT
SM267285,-47.5617,-11.1323,Pindorama do Tocantins,ADM2,DISTRICT
SM267826,-38.2945,-4.0616,Pindoretama,ADM2,DISTRICT
SM301392,-92.7413,46.1208,Pine,ADM2,DISTRICT
SM300360,-82.7395,27.9027,Pinellas,ADM2,DISTRICT
SM327671,-95.9173,49.225,Piney,ADM2:ADM3,DISTRICT
SM293592,43,64.6667,Pinezhskiy rayon,ADM2,DISTRICT
SM256888,-111.2,66.78,Pingangnaktogmiut,ETHNICITY,ETHNICITY
SM269177,112.9533,33.975,Pingdingshan,ADM2,DISTRICT
SM14965,160.7103,6.2091,Pingelap Municipality,ADM2,DISTRICT
SM242555,160.711,6.2074,Pingelapese,LANGUAGE,LANGUAGE
SM261300,117.2,-32.5317,Pingelly,ADM2,DISTRICT
SM269403,106.4957,35.2268,Pingliang,ADM2,DISTRICT
SM263774,-42.4284,-19.7372,Pingo d'Água,ADM2,DISTRICT
SM299060,120.6144,22.4956,Pingtung,ADM2,DISTRICT
SM269230,113.8701,27.5781,Pingxiang,ADM2,DISTRICT
SM242558,111.9656,37.1391,Pingyao,DIALECT,LANGUAGE
SM264630,-49.1573,-25.4315,Pinhais,ADM2,DISTRICT
SM266801,-46.6724,-22.1263,Pinhal,ADM2,DISTRICT
SM265823,-53.2343,-27.5264,Pinhal,ADM2,DISTRICT
SM9986,-51.2245,-27.8816,Pinhal Da Serra Municipality,ADM2,DISTRICT
SM265822,-53.3459,-29.2708,Pinhal Grande,ADM2,DISTRICT
SM264631,-53.4891,-26.0068,Pinhal de São Bento,ADM2,DISTRICT
SM266802,-46.5718,-22.7821,Pinhalzinho,ADM2,DISTRICT
SM266246,-52.9764,-26.8193,Pinhalzinho,ADM2,DISTRICT
SM264633,-50.0544,-23.9246,Pinhalão,ADM2,DISTRICT
SM265296,-44.0114,-22.5338,Pinheiral,ADM2,DISTRICT
SM265824,-53.6335,-27.2264,Pinheirinho do Vale,ADM2,DISTRICT
SM262853,-45.1224,-2.6991,Pinheiro,ADM2,DISTRICT
SM265825,-53.4225,-31.4378,Pinheiro Machado,ADM2,DISTRICT
SM266247,-51.2271,-27.0546,Pinheiro Preto,ADM2,DISTRICT
SM268016,-40.2098,-18.3449,Pinheiros,ADM2,DISTRICT
SM289229,-7.1266,40.7424,Pinhel,ADM2,DISTRICT
SM267122,-37.7858,-10.5711,Pinhão,ADM2,DISTRICT
SM264634,-51.6528,-25.7623,Pinhão,ADM2,DISTRICT
SM242560,121.995,-26.8016,Pini,LANGUAGE,LANGUAGE
SM242559,116.538,-22.4729,Pinigura,LANGUAGE,LANGUAGE
SM459144,116.3728,-23.0306,Pinikura,ETHNICITY,ETHNICITY
SM10439,-74.3811,8.8237,Pinillos (1994-),ADM2,DISTRICT
SM242564,115.7,-32.7,Pinjarup,DIALECT,LANGUAGE
SM242563,11.223,-1.9031,Pinji,LANGUAGE,LANGUAGE
SM286703,-77.5256,7.8934,Pinogana,ADM2,DISTRICT
SM283069,-101.5433,22.2663,Pinos,ADM2,DISTRICT
SM242565,-97.9966,16.3043,Pinotepa Nacional Mixtec,LANGUAGE,LANGUAGE
SM14365,-97.9668,16.4053,Pinotepa de Don Luis,ADM2,DISTRICT
SM276578,119.6,-3.6167,Pinrang,ADM2,DISTRICT
SM262572,26.1667,52.1667,Pinsk,ADM2,DISTRICT
SM267590,-39.9778,-11.9231,Pintadas,ADM2,DISTRICT
SM242567,127.632,-29.806,Pintiini,LANGUAGE,LANGUAGE
SM9987,-51.4618,-29.0929,Pinto Bandeira,ADM2:ADM3,DISTRICT
SM242566,130.643,-23.2963,Pintupi-Luritja,LANGUAGE,LANGUAGE
SM263775,-45.2193,-16.0112,Pintópolis,ADM2,DISTRICT
SM242571,10.0682,5.7991,Pinyin,LANGUAGE,LANGUAGE
SM265092,-40.5972,-6.8698,Pio IX,ADM2,DISTRICT
SM262854,-45.1662,-3.8945,Pio XII,ADM2,DISTRICT
SM270661,-75.138,10.7461,Piojó,ADM2,DISTRICT
SM288871,19.7396,51.3782,Piotrków,ADM2,DISTRICT
SM288870,19.7166,51.413,Piotrków Trybunalski,ADM2,DISTRICT
SM459121,147.4024,-41.1358,Piper River Tasmanian,ETHNICITY,ETHNICITY
SM301394,-96.2586,44.023,Pipestone,ADM2,DISTRICT
SM327798,-101.1681,49.67,Pipestone,ADM2:ADM3,DISTRICT
SM242573,-89.443,13.8931,Pipil,LANGUAGE,LANGUAGE
SM291353,26.0848,47.228,Pipirig,ADM2,DISTRICT
SM266803,-51.7458,-21.8426,Piquerobi,ADM2,DISTRICT
SM267827,-39.4558,-5.8604,Piquet Carneiro,ADM2,DISTRICT
SM266804,-45.1658,-22.5978,Piquete,ADM2,DISTRICT
SM291679,22.3938,47.4665,Pir,ADM2,DISTRICT
SM266805,-46.3025,-23.0529,Piracaia,ADM2,DISTRICT
SM268131,-49.0242,-17.3226,Piracanjuba,ADM2,DISTRICT
SM263776,-44.4175,-20.5192,Piracema,ADM2,DISTRICT
SM266806,-47.773,-22.7158,Piracicaba,ADM2,DISTRICT
SM266807,-47.3667,-21.9984,Piracununga,ADM2,DISTRICT
SM265094,-41.7415,-3.8666,Piracuruca,ADM2,DISTRICT
SM275098,-14.1549,12.6669,Pirada,ADM2,DISTRICT
SM11256,-149.5214,-17.5324,Pirae,ADM2,DISTRICT
SM275704,-88.4632,14.0626,Piraera,ADM2,DISTRICT
SM242575,-61.8167,-6.7771,Pirahã,LANGUAGE,LANGUAGE
SM9988,-49.3942,-23.1977,Piraju,ADM2,DISTRICT
SM263777,-48.7031,-19.9407,Pirajuba,ADM2,DISTRICT
SM9989,-49.4139,-21.9578,Pirajui,ADM2,DISTRICT
SM267123,-36.8486,-10.6908,Pirambu,ADM2,DISTRICT
SM296298,13.6269,45.4828,Piran,ADM1:ADM2,DISTRICT
SM263778,-43.2835,-20.6289,Piranga,ADM2,DISTRICT
SM266811,-48.6719,-21.0857,Pirangi,ADM2,DISTRICT
SM263782,-45.5946,-22.3488,Piranguinho,ADM2,DISTRICT
SM263781,-45.5104,-22.5635,Piranguçu,ADM2,DISTRICT
SM268133,-51.8248,-16.3947,Piranhas,ADM2,DISTRICT
SM265412,-37.7453,-9.5481,Piranhas,ADM2,DISTRICT
SM277638,45.1667,36.7,Piranshahr,ADM2,DISTRICT
SM261197,-59.1667,-25.6667,Pirané,ADM2,DISTRICT
SM262855,-44.2775,-3.7796,Pirapemas,ADM2,DISTRICT
SM263783,-42.3652,-21.668,Pirapetinga,ADM2,DISTRICT
SM263784,-44.8597,-17.4196,Pirapora,ADM2,DISTRICT
SM266812,-46.978,-23.372,Pirapora do Bom Jesus,ADM2,DISTRICT
SM266813,-51.5649,-22.43,Pirapozinho,ADM2,DISTRICT
SM289577,-55.5147,-26.8334,Pirapó,ADM2,DISTRICT
SM265826,-55.2346,-28.0529,Pirapó,ADM2,DISTRICT
SM264636,-49.078,-25.4675,Piraquara,ADM2,DISTRICT
SM267286,-48.2371,-6.6886,Piraquê,ADM2,DISTRICT
SM265827,-53.1116,-31.4166,Piratini,ADM2,DISTRICT
SM266814,-49.1933,-22.4228,Piratininga,ADM2,DISTRICT
SM266248,-51.7747,-27.4562,Piratuba,ADM2,DISTRICT
SM263785,-43.014,-21.2647,Pirauba,ADM2,DISTRICT
SM289634,-57.2145,-25.4772,Pirayú,ADM2,DISTRICT
SM298433,38.1167,40.9564,Piraziz,ADM2,DISTRICT
SM265297,-43.895,-22.667,Piraí,ADM2,DISTRICT
SM267591,-39.3921,-13.8497,Piraí do Norte,ADM2,DISTRICT
SM264635,-49.9153,-24.4885,Piraí do Sul,ADM2,DISTRICT
SM262424,24.2333,42.7,Pirdop,ADM2,DISTRICT
SM268134,-49.0067,-15.7977,Pirenópolis,ADM2,DISTRICT
SM267828,-40.5987,-4.2549,Pires Ferreira,ADM2,DISTRICT
SM268135,-48.3939,-17.3492,Pires do Rio,ADM2,DISTRICT
SM18355,-55.2242,-34.8479,Piriapolis,ADM2,DISTRICT
SM289532,-57.0422,-25.4964,Piribebuy,ADM2,DISTRICT
SM256890,-51.85,1.9,Piriou,ETHNICITY,ETHNICITY
SM265095,-41.7882,-4.3949,Piripiri,ADM2,DISTRICT
SM267592,-41.753,-14.9262,Piripá,ADM2,DISTRICT
SM267593,-40.515,-11.6285,Piritiba,ADM2,DISTRICT
SM274402,23.7167,61.7,Pirkanmaa,ADM1:ADM2,DISTRICT
SM242578,139.77,-29.524,Pirlatapa,LANGUAGE,LANGUAGE
SM458667,140.1004,-29.8394,Pirlatapa,ETHNICITY,ETHNICITY
SM242580,-106.415,35.2291,Piro,LANGUAGE,LANGUAGE
SM256891,-73.96,-10.4,Piro,ETHNICITY,ETHNICITY
SM256891,-73.96,-10.4,Piro,ETHNICITY,ETHNICITY
SM256891,-73.96,-10.4,Piro,ETHNICITY,ETHNICITY
SM256892,-107.07,34.39,Piro,ETHNICITY,ETHNICITY
SM256891,-74,-12,Piro,ETHNICITY,ETHNICITY
SM256891,-74,-12,Piro,ETHNICITY,ETHNICITY
SM256891,-74,-12,Piro,ETHNICITY,ETHNICITY
SM256891,-73,-12,Piro,ETHNICITY,ETHNICITY
SM256891,-73,-12,Piro,ETHNICITY,ETHNICITY
SM256891,-73,-12,Piro,ETHNICITY,ETHNICITY
SM262229,90.0003,22.5367,Pirojpur,ADM2,DISTRICT
SM2734,22.5928,43.1753,Pirotski,ADM1:ADM2,DISTRICT
SM305434,15.6682,43.8203,Pirovac,ADM2,DISTRICT
SM293448,92,57.6667,Pirovskiy rayon,ADM2,DISTRICT
SM264274,-35.4804,-6.7837,Pirpirituba,ADM2,DISTRICT
SM242585,142.5485,-25.4801,Pirriya,LANGUAGE,LANGUAGE
SM458680,142.4902,-25.4916,Pirriya,ETHNICITY,ETHNICITY
SM459101,142.7988,-37.4139,Pirtpirtwurrung,ETHNICITY,ETHNICITY
SM242586,128.197,-3.0537,Piru,LANGUAGE,LANGUAGE
SM277914,10.7167,43.4167,Pisa,ADM2,DISTRICT
SM256893,137.18,-4.71,Pisa,ETHNICITY,ETHNICITY
SM242589,-73.3411,-6.3255,Pisabo,LANGUAGE,LANGUAGE
SM281697,-98.995,21.2276,Pisaflores,ADM2,DISTRICT
SM242588,-98.0305,20.7039,Pisaflores Tepehua,LANGUAGE,LANGUAGE
SM242590,-70.5025,1.1017,Pisamira,LANGUAGE,LANGUAGE
SM305621,15.8595,45.5882,Pisarovina,ADM2,DISTRICT
SM270808,-72.4894,5.7428,Pisba,ADM2,DISTRICT
SM301200,-69.2845,45.8374,Piscataquis,ADM2,DISTRICT
SM242593,-76.803,39.073,Piscataway,LANGUAGE,LANGUAGE
SM292009,21.4024,45.9046,Pischia,ADM2,DISTRICT
SM286743,-75.9426,-13.8038,Pisco,ADM2,DISTRICT
SM291681,22.275,47.5976,Piscolt,ADM2,DISTRICT
SM290459,27.7165,45.5168,Piscu,ADM2,DISTRICT
SM12640,-77.8867,18.1961,Pisgah,ADM2,DISTRICT
SM299777,28.8844,48.2104,Pishchans'kyi,ADM2,DISTRICT
SM375390,67.2139,30.8181,Pishin,ADM2:ADM3,DISTRICT
SM242595,31.28,37.273,Pisidian,LANGUAGE,LANGUAGE
SM256895,-51.29,66.12,Pisigsarfik,ETHNICITY,ETHNICITY
SM18428,69.5,40.8333,Piskent Tumani,ADM2,DISTRICT
SM10999,32.7014,34.6702,Pissouri,ADM2,DISTRICT
SM277915,10.8333,43.9667,Pistoia,ADM2,DISTRICT
SM288776,21.8572,53.6808,Pisz,ADM2,DISTRICT
SM275000,-12.5833,10.8333,Pita,ADM1:ADM2,DISTRICT
SM256896,140.93,-25.33,Pita Pita,ETHNICITY,ETHNICITY
SM256896,140.93,-25.33,Pita Pita,ETHNICITY,ETHNICITY
SM256896,139.7247,-22.6284,Pita Pita,ETHNICITY,ETHNICITY
SM256896,139.7247,-22.6284,Pita Pita,ETHNICITY,ETHNICITY
SM16030,-66.0658,18.0006,Pitahaya Barrio (Arroyo),ADM2,DISTRICT
SM16031,-65.707,18.3422,Pitahaya Barrio (Luquillo),ADM2,DISTRICT
SM270096,-75.8314,2.2299,Pital,ADM2,DISTRICT
SM270097,-76.1226,1.8213,Pitalito,ADM2,DISTRICT
SM264637,-51.767,-24.6625,Pitanga,ADM2,DISTRICT
SM266815,-48.262,-21.014,Pitangueiras,ADM2,DISTRICT
SM264638,-51.5684,-23.1919,Pitangueiras,ADM2,DISTRICT
SM263786,-44.8757,-19.5729,Pitangui,ADM2,DISTRICT
SM11000,32.5436,34.8337,Pitargou,ADM2,DISTRICT
SM256897,-130.1,-25.08,Pitcairn,ETHNICITY,ETHNICITY
SM242602,-128.3241,-24.3799,Pitcairn-Norfolk,LANGUAGE,LANGUAGE
SM242603,17.4261,66.4011,Pite Saami,LANGUAGE,LANGUAGE
SM291274,24.8713,44.8578,Pitesti,ADM2,DISTRICT
SM296394,20.8515,65.3723,Piteå,ADM2,DISTRICT
SM12641,-77.8901,18.4321,Pitfour,ADM2,DISTRICT
SM277355,80.25,30,Pithoragarh (1997-),ADM2,DISTRICT
SM242606,8.4704,10.1361,Piti,LANGUAGE,LANGUAGE
SM2735,144.6928,13.4626,Piti,ADM1,DISTRICT
SM264275,-34.8369,-7.4138,Pitimbu,ADM2,DISTRICT
SM282591,-111.9589,30.215,Pitiquito,ADM2,DISTRICT
SM242607,131.357,-26.9704,Pitjantjatjara,LANGUAGE,LANGUAGE
SM256898,129.44,-25.27,Pitjantjatjara,ETHNICITY,ETHNICITY
SM256898,129.44,-25.27,Pitjantjatjara,ETHNICITY,ETHNICITY
SM256898,129.44,-25.27,Pitjantjatjara,ETHNICITY,ETHNICITY
SM256898,130,-26,Pitjantjatjara,ETHNICITY,ETHNICITY
SM256898,130,-26,Pitjantjatjara,ETHNICITY,ETHNICITY
SM256898,130,-26,Pitjantjatjara,ETHNICITY,ETHNICITY
SM256898,130.0655,-25.6641,Pitjantjatjara,ETHNICITY,ETHNICITY
SM256898,130.0655,-25.6641,Pitjantjatjara,ETHNICITY,ETHNICITY
SM256898,130.0655,-25.6641,Pitjantjatjara,ETHNICITY,ETHNICITY
SM303363,-106.9166,39.2171,Pitkin,ADM2,DISTRICT
SM17040,31.5,61.5833,Pitkyarantskiy Rayon,ADM2,DISTRICT
SM18429,61.3067,41.2222,Pitnak Shahri,ADM2,DISTRICT
SM305535,17.232,45.9478,Pitomača,ADM2,DISTRICT
SM17597,-60.9452,13.965,Piton Flore,ADM2,DISTRICT
SM302002,-77.3747,35.5935,Pitt,ADM2,DISTRICT
SM242610,139.983,-22.7952,Pitta Pitta,LANGUAGE,LANGUAGE
SM302229,-95.7484,34.9239,Pittsburg,ADM2,DISTRICT
SM303065,-79.3971,36.8213,Pittsylvania,ADM2,DISTRICT
SM242612,146.455,-6.9669,Piu,LANGUAGE,LANGUAGE
SM263787,-46.0608,-20.4326,Piui,ADM2,DISTRICT
SM267287,-49.6986,-10.074,Pium,ADM2,DISTRICT
SM286817,-80.4062,-5.2142,Piura,ADM2,DISTRICT
SM2736,-80.3333,-5,Piura,ADM1,DISTRICT
SM302900,-112.127,38.3365,Piute,ADM2,DISTRICT
SM270129,-74.4154,10.4318,Pivijay,ADM2,DISTRICT
SM296291,14.2356,45.6828,Pivka,ADM1:ADM2,DISTRICT
SM305140,23.2582,-30.2892,Pixley ka Seme,ADM2,DISTRICT
SM242614,10.8379,9.3198,Piya-Kwonci,LANGUAGE,LANGUAGE
SM293279,48.5,57.4167,Pizhanskiy rayon,ADM2,DISTRICT
SM264215,-48.9504,-6.4734,Piçarra,ADM2,DISTRICT
SM266245,-48.76,-26.7558,Piçarras,ADM2,DISTRICT
SM264629,-49.4564,-26.0865,Piên,ADM2,DISTRICT
SM273617,-79.6667,-3.6667,Piñas,ADM2,DISTRICT
SM268017,-40.7293,-20.8311,Piúma,ADM2,DISTRICT
SM305670,14.0416,45.2028,Pićan,ADM2,DISTRICT
SM288801,16.9147,53.1575,Piła,ADM2,DISTRICT
SM288765,20.4725,50.4997,Pińczów,ADM2,DISTRICT
SM297110,104.5467,17.2013,Pla Pak,ADM2,DISTRICT
SM264216,-54.5242,-3.9772,Placas,ADM2,DISTRICT
SM303187,-120.7177,39.0634,Placer,ADM2,DISTRICT
SM271065,-79.7427,22.2225,Placetas,ADM2,DISTRICT
SM297532,101.3397,13.5638,Plaeng Yao,ADM2,DISTRICT
SM370243,-9.0209,5.3158,Plahnnyarn,ADM2:ADM3,DISTRICT
SM370243,-9.0209,5.3158,Plahnnyarn,ADM2:ADM3,DISTRICT
SM370243,-9,5.3333,Plahnnyarn,ADM2:ADM3,DISTRICT
SM370243,-9,5.3333,Plahnnyarn,ADM2:ADM3,DISTRICT
SM297008,98.8367,8.5409,Plai Phraya,ADM2,DISTRICT
SM290708,26.0944,46.2163,Plaiesii De Jos,ADM2,DISTRICT
SM2737,57.4833,-20.3,Plaines Wilhems,ADM1,DISTRICT
SM252361,29.29,0.41,Plains Bira,ETHNICITY,ETHNICITY
SM252361,29.29,0.41,Plains Bira,ETHNICITY,ETHNICITY
SM252361,30,1,Plains Bira,ETHNICITY,ETHNICITY
SM252361,30,1,Plains Bira,ETHNICITY,ETHNICITY
SM242620,-110.463,51.2437,Plains Cree,LANGUAGE,LANGUAGE
SM256901,-94,45,Plains Cree,ETHNICITY,ETHNICITY
SM256901,-94,45,Plains Cree,ETHNICITY,ETHNICITY
SM256901,-102.67,51.86,Plains Cree,ETHNICITY,ETHNICITY
SM256901,-102.67,51.86,Plains Cree,ETHNICITY,ETHNICITY
SM242617,-99.2499,38.3941,Plains Indian Sign Language,LANGUAGE,LANGUAGE
SM242621,-121.6533,38.3977,Plains Miwok,LANGUAGE,LANGUAGE
SM454539,36,1,Plains Suk,ETHNICITY,ETHNICITY
SM454539,36,1,Plains Suk,ETHNICITY,ETHNICITY
SM454539,19,3,Plains Suk,ETHNICITY,ETHNICITY
SM454539,19,3,Plains Suk,ETHNICITY,ETHNICITY
SM276102,-72.5491,19.6676,Plaisance,ADM2,DISTRICT
SM2738,55.4583,-4.65,Plaisance,ADM1,DISTRICT
SM242624,-97.7826,17.2173,Plan de Guadalupe,DIALECT,LANGUAGE
SM270576,-75.8168,3.1033,Planadas,ADM2,DISTRICT
SM268136,-47.8868,-15.2146,Planaltina,ADM2,DISTRICT
SM264639,-52.9282,-23.0939,Planaltina do Paraná,ADM2,DISTRICT
SM267594,-40.2232,-13.2735,Planaltino,ADM2,DISTRICT
SM266816,-49.9397,-21.0041,Planalto,ADM2,DISTRICT
SM265830,-53.0895,-27.3439,Planalto,ADM2,DISTRICT
SM264640,-53.7286,-25.7268,Planalto,ADM2,DISTRICT
SM267595,-40.3802,-14.7286,Planalto,ADM2,DISTRICT
SM266249,-52.855,-27.0735,Planalto Alegre,ADM2,DISTRICT
SM263209,-54.6777,-14.5652,Planalto da Serra,ADM2,DISTRICT
SM17598,-60.9154,13.8052,Planard,ADM2,DISTRICT
SM16032,-66.9567,18.4015,Planas Barrio,ADM2,DISTRICT
SM269935,-75.5833,8.3333,Planeta Rica,ADM2,DISTRICT
SM2739,9.5599,47.1802,Planken,ADM1,DISTRICT
SM261301,117.5975,-34.6251,Plantagenet,ADM2,DISTRICT
SM13741,25.848,57.5936,Planu pagasts,ADM2,DISTRICT
SM263788,-48.6752,-20.0692,Planura,ADM2,DISTRICT
SM242626,-7.3064,4.5611,Plapo Krumen,LANGUAGE,LANGUAGE
SM301139,-89.8205,29.5442,Plaquemines,ADM2,DISTRICT
SM2740,21.1167,41.45,Plasnica,ADM1,DISTRICT
SM292762,60.6667,54.3333,Plast gorsovet,ADM2,DISTRICT
SM17599,-61.0557,13.8409,Plat Pays,ADM2,DISTRICT
SM16033,-66.2356,18.161,Plata Barrio (Aibonito),ADM2,DISTRICT
SM16034,-66.9824,18.0242,Plata Barrio (Lajas),ADM2,DISTRICT
SM16035,-67.0482,18.3268,Plata Barrio (Moca),ADM2,DISTRICT
SM11001,33.7623,35.3333,Platani,ADM2,DISTRICT
SM11002,34.0917,35.48,Platanissos,ADM2,DISTRICT
SM11003,33.0608,34.9853,Platanistasa,ADM2,DISTRICT
SM11004,32.7109,34.71,Platanisteia,ADM2,DISTRICT
SM289967,26.3826,44.3448,Plataresti,ADM2,DISTRICT
SM17600,-60.9347,14.0261,Plateau,ADM2,DISTRICT
SM2741,2.5833,7.1667,Plateau,ADM1,DISTRICT
SM2742,9.75,9.1667,Plateau (1996-),ADM1,DISTRICT
SM242627,47.1211,-19.5907,Plateau Malagasy,LANGUAGE,LANGUAGE
SM2743,-1.301,12.5877,Plateau-Central,ADM1,DISTRICT
SM2745,1.1667,7.5,Plateaux,ADM1,DISTRICT
SM2744,15.5,-2,Plateaux,ADM1,DISTRICT
SM11288,14.243,-1.608,Plateaux Department (1996-),ADM2,DISTRICT
SM266817,-50.2221,-22.6281,Platina,ADM2,DISTRICT
SM10440,-74.7455,9.7727,Plato (1996-),ADM2,DISTRICT
SM13742,23.7073,56.5375,Platones pagasts,ADM2,DISTRICT
SM303302,-104.9658,42.1331,Platte,ADM2,DISTRICT
SM301746,-97.5212,41.5713,Platte,ADM2,DISTRICT
SM301604,-94.7736,39.3805,Platte,ADM2,DISTRICT
SM282762,-98.4219,21.2962,Platón Sánchez,ADM2,DISTRICT
SM242633,-109.3539,49.578,Plautdietsch,LANGUAGE,LANGUAGE
SM4113,19.9283,42.5861,Plav (2014-),ADM1,DISTRICT
SM13743,25.7209,56.6184,Plavinas,ADM2,DISTRICT
SM4052,25.7225,56.6786,Plavinu Novads,ADM1,DISTRICT
SM256903,-7.13,4.78,Plawi,ETHNICITY,ETHNICITY
SM271089,-82.4484,23.0993,Playa,ADM2,DISTRICT
SM16036,-67.1905,18.2834,Playa Barrio (Añasco),ADM2,DISTRICT
SM16037,-66.7699,18.0072,Playa Barrio (Guayanilla),ADM2,DISTRICT
SM16038,-66.6141,17.9872,Playa Barrio (Ponce),ADM2,DISTRICT
SM16039,-66.4088,17.9545,Playa Barrio (Santa Isabel),ADM2,DISTRICT
SM16040,-65.8271,18.0751,Playa Barrio (Yabucoa),ADM2,DISTRICT
SM16041,-65.3075,18.3048,Playa Sardinas I Barrio,ADM2,DISTRICT
SM16042,-65.2863,18.2778,Playa Sardinas II Barrio,ADM2,DISTRICT
SM14366,-95.6537,17.7786,Playa Vicente (2003-),ADM2,DISTRICT
SM273443,-80.4347,-2.5541,Playas,ADM2,DISTRICT
SM14367,-117.0319,32.3434,Playas de Rosarito (1995-),ADM2,DISTRICT
SM242634,-71.0036,6.9095,Playero,LANGUAGE,LANGUAGE
SM261722,138.6421,-34.6942,Playford,ADM2,DISTRICT
SM303643,-66.6667,10.5,Plaza,ADM2,DISTRICT
SM271090,-82.3938,23.1183,Plaza de la Revolución and Playa,ADM2,DISTRICT
SM305691,15.3682,45.0779,Plaški,ADM2,DISTRICT
SM12642,-77.2903,18.011,Pleasant Valley,ADM2,DISTRICT
SM303138,-81.1606,39.3709,Pleasants,ADM2,DISTRICT
SM14087,-7.6813,4.5464,Pleebo/Sodeken District,ADM2,DISTRICT
SM14087,-7.6813,4.5464,Pleebo/Sodeken District,ADM2,DISTRICT
SM14087,-7.663,4.604,Pleebo/Sodeken District,ADM2,DISTRICT
SM14087,-7.663,4.604,Pleebo/Sodeken District,ADM2,DISTRICT
SM304053,108.0082,13.9502,Pleiku,ADM2,DISTRICT
SM290394,23.1636,44.2253,Plenita,ADM2,DISTRICT
SM290526,22.4318,46.2832,Plescuta,ADM2,DISTRICT
SM291829,26.4096,47.5204,Plesesti,ADM2,DISTRICT
SM291444,24.2421,44.4817,Plesoiu,ADM2,DISTRICT
SM268917,9.6254,46.8182,Plessur,ADM2,DISTRICT
SM288802,17.7785,51.9253,Pleszew,ADM2,DISTRICT
SM275966,17.8023,45.286,Pleternica,ADM2,DISTRICT
SM262331,24.7,43.4,Pleven,ADM2,DISTRICT
SM2747,24.6333,43.5,Pleven,ADM1,DISTRICT
SM305758,15.6212,44.8805,Plitvička Jezera,ADM2,DISTRICT
SM2748,19.3333,43.3333,Pljevlja,ADM1,DISTRICT
SM291549,26.0212,44.9428,Ploiesti,ADM2,DISTRICT
SM292143,27.244,46.6655,Plopana,ADM2,DISTRICT
SM291550,25.953,45.0441,Plopeni,ADM2,DISTRICT
SM291625,22.6424,47.1143,Plopis,ADM2,DISTRICT
SM290645,23.3905,44.7387,Plopsoru,ADM2,DISTRICT
SM291551,26.1423,45.0269,Plopu,ADM2,DISTRICT
SM291914,25.1492,44.0234,Plosca,ADM2,DISTRICT
SM290123,23.8432,46.6607,Ploscos,ADM2,DISTRICT
SM262343,24.75,42.15,Plovdiv,ADM2,DISTRICT
SM2749,24.8333,42.2667,Plovdiv,ADM1,DISTRICT
SM12643,-77.5385,17.8918,Plowden,ADM2,DISTRICT
SM276039,17.4333,43.0667,Ploče,ADM2,DISTRICT
SM297462,101.25,12.9833,Pluak Daeng,ADM2,DISTRICT
SM290855,27.1158,47.4773,Plugari,ADM2,DISTRICT
SM14368,-96.4298,15.9213,Pluma Hidalgo,ADM2,DISTRICT
SM303188,-120.8386,40.0047,Plumas,ADM2,DISTRICT
SM14149,21.85,55.9167,Plunge (2000-),ADM2,DISTRICT
SM17601,-60.9608,13.7742,Plut,ADM2,DISTRICT
SM2750,18.8169,43.1742,Plužine,ADM1,DISTRICT
SM301240,-70.7371,41.9874,Plymouth,ADM2,DISTRICT
SM300856,-96.214,42.7378,Plymouth,ADM2,DISTRICT
SM274671,-4.1333,50.3833,Plymouth,ADM1:ADM2,DISTRICT
SM271156,13.3667,49.75,Plzeň,ADM2,DISTRICT
SM271157,13.5,49.5833,Plzeň - jih,ADM2,DISTRICT
SM271158,13.25,49.9167,Plzeň - sever,ADM2,DISTRICT
SM2751,13.1667,49.5,Plzeňský,ADM1,DISTRICT
SM262785,-67.2682,-10.2943,Plácido de Castro,ADM2,DISTRICT
SM242640,92.2628,24.8237,Pnar,LANGUAGE,LANGUAGE
SM14088,-8,4.5833,Po River,ADM2,DISTRICT
SM281510,-104.0524,23.9883,Poanas,ADM2,DISTRICT
SM290189,28.419,44.2162,Poarta Alba,ADM2,DISTRICT
SM291446,24.4868,44.6834,Poboru,ADM2,DISTRICT
SM262121,2.698,7.097,Pobè,ADM2,DISTRICT
SM303139,-80.0078,38.3318,Pocahontas,ADM2,DISTRICT
SM300857,-94.6787,42.7341,Pocahontas,ADM2,DISTRICT
SM289092,128.5144,41.5708,Poch'ŏn,ADM2,DISTRICT
SM280714,127.2003,37.8937,Pocheon,ADM2,DISTRICT
SM295015,33.5,52.9167,Pochepskiy rayon,ADM2,DISTRICT
SM293780,44.8333,54.6667,Pochinkovskiy rayon,ADM2,DISTRICT
SM261124,-65.5,-31.5,Pocho,ADM2,DISTRICT
SM242643,94.1119,25.4478,Pochuri Naga,LANGUAGE,LANGUAGE
SM275403,-91.075,14.5356,Pochuta,ADM2,DISTRICT
SM242644,-96.4681,15.7351,Pochutec,LANGUAGE,LANGUAGE
SM264276,-36.0689,-7.0327,Pocinhos,ADM2,DISTRICT
SM260984,-68.5853,-31.8071,Pocito,ADM2,DISTRICT
SM9990,-38.5057,-6.5996,Poco De Jose De Moura,ADM2:ADM3,DISTRICT
SM270945,-83.6333,10.5,Pococí,ADM2,DISTRICT
SM292450,22.2959,46.6989,Pocola,ADM2,DISTRICT
SM256906,-91,15,Pocomam,ETHNICITY,ETHNICITY
SM263210,-56.6565,-16.249,Poconé,ADM2,DISTRICT
SM263792,-41.5627,-19.575,Pocrane,ADM2,DISTRICT
SM286719,-80.1647,7.6372,Pocrí,ADM2,DISTRICT
SM290395,23.7922,44.2439,Podari,ADM2,DISTRICT
SM305474,17.1846,43.3935,Podbablje,ADM2,DISTRICT
SM296213,15.577,46.1448,Podcetrtek,ADM1:ADM2,DISTRICT
SM305568,18.0117,45.2229,Podcrkavlje,ADM2,DISTRICT
SM293825,31.25,57.4167,Poddorskiy rayon,ADM2,DISTRICT
SM288872,18.9213,51.8984,Poddębice,ADM2,DISTRICT
SM242647,139.456,-2.2057,Podena,LANGUAGE,LANGUAGE
SM291093,22.555,44.8922,Podeni,ADM2,DISTRICT
SM291552,26.1902,45.1042,Podenii Noi,ADM2,DISTRICT
SM305475,17.0782,43.2398,Podgora,ADM2,DISTRICT
SM305380,18.2159,45.4576,Podgorač,ADM2,DISTRICT
SM294985,39.5833,50.5,Podgorenskiy rayon,ADM2,DISTRICT
SM289905,27.0063,45.4559,Podgoria,ADM2,DISTRICT
SM4114,19.3333,42.5,Podgorica (2018-),ADM1,DISTRICT
SM299475,30.5333,50.45,Podil's'kyi,ADM2,DISTRICT
SM18267,29.5309,47.7497,Podilsk Raion,ADM2,DISTRICT
SM2753,22.1667,50,Podkarpackie,ADM1,DISTRICT
SM2754,23,53.25,Podlaskie,ADM1,DISTRICT
SM296337,15.8665,46.3161,Podlehnik,ADM1:ADM2,DISTRICT
SM250629,14,11,Podoko,ETHNICITY,ETHNICITY
SM250629,14,11,Podoko,ETHNICITY,ETHNICITY
SM250629,12.1,11,Podoko,ETHNICITY,ETHNICITY
SM250629,12.1,11,Podoko,ETHNICITY,ETHNICITY
SM291354,26.6328,46.8177,Podoleni,ADM2,DISTRICT
SM17041,37.5833,55.4167,Podolsk Urban Okrug (2012-),ADM2,DISTRICT
SM295251,-14.5977,16.2502,Podor,ADM2,DISTRICT
SM293280,47.3333,60.1667,Podosinovskiy rayon,ADM2,DISTRICT
SM17042,34.5,61,Podporozhskiy Rayon,ADM2,DISTRICT
SM305381,17.9841,45.7846,Podravska Moslavina,ADM2,DISTRICT
SM305714,17.214,46.0039,Podravske Sesvete,ADM2,DISTRICT
SM305477,16.5511,43.4865,Podstrana,ADM2,DISTRICT
SM10532,15.8727,45.8166,Podsused - Vrapce,ADM2,DISTRICT
SM305345,16.5452,46.467,Podturen,ADM2,DISTRICT
SM290856,27.2655,47.2081,Podu Iloaiei,ADM2,DISTRICT
SM292144,27.388,46.197,Podu Turcului,ADM2,DISTRICT
SM304753,21.2,42.9,Podujevo,ADM2,DISTRICT
SM2756,20.9117,44.4842,Podunavski,ADM1:ADM2,DISTRICT
SM292145,26.5659,46.4789,Poduri,ADM2,DISTRICT
SM296283,15.3559,46.6008,Podvelka,ADM1:ADM2,DISTRICT
SM256908,35.61,-18.32,Podzo,ETHNICITY,ETHNICITY
SM291915,25.3387,44.433,Poeni,ADM2,DISTRICT
SM291177,24.2867,46.6726,Pogaceaua,ADM2,DISTRICT
SM292303,27.5536,46.3355,Pogana,ADM2,DISTRICT
SM295017,33.25,52.5,Pogarskiy rayon,ADM2,DISTRICT
SM289906,27.0118,44.9056,Pogoanele,ADM2,DISTRICT
SM242654,36.4148,-8.7228,Pogolo,LANGUAGE,LANGUAGE
SM250634,36.85,-9.97,Pogoro,ETHNICITY,ETHNICITY
SM260496,20.5965,40.9241,Pogradecit,ADM2,DISTRICT
SM260496,20.5965,40.9241,Pogradecit,ADM2,DISTRICT
SM260496,20.5833,40.9333,Pogradecit,ADM2,DISTRICT
SM260496,20.5833,40.9333,Pogradecit,ADM2,DISTRICT
SM280737,129.3667,36.0833,Pohang,ADM2,DISTRICT
SM11185,24.7919,58.6346,Pohja-Paernumaa vald,ADM2,DISTRICT
SM11186,25.3804,58.4855,Pohja-Sakala vald,ADM2,DISTRICT
SM2757,158.2056,6.964,Pohnpei,ADM1,DISTRICT
SM242656,158.223,6.8721,Pohnpeian,LANGUAGE,LANGUAGE
SM256909,-81.75,26.75,Pohoy,ETHNICITY,ETHNICITY
SM299778,29.2559,49.4876,Pohrebyshchens'kyi,ADM2,DISTRICT
SM276737,121.5958,0.7098,Pohuwato,ADM2,DISTRICT
SM290295,25.6768,44.5743,Poiana,ADM2,DISTRICT
SM291626,23.7601,47.3063,Poiana Blenchii,ADM2,DISTRICT
SM291553,25.7191,45.1208,Poiana Campina,ADM2,DISTRICT
SM292367,26.9713,45.6688,Poiana Cristei,ADM2,DISTRICT
SM291275,24.713,44.812,Poiana Lacului,ADM2,DISTRICT
SM290396,23.0627,43.9204,Poiana Mare,ADM2,DISTRICT
SM291742,23.731,45.8092,Poiana Sibiului,ADM2,DISTRICT
SM291830,25.1293,47.3112,Poiana Stampei,ADM2,DISTRICT
SM291355,25.9252,47.114,Poiana Teiului,ADM2,DISTRICT
SM289818,22.8708,46.4046,Poiana Vadului,ADM2,DISTRICT
SM291554,25.9978,44.75,Poienarii Burchii,ADM2,DISTRICT
SM291276,24.5333,45.0667,Poienarii de Arges,ADM2,DISTRICT
SM291277,25.05,45.2167,Poienarii de Muscel,ADM2,DISTRICT
SM292304,27.5461,46.5798,Poienesti,ADM2,DISTRICT
SM290124,22.8604,46.8836,Poieni,ADM2,DISTRICT
SM291019,24.4367,47.8237,Poienile De Sub Munte,ADM2,DISTRICT
SM284565,165.3415,-20.9505,Poindimié,ADM2,DISTRICT
SM302403,-90.6629,35.574,Poinsett,ADM2,DISTRICT
SM324017,-114.4887,53.5504,Point Alison,ADM2:ADM3,DISTRICT
SM2758,-61.682,10.184,Point Fortin,ADM1,DISTRICT
SM12644,-77.1048,18.0936,Point Hill,ADM2,DISTRICT
SM301140,-91.6008,30.7094,Pointe Coupee,ADM2,DISTRICT
SM2760,55.5217,-4.6774,Pointe La Rue,ADM1,DISTRICT
SM269800,11.8838,-4.7561,Pointe Noire,ADM2,DISTRICT
SM2761,11.8762,-4.7903,Pointe Noire,ADM1,DISTRICT
SM17602,-60.9931,14.0158,Pointe Seraphine,ADM2,DISTRICT
SM242658,-0.5493,46.0579,Poitevin,DIALECT,LANGUAGE
SM290040,21.5337,44.7844,Pojejena,ADM2,DISTRICT
SM305637,17.4515,43.1574,Pojezerje,ADM2,DISTRICT
SM291831,25.4218,47.5048,Pojorata,ADM2,DISTRICT
SM267597,-38.2433,-12.3659,Pojuca,ADM2,DISTRICT
SM242662,-69.5607,0.3579,Pokangá,LANGUAGE,LANGUAGE
SM242663,23.8972,0.7354,Poke,LANGUAGE,LANGUAGE
SM250636,24,0,Poke,ETHNICITY,ETHNICITY
SM284400,100.5084,6.1599,Pokok Sena,ADM2,DISTRICT
SM242665,40.2897,-2.3159,Pokomo,LANGUAGE,LANGUAGE
SM250637,42.11,0.62,Pokomo,ETHNICITY,ETHNICITY
SM250637,42.11,0.62,Pokomo,ETHNICITY,ETHNICITY
SM250637,40,-1,Pokomo,ETHNICITY,ETHNICITY
SM250637,40,-1,Pokomo,ETHNICITY,ETHNICITY
SM299370,34.9278,1.8063,Pokot,ADM1:ADM2,DISTRICT
SM250638,35.5,1.5,Pokot,ETHNICITY,ETHNICITY
SM299916,36.233,47.981,Pokrovs'kyi,ADM2,DISTRICT
SM18268,37.1719,48.2823,Pokrovsk City Council,ADM2,DISTRICT
SM305654,15.992,45.4852,Pokupsko,ADM2,DISTRICT
SM242686,16.17,1.83,Pol,LANGUAGE,LANGUAGE
SM242686,16.17,1.83,Pol,LANGUAGE,LANGUAGE
SM242686,13.446,4.243,Pol,LANGUAGE,LANGUAGE
SM242686,13.446,4.243,Pol,LANGUAGE,LANGUAGE
SM242670,14,52,Polabian,LANGUAGE,LANGUAGE
SM254073,-63.7,80.14,Polar Inuit,ETHNICITY,ETHNICITY
SM254073,-63.7,80.14,Polar Inuit,ETHNICITY,ETHNICITY
SM254073,-63.7,80.14,Polar Inuit,ETHNICITY,ETHNICITY
SM254073,-70,78,Polar Inuit,ETHNICITY,ETHNICITY
SM254073,-70,78,Polar Inuit,ETHNICITY,ETHNICITY
SM254073,-70,78,Polar Inuit,ETHNICITY,ETHNICITY
SM254073,-69.5,77.53,Polar Inuit,ETHNICITY,ETHNICITY
SM254073,-69.5,77.53,Polar Inuit,ETHNICITY,ETHNICITY
SM254073,-69.5,77.53,Polar Inuit,ETHNICITY,ETHNICITY
SM242668,-1.3909,51.7866,Polari,LANGUAGE,LANGUAGE
SM298627,37.1508,36.8369,Polateli,ADM2,DISTRICT
SM298895,32.1551,39.48,Polatlı,ADM2,DISTRICT
SM9807,29,55.5833,Polatski Rayon (excl. Polatsk and Navapolatsk)),ADM2,DISTRICT
SM305590,15.5186,44.0178,Polača,ADM2,DISTRICT
SM242671,9.5336,10.0258,Polci,LANGUAGE,LANGUAGE
SM11793,45.0833,39.1833,Poldasht,ADM2,DISTRICT
SM277562,48,33.1667,Poldokhtar,ADM2,DISTRICT
SM11005,32.5127,34.8846,Polemi,ADM2,DISTRICT
SM256912,144.24,-6.82,Poles,ETHNICITY,ETHNICITY
SM293040,21.25,54.8333,Polesskiy rayon,ADM2,DISTRICT
SM294540,60.1667,56.4167,Polevskoy gorsovet,ADM2,DISTRICT
SM276569,119.1667,-3.3,Polewali Mandar,ADM2,DISTRICT
SM276278,21.2526,47.8335,Polgár,ADM2,DISTRICT
SM270335,-77.4918,1.733,Policarpa,ADM2,DISTRICT
SM288826,14.451,53.5311,Police,ADM2,DISTRICT
SM11006,32.4302,35.0334,Polis,ADM2,DISTRICT
SM242677,18.6255,51.8439,Polish,LANGUAGE,LANGUAGE
SM256913,16.41,53.53,Polish,ETHNICITY,ETHNICITY
SM256913,16.41,53.53,Polish,ETHNICITY,ETHNICITY
SM256913,16.41,53.53,Polish,ETHNICITY,ETHNICITY
SM256913,16.92,52.24,Polish,ETHNICITY,ETHNICITY
SM256913,16.92,52.24,Polish,ETHNICITY,ETHNICITY
SM256913,16.92,52.24,Polish,ETHNICITY,ETHNICITY
SM256913,22.5667,51.25,Polish,ETHNICITY,ETHNICITY
SM256913,22.5667,51.25,Polish,ETHNICITY,ETHNICITY
SM256913,22.5667,51.25,Polish,ETHNICITY,ETHNICITY
SM242676,20.761,52.0041,Polish Sign Language,LANGUAGE,LANGUAGE
SM11007,33.2218,35.0071,Politiko,ADM2,DISTRICT
SM305591,15.3759,44.1766,Poličnik,ADM2,DISTRICT
SM303267,-92.4414,45.4614,Polk,ADM2,DISTRICT
SM302736,-94.83,30.7927,Polk,ADM2,DISTRICT
SM302268,-123.4134,44.9035,Polk,ADM2,DISTRICT
SM302611,-84.5233,35.1199,Polk,ADM2,DISTRICT
SM301747,-97.5684,41.1869,Polk,ADM2,DISTRICT
SM302003,-82.1697,35.2793,Polk,ADM2,DISTRICT
SM301605,-93.4005,37.6165,Polk,ADM2,DISTRICT
SM301395,-96.4018,47.7739,Polk,ADM2,DISTRICT
SM300858,-93.5735,41.6855,Polk,ADM2,DISTRICT
SM302404,-94.2281,34.4858,Polk,ADM2,DISTRICT
SM300361,-81.6977,27.9489,Polk,ADM2,DISTRICT
SM300398,-85.1881,34.0018,Polk,ADM2,DISTRICT
SM288659,16.0153,51.4998,Polkowice,ADM2,DISTRICT
SM14966,149.4301,7.6397,Pollap Municipality,ADM2,DISTRICT
SM242679,149.4287,7.6389,Pollapese,LANGUAGE,LANGUAGE
SM16043,-65.9996,17.9921,Pollos Barrio,ADM2,DISTRICT
SM295351,156.6537,-6.7498,Polo,ADM2,DISTRICT
SM271871,-71.318,18.1206,Polo,ADM2,DISTRICT
SM299821,36.2516,47.4791,Polohivs'kyi,ADM2,DISTRICT
SM266819,-49.8155,-20.7294,Poloni,ADM2,DISTRICT
SM2762,81,8,Polonnaruwa,ADM1:ADM2,DISTRICT
SM242681,167.09,-15.4653,Polonombauk,LANGUAGE,LANGUAGE
SM299451,27.5144,50.118,Polons'kyi,ADM2,DISTRICT
SM270662,-74.8589,10.7807,Polonuevo,ADM2,DISTRICT
SM295704,-87.7969,13.8031,Polorós,ADM2,DISTRICT
SM281955,-99.8221,20.2199,Polotitlán,ADM2,DISTRICT
SM293475,66,54.8333,Polovinskiy rayon,ADM2,DISTRICT
SM290646,23.8213,45.172,Polovragi,ADM2,DISTRICT
SM14967,149.182,7.355,Polowat Municipality,ADM2,DISTRICT
SM262471,25.5833,43.35,Polski Trambesh,ADM2,DISTRICT
SM2763,34.543,49.5921,Poltava,ADM1,DISTRICT
SM18269,34.559,49.5794,Poltava Raion,ADM2,DISTRICT
SM11187,26.0096,58.6407,Poltsamaa vald,ADM2,DISTRICT
SM296123,19.7949,48.429,Poltár,ADM2,DISTRICT
SM11188,26.9824,58.0837,Polva vald,ADM2,DISTRICT
SM295472,166.0503,-10.0814,Polynesian Outer Islands,ADM2,DISTRICT
SM11008,33.0156,34.9397,Polystypos,ADM2,DISTRICT
SM296214,15.0845,46.3043,Polzela,ADM1:ADM2,DISTRICT
SM242685,135.619,-1.6422,Pom,LANGUAGE,LANGUAGE
SM297171,100.51,13.7548,Pom Pram Sattru,ADM2,DISTRICT
SM286797,-77.4756,-8.8231,Pomabamba,ADM2,DISTRICT
SM292586,26.3209,48.0623,Pomarla,ADM2,DISTRICT
SM289248,-8.6695,39.9074,Pombal,ADM2,DISTRICT
SM9991,-37.8701,-6.8069,Pombal,ADM2,DISTRICT
SM264873,-35.4169,-8.2254,Pombos,ADM2,DISTRICT
SM266252,-49.169,-26.724,Pomerode,ADM2,DISTRICT
SM2765,-58.8333,7.0833,Pomeroon-Supenaam,ADM1,DISTRICT
SM292452,22.3002,46.7911,Pomezeu,ADM2,DISTRICT
SM291682,23.3236,47.6807,Pomi,ADM2,DISTRICT
SM288624,151.3333,-5.5,Pomio,ADM2,DISTRICT
SM17603,-60.9815,13.7579,Pomme,ADM2,DISTRICT
SM256918,-123.04,38.9,Pomo,ETHNICITY,ETHNICITY
SM256918,-123.04,38.9,Pomo,ETHNICITY,ETHNICITY
SM256918,-123,39,Pomo,ETHNICITY,ETHNICITY
SM256918,-123,39,Pomo,ETHNICITY,ETHNICITY
SM258946,16.17,1.83,Pomo-Kweso,LANGUAGE,LANGUAGE
SM2766,21.4022,43.9367,Pomoravski,ADM1:ADM2,DISTRICT
SM262403,27.55,42.7333,Pomorie,ADM2,DISTRICT
SM2767,18,54.25,Pomorskie,ADM1,DISTRICT
SM11009,32.5621,35.1475,Pomos,ADM2,DISTRICT
SM256920,-77.77,35.67,Pomouik,ETHNICITY,ETHNICITY
SM266820,-50.2012,-22.0417,Pompéia,ADM2,DISTRICT
SM263793,-44.9149,-19.1495,Pompéu,ADM2,DISTRICT
SM260997,-66.4167,-28.25,Pomán,ADM2,DISTRICT
SM242694,146.887,-1.9171,Ponam,LANGUAGE,LANGUAGE
SM256921,158.21,6.91,Ponape,ETHNICITY,ETHNICITY
SM256921,158.21,6.91,Ponape,ETHNICITY,ETHNICITY
SM256921,158.22,6.88,Ponape,ETHNICITY,ETHNICITY
SM256921,158.22,6.88,Ponape,ETHNICITY,ETHNICITY
SM242695,-71.5,4.8333,Ponares,LANGUAGE,LANGUAGE
SM293343,46.3167,58.7,Ponazyrevskiy rayon,ADM2,DISTRICT
SM256922,-99.98,43.14,Ponca,ETHNICITY,ETHNICITY
SM256922,-99.98,43.14,Ponca,ETHNICITY,ETHNICITY
SM256922,-99.98,43.14,Ponca,ETHNICITY,ETHNICITY
SM256922,-98.5,43,Ponca,ETHNICITY,ETHNICITY
SM256922,-98.5,43,Ponca,ETHNICITY,ETHNICITY
SM256922,-98.5,43,Ponca,ETHNICITY,ETHNICITY
SM256922,-99,43,Ponca,ETHNICITY,ETHNICITY
SM256922,-99,43,Ponca,ETHNICITY,ETHNICITY
SM256922,-99,43,Ponca,ETHNICITY,ETHNICITY
SM2769,-66.6185,18.073,Ponce,ADM1,DISTRICT
SM281833,-102.9252,20.3582,Poncitlán,ADM2,DISTRICT
SM301652,-112.2264,48.228,Pondera,ADM2,DISTRICT
SM235957,143.925,-4.3256,Pondi,LANGUAGE,LANGUAGE
SM250528,30,-31,Pondo,ETHNICITY,ETHNICITY
SM12645,-78.0734,18.4022,Pondside (Hanover),ADM2,DISTRICT
SM18506,-77.7945,17.9815,Pondside (Saint Elizabeth),ADM2,DISTRICT
SM270663,-74.8049,10.5833,Ponedera,ADM2,DISTRICT
SM297336,100.3878,19.185,Pong,ADM2,DISTRICT
SM297791,102.3719,12.9347,Pong Nam Ron,ADM2,DISTRICT
SM266822,-49.364,-21.7336,Pongaí,ADM2,DISTRICT
SM289149,126.25,38.1667,Pongch'ŏn,ADM2,DISTRICT
SM256924,10.1,4.16,Pongo,ETHNICITY,ETHNICITY
SM289144,125.9678,38.4947,Pongsan,ADM2,DISTRICT
SM242700,6.3745,10.0823,Pongu,LANGUAGE,LANGUAGE
SM242702,88.0925,26.8613,Pongyong,LANGUAGE,LANGUAGE
SM280464,105.8599,11.76,Ponhea Kraek,ADM2,DISTRICT
SM280518,104.8057,11.7523,Ponhea Lueu,ADM2,DISTRICT
SM262199,-3.4167,10.25,Poni (1995-),ADM1:ADM2,DISTRICT
SM291094,22.7568,44.9735,Ponoarele,ADM2,DISTRICT
SM323969,-113.5936,52.6744,Ponoka,ADM2:ADM3,DISTRICT
SM10125,-113.8745,52.7123,Ponoka County,ADM2,DISTRICT
SM289820,23.4038,46.3207,Ponor,ADM2,DISTRICT
SM276360,111.5,-7.9333,Ponorogo,ADM2,DISTRICT
SM242704,124.712,0.9172,Ponosakan,LANGUAGE,LANGUAGE
SM266253,-50.2937,-27.4257,Ponta Alta,ADM2,DISTRICT
SM289377,-25.6709,37.7423,Ponta Delgada,ADM2,DISTRICT
SM264641,-50.0738,-25.1441,Ponta Grossa,ADM2,DISTRICT
SM263075,-55.7269,-22.016,Ponta Porã,ADM2,DISTRICT
SM264217,-49.1549,-1.053,Ponta de Pedras,ADM2,DISTRICT
SM289280,-17.1037,32.7086,Ponta do Sol,ADM2,DISTRICT
SM266823,-48.0686,-20.9661,Pontal,ADM2,DISTRICT
SM263211,-52.7542,-15.9676,Pontal do Araguaia,ADM2,DISTRICT
SM264642,-48.4751,-25.6367,Pontal do Paraná,ADM2,DISTRICT
SM268137,-49.5994,-17.5087,Pontalina,ADM2,DISTRICT
SM266824,-50.5221,-20.4632,Pontalinda,ADM2,DISTRICT
SM296099,-55.2051,5.7846,Pontbuiten,ADM2,DISTRICT
SM267153,-46.6263,-12.097,Ponte Alta do Bom Jesus,ADM2,DISTRICT
SM267154,-47.1813,-10.786,Ponte Alta do Norte,ADM2,DISTRICT
SM266254,-50.4358,-27.1813,Ponte Alta do Norte,ADM2,DISTRICT
SM263212,-52.9024,-16.6505,Ponte Branca,ADM2,DISTRICT
SM263794,-42.9206,-20.407,Ponte Nova,ADM2,DISTRICT
SM265833,-52.5174,-27.6769,Ponte Preta,ADM2,DISTRICT
SM266255,-51.9107,-26.8605,Ponte Serrada,ADM2,DISTRICT
SM289358,-8.3101,41.8067,Ponte da Barca,ADM2,DISTRICT
SM289359,-8.5764,41.7468,Ponte de Lima,ADM2,DISTRICT
SM289289,-8.0542,39.2144,Ponte de Sôr,ADM2,DISTRICT
SM9992,-59.334,-15.3614,Pontes E Lacerda (1999-),ADM2,DISTRICT
SM266825,-49.759,-20.1701,Pontes Gestral,ADM2,DISTRICT
SM274048,-8.5,42.5,Pontevedra,ADM2,DISTRICT
SM242708,96.7728,27.4425,Ponthai,LANGUAGE,LANGUAGE
SM284319,103.5,1.5,Pontian,ADM2,DISTRICT
SM276390,109.1667,0.25,Pontianak,ADM2,DISTRICT
SM242705,36.0974,41.266,Pontic,LANGUAGE,LANGUAGE
SM268018,-40.4986,-18.2522,Ponto Belo,ADM2,DISTRICT
SM263795,-44.938,-16.6377,Ponto Chique,ADM2,DISTRICT
SM267599,-40.162,-10.8547,Ponto Novo,ADM2,DISTRICT
SM263796,-41.4634,-16.8544,Ponto dos Volantes,ADM2,DISTRICT
SM302230,-96.6844,34.728,Pontotoc,ADM2,DISTRICT
SM301479,-89.0374,34.2254,Pontotoc,ADM2,DISTRICT
SM265832,-52.6374,-28.0498,Pontão,ADM2,DISTRICT
SM17604,-61.0436,13.7964,Ponyon,ADM2,DISTRICT
SM293509,36.3,52.3167,Ponyrovskiy rayon,ADM2,DISTRICT
SM284566,165.4005,-21.0765,Ponérihouen,ADM2,DISTRICT
SM2770,14.8333,-3.5333,Pool,ADM1,DISTRICT
SM375372,73.8252,33.8169,Poonch,ADM2:ADM3,DISTRICT
SM276882,74.1992,33.7018,Poonch,ADM2,DISTRICT
SM262743,-66.8979,-18.4775,Poopó,ADM2,DISTRICT
SM12646,-76.5835,17.8915,Poormans Corner,ADM2,DISTRICT
SM303544,71.2,40.8667,Pop,ADM2,DISTRICT
SM303544,71.2,40.8667,Pop,ADM2,DISTRICT
SM303544,71.1148,40.8665,Pop,ADM2,DISTRICT
SM303544,71.1148,40.8665,Pop,ADM2,DISTRICT
SM299566,38.5314,48.9063,Popasnians'kyi,ADM2,DISTRICT
SM299566,38.5314,48.9063,Popasnians'kyi,ADM2,DISTRICT
SM299566,38.5563,48.6288,Popasnians'kyi,ADM2,DISTRICT
SM299566,38.5563,48.6288,Popasnians'kyi,ADM2,DISTRICT
SM269867,-76.5911,2.4806,Popayán,ADM2,DISTRICT
SM301396,-95.4445,45.586,Pope,ADM2,DISTRICT
SM300666,-88.5616,37.4128,Pope,ADM2,DISTRICT
SM302405,-93.0342,35.4476,Pope,ADM2,DISTRICT
SM13744,21.875,57.4269,Popes pagasts,ADM2,DISTRICT
SM292223,24.1101,44.9791,Popesti,ADM2,DISTRICT
SM290857,27.2617,47.137,Popesti,ADM2,DISTRICT
SM292453,22.4152,47.2209,Popesti,ADM2,DISTRICT
SM290973,26.1713,44.3801,Popesti Leordeni,ADM2,DISTRICT
SM299854,29.5,49.9167,Popil'nians'kyi,ADM2,DISTRICT
SM291743,24.0538,45.7282,Poplaca,ADM2,DISTRICT
SM324018,-114.1039,53.0046,Poplar Bay,ADM2:ADM3,DISTRICT
SM250639,26,2,Popoi,ETHNICITY,ETHNICITY
SM256927,-97.42,18.33,Popoloca,ETHNICITY,ETHNICITY
SM256927,-97.42,18.33,Popoloca,ETHNICITY,ETHNICITY
SM256927,-97.42,18.33,Popoloca,ETHNICITY,ETHNICITY
SM256927,-95,18,Popoloca,ETHNICITY,ETHNICITY
SM256927,-95,18,Popoloca,ETHNICITY,ETHNICITY
SM256927,-95,18,Popoloca,ETHNICITY,ETHNICITY
SM256927,-94.83,18.25,Popoloca,ETHNICITY,ETHNICITY
SM256927,-94.83,18.25,Popoloca,ETHNICITY,ETHNICITY
SM256927,-94.83,18.25,Popoloca,ETHNICITY,ETHNICITY
SM305382,18.6611,45.807,Popovac,ADM2,DISTRICT
SM305451,16.6278,45.5709,Popovača,ADM2,DISTRICT
SM262452,26.2333,43.35,Popovo,ADM2,DISTRICT
SM296161,20.3,49.05,Poprad,ADM2,DISTRICT
SM290858,27.5273,47.2675,Popricani,ADM2,DISTRICT
SM242713,-91.6633,15.6609,Popti',LANGUAGE,LANGUAGE
SM275205,-89.5891,16.3569,Poptún,ADM2,DISTRICT
SM266826,-50.5173,-19.9221,Populina,ADM2,DISTRICT
SM242714,-89.738,14.7325,Poqomam,LANGUAGE,LANGUAGE
SM242715,-90.5813,15.3586,Poqomchi',LANGUAGE,LANGUAGE
SM242717,-90.58,15.36,"Poqomchi', Western",LANGUAGE,LANGUAGE
SM303066,-76.3458,37.1224,Poquoson,ADM2,DISTRICT
SM267829,-40.9322,-4.761,Poranga,ADM2,DISTRICT
SM266827,-48.1354,-23.1599,Porangaba,ADM2,DISTRICT
SM268138,-49.2542,-13.3701,Porangatu,ADM2,DISTRICT
SM276828,69.7781,21.6533,Porbandar,ADM2,DISTRICT
SM265298,-41.9496,-20.8971,Porciúncula,ADM2,DISTRICT
SM277957,12.69,46.1018,Pordenone,ADM2,DISTRICT
SM262332,24.9,43.3833,Pordim,ADM2,DISTRICT
SM270880,-71.93,5.6708,Pore,ADM2,DISTRICT
SM10533,13.5965,45.2272,Porec-Parenzo (2006-),ADM2,DISTRICT
SM264644,-51.3857,-22.7467,Porecatu,ADM2,DISTRICT
SM292799,46.4167,55.1667,Poretsskiy rayon,ADM2,DISTRICT
SM276046,13.6667,45.25,Poreč (pre-2006),ADM2,DISTRICT
SM11225,22.012,61.5052,Pori (2004-),ADM2,DISTRICT
SM286522,174.8832,-41.084,Porirua,ADM2,DISTRICT
SM274541,-6.7717,61.4916,Porkeri,ADM2,DISTRICT
SM294123,29.5,57.75,Porkhovskiy rayon,ADM2,DISTRICT
SM280969,-11.0656,7.2801,Porkpa,ADM2,DISTRICT
SM261657,141.8335,-14.6571,Pormpuraaw,ADM2,DISTRICT
SM269465,-5.7184,9.305,Poro,ADM2,DISTRICT
SM242724,124.454,10.6726,Porohanon,LANGUAGE,LANGUAGE
SM291095,22.9416,44.4952,Poroina Mare,ADM2,DISTRICT
SM17043,143.4167,49.5833,Poronayskiy Rayon,ADM2,DISTRICT
SM291916,25.3576,43.9289,Poroschia,ADM2,DISTRICT
SM268920,7.0764,47.4148,Porrentruy,ADM2,DISTRICT
SM286342,24.9559,70.0521,Porsanger,ADM2,DISTRICT
SM286207,9.6568,59.1419,Porsgrunn,ADM2,DISTRICT
SM261723,138.5077,-34.848,Port Adelaide Enfield,ADM2,DISTRICT
SM17605,-61.0537,13.7802,Port Alese,ADM2,DISTRICT
SM261724,137.7136,-32.5553,Port Augusta,ADM2,DISTRICT
SM371010,47.7217,-15.7144,Port Bergé,ADM2:ADM3,DISTRICT
SM276803,-4.7499,54.0852,Port Erin,ADM1:ADM2,DISTRICT
SM2771,55.4,-4.65,Port Glaud,ADM1,DISTRICT
SM285210,7.0217,4.7776,Port Harcourt,ADM2,DISTRICT
SM261302,118.6326,-20.7926,Port Hedland,ADM2,DISTRICT
SM261725,135.8575,-34.7285,Port Lincoln,ADM2,DISTRICT
SM295490,-12.759,8.7239,Port Loko,ADM1:ADM2,DISTRICT
SM2772,57.525,-20.1667,Port Louis,ADM1,DISTRICT
SM261450,152.5192,-31.4211,Port Macquarie-Hastings,ADM2,DISTRICT
SM12647,-76.9021,18.3677,Port Maria,ADM2,DISTRICT
SM12648,-76.3429,17.8995,Port Morant,ADM2,DISTRICT
SM261270,144.9699,-37.8516,Port Phillip,ADM2,DISTRICT
SM261726,138.1071,-33.3651,Port Pirie City and Dists,ADM2,DISTRICT
SM12649,-76.7928,17.939,Port Royal,ADM2,DISTRICT
SM276804,-4.7397,54.0759,Port Saint Mary,ADM1:ADM2,DISTRICT
SM242731,167.78,-16.4952,Port Sandwich,LANGUAGE,LANGUAGE
SM242729,146.5599,-41.1884,Port Sorell,LANGUAGE,LANGUAGE
SM459120,146.5681,-41.2755,Port Sorell Tasmanian,ETHNICITY,ETHNICITY
SM261452,151.8995,-32.7286,Port Stephens,ADM2,DISTRICT
SM295178,35.393,20.023,Port Sudan,ADM2,DISTRICT
SM2773,-61.5167,10.6639,Port of Spain,ADM1,DISTRICT
SM276079,-73.8895,18.1067,Port-Salut,ADM2,DISTRICT
SM276108,-72.3366,18.4747,Port-au-Prince,ADM1:ADM2,DISTRICT
SM276095,-72.8804,19.884,Port-de-Paix,ADM2,DISTRICT
SM303268,-89.5014,44.476,Portage,ADM2,DISTRICT
SM302147,-81.1974,41.1677,Portage,ADM2,DISTRICT
SM10191,-98.2699,49.9928,Portage la Prairie,ADM2,DISTRICT
SM289290,-7.4154,39.2974,Portalegre,ADM2,DISTRICT
SM265451,-38.0021,-6.0055,Portalegre,ADM2,DISTRICT
SM2774,-7.5833,39.25,Portalegre,ADM1,DISTRICT
SM265834,-51.2357,-29.7075,Portao,ADM2,DISTRICT
SM459049,139.2043,-35.3517,Portaulun,ETHNICITY,ETHNICITY
SM267830,-39.0744,-7.5951,Porteiras,ADM2,DISTRICT
SM263797,-43.1404,-15.6869,Porteirinha,ADM2,DISTRICT
SM268139,-50.1567,-17.9024,Porteiro,ADM2,DISTRICT
SM289500,-7.7276,38.2939,Portel,ADM2,DISTRICT
SM264218,-50.8535,-2.6589,Portel,ADM2,DISTRICT
SM268140,-52.6786,-17.3561,Portelândia,ADM2,DISTRICT
SM300752,-87.0733,41.5088,Porter,ADM2,DISTRICT
SM12650,-78.0491,18.3291,Porters Mountain,ADM2,DISTRICT
SM16044,-66.8,18.2281,Portillo Barrio,ADM2,DISTRICT
SM289503,-8.5842,37.1754,Portimão,ADM2,DISTRICT
SM2775,-76.5333,18.1333,Portland,ADM1,DISTRICT
SM12651,-77.1877,17.7462,Portland Cottage,ADM2,DISTRICT
SM289303,-8.6267,41.1556,Porto,ADM2,DISTRICT
SM265097,-42.6888,-3.9456,Porto,ADM2,DISTRICT
SM2776,-8.3269,41.2285,Porto,ADM1,DISTRICT
SM262786,-67.7773,-9.6504,Porto Acre,ADM2,DISTRICT
SM9993,-51.1639,-30.1146,Porto Alegre,ADM2:ADM3,DISTRICT
SM263215,-51.7693,-10.8508,Porto Alegre do Norte,ADM2,DISTRICT
SM265096,-44.0741,-6.9593,Porto Alegre do Piauí,ADM2,DISTRICT
SM267155,-47.0345,-11.5324,Porto Alegre do Tocantins,ADM2,DISTRICT
SM264645,-49.9018,-25.539,Porto Amazonas,ADM2,DISTRICT
SM260447,13.959,-10.6149,Porto Amboim,ADM2,DISTRICT
SM264646,-52.3921,-25.5809,Porto Barreiro,ADM2,DISTRICT
SM12652,-77.8699,18.4661,Porto Bello,ADM2,DISTRICT
SM266256,-48.5953,-27.1563,Porto Belo,ADM2,DISTRICT
SM265414,-35.3734,-9.0706,Porto Calvo,ADM2,DISTRICT
SM263217,-58.9711,-15.8862,Porto Esperidião,ADM2,DISTRICT
SM263218,-57.2294,-15.5197,Porto Estrela,ADM2,DISTRICT
SM266828,-47.5229,-23.2163,Porto Feliz,ADM2,DISTRICT
SM266829,-47.4394,-21.8413,Porto Ferreira,ADM2,DISTRICT
SM263798,-43.0687,-20.6673,Porto Firme,ADM2,DISTRICT
SM262857,-47.0726,-6.3464,Porto Franco,ADM2,DISTRICT
SM267290,-51.7118,0.5655,Porto Grande,ADM2,DISTRICT
SM265836,-54.9606,-27.8466,Porto Lucena,ADM2,DISTRICT
SM265837,-54.6577,-27.5981,Porto Mauá,ADM2,DISTRICT
SM289281,-17.1758,32.8596,Porto Moniz,ADM2,DISTRICT
SM263077,-57.3431,-21.2995,Porto Murtinho,ADM2,DISTRICT
SM267156,-48.4744,-10.5283,Porto Nacional,ADM2,DISTRICT
SM2777,-25.2,17.0167,Porto Novo,ADM1,DISTRICT
SM265299,-44.3291,-22.4507,Porto Real,ADM2,DISTRICT
SM265417,-36.727,-10.1054,Porto Real do Colégio,ADM2,DISTRICT
SM264647,-53.3179,-22.829,Porto Rico,ADM2,DISTRICT
SM262858,-44.6093,-1.8552,Porto Rico do Maranhão,ADM2,DISTRICT
SM289282,-16.343,33.0715,Porto Santo,ADM2,DISTRICT
SM267600,-39.2642,-16.6718,Porto Seguro,ADM2,DISTRICT
SM266257,-51.0116,-26.3924,Porto União,ADM2,DISTRICT
SM266102,-64.3106,-9.2079,Porto Velho,ADM2,DISTRICT
SM265838,-54.9009,-27.7624,Porto Vera Cruz,ADM2,DISTRICT
SM264648,-51.2419,-26.2534,Porto Vitória,ADM2,DISTRICT
SM262787,-72.8207,-8.5247,Porto Walter,ADM2,DISTRICT
SM265839,-55.1542,-27.9332,Porto Xavier,ADM2,DISTRICT
SM267126,-37.4806,-9.9893,Porto da Folha,ADM2,DISTRICT
SM264219,-52.5962,-2.1349,Porto de Moz,ADM2,DISTRICT
SM289249,-8.8189,39.6055,Porto de Mós,ADM2,DISTRICT
SM265415,-35.3916,-9.1156,Porto de Pedras,ADM2,DISTRICT
SM265452,-36.7838,-5.0778,Porto do Mangue,ADM2,DISTRICT
SM263216,-56.8572,-11.7604,Porto dos Gaúchos,ADM2,DISTRICT
SM262116,2.6213,6.4805,Porto-Novo,ADM2,DISTRICT
SM286700,-79.6073,9.4975,Portobelo,ADM2,DISTRICT
SM256929,-8.6,39.31,Portoguese,ETHNICITY,ETHNICITY
SM256929,-8.6,39.31,Portoguese,ETHNICITY,ETHNICITY
SM256929,-7,42,Portoguese,ETHNICITY,ETHNICITY
SM256929,-7,42,Portoguese,ETHNICITY,ETHNICITY
SM273618,-79.6167,-3.7167,Portovelo,ADM2,DISTRICT
SM273498,-80.45,-1.05,Portoviejo,ADM2,DISTRICT
SM303067,-76.308,36.8365,Portsmouth,ADM2,DISTRICT
SM274673,-1.0667,50.8,Portsmouth,ADM1:ADM2,DISTRICT
SM16045,-66.683,18.1416,Portugues Barrio (Adjuntas),ADM2,DISTRICT
SM16046,-66.6174,18.0593,Portugues Barrio (Ponce),ADM2,DISTRICT
SM16047,-66.6222,18.0269,Portugues Urbano Barrio,ADM2,DISTRICT
SM2778,-69.25,9.1667,Portuguesa,ADM1,DISTRICT
SM242730,-8.1,39.91,Portuguese,LANGUAGE,LANGUAGE
SM242728,-8.5336,38.5474,Portuguese Sign Language,LANGUAGE,LANGUAGE
SM291744,24.4955,45.7508,Porumbacu De Jos,ADM2,DISTRICT
SM12653,-77.4001,18.052,Porus (Clarendon),ADM2,DISTRICT
SM18505,-77.431,18.028,Porus (Manchester),ADM2,DISTRICT
SM300062,-57.8504,-32.436,Porvenir,ADM2,DISTRICT
SM300062,-57.8504,-32.436,Porvenir,ADM2,DISTRICT
SM300062,-57.923,-32.1557,Porvenir,ADM2,DISTRICT
SM300062,-57.923,-32.1557,Porvenir,ADM2,DISTRICT
SM346792,25.6931,60.4383,Porvoo,ADM2:ADM3,DISTRICT
SM289821,23.3611,46.4535,Posaga De Jos,ADM2,DISTRICT
SM262556,18.4943,45.0532,Posavina,ADM1:ADM2,DISTRICT
SM17839,14.5054,46.0941,Posavje District,ADM2,DISTRICT
SM305592,15.4786,44.213,Posedarje,ADM2,DISTRICT
SM291555,26.1494,45.2752,Posestii-pamanteni,ADM2,DISTRICT
SM300753,-87.8685,38.0219,Posey,ADM2,DISTRICT
SM295051,39.0833,58.6667,Poshekhonskiy rayon,ADM2,DISTRICT
SM276603,120.5,-1.65,Poso,ADM2,DISTRICT
SM299050,42.7313,41.5213,Posof,ADM2,DISTRICT
SM285518,-86.9559,12.5892,Posoltega,ADM2,DISTRICT
SM292985,81.6667,51.8333,Pospelikhinskiy rayon,ADM2,DISTRICT
SM268141,-46.4696,-14.2452,Posse,ADM2,DISTRICT
SM289908,26.8679,45.2468,Posta Calnau,ADM2,DISTRICT
SM305478,16.6314,43.3753,Postira,ADM2,DISTRICT
SM296292,14.1708,45.7856,Postojna,ADM1:ADM2,DISTRICT
SM271773,-71.6167,18.5667,Postrer Rio,ADM2,DISTRICT
SM15267,125.7267,39.0311,Pot'onggang-guyok,ADM2,DISTRICT
SM11010,33.0335,35.1006,Potami,ADM2,DISTRICT
SM11011,33.457,35.0507,Potamia,ADM2,DISTRICT
SM11012,32.8057,34.8243,Potamiou,ADM2,DISTRICT
SM11013,32.9877,34.904,Potamitissa,ADM2,DISTRICT
SM256934,-82.86,29.61,Potano,ETHNICITY,ETHNICITY
SM2779,-59.3,4.9,Potaro-Siparuni,ADM1,DISTRICT
SM256935,-85.1,43.96,Potawatami,ETHNICITY,ETHNICITY
SM256935,-85.1,43.96,Potawatami,ETHNICITY,ETHNICITY
SM256935,-85,44,Potawatami,ETHNICITY,ETHNICITY
SM256935,-85,44,Potawatami,ETHNICITY,ETHNICITY
SM242741,-89.1881,40.955,Potawatomi,LANGUAGE,LANGUAGE
SM291447,24.6417,44.4799,Potcoava,ADM2,DISTRICT
SM267831,-40.0401,-7.0328,Potengi,ADM2,DISTRICT
SM277936,15.8994,40.4644,Potenza,ADM2,DISTRICT
SM11336,41.6764,42.1419,Poti,ADM2,DISTRICT
SM256937,-35.46,-5.67,Potiguara,ETHNICITY,ETHNICITY
SM256937,-38.64,-4.37,Potiguara,ETHNICITY,ETHNICITY
SM242743,-38,-7.0167,Potiguára,DIALECT,LANGUAGE
SM266830,-45.3061,-22.825,Potim,ADM2,DISTRICT
SM267601,-39.7669,-15.7012,Potiraguá,ADM2,DISTRICT
SM266831,-49.3981,-21.1074,Potirendaba,ADM2,DISTRICT
SM267832,-38.1825,-5.754,Potiretama,ADM2,DISTRICT
SM285263,11.1572,11.7106,Potiskum,ADM2,DISTRICT
SM290296,25.6016,44.5736,Potlogi,ADM2,DISTRICT
SM250452,23.97,2.91,Poto,ETHNICITY,ETHNICITY
SM250452,23.97,2.91,Poto,ETHNICITY,ETHNICITY
SM250452,22,2,Poto,ETHNICITY,ETHNICITY
SM250452,22,2,Poto,ETHNICITY,ETHNICITY
SM242748,-98.7201,17.4223,Potoichan,DIALECT,LANGUAGE
SM295618,-88.8859,13.9701,Potonico,ADM2,DISTRICT
SM285484,-85.8776,11.5785,Potosí,ADM2,DISTRICT
SM270336,-77.4216,0.7467,Potosí,ADM2,DISTRICT
SM2780,-67,-20.6667,Potosí,ADM1,DISTRICT
SM275890,-87.95,15.21,Potrerillos,ADM2,DISTRICT
SM275913,-86.762,14.0448,Potrerillos,ADM2,DISTRICT
SM12654,-77.6687,17.9187,Potsdam,ADM2,DISTRICT
SM242750,82.7586,18.5968,Pottangi Ollar Gadaba,LANGUAGE,LANGUAGE
SM302231,-96.9483,35.2067,Pottawatomie,ADM2,DISTRICT
SM300961,-96.3424,39.379,Pottawatomie,ADM2,DISTRICT
SM300859,-95.5424,41.3366,Pottawattamie,ADM2,DISTRICT
SM302737,-101.894,35.4013,Potter,ADM2,DISTRICT
SM302526,-99.9573,45.0645,Potter,ADM2,DISTRICT
SM302333,-77.8958,41.7449,Potter,ADM2,DISTRICT
SM14089,-7.8108,5.3505,Potupo District,ADM2,DISTRICT
SM263799,-41.7652,-17.7928,Poté,ADM2,DISTRICT
SM284568,164.9834,-21.1076,Pouembout,ADM2,DISTRICT
SM284569,164.0292,-20.2368,Poum,ADM2,DISTRICT
SM242753,94.4618,25.1944,Poumei Naga,LANGUAGE,LANGUAGE
SM263800,-45.9076,-22.2527,Pouso Alegre,ADM2,DISTRICT
SM263801,-44.9527,-22.1796,Pouso Alto,ADM2,DISTRICT
SM265841,-52.2216,-29.1678,Pouso Novo,ADM2,DISTRICT
SM266258,-49.9847,-27.3039,Pouso Redondo,ADM2,DISTRICT
SM2781,103.6667,12.3333,Pouthisat,ADM1,DISTRICT
SM242755,141.864,-3.727,Pouye,LANGUAGE,LANGUAGE
SM284567,164.5828,-20.3929,Pouébo,ADM2,DISTRICT
SM296170,18.45,49.1167,Považská Bystrica,ADM2,DISTRICT
SM256942,-58.8,75.83,Poven,ETHNICITY,ETHNICITY
SM305593,15.1117,44.347,Povljane,ADM2,DISTRICT
SM289378,-25.2449,37.7647,Povoação,ADM2,DISTRICT
SM17044,42.2,51.1833,Povorinskiy Rayon,ADM2,DISTRICT
SM242758,79.239,21.797,Powari,LANGUAGE,LANGUAGE
SM301653,-105.6301,45.395,Powder River,ADM2,DISTRICT
SM301654,-112.9362,46.8566,Powell,ADM2,DISTRICT
SM301107,-83.8238,37.8312,Powell,ADM2,DISTRICT
SM268758,-124.5028,49.9997,Powell River,ADM2,DISTRICT
SM300576,-112.8407,42.6937,Power,ADM2,DISTRICT
SM300860,-92.5315,41.6864,Poweshiek,ADM2,DISTRICT
SM242759,-77.2075,36.9942,Powhatan,LANGUAGE,LANGUAGE
SM303068,-77.9152,37.5502,Powhatan,ADM2,DISTRICT
SM256943,-74.92,39.33,Powhatan,ETHNICITY,ETHNICITY
SM15392,16.1987,50.7534,Powiat walbrzyski (excl. Walbrzych),ADM2,DISTRICT
SM274753,-3.4167,52.3333,Powys,ADM1:ADM2,DISTRICT
SM263219,-54.1187,-15.7564,Poxoréo,ADM2,DISTRICT
SM15183,165.164,-21.3449,Poya,ADM2,DISTRICT
SM256944,-71.41,-43.44,Poya,ETHNICITY,ETHNICITY
SM242762,-73.0758,-7.4691,Poyanáwa,LANGUAGE,LANGUAGE
SM256945,145.96,-42.94,Poyndu,ETHNICITY,ETHNICITY
SM282765,-97.4211,20.5275,Poza Rica de Hidalgo,ADM2,DISTRICT
SM298134,34.8806,37.4367,Pozantı,ADM2,DISTRICT
SM16049,-66.4778,18.2714,Pozas Barrio (Ciales),ADM2,DISTRICT
SM16048,-67.0185,18.3326,Pozas Barrio (San Sebastián),ADM2,DISTRICT
SM288804,16.8654,52.3244,Poznań,ADM2,DISTRICT
SM288803,16.9015,52.4006,Poznań (City),ADM2,DISTRICT
SM256946,-75.24,5.92,Pozo,ETHNICITY,ETHNICITY
SM16050,-66.1683,17.9988,Pozo Hondo Barrio,ADM2,DISTRICT
SM266818,-46.3466,-23.5346,Poá,ADM2,DISTRICT
SM270899,-84.2333,10.0833,Poás,ADM2,DISTRICT
SM265448,-35.7072,-5.57,Poço Branco,ADM2,DISTRICT
SM265449,-38.5336,-6.3879,Poço Dantas,ADM2,DISTRICT
SM263789,-45.9883,-21.8207,Poço Fundo,ADM2,DISTRICT
SM267124,-37.7761,-9.8803,Poço Redondo,ADM2,DISTRICT
SM267125,-38.1505,-10.8164,Poço Verde,ADM2,DISTRICT
SM265831,-51.6697,-29.4534,Poço das Antas,ADM2,DISTRICT
SM265413,-37.3199,-9.2926,Poço das Trincheiras,ADM2,DISTRICT
SM263790,-46.5357,-21.7934,Poços de Caldas,ADM2,DISTRICT
SM264872,-36.7227,-8.2139,Poção,ADM2,DISTRICT
SM262856,-44.9123,-4.785,Poção de Pedras,ADM2,DISTRICT
SM267596,-40.2762,-14.5829,Poções,ADM2,DISTRICT
SM275967,17.6667,45.3333,Požega,ADM2,DISTRICT
SM2782,17.6667,45.4167,Požeško-Slavonska,ADM1,DISTRICT
SM297132,102.6979,15.5591,Pra Thai,ADM2,DISTRICT
SM275082,-15.7434,11.8149,Prabis,ADM2,DISTRICT
SM276665,104.2229,-3.462,Prabumulih,ADM2,DISTRICT
SM297407,101.5744,14.2281,Prachantakham,ADM2,DISTRICT
SM271151,13.8667,49,Prachatice,ADM2,DISTRICT
SM2783,101.6667,14.0833,Prachin Buri (1993-),ADM1,DISTRICT
SM2784,99.75,12.2167,Prachuap Khiri Khan,ADM1,DISTRICT
SM266833,-51.0803,-21.84,Pracinha,ADM2,DISTRICT
SM267291,-51.2449,1.6708,Pracuúba,ADM2,DISTRICT
SM270624,-76.1716,3.4279,Pradera,ADM2,DISTRICT
SM270577,-74.8649,3.7322,Prado,ADM2,DISTRICT
SM267602,-39.2336,-17.3166,Prado,ADM2,DISTRICT
SM264649,-51.3791,-23.0244,Prado Ferreira,ADM2,DISTRICT
SM263803,-44.0648,-21.1076,Prados,ADM2,DISTRICT
SM266834,-48.0759,-21.3437,Pradópolis,ADM2,DISTRICT
SM10109,104.8575,11.663,Praek Pnov,ADM2,DISTRICT
SM2785,14.4667,50.0833,Prague,ADM1,DISTRICT
SM271194,14.7084,50.1177,Praha - východ,ADM2,DISTRICT
SM271184,14.3333,49.8333,Praha - západ,ADM2,DISTRICT
SM271161,14.4217,50.088,Praha 1,ADM2,DISTRICT
SM271172,14.4612,50.0679,Praha 10,ADM2,DISTRICT
SM271176,14.5072,50.0318,Praha 11,ADM2,DISTRICT
SM271177,14.4046,50.0056,Praha 12,ADM2,DISTRICT
SM271178,14.3423,50.0516,Praha 13,ADM2,DISTRICT
SM271179,14.5573,50.1068,Praha 14,ADM2,DISTRICT
SM271180,14.5316,50.0524,Praha 15,ADM2,DISTRICT
SM271181,14.3611,49.9836,Praha 16,ADM2,DISTRICT
SM271182,14.3081,50.0654,Praha 17,ADM2,DISTRICT
SM271162,14.5123,50.1366,Praha 18,ADM2,DISTRICT
SM271163,14.5474,50.1342,Praha 19,ADM2,DISTRICT
SM271164,14.436,50.0755,Praha 2,ADM2,DISTRICT
SM271165,14.6112,50.1157,Praha 20,ADM2,DISTRICT
SM271166,14.669,50.0752,Praha 21,ADM2,DISTRICT
SM271167,14.5986,50.0326,Praha 22,ADM2,DISTRICT
SM271168,14.4523,50.0851,Praha 3,ADM2,DISTRICT
SM271169,14.4392,50.0621,Praha 4,ADM2,DISTRICT
SM271170,14.4029,50.0742,Praha 5,ADM2,DISTRICT
SM271171,14.3954,50.1009,Praha 6,ADM2,DISTRICT
SM271173,14.4245,50.0998,Praha 7,ADM2,DISTRICT
SM271174,14.475,50.1067,Praha 8,ADM2,DISTRICT
SM271175,14.5016,50.1075,Praha 9,ADM2,DISTRICT
SM2786,26,45.1667,Prahova,ADM1,DISTRICT
SM242434,101.218,19.0383,Prai,LANGUAGE,LANGUAGE
SM2787,-23.5167,14.95,Praia,ADM1,DISTRICT
SM266835,-46.5229,-24.0151,Praia Grande,ADM2,DISTRICT
SM266259,-50.0341,-29.2032,Praia Grande,ADM2,DISTRICT
SM267157,-47.7973,-5.4696,Praia Norte,ADM2,DISTRICT
SM289379,-27.131,38.7406,Praia da Vitória,ADM2,DISTRICT
SM290709,25.1472,46.5484,Praid,ADM2,DISTRICT
SM264220,-53.4933,-2.2015,Prainha,ADM2,DISTRICT
SM301656,-105.3779,46.8605,Prairie,ADM2,DISTRICT
SM302406,-91.5528,34.8298,Prairie,ADM2,DISTRICT
SM10192,-99.6574,49.4577,Prairie Lakes,ADM2,DISTRICT
SM11014,32.7455,34.8453,Praitori,ADM2,DISTRICT
SM292587,27.0239,47.4958,Prajeni,ADM2,DISTRICT
SM292224,24.1825,45.0817,Prajila,ADM2,DISTRICT
SM297172,100.6123,13.6971,Prakanong,ADM2,DISTRICT
SM277030,79.5,15.5,Prakasam,ADM2,DISTRICT
SM297424,103.0605,14.6104,Prakhon Chai,ADM2,DISTRICT
SM297439,99.6969,12.3904,Pran Buri,ADM2,DISTRICT
SM264650,-53.6968,-25.9659,Pranchita,ADM2,DISTRICT
SM297562,104.0374,14.8544,Prang Ku,ADM2,DISTRICT
SM256948,139.15,-4.64,Pranje,ETHNICITY,ETHNICITY
SM297625,99.1052,8.5512,Prasaeng,ADM2,DISTRICT
SM280433,103.9769,13.2103,Prasat Bakong,ADM2,DISTRICT
SM280494,104.8449,13.0714,Prasat Balangk,ADM2,DISTRICT
SM280495,105.1416,12.8641,Prasat Sambour,ADM2,DISTRICT
SM17606,-60.8965,13.8847,Praslin,ADM2,DISTRICT
SM11015,33.7617,35.163,Prastio (Famagusta),ADM2,DISTRICT
SM18504,32.9342,35.1773,Prastio (Nicosia),ADM2,DISTRICT
SM242770,70.8004,35.1731,Prasuni,LANGUAGE,LANGUAGE
SM242770,70.8004,35.1731,Prasuni,LANGUAGE,LANGUAGE
SM242770,70.9228,35.4205,Prasuni,LANGUAGE,LANGUAGE
SM242770,70.9228,35.4205,Prasuni,LANGUAGE,LANGUAGE
SM264280,-37.0941,-7.7125,Prata,ADM2,DISTRICT
SM263804,-48.9436,-19.3735,Prata,ADM2,DISTRICT
SM265098,-42.1533,-5.711,Prata do Piauí,ADM2,DISTRICT
SM277182,74.78,24.03,"Pratapgarh, RJ",ADM2,DISTRICT
SM277324,81.75,25.75,"Pratapgarh, UP",ADM2,DISTRICT
SM263806,-46.4288,-19.747,Pratinha,ADM2,DISTRICT
SM277916,11.0969,43.8805,Prato,ADM2,DISTRICT
SM300962,-98.7396,37.6477,Pratt,ADM2,DISTRICT
SM263805,-46.8445,-20.7857,Pratápolis,ADM2,DISTRICT
SM266836,-48.687,-22.8174,Pratânia,ADM2,DISTRICT
SM13745,26.3695,56.8094,Praulienas pagasts,ADM2,DISTRICT
SM293041,21.25,54.4167,Pravdinskiy rayon,ADM2,DISTRICT
SM262425,23.9833,42.9667,Pravets,ADM2,DISTRICT
SM293814,44.6667,43.1667,Pravoberezhnyy rayon,ADM2,DISTRICT
SM297173,100.6945,13.7174,Prawet,ADM2,DISTRICT
SM283259,-105.9436,31.3003,Praxedis G. Guerrero,ADM2,DISTRICT
SM242771,101.021,19.4306,Pray 3,LANGUAGE,LANGUAGE
SM280360,105.8783,12.472,Preaek Prasab,ADM2,DISTRICT
SM280353,103.2475,13.5727,Preah Netr Preah,ADM2,DISTRICT
SM280395,105.375,11.0869,Preah Sdach,ADM2,DISTRICT
SM2788,105,13.75,Preah Vihéar,ADM1:ADM2,DISTRICT
SM302148,-84.648,39.7416,Preble,ADM2,DISTRICT
SM296215,15.0927,46.2173,Prebold,ADM1:ADM2,DISTRICT
SM296193,14.4644,46.327,Preddvor,ADM1:ADM2,DISTRICT
SM291557,26.1061,45.1889,Predeal,ADM2,DISTRICT
SM292643,25.5878,45.4968,Predeal,ADM2,DISTRICT
SM17966,0.7667,6.7333,Prefecture d'Agou,ADM2,DISTRICT
SM17967,1.4333,8.0833,Prefecture d'Est-Mono,ADM2,DISTRICT
SM17968,0.75,9.25,Prefecture de Bassar (1995-),ADM2,DISTRICT
SM17969,0.9167,8.1667,Prefecture de Blitta,ADM2,DISTRICT
SM17970,0.5833,9.7,Prefecture de Dankpen,ADM2,DISTRICT
SM17971,0.7,7.2833,Prefecture de Danyi,ADM2,DISTRICT
SM17972,0.6167,10.8667,Prefecture de Kpendjal,ADM2,DISTRICT
SM17973,0.8333,8.75,Prefecture de Sotouboua (1995-),ADM2,DISTRICT
SM17974,0.1333,10.6667,Prefecture de Tandjouare,ADM2,DISTRICT
SM17975,0.1667,10.9167,Prefecture de Tone (1995-),ADM2,DISTRICT
SM17976,1.3333,7.5,Prefecture de l'Ogou (1995-),ADM2,DISTRICT
SM17977,0.5833,10.3333,Prefecture de l'Oti (1995-),ADM2,DISTRICT
SM17978,1.25,7.0833,Prefecture du Haho (1995-),ADM2,DISTRICT
SM17979,0.75,7.0833,Prefecture du Kloto (1995-),ADM2,DISTRICT
SM17980,1.55,7.2,Prefecture du Moyen-Mono,ADM2,DISTRICT
SM276063,15.7483,46.1648,Pregrada,ADM2,DISTRICT
SM13746,26.7214,56.2922,Preili,ADM2,DISTRICT
SM3986,26.6939,56.2733,Preili Municipality,ADM1,DISTRICT
SM13747,26.6933,56.2856,Preilu pagasts,ADM2,DISTRICT
SM292644,25.7867,45.7316,Prejmer,ADM2,DISTRICT
SM290398,23.8718,44.5913,Prejoi,ADM2,DISTRICT
SM305595,15.1859,44.08,Preko,ADM2,DISTRICT
SM275954,16.6179,46.3339,Prelog,ADM2,DISTRICT
SM10333,15.0099,12.1477,Premier Arrondissement,ADM2,DISTRICT
SM256951,145.1,-41.14,Premingana,ETHNICITY,ETHNICITY
SM256951,145.1,-41.14,Premingana,ETHNICITY,ETHNICITY
SM256951,145.1,-41.14,Premingana,ETHNICITY,ETHNICITY
SM256951,146,-42,Premingana,ETHNICITY,ETHNICITY
SM256951,146,-42,Premingana,ETHNICITY,ETHNICITY
SM256951,146,-42,Premingana,ETHNICITY,ETHNICITY
SM256951,145.21,-41.38,Premingana,ETHNICITY,ETHNICITY
SM256951,145.21,-41.38,Premingana,ETHNICITY,ETHNICITY
SM256951,145.21,-41.38,Premingana,ETHNICITY,ETHNICITY
SM301480,-88.5201,34.6183,Prentiss,ADM2,DISTRICT
SM268878,-75.2493,45.4168,Prescott and Russell,ADM2,DISTRICT
SM305622,16.387,45.9746,Preseka,ADM2,DISTRICT
SM261090,-59.75,-27,Presidencia de la Plaza,ADM2,DISTRICT
SM266837,-49.4059,-22.1095,Presidente Alves,ADM2,DISTRICT
SM266838,-51.6443,-22.131,Presidente Bernardes,ADM2,DISTRICT
SM263807,-43.1621,-20.7733,Presidente Bernardes,ADM2,DISTRICT
SM266260,-51.78,-27.2365,Presidente Castelo Branco,ADM2,DISTRICT
SM264651,-52.1451,-23.2621,Presidente Castelo Branco,ADM2,DISTRICT
SM262860,-44.5051,-5.3048,Presidente Dutra,ADM2,DISTRICT
SM267603,-41.9856,-11.295,Presidente Dutra,ADM2,DISTRICT
SM266839,-52.1753,-21.9184,Presidente Epitácio,ADM2,DISTRICT
SM267344,-59.9832,-1.2834,Presidente Figueiredo,ADM2,DISTRICT
SM289658,-54.6415,-25.5967,Presidente Franco,ADM2,DISTRICT
SM266261,-49.714,-27.0619,Presidente Getúlio,ADM2,DISTRICT
SM2789,-58.8333,-23.5,Presidente Hayes,ADM1,DISTRICT
SM265453,-35.6685,-6.1423,Presidente Juscelino,ADM2,DISTRICT
SM263808,-44.0777,-18.7388,Presidente Juscelino,ADM2,DISTRICT
SM262861,-44.0774,-3.0861,Presidente Juscelino,ADM2,DISTRICT
SM267604,-41.7862,-14.8294,Presidente Jânio Quadros,ADM2,DISTRICT
SM267158,-48.4408,-8.4826,Presidente Kennedy,ADM2,DISTRICT
SM268019,-41.0729,-21.15,Presidente Kennedy,ADM2,DISTRICT
SM263809,-43.5798,-18.6498,Presidente Kubitschek,ADM2,DISTRICT
SM265842,-51.1929,-29.5278,Presidente Lucena,ADM2,DISTRICT
SM266103,-61.9407,-11.1852,Presidente Médici,ADM2,DISTRICT
SM262862,-45.8413,-2.3873,Presidente Médici,ADM2,DISTRICT
SM266263,-49.3227,-27.2632,Presidente Nereu,ADM2,DISTRICT
SM263810,-46.4063,-18.1619,Presidente Olegário,ADM2,DISTRICT
SM266840,-51.3242,-21.9583,Presidente Prudente,ADM2,DISTRICT
SM261125,-63.5,-34.25,Presidente Roque Sáenz Peña,ADM2,DISTRICT
SM262863,-45.4393,-2.6207,Presidente Sarney,ADM2,DISTRICT
SM267605,-39.4213,-13.4615,Presidente Tancredo Neves,ADM2,DISTRICT
SM262864,-44.0102,-3.4239,Presidente Vargas,ADM2,DISTRICT
SM266841,-51.8364,-21.7755,Presidente Venceslau,ADM2,DISTRICT
SM302738,-104.2405,29.9998,Presidio,ADM2,DISTRICT
SM262378,26.8667,43.1833,Preslav,ADM2,DISTRICT
SM13224,67.1667,54.6667,Presnovka Audany,ADM2,DISTRICT
SM301323,-83.8435,45.3984,Presque Isle,ADM2,DISTRICT
SM4416,-2.0167,5.5667,Prestea-Huni Valley,ADM2,DISTRICT
SM303140,-79.6682,39.4693,Preston,ADM2,DISTRICT
SM296284,14.9012,46.552,Prevalje,ADM1:ADM2,DISTRICT
SM280474,105.2305,12.0858,Prey Chhor,ADM2,DISTRICT
SM280456,104.9326,11.1436,Prey Kabbas,ADM2,DISTRICT
SM280366,103.7885,10.687,Prey Nob,ADM2,DISTRICT
SM2791,105.4667,11.3667,Prey Vêng,ADM1,DISTRICT
SM296162,21.25,49,Prešov,ADM2,DISTRICT
SM2790,21.25,49.1667,Prešovský,ADM1,DISTRICT
SM305479,16.2313,43.6071,Prgomet,ADM2,DISTRICT
SM295096,118.5,50.75,Priargunskiy rayon,ADM2,DISTRICT
SM295117,108,52.5,Pribaykal'skiy rayon,ADM2,DISTRICT
SM305346,16.4779,46.3937,Pribislavec,ADM2,DISTRICT
SM291279,25.0868,44.8773,Priboieni,ADM2,DISTRICT
SM303269,-90.3614,45.6804,Price,ADM2,DISTRICT
SM13748,21.5992,56.4469,Priekule,ADM2,DISTRICT
SM3960,21.565,56.4325,Priekules Novads,ADM1,DISTRICT
SM13749,21.6288,56.4584,Priekules pagasts,ADM2,DISTRICT
SM4029,25.4636,57.3542,Priekuli Municipality,ADM1,DISTRICT
SM13750,25.3919,57.3312,Priekulu pagasts,ADM2,DISTRICT
SM281332,23.95,54.6333,Prienų,ADM2,DISTRICT
SM296171,18.6333,48.7667,Prievidza,ADM2,DISTRICT
SM290041,22.129,44.9477,Prigor,ADM2,DISTRICT
SM290647,23.6845,45.0571,Prigoria,ADM2,DISTRICT
SM11735,44.8333,43.0833,Prigorodnyy rayon,ADM2,DISTRICT
SM313187,16.7872,44.9503,Prijedor,ADM2:ADM3,DISTRICT
SM293115,42,44.0833,Prikubanskiy rayon,ADM2,DISTRICT
SM2792,21.6758,41.2878,Prilep,ADM1,DISTRICT
SM293325,49.3333,60.3333,Priluzskiy rayon,ADM2,DISTRICT
SM264874,-35.3754,-8.3368,Primavera,ADM2,DISTRICT
SM264221,-47.1104,-0.9495,Primavera,ADM2,DISTRICT
SM266104,-61.3061,-11.9294,Primavera de Rondônia,ADM2,DISTRICT
SM263220,-54.195,-15.1406,Primavera do Leste,ADM2,DISTRICT
SM262865,-43.329,-2.6508,Primeira Cruz,ADM2,DISTRICT
SM264652,-51.089,-22.871,Primeiro de Maio,ADM2,DISTRICT
SM16051,-66.6195,18.0079,Primero Barrio,ADM2,DISTRICT
SM271077,-78.4331,21.945,Primero de Enero,ADM2,DISTRICT
SM289533,-56.9709,-25.1271,Primero de Marzo,ADM2,DISTRICT
SM261091,-58.9167,-27.25,Primero de Mayo,ADM2,DISTRICT
SM2793,135,45,Primor'ye,ADM1,DISTRICT
SM305480,16.1666,43.6407,Primorski Dolac,ADM2,DISTRICT
SM17046,41,65,Primorskiy Rayon (Arkhangel’skaya),ADM2,DISTRICT
SM17045,30.2409,59.9962,Primorskiy Rayon (Sankt-Peterburg),ADM2,DISTRICT
SM262404,27.6333,42.25,Primorsko,ADM2,DISTRICT
SM293388,38.3413,45.8898,Primorsko-Akhtarskiy rayon,ADM2,DISTRICT
SM2794,14.594,45.454,Primorsko-Goranska,ADM1,DISTRICT
SM305435,15.9254,43.5866,Primošten,ADM2,DISTRICT
SM268623,-64.082,46.7502,Prince,ADM2,DISTRICT
SM302938,-78.4411,37.2243,Prince Edward,ADM2,DISTRICT
SM268879,-77.2495,44.0001,Prince Edward,ADM2,DISTRICT
SM2795,-63.2984,46.3981,Prince Edward Island,ADM1,DISTRICT
SM302939,-77.2241,37.1865,Prince George,ADM2,DISTRICT
SM301221,-76.8473,38.8295,Prince George's,ADM2,DISTRICT
SM302940,-77.4777,38.7017,Prince William,ADM2,DISTRICT
SM301182,-132.8027,55.597,Prince of Wales-Outer Ketchi (pre-2008),ADM2,DISTRICT
SM2796,-61.3818,10.2675,Princes Town (1992-),ADM1,DISTRICT
SM266264,-53.6248,-26.4322,Princesa,ADM2,DISTRICT
SM264281,-38.0426,-7.6487,Princesa Isabel,ADM2,DISTRICT
SM2797,-6,43.3333,Principado de Asturias,ADM1,DISTRICT
SM242783,7.392,1.614,Principense,LANGUAGE,LANGUAGE
SM276440,104.9345,-5.4221,Pringsewu,ADM2,DISTRICT
SM285408,-83.9581,13.5375,Prinzapolka,ADM2,DISTRICT
SM17047,34.3333,61.5,Prionezhskiy Rayon,ADM2,DISTRICT
SM12655,-77.2455,18.4366,Priory,ADM2,DISTRICT
SM17048,30.1352,61.039,Priozersky,ADM2,DISTRICT
SM290460,27.4394,46.1015,Priponesti,ADM2,DISTRICT
SM290859,27.908,47.0702,Prisacani,ADM2,DISTRICT
SM291448,24.4379,44.5121,Priseaca,ADM2,DISTRICT
SM293510,36.75,51.25,Pristenskiy rayon,ADM2,DISTRICT
SM2799,21.1649,42.6631,Pristina,ADM1,DISTRICT
SM291096,22.712,44.2208,Pristol,ADM2,DISTRICT
SM293476,65.3333,55,Pritobol'ny rayon,ADM2,DISTRICT
SM13225,51.75,51.4167,Priural'e Audany,ADM2,DISTRICT
SM17049,67.6667,66.6667,Priural'skiy Rayon,ADM2,DISTRICT
SM305544,18.8373,45.197,Privlaka,ADM2,DISTRICT
SM305596,15.1184,44.2617,Privlaka,ADM2,DISTRICT
SM292933,41.3333,57.3333,Privolzhskiy rayon,ADM2,DISTRICT
SM293845,48.1667,46.25,Privolzhskiy rayon,ADM2,DISTRICT
SM293066,44,46,Priyutnenskiy rayon,ADM2,DISTRICT
SM2800,20.7423,42.2157,Prizren,ADM1,DISTRICT
SM13362,20.7333,42.2333,Prizren (2008-),ADM2,DISTRICT
SM313188,17.6528,44.8589,Prnjavor,ADM2:ADM3,DISTRICT
SM2801,22.15,41.9667,Probištip,ADM1,DISTRICT
SM276362,113.3167,-7.8667,Probolinggo,ADM2,DISTRICT
SM290861,27.4945,47.3879,Probota,ADM2,DISTRICT
SM13751,25.928,56.0516,Prodes pagasts,ADM2,DISTRICT
SM11016,32.8393,34.9412,Prodromos,ADM2,DISTRICT
SM290297,25.5047,44.7034,Produlesti,ADM2,DISTRICT
SM289468,-7.926,39.757,Proença-a-Nova,ADM2,DISTRICT
SM268142,-49.2557,-17.2941,Professor Jamil,ADM2,DISTRICT
SM18356,-56.242,-34.6535,Progreso,ADM2,DISTRICT
SM282993,-89.6205,21.2352,Progreso,ADM2,DISTRICT
SM283296,-101.0795,27.4627,Progreso,ADM2,DISTRICT
SM281698,-99.1891,20.292,Progreso de Obregón,ADM2,DISTRICT
SM282766,-97.7002,20.2754,Progreso de Zaragoza,ADM2,DISTRICT
SM265843,-52.3096,-29.2287,Progresso,ADM2,DISTRICT
SM17050,44,43.8333,Prokhladnenskiy Rayon,ADM2,DISTRICT
SM294681,36.7488,51.0045,Prokhorovskiy rayon,ADM2,DISTRICT
SM17051,86.6667,54,Prokop'yevskiy Rayon,ADM2,DISTRICT
SM305481,17.1551,43.4669,Proložac,ADM2,DISTRICT
SM305436,16.0974,43.958,Promina,ADM2,DISTRICT
SM266842,-49.8851,-21.5289,Promissão,ADM2,DISTRICT
SM293160,85.5,54.9167,Promyshlennovskiy rayon,ADM2,DISTRICT
SM267127,-36.7858,-10.2538,Propriá,ADM2,DISTRICT
SM261728,138.6004,-34.8846,Prospect,ADM2,DISTRICT
SM12656,-77.5678,17.9824,Prospect (Manchester),ADM2,DISTRICT
SM18501,-76.4355,18.1748,Prospect (Portland),ADM2,DISTRICT
SM18502,-77.0412,18.4127,Prospect (Saint Mary),ADM2,DISTRICT
SM18503,-76.3496,17.874,Prospect (Saint Thomas),ADM2,DISTRICT
SM271239,17.0333,49.5,Prostějov,ADM2,DISTRICT
SM288936,20.2841,50.1924,Proszowice,ADM2,DISTRICT
SM275773,-88.6042,15.0672,Protección,ADM2,DISTRICT
SM265844,-51.4962,-28.7441,Protásio Alves,ADM2,DISTRICT
SM262464,27.4,43.1833,Provadiya,ADM2,DISTRICT
SM2802,6.0833,43.9167,Provence-Alpes-Côte d'Azur (1975-),ADM1,DISTRICT
SM242790,6,44,Provençal,DIALECT,LANGUAGE
SM302430,-71.5786,41.8714,Providence,ADM2,DISTRICT
SM270439,-81.3747,13.3848,Providencia,ADM2,DISTRICT
SM270337,-77.6047,1.242,Providencia,ADM2,DISTRICT
SM242791,-81.3739,13.3484,Providencia Sign Language,LANGUAGE,LANGUAGE
SM292789,-175,65.25,Providenskiy rayon,ADM2,DISTRICT
SM4726,87.1802,27.2301,Province 1,ADM1,DISTRICT
SM4118,85.61,26.9424,Province 2,ADM1,DISTRICT
SM4119,85.3954,27.6525,Province 3,ADM1,DISTRICT
SM4803,84.0381,28.3843,Province 4,ADM1,DISTRICT
SM4120,82.524,28.0995,Province 5,ADM1,DISTRICT
SM4121,82.4769,29.2991,Province 6,ADM1,DISTRICT
SM4213,81.0564,6.9847,Province of Uva,ADM1,DISTRICT
SM9836,-66.8128,-18.8969,Provincia Abaroa (1984-),ADM2,DISTRICT
SM9837,-67.006,-10.6462,Provincia Abuna,ADM2,DISTRICT
SM9838,-58.8748,-17.3089,Provincia Angel Sandoval,ADM2,DISTRICT
SM9839,-65.6406,-17.5909,Provincia Arani (1986-),ADM2,DISTRICT
SM9840,-66.5111,-17.8175,Provincia Arque,ADM2,DISTRICT
SM9841,-67.8081,-18.339,Provincia Carangas (1980-),ADM2,DISTRICT
SM9842,-67.1546,-17.8372,Provincia Cercado (1980-),ADM2,DISTRICT
SM9843,-60.5963,-17.851,Provincia Chiquitos (1984-),ADM2,DISTRICT
SM9844,-63.3876,-13.4504,Provincia Itenez,ADM2,DISTRICT
SM9845,-68.5955,-11.1757,Provincia Nicolas Suarez,ADM2,DISTRICT
SM9846,-67.4285,-20.9685,Provincia Nor Lipez (1985-),ADM2,DISTRICT
SM9847,-67.7071,-16.1949,Provincia Nor Yungas (1992-),ADM2,DISTRICT
SM9848,-62.1047,-16.1241,Provincia Nuflo de Chavez,ADM2,DISTRICT
SM9849,-68.9288,-17.4775,Provincia Pacajes (1986-),ADM2,DISTRICT
SM9850,-68.5833,-19,Provincia Sabaya,ADM2,DISTRICT
SM9851,-68.5969,-18.2094,Provincia Sajama (1980-),ADM2,DISTRICT
SM9852,-68.1663,-17.8558,Provincia San Pedro de Totora,ADM2,DISTRICT
SM9853,-65.7864,-11.468,Provincia Vaca Diez,ADM2,DISTRICT
SM9854,-60.9325,-15.5675,Provincia Velasco,ADM2,DISTRICT
SM15389,-76.1023,-5.8128,Provincia de Alto Amazonas (2005-),ADM2,DISTRICT
SM10351,-70.0873,-20.2416,Provincia de Iquique (2007-),ADM2,DISTRICT
SM10352,-71.3947,-33.0438,Provincia de Marga Marga,ADM2,DISTRICT
SM15390,-73.6667,-3,Provincia de Maynas (2014-),ADM2,DISTRICT
SM10353,-71.1796,-32.8075,Provincia de Quillota (2010-),ADM2,DISTRICT
SM10354,-71.5924,-33.0611,Provincia de Valparaiso (2010-),ADM2,DISTRICT
SM12080,8.5638,39.2769,Provincia del Sud Sardegna,ADM2,DISTRICT
SM10355,-69.8161,-20.2645,Provincia del Tamarugal,ADM2,DISTRICT
SM12081,9.0912,39.245,Provincia di Cagliari (2016-),ADM2,DISTRICT
SM12082,9.371,40.214,Provincia di Nuoro (2016-,ADM2,DISTRICT
SM12083,8.7313,40.0168,Provincia di Oristano (2016-,ADM2,DISTRICT
SM12084,8.7589,40.7701,Provincia di Sassari (2016-,ADM2,DISTRICT
SM291558,25.6779,45.1119,Provita De Jos,ADM2,DISTRICT
SM291559,25.6345,45.136,Provita De Sus,ADM2,DISTRICT
SM323939,-110.258,52.3544,Provost,ADM2:ADM3,DISTRICT
SM323940,-110.6299,52.4048,Provost No. 52,ADM2:ADM3,DISTRICT
SM303364,-102.3934,37.9552,Prowers,ADM2,DISTRICT
SM274873,-1.2891,8.0445,Pru,ADM2,DISTRICT
SM263811,-44.1136,-19.4681,Prudente de Morais,ADM2,DISTRICT
SM264653,-51.1107,-25.181,Prudentópolis,ADM2,DISTRICT
SM288995,17.74,50.4098,Prudnik,ADM2,DISTRICT
SM292225,24.2486,44.7346,Prundeni,ADM2,DISTRICT
SM290587,26.2121,44.0833,Prundu,ADM2,DISTRICT
SM292516,24.7245,47.2215,Prundu Bargaului,ADM2,DISTRICT
SM291097,22.9048,44.6003,Prunisor,ADM2,DISTRICT
SM288966,20.8099,52.1009,Pruszków,ADM2,DISTRICT
SM262573,24.5833,52.6667,Pruzhany,ADM2,DISTRICT
SM293129,33.6667,61.5,Pryazhinskiy rayon,ADM2,DISTRICT
SM299822,35.6418,46.733,Pryazovs'kyi,ADM2,DISTRICT
SM299691,32.3764,50.5861,Pryluts'ka,ADM2,DISTRICT
SM299692,32.3019,50.5784,Pryluts'kyi,ADM2,DISTRICT
SM299823,36.3437,46.735,Prymors'kyi,ADM2,DISTRICT
SM288967,20.9394,53.1136,Przasnysz,ADM2,DISTRICT
SM289012,22.6737,49.7606,Przemyśl,ADM2,DISTRICT
SM289011,22.7842,49.7835,Przemyśl (City),ADM2,DISTRICT
SM289013,22.5368,50.0891,Przeworsk,ADM2,DISTRICT
SM288968,20.651,51.4237,Przysucha,ADM2,DISTRICT
SM268908,9.8201,46.8673,Prättigau/Davos,ADM2,DISTRICT
SM370448,5.933,49.8011,Préizerdaul,ADM2:ADM3,DISTRICT
SM2798,7.3989,1.622,Príncipe,ADM1:ADMX,DISTRICT
SM11017,32.5342,34.8935,Psathi,ADM2,DISTRICT
SM11018,33.3426,34.7638,Psematismenos,ADM2,DISTRICT
SM11019,33.4696,34.9536,Psevdas,ADM2,DISTRICT
SM305848,45.1187,36.2606,Pshdar,ADM2,DISTRICT
SM242798,13.569,10.677,Psikye,LANGUAGE,LANGUAGE
SM11020,33.279,35.0599,Psimolofou,ADM2,DISTRICT
SM2804,29,57.5,Pskov,ADM1,DISTRICT
SM17052,28.25,57.75,Pskovskiy Rayon,ADM2,DISTRICT
SM11021,33.6872,35.2474,Psyllatos,ADM2,DISTRICT
SM288730,18.8818,49.9899,Pszczyna,ADM2,DISTRICT
SM296338,15.8767,46.4342,Ptuj,ADM1:ADM2,DISTRICT
SM242814,101.731,20.2551,Pu Ko,LANGUAGE,LANGUAGE
SM269368,100.9808,22.8268,Pu'er,ADM2,DISTRICT
SM242897,118.68,25.37,Pu-Xian Chinese,LANGUAGE,LANGUAGE
SM297233,101.028,19.1631,Pua,ADM2,DISTRICT
SM296988,102.8762,15.8887,Puai Noi,ADM2,DISTRICT
SM242801,141.715,-2.9012,Puare,LANGUAGE,LANGUAGE
SM242915,104.812,23.2384,Pubiao-Qabiao,LANGUAGE,LANGUAGE
SM273418,-79.5656,-3.145,Pucará,ADM2,DISTRICT
SM290298,25.2693,45.1821,Pucheni,ADM2,DISTRICT
SM291560,26.077,44.8239,Puchenii Mari,ADM2,DISTRICT
SM292934,43,57,Puchezhskiy rayon,ADM2,DISTRICT
SM290300,25.4334,45.0757,Pucioasa,ADM2,DISTRICT
SM288700,18.3698,54.6966,Puck,ADM2,DISTRICT
SM296366,16.1389,46.7453,Puconci,ADM1:ADM2,DISTRICT
SM256955,-123.04,45.22,Pudding River,ETHNICITY,ETHNICITY
SM242803,121.275,-24.1628,Puditara,DIALECT,LANGUAGE
SM293130,37,62,Pudozhskiy rayon,ADM2,DISTRICT
SM242804,121.379,18.2112,Pudtol Atta,LANGUAGE,LANGUAGE
SM277139,79.75,11.9167,Puducherry,ADM2,DISTRICT
SM2805,79.8167,11.9333,Puducherry,ADM1,DISTRICT
SM277225,78.9,10.35,Pudukkottai,ADM2,DISTRICT
SM282250,-98.15,19.0196,Puebla,ADM2,DISTRICT
SM2806,-97.9167,18.75,Puebla,ADM1,DISTRICT
SM303365,-104.5129,38.1734,Pueblo,ADM2,DISTRICT
SM16052,-66.3054,18.3458,Pueblo Barrio (Corozal),ADM2,DISTRICT
SM16053,-66.8945,18.3032,Pueblo Barrio (Lares),ADM2,DISTRICT
SM16054,-67.104,18.3994,Pueblo Barrio (Moca),ADM2,DISTRICT
SM16055,-67.2384,18.3318,Pueblo Barrio (Rincón),ADM2,DISTRICT
SM16056,-66.0477,18.3939,Pueblo Barrio (San Juan),ADM2,DISTRICT
SM10441,-73.5833,10.4167,Pueblo Bello,ADM2,DISTRICT
SM303619,-70.6592,8.9149,Pueblo Llano,ADM2,DISTRICT
SM285532,-86.5179,13.3503,Pueblo Nuevo,ADM2,DISTRICT
SM281511,-105.3402,23.4141,Pueblo Nuevo,ADM2,DISTRICT
SM281550,-101.3642,20.5459,Pueblo Nuevo,ADM2,DISTRICT
SM275366,-91.5313,14.6568,Pueblo Nuevo,ADM2,DISTRICT
SM269936,-75.4167,8.5,Pueblo Nuevo,ADM2,DISTRICT
SM283182,-92.8548,17.2116,Pueblo Nuevo Solistahuacán,ADM2,DISTRICT
SM275321,-90.4914,14.2296,Pueblo Nuevo Viñas,ADM2,DISTRICT
SM270427,-76.067,5.2945,Pueblo Rico,ADM2,DISTRICT
SM282767,-97.9101,22.1608,Pueblo Viejo,ADM2,DISTRICT
SM273488,-79.5333,-1.55,Pueblo Viejo,ADM2,DISTRICT
SM271766,-70.7803,18.3931,Pueblo Viejo,ADM2,DISTRICT
SM270131,-74.3854,10.8424,Pueblo Viejo,ADM2,DISTRICT
SM16057,-66.1142,18.4141,Pueblo Viejo Barrio,ADM2,DISTRICT
SM270247,-75.85,5.8,Pueblorrico,ADM2,DISTRICT
SM242806,-68.666,-42.223,Puelche,LANGUAGE,LANGUAGE
SM256956,-66.85,-39.93,Puelche,ETHNICITY,ETHNICITY
SM260847,-67.6667,-37.25,Puelén,ADM2,DISTRICT
SM16058,-66.8495,18.4755,Puente Barrio,ADM2,DISTRICT
SM282768,-96.5481,19.3128,Puente Nacional,ADM2,DISTRICT
SM270500,-73.6929,5.8166,Puente Nacional,ADM2,DISTRICT
SM282106,-99.3181,18.6147,Puente de Ixtla,ADM2,DISTRICT
SM270338,-77.3198,0.8212,Puerres,ADM2,DISTRICT
SM10442,-73.7496,-0.9689,Puerto Alegria,ADM2,DISTRICT
SM10443,-71.1465,-1.9068,Puerto Arica,ADM2,DISTRICT
SM270406,-76.3423,0.4616,Puerto Asís (1992-),ADM2,DISTRICT
SM275511,-88.5852,15.7175,Puerto Barrios,ADM2,DISTRICT
SM270248,-74.5227,6.5075,Puerto Berrío,ADM2,DISTRICT
SM270809,-74.4635,5.9721,Puerto Boyacá,ADM2,DISTRICT
SM303867,-68.1667,10.4167,Puerto Cabello,ADM2,DISTRICT
SM285409,-83.3937,14.0401,Puerto Cabezas,ADM2,DISTRICT
SM270407,-76.4135,0.6764,Puerto Caicedo,ADM2,DISTRICT
SM270655,-68.0368,5.8377,Puerto Carreño,ADM2,DISTRICT
SM15361,-58.9799,-21.6669,Puerto Casado (2008-),ADM2,DISTRICT
SM270074,-68.1642,2.439,Puerto Colombia,ADM2,DISTRICT
SM270664,-74.9089,11.0085,Puerto Colombia,ADM2,DISTRICT
SM270279,-72.7611,2.6226,Puerto Concordia,ADM2,DISTRICT
SM275891,-87.849,15.79,Puerto Cortés,ADM2,DISTRICT
SM16059,-65.3554,18.1385,Puerto Diablo Barrio,ADM2,DISTRICT
SM295579,-88.5296,13.2646,Puerto El Triunfo,ADM2,DISTRICT
SM269937,-76.1935,8.9945,Puerto Escondido,ADM2,DISTRICT
SM16060,-65.4176,18.1161,Puerto Ferro Barrio,ADM2,DISTRICT
SM270280,-71.637,3.9938,Puerto Gaitán,ADM2,DISTRICT
SM270408,-75.9061,0.7305,Puerto Guzmán,ADM2,DISTRICT
SM286729,-75.0896,-9.367,Puerto Inca,ADM2,DISTRICT
SM270409,-75.0583,0.0568,Puerto Leguízamo,ADM2,DISTRICT
SM275945,-83.9306,15.1145,Puerto Lempira,ADM2,DISTRICT
SM269938,-75.7831,7.6818,Puerto Libertador,ADM2,DISTRICT
SM270281,-73.2322,3.2179,Puerto Lleras,ADM2,DISTRICT
SM273499,-80.7476,-1.5573,Puerto López,ADM2,DISTRICT
SM270283,-72.6573,4.0443,Puerto López,ADM2,DISTRICT
SM285519,-87.2273,12.8823,Puerto Morazán,ADM2,DISTRICT
SM282473,-86.8759,20.8506,Puerto Morelos,ADM2,DISTRICT
SM270250,-74.5867,6.1917,Puerto Nare,ADM2,DISTRICT
SM269847,-70.4146,-3.6055,Puerto Nariño,ADM2,DISTRICT
SM16061,-66.4063,18.4846,Puerto Nuevo Barrio,ADM2,DISTRICT
SM270999,-76.7486,21.1693,Puerto Padre,ADM2,DISTRICT
SM270501,-73.9822,6.6765,Puerto Parra,ADM2,DISTRICT
SM282592,-113.2487,31.4095,Puerto Peñasco (pre-1989),ADM2,DISTRICT
SM289637,-58.4902,-22.7588,Puerto Pinasco,ADM2,DISTRICT
SM2807,-70.75,19.75,Puerto Plata,ADM1,DISTRICT
SM273543,-79.2362,0.1723,Puerto Quito,ADM2,DISTRICT
SM16062,-65.4694,18.106,Puerto Real Barrio,ADM2,DISTRICT
SM454380,-66.5,18.25,Puerto Rican,ETHNICITY,ETHNICITY
SM242807,-66.0919,18.4592,Puerto Rican Sign Language,LANGUAGE,LANGUAGE
SM270284,-73.1396,2.7827,Puerto Rico,ADM2,DISTRICT
SM270866,-75.0333,1.9678,Puerto Rico,ADM2,DISTRICT
SM270602,-70.965,6.4189,Puerto Rondón,ADM2,DISTRICT
SM270037,-74.5848,5.6189,Puerto Salgar,ADM2,DISTRICT
SM269848,-71.9391,-1.0987,Puerto Santander,ADM2,DISTRICT
SM270380,-72.4114,8.3288,Puerto Santander (1993-),ADM2,DISTRICT
SM269868,-76.4194,3.2339,Puerto Tejada,ADM2,DISTRICT
SM270251,-74.7041,5.9396,Puerto Triunfo,ADM2,DISTRICT
SM281834,-105.1944,20.6912,Puerto Vallarta,ADM2,DISTRICT
SM270502,-73.8051,7.5366,Puerto Wilches,ADM2,DISTRICT
SM9855,-68.6845,-18.9927,Puerto de Mejillones,ADM2,DISTRICT
SM16063,-66.8881,18.3928,Puertos Barrio,ADM2,DISTRICT
SM292368,27.209,46.0111,Pufesti,ADM2,DISTRICT
SM267159,-48.8556,-10.4288,Pugmil,ADM2,DISTRICT
SM16064,-66.4287,18.3872,Pugnado Adentro Barrio,ADM2,DISTRICT
SM16065,-66.4153,18.4179,Pugnado Afuera Barrio,ADM2,DISTRICT
SM290777,23.0846,45.5109,Pui,ADM2,DISTRICT
SM292305,27.4621,46.4454,Puiesti,ADM2,DISTRICT
SM289909,27.2085,45.3934,Puiestii De Jos,ADM2,DISTRICT
SM242809,93.7344,25.2973,Puimei Naga,LANGUAGE,LANGUAGE
SM256957,-70.06,5.19,Puinava,ETHNICITY,ETHNICITY
SM242810,-67.6886,3.9297,Puinave,LANGUAGE,LANGUAGE
SM256958,-113.17,69.28,Puiplirmiut,ETHNICITY,ETHNICITY
SM256959,-40.93,64.57,Puisortok,ETHNICITY,ETHNICITY
SM295495,-11.5792,7.3186,Pujehun,ADM1:ADM2,DISTRICT
SM273610,-78.6833,-0.95,Pujilí,ADM2,DISTRICT
SM289114,127.5833,40.75,Pujŏn,ADM2,DISTRICT
SM242811,-165.83,-10.91,Pukapuka,LANGUAGE,LANGUAGE
SM11257,-138.8001,-14.8212,Pukapuka,ADM2,DISTRICT
SM4241,-165.8474,-10.8537,Pukapuka,ADM1,DISTRICT
SM256962,-159.77,-21.26,Pukapukan,ETHNICITY,ETHNICITY
SM256962,-159.77,-21.26,Pukapukan,ETHNICITY,ETHNICITY
SM256962,-159.77,-21.26,Pukapukan,ETHNICITY,ETHNICITY
SM256962,-165.84,-10.85,Pukapukan,ETHNICITY,ETHNICITY
SM256962,-165.84,-10.85,Pukapukan,ETHNICITY,ETHNICITY
SM256962,-165.84,-10.85,Pukapukan,ETHNICITY,ETHNICITY
SM256962,-165.84,-10.89,Pukapukan,ETHNICITY,ETHNICITY
SM256962,-165.84,-10.89,Pukapukan,ETHNICITY,ETHNICITY
SM256962,-165.84,-10.89,Pukapukan,ETHNICITY,ETHNICITY
SM242813,122.6908,-8.4057,Pukaunu,LANGUAGE,LANGUAGE
SM289055,126.2969,39.5706,Pukch'ang,ADM2,DISTRICT
SM289115,128.3333,40.25,Pukch'ŏng,ADM2,DISTRICT
SM262648,28,53.5,Pukhavichy,ADM2,DISTRICT
SM226197,-14.6111,10.5271,Pukur,LANGUAGE,LANGUAGE
SM260471,20,42.0833,Pukës,ADM2,DISTRICT
SM9572,69.5603,35.5836,Pul-e Hisar,ADM2,DISTRICT
SM305672,13.8667,44.8667,Pula,ADM2,DISTRICT
SM305672,13.8667,44.8667,Pula,ADM2,DISTRICT
SM305672,13.8488,44.8671,Pula,ADM2,DISTRICT
SM305672,13.8488,44.8671,Pula,ADM2,DISTRICT
SM458143,141.7698,-31.4896,Pulaali,ETHNICITY,ETHNICITY
SM242818,-13.4482,13.8694,Pulaar,LANGUAGE,LANGUAGE
SM242822,145.738,-5.5956,Pulabu,LANGUAGE,LANGUAGE
SM276407,113.8623,-3.1186,Pulang Pisau,ADM2,DISTRICT
SM256963,149.43,7.64,Pulap,ETHNICITY,ETHNICITY
SM242817,-12.7338,12.1826,Pular,LANGUAGE,LANGUAGE
SM250640,-13,11,"Pular (Guinea, Sierra Leone, Guinea-Bissau)",ETHNICITY,ETHNICITY
SM302941,-80.7143,37.0636,Pulaski,ADM2,DISTRICT
SM301606,-92.2077,37.8246,Pulaski,ADM2,DISTRICT
SM300990,-84.5772,37.1039,Pulaski,ADM2,DISTRICT
SM300754,-86.6988,41.0418,Pulaski,ADM2,DISTRICT
SM300667,-89.1266,37.2229,Pulaski,ADM2,DISTRICT
SM302408,-92.3118,34.7699,Pulaski,ADM2,DISTRICT
SM300399,-83.476,32.2323,Pulaski,ADM2,DISTRICT
SM276459,128.4055,2.1992,Pulau Morotai,ADM2,DISTRICT
SM2808,100.2585,5.3768,Pulau Pinang,ADM1,DISTRICT
SM16066,-66.3311,18.1491,Pulguillas Barrio,ADM2,DISTRICT
SM260114,69.0535,33.9723,Puli Alam,ADM2,DISTRICT
SM260192,68.64,36.0012,Puli Khumri,ADM2,DISTRICT
SM256967,149.3,6.68,Pulosuk,ETHNICITY,ETHNICITY
SM242829,149.2005,7.357,Puluwatese,LANGUAGE,LANGUAGE
SM276883,75.0373,33.933,Pulwama (2006-),ADM2,DISTRICT
SM270038,-74.6953,4.6786,Pulí,ADM2,DISTRICT
SM242832,86.838,27.0774,Puma,LANGUAGE,LANGUAGE
SM256969,-68.75,8.05,Pume,ETHNICITY,ETHNICITY
SM256969,-68.75,8.05,Pume,ETHNICITY,ETHNICITY
SM256969,-68.75,8.05,Pume,ETHNICITY,ETHNICITY
SM256969,-68.75,8.05,Pume,ETHNICITY,ETHNICITY
SM256969,-68.25,7,Pume,ETHNICITY,ETHNICITY
SM256969,-68.25,7,Pume,ETHNICITY,ETHNICITY
SM256969,-68.25,7,Pume,ETHNICITY,ETHNICITY
SM256969,-68.25,7,Pume,ETHNICITY,ETHNICITY
SM256969,-68,7,Pume,ETHNICITY,ETHNICITY
SM256969,-68,7,Pume,ETHNICITY,ETHNICITY
SM256969,-68,7,Pume,ETHNICITY,ETHNICITY
SM256969,-68,7,Pume,ETHNICITY,ETHNICITY
SM256969,-68.77,6.85,Pume,ETHNICITY,ETHNICITY
SM256969,-68.77,6.85,Pume,ETHNICITY,ETHNICITY
SM256969,-68.77,6.85,Pume,ETHNICITY,ETHNICITY
SM256969,-68.77,6.85,Pume,ETHNICITY,ETHNICITY
SM242835,92,58.5,Pumpokol,LANGUAGE,LANGUAGE
SM242833,-67.5996,7.1068,Pumé,LANGUAGE,LANGUAGE
SM11258,-149.5833,-17.6167,Punaauia,ADM2,DISTRICT
SM2809,89.85,27.7,Punakha,ADM1,DISTRICT
SM242837,116.089,1.3804,Punan Aput,LANGUAGE,LANGUAGE
SM256971,115,3,Punan Bah,ETHNICITY,ETHNICITY
SM242845,113.156,2.2738,Punan Bah-Biau,LANGUAGE,LANGUAGE
SM242848,113.93,2.6533,Punan Batu 1,LANGUAGE,LANGUAGE
SM242838,115.588,0.5076,Punan Merah,LANGUAGE,LANGUAGE
SM242844,116.251,3.1014,Punan Merap,LANGUAGE,LANGUAGE
SM242836,116.26,2.8697,Punan Tubu,LANGUAGE,LANGUAGE
SM305422,14.6321,45.0202,Punat,ADM2,DISTRICT
SM262699,-65.845,-17.5787,Punata,ADM2,DISTRICT
SM276539,137.3193,-3.561,Puncak,ADM2,DISTRICT
SM276538,137.7703,-3.4882,Puncak Jaya,ADM2,DISTRICT
SM303657,-63.2193,9.978,Punceres,ADM2,DISTRICT
SM277050,73.7547,18.7467,Pune,ADM2,DISTRICT
SM281627,-100.6389,18.3494,Pungarabato,ADM2,DISTRICT
SM292306,27.3816,46.7027,Pungesti,ADM2,DISTRICT
SM291099,22.9632,44.2953,Punghina,ADM2,DISTRICT
SM458724,130.4467,-13.3773,Pungupungu,ETHNICITY,ETHNICITY
SM242857,10.3564,36.5273,Punic,LANGUAGE,LANGUAGE
SM269081,-71.9006,-36.4464,Punilla,ADM2,DISTRICT
SM261126,-64.6667,-31.1667,Punilla,ADM2,DISTRICT
SM305383,18.4154,45.4309,Punitovci,ADM2,DISTRICT
SM2811,72.3167,30.85,Punjab,ADM1,DISTRICT
SM2810,75.4167,30.9167,Punjab,ADM1,DISTRICT
SM256972,77.47,33.44,Punjabi,ETHNICITY,ETHNICITY
SM256972,77.47,33.44,Punjabi,ETHNICITY,ETHNICITY
SM256972,77.47,33.44,Punjabi,ETHNICITY,ETHNICITY
SM256972,77.47,33.44,Punjabi,ETHNICITY,ETHNICITY
SM256972,77.47,33.44,Punjabi,ETHNICITY,ETHNICITY
SM256972,74,32.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,74,32.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,74,32.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,74,32.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,74,32.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,73,32,Punjabi,ETHNICITY,ETHNICITY
SM256972,73,32,Punjabi,ETHNICITY,ETHNICITY
SM256972,73,32,Punjabi,ETHNICITY,ETHNICITY
SM256972,73,32,Punjabi,ETHNICITY,ETHNICITY
SM256972,73,32,Punjabi,ETHNICITY,ETHNICITY
SM256972,74.3,31.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,74.3,31.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,74.3,31.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,74.3,31.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,74.3,31.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,75.3,30.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,75.3,30.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,75.3,30.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,75.3,30.5,Punjabi,ETHNICITY,ETHNICITY
SM256972,75.3,30.5,Punjabi,ETHNICITY,ETHNICITY
SM286822,-70,-16,Puno,ADM2,DISTRICT
SM2812,-70,-15,Puno,ADM1,DISTRICT
SM242858,-70.2084,-14.608,Puno Quechua,LANGUAGE,LANGUAGE
SM16067,-65.5604,18.1063,Punta Arenas Barrio,ADM2,DISTRICT
SM18357,-54.9214,-34.932,Punta Del Este,ADM2,DISTRICT
SM16068,-65.7504,18.1647,Punta Santiago Barrio,ADM2,DISTRICT
SM270950,-84.8134,9.981,Puntarenas,ADM2,DISTRICT
SM2813,-84.8307,9.9779,Puntarenas,ADM1,DISTRICT
SM16069,-67.2583,18.3627,Puntas Barrio,ADM2,DISTRICT
SM242859,143,-27.33,Punthamara,DIALECT,LANGUAGE
SM242860,11.6714,-2.6736,Punu,LANGUAGE,LANGUAGE
SM280434,103.6592,13.4444,Puok,ADM2,DISTRICT
SM270339,-77.6419,0.9225,Pupiales,ADM2,DISTRICT
SM242864,-69.9611,-15.8992,Puquina,LANGUAGE,LANGUAGE
SM269869,-76.3876,2.3385,Puracé,ADM2,DISTRICT
SM242865,132.304,-2.0578,Puragi,LANGUAGE,LANGUAGE
SM256976,147.17,-42.43,Puraneters,ETHNICITY,ETHNICITY
SM242867,145.036,-7.5913,Purari,LANGUAGE,LANGUAGE
SM277422,84.8333,26.5833,Purba Champaran,ADM2,DISTRICT
SM277364,87.7763,21.9373,Purba Medinipur,ADM2,DISTRICT
SM276319,109.3503,-7.2842,Purbalingga,ADM2,DISTRICT
SM276909,86.4834,22.5924,Purbi Singhbhum,ADM2,DISTRICT
SM260275,63.8649,33.1366,Purchaman,ADM2,DISTRICT
SM13752,27.6346,56.4625,Purenu pagasts,ADM2,DISTRICT
SM242871,-101.631,19.2467,Purepecha,LANGUAGE,LANGUAGE
SM256978,-102.81,20.42,Purepecha,ETHNICITY,ETHNICITY
SM256978,-102.81,20.42,Purepecha,ETHNICITY,ETHNICITY
SM256978,-101,19,Purepecha,ETHNICITY,ETHNICITY
SM256978,-101,19,Purepecha,ETHNICITY,ETHNICITY
SM13753,22.9295,57.0515,Pures pagasts,ADM2,DISTRICT
SM265454,-35.5929,-5.4252,Pureza,ADM2,DISTRICT
SM242876,-44.7113,-22.1756,Puri,LANGUAGE,LANGUAGE
SM260374,15.6884,-7.6523,Puri,ADM2,DISTRICT
SM256980,-42.26,-19.36,Puri,ETHNICITY,ETHNICITY
SM256980,-42.97,-21,Puri,ETHNICITY,ETHNICITY
SM277125,85.6,19.9,Puri (1993-),ADM2,DISTRICT
SM270578,-74.886,3.869,Purificación,ADM2,DISTRICT
SM256982,-62.69,5.21,Purigoto,ETHNICITY,ETHNICITY
SM242873,76.0127,34.3386,Purik-Sham-Nubra,LANGUAGE,LANGUAGE
SM270964,-84.404,9.7267,Puriscal,ADM2,DISTRICT
SM16070,-66.9403,18.2497,Purisima Concepcion Barrio,ADM2,DISTRICT
SM242874,-120.431,34.6866,Purisimeño,LANGUAGE,LANGUAGE
SM286008,4.9524,52.5052,Purmerend,ADM2,DISTRICT
SM277423,87.4071,25.8161,Purnia (1990-),ADM2,DISTRICT
SM17053,78,64.6667,Purovskiy Rayon,ADM2,DISTRICT
SM18097,32.9014,40.0396,Pursaklar,ADM2,DISTRICT
SM242883,-62.4358,-11.4226,Puruborá,LANGUAGE,LANGUAGE
SM256986,-78.42,-1.64,Puruha,ETHNICITY,ETHNICITY
SM458167,144.2665,-29.7204,Purukuri,ETHNICITY,ETHNICITY
SM275345,-90.0384,15.2152,Purulhá,ADM2,DISTRICT
SM277365,86.4167,23.3333,Puruliya,ADM2,DISTRICT
SM242885,95.0935,25.3887,Purum,LANGUAGE,LANGUAGE
SM256987,94,25,Purum,ETHNICITY,ETHNICITY
SM242881,93.982,24.662,Purum (Retired),LANGUAGE,LANGUAGE
SM282070,-101.5235,20.1121,Puruándiro,ADM2,DISTRICT
SM276769,107.45,-6.5833,Purwakarta,ADM2,DISTRICT
SM276320,109.9667,-7.7,Purworejo,ADM2,DISTRICT
SM289098,129.6253,42.0976,Puryŏng,ADM2,DISTRICT
SM282069,-101.997,19.9055,Purépero,ADM2,DISTRICT
SM269939,-75.7118,9.2496,Purísima,ADM2,DISTRICT
SM281551,-101.9094,20.947,Purísima del Rincón,ADM2,DISTRICT
SM286855,-71.5823,-10.3733,Purús,ADM2,DISTRICT
SM13754,27.2045,56.232,Pusas pagasts,ADM2,DISTRICT
SM294127,28.75,57,Pushkino-Gorskiy rayon,ADM2,DISTRICT
SM17055,37.8612,55.9863,Pushkinskiy Rayon (Moscow),ADM2,DISTRICT
SM17054,30.3456,59.7388,Pushkinskiy Rayon (Sankt-Peterburg),ADM2,DISTRICT
SM302232,-95.3758,34.4162,Pushmataha,ADM2,DISTRICT
SM260276,62.0591,32.4872,Pusht Rod,ADM2,DISTRICT
SM256989,47.03,31.55,Pusht-I-Kuh,ETHNICITY,ETHNICITY
SM11432,-0.1052,11.0687,Pusiga,ADM2,DISTRICT
SM13755,27.7228,56.6449,Pusmucovas pagasts,ADM2,DISTRICT
SM294128,29.4167,56.4167,Pustoshkinskiy rayon,ADM2,DISTRICT
SM242889,12.9952,10.8385,Putai,LANGUAGE,LANGUAGE
SM283504,97.7824,27.2689,Putao,ADM2,DISTRICT
SM256994,-56.01,52.19,Puthlavamiut,ETHNICITY,ETHNICITY
SM269397,118.8767,25.4589,Putian,ADM2,DISTRICT
SM291917,24.9697,43.9143,Putineiu,ADM2,DISTRICT
SM290588,25.7823,43.9007,Putineiu,ADM2,DISTRICT
SM265845,-52.165,-29.0478,Putinga,ADM2,DISTRICT
SM14370,-97.8703,17.0273,Putla Villa de Guerrero,ADM2,DISTRICT
SM291833,25.6037,47.8852,Putna,ADM2,DISTRICT
SM303142,-81.909,38.5086,Putnam,ADM2,DISTRICT
SM302612,-85.4952,36.1408,Putnam,ADM2,DISTRICT
SM302150,-84.1317,41.0221,Putnam,ADM2,DISTRICT
SM301902,-73.7495,41.4267,Putnam,ADM2,DISTRICT
SM301607,-93.0161,40.4789,Putnam,ADM2,DISTRICT
SM300668,-89.2858,41.2045,Putnam,ADM2,DISTRICT
SM300755,-86.845,39.6663,Putnam,ADM2,DISTRICT
SM300362,-81.7443,29.6086,Putnam,ADM2,DISTRICT
SM300400,-83.3728,33.3218,Putnam,ADM2,DISTRICT
SM11587,20.4638,48.3369,Putnoki Jaras,ADM2,DISTRICT
SM242891,116.443,3.4247,Putoh,LANGUAGE,LANGUAGE
SM2814,101.7,2.9167,Putrajaya,ADM1:ADMX,DISTRICT
SM370454,6.1431,49.9608,Putscheid,ADM1:ADM2:ADM3,DISTRICT
SM2815,79.9319,8.0454,Puttalam,ADM1:ADM2,DISTRICT
SM285800,5.5783,52.2444,Putten,ADM2,DISTRICT
SM14090,-8.204,5.6685,Putu,ADM2,DISTRICT
SM242893,8.9604,6.6592,Putukwam,LANGUAGE,LANGUAGE
SM15391,-72.6673,-2.4505,Putumayo,ADM2,DISTRICT
SM273563,-75.85,0.1,Putumayo,ADM2,DISTRICT
SM2816,-76,0.5,Putumayo,ADM1,DISTRICT
SM299876,25.0852,47.9947,Putyl's'kyi,ADM2,DISTRICT
SM264282,-35.9291,-7.1705,Puxinanã,ADM2,DISTRICT
SM274422,3.146,45.7055,Puy-de-Dôme,ADM2,DISTRICT
SM256997,-122.47,46.93,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122.47,46.93,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122.47,46.93,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122.47,46.93,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122,47,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122,47,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122,47,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122,47,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122.04,47.11,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122.04,47.11,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122.04,47.11,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122.04,47.11,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122.5,47.1,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122.5,47.1,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122.5,47.1,Puyallup,ETHNICITY,ETHNICITY
SM256997,-122.5,47.1,Puyallup,ETHNICITY,ETHNICITY
SM269161,115.155,35.8133,Puyang,ADM2,DISTRICT
SM273465,-80.0167,-4.0167,Puyango,ADM2,DISTRICT
SM242900,120.996,22.643,Puyuma,LANGUAGE,LANGUAGE
SM256998,121,23,Puyuma,ETHNICITY,ETHNICITY
SM13756,22.1052,57.3643,Puzes pagasts,ADM2,DISTRICT
SM260818,-63,-38.1667,Puán,ADM2,DISTRICT
SM271874,-70.6564,19.3919,Puñal,ADM2,DISTRICT
SM305482,16.733,43.3464,Pučišća,ADM2,DISTRICT
SM288899,22.0375,51.4185,Puławy,ADM2,DISTRICT
SM288969,21.024,52.6721,Pułtusk,ADM2,DISTRICT
SM305624,15.7805,45.9157,Pušća,ADM2,DISTRICT
SM242901,164.77,-20.8442,Pwaamei,LANGUAGE,LANGUAGE
SM2817,38.8333,-7.25,Pwani,ADM1,DISTRICT
SM242902,164.556,-20.8133,Pwapwa,LANGUAGE,LANGUAGE
SM14968,151.584,7.337,Pwene Municipality,ADM2,DISTRICT
SM242903,98.7355,15.1915,Pwo Eastern Karen,LANGUAGE,LANGUAGE
SM242904,98.2709,18.016,Pwo Northern Karen,LANGUAGE,LANGUAGE
SM242906,97.7044,17.3622,Pwo Western Karen,LANGUAGE,LANGUAGE
SM242907,9.4373,9.0954,Pyapun,LANGUAGE,LANGUAGE
SM283497,95.3703,18.6925,Pyay,ADM2,DISTRICT
SM242908,-7.0148,5.0072,Pye Krumen,LANGUAGE,LANGUAGE
SM242909,100.029,20.759,Pyen,LANGUAGE,LANGUAGE
SM280683,128.3955,37.3682,Pyeongchang,ADM2,DISTRICT
SM280715,126.9875,37.0137,Pyeongtaek,ADM2,DISTRICT
SM9808,27.6222,53.9194,Pyershamayski Rayon,ADM2,DISTRICT
SM262589,28.5,52.25,Pyetrykaw,ADM2,DISTRICT
SM257002,15.07,3.05,Pygmee-CM,ETHNICITY,ETHNICITY
SM283518,96.1811,22.8099,Pyin-Oo-Lwin,ADM2,DISTRICT
SM235274,-46.7504,-5.9425,Pykobjê,DIALECT,LANGUAGE
SM11022,33.6938,35.0024,Pyla,ADM2,DISTRICT
SM370250,-8.5534,5.5662,Pynestown,ADM2:ADM3,DISTRICT
SM370250,-8.5534,5.5662,Pynestown,ADM2:ADM3,DISTRICT
SM370250,-8.7329,5.5125,Pynestown,ADM2:ADM3,DISTRICT
SM370250,-8.7329,5.5125,Pynestown,ADM2:ADM3,DISTRICT
SM261272,143.3647,-37.3276,Pyrenees,ADM2,DISTRICT
SM11023,33.7218,35.1876,Pyrga,ADM2,DISTRICT
SM299652,32.5107,50.245,Pyriatyns'kyi,ADM2,DISTRICT
SM11024,33.4838,35.0686,Pyrogi,ADM2,DISTRICT
SM288827,14.9405,53.1256,Pyrzyce,ADM2,DISTRICT
SM274435,-0.8164,43.2359,Pyrénées-Atlantiques,ADM2,DISTRICT
SM274447,2.5889,42.6008,Pyrénées-Orientales,ADM2,DISTRICT
SM293344,45.75,58.8333,Pyshchugskiy rayon,ADM2,DISTRICT
SM294129,28,57,Pytalovskiy rayon,ADM2,DISTRICT
SM242912,141.018,-4.45,Pyu,LANGUAGE,LANGUAGE
SM375347,82.8301,28.1202,Pyuthan,ADM2:ADM3,DISTRICT
SM289150,125.5,38.0833,Pyŏksŏng,ADM2,DISTRICT
SM304306,105.6659,22.6065,Pác Nặm,ADM2,DISTRICT
SM270845,-75.4905,5.5063,Pácora,ADM2,DISTRICT
SM241949,-76.3125,2.6152,Páez,LANGUAGE,LANGUAGE
SM303640,-65.8333,10.1667,Páez,ADM2,DISTRICT
SM303692,-69.05,9.5,Páez,ADM2,DISTRICT
SM303819,-71,7.25,Páez,ADM2,DISTRICT
SM269864,-76,2.75,Páez,ADM2,DISTRICT
SM270799,-73.0308,5.0683,Páez,ADM2,DISTRICT
SM283068,-102.4309,23.0156,Pánuco,ADM2,DISTRICT
SM282757,-98.2597,22.0589,Pánuco,ADM2,DISTRICT
SM281508,-104.334,24.4273,Pánuco de Coronado,ADM2,DISTRICT
SM276202,17.4966,47.3377,Pápa,ADM2,DISTRICT
SM270497,-73.1803,6.4375,Páramo,ADM2,DISTRICT
SM276145,19.6065,47.8701,Pásztó,ADM2,DISTRICT
SM282066,-101.585,19.508,Pátzcuaro,ADM2,DISTRICT
SM241918,152.2402,8.6102,Pááfang,LANGUAGE,LANGUAGE
SM265402,-37.4543,-9.6835,Pão de Açúcar,ADM2,DISTRICT
SM242227,32.4822,5.0947,Päri,LANGUAGE,LANGUAGE
SM2678,24.5667,58.4667,Pärnu,ADM1:ADMX,DISTRICT
SM262154,2.9961,9.587,Pèrèrè,ADM2,DISTRICT
SM267581,-39.6194,-11.8897,Pé de Serra,ADM2,DISTRICT
SM276210,18.2525,46.0799,Pécs,ADM1:ADM2,DISTRICT
SM276211,18.4546,46.1678,Pécsváradi,ADM2,DISTRICT
SM262138,2.0374,10.2707,Péhunco,ADM2,DISTRICT
SM242349,-66.082,5.248,Pémono,LANGUAGE,LANGUAGE
SM281549,-101.807,20.4042,Pénjamo,ADM2,DISTRICT
SM270963,-83.6348,9.3547,Pérez Zeledón,ADM2,DISTRICT
SM264628,-53.7073,-23.8408,Pérola,ADM2,DISTRICT
SM264627,-53.7564,-25.8468,Pérola d'Oeste,ADM2,DISTRICT
SM370517,5.8553,49.5505,Pétange,ADM1:ADM2:ADM3,DISTRICT
SM276128,20.0947,48.0059,Pétervására,ADM2,DISTRICT
SM260491,20.3333,40.2833,Përmetit,ADM2,DISTRICT
SM303572,-69.1372,11.3739,Píritu,ADM2,DISTRICT
SM303703,-65.0298,10.0389,Píritu,ADM2,DISTRICT
SM271150,14.2,49.4,Písek,ADM2,DISTRICT
SM289446,-8.2645,41.5883,Póvoa de Lanhoso,ADM2,DISTRICT
SM289304,-8.7571,41.3916,Póvoa de Varzim,ADM2,DISTRICT
SM2764,27.1667,58.0833,Põlva,ADM1:ADMX,DISTRICT
SM242667,35.2318,1.5702,Pökoot,LANGUAGE,LANGUAGE
SM298713,38.8742,38.1963,Pötürge,ADM2,DISTRICT
SM296172,18.3248,49.1241,Púchov,ADM2,DISTRICT
SM298985,39.8987,39.4866,Pülümür,ADM2,DISTRICT
SM276279,21.2118,47.3128,Püspökladány,ADM2,DISTRICT
SM2691,21.8825,42.5628,Pčinjski,ADM1:ADM2,DISTRICT
SM298595,33.1131,41.6151,Pınarbası,ADM2,DISTRICT
SM298609,36.3822,38.7323,Pınarbaşı,ADM2,DISTRICT
SM298650,27.5295,41.6352,Pınarhisar,ADM2,DISTRICT
SM288964,19.7857,52.5547,Płock,ADM2,DISTRICT
SM288963,19.715,52.5354,Płock (City),ADM2,DISTRICT
SM288965,20.3471,52.6389,Płońsk,ADM2,DISTRICT
SM289173,127.1667,39,Pŏptong,ADM2,DISTRICT
SM271238,17.55,49.5,Přerov,ADM2,DISTRICT
SM271185,14.1667,49.6667,Příbram,ADM2,DISTRICT
SM242919,-91.3043,15.6846,Q'anjob'al,LANGUAGE,LANGUAGE
SM11337,44.55,42.6,Q'azbegis Munitsip'alit'etI (excl. Kazbegi),ADM2,DISTRICT
SM11338,45.8333,42,Q'varlis Munitsip'alit'et'I (excl. Qvareli),ADM2,DISTRICT
SM305029,44.6277,13.9298,Qa'atabah,ADM2,DISTRICT
SM305956,68.263,37.4962,Qabodiyon,ADM2,DISTRICT
SM2819,28.75,-30,Qacha's Nek,ADM1,DISTRICT
SM12038,45.7032,35.1438,Qada' Darbandikhan,ADM2,DISTRICT
SM12038,45.7032,35.1438,Qada' Darbandikhan,ADM2,DISTRICT
SM12038,45.6337,35.1862,Qada' Darbandikhan,ADM2,DISTRICT
SM12038,45.6337,35.1862,Qada' Darbandikhan,ADM2,DISTRICT
SM12039,44.1152,36.0149,Qada' Dashti Hawlayr,ADM2,DISTRICT
SM12040,43.8069,36.3643,Qada' Khabat,ADM2,DISTRICT
SM12041,45.4008,35.9098,Qada' Mawat,ADM2,DISTRICT
SM12042,43.406,34.2611,Qada' Samarra',ADM2,DISTRICT
SM12043,45.774,35.4491,Qada' Sayyid Sadiq,ADM2,DISTRICT
SM12044,45.063,32.9107,Qada' al Aziziyah,ADM2,DISTRICT
SM12044,45.063,32.9107,Qada' al Aziziyah,ADM2,DISTRICT
SM12044,45.1625,32.9389,Qada' al Aziziyah,ADM2,DISTRICT
SM12044,45.1625,32.9389,Qada' al Aziziyah,ADM2,DISTRICT
SM12045,45.3557,35.5359,Qada' as Sulaymaniyah,ADM2,DISTRICT
SM260103,63.5842,34.8156,Qadis,ADM2,DISTRICT
SM257005,-95.43,64.3,Qaernermiut,ETHNICITY,ETHNICITY
SM304852,43.364,15.9087,Qafl Shamer,ADM2,DISTRICT
SM257006,-109.88,31.7,Qahatika,ETHNICITY,ETHNICITY
SM257006,-112.44,32.76,Qahatika,ETHNICITY,ETHNICITY
SM257007,48.76,22.65,Qahtan,ETHNICITY,ETHNICITY
SM257007,46.25,19.04,Qahtan,ETHNICITY,ETHNICITY
SM242916,51.3669,35.6677,Qahvehkhaneh Sign Language,LANGUAGE,LANGUAGE
SM305792,47.5125,31.4285,Qalat Salih,ADM2,DISTRICT
SM260267,61.3284,32.3955,Qala-I-Kah,ADM2,DISTRICT
SM260104,63.1235,34.8107,Qala-I-Naw,ADM2,DISTRICT
SM260097,68.3619,36.8975,Qala-I-Zal,ADM2,DISTRICT
SM260077,69.6587,33.4919,Qalandar,ADM2,DISTRICT
SM13226,67.5,48.1833,Qalasy Satbaev,ADM2,DISTRICT
SM260238,66.9772,32.2193,Qalat,ADM2,DISTRICT
SM273839,30.8349,31.0796,Qallin,ADM2,DISTRICT
SM13986,28.3346,-28.7725,Qalo,ADM2,DISTRICT
SM289748,35.0658,32.1858,Qalqilya,ADM1:ADM2,DISTRICT
SM273725,31.2408,30.2071,Qalyub,ADM2,DISTRICT
SM18430,66.5771,38.7626,Qamashi Tumani,ADM2,DISTRICT
SM4951,68,27.5892,Qambar Shahdadkot District,ADM2:ADM3,DISTRICT
SM295815,50.0892,11.0586,Qandala,ADM2,DISTRICT
SM303512,58.9379,42.7867,Qanliko'l,ADM2,DISTRICT
SM295820,42.8401,2.8151,Qansax Dheere,ADM2,DISTRICT
SM13227,79.25,45.1667,Qapal Audany,ADM2,DISTRICT
SM242920,151.702,-4.4526,Qaqet,LANGUAGE,LANGUAGE
SM11508,-46.1667,60.6667,Qaqortoq Municipality,ADM2,DISTRICT
SM260294,67.9874,33.221,Qarabagh,ADM2,DISTRICT
SM260037,69.1763,34.8494,Qarabagh,ADM2,DISTRICT
SM9750,49.6667,40.3333,Qaradag Rayonu,ADM2,DISTRICT
SM2820,71,48.25,Qaraghandy (1997-),ADM1,DISTRICT
SM13228,73.1579,49.8364,Qaraghandy Qalasy,ADM2,DISTRICT
SM304853,43.474,16.4019,Qarah,ADM2,DISTRICT
SM260278,64.9631,36.7931,Qaram Qol,ADM2,DISTRICT
SM13229,54,43,Qaraqiya Audany (excl. Zhangaozen),ADM2,DISTRICT
SM257009,33.79,28.52,Qararsha,ETHNICITY,ETHNICITY
SM13230,65.5,52.6667,Qarasu Audany (excl. Oktyabr),ADM2,DISTRICT
SM13231,70.8167,48.0333,Qarazhal Qalasy,ADM2,DISTRICT
SM11794,51.566,35.4329,Qarchak,ADM2,DISTRICT
SM296741,36.086,35.3959,Qardaha,ADM2,DISTRICT
SM295816,49.5077,9.5021,Qardho,ADM2,DISTRICT
SM260109,70.1957,34.5125,Qarghayi,ADM2,DISTRICT
SM13232,75.5,49.3333,Qarqaraly Audany (excl. Qazybek Bi),ADM2,DISTRICT
SM260032,66.0944,37.2914,Qarqin,ADM2,DISTRICT
SM18431,65.8186,38.8531,Qarshi Shahri,ADM2,DISTRICT
SM18432,65.8762,38.828,Qarshi Tumani,ADM2,DISTRICT
SM17799,47,27.25,Qaryah al Ulya,ADM2,DISTRICT
SM13148,36.2,32.079,Qasabat Az Zarqa,ADM2,DISTRICT
SM11795,60.7455,26.2269,Qaser-e Qand,ADM2,DISTRICT
SM242923,52.8335,28.2593,Qashqa'i,LANGUAGE,LANGUAGE
SM257010,51.42,30.06,Qashqai,ETHNICITY,ETHNICITY
SM257010,51.42,30.06,Qashqai,ETHNICITY,ETHNICITY
SM257010,52,30,Qashqai,ETHNICITY,ETHNICITY
SM257010,52,30,Qashqai,ETHNICITY,ETHNICITY
SM13233,76.75,43,Qaskeleng Audany,ADM2,DISTRICT
SM277527,45.6333,34.2,Qasr-e-Shirin,ADM2,DISTRICT
SM242926,45.7333,14.8,Qatabanian,LANGUAGE,LANGUAGE
SM304920,43.3311,17.3368,Qatabir,ADM2,DISTRICT
SM296749,36.0036,33.3892,Qatana,ADM2,DISTRICT
SM242929,-74.7075,-49.3032,Qawasqar,LANGUAGE,LANGUAGE
SM261955,46.75,41.3333,Qax,ADM1:ADM2,DISTRICT
SM260279,64.2791,35.6414,Qaysar,ADM2,DISTRICT
SM261925,45.09,41.26,Qazax,ADM1:ADM2,DISTRICT
SM2821,49.75,36,Qazvin,ADM1,DISTRICT
SM13234,76.4961,49.8204,Qazybek Bi Audany,ADM2,DISTRICT
SM2823,32.75,25.8,Qena (2009-),ADM1,DISTRICT
SM3941,-52.8429,68.7072,Qeqertalik,ADM1,DISTRICT
SM2822,-53.6752,66.9331,Qeqqata,ADM1,DISTRICT
SM11796,55.8667,26.7833,Qeshm,ADM2,DISTRICT
SM12046,45.4047,35.3006,Qeza-i Qeredagh,ADM2,DISTRICT
SM12047,44.6393,36.5518,Qeza-i Ruwandiz,ADM2,DISTRICT
SM12048,45.7044,35.2793,Qeza-i Sharezur,ADM2,DISTRICT
SM269455,108.5991,26.5738,Qiandongnan Miao and Dong,ADM2,DISTRICT
SM269456,107.1186,26.066,Qiannan Buyei and Miao,ADM2,DISTRICT
SM269457,105.3874,25.3881,Qianxinan Buyei and Miao,ADM2,DISTRICT
SM18433,69.5,41.5,Qibray Tumani,ADM2,DISTRICT
SM242944,103.1413,22.5704,Qila Muji,LANGUAGE,LANGUAGE
SM242945,100.1709,25.6553,Qiliqiao,DIALECT,LANGUAGE
SM375391,66.5561,30.6985,Qilla Abdullah,ADM2:ADM3,DISTRICT
SM375400,68.34,30.9539,Qilla Saifullah,ADM2:ADM3,DISTRICT
SM17800,41.0833,20,Qilwah,ADM2,DISTRICT
SM13235,67.4167,51.4167,Qima Audany,ADM2,DISTRICT
SM242946,37.0542,12.8119,Qimant,LANGUAGE,LANGUAGE
SM273848,32.7153,26.1282,Qina,ADM2,DISTRICT
SM269292,120.2686,36.6317,Qingdao,ADM2,DISTRICT
SM242950,120.3797,36.0894,Qingdao Mandarin,DIALECT,LANGUAGE
SM2824,96,36,Qinghai,ADM1,DISTRICT
SM257014,-68.28,66.74,Qinguamiut,ETHNICITY,ETHNICITY
SM269404,107.5811,36.236,Qingyang,ADM2,DISTRICT
SM269418,112.8723,24.276,Qingyuan,ADM2,DISTRICT
SM269144,119.2921,40.1683,Qinhuangdao,ADM2,DISTRICT
SM269440,108.6166,21.9501,Qinzhou,ADM2,DISTRICT
SM10361,110.4014,19.2094,Qionghai County,ADM2,DISTRICT
SM269159,124.6513,47.7395,Qiqihar,ADM2,DISTRICT
SM305074,51.324,15.6999,Qishn,ADM2,DISTRICT
SM269148,131.0936,45.8074,Qitaihe,ADM2,DISTRICT
SM242956,104.15,24.15,Qiubei Zhuang,LANGUAGE,LANGUAGE
SM303551,65,39.9167,Qiziltepa,ADM2,DISTRICT
SM303442,67.25,37.6667,Qiziriq,ADM2,DISTRICT
SM303499,57.5,43.5,Qo'ng'irot,ADM2,DISTRICT
SM303530,60.3333,41.5,Qo'shko'pir,ADM2,DISTRICT
SM303410,66.5,40.3333,Qo'shrabot,ADM2,DISTRICT
SM18434,70.9424,40.5303,Qoqon Shahri,ADM2,DISTRICT
SM13236,55.5,50,Qobda Audany (excl. Isatay),ADM2,DISTRICT
SM261915,49,40.5,Qobustan,ADM1:ADM2,DISTRICT
SM11797,51.0667,35.7167,Qods,ADM2,DISTRICT
SM277595,51.0454,34.7255,Qom,ADM2,DISTRICT
SM2825,51,34.75,Qom,ADM1,DISTRICT
SM303468,63.846,39.5099,Qorako'l,ADM2,DISTRICT
SM303500,60.1667,42.6667,Qorao'zak,ADM2,DISTRICT
SM18435,72.8846,40.7341,Qorasuw Shahri,ADM2,DISTRICT
SM13237,75,43.3333,Qorday Audany (excl. Korday Village),ADM2,DISTRICT
SM303469,64.7705,39.4742,Qorovulbozor,ADM2,DISTRICT
SM11798,47.85,35.2167,Qorveh,ADM2,DISTRICT
SM295791,44.4494,1.9346,Qoryooley,ADM2,DISTRICT
SM2826,64,51.5,Qostanay (1997-),ADM1:ADMX,DISTRICT
SM13238,63.5833,53.1667,Qostanay Qalasy,ADM2,DISTRICT
SM13239,86,49.1667,Qotanqaraghay Audany (excl. Ulkennaryn),ADM2,DISTRICT
SM285184,9.1614,8.7608,Qua'anpa,ADM2,DISTRICT
SM266844,-48.0426,-23.3003,Quadra,ADM2,DISTRICT
SM277990,35.5156,29.5917,Quaira,ADM2,DISTRICT
SM261303,117.3984,-32.0146,Quairading,ADM2,DISTRICT
SM257016,-65,61.55,Quamauangmiut,ETHNICITY,ETHNICITY
SM304513,104.9534,20.4757,Quan Hóa,ADM2,DISTRICT
SM304514,104.8342,20.2576,Quan Sơn,ADM2,DISTRICT
SM304130,104.6602,22.4151,Quang Bình,ADM2,DISTRICT
SM18476,105.8356,21.0369,Quang truong Ba GJinh,ADM2,DISTRICT
SM232426,118.5974,24.9945,Quanzhang,DIALECT,LANGUAGE
SM269398,118.2129,25.2507,Quanzhou,ADM2,DISTRICT
SM242964,-94.6634,36.9471,Quapaw,LANGUAGE,LANGUAGE
SM257017,-91.39,34.72,Quapaw,ETHNICITY,ETHNICITY
SM265846,-56.1538,-30.2942,Quaraí,ADM2,DISTRICT
SM17607,-60.9763,13.9914,Quarte Chemins,ADM2,DISTRICT
SM263812,-45.5843,-19.2872,Quartel Geral,ADM2,DISTRICT
SM264655,-53.1199,-24.2961,Quarto Centenário,ADM2,DISTRICT
SM264656,-49.9198,-23.555,Quatiguá,ADM2,DISTRICT
SM264080,-47.0104,-0.8255,Quatipuru,ADM2,DISTRICT
SM265300,-44.2322,-22.3623,Quatis,ADM2,DISTRICT
SM10334,15.0569,12.1282,Quatrieme Arrondissement,ADM2,DISTRICT
SM264657,-48.999,-25.3774,Quatro Barras,ADM2,DISTRICT
SM9994,-52.4607,-27.8125,Quatro Irmaos,ADM2:ADM3,DISTRICT
SM264658,-54.0057,-24.5044,Quatro Pontes,ADM2,DISTRICT
SM266845,-50.6496,-22.2115,Quatá,ADM2,DISTRICT
SM301847,-103.5497,35.1044,Quay,ADM2,DISTRICT
SM261948,48.4167,41.1667,Quba,ADM1:ADM2,DISTRICT
SM261931,46.5833,39.3333,Qubadli,ADM1:ADM2,DISTRICT
SM277598,58.65,37.0667,Quchan,ADM2,DISTRICT
SM257018,58.23,21.28,Quda'a,ETHNICITY,ETHNICITY
SM18564,149.5702,-35.4556,Queanbeyan-Palerang Regional,ADM2,DISTRICT
SM242969,-74.8719,45.9965,Quebec Sign Language,LANGUAGE,LANGUAGE
SM275112,-14.8,11.545,Quebo,ADM2,DISTRICT
SM261048,-63.3333,-29.3333,Quebrachos,ADM2,DISTRICT
SM16075,-65.875,18.1986,Quebrada Arenas Barrio (Las Piedras),ADM2,DISTRICT
SM16076,-65.8932,18.0218,Quebrada Arenas Barrio (Maunabo),ADM2,DISTRICT
SM16071,-66.0768,18.3132,Quebrada Arenas Barrio (San Juan),ADM2,DISTRICT
SM16072,-65.9568,18.1115,Quebrada Arenas Barrio (San Lorenzo),ADM2,DISTRICT
SM16073,-66.2797,18.3576,Quebrada Arenas Barrio (Toa Alta),ADM2,DISTRICT
SM16074,-66.4059,18.3785,Quebrada Arenas Barrio (Vega Baja),ADM2,DISTRICT
SM16077,-66.1515,18.0935,Quebrada Arriba Barrio (Cayey),ADM2,DISTRICT
SM16078,-66.0708,18.0489,Quebrada Arriba Barrio (Patillas),ADM2,DISTRICT
SM16079,-66.8388,18.37,Quebrada Barrio (Camuy),ADM2,DISTRICT
SM16080,-65.9681,18.2132,Quebrada Barrio (San Lorenzo),ADM2,DISTRICT
SM16081,-66.7099,18.0734,Quebrada Ceiba Barrio,ADM2,DISTRICT
SM16082,-66.2564,18.3398,Quebrada Cruz Barrio,ADM2,DISTRICT
SM16083,-65.6615,18.3468,Quebrada Fajardo Barrio,ADM2,DISTRICT
SM16086,-66.2805,18.1989,Quebrada Grande Barrio (Barranquitas),ADM2,DISTRICT
SM16084,-67.1216,18.1815,Quebrada Grande Barrio (Mayagüez),ADM2,DISTRICT
SM16085,-65.9607,18.3137,Quebrada Grande Barrio (Trujillo),ADM2,DISTRICT
SM16088,-66.7799,18.0823,Quebrada Honda Barrio (Guayanilla),ADM2,DISTRICT
SM16087,-65.9934,18.1398,Quebrada Honda Barrio (San Lorenzo),ADM2,DISTRICT
SM16089,-65.9951,18.299,Quebrada Infierno Barrio,ADM2,DISTRICT
SM16090,-67.1492,18.3069,Quebrada Larga Barrio,ADM2,DISTRICT
SM16091,-66.6721,18.0554,Quebrada Limon Barrio,ADM2,DISTRICT
SM16092,-65.9885,18.3194,Quebrada Negrito Barrio,ADM2,DISTRICT
SM16093,-65.6646,18.232,Quebrada Seca Barrio,ADM2,DISTRICT
SM16094,-65.6448,18.3064,Quebrada Vueltas Barrio,ADM2,DISTRICT
SM16095,-66.203,18.0374,Quebrada Yeguas Barrio,ADM2,DISTRICT
SM270040,-74.5212,5.0826,Quebradanegra,ADM2,DISTRICT
SM16096,-66.8107,18.0374,Quebradas Barrio (Guayanilla),ADM2,DISTRICT
SM16097,-66.8285,18.0599,Quebradas Barrio (Yauco),ADM2,DISTRICT
SM2834,-66.9246,18.4397,Quebradillas,ADM1,DISTRICT
SM16098,-66.2891,18.2252,Quebradillas Barrio,ADM2,DISTRICT
SM16099,-66.938,18.4728,Quebradillas Barrio-Pueblo,ADM2,DISTRICT
SM265418,-36.4979,-9.2923,Quebrangulo,ADM2,DISTRICT
SM242973,-114.599,32.7859,Quechan,LANGUAGE,LANGUAGE
SM257019,-114.39,32.18,Quechan,ETHNICITY,ETHNICITY
SM257019,-114.39,32.18,Quechan,ETHNICITY,ETHNICITY
SM257019,-114.39,32.18,Quechan,ETHNICITY,ETHNICITY
SM257019,-114.79,32.61,Quechan,ETHNICITY,ETHNICITY
SM257019,-114.79,32.61,Quechan,ETHNICITY,ETHNICITY
SM257019,-114.79,32.61,Quechan,ETHNICITY,ETHNICITY
SM257019,-114,33,Quechan,ETHNICITY,ETHNICITY
SM257019,-114,33,Quechan,ETHNICITY,ETHNICITY
SM257019,-114,33,Quechan,ETHNICITY,ETHNICITY
SM257019,-114.5,32.7,Quechan,ETHNICITY,ETHNICITY
SM257019,-114.5,32.7,Quechan,ETHNICITY,ETHNICITY
SM257019,-114.5,32.7,Quechan,ETHNICITY,ETHNICITY
SM282251,-97.6571,18.9405,Quecholac,ADM2,DISTRICT
SM257020,-73.91,-13.5,Quechua,ETHNICITY,ETHNICITY
SM257020,-73.91,-13.5,Quechua,ETHNICITY,ETHNICITY
SM257020,-78.5,-0.233,Quechua,ETHNICITY,ETHNICITY
SM257020,-78.5,-0.233,Quechua,ETHNICITY,ETHNICITY
SM281628,-99.1755,17.3445,Quechultenango,ADM2,DISTRICT
SM264659,-52.9827,-25.4317,Quedas do Iguaçu,ADM2,DISTRICT
SM301222,-76.085,39.0376,Queen Anne's,ADM2,DISTRICT
SM301903,-73.8388,40.6575,Queens,ADM2,DISTRICT
SM268808,-65.9989,45.8334,Queens,ADM2,DISTRICT
SM268624,-63.2487,46.2834,Queens,ADM2,DISTRICT
SM268827,-64.9988,44.2335,Queens,ADM2,DISTRICT
SM12657,-76.8212,18.0429,Queensborough/ Tunbridge,ADM2,DISTRICT
SM261273,144.6625,-38.269,Queenscliffe,ADM2,DISTRICT
SM2835,145,-20,Queensland,ADM1,DISTRICT
SM286495,168.9181,-44.6615,Queenstown-Lakes,ADM2,DISTRICT
SM257021,-123.86,47.36,Queets,ETHNICITY,ETHNICITY
SM265099,-41.2475,-8.54,Queimada Nova,ADM2,DISTRICT
SM264283,-35.8977,-7.427,Queimadas,ADM2,DISTRICT
SM267606,-39.7638,-11.0591,Queimadas,ADM2,DISTRICT
SM265302,-43.5748,-22.7241,Queimados,ADM2,DISTRICT
SM266846,-50.2359,-21.7929,Queiroz,ADM2,DISTRICT
SM260348,17.2303,-9.3658,Quela,ADM2,DISTRICT
SM295747,-88.2374,13.5145,Quelepa,ADM2,DISTRICT
SM297826,126.559,-8.6014,Quelicai,ADM2,DISTRICT
SM266847,-44.7809,-22.5132,Queluz,ADM2,DISTRICT
SM263814,-43.8936,-20.7315,Queluzita,ADM2,DISTRICT
SM16100,-67.0916,18.2144,Quemado Barrio,ADM2,DISTRICT
SM271066,-80.2889,22.7811,Quemado de Güines,ADM2,DISTRICT
SM16101,-65.9913,18.1772,Quemados Barrio,ADM2,DISTRICT
SM260848,-63.75,-36.25,Quemú Quemú,ADM2,DISTRICT
SM257022,-57.54,-35.41,Querandi,ETHNICITY,ETHNICITY
SM257022,-61.13,-34.18,Querandi,ETHNICITY,ETHNICITY
SM273571,-78.6,-1.3667,Quero,ADM2,DISTRICT
SM257023,-71.9724,-13.5237,Quero,ETHNICITY,ETHNICITY
SM257023,-71.9724,-13.5237,Quero,ETHNICITY,ETHNICITY
SM257023,-72,-13.5,Quero,ETHNICITY,ETHNICITY
SM257023,-72,-13.5,Quero,ETHNICITY,ETHNICITY
SM282071,-100.8438,19.6975,Queréndaro,ADM2,DISTRICT
SM282452,-100.4474,20.7211,Querétaro,ADM2,DISTRICT
SM2836,-99.85,20.85,Querétaro,ADM1,DISTRICT
SM242983,-100.115,20.1396,Querétaro Otomi,LANGUAGE,LANGUAGE
SM263221,-52.5476,-12.3212,Querência,ADM2,DISTRICT
SM264660,-53.5628,-23.1015,Querência do Norte,ADM2,DISTRICT
SM270041,-73.8402,4.3324,Quetame,ADM2,DISTRICT
SM375392,66.762,30.1746,Quetta,ADM2:ADM3,DISTRICT
SM275217,-91.5363,14.8184,Quetzaltenango,ADM2,DISTRICT
SM242986,-95.6727,16.9215,Quetzaltepec Mixe,LANGUAGE,LANGUAGE
SM273489,-79.4833,-1.0167,Quevedo,ADM2,DISTRICT
SM265847,-54.07,-29.3067,Quevedos,ADM2,DISTRICT
SM242987,100.813,30.3113,Queyu,LANGUAGE,LANGUAGE
SM275185,-90.0587,14.28,Quezada,ADM2,DISTRICT
SM285423,-86.9087,12.5306,Quezalguaque,ADM2,DISTRICT
SM2837,-91.6667,14.75,Quezaltenango,ADM1,DISTRICT
SM275414,-89.4532,14.6183,Quezaltepeque,ADM2,DISTRICT
SM295660,-89.25,13.9167,Quezaltepeque,ADM2,DISTRICT
SM2838,121.5,14.6667,Quezon,ADM1:ADM2,DISTRICT
SM304610,109.1957,13.7516,Qui Nhơn,ADM2,DISTRICT
SM242988,-95.6761,16.7565,Quiavicuzas Zapotec,LANGUAGE,LANGUAGE
SM260448,15.2936,-10.7128,Quibala,ADM2,DISTRICT
SM10445,-76.837,5.7608,Quibdo (1999-),ADM2,DISTRICT
SM2839,-90.9167,15.5,Quiché,ADM1,DISTRICT
SM12658,-77.6783,18.2328,Quick Step,ADM2,DISTRICT
SM260428,15.3333,-8.5,Quiculungo,ADM2,DISTRICT
SM242992,-79.0534,-8.0996,Quignam,LANGUAGE,LANGUAGE
SM267607,-39.0339,-10.788,Quijingue,ADM2,DISTRICT
SM273528,-78.153,-0.4646,Quijos,ADM2,DISTRICT
SM285474,-86.0064,13.5797,Quilalí,ADM2,DISTRICT
SM273466,-79.3786,-4.3416,Quilanga,ADM2,DISTRICT
SM260449,14.3532,-10.5067,Quilenda,ADM2,DISTRICT
SM9674,13.7671,-13.9921,Quilengues (2011-),ADM2,DISTRICT
SM242993,-124.603,47.9106,Quileute,LANGUAGE,LANGUAGE
SM257024,-124.13,47.69,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.13,47.69,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.13,47.69,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.13,47.69,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.13,47.69,Quileute,ETHNICITY,ETHNICITY
SM257024,-124,48,Quileute,ETHNICITY,ETHNICITY
SM257024,-124,48,Quileute,ETHNICITY,ETHNICITY
SM257024,-124,48,Quileute,ETHNICITY,ETHNICITY
SM257024,-124,48,Quileute,ETHNICITY,ETHNICITY
SM257024,-124,48,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.59,47.91,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.59,47.91,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.59,47.91,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.59,47.91,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.59,47.91,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.14,47.77,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.14,47.77,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.14,47.77,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.14,47.77,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.14,47.77,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.3,47.9,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.3,47.9,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.3,47.9,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.3,47.9,Quileute,ETHNICITY,ETHNICITY
SM257024,-124.3,47.9,Quileute,ETHNICITY,ETHNICITY
SM257025,-65.65,-19.19,Quillaca,ETHNICITY,ETHNICITY
SM257026,-76.43,1.27,Quillacinga,ETHNICITY,ETHNICITY
SM262700,-66.3435,-17.3744,Quillacollo,ADM2,DISTRICT
SM260819,-58.25,-34.75,Quilmes,ADM2,DISTRICT
SM266265,-52.6767,-26.7264,Quilombo,ADM2,DISTRICT
SM261658,143.5794,-26.1506,Quilpie,ADM2,DISTRICT
SM270415,-75.8376,4.5934,Quimbaya,ADM2,DISTRICT
SM257027,-75.44,4.26,Quimbaya,ETHNICITY,ETHNICITY
SM260375,16.3815,-6.3415,Quimbele,ADM2,DISTRICT
SM275774,-88.3471,15.4171,Quimistán,ADM2,DISTRICT
SM282252,-97.111,19.2557,Quimixtlán,ADM2,DISTRICT
SM257028,-124.03,47.19,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.03,47.19,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.03,47.19,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.03,47.19,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.03,47.19,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124,47,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124,47,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124,47,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124,47,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124,47,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.16,47.42,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.16,47.42,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.16,47.42,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.16,47.42,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.16,47.42,Quinalt,ETHNICITY,ETHNICITY
SM257028,-123.82,47.38,Quinalt,ETHNICITY,ETHNICITY
SM257028,-123.82,47.38,Quinalt,ETHNICITY,ETHNICITY
SM257028,-123.82,47.38,Quinalt,ETHNICITY,ETHNICITY
SM257028,-123.82,47.38,Quinalt,ETHNICITY,ETHNICITY
SM257028,-123.82,47.38,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.1,47.4,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.1,47.4,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.1,47.4,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.1,47.4,Quinalt,ETHNICITY,ETHNICITY
SM257028,-124.1,47.4,Quinalt,ETHNICITY,ETHNICITY
SM2840,-15.0833,11.6667,Quinara,ADM1,DISTRICT
SM242998,-124.16,47.4214,Quinault,LANGUAGE,LANGUAGE
SM270428,-75.7578,5.3198,Quinchía,ADM2,DISTRICT
SM257029,-75.34,4.83,Quindio,ETHNICITY,ETHNICITY
SM2841,-75.6667,4.5,Quindío,ADM1,DISTRICT
SM275083,-15.854,11.8774,Quinhamel,ADM2,DISTRICT
SM273633,-79.642,0.2763,Quinindé,ADM2,DISTRICT
SM242997,-3.687,40.2638,Quinqui,LANGUAGE,LANGUAGE
SM273490,-79.311,-1.2073,Quinsaloma,ADM2,DISTRICT
SM264661,-52.1575,-23.7981,Quinta do Sol,ADM2,DISTRICT
SM282995,-88.6508,20.8446,Quintana Roo,ADM2,DISTRICT
SM2842,-88.25,19.6667,Quintana Roo,ADM1,DISTRICT
SM16102,-66.6105,18.0147,Quinto Barrio,ADM2,DISTRICT
SM265848,-53.1165,-28.7444,Quinze de Novembro,ADM2,DISTRICT
SM243001,-96.1555,16.3416,Quioquitani-Quieri Zapotec,LANGUAGE,LANGUAGE
SM243000,-96.602,17.5621,Quiotepec Chinantec,LANGUAGE,LANGUAGE
SM264875,-36.0051,-8.8475,Quipapá,ADM2,DISTRICT
SM270042,-74.551,4.7237,Quipile,ADM2,DISTRICT
SM9675,14.5595,-15.122,Quipungo (2011-),ADM2,DISTRICT
SM282593,-109.3905,27.5776,Quiriego,ADM2,DISTRICT
SM260349,18.1068,-10.9055,Quirima,ADM2,DISTRICT
SM2843,121.5833,16.2833,Quirino,ADM1:ADM2,DISTRICT
SM268144,-50.5715,-18.4571,Quirinópolis,ADM2,DISTRICT
SM282072,-101.5439,19.672,Quiroga,ADM2,DISTRICT
SM286899,-71,-13.4167,Quispicanchi,ADM2,DISTRICT
SM271851,-69.4204,18.5388,Quisquella,ADM2,DISTRICT
SM265303,-41.3914,-22.0957,Quissamã,ADM2,DISTRICT
SM283855,40.3228,-12.5727,Quissanga,ADM2,DISTRICT
SM266848,-50.3708,-22.0972,Quitana,ADM2,DISTRICT
SM264662,-49.5052,-25.9013,Quitandinha,ADM2,DISTRICT
SM267833,-40.7281,-5.89,Quiterianópolis,ADM2,DISTRICT
SM260376,15,-8,Quitexe,ADM2,DISTRICT
SM261092,-60.1667,-26.6667,Quitilipi,ADM2,DISTRICT
SM301482,-90.2891,34.2514,Quitman,ADM2,DISTRICT
SM300401,-85.0188,31.8673,Quitman,ADM2,DISTRICT
SM273544,-78.5,-0.2167,Quito,ADM1:ADM2:ADME,DISTRICT
SM281835,-102.8803,19.7937,Quitupan,ADM2,DISTRICT
SM271022,-82.3846,22.7936,Quivicán,ADM2,DISTRICT
SM267608,-40.133,-11.4134,Quixabeira,ADM2,DISTRICT
SM264876,-37.8728,-7.7211,Quixabá,ADM2,DISTRICT
SM264284,-37.1178,-7.0508,Quixabá,ADM2,DISTRICT
SM267834,-39.0181,-4.9706,Quixada,ADM2,DISTRICT
SM267835,-39.117,-6.1506,Quixelô,ADM2,DISTRICT
SM267837,-39.3346,-5.1342,Quixeramobim,ADM2,DISTRICT
SM267838,-37.9267,-5.0633,Quixeré,ADM2,DISTRICT
SM289619,-57.3147,-26.0132,Quiíndy,ADM2,DISTRICT
SM243004,113.6,24.8167,Qujiang Hakka-Shibei Shaoguan Tuhua,LANGUAGE,LANGUAGE
SM269369,103.9861,25.7199,Qujing,ADM2,DISTRICT
SM304815,53.5867,12.5548,Qulensya Wa Abd Al Kuri,ADM2,DISTRICT
SM303443,67.5833,38,Qumqo'rg'on,ADM2,DISTRICT
SM305957,68.6389,37.4511,Qumsangir,ADM2,DISTRICT
SM296742,35.7884,33.0857,Quneitra District,ADM2,DISTRICT
SM2844,35.7833,33.0333,Quneitra Governorate (incl. Golan Heights),ADM1,DISTRICT
SM9573,64.9424,37.0359,Qurghan,ADM2,DISTRICT
SM18436,72.8333,40.75,Qurghontepa Tumani,ADM2,DISTRICT
SM261949,48.25,41.4167,Qusar,ADM1:ADM2,DISTRICT
SM9574,65.3635,36.2097,Qush Tepah,ADM2,DISTRICT
SM2845,28,-30.3667,Quthing,ADM1,DISTRICT
SM273923,30.973,30.9666,Qutur,ADM2,DISTRICT
SM18437,72.0617,40.5236,Quva Shahri,ADM2,DISTRICT
SM18438,72.0833,40.5,Quva Tumani,ADM2,DISTRICT
SM18439,71.9697,40.3079,Quvasoy Shahri,ADM2,DISTRICT
SM273979,31.1602,30.5649,Quwaysina,ADM2,DISTRICT
SM303447,69,40.9167,Quyichirchiq,ADM2,DISTRICT
SM289620,-57.0104,-26.2527,Quyquyhó,ADM2,DISTRICT
SM269390,118.8719,28.9427,Quzhou,ADM2,DISTRICT
SM2833,-71.9991,52.0002,Québec,ADM1,DISTRICT
SM270810,-74.2166,5.5588,Quípama,ADM2,DISTRICT
SM304129,104.9724,23.0801,Quản Bạ,ADM2,DISTRICT
SM2827,106.3333,17.5,Quảng Bình,ADM1,DISTRICT
SM2828,107.9167,15.5833,Quảng Nam,ADM1,DISTRICT
SM304414,108.8096,15.1158,Quảng Ngãi,ADM2,DISTRICT
SM2829,108.6667,15,Quảng Ngãi,ADM1,DISTRICT
SM304380,106.5139,17.2707,Quảng Ninh,ADM2,DISTRICT
SM2830,107.3333,21.25,Quảng Ninh,ADM1,DISTRICT
SM304381,106.3671,17.8345,Quảng Trạch,ADM2,DISTRICT
SM304446,107.1872,16.7522,Quảng Trị,ADM2,DISTRICT
SM2831,107,16.75,Quảng Trị,ADM1,DISTRICT
SM303974,106.4284,22.7037,Quảng Uyên,ADM2,DISTRICT
SM304512,105.793,19.7025,Quảng Xương,ADM2,DISTRICT
SM304418,106.8373,20.9324,Quảng Yên,ADM2,DISTRICT
SM304488,107.4937,16.5794,Quảng Điền,ADM2,DISTRICT
SM304101,106.6985,10.7725,Quận 1,ADM2,DISTRICT
SM304098,106.6667,10.7742,Quận 10,ADM2,DISTRICT
SM304099,106.6457,10.7625,Quận 11,ADM2,DISTRICT
SM304100,106.6556,10.8714,Quận 12,ADM2,DISTRICT
SM304102,106.7598,10.7823,Quận 2,ADM2,DISTRICT
SM304103,106.6913,10.7835,Quận 3,ADM2,DISTRICT
SM304104,106.706,10.7578,Quận 4,ADM2,DISTRICT
SM304105,106.6678,10.7548,Quận 5,ADM2,DISTRICT
SM304106,106.635,10.7431,Quận 6,ADM2,DISTRICT
SM304107,106.7291,10.7328,Quận 7,ADM2,DISTRICT
SM304109,106.6372,10.727,Quận 8,ADM2,DISTRICT
SM304110,106.8235,10.8201,Quận 9,ADM2,DISTRICT
SM304321,104.8791,19.7018,Quế Phong,ADM2,DISTRICT
SM304389,108.2616,15.7123,Quế Sơn,ADM2,DISTRICT
SM304433,106.1732,21.1461,Quế Võ,ADM2,DISTRICT
SM304146,105.6113,20.9706,Quốc Oai,ADM2,DISTRICT
SM304322,105.0917,19.5571,Quỳ Châu,ADM2,DISTRICT
SM304323,105.1601,19.3251,Quỳ Hợp,ADM2,DISTRICT
SM304324,105.6435,19.2272,Quỳnh Lưu,ADM2,DISTRICT
SM304467,103.6492,21.7722,Quỳnh Nhai,ADM2,DISTRICT
SM304492,106.3636,20.651,Quỳnh Phụ,ADM2,DISTRICT
SM11339,45.8017,41.9514,Qvareli,ADM2,DISTRICT
SM3949,65,45,Qyzylorda Oblysy (excl. Baikonur),ADM1,DISTRICT
SM13240,72.75,53.5,Qyzyltu Audany (excl. Beskol and Sokolovka),ADM2,DISTRICT
SM261954,47.8333,40.9167,Qəbələ,ADM1:ADM2,DISTRICT
SM283992,-15.0827,16.9299,R'Kiz,ADM2,DISTRICT
SM289682,-56.2198,-25.3536,R. I. 3 Corrales,ADM2,DISTRICT
SM274420,178.1667,-17.5,Ra,ADM2,DISTRICT
SM17801,49.95,26.8,Ra's Tannurah,ADM2,DISTRICT
SM296732,40.1464,36.7566,Ra's al-'Ayn,ADM2,DISTRICT
SM4265,72.985,5.7635,Raa Atholhu,ADM1:ADM2,DISTRICT
SM346788,24.821,64.5463,Raahe,ADM2:ADM3,DISTRICT
SM285567,6.2877,52.403,Raalte,ADM2,DISTRICT
SM274091,25.1473,59.3235,Raasiku,ADM2,DISTRICT
SM295806,43.2116,4.2433,Rab Dhuure,ADM2,DISTRICT
SM292454,22.243,46.7543,Rabagani,ADM2,DISTRICT
SM285222,5.7196,13.0133,Rabah,ADM2,DISTRICT
SM257030,41.05,-1.84,Rabai,ETHNICITY,ETHNICITY
SM16103,-66.1933,18.1708,Rabanal Barrio,ADM2,DISTRICT
SM281420,-6.8401,33.9911,Rabat,ADM2,DISTRICT
SM4278,-6.7803,34.0011,Rabat-Sale-Kenitra,ADM1,DISTRICT
SM288625,152.1667,-4.1833,Rabaul,ADM2,DISTRICT
SM243013,91.1689,25.8248,Rabha,LANGUAGE,LANGUAGE
SM17802,39.2333,22.8,Rabigh,ADM2,DISTRICT
SM275346,-90.5071,15.1124,Rabinal,ADM2,DISTRICT
SM457998,29.7892,9.2935,Rabkona County,ADM2,DISTRICT
SM17608,-61.0454,13.8338,Rabot,ADM2,DISTRICT
SM300402,-83.4021,34.8817,Rabun,ADM2,DISTRICT
SM292146,26.9671,46.3501,Racaciuni,ADM2,DISTRICT
SM290301,25.747,44.6604,Racari,ADM2,DISTRICT
SM290042,21.5879,44.9977,Racasdia,ADM2,DISTRICT
SM12659,-77.2796,17.8454,Race Course,ADM2,DISTRICT
SM296339,15.6329,46.4517,Race-Fram,ADM1:ADM2,DISTRICT
SM2848,43.1333,42.6333,Racha-Lechkhumi-Kvemo Svaneti,ADM1,DISTRICT
SM280903,35.8333,33.5333,Rachiaya,ADM2,DISTRICT
SM291280,24.6511,44.8743,Rachitele De Jos,ADM2,DISTRICT
SM292588,26.6885,47.7728,Rachiti,ADM2,DISTRICT
SM292147,27.3688,46.4477,Rachitoasa,ADM2,DISTRICT
SM290778,22.7674,45.6098,Rachitova,ADM2,DISTRICT
SM457929,34.739,-0.44,Rachuonyo District,ADM2,DISTRICT
SM288731,18.2692,50.087,Racibórz,ADM2,DISTRICT
SM303270,-87.8124,42.7299,Racine,ADM2,DISTRICT
SM291178,24.3874,46.7236,Raciu,ADM2,DISTRICT
SM11588,19.0146,47.1516,Rackevei Jaras,ADM2,DISTRICT
SM292369,26.8855,45.9979,Racoasa,ADM2,DISTRICT
SM292645,25.3956,46.0205,Racos,ADM2,DISTRICT
SM292148,26.781,46.6979,Racova,ADM2,DISTRICT
SM292226,24.3179,45.412,Racovita,ADM2,DISTRICT
SM291745,24.3514,45.666,Racovita,ADM2,DISTRICT
SM292011,21.6394,45.7044,Racovita,ADM2,DISTRICT
SM292685,27.4672,45.3063,Racovita,ADM2,DISTRICT
SM289910,26.8992,45.3503,Racoviteni,ADM2,DISTRICT
SM305009,44.9087,14.34,Rada',ADM2,DISTRICT
SM291834,26.2492,47.488,Radaseni,ADM2,DISTRICT
SM291836,25.9157,47.8509,Radauti,ADM2,DISTRICT
SM292589,26.813,48.2249,Radauti-prut,ADM2,DISTRICT
SM243015,108.41,12.979,Rade,LANGUAGE,LANGUAGE
SM296216,15.1408,46.0656,Radece,ADM1:ADM2,DISTRICT
SM296367,16.0483,46.6272,Radenci,ADM1:ADM2,DISTRICT
SM289822,23.7444,46.2493,Radesti,ADM2,DISTRICT
SM304886,44.99,13.4667,Radfan,ADM2,DISTRICT
SM302942,-80.5764,37.1318,Radford,ADM2,DISTRICT
SM268392,91.7093,27.3464,Radi,ADM2,DISTRICT
SM296285,15.2509,46.6073,Radlje ob Dravi,ADM1:ADM2,DISTRICT
SM305010,45.2744,14.4836,Radman Al Awad,ADM2,DISTRICT
SM262439,25.9333,42.3,Radnevo,ADM2,DISTRICT
SM305738,15.9214,46.1654,Radoboj,ADM2,DISTRICT
SM291918,25.1536,44.1438,Radoiesti-vale,ADM2,DISTRICT
SM288971,21.1619,51.3358,Radom,ADM2,DISTRICT
SM288970,21.1561,51.4172,Radom (City),ADM2,DISTRICT
SM262327,23.0167,42.4667,Radomir,ADM2,DISTRICT
SM291449,24.6782,44.1105,Radomiresti,ADM2,DISTRICT
SM288873,19.6304,51.0646,Radomsko,ADM2,DISTRICT
SM299855,29.25,50.5,Radomyshl's'kyi,ADM2,DISTRICT
SM289968,26.5326,44.1767,Radovanu,ADM2,DISTRICT
SM2849,22.5,41.65,Radoviš,ADM1,DISTRICT
SM296194,14.1942,46.3378,Radovljica,ADM1:ADM2,DISTRICT
SM290862,27.9734,46.9554,Raducaneni,ADM2,DISTRICT
SM291282,24.4723,44.8566,Radutesti,ADM2,DISTRICT
SM281352,23.5333,55.8167,Radviliškio,ADM2,DISTRICT
SM288848,18.5296,52.6236,Radziejów,ADM2,DISTRICT
SM288900,22.7076,51.7826,Radzyń Podlaski,ADM2,DISTRICT
SM298046,10.2704,36.7746,Radès,ADM2,DISTRICT
SM274092,24.9393,59.332,Rae,ADM2,DISTRICT
SM277325,81.25,26.25,Rae Bareli (2010-),ADM2,DISTRICT
SM12660,-76.7797,17.9677,Rae Town,ADM2,DISTRICT
SM11189,27.3858,58.1078,Raepina vald,ADM2,DISTRICT
SM262751,-66.5,-18.3333,Rafael Bustillo,ADM2,DISTRICT
SM282769,-97.1225,18.7961,Rafael Delgado,ADM2,DISTRICT
SM265455,-38.1979,-6.1971,Rafael Fernandes,ADM2,DISTRICT
SM271127,-75.9989,21.0293,Rafael Freyre,ADM2,DISTRICT
SM265456,-37.7369,-6.0489,Rafael Godeiro,ADM2,DISTRICT
SM267610,-39.5092,-12.5009,Rafael Jambeiro,ADM2,DISTRICT
SM282253,-97.8066,19.23,Rafael Lara Grajales,ADM2,DISTRICT
SM282770,-96.9819,19.5922,Rafael Lucio,ADM2,DISTRICT
SM303766,-70.7313,9.3757,Rafael Rangel,ADM2,DISTRICT
SM303744,-72.4475,7.5651,Rafael Urdaneta,ADM2,DISTRICT
SM289739,34.2606,31.2733,Rafah,ADM1:ADM2,DISTRICT
SM273869,34.2387,31.1154,Rafah,ADM2,DISTRICT
SM266849,-47.5868,-23.0328,Rafard,ADM2,DISTRICT
SM268602,24.2031,5.8101,Rafaï,ADM2,DISTRICT
SM17803,43.5833,29.1667,Rafha,ADM2,DISTRICT
SM285034,6.2533,10.1844,Rafi,ADM2,DISTRICT
SM291561,26.2057,44.85,Rafov,ADM2,DISTRICT
SM2850,-75.815,22.3708,Ragged Island,ADM1,DISTRICT
SM9575,70.6735,37.6608,Raghistan (2005-),ADM2,DISTRICT
SM304902,45.0694,15.7902,Raghwan,ADM2,DISTRICT
SM270381,-72.5099,7.5976,Ragonvalia,ADM2,DISTRICT
SM296641,16.1499,63.1921,Ragunda,ADM2,DISTRICT
SM277904,14.6,36.9167,Ragusa,ADM2,DISTRICT
SM304903,45.0885,14.951,Rahabah,ADM2,DISTRICT
SM9809,30.1667,53.1667,Rahachowski Rayon (excl. Rahachow),ADM2,DISTRICT
SM243020,121.059,-3.2463,Rahambuu,LANGUAGE,LANGUAGE
SM375444,70.5284,28.4602,Rahimyar Khan,ADM2:ADM3,DISTRICT
SM243021,92.4094,27.3759,Rahung,DIALECT,LANGUAGE
SM288572,146.4615,-5.723,Rai Coast,ADM2,DISTRICT
SM257052,143.49,-5.8,Raiapu Enga,ETHNICITY,ETHNICITY
SM257052,143.5,-5.52,Raiapu Enga,ETHNICITY,ETHNICITY
SM276939,76.91,16.16,Raichur (1997-),ADM2,DISTRICT
SM277455,83.306,22.0858,"Raigarh, CH (1998-)",ADM2,DISTRICT
SM277051,73.13,18.57,"Raigarh, MH",ADM2,DISTRICT
SM297849,125.4255,-8.6724,Railaco,ADM2,DISTRICT
SM17609,-60.9341,13.8707,Raillon Road,ADM2,DISTRICT
SM257054,-116,39,Railroad Valley Shoshoni,ETHNICITY,ETHNICITY
SM257054,-116,39,Railroad Valley Shoshoni,ETHNICITY,ETHNICITY
SM257054,-115.78,38.33,Railroad Valley Shoshoni,ETHNICITY,ETHNICITY
SM257054,-115.78,38.33,Railroad Valley Shoshoni,ETHNICITY,ETHNICITY
SM257055,133.81,-13.31,Rainbarngo,ETHNICITY,ETHNICITY
SM257055,133.81,-13.31,Rainbarngo,ETHNICITY,ETHNICITY
SM257055,134.4541,-13.231,Rainbarngo,ETHNICITY,ETHNICITY
SM257055,134.4541,-13.231,Rainbarngo,ETHNICITY,ETHNICITY
SM324175,-119.4009,58.5009,Rainbow Lake,ADM2:ADM3,DISTRICT
SM302740,-95.7934,32.8703,Rains,ADM2,DISTRICT
SM257056,138.68,-7.86,Raintana,ETHNICITY,ETHNICITY
SM296100,-55.1553,5.8661,Rainville,ADM2,DISTRICT
SM268880,-92.5003,48.4998,Rainy River,ADM2,DISTRICT
SM257057,-92,49,Rainy River Ojibwa,ETHNICITY,ETHNICITY
SM257057,-92,49,Rainy River Ojibwa,ETHNICITY,ETHNICITY
SM257057,-85.1,45.21,Rainy River Ojibwa,ETHNICITY,ETHNICITY
SM257057,-85.1,45.21,Rainy River Ojibwa,ETHNICITY,ETHNICITY
SM277456,81.6902,21.2562,Raipur (2012-),ADM2,DISTRICT
SM257058,58.05,28.66,Rais,ETHNICITY,ETHNICITY
SM276994,78.0833,23.25,Raisen,ADM2,DISTRICT
SM13757,25.1841,57.3567,Raiskuma pagasts,ADM2,DISTRICT
SM11259,-147.6722,-23.8633,Raivavae,ADM2,DISTRICT
SM276520,130.0665,-1.8652,Raja Ampat,ADM2,DISTRICT
SM243036,126.266,8.2934,Rajah Kabunsuwan Manobo,LANGUAGE,LANGUAGE
SM375448,70.1518,29.1593,Rajan Pur,ADM2:ADM3,DISTRICT
SM11682,78.8156,18.3863,Rajanna Sircilla,ADM2,DISTRICT
SM2851,73.8333,26.5833,Rajasthan,ADM1,DISTRICT
SM243040,88.0386,26.522,Rajbanshi,LANGUAGE,LANGUAGE
SM262246,89.55,23.6667,Rajbari,ADM2,DISTRICT
SM276995,76.75,23.8333,Rajgarh,ADM2,DISTRICT
SM243043,81.577,28.6151,Raji,LANGUAGE,LANGUAGE
SM289080,130.2987,42.2533,Rajin,ADM2,DISTRICT
SM276829,70.8333,22.3333,Rajkot (2013-),ADM2,DISTRICT
SM277457,81,21.1667,Rajnandgaon (1998-),ADM2,DISTRICT
SM243045,120.8744,-8.6581,Rajong,LANGUAGE,LANGUAGE
SM276884,74.25,33.25,Rajouri,ADM2,DISTRICT
SM243046,73.2472,24.5451,Rajput Garasia,LANGUAGE,LANGUAGE
SM277183,73.935,25.2382,Rajsamand,ADM2,DISTRICT
SM262274,88.6075,24.3696,Rajshahi District,ADM2,DISTRICT
SM2852,89,25,Rajshahi Division (2010-),ADM1,DISTRICT
SM305059,44.5042,16.6342,Rajuzah,ADM2,DISTRICT
SM4242,-161.091,-10.0317,Rakahanga,ADM1,DISTRICT
SM257090,160.98,-10.41,Rakahanga-Manihika,ETHNICITY,ETHNICITY
SM257090,160.98,-10.41,Rakahanga-Manihika,ETHNICITY,ETHNICITY
SM257090,-160.98,-10.41,Rakahanga-Manihika,ETHNICITY,ETHNICITY
SM257090,-160.98,-10.41,Rakahanga-Manihika,ETHNICITY,ETHNICITY
SM243049,-161.09,-10.03,Rakahanga-Manihiki,LANGUAGE,LANGUAGE
SM4722,31.4131,-0.7093,Rakai (2017-),ADM1:ADM2,DISTRICT
SM458495,140.1372,-23.4243,Rakaya,ETHNICITY,ETHNICITY
SM243051,93.1459,20.5274,Rakhine,LANGUAGE,LANGUAGE
SM2854,94.25,19,Rakhine,ADM1,DISTRICT
SM257091,94.93,18.6,Rakhine,ETHNICITY,ETHNICITY
SM257091,94.93,18.6,Rakhine,ETHNICITY,ETHNICITY
SM257091,92.64,20.49,Rakhine,ETHNICITY,ETHNICITY
SM257091,92.64,20.49,Rakhine,ETHNICITY,ETHNICITY
SM299766,24.2048,48.0549,Rakhivs'kyi,ADM2,DISTRICT
SM304816,47.7728,15.4618,Rakhyah,ADM2,DISTRICT
SM262322,24.1088,41.9997,Rakitovo,ADM2,DISTRICT
SM294682,35.9167,50.8333,Rakityanskiy rayon,ADM2,DISTRICT
SM458451,150.7807,-23.0692,Rakiwara,ETHNICITY,ETHNICITY
SM286283,11.3435,59.4282,Rakkestad,ADM2,DISTRICT
SM305625,16.3146,45.9323,Rakovec,ADM2,DISTRICT
SM305692,15.6496,44.9932,Rakovica,ADM2,DISTRICT
SM271186,13.7,50.1,Rakovník,ADM2,DISTRICT
SM262344,24.9833,42.2667,Rakovski,ADM2,DISTRICT
SM345409,26.3616,59.3453,Rakvere Linn,ADM2:ADM3,DISTRICT
SM289116,127.7878,39.9311,Rakwon,ADM2,DISTRICT
SM303143,-81.2486,37.7714,Raleigh,ADM2,DISTRICT
SM301608,-91.522,39.5277,Ralls,ADM2,DISTRICT
SM243056,94.7381,25.0201,Ralte,LANGUAGE,LANGUAGE
SM243061,-83.8571,11.5007,Rama,LANGUAGE,LANGUAGE
SM257095,-84.27,10.78,Rama,ETHNICITY,ETHNICITY
SM257095,-84.29,10.78,Rama,ETHNICITY,ETHNICITY
SM289749,35.2059,31.9051,Ramallah and Al-Bireh,ADM1:ADM2,DISTRICT
SM260820,-60.0833,-33.5,Ramallo,ADM2,DISTRICT
SM297755,101.4344,6.4899,Raman,ADM2,DISTRICT
SM276940,77.35,12.65,Ramanagara,ADM2,DISTRICT
SM277226,78.7,9.37,Ramanathapuram,ADM2,DISTRICT
SM243062,-67.3591,-14.302,Ramanos,LANGUAGE,LANGUAGE
SM14969,151.6646,7.4135,Ramanum Municipality,ADM2,DISTRICT
SM13758,24.994,57.9734,Ramatas pagasts,ADM2,DISTRICT
SM276885,75.1861,33.323,Ramban,ADM2,DISTRICT
SM12661,-77.988,18.3467,Ramble (Hanover),ADM2,DISTRICT
SM18500,-76.6263,17.9679,Ramble (St. Thomas),ADM2,DISTRICT
SM370449,5.845,49.8308,Rambrouch,ADM1:ADM2:ADM3,DISTRICT
SM17610,-60.9238,13.8306,Rameau,ADM2,DISTRICT
SM375305,86.257,27.5374,Ramechhap,ADM2:ADM3,DISTRICT
SM17056,38.2333,55.5667,Ramenskiy Rayon,ADM2,DISTRICT
SM294770,36.0833,57.3333,Rameshkovskiy rayon,ADM2,DISTRICT
SM289823,23.526,46.3156,Ramet,ADM2,DISTRICT
SM289824,23.5658,46.4382,Rametea,ADM2,DISTRICT
SM276911,85.5606,23.6307,Ramgarh,ADM2,DISTRICT
SM264663,-54.0062,-25.0915,Ramilândia,ADM2,DISTRICT
SM257103,138.92,-35.27,Ramindjeri,ETHNICITY,ETHNICITY
SM257103,138.92,-35.27,Ramindjeri,ETHNICITY,ETHNICITY
SM257103,138.7112,-35.4607,Ramindjeri,ETHNICITY,ETHNICITY
SM257103,138.7112,-35.4607,Ramindjeri,ETHNICITY,ETHNICITY
SM270811,-73.3114,5.3197,Ramiriquí,ADM2,DISTRICT
SM290043,21.7226,45.4653,Ramna,ADM2,DISTRICT
SM289911,27.1329,45.3784,Ramnicelu,ADM2,DISTRICT
SM292686,27.5134,45.2979,Ramnicelu,ADM2,DISTRICT
SM289912,27.0516,45.3832,Ramnicu Sarat,ADM2,DISTRICT
SM292228,24.3522,45.0945,Ramnicu Valcea,ADM2,DISTRICT
SM243071,152.451,-4.1731,Ramoaaina,LANGUAGE,LANGUAGE
SM18358,-54.5941,-32.2523,Ramon Trigo,ADM2,DISTRICT
SM294988,39.3333,51.9167,Ramonskiy rayon,ADM2,DISTRICT
SM243072,154.9,-5.9702,Ramopa,LANGUAGE,LANGUAGE
SM283297,-101.0821,25.7446,Ramos Arizpe,ADM2,DISTRICT
SM243074,120.356,-2.1399,Rampi,LANGUAGE,LANGUAGE
SM277326,79,29,Rampur,ADM2,DISTRICT
SM277589,50.5333,36.7667,Ramsar,ADM2,DISTRICT
SM302059,-98.7201,48.2689,Ramsey,ADM2,DISTRICT
SM301397,-93.0996,45.0171,Ramsey,ADM2,DISTRICT
SM276785,-4.3888,54.3218,Ramsey,ADM1:ADM2,DISTRICT
SM278003,35.9948,32.585,Ramtha,ADM2,DISTRICT
SM261198,-62.1667,-23,Ramón Lista,ADM2,DISTRICT
SM271852,-69.1667,18.5,Ramón Santana,ADM2,DISTRICT
SM275946,-83.3536,15.0847,Ramón Villeda Morales,ADM2,DISTRICT
SM286076,14.14,66.3112,Rana,ADM2,DISTRICT
SM243078,80.4419,28.6483,Rana Tharu,LANGUAGE,LANGUAGE
SM257109,117.08,5.37,Ranau Dusun,ETHNICITY,ETHNICITY
SM266850,-50.9199,-22.3468,Rancharia,ADM2,DISTRICT
SM16104,-66.8967,18.111,Ranchera Barrio,ADM2,DISTRICT
SM276912,85.3708,23.3313,Ranchi (2007-),ADM2,DISTRICT
SM324114,-114.412,49.9323,Ranchland No. 66,ADM2:ADM3,DISTRICT
SM264667,-50.9189,-23.0589,Rancho Alegre,ADM2,DISTRICT
SM264664,-52.9835,-24.304,Rancho Alegre d'Oeste,ADM2,DISTRICT
SM271839,-70.4482,18.7154,Rancho Arriba,ADM2,DISTRICT
SM285460,-85.573,13.2604,Rancho Grande,ADM2,DISTRICT
SM266266,-49.0716,-27.7073,Rancho Queimado,ADM2,DISTRICT
SM271067,-80.15,22.3833,Ranchuelo,ADM2,DISTRICT
SM269069,-72.3779,-40.1369,Ranco,ADM2,DISTRICT
SM260849,-64.75,-35.5,Rancul,ADM2,DISTRICT
SM286133,5.6206,58.9987,Randaberg,ADM2,DISTRICT
SM302741,-101.897,34.9659,Randall,ADM2,DISTRICT
SM271691,10.069,56.5156,Randers (2007-),ADM2,DISTRICT
SM303144,-79.8758,38.7747,Randolph,ADM2,DISTRICT
SM302004,-79.8062,35.7103,Randolph,ADM2,DISTRICT
SM301609,-92.4971,39.4402,Randolph,ADM2,DISTRICT
SM300756,-85.0113,40.1576,Randolph,ADM2,DISTRICT
SM300670,-89.8253,38.0521,Randolph,ADM2,DISTRICT
SM302409,-91.0277,36.3415,Randolph,ADM2,DISTRICT
SM300404,-84.7542,31.7626,Randolph,ADM2,DISTRICT
SM300297,-85.4591,33.2938,Randolph,ADM2,DISTRICT
SM261454,151.25,-33.9167,Randwick,ADM2,DISTRICT
SM243090,10.58,8.001,Rang,LANGUAGE,LANGUAGE
SM297255,101.705,6.255,Rangae,ADM2,DISTRICT
SM262232,92.2,22.6333,Rangamati,ADM2,DISTRICT
SM11683,78.1684,17.2788,Rangareddi (2016-),ADM2,DISTRICT
SM11620,-19.8333,63.6667,Rangarthing Eystra,ADM2,DISTRICT
SM11621,-19.6603,63.9474,Rangarthing Ytra,ADM2,DISTRICT
SM303620,-70.8333,8.75,Rangel,ADM2,DISTRICT
SM250416,36.39,-3.77,Rangi,ETHNICITY,ETHNICITY
SM250416,36.39,-3.77,Rangi,ETHNICITY,ETHNICITY
SM250416,36,-5,Rangi,ETHNICITY,ETHNICITY
SM250416,36,-5,Rangi,ETHNICITY,ETHNICITY
SM11260,-147.6466,-14.9592,Rangiroa,ADM2,DISTRICT
SM286555,175.75,-39.6667,Rangitikei,ADM2,DISTRICT
SM243087,80.0845,29.2278,Rangkas,LANGUAGE,LANGUAGE
SM15268,125.7,38.95,Rangnang,ADM2,DISTRICT
SM262091,29.7621,-3.1557,Rango,ADM2,DISTRICT
SM262282,89.25,25.7333,Rangpur District,ADM2,DISTRICT
SM2855,89,25.8333,Rangpur Division,ADM1,DISTRICT
SM243086,88.4536,26.3834,Rangpuri,LANGUAGE,LANGUAGE
SM289032,127.1667,40.8333,Rangrim,ADM2,DISTRICT
SM305849,44.8828,36.2571,Rania,ADM2,DISTRICT
SM13759,26.1732,57.2149,Rankas pagasts,ADM2,DISTRICT
SM301483,-89.9458,32.2641,Rankin,ADM2,DISTRICT
SM278163,140.4945,42.8051,Rankoshi,ADM2,DISTRICT
SM2856,22.15,42.2167,Rankovce,ADM1,DISTRICT
SM13760,21.9933,56.7812,Ranku pagasts,ADM2,DISTRICT
SM284878,8.5169,11.4789,Rano,ADM2,DISTRICT
SM257121,168.15,-16.18,Ranon,ETHNICITY,ETHNICITY
SM2857,98.75,10.1,Ranong,ADM1,DISTRICT
SM461536,156.6,-8,Ranongga,ETHNICITY,ETHNICITY
SM297583,100.2832,7.8139,Ranot,ADM2,DISTRICT
SM302060,-97.6575,46.4562,Ransom,ADM2,DISTRICT
SM17804,42.75,21.0833,Ranyah,ADM2,DISTRICT
SM295290,-14.1332,15.0507,Ranérou Ferlo,ADM2,DISTRICT
SM243099,144.511,-4.8524,Rao,LANGUAGE,LANGUAGE
SM297859,10.1801,36.9311,Raoued,ADM2,DISTRICT
SM11261,-144.3209,-27.6012,Rapa,ADM2,DISTRICT
SM243100,-109.342,-27.113,Rapanui,LANGUAGE,LANGUAGE
SM301142,-92.5332,31.1986,Rapides,ADM2,DISTRICT
SM2858,24.7,58.9667,Rapla,ADM1:ADMX,DISTRICT
SM11190,24.7179,59.0331,Rapla vald,ADM2,DISTRICT
SM243103,154.834,-5.7872,Rapoisi,LANGUAGE,LANGUAGE
SM290779,23.1154,45.879,Rapoltu Mare,ADM2,DISTRICT
SM262866,-44.078,-2.4311,Raposa,ADM2,DISTRICT
SM263815,-43.7902,-19.9762,Raposos,ADM2,DISTRICT
SM302943,-78.1593,38.6847,Rappahannock,ADM2,DISTRICT
SM243104,145.671,-4.9935,Rapting,LANGUAGE,LANGUAGE
SM243105,109.539,1.2703,Rara Bakati',LANGUAGE,LANGUAGE
SM295440,161.4248,-9.4495,Raroisuu,ADM2,DISTRICT
SM4243,-159.7725,-21.2327,Rarotonga,ADM1,DISTRICT
SM2859,56.0406,25.4612,Ras Al-Khaimah,ADM1:ADM2,DISTRICT
SM2859,56.0406,25.4612,Ras Al-Khaimah,ADM1:ADM2,DISTRICT
SM2859,56,25.6667,Ras Al-Khaimah,ADM1:ADM2,DISTRICT
SM2859,56,25.6667,Ras Al-Khaimah,ADM1:ADM2,DISTRICT
SM298057,10.1346,37.2112,Ras Jebel,ADM2,DISTRICT
SM304959,45.2814,13.7231,Rasad,ADM2,DISTRICT
SM297678,99.6677,7.9378,Rasada,ADM2,DISTRICT
SM243111,136.352,-2.3761,Rasawa,LANGUAGE,LANGUAGE
SM291837,26.1899,47.3682,Rasca,ADM2,DISTRICT
SM281333,23.1167,55.3667,Raseinių,ADM2,DISTRICT
SM283767,103.9063,47.4128,Rashaant,ADM2,DISTRICT
SM283600,101.4292,49.1206,Rashaant,ADM2,DISTRICT
SM257124,53.48,18.86,Rashid,ETHNICITY,ETHNICITY
SM9576,68.1476,33.6976,Rashidan,ADM2,DISTRICT
SM305995,70.36,39.2349,Rasht,ADM2,DISTRICT
SM277732,49.6333,37.2667,Rasht,ADM2,DISTRICT
SM297563,104.1862,15.3464,Rasi Salai,ADM2,DISTRICT
SM291746,24.0738,45.6976,Rasinari,ADM2,DISTRICT
SM305715,16.7043,46.1832,Rasinja,ADM2,DISTRICT
SM2860,21.2147,43.5592,Rasinski,ADM1:ADM2,DISTRICT
SM291919,25.5712,43.9998,Rasmiresti,ADM2,DISTRICT
SM292646,25.4639,45.5911,Rasnov,ADM2,DISTRICT
SM262670,28.9167,55.8333,Rasony,ADM2,DISTRICT
SM290190,27.9696,44.2592,Rasova,ADM2,DISTRICT
SM17057,41.9167,52.5833,Rasskazovskiy Rayon,ADM2,DISTRICT
SM290400,23.2841,43.8871,Rast,ADM2,DISTRICT
SM291179,24.9693,46.9709,Rastolita,ADM2,DISTRICT
SM290589,25.6876,44.0842,Rasuceni,ADM2,DISTRICT
SM375300,85.4823,28.1764,Rasuwa,ADM2:ADM3,DISTRICT
SM2862,130.3664,42.3361,Rasŏn,ADM1,DISTRICT
SM297174,100.4988,13.6763,Rat Burana,ADM2,DISTRICT
SM257127,121.1,12.49,Ratagnan,ETHNICITY,ETHNICITY
SM243118,121.187,12.2785,Ratagnon,LANGUAGE,LANGUAGE
SM243117,124.858,1.0759,Ratahan,LANGUAGE,LANGUAGE
SM2863,99.5333,13.5833,Ratchaburi,ADM1,DISTRICT
SM297533,101.2814,13.7866,Ratchasan,ADM2,DISTRICT
SM297175,100.5372,13.7603,Ratchathewi,ADM2,DISTRICT
SM291283,25.1586,44.7169,Ratesti,ADM2,DISTRICT
SM243122,73.186,22.309,Rathawi,LANGUAGE,LANGUAGE
SM243121,74.9958,21.4375,Rathwi Bareli,LANGUAGE,LANGUAGE
SM276996,75.1667,23.5833,Ratlam,ADM2,DISTRICT
SM277052,73.5,17,Ratnagiri (1989-),ADM2,DISTRICT
SM2864,80.5833,6.5831,Ratnapura,ADM1:ADM2,DISTRICT
SM243127,154.8006,-5.5245,Ratsua,DIALECT,LANGUAGE
SM297640,103.9054,15.3378,Rattana Buri,ADM2,DISTRICT
SM297584,100.1952,7.0749,Rattaphum,ADM2,DISTRICT
SM290781,22.8646,45.5072,Rau De Mori,ADM2,DISTRICT
SM291747,24.0613,45.624,Rau Sadului,ADM2,DISTRICT
SM291357,26.4117,47.2615,Raucesti,ADM2,DISTRICT
SM260821,-58.8333,-36.5833,Rauch,ADM2,DISTRICT
SM15362,-55.5755,-25.1538,Raul Arsenio Oviedo (2008-),ADM2,DISTRICT
SM263816,-42.4089,-20.0142,Raul Soares,ADM2,DISTRICT
SM286424,7.6861,62.5681,Rauma,ADM2,DISTRICT
SM11226,21.9419,61.1102,Rauma (2004-),ADM2,DISTRICT
SM4030,25.6564,57.3322,Raunas Novads,ADM1,DISTRICT
SM13761,25.6411,57.342,Raunas pagasts,ADM2,DISTRICT
SM292590,27.2255,47.5594,Rauseni,ADM2,DISTRICT
SM278164,145.1408,44.048,Rausu,ADM2,DISTRICT
SM375311,85.2831,26.989,Rautahat,ADM2:ADM3,DISTRICT
SM243129,81.1617,28.9518,Raute,LANGUAGE,LANGUAGE
SM301657,-114.1207,46.0817,Ravalli,ADM2,DISTRICT
SM11799,57.15,31.3,Ravar,ADM2,DISTRICT
SM17611,-61.0464,13.8102,Raveneau,ADM2,DISTRICT
SM277953,11.9833,44.4167,Ravenna,ADM2,DISTRICT
SM261304,120.1534,-33.6838,Ravensthorpe,ADM2,DISTRICT
SM17612,-60.9762,14.0005,Ravine Chabot,ADM2,DISTRICT
SM17613,-61.0289,13.8526,Ravine Claire,ADM2,DISTRICT
SM17614,-60.9685,13.9316,Ravine Poisson,ADM2,DISTRICT
SM17615,-60.9881,14.0033,Ravine Touterelle,ADM2,DISTRICT
SM305423,14.9372,45.3745,Ravna Gora,ADM2,DISTRICT
SM296286,14.9754,46.5318,Ravne na Koroškem,ADM1:ADM2,DISTRICT
SM243132,75.6265,12.3231,Ravula,LANGUAGE,LANGUAGE
SM243137,145.937,-5.8316,Rawa,LANGUAGE,LANGUAGE
SM288874,20.3853,51.7623,Rawa,ADM2,DISTRICT
SM12049,41.9165,34.4689,Rawah District,ADM2,DISTRICT
SM13340,-170.7167,-3.7167,Rawaki,ADM2,DISTRICT
SM375475,73.2309,33.4299,Rawalpindi,ADM2:ADM3,DISTRICT
SM243135,97.0738,27.1027,Rawang,LANGUAGE,LANGUAGE
SM243134,80.8005,30.0742,Rawat,LANGUAGE,LANGUAGE
SM288805,16.9494,51.6451,Rawicz,ADM2,DISTRICT
SM300963,-101.0757,39.7852,Rawlins,ADM2,DISTRICT
SM243143,141.603,-2.842,Rawo,LANGUAGE,LANGUAGE
SM295405,161.9709,-10.6915,Rawo,ADM2,DISTRICT
SM260968,-68.4667,-31.7,Rawson,ADM2,DISTRICT
SM261108,-65.1018,-43.1234,Rawson,ADM2,DISTRICT
SM11527,-90.0819,15.8915,Raxruja,ADM2,DISTRICT
SM301610,-93.9899,39.3524,Ray,ADM2,DISTRICT
SM277126,83.5,19.4,Rayagada,ADM2,DISTRICT
SM293216,129.4167,49.75,Raychikhinsk gorsovet,ADM2,DISTRICT
SM304779,44.0563,15.7406,Raydah,ADM2,DISTRICT
SM2865,43.6667,14.6333,Raymah,ADM1,DISTRICT
SM323831,-112.6563,49.4624,Raymond,ADM2:ADM3,DISTRICT
SM16105,-66.9651,18.0542,Rayo Barrio,ADM2,DISTRICT
SM282187,-100.1295,25.0949,Rayones,ADM2,DISTRICT
SM2866,101.4333,12.8333,Rayong,ADM1,DISTRICT
SM13241,80,43,Rayymbek Audany (excl. Kegen),ADM2,DISTRICT
SM282595,-110.564,29.7621,Rayón,ADM2,DISTRICT
SM282490,-99.6074,21.8383,Rayón,ADM2,DISTRICT
SM281956,-99.577,19.1389,Rayón,ADM2,DISTRICT
SM283183,-92.9976,17.2006,Rayón,ADM2,DISTRICT
SM243146,-93.1006,17.2953,Rayón Zoque,LANGUAGE,LANGUAGE
SM11800,57.1194,37.9306,Raz and Jargalan,ADM2,DISTRICT
SM243147,50.002,36.267,Razajerdi,LANGUAGE,LANGUAGE
SM11801,49,35.4167,Razan,ADM2,DISTRICT
SM2867,59,35.25,Razavi Khorasan,ADM1,DISTRICT
SM291359,26.5615,47.0742,Razboienii De Jos,ADM2,DISTRICT
SM262360,26.5167,43.5333,Razgrad,ADM2,DISTRICT
SM2868,26.6,43.65,Razgrad,ADM1,DISTRICT
SM304921,43.2608,16.937,Razih,ADM2,DISTRICT
SM296368,16.2719,46.5238,Razkrižje,ADM1:ADM2,DISTRICT
SM262301,23.4667,41.95,Razlog,ADM2,DISTRICT
SM290302,25.5291,44.9359,Razvad,ADM2,DISTRICT
SM283447,14.5061,35.8736,Raħal Ġdid,ADM1:ADM2,DISTRICT
SM305673,14.0838,45.08,Raša,ADM2,DISTRICT
SM2861,20.6528,43.2669,Raški,ADM1:ADM2,DISTRICT
SM305597,15.344,44.2813,Ražanac,ADM2,DISTRICT
SM274674,-0.9667,51.45,Reading,ADM1:ADM2,DISTRICT
SM12662,-77.9473,18.4372,Reading,ADM2,DISTRICT
SM302742,-101.523,31.3662,Reagan,ADM2,DISTRICT
SM302743,-99.8222,29.8317,Real,ADM2,DISTRICT
SM16106,-66.5606,18.0867,Real Barrio,ADM2,DISTRICT
SM264668,-53.5466,-25.6743,Realeza,ADM2,DISTRICT
SM260850,-64.25,-35.25,Realicó,ADM2,DISTRICT
SM11262,-136.4429,-18.4761,Reao,ADM2,DISTRICT
SM276886,74.8223,33.2074,Reasi,ADM2,DISTRICT
SM271718,9.7778,56.8056,Rebild,ADM2,DISTRICT
SM275117,8.8344,3.7242,Rebola,ADM2,DISTRICT
SM264669,-50.6312,-25.683,Rebouças,ADM2,DISTRICT
SM292517,24.4998,47.3259,Rebra,ADM2,DISTRICT
SM292307,27.5725,46.8723,Rebricea,ADM2,DISTRICT
SM292986,82.25,53,Rebrikhinskiy rayon,ADM2,DISTRICT
SM292518,24.4657,47.3199,Rebrisoara,ADM2,DISTRICT
SM278165,141.0284,45.3826,Rebun,ADM2,DISTRICT
SM292012,21.5263,45.8265,Recas,ADM2,DISTRICT
SM291020,23.4861,47.6311,Recea,ADM2,DISTRICT
SM292647,24.9409,45.7431,Recea,ADM2,DISTRICT
SM291284,25.0274,44.5426,Recea,ADM2,DISTRICT
SM290125,23.5455,47.1216,Recea-cristur,ADM2,DISTRICT
SM270881,-72.7864,5.2285,Recetor,ADM2,DISTRICT
SM262591,30.1667,52.25,Rechytsa,ADM2,DISTRICT
SM290226,25.9555,45.8191,Reci,ADM2,DISTRICT
SM264877,-34.9529,-8.0117,Recife,ADM2,DISTRICT
SM370505,6.0089,49.5625,Reckange-sur-Mess,ADM1:ADM2:ADM3,DISTRICT
SM263817,-42.4433,-21.5285,Recreio,ADM2,DISTRICT
SM286798,-77.4167,-10,Recuay,ADM2,DISTRICT
SM267160,-47.0733,-8.6685,Recursolândia,ADM2,DISTRICT
SM12663,-77.6259,17.9378,Red Bank,ADM2,DISTRICT
SM323971,-113.7974,52.2816,Red Deer,ADM2:ADM3,DISTRICT
SM323972,-113.7909,52.0995,Red Deer County,ADM2:ADM3,DISTRICT
SM243154,104.993,23.1868,Red Gelao,LANGUAGE,LANGUAGE
SM12664,-78.1128,18.3055,Red Hills,ADM2,DISTRICT
SM12665,-76.8609,18.0678,Red Hills (Rural),ADM2,DISTRICT
SM12666,-76.8099,18.03,Red Hills Gardens,ADM2,DISTRICT
SM12667,-76.8403,18.0595,Red Hills/ Sterling Castle,ADM2,DISTRICT
SM301398,-96.0953,47.8717,Red Lake,ADM2,DISTRICT
SM302744,-95.0504,33.6207,Red River,ADM2,DISTRICT
SM301143,-93.3399,32.0932,Red River,ADM2,DISTRICT
SM2869,35.6667,19.9667,Red Sea,ADM1,DISTRICT
SM301748,-100.4769,40.1758,Red Willow,ADM2,DISTRICT
SM370450,5.8908,49.7656,Redange,ADM1:ADM2:ADM3,DISTRICT
SM281367,5.8873,49.7972,Redange,ADM1:ADM2,DISTRICT
SM274675,-1,54.55,Redcar and Cleveland,ADM1:ADM2,DISTRICT
SM323824,-110.7812,50.0734,Redcliff,ADM2:ADM3,DISTRICT
SM257149,82,18,Reddy,ETHNICITY,ETHNICITY
SM291450,24.3068,44.0416,Redea,ADM2,DISTRICT
SM265849,-53.6092,-27.5683,Redentora,ADM2,DISTRICT
SM264081,-50.1812,-8.0601,Redenção,ADM2,DISTRICT
SM267839,-38.7701,-4.2648,Redenção,ADM2,DISTRICT
SM266851,-45.502,-23.2567,Redenção da Serra,ADM2,DISTRICT
SM265100,-44.538,-9.5821,Redenção do Gurguéia,ADM2,DISTRICT
SM298089,8.1706,34.4008,Redeyef,ADM2,DISTRICT
SM291360,26.5667,46.75,Rediu,ADM2,DISTRICT
SM261659,153.2483,-27.5379,Redland,ADM2,DISTRICT
SM2870,-62.3456,16.9389,Redonda,ADM1,DISTRICT
SM289488,-7.5966,38.5801,Redondo,ADM2,DISTRICT
SM17616,-60.9615,14.0683,Reduit,ADM2,DISTRICT
SM17617,-60.9534,14.0659,Reduit Orchard,ADM2,DISTRICT
SM17618,-60.9556,14.0662,Reduit Park,ADM2,DISTRICT
SM263818,-41.9427,-20.2287,Reduto,ADM2,DISTRICT
SM324019,-113.1188,53.9529,Redwater,ADM2:ADM3,DISTRICT
SM301399,-95.2538,44.4037,Redwood,ADM2,DISTRICT
SM12668,-76.9854,18.2103,Redwood,ADM2,DISTRICT
SM243158,30.4945,6.3223,Reel,LANGUAGE,LANGUAGE
SM302745,-103.6932,31.3232,Reeves,ADM2,DISTRICT
SM298385,38.7674,39.9013,Refahiye,ADM2,DISTRICT
SM305771,46.1,31.7,Refai,ADM2,DISTRICT
SM283184,-93.2267,17.8688,Reforma,ADM2,DISTRICT
SM14371,-94.451,16.3999,Reforma de Pineda,ADM2,DISTRICT
SM12669,-77.5775,18.451,Refuge,ADM2,DISTRICT
SM302746,-97.1595,28.3216,Refugio,ADM2,DISTRICT
SM260049,65.1735,30.1226,Reg,ADM2,DISTRICT
SM259998,63.5,30.8333,Reg,ADM2,DISTRICT
SM265101,-42.4817,-6.2784,Regeneração,ADM2,DISTRICT
SM266852,-51.2921,-22.2233,Regente Feijó,ADM2,DISTRICT
SM277940,16.0833,38.3167,Reggio Di Calabria,ADM2,DISTRICT
SM277954,10.6167,44.6167,Reggio Nell'Emilia,ADM2,DISTRICT
SM291180,24.7052,46.7653,Reghin,ADM2,DISTRICT
SM292370,26.8365,45.7967,Reghiu,ADM2,DISTRICT
SM257152,33,-2,Regi,ETHNICITY,ETHNICITY
SM10446,-73.8489,8.7223,Regidor,ADM2,DISTRICT
SM18593,8.0071,51.3297,Regierungsbezirk Arnsberg,ADM2,DISTRICT
SM18589,8.75,50,Regierungsbezirk Darmstadt,ADM2,DISTRICT
SM18594,8.7833,51.9666,Regierungsbezirk Detmold,ADM2,DISTRICT
SM18590,8.7686,50.6981,Regierungsbezirk Giessen,ADM2,DISTRICT
SM18591,9.4,51.0833,Regierungsbezirk Kassel,ADM2,DISTRICT
SM18595,6.8666,50.7833,Regierungsbezirk Koeln,ADM2,DISTRICT
SM18582,10.8333,49.3333,Regierungsbezirk Mittelfranken,ADM2,DISTRICT
SM18596,7.4333,51.9666,Regierungsbezirk Muenster,ADM2,DISTRICT
SM18562,9.6667,49.0833,Regierungsbezirk Stuttgart,ADM2,DISTRICT
SM18583,10,50,Regierungsbezirk Unterfranken,ADM2,DISTRICT
SM266853,-49.1903,-21.8749,Reginópolis,ADM2,DISTRICT
SM266855,-47.8602,-24.5216,Registro,ADM2,DISTRICT
SM2872,-70.6472,-33.4727,Región Metropolitana de Santiago,ADM1,DISTRICT
SM2871,-1.5,38,Región de Murcia,ADM1,DISTRICT
SM271091,-82.3211,23.1099,Regla,ADM2,DISTRICT
SM297962,9.8419,34.8803,Regueb,ADM2,DISTRICT
SM289489,-7.467,38.398,Reguengos de Monsaraz,ADM2,DISTRICT
SM250287,-5.25,26.86,Reguibat,ETHNICITY,ETHNICITY
SM250287,-5.25,26.86,Reguibat,ETHNICITY,ETHNICITY
SM250287,-13,22,Reguibat,ETHNICITY,ETHNICITY
SM250287,-13,22,Reguibat,ETHNICITY,ETHNICITY
SM4447,-7.9314,32.2458,Rehamna,ADM2,DISTRICT
SM284504,17.0833,-23.3167,Rehoboth Rural,ADM2,DISTRICT
SM268943,8.7082,47.7475,Reiat,ADM2,DISTRICT
SM278644,130.0724,32.4851,Reihoku,ADM2,DISTRICT
SM285627,4.1317,51.4204,Reimerswaal,ADM2,DISTRICT
SM370434,6.2678,49.8686,Reisdorf,ADM1:ADM2:ADM3,DISTRICT
SM275920,-87.4271,13.8428,Reitoca,ADM2,DISTRICT
SM243162,102.434,-3.3306,Rejang,LANGUAGE,LANGUAGE
SM257155,103.28,-3.17,Rejang,ETHNICITY,ETHNICITY
SM243163,114.169,2.719,Rejang Kayan,LANGUAGE,LANGUAGE
SM276730,102.7167,-3.4333,Rejang Lebong,ADM2,DISTRICT
SM243167,82.4996,18.1213,Reli,LANGUAGE,LANGUAGE
SM2873,0.9167,35.75,Relizane,ADM1,DISTRICT
SM265850,-52.0539,-29.1267,Relvado,ADM2,DISTRICT
SM243168,141.034,-8.4153,Rema,LANGUAGE,LANGUAGE
SM298005,9.6398,31.7056,Remada,ADM2,DISTRICT
SM267611,-42.3696,-9.5412,Remanso,ADM2,DISTRICT
SM276321,111.4667,-6.7833,Rembang,ADM2,DISTRICT
SM243169,134.308,-12.9608,Rembarrnga,LANGUAGE,LANGUAGE
SM13762,24.8257,56.7801,Rembates pagasts,ADM2,DISTRICT
SM243170,120.973,-8.5336,Rembong-Wangka,LANGUAGE,LANGUAGE
SM286689,-81.8116,8.2025,Remedios,ADM2,DISTRICT
SM271056,-79.5304,22.3041,Remedios,ADM2,DISTRICT
SM270252,-74.5482,7.0219,Remedios,ADM2,DISTRICT
SM290710,25.4229,46.8191,Remetea,ADM2,DISTRICT
SM292455,22.3528,46.7296,Remetea,ADM2,DISTRICT
SM291021,23.5289,47.5213,Remetea Chioarului,ADM2,DISTRICT
SM291022,23.5874,47.9963,Remeti,ADM2,DISTRICT
SM297796,125.6667,-8.6167,Remexio,ADM2,DISTRICT
SM370489,6.3669,49.545,Remich,ADM1:ADM2:ADM3,DISTRICT
SM281372,6.3178,49.5432,Remich,ADM1:ADM2,DISTRICT
SM257158,-74.25,-7.82,Remo,ETHNICITY,ETHNICITY
SM243175,-73.5443,-6.1373,Remo of the Blanco river,LANGUAGE,LANGUAGE
SM243174,-73.2358,-6.6793,Remo of the Jaquirana river,LANGUAGE,LANGUAGE
SM243173,-74.0833,-7.3333,Remo of the Moa river,LANGUAGE,LANGUAGE
SM285057,3.7286,7.0029,Remo-North,ADM2,DISTRICT
SM270132,-74.5844,10.6505,Remolino,ADM2,DISTRICT
SM243176,145.782,-5.0249,Rempi,LANGUAGE,LANGUAGE
SM13763,22.7091,56.7505,Remtes pagasts,ADM2,DISTRICT
SM243178,110.82,1.2631,Remun,LANGUAGE,LANGUAGE
SM264285,-35.8597,-6.956,Remígio,ADM2,DISTRICT
SM286678,-82.7667,8.7167,Renacimiento,ADM2,DISTRICT
SM264670,-52.9343,-26.2146,Renascença,ADM2,DISTRICT
SM13764,25.4494,57.7144,Rencenu pagasts,ADM2,DISTRICT
SM283601,99.6724,51.1032,Renchinlkhümbe,ADM2,DISTRICT
SM286360,11.0769,61.89,Rendalen,ADM2,DISTRICT
SM13765,22.2171,57.0594,Rendas pagasts,ADM2,DISTRICT
SM243179,37.3608,2.2707,Rendille,LANGUAGE,LANGUAGE
SM250644,37.32,2.43,Rendille,ETHNICITY,ETHNICITY
SM250644,37.32,2.43,Rendille,ETHNICITY,ETHNICITY
SM250644,37.5,2.3,Rendille,ETHNICITY,ETHNICITY
SM250644,37.5,2.3,Rendille,ETHNICITY,ETHNICITY
SM268881,-77.0828,45.5001,Renfrew,ADM2,DISTRICT
SM274730,-4.5,55.8333,Renfrewshire,ADM1:ADM2,DISTRICT
SM243180,107.859,14.6066,Rengao,LANGUAGE,LANGUAGE
SM243180,107.859,14.6066,Rengao,LANGUAGE,LANGUAGE
SM243180,107.9459,14.456,Rengao,LANGUAGE,LANGUAGE
SM243180,107.9459,14.456,Rengao,LANGUAGE,LANGUAGE
SM243183,92.3679,21.7109,Rengmitca,LANGUAGE,LANGUAGE
SM299621,28.2791,45.4562,Reniis'kyi,ADM2,DISTRICT
SM285801,5.7872,51.9904,Renkum,ADM2,DISTRICT
SM261729,140.7486,-34.1749,Renmark Paringa,ADM2,DISTRICT
SM286188,10.0049,62.8286,Rennebu,ADM2,DISTRICT
SM257161,160.21,-11.6,Rennell Island,ETHNICITY,ETHNICITY
SM257161,160.21,-11.6,Rennell Island,ETHNICITY,ETHNICITY
SM257161,160.21,-11.6,Rennell Island,ETHNICITY,ETHNICITY
SM257161,160.42,-11.74,Rennell Island,ETHNICITY,ETHNICITY
SM257161,160.42,-11.74,Rennell Island,ETHNICITY,ETHNICITY
SM257161,160.42,-11.74,Rennell Island,ETHNICITY,ETHNICITY
SM257161,159.9,-11.4,Rennell Island,ETHNICITY,ETHNICITY
SM257161,159.9,-11.4,Rennell Island,ETHNICITY,ETHNICITY
SM257161,159.9,-11.4,Rennell Island,ETHNICITY,ETHNICITY
SM2874,160.1546,-11.6052,Rennell and Bellona,ADM1,DISTRICT
SM243186,160.265,-11.6237,Rennell-Bellona,LANGUAGE,LANGUAGE
SM243185,160.219,-11.615,Rennellese Sign Language,LANGUAGE,LANGUAGE
SM300964,-98.086,37.953,Reno,ADM2,DISTRICT
SM301904,-73.5097,42.7111,Rensselaer,ADM2,DISTRICT
SM285600,5.5335,52.0729,Renswoude,ADM2,DISTRICT
SM297111,104.6566,17.0508,Renu Nakhon,ADM2,DISTRICT
SM302061,-101.6578,48.7191,Renville,ADM2,DISTRICT
SM301400,-94.9471,44.7268,Renville,ADM2,DISTRICT
SM294989,38.6667,51.1667,Rep'evskiy rayon,ADM2,DISTRICT
SM243187,167.619,-16.3265,Repanbitip,DIALECT,LANGUAGE
SM289684,-55.8031,-25.584,Repatriación,ADM2,DISTRICT
SM291023,24.4014,47.8321,Repedea,ADM2,DISTRICT
SM270665,-75.1283,10.5094,Repelón,ADM2,DISTRICT
SM300965,-97.6506,39.8278,Republic,ADM2,DISTRICT
SM2875,17.4,44.7333,Repuplika Srpska (excl. Brcko),ADM1:ADM2,DISTRICT
SM286782,-74.1667,-6,Requena,ADM2,DISTRICT
SM243190,41.7065,4.1166,Rer Bare,LANGUAGE,LANGUAGE
SM243188,145.716,-5.5136,Rerau,LANGUAGE,LANGUAGE
SM243191,167.651,-16.2934,Rerep,LANGUAGE,LANGUAGE
SM267840,-40.616,-4.1384,Reriutaba,ADM2,DISTRICT
SM2876,21.0167,41.0333,Resen,ADM1,DISTRICT
SM289399,-7.9512,41.0769,Resende,ADM2,DISTRICT
SM265304,-44.4849,-22.4407,Resende,ADM2,DISTRICT
SM263819,-44.2811,-20.8413,Resende Costa,ADM2,DISTRICT
SM264672,-50.9314,-24.5731,Reserva,ADM2,DISTRICT
SM263222,-58.4585,-14.9295,Reserva do Cabaçal,ADM2,DISTRICT
SM264671,-51.9465,-25.8478,Reserva do Iguaçu,ADM2,DISTRICT
SM243192,4.624,10.7705,Reshe,LANGUAGE,LANGUAGE
SM299653,34.0792,49.5618,Reshetylivs'kyi,ADM2,DISTRICT
SM17619,-60.942,14.0029,Resinard/Babonneau,ADM2,DISTRICT
SM290044,21.9119,45.2984,Resita,ADM2,DISTRICT
SM263820,-41.1434,-19.2026,Resplendor,ADM2,DISTRICT
SM263821,-43.7844,-21.0946,Ressaquinha,ADM2,DISTRICT
SM271895,-71.5833,19.3,Restauración,ADM2,DISTRICT
SM268795,-67.0407,47.7414,Restigouche,ADM2,DISTRICT
SM266856,-47.5206,-20.6765,Restinga,ADM2,DISTRICT
SM265852,-53.3401,-29.8272,Restinga Seca,ADM2,DISTRICT
SM270285,-73.5956,4.2941,Restrepo,ADM2,DISTRICT
SM270625,-76.5674,3.7959,Restrepo,ADM2,DISTRICT
SM243194,-71.3578,-2.4814,Resígaro,LANGUAGE,LANGUAGE
SM243194,-71.3578,-2.4814,Resígaro,LANGUAGE,LANGUAGE
SM243194,-71.6052,-1.1695,Resígaro,LANGUAGE,LANGUAGE
SM243194,-71.6052,-1.1695,Resígaro,LANGUAGE,LANGUAGE
SM243195,124.36,-8.2248,Reta,LANGUAGE,LANGUAGE
SM275258,-91.6862,14.5339,Retalhuleu,ADM2,DISTRICT
SM2877,-91.8333,14.3333,Retalhuleu,ADM1,DISTRICT
SM12670,-77.767,18.2074,Retirement (St. Elizabeth),ADM2,DISTRICT
SM18499,-77.88,18.4374,Retirement (St. James),ADM2,DISTRICT
SM270253,-75.5149,6.0591,Retiro,ADM2,DISTRICT
SM16107,-67.0225,18.0695,Retiro Barrio,ADM2,DISTRICT
SM267612,-39.4065,-11.4773,Retirolândia,ADM2,DISTRICT
SM12671,-77.0159,18.3787,Retreat (St. Mary),ADM2,DISTRICT
SM18498,-76.3679,17.8776,Retreat (St. Thomas),ADM2,DISTRICT
SM12672,-77.9008,18.289,Retrieve,ADM2,DISTRICT
SM17620,-61.0453,13.7786,Reunion,ADM2,DISTRICT
SM183,55.6034,-21.1008,Reunion,ADM0:ADM1:ADM2,DISTRICT
SM183,55.6034,-21.1008,Reunion,ADM0:ADM1:ADM2,DISTRICT
SM183,55.6034,-21.1008,Reunion,ADM0:ADM1:ADM2,DISTRICT
SM183,55.6021,-21.1004,Reunion,ADM0:ADM1:ADM2,DISTRICT
SM183,55.6021,-21.1004,Reunion,ADM0:ADM1:ADM2,DISTRICT
SM183,55.6021,-21.1004,Reunion,ADM0:ADM1:ADM2,DISTRICT
SM183,55.6,-21.1,Reunion,ADM0:ADM1:ADM2,DISTRICT
SM183,55.6,-21.1,Reunion,ADM0:ADM1:ADM2,DISTRICT
SM183,55.6,-21.1,Reunion,ADM0:ADM1:ADM2,DISTRICT
SM285943,5.1579,51.3805,Reusel-De Mierden,ADM2,DISTRICT
SM261872,10.55,47.4167,Reutte,ADM2,DISTRICT
SM290927,27.1087,44.6925,Reviga,ADM2,DISTRICT
SM12673,-78.2844,18.2426,Revival,ADM2,DISTRICT
SM296124,20.1157,48.683,Revúca,ADM2,DISTRICT
SM276997,81.5,24.75,Rewa,ADM2,DISTRICT
SM274408,178.3333,-18.0833,Rewa,ADM2,DISTRICT
SM276852,76.6161,28.1961,Rewari,ADM2,DISTRICT
SM277632,51.2774,35.3745,Rey,ADM2,DISTRICT
SM14372,-96.8151,17.2145,Reyes Etla,ADM2,DISTRICT
SM243199,-67.0833,-14.8333,Reyesano,LANGUAGE,LANGUAGE
SM257162,-66.87,-14.33,Reyesano,ETHNICITY,ETHNICITY
SM298456,36.5684,36.2636,Reyhanlı,ADM2,DISTRICT
SM277842,-22.3332,65.6099,Reykhólahreppur,ADM2,DISTRICT
SM277829,-22.5995,63.924,Reykjanesbær,ADM2,DISTRICT
SM277787,-21.8264,64.1211,Reykjavík,ADM2,DISTRICT
SM301612,-90.9691,37.3623,Reynolds,ADM2,DISTRICT
SM327660,-95.9338,49.7083,Reynolds,ADM2:ADM3,DISTRICT
SM282662,-98.3083,26.0752,Reynosa,ADM2,DISTRICT
SM13766,27.3385,56.5104,Rezekne,ADM2,DISTRICT
SM3989,27.34,56.5103,Rezekne,ADM1,DISTRICT
SM3990,27.1667,56.5,Rezeknes Novads,ADM1,DISTRICT
SM2878,28.8333,47.6667,Rezina,ADM1,DISTRICT
SM298958,37.3765,40.4328,Reşadiye,ADM2,DISTRICT
SM305569,17.4236,45.2613,Rešetari,ADM2,DISTRICT
SM302613,-84.9244,35.6087,Rhea,ADM2,DISTRICT
SM285803,6.0455,52.0339,Rheden,ADM2,DISTRICT
SM268906,7.8333,47.5405,Rheinfelden,ADM2,DISTRICT
SM2879,7.5,49.6667,Rheinland-Pfalz,ADM1,DISTRICT
SM268934,9.579,47.3777,Rheintal,ADM2,DISTRICT
SM285601,5.5706,51.9615,Rhenen,ADM2,DISTRICT
SM327752,-97.6629,49.1636,Rhineland,ADM2:ADM3,DISTRICT
SM2880,-71.4998,41.7504,Rhode Island,ADM1,DISTRICT
SM274754,-3.5,51.6667,"Rhondda, Cynon, Taff",ADM1:ADM2,DISTRICT
SM274423,4.5304,45.8913,Rhône,ADM2,DISTRICT
SM277082,91.8271,25.89,Ri Bhoi,ADM2,DISTRICT
SM275119,8.7591,3.3846,Riaba,ADM2,DISTRICT
SM267614,-39.3811,-11.8456,Riachao do Jacuipe,ADM2,DISTRICT
SM267128,-37.7868,-11.0128,Riachao do dantas,ADM2,DISTRICT
SM267161,-48.1362,-6.4603,Riachinho,ADM2,DISTRICT
SM263822,-45.8942,-16.2446,Riachinho,ADM2,DISTRICT
SM264290,-35.6752,-7.2634,Riacho,ADM2,DISTRICT
SM265102,-44.679,-9.8366,Riacho Frio,ADM2,DISTRICT
SM265457,-37.9756,-5.9263,Riacho da Cruz,ADM2,DISTRICT
SM264879,-35.8263,-8.054,Riacho das Almas,ADM2,DISTRICT
SM265458,-38.3314,-6.2828,Riacho de Santana,ADM2,DISTRICT
SM267615,-43.1747,-13.8429,Riacho de Santana,ADM2,DISTRICT
SM264288,-36.1355,-7.6614,Riacho de Santo Antônio,ADM2,DISTRICT
SM263823,-42.9755,-16.0661,Riacho dos Machados,ADM2,DISTRICT
SM267129,-37.225,-10.716,Riachuelo,ADM2,DISTRICT
SM265459,-35.8813,-5.836,Riachuelo,ADM2,DISTRICT
SM262867,-46.7267,-7.7702,Riachão,ADM2,DISTRICT
SM267613,-45.125,-11.7748,Riachão das Neves,ADM2,DISTRICT
SM264286,-35.6232,-6.5552,Riachão do Bacamarte,ADM2,DISTRICT
SM264287,-35.303,-7.1704,Riachão do Poço,ADM2,DISTRICT
SM14197,-15.9762,18.0086,Riad,ADM2,DISTRICT
SM268145,-49.5363,-15.3289,Rialma,ADM2,DISTRICT
SM243209,92.0321,23.6803,Riang (India),LANGUAGE,LANGUAGE
SM243208,98.8333,24.0521,Riang (Myanmar),LANGUAGE,LANGUAGE
SM243210,138.484,-7.5075,Riantana,LANGUAGE,LANGUAGE
SM268146,-49.4479,-15.4747,Rianápolis,ADM2,DISTRICT
SM2881,101.749,0.5005,Riau (2002-),ADM1,DISTRICT
SM262868,-47.2979,-5.9344,Ribamar Fiquene,ADM2,DISTRICT
SM303589,-65.7712,9.2768,Ribas,ADM2,DISTRICT
SM263078,-53.5756,-20.6995,Ribas do Rio Pardo,ADM2,DISTRICT
SM283942,38.2125,-15.019,Ribaue,ADM2,DISTRICT
SM266866,-49.0362,-24.6111,Ribeira,ADM2,DISTRICT
SM289283,-17.0358,32.7059,Ribeira Brava,ADM2,DISTRICT
SM2882,-24.2,16.6167,Ribeira Brava,ADM1,DISTRICT
SM289380,-25.4684,37.8046,Ribeira Grande,ADM2,DISTRICT
SM2883,-25.1167,17.1333,Ribeira Grande,ADM1,DISTRICT
SM2884,-23.65,14.9833,Ribeira Grande de Santiago,ADM1,DISTRICT
SM289375,-7.7967,41.5102,Ribeira de Pena,ADM2,DISTRICT
SM267616,-38.3873,-11.0056,Ribeira do Amparo,ADM2,DISTRICT
SM265103,-42.5761,-8.0971,Ribeira do Piauí,ADM2,DISTRICT
SM267617,-38.5012,-10.6331,Ribeira do Pombal,ADM2,DISTRICT
SM266864,-47.8238,-21.2225,Ribeirao Preto,ADM2,DISTRICT
SM265105,-45.472,-8.0872,Ribeiro Gonçalves,ADM2,DISTRICT
SM264880,-35.3934,-8.5076,Ribeirão,ADM2,DISTRICT
SM266857,-48.1877,-22.0342,Ribeirão Bonito,ADM2,DISTRICT
SM266858,-48.7721,-24.2611,Ribeirão Branco,ADM2,DISTRICT
SM263223,-51.7146,-12.7352,Ribeirão Cascalheira,ADM2,DISTRICT
SM264673,-49.7862,-23.2956,Ribeirão Claro,ADM2,DISTRICT
SM266859,-47.5715,-20.4425,Ribeirão Corrente,ADM2,DISTRICT
SM266862,-48.3401,-24.1757,Ribeirão Grande,ADM2,DISTRICT
SM266863,-46.3999,-23.7041,Ribeirão Pires,ADM2,DISTRICT
SM263826,-45.0788,-21.1538,Ribeirão Vermelho,ADM2,DISTRICT
SM263825,-44.0696,-19.7715,Ribeirão das Neves,ADM2,DISTRICT
SM267618,-40.6589,-15.3856,Ribeirão do Largo,ADM2,DISTRICT
SM264674,-50.432,-23.3863,Ribeirão do Pinhal,ADM2,DISTRICT
SM266860,-49.9463,-22.7411,Ribeirão do Sul,ADM2,DISTRICT
SM266861,-51.5784,-21.7847,Ribeirão dos índios,ADM2,DISTRICT
SM263100,-52.7591,-16.5014,Ribeirãozinho,ADM2,DISTRICT
SM267130,-37.3783,-10.5218,Ribeirópolis,ADM2,DISTRICT
SM303720,-63.5476,10.4389,Ribero,ADM2,DISTRICT
SM290782,22.7964,46.206,Ribita,ADM2,DISTRICT
SM296260,14.7161,45.7374,Ribnica,ADM1:ADM2,DISTRICT
SM296276,15.2607,46.5253,Ribnica na Pohorju,ADM1:ADM2,DISTRICT
SM305693,15.3511,45.5771,Ribnik,ADM2,DISTRICT
SM313189,16.8136,44.4492,Ribnik,ADM2:ADM3,DISTRICT
SM243220,110.207,0.3219,Ribun,LANGUAGE,LANGUAGE
SM264291,-37.6402,-6.4714,Ricaho dos Cavalos,ADM2,DISTRICT
SM303885,-68.6667,9.3333,Ricaurte,ADM2,DISTRICT
SM270340,-78.0554,1.1879,Ricaurte,ADM2,DISTRICT
SM270043,-74.7543,4.2913,Ricaurte,ADM2,DISTRICT
SM301401,-93.2967,44.3543,Rice,ADM2,DISTRICT
SM300967,-98.201,38.3471,Rice,ADM2,DISTRICT
SM302901,-111.2445,41.6323,Rich,ADM2,DISTRICT
SM301749,-95.7175,40.1251,Richardson,ADM2,DISTRICT
SM17621,-60.9203,13.9364,Riche Fond,ADM2,DISTRICT
SM17622,-60.9291,13.9378,Riche Fond/La Belle Vie,ADM2,DISTRICT
SM17623,-60.9239,13.9365,Riche Fond/New Village,ADM2,DISTRICT
SM303271,-90.4295,43.3756,Richland,ADM2,DISTRICT
SM302466,-80.903,34.0218,Richland,ADM2,DISTRICT
SM302062,-96.9483,46.2647,Richland,ADM2,DISTRICT
SM302151,-82.5365,40.7747,Richland,ADM2,DISTRICT
SM301658,-104.5613,47.7879,Richland,ADM2,DISTRICT
SM300671,-88.0851,38.7124,Richland,ADM2,DISTRICT
SM301144,-91.7635,32.4178,Richland,ADM2,DISTRICT
SM301905,-74.1496,40.5834,Richmond,ADM2,DISTRICT
SM302005,-79.7478,35.0059,Richmond,ADM2,DISTRICT
SM300405,-82.0736,33.3596,Richmond,ADM2,DISTRICT
SM268828,-60.7652,45.6501,Richmond,ADM2,DISTRICT
SM261660,142.9138,-20.5697,Richmond,ADM2,DISTRICT
SM12674,-77.4634,18.0256,Richmond (Manchester),ADM2,DISTRICT
SM18497,-76.883,18.2111,Richmond (St. Mary),ADM2,DISTRICT
SM18318,-76.7297,37.9371,Richmond County (excl. Richmond),ADM2,DISTRICT
SM12675,-77.3764,18.0517,Richmond Park (Clarendon),ADM2,DISTRICT
SM18496,-76.8046,18.0056,Richmond Park (St. Andrew),ADM2,DISTRICT
SM12676,-76.5904,17.9744,Richmond Vale,ADM2,DISTRICT
SM261455,153.0863,-29.0268,Richmond Valley,ADM2,DISTRICT
SM285689,4.5979,51.8697,Ridderkerk,ADM2,DISTRICT
SM10193,-101.1727,50.8423,Riding Mountain West,ADM2,DISTRICT
SM3987,26.8256,56.3581,Riebinu Novads,ADM1,DISTRICT
SM13767,26.7611,56.347,Riebinu pagasts,ADM2,DISTRICT
SM261829,13.4667,48.2333,Ried im Innkreis,ADM2,DISTRICT
SM243223,102.591,19.5084,Rien,LANGUAGE,LANGUAGE
SM292456,22.4451,46.5688,Rieni,ADM2,DISTRICT
SM14150,21.9327,55.7238,Rietavas,ADM2,DISTRICT
SM277962,12.9507,42.3913,Rieti,ADM2,DISTRICT
SM2885,37.3833,33.5,Rif Dimashq,ADM1,DISTRICT
SM266867,-47.4384,-20.0782,Rifaina,ADM2,DISTRICT
SM250646,-4.67,34.76,Riffian,ETHNICITY,ETHNICITY
SM250646,-4.67,34.76,Riffian,ETHNICITY,ETHNICITY
SM250646,-4.67,34.76,Riffian,ETHNICITY,ETHNICITY
SM250646,-4,35,Riffian,ETHNICITY,ETHNICITY
SM250646,-4,35,Riffian,ETHNICITY,ETHNICITY
SM250646,-4,35,Riffian,ETHNICITY,ETHNICITY
SM250646,-3.25,34.92,Riffian,ETHNICITY,ETHNICITY
SM250646,-3.25,34.92,Riffian,ETHNICITY,ETHNICITY
SM250646,-3.25,34.92,Riffian,ETHNICITY,ETHNICITY
SM10335,14.3522,14.2647,Rig-Rig,ADM2,DISTRICT
SM13768,24.1227,56.9781,Riga,ADM2,DISTRICT
SM4011,24.1217,56.9778,Riga City,ADM1,DISTRICT
SM11802,59.15,28.4333,Rigan,ADM2,DISTRICT
SM288606,147.8333,-9.75,Rigo,ADM2,DISTRICT
SM346841,24.6004,60.7261,Riihimäki,ADM2:ADM3,DISTRICT
SM257163,42.06,17.91,Rijal Al Ma,ETHNICITY,ETHNICITY
SM17805,42.1667,18.15,Rijal Alma,ADM2,DISTRICT
SM285035,5.1883,11.0993,Rijau,ADM2,DISTRICT
SM275976,14.4376,45.3269,Rijeka,ADM2,DISTRICT
SM285568,6.4252,52.29,Rijssen,ADM2,DISTRICT
SM285692,4.3279,52.0349,Rijswijk,ADM2,DISTRICT
SM13769,27.0034,56.6349,Rikavas pagasts,ADM2,DISTRICT
SM243230,-58.1942,-11.1887,Rikbaktsa,LANGUAGE,LANGUAGE
SM278450,141.577,39.0408,Rikuzentakata,ADM2,DISTRICT
SM262540,23.3167,42.15,Rila,ADM2,DISTRICT
SM300968,-96.7352,39.2965,Riley,ADM2,DISTRICT
SM237914,143.789,-14.529,Rimanggudhinma,LANGUAGE,LANGUAGE
SM459268,143.7825,-14.4868,Rimanggudinhma,ETHNICITY,ETHNICITY
SM11263,-152.8079,-22.6404,Rimatara,ADM2,DISTRICT
SM296125,20.0333,48.3833,Rimavská Sobota,ADM2,DISTRICT
SM323973,-114.2348,52.6388,Rimbey,ADM2:ADM3,DISTRICT
SM284920,7.6932,12.8393,Rimi,ADM2,DISTRICT
SM284879,8.2765,11.9323,RiminGad,ADM2,DISTRICT
SM277955,12.5186,44.0241,Rimini,ADM2,DISTRICT
SM16110,-66.155,18.1306,Rincon Barrio (Cayey),ADM2,DISTRICT
SM16111,-66.1695,18.1509,Rincon Barrio (Cidra),ADM2,DISTRICT
SM16108,-65.9909,18.2496,Rincon Barrio (Gurabo),ADM2,DISTRICT
SM16109,-66.9423,18.0927,Rincon Barrio (Sabana Grande),ADM2,DISTRICT
SM16112,-67.2508,18.339,Rincon Barrio-Pueblo,ADM2,DISTRICT
SM260837,-66.5,-22.5,Rinconada,ADM2,DISTRICT
SM266868,-47.9969,-21.5739,Rincão,ADM2,DISTRICT
SM2887,-67.2371,18.3397,Rincón,ADM1,DISTRICT
SM243233,-96.3144,17.4451,Rincón Zapotec,LANGUAGE,LANGUAGE
SM281493,-102.3167,22.2333,Rincón de Romos,ADM2,DISTRICT
SM286425,9.2127,63.0551,Rindal,ADM2,DISTRICT
SM286109,10.1418,61.5298,Ringebu,ADM2,DISTRICT
SM286324,10.2566,60.1602,Ringerike,ADM2,DISTRICT
SM300862,-94.244,40.7352,Ringgold,ADM2,DISTRICT
SM243237,123.311,-10.5515,Ringgou,LANGUAGE,LANGUAGE
SM284797,9.104,12.1861,Ringim,ADM2,DISTRICT
SM271692,8.3967,56.01,Ringkøbing-Skjern,ADM2,DISTRICT
SM286361,10.9503,60.8822,Ringsaker,ADM2,DISTRICT
SM271721,11.8172,55.4472,Ringsted,ADM2,DISTRICT
SM458510,140.2648,-22.8793,Ringu Ringu,ETHNICITY,ETHNICITY
SM289145,126.0947,38.2775,Rinsan,ADM2,DISTRICT
SM266869,-50.7204,-21.6631,Rinópolis,ADM2,DISTRICT
SM16116,-65.7234,18.2708,Rio Abajo Barrio (Ceiba),ADM2,DISTRICT
SM16117,-66.1899,18.1871,Rio Abajo Barrio (Cidra),ADM2,DISTRICT
SM16113,-65.7849,18.1407,Rio Abajo Barrio (Humacao),ADM2,DISTRICT
SM16114,-66.6975,18.311,Rio Abajo Barrio (Utuado),ADM2,DISTRICT
SM16115,-66.382,18.43,Rio Abajo Barrio (Vega Baja),ADM2,DISTRICT
SM263827,-43.7654,-20.117,Rio Acima,ADM2,DISTRICT
SM301848,-106.6931,36.5096,Rio Arriba,ADM2,DISTRICT
SM16119,-66.6866,18.3357,Rio Arriba Barrio (Arecibo),ADM2,DISTRICT
SM16118,-67.0505,18.2771,Rio Arriba Barrio (Añasco),ADM2,DISTRICT
SM16120,-65.708,18.2899,Rio Arriba Barrio (Fajardo),ADM2,DISTRICT
SM16121,-66.393,18.3987,Rio Arriba Barrio (Vega Baja),ADM2,DISTRICT
SM16122,-66.5114,18.3825,Rio Arriba Poniente Barrio,ADM2,DISTRICT
SM16123,-66.4713,18.3868,Rio Arriba Saliente Barrio,ADM2,DISTRICT
SM264675,-50.735,-25.7407,Rio Azul,ADM2,DISTRICT
SM268021,-40.3594,-19.2238,Rio Bananal,ADM2,DISTRICT
SM16124,-66.0935,18.3211,Rio Barrio (Guaynabo),ADM2,DISTRICT
SM16125,-65.739,18.1868,Rio Barrio (Naguabo),ADM2,DISTRICT
SM303366,-108.2172,39.9798,Rio Blanco,ADM2,DISTRICT
SM16126,-65.7904,18.2507,Rio Blanco Barrio,ADM2,DISTRICT
SM264676,-51.4462,-23.8022,Rio Bom,ADM2,DISTRICT
SM265305,-42.6192,-22.7439,Rio Bonito,ADM2,DISTRICT
SM264678,-52.6215,-25.5482,Rio Bonito do Iguaçu,ADM2,DISTRICT
SM263101,-58.1245,-15.3112,Rio Branco,ADM2,DISTRICT
SM262788,-68.1553,-10.0699,Rio Branco,ADM2,DISTRICT
SM264679,-51.3421,-24.356,Rio Branco do Ivaí,ADM2,DISTRICT
SM264680,-49.356,-25.0404,Rio Branco do Sul,ADM2,DISTRICT
SM263079,-54.4591,-21.7754,Rio Brilhante,ADM2,DISTRICT
SM12677,-77.4804,18.4641,Rio Bueno,ADM2,DISTRICT
SM275137,9.8221,2.3382,Rio Campo,ADM2,DISTRICT
SM16127,-66.4561,18.0071,Rio Canas Abajo Barrio (Juana Díaz),ADM2,DISTRICT
SM16128,-67.1101,18.23,Rio Canas Abajo Barrio (Mayagüez),ADM2,DISTRICT
SM16129,-66.4482,18.054,Rio Canas Arriba Barrio (Juana Díaz),ADM2,DISTRICT
SM16130,-67.0585,18.2282,Rio Canas Arriba Barrio (Mayagüez),ADM2,DISTRICT
SM16131,-67.1258,18.2532,Rio Canas Barrio (Añasco),ADM2,DISTRICT
SM16132,-66.0497,18.2839,Rio Canas Barrio (Caguas),ADM2,DISTRICT
SM16133,-67.0362,18.2318,Rio Canas Barrio (Las Marías),ADM2,DISTRICT
SM263828,-42.6608,-20.1289,Rio Casca,ADM2,DISTRICT
SM266870,-47.6138,-22.4099,Rio Claro,ADM2,DISTRICT
SM265306,-44.0772,-22.7798,Rio Claro,ADM2,DISTRICT
SM266105,-62.7464,-9.7188,Rio Crespo,ADM2,DISTRICT
SM10499,-84.2127,10.3449,Rio Cuarto,ADM2,DISTRICT
SM263831,-43.5205,-20.8536,Rio Espera,ADM2,DISTRICT
SM264881,-35.2194,-8.6981,Rio Formoso,ADM2,DISTRICT
SM266272,-49.2012,-28.0844,Rio Fortuna,ADM2,DISTRICT
SM303367,-106.3832,37.5825,Rio Grande,ADM2,DISTRICT
SM265854,-52.3501,-32.0781,Rio Grande,ADM2,DISTRICT
SM16137,-67.2287,18.3711,Rio Grande Barrio (Aguada),ADM2,DISTRICT
SM16135,-66.572,18.23,Rio Grande Barrio (Jayuya),ADM2,DISTRICT
SM16134,-66.4121,18.2953,Rio Grande Barrio (Morovis),ADM2,DISTRICT
SM16136,-67.2375,18.3611,Rio Grande Barrio (Rincón),ADM2,DISTRICT
SM16138,-65.8308,18.38,Rio Grande Barrio-Pueblo,ADM2,DISTRICT
SM246484,-106.124,35.9053,Rio Grande Tewa,LANGUAGE,LANGUAGE
SM266872,-46.3735,-23.7335,Rio Grande da Serra,ADM2,DISTRICT
SM2890,-36.5,-5.75,Rio Grande do Norte,ADM1,DISTRICT
SM265106,-43.1455,-7.8606,Rio Grande do Piauí,ADM2,DISTRICT
SM2891,-53.5,-30,Rio Grande do Sul,ADM1,DISTRICT
SM16140,-66.2514,18.2082,Rio Hondo Barrio (Comerío),ADM2,DISTRICT
SM16139,-67.1272,18.1656,Rio Hondo Barrio (Mayagüez),ADM2,DISTRICT
SM10447,-76.4833,5.1833,Rio Iro,ADM2,DISTRICT
SM16141,-66.3111,17.9975,Rio Jueyes Barrio,ADM2,DISTRICT
SM16142,-66.2645,18.3972,Rio Lajas Barrio (Dorado),ADM2,DISTRICT
SM16143,-66.2945,18.3742,Rio Lajas Barrio (Toa Alto),ADM2,DISTRICT
SM265419,-35.9106,-9.5139,Rio Largo,ADM2,DISTRICT
SM289324,-8.9381,39.3429,Rio Maior,ADM2,DISTRICT
SM263832,-44.3403,-20.2492,Rio Manso,ADM2,DISTRICT
SM264082,-49.8289,-7.4097,Rio Maria,ADM2,DISTRICT
SM266274,-49.5678,-26.4157,Rio Negrinho,ADM2,DISTRICT
SM264681,-49.7158,-26.09,Rio Negro,ADM2,DISTRICT
SM263080,-54.9795,-19.4462,Rio Negro,ADM2,DISTRICT
SM263833,-43.1507,-21.4724,Rio Novo,ADM2,DISTRICT
SM268022,-40.9398,-20.7833,Rio Novo do Sul,ADM2,DISTRICT
SM263834,-46.2922,-19.1989,Rio Paranaiba,ADM2,DISTRICT
SM265855,-52.4406,-30.0418,Rio Pardo,ADM2,DISTRICT
SM18575,-42.5732,-15.73,Rio Pardo de Minas,ADM2,DISTRICT
SM9995,-43.1611,-19.9749,Rio Piracicaba,ADM2:ADM3,DISTRICT
SM263838,-43.1716,-21.2541,Rio Pomba,ADM2,DISTRICT
SM263839,-43.8698,-22.0449,Rio Preto,ADM2,DISTRICT
SM267345,-59.6078,-2.4929,Rio Preto da Eva,ADM2,DISTRICT
SM16144,-66.8888,18.2118,Rio Prieto Barrio (Lares),ADM2,DISTRICT
SM16145,-66.8298,18.1477,Rio Prieto Barrio (Yauco),ADM2,DISTRICT
SM268147,-48.802,-17.8177,Rio Quente,ADM2,DISTRICT
SM10448,-76.75,5.5167,Rio Quito,ADM2,DISTRICT
SM267623,-37.9039,-11.53,Rio Real,ADM2,DISTRICT
SM266275,-49.7792,-27.9557,Rio Rufino,ADM2,DISTRICT
SM271793,-70.077,19.5502,Rio San Juan,ADM2,DISTRICT
SM267165,-47.3839,-9.6448,Rio Sono,ADM2,DISTRICT
SM264292,-35.0371,-6.7967,Rio Tinto,ADM2,DISTRICT
SM268148,-51.0422,-17.7189,Rio Verde,ADM2,DISTRICT
SM263081,-54.9147,-18.8672,Rio Verde de Mato Grosso,ADM2,DISTRICT
SM263840,-43.0742,-18.2687,Rio Vermelho,ADM2,DISTRICT
SM10450,-73.8185,8.6041,Rio Viejo (2007-),ADM2,DISTRICT
SM267162,-46.7745,-11.3682,Rio da Conceição,ADM2,DISTRICT
SM266267,-51.0296,-26.9073,Rio das Antas,ADM2,DISTRICT
SM265307,-43.5758,-22.161,Rio das Flores,ADM2,DISTRICT
SM265308,-41.9492,-22.4545,Rio das Ostras,ADM2,DISTRICT
SM266871,-47.5972,-22.8408,Rio das Pedras,ADM2,DISTRICT
SM267619,-41.687,-13.6217,Rio de Contas,ADM2,DISTRICT
SM265309,-43.3307,-22.9201,Rio de Janeiro,ADM2,DISTRICT
SM2888,-42.5,-22.25,Rio de Janeiro,ADM1:ADMX,DISTRICT
SM267621,-42.1661,-14.2267,Rio do Antônio,ADM2,DISTRICT
SM266268,-50.112,-26.8609,Rio do Campo,ADM2,DISTRICT
SM265460,-35.3838,-5.3823,Rio do Fogo,ADM2,DISTRICT
SM266269,-49.8455,-27.1487,Rio do Oeste,ADM2,DISTRICT
SM267622,-42.1775,-13.1113,Rio do Pires,ADM2,DISTRICT
SM263829,-40.56,-16.6834,Rio do Prado,ADM2,DISTRICT
SM266270,-49.6353,-27.1959,Rio do Sul,ADM2,DISTRICT
SM263830,-42.8925,-20.2061,Rio doce,ADM2,DISTRICT
SM267164,-48.4431,-9.209,Rio dos Bois,ADM2,DISTRICT
SM266271,-49.3723,-26.6109,Rio dos Cedros,ADM2,DISTRICT
SM265853,-52.8764,-27.2552,Rio dos índios,ADM2,DISTRICT
SM273598,-78.6333,-1.6667,Riobamba,ADM2,DISTRICT
SM270579,-75.8333,3.5,Rioblanco,ADM2,DISTRICT
SM270626,-76.4159,4.1028,Riofrío,ADM2,DISTRICT
SM10451,-73.0833,11.1667,Riohacha (1995-),ADM2,DISTRICT
SM286843,-77.4167,-6,Rioja,ADM2,DISTRICT
SM266873,-49.7112,-20.0538,Riolândia,ADM2,DISTRICT
SM270503,-73.3333,7.5,Rionegro,ADM2,DISTRICT
SM270254,-75.4167,6.1667,Rionegro,ADM2,DISTRICT
SM16146,-65.9655,18.0114,Rios Barrio,ADM2,DISTRICT
SM270848,-75.75,5.45,Riosucio,ADM2,DISTRICT
SM10452,-77.1667,7.4167,Riosucio (2000-),ADM2,DISTRICT
SM282491,-100.2522,21.9876,Rioverde,ADM2,DISTRICT
SM265856,-50.3923,-29.5985,Riozinho,ADM2,DISTRICT
SM292592,27.1396,47.919,Ripiceni,ADM2,DISTRICT
SM301613,-90.8639,36.6528,Ripley,ADM2,DISTRICT
SM300758,-85.2624,39.1035,Ripley,ADM2,DISTRICT
SM266276,-53.3396,-26.9819,Riqueza,ADM2,DISTRICT
SM243246,156.92,-6.8893,Ririo,LANGUAGE,LANGUAGE
SM2895,-76,5,Risaralda,ADM1,DISTRICT
SM10453,-75.7586,5.1124,Risaralda (1998-),ADM2,DISTRICT
SM290126,23.1541,46.7308,Risca,ADM2,DISTRICT
SM13063,141.2286,45.1824,Rishiri Gun,ADM2,DISTRICT
SM18440,71.2345,40.385,Rishtan,ADM2,DISTRICT
SM18441,71.25,40.3667,Rishton Tumani,ADM2,DISTRICT
SM257168,150.61,-22.11,Ristebura,ETHNICITY,ETHNICITY
SM286303,9.2121,58.7186,Risør,ADM2,DISTRICT
SM257169,135.3968,-12.9762,Ritarngo,ETHNICITY,ETHNICITY
SM243248,135.259,-13.4559,Ritarungo,LANGUAGE,LANGUAGE
SM303145,-81.063,39.1783,Ritchie,ADM2,DISTRICT
SM12678,-77.4289,18.139,Ritchies,ADM2,DISTRICT
SM327746,-97.0914,49.7042,Ritchot,ADM2:ADM3,DISTRICT
SM13770,25.484,56.1934,Rites pagasts,ADM2,DISTRICT
SM279233,136.0141,34.996,Rittō,ADM2,DISTRICT
SM263841,-44.3705,-20.9929,Ritápolis,ADM2,DISTRICT
SM226904,109.576,1.3307,Riuk Bekati',LANGUAGE,LANGUAGE
SM243250,121.122,-8.6034,Riung,LANGUAGE,LANGUAGE
SM283260,-106.6499,28.8146,Riva Palacio,ADM2,DISTRICT
SM260969,-68.6522,-31.5645,Rivadavia,ADM2,DISTRICT
SM260954,-62.9167,-23.6667,Rivadavia,ADM2,DISTRICT
SM261050,-62.1667,-29.8333,Rivadavia,ADM2,DISTRICT
SM260883,-68.466,-33.18,Rivadavia,ADM2,DISTRICT
SM260822,-63,-35.5,Rivadavia,ADM2,DISTRICT
SM285485,-85.827,11.4393,Rivas,ADM2,DISTRICT
SM2897,-85.75,11.3,Rivas,ADM1,DISTRICT
SM303621,-71.75,8.1667,Rivas Davila,ADM2,DISTRICT
SM454379,25,-20,River Bushmen,ETHNICITY,ETHNICITY
SM2898,-9.3667,5.9167,River Cess,ADM1,DISTRICT
SM17624,-61.0365,13.7677,River Doree,ADM2,DISTRICT
SM2899,-7.8,5.3167,River Gee,ADM1,DISTRICT
SM12679,-77.0778,18.2603,River Head (St. Anne),ADM2,DISTRICT
SM18495,-76.6108,17.9924,River Head (St. Thomas),ADM2,DISTRICT
SM2900,33.2,19,River Nile,ADM1,DISTRICT
SM270098,-75.2019,2.7735,Rivera,ADM2,DISTRICT
SM2901,-55.25,-31.5,Rivera,ADM1,DISTRICT
SM243252,21.1733,4.3281,Riverain Sango,LANGUAGE,LANGUAGE
SM2902,6.8277,4.7497,Rivers (1996-),ADM1,DISTRICT
SM12680,-76.9664,18.178,Riversdale,ADM2,DISTRICT
SM303189,-115.9939,33.7437,Riverside,ADM2,DISTRICT
SM17625,-61.0499,13.8941,Riverside Road,ADM2,DISTRICT
SM266874,-49.4435,-23.8444,Riversul,ADM2,DISTRICT
SM12681,-76.849,18.0144,Riverton City,ADM2,DISTRICT
SM268980,8.9815,46.3093,Riviera,ADM2,DISTRICT
SM2903,57.6417,-20.0583,Rivière du Rempart,ADM1,DISTRICT
SM2904,26.5,51,Rivne,ADM1,DISTRICT
SM18271,26.2459,50.613,Rivne Raion (2020-),ADM2,DISTRICT
SM260424,21.8156,-16.652,Rivungo,ADM2,DISTRICT
SM289117,128.5833,40.3333,Riwŏn,ADM2,DISTRICT
SM17806,43.5167,26.0667,Riyad al Khabra',ADM2,DISTRICT
SM257170,58.94,22.54,Riyam,ETHNICITY,ETHNICITY
SM285185,8.6848,9.568,Riyom,ADM2,DISTRICT
SM2905,121.1667,14.5833,Rizal,ADM1:ADM2,DISTRICT
SM2906,40.8949,40.9044,Rize,ADM1,DISTRICT
SM257172,28.75,9.7,Rizegat,ETHNICITY,ETHNICITY
SM269293,119.1761,35.6053,Rizhao,ADM2,DISTRICT
SM11025,34.433,35.6231,Rizokarpaso,ADM2,DISTRICT
SM261016,-71,-48.25,Rió Chico,ADM2,DISTRICT
SM261068,-65.7667,-27.4337,Rió Chico,ADM2,DISTRICT
SM261127,-64.5,-33.3333,Rió Cuarto,ADM2,DISTRICT
SM261063,-68.3333,-53.5,Rió Grande,ADM2,DISTRICT
SM261049,-64.75,-27.5833,Rió Hondo,ADM2,DISTRICT
SM261128,-63.5,-31.0833,Rió Primero,ADM2,DISTRICT
SM261129,-63,-30.0833,Rió Seco,ADM2,DISTRICT
SM261130,-63.5,-31.75,Rió Segundo,ADM2,DISTRICT
SM261109,-70.8333,-45.3333,Rió Senguer,ADM2,DISTRICT
SM303146,-81.3484,38.714,Roane,ADM2,DISTRICT
SM302614,-84.5232,35.8479,Roane,ADM2,DISTRICT
SM302945,-80.0509,37.2091,Roanoke,ADM2,DISTRICT
SM257173,-77.04,36.08,Roanoke,ETHNICITY,ETHNICITY
SM302946,-79.9414,37.271,Roanoke City,ADM2,DISTRICT
SM12682,-78.0432,18.3012,Roaring River,ADM2,DISTRICT
SM290590,25.5306,44.4139,Roata De Jos,ADM2,DISTRICT
SM275677,-86.5376,16.341,Roatán,ADM2,DISTRICT
SM290401,24.0058,44.3036,Robanestii De Jos,ADM2,DISTRICT
SM459118,144.9557,-40.667,Robbins Island Tasmanian,ETHNICITY,ETHNICITY
SM261730,139.99,-37.1862,Robe,ADM2,DISTRICT
SM289913,27.147,45.1425,Robeasca,ADM2,DISTRICT
SM270341,-78.3819,1.9176,Roberto Payán,ADM2,DISTRICT
SM302747,-100.8134,35.8385,Roberts,ADM2,DISTRICT
SM302527,-96.9461,45.6297,Roberts,ADM2,DISTRICT
SM296532,20.7842,64.166,Robertsfors,ADM2,DISTRICT
SM302748,-96.5128,31.027,Robertson,ADM2,DISTRICT
SM302615,-86.8706,36.5255,Robertson,ADM2,DISTRICT
SM300991,-84.052,38.5188,Robertson,ADM2,DISTRICT
SM302006,-79.1035,34.6401,Robeson,ADM2,DISTRICT
SM13771,27.5857,55.9628,Robeznieku pagasts,ADM2,DISTRICT
SM12683,-76.8178,18.3124,Robins Bay,ADM2,DISTRICT
SM12684,-77.5501,18.2041,Robins Hall,ADM2,DISTRICT
SM261051,-63.9167,-27.8333,Robles,ADM2,DISTRICT
SM16147,-66.2389,18.1334,Robles Barrio (Aibonito),ADM2,DISTRICT
SM16148,-66.9819,18.375,Robles Barrio (San Sebastián),ADM2,DISTRICT
SM17626,-61.023,13.8032,Roblot,ADM2,DISTRICT
SM257174,151.17,-10.07,Roboda,ETHNICITY,ETHNICITY
SM265857,-51.8244,-29.2264,Roca Sales,ADM2,DISTRICT
SM273500,-80.4667,-0.9167,Rocafuerte,ADM2,DISTRICT
SM2907,-54,-34,Rocha,ADM1,DISTRICT
SM16149,-67.0509,18.4009,Rocha Barrio,ADM2,DISTRICT
SM274676,-2.1667,53.5833,Rochdale,ADM1:ADM2,DISTRICT
SM2908,55.47,-4.6406,Roche Caïman,ADM1,DISTRICT
SM263082,-54.7818,-19.988,Rochedo,ADM2,DISTRICT
SM263842,-43.0331,-21.6427,Rochedo de Minas,ADM2,DISTRICT
SM323941,-112.887,52.4595,Rochon Sands,ADM2:ADM3,DISTRICT
SM291285,25.0201,44.6684,Rociu,ADM2,DISTRICT
SM303272,-89.0716,42.6712,Rock,ADM2,DISTRICT
SM301750,-99.4499,42.4213,Rock,ADM2,DISTRICT
SM301402,-96.2532,43.6746,Rock,ADM2,DISTRICT
SM12685,-77.337,17.9912,Rock,ADM2,DISTRICT
SM14093,-9.6667,5.55,Rock Cess,ADM2,DISTRICT
SM17627,-60.9893,14.0001,Rock Hall,ADM2,DISTRICT
SM12686,-76.5305,18.1617,Rock Hall,ADM2,DISTRICT
SM300672,-90.5674,41.4673,Rock Island,ADM2,DISTRICT
SM15314,134.307,7.23,Rock Islands,ADM2,DISTRICT
SM12687,-77.1971,18.0655,Rock River,ADM2,DISTRICT
SM12688,-78.2439,18.348,Rock Spring,ADM2,DISTRICT
SM14094,-7.75,4.4167,Rock Town,ADM2,DISTRICT
SM302947,-79.4476,37.8146,Rockbridge,ADM2,DISTRICT
SM300992,-84.3159,37.3652,Rockcastle,ADM2,DISTRICT
SM300406,-84.0266,33.6542,Rockdale,ADM2,DISTRICT
SM261456,151.1434,-33.9661,Rockdale,ADM2,DISTRICT
SM9734,150.1681,-23.4172,Rockhampton (2014-),ADM2,DISTRICT
SM302949,-78.8758,38.5121,Rockingham,ADM2,DISTRICT
SM301811,-71.089,42.9845,Rockingham,ADM2,DISTRICT
SM302007,-79.7752,36.3961,Rockingham,ADM2,DISTRICT
SM261305,115.7324,-32.2824,Rockingham,ADM2,DISTRICT
SM301906,-74.0241,41.1524,Rockland,ADM2,DISTRICT
SM302749,-96.4078,32.8977,Rockwall,ADM2,DISTRICT
SM327849,-97.334,50.25,Rockwood,ADM2:ADM3,DISTRICT
SM257175,-100.09,54.67,Rocky Cree,ETHNICITY,ETHNICITY
SM323990,-114.9175,52.3762,Rocky Mountain House,ADM2:ADM3,DISTRICT
SM12689,-77.2482,17.777,Rocky Point,ADM2,DISTRICT
SM323883,-113.1399,51.2327,Rockyford,ADM2:ADM3,DISTRICT
SM243268,-63.9917,-12.6832,Rocorona,LANGUAGE,LANGUAGE
SM271085,-80.5652,22.3636,Rodas,ADM2,DISTRICT
SM9577,70.5682,34.273,Rodat (2005-),ADM2,DISTRICT
SM266277,-49.352,-26.9321,Rodeio,ADM2,DISTRICT
SM265858,-53.1704,-27.4697,Rodeio Bonito,ADM2,DISTRICT
SM263843,-42.8409,-21.2126,Rodeiro,ADM2,DISTRICT
SM267624,-38.6461,-9.2177,Rodelas,ADM2,DISTRICT
SM281512,-104.5656,25.1748,Rodeo,ADM2,DISTRICT
SM292987,80.3333,52.5,Rodinskiy rayon,ADM2,DISTRICT
SM292520,24.8237,47.4514,Rodna,ADM2,DISTRICT
SM17628,-60.9544,14.0729,Rodney Bay,ADM2,DISTRICT
SM17629,-60.9491,14.0673,Rodney Heights,ADM2,DISTRICT
SM292935,41.75,57.0833,Rodnikovskiy rayon,ADM2,DISTRICT
SM265462,-38.0934,-5.8566,Rodolfo Fernandes,ADM2,DISTRICT
SM262789,-73.2361,-7.8529,Rodrigues Alves,ADM2,DISTRICT
SM2909,63.416,-19.701,Rodriguez,ADM1,DISTRICT
SM286726,-77.4167,-6.3333,Rodríguez de Mendoza,ADM2,DISTRICT
SM261306,116.9584,-21.1239,Roebourne,ADM2,DISTRICT
SM18494,-76.8084,18.0432,Roehampton (St Andrew),ADM2,DISTRICT
SM12690,-77.8993,18.399,Roehampton (St. James),ADM2,DISTRICT
SM370506,6.15,49.5422,Roeser,ADM1:ADM2:ADM3,DISTRICT
SM292229,24.0779,44.9202,Roesti,ADM2,DISTRICT
SM2910,6.2044,59.0961,Rogaland,ADM1,DISTRICT
SM296218,15.7408,46.2419,Rogatec,ADM1:ADM2,DISTRICT
SM313225,19.0425,43.8333,Rogatica,ADM2:ADM3,DISTRICT
SM296217,15.6294,46.2467,Rogaška Slatina,ADM1:ADM2,DISTRICT
SM296370,16.0244,46.8025,Rogašovci,ADM1:ADM2,DISTRICT
SM302233,-99.6957,35.6884,Roger Mills,ADM2,DISTRICT
SM302234,-95.6044,36.3716,Rogers,ADM2,DISTRICT
SM305996,69.77,38.75,Roghun,ADM2,DISTRICT
SM257176,108.85,13.13,Roglai,ETHNICITY,ETHNICITY
SM295018,33.6667,53.8333,Rognedinskiy rayon,ADM2,DISTRICT
SM243273,6.946,10.56,Rogo,LANGUAGE,LANGUAGE
SM284880,7.83,11.55,Rogo,ADM2,DISTRICT
SM291100,22.8295,44.4708,Rogova,ADM2,DISTRICT
SM305437,15.9709,43.5362,Rogoznica,ADM2,DISTRICT
SM299977,24.6103,49.4063,Rohatyns'kyi,ADM2,DISTRICT
SM262023,29.3719,-3.3835,Roherero,ADM2,DISTRICT
SM243277,92.394,20.8331,Rohingya,LANGUAGE,LANGUAGE
SM261830,13.9693,48.5574,Rohrbach im Mühlkreis,ADM2,DISTRICT
SM276853,76.6667,28.8333,Rohtak (1997-),ADM2,DISTRICT
SM277424,84.0277,24.9794,Rohtas (1999-),ADM2,DISTRICT
SM2911,103.75,15.75,Roi Et,ADM1,DISTRICT
SM303843,-69.6301,8.3246,Rojas,ADM2,DISTRICT
SM260823,-60.75,-34.25,Rojas,ADM2,DISTRICT
SM3967,22.7333,57.5,Rojas Novads,ADM1,DISTRICT
SM14373,-96.6194,17.0086,Rojas de Cuauhtemoc,ADM2,DISTRICT
SM13772,22.7531,57.4908,Rojas novads,ADM2,DISTRICT
SM276554,100.8251,2.166,Rokan Hilir,ADM2,DISTRICT
SM276555,100.4833,0.8833,Rokan Hulu,ADM2,DISTRICT
SM281347,25.5833,55.9667,Rokiškio,ADM2,DISTRICT
SM271159,13.6667,49.7833,Rokycany,ADM2,DISTRICT
SM9996,-54.8561,-28.271,Rolador Municipality,ADM2,DISTRICT
SM327753,-97.9118,49.3356,Roland,ADM2:ADM3,DISTRICT
SM265859,-50.5452,-29.6117,Rolante,ADM2,DISTRICT
SM270628,-76.1639,4.446,Roldanillo,ADM2,DISTRICT
SM280481,104.61,12.21,Rolea B'ier,ADM2,DISTRICT
SM302064,-99.841,48.7725,Rolette,ADM2,DISTRICT
SM266107,-61.7897,-11.7526,Rolim de Moura,ADM2,DISTRICT
SM286325,9.2949,59.9841,Rollag,ADM2,DISTRICT
SM12691,-76.7652,17.9769,Rollington Town,ADM2,DISTRICT
SM250647,26.15,-24.99,Rolong,ETHNICITY,ETHNICITY
SM250647,23.41,-23.49,Rolong,ETHNICITY,ETHNICITY
SM375348,82.6444,28.3254,Rolpa,ADM2:ADM3,DISTRICT
SM264682,-51.4283,-23.275,Rolândia,ADM2,DISTRICT
SM243295,127.428,-7.5864,Roma,LANGUAGE,LANGUAGE
SM292593,26.5708,47.845,Roma,ADM2,DISTRICT
SM277963,12.6667,41.9667,Roma,ADM2,DISTRICT
SM243291,11.7189,44.2349,Romagnol,LANGUAGE,LANGUAGE
SM257181,-58.59,51.57,Romaine,ETHNICITY,ETHNICITY
SM243294,107.447,14.34,Romam,LANGUAGE,LANGUAGE
SM291361,26.9368,46.9297,Roman,ADM2,DISTRICT
SM262496,23.9167,43.15,Roman,ADM2,DISTRICT
SM291627,23.1763,47.1147,Romanasi,ADM2,DISTRICT
SM290864,27.3582,47.2717,Romanesti,ADM2,DISTRICT
SM292594,27.2349,47.7086,Romanesti,ADM2,DISTRICT
SM291362,26.6995,46.8024,Romani,ADM2,DISTRICT
SM243290,24.2256,46.3913,Romanian,LANGUAGE,LANGUAGE
SM257183,25.41,45.53,Romanian,ETHNICITY,ETHNICITY
SM257183,25.41,45.53,Romanian,ETHNICITY,ETHNICITY
SM257183,25.41,45.53,Romanian,ETHNICITY,ETHNICITY
SM257183,26,45,Romanian,ETHNICITY,ETHNICITY
SM257183,26,45,Romanian,ETHNICITY,ETHNICITY
SM257183,26,45,Romanian,ETHNICITY,ETHNICITY
SM257183,22.9503,46.5022,Romanian,ETHNICITY,ETHNICITY
SM257183,22.9503,46.5022,Romanian,ETHNICITY,ETHNICITY
SM257183,22.9503,46.5022,Romanian,ETHNICITY,ETHNICITY
SM243288,26.2924,44.9292,Romanian Sign Language,LANGUAGE,LANGUAGE
SM243286,23.2164,38.6347,Romano-Greek,LANGUAGE,LANGUAGE
SM243287,20.3762,45.2552,Romano-Serbian,LANGUAGE,LANGUAGE
SM292988,81.1667,52.5833,Romanovskiy rayon,ADM2,DISTRICT
SM243289,9.9281,46.5538,Romansh,LANGUAGE,LANGUAGE
SM292687,27.7405,45.3182,Romanu,ADM2,DISTRICT
SM263844,-47.5813,-18.8934,Romaria,ADM2,DISTRICT
SM243303,122.56,12.4047,Romblomanon,LANGUAGE,LANGUAGE
SM2912,122.2821,12.555,Romblon,ADM1:ADM2,DISTRICT
SM243302,37.5382,-3.0777,Rombo,LANGUAGE,LANGUAGE
SM299253,37.544,-3.105,Rombo,ADM2,DISTRICT
SM280444,105.7723,11.4233,Romeas Haek,ADM2,DISTRICT
SM266278,-53.3109,-26.6724,Romelândia,ADM2,DISTRICT
SM299718,33.4828,50.7431,Romens'ka,ADM2,DISTRICT
SM281552,-101.5605,20.8415,Romita,ADM2,DISTRICT
SM303470,64.4,39.9,Romitan,ADM2,DISTRICT
SM243305,144.689,-4.5892,Romkun,LANGUAGE,LANGUAGE
SM293217,130,51,Romnenskiy rayon,ADM2,DISTRICT
SM293642,45.3333,54.4167,Romodanovskiy rayon,ADM2,DISTRICT
SM290783,23.309,45.8292,Romos,ADM2,DISTRICT
SM284434,103,2,Rompin,ADM2,DISTRICT
SM292521,24.429,47.5625,Romuli,ADM2,DISTRICT
SM303886,-68.6296,9.5373,Romulo Gallegos,ADM2,DISTRICT
SM243319,9.02,9.3,Ron,LANGUAGE,LANGUAGE
SM297218,99.8925,8.18,Ron Phi Pun,ADM2,DISTRICT
SM291024,24.0167,47.9167,Rona De Jos,ADM2,DISTRICT
SM291025,24.0388,47.9018,Rona De Sus,ADM2,DISTRICT
SM264683,-52.2245,-24.5636,Roncador,ADM2,DISTRICT
SM16150,-66.7487,18.2602,Roncador Barrio,ADM2,DISTRICT
SM270580,-75.5965,4.1091,Roncesvalles,ADM2,DISTRICT
SM265860,-52.7287,-27.8261,Ronda Alta,ADM2,DISTRICT
SM265861,-52.9166,-27.8329,Rondinha,ADM2,DISTRICT
SM9997,-61.0622,-10.2757,Rondolandia,ADM2:ADM3,DISTRICT
SM264684,-52.845,-23.4746,Rondon,ADM2,DISTRICT
SM264083,-48.5923,-4.4398,Rondon do Pará,ADM2,DISTRICT
SM263102,-54.6372,-16.4332,Rondonópolis,ADM2,DISTRICT
SM270813,-73.1968,5.3817,Rondón,ADM2,DISTRICT
SM2913,-63,-11,Rondônia,ADM1,DISTRICT
SM296938,103.7124,16.2793,Rong Kham,ADM2,DISTRICT
SM297396,100.3942,18.3261,Rong Kwang,ADM2,DISTRICT
SM243313,32.6167,-26.7329,Ronga,LANGUAGE,LANGUAGE
SM243316,122.4343,37.1306,Rongcheng Mandarin,DIALECT,LANGUAGE
SM4087,166.8333,11.3333,Rongelap Atoll,ADM1,DISTRICT
SM243314,120.702,-8.846,Rongga,LANGUAGE,LANGUAGE
SM243312,93.7869,25.098,Rongmei Naga,LANGUAGE,LANGUAGE
SM243310,79.567,30.528,Rongpo,LANGUAGE,LANGUAGE
SM4088,167.4333,11.35,Rongrik Atoll,ADM1,DISTRICT
SM284798,8.3314,12.5493,Roni,ADM2,DISTRICT
SM243318,146.731,-5.8078,Ronji,LANGUAGE,LANGUAGE
SM296387,15.2404,56.3103,Ronneby,ADM2,DISTRICT
SM300969,-99.3251,39.3502,Rooks,ADM2,DISTRICT
SM243321,134.556,-2.4301,Roon,LANGUAGE,LANGUAGE
SM285944,4.4226,51.5115,Roosendaal,ADM2,DISTRICT
SM301849,-103.4802,34.0213,Roosevelt,ADM2,DISTRICT
SM301659,-105.0165,48.2946,Roosevelt,ADM2,DISTRICT
SM4012,24.5964,56.9664,Ropazu Novads,ADM1,DISTRICT
SM13773,24.5969,56.9663,Ropazu novads,ADM2,DISTRICT
SM289014,21.6439,50.0482,Ropczyce-Sędziszów,ADM2,DISTRICT
SM261595,134.9736,-15.3056,Roper Gulf,ADM2,DISTRICT
SM265863,-55.1205,-28.0567,Roque Gonzales,ADM2,DISTRICT
SM260698,-59.4106,-35.4451,Roque Pérez,ADM2,DISTRICT
SM2914,-61.25,2.25,Roraima,ADM1,DISTRICT
SM266127,-60.8949,0.1564,Rorainópolis,ADM2,DISTRICT
SM257188,146.22,-8.12,Roro,ETHNICITY,ETHNICITY
SM268935,9.4425,47.4665,Rorschach,ADM2,DISTRICT
SM299100,34.0716,-1.2891,Rorya,ADM2,DISTRICT
SM283261,-105.5829,28.2978,Rosales,ADM2,DISTRICT
SM323778,-112.44,52.7872,Rosalind,ADM2:ADM3,DISTRICT
SM282133,-105.1919,22.0773,Rosamorada,ADM2,DISTRICT
SM266875,-52.8194,-22.4925,Rosana,ADM2,DISTRICT
SM300188,-57.4319,-34.3921,Rosario,ADM2,DISTRICT
SM300188,-57.4319,-34.3921,Rosario,ADM2,DISTRICT
SM300188,-57.365,-34.3075,Rosario,ADM2,DISTRICT
SM300188,-57.365,-34.3075,Rosario,ADM2,DISTRICT
SM282596,-109.3074,28.0176,Rosario,ADM2,DISTRICT
SM283262,-106.2988,27.2562,Rosario,ADM2,DISTRICT
SM261020,-60.75,-33.1667,Rosario,ADM2,DISTRICT
SM16151,-67.0322,18.1532,Rosario Alto Barrio,ADM2,DISTRICT
SM16152,-67.072,18.1602,Rosario Bajo Barrio,ADM2,DISTRICT
SM16153,-67.0906,18.1677,Rosario Barrio,ADM2,DISTRICT
SM9998,-55.7148,-14.5798,Rosario Oeste (1999-),ADM2,DISTRICT
SM16154,-67.0554,18.155,Rosario Penon Barrio,ADM2,DISTRICT
SM260868,-66.6667,-31.4167,Rosario Vera Peñaloza,ADM2,DISTRICT
SM260957,-65.8333,-24.5833,Rosario de Lerma,ADM2,DISTRICT
SM295514,-89.2065,13.54,Rosario de Mora,ADM2,DISTRICT
SM303802,-72.3144,10.3257,Rosario de Perijá,ADM2,DISTRICT
SM260956,-64.8333,-25.9167,Rosario de la Frontera,ADM2,DISTRICT
SM269870,-76.7423,2.2459,Rosas,ADM2,DISTRICT
SM303590,-67.3538,9.9115,Roscio,ADM2,DISTRICT
SM303863,-61.5,6,Roscio,ADM2,DISTRICT
SM301324,-84.6116,44.3356,Roscommon,ADM2,DISTRICT
SM2915,-8.25,53.75,Roscommon,ADM1:ADM2,DISTRICT
SM18493,-77.8183,18.5123,Rose Hall (St James),ADM2,DISTRICT
SM12692,-77.6415,17.9265,Rose Hall (St. Elizabeth),ADM2,DISTRICT
SM12693,-77.8953,18.4694,Rose Heights,ADM2,DISTRICT
SM17630,-60.9856,14.0082,Rose Hill,ADM2,DISTRICT
SM12694,-77.5234,17.9247,Rose Hill,ADM2,DISTRICT
SM261209,-168.15,-14.5458,Rose Island,ADM1:ADM2,DISTRICT
SM12695,-77.894,18.4639,Rose Mount Garden,ADM2,DISTRICT
SM12696,-76.8073,17.987,Rose Town,ADM2,DISTRICT
SM301403,-95.8108,48.7751,Roseau,ADM2,DISTRICT
SM17631,-61.0268,13.9532,Roseau Valley,ADM2,DISTRICT
SM301660,-106.7308,46.2297,Rosebud,ADM2,DISTRICT
SM327868,-99.5052,50.3275,Rosedale,ADM2:ADM3,DISTRICT
SM266878,-45.2998,-22.933,Roseira,ADM2,DISTRICT
SM323832,-112.088,50.7558,Rosemary,ADM2:ADM3,DISTRICT
SM12697,-77.8922,18.4748,Rosemount,ADM2,DISTRICT
SM289969,27.4509,44.2144,Roseti,ADM2,DISTRICT
SM273893,30.4225,31.3467,Rosetta,ADM2,DISTRICT
SM257189,71.6,37.15,Roshanis,ETHNICITY,ETHNICITY
SM305945,72,37.1,Roshtqala,ADM2,DISTRICT
SM291748,24.3179,45.8038,Rosia,ADM2,DISTRICT
SM292457,22.4053,46.799,Rosia,ADM2,DISTRICT
SM290648,23.7599,45.0442,Rosia De Amaradia,ADM2,DISTRICT
SM289825,23.8533,46.0481,Rosia De Secas,ADM2,DISTRICT
SM289826,23.087,46.3099,Rosia Montana,ADM2,DISTRICT
SM292308,27.892,46.4511,Rosiesti,ADM2,DISTRICT
SM292230,23.9244,44.8854,Rosiile,ADM2,DISTRICT
SM292688,27.3413,44.841,Rosiori,ADM2,DISTRICT
SM292149,27.1045,46.7262,Rosiori,ADM2,DISTRICT
SM291920,24.9872,44.1137,Rosiori De Vede,ADM2,DISTRICT
SM285410,-84.4,13.8833,Rosita,ADM2,DISTRICT
SM271722,12.1,55.65,Roskilde (2007-),ADM2,DISTRICT
SM2916,21.92,41.51,Rosoman,ADM1,DISTRICT
SM370473,6.5042,49.7978,Rosport,ADM1:ADM2:ADM3,DISTRICT
SM302152,-83.057,39.3376,Ross,ADM2,DISTRICT
SM324081,-114.4078,53.7343,Ross Haven,ADM2:ADM3,DISTRICT
SM10194,-100.7412,50.7278,Rossburn (2015-),ADM2,DISTRICT
SM257190,154.17,-11.32,Rossel Island,ETHNICITY,ETHNICITY
SM257190,154.17,-11.32,Rossel Island,ETHNICITY,ETHNICITY
SM257190,154.17,-11.32,Rossel Island,ETHNICITY,ETHNICITY
SM257190,154.18,-11.35,Rossel Island,ETHNICITY,ETHNICITY
SM257190,154.18,-11.35,Rossel Island,ETHNICITY,ETHNICITY
SM257190,154.18,-11.35,Rossel Island,ETHNICITY,ETHNICITY
SM257190,154.14,-11.36,Rossel Island,ETHNICITY,ETHNICITY
SM257190,154.14,-11.36,Rossel Island,ETHNICITY,ETHNICITY
SM257190,154.14,-11.36,Rossel Island,ETHNICITY,ETHNICITY
SM327850,-97.4427,49.9825,Rosser,ADM2:ADM3,DISTRICT
SM283993,-15.72,16.585,Rosso,ADM2,DISTRICT
SM17058,39.5833,50.1667,Rossoshanskiy Rayon,ADM2,DISTRICT
SM11803,51.4167,30.4,Rostam,ADM2,DISTRICT
SM2917,41.0808,47.4403,Rostov,ADM1,DISTRICT
SM17059,39.5,57.1667,Rostovskiy Rayon,ADM2,DISTRICT
SM262869,-44.1743,-2.96,Rosário,ADM2,DISTRICT
SM263845,-42.5151,-20.986,Rosário da Limeira,ADM2,DISTRICT
SM267132,-37.0345,-10.6855,Rosário do Catete,ADM2,DISTRICT
SM264685,-51.2202,-24.3057,Rosário do Ivaí,ADM2,DISTRICT
SM265864,-55.0666,-30.3323,Rosário do Sul,ADM2,DISTRICT
SM2918,145.201,14.154,Rota,ADM1,DISTRICT
SM280413,102.887,12.8405,Rotanak Mondol,ADM2,DISTRICT
SM292231,24.4199,44.9876,Rotarasti,ADM2,DISTRICT
SM276496,123.1205,-10.7362,Rote Ndao,ADM2,DISTRICT
SM265420,-35.9831,-9.8591,Roteiro,ADM2,DISTRICT
SM231827,142.569,-12.713,Roth's Gugu Warra,LANGUAGE,LANGUAGE
SM13987,27.4012,-29.5189,Rothe,ADM2,DISTRICT
SM274677,-1.25,53.4167,Rotherham,ADM1:ADM2,DISTRICT
SM257191,123.12,-10.61,Roti,ETHNICITY,ETHNICITY
SM257191,123.12,-10.61,Roti,ETHNICITY,ETHNICITY
SM257191,123.18,-10.71,Roti,ETHNICITY,ETHNICITY
SM257191,123.18,-10.71,Roti,ETHNICITY,ETHNICITY
SM243339,155.154,-5.9434,Rotokas,LANGUAGE,LANGUAGE
SM286532,176.3333,-38.1667,Rotorua,ADM2,DISTRICT
SM274417,177.08,-12.5,Rotuma,ADM2,DISTRICT
SM2920,177.066,-12.508,Rotuma,ADM1,DISTRICT
SM243340,177.066,-12.5008,Rotuman,LANGUAGE,LANGUAGE
SM257192,177.07,-12.5,Rotuman,ETHNICITY,ETHNICITY
SM291451,24.314,43.9843,Rotunda,ADM2,DISTRICT
SM17632,-60.9161,13.7775,Rouarne,ADM2,DISTRICT
SM11191,26.8803,57.7278,Rouge vald,ADM2,DISTRICT
SM297975,9.2124,35.648,Rouhia,ADM2,DISTRICT
SM257193,-90.62,52.71,Round Lake Ojibwa,ETHNICITY,ETHNICITY
SM262368,26.0833,43.85,Rousse,ADM2,DISTRICT
SM303368,-106.9912,40.4851,Routt,ADM2,DISTRICT
SM346779,26.1587,66.3917,Rovaniemi,ADM2:ADM3,DISTRICT
SM295387,159.9449,-9.4316,Rove - Lengakiki,ADM2,DISTRICT
SM299567,39.3683,47.9824,Roven'kivs'ka,ADM2,DISTRICT
SM299567,39.3683,47.9824,Roven'kivs'ka,ADM2,DISTRICT
SM299567,39.4443,48.0323,Roven'kivs'ka,ADM2,DISTRICT
SM299567,39.4443,48.0323,Roven'kivs'ka,ADM2,DISTRICT
SM294683,39,50,Roven'skiy rayon,ADM2,DISTRICT
SM243343,157.408,-8.2049,Roviana,LANGUAGE,LANGUAGE
SM295326,157.5928,-8.3206,Roviana Lagoon,ADM2,DISTRICT
SM280391,105.0996,13.3511,Rovieng,ADM2,DISTRICT
SM277930,11.8333,45.0333,Rovigo,ADM2,DISTRICT
SM290649,23.156,44.9386,Rovinari,ADM2,DISTRICT
SM10534,13.6397,45.0819,Rovinj-Rovigno (excl. Grad Rovinj),ADM2,DISTRICT
SM270581,-75.3421,4.2122,Rovira,ADM2,DISTRICT
SM305327,16.7312,45.9447,Rovišće,ADM2,DISTRICT
SM302009,-80.5246,35.6395,Rowan,ADM2,DISTRICT
SM300993,-83.4211,38.1963,Rowan,ADM2,DISTRICT
SM12698,-76.2878,17.9736,Rowlandsfield,ADM2,DISTRICT
SM261731,136.8838,-30.5489,Roxby Downs,ADM2,DISTRICT
SM12699,-77.4683,18.0497,Royal Flat,ADM2,DISTRICT
SM260189,67.8173,35.6355,Royi Du Ab,ADM2,DISTRICT
SM299622,30.0742,46.8528,Rozdil'nias'kyi,ADM2,DISTRICT
SM299887,33.4408,45.6709,Rozdol'nens'kyi,ADM2,DISTRICT
SM285805,5.9727,52.0391,Rozendaal,ADM2,DISTRICT
SM299978,24.1552,48.9393,Rozhniativs'kyi,ADM2,DISTRICT
SM299824,37.071,47.3944,Rozivs'kyi,ADM2,DISTRICT
SM13774,26.4367,56.2057,Rozkalnu pagasts,ADM2,DISTRICT
SM17840,14.4678,46.06,Roznik District,ADM2,DISTRICT
SM291363,26.5128,46.8385,Roznov,ADM2,DISTRICT
SM13775,26.341,56.3667,Rozupes pagasts,ADM2,DISTRICT
SM2921,20.1503,42.8428,Rožaje,ADM1,DISTRICT
SM296143,20.5333,48.6667,Rožnava,ADM2,DISTRICT
SM250369,30.14,-1.86,Ruanda,ETHNICITY,ETHNICITY
SM250369,30.14,-1.86,Ruanda,ETHNICITY,ETHNICITY
SM250369,30,-2,Ruanda,ETHNICITY,ETHNICITY
SM250369,30,-2,Ruanda,ETHNICITY,ETHNICITY
SM299087,38.9333,-10.0667,Ruangwa,ADM2,DISTRICT
SM286556,175.408,-39.0885,Ruapehu,ADM2,DISTRICT
SM299266,29.845,-1.185,Rubanda,ADM1:ADM2,DISTRICT
SM13776,22.5677,56.4149,Rubas pagasts,ADM2,DISTRICT
SM295160,29.3293,-1.6813,Rubavu,ADM1:ADM2,DISTRICT
SM263847,-42.228,-16.3421,Rubelita,ADM2,DISTRICT
SM13777,26.0098,56.166,Rubenes pagasts,ADM2,DISTRICT
SM299568,38.3891,49.0146,Rubezhans'ka,ADM2,DISTRICT
SM16155,-66.8795,18.1333,Rubias Barrio,ADM2,DISTRICT
SM268149,-49.8979,-15.1682,Rubiataba,ADM2,DISTRICT
SM263848,-40.493,-16.4515,Rubim,ADM2,DISTRICT
SM266880,-51.0184,-20.2499,Rubinéia,ADM2,DISTRICT
SM266879,-50.7859,-21.3611,Rubiácea,ADM2,DISTRICT
SM17060,81.3333,51.5,Rubtsovskiy Rayon,ADM2,DISTRICT
SM17633,-61.0498,13.8581,Ruby Estate,ADM2,DISTRICT
SM243351,105.7841,17.6829,Ruc,DIALECT,LANGUAGE
SM257197,-74.3,-14.4,Rucana,ETHNICITY,ETHNICITY
SM291286,25.1734,45.4161,Rucar,ADM2,DISTRICT
SM3961,21.2097,56.2117,Rucavas Novads,ADM1,DISTRICT
SM13778,21.13,56.1665,Rucavas pagasts,ADM2,DISTRICT
SM243352,98.0915,21.6176,Ruching Palaung,LANGUAGE,LANGUAGE
SM16156,-66.6986,18.1028,Rucio Barrio,ADM2,DISTRICT
SM285946,4.5659,51.5236,Rucphen,ADM2,DISTRICT
SM288732,18.8742,50.2701,Ruda Śląska,ADM2,DISTRICT
SM277490,57.1167,27.5333,Rudan,ADM2,DISTRICT
SM277733,49.6,36.8,Rudbar,ADM2,DISTRICT
SM243356,49.8801,37.0878,Rudbari,LANGUAGE,LANGUAGE
SM13779,21.8786,56.6538,Rudbarzu pagasts,ADM2,DISTRICT
SM271679,12.4917,55.825,Rudersdal,ADM2,DISTRICT
SM294921,44.3333,50.75,Rudnyanskiy rayon,ADM2,DISTRICT
SM13242,63.1167,52.95,Rudnyy Qalasy,ADM2,DISTRICT
SM313218,19.3436,43.6456,Rudo,ADM2:ADM3,DISTRICT
SM262414,24.8369,41.4544,Rudozem,ADM2,DISTRICT
SM277344,79.0652,30.6087,Rudraprayag,ADM2,DISTRICT
SM304968,47.8382,14.0376,Rudum,ADM2,DISTRICT
SM13780,26.4786,56.4376,Rudzatu pagasts,ADM2,DISTRICT
SM262405,27.25,42.8833,Ruen,ADM2,DISTRICT
SM268414,89.9671,27.3829,Ruepisa,ADM2,DISTRICT
SM243359,38.7133,-7.9906,Rufiji,LANGUAGE,LANGUAGE
SM299155,38.7266,-7.9574,Rufiji,ADM2,DISTRICT
SM257200,38.78,-8.65,Rufiji (Ruihi),ETHNICITY,ETHNICITY
SM16157,-66.7888,18.0023,Rufina Barrio,ADM2,DISTRICT
SM295249,-17.1984,14.7434,Rufisque,ADM2,DISTRICT
SM18166,29.5245,-15.149,Rufunsa District,ADM2,DISTRICT
SM243363,91.469,25.506,Ruga,LANGUAGE,LANGUAGE
SM3973,27.0975,56.9825,Rugaju Novads,ADM1,DISTRICT
SM13781,27.0971,57.0184,Rugaju pagasts,ADM2,DISTRICT
SM261963,29.4845,-3.2068,Rugazi,ADM2,DISTRICT
SM2922,9.5332,47.245,Ruggell,ADM1,DISTRICT
SM292371,27.118,46.0789,Ruginesti,ADM2,DISTRICT
SM290865,26.8559,47.2526,Ruginoasa,ADM2,DISTRICT
SM262064,29.1023,-2.8234,Rugombo,ADM2,DISTRICT
SM305626,16.2286,45.7415,Rugvica,ADM2,DISTRICT
SM295148,29.7699,-2.1996,Ruhango,ADM1:ADM2,DISTRICT
SM299412,30.01,-0.6262,Ruhinda,ADM1:ADM2,DISTRICT
SM274134,23.2425,57.8031,Ruhnu,ADM2,DISTRICT
SM262003,29.939,-3.009,Ruhororo,ADM2,DISTRICT
SM13782,25.3369,57.8941,Rujiena,ADM2,DISTRICT
SM4046,25.2242,57.9353,Rujienas Novads,ADM1,DISTRICT
SM243365,120.844,22.7738,Rukai,LANGUAGE,LANGUAGE
SM257201,120.87,23.29,Rukai,ETHNICITY,ETHNICITY
SM9578,69.4773,35.256,Rukhah,ADM2,DISTRICT
SM299267,30.0934,-1.1733,Rukiga,ADM1:ADM2,DISTRICT
SM2923,29.8778,-0.6908,Rukungiri (2001-),ADM1:ADM2,DISTRICT
SM2924,31.4167,-8,Rukwa (2012-),ADM1,DISTRICT
SM295140,29.9979,-1.7383,Rulindo,ADM1:ADM2,DISTRICT
SM14970,138.1154,9.5077,Rull Municipality,ADM2,DISTRICT
SM2925,-74.8567,23.6781,Rum Cay,ADM1,DISTRICT
SM243371,8.2188,10.571,Ruma,LANGUAGE,LANGUAGE
SM304817,51.0759,17.8145,Rumah,ADM2,DISTRICT
SM17807,47.0833,25.75,Rumah,ADM2,DISTRICT
SM243369,97.3172,23.6404,Rumai Palaung,LANGUAGE,LANGUAGE
SM305826,45.2954,31.4978,Rumaitha,ADM2,DISTRICT
SM305826,45.2954,31.4978,Rumaitha,ADM2,DISTRICT
SM305826,45.3195,31.5232,Rumaitha,ADM2,DISTRICT
SM305826,45.3195,31.5232,Rumaitha,ADM2,DISTRICT
SM13783,22.0553,56.9962,Rumbas pagasts,ADM2,DISTRICT
SM280445,105.8298,11.2216,Rumduol,ADM2,DISTRICT
SM370507,6.0114,49.4614,Rumelange,ADM1:ADM2:ADM3,DISTRICT
SM226373,24.4457,41.1705,Rumelian Turkish,LANGUAGE,LANGUAGE
SM11804,47.49,33.2796,Rumeshkhan County,ADM2,DISTRICT
SM273545,-78.4356,-0.4319,Rumiñahui,ADM2,DISTRICT
SM278170,141.7557,43.8856,Rumoi,ADM2,DISTRICT
SM262051,29.4333,-3.9667,Rumonge,ADM2,DISTRICT
SM3902,29.4477,-3.9648,Rumonge,ADM1,DISTRICT
SM2926,33.8052,-10.8029,Rumphi,ADM1:ADM2,DISTRICT
SM243379,144.179,-7.1715,Rumu,LANGUAGE,LANGUAGE
SM14971,138.1575,9.6243,Rumung Municipality,ADM2,DISTRICT
SM243380,-76.5,2.75,Runa,LANGUAGE,LANGUAGE
SM274521,-6.7202,62.1068,Runavík,ADM2,DISTRICT
SM12700,-77.3242,18.4485,Runaway Bay,ADM2,DISTRICT
SM257204,151.04,-22.2,Runbubura,ETHNICITY,ETHNICITY
SM292232,24.4598,45.1869,Runcu,ADM2,DISTRICT
SM290650,23.1397,45.1204,Runcu,ADM2,DISTRICT
SM290303,25.3853,45.177,Runcu,ADM2,DISTRICT
SM4056,23.9747,56.4106,Rundales Novads,ADM1,DISTRICT
SM13784,24.0357,56.3961,Rundales pagasts,ADM2,DISTRICT
SM13785,27.7829,56.263,Rundenu pagasts,ADM2,DISTRICT
SM243382,29.97,-2.94,Rundi,LANGUAGE,LANGUAGE
SM243381,29.4853,-3.0223,Rundi-Kitwa,LANGUAGE,LANGUAGE
SM284513,19.7667,-17.9333,Rundu Rural West,ADM2,DISTRICT
SM284514,19.7667,-17.9333,Rundu Urban,ADM2,DISTRICT
SM243387,20.9945,9.7154,Runga,LANGUAGE,LANGUAGE
SM243388,116.743,6.8399,Rungus,LANGUAGE,LANGUAGE
SM257206,117.01,6.34,Rungus,ETHNICITY,ETHNICITY
SM243384,31.7614,-7.2496,Rungwa,LANGUAGE,LANGUAGE
SM299104,33.6903,-9.2355,Rungwe,ADM2,DISTRICT
SM302752,-99.9762,31.8311,Runnels,ADM2,DISTRICT
SM305483,17.2389,43.387,Runovići,ADM2,DISTRICT
SM14972,152.244,8.609,Ruo Municipality,ADM2,DISTRICT
SM243390,92.397,27.2029,Rupa,DIALECT,LANGUAGE
SM375365,83.4034,27.5492,Rupandehi,ADM2:ADM3,DISTRICT
SM292648,25.1872,46.059,Rupea,ADM2,DISTRICT
SM277150,76.52,31.04,Rupnagar (2006-),ADM2,DISTRICT
SM11264,-151.3473,-22.4566,Rurutu,ADM2,DISTRICT
SM264084,-55.1841,-4.188,Rurópolis,ADM2,DISTRICT
SM261995,29.6352,-3.454,Rusaka,ADM2,DISTRICT
SM291452,24.595,43.9439,Rusanesti,ADM2,DISTRICT
SM290046,22.4364,45.6004,Rusca Montana,ADM2,DISTRICT
SM291027,24.2855,47.7929,Ruscova,ADM2,DISTRICT
SM2927,26,43.7,Ruse,ADM1,DISTRICT
SM289914,27.2186,44.9421,Rusetu,ADM2,DISTRICT
SM300759,-85.4658,39.62,Rush,ADM2,DISTRICT
SM300970,-99.3092,38.5232,Rush,ADM2,DISTRICT
SM243400,71.5855,38.0783,Rushani,DIALECT,LANGUAGE
SM17951,72,38,Rushanskiy Rayon,ADM2,DISTRICT
SM276805,-4.7797,54.0622,Rushen,ADM1:ADM2,DISTRICT
SM305242,32.3143,-16.6079,Rushinga,ADM2,DISTRICT
SM291181,24.8352,46.9199,Rusii-munti,ADM2,DISTRICT
SM295161,29.0754,-2.5702,Rusizi,ADM1:ADM2,DISTRICT
SM302753,-94.7619,32.1077,Rusk,ADM2,DISTRICT
SM303273,-91.1331,45.4751,Rusk,ADM2,DISTRICT
SM297256,101.5149,6.3745,Ruso,ADM2,DISTRICT
SM13786,26.9153,56.2287,Rusonas pagasts,ADM2,DISTRICT
SM267841,-38.196,-4.8399,Russas,ADM2,DISTRICT
SM302950,-82.0956,36.9338,Russell,ADM2,DISTRICT
SM300971,-98.7624,38.9148,Russell,ADM2,DISTRICT
SM300994,-85.0587,36.991,Russell,ADM2,DISTRICT
SM300298,-85.185,32.2884,Russell,ADM2,DISTRICT
SM12701,-77.6507,17.9864,Russels,ADM2,DISTRICT
SM243406,23.681,70.662,Russenorsk,LANGUAGE,LANGUAGE
SM12702,-78.1236,18.2165,Russia,ADM2,DISTRICT
SM243403,105.564,50.8533,Russia Buriat,LANGUAGE,LANGUAGE
SM243402,50,59,Russian,LANGUAGE,LANGUAGE
SM257211,41.43,45.19,Russian,ETHNICITY,ETHNICITY
SM257211,41.43,45.19,Russian,ETHNICITY,ETHNICITY
SM257211,41.43,45.19,Russian,ETHNICITY,ETHNICITY
SM257211,41.43,45.19,Russian,ETHNICITY,ETHNICITY
SM257211,41.43,45.19,Russian,ETHNICITY,ETHNICITY
SM257211,41.43,45.19,Russian,ETHNICITY,ETHNICITY
SM257211,41.43,45.19,Russian,ETHNICITY,ETHNICITY
SM257211,41.43,45.19,Russian,ETHNICITY,ETHNICITY
SM257211,41.43,45.19,Russian,ETHNICITY,ETHNICITY
SM257211,41.43,45.19,Russian,ETHNICITY,ETHNICITY
SM257211,41.43,45.19,Russian,ETHNICITY,ETHNICITY
SM257211,41.43,45.19,Russian,ETHNICITY,ETHNICITY
SM257211,41.43,45.19,Russian,ETHNICITY,ETHNICITY
SM257211,35.05,53.2,Russian,ETHNICITY,ETHNICITY
SM257211,35.05,53.2,Russian,ETHNICITY,ETHNICITY
SM257211,35.05,53.2,Russian,ETHNICITY,ETHNICITY
SM257211,35.05,53.2,Russian,ETHNICITY,ETHNICITY
SM257211,35.05,53.2,Russian,ETHNICITY,ETHNICITY
SM257211,35.05,53.2,Russian,ETHNICITY,ETHNICITY
SM257211,35.05,53.2,Russian,ETHNICITY,ETHNICITY
SM257211,35.05,53.2,Russian,ETHNICITY,ETHNICITY
SM257211,35.05,53.2,Russian,ETHNICITY,ETHNICITY
SM257211,35.05,53.2,Russian,ETHNICITY,ETHNICITY
SM257211,35.05,53.2,Russian,ETHNICITY,ETHNICITY
SM257211,35.05,53.2,Russian,ETHNICITY,ETHNICITY
SM257211,35.05,53.2,Russian,ETHNICITY,ETHNICITY
SM257211,41.333,52.667,Russian,ETHNICITY,ETHNICITY
SM257211,41.333,52.667,Russian,ETHNICITY,ETHNICITY
SM257211,41.333,52.667,Russian,ETHNICITY,ETHNICITY
SM257211,41.333,52.667,Russian,ETHNICITY,ETHNICITY
SM257211,41.333,52.667,Russian,ETHNICITY,ETHNICITY
SM257211,41.333,52.667,Russian,ETHNICITY,ETHNICITY
SM257211,41.333,52.667,Russian,ETHNICITY,ETHNICITY
SM257211,41.333,52.667,Russian,ETHNICITY,ETHNICITY
SM257211,41.333,52.667,Russian,ETHNICITY,ETHNICITY
SM257211,41.333,52.667,Russian,ETHNICITY,ETHNICITY
SM257211,41.333,52.667,Russian,ETHNICITY,ETHNICITY
SM257211,41.333,52.667,Russian,ETHNICITY,ETHNICITY
SM257211,41.333,52.667,Russian,ETHNICITY,ETHNICITY
SM257211,41,53,Russian,ETHNICITY,ETHNICITY
SM257211,41,53,Russian,ETHNICITY,ETHNICITY
SM257211,41,53,Russian,ETHNICITY,ETHNICITY
SM257211,41,53,Russian,ETHNICITY,ETHNICITY
SM257211,41,53,Russian,ETHNICITY,ETHNICITY
SM257211,41,53,Russian,ETHNICITY,ETHNICITY
SM257211,41,53,Russian,ETHNICITY,ETHNICITY
SM257211,41,53,Russian,ETHNICITY,ETHNICITY
SM257211,41,53,Russian,ETHNICITY,ETHNICITY
SM257211,41,53,Russian,ETHNICITY,ETHNICITY
SM257211,41,53,Russian,ETHNICITY,ETHNICITY
SM257211,41,53,Russian,ETHNICITY,ETHNICITY
SM257211,41,53,Russian,ETHNICITY,ETHNICITY
SM257211,41.33,52.67,Russian,ETHNICITY,ETHNICITY
SM257211,41.33,52.67,Russian,ETHNICITY,ETHNICITY
SM257211,41.33,52.67,Russian,ETHNICITY,ETHNICITY
SM257211,41.33,52.67,Russian,ETHNICITY,ETHNICITY
SM257211,41.33,52.67,Russian,ETHNICITY,ETHNICITY
SM257211,41.33,52.67,Russian,ETHNICITY,ETHNICITY
SM257211,41.33,52.67,Russian,ETHNICITY,ETHNICITY
SM257211,41.33,52.67,Russian,ETHNICITY,ETHNICITY
SM257211,41.33,52.67,Russian,ETHNICITY,ETHNICITY
SM257211,41.33,52.67,Russian,ETHNICITY,ETHNICITY
SM257211,41.33,52.67,Russian,ETHNICITY,ETHNICITY
SM257211,41.33,52.67,Russian,ETHNICITY,ETHNICITY
SM257211,41.33,52.67,Russian,ETHNICITY,ETHNICITY
SM257211,34.53,54.31,Russian,ETHNICITY,ETHNICITY
SM257211,34.53,54.31,Russian,ETHNICITY,ETHNICITY
SM257211,34.53,54.31,Russian,ETHNICITY,ETHNICITY
SM257211,34.53,54.31,Russian,ETHNICITY,ETHNICITY
SM257211,34.53,54.31,Russian,ETHNICITY,ETHNICITY
SM257211,34.53,54.31,Russian,ETHNICITY,ETHNICITY
SM257211,34.53,54.31,Russian,ETHNICITY,ETHNICITY
SM257211,34.53,54.31,Russian,ETHNICITY,ETHNICITY
SM257211,34.53,54.31,Russian,ETHNICITY,ETHNICITY
SM257211,34.53,54.31,Russian,ETHNICITY,ETHNICITY
SM257211,34.53,54.31,Russian,ETHNICITY,ETHNICITY
SM257211,34.53,54.31,Russian,ETHNICITY,ETHNICITY
SM257211,34.53,54.31,Russian,ETHNICITY,ETHNICITY
SM257211,45.94,61.56,Russian,ETHNICITY,ETHNICITY
SM257211,45.94,61.56,Russian,ETHNICITY,ETHNICITY
SM257211,45.94,61.56,Russian,ETHNICITY,ETHNICITY
SM257211,45.94,61.56,Russian,ETHNICITY,ETHNICITY
SM257211,45.94,61.56,Russian,ETHNICITY,ETHNICITY
SM257211,45.94,61.56,Russian,ETHNICITY,ETHNICITY
SM257211,45.94,61.56,Russian,ETHNICITY,ETHNICITY
SM257211,45.94,61.56,Russian,ETHNICITY,ETHNICITY
SM257211,45.94,61.56,Russian,ETHNICITY,ETHNICITY
SM257211,45.94,61.56,Russian,ETHNICITY,ETHNICITY
SM257211,45.94,61.56,Russian,ETHNICITY,ETHNICITY
SM257211,45.94,61.56,Russian,ETHNICITY,ETHNICITY
SM257211,45.94,61.56,Russian,ETHNICITY,ETHNICITY
SM257211,40.53,54.4,Russian,ETHNICITY,ETHNICITY
SM257211,40.53,54.4,Russian,ETHNICITY,ETHNICITY
SM257211,40.53,54.4,Russian,ETHNICITY,ETHNICITY
SM257211,40.53,54.4,Russian,ETHNICITY,ETHNICITY
SM257211,40.53,54.4,Russian,ETHNICITY,ETHNICITY
SM257211,40.53,54.4,Russian,ETHNICITY,ETHNICITY
SM257211,40.53,54.4,Russian,ETHNICITY,ETHNICITY
SM257211,40.53,54.4,Russian,ETHNICITY,ETHNICITY
SM257211,40.53,54.4,Russian,ETHNICITY,ETHNICITY
SM257211,40.53,54.4,Russian,ETHNICITY,ETHNICITY
SM257211,40.53,54.4,Russian,ETHNICITY,ETHNICITY
SM257211,40.53,54.4,Russian,ETHNICITY,ETHNICITY
SM257211,40.53,54.4,Russian,ETHNICITY,ETHNICITY
SM257211,32.87,53.95,Russian,ETHNICITY,ETHNICITY
SM257211,32.87,53.95,Russian,ETHNICITY,ETHNICITY
SM257211,32.87,53.95,Russian,ETHNICITY,ETHNICITY
SM257211,32.87,53.95,Russian,ETHNICITY,ETHNICITY
SM257211,32.87,53.95,Russian,ETHNICITY,ETHNICITY
SM257211,32.87,53.95,Russian,ETHNICITY,ETHNICITY
SM257211,32.87,53.95,Russian,ETHNICITY,ETHNICITY
SM257211,32.87,53.95,Russian,ETHNICITY,ETHNICITY
SM257211,32.87,53.95,Russian,ETHNICITY,ETHNICITY
SM257211,32.87,53.95,Russian,ETHNICITY,ETHNICITY
SM257211,32.87,53.95,Russian,ETHNICITY,ETHNICITY
SM257211,32.87,53.95,Russian,ETHNICITY,ETHNICITY
SM257211,32.87,53.95,Russian,ETHNICITY,ETHNICITY
SM257211,44.26,64,Russian,ETHNICITY,ETHNICITY
SM257211,44.26,64,Russian,ETHNICITY,ETHNICITY
SM257211,44.26,64,Russian,ETHNICITY,ETHNICITY
SM257211,44.26,64,Russian,ETHNICITY,ETHNICITY
SM257211,44.26,64,Russian,ETHNICITY,ETHNICITY
SM257211,44.26,64,Russian,ETHNICITY,ETHNICITY
SM257211,44.26,64,Russian,ETHNICITY,ETHNICITY
SM257211,44.26,64,Russian,ETHNICITY,ETHNICITY
SM257211,44.26,64,Russian,ETHNICITY,ETHNICITY
SM257211,44.26,64,Russian,ETHNICITY,ETHNICITY
SM257211,44.26,64,Russian,ETHNICITY,ETHNICITY
SM257211,44.26,64,Russian,ETHNICITY,ETHNICITY
SM257211,44.26,64,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,39.03,54.23,Russian,ETHNICITY,ETHNICITY
SM257211,39.03,54.23,Russian,ETHNICITY,ETHNICITY
SM257211,39.03,54.23,Russian,ETHNICITY,ETHNICITY
SM257211,39.03,54.23,Russian,ETHNICITY,ETHNICITY
SM257211,39.03,54.23,Russian,ETHNICITY,ETHNICITY
SM257211,39.03,54.23,Russian,ETHNICITY,ETHNICITY
SM257211,39.03,54.23,Russian,ETHNICITY,ETHNICITY
SM257211,39.03,54.23,Russian,ETHNICITY,ETHNICITY
SM257211,39.03,54.23,Russian,ETHNICITY,ETHNICITY
SM257211,39.03,54.23,Russian,ETHNICITY,ETHNICITY
SM257211,39.03,54.23,Russian,ETHNICITY,ETHNICITY
SM257211,39.03,54.23,Russian,ETHNICITY,ETHNICITY
SM257211,39.03,54.23,Russian,ETHNICITY,ETHNICITY
SM257211,45.74,64.9,Russian,ETHNICITY,ETHNICITY
SM257211,45.74,64.9,Russian,ETHNICITY,ETHNICITY
SM257211,45.74,64.9,Russian,ETHNICITY,ETHNICITY
SM257211,45.74,64.9,Russian,ETHNICITY,ETHNICITY
SM257211,45.74,64.9,Russian,ETHNICITY,ETHNICITY
SM257211,45.74,64.9,Russian,ETHNICITY,ETHNICITY
SM257211,45.74,64.9,Russian,ETHNICITY,ETHNICITY
SM257211,45.74,64.9,Russian,ETHNICITY,ETHNICITY
SM257211,45.74,64.9,Russian,ETHNICITY,ETHNICITY
SM257211,45.74,64.9,Russian,ETHNICITY,ETHNICITY
SM257211,45.74,64.9,Russian,ETHNICITY,ETHNICITY
SM257211,45.74,64.9,Russian,ETHNICITY,ETHNICITY
SM257211,45.74,64.9,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM257211,40,61,Russian,ETHNICITY,ETHNICITY
SM243401,44,56,Russian-Tajik Sign Language,LANGUAGE,LANGUAGE
SM261792,16.6721,47.8017,Rust,ADM2,DISTRICT
SM260219,69.8173,37.1062,Rustaq,ADM2,DISTRICT
SM243411,22.9579,48.7171,Rusyn,LANGUAGE,LANGUAGE
SM262010,29.9778,-3.9282,Rutana,ADM2,DISTRICT
SM2929,30.1,-3.8667,Rutana,ADM1,DISTRICT
SM261982,29.7479,-3.3078,Rutegama,ADM2,DISTRICT
SM302616,-86.4167,35.8427,Rutherford,ADM2,DISTRICT
SM302010,-81.9201,35.4023,Rutherford,ADM2,DISTRICT
SM302924,-73.0366,43.5801,Rutland,ADM2,DISTRICT
SM274678,-0.6667,52.6667,Rutland,ADM1:ADM2,DISTRICT
SM262052,29.832,-3.8782,Rutovu,ADM2,DISTRICT
SM269651,29.446,-1.1873,Rutshuru (ville),ADM2,DISTRICT
SM295162,29.3151,-1.9145,Rutsiro,ADM1:ADM2,DISTRICT
SM243417,47.3244,41.6187,Rutul,LANGUAGE,LANGUAGE
SM257214,47.55,41.25,Rutul,ETHNICITY,ETHNICITY
SM292850,47.25,41.6667,Rutul'skiy rayon,ADM2,DISTRICT
SM243419,32.4179,1.4173,Ruuli,LANGUAGE,LANGUAGE
SM243420,21.4164,-8.8697,Ruund,LANGUAGE,LANGUAGE
SM2930,36.2333,-10.7,Ruvuma,ADM1,DISTRICT
SM257215,39.23,30.59,Ruwallah,ETHNICITY,ETHNICITY
SM257215,39.23,30.59,Ruwallah,ETHNICITY,ETHNICITY
SM257215,39.23,30.59,Ruwallah,ETHNICITY,ETHNICITY
SM257215,36.62,32.44,Ruwallah,ETHNICITY,ETHNICITY
SM257215,36.62,32.44,Ruwallah,ETHNICITY,ETHNICITY
SM257215,36.62,32.44,Ruwallah,ETHNICITY,ETHNICITY
SM257215,37,33,Ruwallah,ETHNICITY,ETHNICITY
SM257215,37,33,Ruwallah,ETHNICITY,ETHNICITY
SM257215,37,33,Ruwallah,ETHNICITY,ETHNICITY
SM257215,38.5,33.25,Ruwallah,ETHNICITY,ETHNICITY
SM257215,38.5,33.25,Ruwallah,ETHNICITY,ETHNICITY
SM257215,38.5,33.25,Ruwallah,ETHNICITY,ETHNICITY
SM265463,-35.9155,-5.8856,Ruy Barbosa,ADM2,DISTRICT
SM267625,-40.3267,-12.2465,Ruy Barbosa,ADM2,DISTRICT
SM262017,30.2747,-3.4332,Ruyigi,ADM2,DISTRICT
SM2931,30.3167,-3.45,Ruyigi,ADM1,DISTRICT
SM13243,67,53,Ruzaevka Audany,ADM2,DISTRICT
SM17061,44.8333,54.0833,Ruzayevskiy Rayon,ADM2,DISTRICT
SM262477,22.9,43.65,Ruzh,ADM2,DISTRICT
SM293708,36.25,55.7,Ruzskiy rayon,ADM2,DISTRICT
SM282134,-104.8783,22.0186,Ruíz,ADM2,DISTRICT
SM296340,15.4904,46.518,Ruše,ADM1:ADM2,DISTRICT
SM305438,16.2545,43.8247,Ružić,ADM2,DISTRICT
SM296190,19.3008,49.0749,Ružomberok,ADM2,DISTRICT
SM243425,36.7686,-3.4286,Rwa,LANGUAGE,LANGUAGE
SM295155,30.3543,-1.9728,Rwamagana,ADM1:ADM2,DISTRICT
SM2932,128.2,41.2,Ryanggang,ADM1,DISTRICT
SM262067,29.7983,-3.7229,Ryansoro,ADM2,DISTRICT
SM2933,40.5,54.25,Ryazan',ADM1,DISTRICT
SM17062,39.75,54.5833,Ryazanskiy rayon,ADM2,DISTRICT
SM17063,94.8333,55.9167,Rybinskiy Rayon (Krasnoyarsk),ADM2,DISTRICT
SM17064,39,58,Rybinskiy Rayon (Yaroslavskaya),ADM2,DISTRICT
SM288734,18.4653,50.1039,Rybnik,ADM2,DISTRICT
SM288733,18.5493,50.1108,Rybnik (City),ADM2,DISTRICT
SM294631,50.1667,55.5833,Rybno-Slobodskiy rayon,ADM2,DISTRICT
SM294211,39.5,54.75,Rybnovskiy rayon,ADM2,DISTRICT
SM271224,16.2833,50.2333,Rychnov nad Kněžnou,ADM2,DISTRICT
SM323803,-118.7065,55.7574,Rycroft,ADM2:ADM3,DISTRICT
SM261457,151.1121,-33.8015,Ryde,ADM2,DISTRICT
SM288901,21.9293,51.6622,Ryki,ADM2,DISTRICT
SM293511,34.6667,51.5,Ryl'skiy rayon,ADM2,DISTRICT
SM323779,-112.4253,53.2958,Ryley,ADM2:ADM3,DISTRICT
SM15269,125.8242,38.9356,Ryokp'o-guyok,ADM2,DISTRICT
SM289191,124.4273,39.9577,Ryongch'ŏn,ADM2,DISTRICT
SM289056,125.3831,38.8608,Ryonggang,ADM2,DISTRICT
SM289033,126.7819,40.5308,Ryongrim,ADM2,DISTRICT
SM15270,125.8108,39.1536,Ryongsong-guyok,ADM2,DISTRICT
SM289151,124.964,38.1523,Ryongyŏn,ADM2,DISTRICT
SM288849,19.4098,53.0661,Rypin,ADM2,DISTRICT
SM257216,124.19,24.41,Ryukyu Islands,ETHNICITY,ETHNICITY
SM278390,140.1853,35.9225,Ryūgasaki,ADM2,DISTRICT
SM289017,21.9589,50.0994,Rzeszów,ADM2,DISTRICT
SM289015,21.9885,50.0055,Rzeszów (City),ADM2,DISTRICT
SM15021,31.0593,49.9705,Rzhyshchiv,ADM2,DISTRICT
SM270812,-73.6566,5.484,Ráquira,ADM2,DISTRICT
SM296544,15.1179,60.8863,Rättvik,ADM2,DISTRICT
SM286282,10.8714,59.3516,Råde,ADM2,DISTRICT
SM286042,11.0653,59.9263,Rælingen,ADM2,DISTRICT
SM276146,19.161,47.9174,Rétság,ADM2,DISTRICT
SM243197,55.5321,-21.1131,Réunion Creole French,LANGUAGE,LANGUAGE
SM285461,-85.2158,13.0345,Río Blanco,ADM2,DISTRICT
SM282771,-97.1572,18.8387,Río Blanco,ADM2,DISTRICT
SM275286,-91.6846,15.0374,Río Blanco,ADM2,DISTRICT
SM282663,-98.0388,25.8385,Río Bravo,ADM2,DISTRICT
SM275367,-91.32,14.4,Río Bravo,ADM2,DISTRICT
SM271104,-76.9172,20.5636,Río Cauto,ADM2,DISTRICT
SM283070,-103.0694,23.8063,Río Grande,ADM2,DISTRICT
SM2889,-65.8177,18.3416,Río Grande,ADM1,DISTRICT
SM275385,-89.59,15.0953,Río Hondo,ADM2,DISTRICT
SM282996,-88.1244,21.5024,Río Lagartos,ADM2,DISTRICT
SM303561,-66.0718,1.7136,Río Negro,ADM2,DISTRICT
SM2893,-57.3333,-32.75,Río Negro,ADM1,DISTRICT
SM2892,-67,-40,Río Negro,ADM1,DISTRICT
SM2894,-84.5833,11.3333,Río San Juan,ADM1,DISTRICT
SM273634,-79.3994,1.0623,Río Verde,ADM2,DISTRICT
SM286674,-81.1613,7.9144,Río de Jesús,ADM2,DISTRICT
SM269898,-73.5122,8.1788,Río de Oro,ADM2,DISTRICT
SM2896,27.5351,47.9278,Rîşcani,ADM1,DISTRICT
SM303821,-69.4167,6.75,Rómulo Gallegos,ADM2,DISTRICT
SM2919,107.1167,13.8333,Rôtânôkiri,ADM1:ADM3,DISTRICT
SM271678,12.4483,55.6885,Rødovre,ADM2,DISTRICT
SM286077,13.2853,66.712,Rødøy,ADM2,DISTRICT
SM286192,11.3873,62.5756,Røros,ADM2,DISTRICT
SM286079,12.1156,67.5173,Røst,ADM2,DISTRICT
SM286458,13.5631,64.8838,Røyrvik,ADM2,DISTRICT
SM304223,105.0974,10.0171,Rạch Giá,ADM2,DISTRICT
SM5106,69.6821,32.304,S Waziristan (excl. Bannu Frontier Region),ADM2:ADM3,DISTRICT
SM280509,105.0402,11.3183,S'ang,ADM2,DISTRICT
SM244263,97.7126,17.9872,S'gaw Karen,LANGUAGE,LANGUAGE
SM262607,24.25,52.9167,S'vislach,ADM2,DISTRICT
SM243432,168.228,-15.9534,Sa,LANGUAGE,LANGUAGE
SM2934,102.3333,13.75,Sa Kaeo,ADM1,DISTRICT
SM304282,103.8404,22.3377,Sa Pa,ADM2,DISTRICT
SM304244,107.5986,14.3205,Sa Thầy,ADM2,DISTRICT
SM304019,105.7476,10.3055,Sa Đéc,ADM2,DISTRICT
SM295464,159.7667,-11.2849,Sa'Aiho,ADM2,DISTRICT
SM261201,-170.5752,-14.3028,Sa'Ole,ADM2,DISTRICT
SM243431,161.467,-9.6026,Sa'a,LANGUAGE,LANGUAGE
SM304922,43.76,16.9173,Sa'adah,ADM2,DISTRICT
SM243452,115.664,3.5872,Sa'ban,LANGUAGE,LANGUAGE
SM304940,43.5827,15.0712,Sa'fan,ADM2,DISTRICT
SM243878,103.835,10.8739,Sa'och,LANGUAGE,LANGUAGE
SM2935,43.9149,17.0634,Sadah,ADM1,DISTRICT
SM250657,27.167,31.167,Saadi,ETHNICITY,ETHNICITY
SM250657,27.167,31.167,Saadi,ETHNICITY,ETHNICITY
SM250657,28,30,Saadi,ETHNICITY,ETHNICITY
SM250657,28,30,Saadi,ETHNICITY,ETHNICITY
SM243433,-17.0568,14.625,Saafi-Saafi,LANGUAGE,LANGUAGE
SM295837,42.0627,1.6286,Saakow,ADM2,DISTRICT
SM243436,87.9534,26.8442,Saam,LANGUAGE,LANGUAGE
SM255194,39.64,66.95,Saami,ETHNICITY,ETHNICITY
SM255194,39.64,66.95,Saami,ETHNICITY,ETHNICITY
SM255194,39.64,66.95,Saami,ETHNICITY,ETHNICITY
SM255194,39.64,66.95,Saami,ETHNICITY,ETHNICITY
SM255194,31.91,68.01,Saami,ETHNICITY,ETHNICITY
SM255194,31.91,68.01,Saami,ETHNICITY,ETHNICITY
SM255194,31.91,68.01,Saami,ETHNICITY,ETHNICITY
SM255194,31.91,68.01,Saami,ETHNICITY,ETHNICITY
SM255194,29.41,69.6,Saami,ETHNICITY,ETHNICITY
SM255194,29.41,69.6,Saami,ETHNICITY,ETHNICITY
SM255194,29.41,69.6,Saami,ETHNICITY,ETHNICITY
SM255194,29.41,69.6,Saami,ETHNICITY,ETHNICITY
SM255194,37.68,67.48,Saami,ETHNICITY,ETHNICITY
SM255194,37.68,67.48,Saami,ETHNICITY,ETHNICITY
SM255194,37.68,67.48,Saami,ETHNICITY,ETHNICITY
SM255194,37.68,67.48,Saami,ETHNICITY,ETHNICITY
SM255194,27,66.75,Saami,ETHNICITY,ETHNICITY
SM255194,27,66.75,Saami,ETHNICITY,ETHNICITY
SM255194,27,66.75,Saami,ETHNICITY,ETHNICITY
SM255194,27,66.75,Saami,ETHNICITY,ETHNICITY
SM255194,22,68,Saami,ETHNICITY,ETHNICITY
SM255194,22,68,Saami,ETHNICITY,ETHNICITY
SM255194,22,68,Saami,ETHNICITY,ETHNICITY
SM255194,22,68,Saami,ETHNICITY,ETHNICITY
SM255194,21.5,68.7,Saami,ETHNICITY,ETHNICITY
SM255194,21.5,68.7,Saami,ETHNICITY,ETHNICITY
SM255194,21.5,68.7,Saami,ETHNICITY,ETHNICITY
SM255194,21.5,68.7,Saami,ETHNICITY,ETHNICITY
SM243437,34.0335,0.3652,Saamia,LANGUAGE,LANGUAGE
SM257217,-123.5,48.4,Saanich,ETHNICITY,ETHNICITY
SM257218,49.44,17.62,Saar,ETHNICITY,ETHNICITY
SM11192,24.9556,58.138,Saarde vald,ADM2,DISTRICT
SM2936,22.5833,58.4167,Saare,ADM1:ADMX,DISTRICT
SM11193,22.4935,58.3781,Saaremaa vald,ADM2,DISTRICT
SM11227,25.3007,63.0413,Saarijaervi-Viitasaari,ADM2,DISTRICT
SM2937,6.9608,49.4047,Saarland,ADM1,DISTRICT
SM243443,120.711,23.2632,Saaroa,LANGUAGE,LANGUAGE
SM261898,48.4167,39.8333,Saatlı,ADM1:ADM2,DISTRICT
SM260699,-62.5,-37.75,Saavedra,ADM2,DISTRICT
SM243456,18.4906,11.3072,Saba,LANGUAGE,LANGUAGE
SM10051,114.9257,4.8749,Saba,ADM2,DISTRICT
SM2938,-63.2333,17.6333,Saba,ADM1,DISTRICT
SM297585,100.9123,6.5297,Saba Yoi,ADM2,DISTRICT
SM279648,136.2183,35.9593,Sabae,ADM2,DISTRICT
SM305011,44.67,14.2759,Sabah,ADM2,DISTRICT
SM2939,117,5.5,Sabah (1984-),ADM1,DISTRICT
SM243453,115.512,5.3043,Sabah Bisaya,LANGUAGE,LANGUAGE
SM243457,45.35,15.42,Sabaic,LANGUAGE,LANGUAGE
SM284390,101.1167,3.5833,Sabak Bernam,ADM2,DISTRICT
SM297963,9.2978,35.2318,Sabalat Ouled Asker,ADM2,DISTRICT
SM16158,-67.1496,18.1804,Sabalos Barrio,ADM2,DISTRICT
SM16159,-65.9833,18.4163,Sabana Abajo Barrio,ADM2,DISTRICT
SM16162,-65.7351,18.3203,Sabana Barrio (Luquillo),ADM2,DISTRICT
SM16160,-66.3782,18.2047,Sabana Barrio (Orocovis),ADM2,DISTRICT
SM16161,-66.3328,18.4566,Sabana Barrio (Vega Alta),ADM2,DISTRICT
SM16163,-67.0923,18.0874,Sabana Eneas Barrio,ADM2,DISTRICT
SM2940,-66.9496,18.0939,Sabana Grande,ADM1,DISTRICT
SM16164,-67.0648,18.0967,Sabana Grande Abajo Barrio,ADM2,DISTRICT
SM16165,-66.6807,18.2929,Sabana Grande Barrio,ADM2,DISTRICT
SM16166,-66.9605,18.0789,Sabana Grande Barrio-Pueblo,ADM2,DISTRICT
SM271806,-69.8333,19,Sabana Grande de Boyá,ADM2,DISTRICT
SM271835,-70.1615,18.2593,Sabana Grande de Palenque,ADM2,DISTRICT
SM16167,-66.6146,18.3771,Sabana Hoyos Barrio,ADM2,DISTRICT
SM271875,-70.7402,19.3424,Sabana Iglesia,ADM2,DISTRICT
SM271840,-70.5451,18.6414,Sabana Larga,ADM2,DISTRICT
SM16168,-66.5269,18.0269,Sabana Llana Barrio,ADM2,DISTRICT
SM16169,-66.0124,18.4064,Sabana Llana Norte Barrio,ADM2,DISTRICT
SM16170,-66.0257,18.3865,Sabana Llana Sur Barrio,ADM2,DISTRICT
SM16171,-66.1998,18.4451,Sabana Seca Barrio,ADM2,DISTRICT
SM271767,-70.8881,18.4138,Sabana Yegua,ADM2,DISTRICT
SM16172,-67.0502,18.0233,Sabana Yeguas Barrio,ADM2,DISTRICT
SM270504,-73.5969,7.4184,Sabana de Torres,ADM2,DISTRICT
SM271912,-69.4167,19.0333,Sabana de la Mar,ADM2,DISTRICT
SM275921,-87.2559,13.8025,Sabanagrande,ADM2,DISTRICT
SM270666,-74.7705,10.8008,Sabanagrande,ADM2,DISTRICT
SM270667,-74.9572,10.6164,Sabanalarga,ADM2,DISTRICT
SM270255,-75.7937,6.9057,Sabanalarga,ADM2,DISTRICT
SM270882,-73.0082,4.7351,Sabanalarga,ADM2,DISTRICT
SM10454,-74.2601,10.1107,Sabanas de San Angel,ADM2,DISTRICT
SM257221,-80,22.34,Sabaneque,ETHNICITY,ETHNICITY
SM270256,-75.6085,6.1382,Sabaneta,ADM2,DISTRICT
SM16174,-67.1781,18.2542,Sabanetas Barrio (Mayagüez),ADM2,DISTRICT
SM16173,-66.5785,18.0198,Sabanetas Barrio (Ponce),ADM2,DISTRICT
SM276293,95.3322,5.8794,Sabang,ADM2,DISTRICT
SM283185,-92.5837,17.3031,Sabanilla,ADM2,DISTRICT
SM243454,-60.335,-12.9857,Sabanê,LANGUAGE,LANGUAGE
SM291364,26.8853,47.0019,Sabaoani,ADM2,DISTRICT
SM243449,34.6664,1.013,Sabaot,LANGUAGE,LANGUAGE
SM276830,73.002,23.6297,Sabar Kantha (2013-),ADM2,DISTRICT
SM4211,80.5,6.75,Sabaragamuwa Province,ADM1,DISTRICT
SM260078,69.9118,33.5435,Sabari,ADM2,DISTRICT
SM263849,-43.7833,-19.8539,Sabará,ADM2,DISTRICT
SM257224,139.03,-2.24,Saberi,ETHNICITY,ETHNICITY
SM277974,36.7779,32.1794,Sabha,ADM2,DISTRICT
SM2941,14.9667,26.9,Sabha,ADM1,DISTRICT
SM13787,22.5715,57.0476,Sabile,ADM2,DISTRICT
SM283298,-101.1323,27.8962,Sabinas,ADM2,DISTRICT
SM282188,-100.1127,26.5019,Sabinas Hidalgo,ADM2,DISTRICT
SM302754,-93.8518,31.3432,Sabine,ADM2,DISTRICT
SM301145,-93.5547,31.564,Sabine,ADM2,DISTRICT
SM266881,-49.5765,-21.4797,Sabino,ADM2,DISTRICT
SM296163,21.0984,49.1011,Sabinov,ADM2,DISTRICT
SM294632,50.5,56,Sabinskiy rayon,ADM2,DISTRICT
SM263850,-43.0655,-18.6489,Sabinópolis,ADM2,DISTRICT
SM304988,44.0462,13.5318,Sabir Al Mawadim,ADM2,DISTRICT
SM261899,48.6667,39.9167,Sabirabad,ADM1:ADM2,DISTRICT
SM295792,43.5205,1.3833,Sablale,ADM2,DISTRICT
SM4364,0.3236,9.7018,Saboba,ADM2,DISTRICT
SM267842,-39.8857,-6.4781,Saboeiro,ADM2,DISTRICT
SM285223,6.2641,13.4847,Sabon Birni,ADM2,DISTRICT
SM284819,7.697,11.164,Sabon-Ga,ADM2,DISTRICT
SM305694,15.4752,44.9835,Saborsko,ADM2,DISTRICT
SM270814,-73.7814,5.6879,Saboyá,ADM2,DISTRICT
SM289363,-7.5902,41.2532,Sabrosa,ADM2,DISTRICT
SM17808,41.9167,19.7,Sabt Al Alayah,ADM2,DISTRICT
SM276497,121.7889,-10.5629,Sabu Raijua,ADM2,DISTRICT
SM289230,-7.0931,40.3699,Sabugal,ADM2,DISTRICT
SM284921,7.1167,11.3,Sabuwa,ADM2,DISTRICT
SM17809,42.6333,17.25,Sabya',ADM2,DISTRICT
SM275834,-86.1632,15.4865,Sabá,ADM2,DISTRICT
SM264686,-51.6054,-23.3658,Sabáudia,ADM2,DISTRICT
SM243470,101.065,5.3469,Sabüm,LANGUAGE,LANGUAGE
SM300863,-95.1054,42.3863,Sac,ADM2,DISTRICT
SM295661,-89.4437,13.7382,Sacacoyo,ADM2,DISTRICT
SM292458,22.1589,47.0471,Sacadat,ADM2,DISTRICT
SM292014,21.039,45.7568,Sacalaz,ADM2,DISTRICT
SM282997,-89.6089,20.5197,Sacalum,ADM2,DISTRICT
SM275238,-91.1129,15.2632,Sacapulas,ADM2,DISTRICT
SM243474,-91.109,15.274,Sacapulteco,LANGUAGE,LANGUAGE
SM291683,22.6827,47.4696,Sacaseni,ADM2,DISTRICT
SM2942,-90.75,14.5833,Sacatepéquez,ADM1,DISTRICT
SM291030,24.4351,47.6369,Sacel,ADM2,DISTRICT
SM290712,24.918,46.3287,Sacel,ADM2,DISTRICT
SM290192,28.6638,44.4887,Sacele,ADM2,DISTRICT
SM292649,25.6989,45.6177,Sacele,ADM2,DISTRICT
SM290651,23.5372,45.1065,Sacelu,ADM2,DISTRICT
SM291921,25.0607,44.2331,Saceni,ADM2,DISTRICT
SM280549,128.0686,35.0039,Sacheon,ADM2,DISTRICT
SM274801,43.4194,42.3453,Sachkhere,ADM2,DISTRICT
SM2943,13.25,51,Sachsen,ADM1,DISTRICT
SM2944,11.7333,52,Sachsen-Anhalt,ADM1,DISTRICT
SM14095,-8.8333,6.9167,Saclepea-Mah,ADM2,DISTRICT
SM14095,-8.8333,6.9167,Saclepea-Mah,ADM2,DISTRICT
SM14095,-8.8333,6.9167,Saclepea-Mah,ADM2,DISTRICT
SM14095,-8.9691,6.9959,Saclepea-Mah,ADM2,DISTRICT
SM14095,-8.9691,6.9959,Saclepea-Mah,ADM2,DISTRICT
SM14095,-8.9691,6.9959,Saclepea-Mah,ADM2,DISTRICT
SM14095,-8.8618,6.9054,Saclepea-Mah,ADM2,DISTRICT
SM14095,-8.8618,6.9054,Saclepea-Mah,ADM2,DISTRICT
SM14095,-8.8618,6.9054,Saclepea-Mah,ADM2,DISTRICT
SM16175,-65.666,18.2734,Saco Barrio,ADM2,DISTRICT
SM292015,21.3983,45.6353,Sacosu Turcesc,ADM2,DISTRICT
SM303190,-121.3442,38.4493,Sacramento,ADM2,DISTRICT
SM283300,-101.7581,27.0134,Sacramento,ADM2,DISTRICT
SM263851,-47.226,-19.8804,Sacramento,ADM2,DISTRICT
SM290047,22.1105,45.5715,Sacu,ADM2,DISTRICT
SM292459,22.118,47.33,Sacueni,ADM2,DISTRICT
SM290127,22.8601,46.816,Sacuieu,ADM2,DISTRICT
SM2945,45.1029,-12.8505,Sada,ADM1,DISTRICT
SM257229,119.46,-2.72,Sadang,ETHNICITY,ETHNICITY
SM297586,100.4241,6.6714,Sadao,ADM2,DISTRICT
SM323804,-119.3334,55.8543,Saddle Hills County,ADM2:ADM3,DISTRICT
SM278941,138.3898,38.0444,Sado,ADM2,DISTRICT
SM15271,125.9019,38.9858,Sadong-guyok,ADM2,DISTRICT
SM291838,25.5038,47.5425,Sadova,ADM2,DISTRICT
SM290402,23.9462,43.8875,Sadova,ADM2,DISTRICT
SM262346,25,42.1333,Sadovo,ADM2,DISTRICT
SM12050,44.4625,33.383,Sadr,ADM2,DISTRICT
SM243483,84.8114,23.6585,Sadri,LANGUAGE,LANGUAGE
SM243485,102,24.3,Sadu,LANGUAGE,LANGUAGE
SM291749,24.1839,45.672,Sadu,ADM2,DISTRICT
SM289100,130.1229,42.6471,Saebyŏl,ADM2,DISTRICT
SM262347,24.5833,42.3167,Saedinenie,ADM2,DISTRICT
SM243486,104.787,17.4558,Saek,LANGUAGE,LANGUAGE
SM280372,107.1553,12.5048,Saen Monourom,ADM2,DISTRICT
SM243487,146.078,-5.6481,Saep,LANGUAGE,LANGUAGE
SM370451,5.9864,49.7272,Saeul,ADM1:ADM2:ADM3,DISTRICT
SM293477,62.5,54.9167,Safakulevskiy rayon,ADM2,DISTRICT
SM243488,-2.5847,8.9377,Safaliba,LANGUAGE,LANGUAGE
SM284922,7.2527,12.4949,Safana,ADM2,DISTRICT
SM257234,55.37,32.04,Safari,ETHNICITY,ETHNICITY
SM243493,146.09,-7.0542,Safeyoka,LANGUAGE,LANGUAGE
SM4446,-8.8333,32.1667,Safi (2015-),ADM2,DISTRICT
SM275084,-15.6475,11.9454,Safim,ADM2,DISTRICT
SM296755,36.1229,34.8042,Safita,ADM2,DISTRICT
SM294458,33.1667,55.1667,Safonovskiy rayon,ADM2,DISTRICT
SM298572,32.6993,41.2603,Safranbolu,ADM2,DISTRICT
SM243494,33.4766,-8.5494,Safwa,LANGUAGE,LANGUAGE
SM250653,34.03,-8.11,Safwa,ETHNICITY,ETHNICITY
SM250653,34.03,-8.11,Safwa,ETHNICITY,ETHNICITY
SM250653,33,-8,Safwa,ETHNICITY,ETHNICITY
SM250653,33,-8,Safwa,ETHNICITY,ETHNICITY
SM291629,22.7867,47.0704,Sag,ADM2,DISTRICT
SM279137,130.2643,33.3256,Saga,ADM2,DISTRICT
SM2946,130.1149,33.289,Saga,ADM1:ADMX,DISTRICT
SM257235,121,17,Sagada,ETHNICITY,ETHNICITY
SM301201,-69.8393,43.9117,Sagadahoc,ADM2,DISTRICT
SM279555,140.2333,38.45,Sagae,ADM2,DISTRICT
SM304729,-171.8537,-13.8268,Sagaga Le Usoga,ADM2,DISTRICT
SM304728,-171.8735,-13.8709,Sagaga le Falefa,ADM2,DISTRICT
SM461523,89,55,Sagai,ETHNICITY,ETHNICITY
SM283475,95.6183,21.9942,Sagaing,ADM2,DISTRICT
SM2947,95.5,23.5,Sagaing,ADM1,DISTRICT
SM243495,36.9153,-6.9854,Sagala,LANGUAGE,LANGUAGE
SM243498,38.5037,-3.6016,Sagalla,LANGUAGE,LANGUAGE
SM278543,139.35,35.55,Sagamihara,ADM2,DISTRICT
SM276998,78.75,23.75,Sagar,ADM2,DISTRICT
SM250654,36.84,-7.44,Sagara,ETHNICITY,ETHNICITY
SM11340,45.3241,41.7349,Sagarejo,ADM2,DISTRICT
SM11341,45.25,41.6667,Sagarejos Munitsip'alit'et'I (excl. Sagarejo),ADM2,DISTRICT
SM285335,6.2113,5.1472,Sagbama,ADM2,DISTRICT
SM257237,-85.7,64.24,Sagdlirmiut,ETHNICITY,ETHNICITY
SM289915,27.025,45.1006,Sageata,ADM2,DISTRICT
SM295357,159.729,-9.3276,Saghalu,ADM2,DISTRICT
SM259992,63.6737,33.5809,Saghar,ADM2,DISTRICT
SM283727,91.6064,50.3306,Sagil,ADM2,DISTRICT
SM301325,-84.0532,43.335,Saginaw,ADM2,DISTRICT
SM4371,-0.8654,9.4687,Sagnarigu,ADM2,DISTRICT
SM265865,-53.1285,-27.7148,Sagrada Família,ADM2,DISTRICT
SM266882,-51.005,-21.8653,Sagres,ADM2,DISTRICT
SM283685,89.6361,48.8943,Sagsai,ADM2,DISTRICT
SM290527,21.3424,46.0425,Sagu,ADM2,DISTRICT
SM271128,-75.2333,20.5833,Sagua de Tánamo,ADM2,DISTRICT
SM271057,-80.0651,22.8257,Sagua la Grande,ADM2,DISTRICT
SM303369,-106.2816,38.0806,Saguache,ADM2,DISTRICT
SM10218,-71.749,49.8668,Saguenay/Lac-Saint-Jean,ADM2,DISTRICT
SM304818,48.8955,15.5342,Sah,ADM2,DISTRICT
SM280528,128.9873,35.0855,Saha,ADM2,DISTRICT
SM304923,43.6831,16.8852,Sahar,ADM2,DISTRICT
SM277327,77.6833,29.9,Saharanpur,ADM2,DISTRICT
SM277426,86.5898,25.8166,Saharsa (1999-),ADM2,DISTRICT
SM289916,26.5634,45.0224,Sahateni,ADM2,DISTRICT
SM296939,103.5791,16.7099,Sahatsakhan,ADM2,DISTRICT
SM2948,-0.0458,14.0335,Sahel,ADM1,DISTRICT
SM276913,87.6733,24.9935,Sahibganj (1999-),ADM2,DISTRICT
SM277151,76.7,30.65,Sahibzada Ajit Singh Nagar,ADM2,DISTRICT
SM250655,10.94,35,Sahil,ETHNICITY,ETHNICITY
SM18601,38.5833,17,Sahil Awraja,ADM2,DISTRICT
SM273774,31.3352,27.0692,Sahil Salim,ADM2,DISTRICT
SM375470,72.9137,30.5482,Sahiwal,ADM2:ADM3,DISTRICT
SM297908,10.7097,35.7341,Sahline,ADM2,DISTRICT
SM277528,47.5167,34.5833,Sahneh,ADM2,DISTRICT
SM243512,39.75,14.75,Saho,LANGUAGE,LANGUAGE
SM251100,-12.71,21.33,Sahraoui,ETHNICITY,ETHNICITY
SM251100,-12.71,21.33,Sahraoui,ETHNICITY,ETHNICITY
SM251100,-8.9,27.3,Sahraoui,ETHNICITY,ETHNICITY
SM251100,-8.9,27.3,Sahraoui,ETHNICITY,ETHNICITY
SM10204,-126.8521,65.2844,Sahtu Region,ADM2,DISTRICT
SM243514,127.493,1.0074,Sahu,LANGUAGE,LANGUAGE
SM14374,-108.9941,29.01,Sahuaripa,ADM2,DISTRICT
SM282073,-102.7512,20.0579,Sahuayo,ADM2,DISTRICT
SM297299,101.5833,6.7,Sai Buri,ADM2,DISTRICT
SM2949,114.2702,22.382,Sai Kung,ADM1,DISTRICT
SM297176,100.6518,13.9073,Sai Mai,ADM2,DISTRICT
SM296952,99.8746,16.4396,Sai Ngam,ADM2,DISTRICT
SM297280,100.3107,14.0063,Sai Noi,ADM2,DISTRICT
SM296953,99.8785,16.3086,Sai Thong Watthana,ADM2,DISTRICT
SM296973,98.9337,14.2565,Sai Yok,ADM2,DISTRICT
SM257251,6.54,32.12,Said Atba,ETHNICITY,ETHNICITY
SM280926,35.328,33.4472,Saida,ADM1:ADM2,DISTRICT
SM243516,30.5302,25.4132,Saidi Arabic,LANGUAGE,LANGUAGE
SM277093,93.0122,22.3767,Saiha,ADM2,DISTRICT
SM13064,133.4548,35.3818,Saihaku-gun,ADM2,DISTRICT
SM279628,133.1083,33.861,Saijō,ADM2,DISTRICT
SM278875,129.682,32.9926,Saikai,ADM2,DISTRICT
SM283768,102.6226,48.6545,Saikhan,ADM2,DISTRICT
SM283815,103.8998,45.4464,Saikhan-Ovoo,ADM2,DISTRICT
SM283799,109.0165,44.6784,Saikhandulaan,ADM2,DISTRICT
SM279006,131.776,32.886,Saiki,ADM2,DISTRICT
SM298135,36.0991,37.9962,Saimbeyli,ADM2,DISTRICT
SM283800,109.8819,44.6254,Sainshand,ADM2,DISTRICT
SM283800,109.8819,44.6254,Sainshand,ADM2,DISTRICT
SM283800,109.8819,44.6254,Sainshand,ADM2,DISTRICT
SM283800,110.1345,44.8869,Sainshand,ADM2,DISTRICT
SM283800,110.1345,44.8869,Sainshand,ADM2,DISTRICT
SM283800,110.1345,44.8869,Sainshand,ADM2,DISTRICT
SM283800,110.1367,44.8917,Sainshand,ADM2,DISTRICT
SM283800,110.1367,44.8917,Sainshand,ADM2,DISTRICT
SM283800,110.1367,44.8917,Sainshand,ADM2,DISTRICT
SM2960,-61.3667,15.5667,Saint Andrew,ADM1,DISTRICT
SM2961,-2.5736,49.4478,Saint Andrew,ADM1,DISTRICT
SM2962,-61.65,12.1167,Saint Andrew,ADM1,DISTRICT
SM2964,-61.2302,13.2039,Saint Andrew,ADM1,DISTRICT
SM2959,-59.5783,13.2399,Saint Andrew,ADM1,DISTRICT
SM2963,-76.75,18.0667,Saint Andrew,ADM1,DISTRICT
SM9464,-60.75,11.1833,"Saint Andrew, Tobago",ADM2,DISTRICT
SM2965,-77.2667,18.35,Saint Ann,ADM1,DISTRICT
SM2966,-62.8333,17.3667,Saint Anne Sandy Point,ADM1,DISTRICT
SM2967,-62.2,16.7,Saint Anthon,ADM1,DISTRICT
SM2968,-2.2006,49.1906,Saint Berlade,ADM1,DISTRICT
SM301146,-89.8242,29.874,Saint Bernard,ADM2,DISTRICT
SM2969,59.6333,-16.6333,Saint Brandon,ADM1,DISTRICT
SM2970,-77.0167,18.0667,Saint Catherine,ADM1,DISTRICT
SM301614,-90.6749,38.7819,Saint Charles,ADM2,DISTRICT
SM301147,-90.3582,29.9055,Saint Charles,ADM2,DISTRICT
SM301326,-82.6644,42.9311,Saint Clair,ADM2,DISTRICT
SM301615,-93.776,38.0372,Saint Clair,ADM2,DISTRICT
SM300673,-89.9284,38.4703,Saint Clair,ADM2,DISTRICT
SM300299,-86.3147,33.7157,Saint Clair,ADM2,DISTRICT
SM2971,-2.0766,49.1657,Saint Clement,ADM1,DISTRICT
SM303274,-92.4528,45.0341,Saint Croix,ADM2,DISTRICT
SM2972,-64.748,17.7356,Saint Croix,ADM1,DISTRICT
SM2973,-61.2833,15.4667,Saint David,ADM1,DISTRICT
SM2974,-61.6667,12.05,Saint David,ADM1,DISTRICT
SM2975,-61.2,13.3,Saint David,ADM1,DISTRICT
SM9465,-60.7167,11.25,"Saint David, Tobago",ADM2,DISTRICT
SM2976,-77.7833,18.05,Saint Elizabeth,ADM1,DISTRICT
SM302410,-90.7478,35.022,Saint Francis,ADM2,DISTRICT
SM301616,-90.4723,37.8103,Saint Francois,ADM2,DISTRICT
SM2980,-61.7333,12.05,Saint George,ADM1,DISTRICT
SM2979,-61.3667,15.3,Saint George,ADM1,DISTRICT
SM2978,-59.5435,13.1393,Saint George,ADM1,DISTRICT
SM2981,-61.1928,13.1652,Saint George,ADM1,DISTRICT
SM2977,-61.7968,17.1227,Saint George,ADM1,DISTRICT
SM2983,-62.7061,17.2973,Saint George Basseterre,ADM1,DISTRICT
SM2984,-62.5667,17.1333,Saint George Gingerland,ADM1,DISTRICT
SM2985,-64.6764,32.3842,Saint George municipality,ADM1,DISTRICT
SM2982,-64.6944,32.3726,Saint George's,ADM1,DISTRICT
SM9466,-60.7,11.2167,"Saint George, Tobago",ADM2,DISTRICT
SM2986,-62.1716,16.7443,Saint Georges,ADM1,DISTRICT
SM301148,-90.7103,30.822,Saint Helena,ADM2,DISTRICT
SM2987,-5.7182,-15.9318,Saint Helena,ADM1,DISTRICT
SM274679,-2.75,53.4167,Saint Helens,ADM1:ADM2,DISTRICT
SM2988,-2.1,49.1833,Saint Helier,ADM1,DISTRICT
SM301149,-90.7963,30.0263,Saint James,ADM2,DISTRICT
SM2989,-59.6196,13.2118,Saint James,ADM1,DISTRICT
SM2990,-77.8833,18.3833,Saint James,ADM1,DISTRICT
SM2991,-62.5667,17.1667,Saint James Windward,ADM1,DISTRICT
SM9467,-60.6167,11.3,Saint John,ADM2,DISTRICT
SM268796,-65.8321,45.3335,Saint John,ADM2,DISTRICT
SM2995,-61.7167,12.1333,Saint John,ADM1,DISTRICT
SM2994,-61.45,15.5833,Saint John,ADM1,DISTRICT
SM2997,-64.7377,18.3276,Saint John,ADM1,DISTRICT
SM2993,-59.503,13.1758,Saint John,ADM1,DISTRICT
SM2996,-2.1375,49.2469,Saint John,ADM1,DISTRICT
SM2992,-61.85,17.1,Saint John,ADM1,DISTRICT
SM2998,-62.8,17.3833,Saint John Capisterre,ADM1,DISTRICT
SM2999,-62.6,17.1167,Saint John Figtree,ADM1,DISTRICT
SM301150,-90.4709,30.1265,Saint John the Baptist,ADM2,DISTRICT
SM300363,-81.4099,29.9122,Saint Johns,ADM2,DISTRICT
SM301327,-85.5277,41.9144,Saint Joseph,ADM2,DISTRICT
SM300760,-86.2899,41.6167,Saint Joseph,ADM2,DISTRICT
SM3001,-61.4333,15.4333,Saint Joseph,ADM1,DISTRICT
SM3000,-59.5442,13.2056,Saint Joseph,ADM1,DISTRICT
SM301151,-92.0059,30.5989,Saint Landry,ADM2,DISTRICT
SM301907,-75.1163,44.5006,Saint Lawrence,ADM2,DISTRICT
SM3002,-2.14,49.2153,Saint Lawrence,ADM1,DISTRICT
SM301405,-92.4615,47.5899,Saint Louis,ADM2,DISTRICT
SM3003,55.4333,-4.6333,Saint Louis,ADM1,DISTRICT
SM18320,-90.4434,38.6407,Saint Louis County (excl. Saint Louis City),ADM2,DISTRICT
SM243521,-61.3641,15.3947,Saint Lucian Creole French,LANGUAGE,LANGUAGE
SM300364,-80.4455,27.3808,Saint Lucie,ADM2,DISTRICT
SM3004,-59.6194,13.3073,Saint Lucy,ADM1,DISTRICT
SM3005,-61.3673,15.2509,Saint Luke,ADM1,DISTRICT
SM17634,-60.918,13.8544,Saint Marie Road,ADM2,DISTRICT
SM3006,-61.3667,15.2167,Saint Mark,ADM1,DISTRICT
SM3007,-61.6833,12.1833,Saint Mark,ADM1,DISTRICT
SM301153,-91.6083,30.1291,Saint Martin,ADM2,DISTRICT
SM3008,-2.5572,49.4333,Saint Martin,ADM1,DISTRICT
SM3009,-2.0497,49.2194,Saint Martin,ADM1,DISTRICT
SM301154,-91.4729,29.6346,Saint Mary,ADM2,DISTRICT
SM9468,-60.6333,11.2333,Saint Mary,ADM2,DISTRICT
SM3011,-76.9,18.3167,Saint Mary,ADM1,DISTRICT
SM3010,-61.85,17.05,Saint Mary,ADM1,DISTRICT
SM3012,-2.1722,49.2358,Saint Mary,ADM1,DISTRICT
SM3013,-62.7333,17.35,Saint Mary Cayon,ADM1,DISTRICT
SM301223,-76.5291,38.2159,Saint Mary's,ADM2,DISTRICT
SM3014,-59.5982,13.1263,Saint Michael,ADM1:ADMX,DISTRICT
SM3015,-2.2056,49.2267,Saint Ouen,ADM1,DISTRICT
SM3016,-61.3,15.2833,Saint Patrick,ADM1,DISTRICT
SM3017,-61.6333,12.2,Saint Patrick,ADM1,DISTRICT
SM3018,-61.2345,13.2399,Saint Patrick,ADM1,DISTRICT
SM9469,-60.8,11.1667,"Saint Patrick, Tobago",ADM2,DISTRICT
SM9470,-60.5667,11.2667,Saint Paul,ADM2,DISTRICT
SM3020,-61.3833,15.35,Saint Paul,ADM1,DISTRICT
SM3019,-61.7667,17.0333,Saint Paul,ADM1,DISTRICT
SM3021,-62.8333,17.4,Saint Paul Capisterre,ADM1,DISTRICT
SM3022,-62.6147,17.1346,Saint Paul Charlestown,ADM1,DISTRICT
SM3025,-61.45,15.5167,Saint Peter,ADM1,DISTRICT
SM3027,-62.205,16.7833,Saint Peter,ADM1,DISTRICT
SM3024,-59.6159,13.261,Saint Peter,ADM1,DISTRICT
SM3023,-61.7482,17.0893,Saint Peter,ADM1,DISTRICT
SM3026,-2.1819,49.2133,Saint Peter,ADM1,DISTRICT
SM3028,-62.7167,17.3167,Saint Peter Basseterre,ADM1,DISTRICT
SM3029,-2.5519,49.46,Saint Peter Port,ADM1,DISTRICT
SM3031,-59.464,13.1339,Saint Philip,ADM1,DISTRICT
SM3030,-61.7167,17.05,Saint Philip,ADM1,DISTRICT
SM3032,-2.6436,49.4456,Saint Pierre du Bois,ADM1,DISTRICT
SM3033,-2.5372,49.4819,Saint Sampson,ADM1,DISTRICT
SM3034,-2.6239,49.4497,Saint Saviour,ADM1,DISTRICT
SM3035,-2.0924,49.1952,Saint Saviour,ADM1,DISTRICT
SM301155,-89.9539,30.4088,Saint Tammany,ADM2,DISTRICT
SM3038,-64.9276,18.3454,Saint Thomas,ADM1,DISTRICT
SM3036,-59.5879,13.1793,Saint Thomas,ADM1,DISTRICT
SM3037,-76.4333,17.9,Saint Thomas,ADM1,DISTRICT
SM3039,-62.6,17.1667,Saint Thomas Lowland,ADM1,DISTRICT
SM3040,-62.8,17.35,Saint Thomas Middle Island,ADM1,DISTRICT
SM295252,-16.4081,16.028,Saint-Louis,ADM2,DISTRICT
SM2954,-15,16.3333,Saint-Louis (2002-),ADM1,DISTRICT
SM276096,-72.6782,19.8714,Saint-Louis du Nord,ADM2,DISTRICT
SM276087,-72.5623,19.0472,Saint-Marc,ADM2,DISTRICT
SM268984,6.9982,46.1538,Saint-Maurice,ADM2,DISTRICT
SM2958,-56.2,46.7833,Saint-Pierre,ADM1,DISTRICT
SM276103,-72.1833,19.4167,Saint-Raphaël,ADM2,DISTRICT
SM301618,-90.1944,37.8944,Sainte Genevieve,ADM2,DISTRICT
SM283816,106.2714,45.751,Saintsagaan,ADM2,DISTRICT
SM283816,106.2714,45.751,Saintsagaan,ADM2,DISTRICT
SM283816,106.3636,45.7586,Saintsagaan,ADM2,DISTRICT
SM283816,106.3636,45.7586,Saintsagaan,ADM2,DISTRICT
SM3041,145.7408,15.1685,Saipan,ADM1,DISTRICT
SM264882,-35.6837,-8.3013,Sairé,ADM2,DISTRICT
SM243525,121.067,24.6043,Saisiyat,LANGUAGE,LANGUAGE
SM279198,139.6455,35.861,Saitama,ADM2,DISTRICT
SM3042,139.649,35.8572,Saitama,ADM1:ADMX,DISTRICT
SM278773,131.3154,32.1729,Saito,ADM2,DISTRICT
SM243527,117.401,2.4343,Sajau-Latti,LANGUAGE,LANGUAGE
SM243526,92.77,27.5795,Sajolang,LANGUAGE,LANGUAGE
SM284939,5.5413,11.1623,Sakaba,ADM2,DISTRICT
SM243529,93.949,25.994,Sakachep,LANGUAGE,LANGUAGE
SM279199,139.406,35.9678,Sakado,ADM2,DISTRICT
SM278838,138.5865,36.8803,Sakae,ADM2,DISTRICT
SM279111,135.4946,34.5317,Sakai,ADM2,DISTRICT
SM279649,136.2554,36.1641,Sakai City,ADM2,DISTRICT
SM278479,133.8922,34.324,Sakaide,ADM2,DISTRICT
SM13065,133.227,35.5203,Sakaiminato Shi,ADM2,DISTRICT
SM17810,40,30.3333,Sakaka,ADM2,DISTRICT
SM278839,138.1939,36.4528,Sakaki,ADM2,DISTRICT
SM250659,48.52,-14.11,Sakalava,ETHNICITY,ETHNICITY
SM250659,48.52,-14.11,Sakalava,ETHNICITY,ETHNICITY
SM250659,44.81,-16.99,Sakalava,ETHNICITY,ETHNICITY
SM250659,44.81,-16.99,Sakalava,ETHNICITY,ETHNICITY
SM250659,44.71,-19.16,Sakalava,ETHNICITY,ETHNICITY
SM250659,44.71,-19.16,Sakalava,ETHNICITY,ETHNICITY
SM250659,44.33,-21.26,Sakalava,ETHNICITY,ETHNICITY
SM250659,44.33,-21.26,Sakalava,ETHNICITY,ETHNICITY
SM250659,43.93,-21,Sakalava,ETHNICITY,ETHNICITY
SM250659,43.93,-21,Sakalava,ETHNICITY,ETHNICITY
SM243538,146.702,-6.1873,Sakam,LANGUAGE,LANGUAGE
SM371041,44.5296,-22.9175,Sakaraha,ADM2:ADM3,DISTRICT
SM3043,30.5833,40.75,Sakarya,ADM1,DISTRICT
SM13788,21.2808,56.8579,Sakas pagasts,ADM2,DISTRICT
SM243533,17.9669,-2.8565,Sakata,LANGUAGE,LANGUAGE
SM279556,139.9843,38.9249,Sakata,ADM2,DISTRICT
SM250658,18,-3,Sakata,ETHNICITY,ETHNICITY
SM243545,11.787,0.2519,Sake,LANGUAGE,LANGUAGE
SM249330,133.98,61.6974,Sakha,LANGUAGE,LANGUAGE
SM3044,130,65,Sakha,ADM1,DISTRICT
SM257268,146.04,69.71,Sakha,ETHNICITY,ETHNICITY
SM257268,146.04,69.71,Sakha,ETHNICITY,ETHNICITY
SM257268,146.04,69.71,Sakha,ETHNICITY,ETHNICITY
SM257268,146.04,69.71,Sakha,ETHNICITY,ETHNICITY
SM257268,130.08,66.95,Sakha,ETHNICITY,ETHNICITY
SM257268,130.08,66.95,Sakha,ETHNICITY,ETHNICITY
SM257268,130.08,66.95,Sakha,ETHNICITY,ETHNICITY
SM257268,130.08,66.95,Sakha,ETHNICITY,ETHNICITY
SM257268,121.76,67.22,Sakha,ETHNICITY,ETHNICITY
SM257268,121.76,67.22,Sakha,ETHNICITY,ETHNICITY
SM257268,121.76,67.22,Sakha,ETHNICITY,ETHNICITY
SM257268,121.76,67.22,Sakha,ETHNICITY,ETHNICITY
SM257268,125,65,Sakha,ETHNICITY,ETHNICITY
SM257268,125,65,Sakha,ETHNICITY,ETHNICITY
SM257268,125,65,Sakha,ETHNICITY,ETHNICITY
SM257268,125,65,Sakha,ETHNICITY,ETHNICITY
SM257268,129.5,62.9829,Sakha,ETHNICITY,ETHNICITY
SM257268,129.5,62.9829,Sakha,ETHNICITY,ETHNICITY
SM257268,129.5,62.9829,Sakha,ETHNICITY,ETHNICITY
SM257268,129.5,62.9829,Sakha,ETHNICITY,ETHNICITY
SM257268,129.5,63,Sakha,ETHNICITY,ETHNICITY
SM257268,129.5,63,Sakha,ETHNICITY,ETHNICITY
SM257268,129.5,63,Sakha,ETHNICITY,ETHNICITY
SM257268,129.5,63,Sakha,ETHNICITY,ETHNICITY
SM3045,143,50,Sakhalin,ADM1,DISTRICT
SM243548,142.3298,48.1419,Sakhalin Ainu,LANGUAGE,LANGUAGE
SM454427,143,52.4165,Sakhalin Ainu,ETHNICITY,ETHNICITY
SM243550,143.1628,51.7814,Sakhalin Nivkh,LANGUAGE,LANGUAGE
SM257269,142.54,47.04,Sakhalin Tatar,ETHNICITY,ETHNICITY
SM285170,3.5894,8.7047,Saki East,ADM2,DISTRICT
SM285171,3.1472,8.5698,Saki West,ADM2,DISTRICT
SM297939,10.8182,34.8327,Sakiet Eddaier,ADM2,DISTRICT
SM297940,10.7441,34.857,Sakiet Ezzit,ADM2,DISTRICT
SM297869,8.4341,36.1816,Sakiet Sidi Youssef,ADM2,DISTRICT
SM243551,121.5314,24.0263,Sakizaya,LANGUAGE,LANGUAGE
SM289192,124.9272,40.3561,Sakju,ADM2,DISTRICT
SM3046,103.8333,17.3333,Sakon Nakhon,ADM1,DISTRICT
SM299888,33.6013,45.1278,Saks'ka,ADM2,DISTRICT
SM299889,33.5291,45.278,Saks'kyi,ADM2,DISTRICT
SM13789,27.1146,56.5201,Sakstagala pagasts,ADM2,DISTRICT
SM278840,138.4453,36.2158,Saku,ADM2,DISTRICT
SM274093,24.7165,59.2807,Saku,ADM2,DISTRICT
SM279330,140.0029,36.7236,Sakura,ADM2,DISTRICT
SM279378,140.2133,35.7062,Sakura,ADM2,DISTRICT
SM278392,140.1079,36.3275,Sakuragawa,ADM2,DISTRICT
SM278909,135.8432,34.5182,Sakurai,ADM2,DISTRICT
SM257272,40.86,4.17,Sakuye,ETHNICITY,ETHNICITY
SM262122,2.661,6.766,Sakété,ADM2,DISTRICT
SM3047,-22.9167,16.75,Sal,ADM1,DISTRICT
SM243563,27.4534,-15.5848,Sala,LANGUAGE,LANGUAGE
SM296565,16.4737,59.9722,Sala,ADM2,DISTRICT
SM257273,29.76,-15.26,Sala,ETHNICITY,ETHNICITY
SM280364,102.6396,12.9798,Sala Krau,ADM2,DISTRICT
SM3048,43.65,34.5,Sala ad-Din,ADM1,DISTRICT
SM292460,22.2721,47.4484,Salacea,ADM2,DISTRICT
SM13790,24.3622,57.7565,Salacgriva,ADM2,DISTRICT
SM4065,24.4519,57.7403,Salacgrivas Novads,ADM1,DISTRICT
SM13791,24.4804,57.7302,Salacgrivas pagasts,ADM2,DISTRICT
SM261157,-58.75,-28.25,Saladas,ADM2,DISTRICT
SM260700,-59.6667,-35.75,Saladillo,ADM2,DISTRICT
SM270099,-76.2096,2.0642,Saladoblanco,ADM2,DISTRICT
SM257274,120.55,-6.23,Salajar,ETHNICITY,ETHNICITY
SM257275,150.62,-9.53,Salakahadi,ETHNICITY,ETHNICITY
SM257275,150.62,-9.53,Salakahadi,ETHNICITY,ETHNICITY
SM257275,151,-10,Salakahadi,ETHNICITY,ETHNICITY
SM257275,151,-10,Salakahadi,ETHNICITY,ETHNICITY
SM14097,-9.9962,6.8398,Salala (excl. Nyafokole),ADM2,DISTRICT
SM274071,-6,40.8333,Salamanca,ADM2,DISTRICT
SM281553,-101.1731,20.5854,Salamanca,ADM2,DISTRICT
SM3050,20.5,11,Salamat,ADM1,DISTRICT
SM270133,-74.7194,10.5173,Salamina,ADM2,DISTRICT
SM270851,-75.4155,5.3701,Salamina,ADM2,DISTRICT
SM11026,32.6804,34.8343,Salamiou,ADM2,DISTRICT
SM296782,37.5924,35.144,Salamiyah,ADM2,DISTRICT
SM243564,23.1502,-5.4494,Salampasu,LANGUAGE,LANGUAGE
SM275750,-86.62,14.8105,Salamá,ADM2,DISTRICT
SM275347,-90.3227,15.0809,Salamá,ADM2,DISTRICT
SM243569,-61.25,-12.0499,Salamãi,LANGUAGE,LANGUAGE
SM260181,69.1513,35.2769,Salang,ADM2,DISTRICT
SM286232,17.8337,68.8767,Salangen,ADM2,DISTRICT
SM243561,102.819,35.5457,Salar,LANGUAGE,LANGUAGE
SM292461,22.0207,47.2315,Salard,ADM2,DISTRICT
SM243565,130.53,-3.1465,Salas,LANGUAGE,LANGUAGE
SM3994,25.7064,56.4822,Salas Novads,ADM1,DISTRICT
SM13792,23.6525,56.9181,Salas pagasts (Babites),ADM2,DISTRICT
SM18492,25.738,56.4465,Salas pagasts (Salas),ADM2,DISTRICT
SM257279,-105.36,-26.47,Salas y Gomez,ETHNICITY,ETHNICITY
SM277525,45.9833,34.8167,Salas-e Babajani,ADM2,DISTRICT
SM13793,24.3567,56.8661,Salaspils,ADM2,DISTRICT
SM4013,24.3506,56.8744,Salaspils Novads,ADM1,DISTRICT
SM13794,24.31,56.8933,Salaspils pagasts,ADM2,DISTRICT
SM290784,22.9742,45.5043,Salasu De Sus,ADM2,DISTRICT
SM291630,23.1559,47.3542,Salatig,ADM2,DISTRICT
SM276322,110.4833,-7.3328,Salatiga,ADM2,DISTRICT
SM292233,24.3892,45.2668,Salatrucel,ADM2,DISTRICT
SM291287,24.5252,45.291,Salatrucu,ADM2,DISTRICT
SM17065,58.1667,55.25,Salavatskiy Rayon,ADM2,DISTRICT
SM261052,-63.3,-28.9167,Salavina,ADM2,DISTRICT
SM257281,130.87,-1.11,Salawati,ETHNICITY,ETHNICITY
SM14098,-9.5675,7.4563,Salayea (excl. Gbarlien),ADM2,DISTRICT
SM270382,-72.8574,7.7825,Salazar de las Palmas,ADM2,DISTRICT
SM275218,-91.4618,14.8764,Salcajá,ADM2,DISTRICT
SM291839,26.3584,47.6451,Salcea,ADM2,DISTRICT
SM273611,-78.6,-1.0333,Salcedo,ADM2,DISTRICT
SM271825,-70.3833,19.4167,Salcedo,ADM2,DISTRICT
SM3051,-70.3333,19.4167,Salcedo,ADM1,DISTRICT
SM243575,47.3487,37.0392,Salchuq,LANGUAGE,LANGUAGE
SM291923,24.9266,43.9416,Salcia,ADM2,DISTRICT
SM291562,26.326,45.1832,Salcia,ADM2,DISTRICT
SM291101,22.9305,44.1412,Salcia,ADM2,DISTRICT
SM292689,27.5038,45.4026,Salcia Tudor,ADM2,DISTRICT
SM291563,26.4925,44.8165,Salciile,ADM2,DISTRICT
SM290928,26.9031,44.5376,Salcioara,ADM2,DISTRICT
SM289827,23.4153,46.3826,Salciua De Jos,ADM2,DISTRICT
SM295573,-89.7613,13.8311,Salcoatitán,ADM2,DISTRICT
SM290404,23.4545,44.2295,Salcuta,ADM2,DISTRICT
SM265866,-53.0958,-28.387,Saldanha Marinho,ADM2,DISTRICT
SM270582,-75.0347,3.8887,Saldaña,ADM2,DISTRICT
SM13795,22.4904,56.6644,Saldus,ADM2,DISTRICT
SM3964,22.5,56.6667,Saldus Municipality,ADM1,DISTRICT
SM13796,22.4492,56.7058,Saldus pagasts,ADM2,DISTRICT
SM302951,-80.0548,37.2935,Salem,ADM2,DISTRICT
SM301821,-75.3579,39.5766,Salem,ADM2,DISTRICT
SM296474,17.6988,59.2457,Salem,ADM2,DISTRICT
SM277227,78.29,11.69,Salem (1997-),ADM2,DISTRICT
SM243578,129.427,-2.8255,Saleman,LANGUAGE,LANGUAGE
SM270416,-75.5674,4.6364,Salento,ADM2,DISTRICT
SM277946,15.2667,40.45,Salerno,ADM2,DISTRICT
SM266885,-49.5185,-21.3385,Sales,ADM2,DISTRICT
SM266883,-47.8408,-20.8336,Sales Oliveira,ADM2,DISTRICT
SM266884,-45.8474,-23.5875,Salesópolis,ADM2,DISTRICT
SM266279,-49.9972,-26.9376,Salete,ADM2,DISTRICT
SM289750,35.0903,32.1182,Salfit,ADM1:ADM2,DISTRICT
SM274681,-2.3333,53.5,Salford,ADM1:ADM2,DISTRICT
SM264883,-35.5968,-7.9241,Salgadinho,ADM2,DISTRICT
SM264293,-36.8632,-7.0893,Salgadinho,ADM2,DISTRICT
SM267133,-37.4678,-11.0745,Salgado,ADM2,DISTRICT
SM264687,-53.4394,-26.121,Salgado Filho,ADM2,DISTRICT
SM264294,-35.4568,-7.4086,Salgado de São Félix,ADM2,DISTRICT
SM13797,23.9936,56.6118,Salgales pagasts,ADM2,DISTRICT
SM270257,-75.9875,5.9671,Salgar,ADM2,DISTRICT
SM264884,-39.0566,-8.0787,Salgueiro,ADM2,DISTRICT
SM276147,19.8155,48.1005,Salgótarján,ADM1:ADM2,DISTRICT
SM304990,44.0402,13.5839,Salh,ADM2,DISTRICT
SM305598,15.1634,43.9368,Sali,ADM2,DISTRICT
SM243581,150.727,-10.6148,Saliba,LANGUAGE,LANGUAGE
SM264885,-37.6595,-7.5912,Salidao,ADM2,DISTRICT
SM13798,26.8923,55.812,Salienas pagasts,ADM2,DISTRICT
SM16176,-66.5526,18.1801,Saliente Barrio,ADM2,DISTRICT
SM298726,28.1526,38.4925,Salihli,ADM2,DISTRICT
SM9810,27.4167,52.6667,Salihorski Rayon (excl. Salihorsk),ADM2,DISTRICT
SM3052,22,56.8333,Salima,ADM1:ADM2,DISTRICT
SM14375,-95.2152,16.1949,Salina Cruz,ADM2,DISTRICT
SM243579,-121.718,37.6646,Salinan,LANGUAGE,LANGUAGE
SM257282,-120.66,35.69,Salinan,ETHNICITY,ETHNICITY
SM257282,-120.66,35.69,Salinan,ETHNICITY,ETHNICITY
SM257282,-120.66,35.69,Salinan,ETHNICITY,ETHNICITY
SM257282,-120.66,35.69,Salinan,ETHNICITY,ETHNICITY
SM257282,-121,36,Salinan,ETHNICITY,ETHNICITY
SM257282,-121,36,Salinan,ETHNICITY,ETHNICITY
SM257282,-121,36,Salinan,ETHNICITY,ETHNICITY
SM257282,-121,36,Salinan,ETHNICITY,ETHNICITY
SM257282,-120.84,35.47,Salinan,ETHNICITY,ETHNICITY
SM257282,-120.84,35.47,Salinan,ETHNICITY,ETHNICITY
SM257282,-120.84,35.47,Salinan,ETHNICITY,ETHNICITY
SM257282,-120.84,35.47,Salinan,ETHNICITY,ETHNICITY
SM257282,-120,35,Salinan,ETHNICITY,ETHNICITY
SM257282,-120,35,Salinan,ETHNICITY,ETHNICITY
SM257282,-120,35,Salinan,ETHNICITY,ETHNICITY
SM257282,-120,35,Salinan,ETHNICITY,ETHNICITY
SM18359,-55.8499,-34.744,Salinas,ADM2,DISTRICT
SM282492,-101.682,22.7067,Salinas,ADM2,DISTRICT
SM273555,-80.9667,-2.2167,Salinas,ADM2,DISTRICT
SM263852,-42.174,-16.1185,Salinas,ADM2,DISTRICT
SM3053,-66.2538,18.0175,Salinas,ADM1,DISTRICT
SM16177,-66.2966,17.9792,Salinas Barrio-Pueblo,ADM2,DISTRICT
SM282189,-100.2774,26.069,Salinas Victoria,ADM2,DISTRICT
SM267626,-38.7804,-12.9067,Salinas da Margarida,ADM2,DISTRICT
SM301751,-97.1409,40.5241,Saline,ADM2,DISTRICT
SM301619,-93.2018,39.1368,Saline,ADM2,DISTRICT
SM300972,-97.6499,38.7838,Saline,ADM2,DISTRICT
SM300674,-88.5408,37.7532,Saline,ADM2,DISTRICT
SM302411,-92.6766,34.6466,Saline,ADM2,DISTRICT
SM257283,-106.22,27.43,Salineros-Cabezas,ETHNICITY,ETHNICITY
SM264085,-47.349,-0.6711,Salinópolis,ADM2,DISTRICT
SM261732,138.6083,-34.7683,Salisbury,ADM2,DISTRICT
SM257284,-123.73,49.72,Salish,ETHNICITY,ETHNICITY
SM291750,23.8953,45.7897,Saliste,ADM2,DISTRICT
SM289828,23.4129,45.9143,Salistea,ADM2,DISTRICT
SM291031,24.3534,47.6602,Salistea De Sus,ADM2,DISTRICT
SM267843,-40.2898,-7.138,Salitre,ADM2,DISTRICT
SM257285,-66.25,6.85,Saliva,ETHNICITY,ETHNICITY
SM247066,5.1107,10.2882,Salka-Tsishingini,LANGUAGE,LANGUAGE
SM296769,36.9195,32.5178,Salkhad,ADM2,DISTRICT
SM243586,6.291,52.4482,Sallands,DIALECT,LANGUAGE
SM260701,-63.0833,-36.6667,Salliqueló,ADM2,DISTRICT
SM277639,44.7333,38.1833,Salmas,ADM2,DISTRICT
SM266886,-50.8749,-21.5829,Salmourão,ADM2,DISTRICT
SM13799,27.5294,56.8129,Salnavas pagasts,ADM2,DISTRICT
SM346813,23.3222,60.4124,Salo,ADM2:ADM3,DISTRICT
SM292463,21.6642,46.8028,Salonta,ADM2,DISTRICT
SM257288,59.62,32.42,Salor,ETHNICITY,ETHNICITY
SM264886,-36.7616,-9.0649,Saloá,ADM2,DISTRICT
SM277995,35.7,32.0392,Salt,ADM2,DISTRICT
SM277995,35.7,32.0392,Salt,ADM2,DISTRICT
SM277995,35.7799,31.9979,Salt,ADM2,DISTRICT
SM277995,35.7799,31.9979,Salt,ADM2,DISTRICT
SM302902,-111.924,40.6676,Salt Lake,ADM2,DISTRICT
SM12703,-77.7118,18.4842,Salt Marsh,ADM2,DISTRICT
SM12704,-77.1741,17.8414,Salt River,ADM2,DISTRICT
SM12705,-77.8655,18.4869,Salt Spring,ADM2,DISTRICT
SM243589,145.021,-6.2652,Salt-Yui,LANGUAGE,LANGUAGE
SM3055,-65.4346,-24.7581,Salta,ADM1,DISTRICT
SM282772,-95.517,18.5692,Saltabarranca,ADM2,DISTRICT
SM286080,15.3946,67.1014,Saltdal,ADM2,DISTRICT
SM17635,-61.0098,13.8121,Saltibus,ADM2,DISTRICT
SM283301,-101.1318,25.2003,Saltillo,ADM2,DISTRICT
SM16178,-66.7207,18.1367,Saltillo Barrio,ADM2,DISTRICT
SM266887,-47.7267,-22.8627,Saltinho,ADM2,DISTRICT
SM266280,-53.0257,-26.5837,Saltinho,ADM2,DISTRICT
SM266891,-47.2901,-23.2043,Salto,ADM2,DISTRICT
SM260702,-60.25,-34.25,Salto,ADM2,DISTRICT
SM3056,-57,-31.4167,Salto,ADM1,DISTRICT
SM16179,-66.7094,18.2808,Salto Abajo Barrio,ADM2,DISTRICT
SM16180,-66.7293,18.2684,Salto Arriba Barrio,ADM2,DISTRICT
SM16181,-66.2251,18.1696,Salto Barrio (Cidro),ADM2,DISTRICT
SM16182,-67.0124,18.379,Salto Barrio (San Sebastián),ADM2,DISTRICT
SM9999,-53.1691,-29.0421,Salto Do Jacui (1997-),ADM2,DISTRICT
SM266890,-49.9619,-22.8736,Salto Grande,ADM2,DISTRICT
SM266281,-51.4147,-26.9082,Salto Veloso,ADM2,DISTRICT
SM263853,-40.0446,-16.0859,Salto da Divisa,ADM2,DISTRICT
SM283186,-92.2064,17.42,Salto de Agua,ADM2,DISTRICT
SM263104,-57.959,-15.1344,Salto do Céu,ADM2,DISTRICT
SM264689,-49.6822,-23.6194,Salto do Itararé,ADM2,DISTRICT
SM264690,-53.2998,-25.7704,Salto do Londra,ADM2,DISTRICT
SM266889,-47.607,-23.6575,Salto do Pirapora,ADM2,DISTRICT
SM16183,-66.4128,18.2022,Saltos Barrio,ADM2,DISTRICT
SM15363,-54.4666,-24.1863,Saltos Del Guaira (1999-),ADM2,DISTRICT
SM3057,20.1167,60.3333,Saltvik,ADM1:ADM2,DISTRICT
SM243593,121.3,-2.748,Saluan,LANGUAGE,LANGUAGE
SM257289,121.75,-1.13,Saluan,ETHNICITY,ETHNICITY
SM302467,-81.7269,34.0061,Saluda,ADM2,DISTRICT
SM243594,-56.5107,1.7478,Salumá,LANGUAGE,LANGUAGE
SM267627,-38.5081,-12.9718,Salvador,ADM2,DISTRICT
SM282539,-108.0765,25.4306,Salvador Alvarado,ADM2,DISTRICT
SM10000,-51.5141,-29.4801,Salvador Do Sul (1996-),ADM2,DISTRICT
SM282074,-101.6962,19.3733,Salvador Escalante,ADM2,DISTRICT
SM265868,-54.8288,-28.077,Salvador das Missões,ADM2,DISTRICT
SM243595,-89.5988,13.7024,Salvadoran Sign Language,LANGUAGE,LANGUAGE
SM271775,-68.6667,18.7,Salvaleón de Higüey,ADM2,DISTRICT
SM264086,-48.6195,-0.7885,Salvaterra,ADM2,DISTRICT
SM289325,-8.689,39.0448,Salvaterra de Magos,ADM2,DISTRICT
SM281554,-100.9124,20.2109,Salvatierra,ADM2,DISTRICT
SM375350,82.1732,28.3981,Salyan,ADM2:ADM3,DISTRICT
SM261900,49,39.6667,Salyan,ADM1:ADM2,DISTRICT
SM261848,13.0453,47.8007,Salzburg,ADM2,DISTRICT
SM3058,13.25,47.4167,Salzburg,ADM1,DISTRICT
SM261847,13.188,47.8402,Salzburg Umgebung,ADM2,DISTRICT
SM281421,-6.64,33.99,Salé,ADM2,DISTRICT
SM295280,-12.7762,12.5997,Salémata,ADM2,DISTRICT
SM298851,36.8172,41.0935,Salıpazarı,ADM2,DISTRICT
SM243645,145.813,-5.5557,Sam,LANGUAGE,LANGUAGE
SM297615,100.0648,14.756,Sam Chuk,ADM2,DISTRICT
SM297286,100.5334,14.0794,Sam Khok,ADM2,DISTRICT
SM297366,100.1416,16.4383,Sam Ngam,ADM2,DISTRICT
SM297660,98.8756,17.3132,Sam Ngao,ADM2,DISTRICT
SM297099,100.2107,13.7204,Sam Phran,ADM2,DISTRICT
SM243607,13.8713,-9.8693,Sama,LANGUAGE,LANGUAGE
SM304991,44.1166,13.3809,Sama,ADM2,DISTRICT
SM257291,120.06,5.19,Sama,ETHNICITY,ETHNICITY
SM257291,122.07,6.54,Sama,ETHNICITY,ETHNICITY
SM8441,124,-6,Sama-Bajau,ETHNICITY,ETHNICITY
SM8441,117,-6,Sama-Bajau,ETHNICITY,ETHNICITY
SM8441,117,7,Sama-Bajau,ETHNICITY,ETHNICITY
SM8441,124,7,Sama-Bajau,ETHNICITY,ETHNICITY
SM270816,-73.5501,5.4474,Samacá,ADM2,DISTRICT
SM282998,-89.914,20.8643,Samahil,ADM2,DISTRICT
SM280757,106.7816,14.8054,Samakkhixay,ADM2,DISTRICT
SM257294,132.54,-1.99,Samalek,ETHNICITY,ETHNICITY
SM273662,30.6667,28.2833,Samalut,ADM2,DISTRICT
SM11805,50.9138,32.4498,Saman,ADM2,DISTRICT
SM10455,-74.9935,5.5406,Samana (1999-),ADM2,DISTRICT
SM298457,35.9723,36.0842,Samandağ,ADM2,DISTRICT
SM3060,67.6667,36,Samangan,ADM1,DISTRICT
SM278172,143.0471,42.1545,Samani,ADM2,DISTRICT
SM270342,-77.6667,1.4167,Samaniego,ADM2,DISTRICT
SM273924,31.2101,30.9111,Samannud,ADM2,DISTRICT
SM3059,-69.45,19.25,Samaná,ADM1,DISTRICT
SM298006,11.012,32.7149,Samar,ADM2,DISTRICT
SM3061,125,11.8333,Samar,ADM1:ADM2,DISTRICT
SM3062,50,53,Samara,ADM1,DISTRICT
SM284366,110.75,1.25,Samarahan,ADM2,DISTRICT
SM288579,150.547,-10.4964,Samarai-Murua,ADM2,DISTRICT
SM276421,117.1833,-0.4333,Samarinda,ADM2,DISTRICT
SM290653,23.0489,44.7715,Samarinesti,ADM2,DISTRICT
SM243612,35.4487,32.0817,Samaritan,LANGUAGE,LANGUAGE
SM243613,35.258,32.3107,Samaritan Aramaic,DIALECT,LANGUAGE
SM3063,66.4333,39.8,Samarkand (1973-),ADM1,DISTRICT
SM243599,138.34,-1.8488,Samarokena,LANGUAGE,LANGUAGE
SM18442,66.9597,39.6542,Samarqand Shahri,ADM2,DISTRICT
SM18443,66.9167,39.5833,Samarqand Tumani,ADM2,DISTRICT
SM13244,83.5,49.1667,Samarskoe Audany,ADM2,DISTRICT
SM295391,158.9323,-7.933,Samasodu,ADM2,DISTRICT
SM277427,85.9167,25.75,Samastipur,ADM2,DISTRICT
SM243602,102.2,25.012,Samatao,LANGUAGE,LANGUAGE
SM243603,98.825,23.76,Samatu,LANGUAGE,LANGUAGE
SM275368,-91.4707,14.5744,Samayac,ADM2,DISTRICT
SM276887,75.1093,32.5752,Samba,ADM2,DISTRICT
SM260328,13.1607,-9.0688,Samba,ADM2,DISTRICT
SM260429,15.5156,-8.8117,Samba Cajú,ADM2,DISTRICT
SM243625,11.7077,8.0691,Samba Daka,LANGUAGE,LANGUAGE
SM250662,11,8,Samba Daka,ETHNICITY,ETHNICITY
SM243619,11.7366,8.1924,Samba Leko,LANGUAGE,LANGUAGE
SM257303,11,8,Samba Leko,ETHNICITY,ETHNICITY
SM250675,39.26,-3.41,Sambaa,ETHNICITY,ETHNICITY
SM250675,39.26,-3.41,Sambaa,ETHNICITY,ETHNICITY
SM250675,39.26,-3.41,Sambaa,ETHNICITY,ETHNICITY
SM250675,38,-5,Sambaa,ETHNICITY,ETHNICITY
SM250675,38,-5,Sambaa,ETHNICITY,ETHNICITY
SM250675,38,-5,Sambaa,ETHNICITY,ETHNICITY
SM250675,38.3,-4.5,Sambaa,ETHNICITY,ETHNICITY
SM250675,38.3,-4.5,Sambaa,ETHNICITY,ETHNICITY
SM250675,38.3,-4.5,Sambaa,ETHNICITY,ETHNICITY
SM257304,120.89,14.91,Sambal,ETHNICITY,ETHNICITY
SM257304,120.89,14.91,Sambal,ETHNICITY,ETHNICITY
SM257304,120.86,15.52,Sambal,ETHNICITY,ETHNICITY
SM257304,120.86,15.52,Sambal,ETHNICITY,ETHNICITY
SM277127,83.8833,21.4,Sambalpur (1993-),ADM2,DISTRICT
SM243639,83.9315,21.2233,Sambalpuri,LANGUAGE,LANGUAGE
SM276378,109.3333,1.4167,Sambas,ADM2,DISTRICT
SM257306,111.88,1.02,Sambas,ETHNICITY,ETHNICITY
SM292464,22.2049,46.7982,Sambata,ADM2,DISTRICT
SM370965,49.8467,-14.2172,Sambava,ADM2:ADM3,DISTRICT
SM262871,-45.6775,-7.6149,Sambaíba,ADM2,DISTRICT
SM243621,8.5056,9.1607,Sambe,LANGUAGE,LANGUAGE
SM243635,143.951,-6.7028,Samberigi,LANGUAGE,LANGUAGE
SM277328,78.5697,28.5839,Sambhal,ADM2,DISTRICT
SM257307,145.68,-7.18,Sambia,ETHNICITY,ETHNICITY
SM257308,-48.52,-7.68,Sambioa,ETHNICITY,ETHNICITY
SM299535,23.2065,49.5174,Sambirs'ka,ADM2,DISTRICT
SM273444,-79.7333,-1.95,Samborondón,ADM2,DISTRICT
SM290654,23.324,45.1588,Sambotin,ADM2,DISTRICT
SM280361,106.0926,12.9826,Sambour,ADM2,DISTRICT
SM13066,140.4535,35.6884,Sambu-gun,ADM2,DISTRICT
SM291453,24.3992,44.8113,Samburesti,ADM2,DISTRICT
SM243629,37.0539,1.6074,Samburu,LANGUAGE,LANGUAGE
SM3064,37.1167,1.3333,Samburu,ADM1:ADM2,DISTRICT
SM250663,37.4,1.55,Samburu,ETHNICITY,ETHNICITY
SM250663,37.4,1.55,Samburu,ETHNICITY,ETHNICITY
SM250663,37.4,1.55,Samburu,ETHNICITY,ETHNICITY
SM250663,37,1,Samburu,ETHNICITY,ETHNICITY
SM250663,37,1,Samburu,ETHNICITY,ETHNICITY
SM250663,37,1,Samburu,ETHNICITY,ETHNICITY
SM250663,37,1.5,Samburu,ETHNICITY,ETHNICITY
SM250663,37,1.5,Samburu,ETHNICITY,ETHNICITY
SM250663,37,1.5,Samburu,ETHNICITY,ETHNICITY
SM286705,-78.1101,7.8201,Sambú,ADM2,DISTRICT
SM289152,125.2944,38.3406,Samch'ŏn,ADM2,DISTRICT
SM280684,129.1663,37.4501,Samcheok,ADM2,DISTRICT
SM3065,91.6167,26.9167,Samdrupjongkhar,ADM1,DISTRICT
SM297808,125.65,-9,Same,ADM2,DISTRICT
SM299254,37.8828,-4.222,Same,ADM2,DISTRICT
SM280482,104.5555,11.8679,Sameakki Mean Chey,ADM2,DISTRICT
SM3066,42.215,42.7008,Samegrelo-Zemo Svaneti,ADM1,DISTRICT
SM243640,99.9217,24.7859,Samei,LANGUAGE,LANGUAGE
SM18167,29.6431,-11.4538,Samfya District (2012-),ADM2,DISTRICT
SM275056,-14.5833,13.5833,Sami,ADM2,DISTRICT
SM250948,34.12,0.31,Samia,ETHNICITY,ETHNICITY
SM257310,-122.23,48.37,Samish,ETHNICITY,ETHNICITY
SM17811,42.9667,16.6333,Samitah,ADM2,DISTRICT
SM289093,128.25,41.8333,Samjiyon,ADM2,DISTRICT
SM297026,100.254,14.5998,Samko,ADM2,DISTRICT
SM280414,102.86,12.5829,Samlout,ADM2,DISTRICT
SM286395,5.7622,60.3775,Samnanger,ADM2,DISTRICT
SM243650,142.195,-6.155,Samo,LANGUAGE,LANGUAGE
SM257312,141.57,-6.48,Samo,ETHNICITY,ETHNICITY
SM250475,-3,13,Samo,ETHNICITY,ETHNICITY
SM243651,-171.83,-13.92,Samoan,LANGUAGE,LANGUAGE
SM257313,-172.41,-13.7,Samoan,ETHNICITY,ETHNICITY
SM257313,-172.41,-13.7,Samoan,ETHNICITY,ETHNICITY
SM257313,-172.41,-13.7,Samoan,ETHNICITY,ETHNICITY
SM257313,-171.975,-13.9,Samoan,ETHNICITY,ETHNICITY
SM257313,-171.975,-13.9,Samoan,ETHNICITY,ETHNICITY
SM257313,-171.975,-13.9,Samoan,ETHNICITY,ETHNICITY
SM257313,-171.77,-13.94,Samoan,ETHNICITY,ETHNICITY
SM257313,-171.77,-13.94,Samoan,ETHNICITY,ETHNICITY
SM257313,-171.77,-13.94,Samoan,ETHNICITY,ETHNICITY
SM257314,-172.41,-13.68,Samoan (American),ETHNICITY,ETHNICITY
SM276031,15.7,45.8,Samobor,ADM2,DISTRICT
SM296882,98.6565,18.9003,Samoeng,ADM2,DISTRICT
SM262426,23.55,42.3333,Samokov,ADM2,DISTRICT
SM243652,145.537,-5.0139,Samosa,LANGUAGE,LANGUAGE
SM276692,98.7153,2.6403,Samosir,ADM2,DISTRICT
SM257316,84.54,73.05,Samoyed,ETHNICITY,ETHNICITY
SM267166,-47.919,-5.3659,Sampaio,ADM2,DISTRICT
SM243657,87.0019,27.2999,Sampang,LANGUAGE,LANGUAGE
SM276363,113.25,-7.05,Sampang,ADM2,DISTRICT
SM280767,102.41,21.57,Samphanh,ADM2,DISTRICT
SM297178,100.5077,13.7423,Samphantawong,ADM2,DISTRICT
SM257318,-110.83,38.38,Sampit,ETHNICITY,ETHNICITY
SM280385,103.9182,12.4706,Sampov Meas,ADM2,DISTRICT
SM302011,-78.3715,34.9916,Sampson,ADM2,DISTRICT
SM294583,41.6667,52.25,Sampurskiy rayon,ADM2,DISTRICT
SM270532,-75.3458,9.1542,Sampués,ADM2,DISTRICT
SM280376,103.6274,14.2504,Samraong,ADM2,DISTRICT
SM280457,104.75,11.1667,Samraong,ADM2,DISTRICT
SM280490,104.5981,11.481,Samraong Tong,ADM2,DISTRICT
SM257319,103.57,13.68,Samre,ETHNICITY,ETHNICITY
SM243662,103.978,13.479,Samre of Siem Reap,LANGUAGE,LANGUAGE
SM297698,104.7914,14.9853,Samrong,ADM2,DISTRICT
SM297641,103.9409,15.0398,Samrong Thap,ADM2,DISTRICT
SM298231,38.4895,37.5857,Samsat,ADM2,DISTRICT
SM15272,125.9114,39.0994,Samsok-kuyok,ADM2,DISTRICT
SM289083,127.9764,41.2308,Samsu,ADM2,DISTRICT
SM291631,22.9537,47.3323,Samsud,ADM2,DISTRICT
SM3067,36.3333,41.25,Samsun,ADM1,DISTRICT
SM271693,10.6005,55.8525,Samsø,ADM2,DISTRICT
SM243663,99.2576,22.0185,Samtao,LANGUAGE,LANGUAGE
SM274802,42.35,42.1667,Samtredia,ADM2,DISTRICT
SM3068,89.0833,27.0333,Samtse,ADM1,DISTRICT
SM3069,43.2667,41.5833,Samtskhe-Javakheti,ADM1,DISTRICT
SM248317,-5.3752,10.7283,Samue,LANGUAGE,LANGUAGE
SM303745,-71.8317,8.5076,Samuel Dario Maldonado,ADM2,DISTRICT
SM12706,-77.5144,18.451,Samuels Prospect,ADM2,DISTRICT
SM262361,26.8,43.6,Samuil,ADM2,DISTRICT
SM278544,139.3856,35.3755,Samukawa,ADM2,DISTRICT
SM280771,106.8748,16.3587,Samuoi,ADM2,DISTRICT
SM3070,100.7053,13.6044,Samut Prakan,ADM1,DISTRICT
SM3071,100.2167,13.5667,Samut Sakhon,ADM1,DISTRICT
SM3072,99.9583,13.4,Samut Songkhram,ADM1,DISTRICT
SM261927,46.4167,40.9167,Samux,ADM1:ADM2,DISTRICT
SM243667,74.9104,18.3068,Samvedi,LANGUAGE,LANGUAGE
SM283376,-5.0162,13.179,San,ADM2,DISTRICT
SM257321,13.82,-14.45,San,ETHNICITY,ETHNICITY
SM257321,13.82,-14.45,San,ETHNICITY,ETHNICITY
SM257321,13.82,-14.45,San,ETHNICITY,ETHNICITY
SM257321,15.49,-15.71,San,ETHNICITY,ETHNICITY
SM257321,15.49,-15.71,San,ETHNICITY,ETHNICITY
SM257321,15.49,-15.71,San,ETHNICITY,ETHNICITY
SM257321,21.4524,-18.9373,San,ETHNICITY,ETHNICITY
SM257321,21.4524,-18.9373,San,ETHNICITY,ETHNICITY
SM257321,21.4524,-18.9373,San,ETHNICITY,ETHNICITY
SM257321,20,-21,San,ETHNICITY,ETHNICITY
SM257321,20,-21,San,ETHNICITY,ETHNICITY
SM257321,20,-21,San,ETHNICITY,ETHNICITY
SM14376,-96.7893,16.5154,San Agustin Amatengo,ADM2,DISTRICT
SM14377,-97.9989,17.5989,San Agustin Atenango,ADM2,DISTRICT
SM14378,-97.8264,16.4421,San Agustin Chayuco,ADM2,DISTRICT
SM14379,-96.7583,17.1866,San Agustin Etla,ADM2,DISTRICT
SM14380,-96.6217,15.9793,San Agustin Loxicha,ADM2,DISTRICT
SM14381,-97.5136,17.2093,San Agustin Tlacotepec,ADM2,DISTRICT
SM14382,-96.6785,17.0864,San Agustin Yatareni,ADM2,DISTRICT
SM14383,-96.7048,17.0073,San Agustin de las Juntas,ADM2,DISTRICT
SM275864,-88.9303,14.8197,San Agustín,ADM2,DISTRICT
SM295580,-88.5898,13.4562,San Agustín,ADM2,DISTRICT
SM270100,-76.4157,1.9243,San Agustín,ADM2,DISTRICT
SM275419,-89.9893,15.015,San Agustín Acasaguastlán,ADM2,DISTRICT
SM281700,-98.6248,20.5032,San Agustín Metzquititlán,ADM2,DISTRICT
SM243678,-96.3362,16.2202,San Agustín Mixtepec Zapotec,LANGUAGE,LANGUAGE
SM243676,-97.5185,17.2037,San Agustín Tlacotepec,DIALECT,LANGUAGE
SM281701,-98.9091,20.1079,San Agustín Tlaxiaca,ADM2,DISTRICT
SM289659,-54.9062,-24.976,San Alberto,ADM2,DISTRICT
SM269900,-73.4582,7.8144,San Alberto,ADM2,DISTRICT
SM261131,-65.25,-31.75,San Alberto,ADM2,DISTRICT
SM295705,-87.9117,13.4525,San Alejo,ADM2,DISTRICT
SM14384,-97.757,16.8598,San Andres Cabecera Nueva,ADM2,DISTRICT
SM243683,-81.7043,12.5535,San Andres Creole English,LANGUAGE,LANGUAGE
SM14385,-97.6943,17.6963,San Andres Dinicuiti,ADM2,DISTRICT
SM14386,-92.7961,17.1565,San Andres Duraznal,ADM2,DISTRICT
SM14387,-97.9053,16.3308,San Andres Huaxpaltepec,ADM2,DISTRICT
SM14388,-96.6683,17.0998,San Andres Huayapam,ADM2,DISTRICT
SM14389,-96.8414,17.0698,San Andres Ixtlahuaca,ADM2,DISTRICT
SM14390,-97.5228,17.5734,San Andres Lagunas,ADM2,DISTRICT
SM14391,-97.0685,17.2349,San Andres Nuxino,ADM2,DISTRICT
SM14392,-96.5254,16.2094,San Andres Paxtlan,ADM2,DISTRICT
SM14393,-97.2733,17.4854,San Andres Sinaxtla,ADM2,DISTRICT
SM14394,-96.2267,17.2704,San Andres Solaga,ADM2,DISTRICT
SM10456,-75.5163,9.1218,San Andres Sotavento (2007-),ADM2,DISTRICT
SM14395,-96.618,17.9468,San Andres Teotilalpam,ADM2,DISTRICT
SM14396,-98.3894,17.6629,San Andres Tepetlapa,ADM2,DISTRICT
SM14397,-96.1488,17.2938,San Andres Yaa,ADM2,DISTRICT
SM14398,-96.8589,16.5992,San Andres Zabache,ADM2,DISTRICT
SM14399,-96.8573,17.1896,San Andres Zautla,ADM2,DISTRICT
SM275705,-88.5498,14.2197,San Andrés,ADM2,DISTRICT
SM275206,-90.3988,17.4954,San Andrés,ADM2,DISTRICT
SM270440,-81.7175,12.5431,San Andrés,ADM2,DISTRICT
SM282254,-98.3064,19.0217,San Andrés Cholula,ADM2,DISTRICT
SM275404,-90.8691,14.6101,San Andrés Itzapa,ADM2,DISTRICT
SM275239,-90.9302,15.2253,San Andrés Sajcabajá,ADM2,DISTRICT
SM275338,-91.1047,14.7439,San Andrés Semetabaj,ADM2,DISTRICT
SM282773,-97.0858,18.7739,San Andrés Tenejapan,ADM2,DISTRICT
SM282775,-95.2184,18.4506,San Andrés Tuxtla,ADM2,DISTRICT
SM275259,-91.6405,14.3388,San Andrés Villa Seca,ADM2,DISTRICT
SM275372,-91.4962,14.9078,San Andrés Xecul,ADM2,DISTRICT
SM270505,-72.8341,6.7838,San Andrés de Cuerquia,ADM2,DISTRICT
SM270258,-75.6667,6.9167,San Andrés de Cuerquia,ADM2,DISTRICT
SM260703,-59.5,-34.5,San Andrés de Giles,ADM2,DISTRICT
SM3073,-81.7198,12.5245,San Andrés y Providencia,ADM1,DISTRICT
SM233512,-76.6593,0.8887,San Andrés-Lowland Inga,DIALECT,LANGUAGE
SM269941,-75.786,9.3614,San Antero,ADM2,DISTRICT
SM16184,-65.9904,18.3886,San Anton Barrio (Carolina),ADM2,DISTRICT
SM16185,-66.6015,18.0056,San Anton Barrio (Ponce),ADM2,DISTRICT
SM14400,-96.6927,16.809,San Antonino Castillo Velasco,ADM2,DISTRICT
SM14401,-97.7265,17.5187,San Antonino Monte Verde,ADM2,DISTRICT
SM14402,-97.0272,16.8198,San Antonino el Alto,ADM2,DISTRICT
SM289720,-57.562,-25.4183,San Antonio,ADM2,DISTRICT
SM282493,-98.8712,21.618,San Antonio,ADM2,DISTRICT
SM275865,-88.88,15.04,San Antonio,ADM2,DISTRICT
SM275659,-88.4649,13.9504,San Antonio,ADM2,DISTRICT
SM295748,-88.2833,13.85,San Antonio,ADM2,DISTRICT
SM270583,-75.5,3.9566,San Antonio,ADM2,DISTRICT
SM269093,-71.5,-33.5833,San Antonio,ADM2,DISTRICT
SM260933,-65.5,-41,San Antonio,ADM2,DISTRICT
SM260838,-65.5,-24.3333,San Antonio,ADM2,DISTRICT
SM14403,-97.498,17.7446,San Antonio Acutla,ADM2,DISTRICT
SM275277,-90.778,14.5456,San Antonio Aguas Calientes,ADM2,DISTRICT
SM16186,-66.0369,18.3009,San Antonio Barrio (Caguas),ADM2,DISTRICT
SM16187,-66.9194,18.4276,San Antonio Barrio (Quebradillas),ADM2,DISTRICT
SM282255,-97.2778,18.4924,San Antonio Cañada,ADM2,DISTRICT
SM275478,-91.787,15.6439,San Antonio Huista,ADM2,DISTRICT
SM14404,-97.135,16.9214,San Antonio Huitepec,ADM2,DISTRICT
SM275240,-91.2087,15.049,San Antonio Ilotenango,ADM2,DISTRICT
SM281957,-99.554,19.1732,San Antonio La Isla,ADM2,DISTRICT
SM275420,-90.2709,14.7449,San Antonio La Paz,ADM2,DISTRICT
SM295620,-88.898,14.0127,San Antonio Los Ranchos,ADM2,DISTRICT
SM295697,-89.0234,13.5749,San Antonio Masahuat,ADM2,DISTRICT
SM14405,-97.1318,18.1432,San Antonio Nanahuatipam,ADM2,DISTRICT
SM295544,-89.5543,14.1877,San Antonio Pajonal,ADM2,DISTRICT
SM275339,-91.105,14.6652,San Antonio Palopó,ADM2,DISTRICT
SM275287,-91.7043,14.9587,San Antonio Sacatepéquez,ADM2,DISTRICT
SM14406,-97.5773,17.1532,San Antonio Sinicahua,ADM2,DISTRICT
SM275369,-91.4044,14.5184,San Antonio Suchitepéquez,ADM2,DISTRICT
SM14407,-98.0662,16.5322,San Antonio Tepetlapa,ADM2,DISTRICT
SM260704,-59.469,-34.2424,San Antonio de Areco,ADM2,DISTRICT
SM275892,-88.0318,15.1301,San Antonio de Cortés,ADM2,DISTRICT
SM275896,-86.8423,13.7109,San Antonio de Flores,ADM2,DISTRICT
SM275816,-87.3408,13.6415,San Antonio de Flores,ADM2,DISTRICT
SM275922,-86.9895,14.0386,San Antonio de Oriente,ADM2,DISTRICT
SM270533,-75.5633,9.3367,San Antonio de Palmito,ADM2,DISTRICT
SM275114,5.6317,-1.4081,San Antonio de Palé,ADM2,DISTRICT
SM286823,-69.6156,-14.7118,San Antonio de Putina,ADM2,DISTRICT
SM14408,-96.6971,17.0309,San Antonio de la Cal,ADM2,DISTRICT
SM295619,-88.7833,14.0167,San Antonio de la Cruz,ADM2,DISTRICT
SM271146,-82.4966,22.8621,San Antonio de los Baños,ADM2,DISTRICT
SM295559,-89.7559,13.7129,San Antonio del Monte,ADM2,DISTRICT
SM275681,-87.72,13.88,San Antonio del Norte,ADM2,DISTRICT
SM271123,-74.8075,20.0556,San Antonio del Sur,ADM2,DISTRICT
SM270044,-74.35,4.6167,San Antonio del Tequendama,ADM2,DISTRICT
SM263854,-43.2628,-18.4917,San Antonio do Itambe,ADM2,DISTRICT
SM263855,-43.253,-19.2505,San Antonio do Rio Abai,ADM2,DISTRICT
SM302755,-94.1682,31.3942,San Augustine,ADM2,DISTRICT
SM14409,-96.4865,16.7649,San Baltazar Chichicapam,ADM2,DISTRICT
SM14410,-96.797,16.0143,San Baltazar Loxicha,ADM2,DISTRICT
SM243686,-96.762,16.0409,San Baltazar Loxicha Zapotec,LANGUAGE,LANGUAGE
SM14411,-96.2205,17.2296,San Baltazar Yatzachi el Bajo,ADM2,DISTRICT
SM275373,-91.4577,15.1149,San Bartolo,ADM2,DISTRICT
SM14412,-96.7098,16.9488,San Bartolo Coyotepec,ADM2,DISTRICT
SM14413,-97.3062,17.5856,San Bartolo Soyaltepec,ADM2,DISTRICT
SM281702,-98.1993,20.4518,San Bartolo Tutotepec,ADM2,DISTRICT
SM14414,-95.9449,16.4322,San Bartolo Yautepec,ADM2,DISTRICT
SM14415,-96.6744,18.0376,San Bartolome Ayautla,ADM2,DISTRICT
SM14416,-96.7464,15.9397,San Bartolome Loxicha,ADM2,DISTRICT
SM14417,-96.4999,16.9009,San Bartolome Quialana,ADM2,DISTRICT
SM14418,-97.4519,17.2308,San Bartolome Yucuane,ADM2,DISTRICT
SM14419,-96.2417,17.2386,San Bartolome Zoogocho,ADM2,DISTRICT
SM275241,-91.0336,15.1836,San Bartolomé Jocotenango,ADM2,DISTRICT
SM275278,-90.6837,14.5996,San Bartolomé Milpas Altas,ADM2,DISTRICT
SM295653,-89.0575,13.7842,San Bartolomé Perulapía,ADM2,DISTRICT
SM303191,-121.075,36.6057,San Benito,ADM2,DISTRICT
SM275207,-89.9098,16.9129,San Benito,ADM2,DISTRICT
SM270506,-73.5375,6.1021,San Benito,ADM2,DISTRICT
SM270534,-74.9567,8.7883,San Benito Abad,ADM2,DISTRICT
SM303192,-116.1785,34.8414,San Bernardino,ADM2,DISTRICT
SM289534,-57.2933,-25.2931,San Bernardino,ADM2,DISTRICT
SM275370,-91.456,14.5308,San Bernardino,ADM2,DISTRICT
SM269943,-75.4424,8.7972,San Bernardino de Sahagún,ADM2,DISTRICT
SM281513,-105.6625,26.1699,San Bernardo,ADM2,DISTRICT
SM270343,-77.038,1.5386,San Bernardo,ADM2,DISTRICT
SM270045,-74.359,4.1296,San Bernardo,ADM2,DISTRICT
SM14420,-96.9057,16.8432,San Bernardo Mixtepec,ADM2,DISTRICT
SM269944,-75.9695,9.335,San Bernardo del Viento,ADM2,DISTRICT
SM282135,-105.3233,21.5763,San Blas,ADM2,DISTRICT
SM14421,-95.1638,16.3464,San Blas Atempa,ADM2,DISTRICT
SM243687,-78.3075,9.1569,San Blas Kuna,LANGUAGE,LANGUAGE
SM260869,-67.0833,-28.5,San Blas de los Sauces,ADM2,DISTRICT
SM283302,-101.6938,27.344,San Buenaventura,ADM2,DISTRICT
SM275923,-87.1855,13.891,San Buenaventura,ADM2,DISTRICT
SM295581,-88.3972,13.5793,San Buenaventura,ADM2,DISTRICT
SM270384,-73.166,8.4418,San Calixto,ADM2,DISTRICT
SM18360,-54.9041,-34.651,San Carlos,ADM2,DISTRICT
SM286657,-80.0691,8.532,San Carlos,ADM2,DISTRICT
SM289524,-57.2978,-22.393,San Carlos,ADM2,DISTRICT
SM282664,-98.989,24.4029,San Carlos,ADM2,DISTRICT
SM295726,-88.1133,13.6291,San Carlos,ADM2,DISTRICT
SM270900,-84.402,10.5865,San Carlos,ADM2,DISTRICT
SM269945,-75.6896,8.7104,San Carlos,ADM2,DISTRICT
SM270259,-74.9049,6.2078,San Carlos,ADM2,DISTRICT
SM260958,-66.0833,-25.75,San Carlos,ADM2,DISTRICT
SM260884,-69,-34,San Carlos,ADM2,DISTRICT
SM275177,-90.077,14.4801,San Carlos Alzatate,ADM2,DISTRICT
SM275219,-91.5635,15.0505,San Carlos Sija,ADM2,DISTRICT
SM14422,-96.0906,16.4207,San Carlos Yautepec,ADM2,DISTRICT
SM270286,-73.2179,3.7984,San Carlos de Guaroa,ADM2,DISTRICT
SM303825,-66.9167,10,San Casimiro,ADM2,DISTRICT
SM270385,-72.6101,7.8477,San Cayetano,ADM2,DISTRICT
SM270046,-74.0714,5.3169,San Cayetano,ADM2,DISTRICT
SM260705,-59.6667,-38.4167,San Cayetano,ADM2,DISTRICT
SM295536,-88.8154,13.6529,San Cayetano Istepeque,ADM2,DISTRICT
SM282494,-99.8896,21.6,San Ciro de Acosta,ADM2,DISTRICT
SM261158,-58.5,-27.3333,San Cosme,ADM2,DISTRICT
SM289578,-56.5042,-27.3549,San Cosme y Damián,ADM2,DISTRICT
SM10457,-75.0734,10.3696,San Cristobal,ADM2,DISTRICT
SM257323,161.46,-10.28,San Cristobal,ETHNICITY,ETHNICITY
SM257323,161.46,-10.28,San Cristobal,ETHNICITY,ETHNICITY
SM257323,162,-11,San Cristobal,ETHNICITY,ETHNICITY
SM257323,162,-11,San Cristobal,ETHNICITY,ETHNICITY
SM14423,-96.383,16.3105,San Cristobal Amatlan,ADM2,DISTRICT
SM14424,-97.5789,17.2865,San Cristobal Amoltepec,ADM2,DISTRICT
SM275288,-91.7678,14.8898,San Cristobal Cucho,ADM2,DISTRICT
SM14425,-96.1644,17.3386,San Cristobal Lachirioag,ADM2,DISTRICT
SM14426,-97.3704,17.7281,San Cristobal Suchixtlahuaca,ADM2,DISTRICT
SM303746,-72.1667,7.7167,San Cristóbal,ADM2,DISTRICT
SM289660,-55.3848,-25.7835,San Cristóbal,ADM2,DISTRICT
SM273637,-89.5576,-0.9223,San Cristóbal,ADM2,DISTRICT
SM295654,-88.8833,13.7,San Cristóbal,ADM2,DISTRICT
SM271836,-70.1333,18.4167,San Cristóbal,ADM2,DISTRICT
SM271025,-83.0456,22.7145,San Cristóbal,ADM2,DISTRICT
SM261021,-61.3333,-30.3333,San Cristóbal,ADM2,DISTRICT
SM3074,-70.2,18.55,San Cristóbal,ADM1,DISTRICT
SM275421,-89.8862,14.9961,San Cristóbal Acasaguastlán,ADM2,DISTRICT
SM243689,-97.5706,17.2846,San Cristóbal Amoltepec Mixtec,DIALECT,LANGUAGE
SM275374,-91.4747,14.9281,San Cristóbal Totonicapán,ADM2,DISTRICT
SM275171,-90.5597,15.3964,San Cristóbal Verapaz,ADM2,DISTRICT
SM281836,-103.4591,21.056,San Cristóbal de la Barranca,ADM2,DISTRICT
SM283187,-92.6,16.7,San Cristóbal de las Casas,ADM2,DISTRICT
SM14427,-98.2967,19.2821,San Damian Texoloc,ADM2,DISTRICT
SM303868,-67.9541,10.2561,San Diego,ADM2,DISTRICT
SM303193,-116.7702,33.0282,San Diego,ADM2,DISTRICT
SM275386,-89.7555,14.794,San Diego,ADM2,DISTRICT
SM269901,-73.2735,10.2324,San Diego,ADM2,DISTRICT
SM281837,-102.0334,20.9724,San Diego de Alejandría,ADM2,DISTRICT
SM281555,-100.8078,21.3997,San Diego de la Unión,ADM2,DISTRICT
SM282256,-98.3163,18.7921,San Diego la Mesa Tochimiltzingo,ADM2,DISTRICT
SM281514,-105.75,24.2,San Dimas,ADM2,DISTRICT
SM285462,-85.8515,12.7631,San Dionisio,ADM2,DISTRICT
SM295582,-88.4705,13.2376,San Dionisio,ADM2,DISTRICT
SM14428,-96.3697,16.7698,San Dionisio Ocotepec,ADM2,DISTRICT
SM14429,-96.6651,16.7449,San Dionisio Ocotlan,ADM2,DISTRICT
SM14430,-94.751,16.3279,San Dionisio del Mar,ADM2,DISTRICT
SM243700,-94.7001,16.3514,San Dionisio del Mar Huave,LANGUAGE,LANGUAGE
SM270818,-73.0729,5.204,San Eduardo,ADM2,DISTRICT
SM295698,-88.9853,13.6446,San Emigdio,ADM2,DISTRICT
SM5205,-56.3925,-24.6458,San Estanislao (2002-),ADM2,DISTRICT
SM270694,-75.2078,10.3742,San Estanislao de Kostka,ADM2,DISTRICT
SM275751,-85.6949,15.2947,San Esteban,ADM2,DISTRICT
SM14431,-97.691,17.0759,San Esteban Atatlahuca,ADM2,DISTRICT
SM295537,-88.7373,13.7446,San Esteban Catarina,ADM2,DISTRICT
SM303783,-68.6333,10.5,San Felipe,ADM2,DISTRICT
SM282999,-88.2667,21.4616,San Felipe,ADM2,DISTRICT
SM281557,-101.244,21.4598,San Felipe,ADM2,DISTRICT
SM275260,-91.5989,14.623,San Felipe,ADM2,DISTRICT
SM270075,-67.3412,2.1432,San Felipe,ADM2,DISTRICT
SM14432,-96.5248,18.0778,San Felipe Jalapa de Diaz,ADM2,DISTRICT
SM281703,-98.5838,21.2415,San Felipe Orizatlán,ADM2,DISTRICT
SM243709,-97.7432,18.3594,San Felipe Otlaltepec Popoloca,LANGUAGE,LANGUAGE
SM14433,-96.8645,17.0968,San Felipe Tejalapam,ADM2,DISTRICT
SM282258,-98.5149,19.2326,San Felipe Teotlalcingo,ADM2,DISTRICT
SM282259,-97.7896,20.1114,San Felipe Tepatlán,ADM2,DISTRICT
SM14434,-96.488,17.8896,San Felipe Usila,ADM2,DISTRICT
SM269094,-70.6593,-32.604,San Felipe de Aconcagua,ADM2,DISTRICT
SM282598,-110.3271,29.8643,San Felipe de Jesús,ADM2,DISTRICT
SM271823,-70.7025,19.7512,San Felipe de Puerto Plata,ADM2,DISTRICT
SM14435,-99.981,19.6602,San Felipe del Progreso (2002-),ADM2,DISTRICT
SM303822,-67.1667,7.5833,San Fernando,ADM2,DISTRICT
SM285465,-86.2922,13.7443,San Fernando,ADM2,DISTRICT
SM282665,-98.0355,24.9161,San Fernando,ADM2,DISTRICT
SM283188,-93.2228,16.904,San Fernando,ADM2,DISTRICT
SM275727,-89.1015,14.6879,San Fernando,ADM2,DISTRICT
SM295727,-88.2,13.9667,San Fernando,ADM2,DISTRICT
SM295621,-89.0563,14.3112,San Fernando,ADM2,DISTRICT
SM273419,-79.2689,-3.1335,San Fernando,ADM2,DISTRICT
SM270695,-74.4118,9.0771,San Fernando,ADM2,DISTRICT
SM261094,-59.1667,-27.6667,San Fernando,ADM2,DISTRICT
SM3075,-61.4504,10.2813,San Fernando,ADM1,DISTRICT
SM271801,-71.6167,19.8333,San Fernando de Monte Cristi,ADM2,DISTRICT
SM303803,-71.7061,10.5545,San Francisco,ADM2,DISTRICT
SM303573,-68.726,11.1602,San Francisco,ADM2,DISTRICT
SM303194,-122.4425,37.7782,San Francisco,ADM2,DISTRICT
SM286675,-81.0011,8.2697,San Francisco,ADM2,DISTRICT
SM275656,-87.0174,15.6525,San Francisco,ADM2,DISTRICT
SM275706,-88.3719,14.1244,San Francisco,ADM2,DISTRICT
SM275208,-89.9088,16.7243,San Francisco,ADM2,DISTRICT
SM270410,-76.8399,1.1116,San Francisco,ADM2,DISTRICT
SM270261,-74.979,5.8597,San Francisco,ADM2,DISTRICT
SM270047,-74.2661,4.9634,San Francisco,ADM2,DISTRICT
SM14436,-97.3469,16.8806,San Francisco Cahuacua,ADM2,DISTRICT
SM14437,-96.2647,17.1809,San Francisco Cajonos,ADM2,DISTRICT
SM14438,-96.7495,17.9254,San Francisco Chapulapa,ADM2,DISTRICT
SM295699,-89.0792,13.6133,San Francisco Chinameca,ADM2,DISTRICT
SM14439,-97.307,17.426,San Francisco Chindua,ADM2,DISTRICT
SM275375,-91.4746,14.9662,San Francisco El Alto,ADM2,DISTRICT
SM295728,-88.1,13.7,San Francisco Gotera,ADM2,DISTRICT
SM14440,-96.9377,18.1984,San Francisco Huehuetlan,ADM2,DISTRICT
SM14441,-94.4771,16.3138,San Francisco Ixhuatan,ADM2,DISTRICT
SM14442,-97.277,17.3493,San Francisco Jaltepetongo,ADM2,DISTRICT
SM295583,-88.568,13.414,San Francisco Javier,ADM2,DISTRICT
SM275220,-91.544,14.9238,San Francisco La Unión,ADM2,DISTRICT
SM14443,-96.5996,17.0251,San Francisco Lachigolo,ADM2,DISTRICT
SM295622,-88.999,13.975,San Francisco Lempa,ADM2,DISTRICT
SM285437,-86.2078,12.504,San Francisco Libre,ADM2,DISTRICT
SM14444,-96.3791,16.3758,San Francisco Logueche,ADM2,DISTRICT
SM243707,-100.058,19.3584,San Francisco Matlatzinca,LANGUAGE,LANGUAGE
SM295508,-90.029,13.8066,San Francisco Menéndez,ADM2,DISTRICT
SM295623,-89.0488,14.2117,San Francisco Morazán,ADM2,DISTRICT
SM14445,-97.3388,17.3747,San Francisco Nuxano,ADM2,DISTRICT
SM14446,-96.2041,16.0889,San Francisco Ozolotepec,ADM2,DISTRICT
SM14447,-96.9435,16.5212,San Francisco Sola,ADM2,DISTRICT
SM14448,-96.9391,17.3772,San Francisco Telixtlahuaca,ADM2,DISTRICT
SM14449,-97.5094,17.8554,San Francisco Teopan,ADM2,DISTRICT
SM14450,-98.1582,19.2603,San Francisco Tetlanohcan,ADM2,DISTRICT
SM14451,-98.283,17.4783,San Francisco Tlapancingo,ADM2,DISTRICT
SM275352,-91.5306,14.61,San Francisco Zapotitlán,ADM2,DISTRICT
SM275752,-86.0354,14.6068,San Francisco de Becerra,ADM2,DISTRICT
SM283263,-106.7229,27.8943,San Francisco de Borja,ADM2,DISTRICT
SM283264,-105.3778,27.5736,San Francisco de Conchos,ADM2,DISTRICT
SM275800,-87.5481,13.6612,San Francisco de Coray,ADM2,DISTRICT
SM271902,-70.2,19.3,San Francisco de Macorís,ADM2,DISTRICT
SM275775,-88.21,14.67,San Francisco de Ojuera,ADM2,DISTRICT
SM275660,-88.3025,14.5844,San Francisco de Opalaca,ADM2,DISTRICT
SM275893,-87.9819,15.0183,San Francisco de Yojoa,ADM2,DISTRICT
SM275754,-86.1182,14.8418,San Francisco de la Paz,ADM2,DISTRICT
SM14452,-102.229,22.0331,San Francisco de los Romo,ADM2,DISTRICT
SM14453,-94.552,16.207,San Francisco del Mar,ADM2,DISTRICT
SM243703,-94.5738,16.2836,San Francisco del Mar Huave,LANGUAGE,LANGUAGE
SM285520,-86.7711,13.2119,San Francisco del Norte,ADM2,DISTRICT
SM283265,-105.9059,26.8942,San Francisco del Oro,ADM2,DISTRICT
SM281558,-101.7906,20.9355,San Francisco del Rincón,ADM2,DISTRICT
SM275728,-88.99,14.41,San Francisco del Valle,ADM2,DISTRICT
SM286679,-81.8738,8.2777,San Félix,ADM2,DISTRICT
SM275353,-91.5132,14.5066,San Gabriel,ADM2,DISTRICT
SM282260,-97.3509,18.3261,San Gabriel Chilac,ADM2,DISTRICT
SM14454,-97.0757,16.0699,San Gabriel Mixtepec,ADM2,DISTRICT
SM275479,-91.7292,15.3904,San Gaspar Ixchil,ADM2,DISTRICT
SM303681,-69.9803,8.8466,San Genaro de Boconoito,ADM2,DISTRICT
SM295749,-88.4134,13.8097,San Gerardo,ADM2,DISTRICT
SM16188,-67.0433,18.081,San German Barrio-Pueblo,ADM2,DISTRICT
SM3076,-67.0357,18.1147,San Germán,ADM1,DISTRICT
SM303591,-67.3994,8.0008,San Gerónimo de Guayabal,ADM2,DISTRICT
SM270508,-73.1387,6.5446,San Gil,ADM2,DISTRICT
SM282261,-98.3492,19.0094,San Gregorio Atzompa,ADM2,DISTRICT
SM300135,-55.75,-32.0833,San Gregorio de Polanco,ADM2,DISTRICT
SM271837,-70.1833,18.3333,San Gregorio de Yaguate,ADM2,DISTRICT
SM286894,-79,-5.0833,San Ignacio,ADM2,DISTRICT
SM289594,-57.1456,-26.9589,San Ignacio,ADM2,DISTRICT
SM282540,-106.4105,23.9617,San Ignacio,ADM2,DISTRICT
SM275924,-87.0476,14.738,San Ignacio,ADM2,DISTRICT
SM295624,-89.1577,14.3425,San Ignacio,ADM2,DISTRICT
SM260903,-55.3333,-27.25,San Ignacio,ADM2,DISTRICT
SM14455,-102.511,20.7603,San Ignacio Cerro Gordo,ADM2,DISTRICT
SM14456,-110.2615,27.3734,San Ignacio Rio Muerto,ADM2,DISTRICT
SM271860,-71.35,19.3833,San Ignacio de Sabaneta,ADM2,DISTRICT
SM295538,-88.5635,13.6814,San Ildefonso,ADM2,DISTRICT
SM257325,-100,36,San Ildefonso,ETHNICITY,ETHNICITY
SM257325,-100,36,San Ildefonso,ETHNICITY,ETHNICITY
SM257325,-100,36,San Ildefonso,ETHNICITY,ETHNICITY
SM257325,-106,36,San Ildefonso,ETHNICITY,ETHNICITY
SM257325,-106,36,San Ildefonso,ETHNICITY,ETHNICITY
SM257325,-106,36,San Ildefonso,ETHNICITY,ETHNICITY
SM257325,-106.2,35.9,San Ildefonso,ETHNICITY,ETHNICITY
SM257325,-106.2,35.9,San Ildefonso,ETHNICITY,ETHNICITY
SM257325,-106.2,35.9,San Ildefonso,ETHNICITY,ETHNICITY
SM14457,-96.4524,16.2889,San Ildefonso Amatlan,ADM2,DISTRICT
SM16189,-66.3558,18.0509,San Ildefonso Barrio,ADM2,DISTRICT
SM275480,-91.8129,15.4161,San Ildefonso Ixtahuacán,ADM2,DISTRICT
SM14458,-96.9706,16.5597,San Ildefonso Sola,ADM2,DISTRICT
SM14459,-96.1069,17.3725,San Ildefonso Villa Alta,ADM2,DISTRICT
SM285463,-86.2155,12.8784,San Isidro,ADM2,DISTRICT
SM275661,-88.1174,14.5676,San Isidro,ADM2,DISTRICT
SM275817,-87.2609,13.6445,San Isidro,ADM2,DISTRICT
SM295602,-88.7086,13.8275,San Isidro,ADM2,DISTRICT
SM295730,-88.2389,13.861,San Isidro,ADM2,DISTRICT
SM270937,-84.0337,10.0274,San Isidro,ADM2,DISTRICT
SM260709,-58.5303,-34.4901,San Isidro,ADM2,DISTRICT
SM16190,-65.2667,18.3213,San Isidro Barrio,ADM2,DISTRICT
SM295625,-88.8403,14.0112,San Isidro Labrador,ADM2,DISTRICT
SM302756,-95.1669,30.5795,San Jacinto,ADM2,DISTRICT
SM275415,-89.517,14.6747,San Jacinto,ADM2,DISTRICT
SM270696,-75.1104,9.8391,San Jacinto,ADM2,DISTRICT
SM14460,-96.7625,17.1006,San Jacinto Amilpas,ADM2,DISTRICT
SM14461,-97.3725,16.5199,San Jacinto Tlacotepec,ADM2,DISTRICT
SM273445,-79.699,-2.1168,San Jacinto de Yaguachi,ADM2,DISTRICT
SM10458,-74.6678,8.2373,San Jacinto del Cauca,ADM2,DISTRICT
SM300071,-58.0202,-32.6588,San Javier,ADM2,DISTRICT
SM282599,-109.7603,28.5932,San Javier,ADM2,DISTRICT
SM260904,-55.13,-27.7781,San Javier,ADM2,DISTRICT
SM261022,-59.9167,-30.3333,San Javier,ADM2,DISTRICT
SM261132,-65.1667,-32.0833,San Javier,ADM2,DISTRICT
SM14462,-96.9781,16.1834,San Jeronimo Coatlan,ADM2,DISTRICT
SM14463,-97.8483,17.8125,San Jeronimo Silacayoapilla,ADM2,DISTRICT
SM14464,-97.0429,17.3929,San Jeronimo Sosola,ADM2,DISTRICT
SM14465,-96.5909,16.7086,San Jeronimo Taviche,ADM2,DISTRICT
SM14466,-96.9161,18.1555,San Jeronimo Tecoatl,ADM2,DISTRICT
SM14467,-96.5552,17.0084,San Jeronimo Tlacochahuaya,ADM2,DISTRICT
SM14468,-98.2653,19.2453,San Jeronimo Zacualpan,ADM2,DISTRICT
SM275866,-88.9027,14.9621,San Jerónimo,ADM2,DISTRICT
SM275844,-87.5677,14.6404,San Jerónimo,ADM2,DISTRICT
SM275348,-90.19,15.0652,San Jerónimo,ADM2,DISTRICT
SM270262,-75.7078,6.4414,San Jerónimo,ADM2,DISTRICT
SM261023,-61.0833,-32.1667,San Jerónimo,ADM2,DISTRICT
SM282262,-98.3936,19.0272,San Jerónimo Tecuanipan,ADM2,DISTRICT
SM243761,-96.8833,18.1999,San Jerónimo Tecóatl Mazatec,LANGUAGE,LANGUAGE
SM282263,-97.9194,18.2053,San Jerónimo Xayacatlán,ADM2,DISTRICT
SM303195,-121.2715,37.9348,San Joaquin,ADM2,DISTRICT
SM303869,-67.7806,10.2914,San Joaquín,ADM2,DISTRICT
SM289685,-56.0503,-25.0449,San Joaquín,ADM2,DISTRICT
SM282453,-99.5322,20.9575,San Joaquín,ADM2,DISTRICT
SM270509,-72.8485,6.4655,San Joaquín,ADM2,DISTRICT
SM285486,-85.789,11.427,San Jorge,ADM2,DISTRICT
SM275729,-89.1186,14.637,San Jorge,ADM2,DISTRICT
SM295750,-88.3358,13.4185,San Jorge,ADM2,DISTRICT
SM14469,-98.1015,17.6592,San Jorge Nuchita,ADM2,DISTRICT
SM10459,-75.8129,5.0709,San Jose,ADM2,DISTRICT
SM14470,-97.9676,17.9428,San Jose Ayuquila,ADM2,DISTRICT
SM16191,-66.9156,18.477,San Jose Barrio,ADM2,DISTRICT
SM14471,-96.1551,17.953,San Jose Chiltepec,ADM2,DISTRICT
SM10460,-75.5659,7.7533,San Jose De Ure,ADM2,DISTRICT
SM14472,-98.2568,16.3691,San Jose Estancia Grande,ADM2,DISTRICT
SM14473,-96.6457,18.2436,San Jose Independencia,ADM2,DISTRICT
SM14474,-96.3401,16.4106,San Jose Lachiguiri,ADM2,DISTRICT
SM14475,-98.06,19.33,San Jose Teacalco,ADM2,DISTRICT
SM14476,-96.6732,18.1633,San Jose Tenango,ADM2,DISTRICT
SM14477,-96.4952,16.2881,San Jose del Penasco,ADM2,DISTRICT
SM14478,-96.6815,16.6991,San Jose del Progreso,ADM2,DISTRICT
SM14479,-100.1318,19.626,San Jose del Rincon,ADM2,DISTRICT
SM275818,-87.4226,13.7142,San José,ADM2,DISTRICT
SM275682,-87.9624,14.2264,San José,ADM2,DISTRICT
SM275867,-88.7167,14.9118,San José,ADM2,DISTRICT
SM275209,-89.905,16.9866,San José,ADM2,DISTRICT
SM275436,-90.8833,13.958,San José,ADM2,DISTRICT
SM295706,-87.8975,13.5455,San José,ADM2,DISTRICT
SM270965,-84.0833,9.9333,San José,ADM2,DISTRICT
SM3077,-84,9.6667,San José,ADM1,DISTRICT
SM3078,-56.75,-34.25,San José,ADM1:ADM2,DISTRICT
SM275186,-90.1479,14.2654,San José Acatempa,ADM2,DISTRICT
SM295626,-88.8407,13.9761,San José Cancasque,ADM2,DISTRICT
SM275340,-91.2276,14.7687,San José Chacayá,ADM2,DISTRICT
SM282264,-97.7359,19.2543,San José Chiapa,ADM2,DISTRICT
SM275354,-91.4334,14.3851,San José El Idolo,ADM2,DISTRICT
SM295655,-89.1177,13.8548,San José Guayabal,ADM2,DISTRICT
SM281559,-100.4115,21.0339,San José Iturbide,ADM2,DISTRICT
SM275406,-89.6081,14.717,San José La Arada,ADM2,DISTRICT
SM295627,-88.8328,14.0617,San José Las Flores,ADM2,DISTRICT
SM282265,-97.2698,18.2659,San José Miahuatlán,ADM2,DISTRICT
SM289535,-56.8784,-25.1163,San José Obrero,ADM2,DISTRICT
SM275289,-91.9585,15.2318,San José Ojetenam,ADM2,DISTRICT
SM275474,-90.3472,14.5518,San José Pinula,ADM2,DISTRICT
SM275390,-90.9052,14.8539,San José Poaquil,ADM2,DISTRICT
SM295662,-89.2706,13.5598,San José Villanueva,ADM2,DISTRICT
SM275777,-88.3221,15.0657,San José de Colinas,ADM2,DISTRICT
SM275845,-88.0483,14.7098,San José de Comayagua,ADM2,DISTRICT
SM285427,-86.68,13.2653,San José de Cusmapa,ADM2,DISTRICT
SM270386,-72.5052,8.0841,San José de Cúcuta,ADM2,DISTRICT
SM281494,-102.4167,22.15,San José de Gracia,ADM2,DISTRICT
SM303592,-65.8131,9.8603,San José de Guaribe,ADM2,DISTRICT
SM271876,-71.0833,19.3333,San José de Las Matas,ADM2,DISTRICT
SM270510,-72.7316,6.6305,San José de Miranda,ADM2,DISTRICT
SM271841,-70.5,18.6333,San José de Ocoa,ADM2,DISTRICT
SM3079,-70.5088,18.5488,San José de Ocoa,ADM1,DISTRICT
SM270819,-73.5596,5.9768,San José de Pare,ADM2,DISTRICT
SM270263,-75.6976,6.8221,San José de la Montaña,ADM2,DISTRICT
SM271023,-82.15,22.9667,San José de las Lajas,ADM2,DISTRICT
SM289686,-56.6986,-25.5394,San José de los Arroyos,ADM2,DISTRICT
SM285498,-85.7798,12.595,San José de los Remates,ADM2,DISTRICT
SM270867,-76.1655,1.3078,San José del Fragua,ADM2,DISTRICT
SM275473,-90.3677,14.7876,San José del Golfo,ADM2,DISTRICT
SM270079,-72.0746,2.5537,San José del Guaviare,ADM2,DISTRICT
SM269921,-76.2223,4.9466,San José del Palmar,ADM2,DISTRICT
SM275846,-87.2905,14.862,San José del Potrero,ADM2,DISTRICT
SM303089,-123.0249,48.5312,San Juan,ADM2,DISTRICT
SM302903,-109.8046,37.626,San Juan,ADM2,DISTRICT
SM301850,-108.3206,36.5085,San Juan,ADM2,DISTRICT
SM303370,-107.6762,37.764,San Juan,ADM2,DISTRICT
SM275683,-87.7295,13.9543,San Juan,ADM2,DISTRICT
SM275662,-88.4195,14.4213,San Juan,ADM2,DISTRICT
SM3081,-71.25,18.8333,San Juan,ADM1,DISTRICT
SM3082,-66.0917,18.451,San Juan,ADM1,DISTRICT
SM3080,-69,-31,San Juan,ADM1,DISTRICT
SM257326,-111,36,San Juan,ETHNICITY,ETHNICITY
SM257326,-111,36,San Juan,ETHNICITY,ETHNICITY
SM257326,-110,37,San Juan,ETHNICITY,ETHNICITY
SM257326,-110,37,San Juan,ETHNICITY,ETHNICITY
SM243751,-106.0703,36.0542,San Juan (USA),DIALECT:LANGUAGE,LANGUAGE
SM14480,-97.5084,17.3423,San Juan Achiutla,ADM2,DISTRICT
SM16192,-66.1088,18.4661,San Juan Antiguo Barrio,ADM2,DISTRICT
SM282266,-97.548,19.0539,San Juan Atenco,ADM2,DISTRICT
SM14481,-96.5284,17.4365,San Juan Atepec,ADM2,DISTRICT
SM275481,-91.6448,15.4479,San Juan Atitán,ADM2,DISTRICT
SM243760,-97.3792,18.2224,San Juan Atzingo Popoloca,LANGUAGE,LANGUAGE
SM282267,-98.0074,18.739,San Juan Atzompa,ADM2,DISTRICT
SM275355,-91.1989,14.4261,San Juan Bautista,ADM2,DISTRICT
SM14482,-96.8001,17.5791,San Juan Bautista Atatlahuca,ADM2,DISTRICT
SM14483,-97.2702,17.7287,San Juan Bautista Coixtlahuaca,ADM2,DISTRICT
SM14484,-96.9222,17.7255,San Juan Bautista Cuicatlan,ADM2,DISTRICT
SM14485,-96.7815,17.2203,San Juan Bautista Guelache,ADM2,DISTRICT
SM14486,-96.8264,17.4246,San Juan Bautista Jayacatlan,ADM2,DISTRICT
SM14487,-98.3362,16.5114,San Juan Bautista Lo de Soto,ADM2,DISTRICT
SM14488,-97.6651,17.975,San Juan Bautista Suchitepec,ADM2,DISTRICT
SM14489,-98.3513,17.5856,San Juan Bautista Tlachichilco,ADM2,DISTRICT
SM14490,-96.5882,17.8805,San Juan Bautista Tlacoatzintepec,ADM2,DISTRICT
SM14491,-96.0887,18.0414,San Juan Bautista Tuxtepec,ADM2,DISTRICT
SM14492,-96.324,17.7958,San Juan Bautista Valle Nacional,ADM2,DISTRICT
SM289595,-57.4059,-26.7122,San Juan Bautista de las Misiones,ADM2,DISTRICT
SM289605,-57.8577,-26.6102,San Juan Bautista del Ñeembucu,ADM2,DISTRICT
SM273523,-78.3817,-3.2627,San Juan Bosco,ADM2,DISTRICT
SM14493,-98.1639,16.6198,San Juan Cacahuatepec,ADM2,DISTRICT
SM14494,-92.3884,16.9403,San Juan Cancuc,ADM2,DISTRICT
SM275172,-90.2304,15.3937,San Juan Chamelco,ADM2,DISTRICT
SM14495,-96.5114,17.2979,San Juan Chicomezuchil,ADM2,DISTRICT
SM14496,-96.6703,16.8281,San Juan Chilateca,ADM2,DISTRICT
SM14497,-98.3104,17.875,San Juan Cieneguilla,ADM2,DISTRICT
SM14498,-96.7528,18.0457,San Juan Coatzospam,ADM2,DISTRICT
SM14499,-97.9152,16.4703,San Juan Colorado,ADM2,DISTRICT
SM243756,-97.9545,16.4607,San Juan Colorado Mixtec,LANGUAGE,LANGUAGE
SM14500,-95.9435,17.3107,San Juan Comaltepec,ADM2,DISTRICT
SM275242,-91.021,15.427,San Juan Cotzal,ADM2,DISTRICT
SM14501,-95.3267,17.4386,San Juan Cotzocon,ADM2,DISTRICT
SM14502,-97.3851,17.2779,San Juan Diuxi,ADM2,DISTRICT
SM275407,-89.4282,14.7376,San Juan Ermita,ADM2,DISTRICT
SM282776,-95.1502,17.7639,San Juan Evangelista,ADM2,DISTRICT
SM14503,-96.5355,17.4048,San Juan Evangelista Analco,ADM2,DISTRICT
SM275707,-88.7748,14.1549,San Juan Guarita,ADM2,DISTRICT
SM14504,-96.5387,16.9553,San Juan Guelavia,ADM2,DISTRICT
SM243759,-96.5967,17.0332,San Juan Guelavía Zapotec,LANGUAGE,LANGUAGE
SM14505,-95.0911,17.0734,San Juan Guichicovi,ADM2,DISTRICT
SM14506,-98.2576,19.2342,San Juan Huactzinco,ADM2,DISTRICT
SM14507,-98.2852,17.7566,San Juan Ihualtepec,ADM2,DISTRICT
SM275482,-91.4489,15.6062,San Juan Ixcoy,ADM2,DISTRICT
SM14508,-95.8891,16.8843,San Juan Juquila Mixes,ADM2,DISTRICT
SM14509,-96.2742,17.3373,San Juan Juquila Vijanos,ADM2,DISTRICT
SM275341,-91.3147,14.6667,San Juan La Laguna,ADM2,DISTRICT
SM14510,-97.1112,16.1799,San Juan Lachao,ADM2,DISTRICT
SM14511,-96.5386,16.5559,San Juan Lachigalla,ADM2,DISTRICT
SM14512,-95.9052,16.4935,San Juan Lajarcia,ADM2,DISTRICT
SM14513,-95.8093,17.5323,San Juan Lalana,ADM2,DISTRICT
SM14514,-95.3128,17.2087,San Juan Mazatlan,ADM2,DISTRICT
SM14515,-97.8615,17.3168,San Juan Mixtepec -Dto. 08 -,ADM2,DISTRICT
SM14516,-96.2992,16.2767,San Juan Mixtepec -Dto. 26 -,ADM2,DISTRICT
SM289706,-55.9007,-26.2315,San Juan Nepomuceno,ADM2,DISTRICT
SM270697,-75.0743,9.9498,San Juan Nepomuceno,ADM2,DISTRICT
SM295679,-88.9026,13.4931,San Juan Nonualco,ADM2,DISTRICT
SM14517,-97.7362,17.4149,San Juan Numi,ADM2,DISTRICT
SM14518,-96.2686,16.0978,San Juan Ozolotepec,ADM2,DISTRICT
SM14519,-96.0362,17.5069,San Juan Petlapa,ADM2,DISTRICT
SM243755,-98.1449,17.2226,San Juan Piñas,DIALECT,LANGUAGE
SM14520,-97.3603,16.3215,San Juan Quiahije,ADM2,DISTRICT
SM228666,-97.3167,16.3,San Juan Quiahije Chatino Sign Language,LANGUAGE,LANGUAGE
SM14521,-96.5803,17.6124,San Juan Quiotepec,ADM2,DISTRICT
SM275438,-90.6561,14.7679,San Juan Sacatepéquez,ADM2,DISTRICT
SM14522,-97.2938,17.4474,San Juan Sayultepec,ADM2,DISTRICT
SM14523,-96.2121,17.3022,San Juan Tabaa,ADM2,DISTRICT
SM295680,-89.0894,13.4997,San Juan Talpa,ADM2,DISTRICT
SM14524,-97.1254,17.1106,San Juan Tamazola,ADM2,DISTRICT
SM275322,-90.2685,14.0723,San Juan Tecuaco,ADM2,DISTRICT
SM14525,-97.4199,17.0932,San Juan Teita,ADM2,DISTRICT
SM243757,-97.3899,17.1542,San Juan Teita Mixtec,LANGUAGE,LANGUAGE
SM14526,-96.6095,16.9263,San Juan Teitipac,ADM2,DISTRICT
SM14527,-96.7559,17.7564,San Juan Tepeuxila,ADM2,DISTRICT
SM295681,-88.9946,13.6013,San Juan Tepezontes,ADM2,DISTRICT
SM14528,-97.4169,17.5814,San Juan Teposcolula,ADM2,DISTRICT
SM14529,-96.2888,17.4308,San Juan Yaee,ADM2,DISTRICT
SM14530,-96.1694,17.4021,San Juan Yatzona,ADM2,DISTRICT
SM14531,-97.2683,17.5166,San Juan Yucuita,ADM2,DISTRICT
SM270287,-73.8207,3.2903,San Juan de Arama,ADM2,DISTRICT
SM270535,-75.1941,9.2849,San Juan de Betulia,ADM2,DISTRICT
SM303704,-65.4212,10.1315,San Juan de Capistrano,ADM2,DISTRICT
SM275926,-86.9635,14.2929,San Juan de Flores,ADM2,DISTRICT
SM281515,-102.6991,24.595,San Juan de Guadalupe,ADM2,DISTRICT
SM285533,-86.5775,13.1916,San Juan de Limay,ADM2,DISTRICT
SM275868,-88.7102,14.8015,San Juan de Opoa,ADM2,DISTRICT
SM285449,-86.0746,11.9052,San Juan de Oriente,ADM2,DISTRICT
SM270048,-74.6825,4.8308,San Juan de Río Seco,ADM2,DISTRICT
SM270264,-76.5051,8.6716,San Juan de Urabá,ADM2,DISTRICT
SM271846,-71.1667,18.8333,San Juan de la Maguana,ADM2,DISTRICT
SM14532,-97.0103,18.0594,San Juan de los Cues,ADM2,DISTRICT
SM281838,-102.2988,21.2685,San Juan de los Lagos,ADM2,DISTRICT
SM270124,-73.0833,10.8333,San Juan del Cesar,ADM2,DISTRICT
SM14533,-96.7951,17.2888,San Juan del Estado,ADM2,DISTRICT
SM285478,-83.7354,10.9467,San Juan del Norte,ADM2,DISTRICT
SM289579,-55.971,-27.3079,San Juan del Paraná,ADM2,DISTRICT
SM14534,-96.1694,16.8914,San Juan del Rio,ADM2,DISTRICT
SM282454,-100.0029,20.3874,San Juan del Río,ADM2,DISTRICT
SM281516,-104.4989,24.7739,San Juan del Río,ADM2,DISTRICT
SM285428,-86.1386,13.5265,San Juan del Río Coco,ADM2,DISTRICT
SM271026,-83.8366,22.3021,San Juan y Martínez,ADM2,DISTRICT
SM3083,-61.4975,10.6496,San Juan-Laventille(1992-),ADM1,DISTRICT
SM270288,-73.6964,4.4291,San Juanito,ADM2,DISTRICT
SM303747,-72.0637,8.2659,San Judas Tadeo,ADM2,DISTRICT
SM281839,-102.1618,20.9902,San Julián,ADM2,DISTRICT
SM295560,-89.56,13.7039,San Julián,ADM2,DISTRICT
SM261134,-62.6667,-31.3333,San Justo,ADM2,DISTRICT
SM261024,-60.5,-30.6667,San Justo,ADM2,DISTRICT
SM296884,99.1861,18.735,San Kamphaeng,ADM2,DISTRICT
SM283407,14.1978,36.0486,San Lawrenz,ADM1:ADM2,DISTRICT
SM286680,-82.0892,8.2937,San Lorenzo,ADM2,DISTRICT
SM289721,-57.5109,-25.3507,San Lorenzo,ADM2,DISTRICT
SM285499,-85.707,12.2634,San Lorenzo,ADM2,DISTRICT
SM14535,-97.8882,16.4166,San Lorenzo,ADM2,DISTRICT
SM275801,-87.4074,13.4515,San Lorenzo,ADM2,DISTRICT
SM275356,-91.5256,14.2522,San Lorenzo,ADM2,DISTRICT
SM275290,-91.7452,15.027,San Lorenzo,ADM2,DISTRICT
SM295539,-88.8196,13.6922,San Lorenzo,ADM2,DISTRICT
SM295509,-89.7833,14.0333,San Lorenzo,ADM2,DISTRICT
SM273635,-78.8333,1.2833,San Lorenzo,ADM2,DISTRICT
SM270346,-77.246,1.5573,San Lorenzo,ADM2,DISTRICT
SM261025,-61,-33,San Lorenzo,ADM2,DISTRICT
SM261095,-60.4167,-27.3333,San Lorenzo,ADM2,DISTRICT
SM3084,-65.9746,18.155,San Lorenzo,ADM1,DISTRICT
SM14536,-96.2528,16.9129,San Lorenzo Albarradas,ADM2,DISTRICT
SM14537,-98.255,19.2214,San Lorenzo Axocomanitla,ADM2,DISTRICT
SM16193,-66.4382,18.3064,San Lorenzo Barrio,ADM2,DISTRICT
SM16194,-65.963,18.191,San Lorenzo Barrio-Pueblo,ADM2,DISTRICT
SM14538,-96.7978,17.1353,San Lorenzo Cacaotepec,ADM2,DISTRICT
SM14539,-96.9114,18.2069,San Lorenzo Cuaunecuiltitla,ADM2,DISTRICT
SM14540,-97.1882,16.5323,San Lorenzo Texmelucan,ADM2,DISTRICT
SM14541,-98.1238,17.6319,San Lorenzo Victoria,ADM2,DISTRICT
SM285429,-86.6458,13.4077,San Lucas,ADM2,DISTRICT
SM282075,-100.7987,18.5871,San Lucas,ADM2,DISTRICT
SM283190,-92.6977,16.6011,San Lucas,ADM2,DISTRICT
SM275897,-86.9516,13.7459,San Lucas,ADM2,DISTRICT
SM14542,-95.6989,16.9463,San Lucas Camotlan,ADM2,DISTRICT
SM14543,-96.3847,18.0419,San Lucas Ojitlan,ADM2,DISTRICT
SM14544,-96.4678,16.8936,San Lucas Quiavini,ADM2,DISTRICT
SM275279,-90.6497,14.5976,San Lucas Sacatepéquez,ADM2,DISTRICT
SM14545,-98.2557,19.4881,San Lucas Tecopilco,ADM2,DISTRICT
SM275342,-91.1466,14.5947,San Lucas Tolimán,ADM2,DISTRICT
SM14546,-96.9071,18.1201,San Lucas Zoquiapam,ADM2,DISTRICT
SM275847,-87.4167,14.75,San Luis,ADM2,DISTRICT
SM275778,-88.47,15.09,San Luis,ADM2,DISTRICT
SM275199,-89.4795,16.1367,San Luis,ADM2,DISTRICT
SM271050,-75.8274,20.2686,San Luis,ADM2,DISTRICT
SM271027,-83.6845,22.2577,San Luis,ADM2,DISTRICT
SM270265,-74.9469,6.0164,San Luis,ADM2,DISTRICT
SM3085,-66,-34,San Luis,ADM1,DISTRICT
SM281629,-98.6967,16.902,San Luis Acatlán (pre-2005),ADM2,DISTRICT
SM14548,-96.4895,16.457,San Luis Amatlan,ADM2,DISTRICT
SM275178,-89.7132,14.6503,San Luis Jilotepeque,ADM2,DISTRICT
SM295682,-88.9333,13.65,San Luis La Herradura,ADM2,DISTRICT
SM303197,-120.4522,35.3874,San Luis Obispo,ADM2,DISTRICT
SM282495,-100.9584,22.3393,San Luis Potosí,ADM2,DISTRICT
SM3086,-100.5,22.5,San Luis Potosí,ADM1,DISTRICT
SM282600,-114.8643,32.3225,San Luis Río Colorado,ADM2,DISTRICT
SM295683,-89.0833,13.4667,San Luis Talpa,ADM2,DISTRICT
SM281517,-104.2788,25.3836,San Luis de Cordero,ADM2,DISTRICT
SM270289,-74.0767,3.8267,San Luis de Cubarral,ADM2,DISTRICT
SM270820,-73.142,4.8529,San Luis de Gaceno,ADM2,DISTRICT
SM270884,-71.5819,5.2806,San Luis de Palenque,ADM2,DISTRICT
SM281560,-100.4973,21.363,San Luis de la Paz,ADM2,DISTRICT
SM295751,-88.3557,13.8287,San Luis de la Reina,ADM2,DISTRICT
SM295629,-88.9636,13.9637,San Luis del Carmen,ADM2,DISTRICT
SM261160,-58.25,-27.5,San Luis del Palmar,ADM2,DISTRICT
SM270584,-75.0833,4.1667,San Luís,ADM2,DISTRICT
SM243771,-97.5459,18.6812,San Luís Temalacayuca Popoloca,LANGUAGE,LANGUAGE
SM289525,-57.735,-22.2472,San Lázaro,ADM2,DISTRICT
SM275894,-87.9017,15.3728,San Manuel,ADM2,DISTRICT
SM275179,-89.7634,14.5226,San Manuel Chaparrón,ADM2,DISTRICT
SM275708,-88.6705,14.4444,San Manuel Colohete,ADM2,DISTRICT
SM14549,-96.4003,16.0558,San Marcial Ozolotepec,ADM2,DISTRICT
SM286883,-78.0605,-7.2828,San Marcos,ADM2,DISTRICT
SM285508,-86.2747,11.9178,San Marcos,ADM2,DISTRICT
SM281840,-104.2356,20.8034,San Marcos,ADM2,DISTRICT
SM281630,-99.3746,16.7727,San Marcos,ADM2,DISTRICT
SM275779,-88.39,15.24,San Marcos,ADM2,DISTRICT
SM275730,-88.92,14.38,San Marcos,ADM2,DISTRICT
SM275292,-91.834,14.9892,San Marcos,ADM2,DISTRICT
SM295515,-89.1768,13.6595,San Marcos,ADM2,DISTRICT
SM270536,-75.1667,8.5833,San Marcos,ADM2,DISTRICT
SM3087,-91.9167,15,San Marcos,ADM1,DISTRICT
SM14550,-97.8863,17.7294,San Marcos Arteaga,ADM2,DISTRICT
SM275324,-91.2598,14.7351,San Marcos La Laguna,ADM2,DISTRICT
SM243788,-97.5936,18.74,San Marcos Tlalcoyalco Popoloca,LANGUAGE,LANGUAGE
SM275710,-88.6013,14.4161,San Marcos de Caiquín,ADM2,DISTRICT
SM275819,-86.821,13.4151,San Marcos de Colón,ADM2,DISTRICT
SM275663,-88.2469,14.1324,San Marcos de la Sierra,ADM2,DISTRICT
SM3088,12.45,43.9333,San Marino,ADM1,DISTRICT
SM14551,-97.6042,17.3984,San Martin Huamelulpam,ADM2,DISTRICT
SM14552,-97.8585,17.212,San Martin Itunyoso,ADM2,DISTRICT
SM14553,-96.8465,16.6107,San Martin Lachila,ADM2,DISTRICT
SM14554,-98.2447,17.3683,San Martin Peras,ADM2,DISTRICT
SM14555,-96.6936,16.8635,San Martin Tilcajete,ADM2,DISTRICT
SM14556,-97.0409,18.1072,San Martin Toxpalan,ADM2,DISTRICT
SM14557,-98.0734,17.8156,San Martin Zacatepec,ADM2,DISTRICT
SM10461,-74.0035,8.8821,San Martin de Loba (1995-),ADM2,DISTRICT
SM14558,-96.7211,16.6561,San Martin de los Cansecos,ADM2,DISTRICT
SM286844,-76.1667,-6.5833,San Martín,ADM2,DISTRICT
SM295516,-89.0635,13.7435,San Martín,ADM2,DISTRICT
SM270290,-73.0398,3.5082,San Martín,ADM2,DISTRICT
SM269902,-73.5725,7.97,San Martín,ADM2,DISTRICT
SM261026,-61.8333,-32,San Martín,ADM2,DISTRICT
SM260970,-68.25,-31.5,San Martín,ADM2,DISTRICT
SM261053,-63.8333,-28.1667,San Martín,ADM2,DISTRICT
SM260885,-68.2492,-32.8373,San Martín,ADM2,DISTRICT
SM261161,-56.9167,-28.8333,San Martín,ADM2,DISTRICT
SM3089,-76.8333,-7,San Martín,ADM1,DISTRICT
SM282497,-98.6214,21.3988,San Martín Chalchicuautla,ADM2,DISTRICT
SM281843,-103.9184,20.4367,San Martín Hidalgo,ADM2,DISTRICT
SM243785,-97.9202,17.2428,San Martín Itunyoso Triqui,LANGUAGE,LANGUAGE
SM275391,-90.7784,14.8321,San Martín Jilotepeque,ADM2,DISTRICT
SM243778,-98.2373,17.3591,San Martín Peras,DIALECT,LANGUAGE
SM243776,-76.6608,-6.9582,San Martín Quechua,LANGUAGE,LANGUAGE
SM275222,-91.6627,14.7974,San Martín Sacatepéquez,ADM2,DISTRICT
SM282269,-98.4283,19.2693,San Martín Texmelucan,ADM2,DISTRICT
SM282270,-98.351,18.6495,San Martín Totoltepec,ADM2,DISTRICT
SM275261,-91.5939,14.6128,San Martín Zapotitlán,ADM2,DISTRICT
SM281841,-103.8047,21.6069,San Martín de Bolaños,ADM2,DISTRICT
SM281959,-98.8356,19.7054,San Martín de las Piráámides,ADM2,DISTRICT
SM303198,-122.3557,37.4362,San Mateo,ADM2,DISTRICT
SM275223,-91.5855,14.8436,San Mateo,ADM2,DISTRICT
SM270901,-84.5315,9.9574,San Mateo,ADM2,DISTRICT
SM270821,-72.578,6.377,San Mateo,ADM2,DISTRICT
SM281960,-99.5375,19.267,San Mateo Atenco,ADM2,DISTRICT
SM14559,-96.2054,17.1607,San Mateo Cajonos,ADM2,DISTRICT
SM14560,-97.262,17.4182,San Mateo Etlatongo,ADM2,DISTRICT
SM275483,-91.4932,15.921,San Mateo Ixtatán,ADM2,DISTRICT
SM14561,-98.4094,17.652,San Mateo Nejapam,ADM2,DISTRICT
SM14562,-97.5164,17.1508,San Mateo Penasco,ADM2,DISTRICT
SM243780,-97.5332,17.1513,San Mateo Peñasco Mixtec,DIALECT,LANGUAGE
SM14563,-96.34,15.9572,San Mateo Pinas,ADM2,DISTRICT
SM14564,-96.5094,16.1293,San Mateo Rio Hondo,ADM2,DISTRICT
SM14565,-97.3671,16.9876,San Mateo Sindihui,ADM2,DISTRICT
SM243784,-97.0329,17.0028,San Mateo Tepantepec,DIALECT,LANGUAGE
SM14566,-97.4268,17.8082,San Mateo Tlapiltepec,ADM2,DISTRICT
SM14567,-96.8642,18.1416,San Mateo Yoloxochitlan,ADM2,DISTRICT
SM14568,-97.2956,16.7916,San Mateo Yucutindoo,ADM2,DISTRICT
SM14569,-95.0388,16.208,San Mateo del Mar,ADM2,DISTRICT
SM243774,-95.0077,16.2129,San Mateo del Mar Huave,LANGUAGE,LANGUAGE
SM275898,-86.6403,13.9597,San Matías,ADM2,DISTRICT
SM295663,-89.3078,13.903,San Matías,ADM2,DISTRICT
SM282271,-98.5112,19.3379,San Matías Tlalancaleca,ADM2,DISTRICT
SM14570,-96.1471,17.256,San Melchor Betaza,ADM2,DISTRICT
SM301851,-104.8159,35.4803,San Miguel,ADM2,DISTRICT
SM303371,-108.4058,38.0037,San Miguel,ADM2,DISTRICT
SM286884,-79,-7,San Miguel,ADM2,DISTRICT
SM289596,-56.9677,-26.5809,San Miguel,ADM2,DISTRICT
SM295752,-88.1667,13.5,San Miguel,ADM2,DISTRICT
SM273553,-79.0333,-1.7,San Miguel,ADM2,DISTRICT
SM10462,-76.91,0.343,San Miguel,ADM2,DISTRICT
SM270511,-72.6432,6.5682,San Miguel,ADM2,DISTRICT
SM261162,-57.5,-27.9167,San Miguel,ADM2,DISTRICT
SM3090,-88.2833,13.5333,San Miguel,ADM1,DISTRICT
SM275484,-91.6226,15.7041,San Miguel Acatán,ADM2,DISTRICT
SM14571,-97.4889,17.3064,San Miguel Achiutla,ADM2,DISTRICT
SM243779,-97.4889,17.3065,San Miguel Achiutla Mixtec,DIALECT,LANGUAGE
SM14572,-98.3414,17.6554,San Miguel Ahuehuetitlan,ADM2,DISTRICT
SM14573,-96.7044,17.4021,San Miguel Aloapam,ADM2,DISTRICT
SM14574,-98.014,17.8832,San Miguel Amatitlan,ADM2,DISTRICT
SM14575,-96.4564,17.2002,San Miguel Amatlan,ADM2,DISTRICT
SM243783,-97.5707,17.0323,San Miguel Chalcatongo,DIALECT,LANGUAGE
SM14576,-97.1951,17.6287,San Miguel Chicahua,ADM2,DISTRICT
SM275349,-90.413,15.1598,San Miguel Chicaj,ADM2,DISTRICT
SM14577,-94.4761,16.6466,San Miguel Chimalapa,ADM2,DISTRICT
SM14578,-96.691,16.1636,San Miguel Coatlan,ADM2,DISTRICT
SM243792,-81.2564,8.326,San Miguel Creole French,LANGUAGE,LANGUAGE
SM275265,-90.8319,14.5261,San Miguel Dueñas,ADM2,DISTRICT
SM14579,-96.7377,16.5941,San Miguel Ejutla,ADM2,DISTRICT
SM243782,-97.6225,17.0469,San Miguel El Grande Mixtec,LANGUAGE,LANGUAGE
SM14580,-97.1509,17.7437,San Miguel Huautla,ADM2,DISTRICT
SM282272,-97.7642,18.0101,San Miguel Ixitlán,ADM2,DISTRICT
SM275293,-91.7334,15.2635,San Miguel Ixtahuacán,ADM2,DISTRICT
SM14581,-96.939,16.7655,San Miguel Mixtepec,ADM2,DISTRICT
SM14582,-97.4098,16.252,San Miguel Panixtlahuaca,ADM2,DISTRICT
SM275357,-91.3596,14.5053,San Miguel Panán,ADM2,DISTRICT
SM14583,-97.0083,16.9314,San Miguel Peras,ADM2,DISTRICT
SM14584,-97.2322,16.9872,San Miguel Piedras,ADM2,DISTRICT
SM243781,-97.2056,16.9944,San Miguel Piedras Mixtec,LANGUAGE,LANGUAGE
SM14585,-95.762,16.9834,San Miguel Quetzaltepec,ADM2,DISTRICT
SM14586,-96.7975,17.9289,San Miguel Santa Flor,ADM2,DISTRICT
SM275224,-91.6021,14.9006,San Miguel Sigüilá,ADM2,DISTRICT
SM14587,-96.396,18.254,San Miguel Soyaltepec,ADM2,DISTRICT
SM14588,-96.463,16.0776,San Miguel Suchixtepec,ADM2,DISTRICT
SM14589,-97.2696,17.3952,San Miguel Tecomatlan,ADM2,DISTRICT
SM14590,-95.5566,16.238,San Miguel Tenango,ADM2,DISTRICT
SM295684,-89.016,13.6304,San Miguel Tepezontes,ADM2,DISTRICT
SM14591,-97.3241,17.8023,San Miguel Tequixtepec,ADM2,DISTRICT
SM14592,-96.5704,16.7745,San Miguel Tilquiapam,ADM2,DISTRICT
SM14593,-98.091,16.4302,San Miguel Tlacamama,ADM2,DISTRICT
SM14594,-98.0116,17.4433,San Miguel Tlacotepec,ADM2,DISTRICT
SM281631,-100.296,17.8638,San Miguel Totolapan,ADM2,DISTRICT
SM14595,-97.4425,17.7514,San Miguel Tulancingo,ADM2,DISTRICT
SM282273,-98.3032,19.1735,San Miguel Xoxtla,ADM2,DISTRICT
SM14596,-96.3364,17.3706,San Miguel Yotao,ADM2,DISTRICT
SM282601,-110.8187,29.445,San Miguel de Horcasitas,ADM2,DISTRICT
SM295630,-88.9331,14.001,San Miguel de Mercedes,ADM2,DISTRICT
SM270411,-76.6544,1.2124,San Miguel de Mocoa (1992-),ADM2,DISTRICT
SM270822,-73.7514,5.5349,San Miguel de Sema,ADM2,DISTRICT
SM273461,-78.1972,0.4203,San Miguel de Urcuquí,ADM2,DISTRICT
SM273546,-78.9887,-0.0358,San Miguel de los Bancos,ADM2,DISTRICT
SM271092,-82.2953,23.065,San Miguel del Padrón,ADM2,DISTRICT
SM14597,-96.1186,15.9406,San Miguel del Puerto,ADM2,DISTRICT
SM14598,-96.5694,17.3229,San Miguel del Rio,ADM2,DISTRICT
SM281844,-102.3645,20.9914,San Miguel el Alto,ADM2,DISTRICT
SM14599,-97.6155,17.0647,San Miguel el Grande,ADM2,DISTRICT
SM286665,-79.5,9.05,San Miguelito,ADM2,DISTRICT
SM285479,-84.6937,11.4618,San Miguelito,ADM2,DISTRICT
SM275664,-88.3398,14.3488,San Miguelito,ADM2,DISTRICT
SM275927,-87.4923,13.7457,San Miguelito,ADM2,DISTRICT
SM14600,-96.7284,16.4427,San Nicolas,ADM2,DISTRICT
SM14601,-98.1619,17.7826,San Nicolas Hidalgo,ADM2,DISTRICT
SM285534,-86.3472,12.9345,San Nicolás,ADM2,DISTRICT
SM282666,-98.8052,24.6468,San Nicolás,ADM2,DISTRICT
SM275869,-88.73,14.98,San Nicolás,ADM2,DISTRICT
SM275780,-88.3791,14.9191,San Nicolás,ADM2,DISTRICT
SM271014,-81.893,22.7511,San Nicolás,ADM2,DISTRICT
SM260710,-60.3333,-33.5,San Nicolás,ADM2,DISTRICT
SM282274,-97.5216,19.221,San Nicolás Buenos Aires,ADM2,DISTRICT
SM282498,-100.4991,22.1625,San Nicolás Tolentino,ADM2,DISTRICT
SM282190,-100.3022,25.7417,San Nicolás de los Garza,ADM2,DISTRICT
SM282275,-98.5246,19.0854,San Nicolás de los Ranchos,ADM2,DISTRICT
SM270537,-75.5248,9.7371,San Onofre,ADM2,DISTRICT
SM296885,98.8853,18.6057,San Pa Tong,ADM2,DISTRICT
SM289640,-56.9193,-24.1381,San Pablo,ADM2,DISTRICT
SM286885,-78.7492,-7.0751,San Pablo,ADM2,DISTRICT
SM275294,-91.9708,14.9539,San Pablo,ADM2,DISTRICT
SM270938,-84.0934,9.9967,San Pablo,ADM2,DISTRICT
SM270347,-77.0237,1.6702,San Pablo,ADM2,DISTRICT
SM270699,-74.2447,7.3127,San Pablo (1994-),ADM2,DISTRICT
SM282276,-98.1256,18.1424,San Pablo Anicano,ADM2,DISTRICT
SM14602,-96.8164,16.1258,San Pablo Coatlan,ADM2,DISTRICT
SM14603,-96.8892,16.975,San Pablo Cuatro Venados,ADM2,DISTRICT
SM14604,-96.7678,17.1544,San Pablo Etla,ADM2,DISTRICT
SM14605,-96.8798,17.2993,San Pablo Huitzo,ADM2,DISTRICT
SM14606,-96.7959,16.8181,San Pablo Huixtepec,ADM2,DISTRICT
SM275358,-91.4285,14.5897,San Pablo Jocopilas,ADM2,DISTRICT
SM275325,-91.2807,14.7289,San Pablo La Laguna,ADM2,DISTRICT
SM14607,-96.5397,17.5291,San Pablo Macuiltianguis,ADM2,DISTRICT
SM295664,-89.3333,14,San Pablo Tacachico,ADM2,DISTRICT
SM14608,-97.492,17.0255,San Pablo Tijaltepec,ADM2,DISTRICT
SM14609,-96.3383,16.9336,San Pablo Villa de Mitla,ADM2,DISTRICT
SM14610,-96.2265,17.1449,San Pablo Yaganiza,ADM2,DISTRICT
SM270823,-74.1372,5.6664,San Pablo de Borbur,ADM2,DISTRICT
SM14611,-98.135,19.1529,San Pablo del Monte (1995-),ADM2,DISTRICT
SM302757,-97.5183,28.0088,San Patricio,ADM2,DISTRICT
SM289597,-56.8294,-26.9955,San Patricio,ADM2,DISTRICT
SM16195,-66.6427,18.136,San Patricio Barrio,ADM2,DISTRICT
SM283435,14.3986,35.9375,San Pawl il-Baħar,ADM1:ADM2,DISTRICT
SM283303,-103.037,25.7969,San Pedro,ADM2,DISTRICT
SM275870,-88.8,14.6167,San Pedro,ADM2,DISTRICT
SM270629,-76.2612,3.9994,San Pedro,ADM2,DISTRICT
SM270538,-75.035,9.3992,San Pedro,ADM2,DISTRICT
SM260905,-54,-26.6667,San Pedro,ADM2,DISTRICT
SM260839,-64.8333,-24.3333,San Pedro,ADM2,DISTRICT
SM260711,-59.75,-33.75,San Pedro,ADM2,DISTRICT
SM3091,-56.6667,-24.1333,San Pedro,ADM1,DISTRICT
SM14612,-98.0889,16.6693,San Pedro Amuzgos,ADM2,DISTRICT
SM243802,-98.0464,16.5198,San Pedro Amuzgos Amuzgo,LANGUAGE,LANGUAGE
SM14613,-96.7251,16.7366,San Pedro Apostol,ADM2,DISTRICT
SM14614,-97.9734,16.508,San Pedro Atoyac,ADM2,DISTRICT
SM275439,-90.441,14.7727,San Pedro Ayampuc,ADM2,DISTRICT
SM14615,-96.2614,17.1659,San Pedro Cajonos,ADM2,DISTRICT
SM275173,-90.1815,15.5677,San Pedro Carchá,ADM2,DISTRICT
SM282277,-98.3601,19.074,San Pedro Cholula,ADM2,DISTRICT
SM14616,-95.1496,16.4784,San Pedro Comitancillo,ADM2,DISTRICT
SM14617,-97.1141,17.5162,San Pedro Coxcaltepec Cantaros,ADM2,DISTRICT
SM282191,-100.3845,25.631,San Pedro Garza García,ADM2,DISTRICT
SM14618,-95.7463,15.9832,San Pedro Huamelula,ADM2,DISTRICT
SM14619,-95.155,16.2379,San Pedro Huilotepec,ADM2,DISTRICT
SM14620,-96.5398,18.1534,San Pedro Ixcatlan,ADM2,DISTRICT
SM14621,-96.8022,17.0553,San Pedro Ixtlahuaca,ADM2,DISTRICT
SM14622,-97.0339,17.6886,San Pedro Jaltepetongo,ADM2,DISTRICT
SM14623,-98.0431,16.4648,San Pedro Jicayan,ADM2,DISTRICT
SM275243,-91.1561,15.1547,San Pedro Jocopilas,ADM2,DISTRICT
SM14624,-97.0823,17.7673,San Pedro Jocotipac,ADM2,DISTRICT
SM14625,-97.1042,16.3264,San Pedro Juchatengo,ADM2,DISTRICT
SM275326,-91.2761,14.6438,San Pedro La Laguna,ADM2,DISTRICT
SM282136,-104.7403,21.1904,San Pedro Lagunillas,ADM2,DISTRICT
SM14626,-96.7134,16.7446,San Pedro Martir,ADM2,DISTRICT
SM14627,-96.2472,16.4131,San Pedro Martir Quiechapa,ADM2,DISTRICT
SM14628,-97.6213,17.4386,San Pedro Martir Yucuxaco,ADM2,DISTRICT
SM295685,-89.0333,13.5167,San Pedro Masahuat,ADM2,DISTRICT
SM14629,-97.0816,15.9377,San Pedro Mixtepec -Dto. 22 -,ADM2,DISTRICT
SM14630,-96.2172,16.2151,San Pedro Mixtepec -Dto. 26 -,ADM2,DISTRICT
SM243801,-97.5415,17.105,San Pedro Molinos,DIALECT,LANGUAGE
SM14631,-97.5517,17.1003,San Pedro Molinos,ADM2,DISTRICT
SM275485,-91.7782,15.5239,San Pedro Necta,ADM2,DISTRICT
SM295686,-88.9333,13.6167,San Pedro Nonualco,ADM2,DISTRICT
SM14632,-97.5475,17.8096,San Pedro Nopala,ADM2,DISTRICT
SM14633,-96.9128,18.1842,San Pedro Ocopetatillo,ADM2,DISTRICT
SM14634,-95.8426,16.9649,San Pedro Ocotepec,ADM2,DISTRICT
SM295641,-89.0128,13.7786,San Pedro Perulapán,ADM2,DISTRICT
SM275180,-89.8488,14.7063,San Pedro Pinula,ADM2,DISTRICT
SM14635,-96.4313,15.789,San Pedro Pochutla,ADM2,DISTRICT
SM295499,-89.8043,13.7779,San Pedro Puxtla,ADM2,DISTRICT
SM14636,-96.0372,16.7627,San Pedro Quiatoni,ADM2,DISTRICT
SM243803,-96.0899,16.7608,San Pedro Quiatoni Zapotec,LANGUAGE,LANGUAGE
SM275440,-90.6297,14.6758,San Pedro Sacatepéquez,ADM2,DISTRICT
SM275295,-91.7669,14.962,San Pedro Sacatepéquez,ADM2,DISTRICT
SM14637,-96.6456,17.8143,San Pedro Sochiapam,ADM2,DISTRICT
SM275884,-88.0789,15.5142,San Pedro Sula,ADM2,DISTRICT
SM14638,-94.285,16.3042,San Pedro Tapanatepec,ADM2,DISTRICT
SM14639,-96.5335,16.6488,San Pedro Taviche,ADM2,DISTRICT
SM14640,-97.3025,17.0029,San Pedro Teozacoalco,ADM2,DISTRICT
SM14641,-96.6287,17.9903,San Pedro Teutila,ADM2,DISTRICT
SM14642,-97.3799,17.3394,San Pedro Tidaa,ADM2,DISTRICT
SM14643,-97.3462,17.4432,San Pedro Topiltepec,ADM2,DISTRICT
SM14644,-96.2354,16.664,San Pedro Totolapam,ADM2,DISTRICT
SM14645,-96.3642,17.4101,San Pedro Yaneri,ADM2,DISTRICT
SM282278,-98.0649,18.0788,San Pedro Yeloixtlahuaca,ADM2,DISTRICT
SM14646,-96.4832,17.6252,San Pedro Yolox,ADM2,DISTRICT
SM14647,-97.4814,17.5632,San Pedro Yucunama,ADM2,DISTRICT
SM275781,-88.1094,14.7462,San Pedro Zacapa,ADM2,DISTRICT
SM270348,-77.1298,1.5553,San Pedro de Cartago,ADM2,DISTRICT
SM273595,-77.7087,0.6165,San Pedro de Huaca,ADM2,DISTRICT
SM285525,-85.1052,12.1665,San Pedro de Lóvago,ADM2,DISTRICT
SM271853,-69.2894,18.4692,San Pedro de Macorís,ADM2,DISTRICT
SM3092,-69.3333,18.5,San Pedro de Macorís,ADM1,DISTRICT
SM273572,-78.5333,-1.3167,San Pedro de Pelileo,ADM2,DISTRICT
SM275684,-87.8514,14.2454,San Pedro de Tutule,ADM2,DISTRICT
SM270267,-76.3175,8.4229,San Pedro de Urabá,ADM2,DISTRICT
SM282602,-109.7249,29.2875,San Pedro de la Cueva,ADM2,DISTRICT
SM270266,-75.5645,6.4654,San Pedro de los Milagros,ADM2,DISTRICT
SM281519,-104.3882,25.5989,San Pedro del Gallo,ADM2,DISTRICT
SM5164,-86.8784,13.2736,San Pedro del Norte (Excl. Santo Tomas del Norte),ADM2,DISTRICT
SM289580,-56.0086,-26.7301,San Pedro del Paraná,ADM2,DISTRICT
SM289641,-57.0595,-23.7722,San Pedro del Ycuamandyyú,ADM2,DISTRICT
SM14648,-96.4759,16.0108,San Pedro el Alto,ADM2,DISTRICT
SM14649,-96.1358,17.0259,San Pedro y San Pablo Ayutla,ADM2,DISTRICT
SM14650,-97.4884,17.5018,San Pedro y San Pablo Teposcolula,ADM2,DISTRICT
SM14651,-97.7066,18.0862,San Pedro y San Pablo Tequixtepec,ADM2,DISTRICT
SM10463,-75.9167,9,San Pelayo (1997-),ADM2,DISTRICT
SM14652,-96.9156,20.2087,San Rafael,ADM2,DISTRICT
SM275711,-88.4167,14.7333,San Rafael,ADM2,DISTRICT
SM295753,-88.3254,13.3856,San Rafael,ADM2,DISTRICT
SM295631,-89.0382,14.1227,San Rafael,ADM2,DISTRICT
SM270939,-84.0755,10.0465,San Rafael,ADM2,DISTRICT
SM270268,-75.0243,6.3123,San Rafael,ADM2,DISTRICT
SM260886,-68.8333,-35,San Rafael,ADM2,DISTRICT
SM295642,-88.878,13.7423,San Rafael Cedros,ADM2,DISTRICT
SM275487,-91.5333,15.7066,San Rafael La Independencia,ADM2,DISTRICT
SM295687,-88.924,13.4931,San Rafael Obrajuelo,ADM2,DISTRICT
SM275488,-91.6636,15.4054,San Rafael Petzal,ADM2,DISTRICT
SM303767,-70.5897,9.3076,San Rafael de Carvajal,ADM2,DISTRICT
SM303682,-68.9734,9.6774,San Rafael de Onoto,ADM2,DISTRICT
SM285542,-86.0835,13.2421,San Rafael del Norte,ADM2,DISTRICT
SM289581,-55.1006,-26.4397,San Rafael del Paraná,ADM2,DISTRICT
SM285438,-86.442,11.8621,San Rafael del Sur,ADM2,DISTRICT
SM271776,-68.6667,18.3833,San Rafael del Yuma,ADM2,DISTRICT
SM275310,-90.1612,14.4545,San Rafaél Las Flores,ADM2,DISTRICT
SM275296,-91.9097,14.9272,San Rafaél Pie de la Cuesta,ADM2,DISTRICT
SM300158,-55.994,-34.3125,San Ramon,ADM2,DISTRICT
SM285452,-85.7266,12.9294,San Ramón,ADM2,DISTRICT
SM295643,-88.927,13.6797,San Ramón,ADM2,DISTRICT
SM270902,-84.4704,10.0872,San Ramón,ADM2,DISTRICT
SM275441,-90.5578,14.7775,San Raymundo,ADM2,DISTRICT
SM14653,-96.7529,16.9842,San Raymundo Jalpan,ADM2,DISTRICT
SM286824,-70.5,-15.8333,San Román,ADM2,DISTRICT
SM270269,-74.9525,6.4442,San Roque,ADM2,DISTRICT
SM261163,-58.75,-28.6667,San Roque,ADM2,DISTRICT
SM289621,-57.2975,-25.8756,San Roque González de Santa Cruz,ADM2,DISTRICT
SM302758,-98.8176,31.1552,San Saba,ADM2,DISTRICT
SM296886,99.0405,18.941,San Sai,ADM2,DISTRICT
SM289552,-56.4268,-25.9577,San Salvador,ADM2,DISTRICT
SM281704,-99.0329,20.2928,San Salvador,ADM2,DISTRICT
SM295517,-89.15,13.7,San Salvador,ADM2,DISTRICT
SM3094,-89.1833,13.7833,San Salvador,ADM1,DISTRICT
SM3093,-74.4931,24.0367,San Salvador,ADM1,DISTRICT
SM16196,-66.0473,18.1373,San Salvador Barrio,ADM2,DISTRICT
SM282282,-97.7729,18.9229,San Salvador Huixcolotla,ADM2,DISTRICT
SM282280,-97.6188,19.1702,San Salvador el Seco,ADM2,DISTRICT
SM282281,-98.5106,19.273,San Salvador el Verde,ADM2,DISTRICT
SM275712,-88.7349,14.3459,San Sebastian,ADM2,DISTRICT
SM14654,-96.6391,16.9864,San Sebastian Abasolo,ADM2,DISTRICT
SM16197,-66.99,18.3368,San Sebastian Barrio-Pueblo,ADM2,DISTRICT
SM14655,-96.8872,16.107,San Sebastian Coatlan,ADM2,DISTRICT
SM14656,-98.1429,16.5218,San Sebastian Ixcapa,ADM2,DISTRICT
SM14657,-97.6837,17.5166,San Sebastian Nicananduta,ADM2,DISTRICT
SM14658,-96.4407,16.1902,San Sebastian Rio Hondo,ADM2,DISTRICT
SM14659,-98.0803,17.3551,San Sebastian Tecomaxtlahuaca,ADM2,DISTRICT
SM14660,-96.6192,16.9522,San Sebastian Teitipac,ADM2,DISTRICT
SM14661,-96.6816,17.0476,San Sebastian Tutla,ADM2,DISTRICT
SM270586,-74.9077,5.2427,San Sebastian de Mariquita,ADM2,DISTRICT
SM303826,-67.1667,10,San Sebastián,ADM2,DISTRICT
SM275848,-87.6322,14.2369,San Sebastián,ADM2,DISTRICT
SM275262,-91.6496,14.566,San Sebastián,ADM2,DISTRICT
SM295540,-88.6833,13.7167,San Sebastián,ADM2,DISTRICT
SM269871,-76.6667,1.9167,San Sebastián,ADM2,DISTRICT
SM3095,-66.9746,18.3291,San Sebastián,ADM1,DISTRICT
SM275489,-91.6191,15.7857,San Sebastián Coatán,ADM2,DISTRICT
SM275490,-91.5741,15.4071,San Sebastián Huehuetenango,ADM2,DISTRICT
SM295545,-89.6321,13.9693,San Sebastián Salitrillo,ADM2,DISTRICT
SM282283,-96.8331,18.3652,San Sebastián Tlacotepec,ADM2,DISTRICT
SM270134,-74.2152,9.3324,San Sebastián de Buenavista,ADM2,DISTRICT
SM285543,-86.1429,13.3756,San Sebastián de Yalí,ADM2,DISTRICT
SM281845,-104.819,20.8511,San Sebastián del Oeste,ADM2,DISTRICT
SM275297,-92.0553,15.1358,San Sibinal,ADM2,DISTRICT
SM14662,-96.7117,16.4167,San Simon Almolongas,ADM2,DISTRICT
SM14663,-98.0013,17.8337,San Simon Zahuatlan,ADM2,DISTRICT
SM295731,-88.2384,13.809,San Simón,ADM2,DISTRICT
SM243812,-98.0051,17.8291,San Simón Zahuatlán,DIALECT,LANGUAGE
SM281961,-100.0367,18.9794,San Simón de Guerrero,ADM2,DISTRICT
SM17636,-60.9899,14.017,San Soucis,ADM2,DISTRICT
SM295541,-88.75,13.4833,San Vicente,ADM2,DISTRICT
SM273501,-80.4027,-0.6044,San Vicente,ADM2,DISTRICT
SM270270,-75.3302,6.3224,San Vicente,ADM2,DISTRICT
SM3096,-88.75,13.5167,San Vicente,ADM1,DISTRICT
SM275782,-88.3022,14.8825,San Vicente Centenario,ADM2,DISTRICT
SM14664,-96.848,16.3792,San Vicente Coatlan,ADM2,DISTRICT
SM243870,-96.8667,16.2817,San Vicente Coatlán Zapotec,LANGUAGE,LANGUAGE
SM14665,-97.0158,16.6819,San Vicente Lachixio,ADM2,DISTRICT
SM14666,-97.4431,17.441,San Vicente Nunu,ADM2,DISTRICT
SM275425,-90.6448,14.3325,San Vicente Pacaya,ADM2,DISTRICT
SM282499,-98.5893,21.786,San Vicente Tancuayalab,ADM2,DISTRICT
SM270512,-73.417,6.884,San Vicente de Chucurí (1995-),ADM2,DISTRICT
SM270868,-74.5178,1.578,San Vicente del Caguán,ADM2,DISTRICT
SM270135,-74.3291,9.3269,San Zenón,ADM2,DISTRICT
SM283402,14.4894,35.9172,San Ġiljan,ADM1:ADM2,DISTRICT
SM283403,14.475,35.9089,San Ġwann,ADM1:ADM2,DISTRICT
SM279579,131.1542,34.0396,San'yō-Onoda,ADM2,DISTRICT
SM269477,-6.6503,4.7677,San-Pédro,ADM2,DISTRICT
SM3097,44.4249,15.2607,Sanaa (excl. Sanaa city),ADM1,DISTRICT
SM3098,48,10.5,Sanaag,ADM1,DISTRICT
SM243681,44.8412,15.6552,Sanaani Arabic,LANGUAGE,LANGUAGE
SM269527,10.3333,4,Sanaga Maritime,ADM2,DISTRICT
SM260870,-67.0833,-29.2,Sanagasta,ADM2,DISTRICT
SM279415,134.4519,33.9823,Sanagōchi,ADM2,DISTRICT
SM283000,-89.2109,20.7759,Sanahcat,ADM2,DISTRICT
SM297524,101.6548,13.6261,Sanam Chaikhet,ADM2,DISTRICT
SM280758,106.4133,14.7381,Sanamxay,ADM2,DISTRICT
SM277553,46.8333,35.3333,Sanandaj,ADM2,DISTRICT
SM292017,21.1862,45.8668,Sanandrei,ADM2,DISTRICT
SM265870,-51.8205,-27.9314,Sananduva,ADM2,DISTRICT
SM257331,-61.05,-21.75,Sanapana,ETHNICITY,ETHNICITY
SM243684,-58.38,-22.49,Sanapaná,LANGUAGE,LANGUAGE
SM243673,-58.907,-22.1145,Sanapaná (Retired),LANGUAGE,LANGUAGE
SM275422,-90.2127,14.8214,Sanarate,ADM2,DISTRICT
SM280857,105.737,15.3411,Sanasomboon,ADM2,DISTRICT
SM280958,-9.8978,7.047,Sanayea,ADM2,DISTRICT
SM302528,-98.0914,44.0234,Sanborn,ADM2,DISTRICT
SM298513,29.2432,40.9895,Sancaktepe,ADM2,DISTRICT
SM289829,23.9479,46.2118,Sancel,ADM2,DISTRICT
SM280550,127.8707,35.3663,Sancheong,ADM2,DISTRICT
SM293281,47.3333,57,Sanchurskiy rayon,ADM2,DISTRICT
SM268150,-50.3721,-16.2822,Sanclerlândia,ADM2,DISTRICT
SM290128,22.9914,46.8341,Sancraiu,ADM2,DISTRICT
SM291182,24.5247,46.5473,Sancraiu De Mures,ADM2,DISTRICT
SM271042,-79.5104,21.8218,Sancti Spíritus,ADM2,DISTRICT
SM3100,-79.4167,21.9167,Sancti Spíritus,ADM1,DISTRICT
SM282697,-98.488,19.5008,Sanctórum de Lázaro Cárdenas,ADM2,DISTRICT
SM274531,-6.806,61.8445,Sand,ADM2,DISTRICT
SM17637,-60.9803,13.9278,Sand De Feu,ADM2,DISTRICT
SM278346,135.2166,34.9551,Sanda,ADM2,DISTRICT
SM284923,8.3636,12.8972,Sandamu,ADM2,DISTRICT
SM280496,105.4138,13.0689,Sandan,ADM2,DISTRICT
SM262289,23.45,41.5667,Sandanski,ADM2,DISTRICT
SM3101,142,-3.5,Sandaun,ADM1,DISTRICT
SM243695,35.4808,-5.2692,Sandawe,LANGUAGE,LANGUAGE
SM250665,35.55,-4.83,Sandawe,ETHNICITY,ETHNICITY
SM250665,35.55,-4.83,Sandawe,ETHNICITY,ETHNICITY
SM250665,35.55,-4.83,Sandawe,ETHNICITY,ETHNICITY
SM250665,36,-5,Sandawe,ETHNICITY,ETHNICITY
SM250665,36,-5,Sandawe,ETHNICITY,ETHNICITY
SM250665,36,-5,Sandawe,ETHNICITY,ETHNICITY
SM250665,35.5,-5.5,Sandawe,ETHNICITY,ETHNICITY
SM250665,35.5,-5.5,Sandawe,ETHNICITY,ETHNICITY
SM250665,35.5,-5.5,Sandawe,ETHNICITY,ETHNICITY
SM286426,5.5797,62.2046,Sande,ADM2,DISTRICT
SM461868,10.2197,59.1288,Sandefjord (2020-),ADM2,DISTRICT
SM301661,-115.1333,47.6748,Sanders,ADM2,DISTRICT
SM295312,160.03,-8.901,Sandfly-Buenavista,ADM2,DISTRICT
SM277830,-22.6566,64.0166,Sandgerði,ADM2,DISTRICT
SM286825,-69.3333,-13.8333,Sandia,ADM2,DISTRICT
SM271028,-84.206,22.0803,Sandino,ADM2,DISTRICT
SM461880,5.7286,58.8476,Sandnes (2020-),ADM2,DISTRICT
SM267167,-49.8549,-12.4074,Sandolândia,ADM2,DISTRICT
SM288753,21.609,50.6951,Sandomierz,ADM2,DISTRICT
SM290714,25.7931,46.5793,Sandominic,ADM2,DISTRICT
SM270349,-77.4828,1.3072,Sandoná,ADM2,DISTRICT
SM301852,-106.8658,35.6886,Sandoval,ADM2,DISTRICT
SM266892,-51.8532,-22.4548,Sandovalina,ADM2,DISTRICT
SM294774,36.4167,58.4167,Sandovskiy rayon,ADM2,DISTRICT
SM3102,-6.75,61.8333,Sandoyar,ADM1,DISTRICT
SM12707,-76.8955,18.3389,Sandside,ADM2,DISTRICT
SM261307,118.9885,-28.4219,Sandstone,ADM2,DISTRICT
SM275065,-14.3667,13.4167,Sandu,ADM2,DISTRICT
SM292150,26.7419,46.4508,Sanduleni,ADM2,DISTRICT
SM290129,23.7305,46.5889,Sandulesti,ADM2,DISTRICT
SM302153,-83.1439,41.3574,Sandusky,ADM2,DISTRICT
SM296627,16.6846,60.5503,Sandviken,ADM2,DISTRICT
SM370525,6.2189,49.6133,Sandweiler,ADM1:ADM2:ADM3,DISTRICT
SM274682,-2,52.5,Sandwell,ADM1:ADM2,DISTRICT
SM12708,-77.1623,17.9511,Sandy Bay (Clarendon),ADM2,DISTRICT
SM18491,-78.0838,18.4375,Sandy Bay (Hanover),ADM2,DISTRICT
SM324082,-114.0404,53.8073,Sandy Beach,ADM2:ADM3,DISTRICT
SM4225,-63.0899,18.204,Sandy Ground,ADM1,DISTRICT
SM4226,-63.0118,18.2207,Sandy Hill,ADM1,DISTRICT
SM279912,68.75,52.5,Sandyktauskiy,ADM2,DISTRICT
SM3103,-64.8683,32.2935,Sandys,ADM1,DISTRICT
SM298367,30.2778,38.4734,Sandıklı,ADM2,DISTRICT
SM370508,5.9363,49.5331,Sanem,ADM1:ADM2:ADM3,DISTRICT
SM297721,103.0511,17.7943,Sang Khom,ADM2,DISTRICT
SM297272,102.2268,18.0539,Sang Khom,ADM2,DISTRICT
SM9579,65.9121,34.1926,Sang-e Takht,ADM2,DISTRICT
SM284821,8.45,9.25,Sanga,ADM2,DISTRICT
SM283957,35.5598,-12.1773,Sanga,ADM2,DISTRICT
SM454374,14.87,0.46,Sanga,ETHNICITY,ETHNICITY
SM454374,14.87,0.46,Sanga,ETHNICITY,ETHNICITY
SM454374,16,2,Sanga,ETHNICITY,ETHNICITY
SM454374,16,2,Sanga,ETHNICITY,ETHNICITY
SM243723,26.8462,-10.0236,Sanga (Democratic Republic of Congo),LANGUAGE,LANGUAGE
SM243721,8.8388,10.4157,Sanga (Nigeria),LANGUAGE,LANGUAGE
SM243730,126.409,7.3953,Sangab Mandaya,LANGUAGE,LANGUAGE
SM300675,-89.6589,39.7582,Sangamon,ADM2,DISTRICT
SM11684,78.0847,17.6289,Sangareddy,ADM2,DISTRICT
SM260204,66.3416,35.8421,Sangcharak,ADM2,DISTRICT
SM291183,24.6459,46.5799,Sangeorgiu De Mures,ADM2,DISTRICT
SM291185,24.8932,46.4148,Sangeorgiu De Padure,ADM2,DISTRICT
SM292524,24.6698,47.3778,Sangeorz-bai,ADM2,DISTRICT
SM291186,24.1509,46.5441,Sanger,ADM2,DISTRICT
SM291564,26.3539,45.1433,Sangeru,ADM2,DISTRICT
SM243731,110.404,-0.1315,Sanggau,LANGUAGE,LANGUAGE
SM276379,110.45,0.2547,Sanggau,ADM2,DISTRICT
SM3104,15.5,1.3333,Sangha,ADM1,DISTRICT
SM3105,16.1667,3.5,Sangha-Mbaéré,ADM1,DISTRICT
SM375499,69.2936,25.9796,Sanghar,ADM2:ADM3,DISTRICT
SM243729,125.378,5.4023,Sangil,LANGUAGE,LANGUAGE
SM259999,64.9965,32.1236,Sangin,ADM2,DISTRICT
SM243728,125.4811,5.4449,Sangir,LANGUAGE,LANGUAGE
SM243728,125.4811,5.4449,Sangir,LANGUAGE,LANGUAGE
SM243728,125.5,3,Sangir,LANGUAGE,LANGUAGE
SM243728,125.5,3,Sangir,LANGUAGE,LANGUAGE
SM243713,53.3,35.72,Sangisari,LANGUAGE,LANGUAGE
SM280738,128.1667,36.4167,Sangju,ADM2,DISTRICT
SM280415,103.42,13.06,Sangkae,ADM2,DISTRICT
SM297642,103.8391,14.5705,Sangkha,ADM2,DISTRICT
SM296974,98.5279,15.1934,Sangkhla Buri,ADM2,DISTRICT
SM280392,104.7705,13.474,Sangkom Thmei,ADM2,DISTRICT
SM243715,100.77,21.64,Sangkong,LANGUAGE,LANGUAGE
SM243736,71.3,36.45,Sanglechi,LANGUAGE,LANGUAGE
SM243735,71.6743,36.6354,Sanglechi-Ishkashimi,FAMILY,LANGUAGE
SM277053,74.7699,17.112,Sangli,ADM2,DISTRICT
SM243720,20.1702,6.7858,Sango,LANGUAGE,LANGUAGE
SM3106,-61.1177,10.5835,Sangre Grande (1992-),ADM1,DISTRICT
SM277153,75.85,30.25,Sangrur (2006-),ADM2,DISTRICT
SM243716,94.6279,25.8581,Sangtam Naga,LANGUAGE,LANGUAGE
SM280795,102.1852,18.2628,Sangthong,ADM2,DISTRICT
SM250667,34.03,-6.92,Sangu,ETHNICITY,ETHNICITY
SM250667,34.03,-6.92,Sangu,ETHNICITY,ETHNICITY
SM250667,34,-8,Sangu,ETHNICITY,ETHNICITY
SM250667,34,-8,Sangu,ETHNICITY,ETHNICITY
SM243725,11.9098,-1.5269,Sangu (Gabon),LANGUAGE,LANGUAGE
SM243722,33.9,-8.4,Sangu (Tanzania),LANGUAGE,LANGUAGE
SM12709,-77.4294,18.1559,Sanguinetti,ADM2,DISTRICT
SM262209,-2.6667,12.1667,Sanguié,ADM1:ADM2,DISTRICT
SM289079,126.0708,38.8603,Sangwŏn,ADM2,DISTRICT
SM266282,-49.1221,-28.6701,Sangão,ADM2,DISTRICT
SM257342,-4.6,32.7,Sanhaja,ETHNICITY,ETHNICITY
SM304941,44.3057,15.2252,Sanhan,ADM2,DISTRICT
SM264887,-36.5217,-8.2919,Sanharó,ADM2,DISTRICT
SM243742,103.759,24.278,Sani,LANGUAGE,LANGUAGE
SM11027,33.1905,34.8028,Sanida,ADM2,DISTRICT
SM243739,102.12,25.2,Sanie,LANGUAGE,LANGUAGE
SM301329,-82.6458,43.4433,Sanilac,ADM2,DISTRICT
SM243743,142.369,-4.4376,Saniyo-Hiyewe,LANGUAGE,LANGUAGE
SM278943,139.0898,37.5407,Sanjō,ADM2,DISTRICT
SM243769,-10.569,10.0962,Sankaran Maninka,LANGUAGE,LANGUAGE
SM297668,100.1704,15.0211,Sankha Buri,ADM2,DISTRICT
SM375315,87.2266,27.5479,Sankhuwasabha,ADM2:ADM3,DISTRICT
SM268936,9.3905,47.4634,Sankt Gallen,ADM2,DISTRICT
SM3107,9.25,47.25,Sankt Gallen,ADM1,DISTRICT
SM261849,13.3,47.3,Sankt Johann im Pongau,ADM2,DISTRICT
SM261811,15.6167,48.2,Sankt Pölten,ADM2,DISTRICT
SM261810,15.6651,48.1691,Sankt Pölten Land,ADM2,DISTRICT
SM261798,14.4,46.8333,Sankt Veit an der Glan,ADM2,DISTRICT
SM3108,23.45,-4.9667,Sankuru,ADM1:ADM2,DISTRICT
SM3109,38.7817,37.2128,Sanliurfa,ADM1,DISTRICT
SM3110,166.75,-15.3333,Sanma,ADM1,DISTRICT
SM290130,24.0734,47.0071,Sanmartin,ADM2,DISTRICT
SM292465,21.9857,46.9906,Sanmartin,ADM2,DISTRICT
SM262207,-1.0833,13.25,Sanmatenga,ADM1:ADM2,DISTRICT
SM269162,110.915,34.5261,Sanmenxia,ADM2,DISTRICT
SM291632,23.2145,47.0382,Sanmihaiu Almasului,ADM2,DISTRICT
SM292525,24.3432,46.8964,Sanmihaiu De Campie,ADM2,DISTRICT
SM292018,21.0835,45.71,Sanmihaiu Roman,ADM2,DISTRICT
SM269399,117.3367,26.2854,Sanming,ADM2,DISTRICT
SM279380,140.4208,35.624,Sanmu,ADM2,DISTRICT
SM283408,14.2514,36.0231,Sannat,ADM1:ADM2,DISTRICT
SM292019,20.6228,46.0729,Sannicolau Mare,ADM2,DISTRICT
SM14100,-8.7401,7.3604,Sanniquelleh-Mahn (geonames),ADM2,DISTRICT
SM14100,-8.7401,7.3604,Sanniquelleh-Mahn (geonames),ADM2,DISTRICT
SM14100,-8.7179,7.3873,Sanniquelleh-Mahn (geonames),ADM2,DISTRICT
SM14100,-8.7179,7.3873,Sanniquelleh-Mahn (geonames),ADM2,DISTRICT
SM13067,141.2213,40.4057,Sannohe Gun,ADM2,DISTRICT
SM279331,139.5358,36.4241,Sano,ADM2,DISTRICT
SM289018,22.1362,49.43,Sanok,ADM2,DISTRICT
SM297643,103.7848,15.171,Sanom,ADM2,DISTRICT
SM291187,24.3629,46.4631,Sanpaul,ADM2,DISTRICT
SM290131,23.4173,46.896,Sanpaul,ADM2,DISTRICT
SM302905,-111.5763,39.374,Sanpete,ADM2,DISTRICT
SM292650,25.6368,45.7108,Sanpetru,ADM2,DISTRICT
SM291188,24.2667,46.7167,Sanpetru De Campie,ADM2,DISTRICT
SM297669,100.2551,15.1487,Sanphaya,ADM2,DISTRICT
SM257346,-119.95,47.96,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-119.95,47.96,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-119.95,47.96,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-119.95,47.96,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-119.95,47.96,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.75,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.75,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.75,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.75,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.75,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-119,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-119,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-119,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-119,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-119,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.44,47.94,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.44,47.94,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.44,47.94,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.44,47.94,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.44,47.94,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.7,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.7,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.7,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.7,48,Sanpoil,ETHNICITY,ETHNICITY
SM257346,-118.7,48,Sanpoil,ETHNICITY,ETHNICITY
SM14101,-9.1791,5.227,Sanquin District 2 (excl. Tasu),ADM2,DISTRICT
SM14102,-9.2128,5.5083,Sanquin District Number One,ADM2,DISTRICT
SM14103,-9.3045,5.312,Sanquin District Number Three (excl. Toto),ADM2,DISTRICT
SM275423,-90.0982,14.7531,Sansare,ADM2,DISTRICT
SM10362,112.3344,16.8339,Sansha,ADM2,DISTRICT
SM243809,72.6838,28.7364,Sansi,LANGUAGE,LANGUAGE
SM290716,25.892,46.2502,Sansimion,ADM2,DISTRICT
SM243808,77,20,Sanskrit,LANGUAGE,LANGUAGE
SM243814,100.099,21.6402,Sansu,LANGUAGE,LANGUAGE
SM292526,24.9184,47.4527,Sant,ADM2,DISTRICT
SM283642,103.8393,46.0861,Sant,ADM2,DISTRICT
SM283657,105.3743,49.2393,Sant,ADM2,DISTRICT
SM3111,1.4825,42.4625,Sant Julià de Lòria,ADM1,DISTRICT
SM277329,83.0348,26.7902,Sant Kabir Nagar,ADM2,DISTRICT
SM277330,82.4308,25.3579,Sant Ravi Das Nagar,ADM2,DISTRICT
SM286799,-78.25,-9,Santa,ADM2,DISTRICT
SM266893,-48.8271,-21.325,Santa Adélia,ADM2,DISTRICT
SM266894,-50.7178,-20.035,Santa Albertina,ADM2,DISTRICT
SM264691,-50.4133,-23.2604,Santa Amélia,ADM2,DISTRICT
SM303706,-64.654,9.3058,Santa Ana,ADM2,DISTRICT
SM295406,162.47,-10.8314,Santa Ana,ADM2,DISTRICT
SM282603,-111.1234,30.5118,Santa Ana,ADM2,DISTRICT
SM14667,-96.7171,16.3492,Santa Ana,ADM2,DISTRICT
SM275928,-87.21,13.94,Santa Ana,ADM2,DISTRICT
SM275685,-87.9411,14.0001,Santa Ana,ADM2,DISTRICT
SM275200,-89.6688,16.7731,Santa Ana,ADM2,DISTRICT
SM295546,-89.5,14,Santa Ana,ADM2,DISTRICT
SM273503,-80.3833,-1.2167,Santa Ana,ADM2,DISTRICT
SM270966,-84.1939,9.9249,Santa Ana,ADM2,DISTRICT
SM3112,-89.5833,14.1,Santa Ana,ADM1,DISTRICT
SM257352,-107,35,Santa Ana,ETHNICITY,ETHNICITY
SM257352,-107,35,Santa Ana,ETHNICITY,ETHNICITY
SM257352,-106.4,35.2,Santa Ana,ETHNICITY,ETHNICITY
SM257352,-106.4,35.2,Santa Ana,ETHNICITY,ETHNICITY
SM10464,-74.3433,9.5148,Santa Ana (2000-),ADM2,DISTRICT
SM14668,-96.8785,18.23,Santa Ana Ateixtlahuaca,ADM2,DISTRICT
SM14669,-96.7977,17.9739,Santa Ana Cuauhtemoc,ADM2,DISTRICT
SM275491,-91.8651,15.7103,Santa Ana Huista,ADM2,DISTRICT
SM282077,-101.0421,20.0415,Santa Ana Maya,ADM2,DISTRICT
SM14670,-98.3331,19.2976,Santa Ana Nopalucan,ADM2,DISTRICT
SM14671,-95.9056,16.6526,Santa Ana Tavela,ADM2,DISTRICT
SM14672,-96.8558,16.7428,Santa Ana Tlapacoyan,ADM2,DISTRICT
SM14673,-96.6242,17.3928,Santa Ana Yareni,ADM2,DISTRICT
SM14674,-96.7271,16.8398,Santa Ana Zegache,ADM2,DISTRICT
SM243839,-76.2753,-10.3302,Santa Ana de Tusi Pasco Quechua,LANGUAGE,LANGUAGE
SM275820,-87.1035,13.2992,Santa Ana de Yusguare,ADM2,DISTRICT
SM14675,-96.4717,16.9936,Santa Ana del Valle,ADM2,DISTRICT
SM275392,-90.9566,14.8196,Santa Apolonia,ADM2,DISTRICT
SM14676,-98.3127,19.2462,Santa Apolonia Teacalco,ADM2,DISTRICT
SM303658,-63.6081,9.6062,Santa Barbara,ADM2,DISTRICT
SM303199,-120.0308,34.5383,Santa Barbara,ADM2,DISTRICT
SM10465,-75.5826,5.8894,Santa Barbara (1997-),ADM2,DISTRICT
SM10466,-74.6491,9.5211,Santa Barbara de Pinto,ADM2,DISTRICT
SM266896,-45.8681,-23.4221,Santa Branca,ADM2,DISTRICT
SM267629,-38.1137,-9.679,Santa Brígida,ADM2,DISTRICT
SM283267,-105.7715,26.8207,Santa Bárbara,ADM2,DISTRICT
SM275783,-88.16,14.9,Santa Bárbara,ADM2,DISTRICT
SM275492,-91.6341,15.33,Santa Bárbara,ADM2,DISTRICT
SM275359,-91.2469,14.4732,Santa Bárbara,ADM2,DISTRICT
SM270940,-84.15,10.0833,Santa Bárbara,ADM2,DISTRICT
SM270350,-77.9149,2.3019,Santa Bárbara,ADM2,DISTRICT
SM270513,-72.9103,6.9928,Santa Bárbara,ADM2,DISTRICT
SM263860,-43.445,-20.0236,Santa Bárbara,ADM2,DISTRICT
SM267628,-38.9921,-11.9177,Santa Bárbara,ADM2,DISTRICT
SM260825,-64.4,-23.8333,Santa Bárbara,ADM2,DISTRICT
SM3113,-88.3333,15.0833,Santa Bárbara,ADM1,DISTRICT
SM266895,-47.4396,-22.7958,Santa Bárbara d'Oeste,ADM2,DISTRICT
SM268151,-49.6862,-16.6023,Santa Bárbara de Goiás,ADM2,DISTRICT
SM271830,-69.3333,19.2333,Santa Bárbara de Samaná,ADM2,DISTRICT
SM315445,-42.1033,-19.9406,Santa Bárbara do Leste,ADM2:ADM3,DISTRICT
SM263858,-43.6932,-21.9769,Santa Bárbara do Monte Verde,ADM2,DISTRICT
SM264087,-48.251,-1.1983,Santa Bárbara do Pará,ADM2,DISTRICT
SM265871,-53.2574,-28.3743,Santa Bárbara do Sul,ADM2,DISTRICT
SM263859,-43.5238,-21.239,Santa Bárbara do Tugúrio Part,ADM2,DISTRICT
SM263105,-54.8077,-11.906,Santa Carmem,ADM2,DISTRICT
SM295407,162.4462,-10.8912,Santa Catalina,ADM2,DISTRICT
SM260826,-66.145,-22.0872,Santa Catalina,ADM2,DISTRICT
SM10467,-75.2956,10.6045,Santa Catalina (1994-),ADM2,DISTRICT
SM16198,-66.4055,18.101,Santa Catalina Barrio,ADM2,DISTRICT
SM14677,-96.2669,16.3197,Santa Catalina Quieri,ADM2,DISTRICT
SM282500,-99.4284,21.5733,Santa Catarina,ADM2,DISTRICT
SM282192,-100.482,25.6008,Santa Catarina,ADM2,DISTRICT
SM281561,-100.0695,21.1513,Santa Catarina,ADM2,DISTRICT
SM10070,-23.7082,15.1055,Santa Catarina,ADM2,DISTRICT
SM3115,-23.7,15.1,Santa Catarina,ADM1,DISTRICT
SM3114,-50,-27,Santa Catarina,ADM1,DISTRICT
SM243854,-96.3512,17.0446,Santa Catarina Albarradas Zapotec,LANGUAGE,LANGUAGE
SM14678,-98.2136,19.1983,Santa Catarina Ayometla,ADM2,DISTRICT
SM275266,-90.806,14.5551,Santa Catarina Barahona,ADM2,DISTRICT
SM14679,-96.6447,16.3051,Santa Catarina Cuixtla,ADM2,DISTRICT
SM275327,-91.3798,14.7202,Santa Catarina Ixtahuacan,ADM2,DISTRICT
SM14680,-96.5794,17.2124,Santa Catarina Ixtepeji,ADM2,DISTRICT
SM14681,-97.3061,16.1531,Santa Catarina Juquila,ADM2,DISTRICT
SM11528,-89.834,15.2536,Santa Catarina La Tinta,ADM2,DISTRICT
SM14682,-96.5013,17.1841,Santa Catarina Lachatao,ADM2,DISTRICT
SM14683,-96.7194,16.0633,Santa Catarina Loxicha,ADM2,DISTRICT
SM295561,-89.7737,13.782,Santa Catarina Masahuat,ADM2,DISTRICT
SM14684,-97.844,16.3253,Santa Catarina Mechoacan,ADM2,DISTRICT
SM14685,-96.6253,16.7773,Santa Catarina Minas,ADM2,DISTRICT
SM275187,-89.7713,14.4355,Santa Catarina Mita,ADM2,DISTRICT
SM275328,-91.1306,14.7179,Santa Catarina Palopó,ADM2,DISTRICT
SM275442,-90.4634,14.5605,Santa Catarina Pinula,ADM2,DISTRICT
SM14686,-96.7376,16.8881,Santa Catarina Quiane,ADM2,DISTRICT
SM14687,-96.2844,16.3147,Santa Catarina Quioquitani,ADM2,DISTRICT
SM14688,-97.5571,17.3472,Santa Catarina Tayata,ADM2,DISTRICT
SM14689,-97.5351,17.0663,Santa Catarina Ticua,ADM2,DISTRICT
SM243849,-97.5364,17.0733,Santa Catarina Ticuá,DIALECT,LANGUAGE
SM282284,-98.0808,18.6158,Santa Catarina Tlaltempan,ADM2,DISTRICT
SM243844,-97.662,17.0207,Santa Catarina Yosonotu,DIALECT,LANGUAGE
SM14690,-97.6699,17.0079,Santa Catarina Yosonotu,ADM2,DISTRICT
SM14691,-97.5884,18.0567,Santa Catarina Zapoquila,ADM2,DISTRICT
SM10071,-24.3385,14.8957,Santa Catarina do Fogo,ADM2,DISTRICT
SM3116,-24.3333,14.9,Santa Catarina do Fogo,ADM1,DISTRICT
SM10001,-51.924,-28.1726,Santa Cecilia Do Sul,ADM2,DISTRICT
SM266283,-50.4611,-26.9328,Santa Cecília,ADM2,DISTRICT
SM264295,-35.9368,-7.7203,Santa Cecília,ADM2,DISTRICT
SM264692,-50.8228,-23.5444,Santa Cecília do Pavão,ADM2,DISTRICT
SM303200,-121.6963,37.2325,Santa Clara,ADM2,DISTRICT
SM281520,-103.407,24.4985,Santa Clara,ADM2,DISTRICT
SM295542,-88.6613,13.724,Santa Clara,ADM2,DISTRICT
SM273538,-77.8965,-1.267,Santa Clara,ADM2,DISTRICT
SM271058,-79.9667,22.4,Santa Clara,ADM2,DISTRICT
SM257353,-106,36,Santa Clara,ETHNICITY,ETHNICITY
SM257353,-106,36,Santa Clara,ETHNICITY,ETHNICITY
SM257353,-106.3,36,Santa Clara,ETHNICITY,ETHNICITY
SM257353,-106.3,36,Santa Clara,ETHNICITY,ETHNICITY
SM275329,-91.2974,14.7245,Santa Clara La Laguna,ADM2,DISTRICT
SM266897,-50.8987,-20.0753,Santa Clara do Oeste,ADM2,DISTRICT
SM265872,-52.1436,-29.4558,Santa Clara do Sul,ADM2,DISTRICT
SM289400,-8.1138,40.4044,Santa Comba Dão,ADM2,DISTRICT
SM303201,-122.0098,37.0216,Santa Cruz,ADM2,DISTRICT
SM301792,-110.8466,31.526,Santa Cruz,ADM2,DISTRICT
SM289284,-16.8309,32.675,Santa Cruz,ADM2,DISTRICT
SM286886,-79,-6.8333,Santa Cruz,ADM2,DISTRICT
SM282604,-110.577,31.2085,Santa Cruz,ADM2,DISTRICT
SM12710,-77.6909,18.0511,Santa Cruz,ADM2,DISTRICT
SM275713,-88.5268,14.3443,Santa Cruz,ADM2,DISTRICT
SM273638,-90.333,-0.6296,Santa Cruz,ADM2,DISTRICT
SM270922,-85.6737,10.2377,Santa Cruz,ADM2,DISTRICT
SM270351,-77.7,1.3333,Santa Cruz,ADM2,DISTRICT
SM264891,-40.3189,-8.3062,Santa Cruz,ADM2,DISTRICT
SM3119,-23.5667,15.1,Santa Cruz,ADM1,DISTRICT
SM3118,-61.5,-17.5,Santa Cruz,ADM1:ADM2,DISTRICT
SM3117,-70,-49,Santa Cruz,ADM1,DISTRICT
SM10002,-38.0497,-6.5454,Santa Cruz  (Paraiba),ADM2,DISTRICT
SM10003,-35.9986,-6.1874,Santa Cruz (Rio Grande do Norte),ADM2,DISTRICT
SM14692,-96.8834,18.1574,Santa Cruz Acatepec,ADM2,DISTRICT
SM14693,-96.6833,17.0583,Santa Cruz Amilpas,ADM2,DISTRICT
SM295644,-88.9582,13.6574,Santa Cruz Analquito,ADM2,DISTRICT
SM275393,-90.9326,14.6941,Santa Cruz Balanyá,ADM2,DISTRICT
SM275493,-91.2212,15.9316,Santa Cruz Barillas,ADM2,DISTRICT
SM16199,-65.9398,18.3491,Santa Cruz Barrio,ADM2,DISTRICT
SM267630,-39.1726,-16.2785,Santa Cruz Cabrália,ADM2,DISTRICT
SM10004,-52.498,-10.156,Santa Cruz Do Xingu,ADM2:ADM3,DISTRICT
SM275350,-90.4873,14.9527,Santa Cruz El Chol,ADM2,DISTRICT
SM257354,166.06,-10.72,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,166.06,-10.72,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,166.06,-10.72,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,166.06,-10.72,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,165.799,-10.724,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,165.799,-10.724,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,165.799,-10.724,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,165.799,-10.724,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,165.85,-10.73,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,165.85,-10.73,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,165.85,-10.73,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,165.85,-10.73,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,166.8593,-11.6107,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,166.8593,-11.6107,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,166.8593,-11.6107,Santa Cruz Island,ETHNICITY,ETHNICITY
SM257354,166.8593,-11.6107,Santa Cruz Island,ETHNICITY,ETHNICITY
SM14694,-97.6684,16.7625,Santa Cruz Itundujia,ADM2,DISTRICT
SM275330,-91.2218,14.7432,Santa Cruz La Laguna,ADM2,DISTRICT
SM295645,-88.9667,13.75,Santa Cruz Michapa,ADM2,DISTRICT
SM14695,-96.8555,16.7811,Santa Cruz Mixtepec,ADM2,DISTRICT
SM275263,-91.6536,14.4645,Santa Cruz Muluá,ADM2,DISTRICT
SM275311,-90.369,14.3831,Santa Cruz Naranjo,ADM2,DISTRICT
SM14696,-97.7176,17.166,Santa Cruz Nundaco,ADM2,DISTRICT
SM14697,-96.5844,16.9567,Santa Cruz Papalutla,ADM2,DISTRICT
SM14698,-98.2038,19.2109,Santa Cruz Quilehtla,ADM2,DISTRICT
SM14699,-98.1502,17.821,Santa Cruz Tacache de Mina,ADM2,DISTRICT
SM14700,-97.4808,16.9203,Santa Cruz Tacahua,ADM2,DISTRICT
SM14701,-97.565,17.3729,Santa Cruz Tayata,ADM2,DISTRICT
SM282698,-98.127,19.3441,Santa Cruz Tlaxcala,ADM2,DISTRICT
SM275160,-90.414,15.3385,Santa Cruz Verapaz,ADM2,DISTRICT
SM14702,-96.6824,16.3496,Santa Cruz Xitla,ADM2,DISTRICT
SM14703,-96.7558,17.024,Santa Cruz Xoxocotlan,ADM2,DISTRICT
SM14704,-97.4827,16.4961,Santa Cruz Zenzontepec,ADM2,DISTRICT
SM264888,-38.1534,-7.8482,Santa Cruz da Baixa Verde,ADM2,DISTRICT
SM266898,-47.4928,-22.1261,Santa Cruz da Conceição,ADM2,DISTRICT
SM266900,-47.4475,-21.2617,Santa Cruz da Esperança,ADM2,DISTRICT
SM289381,-28.0043,39.0495,Santa Cruz da Graciosa,ADM2,DISTRICT
SM267632,-39.7915,-14.9031,Santa Cruz da Vitória,ADM2,DISTRICT
SM289382,-31.1851,39.4698,Santa Cruz das Flores,ADM2,DISTRICT
SM266901,-47.2514,-21.8867,Santa Cruz das Palmeiras,ADM2,DISTRICT
SM271862,-71.0994,18.2134,Santa Cruz de Barahona,ADM2,DISTRICT
SM14705,-98.2236,17.5797,Santa Cruz de Bravo,ADM2,DISTRICT
SM268152,-48.571,-17.4515,Santa Cruz de Goiás,ADM2,DISTRICT
SM281562,-100.9796,20.6366,Santa Cruz de Juventino Rosas,ADM2,DISTRICT
SM269947,-75.8972,9.1761,Santa Cruz de Lorica,ADM2,DISTRICT
SM263861,-44.2137,-21.12,Santa Cruz de Minas,ADM2,DISTRICT
SM264693,-53.3664,-23.1441,Santa Cruz de Monte Caste,ADM2,DISTRICT
SM263862,-41.7992,-16.0618,Santa Cruz de Salinas,ADM2,DISTRICT
SM274051,-17.3333,28.1667,Santa Cruz de Tenerife,ADM2,DISTRICT
SM275885,-87.8289,15.0109,Santa Cruz de Yojoa,ADM2,DISTRICT
SM271015,-81.8633,23.1188,Santa Cruz del Norte,ADM2,DISTRICT
SM275244,-91.126,15.0372,Santa Cruz del Quiché,ADM2,DISTRICT
SM271905,-69,18.75,Santa Cruz del Seybo,ADM2,DISTRICT
SM270980,-78.0374,20.8214,Santa Cruz del Sur,ADM2,DISTRICT
SM264088,-49.2929,-0.596,Santa Cruz do Arari,ADM2,DISTRICT
SM264890,-36.3303,-7.888,Santa Cruz do Capibaribe,ADM2,DISTRICT
SM263863,-42.8211,-20.2334,Santa Cruz do Escalvado,ADM2,DISTRICT
SM265107,-41.7638,-7.2649,Santa Cruz do Piaui,ADM2,DISTRICT
SM266902,-49.5778,-22.7824,Santa Cruz do Rio Pardo,ADM2,DISTRICT
SM265874,-52.411,-29.6153,Santa Cruz do Sul,ADM2,DISTRICT
SM265108,-41.9579,-5.8323,Santa Cruz dos Milagres,ADM2,DISTRICT
SM263864,-42.4003,-18.8623,Santa Efigênia de Minas,ADM2,DISTRICT
SM289536,-56.7806,-25.3955,Santa Elena,ADM2,DISTRICT
SM283001,-89.7127,20.3098,Santa Elena,ADM2,DISTRICT
SM275686,-88.1545,14.0715,Santa Elena,ADM2,DISTRICT
SM295584,-88.4107,13.4074,Santa Elena,ADM2,DISTRICT
SM273556,-80.8667,-2.2333,Santa Elena,ADM2,DISTRICT
SM3120,-80.5833,-2.0833,Santa Elena,ADM1,DISTRICT
SM266903,-48.378,-21.453,Santa Ernestina,ADM2,DISTRICT
SM275494,-91.3254,15.7498,Santa Eulalia,ADM2,DISTRICT
SM301853,-105.9761,35.5069,Santa Fe,ADM2,DISTRICT
SM286676,-80.9586,8.7206,Santa Fe,ADM2,DISTRICT
SM3121,-61,-31,Santa Fe,ADM1,DISTRICT
SM15365,-54.6283,-25.1891,Santa Fe Del Parana,ADM2,DISTRICT
SM270150,-75.9,6.55,Santa Fe de Antioquia,ADM2,DISTRICT
SM265109,-45.6672,-8.8958,Santa Filomena,ADM2,DISTRICT
SM264892,-40.593,-8.288,Santa Filomena,ADM2,DISTRICT
SM268153,-51.1385,-15.623,Santa Filomena do Maranhão,ADM2,DISTRICT
SM275835,-86.1108,15.8542,Santa Fé,ADM2,DISTRICT
SM275731,-89.2732,14.5004,Santa Fé,ADM2,DISTRICT
SM264694,-51.8136,-23.0399,Santa Fé,ADM2,DISTRICT
SM262872,-44.5518,-5.4827,Santa Fé de Goiás,ADM2,DISTRICT
SM263865,-45.5973,-16.6812,Santa Fé de Minas,ADM2,DISTRICT
SM267168,-48.9558,-7.1075,Santa Fé do Araguaia,ADM2,DISTRICT
SM266904,-50.9509,-20.2658,Santa Fé do Sul,ADM2,DISTRICT
SM266905,-47.5207,-22.4762,Santa Gertrudes,ADM2,DISTRICT
SM14706,-96.8019,16.7851,Santa Gertrudis,ADM2,DISTRICT
SM266285,-53.609,-26.9235,Santa Helena,ADM2,DISTRICT
SM264695,-54.2872,-24.9069,Santa Helena,ADM2,DISTRICT
SM264296,-38.5951,-6.7275,Santa Helena,ADM2,DISTRICT
SM262873,-45.3836,-2.4266,Santa Helena,ADM2,DISTRICT
SM268156,-50.5388,-17.8054,Santa Helena de Goiás,ADM2,DISTRICT
SM263866,-40.6621,-16.9029,Santa Helena de Minas,ADM2,DISTRICT
SM270514,-73.6004,6.3969,Santa Helena del Opón,ADM2,DISTRICT
SM14707,-96.7549,16.8044,Santa Ines Yatzeche,ADM2,DISTRICT
SM14708,-97.1512,17.2695,Santa Ines de Zaragoza,ADM2,DISTRICT
SM14709,-96.8569,16.9243,Santa Ines del Monte,ADM2,DISTRICT
SM282285,-98.069,18.3942,Santa Inés Ahuatempan,ADM2,DISTRICT
SM243860,-97.8599,18.3865,Santa Inés Ahuatempan Popoloca,LANGUAGE,LANGUAGE
SM243853,-96.7527,16.8842,Santa Inés Yatzechi Zapotec,LANGUAGE,LANGUAGE
SM264696,-51.8975,-22.6874,Santa Inês,ADM2,DISTRICT
SM264297,-38.5935,-7.6833,Santa Inês,ADM2,DISTRICT
SM262874,-45.4039,-3.7566,Santa Inês,ADM2,DISTRICT
SM267633,-39.8331,-13.2662,Santa Inês,ADM2,DISTRICT
SM286701,-79.3167,9.4833,Santa Isabel,ADM2,DISTRICT
SM273420,-79.3167,-3.3333,Santa Isabel,ADM2,DISTRICT
SM270587,-75.2239,4.7263,Santa Isabel,ADM2,DISTRICT
SM10072,-22.8586,16.0866,Santa Isabel,ADM2,DISTRICT
SM266906,-46.2415,-23.2944,Santa Isabel,ADM2,DISTRICT
SM268157,-49.378,-15.27,Santa Isabel,ADM2,DISTRICT
SM3122,-66.3871,17.9966,Santa Isabel,ADM1,DISTRICT
SM16200,-66.8097,18.2512,Santa Isabel Barrio,ADM2,DISTRICT
SM16201,-66.4049,17.9685,Santa Isabel Barrio-Pueblo,ADM2,DISTRICT
SM282286,-98.3827,18.9773,Santa Isabel Cholula,ADM2,DISTRICT
SM295562,-89.5884,13.5977,Santa Isabel Ishuatán,ADM2,DISTRICT
SM14710,-98.2122,19.2667,Santa Isabel Xiloxoxtla,ADM2,DISTRICT
SM264697,-53.2591,-23.1382,Santa Isabel do Ivaí,ADM2,DISTRICT
SM264698,-53.4105,-25.7757,Santa Isabel do Oeste,ADM2,DISTRICT
SM264089,-48.1222,-1.3798,Santa Isabel do Pará,ADM2,DISTRICT
SM267346,-65.5512,-0.2548,Santa Isabel do Rio Negro,ADM2,DISTRICT
SM263867,-47.5371,-19.3557,Santa Juliana,ADM2,DISTRICT
SM268023,-40.5733,-20.1195,Santa Leopoldina,ADM2,DISTRICT
SM273446,-80.0318,-1.7132,Santa Lucia,ADM2,DISTRICT
SM266907,-48.0828,-21.6647,Santa Lucia,ADM2,DISTRICT
SM14711,-96.6226,16.1818,Santa Lucia Miahuatlan,ADM2,DISTRICT
SM14712,-97.7126,16.9491,Santa Lucia Monteverde,ADM2,DISTRICT
SM14713,-96.6651,16.7299,Santa Lucia Ocotlan,ADM2,DISTRICT
SM14714,-96.6807,17.0667,Santa Lucia del Camino,ADM2,DISTRICT
SM285500,-85.7,12.5333,Santa Lucía,ADM2,DISTRICT
SM275929,-87.0918,14.126,Santa Lucía,ADM2,DISTRICT
SM275665,-88.4157,13.9081,Santa Lucía,ADM2,DISTRICT
SM270668,-74.9654,10.3524,Santa Lucía,ADM2,DISTRICT
SM260971,-68.4667,-31.5333,Santa Lucía,ADM2,DISTRICT
SM275426,-91.0707,14.2912,Santa Lucía Cotzumalguapa,ADM2,DISTRICT
SM275376,-91.2785,15.1503,Santa Lucía La Reforma,ADM2,DISTRICT
SM275267,-90.6755,14.5675,Santa Lucía Milpas Altas,ADM2,DISTRICT
SM243845,-97.6277,16.9969,Santa Lucía Monteverde Mixtec,LANGUAGE,LANGUAGE
SM275331,-91.271,14.7759,Santa Lucía Utatlán,ADM2,DISTRICT
SM265110,-44.2778,-8.9744,Santa Luz,ADM2,DISTRICT
SM264298,-36.9066,-6.9256,Santa Luzia,ADM2,DISTRICT
SM263869,-43.8265,-19.7354,Santa Luzia,ADM2,DISTRICT
SM262876,-45.878,-4.1878,Santa Luzia,ADM2,DISTRICT
SM267634,-39.2767,-15.4639,Santa Luzia,ADM2,DISTRICT
SM266108,-61.7944,-12.1047,Santa Luzia d'Oeste,ADM2,DISTRICT
SM267134,-37.4785,-11.3615,Santa Luzia do Itanhy,ADM2,DISTRICT
SM265421,-35.8292,-9.6108,Santa Luzia do Norte,ADM2,DISTRICT
SM262875,-45.7594,-2.5468,Santa Luzia do Paruá,ADM2,DISTRICT
SM264091,-46.9272,-1.6575,Santa Luzia do Pará,ADM2,DISTRICT
SM283423,14.5064,35.8617,Santa Luċija,ADM1:ADM2,DISTRICT
SM264700,-53.5521,-25.4147,Santa Lúcia,ADM2,DISTRICT
SM14715,-97.4739,17.8082,Santa Magdalena Jicotlan,ADM2,DISTRICT
SM292595,27.3026,47.6331,Santa Mare,ADM2,DISTRICT
SM263870,-42.2706,-20.4357,Santa Margarida,ADM2,DISTRICT
SM10005,-54.11,-30.3674,Santa Margarida Do Sul,ADM2,DISTRICT
SM265876,-53.8276,-29.7742,Santa Maria,ADM2,DISTRICT
SM265465,-35.72,-5.8453,Santa Maria,ADM2,DISTRICT
SM14716,-95.8586,17.0499,Santa Maria Alotepec,ADM2,DISTRICT
SM14717,-97.0852,17.6223,Santa Maria Apazco,ADM2,DISTRICT
SM14718,-96.785,17.0984,Santa Maria Atzompa,ADM2,DISTRICT
SM14719,-97.6849,17.8819,Santa Maria Camotlan,ADM2,DISTRICT
SM14720,-97.2607,17.5658,Santa Maria Chachoapam,ADM2,DISTRICT
SM14721,-96.7764,18.2755,Santa Maria Chilchotla,ADM2,DISTRICT
SM14722,-94.411,16.9708,Santa Maria Chimalapa,ADM2,DISTRICT
SM14723,-96.9349,15.8621,Santa Maria Colotepec,ADM2,DISTRICT
SM14724,-98.3202,16.4254,Santa Maria Cortijo,ADM2,DISTRICT
SM14725,-96.7026,16.9713,Santa Maria Coyotepec,ADM2,DISTRICT
SM14726,-95.8529,16.3142,Santa Maria Ecatepec,ADM2,DISTRICT
SM14727,-96.6095,17.0015,Santa Maria Guelace,ADM2,DISTRICT
SM14728,-95.3686,16.7524,Santa Maria Guienagati,ADM2,DISTRICT
SM14729,-96.2321,15.8061,Santa Maria Huatulco,ADM2,DISTRICT
SM14730,-97.9394,16.2261,Santa Maria Huazolotitlan,ADM2,DISTRICT
SM14731,-98.0209,16.6142,Santa Maria Ipalapa,ADM2,DISTRICT
SM14732,-97.1958,17.855,Santa Maria Ixcatlan,ADM2,DISTRICT
SM14733,-96.1738,17.8634,Santa Maria Jacatepec,ADM2,DISTRICT
SM14734,-95.4635,16.4942,Santa Maria Jalapa del Marques,ADM2,DISTRICT
SM14735,-96.5211,17.3613,Santa Maria Jaltianguis,ADM2,DISTRICT
SM14736,-97.0409,16.7376,Santa Maria Lachixio,ADM2,DISTRICT
SM265310,-41.909,-21.9579,Santa Maria Madalena,ADM2,DISTRICT
SM14737,-95.278,16.3911,Santa Maria Mixtequilla,ADM2,DISTRICT
SM14738,-97.3293,17.6471,Santa Maria Nativitas,ADM2,DISTRICT
SM14739,-97.5059,17.4083,Santa Maria Nduayaco,ADM2,DISTRICT
SM14740,-96.3482,16.101,Santa Maria Ozolotepec,ADM2,DISTRICT
SM14741,-96.7838,17.8073,Santa Maria Papalo,ADM2,DISTRICT
SM14742,-97.0301,17.035,Santa Maria Penoles,ADM2,DISTRICT
SM14743,-95.0379,16.8896,Santa Maria Petapa,ADM2,DISTRICT
SM14744,-96.0416,16.2898,Santa Maria Quiegolani,ADM2,DISTRICT
SM14745,-97.0313,16.5746,Santa Maria Sola,ADM2,DISTRICT
SM243850,-97.3941,17.1422,Santa Maria Tataltepec,DIALECT,LANGUAGE
SM14746,-97.3908,17.1396,Santa Maria Tataltepec,ADM2,DISTRICT
SM14747,-97.005,17.9757,Santa Maria Tecomavaca,ADM2,DISTRICT
SM14748,-96.1611,17.3806,Santa Maria Temaxcalapa,ADM2,DISTRICT
SM14749,-97.1795,16.1643,Santa Maria Temaxcaltepec,ADM2,DISTRICT
SM14750,-96.9662,18.1773,Santa Maria Teopoxco,ADM2,DISTRICT
SM14751,-95.9848,16.9837,Santa Maria Tepantlali,ADM2,DISTRICT
SM14752,-97.0676,17.7103,Santa Maria Texcatitlan,ADM2,DISTRICT
SM14753,-96.0718,17.0998,Santa Maria Tlahuitoltepec,ADM2,DISTRICT
SM14754,-96.734,17.9362,Santa Maria Tlalixtac,ADM2,DISTRICT
SM14755,-96.6805,15.7747,Santa Maria Tonameca,ADM2,DISTRICT
SM14756,-95.625,16.6,Santa Maria Totolapilla,ADM2,DISTRICT
SM14757,-95.0284,16.3789,Santa Maria Xadani,ADM2,DISTRICT
SM14758,-96.2619,17.2389,Santa Maria Yalina,ADM2,DISTRICT
SM14759,-96.4304,17.2341,Santa Maria Yavesia,ADM2,DISTRICT
SM14760,-97.4801,16.8813,Santa Maria Yolotepec,ADM2,DISTRICT
SM14761,-97.5066,17.1137,Santa Maria Yosoyua,ADM2,DISTRICT
SM14762,-97.7985,17.031,Santa Maria Yucuhiti,ADM2,DISTRICT
SM14763,-97.9927,16.7794,Santa Maria Zacatepec,ADM2,DISTRICT
SM14764,-97.3345,16.6539,Santa Maria Zaniza,ADM2,DISTRICT
SM14765,-96.3552,16.5497,Santa Maria Zoquitlan,ADM2,DISTRICT
SM264893,-39.9261,-8.5791,Santa Maria da Boa Vista,ADM2,DISTRICT
SM289215,-8.5155,40.9604,Santa Maria da Feira,ADM2,DISTRICT
SM266908,-48.1446,-22.5375,Santa Maria da Serra,ADM2,DISTRICT
SM267635,-44.378,-13.2614,Santa Maria da Vitória,ADM2,DISTRICT
SM264092,-50.2658,-8.6367,Santa Maria das Barreiras,ADM2,DISTRICT
SM263871,-43.0684,-19.4359,Santa Maria de Itabira,ADM2,DISTRICT
SM268024,-40.801,-20.0919,Santa Maria de Jetibá,ADM2,DISTRICT
SM14766,-103.3621,21.5144,Santa Maria de la Paz,ADM2,DISTRICT
SM275755,-85.9428,14.7981,Santa Maria del Real,ADM2,DISTRICT
SM14767,-97.6025,17.3485,Santa Maria del Rosario,ADM2,DISTRICT
SM14768,-96.6217,17.0377,Santa Maria del Tule,ADM2,DISTRICT
SM264894,-35.8817,-7.8144,Santa Maria do Cambucá,ADM2,DISTRICT
SM265875,-50.9881,-29.4795,Santa Maria do Herval,ADM2,DISTRICT
SM457970,-51.9663,-24.92,Santa Maria do Oeste,ADM2,DISTRICT
SM264093,-47.5073,-1.3358,Santa Maria do Pará,ADM2,DISTRICT
SM263872,-40.111,-16.3044,Santa Maria do Salto,ADM2,DISTRICT
SM263873,-42.3388,-18.2526,Santa Maria do Suaçuí,ADM2,DISTRICT
SM267169,-47.8534,-8.8157,Santa Maria do Tocantins,ADM2,DISTRICT
SM14769,-96.816,18.1052,Santa Maria la Asuncion,ADM2,DISTRICT
SM264703,-50.5294,-23.0483,Santa Mariana,ADM2,DISTRICT
SM270137,-74.2084,11.2435,Santa Marta (Dist. Esp.),ADM2,DISTRICT
SM289364,-7.7963,41.2214,Santa Marta de Penaguião,ADM2,DISTRICT
SM289598,-56.889,-26.7393,Santa María,ADM2,DISTRICT
SM286712,-80.6833,8.1167,Santa María,ADM2,DISTRICT
SM285466,-86.7124,13.7226,Santa María,ADM2,DISTRICT
SM275687,-87.9116,14.269,Santa María,ADM2,DISTRICT
SM295586,-88.4131,13.3462,Santa María,ADM2,DISTRICT
SM270101,-75.6318,2.9339,Santa María,ADM2,DISTRICT
SM270824,-73.2537,4.8228,Santa María,ADM2,DISTRICT
SM261135,-64.3333,-31.6667,Santa María,ADM2,DISTRICT
SM260998,-66.4167,-26.6667,Santa María,ADM2,DISTRICT
SM243847,-97.9895,16.7634,Santa María Acatepec,DIALECT,LANGUAGE
SM275161,-89.7896,15.5847,Santa María Cahabón,ADM2,DISTRICT
SM275377,-91.3292,15.0289,Santa María Chiquimula,ADM2,DISTRICT
SM275312,-90.2605,14.1416,Santa María Ixhuatán,ADM2,DISTRICT
SM243829,-97.7009,18.6286,Santa María La Alta Nahuatl,LANGUAGE,LANGUAGE
SM295688,-88.9227,13.6279,Santa María Ostuma,ADM2,DISTRICT
SM243851,-97.0027,17.0796,Santa María Peñoles,DIALECT,LANGUAGE
SM243857,-96.0486,16.033,Santa María Quiegolani Zapotec,LANGUAGE,LANGUAGE
SM275332,-91.3321,14.7009,Santa María Visitación,ADM2,DISTRICT
SM243848,-97.5155,17.1199,Santa María Yosoyúa,DIALECT,LANGUAGE
SM243842,-97.9484,16.7003,Santa María Zacatepec Mixtec,LANGUAGE,LANGUAGE
SM303593,-65.3225,8.8156,Santa María de Ipire,ADM2,DISTRICT
SM275268,-90.6999,14.4785,Santa María de Jesús,ADM2,DISTRICT
SM285544,-85.8167,13.2,Santa María de Pantasma,ADM2,DISTRICT
SM281846,-103.1889,22.1925,Santa María de los Angeles,ADM2,DISTRICT
SM243828,-94.8159,16.2167,Santa María del Mar Huave,LANGUAGE,LANGUAGE
SM282137,-104.586,21.3567,Santa María del Oro,ADM2,DISTRICT
SM282501,-100.5725,21.7706,Santa María del Río,ADM2,DISTRICT
SM266909,-51.7464,-21.3183,Santa Mercedes,ADM2,DISTRICT
SM316891,-53.1124,-23.1655,Santa Mônica,ADM2:ADM3,DISTRICT
SM16202,-66.1646,18.3171,Santa Olaya Barrio,ADM2,DISTRICT
SM267844,-39.988,-4.3958,Santa Quitéria,ADM2,DISTRICT
SM262877,-42.9548,-3.3024,Santa Quitéria do Maranhão,ADM2,DISTRICT
SM303804,-71.5069,10.5347,Santa Rita,ADM2,DISTRICT
SM289661,-55.136,-25.7747,Santa Rita,ADM2,DISTRICT
SM275810,-87.81,15.18,Santa Rita,ADM2,DISTRICT
SM275784,-88.2678,14.7602,Santa Rita,ADM2,DISTRICT
SM275872,-89.01,14.88,Santa Rita,ADM2,DISTRICT
SM295632,-89.0002,14.1186,Santa Rita,ADM2,DISTRICT
SM264299,-34.9739,-7.1044,Santa Rita,ADM2,DISTRICT
SM262878,-44.3288,-3.1635,Santa Rita,ADM2,DISTRICT
SM3123,144.6721,13.3861,Santa Rita,ADM1,DISTRICT
SM10006,-55.4017,-13.7142,Santa Rita Do Trivelato,ADM2,DISTRICT
SM263880,-41.4028,-19.418,Santa Rita Itueto,ADM2,DISTRICT
SM268158,-53.0768,-17.2329,Santa Rita de Araguaia,ADM2,DISTRICT
SM263874,-46.2737,-22.0194,Santa Rita de Caldas,ADM2,DISTRICT
SM267636,-44.5958,-11.0272,Santa Rita de Cássia,ADM2,DISTRICT
SM263875,-44.1033,-22.1091,Santa Rita de Jacutinga,ADM2,DISTRICT
SM263876,-42.127,-19.8692,Santa Rita de Minas,ADM2,DISTRICT
SM457963,-43.958,-21.5615,Santa Rita do Ibitipoca,ADM2,DISTRICT
SM268159,-49.0594,-14.8266,Santa Rita do Novo destino,ADM2,DISTRICT
SM266911,-50.8137,-20.0806,Santa Rita do Oeste,ADM2,DISTRICT
SM263083,-52.6932,-21.3548,Santa Rita do Pardo,ADM2,DISTRICT
SM266912,-47.5035,-21.6924,Santa Rita do Passa Quatro,ADM2,DISTRICT
SM315485,-45.6746,-22.2562,Santa Rita do Sapucaí,ADM2:ADM3,DISTRICT
SM267170,-49.3711,-10.9736,Santa Rita do Tocantins,ADM2,DISTRICT
SM300365,-87.0185,30.6929,Santa Rosa,ADM2,DISTRICT
SM289599,-56.7943,-26.8714,Santa Rosa,ADM2,DISTRICT
SM273619,-79.9571,-3.4641,Santa Rosa,ADM2,DISTRICT
SM270701,-75.3616,10.4731,Santa Rosa,ADM2,DISTRICT
SM265877,-54.4803,-27.8257,Santa Rosa,ADM2,DISTRICT
SM260887,-68,-33.5,Santa Rosa,ADM2,DISTRICT
SM260999,-65.25,-28.0833,Santa Rosa,ADM2,DISTRICT
SM3124,-90.3,14.1667,Santa Rosa,ADM1,DISTRICT
SM10468,-76.4979,1.537,Santa Rosa (1996-),ADM2,DISTRICT
SM16204,-66.1278,18.3521,Santa Rosa Barrio (Guaynabo),ADM2,DISTRICT
SM16205,-67.0396,18.0516,Santa Rosa Barrio (Lajas),ADM2,DISTRICT
SM16203,-66.75,18.3291,Santa Rosa Barrio (Utuado),ADM2,DISTRICT
SM15366,-56.2719,-23.7591,Santa Rosa Del Aguaray,ADM2,DISTRICT
SM15367,-54.9232,-25.8456,Santa Rosa Del Monday (2011-),ADM2,DISTRICT
SM295547,-89.3629,14.1944,Santa Rosa Guachipilín,ADM2,DISTRICT
SM263881,-45.98,-19.5387,Santa Rosa da Serra,ADM2,DISTRICT
SM275836,-85.6866,15.9005,Santa Rosa de Aguán,ADM2,DISTRICT
SM270429,-75.545,4.8452,Santa Rosa de Cabal,ADM2,DISTRICT
SM275873,-88.7904,14.769,Santa Rosa de Copán,ADM2,DISTRICT
SM268160,-49.4815,-16.0699,Santa Rosa de Goiás,ADM2,DISTRICT
SM275313,-90.3366,14.4395,Santa Rosa de Lima,ADM2,DISTRICT
SM295707,-87.8333,13.75,Santa Rosa de Lima,ADM2,DISTRICT
SM267135,-37.2287,-10.6314,Santa Rosa de Lima,ADM2,DISTRICT
SM266286,-49.1894,-28.0125,Santa Rosa de Lima,ADM2,DISTRICT
SM270151,-75.4409,6.6878,Santa Rosa de Osos,ADM2,DISTRICT
SM270825,-73.0134,5.8852,Santa Rosa de Viterbo,ADM2,DISTRICT
SM266913,-47.3632,-21.4988,Santa Rosa de Viterbo,ADM2,DISTRICT
SM289687,-56.2536,-24.9326,Santa Rosa del Mbutuy,ADM2,DISTRICT
SM285424,-86.3661,12.823,Santa Rosa del Peñón,ADM2,DISTRICT
SM270702,-74.2759,7.6976,Santa Rosa del Sur,ADM2,DISTRICT
SM265111,-42.2463,-6.8288,Santa Rosa do Piauí,ADM2,DISTRICT
SM262790,-70.3903,-9.4773,Santa Rosa do Purus,ADM2,DISTRICT
SM266287,-49.7351,-29.0975,Santa Rosa do Sul,ADM2,DISTRICT
SM267171,-48.073,-11.3828,Santa Rosa do Tocantins,ADM2,DISTRICT
SM303683,-69.0491,9.0986,Santa Rosalía,ADM2,DISTRICT
SM270658,-70.6599,5.0217,Santa Rosalía,ADM2,DISTRICT
SM266914,-50.7283,-20.2736,Santa Salete,ADM2,DISTRICT
SM270826,-73.6298,5.7021,Santa Sofía,ADM2,DISTRICT
SM5166,-86.1455,11.6766,Santa Teresa,ADM2,DISTRICT
SM268025,-40.6567,-19.8962,Santa Teresa,ADM2,DISTRICT
SM243830,-104.7,22.7102,Santa Teresa Cora,LANGUAGE,LANGUAGE
SM264301,-37.4965,-7.1209,Santa Teresinha,ADM2,DISTRICT
SM265878,-51.7216,-29.1506,Santa Tereza,ADM2,DISTRICT
SM268161,-48.995,-13.5511,Santa Tereza de Goiás,ADM2,DISTRICT
SM264704,-53.6194,-25.0444,Santa Tereza do Oeste,ADM2,DISTRICT
SM267172,-47.726,-10.2998,Santa Tereza do Tocantins,ADM2,DISTRICT
SM266289,-50.0134,-26.6696,Santa Terezinha,ADM2,DISTRICT
SM264895,-37.4442,-7.4266,Santa Terezinha,ADM2,DISTRICT
SM263106,-50.8326,-10.3914,Santa Terezinha,ADM2,DISTRICT
SM268162,-49.7145,-14.3105,Santa Terezinha de Goiás,ADM2,DISTRICT
SM264705,-54.4225,-25.4135,Santa Terezinha de Itaipu,ADM2,DISTRICT
SM266288,-53.1842,-26.5987,Santa Terezinha do Progresso,ADM2,DISTRICT
SM267173,-47.7006,-6.4804,Santa Terezinha do Tocantins,ADM2,DISTRICT
SM283392,14.4778,35.8903,Santa Venera,ADM1:ADM2,DISTRICT
SM260959,-64.9167,-22.4167,Santa Victoria,ADM2,DISTRICT
SM457967,-50.2991,-19.0919,Santa Vitória,ADM2,DISTRICT
SM265879,-53.2235,-33.2435,Santa Vitória do Palmar,ADM2,DISTRICT
SM305308,-74.1808,4.3147,Santafé de Bogotá,ADM1:ADM2:ADMX,DISTRICT
SM305308,-74.1808,4.3147,Santafé de Bogotá,ADM1:ADM2:ADMX,DISTRICT
SM305308,-74.1833,4.25,Santafé de Bogotá,ADM1:ADM2:ADMX,DISTRICT
SM305308,-74.1833,4.25,Santafé de Bogotá,ADM1:ADM2:ADMX,DISTRICT
SM257355,87.58,22.51,Santal,ETHNICITY,ETHNICITY
SM257355,87.58,22.51,Santal,ETHNICITY,ETHNICITY
SM257355,87.58,22.51,Santal,ETHNICITY,ETHNICITY
SM257355,87.58,22.51,Santal,ETHNICITY,ETHNICITY
SM257355,87.1665,23.5,Santal,ETHNICITY,ETHNICITY
SM257355,87.1665,23.5,Santal,ETHNICITY,ETHNICITY
SM257355,87.1665,23.5,Santal,ETHNICITY,ETHNICITY
SM257355,87.1665,23.5,Santal,ETHNICITY,ETHNICITY
SM257355,87,24,Santal,ETHNICITY,ETHNICITY
SM257355,87,24,Santal,ETHNICITY,ETHNICITY
SM257355,87,24,Santal,ETHNICITY,ETHNICITY
SM257355,87,24,Santal,ETHNICITY,ETHNICITY
SM257355,87.17,23.5,Santal,ETHNICITY,ETHNICITY
SM257355,87.17,23.5,Santal,ETHNICITY,ETHNICITY
SM257355,87.17,23.5,Santal,ETHNICITY,ETHNICITY
SM257355,87.17,23.5,Santal,ETHNICITY,ETHNICITY
SM11028,33.8074,35.1886,Santalaris,ADM2,DISTRICT
SM243817,87.8185,25.0317,Santali,LANGUAGE,LANGUAGE
SM267638,-39.5086,-11.1279,Santaluz,ADM2,DISTRICT
SM290785,22.9667,45.5833,Santamaria-orlea,ADM2,DISTRICT
SM290529,21.504,46.3462,Santana,ADM2,DISTRICT
SM289275,-16.9044,32.7966,Santana,ADM2,DISTRICT
SM270827,-73.5111,6.0446,Santana,ADM2,DISTRICT
SM265467,-36.766,-6.7521,Santana,ADM2,DISTRICT
SM267639,-43.8476,-13.0894,Santana,ADM2,DISTRICT
SM267292,-51.3884,0.1813,Santana,ADM2,DISTRICT
SM16206,-66.6648,18.4535,Santana Barrio (Arecibo),ADM2,DISTRICT
SM16207,-66.9668,18.1099,Santana Barrio (Sabana Grande),ADM2,DISTRICT
SM291189,24.5588,46.5928,Santana De Mures,ADM2,DISTRICT
SM265880,-53.183,-30.762,Santana da Boa Vista,ADM2,DISTRICT
SM266915,-50.7985,-20.2565,Santana da Ponte Pensa,ADM2,DISTRICT
SM263883,-45.4897,-21.2676,Santana da Vargem,ADM2,DISTRICT
SM263884,-42.5658,-21.2832,Santana de Cataguases,ADM2,DISTRICT
SM264302,-38.3585,-7.6659,Santana de Mangueira,ADM2,DISTRICT
SM266916,-46.9172,-23.4473,Santana de Parnaíba,ADM2,DISTRICT
SM263885,-43.927,-18.9078,Santana de Pirapama,ADM2,DISTRICT
SM267845,-40.1448,-3.4839,Santana do Acaraú,ADM2,DISTRICT
SM264094,-50.673,-9.1387,Santana do Araguaia,ADM2,DISTRICT
SM267846,-39.7845,-7.1797,Santana do Cariri,ADM2,DISTRICT
SM263887,-44.0694,-21.6468,Santana do Garambéu,ADM2,DISTRICT
SM265422,-37.194,-9.3443,Santana do Ipanema,ADM2,DISTRICT
SM264706,-49.6194,-23.7344,Santana do Itararé,ADM2,DISTRICT
SM263888,-45.0791,-20.8887,Santana do Jacaré,ADM2,DISTRICT
SM265881,-55.4816,-30.7807,Santana do Livramento,ADM2,DISTRICT
SM263889,-41.8977,-20.0556,Santana do Manhuaçu,ADM2,DISTRICT
SM262879,-42.7488,-3.1351,Santana do Maranhão,ADM2,DISTRICT
SM265466,-36.7133,-5.9345,Santana do Matos,ADM2,DISTRICT
SM265423,-36.2267,-9.1243,Santana do Mundaú,ADM2,DISTRICT
SM263892,-42.5407,-19.3801,Santana do Paraíso,ADM2,DISTRICT
SM265112,-41.4619,-6.9525,Santana do Piauí,ADM2,DISTRICT
SM263893,-43.6838,-19.1842,Santana do Riacho,ADM2,DISTRICT
SM267136,-36.6331,-10.2765,Santana do São Francisco,ADM2,DISTRICT
SM263886,-43.1745,-21.9454,Santana do deserto,ADM2,DISTRICT
SM264303,-37.9539,-7.3816,Santana dos Garrotes,ADM2,DISTRICT
SM263894,-43.6458,-20.7986,Santana dos Montes,ADM2,DISTRICT
SM3125,-73.4167,6.8333,Santander,ADM1,DISTRICT
SM10469,-76.5,3,Santander de Quilichao (1998-),ADM2,DISTRICT
SM292466,21.8395,47.065,Santandrei,ADM2,DISTRICT
SM267640,-38.8801,-12.0242,Santanópolis,ADM2,DISTRICT
SM10007,-55.2383,-2.6792,Santarem (2013-),ADM2,DISTRICT
SM289326,-8.7131,39.3213,Santarém,ADM2,DISTRICT
SM264304,-38.4641,-6.4843,Santarém,ADM2,DISTRICT
SM3126,-8.5019,39.2888,Santarém,ADM1,DISTRICT
SM264095,-47.363,-0.8878,Santarém Novo,ADM2,DISTRICT
SM291685,22.4733,47.5115,Santau,ADM2,DISTRICT
SM257356,-94.42,44.98,Santee-Sisseton,ETHNICITY,ETHNICITY
SM257356,-94.42,44.98,Santee-Sisseton,ETHNICITY,ETHNICITY
SM257356,-94,45,Santee-Sisseton,ETHNICITY,ETHNICITY
SM257356,-94,45,Santee-Sisseton,ETHNICITY,ETHNICITY
SM297234,101.0002,18.9119,Santi Suk,ADM2,DISTRICT
SM289600,-56.7838,-27.1298,Santiago,ADM2,DISTRICT
SM286667,-80.8783,8.1243,Santiago,ADM2,DISTRICT
SM282193,-100.1859,25.4116,Santiago,ADM2,DISTRICT
SM273524,-78.3167,-2.7167,Santiago,ADM2,DISTRICT
SM270412,-77.0389,1.0536,Santiago,ADM2,DISTRICT
SM270387,-72.7375,7.8693,Santiago,ADM2,DISTRICT
SM269086,-70.6667,-33.3333,Santiago,ADM2,DISTRICT
SM3127,-70.8833,19.3167,Santiago,ADM1,DISTRICT
SM10008,-54.7336,-29.1137,Santiago (2001-),ADM2,DISTRICT
SM14770,-97.5322,16.6275,Santiago Amoltepec,ADM2,DISTRICT
SM14771,-97.1397,17.6585,Santiago Apoala,ADM2,DISTRICT
SM14772,-96.7137,16.8051,Santiago Apostol,ADM2,DISTRICT
SM14773,-95.6888,15.9446,Santiago Astata,ADM2,DISTRICT
SM14774,-95.9204,17.0818,Santiago Atitlan,ADM2,DISTRICT
SM275333,-91.2277,14.6031,Santiago Atitlán,ADM2,DISTRICT
SM14775,-97.9611,17.9204,Santiago Ayuquililla,ADM2,DISTRICT
SM16208,-66.8317,18.3985,Santiago Barrio,ADM2,DISTRICT
SM14776,-97.7414,17.7344,Santiago Cacaloxtepec,ADM2,DISTRICT
SM14777,-96.1717,17.5508,Santiago Camotlan,ADM2,DISTRICT
SM14778,-97.7047,18.1993,Santiago Chazumba,ADM2,DISTRICT
SM275495,-91.6852,15.5074,Santiago Chimaltenango,ADM2,DISTRICT
SM14779,-95.9161,17.3765,Santiago Choapam,ADM2,DISTRICT
SM14780,-96.3946,17.6159,Santiago Comaltepec,ADM2,DISTRICT
SM14781,-97.7099,17.8175,Santiago Huajolotitlan,ADM2,DISTRICT
SM14782,-97.0647,17.4753,Santiago Huauclilla,ADM2,DISTRICT
SM14783,-97.4321,17.8806,Santiago Ihuitlan Plumas,ADM2,DISTRICT
SM14784,-95.5923,16.9155,Santiago Ixcuintepec,ADM2,DISTRICT
SM282138,-105.2319,21.8097,Santiago Ixcuintla,ADM2,DISTRICT
SM14785,-97.6826,16.5504,Santiago Ixtayutla,ADM2,DISTRICT
SM14786,-97.7776,16.181,Santiago Jamiltepec,ADM2,DISTRICT
SM14787,-95.9688,17.6697,Santiago Jocotepec,ADM2,DISTRICT
SM14788,-97.9975,17.2192,Santiago Juxtlahuaca,ADM2,DISTRICT
SM14789,-95.5246,16.7657,Santiago Lachiguiri,ADM2,DISTRICT
SM14790,-96.2504,17.4172,Santiago Lalopa,ADM2,DISTRICT
SM14791,-95.232,16.6219,Santiago Laollaga,ADM2,DISTRICT
SM14792,-96.306,17.22,Santiago Laxopa,ADM2,DISTRICT
SM14793,-98.2758,16.4918,Santiago Llano Grande,ADM2,DISTRICT
SM10073,-23.5522,15.1112,Santiago Maior,ADM2,DISTRICT
SM281563,-101.0009,20.1633,Santiago Maravatío,ADM2,DISTRICT
SM303827,-67.4729,10.2239,Santiago Mariño,ADM2,DISTRICT
SM14794,-96.4219,16.8385,Santiago Matatlan,ADM2,DISTRICT
SM282287,-97.4355,18.5445,Santiago Miahuatlán,ADM2,DISTRICT
SM14795,-97.6903,17.9882,Santiago Miltepec,ADM2,DISTRICT
SM14796,-97.2434,16.4383,Santiago Minas,ADM2,DISTRICT
SM14797,-96.9253,17.5292,Santiago Nacaltepec,ADM2,DISTRICT
SM14798,-97.3734,17.4194,Santiago Nejapilla,ADM2,DISTRICT
SM14799,-94.5934,16.5244,Santiago Niltepec,ADM2,DISTRICT
SM295690,-88.9559,13.4848,Santiago Nonualco,ADM2,DISTRICT
SM14800,-97.6699,17.3439,Santiago Nundiche,ADM2,DISTRICT
SM14801,-97.75,17.0094,Santiago Nuyoo,ADM2,DISTRICT
SM281521,-105.7075,24.9707,Santiago Papasquiaro,ADM2,DISTRICT
SM14802,-98.1017,16.2733,Santiago Pinotepa Nacional,ADM2,DISTRICT
SM3130,-71.3333,19.4167,Santiago Rodríguez,ADM1,DISTRICT
SM275269,-90.6807,14.6378,Santiago Sacatepéquez,ADM2,DISTRICT
SM14803,-95.6528,17.6158,Santiago Sochiapan,ADM2,DISTRICT
SM14804,-96.868,17.2485,Santiago Suchilquitongo,ADM2,DISTRICT
SM14805,-98.214,17.7286,Santiago Tamazola,ADM2,DISTRICT
SM14806,-98.4863,16.3269,Santiago Tapextla,ADM2,DISTRICT
SM14807,-96.9956,17.3035,Santiago Tenango,ADM2,DISTRICT
SM14808,-97.3992,17.7839,Santiago Tepetlapa,ADM2,DISTRICT
SM14809,-97.6774,16.3417,Santiago Tetepec,ADM2,DISTRICT
SM295518,-89.1061,13.6425,Santiago Texacuangos,ADM2,DISTRICT
SM14810,-96.9736,18.2144,Santiago Texcalcingo,ADM2,DISTRICT
SM14811,-97.2807,16.7026,Santiago Textitlan,ADM2,DISTRICT
SM14812,-97.3433,17.2488,Santiago Tilantongo,ADM2,DISTRICT
SM14813,-97.3204,17.465,Santiago Tillo,ADM2,DISTRICT
SM243852,-96.9958,17.0274,Santiago Tlazoyaltepec,DIALECT,LANGUAGE
SM14814,-96.9673,17.0276,Santiago Tlazoyaltepec,ADM2,DISTRICT
SM281706,-98.3916,20.0421,Santiago Tulantepec de Lugo Guerrero,ADM2,DISTRICT
SM282777,-95.3752,18.4204,Santiago Tuxtla,ADM2,DISTRICT
SM14815,-96.2482,16.0039,Santiago Xanica,ADM2,DISTRICT
SM243858,-96.2186,15.8836,Santiago Xanica Zapotec,LANGUAGE,LANGUAGE
SM14816,-96.4238,17.2753,Santiago Xiacui,ADM2,DISTRICT
SM14817,-97.246,16.1943,Santiago Yaitepec,ADM2,DISTRICT
SM14818,-95.4228,17.548,Santiago Yaveo,ADM2,DISTRICT
SM14819,-97.5724,17.4652,Santiago Yolomecatl,ADM2,DISTRICT
SM14820,-97.5538,16.8535,Santiago Yosondua,ADM2,DISTRICT
SM14821,-98.2206,17.6144,Santiago Yucuyachi,ADM2,DISTRICT
SM14822,-95.8847,17.1887,Santiago Zacatepec,ADM2,DISTRICT
SM14823,-96.2417,17.22,Santiago Zoochila,ADM2,DISTRICT
SM281705,-98.9762,20.4082,Santiago de Anaya,ADM2,DISTRICT
SM270630,-76.581,3.4103,Santiago de Cali,ADM2,DISTRICT
SM286754,-78.25,-8.3333,Santiago de Chuco,ADM2,DISTRICT
SM271051,-75.7082,20.0062,Santiago de Cuba,ADM2,DISTRICT
SM3128,-75.9333,20.2,Santiago de Cuba,ADM1,DISTRICT
SM295587,-88.4667,13.45,Santiago de María,ADM2,DISTRICT
SM273573,-78.5333,-1.1667,Santiago de Pillaro,ADM2,DISTRICT
SM275688,-87.86,14.32,Santiago de Puringla,ADM2,DISTRICT
SM10470,-75.5358,9.5322,Santiago de Tolu (2002-),ADM2,DISTRICT
SM295548,-89.6183,14.1738,Santiago de la Frontera,ADM2,DISTRICT
SM271877,-70.7,19.4167,Santiago de los Caballeros,ADM2,DISTRICT
SM3129,-63.5,-28,Santiago del Estero,ADM1,DISTRICT
SM243838,-63.2466,-27.821,Santiago del Estero Quichua,LANGUAGE,LANGUAGE
SM14824,-98.0911,17.4484,Santiago del Rio,ADM2,DISTRICT
SM289351,-8.6758,38.025,Santiago do Cacém,ADM2,DISTRICT
SM266290,-52.6747,-26.6306,Santiago do Sul,ADM2,DISTRICT
SM14825,-92.7207,16.9484,Santiago el Pinar,ADM2,DISTRICT
SM16209,-65.6995,18.1984,Santiago y Lima Barrio,ADM2,DISTRICT
SM257358,-122.62,44.46,Santiam,ETHNICITY,ETHNICITY
SM289831,23.669,46.1297,Santimbru,ADM2,DISTRICT
SM10074,-23.5987,14.9421,Santissimo Nome de Jesus,ADM2,DISTRICT
SM283828,95.4329,48.5833,Santmargats,ADM2,DISTRICT
SM263107,-57.3858,-14.4613,Santo Afonso,ADM2,DISTRICT
SM267641,-38.7743,-12.6137,Santo Amaro,ADM2,DISTRICT
SM10075,-23.7177,15.2645,Santo Amaro Abade,ADM2,DISTRICT
SM266291,-48.8048,-27.7515,Santo Amaro da Imperatriz,ADM2,DISTRICT
SM267137,-36.9583,-10.7773,Santo Amaro das Brotas,ADM2,DISTRICT
SM262880,-43.1651,-2.6203,Santo Amaro do Maranhão,ADM2,DISTRICT
SM266917,-51.701,-22.0152,Santo Anastácio,ADM2,DISTRICT
SM10076,-25.2754,17.0767,Santo Andre,ADM2,DISTRICT
SM266918,-46.5046,-23.7106,Santo André,ADM2,DISTRICT
SM264305,-36.6146,-7.2418,Santo André,ADM2,DISTRICT
SM10009,-53.7414,-14.8245,Santo Antonio Do Leste,ADM2,DISTRICT
SM264707,-50.0847,-23.2742,Santo Antonio da Platina,ADM2,DISTRICT
SM10077,-25.0126,17.1115,Santo Antonio das Pombas,ADM2,DISTRICT
SM264708,-52.309,-22.6937,Santo Antonio do Caiuá,ADM2,DISTRICT
SM264709,-50.6194,-23.5636,Santo Antonio do Paraíso,ADM2,DISTRICT
SM281407,113.5412,22.1995,Santo António,ADM1:ADM2,DISTRICT
SM265468,-35.5488,-6.3095,Santo Antônio,ADM2,DISTRICT
SM266919,-47.2036,-21.0924,Santo Antônio da Alegria,ADM2,DISTRICT
SM268163,-50.6307,-17.5123,Santo Antônio da Barra,ADM2,DISTRICT
SM265885,-50.5602,-29.8221,Santo Antônio da Patrulha,ADM2,DISTRICT
SM265886,-55.4088,-28.478,Santo Antônio das Missões,ADM2,DISTRICT
SM268164,-49.311,-16.5001,Santo Antônio de Goiás,ADM2,DISTRICT
SM267643,-39.2268,-13.008,Santo Antônio de Jesus,ADM2,DISTRICT
SM265113,-41.181,-6.8764,Santo Antônio de Lisboa,ADM2,DISTRICT
SM266920,-46.9476,-22.6044,Santo Antônio de Posse,ADM2,DISTRICT
SM265311,-42.2109,-21.5725,Santo Antônio de Pádua,ADM2,DISTRICT
SM457965,-44.9551,-20.9166,Santo Antônio do Amparo,ADM2,DISTRICT
SM266922,-50.5311,-20.8562,Santo Antônio do Aracanguá,ADM2,DISTRICT
SM457964,-42.811,-21.7442,Santo Antônio do Aventureiro,ADM2,DISTRICT
SM268165,-48.296,-16.0807,Santo Antônio do Descoberto,ADM2,DISTRICT
SM315516,-42.6155,-20.3233,Santo Antônio do Grama,ADM2:ADM3,DISTRICT
SM267347,-68.7592,-3.09,Santo Antônio do Içá,ADM2,DISTRICT
SM457966,-40.28,-16.5093,Santo Antônio do Jacinto,ADM2,DISTRICT
SM263108,-55.3133,-16.7858,Santo Antônio do Leverger,ADM2,DISTRICT
SM263900,-45.3059,-20.0799,Santo Antônio do Monte,ADM2,DISTRICT
SM265887,-52.0193,-28.4863,Santo Antônio do Palma,ADM2,DISTRICT
SM266923,-45.6997,-22.8214,Santo Antônio do Pinhal,ADM2,DISTRICT
SM265888,-52.6658,-28.3861,Santo Antônio do Planalto,ADM2,DISTRICT
SM315511,-42.6442,-15.3014,Santo Antônio do Retiro,ADM2:ADM3,DISTRICT
SM264097,-48.1947,-1.1031,Santo Antônio do Tauá,ADM2,DISTRICT
SM262882,-44.4751,-4.8257,Santo Antônio dos Lopes,ADM2,DISTRICT
SM265114,-42.7008,-6.054,Santo Antônio dos Milagres,ADM2,DISTRICT
SM265889,-53.7357,-27.8919,Santo Augusto,ADM2,DISTRICT
SM265890,-54.7038,-27.7884,Santo Cristo,ADM2,DISTRICT
SM10078,-25.1208,17.1293,Santo Crucifixo,ADM2,DISTRICT
SM285526,-84.9556,12.3616,Santo Domingo,ADM2,DISTRICT
SM282502,-101.6792,23.3478,Santo Domingo,ADM2,DISTRICT
SM295563,-89.7979,13.7156,Santo Domingo,ADM2,DISTRICT
SM295531,-88.8547,13.7072,Santo Domingo,ADM2,DISTRICT
SM271059,-80.372,22.6086,Santo Domingo,ADM2,DISTRICT
SM270941,-84.0684,9.9833,Santo Domingo,ADM2,DISTRICT
SM270152,-75.1546,6.4735,Santo Domingo,ADM2,DISTRICT
SM3131,-69.8487,18.5668,Santo Domingo,ADM1,DISTRICT
SM14826,-96.1908,17.0726,Santo Domingo Albarradas,ADM2,DISTRICT
SM243856,-96.281,17.0345,Santo Domingo Albarradas Zapotec,LANGUAGE,LANGUAGE
SM14827,-98.3711,16.3252,Santo Domingo Armenta,ADM2,DISTRICT
SM16210,-66.7473,18.0642,Santo Domingo Barrio,ADM2,DISTRICT
SM14828,-95.1663,16.5975,Santo Domingo Chihuitan,ADM2,DISTRICT
SM271885,-69.794,18.5125,Santo Domingo Este,ADM2,DISTRICT
SM243846,-97.5054,17.253,Santo Domingo Heundío Mixtec,DIALECT,LANGUAGE
SM14829,-94.7362,16.5738,Santo Domingo Ingenio,ADM2,DISTRICT
SM14830,-97.5301,16.9097,Santo Domingo Ixcatlan,ADM2,DISTRICT
SM271886,-69.8333,18.5833,Santo Domingo Norte,ADM2,DISTRICT
SM14831,-97.0651,17.1722,Santo Domingo Nuxaa,ADM2,DISTRICT
SM271887,-70.0066,18.4839,Santo Domingo Oeste,ADM2,DISTRICT
SM14832,-96.3204,16.171,Santo Domingo Ozolotepec,ADM2,DISTRICT
SM14833,-95.2101,16.8778,Santo Domingo Petapa,ADM2,DISTRICT
SM14834,-96.0885,17.3351,Santo Domingo Roayaga,ADM2,DISTRICT
SM275360,-91.5003,14.3,Santo Domingo Suchitepequez,ADM2,DISTRICT
SM14835,-95.3185,16.2567,Santo Domingo Tehuantepec,ADM2,DISTRICT
SM14836,-97.2808,16.5774,Santo Domingo Teojomulco,ADM2,DISTRICT
SM14837,-96.0689,16.9367,Santo Domingo Tepuxtepec,ADM2,DISTRICT
SM14838,-97.3478,17.4059,Santo Domingo Tlatayapam,ADM2,DISTRICT
SM14839,-96.6216,17.0547,Santo Domingo Tomaltepec,ADM2,DISTRICT
SM14840,-98.006,17.6848,Santo Domingo Tonala,ADM2,DISTRICT
SM14841,-97.3552,17.6054,Santo Domingo Tonaltepec,ADM2,DISTRICT
SM14842,-96.2594,17.1519,Santo Domingo Xagacia,ADM2,DISTRICT
SM275270,-90.7014,14.6783,Santo Domingo Xenacoj,ADM2,DISTRICT
SM14843,-97.3533,17.5328,Santo Domingo Yanhuitlan,ADM2,DISTRICT
SM14844,-97.6863,17.6171,Santo Domingo Yodohino,ADM2,DISTRICT
SM14845,-94.3515,16.474,Santo Domingo Zanatepec,ADM2,DISTRICT
SM14846,-96.6467,15.8346,Santo Domingo de Morelos,ADM2,DISTRICT
SM270388,-72.785,7.1869,Santo Domingo de Silos,ADM2,DISTRICT
SM3132,-79.1858,-0.2895,Santo Domingo de los Tsachilas,ADM1,DISTRICT
SM267644,-39.2681,-12.4642,Santo Estêvão,ADM2,DISTRICT
SM266924,-51.3699,-21.8298,Santo Expedito,ADM2,DISTRICT
SM265891,-51.6704,-27.9231,Santo Expedito do Sul,ADM2,DISTRICT
SM263901,-44.1773,-18.3838,Santo Hipólito,ADM2,DISTRICT
SM264711,-51.8061,-22.7237,Santo Inácio,ADM2,DISTRICT
SM265116,-41.9179,-7.4691,Santo Inácio do Piauí,ADM2,DISTRICT
SM289305,-8.4383,41.3301,Santo Tirso,ADM2,DISTRICT
SM14847,-96.6594,16.855,Santo Tomas Jalieza,ADM2,DISTRICT
SM14848,-96.8708,17.1722,Santo Tomas Mazaltepec,ADM2,DISTRICT
SM14849,-97.7734,17.122,Santo Tomas Ocotepec,ADM2,DISTRICT
SM14850,-96.5707,16.2348,Santo Tomas Tamazulapan,ADM2,DISTRICT
SM5163,-86.9011,13.1816,Santo Tomas del Norte,ADM2,DISTRICT
SM285527,-85.0079,12.129,Santo Tomás,ADM2,DISTRICT
SM281962,-100.2856,19.1781,Santo Tomás,ADM2,DISTRICT
SM295519,-89.1833,13.6167,Santo Tomás,ADM2,DISTRICT
SM270669,-74.8009,10.7318,Santo Tomás,ADM2,DISTRICT
SM282288,-97.8662,18.8927,Santo Tomás Hueyotlipan,ADM2,DISTRICT
SM275361,-91.4121,14.6227,Santo Tomás La Unión,ADM2,DISTRICT
SM261164,-56.25,-28.25,Santo Tomé,ADM2,DISTRICT
SM265883,-54.3021,-28.2649,Santo Ángelo,ADM2,DISTRICT
SM276799,-4.557,54.1057,Santon,ADM1:ADM2,DISTRICT
SM266926,-46.2706,-23.8566,Santos,ADM2,DISTRICT
SM263903,-43.5257,-21.4645,Santos Dumont,ADM2,DISTRICT
SM303622,-71.0784,8.6311,Santos Marquina,ADM2,DISTRICT
SM303828,-67.1847,10.2524,Santos Michelena,ADM2,DISTRICT
SM14851,-97.1917,16.0917,Santos Reyes Nopala,ADM2,DISTRICT
SM14852,-96.8736,17.7973,Santos Reyes Papalo,ADM2,DISTRICT
SM14853,-97.9348,17.4385,Santos Reyes Tepejillo,ADM2,DISTRICT
SM14854,-98.0017,17.774,Santos Reyes Yucuna,ADM2,DISTRICT
SM12711,-78.2839,18.3588,Santoy,ADM2,DISTRICT
SM270430,-75.9906,5.0532,Santuario,ADM2,DISTRICT
SM280497,105.32,12.64,Santuk,ADM2,DISTRICT
SM16211,-66.0471,18.4408,Santurce Barrio,ADM2,DISTRICT
SM266925,-50.5203,-21.6706,Santópolis do Aguapeí,ADM2,DISTRICT
SM278467,134.2094,34.2662,Sanuki,ADM2,DISTRICT
SM243868,-64.5,4.5,Sanumá,LANGUAGE,LANGUAGE
SM250427,23.75,30.72,Sanusi,ETHNICITY,ETHNICITY
SM250427,23.75,30.72,Sanusi,ETHNICITY,ETHNICITY
SM250427,23.75,30.72,Sanusi,ETHNICITY,ETHNICITY
SM250427,26.5,29.4165,Sanusi,ETHNICITY,ETHNICITY
SM250427,26.5,29.4165,Sanusi,ETHNICITY,ETHNICITY
SM250427,26.5,29.4165,Sanusi,ETHNICITY,ETHNICITY
SM250427,22,31,Sanusi,ETHNICITY,ETHNICITY
SM250427,22,31,Sanusi,ETHNICITY,ETHNICITY
SM250427,22,31,Sanusi,ETHNICITY,ETHNICITY
SM280759,107.2316,15.0294,Sanxay,ADM2,DISTRICT
SM269462,109.4194,18.3911,Sanya,ADM2,DISTRICT
SM250049,42.67,0.11,Sanye,ETHNICITY,ETHNICITY
SM250049,43.7,1.53,Sanye,ETHNICITY,ETHNICITY
SM260377,16.0488,-7.2603,Sanza Pombo,ADM2,DISTRICT
SM290227,26.1179,46.0646,Sanzieni,ADM2,DISTRICT
SM275094,-16.2006,12.4019,Sao Domingos,ADM2,DISTRICT
SM10079,-24.3586,16.5952,Sao Francisco de Assis,ADM2,DISTRICT
SM10010,-54.3303,-30.3337,Sao Gabriel (1996-),ADM2,DISTRICT
SM297536,100.8439,14.5755,Sao Hai,ADM2,DISTRICT
SM10011,-36.391,-8.8632,Sao Joao,ADM2:ADM3,DISTRICT
SM10080,-22.741,16.1153,Sao Joao Baptista (Boa Vista),ADM2,DISTRICT
SM18488,-24.6794,14.8453,Sao Joao Baptista (Brava),ADM2,DISTRICT
SM18490,-25.1777,17.012,Sao Joao Baptista (Porto Novo),ADM2,DISTRICT
SM18489,-23.6564,14.9898,Sao Joao Baptista (Ribeira Grande de Santiago),ADM2,DISTRICT
SM10013,-37.2597,-7.5288,Sao Jose Do Egito,ADM2,DISTRICT
SM10014,-51.762,-31.8009,Sao Jose Do Norte,ADM2,DISTRICT
SM10015,-51.4925,-29.5442,Sao Jose Do Sul,ADM2:ADM3,DISTRICT
SM10016,-52.6211,-10.7279,Sao Jose Do Xingu (1999-),ADM2:ADM3,DISTRICT
SM10081,-24.4362,14.9743,Sao Lourenco,ADM2,DISTRICT
SM10082,-23.5935,15.0649,Sao Lourenco Dos Orgaos,ADM2,DISTRICT
SM10017,-54.8902,-28.3921,Sao Luiz Gonzaga (1996-),ADM2,DISTRICT
SM10018,-50.4807,-25.9235,Sao Mateus Do Sul,ADM2,DISTRICT
SM10083,-23.6391,15.1933,Sao Miguel Arcanjo,ADM2,DISTRICT
SM10019,-36.1138,-9.8288,Sao Miguel Dos Campos (1995-),ADM2,DISTRICT
SM10084,-23.5705,15.0128,Sao Nicolau Tolentino,ADM2,DISTRICT
SM10085,-25.1769,17.1293,Sao Pedro Apostolo,ADM2,DISTRICT
SM10020,-53.2444,-27.7928,Sao Pedro Das Missoes,ADM2:ADM3,DISTRICT
SM10086,-23.6296,15.0907,Sao Salvador do Mundo,ADM2,DISTRICT
SM243884,6.7226,0.3203,Sao Tome and Principe Sign Language,LANGUAGE,LANGUAGE
SM10021,-51.7369,-29.063,Sao Valentim Do Sul,ADM2,DISTRICT
SM10022,-54.7624,-29.7173,Sao Vicente Do Sul,ADM2,DISTRICT
SM257362,104.49,11.1,Saoch,ETHNICITY,ETHNICITY
SM257362,104.49,11.1,Saoch,ETHNICITY,ETHNICITY
SM257362,104,11,Saoch,ETHNICITY,ETHNICITY
SM257362,104,11,Saoch,ETHNICITY,ETHNICITY
SM298040,10.1413,36.2079,Saouaf,ADM2,DISTRICT
SM298838,30.2744,40.7022,Sapanca,ADM2,DISTRICT
SM291033,23.6982,47.9693,Sapanta,ADM2,DISTRICT
SM243889,-61.5805,3.4334,Sapara,LANGUAGE,LANGUAGE
SM257367,142.22,-8.36,Sapara,ETHNICITY,ETHNICITY
SM262541,23.3167,42.2833,Sapareva Banya,ADM2,DISTRICT
SM243886,128.639,-3.5831,Saparua,LANGUAGE,LANGUAGE
SM267658,-39.2385,-12.772,Sapeaçu,ADM2,DISTRICT
SM284657,5.6535,5.8262,Sapele,ADM2,DISTRICT
SM263085,-57.5842,-19.105,Sapezal,ADM2,DISTRICT
SM263116,-58.6879,-13.1645,Sapezal,ADM2,DISTRICT
SM297179,100.69,13.7653,Saphan Sung,ADM2,DISTRICT
SM17638,-61.0121,13.7567,Saphire,ADM2,DISTRICT
SM265929,-50.9768,-29.6251,Sapiranga,ADM2,DISTRICT
SM243894,-8.4923,5.6956,Sapo,LANGUAGE,LANGUAGE
SM289917,26.7518,45.2229,Sapoca,ADM2,DISTRICT
SM243897,139.5,-2.4969,Saponi,LANGUAGE,LANGUAGE
SM264735,-50.6102,-23.8775,Sapopema,ADM2,DISTRICT
SM243896,154.732,-5.641,Saposa,LANGUAGE,LANGUAGE
SM461505,154.67,-5.5833,Saposa,ETHNICITY,ETHNICITY
SM17639,-61.0479,13.8495,Sapphire,ADM2,DISTRICT
SM278173,141.35,43.0667,Sapporo,ADM2,DISTRICT
SM375324,86.7494,26.6068,Saptari,ADM2:ADM3,DISTRICT
SM243899,106.843,15.3692,Sapuan,LANGUAGE,LANGUAGE
SM265322,-42.8139,-22.0409,Sapucaia,ADM2,DISTRICT
SM264110,-49.5006,-6.8479,Sapucaia,ADM2,DISTRICT
SM265930,-51.1553,-29.8183,Sapucaia do Sul,ADM2,DISTRICT
SM289622,-56.9583,-25.6728,Sapucaí,ADM2,DISTRICT
SM263969,-45.8422,-22.7917,Sapucaí-Mirim,ADM2,DISTRICT
SM270352,-77.7066,1.0595,Sapuyes,ADM2,DISTRICT
SM243902,157.1597,5.7893,Sapwuahfik,DIALECT,LANGUAGE
SM14973,157.28,5.815,Sapwuahfik Municipality,ADM2,DISTRICT
SM243891,-62.9667,4.0736,Sapé,LANGUAGE,LANGUAGE
SM264330,-35.2173,-7.0794,Sapé,ADM2,DISTRICT
SM304924,43.4716,16.8601,Saqayn,ADM2,DISTRICT
SM277554,46.3333,36.1667,Saqqez,ADM2,DISTRICT
SM265324,-42.5616,-22.8701,Saquarema,ADM2,DISTRICT
SM10219,-78.7256,-0.8287,Saquisili Canton,ADM2,DISTRICT
SM243952,17.8785,8.5063,Sar,LANGUAGE,LANGUAGE
SM243953,124.42,-8.33,Sar (Indonesia),LANGUAGE,LANGUAGE
SM260159,69.0393,33.063,Sar Hawza,ADM2,DISTRICT
SM260205,66.0074,36.2989,Sar-i-Pul City,ADM2,DISTRICT
SM262765,-63.6918,-16.8633,Sara,ADM2,DISTRICT
SM257372,18.06,11.49,Sara,ETHNICITY,ETHNICITY
SM257372,18.06,11.49,Sara,ETHNICITY,ETHNICITY
SM257372,18.06,11.49,Sara,ETHNICITY,ETHNICITY
SM257372,18,9,Sara,ETHNICITY,ETHNICITY
SM257372,18,9,Sara,ETHNICITY,ETHNICITY
SM257372,18,9,Sara,ETHNICITY,ETHNICITY
SM257372,17.5,8,Sara,ETHNICITY,ETHNICITY
SM257372,17.5,8,Sara,ETHNICITY,ETHNICITY
SM257372,17.5,8,Sara,ETHNICITY,ETHNICITY
SM243922,109.6155,1.204,Sara Bakati',LANGUAGE,LANGUAGE
SM243913,19.9446,8.9918,Sara Dunjo,LANGUAGE,LANGUAGE
SM243912,22.1637,10.5376,Sara Kaba,LANGUAGE,LANGUAGE
SM243915,18.6607,9.0347,Sara Kaba Deme,LANGUAGE,LANGUAGE
SM243914,19.2566,9.3939,Sara Kaba Náà,LANGUAGE,LANGUAGE
SM277679,47.4668,37.9834,Sarab,ADM2,DISTRICT
SM3141,100.9682,14.6084,Saraburi,ADM1,DISTRICT
SM273467,-79.25,-3.65,Saraguro,ADM2,DISTRICT
SM276914,85.8959,22.8391,Saraikela-kharsawan,ADM2,DISTRICT
SM244192,71.906,29.5534,Saraiki,LANGUAGE,LANGUAGE
SM290193,28.1473,44.7175,Saraiu,ADM2,DISTRICT
SM3142,21.2667,41.9833,Saraj,ADM1:ADM2,DISTRICT
SM262557,18.2422,43.8433,Sarajevo,ADM1:ADM2,DISTRICT
SM454375,23.5,40,Sarakatsani,ETHNICITY,ETHNICITY
SM277602,60.8167,36.3,Sarakhs,ADM2,DISTRICT
SM250700,-12.04,16.39,Sarakol,ETHNICITY,ETHNICITY
SM250700,-12.04,16.39,Sarakol,ETHNICITY,ETHNICITY
SM250700,-12.04,16.39,Sarakol,ETHNICITY,ETHNICITY
SM250700,-13.41,14.78,Sarakol,ETHNICITY,ETHNICITY
SM250700,-13.41,14.78,Sarakol,ETHNICITY,ETHNICITY
SM250700,-13.41,14.78,Sarakol,ETHNICITY,ETHNICITY
SM250700,-10,15,Sarakol,ETHNICITY,ETHNICITY
SM250700,-10,15,Sarakol,ETHNICITY,ETHNICITY
SM250700,-10,15,Sarakol,ETHNICITY,ETHNICITY
SM250700,10,15,Sarakol,ETHNICITY,ETHNICITY
SM250700,10,15,Sarakol,ETHNICITY,ETHNICITY
SM250700,10,15,Sarakol,ETHNICITY,ETHNICITY
SM296056,-54.9689,4.3202,Sarakreek,ADM2,DISTRICT
SM293983,56.4167,51.8333,Saraktashskiy rayon,ADM2,DISTRICT
SM11029,32.5377,34.9611,Sarama,ADM2,DISTRICT
SM3143,-55.5833,5.6667,Saramacca,ADM1,DISTRICT
SM243926,-54.0238,4.5473,Saramaccan,LANGUAGE,LANGUAGE
SM257376,-53.8,4.98,Saramaccan,ETHNICITY,ETHNICITY
SM257376,-53.8,4.98,Saramaccan,ETHNICITY,ETHNICITY
SM257376,-53.8,4.98,Saramaccan,ETHNICITY,ETHNICITY
SM257376,-55.77,5.09,Saramaccan,ETHNICITY,ETHNICITY
SM257376,-55.77,5.09,Saramaccan,ETHNICITY,ETHNICITY
SM257376,-55.77,5.09,Saramaccan,ETHNICITY,ETHNICITY
SM257376,-55.75,3.5,Saramaccan,ETHNICITY,ETHNICITY
SM257376,-55.75,3.5,Saramaccan,ETHNICITY,ETHNICITY
SM257376,-55.75,3.5,Saramaccan,ETHNICITY,ETHNICITY
SM257376,-56,4,Saramaccan,ETHNICITY,ETHNICITY
SM257376,-56,4,Saramaccan,ETHNICITY,ETHNICITY
SM257376,-56,4,Saramaccan,ETHNICITY,ETHNICITY
SM296063,-55.2558,5.8297,Saramaccapolder,ADM2,DISTRICT
SM277428,84.75,25.9167,Saran,ADM2,DISTRICT
SM265931,-52.9347,-27.9235,Sarandi,ADM2,DISTRICT
SM264736,-51.871,-23.478,Sarandi,ADM2,DISTRICT
SM300216,-55.9167,-33.8333,Sarandi Del Yi,ADM2,DISTRICT
SM300222,-56.3319,-33.7248,Sarandi Grande,ADM2,DISTRICT
SM260476,20.0833,39.8333,Sarandës,ADM2,DISTRICT
SM3144,125.2753,5.8747,Sarangani,ADM1:ADM2,DISTRICT
SM243919,125.354,6.0369,Sarangani Blaan,LANGUAGE,LANGUAGE
SM243920,125.499,5.849,Sarangani Manobo,LANGUAGE,LANGUAGE
SM11030,33.0074,34.9791,Saranti,ADM2,DISTRICT
SM296887,99.0299,18.7051,Saraphi,ADM2,DISTRICT
SM270933,-84,10.5,Sarapiquí,ADM2,DISTRICT
SM294833,53.75,56.5,Sarapul'skiy rayon,ADM2,DISTRICT
SM266957,-47.771,-23.659,Sarapuí,ADM2,DISTRICT
SM304960,45.3508,13.583,Sarar,ADM2,DISTRICT
SM291034,23.8167,47.95,Sarasau,ADM2,DISTRICT
SM243916,146.4,-6.18,Sarasira,LANGUAGE,LANGUAGE
SM300366,-82.365,27.1825,Sarasota,ADM2,DISTRICT
SM17812,43.1167,18,Sarat Abidah,ADM2,DISTRICT
SM301908,-73.8639,43.1074,Saratoga,ADM2,DISTRICT
SM3145,47,51.5,Saratov,ADM1,DISTRICT
SM294433,46.1667,51.75,Saratovskiy rayon,ADM2,DISTRICT
SM299623,29.6633,46.0218,Sarats'kyi,ADM2,DISTRICT
SM3146,106.25,15.75,Saravan,ADM1,DISTRICT
SM280772,106.3616,15.7159,Saravane,ADM2,DISTRICT
SM243924,-65.3945,-11.9423,Saraveca,LANGUAGE,LANGUAGE
SM243924,-65.3945,-11.9423,Saraveca,LANGUAGE,LANGUAGE
SM243924,-57.8,-16.07,Saraveca,LANGUAGE,LANGUAGE
SM243924,-57.8,-16.07,Saraveca,LANGUAGE,LANGUAGE
SM270603,-71.8533,6.9206,Saravena,ADM2,DISTRICT
SM3147,113.5,2.5,Sarawak,ADM1,DISTRICT
SM298946,27.9373,41.4548,Saray,ADM2,DISTRICT
SM298995,44.1773,38.6551,Saray,ADM2,DISTRICT
SM295281,-11.7963,13.0015,Saraya,ADM2,DISTRICT
SM277597,58.3167,33.4167,Sarayan,ADM2,DISTRICT
SM298913,34.8537,41.3373,Saraydüzü,ADM2,DISTRICT
SM299038,35.5163,39.7039,Saraykent,ADM2,DISTRICT
SM298308,28.88,37.9158,Sarayköy,ADM2,DISTRICT
SM298683,32.4119,38.2703,Sarayönü,ADM2,DISTRICT
SM305958,68.9731,37.9533,Sarband,ADM2,DISTRICT
SM291924,25.3833,44.4667,Sarbenii De Jos,ADM2,DISTRICT
SM292467,22.1379,47.1903,Sarbi,ADM2,DISTRICT
SM370214,-7.624,5.214,Sarbo,ADM2:ADM3,DISTRICT
SM14104,-7.6421,5.1857,Sarbo,ADM2,DISTRICT
SM277640,45.4832,36.2167,Sardasht,ADM2,DISTRICT
SM285244,11.2118,6.8746,Sardauna,ADM2,DISTRICT
SM3148,9,40,Sardegna,ADM1,DISTRICT
SM270389,-72.8204,8.2342,Sardinata,ADM2,DISTRICT
SM16212,-65.6396,18.3468,Sardinera Barrio,ADM2,DISTRICT
SM257385,8.92,40.4,Sardinia,ETHNICITY,ETHNICITY
SM257385,8.92,40.4,Sardinia,ETHNICITY,ETHNICITY
SM257385,8.92,40.4,Sardinia,ETHNICITY,ETHNICITY
SM257385,9,40,Sardinia,ETHNICITY,ETHNICITY
SM257385,9,40,Sardinia,ETHNICITY,ETHNICITY
SM257385,9,40,Sardinia,ETHNICITY,ETHNICITY
SM257385,9,40,Sardinia,ETHNICITY,ETHNICITY
SM257385,9,40,Sardinia,ETHNICITY,ETHNICITY
SM257385,9,40,Sardinia,ETHNICITY,ETHNICITY
SM289327,-8.1432,39.5564,Sardoal,ADM2,DISTRICT
SM263970,-42.403,-18.7735,Sardoá,ADM2,DISTRICT
SM268937,9.4333,47.05,Sarganserland,ADM2,DISTRICT
SM293910,73.4167,55.75,Sargatskiy rayon,ADM2,DISTRICT
SM302065,-97.6307,46.1079,Sargent,ADM2,DISTRICT
SM261096,-59.5,-26.8333,Sargento Cabral,ADM2,DISTRICT
SM375479,72.7197,32.0896,Sargodha,ADM2:ADM3,DISTRICT
SM10336,18.3901,9.1463,Sarh,ADM2,DISTRICT
SM3149,66.1667,35.8333,Sari Pul,ADM1,DISTRICT
SM18098,35.5095,37.1497,Saricam,ADM2,DISTRICT
SM292068,28.8187,44.9213,Sarichioi,ADM2,DISTRICT
SM15290,145.782,16.694,Sarigan Island,ADM2,DISTRICT
SM284368,111.6667,2,Sarikei,ADM2,DISTRICT
SM243940,75.1449,37.905,Sarikoli,LANGUAGE,LANGUAGE
SM303431,67.8,38.6667,Sariosiyo,ADM2,DISTRICT
SM257389,61.86,30.49,Sariq,ETHNICITY,ETHNICITY
SM289146,125.7547,38.5242,Sariwŏn,ADM2,DISTRICT
SM276228,21.4488,46.8615,Sarkad,ADM2,DISTRICT
SM13800,26.357,56.9059,Sarkanu pagasts,ADM2,DISTRICT
SM375306,85.5639,26.9687,Sarlahi,ADM2:ADM3,DISTRICT
SM243944,43.644,36.021,Sarli,DIALECT,LANGUAGE
SM294633,52.5833,55.25,Sarmanovskiy rayon,ADM2,DISTRICT
SM290717,25.4429,46.8868,Sarmas,ADM2,DISTRICT
SM291634,22.8199,47.3334,Sarmasag,ADM2,DISTRICT
SM291190,24.1703,46.7457,Sarmasu,ADM2,DISTRICT
SM276540,139.0833,-2.4167,Sarmi,ADM2,DISTRICT
SM261054,-63.4167,-28.0833,Sarmiento,ADM2,DISTRICT
SM260972,-68.6667,-32,Sarmiento,ADM2,DISTRICT
SM261110,-69,-45.3333,Sarmiento,ADM2,DISTRICT
SM290786,22.7696,45.51,Sarmizegetusa,ADM2,DISTRICT
SM10060,24.0333,41.7333,Sarnitsa Obshtina,ADM2,DISTRICT
SM18272,26.8948,51.3919,Sarny Raion (2020-),ADM2,DISTRICT
SM260160,69.0459,32.7522,Sarobi,ADM2,DISTRICT
SM276752,102.65,-2.3,Sarolangun,ADM2,DISTRICT
SM17640,-60.9849,13.9421,Sarot,ADM2,DISTRICT
SM17066,43.2927,54.928,Sarov Urban Okrug,ADM2,DISTRICT
SM3151,90.4167,27.0333,Sarpang,ADM1,DISTRICT
SM293054,44.8333,47.8333,Sarpinskiy rayon,ADM2,DISTRICT
SM250669,-8.34,6.16,Sarpo,ETHNICITY,ETHNICITY
SM250669,-8.34,6.16,Sarpo,ETHNICITY,ETHNICITY
SM250669,-9,6,Sarpo,ETHNICITY,ETHNICITY
SM250669,-9,6,Sarpo,ETHNICITY,ETHNICITY
SM277519,45.85,34.5,Sarpol-e-Zahab,ADM2,DISTRICT
SM286287,11.1117,59.2815,Sarpsborg,ADM2,DISTRICT
SM301752,-96.112,41.1129,Sarpy,ADM2,DISTRICT
SM13245,80.25,45.5,Sarqan Audany (excl. Burlyutobsin),ADM2,DISTRICT
SM284585,165.8452,-21.6421,Sarraméa,ADM2,DISTRICT
SM243954,-114.301,50.8822,Sarsi,LANGUAGE,LANGUAGE
SM257396,-119.94,52.43,Sarsi,ETHNICITY,ETHNICITY
SM257396,-119.94,52.43,Sarsi,ETHNICITY,ETHNICITY
SM257396,-119.94,52.43,Sarsi,ETHNICITY,ETHNICITY
SM257396,-110,54,Sarsi,ETHNICITY,ETHNICITY
SM257396,-110,54,Sarsi,ETHNICITY,ETHNICITY
SM257396,-110,54,Sarsi,ETHNICITY,ETHNICITY
SM257396,-111.04,53.15,Sarsi,ETHNICITY,ETHNICITY
SM257396,-111.04,53.15,Sarsi,ETHNICITY,ETHNICITY
SM257396,-111.04,53.15,Sarsi,ETHNICITY,ETHNICITY
SM243958,92.4094,27.3759,Sartang,LANGUAGE,LANGUAGE
SM274461,0.2652,48.0049,Sarthe,ADM2,DISTRICT
SM13068,142.3964,42.7384,Saru-gun,ADM2,DISTRICT
SM243962,17.031,10.5987,Sarua,LANGUAGE,LANGUAGE
SM243960,119.349,-1.7094,Sarudu,LANGUAGE,LANGUAGE
SM278176,142.1211,45.2408,Sarufutsu,ADM2,DISTRICT
SM243961,145.578,-5.0882,Saruga,LANGUAGE,LANGUAGE
SM298728,27.6471,38.7678,Saruhanlı,ADM2,DISTRICT
SM292234,24.0098,44.9259,Sarulesti,ADM2,DISTRICT
SM289919,26.7786,45.483,Sarulesti,ADM2,DISTRICT
SM289971,26.6166,44.4129,Sarulesti,ADM2,DISTRICT
SM266958,-49.4837,-23.2582,Sarutaiá,ADM2,DISTRICT
SM13246,68.8333,41.6667,Saryaghash Audany (excl. Keles),ADM2,DISTRICT
SM279852,65.5,53.4167,Sarykolskiy,ADM2,DISTRICT
SM13247,70.5,44.8333,Sarysu Audany (excl. Zhangatas Town),ADM2,DISTRICT
SM263971,-44.1344,-20.0446,Sarzedo,ADM2,DISTRICT
SM298409,30.6199,40.0467,Sarıcakaya,ADM2,DISTRICT
SM298727,28.7023,38.2486,Sarıgöl,ADM2,DISTRICT
SM298585,42.5901,40.3314,Sarıkamış,ADM2,DISTRICT
SM299026,35.3926,39.4985,Sarıkaya,ADM2,DISTRICT
SM298610,35.9745,39.0876,Sarıoğlan,ADM2,DISTRICT
SM298579,32.6265,36.7018,Sarıveliler,ADM2,DISTRICT
SM298643,33.8468,38.9929,Sarıyahşi,ADM2,DISTRICT
SM298515,29.0568,41.2148,Sarıyer,ADM2,DISTRICT
SM298611,36.5149,38.4802,Sarız,ADM2,DISTRICT
SM270050,-74.428,4.9289,Sasaima,ADM2,DISTRICT
SM243967,116.284,-8.5835,Sasak,LANGUAGE,LANGUAGE
SM257401,116.33,-8.49,Sasak,ETHNICITY,ETHNICITY
SM280529,128.9799,35.1448,Sasang,ADM2,DISTRICT
SM243965,6.0753,7.0619,Sasaru,LANGUAGE,LANGUAGE
SM278347,135.2309,35.0752,Sasayama,ADM2,DISTRICT
SM290048,21.7017,44.9039,Sasca Montana,ADM2,DISTRICT
SM291191,24.9963,46.1657,Saschiz,ADM2,DISTRICT
SM289832,23.569,45.8563,Sasciori,ADM2,DISTRICT
SM292152,27.0916,46.1929,Sascut,ADM2,DISTRICT
SM13069,129.6896,33.1899,Sasebo Shi (2010-),ADM2,DISTRICT
SM13070,139.7906,36.1105,Sashima-gun,ADM2,DISTRICT
SM3152,-106.001,54.0001,Saskatchewan,ADM1,DISTRICT
SM298189,41.4199,38.3346,Sason,ADM2,DISTRICT
SM3153,-6.5073,6.9659,Sassandra-Marahoué,ADM1,DISTRICT
SM243972,8.3933,40.748,Sassarese Sardinian,LANGUAGE,LANGUAGE
SM280982,-8.3333,4.8333,Sasstown 180606,ADM2,DISTRICT
SM274403,22.1667,61.5,Satakunta,ADM1:ADM2,DISTRICT
SM277055,74.0643,17.726,Satara,ADM2,DISTRICT
SM14974,147.0318,7.382,Satawal Municipality,ADM2,DISTRICT
SM243975,147.038,7.3608,Satawalese,LANGUAGE,LANGUAGE
SM292022,21.076,45.9393,Satchinez,ADM2,DISTRICT
SM243978,-56.8749,-3.639,Sateré-Mawé,LANGUAGE,LANGUAGE
SM283268,-106.1985,27.7858,Satevó,ADM2,DISTRICT
SM297587,100.4223,7.4796,Sathing Phra,ADM2,DISTRICT
SM297180,100.53,13.7175,Sathorn,ADM2,DISTRICT
SM286750,-74.25,-11.5,Satipo,ADM2,DISTRICT
SM270829,-72.7532,6.1058,Sativanorte,ADM2,DISTRICT
SM270830,-72.7151,6.071,Sativasur,ADM2,DISTRICT
SM262259,89.15,22.35,Satkhira,ADM2,DISTRICT
SM17067,59,55,Satkinskiy Rayon,ADM2,DISTRICT
SM276999,81,24.5,Satna,ADM2,DISTRICT
SM305384,18.3773,45.3537,Satnica Đakovačka,ADM2,DISTRICT
SM279030,133.55,34.5138,Satoshō,ADM2,DISTRICT
SM262290,24.0078,41.5985,Satovcha,ADM2,DISTRICT
SM14975,153.7365,5.334,Satowan Municipality,ADM2,DISTRICT
SM278505,130.4765,31.94,Satsuma,ADM2,DISTRICT
SM278506,130.259,31.8238,Satsumasendai,ADM2,DISTRICT
SM257411,-123.34,47.09,Satsup,ETHNICITY,ETHNICITY
SM296915,100.9275,12.7174,Sattahip,ADM2,DISTRICT
SM279200,139.7402,36.0708,Satte,ADM2,DISTRICT
SM291686,22.8357,47.7651,Satu Mare,ADM2,DISTRICT
SM291840,26.0134,47.8238,Satu Mare,ADM2,DISTRICT
SM3154,23,47.75,Satu Mare,ADM1,DISTRICT
SM265432,-35.8456,-9.5853,Satuba,ADM2,DISTRICT
SM262909,-45.2554,-3.848,Satubinha,ADM2,DISTRICT
SM297425,103.3301,15.227,Satuk,ADM2,DISTRICT
SM291035,23.4198,47.5719,Satulung,ADM2,DISTRICT
SM3155,100,6.8333,Satun,ADM1,DISTRICT
SM3156,-172.6333,-13.6333,Satupa'itea,ADM1,DISTRICT
SM257412,-82.34,30.71,Saturiwa,ETHNICITY,ETHNICITY
SM267660,-38.8017,-12.7662,Saubara,ADM2,DISTRICT
SM291687,22.4862,47.4604,Sauca,ADM2,DISTRICT
SM262736,-67.4167,-18.25,Saucarí,ADM2,DISTRICT
SM261165,-58.6667,-30,Sauce,ADM2,DISTRICT
SM292153,26.9337,46.6578,Saucesti,ADM2,DISTRICT
SM283269,-105.3151,28.0588,Saucillo,ADM2,DISTRICT
SM286136,6.3531,59.6507,Sauda,ADM2,DISTRICT
SM264737,-52.6082,-25.7047,Saudade do Iguaçu,ADM2,DISTRICT
SM266315,-53.0404,-26.879,Saudades,ADM2,DISTRICT
SM8789,42.5171,18.49,Saudi,ETHNICITY,ETHNICITY
SM243984,46.4714,23.614,Saudi Arabian Sign Language,LANGUAGE,LANGUAGE
SM11194,24.525,59.2926,Saue vald,ADM2,DISTRICT
SM257413,144.02,-7.09,Saui,ETHNICITY,ETHNICITY
SM257414,-57.17,-1.6,Sauiana,ETHNICITY,ETHNICITY
SM303275,-89.9484,43.4268,Sauk,ADM2,DISTRICT
SM257415,-87.75,45.3,Sauk,ETHNICITY,ETHNICITY
SM13801,25.418,56.2842,Saukas pagasts,ADM2,DISTRICT
SM290655,23.4877,44.8125,Saulesti,ADM2,DISTRICT
SM291192,24.2095,46.6333,Saulia,ADM2,DISTRICT
SM13802,24.4112,57.2584,Saulkrasti,ADM2,DISTRICT
SM4014,24.4261,57.2514,Saulkrastu Novads,ADM1,DISTRICT
SM13803,24.421,57.2521,Saulkrastu pagasts,ADM2,DISTRICT
SM257416,-87.15,48.88,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-87.15,48.88,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-87.15,48.88,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-87.15,48.88,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-97.37,49.9,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-97.37,49.9,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-97.37,49.9,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-97.37,49.9,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-96.01,51.48,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-96.01,51.48,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-96.01,51.48,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-96.01,51.48,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-102.5,50,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-102.5,50,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-102.5,50,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-102.5,50,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-100,51,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-100,51,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-100,51,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-100,51,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-97.25,47.6,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-97.25,47.6,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-97.25,47.6,Saulteaux,ETHNICITY,ETHNICITY
SM257416,-97.25,47.6,Saulteaux,ETHNICITY,ETHNICITY
SM257417,-62.98,66.68,Saumingmiut,ETHNICITY,ETHNICITY
SM13804,26.6175,56.3912,Saunas pagasts,ADM2,DISTRICT
SM301753,-96.6374,41.2264,Saunders,ADM2,DISTRICT
SM243992,77.56,27.46,Sauraseni Prakrit,LANGUAGE,LANGUAGE
SM243988,77.9427,9.9917,Saurashtra,LANGUAGE,LANGUAGE
SM243991,136.79,-2.3335,Sauri,LANGUAGE,LANGUAGE
SM243989,87.6432,24.8124,Sauria Paharia,LANGUAGE,LANGUAGE
SM260343,20.5616,-9.3103,Saurimo,ADM2,DISTRICT
SM243994,139.627,-3.1663,Sause,LANGUAGE,LANGUAGE
SM243993,145.492,-5.6873,Sausi,LANGUAGE,LANGUAGE
SM13805,25.6334,56.8291,Sausnejas pagasts,ADM2,DISTRICT
SM17641,-60.9438,13.8127,Saut,ADM2,DISTRICT
SM17642,-61.0337,13.7774,Sauzay,ADM2,DISTRICT
SM281470,49.8,-14.2,Sava,ADM1:ADM2,DISTRICT
SM290132,23.4481,46.6654,Savadisla,ADM2,DISTRICT
SM11806,52.6825,35.9776,Savadkuh-e Shomali,ADM2,DISTRICT
SM262161,1.849,7.862,Savalou,ADM2,DISTRICT
SM3158,0.5,10.5,Savanes,ADM1,DISTRICT
SM3157,-5.4942,9.4883,Savanes,ADM1,DISTRICT
SM3935,-1.6,9.15,Savannah,ADM1,DISTRICT
SM12712,-78.1303,18.2196,Savannah-la-mar Business Dist.,ADM2,DISTRICT
SM3159,105.75,16.5,Savannakhét,ADM1,DISTRICT
SM3160,57.5,-20.4667,Savanne,ADM1,DISTRICT
SM17643,-60.9202,13.771,Savannes,ADM2,DISTRICT
SM17644,-61.0514,13.7786,Savannesgeorge/Constitution,ADM2,DISTRICT
SM243997,84.1414,18.7302,Savara,LANGUAGE,LANGUAGE
SM290530,22.2651,46.0253,Savarsin,ADM2,DISTRICT
SM298169,27.6637,39.3926,Savaştepe,ADM2,DISTRICT
SM277568,49.9833,35.1,Saveh,ADM2,DISTRICT
SM274926,-0.8278,9.6247,Savelugu Nanton,ADM2,DISTRICT
SM292596,26.8668,47.96,Saveni,ADM2,DISTRICT
SM243998,71.5557,35.3029,Savi,LANGUAGE,LANGUAGE
SM243998,71.5557,35.3029,Savi,LANGUAGE,LANGUAGE
SM243998,71.4619,35.1668,Savi,LANGUAGE,LANGUAGE
SM243998,71.4619,35.1668,Savi,LANGUAGE,LANGUAGE
SM291366,26.484,46.8647,Savinesti,ADM2,DISTRICT
SM292936,41.3333,56.5833,Savinskiy rayon,ADM2,DISTRICT
SM461530,159.8002,-9.1251,Savo,ETHNICITY,ETHNICITY
SM274424,6.4855,45.4681,Savoie,ADM2,DISTRICT
SM277968,8.2667,44.3,Savona,ADM2,DISTRICT
SM346774,28.8218,62.0092,Savonlinna,ADM2:ADM3,DISTRICT
SM244001,159.814,-9.1285,Savosavo,LANGUAGE,LANGUAGE
SM299624,30.086,48.1327,Savrans'kyi,ADM2,DISTRICT
SM295358,159.6426,-9.3775,Savulei,ADM2,DISTRICT
SM257427,121.92,-10.57,Savunese,ETHNICITY,ETHNICITY
SM298749,40.8889,37.5368,Savur,ADM2,DISTRICT
SM9811,27.5942,53.9178,Savyetski Rayon,ADM2,DISTRICT
SM262162,2.567,7.959,Savè,ADM2,DISTRICT
SM274927,-2.4135,9.2732,Sawa-Tuna-Kalba,ADM2,DISTRICT
SM297027,100.2896,14.7464,Sawaengha,ADM2,DISTRICT
SM276646,100.75,-0.6,Sawahlunto,ADM2,DISTRICT
SM244002,128.086,0.5111,Sawai,LANGUAGE,LANGUAGE
SM257428,127.82,-0.12,Sawai,ETHNICITY,ETHNICITY
SM277184,76.4,26.18,Sawai Madhopur (1997-),ADM2,DISTRICT
SM257429,-118,41,Sawakudokwa,ETHNICITY,ETHNICITY
SM297739,99.7849,15.6025,Sawang Arom,ADM2,DISTRICT
SM297498,103.4495,17.4559,Sawang Daen Din,ADM2,DISTRICT
SM297603,99.854,17.299,Sawankhalok,ADM2,DISTRICT
SM244007,136.296,-1.9147,Saweru,LANGUAGE,LANGUAGE
SM244011,138.643,-5.9929,Sawi,LANGUAGE,LANGUAGE
SM296930,99.0186,10.2401,Sawi,ADM2,DISTRICT
SM244010,125.078,-8.2911,Sawila,LANGUAGE,LANGUAGE
SM244012,15.9742,29.16,Sawknah-Fogaha,LANGUAGE,LANGUAGE
SM257433,142.37,-4.06,Sawos,ETHNICITY,ETHNICITY
SM303277,-91.1447,45.88,Sawyer,ADM2,DISTRICT
SM12713,-77.4969,18.3788,Sawyers,ADM2,DISTRICT
SM244017,1.9202,6.5827,Saxwe Gbe,LANGUAGE,LANGUAGE
SM244022,9.7175,9.6944,Saya,LANGUAGE,LANGUAGE
SM9580,65.709,36.1498,Sayad (2005-),ADM2,DISTRICT
SM297909,10.8908,35.6668,Sayada-Lamta-Bou Hjar,ADM2,DISTRICT
SM279201,139.4121,35.853,Sayama,ADM2,DISTRICT
SM293209,91.4133,53.0839,Sayanogorsk,ADM2,DISTRICT
SM293450,95,54.75,Sayanskiy rayon,ADM2,DISTRICT
SM275201,-90.1634,16.3316,Sayaxché,ADM2,DISTRICT
SM9581,67.6105,35.118,Sayghan,ADM2,DISTRICT
SM305075,51.2869,15.5345,Sayhut,ADM2,DISTRICT
SM260233,68.6573,33.9249,Sayid Abad,ADM2,DISTRICT
SM283658,105.5592,49.093,Saykhan,ADM2,DISTRICT
SM279885,69.75,42.4167,Sayramskiy,ADM2,DISTRICT
SM281847,-103.5734,19.8772,Sayula,ADM2,DISTRICT
SM244024,-94.9531,17.8606,Sayula Popoluca,LANGUAGE,LANGUAGE
SM282778,-94.9656,17.7926,Sayula de Alemán,ADM2,DISTRICT
SM304819,48.8315,15.9831,Sayun,ADM2,DISTRICT
SM303426,68.85,40.7167,Sayxunobod,ADM2,DISTRICT
SM9582,69.4797,33.7124,Sayyid Karam (2005-),ADM2,DISTRICT
SM9583,69.2621,35.0468,Sayyid Khel,ADM2,DISTRICT
SM278349,134.3706,35.0215,Sayō,ADM2,DISTRICT
SM283072,-103.2691,23.5964,Saín Alto,ADM2,DISTRICT
SM2950,0.3333,34.6667,Saïda,ADM1,DISTRICT
SM457347,0.1667,34.6667,Saïda District,ADM2,DISTRICT
SM274474,4.5882,46.6365,Saône-et-Loire,ADM2,DISTRICT
SM267661,-40.3751,-10.8446,Saúde,ADM2,DISTRICT
SM257436,40.45,32.42,Sbaa,ETHNICITY,ETHNICITY
SM298112,9.0661,35.2607,Sbeitla,ADM2,DISTRICT
SM298113,9.1729,35.5028,Sbiba,ADM2,DISTRICT
SM298101,10.0309,35.9204,Sbikha,ADM2,DISTRICT
SM290405,23.5482,44.4659,Scaesti,ADM2,DISTRICT
SM290930,27.4433,44.7326,Scanteia,ADM2,DISTRICT
SM290866,27.5886,46.9241,Scanteia,ADM2,DISTRICT
SM290462,28.0043,45.6883,Scanteiesti,ADM2,DISTRICT
SM291454,24.5833,43.9853,Scarisoara,ADM2,DISTRICT
SM289833,22.8817,46.4607,Scarisoara,ADM2,DISTRICT
SM261662,152.8069,-28.0402,Scenic Rim,ADM2,DISTRICT
SM3163,9.524,47.1743,Schaan,ADM1,DISTRICT
SM268944,8.6352,47.6944,Schaffhausen,ADM2,DISTRICT
SM3164,8.6339,47.7103,Schaffhausen,ADM1,DISTRICT
SM291841,26.1809,47.6503,Scheia,ADM2,DISTRICT
SM261812,15.1038,47.9232,Scheibbs,ADM2,DISTRICT
SM290463,27.8523,45.5181,Schela,ADM2,DISTRICT
SM3165,9.551,47.2343,Schellenberg,ADM1,DISTRICT
SM301909,-74.0586,42.8181,Schenectady,ADM2,DISTRICT
SM9425,6.3517,49.4886,Schengen (2011-),ADM1:ADM2,DISTRICT
SM285807,5.487,52.0877,Scherpenzeel,ADM2,DISTRICT
SM285697,4.3868,51.9265,Schiedam,ADM2,DISTRICT
SM370435,6.0986,49.8306,Schieren,ADM1:ADM2:ADM3,DISTRICT
SM285741,6.2292,53.4871,Schiermonnikoog,ADM2,DISTRICT
SM244030,6.1667,53.4833,Schiermonnikoog Frisian,LANGUAGE,LANGUAGE
SM370509,6.0149,49.5056,Schifflange,ADM1:ADM2:ADM3,DISTRICT
SM291455,24.5593,44.3568,Schitu,ADM2,DISTRICT
SM290591,25.8424,44.1457,Schitu,ADM2,DISTRICT
SM290868,27.767,47.0026,Schitu Duca,ADM2,DISTRICT
SM291288,25.0058,45.1863,Schitu Golesti,ADM2,DISTRICT
SM302759,-100.5386,30.8975,Schleicher,ADM2,DISTRICT
SM268945,8.5111,47.75,Schleitheim,ADM2,DISTRICT
SM3166,9.75,54.2,Schleswig-Holstein,ADM1,DISTRICT
SM300407,-84.3147,32.2617,Schley,ADM2,DISTRICT
SM301910,-74.4421,42.5882,Schoharie,ADM2,DISTRICT
SM12714,-77.7188,18.0238,Scholefield,ADM2,DISTRICT
SM301330,-86.1773,46.0425,Schoolcraft,ADM2,DISTRICT
SM285628,3.8868,51.6929,Schouwen-Duiveland,ADM2,DISTRICT
SM266316,-49.0627,-26.3749,Schroeder,ADM2,DISTRICT
SM370526,6.2686,49.6206,Schuttrange,ADM1:ADM2:ADM3,DISTRICT
SM301912,-76.8752,42.3938,Schuyler,ADM2,DISTRICT
SM301620,-92.5209,40.4703,Schuyler,ADM2,DISTRICT
SM300676,-90.6151,40.158,Schuyler,ADM2,DISTRICT
SM302334,-76.216,40.7058,Schuylkill,ADM2,DISTRICT
SM261873,11.7667,47.2667,Schwaz,ADM2,DISTRICT
SM268953,8.6362,47.0281,Schwyz,ADM2,DISTRICT
SM3167,8.741,47.0405,Schwyz,ADM1,DISTRICT
SM261831,13.6,48.4333,Schärding,ADM2,DISTRICT
SM302154,-82.9928,38.804,Scioto,ADM2,DISTRICT
SM290656,23.4713,45.0287,Scoarta,ADM2,DISTRICT
SM290869,26.8985,47.4053,Scobinti,ADM2,DISTRICT
SM291457,24.5539,44.5679,Scornicesti,ADM2,DISTRICT
SM292690,27.6148,45.3491,Scortaru Nou,ADM2,DISTRICT
SM291565,25.8471,45.0888,Scorteni,ADM2,DISTRICT
SM292154,26.6854,46.5804,Scorteni,ADM2,DISTRICT
SM289920,26.6618,45.362,Scortoasa,ADM2,DISTRICT
SM302012,-79.4804,34.8409,Scotland,ADM2,DISTRICT
SM301621,-92.147,40.4526,Scotland,ADM2,DISTRICT
SM3168,-4,56,Scotland,ADM1,DISTRICT
SM244036,-3.7101,55.887,Scots,LANGUAGE,LANGUAGE
SM302952,-82.603,36.7142,Scott,ADM2,DISTRICT
SM302617,-84.5035,36.4285,Scott,ADM2,DISTRICT
SM301510,-89.5685,37.0531,Scott,ADM2,DISTRICT
SM301484,-89.5376,32.4064,Scott,ADM2,DISTRICT
SM301406,-93.5359,44.6485,Scott,ADM2,DISTRICT
SM300761,-85.7475,38.6851,Scott,ADM2,DISTRICT
SM300995,-84.5839,38.2916,Scott,ADM2,DISTRICT
SM300864,-90.6232,41.6371,Scott,ADM2,DISTRICT
SM300677,-90.4747,39.6441,Scott,ADM2,DISTRICT
SM300973,-100.9069,38.4822,Scott,ADM2,DISTRICT
SM302412,-94.0633,34.8608,Scott,ADM2,DISTRICT
SM257439,-3,57.16,Scottish,ETHNICITY,ETHNICITY
SM257439,-3,57.16,Scottish,ETHNICITY,ETHNICITY
SM257439,-3,57.16,Scottish,ETHNICITY,ETHNICITY
SM257439,-3,57.16,Scottish,ETHNICITY,ETHNICITY
SM257439,-3,57.16,Scottish,ETHNICITY,ETHNICITY
SM257439,-3,57.16,Scottish,ETHNICITY,ETHNICITY
SM257439,-2.5,55.5,Scottish,ETHNICITY,ETHNICITY
SM257439,-2.5,55.5,Scottish,ETHNICITY,ETHNICITY
SM257439,-2.5,55.5,Scottish,ETHNICITY,ETHNICITY
SM257439,-2.5,55.5,Scottish,ETHNICITY,ETHNICITY
SM257439,-2.5,55.5,Scottish,ETHNICITY,ETHNICITY
SM257439,-2.5,55.5,Scottish,ETHNICITY,ETHNICITY
SM257439,-1.15,60.15,Scottish,ETHNICITY,ETHNICITY
SM257439,-1.15,60.15,Scottish,ETHNICITY,ETHNICITY
SM257439,-1.15,60.15,Scottish,ETHNICITY,ETHNICITY
SM257439,-1.15,60.15,Scottish,ETHNICITY,ETHNICITY
SM257439,-1.15,60.15,Scottish,ETHNICITY,ETHNICITY
SM257439,-1.15,60.15,Scottish,ETHNICITY,ETHNICITY
SM257439,-4,55,Scottish,ETHNICITY,ETHNICITY
SM257439,-4,55,Scottish,ETHNICITY,ETHNICITY
SM257439,-4,55,Scottish,ETHNICITY,ETHNICITY
SM257439,-4,55,Scottish,ETHNICITY,ETHNICITY
SM257439,-4,55,Scottish,ETHNICITY,ETHNICITY
SM257439,-4,55,Scottish,ETHNICITY,ETHNICITY
SM257439,-2.25,57.25,Scottish,ETHNICITY,ETHNICITY
SM257439,-2.25,57.25,Scottish,ETHNICITY,ETHNICITY
SM257439,-2.25,57.25,Scottish,ETHNICITY,ETHNICITY
SM257439,-2.25,57.25,Scottish,ETHNICITY,ETHNICITY
SM257439,-2.25,57.25,Scottish,ETHNICITY,ETHNICITY
SM257439,-2.25,57.25,Scottish,ETHNICITY,ETHNICITY
SM257439,-3,56.25,Scottish,ETHNICITY,ETHNICITY
SM257439,-3,56.25,Scottish,ETHNICITY,ETHNICITY
SM257439,-3,56.25,Scottish,ETHNICITY,ETHNICITY
SM257439,-3,56.25,Scottish,ETHNICITY,ETHNICITY
SM257439,-3,56.25,Scottish,ETHNICITY,ETHNICITY
SM257439,-3,56.25,Scottish,ETHNICITY,ETHNICITY
SM274731,-2.8333,55.5833,Scottish Borders,ADM1:ADM2,DISTRICT
SM244037,-5.2437,56.7574,Scottish Gaelic,LANGUAGE,LANGUAGE
SM301754,-103.708,41.8506,Scotts Bluff,ADM2,DISTRICT
SM12715,-76.8394,18.1908,Scotts Hall,ADM2,DISTRICT
SM12716,-77.3982,17.9992,Scotts Pass,ADM2,DISTRICT
SM300408,-81.6119,32.7506,Screven,ADM2,DISTRICT
SM291925,24.953,44.1645,Scrioastea,ADM2,DISTRICT
SM292235,24.1798,44.8488,Scundu,ADM2,DISTRICT
SM302760,-100.9164,32.7463,Scurry,ADM2,DISTRICT
SM291926,25.259,44.3526,Scurtu Mare,ADM2,DISTRICT
SM289921,26.9375,44.8396,Scutelnici,ADM2,DISTRICT
SM454376,40,45,Scythian,ETHNICITY,ETHNICITY
SM231882,-79.6036,33.6632,Sea Island Creole English,LANGUAGE,LANGUAGE
SM267662,-41.9168,-12.383,Seabra,ADM2,DISTRICT
SM291927,25.0742,43.7493,Seaca,ADM2,DISTRICT
SM291458,24.7615,44.1516,Seaca,ADM2,DISTRICT
SM290406,23.1996,43.9282,Seaca De Camp,ADM2,DISTRICT
SM290407,23.3236,44.3711,Seaca De Padure,ADM2,DISTRICT
SM12717,-77.905,18.2434,Seaford Town,ADM2,DISTRICT
SM12718,-76.4566,17.9442,Seaforth,ADM2,DISTRICT
SM266318,-52.3486,-27.1448,Seara,ADM2,DISTRICT
SM302413,-92.6995,35.9109,Searcy,ADM2,DISTRICT
SM12719,-78.136,18.2168,Seaton Crescent,ADM2,DISTRICT
SM12720,-76.8411,18.0062,Seaview Gardens,ADM2,DISTRICT
SM12721,-76.8237,18.019,Seaward Pen,ADM2,DISTRICT
SM244040,27.5144,-11.9396,Seba,LANGUAGE,LANGUAGE
SM324020,-114.7349,53.5588,Seba Beach,ADM2:ADM3,DISTRICT
SM302414,-94.2739,35.1993,Sebastian,ADM2,DISTRICT
SM9856,-66.2167,-19.2167,Sebastian Pagador Province,ADM2,DISTRICT
SM266959,-49.9075,-20.6156,Sebastianópolis do Sul,ADM2,DISTRICT
SM265143,-44.8361,-10.5976,Sebastião Barros,ADM2,DISTRICT
SM267663,-43.1373,-14.5685,Sebastião Laranjeiras,ADM2,DISTRICT
SM265144,-44.0255,-7.5132,Sebastião Leal,ADM2,DISTRICT
SM244043,37.9891,8.1188,Sebat Bet Gurage,LANGUAGE,LANGUAGE
SM457656,-1.5,34.5,Sebdou District,ADM2,DISTRICT
SM250652,34.5,1.65,Sebei,ETHNICITY,ETHNICITY
SM250652,34.5,1.65,Sebei,ETHNICITY,ETHNICITY
SM250652,34.8,1,Sebei,ETHNICITY,ETHNICITY
SM250652,34.8,1,Sebei,ETHNICITY,ETHNICITY
SM298223,31.583,40.4216,Seben,ADM2,DISTRICT
SM284322,100.4721,5.2256,Seberang Perai Selatan,ADM2,DISTRICT
SM284323,100.426,5.3877,Seberang Perai Tengah,ADM2,DISTRICT
SM284324,100.3787,5.4084,Seberang Perai Utara,ADM2,DISTRICT
SM265932,-53.3648,-27.5088,Seberi,ADM2,DISTRICT
SM244044,111.212,-0.0246,Seberuang,LANGUAGE,LANGUAGE
SM289834,23.5768,45.9424,Sebes,ADM2,DISTRICT
SM294130,28.5,56.25,Sebezhskiy rayon,ADM2,DISTRICT
SM290531,22.1548,46.3877,Sebis,ADM2,DISTRICT
SM14198,-16.0021,18.0755,Sebkha,ADM2,DISTRICT
SM244047,114.178,3.427,Sebop,LANGUAGE,LANGUAGE
SM303748,-72.0639,8.1471,Seboruco,ADM2,DISTRICT
SM244050,110.48,1.421,Sebuyau,LANGUAGE,LANGUAGE
SM291566,25.6846,45.275,Secaria,ADM2,DISTRICT
SM292023,21.8083,45.905,Secas,ADM2,DISTRICT
SM13806,25.3764,56.5354,Seces pagasts,ADM2,DISTRICT
SM257440,-123.49,49.52,Sechalt,ETHNICITY,ETHNICITY
SM244054,-123.765,49.4757,Sechelt,LANGUAGE,LANGUAGE
SM293781,45.9167,55.1667,Sechenovskiy rayon,ADM2,DISTRICT
SM286818,-80.6718,-5.8705,Sechura,ADM2,DISTRICT
SM244053,-80.8283,-5.569,Sechuran,LANGUAGE,LANGUAGE
SM257441,-77.38,34.91,Secotan,ETHNICITY,ETHNICITY
SM244055,-75.5547,-0.4598,Secoya,LANGUAGE,LANGUAGE
SM257442,-74,0.54,Secoya,ETHNICITY,ETHNICITY
SM290408,23.2953,44.4785,Secu,ADM2,DISTRICT
SM291367,26.8106,46.8685,Secuieni,ADM2,DISTRICT
SM290718,24.9695,46.2762,Secuieni,ADM2,DISTRICT
SM290532,21.0029,46.0926,Secusigiu,ADM2,DISTRICT
SM13807,25.75,57.6528,Seda,ADM2,DISTRICT
SM244059,108.027,14.7793,Sedang,LANGUAGE,LANGUAGE
SM371697,33.5672,-16.1386,Sede,ADM2:ADM3,DISTRICT
SM265933,-53.9615,-27.6449,Sede Nova,ADM2,DISTRICT
SM293911,75.5,57,Sedel'nikovskiy rayon,ADM2,DISTRICT
SM13808,26.2154,55.8912,Sederes pagasts,ADM2,DISTRICT
SM12722,-77.3264,17.8475,Sedgepond,ADM2,DISTRICT
SM323942,-111.6931,52.7776,Sedgewick,ADM2:ADM3,DISTRICT
SM300974,-97.461,37.6848,Sedgwick,ADM2,DISTRICT
SM303372,-102.3518,40.8759,Sedgwick,ADM2,DISTRICT
SM305112,28.1828,-26.5669,Sedibeng,ADM2,DISTRICT
SM244065,120.378,-1.3729,Sedoa,LANGUAGE,LANGUAGE
SM268938,9.0168,47.2246,See-Gaster,ADM2,DISTRICT
SM246189,121.358,23.9032,Seediq,LANGUAGE,LANGUAGE
SM14105,-9.1667,5.5,Seedor,ADM2,DISTRICT
SM370244,-8.6821,5.6817,Seekon,ADM2:ADM3,DISTRICT
SM244069,-4.582,11.1285,Seeku,LANGUAGE,LANGUAGE
SM17924,7.2633,47.0664,Seeland District,ADM2,DISTRICT
SM298546,26.8888,38.1814,Seferihisar,ADM2,DISTRICT
SM281444,-4.68,33.7998,Sefrou,ADM2,DISTRICT
SM347472,-3,53.5,Sefton,ADM1:ADM2:ADM3,DISTRICT
SM4404,-2.8752,6.0418,Sefwi-Akontombra,ADM2,DISTRICT
SM4405,-2.4833,6.2,Sefwi-Wiawso (2008-),ADM2,DISTRICT
SM244071,116.954,1.977,Segai,LANGUAGE,LANGUAGE
SM284311,102.8167,2.6,Segamat,ADM2,DISTRICT
SM290409,23.742,44.0943,Segarcea,ADM2,DISTRICT
SM291928,24.8142,43.8242,Segarcea-vale,ADM2,DISTRICT
SM262108,3.6944,10.9278,Segbana,ADM2,DISTRICT
SM244077,39.1225,-5.0066,Segeju,LANGUAGE,LANGUAGE
SM250671,38.22,-4.83,Segeju,ETHNICITY,ETHNICITY
SM11214,37.5515,5.5463,Segen Peoples,ADM2,DISTRICT
SM244075,131.218,-1.3693,Seget,LANGUAGE,LANGUAGE
SM305484,16.2122,43.5431,Seget,ADM2,DISTRICT
SM17068,34.3333,63.5833,Segezhskiy Rayon,ADM2,DISTRICT
SM274072,-4,41.1667,Segovia,ADM2,DISTRICT
SM270153,-74.6427,7.2813,Segovia,ADM2,DISTRICT
SM265934,-52.9228,-29.2971,Segredo,ADM2,DISTRICT
SM16213,-66.6213,18.0157,Segundo Barrio,ADM2,DISTRICT
SM271052,-75.4965,20.4128,Segundo Frente,ADM2,DISTRICT
SM277000,77.1285,22.9911,Sehore,ADM2,DISTRICT
SM244082,-2.7315,6.3172,Sehwi,LANGUAGE,LANGUAGE
SM18099,27.4386,38.6166,Sehzadeler,ADM2,DISTRICT
SM289231,-7.7025,40.422,Seia,ADM2,DISTRICT
SM291752,24.2391,46,Seica Mare,ADM2,DISTRICT
SM291753,24.0957,46.042,Seica Mica,ADM2,DISTRICT
SM244083,144.25,-1.2667,Seimat,LANGUAGE,LANGUAGE
SM290194,28.0876,44.4018,Seimeni,ADM2,DISTRICT
SM11228,22.788,62.8092,Seinaejoki (2009-),ADM2,DISTRICT
SM274516,1.1252,49.6728,Seine-Maritime,ADM2,DISTRICT
SM274508,2.476,48.9142,Seine-Saint-Denis,ADM2,DISTRICT
SM274507,2.9591,48.646,Seine-et-Marne,ADM2,DISTRICT
SM291036,23.2833,47.75,Seini,ADM2,DISTRICT
SM278944,139.2662,37.9835,Seirō,ADM2,DISTRICT
SM244084,128.068,-3.6111,Seit-Kaitetu,LANGUAGE,LANGUAGE
SM290533,20.8446,46.1086,Seitin,ADM2,DISTRICT
SM289340,-9.1037,38.6117,Seixal,ADM2,DISTRICT
SM279629,132.6326,33.3941,Seiyo,ADM2,DISTRICT
SM4015,24.5461,57.2122,Sejas Novads,ADM1,DISTRICT
SM13809,24.5471,57.2121,Sejas novads,ADM2,DISTRICT
SM298058,9.3041,37.1327,Sejnane,ADM2,DISTRICT
SM288681,23.3043,54.0956,Sejny,ADM2,DISTRICT
SM3171,127.2924,36.593,Sejong,ADM1,DISTRICT
SM297313,103.8962,17.9873,Seka,ADM2,DISTRICT
SM276380,110.9507,0.0349,Sekadau,ADM2,DISTRICT
SM244090,-125.664,57.4421,Sekani,LANGUAGE,LANGUAGE
SM257446,-131.76,56.11,Sekani,ETHNICITY,ETHNICITY
SM257446,-131.76,56.11,Sekani,ETHNICITY,ETHNICITY
SM257446,-131.76,56.11,Sekani,ETHNICITY,ETHNICITY
SM257446,-123,56,Sekani,ETHNICITY,ETHNICITY
SM257446,-123,56,Sekani,ETHNICITY,ETHNICITY
SM257446,-123,56,Sekani,ETHNICITY,ETHNICITY
SM257446,-120,56.14,Sekani,ETHNICITY,ETHNICITY
SM257446,-120,56.14,Sekani,ETHNICITY,ETHNICITY
SM257446,-120,56.14,Sekani,ETHNICITY,ETHNICITY
SM244088,113.562,2.4757,Sekapan,LANGUAGE,LANGUAGE
SM244087,132.469,-2.6585,Sekar,LANGUAGE,LANGUAGE
SM305250,30.9565,-18.2357,Seke,ADM2,DISTRICT
SM244091,83.8064,28.9251,Seke (Nepal),LANGUAGE,LANGUAGE
SM244092,168.197,-15.8839,Seke (Vanuatu),LANGUAGE,LANGUAGE
SM305127,29.838,-24.8281,Sekhukhune,ADM2,DISTRICT
SM244094,9.7921,1.523,Seki,LANGUAGE,LANGUAGE
SM279810,136.9167,35.4833,Seki,ADM2,DISTRICT
SM244097,119.959,-2.1431,Seko Padang,LANGUAGE,LANGUAGE
SM244096,119.823,-2.2207,Seko Tengah,LANGUAGE,LANGUAGE
SM4413,-1.7138,4.9347,Sekondi Takoradi (2008-2018),ADM2,DISTRICT
SM244098,0.5886,7.1675,Sekpele,LANGUAGE,LANGUAGE
SM11434,-1.2833,6.9,Sekyere Afram Plains (2012-),ADM2,DISTRICT
SM11435,-0.7408,7.2167,Sekyere Afram Plains North,ADM2,DISTRICT
SM11436,-1.3833,7.0167,Sekyere Central,ADM2,DISTRICT
SM11437,-1.3961,6.8468,Sekyere East (2008-),ADM2,DISTRICT
SM11438,-1.4833,6.9333,Sekyere South,ADM2,DISTRICT
SM286110,9.5342,61.7728,Sel,ADM2,DISTRICT
SM274023,37.44,16.88,Sela,ADM2,DISTRICT
SM3172,101.5,3.1667,Selangor (1974-),ADM1,DISTRICT
SM244102,101.614,3.1349,Selangor Sign Language,LANGUAGE,LANGUAGE
SM297472,104.0003,16.047,Selaphum,ADM2,DISTRICT
SM244105,130.961,-8.1838,Selaru,LANGUAGE,LANGUAGE
SM290304,25.3129,44.4871,Selaru,ADM2,DISTRICT
SM244106,120.496,-6.1015,Selayar,LANGUAGE,LANGUAGE
SM11741,120.8,-6.8167,Selayar Islands Regency,ADM2,DISTRICT
SM265935,-52.9824,-28.6764,Selbach,ADM2,DISTRICT
SM286193,11.0394,63.2177,Selbu,ADM2,DISTRICT
SM305485,16.8463,43.2944,Selca,ADM2,DISTRICT
SM244110,0.4246,7.1746,Selee,LANGUAGE,LANGUAGE
SM293218,132,52.5,Selemdzhinskiy rayon,ADM2,DISTRICT
SM298729,28.874,38.7548,Selendi,ADM2,DISTRICT
SM283769,103.9732,49.4581,Selenge,ADM2,DISTRICT
SM3173,106.4167,49.6667,Selenge,ADM1,DISTRICT
SM17069,106.3333,51.1667,Selenginskiy Rayon,ADM2,DISTRICT
SM244111,147.234,-6.0353,Selepet,LANGUAGE,LANGUAGE
SM260439,14.4898,-11.4986,Seles,ADM2,DISTRICT
SM290534,21.7488,46.3789,Seleus,ADM2,DISTRICT
SM3174,27.8347,-21.9758,Selibe Phikwe,ADM1,DISTRICT
SM298586,42.7896,40.4644,Selim,ADM2,DISTRICT
SM291754,24.2199,45.7514,Selimbar,ADM2,DISTRICT
SM294883,41.75,55.8333,Selivanovskiy rayon,ADM2,DISTRICT
SM294775,33.4167,56.8333,Selizharovskiy rayon,ADM2,DISTRICT
SM286209,8.6267,59.4827,Seljord,ADM2,DISTRICT
SM241686,-68.5,-54,Selk'nam,LANGUAGE,LANGUAGE
SM244113,82.3004,59.9409,Selkup,LANGUAGE,LANGUAGE
SM257449,76.43,69.98,Selkup,ETHNICITY,ETHNICITY
SM257449,76.43,69.98,Selkup,ETHNICITY,ETHNICITY
SM257449,76.43,69.98,Selkup,ETHNICITY,ETHNICITY
SM257449,76.43,69.98,Selkup,ETHNICITY,ETHNICITY
SM257449,76.43,69.98,Selkup,ETHNICITY,ETHNICITY
SM257449,90,59,Selkup,ETHNICITY,ETHNICITY
SM257449,90,59,Selkup,ETHNICITY,ETHNICITY
SM257449,90,59,Selkup,ETHNICITY,ETHNICITY
SM257449,90,59,Selkup,ETHNICITY,ETHNICITY
SM257449,90,59,Selkup,ETHNICITY,ETHNICITY
SM257449,86.9795,65.7181,Selkup,ETHNICITY,ETHNICITY
SM257449,86.9795,65.7181,Selkup,ETHNICITY,ETHNICITY
SM257449,86.9795,65.7181,Selkup,ETHNICITY,ETHNICITY
SM257449,86.9795,65.7181,Selkup,ETHNICITY,ETHNICITY
SM257449,86.9795,65.7181,Selkup,ETHNICITY,ETHNICITY
SM257449,87.9523,65.7961,Selkup,ETHNICITY,ETHNICITY
SM257449,87.9523,65.7961,Selkup,ETHNICITY,ETHNICITY
SM257449,87.9523,65.7961,Selkup,ETHNICITY,ETHNICITY
SM257449,87.9523,65.7961,Selkup,ETHNICITY,ETHNICITY
SM257449,87.9523,65.7961,Selkup,ETHNICITY,ETHNICITY
SM257449,82.2995,65.5345,Selkup,ETHNICITY,ETHNICITY
SM257449,82.2995,65.5345,Selkup,ETHNICITY,ETHNICITY
SM257449,82.2995,65.5345,Selkup,ETHNICITY,ETHNICITY
SM257449,82.2995,65.5345,Selkup,ETHNICITY,ETHNICITY
SM257449,82.2995,65.5345,Selkup,ETHNICITY,ETHNICITY
SM11031,32.6203,35.1524,Selladi tou Appi kai Agio Georgoudi,ADM2,DISTRICT
SM276213,17.8983,45.8695,Sellyei,ADM2,DISTRICT
SM305347,16.3903,46.4942,Selnica,ADM2,DISTRICT
SM296341,15.4954,46.5817,Selnica ob Dravi,ADM1:ADM2,DISTRICT
SM13810,25.656,56.5396,Selpils pagasts,ADM2,DISTRICT
SM11807,48.1667,33.8333,Selseleh,ADM2,DISTRICT
SM11622,-22.0029,64.1554,Seltjarnarneskaupstadur,ADM2,DISTRICT
SM13811,25.1881,57.869,Selu pagasts,ADM2,DISTRICT
SM276722,102.4743,-3.9664,Seluma,ADM2,DISTRICT
SM244116,116.45,4.3479,Selungai Murut,LANGUAGE,LANGUAGE
SM244115,131.25,-7.5992,Seluwasan,LANGUAGE,LANGUAGE
SM263086,-51.8323,-20.2496,Selvíria,ADM2,DISTRICT
SM286542,171.8706,-43.3223,Selwyn,ADM2,DISTRICT
SM299955,37.2996,48.1453,Selydovs'ka,ADM2,DISTRICT
SM298547,27.3807,37.9577,Selçuk,ADM2,DISTRICT
SM298684,32.5409,38.0576,Selçuklu,ADM2,DISTRICT
SM263972,-42.8156,-20.0734,Sem-Peixe,ADM2,DISTRICT
SM244121,101.477,4.1326,Semai,LANGUAGE,LANGUAGE
SM257450,101.08,4.19,Semai,ETHNICITY,ETHNICITY
SM257450,101.08,4.19,Semai,ETHNICITY,ETHNICITY
SM257450,101.08,4.19,Semai,ETHNICITY,ETHNICITY
SM257450,102.1665,3.75,Semai,ETHNICITY,ETHNICITY
SM257450,102.1665,3.75,Semai,ETHNICITY,ETHNICITY
SM257450,102.1665,3.75,Semai,ETHNICITY,ETHNICITY
SM257450,102,4,Semai,ETHNICITY,ETHNICITY
SM257450,102,4,Semai,ETHNICITY,ETHNICITY
SM257450,102,4,Semai,ETHNICITY,ETHNICITY
SM257451,102.34,4.14,Semang,ETHNICITY,ETHNICITY
SM257451,102.34,4.14,Semang,ETHNICITY,ETHNICITY
SM257451,102.34,4.14,Semang,ETHNICITY,ETHNICITY
SM257451,102.34,4.14,Semang,ETHNICITY,ETHNICITY
SM257451,102.34,4.14,Semang,ETHNICITY,ETHNICITY
SM257451,103.1,4.58,Semang,ETHNICITY,ETHNICITY
SM257451,103.1,4.58,Semang,ETHNICITY,ETHNICITY
SM257451,103.1,4.58,Semang,ETHNICITY,ETHNICITY
SM257451,103.1,4.58,Semang,ETHNICITY,ETHNICITY
SM257451,103.1,4.58,Semang,ETHNICITY,ETHNICITY
SM257451,102,5,Semang,ETHNICITY,ETHNICITY
SM257451,102,5,Semang,ETHNICITY,ETHNICITY
SM257451,102,5,Semang,ETHNICITY,ETHNICITY
SM257451,102,5,Semang,ETHNICITY,ETHNICITY
SM257451,102,5,Semang,ETHNICITY,ETHNICITY
SM257451,101,5,Semang,ETHNICITY,ETHNICITY
SM257451,101,5,Semang,ETHNICITY,ETHNICITY
SM257451,101,5,Semang,ETHNICITY,ETHNICITY
SM257451,101,5,Semang,ETHNICITY,ETHNICITY
SM257451,101,5,Semang,ETHNICITY,ETHNICITY
SM257451,101,5.86,Semang,ETHNICITY,ETHNICITY
SM257451,101,5.86,Semang,ETHNICITY,ETHNICITY
SM257451,101,5.86,Semang,ETHNICITY,ETHNICITY
SM257451,101,5.86,Semang,ETHNICITY,ETHNICITY
SM257451,101,5.86,Semang,ETHNICITY,ETHNICITY
SM257451,101.25,5,Semang,ETHNICITY,ETHNICITY
SM257451,101.25,5,Semang,ETHNICITY,ETHNICITY
SM257451,101.25,5,Semang,ETHNICITY,ETHNICITY
SM257451,101.25,5,Semang,ETHNICITY,ETHNICITY
SM257451,101.25,5,Semang,ETHNICITY,ETHNICITY
SM244120,102.816,4.1217,Semaq Beri,LANGUAGE,LANGUAGE
SM276323,110.4414,-7.2067,Semarang,ADM2,DISTRICT
SM257452,141.65,-8.98,Semariji,ETHNICITY,ETHNICITY
SM3175,31.3551,-0.0083,Sembabule,ADM1:ADM2,DISTRICT
SM244125,116.799,4.221,Sembakung Murut,LANGUAGE,LANGUAGE
SM278572,140.67,39.7571,Semboku,ADM2,DISTRICT
SM269809,14.8688,1.7316,Sembé (pre-1994),ADM2,DISTRICT
SM244129,102.806,2.828,Semelai,LANGUAGE,LANGUAGE
SM257454,103.06,3,Semelai,ETHNICITY,ETHNICITY
SM305385,18.5405,45.3617,Semeljci,ADM2,DISTRICT
SM274340,37.0884,12.6581,Semen Gondar,ADM2,DISTRICT
SM274340,37.0884,12.6581,Semen Gondar,ADM2,DISTRICT
SM274340,37.063,12.7197,Semen Gondar,ADM2,DISTRICT
SM274340,37.063,12.7197,Semen Gondar,ADM2,DISTRICT
SM274341,39.1,11.92,Semen Wello,ADM2,DISTRICT
SM3176,39,16,Semenawi Keyih Bahri,ADM1,DISTRICT
SM8347,103.6437,-4.0782,Semendo,ETHNICITY,ETHNICITY
SM299654,33.1793,49.5975,Semenivs'kyi,ADM2,DISTRICT
SM13812,23.1134,57.0639,Semes pagasts,ADM2,DISTRICT
SM13249,80.2167,50.4667,Semey Qalasy,ADM2,DISTRICT
SM18600,39.3333,15.5833,Semhar Awraja,ADM2,DISTRICT
SM296261,15.1347,45.6514,Semic,ADM1:ADM2,DISTRICT
SM274333,38.1639,14.0204,Semien Mi'irabaw,ADM2,DISTRICT
SM294993,39,51.6667,Semilukskiy rayon,ADM2,DISTRICT
SM271229,15.4167,50.6167,Semily,ADM2,DISTRICT
SM244134,134.454,-3.7155,Semimi,LANGUAGE,LANGUAGE
SM302235,-96.6155,35.1675,Seminole,ADM2,DISTRICT
SM300409,-84.8689,30.9388,Seminole,ADM2,DISTRICT
SM300367,-81.2363,28.717,Seminole,ADM2,DISTRICT
SM257456,-81.44,26.13,Seminole,ETHNICITY,ETHNICITY
SM257456,-81.44,26.13,Seminole,ETHNICITY,ETHNICITY
SM257456,-81.44,26.13,Seminole,ETHNICITY,ETHNICITY
SM257456,-97,35.5,Seminole,ETHNICITY,ETHNICITY
SM257456,-97,35.5,Seminole,ETHNICITY,ETHNICITY
SM257456,-97,35.5,Seminole,ETHNICITY,ETHNICITY
SM257456,-81,27,Seminole,ETHNICITY,ETHNICITY
SM257456,-81,27,Seminole,ETHNICITY,ETHNICITY
SM257456,-81,27,Seminole,ETHNICITY,ETHNICITY
SM277696,51.5333,31.3,Semirom,ADM2,DISTRICT
SM290535,20.9321,46.1126,Semlac,ADM2,DISTRICT
SM244139,101.058,5.2762,Semnam,LANGUAGE,LANGUAGE
SM3177,55,35.5,Semnan,ADM1,DISTRICT
SM244138,53.2868,35.5295,Semnani-Biyabuneki,LANGUAGE,LANGUAGE
SM257457,103.32,4.19,Semoq Beri,ETHNICITY,ETHNICITY
SM244141,137.588,-5.1758,Sempan,LANGUAGE,LANGUAGE
SM240812,34.9829,-19.7933,Sena,LANGUAGE,LANGUAGE
SM297380,100.3749,14.2961,Sena,ADM2,DISTRICT
SM262791,-69.5497,-10.0357,Sena Madureira,ADM2,DISTRICT
SM251110,34.96,-16.24,Sena Malawi,ETHNICITY,ETHNICITY
SM251070,35,-18,Sena Mozambiquean,ETHNICITY,ETHNICITY
SM262910,-43.8749,-5.2921,Senador Alexandre Costa,ADM2,DISTRICT
SM263973,-46.2242,-22.5536,Senador Amaral,ADM2,DISTRICT
SM268178,-49.1075,-16.7232,Senador Canedo,ADM2,DISTRICT
SM263974,-42.9114,-21.7616,Senador Cortes,ADM2,DISTRICT
SM265487,-35.6573,-6.0401,Senador Elói de Souza,ADM2,DISTRICT
SM263975,-43.1103,-20.8968,Senador Firmino,ADM2,DISTRICT
SM265488,-35.1335,-6.1484,Senador Georgino Avelino,ADM2,DISTRICT
SM262793,-67.3931,-9.9408,Senador Guiomard,ADM2,DISTRICT
SM263976,-46.1428,-22.1584,Senador José Bento,ADM2,DISTRICT
SM264111,-51.7843,-4.0007,Senador José Porfírio,ADM2,DISTRICT
SM262911,-47.1719,-5.4266,Senador La Rocque,ADM2,DISTRICT
SM263977,-43.2133,-17.9134,Senador Modestino Gonçalves,ADM2,DISTRICT
SM267853,-39.4851,-5.5754,Senador Pompeu,ADM2,DISTRICT
SM265433,-37.5597,-9.3383,Senador Rui Palmeira,ADM2,DISTRICT
SM265936,-54.524,-28.0295,Senador Salgado Filho,ADM2,DISTRICT
SM267854,-40.4442,-3.2765,Senador Sá,ADM2,DISTRICT
SM274012,39.4167,14.7,Senafe,ADM2,DISTRICT
SM275162,-89.8399,15.4025,Senahú,ADM2,DISTRICT
SM274833,42.0601,42.2679,Senaki,ADM2,DISTRICT
SM18168,23.7673,-16.0897,Senanga District (2012-),ADM2,DISTRICT
SM296871,104.6844,16.0513,Senangkhanikhom,ADM2,DISTRICT
SM11685,94.0224,25.2671,Senapati (2016-),ADM2,DISTRICT
SM244146,-5.2822,10.4987,Senara Sénoufo,LANGUAGE,LANGUAGE
SM244148,51.4363,35.7776,Senaya,LANGUAGE,LANGUAGE
SM278735,140.8667,38.25,Sendai,ADM2,DISTRICT
SM290464,27.9225,45.4132,Sendreni,ADM2,DISTRICT
SM292597,26.3296,47.9466,Sendriceni,ADM2,DISTRICT
SM244153,147.807,-6.3859,Sene,LANGUAGE,LANGUAGE
SM4355,-0.1786,7.5898,Sene East,ADM2,DISTRICT
SM4356,-0.4502,7.6112,Sene West,ADM2,DISTRICT
SM296133,17.4033,48.2219,Senec,ADM2,DISTRICT
SM244154,-77.53,42.56,Seneca,LANGUAGE,LANGUAGE
SM302155,-83.1277,41.1239,Seneca,ADM2,DISTRICT
SM301913,-76.8238,42.7811,Seneca,ADM2,DISTRICT
SM257463,-78.24,43.12,Seneca,ETHNICITY,ETHNICITY
SM257463,-78.24,43.12,Seneca,ETHNICITY,ETHNICITY
SM257463,-77,43,Seneca,ETHNICITY,ETHNICITY
SM257463,-77,43,Seneca,ETHNICITY,ETHNICITY
SM244155,9.4378,34.4678,Sened,LANGUAGE,LANGUAGE
SM298080,9.165,34.5368,Sened,ADM2,DISTRICT
SM295352,157.0002,-7.0036,Senga,ADM2,DISTRICT
SM250673,32.95,-12.01,Senga,ETHNICITY,ETHNICITY
SM244158,17.2965,-1.9226,Sengele,LANGUAGE,LANGUAGE
SM299134,32.4866,-2.4799,Sengerema,ADM2,DISTRICT
SM244165,140.77,-3.4198,Senggi,LANGUAGE,LANGUAGE
SM268294,114.8358,4.924,Sengkurong,ADM2,DISTRICT
SM244156,93.8814,24.9456,Sengmai,LANGUAGE,LANGUAGE
SM244164,142.956,-4.0225,Sengo,LANGUAGE,LANGUAGE
SM244161,149.784,-6.1647,Sengseng,LANGUAGE,LANGUAGE
SM282078,-100.3377,19.7539,Senguio,ADM2,DISTRICT
SM454502,35,1,Sengwer,ETHNICITY,ETHNICITY
SM264738,-49.4608,-24.2257,Sengés,ADM2,DISTRICT
SM244166,-4.5129,34.9992,Senhaja De Srair,LANGUAGE,LANGUAGE
SM18602,38.5,16.8333,Senhit Awraja,ADM2,DISTRICT
SM267665,-40.1012,-10.4288,Senhor do Bonfim,ADM2,DISTRICT
SM263978,-43.3454,-20.8018,Senhora de Oliveira,ADM2,DISTRICT
SM263980,-43.0858,-18.9027,Senhora do Porto,ADM2,DISTRICT
SM263981,-43.5968,-21.0288,Senhora dos Remédios,ADM2,DISTRICT
SM296178,17.3667,48.6833,Senica,ADM2,DISTRICT
SM298483,30.5547,38.1141,Senirkent,ADM2,DISTRICT
SM276067,14.9045,44.9887,Senj,ADM2,DISTRICT
SM15305,17.6135,69.3155,Senja,ADM2,DISTRICT
SM370120,-10.8864,6.8525,Senjeh,ADM2:ADM3,DISTRICT
SM370120,-10.8864,6.8525,Senjeh,ADM2:ADM3,DISTRICT
SM370120,-10.9133,6.7153,Senjeh,ADM2:ADM3,DISTRICT
SM370120,-10.9133,6.7153,Senjeh,ADM2:ADM3,DISTRICT
SM279113,135.2918,34.3516,Sennan,ADM2,DISTRICT
SM13072,135.1572,34.3069,Sennan-gun,ADM2,DISTRICT
SM3178,34,13,Sennar,ADM1,DISTRICT
SM17906,33.2334,13.5854,Sennar Locality,ADM2,DISTRICT
SM262193,0.012,13.964,Seno (1995-),ADM1:ADM2,DISTRICT
SM269062,7.2603,46.8013,Sense,ADM2,DISTRICT
SM295732,-88.1641,13.6876,Sensembra,ADM2,DISTRICT
SM275732,-88.9,14.48,Sensenti,ADM2,DISTRICT
SM244170,-74.9166,-7.25,Sensi,LANGUAGE,LANGUAGE
SM295603,-88.5833,13.8833,Sensuntepeque,ADM2,DISTRICT
SM241032,140.512,-2.6013,Sentani,LANGUAGE,LANGUAGE
SM244172,93.8087,21.8936,Senthang Chin,LANGUAGE,LANGUAGE
SM244171,92.2534,11.553,Sentinel,LANGUAGE,LANGUAGE
SM265937,-51.621,-30.6034,Sentinela do Sul,ADM2,DISTRICT
SM267666,-41.5795,-10.2125,Sento Sé,ADM2,DISTRICT
SM17841,14.4333,46.1056,Sentvid District,ADM2,DISTRICT
SM2273,-4.73,9.28,Senufo-Generic,ETHNICITY,ETHNICITY
SM2273,-4.73,9.28,Senufo-Generic,ETHNICITY,ETHNICITY
SM2273,-6,10,Senufo-Generic,ETHNICITY,ETHNICITY
SM2273,-6,10,Senufo-Generic,ETHNICITY,ETHNICITY
SM280571,126.6554,37.5523,Seo,ADM2,DISTRICT
SM280679,127.331,36.2721,Seo,ADM2,DISTRICT
SM280674,128.5511,35.8747,Seo,ADM2,DISTRICT
SM280530,129.0195,35.1253,Seo,ADM2,DISTRICT
SM17884,126.8911,35.1525,Seo-gu,ADM2,DISTRICT
SM280656,126.6972,36.106,Seocheon,ADM2,DISTRICT
SM280620,127.0109,37.4945,Seocho,ADM2,DISTRICT
SM280621,126.9351,37.5781,Seodaemun,ADM2,DISTRICT
SM280573,126.4975,33.2931,Seogwipo,ADM2,DISTRICT
SM280623,127.0145,37.6027,Seongbuk,ADM2,DISTRICT
SM280624,127.0246,37.5478,Seongdong,ADM2,DISTRICT
SM280739,128.2884,35.9189,Seongju,ADM2,DISTRICT
SM280716,127.1288,37.4188,Seongnam,ADM2,DISTRICT
SM277002,79.6667,22.4167,Seoni,ADM2,DISTRICT
SM280657,126.4657,36.7852,Seosan,ADM2,DISTRICT
SM244178,126.98,37.54,Seoul,DIALECT,LANGUAGE
SM3179,127,37.5833,Seoul,ADM1,DISTRICT
SM289174,127.3333,38.6667,Sep'o,ADM2,DISTRICT
SM244180,129.243,-3.3668,Sepa (Indonesia),LANGUAGE,LANGUAGE
SM244179,144.965,-4.27,Sepa (Papua New Guinea),LANGUAGE,LANGUAGE
SM284391,101.6667,2.75,Sepang,ADM2,DISTRICT
SM244182,144.809,-4.2279,Sepen,LANGUAGE,LANGUAGE
SM277712,52.1,30.15,Sepidan,ADM2,DISTRICT
SM244185,142.117,-4.3093,Sepik Iwam,LANGUAGE,LANGUAGE
SM280789,106.3376,16.7611,Sepone,ADM2,DISTRICT
SM290536,21.7342,46.5713,Sepreus,ADM2,DISTRICT
SM257470,-64.57,50.42,Sept-Iles,ETHNICITY,ETHNICITY
SM262323,24.1333,42.2667,Septemvri,ADM2,DISTRICT
SM370494,5.9672,49.7011,Septfontaines,ADM1:ADM2:ADM3,DISTRICT
SM10337,15.1502,12.0977,Septieme Arrondissement,ADM2,DISTRICT
SM302618,-85.4106,35.3712,Sequatchie,ADM2,DISTRICT
SM302236,-94.7552,35.4954,Sequoyah,ADM2,DISTRICT
SM244193,141.951,-2.9854,Sera,LANGUAGE,LANGUAGE
SM278136,133.0161,34.6159,Sera,ADM2,DISTRICT
SM294922,42.5,49.4167,Serafimovichskiy rayon,ADM2,DISTRICT
SM265938,-51.9267,-28.6912,Serafina Corrêa,ADM2,DISTRICT
SM276472,128.43,-3.1027,Seram Bagian Barat,ADM2,DISTRICT
SM276463,130.3917,-3.3985,Seram Bagian Timur,ADM2,DISTRICT
SM257471,148.4,-9.63,Seramina,ETHNICITY,ETHNICITY
SM276718,106,-6.15,Serang,ADM2,DISTRICT
SM268295,115.0584,5.0172,Serasa,ADM2,DISTRICT
SM11156,38.5,14.8333,Seraye Awraja,ADM2,DISTRICT
SM292372,27.3086,46.1633,Serbanesti,ADM2,DISTRICT
SM291459,24.6878,44.3361,Serbanesti,ADM2,DISTRICT
SM257473,18.57,44.09,Serbian,ETHNICITY,ETHNICITY
SM257473,18.57,44.09,Serbian,ETHNICITY,ETHNICITY
SM257473,20,44,Serbian,ETHNICITY,ETHNICITY
SM257473,20,44,Serbian,ETHNICITY,ETHNICITY
SM454373,-87.75,41.833,Serbian American,ETHNICITY,ETHNICITY
SM244201,21.9192,44.3238,Serbian Standard,DIALECT:LANGUAGE,LANGUAGE
SM244911,18.81,44.15,Serbian-Croatian-Bosnian,LANGUAGE,LANGUAGE
SM292651,25.1411,45.83,Sercaia,ADM2,DISTRICT
SM277094,92.9004,23.2817,Serchhip,ADM2,DISTRICT
SM276693,99.0333,3.3667,Serdang Bedagai,ADM2,DISTRICT
SM18100,30.3678,40.7637,Serdivan,ADM2,DISTRICT
SM244209,27.4688,4.7113,Sere,LANGUAGE,LANGUAGE
SM293709,38.6667,54.5,Serebryano-Prudskiy rayon,ADM2,DISTRICT
SM13250,83.5,49.8333,Serebryansk Qalasy,ADM2,DISTRICT
SM244206,-16.3916,13.8941,Sereer,LANGUAGE,LANGUAGE
SM280354,102.9633,13.6211,Serei Saophoan,ADM2,DISTRICT
SM273985,38.885,15.7219,Serejeka,ADM2,DISTRICT
SM13813,25.1489,56.5682,Serenes pagasts,ADM2,DISTRICT
SM299101,34.7176,-1.9834,Serengeti,ADM2,DISTRICT
SM305153,30.2343,-13.3622,Serenje (2012-),ADM2,DISTRICT
SM250674,-16.22,14.41,Serer,ETHNICITY,ETHNICITY
SM250674,-16.22,14.41,Serer,ETHNICITY,ETHNICITY
SM250674,-12,14,Serer,ETHNICITY,ETHNICITY
SM250674,-12,14,Serer,ETHNICITY,ETHNICITY
SM4719,33.452,1.487,Serere District,ADM1:ADM2,DISTRICT
SM293783,45.5,55.5833,Sergachskiy rayon,ADM2,DISTRICT
SM15007,107.036,47.6683,Sergelen,ADM2,DISTRICT
SM283787,114.0848,48.5537,Sergelen,ADM2,DISTRICT
SM3180,-37.3333,-10.5,Sergipe,ADM1,DISTRICT
SM17070,38.1667,56.3333,Sergiyevo-Posadskiy Rayon,ADM2,DISTRICT
SM292851,47.5833,42.3333,Sergokalinskiy rayon,ADM2,DISTRICT
SM244214,-112.384,28.9877,Seri,LANGUAGE,LANGUAGE
SM257474,-111.54,29.06,Seri,ETHNICITY,ETHNICITY
SM257474,-111.54,29.06,Seri,ETHNICITY,ETHNICITY
SM257474,-111.54,29.06,Seri,ETHNICITY,ETHNICITY
SM257474,-112,29,Seri,ETHNICITY,ETHNICITY
SM257474,-112,29,Seri,ETHNICITY,ETHNICITY
SM257474,-112,29,Seri,ETHNICITY,ETHNICITY
SM257474,-112.18,29.59,Seri,ETHNICITY,ETHNICITY
SM257474,-112.18,29.59,Seri,ETHNICITY,ETHNICITY
SM257474,-112.18,29.59,Seri,ETHNICITY,ETHNICITY
SM263982,-42.4599,-20.4954,Sericita,ADM2,DISTRICT
SM264331,-36.4265,-6.8868,Seridó,ADM2,DISTRICT
SM299016,31.0068,37.0774,Serik,ADM2,DISTRICT
SM244212,129.901,-8.1124,Serili,LANGUAGE,LANGUAGE
SM266112,-63.1894,-11.9249,Seringueiras,ADM2,DISTRICT
SM298309,29.2725,37.5913,Serinhisar,ADM2,DISTRICT
SM263983,-44.463,-21.9189,Seritinga,ADM2,DISTRICT
SM3181,-51.7183,64.1828,Sermersooq,ADM1,DISTRICT
SM289404,-7.5123,40.921,Sernancelhe,ADM2,DISTRICT
SM293622,49.25,57,Sernurskiy rayon,ADM2,DISTRICT
SM244216,22.0021,-33.0386,Seroa,LANGUAGE,LANGUAGE
SM265325,-43.7086,-22.7388,Seropédica,ADM2,DISTRICT
SM268527,26.5548,-22.0005,Serowe,ADM2,DISTRICT
SM289423,-7.4753,37.952,Serpa,ADM2,DISTRICT
SM261310,116.0451,-32.3245,Serpentine-Jarrahdale,ADM2,DISTRICT
SM17071,37.4167,54.9167,Serpukhovskiy Rayon,ADM2,DISTRICT
SM268032,-40.3019,-20.1082,Serra,ADM2,DISTRICT
SM266319,-53.014,-26.7024,Serra Alta,ADM2,DISTRICT
SM266960,-47.5388,-21.2876,Serra Azul,ADM2,DISTRICT
SM263984,-43.2025,-18.3823,Serra Azul de Minas,ADM2,DISTRICT
SM264332,-36.7333,-7.5802,Serra Branca,ADM2,DISTRICT
SM264336,-38.3959,-7.2509,Serra Grande,ADM2,DISTRICT
SM266961,-46.7032,-22.6077,Serra Negra,ADM2,DISTRICT
SM265491,-37.3942,-6.576,Serra Negra do Norte,ADM2,DISTRICT
SM10023,-51.3341,-12.029,Serra Nova Dourada,ADM2,DISTRICT
SM267669,-39.3005,-12.0691,Serra Preta,ADM2,DISTRICT
SM264337,-35.6763,-7.1567,Serra Redonda,ADM2,DISTRICT
SM264906,-38.3994,-8.0909,Serra Talhada,ADM2,DISTRICT
SM264335,-35.4358,-6.697,Serra da Raiz,ADM2,DISTRICT
SM263985,-45.8,-19.3839,Serra da Saudad,ADM2,DISTRICT
SM265489,-35.7119,-6.437,Serra de São Bento,ADM2,DISTRICT
SM265490,-37.0312,-5.122,Serra do Mel,ADM2,DISTRICT
SM267293,-52.282,1.658,Serra do Navio,ADM2,DISTRICT
SM267667,-43.7785,-13.411,Serra do Ramalho,ADM2,DISTRICT
SM263986,-46.6531,-19.1499,Serra do Salitre,ADM2,DISTRICT
SM263987,-40.2711,-17.7502,Serra dos Aimorés,ADM2,DISTRICT
SM267668,-43.796,-12.915,Serra dourada,ADM2,DISTRICT
SM266962,-47.6118,-21.2125,Serrana,ADM2,DISTRICT
SM263988,-46.1071,-21.5551,Serrania,ADM2,DISTRICT
SM244219,-116.772,33.9479,Serrano,LANGUAGE,LANGUAGE
SM257475,-116.07,34,Serrano,ETHNICITY,ETHNICITY
SM257475,-116.07,34,Serrano,ETHNICITY,ETHNICITY
SM257475,-116.07,34,Serrano,ETHNICITY,ETHNICITY
SM257475,-116.07,34,Serrano,ETHNICITY,ETHNICITY
SM257475,-117,34,Serrano,ETHNICITY,ETHNICITY
SM257475,-117,34,Serrano,ETHNICITY,ETHNICITY
SM257475,-117,34,Serrano,ETHNICITY,ETHNICITY
SM257475,-117,34,Serrano,ETHNICITY,ETHNICITY
SM257475,-117,34.52,Serrano,ETHNICITY,ETHNICITY
SM257475,-117,34.52,Serrano,ETHNICITY,ETHNICITY
SM257475,-117,34.52,Serrano,ETHNICITY,ETHNICITY
SM257475,-117,34.52,Serrano,ETHNICITY,ETHNICITY
SM257475,-117,34.2,Serrano,ETHNICITY,ETHNICITY
SM257475,-117,34.2,Serrano,ETHNICITY,ETHNICITY
SM257475,-117,34.2,Serrano,ETHNICITY,ETHNICITY
SM257475,-117,34.2,Serrano,ETHNICITY,ETHNICITY
SM262912,-45.0851,-1.8566,Serrano do Maranhão,ADM2,DISTRICT
SM263991,-44.5424,-21.8239,Serranos,ADM2,DISTRICT
SM268179,-52.2475,-18.2704,Serranópolis,ADM2,DISTRICT
SM263989,-42.8513,-15.8998,Serranópolis de Minas,ADM2,DISTRICT
SM264739,-54.0164,-25.47,Serranópolis do Iguaçu,ADM2,DISTRICT
SM264338,-35.6693,-6.8637,Serraria,ADM2,DISTRICT
SM3182,12.4667,43.9667,Serravalle,ADM1,DISTRICT
SM265493,-35.5948,-6.2533,Serrinha,ADM2,DISTRICT
SM322143,-39.0387,-11.5995,Serrinha (2000-),ADM2:ADM3,DISTRICT
SM265492,-37.9886,-6.1526,Serrinha dos Pintos,ADM2,DISTRICT
SM264907,-39.4081,-7.8401,Serrita,ADM2,DISTRICT
SM263992,-43.3808,-18.5204,Serro,ADM2,DISTRICT
SM267671,-40.2478,-11.5402,Serrolândia,ADM2,DISTRICT
SM264740,-50.888,-22.9583,Sertaneja,ADM2,DISTRICT
SM264741,-51.0347,-23.0179,Sertanópolis,ADM2,DISTRICT
SM266963,-48.0252,-21.1282,Sertaozinho,ADM2,DISTRICT
SM264908,-37.3212,-8.2324,Sertânia,ADM2,DISTRICT
SM289469,-8.0985,39.8218,Sertã,ADM2,DISTRICT
SM265943,-52.3566,-28.0041,Sertão,ADM2,DISTRICT
SM265942,-51.6654,-30.4911,Sertão Santana,ADM2,DISTRICT
SM264339,-35.4163,-6.7394,Sertãozinho,ADM2,DISTRICT
SM244226,112.133,1.7389,Seru,LANGUAGE,LANGUAGE
SM244224,130.0173,-6.3111,Serua,LANGUAGE,LANGUAGE
SM274409,178,-18.1667,Serua,ADM2,DISTRICT
SM244225,117.279,4.3995,Serudung Murut,LANGUAGE,LANGUAGE
SM244223,136.252,-1.8777,Serui-Laut,LANGUAGE,LANGUAGE
SM276408,112.25,-2.3333,Seruyan,ADM2,DISTRICT
SM293219,128.5,51.1667,Seryshevskiy rayon,ADM2,DISTRICT
SM280437,106.3582,13.6053,Sesan,ADM2,DISTRICT
SM13814,23.7991,56.4037,Sesavas pagasts,ADM2,DISTRICT
SM454551,32.29,-0.39,Sese,ETHNICITY,ETHNICITY
SM244235,55.4503,-4.6231,Seselwa Creole French,LANGUAGE,LANGUAGE
SM284530,13.7653,-19.5611,Sesfontein,ADM2,DISTRICT
SM18169,23.8753,-16.983,Sesheke District (2013-),ADM2,DISTRICT
SM289341,-9.1201,38.4949,Sesimbra,ADM2,DISTRICT
SM295754,-88.4167,13.7833,Sesori,ADM2,DISTRICT
SM270051,-73.8005,4.9883,Sesquilé,ADM2,DISTRICT
SM17072,30,60.0833,Sestroretskiy Rayon,ADM2,DISTRICT
SM10535,16.1333,45.8333,Sesvete,ADM2,DISTRICT
SM244239,141.748,-3.4216,Seta,LANGUAGE,LANGUAGE
SM279464,139.6533,35.6466,Setagaya,ADM2,DISTRICT
SM244240,141.833,-5.3113,Setaman,LANGUAGE,LANGUAGE
SM278177,139.8939,42.3964,Setana Shi,ADM2,DISTRICT
SM266964,-47.9501,-24.2772,Sete Barras,ADM2,DISTRICT
SM263993,-44.2795,-19.4363,Sete Lagoas,ADM2,DISTRICT
SM263088,-55.0273,-23.8807,Sete Quedas,ADM2,DISTRICT
SM265944,-54.4791,-28.1255,Sete de Setembro,ADM2,DISTRICT
SM295228,36.147,14.666,Seteet,ADM2,DISTRICT
SM244243,141.827,-3.4552,Seti,LANGUAGE,LANGUAGE
SM284398,102.7461,5.5197,Setiu,ADM2,DISTRICT
SM278060,137.0841,35.2234,Seto,ADM2,DISTRICT
SM11195,27.6196,57.8842,Setomaa vald,ADM2,DISTRICT
SM279032,134.1416,34.6654,Setouchi,ADM2,DISTRICT
SM4428,-7.4167,33.0833,Settat (2015-),ADM2,DISTRICT
SM244249,11.462,45.875,Sette Comuni Cimbrian,DIALECT,LANGUAGE
SM279114,135.5682,34.7722,Settsu,ADM2,DISTRICT
SM263994,-42.1951,-17.6669,Setubinha,ADM2,DISTRICT
SM289342,-8.9082,38.5174,Setúbal,ADM2,DISTRICT
SM3184,-8.5865,38.3496,Setúbal,ADM1,DISTRICT
SM3185,33.5385,44.5553,Sevastopol',ADM1,DISTRICT
SM12723,-77.9138,18.347,Seven Rivers,ADM2,DISTRICT
SM289217,-8.3549,40.7316,Sever do Vouga,ADM2,DISTRICT
SM265495,-38.0057,-5.8113,Severiano Melo,ADM2,DISTRICT
SM265945,-52.1075,-27.4065,Severiano de Almeida,ADM2,DISTRICT
SM305329,16.9695,45.8415,Severin,ADM2,DISTRICT
SM244251,-89.9331,53.9092,Severn Ojibwa,LANGUAGE,LANGUAGE
SM3187,20.1433,45.9425,Severno-Banatski,ADM1:ADM2,DISTRICT
SM3186,19.6361,45.815,Severno-Bački,ADM1:ADM2,DISTRICT
SM293875,78.5,56.3333,Severnyy rayon,ADM2,DISTRICT
SM17073,111.5,56,Severo-Baykal'skiy Rayon,ADM2,DISTRICT
SM293451,93.25,60,Severo-Eniseyskiy rayon,ADM2,DISTRICT
SM293614,160,63.5,Severo-Evenskiy rayon,ADM2,DISTRICT
SM293389,38.7698,44.7481,Severskiy rayon,ADM2,DISTRICT
SM266966,-48.7904,-20.7807,Severínia,ADM2,DISTRICT
SM302906,-111.8046,38.7476,Sevier,ADM2,DISTRICT
SM302619,-83.5242,35.7847,Sevier,ADM2,DISTRICT
SM302415,-94.2412,33.9972,Sevier,ADM2,DISTRICT
SM274039,-5.6961,37.447,Sevilla,ADM2,DISTRICT
SM270631,-75.8883,4.1935,Sevilla,ADM2,DISTRICT
SM273421,-78.575,-2.6819,Sevilla de Oro,ADM2,DISTRICT
SM262512,25.1,43.0167,Sevlievo,ADM2,DISTRICT
SM296243,15.3,46,Sevnica,ADM1:ADM2,DISTRICT
SM283624,102.533,43.6116,Sevrei,ADM2,DISTRICT
SM295019,34.4167,52.0833,Sevskiy rayon,ADM2,DISTRICT
SM257480,26.85,-11.24,Sewa,ETHNICITY,ETHNICITY
SM244255,150.956,-10.0277,Sewa Bay,LANGUAGE,LANGUAGE
SM301756,-97.1395,40.8724,Seward,ADM2,DISTRICT
SM300975,-100.8513,37.1933,Seward,ADM2,DISTRICT
SM240362,-161.8067,64.7796,Seward Alaska Inupiatun,LANGUAGE,LANGUAGE
SM323806,-118.7817,55.3487,Sexsmith,ADM2:ADM3,DISTRICT
SM16214,-66.6117,18.0212,Sexto Barrio,ADM2,DISTRICT
SM18101,29.3493,36.6431,Seydikemer,ADM2,DISTRICT
SM298597,33.7325,41.6192,Seydiler,ADM2,DISTRICT
SM298685,31.8505,37.4293,Seydişehir,ADM2,DISTRICT
SM18102,35.2164,36.9554,Seyhan (2008-),ADM2,DISTRICT
SM298410,30.7008,39.454,Seyitgazi,ADM2,DISTRICT
SM12724,-76.7771,18.0129,Seymour Lands,ADM2,DISTRICT
SM283002,-89.3729,20.8517,Seyé,ADM2,DISTRICT
SM277743,-14,65.25,Seyðisfjörður,ADM2,DISTRICT
SM244259,34.6449,9.5076,Seze,LANGUAGE,LANGUAGE
SM296299,13.8864,45.7347,Sežana,ADM1:ADM2,DISTRICT
SM290229,25.7973,45.8381,Sfantu  Gheorghe,ADM2,DISTRICT
SM292069,29.5936,44.8965,Sfantu Gheorghe,ADM2,DISTRICT
SM290931,26.8432,44.6474,Sfantu Gheorghe,ADM2,DISTRICT
SM3188,10.4167,34.75,Sfax,ADM1,DISTRICT
SM297941,10.7631,34.7439,Sfax Médina,ADM2,DISTRICT
SM297942,10.722,34.7404,Sfax Ouest,ADM2,DISTRICT
SM18010,10.6667,34.8314,Sfax Sud (excl. Thyne),ADM2,DISTRICT
SM291929,25.1039,44.1882,Sfintesti,ADM2,DISTRICT
SM15368,-56.9417,-22.5326,Sgto. Jose Felix Lopez,ADM2,DISTRICT
SM244266,8.7757,9.2328,Sha,LANGUAGE,LANGUAGE
SM3189,114.1953,22.3871,Sha Tin,ADM1,DISTRICT
SM283659,106.1861,50.079,Shaamar,ADM2,DISTRICT
SM3190,109,36,Shaanxi,ADM1,DISTRICT
SM244269,43.144,36.375,Shabaki,LANGUAGE,LANGUAGE
SM293282,46.9167,58.3333,Shabalinskiy rayon,ADM2,DISTRICT
SM3191,46,3,Shabeellaha Dhexe,ADM1,DISTRICT
SM3192,44.4167,1.8333,Shabeellaha Hoose,ADM1,DISTRICT
SM274383,43.75,6,Shabelle,ADM2,DISTRICT
SM274383,43.75,6,Shabelle,ADM2,DISTRICT
SM274383,43.4968,6.1172,Shabelle,ADM2,DISTRICT
SM274383,43.4968,6.1172,Shabelle,ADM2,DISTRICT
SM11808,45.7333,38.2,Shabestar,ADM2,DISTRICT
SM262508,28.5167,43.6167,Shabla,ADM2,DISTRICT
SM244270,35.223,7.6441,Shabo,LANGUAGE,LANGUAGE
SM3193,47,15,Shabwah,ADM1,DISTRICT
SM302761,-99.3541,32.736,Shackelford,ADM2,DISTRICT
SM304925,43.1871,16.8913,Shada'a,ADM2,DISTRICT
SM277533,48.6667,30.6667,Shadegan,ADM2,DISTRICT
SM257482,37.74,40.11,Shaderli,ETHNICITY,ETHNICITY
SM17074,63.6667,56,Shadrinskiy Rayon,ADM2,DISTRICT
SM277724,49.3833,37.0833,Shaft,ADM2,DISTRICT
SM285059,3.5683,6.7991,Shagamu,ADM2,DISTRICT
SM285224,5.1301,12.5598,Shagari,ADM2,DISTRICT
SM9584,66.0057,32.0795,Shah Wali Kot (2005-),ADM2,DISTRICT
SM305076,52.594,17.5923,Shahan,ADM2,DISTRICT
SM304780,43.7094,16.18,Shaharah,ADM2,DISTRICT
SM296770,36.7203,33.0428,Shahba,ADM2,DISTRICT
SM5997,77.2706,28.6844,Shahdara,ADM2,DISTRICT
SM277003,81.5,23.5,Shahdol (2003-),ADM2,DISTRICT
SM277154,76.1333,31.1318,Shahid Bhagat Singh Nagar,ADM2,DISTRICT
SM260224,65.4783,32.923,Shahidi Hasas,ADM2,DISTRICT
SM277331,79.8333,28,Shahjahanpur,ADM2,DISTRICT
SM260239,67.3949,32.4977,Shahjoy,ADM2,DISTRICT
SM244274,53.32,35.778,Shahmirzadi,LANGUAGE,LANGUAGE
SM259993,64.3355,34.1024,Shahrak,ADM2,DISTRICT
SM11809,50.8,32.3167,Shahrekord,ADM2,DISTRICT
SM11810,51.15,36.6333,Shahrestan-e 'Abbasabad,ADM2,DISTRICT
SM11811,45.8334,37.5,Shahrestan-e 'Ajab Shir,ADM2,DISTRICT
SM11812,58.1667,28.4333,Shahrestan-e 'Anbarabad,ADM2,DISTRICT
SM11813,52.7333,27.45,Shahrestan-e 'Asaluyeh,ADM2,DISTRICT
SM11814,53.4167,31,Shahrestan-e Abarkuh,ADM2,DISTRICT
SM11815,55.1624,26.0547,Shahrestan-e Abu Musa,ADM2,DISTRICT
SM11816,48.65,31.25,Shahrestan-e Ahvaz,ADM2,DISTRICT
SM11817,55.35,30.85,Shahrestan-e Anar,ADM2,DISTRICT
SM11818,49.5333,32.1667,Shahrestan-e Andika,ADM2,DISTRICT
SM11819,54.5833,37.1667,Shahrestan-e Aq Qala,ADM2,DISTRICT
SM11820,52.6167,34.9,Shahrestan-e Aradan,ADM2,DISTRICT
SM11821,49.8,34.1333,Shahrestan-e Arak,ADM2,DISTRICT
SM11822,51.9,34.1,Shahrestan-e Aran va Bidgol,ADM2,DISTRICT
SM11823,48.3,38.25,Shahrestan-e Ardabil,ADM2,DISTRICT
SM11824,54.65,32.65,Shahrestan-e Ardakan,ADM2,DISTRICT
SM11825,53.4167,29.8,Shahrestan-e Arsanjan,ADM2,DISTRICT
SM11826,53.6667,32,Shahrestan-e Ashkezar,ADM2,DISTRICT
SM11827,55.3167,36.9667,Shahrestan-e Azadshahr,ADM2,DISTRICT
SM11828,45.8333,37.75,Shahrestan-e Azarshahr,ADM2,DISTRICT
SM11829,52.7333,36.6667,Shahrestan-e Babolsar,ADM2,DISTRICT
SM11830,55.4167,31.6667,Shahrestan-e Bafq,ADM2,DISTRICT
SM11831,56.5167,29.1,Shahrestan-e Baft,ADM2,DISTRICT
SM11832,51.15,35.5333,Shahrestan-e Baharestan,ADM2,DISTRICT
SM11833,50.0833,31.05,Shahrestan-e Bahma'i,ADM2,DISTRICT
SM11834,58.1333,34.5833,Shahrestan-e Bajestan,ADM2,DISTRICT
SM11835,60.2833,35.0167,Shahrestan-e Bakharz,ADM2,DISTRICT
SM11836,49.1167,30.6333,Shahrestan-e Bandar-e Mahshahr,ADM2,DISTRICT
SM11837,58.0833,26.45,Shahrestan-e Bashagard,ADM2,DISTRICT
SM11838,51.1167,30.45,Shahrestan-e Basht,ADM2,DISTRICT
SM11839,53.6667,30.3333,Shahrestan-e Bavanat,ADM2,DISTRICT
SM11840,48.95,31.5,Shahrestan-e Bavi,ADM2,DISTRICT
SM11841,56.15,32.0167,Shahrestan-e Behabad,ADM2,DISTRICT
SM11842,50.2,30.5667,Shahrestan-e Behbahan,ADM2,DISTRICT
SM11843,59.3333,36.2667,Shahrestan-e Binalud,ADM2,DISTRICT
SM11844,59.0722,33.0067,Shahrestan-e Birjand,ADM2,DISTRICT
SM11845,57.3333,37.8333,Shahrestan-e Bojnurd,ADM2,DISTRICT
SM11846,46.0001,37.3334,Shahrestan-e Bonab,ADM2,DISTRICT
SM11847,51.7667,32.9833,Shahrestan-e Borkhvar,ADM2,DISTRICT
SM11848,51.2,31.9,Shahrestan-e Borujen,ADM2,DISTRICT
SM11849,51.1167,30.7833,Shahrestan-e Bowyer Ahmad,ADM2,DISTRICT
SM11850,49.7,35.6167,Shahrestan-e Bu'in Zahra,ADM2,DISTRICT
SM11851,61.2108,25.5683,Shahrestan-e Chabahar,ADM2,DISTRICT
SM11852,50.5,32.75,Shahrestan-e Chadegan,ADM2,DISTRICT
SM11853,51.1667,36.45,Shahrestan-e Chalus,ADM2,DISTRICT
SM11854,50.85,30.7667,Shahrestan-e Charam,ADM2,DISTRICT
SM11855,47.0668,37.1001,Shahrestan-e Charavimaq,ADM2,DISTRICT
SM11856,46.25,34.4167,Shahrestan-e Dalahu,ADM2,DISTRICT
SM11857,59.3,27.3167,Shahrestan-e Dalgan,ADM2,DISTRICT
SM11858,51.2833,30.95,Shahrestan-e Dana,ADM2,DISTRICT
SM11859,54.95,28.5667,Shahrestan-e Darab,ADM2,DISTRICT
SM11860,60.1667,32.95,Shahrestan-e Darmian,ADM2,DISTRICT
SM11861,48.0667,31.7,Shahrestan-e Dasht-e Azadegan,ADM2,DISTRICT
SM11862,51.6,31.9833,Shahrestan-e Dehaqan,ADM2,DISTRICT
SM11863,47.35,35.35,Shahrestan-e Dehgolan,ADM2,DISTRICT
SM11864,47.9167,33.6167,Shahrestan-e Dureh,ADM2,DISTRICT
SM11865,50.4142,35.7281,Shahrestan-e Eshtehard,ADM2,DISTRICT
SM11866,46.6667,34.05,Shahrestan-e Eslamabad-e Gharb,ADM2,DISTRICT
SM11867,49.1667,35.1167,Shahrestan-e Famenin,ADM2,DISTRICT
SM11868,49.6333,34.6,Shahrestan-e Farahan,ADM2,DISTRICT
SM11869,52.25,28.75,Shahrestan-e Farashband,ADM2,DISTRICT
SM11870,52.5667,36.6333,Shahrestan-e Fareydunkenar,ADM2,DISTRICT
SM11871,50.5624,32.2578,Shahrestan-e Farsan,ADM2,DISTRICT
SM11872,57.2167,28.2,Shahrestan-e Faryab,ADM2,DISTRICT
SM11873,52.5667,28.9167,Shahrestan-e Firuzabad,ADM2,DISTRICT
SM11874,58.4167,36.3,Shahrestan-e Firuzeh,ADM2,DISTRICT
SM11875,50.75,30.3333,Shahrestan-e Gachsaran,ADM2,DISTRICT
SM11876,55.65,37.3,Shahrestan-e Galikesh,ADM2,DISTRICT
SM11877,53.8667,36.7167,Shahrestan-e Galugah,ADM2,DISTRICT
SM11878,52.2127,34.9636,Shahrestan-e Garmsar,ADM2,DISTRICT
SM11879,53.65,27.65,Shahrestan-e Gerash,ADM2,DISTRICT
SM11880,54.15,37.15,Shahrestan-e Gomishan,ADM2,DISTRICT
SM11881,58.95,34.3833,Shahrestan-e Gonabad,ADM2,DISTRICT
SM11882,55,37.5,Shahrestan-e Gonbad-e Kavus,ADM2,DISTRICT
SM11883,54.5167,36.7333,Shahrestan-e Gorgan,ADM2,DISTRICT
SM11884,46.9168,37.4667,Shahrestan-e Hashtrud,ADM2,DISTRICT
SM11885,49.6,30.3333,Shahrestan-e Hendijan,ADM2,DISTRICT
SM11886,61.6167,31.25,Shahrestan-e Hirmand,ADM2,DISTRICT
SM11887,60.2107,27.6195,Shahrestan-e Iranshahr,ADM2,DISTRICT
SM11888,56.6833,37.0167,Shahrestan-e Jajarm,ADM2,DISTRICT
SM11889,46.3,34.8667,Shahrestan-e Javanrud,ADM2,DISTRICT
SM11890,57.5833,28.8333,Shahrestan-e Jiroft,ADM2,DISTRICT
SM11891,57.0333,36.6667,Shahrestan-e Joghatay,ADM2,DISTRICT
SM11892,45.9168,38.8334,Shahrestan-e Jolfa,ADM2,DISTRICT
SM11893,52.9496,36.6718,Shahrestan-e Juybar,ADM2,DISTRICT
SM11894,57.6167,27.9,Shahrestan-e Kahnuj,ADM2,DISTRICT
SM11895,55.5333,37.5333,Shahrestan-e Kalaleh,ADM2,DISTRICT
SM11896,51.1167,36.45,Shahrestan-e Kalar Dasht,ADM2,DISTRICT
SM11897,59.9,36.9,Shahrestan-e Kalat,ADM2,DISTRICT
SM11898,52.2686,27.7133,Shahrestan-e Kangan,ADM2,DISTRICT
SM11899,51.2833,33.8833,Shahrestan-e Kashan,ADM2,DISTRICT
SM11900,58.4833,35.4333,Shahrestan-e Kashmar,ADM2,DISTRICT
SM11901,52.7333,29.2167,Shahrestan-e Kavar,ADM2,DISTRICT
SM11902,58.2,35.1167,Shahrestan-e Khalilabad,ADM2,DISTRICT
SM11903,48.5447,37.406,Shahrestan-e Khalkhal,ADM2,DISTRICT
SM11904,55.55,27.2667,Shahrestan-e Khamir,ADM2,DISTRICT
SM11905,54.2,30.3833,Shahrestan-e Khatam,ADM2,DISTRICT
SM11906,53.25,29.5,Shahrestan-e Kherameh,ADM2,DISTRICT
SM11907,46.95,39.0667,Shahrestan-e Khoda Afarin,ADM2,DISTRICT
SM11908,51.5,32.65,Shahrestan-e Khomeyni Shahr,ADM2,DISTRICT
SM11909,49.1833,34.3667,Shahrestan-e Khondab,ADM2,DISTRICT
SM11910,48.5833,33.3833,Shahrestan-e Khorramabad,ADM2,DISTRICT
SM11911,53.0833,30.5,Shahrestan-e Khorrambid,ADM2,DISTRICT
SM11912,58.0667,36.4333,Shahrestan-e Khowshab,ADM2,DISTRICT
SM11913,54.8833,33.7667,Shahrestan-e Khur va Biabanak,ADM2,DISTRICT
SM11914,58.7667,32.1833,Shahrestan-e Khusf,ADM2,DISTRICT
SM11915,50.7667,32,Shahrestan-e Kiar,ADM2,DISTRICT
SM11916,50.45,31.0333,Shahrestan-e Kohgiluyeh,ADM2,DISTRICT
SM11917,49.3333,34.7667,Shahrestan-e Komijan,ADM2,DISTRICT
SM11918,59.85,25.55,Shahrestan-e Konarak,ADM2,DISTRICT
SM11919,54.2,36.6833,Shahrestan-e Kordkuy,ADM2,DISTRICT
SM11920,48.2699,37.7214,Shahrestan-e Kowsar,ADM2,DISTRICT
SM11921,56.3333,31.3833,Shahrestan-e Kuhbanan,ADM2,DISTRICT
SM11922,47.4,33.5167,Shahrestan-e Kuhdasht,ADM2,DISTRICT
SM11923,50,32.4167,Shahrestan-e Kuhrang,ADM2,DISTRICT
SM11924,50.0167,37.2167,Shahrestan-e Lahijan,ADM2,DISTRICT
SM11925,49.1667,32.4167,Shahrestan-e Lali,ADM2,DISTRICT
SM11926,53.3833,27.3667,Shahrestan-e Lamerd,ADM2,DISTRICT
SM11927,54.45,27.75,Shahrestan-e Larestan,ADM2,DISTRICT
SM11928,58.7833,34.9833,Shahrestan-e Mah Velat,ADM2,DISTRICT
SM11929,44.5834,39.4167,Shahrestan-e Maku,ADM2,DISTRICT
SM11930,50.65,35.6333,Shahrestan-e Malard,ADM2,DISTRICT
SM11931,46.2167,37.1334,Shahrestan-e Malekan,ADM2,DISTRICT
SM11932,46.55,33.3,Shahrestan-e Malekshahi,ADM2,DISTRICT
SM11933,56.6,37.7,Shahrestan-e Maneh va Samalqan,ADM2,DISTRICT
SM11934,57.6167,27.35,Shahrestan-e Manujan,ADM2,DISTRICT
SM11935,46.3937,37.3806,Shahrestan-e Maragheh,ADM2,DISTRICT
SM11936,55.9167,37.85,Shahrestan-e Maraveh Tappeh,ADM2,DISTRICT
SM11937,46.35,35.5667,Shahrestan-e Marivan,ADM2,DISTRICT
SM11938,48.9833,37.35,Shahrestan-e Masal,ADM2,DISTRICT
SM11939,49.2667,31.9833,Shahrestan-e Masjed Soleyman,ADM2,DISTRICT
SM11940,55.95,36.6333,Shahrestan-e Mayamey,ADM2,DISTRICT
SM11941,46.1667,33.2833,Shahrestan-e Mehran,ADM2,DISTRICT
SM11942,61.5333,27.05,Shahrestan-e Mehrestan,ADM2,DISTRICT
SM11943,54.6167,31.4167,Shahrestan-e Mehriz,ADM2,DISTRICT
SM11944,46.2,36.9,Shahrestan-e Miandoab,ADM2,DISTRICT
SM11945,53.3,36.5833,Shahrestan-e Miandorud,ADM2,DISTRICT
SM11946,57.2833,26.9333,Shahrestan-e Minab,ADM2,DISTRICT
SM11947,55.45,37.1167,Shahrestan-e Minudasht,ADM2,DISTRICT
SM11948,53.7167,33.4666,Shahrestan-e Na'in,ADM2,DISTRICT
SM11949,51.1167,32.9333,Shahrestan-e Najafabad,ADM2,DISTRICT
SM11950,48.5167,38.3833,Shahrestan-e Namin,ADM2,DISTRICT
SM11951,58.7333,29.2667,Shahrestan-e Narmashir,ADM2,DISTRICT
SM11952,59.8167,31.5667,Shahrestan-e Nehbandan,ADM2,DISTRICT
SM11953,53.6,36.5167,Shahrestan-e Neka,ADM2,DISTRICT
SM11954,54.3333,29.3333,Shahrestan-e Neyriz,ADM2,DISTRICT
SM11955,58.8833,36.2167,Shahrestan-e Neyshabur,ADM2,DISTRICT
SM11956,48.0833,38,Shahrestan-e Nir,ADM2,DISTRICT
SM11957,49.7,30.75,Shahrestan-e Omidiyeh,ADM2,DISTRICT
SM11958,56.5333,28.4667,Shahrestan-e Orzu'iyeh,ADM2,DISTRICT
SM11959,45.8834,37.9501,Shahrestan-e Osku,ADM2,DISTRICT
SM11960,53.2667,27.1667,Shahrestan-e Parsian,ADM2,DISTRICT
SM11961,53.15,30.1333,Shahrestan-e Pasargad,ADM2,DISTRICT
SM11962,46.2667,35.05,Shahrestan-e Paveh,ADM2,DISTRICT
SM11963,51.7167,35.35,Shahrestan-e Pishva,ADM2,DISTRICT
SM11964,52.9,36.4833,Shahrestan-e Qa'em Shahr,ADM2,DISTRICT
SM11965,59.3502,33.742,Shahrestan-e Qa'en,ADM2,DISTRICT
SM11966,58.3333,27.2,Shahrestan-e Qal'eh Ganj,ADM2,DISTRICT
SM11967,49.8167,36.4333,Shahrestan-e Qazvin,ADM2,DISTRICT
SM11968,52.9,28.3167,Shahrestan-e Qir va Karzin,ADM2,DISTRICT
SM11969,56.9833,29.25,Shahrestan-e Rabor,ADM2,DISTRICT
SM11970,55.95,30.5333,Shahrestan-e Rafsanjan,ADM2,DISTRICT
SM11971,49.65,31.2167,Shahrestan-e Ramhormoz,ADM2,DISTRICT
SM11972,55.0833,36.9333,Shahrestan-e Ramian,ADM2,DISTRICT
SM11973,49.3833,30.9667,Shahrestan-e Ramshir,ADM2,DISTRICT
SM11974,46.65,34.75,Shahrestan-e Ravansar,ADM2,DISTRICT
SM11975,48.95,37.5333,Shahrestan-e Rezvanshahr,ADM2,DISTRICT
SM11976,51.0307,35.5212,Shahrestan-e Robat Karim,ADM2,DISTRICT
SM11977,59.3667,34.9167,Shahrestan-e Roshtkhvar,ADM2,DISTRICT
SM11978,58.4667,27.9,Shahrestan-e Rudbar-e Jonubi,ADM2,DISTRICT
SM11979,50.3,36.8667,Shahrestan-e Rudsar,ADM2,DISTRICT
SM11980,57.4167,36.0167,Shahrestan-e Sabzevar,ADM2,DISTRICT
SM11981,47.9833,38.1833,Shahrestan-e Sar'eyn,ADM2,DISTRICT
SM11982,62.568,27.3235,Shahrestan-e Saravan,ADM2,DISTRICT
SM11983,61.4818,26.4339,Shahrestan-e Sarbaz,ADM2,DISTRICT
SM11984,60.0833,32.4,Shahrestan-e Sarbisheh,ADM2,DISTRICT
SM11985,53.2833,36.3333,Shahrestan-e Sari,ADM2,DISTRICT
SM11986,46.3333,35.25,Shahrestan-e Sarvabad,ADM2,DISTRICT
SM11987,53.1333,29.2167,Shahrestan-e Sarvestan,ADM2,DISTRICT
SM11988,52.9167,36.0833,Shahrestan-e Savadkuh,ADM2,DISTRICT
SM11989,50.8333,36,Shahrestan-e Savojbolagh,ADM2,DISTRICT
SM11990,53.7833,35.1167,Shahrestan-e Semnan,ADM2,DISTRICT
SM11991,46.6668,36.6834,Shahrestan-e Shahin Dezh,ADM2,DISTRICT
SM11992,51.2,33.2833,Shahrestan-e Shahin Shahr va Meymeh,ADM2,DISTRICT
SM11993,55.05,30.2333,Shahrestan-e Shahr-e Babak,ADM2,DISTRICT
SM11994,51.8333,31.9,Shahrestan-e Shahreza,ADM2,DISTRICT
SM11995,51.0663,35.6283,Shahrestan-e Shahriar,ADM2,DISTRICT
SM11996,55.5,35.5,Shahrestan-e Shahrud,ADM2,DISTRICT
SM11997,52.5,29.5833,Shahrestan-e Shiraz,ADM2,DISTRICT
SM11998,48.85,31.9,Shahrestan-e Shushtar,ADM2,DISTRICT
SM11999,49.9,36.9333,Shahrestan-e Siahkal,ADM2,DISTRICT
SM12000,61.9167,27.3333,Shahrestan-e Sib va Suran,ADM2,DISTRICT
SM12001,57.1833,26.5,Shahrestan-e Sirik,ADM2,DISTRICT
SM12002,46.2833,38.0833,Shahrestan-e Tabriz,ADM2,DISTRICT
SM12003,47.1667,36.5335,Shahrestan-e Takab,ADM2,DISTRICT
SM12004,49.55,36,Shahrestan-e Takestan,ADM2,DISTRICT
SM12005,50.7761,36.2053,Shahrestan-e Taleqan,ADM2,DISTRICT
SM12006,48.7833,37.9,Shahrestan-e Talesh,ADM2,DISTRICT
SM12007,60.7333,34.7333,Shahrestan-e Taybad,ADM2,DISTRICT
SM12008,51.4174,35.7101,Shahrestan-e Tehran,ADM2,DISTRICT
SM12009,50.9167,32.8333,Shahrestan-e Tiran va Karvan,ADM2,DISTRICT
SM12010,50.8,36.6333,Shahrestan-e Tonekabon,ADM2,DISTRICT
SM12011,59.1,35.4667,Shahrestan-e Torbat-e Heydariyeh,ADM2,DISTRICT
SM12012,51.6412,35.3308,Shahrestan-e Varamin,ADM2,DISTRICT
SM12013,46.464,38.5949,Shahrestan-e Varzaqan,ADM2,DISTRICT
SM12014,54.3607,31.8898,Shahrestan-e Yazd,ADM2,DISTRICT
SM12015,60.8536,30.8543,Shahrestan-e Zabol,ADM2,DISTRICT
SM12016,60.2481,29.48,Shahrestan-e Zahedan,ADM2,DISTRICT
SM12017,54.4667,28.3,Shahrestan-e Zarrin Dasht,ADM2,DISTRICT
SM12018,59.7333,35.2833,Shahrestan-e Zaveh,ADM2,DISTRICT
SM12019,60.3,33.5833,Shahrestan-e Zirkuh,ADM2,DISTRICT
SM259977,70.1355,37.3412,Shahri Buzurg,ADM2,DISTRICT
SM18444,72.0579,40.7107,Shahrikhon Shahri,ADM2,DISTRICT
SM18445,72.0667,40.7167,Shahrikhon Tumani,ADM2,DISTRICT
SM305998,68.42,38.7,Shahrinav,ADM2,DISTRICT
SM303515,67.1667,39,Shahrisabz,ADM2,DISTRICT
SM9585,66.4725,33.7079,Shahristan (2005-),ADM2,DISTRICT
SM305976,68.78,39.65,Shahriston,ADM2,DISTRICT
SM305959,68.0376,37.4704,Shahrituz,ADM2,DISTRICT
SM244275,50.2074,36.7581,Shahrudi-Southern Talysh,LANGUAGE,LANGUAGE
SM11439,0.155,5.895,Shai Osudoku District,ADM2,DISTRICT
SM277004,76.25,23.5,Shajapur (2013-),ADM2,DISTRICT
SM260038,69.0364,34.6638,Shakar Dara (1996-),ADM2,DISTRICT
SM257486,12.25,-0.36,Shake,ETHNICITY,ETHNICITY
SM293715,35.4954,56.0019,Shakhovskoy rayon,ADM2,DISTRICT
SM299956,38.4627,48.0712,Shakhtars'ka,ADM2,DISTRICT
SM18273,38.5,48,Shakhtarsk Raion,ADM2,DISTRICT
SM13251,72.6,49.6833,Shakhtinsk Qalasy,ADM2,DISTRICT
SM293785,46.6209,57.6809,Shakhunskiy rayon,ADM2,DISTRICT
SM278178,140.4695,43.2973,Shakotan,ADM2,DISTRICT
SM279980,67.5,53.8333,Shal Akyna,ADM2,DISTRICT
SM17075,45.9167,43.1667,Shalinskiy Rayon,ADM2,DISTRICT
SM279929,59.5,47.5,Shalkarskiy,ADM2,DISTRICT
SM244286,9.6499,9.8756,Shall-Zwall,LANGUAGE,LANGUAGE
SM3195,114.1625,22.3299,Sham Shui Po,ADM1,DISTRICT
SM4412,-1.6333,5.0167,Shama,ADM2,DISTRICT
SM244295,6.2028,10.4369,Shama-Sambuga,LANGUAGE,LANGUAGE
SM3196,33.7,30.5,Shamal Sina',ADM1,DISTRICT
SM244294,8.0516,9.47,Shamang,LANGUAGE,LANGUAGE
SM244296,38.29,-4.8,Shambala,LANGUAGE,LANGUAGE
SM292853,46.5,42.4167,Shamil'skiy rayon,ADM2,DISTRICT
SM305830,44.6086,31.7791,Shamiya,ADM2,DISTRICT
SM257491,34.63,15.83,Shamkella,ETHNICITY,ETHNICITY
SM277332,77.2658,29.4638,Shamli,ADM2,DISTRICT
SM257492,42.63,28.2,Shammar,ETHNICITY,ETHNICITY
SM257493,44.66,20.1,Shamran,ETHNICITY,ETHNICITY
SM260162,69.5321,33.3153,Shamul,ADM2,DISTRICT
SM9586,67.7705,31.8215,Shamulzai (2005-),ADM2,DISTRICT
SM305243,31.6415,-17.1237,Shamva,ADM2,DISTRICT
SM244306,98.0342,21.5995,Shan,LANGUAGE,LANGUAGE
SM3197,98,22,Shan,ADM1,DISTRICT
SM257494,98.16,19.72,Shan,ETHNICITY,ETHNICITY
SM257494,96.52,21.07,Shan,ETHNICITY,ETHNICITY
SM257495,49.93,16.61,Shanafir,ETHNICITY,ETHNICITY
SM3198,118.25,36.3333,Shandong,ADM1,DISTRICT
SM244311,-73.3264,-8.0092,Shanenawa,LANGUAGE,LANGUAGE
SM244307,7.8595,9.4536,Shang,LANGUAGE,LANGUAGE
SM244310,4.4146,11.2752,Shanga,LANGUAGE,LANGUAGE
SM284940,4.6095,11.2117,Shanga,ADM2,DISTRICT
SM257498,33.57,-21.62,Shangaan,ETHNICITY,ETHNICITY
SM257499,37,6,Shangama,ETHNICITY,ETHNICITY
SM3199,121.4167,31.1667,Shanghai,ADM1,DISTRICT
SM244315,121.4754,31.2229,Shanghainese,DIALECT,LANGUAGE
SM375428,72.7014,34.6852,Shangla,ADM2:ADM3,DISTRICT
SM269285,109.8911,33.6933,Shangluo,ADM2,DISTRICT
SM18170,22.4556,-16.3083,Shangombo (2012-),ADM2,DISTRICT
SM269163,115.6972,34.3264,Shangqiu,ADM2,DISTRICT
SM269231,117.471,28.6743,Shangrao,ADM2,DISTRICT
SM285381,11.9639,10.1839,Shani,ADM2,DISTRICT
SM244313,25.814,-17.3099,Shanjo,LANGUAGE,LANGUAGE
SM257500,24.16,-16.8,Shanjo,ETHNICITY,ETHNICITY
SM269363,92.5568,28.5563,Shannan,ADM2,DISTRICT
SM302529,-102.5516,43.3356,Shannon,ADM2,DISTRICT
SM301511,-91.4005,37.1574,Shannon,ADM2,DISTRICT
SM284881,7.9515,12.0462,Shanono,ADM2,DISTRICT
SM244300,116.6068,23.3372,Shantou,DIALECT,LANGUAGE
SM269419,116.5397,23.3202,Shantou,ADM2,DISTRICT
SM269420,115.4954,22.7442,Shanwei,ADM2,DISTRICT
SM3200,112.25,37.6667,Shanxi,ADM1,DISTRICT
SM269421,113.8285,24.8392,Shaoguan,ADM2,DISTRICT
SM244320,117.2972,27.1128,Shaowu,DIALECT,LANGUAGE
SM269391,120.6,29.7017,Shaoxing,ADM2,DISTRICT
SM269206,110.9515,26.9313,Shaoyang,ADM2,DISTRICT
SM10363,106.4555,29.5417,Shapingba District,ADM2,DISTRICT
SM305839,44.3443,36.3968,Shaqlawa,ADM2,DISTRICT
SM305839,44.3443,36.3968,Shaqlawa,ADM2,DISTRICT
SM305839,44.2825,36.4408,Shaqlawa,ADM2,DISTRICT
SM305839,44.2825,36.4408,Shaqlawa,ADM2,DISTRICT
SM17813,45.1333,25.3667,Shaqra',ADM2,DISTRICT
SM13252,81,49.5,Shar Audany,ADM2,DISTRICT
SM17076,45.5833,58.5,Shar'inskiy rayon,ADM2,DISTRICT
SM304992,43.7672,13.7336,Shara'b Ar Rawnah,ADM2,DISTRICT
SM304993,43.8861,13.7832,Shara'b As Salam,ADM2,DISTRICT
SM260147,68.7285,33.2002,Sharan,ADM2,DISTRICT
SM244325,-71.1232,-9.8626,Sharanahua,LANGUAGE,LANGUAGE
SM293786,46.4167,57.0833,Sharangskiy rayon,ADM2,DISTRICT
SM294285,54,54.9167,Sharanskiy rayon,ADM2,DISTRICT
SM257501,37.19,29.87,Shararat,ETHNICITY,ETHNICITY
SM304854,43.6546,15.7286,Sharas,ADM2,DISTRICT
SM12051,45.6,35.7,Sharbazher District,ADM2,DISTRICT
SM295233,33.56,15.77,Sharg En Nile,ADM2,DISTRICT
SM283544,95.2669,46.2423,Sharga,ADM2,DISTRICT
SM299780,28.0842,48.7381,Sharhorods'kyi,ADM2,DISTRICT
SM13073,144.732,43.8812,Shari-gun,ADM2,DISTRICT
SM262247,90.3667,23.25,Shariatpur,ADM2,DISTRICT
SM3201,55.4406,25.3176,Sharjah,ADM1,DISTRICT
SM261311,114.2185,-26.3892,Shark Bay,ADM2,DISTRICT
SM262671,27.4167,55.4167,Sharkawshchyna,ADM2,DISTRICT
SM301485,-90.8132,32.8797,Sharkey,ADM2,DISTRICT
SM303427,68.3333,40.3333,Sharof Rashidov,ADM2,DISTRICT
SM302416,-91.4799,36.1612,Sharp,ADM2,DISTRICT
SM17891,27.5,9,Sharq Baḥr al Ghazal,ADM2,DISTRICT
SM260690,55.5085,25.3345,Sharqan,ADM2,DISTRICT
SM9751,45.0833,39.5,Sharur Rayon (2004-),ADM2,DISTRICT
SM17814,47.5,18,Sharurah,ADM2,DISTRICT
SM244326,13.5657,10.1414,Sharwa,LANGUAGE,LANGUAGE
SM283782,106.4304,49.2228,Sharyngol,ADM2,DISTRICT
SM293452,89.25,55.5,Sharypovskiy rayon,ADM2,DISTRICT
SM250315,34.68,-2.27,Shashi,ETHNICITY,ETHNICITY
SM244330,-122.738,41.7587,Shasta,LANGUAGE,LANGUAGE
SM303202,-122.0405,40.7638,Shasta,ADM2,DISTRICT
SM257507,-121.51,41.09,Shasta,ETHNICITY,ETHNICITY
SM257507,-121.51,41.09,Shasta,ETHNICITY,ETHNICITY
SM257507,-121.51,41.09,Shasta,ETHNICITY,ETHNICITY
SM257507,-121.51,41.09,Shasta,ETHNICITY,ETHNICITY
SM257507,-123,41,Shasta,ETHNICITY,ETHNICITY
SM257507,-123,41,Shasta,ETHNICITY,ETHNICITY
SM257507,-123,41,Shasta,ETHNICITY,ETHNICITY
SM257507,-123,41,Shasta,ETHNICITY,ETHNICITY
SM257507,-122,41,Shasta,ETHNICITY,ETHNICITY
SM257507,-122,41,Shasta,ETHNICITY,ETHNICITY
SM257507,-122,41,Shasta,ETHNICITY,ETHNICITY
SM257507,-122,41,Shasta,ETHNICITY,ETHNICITY
SM257507,-122.7,41.62,Shasta,ETHNICITY,ETHNICITY
SM257507,-122.7,41.62,Shasta,ETHNICITY,ETHNICITY
SM257507,-122.7,41.62,Shasta,ETHNICITY,ETHNICITY
SM257507,-122.7,41.62,Shasta,ETHNICITY,ETHNICITY
SM257508,-122.3,41.5,Shasta (East),ETHNICITY,ETHNICITY
SM257509,-122.8,41.8,Shasta (West),ETHNICITY,ETHNICITY
SM293787,44.1667,55.1667,Shatkovskiy rayon,ADM2,DISTRICT
SM305772,46.3,31.4,Shatrah,ADM2,DISTRICT
SM293481,64.75,56.5,Shatrovskiy rayon,ADM2,DISTRICT
SM299804,23.8652,51.5224,Shats'kyi,ADM2,DISTRICT
SM244333,29.7687,10.8525,Shatt,LANGUAGE,LANGUAGE
SM17077,39.75,55.5833,Shaturskiy Rayon,ADM2,DISTRICT
SM244334,9.0667,10.275,Shau,LANGUAGE,LANGUAGE
SM9752,46.5,40.4167,Shaumyan Rayon,ADM2,DISTRICT
SM9753,49.9167,40.3833,Shaumyanovskiy Rayon,ADM2,DISTRICT
SM9725,44.45,40.1833,Shaumyanskiy Rayon,ADM2,DISTRICT
SM4266,73.1976,6.3213,Shaviyani Atholhu,ADM1:ADM2,DISTRICT
SM303278,-88.7654,44.7892,Shawano,ADM2,DISTRICT
SM228675,-76.8252,-5.6376,Shawi,LANGUAGE,LANGUAGE
SM250727,5.11,34.23,Shawiya,ETHNICITY,ETHNICITY
SM250727,5.11,34.23,Shawiya,ETHNICITY,ETHNICITY
SM250727,7,36,Shawiya,ETHNICITY,ETHNICITY
SM250727,7,36,Shawiya,ETHNICITY,ETHNICITY
SM244336,-83,40,Shawnee,LANGUAGE,LANGUAGE
SM300976,-95.7565,39.0415,Shawnee,ADM2,DISTRICT
SM257511,-89.27,37.72,Shawnee,ETHNICITY,ETHNICITY
SM257511,-89.27,37.72,Shawnee,ETHNICITY,ETHNICITY
SM257511,-85,37,Shawnee,ETHNICITY,ETHNICITY
SM257511,-85,37,Shawnee,ETHNICITY,ETHNICITY
SM257512,-7.09,33.65,Shawya,ETHNICITY,ETHNICITY
SM18446,69.2369,41.3139,Shaykhontohur Tumani,ADM2,DISTRICT
SM277569,49.3,33.9,Shazand,ADM2,DISTRICT
SM18274,39.2465,48.9403,Shchastynskyi Raion,ADM2,DISTRICT
SM17078,38.1667,56,Shchelkovskiy Rayon,ADM2,DISTRICT
SM293390,38.6674,46.5556,Shcherbinovskiy rayon,ADM2,DISTRICT
SM17079,36.8333,51.9167,Shchigrovskiy Rayon,ADM2,DISTRICT
SM299696,32,51.8333,Shchors'kyi,ADM2,DISTRICT
SM293482,62.75,55.1667,Shchuchanskiy rayon,ADM2,DISTRICT
SM262608,24.6667,53.5833,Shchuchyn,ADM2,DISTRICT
SM244339,114.814,23.0399,She,LANGUAGE,LANGUAGE
SM257514,117.44,27.25,She,ETHNICITY,ETHNICITY
SM257514,117.44,27.25,She,ETHNICITY,ETHNICITY
SM257514,117.44,27.25,She,ETHNICITY,ETHNICITY
SM257514,119,27,She,ETHNICITY,ETHNICITY
SM257514,119,27,She,ETHNICITY,ETHNICITY
SM257514,119,27,She,ETHNICITY,ETHNICITY
SM257514,119,27,She,ETHNICITY,ETHNICITY
SM257514,119,27,She,ETHNICITY,ETHNICITY
SM257514,119,27,She,ETHNICITY,ETHNICITY
SM244340,35.9498,7.7485,She (Ethiopia),DIALECT,LANGUAGE
SM292874,85.5,51.3333,Shebalinskiy rayon,ADM2,DISTRICT
SM258896,-61.8486,0.2265,Shebayo,LANGUAGE,LANGUAGE
SM17080,37,50.5,Shebekinskiy Rayon,ADM2,DISTRICT
SM303279,-87.8,43.7562,Sheboygan,ADM2,DISTRICT
SM295801,45.4291,9.9925,Sheekh,ADM2,DISTRICT
SM299413,30.3304,-0.6262,Sheema,ADM1:ADM2,DISTRICT
SM3202,168.3673,-17.5894,Shefa,ADM1,DISTRICT
SM274684,-1.5,53.4167,Sheffield,ADM1:ADM2,DISTRICT
SM12725,-78.2917,18.2841,Shefield,ADM2,DISTRICT
SM295243,30.08,13.13,Sheikan,ADM2,DISTRICT
SM273870,34.0678,31.077,Sheikh Zawid,ADM2,DISTRICT
SM277429,85.7818,25.1307,Sheikhpura,ADM2,DISTRICT
SM375465,74.1382,31.7097,Sheikhupura,ADM2:ADM3,DISTRICT
SM274316,35.4017,7.5617,Sheka,ADM2,DISTRICT
SM251047,20.65,-25.63,Shekgalagari,ETHNICITY,ETHNICITY
SM251047,20.65,-25.63,Shekgalagari,ETHNICITY,ETHNICITY
SM251047,21.7511,-24.7967,Shekgalagari,ETHNICITY,ETHNICITY
SM251047,21.7511,-24.7967,Shekgalagari,ETHNICITY,ETHNICITY
SM260182,68.4189,34.9468,Shekh Ali,ADM2,DISTRICT
SM12052,43.3,36.7,Shekhan District,ADM2,DISTRICT
SM244344,75.407,27.34,Shekhawati,LANGUAGE,LANGUAGE
SM244345,35.3922,7.2742,Shekkacho,LANGUAGE,LANGUAGE
SM244346,35.2826,7.0502,Sheko,LANGUAGE,LANGUAGE
SM257518,36,7,Sheko,ETHNICITY,ETHNICITY
SM294944,38.5833,59.25,Sheksninskiy rayon,ADM2,DISTRICT
SM292992,82.25,53.4167,Shelabolikhinskiy rayon,ADM2,DISTRICT
SM268829,-65.2821,43.8502,Shelburne,ADM2,DISTRICT
SM302763,-94.145,31.7924,Shelby,ADM2,DISTRICT
SM302620,-89.8956,35.184,Shelby,ADM2,DISTRICT
SM302156,-84.2047,40.3315,Shelby,ADM2,DISTRICT
SM301512,-92.0766,39.7978,Shelby,ADM2,DISTRICT
SM300865,-95.3102,41.6851,Shelby,ADM2,DISTRICT
SM300762,-85.7917,39.5237,Shelby,ADM2,DISTRICT
SM300678,-88.8055,39.3912,Shelby,ADM2,DISTRICT
SM300996,-85.1948,38.2154,Shelby,ADM2,DISTRICT
SM300300,-86.6607,33.2643,Shelby,ADM2,DISTRICT
SM13253,78.5,43.5,Shelek Audany,ADM2,DISTRICT
SM17081,103.8333,52,Shelekhovskiy Rayon,ADM2,DISTRICT
SM292727,46,43.5833,Shelkovskiy rayon,ADM2,DISTRICT
SM284840,12.1267,9.9481,Shelleng,ADM2,DISTRICT
SM261458,150.7712,-34.5903,Shellharbour,ADM2,DISTRICT
SM295097,118.6667,51.6667,Shelopuginskiy rayon,ADM2,DISTRICT
SM244348,-7.7398,53.7907,Shelta,LANGUAGE,LANGUAGE
SM257519,-64.84,50.86,Shelter Bay,ETHNICITY,ETHNICITY
SM274001,37.8376,14.9246,Shemboko,ADM2,DISTRICT
SM277634,51.59,35.9314,Shemiranat,ADM2,DISTRICT
SM279950,82.25,50.6667,Shemonaikhinskiy,ADM2,DISTRICT
SM244352,-6.3713,10.4867,Shempire Senoufo,LANGUAGE,LANGUAGE
SM292800,47.5,54.9167,Shemurshinskiy rayon,ADM2,DISTRICT
SM257521,32.45,10.9,Shenabla,ETHNICITY,ETHNICITY
SM302953,-78.5706,38.8584,Shenandoah,ADM2,DISTRICT
SM285186,9.5,8.7167,Shendam,ADM2,DISTRICT
SM295186,34.184,16.622,Shendi,ADM2,DISTRICT
SM244354,92.591,22.1901,Shendu,LANGUAGE,LANGUAGE
SM244357,8.6392,10.5256,Sheni,LANGUAGE,LANGUAGE
SM244358,8.6392,10.5256,Shenic,FAMILY,LANGUAGE
SM293596,42.4167,62.25,Shenkurskiy rayon,ADM2,DISTRICT
SM269193,110.4972,31.5834,Shennongjia,ADM2,DISTRICT
SM269245,123.1369,42.1822,Shenyang,ADM2,DISTRICT
SM244359,123.1563,41.805,Shenyang Mandarin,DIALECT,LANGUAGE
SM269422,113.9859,22.5565,Shenzhen,ADM2,DISTRICT
SM277430,85.3,26.5,Sheohar,ADM2,DISTRICT
SM277005,77,25.8,Sheopur,ADM2,DISTRICT
SM299452,27.0681,50.1805,Shepetivs'ka,ADM2,DISTRICT
SM5103,69.7066,31.7542,Sherani,ADM2:ADM3,DISTRICT
SM279984,78.25,52.4167,Sherbaktinskiy,ADM2,DISTRICT
SM244364,-12.7405,7.7759,Sherbro,LANGUAGE,LANGUAGE
SM250676,-12.43,7.78,Sherbro,ETHNICITY,ETHNICITY
SM250676,-12.43,7.78,Sherbro,ETHNICITY,ETHNICITY
SM250676,-12.69,7.52,Sherbro,ETHNICITY,ETHNICITY
SM250676,-12.69,7.52,Sherbro,ETHNICITY,ETHNICITY
SM301407,-93.7746,45.444,Sherburne,ADM2,DISTRICT
SM244363,92.5799,27.0035,Sherdukpen,LANGUAGE,LANGUAGE
SM257524,-45.09,-9.19,Sherente,ETHNICITY,ETHNICITY
SM257524,-45.09,-9.19,Sherente,ETHNICITY,ETHNICITY
SM257524,-48,-9,Sherente,ETHNICITY,ETHNICITY
SM257524,-48,-9,Sherente,ETHNICITY,ETHNICITY
SM244367,92.2578,27.1286,Shergaon,DIALECT,LANGUAGE
SM303303,-106.8795,44.79,Sheridan,ADM2,DISTRICT
SM301757,-102.409,42.5048,Sheridan,ADM2,DISTRICT
SM302066,-100.3457,47.5754,Sheridan,ADM2,DISTRICT
SM301662,-104.5047,48.7212,Sheridan,ADM2,DISTRICT
SM300978,-100.4418,39.3504,Sheridan,ADM2,DISTRICT
SM302764,-101.8935,36.2777,Sherman,ADM2,DISTRICT
SM302269,-120.6893,45.4052,Sherman,ADM2,DISTRICT
SM301758,-98.9762,41.2206,Sherman,ADM2,DISTRICT
SM300979,-101.72,39.3514,Sherman,ADM2,DISTRICT
SM303432,66.8333,37.75,Sherobod,ADM2,DISTRICT
SM257525,86.767,27.85,Sherpa,ETHNICITY,ETHNICITY
SM257525,86.767,27.85,Sherpa,ETHNICITY,ETHNICITY
SM257525,87,28,Sherpa,ETHNICITY,ETHNICITY
SM257525,87,28,Sherpa,ETHNICITY,ETHNICITY
SM262248,90.0833,25.0833,Sherpur,ADM2,DISTRICT
SM12726,-77.6364,18.3823,Sherwood Content,ADM2,DISTRICT
SM12727,-76.3782,18.1543,Sherwood Forest,ADM2,DISTRICT
SM260124,69.9801,34.1942,Sherzad,ADM2,DISTRICT
SM244368,82.6467,27.9437,Sheshi Kham,LANGUAGE,LANGUAGE
SM13254,73.5,48.6667,Shet Audany (excl. Aqadyr),ADM2,DISTRICT
SM257527,-75.86,-6.75,Shetebo,ETHNICITY,ETHNICITY
SM274732,-1.3333,60.3333,Shetland Islands,ADM1:ADM2,DISTRICT
SM299476,30.5167,50.4333,Shevchenkivs'kyi,ADM2,DISTRICT
SM244371,118.4283,30.5898,Shexian,DIALECT,LANGUAGE
SM244377,28.6122,-2.5578,Shi,LANGUAGE,LANGUAGE
SM301331,-84.1467,42.9537,Shiawassee,ADM2,DISTRICT
SM260268,61.3686,32.1086,Shib Koh,ADM2,DISTRICT
SM304820,48.6534,15.8703,Shibam,ADM2,DISTRICT
SM305085,43.8424,15.5028,Shibam Kawkaban,ADM2,DISTRICT
SM260259,68.1449,34.8652,Shibar,ADM2,DISTRICT
SM278946,139.4279,37.9073,Shibata,ADM2,DISTRICT
SM13074,140.65,38.1627,Shibata-gun,ADM2,DISTRICT
SM278183,142.5405,44.1152,Shibetsu City,ADM2,DISTRICT
SM13075,144.8989,43.6643,Shibetsu-gun,ADM2,DISTRICT
SM9587,65.7478,36.5913,Shibirghan (2005-),ADM2,DISTRICT
SM278103,138.9833,36.5,Shibukawa,ADM2,DISTRICT
SM278508,131.1,31.4833,Shibushi,ADM2,DISTRICT
SM279465,139.6982,35.664,Shibuya,ADM2,DISTRICT
SM18171,27.7026,-15.3892,Shibuyunji District,ADM2,DISTRICT
SM257532,14.68,-3.65,Shibwato,ETHNICITY,ETHNICITY
SM3203,44.14,42.32,Shida Kartli,ADM1,DISTRICT
SM227142,113.391,23.656,Shidong Biao,LANGUAGE,LANGUAGE
SM13255,66.75,44.1667,Shieli Audany (excl. Syrdariya),ADM2,DISTRICT
SM3204,136.1333,35.2167,Shiga,ADM1:ADMX,DISTRICT
SM9588,71.2251,35.0363,Shigal wa Sheltan,ADM2,DISTRICT
SM5136,75.7507,35.7615,Shigar,ADM2:ADM3,DISTRICT
SM269364,86.8754,29.1058,Shigatse,ADM2,DISTRICT
SM259978,71.45,37.6167,Shighnan,ADM2,DISTRICT
SM244376,56.1711,25.939,Shihhi Arabic,LANGUAGE,LANGUAGE
SM269145,114.47,38.04,Shijiazhuang,ADM2,DISTRICT
SM279115,135.6678,34.7324,Shijōnawate,ADM2,DISTRICT
SM375496,68.6092,27.9532,Shikarpur,ADM2:ADM3,DISTRICT
SM244378,9.7277,10.5238,Shiki,LANGUAGE,LANGUAGE
SM279202,139.5835,35.837,Shiki,ADM2,DISTRICT
SM9589,70.6518,38.2064,Shiki,ADM2,DISTRICT
SM13076,135.7857,34.5648,Shiki-gun,ADM2,DISTRICT
SM279630,133.5362,33.9354,Shikokuchūō,ADM2,DISTRICT
SM250678,26.18,-10.83,Shila,ETHNICITY,ETHNICITY
SM250678,26.18,-10.83,Shila,ETHNICITY,ETHNICITY
SM250678,28,-10,Shila,ETHNICITY,ETHNICITY
SM250678,28,-10,Shila,ETHNICITY,ETHNICITY
SM250728,-9,30,Shilha,ETHNICITY,ETHNICITY
SM295099,115.6667,52,Shilkinskiy rayon,ADM2,DISTRICT
SM244384,31.7926,9.8547,Shilluk,LANGUAGE,LANGUAGE
SM250679,26.53,10,Shilluk,ETHNICITY,ETHNICITY
SM250679,26.53,10,Shilluk,ETHNICITY,ETHNICITY
SM250679,26.53,10,Shilluk,ETHNICITY,ETHNICITY
SM250679,31.5,9.75,Shilluk,ETHNICITY,ETHNICITY
SM250679,31.5,9.75,Shilluk,ETHNICITY,ETHNICITY
SM250679,31.5,9.75,Shilluk,ETHNICITY,ETHNICITY
SM250679,32,10,Shilluk,ETHNICITY,ETHNICITY
SM250679,32,10,Shilluk,ETHNICITY,ETHNICITY
SM250679,32,10,Shilluk,ETHNICITY,ETHNICITY
SM283829,97.2009,46.8811,Shilüüstei,ADM2,DISTRICT
SM278703,136.8134,34.338,Shima,ADM2,DISTRICT
SM278879,130.3293,32.7943,Shimabara,ADM2,DISTRICT
SM13077,138.1266,34.9122,Shimada-shi (2008-),ADM2,DISTRICT
SM13078,131.2421,25.8382,Shimajiri-gun,ADM2,DISTRICT
SM278187,140.0608,42.655,Shimamaki,ADM2,DISTRICT
SM279116,135.6533,34.8989,Shimamoto,ADM2,DISTRICT
SM3205,132.5611,35.0769,Shimane,ADM1:ADMX,DISTRICT
SM17082,127.5,52.1667,Shimanovskiy Rayon,ADM2,DISTRICT
SM278600,132.8522,33.0816,Shimanto City,ADM2,DISTRICT
SM276876,77.5833,31.1667,Shimla,ADM2,DISTRICT
SM279302,138.9215,34.7088,Shimoda,ADM2,DISTRICT
SM276941,75.16,14.05,Shimoga (1997-),ADM2,DISTRICT
SM13079,141.7621,39.8423,Shimohei-gun,ADM2,DISTRICT
SM13080,137.7232,35.3913,Shimoina-gun,ADM2,DISTRICT
SM13081,141.3336,41.2748,Shimokita-gun,ADM2,DISTRICT
SM13082,137.6173,36.8963,Shimoniikawa Gun,ADM2,DISTRICT
SM279580,130.9877,34.1728,Shimonoseki,ADM2,DISTRICT
SM13083,138.499,36.798,Shimotakai-gun,ADM2,DISTRICT
SM13084,139.731,36.2376,Shimotsuga-gun,ADM2,DISTRICT
SM279332,139.8611,36.4029,Shimotsuke,ADM2,DISTRICT
SM278393,139.9664,36.1874,Shimotsuma,ADM2,DISTRICT
SM293826,30.7167,58.2167,Shimskiy rayon,ADM2,DISTRICT
SM244392,75.287,34.6515,Shina,LANGUAGE,LANGUAGE
SM257560,76.41,34.18,Shina,ETHNICITY,ETHNICITY
SM257560,76.41,34.18,Shina,ETHNICITY,ETHNICITY
SM257560,73,35,Shina,ETHNICITY,ETHNICITY
SM257560,73,35,Shina,ETHNICITY,ETHNICITY
SM244395,-62.3404,-14.3798,Shinabo,LANGUAGE,LANGUAGE
SM279466,139.7302,35.609,Shinagawa,ADM2,DISTRICT
SM260015,62.3808,33.2544,Shindand,ADM2,DISTRICT
SM283602,99.5367,48.946,Shine-Ider,ADM2,DISTRICT
SM283754,99.2666,44.5283,Shinejinst,ADM2,DISTRICT
SM279527,135.8586,33.7666,Shingū,ADM2,DISTRICT
SM279558,140.3399,38.7882,Shinjo,ADM2,DISTRICT
SM279467,139.7036,35.6938,Shinjuku,ADM2,DISTRICT
SM279033,133.5604,35.2032,Shinjō,ADM2,DISTRICT
SM285277,6.512,13.0376,Shinkafi,ADM2,DISTRICT
SM278881,129.0685,32.953,Shinkamigotō,ADM2,DISTRICT
SM260241,67.1561,31.92,Shinkay,ADM2,DISTRICT
SM278061,137.5436,34.9607,Shinshiro,ADM2,DISTRICT
SM260125,70.7958,34.2058,Shinwar,ADM2,DISTRICT
SM260183,68.9708,35.1204,Shinwari,ADM2,DISTRICT
SM3206,32.9167,-3.6667,Shinyanga (2012-),ADM1,DISTRICT
SM299171,33.105,-3.642,Shinyanga Rural,ADM2,DISTRICT
SM299172,33.5,-3.6667,Shinyanga Urban,ADM2,DISTRICT
SM278740,141.0399,38.3265,Shiogama,ADM2,DISTRICT
SM278848,137.9333,36.0833,Shiojiri,ADM2,DISTRICT
SM13085,139.8734,36.75,Shioya-gun,ADM2,DISTRICT
SM257563,-54.28,-5.78,Shipaya,ETHNICITY,ETHNICITY
SM244399,-74.8219,-7.1711,Shipibo-Conibo,LANGUAGE,LANGUAGE
SM252946,-74.67,-9.25,Shipibo-Conibo,ETHNICITY,ETHNICITY
SM252946,-74.67,-9.25,Shipibo-Conibo,ETHNICITY,ETHNICITY
SM252946,-74,-9,Shipibo-Conibo,ETHNICITY,ETHNICITY
SM252946,-74,-9,Shipibo-Conibo,ETHNICITY,ETHNICITY
SM292993,82.0833,52.3333,Shipunovskiy rayon,ADM2,DISTRICT
SM285317,10.0173,11.5041,Shira,ADM2,DISTRICT
SM3207,43.8333,40.75,Shirak,ADM1,DISTRICT
SM279768,140.2496,37.1223,Shirakawa,ADM2,DISTRICT
SM279813,136.8794,36.2198,Shirakawa Village,ADM2,DISTRICT
SM278196,143.9646,43.1388,Shiranuka,ADM2,DISTRICT
SM278197,141.2453,42.5966,Shiraoi,ADM2,DISTRICT
SM279203,139.6667,36.0167,Shiraoka,ADM2,DISTRICT
SM244402,52.5333,29.6167,Shirazi,DIALECT,LANGUAGE
SM18447,69.12,40.2333,Shirin Shahri,ADM2,DISTRICT
SM260280,64.8218,36.2472,Shirin Tagab,ADM2,DISTRICT
SM293210,89.75,54.5,Shirinskiy rayon,ADM2,DISTRICT
SM12728,-76.6111,18.1566,Shirley Castle,ADM2,DISTRICT
SM279383,140.0601,35.8016,Shiroi,ADM2,DISTRICT
SM278741,140.5794,37.9969,Shiroishi,ADM2,DISTRICT
SM285036,6.6831,10.1154,Shiroro,ADM2,DISTRICT
SM277590,57.9,37.5333,Shirvan,ADM2,DISTRICT
SM296717,31.2,-27.1167,Shiselweni,ADM2,DISTRICT
SM3208,31.4,-27.05,Shiselweni,ADM1,DISTRICT
SM278351,134.5434,35.1547,Shisō,ADM2,DISTRICT
SM283560,108.5132,46.0603,Shiveegovi,ADM2,DISTRICT
SM283560,108.5132,46.0603,Shiveegovi,ADM2,DISTRICT
SM283560,108.6507,46.1204,Shiveegovi,ADM2,DISTRICT
SM283560,108.6507,46.1204,Shiveegovi,ADM2,DISTRICT
SM277006,78,25.5,Shivpuri,ADM2,DISTRICT
SM257569,-117,36,Shivwits,ETHNICITY,ETHNICITY
SM257569,-117,36,Shivwits,ETHNICITY,ETHNICITY
SM257569,-114,36.8,Shivwits,ETHNICITY,ETHNICITY
SM257569,-114,36.8,Shivwits,ETHNICITY,ETHNICITY
SM244417,11.9471,-0.0917,Shiwa,LANGUAGE,LANGUAGE
SM13086,141.1522,39.5637,Shiwa-gun,ADM2,DISTRICT
SM18172,31.6933,-10.9889,Shiwang'andu District,ADM2,DISTRICT
SM244419,100.4,28.3895,Shixing,LANGUAGE,LANGUAGE
SM269194,110.5608,32.4527,Shiyan,ADM2,DISTRICT
SM269269,106.516,38.9944,Shizuishan,ADM2,DISTRICT
SM3209,138.3333,35.0167,Shizuoka,ADM1:ADMX,DISTRICT
SM13087,138.3143,35.2016,Shizuoka-shi (2008-),ADM2,DISTRICT
SM262634,30.3333,54.1667,Shklow,ADM2,DISTRICT
SM260472,19.5351,42.0692,Shkodrës,ADM2,DISTRICT
SM3210,19.75,42.1833,Shkodër,ADM1,DISTRICT
SM17083,132.5833,43.3333,Shkotovskiy Rayon,ADM2,DISTRICT
SM303433,67.8333,38,Sho'rchi,ADM2,DISTRICT
SM261459,150.413,-35.1416,Shoalhaven,ADM2,DISTRICT
SM12729,-78.1402,18.2206,Shoalin Grotto,ADM2,DISTRICT
SM296020,29.2488,7.2465,Shobet,ADM2,DISTRICT
SM303461,64.3333,40.5,Shofirkon,ADM2,DISTRICT
SM296010,33.4561,4.2561,Shokodom,ADM2,DISTRICT
SM244423,77.2211,11.9458,Sholaga,LANGUAGE,LANGUAGE
SM260247,66.9098,36.294,Sholgara,ADM2,DISTRICT
SM244425,93.8064,7.021,Shom Peng,LANGUAGE,LANGUAGE
SM262082,30.0539,-3.2852,Shombo,ADM2,DISTRICT
SM284745,11.2566,9.6737,Shomgom,ADM2,DISTRICT
SM284999,3.3695,6.5561,Shomolu,ADM2,DISTRICT
SM257571,93.77,7,Shompen,ETHNICITY,ETHNICITY
SM244429,29.7573,-17.2085,Shona,LANGUAGE,LANGUAGE
SM251006,31.64,-18.79,Shona,ETHNICITY,ETHNICITY
SM251006,31.64,-18.79,Shona,ETHNICITY,ETHNICITY
SM251006,31,-19,Shona,ETHNICITY,ETHNICITY
SM251006,31,-19,Shona,ETHNICITY,ETHNICITY
SM244432,10.9642,8.9258,Shoo-Minda-Nye,LANGUAGE,LANGUAGE
SM277996,35.6207,31.8555,Shooneh Janoobiyyeh,ADM2,DISTRICT
SM244434,88.4344,52.3261,Shor,LANGUAGE,LANGUAGE
SM8073,90,53.3,Shor,ETHNICITY,ETHNICITY
SM8073,90,53.3,Shor,ETHNICITY,ETHNICITY
SM8073,88,52.75,Shor,ETHNICITY,ETHNICITY
SM8073,88,52.75,Shor,ETHNICITY,ETHNICITY
SM260051,66.0406,30.1684,Shorabak,ADM2,DISTRICT
SM279913,71,51.6667,Shortandinskiy,ADM2,DISTRICT
SM260248,66.8512,37.2565,Shortepa,ADM2,DISTRICT
SM300578,-115.891,47.3517,Shoshone,ADM2,DISTRICT
SM244435,-116,41,Shoshoni,LANGUAGE,LANGUAGE
SM257573,-113.18,42.88,Shoshoni,ETHNICITY,ETHNICITY
SM257573,-115.01,39.88,Shoshoni,ETHNICITY,ETHNICITY
SM299721,33.4878,51.8707,Shostkins'ka,ADM2,DISTRICT
SM13149,35.5608,30.5314,Shoubak,ADM2,DISTRICT
SM292719,40.1667,45,Shovgenovskiy rayon,ADM2,DISTRICT
SM303531,60.25,41.6667,Shovot,ADM2,DISTRICT
SM12020,44.8335,39.1501,Showt,ADM2,DISTRICT
SM13088,134.2524,34.4977,Shozu-gun,ADM2,DISTRICT
SM299429,31.3938,49.0135,Shpolians'kyi,ADM2,DISTRICT
SM277333,82.0047,27.5075,Shravasti,ADM2,DISTRICT
SM274685,-2.75,52.6667,Shropshire,ADM1:ADM2,DISTRICT
SM13256,74.25,43.6667,Shu Audany (excl. Shu City),ADM2,DISTRICT
SM13257,73.7,43.6,Shu Qalasy,ADM2,DISTRICT
SM305087,44.2241,15.3856,Shu'aub,ADM2,DISTRICT
SM244440,26.0238,-21.52,Shua,LANGUAGE,LANGUAGE
SM7379,25.3321,-20.5502,Shua,ETHNICITY,ETHNICITY
SM244438,5.066,44.1692,Shuadit,LANGUAGE,LANGUAGE
SM244444,111.9165,27.457,Shuangfeng,DIALECT,LANGUAGE
SM269149,132.6455,46.5227,Shuangyashan,ADM2,DISTRICT
SM244443,-78.1892,-3.4514,Shuar,LANGUAGE,LANGUAGE
SM257576,-76.18,-2.52,Shuar,ETHNICITY,ETHNICITY
SM257576,-76.18,-2.52,Shuar,ETHNICITY,ETHNICITY
SM257576,-76.18,-2.52,Shuar,ETHNICITY,ETHNICITY
SM257576,-77.5,-3.5,Shuar,ETHNICITY,ETHNICITY
SM257576,-77.5,-3.5,Shuar,ETHNICITY,ETHNICITY
SM257576,-77.5,-3.5,Shuar,ETHNICITY,ETHNICITY
SM257576,-78,-3,Shuar,ETHNICITY,ETHNICITY
SM257576,-78,-3,Shuar,ETHNICITY,ETHNICITY
SM257576,-78,-3,Shuar,ETHNICITY,ETHNICITY
SM13258,78.25,47.8333,Shubartau Audany,ADM2,DISTRICT
SM244445,31.0681,-2.9766,Shubi,LANGUAGE,LANGUAGE
SM250680,31.03,-3.2,Shubi,ETHNICITY,ETHNICITY
SM273716,31.2981,30.1427,Shubra al-Khaymah 2,ADM2,DISTRICT
SM279914,70.1667,52.9167,Shuchinskiy,ADM2,DISTRICT
SM257577,-37.01,-9.52,Shucuru,ETHNICITY,ETHNICITY
SM244447,71.7925,37.5018,Shughni,LANGUAGE,LANGUAGE
SM305947,72.1675,37.7,Shughnon,ADM2,DISTRICT
SM9590,71.1638,37.1043,Shuhada,ADM2,DISTRICT
SM303501,58.8917,42.7105,Shumanay,ADM2,DISTRICT
SM244457,70.5696,34.4756,Shumashti,LANGUAGE,LANGUAGE
SM244457,70.5696,34.4756,Shumashti,LANGUAGE,LANGUAGE
SM244457,70.7829,34.7301,Shumashti,LANGUAGE,LANGUAGE
SM244457,70.7829,34.7301,Shumashti,LANGUAGE,LANGUAGE
SM244458,78.3865,31.6862,Shumcho,LANGUAGE,LANGUAGE
SM262379,26.9833,43.2667,Shumen,ADM2,DISTRICT
SM3211,27,43.3333,Shumen,ADM1,DISTRICT
SM17084,46.5,55.5,Shumerlinskiy Rayon,ADM2,DISTRICT
SM293483,63.25,55.25,Shumikhinskiy rayon,ADM2,DISTRICT
SM262672,29.5,55.3333,Shumilina,ADM2,DISTRICT
SM299738,26.1177,50.1182,Shums'kyi,ADM2,DISTRICT
SM257579,74.78,37.32,Shunghanis,ETHNICITY,ETHNICITY
SM269317,112.5413,39.5927,Shuozhou,ADM2,DISTRICT
SM276889,74.8187,33.7307,Shupiyan,ADM2,DISTRICT
SM305961,70,37.8,Shurobod,ADM2,DISTRICT
SM273732,27.4884,24.125,Shurtah al-Dakhlah,ADM2,DISTRICT
SM273733,27.1897,27.2586,Shurtah al-Farafirah,ADM2,DISTRICT
SM305227,30.1667,-19.75,Shurugwi,ADM2,DISTRICT
SM295037,64,65.3333,Shuryshkarskiy rayon,ADM2,DISTRICT
SM277534,48.2167,32.0333,Shush,ADM2,DISTRICT
SM293453,91.5,53.4167,Shushenskiy rayon,ADM2,DISTRICT
SM273564,-76.4166,-0.3106,Shushufindi,ADM2,DISTRICT
SM244464,-119.667,50.8734,Shuswap,LANGUAGE,LANGUAGE
SM257581,-124.34,51.1,Shuswap,ETHNICITY,ETHNICITY
SM257581,-124.34,51.1,Shuswap,ETHNICITY,ETHNICITY
SM257581,-124.34,51.1,Shuswap,ETHNICITY,ETHNICITY
SM257581,-124.34,51.1,Shuswap,ETHNICITY,ETHNICITY
SM257581,-120,51,Shuswap,ETHNICITY,ETHNICITY
SM257581,-120,51,Shuswap,ETHNICITY,ETHNICITY
SM257581,-120,51,Shuswap,ETHNICITY,ETHNICITY
SM257581,-120,51,Shuswap,ETHNICITY,ETHNICITY
SM257581,-119.53,53.41,Shuswap,ETHNICITY,ETHNICITY
SM257581,-119.53,53.41,Shuswap,ETHNICITY,ETHNICITY
SM257581,-119.53,53.41,Shuswap,ETHNICITY,ETHNICITY
SM257581,-119.53,53.41,Shuswap,ETHNICITY,ETHNICITY
SM257581,-120,51.3,Shuswap,ETHNICITY,ETHNICITY
SM257581,-120,51.3,Shuswap,ETHNICITY,ETHNICITY
SM257581,-120,51.3,Shuswap,ETHNICITY,ETHNICITY
SM257581,-120,51.3,Shuswap,ETHNICITY,ETHNICITY
SM9591,69.3027,35.294,Shutul,ADM2,DISTRICT
SM244470,14,12.25,Shuwa-Zamani,LANGUAGE,LANGUAGE
SM292937,41.3667,56.85,Shuya,ADM2,DISTRICT
SM292938,41.5,56.8333,Shuyskiy rayon,ADM2,DISTRICT
SM244471,30.3533,11.2708,Shwai,LANGUAGE,LANGUAGE
SM260163,69.3703,33.4521,Shwak,ADM2,DISTRICT
SM244472,97.7972,23.6729,Shwe Palaung,LANGUAGE,LANGUAGE
SM283476,95.4482,23.0071,Shwebo,ADM2,DISTRICT
SM279873,69.5833,42.3167,Shymkent,ADM1:ADM2,DISTRICT
SM299625,30.191,47.3841,Shyria‹vs'kyi,ADM2,DISTRICT
SM299917,33.2651,47.6846,Shyrokivs'kyi,ADM2,DISTRICT
SM299655,34.0081,49.8774,Shyshats'kyi,ADM2,DISTRICT
SM278137,133.0677,34.9483,Shōbara,ADM2,DISTRICT
SM279611,138.53,35.6278,Shōwa,ADM2,DISTRICT
SM279581,131.825,34.1514,Shūnan,ADM2,DISTRICT
SM297317,99.8732,7.6926,Si Banphot,ADM2,DISTRICT
SM297264,102.2253,16.9978,Si Bun Ruang,ADM2,DISTRICT
SM297273,102.5031,17.9357,Si Chiang Mai,ADM2,DISTRICT
SM296989,102.1308,16.7626,Si Chomphu,ADM2,DISTRICT
SM304283,104.2733,22.6716,Si Ma Cai,ADM2,DISTRICT
SM297408,101.425,13.8767,Si Mahosot,ADM2,DISTRICT
SM297700,105.3483,15.563,Si Muang Mai,ADM2,DISTRICT
SM297604,99.9767,17.3928,Si Nakhon,ADM2,DISTRICT
SM297616,100.1505,14.6381,Si Prachan,ADM2,DISTRICT
SM296916,101.0401,13.1298,Si Racha,ADM2,DISTRICT
SM297701,105.4284,15.1125,Si Rin Ton,ADM2,DISTRICT
SM3212,104.3333,14.8333,Si Sa Ket,ADM1,DISTRICT
SM297257,101.5126,6.1936,Si Sakhon,ADM2,DISTRICT
SM297605,99.7467,17.164,Si Sam Rong,ADM2,DISTRICT
SM297606,99.7059,17.5993,Si Satchanalai,ADM2,DISTRICT
SM296963,99.1299,14.6725,Si Sawat,ADM2,DISTRICT
SM297473,103.5143,16.0195,Si Somdet,ADM2,DISTRICT
SM297101,104.245,17.6299,Si Songkhram,ADM2,DISTRICT
SM297722,103.2308,17.0339,Si That,ADM2,DISTRICT
SM297347,101.1477,15.4425,Si Thep,ADM2,DISTRICT
SM297314,103.7798,18.1553,Si Wilai,ADM2,DISTRICT
SM11032,33.3901,34.9531,Sia,ADM2,DISTRICT
SM270831,-73.2345,5.469,Siachoque,ADM2,DISTRICT
SM276556,102.0136,0.9745,Siak,ADM2,DISTRICT
SM375459,74.5682,32.4433,Sialkot,ADM2:ADM3,DISTRICT
SM244474,147.574,-6.0769,Sialum,LANGUAGE,LANGUAGE
SM244476,-4.9214,10.998,Siamou,LANGUAGE,LANGUAGE
SM244479,145.247,-6.1319,Siane,LANGUAGE,LANGUAGE
SM257583,145.31,-6.5,Siane,ETHNICITY,ETHNICITY
SM257583,145.31,-6.5,Siane,ETHNICITY,ETHNICITY
SM257583,145,-6,Siane,ETHNICITY,ETHNICITY
SM257583,145,-6,Siane,ETHNICITY,ETHNICITY
SM244477,114.693,-0.384,Siang,LANGUAGE,LANGUAGE
SM11686,94.9924,28.2121,Siang,ADM2,DISTRICT
SM244480,152.892,-4.6206,Siar-Lak,LANGUAGE,LANGUAGE
SM276622,125.3751,2.1173,Siau Tagulandang Biaro,ADM2,DISTRICT
SM14151,23.3167,55.9333,Siauliai,ADM2,DISTRICT
SM14152,23.3167,55.9333,Siauliu rajonas (excl. Siauliai city),ADM2,DISTRICT
SM18174,28.5504,-16.2674,Siavonga District (2012-),ADM2,DISTRICT
SM244485,141.42,-4.0531,Siawi,LANGUAGE,LANGUAGE
SM3214,34.302,0.105,Siaya,ADM1:ADM2,DISTRICT
SM304951,45.4023,13.8091,Sibah,ADM2,DISTRICT
SM270981,-77.5241,21.2357,Sibanicú,ADM2,DISTRICT
SM270052,-74.2829,4.4491,Sibaté,ADM2,DISTRICT
SM244488,155.412,-6.4315,Sibe,LANGUAGE,LANGUAGE
SM257584,46.75,24.53,Sibea,ETHNICITY,ETHNICITY
SM257584,43.97,21.76,Sibea,ETHNICITY,ETHNICITY
SM244489,72.5642,57.4921,Siberian Tatar,LANGUAGE,LANGUAGE
SM8099,67.46,58.41,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,67.46,58.41,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,67.46,58.41,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,67.46,58.41,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,66.52,56.89,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,66.52,56.89,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,66.52,56.89,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,66.52,56.89,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,68.72,58.1,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,68.72,58.1,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,68.72,58.1,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,68.72,58.1,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,69.01,57.93,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,69.01,57.93,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,69.01,57.93,Siberian Tatars,ETHNICITY,ETHNICITY
SM8099,69.01,57.93,Siberian Tatars,ETHNICITY,ETHNICITY
SM5122,67.9161,29.5567,Sibi (2018-),ADM2:ADM3,DISTRICT
SM275225,-91.6414,14.9894,Sibilia,ADM2,DISTRICT
SM305570,17.9075,45.1892,Sibinj,ADM2,DISTRICT
SM16355,13.3464,-3.6513,Sibiti,ADM2,DISTRICT
SM291755,24.1353,45.7938,Sibiu,ADM2,DISTRICT
SM3216,24.1438,45.7843,Sibiu,ADM1,DISTRICT
SM301408,-94.2322,44.5795,Sibley,ADM2,DISTRICT
SM276694,98.8,1.7333,Sibolga,ADM2,DISTRICT
SM289835,23.3223,45.9409,Sibot,ADM2,DISTRICT
SM11687,94.6555,26.9164,Sibsagar (2015-),ADM2,DISTRICT
SM284371,112.25,2.4167,Sibu,ADM2,DISTRICT
SM244500,111.763,2.2676,Sibu Melanau,LANGUAGE,LANGUAGE
SM270402,-76.9024,1.2296,Sibundoy,ADM2,DISTRICT
SM10251,19.0833,5.7333,Sibut,ADM2,DISTRICT
SM290134,23.8962,46.9313,Sic,ADM2,DISTRICT
SM244502,13.5135,37.5315,Sicana,LANGUAGE,LANGUAGE
SM11033,33.3708,35.252,Sichari,ADM2,DISTRICT
SM244504,32.6608,-8.5118,Sichela,DIALECT,LANGUAGE
SM290049,21.8291,44.7062,Sichevita,ADM2,DISTRICT
SM297219,99.8132,8.9498,Sichon,ADM2,DISTRICT
SM3217,102.6667,30.5,Sichuan (1997-),ADM1,DISTRICT
SM244503,102.121,28.1947,Sichuan Yi,LANGUAGE,LANGUAGE
SM244506,14.0414,37.5735,Sicilian,LANGUAGE,LANGUAGE
SM257585,14.1,37.66,Sicilian,ETHNICITY,ETHNICITY
SM257585,14.1,37.66,Sicilian,ETHNICITY,ETHNICITY
SM257585,14.1,37.66,Sicilian,ETHNICITY,ETHNICITY
SM257585,14.1,37.66,Sicilian,ETHNICITY,ETHNICITY
SM257585,13,38,Sicilian,ETHNICITY,ETHNICITY
SM257585,13,38,Sicilian,ETHNICITY,ETHNICITY
SM257585,13,38,Sicilian,ETHNICITY,ETHNICITY
SM257585,13,38,Sicilian,ETHNICITY,ETHNICITY
SM257585,15.267,37.067,Sicilian,ETHNICITY,ETHNICITY
SM257585,15.267,37.067,Sicilian,ETHNICITY,ETHNICITY
SM257585,15.267,37.067,Sicilian,ETHNICITY,ETHNICITY
SM257585,15.267,37.067,Sicilian,ETHNICITY,ETHNICITY
SM257585,12.515,38.019,Sicilian,ETHNICITY,ETHNICITY
SM257585,12.515,38.019,Sicilian,ETHNICITY,ETHNICITY
SM257585,12.515,38.019,Sicilian,ETHNICITY,ETHNICITY
SM257585,12.515,38.019,Sicilian,ETHNICITY,ETHNICITY
SM3218,14.25,37.75,Sicily,ADM1,DISTRICT
SM244508,14.8413,37.534,Sicula,LANGUAGE,LANGUAGE
SM290537,21.7514,46.4572,Sicula,ADM2,DISTRICT
SM274317,38.448,6.718,Sidama,ADM2,DISTRICT
SM257586,38.24,6.65,Sidama,ETHNICITY,ETHNICITY
SM257586,38.24,6.65,Sidama,ETHNICITY,ETHNICITY
SM257586,38,7,Sidama,ETHNICITY,ETHNICITY
SM257586,38,7,Sidama,ETHNICITY,ETHNICITY
SM244510,38.3729,6.7418,Sidamo,LANGUAGE,LANGUAGE
SM277335,83.0147,27.258,Siddharth Nagar (1997-),ADM2,DISTRICT
SM11688,78.8508,18.1015,Siddipet,ADM2,DISTRICT
SM276579,119.9667,-3.85,Sidenreng Rappang,ADM2,DISTRICT
SM266320,-49.5364,-28.563,Siderópolis,ADM2,DISTRICT
SM244514,31.917,36.764,Sidetic,LANGUAGE,LANGUAGE
SM277007,82,24.25,Sidhi (2008-),ADM2,DISTRICT
SM298081,8.8143,34.6856,Sidi Aich,ADM2,DISTRICT
SM297964,9.1342,34.8053,Sidi Ali Ben Aoun,ADM2,DISTRICT
SM457667,3,31,Sidi Ali Benyoub District,ADM2,DISTRICT
SM457666,0.4167,36.1,Sidi Ali District,ADM2,DISTRICT
SM297880,10.892,35.2899,Sidi Alouane,ADM2,DISTRICT
SM3219,-0.5,34.8333,Sidi Bel Abbès,ADM1,DISTRICT
SM457350,-0.5833,34.75,Sidi Bel Abbès District,ADM2,DISTRICT
SM4430,-8.4269,32.649,Sidi Bennour,ADM2,DISTRICT
SM297988,10.4133,35.9691,Sidi Bou Ali,ADM2,DISTRICT
SM3220,9.5,34.8333,Sidi Bou Zid,ADM1:ADMX,DISTRICT
SM297954,9.599,35.0924,Sidi Bouzid Est,ADM2,DISTRICT
SM297955,9.4107,35.0058,Sidi Bouzid Ouest,ADM2,DISTRICT
SM298026,10.1752,36.786,Sidi El Béchir,ADM2,DISTRICT
SM297989,10.3088,35.7261,Sidi El Heni,ADM2,DISTRICT
SM298027,10.1023,36.7421,Sidi Hassine,ADM2,DISTRICT
SM4443,-10.1711,29.3772,Sidi Ifnit,ADM2,DISTRICT
SM4456,-5.75,34.5833,Sidi Kacem (2009-),ADM2,DISTRICT
SM297905,10.5267,33.5472,Sidi Makhlouf,ADM2,DISTRICT
SM273831,30.769,31.3049,Sidi Salim,ADM2,DISTRICT
SM4453,-5.9255,34.2622,Sidi Slimane,ADM2,DISTRICT
SM297861,10.0493,36.9144,Sidi Thabet,ADM2,DISTRICT
SM276364,112.7,-7.45,Sidoarjo,ADM2,DISTRICT
SM263089,-54.99,-21.0204,Sidrolândia,ADM2,DISTRICT
SM244517,169.166,-18.8181,Sie,LANGUAGE,LANGUAGE
SM257590,169.16,-18.85,Sie,ETHNICITY,ETHNICITY
SM288974,22.3041,52.1919,Siedlce,ADM2,DISTRICT
SM288972,22.2758,52.1618,Siedlce (City),ADM2,DISTRICT
SM280438,105.8181,13.3042,Siem Bouk,ADM2,DISTRICT
SM280439,106.4244,14.1761,Siem Pang,ADM2,DISTRICT
SM280435,103.85,13.31,Siem Reab,ADM2,DISTRICT
SM288735,19.0232,50.3123,Siemianowice Śląskie,ADM2,DISTRICT
SM288682,22.8864,52.4884,Siemiatycze,ADM2,DISTRICT
SM3221,104,13.5,Siemréab,ADM1,DISTRICT
SM277908,11.4,43.2167,Siena,ADM2,DISTRICT
SM288875,18.6031,51.603,Sieradz,ADM2,DISTRICT
SM288975,19.7122,52.8335,Sierpc,ADM2,DISTRICT
SM301854,-107.1925,33.1305,Sierra,ADM2,DISTRICT
SM303203,-120.516,39.5804,Sierra,ADM2,DISTRICT
SM16215,-66.8173,18.0903,Sierra Alta Barrio,ADM2,DISTRICT
SM16216,-66.7977,18.0776,Sierra Baja Barrio,ADM2,DISTRICT
SM244519,-13.235,8.483,Sierra Leone Sign Language,LANGUAGE,LANGUAGE
SM283304,-103.6558,27.5845,Sierra Mojada,ADM2,DISTRICT
SM244520,-96.9,18.45,Sierra Negra Nahuatl,LANGUAGE,LANGUAGE
SM270982,-77.469,21.5517,Sierra de Cubitas,ADM2,DISTRICT
SM244522,-96.6191,17.4219,Sierra de Juárez Zapotec,LANGUAGE,LANGUAGE
SM268985,7.5157,46.2705,Sierre,ADM2,DISTRICT
SM295441,160.8895,-9.0297,Siesie,ADM2,DISTRICT
SM292528,24.6243,47.0124,Sieu,ADM2,DISTRICT
SM292529,24.3765,47.0898,Sieu-magherus,ADM2,DISTRICT
SM292530,24.2686,47.1329,Sieu-odorhei,ADM2,DISTRICT
SM292531,24.6794,46.9988,Sieut,ADM2,DISTRICT
SM299569,38.5643,48.9014,Sieverodonets'ka,ADM2,DISTRICT
SM303854,-61.5315,7.2481,Sifontes,ADM2,DISTRICT
SM10195,-100.6628,49.6656,Sifton (2015-),ADM2,DISTRICT
SM295392,159.8311,-8.4869,Sigana,ADM2,DISTRICT
SM304686,-178.155,-14.2744,Sigave,ADM2,DISTRICT
SM273614,-78.8914,-0.492,Sigchos,ADM2,DISTRICT
SM286328,9.6367,60.0459,Sigdal,ADM2,DISTRICT
SM265145,-41.7846,-4.7989,Sigefredo Pacheco,ADM2,DISTRICT
SM291037,23.8868,47.9191,Sighetu Marmatiei,ADM2,DISTRICT
SM291193,24.7793,46.2282,Sighisoara,ADM2,DISTRICT
SM11342,45.9531,41.6262,Sighnaghi,ADM2,DISTRICT
SM11343,45.85,41.5667,Sighnaghis Munitsip'alit'et'I (excl. Signagi),ADM2,DISTRICT
SM244524,13.1214,-0.9561,Sighu,LANGUAGE,LANGUAGE
SM276604,119.9669,-1.385,Sigi,ADM2,DISTRICT
SM273422,-78.7833,-3.0167,Sigsig,ADM2,DISTRICT
SM296475,17.9395,59.6396,Sigtuna,ADM2,DISTRICT
SM275849,-87.8455,14.6344,Siguatepeque,ADM2,DISTRICT
SM274987,-9.5,11.6667,Siguiri,ADM1:ADM2,DISTRICT
SM13815,24.8447,57.1603,Sigulda,ADM2,DISTRICT
SM4016,24.8639,57.1297,Siguldas Novads,ADM1,DISTRICT
SM13816,24.9198,57.1433,Siguldas pagasts,ADM2,DISTRICT
SM296718,31.6454,-27.0582,Sigwe,ADM2,DISTRICT
SM299255,37.0619,-3.0707,Siha,ADM2,DISTRICT
SM244478,113.843,2.6615,Sihan,LANGUAGE,LANGUAGE
SM244531,145.574,-5.2803,Sihan (Gum),LANGUAGE,LANGUAGE
SM250682,48.39,-18.09,Sihanaka,ETHNICITY,ETHNICITY
SM280717,126.7888,37.3907,Siheung,ADM2,DISTRICT
SM292373,27.1563,45.494,Sihlea,ADM2,DISTRICT
SM286800,-77.5,-8.5,Sihuas,ADM2,DISTRICT
SM244533,-77.5319,-8.4935,Sihuas Ancash Quechua,LANGUAGE,LANGUAGE
SM346789,25.8485,64.2341,Siikalatva,ADM2:ADM3,DISTRICT
SM3222,41.9458,37.9615,Siirt (1990-),ADM1,DISTRICT
SM14976,151.8246,7.295,Siis Municipality,ADM2,DISTRICT
SM298028,10.1581,36.7956,Sijoumi,ADM2,DISTRICT
SM289034,126.3656,41.0231,Sijung,ADM2,DISTRICT
SM276647,101.0679,-0.6487,Sijunjung,ADM2,DISTRICT
SM284401,100.7978,5.8389,Sik,ADM2,DISTRICT
SM244544,122.38,-8.673,Sika,LANGUAGE,LANGUAGE
SM244543,162.7295,-8.3782,Sikaiana,LANGUAGE,LANGUAGE
SM295442,162.9415,-8.4112,Sikaiana,ADM2,DISTRICT
SM257591,122.14,-8.9,Sikanese,ETHNICITY,ETHNICITY
SM297679,99.3392,7.5712,Sikao,ADM2,DISTRICT
SM277186,75.1667,27.5833,Sikar,ADM2,DISTRICT
SM244545,138.287,-2.8029,Sikaritai,LANGUAGE,LANGUAGE
SM283383,-5.9401,11.5218,Sikasso,ADM2,DISTRICT
SM3223,-6.2,11.3,Sikasso,ADM1,DISTRICT
SM297133,101.6067,14.9428,Sikhiu,ADM2,DISTRICT
SM297644,103.7831,14.9475,Sikhoraphum,ADM2,DISTRICT
SM280796,102.4845,18.007,Sikhottabong,ADM2,DISTRICT
SM244549,-57.9221,3.0129,Sikiana,LANGUAGE,LANGUAGE
SM305572,18.4655,45.1079,Sikirevci,ADM2,DISTRICT
SM276498,122.3667,-8.6667,Sikka,ADM2,DISTRICT
SM3224,88.5,27.75,Sikkim,ADM0:ADM1,DISTRICT
SM244550,88.5915,27.858,Sikkimese,LANGUAGE,LANGUAGE
SM276214,18.3105,45.8498,Siklósi,ADM2,DISTRICT
SM257598,-9.05,5.53,Sikon,ETHNICITY,ETHNICITY
SM299189,32.7667,-5.6333,Sikonge,ADM2,DISTRICT
SM18175,22.1776,-15.2367,Sikongo District,ADM2,DISTRICT
SM244553,-113.109,49.0669,Siksika,LANGUAGE,LANGUAGE
SM244554,95.9636,2.6911,Sikule,LANGUAGE,LANGUAGE
SM244556,102.134,21.6888,Sila,LANGUAGE,LANGUAGE
SM3225,21.5,12.25,Sila,ADM1,DISTRICT
SM14855,-98.1335,17.5284,Silacayoapam,ADM2,DISTRICT
SM244559,-98.1163,17.5541,Silacayoapan Mixtec,LANGUAGE,LANGUAGE
SM13817,26.9069,56.3546,Silajanu pagasts,ADM2,DISTRICT
SM285225,4.8194,12.9924,Silame,ADM2,DISTRICT
SM281564,-101.4499,20.9372,Silao,ADM2,DISTRICT
SM275756,-86.5152,14.7621,Silca,ADM2,DISTRICT
SM12730,-77.4592,18.1915,Silent Hill,ADM2,DISTRICT
SM244561,16.9491,50.9656,Silesian,LANGUAGE,LANGUAGE
SM13259,72.75,52.1667,Sileti Audany,ADM2,DISTRICT
SM3226,9.3333,36,Siliana,ADM1,DISTRICT
SM297966,9.2979,36.0586,Siliana Nord,ADM2,DISTRICT
SM297967,9.399,35.9826,Siliana Sud,ADM2,DISTRICT
SM298759,33.9407,36.3887,Silifke,ADM2,DISTRICT
SM11034,32.8859,34.8337,Silikou,ADM2,DISTRICT
SM244566,139.003,-4.5254,Silimo,LANGUAGE,LANGUAGE
SM290538,21.9349,46.3361,Silindia,ADM2,DISTRICT
SM244564,142.397,-3.6084,Siliput,LANGUAGE,LANGUAGE
SM292691,27.8259,45.3526,Silistea,ADM2,DISTRICT
SM290410,23.4819,44.0449,Silistea Crucii,ADM2,DISTRICT
SM291931,25.0139,44.3803,Silistea-gumesti,ADM2,DISTRICT
SM262386,27.2,44.0333,Silistra,ADM2,DISTRICT
SM3227,27.05,43.9333,Silistra,ADM1,DISTRICT
SM292532,24.2916,46.7813,Silivasu De Campie,ADM2,DISTRICT
SM18103,28.1989,41.1812,Silivri,ADM2,DISTRICT
SM286210,9.7337,59.2825,Siljan,ADM2,DISTRICT
SM271694,9.5467,56.175,Silkeborg (2007-),ADM2,DISTRICT
SM274221,27.7642,59.3956,Sillamäe,ADM2,DISTRICT
SM13818,27.0528,56.4115,Silmalas pagasts,ADM2,DISTRICT
SM12731,-77.7106,18.1633,Siloah,ADM2,DISTRICT
SM244571,145.586,-5.123,Silopi,LANGUAGE,LANGUAGE
SM298920,42.4717,37.2498,Silopi,ADM2,DISTRICT
SM244575,38.1449,7.77,Silt'e,LANGUAGE,LANGUAGE
SM283191,-92.4314,15.5538,Siltepec,ADM2,DISTRICT
SM274319,38.2687,7.8322,Silti,ADM2,DISTRICT
SM274319,38.2687,7.8322,Silti,ADM2,DISTRICT
SM274319,38.2329,7.792,Silti,ADM2,DISTRICT
SM274319,38.2329,7.792,Silti,ADM2,DISTRICT
SM13819,26.6819,56.5062,Silukalna pagasts,ADM2,DISTRICT
SM14153,21.4833,55.35,Silute (2000-),ADM2,DISTRICT
SM303575,-68.3501,10.7571,Silva,ADM2,DISTRICT
SM265326,-42.35,-22.5455,Silva Jardim,ADM2,DISTRICT
SM298324,41.0126,38.1413,Silvan,ADM2,DISTRICT
SM270053,-74.3843,4.4251,Silvania,ADM2,DISTRICT
SM10025,-48.5614,-16.5529,Silvania (1997-),ADM2,DISTRICT
SM267181,-48.2234,-11.1134,Silvanópolis,ADM2,DISTRICT
SM265946,-53.5461,-29.6364,Silveira Martins,ADM2,DISTRICT
SM266967,-44.8478,-22.7365,Silveiras,ADM2,DISTRICT
SM263995,-43.1983,-21.1445,Silveirânia,ADM2,DISTRICT
SM324021,-113.9901,53.04,Silver Beach,ADM2:ADM3,DISTRICT
SM301663,-112.6567,45.9024,Silver Bow,ADM2,DISTRICT
SM324084,-114.6605,53.6344,Silver Sands,ADM2:ADM3,DISTRICT
SM289505,-8.3347,37.2034,Silves,ADM2,DISTRICT
SM267352,-58.6275,-2.7685,Silves,ADM2,DISTRICT
SM269875,-76.3481,2.6588,Silvia,ADM2,DISTRICT
SM263996,-45.8158,-22.053,Silvianópolis,ADM2,DISTRICT
SM261055,-64.3333,-28.1667,Silípica,ADM2,DISTRICT
SM244578,22.6455,-15.7434,Simaa,LANGUAGE,LANGUAGE
SM257606,22.21,-16.13,Simaa,ETHNICITY,ETHNICITY
SM270515,-73.6693,6.5241,Simacota,ADM2,DISTRICT
SM276695,99,2.9,Simalungun,ADM2,DISTRICT
SM290540,21.4515,46.424,Simand,ADM2,DISTRICT
SM299094,36.6,-3.8667,Simanjiro,ADM2,DISTRICT
SM298700,28.9646,39.0982,Simav,ADM2,DISTRICT
SM244584,11.1783,-1.2811,Simba,LANGUAGE,LANGUAGE
SM244589,151.957,-4.8862,Simbali,LANGUAGE,LANGUAGE
SM244585,145.603,-7.0226,Simbari,LANGUAGE,LANGUAGE
SM244586,156.541,-8.2695,Simbo,LANGUAGE,LANGUAGE
SM295327,156.5382,-8.2769,Simbo,ADM2,DISTRICT
SM257607,157,-8,Simboe,ETHNICITY,ETHNICITY
SM268882,-79.833,44.4168,Simcoe,ADM2,DISTRICT
SM276915,84.6,22.6,Simdega,ADM2,DISTRICT
SM244593,155.423,-6.2302,Simeku,LANGUAGE,LANGUAGE
SM305776,42.7117,36.8982,Simele,ADM2,DISTRICT
SM305776,42.7117,36.8982,Simele,ADM2,DISTRICT
SM305776,42.7215,36.8994,Simele,ADM2,DISTRICT
SM305776,42.7215,36.8994,Simele,ADM2,DISTRICT
SM262524,25.8833,42.05,Simeonovgrad,ADM2,DISTRICT
SM290787,23.0075,45.853,Simeria,ADM2,DISTRICT
SM244592,96.2757,2.5029,Simeulue,LANGUAGE,LANGUAGE
SM276294,96.0833,2.6167,Simeulue,ADM2,DISTRICT
SM299891,34.1091,44.948,Simferopol's'ka,ADM2,DISTRICT
SM299892,34.1023,44.9569,Simferopol's'kyi,ADM2,DISTRICT
SM291102,22.7423,44.6274,Simian,ADM2,DISTRICT
SM292468,22.0588,47.4648,Simian,ADM2,DISTRICT
SM270054,-73.8694,5.5042,Simijaca,ADM2,DISTRICT
SM291842,26.3913,47.7093,Siminicea,ADM2,DISTRICT
SM262291,23.1667,41.8833,Simitli,ADM2,DISTRICT
SM270704,-73.9792,7.8163,Simití,ADM2,DISTRICT
SM3228,34.25,-3,Simiyu,ADM1,DISTRICT
SM291635,22.7944,47.2302,Simleu Silvaniei,ADM2,DISTRICT
SM290411,23.7886,44.4128,Simnicu De Sus,ADM2,DISTRICT
SM261069,-65.3561,-27.2636,Simoca,ADM2,DISTRICT
SM14856,-92.6715,17.1474,Simojovel (1999-),ADM2,DISTRICT
SM268181,-46.5923,-14.4382,Simolândia,ADM2,DISTRICT
SM273447,-79.4014,-2.0362,Simon Bolivar,ADM2,DISTRICT
SM290720,25.1133,46.3547,Simonesti,ADM2,DISTRICT
SM263998,-41.9873,-19.9989,Simonésia,ADM2,DISTRICT
SM257608,136.75,-4.48,Simore,ETHNICITY,ETHNICITY
SM12021,52.8236,36.5791,Simorgh County,ADM2,DISTRICT
SM11035,32.5017,34.9459,Simou,ADM2,DISTRICT
SM285884,5.9841,50.829,Simpelveld,ADM2,DISTRICT
SM265147,-41.9248,-7.8617,Simplício Mendes,ADM2,DISTRICT
SM301486,-89.9195,31.9132,Simpson,ADM2,DISTRICT
SM300997,-86.5823,36.7419,Simpson,ADM2,DISTRICT
SM296444,14.2378,55.5862,Simrishamn,ADM2,DISTRICT
SM244596,93.3265,24.5444,Simte,LANGUAGE,LANGUAGE
SM267143,-37.7714,-10.7116,Simão Dias,ADM2,DISTRICT
SM263997,-43.2903,-21.9663,Simão Pereira,ADM2,DISTRICT
SM303644,-66.7465,10.1779,Simón Bolívar,ADM2,DISTRICT
SM303805,-71.3687,10.2584,Simón Bolívar,ADM2,DISTRICT
SM289688,-56.3496,-25.0332,Simón Bolívar,ADM2,DISTRICT
SM303609,-69.1633,9.7813,Simón Planas,ADM2,DISTRICT
SM303749,-71.8503,8.3508,Simón Rodriguez,ADM2,DISTRICT
SM303707,-64.1667,8.75,Simón Rodríguez,ADM2,DISTRICT
SM265146,-40.7274,-7.7382,Simões,ADM2,DISTRICT
SM267672,-38.3823,-12.7783,Simões Filho,ADM2,DISTRICT
SM289147,126.5,38.5,Sin'gye,ADM2,DISTRICT
SM288621,145.0167,-6.0833,Sina Sina-Yonggomugl,ADM2,DISTRICT
SM257610,-66.09,-10.98,Sinabo,ETHNICITY,ETHNICITY
SM291568,25.5494,45.3484,Sinaia,ADM2,DISTRICT
SM3229,144.7538,13.4633,Sinajana,ADM1,DISTRICT
SM282541,-108.0908,25.941,Sinaloa,ADM2,DISTRICT
SM3230,-107.5,25,Sinaloa,ADM1,DISTRICT
SM458004,126.0987,34.8311,Sinan,ADM2,DISTRICT
SM283003,-89.201,21.2762,Sinanché,ADM2,DISTRICT
SM11036,32.9102,35.0109,Sinaoros,ADM2,DISTRICT
SM244604,145.032,-6.0873,Sinasina,LANGUAGE,LANGUAGE
SM244606,145.0255,-5.9547,Sinasina Sign Language,LANGUAGE,LANGUAGE
SM244599,147.861,-9.7979,Sinaugoro,LANGUAGE,LANGUAGE
SM305211,27.4791,-17.2224,Sinazongwe,ADM2,DISTRICT
SM292653,25.1558,45.7589,Sinca Veche,ADM2,DISTRICT
SM291194,24.3764,46.6634,Sincai,ADM2,DISTRICT
SM298896,32.4957,39.9594,Sincan,ADM2,DISTRICT
SM298368,30.2466,38.7539,Sincanlı,ADM2,DISTRICT
SM270541,-75.435,9.3167,Sincelejo,ADM2,DISTRICT
SM289153,125.4972,38.3339,Sinch'ŏn,ADM2,DISTRICT
SM298232,38.6132,38.0337,Sincik,ADM2,DISTRICT
SM270540,-75.0854,9.2552,Sincé,ADM2,DISTRICT
SM3231,68.7667,26.1333,Sind,ADM1,DISTRICT
SM18176,31.7097,-14.2296,Sinda District,ADM2,DISTRICT
SM244609,69,26,Sindhi,LANGUAGE,LANGUAGE
SM257613,67.12,26.43,Sindhi,ETHNICITY,ETHNICITY
SM257613,67.12,26.43,Sindhi,ETHNICITY,ETHNICITY
SM257613,67.12,26.43,Sindhi,ETHNICITY,ETHNICITY
SM257613,67.12,26.43,Sindhi,ETHNICITY,ETHNICITY
SM257613,68,25,Sindhi,ETHNICITY,ETHNICITY
SM257613,68,25,Sindhi,ETHNICITY,ETHNICITY
SM257613,68,25,Sindhi,ETHNICITY,ETHNICITY
SM257613,68,25,Sindhi,ETHNICITY,ETHNICITY
SM257613,69,25.4906,Sindhi,ETHNICITY,ETHNICITY
SM257613,69,25.4906,Sindhi,ETHNICITY,ETHNICITY
SM257613,69,25.4906,Sindhi,ETHNICITY,ETHNICITY
SM257613,69,25.4906,Sindhi,ETHNICITY,ETHNICITY
SM257613,69,26,Sindhi,ETHNICITY,ETHNICITY
SM257613,69,26,Sindhi,ETHNICITY,ETHNICITY
SM257613,69,26,Sindhi,ETHNICITY,ETHNICITY
SM257613,69,26,Sindhi,ETHNICITY,ETHNICITY
SM244607,69.0008,24.5408,Sindhi Bhil,LANGUAGE,LANGUAGE
SM277056,73.7,16.17,Sindhudurg,ADM2,DISTRICT
SM375307,86.0703,27.1851,Sindhuli,ADM2:ADM3,DISTRICT
SM375301,85.7558,27.907,Sindhupalchok,ADM2:ADM3,DISTRICT
SM244614,-97.3036,17.02,Sindihui Mixtec,LANGUAGE,LANGUAGE
SM289193,124.2245,39.8275,Sindo,ADM2,DISTRICT
SM262155,2.3353,10.2156,Sinendé,ADM2,DISTRICT
SM289343,-8.8712,37.9536,Sines,ADM2,DISTRICT
SM292236,23.8395,44.9323,Sinesti,ADM2,DISTRICT
SM290870,27.1875,47.1212,Sinesti,ADM2,DISTRICT
SM280878,101.1091,21.2618,Sing,ADM2,DISTRICT
SM3232,100.3333,14.925,Sing Buri,ADM1,DISTRICT
SM244626,33.8997,-0.2459,Singa,LANGUAGE,LANGUAGE
SM295189,33.7494,13.1518,Singa,ADM2,DISTRICT
SM244621,103.864,1.3307,Singapore Sign Language,LANGUAGE,LANGUAGE
SM3233,-178.1667,-14.2667,Singave,ADM1,DISTRICT
SM297588,100.4881,7.2845,Singha Nakhon,ADM2,DISTRICT
SM3235,34.6667,-5.75,Singida,ADM1,DISTRICT
SM299184,34.9585,-4.6284,Singida Rural,ADM2,DISTRICT
SM299185,34.71,-4.84,Singida Urban,ADM2,DISTRICT
SM276381,109.001,0.9073,Singkawang,ADM2,DISTRICT
SM261460,150.9187,-32.6055,Singleton,ADM2,DISTRICT
SM257629,97.2,21.07,Singpho,ETHNICITY,ETHNICITY
SM257629,97.2,21.07,Singpho,ETHNICITY,ETHNICITY
SM257629,97,26,Singpho,ETHNICITY,ETHNICITY
SM257629,97,26,Singpho,ETHNICITY,ETHNICITY
SM277008,82.4207,24.2167,Singrauli,ADM2,DISTRICT
SM281707,-98.5192,19.9896,Singuilucan,ADM2,DISTRICT
SM290593,25.9536,44.2298,Singureni,ADM2,DISTRICT
SM244634,81,8,Sinhala,LANGUAGE,LANGUAGE
SM259925,81.37,6.76,Sinhalese,ETHNICITY,ETHNICITY
SM259925,81.37,6.76,Sinhalese,ETHNICITY,ETHNICITY
SM259925,81.37,6.76,Sinhalese,ETHNICITY,ETHNICITY
SM259925,80.417,8.333,Sinhalese,ETHNICITY,ETHNICITY
SM259925,80.417,8.333,Sinhalese,ETHNICITY,ETHNICITY
SM259925,80.417,8.333,Sinhalese,ETHNICITY,ETHNICITY
SM259925,80,7,Sinhalese,ETHNICITY,ETHNICITY
SM259925,80,7,Sinhalese,ETHNICITY,ETHNICITY
SM259925,80,7,Sinhalese,ETHNICITY,ETHNICITY
SM289118,127.6667,40.25,Sinhŭng,ADM2,DISTRICT
SM244637,-97.5904,17.2218,Sinicahua Mixtec,LANGUAGE,LANGUAGE
SM232398,105.0817,23.2265,Sinicized Miao,LANGUAGE,LANGUAGE
SM265947,-52.605,-29.4151,Sinimbu,ADM2,DISTRICT
SM244636,-0.7877,13.7206,Sininkere,LANGUAGE,LANGUAGE
SM275999,16.6582,43.7229,Sinj,ADM2,DISTRICT
SM276580,120.15,-5.2167,Sinjai,ADM2,DISTRICT
SM305801,41.8932,36.2955,Sinjar,ADM2,DISTRICT
SM257634,-119.5,48.5,Sinkaitk,ETHNICITY,ETHNICITY
SM257634,-119.5,48.5,Sinkaitk,ETHNICITY,ETHNICITY
SM257634,-119.5,48.5,Sinkaitk,ETHNICITY,ETHNICITY
SM257634,-120,49,Sinkaitk,ETHNICITY,ETHNICITY
SM257634,-120,49,Sinkaitk,ETHNICITY,ETHNICITY
SM257634,-120,49,Sinkaitk,ETHNICITY,ETHNICITY
SM257634,-119.56,48.78,Sinkaitk,ETHNICITY,ETHNICITY
SM257634,-119.56,48.78,Sinkaitk,ETHNICITY,ETHNICITY
SM257634,-119.56,48.78,Sinkaitk,ETHNICITY,ETHNICITY
SM295179,35.976,18.392,Sinkat,ADM2,DISTRICT
SM257635,-122.92,40.21,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-122.92,40.21,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-122.92,40.21,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-122.92,40.21,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-122.92,40.21,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-124,40,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-124,40,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-124,40,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-124,40,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-124,40,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.6,39.73,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.6,39.73,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.6,39.73,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.6,39.73,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.6,39.73,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.96,40.1,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.96,40.1,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.96,40.1,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.96,40.1,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.96,40.1,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.8,40.2,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.8,40.2,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.8,40.2,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.8,40.2,Sinkyone,ETHNICITY,ETHNICITY
SM257635,-123.8,40.2,Sinkyone,ETHNICITY,ETHNICITY
SM3236,-8.7667,5.3333,Sinoe,ADM1,DISTRICT
SM263117,-55.4402,-11.7641,Sinop,ADM2,DISTRICT
SM3237,34.871,41.7711,Sinop,ADM1,DISTRICT
SM289119,128.2658,40.0917,Sinp'o,ADM2,DISTRICT
SM289132,126.7914,38.9931,Sinp'yŏng,ADM2,DISTRICT
SM285950,5.8435,51.6183,Sint Anthonis,ADM2,DISTRICT
SM3238,-62.9667,17.5,Sint Eustatius,ADM1,DISTRICT
SM285948,5.3785,51.6608,Sint-Michielsgestel,ADM2,DISTRICT
SM11037,33.7019,35.1597,Sinta,ADM2,DISTRICT
SM276382,112.0833,-0.0833,Sintang,ADM2,DISTRICT
SM244647,9.4,48.7,Sinte Romani,LANGUAGE,LANGUAGE
SM290541,21.608,46.5168,Sintea Mare,ADM2,DISTRICT
SM292533,24.3166,47.168,Sintereag,ADM2,DISTRICT
SM292469,22.5063,47.1459,Sinteu,ADM2,DISTRICT
SM289262,-9.3837,38.8144,Sintra,ADM2,DISTRICT
SM275733,-89.1212,14.4657,Sinuapa,ADM2,DISTRICT
SM289154,125.7258,38.1486,Sinwŏn,ADM2,DISTRICT
SM289057,126.5539,39.3742,Sinyang,ADM2,DISTRICT
SM244648,22.5278,12.1895,Sinyar,LANGUAGE,LANGUAGE
SM289094,124.4633,40.0906,Sinŭiju,ADM2,DISTRICT
SM244653,147.353,-5.9604,Sio,LANGUAGE,LANGUAGE
SM257637,147,-6,Sio,ETHNICITY,ETHNICITY
SM11589,17.9963,46.8506,Siofoki Jaras,ADM2,DISTRICT
SM18177,23.2221,-16.7536,Sioma District,ADM2,DISTRICT
SM268986,7.3494,46.2334,Sion,ADM2,DISTRICT
SM303898,-64.7462,17.7491,Sion Farm,ADM2,DISTRICT
SM257638,-75.62,1.04,Siona-Secoya,ETHNICITY,ETHNICITY
SM244651,-76.0237,0.3173,Siona-Tetete,LANGUAGE,LANGUAGE
SM301759,-103.7589,42.4877,Sioux,ADM2,DISTRICT
SM302067,-101.0403,46.1127,Sioux,ADM2,DISTRICT
SM300866,-96.1779,43.0826,Sioux,ADM2,DISTRICT
SM275298,-91.6554,15.1924,Sipacapa,ADM2,DISTRICT
SM244658,-91.7041,15.2305,Sipacapense,LANGUAGE,LANGUAGE
SM11529,-91.1477,13.9581,Sipacate,ADM2,DISTRICT
SM277264,91.3433,23.6064,Sipahijala,ADM2,DISTRICT
SM3240,-56,4,Sipaliwini,ADM1,DISTRICT
SM3241,-61.5012,10.1338,Siparia (1992-),ADM1,DISTRICT
SM296689,31.6648,-26.6763,Siphofaneni,ADM2,DISTRICT
SM269238,124.45,43.6142,Siping,ADM2,DISTRICT
SM290872,27.2259,47.4604,Sipote,ADM2,DISTRICT
SM269922,-76.5104,4.6026,Sipí,ADM2,DISTRICT
SM264742,-49.7728,-23.6267,Siqueira Campos,ADM2,DISTRICT
SM3242,123.5667,9.1833,Siquijor,ADM1:ADM2,DISTRICT
SM275427,-90.9417,14.3491,Siquinalá,ADM2,DISTRICT
SM270946,-83.4828,10.1361,Siquirres,ADM2,DISTRICT
SM244668,10.4635,-1.7808,Sira,LANGUAGE,LANGUAGE
SM375325,86.3498,26.7425,Siraha,ADM2:ADM3,DISTRICT
SM262275,89.6167,24.3333,Sirajganj,ADM2,DISTRICT
SM244666,75.5451,33.1436,Sirajic,LANGUAGE,LANGUAGE
SM257643,120.87,23.29,Siraya,ETHNICITY,ETHNICITY
SM244669,120.315,22.9777,Sirayaic,LANGUAGE,LANGUAGE
SM305330,17.2553,45.5258,Sirač,ADM2,DISTRICT
SM286251,6.7159,58.6651,Sirdal,ADM2,DISTRICT
SM3243,68.6667,40.4333,Sirdaryo,ADM1,DISTRICT
SM18448,68.6619,40.8395,Sirdaryo Shahri,ADM2,DISTRICT
SM18449,68.6667,40.8333,Sirdaryo Tumani,ADM2,DISTRICT
SM461519,173.9,64.4,Sireniki,ETHNICITY,ETHNICITY
SM291843,26.0688,47.948,Siret,ADM2,DISTRICT
SM290873,26.7338,47.4184,Siretel,ADM2,DISTRICT
SM18450,69.2358,41.2564,Sirgali Tumani,ADM2,DISTRICT
SM244681,9.6674,10.764,Siri,LANGUAGE,LANGUAGE
SM290542,21.6542,46.2847,Siria,ADM2,DISTRICT
SM244680,-70.184,0.4365,Siriano,LANGUAGE,LANGUAGE
SM292237,24.1857,44.9387,Sirineasa,ADM2,DISTRICT
SM264909,-35.1391,-8.5998,Sirinhaém,ADM2,DISTRICT
SM257645,-64.86,-16.66,Siriono,ETHNICITY,ETHNICITY
SM257645,-64.86,-16.66,Siriono,ETHNICITY,ETHNICITY
SM257645,-64.86,-16.66,Siriono,ETHNICITY,ETHNICITY
SM257645,-64.86,-16.66,Siriono,ETHNICITY,ETHNICITY
SM257645,-64.86,-16.66,Siriono,ETHNICITY,ETHNICITY
SM257645,-64,-15,Siriono,ETHNICITY,ETHNICITY
SM257645,-64,-15,Siriono,ETHNICITY,ETHNICITY
SM257645,-64,-15,Siriono,ETHNICITY,ETHNICITY
SM257645,-64,-15,Siriono,ETHNICITY,ETHNICITY
SM257645,-64,-15,Siriono,ETHNICITY,ETHNICITY
SM257645,-64,-16,Siriono,ETHNICITY,ETHNICITY
SM257645,-64,-16,Siriono,ETHNICITY,ETHNICITY
SM257645,-64,-16,Siriono,ETHNICITY,ETHNICITY
SM257645,-64,-16,Siriono,ETHNICITY,ETHNICITY
SM257645,-64,-16,Siriono,ETHNICITY,ETHNICITY
SM257645,-63.58,-15,Siriono,ETHNICITY,ETHNICITY
SM257645,-63.58,-15,Siriono,ETHNICITY,ETHNICITY
SM257645,-63.58,-15,Siriono,ETHNICITY,ETHNICITY
SM257645,-63.58,-15,Siriono,ETHNICITY,ETHNICITY
SM257645,-63.58,-15,Siriono,ETHNICITY,ETHNICITY
SM257645,-63.5,-14.5,Siriono,ETHNICITY,ETHNICITY
SM257645,-63.5,-14.5,Siriono,ETHNICITY,ETHNICITY
SM257645,-63.5,-14.5,Siriono,ETHNICITY,ETHNICITY
SM257645,-63.5,-14.5,Siriono,ETHNICITY,ETHNICITY
SM257645,-63.5,-14.5,Siriono,ETHNICITY,ETHNICITY
SM244679,-63.9688,-15.5298,Sirionó,LANGUAGE,LANGUAGE
SM267144,-37.1212,-10.5872,Siriri,ADM2,DISTRICT
SM277517,55.65,29.3667,Sirjan,ADM2,DISTRICT
SM260082,71.1156,34.7586,Sirkanay,ADM2,DISTRICT
SM276866,77.5,30.75,Sirmaur,ADM2,DISTRICT
SM244684,77.17,31.1,Sirmauri,LANGUAGE,LANGUAGE
SM291569,25.9416,44.8001,Sirna,ADM2,DISTRICT
SM3244,42.5,37.5,Sirnak,ADM1,DISTRICT
SM277187,72.75,24.8333,Sirohi,ADM2,DISTRICT
SM244687,145.994,-5.5289,Siroi,LANGUAGE,LANGUAGE
SM4720,34.314,1.157,Sironko (2010-),ADM1:ADM2,DISTRICT
SM276854,75.0833,29.5833,Sirsa,ADM2,DISTRICT
SM244563,145.225,-4.9824,Sirva,LANGUAGE,LANGUAGE
SM12022,46.5665,33.7546,Sirvan,ADM2,DISTRICT
SM304904,45.0115,15.4369,Sirwah,ADM2,DISTRICT
SM3246,16.5,45.4167,Sisacko-Moslavacka,ADM1,DISTRICT
SM275989,16.3667,45.5,Sisak,ADM2,DISTRICT
SM280797,102.6382,17.9406,Sisattanak,ADM2,DISTRICT
SM257650,15.22,-3.23,Sise,ETHNICITY,ETHNICITY
SM291103,22.8375,44.7715,Sisesti,ADM2,DISTRICT
SM291038,23.741,47.6133,Sisesti,ADM2,DISTRICT
SM9726,46.0271,39.5255,Sisian,ADM2,DISTRICT
SM244695,156.83,-7,Sisiqa,LANGUAGE,LANGUAGE
SM303204,-122.5404,41.5927,Siskiyou,ADM2,DISTRICT
SM305119,29.6601,-30.1108,Sisonke,ADM2,DISTRICT
SM269025,7.8487,47.4424,Sissach,ADM2,DISTRICT
SM274953,-1.7539,10.7049,Sissala East,ADM2,DISTRICT
SM274954,-2.2785,10.8077,Sissala West,ADM2,DISTRICT
SM244696,-2.3822,11.0916,Sissala of Burkina Faso,LANGUAGE,LANGUAGE
SM244697,142.049,-3.0198,Sissano,LANGUAGE,LANGUAGE
SM262210,-2.25,11.3333,Sissili (1995-),ADM1:ADM2,DISTRICT
SM3247,60.5,28.5,Sistan and Baluchestan,ADM1,DISTRICT
SM290543,21.7559,45.9865,Sistarovat,ADM2,DISTRICT
SM4169,-79.7896,19.722,Sister Island,ADM1,DISTRICT
SM290230,26.1062,45.6196,Sita Buzaului,ADM2,DISTRICT
SM283193,-92.352,17.0398,Sitalá,ADM2,DISTRICT
SM277431,85.5,26.6667,Sitamarhi (1994-),ADM2,DISTRICT
SM277336,80.9167,27.5,Sitapur,ADM2,DISTRICT
SM296690,31.6626,-26.851,Sithobela,ADM2,DISTRICT
SM280397,105.3655,11.7712,Sithor Kandal,ADM2,DISTRICT
SM244705,-2.75,9.1667,Siti,LANGUAGE,LANGUAGE
SM274384,41.8556,10.2824,Siti,ADM2,DISTRICT
SM274384,41.8556,10.2824,Siti,ADM2,DISTRICT
SM274384,41.8375,10.171,Siti,ADM2,DISTRICT
SM274384,41.8375,10.171,Siti,ADM2,DISTRICT
SM14857,-96.5437,16.3717,Sitio de Xitlapehua,ADM2,DISTRICT
SM264910,-39.5217,-7.6309,Sitio dos Moreiras,ADM2,DISTRICT
SM10471,-74.6308,10.8897,Sitionuevo,ADM2,DISTRICT
SM301184,-135.2692,57.0826,Sitka,ADM2,DISTRICT
SM257654,-135.74,57.84,Sitka,ETHNICITY,ETHNICITY
SM262387,27,44.0167,Sitovo,ADM2,DISTRICT
SM285885,5.8229,51.0107,Sittard-Geleen,ADM2,DISTRICT
SM283465,92.8674,20.2004,Sittwe,ADM2,DISTRICT
SM244706,102.6607,31.5905,Situ,LANGUAGE,LANGUAGE
SM276365,114.05,-7.7167,Situbondo,ADM2,DISTRICT
SM244709,-124.379,42.5111,Siuslaw,LANGUAGE,LANGUAGE
SM257655,-123.93,44.05,Siuslaw,ETHNICITY,ETHNICITY
SM257655,-123.93,44.05,Siuslaw,ETHNICITY,ETHNICITY
SM257655,-124,44,Siuslaw,ETHNICITY,ETHNICITY
SM257655,-124,44,Siuslaw,ETHNICITY,ETHNICITY
SM277228,78.5376,9.9276,Sivaganga,ADM2,DISTRICT
SM244712,52.92,30.08,Sivandi,LANGUAGE,LANGUAGE
SM3248,37.002,39.7916,Sivas,ADM1,DISTRICT
SM298990,29.6902,38.5085,Sivaslı,ADM2,DISTRICT
SM298874,39.3202,37.7619,Siverek,ADM2,DISTRICT
SM244715,-73.8612,-12.5109,Sivia Sign Language,LANGUAGE,LANGUAGE
SM257657,-170.3,63.34,Sivokakmeit,ETHNICITY,ETHNICITY
SM257657,-170.3,63.34,Sivokakmeit,ETHNICITY,ETHNICITY
SM257657,-170.31,63.49,Sivokakmeit,ETHNICITY,ETHNICITY
SM257657,-170.31,63.49,Sivokakmeit,ETHNICITY,ETHNICITY
SM298352,39.3058,38.4492,Sivrice,ADM2,DISTRICT
SM298411,31.5248,39.4594,Sivrihisar,ADM2,DISTRICT
SM244718,155.452,-6.6429,Siwai,LANGUAGE,LANGUAGE
SM257658,155.13,-5.88,Siwai,ETHNICITY,ETHNICITY
SM257658,155.13,-5.88,Siwai,ETHNICITY,ETHNICITY
SM257658,155.13,-5.88,Siwai,ETHNICITY,ETHNICITY
SM257658,155,-6,Siwai,ETHNICITY,ETHNICITY
SM257658,155,-6,Siwai,ETHNICITY,ETHNICITY
SM257658,155,-6,Siwai,ETHNICITY,ETHNICITY
SM257658,155.45,-6.76,Siwai,ETHNICITY,ETHNICITY
SM257658,155.45,-6.76,Siwai,ETHNICITY,ETHNICITY
SM257658,155.45,-6.76,Siwai,ETHNICITY,ETHNICITY
SM277432,84.5,26.1667,Siwan,ADM2,DISTRICT
SM250684,26.5,29.633,Siwans,ETHNICITY,ETHNICITY
SM250684,26.5,29.633,Siwans,ETHNICITY,ETHNICITY
SM250684,26,29,Siwans,ETHNICITY,ETHNICITY
SM250684,26,29,Siwans,ETHNICITY,ETHNICITY
SM244720,25.5196,29.2004,Siwi,LANGUAGE,LANGUAGE
SM244721,0.4436,7.2381,Siwu,LANGUAGE,LANGUAGE
SM10338,15.0739,12.0985,Sixieme Arrondissement,ADM2,DISTRICT
SM305141,21.1846,-28.0001,Siyanda,ADM2,DISTRICT
SM261950,49.0833,41,Siyəzən,ADM1:ADM2,DISTRICT
SM244725,93.6884,23.8192,Sizang Chin,LANGUAGE,LANGUAGE
SM3249,11.8333,55.4167,Sjælland,ADM1,DISTRICT
SM274522,-6.7992,62.1347,Sjógv,ADM2,DISTRICT
SM296445,13.7556,55.6515,Sjöbo,ADM2,DISTRICT
SM300028,32.9077,46.1154,Skadovs'kyi,ADM2,DISTRICT
SM277811,-18.1449,63.9595,Skaftárhreppur,ADM2,DISTRICT
SM11623,-20.25,65.95,Skagabyggd,ADM2,DISTRICT
SM244728,-121.991,48.5314,Skagit,DIALECT,LANGUAGE
SM303090,-121.8023,48.4822,Skagit,ADM2,DISTRICT
SM257660,-122.13,48.09,Skagit,ETHNICITY,ETHNICITY
SM18321,-135.306,59.4685,Skagway Municipality,ADM2,DISTRICT
SM13820,27.3863,55.9836,Skaistas pagasts,ADM2,DISTRICT
SM13821,24.5712,56.3777,Skaistkalnes pagasts,ADM2,DISTRICT
SM296179,17.1667,48.75,Skalica,ADM2,DISTRICT
SM303092,-121.9151,46.0228,Skamania,ADM2,DISTRICT
SM271695,9.8987,56.0796,Skanderborg (2007-),ADM2,DISTRICT
SM13822,24.9845,57.857,Skankalnes pagasts,ADM2,DISTRICT
SM296594,13.4755,58.3776,Skara,ADM2,DISTRICT
SM5137,75.6263,35.4012,Skardu (2015-),ADM2:ADM3,DISTRICT
SM11038,33.3539,34.8207,Skarinou,ADM2,DISTRICT
SM288754,20.7923,51.065,Skarżysko,ADM2,DISTRICT
SM286194,10.0674,63.3239,Skaun,ADM2,DISTRICT
SM13823,27.9498,56.1625,Skaunes pagasts,ADM2,DISTRICT
SM13824,22.4098,56.881,Skedes pagasts,ADM2,DISTRICT
SM268759,-132.0034,53.7495,Skeena-Queen Charlotte,ADM2,DISTRICT
SM11624,-19.3776,64.3474,Skeida- og Gnupverjahreppur,ADM2,DISTRICT
SM9864,19.4742,43.9933,Skelani Opstina,ADM2,DISTRICT
SM296533,20.6441,64.8026,Skellefteå,ADM2,DISTRICT
SM13825,27.0398,56.044,Skeltovas pagasts,ADM2,DISTRICT
SM313192,17.3894,44.4669,Skender Vakuf / Kneževo,ADM2:ADM3,DISTRICT
SM244731,-58.5626,6.0519,Skepi Creole Dutch,LANGUAGE,LANGUAGE
SM297944,9.9747,34.3416,Skhira,ADM2,DISTRICT
SM281422,-6.87,33.77,Skhirate-Témara,ADM2,DISTRICT
SM12732,-76.6275,18.1732,Skibo,ADM2,DISTRICT
SM286211,9.6105,59.2075,Skien,ADM2,DISTRICT
SM288877,20.1937,51.9449,Skierniewice,ADM2,DISTRICT
SM288876,20.1691,51.9581,Skierniewice (City),ADM2,DISTRICT
SM3251,6.8333,36.75,Skikda,ADM1,DISTRICT
SM457684,6.8333,36.75,Skikda District,ADM2,DISTRICT
SM13826,27.6513,57.0529,Skilbenu pagasts,ADM2,DISTRICT
SM257661,-122.94,46.05,Skilloot,ETHNICITY,ETHNICITY
SM296566,15.7146,59.7972,Skinnskatteberg,ADM2,DISTRICT
SM286288,11.1617,59.4775,Skiptvet,ADM2,DISTRICT
SM271696,8.9766,56.6448,Skive (2007-),ADM2,DISTRICT
SM286235,20.9737,70.0338,Skjervøy,ADM2,DISTRICT
SM286111,8.2649,61.8835,Skjåk,ADM2,DISTRICT
SM257662,-122.15,47.81,Skokomish,ETHNICITY,ETHNICITY
SM244734,29.7204,68.8326,Skolt Saami,LANGUAGE,LANGUAGE
SM257663,25.32,66.52,Skolt Sami,ETHNICITY,ETHNICITY
SM274533,-6.8867,61.9037,Skopun,ADM2,DISTRICT
SM277849,-21.5,64.5167,Skorradalshreppur,ADM2,DISTRICT
SM241034,140.902,-2.6438,Skou,LANGUAGE,LANGUAGE
SM11039,32.461,34.9762,Skoulli,ADM2,DISTRICT
SM11040,32.8848,35.0942,Skouriotissa,ADM2,DISTRICT
SM293222,123.5,53.8333,Skovorodinskiy rayon,ADM2,DISTRICT
SM305424,14.9053,45.4313,Skrad,ADM2,DISTRICT
SM275983,15.9247,43.8183,Skradin,ADM2,DISTRICT
SM260469,20.2548,40.5604,Skraparit,ADM2,DISTRICT
SM260469,20.2548,40.5604,Skraparit,ADM2,DISTRICT
SM260469,20.2667,40.55,Skraparit,ADM2,DISTRICT
SM260469,20.2667,40.55,Skraparit,ADM2,DISTRICT
SM4053,25.0969,56.6725,Skriveru Novads,ADM1,DISTRICT
SM13827,25.0974,56.6725,Skriveru novads,ADM2,DISTRICT
SM13828,26.7407,55.7629,Skrudalienas pagasts,ADM2,DISTRICT
SM13829,22.0093,56.6716,Skrunda,ADM2,DISTRICT
SM3953,21.9889,56.6611,Skrundas Novads,ADM1,DISTRICT
SM13830,22.0683,56.697,Skrundas pagasts,ADM2,DISTRICT
SM13831,25.4747,57.087,Skujenes pagasts,ADM2,DISTRICT
SM13832,24.5096,57.3722,Skultes pagasts,ADM2,DISTRICT
SM281339,21.5333,56.2667,Skuodo,ADM2,DISTRICT
SM296446,13.5455,55.4732,Skurup,ADM2,DISTRICT
SM11041,33.1653,35.2355,Skylloura,ADM2,DISTRICT
SM274532,-6.6756,61.8306,Skálavík,ADM2,DISTRICT
SM3250,13.5,55.9833,Skåne,ADM1,DISTRICT
SM296595,13.9036,58.4349,Skövde,ADM2,DISTRICT
SM277770,-16.7789,65.1718,Skútustaðahreppur,ADM2,DISTRICT
SM274534,-6.8246,61.7683,Skúvoy,ADM2,DISTRICT
SM294801,70.1667,55.6667,Sladkovskiy rayon,ADM2,DISTRICT
SM271723,11.3339,55.3455,Slagelse (2007-),ADM2,DISTRICT
SM13833,23.3121,56.882,Slampes pagasts,ADM2,DISTRICT
SM291570,25.9475,45.2303,Slanic,ADM2,DISTRICT
SM292156,26.4777,46.2232,Slanic-moldova,ADM2,DISTRICT
SM17085,28.25,59.0833,Slantsevskiy Rayon,ADM2,DISTRICT
SM291844,25.978,47.4363,Slatina,ADM2,DISTRICT
SM291460,24.3675,44.4428,Slatina,ADM2,DISTRICT
SM276014,17.7,45.7,Slatina,ADM2,DISTRICT
SM290050,22.3181,45.2606,Slatina-timis,ADM2,DISTRICT
SM291461,24.3208,44.4155,Slatioara,ADM2,DISTRICT
SM292071,28.5813,44.8758,Slava Cercheza,ADM2,DISTRICT
SM324178,-114.7723,55.2753,Slave Lake,ADM2:ADM3,DISTRICT
SM17086,78.5833,53,Slavgorodskiy Rayon,ADM2,DISTRICT
SM244741,14.7,41.89,Slavomolisano,LANGUAGE,LANGUAGE
SM276023,18.0306,45.1627,Slavonski Brod,ADM2,DISTRICT
SM305573,18.4792,45.0673,Slavonski Šamac,ADM2,DISTRICT
SM293042,21.6667,55,Slavskiy rayon,ADM2,DISTRICT
SM299454,9.5,48.1667,Slavuts'kyi,ADM2,DISTRICT
SM18277,30.7282,51.5304,Slavutyts'ka Mis'krada,ADM2,DISTRICT
SM17087,37.75,45.4167,Slavyansky rayon,ADM2,DISTRICT
SM262635,31,53.5,Slawharad,ADM2,DISTRICT
SM276712,110.3233,-7.6817,Sleman,ADM2,DISTRICT
SM285700,4.7719,51.83,Sliedrecht,ADM2,DISTRICT
SM3253,-8.6667,54.25,Sligo,ADM1:ADM2,DISTRICT
SM12733,-76.9399,18.082,Sligoville,ADM2,DISTRICT
SM291756,24.1978,45.9447,Slimnic,ADM2,DISTRICT
SM12734,-77.7969,18.0574,Slipe,ADM2,DISTRICT
SM262391,26.3167,42.6667,Sliven,ADM2,DISTRICT
SM3254,26.2833,42.6667,Sliven,ADM1,DISTRICT
SM290657,23.1076,44.792,Slivilesti,ADM2,DISTRICT
SM262428,22.9833,42.85,Slivnitsa,ADM2,DISTRICT
SM305638,17.5443,42.9586,Slivno,ADM2,DISTRICT
SM262369,26.3,43.9667,Slivo Pole,ADM2,DISTRICT
SM293284,50.25,58.75,Slobodskoy rayon,ADM2,DISTRICT
SM290933,27.3876,44.5744,Slobozia,ADM2,DISTRICT
SM290594,25.9107,43.8567,Slobozia,ADM2,DISTRICT
SM291289,25.2409,44.522,Slobozia,ADM2,DISTRICT
SM292374,27.0398,45.492,Slobozia Bradului,ADM2,DISTRICT
SM292375,27.214,45.6061,Slobozia Ciorasti,ADM2,DISTRICT
SM290305,25.7313,44.5987,Slobozia Moara,ADM2,DISTRICT
SM9812,25.25,53.0833,Slonimskiy Rayon (excl. Slonim),ADM2,DISTRICT
SM302068,-103.4599,46.4472,Slope,ADM2,DISTRICT
SM274686,-0.5667,51.5,Slough,ADM1:ADM2,DISTRICT
SM244749,18.7848,48.5457,Slovak,LANGUAGE,LANGUAGE
SM244747,18.1248,48.0463,Slovakian Sign Language,LANGUAGE,LANGUAGE
SM244748,14.7766,46.2543,Slovenian,LANGUAGE,LANGUAGE
SM296277,15.0836,46.4886,Slovenj Gradec,ADM1:ADM2,DISTRICT
SM4156,15.5067,46.4252,Slovenska Bistrica  (2006-),ADM1,DISTRICT
SM296221,15.4556,46.325,Slovenske Konjice,ADM1:ADM2,DISTRICT
SM299958,37.6162,48.8522,Slovians'ka,ADM2,DISTRICT
SM18278,37.5,48.9167,Sloviansk Raion,ADM2,DISTRICT
SM285629,3.5197,51.3349,Sluis,ADM2,DISTRICT
SM276054,15.5833,45.0833,Slunj,ADM2,DISTRICT
SM9813,27.6667,53,Slutski Rayon (excl. Slutsk),ADM2,DISTRICT
SM292905,104,51.5,Slyudyanskiy rayon,ADM2,DISTRICT
SM280524,103.0201,11.6036,Smach Mean Chey,ADM2,DISTRICT
SM285743,6.0333,53.1167,Smallingerland,ADM2,DISTRICT
SM262651,28.1667,54,Smalyavichy,ADM2,DISTRICT
SM292072,28.006,45.2846,Smardan,ADM2,DISTRICT
SM290466,27.9355,45.4969,Smardan,ADM2,DISTRICT
SM13834,23.3056,56.977,Smardes pagasts,ADM2,DISTRICT
SM291934,25.4419,43.8394,Smardioasa,ADM2,DISTRICT
SM9814,26.4167,54.5,Smarhonski Rayon (excl. Smarhon'),ADM2,DISTRICT
SM17842,14.4704,46.125,Smarna Gora District,ADM2,DISTRICT
SM296546,15.452,60.0948,Smedjebacken,ADM2,DISTRICT
SM289922,26.9126,44.9882,Smeeni,ADM2,DISTRICT
SM295075,134,48.5,Smidovichskiy rayon,ADM2,DISTRICT
SM299430,31.8836,49.2329,Smilians'kyi,ADM2,DISTRICT
SM13835,25.904,57.4207,Smiltene,ADM2,DISTRICT
SM4038,26.0108,57.4067,Smiltenes Novads,ADM1,DISTRICT
SM13836,25.8761,57.4579,Smiltenes pagasts,ADM2,DISTRICT
SM294348,142.8333,49.75,Smirnykhovskiy rayon,ADM2,DISTRICT
SM302765,-95.2692,32.375,Smith,ADM2,DISTRICT
SM302622,-85.9567,36.2505,Smith,ADM2,DISTRICT
SM301487,-89.5067,32.0177,Smith,ADM2,DISTRICT
SM300980,-98.7855,39.7852,Smith,ADM2,DISTRICT
SM12735,-78.0879,18.2228,Smithfield,ADM2,DISTRICT
SM3255,-64.7353,32.3153,Smiths,ADM1,DISTRICT
SM12736,-77.3856,18.1198,Smithville,ADM2,DISTRICT
SM305639,16.8928,42.929,Smokvica,ADM2,DISTRICT
SM324054,-112.47,54.1119,Smoky Lake,ADM2:ADM3,DISTRICT
SM10167,-112.2519,54.2391,Smoky Lake County,ADM2,DISTRICT
SM323807,-117.1851,55.6507,Smoky River No. 130,ADM2:ADM3,DISTRICT
SM3256,33,55,Smolensk,ADM1,DISTRICT
SM17088,84.9167,52.1667,Smolenskiy Rayon,ADM2,DISTRICT
SM262415,24.713,41.5752,Smolyan,ADM2,DISTRICT
SM3257,24.65,41.6167,Smolyan,ADM1,DISTRICT
SM290467,27.7549,45.9325,Smulti,ADM2,DISTRICT
SM262380,27.05,43.05,Smyadovo,ADM2,DISTRICT
SM302954,-81.537,36.8439,Smyth,ADM2,DISTRICT
SM244754,140.897,-8.9181,Smärky Kanum,LANGUAGE,LANGUAGE
SM286429,8.0181,63.4653,Smøla,ADM2,DISTRICT
SM290974,26.1238,44.6848,Snagov,ADM2,DISTRICT
SM257670,-119.89,42.43,Snake,ETHNICITY,ETHNICITY
SM13837,21.9469,56.8434,Snepeles pagasts,ADM2,DISTRICT
SM299979,25.5601,48.448,Sniatyns'kyi,ADM2,DISTRICT
SM299593,32.7895,47.0693,Snihurivs'kyi,ADM2,DISTRICT
SM296164,22.1506,48.9858,Snina,ADM2,DISTRICT
SM299961,38.765,48.0372,Snizhnens'ka,ADM2,DISTRICT
SM244758,-122.138,47.9341,Snohomish,DIALECT,LANGUAGE
SM303093,-121.7222,48.046,Snohomish,ADM2,DISTRICT
SM257671,-122.24,47.55,Snohomish,ETHNICITY,ETHNICITY
SM12737,-76.4935,18.1947,Snow Hill,ADM2,DISTRICT
SM12738,-77.4914,17.942,Snowdon,ADM2,DISTRICT
SM18565,148.8931,-36.4212,Snowy Monaro Regional,ADM2,DISTRICT
SM18566,148.0939,-35.943,Snowy Valleys,ADM2,DISTRICT
SM280362,106.4736,12.1937,Snuol,ADM2,DISTRICT
SM302335,-77.0702,40.7698,Snyder,ADM2,DISTRICT
SM286459,12.3829,64.2461,Snåsa,ADM2,DISTRICT
SM277850,-23.5,64.8667,Snæfellsbær,ADM2,DISTRICT
SM244764,12.525,3.6826,So (Cameroon),LANGUAGE,LANGUAGE
SM244769,23.6907,1.2504,So (Democratic Republic of Congo),LANGUAGE,LANGUAGE
SM297315,103.4492,18.1418,So Phisai,ADM2,DISTRICT
SM273997,42.7333,13.0167,So. Southern Red-Sea,ADM2,DISTRICT
SM10472,-74.2063,4.5461,Soacha (1995-),ADM2,DISTRICT
SM370998,45.4088,-16.4967,Soalala,ADM2:ADM3,DISTRICT
SM371020,49.3098,-16.7234,Soanierana-Ivongo,ADM2:ADM3,DISTRICT
SM292654,24.9276,45.9287,Soars,ADM2,DISTRICT
SM270832,-72.7242,6.2997,Soatá,ADM2,DISTRICT
SM370951,46.5617,-19.2259,Soavinandriana,ADM2:ADM3,DISTRICT
SM284822,7.9644,10.8701,Soba,ADM2,DISTRICT
SM257673,-109.99,33.57,Sobaipuri,ETHNICITY,ETHNICITY
SM244761,138.75,-1.87,Sobei,LANGUAGE,LANGUAGE
SM17089,40,56.1667,Sobinskiy Rayon,ADM2,DISTRICT
SM18451,69.2261,41.3619,Sobir Rahimov Tumani,ADM2,DISTRICT
SM293104,156.5,54.5833,Sobolevskiy rayon,ADM2,DISTRICT
SM267676,-40.7752,-9.5904,Sobradinho,ADM2,DISTRICT
SM10026,-53.0202,-29.4133,Sobradinho (1996-),ADM2:ADM3,DISTRICT
SM264340,-35.2325,-7.1689,Sobrado,ADM2,DISTRICT
SM267855,-40.0438,-3.8593,Sobral,ADM2,DISTRICT
SM289263,-9.1741,38.9959,Sobral de Monte Agraço,ADM2,DISTRICT
SM296144,22.182,48.7425,Sobrance,ADM2,DISTRICT
SM261136,-64.0833,-29.6667,Sobremonte,ADM2,DISTRICT
SM263999,-42.1482,-19.2131,Sobrália,ADM2,DISTRICT
SM18362,-55.5847,-34.6775,Soca,ADM2,DISTRICT
SM4202,124.9036,6.4736,Soccsksargen,ADM1,DISTRICT
SM270833,-72.7007,5.9293,Socha,ADM2,DISTRICT
SM288976,20.2134,52.2669,Sochaczew,ADM2,DISTRICT
SM293394,39.7334,43.6008,Sochi gorsovet,ADM2,DISTRICT
SM282779,-96.9222,19.1731,Sochiapa,ADM2,DISTRICT
SM244766,-96.6079,17.7985,Sochiapam Chinantec,LANGUAGE,LANGUAGE
SM295733,-87.9946,13.6969,Sociedad,ADM2,DISTRICT
SM257675,-151.44,-16.81,Society Islands,ETHNICITY,ETHNICITY
SM257675,-151.44,-16.81,Society Islands,ETHNICITY,ETHNICITY
SM257675,-149.52,-17.61,Society Islands,ETHNICITY,ETHNICITY
SM257675,-149.52,-17.61,Society Islands,ETHNICITY,ETHNICITY
SM257675,-149.54,-17.71,Society Islands,ETHNICITY,ETHNICITY
SM257675,-149.54,-17.71,Society Islands,ETHNICITY,ETHNICITY
SM290544,21.4413,46.5101,Socodor,ADM2,DISTRICT
SM290051,21.4178,44.8533,Socol,ADM2,DISTRICT
SM283194,-92.3666,16.1741,Socoltenango,ADM2,DISTRICT
SM291688,22.9814,47.5402,Socond,ADM2,DISTRICT
SM282780,-94.8452,17.9918,Soconusco,ADM2,DISTRICT
SM301855,-106.9303,34.0072,Socorro,ADM2,DISTRICT
SM270516,-73.255,6.4565,Socorro,ADM2,DISTRICT
SM266968,-46.5099,-22.6063,Socorro,ADM2,DISTRICT
SM265148,-42.5075,-7.8982,Socorro do Piauí,ADM2,DISTRICT
SM4164,53.8207,12.5023,Socotra,ADM1,DISTRICT
SM270834,-72.5811,5.909,Socotá,ADM2,DISTRICT
SM279385,140.0177,35.4164,Sodegaura,ADM2,DISTRICT
SM14107,-7.9333,4.6833,Sodoke,ADM2,DISTRICT
SM10536,18.6196,45.4001,Sodolovci,ADM2,DISTRICT
SM296264,14.6221,45.7564,Sodražica,ADM1:ADM2,DISTRICT
SM297010,99.173,18.0941,Soem Ngam,ADM2,DISTRICT
SM297135,102.493,14.3446,Soeng Sang,ADM2,DISTRICT
SM285602,5.2985,52.1564,Soest,ADM2,DISTRICT
SM3260,34.8,-19.2,Sofala,ADM1,DISTRICT
SM3261,23.8,42.6667,Sofia,ADM1,DISTRICT
SM281479,48.4,-15.2,Sofia,ADM1:ADM2,DISTRICT
SM299918,33.868,48.0487,Sofi‹vs'kyi,ADM2,DISTRICT
SM290545,21.2906,46.2681,Sofronea,ADM2,DISTRICT
SM11042,33.5425,34.8228,Softades,ADM2,DISTRICT
SM244772,33.3942,0.4046,Soga,LANGUAGE,LANGUAGE
SM270835,-72.9119,5.6401,Sogamoso,ADM2,DISTRICT
SM244775,66.983,39.7,Sogdian,LANGUAGE,LANGUAGE
SM461870,7.103,61.2314,Sogndal (2020-),ADM2,DISTRICT
SM289972,26.5216,44.3555,Sohatu,ADM2,DISTRICT
SM5129,68.6455,28.5093,Sohbatpur,ADM2:ADM3,DISTRICT
SM288593,147.9167,-9,Sohe,ADM2,DISTRICT
SM289836,22.9941,46.3275,Sohodol,ADM2,DISTRICT
SM297805,125.94,-8.86,Soibada,ADM2,DISTRICT
SM244783,54.5964,31.8542,Soic,LANGUAGE,LANGUAGE
SM291571,26.2097,45.1694,Soimari,ADM2,DISTRICT
SM292470,22.1343,46.6706,Soimi,ADM2,DISTRICT
SM290788,22.863,45.9459,Soimus,ADM2,DISTRICT
SM244787,106.84,15.1665,Sok,DIALECT,LANGUAGE
SM280685,128.5928,38.2073,Sokcho,ADM2,DISTRICT
SM14977,158.1427,6.9327,Sokehs Municipality,ADM2,DISTRICT
SM274772,41,43.25,Sokhumi,ADM2,DISTRICT
SM286137,6.2943,58.3426,Sokndal,ADM2,DISTRICT
SM17090,40.3333,59.5,Sokol'skiy Rayon,ADM2,DISTRICT
SM313226,18.7767,43.9631,Sokolac,ADM2:ADM3,DISTRICT
SM13838,26.9993,56.5046,Sokolku pagasts,ADM2,DISTRICT
SM271215,12.6333,50.2167,Sokolov,ADM2,DISTRICT
SM305716,16.7095,46.1043,Sokolovac,ADM2,DISTRICT
SM13260,69.25,55.1667,Sokolovka Audany,ADM2,DISTRICT
SM292940,43.1594,57.1385,Sokol’skiy rayon,ADM2,DISTRICT
SM244791,17.6423,11.4634,Sokoro,LANGUAGE,LANGUAGE
SM3263,5.25,13.0833,Sokoto (1996-),ADM1,DISTRICT
SM285226,5.268,13.0656,Sokoto North,ADM2,DISTRICT
SM285227,5.2594,13.0401,Sokoto South,ADM2,DISTRICT
SM288977,22.269,52.4954,Sokołów,ADM2,DISTRICT
SM280315,74.3333,42.8333,Sokuluk,ADM2,DISTRICT
SM299877,27.4124,48.4484,Sokyrians'kyi,ADM2,DISTRICT
SM288683,23.4186,53.4351,Sokółka,ADM2,DISTRICT
SM286138,5.6473,58.8881,Sola,ADM2,DISTRICT
SM276867,76.8333,31.0833,Solan,ADM2,DISTRICT
SM303205,-121.94,38.2669,Solano,ADM2,DISTRICT
SM270869,-73.276,0.2284,Solano,ADM2,DISTRICT
SM277057,75.5,17.75,Solapur,ADM2,DISTRICT
SM296224,14.6591,46.4062,Solcava,ADM1:ADM2,DISTRICT
SM289973,26.5122,44.2268,Soldanu,ADM2,DISTRICT
SM275899,-87.1167,13.5833,Soledad,ADM2,DISTRICT
SM270670,-74.7879,10.8999,Soledad,ADM2,DISTRICT
SM282781,-97.1724,18.7207,Soledad Atzompa,ADM2,DISTRICT
SM14858,-96.8221,17.1656,Soledad Etla,ADM2,DISTRICT
SM282782,-96.4419,19.0393,Soledad de Doblado,ADM2,DISTRICT
SM282503,-100.8909,22.2123,Soledad de Graciano Sánchez,ADM2,DISTRICT
SM265949,-52.5102,-28.8405,Soledade,ADM2,DISTRICT
SM264342,-36.3648,-7.1124,Soledade,ADM2,DISTRICT
SM264000,-45.0427,-22.0222,Soledade de Minas,ADM2,DISTRICT
SM292309,27.8061,46.7717,Solesti,ADM2,DISTRICT
SM293827,30.25,58.0833,Soletskiy rayon,ADM2,DISTRICT
SM298206,41.056,38.9697,Solhan,ADM2,DISTRICT
SM244798,28.7934,-15.5213,Soli,LANGUAGE,LANGUAGE
SM250970,28.74,-16.21,Soli,ETHNICITY,ETHNICITY
SM14860,-87.1766,20.6308,Solidaridad (2008-),ADM2,DISTRICT
SM293347,42.25,59,Soligalichskiy rayon,ADM2,DISTRICT
SM274687,-1.75,52.4167,Solihull,ADM1:ADM2,DISTRICT
SM297928,10.5275,36.7358,Soliman,ADM2,DISTRICT
SM257683,-62.79,-4.16,Soliman,ETHNICITY,ETHNICITY
SM276000,16.4907,43.5433,Solin,ADM2,DISTRICT
SM10473,-75.6553,0.9698,Solita,ADM2,DISTRICT
SM296538,16.9167,63.3833,Sollefteå,ADM2,DISTRICT
SM296477,17.9352,59.445,Sollentuna,ADM2,DISTRICT
SM296478,18.0073,59.3655,Solna,ADM2,DISTRICT
SM17091,37.0833,56.0833,Solnechnogorskiy Rayon,ADM2,DISTRICT
SM293514,36.75,51.5,Solntsevskiy rayon,ADM2,DISTRICT
SM276649,100.8167,-0.9667,Solok,ADM2,DISTRICT
SM276648,101.417,-1.2333,Solok Selatan,ADM2,DISTRICT
SM275335,-91.1825,14.8179,Sololá,ADM2,DISTRICT
SM3265,-91.25,14.6667,Sololá,ADM1,DISTRICT
SM275496,-91.4361,15.6712,Soloma,ADM2,DISTRICT
SM299477,30.4514,50.4289,Solomians'kyi,ADM2,DISTRICT
SM257684,159.06,-8.02,Solomon Islands,ETHNICITY,ETHNICITY
SM244806,159.9669,-9.454,Solomon Islands Sign Language,LANGUAGE,LANGUAGE
SM244807,119.4873,48.166,Solon,DIALECT,LANGUAGE
SM292997,84.5,51.6667,Soloneshenskiy rayon,ADM2,DISTRICT
SM244802,148.825,-5.8613,Solong,LANGUAGE,LANGUAGE
SM257685,12.91,-6.41,Solongo,ETHNICITY,ETHNICITY
SM299919,34.8758,48.2075,Solonians'kyi,ADM2,DISTRICT
SM292157,26.5386,46.5693,Solont,ADM2,DISTRICT
SM267856,-39.0121,-5.8707,Solonópole,ADM2,DISTRICT
SM244801,154.624,-5.4104,Solos,LANGUAGE,LANGUAGE
SM283195,-93.013,17.3936,Solosuchiapa,ADM2,DISTRICT
SM268961,7.5308,47.2082,Solothurn,ADM2,DISTRICT
SM3266,7.5205,47.2107,Solothurn,ADM1,DISTRICT
SM291196,24.7853,46.7811,Solovastru,ADM2,DISTRICT
SM271724,12.1734,55.5355,Solrød,ADM2,DISTRICT
SM12023,48.7939,36.4324,Soltaniyeh,ADM2,DISTRICT
SM244809,-96.96,16.54,Soltec,LANGUAGE,LANGUAGE
SM282289,-97.7424,19.1389,Soltepec,ADM2,DISTRICT
SM292998,86.5,52.8333,Soltonskiy rayon,ADM2,DISTRICT
SM244361,86.9994,27.674,Solu-Khumbu Sherpa,LANGUAGE,LANGUAGE
SM375326,86.7817,27.7258,Solukhumbu,ADM2:ADM3,DISTRICT
SM286169,4.8388,61.0739,Solund,ADM2,DISTRICT
SM305199,26.0429,-12.3928,Solwezi,ADM2,DISTRICT
SM264341,-35.695,-6.7332,Solânea,ADM2,DISTRICT
SM244817,146.651,-6.0737,Som,LANGUAGE,LANGUAGE
SM298730,27.6175,39.1963,Soma,ADM2,DISTRICT
SM13089,140.7739,37.7098,Soma-gun,ADM2,DISTRICT
SM244813,45.1529,4.7787,Somali,LANGUAGE,LANGUAGE
SM3267,44,7,Somali,ADM1,DISTRICT
SM251007,47,4.57,Somalie,ETHNICITY,ETHNICITY
SM251007,47,4.57,Somalie,ETHNICITY,ETHNICITY
SM251007,47,4.57,Somalie,ETHNICITY,ETHNICITY
SM251007,47,4.57,Somalie,ETHNICITY,ETHNICITY
SM251007,47.05,7.3,Somalie,ETHNICITY,ETHNICITY
SM251007,47.05,7.3,Somalie,ETHNICITY,ETHNICITY
SM251007,47.05,7.3,Somalie,ETHNICITY,ETHNICITY
SM251007,47.05,7.3,Somalie,ETHNICITY,ETHNICITY
SM251007,47.25,9,Somalie,ETHNICITY,ETHNICITY
SM251007,47.25,9,Somalie,ETHNICITY,ETHNICITY
SM251007,47.25,9,Somalie,ETHNICITY,ETHNICITY
SM251007,47.25,9,Somalie,ETHNICITY,ETHNICITY
SM251007,48,8,Somalie,ETHNICITY,ETHNICITY
SM251007,48,8,Somalie,ETHNICITY,ETHNICITY
SM251007,48,8,Somalie,ETHNICITY,ETHNICITY
SM251007,48,8,Somalie,ETHNICITY,ETHNICITY
SM251007,48.3,5.6,Somalie,ETHNICITY,ETHNICITY
SM251007,48.3,5.6,Somalie,ETHNICITY,ETHNICITY
SM251007,48.3,5.6,Somalie,ETHNICITY,ETHNICITY
SM251007,48.3,5.6,Somalie,ETHNICITY,ETHNICITY
SM283073,-103.6129,23.6399,Sombrerete,ADM2,DISTRICT
SM266321,-49.6918,-29.0819,Sombrio,ADM2,DISTRICT
SM291039,23.4667,47.5167,Somcuta Mare,ADM2,DISTRICT
SM296940,103.7501,16.7697,Somdet,ADM2,DISTRICT
SM285951,5.7105,51.3823,Someren,ADM2,DISTRICT
SM302336,-79.0283,39.9724,Somerset,ADM2,DISTRICT
SM301822,-74.6163,40.5635,Somerset,ADM2,DISTRICT
SM301224,-75.8535,38.0801,Somerset,ADM2,DISTRICT
SM301202,-69.9588,45.5139,Somerset,ADM2,DISTRICT
SM274688,-3,51.0833,Somerset,ADM1:ADM2,DISTRICT
SM261663,152.4152,-27.0113,Somerset,ADM2,DISTRICT
SM12739,-77.5431,18.0804,Somerset (Manchester),ADM2,DISTRICT
SM18487,-76.546,18.0098,Somerset (St. Thomas),ADM2,DISTRICT
SM12740,-77.7707,18.405,Somerton,ADM2,DISTRICT
SM302766,-97.7743,32.2223,Somervell,ADM2,DISTRICT
SM291636,23.2229,47.3285,Somes-odorhei,ADM2,DISTRICT
SM274503,2.2764,49.9314,Somme,ADM2,DISTRICT
SM3268,17.5833,46.4167,Somogy,ADM1,DISTRICT
SM270836,-73.4286,4.9697,Somondoco,ADM2,DISTRICT
SM285511,-86.9744,12.9602,Somotillo,ADM2,DISTRICT
SM285430,-86.6378,13.5296,Somoto,ADM2,DISTRICT
SM292073,28.6567,45.1886,Somova,ADM2,DISTRICT
SM244820,16.6784,9.659,Somrai,LANGUAGE,LANGUAGE
SM244819,103.139,12.243,Somray of Battambang-Somre of Siem Reap,LANGUAGE,LANGUAGE
SM244822,11.4826,6.9053,Somyev,LANGUAGE,LANGUAGE
SM285952,5.4993,51.5097,Son en Breugel,ADM2,DISTRICT
SM15273,125.7753,38.9933,Son'gyo-guyok,ADM2,DISTRICT
SM289837,24.0026,46.2509,Sona,ADM2,DISTRICT
SM275099,-14.403,12.412,Sonaco,ADM2,DISTRICT
SM16217,-67.0276,18.3149,Sonador Barrio,ADM2,DISTRICT
SM16218,-66.1192,18.2705,Sonadora Barrio (Aguas Buenas),ADM2,DISTRICT
SM16219,-66.1256,18.2888,Sonadora Barrio (Guaynabo),ADM2,DISTRICT
SM244825,103.33,26.55,Sonaga,LANGUAGE,LANGUAGE
SM275837,-86.26,15.64,Sonaguera,ADM2,DISTRICT
SM277337,83.0535,24.4021,Sonbhadra,ADM2,DISTRICT
SM244828,18.3555,-6.3222,Sonde,LANGUAGE,LANGUAGE
SM250696,19,-7,Sonde,ETHNICITY,ETHNICITY
SM277865,9.9491,46.2111,Sondrio,ADM2,DISTRICT
SM276856,76.9167,29,Sonepat (1996-),ADM2,DISTRICT
SM297397,100.2291,18.5701,Song,ADM2,DISTRICT
SM284841,12.6269,9.8547,Song,ADM2,DISTRICT
SM297499,103.4499,17.3088,Song Dao,ADM2,DISTRICT
SM297235,100.6617,19.4091,Song Kwae,ADM2,DISTRICT
SM297617,99.9758,14.189,Song Phi Nong,ADM2,DISTRICT
SM244836,26.016,-4.0121,Songa,LANGUAGE,LANGUAGE
SM262053,29.6676,-3.8534,Songa,ADM2,DISTRICT
SM244835,25.2765,-5.7199,Songe,LANGUAGE,LANGUAGE
SM299165,35.4,-10.3906,Songea Rural,ADM2,DISTRICT
SM299166,35.6205,-10.657,Songea Urban,ADM2,DISTRICT
SM289133,126.2331,38.4811,Songhu,ADM2,DISTRICT
SM289155,125.1517,38.3689,Songhwa,ADM2,DISTRICT
SM283830,95.8833,48.8833,Songino,ADM2,DISTRICT
SM257696,-123.53,48.53,Songish,ETHNICITY,ETHNICITY
SM3270,100.6667,6.8333,Songkhla,ADM1,DISTRICT
SM280790,105.3073,16.1712,Songkhone,ADM2,DISTRICT
SM260378,14.7894,-7.1335,Songo,ADM2,DISTRICT
SM250590,18.31,-9.32,Songo,ETHNICITY,ETHNICITY
SM257697,17.99,-4.92,Songo,ETHNICITY,ETHNICITY
SM257697,17.99,-4.92,Songo,ETHNICITY,ETHNICITY
SM257697,18,-5,Songo,ETHNICITY,ETHNICITY
SM257697,18,-5,Songo,ETHNICITY,ETHNICITY
SM271053,-75.6977,20.1639,Songo-La Maya,ADM2,DISTRICT
SM244833,26.3477,-2.2187,Songoora,LANGUAGE,LANGUAGE
SM280625,127.1111,37.5021,Songpa,ADM2,DISTRICT
SM289134,125.6306,38.7606,Songrim,ADM2,DISTRICT
SM257698,144.42,-7.05,Songu,ETHNICITY,ETHNICITY
SM4695,32.8029,-9.0103,Songwe District (2016-),ADM2,DISTRICT
SM4162,32.8029,-9.0103,Songwe Region,ADM1,DISTRICT
SM289036,125.9381,40.3547,Songwŏn,ADM2,DISTRICT
SM250698,25,-6,Songye,ETHNICITY,ETHNICITY
SM269239,124.3972,44.8878,Songyuan,ADM2,DISTRICT
SM244843,81.2185,28.7782,Sonha,LANGUAGE,LANGUAGE
SM244847,142.589,-6.4274,Sonia,LANGUAGE,LANGUAGE
SM244846,-11.7178,13.1273,Soninke,LANGUAGE,LANGUAGE
SM11689,92.9643,26.7675,Sonitpur (2015-),ADM2,DISTRICT
SM250747,36,-3,Sonjo,ETHNICITY,ETHNICITY
SM303206,-122.9225,38.5253,Sonoma,ADM2,DISTRICT
SM263090,-54.4533,-17.674,Sonora,ADM2,DISTRICT
SM3271,-110.5,29.6667,Sonora,ADM1,DISTRICT
SM277520,47.5,34.8333,Sonqor,ADM2,DISTRICT
SM250387,0.73,14.19,Sonra,ETHNICITY,ETHNICITY
SM250387,0.73,14.19,Sonra,ETHNICITY,ETHNICITY
SM250387,0.73,14.19,Sonra,ETHNICITY,ETHNICITY
SM250387,-1,17,Sonra,ETHNICITY,ETHNICITY
SM250387,-1,17,Sonra,ETHNICITY,ETHNICITY
SM250387,-1,17,Sonra,ETHNICITY,ETHNICITY
SM250387,-1.67,16.58,Sonra,ETHNICITY,ETHNICITY
SM250387,-1.67,16.58,Sonra,ETHNICITY,ETHNICITY
SM250387,-1.67,16.58,Sonra,ETHNICITY,ETHNICITY
SM295564,-89.8,13.6333,Sonsonate,ADM2,DISTRICT
SM3272,-89.7,13.7,Sonsonate,ADM1,DISTRICT
SM244852,132.2213,5.3286,Sonsorol,LANGUAGE,LANGUAGE
SM3273,132.2206,5.331,Sonsorol,ADM1,DISTRICT
SM270154,-75,5.75,Sonsón,ADM2,DISTRICT
SM295565,-89.7084,13.7491,Sonzacate,ADM2,DISTRICT
SM286668,-81.3661,7.8438,Soná,ADM2,DISTRICT
SM244860,34.7595,2.5837,Soo,LANGUAGE,LANGUAGE
SM278509,130.9747,31.6562,Soo,ADM2,DISTRICT
SM257705,35.78,2.32,Soo-Tepeth-Tepes,ETHNICITY,ETHNICITY
SM3274,47.5,8.6667,Sool,ADM1,DISTRICT
SM268033,-40.1413,-19.0822,Sooretama,ADM2,DISTRICT
SM244869,145.361,-5.4961,Sop,LANGUAGE,LANGUAGE
SM297068,97.9315,17.9633,Sop Moei,ADM2,DISTRICT
SM297011,99.3408,17.8986,Sop Prap,ADM2,DISTRICT
SM280860,104.38,20.66,Sopbao,ADM2,DISTRICT
SM270155,-75.7461,6.5227,Sopetrán,ADM2,DISTRICT
SM3275,21.32,41.89,Sopište,ADM1,DISTRICT
SM305524,17.7451,45.803,Sopje,ADM2,DISTRICT
SM10474,-75.1185,10.3318,Soplaviento (1995-),ADM2,DISTRICT
SM290412,23.5125,44.3906,Sopot,ADM2,DISTRICT
SM288703,18.5478,54.4419,Sopot,ADM2,DISTRICT
SM290052,21.8514,44.7965,Sopotu Nou,ADM2,DISTRICT
SM276582,119.89,-4.3842,Soppeng,ADM2,DISTRICT
SM276269,16.7565,47.6039,Sopron-Fertőd,ADM1:ADM2,DISTRICT
SM270056,-73.9862,4.8716,Sopó,ADM2,DISTRICT
SM244871,53.9168,12.4892,Soqotri,LANGUAGE,LANGUAGE
SM244873,84.19,19.1213,Sora,LANGUAGE,LANGUAGE
SM270837,-73.4434,5.5808,Sora,ADM2,DISTRICT
SM13090,141.9546,43.4295,Sorachi-gun,ADM2,DISTRICT
SM270838,-73.3189,5.496,Soracá,ADM2,DISTRICT
SM13091,135.9342,34.7834,Soraku-gun,ADM2,DISTRICT
SM5324,44.5377,36.6543,Soran (excl. Riwanduz),ADM2,DISTRICT
SM5324,44.5377,36.6543,Soran (excl. Riwanduz),ADM2,DISTRICT
SM5324,44.6032,36.8665,Soran (excl. Riwanduz),ADM2,DISTRICT
SM5324,44.6032,36.8665,Soran (excl. Riwanduz),ADM2,DISTRICT
SM13261,72.8667,49.7667,Sorang Qalasy,ADM2,DISTRICT
SM8075,14.102,51.275,Sorbs,ETHNICITY,ETHNICITY
SM244878,94.3154,24.7474,Sorbung,LANGUAGE,LANGUAGE
SM261771,147.6949,-42.7891,Sorell,ADM2,DISTRICT
SM299028,35.1921,39.8202,Sorgun,ADM2,DISTRICT
SM244881,146.642,-1.9838,Sori-Harengan,LANGUAGE,LANGUAGE
SM274073,-2.6667,41.6667,Soria,ADM2,DISTRICT
SM3277,-57.75,-33.5,Soriano,ADM1,DISTRICT
SM12024,53.1333,34.9333,Sorkheh,ADM2,DISTRICT
SM244883,53.219,35.4565,Sorkhei-Aftari,LANGUAGE,LANGUAGE
SM3278,28.25,48.1667,Soroca,ADM1,DISTRICT
SM266969,-47.4596,-23.4958,Sorocaba,ADM2,DISTRICT
SM257710,139.59,10.04,Sorol,ETHNICITY,ETHNICITY
SM14978,140.3925,8.1435,Sorol Municipality,ADM2,DISTRICT
SM276522,131.5,-1.1667,Sorong,ADM2,DISTRICT
SM276521,132.2864,-1.505,Sorong Selatan,ADM2,DISTRICT
SM4718,33.5943,1.7848,Soroti (2010-),ADM1:ADM2,DISTRICT
SM10027,-55.6995,-12.6817,Sorriso Municipality,ADM2:ADM3,DISTRICT
SM296520,16.75,65.75,Sorsele,ADM2,DISTRICT
SM3280,123.9167,12.8333,Sorsogon,ADM1:ADM2,DISTRICT
SM244889,124.165,13.0426,Sorsogon Ayta,LANGUAGE,LANGUAGE
SM293133,30.6667,61.75,Sortaval' gorsovet,ADM2,DISTRICT
SM286084,15.4117,68.6962,Sortland,ADM2,DISTRICT
SM257711,1.71,10.49,Soruba,ETHNICITY,ETHNICITY
SM271725,11.5569,55.4827,Sorø (2007-),ADM2,DISTRICT
SM244890,143.396,-3.9902,Sos Kundi,LANGUAGE,LANGUAGE
SM303844,-69.3083,8.0948,Sosa,ADM2,DISTRICT
SM293788,43.25,55.75,Sosnovskiy rayon,ADM2,DISTRICT
SM292765,61.1667,55.25,Sosnovskiy rayon,ADM2,DISTRICT
SM293543,29.0901,59.8746,Sosnovyy Bor,ADM2,DISTRICT
SM288736,19.1946,50.2712,Sosnowiec,ADM2,DISTRICT
SM15274,125.7539,39.0672,Sosong-guyok,ADM2,DISTRICT
SM10252,15.5676,3.8954,Sosso-Nakombo,ADM2,DISTRICT
SM264343,-36.1788,-6.6759,Sossêgo,ADM2,DISTRICT
SM17843,14.6729,46.0434,Sostro District,ADM2,DISTRICT
SM271824,-70.4667,19.7167,Sosua,ADM2,DISTRICT
SM244893,140.969,-8.4045,Sota Kanum,LANGUAGE,LANGUAGE
SM290306,25.3825,44.9854,Sotanga,ADM2,DISTRICT
SM270719,-73.2859,5.7618,Sotaquirá,ADM2,DISTRICT
SM269876,-76.5833,2.25,Sotará,ADM2,DISTRICT
SM14861,-94.9285,18.2548,Soteapan (1997-),ADM2,DISTRICT
SM296596,11.343,58.4222,Sotenäs,ADM2,DISTRICT
SM250703,26.32,-30.06,Sotho,ETHNICITY,ETHNICITY
SM250703,26.32,-30.06,Sotho,ETHNICITY,ETHNICITY
SM250703,28,-29,Sotho,ETHNICITY,ETHNICITY
SM250703,28,-29,Sotho,ETHNICITY,ETHNICITY
SM303659,-62.5,8.8333,Sotillo,ADM2,DISTRICT
SM303708,-64.6167,10.2167,Sotillo,ADM2,DISTRICT
SM11043,32.8409,34.7032,Sotira,ADM2,DISTRICT
SM18486,33.9337,35.0078,Sotira (Famagusta),ADM2,DISTRICT
SM282667,-98.0304,23.728,Soto La Marina,ADM2,DISTRICT
SM291572,25.7191,45.2118,Sotrile,ADM2,DISTRICT
SM3281,20.75,60.1167,Sottunga,ADM1:ADM2,DISTRICT
SM283004,-89.0044,20.598,Sotuta,ADM2,DISTRICT
SM245484,106.54,14.687,Sou,LANGUAGE,LANGUAGE
SM269810,13.6807,1.7138,Souanké,ADM2,DISTRICT
SM297881,10.5323,35.345,Souassi,ADM2,DISTRICT
SM17645,-60.9988,13.9768,Soucis,ADM2,DISTRICT
SM17646,-61.0542,13.8517,Soufriere Estate,ADM2,DISTRICT
SM3282,-61.0249,13.8454,Soufrière,ADM1,DISTRICT
SM237434,133.95,-1.4961,Sougb,LANGUAGE,LANGUAGE
SM3283,7.9167,36.1667,Souk Ahras,ADM1,DISTRICT
SM297867,8.8362,33.9085,Souk El Ahed,ADM2,DISTRICT
SM297956,9.5672,34.8614,Souk Jedid,ADM2,DISTRICT
SM297862,10.2302,36.879,Soukra,ADM2,DISTRICT
SM262194,-1.25,14.3333,Soum,ADM1:ADM2,DISTRICT
SM11044,32.8844,34.7357,Souni-Zanakia,ADM2,DISTRICT
SM280927,35.2697,33.2136,Sour,ADM2,DISTRICT
SM457689,3.8333,35.5,Sour El Ghozlane District,ADM2,DISTRICT
SM289476,-8.6277,40.0688,Soure,ADM2,DISTRICT
SM264113,-48.6122,-0.4862,Soure,ADM2,DISTRICT
SM262184,-3,13.25,Sourou (1995-),ADM1:ADM2,DISTRICT
SM10253,15.7333,4.7833,Sous-Prefecture de Carnot,ADM2,DISTRICT
SM264344,-38.1861,-6.731,Sousa,ADM2,DISTRICT
SM289291,-7.7179,38.9669,Sousel,ADM2,DISTRICT
SM11045,32.606,34.7357,Souskiou,ADM2,DISTRICT
SM250722,-13.16,10.75,Sousou,ETHNICITY,ETHNICITY
SM250722,-13.16,10.75,Sousou,ETHNICITY,ETHNICITY
SM250722,-13,15,Sousou,ETHNICITY,ETHNICITY
SM250722,-13,15,Sousou,ETHNICITY,ETHNICITY
SM4279,-9.5657,30.4254,Souss-Massa,ADM1,DISTRICT
SM3285,10.4167,35.9167,Sousse,ADM1,DISTRICT
SM297990,10.6,35.8334,Sousse Jaouhara,ADM2,DISTRICT
SM297991,10.6347,35.8335,Sousse Médina,ADM2,DISTRICT
SM18011,10.5811,35.8049,Sousse Riadh (excl. Zaouia-Ksiba-Thrayet),ADM2,DISTRICT
SM297993,10.6407,35.8053,Sousse Sidi Abdelhamid,ADM2,DISTRICT
SM3286,35.3333,33.4167,South,ADM1,DISTRICT
SM277366,88.4317,22.162,South 24 Parganas,ADM2,DISTRICT
SM3288,-77.2436,26.0469,South Abaco,ADM1,DISTRICT
SM275146,27.906,36.1673,South Aegean,ADM1:ADM2,DISTRICT
SM244906,28.1186,-25.9655,South African Sign Language,LANGUAGE,LANGUAGE
SM276808,92.5214,10.7578,South Andaman,ADM2,DISTRICT
SM3289,-77.6489,23.9236,South Andros,ADM1,DISTRICT
SM3290,135,-30,South Australia,ADM1,DISTRICT
SM245199,139.538,-7.363,South Awyu,LANGUAGE,LANGUAGE
SM274733,-4.6667,55.25,South Ayrshire,ADM1:ADM2,DISTRICT
SM244995,48.4302,36.5137,South Azerbaijani,LANGUAGE,LANGUAGE
SM454501,24.3,-25.6,South Bantu,ETHNICITY,ETHNICITY
SM324085,-113.5685,54.7237,South Baptiste,ADM2:ADM3,DISTRICT
SM244943,13.24,46.86,South Bavarian,DIALECT,LANGUAGE
SM245246,-66.3331,-20.9201,South Bolivian Quechua,LANGUAGE,LANGUAGE
SM288565,155.6964,-6.7459,South Bougainville,ADM2,DISTRICT
SM261665,151.6017,-26.4008,South Burnett,ADM2,DISTRICT
SM3292,-81.0001,34.0004,South Carolina,ADM1,DISTRICT
SM245068,21.051,2.9411,South Central Banda,LANGUAGE,LANGUAGE
SM245105,30.3021,6.7307,South Central Dinka,LANGUAGE,LANGUAGE
SM3294,-100.2507,44.5003,South Dakota,ADM1,DISTRICT
SM3295,24.6667,11.5,South Darfur (2012-),ADM1,DISTRICT
SM274963,0.214,6.5646,South Dayi,ADM2,DISTRICT
SM5866,77.2517,28.5301,South Delhi (2012-),ADM2,DISTRICT
SM12062,-6.3774,53.286,South Dublin,ADM2,DISTRICT
SM268535,25.6722,-25.2252,South East,ADM2,DISTRICT
SM5865,77.1364,28.6106,South East Delhi,ADM2,DISTRICT
SM295313,160.341,-9.153,South East Gela,ADM2,DISTRICT
SM346818,21.4044,62.3974,South Eastern Bothnia,ADM2:ADM3,DISTRICT
SM346807,26.0679,61.8058,South Eastern Middle,ADM2:ADM3,DISTRICT
SM11157,38.997,15.271,South Eastern Subregion,ADM2,DISTRICT
SM245123,168.377,-17.7006,South Efate,LANGUAGE,LANGUAGE
SM3296,-76.1831,24.8008,South Eleuthera,ADM1,DISTRICT
SM244980,27,57.85,South Estonian,LANGUAGE,LANGUAGE
SM245063,13.6164,9.5141,South Fali,LANGUAGE,LANGUAGE
SM288615,142.1167,-8.6167,South Fly,ADM2,DISTRICT
SM277083,90.5853,25.3016,South Garo Hills,ADM2,DISTRICT
SM261274,146.067,-38.699,South Gippsland,ADM2,DISTRICT
SM245264,14.1931,10.2809,South Giziga,LANGUAGE,LANGUAGE
SM274689,-2.4167,51.5,South Gloucestershire,ADM1:ADM2,DISTRICT
SM276810,74.1673,15.2043,South Goa,ADM2,DISTRICT
SM4227,-63.0898,18.1918,South Hill,ADM1,DISTRICT
SM280127,37.61,-0.131,South Imenti,ADM2,DISTRICT
SM245422,157.4307,53.0544,South Itelmen,LANGUAGE,LANGUAGE
SM274395,28.1667,60.9667,South Karelia,ADM1:ADM2,DISTRICT
SM3298,59.5,32.5,South Khorasan,ADM1,DISTRICT
SM295328,157.1079,-8.0046,South Kolombangara,ADM2,DISTRICT
SM3299,30.8333,11.2667,"South Kordufan (pre-2005, 2013-)",ADM1,DISTRICT
SM274734,-3.8333,55.5833,South Lanarkshire,ADM1:ADM2,DISTRICT
SM245285,38.0837,31.5601,South Levantine Arabic,LANGUAGE,LANGUAGE
SM245287,16.5391,40.1479,South Lucanian,LANGUAGE,LANGUAGE
SM245130,-138.979,-9.7566,South Marquesan,LANGUAGE,LANGUAGE
SM245198,140.815,-6.0139,South Muyu,LANGUAGE,LANGUAGE
SM257713,-116.56,36.94,South Northern Paiute,ETHNICITY,ETHNICITY
SM257713,-117.26,39.71,South Northern Paiute,ETHNICITY,ETHNICITY
SM257713,-119.53,40.16,South Northern Paiute,ETHNICITY,ETHNICITY
SM257713,-117.32,42.21,South Northern Paiute,ETHNICITY,ETHNICITY
SM245157,129.148,-3.2341,South Nuaulu,LANGUAGE,LANGUAGE
SM261312,115.8722,-31.9988,South Perth,ADM2,DISTRICT
SM295329,156.5767,-8.1076,South Ranongga,ADM2,DISTRICT
SM295330,157.5627,-8.7476,South Rendova,ADM2,DISTRICT
SM245450,-59.0483,3.8581,South Rupununi Sign,LANGUAGE,LANGUAGE
SM244975,13.7006,62.8801,South Saami,LANGUAGE,LANGUAGE
SM11690,89.8887,25.7089,South Salmara Mancachar,ADM2,DISTRICT
SM295302,159.8208,-9.1378,South Savo,ADM2,DISTRICT
SM277216,88.4167,27.3333,South Sikkim,ADM2,DISTRICT
SM10202,-111.8912,60.0026,South Slave Region,ADM2,DISTRICT
SM245217,-117.047,59.8794,South Slavey,LANGUAGE,LANGUAGE
SM245201,145.883,-6.3534,South Tairora,LANGUAGE,LANGUAGE
SM286505,174.3716,-39.5216,South Taranaki,ADM2,DISTRICT
SM454499,11.5,5.5,South Tikar,ETHNICITY,ETHNICITY
SM274964,0.647,5.977,South Tongu,ADM2,DISTRICT
SM277265,91.6095,23.1671,South Tripura (2012-),ADM2,DISTRICT
SM274690,-1.4167,54.95,South Tyneside,ADM1:ADM2,DISTRICT
SM245288,-74.0603,-10.3788,South Ucayali Ashéninka,LANGUAGE,LANGUAGE
SM295408,161.9746,-9.8209,South Ulawa,ADM2,DISTRICT
SM324086,-114.6602,53.6522,South View,ADM2:ADM3,DISTRICT
SM242189,99.375,22.8658,South Wa,LANGUAGE,LANGUAGE
SM286513,175.8862,-38.168,South Waikato,ADM2,DISTRICT
SM286523,175.3844,-41.2673,South Wairarapa,ADM2,DISTRICT
SM245140,146.409,-7.0375,South Watut,LANGUAGE,LANGUAGE
SM245154,128.547,-3.291,South Wemale,LANGUAGE,LANGUAGE
SM5863,77.0672,28.5806,South West Delhi,ADM2,DISTRICT
SM277084,89.934,25.4724,South West Garo Hills,ADM2,DISTRICT
SM295303,160.2626,-9.1266,South West Gela,ADM2,DISTRICT
SM277085,91.2946,25.3215,South West Khasi Hills,ADM2,DISTRICT
SM346824,22.929,61.3616,South Western Pirkanm,ADM2:ADM3,DISTRICT
SM11158,38.832,15.205,South Western Subregion,ADM2,DISTRICT
SM454422,45.021,12.786,South Yemen Arab,ETHNICITY,ETHNICITY
SM235082,14.4771,-5.5772,South-Central Kikongo,LANGUAGE,LANGUAGE
SM3287,25.75,-25,South-East,ADM1,DISTRICT
SM293666,37.75,55.6667,South-Eastern,ADM2,DISTRICT
SM233495,20.7673,-19.688,South-Eastern Ju,LANGUAGE,LANGUAGE
SM244907,146.8854,-42.9886,South-Eastern-Tasmanian-Hinterland,LANGUAGE,LANGUAGE
SM293667,37.5,55.6667,South-Western,ADM2,DISTRICT
SM459117,146.0713,-43.4004,South-western Tasmanian,ETHNICITY,ETHNICITY
SM302955,-77.1061,36.7204,Southampton,ADM2,DISTRICT
SM274692,-1.3833,50.9167,Southampton,ADM1:ADM2,DISTRICT
SM3300,-64.8564,32.2508,Southampton,ADM1,DISTRICT
SM303899,-64.778,17.7096,Southcentral,ADM2,DISTRICT
SM245126,168.211,-16.2975,Southeast Ambrym,LANGUAGE,LANGUAGE
SM245146,129.8,-7.9436,Southeast Babar,LANGUAGE,LANGUAGE
SM301186,-143.2506,63.7506,Southeast Fairbanks,ADM2,DISTRICT
SM245057,6.3219,4.4424,Southeast Ijo,LANGUAGE,LANGUAGE
SM245405,103.8287,31.8524,Southeast Maoxian Qiang,LANGUAGE,LANGUAGE
SM244973,70.3035,34.431,Southeast Pashayi,LANGUAGE,LANGUAGE
SM244973,70.3035,34.431,Southeast Pashayi,LANGUAGE,LANGUAGE
SM244973,70.592,34.666,Southeast Pashayi,LANGUAGE,LANGUAGE
SM244973,70.592,34.666,Southeast Pashayi,LANGUAGE,LANGUAGE
SM7373,20.75,-10.667,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.75,-10.667,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.75,-10.667,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.75,-10.667,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.75,-10.667,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.75,-10.667,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.333,-10.667,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.333,-10.667,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.333,-10.667,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.333,-10.667,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.333,-10.667,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.333,-10.667,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.75,-19.333,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.75,-19.333,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.75,-19.333,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.75,-19.333,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.75,-19.333,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.75,-19.333,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.333,-19.333,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.333,-19.333,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.333,-19.333,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.333,-19.333,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.333,-19.333,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.333,-19.333,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21,-20,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21,-20,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21,-20,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21,-20,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21,-20,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21,-20,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.18,-20,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.18,-20,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.18,-20,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.18,-20,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.18,-20,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.18,-20,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.58,-19.83,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.58,-19.83,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.58,-19.83,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.58,-19.83,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.58,-19.83,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.58,-19.83,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.4524,-18.9373,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.4524,-18.9373,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.4524,-18.9373,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.4524,-18.9373,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.4524,-18.9373,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,21.4524,-18.9373,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.6815,-21.1519,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.6815,-21.1519,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.6815,-21.1519,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.6815,-21.1519,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.6815,-21.1519,Southeastern !Kung,ETHNICITY,ETHNICITY
SM7373,20.6815,-21.1519,Southeastern !Kung,ETHNICITY,ETHNICITY
SM245106,31.285,7.0221,Southeastern Dinka,LANGUAGE,LANGUAGE
SM245260,-96.6199,17.2588,Southeastern Ixtlán Zapotec,LANGUAGE,LANGUAGE
SM244912,78.5936,19.6908,Southeastern Kolami,LANGUAGE,LANGUAGE
SM462079,16.31,-6.89,Southeastern Kongo,ETHNICITY,ETHNICITY
SM462079,13.95,-7.9,Southeastern Kongo,ETHNICITY,ETHNICITY
SM462079,14.4,-5.44,Southeastern Kongo,ETHNICITY,ETHNICITY
SM245257,-97.0145,17.2122,Southeastern Nochixtlán Mixtec,LANGUAGE,LANGUAGE
SM245237,-122.599,38.9354,Southeastern Pomo,LANGUAGE,LANGUAGE
SM245227,-106.706,26.7282,Southeastern Tarahumara,LANGUAGE,LANGUAGE
SM245231,-103.983,23.1584,Southeastern Tepehuan,LANGUAGE,LANGUAGE
SM11215,39.5316,13.248,Southeastern Tigray Zone,ADM2,DISTRICT
SM11215,39.5316,13.248,Southeastern Tigray Zone,ADM2,DISTRICT
SM11215,39.5283,12.9603,Southeastern Tigray Zone,ADM2,DISTRICT
SM11215,39.5283,12.9603,Southeastern Tigray Zone,ADM2,DISTRICT
SM274693,0.7,51.5333,Southend-on-Sea,ADM1:ADM2,DISTRICT
SM293668,37.6,55.6167,Southern,ADM2,DISTRICT
SM3303,114.2077,22.2411,Southern,ADM1,DISTRICT
SM3302,25,-25,Southern,ADM1,DISTRICT
SM3304,-12,7.8,Southern,ADM1:ADMX,DISTRICT
SM461322,50.5833,25.8667,Southern (2014-),ADM1,DISTRICT
SM3305,27,-16.5,"Southern (pre-2012, 2018-)",ADM1,DISTRICT
SM245166,121.054,15.1841,Southern Alta,LANGUAGE,LANGUAGE
SM244993,86.8705,50.1347,Southern Altai,LANGUAGE,LANGUAGE
SM245212,129.285,28.1955,Southern Amami-Oshima,LANGUAGE,LANGUAGE
SM458150,151.3525,-30.7099,Southern Anaiwan,ETHNICITY,ETHNICITY
SM4267,72.83,3.65,Southern Ari Atoll,ADM1:ADM2,DISTRICT
SM226058,103.306,25.395,Southern Awu (Lope),LANGUAGE,LANGUAGE
SM245251,-70.3442,-17.1417,Southern Aymara,LANGUAGE,LANGUAGE
SM245022,100.289,25.519,Southern Bai,LANGUAGE,LANGUAGE
SM244949,64,26,Southern Balochi,LANGUAGE,LANGUAGE
SM245178,47.82,-22.8,Southern Betsimisaraka Malagasy,LANGUAGE,LANGUAGE
SM245286,48.1108,-20.8687,Southern Betsimisaraka Malagasy (Retired),LANGUAGE,LANGUAGE
SM236981,122.769,9.492,Southern Binukidnon,LANGUAGE,LANGUAGE
SM245071,-2.7267,9.2873,Southern Birifor,LANGUAGE,LANGUAGE
SM245112,-4.265,11.5409,Southern Bobo Madaré,LANGUAGE,LANGUAGE
SM245216,-124.452,53.1763,Southern Carrier,LANGUAGE,LANGUAGE
SM257715,-44.75,-15.11,Southern Cayapo,ETHNICITY,ETHNICITY
SM257715,-47.81,-13.38,Southern Cayapo,ETHNICITY,ETHNICITY
SM257715,-49,-16.81,Southern Cayapo,ETHNICITY,ETHNICITY
SM257715,-54.28,-17.67,Southern Cayapo,ETHNICITY,ETHNICITY
SM257715,-50.97,-15.16,Southern Cayapo,ETHNICITY,ETHNICITY
SM245054,149.7169,-37.0623,Southern Coastal Yuin,LANGUAGE,LANGUAGE
SM245247,-76.9854,-9.31,Southern Conchucos Ancash Quechua,LANGUAGE,LANGUAGE
SM243108,-159.78,-21.23,Southern Cook Island Maori,LANGUAGE,LANGUAGE
SM251027,-1.79,9.96,Southern Dagara,ETHNICITY,ETHNICITY
SM251027,-0.96,10.14,Southern Dagara,ETHNICITY,ETHNICITY
SM17907,25,11.5,"Southern Darfur (excl. Nyala, Kas)",ADM2,DISTRICT
SM245031,109.222,25.8874,Southern Dong,LANGUAGE,LANGUAGE
SM261666,151.9217,-28.5027,Southern Downs,ADM2,DISTRICT
SM245233,-78.6971,51.4078,Southern East Cree,LANGUAGE,LANGUAGE
SM257716,-85,51,Southern East Cree,ETHNICITY,ETHNICITY
SM257716,-85,51,Southern East Cree,ETHNICITY,ETHNICITY
SM257716,-77.81,51.08,Southern East Cree,ETHNICITY,ETHNICITY
SM257716,-77.81,51.08,Southern East Cree,ETHNICITY,ETHNICITY
SM245010,84.8511,28.106,Southern Ghale,LANGUAGE,LANGUAGE
SM245006,80.2781,20.1045,Southern Gondi,LANGUAGE,LANGUAGE
SM261275,142.0253,-37.5299,Southern Grampians,ADM2,DISTRICT
SM245098,-7.5687,4.4761,Southern Grebo,LANGUAGE,LANGUAGE
SM244914,106.481,26.3902,Southern Guiyang Hmong,LANGUAGE,LANGUAGE
SM245386,35.51,9.98,Southern Gumuz,LANGUAGE,LANGUAGE
SM245214,-132.008,53.2616,Southern Haida,LANGUAGE,LANGUAGE
SM257717,-132.1,53.09,Southern Haida,ETHNICITY,ETHNICITY
SM3307,143.5833,-6.5,Southern Highlands,ADM1,DISTRICT
SM244969,72.1702,33.2122,Southern Hindko,LANGUAGE,LANGUAGE
SM285336,5.9416,4.6768,Southern Ijaw,ADM2,DISTRICT
SM245053,148.7238,-36.3001,Southern Inland Yuin,LANGUAGE,LANGUAGE
SM233582,97.3278,25.4634,Southern Jinghpaw,LANGUAGE,LANGUAGE
SM245399,106.4732,16.1084,Southern Katang,LANGUAGE,LANGUAGE
SM245377,69.086,59.501,Southern Khanty,LANGUAGE,LANGUAGE
SM245059,-10.3377,8.3245,Southern Kisi,LANGUAGE,LANGUAGE
SM245207,143.244,-8.6489,Southern Kiwai,LANGUAGE,LANGUAGE
SM244947,46.5976,32.8977,Southern Kurdish,LANGUAGE,LANGUAGE
SM245362,99.33,23.5,Southern Lalo,LANGUAGE,LANGUAGE
SM245244,-57.9456,-23.4276,Southern Lengua,LANGUAGE,LANGUAGE
SM3309,125.0833,10.3333,Southern Leyte,ADM1:ADM2,DISTRICT
SM245290,100.89,23.847,Southern Lolopo,LANGUAGE,LANGUAGE
SM244952,50.8382,30.4669,Southern Luri,LANGUAGE,LANGUAGE
SM245100,31.25,2.8735,Southern Ma'di,LANGUAGE,LANGUAGE
SM261733,140.5203,-35.3673,Southern Mallee,ADM2,DISTRICT
SM245401,65.5664,58.4362,Southern Mansi,LANGUAGE,LANGUAGE
SM244915,106.308,25.3359,Southern Mashan Hmong,LANGUAGE,LANGUAGE
SM238191,10.9364,6.6,Southern Mfumte,LANGUAGE,LANGUAGE
SM261772,147.4341,-42.4179,Southern Midlands,ADM2,DISTRICT
SM244991,107.6542,12.0635,Southern Mnong,LANGUAGE,LANGUAGE
SM245016,103.3061,22.8731,Southern Muji,LANGUAGE,LANGUAGE
SM245249,-59.5187,-14.3707,Southern Nambikuára,LANGUAGE,LANGUAGE
SM3310,36.7273,6.0586,"Southern Nations, Nationalities and Peoples",ADM1,DISTRICT
SM245065,19.5939,2.5414,Southern Ngbandi,LANGUAGE,LANGUAGE
SM244988,93.8042,6.8274,Southern Nicobarese,LANGUAGE,LANGUAGE
SM245017,101.412,23.058,Southern Nisu,LANGUAGE,LANGUAGE
SM245074,-2.1439,11.5186,Southern Nuni,LANGUAGE,LANGUAGE
SM245241,-121.4871,36.7871,Southern Ohlone,LANGUAGE,LANGUAGE
SM245190,141.852,-3.4191,Southern One,LANGUAGE,LANGUAGE
SM274404,23,62.75,Southern Ostrobothnia,ADM1:ADM2,DISTRICT
SM458177,142.5651,-32.7216,Southern Paakantyi,ETHNICITY,ETHNICITY
SM245225,-114,37,Southern Paiute,DIALECT:LANGUAGE,LANGUAGE
SM257718,-112.667,36.833,Southern Paiute (Kaibab),ETHNICITY,ETHNICITY
SM257718,-112.667,36.833,Southern Paiute (Kaibab),ETHNICITY,ETHNICITY
SM257718,-113,36,Southern Paiute (Kaibab),ETHNICITY,ETHNICITY
SM257718,-113,36,Southern Paiute (Kaibab),ETHNICITY,ETHNICITY
SM257719,-112.93,36.88,Southern Paiute (Las Vegas),ETHNICITY,ETHNICITY
SM257719,-112.93,36.88,Southern Paiute (Las Vegas),ETHNICITY,ETHNICITY
SM257719,-115,36,Southern Paiute (Las Vegas),ETHNICITY,ETHNICITY
SM257719,-115,36,Southern Paiute (Las Vegas),ETHNICITY,ETHNICITY
SM245254,-99.334,20.966,Southern Pame,LANGUAGE,LANGUAGE
SM244953,68.25,37.17,Southern Pashto,LANGUAGE,LANGUAGE
SM245245,-76.527,-3.3273,Southern Pastaza Quechua,LANGUAGE,LANGUAGE
SM245398,108.093,23.011,Southern Pinghua,LANGUAGE,LANGUAGE
SM346825,23.7187,61.1464,Southern Pirkanmaa,ADM2:ADM3,DISTRICT
SM245239,-122.545,38.7643,Southern Pomo,LANGUAGE,LANGUAGE
SM257720,-122.833,38.25,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-122.833,38.25,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-122.833,38.25,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-122.833,38.25,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-122.94,38.55,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-122.94,38.55,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-122.94,38.55,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-122.94,38.55,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-122.88,38.54,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-122.88,38.54,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-122.88,38.54,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-122.88,38.54,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-123,38.5,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-123,38.5,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-123,38.5,Southern Pomo,ETHNICITY,ETHNICITY
SM257720,-123,38.5,Southern Pomo,ETHNICITY,ETHNICITY
SM4210,80.75,6.1667,Southern Province-Sri Lanka,ADM1,DISTRICT
SM245256,-97.9572,18.2123,Southern Puebla Mixtec,LANGUAGE,LANGUAGE
SM245221,-122.141,47.263,Southern Puget Sound Salish,LANGUAGE,LANGUAGE
SM245021,100.622,27.8408,Southern Pumi,LANGUAGE,LANGUAGE
SM245042,108.533,25.6618,Southern Qiandong Miao,LANGUAGE,LANGUAGE
SM245020,103.384,31.5392,Southern Qiang,LANGUAGE,LANGUAGE
SM4178,35,-15.5,Southern Region,ADM1:ADMX,DISTRICT
SM245024,94.6853,26.1178,Southern Rengma Naga,LANGUAGE,LANGUAGE
SM245259,-96.3775,17.3031,Southern Rincon Zapotec,LANGUAGE,LANGUAGE
SM245262,108.318,11.3003,Southern Roglai,LANGUAGE,LANGUAGE
SM245176,119.4839,4.7558,Southern Sama,LANGUAGE,LANGUAGE
SM245115,-2.9052,12.713,Southern Samo,LANGUAGE,LANGUAGE
SM274388,27.8333,61.75,Southern Savonia,ADM1:ADM2,DISTRICT
SM245410,85.8581,58.6626,Southern Selkup,DIALECT,LANGUAGE
SM245240,-120.507,38.2363,Southern Sierra Miwok,LANGUAGE,LANGUAGE
SM245270,54.01,12.34,Southern Soqotri,DIALECT,LANGUAGE
SM245080,27.89,-28.78,Southern Sotho,LANGUAGE,LANGUAGE
SM234536,94.2473,25.7363,Southern Tangkhul Naga,LANGUAGE,LANGUAGE
SM245035,99.7031,6.8567,Southern Thai,LANGUAGE,LANGUAGE
SM245391,117.75,4,Southern Tidung,LANGUAGE,LANGUAGE
SM245218,-106.661,34.7873,Southern Tiwa,LANGUAGE,LANGUAGE
SM245076,-4.6568,10.877,Southern Toussian,LANGUAGE,LANGUAGE
SM235296,26.0158,-22.0237,Southern Tshwa,LANGUAGE,LANGUAGE
SM257722,24.41,-22.88,Southern Tshwa,ETHNICITY,ETHNICITY
SM245219,-128.53,52.59,Southern Tsimshian,DIALECT,LANGUAGE
SM245030,110.022,28.2368,Southern Tujia,LANGUAGE,LANGUAGE
SM245215,-136.573,60.7823,Southern Tutchone,LANGUAGE,LANGUAGE
SM257723,-109,38,Southern Ute,ETHNICITY,ETHNICITY
SM257723,-109,38,Southern Ute,ETHNICITY,ETHNICITY
SM257723,-109,37.67,Southern Ute,ETHNICITY,ETHNICITY
SM257723,-109,37.67,Southern Ute,ETHNICITY,ETHNICITY
SM244997,60.6907,36.6099,Southern Uzbek,LANGUAGE,LANGUAGE
SM245025,87.3959,26.932,Southern Yamphu,LANGUAGE,LANGUAGE
SM459151,114.9369,-25.706,Southern Yingkarta,ETHNICITY,ETHNICITY
SM245038,154.375,64.218,Southern Yukaghir,LANGUAGE,LANGUAGE
SM454510,151,65.48,Southern Yukaghir,ETHNICITY,ETHNICITY
SM241047,-128.5445,52.591,Southern-Coastal Tsimshian,LANGUAGE,LANGUAGE
SM12741,-77.6699,17.8821,Southfield,ADM2,DISTRICT
SM286503,167.8527,-45.7726,Southland,ADM2,DISTRICT
SM3311,168,-45.6667,Southland,ADM1,DISTRICT
SM303908,-64.8976,18.3207,Southside,ADM2,DISTRICT
SM12742,-76.7862,17.9657,Southside,ADM2,DISTRICT
SM303900,-64.8576,17.6932,Southwest,ADM2,DISTRICT
SM245066,14.9569,4.7004,Southwest Gbaya,LANGUAGE,LANGUAGE
SM245174,117.36,8.6762,Southwest Palawano,LANGUAGE,LANGUAGE
SM244972,69.8734,34.8478,Southwest Pashayi,LANGUAGE,LANGUAGE
SM245133,169.356,-19.5795,Southwest Tanna,LANGUAGE,LANGUAGE
SM245409,47.5655,41.9772,Southwestern Dargwa,LANGUAGE,LANGUAGE
SM245104,28.0613,8.8112,Southwestern Dinka,LANGUAGE,LANGUAGE
SM245044,106.003,26.1803,Southwestern Guiyang Hmong,LANGUAGE,LANGUAGE
SM245043,106.561,26.0436,Southwestern Huishui Hmong,LANGUAGE,LANGUAGE
SM245289,101.412,23.058,Southwestern Nisu,LANGUAGE,LANGUAGE
SM245013,84.9036,27.5722,Southwestern Tamang,LANGUAGE,LANGUAGE
SM245226,-107.578,27.0314,Southwestern Tarahumara,LANGUAGE,LANGUAGE
SM245232,-104.688,23.2553,Southwestern Tepehuan,LANGUAGE,LANGUAGE
SM245255,-97.7974,16.9887,Southwestern Tlaxiaco Mixtec,LANGUAGE,LANGUAGE
SM267677,-41.9482,-11.946,Souto Soares,ADM2,DISTRICT
SM280436,104.1295,13.2322,Soutr Nikom,ADM2,DISTRICT
SM291104,22.8124,44.8484,Sovarna,ADM2,DISTRICT
SM293043,21.8833,55.0833,Sovetsk,ADM2,DISTRICT
SM279981,69.75,54.5,Sovetskiy,ADM2,DISTRICT
SM17952,69.5833,38.0833,Sovetskiy Rayon,ADM2,DISTRICT
SM293623,48.5,56.6667,Sovetskiy rayon,ADM2,DISTRICT
SM294434,46.8333,51.4167,Sovetskiy rayon,ADM2,DISTRICT
SM293285,49,57.5833,Sovetskiy rayon,ADM2,DISTRICT
SM293515,37.5833,51.8333,Sovetskiy rayon,ADM2,DISTRICT
SM292999,85.5833,52.25,Sovetskiy rayon,ADM2,DISTRICT
SM299893,34.9291,45.3152,Soviets'kyi,ADM2,DISTRICT
SM245486,168.1865,-15.8265,Sowa,LANGUAGE,LANGUAGE
SM3312,26.2244,-20.5636,Sowa,ADM1,DISTRICT
SM245487,140.92,-3.2837,Sowanda,LANGUAGE,LANGUAGE
SM245489,-96.4946,18.2868,Soyaltepec Mazatec,LANGUAGE,LANGUAGE
SM245488,-97.1921,17.5892,Soyaltepec Mixtec,LANGUAGE,LANGUAGE
SM283196,-92.9658,16.9135,Soyaló,ADM2,DISTRICT
SM281964,-99.4942,20.0394,Soyaniquilpan de Juárez,ADM2,DISTRICT
SM295520,-89.143,13.7046,Soyapango,ADM2,DISTRICT
SM297792,102.228,13.1703,Soydow,ADM2,DISTRICT
SM260392,12.8309,-6.2467,Soyo,ADM2,DISTRICT
SM282607,-109.6467,28.7987,Soyopa,ADM2,DISTRICT
SM245491,101.0659,52.0406,Soyot,DIALECT,LANGUAGE
SM260207,66.233,36.02,Sozma Qala,ADM2,DISTRICT
SM262394,27.5173,42.3112,Sozopol,ADM2,DISTRICT
SM273468,-79.7833,-4.3333,Sozoranga,ADM2,DISTRICT
SM303474,71.0942,40.0442,So’x,ADM2,DISTRICT
SM300410,-84.2842,33.2609,Spalding,ADM2,DISTRICT
SM12743,-77.458,18.1688,Spaldings,ADM2,DISTRICT
SM9727,44.5,40.1833,Spandaryanskiy Rayon,ADM2,DISTRICT
SM245519,-1.1179,40.4414,Spanish,LANGUAGE,LANGUAGE
SM257727,-4.92,40.27,Spanish,ETHNICITY,ETHNICITY
SM257727,-4.92,40.27,Spanish,ETHNICITY,ETHNICITY
SM257727,-4.92,40.27,Spanish,ETHNICITY,ETHNICITY
SM257727,-4.92,40.27,Spanish,ETHNICITY,ETHNICITY
SM257727,-4.92,40.27,Spanish,ETHNICITY,ETHNICITY
SM257727,-4.92,40.27,Spanish,ETHNICITY,ETHNICITY
SM257727,-6,37,Spanish,ETHNICITY,ETHNICITY
SM257727,-6,37,Spanish,ETHNICITY,ETHNICITY
SM257727,-6,37,Spanish,ETHNICITY,ETHNICITY
SM257727,-6,37,Spanish,ETHNICITY,ETHNICITY
SM257727,-6,37,Spanish,ETHNICITY,ETHNICITY
SM257727,-6,37,Spanish,ETHNICITY,ETHNICITY
SM257727,-4.5,41.4,Spanish,ETHNICITY,ETHNICITY
SM257727,-4.5,41.4,Spanish,ETHNICITY,ETHNICITY
SM257727,-4.5,41.4,Spanish,ETHNICITY,ETHNICITY
SM257727,-4.5,41.4,Spanish,ETHNICITY,ETHNICITY
SM257727,-4.5,41.4,Spanish,ETHNICITY,ETHNICITY
SM257727,-4.5,41.4,Spanish,ETHNICITY,ETHNICITY
SM257727,-4,39.9,Spanish,ETHNICITY,ETHNICITY
SM257727,-4,39.9,Spanish,ETHNICITY,ETHNICITY
SM257727,-4,39.9,Spanish,ETHNICITY,ETHNICITY
SM257727,-4,39.9,Spanish,ETHNICITY,ETHNICITY
SM257727,-4,39.9,Spanish,ETHNICITY,ETHNICITY
SM257727,-4,39.9,Spanish,ETHNICITY,ETHNICITY
SM257727,-1.1,38,Spanish,ETHNICITY,ETHNICITY
SM257727,-1.1,38,Spanish,ETHNICITY,ETHNICITY
SM257727,-1.1,38,Spanish,ETHNICITY,ETHNICITY
SM257727,-1.1,38,Spanish,ETHNICITY,ETHNICITY
SM257727,-1.1,38,Spanish,ETHNICITY,ETHNICITY
SM257727,-1.1,38,Spanish,ETHNICITY,ETHNICITY
SM257727,-6,39,Spanish,ETHNICITY,ETHNICITY
SM257727,-6,39,Spanish,ETHNICITY,ETHNICITY
SM257727,-6,39,Spanish,ETHNICITY,ETHNICITY
SM257727,-6,39,Spanish,ETHNICITY,ETHNICITY
SM257727,-6,39,Spanish,ETHNICITY,ETHNICITY
SM257727,-6,39,Spanish,ETHNICITY,ETHNICITY
SM245493,-3.7074,40.0314,Spanish Sign Language,LANGUAGE,LANGUAGE
SM12744,-76.9229,17.9801,Spanish Town Central,ADM2,DISTRICT
SM3313,-76.7706,25.5454,Spanish Wells,ADM1,DISTRICT
SM289974,26.789,44.1311,Spantov,ADM2,DISTRICT
SM302468,-81.9907,34.9313,Spartanburg,ADM2,DISTRICT
SM293081,34,54.4167,Spas-Demenskiy rayon,ADM2,DISTRICT
SM17092,133,44.5,Spasskiy Rayon,ADM2,DISTRICT
SM294635,49.5,55,Spasskiy rayon,ADM2,DISTRICT
SM293789,45.75,55.8333,Spasskiy rayon,ADM2,DISTRICT
SM11046,33.8815,35.2346,Spathariko,ADM2,DISTRICT
SM323874,-111.6571,51.0673,Special Area No. 2,ADM2:ADM3,DISTRICT
SM323875,-110.662,51.314,Special Area No. 3,ADM2:ADM3,DISTRICT
SM323876,-110.6439,51.9543,Special Area No. 4,ADM2:ADM3,DISTRICT
SM300998,-85.3279,38.0325,Spencer,ADM2,DISTRICT
SM300763,-87.0077,38.0141,Spencer,ADM2,DISTRICT
SM260068,69.4912,33.1566,Spera,ADM2,DISTRICT
SM292535,24.15,47.3,Spermezeu,ADM2,DISTRICT
SM12745,-77.5475,18.4576,Spicy Hill,ADM2,DISTRICT
SM11047,32.9464,34.9707,Spilia,ADM2,DISTRICT
SM260052,66.1697,30.9879,Spin Boldak,ADM2,DISTRICT
SM302530,-98.3462,44.938,Spink,ADM2,DISTRICT
SM292471,22.2022,47.2021,Spinus,ADM2,DISTRICT
SM323808,-118.8366,55.7802,Spirit River,ADM2:ADM3,DISTRICT
SM323809,-118.5167,55.7667,Spirit River No. 133,ADM2:ADM3,DISTRICT
SM294777,35,57.4167,Spirovskiy rayon,ADM2,DISTRICT
SM11048,33.0081,34.7651,Spitali,ADM2,DISTRICT
SM305977,69.3311,40.1049,Spitamen,ADM2,DISTRICT
SM245499,77.833,32.526,Spiti Bhoti,LANGUAGE,LANGUAGE
SM17910,17.71,78.0529,Spitsbergen,ADM2,DISTRICT
SM261799,13.3333,46.85,Spittal an der Drau,ADM2,DISTRICT
SM296135,20.5667,48.95,Spišská Nová Ves,ADM2,DISTRICT
SM276001,16.5,43.5,Split,ADM2,DISTRICT
SM3314,16.5,43.1667,Splitsko-Dalmatinska,ADM1,DISTRICT
SM245500,-118.062,47.9087,Spokane,LANGUAGE,LANGUAGE
SM303094,-117.404,47.6206,Spokane,ADM2,DISTRICT
SM257729,-116.56,47.88,Spokane,ETHNICITY,ETHNICITY
SM12746,-77.7911,18.4787,Spot Valley,ADM2,DISTRICT
SM302956,-77.656,38.185,Spotsylvania,ADM2,DISTRICT
SM291462,24.6398,44.0611,Sprancenata,ADM2,DISTRICT
SM289838,23.7345,45.9683,Spring,ADM2,DISTRICT
SM12747,-76.326,17.96,Spring Bank,ADM2,DISTRICT
SM18485,-76.4298,17.9175,Spring Garden (St. Thomas),ADM2,DISTRICT
SM12748,-77.567,18.2937,Spring Garden (Trelawny),ADM2,DISTRICT
SM12749,-77.4798,18.1623,Spring Ground,ADM2,DISTRICT
SM12750,-76.7291,18.1259,Spring Hill,ADM2,DISTRICT
SM324022,-114.1373,53.519,Spring Lake,ADM2:ADM3,DISTRICT
SM257730,-115,40,Spring Valley Shoshoni,ETHNICITY,ETHNICITY
SM257730,-115,40,Spring Valley Shoshoni,ETHNICITY,ETHNICITY
SM257730,-115,40,Spring Valley Shoshoni,ETHNICITY,ETHNICITY
SM257730,-114.48,39.19,Spring Valley Shoshoni,ETHNICITY,ETHNICITY
SM257730,-114.48,39.19,Spring Valley Shoshoni,ETHNICITY,ETHNICITY
SM257730,-114.48,39.19,Spring Valley Shoshoni,ETHNICITY,ETHNICITY
SM257730,-114.5,39.3,Spring Valley Shoshoni,ETHNICITY,ETHNICITY
SM257730,-114.5,39.3,Spring Valley Shoshoni,ETHNICITY,ETHNICITY
SM257730,-114.5,39.3,Spring Valley Shoshoni,ETHNICITY,ETHNICITY
SM327841,-96.7506,49.9167,Springfield,ADM2:ADM3,DISTRICT
SM12751,-76.7561,17.9712,Springfield (Kingston),ADM2,DISTRICT
SM18484,-77.8739,18.1766,Springfield (Saint Elizabeth),ADM2,DISTRICT
SM257731,-105.76,60.83,Springwoods Cree,ETHNICITY,ETHNICITY
SM324023,-113.9056,53.5493,Spruce Grove,ADM2:ADM3,DISTRICT
SM12752,-77.5566,18.0065,Spur Tree,ADM2,DISTRICT
SM245503,-123.014,49.3231,Squamish,LANGUAGE,LANGUAGE
SM257732,-123.01,49.61,Squamish,ETHNICITY,ETHNICITY
SM257732,-123.01,49.61,Squamish,ETHNICITY,ETHNICITY
SM257732,-123.01,49.61,Squamish,ETHNICITY,ETHNICITY
SM257732,-123.01,49.61,Squamish,ETHNICITY,ETHNICITY
SM257732,-123,50,Squamish,ETHNICITY,ETHNICITY
SM257732,-123,50,Squamish,ETHNICITY,ETHNICITY
SM257732,-123,50,Squamish,ETHNICITY,ETHNICITY
SM257732,-123,50,Squamish,ETHNICITY,ETHNICITY
SM257732,-123.19,49.77,Squamish,ETHNICITY,ETHNICITY
SM257732,-123.19,49.77,Squamish,ETHNICITY,ETHNICITY
SM257732,-123.19,49.77,Squamish,ETHNICITY,ETHNICITY
SM257732,-123.19,49.77,Squamish,ETHNICITY,ETHNICITY
SM257732,-123.3,49.7,Squamish,ETHNICITY,ETHNICITY
SM257732,-123.3,49.7,Squamish,ETHNICITY,ETHNICITY
SM257732,-123.3,49.7,Squamish,ETHNICITY,ETHNICITY
SM257732,-123.3,49.7,Squamish,ETHNICITY,ETHNICITY
SM268760,-123.0028,50.4997,Squamish-Lillooet,ADM2,DISTRICT
SM297061,100.8832,15.2383,Sra Both,ADM2,DISTRICT
SM280525,103.7623,11.1097,Srae Ambel,ADM2,DISTRICT
SM276324,110.935,-7.4128,Sragen,ADM2,DISTRICT
SM257733,-6.41,32.2,Sraghna,ETHNICITY,ETHNICITY
SM245504,-55.33,5.83,Sranan Tongo,LANGUAGE,LANGUAGE
SM305508,16.2714,46.332,Sračinec,ADM2,DISTRICT
SM313193,17.5719,45.0267,Srbac,ADM2:ADM3,DISTRICT
SM304741,20.7833,42.7333,Srbica,ADM2,DISTRICT
SM9865,19.3161,44.0653,Srebrenica (excl. Skelani),ADM2,DISTRICT
SM262395,27.1333,42.2667,Sredets,ADM2,DISTRICT
SM294924,44.8667,48.7167,Sredneakhtubinskiy rayon,ADM2,DISTRICT
SM293615,153.5,64,Srednekanskiy rayon,ADM2,DISTRICT
SM294319,153,68,Srednekolymskiy rayon,ADM2,DISTRICT
SM3316,20.3819,45.3836,Srednje-Banatski,ADM1:ADM2,DISTRICT
SM280475,105.1693,11.8342,Srei Santhor,ADM2,DISTRICT
SM280426,103.5662,13.8352,Srei Snam,ADM2,DISTRICT
SM3317,19.8256,44.9217,Sremski,ADM1:ADM2,DISTRICT
SM225793,142.478,-3.4578,Srenge,LANGUAGE,LANGUAGE
SM295100,117.8333,52.3333,Sretenskiy rayon,ADM2,DISTRICT
SM284375,111.5,1.5,Sri Aman,ADM2,DISTRICT
SM245513,79.864,6.916,Sri Lanka Malay,LANGUAGE,LANGUAGE
SM245510,79.857,7.1059,Sri Lankan Sign Language,LANGUAGE,LANGUAGE
SM257737,80.783,7.8835,Sri Lankan Tamil,ETHNICITY,ETHNICITY
SM297409,101.5418,13.8904,Sri Mahar Pho,ADM2,DISTRICT
SM297564,104.4763,14.8106,Sri Ratana,ADM2,DISTRICT
SM277031,84,18.5,Srikakulam,ADM2,DISTRICT
SM276890,74.8333,34.0833,Srinagar (2006-),ADM2,DISTRICT
SM10110,103.9866,14.2439,Srok Anlong Veng,ADM2,DISTRICT
SM10111,104.3486,10.5339,Srok Damnak Chang'aeur,ADM2,DISTRICT
SM10112,102.5698,13.134,Srok Kamrieng,ADM2,DISTRICT
SM10113,103.2444,12.7248,Srok Koas Krala,ADM2,DISTRICT
SM10114,102.5102,13.2929,Srok Phnum Proek,ADM2,DISTRICT
SM10115,103.4041,12.6604,Srok Rukh Kiri,ADM2,DISTRICT
SM10116,102.5514,13.3842,Srok Sampou Lun,ADM2,DISTRICT
SM10118,105.5593,11.9998,Srok Tbong Khmum (excl. Krong Suong),ADM2,DISTRICT
SM10119,104.3084,14.2581,Srok Trapeang Prasat,ADM2,DISTRICT
SM257741,31.76,1.01,Ssingo,ETHNICITY,ETHNICITY
SM5993,-61.4667,10.6833,St Anns,ADM1:ADM2,DISTRICT
SM17647,-60.9073,13.7874,St Helen Estate,ADM2,DISTRICT
SM17648,-60.9736,13.7587,St Jude'S Highway,ADM2,DISTRICT
SM17649,-61.025,13.9385,St Lawrence,ADM2,DISTRICT
SM17650,-61.0425,13.9424,St Lawrence Estate,ADM2,DISTRICT
SM280934,-10.7347,6.5052,St Paul River and Commonwealth,ADM2,DISTRICT
SM17651,-61.0277,13.8443,St Phillip,ADM2,DISTRICT
SM17652,-61.0459,13.8177,St Remy Estate,ADM2,DISTRICT
SM324024,-113.6386,53.6404,St. Albert,ADM2:ADM3,DISTRICT
SM327846,-97.0006,50.25,St. Andrews,ADM2:ADM3,DISTRICT
SM12753,-77.2078,18.4299,St. Ann's Bay,ADM2,DISTRICT
SM257744,-57.25,51.63,St. Augustin,ETHNICITY,ETHNICITY
SM245544,-62.8316,17.9036,St. Barth Creole French,DIALECT,LANGUAGE
SM327847,-96.7512,50.1642,St. Clements,ADM2:ADM3,DISTRICT
SM12754,-77.363,18.333,St. D'Acre,ADM2,DISTRICT
SM327636,-97.5434,49.9312,St. François Xavier,ADM2:ADM3,DISTRICT
SM245550,-5.7121,-15.9656,St. Helena English,DIALECT,LANGUAGE
SM12755,-76.9747,18.0009,St. John's East,ADM2,DISTRICT
SM12756,-77.0076,18.0054,St. John's West,ADM2,DISTRICT
SM17653,-60.9144,13.9007,St. Joseph Estate,ADM2,DISTRICT
SM16220,-66.0025,18.3698,St. Just Barrio,ADM2,DISTRICT
SM327922,-97.834,50.3333,St. Laurent,ADM2:ADM3,DISTRICT
SM12757,-77.8889,18.2553,St. Leonards,ADM2,DISTRICT
SM12758,-76.5289,18.1908,St. Margaret's Bay,ADM2,DISTRICT
SM257745,-65.53,51.27,St. Marguerite,ETHNICITY,ETHNICITY
SM324056,-111.2892,53.9899,St. Paul,ADM2:ADM3,DISTRICT
SM461344,-10.7347,6.5052,St. Paul River (excl. commonwealth),ADM2,DISTRICT
SM12759,-77.612,18.1314,St. Paul's,ADM2,DISTRICT
SM12760,-76.672,18.0788,St. Peter's,ADM2,DISTRICT
SM12761,-77.5479,18.3342,St. Vincent,ADM2,DISTRICT
SM13839,26.7439,56.4427,Stabulnieku pagasts,ADM2,DISTRICT
SM13840,25.5154,56.5424,Staburaga pagasts,ADM2,DISTRICT
SM15306,6.0103,61.9363,Stad,ADM2,DISTRICT
SM12762,-76.7764,17.995,Stadium Gardens,ADM2,DISTRICT
SM285839,6.9891,53.0001,Stadskanaal,ADM2,DISTRICT
SM370491,6.3644,49.5628,Stadtbredimus,ADM1:ADM2:ADM3,DISTRICT
SM296447,13.2,55.65,Staffanstorp,ADM2,DISTRICT
SM302957,-77.4574,38.4207,Stafford,ADM2,DISTRICT
SM300981,-98.7174,38.031,Stafford,ADM2,DISTRICT
SM274694,-2,52.8333,Staffordshire,ADM1:ADM2,DISTRICT
SM13841,24.7588,57.8356,Staicele,ADM2,DISTRICT
SM13842,24.7317,57.8902,Staiceles pagasts,ADM2,DISTRICT
SM299571,38.6214,48.5625,Stakhanivs'ka,ADM2,DISTRICT
SM13843,25.0366,57.4213,Stalbes pagasts,ADM2,DISTRICT
SM257746,-122.76,49.16,Stalo,ETHNICITY,ETHNICITY
SM257746,-122.76,49.16,Stalo,ETHNICITY,ETHNICITY
SM257746,-122.76,49.16,Stalo,ETHNICITY,ETHNICITY
SM257746,-122.76,49.16,Stalo,ETHNICITY,ETHNICITY
SM257746,-122,49,Stalo,ETHNICITY,ETHNICITY
SM257746,-122,49,Stalo,ETHNICITY,ETHNICITY
SM257746,-122,49,Stalo,ETHNICITY,ETHNICITY
SM257746,-122,49,Stalo,ETHNICITY,ETHNICITY
SM257746,-122.7,49.23,Stalo,ETHNICITY,ETHNICITY
SM257746,-122.7,49.23,Stalo,ETHNICITY,ETHNICITY
SM257746,-122.7,49.23,Stalo,ETHNICITY,ETHNICITY
SM257746,-122.7,49.23,Stalo,ETHNICITY,ETHNICITY
SM257746,-121.5,49.4,Stalo,ETHNICITY,ETHNICITY
SM257746,-121.5,49.4,Stalo,ETHNICITY,ETHNICITY
SM257746,-121.5,49.4,Stalo,ETHNICITY,ETHNICITY
SM257746,-121.5,49.4,Stalo,ETHNICITY,ETHNICITY
SM289019,22.052,50.591,Stalowa Wola,ADM2,DISTRICT
SM291290,24.9931,45.0567,Stalpeni,ADM2,DISTRICT
SM289923,26.7084,45.0852,Stalpu,ADM2,DISTRICT
SM262348,24.5167,42.1167,Stamboliyski,ADM2,DISTRICT
SM262525,25.7,41.7333,Stambolovo,ADM2,DISTRICT
SM13844,26.9364,57.2461,Stamerienas pagasts,ADM2,DISTRICT
SM9866,17.8355,44.7678,Stanari,ADM2,DISTRICT
SM291198,25.2336,46.9597,Stanceni,ADM2,DISTRICT
SM292692,27.7961,44.8889,Stancuta,ADM2,DISTRICT
SM323885,-112.9849,51.1111,Standard,ADM2:ADM3,DISTRICT
SM245538,43.8525,27.9625,Standard Arabic,LANGUAGE,LANGUAGE
SM245542,24,57,Standard Latvian,DIALECT,LANGUAGE
SM245534,101.699,3.089,Standard Malay,LANGUAGE,LANGUAGE
SM292239,24.0426,44.8136,Stanesti,ADM2,DISTRICT
SM290658,23.2533,45.1305,Stanesti,ADM2,DISTRICT
SM290595,25.8797,43.9334,Stanesti,ADM2,DISTRICT
SM291105,23.2926,44.6002,Stangaceaua,ADM2,DISTRICT
SM286363,11.1907,60.7158,Stange,ADM2,DISTRICT
SM292310,28.1615,46.6503,Stanilesti,ADM2,DISTRICT
SM292158,27.3106,46.4276,Stanisesti,ADM2,DISTRICT
SM303208,-120.9977,37.5591,Stanislaus,ADM2,DISTRICT
SM291368,27.137,47.0122,Stanita,ADM2,DISTRICT
SM305600,15.6908,43.9059,Stankovci,ADM2,DISTRICT
SM302531,-100.7359,44.4123,Stanley,ADM2,DISTRICT
SM327754,-98.0866,49.1686,Stanley,ADM2:ADM3,DISTRICT
SM302013,-80.2509,35.312,Stanly,ADM2,DISTRICT
SM3318,-88.4667,16.8333,Stann Creek,ADM1,DISTRICT
SM293571,38.3333,52.8333,Stanovlyanskiy rayon,ADM2,DISTRICT
SM301760,-97.1939,41.9169,Stanton,ADM2,DISTRICT
SM300982,-101.7842,37.5631,Stanton,ADM2,DISTRICT
SM285570,6.2074,52.6324,Staphorst,ADM2,DISTRICT
SM3319,22.7,41.2333,Star Dojran,ADM1,DISTRICT
SM295409,162.2015,-10.7193,Star Harbour North,ADM2,DISTRICT
SM295410,162.1019,-10.7427,Star Harbour South,ADM2,DISTRICT
SM305574,17.2445,45.1487,Stara Gradiška,ADM2,DISTRICT
SM262440,25.6333,42.4167,Stara Zagora,ADM2,DISTRICT
SM3320,25.5667,42.45,Stara Zagora,ADM1,DISTRICT
SM288755,21.143,51.0537,Starachowice,ADM2,DISTRICT
SM13341,-155.8953,-5.6363,Starbuck,ADM2,DISTRICT
SM288829,15.2268,53.3152,Stargard,ADM2,DISTRICT
SM276002,16.5969,43.1861,Stari Grad,ADM2,DISTRICT
SM305546,18.9102,45.259,Stari Jankovci,ADM2,DISTRICT
SM305547,18.5583,45.2856,Stari Mikanovci,ADM2,DISTRICT
SM305601,15.4394,44.2967,Starigrad,ADM2,DISTRICT
SM294778,34.9167,56.5,Staritskiy rayon,ADM2,DISTRICT
SM302069,-102.6551,46.8107,Stark,ADM2,DISTRICT
SM302157,-81.3656,40.8139,Stark,ADM2,DISTRICT
SM300679,-89.7975,41.0934,Stark,ADM2,DISTRICT
SM300764,-86.6477,41.2809,Starke,ADM2,DISTRICT
SM323886,-112.5575,51.6634,Starland County,ADM2:ADM3,DISTRICT
SM3321,21.9333,42.2333,Staro Nagoričane,ADM1,DISTRICT
SM305575,17.5202,45.2287,Staro Petrovo Selo,ADM2,DISTRICT
SM299962,37.9167,47.75,Starobeshivs'kyi,ADM2,DISTRICT
SM295020,32.75,52.5,Starodubskiy rayon,ADM2,DISTRICT
SM288704,18.3991,53.8991,Starogard,ADM2,DISTRICT
SM299455,27.2048,49.7549,Starokostiantynivs'kyi,ADM2,DISTRICT
SM294861,47.5,52.75,Starokulatkinskiy rayon,ADM2,DISTRICT
SM293395,39.1135,46.5226,Starominskiy rayon,ADM2,DISTRICT
SM17093,38,51.25,Starooskolsky Urban Okrug,ADM2,DISTRICT
SM294925,46.8333,50.4167,Staropoltavskiy rayon,ADM2,DISTRICT
SM17094,31.4167,58,Starorusskiy Rayon,ADM2,DISTRICT
SM293647,44.5,54.3333,Staroshaygovskiy rayon,ADM2,DISTRICT
SM299457,27.6147,49.5973,Starosyniavs'kyi,ADM2,DISTRICT
SM302767,-98.7384,26.5622,Starr,ADM2,DISTRICT
SM262652,28.1667,53,Staryya Darohi,ADM2,DISTRICT
SM296153,20.7,49.3,Stará Lubovna,ADM2,DISTRICT
SM296344,15.7552,46.4553,Starše,ADM1:ADM2,DISTRICT
SM288756,21.1959,50.5174,Staszów,ADM2,DISTRICT
SM11049,32.6034,34.8825,Statos-Agios Fotios,ADM2,DISTRICT
SM232477,101.72,31.1026,Stau-Dgebshes,LANGUAGE,LANGUAGE
SM292598,26.7747,47.7214,Stauceni,ADM2,DISTRICT
SM302958,-79.0596,38.1493,Staunton,ADM2,DISTRICT
SM461877,5.7286,58.9694,Stavanger (2020-),ADM2,DISTRICT
SM323856,-113.6391,50.1615,Stavely,ADM2:ADM3,DISTRICT
SM11050,32.616,34.7881,Stavrokonnou,ADM2,DISTRICT
SM3322,44,45,Stavropol',ADM1,DISTRICT
SM10196,-96.5006,49.5833,Ste. Anne,ADM2,DISTRICT
SM10197,-99.4172,50.9997,Ste. Rose (2015-),ADM2,DISTRICT
SM301409,-94.613,45.5522,Stearns,ADM2,DISTRICT
SM286011,5.2239,52.6962,Stede Broec,ADM2,DISTRICT
SM302070,-97.7247,47.4562,Steele,ADM2,DISTRICT
SM301410,-93.226,44.0223,Steele,ADM2,DISTRICT
SM285953,4.3208,51.6008,Steenbergen,ADM2,DISTRICT
SM285571,6.0403,52.7559,Steenwijkerland,ADM2,DISTRICT
SM12763,-77.171,18.4285,Steer Town,ADM2,DISTRICT
SM292311,27.6294,46.7156,Stefan Cel Mare,ADM2,DISTRICT
SM291370,26.5129,46.986,Stefan Cel Mare,ADM2,DISTRICT
SM291463,24.2226,43.8117,Stefan Cel Mare,ADM2,DISTRICT
SM291291,25.2471,44.492,Stefan Cel Mare,ADM2,DISTRICT
SM289976,27.3251,44.331,Stefan Voda,ADM2,DISTRICT
SM292240,24.2334,44.6054,Stefanesti,ADM2,DISTRICT
SM292599,27.206,47.7883,Stefanesti,ADM2,DISTRICT
SM291293,24.9333,44.8667,Stefanesti,ADM2,DISTRICT
SM290975,26.1833,44.5333,Stefanestii De Jos,ADM2,DISTRICT
SM291574,25.8988,45.2423,Stefesti,ADM2,DISTRICT
SM292472,22.458,46.5377,Stei,ADM2,DISTRICT
SM3324,15.1667,47.25,Steiermark,ADM1,DISTRICT
SM286085,15.0162,67.7763,Steigen,ADM2,DISTRICT
SM268946,8.8468,47.6643,Stein,ADM2,DISTRICT
SM285886,5.7613,50.9736,Stein,ADM2,DISTRICT
SM370495,5.9192,49.6614,Steinfort,ADM1:ADM2:ADM3,DISTRICT
SM461899,11.3851,64.193,Steinkjer (2020-),ADM2,DISTRICT
SM370527,6.1239,49.6769,Steinsel,ADM1:ADM2:ADM3,DISTRICT
SM290659,23.7057,44.7736,Stejari,ADM2,DISTRICT
SM292074,28.54,44.7853,Stejaru,ADM2,DISTRICT
SM291935,24.8821,44.1772,Stejaru,ADM2,DISTRICT
SM245545,6.0911,52.8933,Stellingwerfs,DIALECT,LANGUAGE
SM290934,27.8882,44.4258,Stelnica,ADM2,DISTRICT
SM13845,24.51,56.5245,Stelpes pagasts,ADM2,DISTRICT
SM13846,22.5384,57.1424,Stende,ADM2,DISTRICT
SM11051,32.4677,34.9986,Steni,ADM2,DISTRICT
SM296597,11.9047,58.0625,Stenungsund,ADM2,DISTRICT
SM302768,-98.8362,32.7359,Stephens,ADM2,DISTRICT
SM302237,-97.8515,34.4856,Stephens,ADM2,DISTRICT
SM300411,-83.2934,34.5539,Stephens,ADM2,DISTRICT
SM300681,-89.6624,42.3518,Stephenson,ADM2,DISTRICT
SM13262,71.973,52.3623,Stepnogorsk Qalasy,ADM2,DISTRICT
SM294287,55.25,53.4167,Sterlibashevskiy rayon,ADM2,DISTRICT
SM302769,-101.05,31.8279,Sterling,ADM2,DISTRICT
SM294288,55.75,53.6667,Sterlitamakskiy rayon,ADM2,DISTRICT
SM12764,-77.541,18.2822,Stettin,ADM2,DISTRICT
SM323943,-112.7215,52.3218,Stettler,ADM2:ADM3,DISTRICT
SM323944,-112.6008,52.2307,Stettler County No. 6,ADM2:ADM3,DISTRICT
SM301914,-77.3838,42.2678,Steuben,ADM2,DISTRICT
SM300765,-85.0008,41.6439,Steuben,ADM2,DISTRICT
SM303095,-117.8551,48.3991,Stevens,ADM2,DISTRICT
SM301411,-96.0003,45.5861,Stevens,ADM2,DISTRICT
SM300983,-101.3121,37.1923,Stevens,ADM2,DISTRICT
SM271726,12.3069,55.3337,Stevns (2007-),ADM2,DISTRICT
SM302623,-87.8385,36.5011,Stewart,ADM2,DISTRICT
SM300412,-84.8352,32.0785,Stewart,ADM2,DISTRICT
SM12765,-77.4519,18.3989,Stewart Town,ADM2,DISTRICT
SM261833,14.4167,48.0333,Steyr,ADM2,DISTRICT
SM261832,14.4667,47.9,Steyr Land,ADM2,DISTRICT
SM15175,5.0126,52.1768,Stichtse Vecht,ADM2,DISTRICT
SM268761,-128.7598,58.8592,Stikine,ADM2,DISTRICT
SM257748,-131.76,56.11,Stikine,ETHNICITY,ETHNICITY
SM257749,-123.79,45.39,Stiletz,ETHNICITY,ETHNICITY
SM301664,-109.3948,45.6694,Stillwater,ADM2,DISTRICT
SM274736,-4.2339,56.1153,Stirling,ADM1:ADM2,DISTRICT
SM323833,-112.528,49.5018,Stirling,ADM2:ADM3,DISTRICT
SM261313,115.8093,-31.8794,Stirling,ADM2,DISTRICT
SM10537,18.6384,45.0962,Stitar,ADM2,DISTRICT
SM292600,26.7642,47.979,Stiubieni,ADM2,DISTRICT
SM292024,21.9799,45.5628,Stiuca,ADM2,DISTRICT
SM280976,-10.0327,6.0587,Stjohnriver,ADM2,DISTRICT
SM286462,11.0273,63.567,Stjørdal,ADM2,DISTRICT
SM296479,18.0643,59.3248,Stockholm,ADM2,DISTRICT
SM3327,18,59.5,Stockholm,ADM1,DISTRICT
SM274695,-2.1667,53.4167,Stockport,ADM1:ADM2,DISTRICT
SM274696,-1.3333,54.55,Stockton-on-Tees,ADM1:ADM2,DISTRICT
SM245558,77.116,32.497,Stod Bhoti,LANGUAGE,LANGUAGE
SM301513,-89.9443,36.8556,Stoddard,ADM2,DISTRICT
SM244303,102.63,32.225,Stodsde,LANGUAGE,LANGUAGE
SM292241,24.1634,45.1472,Stoenesti,ADM2,DISTRICT
SM291464,24.4972,44.1168,Stoenesti,ADM2,DISTRICT
SM290596,25.8935,44.1416,Stoenesti,ADM2,DISTRICT
SM291294,25.1767,45.2643,Stoenesti,ADM2,DISTRICT
SM291465,24.6427,44.1857,Stoicanesti,ADM2,DISTRICT
SM292242,24.3599,44.9146,Stoilesti,ADM2,DISTRICT
SM290660,23.6456,44.68,Stoina,ADM2,DISTRICT
SM274697,-2.1667,53,Stoke-on-Trent,ADM1:ADM2,DISTRICT
SM302014,-80.2396,36.4019,Stokes,ADM2,DISTRICT
SM13847,27.5692,56.4114,Stolerovas pagasts,ADM2,DISTRICT
SM262514,23.3056,42.6865,Stolichna,ADM2,DISTRICT
SM262574,27,52,Stolin,ADM2,DISTRICT
SM290874,26.7353,47.1856,Stolniceni-prajescu,ADM2,DISTRICT
SM291295,24.7734,44.5808,Stolnici,ADM2,DISTRICT
SM305640,17.6919,42.8397,Ston,ADM2,DISTRICT
SM301514,-93.456,36.7469,Stone,ADM2,DISTRICT
SM301488,-89.1177,30.79,Stone,ADM2,DISTRICT
SM302417,-92.1567,35.8599,Stone,ADM2,DISTRICT
SM17654,-61.0599,13.8451,Stonefield,ADM2,DISTRICT
SM12766,-77.8504,18.2423,Stonehenge,ADM2,DISTRICT
SM302770,-100.2533,33.1791,Stonewall,ADM2,DISTRICT
SM245560,-114.984,51.1939,Stoney,LANGUAGE,LANGUAGE
SM4228,-63.0447,18.2211,Stoney Ground,ADM1,DISTRICT
SM261276,145.0378,-37.8613,Stonnington,ADM2,DISTRICT
SM12767,-76.7845,18.0791,Stony Hill,ADM2,DISTRICT
SM324026,-113.9973,53.5292,Stony Plain,ADM2:ADM3,DISTRICT
SM4017,24.3236,56.9378,Stopinu Novads,ADM1,DISTRICT
SM13848,24.3177,56.9301,Stopinu novads,ADM2,DISTRICT
SM286364,11.0476,61.572,Stor-Elvdal,ADM2,DISTRICT
SM286396,5.4998,59.7809,Stord,ADM2,DISTRICT
SM301777,-119.5292,39.4465,Storey,ADM2,DISTRICT
SM286237,19.9609,69.2686,Storfjord,ADM2,DISTRICT
SM296508,14.2473,59.4948,Storfors,ADM2,DISTRICT
SM268883,-74.666,45.2501,"Stormont, Dundas and Glengarry",ADM2,DISTRICT
SM291936,25.4562,43.8797,Storobaneasa,ADM2,DISTRICT
SM299878,25.7233,48.161,Storozhynets'kyi,ADM2,DISTRICT
SM296521,16.2581,65.4519,Storuman,ADM2,DISTRICT
SM300867,-93.465,42.0362,Story,ADM2,DISTRICT
SM280498,104.4878,12.8992,Stoung,ADM2,DISTRICT
SM262653,26.6667,53.5833,Stowptsy,ADM2,DISTRICT
SM13849,26.8575,57.1077,Stradu pagasts,ADM2,DISTRICT
SM301812,-71.0294,43.2974,Strafford,ADM2,DISTRICT
SM305349,16.414,46.3716,Strahoninec,ADM2,DISTRICT
SM291847,25.5528,47.9183,Straja,ADM2,DISTRICT
SM271152,13.9,49.2667,Strakonice,ADM2,DISTRICT
SM262499,26.7833,42.4833,Straldzha,ADM2,DISTRICT
SM291041,24.1027,47.7516,Stramtura,ADM2,DISTRICT
SM461879,6.0434,59.0235,Strand (2020-),ADM2,DISTRICT
SM286431,6.9312,62.3113,Stranda,ADM2,DISTRICT
SM11625,-21.9572,65.7746,Strandabyggd,ADM2,DISTRICT
SM292377,27.0436,45.929,Straoane,ADM2,DISTRICT
SM370528,6.0733,49.6206,Strassen,ADM1:ADM2:ADM3,DISTRICT
SM286506,174.2833,-39.3333,Stratford,ADM2,DISTRICT
SM12768,-78.1171,18.2386,Strathbogie,ADM2,DISTRICT
SM261277,145.3294,-36.724,Strathbogie,ADM2,DISTRICT
SM324028,-113.1001,53.5857,Strathcona County,ADM2:ADM3,DISTRICT
SM10173,-125.5147,50.2121,Strathcona Regional District,ADM2,DISTRICT
SM261463,151.0756,-33.8798,Strathfield,ADM2,DISTRICT
SM323887,-113.396,51.0479,Strathmore,ADM2:ADM3,DISTRICT
SM13850,24.9626,57.327,Straupes pagasts,ADM2,DISTRICT
SM12769,-78.0895,18.3068,Strawberry,ADM2,DISTRICT
SM13851,22.7567,57.1397,Strazdes pagasts,ADM2,DISTRICT
SM262472,25.9833,43.2667,Strazhitsa,ADM2,DISTRICT
SM261734,134.5512,-32.7381,Streaky Bay,ADM2,DISTRICT
SM291106,23.1879,44.6236,Strehaia,ADM2,DISTRICT
SM291466,24.2704,44.5273,Strejesti,ADM2,DISTRICT
SM291575,25.9308,44.8798,Strejnicu,ADM2,DISTRICT
SM262314,24.3667,42.4667,Strelcha,ADM2,DISTRICT
SM289839,23.5934,46.2561,Stremt,ADM2,DISTRICT
SM13852,25.6963,57.628,Strenci,ADM2,DISTRICT
SM4039,25.7831,57.6261,Strencu Novads,ADM1,DISTRICT
SM3331,-7,62.1333,Streymoyar,ADM1,DISTRICT
SM305386,18.4309,45.2263,Strizivojna,ADM2,DISTRICT
SM292243,23.9265,45.0752,Stroesti,ADM2,DISTRICT
SM291848,26.1274,47.6194,Stroiesti,ADM2,DISTRICT
SM11052,33.647,35.1725,Strongylos,ADM2,DISTRICT
SM296154,21.6503,49.2025,Stropkov,ADM2,DISTRICT
SM11053,32.4786,34.8914,Stroumpi,ADM2,DISTRICT
SM11054,33.3477,35.1335,Strovolos,ADM2,DISTRICT
SM271697,8.5833,56.4667,Struer (2007-),ADM2,DISTRICT
SM3332,20.6336,41.2814,Struga,ADM1,DISTRICT
SM292160,26.7358,46.5272,Strugari,ADM2,DISTRICT
SM294131,29,58.3333,Strugo-Krasnenskiy rayon,ADM2,DISTRICT
SM12770,-77.9566,18.2532,Struie,ADM2,DISTRICT
SM3333,22.6464,41.4111,Strumitsa,ADM1,DISTRICT
SM262292,23.2,41.6333,Strumyani,ADM2,DISTRICT
SM290875,26.944,47.1602,Strunga,ADM2,DISTRICT
SM13853,27.2052,56.7145,Struzanu pagasts,ADM2,DISTRICT
SM299541,23.8407,49.2608,Stryis'ka,ADM2,DISTRICT
SM461903,6.724,61.9041,Stryn (2019-),ADM2,DISTRICT
SM288996,18.346,50.5306,Strzelce,ADM2,DISTRICT
SM288926,15.6406,52.9122,Strzelce-Drezdenko,ADM2,DISTRICT
SM288662,17.1063,50.7669,Strzelin,ADM2,DISTRICT
SM289020,21.7495,49.877,Strzyżów,ADM2,DISTRICT
SM296467,17.088,59.3611,Strängnäs,ADM2,DISTRICT
SM296598,11.2554,58.9489,Strömstad,ADM2,DISTRICT
SM296642,15.2937,64.2488,Strömsund,ADM2,DISTRICT
SM3329,28.5833,47.1667,Străşeni,ADM1,DISTRICT
SM454372,-0.167,52.5,Stuart Britain,ETHNICITY,ETHNICITY
SM327662,-96.5173,49.1333,Stuartburn,ADM2:ADM3,DISTRICT
SM305739,15.9326,45.9754,Stubičke Toplice,ADM2,DISTRICT
SM3334,21.4333,41.8333,Studeničani,ADM1,DISTRICT
SM280499,104.87,12.63,Stueng Saen,ADM2,DISTRICT
SM280440,106.07,13.65,Stueng Traeng,ADM2,DISTRICT
SM280467,105.5612,12.3558,Stueng Trang,ADM2,DISTRICT
SM280367,103.7402,10.8525,Stueng hav,ADM2,DISTRICT
SM291849,25.7571,47.4333,Stulpicani,ADM2,DISTRICT
SM17095,38.0833,54.9167,Stupinskiy Rayon,ADM2,DISTRICT
SM305655,15.8408,45.7531,Stupnik,ADM2,DISTRICT
SM12771,-77.3199,18.4163,Sturge Town,ADM2,DISTRICT
SM10168,-113.522,53.8182,Sturgeon County,ADM2,DISTRICT
SM302071,-98.9588,46.9793,Stutsman,ADM2,DISTRICT
SM11626,-22.7718,65.0537,Stykkisholmsbaer,ADM2,DISTRICT
SM11055,33.8293,35.1644,Stylloi,ADM2,DISTRICT
SM3328,106.25,13.75,Stœng Trêng (1980-),ADM4:ADM1,DISTRICT
SM3330,14.1964,50.1272,Středočeský,ADM1,DISTRICT
SM297258,101.9982,6.0767,Su-ngai Ko Lok,ADM2,DISTRICT
SM261202,-170.6282,-14.2774,Sua,ADM2,DISTRICT
SM245568,132.157,-2.0797,Suabo,LANGUAGE,LANGUAGE
SM17963,125.25,-9.3,Suai,ADM2,DISTRICT
SM270517,-73.3701,6.0822,Suaita,ADM2,DISTRICT
SM14108,-9.6255,6.9262,Suakoko (excl. Yeallequelleh),ADM2,DISTRICT
SM4400,-3.0248,6.1157,Suaman,ADM2,DISTRICT
SM289135,126.4017,38.6961,Suan,ADM2,DISTRICT
SM270671,-74.9193,10.3021,Suan,ADM2,DISTRICT
SM297181,100.6268,13.7299,Suan Luang,ADM2,DISTRICT
SM297455,99.3113,13.5636,Suan Phung,ADM2,DISTRICT
SM245570,94.5803,25.4333,Suansu,LANGUAGE,LANGUAGE
SM282608,-109.897,28.3888,Suaqui Grande,ADM2,DISTRICT
SM290135,23.9582,46.7537,Suatu,ADM2,DISTRICT
SM245572,150.21,-10.6606,Suau,LANGUAGE,LANGUAGE
SM257751,150.2,-10.41,Suau,ETHNICITY,ETHNICITY
SM257751,150.2,-10.41,Suau,ETHNICITY,ETHNICITY
SM257751,149.91,-10.41,Suau,ETHNICITY,ETHNICITY
SM257751,149.91,-10.41,Suau,ETHNICITY,ETHNICITY
SM270103,-75.8116,1.8784,Suaza,ADM2,DISTRICT
SM245575,34.1033,-0.9572,Suba,LANGUAGE,LANGUAGE
SM280301,34.223,-0.58,Suba,ADM2,DISTRICT
SM245576,34.268,-1.1033,Suba-Simbiti,LANGUAGE,LANGUAGE
SM10475,-74.1612,4.9781,Subachoque (1997-),ADM2,DISTRICT
SM276770,107.7025,-6.5083,Subang,ADM2,DISTRICT
SM257753,122.66,8.33,Subanun,ETHNICITY,ETHNICITY
SM257753,122.66,8.33,Subanun,ETHNICITY,ETHNICITY
SM257753,123,8,Subanun,ETHNICITY,ETHNICITY
SM257753,123,8,Subanun,ETHNICITY,ETHNICITY
SM277128,83.8249,20.9315,Subarnapur,ADM2,DISTRICT
SM13854,25.9107,56.0075,Subate,ADM2,DISTRICT
SM290721,25.4065,46.8565,Subcetate,ADM2,DISTRICT
SM245584,31.3594,-2.9339,Subi,LANGUAGE,LANGUAGE
SM261314,115.8156,-31.9596,Subiaco,ADM2,DISTRICT
SM245583,24.8867,-17.5696,Subiya,LANGUAGE,LANGUAGE
SM250706,25.21,-17.12,Subiya,ETHNICITY,ETHNICITY
SM303304,-109.9147,42.7669,Sublette,ADM2,DISTRICT
SM245587,-86.9166,12.4667,Subtiaba,LANGUAGE,LANGUAGE
SM295443,160.9088,-8.6249,Sububenu-Burianias,ADM2,DISTRICT
SM276295,97.9333,2.75,Subulussalam,ADM2,DISTRICT
SM12772,-78.0511,18.3773,Success,ADM2,DISTRICT
SM291850,26.2811,47.6746,Suceava,ADM2,DISTRICT
SM3335,25.75,47.5833,Suceava,ADM1,DISTRICT
SM290468,28.0575,45.995,Suceveni,ADM2,DISTRICT
SM291851,25.74,47.7905,Sucevita,ADM2,DISTRICT
SM288937,19.591,49.7446,Sucha,ADM2,DISTRICT
SM283197,-93.1,16.6078,Suchiapa,ADM2,DISTRICT
SM283198,-92.2225,14.6414,Suchiate,ADM2,DISTRICT
SM3336,-91.4305,14.3808,Suchitepéquez,ADM1,DISTRICT
SM295646,-89.0667,13.9167,Suchitoto,ADM2,DISTRICT
SM283006,-88.3785,21.2125,Sucilá,ADM2,DISTRICT
SM303768,-70.7734,9.434,Sucre,ADM2,DISTRICT
SM303784,-68.8333,10.3,Sucre,ADM2,DISTRICT
SM303807,-71.25,9.0833,Sucre,ADM2,DISTRICT
SM303645,-66.8016,10.4723,Sucre,ADM2,DISTRICT
SM303750,-72,7.85,Sucre,ADM2,DISTRICT
SM303721,-64.0833,10.4167,Sucre,ADM2,DISTRICT
SM303684,-70,9.3333,Sucre,ADM2,DISTRICT
SM303624,-71.5,8.5833,Sucre,ADM2,DISTRICT
SM303855,-64.25,6,Sucre,ADM2,DISTRICT
SM303829,-67.45,10.1667,Sucre,ADM2,DISTRICT
SM303576,-69.7131,11.064,Sucre,ADM2,DISTRICT
SM286881,-73.786,-14.103,Sucre,ADM2,DISTRICT
SM273504,-80.4158,-0.6537,Sucre,ADM2,DISTRICT
SM10476,-76.9144,2.0618,Sucre,ADM2,DISTRICT
SM270519,-73.9663,5.97,Sucre,ADM2,DISTRICT
SM270542,-74.75,8.8333,Sucre,ADM2,DISTRICT
SM3337,-75,9,Sucre,ADM1,DISTRICT
SM3338,-63.5,10.4167,Sucre,ADM1,DISTRICT
SM3339,-76.8667,0.1,Sucumbios,ADM1,DISTRICT
SM273565,-77.6431,0.3694,Sucumbíos,ADM2,DISTRICT
SM267183,-48.8448,-12.019,Sucupira,ADM2,DISTRICT
SM262916,-44.2801,-6.4668,Sucupira do Norte,ADM2,DISTRICT
SM262917,-43.4972,-6.4679,Sucupira do Riachão,ADM2,DISTRICT
SM273514,-78.1667,-2.4667,Sucúa,ADM2,DISTRICT
SM3341,-73.7333,18.3,Sud,ADM1,DISTRICT
SM3340,11.5833,2.75,Sud,ADM1,DISTRICT
SM3342,165.8333,-21.75,Sud,ADM1,DISTRICT
SM16221,-66.1643,18.1644,Sud Barrio,ADM2,DISTRICT
SM9857,-68.25,-18.5,Sud Carangas Province,ADM2,DISTRICT
SM262752,-65.8777,-21.4328,Sud Chichas,ADM2,DISTRICT
SM262694,-64.9545,-20.8974,Sud Cinti,ADM2,DISTRICT
SM269479,-3.2408,5.4996,Sud Comoé,ADM2,DISTRICT
SM262753,-66.9116,-22.1612,Sud Lípez,ADM2,DISTRICT
SM266970,-50.8801,-20.6186,Sud Mennucci,ADM2,DISTRICT
SM246284,0.0199,29.1107,Sud Oranais-Gourara,LANGUAGE,LANGUAGE
SM262726,-67.1469,-16.1621,Sud Yungas,ADM2,DISTRICT
SM3343,-72.4,18.3,Sud-Est,ADM1,DISTRICT
SM3344,28.596,-3.2255,Sud-Kivu,ADM1:ADM2,DISTRICT
SM3344,28.596,-3.2255,Sud-Kivu,ADM1:ADM2,DISTRICT
SM3344,28.25,-3.1667,Sud-Kivu,ADM1:ADM2,DISTRICT
SM3344,28.25,-3.1667,Sud-Kivu,ADM1:ADM2,DISTRICT
SM3345,-3.2,10.5,Sud-Ouest,ADM1,DISTRICT
SM3346,9.3,5.2,Sud-Ouest,ADM1,DISTRICT
SM462121,19.4988,3.168,Sud-Ubangi (excl. Zongo),ADM1:ADM2,DISTRICT
SM462121,19.4988,3.168,Sud-Ubangi (excl. Zongo),ADM1:ADM2,DISTRICT
SM462121,19.35,3.0833,Sud-Ubangi (excl. Zongo),ADM1:ADM2,DISTRICT
SM462121,19.35,3.0833,Sud-Ubangi (excl. Zongo),ADM1:ADM2,DISTRICT
SM245590,33.3179,17.8021,Sudanese Arabic,LANGUAGE,LANGUAGE
SM245591,28.774,5.654,Sudanese Creole Arabic,LANGUAGE,LANGUAGE
SM299894,35.002,44.9167,Sudats'ka,ADM2,DISTRICT
SM268884,-81.9998,47.1668,Sudbury,ADM2,DISTRICT
SM245592,153.488,-11.5229,Sudest,LANGUAGE,LANGUAGE
SM375373,73.6858,33.715,Sudhnati,ADM2:ADM3,DISTRICT
SM293348,41.6667,57.8333,Sudislavskiy rayon,ADM2,DISTRICT
SM290935,27.5822,44.5714,Suditi,ADM2,DISTRICT
SM294886,41,56,Sudogodskiy rayon,ADM2,DISTRICT
SM289120,126.9192,39.4089,Sudong,ADM2,DISTRICT
SM292025,22.046,45.7875,Sudrias,ADM2,DISTRICT
SM15177,5.5258,53.0106,Sudwest Fryslan (2018-),ADM2,DISTRICT
SM283007,-88.9003,20.8143,Sudzal,ADM2,DISTRICT
SM293516,35.25,51.25,Sudzhanskiy rayon,ADM2,DISTRICT
SM15008,106.2081,50.2267,Suehbaatar,ADM2,DISTRICT
SM14109,-10.641,6.7233,Suehn Mecca District,ADM2,DISTRICT
SM245602,147.534,-7.7634,Suena,LANGUAGE,LANGUAGE
SM270058,-73.8036,5.1261,Suesca,ADM2,DISTRICT
SM293001,80,53.3333,Suetskiy rayon,ADM2,DISTRICT
SM302960,-76.608,36.7085,Suffolk,ADM2,DISTRICT
SM301915,-72.6852,40.9405,Suffolk,ADM2,DISTRICT
SM301241,-71.0658,42.3555,Suffolk,ADM2,DISTRICT
SM274698,1,52.1667,Suffolk,ADM1:ADM2,DISTRICT
SM257765,145.74,-5.78,Sufrai,ETHNICITY,ETHNICITY
SM289840,23.5996,45.7478,Sugag,ADM2,DISTRICT
SM245604,141.2,-4.5071,Suganga,LANGUAGE,LANGUAGE
SM279468,139.6364,35.6995,Suginami,ADM2,DISTRICT
SM245606,117.176,6.1424,Sugut Dusun,LANGUAGE,LANGUAGE
SM3351,31.7,26.5,Suhaj,ADM1,DISTRICT
SM292601,26.4474,48.117,Suharau,ADM2,DISTRICT
SM262473,25.2,43.1667,Suhindol,ADM2,DISTRICT
SM305526,17.4976,45.8024,Suhopolje,ADM2,DISTRICT
SM257768,47.38,23.63,Suhul,ETHNICITY,ETHNICITY
SM11440,-0.45,6.0333,Suhum/Kraboa/Coaltar (2012-),ADM2,DISTRICT
SM245613,106.563,24.0488,Sui,LANGUAGE,LANGUAGE
SM291296,24.5362,45.2445,Suici,ADM2,DISTRICT
SM269150,126.8087,46.8805,Suihua,ADM2,DISTRICT
SM269329,105.4689,30.639,Suining,ADM2,DISTRICT
SM260713,-59.75,-34.75,Suipacha,ADM2,DISTRICT
SM279117,135.5191,34.7856,Suita,ADM2,DISTRICT
SM269179,113.4658,31.9209,Suizhou Shi,ADM2,DISTRICT
SM4953,68.0719,24.6064,Sujawal,ADM2:ADM3,DISTRICT
SM257769,141.47,-8.14,Suka,ETHNICITY,ETHNICITY
SM276771,106.7,-7.0667,Sukabumi,ADM2,DISTRICT
SM279772,140.3169,37.2934,Sukagawa,ADM2,DISTRICT
SM276409,111.2368,-2.6268,Sukamara,ADM2,DISTRICT
SM289060,125.5861,39.4008,Sukch'ŏn,ADM2,DISTRICT
SM293082,35.3333,54.0833,Sukhinichskiy rayon,ADM2,DISTRICT
SM297259,101.7381,5.9151,Sukhirin,ADM2,DISTRICT
SM293456,94,56.5833,Sukhobuzimskiy rayon,ADM2,DISTRICT
SM294552,62,57,Sukholozhskiy rayon,ADM2,DISTRICT
SM3353,99.6667,17.25,Sukhothai,ADM1,DISTRICT
SM280849,105.6752,14.6256,Sukhuma,ADM2,DISTRICT
SM245620,141.842,-8.0478,Suki,LANGUAGE,LANGUAGE
SM257771,-51.52,66.68,Sukkertoppen,ETHNICITY,ETHNICITY
SM375506,69.147,27.4996,Sukkur,ADM2:ADM3,DISTRICT
SM277458,81.6601,18.4004,Sukma,ADM2,DISTRICT
SM276326,110.8333,-7.6833,Sukoharjo,ADM2,DISTRICT
SM305602,15.3139,44.047,Sukošan,ADM2,DISTRICT
SM245623,17.7736,-5.765,Suku,LANGUAGE,LANGUAGE
SM250718,18,-6,Suku,ETHNICITY,ETHNICITY
SM245624,32.8765,-3.1608,Sukuma,LANGUAGE,LANGUAGE
SM250719,34.21,-3.26,Sukuma,ETHNICITY,ETHNICITY
SM250719,34.21,-3.26,Sukuma,ETHNICITY,ETHNICITY
SM250719,34.21,-3.26,Sukuma,ETHNICITY,ETHNICITY
SM250719,33.49,-4.9,Sukuma,ETHNICITY,ETHNICITY
SM250719,33.49,-4.9,Sukuma,ETHNICITY,ETHNICITY
SM250719,33.49,-4.9,Sukuma,ETHNICITY,ETHNICITY
SM250719,34,-3,Sukuma,ETHNICITY,ETHNICITY
SM250719,34,-3,Sukuma,ETHNICITY,ETHNICITY
SM250719,34,-3,Sukuma,ETHNICITY,ETHNICITY
SM250719,33.5,-3,Sukuma,ETHNICITY,ETHNICITY
SM250719,33.5,-3,Sukuma,ETHNICITY,ETHNICITY
SM250719,33.5,-3,Sukuma,ETHNICITY,ETHNICITY
SM278601,132.7343,32.9614,Sukumo,ADM2,DISTRICT
SM245628,13.6437,10.8933,Sukur,LANGUAGE,LANGUAGE
SM245627,146.3419,-6.28,Sukurum,LANGUAGE,LANGUAGE
SM266322,-52.9436,-26.7069,Sul Brazil,ADM2,DISTRICT
SM245631,125.966,-2.3077,Sula,LANGUAGE,LANGUAGE
SM286432,6.2123,62.4445,Sula,ADM2,DISTRICT
SM257775,125.29,-1.85,Sula,ETHNICITY,ETHNICITY
SM275811,-87.3,14.95,Sulaco,ADM2,DISTRICT
SM245634,125.9656,-2.3077,Sulaic,FAMILY,LANGUAGE
SM298635,33.7205,40.1675,Sulakyurt,ADM2,DISTRICT
SM3354,119.3333,-2.5,Sulawesi Barat,ADM1,DISTRICT
SM3355,120.25,-4.3333,Sulawesi Seletan (2004-),ADM1,DISTRICT
SM3356,122.3538,-0.9166,Sulawesi Tengah,ADM1,DISTRICT
SM3357,122.2149,-4.3935,Sulawesi Tenggara,ADM1,DISTRICT
SM3358,124.8333,1.25,Sulawesi Utara (2000-),ADM1,DISTRICT
SM286142,6.2522,59.4857,Suldal,ADM2,DISTRICT
SM284799,9.1875,12.6558,Sule-Tan,ADM2,DISTRICT
SM285037,7.1886,9.2145,Suleja,ADM2,DISTRICT
SM292313,27.9041,46.3061,Suletea,ADM2,DISTRICT
SM292854,48.0833,41.6667,Suleyman-Stal'skiy rayon,ADM2,DISTRICT
SM292075,29.652,45.1561,Sulina,ADM2,DISTRICT
SM264744,-52.6837,-25.6714,Sulina,ADM2,DISTRICT
SM245639,151.891,-5.0347,Sulka,LANGUAGE,LANGUAGE
SM461506,152.333,-4.5,Sulka,ETHNICITY,ETHNICITY
SM461506,152.333,-4.5,Sulka,ETHNICITY,ETHNICITY
SM461506,152.3,-4.483,Sulka,ETHNICITY,ETHNICITY
SM461506,152.3,-4.483,Sulka,ETHNICITY,ETHNICITY
SM286819,-80.6667,-4.5,Sullana,ADM2,DISTRICT
SM302337,-76.5121,41.4462,Sullivan,ADM2,DISTRICT
SM302624,-82.3041,36.5129,Sullivan,ADM2,DISTRICT
SM301804,-72.2224,43.3612,Sullivan,ADM2,DISTRICT
SM301916,-74.7681,41.7164,Sullivan,ADM2,DISTRICT
SM301515,-93.1115,40.2106,Sullivan,ADM2,DISTRICT
SM300766,-87.4147,39.0888,Sullivan,ADM2,DISTRICT
SM302532,-100.1322,44.7156,Sully,ADM2,DISTRICT
SM245641,122.551,11.2473,Sulod,LANGUAGE,LANGUAGE
SM17655,-61.0451,13.84,Sulphur Springs,ADM2,DISTRICT
SM3359,124.2833,6.55,Sultan Kudarat,ADM1:ADM2,DISTRICT
SM298518,29.268,40.9526,Sultanbeyli,ADM2,DISTRICT
SM298370,31.2351,38.5406,Sultandağı,ADM2,DISTRICT
SM298519,28.7174,41.2179,Sultangazi,ADM2,DISTRICT
SM18104,33.5521,38.2438,Sultanhani,ADM2,DISTRICT
SM298151,28.1741,37.8997,Sultanhisar,ADM2,DISTRICT
SM277338,82,26.25,Sultanpur (2010-),ADM2,DISTRICT
SM281965,-99.9813,18.75,Sultepec,ADM2,DISTRICT
SM3360,121,6,Sulu,ADM1:ADM2,DISTRICT
SM295444,160.8874,-8.4924,Sulufou-Kwarande,ADM2,DISTRICT
SM298777,35.6592,40.842,Suluova,ADM2,DISTRICT
SM298959,36.0898,40.0049,Sulusaray,ADM2,DISTRICT
SM288927,15.049,52.4285,Sulęcin,ADM2,DISTRICT
SM245644,17.0093,7.0953,Suma,LANGUAGE,LANGUAGE
SM283008,-89.1549,21.1059,Suma,ADM2,DISTRICT
SM257779,-102.25,38.45,Suma,ETHNICITY,ETHNICITY
SM257779,-102.25,38.45,Suma,ETHNICITY,ETHNICITY
SM257779,-101.57,35.59,Suma,ETHNICITY,ETHNICITY
SM257779,-101.57,35.59,Suma,ETHNICITY,ETHNICITY
SM257779,-107.41,31.32,Suma,ETHNICITY,ETHNICITY
SM257779,-107.41,31.32,Suma,ETHNICITY,ETHNICITY
SM284882,8.9144,11.3267,Sumaila,ADM2,DISTRICT
SM245649,143.239,-4.7308,Sumariup,LANGUAGE,LANGUAGE
SM266971,-47.252,-22.8374,Sumaré,ADM2,DISTRICT
SM3362,100.5,-1,Sumatera Barat,ADM1,DISTRICT
SM3363,103.8333,-2.75,Sumatera Selatan (2000-),ADM1,DISTRICT
SM3364,99,2,Sumatera Utara,ADM1,DISTRICT
SM245650,145.474,-5.4856,Sumau,LANGUAGE,LANGUAGE
SM245081,29.048,-25.6272,Sumayela Ndebele,LANGUAGE,LANGUAGE
SM274542,-6.7188,61.425,Sumba,ADM2,DISTRICT
SM257781,120.38,-10.01,Sumba,ETHNICITY,ETHNICITY
SM257781,120.38,-10.01,Sumba,ETHNICITY,ETHNICITY
SM257781,120,-10,Sumba,ETHNICITY,ETHNICITY
SM257781,120,-10,Sumba,ETHNICITY,ETHNICITY
SM276500,119.45,-9.5667,Sumba Barat,ADM2,DISTRICT
SM276499,119.0891,-9.5622,Sumba Barat Daya,ADM2,DISTRICT
SM276501,119.6191,-9.6294,Sumba Tengah,ADM2,DISTRICT
SM276502,120.25,-9.8833,Sumba Timur,ADM2,DISTRICT
SM298822,36.0291,37.4617,Sumbas,ADM2,DISTRICT
SM245656,117.472,-8.7192,Sumbawa,LANGUAGE,LANGUAGE
SM276483,117.3324,-8.7439,Sumbawa,ADM2,DISTRICT
SM276491,116.9213,-8.7516,Sumbawa Barat,ADM2,DISTRICT
SM257782,117.33,-8.61,Sumbawanese,ETHNICITY,ETHNICITY
SM257782,117.33,-8.61,Sumbawanese,ETHNICITY,ETHNICITY
SM257782,117.27,-8.63,Sumbawanese,ETHNICITY,ETHNICITY
SM257782,117.27,-8.63,Sumbawanese,ETHNICITY,ETHNICITY
SM299160,31.939,-8.186,Sumbawanga Rural,ADM2,DISTRICT
SM299161,31.6167,-7.9667,Sumbawanga Urban,ADM2,DISTRICT
SM260440,13.9358,-11.4471,Sumbe,ADM2,DISTRICT
SM245655,31.9457,-3.4734,Sumbwa,LANGUAGE,LANGUAGE
SM250720,31.96,-4.31,Sumbwa,ETHNICITY,ETHNICITY
SM250720,31.96,-4.31,Sumbwa,ETHNICITY,ETHNICITY
SM250720,32,-4,Sumbwa,ETHNICITY,ETHNICITY
SM250720,32,-4,Sumbwa,ETHNICITY,ETHNICITY
SM277725,49.3,37.3333,Sume'eh Sara,ADM2,DISTRICT
SM276772,107.9833,-6.8167,Sumedang,ADM2,DISTRICT
SM276366,114.3333,-7.1167,Sumenep,ADM2,DISTRICT
SM245660,45.6667,31.3,Sumerian,LANGUAGE,LANGUAGE
SM245662,94.4235,25.9996,Sumi Naga,LANGUAGE,LANGUAGE
SM279469,139.8015,35.7107,Sumida,ADM2,DISTRICT
SM16222,-66.1234,18.2264,Sumidero Barrio,ADM2,DISTRICT
SM16223,-66.1761,18.0912,Sumido Barrio,ADM2,DISTRICT
SM265327,-42.6613,-22.1318,Sumidouro,ADM2,DISTRICT
SM278454,141.5505,39.1801,Sumita,ADM2,DISTRICT
SM288573,145.55,-4.8,Sumkar,ADM2,DISTRICT
SM12773,-77.7935,18.3736,Summer Hill,ADM2,DISTRICT
SM12774,-77.2843,18.0943,Summerfield,ADM2,DISTRICT
SM303147,-80.8586,37.6559,Summers,ADM2,DISTRICT
SM17656,-60.9764,14.0258,Summersdale,ADM2,DISTRICT
SM302907,-110.9557,40.8682,Summit,ADM2,DISTRICT
SM302158,-81.5322,41.126,Summit,ADM2,DISTRICT
SM303374,-106.1164,39.6342,Summit,ADM2,DISTRICT
SM302625,-86.4604,36.4694,Sumner,ADM2,DISTRICT
SM300984,-97.4766,37.2373,Sumner,ADM2,DISTRICT
SM257783,-84.9,13.92,Sumo,ETHNICITY,ETHNICITY
SM278352,134.8586,34.3453,Sumoto,ADM2,DISTRICT
SM275271,-90.7462,14.6557,Sumpango,ADM2,DISTRICT
SM261882,49.628,40.5947,Sumqayıt,ADM1:ADM2,DISTRICT
SM299724,34.8068,50.9183,Sums'ka,ADM2,DISTRICT
SM302469,-80.3823,33.9162,Sumter,ADM2,DISTRICT
SM300369,-82.081,28.7048,Sumter,ADM2,DISTRICT
SM300413,-84.197,32.04,Sumter,ADM2,DISTRICT
SM300302,-88.1988,32.591,Sumter,ADM2,DISTRICT
SM245665,93.8367,20.1238,Sumtu Chin,LANGUAGE,LANGUAGE
SM245666,-84.5,13,Sumuic,FAMILY,LANGUAGE
SM273792,30.8482,28.9545,Sumusta al-Waqf,ADM2,DISTRICT
SM3365,34,51,Sumy,ADM1,DISTRICT
SM264346,-36.9104,-7.6171,Sumé,ADM2,DISTRICT
SM278201,141.9327,43.5033,Sunagawa,ADM2,DISTRICT
SM13855,25.4672,56.4609,Sunakstes pagasts,ADM2,DISTRICT
SM245667,78.4513,31.808,Sunam,LANGUAGE,LANGUAGE
SM262286,91.4167,24.8667,Sunamganj,ADM2,DISTRICT
SM15275,125.7097,39.2047,Sunan,ADM2,DISTRICT
SM17657,-60.9792,14.0056,Sunbilt,ADM2,DISTRICT
SM323976,-114.1952,52.3895,Sunbreaker Cove,ADM2:ADM3,DISTRICT
SM268797,-66.3322,45.9168,Sunbury,ADM2,DISTRICT
SM289061,125.9169,39.4489,Sunch'ŏn,ADM2,DISTRICT
SM280578,127.1497,35.3765,Sunchang,ADM2,DISTRICT
SM280597,127.461,34.9716,Suncheon,ADM2,DISTRICT
SM292474,22.5332,46.9252,Suncuius,ADM2,DISTRICT
SM3366,20.1667,60.25,Sund,ADM1:ADM2,DISTRICT
SM274523,-6.9818,62.2052,Sunda,ADM2,DISTRICT
SM324030,-114.1117,53.0822,Sundance Beach,ADM2:ADM3,DISTRICT
SM245670,107.106,-6.8971,Sundanese,LANGUAGE,LANGUAGE
SM257785,108.27,-7.36,Sundanese,ETHNICITY,ETHNICITY
SM277130,84.5,22.25,Sundargarh,ADM2,DISTRICT
SM296480,17.9702,59.3667,Sundbyberg,ADM2,DISTRICT
SM274699,-1.4167,54.8833,Sunderland,ADM1:ADM2,DISTRICT
SM12775,-77.8273,18.3979,Sunderland,ADM2,DISTRICT
SM323913,-114.6403,51.7995,Sundre,ADM2:ADM3,DISTRICT
SM296539,16.9265,62.4791,Sundsvall,ADM2,DISTRICT
SM301489,-90.5886,33.6023,Sunflower,ADM2,DISTRICT
SM297398,100.1254,18.0354,Sung Men,ADM2,DISTRICT
SM297136,101.8305,14.8562,Sung Noen,ADM2,DISTRICT
SM10052,114.9238,4.8682,Sungai Kebun,ADM2,DISTRICT
SM10053,114.9198,4.8795,Sungai Kedayan,ADM2,DISTRICT
SM297248,101.8933,6.1043,Sungai Padi,ADM2,DISTRICT
SM276753,101.3218,-2.109,Sungai Penuh,ADM2,DISTRICT
SM15276,126,39.0333,Sungho-guyok,ADM2,DISTRICT
SM262396,26.8333,42.8333,Sungurlare,ADM2,DISTRICT
SM298290,34.2822,40.1821,Sungurlu,ADM2,DISTRICT
SM305452,16.5673,45.3686,Sunja,ADM2,DISTRICT
SM286434,8.5619,62.6746,Sunndal,ADM2,DISTRICT
SM296509,13.0442,59.9038,Sunne,ADM2,DISTRICT
SM15307,6.1135,61.4699,Sunnfjord,ADM2,DISTRICT
SM12776,-76.4093,17.9561,Sunning Hill,ADM2,DISTRICT
SM17658,-60.9736,14.0281,Sunny Acres,ADM2,DISTRICT
SM324087,-114.0498,53.7874,Sunrise Beach,ADM2:ADM3,DISTRICT
SM375316,87.141,26.641,Sunsari,ADM2:ADM3,DISTRICT
SM324088,-113.5406,54.7458,Sunset Beach,ADM2:ADM3,DISTRICT
SM324089,-114.3442,53.6915,Sunset Point,ADM2:ADM3,DISTRICT
SM268762,-123.7528,49.9997,Sunshine Coast,ADM2,DISTRICT
SM9735,152.8511,-26.561,Sunshine Coast (2014-),ADM2,DISTRICT
SM293286,50,57.8333,Sunskiy rayon,ADM2,DISTRICT
SM294320,115,63,Suntarskiy rayon,ADM2,DISTRICT
SM13856,24.8847,56.8907,Suntazu pagasts,ADM2,DISTRICT
SM13092,138.9627,35.3444,Sunto-gun,ADM2,DISTRICT
SM283199,-93.247,17.4832,Sunuapa,ADM2,DISTRICT
SM237616,168.0579,-14.9701,Sunwadia,LANGUAGE,LANGUAGE
SM245682,86.2476,27.5201,Sunwar,LANGUAGE,LANGUAGE
SM4350,-2.3167,7.3667,Sunyani West,ADM2,DISTRICT
SM17096,45.0833,43.3333,Sunzhenskiy rayon (1993-),ADM2,DISTRICT
SM245686,104.136,11.936,Suoy,LANGUAGE,LANGUAGE
SM293134,32.25,62.25,Suoyarvskiy rayon,ADM2,DISTRICT
SM270059,-74.2291,5.0615,Supatá,ADM2,DISTRICT
SM277433,86.8,26.25,Supaul,ADM2,DISTRICT
SM276003,16.5553,43.3844,Supetar,ADM2,DISTRICT
SM3367,100.0667,14.4667,Suphan Buri,ADM1,DISTRICT
SM276541,135.6111,-0.7388,Supiori,ADM2,DISTRICT
SM291199,24.5222,46.3911,Suplac,ADM2,DISTRICT
SM292475,22.4923,47.2434,Suplacu De Barcau,ADM2,DISTRICT
SM291689,22.8077,47.4583,Supuru De Jos,ADM2,DISTRICT
SM245691,-5.8771,11.632,Supyire Senoufo,LANGUAGE,LANGUAGE
SM270853,-75.6728,5.435,Supía,ADM2,DISTRICT
SM305773,46.5026,30.8485,Suq ash Shuyukh,ADM2,DISTRICT
SM269218,118.5294,33.8478,Suqian,ADM2,DISTRICT
SM245717,9.4901,9.4796,Sur,LANGUAGE,LANGUAGE
SM18105,40.2374,37.9136,Sur,ADM2,DISTRICT
SM291757,24.1848,45.8592,Sura Mare,ADM2,DISTRICT
SM291758,24.0421,45.8249,Sura Mica,ADM2,DISTRICT
SM276367,112.7167,-7.2667,Surabaya,ADM2,DISTRICT
SM296557,16.1188,59.7749,Surahammar,ADM2,DISTRICT
SM277459,82.8658,23.2139,Surajpur,ADM2,DISTRICT
SM276327,110.8167,-7.55,Surakarta,ADM2,DISTRICT
SM291576,26.1734,45.1942,Surani,ADM2,DISTRICT
SM276831,72.8119,21.178,Surat (2007-),ADM2,DISTRICT
SM3368,99.0394,9.0011,Surat Thani,ADM1,DISTRICT
SM270520,-73.059,7.517,Suratá,ADM2,DISTRICT
SM295021,32.4167,53.0833,Surazhskiy rayon,ADM2,DISTRICT
SM245696,21.6243,13.1331,Surbakhal,LANGUAGE,LANGUAGE
SM292694,27.3349,45.0989,Surdila-gaiseanca,ADM2,DISTRICT
SM292695,27.2684,45.0729,Surdila-greci,ADM2,DISTRICT
SM291637,23.3731,47.2443,Surduc,ADM2,DISTRICT
SM245698,86.3058,27.6969,Surel,DIALECT,LANGUAGE
SM276832,71.6667,22.75,Surendranagar (2013-),ADM2,DISTRICT
SM261278,144.1153,-38.34,Surf Coast,ADM2,DISTRICT
SM277460,83.0963,22.8962,Surguja (2012-),ADM2,DISTRICT
SM245701,73.35,61.25,Surgut Khanty,LANGUAGE,LANGUAGE
SM250721,35,6,Suri,ETHNICITY,ETHNICITY
SM250721,35,6,Suri,ETHNICITY,ETHNICITY
SM250721,15,10,Suri,ETHNICITY,ETHNICITY
SM250721,15,10,Suri,ETHNICITY,ETHNICITY
SM3369,125.527,9.663,Surigao del Norte (2006-),ADM1:ADM2,DISTRICT
SM3370,126.1285,8.8091,Surigao del Sur,ADM1:ADM2,DISTRICT
SM245709,126,9,Surigaonon,LANGUAGE,LANGUAGE
SM3371,103.75,14.8333,Surin,ADM1,DISTRICT
SM245712,85.792,25.686,Surjapuri,LANGUAGE,LANGUAGE
SM260127,70.2766,34.3947,Surkh Rod,ADM2,DISTRICT
SM3372,67.5,38,Surkhandarya,ADM1,DISTRICT
SM375341,81.5139,28.658,Surkhet,ADM2:ADM3,DISTRICT
SM260184,68.577,34.7632,Surkhi Parsa,ADM2,DISTRICT
SM286435,8.7249,62.9753,Surnadal,ADM2,DISTRICT
SM260039,69.7278,34.6054,Surobi,ADM2,DISTRICT
SM294926,43,48.6667,Surovikinskiy rayon,ADM2,DISTRICT
SM274700,-0.3333,51.25,Surrey,ADM1:ADM2,DISTRICT
SM302961,-76.8883,37.1169,Surry,ADM2,DISTRICT
SM302015,-80.6875,36.4147,Surry,ADM2,DISTRICT
SM268924,8.137,47.1559,Sursee,ADM2,DISTRICT
SM268909,9.0483,46.6956,Surselva,ADM2,DISTRICT
SM245720,152.768,-3.9908,Sursurunga,LANGUAGE,LANGUAGE
SM3373,16.85,30.0333,Surt,ADM1,DISTRICT
SM284941,4.0967,11.83,Suru,ADM2,DISTRICT
SM245727,-66.3146,-6.8253,Suruahá,LANGUAGE,LANGUAGE
SM264912,-35.7531,-7.8699,Surubim,ADM2,DISTRICT
SM461537,-62,-11,Surui,ETHNICITY,ETHNICITY
SM285001,3.3489,6.4917,Surulere,ADM2,DISTRICT
SM285172,4.4149,8.0718,Surulere,ADM2,DISTRICT
SM298865,38.4262,36.9768,Suruç,ADM2,DISTRICT
SM245726,-61.2818,-11.0989,Suruí,LANGUAGE,LANGUAGE
SM245725,-48.588,-5.9271,Suruí Do Pará,LANGUAGE,LANGUAGE
SM11691,79.6225,17.1416,Suryapet,ADM2,DISTRICT
SM270061,-73.8479,5.427,Susa,ADM2,DISTRICT
SM270720,-72.7567,6.1861,Susacón,ADM2,DISTRICT
SM13857,27.5691,57.191,Susaju pagasts,ADM2,DISTRICT
SM278602,133.2667,33.3833,Susaki,ADM2,DISTRICT
SM292244,24.1002,44.6101,Susani,ADM2,DISTRICT
SM293350,41.5,58.25,Susaninskiy rayon,ADM2,DISTRICT
SM273590,-79.0514,-2.4401,Suscal,ADM2,DISTRICT
SM290723,25.5648,46.6435,Suseni,ADM2,DISTRICT
SM291297,24.9644,44.7086,Suseni,ADM2,DISTRICT
SM280675,128.6263,35.8591,Suseong,ADM2,DISTRICT
SM257813,45.8,33.44,Susian,ETHNICITY,ETHNICITY
SM279304,138.8773,35.2255,Susono,ADM2,DISTRICT
SM257814,-77.05,41.62,Susquehana,ETHNICITY,ETHNICITY
SM302338,-75.8007,41.8213,Susquehanna,ADM2,DISTRICT
SM245731,-76.208,41.169,Susquehannock,LANGUAGE,LANGUAGE
SM260827,-66.6667,-23.5,Susques,ADM2,DISTRICT
SM302962,-77.2618,36.9218,Sussex,ADM2,DISTRICT
SM301823,-74.6902,41.1395,Sussex,ADM2,DISTRICT
SM303392,-75.3395,38.6833,Sussex,ADM2,DISTRICT
SM265150,-41.3918,-7.0034,Sussuapara,ADM2,DISTRICT
SM283922,33.2715,-19.6908,Sussundenga,ADM2,DISTRICT
SM283074,-103.1479,22.6131,Susticacán,ADM2,DISTRICT
SM245734,-13.3628,10.4046,Susu,LANGUAGE,LANGUAGE
SM16224,-66.8952,18.0611,Susua Alta Barrio,ADM2,DISTRICT
SM16225,-66.8911,18.0088,Susua Baja Barrio (Guánica),ADM2,DISTRICT
SM16226,-66.8667,18.0245,Susua Baja Barrio (Yauco),ADM2,DISTRICT
SM16227,-66.911,18.0363,Susua Barrio,ADM2,DISTRICT
SM245735,146.561,-7.1928,Susuami,LANGUAGE,LANGUAGE
SM295393,159.3986,-8.2076,Susubona,ADM2,DISTRICT
SM295353,156.8974,-6.9238,Susuka,ADM2,DISTRICT
SM293616,147,63,Susumanskiy rayon,ADM2,DISTRICT
SM282079,-100.4063,19.1959,Susupuato,ADM2,DISTRICT
SM298171,28.1438,39.9234,Susurluk,ADM2,DISTRICT
SM298587,43.1309,40.7793,Susuz,ADM2,DISTRICT
SM270721,-73.6525,5.6155,Sutamarchán,ADM2,DISTRICT
SM270062,-73.8791,5.229,Sutatausa,ADM2,DISTRICT
SM270722,-73.4337,5.0264,Sutatenza,ADM2,DISTRICT
SM292696,27.4707,45.221,Sutesti,ADM2,DISTRICT
SM261464,151.0771,-34.072,Sutherland,ADM2,DISTRICT
SM305490,16.4785,43.3846,Sutivan,ADM2,DISTRICT
SM257819,140.39,-2.63,Sutrai,ETHNICITY,ETHNICITY
SM13858,26.5023,56.3061,Sutru pagasts,ADM2,DISTRICT
SM303209,-121.6948,39.0345,Sutter,ADM2,DISTRICT
SM302771,-100.5382,30.4983,Sutton,ADM2,DISTRICT
SM13093,140.3444,42.705,Suttsu-gun,ADM2,DISTRICT
SM245739,13.3212,-4.5849,Suundi,LANGUAGE,LANGUAGE
SM346836,22.0256,62.3327,Suupohja,ADM2:ADM3,DISTRICT
SM304758,20.85,42.35,Suva Reka,ADM2,DISTRICT
SM262465,27.65,43.3333,Suvorovo,ADM2,DISTRICT
SM294716,36.5833,54.1667,Suvorovskiy rayon,ADM2,DISTRICT
SM278849,138.1136,36.029,Suwa,ADM2,DISTRICT
SM13094,138.2129,35.9701,Suwa-gun,ADM2,DISTRICT
SM297265,102.2485,17.5461,Suwan Khuha,ADM2,DISTRICT
SM297474,103.8063,15.6053,Suwannaphum,ADM2,DISTRICT
SM300370,-82.9915,30.1956,Suwannee,ADM2,DISTRICT
SM257821,34.39,31.27,Suwarka,ETHNICITY,ETHNICITY
SM245742,123.362,0.4907,Suwawa,LANGUAGE,LANGUAGE
SM304781,43.6212,16.0611,Suwayr,ADM2,DISTRICT
SM288685,22.8182,54.1666,Suwałki,ADM2,DISTRICT
SM288684,22.9291,54.1027,Suwałki (City),ADM2,DISTRICT
SM280718,127.0099,37.2859,Suwon,ADM2,DISTRICT
SM280531,129.1125,35.1563,Suyeong,ADM2,DISTRICT
SM245744,-53.0743,-11.5178,Suyá,LANGUAGE,LANGUAGE
SM280322,73.28,41.08,Suzak,ADM2,DISTRICT
SM278850,138.3167,36.65,Suzaka,ADM2,DISTRICT
SM279874,68.5,44.8333,Suzakskiy,ADM2,DISTRICT
SM457972,-46.31,-23.6071,Suzano,ADM2,DISTRICT
SM321039,-51.0708,-20.5103,Suzanápolis,ADM2:ADM3,DISTRICT
SM17097,40.5,56.0833,Suzdal'skiy Rayon,ADM2,DISTRICT
SM295022,34.1667,52.4167,Suzemskiy rayon,ADM2,DISTRICT
SM245745,120.3641,31.328,Suzhou,DIALECT,LANGUAGE
SM269219,120.8949,31.4605,Suzhou,ADM2,DISTRICT
SM269124,117.2057,33.8697,Suzhou,ADM2,DISTRICT
SM278419,137.2259,37.4553,Suzu,ADM2,DISTRICT
SM278705,136.5844,34.8811,Suzuka,ADM2,DISTRICT
SM293876,82.5,53.8333,Suzunskiy rayon,ADM2,DISTRICT
SM270588,-74.8182,4.049,Suárez,ADM2,DISTRICT
SM269877,-76.7448,2.9346,Suárez,ADM2,DISTRICT
SM3348,-19,64,Suðurland,ADM1,DISTRICT
SM3349,-22.25,63.9167,Suðurnes,ADM1,DISTRICT
SM3350,-6.8167,61.4833,Suðuroyar,ADM1,DISTRICT
SM305489,17.1873,43.1273,Sućuraj,ADM2,DISTRICT
SM279582,132.2817,33.9159,Suō-Ōshima,ADM2,DISTRICT
SM298927,38.0893,40.1634,Suşehri,ADM2,DISTRICT
SM3375,15.8643,78.1571,Svalbard,ADM1,DISTRICT
SM11627,-15.8,66.0833,Svalbardshreppur,ADM2,DISTRICT
SM11628,-18.0351,65.7414,Svalbardsstrandarhreppur,ADM2,DISTRICT
SM299767,22.9896,48.5464,Svaliavs'kyi,ADM2,DISTRICT
SM296448,13.1317,55.965,Svalöv,ADM2,DISTRICT
SM245746,42.5915,42.9134,Svan,LANGUAGE,LANGUAGE
SM257822,43,43,Svan,ETHNICITY,ETHNICITY
SM13859,27.7185,56.1022,Svarinu pagasts,ADM2,DISTRICT
SM280396,105.4377,11.5384,Svay Antor,ADM2,DISTRICT
SM280355,102.9527,13.8131,Svay Chek,ADM2,DISTRICT
SM280446,105.6981,11.1116,Svay Chrum,ADM2,DISTRICT
SM280427,104.2745,13.6917,Svay Leu,ADM2,DISTRICT
SM280407,103.0833,13.0833,Svay Pao,ADM2,DISTRICT
SM280447,105.8101,11.0955,Svay Rieng,ADM2,DISTRICT
SM3376,105.8333,11.1667,Svay Rieng,ADM1,DISTRICT
SM280448,105.9419,11.1082,Svay Teab,ADM2,DISTRICT
SM293287,47.6667,58.25,Svechinskiy rayon,ADM2,DISTRICT
SM296449,13.2686,55.5432,Svedala,ADM2,DISTRICT
SM286398,5.3541,59.543,Sveio,ADM2,DISTRICT
SM277746,-16.0204,64.2677,Sveitarfélagið Hornafjörður,ADM2,DISTRICT
SM277789,-19.4457,65.5002,Sveitarfélagið Skagafjörður,ADM2,DISTRICT
SM277814,-21.0455,63.8871,Sveitarfélagið Árborg,ADM2,DISTRICT
SM271745,10.6139,55.0841,Svendborg (2007-),ADM2,DISTRICT
SM296599,13.0522,57.3925,Svenljunga,ADM2,DISTRICT
SM13860,26.3516,55.9091,Sventes pagasts,ADM2,DISTRICT
SM299576,39.5806,48.0685,Sverdlovs'ka,ADM2,DISTRICT
SM299577,39.6667,48,Sverdlovs'kyi,ADM2,DISTRICT
SM3377,62,58,Sverdlovsk,ADM1,DISTRICT
SM13368,74.6001,42.9028,Sverdlovskiy Rayon,ADM2,DISTRICT
SM296345,15.839,46.6475,Sveta Ana,ADM1:ADM2,DISTRICT
SM305351,16.7413,46.3328,Sveta Marija na Muri,ADM2,DISTRICT
SM276032,15.7797,45.7962,Sveta Nedelja,ADM2,DISTRICT
SM305675,14.0989,45.1493,Sveta Nedelja,ADM2,DISTRICT
SM4149,15.8892,46.5689,Sveta Trojica v Slovenskih Goricah,ADM1,DISTRICT
SM13861,23.6238,56.5604,Svetes pagasts,ADM2,DISTRICT
SM296347,15.9528,46.5232,Sveti Andraž v Slovenskih Goricah,ADM1:ADM2,DISTRICT
SM305603,15.4282,43.9616,Sveti Filip i Jakov,ADM2,DISTRICT
SM305511,16.3236,46.246,Sveti Ilija,ADM2,DISTRICT
SM276033,16.25,45.9667,Sveti Ivan Zelina,ADM2,DISTRICT
SM305717,16.6062,45.9453,Sveti Ivan Žabno,ADM2,DISTRICT
SM305352,16.3853,46.4285,Sveti Juraj na Bregu,ADM2,DISTRICT
SM296372,16.0267,46.5675,Sveti Jurij,ADM1:ADM2,DISTRICT
SM4150,15.7925,46.6131,Sveti Jurij v Slovenskih Goricah,ADM1,DISTRICT
SM305740,15.907,46.0822,Sveti Križ Začretje,ADM2,DISTRICT
SM305676,13.7432,45.1777,Sveti Lovreč,ADM2,DISTRICT
SM305353,16.3623,46.5254,Sveti Martin na Muri,ADM2,DISTRICT
SM3378,21.9833,41.9,Sveti Nikole,ADM1,DISTRICT
SM305718,16.4527,46.08,Sveti Petar Orehovec,ADM2,DISTRICT
SM305677,13.8659,45.1835,Sveti Petar u Šumi,ADM2,DISTRICT
SM305510,16.6044,46.2833,Sveti Đurđ,ADM2,DISTRICT
SM293044,20.25,54.8333,Svetlogorsk,ADM2,DISTRICT
SM294927,44.75,48.4167,Svetloyarskiy rayon,ADM2,DISTRICT
SM305678,13.8812,45.0864,Svetvinčenat,ADM2,DISTRICT
SM299469,30.3805,50.4853,Sviatoshyns'kyi,ADM2,DISTRICT
SM296155,21.5667,49.3,Svidník,ADM2,DISTRICT
SM262516,26.3167,41.8667,Svilengrad,ADM2,DISTRICT
SM291107,22.1049,44.5001,Svinita,ADM2,DISTRICT
SM262474,25.35,43.5333,Svishtov,ADM2,DISTRICT
SM271243,16.5,49.7333,Svitavy,ADM2,DISTRICT
SM13862,23.928,56.3747,Svitenes pagasts,ADM2,DISTRICT
SM299516,33.2462,49.0632,Svitlovods'kyi,ADM2,DISTRICT
SM17098,127.8333,51.5,Svobodnenskiy Rayon,ADM2,DISTRICT
SM262429,23.4,43,Svoge,ADM2,DISTRICT
SM9815,29.5,52.75,Svyetlahorski Rayon (excl. Svyetlahorsk),ADM2,DISTRICT
SM375432,72.4767,34.1413,Swabi,ADM2:ADM3,DISTRICT
SM18584,10.5,48.3333,Swabia,ADM2,DISTRICT
SM245748,9.9871,48.3868,Swabian,LANGUAGE,LANGUAGE
SM12777,-77.5333,17.9861,Swaby's Hope,ADM2,DISTRICT
SM245751,37.624,-8.2561,Swahili,LANGUAGE,LANGUAGE
SM250941,39.4,-9.35,Swahili-East Africa,ETHNICITY,ETHNICITY
SM250941,38.9,-4.72,Swahili-East Africa,ETHNICITY,ETHNICITY
SM250941,39.95,-2.62,Swahili-East Africa,ETHNICITY,ETHNICITY
SM250941,43.48,0.9,Swahili-East Africa,ETHNICITY,ETHNICITY
SM302016,-83.4927,35.4867,Swain,ADM2,DISTRICT
SM261210,-171.082,-11.056,Swains,ADM1:ADM2,DISTRICT
SM284497,14.6012,-22.632,Swakopmund,ADM2,DISTRICT
SM12778,-76.7744,18.0049,Swallowfield,ADM2,DISTRICT
SM245755,-95,56,Swampy Cree,LANGUAGE,LANGUAGE
SM257826,-88.67,51.44,Swampy Cree,ETHNICITY,ETHNICITY
SM257826,-88.67,51.44,Swampy Cree,ETHNICITY,ETHNICITY
SM257826,-88.67,51.44,Swampy Cree,ETHNICITY,ETHNICITY
SM257826,-83,53,Swampy Cree,ETHNICITY,ETHNICITY
SM257826,-83,53,Swampy Cree,ETHNICITY,ETHNICITY
SM257826,-83,53,Swampy Cree,ETHNICITY,ETHNICITY
SM257826,-83.88,53.62,Swampy Cree,ETHNICITY,ETHNICITY
SM257826,-83.88,53.62,Swampy Cree,ETHNICITY,ETHNICITY
SM257826,-83.88,53.62,Swampy Cree,ETHNICITY,ETHNICITY
SM261315,116.0875,-31.7704,Swan,ADM2,DISTRICT
SM261279,143.1681,-35.1006,Swan Hill,ADM2,DISTRICT
SM10169,-115.3986,54.7149,Swan Hills,ADM2,DISTRICT
SM274755,-4,51.5833,Swansea,ADM1:ADM2,DISTRICT
SM375429,72.4993,35.2416,Swat,ADM2:ADM3,DISTRICT
SM245757,31.5,-26,Swati,LANGUAGE,LANGUAGE
SM250725,31.05,-26.71,Swati,ETHNICITY,ETHNICITY
SM250725,31.05,-26.71,Swati,ETHNICITY,ETHNICITY
SM250725,32,-27,Swati,ETHNICITY,ETHNICITY
SM250725,32,-27,Swati,ETHNICITY,ETHNICITY
SM257829,27.99,-12.59,Swawka,ETHNICITY,ETHNICITY
SM245759,17.3895,59.8006,Swedish,LANGUAGE,LANGUAGE
SM257830,16.58,60.11,Swedish,ETHNICITY,ETHNICITY
SM245758,16.1133,58.929,Swedish Sign Language,LANGUAGE,LANGUAGE
SM301665,-109.9411,45.8137,Sweet Grass,ADM2,DISTRICT
SM303305,-108.8794,41.6595,Sweetwater,ADM2,DISTRICT
SM301412,-95.6814,45.2827,Swift,ADM2,DISTRICT
SM12779,-76.5787,18.1577,Swift River,ADM2,DISTRICT
SM274701,-1.75,51.5833,Swindon,ADM1:ADM2,DISTRICT
SM302772,-101.735,34.5304,Swisher,ADM2,DISTRICT
SM257832,7.27,47.31,Swiss,ETHNICITY,ETHNICITY
SM245765,6.7843,46.7225,Swiss-French Sign Language,LANGUAGE,LANGUAGE
SM245764,9.0756,47.1069,Swiss-German Sign Language,LANGUAGE,LANGUAGE
SM245763,8.7911,46.3076,Swiss-Italian Sign Language,LANGUAGE,LANGUAGE
SM300767,-85.037,38.8262,Switzerland,ADM2,DISTRICT
SM294947,41.25,60,Syamzhenskiy rayon,ADM2,DISTRICT
SM245770,34.337,1.2805,Syan,LANGUAGE,LANGUAGE
SM375359,83.8272,28.045,Syangja,ADM2:ADM3,DISTRICT
SM262673,29.8333,54.8333,Syanno,ADM2,DISTRICT
SM3380,10.345,55.3791,Syddanmark,ADM1,DISTRICT
SM271698,10.5208,56.3125,Syddjurs,ADM2,DISTRICT
SM245772,150.3491,-33.5293,Sydney,LANGUAGE,LANGUAGE
SM261465,151.2084,-33.8678,Sydney,ADM2,DISTRICT
SM245773,-5.8581,10.5954,Syenara Senoufo,LANGUAGE,LANGUAGE
SM11056,33.8495,35.2866,Sygkrasi,ADM2,DISTRICT
SM257834,-119.63,49.46,Syilx,ETHNICITY,ETHNICITY
SM257834,-119.63,49.46,Syilx,ETHNICITY,ETHNICITY
SM257834,-119.5,48.8,Syilx,ETHNICITY,ETHNICITY
SM257834,-119.5,48.8,Syilx,ETHNICITY,ETHNICITY
SM286436,6.5783,62.3896,Sykkylven,ADM2,DISTRICT
SM11057,33.1056,34.882,Sykopetra,ADM2,DISTRICT
SM17099,50.5,61.6667,Syktyvdinskiy rayon,ADM2,DISTRICT
SM262287,91.7667,24.9167,Sylhet,ADM2,DISTRICT
SM3381,91.6667,24.6667,Sylhet,ADM1,DISTRICT
SM245776,92.2535,24.8372,Sylheti,LANGUAGE,LANGUAGE
SM323977,-114.0885,52.3049,Sylvan Lake,ADM2:ADM3,DISTRICT
SM18279,35.5296,48.3258,Synelnykove Raion,ADM2,DISTRICT
SM11058,33.29,35.1045,Synoikismos Anthoupolis,ADM2,DISTRICT
SM4349,-2.3333,7.3333,Synyani (2008-),ADM2,DISTRICT
SM277905,15.2792,37.0881,Syracuse,ADM2,DISTRICT
SM13263,66.05,45,Syrdariya Audany,ADM2,DISTRICT
SM257835,38.9165,34.75,Syrian,ETHNICITY,ETHNICITY
SM257835,38.9165,34.75,Syrian,ETHNICITY,ETHNICITY
SM257835,38.9165,34.75,Syrian,ETHNICITY,ETHNICITY
SM257835,36,36,Syrian,ETHNICITY,ETHNICITY
SM257835,36,36,Syrian,ETHNICITY,ETHNICITY
SM257835,36,36,Syrian,ETHNICITY,ETHNICITY
SM257835,36.8701,35.1289,Syrian,ETHNICITY,ETHNICITY
SM257835,36.8701,35.1289,Syrian,ETHNICITY,ETHNICITY
SM257835,36.8701,35.1289,Syrian,ETHNICITY,ETHNICITY
SM11059,32.9338,35.2191,Syrianochori,ADM2,DISTRICT
SM245779,92.9029,24.9437,Syriem,LANGUAGE,LANGUAGE
SM279896,52.75,50.25,Syrymskiy,ADM2,DISTRICT
SM294554,60.75,56.5,Sysertskiy rayon,ADM2,DISTRICT
SM11060,33.1798,35.297,Sysklipos,ADM2,DISTRICT
SM293307,50,61,Sysol'skiy rayon,ADM2,DISTRICT
SM3382,46.25,39.3333,Syunik,ADM1,DISTRICT
SM3383,22.1667,48,Szabolcs-Szatmár-Bereg,ADM1,DISTRICT
SM288810,16.3773,52.5839,Szamotuły,ADM2,DISTRICT
SM276229,20.6371,46.8289,Szarvas,ADM2,DISTRICT
SM288834,14.6242,53.4297,Szczecin,ADM2,DISTRICT
SM288832,16.5896,53.7377,Szczecinek,ADM2,DISTRICT
SM288778,21.0703,53.5418,Szczytno,ADM2,DISTRICT
SM276252,20.1376,46.2954,Szeged,ADM1:ADM2,DISTRICT
SM276230,21.17,47,Szeghalmi,ADM2,DISTRICT
SM11590,18.4135,47.1653,Szekesfehervari Jaras,ADM1:ADM2,DISTRICT
SM11591,18.6789,46.34,Szekszardi jaras,ADM2,DISTRICT
SM276152,19.0252,47.7115,Szentendre,ADM2,DISTRICT
SM276253,20.3731,46.6601,Szentes,ADM2,DISTRICT
SM276196,16.3036,46.9367,Szentgotthárd,ADM2,DISTRICT
SM276215,18.0153,46.0469,Szentlőrinci,ADM2,DISTRICT
SM276234,21.1687,48.1493,Szerencs,ADM2,DISTRICT
SM11592,19.0283,47.3057,Szigetszentmiklosi Jaras,ADM2,DISTRICT
SM276216,17.8039,46.0734,Szigetvári,ADM2,DISTRICT
SM276235,20.9551,48.3003,Szikszó,ADM2,DISTRICT
SM276153,18.8544,47.9342,Szob,ADM2,DISTRICT
SM276133,20.1984,47.2248,Szolnok,ADM1:ADM2,DISTRICT
SM276197,16.6395,47.2104,Szombathely,ADM1:ADM2,DISTRICT
SM288705,19.22,53.9015,Sztum,ADM2,DISTRICT
SM288978,20.8426,51.256,Szydłowiec,ADM2,DISTRICT
SM276148,19.5516,48.073,Szécsény,ADM2,DISTRICT
SM270883,-72.2105,6.0521,Sácama,ADM2,DISTRICT
SM270815,-73.5435,5.5744,Sáchica,ADM2,DISTRICT
SM243584,-69.3843,5.3012,Sáliba,LANGUAGE,LANGUAGE
SM271829,-69.6465,19.1695,Sánchez,ADM2,DISTRICT
SM286764,-77.9259,-7.7854,Sánchez Carrión,ADM2,DISTRICT
SM3099,-70.1667,19,Sánchez Ramírez,ADM1,DISTRICT
SM276263,18.6072,46.8404,Sárbogárd,ADM2,DISTRICT
SM282606,-111.4283,31.2326,Sáric,ADM2,DISTRICT
SM276232,21.5272,48.2958,Sárospatak,ADM2,DISTRICT
SM276195,16.933,47.2387,Sárvár,ADM2,DISTRICT
SM276212,18.1376,46.2725,Sásdi,ADM2,DISTRICT
SM267659,-38.4889,-11.6317,Sátiro Dias,ADM2,DISTRICT
SM276233,21.5439,48.459,Sátoraljaújhely,ADM2,DISTRICT
SM289403,-7.676,40.7625,Sátão,ADM2,DISTRICT
SM264712,-53.6042,-26.056,São Antonio de Sudoeste,ADM2,DISTRICT
SM267848,-40.9459,-4.0496,São Benedito,ADM2,DISTRICT
SM262883,-43.7404,-3.331,São Benedito do Rio Preto,ADM2,DISTRICT
SM264896,-35.9017,-8.7721,São Benedito do Sul,ADM2,DISTRICT
SM264306,-37.759,-6.8845,São Bentinho,ADM2,DISTRICT
SM264307,-37.4714,-6.4734,São Bento,ADM2,DISTRICT
SM262884,-45.1358,-2.8302,São Bento,ADM2,DISTRICT
SM263904,-45.0665,-21.5598,São Bento Abade,ADM2,DISTRICT
SM265469,-35.9891,-5.1441,São Bento do Norte,ADM2,DISTRICT
SM266927,-45.6839,-22.6709,São Bento do Sapucaí,ADM2,DISTRICT
SM266292,-49.3832,-26.3121,São Bento do Sul,ADM2,DISTRICT
SM267175,-47.9963,-5.9503,São Bento do Tocantins,ADM2,DISTRICT
SM265470,-36.0592,-6.3997,São Bento do Trairí,ADM2,DISTRICT
SM264897,-36.4812,-8.5373,São Bento do Una,ADM2,DISTRICT
SM266293,-52.9692,-26.4626,São Bernardino,ADM2,DISTRICT
SM262885,-42.4056,-3.4361,São Bernardo,ADM2,DISTRICT
SM266928,-46.5441,-23.8102,São Bernardo do Campo,ADM2,DISTRICT
SM266294,-48.9397,-27.9566,São Bonifácio,ADM2,DISTRICT
SM265892,-55.7899,-28.7638,São Borja,ADM2,DISTRICT
SM265117,-42.9757,-8.9738,São Braz do Piauí,ADM2,DISTRICT
SM265424,-36.8504,-10.1241,São Brás,ADM2,DISTRICT
SM289504,-7.8755,37.1956,São Brás de Alportel,ADM2,DISTRICT
SM263905,-43.9692,-20.6219,São Brás do Suaçuí,ADM2,DISTRICT
SM264098,-48.049,-0.7982,São Caetano de Odivelas,ADM2,DISTRICT
SM266929,-46.5624,-23.6243,São Caetano do Sul,ADM2,DISTRICT
SM264898,-36.1616,-8.3393,São Caitano,ADM2,DISTRICT
SM266930,-47.8747,-21.9065,São Carlos,ADM2,DISTRICT
SM266296,-53.0355,-27.0445,São Carlos,ADM2,DISTRICT
SM264713,-52.5207,-23.3548,São Carlos do Ivaí,ADM2,DISTRICT
SM267138,-37.2412,-10.9445,São Cristóvão,ADM2,DISTRICT
SM266297,-50.3773,-27.2671,São Cristóvão do Sul,ADM2,DISTRICT
SM267645,-45.5638,-12.8927,São Desidério,ADM2,DISTRICT
SM267139,-37.5622,-10.7819,São Domingos,ADM2,DISTRICT
SM266298,-52.557,-26.5643,São Domingos,ADM2,DISTRICT
SM268167,-46.5109,-13.5344,São Domingos,ADM2,DISTRICT
SM267646,-39.5992,-11.4846,São Domingos,ADM2,DISTRICT
SM3133,-23.5333,15.0167,São Domingos,ADM1,DISTRICT
SM263906,-42.034,-19.5199,São Domingos das Dores,ADM2,DISTRICT
SM264308,-37.9166,-6.8073,São Domingos de Pombal,ADM2,DISTRICT
SM264099,-48.7276,-5.7193,São Domingos do Araguaia,ADM2,DISTRICT
SM262886,-44.5828,-6.858,São Domingos do Azeitão,ADM2,DISTRICT
SM264100,-47.7784,-1.8716,São Domingos do Capim,ADM2,DISTRICT
SM264309,-36.3639,-7.5675,São Domingos do Cariri,ADM2,DISTRICT
SM262887,-44.3484,-5.6397,São Domingos do Maranhão,ADM2,DISTRICT
SM268026,-40.5545,-19.1297,São Domingos do Norte,ADM2,DISTRICT
SM263907,-42.8842,-19.8978,São Domingos do Prata,ADM2,DISTRICT
SM265893,-51.8733,-28.5445,São Domingos do Sul,ADM2,DISTRICT
SM267647,-39.0962,-12.8653,São Felipe,ADM2,DISTRICT
SM266109,-61.4774,-11.8977,São Felipe d'Oeste,ADM2,DISTRICT
SM265471,-37.139,-6.2973,São Fernando,ADM2,DISTRICT
SM265313,-41.7891,-21.7163,São Fidélis,ADM2,DISTRICT
SM3134,-24.4333,14.9333,São Filipe,ADM1,DISTRICT
SM266931,-50.6746,-20.3646,São Francisco,ADM2,DISTRICT
SM267140,-36.8639,-10.3306,São Francisco,ADM2,DISTRICT
SM264310,-38.0378,-6.633,São Francisco,ADM2,DISTRICT
SM263912,-44.8431,-15.8837,São Francisco,ADM2,DISTRICT
SM281405,113.5634,22.1282,São Francisco Xavier,ADM1:ADM2,DISTRICT
SM265894,-55.1357,-29.4309,São Francisco de Assis,ADM2,DISTRICT
SM265119,-41.4806,-8.1233,São Francisco de Assis do Piauí,ADM2,DISTRICT
SM268168,-49.2489,-15.9522,São Francisco de Goias,ADM2,DISTRICT
SM265314,-41.1491,-21.38,São Francisco de Itabapoana,ADM2,DISTRICT
SM263909,-44.9923,-20.7187,São Francisco de Oliveira,ADM2,DISTRICT
SM265896,-50.4493,-29.2425,São Francisco de Paula,ADM2,DISTRICT
SM263910,-49.8104,-19.7718,São Francisco de Sales,ADM2,DISTRICT
SM262889,-47.3489,-5.1388,São Francisco do Brejão,ADM2,DISTRICT
SM267650,-38.6334,-12.6456,São Francisco do Conde,ADM2,DISTRICT
SM263911,-42.2849,-20.7853,São Francisco do Glória,ADM2,DISTRICT
SM266110,-63.1265,-12.3831,São Francisco do Guaporé,ADM2,DISTRICT
SM262890,-42.9525,-6.2335,São Francisco do Maranhão,ADM2,DISTRICT
SM265473,-38.1625,-5.9684,São Francisco do Oeste,ADM2,DISTRICT
SM264103,-47.7571,-1.1464,São Francisco do Pará,ADM2,DISTRICT
SM265120,-42.5509,-7.169,São Francisco do Piauí,ADM2,DISTRICT
SM266299,-48.6325,-26.2655,São Francisco do Sul,ADM2,DISTRICT
SM267649,-38.9998,-12.6781,São Félix,ADM2,DISTRICT
SM263109,-52.1392,-11.4423,São Félix Xingu,ADM2,DISTRICT
SM262888,-44.8346,-6.9488,São Félix de Balsas,ADM2,DISTRICT
SM263908,-41.4446,-18.5589,São Félix de Minas,ADM2,DISTRICT
SM267648,-43.9814,-13.4147,São Félix do Coribe,ADM2,DISTRICT
SM265118,-42.1083,-5.8842,São Félix do Piauí,ADM2,DISTRICT
SM267176,-46.7278,-10.0649,São Félix do Tocantins,ADM2,DISTRICT
SM264102,-52.1935,-7.2134,São Félix do Xingu,ADM2,DISTRICT
SM267651,-41.5541,-10.9558,São Gabriel,ADM2,DISTRICT
SM268027,-40.5252,-18.9736,São Gabriel da Palha,ADM2,DISTRICT
SM267349,-68.0324,0.2404,São Gabriel de Cahoeira,ADM2,DISTRICT
SM263084,-54.4487,-19.1317,São Gabriel do Oeste,ADM2,DISTRICT
SM263916,-42.8283,-20.9083,São Geraldo,ADM2,DISTRICT
SM263914,-42.3106,-18.914,São Geraldo da Piedade,ADM2,DISTRICT
SM264104,-48.7172,-6.2136,São Geraldo do Araguaia,ADM2,DISTRICT
SM263915,-41.3625,-18.918,São Geraldo do Baixio,ADM2,DISTRICT
SM265315,-43.0411,-22.8156,São Gonçalo,ADM2,DISTRICT
SM263917,-45.6132,-18.2331,São Gonçalo do Abaeté,ADM2,DISTRICT
SM265474,-35.3325,-5.7724,São Gonçalo do Amarante,ADM2,DISTRICT
SM267849,-39.0673,-3.5661,São Gonçalo do Amarante,ADM2,DISTRICT
SM265121,-45.4239,-10.0763,São Gonçalo do Gurguéia,ADM2,DISTRICT
SM263918,-44.8402,-19.9729,São Gonçalo do Pará,ADM2,DISTRICT
SM265122,-42.6702,-6.0203,São Gonçalo do Piauí,ADM2,DISTRICT
SM263919,-43.3284,-19.8118,São Gonçalo do Rio Abaixo,ADM2,DISTRICT
SM263920,-45.6012,-21.9105,São Gonçalo do Sapucaí,ADM2,DISTRICT
SM267652,-38.9777,-12.4085,São Gonçalo dos Campos,ADM2,DISTRICT
SM263921,-45.9511,-19.3744,São Gotardo,ADM2,DISTRICT
SM265898,-51.9252,-30.315,São Jerônimo,ADM2,DISTRICT
SM264714,-50.7899,-23.6871,São Jerônimo da Serra,ADM2,DISTRICT
SM266304,-49.998,-28.3018,São Joaquim,ADM2,DISTRICT
SM266937,-47.928,-20.5396,São Joaquim da Barra,ADM2,DISTRICT
SM263937,-44.2498,-20.0578,São Joaquim de Bicas,ADM2,DISTRICT
SM264902,-35.8598,-8.4718,São Joaquin do Monte,ADM2,DISTRICT
SM265901,-51.7308,-28.4989,São Jorge,ADM2,DISTRICT
SM264719,-52.9486,-25.6632,São Jorge d'Oeste,ADM2,DISTRICT
SM264720,-52.3036,-23.4384,São Jorge do Ivaí,ADM2,DISTRICT
SM264722,-53.8855,-23.7495,São Jorge do Patrocínio,ADM2,DISTRICT
SM266308,-48.6584,-27.5798,São José,ADM2,DISTRICT
SM263938,-46.2853,-20.7505,São José da Barra,ADM2,DISTRICT
SM266938,-47.6294,-20.5993,São José da Bela Vista,ADM2,DISTRICT
SM264723,-49.6519,-23.9648,São José da Boa Vista,ADM2,DISTRICT
SM264903,-35.1743,-8.8724,São José da Coroa Grande,ADM2,DISTRICT
SM264315,-38.0939,-6.9425,São José da Lagoa Tapada,ADM2,DISTRICT
SM265425,-36.0339,-8.975,São José da Laje,ADM2,DISTRICT
SM263939,-43.9911,-19.6902,São José da Lapa,ADM2,DISTRICT
SM263940,-42.1039,-18.3306,São José da Safira,ADM2,DISTRICT
SM265426,-37.4903,-9.5327,São José da Tapera,ADM2,DISTRICT
SM263941,-44.5531,-19.6891,São José da Varginha,ADM2,DISTRICT
SM267654,-39.3502,-15.0719,São José da Vitória,ADM2,DISTRICT
SM265902,-53.1296,-27.7974,São José das Missões,ADM2,DISTRICT
SM264724,-54.1272,-24.8346,São José das Palmeiras,ADM2,DISTRICT
SM264316,-38.3448,-7.2592,São José de Caiana,ADM2,DISTRICT
SM264317,-37.3822,-6.8024,São José de Espinharas,ADM2,DISTRICT
SM265476,-35.3061,-6.0428,São José de Mipibu,ADM2,DISTRICT
SM264318,-38.5261,-7.1107,São José de Piranhas,ADM2,DISTRICT
SM264319,-38.0847,-7.7065,São José de Princesa,ADM2,DISTRICT
SM262897,-44.1115,-2.5807,São José de Ribamar (pre-1959),ADM2,DISTRICT
SM265318,-41.9526,-21.3783,São José de Ubá,ADM2,DISTRICT
SM263942,-45.5202,-22.3406,São José do Alegre,ADM2,DISTRICT
SM266939,-44.5855,-22.7372,São José do Barreiro,ADM2,DISTRICT
SM264320,-37.3793,-6.2373,São José do Belmonte,ADM2,DISTRICT
SM264321,-37.3161,-7.1372,São José do Bonfim,ADM2,DISTRICT
SM268028,-41.6642,-20.986,São José do Calçado,ADM2,DISTRICT
SM265477,-35.7444,-6.2795,São José do Campestre,ADM2,DISTRICT
SM266305,-53.5629,-26.4813,São José do Cedro,ADM2,DISTRICT
SM266307,-50.6566,-27.604,São José do Cerrito,ADM2,DISTRICT
SM265130,-41.9012,-3.743,São José do Divino,ADM2,DISTRICT
SM263943,-41.3725,-18.401,São José do Divino,ADM2,DISTRICT
SM263944,-42.6918,-19.9236,São José do Goiabal,ADM2,DISTRICT
SM265903,-52.2728,-29.0644,São José do Herval,ADM2,DISTRICT
SM265904,-51.2498,-29.5436,São José do Hortêncio,ADM2,DISTRICT
SM265905,-54.1216,-27.7353,São José do Inhacorá,ADM2,DISTRICT
SM263945,-42.674,-18.2516,São José do Jacuri,ADM2,DISTRICT
SM267655,-39.9038,-11.4683,São José do Jacuípe,ADM2,DISTRICT
SM263947,-41.7633,-20.0245,São José do Mantimento,ADM2,DISTRICT
SM265907,-51.5572,-27.7558,São José do Ouro,ADM2,DISTRICT
SM265131,-42.4936,-7.4954,São José do Peixe,ADM2,DISTRICT
SM265132,-41.517,-6.841,São José do Piauí,ADM2,DISTRICT
SM263111,-54.285,-16.4455,São José do Povo,ADM2,DISTRICT
SM263112,-56.8507,-13.527,São José do Rio Claro,ADM2,DISTRICT
SM266940,-46.8998,-21.6011,São José do Rio Pardo,ADM2,DISTRICT
SM266941,-49.3567,-20.7691,São José do Rio Preto,ADM2,DISTRICT
SM264323,-36.8034,-6.8278,São José do Sabugi,ADM2,DISTRICT
SM265478,-36.851,-6.5025,São José do Seridó,ADM2,DISTRICT
SM265319,-42.9165,-22.1706,São José do Vale do Rio Preto,ADM2,DISTRICT
SM265908,-49.9467,-28.6831,São José dos Ausentes,ADM2,DISTRICT
SM262898,-44.5946,-5.0592,São José dos Basílios,ADM2,DISTRICT
SM266942,-45.9218,-23.0983,São José dos Campos,ADM2,DISTRICT
SM264324,-36.8477,-7.4164,São José dos Cordeiros,ADM2,DISTRICT
SM264725,-49.0882,-25.6695,São José dos Pinhais,ADM2,DISTRICT
SM263114,-58.2972,-15.5666,São José dos Quatro Marcos,ADM2,DISTRICT
SM264325,-35.3741,-7.251,São José dos Ramos,ADM2,DISTRICT
SM264718,-52.8042,-25.7839,São João,ADM2,DISTRICT
SM266300,-48.8723,-27.3414,São João Batista,ADM2,DISTRICT
SM262891,-44.8155,-3.0081,São João Batista,ADM2,DISTRICT
SM263922,-46.4458,-20.5312,São João Batista do Glória,ADM2,DISTRICT
SM263934,-42.7822,-18.5085,São João Evangelista,ADM2,DISTRICT
SM263936,-43.015,-21.5862,São João Nepomuceno,ADM2,DISTRICT
SM265129,-42.3401,-8.2758,São João Piaui,ADM2,DISTRICT
SM268169,-47.4141,-14.4291,São João d'Aliança,ADM2,DISTRICT
SM266128,-59.8489,0.6818,São João da Baliza,ADM2,DISTRICT
SM265316,-41.0955,-21.7366,São João da Barra,ADM2,DISTRICT
SM266933,-46.8164,-21.9796,São João da Boa Vista,ADM2,DISTRICT
SM265123,-41.3823,-6.7276,São João da Canabrava,ADM2,DISTRICT
SM265124,-41.2155,-4.0936,São João da Fronteira,ADM2,DISTRICT
SM263923,-44.3421,-16.8787,São João da Lagoa,ADM2,DISTRICT
SM289216,-8.4928,40.8977,São João da Madeira,ADM2,DISTRICT
SM263925,-45.933,-21.9352,São João da Mata,ADM2,DISTRICT
SM268170,-50.3532,-16.8239,São João da Paraúna,ADM2,DISTRICT
SM289401,-7.4338,41.1108,São João da Pesqueira,ADM2,DISTRICT
SM264105,-47.978,-0.8536,São João da Ponta,ADM2,DISTRICT
SM263926,-43.8305,-15.9031,São João da Ponte,ADM2,DISTRICT
SM265125,-41.8579,-5.4417,São João da Serra,ADM2,DISTRICT
SM265899,-51.8388,-27.7968,São João da Urtiga,ADM2,DISTRICT
SM265127,-41.9292,-6.9473,São João da Varjota,ADM2,DISTRICT
SM266934,-50.3896,-20.4191,São João das Duas Ponte,ADM2,DISTRICT
SM263927,-44.2126,-14.8867,São João das Missões,ADM2,DISTRICT
SM266935,-50.361,-20.512,São João de Iracema,ADM2,DISTRICT
SM265317,-43.365,-22.7993,São João de Meriti,ADM2,DISTRICT
SM264106,-47.2121,-0.7804,São João de Pirabas,ADM2,DISTRICT
SM263928,-44.2448,-21.2503,São João del Rei,ADM2,DISTRICT
SM264107,-48.7493,-5.443,São João do Araguaia,ADM2,DISTRICT
SM265128,-42.4697,-3.8033,São João do Arraial,ADM2,DISTRICT
SM267850,-38.6365,-7.3881,São João do Belm,ADM2,DISTRICT
SM264899,-38.7876,-7.8602,São João do Belmonte,ADM2,DISTRICT
SM264715,-52.2854,-22.8294,São João do Caiuá,ADM2,DISTRICT
SM264312,-36.486,-7.4847,São João do Cariri,ADM2,DISTRICT
SM262893,-46.3924,-3.5592,São João do Carú,ADM2,DISTRICT
SM266301,-48.8005,-26.6027,São João do Itaperiú,ADM2,DISTRICT
SM264716,-51.8826,-23.9792,São João do Ivaí,ADM2,DISTRICT
SM267851,-38.2688,-5.3188,São João do Jaguaribe,ADM2,DISTRICT
SM263929,-42.1563,-20.3835,São João do Manhuaçu,ADM2,DISTRICT
SM263930,-41.1575,-18.7395,São João do Manteninha,ADM2,DISTRICT
SM266302,-53.5922,-27.0831,São João do Oeste,ADM2,DISTRICT
SM263931,-42.1741,-19.3485,São João do Oriente,ADM2,DISTRICT
SM263932,-44.5406,-16.5339,São João do Pacuí,ADM2,DISTRICT
SM263933,-41.9569,-15.3765,São João do Paraíso,ADM2,DISTRICT
SM262894,-46.8893,-6.4017,São João do Paraíso,ADM2,DISTRICT
SM266936,-51.6686,-21.2052,São João do Pau d'Alho,ADM2,DISTRICT
SM265900,-53.4729,-29.6456,São João do Polêsine,ADM2,DISTRICT
SM264313,-38.4294,-6.7185,São João do Rio do Peixe,ADM2,DISTRICT
SM265475,-37.1543,-6.6965,São João do Sabugi,ADM2,DISTRICT
SM262895,-43.7395,-4.965,São João do Soter,ADM2,DISTRICT
SM266303,-49.851,-29.2071,São João do Sul,ADM2,DISTRICT
SM264314,-36.7977,-8.1091,São João do Tigre,ADM2,DISTRICT
SM264717,-50.2624,-25.7041,São João do Triunfo,ADM2,DISTRICT
SM262896,-43.5978,-6.5178,São João dos Patos,ADM2,DISTRICT
SM265133,-40.7973,-7.0599,São Juliao,ADM2,DISTRICT
SM265909,-51.1362,-29.7425,São Leopoldo,ADM2,DISTRICT
SM263948,-45.0323,-22.1175,São Lourenço,ADM2,DISTRICT
SM281409,113.5359,22.1923,São Lourenço,ADM1:ADM2,DISTRICT
SM264904,-35.1085,-8.0115,São Lourenço da Mata,ADM2,DISTRICT
SM266944,-46.9393,-23.8478,São Lourenço da Serra,ADM2,DISTRICT
SM266309,-52.9047,-26.4688,São Lourenço do Oeste,ADM2,DISTRICT
SM265134,-42.403,-9.1334,São Lourenço do Piauí,ADM2,DISTRICT
SM265910,-52.0922,-31.2103,São Lourenço do Sul,ADM2,DISTRICT
SM3135,-23.6,15.0667,São Lourenço dos Órgãos,ADM1,DISTRICT
SM266310,-49.1754,-28.3425,São Ludgero,ADM2,DISTRICT
SM262900,-44.3044,-2.6495,São Luis,ADM2,DISTRICT
SM262899,-44.716,-4.3787,São Luis Gonzaga do Maranhao,ADM2,DISTRICT
SM265135,-41.2765,-6.778,São Luis do Piauí,ADM2,DISTRICT
SM266129,-60.1285,0.8296,São Luiz,ADM2,DISTRICT
SM267852,-39.2488,-3.6412,São Luiz do Curu,ADM2,DISTRICT
SM268172,-49.2821,-14.8928,São Luiz do Norte,ADM2,DISTRICT
SM266945,-45.2272,-23.2606,São Luiz do Paraitinga,ADM2,DISTRICT
SM268171,-50.3881,-16.4542,São Luís de Montes Belos,ADM2,DISTRICT
SM265428,-35.5719,-9.2628,São Luís do Quitunde,ADM2,DISTRICT
SM281408,113.5507,22.2001,São Lázaro,ADM1:ADM2,DISTRICT
SM264326,-37.0913,-6.9211,São Mamede,ADM2,DISTRICT
SM264726,-52.5889,-23.3836,São Manoel do Paraná,ADM2,DISTRICT
SM266946,-48.5634,-22.6823,São Manuel,ADM2,DISTRICT
SM265912,-51.049,-28.9687,São Marcos,ADM2,DISTRICT
SM266311,-48.9856,-28.1051,São Martinho,ADM2,DISTRICT
SM265914,-53.9661,-27.7278,São Martinho,ADM2,DISTRICT
SM265913,-53.8845,-29.4579,São Martinho da Serra,ADM2,DISTRICT
SM268029,-40.0552,-18.7862,São Mateus,ADM2,DISTRICT
SM262901,-44.572,-3.9797,São Mateus do Maranhão,ADM2,DISTRICT
SM265480,-38.4695,-6.2022,São Miguel,ADM2,DISTRICT
SM3136,-23.65,15.2,São Miguel,ADM1,DISTRICT
SM266947,-47.9999,-23.9377,São Miguel Arcanjo,ADM2,DISTRICT
SM264327,-35.2017,-7.2171,São Miguel Taipu,ADM2,DISTRICT
SM265140,-41.6163,-5.7049,São Miguel Tapuio,ADM2,DISTRICT
SM265136,-42.2708,-5.8179,São Miguel da Baixa Grande,ADM2,DISTRICT
SM266312,-53.2572,-26.686,São Miguel da Boa Vista,ADM2,DISTRICT
SM267656,-39.434,-13.0431,São Miguel das Matas,ADM2,DISTRICT
SM265915,-54.5082,-28.7243,São Miguel das Misses,ADM2,DISTRICT
SM265479,-35.713,-5.1866,São Miguel de Touros,ADM2,DISTRICT
SM267141,-37.3586,-10.3686,São Miguel do Aleixo,ADM2,DISTRICT
SM263949,-42.6979,-20.7348,São Miguel do Anta,ADM2,DISTRICT
SM268173,-50.265,-12.9863,São Miguel do Araguaia,ADM2,DISTRICT
SM265139,-42.388,-7.5994,São Miguel do Fidalgo,ADM2,DISTRICT
SM264108,-47.5346,-1.5974,São Miguel do Guamá,ADM2,DISTRICT
SM266111,-62.9353,-11.7078,São Miguel do Guaporé,ADM2,DISTRICT
SM264728,-54.2321,-25.3656,São Miguel do Iguaçu,ADM2,DISTRICT
SM266313,-53.5194,-26.7187,São Miguel do Oeste,ADM2,DISTRICT
SM268174,-48.6619,-16.9996,São Miguel do Passa Quatro,ADM2,DISTRICT
SM267177,-47.6082,-5.5346,São Miguel do Tocantins,ADM2,DISTRICT
SM265430,-35.3842,-9.2542,São Miguel dos Milagres,ADM2,DISTRICT
SM265916,-55.2559,-28.2236,São Nicolau,ADM2,DISTRICT
SM268175,-49.8329,-15.3443,São Patrício,ADM2,DISTRICT
SM266948,-46.635,-23.627,São Paulo,ADM2,DISTRICT
SM3137,-49,-22,São Paulo,ADM1:ADMX,DISTRICT
SM243881,-46.7609,-23.5708,São Paulo Kaingáng,LANGUAGE,LANGUAGE
SM243881,-46.7609,-23.5708,São Paulo Kaingáng,LANGUAGE,LANGUAGE
SM243881,-50.4299,-21.7719,São Paulo Kaingáng,LANGUAGE,LANGUAGE
SM243881,-50.4299,-21.7719,São Paulo Kaingáng,LANGUAGE,LANGUAGE
SM265918,-54.9551,-27.9622,São Paulo das Missões,ADM2,DISTRICT
SM267350,-69.5814,-4.6509,São Paulo de Olivença,ADM2,DISTRICT
SM265481,-35.7533,-5.8899,São Paulo do Potengi,ADM2,DISTRICT
SM266950,-47.9044,-22.5596,São Pedro,ADM2,DISTRICT
SM265482,-35.6255,-5.856,São Pedro,ADM2,DISTRICT
SM265320,-42.1316,-22.8503,São Pedro da Aldeia,ADM2,DISTRICT
SM263115,-54.7796,-15.9516,São Pedro da Cipa,ADM2,DISTRICT
SM265919,-51.5144,-29.4213,São Pedro da Serra,ADM2,DISTRICT
SM263950,-46.6476,-21.1129,São Pedro da União,ADM2,DISTRICT
SM262902,-48.361,-5.1615,São Pedro da Água Branca,ADM2,DISTRICT
SM266314,-48.8392,-27.5728,São Pedro de Alcântara,ADM2,DISTRICT
SM265920,-54.9034,-28.1556,São Pedro do Butiá,ADM2,DISTRICT
SM264729,-53.8877,-24.9093,São Pedro do Iguaçu,ADM2,DISTRICT
SM264730,-51.8695,-23.8334,São Pedro do Ivaí,ADM2,DISTRICT
SM264731,-53.1658,-22.7685,São Pedro do Paraná,ADM2,DISTRICT
SM265141,-42.7717,-5.8214,São Pedro do Piauí,ADM2,DISTRICT
SM263951,-42.5957,-18.3545,São Pedro do Suaçuí,ADM2,DISTRICT
SM289402,-8.0902,40.8007,São Pedro do Sul,ADM2,DISTRICT
SM265921,-54.2526,-29.6229,São Pedro do Sul,ADM2,DISTRICT
SM266949,-49.743,-22.6964,São Pedro do Turvo,ADM2,DISTRICT
SM262904,-46.7074,-6.8196,São Pedro dos Crentes,ADM2,DISTRICT
SM263952,-42.563,-20.0652,São Pedro dos Ferros,ADM2,DISTRICT
SM265484,-36.8952,-5.8566,São Rafael,ADM2,DISTRICT
SM265142,-42.6084,-9.0522,São Raimundo Nonato,ADM2,DISTRICT
SM262905,-45.7431,-6.9585,São Raimundo das Mangabeiras,ADM2,DISTRICT
SM262906,-45.0689,-5.1103,São Raimundo do Doca Bezerra,ADM2,DISTRICT
SM262907,-44.991,-4.9814,São Roberto,ADM2,DISTRICT
SM263953,-45.4678,-16.3591,São Romão,ADM2,DISTRICT
SM266951,-47.1128,-23.5401,São Roque,ADM2,DISTRICT
SM263954,-46.4729,-20.1674,São Roque de Minas,ADM2,DISTRICT
SM268030,-40.6714,-19.7303,São Roque do Canaã,ADM2,DISTRICT
SM289383,-28.2926,38.4882,São Roque do Pico,ADM2,DISTRICT
SM3138,-23.6333,15.0833,São Salvador do Mundo,ADM1,DISTRICT
SM267178,-48.4018,-12.5495,São Salvador do Tocantins,ADM2,DISTRICT
SM263963,-42.5988,-21.0288,São Sebastio da Vargem Alegre,ADM2,DISTRICT
SM266953,-45.4108,-23.8085,São Sebastião,ADM2,DISTRICT
SM265431,-36.5528,-9.967,São Sebastião,ADM2,DISTRICT
SM264733,-50.7131,-23.4539,São Sebastião da Amoreira,ADM2,DISTRICT
SM263955,-45.7852,-22.1643,São Sebastião da Bela Vista,ADM2,DISTRICT
SM264109,-49.6263,-1.4787,São Sebastião da Boa Vista,ADM2,DISTRICT
SM266952,-46.7593,-21.7473,São Sebastião da Grama,ADM2,DISTRICT
SM264328,-35.8445,-7.085,São Sebastião de Lagoa de Roça,ADM2,DISTRICT
SM265321,-42.0959,-21.8659,São Sebastião do Alto,ADM2,DISTRICT
SM263956,-41.9392,-19.5179,São Sebastião do Anta,ADM2,DISTRICT
SM265922,-51.3479,-29.588,São Sebastião do Caí,ADM2,DISTRICT
SM263958,-42.5373,-18.0683,São Sebastião do Maranhão,ADM2,DISTRICT
SM263959,-45.0407,-20.2438,São Sebastião do Oeste,ADM2,DISTRICT
SM263960,-47.0145,-20.9163,São Sebastião do Paraíso,ADM2,DISTRICT
SM267657,-38.4517,-12.5036,São Sebastião do Passé,ADM2,DISTRICT
SM263961,-43.23,-19.2992,São Sebastião do Rio Preto,ADM2,DISTRICT
SM263962,-45.0205,-22.2128,São Sebastião do Rio Verde,ADM2,DISTRICT
SM267179,-48.346,-5.2518,São Sebastião do Tocantins,ADM2,DISTRICT
SM267351,-58.7422,-1.932,São Sebastião do Uatumã,ADM2,DISTRICT
SM264329,-37.0222,-8.1569,São Sebastião do Umbuzeiro,ADM2,DISTRICT
SM265923,-53.6088,-30.1598,São Sepé,ADM2,DISTRICT
SM266955,-47.5677,-21.4512,São Simão,ADM2,DISTRICT
SM268176,-50.6061,-19.0108,São Simão,ADM2,DISTRICT
SM263964,-44.9681,-21.7394,São Thomé das Letras,ADM2,DISTRICT
SM263965,-44.5596,-20.9361,São Tiago,ADM2,DISTRICT
SM263966,-47.1308,-20.8032,São Tomás de Aquino,ADM2,DISTRICT
SM265485,-36.1086,-5.973,São Tomé,ADM2,DISTRICT
SM264734,-52.514,-23.5236,São Tomé,ADM2,DISTRICT
SM3139,6.6,0.25,São Tomé,ADM1,DISTRICT
SM265925,-52.5872,-27.5511,São Valentim,ADM2,DISTRICT
SM267180,-48.14,-11.8603,São Valério da Natividade,ADM2,DISTRICT
SM265926,-53.9205,-27.8124,São Valério do Sul,ADM2,DISTRICT
SM265927,-51.3729,-29.3832,São Vendelino,ADM2,DISTRICT
SM289276,-16.9835,32.8173,São Vicente,ADM2,DISTRICT
SM266956,-46.4968,-23.9651,São Vicente,ADM2,DISTRICT
SM265486,-36.6569,-6.2238,São Vicente,ADM2,DISTRICT
SM3140,-25,16.8333,São Vicente,ADM1,DISTRICT
SM264905,-35.5022,-7.6022,São Vicente Ferrer,ADM2,DISTRICT
SM262908,-44.9396,-2.8772,São Vicente Ferrer,ADM2,DISTRICT
SM263967,-44.4665,-21.6702,São Vicente de Minas,ADM2,DISTRICT
SM243882,6.6194,0.2833,Sãotomense,LANGUAGE,LANGUAGE
SM296507,12.8644,59.1956,Säffle,ADM2,DISTRICT
SM296545,15.7138,60.3689,Säter,ADM2,DISTRICT
SM296651,14.5943,57.3288,Sävsjö,ADM2,DISTRICT
SM262117,2.5817,6.3947,Sèmè-Kpodji,ADM2,DISTRICT
SM281410,113.551,22.1939,Sé,ADM1:ADM2,DISTRICT
SM285453,-86.0903,12.9006,Sébaco,ADM2,DISTRICT
SM274555,13.6735,-0.5426,Sébé-Brikolo,ADM2,DISTRICT
SM295255,-15.5848,12.6981,Sédhiou,ADM2,DISTRICT
SM3169,-15.558,12.7068,Sédhiou,ADM1,DISTRICT
SM283377,-6.1503,13.6596,Ségou,ADM2,DISTRICT
SM3170,-5.9,13.6,Ségou,ADM1,DISTRICT
SM284010,-12.2392,15.2074,Sélibaby,ADM2,DISTRICT
SM265941,-52.2436,-29.4064,Sério,ADM2,DISTRICT
SM3183,5.5,36.1667,Sétif,ADM1,DISTRICT
SM457348,5,36,Sétif District,ADM2,DISTRICT
SM244507,-5.2893,11.1789,Sìcìté Sénoufo,LANGUAGE,LANGUAGE
SM304260,103.2747,22.2992,Sìn Hồ,ADM2,DISTRICT
SM265496,-35.9711,-6.126,Sítio Novo,ADM2,DISTRICT
SM262913,-46.6722,-6.1559,Sítio Novo,ADM2,DISTRICT
SM267182,-47.6864,-5.6299,Sítio Novo do Tocantins,ADM2,DISTRICT
SM268182,-46.27,-14.752,Sítio d'Abadia,ADM2,DISTRICT
SM267673,-43.5454,-13.0039,Sítio do Mato,ADM2,DISTRICT
SM267674,-38.0735,-10.3432,Sítio do Quinto,ADM2,DISTRICT
SM3234,28.1667,47.6667,Sîngerei,ADM1,DISTRICT
SM304147,105.8298,21.271,Sóc Sơn,ADM2,DISTRICT
SM304457,105.9846,9.6065,Sóc Trăng,ADM2,DISTRICT
SM3258,105.9167,9.55,Sóc Trăng,ADM1,DISTRICT
SM244858,105.001,16.9081,Sô,LANGUAGE,LANGUAGE
SM262145,2.4271,6.5016,Sô-Ava,ADM2,DISTRICT
SM304503,105.823,21.4883,Sông Công,ADM2,DISTRICT
SM304371,109.1863,13.5261,Sông Cầu,ADM2,DISTRICT
SM304372,108.9167,12.9167,Sông Hinh,ADM2,DISTRICT
SM304580,105.4074,21.4392,Sông Lô,ADM2,DISTRICT
SM304470,103.6873,21.0697,Sông Mã,ADM2,DISTRICT
SM274271,26.2976,59.3246,Sõmeru,ADM2,DISTRICT
SM296619,16.9488,61.2537,Söderhamn,ADM2,DISTRICT
SM296416,16.4451,58.4206,Söderköping,ADM2,DISTRICT
SM3259,16.6667,59.25,Södermanland,ADM1,DISTRICT
SM296476,17.6276,59.1812,Södertälje,ADM2,DISTRICT
SM298150,27.4132,37.7586,Söke,ADM2,DISTRICT
SM296388,14.6452,56.0891,Sölvesborg,ADM2,DISTRICT
SM298199,30.173,40.0248,Söğüt,ADM2,DISTRICT
SM298839,30.4782,40.9161,Söğütlü,ADM2,DISTRICT
SM286082,12.1673,65.3138,Sømna,ADM2,DISTRICT
SM271744,9.8,54.9167,Sønderborg (2007-),ADM2,DISTRICT
SM286113,10.3509,60.6994,Søndre Land,ADM2,DISTRICT
SM286114,9.5484,60.8224,Sør-Aurdal,ADM2,DISTRICT
SM286115,9.9395,61.5565,Sør-Fron,ADM2,DISTRICT
SM286362,11.6825,60.2546,Sør-Odal,ADM2,DISTRICT
SM286343,30.0426,69.7286,Sør-Varanger,ADM2,DISTRICT
SM286083,15.5945,67.348,Sørfold,ADM2,DISTRICT
SM286236,18.1563,69.147,Sørreisa,ADM2,DISTRICT
SM274545,-7.3025,62.0692,Sørvágs,ADM2,DISTRICT
SM281522,-104.1386,23.4426,Súchil,ADM2,DISTRICT
SM277833,-22.8264,65.878,Súðavíkurhreppur,ADM2,DISTRICT
SM261854,15.8862,46.9409,Südoststeiermark,ADM2,DISTRICT
SM3352,113.5,46.25,Sühbaatar,ADM1,DISTRICT
SM15009,113.4167,46.896,Sükhbaatar (excl. Baruun-Urt),ADM2,DISTRICT
SM298348,26.926,41.7796,Süleoğlu,ADM2,DISTRICT
SM283561,108.6195,46.5092,Sümber,ADM2,DISTRICT
SM283561,108.6195,46.5092,Sümber,ADM2,DISTRICT
SM283713,105.9247,48.7922,Sümber,ADM2,DISTRICT
SM283561,108.3623,46.3422,Sümber,ADM2,DISTRICT
SM283561,108.3623,46.3422,Sümber,ADM2,DISTRICT
SM276203,17.2678,47.0265,Sümeg,ADM2,DISTRICT
SM298966,40.1201,40.911,Sürmene,ADM2,DISTRICT
SM298472,30.9566,37.5112,Sütçüler,ADM2,DISTRICT
SM3049,23.25,47.1667,Sălaj,ADM1,DISTRICT
SM288850,17.509,53.4426,Sępólno,ADM2,DISTRICT
SM298170,28.1865,39.2506,Sındırgı,ADM2,DISTRICT
SM288828,16.5399,54.3791,Sławno,ADM2,DISTRICT
SM288925,14.7892,52.3227,Słubice,ADM2,DISTRICT
SM288806,17.9082,52.3142,Słupca,ADM2,DISTRICT
SM288702,17.1592,54.4221,Słupsk,ADM2,DISTRICT
SM288701,17.0279,54.4606,Słupsk (City),ADM2,DISTRICT
SM278200,140.9533,42.5799,Sōbetsu,ADM2,DISTRICT
SM279036,133.7022,34.7027,Sōja,ADM2,DISTRICT
SM279205,139.8022,35.8387,Sōka,ADM2,DISTRICT
SM279770,140.8732,37.7819,Sōma,ADM2,DISTRICT
SM279386,140.5537,35.6998,Sōsa,ADM2,DISTRICT
SM289081,130.3934,42.3504,Sŏnbong,ADM2,DISTRICT
SM289194,124.9167,39.75,Sŏnch'ŏn,ADM2,DISTRICT
SM289058,126.2164,39.2633,Sŏngch'ŏn,ADM2,DISTRICT
SM289035,126.6778,40.7648,Sŏnggan,ADM2,DISTRICT
SM304566,105.389,21.6362,Sơn Dương,ADM2,DISTRICT
SM304415,108.5364,14.9843,Sơn Hà,ADM2,DISTRICT
SM304370,108.9728,13.1587,Sơn Hòa,ADM2,DISTRICT
SM304469,103.9115,21.3461,Sơn La,ADM2,DISTRICT
SM3269,104,21.1667,Sơn La,ADM1,DISTRICT
SM304026,108.2641,16.1108,Sơn Trà,ADM2,DISTRICT
SM304404,108.3642,14.9639,Sơn Tây,ADM2,DISTRICT
SM304148,105.4744,21.0971,Sơn Tây,ADM2,DISTRICT
SM304403,108.743,15.1909,Sơn Tịnh,ADM2,DISTRICT
SM304187,106.8714,21.3063,Sơn Động,ADM2,DISTRICT
SM261944,44.8667,39.7,Sədərək,ADM1:ADM2,DISTRICT
SM304515,105.9034,19.7577,Sầm Sơn,ADM2,DISTRICT
SM304468,103.4974,20.8875,Sốp Cộp,ADM2,DISTRICT
SM289195,125.5153,39.9386,T'aech'ŏn,ADM2,DISTRICT
SM289156,125.2997,38.1358,T'aet'an,ADM2,DISTRICT
SM246408,107.309,25.6717,T'en,LANGUAGE,LANGUAGE
SM289175,127.8333,38.9167,T'ongch'ŏn,ADM2,DISTRICT
SM289136,126.7386,38.2844,T'osan,ADM2,DISTRICT
SM280510,104.9435,11.4547,Ta Khmau,ADM2,DISTRICT
SM14979,153.6465,5.2842,Ta Municipality,ADM2,DISTRICT
SM280773,106.703,16.0776,Ta Oi,ADM2,DISTRICT
SM297490,102.726,14.0627,Ta Phraya,ADM2,DISTRICT
SM280423,107.2403,14.2919,Ta Veaeng,ADM2,DISTRICT
SM283409,14.2161,36.0439,Ta' Kerċem,ADM1:ADM2,DISTRICT
SM283393,14.4961,35.8994,Ta' Xbiex,ADM1:ADM2,DISTRICT
SM245919,45.6411,13.6555,Ta'izzi-Adeni Arabic,LANGUAGE,LANGUAGE
SM261208,-169.4816,-14.2448,Ta'u,ADM2,DISTRICT
SM257948,144.44,-33.61,Ta-Tathi,ETHNICITY,ETHNICITY
SM257948,144.44,-33.61,Ta-Tathi,ETHNICITY,ETHNICITY
SM257948,142.8385,-34.7776,Ta-Tathi,ETHNICITY,ETHNICITY
SM257948,142.8385,-34.7776,Ta-Tathi,ETHNICITY,ETHNICITY
SM3384,44,13.5,Taizz,ADM1,DISTRICT
SM257840,20.11,-24.47,Taa,ETHNICITY,ETHNICITY
SM257840,20.11,-24.47,Taa,ETHNICITY,ETHNICITY
SM257840,20.11,-24.47,Taa,ETHNICITY,ETHNICITY
SM257840,22.2,-23.86,Taa,ETHNICITY,ETHNICITY
SM257840,22.2,-23.86,Taa,ETHNICITY,ETHNICITY
SM257840,22.2,-23.86,Taa,ETHNICITY,ETHNICITY
SM257840,22.8207,-24.2365,Taa,ETHNICITY,ETHNICITY
SM257840,22.8207,-24.2365,Taa,ETHNICITY,ETHNICITY
SM257840,22.8207,-24.2365,Taa,ETHNICITY,ETHNICITY
SM245785,29.5238,-7.9142,Taabwa,LANGUAGE,LANGUAGE
SM276165,18.0056,46.6804,Tab,ADM2,DISTRICT
SM245797,-96.2777,17.2822,Tabaa Zapotec,LANGUAGE,LANGUAGE
SM276392,115.5,-1.8833,Tabalong,ADM2,DISTRICT
SM276481,115.0667,-8.4333,Tabanan,ADM2,DISTRICT
SM263118,-56.6419,-11.0529,Tabaporã,ADM2,DISTRICT
SM266974,-49.0248,-20.9238,Tabapuã,ADM2,DISTRICT
SM298098,8.8276,36.9158,Tabarka,ADM2,DISTRICT
SM245792,127.633,1.4656,Tabaru,LANGUAGE,LANGUAGE
SM277663,60.2215,32.803,Tabas,ADM2,DISTRICT
SM245789,47.8379,42.0198,Tabasaran,LANGUAGE,LANGUAGE
SM257841,47.82,41.96,Tabasaran,ETHNICITY,ETHNICITY
SM292855,48,41.9167,Tabasaranskiy rayon,ADM2,DISTRICT
SM283075,-102.9375,21.9092,Tabasco,ADM2,DISTRICT
SM3385,-92.6667,18,Tabasco,ADM1,DISTRICT
SM245795,-92.7367,18.1713,Tabasco Chontal,LANGUAGE,LANGUAGE
SM245794,-93.5688,17.9371,Tabasco Nahuatl,LANGUAGE,LANGUAGE
SM245793,-93.0968,18.1713,Tabasco Zoque,LANGUAGE,LANGUAGE
SM266975,-48.6459,-21.7421,Tabatinga,ADM2,DISTRICT
SM267353,-69.6027,-3.963,Tabatinga,ADM2,DISTRICT
SM289707,-55.53,-26.1882,Tabaí,ADM2,DISTRICT
SM265950,-51.728,-29.6672,Tabaí,ADM2,DISTRICT
SM323834,-112.145,49.7962,Taber,ADM2:ADM3,DISTRICT
SM323835,-112.0529,49.9315,Taber Municipal District,ADM2:ADM3,DISTRICT
SM270063,-74.1021,4.9351,Tabio,ADM2,DISTRICT
SM264913,-37.492,-7.5908,Tabira,ADM2,DISTRICT
SM13342,174.8981,-1.4224,Tabiteuea,ADM2,DISTRICT
SM245803,140.396,-2.4537,Tabla,LANGUAGE,LANGUAGE
SM457691,3.25,36.25,Tablat District,ADM2,DISTRICT
SM9736,145.2753,-17.8336,Tablelands (2014-),ADM2,DISTRICT
SM245805,143.305,-8.0939,Tabo,LANGUAGE,LANGUAGE
SM267678,-44.0836,-12.3694,Tabocas do Brejo Velho,ADM2,DISTRICT
SM286658,-79.5583,8.7833,Taboga,ADM2,DISTRICT
SM265497,-38.0535,-5.9261,Taboleiro Grande,ADM2,DISTRICT
SM16228,-66.9351,18.1281,Tabonuco Barrio,ADM2,DISTRICT
SM296227,15.0143,46.2224,Tabor,ADM1:ADM2,DISTRICT
SM3386,32.8333,-5.25,Tabora,ADM1,DISTRICT
SM299190,32.83,-5.02,Tabora Urban,ADM2,DISTRICT
SM13863,26.6856,55.8636,Tabores pagasts,ADM2,DISTRICT
SM266977,-46.7827,-23.6116,Taboão da Serra,ADM2,DISTRICT
SM245807,143.593,-4.4916,Tabriak,LANGUAGE,LANGUAGE
SM13343,-159.3333,3.8667,Tabuaeran,ADM2,DISTRICT
SM289405,-7.5674,41.0956,Tabuaço,ADM2,DISTRICT
SM17815,36.75,28.6667,Tabuk,ADM2,DISTRICT
SM3387,37.1667,28.25,Tabuk,ADM1,DISTRICT
SM264003,-43.2483,-21.3578,Tabuleiro,ADM2,DISTRICT
SM267857,-38.0725,-5.2911,Tabuleiro do Norte,ADM2,DISTRICT
SM293002,79,52.8333,Tabunskiy rayon,ADM2,DISTRICT
SM250726,30.56,-10.32,Tabwa,ETHNICITY,ETHNICITY
SM245812,-97.4537,16.9648,Tacahua-Yolotepec Mixtec,LANGUAGE,LANGUAGE
SM264914,-36.2471,-8.3228,Tacaimbó,ADM2,DISTRICT
SM14862,-101.4473,19.2403,Tacambaro,ADM2,DISTRICT
SM245811,-68.0406,-13.3925,Tacana,LANGUAGE,LANGUAGE
SM257843,-67.97,-14.49,Tacana,ETHNICITY,ETHNICITY
SM275299,-92.1226,15.255,Tacaná,ADM2,DISTRICT
SM264915,-38.0765,-8.9568,Tacaratu,ADM2,DISTRICT
SM4810,-61.3833,10.6667,Tacarigua,ADM1:ADM2,DISTRICT
SM257844,-67.06,10.24,Tacarigua,ETHNICITY,ETHNICITY
SM257845,-81.2,30.92,Tacatacura,ETHNICITY,ETHNICITY
SM257845,-77.73,35.7,Tacatacura,ETHNICITY,ETHNICITY
SM245817,-7.7788,29.7854,Tachelhit,LANGUAGE,LANGUAGE
SM269347,82.95,46.75,Tacheng,ADM2,DISTRICT
SM279712,130.6136,33.3794,Tachiarai,ADM2,DISTRICT
SM279471,139.4045,35.7145,Tachikawa,ADM2,DISTRICT
SM15109,100.0341,20.6786,Tachileik District,ADM2,DISTRICT
SM245815,34.8298,0.6079,Tachoni,LANGUAGE,LANGUAGE
SM271160,12.75,49.7667,Tachov,ADM2,DISTRICT
SM327741,-96.8501,49.7513,Taché,ADM2:ADM3,DISTRICT
SM266978,-51.3324,-22.4968,Taciba,ADM2,DISTRICT
SM264347,-35.5228,-6.5381,Tacima,ADM2,DISTRICT
SM286847,-70.5,-17.8333,Tacna,ADM2,DISTRICT
SM3389,-70.3333,-17.6667,Tacna,ADM1,DISTRICT
SM282626,-92.7531,17.5075,Tacotalpa,ADM2,DISTRICT
SM275163,-90.3482,15.3203,Tactic,ADM2,DISTRICT
SM289606,-57.7497,-26.9038,Tacuaras,ADM2,DISTRICT
SM3390,-55.75,-32.0833,Tacuarembó,ADM1:ADM2,DISTRICT
SM289642,-56.5928,-23.5425,Tacuatí,ADM2,DISTRICT
SM295500,-89.9532,13.8993,Tacuba,ADM2,DISTRICT
SM245818,-53.7504,-4.563,Tacunyape,LANGUAGE,LANGUAGE
SM263091,-54.911,-23.6872,Tacuru,ADM2,DISTRICT
SM292314,27.6651,46.9363,Tacuta,ADM2,DISTRICT
SM13864,21.316,56.5806,Tadaiku pagasts,ADM2,DISTRICT
SM245819,2.3265,16.685,Tadaksahak,LANGUAGE,LANGUAGE
SM279118,135.3924,34.4924,Tadaoka,ADM2,DISTRICT
SM3391,42.5,12,Tadjourah,ADM1:ADMX,DISTRICT
SM10477,-76.5507,5.2635,Tado (1999-),ADM2,DISTRICT
SM236347,120.063,-1.3575,Tado-Lindu,LANGUAGE,LANGUAGE
SM257846,-69.93,48,Tadoussac,ETHNICITY,ETHNICITY
SM245826,121.305,13.0211,Tadyawan,LANGUAGE,LANGUAGE
SM257847,120.74,13.54,Tadyawan,ETHNICITY,ETHNICITY
SM3393,70.1807,38.8654,Tadzhikistan Territories,ADM1,DISTRICT
SM245827,120.139,-2.5821,Tae',LANGUAGE,LANGUAGE
SM257848,119.75,-4.59,Tae',ETHNICITY,ETHNICITY
SM280658,126.2839,36.7004,Taean,ADM2,DISTRICT
SM280686,128.9857,37.1652,Taebaek,ADM2,DISTRICT
SM289062,125.55,39.0833,Taedong,ADM2,DISTRICT
SM15277,125.7964,39.0264,Taedonggang,ADM2,DISTRICT
SM289084,128.6911,41.9453,Taehongdan,ADM2,DISTRICT
SM289063,126.9511,40.0828,Taehŭng,ADM2,DISTRICT
SM15278,125.8339,39.0631,Taesong-guyok,ADM2,DISTRICT
SM285038,7.23,9.232,Tafa,ADM2,DISTRICT
SM285318,9.5824,9.8254,Tafawa-B,ADM2,DISTRICT
SM3394,169.3485,-19.5095,Tafea,ADM1,DISTRICT
SM245830,0.3988,6.7952,Tafi,LANGUAGE,LANGUAGE
SM3395,35.5833,30.75,Tafilah,ADM1,DISTRICT
SM277978,35.5478,30.8279,Tafileh,ADM2,DISTRICT
SM12025,49.8833,34.8333,Tafresh,ADM2,DISTRICT
SM277664,53.8333,31.5167,Taft,ADM2,DISTRICT
SM14980,162.9697,5.3499,Tafunsak Municipality,ADM2,DISTRICT
SM261071,-65.2584,-26.7324,Tafí Viejo,ADM2,DISTRICT
SM261070,-65.7095,-26.8529,Tafí del Valle,ADM2,DISTRICT
SM290136,24.0261,46.951,Taga,ADM2,DISTRICT
SM9592,70.3317,36.4722,Tagab (Badakhshan),ADM2,DISTRICT
SM260066,69.6793,34.7946,Tagab (Kapisa),ADM2,DISTRICT
SM245843,125.175,6.9712,Tagabawa,LANGUAGE,LANGUAGE
SM278742,140.9991,38.2954,Tagajō,ADM2,DISTRICT
SM257849,125.31,6.4,Tagakaolo,ETHNICITY,ETHNICITY
SM245839,125.426,6.4534,Tagakaulu Kalagan,LANGUAGE,LANGUAGE
SM245844,116.177,4.4291,Tagal Murut,LANGUAGE,LANGUAGE
SM245847,142.9612,-18.1973,Tagalaka,LANGUAGE,LANGUAGE
SM459200,142.9612,-18.1973,Tagalaka,ETHNICITY,ETHNICITY
SM245841,121.747,14.06,Tagalog,LANGUAGE,LANGUAGE
SM257850,121.25,14.56,Tagalog,ETHNICITY,ETHNICITY
SM257850,121.25,14.56,Tagalog,ETHNICITY,ETHNICITY
SM257850,121.25,14.56,Tagalog,ETHNICITY,ETHNICITY
SM257850,121,14,Tagalog,ETHNICITY,ETHNICITY
SM257850,121,14,Tagalog,ETHNICITY,ETHNICITY
SM257850,121,14,Tagalog,ETHNICITY,ETHNICITY
SM257850,120.98,14.6,Tagalog,ETHNICITY,ETHNICITY
SM257850,120.98,14.6,Tagalog,ETHNICITY,ETHNICITY
SM257850,120.98,14.6,Tagalog,ETHNICITY,ETHNICITY
SM278947,139.0703,37.7,Tagami,ADM2,DISTRICT
SM3396,-10.3333,18.6667,Tagant,ADM1,DISTRICT
SM279714,130.7997,33.6316,Tagawa,ADM2,DISTRICT
SM13095,130.8528,33.5887,Tagawa-gun,ADM2,DISTRICT
SM245852,118.497,9.5426,Tagbanwa,LANGUAGE,LANGUAGE
SM257851,118.58,9.33,Tagbanwa,ETHNICITY,ETHNICITY
SM257851,118.58,9.33,Tagbanwa,ETHNICITY,ETHNICITY
SM257851,119,10,Tagbanwa,ETHNICITY,ETHNICITY
SM257851,119,10,Tagbanwa,ETHNICITY,ETHNICITY
SM245849,26.4475,4.6852,Tagbu,LANGUAGE,LANGUAGE
SM245849,26.4475,4.6852,Tagbu,LANGUAGE,LANGUAGE
SM245849,27.71,7.76,Tagbu,LANGUAGE,LANGUAGE
SM245849,27.71,7.76,Tagbu,LANGUAGE,LANGUAGE
SM245853,6.8399,16.1908,Tagdal,LANGUAGE,LANGUAGE
SM245855,94.2217,27.9861,Tagin,LANGUAGE,LANGUAGE
SM245854,-134.799,60.242,Tagish,LANGUAGE,LANGUAGE
SM257854,-134.62,59.08,Tagish,ETHNICITY,ETHNICITY
SM245856,30.9306,12.0346,Tagoi,LANGUAGE,LANGUAGE
SM257855,-117,43,Tagotoka,ETHNICITY,ETHNICITY
SM18117,63,36,Tagtabazar Etrap,ADM2,DISTRICT
SM271043,-79.1816,22.048,Taguasco,ADM2,DISTRICT
SM267184,-46.5711,-12.3857,Taguatinga,ADM2,DISTRICT
SM266979,-49.4078,-23.4851,Taguaí,ADM2,DISTRICT
SM245858,-5.1737,8.6705,Tagwana Senoufo,LANGUAGE,LANGUAGE
SM11265,-151.5016,-16.6304,Tahaa,ADM2,DISTRICT
SM245860,8.7317,22.5908,Tahaggart Tamahaq,LANGUAGE,LANGUAGE
SM278064,137.1831,34.6414,Tahara,ADM2,DISTRICT
SM283009,-88.9098,20.2422,Tahdziú,ADM2,DISTRICT
SM245861,-149.45,-17.6485,Tahitian,LANGUAGE,LANGUAGE
SM245865,-130.052,58.4498,Tahltan,LANGUAGE,LANGUAGE
SM283010,-89.2825,20.8816,Tahmek,ADM2,DISTRICT
SM3397,5.5,16,Tahoua,ADM1,DISTRICT
SM257857,-129.83,57.06,Tahtlan,ETHNICITY,ETHNICITY
SM257857,-129.83,57.06,Tahtlan,ETHNICITY,ETHNICITY
SM257857,-129.83,57.06,Tahtlan,ETHNICITY,ETHNICITY
SM257857,-131,58,Tahtlan,ETHNICITY,ETHNICITY
SM257857,-131,58,Tahtlan,ETHNICITY,ETHNICITY
SM257857,-131,58,Tahtlan,ETHNICITY,ETHNICITY
SM257857,-127.85,57.66,Tahtlan,ETHNICITY,ETHNICITY
SM257857,-127.85,57.66,Tahtlan,ETHNICITY,ETHNICITY
SM257857,-127.85,57.66,Tahtlan,ETHNICITY,ETHNICITY
SM286785,-70.5,-11.25,Tahuamanu,ADM2,DISTRICT
SM11266,-139.0871,-9.9507,Tahuata,ADM2,DISTRICT
SM245881,144.66,-5.2922,Tai,LANGUAGE,LANGUAGE
SM295445,161.0444,-9.2352,Tai,ADM2,DISTRICT
SM285211,7.3,4.7167,Tai,ADM2,DISTRICT
SM245873,104.625,20.4559,Tai Daeng,LANGUAGE,LANGUAGE
SM257859,105.6,18.19,Tai Daeng,ETHNICITY,ETHNICITY
SM245871,102.928,18.9572,Tai Dam,LANGUAGE,LANGUAGE
SM245872,104.938,19.1801,Tai Do-Mene-Yo,LANGUAGE,LANGUAGE
SM245874,103.5705,21.849,Tai Dón,LANGUAGE,LANGUAGE
SM245880,102.586,23.3088,Tai Hongjin,LANGUAGE,LANGUAGE
SM245889,95.0772,24.9041,Tai Laing,LANGUAGE,LANGUAGE
SM245888,102.407,20.045,Tai Long,LANGUAGE,LANGUAGE
SM245892,104.729,18.34,Tai Mène,DIALECT,LANGUAGE
SM245897,99.722,21.7837,Tai Nüa,LANGUAGE,LANGUAGE
SM245904,104.658,18.5562,Tai Pao,LANGUAGE,LANGUAGE
SM245879,104.225,19.1282,Tai Pao (Retired),LANGUAGE,LANGUAGE
SM3398,114.1654,22.44,Tai Po,ADM1,DISTRICT
SM245908,104.933,19.66,Tai Thanh,LANGUAGE,LANGUAGE
SM245916,101.899,23.569,Tai Ya,LANGUAGE,LANGUAGE
SM269294,117.0042,36.0711,Tai'an,ADM2,DISTRICT
SM245867,144.506,-4.0505,Taiap,LANGUAGE,LANGUAGE
SM11267,-149.2002,-17.7884,Taiarapu-Est,ADM2,DISTRICT
SM11268,-149.5342,-17.5512,Taiarapu-Ouest,ADM2,DISTRICT
SM266980,-48.5309,-21.1322,Taiaçu,ADM2,DISTRICT
SM245870,121.4232,25.0853,Taibei Hokkien,DIALECT,LANGUAGE
SM299055,120.673,24.1547,Taichung,ADM2,DISTRICT
SM234041,98.3792,53.7145,Taiga Sayan Turkic,LANGUAGE,LANGUAGE
SM245885,140.7495,-2.9408,Taikat,LANGUAGE,LANGUAGE
SM274410,178.5,-17.8333,Tailevu,ADM2,DISTRICT
SM264114,-48.7575,-2.8724,Tailândia,ADM2,DISTRICT
SM257862,66.47,30.73,Taimani,ETHNICITY,ETHNICITY
SM250846,47.93,-21.87,Taimoro,ETHNICITY,ETHNICITY
SM257863,64.9,29.51,Taimuri,ETHNICITY,ETHNICITY
SM245894,88.53,69.52,Taimyr Pidgin Russian,LANGUAGE,LANGUAGE
SM4341,-2.3167,7.8667,Tain (2012-),ADM2,DISTRICT
SM245898,145.682,-7.4031,Tainae,LANGUAGE,LANGUAGE
SM278948,139.4805,38.0059,Tainai,ADM2,DISTRICT
SM299056,120.1888,23,Tainan,ADM2,DISTRICT
SM245899,-66.8042,18.2836,Taino,LANGUAGE,LANGUAGE
SM257864,-78.79,20.45,Taino,ETHNICITY,ETHNICITY
SM257864,-78.79,20.45,Taino,ETHNICITY,ETHNICITY
SM257864,-72.63,17.35,Taino,ETHNICITY,ETHNICITY
SM257864,-72.63,17.35,Taino,ETHNICITY,ETHNICITY
SM257864,-75.37,19.94,Taino,ETHNICITY,ETHNICITY
SM257864,-75.37,19.94,Taino,ETHNICITY,ETHNICITY
SM264004,-42.0904,-15.838,Taiobeiras,ADM2,DISTRICT
SM267186,-47.0334,-12.1568,Taipas do Tocantins,ADM2,DISTRICT
SM299057,121.5533,25.083,Taipei City,ADM2,DISTRICT
SM461928,121.7843,24.9847,Taipei and New Taipei,ADM1,DISTRICT
SM265498,-35.5813,-5.5194,Taipu,ADM2,DISTRICT
SM257865,-74.81,10.81,Tairona,ETHNICITY,ETHNICITY
SM257866,146.33,-6.53,Tairora,ETHNICITY,ETHNICITY
SM245905,145.732,-7.9487,Tairuma,LANGUAGE,LANGUAGE
SM250750,47.39,-23.37,Taisaka,ETHNICITY,ETHNICITY
SM250750,47.39,-23.37,Taisaka,ETHNICITY,ETHNICITY
SM250750,47.39,-23.37,Taisaka,ETHNICITY,ETHNICITY
SM250750,48,-23,Taisaka,ETHNICITY,ETHNICITY
SM250750,48,-23,Taisaka,ETHNICITY,ETHNICITY
SM250750,48,-23,Taisaka,ETHNICITY,ETHNICITY
SM250750,47.75,-22.9,Taisaka,ETHNICITY,ETHNICITY
SM250750,47.75,-22.9,Taisaka,ETHNICITY,ETHNICITY
SM250750,47.75,-22.9,Taisaka,ETHNICITY,ETHNICITY
SM273515,-77.4832,-2.4905,Taisha,ADM2,DISTRICT
SM278353,134.5913,34.8337,Taishi,ADM2,DISTRICT
SM283545,96.5254,46.7019,Taishir,ADM2,DISTRICT
SM245910,38.5725,-3.7935,Taita,LANGUAGE,LANGUAGE
SM250732,37.78,-3.64,Taita,ETHNICITY,ETHNICITY
SM250732,37.78,-3.64,Taita,ETHNICITY,ETHNICITY
SM250732,39,-4,Taita,ETHNICITY,ETHNICITY
SM250732,39,-4,Taita,ETHNICITY,ETHNICITY
SM3402,38.37,-3.4,Taita Taveta,ADM1:ADM2,DISTRICT
SM299061,121.0483,22.8836,Taitung,ADM2,DISTRICT
SM279472,139.78,35.7126,Taitō,ADM2,DISTRICT
SM221,120.7054,24.1511,Taiwan,ADM0:ADM1,DISTRICT
SM245912,121.305,25.0206,Taiwan Sign Language,LANGUAGE,LANGUAGE
SM245917,112.4025,37.8632,Taiyuan,DIALECT,LANGUAGE
SM269318,112.3193,37.9331,Taiyuan,ADM2,DISTRICT
SM269220,120.094,32.5651,Taizhou,ADM2,DISTRICT
SM269392,121.444,28.6835,Taizhou,ADM2,DISTRICT
SM266323,-50.0993,-27.089,Taió,ADM2,DISTRICT
SM266981,-48.427,-21.1274,Taiúva,ADM2,DISTRICT
SM245921,120.078,-0.7258,Taje,LANGUAGE,LANGUAGE
SM297870,8.5505,35.9755,Tajerouine,ADM2,DISTRICT
SM12053,44.235,33.5103,Taji District,ADM2,DISTRICT
SM245922,68.5549,38.8213,Tajik,LANGUAGE,LANGUAGE
SM257869,53.34,33.34,Tajik,ETHNICITY,ETHNICITY
SM257869,53.34,33.34,Tajik,ETHNICITY,ETHNICITY
SM257869,53.34,33.34,Tajik,ETHNICITY,ETHNICITY
SM257869,53.34,33.34,Tajik,ETHNICITY,ETHNICITY
SM257869,53.34,33.34,Tajik,ETHNICITY,ETHNICITY
SM257869,73,37,Tajik,ETHNICITY,ETHNICITY
SM257869,73,37,Tajik,ETHNICITY,ETHNICITY
SM257869,73,37,Tajik,ETHNICITY,ETHNICITY
SM257869,73,37,Tajik,ETHNICITY,ETHNICITY
SM257869,73,37,Tajik,ETHNICITY,ETHNICITY
SM257869,69.77,37.9,Tajik,ETHNICITY,ETHNICITY
SM257869,69.77,37.9,Tajik,ETHNICITY,ETHNICITY
SM257869,69.77,37.9,Tajik,ETHNICITY,ETHNICITY
SM257869,69.77,37.9,Tajik,ETHNICITY,ETHNICITY
SM257869,69.77,37.9,Tajik,ETHNICITY,ETHNICITY
SM257869,68.52,39.37,Tajik,ETHNICITY,ETHNICITY
SM257869,68.52,39.37,Tajik,ETHNICITY,ETHNICITY
SM257869,68.52,39.37,Tajik,ETHNICITY,ETHNICITY
SM257869,68.52,39.37,Tajik,ETHNICITY,ETHNICITY
SM257869,68.52,39.37,Tajik,ETHNICITY,ETHNICITY
SM257869,72.1667,37.9,Tajik,ETHNICITY,ETHNICITY
SM257869,72.1667,37.9,Tajik,ETHNICITY,ETHNICITY
SM257869,72.1667,37.9,Tajik,ETHNICITY,ETHNICITY
SM257869,72.1667,37.9,Tajik,ETHNICITY,ETHNICITY
SM257869,72.1667,37.9,Tajik,ETHNICITY,ETHNICITY
SM245925,67.3267,37.2176,Tajiki Arabic,LANGUAGE,LANGUAGE
SM279814,137.1126,35.334,Tajimi,ADM2,DISTRICT
SM245923,120.01,-0.3155,Tajio,LANGUAGE,LANGUAGE
SM245927,-8.989,5.5069,Tajuasohn,LANGUAGE,LANGUAGE
SM275300,-91.9697,15.0616,Tajumulco,ADM2,DISTRICT
SM3404,98.8333,16.5833,Tak,ADM1,DISTRICT
SM297249,102.0024,6.2375,Tak Bai,ADM2,DISTRICT
SM297198,100.4669,15.3825,Tak Fa,ADM2,DISTRICT
SM278354,134.9047,35.0847,Taka,ADM2,DISTRICT
SM4104,169.5863,11.173,Taka Atoll,ADM1,DISTRICT
SM278395,140.6209,36.7692,Takahagi,ADM2,DISTRICT
SM278066,136.9949,34.9334,Takahama,ADM2,DISTRICT
SM278777,130.9983,31.9359,Takaharu,ADM2,DISTRICT
SM279037,133.5244,34.821,Takahashi,ADM2,DISTRICT
SM284883,9.1756,11.4619,Takai,ADM2,DISTRICT
SM13096,135.8103,34.45,Takaichi-gun,ADM2,DISTRICT
SM279121,135.4296,34.5287,Takaishi,ADM2,DISTRICT
SM276583,119.5167,-5.4167,Takalar,ADM2,DISTRICT
SM3405,55.5167,-4.7833,Takamaka,ADM1,DISTRICT
SM278469,134.0545,34.2685,Takamatsu,ADM2,DISTRICT
SM279499,136.9626,36.7426,Takaoka,ADM2,DISTRICT
SM13097,133.0754,33.3491,Takaoka-gun,ADM2,DISTRICT
SM278355,135.332,34.8601,Takarazuka,ADM2,DISTRICT
SM11269,-145.0089,-14.4517,Takaroa,ADM2,DISTRICT
SM278356,134.7867,34.778,Takasago,ADM2,DISTRICT
SM13098,138.8923,36.4054,Takasaki Shi (2009-),ADM2,DISTRICT
SM279237,136.0286,35.3545,Takashima,ADM2,DISTRICT
SM279122,135.6079,34.8808,Takatsuki,ADM2,DISTRICT
SM279815,137.2859,36.1318,Takayama,ADM2,DISTRICT
SM278138,132.9,34.35,Takehara,ADM2,DISTRICT
SM15252,8.7256,13.9159,Takeita,ADM2,DISTRICT
SM245942,-123.452,42.423,Takelma,LANGUAGE,LANGUAGE
SM257871,-123.07,42.2,Takelma,ETHNICITY,ETHNICITY
SM257871,-123.07,42.2,Takelma,ETHNICITY,ETHNICITY
SM257871,-123.07,42.2,Takelma,ETHNICITY,ETHNICITY
SM257871,-123,42,Takelma,ETHNICITY,ETHNICITY
SM257871,-123,42,Takelma,ETHNICITY,ETHNICITY
SM257871,-123,42,Takelma,ETHNICITY,ETHNICITY
SM257871,-123.48,42.44,Takelma,ETHNICITY,ETHNICITY
SM257871,-123.48,42.44,Takelma,ETHNICITY,ETHNICITY
SM257871,-123.48,42.44,Takelma,ETHNICITY,ETHNICITY
SM297929,10.6776,36.8131,Takelsa,ADM2,DISTRICT
SM279139,129.9978,33.2041,Takeo,ADM2,DISTRICT
SM245940,49.6885,36.0651,Takestani,LANGUAGE,LANGUAGE
SM279007,131.3272,32.9977,Taketa,ADM2,DISTRICT
SM3407,69.75,36.6667,Takhar,ADM1,DISTRICT
SM297199,100.3806,15.2593,Takhli,ADM2,DISTRICT
SM292720,38.8055,44.9743,Takhtamukaysk rayon,ADM2,DISTRICT
SM13099,136.3387,34.3685,Taki-gun,ADM2,DISTRICT
SM245943,145.975,-4.6906,Takia,LANGUAGE,LANGUAGE
SM278206,141.9499,43.6084,Takikawa,ADM2,DISTRICT
SM278455,141.0667,39.8,Takizawa,ADM2,DISTRICT
SM245950,88.4254,28.1652,Takpa (Retired),LANGUAGE,LANGUAGE
SM257872,86.53,30.02,Takpo,ETHNICITY,ETHNICITY
SM279140,130.1052,33.2783,Taku,ADM2,DISTRICT
SM257873,-133.27,57.68,Taku,ETHNICITY,ETHNICITY
SM245951,108.046,15.4299,Takua,LANGUAGE,LANGUAGE
SM297304,98.3389,8.8688,Takua Pa,ADM2,DISTRICT
SM297305,98.3895,8.2843,Takua Thung,ADM2,DISTRICT
SM285245,10.0448,7.4116,Takum,ADM2,DISTRICT
SM245954,157.0321,-4.7731,Takuu,LANGUAGE,LANGUAGE
SM295447,160.7713,-8.4032,Takwa,ADM2,DISTRICT
SM245956,36.249,-16.2276,Takwane,LANGUAGE,LANGUAGE
SM3406,104.8333,10.9167,Takêv,ADM1,DISTRICT
SM245982,9.5077,9.1535,Tal,LANGUAGE,LANGUAGE
SM296767,39.2136,36.4828,Tal Abyad,ADM2,DISTRICT
SM293003,83.4167,53.75,Tal'menskiy rayon,ADM2,DISTRICT
SM299431,30.6975,48.8884,Tal'nivs'kyi,ADM2,DISTRICT
SM283394,14.4939,35.8925,Tal-Pietà,ADM1:ADM2,DISTRICT
SM245967,9.8546,10.233,Tala,LANGUAGE,LANGUAGE
SM300148,-55.7372,-34.3324,Tala,ADM2,DISTRICT
SM281848,-103.6927,20.5982,Tala,ADM2,DISTRICT
SM11061,32.4257,34.8086,Tala,ADM2,DISTRICT
SM261178,-59.3333,-32.3333,Tala,ADM2,DISTRICT
SM260193,68.3807,35.2753,Tala Wa Barfak,ADM2,DISTRICT
SM227273,124.892,8.1593,Talaandig-Binukid,LANGUAGE,LANGUAGE
SM262674,29.75,54.5,Talachyn,ADM2,DISTRICT
SM305802,42.5,36.5,Talafar,ADM2,DISTRICT
SM269087,-70.9167,-33.6667,Talagante,ADM2,DISTRICT
SM270706,-74.6559,9.3201,Talaigua Nuevo (1994-),ADM2,DISTRICT
SM270947,-83.0984,9.4612,Talamanca,ADM2,DISTRICT
SM275930,-87.0694,14.4139,Talanga,ADM2,DISTRICT
SM16229,-65.9082,18.0191,Talante Barrio,ADM2,DISTRICT
SM286820,-81.0833,-4.4167,Talara,ADM2,DISTRICT
SM298612,35.5606,38.7016,Talas,ADM2,DISTRICT
SM280342,72.6941,42.1865,Talas,ADM2,DISTRICT
SM3408,72,42.3333,Talas (1990-),ADM1:ADMX,DISTRICT
SM288608,149.5833,-5.6667,Talasea,ADM2,DISTRICT
SM279908,70.6667,43.5833,Talasskiy,ADM2,DISTRICT
SM279908,70.6667,43.5833,Talasskiy,ADM2,DISTRICT
SM279908,70.4667,43.1667,Talasskiy,ADM2,DISTRICT
SM279908,70.4667,43.1667,Talasskiy,ADM2,DISTRICT
SM285278,6.0545,12.3835,Talata-Mafara,ADM2,DISTRICT
SM245963,126.795,4.1185,Talaud,LANGUAGE,LANGUAGE
SM301226,-76.1786,38.7491,Talbot,ADM2,DISTRICT
SM300415,-84.533,32.6995,Talbot,ADM2,DISTRICT
SM269078,-71.3533,-35.4378,Talca,ADM2,DISTRICT
SM293723,37.5833,56.75,Taldomskiy rayon,ADM2,DISTRICT
SM13264,79,44.8333,Taldyqorghan Audany (excl. Taldyqorghan and Tekeli),ADM2,DISTRICT
SM13265,78.4,45,Taldyqorghan Qalasy,ADM2,DISTRICT
SM291577,25.5638,45.2241,Talea,ADM2,DISTRICT
SM295796,48.5412,9.1795,Taleex,ADM2,DISTRICT
SM11441,-0.8,10.7167,Talensi-Nabdam (2012-),ADM2,DISTRICT
SM13266,77.5,44,Talghar Audany (excl. Talghar and Kapshagay),ADM2,DISTRICT
SM13267,77.25,43.3167,Talghar Qalasy,ADM2,DISTRICT
SM275714,-88.728,14.6811,Talgua,ADM2,DISTRICT
SM257877,-85.44,34.36,Tali,ETHNICITY,ETHNICITY
SM245977,124.869,-1.7952,Taliabu,LANGUAGE,LANGUAGE
SM300416,-82.8788,33.5661,Taliaferro,ADM2,DISTRICT
SM245972,106.102,16.5287,Talieng,LANGUAGE,LANGUAGE
SM297182,100.4329,13.7727,Taling Chan,ADM2,DISTRICT
SM245973,29.9494,0.6762,Talinga-Bwisi,LANGUAGE,LANGUAGE
SM245974,160.231,-9.7676,Talise,LANGUAGE,LANGUAGE
SM295359,160.22,-9.7641,Talise,ADM2,DISTRICT
SM257880,48.37,39.7,Talish,ETHNICITY,ETHNICITY
SM267187,-49.0786,-12.6779,Talismã,ADM2,DISTRICT
SM296788,36.2738,34.7185,Talkalakh,ADM2,DISTRICT
SM11629,-23.8833,65.6167,Talknafjardarhreppur,ADM2,DISTRICT
SM16230,-66.6947,18.0511,Tallaboa Alta Barrio,ADM2,DISTRICT
SM16231,-66.738,17.9978,Tallaboa Poniente Barrio,ADM2,DISTRICT
SM16232,-66.7127,18.0246,Tallaboa Saliente Barrio,ADM2,DISTRICT
SM300303,-86.1659,33.3801,Talladega,ADM2,DISTRICT
SM301490,-90.1733,33.9505,Tallahatchie,ADM2,DISTRICT
SM257881,-80.52,-5.26,Tallanes,ETHNICITY,ETHNICITY
SM300304,-85.7975,32.8624,Tallapoosa,ADM2,DISTRICT
SM250973,-0.19,10.94,Tallensi,ETHNICITY,ETHNICITY
SM250973,-0.19,10.94,Tallensi,ETHNICITY,ETHNICITY
SM250973,-0.19,10.94,Tallensi,ETHNICITY,ETHNICITY
SM250973,-0.19,10.94,Tallensi,ETHNICITY,ETHNICITY
SM250973,-0.6665,10.625,Tallensi,ETHNICITY,ETHNICITY
SM250973,-0.6665,10.625,Tallensi,ETHNICITY,ETHNICITY
SM250973,-0.6665,10.625,Tallensi,ETHNICITY,ETHNICITY
SM250973,-0.6665,10.625,Tallensi,ETHNICITY,ETHNICITY
SM250973,-1,11,Tallensi,ETHNICITY,ETHNICITY
SM250973,-1,11,Tallensi,ETHNICITY,ETHNICITY
SM250973,-1,11,Tallensi,ETHNICITY,ETHNICITY
SM250973,-1,11,Tallensi,ETHNICITY,ETHNICITY
SM250973,-0.57,10.66,Tallensi,ETHNICITY,ETHNICITY
SM250973,-0.57,10.66,Tallensi,ETHNICITY,ETHNICITY
SM250973,-0.57,10.66,Tallensi,ETHNICITY,ETHNICITY
SM250973,-0.57,10.66,Tallensi,ETHNICITY,ETHNICITY
SM274096,24.7646,59.4344,Tallinn,ADM2,DISTRICT
SM245980,-80.6511,-5.3308,Tallán,LANGUAGE,LANGUAGE
SM295665,-89.3995,13.6701,Talnique,ADM2,DISTRICT
SM3409,144.7588,13.3552,Talofofo,ADM1,DISTRICT
SM245987,122.872,-4.6478,Taloki,LANGUAGE,LANGUAGE
SM245986,119.35,-2.4525,Talondo',LANGUAGE,LANGUAGE
SM294994,40.75,51.0833,Talovskiy rayon,ADM2,DISTRICT
SM281849,-104.9137,20.2862,Talpa de Allende,ADM2,DISTRICT
SM291938,25.3092,44.2807,Talpa-ograzile,ADM2,DISTRICT
SM13865,22.586,57.2452,Talsi,ADM2,DISTRICT
SM3968,22.5833,57.3333,Talsi Municipality,ADM1,DISTRICT
SM253440,-123.5,42.5,Taltushtuntede,ETHNICITY,ETHNICITY
SM9593,69.6088,36.7406,Taluqan (2005-),ADM2,DISTRICT
SM245990,126.336,-7.8855,Talur,DIALECT,LANGUAGE
SM17659,-60.9441,13.9958,Talvern,ADM2,DISTRICT
SM304573,105.9535,10.0813,Tam Bình,ADM2,DISTRICT
SM304582,105.5569,21.3619,Tam Dương,ADM2,DISTRICT
SM304390,108.5058,15.592,Tam Kỳ,ADM2,DISTRICT
SM304364,105.2313,21.2944,Tam Nông,ADM2,DISTRICT
SM304020,105.5252,10.7276,Tam Nông,ADM2,DISTRICT
SM304344,105.895,20.157,Tam Điệp,ADM2,DISTRICT
SM304261,103.5934,22.3539,Tam Đường,ADM2,DISTRICT
SM304581,105.5946,21.4569,Tam Đảo,ADM2,DISTRICT
SM300868,-92.5325,42.0798,Tama,ADM2,DISTRICT
SM279473,139.4398,35.6305,Tama,ADM2,DISTRICT
SM250734,22.26,15.03,Tama,ETHNICITY,ETHNICITY
SM245999,21.9951,14.332,Tama (Chad),LANGUAGE,LANGUAGE
SM246008,-75.25,1.0333,Tama (Colombia),LANGUAGE,LANGUAGE
SM289977,26.5762,44.4595,Tamadau Mare,ADM2,DISTRICT
SM246004,138.892,-6.6449,Tamagario,LANGUAGE,LANGUAGE
SM298012,7.8849,34.2647,Tamaghza,ADM2,DISTRICT
SM275164,-90.2417,15.3022,Tamahú,ADM2,DISTRICT
SM269903,-73.7811,8.8642,Tamalameque,ADM2,DISTRICT
SM4372,-0.8333,9.4,Tamale Metropolitan District (2012-),ADM2,DISTRICT
SM282784,-97.6935,21.5025,Tamalín,ADM2,DISTRICT
SM237243,167.16,-15.6835,Tamambo,LANGUAGE,LANGUAGE
SM278110,139.1264,36.3022,Tamamura,ADM2,DISTRICT
SM246003,112.975,1,Taman (Indonesia),LANGUAGE,LANGUAGE
SM245996,95.1171,25.1566,Taman (Myanmar),LANGUAGE,LANGUAGE
SM13344,175.9862,-2.5011,Tamana,ADM2,DISTRICT
SM278647,130.5602,32.9155,Tamana,ADM2,DISTRICT
SM13100,130.5819,33.0114,Tamana Shi,ADM2,DISTRICT
SM257885,-66.13,7.24,Tamanaco,ETHNICITY,ETHNICITY
SM246006,-65.883,2.765,Tamanaku,LANGUAGE,LANGUAGE
SM264916,-35.1394,-8.7487,Tamandaré,ADM2,DISTRICT
SM3410,4.6667,23.75,Tamanghasset,ADM1,DISTRICT
SM295666,-89.4077,13.5577,Tamanique,ADM2,DISTRICT
SM279038,133.9377,34.5251,Tamano,ADM2,DISTRICT
SM457742,5.5,23,Tamanrasset District,ADM2,DISTRICT
SM296069,-55.0186,5.7825,Tamanredjo,ADM2,DISTRICT
SM264745,-51.0468,-23.8256,Tamarana,ADM2,DISTRICT
SM291371,26.9509,47.0033,Tamaseni,ADM2,DISTRICT
SM246009,-2.4127,14.2038,Tamasheq,LANGUAGE,LANGUAGE
SM282504,-99.3549,21.8909,Tamasopo,ADM2,DISTRICT
SM14981,149.4118,7.539,Tamatam Municipality,ADM2,DISTRICT
SM257888,-82.99,33.05,Tamathli,ETHNICITY,ETHNICITY
SM3411,-98.75,24,Tamaulipas,ADM1,DISTRICT
SM257889,-97.94,22.89,Tamaulipeco,ETHNICITY,ETHNICITY
SM257890,138.49,-1.89,Tamaya,ETHNICITY,ETHNICITY
SM257890,138.49,-1.89,Tamaya,ETHNICITY,ETHNICITY
SM257890,138.43,-3.98,Tamaya,ETHNICITY,ETHNICITY
SM257890,138.43,-3.98,Tamaya,ETHNICITY,ETHNICITY
SM257890,140,-3,Tamaya,ETHNICITY,ETHNICITY
SM257890,140,-3,Tamaya,ETHNICITY,ETHNICITY
SM271812,-71.1667,18.5,Tamayo,ADM2,DISTRICT
SM246007,-97.0998,17.1258,Tamazola Mixtec,LANGUAGE,LANGUAGE
SM281523,-106.7673,25.0559,Tamazula,ADM2,DISTRICT
SM281850,-103.1691,19.6569,Tamazula de Gordiano,ADM2,DISTRICT
SM14863,-96.0356,17.0377,Tamazulapam del Espiritu Santo,ADM2,DISTRICT
SM282505,-98.7845,21.2429,Tamazunchale,ADM2,DISTRICT
SM278357,135.0497,35.1772,Tamba,ADM2,DISTRICT
SM295259,-13.647,13.605,Tambacounda,ADM2,DISTRICT
SM3412,-13.25,13.9167,Tambacounda (2008-),ADM1,DISTRICT
SM250844,47.93,-21.18,Tambahoaka,ETHNICITY,ETHNICITY
SM283923,34.2557,-16.7236,Tambara,ADM2,DISTRICT
SM246019,9.3284,9.3604,Tambas,LANGUAGE,LANGUAGE
SM285228,4.8488,12.3661,Tambawal,ADM2,DISTRICT
SM266982,-47.2276,-21.5869,Tambaú,ADM2,DISTRICT
SM264917,-35.1736,-7.4473,Tambe,ADM2,DISTRICT
SM275715,-88.7677,14.2128,Tambla,ADM2,DISTRICT
SM257894,32.88,-10.93,Tambo,ETHNICITY,ETHNICITY
SM264746,-52.4672,-23.1989,Tamboara,ADM2,DISTRICT
SM286786,-70.0501,-12.1995,Tambopata,ADM2,DISTRICT
SM246017,118,-8.25,Tambora,LANGUAGE,LANGUAGE
SM18364,-56.6076,-31.9898,Tambores,ADM2,DISTRICT
SM271878,-70.6,19.5,Tamboril,ADM2,DISTRICT
SM267859,-40.2674,-4.9931,Tamboril,ADM2,DISTRICT
SM265151,-43.0908,-8.4028,Tamboril do Piauí,ADM2,DISTRICT
SM246013,167.139,-15.5004,Tambotalo,LANGUAGE,LANGUAGE
SM3413,41.5,52.75,Tambov,ADM1,DISTRICT
SM293226,128,50.0833,Tambovskiy rayon,ADM2,DISTRICT
SM276523,132.4896,-0.6052,Tambrauw,ADM2,DISTRICT
SM288612,144.15,-5.9833,Tambul-Nebilyer,ADM2,DISTRICT
SM246014,116.373,5.6828,Tambunan Dusun,LANGUAGE,LANGUAGE
SM284019,-10.6702,17.2456,Tamchakett,ADM2,DISTRICT
SM270604,-71.7839,6.3983,Tame,ADM2,DISTRICT
SM11692,93.5156,24.9755,Tamenglong (2016-),ADM2,DISTRICT
SM274586,-2.0833,53.5,Tameside,ADM1:ADM2,DISTRICT
SM246026,147.732,-6.7002,Tami,LANGUAGE,LANGUAGE
SM282786,-97.4999,21.2889,Tamiahua,ADM2,DISTRICT
SM246025,78.826,10.5202,Tamil,LANGUAGE,LANGUAGE
SM250974,80.48,9.28,Tamil,ETHNICITY,ETHNICITY
SM250974,80.48,9.28,Tamil,ETHNICITY,ETHNICITY
SM250974,80.48,9.28,Tamil,ETHNICITY,ETHNICITY
SM250974,80.48,9.28,Tamil,ETHNICITY,ETHNICITY
SM250974,78.1,9.63,Tamil,ETHNICITY,ETHNICITY
SM250974,78.1,9.63,Tamil,ETHNICITY,ETHNICITY
SM250974,78.1,9.63,Tamil,ETHNICITY,ETHNICITY
SM250974,78.1,9.63,Tamil,ETHNICITY,ETHNICITY
SM250974,79.2915,10.625,Tamil,ETHNICITY,ETHNICITY
SM250974,79.2915,10.625,Tamil,ETHNICITY,ETHNICITY
SM250974,79.2915,10.625,Tamil,ETHNICITY,ETHNICITY
SM250974,79.2915,10.625,Tamil,ETHNICITY,ETHNICITY
SM250974,79,11,Tamil,ETHNICITY,ETHNICITY
SM250974,79,11,Tamil,ETHNICITY,ETHNICITY
SM250974,79,11,Tamil,ETHNICITY,ETHNICITY
SM250974,79,11,Tamil,ETHNICITY,ETHNICITY
SM250974,78,11.1,Tamil,ETHNICITY,ETHNICITY
SM250974,78,11.1,Tamil,ETHNICITY,ETHNICITY
SM250974,78,11.1,Tamil,ETHNICITY,ETHNICITY
SM250974,78,11.1,Tamil,ETHNICITY,ETHNICITY
SM3414,78.3333,11,Tamil Nadu,ADM1,DISTRICT
SM270353,-77.3552,1.5939,Taminango,ADM2,DISTRICT
SM273911,30.9704,29.4628,Tamiyah,ADM2,DISTRICT
SM246036,18.2295,11.4926,Tamki,LANGUAGE,LANGUAGE
SM296090,-55.2148,5.8137,Tammenga,ADM2,DISTRICT
SM261316,117.4831,-31.6051,Tammin,ADM2,DISTRICT
SM291108,23.0079,44.5615,Tamna,ADM2,DISTRICT
SM246037,139.233,-5.5796,Tamnim Citak,LANGUAGE,LANGUAGE
SM10054,114.9156,4.8757,Tamoi,ADM2,DISTRICT
SM297319,100.0436,7.2878,Tamot,ADM2,DISTRICT
SM257900,-45.63,-18.38,Tamoyo,ETHNICITY,ETHNICITY
SM257900,-43.75,-21.62,Tamoyo,ETHNICITY,ETHNICITY
SM282506,-98.7377,21.4137,Tampacan,ADM2,DISTRICT
SM282508,-98.779,21.5575,Tampamolón Corona,ADM2,DISTRICT
SM11229,23.9251,61.5702,Tampere (2011-),ADM2,DISTRICT
SM246042,116.789,5.7247,Tampias Lobu,LANGUAGE,LANGUAGE
SM282668,-97.936,22.2951,Tampico,ADM2,DISTRICT
SM282787,-97.824,21.9973,Tampico Alto,ADM2,DISTRICT
SM246039,107.2454,13.5645,Tampuan,LANGUAGE,LANGUAGE
SM246040,-1.3547,9.7558,Tampulma,LANGUAGE,LANGUAGE
SM261850,13.7,47.15,Tamsweg,ADM2,DISTRICT
SM283477,94.406,24.2191,Tamu,ADM2,DISTRICT
SM3415,144.7809,13.4876,Tamuning,ADM1,DISTRICT
SM279775,140.567,37.3429,Tamura,ADM2,DISTRICT
SM13101,140.5738,37.4379,Tamura-shi,ADM2,DISTRICT
SM282509,-98.7258,22.0776,Tamuín,ADM2,DISTRICT
SM261466,150.8153,-30.922,Tamworth Regional,ADM2,DISTRICT
SM276189,18.3586,46.6542,Tamás,ADM2,DISTRICT
SM297702,105.1627,15.3904,Tan Sum,ADM2,DISTRICT
SM281452,-11.0833,28.0833,Tan-Tan,ADM2,DISTRICT
SM286344,28.1849,70.1998,Tana,ADM2,DISTRICT
SM3416,39.4167,-1.5333,Tana River,ADM1:ADM2,DISTRICT
SM276422,117.25,3.55,Tana Tidung,ADM2,DISTRICT
SM276584,119.7617,-3.0567,Tana Toraja,ADM2,DISTRICT
SM266983,-49.6458,-20.5021,Tanabi,ADM2,DISTRICT
SM246061,-143.338,63.4046,Tanacross,LANGUAGE,LANGUAGE
SM276393,115.7037,-3.4541,Tanah Bumbu,ADM2,DISTRICT
SM276651,100.5771,-0.4555,Tanah Datar,ADM2,DISTRICT
SM276394,114.8667,-3.8833,Tanah Laut,ADM2,DISTRICT
SM284420,102,5.6667,Tanah Merah,ADM2,DISTRICT
SM246059,133.18,-2.5523,Tanahmerah,LANGUAGE,LANGUAGE
SM375360,84.2232,27.9355,Tanahu,ADM2:ADM3,DISTRICT
SM246060,-150.759,60.4712,Tanaina,LANGUAGE,LANGUAGE
SM257902,-150.35,61.71,Tanaina,ETHNICITY,ETHNICITY
SM257902,-150.35,61.71,Tanaina,ETHNICITY,ETHNICITY
SM257902,-150.35,61.71,Tanaina,ETHNICITY,ETHNICITY
SM257902,-144,62,Tanaina,ETHNICITY,ETHNICITY
SM257902,-144,62,Tanaina,ETHNICITY,ETHNICITY
SM257902,-144,62,Tanaina,ETHNICITY,ETHNICITY
SM257902,-150.45,61.74,Tanaina,ETHNICITY,ETHNICITY
SM257902,-150.45,61.74,Tanaina,ETHNICITY,ETHNICITY
SM257902,-150.45,61.74,Tanaina,ETHNICITY,ETHNICITY
SM250737,47.34,-21.15,Tanala,ETHNICITY,ETHNICITY
SM250737,47.34,-21.15,Tanala,ETHNICITY,ETHNICITY
SM250737,47.34,-21.15,Tanala,ETHNICITY,ETHNICITY
SM250737,47,-22,Tanala,ETHNICITY,ETHNICITY
SM250737,47,-22,Tanala,ETHNICITY,ETHNICITY
SM250737,47,-22,Tanala,ETHNICITY,ETHNICITY
SM250737,48,-22,Tanala,ETHNICITY,ETHNICITY
SM250737,48,-22,Tanala,ETHNICITY,ETHNICITY
SM250737,48,-22,Tanala,ETHNICITY,ETHNICITY
SM16233,-66.7675,18.2202,Tanama Barrio (Adjuntas),ADM2,DISTRICT
SM16234,-66.715,18.4236,Tanama Barrio (Arecibo),ADM2,DISTRICT
SM246052,145.757,15.237,Tanapag,LANGUAGE,LANGUAGE
SM292380,27.3798,46.1016,Tanasoaia,ADM2,DISTRICT
SM282510,-98.9447,21.5957,Tancanhuitz de Santos,ADM2,DISTRICT
SM11736,-102.3484,19.3107,Tancitaro,ADM2,DISTRICT
SM282788,-97.753,21.2539,Tancoco,ADM2,DISTRICT
SM246073,146.6071,-5.7441,Tanda,LANGUAGE,LANGUAGE
SM246072,126.186,8.9815,Tandaganon,LANGUAGE,LANGUAGE
SM299127,39.6308,-10.7623,Tandahimba,ADM2,DISTRICT
SM295360,159.8269,-9.4524,Tandai,ADM2,DISTRICT
SM290936,27.652,44.6449,Tandarei,ADM2,DISTRICT
SM370455,6.1961,49.9156,Tandel,ADM1:ADM2:ADM3,DISTRICT
SM246067,134.532,-2.8423,Tandia,LANGUAGE,LANGUAGE
SM260714,-59.25,-37.3333,Tandil,ADM2,DISTRICT
SM10339,16.7235,9.6626,Tandjile Est (1999-),ADM2,DISTRICT
SM10340,15.79,9.36,Tandjile Ouest (excl. Manbague),ADM2,DISTRICT
SM3417,16.5,9.5,Tandjilé,ADM1,DISTRICT
SM375485,68.72,25.46,Tando Allahyar,ADM2:ADM3,DISTRICT
SM375486,68.4833,25.0167,Tando M. Khan,ADM2:ADM3,DISTRICT
SM246070,45.9,-24.8016,Tandroy Malagasy,LANGUAGE,LANGUAGE
SM246074,166.97,-11.68,Tanema,LANGUAGE,LANGUAGE
SM14864,-96.2988,17.3856,Tanetze de Zaragoza,ADM2,DISTRICT
SM301516,-93.0411,36.6547,Taney,ADM2,DISTRICT
SM257906,11.74,6.21,Tang,ETHNICITY,ETHNICITY
SM299194,39.0275,-5.1157,Tanga,ADM2,DISTRICT
SM3418,38.2833,-5.2,Tanga,ADM1,DISTRICT
SM257907,152.4,-3.03,Tanga Island,ETHNICITY,ETHNICITY
SM257907,152.4,-3.03,Tanga Island,ETHNICITY,ETHNICITY
SM257907,153.31,-3.38,Tanga Island,ETHNICITY,ETHNICITY
SM257907,153.31,-3.38,Tanga Island,ETHNICITY,ETHNICITY
SM262249,89.9867,24.3385,Tangail,ADM2,DISTRICT
SM241090,11.3228,9.7544,Tangale,LANGUAGE,LANGUAGE
SM246109,94.98,28.95,Tangam,LANGUAGE,LANGUAGE
SM282081,-102.4341,19.9116,Tangamandapio,ADM2,DISTRICT
SM282082,-102.2286,19.8829,Tangancícuaro,ADM2,DISTRICT
SM3419,29.2,-5.9333,Tanganyika,ADM1:ADM2,DISTRICT
SM262004,30.0724,-2.9136,Tangara,ADM2,DISTRICT
SM295361,159.7298,-9.5694,Tangarare,ADM2,DISTRICT
SM266324,-51.0994,-27.1259,Tangará,ADM2,DISTRICT
SM265499,-35.7963,-6.2385,Tangará,ADM2,DISTRICT
SM263119,-58.0986,-14.5232,Tangará da Serra,ADM2,DISTRICT
SM285229,4.9629,13.4969,Tangazar,ADM2,DISTRICT
SM246075,92.5073,22.3185,Tangchangya,LANGUAGE,LANGUAGE
SM281435,-5.9,35.59,Tanger-Assilah,ADM2,DISTRICT
SM4280,-5.774,35.7263,Tanger-Tetouan-Al Hoceima,ADM1,DISTRICT
SM276720,106.4667,-6.2,Tangerang,ADM2,DISTRICT
SM276719,106.7124,-6.2937,Tangerang Selatan,ADM2,DISTRICT
SM277658,51.2667,28.8833,Tangestan,ADM2,DISTRICT
SM246092,153.611,-4.0882,Tangga,LANGUAGE,LANGUAGE
SM276441,104.6235,-5.3851,Tanggamus,ADM2,DISTRICT
SM246098,144.916,-4.4621,Tanggu,LANGUAGE,LANGUAGE
SM257909,144.46,-4.2,Tanggum,ETHNICITY,ETHNICITY
SM257909,144.46,-4.2,Tanggum,ETHNICITY,ETHNICITY
SM257909,153.31,-3.38,Tanggum,ETHNICITY,ETHNICITY
SM257909,153.31,-3.38,Tanggum,ETHNICITY,ETHNICITY
SM301156,-90.4057,30.6267,Tangipahoa,ADM2,DISTRICT
SM246096,140.269,-5.1334,Tangko,LANGUAGE,LANGUAGE
SM246104,99.9,26.9,Tanglang-Toloza,LANGUAGE,LANGUAGE
SM12780,-77.8362,18.3434,Tangle River,ADM2,DISTRICT
SM269146,118.18,39.63,Tangshan,ADM2,DISTRICT
SM246106,71.2542,38.2267,Tangshewi,LANGUAGE,LANGUAGE
SM270354,-77.3865,1.0919,Tangua,ADM2,DISTRICT
SM246099,144.927,-4.5728,Tanguat,LANGUAGE,LANGUAGE
SM262139,1.4176,11.0362,Tanguiéta,ADM2,DISTRICT
SM246079,102.2465,38.8949,Tangut,LANGUAGE,LANGUAGE
SM265328,-42.7194,-22.7918,Tanguá,ADM2,DISTRICT
SM246105,103.5427,35.8038,Tangwang,LANGUAGE,LANGUAGE
SM267679,-41.1351,-14.0701,Tanhaçu,ADM2,DISTRICT
SM282083,-102.3391,20.2852,Tanhuato,ADM2,DISTRICT
SM260069,69.75,33.1725,Tani,ADM2,DISTRICT
SM14865,-96.7531,16.5658,Taniche,ADM2,DISTRICT
SM257910,141.54,-12.2,Tanikutti,ETHNICITY,ETHNICITY
SM257910,141.54,-12.2,Tanikutti,ETHNICITY,ETHNICITY
SM257910,141.9866,-12.5552,Tanikutti,ETHNICITY,ETHNICITY
SM257910,141.9866,-12.5552,Tanikutti,ETHNICITY,ETHNICITY
SM257911,131.53,-7.52,Tanimbar,ETHNICITY,ETHNICITY
SM257911,131.53,-7.52,Tanimbar,ETHNICITY,ETHNICITY
SM257911,130.96,-8.19,Tanimbar,ETHNICITY,ETHNICITY
SM257911,130.96,-8.19,Tanimbar,ETHNICITY,ETHNICITY
SM246114,166.507,-11.2975,Tanimbili,LANGUAGE,LANGUAGE
SM246116,-70.3853,-0.5902,Tanimuca-Retuarã,LANGUAGE,LANGUAGE
SM3421,99,13,Tanintharyi,ADM1,DISTRICT
SM246120,6.8743,9.4012,Tanjijili,LANGUAGE,LANGUAGE
SM276754,103.244,-1.1544,Tanjung Jabung B,ADM2,DISTRICT
SM276745,103.6176,-1.132,Tanjung Jabung T,ADM2,DISTRICT
SM276438,104.4433,0.9168,Tanjungpinang,ADM2,DISTRICT
SM375414,70.3926,32.24,Tank,ADM2:ADM3,DISTRICT
SM15312,70.306,32.4645,Tank Tribal Area,ADM2,DISTRICT
SM454370,33.33,23.133,Tanka,ETHNICITY,ETHNICITY
SM246125,113.7229,22.1645,Tanka Cantonese,DIALECT,LANGUAGE
SM282511,-98.8621,21.7056,Tanlajás,ADM2,DISTRICT
SM257915,169.44,-19.6,Tannese,ETHNICITY,ETHNICITY
SM257916,-106.9,35.59,Tano,ETHNICITY,ETHNICITY
SM274877,-2.1827,7.201,Tano North,ADM2,DISTRICT
SM274878,-2.0333,7.0833,Tano South,ADM2,DISTRICT
SM13102,138.8086,36.1135,Tano-gun,ADM2,DISTRICT
SM246128,47.1357,-24.9186,Tanosy Malagasy,LANGUAGE,LANGUAGE
SM267680,-42.5431,-13.5837,Tanque Novo,ADM2,DISTRICT
SM265434,-36.4082,-9.5607,Tanque d'Arca,ADM2,DISTRICT
SM265152,-42.1831,-6.6961,Tanque do Piauí,ADM2,DISTRICT
SM267681,-39.0961,-11.9567,Tanquinho,ADM2,DISTRICT
SM282512,-98.6561,21.5913,Tanquián de Escobedo,ADM2,DISTRICT
SM290876,27.2481,46.9081,Tansa,ADM2,DISTRICT
SM273925,30.9809,30.811,Tanta,ADM2,DISTRICT
SM290661,23.5429,44.6187,Tantareni,ADM2,DISTRICT
SM282789,-97.7461,21.431,Tantima,ADM2,DISTRICT
SM282790,-98.1845,21.3931,Tantoyuca,ADM2,DISTRICT
SM236649,121.311,17.3441,Tanudan Kalinga,LANGUAGE,LANGUAGE
SM296601,11.4257,58.7166,Tanum,ADM2,DISTRICT
SM259048,35.459,-11.779,Tanzanian Ngoni,LANGUAGE,LANGUAGE
SM246131,39.127,-6.8243,Tanzanian Sign Language,LANGUAGE,LANGUAGE
SM297500,104.1586,16.9402,Tao Ngoi,ADM2,DISTRICT
SM371034,47.0782,-24.5798,Taolagnaro,ADM2:ADM3,DISTRICT
SM301857,-105.631,36.5783,Taos,ADM2,DISTRICT
SM240065,-105.604,36.4277,Taos Northern Tiwa,LANGUAGE,LANGUAGE
SM4072,-3.9852,22.6665,Taoudenit Region,ADM1,DISTRICT
SM281438,-4.8333,34.5,Taounate,ADM2,DISTRICT
SM4452,-2.8135,34.2463,Taourirt,ADM2,DISTRICT
SM299062,121.2461,24.895,Taoyuan,ADM2,DISTRICT
SM246142,120.952,24.8546,Taoyuan-Miaoli,DIALECT,LANGUAGE
SM11196,25.8457,59.2382,Tapa vald,ADM2,DISTRICT
SM262701,-66.6421,-17.5658,Tapacarí,ADM2,DISTRICT
SM283201,-92.3021,14.9476,Tapachula,ADM2,DISTRICT
SM246147,-92.28,14.9,Tapachultec,LANGUAGE,LANGUAGE
SM257922,-54.62,-2.65,Tapajos,ETHNICITY,ETHNICITY
SM283202,-93.1155,17.2262,Tapalapa,ADM2,DISTRICT
SM295691,-89.0671,13.5467,Tapalhuaca,ADM2,DISTRICT
SM281851,-103.7376,19.9504,Tapalpa,ADM2,DISTRICT
SM260715,-60.0833,-36.3333,Tapalqué,ADM2,DISTRICT
SM296112,-55.0517,3.5083,Tapanahony,ADM2,DISTRICT
SM276697,99.25,1.5167,Tapanuli Selatan,ADM2,DISTRICT
SM276698,98.6667,1.9,Tapanuli Tengah,ADM2,DISTRICT
SM276699,99.0707,2.0028,Tapanuli Utara,ADM2,DISTRICT
SM257923,-65.37,6.36,Taparita,ETHNICITY,ETHNICITY
SM246149,-67.6,7.65,Taparita,LANGUAGE,LANGUAGE
SM264005,-41.6028,-19.7275,Taparuba,ADM2,DISTRICT
SM267354,-65.6999,-6.2142,Tapauá,ADM2,DISTRICT
SM257925,-51.66,-29.43,Tape,ETHNICITY,ETHNICITY
SM246150,-40.3333,-5,Tapeba,LANGUAGE,LANGUAGE
SM246151,143.337,-4.7776,Tapei,LANGUAGE,LANGUAGE
SM264747,-52.9111,-23.6253,Tapejara,ADM2,DISTRICT
SM10028,-52.0082,-28.0643,Tapejara (1996-),ADM2,DISTRICT
SM261097,-59.75,-27.5,Tapenagá,ADM2,DISTRICT
SM265953,-52.8662,-28.6676,Tapera,ADM2,DISTRICT
SM264348,-36.792,-7.1687,Taperoá,ADM2,DISTRICT
SM267682,-39.2202,-13.5696,Taperoá,ADM2,DISTRICT
SM265954,-51.4164,-30.7147,Tapes,ADM2,DISTRICT
SM297367,100.4174,16.211,Taphan Hin,ADM2,DISTRICT
SM276834,73.4,21.12,Tapi,ADM2,DISTRICT
SM257926,-63.39,-20.45,Tapiete,ETHNICITY,ETHNICITY
SM246153,-62.684,-21.4169,Tapieté,LANGUAGE,LANGUAGE
SM283203,-93.0093,17.2536,Tapilula,ADM2,DISTRICT
SM276395,115.0333,-2.9167,Tapin,ADM2,DISTRICT
SM17660,-61.0049,14.0138,Tapion,ADM2,DISTRICT
SM264748,-53.1363,-23.3064,Tapira,ADM2,DISTRICT
SM264007,-46.918,-19.9337,Tapira,ADM2,DISTRICT
SM267683,-40.7569,-11.9473,Tapiramutá,ADM2,DISTRICT
SM257927,-49.75,-11.01,Tapirape,ETHNICITY,ETHNICITY
SM257927,-49.75,-11.01,Tapirape,ETHNICITY,ETHNICITY
SM257927,-49.75,-11.01,Tapirape,ETHNICITY,ETHNICITY
SM257927,-51,-11,Tapirape,ETHNICITY,ETHNICITY
SM257927,-51,-11,Tapirape,ETHNICITY,ETHNICITY
SM257927,-51,-11,Tapirape,ETHNICITY,ETHNICITY
SM257927,-52,-11,Tapirape,ETHNICITY,ETHNICITY
SM257927,-52,-11,Tapirape,ETHNICITY,ETHNICITY
SM257927,-52,-11,Tapirape,ETHNICITY,ETHNICITY
SM246154,-50.5438,-10.5373,Tapirapé,LANGUAGE,LANGUAGE
SM266985,-46.7321,-21.4466,Tapiratiba,ADM2,DISTRICT
SM266984,-47.6258,-24.0149,Tapiraí,ADM2,DISTRICT
SM264006,-46.1329,-19.8727,Tapiraí,ADM2,DISTRICT
SM375321,87.8217,27.6105,Taplejung,ADM2:ADM3,DISTRICT
SM262220,1.75,12,Tapoa,ADM1:ADM2,DISTRICT
SM257928,-63.71,-12.16,Tapoaja,ETHNICITY,ETHNICITY
SM276204,17.4951,46.8983,Tapolca,ADM2,DISTRICT
SM10031,-56.4615,-12.7426,Tapurah (2005-),ADM2,DISTRICT
SM11270,-151.3801,-16.8465,Taputapuatea,ADM2,DISTRICT
SM257930,-76,62,Taqagmiut,ETHNICITY,ETHNICITY
SM257930,-76,62,Taqagmiut,ETHNICITY,ETHNICITY
SM257930,-77.69,55.32,Taqagmiut,ETHNICITY,ETHNICITY
SM257930,-77.69,55.32,Taqagmiut,ETHNICITY,ETHNICITY
SM265955,-50.7637,-29.6634,Taquara,ADM2,DISTRICT
SM266986,-48.3934,-21.0676,Taquaral,ADM2,DISTRICT
SM268183,-49.5904,-16.0582,Taquaral de Goiás,ADM2,DISTRICT
SM265435,-36.4942,-9.6434,Taquarana,ADM2,DISTRICT
SM264008,-43.6916,-19.6335,Taquaraçu de Minas,ADM2,DISTRICT
SM265956,-51.8175,-29.7289,Taquari,ADM2,DISTRICT
SM266989,-48.5474,-21.4327,Taquaritinga,ADM2,DISTRICT
SM264918,-36.1263,-7.8457,Taquaritinga do Norte,ADM2,DISTRICT
SM266990,-49.2144,-23.53,Taquarituba,ADM2,DISTRICT
SM266991,-48.6752,-23.951,Taquarivaí,ADM2,DISTRICT
SM263092,-53.48,-22.7342,Taquarussu,ADM2,DISTRICT
SM265957,-53.4991,-27.4072,Taquaruçu do Sul,ADM2,DISTRICT
SM10538,13.6249,45.2995,Tar-Vabriga-Torre Abrega,ADM2,DISTRICT
SM279141,130.1504,32.9967,Tara,ADM2,DISTRICT
SM3422,10.5,8,Taraba,ADM1,DISTRICT
SM266992,-51.6202,-22.3504,Tarabai,ADM2,DISTRICT
SM3423,28.5833,45.9167,Taraclia,ADM1,DISTRICT
SM283643,102.4458,46.2992,Taragt,ADM2,DISTRICT
SM257931,-107.26,28.19,Tarahumara,ETHNICITY,ETHNICITY
SM257931,-107.26,28.19,Tarahumara,ETHNICITY,ETHNICITY
SM257931,-106.83,26.14,Tarahumara,ETHNICITY,ETHNICITY
SM257931,-106.83,26.14,Tarahumara,ETHNICITY,ETHNICITY
SM257931,-107,28,Tarahumara,ETHNICITY,ETHNICITY
SM257931,-107,28,Tarahumara,ETHNICITY,ETHNICITY
SM270651,-69.8966,-0.7484,Taraira,ADM2,DISTRICT
SM246161,-36.1576,-5.6988,Tarairiu,LANGUAGE,LANGUAGE
SM276423,117.6,3.3667,Tarakan,ADM2,DISTRICT
SM298840,30.5,40.4069,Taraklı,ADM2,DISTRICT
SM10341,17.2781,9.0456,Taralnass,ADM2,DISTRICT
SM279073,124.6988,24.6666,Tarama,ADM2,DISTRICT
SM3424,174.6667,-39.1667,Taranaki,ADM1,DISTRICT
SM281565,-100.532,20.0036,Tarandacuao,ADM2,DISTRICT
SM13268,62.75,52.5,Taranovskiy Rayon (excl. Lisakovsk),ADM2,DISTRICT
SM277927,17.25,40.6167,Taranto,ADM2,DISTRICT
SM246163,94.1703,24.4003,Tarao,LANGUAGE,LANGUAGE
SM10479,-69.7769,-2.8854,Tarapaca,ADM2,DISTRICT
SM3425,-69.3333,-20,Tarapacá (2007-),ADM1,DISTRICT
SM286557,176.0992,-40.4778,Tararua,ADM2,DISTRICT
SM286848,-70.0833,-17.4167,Tarata,ADM2,DISTRICT
SM262794,-71.4234,-8.6552,Tarauacá,ADM2,DISTRICT
SM284884,8.5443,11.9586,Tarauni,ADM2,DISTRICT
SM13345,172.984,1.419,Tarawa,ADM2,DISTRICT
SM270157,-75.5049,7.493,Tarazá,ADM2,DISTRICT
SM295121,107.6667,51.5,Tarbagatayskiy rayon,ADM2,DISTRICT
SM13269,83.75,47.5,Tarbaghatay Audany (excl. Aqsuat),ADM2,DISTRICT
SM292476,22.3636,46.6104,Tarcaia,ADM2,DISTRICT
SM291372,26.1626,46.8227,Tarcau,ADM2,DISTRICT
SM292477,22.1894,47.4577,Tarcea,ADM2,DISTRICT
SM246175,107.296,15.4928,Tareng,LANGUAGE,LANGUAGE
SM282084,-101.9102,19.328,Taretan,ADM2,DISTRICT
SM257933,-162.59,69.44,Tareumiut,ETHNICITY,ETHNICITY
SM257933,-162.59,69.44,Tareumiut,ETHNICITY,ETHNICITY
SM257933,-162.59,69.44,Tareumiut,ETHNICITY,ETHNICITY
SM257933,-157,71,Tareumiut,ETHNICITY,ETHNICITY
SM257933,-157,71,Tareumiut,ETHNICITY,ETHNICITY
SM257933,-157,71,Tareumiut,ETHNICITY,ETHNICITY
SM257933,-156.78,71.24,Tareumiut,ETHNICITY,ETHNICITY
SM257933,-156.78,71.24,Tareumiut,ETHNICITY,ETHNICITY
SM257933,-156.78,71.24,Tareumiut,ETHNICITY,ETHNICITY
SM15015,-12.9208,27.939,Tarfaya,ADM2,DISTRICT
SM13866,21.6554,57.3362,Targales pagasts,ADM2,DISTRICT
SM262453,26.5667,43.25,Targovishte,ADM2,DISTRICT
SM3426,26.3833,43.2667,Targovishte,ADM1,DISTRICT
SM290307,25.455,44.9262,Targoviste,ADM2,DISTRICT
SM290470,27.923,45.874,Targu Bujor,ADM2,DISTRICT
SM290662,23.4957,44.9655,Targu Carbunesti,ADM2,DISTRICT
SM290664,23.2771,45.0528,Targu Jiu,ADM2,DISTRICT
SM291043,23.8535,47.4612,Targu Lapus,ADM2,DISTRICT
SM290665,23.7126,44.8885,Targu Logresti,ADM2,DISTRICT
SM291201,24.5555,46.5388,Targu Mures,ADM2,DISTRICT
SM291373,26.3641,47.197,Targu Neamt,ADM2,DISTRICT
SM292163,26.6113,46.2799,Targu Ocna,ADM2,DISTRICT
SM290231,26.1716,46.0059,Targu Secuiesc,ADM2,DISTRICT
SM292164,26.6759,46.2679,Targu Trotus,ADM2,DISTRICT
SM288649,142.8333,-6.2833,Tari-Pori,ADM2,DISTRICT
SM283728,91.9038,49.7744,Tarialan,ADM2,DISTRICT
SM283603,102.05,49.6167,Tarialan,ADM2,DISTRICT
SM246178,-68.9042,0.4402,Tariana,LANGUAGE,LANGUAGE
SM257934,-69.62,1.25,Tariana,ETHNICITY,ETHNICITY
SM283528,99.8618,48.2239,Tariat,ADM2,DISTRICT
SM246179,-3.4306,34.917,Tarifiyt-Beni-Iznasen-Eastern Middle Atlas Berber,LANGUAGE,LANGUAGE
SM3427,-64,-21.5,Tarija,ADM1,DISTRICT
SM304821,49.0229,16.066,Tarim,ADM2,DISTRICT
SM299102,34.4768,-1.3784,Tarime,ADM2,DISTRICT
SM281566,-100.7575,20.2992,Tarimoro,ADM2,DISTRICT
SM246184,13.158,11.8523,Tarjumo,LANGUAGE,LANGUAGE
SM285350,8.8819,7.6208,Tarka,ADM2,DISTRICT
SM4417,-1.9833,5.3,Tarkwa-Nusaem,ADM2,DISTRICT
SM3428,120.5,15.5,Tarlac,ADM1:ADM2,DISTRICT
SM292536,24.1651,47.4058,Tarlisua,ADM2,DISTRICT
SM292655,25.7737,45.6458,Tarlungeni,ADM2,DISTRICT
SM286751,-75.6913,-11.4056,Tarma,ADM2,DISTRICT
SM257938,-75.81,-11.42,Tarma,ETHNICITY,ETHNICITY
SM285264,11.8023,12.1784,Tarmuwa,ADM2,DISTRICT
SM274449,2.1632,43.7817,Tarn,ADM2,DISTRICT
SM277155,74.8164,31.3269,Tarn Taran,ADM2,DISTRICT
SM274448,1.1667,44,Tarn-et-Garonne,ADM2,DISTRICT
SM291690,23.1949,48.0836,Tarna Mare,ADM2,DISTRICT
SM260242,66.5165,31.9074,Tarnak Wa Jaldak,ADM2,DISTRICT
SM291760,24.2976,46.1375,Tarnava,ADM2,DISTRICT
SM291202,24.2854,46.3263,Tarnaveni,ADM2,DISTRICT
SM289022,21.7159,50.523,Tarnobrzeg,ADM2,DISTRICT
SM289021,21.6987,50.5839,Tarnobrzeg (City),ADM2,DISTRICT
SM294948,43.5,60.5,Tarnogskiy rayon,ADM2,DISTRICT
SM290053,21.9685,45.3327,Tarnova,ADM2,DISTRICT
SM290546,21.8039,46.2905,Tarnova,ADM2,DISTRICT
SM288739,18.8627,50.4726,Tarnowskie Góry,ADM2,DISTRICT
SM288939,20.9053,50.0545,Tarnów,ADM2,DISTRICT
SM288938,20.9786,50.0155,Tarnów (City),ADM2,DISTRICT
SM257939,142.78,-5.51,Taro Island,ETHNICITY,ETHNICITY
SM246188,10.0288,8.9836,Tarok,LANGUAGE,LANGUAGE
SM250740,10,9,Tarok,ETHNICITY,ETHNICITY
SM277670,48.9,36.95,Tarom,ADM2,DISTRICT
SM289407,-7.75,41.0265,Tarouca,ADM2,DISTRICT
SM281427,-8.4167,30.5,Taroudannt,ADM2,DISTRICT
SM246193,140.1155,-2.3223,Tarpia,LANGUAGE,LANGUAGE
SM270104,-75.8768,2.1349,Tarquí,ADM2,DISTRICT
SM3429,-23.7167,15.2667,Tarrafal,ADM1,DISTRICT
SM3430,-24.35,16.6,Tarrafal de São Nicolau,ADM1,DISTRICT
SM267860,-39.7233,-6.7374,Tarrafas,ADM2,DISTRICT
SM274079,1.249,41.129,Tarragona,ADM2,DISTRICT
SM257940,-73.66,60.84,Tarramiut,ETHNICITY,ETHNICITY
SM302774,-97.2912,32.7716,Tarrant,ADM2,DISTRICT
SM270967,-84.0214,9.6596,Tarrazú,ADM2,DISTRICT
SM17100,74.5,56.75,Tarskiy Rayon,ADM2,DISTRICT
SM270158,-75.831,5.8812,Tarso,ADM2,DISTRICT
SM291692,23.35,47.95,Tarsolt,ADM2,DISTRICT
SM370221,-9.1667,5.1667,Tarsue,ADM2:ADM3,DISTRICT
SM298751,34.8549,37.0733,Tarsus,ADM2,DISTRICT
SM267294,-51.1097,1.263,Tartarugalzinho,ADM2,DISTRICT
SM290308,25.8213,44.5767,Tartasesti,ADM2,DISTRICT
SM246197,-8.1239,37.2375,Tartessian,LANGUAGE,LANGUAGE
SM3431,26.725,58.3807,Tartu,ADM1:ADMX,DISTRICT
SM11197,26.7326,58.375,Tartu linn,ADM2,DISTRICT
SM11198,26.7496,58.478,Tartu vald,ADM2,DISTRICT
SM296756,35.9716,34.839,Tartus,ADM2,DISTRICT
SM3432,36.1587,35.0043,Tartus,ADM1,DISTRICT
SM257941,150.28,-22.78,Tarubura,ETHNICITY,ETHNICITY
SM246198,-59.9048,-0.9096,Taruma,LANGUAGE,LANGUAGE
SM257942,-57.86,2.63,Taruma,ETHNICITY,ETHNICITY
SM264009,-41.9198,-19.3004,Tarumirim,ADM2,DISTRICT
SM278510,130.7605,31.5158,Tarumizu,ADM2,DISTRICT
SM292856,46.6667,44.4167,Tarumovskiy rayon,ADM2,DISTRICT
SM266993,-50.6033,-22.7611,Tarumã,ADM2,DISTRICT
SM293083,37,54.6667,Tarusskiy rayon,ADM2,DISTRICT
SM299626,29.1516,46.1855,Tarutyns'kyi,ADM2,DISTRICT
SM370247,-8.7167,5.1169,"Tarweh, Dropo and Putu",ADM2:ADM3,DISTRICT
SM282085,-101.1503,19.7918,Tarímbaro,ADM2,DISTRICT
SM283395,14.5022,35.9125,Tas-Sliema,ADM1:ADM2,DISTRICT
SM257943,124.93,6.82,Tasaday,ETHNICITY,ETHNICITY
SM246200,6.9204,16.7814,Tasawaq,LANGUAGE,LANGUAGE
SM291374,26.0107,46.8899,Tasca,ADM2,DISTRICT
SM270723,-72.7539,5.8482,Tasco,ADM2,DISTRICT
SM293457,95,57.4167,Taseevskiy rayon,ADM2,DISTRICT
SM3433,58.5,41,Tashauz,ADM1,DISTRICT
SM9858,91.75,27.1667,Tashigang,ADM2,DISTRICT
SM9594,70.3959,36.7702,Tashkan,ADM2,DISTRICT
SM3434,69.8,41.2333,Tashkent,ADM1,DISTRICT
SM3435,69.25,41.3333,Tashkent City,ADM1,DISTRICT
SM17101,87.8833,52.7833,Tashtagol'skiy Rayon,ADM2,DISTRICT
SM293211,89.5,52.25,Tashtypskiy rayon,ADM2,DISTRICT
SM276773,108.1333,-7.5,Tasikmalaya,ADM2,DISTRICT
SM257944,-62.95,76.62,Tasiusak,ETHNICITY,ETHNICITY
SM279897,50,50.75,Taskalinskiy,ADM2,DISTRICT
SM13270,80.5,47.1667,Taskesken Audany,ADM2,DISTRICT
SM286507,172.6959,-41.3019,Tasman,ADM2,DISTRICT
SM261773,147.9474,-43.1625,Tasman,ADM2,DISTRICT
SM3436,173.05,-41.2,Tasman,ADM1:ADMX,DISTRICT
SM3437,147,-42,Tasmania,ADM1,DISTRICT
SM257945,146.92,-41.73,Tasmanians,ETHNICITY,ETHNICITY
SM257945,146.92,-41.73,Tasmanians,ETHNICITY,ETHNICITY
SM257945,146,-42,Tasmanians,ETHNICITY,ETHNICITY
SM257945,146,-42,Tasmanians,ETHNICITY,ETHNICITY
SM246204,166.664,-15.0792,Tasmate,LANGUAGE,LANGUAGE
SM291693,22.5833,47.4833,Tasnad,ADM2,DISTRICT
SM245985,30.4815,10.6453,Tasomi-Tata,LANGUAGE,LANGUAGE
SM281708,-99.3456,20.547,Tasquillo,ADM2,DISTRICT
SM15253,4.926,17.2436,Tassara,ADM2,DISTRICT
SM262918,-45.853,-8.286,Tasso Fragoso,ADM2,DISTRICT
SM257946,48.77,40.82,Tat,ETHNICITY,ETHNICITY
SM281453,-7.8333,29.6667,Tata,ADM2,DISTRICT
SM276141,18.3372,47.6687,Tata,ADM2,DISTRICT
SM276142,18.4745,47.5757,Tatabánya,ADM1:ADM2,DISTRICT
SM14866,-94.7798,18.3601,Tatahuicapan de Juarez,ADM2,DISTRICT
SM18003,-175.136,-21.2272,Tatakamotonga,ADM2,DISTRICT
SM11271,-138.4119,-17.3315,Tatakoto,ADM2,DISTRICT
SM4367,0.4315,9.217,Tatale Sangule,ADM2,DISTRICT
SM246209,-97.5311,16.2963,Tataltepec Chatino,LANGUAGE,LANGUAGE
SM14867,-97.56,16.3242,Tataltepec de Valdes,ADM2,DISTRICT
SM295394,159.7518,-8.3615,Tatamba,ADM2,DISTRICT
SM246208,115.553,5.4981,Tatana,LANGUAGE,LANGUAGE
SM3439,10,32,Tataouine,ADM1,DISTRICT
SM298007,10.6335,32.6852,Tataouine Nord,ADM2,DISTRICT
SM298008,10.1893,32.7877,Tataouine Sud,ADM2,DISTRICT
SM246206,50,55,Tatar,LANGUAGE,LANGUAGE
SM257947,50,55,Tatar,ETHNICITY,ETHNICITY
SM257947,50,55,Tatar,ETHNICITY,ETHNICITY
SM257947,50,55,Tatar,ETHNICITY,ETHNICITY
SM257947,52.58,55.23,Tatar,ETHNICITY,ETHNICITY
SM257947,52.58,55.23,Tatar,ETHNICITY,ETHNICITY
SM257947,52.58,55.23,Tatar,ETHNICITY,ETHNICITY
SM257947,48.5,55,Tatar,ETHNICITY,ETHNICITY
SM257947,48.5,55,Tatar,ETHNICITY,ETHNICITY
SM257947,48.5,55,Tatar,ETHNICITY,ETHNICITY
SM292316,27.956,46.7089,Tatarani,ADM2,DISTRICT
SM290309,25.28,45.0007,Tatarani,ADM2,DISTRICT
SM292381,27.3093,45.5162,Tataranu,ADM2,DISTRICT
SM292165,27.1963,46.2282,Tatarasti,ADM2,DISTRICT
SM291939,25.1939,44.3696,Tatarastii De Jos,ADM2,DISTRICT
SM291940,25.1315,44.4086,Tatarastii De Sus,ADM2,DISTRICT
SM299627,29.6133,45.8378,Tatarbunars'kyi,ADM2,DISTRICT
SM17102,76,55.25,Tatarskiy Rayon,ADM2,DISTRICT
SM3440,51,55.3333,Tatarstan,ADM1,DISTRICT
SM291579,26.3198,45.1041,Tataru,ADM2,DISTRICT
SM290878,26.5859,47.3425,Tatarusi,ADM2,DISTRICT
SM282791,-97.0869,19.7104,Tatatila,ADM2,DISTRICT
SM257949,-117.9,34.75,Tataviam,ETHNICITY,ETHNICITY
SM301491,-89.9448,34.6503,Tate,ADM2,DISTRICT
SM278111,139.5353,36.246,Tatebayashi,ADM2,DISTRICT
SM279388,139.8616,34.9716,Tateyama,ADM2,DISTRICT
SM17816,43.5833,19.5833,Tathlith,ADM2,DISTRICT
SM261735,140.5075,-36.2223,Tatiara,ADM2,DISTRICT
SM288941,19.948,49.3042,Tatra,ADM2,DISTRICT
SM257952,-106.13,63,Tatsanuttine,ETHNICITY,ETHNICITY
SM278358,134.5191,34.888,Tatsuno,ADM2,DISTRICT
SM300417,-82.0582,32.0458,Tattnall,ADM2,DISTRICT
SM291469,24.6141,44.6639,Tatulesti,ADM2,DISTRICT
SM275931,-87.0698,13.9847,Tatumbla,ADM2,DISTRICT
SM459061,147.4409,-38.0876,Tatungalung,ETHNICITY,ETHNICITY
SM250178,35,-5,Taturu,ETHNICITY,ETHNICITY
SM250178,35,-5,Taturu,ETHNICITY,ETHNICITY
SM250178,35.5,-4.5,Taturu,ETHNICITY,ETHNICITY
SM250178,35.5,-4.5,Taturu,ETHNICITY,ETHNICITY
SM246216,-70.5327,0.5558,Tatuyo,LANGUAGE,LANGUAGE
SM257953,-71.11,1.04,Tatuyu,ETHNICITY,ETHNICITY
SM266994,-47.8562,-23.3544,Tatuí,ADM2,DISTRICT
SM298215,42.2858,38.4954,Tatvan,ADM2,DISTRICT
SM294290,55.75,56.25,Tatyshlinskiy rayon,ADM2,DISTRICT
SM246217,147.094,-8.3486,Tauade,LANGUAGE,LANGUAGE
SM257954,146.8,-8.51,Tauade,ETHNICITY,ETHNICITY
SM266995,-45.4975,-23.0716,Taubaté,ADM2,DISTRICT
SM275851,-87.9869,14.6715,Taulabe,ADM2,DISTRICT
SM246219,152.095,-4.4443,Taulil,LANGUAGE,LANGUAGE
SM257955,-61.76,5.5,Taulipong,ETHNICITY,ETHNICITY
SM257955,-61.76,5.5,Taulipong,ETHNICITY,ETHNICITY
SM257955,-62,4,Taulipong,ETHNICITY,ETHNICITY
SM257955,-62,4,Taulipong,ETHNICITY,ETHNICITY
SM283480,96.9397,20.4949,Taunggye,ADM2,DISTRICT
SM283498,96.4164,18.6299,Taungoo,ADM2,DISTRICT
SM459055,145.4797,-37.0316,Taungurung,ETHNICITY,ETHNICITY
SM246222,96.7401,20.4104,Taungyo,LANGUAGE,LANGUAGE
SM286514,176.0833,-38.6833,Taupo,ADM2,DISTRICT
SM246227,150.373,-10.2275,Taupota,LANGUAGE,LANGUAGE
SM284800,9.3841,12.2557,Taura,ADM2,DISTRICT
SM3441,22.5,55.3333,Taurages,ADM1,DISTRICT
SM281356,22.2833,55.25,Tauragės,ADM2,DISTRICT
SM270886,-72.6507,4.6645,Tauramena,ADM2,DISTRICT
SM286533,176.2445,-37.6973,Tauranga,ADM2,DISTRICT
SM13867,25.6675,57.1378,Taurenes pagasts,ADM2,DISTRICT
SM291203,24.0817,46.5707,Taureni,ADM2,DISTRICT
SM13868,25.3191,56.9031,Taurupes pagasts,ADM2,DISTRICT
SM270064,-73.9694,5.1764,Tausa,ADM2,DISTRICT
SM246232,136.899,-2.8406,Tause,LANGUAGE,LANGUAGE
SM246233,-75.5603,-3.225,Taushiro,LANGUAGE,LANGUAGE
SM257956,121.12,5.99,Tausog,ETHNICITY,ETHNICITY
SM246231,118.88,5.1224,Tausug,LANGUAGE,LANGUAGE
SM290547,21.9675,46.2638,Taut,ADM2,DISTRICT
SM246234,117.6731,8.8813,Taut Batu,LANGUAGE,LANGUAGE
SM292478,22.3561,47.2813,Tauteu,ADM2,DISTRICT
SM291044,23.486,47.7213,Tautii-magheraus,ADM2,DISTRICT
SM10495,-159.7875,-18.8741,Tautu District,ADM2,DISTRICT
SM246236,145.366,-5.7425,Tauya,LANGUAGE,LANGUAGE
SM267861,-40.2728,-5.9085,Tauá,ADM2,DISTRICT
SM246238,121.4228,23.6351,Tavalong-Vataan,DIALECT,LANGUAGE
SM15369,-55.0714,-25.6245,Tavapy,ADM2,DISTRICT
SM265958,-51.0776,-31.2729,Tavares,ADM2,DISTRICT
SM264349,-37.8796,-7.6142,Tavares,ADM2,DISTRICT
SM298310,29.0797,37.5839,Tavas,ADM2,DISTRICT
SM274405,24.3333,60.9167,Tavastia Proper,ADM1:ADM2,DISTRICT
SM246239,37.7061,-3.4182,Taveta,LANGUAGE,LANGUAGE
SM257957,39.59,-0.63,Taveta,ETHNICITY,ETHNICITY
SM305999,71,38.84,Tavildara,ADM2,DISTRICT
SM289506,-7.6506,37.1309,Tavira,ADM2,DISTRICT
SM257958,-105,40,Taviwatsiu,ETHNICITY,ETHNICITY
SM246243,98.194,14.1078,Tavoyan,LANGUAGE,LANGUAGE
SM293916,73.5833,54.5833,Tavricheskiy rayon,ADM2,DISTRICT
SM246244,14.4036,62.2313,Tavringer Romani,LANGUAGE,LANGUAGE
SM13271,82,50,Tavriya Audany,ADM2,DISTRICT
SM11062,34.0762,35.394,Tavrou,ADM2,DISTRICT
SM295341,156.5603,-6.6688,Tavula,ADM2,DISTRICT
SM3442,45.1667,40.9167,Tavush,ADM1,DISTRICT
SM298701,29.5165,39.549,Tavşanlı,ADM2,DISTRICT
SM257960,-84.21,13.88,Tawaka (sumo),ETHNICITY,ETHNICITY
SM246251,150.644,-10.3115,Tawala,LANGUAGE,LANGUAGE
SM246255,4.4769,13.9878,Tawallammat Tamajaq,LANGUAGE,LANGUAGE
SM254593,5.24,17.22,Tawallammat Tamajaq,ETHNICITY,ETHNICITY
SM250741,21.92,-19.07,Tawana,ETHNICITY,ETHNICITY
SM246254,-60.535,-11.7846,Tawandê,LANGUAGE,LANGUAGE
SM277200,91.9536,27.6619,Tawang,ADM2,DISTRICT
SM246258,92.1838,27.7686,Tawang Monpa,LANGUAGE,LANGUAGE
SM246246,31.621,-16.0167,Tawara,LANGUAGE,LANGUAGE
SM250742,32.17,-17.4,Tawara,ETHNICITY,ETHNICITY
SM3443,120.0265,5.2057,Tawi-Tawi,ADM1:ADM2,DISTRICT
SM246263,139.061,-3.4346,Taworta,LANGUAGE,LANGUAGE
SM246262,115.204,-1.3002,Tawoyan,LANGUAGE,LANGUAGE
SM229627,96.8803,28.4993,Tawra,LANGUAGE,LANGUAGE
SM281632,-99.6261,18.5456,Taxco de Alarcón,ADM2,DISTRICT
SM275314,-90.4618,14.079,Taxisco,ADM2,DISTRICT
SM303502,61.0733,42.9818,Taxtako'pir,ADM2,DISTRICT
SM246273,108.257,13.8962,Tay Boi,LANGUAGE,LANGUAGE
SM246275,104.367,18.6624,Tay Khang,LANGUAGE,LANGUAGE
SM246270,121.293,16.3171,Tayabas Ayta near Lucena City in Western Quezon,LANGUAGE,LANGUAGE
SM286915,-74.75,-12.25,Tayacaja,ADM2,DISTRICT
SM246271,7.6743,16.9232,Tayart Tamajeq,LANGUAGE,LANGUAGE
SM303280,-90.5013,45.2115,Taylor,ADM2,DISTRICT
SM302775,-99.8901,32.3015,Taylor,ADM2,DISTRICT
SM303148,-80.0462,39.336,Taylor,ADM2,DISTRICT
SM300869,-94.6964,40.7374,Taylor,ADM2,DISTRICT
SM300999,-85.3278,37.3665,Taylor,ADM2,DISTRICT
SM300418,-84.2505,32.5555,Taylor,ADM2,DISTRICT
SM300371,-83.619,30.0196,Taylor,ADM2,DISTRICT
SM17817,38.5833,27.75,Tayma',ADM2,DISTRICT
SM17103,95,72,Taymyrsky Dolgano-Nenetsky District,ADM2,DISTRICT
SM246278,166.546,-22.2277,Tayo,LANGUAGE,LANGUAGE
SM13272,52,49.5,Taypaq Audany,ADM2,DISTRICT
SM17104,98,56,Tayshetskiy Rayon,ADM2,DISTRICT
SM259994,64.3896,33.4811,Taywara,ADM2,DISTRICT
SM257963,58.51,23.22,Tayy,ETHNICITY,ETHNICITY
SM278004,35.7047,32.5881,Tayybeh,ADM2,DISTRICT
SM278004,35.7047,32.5881,Tayybeh,ADM2,DISTRICT
SM278004,35.6876,32.4663,Tayybeh,ADM2,DISTRICT
SM278004,35.6876,32.4663,Tayybeh,ADM2,DISTRICT
SM246285,82.4441,65.7035,Taz-Turukhan,DIALECT,LANGUAGE
SM15016,-3.75,34.25,Taza (2009-),ADM2,DISTRICT
SM250295,8,14,Tazarawa,ETHNICITY,ETHNICITY
SM302963,-81.5607,37.125,Tazewell,ADM2,DISTRICT
SM300682,-89.5134,40.5075,Tazewell,ADM2,DISTRICT
SM291375,26.47,46.721,Tazlau,ADM2,DISTRICT
SM295038,80,68.3333,Tazovskiy rayon,ADM2,DISTRICT
SM298686,32.4953,36.9339,Taşkent,ADM2,DISTRICT
SM298598,34.2027,41.5183,Taşköprü,ADM2,DISTRICT
SM298490,43.3768,39.6429,Taşlıçay,ADM2,DISTRICT
SM298778,36.3309,40.77,Taşova,ADM2,DISTRICT
SM280393,105.0683,13.8276,Tbaeng Mean chey,ADM2,DISTRICT
SM3446,44.75,41.7,Tbilisi,ADM1:ADM2,DISTRICT
SM3446,44.75,41.7,Tbilisi,ADM1:ADM2,DISTRICT
SM3446,44.8333,41.7167,Tbilisi,ADM1:ADM2,DISTRICT
SM3446,44.8333,41.7167,Tbilisi,ADM1:ADM2,DISTRICT
SM293396,40.1358,45.3857,Tbilisskiy rayon,ADM2,DISTRICT
SM246287,124.538,6.2038,Tboli,LANGUAGE,LANGUAGE
SM257964,124.39,6.74,Tboli,ETHNICITY,ETHNICITY
SM3447,105.65,11.98,Tbong Khmum,ADM1,DISTRICT
SM296845,1.4167,8.8333,Tchamba (Nyala),ADM2,DISTRICT
SM257965,142.95,-4.96,Tchambuli,ETHNICITY,ETHNICITY
SM257965,142.78,-4.2,Tchambuli,ETHNICITY,ETHNICITY
SM262156,2.5169,9.0349,Tchaourou,ADM2,DISTRICT
SM296846,1.1667,9,Tchaudjo,ADM2,DISTRICT
SM16356,12.0889,-4.8775,Tchiamba-Nzassi,ADM2,DISTRICT
SM375179,3.8986,13.0788,Tchibiri,ADM2:ADM3,DISTRICT
SM260466,16.0762,-12.8386,Tchicala-Tcholoanga,ADM2,DISTRICT
SM14112,-8.1344,6.0583,Tchien (geonames),ADM2,DISTRICT
SM16357,16.3291,-1.1829,Tchikapika,ADM2,DISTRICT
SM260457,14.9312,-12.8172,Tchindjenje,ADM2,DISTRICT
SM246292,13.6001,-2.0339,Tchitchege,LANGUAGE,LANGUAGE
SM269466,-4.8972,9.5272,Tchologo,ADM2,DISTRICT
SM246295,2.5563,8.4345,Tchumbuli,LANGUAGE,LANGUAGE
SM288706,18.7192,53.919,Tczew,ADM2,DISTRICT
SM259483,-149.406,-17.679,Te Parau Tinito,LANGUAGE,LANGUAGE
SM246478,129.1437,-6.9709,Te'un,LANGUAGE,LANGUAGE
SM261736,138.7199,-34.8017,Tea Tree Gully,ADM2,DISTRICT
SM283011,-89.2421,20.3877,Teabo,ADM2,DISTRICT
SM292537,24.4765,46.9115,Teaca,ADM2,DISTRICT
SM246297,166.868,-11.6752,Teanu,LANGUAGE,LANGUAGE
SM282627,-92.9515,17.6026,Teapa,ADM2,DISTRICT
SM3448,21.05,42.1,Tearce,ADM1,DISTRICT
SM290413,23.8639,44.1782,Teasc,ADM2,DISTRICT
SM10342,21.972,17.1142,Tebi,ADM2,DISTRICT
SM15370,-56.6541,-25.7986,Tebicuary,ADM2,DISTRICT
SM289623,-56.6748,-25.918,Tebicuarymí,ADM2,DISTRICT
SM276700,99.1417,3.325,Tebingtinggi,ADM2,DISTRICT
SM276746,102.3747,-1.4558,Tebo,ADM2,DISTRICT
SM297911,10.9515,35.6401,Teboulba,ADM2,DISTRICT
SM297897,9.7385,36.8734,Tebourba,ADM2,DISTRICT
SM257967,147.73,-42.19,Tebranuykuna,ETHNICITY,ETHNICITY
SM246303,-3.5,14.5,Tebul Sign Language,LANGUAGE,LANGUAGE
SM246302,-3.1,14.66,Tebul Ure Dogon,LANGUAGE,LANGUAGE
SM282291,-97.9596,18.8912,Tecali de Herrera,ADM2,DISTRICT
SM281852,-103.2089,19.2652,Tecalitlán,ADM2,DISTRICT
SM282292,-97.7464,18.861,Tecamachalco,ADM2,DISTRICT
SM295588,-88.5019,13.4368,Tecapán,ADM2,DISTRICT
SM282727,-116.6322,32.5503,Tecate,ADM2,DISTRICT
SM281854,-103.566,20.0916,Techaluta de Montenegro,ADM2,DISTRICT
SM4357,-1.9639,7.5342,Techiman (2012-),ADM2,DISTRICT
SM4358,-1.955,7.612,Techiman North,ADM2,DISTRICT
SM281633,-99.2653,16.9729,Tecoanapa,ADM2,DISTRICT
SM283012,-89.4517,20.6964,Tecoh,ADM2,DISTRICT
SM281855,-104.0197,20.2183,Tecolotlán,ADM2,DISTRICT
SM295532,-88.7496,13.4109,Tecoluca,ADM2,DISTRICT
SM282792,-97.0253,20.3881,Tecolutla,ADM2,DISTRICT
SM282293,-98.3069,18.0696,Tecomatlán,ADM2,DISTRICT
SM283324,-103.8735,18.8947,Tecomán,ADM2,DISTRICT
SM281709,-99.6466,20.533,Tecozautla,ADM2,DISTRICT
SM246304,-97.8436,20.2957,Tecpatlán Totonac,LANGUAGE,LANGUAGE
SM246304,-97.8436,20.2957,Tecpatlán Totonac,LANGUAGE,LANGUAGE
SM246304,-97.8675,20.2394,Tecpatlán Totonac,LANGUAGE,LANGUAGE
SM246304,-97.8675,20.2394,Tecpatlán Totonac,LANGUAGE,LANGUAGE
SM283204,-93.471,17.1845,Tecpatán,ADM2,DISTRICT
SM275394,-91.0152,14.7986,Tecpán Guatemala,ADM2,DISTRICT
SM275498,-92.0485,15.3114,Tectitán,ADM2,DISTRICT
SM282139,-105.5169,22.3732,Tecuala,ADM2,DISTRICT
SM290471,27.4283,45.8516,Tecuci,ADM2,DISTRICT
SM275387,-89.7548,15.0542,Teculután,ADM2,DISTRICT
SM281966,-98.9686,19.7137,Tecámac,ADM2,DISTRICT
SM250744,17.48,22.69,Teda-Tebou,ETHNICITY,ETHNICITY
SM250744,17.48,22.69,Teda-Tebou,ETHNICITY,ETHNICITY
SM250744,17.48,22.69,Teda-Tebou,ETHNICITY,ETHNICITY
SM250744,23.81,25.73,Teda-Tebou,ETHNICITY,ETHNICITY
SM250744,23.81,25.73,Teda-Tebou,ETHNICITY,ETHNICITY
SM250744,23.81,25.73,Teda-Tebou,ETHNICITY,ETHNICITY
SM250744,17,21,Teda-Tebou,ETHNICITY,ETHNICITY
SM250744,17,21,Teda-Tebou,ETHNICITY,ETHNICITY
SM250744,17,21,Teda-Tebou,ETHNICITY,ETHNICITY
SM250744,17.5,20.5,Teda-Tebou,ETHNICITY,ETHNICITY
SM250744,17.5,20.5,Teda-Tebou,ETHNICITY,ETHNICITY
SM250744,17.5,20.5,Teda-Tebou,ETHNICITY,ETHNICITY
SM246305,14.7717,18.0426,Tedaga,LANGUAGE,LANGUAGE
SM246306,93.519,23.6079,Tedim Chin,ADM2:LANGUAGE,DISTRICT
SM246309,7.3755,4.7321,Tee,LANGUAGE,LANGUAGE
SM246313,136.753,-2.3504,Tefaro,LANGUAGE,LANGUAGE
SM298235,29.7803,37.3204,Tefenni,ADM2,DISTRICT
SM267355,-65.6045,-4.5223,Tefé,ADM2,DISTRICT
SM276328,109.1667,-7.0333,Tegal,ADM2,DISTRICT
SM246314,31.1112,11.9523,Tegali,LANGUAGE,LANGUAGE
SM257970,-131.16,59.73,Teglin,ETHNICITY,ETHNICITY
SM241721,-110.0621,31.1521,Teguima,LANGUAGE,LANGUAGE
SM294661,88.1667,57.5,Tegul'detskiy rayon,ADM2,DISTRICT
SM303210,-122.2339,40.1257,Tehama,ADM2,DISTRICT
SM246317,131.985,-1.5105,Tehit,LANGUAGE,LANGUAGE
SM3450,51.4167,35.6667,Tehran,ADM1,DISTRICT
SM277345,78.6667,30.5,Tehri Garhwal (1997-),ADM2,DISTRICT
SM282294,-97.4661,18.4299,Tehuacán,ADM2,DISTRICT
SM246321,-68.3235,-47.5796,Tehuelche,LANGUAGE,LANGUAGE
SM259886,-69,-46.5,Tehuelche,ETHNICITY,ETHNICITY
SM259886,-69,-46.5,Tehuelche,ETHNICITY,ETHNICITY
SM259886,-69,-46.5,Tehuelche,ETHNICITY,ETHNICITY
SM259886,-70,-46,Tehuelche,ETHNICITY,ETHNICITY
SM259886,-70,-46,Tehuelche,ETHNICITY,ETHNICITY
SM259886,-70,-46,Tehuelche,ETHNICITY,ETHNICITY
SM259886,-68,-40.5,Tehuelche,ETHNICITY,ETHNICITY
SM259886,-68,-40.5,Tehuelche,ETHNICITY,ETHNICITY
SM259886,-68,-40.5,Tehuelche,ETHNICITY,ETHNICITY
SM257972,-65.94,-43.12,Tehuelche North,ETHNICITY,ETHNICITY
SM257971,-68.92,-47.84,Tehuelche South,ETHNICITY,ETHNICITY
SM261111,-70.2526,-44.2213,Tehuelches,ADM2,DISTRICT
SM282793,-97.0565,18.5246,Tehuipango,ADM2,DISTRICT
SM282295,-98.3076,18.3617,Tehuitzingo,ADM2,DISTRICT
SM291580,26.0102,45.22,Teisani,ADM2,DISTRICT
SM291298,25.1411,44.6423,Teiu,ADM2,DISTRICT
SM289842,23.7081,46.2096,Teius,ADM2,DISTRICT
SM246325,124.174,-8.3766,Teiwa,LANGUAGE,LANGUAGE
SM264350,-37.2752,-7.2482,Teixeira,ADM2,DISTRICT
SM267684,-39.787,-17.424,Teixeira de Freitas,ADM2,DISTRICT
SM264010,-42.8544,-20.63,Teixeiras,ADM2,DISTRICT
SM266113,-62.2474,-10.9927,Teixeirópolis,ADM2,DISTRICT
SM246328,-96.8876,17.0944,Tejalapan Zapotec,LANGUAGE,LANGUAGE
SM16236,-65.8494,18.1478,Tejas Barrio (Humacao),ADM2,DISTRICT
SM16237,-65.8776,18.1472,Tejas Barrio (Las Piedras),ADM2,DISTRICT
SM16235,-65.8897,18.1096,Tejas Barrio (Yabucoa),ADM2,DISTRICT
SM266996,-49.3072,-23.3523,Tejupa,ADM2,DISTRICT
SM14868,-100.2328,18.8616,Tejupilco (2002-),ADM2,DISTRICT
SM295604,-88.9227,13.8536,Tejutepeque,ADM2,DISTRICT
SM275301,-91.8246,15.1564,Tejutla,ADM2,DISTRICT
SM295633,-89.1667,14.2167,Tejutla,ADM2,DISTRICT
SM267862,-39.6497,-3.9355,Tejuçuoca,ADM2,DISTRICT
SM283013,-88.861,21.0167,Tekal de Venegas,ADM2,DISTRICT
SM283014,-89.1055,21.0059,Tekantó,ADM2,DISTRICT
SM283015,-89.2645,20.0404,Tekax,ADM2,DISTRICT
SM257973,59.98,34.45,Teke,ETHNICITY,ETHNICITY
SM257973,54.45,36.12,Teke,ETHNICITY,ETHNICITY
SM246334,15.6233,-2.3842,Teke-Eboo-Nzikou,LANGUAGE,LANGUAGE
SM246330,15.5061,-3.59,Teke-Fuumu,LANGUAGE,LANGUAGE
SM259964,15.13,-1.36,Teke-Generic,ETHNICITY,ETHNICITY
SM259964,15.13,-1.36,Teke-Generic,ETHNICITY,ETHNICITY
SM259964,13.58,-2.6,Teke-Generic,ETHNICITY,ETHNICITY
SM259964,13.58,-2.6,Teke-Generic,ETHNICITY,ETHNICITY
SM259964,14.73,-4.55,Teke-Generic,ETHNICITY,ETHNICITY
SM259964,14.73,-4.55,Teke-Generic,ETHNICITY,ETHNICITY
SM259964,15.32,-3.85,Teke-Generic,ETHNICITY,ETHNICITY
SM259964,15.32,-3.85,Teke-Generic,ETHNICITY,ETHNICITY
SM259964,15,-3,Teke-Generic,ETHNICITY,ETHNICITY
SM259964,15,-3,Teke-Generic,ETHNICITY,ETHNICITY
SM246336,14.4407,-2.2712,Teke-Kukuya,LANGUAGE,LANGUAGE
SM246333,13.2703,-3.3446,Teke-Laali,LANGUAGE,LANGUAGE
SM246335,15.2047,-2.6526,Teke-Nzikou,LANGUAGE,LANGUAGE
SM246331,14.8664,-1.6169,Teke-Tege,LANGUAGE,LANGUAGE
SM246337,13.8603,-2.9803,Teke-Tsaayi,LANGUAGE,LANGUAGE
SM246332,13.8668,-3.5069,Teke-Tyee,LANGUAGE,LANGUAGE
SM13273,78.95,44.8,Tekeli Qalasy,ADM2,DISTRICT
SM257974,-80.41,25.79,Tekesta,ETHNICITY,ETHNICITY
SM3451,27.5,41,Tekirdag,ADM1,DISTRICT
SM283017,-89.2873,20.5506,Tekit,ADM2,DISTRICT
SM298852,36.4621,41.1449,Tekkeköy,ADM2,DISTRICT
SM298396,41.5116,39.65,Tekman,ADM2,DISTRICT
SM250290,-9.4,29.37,Tekna,ETHNICITY,ETHNICITY
SM250290,-9.4,29.37,Tekna,ETHNICITY,ETHNICITY
SM250290,-11,28,Tekna,ETHNICITY,ETHNICITY
SM250290,-11,28,Tekna,ETHNICITY,ETHNICITY
SM230617,-52.3699,3.2369,Teko,LANGUAGE,LANGUAGE
SM257975,-51.93,3.51,Teko,ETHNICITY,ETHNICITY
SM283018,-88.3459,20.5302,Tekom,ADM2,DISTRICT
SM246340,-92.1136,15.4599,Tektiteko,LANGUAGE,LANGUAGE
SM3452,34.8,32.1,Tel Aviv,ADM1:ADM2,DISTRICT
SM3452,34.8,32.1,Tel Aviv,ADM1:ADM2,DISTRICT
SM3452,34.8,32.0841,Tel Aviv,ADM1:ADM2,DISTRICT
SM3452,34.8,32.0841,Tel Aviv,ADM1:ADM2,DISTRICT
SM13274,73,50.0833,Tel'man Audany,ADM2,DISTRICT
SM299963,38,47.4167,Tel'manivs'kyi,ADM2,DISTRICT
SM275657,-87.58,15.7,Tela,ADM2,DISTRICT
SM246342,129.687,-8.012,Tela-Masbuar,LANGUAGE,LANGUAGE
SM3453,79.0833,17.8333,Telangana,ADM1,DISTRICT
SM274812,45.4833,41.9167,Telavi,ADM2,DISTRICT
SM283019,-89.2696,21.1958,Telchac Pueblo,ADM2,DISTRICT
SM283020,-89.2646,21.3136,Telchac Puerto,ADM2,DISTRICT
SM292538,24.394,47.4507,Telciu,ADM2,DISTRICT
SM246344,141.731,-5.0744,Telefol,LANGUAGE,LANGUAGE
SM288596,141.6667,-4.9167,Telefomin,ADM2,DISTRICT
SM291581,25.8144,45.1427,Telega,ADM2,DISTRICT
SM3455,28.5,47.5833,Teleneşti,ADM1,DISTRICT
SM461516,88.5,50.03,Telengit,ETHNICITY,ETHNICITY
SM3456,25.1667,44.0833,Teleorman,ADM1,DISTRICT
SM290666,23.1084,44.9818,Telesti,ADM2,DISTRICT
SM300419,-82.939,31.9298,Telfair,ADM2,DISTRICT
SM274587,-2.5,52.75,Telford and Wrekin,ADM1:ADM2,DISTRICT
SM267145,-36.8675,-10.1794,Telha,ADM2,DISTRICT
SM285416,-86.8084,12.6209,Telica,ADM2,DISTRICT
SM292656,25.8559,45.6986,Teliu,ADM2,DISTRICT
SM290789,22.8793,45.6987,Teliucu Inferior,ADM2,DISTRICT
SM303375,-105.1618,38.8822,Teller,ADM2,DISTRICT
SM270105,-75.0963,3.0381,Tello,ADM2,DISTRICT
SM283831,97.6128,48.638,Telmen,ADM2,DISTRICT
SM281636,-99.9362,18.358,Teloloapan,ADM2,DISTRICT
SM285431,-86.272,13.5151,Telpaneca,ADM2,DISTRICT
SM261112,-67.1667,-42.4167,Telsen,ADM2,DISTRICT
SM246350,78.7024,16.4529,Telugu,LANGUAGE,LANGUAGE
SM250977,79,18,Telugu,ETHNICITY,ETHNICITY
SM250977,79,18,Telugu,ETHNICITY,ETHNICITY
SM250977,79.6,15.9,Telugu,ETHNICITY,ETHNICITY
SM250977,79.6,15.9,Telugu,ETHNICITY,ETHNICITY
SM276514,133.3311,-1.8804,Teluk Bintuni,ADM2,DISTRICT
SM276515,134.5,-2.7,Teluk Wondama,ADM2,DISTRICT
SM246351,129.663,-3.2969,Teluti,LANGUAGE,LANGUAGE
SM264749,-50.5415,-24.2244,Telêmaco Borba,ADM2,DISTRICT
SM3457,22.1667,56.0833,Telšiai,ADM1,DISTRICT
SM281359,22.25,55.9833,Telšių,ADM2,DISTRICT
SM246378,1.136,8.967,Tem,LANGUAGE,LANGUAGE
SM250748,0.72,9.1,Tem,ETHNICITY,ETHNICITY
SM250748,0.72,9.1,Tem,ETHNICITY,ETHNICITY
SM250748,1,11,Tem,ETHNICITY,ETHNICITY
SM250748,1,11,Tem,ETHNICITY,ETHNICITY
SM11443,-0.0348,5.6424,Tema Metropolitan District (2012-),ADM2,DISTRICT
SM281968,-98.868,19.2038,Temamatla,ADM2,DISTRICT
SM276329,110.1167,-7.25,Temanggung,ADM2,DISTRICT
SM282794,-97.7059,20.9892,Temapache,ADM2,DISTRICT
SM281969,-98.8988,19.8272,Temascalapa,ADM2,DISTRICT
SM281970,-100.032,19.9156,Temascalcingo,ADM2,DISTRICT
SM281971,-99.9838,19.0656,Temascaltepec,ADM2,DISTRICT
SM246356,-99.7968,19.3282,Temascaltepec Nahuatl,LANGUAGE,LANGUAGE
SM283021,-88.9068,21.1614,Temax,ADM2,DISTRICT
SM257985,-47.48,-2.35,Tembe,ETHNICITY,ETHNICITY
SM257986,32.5,-27.41,Tembe Tonga,ETHNICITY,ETHNICITY
SM246368,-55.1291,-26.482,Tembey,LANGUAGE,LANGUAGE
SM15371,-55.3905,-25.2588,Tembiapora,ADM2,DISTRICT
SM246360,28.5778,-1.9186,Tembo (Kitembo),LANGUAGE,LANGUAGE
SM3458,115.1667,4.5833,Temburong,ADM1,DISTRICT
SM246374,11.3894,8.7444,Teme,LANGUAGE,LANGUAGE
SM240759,29.3898,11.8275,Temein,LANGUAGE,LANGUAGE
SM299148,39.4218,-6.9245,Temeke,ADM2,DISTRICT
SM284435,102.25,3.6667,Temerloh,ADM2,DISTRICT
SM246376,35.8035,-2.193,Temi,LANGUAGE,LANGUAGE
SM246375,101.438,4.974,Temiar,LANGUAGE,LANGUAGE
SM257987,101.06,5.5,Temiar,ETHNICITY,ETHNICITY
SM279930,56.5667,49.2167,Temirskiy,ADM2,DISTRICT
SM13275,72.9333,50.0833,Temirtau Qalasy,ADM2,DISTRICT
SM282107,-99.2554,18.8527,Temixco,ADM2,DISTRICT
SM250759,-10.66,8.96,Temne,ETHNICITY,ETHNICITY
SM250759,-10.66,8.96,Temne,ETHNICITY,ETHNICITY
SM250759,-12.64,8.46,Temne,ETHNICITY,ETHNICITY
SM250759,-12.64,8.46,Temne,ETHNICITY,ETHNICITY
SM17105,43.1667,54.6667,Temnikovskiy Rayon (excl. Sarov),ADM2,DISTRICT
SM282108,-98.7786,18.7607,Temoac,ADM2,DISTRICT
SM281972,-99.5983,19.471,Temoaya,ADM2,DISTRICT
SM246382,-99.6541,19.6001,Temoaya Otomi,LANGUAGE,LANGUAGE
SM246380,102.916,3.1133,Temoq,LANGUAGE,LANGUAGE
SM257988,103.33,3.58,Temoq,ETHNICITY,ETHNICITY
SM261467,147.4792,-34.3831,Temora,ADM2,DISTRICT
SM14869,-107.8333,28.95,Temosachic (1951-),ADM2,DISTRICT
SM3459,165.9388,-10.7275,Temotu,ADM1,DISTRICT
SM283022,-88.1213,20.8632,Temozón,ADM2,DISTRICT
SM246383,116.545,6.3639,Tempasuk Dusun,LANGUAGE,LANGUAGE
SM12781,-76.8182,18.1047,Temple Hall,ADM2,DISTRICT
SM11063,33.2915,35.3294,Templos,ADM2,DISTRICT
SM14870,-98.3791,21.5651,Tempoal (1988-),ADM2,DISTRICT
SM293397,37.25,45.25,Temryukskiy rayon,ADM2,DISTRICT
SM246385,101.906,2.7818,Temuan,LANGUAGE,LANGUAGE
SM257989,102.79,2.31,Temuan,ETHNICITY,ETHNICITY
SM257989,102.33,3.16,Temuan,ETHNICITY,ETHNICITY
SM11064,32.8866,35.0254,Temvria,ADM2,DISTRICT
SM293649,42.75,54.6667,Ten'gushevskiy rayon,ADM2,DISTRICT
SM293617,149,61.25,Ten'kinskiy rayon,ADM2,DISTRICT
SM273529,-77.8167,-0.9833,Tena,ADM2,DISTRICT
SM270065,-74.3992,4.6486,Tena,ADM2,DISTRICT
SM8213,-77.1747,-0.1833,Tena Lowland Kichwa,ETHNICITY,ETHNICITY
SM246387,-77.5404,-0.7627,Tena Lowland Quichua,LANGUAGE,LANGUAGE
SM283101,-90,19.8333,Tenabo,ADM2,DISTRICT
SM281856,-104.1705,20.1645,Tenamaxtlán,ADM2,DISTRICT
SM282797,-96.8686,19.2463,Tenampa,ADM2,DISTRICT
SM282296,-97.4026,20.1859,Tenampulco,ADM2,DISTRICT
SM282699,-98.1901,19.1479,Tenancingo,ADM2,DISTRICT
SM281973,-99.5756,18.9606,Tenancingo,ADM2,DISTRICT
SM295647,-88.9693,13.8265,Tenancingo,ADM2,DISTRICT
SM246388,-98.1751,20.4262,Tenango Otomi,LANGUAGE,LANGUAGE
SM281711,-98.2049,20.354,Tenango de Doria,ADM2,DISTRICT
SM281975,-98.8591,19.1589,Tenango del Aire,ADM2,DISTRICT
SM281976,-99.6055,19.0821,Tenango del Valle,ADM2,DISTRICT
SM271826,-70.3333,19.3833,Tenares,ADM2,DISTRICT
SM279562,140.3965,38.3582,Tendo,ADM2,DISTRICT
SM257990,-4.33,10.11,Tene,ETHNICITY,ETHNICITY
SM283205,-92.4813,16.8436,Tenejapa,ADM2,DISTRICT
SM265500,-38.1618,-6.4695,Tenente Ananias,ADM2,DISTRICT
SM265501,-36.7224,-6.1485,Tenente Laurentino Cruz,ADM2,DISTRICT
SM265959,-53.7712,-27.3629,Tenente Portela,ADM2,DISTRICT
SM270139,-74.7347,9.932,Tenerife,ADM2,DISTRICT
SM246366,-48.2359,-2.6123,Tenetehara,LANGUAGE,LANGUAGE
SM257991,-45.6,-4.07,Tenetehara,ETHNICITY,ETHNICITY
SM257991,-45.6,-4.07,Tenetehara,ETHNICITY,ETHNICITY
SM257991,-46,-3,Tenetehara,ETHNICITY,ETHNICITY
SM257991,-46,-3,Tenetehara,ETHNICITY,ETHNICITY
SM246398,117.645,0.718,Tenggarong Kutai Malay,LANGUAGE,LANGUAGE
SM246403,113.037,-7.8907,Tengger,LANGUAGE,LANGUAGE
SM257992,113.13,-8.11,Tenggerese,ETHNICITY,ETHNICITY
SM13276,69,49.6667,Tengiz Audany,ADM2,DISTRICT
SM11693,94.1473,24.3792,Tengnoupal,ADM2,DISTRICT
SM246392,-3.3716,14.0675,Tengou-Togo Dogon,LANGUAGE,LANGUAGE
SM246405,-61.936,-7.9263,Tenharim-Parintintin-Diahoi,LANGUAGE,LANGUAGE
SM246406,-121.44,44.7985,Tenino,LANGUAGE,LANGUAGE
SM257993,-121.57,44.01,Tenino,ETHNICITY,ETHNICITY
SM257993,-121.57,44.01,Tenino,ETHNICITY,ETHNICITY
SM257993,-121.57,44.01,Tenino,ETHNICITY,ETHNICITY
SM257993,-121.57,44.01,Tenino,ETHNICITY,ETHNICITY
SM257993,-121,45,Tenino,ETHNICITY,ETHNICITY
SM257993,-121,45,Tenino,ETHNICITY,ETHNICITY
SM257993,-121,45,Tenino,ETHNICITY,ETHNICITY
SM257993,-121,45,Tenino,ETHNICITY,ETHNICITY
SM257993,-120.45,45.59,Tenino,ETHNICITY,ETHNICITY
SM257993,-120.45,45.59,Tenino,ETHNICITY,ETHNICITY
SM257993,-120.45,45.59,Tenino,ETHNICITY,ETHNICITY
SM257993,-120.45,45.59,Tenino,ETHNICITY,ETHNICITY
SM257993,-121.5,45.4,Tenino,ETHNICITY,ETHNICITY
SM257993,-121.5,45.4,Tenino,ETHNICITY,ETHNICITY
SM257993,-121.5,45.4,Tenino,ETHNICITY,ETHNICITY
SM257993,-121.5,45.4,Tenino,ETHNICITY,ETHNICITY
SM246407,150.923,-2.7181,Tenis,LANGUAGE,LANGUAGE
SM246407,150.923,-2.7181,Tenis,LANGUAGE,LANGUAGE
SM246407,150.672,-1.6507,Tenis,LANGUAGE,LANGUAGE
SM246407,150.672,-1.6507,Tenis,LANGUAGE,LANGUAGE
SM270066,-74.1657,4.8232,Tenjo,ADM2,DISTRICT
SM3460,-86.2503,35.7504,Tennessee,ADM1,DISTRICT
SM246409,32.6307,4.9111,Tennet,LANGUAGE,LANGUAGE
SM282798,-96.9325,19.8119,Tenochtitlán,ADM2,DISTRICT
SM14113,-10.1667,6.3333,Tenor Chiefdom,ADM2,DISTRICT
SM282628,-91.3064,17.4334,Tenosique,ADM2,DISTRICT
SM278918,135.8374,34.5962,Tenri,ADM2,DISTRICT
SM301157,-91.3401,32.0017,Tensas,ADM2,DISTRICT
SM261468,152.048,-29.069,Tenterfield,ADM2,DISTRICT
SM270724,-73.4246,5.0708,Tenza,ADM2,DISTRICT
SM264351,-36.6222,-6.9757,Tenório,ADM2,DISTRICT
SM281857,-102.5443,21.472,Teocaltiche,ADM2,DISTRICT
SM282799,-96.9776,19.3788,Teocelo,ADM2,DISTRICT
SM14871,-96.6503,17.3138,Teococuilco de Marcos Perez,ADM2,DISTRICT
SM281858,-103.35,20.1015,Teocuitatlán de Corona,ADM2,DISTRICT
SM266997,-52.364,-22.4162,Teodoro Sampaio,ADM2,DISTRICT
SM267685,-38.6121,-12.2655,Teodoro Sampaio,ADM2,DISTRICT
SM267687,-38.9452,-11.5341,Teofilândia,ADM2,DISTRICT
SM299458,26.4192,49.8391,Teofipol's'kyi,ADM2,DISTRICT
SM246413,-97.2244,16.5906,Teojomulco Chatino,LANGUAGE,LANGUAGE
SM282700,-98.1287,19.2242,Teolocholco,ADM2,DISTRICT
SM281977,-99.1808,19.7463,Teoloyucán,ADM2,DISTRICT
SM267688,-39.4649,-13.5703,Teolândia,ADM2,DISTRICT
SM246414,154.971,-5.6747,Teop,LANGUAGE,LANGUAGE
SM461507,155.18,-5.85,Teop,ETHNICITY,ETHNICITY
SM282297,-98.2676,18.7567,Teopantlán,ADM2,DISTRICT
SM283206,-92.5167,16.5597,Teopisca,ADM2,DISTRICT
SM246416,131.737,-4.7416,Teor,LANGUAGE,LANGUAGE
SM270390,-73.1601,8.7522,Teorama,ADM2,DISTRICT
SM295668,-89.5424,13.5751,Teotepeque,ADM2,DISTRICT
SM281978,-98.8625,19.6865,Teotihuacán,ADM2,DISTRICT
SM14872,-97.0589,18.1422,Teotitlan de Flores Magon,ADM2,DISTRICT
SM14873,-96.528,17.0227,Teotitlan del Valle,ADM2,DISTRICT
SM282298,-98.8135,18.4664,Teotlalco,ADM2,DISTRICT
SM14874,-97.5429,17.7397,Teotongo,ADM2,DISTRICT
SM265436,-36.4327,-9.9901,Teotônio Vilela,ADM2,DISTRICT
SM246430,11.1174,6.6757,Tep,LANGUAGE,LANGUAGE
SM282609,-109.51,29.4761,Tepache,ADM2,DISTRICT
SM295342,156.6635,-6.8689,Tepakaza,ADM2,DISTRICT
SM283023,-89.0224,21.0619,Tepakán,ADM2,DISTRICT
SM282086,-102.8451,19.0603,Tepalcatepec,ADM2,DISTRICT
SM282110,-98.8694,18.6015,Tepalcingo,ADM2,DISTRICT
SM282299,-97.5335,18.5381,Tepanco de López,ADM2,DISTRICT
SM282300,-97.7837,20.0016,Tepango de Rodríguez,ADM2,DISTRICT
SM281859,-102.7212,20.8331,Tepatitlán de Morelos,ADM2,DISTRICT
SM282800,-96.8466,19.048,Tepatlaxco,ADM2,DISTRICT
SM282302,-97.9981,19.1359,Tepatlaxco de Hidalgo,ADM2,DISTRICT
SM282303,-97.8841,18.9869,Tepeaca,ADM2,DISTRICT
SM281712,-98.5015,19.8047,Tepeapulco,ADM2,DISTRICT
SM18106,30.5021,39.7843,Tepebasi,ADM2,DISTRICT
SM246421,-101.729,19.2343,Tepecano,LANGUAGE,LANGUAGE
SM257995,-103.46,22.94,Tepecano,ETHNICITY,ETHNICITY
SM283076,-103.3284,21.6274,Tepechitlán,ADM2,DISTRICT
SM281637,-99.473,18.1511,Tepecoacuilco de Trujano,ADM2,DISTRICT
SM295669,-89.4899,13.6957,Tepecoyo,ADM2,DISTRICT
SM281713,-98.8444,21.0668,Tepehuacán de Guerrero,ADM2,DISTRICT
SM281524,-106.2088,25.4784,Tepehuanes,ADM2,DISTRICT
SM257996,-106.83,26.14,Tepehuenes,ETHNICITY,ETHNICITY
SM257996,-106.83,26.14,Tepehuenes,ETHNICITY,ETHNICITY
SM257996,-104.53,25.23,Tepehuenes,ETHNICITY,ETHNICITY
SM257996,-104.53,25.23,Tepehuenes,ETHNICITY,ETHNICITY
SM257996,-104.76,26.75,Tepehuenes,ETHNICITY,ETHNICITY
SM257996,-104.76,26.75,Tepehuenes,ETHNICITY,ETHNICITY
SM257996,-101.6,24.12,Tepehuenes,ETHNICITY,ETHNICITY
SM257996,-101.6,24.12,Tepehuenes,ETHNICITY,ETHNICITY
SM257996,-107,27,Tepehuenes,ETHNICITY,ETHNICITY
SM257996,-107,27,Tepehuenes,ETHNICITY,ETHNICITY
SM281714,-99.3526,19.902,Tepeji del Río de Ocampo,ADM2,DISTRICT
SM260492,19.9667,40.3333,Tepelenës,ADM2,DISTRICT
SM14875,-97.3456,17.9591,Tepelmeme Villa de Morelos,ADM2,DISTRICT
SM282304,-98.6256,18.7453,Tepemaxalco,ADM2,DISTRICT
SM282305,-98.4433,18.7201,Tepeojuma,ADM2,DISTRICT
SM282701,-98.3807,19.2794,Tepetitla de Lardizábal,ADM2,DISTRICT
SM281715,-99.3888,20.183,Tepetitlán,ADM2,DISTRICT
SM295533,-88.8409,13.6447,Tepetitán,ADM2,DISTRICT
SM281979,-98.8191,19.575,Tepetlaoxtoc,ADM2,DISTRICT
SM281980,-98.8213,19.0301,Tepetlixpa,ADM2,DISTRICT
SM282801,-96.7757,19.6695,Tepetlán,ADM2,DISTRICT
SM283077,-103.1324,22.4576,Tepetongo,ADM2,DISTRICT
SM246422,-96.5404,17.7115,Tepetotutla Chinantec,LANGUAGE,LANGUAGE
SM282802,-97.8558,21.1452,Tepetzintla,ADM2,DISTRICT
SM282306,-97.8473,19.9532,Tepetzintla,ADM2,DISTRICT
SM246423,-96.788,17.756,Tepeuxila Cuicatec,LANGUAGE,LANGUAGE
SM282307,-98.6689,18.6335,Tepexco,ADM2,DISTRICT
SM282308,-97.9297,18.5302,Tepexi de Rodríguez,ADM2,DISTRICT
SM282309,-97.4624,19.4578,Tepeyahualco,ADM2,DISTRICT
SM282310,-97.8675,18.811,Tepeyahualco de Cuauhtémoc,ADM2,DISTRICT
SM14876,-98.236,19.2513,Tepeyanco (1995-),ADM2,DISTRICT
SM281495,-102.1667,22.2167,Tepezalá,ADM2,DISTRICT
SM282140,-104.8719,21.5623,Tepic,ADM2,DISTRICT
SM246426,-96.1433,17.6651,Tepinapa Chinantec,LANGUAGE,LANGUAGE
SM271200,13.8333,50.6333,Teplice,ADM2,DISTRICT
SM294717,37.6667,53.5833,Teplo-Ogarevskiy rayon,ADM2,DISTRICT
SM299781,29.7452,48.6661,Teplyts'kyi,ADM2,DISTRICT
SM257997,-7.21,5.48,Tepo,ETHNICITY,ETHNICITY
SM246427,-7.6635,5.1627,Tepo Krumen,LANGUAGE,LANGUAGE
SM281981,-99.2223,19.7149,Tepotzotlán,ADM2,DISTRICT
SM282111,-99.0997,18.9642,Tepoztlán,ADM2,DISTRICT
SM257998,142.66,-11.79,Teppathiggi,ETHNICITY,ETHNICITY
SM257998,142.66,-11.79,Teppathiggi,ETHNICITY,ETHNICITY
SM257998,142.0727,-12.0767,Teppathiggi,ETHNICITY,ETHNICITY
SM257998,142.0727,-12.0767,Teppathiggi,ETHNICITY,ETHNICITY
SM290472,27.3768,45.9773,Tepu,ADM2,DISTRICT
SM257999,-66.42,9.55,Teque,ETHNICITY,ETHNICITY
SM282803,-97.0215,18.74,Tequila,ADM2,DISTRICT
SM281860,-103.7653,21.0793,Tequila,ADM2,DISTRICT
SM282455,-99.9492,20.5599,Tequisquiapan,ADM2,DISTRICT
SM246433,-95.59,16.4,Tequistlateco Chontal,LANGUAGE,LANGUAGE
SM281982,-99.1455,19.9094,Tequixquiac,ADM2,DISTRICT
SM246454,38.5061,67.2458,Ter Saami,LANGUAGE,LANGUAGE
SM246434,11.8095,11.0376,Tera,LANGUAGE,LANGUAGE
SM11065,32.427,34.9793,Tera,ADM2,DISTRICT
SM250749,12,11,Tera,ETHNICITY,ETHNICITY
SM15254,0.843,14.186,Tera Department,ADM2,DISTRICT
SM258000,34.57,30.81,Terabin,ETHNICITY,ETHNICITY
SM13346,-160.388,4.6876,Teraina,ADM2,DISTRICT
SM258004,147.58,-42.74,Teralinak,ETHNICITY,ETHNICITY
SM277862,13.6833,42.65,Teramo,ADM2,DISTRICT
SM293572,38.25,52.1667,Terbunskiy rayon,ADM2,DISTRICT
SM298386,40.3851,39.7781,Tercan,ADM2,DISTRICT
SM271054,-76.3097,20.159,Tercer Frente,ADM2,DISTRICT
SM261137,-63.75,-32.3333,Tercero Arriba,ADM2,DISTRICT
SM16238,-66.6097,18.0112,Tercero Barrio,ADM2,DISTRICT
SM260070,70.1908,33.3837,Tere Zayi,ADM2,DISTRICT
SM291694,22.7423,47.6782,Terebesti,ADM2,DISTRICT
SM299739,25.6916,49.2988,Terebovlians'kyi,ADM2,DISTRICT
SM246439,143.835,-3.6872,Terebu,LANGUAGE,LANGUAGE
SM258005,-58.11,-0.61,Terecuma,ETHNICITY,ETHNICITY
SM290054,22.3119,45.1454,Teregova,ADM2,DISTRICT
SM246441,155.745,-6.7005,Terei,LANGUAGE,LANGUAGE
SM13277,52.1667,51.1667,Terekti Audany (excl. Priural'e),ADM2,DISTRICT
SM258006,-42.53,-3.1,Teremembe,ETHNICITY,ETHNICITY
SM292026,20.5291,45.954,Teremia Mare,ADM2,DISTRICT
SM294865,48.25,53.75,Teren'gul'skiy rayon,ADM2,DISTRICT
SM258007,-58,-21,Terena,ETHNICITY,ETHNICITY
SM246442,-56.0329,-20.6614,Terena-Kinikinao-Chane,LANGUAGE,LANGUAGE
SM263093,-55.106,-20.4207,Terenos,ADM2,DISTRICT
SM279869,65,45,Terenozekskiy,ADM2,DISTRICT
SM10032,-42.7407,-5.1025,Teresina (1995-),ADM2,DISTRICT
SM268184,-47.2527,-13.7324,Teresina de Goiás,ADM2,DISTRICT
SM246435,93.1297,8.2372,Teressa,LANGUAGE,LANGUAGE
SM265329,-42.8741,-22.3135,Teresópolis,ADM2,DISTRICT
SM246440,124.271,-8.4806,Tereweng,LANGUAGE,LANGUAGE
SM264919,-36.6121,-9.0877,Terezinha,ADM2,DISTRICT
SM268185,-49.0743,-16.428,Terezópolis de Goiás,ADM2,DISTRICT
SM375317,87.5244,27.1325,Terhathum,ADM2:ADM3,DISTRICT
SM246447,-82.9631,9.067,Teribe,LANGUAGE,LANGUAGE
SM246446,35,0.6,Terik,LANGUAGE,LANGUAGE
SM274803,42.9,42.2167,Terjola,ADM2,DISTRICT
SM296002,31.4456,5.6189,Terkaka,ADM2,DISTRICT
SM299009,29.1805,40.6138,Termal,ADM2,DISTRICT
SM246448,123.169,-10.7326,Termanu,LANGUAGE,LANGUAGE
SM298853,36.9826,41.2188,Terme,ADM2,DISTRICT
SM18452,67.2764,37.2392,Termiz Shahri,ADM2,DISTRICT
SM18453,67.5,37.3167,Termiz Tumani,ADM2,DISTRICT
SM258008,127.24,1.09,Ternatan,ETHNICITY,ETHNICITY
SM246450,127.333,0.8056,Ternate,LANGUAGE,LANGUAGE
SM276460,127.3102,0.8962,Ternate,ADM2,DISTRICT
SM246451,120.72,14.28,Ternateño,LANGUAGE,LANGUAGE
SM285618,3.8282,51.2855,Terneuzen,ADM2,DISTRICT
SM294105,137.5,46,Terneyskiy rayon,ADM2,DISTRICT
SM277920,12.3167,42.6833,Terni,ADM2,DISTRICT
SM11402,25.5656,49.5504,Ternopil Raion (2020-),ADM2,DISTRICT
SM3461,25.5915,49.5558,Ternopil',ADM1,DISTRICT
SM294995,41.25,51.6667,Ternovskiy rayon,ADM2,DISTRICT
SM290415,23.492,44.3063,Terpezita,ADM2,DISTRICT
SM264115,-47.8445,-0.9931,Terra Alta,ADM2,DISTRICT
SM264750,-52.3443,-23.6794,Terra Boa,ADM2,DISTRICT
SM264920,-39.391,-8.1698,Terra Nova,ADM2,DISTRICT
SM267689,-38.6187,-12.3854,Terra Nova,ADM2,DISTRICT
SM263122,-54.9555,-10.4954,Terra Nova do Norte,ADM2,DISTRICT
SM264751,-52.6846,-22.7327,Terra Rica,ADM2,DISTRICT
SM266998,-48.369,-20.7618,Terra Roxa,ADM2,DISTRICT
SM264752,-54.0909,-24.2229,Terra Roxa,ADM2,DISTRICT
SM264116,-56.4825,-1.8965,Terra Santa,ADM2,DISTRICT
SM319321,-50.0614,-29.5844,Terra de Areia (1996-),ADM2:ADM3,DISTRICT
SM285454,-85.9609,12.7066,Terrabona,ADM2,DISTRICT
SM258009,147.18,-24.5,Terrabura,ETHNICITY,ETHNICITY
SM258009,147.18,-24.5,Terrabura,ETHNICITY,ETHNICITY
SM258009,145.2719,-25.6353,Terrabura,ETHNICITY,ETHNICITY
SM258009,145.2719,-25.6353,Terrabura,ETHNICITY,ETHNICITY
SM16239,-66.9317,18.4801,Terranova Barrio,ADM2,DISTRICT
SM289434,-8.1911,41.7391,Terras de Bouro,ADM2,DISTRICT
SM17661,-61.0501,13.8423,Terre Blanche,ADM2,DISTRICT
SM301158,-90.8376,29.3374,Terrebonne,ADM2,DISTRICT
SM302776,-102.0765,30.225,Terrell,ADM2,DISTRICT
SM300420,-84.4369,31.7769,Terrell,ADM2,DISTRICT
SM14877,-97.9146,19.4847,Terrenate (1995-),ADM2,DISTRICT
SM274475,6.8884,47.6358,Territoire de Belfort,ADM2,DISTRICT
SM302777,-102.3352,33.1738,Terry,ADM2,DISTRICT
SM285745,5.35,53.4,Terschelling,ADM2,DISTRICT
SM246455,5.3167,53.4,Terschelling Frisian,LANGUAGE,LANGUAGE
SM11066,33.5459,34.8594,Tersefanou,ADM2,DISTRICT
SM17106,44.25,43.5833,Terskiy Rayon,ADM2,DISTRICT
SM293739,35,66.9167,Terskiy rayon,ADM2,DISTRICT
SM274059,-0.6667,40.6667,Teruel,ADM2,DISTRICT
SM270106,-75.5636,2.7524,Teruel,ADM2,DISTRICT
SM262509,27.4,43.75,Tervel,ADM2,DISTRICT
SM4060,23.3169,56.4261,Tervetes Novads,ADM1,DISTRICT
SM13869,23.3402,56.4769,Tervetes pagasts,ADM2,DISTRICT
SM9595,68.3137,31.9062,Terwo,ADM2,DISTRICT
SM283729,93.5907,50.4635,Tes,ADM2,DISTRICT
SM283832,95.7936,49.6549,Tes,ADM2,DISTRICT
SM283832,95.7936,49.6549,Tes,ADM2,DISTRICT
SM283832,95.6264,49.6276,Tes,ADM2,DISTRICT
SM283832,95.6264,49.6276,Tes,ADM2,DISTRICT
SM246457,47.33,-23.3,Tesaka Malagasy,LANGUAGE,LANGUAGE
SM270107,-75.7155,2.5353,Tesalia,ADM2,DISTRICT
SM246458,29.5803,11.3885,Tese,LANGUAGE,LANGUAGE
SM274002,36.6596,15.1107,Teseneye,ADM2,DISTRICT
SM246459,8.3559,9.6419,Teshenawa,LANGUAGE,LANGUAGE
SM283770,102.6616,49.9411,Teshig,ADM2,DISTRICT
SM13103,141.8876,44.91,Teshio Gun,ADM2,DISTRICT
SM291582,25.7255,45.3211,Tesila,ADM2,DISTRICT
SM15255,10.9835,15.7134,Tesker,ADM2,DISTRICT
SM313194,17.7319,44.5728,Teslić,ADM2:ADM3,DISTRICT
SM291470,24.356,44.5132,Teslui,ADM2,DISTRICT
SM290416,24.1524,44.2053,Teslui,ADM2,DISTRICT
SM246462,33.9394,1.1832,Teso,LANGUAGE,LANGUAGE
SM5257,34.276,0.607,Teso,ADM2,DISTRICT
SM263123,-53.4334,-15.949,Tesouro,ADM2,DISTRICT
SM283355,0.0667,20.0965,Tessalit,ADM2,DISTRICT
SM284612,7.9,13.6,Tessaoua,ADM2,DISTRICT
SM297983,9.4257,36.5944,Testour,ADM2,DISTRICT
SM295465,160.068,-11.5573,Tetau Nangoto,ADM2,DISTRICT
SM292479,22.298,47.0366,Tetchea,ADM2,DISTRICT
SM290311,25.4437,44.6758,Tetcoiu,ADM2,DISTRICT
SM3462,33,-15.5,Tete,ADM1,DISTRICT
SM17662,-60.9979,13.8909,Tete Chemin,ADM2,DISTRICT
SM17663,-61.034,13.7616,Tete Morne,ADM2,DISTRICT
SM17664,-60.9519,13.7711,Tete Morne/Morne Andrew,ADM2,DISTRICT
SM282112,-99.4045,18.711,Tetecala,ADM2,DISTRICT
SM295362,160.6017,-9.829,Tetekanji,ADM2,DISTRICT
SM246466,24.546,-4.2931,Tetela,LANGUAGE,LANGUAGE
SM250752,22.38,-1,Tetela,ETHNICITY,ETHNICITY
SM250752,22.38,-1,Tetela,ETHNICITY,ETHNICITY
SM250752,25,-4,Tetela,ETHNICITY,ETHNICITY
SM250752,25,-4,Tetela,ETHNICITY,ETHNICITY
SM282311,-97.7751,19.8279,Tetela de Ocampo,ADM2,DISTRICT
SM282113,-98.7143,18.875,Tetela del Volcán,ADM2,DISTRICT
SM246467,-98.893,18.8854,Tetelcingo Nahuatl,LANGUAGE,LANGUAGE
SM282313,-97.4504,19.8552,Teteles de Avila Castillo,ADM2,DISTRICT
SM281716,-99.1635,20.1158,Tetepango,ADM2,DISTRICT
SM246468,-76.5761,-0.0267,Tetete,LANGUAGE,LANGUAGE
SM262547,24.2667,42.9167,Teteven,ADM2,DISTRICT
SM281638,-99.6778,18.6521,Tetipac,ADM2,DISTRICT
SM283024,-89.9566,20.9704,Tetiz,ADM2,DISTRICT
SM282705,-98.0707,19.4915,Tetla de la Solidaridad,ADM2,DISTRICT
SM14878,-98.2879,19.2469,Tetlatlahuca (1995-),ADM2,DISTRICT
SM292246,23.9174,44.7837,Tetoiu,ADM2,DISTRICT
SM303307,-110.5897,43.9348,Teton,ADM2,DISTRICT
SM301667,-112.2408,47.8373,Teton,ADM2,DISTRICT
SM300579,-111.2077,43.7595,Teton,ADM2,DISTRICT
SM258012,-99.15,46.61,Teton,ETHNICITY,ETHNICITY
SM258012,-99.15,46.61,Teton,ETHNICITY,ETHNICITY
SM258012,-99.15,46.61,Teton,ETHNICITY,ETHNICITY
SM258012,-98.97,44.74,Teton,ETHNICITY,ETHNICITY
SM258012,-98.97,44.74,Teton,ETHNICITY,ETHNICITY
SM258012,-98.97,44.74,Teton,ETHNICITY,ETHNICITY
SM258012,-103,43,Teton,ETHNICITY,ETHNICITY
SM258012,-103,43,Teton,ETHNICITY,ETHNICITY
SM258012,-103,43,Teton,ETHNICITY,ETHNICITY
SM258012,-102.27,44.5,Teton,ETHNICITY,ETHNICITY
SM258012,-102.27,44.5,Teton,ETHNICITY,ETHNICITY
SM258012,-102.27,44.5,Teton,ETHNICITY,ETHNICITY
SM4460,-5.5,35.5833,Tetouan (2010-),ADM2,DISTRICT
SM3463,20.9025,42.0444,Tetovo,ADM1,DISTRICT
SM11344,44.4667,41.55,Tetri Tsqaro,ADM2,DISTRICT
SM11345,44.5,41.6,Tetrits'q'alos Munitsip'alit'et'I (excl. Tetri Tskaro),ADM2,DISTRICT
SM246472,5.6997,14.9733,Tetserret,LANGUAGE,LANGUAGE
SM16240,-66.6126,18.2679,Tetuan Barrio,ADM2,DISTRICT
SM246474,125.528,-9.1122,Tetum,LANGUAGE,LANGUAGE
SM246475,125.583,-8.5841,Tetun Dili,LANGUAGE,LANGUAGE
SM258015,125.77,-8.86,Tetun Terik,ETHNICITY,ETHNICITY
SM258015,125.77,-8.86,Tetun Terik,ETHNICITY,ETHNICITY
SM258015,126,-9,Tetun Terik,ETHNICITY,ETHNICITY
SM258015,126,-9,Tetun Terik,ETHNICITY,ETHNICITY
SM294636,48.75,55,Tetyushskiy rayon,ADM2,DISTRICT
SM17107,39.3333,44.9167,Teuchezhskiy Rayon (excl. Adygeysk city),ADM2,DISTRICT
SM281861,-103.8344,20.6741,Teuchitlán,ADM2,DISTRICT
SM283078,-103.4588,21.3664,Teul de González Ortega (pre-2005),ADM2,DISTRICT
SM284842,11.8425,7.9958,Teungo,ADM2,DISTRICT
SM275900,-86.7,14.29,Teupasenti,ADM2,DISTRICT
SM258016,-68.69,-48.87,Teushan,ETHNICITY,ETHNICITY
SM246480,-70.188,-43.1876,Teushen,LANGUAGE,LANGUAGE
SM285501,-85.8211,12.428,Teustepe,ADM2,DISTRICT
SM246481,-96.6645,17.9029,Teutila Cuicatec,LANGUAGE,LANGUAGE
SM10034,-51.7714,-29.4681,Teutonia (1996-),ADM2:ADM3,DISTRICT
SM11272,-149.4092,-17.7396,Teva I Uta,ADM2,DISTRICT
SM250978,31.32,-15.11,Teve,ETHNICITY,ETHNICITY
SM250978,33.14,-18.24,Teve,ETHNICITY,ETHNICITY
SM14199,-15.9993,18.1101,Tevragh Zeina,ADM2,DISTRICT
SM293917,72.75,57.75,Tevrizskiy rayon,ADM2,DISTRICT
SM258017,-106.72,36.03,Tewa,ETHNICITY,ETHNICITY
SM258017,-106.72,36.03,Tewa,ETHNICITY,ETHNICITY
SM258017,-106.333,35.6,Tewa,ETHNICITY,ETHNICITY
SM258017,-106.333,35.6,Tewa,ETHNICITY,ETHNICITY
SM288588,147.5333,-6.15,Tewae-Siassi,ADM2,DISTRICT
SM246486,33.8246,-18.4459,Tewe,LANGUAGE,LANGUAGE
SM280970,-11.3242,6.9702,Tewor,ADM2,DISTRICT
SM302239,-101.49,36.7479,Texas,ADM2,DISTRICT
SM301517,-91.9651,37.3173,Texas,ADM2,DISTRICT
SM3464,-99.2506,31.2504,Texas,ADM1,DISTRICT
SM281983,-99.9376,18.94,Texcaltitlán,ADM2,DISTRICT
SM281984,-99.5018,19.1339,Texcalyacac,ADM2,DISTRICT
SM282804,-98.3094,20.6252,Texcatepec,ADM2,DISTRICT
SM246488,-98.2663,20.7342,Texcatepec Otomi,LANGUAGE,LANGUAGE
SM281866,-98.8806,19.517,Texcoco,ADM2,DISTRICT
SM264753,-50.4316,-25.2781,Texeira Soares,ADM2,DISTRICT
SM286012,4.8117,53.0788,Texel,ADM2,DISTRICT
SM282805,-97.0228,18.6224,Texhuacán,ADM2,DISTRICT
SM275901,-87.0348,13.6637,Texiguat,ADM2,DISTRICT
SM282806,-94.7991,17.8198,Texistepec,ADM2,DISTRICT
SM246489,-94.8076,17.8926,Texistepec Popoluca,LANGUAGE,LANGUAGE
SM295549,-89.4833,14.1167,Texistepeque,ADM2,DISTRICT
SM246490,-97.0895,16.492,Texmelucan Zapotec,LANGUAGE,LANGUAGE
SM283025,-89.0714,21.0685,Teya,ADM2,DISTRICT
SM14200,-15.9392,18.1278,Teyarett,ADM2,DISTRICT
SM292941,40.05,56.8667,Teykovo,ADM2,DISTRICT
SM292942,40.5,56.75,Teykovskiy rayon,ADM2,DISTRICT
SM282314,-97.3582,19.8537,Teziutlán,ADM2,DISTRICT
SM246492,-97.8097,17.6508,Tezoatlán,DIALECT,LANGUAGE
SM246491,-97.9002,17.6155,Tezoatlán Mixtec,LANGUAGE,LANGUAGE
SM282808,-96.7696,18.5723,Tezonapa,ADM2,DISTRICT
SM281717,-99.281,20.1725,Tezontepec de Aldama,ADM2,DISTRICT
SM281867,-98.9083,19.5924,Tezoyuca,ADM2,DISTRICT
SM264011,-41.3374,-17.6791,Teófilo Otoni,ADM2,DISTRICT
SM246496,11.1751,9.2134,Tha,LANGUAGE,LANGUAGE
SM297274,102.5661,17.795,Tha Bo,ADM2,DISTRICT
SM297626,99.0361,9.6114,Tha Chana,ADM2,DISTRICT
SM297581,100.3935,14.7794,Tha Chang,ADM2,DISTRICT
SM297627,98.9562,9.3348,Tha Chang,ADM2,DISTRICT
SM296941,103.25,16.8833,Tha Khantho,ADM2,DISTRICT
SM297041,101.4414,17.6128,Tha Li,ADM2,DISTRICT
SM297052,101.1999,15.0479,Tha Luang,ADM2,DISTRICT
SM297784,101.977,12.7385,Tha Mai,ADM2,DISTRICT
SM296964,99.779,13.9467,Tha Maka,ADM2,DISTRICT
SM296965,99.6154,13.9095,Tha Muang,ADM2,DISTRICT
SM297552,99.9199,6.7954,Tha Phae,ADM2,DISTRICT
SM297747,100.4706,17.8587,Tha Pla,ADM2,DISTRICT
SM297381,100.7127,14.5359,Tha Rua,ADM2,DISTRICT
SM296931,99.0988,10.7686,Tha Sae,ADM2,DISTRICT
SM297221,99.8854,8.6937,Tha Sala,ADM2,DISTRICT
SM297661,98.1208,17.4748,Tha Song Yang,ADM2,DISTRICT
SM297525,101.7346,13.3951,Tha Ta Kieb,ADM2,DISTRICT
SM297200,100.4594,15.6543,Tha Tako,ADM2,DISTRICT
SM297645,103.6511,15.3094,Tha Tum,ADM2,DISTRICT
SM297102,104.4988,17.5926,Tha Uthen,ADM2,DISTRICT
SM297236,100.7612,19.1315,Tha Wang Pha,ADM2,DISTRICT
SM297053,100.5092,14.8251,Tha Wung,ADM2,DISTRICT
SM297355,99.7874,12.8174,Tha Yang,ADM2,DISTRICT
SM4268,73.1179,2.1943,Thaa Atholhu,ADM1:ADM2,DISTRICT
SM459132,114.7991,-27.1697,Thaagurda,ETHNICITY,ETHNICITY
SM3466,28.6,-29.5667,Thaba-Tseka,ADM1,DISTRICT
SM305107,28.3408,-28.2438,Thabo Mofutsanyane,ADM2,DISTRICT
SM246498,76.3642,11.2651,Thachanadan,LANGUAGE,LANGUAGE
SM17818,46.0833,25.4667,Thadiq,ADM2,DISTRICT
SM258019,94,25,Thado,ETHNICITY,ETHNICITY
SM246499,93.722,24.096,Thado Chin,LANGUAGE,LANGUAGE
SM246505,100.671,14.192,Thai,LANGUAGE,LANGUAGE
SM258020,98.79,8.81,Thai,ETHNICITY,ETHNICITY
SM258020,98.79,8.81,Thai,ETHNICITY,ETHNICITY
SM258020,98.79,8.81,Thai,ETHNICITY,ETHNICITY
SM258020,98.79,8.81,Thai,ETHNICITY,ETHNICITY
SM258020,101.61,14.88,Thai,ETHNICITY,ETHNICITY
SM258020,101.61,14.88,Thai,ETHNICITY,ETHNICITY
SM258020,101.61,14.88,Thai,ETHNICITY,ETHNICITY
SM258020,101.61,14.88,Thai,ETHNICITY,ETHNICITY
SM258020,100,15,Thai,ETHNICITY,ETHNICITY
SM258020,100,15,Thai,ETHNICITY,ETHNICITY
SM258020,100,15,Thai,ETHNICITY,ETHNICITY
SM258020,100,15,Thai,ETHNICITY,ETHNICITY
SM258020,100.85,14,Thai,ETHNICITY,ETHNICITY
SM258020,100.85,14,Thai,ETHNICITY,ETHNICITY
SM258020,100.85,14,Thai,ETHNICITY,ETHNICITY
SM258020,100.85,14,Thai,ETHNICITY,ETHNICITY
SM258020,100.5,13.8,Thai,ETHNICITY,ETHNICITY
SM258020,100.5,13.8,Thai,ETHNICITY,ETHNICITY
SM258020,100.5,13.8,Thai,ETHNICITY,ETHNICITY
SM258020,100.5,13.8,Thai,ETHNICITY,ETHNICITY
SM297766,104.4278,16.0794,Thai Charoen,ADM2,DISTRICT
SM297306,98.3129,8.49,Thai Muang,ADM2,DISTRICT
SM246502,100.445,13.7837,Thai Sign Language,LANGUAGE,LANGUAGE
SM246503,99.8651,13.2136,Thai Song,LANGUAGE,LANGUAGE
SM246508,83.6466,28.7014,Thakali,LANGUAGE,LANGUAGE
SM280872,104.8264,17.4088,Thakhek,ADM2,DISTRICT
SM262283,88.3333,25.9667,Thakurgaon,ADM2,DISTRICT
SM268962,7.6359,47.311,Thal,ADM2,DISTRICT
SM298114,8.6826,35.5923,Thala,ADM2,DISTRICT
SM280441,105.7913,13.6723,Thala Barivat,ADM2,DISTRICT
SM297402,98.334,8.0341,Thalang,ADM2,DISTRICT
SM297222,99.3772,8.4582,Tham Phannara,ADM2,DISTRICT
SM17665,-60.9479,13.9325,Thamazo,ADM2,DISTRICT
SM286515,175.6667,-37,Thames-Coromandel,ADM2,DISTRICT
SM304822,49.7361,17.6031,Thamud,ADM2,DISTRICT
SM297756,101.2594,6.0804,Than To,ADM2,DISTRICT
SM304263,103.8286,21.9142,Than Uyên,ADM2,DISTRICT
SM283469,94.5198,18.5128,Thandwe,ADM2,DISTRICT
SM277058,73.25,19.3333,Thane (2014-),ADM2,DISTRICT
SM246513,93.8396,25.3472,Thangal Naga,LANGUAGE,LANGUAGE
SM458145,140.5364,-33.1941,Thangkaali,ETHNICITY,ETHNICITY
SM246517,85.7657,28.7554,Thangmi,LANGUAGE,LANGUAGE
SM304366,105.1588,21.4782,Thanh Ba,ADM2,DISTRICT
SM304011,105.4781,10.6014,Thanh Bình,ADM2,DISTRICT
SM304327,105.2405,18.7393,Thanh Chương,ADM2,DISTRICT
SM304064,106.4338,20.8958,Thanh Hà,ADM2,DISTRICT
SM304519,105.7926,19.8023,Thanh Hóa,ADM2,DISTRICT
SM3469,105.3333,20.0667,Thanh Hóa,ADM1,DISTRICT
SM304027,108.1846,16.0673,Thanh Khê,ADM2,DISTRICT
SM304167,105.921,20.4625,Thanh Liêm,ADM2,DISTRICT
SM304065,106.2222,20.7795,Thanh Miện,ADM2,DISTRICT
SM304151,105.7845,20.8581,Thanh Oai,ADM2,DISTRICT
SM304355,105.1854,21.0722,Thanh Sơn,ADM2,DISTRICT
SM304356,105.2739,21.174,Thanh Thuỷ,ADM2,DISTRICT
SM304152,105.8358,20.9293,Thanh Trì,ADM2,DISTRICT
SM304153,105.8128,20.9955,Thanh Xuân,ADM2,DISTRICT
SM277230,79.22,10.64,Thanjavur (1997-),ADM2,DISTRICT
SM297287,100.7629,14.0285,Thanyaburi,ADM2,DISTRICT
SM246518,120.93,23.8333,Thao,LANGUAGE,LANGUAGE
SM297357,100.6008,16.1917,Thap Khlo,ADM2,DISTRICT
SM297307,98.6321,8.5377,Thap Put,ADM2,DISTRICT
SM297440,99.5716,11.5479,Thap Sakae,ADM2,DISTRICT
SM297740,99.8222,15.4906,Thap Than,ADM2,DISTRICT
SM280777,105.8104,16.0913,Thapangthong,ADM2,DISTRICT
SM280846,103.1406,18.3994,Thaphabath,ADM2,DISTRICT
SM280778,105.6,16.7,Thaphalanxay,ADM2,DISTRICT
SM258044,42.49,21.5,Thaqif,ETHNICITY,ETHNICITY
SM17819,44.6,18.1667,Thar,ADM2,DISTRICT
SM246525,38.073,-0.1322,Tharaka,LANGUAGE,LANGUAGE
SM250979,42.91,3.18,Tharaka,ETHNICITY,ETHNICITY
SM3470,38.0288,-0.1009,Tharaka-Nithi,ADM1:ADM2,DISTRICT
SM459136,114.962,-23.7565,Tharrkari,ETHNICITY,ETHNICITY
SM297103,104.7028,16.9646,That Phanom,ADM2,DISTRICT
SM280828,106.4815,15.4275,Thateng,ADM2,DISTRICT
SM280823,103.6157,19.0013,Thathom,ADM1:ADM2,DISTRICT
SM283521,97.2747,17.1325,Thaton,ADM2,DISTRICT
SM4954,67.9228,24.4903,"Thatta (ADM3,2013-)",ADM2:ADM3,DISTRICT
SM225036,104.229,17.7655,Thavung,LANGUAGE,LANGUAGE
SM459069,149.5988,-37.1671,Thawa,ETHNICITY,ETHNICITY
SM297476,103.7783,16.0278,Thawatchaburi,ADM2,DISTRICT
SM297183,100.3662,13.7764,Thawi Wattana,ADM2,DISTRICT
SM12054,44.4589,33.3904,Thawra,ADM2,DISTRICT
SM283499,95.5,18,Thayarwady,ADM2,DISTRICT
SM301761,-97.5949,40.1763,Thayer,ADM2,DISTRICT
SM283513,95.0647,19.4167,Thayetmyo,ADM2,DISTRICT
SM459384,141.9324,-12.5582,Thaynakwith,ETHNICITY,ETHNICITY
SM246531,142.034,-14.8159,Thayore,LANGUAGE,LANGUAGE
SM246530,143.324,-14.8292,Thaypan,LANGUAGE,LANGUAGE
SM246535,106.667,14.6733,The,LANGUAGE,LANGUAGE
SM261737,139.7033,-35.7184,The Coorong,ADM2,DISTRICT
SM276835,73.75,20.75,The Dangs,ADM2,DISTRICT
SM4229,-63.0212,18.2153,The Farrington,ADM1,DISTRICT
SM261469,150.961,-33.5912,The Hills,ADM2,DISTRICT
SM17666,-60.9962,13.9978,The Morne,ADM2,DISTRICT
SM277231,76.64,11.46,The Nilgiris,ADM2,DISTRICT
SM4230,-63.045,18.2116,The Quarter,ADM1,DISTRICT
SM4231,-63.0571,18.2148,The Valley,ADM1,DISTRICT
SM258054,147.67,-36.91,Theddora,ETHNICITY,ETHNICITY
SM258054,147.67,-36.91,Theddora,ETHNICITY,ETHNICITY
SM258054,147.6926,-36.3642,Theddora,ETHNICITY,ETHNICITY
SM258054,147.6926,-36.3642,Theddora,ETHNICITY,ETHNICITY
SM246534,102.4141,19.3799,Theen,LANGUAGE,LANGUAGE
SM11067,32.4558,34.9164,Theletra,ADM2,DISTRICT
SM250753,28.25,-30.86,Thembu,ETHNICITY,ETHNICITY
SM277232,77.42,9.85,Theni,ADM2,DISTRICT
SM457836,2,35.8333,Theniet El Had District,ADM2,DISTRICT
SM266114,-62.2809,-10.1016,Theobroma,ADM2,DISTRICT
SM17667,-61.0497,13.9288,Theodrine,ADM2,DISTRICT
SM297774,101.4657,15.5917,Thep Sathit,ADM2,DISTRICT
SM297590,100.9103,6.7973,Thepha,ADM2,DISTRICT
SM11068,33.3326,35.3258,Thermeia,ADM2,DISTRICT
SM275158,21.9836,39.4022,Thessaly,ADM1:ADM2,DISTRICT
SM297984,9.1016,36.5115,Thibar,ADM2,DISTRICT
SM268963,7.55,47.3833,Thierstein,ADM2,DISTRICT
SM246547,116.6801,-23.7116,Thiin,LANGUAGE,LANGUAGE
SM459140,116.6801,-23.7116,Thiin,ETHNICITY,ETHNICITY
SM5254,37.0833,-1.0833,Thika District,ADM2,DISTRICT
SM3474,89.5833,27.6,Thimphu,ADM1,DISTRICT
SM11631,-17.5993,65.3723,Thingeyjarsveit (2008-2022),ADM2,DISTRICT
SM458168,146.3257,-29.8393,Thinuntu,ETHNICITY,ETHNICITY
SM284586,166.2146,-21.6057,Thio,ADM2,DISTRICT
SM277233,80,13.25,Thiruvallur,ADM2,DISTRICT
SM276956,76.9857,8.604,Thiruvananthapuram,ADM2,DISTRICT
SM277234,79.55,10.66,Thiruvarur,ADM2,DISTRICT
SM271709,8.6183,57.004,Thisted (2007-),ADM2,DISTRICT
SM295261,-16.8697,14.73,Thiès,ADM2,DISTRICT
SM3472,-16.8667,14.8667,Thiès,ADM1,DISTRICT
SM304520,105.6814,19.8971,Thiệu Hóa,ADM2,DISTRICT
SM258059,30.56,-23.3,Thlalerwa,ETHNICITY,ETHNICITY
SM280526,103.4961,11.7503,Thma Bang,ADM2,DISTRICT
SM280356,103,13.95,Thma Puok,ADM2,DISTRICT
SM246553,105.363,19.3563,Tho,LANGUAGE,LANGUAGE
SM297012,99.2884,17.5813,Thoen,ADM2,DISTRICT
SM296902,100.201,19.6765,Thoeng,ADM2,DISTRICT
SM285619,4.1337,51.5834,Tholen,ADM2,DISTRICT
SM301762,-100.5559,41.9135,Thomas,ADM2,DISTRICT
SM300985,-101.0555,39.351,Thomas,ADM2,DISTRICT
SM300421,-83.9193,30.8637,Thomas,ADM2,DISTRICT
SM17668,-60.9447,13.919,Thomazo/Tournesse,ADM2,DISTRICT
SM246552,-120.193,50.1668,Thompson,LANGUAGE,LANGUAGE
SM327755,-98.2514,49.3347,Thompson,ADM2:ADM3,DISTRICT
SM12782,-77.3719,18.0897,Thompson Town,ADM2,DISTRICT
SM268763,-120.5025,50.9998,Thompson-Nicola,ADM2,DISTRICT
SM297184,100.4837,13.7187,Thon Buri,ADM2,DISTRICT
SM296966,98.6923,14.8328,Thong Pha Phum,ADM2,DISTRICT
SM297748,100.3966,17.4993,Thong Saen Khan,ADM2,DISTRICT
SM280818,101.0248,17.6754,Thongmyxay,ADM2,DISTRICT
SM277235,77.963,8.8375,Thoothukkudi,ADM2,DISTRICT
SM246555,104.9,24.15,Thopho,LANGUAGE,LANGUAGE
SM12783,-77.7225,18.1956,Thornton,ADM2,DISTRICT
SM324031,-114.0459,53.2272,Thorsby,ADM2:ADM3,DISTRICT
SM234209,105.1,24.2,Thou-Kathu,LANGUAGE,LANGUAGE
SM11694,94.01,24.6221,Thoubal (2016-),ADM2,DISTRICT
SM280807,102.6964,18.3677,Thoulakhom,ADM2,DISTRICT
SM303913,105.2668,10.3195,Thoại Sơn,ADM2,DISTRICT
SM280491,104.3798,11.7156,Thpong,ADM2,DISTRICT
SM246558,24.712,42.1155,Thracian,LANGUAGE,LANGUAGE
SM14114,-9.3333,6.25,Three B,ADM2,DISTRICT
SM323889,-113.2646,51.7013,Three Hills,ADM2:ADM3,DISTRICT
SM12784,-78.1428,18.2571,Three Mile River,ADM2,DISTRICT
SM12785,-76.8245,18.0313,Three Oaks/ Glendale,ADM2,DISTRICT
SM261317,115.6316,-29.5139,Three Springs,ADM2,DISTRICT
SM268382,91.5982,27.095,Thrimshing,ADM2,DISTRICT
SM11069,32.5211,34.9113,Thrinia,ADM2,DISTRICT
SM276957,76.2204,10.5202,Thrissur,ADM2,DISTRICT
SM302778,-99.2124,33.1775,Throckmorton,ADM2,DISTRICT
SM246565,104.251,22.7528,Thu Lao,LANGUAGE,LANGUAGE
SM246561,87.6189,27.7678,Thudam,LANGUAGE,LANGUAGE
SM304783,43.8252,15.601,Thula,ADM2,DISTRICT
SM246564,87.6063,27.5389,Thulung,LANGUAGE,LANGUAGE
SM269046,7.625,46.7629,Thun,ADM2,DISTRICT
SM268885,-88.5,49.5001,Thunder Bay,ADM2,DISTRICT
SM297237,100.9194,19.4634,Thung Chang,ADM2,DISTRICT
SM297723,103.2209,17.4906,Thung Fon,ADM2,DISTRICT
SM297036,99.05,17.9833,Thung Hua Chang,ADM2,DISTRICT
SM297607,99.5527,17.3708,Thung Saliam,ADM2,DISTRICT
SM297703,104.9173,14.7394,Thung Si Udom,ADM2,DISTRICT
SM297223,99.6691,8.1176,Thung Song,ADM2,DISTRICT
SM296932,99.0534,10.0922,Thung Tako,ADM2,DISTRICT
SM297553,99.7659,7.0492,Thung Wa,ADM2,DISTRICT
SM297224,99.372,8.2871,Thung Yai,ADM2,DISTRICT
SM297289,101.4465,6.6416,Thung Yang Daeng,ADM2,DISTRICT
SM297185,100.4964,13.6339,Thungkru,ADM2,DISTRICT
SM246568,150.721,-34.634,Thurawal,LANGUAGE,LANGUAGE
SM3475,9.0769,47.5562,Thurgau,ADM1,DISTRICT
SM246569,26.4858,8.7257,Thuri,LANGUAGE,LANGUAGE
SM258068,29.68,6.37,Thuri,ETHNICITY,ETHNICITY
SM258070,149.55,-27.74,Thuribura,ETHNICITY,ETHNICITY
SM274588,0.4167,51.5,Thurrock,ADM1:ADM2,DISTRICT
SM303096,-122.8321,46.9295,Thurston,ADM2,DISTRICT
SM301763,-96.5441,42.1582,Thurston,ADM2,DISTRICT
SM304621,106.7112,10.9398,Thuận An,ADM2,DISTRICT
SM304352,109.0584,11.7194,Thuận Bắc,ADM2,DISTRICT
SM304460,103.6441,21.4232,Thuận Châu,ADM2,DISTRICT
SM304353,108.8504,11.4358,Thuận Nam,ADM2,DISTRICT
SM304435,106.0752,21.0409,Thuận Thành,ADM2,DISTRICT
SM304078,106.6783,20.9493,Thuỷ Nguyên,ADM2,DISTRICT
SM18012,10.6785,34.6892,Thyna,ADM2,DISTRICT
SM3477,35.1506,-16.1058,Thyolo,ADM1:ADM2,DISTRICT
SM304307,105.8387,22.1413,Thành Phố Bắc Kạn,ADM2,DISTRICT
SM304382,106.5771,17.4468,Thành Phố Đồng Hới,ADM2,DISTRICT
SM304493,106.3362,20.4467,Thái Bình,ADM2,DISTRICT
SM3467,106.3667,20.5,Thái Bình,ADM1,DISTRICT
SM304326,105.4556,19.2907,Thái Hoà,ADM2,DISTRICT
SM304504,105.8089,21.5606,Thái Nguyên,ADM2,DISTRICT
SM3468,105.8333,21.6667,Thái Nguyên,ADM1,DISTRICT
SM304494,106.5169,20.5392,Thái Thụy,ADM2,DISTRICT
SM304012,105.8188,10.544,Tháp Mười,ADM2,DISTRICT
SM303964,105.9585,22.8074,Thông Nông,ADM2,DISTRICT
SM3476,11,50.9167,Thüringen,ADM1,DISTRICT
SM304392,108.3923,15.6872,Thăng Bình,ADM2,DISTRICT
SM304155,105.8728,20.8454,Thường Tín,ADM2,DISTRICT
SM304521,105.2411,19.9368,Thường Xuân,ADM2,DISTRICT
SM303963,106.3424,22.4765,Thạch An,ADM2,DISTRICT
SM304171,105.8112,18.3509,Thạch Hà,ADM2,DISTRICT
SM304516,105.6667,20.1667,Thạch Thành,ADM2,DISTRICT
SM304150,105.5693,21.0305,Thạch Thất,ADM2,DISTRICT
SM304289,106.1667,10.6667,Thạnh Hóa,ADM2,DISTRICT
SM304561,106.5432,9.9299,Thạnh Phú,ADM2,DISTRICT
SM304458,105.712,9.4694,Thạnh Trị,ADM2,DISTRICT
SM303981,108.2837,12.8446,Thị Xã Buôn Hồ,ADM2,DISTRICT
SM304044,103.1233,22.0337,Thị Xã Mường Lay,ADM2,DISTRICT
SM304518,105.4828,19.9311,Thọ Xuân,ADM2,DISTRICT
SM304007,107.1591,10.9776,Thống Nhất,ADM2,DISTRICT
SM303950,105.5317,10.2351,Thốt Nốt,ADM2,DISTRICT
SM303959,105.1602,9.3579,Thới Bình,ADM2,DISTRICT
SM303951,105.5456,10.0266,Thới Lai,ADM2,DISTRICT
SM304620,106.6579,11.0059,Thủ Dầu Một,ADM2,DISTRICT
SM304290,106.3458,10.6564,Thủ Thừa,ADM2,DISTRICT
SM304113,106.7473,10.8514,Thủ Đức,ADM2,DISTRICT
SM3465,107.5833,16.3333,Thừa Thiên Huế,ADM1,DISTRICT
SM17669,-61.0246,13.8374,Ti Boug,ADM2,DISTRICT
SM17670,-61.0023,13.9707,Ti Colon,ADM2,DISTRICT
SM17671,-61.0641,13.8339,Ti Delcer,ADM2,DISTRICT
SM17672,-60.9377,13.8301,Ti Riviere,ADM2,DISTRICT
SM17673,-60.9711,13.9949,Ti Rocher (Castries),ADM2,DISTRICT
SM17674,-60.9286,13.8173,Ti Rocher (Micoud),ADM2,DISTRICT
SM291471,24.6388,43.8642,Tia Mare,ADM2,DISTRICT
SM299754,23.5719,48.0112,Tiachivs'kyi,ADM2,DISTRICT
SM246571,-4.6235,5.2802,Tiagbamrin Aizi,LANGUAGE,LANGUAGE
SM258071,146.36,-7.26,Tiak,ETHNICITY,ETHNICITY
SM246572,166.842,-15.2334,Tiale,LANGUAGE,LANGUAGE
SM274823,45,42.1,Tianeti,ADM2,DISTRICT
SM246575,150.935,-2.9551,Tiang,LANGUAGE,LANGUAGE
SM282315,-98.4521,18.9707,Tianguismanalco,ADM2,DISTRICT
SM281868,-99.4612,19.1614,Tianguistenco,ADM2,DISTRICT
SM281718,-98.5525,20.7833,Tianguistengo,ADM2,DISTRICT
SM267863,-40.9901,-3.673,Tianguá,ADM2,DISTRICT
SM3479,117.3333,39.3333,Tianjin,ADM1,DISTRICT
SM246576,117.2154,39.1861,Tianjin Mandarin,DIALECT,LANGUAGE
SM269405,105.807,34.6522,Tianshui,ADM2,DISTRICT
SM3480,1.5833,34.9167,Tiaret,ADM1,DISTRICT
SM457754,1.5,34.8333,Tiaret District,ADM2,DISTRICT
SM258073,-69.07,-13.05,Tiatinagua,ETHNICITY,ETHNICITY
SM270067,-74.5164,4.3061,Tibacuy,ADM2,DISTRICT
SM264755,-50.5186,-24.6581,Tibaji,ADM2,DISTRICT
SM290879,27.3261,46.9868,Tibana,ADM2,DISTRICT
SM290880,27.3355,46.9176,Tibanesti,ADM2,DISTRICT
SM270725,-73.4278,5.2846,Tibaná,ADM2,DISTRICT
SM270726,-73.0109,5.7472,Tibasosa,ADM2,DISTRICT
SM265503,-37.3135,-4.9115,Tibau,ADM2,DISTRICT
SM265502,-35.0905,-6.1864,Tibau do Sul,ADM2,DISTRICT
SM246583,11.3103,5.3054,Tibea,LANGUAGE,LANGUAGE
SM16241,-66.6369,18.0867,Tibes Barrio,ADM2,DISTRICT
SM3481,16.6667,20.3333,Tibesti,ADM1,DISTRICT
SM10343,17.1662,21.2723,Tibesti-Est,ADM2,DISTRICT
SM10344,16.5856,20.4765,Tibesti-Ouest,ADM2,DISTRICT
SM246582,90.1872,28.3681,Tibetan,LANGUAGE,LANGUAGE
SM258074,90.46,37.6,Tibetan,ETHNICITY,ETHNICITY
SM258074,90.46,37.6,Tibetan,ETHNICITY,ETHNICITY
SM258074,91,30,Tibetan,ETHNICITY,ETHNICITY
SM258074,91,30,Tibetan,ETHNICITY,ETHNICITY
SM246586,91.158,29.6518,Tibetan Sign Language,LANGUAGE,LANGUAGE
SM270068,-73.5137,5.0852,Tibirita,ADM2,DISTRICT
SM296602,14.2266,58.4492,Tibro,ADM2,DISTRICT
SM291376,26.5735,47.1186,Tibucani,ADM2,DISTRICT
SM270968,-84.0833,9.9667,Tibás,ADM2,DISTRICT
SM270391,-72.8127,8.6954,Tibú,ADM2,DISTRICT
SM292697,27.8906,45.1482,Tichilesti,ADM2,DISTRICT
SM283983,-9.7993,18.6474,Tichitt,ADM2,DISTRICT
SM246591,83.4672,28.9506,Tichurong,LANGUAGE,LANGUAGE
SM3482,8.7907,46.2846,Ticino,ADM1,DISTRICT
SM290667,23.3892,44.8834,Ticleni,ADM2,DISTRICT
SM285439,-86.2053,12.0205,Ticuantepe,ADM2,DISTRICT
SM283026,-89.5271,20.3802,Ticul,ADM2,DISTRICT
SM246594,-69.8723,-3.6629,Ticuna,LANGUAGE,LANGUAGE
SM292657,25.0935,45.9282,Ticusu Vechi,ADM2,DISTRICT
SM290055,21.656,45.159,Ticvaniu Mare,ADM2,DISTRICT
SM296603,13.9434,58.1484,Tidaholm,ADM2,DISTRICT
SM246595,-97.3333,17.4142,Tidaá Mixtec,LANGUAGE,LANGUAGE
SM246596,1.8622,27.019,Tidikelt-Tuat Tamazight,LANGUAGE,LANGUAGE
SM283984,-11.5246,18.4375,Tidjikja,ADM2,DISTRICT
SM258075,116.94,1.91,Tidog,ETHNICITY,ETHNICITY
SM246598,127.746,0.0246,Tidore,LANGUAGE,LANGUAGE
SM276461,127.5698,0.6096,Tidore Kepulauan,ADM2,DISTRICT
SM258076,127.1,0.39,Tidorese,ETHNICITY,ETHNICITY
SM246600,117.5514,3.938,Tidung,FAMILY,LANGUAGE
SM246602,-4.5262,10.7184,Tiefo-Daramandugu,LANGUAGE,LANGUAGE
SM246604,-4.45,10.85,Tiefo-Nyafogo-Numudara,LANGUAGE,LANGUAGE
SM285809,5.4043,51.8846,Tiel,ADM2,DISTRICT
SM10364,124.3236,42.6258,Tieling Shi,ADM2,DISTRICT
SM246605,-4.2569,15.4705,Tiemacèwè Bozo,LANGUAGE,LANGUAGE
SM246606,16.4765,-2.8469,Tiene,LANGUAGE,LANGUAGE
SM14115,-8.1667,5.1667,Tienpo,ADM2,DISTRICT
SM296501,17.6363,60.3721,Tierp,ADM2,DISTRICT
SM281568,-100.1572,21.0307,Tierra Blanca,ADM2,DISTRICT
SM14880,-96.2994,18.5453,Tierra Blanca (1988-),ADM2,DISTRICT
SM282513,-100.5024,21.6597,Tierra Nueva,ADM2,DISTRICT
SM269073,-69.4517,-53.6608,Tierra del Fuego,ADM2,DISTRICT
SM3483,-67,-54.5,Tierra del Fuego,ADM1,DISTRICT
SM269948,-76.2131,7.8786,Tierralta,ADM2,DISTRICT
SM16242,-66.5148,18.4634,Tierras Nuevas Poniente Barrio,ADM2,DISTRICT
SM16243,-66.4698,18.4588,Tierras Nuevas Saliente Barrio,ADM2,DISTRICT
SM267000,-47.7143,-23.042,Tietê,ADM2,DISTRICT
SM246609,141.293,-5.039,Tifal,LANGUAGE,LANGUAGE
SM292382,27.1039,45.8533,Tifesti,ADM2,DISTRICT
SM300422,-83.5266,31.4574,Tift,ADM2,DISTRICT
SM246612,150.893,-2.7095,Tigak,LANGUAGE,LANGUAGE
SM461508,150.8333,-2.567,Tigak,ETHNICITY,ETHNICITY
SM290881,27.4559,47.3487,Tiganasi,ADM2,DISTRICT
SM291941,25.378,43.9014,Tiganesti,ADM2,DISTRICT
SM457756,0.25,35.4167,Tighenif District,ADM2,DISTRICT
SM293105,158.6869,57.7605,Tigil'skiy rayon,ADM2,DISTRICT
SM246615,10.7085,6.984,Tigon Mbembe,LANGUAGE,LANGUAGE
SM250754,11,7,Tigon Mbembe,ETHNICITY,ETHNICITY
SM250754,11,7,Tigon Mbembe,ETHNICITY,ETHNICITY
SM250754,11,17,Tigon Mbembe,ETHNICITY,ETHNICITY
SM250754,11,17,Tigon Mbembe,ETHNICITY,ETHNICITY
SM3484,38.8333,14.1667,Tigray,ADM1,DISTRICT
SM246620,38.2233,17.3494,Tigre,LANGUAGE,LANGUAGE
SM260716,-58.5828,-34.4206,Tigre,ADM2,DISTRICT
SM250755,39.08,14.99,Tigre,ETHNICITY,ETHNICITY
SM250755,39.08,14.99,Tigre,ETHNICITY,ETHNICITY
SM250755,39.08,14.99,Tigre,ETHNICITY,ETHNICITY
SM250755,39,16,Tigre,ETHNICITY,ETHNICITY
SM250755,39,16,Tigre,ETHNICITY,ETHNICITY
SM250755,39,16,Tigre,ETHNICITY,ETHNICITY
SM250755,39,17,Tigre,ETHNICITY,ETHNICITY
SM250755,39,17,Tigre,ETHNICITY,ETHNICITY
SM250755,39,17,Tigre,ETHNICITY,ETHNICITY
SM266325,-53.1613,-26.6657,Tigrinhos,ADM2,DISTRICT
SM246621,38.9266,15.3359,Tigrinya,LANGUAGE,LANGUAGE
SM250756,39,14,Tigrinya,ETHNICITY,ETHNICITY
SM291299,24.5509,45.1559,Tigveni,ADM2,DISTRICT
SM292539,24.8791,47.2368,Tiha Bargaului,ADM2,DISTRICT
SM282810,-97.526,20.6549,Tihuatlán,ADM2,DISTRICT
SM246628,122.963,-10.8425,Tii,LANGUAGE,LANGUAGE
SM246631,-97.4578,17.1539,Tijaltepec Mixtec,LANGUAGE,LANGUAGE
SM16244,-66.4816,18.0499,Tijeras Barrio,ADM2,DISTRICT
SM296105,-55.5199,5.7899,Tijgerkreek,ADM2,DISTRICT
SM14881,-116.9721,32.5022,Tijuana (1995-),ADM2,DISTRICT
SM266326,-48.7162,-27.247,Tijucas,ADM2,DISTRICT
SM264756,-49.1178,-25.8985,Tijucas do Sul,ADM2,DISTRICT
SM277009,79,24.75,Tikamgarh,ADM2,DISTRICT
SM246634,11.6104,5.87,Tikar,LANGUAGE,LANGUAGE
SM250757,12.55,5.07,Tikar,ETHNICITY,ETHNICITY
SM250757,12.55,5.07,Tikar,ETHNICITY,ETHNICITY
SM250757,12.55,5.07,Tikar,ETHNICITY,ETHNICITY
SM250757,11.667,5.833,Tikar,ETHNICITY,ETHNICITY
SM250757,11.667,5.833,Tikar,ETHNICITY,ETHNICITY
SM250757,11.667,5.833,Tikar,ETHNICITY,ETHNICITY
SM250757,12,6,Tikar,ETHNICITY,ETHNICITY
SM250757,12,6,Tikar,ETHNICITY,ETHNICITY
SM250757,12,6,Tikar,ETHNICITY,ETHNICITY
SM258077,-161.58,68.33,Tikeramiut,ETHNICITY,ETHNICITY
SM258077,-161.58,68.33,Tikeramiut,ETHNICITY,ETHNICITY
SM258077,-166.21,68.41,Tikeramiut,ETHNICITY,ETHNICITY
SM258077,-166.21,68.41,Tikeramiut,ETHNICITY,ETHNICITY
SM246636,96.0244,27.3818,Tikhakic,LANGUAGE,LANGUAGE
SM293399,40.2755,45.7385,Tikhoretskiy rayon,ADM2,DISTRICT
SM17108,33.3333,59.75,Tikhvinskiy Rayon,ADM2,DISTRICT
SM246638,168.822,-12.2766,Tikopia,LANGUAGE,LANGUAGE
SM295473,168.8331,-12.2949,Tikopia,ADM2,DISTRICT
SM258078,168.83,-12.3,Tikopia,ETHNICITY,ETHNICITY
SM258078,168.83,-12.3,Tikopia,ETHNICITY,ETHNICITY
SM258078,168.83,-12.3,Tikopia,ETHNICITY,ETHNICITY
SM258078,168.83,-12.29,Tikopia,ETHNICITY,ETHNICITY
SM258078,168.83,-12.29,Tikopia,ETHNICITY,ETHNICITY
SM258078,168.83,-12.29,Tikopia,ETHNICITY,ETHNICITY
SM258078,168.8241,-12.2967,Tikopia,ETHNICITY,ETHNICITY
SM258078,168.8241,-12.2967,Tikopia,ETHNICITY,ETHNICITY
SM258078,168.8241,-12.2967,Tikopia,ETHNICITY,ETHNICITY
SM305809,43.65,34.75,Tikrit,ADM2,DISTRICT
SM258080,-69.31,-4.78,Tikuna,ETHNICITY,ETHNICITY
SM258080,-69.31,-4.78,Tikuna,ETHNICITY,ETHNICITY
SM258080,-69.31,-4.78,Tikuna,ETHNICITY,ETHNICITY
SM258080,-69.9585,-3.5,Tikuna,ETHNICITY,ETHNICITY
SM258080,-69.9585,-3.5,Tikuna,ETHNICITY,ETHNICITY
SM258080,-69.9585,-3.5,Tikuna,ETHNICITY,ETHNICITY
SM258080,-70,-3,Tikuna,ETHNICITY,ETHNICITY
SM258080,-70,-3,Tikuna,ETHNICITY,ETHNICITY
SM258080,-70,-3,Tikuna,ETHNICITY,ETHNICITY
SM283207,-92.4818,17.3507,Tila,ADM2,DISTRICT
SM282316,-98.5458,18.605,Tilapa,ADM2,DISTRICT
SM246640,-99.5074,19.4403,Tilapa Otomi,LANGUAGE,LANGUAGE
SM270923,-84.9751,10.4588,Tilarán,ADM2,DISTRICT
SM258081,147.23,-25.36,Tilbabura,ETHNICITY,ETHNICITY
SM285954,5.0656,51.5779,Tilburg,ADM2,DISTRICT
SM260828,-65.3333,-23.5,Tilcara,ADM2,DISTRICT
SM292480,22.2071,47.0656,Tileagd,ADM2,DISTRICT
SM291761,23.8141,45.8013,Tilisca,ADM2,DISTRICT
SM305794,43.0564,36.5914,Tilkef,ADM2,DISTRICT
SM305794,43.0564,36.5914,Tilkef,ADM2,DISTRICT
SM305794,43.0621,36.5921,Tilkef,ADM2,DISTRICT
SM305794,43.0621,36.5921,Tilkef,ADM2,DISTRICT
SM15257,1.443,14.516,Tillaberi Department,ADM2,DISTRICT
SM3485,2.0833,14.3333,Tillabéry,ADM1,DISTRICT
SM246642,-122.769,44.0924,Tillamook,LANGUAGE,LANGUAGE
SM302270,-123.7588,45.4564,Tillamook,ADM2,DISTRICT
SM258082,-123.73,45.77,Tillamook,ETHNICITY,ETHNICITY
SM258082,-123.73,45.77,Tillamook,ETHNICITY,ETHNICITY
SM258082,-123.73,45.77,Tillamook,ETHNICITY,ETHNICITY
SM258082,-123.72,44.75,Tillamook,ETHNICITY,ETHNICITY
SM258082,-123.72,44.75,Tillamook,ETHNICITY,ETHNICITY
SM258082,-123.72,44.75,Tillamook,ETHNICITY,ETHNICITY
SM258082,-123.82,45.4,Tillamook,ETHNICITY,ETHNICITY
SM258082,-123.82,45.4,Tillamook,ETHNICITY,ETHNICITY
SM258082,-123.82,45.4,Tillamook,ETHNICITY,ETHNICITY
SM15258,4.5575,15.9002,Tillia,ADM2,DISTRICT
SM302240,-98.9242,34.3729,Tillman,ADM2,DISTRICT
SM297840,125.1089,-9.3418,Tilomar,ADM2,DISTRICT
SM246643,-96.6321,16.8529,Tilquiapan Zapotec,LANGUAGE,LANGUAGE
SM246645,86.4694,27.2203,Tilung,LANGUAGE,LANGUAGE
SM13870,27.3675,56.8975,Tilzas pagasts,ADM2,DISTRICT
SM246646,29.1632,11.7176,Tima,LANGUAGE,LANGUAGE
SM270108,-75.9734,1.912,Timaná,ADM2,DISTRICT
SM286543,171.2167,-44.4,Timaru,ADM2,DISTRICT
SM293400,39.0041,45.5549,Timashevskiy rayon,ADM2,DISTRICT
SM264921,-35.3563,-7.5319,Timbaúba,ADM2,DISTRICT
SM265504,-37.2328,-6.4826,Timbaúba dos Batistas,ADM2,DISTRICT
SM246647,147.057,-6.0688,Timbe,LANGUAGE,LANGUAGE
SM269879,-77.6335,2.7646,Timbiquí,ADM2,DISTRICT
SM262919,-43.8257,-4.1969,Timbiras,ADM2,DISTRICT
SM258088,-60.12,-32.45,Timbu,ETHNICITY,ETHNICITY
SM267001,-49.6122,-23.1927,Timburi,ADM2,DISTRICT
SM266327,-49.8951,-28.7909,Timbé do Sul,ADM2,DISTRICT
SM284016,-8.1824,16.1019,Timbédra,ADM2,DISTRICT
SM269878,-76.7106,2.3696,Timbío,ADM2,DISTRICT
SM266330,-49.2873,-26.8206,Timbó,ADM2,DISTRICT
SM266329,-50.6355,-26.6246,Timbó Grande,ADM2,DISTRICT
SM286143,5.653,58.7364,Time,ADM2,DISTRICT
SM11070,32.5085,34.7252,Timi,ADM2,DISTRICT
SM281869,-99.7255,19.8802,Timilpan,ADM2,DISTRICT
SM457760,0.5,29.25,Timimoun District,ADM2,DISTRICT
SM279971,67,53.8333,Timiryazevskiy,ADM2,DISTRICT
SM291377,26.5198,47.2294,Timisesti,ADM2,DISTRICT
SM268886,-80.333,47.7502,Timiskaming,ADM2,DISTRICT
SM292027,21.2297,45.7564,Timisoara,ADM2,DISTRICT
SM3487,21.5,45.6667,Timiș,ADM1,DISTRICT
SM246655,-12.3331,8.9029,Timne,LANGUAGE,LANGUAGE
SM262920,-42.8805,-5.1978,Timon,ADM2,DISTRICT
SM246657,125.59,-8.66,Timor Pidgin,LANGUAGE,LANGUAGE
SM276504,124.4,-9.8333,Timor Tengah Selatan,ADM2,DISTRICT
SM276505,124.519,-9.3314,Timor Tengah Utara,ADM2,DISTRICT
SM258090,-71.11,9,Timote,ETHNICITY,ETHNICITY
SM246656,-70.9753,8.8482,Timote-Cuica,LANGUAGE,LANGUAGE
SM258091,-110.38,39.4,Timpananunts,ETHNICITY,ETHNICITY
SM258091,-110.38,39.4,Timpananunts,ETHNICITY,ETHNICITY
SM258091,-111.81,40.22,Timpananunts,ETHNICITY,ETHNICITY
SM258091,-111.81,40.22,Timpananunts,ETHNICITY,ETHNICITY
SM296540,17.3785,62.616,Timrå,ADM2,DISTRICT
SM293518,37.1667,51.6667,Timskiy rayon,ADM2,DISTRICT
SM246664,-82.3132,30.3027,Timucua,LANGUAGE,LANGUAGE
SM258092,-81.58,28.51,Timucua,ETHNICITY,ETHNICITY
SM258092,-81.58,28.51,Timucua,ETHNICITY,ETHNICITY
SM258092,-82,27,Timucua,ETHNICITY,ETHNICITY
SM258092,-82,27,Timucua,ETHNICITY,ETHNICITY
SM283028,-89.5274,20.8318,Timucuy,ADM2,DISTRICT
SM246666,115.826,5.1796,Timugon Murut,LANGUAGE,LANGUAGE
SM284325,100.2922,5.4041,Timur Laut,ADM2,DISTRICT
SM264012,-42.5992,-19.555,Timóteo,ADM2,DISTRICT
SM283356,3.1052,18.4583,Tin-Essako,ADM2,DISTRICT
SM303887,-68.3333,9.6667,Tinaco,ADM2,DISTRICT
SM246670,77.9912,32.2852,Tinani,LANGUAGE,LANGUAGE
SM292481,21.9364,46.7631,Tinca,ADM2,DISTRICT
SM246678,45.9227,42.3316,Tindi,LANGUAGE,LANGUAGE
SM3488,-5.8333,27.4167,Tindouf,ADM1,DISTRICT
SM457834,4.75,36.75,Tinedbar Commune,ADM2,DISTRICT
SM258094,121.24,17.6,Tingaian,ETHNICITY,ETHNICITY
SM246684,31.1244,12.1797,Tingal,LANGUAGE,LANGUAGE
SM282088,-101.8621,19.4904,Tingambato,ADM2,DISTRICT
SM258095,-34.77,67.27,Tingamitmiut,ETHNICITY,ETHNICITY
SM4438,-5.53,31.5197,Tinghir (2009-),ADM2,DISTRICT
SM296671,14.9667,56.5333,Tingsryd,ADM2,DISTRICT
SM246680,-37.538,-11.5816,Tingui-Boto,LANGUAGE,LANGUAGE
SM282089,-102.4977,19.7793,Tinguindín,ADM2,DISTRICT
SM286437,8.2067,62.9119,Tingvoll,ADM2,DISTRICT
SM3489,145.616,15.029,Tinian,ADM1,DISTRICT
SM246685,-73.768,2.716,Tinigua,LANGUAGE,LANGUAGE
SM298059,9.7409,37.1231,Tinja,ADM2,DISTRICT
SM270727,-73.7105,5.5664,Tinjacá,ADM2,DISTRICT
SM305679,13.8378,45.2177,Tinjan,ADM2,DISTRICT
SM286212,9.1353,59.8349,Tinn,ADM2,DISTRICT
SM246690,120.994,16.7899,Tinoc Kallahan,DIALECT,LANGUAGE
SM261000,-68,-27.5,Tinogasta,ADM2,DISTRICT
SM291583,26.0098,44.817,Tinosu,ADM2,DISTRICT
SM246691,154.972,-5.5836,Tinputz,LANGUAGE,LANGUAGE
SM277394,95.6,27.6,Tinsukia,ADM2,DISTRICT
SM284020,-10.3184,15.9813,Tintane,ADM2,DISTRICT
SM289924,26.8579,45.0728,Tintesti,ADM2,DISTRICT
SM13871,24.588,56.8624,Tinuzu pagasts,ADM2,DISTRICT
SM246672,120.095,15.5797,Tinà Sambal,LANGUAGE,LANGUAGE
SM283029,-88.5163,20.7,Tinúm,ADM2,DISTRICT
SM10035,-52.5974,-28.5818,Tio Hugo,ADM2,DISTRICT
SM302339,-77.2543,41.7722,Tioga,ADM2,DISTRICT
SM301917,-76.3063,42.1703,Tioga,ADM2,DISTRICT
SM258097,-80.83,43.83,Tiononati,ETHNICITY,ETHNICITY
SM270728,-72.7219,6.3969,Tipacoque,ADM2,DISTRICT
SM235444,-116.604,32.331,Tipai,LANGUAGE,LANGUAGE
SM258098,-115.55,32.29,Tipai,ETHNICITY,ETHNICITY
SM258098,-115.55,32.29,Tipai,ETHNICITY,ETHNICITY
SM258098,-115.55,32.29,Tipai,ETHNICITY,ETHNICITY
SM258098,-115.55,32.29,Tipai,ETHNICITY,ETHNICITY
SM258098,-116,32,Tipai,ETHNICITY,ETHNICITY
SM258098,-116,32,Tipai,ETHNICITY,ETHNICITY
SM258098,-116,32,Tipai,ETHNICITY,ETHNICITY
SM258098,-116,32,Tipai,ETHNICITY,ETHNICITY
SM258098,-116.49,32.44,Tipai,ETHNICITY,ETHNICITY
SM258098,-116.49,32.44,Tipai,ETHNICITY,ETHNICITY
SM258098,-116.49,32.44,Tipai,ETHNICITY,ETHNICITY
SM258098,-116.49,32.44,Tipai,ETHNICITY,ETHNICITY
SM258098,-116,32.7,Tipai,ETHNICITY,ETHNICITY
SM258098,-116,32.7,Tipai,ETHNICITY,ETHNICITY
SM258098,-116,32.7,Tipai,ETHNICITY,ETHNICITY
SM258098,-116,32.7,Tipai,ETHNICITY,ETHNICITY
SM3490,2.4167,36.5833,Tipaza,ADM1,DISTRICT
SM285440,-86.0117,12.3602,Tipitapa,ADM2,DISTRICT
SM301493,-88.9089,34.7684,Tippah,ADM2,DISTRICT
SM300769,-86.8941,40.3886,Tippecanoe,ADM2,DISTRICT
SM246700,92.0747,23.5864,Tippera,LANGUAGE,LANGUAGE
SM3491,-7.8333,52.6667,Tipperary,ADM1:ADM2,DISTRICT
SM302626,-89.7592,35.4969,Tipton,ADM2,DISTRICT
SM300770,-86.0519,40.3114,Tipton,ADM2,DISTRICT
SM282090,-100.8255,19.0809,Tiquicheo de Nicolás Romero,ADM2,DISTRICT
SM275428,-91.3681,14.2836,Tiquisate,ADM2,DISTRICT
SM10480,-74.3063,8.478,Tiquisio,ADM2,DISTRICT
SM246702,30.8092,11.0963,Tira,LANGUAGE,LANGUAGE
SM454540,30,11,Tira,ETHNICITY,ETHNICITY
SM264014,-44.1607,-21.1185,Tiradentes,ADM2,DISTRICT
SM265963,-54.113,-27.3818,Tiradentes do Sul,ADM2,DISTRICT
SM246701,69.5901,34.1792,Tirahi,LANGUAGE,LANGUAGE
SM277697,52.45,32.2667,Tiran and Karvan,ADM2,DISTRICT
SM246706,-3.7,14.76,Tiranige Diga Dogon,LANGUAGE,LANGUAGE
SM3492,19.7833,41.2667,Tiranë,ADM1:ADMX,DISTRICT
SM260474,19.9167,41.3333,Tiranës,ADM2,DISTRICT
SM277201,95.5179,27.0192,Tirap,ADM2,DISTRICT
SM9859,-65.9167,-17.3333,Tiraque Province,ADM2,DISTRICT
SM236965,167.288,-15.9914,Tirax,LANGUAGE,LANGUAGE
SM298548,27.7273,38.0658,Tire,ADM2,DISTRICT
SM291695,22.4399,47.5855,Tiream,ADM2,DISTRICT
SM298435,38.8498,41.0063,Tirebolu,ADM2,DISTRICT
SM246712,165.792,-21.8147,Tiri-Mea,LANGUAGE,LANGUAGE
SM258100,35.42,0.09,Tiriki,ETHNICITY,ETHNICITY
SM258100,35.42,0.09,Tiriki,ETHNICITY,ETHNICITY
SM258100,35,0,Tiriki,ETHNICITY,ETHNICITY
SM258100,35,0,Tiriki,ETHNICITY,ETHNICITY
SM260225,65.917,32.6822,Tirin Kot,ADM2,DISTRICT
SM3493,-9.5,24.25,Tiris Zemmour,ADM1,DISTRICT
SM245704,35.0823,6.0321,Tirma-Chai,LANGUAGE,LANGUAGE
SM3494,11.3333,47.25,Tirol,ADM1,DISTRICT
SM264015,-45.8405,-18.8573,Tiros,ADM2,DISTRICT
SM295395,159.5423,-8.1891,Tirotonga,ADM2,DISTRICT
SM277236,78.65,10.97,Tiruchirappalli (1995-),ADM2,DISTRICT
SM277237,77.5741,8.7927,Tirunelveli,ADM2,DISTRICT
SM277238,77.4054,10.8381,Tiruppur,ADM2,DISTRICT
SM246717,124.127,6.7732,Tiruray,LANGUAGE,LANGUAGE
SM258103,123.84,6.14,Tiruray,ETHNICITY,ETHNICITY
SM277239,79.09,12.51,Tiruvannamalai,ADM2,DISTRICT
SM13872,26.4084,57.1191,Tirzas pagasts,ADM2,DISTRICT
SM273574,-78.6797,-1.3661,Tisaleo,ADM2,DISTRICT
SM301494,-88.2393,34.7404,Tishomingo,ADM2,DISTRICT
SM285450,-86.0221,12.0855,Tisma,ADM2,DISTRICT
SM290668,22.938,45.0446,Tismana,ADM2,DISTRICT
SM305439,15.6416,43.7979,Tisno,ADM2,DISTRICT
SM3495,1.75,35.75,Tissemsilt,ADM1,DISTRICT
SM10345,22.7811,11.1113,Tissi,ADM2,DISTRICT
SM293164,88,55.25,Tisul'skiy rayon,ADM2,DISTRICT
SM11593,20.7888,47.5391,Tiszafueredi Jaras,ADM2,DISTRICT
SM11594,19.973,46.8601,Tiszakecskei Jaras,ADM2,DISTRICT
SM276175,21.3655,47.9861,Tiszavasvár,ADM2,DISTRICT
SM276236,20.9932,47.9266,Tiszaújváros,ADM2,DISTRICT
SM246721,10.1272,8.1938,Tita,LANGUAGE,LANGUAGE
SM246722,146.888,-2.1817,Titan,LANGUAGE,LANGUAGE
SM275108,-15.4,11.7833,Tite,ADM2,DISTRICT
SM291300,24.979,45.0115,Titesti,ADM2,DISTRICT
SM270160,-75.7992,6.0622,Titiribí,ADM2,DISTRICT
SM290312,25.5555,44.6575,Titu,ADM2,DISTRICT
SM302779,-94.9657,33.2166,Titus,ADM2,DISTRICT
SM246729,9.2629,6.8804,Tiv,LANGUAGE,LANGUAGE
SM250760,7.35,9.83,Tiv,ETHNICITY,ETHNICITY
SM250760,7.35,9.83,Tiv,ETHNICITY,ETHNICITY
SM250760,7.35,9.83,Tiv,ETHNICITY,ETHNICITY
SM250760,9,7.25,Tiv,ETHNICITY,ETHNICITY
SM250760,9,7.25,Tiv,ETHNICITY,ETHNICITY
SM250760,9,7.25,Tiv,ETHNICITY,ETHNICITY
SM250760,9,7,Tiv,ETHNICITY,ETHNICITY
SM250760,9,7,Tiv,ETHNICITY,ETHNICITY
SM250760,9,7,Tiv,ETHNICITY,ETHNICITY
SM295262,-16.7106,15.0852,Tivaouane,ADM2,DISTRICT
SM3496,18.6531,42.4056,Tivat,ADM1,DISTRICT
SM246725,-62.5154,10.1022,Tiverighotto,LANGUAGE,LANGUAGE
SM12786,-76.8009,17.9733,Tivoli Gardens,ADM2,DISTRICT
SM258104,-105.89,36.39,Tiwa,ETHNICITY,ETHNICITY
SM258104,-105.89,36.39,Tiwa,ETHNICITY,ETHNICITY
SM258104,-105.89,36.39,Tiwa,ETHNICITY,ETHNICITY
SM258104,-105.5,36.5,Tiwa,ETHNICITY,ETHNICITY
SM258104,-105.5,36.5,Tiwa,ETHNICITY,ETHNICITY
SM258104,-105.5,36.5,Tiwa,ETHNICITY,ETHNICITY
SM258104,-106,37,Tiwa,ETHNICITY,ETHNICITY
SM258104,-106,37,Tiwa,ETHNICITY,ETHNICITY
SM258104,-106,37,Tiwa,ETHNICITY,ETHNICITY
SM246730,92.2583,26.0667,Tiwa (India),LANGUAGE,LANGUAGE
SM246734,130.949,-11.6308,Tiwi,LANGUAGE,LANGUAGE
SM258105,130.97,-11.59,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.97,-11.59,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.97,-11.59,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.97,-11.59,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.97,-11.59,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.81,-11.56,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.81,-11.56,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.81,-11.56,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.81,-11.56,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.81,-11.56,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.87,-11.59,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.87,-11.59,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.87,-11.59,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.87,-11.59,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.87,-11.59,Tiwi,ETHNICITY,ETHNICITY
SM258105,131,-11.38,Tiwi,ETHNICITY,ETHNICITY
SM258105,131,-11.38,Tiwi,ETHNICITY,ETHNICITY
SM258105,131,-11.38,Tiwi,ETHNICITY,ETHNICITY
SM258105,131,-11.38,Tiwi,ETHNICITY,ETHNICITY
SM258105,131,-11.38,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.7597,-11.5634,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.7597,-11.5634,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.7597,-11.5634,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.7597,-11.5634,Tiwi,ETHNICITY,ETHNICITY
SM258105,130.7597,-11.5634,Tiwi,ETHNICITY,ETHNICITY
SM261596,130.7818,-11.5532,Tiwi Islands,ADM2,DISTRICT
SM273516,-78.0066,-3.0484,Tiwintza,ADM2,DISTRICT
SM283030,-88.3003,20.4393,Tixcacalcupul,ADM2,DISTRICT
SM283031,-89.3806,20.9811,Tixkokob,ADM2,DISTRICT
SM283032,-89.087,20.2468,Tixméhuac,ADM2,DISTRICT
SM283033,-89.4674,20.9676,Tixpéhual,ADM2,DISTRICT
SM281639,-99.3794,17.5807,Tixtla de Guerrero,ADM2,DISTRICT
SM258106,34.27,29.82,Tiyaha,ETHNICITY,ETHNICITY
SM295807,44.5291,3.8359,Tiyeeglow,ADM2,DISTRICT
SM281862,-103.0753,20.1264,Tizapán el Alto,ADM2,DISTRICT
SM281719,-98.9777,19.8366,Tizayuca,ADM2,DISTRICT
SM3497,4.25,36.75,Tizi Ouzou,ADM1,DISTRICT
SM457766,4.0833,36.75,Tizi Ouzou District,ADM2,DISTRICT
SM283034,-87.9576,21.2362,Tizimín,ADM2,DISTRICT
SM4444,-9.5,29.5833,Tiznit (2009-),ADM2,DISTRICT
SM246608,-5.0861,14.1223,Tiéyaxo Bozo,LANGUAGE,LANGUAGE
SM304436,106.04,21.1219,Tiên Du,ADM2,DISTRICT
SM304079,106.5873,20.6997,Tiên Lãng,ADM2,DISTRICT
SM304209,106.1251,20.6849,Tiên Lữ,ADM2,DISTRICT
SM304393,108.2826,15.4709,Tiên Phước,ADM2,DISTRICT
SM304419,107.3747,21.3757,Tiên Yên,ADM2,DISTRICT
SM296373,16.0809,46.6604,Tišina,ADM1:ADM2,DISTRICT
SM3478,106.3,10.4,Tiền Giang,ADM1,DISTRICT
SM304495,106.5288,20.3847,Tiền Hải,ADM2,DISTRICT
SM304550,106.2059,9.8035,Tiểu Cần,ADM2,DISTRICT
SM458101,117.0541,-30.9639,Tjapanmay,ETHNICITY,ETHNICITY
SM461895,16.3617,68.5556,Tjeldsund (2020-),ADM2,DISTRICT
SM458080,123.7832,-30.4319,Tjeraridjal,ETHNICITY,ETHNICITY
SM458056,120.5664,-27.118,Tjupan,ETHNICITY,ETHNICITY
SM246740,118.267,-22.842,Tjurruru,LANGUAGE,LANGUAGE
SM258108,141.77,-11.64,Tjuundji,ETHNICITY,ETHNICITY
SM258108,141.77,-11.64,Tjuundji,ETHNICITY,ETHNICITY
SM258108,141.8711,-12.0199,Tjuundji,ETHNICITY,ETHNICITY
SM258108,141.8711,-12.0199,Tjuundji,ETHNICITY,ETHNICITY
SM296604,11.631,58.0052,Tjörn,ADM2,DISTRICT
SM277773,-17.2,66.1167,Tjörneshreppur,ADM2,DISTRICT
SM274804,42.9854,42.3382,Tkibuli,ADM2,DISTRICT
SM305604,15.4164,43.92,Tkon,ADM2,DISTRICT
SM11346,41.6753,42.8729,Tkvarcheli district,ADM2,DISTRICT
SM282811,-98.1919,20.6027,Tlachichilco,ADM2,DISTRICT
SM246744,-98.1947,20.704,Tlachichilco Tepehua,LANGUAGE,LANGUAGE
SM282317,-97.4039,19.1356,Tlachichuca,ADM2,DISTRICT
SM281640,-98.259,16.9262,Tlacoachistlahuaca,ADM2,DISTRICT
SM281641,-98.7695,17.2464,Tlacoapa,ADM2,DISTRICT
SM246745,-98.8013,17.3262,Tlacoapa Me'phaa,LANGUAGE,LANGUAGE
SM246747,-96.5176,17.8646,Tlacoatzintepec Chinantec,LANGUAGE,LANGUAGE
SM14882,-96.4692,16.9551,Tlacolula de Matamoros,ADM2,DISTRICT
SM282812,-97.0087,19.6905,Tlacolulan,ADM2,DISTRICT
SM246748,-95.7414,16.2971,Tlacolulita Zapotec,LANGUAGE,LANGUAGE
SM282813,-95.6316,18.5468,Tlacotalpan,ADM2,DISTRICT
SM14883,-97.4428,17.8443,Tlacotepec Plumas,ADM2,DISTRICT
SM282318,-97.6346,18.6615,Tlacotepec de Benito Juárez,ADM2,DISTRICT
SM282814,-96.7992,19.1814,Tlacotepec de Mejía,ADM2,DISTRICT
SM282319,-97.9862,20.3803,Tlacuilotepec,ADM2,DISTRICT
SM281525,-103.5246,26.0533,Tlahualilo,ADM2,DISTRICT
SM282320,-98.5734,19.3649,Tlahuapan,ADM2,DISTRICT
SM281720,-99.2268,20.1309,Tlahuelilpan,ADM2,DISTRICT
SM281722,-98.9626,20.8981,Tlahuiltepa,ADM2,DISTRICT
SM246750,-96.1186,16.9634,Tlahuitoltepec Mixe,LANGUAGE,LANGUAGE
SM282815,-95.9447,18.2021,Tlajocalpan,ADM2,DISTRICT
SM281863,-103.4294,20.4924,Tlajomulco de Zúñiga,ADM2,DISTRICT
SM281642,-100.454,18.4292,Tlalchapa,ADM2,DISTRICT
SM282816,-96.1754,18.7526,Tlalixcoyan,ADM2,DISTRICT
SM14884,-96.6461,17.0669,Tlalixtac de Cabrera,ADM2,DISTRICT
SM281643,-98.3934,17.575,Tlalixtaquilla de Maldonado,ADM2,DISTRICT
SM281870,-98.7926,19.1977,Tlalmanalco,ADM2,DISTRICT
SM282817,-96.9741,19.5348,Tlalnelhuayocan,ADM2,DISTRICT
SM282114,-98.9688,19.0205,Tlalnepantla,ADM2,DISTRICT
SM281871,-99.1934,19.5405,Tlalnepantla,ADM2,DISTRICT
SM283330,-99.1657,19.2787,Tlalpan,ADM2,DISTRICT
SM282091,-100.1973,19.7798,Tlalpujahua,ADM2,DISTRICT
SM282321,-98.3411,19.1599,Tlaltenango,ADM2,DISTRICT
SM283079,-103.246,21.7776,Tlaltenango de Sánchez Román,ADM2,DISTRICT
SM282819,-96.8905,19.2889,Tlaltetela,ADM2,DISTRICT
SM282115,-99.1262,18.7082,Tlaltizapán,ADM2,DISTRICT
SM246753,-99.3992,18.6239,Tlamacazapa Nahuatl,LANGUAGE,LANGUAGE
SM281723,-98.5945,19.8253,Tlanalapa,ADM2,DISTRICT
SM281724,-98.65,21.0301,Tlanchinol,ADM2,DISTRICT
SM282322,-97.8851,18.8538,Tlanepantla,ADM2,DISTRICT
SM282324,-97.918,20.1675,Tlaola,ADM2,DISTRICT
SM281644,-98.6067,17.5368,Tlapa de Comonfort,ADM2,DISTRICT
SM282325,-97.8335,20.1351,Tlapacoya,ADM2,DISTRICT
SM282820,-97.1722,20.019,Tlapacoyan,ADM2,DISTRICT
SM282326,-98.5251,18.691,Tlapanalá,ADM2,DISTRICT
SM258109,-98.54,17.82,Tlapanec,ETHNICITY,ETHNICITY
SM281645,-100.4516,18.2722,Tlapehuala,ADM2,DISTRICT
SM281741,-103.3543,20.5773,Tlaquepaque,ADM2,DISTRICT
SM282821,-97.1105,18.6088,Tlaquilpan,ADM2,DISTRICT
SM282116,-99.1226,18.5504,Tlaquiltenango,ADM2,DISTRICT
SM282327,-97.4885,19.866,Tlatlauquitepec,ADM2,DISTRICT
SM281872,-100.2562,18.5617,Tlatlaya,ADM2,DISTRICT
SM282707,-98.2296,19.3242,Tlaxcala,ADM2,DISTRICT
SM3498,-98.1667,19.4167,Tlaxcala,ADM1,DISTRICT
SM228384,-98.3002,19.1248,Tlaxcala-Puebla-Central Nahuatl,LANGUAGE,LANGUAGE
SM258111,-98,19,Tlaxcalans,ETHNICITY,ETHNICITY
SM282708,-98.1643,19.6168,Tlaxco,ADM2,DISTRICT
SM282328,-98.0519,20.3992,Tlaxco,ADM2,DISTRICT
SM281725,-99.2265,20.095,Tlaxcoapan,ADM2,DISTRICT
SM282117,-98.976,18.9507,Tlayacapan,ADM2,DISTRICT
SM282092,-102.0728,19.9999,Tlazazalca,ADM2,DISTRICT
SM246756,-96.9783,17.027,Tlazoyaltepec Mixtec,LANGUAGE,LANGUAGE
SM3499,-1.4167,34.6667,Tlemcen,ADM1,DISTRICT
SM457769,-1.5,34.75,Tlemcen District,ADM2,DISTRICT
SM250761,24.1,-27.35,Tlhaping,ETHNICITY,ETHNICITY
SM250762,24.02,-24.71,Tlharo,ETHNICITY,ETHNICITY
SM253218,-120.1,61.81,Tlicho,ETHNICITY,ETHNICITY
SM253218,-120.1,61.81,Tlicho,ETHNICITY,ETHNICITY
SM253218,-120.1,61.81,Tlicho,ETHNICITY,ETHNICITY
SM253218,-117,63,Tlicho,ETHNICITY,ETHNICITY
SM253218,-117,63,Tlicho,ETHNICITY,ETHNICITY
SM253218,-117,63,Tlicho,ETHNICITY,ETHNICITY
SM253218,-115.61,63.85,Tlicho,ETHNICITY,ETHNICITY
SM253218,-115.61,63.85,Tlicho,ETHNICITY,ETHNICITY
SM253218,-115.61,63.85,Tlicho,ETHNICITY,ETHNICITY
SM282822,-97.0879,18.7971,Tlilapan,ADM2,DISTRICT
SM246760,-135.29,59.4447,Tlingit,LANGUAGE,LANGUAGE
SM258113,-135.02,57.04,Tlingit,ETHNICITY,ETHNICITY
SM258113,-135.02,57.04,Tlingit,ETHNICITY,ETHNICITY
SM258113,-135.02,57.04,Tlingit,ETHNICITY,ETHNICITY
SM258113,-135.02,57.04,Tlingit,ETHNICITY,ETHNICITY
SM258113,-134.583,57.5,Tlingit,ETHNICITY,ETHNICITY
SM258113,-134.583,57.5,Tlingit,ETHNICITY,ETHNICITY
SM258113,-134.583,57.5,Tlingit,ETHNICITY,ETHNICITY
SM258113,-134.583,57.5,Tlingit,ETHNICITY,ETHNICITY
SM258113,-133.92,58.12,Tlingit,ETHNICITY,ETHNICITY
SM258113,-133.92,58.12,Tlingit,ETHNICITY,ETHNICITY
SM258113,-133.92,58.12,Tlingit,ETHNICITY,ETHNICITY
SM258113,-133.92,58.12,Tlingit,ETHNICITY,ETHNICITY
SM258113,-133.59,57,Tlingit,ETHNICITY,ETHNICITY
SM258113,-133.59,57,Tlingit,ETHNICITY,ETHNICITY
SM258113,-133.59,57,Tlingit,ETHNICITY,ETHNICITY
SM258113,-133.59,57,Tlingit,ETHNICITY,ETHNICITY
SM258114,-136,59,Tlingit (Northern),ETHNICITY,ETHNICITY
SM258115,-133.59,57,Tlingit (Southern),ETHNICITY,ETHNICITY
SM258116,-132.78,58.15,Tlingit Inland,ETHNICITY,ETHNICITY
SM299980,25.0028,48.8642,Tlumats'kyi,ADM2,DISTRICT
SM292857,46.4167,42.0833,Tlyaratinskiy rayon,ADM2,DISTRICT
SM283329,-99.0028,19.2744,Tláhuac,ADM2,DISTRICT
SM246910,15.2819,7.2594,To,LANGUAGE,LANGUAGE
SM246768,160.619,-8.3923,To'abaita,LANGUAGE,LANGUAGE
SM303545,71.4718,41.0072,To'raqo'rg'on,ADM2,DISTRICT
SM303503,61.7268,41.6242,To'rtko'l,ADM2,DISTRICT
SM3500,-66.2538,18.3661,Toa Alta,ADM1,DISTRICT
SM16245,-66.2476,18.3879,Toa Alta Barrio-Pueblo,ADM2,DISTRICT
SM3501,-66.2129,18.4318,Toa Baja,ADM1,DISTRICT
SM16246,-66.254,18.4424,Toa Baja Barrio-Pueblo,ADM2,DISTRICT
SM258117,121.38,24.58,Toakas,ETHNICITY,ETHNICITY
SM14172,49.392,-18.1386,Toamasina I,ADM2,DISTRICT
SM14173,49.1461,-17.9405,Toamasina II,ADM2,DISTRICT
SM246771,146.206,-8.0723,Toaripi,LANGUAGE,LANGUAGE
SM258118,145.69,-7.86,Toaripi,ETHNICITY,ETHNICITY
SM260852,-64.8,-36.5833,Toay,ADM2,DISTRICT
SM246777,-61.96,-23.77,Toba,LANGUAGE,LANGUAGE
SM278709,136.8333,34.4833,Toba,ADM2,DISTRICT
SM258119,-59.42,-24.59,Toba,ETHNICITY,ETHNICITY
SM258119,-59.42,-24.59,Toba,ETHNICITY,ETHNICITY
SM258119,-59.42,-24.59,Toba,ETHNICITY,ETHNICITY
SM258119,-60,-25.5,Toba,ETHNICITY,ETHNICITY
SM258119,-60,-25.5,Toba,ETHNICITY,ETHNICITY
SM258119,-60,-25.5,Toba,ETHNICITY,ETHNICITY
SM258119,-60,-25,Toba,ETHNICITY,ETHNICITY
SM258119,-60,-25,Toba,ETHNICITY,ETHNICITY
SM258119,-60,-25,Toba,ETHNICITY,ETHNICITY
SM252117,98.18,2.41,Toba Batak,ETHNICITY,ETHNICITY
SM252117,98.18,2.41,Toba Batak,ETHNICITY,ETHNICITY
SM252117,99,2,Toba Batak,ETHNICITY,ETHNICITY
SM252117,99,2,Toba Batak,ETHNICITY,ETHNICITY
SM276701,99.2168,2.3979,Toba Samosir,ADM2,DISTRICT
SM375451,72.5369,30.9286,Toba Tek Singh,ADM2:ADM3,DISTRICT
SM246776,-58.0158,-21.5474,Toba-Maskoy,LANGUAGE,LANGUAGE
SM3503,-60.6667,11.25,Tobago,ADM1:ADMX,DISTRICT
SM246780,-60.6791,11.2388,Tobagonian Creole English,LANGUAGE,LANGUAGE
SM258120,-42.53,-4.17,Tobajara,ETHNICITY,ETHNICITY
SM246779,16.209,9.8289,Tobanga,LANGUAGE,LANGUAGE
SM246774,140.71,-2.5643,Tobati,LANGUAGE,LANGUAGE
SM289537,-57.0466,-25.216,Tobatí,ADM2,DISTRICT
SM246783,128.154,1.0946,Tobelo,LANGUAGE,LANGUAGE
SM258121,127.84,1.57,Tobelorese,ETHNICITY,ETHNICITY
SM258121,127.84,1.57,Tobelorese,ETHNICITY,ETHNICITY
SM258121,127.84,1.57,Tobelorese,ETHNICITY,ETHNICITY
SM258121,128,1,Tobelorese,ETHNICITY,ETHNICITY
SM258121,128,1,Tobelorese,ETHNICITY,ETHNICITY
SM258121,128,1,Tobelorese,ETHNICITY,ETHNICITY
SM258121,127.94,2,Tobelorese,ETHNICITY,ETHNICITY
SM258121,127.94,2,Tobelorese,ETHNICITY,ETHNICITY
SM258121,127.94,2,Tobelorese,ETHNICITY,ETHNICITY
SM246784,131.1713,3.0045,Tobian,LANGUAGE,LANGUAGE
SM267146,-38.0218,-11.0779,Tobias Barreto,ADM2,DISTRICT
SM246785,116.67,6.561,Tobilung,LANGUAGE,LANGUAGE
SM246789,147.376,-6.3626,Tobo,DIALECT,LANGUAGE
SM258122,-102.49,26.83,Toboso,ETHNICITY,ETHNICITY
SM270730,-73.1854,5.5582,Toca,ADM2,DISTRICT
SM286836,-76.6345,-8.2493,Tocache,ADM2,DISTRICT
SM270069,-74.6572,4.4607,Tocaima,ADM2,DISTRICT
SM270070,-73.9483,4.9481,Tocancipá,ADM2,DISTRICT
SM264016,-43.0256,-21.1812,Tocantins,ADM2,DISTRICT
SM3504,-48,-10.5,Tocantins,ADM1,DISTRICT
SM246792,-49.8991,-3.5936,Tocantins Asurini,LANGUAGE,LANGUAGE
SM267189,-47.5577,-6.2625,Tocantinópolis,ADM2,DISTRICT
SM267188,-48.1871,-9.5644,Tocantínia,ADM2,DISTRICT
SM282709,-98.0205,19.3826,Tocatlán,ADM2,DISTRICT
SM3505,139.8167,36.7,Tochigi Prefecture,ADM1:ADMX,DISTRICT
SM12989,139.6936,36.4113,Tochigi-Shi (2014-),ADM2,DISTRICT
SM282329,-98.5933,18.8867,Tochimilco,ADM2,DISTRICT
SM11071,33.3196,34.7693,Tochni,ADM2,DISTRICT
SM246794,30.1567,10.8828,Tocho,LANGUAGE,LANGUAGE
SM282330,-97.8213,18.8382,Tochtepec,ADM2,DISTRICT
SM275838,-85.9506,15.589,Tocoa,ADM2,DISTRICT
SM258123,-82.31,27.95,Tocobaga,ETHNICITY,ETHNICITY
SM269098,-69.6401,-22.0295,Tocopilla,ADM2,DISTRICT
SM264017,-46.1495,-22.3566,Tocos do Moji,ADM2,DISTRICT
SM282093,-102.6007,19.62,Tocumbo,ADM2,DISTRICT
SM303577,-69.2589,11.499,Tocópero,ADM2,DISTRICT
SM246796,77.0168,11.4184,Toda,LANGUAGE,LANGUAGE
SM279207,139.657,35.8148,Toda,ADM2,DISTRICT
SM258124,77.09,10.43,Toda,ETHNICITY,ETHNICITY
SM258124,77.09,10.43,Toda,ETHNICITY,ETHNICITY
SM258124,77.09,10.43,Toda,ETHNICITY,ETHNICITY
SM258124,76.5,11.5,Toda,ETHNICITY,ETHNICITY
SM258124,76.5,11.5,Toda,ETHNICITY,ETHNICITY
SM258124,76.5,11.5,Toda,ETHNICITY,ETHNICITY
SM258124,77,12,Toda,ETHNICITY,ETHNICITY
SM258124,77,12,Toda,ETHNICITY,ETHNICITY
SM258124,77,12,Toda,ETHNICITY,ETHNICITY
SM13104,141.1211,38.545,Toda Gun,ADM2,DISTRICT
SM302534,-100.7184,43.1934,Todd,ADM2,DISTRICT
SM301413,-94.8976,46.0706,Todd,ADM2,DISTRICT
SM301001,-87.1792,36.8356,Todd,ADM2,DISTRICT
SM280935,-10.4959,6.6393,Todee,ADM2,DISTRICT
SM280935,-10.4959,6.6393,Todee,ADM2,DISTRICT
SM280935,-10.4959,6.6393,Todee,ADM2,DISTRICT
SM280935,-10.4773,6.6187,Todee,ADM2,DISTRICT
SM280935,-10.4773,6.6187,Todee,ADM2,DISTRICT
SM280935,-10.4773,6.6187,Todee,ADM2,DISTRICT
SM280935,-10.5333,6.6667,Todee,ADM2,DISTRICT
SM280935,-10.5333,6.6667,Todee,ADM2,DISTRICT
SM280935,-10.5333,6.6667,Todee,ADM2,DISTRICT
SM292604,27.1012,47.6139,Todireni,ADM2,DISTRICT
SM291852,26.0776,47.7045,Todiresti,ADM2,DISTRICT
SM275499,-91.6052,15.5263,Todos Santos Cuchumatán,ADM2,DISTRICT
SM246799,108.114,14.5672,Todrah,LANGUAGE,LANGUAGE
SM258127,-118,40,Toedokado,ETHNICITY,ETHNICITY
SM258127,-118,40,Toedokado,ETHNICITY,ETHNICITY
SM258127,-118.37,40.12,Toedokado,ETHNICITY,ETHNICITY
SM258127,-118.37,40.12,Toedokado,ETHNICITY,ETHNICITY
SM284885,8.2667,12.05,Tofa,ADM2,DISTRICT
SM8080,96.976,54.2177,Tofa,ETHNICITY,ETHNICITY
SM246802,140.591,-3.7432,Tofanma,LANGUAGE,LANGUAGE
SM262146,2.181,6.856,Toffo,ADM2,DISTRICT
SM323780,-112.6737,53.3729,Tofield,ADM2:ADM3,DISTRICT
SM246805,2.4323,6.5177,Tofin Gbe,LANGUAGE,LANGUAGE
SM246806,22.7362,6.3582,Togbo-Vara Banda,LANGUAGE,LANGUAGE
SM3506,45.4167,9.3333,Togdheer,ADM1,DISTRICT
SM258128,132.98,-13.54,Togeman,ETHNICITY,ETHNICITY
SM258128,132.98,-13.54,Togeman,ETHNICITY,ETHNICITY
SM258128,131.8776,-14.441,Togeman,ETHNICITY,ETHNICITY
SM258128,131.8776,-14.441,Togeman,ETHNICITY,ETHNICITY
SM268939,9.1728,47.2945,Toggenburg,ADM2,DISTRICT
SM246809,25.9756,8.6629,Togoyo,LANGUAGE,LANGUAGE
SM293880,84.3333,55.25,Toguchinskiy rayon,ADM2,DISTRICT
SM293004,86.0833,53.6667,Togul'skiy rayon,ADM2,DISTRICT
SM280323,74.037,41.405,Togus-Toro,ADM2,DISTRICT
SM270731,-73.5137,5.8943,Toguí,ADM2,DISTRICT
SM13105,133.9251,35.3874,Tohaku-gun,ADM2,DISTRICT
SM246813,-111.995,31.7973,Tohono O'odham,LANGUAGE,LANGUAGE
SM256724,-111.72,32.48,Tohono Oodham,ETHNICITY,ETHNICITY
SM256724,-111.72,32.48,Tohono Oodham,ETHNICITY,ETHNICITY
SM256724,-111.72,32.48,Tohono Oodham,ETHNICITY,ETHNICITY
SM256724,-112,32,Tohono Oodham,ETHNICITY,ETHNICITY
SM256724,-112,32,Tohono Oodham,ETHNICITY,ETHNICITY
SM256724,-112,32,Tohono Oodham,ETHNICITY,ETHNICITY
SM256724,-112,31,Tohono Oodham,ETHNICITY,ETHNICITY
SM256724,-112,31,Tohono Oodham,ETHNICITY,ETHNICITY
SM256724,-112,31,Tohono Oodham,ETHNICITY,ETHNICITY
SM11199,27.5626,59.3482,Toila vald,ADM2,DISTRICT
SM16247,-66.1844,18.1267,Toita Barrio (Cayey),ADM2,DISTRICT
SM16248,-66.1901,18.1501,Toita Barrio (Cidra),ADM2,DISTRICT
SM305988,70.8,39.2,Tojikobod,ADM2,DISTRICT
SM276595,121.482,-1.2036,Tojo Una-Una,ADM2,DISTRICT
SM246816,-91.7825,16.2572,Tojolabal,LANGUAGE,LANGUAGE
SM246827,147.303,-9.4197,Tok Pisin,LANGUAGE,LANGUAGE
SM258130,26.82,-16.45,Toka,ETHNICITY,ETHNICITY
SM229796,26.502,-18.37,Toka-Leya-Dombe,LANGUAGE,LANGUAGE
SM276237,21.37,48.1401,Tokaji,ADM2,DISTRICT
SM246818,145.298,-6.0505,Tokano,LANGUAGE,LANGUAGE
SM294588,41.25,52,Tokarevskiy rayon,ADM2,DISTRICT
SM3507,36.5833,40.4167,Tokat,ADM1,DISTRICT
SM246820,-171.7808,-9.2276,Tokelau,LANGUAGE,LANGUAGE
SM258131,-171.83,-9.14,Tokelau,ETHNICITY,ETHNICITY
SM258131,-171.83,-9.14,Tokelau,ETHNICITY,ETHNICITY
SM258131,-171.85,-9.2,Tokelau,ETHNICITY,ETHNICITY
SM258131,-171.85,-9.2,Tokelau,ETHNICITY,ETHNICITY
SM246822,89.18,42.98,Tokharian A,LANGUAGE,LANGUAGE
SM246823,82.9,41.65,Tokharian B,LANGUAGE,LANGUAGE
SM279817,137.2075,35.3242,Toki,ADM2,DISTRICT
SM246824,46.3724,42.5727,Tokita,DIALECT,LANGUAGE
SM286213,8.0139,59.4457,Tokke,ADM2,DISTRICT
SM18280,35.7078,47.2553,Tokmak Raion,ADM2,DISTRICT
SM13369,75.2978,42.842,Tokmok District,ADM2,DISTRICT
SM278072,136.853,34.8893,Tokoname,ADM2,DISTRICT
SM13106,143.5811,43.645,Tokoro-gun,ADM2,DISTRICT
SM279210,139.4582,35.7997,Tokorozawa,ADM2,DISTRICT
SM280324,73,41.75,Toktogul,ADM2,DISTRICT
SM246830,128.961,27.7736,Toku-No-Shima,LANGUAGE,LANGUAGE
SM279416,134.5236,34.0492,Tokushima,ADM2,DISTRICT
SM3508,134.2368,33.9218,Tokushima,ADM1:ADMX,DISTRICT
SM3509,139.6917,35.6895,Tokyo,ADM1:ADMX,DISTRICT
SM246842,-87.0372,14.6686,Tol,LANGUAGE,LANGUAGE
SM454369,152.128,-4.311,Tolai,ETHNICITY,ETHNICITY
SM246832,151.3236,-4.9032,Tolai-Nakanai-Trade,LANGUAGE,LANGUAGE
SM246835,121.888,-4.0358,Tolaki,LANGUAGE,LANGUAGE
SM283686,90.2925,48.4112,Tolbo,ADM2,DISTRICT
SM281726,-98.9191,19.9411,Tolcayuca,ADM2,DISTRICT
SM279875,70.25,42.25,Tolebiyskiy,ADM2,DISTRICT
SM274066,-4,39.8333,Toledo,ADM2,DISTRICT
SM270392,-72.3113,7.2322,Toledo,ADM2,DISTRICT
SM270161,-75.7149,7.0235,Toledo,ADM2,DISTRICT
SM264757,-53.8206,-24.7352,Toledo,ADM2,DISTRICT
SM264018,-46.3904,-22.7057,Toledo,ADM2,DISTRICT
SM3510,-88.8667,16.3333,Toledo,ADM1,DISTRICT
SM14982,151.623,7.333,Tolensom Municipality,ADM2,DISTRICT
SM286365,10.9977,62.4098,Tolga,ADM2,DISTRICT
SM9720,-67.1811,-54.5097,Tolhuin,ADM2,DISTRICT
SM276596,120.8864,1.3086,Toli-Toli,ADM2,DISTRICT
SM371043,43.815,-23.036,Toliary,ADM2:ADM3,DISTRICT
SM371042,43.6656,-23.3316,Toliary Urban,ADM2:ADM3,DISTRICT
SM276542,138.4004,-3.488,Tolikara,ADM2,DISTRICT
SM3512,-75.25,4,Tolima,ADM1,DISTRICT
SM282456,-99.9457,20.9117,Tolimán,ADM2,DISTRICT
SM281742,-103.9073,19.5748,Tolimán,ADM2,DISTRICT
SM258710,-114,34,Tolkepaya Yavapai,ETHNICITY,ETHNICITY
SM303388,-72.3365,41.855,Tolland,ADM2,DISTRICT
SM12787,-77.3657,17.972,Tollgate,ADM2,DISTRICT
SM296249,13.7116,46.1795,Tolmin,ADM1:ADM2,DISTRICT
SM3513,18.5833,46.5,Tolna,ADM1,DISTRICT
SM11595,18.8269,46.4254,Tolnai jaras,ADM2,DISTRICT
SM246845,166.772,-15.1569,Tolomako,LANGUAGE,LANGUAGE
SM4370,-1.0667,9.4333,Tolon,ADM2,DISTRICT
SM258134,-123.22,41.16,Tolowa,ETHNICITY,ETHNICITY
SM258134,-123.22,41.16,Tolowa,ETHNICITY,ETHNICITY
SM258134,-123.22,41.16,Tolowa,ETHNICITY,ETHNICITY
SM258134,-124,42,Tolowa,ETHNICITY,ETHNICITY
SM258134,-124,42,Tolowa,ETHNICITY,ETHNICITY
SM258134,-124,42,Tolowa,ETHNICITY,ETHNICITY
SM258134,-123.92,41.87,Tolowa,ETHNICITY,ETHNICITY
SM258134,-123.92,41.87,Tolowa,ETHNICITY,ETHNICITY
SM258134,-123.92,41.87,Tolowa,ETHNICITY,ETHNICITY
SM246848,-124.1,41.9192,Tolowa-Chetco,LANGUAGE,LANGUAGE
SM281873,-99.6602,19.3278,Toluca,ADM2,DISTRICT
SM258135,-87.77,15.36,Tolupan,ETHNICITY,ETHNICITY
SM258135,-87.77,15.36,Tolupan,ETHNICITY,ETHNICITY
SM258135,-87,15,Tolupan,ETHNICITY,ETHNICITY
SM258135,-87,15,Tolupan,ETHNICITY,ETHNICITY
SM270544,-75.4376,9.4545,Toluviejo,ADM2,DISTRICT
SM286681,-81.6601,8.1901,Tolé,ADM2,DISTRICT
SM302780,-100.4621,31.4044,Tom Green,ADM2,DISTRICT
SM246849,-9.2344,8.2038,Toma,LANGUAGE,LANGUAGE
SM250439,-8.23,9.67,Toma,ETHNICITY,ETHNICITY
SM250439,-8.23,9.67,Toma,ETHNICITY,ETHNICITY
SM250439,-9,11,Toma,ETHNICITY,ETHNICITY
SM250439,-9,11,Toma,ETHNICITY,ETHNICITY
SM246852,121.944,-2.5649,Tomadino,LANGUAGE,LANGUAGE
SM258136,17.68,21.43,Tomagra,ETHNICITY,ETHNICITY
SM258136,16.37,18.9,Tomagra,ETHNICITY,ETHNICITY
SM299922,34.7423,47.8124,Tomakivs'kyi,ADM2,DISTRICT
SM278212,141.5795,42.6823,Tomakomai,ADM2,DISTRICT
SM275716,-88.7314,14.2433,Tomalá,ADM2,DISTRICT
SM13107,141.8542,44.3233,Tomamae Gun,ADM2,DISTRICT
SM289328,-8.3935,39.6104,Tomar,ADM2,DISTRICT
SM267147,-37.8754,-11.3742,Tomar do Geru,ADM2,DISTRICT
SM294349,142.25,47.8333,Tomarinskiy rayon,ADM2,DISTRICT
SM298613,35.8047,38.4578,Tomarza,ADM2,DISTRICT
SM9860,-67.4217,-17.6384,Tomas Barron,ADM2,DISTRICT
SM16249,-66.0147,18.2066,Tomas de Castro Barrio,ADM2,DISTRICT
SM299782,28.514,48.5375,Tomashpil's'kyi,ADM2,DISTRICT
SM288904,23.5208,50.4783,Tomaszów Lubelski,ADM2,DISTRICT
SM288879,20.0664,51.5967,Tomaszów Mazowiecki,ADM2,DISTRICT
SM282823,-96.9957,19.0203,Tomatlán,ADM2,DISTRICT
SM281743,-105.1979,19.9821,Tomatlán,ADM2,DISTRICT
SM264758,-49.9555,-23.7766,Tomazina,ADM2,DISTRICT
SM3514,-15,11.3,Tombali,ADM1,DISTRICT
SM246855,121.915,-2.51,Tombelala,LANGUAGE,LANGUAGE
SM258137,143.33,-5.26,Tombema Enga,ETHNICITY,ETHNICITY
SM246856,12.3624,-2.2274,Tombidi,LANGUAGE,LANGUAGE
SM260393,13.3558,-6.6817,Tomboco,ADM2,DISTRICT
SM246854,117.322,6.2892,Tombonuo,LANGUAGE,LANGUAGE
SM264019,-42.0682,-20.8805,Tombos,ADM2,DISTRICT
SM283390,-3.0855,19.1486,Tombouctou,ADM2,DISTRICT
SM4073,-3.6,19.7,Tombouctou Region (2012-),ADM1,DISTRICT
SM246853,124.754,1.3971,Tombulu,LANGUAGE,LANGUAGE
SM260370,12.165,-16.5184,Tombwa,ADM2,DISTRICT
SM303552,65.2346,42.225,Tomdi,ADM2,DISTRICT
SM278745,141.2575,38.6858,Tome,ADM2,DISTRICT
SM246858,-69.6666,3.25,Tomedes,LANGUAGE,LANGUAGE
SM296451,14.0198,55.6196,Tomelilla,ADM2,DISTRICT
SM13873,24.6221,56.7409,Tomes pagasts,ADM2,DISTRICT
SM292028,22.3097,45.7667,Tomesti,ADM2,DISTRICT
SM290790,22.6702,46.2245,Tomesti,ADM2,DISTRICT
SM290884,27.7048,47.117,Tomesti,ADM2,DISTRICT
SM279075,127.6667,26.1667,Tomigusuku,ADM2,DISTRICT
SM14983,138.1549,9.5252,Tomil Municipality,ADM2,DISTRICT
SM262695,-64.3016,-19.4457,Tomina,ADM2,DISTRICT
SM246861,120.89,0.4257,Tomini,LANGUAGE,LANGUAGE
SM258138,120.14,0.44,Tomini,ETHNICITY,ETHNICITY
SM283378,-4.3772,13.2818,Tominian,ADM2,DISTRICT
SM278112,138.8833,36.25,Tomioka,ADM2,DISTRICT
SM279391,140.3408,35.7202,Tomisato,ADM2,DISTRICT
SM246864,-3.28,14.756,Tommo So Dogon,LANGUAGE,LANGUAGE
SM246866,-3.8223,13.6331,Tomo Kan Dogon,LANGUAGE,LANGUAGE
SM276623,124.834,1.3131,Tomohon,ADM2,DISTRICT
SM246867,151.828,-5.3072,Tomoip,LANGUAGE,LANGUAGE
SM301918,-76.4737,42.452,Tompkins,ADM2,DISTRICT
SM305548,19.0933,45.2315,Tompojevci,ADM2,DISTRICT
SM294322,135.4167,62.75,Tomponskiy rayon,ADM2,DISTRICT
SM292247,24.0602,45.0961,Tomsani,ADM2,DISTRICT
SM291584,26.2959,44.9494,Tomsani,ADM2,DISTRICT
SM3515,82.5,58.5,Tomsk,ADM1,DISTRICT
SM17109,84.6667,56.5,Tomskiy Rayon,ADM2,DISTRICT
SM262754,-65.8878,-19.4226,Tomás Frías,ADM2,DISTRICT
SM289582,-55.2825,-26.4735,Tomás Romero Pereira,ADM2,DISTRICT
SM264117,-48.241,-2.6213,Tomé-Açu,ADM2,DISTRICT
SM280345,76.5797,42.0976,Ton,ADM2,DISTRICT
SM270521,-72.9656,7.1573,Tona,ADM2,DISTRICT
SM295522,-89.2,13.9167,Tonacatepeque,ADM2,DISTRICT
SM281744,-103.2023,20.6099,Tonalá,ADM2,DISTRICT
SM283208,-93.6706,15.9828,Tonalá,ADM2,DISTRICT
SM279501,136.989,36.624,Tonami,ADM2,DISTRICT
SM14885,-99.056,19.685,Tonanitla,ADM2,DISTRICT
SM267356,-67.4728,-2.5761,Tonantins,ADM2,DISTRICT
SM281874,-99.6436,18.782,Tonatico,ADM2,DISTRICT
SM281745,-103.9664,19.8242,Tonaya,ADM2,DISTRICT
SM282824,-96.9196,19.7123,Tonayán,ADM2,DISTRICT
SM279124,135.5943,34.4888,Tondabayashi,ADM2,DISTRICT
SM246876,124.964,1.2802,Tondano,LANGUAGE,LANGUAGE
SM289408,-8.1004,40.5405,Tondela,ADM2,DISTRICT
SM246874,-2.8162,15.263,Tondi Songway Kiini,LANGUAGE,LANGUAGE
SM278397,140.1704,35.8629,Tone,ADM2,DISTRICT
SM13108,139.0947,36.8201,Tone-gun,ADM2,DISTRICT
SM296037,31.6174,9.6337,Tonga,ADM2,DISTRICT
SM246889,34.0613,-12.0816,Tonga (Nyasa),LANGUAGE,LANGUAGE
SM250981,34,-12,Tonga (Nyasa),ETHNICITY,ETHNICITY
SM246893,-175.25,-21.17,Tonga (Tonga Islands),LANGUAGE,LANGUAGE
SM246887,27.1035,-17.4223,Tonga (Zambia),LANGUAGE,LANGUAGE
SM258139,30.09,-18.02,Tonga (Zambia),ETHNICITY,ETHNICITY
SM258139,30.09,-18.02,Tonga (Zambia),ETHNICITY,ETHNICITY
SM258139,30.09,-18.02,Tonga (Zambia),ETHNICITY,ETHNICITY
SM258139,29.98,-16.64,Tonga (Zambia),ETHNICITY,ETHNICITY
SM258139,29.98,-16.64,Tonga (Zambia),ETHNICITY,ETHNICITY
SM258139,29.98,-16.64,Tonga (Zambia),ETHNICITY,ETHNICITY
SM258139,28,-17,Tonga (Zambia),ETHNICITY,ETHNICITY
SM258139,28,-17,Tonga (Zambia),ETHNICITY,ETHNICITY
SM258139,28,-17,Tonga (Zambia),ETHNICITY,ETHNICITY
SM258139,28,-18,Tonga (Zambia),ETHNICITY,ETHNICITY
SM258139,28,-18,Tonga (Zambia),ETHNICITY,ETHNICITY
SM258139,28,-18,Tonga (Zambia),ETHNICITY,ETHNICITY
SM258140,-175.14,-21.26,Tongan (Polynesian),ETHNICITY,ETHNICITY
SM258140,-175.14,-21.26,Tongan (Polynesian),ETHNICITY,ETHNICITY
SM258140,-175.14,-21.26,Tongan (Polynesian),ETHNICITY,ETHNICITY
SM258140,-175.07,-19.74,Tongan (Polynesian),ETHNICITY,ETHNICITY
SM258140,-175.07,-19.74,Tongan (Polynesian),ETHNICITY,ETHNICITY
SM258140,-175.07,-19.74,Tongan (Polynesian),ETHNICITY,ETHNICITY
SM258140,-175.18,-21.19,Tongan (Polynesian),ETHNICITY,ETHNICITY
SM258140,-175.18,-21.19,Tongan (Polynesian),ETHNICITY,ETHNICITY
SM258140,-175.18,-21.19,Tongan (Polynesian),ETHNICITY,ETHNICITY
SM258141,-130.58,54.99,Tongass,ETHNICITY,ETHNICITY
SM3516,-175.2,-21.1333,Tongatapu,ADM1,DISTRICT
SM289197,125.5,40.25,Tongch'ang,ADM2,DISTRICT
SM269286,109.0456,35.2253,Tongchuan,ADM2,DISTRICT
SM15279,125.7828,39.0114,Tongdaewon-guyok,ADM2,DISTRICT
SM269240,125.8483,42.1239,Tonghua,ADM2,DISTRICT
SM269265,121.9353,43.582,Tongliao,ADM2,DISTRICT
SM269125,117.9021,30.9332,Tongling,ADM2,DISTRICT
SM269458,109.1931,27.7419,Tongren,ADM2,DISTRICT
SM289037,126.5325,40.2819,Tongsin,ADM2,DISTRICT
SM246897,-118.11,34.1,Tongva,LANGUAGE,LANGUAGE
SM253407,-118.74,33.05,Tongva,ETHNICITY,ETHNICITY
SM253407,-118.74,33.05,Tongva,ETHNICITY,ETHNICITY
SM253407,-118.74,33.05,Tongva,ETHNICITY,ETHNICITY
SM253407,-118.02,34.06,Tongva,ETHNICITY,ETHNICITY
SM253407,-118.02,34.06,Tongva,ETHNICITY,ETHNICITY
SM253407,-118.02,34.06,Tongva,ETHNICITY,ETHNICITY
SM253407,-118,34,Tongva,ETHNICITY,ETHNICITY
SM253407,-118,34,Tongva,ETHNICITY,ETHNICITY
SM253407,-118,34,Tongva,ETHNICITY,ETHNICITY
SM253407,-118,34.8,Tongva,ETHNICITY,ETHNICITY
SM253407,-118,34.8,Tongva,ETHNICITY,ETHNICITY
SM253407,-118,34.8,Tongva,ETHNICITY,ETHNICITY
SM246888,30.0807,-6.4305,Tongwe,LANGUAGE,LANGUAGE
SM250942,30.58,-5.41,Tongwe,ETHNICITY,ETHNICITY
SM280551,128.3971,34.8736,Tongyeong,ADM2,DISTRICT
SM281746,-103.5319,19.4261,Tonila,ADM2,DISTRICT
SM246904,-4.4067,8.357,Tonjon,LANGUAGE,LANGUAGE
SM277188,75.5833,26.1667,Tonk,ADM2,DISTRICT
SM246905,-96.75,30.25,Tonkawa,LANGUAGE,LANGUAGE
SM258142,-94.8,30.92,Tonkawa,ETHNICITY,ETHNICITY
SM258142,-98.28,30.45,Tonkawa,ETHNICITY,ETHNICITY
SM283547,93.9053,46.2971,Tonkhil,ADM2,DISTRICT
SM293790,46.4167,57.3333,Tonkinskiy rayon,ADM2,DISTRICT
SM295491,-11.8417,8.6189,Tonkolili,ADM1:ADM2,DISTRICT
SM269493,-7.6094,7.5679,Tonkpi,ADM2,DISTRICT
SM14984,151.89,7.381,Tonoas Municipality,ADM2,DISTRICT
SM258143,-63.95,-25.53,Tonocote,ETHNICITY,ETHNICITY
SM286720,-80.4583,7.436,Tonosí,ADM2,DISTRICT
SM246906,124.652,1.0539,Tonsawang,LANGUAGE,LANGUAGE
SM461901,10.4094,59.2711,Tonsberg (2020-),ADM2,DISTRICT
SM246907,125.052,1.5139,Tonsea,LANGUAGE,LANGUAGE
SM293791,47.25,57.75,Tonshaevskiy rayon,ADM2,DISTRICT
SM246908,124.654,1.2481,Tontemboan,LANGUAGE,LANGUAGE
SM298967,39.2899,40.8852,Tonya,ADM2,DISTRICT
SM261318,116.388,-31.4699,Toodyay,ADM2,DISTRICT
SM302908,-113.1311,40.4487,Tooele,ADM2,DISTRICT
SM301668,-111.6957,48.6553,Toole,ADM2,DISTRICT
SM300423,-82.3313,32.1217,Toombs,ADM2,DISTRICT
SM280774,106.2384,16.0224,Toomlarn,ADM2,DISTRICT
SM246911,30.6389,0.5729,Tooro,LANGUAGE,LANGUAGE
SM261669,151.4771,-27.4796,Toowoomba,ADM2,DISTRICT
SM12788,-77.5034,18.1162,Top Hill,ADM2,DISTRICT
SM18483,-77.6379,17.8932,Top Hill (Saint Elizabeth),ADM2,DISTRICT
SM270732,-72.8284,5.7748,Topagá,ADM2,DISTRICT
SM269959,-74.3133,5.3389,Topaipí,ADM2,DISTRICT
SM290196,28.0708,44.5212,Topalu,ADM2,DISTRICT
SM291472,24.5283,44.8432,Topana,ADM2,DISTRICT
SM293005,83.5,52.6667,Topchikhinskiy rayon,ADM2,DISTRICT
SM281526,-106.6662,25.2296,Topia,ADM2,DISTRICT
SM17110,85.5,55.25,Topkinskiy Rayon,ADM2,DISTRICT
SM290057,22.391,44.8045,Toplet,ADM2,DISTRICT
SM289925,26.9821,45.441,Topliceni,ADM2,DISTRICT
SM290724,25.3605,46.937,Toplita,ADM2,DISTRICT
SM290792,22.774,45.669,Toplita,ADM2,DISTRICT
SM3517,21.2781,43.1419,Toplički,ADM1:ADM2,DISTRICT
SM14117,-8.3167,5,Topo,ADM2,DISTRICT
SM246914,119.314,-2.067,Topoiyo,LANGUAGE,LANGUAGE
SM296150,18.1833,48.5667,Topolcany,ADM2,DISTRICT
SM292076,28.3432,44.8608,Topolog,ADM2,DISTRICT
SM292029,21.6378,45.7836,Topolovatu Mare,ADM2,DISTRICT
SM291301,25.0833,44.8167,Topoloveni,ADM2,DISTRICT
SM262517,26.3333,42.0833,Topolovgrad,ADM2,DISTRICT
SM290597,25.6474,43.9959,Toporu,ADM2,DISTRICT
SM246913,34.2159,5.159,Toposa,LANGUAGE,LANGUAGE
SM250765,34.02,5.76,Toposa,ETHNICITY,ETHNICITY
SM250765,34.02,5.76,Toposa,ETHNICITY,ETHNICITY
SM250765,34.02,5.76,Toposa,ETHNICITY,ETHNICITY
SM250765,34,5,Toposa,ETHNICITY,ETHNICITY
SM250765,34,5,Toposa,ETHNICITY,ETHNICITY
SM250765,34,5,Toposa,ETHNICITY,ETHNICITY
SM250765,18,6,Toposa,ETHNICITY,ETHNICITY
SM250765,18,6,Toposa,ETHNICITY,ETHNICITY
SM250765,18,6,Toposa,ETHNICITY,ETHNICITY
SM290197,28.4885,44.0313,Topraisar,ADM2,DISTRICT
SM298823,36.1199,37.0504,Toprakkale,ADM2,DISTRICT
SM305453,15.9748,45.2961,Topusko,ADM2,DISTRICT
SM13278,75.5,47.3333,Toqyrauyn Audany,ADM2,DISTRICT
SM258144,139.92,-2.67,Tor,ETHNICITY,ETHNICITY
SM258145,120.04,-1.68,Toradja,ETHNICITY,ETHNICITY
SM258145,120.04,-1.68,Toradja,ETHNICITY,ETHNICITY
SM258145,121,-2,Toradja,ETHNICITY,ETHNICITY
SM258145,121,-2,Toradja,ETHNICITY,ETHNICITY
SM17675,-61.033,13.857,Toraille,ADM2,DISTRICT
SM276585,119.7922,-2.9274,Toraja Utara,ADM2,DISTRICT
SM246919,119.829,-2.9714,Toraja-Sa'dan,LANGUAGE,LANGUAGE
SM246922,19.5254,11.2027,Toram,LANGUAGE,LANGUAGE
SM246917,155.496,-6.1992,Torau,LANGUAGE,LANGUAGE
SM3518,167.5,-13.75,Torba,ADM1,DISTRICT
SM298549,27.3718,38.176,Torbalı,ADM2,DISTRICT
SM277605,60.7833,35.4667,Torbat-e-Jam,ADM2,DISTRICT
SM274589,-3.5579,50.4516,Torbay,ADM1:ADM2,DISTRICT
SM293650,43.2844,54.0815,Torbeevskiy rayon,ADM2,DISTRICT
SM303751,-72.2104,7.6615,Torbes,ADM2,DISTRICT
SM10346,21.7125,15.9975,Torbol,ADM2,DISTRICT
SM260717,-57.25,-36.5,Tordillo,ADM2,DISTRICT
SM305549,18.7939,45.3697,Tordinci,ADM2,DISTRICT
SM299964,38.6276,48.0397,Torez'ka,ADM2,DISTRICT
SM274757,-3.0514,51.7015,Torfaen,ADM1:ADM2,DISTRICT
SM5132,72.8521,34.5587,Torghar,ADM2:ADM3,DISTRICT
SM11200,24.8896,58.4866,Tori vald,ADM2,DISTRICT
SM262147,2.143,6.538,Tori-Bossito,ADM2,DISTRICT
SM269880,-76.1667,3,Toribío,ADM2,DISTRICT
SM278398,140.0801,35.9129,Toride,ADM2,DISTRICT
SM277887,7.3667,45.1333,Torino,ADM2,DISTRICT
SM17892,32.5,4.5,Torit County,ADM2,DISTRICT
SM264923,-36.0633,-7.9982,Toritama,ADM2,DISTRICT
SM263124,-52.8812,-16.2759,Torixoréu,ADM2,DISTRICT
SM12026,54.1667,36.9,Torkaman,ADM2,DISTRICT
SM292030,21.4765,45.5214,Tormac,ADM2,DISTRICT
SM246929,23.066,67.2527,Tornedalen Finnish,LANGUAGE,LANGUAGE
SM346780,24.2883,66.6034,Tornio Valley,ADM2:ADM3,DISTRICT
SM260718,-62.25,-38.25,Tornquist,ADM2,DISTRICT
SM246931,8.5819,9.0914,Toro,LANGUAGE,LANGUAGE
SM285319,9.2206,10.4465,Toro,ADM2,DISTRICT
SM270632,-76.0796,4.6271,Toro,ADM2,DISTRICT
SM16250,-66.5199,18.2204,Toro Negro Barrio,ADM2,DISTRICT
SM246933,-3.1001,14.4075,Toro So Dogon,LANGUAGE,LANGUAGE
SM246934,-2.2243,15.0644,Toro Tegu Dogon,LANGUAGE,LANGUAGE
SM15259,1.4938,13.1208,Torodi,ADM2,DISTRICT
SM295734,-88.226,13.9137,Torola,ADM2,DISTRICT
SM258149,-68,-11.63,Toromona,ETHNICITY,ETHNICITY
SM246935,-67.3931,-13.3028,Toromono,LANGUAGE,LANGUAGE
SM246932,30.341,11.1254,Torona,LANGUAGE,LANGUAGE
SM268888,-79.4291,43.6966,Toronto,ADM2,DISTRICT
SM294779,31.5,56.5833,Toropetskiy rayon,ADM2,DISTRICT
SM265964,-54.2931,-29.4787,Toropi,ADM2,DISTRICT
SM4717,34.0833,0.75,Tororo (2005-),ADM1:ADM2,DISTRICT
SM18107,34.603,36.8708,Toroslar,ADM2,DISTRICT
SM301858,-105.8508,34.6405,Torrance,ADM2,DISTRICT
SM16251,-66.9195,18.0691,Torre Barrio,ADM2,DISTRICT
SM289458,-7.0358,41.1792,Torre de Moncorvo,ADM2,DISTRICT
SM267002,-48.2133,-23.2464,Torre de Pedra,ADM2,DISTRICT
SM16253,-65.9185,18.392,Torrecilla Alta Barrio (Canóvanas),ADM2,DISTRICT
SM16252,-65.9148,18.4189,Torrecilla Alta Barrio (Loiz),ADM2,DISTRICT
SM16254,-65.9328,18.4442,Torrecilla Baja Barrio,ADM2,DISTRICT
SM16255,-66.4431,18.3321,Torrecillas Barrio,ADM2,DISTRICT
SM303610,-70.25,10.1667,Torres,ADM2,DISTRICT
SM265965,-49.8198,-29.2967,Torres,ADM2,DISTRICT
SM261670,142.2206,-10.5793,Torres,ADM2,DISTRICT
SM289330,-8.548,39.4916,Torres Novas,ADM2,DISTRICT
SM246940,142.692,-11.066,Torres Strait Creole,LANGUAGE,LANGUAGE
SM261671,142.273,-10.2014,Torres Strait Island,ADM2,DISTRICT
SM289264,-9.2524,39.1022,Torres Vedras,ADM2,DISTRICT
SM283305,-103.3527,25.5047,Torreón,ADM2,DISTRICT
SM267003,-48.1534,-22.4714,Torrinha,ADM2,DISTRICT
SM296510,12.9384,60.5694,Torsby,ADM2,DISTRICT
SM296655,15.8913,56.4265,Torsås,ADM2,DISTRICT
SM258150,-77.73,7.91,Tortado,ETHNICITY,ETHNICITY
SM3520,-2.6489,49.43,Torteval,ADM1,DISTRICT
SM16256,-66.0914,18.3426,Tortugo Barrio,ADM2,DISTRICT
SM298397,41.5508,40.297,Tortum,ADM2,DISTRICT
SM298450,39.2967,40.5603,Torul,ADM2,DISTRICT
SM288853,18.8333,52.9826,Toruń,ADM2,DISTRICT
SM288852,18.6015,53.0145,Toruń (City),ADM2,DISTRICT
SM11201,25.8874,57.9842,Torva vald,ADM2,DISTRICT
SM246943,72.5316,35.3101,Torwali,LANGUAGE,LANGUAGE
SM17111,35,57.0833,Torzhokskiy Rayon,ADM2,DISTRICT
SM246921,-61.7416,-6.1953,Torá,LANGUAGE,LANGUAGE
SM278605,133.3912,33.4773,Tosa City,ADM2,DISTRICT
SM13109,133.4627,33.7586,Tosa-gun,ADM2,DISTRICT
SM273505,-80.2783,-0.7793,Tosagua,ADM2,DISTRICT
SM278607,132.8928,32.8286,Tosashimizu,ADM2,DISTRICT
SM3522,11,43.4167,Toscana,ADM1,DISTRICT
SM279474,139.7154,35.7325,Toshima,ADM2,DISTRICT
SM303448,69.2,41.4,Toshkent,ADM2,DISTRICT
SM303475,71.8333,40.5,Toshloq,ADM2,DISTRICT
SM17112,31,59.4167,Tosnenskiy Rayon,ADM2,DISTRICT
SM283833,98.2736,48.7495,Tosontsengel,ADM2,DISTRICT
SM283605,100.8892,49.4706,Tosontsengel,ADM2,DISTRICT
SM258151,-70.35,9.67,Tosto,ETHNICITY,ETHNICITY
SM246947,102.0833,28.5333,Tosu,LANGUAGE,LANGUAGE
SM279142,130.49,33.3783,Tosu,ADM2,DISTRICT
SM298600,34.0444,41.0264,Tosya,ADM2,DISTRICT
SM270733,-73.0065,5.5023,Tota,ADM2,DISTRICT
SM281747,-103.476,21.9405,Totatiche,ADM2,DISTRICT
SM246949,24.3751,-16.9575,Totela,LANGUAGE,LANGUAGE
SM250766,25.09,-16.21,Toteli,ETHNICITY,ETHNICITY
SM294949,42.5,59.75,Totemskiy rayon,ADM2,DISTRICT
SM290793,22.8902,45.5714,Totesti,ADM2,DISTRICT
SM246952,88.975,26.67,Toto,LANGUAGE,LANGUAGE
SM285012,7.1983,8.2502,Toto,ADM2,DISTRICT
SM14118,-9.3333,5.3333,Toto,ADM2,DISTRICT
SM285432,-86.4928,13.5609,Totogalpa,ADM2,DISTRICT
SM282710,-98.2435,19.332,Totolac,ADM2,DISTRICT
SM283209,-92.6736,16.517,Totolapa,ADM2,DISTRICT
SM282118,-98.9208,18.9928,Totolapan,ADM2,DISTRICT
SM246954,120.875,1.15,Totoli,LANGUAGE,LANGUAGE
SM282331,-97.8471,18.2412,Totoltepec de Guerrero,ADM2,DISTRICT
SM246958,-97.0824,16.8554,Totomachapan Zapotec,LANGUAGE,LANGUAGE
SM258155,-96.54,19.19,Totonac,ETHNICITY,ETHNICITY
SM258155,-96.54,19.19,Totonac,ETHNICITY,ETHNICITY
SM258155,-97,20,Totonac,ETHNICITY,ETHNICITY
SM258155,-97,20,Totonac,ETHNICITY,ETHNICITY
SM275378,-91.32,14.9019,Totonicapán,ADM2,DISTRICT
SM3523,-91.3333,15,Totonicapán,ADM1,DISTRICT
SM246955,-96.0179,17.2157,Totontepec Mixe,LANGUAGE,LANGUAGE
SM14886,-96.0089,17.2468,Totontepec Villa de Morelos,ADM2,DISTRICT
SM261138,-63.9167,-30.8333,Totoral,ADM2,DISTRICT
SM246956,-76.6379,2.5515,Totoro,LANGUAGE,LANGUAGE
SM269881,-76.3925,2.5052,Totoró,ADM2,DISTRICT
SM246957,-98.5829,17.4324,Tototepec,DIALECT,LANGUAGE
SM281748,-102.7392,20.5395,Tototlán,ADM2,DISTRICT
SM279484,134.1558,35.4326,Tottori,ADM2,DISTRICT
SM3524,133.85,35.4,Tottori,ADM1:ADMX,DISTRICT
SM282825,-96.9393,19.2328,Totutla,ADM2,DISTRICT
SM17676,-60.9473,13.8218,Tou Cochan/Ti Rocher,ADM2,DISTRICT
SM251009,11.46,28.02,Touareg,ETHNICITY,ETHNICITY
SM251009,11.46,28.02,Touareg,ETHNICITY,ETHNICITY
SM251009,-0.2,24.54,Touareg,ETHNICITY,ETHNICITY
SM251009,-0.2,24.54,Touareg,ETHNICITY,ETHNICITY
SM251009,1.42,22.27,Touareg,ETHNICITY,ETHNICITY
SM251009,1.42,22.27,Touareg,ETHNICITY,ETHNICITY
SM251009,6,23,Touareg,ETHNICITY,ETHNICITY
SM251009,6,23,Touareg,ETHNICITY,ETHNICITY
SM251010,15.21,21.33,Toubou,ETHNICITY,ETHNICITY
SM250642,-13.43,17.79,Toucouleur,ETHNICITY,ETHNICITY
SM250642,-13.43,17.79,Toucouleur,ETHNICITY,ETHNICITY
SM250642,-13.43,17.79,Toucouleur,ETHNICITY,ETHNICITY
SM250642,-14,17,Toucouleur,ETHNICITY,ETHNICITY
SM250642,-14,17,Toucouleur,ETHNICITY,ETHNICITY
SM250642,-14,17,Toucouleur,ETHNICITY,ETHNICITY
SM250642,-14.32,14.73,Toucouleur,ETHNICITY,ETHNICITY
SM250642,-14.32,14.73,Toucouleur,ETHNICITY,ETHNICITY
SM250642,-14.32,14.73,Toucouleur,ETHNICITY,ETHNICITY
SM262140,1.4553,10.5142,Toucountouna,ADM2,DISTRICT
SM457771,5.75,33.5,Touggourt District,ADM2,DISTRICT
SM274997,-11.6,11.4667,Tougué,ADM1:ADM2,DISTRICT
SM284571,165.2423,-20.786,Touho,ADM2,DISTRICT
SM14201,-15.9031,18.0717,Toujounine,ADM2,DISTRICT
SM305695,15.3261,45.2523,Tounj,ADM2,DISTRICT
SM246965,157.296,-8.5841,Touo,LANGUAGE,LANGUAGE
SM258156,15,10,Toupouri,ETHNICITY,ETHNICITY
SM258156,15,10,Toupouri,ETHNICITY,ETHNICITY
SM258156,14.8,10.3,Toupouri,ETHNICITY,ETHNICITY
SM258156,14.8,10.3,Toupouri,ETHNICITY,ETHNICITY
SM258157,-5.61,8.92,Toura,ETHNICITY,ETHNICITY
SM258157,-5.61,8.92,Toura,ETHNICITY,ETHNICITY
SM258157,-7,8,Toura,ETHNICITY,ETHNICITY
SM258157,-7,8,Toura,ETHNICITY,ETHNICITY
SM246966,-7.3311,7.7675,Toura (Côte d'Ivoire),LANGUAGE,LANGUAGE
SM246967,146.988,-9.1001,Toura (Papua New Guinea),LANGUAGE,LANGUAGE
SM17677,-60.9977,13.9735,Tourat/Soucis,ADM2,DISTRICT
SM265506,-35.6046,-5.2611,Touros,ADM2,DISTRICT
SM305810,44.6314,34.8415,Touz Hourmato,ADM2,DISTRICT
SM303830,-67.2916,10.4035,Tovar,ADM2,DISTRICT
SM303625,-71.7,8.3667,Tovar,ADM2,DISTRICT
SM305550,19.1521,45.1638,Tovarnik,ADM2,DISTRICT
SM262172,1.8074,6.888,Toviklin,ADM2,DISTRICT
SM261918,45.75,41,Tovuz,ADM1:ADM2,DISTRICT
SM233315,-106.766,35.5994,Towa,LANGUAGE,LANGUAGE
SM278991,141.0579,40.5674,Towada,ADM2,DISTRICT
SM246970,140.803,-3.8743,Towei,LANGUAGE,LANGUAGE
SM12789,-76.8281,18.0118,Tower Hill,ADM2,DISTRICT
SM12790,-77.9602,18.4404,Tower Hill/Moy Hall,ADM2,DISTRICT
SM17678,-61.0576,13.8549,Town (Soufrière),ADM2,DISTRICT
SM17679,-60.9541,13.7284,Town (Vieux-Fort),ADM2,DISTRICT
SM12791,-78.1357,18.339,Town Head,ADM2,DISTRICT
SM302072,-99.2458,48.6855,Towner,ADM2,DISTRICT
SM300424,-83.7373,34.9166,Towns,ADM2,DISTRICT
SM261672,146.6322,-19.352,Townsville,ADM2,DISTRICT
SM261280,147.6272,-36.3659,Towong,ADM2,DISTRICT
SM279502,137.2987,36.5515,Toyama,ADM2,DISTRICT
SM3526,137.2651,36.6393,Toyama,ADM1:ADMX,DISTRICT
SM18454,67.1587,39.563,Toyloq District,ADM2,DISTRICT
SM18455,67.1167,39.5833,Toyloq Tumani,ADM2,DISTRICT
SM278073,137.0089,35.0625,Toyoake,ADM2,DISTRICT
SM278074,137.4071,34.7405,Toyohashi,ADM2,DISTRICT
SM13111,137.3618,34.8503,Toyokawa-shi (2010-),ADM2,DISTRICT
SM279125,135.4728,34.7819,Toyonaka,ADM2,DISTRICT
SM13112,135.4312,34.9632,Toyono Gun,ADM2,DISTRICT
SM278360,134.8272,35.5138,Toyooka,ADM2,DISTRICT
SM278078,137.15,35.087,Toyota,ADM2,DISTRICT
SM278079,136.9163,35.2516,Toyoyama,ADM2,DISTRICT
SM298013,8.1035,33.9424,Tozeur,ADM2,DISTRICT
SM3527,8.0833,34,Tozeur,ADM1,DISTRICT
SM267004,-48.3686,-22.0302,Trabiju,ADM2,DISTRICT
SM3529,39.8126,40.8695,Trabzon,ADM1,DISTRICT
SM11072,33.3504,35.208,Trachonas,ADM2,DISTRICT
SM11073,33.4795,35.2147,Trachoni,ADM2,DISTRICT
SM11074,32.6773,34.7981,Trachypedoula,ADM2,DISTRICT
SM264118,-46.9397,-0.9889,Tracuateua,ADM2,DISTRICT
SM264924,-35.1548,-7.7246,Tracunhaém,ADM2,DISTRICT
SM232363,146.2346,-8.3166,Trading Eleman,LANGUAGE,LANGUAGE
SM232364,144.912,-7.7751,Trading Koriki,LANGUAGE,LANGUAGE
SM12792,-76.7847,18.0128,Trafalgar Park,ADM2,DISTRICT
SM274590,-2.3333,53.4167,Trafford,ADM1:ADM2,DISTRICT
SM291942,25.0049,43.7672,Traian,ADM2,DISTRICT
SM291473,24.4559,44.0234,Traian,ADM2,DISTRICT
SM292698,27.6917,45.1536,Traian,ADM2,DISTRICT
SM302073,-97.1616,47.4542,Traill,ADM2,DISTRICT
SM265437,-36.9785,-9.8924,Traipu,ADM2,DISTRICT
SM267864,-39.3803,-3.3316,Trairi,ADM2,DISTRICT
SM264119,-55.9582,-5.1025,Trairão,ADM2,DISTRICT
SM265330,-42.1883,-22.152,Trajano de Morais,ADM2,DISTRICT
SM14154,24.9333,54.6333,Trakai (2000-),ADM2,DISTRICT
SM297704,105.0644,15.6136,Trakan Phutphon,ADM2,DISTRICT
SM258158,146.92,-41.73,Tralakumbina,ETHNICITY,ETHNICITY
SM280458,104.6082,11.01,Tram Kak,ADM2,DISTRICT
SM265966,-50.2302,-30.0398,Tramandaí,ADM2,DISTRICT
SM262328,22.6333,42.7667,Tran,ADM2,DISTRICT
SM261072,-65.5,-26.4167,Trancas,ADM2,DISTRICT
SM289232,-7.3254,40.7918,Trancoso,ADM2,DISTRICT
SM14887,-102.32,22.7552,Trancoso,ADM2,DISTRICT
SM296606,13.4333,57.5,Tranemo,ADM2,DISTRICT
SM3531,99.5981,7.5072,Trang,ADM1,DISTRICT
SM12793,-76.6815,18.1803,Tranquility,ADM2,DISTRICT
SM3532,34.979,1.045,Trans Nzoia,ADM1:ADM2,DISTRICT
SM246981,3.3,43.97,Transalpine Gaulish,LANGUAGE,LANGUAGE
SM3533,22.2887,48.6265,Transcarpathia,ADM1,DISTRICT
SM3534,29.25,47.3667,Transnistria,ADM1:ADMX,DISTRICT
SM246991,-95.1166,16.8333,Transyautepecan,FAMILY,LANGUAGE
SM302017,-82.7983,35.2022,Transylvania,ADM2,DISTRICT
SM296652,14.8346,58.0291,Tranås,ADM2,DISTRICT
SM277906,12.6667,37.8333,Trapani,ADM2,DISTRICT
SM13874,26.5909,57.4509,Trapenes pagasts,ADM2,DISTRICT
SM11075,33.4751,35.302,Trapeza,ADM2,DISTRICT
SM3535,-14.6588,17.8665,Trarza,ADM1,DISTRICT
SM250289,-15,18,Trarza,ETHNICITY,ETHNICITY
SM3536,91.75,27.25,Trashigang,ADM1,DISTRICT
SM3537,102.5,12.5,Trat,ADM1,DISTRICT
SM246996,12.059,55.5605,Traveller Danish,LANGUAGE,LANGUAGE
SM246995,9.4891,62.6217,Traveller Norwegian,LANGUAGE,LANGUAGE
SM246994,-4.3859,55.9829,Traveller Scottish,LANGUAGE,LANGUAGE
SM301414,-96.4716,45.7722,Traverse,ADM2,DISTRICT
SM265967,-52.0997,-29.2881,Travesseiro,ADM2,DISTRICT
SM302781,-97.782,30.3347,Travis,ADM2,DISTRICT
SM261320,117.8381,-31.1668,Trayning,ADM2,DISTRICT
SM296245,15.0422,46.1417,Trbovlje,ADM1:ADM2,DISTRICT
SM12794,-77.2553,17.9926,Treadlight,ADM2,DISTRICT
SM280450,104.8051,10.8813,Treang,ADM2,DISTRICT
SM301669,-107.2717,46.2115,Treasure,ADM2,DISTRICT
SM12795,-77.7605,17.8955,Treasure Beach,ADM2,DISTRICT
SM313234,18.3122,42.7342,Trebinje,ADM2:ADM3,DISTRICT
SM296136,21.7167,48.6333,Trebišov,ADM2,DISTRICT
SM4138,14.9877,45.9109,Trebnje (2011-),ADM1,DISTRICT
SM246998,11.123,45.654,Tredici Communi Cimbrian,DIALECT,LANGUAGE
SM246999,70.9617,34.7548,Tregami,LANGUAGE,LANGUAGE
SM246999,70.9617,34.7548,Tregami,LANGUAGE,LANGUAGE
SM246999,71.0998,34.9981,Tregami,LANGUAGE,LANGUAGE
SM246999,71.0998,34.9981,Tregami,LANGUAGE,LANGUAGE
SM300986,-99.8727,38.9143,Trego,ADM2,DISTRICT
SM3538,-54.25,-33,Treinta y Tres,ADM1,DISTRICT
SM11076,32.7953,34.9333,Treis Elies,ADM2,DISTRICT
SM262542,22.6,42.55,Treklyano,ADM2,DISTRICT
SM3539,-77.6333,18.3833,Trelawny,ADM1,DISTRICT
SM296452,13.2686,55.4127,Trelleborg,ADM2,DISTRICT
SM267690,-41.3234,-14.9008,Tremedal,ADM2,DISTRICT
SM247001,-40.204,-3.0936,Tremembé,LANGUAGE,LANGUAGE
SM267005,-45.615,-22.9241,Tremembé,ADM2,DISTRICT
SM11077,33.6058,35.084,Tremetousia,ADM2,DISTRICT
SM11078,32.4451,34.8212,Tremithousa,ADM2,DISTRICT
SM303281,-91.3585,44.304,Trempealeau,ADM2,DISTRICT
SM14119,-7.8736,4.7018,Trenbo (excl. Webado),ADM2,DISTRICT
SM12796,-76.8012,17.9874,Trench Town,ADM2,DISTRICT
SM260853,-64.25,-35.6667,Trenel,ADM2,DISTRICT
SM276368,111.6167,-8.1667,Trenggalek,ADM2,DISTRICT
SM3541,103,5,Trengganu,ADM1,DISTRICT
SM260720,-62.6667,-36,Trenque Lauquen,ADM2,DISTRICT
SM3542,11.3333,46.5,Trentino-Alto Adige,ADM1,DISTRICT
SM277918,11.1218,46.0697,Trento,ADM2,DISTRICT
SM3540,18.25,48.8333,Trenčiansky,ADM1,DISTRICT
SM296173,18.0426,48.8952,Trenčín,ADM2,DISTRICT
SM260721,-60.25,-38.5,Tres Arroyos,ADM2,DISTRICT
SM10036,-50.2714,-26.1651,Tres Barras,ADM2,DISTRICT
SM260723,-62.8625,-36.4528,Tres Lomas,ADM2,DISTRICT
SM14888,-96.1709,18.3051,Tres Valles,ADM2,DISTRICT
SM260722,-58.5661,-34.5999,Tres de Febrero,ADM2,DISTRICT
SM293006,82.3333,50.8333,Tret'yakovskiy rayon,ADM2,DISTRICT
SM300426,-82.5673,32.4039,Treutlen,ADM2,DISTRICT
SM277931,12.2167,45.8333,Treviso,ADM2,DISTRICT
SM266331,-49.5036,-28.4994,Treviso,ADM2,DISTRICT
SM266333,-51.4318,-26.9573,Treze Tílias,ADM2,DISTRICT
SM266332,-49.145,-28.5392,Treze de Maio,ADM2,DISTRICT
SM303914,104.9614,10.4142,Tri Tôn,ADM2,DISTRICT
SM10539,15.7474,43.7564,Tribunj,ADM2,DISTRICT
SM247007,107.515,15.5002,Trieng,LANGUAGE,LANGUAGE
SM3543,9.5552,47.0838,Triesen,ADM1,DISTRICT
SM3544,9.5596,47.1266,Triesenberg,ADM1,DISTRICT
SM277958,13.7612,45.713,Trieste,ADM2,DISTRICT
SM291378,26.8331,46.9091,Trifesti,ADM2,DISTRICT
SM301002,-87.8734,36.8063,Trigg,ADM2,DISTRICT
SM11505,21.5,39.6667,Trikala,ADM2,DISTRICT
SM13875,25.6986,57.5466,Trikatas pagasts,ADM2,DISTRICT
SM11079,33.8928,35.2911,Trikomo,ADM2,DISTRICT
SM305491,16.7235,43.6177,Trilj,ADM2,DISTRICT
SM301003,-85.3376,38.613,Trimble,ADM2,DISTRICT
SM11080,32.9127,34.851,Trimiklini,ADM2,DISTRICT
SM11081,33.2569,35.336,Trimithi,ADM2,DISTRICT
SM11082,32.4846,34.9751,Trimithousa,ADM2,DISTRICT
SM247010,138.027,-2.1586,Trimuris,LANGUAGE,LANGUAGE
SM282610,-111.5131,30.3889,Trincheras,ADM2,DISTRICT
SM3545,81.0833,8.5833,Trincomalee,ADM1:ADM2,DISTRICT
SM264925,-40.3341,-7.7833,Trindade,ADM2,DISTRICT
SM268187,-49.5677,-16.6536,Trindade,ADM2,DISTRICT
SM265976,-52.9135,-27.5268,Trindade do Sul,ADM2,DISTRICT
SM247016,110.036,1.335,Tring,LANGUAGE,LANGUAGE
SM247014,109.95,1.4313,Tringgus-Sembaan Bidayuh,LANGUAGE,LANGUAGE
SM289583,-55.7309,-27.1344,Trinidad,ADM2,DISTRICT
SM275874,-88.7713,14.9492,Trinidad,ADM2,DISTRICT
SM271044,-79.8836,21.832,Trinidad,ADM2,DISTRICT
SM270887,-71.2019,5.3478,Trinidad,ADM2,DISTRICT
SM283080,-103.4933,21.2224,Trinidad García de la Cadena,ADM2,DISTRICT
SM14889,-96.7746,16.9227,Trinidad Zaachila,ADM2,DISTRICT
SM247019,-61.5167,10.6667,Trinidad and Tobago Sign Language,LANGUAGE,LANGUAGE
SM275785,-88.27,15.14,Trinidad de Copán,ADM2,DISTRICT
SM247018,-61.4171,10.4208,Trinidadian Creole English,LANGUAGE,LANGUAGE
SM247017,-65.4096,-15.3331,Trinitario-Javeriano-Loretano,LANGUAGE,LANGUAGE
SM302782,-95.1355,31.0888,Trinity,ADM2,DISTRICT
SM303211,-123.1126,40.6507,Trinity,ADM2,DISTRICT
SM3546,-2.09,49.2347,Trinity,ADM1,DISTRICT
SM3547,-62.7637,17.3067,Trinity Palmetto Point,ADM1,DISTRICT
SM12797,-76.4942,17.9572,Trinityville,ADM2,DISTRICT
SM3548,13.4,32.5667,Tripoli,ADM1,DISTRICT
SM13975,35.85,34.43,Tripoli City,ADM2,DISTRICT
SM302535,-99.884,43.3459,Tripp,ADM2,DISTRICT
SM3549,92,24,Tripura,ADM1,DISTRICT
SM3550,-12.2834,-37.1128,Tristan da Cunha,ADM1,DISTRICT
SM247026,-12.311,-37.068,Tristan da Cunha English,DIALECT,LANGUAGE
SM258166,-12.27,-37.11,Tristan de Cunha,ETHNICITY,ETHNICITY
SM290137,24.0014,46.5874,Tritenii De Jos,ADM2,DISTRICT
SM265977,-51.5603,-29.8225,Triunfo,ADM2,DISTRICT
SM264926,-38.0518,-7.8477,Triunfo,ADM2,DISTRICT
SM264352,-38.5783,-6.5848,Triunfo,ADM2,DISTRICT
SM265507,-37.1399,-5.9436,Triunfo Potiguar,ADM2,DISTRICT
SM291943,25.2408,44.262,Trivalea-mosteni,ADM2,DISTRICT
SM262921,-44.6175,-4.5222,Trizidela do Vale,ADM2,DISTRICT
SM247021,-55.7599,2.5362,Trió,LANGUAGE,LANGUAGE
SM304447,107.1603,16.7813,Triệu Phong,ADM2,DISTRICT
SM304523,105.5829,19.8258,Triệu Sơn,ADM2,DISTRICT
SM296180,17.6,48.3667,Trnava,ADM2,DISTRICT
SM305387,18.2534,45.2586,Trnava,ADM2,DISTRICT
SM3551,17.6667,48.4167,Trnavský,ADM1,DISTRICT
SM305512,16.3997,46.2939,Trnovec Bartolovečki,ADM2,DISTRICT
SM313227,18.4264,43.7406,Trnovo,ADM2:ADM3,DISTRICT
SM17844,14.4889,46.0241,Trnovo District,ADM2,DISTRICT
SM296348,15.9044,46.5178,Trnovska Vas,ADM1:ADM2,DISTRICT
SM258168,151.11,-8.5,Trobriand,ETHNICITY,ETHNICITY
SM258168,151.11,-8.5,Trobriand,ETHNICITY,ETHNICITY
SM258168,151.11,-8.5,Trobriand,ETHNICITY,ETHNICITY
SM258168,151.09,-8.43,Trobriand,ETHNICITY,ETHNICITY
SM258168,151.09,-8.43,Trobriand,ETHNICITY,ETHNICITY
SM258168,151.09,-8.43,Trobriand,ETHNICITY,ETHNICITY
SM258168,151.01,-8.54,Trobriand,ETHNICITY,ETHNICITY
SM258168,151.01,-8.54,Trobriand,ETHNICITY,ETHNICITY
SM258168,151.01,-8.54,Trobriand,ETHNICITY,ETHNICITY
SM323890,-113.2295,51.8272,Trochu,ADM2:ADM3,DISTRICT
SM289306,-8.5764,41.3065,Trofa,ADM2,DISTRICT
SM276004,16.2514,43.5206,Trogir,ADM2,DISTRICT
SM291945,25.024,44.0042,Troianul,ADM2,DISTRICT
SM17680,-60.9726,13.9889,Trois Piton,ADM2,DISTRICT
SM10347,15.052,12.1006,Troisieme Arrondissement,ADM2,DISTRICT
SM370429,6.002,50.1438,Troisvierges,ADM1:ADM2:ADM3,DISTRICT
SM17113,61.5,54.1667,Troitskiy Rayon,ADM2,DISTRICT
SM293007,84.5833,53,Troitskiy rayon,ADM2,DISTRICT
SM293308,56.25,62.6667,Troitsko-Pechorskiy rayon,ADM2,DISTRICT
SM17114,37.2776,55.4876,Troitsky Administrative Okrug (2012-),ADM2,DISTRICT
SM12798,-76.932,18.2049,Troja,ADM2,DISTRICT
SM275902,-86.0017,14.0597,Trojes,ADM2,DISTRICT
SM296607,12.3503,58.2121,Trollhättan,ADM2,DISTRICT
SM268189,-48.7729,-13.4041,Trombas,ADM2,DISTRICT
SM266334,-49.8125,-27.308,Trombudo Central,ADM2,DISTRICT
SM11283,54.5238,-15.8933,Tromelin Island,ADM2,DISTRICT
SM4183,23.7123,69.7243,Troms og Finnmark (2020-2023),ADM1,DISTRICT
SM286240,18.957,69.6496,Tromsø,ADM2,DISTRICT
SM297749,100.1347,17.4723,Tron,ADM2,DISTRICT
SM4182,11.58,64.0248,Trondelag (2020-),ADM1,DISTRICT
SM461885,10.3969,63.4281,Trondheim (2020-),ADM2,DISTRICT
SM268451,90.6781,27.1094,Trong,ADM2,DISTRICT
SM3553,90.5333,27.35,Trongsa,ADM1,DISTRICT
SM260499,20.1628,42.3995,Tropojës,ADM2,DISTRICT
SM260499,20.1628,42.3995,Tropojës,ADM2,DISTRICT
SM260499,20.0674,42.3606,Tropojës,ADM2,DISTRICT
SM260499,20.0674,42.3606,Tropojës,ADM2,DISTRICT
SM296468,17.4985,58.8933,Trosa,ADM2,DISTRICT
SM299783,29.2193,48.5137,Trostianets'kyi,ADM2,DISTRICT
SM17681,-60.962,13.9422,Trou Cochan/Marc,ADM2,DISTRICT
SM17682,-60.9538,13.9453,Trou Florent/Marc,ADM2,DISTRICT
SM17683,-60.9895,14.006,Trou Rouge,ADM2,DISTRICT
SM11083,33.6223,35.0259,Troulloi,ADM2,DISTRICT
SM17684,-60.9034,13.8089,Troumassee,ADM2,DISTRICT
SM300427,-85.0283,33.0335,Troup,ADM2,DISTRICT
SM302627,-86.1568,36.392,Trousdale,ADM2,DISTRICT
SM12799,-77.333,18.1378,Trout Hall,ADM2,DISTRICT
SM12800,-77.6098,18.2492,Troy,ADM2,DISTRICT
SM262548,24.7167,42.8833,Troyan,ADM2,DISTRICT
SM305641,17.2709,43.0078,Trpanj,ADM2,DISTRICT
SM305551,18.8995,45.4185,Trpinja,ADM2,DISTRICT
SM295023,33.75,52.5833,Trubchevskiy rayon,ADM2,DISTRICT
SM303769,-70.4167,9.5,Trujillo,ADM2,DISTRICT
SM286755,-78.9167,-8.0833,Trujillo,ADM2,DISTRICT
SM275830,-85.9464,15.8263,Trujillo,ADM2,DISTRICT
SM270633,-76.3473,4.237,Trujillo,ADM2,DISTRICT
SM3554,-70.5089,9.4386,Trujillo,ADM1,DISTRICT
SM3555,-66.0052,18.3416,Trujillo Alto,ADM1,DISTRICT
SM16257,-66.0071,18.3544,Trujillo Alto Barrio-Pueblo,ADM2,DISTRICT
SM16258,-65.9478,18.3638,Trujillo Bajo Barrio,ADM2,DISTRICT
SM258169,151.62,7.31,Trukese,ETHNICITY,ETHNICITY
SM258169,151.62,7.31,Trukese,ETHNICITY,ETHNICITY
SM258169,151.62,7.31,Trukese,ETHNICITY,ETHNICITY
SM258169,151.669,7.411,Trukese,ETHNICITY,ETHNICITY
SM258169,151.669,7.411,Trukese,ETHNICITY,ETHNICITY
SM258169,151.669,7.411,Trukese,ETHNICITY,ETHNICITY
SM258169,151.6,7.34,Trukese,ETHNICITY,ETHNICITY
SM258169,151.6,7.34,Trukese,ETHNICITY,ETHNICITY
SM258169,151.6,7.34,Trukese,ETHNICITY,ETHNICITY
SM247030,-39,-8.5,Truká,LANGUAGE,LANGUAGE
SM247032,-53.5717,-11.8119,Trumai,LANGUAGE,LANGUAGE
SM258170,-51.51,-10.76,Trumai,ETHNICITY,ETHNICITY
SM258170,-51.51,-10.76,Trumai,ETHNICITY,ETHNICITY
SM258170,-51.51,-10.76,Trumai,ETHNICITY,ETHNICITY
SM258170,-53,-12,Trumai,ETHNICITY,ETHNICITY
SM258170,-53,-12,Trumai,ETHNICITY,ETHNICITY
SM258170,-53,-12,Trumai,ETHNICITY,ETHNICITY
SM258170,-53.67,-11.83,Trumai,ETHNICITY,ETHNICITY
SM258170,-53.67,-11.83,Trumai,ETHNICITY,ETHNICITY
SM258170,-53.67,-11.83,Trumai,ETHNICITY,ETHNICITY
SM302159,-80.7612,41.3172,Trumbull,ADM2,DISTRICT
SM12801,-78.1549,18.313,Truro,ADM2,DISTRICT
SM292605,26.9891,47.7806,Trusesti,ADM2,DISTRICT
SM271225,15.8333,50.5833,Trutnov,ADM2,DISTRICT
SM262513,25.55,42.85,Tryavna,ADM2,DISTRICT
SM11084,33.6379,35.305,Trypimeni,ADM2,DISTRICT
SM286367,12.2642,61.3155,Trysil,ADM2,DISTRICT
SM288664,17.0499,51.372,Trzebnica,ADM2,DISTRICT
SM296313,14.5526,46.1253,Trzin,ADM1:ADM2,DISTRICT
SM304406,108.5263,15.2375,Trà Bồng,ADM2,DISTRICT
SM304551,106.2942,9.6971,Trà Cú,ADM2,DISTRICT
SM303965,106.3254,22.8096,Trà Lĩnh,ADM2,DISTRICT
SM304552,106.3352,9.9513,Trà Vinh,ADM2,DISTRICT
SM3528,106.3,9.8,Trà Vinh,ADM1,DISTRICT
SM304574,106.0096,9.9752,Trà Ôn,ADM2,DISTRICT
SM304255,106.4833,22.2772,Tràng Định,ADM2,DISTRICT
SM286087,12.0965,66.5016,Træna,ADM2,DISTRICT
SM265968,-52.1837,-27.4822,Três Arroios,ADM2,DISTRICT
SM264759,-53.2315,-25.4222,Três Barras do Paraná,ADM2,DISTRICT
SM265969,-49.9849,-29.4753,Três Cachoeiras,ADM2,DISTRICT
SM264020,-45.2142,-21.7026,Três Corações,ADM2,DISTRICT
SM265970,-50.7768,-29.466,Três Coroas,ADM2,DISTRICT
SM265972,-50.0834,-29.4325,Três Forquilhas,ADM2,DISTRICT
SM267006,-50.8703,-20.2909,Três Fronteiras,ADM2,DISTRICT
SM263094,-52.2596,-20.3796,Três Lagoas,ADM2,DISTRICT
SM264021,-45.0256,-18.2889,Três Marias,ADM2,DISTRICT
SM265974,-52.8664,-27.6128,Três Palmeiras,ADM2,DISTRICT
SM265975,-53.9224,-27.4308,Três Passos,ADM2,DISTRICT
SM264022,-45.4944,-21.4052,Três Pontas,ADM2,DISTRICT
SM268186,-47.7985,-18.3684,Três Ranchos,ADM2,DISTRICT
SM265331,-43.0662,-22.1212,Três Rios,ADM2,DISTRICT
SM265971,-54.2602,-27.7345,Três de Maio,ADM2,DISTRICT
SM303966,106.5607,22.8333,Trùng Khánh,ADM2,DISTRICT
SM296197,14.3236,46.3842,Tržič,ADM1:ADM2,DISTRICT
SM304589,104.4276,21.4855,Trạm Tấu,ADM2,DISTRICT
SM304008,107.0361,10.9662,Trảng Bom,ADM2,DISTRICT
SM304479,106.3754,11.0572,Trảng Bàng,ADM2,DISTRICT
SM304590,104.797,21.6659,Trấn Yên,ADM2,DISTRICT
SM303960,104.9233,9.1266,Trần Văn Thời,ADM2,DISTRICT
SM304449,106.0939,9.4952,Trần Đề,ADM2,DISTRICT
SM304315,106.2458,20.2569,Trực Ninh,ADM2,DISTRICT
SM11347,42.0667,42.6667,Ts'alenjikhis Munitsip'alit'et'I (excl. Tsalenjikha),ADM2,DISTRICT
SM11348,43.9667,41.6333,Ts'alk'is Munitsip'alit'et'I (excl. Tsalka),ADM2,DISTRICT
SM247068,23.9982,-19.181,Ts'ixa,LANGUAGE,LANGUAGE
SM247084,102.926,21.3616,Ts'ün-Lao,LANGUAGE,LANGUAGE
SM247036,12.7581,-2.2925,Tsaangi,LANGUAGE,LANGUAGE
SM11085,32.4844,34.8322,Tsada,ADM2,DISTRICT
SM285279,6.8947,11.8825,Tsafe,ADM2,DISTRICT
SM229041,-79.2347,-0.2118,Tsafiki,LANGUAGE,LANGUAGE
SM283788,113.1252,48.44,Tsagaan-Ovoo,ADM2,DISTRICT
SM283788,113.1252,48.44,Tsagaan-Ovoo,ADM2,DISTRICT
SM283788,113.4332,48.395,Tsagaan-Ovoo,ADM2,DISTRICT
SM283788,113.4332,48.395,Tsagaan-Ovoo,ADM2,DISTRICT
SM283606,98.6931,49.5922,Tsagaan-Uul,ADM2,DISTRICT
SM283606,98.6931,49.5922,Tsagaan-Uul,ADM2,DISTRICT
SM283606,98.6026,49.554,Tsagaan-Uul,ADM2,DISTRICT
SM283606,98.6026,49.554,Tsagaan-Uul,ADM2,DISTRICT
SM283608,101.513,50.5353,Tsagaan-Üür,ADM2,DISTRICT
SM283834,96.6713,47.1038,Tsagaanchuluut,ADM2,DISTRICT
SM283817,107.6509,46.4061,Tsagaandelger,ADM2,DISTRICT
SM283730,94.2442,49.399,Tsagaanhairhan,ADM2,DISTRICT
SM283835,96.8031,47.4927,Tsagaankhairkhan,ADM2,DISTRICT
SM283609,99.3452,51.3392,Tsagaannuur,ADM2,DISTRICT
SM283660,105.4186,50.0928,Tsagaannuur,ADM2,DISTRICT
SM283687,89.7326,49.5048,Tsagaannuur,ADM2,DISTRICT
SM11349,42.77,42.6472,Tsageri,ADM2,DISTRICT
SM11350,42.7667,42.6,Tsageris Munitsip'alit'et'I (excl. Tsageri),ADM2,DISTRICT
SM283529,98.8505,48.071,Tsakhir,ADM2,DISTRICT
SM247038,46.8858,41.5916,Tsakhur,LANGUAGE,LANGUAGE
SM11086,32.7412,35.0125,Tsakistra,ADM2,DISTRICT
SM247037,22.7341,37.1405,Tsakonian,LANGUAGE,LANGUAGE
SM11351,42.0769,42.6057,Tsalenjikha,ADM2,DISTRICT
SM11352,44.0833,41.5833,Tsalka,ADM2,DISTRICT
SM247042,36.9139,5.3097,Tsamai,LANGUAGE,LANGUAGE
SM258171,37,5,Tsamai,ETHNICITY,ETHNICITY
SM274564,10.71,-1.16,Tsamba Mangotsi,ADM2,DISTRICT
SM284552,14.8833,-17.75,Tsandi,ADM2,DISTRICT
SM258172,84.32,30.87,Tsang Tibetans,ETHNICITY,ETHNICITY
SM283866,34.3269,-15.2001,Tsangano,ADM2,DISTRICT
SM268455,90.0489,27.0279,Tsangkha,ADM2,DISTRICT
SM284887,8.0081,12.2617,Tsanyawa,ADM2,DISTRICT
SM262362,26.2167,43.6,Tsar Kaloyan,ADM2,DISTRICT
SM370992,47.6468,-17.143,Tsaratanana,ADM2:ADM3,DISTRICT
SM262397,27.8167,42.1,Tsarevo,ADM2,DISTRICT
SM299923,34.486,48.9436,Tsarychans'kyi,ADM2,DISTRICT
SM247044,109.336,18.3192,Tsat,LANGUAGE,LANGUAGE
SM247039,140.186,-5.3908,Tsaukambo,LANGUAGE,LANGUAGE
SM283714,105.3184,48.4436,Tseel,ADM2,DISTRICT
SM283548,95.8658,45.5618,Tseel,ADM2,DISTRICT
SM247046,89.1931,27.8046,Tseku,LANGUAGE,LANGUAGE
SM293484,63.6667,54.5,Tselinnyy rayon,ADM2,DISTRICT
SM293055,44.5,46.5,Tselinnyy rayon,ADM2,DISTRICT
SM293008,86,53.0833,Tselinnyy rayon,ADM2,DISTRICT
SM279915,71.25,51.1667,Tselinogradskiy,ADM1:ADM2,DISTRICT
SM279915,71.25,51.1667,Tselinogradskiy,ADM1:ADM2,DISTRICT
SM279915,71.4333,51.1333,Tselinogradskiy,ADM1:ADM2,DISTRICT
SM279915,71.4333,51.1333,Tselinogradskiy,ADM1:ADM2,DISTRICT
SM283688,89.1436,48.9431,Tsengel,ADM2,DISTRICT
SM283530,101.7549,47.445,Tsenkher,ADM2,DISTRICT
SM283570,109.0503,47.7389,Tsenkhermandal,ADM2,DISTRICT
SM262370,25.6167,43.5833,Tsenovo,ADM2,DISTRICT
SM9816,27.5633,53.9178,Tsentral'ny Rayon,ADM2,DISTRICT
SM17953,68.8,38.55,Tsentral'nyy Rayon,ADM2,DISTRICT
SM17115,30.3679,59.9402,Tsentralniy Rayon,ADM2,DISTRICT
SM258173,23.55,-20.42,Tserekwe,ETHNICITY,ETHNICITY
SM11087,33.3334,35.0663,Tseri,ADM2,DISTRICT
SM11088,32.9903,34.6487,Tserkezoi,ADM2,DISTRICT
SM247048,-130.08,55.5,Tsetsaut,LANGUAGE,LANGUAGE
SM258174,-131.52,55.5,Tsetsaut,ETHNICITY,ETHNICITY
SM258174,-130.08,56.02,Tsetsaut,ETHNICITY,ETHNICITY
SM283585,93.2672,46.5888,Tsetseg,ADM2,DISTRICT
SM283836,96.0004,48.7451,Tsetsen-Uul,ADM2,DISTRICT
SM283610,97.7412,49.5237,Tsetserleg,ADM2,DISTRICT
SM283531,101.2392,48.8762,Tsetserleg,ADM2,DISTRICT
SM229622,45.8096,42.2646,Tsez,LANGUAGE,LANGUAGE
SM268548,22.4184,-25.754,Tshabong,ADM2,DISTRICT
SM247050,91.7741,27.3694,Tshangla,LANGUAGE,LANGUAGE
SM11127,15.3855,-4.4204,Tshangu District,ADM2,DISTRICT
SM269793,20.823,-6.409,Tshikapa,ADM2,DISTRICT
SM11128,23.4978,-6.2543,Tshilenge District,ADM2,DISTRICT
SM258175,22.25,-8.39,Tshiluba,ETHNICITY,ETHNICITY
SM258175,23.21,-4.14,Tshiluba,ETHNICITY,ETHNICITY
SM247055,101.84,32.18,Tshobdun,LANGUAGE,LANGUAGE
SM305271,27.4672,-19.5855,Tsholotsho,ADM2,DISTRICT
SM3556,25.2167,0.4667,Tshopo,ADM1:ADM2,DISTRICT
SM3557,19.2,-0.7333,Tshuapa,ADM1:ADM2,DISTRICT
SM3557,19.2,-0.7333,Tshuapa,ADM1:ADM2,DISTRICT
SM3557,20.8667,-0.2167,Tshuapa,ADM1:ADM2,DISTRICT
SM3557,20.8667,-0.2167,Tshuapa,ADM1:ADM2,DISTRICT
SM16358,13.8637,-3.735,Tsiaki,ADM2,DISTRICT
SM247059,4.8874,9.605,Tsikimba,LANGUAGE,LANGUAGE
SM294866,48,54.5833,Tsil'ninskiy rayon,ADM2,DISTRICT
SM250768,49.46,-14.67,Tsimihety,ETHNICITY,ETHNICITY
SM247061,48.8102,-15.4294,Tsimihety Malagasy,LANGUAGE,LANGUAGE
SM258176,-128.42,53.19,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-128.42,53.19,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-128.42,53.19,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-128.42,53.19,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-128.42,53.19,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.25,54.24,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.25,54.24,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.25,54.24,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.25,54.24,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.25,54.24,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.25,54.64,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.25,54.64,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.25,54.64,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.25,54.64,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.25,54.64,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-129.58,54,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-129.58,54,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-129.58,54,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-129.58,54,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-129.58,54,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.05,55.3,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.05,55.3,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.05,55.3,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.05,55.3,Tsimshian,ETHNICITY,ETHNICITY
SM258176,-130.05,55.3,Tsimshian,ETHNICITY,ETHNICITY
SM3558,45.1046,-12.7884,Tsingoni,ADM1,DISTRICT
SM371031,45.4792,-25.3849,Tsiombe,ADM2:ADM3,DISTRICT
SM3559,90.1667,27.0333,Tsirang,ADM1,DISTRICT
SM370948,46.0452,-18.8402,Tsiroanomandidy,ADM2:ADM3,DISTRICT
SM300029,32.7224,46.6253,Tsiurupyns'kyi,ADM2,DISTRICT
SM292804,47.5,55.8333,Tsivil'skiy rayon,ADM2,DISTRICT
SM274794,42.6,42.2667,Tskaltubo,ADM2,DISTRICT
SM11353,43.9667,42.2333,Tskhinvali District,ADM2,DISTRICT
SM247076,11.4972,9.5326,Tso,LANGUAGE,LANGUAGE
SM250769,25.77,-19.63,Tsoa,ETHNICITY,ETHNICITY
SM250769,25.77,-19.63,Tsoa,ETHNICITY,ETHNICITY
SM250769,25.9366,-21.0249,Tsoa,ETHNICITY,ETHNICITY
SM250769,25.9366,-21.0249,Tsoa,ETHNICITY,ETHNICITY
SM250770,12,-1,Tsogho,ETHNICITY,ETHNICITY
SM247071,11.1359,-1.5547,Tsogo,LANGUAGE,LANGUAGE
SM283549,96.6462,45.339,Tsogt,ADM2,DISTRICT
SM283625,105.3211,44.4143,Tsogt-Ovoo,ADM2,DISTRICT
SM283626,105.7561,43.6448,Tsogttsetsii,ADM2,DISTRICT
SM247075,31.8105,-23.5754,Tsonga,LANGUAGE,LANGUAGE
SM247078,27.4955,-25.8633,Tsotsitaal,LANGUAGE,LANGUAGE
SM247077,34.6961,0.2975,Tsotso,LANGUAGE,LANGUAGE
SM247079,120.787,23.216,Tsou,LANGUAGE,LANGUAGE
SM278711,136.5062,34.7171,Tsu,ADM2,DISTRICT
SM258177,22.29,-20.1,Tsuakwe,ETHNICITY,ETHNICITY
SM278950,138.8862,37.6654,Tsubame,ADM2,DISTRICT
SM278399,140.2,36.0833,Tsuchiura,ADM2,DISTRICT
SM3560,114.106,22.3791,Tsuen Wan,ADM1,DISTRICT
SM278992,140.3496,40.8699,Tsugaru,ADM2,DISTRICT
SM278401,140.0817,36.1053,Tsukuba,ADM2,DISTRICT
SM278402,140.0381,35.982,Tsukubamirai,ADM2,DISTRICT
SM279008,131.8667,33.0667,Tsukumi,ADM2,DISTRICT
SM247083,85.0817,28.4719,Tsum,LANGUAGE,LANGUAGE
SM292858,46.0833,42.5,Tsumadinskiy rayon,ADM2,DISTRICT
SM278951,138.6417,36.971,Tsunan,ADM2,DISTRICT
SM17116,46,42.1667,Tsuntinskiy Rayon (1992-),ADM2,DISTRICT
SM279613,138.9149,35.5366,Tsuru,ADM2,DISTRICT
SM279651,136.0807,35.6373,Tsuruga,ADM2,DISTRICT
SM279211,139.3946,35.9357,Tsurugashima,ADM2,DISTRICT
SM279417,134.0552,33.9648,Tsurugi,ADM2,DISTRICT
SM278222,144.2899,43.2555,Tsurui,ADM2,DISTRICT
SM279565,139.8084,38.5851,Tsuruoka,ADM2,DISTRICT
SM278883,129.326,34.4085,Tsushima,ADM2,DISTRICT
SM278080,136.7417,35.1772,Tsushima,ADM2,DISTRICT
SM247087,5.221,10.8421,Tsuvadi,LANGUAGE,LANGUAGE
SM247088,13.628,10.1551,Tsuvan,LANGUAGE,LANGUAGE
SM279040,134.0318,35.1405,Tsuyama,ADM2,DISTRICT
SM13113,135.8773,34.8466,Tsuzuki-gun,ADM2,DISTRICT
SM247093,32.4287,-22.9891,Tswa,LANGUAGE,LANGUAGE
SM247091,24.7587,-24.368,Tswana,LANGUAGE,LANGUAGE
SM251105,20.83,-24.6,Tswana,ETHNICITY,ETHNICITY
SM251105,20.83,-24.6,Tswana,ETHNICITY,ETHNICITY
SM251105,20.83,-24.6,Tswana,ETHNICITY,ETHNICITY
SM251105,20.39,-27.68,Tswana,ETHNICITY,ETHNICITY
SM251105,20.39,-27.68,Tswana,ETHNICITY,ETHNICITY
SM251105,20.39,-27.68,Tswana,ETHNICITY,ETHNICITY
SM251105,27,-24,Tswana,ETHNICITY,ETHNICITY
SM251105,27,-24,Tswana,ETHNICITY,ETHNICITY
SM251105,27,-24,Tswana,ETHNICITY,ETHNICITY
SM251105,25.3656,-24.0665,Tswana,ETHNICITY,ETHNICITY
SM251105,25.3656,-24.0665,Tswana,ETHNICITY,ETHNICITY
SM251105,25.3656,-24.0665,Tswana,ETHNICITY,ETHNICITY
SM247090,27.4201,-23.4055,Tswapong,LANGUAGE,LANGUAGE
SM15372,-59.6142,-23.0324,Tte 1ro Manuel Irala Fernandez,ADM2,DISTRICT
SM15373,-59.7438,-23.9127,Tte. Esteban Martinez,ADM2,DISTRICT
SM247283,102.117,36.8177,Tu,FAMILY:LANGUAGE,LANGUAGE
SM304236,107.94,14.9019,Tu Mơ Rông,ADM2,DISTRICT
SM276464,132.7494,-5.643,Tual,ADM2,DISTRICT
SM261213,-170.7695,-14.381,Tualatai,ADM2,DISTRICT
SM258178,-123.39,45.43,Tualatin,ETHNICITY,ETHNICITY
SM247098,-122.955,45.327,Tualatin-Yamhill,LANGUAGE,LANGUAGE
SM261214,-170.7227,-14.3624,Tualauta,ADM2,DISTRICT
SM3561,-171.8,-13.9167,Tuamasaga,ADM1,DISTRICT
SM258179,-143.72,-16.81,Tuamotu,ETHNICITY,ETHNICITY
SM258179,-143.72,-16.81,Tuamotu,ETHNICITY,ETHNICITY
SM258179,-142.31,-15.94,Tuamotu,ETHNICITY,ETHNICITY
SM258179,-142.31,-15.94,Tuamotu,ETHNICITY,ETHNICITY
SM247099,-146.3218,-16.1726,Tuamotuan,LANGUAGE,LANGUAGE
SM17117,39.0833,44.25,Tuapsinskiy Rayon,ADM2,DISTRICT
SM258180,-113,40,Tubaduka,ETHNICITY,ETHNICITY
SM258180,-113,40,Tubaduka,ETHNICITY,ETHNICITY
SM258180,-113.94,41.59,Tubaduka,ETHNICITY,ETHNICITY
SM258180,-113.94,41.59,Tubaduka,ETHNICITY,ETHNICITY
SM461538,87,51.1333,Tubalar,ETHNICITY,ETHNICITY
SM461538,87,51.1333,Tubalar,ETHNICITY,ETHNICITY
SM461538,87.42,52.2,Tubalar,ETHNICITY,ETHNICITY
SM461538,87.42,52.2,Tubalar,ETHNICITY,ETHNICITY
SM304887,44.8784,13.0921,Tuban,ADM2,DISTRICT
SM276369,111.9,-6.9667,Tuban,ADM2,DISTRICT
SM247107,-111,30,Tubar,LANGUAGE,LANGUAGE
SM258181,-108.22,26.56,Tubar,ETHNICITY,ETHNICITY
SM270672,-74.9837,10.9155,Tubará,ADM2,DISTRICT
SM266335,-49.0365,-28.4926,Tubarão,ADM2,DISTRICT
SM289741,35.4865,32.3239,Tubas,ADM1:ADM2,DISTRICT
SM258182,-117.83,35.48,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-117.83,35.48,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-117.83,35.48,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-117.83,35.48,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-117.83,35.48,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118,39,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118,39,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118,39,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118,39,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118,39,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118.32,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118.32,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118.32,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118.32,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118.32,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118.2,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118.2,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118.2,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118.2,36,Tubatulabal,ETHNICITY,ETHNICITY
SM258182,-118.2,36,Tubatulabal,ETHNICITY,ETHNICITY
SM285572,6.7824,52.4045,Tubbergen,ADM2,DISTRICT
SM370215,-7.6763,5.0389,Tubbo,ADM2:ADM3,DISTRICT
SM303671,-64.0833,10.95,Tubores,ADM2,DISTRICT
SM11273,-149.4832,-23.3649,Tubuai,ADM2,DISTRICT
SM258183,-149.48,-23.38,Tubuai,ETHNICITY,ETHNICITY
SM282611,-111.4222,30.8541,Tubutama,ADM2,DISTRICT
SM247111,-69.5763,0.5798,Tucano,LANGUAGE,LANGUAGE
SM267691,-38.8625,-10.9943,Tucano,ADM2,DISTRICT
SM258184,-70,0,Tucano,ETHNICITY,ETHNICITY
SM10481,-75.5452,9.2234,Tuchin,ADM2,DISTRICT
SM288854,17.9397,53.5815,Tuchola,ADM2,DISTRICT
SM303149,-79.565,39.1136,Tucker,ADM2,DISTRICT
SM12802,-77.8944,18.449,Tucker,ADM2,DISTRICT
SM3562,-65.5,-27,Tucumán,ADM1,DISTRICT
SM264120,-51.4456,-6.8347,Tucumã,ADM2,DISTRICT
SM265978,-54.4397,-27.6492,Tucunduva,ADM2,DISTRICT
SM303891,-61.7134,9.3089,Tucupita,ADM2,DISTRICT
SM264121,-49.8748,-3.8276,Tucuruí,ADM2,DISTRICT
SM275165,-90.0724,15.3008,Tucurú,ADM2,DISTRICT
SM454368,-0.167,51.5,Tudor Britain,ETHNICITY,ETHNICITY
SM290473,27.6508,45.5647,Tudor Vladimirescu,ADM2,DISTRICT
SM292606,26.634,47.5077,Tudora,ADM2,DISTRICT
SM280483,104.3803,12.0817,Tuek Phos,ADM2,DISTRICT
SM3563,113.9679,22.3916,Tuen Mun,ADM1,DISTRICT
SM277105,94.75,26.25,Tuensang (2007-),ADM2,DISTRICT
SM11202,25.4644,58.7414,Tueri vald,ADM2,DISTRICT
SM13279,68.25,43.3333,Tuerkistan Qalasy,ADM2,DISTRICT
SM298125,36.2273,38.2734,Tufanbeyli,ADM2,DISTRICT
SM291474,24.7899,44.3472,Tufeni,ADM2,DISTRICT
SM292700,27.8026,44.9872,Tufesti,ADM2,DISTRICT
SM262922,-45.5561,-3.7661,Tufilândia,ADM2,DISTRICT
SM247114,35.8499,0.6657,Tugen,LANGUAGE,LANGUAGE
SM258185,36.68,0.26,Tugen,ETHNICITY,ETHNICITY
SM258185,36.68,0.26,Tugen,ETHNICITY,ETHNICITY
SM258185,35.8,0.8,Tugen,ETHNICITY,ETHNICITY
SM258185,35.8,0.8,Tugen,ETHNICITY,ETHNICITY
SM290417,23.823,44.2003,Tuglui,ADM2,DISTRICT
SM247116,126.7,-7.7256,Tugun,LANGUAGE,LANGUAGE
SM293189,134,54.5,Tuguro-Chumikanskiy rayon,ADM2,DISTRICT
SM247117,128.223,0.5806,Tugutil,LANGUAGE,LANGUAGE
SM305741,15.7481,46.0763,Tuhelj,ADM2,DISTRICT
SM258186,-67.23,4.88,Tuirimnainai,ETHNICITY,ETHNICITY
SM267007,-46.6962,-22.8451,Tuiuti,ADM2,DISTRICT
SM258187,101.8,38.25,Tujen,ETHNICITY,ETHNICITY
SM258187,101.8,38.25,Tujen,ETHNICITY,ETHNICITY
SM258187,101.8,38.25,Tujen,ETHNICITY,ETHNICITY
SM258187,101.8,38.25,Tujen,ETHNICITY,ETHNICITY
SM258187,101.8,38.25,Tujen,ETHNICITY,ETHNICITY
SM258187,102,37.75,Tujen,ETHNICITY,ETHNICITY
SM258187,102,37.75,Tujen,ETHNICITY,ETHNICITY
SM258187,102,37.75,Tujen,ETHNICITY,ETHNICITY
SM258187,102,37.75,Tujen,ETHNICITY,ETHNICITY
SM258187,102,37.75,Tujen,ETHNICITY,ETHNICITY
SM258187,100,39,Tujen,ETHNICITY,ETHNICITY
SM258187,100,39,Tujen,ETHNICITY,ETHNICITY
SM258187,100,39,Tujen,ETHNICITY,ETHNICITY
SM258187,100,39,Tujen,ETHNICITY,ETHNICITY
SM258187,100,39,Tujen,ETHNICITY,ETHNICITY
SM258187,101,36,Tujen,ETHNICITY,ETHNICITY
SM258187,101,36,Tujen,ETHNICITY,ETHNICITY
SM258187,101,36,Tujen,ETHNICITY,ETHNICITY
SM258187,101,36,Tujen,ETHNICITY,ETHNICITY
SM258187,101,36,Tujen,ETHNICITY,ETHNICITY
SM258187,101,36,Tujen,ETHNICITY,ETHNICITY
SM258187,101,36,Tujen,ETHNICITY,ETHNICITY
SM258187,101,36,Tujen,ETHNICITY,ETHNICITY
SM258187,101,36,Tujen,ETHNICITY,ETHNICITY
SM258187,101,36,Tujen,ETHNICITY,ETHNICITY
SM258188,109,29,Tujia,ETHNICITY,ETHNICITY
SM258188,109,29,Tujia,ETHNICITY,ETHNICITY
SM258188,109,29,Tujia,ETHNICITY,ETHNICITY
SM258188,109,29,Tujia,ETHNICITY,ETHNICITY
SM247127,123.578,-5.3099,Tukang Besi North,LANGUAGE,LANGUAGE
SM247128,123.948,-5.7478,Tukang Besi South,LANGUAGE,LANGUAGE
SM294637,52.25,55.6667,Tukayevskiy rayon,ADM2,DISTRICT
SM247131,11.5353,4.6435,Tuki,LANGUAGE,LANGUAGE
SM247132,78.1627,31.4925,Tukpa,LANGUAGE,LANGUAGE
SM247132,78.1627,31.4925,Tukpa,LANGUAGE,LANGUAGE
SM247132,78.5605,31.467,Tukpa,LANGUAGE,LANGUAGE
SM247132,78.5605,31.467,Tukpa,LANGUAGE,LANGUAGE
SM370264,-9.4193,6.6415,Tukpahblee,ADM2:ADM3,DISTRICT
SM247135,125.283,-8.6788,Tukudede,LANGUAGE,LANGUAGE
SM258189,-111,44,Tukudika,ETHNICITY,ETHNICITY
SM258189,-111,44,Tukudika,ETHNICITY,ETHNICITY
SM258189,-113.84,45.17,Tukudika,ETHNICITY,ETHNICITY
SM258189,-113.84,45.17,Tukudika,ETHNICITY,ETHNICITY
SM4021,23,56.9167,Tukuma novads,ADM1,DISTRICT
SM247133,-61.42,-11.518,Tukumanféd,LANGUAGE,LANGUAGE
SM13876,23.1528,56.9654,Tukums,ADM2,DISTRICT
SM299784,28.869,48.6741,Tul'chyns'kyi (pre-2020),ADM2,DISTRICT
SM247139,11.5255,9.8463,Tula,LANGUAGE,LANGUAGE
SM282669,-99.7026,22.9703,Tula,ADM2,DISTRICT
SM3564,37.5,54,Tula,ADM1,DISTRICT
SM281727,-99.3557,20.0484,Tula de Allende,ADM2,DISTRICT
SM295304,160.1466,-9.1015,Tulagi,ADM2,DISTRICT
SM259995,63.7142,34.0092,Tulak,ADM2,DISTRICT
SM281728,-98.3605,20.1198,Tulancingo de Bravo,ADM2,DISTRICT
SM276442,105.16,-4.4336,Tulang Bawang Barat,ADM2,DISTRICT
SM276443,105.58,-4.206,Tulangbawang,ADM2,DISTRICT
SM303212,-118.8005,36.2202,Tulare,ADM2,DISTRICT
SM292482,21.7976,46.7888,Tulca,ADM2,DISTRICT
SM292077,28.793,45.1833,Tulcea,ADM2,DISTRICT
SM3565,28.8333,45,Tulcea,ADM1,DISTRICT
SM282332,-98.4093,17.9989,Tulcingo,ADM2,DISTRICT
SM273596,-77.7333,0.8,Tulcán,ADM2,DISTRICT
SM247144,-118.7686,36.0535,Tule-Kaweah Yokuts,LANGUAGE,LANGUAGE
SM247143,128.31,-3.5779,Tulehu,LANGUAGE,LANGUAGE
SM290725,25.7533,46.9254,Tulghes,ADM2,DISTRICT
SM268528,28.112,-22.7832,Tuli,ADM2,DISTRICT
SM303626,-71.0912,9.1418,Tulio Febres Cordero,ADM2,DISTRICT
SM247146,29.108,11.4507,Tulishi,LANGUAGE,LANGUAGE
SM454535,29,11,Tulishi,ETHNICITY,ETHNICITY
SM454535,29,11,Tulishi,ETHNICITY,ETHNICITY
SM454535,29,12,Tulishi,ETHNICITY,ETHNICITY
SM454535,29,12,Tulishi,ETHNICITY,ETHNICITY
SM289742,35.094,32.3199,Tulkarm,ADM1:ADM2,DISTRICT
SM261813,16,48.3,Tulln,ADM2,DISTRICT
SM302241,-95.9415,36.1211,Tulsa,ADM2,DISTRICT
SM281875,-99.1266,19.6879,Tultepec,ADM2,DISTRICT
SM281877,-99.1687,19.6481,Tultitlán,ADM2,DISTRICT
SM247147,75.2651,12.8114,Tulu,LANGUAGE,LANGUAGE
SM247148,146.934,-2.1416,Tulu-Bohuai,LANGUAGE,LANGUAGE
SM458417,150.999,-24.3899,Tulua,ETHNICITY,ETHNICITY
SM290474,28.0524,45.5924,Tulucesti,ADM2,DISTRICT
SM14890,-87.517,20.2262,Tulum,ADM2,DISTRICT
SM261139,-63.9167,-30.25,Tulumba,ADM2,DISTRICT
SM276370,111.9167,-8.1167,Tulungagung,ADM2,DISTRICT
SM17118,100.3333,54.1667,Tulunskiy Rayon,ADM2,DISTRICT
SM270634,-76.0502,4.0089,Tuluá,ADM2,DISTRICT
SM247155,146.609,-6.2922,Tuma-Irumu,LANGUAGE,LANGUAGE
SM285455,-85.7487,13.0442,Tuma-La Dalia,ADM2,DISTRICT
SM270355,-78.6122,1.6377,Tumaco,ADM2,DISTRICT
SM247156,17.4216,9.4375,Tumak,LANGUAGE,LANGUAGE
SM11274,-151.4867,-16.7911,Tumaraa,ADM2,DISTRICT
SM247153,13.1571,14.2584,Tumari Kanuri,LANGUAGE,LANGUAGE
SM283210,-92.2779,17.3079,Tumbalá,ADM2,DISTRICT
SM16259,-65.9212,18.0208,Tumbao Barrio,ADM2,DISTRICT
SM260829,-65.6667,-23.7167,Tumbaya,ADM2,DISTRICT
SM286850,-80.4167,-3.75,Tumbes,ADM2,DISTRICT
SM3566,-80.5,-3.8333,Tumbes,ADM1,DISTRICT
SM258194,-80.06,-4.33,Tumbez,ETHNICITY,ETHNICITY
SM282094,-102.5349,18.5396,Tumbiscatío,ADM2,DISTRICT
SM247158,33.3738,-11.3297,Tumbuka,LANGUAGE,LANGUAGE
SM250774,34.58,-9.66,Tumbuka,ETHNICITY,ETHNICITY
SM250774,34.58,-9.66,Tumbuka,ETHNICITY,ETHNICITY
SM250774,34.58,-9.66,Tumbuka,ETHNICITY,ETHNICITY
SM250774,34,-12,Tumbuka,ETHNICITY,ETHNICITY
SM250774,34,-12,Tumbuka,ETHNICITY,ETHNICITY
SM250774,34,-12,Tumbuka,ETHNICITY,ETHNICITY
SM250774,33.599,-11.9006,Tumbuka,ETHNICITY,ETHNICITY
SM250774,33.599,-11.9006,Tumbuka,ETHNICITY,ETHNICITY
SM250774,33.599,-11.9006,Tumbuka,ETHNICITY,ETHNICITY
SM261739,136.1365,-34.197,Tumby Bay,ADM2,DISTRICT
SM13877,23.0679,56.9601,Tumes pagasts,ADM2,DISTRICT
SM247161,8.1038,10.5113,Tumi,LANGUAGE,LANGUAGE
SM264023,-41.723,-19.024,Tumiritinga,ADM2,DISTRICT
SM276942,77,13.5,Tumkur,ADM2,DISTRICT
SM247162,142.346,-3.1496,Tumleo,LANGUAGE,LANGUAGE
SM247164,79.0667,39.8667,Tumshuqese,LANGUAGE,LANGUAGE
SM247165,30.4128,10.6378,Tumtum,LANGUAGE,LANGUAGE
SM247167,-1.7971,10.6345,Tumulung Sisaala,LANGUAGE,LANGUAGE
SM10365,79.1087,39.872,Tumxuk,ADM2,DISTRICT
SM247169,3.8504,32.5014,Tumzabt,LANGUAGE,LANGUAGE
SM16260,-67.0965,18.0654,Tuna Barrio,ADM2,DISTRICT
SM3567,-61.3818,10.6339,Tunapuna/Piarco (1992-),ADM1,DISTRICT
SM290976,26.1391,44.568,Tunari,ADM2,DISTRICT
SM265979,-52.8987,-29.1059,Tunas,ADM2,DISTRICT
SM264760,-48.866,-24.9675,Tunas do Paraná,ADM2,DISTRICT
SM258199,-118,37,Tunava (Deep Springs and Fish Lake),ETHNICITY,ETHNICITY
SM258200,-118,37.28,Tunava (Deep Springs),ETHNICITY,ETHNICITY
SM258201,-118.14,37.69,Tunava (Fish Lake),ETHNICITY,ETHNICITY
SM3568,39.5475,39.1071,Tunceli,ADM1,DISTRICT
SM247171,83.7405,70.1626,Tundra Enets,LANGUAGE,LANGUAGE
SM239494,71.0234,66.1775,Tundra Nenets,LANGUAGE,LANGUAGE
SM299105,32.7989,-9.2989,Tunduma,ADM2,DISTRICT
SM284888,8.5993,11.2827,Tundun Wada,ADM2,DISTRICT
SM299167,37.3002,-10.9519,Tunduru,ADM2,DISTRICT
SM262500,26.45,42.35,Tundzha,ADM2,DISTRICT
SM264761,-52.8357,-23.9017,Tuneiras do Oeste,ADM2,DISTRICT
SM247177,10.67,4.75,Tunen,LANGUAGE,LANGUAGE
SM247175,10.67,4.75,Tunen (Retired),LANGUAGE,LANGUAGE
SM247185,150.198,-2.5219,Tungag,LANGUAGE,LANGUAGE
SM247188,135.551,-3.3746,Tunggare,LANGUAGE,LANGUAGE
SM295101,120,55,Tungiro-Olekminskiy rayon,ADM2,DISTRICT
SM295102,116,53.5,Tungokochenskiy rayon,ADM2,DISTRICT
SM3569,-78.5,-1.25,Tungurahua,ADM1,DISTRICT
SM243568,-78.3953,-1.3545,Tungurahua Highland Quichua,LANGUAGE,LANGUAGE
SM247191,18.2059,9.4251,Tunia,LANGUAGE,LANGUAGE
SM247192,-92.5403,30.9888,Tunica,LANGUAGE,LANGUAGE
SM301495,-90.3755,34.6519,Tunica,ADM2,DISTRICT
SM258203,-91.11,35.24,Tunica,ETHNICITY,ETHNICITY
SM3570,10.1848,36.7863,Tunis,ADM1:ADMX,DISTRICT
SM247193,9.5616,33.7966,Tunisian Arabic,LANGUAGE,LANGUAGE
SM247190,10.0335,36.4956,Tunisian Sign Language,LANGUAGE,LANGUAGE
SM247196,11.2996,32.8639,Tunisian-Zuwara Berber,LANGUAGE,LANGUAGE
SM270734,-73.3783,5.518,Tunja,ADM2,DISTRICT
SM247197,115.883,-0.2905,Tunjung,LANGUAGE,LANGUAGE
SM295123,102.5,51.5,Tunkinskiy rayon,ADM2,DISTRICT
SM283035,-88.7534,20.8903,Tunkás,ADM2,DISTRICT
SM247201,43.6847,1.0617,Tunni,LANGUAGE,LANGUAGE
SM370531,6.0103,49.7178,Tuntange,ADM1:ADM2:ADM3,DISTRICT
SM262923,-44.8554,-5.6004,Tuntum,ADM2,DISTRICT
SM346781,24.0004,67.9862,Tunturi Lapland,ADM2:ADM3,DISTRICT
SM247203,-37.6311,65.6136,Tunumiisiut,LANGUAGE,LANGUAGE
SM270735,-73.9588,5.708,Tunungua,ADM2,DISTRICT
SM258205,-86.78,73.39,Tununirmiut,ETHNICITY,ETHNICITY
SM258205,-86.25,75.07,Tununirmiut,ETHNICITY,ETHNICITY
SM258206,-85.69,70.89,Tununirusirmiut,ETHNICITY,ETHNICITY
SM260888,-69.5,-33.6667,Tunuyán,ADM2,DISTRICT
SM247205,118.2294,29.7202,Tunxi,DIALECT,LANGUAGE
SM247207,10.0295,9.1158,Tunzu,LANGUAGE,LANGUAGE
SM266336,-53.6562,-26.9925,Tunápolis,ADM2,DISTRICT
SM370142,-7.6667,4.75,Tuobo (Maryland),ADM2:ADM3,DISTRICT
SM303213,-119.9548,38.0276,Tuolumne,ADM2,DISTRICT
SM247212,10.6592,4.6563,Tuotomb,LANGUAGE,LANGUAGE
SM9965,-48.714,-18.5618,Tupaciguara (1992-),ADM2,DISTRICT
SM264927,-37.3453,-8.6733,Tupanatinga,ADM2,DISTRICT
SM265980,-51.5445,-27.9264,Tupanci do Sul,ADM2,DISTRICT
SM265981,-53.9747,-29.0219,Tupanciretã,ADM2,DISTRICT
SM265982,-51.4304,-29.48,Tupandi,ADM2,DISTRICT
SM265983,-54.5591,-27.6892,Tuparendi,ADM2,DISTRICT
SM264928,-37.2452,-7.7134,Tuparetama,ADM2,DISTRICT
SM258207,-62.71,-15.08,Tupari,ETHNICITY,ETHNICITY
SM247213,-62.2544,-11.8884,Tuparí,LANGUAGE,LANGUAGE
SM267009,-51.5829,-21.3892,Tupi Paulista,ADM2,DISTRICT
SM291379,26.6416,47.0815,Tupilati,ADM2,DISTRICT
SM258208,-40.73,-10.34,Tupina,ETHNICITY,ETHNICITY
SM258208,-38.78,-11.77,Tupina,ETHNICITY,ETHNICITY
SM258209,-45.25,-23.2,Tupinakin,ETHNICITY,ETHNICITY
SM258210,-38.18,-11.81,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-38.18,-11.81,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-38.18,-11.81,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-38.18,-11.81,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-39.83,-16.98,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-39.83,-16.98,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-39.83,-16.98,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-39.83,-16.98,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-40.82,-20.43,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-40.82,-20.43,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-40.82,-20.43,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-40.82,-20.43,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-43.12,-22.48,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-43.12,-22.48,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-43.12,-22.48,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-43.12,-22.48,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-42.5,-11.75,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-42.5,-11.75,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-42.5,-11.75,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-42.5,-11.75,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-35,-8,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-35,-8,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-35,-8,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-35,-8,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-44.5,-22.79,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-44.5,-22.79,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-44.5,-22.79,Tupinamba,ETHNICITY,ETHNICITY
SM258210,-44.5,-22.79,Tupinamba,ETHNICITY,ETHNICITY
SM247217,-42.1064,-22.8078,Tupinambá,LANGUAGE,LANGUAGE
SM247216,-40.4167,-19,Tupinikin,DIALECT,LANGUAGE
SM10037,-48.2766,-8.9192,Tupirama,ADM2:ADM3,DISTRICT
SM267191,-48.223,-8.3858,Tupiratins,ADM2,DISTRICT
SM279969,50.75,44.3333,Tupkaraganskiy,ADM2,DISTRICT
SM260889,-69.25,-33.25,Tupungato,ADM2,DISTRICT
SM247237,14.8697,10.0144,Tupuri,LANGUAGE,LANGUAGE
SM267008,-50.5349,-21.9544,Tupã,ADM2,DISTRICT
SM264762,-53.4921,-24.6332,Tupãssi,ADM2,DISTRICT
SM304888,44.4115,13.0427,Tur Al Bahah,ADM2,DISTRICT
SM16261,-66.048,18.2022,Turabo Barrio,ADM2,DISTRICT
SM247242,149.567,-9.8358,Turaka,LANGUAGE,LANGUAGE
SM17820,39,31.5,Turayf,ADM2,DISTRICT
SM270707,-75.3804,10.3571,Turbaco,ADM2,DISTRICT
SM270708,-75.4561,10.2353,Turbaná,ADM2,DISTRICT
SM270162,-76.7335,8.0954,Turbo,ADM2,DISTRICT
SM290669,23.5317,44.6825,Turburea,ADM2,DISTRICT
SM290670,23.3542,44.7178,Turceni,ADM2,DISTRICT
SM296191,18.8576,48.8628,Turcianske Teplice,ADM2,DISTRICT
SM290671,23.3033,45.1274,Turcinesti,ADM2,DISTRICT
SM292078,28.1881,45.1092,Turcoaia,ADM2,DISTRICT
SM290138,23.7857,46.5746,Turda,ADM2,DISTRICT
SM290794,23.1319,45.8477,Turdas,ADM2,DISTRICT
SM11275,-138.5603,-20.8557,Tureia,ADM2,DISTRICT
SM288811,18.493,51.9958,Turek,ADM2,DISTRICT
SM290139,23.6768,46.6392,Tureni,ADM2,DISTRICT
SM285231,5.4943,12.4862,Tureta,ADM2,DISTRICT
SM269348,89.1667,42.9333,Turfan,ADM2,DISTRICT
SM298731,27.7352,38.5152,Turgutlu,ADM2,DISTRICT
SM298949,36.0874,40.3984,Turhal,ADM2,DISTRICT
SM247247,84.4712,22.0122,Turi,LANGUAGE,LANGUAGE
SM290232,26.0395,46.0293,Turia,ADM2,DISTRICT
SM262924,-45.3818,-1.6325,Turiaçu,ADM2,DISTRICT
SM282095,-101.4239,18.9532,Turicato,ADM2,DISTRICT
SM262925,-45.3472,-2.1241,Turilândia,ADM2,DISTRICT
SM297809,125.7045,-8.8237,Turiscai,ADM2,DISTRICT
SM247248,-49.5,-2,Turiwára,LANGUAGE,LANGUAGE
SM267011,-50.098,-20.9474,Turiúba,ADM2,DISTRICT
SM247253,-4.9208,10.7114,Turka,LANGUAGE,LANGUAGE
SM247255,35.7101,4.3069,Turkana,LANGUAGE,LANGUAGE
SM3571,35.5,3,Turkana,ADM1:ADM2,DISTRICT
SM250782,35.44,3.49,Turkana,ETHNICITY,ETHNICITY
SM250782,35.44,3.49,Turkana,ETHNICITY,ETHNICITY
SM250782,35.44,3.49,Turkana,ETHNICITY,ETHNICITY
SM250782,35.44,3.49,Turkana,ETHNICITY,ETHNICITY
SM250782,35.375,3.25,Turkana,ETHNICITY,ETHNICITY
SM250782,35.375,3.25,Turkana,ETHNICITY,ETHNICITY
SM250782,35.375,3.25,Turkana,ETHNICITY,ETHNICITY
SM250782,35.375,3.25,Turkana,ETHNICITY,ETHNICITY
SM250782,35,4,Turkana,ETHNICITY,ETHNICITY
SM250782,35,4,Turkana,ETHNICITY,ETHNICITY
SM250782,35,4,Turkana,ETHNICITY,ETHNICITY
SM250782,35,4,Turkana,ETHNICITY,ETHNICITY
SM250782,36,3,Turkana,ETHNICITY,ETHNICITY
SM250782,36,3,Turkana,ETHNICITY,ETHNICITY
SM250782,36,3,Turkana,ETHNICITY,ETHNICITY
SM250782,36,3,Turkana,ETHNICITY,ETHNICITY
SM247251,50.3222,34.4224,Turkic Khalaj,LANGUAGE,LANGUAGE
SM240732,32.8667,39.8667,Turkish,LANGUAGE,LANGUAGE
SM258219,30.63,39.15,Turkish,ETHNICITY,ETHNICITY
SM258219,30.63,39.15,Turkish,ETHNICITY,ETHNICITY
SM258219,30.63,39.15,Turkish,ETHNICITY,ETHNICITY
SM258219,30.63,39.15,Turkish,ETHNICITY,ETHNICITY
SM258219,30.63,39.15,Turkish,ETHNICITY,ETHNICITY
SM258219,30.63,39.15,Turkish,ETHNICITY,ETHNICITY
SM258219,30.63,39.15,Turkish,ETHNICITY,ETHNICITY
SM258219,30.63,39.15,Turkish,ETHNICITY,ETHNICITY
SM258219,30.63,39.15,Turkish,ETHNICITY,ETHNICITY
SM258219,30.63,39.15,Turkish,ETHNICITY,ETHNICITY
SM258219,35.5,38.5,Turkish,ETHNICITY,ETHNICITY
SM258219,35.5,38.5,Turkish,ETHNICITY,ETHNICITY
SM258219,35.5,38.5,Turkish,ETHNICITY,ETHNICITY
SM258219,35.5,38.5,Turkish,ETHNICITY,ETHNICITY
SM258219,35.5,38.5,Turkish,ETHNICITY,ETHNICITY
SM258219,35.5,38.5,Turkish,ETHNICITY,ETHNICITY
SM258219,35.5,38.5,Turkish,ETHNICITY,ETHNICITY
SM258219,35.5,38.5,Turkish,ETHNICITY,ETHNICITY
SM258219,35.5,38.5,Turkish,ETHNICITY,ETHNICITY
SM258219,35.5,38.5,Turkish,ETHNICITY,ETHNICITY
SM258219,30,38,Turkish,ETHNICITY,ETHNICITY
SM258219,30,38,Turkish,ETHNICITY,ETHNICITY
SM258219,30,38,Turkish,ETHNICITY,ETHNICITY
SM258219,30,38,Turkish,ETHNICITY,ETHNICITY
SM258219,30,38,Turkish,ETHNICITY,ETHNICITY
SM258219,30,38,Turkish,ETHNICITY,ETHNICITY
SM258219,30,38,Turkish,ETHNICITY,ETHNICITY
SM258219,30,38,Turkish,ETHNICITY,ETHNICITY
SM258219,30,38,Turkish,ETHNICITY,ETHNICITY
SM258219,30,38,Turkish,ETHNICITY,ETHNICITY
SM258219,34.25,39.33,Turkish,ETHNICITY,ETHNICITY
SM258219,34.25,39.33,Turkish,ETHNICITY,ETHNICITY
SM258219,34.25,39.33,Turkish,ETHNICITY,ETHNICITY
SM258219,34.25,39.33,Turkish,ETHNICITY,ETHNICITY
SM258219,34.25,39.33,Turkish,ETHNICITY,ETHNICITY
SM258219,34.25,39.33,Turkish,ETHNICITY,ETHNICITY
SM258219,34.25,39.33,Turkish,ETHNICITY,ETHNICITY
SM258219,34.25,39.33,Turkish,ETHNICITY,ETHNICITY
SM258219,34.25,39.33,Turkish,ETHNICITY,ETHNICITY
SM258219,34.25,39.33,Turkish,ETHNICITY,ETHNICITY
SM258219,27.8333,37.85,Turkish,ETHNICITY,ETHNICITY
SM258219,27.8333,37.85,Turkish,ETHNICITY,ETHNICITY
SM258219,27.8333,37.85,Turkish,ETHNICITY,ETHNICITY
SM258219,27.8333,37.85,Turkish,ETHNICITY,ETHNICITY
SM258219,27.8333,37.85,Turkish,ETHNICITY,ETHNICITY
SM258219,27.8333,37.85,Turkish,ETHNICITY,ETHNICITY
SM258219,27.8333,37.85,Turkish,ETHNICITY,ETHNICITY
SM258219,27.8333,37.85,Turkish,ETHNICITY,ETHNICITY
SM258219,27.8333,37.85,Turkish,ETHNICITY,ETHNICITY
SM258219,27.8333,37.85,Turkish,ETHNICITY,ETHNICITY
SM258219,27.533,39.385,Turkish,ETHNICITY,ETHNICITY
SM258219,27.533,39.385,Turkish,ETHNICITY,ETHNICITY
SM258219,27.533,39.385,Turkish,ETHNICITY,ETHNICITY
SM258219,27.533,39.385,Turkish,ETHNICITY,ETHNICITY
SM258219,27.533,39.385,Turkish,ETHNICITY,ETHNICITY
SM258219,27.533,39.385,Turkish,ETHNICITY,ETHNICITY
SM258219,27.533,39.385,Turkish,ETHNICITY,ETHNICITY
SM258219,27.533,39.385,Turkish,ETHNICITY,ETHNICITY
SM258219,27.533,39.385,Turkish,ETHNICITY,ETHNICITY
SM258219,27.533,39.385,Turkish,ETHNICITY,ETHNICITY
SM258219,35.3167,37,Turkish,ETHNICITY,ETHNICITY
SM258219,35.3167,37,Turkish,ETHNICITY,ETHNICITY
SM258219,35.3167,37,Turkish,ETHNICITY,ETHNICITY
SM258219,35.3167,37,Turkish,ETHNICITY,ETHNICITY
SM258219,35.3167,37,Turkish,ETHNICITY,ETHNICITY
SM258219,35.3167,37,Turkish,ETHNICITY,ETHNICITY
SM258219,35.3167,37,Turkish,ETHNICITY,ETHNICITY
SM258219,35.3167,37,Turkish,ETHNICITY,ETHNICITY
SM258219,35.3167,37,Turkish,ETHNICITY,ETHNICITY
SM258219,35.3167,37,Turkish,ETHNICITY,ETHNICITY
SM258219,35.4833,38.7333,Turkish,ETHNICITY,ETHNICITY
SM258219,35.4833,38.7333,Turkish,ETHNICITY,ETHNICITY
SM258219,35.4833,38.7333,Turkish,ETHNICITY,ETHNICITY
SM258219,35.4833,38.7333,Turkish,ETHNICITY,ETHNICITY
SM258219,35.4833,38.7333,Turkish,ETHNICITY,ETHNICITY
SM258219,35.4833,38.7333,Turkish,ETHNICITY,ETHNICITY
SM258219,35.4833,38.7333,Turkish,ETHNICITY,ETHNICITY
SM258219,35.4833,38.7333,Turkish,ETHNICITY,ETHNICITY
SM258219,35.4833,38.7333,Turkish,ETHNICITY,ETHNICITY
SM258219,35.4833,38.7333,Turkish,ETHNICITY,ETHNICITY
SM258219,28.95,41.0167,Turkish,ETHNICITY,ETHNICITY
SM258219,28.95,41.0167,Turkish,ETHNICITY,ETHNICITY
SM258219,28.95,41.0167,Turkish,ETHNICITY,ETHNICITY
SM258219,28.95,41.0167,Turkish,ETHNICITY,ETHNICITY
SM258219,28.95,41.0167,Turkish,ETHNICITY,ETHNICITY
SM258219,28.95,41.0167,Turkish,ETHNICITY,ETHNICITY
SM258219,28.95,41.0167,Turkish,ETHNICITY,ETHNICITY
SM258219,28.95,41.0167,Turkish,ETHNICITY,ETHNICITY
SM258219,28.95,41.0167,Turkish,ETHNICITY,ETHNICITY
SM258219,28.95,41.0167,Turkish,ETHNICITY,ETHNICITY
SM258219,39.7333,41,Turkish,ETHNICITY,ETHNICITY
SM258219,39.7333,41,Turkish,ETHNICITY,ETHNICITY
SM258219,39.7333,41,Turkish,ETHNICITY,ETHNICITY
SM258219,39.7333,41,Turkish,ETHNICITY,ETHNICITY
SM258219,39.7333,41,Turkish,ETHNICITY,ETHNICITY
SM258219,39.7333,41,Turkish,ETHNICITY,ETHNICITY
SM258219,39.7333,41,Turkish,ETHNICITY,ETHNICITY
SM258219,39.7333,41,Turkish,ETHNICITY,ETHNICITY
SM258219,39.7333,41,Turkish,ETHNICITY,ETHNICITY
SM258219,39.7333,41,Turkish,ETHNICITY,ETHNICITY
SM247250,28.8555,41.0268,Turkish Sign Language,LANGUAGE,LANGUAGE
SM3950,68.6667,43.25,Turkistan (excl. Shimkent),ADM1,DISTRICT
SM13280,68.5,43.3333,Turkistan Audany (excl. Kentau and Turkestan)),ADM2,DISTRICT
SM247252,59.1757,37.0872,Turkmen,LANGUAGE,LANGUAGE
SM258220,58.14,38.68,Turkmen,ETHNICITY,ETHNICITY
SM258220,58.14,38.68,Turkmen,ETHNICITY,ETHNICITY
SM258220,58.14,38.68,Turkmen,ETHNICITY,ETHNICITY
SM258220,62,38,Turkmen,ETHNICITY,ETHNICITY
SM258220,62,38,Turkmen,ETHNICITY,ETHNICITY
SM258220,62,38,Turkmen,ETHNICITY,ETHNICITY
SM258220,59.6118,42.464,Turkmen,ETHNICITY,ETHNICITY
SM258220,59.6118,42.464,Turkmen,ETHNICITY,ETHNICITY
SM258220,59.6118,42.464,Turkmen,ETHNICITY,ETHNICITY
SM247256,-71.7451,21.8078,Turks And Caicos Creole English,LANGUAGE,LANGUAGE
SM346814,22.2125,60.5091,Turku,ADM2:ADM3,DISTRICT
SM247249,18.4369,9.0903,Turku,LANGUAGE,LANGUAGE
SM13878,26.2389,56.4468,Turku pagasts,ADM2,DISTRICT
SM13879,21.7438,56.8276,Turlavas pagasts,ADM2,DISTRICT
SM267012,-50.456,-20.0595,Turmalina,ADM2,DISTRICT
SM264026,-42.8304,-17.2535,Turmalina,ADM2,DISTRICT
SM270736,-73.5088,5.3062,Turmequé,ADM2,DISTRICT
SM302536,-97.1487,43.3109,Turner,ADM2,DISTRICT
SM300428,-83.6241,31.7164,Turner,ADM2,DISTRICT
SM323915,-114.2742,50.67,Turner Valley,ADM2:ADM3,DISTRICT
SM12803,-77.2352,18.0665,Turners,ADM2,DISTRICT
SM296374,16.3181,46.6219,Turnišče,ADM1:ADM2,DISTRICT
SM291946,24.8689,43.7465,Turnu Magurele,ADM2,DISTRICT
SM291763,24.3176,45.6467,Turnu Rosu,ADM2,DISTRICT
SM290058,22.3176,45.3977,Turnu Ruieni,ADM2,DISTRICT
SM292875,87.5,51.9167,Turochakskiy rayon,ADM2,DISTRICT
SM247259,42.1451,37.2275,Turoyo,LANGUAGE,LANGUAGE
SM258222,153.71,-26.17,Turrbal,ETHNICITY,ETHNICITY
SM258222,153.71,-26.17,Turrbal,ETHNICITY,ETHNICITY
SM258222,153.0519,-27.5074,Turrbal,ETHNICITY,ETHNICITY
SM258222,153.0519,-27.5074,Turrbal,ETHNICITY,ETHNICITY
SM270920,-83.5158,9.8141,Turrialba,ADM2,DISTRICT
SM270969,-84.5233,9.7373,Turrubares,ADM2,DISTRICT
SM305989,68.1667,38.5833,Tursunzoda,ADM2,DISTRICT
SM291696,23.2097,47.9802,Turt,ADM2,DISTRICT
SM250614,34.89,-5.2,Turu,ETHNICITY,ETHNICITY
SM250614,34.89,-5.2,Turu,ETHNICITY,ETHNICITY
SM250614,34.89,-5.2,Turu,ETHNICITY,ETHNICITY
SM250614,34,-5,Turu,ETHNICITY,ETHNICITY
SM250614,34,-5,Turu,ETHNICITY,ETHNICITY
SM250614,34,-5,Turu,ETHNICITY,ETHNICITY
SM250614,35,-5,Turu,ETHNICITY,ETHNICITY
SM250614,35,-5,Turu,ETHNICITY,ETHNICITY
SM250614,35,-5,Turu,ETHNICITY,ETHNICITY
SM17821,41.5667,21.2,Turubah,ADM2,DISTRICT
SM17119,88,64,Turukhanskiy Rayon,ADM2,DISTRICT
SM291697,23.1038,47.9235,Turulung,ADM2,DISTRICT
SM247265,142.568,-7.9324,Turumsa,LANGUAGE,LANGUAGE
SM247264,92.2676,26.3441,Turung,LANGUAGE,LANGUAGE
SM267865,-39.3756,-3.5334,Tururu,ADM2,DISTRICT
SM265985,-52.1287,-31.5232,Turuçu,ADM2,DISTRICT
SM268191,-50.297,-17.7959,Turvelândia,ADM2,DISTRICT
SM266337,-49.6827,-28.8983,Turvo,ADM2,DISTRICT
SM264763,-51.5086,-24.97,Turvo,ADM2,DISTRICT
SM264027,-45.8009,-21.8724,Turvolandia,ADM2,DISTRICT
SM268190,-50.1691,-16.5581,Turvânia,ADM2,DISTRICT
SM303685,-68.8333,9.1667,Turén,ADM2,DISTRICT
SM295501,-89.7734,13.9619,Turín,ADM2,DISTRICT
SM18108,43.4022,38.4842,Tusba,ADM2,DISTRICT
SM300305,-87.525,33.2896,Tuscaloosa,ADM2,DISTRICT
SM302161,-81.4738,40.441,Tuscarawas,ADM2,DISTRICT
SM247269,-79.4214,43.1303,Tuscarora,LANGUAGE,LANGUAGE
SM301332,-83.4399,43.4913,Tuscola,ADM2,DISTRICT
SM258225,-83.97,35.23,Tuskegee,ETHNICITY,ETHNICITY
SM290726,25.9031,46.2075,Tusnad,ADM2,DISTRICT
SM298233,37.923,37.8053,Tut,ADM2,DISTRICT
SM270737,-73.214,5.6459,Tuta,ADM2,DISTRICT
SM298491,42.7757,39.5504,Tutak,ADM2,DISTRICT
SM17120,39.5833,57.9167,Tutayevskiy Rayon,ADM2,DISTRICT
SM270738,-72.8796,6.0513,Tutazá,ADM2,DISTRICT
SM258226,-141.97,66.26,Tutchone (Northern),ETHNICITY,ETHNICITY
SM258226,-141.97,66.26,Tutchone (Northern),ETHNICITY,ETHNICITY
SM258226,-138.21,61.24,Tutchone (Northern),ETHNICITY,ETHNICITY
SM258226,-138.21,61.24,Tutchone (Northern),ETHNICITY,ETHNICITY
SM258227,-140.43,60.22,Tutchone Southern,ETHNICITY,ETHNICITY
SM247275,-78.75,37.67,Tutelo,LANGUAGE,LANGUAGE
SM258228,-78.67,39.05,Tutelo,ETHNICITY,ETHNICITY
SM13920,-42.3457,-2.8172,Tutoia,ADM2,DISTRICT
SM247277,114.638,4.7433,Tutong,LANGUAGE,LANGUAGE
SM3572,114.7167,4.6333,Tutong,ADM1,DISTRICT
SM290886,27.7987,47.1258,Tutora,ADM2,DISTRICT
SM262388,26.6,44,Tutrakan,ADM2,DISTRICT
SM247279,95.8,27.161,Tutsa Naga,LANGUAGE,LANGUAGE
SM454497,30,-2,Tutsi/Hutu,ETHNICITY,ETHNICITY
SM303909,-64.8885,18.3407,Tutu,ADM2,DISTRICT
SM297854,127.2592,-8.396,Tutuala,ADM2,DISTRICT
SM247280,167.2689,-15.5807,Tutuba,LANGUAGE,LANGUAGE
SM268529,25.9398,-20.2182,Tutume,ADM2,DISTRICT
SM247282,-97.5597,16.1135,Tututepec Mixtec,LANGUAGE,LANGUAGE
SM247281,-124.293,42.5563,Tututni,LANGUAGE,LANGUAGE
SM258229,-124.15,42.82,Tututni,ETHNICITY,ETHNICITY
SM258229,-124.15,42.82,Tututni,ETHNICITY,ETHNICITY
SM258229,-124.15,42.82,Tututni,ETHNICITY,ETHNICITY
SM258229,-124.15,42.82,Tututni,ETHNICITY,ETHNICITY
SM258229,-124,42,Tututni,ETHNICITY,ETHNICITY
SM258229,-124,42,Tututni,ETHNICITY,ETHNICITY
SM258229,-124,42,Tututni,ETHNICITY,ETHNICITY
SM258229,-124,42,Tututni,ETHNICITY,ETHNICITY
SM258229,-124.04,42.61,Tututni,ETHNICITY,ETHNICITY
SM258229,-124.04,42.61,Tututni,ETHNICITY,ETHNICITY
SM258229,-124.04,42.61,Tututni,ETHNICITY,ETHNICITY
SM258229,-124.04,42.61,Tututni,ETHNICITY,ETHNICITY
SM258229,-124.3,42.5,Tututni,ETHNICITY,ETHNICITY
SM258229,-124.3,42.5,Tututni,ETHNICITY,ETHNICITY
SM258229,-124.3,42.5,Tututni,ETHNICITY,ETHNICITY
SM258229,-124.3,42.5,Tututni,ETHNICITY,ETHNICITY
SM3573,95,51.5,Tuva,ADM1,DISTRICT
SM247285,179.1981,-8.5208,Tuvalu,LANGUAGE,LANGUAGE
SM258230,178.68,-7.48,Tuvaluan,ETHNICITY,ETHNICITY
SM247286,94.3667,51.7,Tuvinian,LANGUAGE,LANGUAGE
SM258231,100.29,54.12,Tuvinian,ETHNICITY,ETHNICITY
SM258231,100.29,54.12,Tuvinian,ETHNICITY,ETHNICITY
SM258231,100.29,54.12,Tuvinian,ETHNICITY,ETHNICITY
SM258231,94.47,51.16,Tuvinian,ETHNICITY,ETHNICITY
SM258231,94.47,51.16,Tuvinian,ETHNICITY,ETHNICITY
SM258231,94.47,51.16,Tuvinian,ETHNICITY,ETHNICITY
SM258231,95.1603,50.2596,Tuvinian,ETHNICITY,ETHNICITY
SM258231,95.1603,50.2596,Tuvinian,ETHNICITY,ETHNICITY
SM258231,95.1603,50.2596,Tuvinian,ETHNICITY,ETHNICITY
SM247288,121.113,16.6646,Tuwali Ifugao,LANGUAGE,LANGUAGE
SM247287,142.095,-4.8031,Tuwari,LANGUAGE,LANGUAGE
SM247290,0.3801,7.311,Tuwuli,LANGUAGE,LANGUAGE
SM281749,-104.0107,19.6963,Tuxcacuesco,ADM2,DISTRICT
SM281750,-103.2296,20.1544,Tuxcueca,ADM2,DISTRICT
SM247292,-69.0167,-9.75,Tuxináwa,LANGUAGE,LANGUAGE
SM282826,-97.426,20.9463,Tuxpan,ADM2,DISTRICT
SM282141,-105.3744,21.9615,Tuxpan,ADM2,DISTRICT
SM281752,-103.4274,19.4882,Tuxpan,ADM2,DISTRICT
SM282096,-100.4852,19.5673,Tuxpan,ADM2,DISTRICT
SM282827,-95.8801,18.1879,Tuxtilla,ADM2,DISTRICT
SM283104,-92.1898,14.9241,Tuxtla Chico,ADM2,DISTRICT
SM283105,-93.1362,16.7084,Tuxtla Gutiérrez,ADM2,DISTRICT
SM247291,-38.2742,-8.9536,Tuxá,LANGUAGE,LANGUAGE
SM262223,-3.4167,11.4167,Tuy,ADM2,DISTRICT
SM304374,109.206,13.2507,Tuy An,ADM2,DISTRICT
SM304375,109.2719,13.1146,Tuy Hoà,ADM2,DISTRICT
SM303935,108.7047,11.3475,Tuy Phong,ADM2,DISTRICT
SM304612,109.1528,13.8456,Tuy Phước,ADM2,DISTRICT
SM303997,107.3905,12.1151,Tuy Đức,ADM2,DISTRICT
SM17121,54,54.5,Tuymazinskiy Rayon,ADM2,DISTRICT
SM277483,48.35,34.5667,Tuyserkan,ADM2,DISTRICT
SM247293,-70.0435,0.3944,Tuyuca,LANGUAGE,LANGUAGE
SM258232,-70.5,-13.28,Tuyuneri,ETHNICITY,ETHNICITY
SM304383,106.0268,17.9132,Tuyên Hóa,ADM2,DISTRICT
SM304567,105.2156,21.8281,Tuyên Quang,ADM2,DISTRICT
SM3574,105.25,22.1167,Tuyên Quang,ADM1,DISTRICT
SM14891,-97.5312,20.1041,Tuzamapan de Galeana,ADM2,DISTRICT
SM282097,-100.6173,19.2412,Tuzantla,ADM2,DISTRICT
SM283106,-92.4214,15.1356,Tuzantán,ADM2,DISTRICT
SM293288,47.8333,57.5833,Tuzhinskiy rayon,ADM2,DISTRICT
SM4115,19.3293,42.3635,Tuzi,ADM1,DISTRICT
SM298520,29.3194,40.8637,Tuzla,ADM2,DISTRICT
SM290199,28.6453,44.0443,Tuzla,ADM2,DISTRICT
SM262558,18.6017,44.545,Tuzla,ADM1:ADM2,DISTRICT
SM298470,43.6521,40.0386,Tuzluca,ADM2,DISTRICT
SM298687,31.6305,38.4879,Tuzlukçu,ADM2,DISTRICT
SM305493,17.0598,43.2649,Tučepi,ADM2,DISTRICT
SM304036,103.4176,21.6948,Tuần Giáo,ADM2,DISTRICT
SM262392,26,42.7333,Tvarditsa,ADM2,DISTRICT
SM286304,8.9264,58.6262,Tvedestrand,ADM2,DISTRICT
SM3575,35.0049,57.0096,Tver',ADM1,DISTRICT
SM296182,19.5546,49.3342,Tvrdošín,ADM2,DISTRICT
SM274543,-6.7854,61.5646,Tvøroyri,ADM2,DISTRICT
SM14122,-8.4389,7.1456,Twan River District,ADM2,DISTRICT
SM247300,-121.765,47.7419,Twana,LANGUAGE,LANGUAGE
SM258233,-123.01,47.28,Twana,ETHNICITY,ETHNICITY
SM258233,-123.01,47.28,Twana,ETHNICITY,ETHNICITY
SM258233,-123.01,47.28,Twana,ETHNICITY,ETHNICITY
SM258233,-123.01,47.28,Twana,ETHNICITY,ETHNICITY
SM258233,-123.01,47.28,Twana,ETHNICITY,ETHNICITY
SM258233,-123.01,47.28,Twana,ETHNICITY,ETHNICITY
SM258233,-123.25,47.4165,Twana,ETHNICITY,ETHNICITY
SM258233,-123.25,47.4165,Twana,ETHNICITY,ETHNICITY
SM258233,-123.25,47.4165,Twana,ETHNICITY,ETHNICITY
SM258233,-123.25,47.4165,Twana,ETHNICITY,ETHNICITY
SM258233,-123.25,47.4165,Twana,ETHNICITY,ETHNICITY
SM258233,-123.25,47.4165,Twana,ETHNICITY,ETHNICITY
SM258233,-123,48,Twana,ETHNICITY,ETHNICITY
SM258233,-123,48,Twana,ETHNICITY,ETHNICITY
SM258233,-123,48,Twana,ETHNICITY,ETHNICITY
SM258233,-123,48,Twana,ETHNICITY,ETHNICITY
SM258233,-123,48,Twana,ETHNICITY,ETHNICITY
SM258233,-123,48,Twana,ETHNICITY,ETHNICITY
SM258233,-123.16,47.55,Twana,ETHNICITY,ETHNICITY
SM258233,-123.16,47.55,Twana,ETHNICITY,ETHNICITY
SM258233,-123.16,47.55,Twana,ETHNICITY,ETHNICITY
SM258233,-123.16,47.55,Twana,ETHNICITY,ETHNICITY
SM258233,-123.16,47.55,Twana,ETHNICITY,ETHNICITY
SM258233,-123.16,47.55,Twana,ETHNICITY,ETHNICITY
SM258233,-123,47.3,Twana,ETHNICITY,ETHNICITY
SM258233,-123,47.3,Twana,ETHNICITY,ETHNICITY
SM258233,-123,47.3,Twana,ETHNICITY,ETHNICITY
SM258233,-123,47.3,Twana,ETHNICITY,ETHNICITY
SM258233,-123,47.3,Twana,ETHNICITY,ETHNICITY
SM258233,-123,47.3,Twana,ETHNICITY,ETHNICITY
SM258233,-123.25,47.43,Twana,ETHNICITY,ETHNICITY
SM258233,-123.25,47.43,Twana,ETHNICITY,ETHNICITY
SM258233,-123.25,47.43,Twana,ETHNICITY,ETHNICITY
SM258233,-123.25,47.43,Twana,ETHNICITY,ETHNICITY
SM258233,-123.25,47.43,Twana,ETHNICITY,ETHNICITY
SM258233,-123.25,47.43,Twana,ETHNICITY,ETHNICITY
SM261472,153.3465,-28.3499,Tweed,ADM2,DISTRICT
SM247303,153.287,-28.0287,Tweed-Albert,LANGUAGE,LANGUAGE
SM285573,6.5612,52.4497,Twenterand,ADM2,DISTRICT
SM247304,6.7709,52.2926,Twents,DIALECT,LANGUAGE
SM11444,-1.5985,5.6753,Twifo Ati-Morkwa,ADM2,DISTRICT
SM11445,-1.4346,5.4208,Twifo Hemang Lower Denkyira,ADM2,DISTRICT
SM300429,-83.4271,32.6672,Twiggs,ADM2,DISTRICT
SM300580,-114.6672,42.356,Twin Falls,ADM2,DISTRICT
SM323781,-111.7436,53.7118,Two Hills,ADM2:ADM3,DISTRICT
SM247308,141.984,-12.544,Tyanngayt-Mamngayt-Ntrwangayt-Ntrangit,LANGUAGE,LANGUAGE
SM247310,8.3082,9.7557,Tyap,LANGUAGE,LANGUAGE
SM247311,130.676,-12.4868,Tyaraity,LANGUAGE,LANGUAGE
SM258236,15.68,-16.21,Tyavikwa,ETHNICITY,ETHNICITY
SM293168,88.5,56.25,Tyazhinskiy rayon,ADM2,DISTRICT
SM288740,19.0084,50.1265,Tychy,ADM2,DISTRICT
SM286197,11.6504,63.0448,Tydal,ADM2,DISTRICT
SM258238,14.84,-14.04,Tyilenge-Humbi,ETHNICITY,ETHNICITY
SM258239,14.31,-13.64,Tyilenge-Muso,ETHNICITY,ETHNICITY
SM303150,-80.8848,39.4653,Tyler,ADM2,DISTRICT
SM302783,-94.3766,30.7712,Tyler,ADM2,DISTRICT
SM11089,33.5101,35.1345,Tymvou,ADM2,DISTRICT
SM285548,6.6027,53.1042,Tynaarlo,ADM2,DISTRICT
SM17122,123.5,55.3333,Tyndinskiy Rayon,ADM2,DISTRICT
SM286368,10.7763,62.2739,Tynset,ADM2,DISTRICT
SM296483,18.2807,59.2415,Tyresö,ADM2,DISTRICT
SM258240,10.08,45.64,Tyrolians,ETHNICITY,ETHNICITY
SM302018,-76.1701,35.8702,Tyrrell,ADM2,DISTRICT
SM299981,24.8483,48.9014,Tysmenyts'kyi,ADM2,DISTRICT
SM286400,5.5313,60.0408,Tysnes,ADM2,DISTRICT
SM286144,5.4478,59.4216,Tysvær,ADM2,DISTRICT
SM285746,5.958,53.1997,Tytsjerksteradiel,ADM2,DISTRICT
SM17123,72.5,56,Tyukalinskiy Rayon,ADM2,DISTRICT
SM293460,89.25,57,Tyukhtetskiy rayon,ADM2,DISTRICT
SM279877,70.25,42.5,Tyulkubaskiy,ADM2,DISTRICT
SM294638,50.25,55.9167,Tyulyachinskiy rayon,ADM2,DISTRICT
SM3576,68.5,57.3333,Tyumen',ADM1,DISTRICT
SM293009,81.5,53.25,Tyumentsevskiy rayon,ADM2,DISTRICT
SM299785,28.5006,49.0121,Tyvrivs'kyi,ADM2,DISTRICT
SM247326,-91.2012,14.6351,Tz'utujil,LANGUAGE,LANGUAGE
SM247318,-92.2786,16.6384,Tzeltal,LANGUAGE,LANGUAGE
SM247319,-92.28,16.64,"Tzeltal, Bachajón",LANGUAGE,LANGUAGE
SM258241,-93.45,16.3,Tzeltzal,ETHNICITY,ETHNICITY
SM258241,-93.45,16.3,Tzeltzal,ETHNICITY,ETHNICITY
SM258241,-93.45,16.3,Tzeltzal,ETHNICITY,ETHNICITY
SM258241,-91.75,17,Tzeltzal,ETHNICITY,ETHNICITY
SM258241,-91.75,17,Tzeltzal,ETHNICITY,ETHNICITY
SM258241,-91.75,17,Tzeltzal,ETHNICITY,ETHNICITY
SM258241,-92,17,Tzeltzal,ETHNICITY,ETHNICITY
SM258241,-92,17,Tzeltzal,ETHNICITY,ETHNICITY
SM258241,-92,17,Tzeltzal,ETHNICITY,ETHNICITY
SM282333,-98.0619,18.8593,Tzicatlacoyan,ADM2,DISTRICT
SM283107,-92.2446,16.1212,Tzimol,ADM2,DISTRICT
SM281987,-101.548,19.6059,Tzintzuntzan,ADM2,DISTRICT
SM281988,-100.9085,19.4449,Tzitzio,ADM2,DISTRICT
SM14892,-98.0816,19.3806,Tzompantepec (1995-),ADM2,DISTRICT
SM247320,-92.7388,16.6362,Tzotzil,LANGUAGE,LANGUAGE
SM258243,-93.3,15.9,Tzotzil,ETHNICITY,ETHNICITY
SM247323,-92.74,16.64,"Tzotzil, Chamula",LANGUAGE,LANGUAGE
SM247324,-92.74,16.64,"Tzotzil, Chenalhó",LANGUAGE,LANGUAGE
SM247322,-92.74,16.64,"Tzotzil, Huixtán",LANGUAGE,LANGUAGE
SM247321,-92.74,16.64,"Tzotzil, San Andrés Larrainzar",LANGUAGE,LANGUAGE
SM247325,-92.74,16.64,"Tzotzil, Zinacantán",LANGUAGE,LANGUAGE
SM283036,-89.0365,19.96,Tzucacab,ADM2,DISTRICT
SM246282,106.167,21.6448,Tày,LANGUAGE,LANGUAGE
SM246279,103.805,22.295,Tày Sa Pa,LANGUAGE,LANGUAGE
SM246280,104.008,21.3831,Tày Tac,LANGUAGE,LANGUAGE
SM271153,14.6667,49.4167,Tábor,ADM2,DISTRICT
SM289477,-8.0286,40.3571,Tábua,ADM2,DISTRICT
SM3388,-72.0833,7.8333,Táchira,ADM1,DISTRICT
SM270885,-72.2131,5.8897,Támara,ADM2,DISTRICT
SM270156,-75.7215,5.6705,Támesis,ADM2,DISTRICT
SM303943,107.6843,11.1137,Tánh Linh,ADM2,DISTRICT
SM304299,106.4042,10.5321,Tân An,ADM2,DISTRICT
SM304476,105.9985,11.5579,Tân Biên,ADM2,DISTRICT
SM304111,106.6538,10.8077,Tân Bình,ADM2,DISTRICT
SM304477,106.2827,11.5874,Tân Châu,ADM2,DISTRICT
SM303922,105.19,10.7988,Tân Châu,ADM2,DISTRICT
SM304224,105.2594,10.0954,Tân Hiệp,ADM2,DISTRICT
SM304286,105.6885,10.8338,Tân Hưng,ADM2,DISTRICT
SM304010,105.491,10.8709,Tân Hồng,ADM2,DISTRICT
SM304325,105.1972,19.1164,Tân Kỳ,ADM2,DISTRICT
SM304192,105.234,20.6056,Tân Lạc,ADM2,DISTRICT
SM304006,107.3998,11.3987,Tân Phú,ADM2,DISTRICT
SM304112,106.6232,10.7988,Tân Phú,ADM2,DISTRICT
SM304534,106.6407,10.2615,Tân Phú Đông,ADM2,DISTRICT
SM304535,106.2331,10.5149,Tân Phước,ADM2,DISTRICT
SM304365,104.9627,21.1986,Tân Sơn,ADM2,DISTRICT
SM304599,107.1019,10.5738,Tân Thành,ADM2,DISTRICT
SM304287,105.9658,10.6035,Tân Thạnh,ADM2,DISTRICT
SM304288,106.5164,10.5318,Tân Trụ,ADM2,DISTRICT
SM304262,103.7358,22.1284,Tân Uyên,ADM2,DISTRICT
SM304619,106.7414,11.0523,Tân Uyên,ADM2,DISTRICT
SM304188,106.1085,21.3898,Tân Yên,ADM2,DISTRICT
SM304391,107.4869,15.85,Tây Giang,ADM2,DISTRICT
SM304373,109.2439,12.9465,Tây Hoà,ADM2,DISTRICT
SM304149,105.8197,21.0663,Tây Hồ,ADM2,DISTRICT
SM304478,106.1266,11.3692,Tây Ninh,ADM2,DISTRICT
SM3444,106.1667,11.3333,Tây Ninh,ADM1,DISTRICT
SM304611,108.88,13.9431,Tây Sơn,ADM2,DISTRICT
SM304405,108.3684,15.1683,Tây Trà,ADM2,DISTRICT
SM296482,18.0653,59.4374,Täby,ADM2,DISTRICT
SM271680,12.596,55.6039,Tårnby,ADM2,DISTRICT
SM3449,7.8333,35,Tébessa,ADM1,DISTRICT
SM457358,7.75,36.25,Tébessa District,ADM2,DISTRICT
SM297982,9.2391,36.4708,Téboursouk,ADM2,DISTRICT
SM281634,-100.7626,17.377,Técpan de Galeana,ADM2,DISTRICT
SM457359,-0.5833,34.4167,Télagh District,ADM2,DISTRICT
SM274992,-13.3333,10.9167,Télimélé,ADM1:ADM2,DISTRICT
SM283370,-4.908,14.659,Ténenkou,ADM2,DISTRICT
SM457360,1.25,36.4167,Ténès District,ADM2,DISTRICT
SM276270,17.5161,47.5152,Téti,ADM2,DISTRICT
SM246311,-3.7118,9.8456,Téén,LANGUAGE,LANGUAGE
SM274536,-6.7744,62.0193,Tórshavn,ADM2,DISTRICT
SM283644,102.9838,45.5284,Tögrög,ADM2,DISTRICT
SM283546,94.8156,45.8221,Tögrög,ADM2,DISTRICT
SM283604,100.2566,49.2904,Tömörbulag,ADM2,DISTRICT
SM296605,14.1614,58.68,Töreboda,ADM2,DISTRICT
SM276135,20.4486,47.1821,Törökszentmiklós,ADM2,DISTRICT
SM3525,106.5,47.5,Töv,ADM1,DISTRICT
SM271746,8.8721,55.073,Tønder (2007-),ADM2,DISTRICT
SM270356,-77.6309,1.1355,Túquerres (1992-),ADM2,DISTRICT
SM247106,-117.163,35.327,Tübatulabal,LANGUAGE,LANGUAGE
SM271280,9.5,48.1667,Tübingen,ADM2,DISTRICT
SM283838,96.5427,48.9777,Tüdevtei,ADM2,DISTRICT
SM283673,112.1323,47.6083,Tümentsogt,ADM2,DISTRICT
SM258195,-116.81,36.52,Tümpisa Shoshone (Death Valley),ETHNICITY,ETHNICITY
SM258196,-117.33,36.37,Tümpisa Shoshone (Panamint),ETHNICITY,ETHNICITY
SM258197,-117,36,Tümpisa Shoshone (Saline and Panamint),ETHNICITY,ETHNICITY
SM258198,-117.79,36.65,Tümpisa Shoshone (Saline),ETHNICITY,ETHNICITY
SM283611,100.6282,49.8544,Tünel,ADM2,DISTRICT
SM280346,78.5313,42.7927,Tüp,ADM2,DISTRICT
SM283731,91.6665,50.0971,Türgen,ADM2,DISTRICT
SM298914,34.3482,41.942,Türkeli,ADM2,DISTRICT
SM298559,36.8463,37.3842,Türkoğlu,ADM2,DISTRICT
SM283661,105.0466,50.3251,Tüshig,ADM2,DISTRICT
SM283674,111.8161,46.1874,Tüvshinshiree,ADM2,DISTRICT
SM283532,101.9083,47.3748,Tüvshrüülekh,ADM2,DISTRICT
SM304522,105.7284,19.4453,Tĩnh Gia,ADM2,DISTRICT
SM279389,140.3671,35.5652,Tōgane,ADM2,DISTRICT
SM278070,137.0658,35.1081,Tōgō,ADM2,DISTRICT
SM278710,136.591,35.0789,Tōin,ADM2,DISTRICT
SM278071,136.8985,35.0268,Tōkai,ADM2,DISTRICT
SM278949,138.7667,37.1333,Tōkamachi,ADM2,DISTRICT
SM278858,138.3687,36.3739,Tōmi,ADM2,DISTRICT
SM278457,141.5092,39.3648,Tōno,ADM2,DISTRICT
SM279632,132.8723,33.791,Tōon,ADM2,DISTRICT
SM289064,126.2731,39.7553,Tŏkch'ŏn,ADM2,DISTRICT
SM271219,15.9333,49.1333,Třebíč,ADM2,DISTRICT
SM304407,108.7705,15.095,Tư Nghĩa,ADM2,DISTRICT
SM304328,104.5779,19.3098,Tương Dương,ADM2,DISTRICT
SM261891,46.9429,40.3305,Tərtər (excl. Nagorno Karabakh),ADM1:ADM2,DISTRICT
SM303921,105.0091,10.559,Tịnh Biên,ADM2,DISTRICT
SM304043,103.3733,21.9536,Tủa Chùa,ADM2,DISTRICT
SM304063,106.4136,20.8041,Tứ Kỳ,ADM2,DISTRICT
SM304434,105.9593,21.13,Từ Sơn,ADM2,DISTRICT
SM247834,101.772,22.6767,U,LANGUAGE,LANGUAGE
SM303961,104.9412,9.3677,U Minh,ADM2,DISTRICT
SM304225,105.1191,9.6419,U Minh Thượng,ADM2,DISTRICT
SM14985,158.2736,6.9497,U Municipality,ADM2,DISTRICT
SM297609,99.8895,14.4109,U Thong,ADM2,DISTRICT
SM258334,-72,7,U'wa,ETHNICITY,ETHNICITY
SM454423,54.5,24.4,UAE Arab,ETHNICITY,ETHNICITY
SM305251,32.0257,-17.0378,UMP,ADM2,DISTRICT
SM258316,-87.667,41.833,US-African American,ETHNICITY,ETHNICITY
SM11276,-139.5551,-8.8999,Ua Huka,ADM2,DISTRICT
SM11277,-140.0759,-9.3922,Ua-Pou,ADM2,DISTRICT
SM247328,124.144,-9.7728,Uab Meto,LANGUAGE,LANGUAGE
SM258245,124.9,-9.29,Uab Meto,ETHNICITY,ETHNICITY
SM3577,166.9258,-0.514,Uaboe,ADM1,DISTRICT
SM247332,-68.4357,-2.6764,Uainuma-Mariate,LANGUAGE,LANGUAGE
SM247332,-68.4357,-2.6764,Uainuma-Mariate,LANGUAGE,LANGUAGE
SM247332,-71.1493,-1.6761,Uainuma-Mariate,LANGUAGE,LANGUAGE
SM247332,-71.1493,-1.6761,Uainuma-Mariate,LANGUAGE,LANGUAGE
SM258246,-65.15,3.61,Uaka,ETHNICITY,ETHNICITY
SM258247,71.97,35.93,Uakhani,ETHNICITY,ETHNICITY
SM279973,72.3354,53.6259,Ualikhanovskiy,ADM2,DISTRICT
SM247333,-38.75,-8.5,Uamué,LANGUAGE,LANGUAGE
SM258249,-69.33,0.68,Uanan,ETHNICITY,ETHNICITY
SM247335,147.687,-9.6957,Uare,LANGUAGE,LANGUAGE
SM267357,-65.4204,-3.1474,Uarini,ADM2,DISTRICT
SM3578,35.3167,0.5,Uasin Gishu,ADM1:ADM2,DISTRICT
SM297812,126.6806,-8.7566,Uato Carbau,ADM2,DISTRICT
SM297813,126.5735,-8.7628,Uatolari,ADM2,DISTRICT
SM267692,-39.2303,-9.8414,Uauá,ADM2,DISTRICT
SM283037,-88.3522,20.7439,Uayma,ADM2,DISTRICT
SM234105,-51.6888,3.4283,Uaçá Creole French,LANGUAGE,LANGUAGE
SM247338,7.9644,5.7234,Ubaghara,LANGUAGE,LANGUAGE
SM267694,-39.3808,-14.2705,Ubaitaba,ADM2,DISTRICT
SM267866,-40.9342,-3.8402,Ubajara,ADM2,DISTRICT
SM269960,-73.477,4.8082,Ubalá,ADM2,DISTRICT
SM258250,37,6,Ubamer,ETHNICITY,ETHNICITY
SM247339,9.2616,6.4091,Ubang,LANGUAGE,LANGUAGE
SM264030,-42.059,-19.6619,Ubaporanga,ADM2,DISTRICT
SM269961,-73.9966,4.4844,Ubaque,ADM2,DISTRICT
SM267013,-49.7298,-21.2204,Ubarana,ADM2,DISTRICT
SM267014,-45.0244,-23.3826,Ubatuba,ADM2,DISTRICT
SM267695,-39.5228,-14.0563,Ubatã,ADM2,DISTRICT
SM264029,-44.8553,-16.3834,Ubaí,ADM2,DISTRICT
SM267693,-39.6935,-13.287,Ubaíra,ADM2,DISTRICT
SM279584,131.2731,34.0491,Ube,ADM2,DISTRICT
SM264031,-47.9414,-19.5483,Uberaba,ADM2,DISTRICT
SM264032,-48.3348,-19.0233,Uberlândia,ADM2,DISTRICT
SM247342,18.558,11.8006,Ubi,LANGUAGE,LANGUAGE
SM293881,79.5,55.25,Ubinskiy rayon,ADM2,DISTRICT
SM247344,149.396,-9.5904,Ubir,LANGUAGE,LANGUAGE
SM267015,-49.6628,-22.5513,Ubirajara,ADM2,DISTRICT
SM264764,-53.0193,-24.504,Ubiratã,ADM2,DISTRICT
SM265986,-54.6625,-28.0519,Ubiretama,ADM2,DISTRICT
SM296990,102.6754,16.7777,Ubol Ratana,ADM2,DISTRICT
SM3579,105.1667,15.1667,Ubon Ratchathani (1993-),ADM1,DISTRICT
SM247349,39.67,43.67,Ubykh,LANGUAGE,LANGUAGE
SM264028,-42.9729,-21.1148,Ubá,ADM2,DISTRICT
SM261901,47.75,40.4167,Ucar,ADM1:ADM2,DISTRICT
SM286783,-75.25,-7.1667,Ucayali,ADM2,DISTRICT
SM3580,-73.5,-9.75,Ucayali,ADM1,DISTRICT
SM247350,-73.1836,-9.4942,Ucayali-Yurúa Ashéninka,LANGUAGE,LANGUAGE
SM292658,24.6848,45.7798,Ucea De Jos,ADM2,DISTRICT
SM294293,59.25,54.25,Uchalinskiy rayon,ADM2,DISTRICT
SM279633,132.7557,33.57,Uchiko,ADM2,DISTRICT
SM303476,71,40.5833,Uchko'prik,ADM2,DISTRICT
SM267016,-49.1365,-20.9185,Uchoa,ADM2,DISTRICT
SM303546,72.1082,41.0772,Uchqo'rg'on,ADM2,DISTRICT
SM303553,63.5683,42.1369,Uchquduq,ADM2,DISTRICT
SM282934,-89.7392,21.0261,Ucú,ADM2,DISTRICT
SM247353,8.0656,4.702,Uda,LANGUAGE,LANGUAGE
SM291302,24.5858,44.8759,Uda,ADM2,DISTRICT
SM278920,135.9979,34.5204,Uda,ADM2,DISTRICT
SM13114,136.1452,34.4924,Uda Shi,ADM2,DISTRICT
SM277189,73.77,24.33,Udaipur (2008-),ADM2,DISTRICT
SM250758,0,16,Udalan,ETHNICITY,ETHNICITY
SM277395,92.0921,26.7336,Udalguri,ADM2,DISTRICT
SM258253,7.07,9.09,Udam,ETHNICITY,ETHNICITY
SM258254,153.02,-27.27,Udanbi,ETHNICITY,ETHNICITY
SM375327,86.5199,26.9331,Udayapur,ADM2:ADM3,DISTRICT
SM305752,15.7666,44.5311,Udbina,ADM2,DISTRICT
SM296608,11.8474,58.341,Uddevalla,ADM2,DISTRICT
SM258256,138.68,49.79,Udege,ETHNICITY,ETHNICITY
SM258256,138.68,49.79,Udege,ETHNICITY,ETHNICITY
SM258256,136,47,Udege,ETHNICITY,ETHNICITY
SM258256,136,47,Udege,ETHNICITY,ETHNICITY
SM285955,5.6126,51.6568,Uden,ADM2,DISTRICT
SM284727,7.5671,6.872,Udenu,ADM2,DISTRICT
SM291853,26.4096,47.5715,Udesti,ADM2,DISTRICT
SM277346,79.5235,29.0275,Udham Singh Nagar,ADM2,DISTRICT
SM276891,75.1667,33,Udhampur (2006-),ADM2,DISTRICT
SM247359,47.7236,40.9,Udi,LANGUAGE,LANGUAGE
SM284728,7.3554,6.5118,Udi,ADM2,DISTRICT
SM247358,135.678,46.6309,Udihe,LANGUAGE,LANGUAGE
SM277959,13.1779,46.1641,Udine,ADM2,DISTRICT
SM247360,52.6355,56.1292,Udmurt,LANGUAGE,LANGUAGE
SM3581,53,57,Udmurt,ADM1,DISTRICT
SM258257,51.49,53.83,Udmurt,ETHNICITY,ETHNICITY
SM258257,51.49,53.83,Udmurt,ETHNICITY,ETHNICITY
SM258257,51.49,53.83,Udmurt,ETHNICITY,ETHNICITY
SM258257,53,57,Udmurt,ETHNICITY,ETHNICITY
SM258257,53,57,Udmurt,ETHNICITY,ETHNICITY
SM258257,53,57,Udmurt,ETHNICITY,ETHNICITY
SM258257,52.37,56.09,Udmurt,ETHNICITY,ETHNICITY
SM258257,52.37,56.09,Udmurt,ETHNICITY,ETHNICITY
SM258257,52.37,56.09,Udmurt,ETHNICITY,ETHNICITY
SM17124,35,57.9167,Udomel'skiy Rayon,ADM2,DISTRICT
SM3582,102.8333,17.3333,Udon Thani (1993-),ADM1,DISTRICT
SM293309,48.8333,63.5,Udorskiy rayon,ADM2,DISTRICT
SM14986,151.708,7.389,Udot-Fonuweisom Municipality,ADM2,DISTRICT
SM13880,27.0641,55.9181,Udrisu pagasts,ADM2,DISTRICT
SM284658,5.822,5.4722,Udu,ADM2,DISTRICT
SM247362,34.2293,9.1601,Uduk,LANGUAGE,LANGUAGE
SM258258,31,11.95,Uduk,ETHNICITY,ETHNICITY
SM285104,8.2653,4.7523,Udung Uko,ADM2,DISTRICT
SM276943,74.87,13.5,Udupi,ADM2,DISTRICT
SM278860,138.2419,36.3835,Ueda,ADM2,DISTRICT
SM279614,139.05,35.65,Uenohara,ADM2,DISTRICT
SM18109,29.0598,41.0284,Ueskuedar,ADM2,DISTRICT
SM247368,146.245,-6.0513,Ufim,LANGUAGE,LANGUAGE
SM294294,55.6667,54.75,Ufimskiy rayon,ADM2,DISTRICT
SM13881,21.9699,57.2378,Ugales pagasts,ADM2,DISTRICT
SM247373,32.4829,0.2475,Ugandan Sign Language,LANGUAGE,LANGUAGE
SM262549,24.4167,43.1,Ugarchin,ADM2,DISTRICT
SM247376,35.78,35.6,Ugaritic,LANGUAGE,LANGUAGE
SM258261,123.98,-16.6,Ugarung,ETHNICITY,ETHNICITY
SM258261,123.98,-16.6,Ugarung,ETHNICITY,ETHNICITY
SM258261,123.6707,-16.6936,Ugarung,ETHNICITY,ETHNICITY
SM258261,123.6707,-16.6936,Ugarung,ETHNICITY,ETHNICITY
SM247381,157.341,-8.4573,Ughele,LANGUAGE,LANGUAGE
SM284659,6.0482,5.5112,Ughelli North,ADM2,DISTRICT
SM284661,5.9511,5.3435,Ughelli South,ADM2,DISTRICT
SM295411,161.7427,-10.2561,Ugi and Pio,ADM2,DISTRICT
SM17125,142.0833,49.0833,Uglegorskiy Rayon,ADM2,DISTRICT
SM17126,38.4167,57.5,Uglichskiy Rayon,ADM2,DISTRICT
SM313204,18.9938,44.693,Ugljevik,ADM2:ADM3,DISTRICT
SM293010,80.0833,51.3333,Uglovskiy rayon,ADM2,DISTRICT
SM247382,100.098,14.2408,Ugong,LANGUAGE,LANGUAGE
SM294464,34.3333,54.75,Ugranskiy rayon,ADM2,DISTRICT
SM283715,105.4138,48.2074,Ugtaal,ADM2,DISTRICT
SM283715,105.4138,48.2074,Ugtaal,ADM2,DISTRICT
SM283715,105.4121,48.2503,Ugtaal,ADM2,DISTRICT
SM283715,105.4121,48.2503,Ugtaal,ADM2,DISTRICT
SM305120,30.2748,-30.5437,Ugu,ADM2,DISTRICT
SM284635,7.3394,5.0063,Ugwunagbo,ADM2,DISTRICT
SM247385,5.6854,7.3814,Uhami,LANGUAGE,LANGUAGE
SM271203,17.55,49.0167,Uherské Hradiště,ADM2,DISTRICT
SM258264,137.6,-4.15,Uhunduni,ETHNICITY,ETHNICITY
SM284696,5.8845,6.4922,Uhunmwonde,ADM2,DISTRICT
SM267696,-42.1561,-11.3906,Uibaí,ADM2,DISTRICT
SM247386,87.38,43.88,Uighur,LANGUAGE,LANGUAGE
SM258265,88.16,40.86,Uigur,ETHNICITY,ETHNICITY
SM258265,88.16,40.86,Uigur,ETHNICITY,ETHNICITY
SM258265,88.16,40.86,Uigur,ETHNICITY,ETHNICITY
SM258265,80.57,35.44,Uigur,ETHNICITY,ETHNICITY
SM258265,80.57,35.44,Uigur,ETHNICITY,ETHNICITY
SM258265,80.57,35.44,Uigur,ETHNICITY,ETHNICITY
SM258265,81,44,Uigur,ETHNICITY,ETHNICITY
SM258265,81,44,Uigur,ETHNICITY,ETHNICITY
SM258265,81,44,Uigur,ETHNICITY,ETHNICITY
SM258265,81,44,Uigur,ETHNICITY,ETHNICITY
SM258265,81,44,Uigur,ETHNICITY,ETHNICITY
SM258265,81,44,Uigur,ETHNICITY,ETHNICITY
SM280719,127.0477,37.7387,Uijeongbu,ADM2,DISTRICT
SM292483,22.2242,46.7022,Uileacu De Beius,ADM2,DISTRICT
SM279931,55,49.3333,Uilskiy,ADM2,DISTRICT
SM303308,-110.5476,41.2876,Uinta,ADM2,DISTRICT
SM302909,-109.5184,40.125,Uintah,ADM2,DISTRICT
SM258266,-110.35,40.47,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-110.35,40.47,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-110.35,40.47,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-110.35,40.47,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-112,40,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-112,40,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-112,40,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-112,40,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-110.22,40.47,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-110.22,40.47,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-110.22,40.47,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-110.22,40.47,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-111,40,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-111,40,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-111,40,Uintah Ute,ETHNICITY,ETHNICITY
SM258266,-111,40,Uintah Ute,ETHNICITY,ETHNICITY
SM266130,-60.2519,4.6527,Uiramutã,ADM2,DISTRICT
SM268192,-49.934,-14.1413,Uirapuru,ADM2,DISTRICT
SM264353,-38.3802,-6.4965,Uiraúna,ADM2,DISTRICT
SM247388,-63.7665,0.6625,Uirina,LANGUAGE,LANGUAGE
SM247388,-63.7665,0.6625,Uirina,LANGUAGE,LANGUAGE
SM247388,-64.2659,0.4871,Uirina,LANGUAGE,LANGUAGE
SM247388,-64.2659,0.4871,Uirina,LANGUAGE,LANGUAGE
SM280552,128.2692,35.3923,Uiryeong,ADM2,DISTRICT
SM247389,155.706,-6.5553,Uisai,LANGUAGE,LANGUAGE
SM280740,128.6152,36.3612,Uiseong,ADM2,DISTRICT
SM286013,4.7118,52.5286,Uitgeest,ADM2,DISTRICT
SM286015,4.8307,52.24,Uithoorn,ADM2,DISTRICT
SM280720,126.9758,37.345,Uiwang,ADM2,DISTRICT
SM4089,165.6278,9.0804,Ujae Atoll,ADM1,DISTRICT
SM4090,160.9167,9.8167,Ujelang Atoll,ADM1,DISTRICT
SM247394,34.5094,-9.2455,Ujindile,DIALECT,LANGUAGE
SM247395,134.257,-5.6933,Ujir,LANGUAGE,LANGUAGE
SM278682,135.8204,34.903,Ujitawara,ADM2,DISTRICT
SM277010,75.5,23.4167,Ujjain,ADM2,DISTRICT
SM247398,6.0393,7.5972,Ukaan,LANGUAGE,LANGUAGE
SM247400,35.2805,-9.1859,Ukalawa,DIALECT,LANGUAGE
SM285105,7.5969,4.8965,Ukanafun,ADM2,DISTRICT
SM299135,33,-2.04,Ukerewe,ADM2,DISTRICT
SM11695,94.3588,25.0483,Ukhrul (2016-),ADM2,DISTRICT
SM247403,16.267,2.9095,Ukhwejo,LANGUAGE,LANGUAGE
SM278654,130.6549,32.6267,Uki,ADM2,DISTRICT
SM279717,130.7933,33.3118,Ukiha,ADM2,DISTRICT
SM247406,114.579,1.5662,Ukit,LANGUAGE,LANGUAGE
SM281325,24.75,55.25,Ukmergės,ADM2,DISTRICT
SM247407,9.026,6.5288,Ukpe-Bayobiri,LANGUAGE,LANGUAGE
SM247409,8.1005,5.5832,Ukpet-Ehom,LANGUAGE,LANGUAGE
SM247412,29.945,49.796,Ukrainian,LANGUAGE,LANGUAGE
SM258273,24,49.39,Ukrainian,ETHNICITY,ETHNICITY
SM258273,24,49.39,Ukrainian,ETHNICITY,ETHNICITY
SM258273,24,49.39,Ukrainian,ETHNICITY,ETHNICITY
SM258273,24,49.39,Ukrainian,ETHNICITY,ETHNICITY
SM258273,36.23,48.45,Ukrainian,ETHNICITY,ETHNICITY
SM258273,36.23,48.45,Ukrainian,ETHNICITY,ETHNICITY
SM258273,36.23,48.45,Ukrainian,ETHNICITY,ETHNICITY
SM258273,36.23,48.45,Ukrainian,ETHNICITY,ETHNICITY
SM258273,36,48,Ukrainian,ETHNICITY,ETHNICITY
SM258273,36,48,Ukrainian,ETHNICITY,ETHNICITY
SM258273,36,48,Ukrainian,ETHNICITY,ETHNICITY
SM258273,36,48,Ukrainian,ETHNICITY,ETHNICITY
SM258273,33.87,50.82,Ukrainian,ETHNICITY,ETHNICITY
SM258273,33.87,50.82,Ukrainian,ETHNICITY,ETHNICITY
SM258273,33.87,50.82,Ukrainian,ETHNICITY,ETHNICITY
SM258273,33.87,50.82,Ukrainian,ETHNICITY,ETHNICITY
SM258273,31.5574,50.2871,Ukrainian,ETHNICITY,ETHNICITY
SM258273,31.5574,50.2871,Ukrainian,ETHNICITY,ETHNICITY
SM258273,31.5574,50.2871,Ukrainian,ETHNICITY,ETHNICITY
SM258273,31.5574,50.2871,Ukrainian,ETHNICITY,ETHNICITY
SM247411,30.553,50.4111,Ukrainian Sign Language,LANGUAGE,LANGUAGE
SM13882,23.0939,56.3636,Ukru pagasts,ADM2,DISTRICT
SM247416,5.8514,7.4164,Ukue,LANGUAGE,LANGUAGE
SM285351,9.5636,7.6439,Ukum,ADM2,DISTRICT
SM260458,15.1236,-12.7388,Ukuma,ADM2,DISTRICT
SM247419,145.514,-4.9441,Ukuriguma,LANGUAGE,LANGUAGE
SM247420,7.96,5.5991,Ukwa,LANGUAGE,LANGUAGE
SM284636,7.3667,4.9,Ukwa East,ADM2,DISTRICT
SM284637,7.2393,4.954,Ukwa West,ADM2,DISTRICT
SM284662,6.2374,5.8448,Ukwuani,ADM2,DISTRICT
SM247421,6.4856,5.7889,Ukwuani-Aboh-Ndoni,LANGUAGE,LANGUAGE
SM293191,139.5,51.8333,Ul'chskiy rayon,ADM2,DISTRICT
SM299517,30.2353,48.3321,Ul'ianovs'kyi,ADM2,DISTRICT
SM13281,74,50,"Ul'yanov Audany (excl. Sorang, Bukhar-Zhyrau,Qaraghandy)",ADM2,DISTRICT
SM3584,48,54,Ul'yanovsk,ADM1,DISTRICT
SM293084,35.6667,53.6667,Ul'yanovskiy rayon,ADM2,DISTRICT
SM298762,28.4273,37.1151,Ula,ADM2,DISTRICT
SM269266,112.0912,41.7338,Ulaan Chab,ADM2,DISTRICT
SM283612,99.2304,50.6774,Ulaan-Uul,ADM2,DISTRICT
SM3585,106.9167,47.9167,Ulaanbaatar,ADM1:ADMX,DISTRICT
SM283801,110.4668,43.5746,Ulaanbadrakh,ADM2,DISTRICT
SM283732,92.0661,49.9754,Ulaangom,ADM2,DISTRICT
SM283689,89.4375,49.0357,Ulaankhus,ADM2,DISTRICT
SM250146,-3.68,31.42,Ulad Yihya,ETHNICITY,ETHNICITY
SM292876,88.5,50.8333,Ulaganskiy rayon,ADM2,DISTRICT
SM13282,82.75,49.5833,Ulan Audany (excl. Oskemen and Tavriya),ADM2,DISTRICT
SM299120,36.6133,-8.9889,Ulanga,ADM2,DISTRICT
SM247425,142.845,-3.3288,Ulau-Suain,LANGUAGE,LANGUAGE
SM258275,161.44,-9.63,Ulawan,ETHNICITY,ETHNICITY
SM298928,37.0454,39.4546,Ulaş,ADM2,DISTRICT
SM247428,140.29,51.8521,Ulch,LANGUAGE,LANGUAGE
SM258276,136,50,Ulch,ETHNICITY,ETHNICITY
SM258276,136,50,Ulch,ETHNICITY,ETHNICITY
SM258276,140.41,51.93,Ulch,ETHNICITY,ETHNICITY
SM258276,140.41,51.93,Ulch,ETHNICITY,ETHNICITY
SM3586,19.2683,41.9553,Ulcinj,ADM1,DISTRICT
SM295103,112,51,Uletovskiy rayon,ADM2,DISTRICT
SM264122,-47.5009,-3.8123,Ulianópolis,ADM2,DISTRICT
SM15010,96.8491,47.7339,Uliastay,ADM2,DISTRICT
SM290727,25.2705,46.2115,Ulies,ADM2,DISTRICT
SM290313,25.4077,44.6016,Uliesti,ADM2,DISTRICT
SM14987,139.6,9.9,Ulithi Municipality,ADM2,DISTRICT
SM247432,139.6036,9.9614,Ulithian,LANGUAGE,LANGUAGE
SM258278,140.4,8.12,Ulithian,ETHNICITY,ETHNICITY
SM258278,140.4,8.12,Ulithian,ETHNICITY,ETHNICITY
SM258278,139.79,10.02,Ulithian,ETHNICITY,ETHNICITY
SM258278,139.79,10.02,Ulithian,ETHNICITY,ETHNICITY
SM280741,129.3197,36.9197,Uljin,ADM2,DISTRICT
SM280640,129.1269,35.5623,Ulju,ADM2,DISTRICT
SM251551,-126,53,Ulkatchotʼen,ETHNICITY,ETHNICITY
SM251551,-126,53,Ulkatchotʼen,ETHNICITY,ETHNICITY
SM251551,-126,53,Ulkatchotʼen,ETHNICITY,ETHNICITY
SM251551,-124.02,51.32,Ulkatchotʼen,ETHNICITY,ETHNICITY
SM251551,-124.02,51.32,Ulkatchotʼen,ETHNICITY,ETHNICITY
SM251551,-124.02,51.32,Ulkatchotʼen,ETHNICITY,ETHNICITY
SM251551,-127,55,Ulkatchotʼen,ETHNICITY,ETHNICITY
SM251551,-127,55,Ulkatchotʼen,ETHNICITY,ETHNICITY
SM251551,-127,55,Ulkatchotʼen,ETHNICITY,ETHNICITY
SM13283,84.5,49.25,Ulkennaryn Audany,ADM2,DISTRICT
SM247434,77.0906,9.4672,Ullatan,LANGUAGE,LANGUAGE
SM286047,11.1744,60.144,Ullensaker,ADM2,DISTRICT
SM461884,6.7207,60.375,Ullensverg (2020-),ADM2,DISTRICT
SM280742,130.8608,37.5044,Ulleung,ADM2,DISTRICT
SM270635,-75.7778,4.7075,Ulloa,ADM2,DISTRICT
SM260973,-68.9167,-31,Ullum,ADM2,DISTRICT
SM291854,25.2647,47.8632,Ulma,ADM2,DISTRICT
SM291045,23.2793,47.4621,Ulmeni,ADM2,DISTRICT
SM289926,26.6236,45.0688,Ulmeni,ADM2,DISTRICT
SM289978,26.7279,44.1459,Ulmeni,ADM2,DISTRICT
SM292248,23.9816,44.9822,Ulmetu,ADM2,DISTRICT
SM290314,25.4438,44.8903,Ulmi,ADM2,DISTRICT
SM290598,25.7669,44.4934,Ulmi,ADM2,DISTRICT
SM289979,26.9181,44.2873,Ulmu,ADM2,DISTRICT
SM292701,27.2984,44.9482,Ulmu,ADM2,DISTRICT
SM296609,13.4612,57.8206,Ulricehamn,ADM2,DISTRICT
SM3587,129.2667,35.5667,Ulsan,ADM1,DISTRICT
SM286439,5.8524,62.3442,Ulstein,ADM2,DISTRICT
SM301919,-74.2586,41.8882,Ulster,ADM2,DISTRICT
SM4175,-7.9395,54.9273,Ulster,ADM1,DISTRICT
SM12804,-77.4968,18.3211,Ulster Spring,ADM2,DISTRICT
SM247437,-96.05,17.36,Ulterior-Mixe,LANGUAGE,LANGUAGE
SM295756,-88.0675,13.4796,Uluazapa,ADM2,DISTRICT
SM298807,37.7589,40.8735,Ulubey,ADM2,DISTRICT
SM298991,29.2964,38.4296,Ulubey,ADM2,DISTRICT
SM298473,30.457,38.0886,Uluborlu,ADM2,DISTRICT
SM298921,42.8522,37.4462,Uludere,ADM2,DISTRICT
SM18456,71.7,40.75,Ulugnor Tumani,ADM2,DISTRICT
SM18457,71.6707,40.7719,Ulugnar,ADM2,DISTRICT
SM247446,6.592,6.4974,Ulukwumi,LANGUAGE,LANGUAGE
SM298798,34.5874,37.5459,Ulukışla,ADM2,DISTRICT
SM247450,118.994,-3.0093,Ulumanda',LANGUAGE,LANGUAGE
SM298183,32.6507,41.5937,Ulus,ADM2,DISTRICT
SM258281,-86.89,13.49,Ulva,ETHNICITY,ETHNICITY
SM286402,6.9187,60.5672,Ulvik,ADM2,DISTRICT
SM247457,-83.5815,12.9323,Ulwa,LANGUAGE,LANGUAGE
SM249495,143.993,-4.3991,Ulwa (Papua New Guinea),LANGUAGE,LANGUAGE
SM17127,48.0169,54.3353,Ulyanovskiy Rayon,ADM2,DISTRICT
SM13284,66.5,48.6667,"Ulytau Audany (excl. Zhezdi, Zhezqazghan,Satbaev)",ADM2,DISTRICT
SM295172,33.4753,14.1714,Um Al Gura,ADM2,DISTRICT
SM295245,31.23,13.21,Um Rawaba,ADM2,DISTRICT
SM247459,119.968,-1.7042,Uma,LANGUAGE,LANGUAGE
SM247467,114.205,2.1414,Uma' Lasan,LANGUAGE,LANGUAGE
SM247466,116.5,3.25,Uma' Lung,LANGUAGE,LANGUAGE
SM10540,13.5259,45.4346,Umag-Umago (excl. Grad Umag),ADM2,DISTRICT
SM18281,30.2196,48.7504,Uman Raion,ADM2,DISTRICT
SM14988,151.877,7.299,Uman-Fonuweisom Municipality,ADM2,DISTRICT
SM258282,-56.82,74.97,Umanak,ETHNICITY,ETHNICITY
SM247469,149.658,-9.8967,Umanakaina,LANGUAGE,LANGUAGE
SM267867,-38.7195,-6.6092,Umari,ADM2,DISTRICT
SM277011,80.8305,23.5287,Umaria,ADM2,DISTRICT
SM265508,-37.807,-6.0059,Umarizal,ADM2,DISTRICT
SM3588,144.6691,13.3074,Umatac,ADM1,DISTRICT
SM247471,-118.552,45.6391,Umatilla,LANGUAGE,LANGUAGE
SM302272,-118.7368,45.5919,Umatilla,ADM2,DISTRICT
SM258283,-120.99,44.71,Umatillo,ETHNICITY,ETHNICITY
SM258283,-120.99,44.71,Umatillo,ETHNICITY,ETHNICITY
SM258283,-120.99,44.71,Umatillo,ETHNICITY,ETHNICITY
SM258283,-119,46,Umatillo,ETHNICITY,ETHNICITY
SM258283,-119,46,Umatillo,ETHNICITY,ETHNICITY
SM258283,-119,46,Umatillo,ETHNICITY,ETHNICITY
SM258283,-119.94,45.49,Umatillo,ETHNICITY,ETHNICITY
SM258283,-119.94,45.49,Umatillo,ETHNICITY,ETHNICITY
SM258283,-119.94,45.49,Umatillo,ETHNICITY,ETHNICITY
SM267148,-37.6627,-11.4005,Umbauba,ADM2,DISTRICT
SM247476,143.328,-13.921,Umbindhamu,LANGUAGE,LANGUAGE
SM270739,-73.4834,5.1841,Umbita,ADM2,DISTRICT
SM290475,27.4589,45.7139,Umbraresti,ADM2,DISTRICT
SM3589,12.5,43,Umbria,ADM1,DISTRICT
SM247477,13.02,42.75,Umbrian,LANGUAGE,LANGUAGE
SM247481,143.97,-5.9513,Umbu-Ungu,LANGUAGE,LANGUAGE
SM247478,131.825,-12.9325,Umbugarla,LANGUAGE,LANGUAGE
SM247480,14.4148,-12.4763,Umbundu,LANGUAGE,LANGUAGE
SM267699,-41.1742,-10.5086,Umburanas,ADM2,DISTRICT
SM264033,-40.6688,-17.2566,Umburatiba,ADM2,DISTRICT
SM247479,143.6305,-14.2588,Umbuygamu,LANGUAGE,LANGUAGE
SM264354,-35.7393,-7.6711,Umbuzeiro,ADM2,DISTRICT
SM247488,18.646,64.6383,Ume Saami,LANGUAGE,LANGUAGE
SM247483,141.157,-3.3457,Umeda-Punda,LANGUAGE,LANGUAGE
SM375500,69.73,25.37,Umerkot,ADM2:ADM3,DISTRICT
SM296522,20.2548,63.8299,Umeå,ADM2,DISTRICT
SM305121,30.1984,-29.5093,Umgungundlovu,ADM2,DISTRICT
SM305272,28.3141,-19.8532,Umguza,ADM2,DISTRICT
SM458644,123.8597,-16.3228,Umida,ETHNICITY,ETHNICITY
SM247490,124.0792,-16.6155,Umiida,DIALECT,LANGUAGE
SM247489,124.5,-16.5593,Umiida-Unggarangu,LANGUAGE,LANGUAGE
SM247491,121.508,14.8757,Umiray Dumaget Agta,LANGUAGE,LANGUAGE
SM267868,-39.3969,-3.706,Umirim,ADM2,DISTRICT
SM305122,32.3295,-27.6224,Umkhanyakude,ADM2,DISTRICT
SM17822,37.4167,25.3333,Umluj,ADM2,DISTRICT
SM3591,51.3333,25.4667,Umm Salal,ADM1,DISTRICT
SM305294,55.7352,25.4933,Umm al-Qaywayn,ADM2,DISTRICT
SM3590,55.75,25.5,Umm al-Qaywayn,ADM1,DISTRICT
SM247494,8.1034,5.3744,Umon,LANGUAGE,LANGUAGE
SM258284,-60.44,-15.14,Umotina,ETHNICITY,ETHNICITY
SM258284,-60.44,-15.14,Umotina,ETHNICITY,ETHNICITY
SM258284,-57,-15,Umotina,ETHNICITY,ETHNICITY
SM258284,-57,-15,Umotina,ETHNICITY,ETHNICITY
SM247495,-57.4192,-15.5929,Umotína,LANGUAGE,LANGUAGE
SM297662,98.8779,15.795,Umphang,ADM2,DISTRICT
SM247497,143.467,-13.1528,Umpila,LANGUAGE,LANGUAGE
SM459402,143.4314,-13.6442,Umpila,ETHNICITY,ETHNICITY
SM459408,143.4489,-14.0356,Umpithamu,ETHNICITY,ETHNICITY
SM258285,-123.64,43.7,Umpqua,ETHNICITY,ETHNICITY
SM284638,7.4103,5.94,Umu-Nneochi,ADM2,DISTRICT
SM284639,7.4687,5.558,Umuahia North,ADM2,DISTRICT
SM284640,7.44,5.4623,Umuahia South,ADM2,DISTRICT
SM264766,-53.3852,-23.7101,Umuarama,ADM2,DISTRICT
SM3592,30.0833,-1.9167,Umujyi wa Kigali,ADM1:ADMX,DISTRICT
SM13883,24.9121,57.504,Umurgas pagasts,ADM2,DISTRICT
SM305279,28.9499,-20.347,Umzingwane,ADM2,DISTRICT
SM305123,30.5588,-28.5857,Umzinyathi,ADM2,DISTRICT
SM282935,-89.7609,20.8657,Umán,ADM2,DISTRICT
SM247502,140.1,-4.6968,Una,LANGUAGE,LANGUAGE
SM276868,76.1928,31.5837,Una,ADM2,DISTRICT
SM267700,-39.2057,-15.1645,Una,ADM2,DISTRICT
SM262559,16.1936,44.7369,Una-Sana,ADM2,DISTRICT
SM247503,-69.2248,-2.9996,Unainuman,LANGUAGE,LANGUAGE
SM258286,-163.6,62.34,Unaligmiut,ETHNICITY,ETHNICITY
SM258286,-163.6,62.34,Unaligmiut,ETHNICITY,ETHNICITY
SM258286,-160.75,63.98,Unaligmiut,ETHNICITY,ETHNICITY
SM258286,-160.75,63.98,Unaligmiut,ETHNICITY,ETHNICITY
SM247505,-98.3201,35.0264,Unami,LANGUAGE,LANGUAGE
SM14989,150.3395,8.75,Unanu Municipality,ADM2,DISTRICT
SM264034,-46.7701,-16.5863,Unaí,ADM2,DISTRICT
SM258288,-106.58,38.37,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-106.58,38.37,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-106.58,38.37,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-106.58,38.37,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-107,39,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-107,39,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-107,39,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-107,39,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-107.8,38.14,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-107.8,38.14,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-107.8,38.14,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-107.8,38.14,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-108,38,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-108,38,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-108,38,Uncompahgre,ETHNICITY,ETHNICITY
SM258288,-108,38,Uncompahgre,ETHNICITY,ETHNICITY
SM231492,29.8122,11.6432,Uncunwee,LANGUAGE,LANGUAGE
SM247531,119.673,-0.8588,Unde Kaili,LANGUAGE,LANGUAGE
SM269962,-74.071,4.3069,Une,ADM2,DISTRICT
SM247534,149.141,-4.8938,Uneapa,LANGUAGE,LANGUAGE
SM295024,32.8333,52.8333,Unechskiy rayon,ADM2,DISTRICT
SM247535,6.1343,6.9724,Uneme,LANGUAGE,LANGUAGE
SM305440,16.1841,43.741,Unešić,ADM2,DISTRICT
SM250786,28.72,-9.01,Unga,ETHNICITY,ETHNICITY
SM258289,126.12,-15.66,Ungarinyin,ETHNICITY,ETHNICITY
SM258289,126.12,-15.66,Ungarinyin,ETHNICITY,ETHNICITY
SM258289,126.0054,-16.0038,Ungarinyin,ETHNICITY,ETHNICITY
SM258289,126.0054,-16.0038,Ungarinyin,ETHNICITY,ETHNICITY
SM459388,142.0623,-12.6491,Ungawangadi,ETHNICITY,ETHNICITY
SM258290,173,64,Ungazikmit,ETHNICITY,ETHNICITY
SM258290,173,64,Ungazikmit,ETHNICITY,ETHNICITY
SM258290,173,64,Ungazikmit,ETHNICITY,ETHNICITY
SM258290,170.08,65.96,Ungazikmit,ETHNICITY,ETHNICITY
SM258290,170.08,65.96,Ungazikmit,ETHNICITY,ETHNICITY
SM258290,170.08,65.96,Ungazikmit,ETHNICITY,ETHNICITY
SM258290,172.86,64.48,Ungazikmit,ETHNICITY,ETHNICITY
SM258290,172.86,64.48,Ungazikmit,ETHNICITY,ETHNICITY
SM258290,172.86,64.48,Ungazikmit,ETHNICITY,ETHNICITY
SM288639,145.5167,-6.1167,Unggai-Bena,ADM2,DISTRICT
SM247541,124.156,-16.91,Unggaranggu,DIALECT,LANGUAGE
SM247540,124.67,-15.65,Unggumi,LANGUAGE,LANGUAGE
SM458616,124.8691,-17.0888,Unggumi,ETHNICITY,ETHNICITY
SM291204,24.4319,46.4685,Ungheni,ADM2,DISTRICT
SM291304,24.9519,44.5117,Ungheni,ADM2,DISTRICT
SM3593,27.9167,47.3333,Ungheni,ADM1,DISTRICT
SM258291,149.49,-26.93,Unghi,ETHNICITY,ETHNICITY
SM258291,149.49,-26.93,Unghi,ETHNICITY,ETHNICITY
SM258291,147.9422,-27.4234,Unghi,ETHNICITY,ETHNICITY
SM258291,147.9422,-27.4234,Unghi,ETHNICITY,ETHNICITY
SM284889,8.4434,12.042,Ungogo,ADM2,DISTRICT
SM258292,150.75,-27.66,Ungorri,ETHNICITY,ETHNICITY
SM258292,150.75,-27.66,Ungorri,ETHNICITY,ETHNICITY
SM258292,146.9602,-27.3681,Ungorri,ETHNICITY,ETHNICITY
SM258292,146.9602,-27.3681,Ungorri,ETHNICITY,ETHNICITY
SM292659,25.2186,45.9783,Ungra,ADM2,DISTRICT
SM290140,24.05,47.1167,Unguras,ADM2,DISTRICT
SM292607,26.7605,47.9038,Ungureni,ADM2,DISTRICT
SM292167,27.1068,46.5452,Ungureni,ADM2,DISTRICT
SM269925,-77.1092,8.1112,Unguía,ADM2,DISTRICT
SM289085,128.5,41.3333,Unhŭng,ADM2,DISTRICT
SM247545,142.0277,-3.0937,Uni,LANGUAGE,LANGUAGE
SM16262,-66.3828,18.3352,Unibon Barrio,ADM2,DISTRICT
SM302628,-82.4322,36.1108,Unicoi,ADM2,DISTRICT
SM264768,-52.0908,-23.0602,Uniflor,ADM2,DISTRICT
SM293289,51.5,57.6667,Uninskiy rayon,ADM2,DISTRICT
SM302537,-96.6561,42.8325,Union,ADM2,DISTRICT
SM302341,-77.0623,40.963,Union,ADM2,DISTRICT
SM302629,-83.8375,36.2879,Union,ADM2,DISTRICT
SM302273,-118.009,45.3104,Union,ADM2,DISTRICT
SM302470,-81.6194,34.6893,Union,ADM2,DISTRICT
SM302020,-80.5307,34.9884,Union,ADM2,DISTRICT
SM301825,-74.3086,40.6598,Union,ADM2,DISTRICT
SM302162,-83.3716,40.2994,Union,ADM2,DISTRICT
SM301859,-103.471,36.4818,Union,ADM2,DISTRICT
SM301159,-92.3748,32.8319,Union,ADM2,DISTRICT
SM301496,-89.0039,34.4905,Union,ADM2,DISTRICT
SM300870,-94.2424,41.0277,Union,ADM2,DISTRICT
SM300771,-84.9251,39.6255,Union,ADM2,DISTRICT
SM301004,-87.9454,37.6585,Union,ADM2,DISTRICT
SM300683,-89.2551,37.4712,Union,ADM2,DISTRICT
SM302419,-92.5973,33.1714,Union,ADM2,DISTRICT
SM300430,-83.9908,34.834,Union,ADM2,DISTRICT
SM300372,-82.3714,30.0438,Union,ADM2,DISTRICT
SM17685,-60.9648,14.0291,Union,ADM2,DISTRICT
SM14893,-94.8385,16.491,Union Hidalgo,ADM2,DISTRICT
SM14894,-92.0967,15.0619,Union Juarez,ADM2,DISTRICT
SM10482,-76.63,5.2814,Union Panamericana,ADM2,DISTRICT
SM17686,-60.9571,14.0265,Union Terrace,ADM2,DISTRICT
SM17687,-61.0569,13.811,Union Vale,ADM2,DISTRICT
SM17688,-60.9527,14.0228,Union/Ti Morne,ADM2,DISTRICT
SM290418,23.1794,44.1581,Unirea,ADM2,DISTRICT
SM292702,27.7761,45.1059,Unirea,ADM2,DISTRICT
SM289980,27.5828,44.2512,Unirea,ADM2,DISTRICT
SM289843,23.7626,46.4195,Unirea,ADM2,DISTRICT
SM265988,-55.1919,-29.0825,Unistalda,ADM2,DISTRICT
SM3594,29.85,8.65,Unity,ADM1,DISTRICT
SM12805,-77.9768,18.433,Unity Hall,ADM2,DISTRICT
SM16263,-66.0463,18.4074,Universidad Barrio,ADM2,DISTRICT
SM12806,-76.7477,18.0009,University,ADM2,DISTRICT
SM265154,-42.8647,-4.5965,União,ADM2,DISTRICT
SM267017,-49.8914,-20.888,União Paulista,ADM2,DISTRICT
SM265987,-52.0397,-28.7853,União da Serra,ADM2,DISTRICT
SM264767,-51.1121,-26.0921,União da Vitória,ADM2,DISTRICT
SM264036,-50.3574,-19.3409,União de Minas,ADM2,DISTRICT
SM266338,-52.8926,-26.7998,União do Oeste,ADM2,DISTRICT
SM263125,-54.1881,-11.472,União do Sul,ADM2,DISTRICT
SM265339,-36.0818,-9.1325,União dos Palmares,ADM2,DISTRICT
SM303578,-69.2778,10.8195,Unión,ADM2,DISTRICT
SM289643,-56.5309,-24.8699,Unión,ADM2,DISTRICT
SM261140,-62.75,-32.8333,Unión,ADM2,DISTRICT
SM271004,-81.5159,22.6811,Unión de Reyes,ADM2,DISTRICT
SM281753,-101.9932,21.1601,Unión de San Antonio,ADM2,DISTRICT
SM281754,-104.2673,19.9684,Unión de Tula,ADM2,DISTRICT
SM289199,125.4319,39.6808,Unjŏn,ADM2,DISTRICT
SM261741,138.608,-34.9539,Unley,ADM2,DISTRICT
SM279262,132.8948,35.2492,Unnan,ADM2,DISTRICT
SM277339,80.5,26.5,Unnao,ADM2,DISTRICT
SM11533,-16.1909,11.2688,Uno,ADM2,DISTRICT
SM277266,92.0038,24.3278,Unokoti,ADM2,DISTRICT
SM289200,125.75,40.0833,Unsan,ADM2,DISTRICT
SM247547,152.183,-4.1957,Unserdeutsch,LANGUAGE,LANGUAGE
SM292608,26.7789,47.8116,Unteni,ADM2,DISTRICT
SM268947,8.4633,47.68,Unterklettgau,ADM2,DISTRICT
SM292860,46.8333,42.6667,Untsukul'skiy rayon,ADM2,DISTRICT
SM247550,167.599,-16.2638,Unua,LANGUAGE,LANGUAGE
SM247552,150.2683,-10.3652,Unubahe,LANGUAGE,LANGUAGE
SM284774,7.2411,5.7736,Unuimo,ADM2,DISTRICT
SM278884,130.2283,32.7839,Unzen,ADM2,DISTRICT
SM247654,5.9778,7.0061,Uokha,LANGUAGE,LANGUAGE
SM278952,139.098,37.2209,Uonuma,ADM2,DISTRICT
SM279503,137.4898,36.7623,Uozu,ADM2,DISTRICT
SM270903,-85.1361,10.8766,Upala,ADM2,DISTRICT
SM265509,-37.268,-5.6992,Upanema,ADM2,DISTRICT
SM258293,-59.82,76.32,Upernavik,ETHNICITY,ETHNICITY
SM250958,5.55,8.97,Upila,ETHNICITY,ETHNICITY
SM13884,26.4814,56.2425,Upmalas pagasts,ADM2,DISTRICT
SM294807,66.3333,56.1667,Uporovskiy rayon,ADM2,DISTRICT
SM247663,13.89,48.24,Upper Austrian german,DIALECT,LANGUAGE
SM275061,-15.6667,13.55,Upper Baddibu,ADM2,DISTRICT
SM18586,11,48,Upper Bavaria,ADM2,DISTRICT
SM247702,-123.169,46.7981,Upper Chehalis,LANGUAGE,LANGUAGE
SM248381,-121.509,45.0278,Upper Chinook,LANGUAGE,LANGUAGE
SM3596,-58.3333,5.5,Upper Demerara-Berbice,ADM1,DISTRICT
SM11446,-1.7831,5.9698,Upper Denkyira East,ADM2,DISTRICT
SM11447,-2.15,6.15,Upper Denkyira West,ADM2,DISTRICT
SM5126,72.0469,35.3088,Upper Dir,ADM2:ADM3,DISTRICT
SM3597,-0.75,10.75,Upper East,ADM1,DISTRICT
SM18587,11.3333,49.8333,Upper Franconia,ADM2,DISTRICT
SM261321,116.3388,-24.7194,Upper Gascoyne,ADM2,DISTRICT
SM247693,138.901,-3.9437,Upper Grand Valley Dani,LANGUAGE,LANGUAGE
SM247707,-15.3325,13.2335,Upper Guinea Crioulo,LANGUAGE,LANGUAGE
SM261475,150.6928,-31.9813,Upper Hunter,ADM2,DISTRICT
SM286524,175.1329,-41.0878,Upper Hutt,ADM2,DISTRICT
SM14123,-8.4684,4.82,Upper Jloh District,ADM2,DISTRICT
SM247690,116.901,5.3191,Upper Kinabatangan,LANGUAGE,LANGUAGE
SM15313,73.2268,35.2738,Upper Kohistan District,ADM2,DISTRICT
SM247701,-155.951,62.2976,Upper Kuskokwim,LANGUAGE,LANGUAGE
SM261476,149.5306,-34.4187,Upper Lachlan,ADM2,DISTRICT
SM241341,90.3947,27.3732,Upper Mangdep,LANGUAGE,LANGUAGE
SM11448,-0.1333,6.3833,Upper Manya Krobo,ADM2,DISTRICT
SM247659,-97.81,20.236,Upper Necaxa Totonac,LANGUAGE,LANGUAGE
SM3598,32,9.5,Upper Nile,ADM1:ADM2,DISTRICT
SM3598,32,9.5,Upper Nile,ADM1:ADM2,DISTRICT
SM3598,32.7,10,Upper Nile,ADM1:ADM2,DISTRICT
SM3598,32.7,10,Upper Nile,ADM1:ADM2,DISTRICT
SM275062,-16.3333,13.4,Upper Nuimi,ADM2,DISTRICT
SM18588,12,49.5,Upper Palatinate,ADM2,DISTRICT
SM346827,24.0492,62.0963,Upper Pirkanmaa,ADM2:ADM3,DISTRICT
SM3599,-14.1667,13.3667,Upper River,ADM1,DISTRICT
SM247679,140.9993,-34.0988,Upper Riverland,LANGUAGE,LANGUAGE
SM275048,-15.2,13.75,Upper Saloum,ADM2,DISTRICT
SM346768,27.0362,63.5329,Upper Savonia,ADM2:ADM3,DISTRICT
SM277202,94.9181,28.8336,Upper Siang,ADM2,DISTRICT
SM247661,14.4098,51.2403,Upper Sorbian,LANGUAGE,LANGUAGE
SM277203,94,28.3,Upper Subansiri,ADM2,DISTRICT
SM247670,107.0561,16.1865,Upper Ta'oih,LANGUAGE,LANGUAGE
SM3600,-59,2.9167,Upper Takutu-Upper Essequibo,ADM1,DISTRICT
SM247700,-141.37,62.6867,Upper Tanana,LANGUAGE,LANGUAGE
SM258296,-133.02,60.81,Upper Tanana,ETHNICITY,ETHNICITY
SM258296,-133.02,60.81,Upper Tanana,ETHNICITY,ETHNICITY
SM258296,-133.02,60.81,Upper Tanana,ETHNICITY,ETHNICITY
SM258296,-141,63,Upper Tanana,ETHNICITY,ETHNICITY
SM258296,-141,63,Upper Tanana,ETHNICITY,ETHNICITY
SM258296,-141,63,Upper Tanana,ETHNICITY,ETHNICITY
SM258296,-143.12,63.44,Upper Tanana,ETHNICITY,ETHNICITY
SM258296,-143.12,63.44,Upper Tanana,ETHNICITY,ETHNICITY
SM258296,-143.12,63.44,Upper Tanana,ETHNICITY,ETHNICITY
SM247687,121.202,17.2143,Upper Tanudan Kalinga,LANGUAGE,LANGUAGE
SM247657,48.7301,36.5998,Upper Taromi,LANGUAGE,LANGUAGE
SM370211,-8.1434,5.2888,Upper Tienpo,ADM2:ADM3,DISTRICT
SM247699,-123.36,43.22,Upper Umpqua,LANGUAGE,LANGUAGE
SM3601,-2.25,10.3333,Upper West,ADM1,DISTRICT
SM11449,-0.56,5.8003,Upper West Akim,ADM2,DISTRICT
SM296484,17.7498,59.491,Upplands-Bro,ADM2,DISTRICT
SM296485,17.91,59.5238,Upplands-Väsby,ADM2,DISTRICT
SM296502,17.774,59.9643,Uppsala,ADM2,DISTRICT
SM3602,17.75,60,Uppsala,ADM1,DISTRICT
SM296672,15.4473,57.0344,Uppvidinge,ADM2,DISTRICT
SM302785,-94.9415,32.7363,Upshur,ADM2,DISTRICT
SM303151,-80.2334,38.8978,Upshur,ADM2,DISTRICT
SM300431,-84.2993,32.8813,Upson,ADM2,DISTRICT
SM302786,-102.043,31.3687,Upton,ADM2,DISTRICT
SM247729,90.9,27.4667,Ura,DIALECT,LANGUAGE
SM247740,151.953,-4.6423,Ura (Papua New Guinea),LANGUAGE,LANGUAGE
SM247744,169.084,-18.6747,Ura (Vanuatu),LANGUAGE,LANGUAGE
SM303785,-69,10.1333,Urachiche,ADM2,DISTRICT
SM303660,-62.3516,8.9996,Uracoa,ADM2,DISTRICT
SM247731,142.135,-12.0679,Uradhi,LANGUAGE,LANGUAGE
SM459257,142.1591,-11.8906,Uradhi^,ETHNICITY,ETHNICITY
SM278223,143.681,42.9219,Urahoro,ADM2,DISTRICT
SM247732,99.0768,7.5258,Urak Lawoi',LANGUAGE,LANGUAGE
SM278225,142.8802,42.3046,Urakawa,ADM2,DISTRICT
SM258301,76.91,11.09,Urali Gounder,ETHNICITY,ETHNICITY
SM247735,76.8007,10.8519,Urali of Idukki,LANGUAGE,LANGUAGE
SM261477,151.3545,-30.4855,Uralla,ADM2,DISTRICT
SM258303,144.5,-7.48,Urama,ETHNICITY,ETHNICITY
SM299191,32.1315,-5.175,Urambo,ADM2,DISTRICT
SM270163,-76.1308,6.9166,Uramita,ADM2,DISTRICT
SM267701,-42.6654,-14.734,Urandi,ADM2,DISTRICT
SM247739,141.54,-5.087,Urapmin,LANGUAGE,LANGUAGE
SM258304,141.18,-4.76,Urapmin,ETHNICITY,ETHNICITY
SM258305,5.76,27.54,Uraren,ETHNICITY,ETHNICITY
SM247742,-75.4211,-4.4401,Urarina,LANGUAGE,LANGUAGE
SM247741,44.3833,35.0333,Urartian,LANGUAGE,LANGUAGE
SM279077,127.7151,26.2511,Urasoe,ADM2,DISTRICT
SM247743,142.79,-3.6007,Urat,LANGUAGE,LANGUAGE
SM264769,-50.7942,-23.1929,Uraí,ADM2,DISTRICT
SM18482,27.751,-29.13,Urban (Berea),ADM2,DISTRICT
SM13988,27.93,-28.8954,Urban (Leribe),ADM2,DISTRICT
SM271129,-76.1383,20.5975,Urbano Noris,ADM2,DISTRICT
SM262929,-43.3814,-3.3797,Urbano Santos,ADM2,DISTRICT
SM273448,-79.8,-1.8333,Urbina Jado,ADM2,DISTRICT
SM303647,-66.9167,10.1667,Urdaneta,ADM2,DISTRICT
SM303770,-70.5833,9.0833,Urdaneta,ADM2,DISTRICT
SM303611,-69.6667,10.5,Urdaneta,ADM2,DISTRICT
SM303831,-66.8,9.7167,Urdaneta,ADM2,DISTRICT
SM273479,-79.4667,-1.5833,Urdaneta,ADM2,DISTRICT
SM290672,23.296,44.7993,Urdari,ADM2,DISTRICT
SM279888,48,48.75,Urdinskiy,ADM2,DISTRICT
SM247747,67,25,Urdu,LANGUAGE,LANGUAGE
SM291381,26.5342,47.1776,Urecheni,ADM2,DISTRICT
SM292168,27.0707,46.1243,Urechesti,ADM2,DISTRICT
SM293792,45.75,57.5,Urenskiy rayon,ADM2,DISTRICT
SM282612,-110.3421,29.3237,Ures,ADM2,DISTRICT
SM279143,130.015,33.0956,Ureshino,ADM2,DISTRICT
SM261834,14.2745,48.4251,Urfahr Umgebung,ADM2,DISTRICT
SM283839,94.2943,48.5142,Urgamal,ADM2,DISTRICT
SM18458,60.6333,41.55,Urganch Shahri,ADM2,DISTRICT
SM18459,60.5292,41.5127,Urganch Tumani,ADM2,DISTRICT
SM260148,69.1301,32.8704,Urgun,ADM2,DISTRICT
SM18460,67.2417,39.4069,Urgut Shahri,ADM2,DISTRICT
SM18461,67.1667,39.4167,Urgut Tumani,ADM2,DISTRICT
SM247752,5.8615,5.3116,Urhobo,LANGUAGE,LANGUAGE
SM247755,146.608,-6.4199,Uri,LANGUAGE,LANGUAGE
SM268982,8.6388,46.8304,Uri,ADM2,DISTRICT
SM3603,8.6418,46.7847,Uri,ADM1,DISTRICT
SM281569,-101.1604,20.1131,Uriangato,ADM2,DISTRICT
SM303752,-71.9167,7.6667,Uribante,ADM2,DISTRICT
SM270125,-71.7489,12.0264,Uribia,ADM2,DISTRICT
SM290795,23.0801,45.3173,Uricani,ADM2,DISTRICT
SM247753,145.507,-5.5898,Urigina,LANGUAGE,LANGUAGE
SM247757,142.653,-3.5802,Urim,LANGUAGE,LANGUAGE
SM247756,143.701,-3.8607,Urimo,LANGUAGE,LANGUAGE
SM247758,167.3464,-16.0095,Uripiv-Wala-Rano-Atchin,LANGUAGE,LANGUAGE
SM283271,-107.9546,27.2704,Urique,ADM2,DISTRICT
SM292540,24.0776,47.212,Uriu,ADM2,DISTRICT
SM285725,5.6237,52.6659,Urk,ADM2,DISTRICT
SM298550,26.7688,38.3326,Urla,ADM2,DISTRICT
SM291585,26.2438,44.9969,Urlati,ADM2,DISTRICT
SM292805,47.9167,55.6667,Urmarskiy rayon,ADM2,DISTRICT
SM292541,24.3592,46.7956,Urmenis,ADM2,DISTRICT
SM247762,45.0421,37.3319,Urmian group,DIALECT,LANGUAGE
SM247765,133.023,-13.4766,Urningangg,LANGUAGE,LANGUAGE
SM304762,21.1333,42.3667,Uroševac,ADM2,DISTRICT
SM3604,21.1474,42.3693,Uroševac,ADM1,DISTRICT
SM270164,-76.08,6.19,Urrao,ADM2,DISTRICT
SM458824,132.6487,-11.9177,Urrirrk,ETHNICITY,ETHNICITY
SM247791,-68.9712,-16.7255,Uru,LANGUAGE,LANGUAGE
SM267020,-49.2972,-21.7652,Uru,ADM2,DISTRICT
SM258311,-67.4,-18.87,Uru,ETHNICITY,ETHNICITY
SM258311,-67.4,-18.87,Uru,ETHNICITY,ETHNICITY
SM258311,-67.4,-18.87,Uru,ETHNICITY,ETHNICITY
SM258311,-69.72,-16.4,Uru,ETHNICITY,ETHNICITY
SM258311,-69.72,-16.4,Uru,ETHNICITY,ETHNICITY
SM258311,-69.72,-16.4,Uru,ETHNICITY,ETHNICITY
SM258311,-69,-16.517,Uru,ETHNICITY,ETHNICITY
SM258311,-69,-16.517,Uru,ETHNICITY,ETHNICITY
SM258311,-69,-16.517,Uru,ETHNICITY,ETHNICITY
SM258311,-69,-16,Uru,ETHNICITY,ETHNICITY
SM258311,-69,-16,Uru,ETHNICITY,ETHNICITY
SM258311,-69,-16,Uru,ETHNICITY,ETHNICITY
SM247780,-63.8086,-10.9176,Uru-Eu-Wau-Wau,LANGUAGE,LANGUAGE
SM247787,-62.7226,-11.2946,Uru-Pa-In,LANGUAGE,LANGUAGE
SM283272,-108.3671,27.8467,Uruachic,ADM2,DISTRICT
SM285106,8.066,5.011,Uruan,ADM2,DISTRICT
SM268194,-49.6403,-15.5637,Uruana,ADM2,DISTRICT
SM264037,-46.3383,-16.0749,Uruana de Minas,ADM2,DISTRICT
SM247773,132.745,-3.455,Uruangnirin,LANGUAGE,LANGUAGE
SM281989,-102.1014,19.4274,Uruapan,ADM2,DISTRICT
SM264124,-53.7802,-3.6321,Uruará,ADM2,DISTRICT
SM247771,155.573,-6.2331,Uruava,LANGUAGE,LANGUAGE
SM268193,-49.0637,-14.4225,Uruaçu,ADM2,DISTRICT
SM286900,-72.3333,-13.25,Urubamba,ADM2,DISTRICT
SM266340,-49.5742,-28.05,Urubici,ADM2,DISTRICT
SM267870,-39.5476,-3.6283,Uruburetama,ADM2,DISTRICT
SM247775,-46.2204,-2.4646,Urubú-Kaapor,LANGUAGE,LANGUAGE
SM247774,-46.4213,-2.5409,Urubú-Kaapor Sign Language,LANGUAGE,LANGUAGE
SM267358,-58.8043,-1.1928,Urucará,ADM2,DISTRICT
SM264039,-45.6173,-16.0182,Urucuia,ADM2,DISTRICT
SM267360,-57.7501,-2.8327,Urucurituba,ADM2,DISTRICT
SM264038,-42.7197,-20.3224,Urucânia,ADM2,DISTRICT
SM285108,8.1695,4.717,UrueOffo,ADM2,DISTRICT
SM265990,-56.6322,-29.828,Uruguaiana,ADM2,DISTRICT
SM261179,-58.5828,-32.4434,Uruguay,ADM2,DISTRICT
SM247781,-56.4509,-34.5967,Uruguayan Sign Language,LANGUAGE,LANGUAGE
SM247785,43.994,42.0379,Urum,LANGUAGE,LANGUAGE
SM279078,127.8737,26.3678,Uruma,ADM2,DISTRICT
SM303579,-70.255,11.1991,Urumaco,ADM2,DISTRICT
SM247786,-63.848,-9.08,Urumi,LANGUAGE,LANGUAGE
SM10483,-72.9759,10.4982,Urumita (1998-),ADM2,DISTRICT
SM267871,-40.7217,-3.3198,Uruoca,ADM2,DISTRICT
SM247789,-62.75,-10.25,Urupa,LANGUAGE,LANGUAGE
SM258315,-63.69,-11.54,Urupa,ETHNICITY,ETHNICITY
SM266341,-49.9297,-28.0416,Urupema,ADM2,DISTRICT
SM293116,41.1667,43.9167,Urupskiy rayon,ADM2,DISTRICT
SM266115,-62.3813,-11.0792,Urupá,ADM2,DISTRICT
SM267019,-49.2807,-21.1841,Urupês,ADM2,DISTRICT
SM292728,45.5833,43.0833,Urus-Martanovskiy rayon,ADM2,DISTRICT
SM266342,-49.3125,-28.4746,Urussanga,ADM2,DISTRICT
SM268195,-48.1979,-17.4166,Urutaí,ADM2,DISTRICT
SM3605,66,32.8333,Uruzgan,ADM1,DISTRICT
SM267702,-39.1686,-14.4842,Uruçuca,ADM2,DISTRICT
SM265155,-44.5833,-7.3421,Uruçuí,ADM2,DISTRICT
SM17128,43.9166,43.4167,Urvanskiy Rayon (2003-),ADM2,DISTRICT
SM13115,141.9067,43.8101,Uryu Gun,ADM2,DISTRICT
SM17129,42.1667,50.8333,Uryupinskiy Rayon,ADM2,DISTRICT
SM13285,81.6333,47.0833,Urzhar Audany (excl. Taskesken and Maqanshy),ADM2,DISTRICT
SM293290,49.8333,57.1667,Urzhumskiy rayon,ADM2,DISTRICT
SM291476,24.275,43.8606,Urzica,ADM2,DISTRICT
SM291698,22.4018,47.7329,Urziceni,ADM2,DISTRICT
SM290938,26.6419,44.7184,Urziceni,ADM2,DISTRICT
SM290419,23.5696,44.0149,Urzicuta,ADM2,DISTRICT
SM267018,-50.6609,-20.2091,Urânia,ADM2,DISTRICT
SM279009,131.3366,33.45,Usa,ADM2,DISTRICT
SM258317,138.83,-3.75,Usagek Goliath,ETHNICITY,ETHNICITY
SM247793,8.5346,4.7858,Usaghade,LANGUAGE,LANGUAGE
SM3606,29.4167,38.5,Usak,ADM1,DISTRICT
SM247795,145.362,-4.8427,Usan,LANGUAGE,LANGUAGE
SM247796,145.633,-6.389,Usarufa,LANGUAGE,LANGUAGE
SM304969,46.0274,15.1703,Usaylan,ADM2,DISTRICT
SM370452,5.9822,49.7697,Useldange,ADM1:ADM2:ADM3,DISTRICT
SM262675,28.5698,55.1708,Ushat,ADM2,DISTRICT
SM250045,28.26,-11.36,Ushi,ETHNICITY,ETHNICITY
SM278403,140.1858,35.9718,Ushiku,ADM2,DISTRICT
SM247801,72.643,35.1584,Ushojo,LANGUAGE,LANGUAGE
SM285352,9.0887,7.0441,Ushongo,ADM2,DISTRICT
SM289038,125.6461,40.6025,Usi,ADM2,DISTRICT
SM270673,-74.9837,10.7454,Usiacurí,ADM2,DISTRICT
SM247803,-96.4074,17.9184,Usila Chinantec,LANGUAGE,LANGUAGE
SM288574,145.1667,-5.5333,Usino-Bundi,ADM2,DISTRICT
SM247805,140.502,-3.6385,Usku,LANGUAGE,LANGUAGE
SM293574,39.75,52,Usmanskiy rayon,ADM2,DISTRICT
SM13885,22.1592,57.2238,Usmas pagasts,ADM2,DISTRICT
SM18462,65.2448,38.8272,Usmon Yusupov Tumani,ADM2,DISTRICT
SM17130,103.25,52.5,Usol'skiy Rayon,ADM2,DISTRICT
SM247807,-90.8893,15.3648,Uspanteco,LANGUAGE,LANGUAGE
SM275246,-90.844,15.4439,Uspantán,ADM2,DISTRICT
SM279985,77.5,53.0833,Uspenskiy,ADM2,DISTRICT
SM293404,41.4394,44.8364,Uspenskiy rayon,ADM2,DISTRICT
SM285246,10.0381,7.0868,Ussa,ADM2,DISTRICT
SM294106,132,43.75,Ussuriyskiy rayon,ADM2,DISTRICT
SM17131,90.25,53.8333,Ust'-Abakanskiy Rayon (excl. Chernogorsk),ADM2,DISTRICT
SM294323,132,63,Ust'-Aldanskiy rayon,ADM2,DISTRICT
SM293096,157,52.6667,Ust'-Bol'sheretskiy rayon,ADM2,DISTRICT
SM293107,41.9667,44.0833,Ust'-Dzhegutinskiy rayon,ADM2,DISTRICT
SM17132,102.5,58,Ust'-Ilimskiy Rayon,ADM2,DISTRICT
SM293012,83.4167,52,Ust'-Kalmanskiy rayon,ADM2,DISTRICT
SM293097,161,56,Ust'-Kamchatskiy rayon,ADM2,DISTRICT
SM292867,84.8333,50.8333,Ust'-Kanskiy rayon,ADM2,DISTRICT
SM292868,85.5833,50.1667,Ust'-Koksinskiy rayon,ADM2,DISTRICT
SM294951,39.5,59.9167,Ust'-Kubinskiy rayon,ADM2,DISTRICT
SM293313,53.6667,61.6667,Ust'-Kulomskiy rayon,ADM2,DISTRICT
SM17133,106,57,Ust'-Kutskiy Rayon,ADM2,DISTRICT
SM293405,39.7233,45.2597,Ust'-Labinskiy rayon,ADM2,DISTRICT
SM293013,83.75,52.4167,Ust'-Pristanskiy rayon,ADM2,DISTRICT
SM293882,75.8333,55.75,Ust'-Tarkskiy rayon,ADM2,DISTRICT
SM293314,52.1667,65.4167,Ust'-Tsilemskiy rayon,ADM2,DISTRICT
SM292917,103.6667,55,Ust'-Udinskiy rayon,ADM2,DISTRICT
SM293316,49.9167,62.25,Ust'-Vymskiy rayon,ADM2,DISTRICT
SM294326,139.9167,69.3333,Ust'-Yanskiy rayon,ADM2,DISTRICT
SM293598,43.6667,61.3333,Ust'yanskiy rayon,ADM2,DISTRICT
SM269010,8.7135,47.3495,Uster,ADM2,DISTRICT
SM299518,32.5372,47.9573,Ustynivs'kyi,ADM2,DISTRICT
SM294952,36.5,58.8333,Ustyuzhenskiy rayon,ADM2,DISTRICT
SM247812,91.7879,23.0131,Usui,LANGUAGE,LANGUAGE
SM279010,131.7446,33.0722,Usuki,ADM2,DISTRICT
SM295589,-88.3833,13.3,Usulután,ADM2,DISTRICT
SM3608,-88.5333,13.4333,Usulután,ADM1,DISTRICT
SM275388,-89.7883,14.9891,Usumatlán,ADM2,DISTRICT
SM237049,114.039,3.5444,Usun Apau Kenyah,LANGUAGE,LANGUAGE
SM233639,4.8387,11.5319,Ut-Main,LANGUAGE,LANGUAGE
SM302910,-111.6703,40.12,Utah,ADM2,DISTRICT
SM3609,-111.751,39.2502,Utah,ADM1,DISTRICT
SM258325,43.73,22.6,Utaibah,ETHNICITY,ETHNICITY
SM247815,34.7092,-9.4366,Utalingoro,DIALECT,LANGUAGE
SM247816,144.924,-4.8276,Utarmbung,LANGUAGE,LANGUAGE
SM278228,142.0388,43.4937,Utashinai,ADM2,DISTRICT
SM286727,-78.3285,-5.7703,Utcubamba,ADM2,DISTRICT
SM247817,-109,38,Ute,DIALECT:LANGUAGE,LANGUAGE
SM258326,-105.35,37.58,Ute,ETHNICITY,ETHNICITY
SM258326,-105.35,37.58,Ute,ETHNICITY,ETHNICITY
SM258326,-108.5,37,Ute,ETHNICITY,ETHNICITY
SM258326,-108.5,37,Ute,ETHNICITY,ETHNICITY
SM247819,-110.305,40.0965,Ute-Southern Paiute,LANGUAGE,LANGUAGE
SM258327,-119.14,36.41,Utechem,ETHNICITY,ETHNICITY
SM247818,34.7675,-8.9492,Utengule,DIALECT,LANGUAGE
SM281363,25.6,55.5,Utenos,ADM2,DISTRICT
SM3610,25.75,55.5,Utenos,ADM1,DISTRICT
SM297382,100.6964,14.3532,Uthai,ADM2,DISTRICT
SM3611,99.55,15.3833,Uthai Thani,ADM1,DISTRICT
SM305124,29.658,-28.7192,Uthukela,ADM2,DISTRICT
SM297566,104.16,15.1231,Uthumphon Phisai,ADM2,DISTRICT
SM305115,31.5153,-28.7005,Uthungulu,ADM2,DISTRICT
SM269963,-74.5013,5.1698,Utica,ADM2,DISTRICT
SM275678,-86.9307,16.1003,Utila,ADM2,DISTRICT
SM258328,-81.72,30.06,Utina,ETHNICITY,ETHNICITY
SM267703,-41.1948,-12.0427,Utinga,ADM2,DISTRICT
SM298060,9.9759,37.0504,Utique,ADM2,DISTRICT
SM258330,-95.08,66.99,Utkuhiksalingmiut,ETHNICITY,ETHNICITY
SM304792,43.9483,14.4738,Utmah,ADM2,DISTRICT
SM278656,130.6131,32.6809,Uto,ADM2,DISTRICT
SM260854,-65,-37.25,Utracán,ADM2,DISTRICT
SM285603,5.0371,52.0968,Utrecht,ADM2,DISTRICT
SM3612,5.25,52,Utrecht,ADM1,DISTRICT
SM4105,169.8097,11.2686,Utrik Atoll,ADM1,DISTRICT
SM286145,4.8862,59.3057,Utsira,ADM2,DISTRICT
SM279338,139.8866,36.5969,Utsunomiya,ADM2,DISTRICT
SM277461,81.1,20.2,Uttar Bastar Kanker,ADM2,DISTRICT
SM277368,88.126,25.614,Uttar Dinajpur,ADM2,DISTRICT
SM3613,80.75,27.25,Uttar Pradesh (2000-),ADM1,DISTRICT
SM258331,83,26,Uttar Pradesh--Other (Bhojpuri),ETHNICITY,ETHNICITY
SM258331,83,26,Uttar Pradesh--Other (Bhojpuri),ETHNICITY,ETHNICITY
SM258331,83,25.92,Uttar Pradesh--Other (Bhojpuri),ETHNICITY,ETHNICITY
SM258331,83,25.92,Uttar Pradesh--Other (Bhojpuri),ETHNICITY,ETHNICITY
SM276944,74.5833,14.8833,Uttara Kannada,ADM2,DISTRICT
SM3614,100.5,17.6667,Uttaradit,ADM1,DISTRICT
SM3615,79.25,30.25,Uttarakhand,ADM1,DISTRICT
SM277347,78.5,31,Uttarkashi,ADM2,DISTRICT
SM247832,145.544,-5.1642,Utu,LANGUAGE,LANGUAGE
SM3616,-66.6996,18.273,Utuado,ADM1,DISTRICT
SM258332,-66.5,18.21,Utuado,ETHNICITY,ETHNICITY
SM16264,-66.7057,18.2663,Utuado Barrio-Pueblo,ADM2,DISTRICT
SM295474,166.5458,-11.2623,Utupua,ADM2,DISTRICT
SM11278,-151.4462,-16.7315,Uturoa,ADM2,DISTRICT
SM14990,162.9562,5.2751,Utwe Municipality,ADM2,DISTRICT
SM459267,142.4721,-11.8922,Utyangikwathiya,ETHNICITY,ETHNICITY
SM284469,15.9167,-18.0167,Uukwiyu,ADM2,DISTRICT
SM283675,112.1835,46.3799,Uulbayan,ADM2,DISTRICT
SM4171,24.75,60.4167,Uusimaa (2011-),ADM1,DISTRICT
SM459286,143.3406,-13.099,Uutaalnganu,ETHNICITY,ETHNICITY
SM302787,-99.7622,29.3573,Uvalde,ADM2,DISTRICT
SM9817,30.6667,52.5833,Uvarovichskiy Rayon,ADM2,DISTRICT
SM247835,5.773,5.4426,Uvbie,LANGUAGE,LANGUAGE
SM258333,-176.17,-13.28,Uvean,ETHNICITY,ETHNICITY
SM292770,61.5,54.5,Uvel'skiy rayon,ADM2,DISTRICT
SM299248,30.3755,-5.098,Uvinza,ADM2,DISTRICT
SM3618,93,49.5,Uvs,ADM1,DISTRICT
SM284663,5.776,5.5716,Uvwie,ADM2,DISTRICT
SM3617,-176.2205,-13.2988,Uvéa,ADM1,DISTRICT
SM459281,141.8282,-15.1455,Uw El,ETHNICITY,ETHNICITY
SM459428,142.6986,-15.3479,Uw Olkola,ETHNICITY,ETHNICITY
SM459177,142.0212,-15.5997,Uw Oykangand,ETHNICITY,ETHNICITY
SM279634,132.5607,33.2233,Uwajima,ADM2,DISTRICT
SM247838,131.7,-12.8,Uwinymil,LANGUAGE,LANGUAGE
SM458880,131.7755,-13.3115,Uwinymil,ETHNICITY,ETHNICITY
SM14895,-94.4259,17.275,Uxpanapa,ADM2,DISTRICT
SM247839,145.47,-5.3225,Uya,LANGUAGE,LANGUAGE
SM283645,102.281,46.4567,Uyanga,ADM2,DISTRICT
SM293462,94.25,55.8333,Uyarskiy rayon,ADM2,DISTRICT
SM303537,71.8987,41.0413,Uychi,ADM2,DISTRICT
SM13286,81,45.5,Uygentas Audany,ADM2,DISTRICT
SM279837,79.75,43.5,Uygurskiy,ADM2,DISTRICT
SM285109,7.903,5.0187,Uyo,ADM2,DISTRICT
SM292772,60.0833,54.3333,Uyskiy rayon,ADM2,DISTRICT
SM299192,33.3435,-5.0691,Uyui,ADM2,DISTRICT
SM459266,142.6557,-12.0836,Uyungkuthi,ETHNICITY,ETHNICITY
SM13886,21.4659,57.1967,Uzavas pagasts,ADM2,DISTRICT
SM258337,64.7,36.13,Uzbek,ETHNICITY,ETHNICITY
SM258337,64.7,36.13,Uzbek,ETHNICITY,ETHNICITY
SM258337,64.7,36.13,Uzbek,ETHNICITY,ETHNICITY
SM258337,64.7,36.13,Uzbek,ETHNICITY,ETHNICITY
SM258337,64.7,36.13,Uzbek,ETHNICITY,ETHNICITY
SM258337,60,43,Uzbek,ETHNICITY,ETHNICITY
SM258337,60,43,Uzbek,ETHNICITY,ETHNICITY
SM258337,60,43,Uzbek,ETHNICITY,ETHNICITY
SM258337,60,43,Uzbek,ETHNICITY,ETHNICITY
SM258337,60,43,Uzbek,ETHNICITY,ETHNICITY
SM258337,60.63,41.55,Uzbek,ETHNICITY,ETHNICITY
SM258337,60.63,41.55,Uzbek,ETHNICITY,ETHNICITY
SM258337,60.63,41.55,Uzbek,ETHNICITY,ETHNICITY
SM258337,60.63,41.55,Uzbek,ETHNICITY,ETHNICITY
SM258337,60.63,41.55,Uzbek,ETHNICITY,ETHNICITY
SM258337,69.27,41.3,Uzbek,ETHNICITY,ETHNICITY
SM258337,69.27,41.3,Uzbek,ETHNICITY,ETHNICITY
SM258337,69.27,41.3,Uzbek,ETHNICITY,ETHNICITY
SM258337,69.27,41.3,Uzbek,ETHNICITY,ETHNICITY
SM258337,69.27,41.3,Uzbek,ETHNICITY,ETHNICITY
SM258337,69.2167,41.2667,Uzbek,ETHNICITY,ETHNICITY
SM258337,69.2167,41.2667,Uzbek,ETHNICITY,ETHNICITY
SM258337,69.2167,41.2667,Uzbek,ETHNICITY,ETHNICITY
SM258337,69.2167,41.2667,Uzbek,ETHNICITY,ETHNICITY
SM258337,69.2167,41.2667,Uzbek,ETHNICITY,ETHNICITY
SM247844,65.3864,40.2612,Uzbeki Arabic,LANGUAGE,LANGUAGE
SM262655,27.3333,53.4167,Uzda,ADM2,DISTRICT
SM247846,8.314,6.5884,Uzekwe,LANGUAGE,LANGUAGE
SM280338,73.3,40.55,Uzgen,ADM2,DISTRICT
SM18282,22.2808,48.6296,Uzhhorod Raion,ADM2,DISTRICT
SM293463,90.25,55.3333,Uzhurskiy rayon,ADM2,DISTRICT
SM284729,7.1379,6.7414,Uzo-Uwani,ADM2,DISTRICT
SM303435,68.1667,38.1667,Uzun,ADM2,DISTRICT
SM298398,41.5483,40.5318,Uzundere,ADM2,DISTRICT
SM279854,65.5,54.1667,Uzunkolskiy,ADM2,DISTRICT
SM298349,26.6965,41.2768,Uzunköprü,ADM2,DISTRICT
SM260379,15.0678,-7.5749,Uíge,ADM2,DISTRICT
SM3583,15.4406,-7.1072,Uíge,ADM1,DISTRICT
SM304420,106.7677,21.0839,Uông Bí,ADM2,DISTRICT
SM298291,34.4563,40.4557,Uğurludağ,ADM2,DISTRICT
SM3619,-171.5333,-13.95,Va'a-o-Fonoti,ADM1,DISTRICT
SM247849,79.5335,12.215,Vaagri Booli,LANGUAGE,LANGUAGE
SM247850,22.2901,-29.4046,Vaal-Orange,LANGUAGE,LANGUAGE
SM247850,22.2901,-29.4046,Vaal-Orange,LANGUAGE,LANGUAGE
SM247850,22.0021,-33.0386,Vaal-Orange,LANGUAGE,LANGUAGE
SM247850,22.0021,-33.0386,Vaal-Orange,LANGUAGE,LANGUAGE
SM285890,5.9767,50.7785,Vaals,ADM2,DISTRICT
SM346819,21.7303,63.1157,Vaasa,ADM2:ADM3,DISTRICT
SM4269,73.5854,3.3701,Vaavu Atholhu,ADM1:ADM2,DISTRICT
SM13887,26.4897,56.0274,Vaboles pagasts,ADM2,DISTRICT
SM265991,-50.9443,-28.4122,Vacaria,ADM2,DISTRICT
SM16265,-66.4763,18.1534,Vacas Barrio,ADM2,DISTRICT
SM230867,79.8737,60.1038,Vach-Vasjugan,LANGUAGE,LANGUAGE
SM293793,42.75,55.75,Vachskiy rayon,ADM2,DISTRICT
SM292609,26.3859,47.8823,Vaculesti,ADM2,DISTRICT
SM290141,23.714,47.2063,Vad,ADM2,DISTRICT
SM13888,22.7797,56.3907,Vadakstes pagasts,ADM2,DISTRICT
SM291477,24.3402,43.8453,Vadastrita,ADM2,DISTRICT
SM292703,27.9059,45.3374,Vadeni,ADM2,DISTRICT
SM258340,109.57,75.44,Vadeyev Nganasan,ETHNICITY,ETHNICITY
SM275903,-86.9555,13.6436,Vado Ancho,ADM2,DISTRICT
SM276836,73.12,22.18,Vadodara (2013-),ADM2,DISTRICT
SM293794,44.1667,55.5,Vadskiy rayon,ADM2,DISTRICT
SM296417,14.8616,58.4078,Vadstena,ADM2,DISTRICT
SM286345,29.7492,70.0741,Vadsø,ADM2,DISTRICT
SM292485,22.5027,46.9719,Vadu Crisului,ADM2,DISTRICT
SM291046,23.9608,47.8813,Vadu Izei,ADM2,DISTRICT
SM289844,22.9553,46.4091,Vadu Motilor,ADM2,DISTRICT
SM289927,26.8825,45.1574,Vadu Pasii,ADM2,DISTRICT
SM242538,166.2472,-10.1733,Vaeakau-Taumako,LANGUAGE,LANGUAGE
SM11203,26.3142,59.0656,Vaeike-Maarja vald,ADM2,DISTRICT
SM247859,50.0013,34.5309,Vafsi,LANGUAGE,LANGUAGE
SM16266,-66.4509,18.2769,Vaga Barrio,ADM2,DISTRICT
SM294810,69.1667,57.5833,Vagayskiy rayon,ADM2,DISTRICT
SM296653,14.128,57.4721,Vaggeryd,ADM2,DISTRICT
SM247862,9.5655,9.6465,Vaghat-Ya-Bijim-Legeri,LANGUAGE,LANGUAGE
SM247861,68.6051,26.1404,Vaghri,LANGUAGE,LANGUAGE
SM247864,156.542,-6.6871,Vaghua,LANGUAGE,LANGUAGE
SM290673,23.1011,44.7298,Vagiulesti,ADM2,DISTRICT
SM247865,-2.3133,9.3385,Vagla,LANGUAGE,LANGUAGE
SM250793,-2,8,Vagla,ETHNICITY,ETHNICITY
SM289218,-8.6783,40.4994,Vagos,ADM2,DISTRICT
SM305990,69.3333,38.8333,Vahdat,ADM2,DISTRICT
SM280986,-10.4278,8.1256,Vahun,ADM2,DISTRICT
SM247868,-11.3262,7.0509,Vai,LANGUAGE,LANGUAGE
SM250794,-10.69,6.67,Vai,ETHNICITY,ETHNICITY
SM250794,-10.69,6.67,Vai,ETHNICITY,ETHNICITY
SM250794,-10.69,6.67,Vai,ETHNICITY,ETHNICITY
SM250794,-11.44,7.37,Vai,ETHNICITY,ETHNICITY
SM250794,-11.44,7.37,Vai,ETHNICITY,ETHNICITY
SM250794,-11.44,7.37,Vai,ETHNICITY,ETHNICITY
SM250794,-11,7,Vai,ETHNICITY,ETHNICITY
SM250794,-11,7,Vai,ETHNICITY,ETHNICITY
SM250794,-11,7,Vai,ETHNICITY,ETHNICITY
SM250794,-11.2,6.89,Vai,ETHNICITY,ETHNICITY
SM250794,-11.2,6.89,Vai,ETHNICITY,ETHNICITY
SM250794,-11.2,6.89,Vai,ETHNICITY,ETHNICITY
SM13889,25.2729,57.432,Vaidavas pagasts,ADM2,DISTRICT
SM292250,23.8862,45.1732,Vaideeni,ADM2,DISTRICT
SM261203,-170.5897,-14.2408,Vaifanua,ADM2,DISTRICT
SM304731,-171.7403,-13.8528,Vaimauga East,ADM2,DISTRICT
SM304732,-171.7645,-13.8874,Vaimauga West,ADM2,DISTRICT
SM18004,-175.2007,-21.1986,Vaini,ADM2,DISTRICT
SM3962,21.8283,56.4506,Vainodes Novads,ADM1,DISTRICT
SM13890,21.8315,56.4174,Vainodes pagasts,ADM2,DISTRICT
SM10496,-159.7833,-18.8681,Vaipae District,ADM2,DISTRICT
SM247871,93.7195,24.1378,Vaiphei,LANGUAGE,LANGUAGE
SM277434,85.4167,25.75,Vaishali,ADM2,DISTRICT
SM258356,54.12,31.38,Vaisi,ETHNICITY,ETHNICITY
SM3621,-172.7333,-13.5333,Vaisigano,ADM1,DISTRICT
SM10497,-159.775,-18.8333,Vaitupa District,ADM2,DISTRICT
SM3622,178.6746,-7.4742,Vaitupu,ADM1,DISTRICT
SM13891,25.4303,57.2335,Vaives pagasts,ADM2,DISTRICT
SM3623,22.5,9.8333,Vakaga,ADM1,DISTRICT
SM298968,39.3263,40.9956,Vakfıkebir,ADM2,DISTRICT
SM305963,68.9414,37.756,Vakhsh,ADM2,DISTRICT
SM281468,46.9,-19.7,Vakinankaratra,ADM1:ADM2,DISTRICT
SM346815,21.5178,60.7687,Vakka-Suomi,ADM2:ADM3,DISTRICT
SM286403,5.8227,60.5903,Vaksdal,ADM2,DISTRICT
SM324093,-114.3794,53.6698,Val Quentin,ADM2:ADM3,DISTRICT
SM302788,-101.1517,29.893,Val Verde,ADM2,DISTRICT
SM274509,2.1767,49.0789,Val-d'Oise,ADM2,DISTRICT
SM274510,2.4933,48.7815,Val-de-Marne,ADM2,DISTRICT
SM268930,6.9083,47.0401,Val-de-Ruz,ADM2,DISTRICT
SM268931,6.5631,46.9036,Val-de-Travers,ADM2,DISTRICT
SM3624,7.4597,46.2293,Valais,ADM1,DISTRICT
SM3625,22.5322,41.3344,Valandovo,ADM1,DISTRICT
SM295363,160.5493,-9.6769,Valasi,ADM2,DISTRICT
SM291586,25.9438,45.1238,Valcanesti,ADM2,DISTRICT
SM291638,22.7366,47.1096,Valcau De Jos,ADM2,DISTRICT
SM291479,24.5497,44.2892,Valcele,ADM2,DISTRICT
SM289982,27.1666,44.3746,Valcelele,ADM2,DISTRICT
SM289928,27.3494,45.3443,Valcelele,ADM2,DISTRICT
SM262312,23.5167,43.7167,Valchedram,ADM2,DISTRICT
SM260934,-66.25,-41,Valcheta,ADM2,DISTRICT
SM262455,27.5,43.45,Valchi Dol,ADM2,DISTRICT
SM293831,33.25,57.9167,Valdayskiy rayon,ADM2,DISTRICT
SM13892,22.5931,57.3691,Valdemarpils,ADM2,DISTRICT
SM296418,16.6,58.2,Valdemarsvik,ADM2,DISTRICT
SM303722,-62.3003,10.5795,Valdez,ADM2,DISTRICT
SM301187,-144.5006,61.5006,Valdez-Cordova,ADM2,DISTRICT
SM13893,22.4075,57.3517,Valdgales pagasts,ADM2,DISTRICT
SM270165,-75.3913,7.2895,Valdivia,ADM2,DISTRICT
SM269070,-72.5469,-39.8027,Valdivia,ADM2,DISTRICT
SM3627,9.5266,47.1454,Valduz,ADM1,DISTRICT
SM247879,18.5585,7.4994,Vale,LANGUAGE,LANGUAGE
SM3628,-2.5283,49.4936,Vale,ADM1,DISTRICT
SM265993,-51.2301,-29.3499,Vale Real,ADM2,DISTRICT
SM265994,-52.1056,-29.8351,Vale Verde,ADM2,DISTRICT
SM289219,-8.3602,40.845,Vale de Cambra,ADM2,DISTRICT
SM10038,-58.9638,-14.9759,Vale de Sao Domingos,ADM2:ADM3,DISTRICT
SM266116,-61.9392,-9.7324,Vale do Anari,ADM2,DISTRICT
SM266118,-62.0699,-10.2065,Vale do Paraíso,ADM2,DISTRICT
SM265992,-52.6816,-29.5816,Vale do Sol,ADM2,DISTRICT
SM274758,-3.4167,51.4167,Vale of Glamorgan,ADM1:ADM2,DISTRICT
SM289983,26.7818,44.3483,Valea Argovei,ADM2,DISTRICT
SM291587,26.1612,44.9553,Valea Calugareasca,ADM2,DISTRICT
SM291047,23.461,47.4071,Valea Chioarului,ADM2,DISTRICT
SM290939,27.5398,44.7146,Valea Ciorii,ADM2,DISTRICT
SM291305,24.6196,45.1896,Valea Danului,ADM2,DISTRICT
SM290599,26.3068,44.2114,Valea Dragului,ADM2,DISTRICT
SM291306,24.7009,45.1968,Valea Iasului,ADM2,DISTRICT
SM290142,23.32,46.6409,Valea Ierii,ADM2,DISTRICT
SM291205,24.0753,46.6309,Valea Larga,ADM2,DISTRICT
SM292486,22.132,47.521,Valea Lui Mihai,ADM2,DISTRICT
SM289845,24.0828,46.1336,Valea Lunga,ADM2,DISTRICT
SM290316,25.5699,45.0698,Valea Lunga-cricov,ADM2,DISTRICT
SM290940,26.8352,44.7355,Valea Macrisului,ADM2,DISTRICT
SM292251,24.0006,44.6737,Valea Mare,ADM2,DISTRICT
SM291480,24.4612,44.4419,Valea Mare,ADM2,DISTRICT
SM290317,25.2329,44.7938,Valea Mare,ADM2,DISTRICT
SM291307,25.1057,45.289,Valea Mare Pravat,ADM2,DISTRICT
SM290476,27.6955,45.8593,Valea Marului,ADM2,DISTRICT
SM292079,28.9199,45.0321,Valea Nucarilor,ADM2,DISTRICT
SM289930,27.0468,45.3617,Valea Ramnicului,ADM2,DISTRICT
SM289931,26.8291,45.4882,Valea Salciei,ADM2,DISTRICT
SM292385,26.8082,45.8789,Valea Sarii,ADM2,DISTRICT
SM290887,26.6646,47.2884,Valea Seaca,ADM2,DISTRICT
SM292169,27.0454,46.2485,Valea Seaca,ADM2,DISTRICT
SM290420,23.8667,43.9833,Valea Stanciului,ADM2,DISTRICT
SM291382,27.0817,46.7945,Valea Ursului,ADM2,DISTRICT
SM291764,24.3122,46.0714,Valea Viilor,ADM2,DISTRICT
SM291699,23.1849,47.6955,Valea Vinului,ADM2,DISTRICT
SM303870,-68.0833,10.0833,Valencia,ADM2,DISTRICT
SM301860,-106.8091,34.7154,Valencia,ADM2,DISTRICT
SM274042,-0.8333,39.3333,Valencia,ADM2,DISTRICT
SM273480,-79.3774,-0.7119,Valencia,ADM2,DISTRICT
SM269949,-76.2143,8.2335,Valencia,ADM2,DISTRICT
SM265332,-43.8784,-22.2402,Valencia,ADM2,DISTRICT
SM7601,-0.4,39.5,Valencian,ETHNICITY,ETHNICITY
SM247880,-0.75,39.5,Valencian Sign Language,LANGUAGE,LANGUAGE
SM16267,-65.922,18.1935,Valenciano Abajo Barrio,ADM2,DISTRICT
SM16268,-65.9233,18.1643,Valenciano Arriba Barrio,ADM2,DISTRICT
SM291481,24.7975,44.2188,Valeni,ADM2,DISTRICT
SM290318,25.1768,45.1759,Valeni-dambovita,ADM2,DISTRICT
SM291588,26.0389,45.1877,Valenii De Munte,ADM2,DISTRICT
SM267705,-39.4847,-11.4339,Valente,ADM2,DISTRICT
SM267022,-50.1213,-20.4314,Valentim Gentil,ADM2,DISTRICT
SM289539,-56.8707,-25.5885,Valenzuela,ADM2,DISTRICT
SM289360,-8.6339,42.0282,Valença,ADM2,DISTRICT
SM267704,-39.24,-13.3721,Valença,ADM2,DISTRICT
SM265156,-41.8139,-6.2787,Valença do Piauí,ADM2,DISTRICT
SM303772,-70.6078,9.3206,Valera,ADM2,DISTRICT
SM14896,-96.9785,17.7813,Valerio Trujano,ADM2,DISTRICT
SM3629,26.1667,57.9167,Valga,ADM1:ADMX,DISTRICT
SM11204,26.2715,57.7791,Valga vald,ADM2,DISTRICT
SM13894,23.626,56.8137,Valgundes pagasts,ADM2,DISTRICT
SM267023,-46.9749,-22.9775,Valinhos,ADM2,DISTRICT
SM290796,22.8253,46.0229,Valisoara,ADM2,DISTRICT
SM290059,22.0317,45.2264,Valiug,ADM2,DISTRICT
SM13895,26.0057,57.7746,Valka,ADM2,DISTRICT
SM4040,25.9167,57.5833,Valka Municipality,ADM1,DISTRICT
SM13896,25.9977,57.7428,Valkas pagasts,ADM2,DISTRICT
SM285891,5.8349,50.8571,Valkenburg aan de Geul,ADM2,DISTRICT
SM285957,5.4532,51.3258,Valkenswaard,ADM2,DISTRICT
SM274074,-4.6667,41.5833,Valladolid,ADM2,DISTRICT
SM282936,-88.1241,20.686,Valladolid,ADM2,DISTRICT
SM275717,-88.7084,14.1471,Valladolid,ADM2,DISTRICT
SM286305,7.5334,59.2119,Valle,ADM2,DISTRICT
SM3630,-87.5833,13.5833,Valle,ADM1,DISTRICT
SM260974,-67.5,-30.5833,Valle Fértil,ADM2,DISTRICT
SM260830,-65,-23.5,Valle Grande,ADM2,DISTRICT
SM282671,-97.8382,25.6964,Valle Hermoso,ADM2,DISTRICT
SM247890,-96.3187,17.7964,Valle Nacional Chinantec,LANGUAGE,LANGUAGE
SM261001,-65.75,-28.5833,Valle Viejo,ADM2,DISTRICT
SM3631,7.4167,45.7667,Valle d'Aosta,ADM1:ADM2,DISTRICT
SM3631,7.4167,45.7667,Valle d'Aosta,ADM1:ADM2,DISTRICT
SM3631,7.25,45.75,Valle d'Aosta,ADM1:ADM2,DISTRICT
SM3631,7.25,45.75,Valle d'Aosta,ADM1:ADM2,DISTRICT
SM281878,-100.1101,19.1679,Valle de Bravo,ADM2,DISTRICT
SM14897,-98.9381,19.2946,Valle de Chalco Solidaridad,ADM2,DISTRICT
SM281755,-102.6394,21.0523,Valle de Guadalupe,ADM2,DISTRICT
SM281756,-102.9834,19.8162,Valle de Juárez,ADM2,DISTRICT
SM270522,-73.1295,6.4198,Valle de San José,ADM2,DISTRICT
SM270589,-75.1939,4.172,Valle de San Juan,ADM2,DISTRICT
SM281570,-101.2529,20.4099,Valle de Santiago,ADM2,DISTRICT
SM283273,-105.8476,27.4894,Valle de Zaragoza,ADM2,DISTRICT
SM275932,-87.0184,14.1544,Valle de Ángeles,ADM2,DISTRICT
SM3632,-76.5,3.75,Valle del Cauca,ADM1,DISTRICT
SM10484,-76.8673,0.3558,Valle del Guamuez (1994-),ADM2,DISTRICT
SM282194,-99.8811,26.5593,Vallecillo,ADM2,DISTRICT
SM275933,-87.4024,14.5131,Vallecillo,ADM2,DISTRICT
SM10485,-73.3664,10.3565,Valledupar (1997-),ADM2,DISTRICT
SM9429,6.2175,49.8215,Vallee de L'Ernz,ADM1:ADM2,DISTRICT
SM262766,-64.0031,-18.6313,Vallegrande,ADM2,DISTRICT
SM271847,-71.2833,18.6667,Vallejuelo,ADM2,DISTRICT
SM268981,8.6054,46.3548,Vallemaggia,ADM2,DISTRICT
SM271682,12.3737,55.6313,Vallensbæk,ADM2,DISTRICT
SM296486,18.21,59.5915,Vallentuna,ADM2,DISTRICT
SM13897,24.8118,56.5209,Valles pagasts,ADM2,DISTRICT
SM283448,14.5125,35.8983,Valletta,ADM1:ADM2,DISTRICT
SM301764,-98.9819,41.5673,Valley,ADM2,DISTRICT
SM301670,-106.6675,48.3653,Valley,ADM2,DISTRICT
SM300581,-115.5661,44.7667,Valley,ADM2,DISTRICT
SM247889,-121.191,39.8078,Valley Maidu,LANGUAGE,LANGUAGE
SM324205,-117.2759,55.0636,Valleyview,ADM2:ADM3,DISTRICT
SM276093,-71.9087,19.379,Vallières,ADM2,DISTRICT
SM3633,-4.8546,8.2501,Vallée du Bandama,ADM1,DISTRICT
SM269504,11.2667,2.3667,Vallée du Ntem,ADM2,DISTRICT
SM13898,25.4222,57.5308,Valmiera,ADM2,DISTRICT
SM4047,25.4279,57.535,Valmiera,ADM1,DISTRICT
SM13899,25.4536,57.5925,Valmieras pagasts,ADM2,DISTRICT
SM303808,-71.1473,9.9702,Valmore Rodríguez,ADM2,DISTRICT
SM289307,-8.5009,41.208,Valongo,ADM2,DISTRICT
SM262656,26.6222,54.0361,Valozhyn,ADM2,DISTRICT
SM10487,-75.6541,5.652,Valparaiso (1997-),ADM2,DISTRICT
SM283081,-103.7132,22.7728,Valparaíso,ADM2,DISTRICT
SM267024,-50.947,-21.2151,Valparaíso,ADM2,DISTRICT
SM3634,-71.3333,-32.75,Valparaíso,ADM1,DISTRICT
SM10486,-75.7597,1.0571,Valparaíso (1997-),ADM2,DISTRICT
SM268196,-47.9881,-16.099,Valparaíso de Goiás,ADM2,DISTRICT
SM289365,-7.3361,41.6208,Valpaços,ADM2,DISTRICT
SM247894,166.606,-14.712,Valpei,LANGUAGE,LANGUAGE
SM275961,18.4167,45.6667,Valpovo,ADM2,DISTRICT
SM276837,73.0833,20.5,Valsad (1997-),ADM2,DISTRICT
SM291308,24.7833,45.2167,Valsanesti,ADM2,DISTRICT
SM290200,28.4653,44.1639,Valu Lui Traian,ADM2,DISTRICT
SM258371,-12.46,9.16,Valunka,ETHNICITY,ETHNICITY
SM17134,38,50.1667,Valuyskiy Rayon,ADM2,DISTRICT
SM3635,-71.05,19.5833,Valverde,ADM1,DISTRICT
SM270904,-84.3029,10.1872,Valverde Vega,ADM2,DISTRICT
SM291858,25.6909,47.5635,Vama,ADM2,DISTRICT
SM291700,23.3966,47.8435,Vama,ADM2,DISTRICT
SM292660,25.9799,45.6026,Vama Buzaului,ADM2,DISTRICT
SM247897,165.04,-20.7708,Vamale,LANGUAGE,LANGUAGE
SM247898,14.2131,10.9481,Vame,LANGUAGE,LANGUAGE
SM3636,43.7178,38.4074,Van,ADM1,DISTRICT
SM302630,-85.4526,35.696,Van Buren,ADM2,DISTRICT
SM301333,-86.3064,42.2851,Van Buren,ADM2,DISTRICT
SM300871,-91.95,40.7532,Van Buren,ADM2,DISTRICT
SM302420,-92.5157,35.5807,Van Buren,ADM2,DISTRICT
SM302163,-84.5861,40.8554,Van Wert,ADM2,DISTRICT
SM302789,-95.8365,32.5637,Van Zandt,ADM2,DISTRICT
SM17689,-60.9936,13.9356,Vanard,ADM2,DISTRICT
SM258374,153.31,-11.41,Vanatinai,ETHNICITY,ETHNICITY
SM292386,27.2695,45.7388,Vanatori,ADM2,DISTRICT
SM291207,24.989,46.2315,Vanatori,ADM2,DISTRICT
SM291110,22.9298,44.2478,Vanatori,ADM2,DISTRICT
SM290888,26.764,47.3338,Vanatori,ADM2,DISTRICT
SM290477,28.0089,45.526,Vanatori,ADM2,DISTRICT
SM291383,26.277,47.2353,Vanatori-neamt,ADM2,DISTRICT
SM290600,25.5612,44.4907,Vanatorii Mici,ADM2,DISTRICT
SM302021,-78.4083,36.3648,Vance,ADM2,DISTRICT
SM17954,71.75,38.4167,Vanchskiy Rayon,ADM2,DISTRICT
SM285353,9.059,6.8281,Vandeiky,ADM2,DISTRICT
SM300772,-87.5858,38.0251,Vanderburgh,ADM2,DISTRICT
SM13900,22.8252,57.3362,Vandzenes pagasts,ADM2,DISTRICT
SM282514,-100.9547,24.0234,Vanegas,ADM2,DISTRICT
SM13901,22.6017,56.9101,Vanes pagasts,ADM2,DISTRICT
SM286117,8.5717,61.1256,Vang,ADM2,DISTRICT
SM370974,47.45,-23.4935,Vangaindrano,ADM2:ADM3,DISTRICT
SM13902,24.5282,57.0909,Vangazi,ADM2,DISTRICT
SM247908,157.997,-8.6683,Vangunu,LANGUAGE,LANGUAGE
SM280808,102.4591,18.9129,Vangvieng,ADM2,DISTRICT
SM274795,42.6167,42.0167,Vani,ADM2,DISTRICT
SM295475,166.8687,-11.6556,Vanikoro,ADM2,DISTRICT
SM247909,141.258,-2.685,Vanimo,LANGUAGE,LANGUAGE
SM288597,141.35,-3.3667,Vanimo-Green River,ADM2,DISTRICT
SM265996,-51.8332,-28.4883,Vanini,ADM2,DISTRICT
SM293192,140.25,49.0833,Vaninskiy rayon,ADM2,DISTRICT
SM291111,22.8734,44.4233,Vanju Mare,ADM2,DISTRICT
SM291112,22.8021,44.4312,Vanjulet,ADM2,DISTRICT
SM296547,14.2784,60.4612,Vansbro,ADM2,DISTRICT
SM256247,166.81,-15.4,Vanuatu,ETHNICITY,ETHNICITY
SM247916,29.8135,0.9385,Vanuma,LANGUAGE,LANGUAGE
SM286440,5.5583,62.1,Vanylven,ADM2,DISTRICT
SM247922,167.31,-15.8983,Vao,LANGUAGE,LANGUAGE
SM280775,105.9553,15.7501,Vapy,ADM2,DISTRICT
SM15374,-55.7629,-24.9749,Vaqueria,ADM2,DISTRICT
SM274467,6.2915,43.4586,Var,ADM2,DISTRICT
SM296612,13.0729,58.2439,Vara,ADM2,DISTRICT
SM290060,21.5404,45.0717,Varadia,ADM2,DISTRICT
SM290548,22.1565,46.0462,Varadia De Mures,ADM2,DISTRICT
SM13903,26.7593,56.6061,Varaklani,ADM2,DISTRICT
SM4037,26.6344,56.6333,Varaklanu Novads,ADM1,DISTRICT
SM13904,26.6812,56.5934,Varaklanu pagasts,ADM2,DISTRICT
SM277340,83,25.3333,Varanasi (1997-),ADM2,DISTRICT
SM18240,25.8513,51.3346,Varash City,ADM2,DISTRICT
SM18283,26.0655,51.6584,Varash Raion (2020-),ADM2,DISTRICT
SM290601,26.2522,44.2462,Varasti,ADM2,DISTRICT
SM276011,16.3344,46.3035,Varaždin,ADM2,DISTRICT
SM3637,16.3333,46.25,Varaždinska,ADM1,DISTRICT
SM276012,16.4232,46.2105,Varaždinske Toplice,ADM2,DISTRICT
SM296634,12.418,57.1721,Varberg,ADM2,DISTRICT
SM291491,25.9598,45.1649,Varbilau,ADM2,DISTRICT
SM262381,26.75,43.0167,Varbitsa,ADM2,DISTRICT
SM292487,22.2968,46.9655,Varciorog,ADM2,DISTRICT
SM271748,8.5,55.6167,Varde,ADM2,DISTRICT
SM286346,31.1087,70.3704,Vardø,ADM2,DISTRICT
SM277866,8.8,45.8,Varese,ADM2,DISTRICT
SM292610,26.3153,47.8611,Varfu Campului,ADM2,DISTRICT
SM290319,25.5071,45.0956,Varfuri,ADM2,DISTRICT
SM290549,22.5198,46.3369,Varfurile,ADM2,DISTRICT
SM303780,-66.9346,10.599,Vargas,ADM2,DISTRICT
SM3639,-66.9,10.5667,Vargas,ADM1,DISTRICT
SM293485,65.9167,55.3333,Vargashinskiy rayon,ADM2,DISTRICT
SM291208,24.8026,46.5869,Vargata,ADM2,DISTRICT
SM267027,-46.4156,-22.8912,Vargem,ADM2,DISTRICT
SM266345,-50.9579,-27.4568,Vargem,ADM2,DISTRICT
SM264040,-42.3233,-19.6009,Vargem Alegre,ADM2,DISTRICT
SM268034,-41.0086,-20.6473,Vargem Alta,ADM2,DISTRICT
SM266344,-51.7464,-26.933,Vargem Bonita,ADM2,DISTRICT
SM264041,-46.3143,-20.4056,Vargem Bonita,ADM2,DISTRICT
SM262930,-43.8517,-3.601,Vargem Grande,ADM2,DISTRICT
SM267026,-47.0191,-23.6286,Vargem Grande Paulista,ADM2,DISTRICT
SM264042,-42.3011,-15.3418,Vargem Grande do Rio Pardo,ADM2,DISTRICT
SM267025,-46.8907,-21.8715,Vargem Grande do Sul,ADM2,DISTRICT
SM266343,-52.1533,-26.8443,Vargeão,ADM2,DISTRICT
SM290235,25.559,46.1274,Varghis,ADM2,DISTRICT
SM264043,-45.4119,-21.5658,Varginha,ADM2,DISTRICT
SM247926,79.7516,20.7139,Varhadi-Nagpuri,LANGUAGE,LANGUAGE
SM292033,21.0197,46.0149,Varias,ADM2,DISTRICT
SM13905,26.0151,56.6033,Variesu pagasts,ADM2,DISTRICT
SM280428,103.8997,13.8357,Varin,ADM2,DISTRICT
SM13906,26.1557,57.3162,Varinu pagasts,ADM2,DISTRICT
SM11090,32.7456,35.1141,Variseia,ADM2,DISTRICT
SM247927,156.655,-6.8344,Varisi,LANGUAGE,LANGUAGE
SM268197,-49.6211,-17.0776,Varjao,ADM2,DISTRICT
SM267872,-40.488,-4.1635,Varjota,ADM2,DISTRICT
SM264044,-45.8985,-18.4562,Varjão de Minas,ADM2,DISTRICT
SM346775,28.0072,62.4601,Varkaus,ADM2:ADM3,DISTRICT
SM3988,26.5028,56.2256,Varkavas Novads,ADM1,DISTRICT
SM13907,26.6206,56.2327,Varkavas pagasts,ADM2,DISTRICT
SM290478,27.8297,45.9224,Varlezi,ADM2,DISTRICT
SM247929,73.2975,20.5635,Varli,LANGUAGE,LANGUAGE
SM258388,73.39,20.25,Varli,ETHNICITY,ETHNICITY
SM13908,22.1892,56.8563,Varmes pagasts,ADM2,DISTRICT
SM262456,27.9167,43.2167,Varna,ADM2,DISTRICT
SM3641,27.6,43.2167,Varna,ADM1,DISTRICT
SM293796,44.9167,57.5,Varnavinskiy rayon,ADM2,DISTRICT
SM292773,61.0833,53.25,Varnenskiy rayon,ADM2,DISTRICT
SM265333,-41.8198,-20.8984,Varre-Sai,ADM2,DISTRICT
SM290728,25.339,46.5316,Varsag,ADM2,DISTRICT
SM262303,23.3333,43.2167,Varshets,ADM2,DISTRICT
SM291639,22.9453,47.1971,Varsolt,ADM2,DISTRICT
SM292388,27.0647,45.7246,Vartescoiu,ADM2,DISTRICT
SM298784,41.4544,39.1709,Varto,ADM2,DISTRICT
SM291947,25.198,44.1998,Vartoapele De Sus,ADM2,DISTRICT
SM290421,23.3483,44.2087,Vartop,ADM2,DISTRICT
SM13909,21.5421,57.2844,Varves pagasts,ADM2,DISTRICT
SM290422,23.6064,44.2446,Varvoru De Jos,ADM2,DISTRICT
SM299700,32.7459,50.4509,Varvyns'kyi,ADM2,DISTRICT
SM267710,-39.3709,-12.9966,Varzedo,ADM2,DISTRICT
SM264047,-43.9204,-15.6554,Varzelândia,ADM2,DISTRICT
SM305991,68.75,38.75,Varzob,ADM2,DISTRICT
SM281320,24.5667,54.2167,Varėnos,ADM2,DISTRICT
SM3642,16.75,47.1667,Vas,ADM1,DISTRICT
SM11091,33.2225,34.8033,Vasa,ADM2,DISTRICT
SM11091,33.2225,34.8033,Vasa,ADM2,DISTRICT
SM11091,32.7869,34.8374,Vasa,ADM2,DISTRICT
SM11091,32.7869,34.8374,Vasa,ADM2,DISTRICT
SM247931,73.8652,21.3624,Vasavi,LANGUAGE,LANGUAGE
SM292488,22.4629,46.4749,Vascau,ADM2,DISTRICT
SM256368,19.64,-14.7,Vasekela !Xu,ETHNICITY,ETHNICITY
SM256368,19.64,-14.7,Vasekela !Xu,ETHNICITY,ETHNICITY
SM256368,19.53,-16.42,Vasekela !Xu,ETHNICITY,ETHNICITY
SM256368,19.53,-16.42,Vasekela !Xu,ETHNICITY,ETHNICITY
SM294953,38,60.4167,Vashkinskiy rayon,ADM2,DISTRICT
SM295343,157.0256,-7.2122,Vasiduki,ADM2,DISTRICT
SM11092,33.1053,35.3452,Vasileia,ADM2,DISTRICT
SM17135,30.2612,59.9397,Vasileostrovskiy Rayon,ADM2,DISTRICT
SM3643,22.6667,41.5333,Vasilevo,ADM1,DISTRICT
SM11093,34.1622,35.4519,Vasili,ADM2,DISTRICT
SM292320,27.7387,46.6468,Vaslui,ADM2,DISTRICT
SM3644,27.75,46.5,Vaslui,ADM1,DISTRICT
SM265335,-43.5981,-22.36,Vassouras,ADM2,DISTRICT
SM276198,16.8594,47.0349,Vasvár,ADM2,DISTRICT
SM18284,30.3257,50.181,Vasyl'kivs'ka Mis'krada,ADM2,DISTRICT
SM18285,35.2841,47.4423,Vasylivka Raion,ADM2,DISTRICT
SM18286,30.3333,50.1667,Vasylkiv Raion (excl. Vasylkiv),ADM2,DISTRICT
SM290797,22.58,46.1843,Vata De Jos,ADM2,DISTRICT
SM291209,24.7795,46.9475,Vatava,ADM2,DISTRICT
SM11094,33.6602,35.14,Vatili,ADM2,DISTRICT
SM277831,-22.2383,63.9723,Vatnsleysustrandarhreppur,ADM2,DISTRICT
SM371027,48.7789,-19.3609,Vatomandry,ADM2:ADM3,DISTRICT
SM281475,47.9,-21.3,Vatovavy Fitovinany,ADM1:ADM2,DISTRICT
SM291859,25.3339,47.3444,Vatra Dornei,ADM2,DISTRICT
SM291860,25.578,47.664,Vatra Moldovitei,ADM2,DISTRICT
SM247939,167.42,-13.83,Vatrata,LANGUAGE,LANGUAGE
SM295364,160.1019,-9.765,Vatukulau,ADM2,DISTRICT
SM274468,5.1667,44,Vaucluse,ADM2,DISTRICT
SM3649,6.645,46.6167,Vaud,ADM1,DISTRICT
SM12807,-77.7805,18.3214,Vaughnsfield,ADM2,DISTRICT
SM3650,-70.75,0.75,Vaupés,ADM1,DISTRICT
SM323838,-112.1086,50.0696,Vauxhall,ADM2:ADM3,DISTRICT
SM3651,-174,-18.6667,Vava'u,ADM1,DISTRICT
SM295377,159.957,-9.435,Vavaea,ADM2,DISTRICT
SM371021,48.95,-17.5167,Vavatenina,ADM2:ADM3,DISTRICT
SM11095,33.2332,34.889,Vavatsinia,ADM2,DISTRICT
SM11096,33.2737,34.837,Vavla,ADM2,DISTRICT
SM3652,80.4758,8.8613,Vavuniya,ADM1:ADM2,DISTRICT
SM9818,24.4167,53.25,Vawkavyski Rayon (excl. Vawkavysk),ADM2,DISTRICT
SM296488,18.3417,59.4039,Vaxholm,ADM2,DISTRICT
SM16269,-66.5707,17.9817,Vayas Barrio,ADM2,DISTRICT
SM3653,45.6167,39.75,Vayots Dzor,ADM1:ADM2,DISTRICT
SM3653,45.6167,39.75,Vayots Dzor,ADM1:ADM2,DISTRICT
SM3653,45.4167,39.75,Vayots Dzor,ADM1:ADM2,DISTRICT
SM3653,45.4167,39.75,Vayots Dzor,ADM1:ADM2,DISTRICT
SM10042,-46.7955,-17.7878,Vazante District,ADM2:ADM3,DISTRICT
SM9952,-46.5356,-17.7452,Vazante Municipality,ADM2,DISTRICT
SM280386,103.1344,12.2423,Veal Veaeng,ADM2,DISTRICT
SM13910,25.1448,57.7914,Vecates pagasts,ADM2,DISTRICT
SM13911,22.9468,56.4786,Vecauces pagasts,ADM2,DISTRICT
SM13912,26.9507,57.5861,Veclaicenes pagasts,ADM2,DISTRICT
SM4031,25.7036,57.0992,Vecpiebalgas Novads,ADM1,DISTRICT
SM13913,25.7856,57.0773,Vecpiebalgas pagasts,ADM2,DISTRICT
SM13914,21.5345,56.6161,Vecpils pagasts,ADM2,DISTRICT
SM13915,26.8123,55.8489,Vecsalienas pagasts,ADM2,DISTRICT
SM13916,24.4197,56.4184,Vecsaules pagasts,ADM2,DISTRICT
SM11596,19.3014,47.4292,Vecsesi Jaras,ADM2,DISTRICT
SM13917,27.3584,56.9847,Vectilzas pagasts,ADM2,DISTRICT
SM4057,24.6344,56.4961,Vecumnieku Novads,ADM1,DISTRICT
SM13918,24.5045,56.6199,Vecumnieku pagasts,ADM2,DISTRICT
SM13919,27.7848,57.2121,Vecumu pagasts,ADM2,DISTRICT
SM258395,81.36,7.65,Vedda,ETHNICITY,ETHNICITY
SM258395,81.36,7.65,Vedda,ETHNICITY,ETHNICITY
SM258395,81.36,7.65,Vedda,ETHNICITY,ETHNICITY
SM258395,81.36,7.65,Vedda,ETHNICITY,ETHNICITY
SM258395,80.68,8.72,Vedda,ETHNICITY,ETHNICITY
SM258395,80.68,8.72,Vedda,ETHNICITY,ETHNICITY
SM258395,80.68,8.72,Vedda,ETHNICITY,ETHNICITY
SM258395,80.68,8.72,Vedda,ETHNICITY,ETHNICITY
SM258395,81.25,7.75,Vedda,ETHNICITY,ETHNICITY
SM258395,81.25,7.75,Vedda,ETHNICITY,ETHNICITY
SM258395,81.25,7.75,Vedda,ETHNICITY,ETHNICITY
SM258395,81.25,7.75,Vedda,ETHNICITY,ETHNICITY
SM258395,81,8,Vedda,ETHNICITY,ETHNICITY
SM258395,81,8,Vedda,ETHNICITY,ETHNICITY
SM258395,81,8,Vedda,ETHNICITY,ETHNICITY
SM258395,81,8,Vedda,ETHNICITY,ETHNICITY
SM258395,81.19,8.59,Vedda,ETHNICITY,ETHNICITY
SM258395,81.19,8.59,Vedda,ETHNICITY,ETHNICITY
SM258395,81.19,8.59,Vedda,ETHNICITY,ETHNICITY
SM258395,81.19,8.59,Vedda,ETHNICITY,ETHNICITY
SM247945,81.2618,6.974,Veddah,LANGUAGE,LANGUAGE
SM291948,25.075,44.0944,Vedea,ADM2,DISTRICT
SM291309,24.6468,44.7774,Vedea,ADM2,DISTRICT
SM292729,46.0833,43,Vedenskiy rayon,ADM2,DISTRICT
SM285842,6.8792,53.0895,Veendam,ADM2,DISTRICT
SM285604,5.5556,52.0246,Veenendaal,ADM2,DISTRICT
SM285620,3.5569,51.539,Veere,ADM2,DISTRICT
SM286092,13.2002,65.8411,Vefsn,ADM2,DISTRICT
SM286093,11.9646,65.6754,Vega,ADM2,DISTRICT
SM3654,-66.333,18.4105,Vega Alta,ADM1,DISTRICT
SM16270,-66.329,18.4115,Vega Alta Barrio-Pueblo,ADM2,DISTRICT
SM3655,-66.3996,18.4258,Vega Baja,ADM1,DISTRICT
SM16271,-66.3869,18.4444,Vega Baja Barrio-Pueblo,ADM2,DISTRICT
SM16272,-66.2095,18.2064,Vega Redonda Barrio,ADM2,DISTRICT
SM282830,-96.6321,19.9752,Vega de Alatorre,ADM2,DISTRICT
SM270167,-74.787,6.8167,Vegachí,ADM2,DISTRICT
SM16273,-66.7012,18.1853,Vegas Abajo Barrio,ADM2,DISTRICT
SM16274,-66.6954,18.1641,Vegas Arriba,ADM2,DISTRICT
SM16275,-66.1126,18.1282,Vegas Barrio (Cayey),ADM2,DISTRICT
SM16276,-66.8729,18.105,Vegas Barrio (Yauco),ADM2,DISTRICT
SM323783,-112.0662,53.4991,Vegreville,ADM2:ADM3,DISTRICT
SM16277,-66.5872,18.1961,Veguitas Barrio,ADM2,DISTRICT
SM286306,8.8645,58.7509,Vegårshei,ADM2,DISTRICT
SM375471,72.3528,30.0419,Vehari,ADM2:ADM3,DISTRICT
SM247947,146.895,-6.9215,Vehes,LANGUAGE,LANGUAGE
SM260975,-67.8333,-31.8333,Veinticinco de Mayo,ADM2,DISTRICT
SM260906,-54.6667,-27.4167,Veinticinco de Mayo,ADM2,DISTRICT
SM260935,-69,-41.25,Veinticinco de Mayo,ADM2,DISTRICT
SM260724,-60.25,-35.5,Veinticinco de Mayo,ADM2,DISTRICT
SM261098,-59.9748,-26.7865,Veinticinco de Mayo,ADM2,DISTRICT
SM271749,9.05,55.4631,Vejen (2007-),ADM2,DISTRICT
SM271750,9.4042,55.7487,Vejle (2007-),ADM2,DISTRICT
SM293599,42,61,Vel'skiy rayon,ADM2,DISTRICT
SM290423,23.3789,44.2853,Vela,ADM2,DISTRICT
SM305643,16.7215,42.96,Vela Luka,ADM2,DISTRICT
SM289384,-28.1516,38.68,Velas,ADM2,DISTRICT
SM285959,5.3805,51.4125,Veldhoven,ADM2,DISTRICT
SM296228,15.1333,46.3667,Velenje,ADM1:ADM2,DISTRICT
SM3656,21.7628,41.7758,Veles,ADM1,DISTRICT
SM305401,17.6609,45.4573,Velika,ADM2,DISTRICT
SM276042,16.0833,45.7,Velika Gorica,ADM2,DISTRICT
SM305577,18.3944,45.1553,Velika Kopanica,ADM2,DISTRICT
SM305454,16.5909,45.6061,Velika Ludina,ADM2,DISTRICT
SM305332,17.0641,45.8002,Velika Pisanica,ADM2,DISTRICT
SM296375,16.3567,46.5786,Velika Polana,ADM1:ADM2,DISTRICT
SM305333,16.954,45.6862,Velika Trnovitica,ADM2,DISTRICT
SM296314,14.5858,45.8392,Velike Lašče,ADM1:ADM2,DISTRICT
SM305513,16.7098,46.2903,Veliki Bukovec,ADM2,DISTRICT
SM305334,17.0483,45.7547,Veliki Grđevac,ADM2,DISTRICT
SM262475,25.632,43.0824,Veliko Tarnovo,ADM2,DISTRICT
SM3657,25.65,43.2333,Veliko Tarnovo,ADM1,DISTRICT
SM305742,15.8439,46.0038,Veliko Trgovišće,ADM2,DISTRICT
SM305335,16.9341,45.9336,Veliko Trojstvo,ADM2,DISTRICT
SM17136,30.5833,56.5,Velikolukskiy Rayon,ADM2,DISTRICT
SM17137,46,60.75,Velikoustyugskiy Rayon,ADM2,DISTRICT
SM296114,19.35,48.2,Velký Krtíš,ADM2,DISTRICT
SM296453,13.0092,55.4478,Vellinge,ADM2,DISTRICT
SM277241,79.035,12.86,Vellore,ADM2,DISTRICT
SM286016,4.6321,52.4518,Velsen,ADM2,DISTRICT
SM247950,5.9441,52.2702,Veluws,DIALECT,LANGUAGE
SM299656,33.7301,49.7898,Velykobahachans'kyi,ADM2,DISTRICT
SM299757,22.4589,48.8925,Velykobereznians'kyi,ADM2,DISTRICT
SM299829,34.685,47.2764,Velykobilozers'kyi,ADM2,DISTRICT
SM300030,33.9311,47.1706,Velykolepetys'kyi,ADM2,DISTRICT
SM299628,29.8506,47.0776,Velykomykhailivs'kyi,ADM2,DISTRICT
SM299965,36.8333,47.9167,Velykonovosilkivs'kyi,ADM2,DISTRICT
SM300031,33.2942,47.3194,Velykooleksandrivs'kyi,ADM2,DISTRICT
SM297827,126.2082,-8.5104,Vemasse,ADM2,DISTRICT
SM247952,13.6633,10.8301,Vemgo-Mabas,LANGUAGE,LANGUAGE
SM270590,-74.9367,4.6918,Venadillo,ADM2,DISTRICT
SM282515,-101.1136,22.9366,Venado,ADM2,DISTRICT
SM302342,-79.7579,41.401,Venango,ADM2,DISTRICT
SM264770,-49.7915,-23.8881,Venceslau Bras,ADM2,DISTRICT
SM247955,29.9523,-22.188,Venda,LANGUAGE,LANGUAGE
SM250795,32.86,-22.79,Venda,ETHNICITY,ETHNICITY
SM250795,32.86,-22.79,Venda,ETHNICITY,ETHNICITY
SM250795,32.86,-22.79,Venda,ETHNICITY,ETHNICITY
SM250795,30,-23,Venda,ETHNICITY,ETHNICITY
SM250795,30,-23,Venda,ETHNICITY,ETHNICITY
SM250795,30,-23,Venda,ETHNICITY,ETHNICITY
SM250795,30,-22.5,Venda,ETHNICITY,ETHNICITY
SM250795,30,-22.5,Venda,ETHNICITY,ETHNICITY
SM250795,30,-22.5,Venda,ETHNICITY,ETHNICITY
SM268035,-41.1354,-20.3849,Venda Nova do Imigrante,ADM2,DISTRICT
SM289490,-8.547,38.6398,Vendas Novas,ADM2,DISTRICT
SM274462,-1.3041,46.6417,Vendée,ADM2,DISTRICT
SM269964,-74.4775,4.0886,Venecia,ADM2,DISTRICT
SM270168,-75.799,5.9542,Venecia,ADM2,DISTRICT
SM247960,12.2142,45.5036,Venetian,LANGUAGE,LANGUAGE
SM247959,12.059,45.601,Venetic,LANGUAGE,LANGUAGE
SM3658,11.75,45.5,Veneto,ADM1,DISTRICT
SM262373,26.9333,43.55,Venets,ADM2,DISTRICT
SM294721,38.25,54.25,Venevskiy rayon,ADM2,DISTRICT
SM277932,12.3263,45.4405,Venezia,ADM2,DISTRICT
SM271069,-78.7872,21.7421,Venezuela,ADM2,DISTRICT
SM247958,-66.7678,10.3294,Venezuelan Sign Language,LANGUAGE,LANGUAGE
SM293883,76.9167,55.9167,Vengerovskiy rayon,ADM2,DISTRICT
SM247963,10.4137,6.114,Vengo,LANGUAGE,LANGUAGE
SM265511,-38.5373,-6.3408,Venha-Ver,ADM2,DISTRICT
SM297828,126.3786,-8.5824,Venilale,ADM2,DISTRICT
SM286254,7.976,58.3427,Vennesla,ADM2,DISTRICT
SM285893,5.9593,51.5141,Venray,ADM2,DISTRICT
SM273481,-79.4667,-1.45,Ventanas,ADM2,DISTRICT
SM264771,-50.2237,-24.142,Ventania,ADM2,DISTRICT
SM270741,-73.5503,5.3599,Ventaquemada,ADM2,DISTRICT
SM9124,21.6015,57.4067,Ventspils city under state jurisdiction,ADM1:ADM2,DISTRICT
SM9124,21.6015,57.4067,Ventspils city under state jurisdiction,ADM1:ADM2,DISTRICT
SM9124,21.6017,57.4061,Ventspils city under state jurisdiction,ADM1:ADM2,DISTRICT
SM9124,21.6017,57.4061,Ventspils city under state jurisdiction,ADM1:ADM2,DISTRICT
SM9125,21.8333,57.3333,Ventspils district (excl. Ventspils city),ADM1,DISTRICT
SM303214,-119.126,34.3575,Ventura,ADM2,DISTRICT
SM247965,-119.035,34.1761,Ventureño,LANGUAGE,LANGUAGE
SM264929,-36.7982,-8.6033,Venturosa,ADM2,DISTRICT
SM17690,-61.0168,13.9156,Venus,ADM2,DISTRICT
SM282335,-97.6718,20.5044,Venustiano Carranza,ADM2,DISTRICT
SM281990,-102.674,20.1216,Venustiano Carranza,ADM2,DISTRICT
SM283331,-99.1105,19.4397,Venustiano Carranza,ADM2,DISTRICT
SM283108,-92.5584,16.3231,Venustiano Carranza,ADM2,DISTRICT
SM265997,-52.2196,-29.5421,Venâncio Aires,ADM2,DISTRICT
SM247966,34.7865,60.3353,Veps,LANGUAGE,LANGUAGE
SM258428,35,61,Veps,ETHNICITY,ETHNICITY
SM258428,35,61,Veps,ETHNICITY,ETHNICITY
SM258428,35.02,61.27,Veps,ETHNICITY,ETHNICITY
SM258428,35.02,61.27,Veps,ETHNICITY,ETHNICITY
SM263127,-55.3481,-12.4661,Vera,ADM2,DISTRICT
SM261027,-60.5,-29,Vera,ADM2,DISTRICT
SM17964,125.563,-8.5924,Vera Cruz,ADM2,DISTRICT
SM267029,-49.8328,-22.2334,Vera Cruz,ADM2,DISTRICT
SM265998,-52.5282,-29.7728,Vera Cruz,ADM2,DISTRICT
SM265512,-35.4442,-6.0326,Vera Cruz,ADM2,DISTRICT
SM267711,-38.7091,-13.0255,Vera Cruz,ADM2,DISTRICT
SM264772,-53.9281,-24.9409,Vera Cruz do Oeste,ADM2,DISTRICT
SM265158,-41.506,-7.5548,Vera Mendes,ADM2,DISTRICT
SM247968,167.4287,-13.8925,Vera'a,LANGUAGE,LANGUAGE
SM282831,-96.2278,19.1778,Veracruz,ADM2,DISTRICT
SM275875,-88.7857,14.8834,Veracruz,ADM2,DISTRICT
SM3659,-96.6667,19.3333,Veracruz,ADM1,DISTRICT
SM3660,-81.1333,8.25,Veraguas (1997-),ADM1,DISTRICT
SM265999,-51.5566,-28.9779,Veranópolis,ADM2,DISTRICT
SM295534,-88.8719,13.6593,Verapaz,ADM2,DISTRICT
SM277888,8.55,45.9333,Verbano-Cusio-Ossola,ADM2,DISTRICT
SM290424,23.1789,44.2931,Verbita,ADM2,DISTRICT
SM277889,8.4142,45.3319,Vercelli,ADM2,DISTRICT
SM286463,11.7272,63.9002,Verdal,ADM2,DISTRICT
SM264930,-38.9982,-7.9847,Verdejante,ADM2,DISTRICT
SM264048,-43.6801,-15.5468,Verdelândia,ADM2,DISTRICT
SM250796,13,9,Vere,ETHNICITY,ETHNICITY
SM247972,12.4162,9.0063,Vere Kaadam,LANGUAGE,LANGUAGE
SM267712,-40.0487,-17.15,Vereda,ADM2,DISTRICT
SM264049,-42.7486,-17.5057,Veredinha,ADM2,DISTRICT
SM13921,27.363,56.5641,Veremu pagasts,ADM2,DISTRICT
SM291861,26.4643,47.625,Veresti,ADM2,DISTRICT
SM13922,21.1418,56.7157,Vergales pagasts,ADM2,DISTRICT
SM269965,-74.3154,5.136,Vergara,ADM2,DISTRICT
SM291482,24.3423,44.6413,Verguleasa,ADM2,DISTRICT
SM293600,46,62.8333,Verhnetoemskiy rayon,ADM2,DISTRICT
SM299926,34.3295,48.6571,Verkhn'odniprovs'kyi,ADM2,DISTRICT
SM300032,34.3314,47.2549,Verkhn'orohachyts'kyi,ADM2,DISTRICT
SM293193,133,50.5,Verkhnebureinskiy rayon,ADM2,DISTRICT
SM293291,52.25,59.3333,Verkhnekamskiy rayon,ADM2,DISTRICT
SM294664,86,59,Verkhneketskiy rayon,ADM2,DISTRICT
SM294996,40,51.8333,Verkhnekhavskiy rayon,ADM2,DISTRICT
SM294327,150.8333,65.75,Verkhnekolymskiy rayon,ADM2,DISTRICT
SM294997,40.3833,50.1667,Verkhnemamonskiy rayon,ADM2,DISTRICT
SM292774,59.3333,53.9167,Verkhneural'skiy rayon,ADM2,DISTRICT
SM294639,48.75,55.6667,Verkhneuslonskiy rayon,ADM2,DISTRICT
SM294328,120.5,63,Verkhnevilyuyskiy rayon,ADM2,DISTRICT
SM292775,60.1667,56,Verkhniy Ufaley gorsovet,ADM2,DISTRICT
SM293292,48.9633,58.0153,Verkhoshizhemskiy rayon,ADM2,DISTRICT
SM294956,42.25,60.5833,Verkhovazhskiy rayon,ADM2,DISTRICT
SM299982,24.8331,48.1554,Verkhovyns'kyi,ADM2,DISTRICT
SM294329,136,68,Verkhoyanskiy rayon,ADM2,DISTRICT
SM264051,-42.2707,-20.0309,Vermelho Novo,ADM2,DISTRICT
SM290061,21.6299,45.5222,Vermes,ADM2,DISTRICT
SM301160,-92.3043,29.8094,Vermilion,ADM2,DISTRICT
SM300684,-87.7328,40.1834,Vermilion,ADM2,DISTRICT
SM323784,-110.8502,53.3547,Vermilion,ADM2:ADM3,DISTRICT
SM323785,-110.4983,53.3816,Vermilion River County No. 24,ADM2:ADM3,DISTRICT
SM300773,-87.464,39.8538,Vermillion,ADM2,DISTRICT
SM3661,-72.7498,44.0003,Vermont,ADM1,DISTRICT
SM292943,42.5833,56.8333,Vernelandekhovskiy rayon,ADM2,DISTRICT
SM289932,26.6965,45.208,Vernesti,ADM2,DISTRICT
SM303282,-90.8344,43.5939,Vernon,ADM2,DISTRICT
SM301161,-93.1842,31.1083,Vernon,ADM2,DISTRICT
SM301518,-94.3424,37.8506,Vernon,ADM2,DISTRICT
SM303786,-68.5586,10.4697,Veroes,ADM2,DISTRICT
SM277933,11.0333,45.4167,Verona,ADM2,DISTRICT
SM270636,-76.2465,4.6634,Versalles,ADM2,DISTRICT
SM264931,-35.8032,-7.7899,Vertente do Lério,ADM2,DISTRICT
SM264932,-35.9778,-7.9094,Vertentes,ADM2,DISTRICT
SM270983,-78.1513,21.2632,Vertientes,ADM2,DISTRICT
SM264773,-52.9592,-25.8533,Verê,ADM2,DISTRICT
SM264050,-48.3589,-19.5918,Veríssimo,ADM2,DISTRICT
SM296376,16.1712,46.5818,Veržej,ADM1:ADM2,DISTRICT
SM294786,37.1667,58.6667,Ves'egonskiy rayon,ADM2,DISTRICT
SM13923,25.5336,57.2674,Veselavas pagasts,ADM2,DISTRICT
SM299830,34.9213,47.0137,Veselivs'kyi,ADM2,DISTRICT
SM299594,31.2327,47.3565,Veselynivs'kyi,ADM2,DISTRICT
SM264052,-43.9515,-19.7282,Vespasiano,ADM2,DISTRICT
SM266000,-51.8665,-29.0706,Vespasiano Correa,ADM2,DISTRICT
SM286048,10.7481,59.6023,Vestby (1964-),ADM2,DISTRICT
SM3663,-22.5,65.6667,Vestfirðir,ADM1,DISTRICT
SM4184,8.7229,59.4938,Vestfold og Telemark,ADM1,DISTRICT
SM271710,9.3708,56.8,Vesthimmerland,ADM2,DISTRICT
SM13924,25.849,56.8662,Vestienas pagasts,ADM2,DISTRICT
SM3926,6.4326,60.9302,Vestland,ADM1,DISTRICT
SM274537,-7.1693,62.1642,Vestmanna,ADM2,DISTRICT
SM277817,-20.269,63.4388,Vestmannaeyjar,ADM2,DISTRICT
SM286441,7.0897,62.6213,Vestnes,ADM2,DISTRICT
SM286118,8.982,61.0884,Vestre Slidre,ADM2,DISTRICT
SM286119,10.6076,60.7259,Vestre Toten,ADM2,DISTRICT
SM11632,-23.4652,65.554,Vesturbyggd,ADM2,DISTRICT
SM3665,-21.8333,64.75,Vesturland,ADM1,DISTRICT
SM286094,13.6112,68.1483,Vestvågøy,ADM2,DISTRICT
SM276206,17.8216,47.0967,Veszprém,ADM1:ADM2,DISTRICT
SM3666,17.6667,47.1667,Veszprém,ADM1,DISTRICT
SM283083,-102.4827,22.8525,Vetagrande,ADM2,DISTRICT
SM270524,-72.8813,7.3226,Vetas,ADM2,DISTRICT
SM291210,24.8001,46.3519,Vetca,ADM2,DISTRICT
SM290798,22.7674,45.8742,Vetel,ADM2,DISTRICT
SM323867,-111.1174,52.0011,Veteran,ADM2:ADM3,DISTRICT
SM291701,22.7562,47.7929,Vetis,ADM2,DISTRICT
SM296645,15.1847,57.3686,Vetlanda,ADM2,DISTRICT
SM293797,45.5833,57.75,Vetluzhskiy rayon,ADM2,DISTRICT
SM262371,26.3333,43.7167,Vetovo,ADM2,DISTRICT
SM262457,27.4333,43.3167,Vetrino,ADM2,DISTRICT
SM292321,28.218,46.4574,Vetrisoaia,ADM2,DISTRICT
SM280424,106.8589,14.0502,Veun Sai,ADM2,DISTRICT
SM286095,12.4392,65.7,Vevelstad,ADM2,DISTRICT
SM3667,20.5833,41.2333,Vevčani,ADM1,DISTRICT
SM294691,38.5833,50.1667,Veydelevskiy rayon,ADM2,DISTRICT
SM298854,35.4623,41.1519,Vezirköprü,ADM2,DISTRICT
SM247981,43.67,-23.34,Vezo,DIALECT,LANGUAGE
SM305128,30.1936,-22.7547,Vhembe,ADM2,DISTRICT
SM266001,-51.9874,-27.5774,Viadutos,ADM2,DISTRICT
SM266002,-50.9222,-30.2043,Viamão,ADM2,DISTRICT
SM262931,-45.0055,-3.197,Viana,ADM2,DISTRICT
SM268036,-40.5141,-20.3676,Viana,ADM2,DISTRICT
SM260330,13.3976,-8.9987,Viana,ADM2,DISTRICT
SM289491,-8.0001,38.3365,Viana do Alentejo,ADM2,DISTRICT
SM289361,-8.8346,41.6937,Viana do Castelo,ADM2,DISTRICT
SM3668,-8.5063,41.8965,Viana do Castelo,ADM1,DISTRICT
SM17691,-60.974,13.8127,Viancelle,ADM2,DISTRICT
SM370456,6.2089,49.935,Vianden,ADM1:ADM2:ADM3,DISTRICT
SM281368,6.1662,49.9326,Vianden,ADM1:ADM2,DISTRICT
SM269966,-74.554,4.8958,Vianí,ADM2,DISTRICT
SM268198,-48.4565,-16.8529,Vianópolis,ADM2,DISTRICT
SM277941,16.1274,38.6996,Vibo Valentia,ADM2,DISTRICT
SM271699,9.3667,56.45,Viborg (2007-),ADM2,DISTRICT
SM17845,14.4697,46.0078,Vic District,ADM2,DISTRICT
SM266003,-53.3997,-27.1798,Vicente Dutra,ADM2,DISTRICT
SM282336,-97.1796,18.5222,Vicente Guerrero,ADM2,DISTRICT
SM281527,-103.978,23.7217,Vicente Guerrero,ADM2,DISTRICT
SM260725,-58.4744,-34.5235,Vicente López,ADM2,DISTRICT
SM271863,-71.0833,18.3833,Vicente Noble,ADM2,DISTRICT
SM263095,-54.4618,-22.4916,Vicentina,ADM2,DISTRICT
SM268200,-49.9077,-17.7071,Vicentinópolis,ADM2,DISTRICT
SM277934,11.45,45.6667,Vicenza,ADM2,DISTRICT
SM3669,-69.5,5,Vichada,ADM1,DISTRICT
SM370453,6,49.8031,Vichten,ADM1:ADM2:ADM3,DISTRICT
SM292944,41.9167,57.0333,Vichuga,ADM2,DISTRICT
SM292945,42,57.25,Vichugskiy rayon,ADM2,DISTRICT
SM291862,25.7266,47.9011,Vicovu De Jos,ADM2,DISTRICT
SM291863,25.6584,47.9207,Vicovu De Sus,ADM2,DISTRICT
SM286872,-74.3333,-13.75,Victor Fajardo,ADM2,DISTRICT
SM266004,-52.6807,-28.545,Victor Graeff,ADM2,DISTRICT
SM261742,138.5461,-35.5161,Victor Harbor,ADM2,DISTRICT
SM292034,21.8757,45.6123,Victor Vlad Delamarina,ADM2,DISTRICT
SM302790,-96.9715,28.7964,Victoria,ADM2,DISTRICT
SM17692,-61.0396,13.8112,Victoria,ADM2,DISTRICT
SM290889,27.6017,47.3075,Victoria,ADM2,DISTRICT
SM292661,24.7028,45.7297,Victoria,ADM2,DISTRICT
SM292705,27.6704,44.7957,Victoria,ADM2,DISTRICT
SM282672,-99.1349,23.7584,Victoria,ADM2,DISTRICT
SM281571,-100.2229,21.3545,Victoria,ADM2,DISTRICT
SM275812,-87.5302,15.1001,Victoria,ADM2,DISTRICT
SM295605,-88.6441,13.9715,Victoria,ADM2,DISTRICT
SM270854,-74.8406,5.4567,Victoria,ADM2,DISTRICT
SM268798,-67.4142,47.0729,Victoria,ADM2,DISTRICT
SM327821,-98.8964,49.6642,Victoria,ADM2:ADM3,DISTRICT
SM268830,-60.5818,46.5001,Victoria,ADM2,DISTRICT
SM261180,-60.3333,-32.6667,Victoria,ADM2,DISTRICT
SM3670,145,-37,Victoria,ADM1,DISTRICT
SM16278,-67.1508,18.4114,Victoria Barrio,ADM2,DISTRICT
SM327663,-96.5393,50.712,Victoria Beach,ADM2:ADM3,DISTRICT
SM9737,130.7617,-15.9292,Victoria Daly (2014-),ADM2,DISTRICT
SM261322,115.9059,-31.9765,Victoria Park,ADM2,DISTRICT
SM261323,116.2855,-31.0163,Victoria Plains,ADM2,DISTRICT
SM12808,-77.378,17.9047,Victoria Town,ADM2,DISTRICT
SM262932,-45.3198,-4.2188,Victorino Freire,ADM2,DISTRICT
SM264934,-35.3912,-7.6565,Vicência,ADM2,DISTRICT
SM266346,-49.3382,-27.3897,Vidal Ramos,ADM2,DISTRICT
SM17693,-60.9807,14.0244,Vide Bouteille,ADM2,DISTRICT
SM266347,-51.1016,-27.0344,Videira,ADM2,DISTRICT
SM291949,25.5784,44.2766,Videle,ADM2,DISTRICT
SM296349,15.8813,46.3641,Videm,ADM1:ADM2,DISTRICT
SM289424,-7.7447,38.1845,Vidigueira,ADM2,DISTRICT
SM262478,22.8333,44,Vidin,ADM2,DISTRICT
SM3671,22.7167,43.8167,Vidin,ADM1,DISTRICT
SM277013,78,23.9167,Vidisha,ADM2,DISTRICT
SM305514,16.2414,46.2859,Vidovec,ADM2,DISTRICT
SM292389,26.9051,45.9142,Vidra,ADM2,DISTRICT
SM290977,26.1431,44.2811,Vidra,ADM2,DISTRICT
SM289846,22.9273,46.3707,Vidra,ADM2,DISTRICT
SM13925,24.648,57.3157,Vidrizu pagasts,ADM2,DISTRICT
SM247990,36.9316,-7.5267,Vidunda,LANGUAGE,LANGUAGE
SM289435,-8.1324,41.6356,Vieira do Minho,ADM2,DISTRICT
SM264054,-42.2802,-20.9089,Vieiras,ADM2,DISTRICT
SM264357,-38.2709,-6.5584,Vieirópolis,ADM2,DISTRICT
SM247992,-3.8413,10.8429,Viemo,LANGUAGE,LANGUAGE
SM280809,102.3528,18.4542,Viengkham,ADM2,DISTRICT
SM280879,101.0784,20.6319,Viengphoukha,ADM2,DISTRICT
SM280847,104.46,18.493,Viengthong,ADM2,DISTRICT
SM280861,103.3667,20.0833,Viengthong,ADM2,DISTRICT
SM280861,103.3667,20.0833,Viengthong,ADM2,DISTRICT
SM280861,104.1099,20.7661,Viengthong,ADM2,DISTRICT
SM280861,104.1099,20.7661,Viengthong,ADM2,DISTRICT
SM280862,104.3889,20.3611,Viengxay,ADM2,DISTRICT
SM274436,0.452,46.5353,Vienne,ADM2,DISTRICT
SM247993,16.372,48.208,Viennese German,DIALECT,LANGUAGE
SM3674,102.6667,18.0833,Vientiane Capital,ADM1,DISTRICT
SM3673,102.4167,18.75,"Vientiane Province (1994-2006, 2013-)",ADM1,DISTRICT
SM3675,-65.4371,18.1291,Vieques,ADM1,DISTRICT
SM13926,22.865,56.8151,Viesatu pagasts,ADM2,DISTRICT
SM283306,-103.1816,25.3263,Viesca,ADM2,DISTRICT
SM13927,25.5566,56.3452,Viesite,ADM2,DISTRICT
SM4066,25.5194,56.2989,Viesites Novads,ADM1,DISTRICT
SM13928,25.5262,56.3657,Viesites pagasts,ADM2,DISTRICT
SM13929,23.9393,56.4524,Viesturu pagasts,ADM2,DISTRICT
SM13930,25.7397,56.7417,Vietalvas pagasts,ADM2,DISTRICT
SM247997,105.7741,20.6812,Vietnamese,LANGUAGE,LANGUAGE
SM258437,105.35,10.22,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.35,10.22,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.35,10.22,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.35,10.22,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.35,10.22,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.35,10.22,Vietnamese,ETHNICITY,ETHNICITY
SM258437,107,17,Vietnamese,ETHNICITY,ETHNICITY
SM258437,107,17,Vietnamese,ETHNICITY,ETHNICITY
SM258437,107,17,Vietnamese,ETHNICITY,ETHNICITY
SM258437,107,17,Vietnamese,ETHNICITY,ETHNICITY
SM258437,107,17,Vietnamese,ETHNICITY,ETHNICITY
SM258437,107,17,Vietnamese,ETHNICITY,ETHNICITY
SM258437,106.25,20.5,Vietnamese,ETHNICITY,ETHNICITY
SM258437,106.25,20.5,Vietnamese,ETHNICITY,ETHNICITY
SM258437,106.25,20.5,Vietnamese,ETHNICITY,ETHNICITY
SM258437,106.25,20.5,Vietnamese,ETHNICITY,ETHNICITY
SM258437,106.25,20.5,Vietnamese,ETHNICITY,ETHNICITY
SM258437,106.25,20.5,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.9,21,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.9,21,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.9,21,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.9,21,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.9,21,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.9,21,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.85,21.03,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.85,21.03,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.85,21.03,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.85,21.03,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.85,21.03,Vietnamese,ETHNICITY,ETHNICITY
SM258437,105.85,21.03,Vietnamese,ETHNICITY,ETHNICITY
SM258437,106.64,10.82,Vietnamese,ETHNICITY,ETHNICITY
SM258437,106.64,10.82,Vietnamese,ETHNICITY,ETHNICITY
SM258437,106.64,10.82,Vietnamese,ETHNICITY,ETHNICITY
SM258437,106.64,10.82,Vietnamese,ETHNICITY,ETHNICITY
SM258437,106.64,10.82,Vietnamese,ETHNICITY,ETHNICITY
SM258437,106.64,10.82,Vietnamese,ETHNICITY,ETHNICITY
SM3676,-60.9659,13.7841,Vieux Fort,ADM1,DISTRICT
SM17694,-60.9548,13.732,Vieux Fort/Laborie Highway,ADM2,DISTRICT
SM17695,-60.9355,13.7869,Vige',ADM2,DISTRICT
SM264125,-48.138,-0.8882,Vigia,ADM2,DISTRICT
SM17696,-60.9961,14.0205,Vigie,ADM2,DISTRICT
SM300774,-87.39,39.4306,Vigo,ADM2,DISTRICT
SM270169,-76.6628,6.3851,Vigía del Fuerte,ADM2,DISTRICT
SM3677,34.712,0.072,Vihiga,ADM1:ADM2,DISTRICT
SM291703,22.95,47.6667,Viile Satu Mare,ADM2,DISTRICT
SM274098,24.8483,59.502,Viimsi,ADM2,DISTRICT
SM291950,25.1752,43.7799,Viisoara,ADM2,DISTRICT
SM291211,24.5652,46.3028,Viisoara,ADM2,DISTRICT
SM291384,26.2685,46.9399,Viisoara,ADM2,DISTRICT
SM290143,23.9358,46.5538,Viisoara,ADM2,DISTRICT
SM292611,26.7427,48.1675,Viisoara,ADM2,DISTRICT
SM292489,22.45,47.3833,Viisoara,ADM2,DISTRICT
SM13931,26.0091,57.5978,Vijciema pagasts,ADM2,DISTRICT
SM270637,-76.4936,3.7328,Vijes,ADM2,DISTRICT
SM15178,5.0833,51.9167,Vijfheerenlanden,ADM2,DISTRICT
SM286172,6.5851,61.0886,Vik,ADM2,DISTRICT
SM11696,77.9045,17.3354,Vikarabad,ADM2,DISTRICT
SM4186,9.8948,60.0805,Viken,ADM1,DISTRICT
SM323786,-111.7645,53.0974,Viking,ADM2:ADM3,DISTRICT
SM11097,33.201,34.828,Vikla,ADM2,DISTRICT
SM13932,27.381,57.2293,Viksnas pagasts,ADM2,DISTRICT
SM299831,35.4404,47.9487,Vil'nians'kyi,ADM2,DISTRICT
SM299520,30.873,48.2353,Vil'shans'kyi,ADM2,DISTRICT
SM264774,-53.8077,-23.555,Vila Alta,ADM2,DISTRICT
SM268201,-47.0926,-14.9791,Vila Boa,ADM2,DISTRICT
SM289448,-7.1507,41.3143,Vila Flor,ADM2,DISTRICT
SM265514,-35.0845,-6.2938,Vila Flor,ADM2,DISTRICT
SM266005,-51.5294,-28.8684,Vila Flores,ADM2,DISTRICT
SM289265,-9.0322,38.9214,Vila Franca de Xira,ADM2,DISTRICT
SM289386,-25.4291,37.7366,Vila Franca do Campo,ADM2,DISTRICT
SM266007,-52.1415,-28.1278,Vila Lângaro,ADM2,DISTRICT
SM266008,-52.1638,-28.5551,Vila Maria,ADM2,DISTRICT
SM289331,-8.4095,39.4756,Vila Nova da Barquinha,ADM2,DISTRICT
SM289353,-8.7053,41.9331,Vila Nova de Cerveira,ADM2,DISTRICT
SM289436,-8.4851,41.4122,Vila Nova de Famalicão,ADM2,DISTRICT
SM289233,-7.2039,41.0637,Vila Nova de Foz Côa,ADM2,DISTRICT
SM289309,-8.627,41.137,Vila Nova de Gaia,ADM2,DISTRICT
SM289409,-7.7613,40.8659,Vila Nova de Paiva,ADM2,DISTRICT
SM289478,-8.2411,40.2213,Vila Nova de Poiares,ADM2,DISTRICT
SM265159,-40.9362,-7.1867,Vila Nova do Piauí,ADM2,DISTRICT
SM266009,-53.88,-30.3371,Vila Nova do Sul,ADM2,DISTRICT
SM262933,-48.068,-5.0615,Vila Nova dos Martírios,ADM2,DISTRICT
SM268037,-40.6516,-18.6119,Vila Pavão,ADM2,DISTRICT
SM289366,-7.6367,41.5208,Vila Pouca de Aguiar,ADM2,DISTRICT
SM268202,-48.7757,-15.2022,Vila Propício,ADM2,DISTRICT
SM289367,-7.7399,41.3002,Vila Real,ADM2,DISTRICT
SM3678,-7.5992,41.5436,Vila Real,ADM1,DISTRICT
SM289508,-7.4254,37.1923,Vila Real de Santo António,ADM2,DISTRICT
SM263128,-51.4137,-10.1341,Vila Rica,ADM2,DISTRICT
SM268038,-40.3663,-18.98,Vila Valério,ADM2,DISTRICT
SM268039,-40.3226,-20.3848,Vila Velha,ADM2,DISTRICT
SM289461,-7.6599,39.6871,Vila Velha de Ródão,ADM2,DISTRICT
SM289437,-8.4363,41.6487,Vila Verde,ADM2,DISTRICT
SM289492,-7.4161,38.7759,Vila Viçosa,ADM2,DISTRICT
SM289460,-8.1284,39.7069,Vila de Rei,ADM2,DISTRICT
SM289507,-8.9079,37.0884,Vila do Bispo,ADM2,DISTRICT
SM289308,-8.6829,41.3329,Vila do Conde,ADM2,DISTRICT
SM289385,-25.0997,36.9745,Vila do Porto,ADM2,DISTRICT
SM280779,105.9368,16.9407,Vilabuly,ADM2,DISTRICT
SM13933,27.682,57.1871,Vilaka,ADM2,DISTRICT
SM3974,27.6542,57.1822,Vilakas Novads,ADM1,DISTRICT
SM283905,35.1495,-22.268,Vilanculos,ADM2,DISTRICT
SM13934,26.9268,56.5495,Vilani,ADM2,DISTRICT
SM3991,26.8961,56.5633,Vilanu Novads,ADM1,DISTRICT
SM13935,26.9104,56.5493,Vilanu pagasts,ADM2,DISTRICT
SM303283,-89.5148,46.0529,Vilas,ADM2,DISTRICT
SM286873,-73.888,-13.632,Vilcas Huamán,ADM2,DISTRICT
SM13936,23.5268,56.3931,Vilces pagasts,ADM2,DISTRICT
SM293601,48,61.25,Vilegodskiy rayon,ADM2,DISTRICT
SM248000,-58.9661,-27.4063,Vilela,LANGUAGE,LANGUAGE
SM258440,-64.16,-24.02,Vilela,ETHNICITY,ETHNICITY
SM296524,16.0321,64.9936,Vilhelmina,ADM2,DISTRICT
SM266119,-60.2753,-12.0206,Vilhena,ADM2,DISTRICT
SM248001,11.3804,-4.0672,Vili,LANGUAGE,LANGUAGE
SM248002,10.3056,-2.7529,Vili of Ngounie,LANGUAGE,LANGUAGE
SM274185,25.5937,58.3563,Viljandi,ADM2,DISTRICT
SM3679,25.5667,58.3333,Viljandi,ADM1:ADMX,DISTRICT
SM11205,25.491,58.3639,Viljandi vald,ADM2,DISTRICT
SM305392,18.0632,45.7503,Viljevo,ADM2,DISTRICT
SM281342,23.0333,54.65,Vilkaviškio,ADM2,DISTRICT
SM13937,24.5796,57.6111,Vilkenes pagasts,ADM2,DISTRICT
SM282832,-97.206,19.6514,Villa Aldama,ADM2,DISTRICT
SM271838,-70.25,18.6333,Villa Altagracia,ADM2,DISTRICT
SM271879,-70.8667,19.5833,Villa Bisonó,ADM2,DISTRICT
SM275443,-90.5141,14.3863,Villa Canales,ADM2,DISTRICT
SM285441,-86.5269,12.0223,Villa Carlos Fonseca,ADM2,DISTRICT
SM270393,-73,7.9167,Villa Caro,ADM2,DISTRICT
SM3680,-80,22.5667,Villa Clara,ADM1,DISTRICT
SM283109,-92.5905,15.1865,Villa Comaltitlán,ADM2,DISTRICT
SM281757,-103.6889,20.3989,Villa Corona,ADM2,DISTRICT
SM283110,-93.2084,16.1198,Villa Corzo,ADM2,DISTRICT
SM14898,-96.4127,17.0511,Villa Diaz Ordaz,ADM2,DISTRICT
SM289722,-57.5874,-25.3738,Villa Elisa,ADM2,DISTRICT
SM289601,-57.1229,-26.4669,Villa Florida,ADM2,DISTRICT
SM289607,-57.9602,-26.3474,Villa Franca,ADM2,DISTRICT
SM283085,-101.9081,22.1338,Villa García,ADM2,DISTRICT
SM260726,-56.9725,-37.2617,Villa Gesell,ADM2,DISTRICT
SM271880,-70.7871,19.5642,Villa Gonzalez,ADM2,DISTRICT
SM283086,-101.948,22.54,Villa González Ortega,ADM2,DISTRICT
SM281758,-103.6613,22.0101,Villa Guerrero,ADM2,DISTRICT
SM281881,-99.6735,18.964,Villa Guerrero,ADM2,DISTRICT
SM15378,-58.0474,-23.7808,Villa Hayes (2009-),ADM2,DISTRICT
SM271785,-69.0433,18.4514,Villa Hermosa,ADM2,DISTRICT
SM283087,-101.7407,22.3922,Villa Hidalgo,ADM2,DISTRICT
SM282613,-109.344,30.2289,Villa Hidalgo,ADM2,DISTRICT
SM282523,-100.6697,22.5347,Villa Hidalgo,ADM2,DISTRICT
SM271813,-71.5333,18.5833,Villa Jaragua,ADM2,DISTRICT
SM282524,-100.2178,22.2857,Villa Juárez,ADM2,DISTRICT
SM275444,-90.6034,14.5361,Villa Nueva,ADM2,DISTRICT
SM289608,-57.7276,-26.0441,Villa Oliva,ADM2,DISTRICT
SM282614,-110.0035,29.0483,Villa Pesqueira,ADM2,DISTRICT
SM281760,-104.7076,19.786,Villa Purificación,ADM2,DISTRICT
SM10488,-76.4667,3.1782,Villa Rica,ADM2,DISTRICT
SM271903,-69.8837,19.1505,Villa Rivas,ADM2,DISTRICT
SM285528,-84.8671,12.0371,Villa Sandino,ADM2,DISTRICT
SM14899,-97.0582,16.5131,Villa Sola de Vega,ADM2,DISTRICT
SM271759,-70.9151,18.496,Villa Tabara Arriba,ADM2,DISTRICT
SM14900,-96.2472,17.3649,Villa Talea de Castro,ADM2,DISTRICT
SM271827,-70.4,19.3,Villa Tapia,ADM2,DISTRICT
SM14901,-97.4636,17.6634,Villa Tejupam de la Union,ADM2,DISTRICT
SM283307,-100.7848,28.2044,Villa Unión,ADM2,DISTRICT
SM248004,120.638,17.471,Villa Viciosa Agta,LANGUAGE,LANGUAGE
SM281882,-100.0017,19.4603,Villa Victoria,ADM2,DISTRICT
SM271802,-71.44,19.8079,Villa Vázquez,ADM2,DISTRICT
SM289713,-55.4819,-24.1335,Villa Ygatimí,ADM2,DISTRICT
SM281879,-100.1285,19.3834,Villa de Allende,ADM2,DISTRICT
SM283316,-103.8444,19.3097,Villa de Alvarez,ADM2,DISTRICT
SM282516,-100.8563,22.6652,Villa de Arista,ADM2,DISTRICT
SM282517,-101.3374,21.949,Villa de Arriaga,ADM2,DISTRICT
SM14902,-97.6308,17.5925,Villa de Chilapa de Diaz,ADM2,DISTRICT
SM283084,-102.2543,23.4614,Villa de Cos,ADM2,DISTRICT
SM14903,-96.7962,17.1962,Villa de Etla,ADM2,DISTRICT
SM282519,-100.6956,23.2765,Villa de Guadalupe,ADM2,DISTRICT
SM270742,-73.5155,5.6706,Villa de Leyva,ADM2,DISTRICT
SM282521,-101.9813,22.9287,Villa de Ramos,ADM2,DISTRICT
SM282522,-100.9465,21.841,Villa de Reyes,ADM2,DISTRICT
SM275852,-87.548,14.307,Villa de San Antonio,ADM2,DISTRICT
SM269967,-73.8204,5.3154,Villa de San Diego de Ubaté,ADM2,DISTRICT
SM275934,-86.9535,14.1868,Villa de San Francisco,ADM2,DISTRICT
SM14904,-97.5737,17.693,Villa de Tamazulapam del Progreso,ADM2,DISTRICT
SM281729,-98.807,19.9051,Villa de Tezontepec,ADM2,DISTRICT
SM14905,-97.5311,16.0479,Villa de Tututepec de Melchor Ocampo,ADM2,DISTRICT
SM14906,-96.7207,16.9307,Villa de Zaachila,ADM2,DISTRICT
SM282520,-100.7249,23.6691,Villa de la Paz,ADM2,DISTRICT
SM281880,-99.4601,19.7255,Villa del Carbón,ADM2,DISTRICT
SM289644,-57.077,-24.5611,Villa del Rosario,ADM2,DISTRICT
SM270395,-72.4818,7.7582,Villa del Rosario,ADM2,DISTRICT
SM261801,13.8514,46.609,Villach,ADM2,DISTRICT
SM261800,13.7729,46.6531,Villach Land,ADM2,DISTRICT
SM283111,-93.3319,16.3415,Villaflores,ADM2,DISTRICT
SM270404,-76.7279,0.8966,Villagarzón,ADM2,DISTRICT
SM17697,-61.0415,13.9394,Village (Anse-la-Raye),ADM2,DISTRICT
SM17699,-61.0611,13.9053,Village (Canaries),ADM2,DISTRICT
SM17698,-61.0502,13.7746,Village (Choiseul),ADM2,DISTRICT
SM17700,-60.9938,13.7493,Village (Laborie),ADM2,DISTRICT
SM17701,-60.8969,13.819,Village (Micoud),ADM2,DISTRICT
SM17702,-61.0418,13.938,Village/Petite Bourgh,ADM2,DISTRICT
SM282673,-99.4187,24.5173,Villagrán,ADM2,DISTRICT
SM281572,-100.9828,20.5382,Villagrán,ADM2,DISTRICT
SM269968,-74.191,5.271,Villagómez,ADM2,DISTRICT
SM270591,-75.1565,4.965,Villahermosa,ADM2,DISTRICT
SM3681,-66.4774,18.1291,Villalba,ADM1,DISTRICT
SM16279,-66.5144,18.0985,Villalba Abajo Barrio,ADM2,DISTRICT
SM16280,-66.5079,18.1377,Villalba Arriba Barrio,ADM2,DISTRICT
SM16281,-66.4924,18.1285,Villalba Barrio-Pueblo,ADM2,DISTRICT
SM289609,-57.9151,-27.1786,Villalbín,ADM2,DISTRICT
SM282196,-100.3873,26.4704,Villaldama,ADM2,DISTRICT
SM281991,-102.599,19.9606,Villamar,ADM2,DISTRICT
SM270855,-75.4368,4.9508,Villamaría,ADM2,DISTRICT
SM285512,-86.8111,12.9637,Villanueva,ADM2,DISTRICT
SM283088,-102.8583,22.2894,Villanueva,ADM2,DISTRICT
SM275886,-88.0436,15.3246,Villanueva,ADM2,DISTRICT
SM270118,-72.9608,10.5833,Villanueva,ADM2,DISTRICT
SM270525,-73.1778,6.6655,Villanueva,ADM2,DISTRICT
SM270709,-75.2657,10.4476,Villanueva,ADM2,DISTRICT
SM270888,-72.8224,4.4782,Villanueva,ADM2,DISTRICT
SM269970,-73.6054,5.2088,Villapinzón,ADM2,DISTRICT
SM260727,-62.75,-39,Villarino,ADM2,DISTRICT
SM289553,-56.4084,-25.831,Villarrica,ADM2,DISTRICT
SM270592,-74.6526,3.831,Villarrica,ADM2,DISTRICT
SM270291,-73.4915,4.0742,Villavicencio,ADM2,DISTRICT
SM270109,-75.1453,3.2977,Villavieja,ADM2,DISTRICT
SM15260,7.1151,13.4947,Ville de Maradi,ADM2,DISTRICT
SM3682,15.0667,12.1167,Ville de N'Djamena,ADM1:ADMX,DISTRICT
SM15261,5.2364,14.8859,Ville de Tahoua,ADM2,DISTRICT
SM15262,8.9883,13.755,Ville de Zinder,ADM2,DISTRICT
SM289723,-57.6071,-25.7116,Villeta,ADM2,DISTRICT
SM269971,-74.505,5.0001,Villeta,ADM2,DISTRICT
SM324059,-111.9202,54.1164,Vilna,ADM2:ADM3,DISTRICT
SM3683,25.3333,54.8333,Vilniaus,ADM1,DISTRICT
SM14155,25.3167,54.6833,Vilnius City,ADM2,DISTRICT
SM14156,25.3833,54.7333,Vilnius District Municipality (excl. Vilnius city),ADM2,DISTRICT
SM13938,25.2185,57.9515,Vilpulkas pagasts,ADM2,DISTRICT
SM277242,79.37,11.99,Viluppuram,ADM2,DISTRICT
SM9819,27.0833,54.5,Vilyeyski Rayon (excl. Vilyeyka),ADM2,DISTRICT
SM294330,123,64,Vilyuyskiy rayon,ADM2,DISTRICT
SM291048,23.6884,47.4177,Vima Mica,ADM2,DISTRICT
SM289449,-6.5209,41.5729,Vimioso,ADM2,DISTRICT
SM296657,15.8674,57.6901,Vimmerby,ADM2,DISTRICT
SM299459,27.2325,49.0344,Vin'kovets'kyi,ADM2,DISTRICT
SM269500,13.7271,7.1637,Vina,ADM2,DISTRICT
SM261324,115.8541,-31.9314,Vincent,ADM2,DISTRICT
SM248009,-61.1963,13.2436,Vincentian Creole English,LANGUAGE,LANGUAGE
SM273482,-79.7333,-1.55,Vinces,ADM2,DISTRICT
SM260871,-68.6667,-28.2,Vinchina,ADM2,DISTRICT
SM461891,5.8078,59.6054,Vindafjord (2006-),ADM2,DISTRICT
SM296525,19.7122,64.1861,Vindeln,ADM2,DISTRICT
SM292324,27.8057,46.1435,Vinderei,ADM2,DISTRICT
SM258442,33.57,2.52,Vindunda,ETHNICITY,ETHNICITY
SM16359,14.5946,-3.2545,Vindza,ADM2,DISTRICT
SM12809,-76.7706,17.9869,Vineyard Town,ADM2,DISTRICT
SM290551,21.1524,46.0225,Vinga,ADM2,DISTRICT
SM296469,15.8833,59.0667,Vingåker,ADM2,DISTRICT
SM304330,105.681,18.6918,Vinh,ADM2,DISTRICT
SM289450,-7.0406,41.831,Vinhais,ADM2,DISTRICT
SM267030,-46.9814,-23.054,Vinhedo,ADM2,DISTRICT
SM305515,16.1534,46.3391,Vinica,ADM2,DISTRICT
SM3686,22.5842,41.8592,Vinitsa,ADM1,DISTRICT
SM286214,7.989,59.5692,Vinje,ADM2,DISTRICT
SM276019,18.8048,45.2804,Vinkovci,ADM2,DISTRICT
SM11206,26.6226,59.2374,Vinni vald,ADM2,DISTRICT
SM3687,28.4658,49.2338,Vinnytsya,ADM1,DISTRICT
SM11372,28.4436,49.2334,Vinnytsya Raion (2020-),ADM2,DISTRICT
SM305428,14.6894,45.2222,Vinodolska,ADM2,DISTRICT
SM293602,43,63,Vinogradovskiy rayon,ADM2,DISTRICT
SM289933,26.7239,45.4546,Vintila Voda,ADM2,DISTRICT
SM292390,26.7261,45.6297,Vintileasca,ADM2,DISTRICT
SM302164,-82.4854,39.251,Vinton,ADM2,DISTRICT
SM289847,23.4565,46.0223,Vintu De Jos,ADM2,DISTRICT
SM248012,30.5699,-4.9931,Vinza,LANGUAGE,LANGUAGE
SM258443,30.82,-4.62,Vinza,ETHNICITY,ETHNICITY
SM269972,-74.5081,4.4205,Viotá,ADM2,DISTRICT
SM296250,13.9844,45.8236,Vipava,ADM1:ADM2,DISTRICT
SM289934,26.4453,45.2464,Viperesti,ADM2,DISTRICT
SM13939,26.117,56.4696,Vipes pagasts,ADM2,DISTRICT
SM297814,126.3697,-8.8591,Viqueque,ADM2,DISTRICT
SM3688,126.36,-8.7872,Viqueque,ADM1,DISTRICT
SM305605,15.0866,44.3,Vir,ADM2,DISTRICT
SM270743,-73.2668,5.4411,Viracachá,ADM2,DISTRICT
SM267031,-48.3148,-20.8763,Viradouro,ADM2,DISTRICT
SM298866,39.7625,37.231,Viranşehir,ADM2,DISTRICT
SM13940,22.6318,57.1296,Virbu pagasts,ADM2,DISTRICT
SM13941,23.8291,56.5232,Vircavas pagasts,ADM2,DISTRICT
SM260371,12.9439,-15.7652,Virei,ADM2,DISTRICT
SM13942,26.3752,57.3965,Viresu pagasts,ADM2,DISTRICT
SM13943,21.4678,56.4351,Virgas pagasts,ADM2,DISTRICT
SM264055,-42.3485,-16.7164,Virgem da Lapa,ADM2,DISTRICT
SM248013,-63.0785,18.0471,Virgin Islands Creole English,LANGUAGE,LANGUAGE
SM275718,-88.5619,14.0052,Virginia,ADM2,DISTRICT
SM3690,-77.4468,37.5481,Virginia,ADM1,DISTRICT
SM302964,-76.0052,36.7377,Virginia Beach,ADM2,DISTRICT
SM264058,-42.6694,-18.8151,Virginópolis,ADM2,DISTRICT
SM264059,-42.3074,-18.4428,Virgolândia,ADM2,DISTRICT
SM264056,-45.1158,-22.3551,Virgínia,ADM2,DISTRICT
SM305719,16.9868,46.0666,Virje,ADM2,DISTRICT
SM264775,-52.2367,-25.4301,Virmond,ADM2,DISTRICT
SM276015,17.3848,45.8317,Virovitica,ADM2,DISTRICT
SM3691,17.5833,45.75,Viroviticko-Podravska,ADM1,DISTRICT
SM286756,-78.6036,-8.522,Viru,ADM2,DISTRICT
SM11207,26.5859,59.4639,Viru-Nigula vald,ADM2,DISTRICT
SM277243,77.92,9.45,Virudunagar,ADM2,DISTRICT
SM276005,16.187,43.0532,Vis,ADM2,DISTRICT
SM14157,26.4167,55.6,Visaginas,ADM2,DISTRICT
SM277020,83.2667,17.7333,Visakhapatnam,ADM2,DISTRICT
SM292706,27.3061,45.185,Visani,ADM2,DISTRICT
SM258446,122.37,11.14,Visayan,ETHNICITY,ETHNICITY
SM258446,122.37,11.14,Visayan,ETHNICITY,ETHNICITY
SM258446,122.37,11.14,Visayan,ETHNICITY,ETHNICITY
SM258446,121.36,14.05,Visayan,ETHNICITY,ETHNICITY
SM258446,121.36,14.05,Visayan,ETHNICITY,ETHNICITY
SM258446,121.36,14.05,Visayan,ETHNICITY,ETHNICITY
SM258446,123,10,Visayan,ETHNICITY,ETHNICITY
SM258446,123,10,Visayan,ETHNICITY,ETHNICITY
SM258446,123,10,Visayan,ETHNICITY,ETHNICITY
SM264060,-42.8529,-20.9951,Visconde do Rio Branco,ADM2,DISTRICT
SM289410,-7.9172,40.6745,Viseu,ADM2,DISTRICT
SM264126,-46.3208,-1.302,Viseu,ADM2,DISTRICT
SM3692,-7.7982,40.8275,Viseu,ADM1,DISTRICT
SM291049,24.3631,47.7276,Viseu De Jos,ADM2,DISTRICT
SM291050,24.4171,47.7149,Viseu De Sus,ADM2,DISTRICT
SM248015,76.7593,10.1751,Vishavan,LANGUAGE,LANGUAGE
SM291483,24.4547,43.8662,Visina,ADM2,DISTRICT
SM290322,25.5682,45.1126,Visinesti,ADM2,DISTRICT
SM13944,26.7908,56.0687,Visku pagasts,ADM2,DISTRICT
SM10541,13.7213,45.2761,Visnjan-Visignano,ADM2,DISTRICT
SM305516,16.327,46.0898,Visoko,ADM2,DISTRICT
SM268987,7.8561,46.1761,Visp,ADM2,DISTRICT
SM266011,-53.5157,-27.3108,Vista Alegre,ADM2,DISTRICT
SM267033,-48.6548,-21.1802,Vista Alegre do Alto,ADM2,DISTRICT
SM266010,-51.7922,-28.8308,Vista Alegre do Prata,ADM2,DISTRICT
SM266012,-53.6974,-27.2674,Vista Gaúcha,ADM2,DISTRICT
SM281992,-102.457,20.2775,Vista Hermosa,ADM2,DISTRICT
SM270292,-73.5321,2.9041,Vista Hermosa,ADM2,DISTRICT
SM258450,80.48,16.3,Viswabrahmin,ETHNICITY,ETHNICITY
SM291951,25.4525,43.9927,Vitanesti,ADM2,DISTRICT
SM296229,15.2947,46.4031,Vitanje,ADM1:ADM2,DISTRICT
SM277964,12.0833,42.4167,Viterbo,ADM2,DISTRICT
SM270856,-75.9192,5.0451,Viterbo,ADM2,DISTRICT
SM248020,11.1087,6.6652,Viti,LANGUAGE,LANGUAGE
SM13945,22.8502,56.4493,Vitinu pagasts,ADM2,DISTRICT
SM291485,24.377,44.8519,Vitomiresti,ADM2,DISTRICT
SM266348,-49.8552,-26.8267,Vitor Meireles,ADM2,DISTRICT
SM268040,-40.3192,-20.2856,Vitoria,ADM2,DISTRICT
SM264778,-52.8198,-26.2405,Vitorino,ADM2,DISTRICT
SM248023,139.1098,-2.1111,Vitou,LANGUAGE,LANGUAGE
SM11098,33.6502,35.2369,Vitsada,ADM2,DISTRICT
SM3693,29.5,55,Vitsyebsk,ADM1,DISTRICT
SM9820,30.3333,55.25,Vitsyebski Rayon (excl. Vitsyebsk),ADM2,DISTRICT
SM267034,-50.4794,-20.1935,Vitória Brasil,ADM2,DISTRICT
SM267713,-40.9658,-15.2795,Vitória da Conquista,ADM2,DISTRICT
SM266013,-54.4817,-28.3547,Vitória das Misses,ADM2,DISTRICT
SM264935,-35.2945,-8.1498,Vitória de Santo Antão,ADM2,DISTRICT
SM267295,-52.0344,-0.9565,Vitória do Jari,ADM2,DISTRICT
SM262934,-44.9135,-3.5902,Vitória do Mearim,ADM2,DISTRICT
SM264127,-51.8851,-3.2203,Vitória do Xingu,ADM2,DISTRICT
SM16282,-66.6824,18.2603,Vivi Abajo Barrio,ADM2,DISTRICT
SM16283,-66.6755,18.2191,Vivi Arriba Barrio,ADM2,DISTRICT
SM295344,156.8918,-7.0858,Viviru,ADM2,DISTRICT
SM248026,167.5,-16.28,Vivti,LANGUAGE,LANGUAGE
SM230792,10.58,-1.22,Viya,LANGUAGE,LANGUAGE
SM274055,-2.9167,43.25,Vizcaya,ADM2,DISTRICT
SM298651,27.7745,41.5829,Vize,ADM2,DISTRICT
SM289438,-8.2931,41.3753,Vizela,ADM2,DISTRICT
SM277021,83.3768,18.411,Vizianagaram,ADM2,DISTRICT
SM10542,13.7594,45.3313,Vizinada-Visinada,ADM2,DISTRICT
SM292707,27.7322,45.0203,Viziru,ADM2,DISTRICT
SM265513,-37.965,-5.9911,Viçosa,ADM2,DISTRICT
SM264053,-42.8953,-20.7353,Viçosa,ADM2,DISTRICT
SM265340,-36.321,-9.3602,Viçosa,ADM2,DISTRICT
SM267874,-41.1382,-3.5491,Viçosa do Ceará,ADM2,DISTRICT
SM274529,-6.53,62.363,Viðareiði,ADM2,DISTRICT
SM271029,-83.722,22.6177,Viñales,ADM2,DISTRICT
SM313219,19.3136,43.7217,Višegrad,ADM2:ADM3,DISTRICT
SM305393,18.4576,45.3432,Viškovci,ADM2,DISTRICT
SM305429,14.3842,45.3753,Viškovo,ADM2,DISTRICT
SM304357,105.3922,21.3296,Việt Trì,ADM2,DISTRICT
SM304189,106.09,21.2749,Việt Yên,ADM2,DISTRICT
SM262096,4.5833,50.9167,Vlaams Brabant,ADM1:ADM2,DISTRICT
SM248029,4.3131,50.8021,Vlaamse Gebarentaal,LANGUAGE,LANGUAGE
SM3694,4.5,51,Vlaanderen,ADM1,DISTRICT
SM285705,4.3257,51.9175,Vlaardingen,ADM2,DISTRICT
SM289985,27.0851,44.3448,Vlad Tepes,ADM2,DISTRICT
SM291113,23.0376,44.3611,Vladaia,ADM2,DISTRICT
SM290890,27.3356,47.4376,Vladeni,ADM2,DISTRICT
SM290941,27.8512,44.6065,Vladeni,ADM2,DISTRICT
SM292612,26.5088,47.7222,Vladeni,ADM2,DISTRICT
SM292252,24.3018,45.1357,Vladesti,ADM2,DISTRICT
SM290479,28.0885,45.8199,Vladesti,ADM2,DISTRICT
SM291310,24.9363,45.1434,Vladesti,ADM2,DISTRICT
SM291486,24.4099,44.0011,Vladila,ADM2,DISTRICT
SM3696,40.5,56,Vladimir,ADM1,DISTRICT
SM290552,21.4041,46.1651,Vladimirescu,ADM2,DISTRICT
SM305394,18.5718,45.4602,Vladislavci,ADM2,DISTRICT
SM291487,24.3373,44.2727,Vladuleni,ADM2,DISTRICT
SM290729,25.5763,46.3741,Vlahita,ADM2,DISTRICT
SM313240,18.91,44.1939,Vlasenica,ADM2:ADM3,DISTRICT
SM292614,26.919,47.9399,Vlasinesti,ADM2,DISTRICT
SM248032,18.16,46.09,Vlax Romani,LANGUAGE,LANGUAGE
SM285747,4.9507,53.2532,Vlieland,ADM2,DISTRICT
SM285621,3.6233,51.4591,Vlissingen,ADM2,DISTRICT
SM3697,19.8,40.1667,Vlorë,ADM1,DISTRICT
SM260477,19.55,40.3333,Vlorës,ADM2,DISTRICT
SM296855,1.5,6.4167,Vo,ADM2,DISTRICT
SM303462,64.5381,39.9799,Vobkent,ADM2,DISTRICT
SM296315,14.4925,46.1736,Vodice,ADM1:ADM2,DISTRICT
SM10543,15.7791,43.7607,Vodice (2006-),ADM2,DISTRICT
SM10544,13.8504,44.9619,Vodnjan-Dignano (excl. Grad Vodnjan),ADM2,DISTRICT
SM285894,5.915,50.8712,Voerendaal,ADM2,DISTRICT
SM284573,164.6854,-20.9467,Voh,ADM2,DISTRICT
SM14174,47.0875,-21.6652,Vohibato,ADM2,DISTRICT
SM370966,49.7147,-13.4838,Vohimarina (Iharana),ADM2:ADM3,DISTRICT
SM370989,47.7062,-22.3427,Vohipeno,ADM2:ADM3,DISTRICT
SM292253,24.2998,44.5965,Voicesti,ADM2,DISTRICT
SM292254,23.9761,45.4113,Voineasa,ADM2,DISTRICT
SM291488,24.1553,44.2895,Voineasa,ADM2,DISTRICT
SM292325,27.4332,46.5558,Voinesti,ADM2,DISTRICT
SM290891,27.4215,47.0751,Voinesti,ADM2,DISTRICT
SM290323,25.2607,45.0699,Voinesti,ADM2,DISTRICT
SM14124,-9.8434,8.2562,Voinjama (excl. Quardu Gboni and Bondi),ADM2,DISTRICT
SM292035,21.2698,45.4847,Voiteg,ADM2,DISTRICT
SM290675,23.4467,45.0903,Voitestii De Vale,ADM2,DISTRICT
SM261855,15.1,47.05,Voitsberg,ADM2,DISTRICT
SM291212,24.6043,46.7055,Voivodeni,ADM2,DISTRICT
SM296230,15.3126,46.3171,Vojnik,ADM1:ADM2,DISTRICT
SM305697,15.7,45.3333,Vojnić,ADM2,DISTRICT
SM293351,46.5833,58.9167,Vokhomskiy rayon,ADM2,DISTRICT
SM11099,34.0684,35.3709,Vokolida,ADM2,DISTRICT
SM17138,47.25,52.0833,Vol'skiy Rayon,ADM2,DISTRICT
SM16284,-67.0753,18.3741,Voladoras Barrio,ADM2,DISTRICT
SM293014,80.5,52.0833,Volchikhinskiy rayon,ADM2,DISTRICT
SM4696,6.068,62.1468,Volda (2020-),ADM2,DISTRICT
SM17703,-60.9079,13.8263,Volet,ADM2,DISTRICT
SM3698,44,49,Volgograd,ADM1,DISTRICT
SM17139,32.5,59.75,Volkhovskiy rayon,ADM2,DISTRICT
SM18287,37.5,47.5,Volnovakha Raion (excl. Dokuchaevsk),ADM2,DISTRICT
SM299460,26.2103,49.5366,Volochys'kyi,ADM2,DISTRICT
SM299858,28.5,50.6667,Volodars'ko-Volyns'kyi,ADM2,DISTRICT
SM299967,37.25,47.25,Volodars'kyi,ADM2,DISTRICT
SM17140,43,56.3333,Volodarskiy Rayon (excl. Dzerzhinsk),ADM2,DISTRICT
SM293846,48.75,46.25,Volodarskiy rayon,ADM2,DISTRICT
SM299807,24.3246,50.85,Volodymyr-Volyns'kyi,ADM2,DISTRICT
SM3699,42,60,Vologda,ADM1,DISTRICT
SM17141,39.75,59.1667,Vologodskiy Rayon,ADM2,DISTRICT
SM291114,23.0595,44.6324,Voloiac,ADM2,DISTRICT
SM293725,35.9925,56.0889,Volokolamskiy rayon,ADM2,DISTRICT
SM294692,37.8333,50.5,Volokonovskiy rayon,ADM2,DISTRICT
SM293551,29.4771,59.4211,Volosovskiy rayon,ADM2,DISTRICT
SM293833,30.6667,57.8333,Volotovskiy rayon,ADM2,DISTRICT
SM299758,23.1871,48.714,Volovets'kyi,ADM2,DISTRICT
SM293575,37.9167,52,Volovskiy rayon,ADM2,DISTRICT
SM264061,-42.5736,-21.753,Volta Grande,ADM2,DISTRICT
SM265336,-44.079,-22.5198,Volta Redonda,ADM2,DISTRICT
SM3937,0.5,6.4,Volta Region (2018-),ADM1,DISTRICT
SM290978,26.1844,44.4902,Voluntari,ADM2,DISTRICT
SM300373,-81.1486,29.0635,Volusia,ADM2,DISTRICT
SM3701,24.8667,51.1667,Volyn,ADM1,DISTRICT
SM293624,48.5833,56.0833,Volzhskiy rayon,ADM2,DISTRICT
SM294387,50.0833,53.25,Volzhskiy rayon,ADM2,DISTRICT
SM295331,157.151,-8.2183,Vonavona,ADM2,DISTRICT
SM370975,47.306,-22.6866,Vondrozo,ADM2:ADM3,DISTRICT
SM11100,33.5053,35.2102,Voni,ADM2,DISTRICT
SM248044,8.2077,10.4357,Vono,LANGUAGE,LANGUAGE
SM258457,29.81,1.62,Vonoma,ETHNICITY,ETHNICITY
SM295332,156.6638,-7.8758,Vonunu,ADM2,DISTRICT
SM285709,4.4401,52.1253,Voorschoten,ADM2,DISTRICT
SM285811,6.0999,52.2297,Voorst,ADM2,DISTRICT
SM277747,-15.0162,65.6773,Vopnafjarðarhreppur,ADM2,DISTRICT
SM262612,25.1667,54.1667,Voranava,ADM2,DISTRICT
SM3702,9.9167,47.25,Vorarlberg,ADM1,DISTRICT
SM271727,12.1001,55.0138,Vordingborg (2007-),ADM2,DISTRICT
SM245722,8.2714,10.2254,Vori,LANGUAGE,LANGUAGE
SM274276,23.2341,58.9999,Vormsi,ADM2,DISTRICT
SM292615,26.647,47.96,Vorniceni,ADM2,DISTRICT
SM248052,12.0719,9.7832,Voro,LANGUAGE,LANGUAGE
SM294998,40.9333,50.65,Vorob'evskiy rayon,ADM2,DISTRICT
SM11101,33.6575,34.9872,Voroklini,ADM2,DISTRICT
SM292616,26.6138,47.5786,Vorona,ADM2,DISTRICT
SM3703,40.5,51,Voronezh,ADM1,DISTRICT
SM293799,45.75,56.1667,Vorotynskiy rayon,ADM2,DISTRICT
SM290799,22.6723,46.0367,Vorta,ADM2,DISTRICT
SM11208,26.9742,57.8662,Voru vald,ADM2,DISTRICT
SM305964,69.6223,37.9279,Vose,ADM2,DISTRICT
SM274490,6.4053,48.1916,Vosges,ADM2,DISTRICT
SM294439,46.9025,51.8414,Voskresenskiy rayon,ADM2,DISTRICT
SM293726,38.75,55.3333,Voskresenskiy rayon,ADM2,DISTRICT
SM293800,45.5,56.75,Voskresenskiy rayon,ADM2,DISTRICT
SM290730,25.6528,46.6358,Voslabeni,ADM2,DISTRICT
SM461882,6.4183,60.6278,Voss (2020-),ADM2,DISTRICT
SM13347,-152.3107,-10.0632,Vostok,ADM2,DISTRICT
SM258458,29,59,Votes,ETHNICITY,ETHNICITY
SM11102,34.1927,35.4716,Vothylakas,ADM2,DISTRICT
SM248055,28.6235,59.3802,Votic,LANGUAGE,LANGUAGE
SM258459,-83.38,9.69,Voto,ETHNICITY,ETHNICITY
SM267035,-47.409,-23.5822,Votorantim,ADM2,DISTRICT
SM267036,-50.0127,-20.4801,Votuporanga,ADM2,DISTRICT
SM11103,32.8272,34.8159,Vouni,ADM2,DISTRICT
SM11104,33.3944,35.2619,Vouno,ADM2,DISTRICT
SM289411,-8.1136,40.6894,Vouzela,ADM2,DISTRICT
SM248057,167.2021,-15.8984,Vovo,DIALECT,LANGUAGE
SM294959,40.25,60.5,Vozhegodskiy rayon,ADM2,DISTRICT
SM18288,31.3357,47.5625,Voznesensk Raion,ADM2,DISTRICT
SM293801,42.8333,54.9167,Voznesenskiy rayon,ADM2,DISTRICT
SM13287,70.75,54.3833,Vozvyshenka Audany,ADM2,DISTRICT
SM305527,17.5459,45.6202,Voćin,ADM2,DISTRICT
SM305552,18.6104,45.2749,Vođinci,ADM2,DISTRICT
SM299598,30.585,47.8592,Vradi‹vs'kyi,ADM2,DISTRICT
SM3705,26.9167,45.75,Vrancea,ADM1,DISTRICT
SM292391,26.7097,45.8717,Vrancioaia,ADM2,DISTRICT
SM291311,25.0197,44.8565,Vranesti,ADM2,DISTRICT
SM290062,21.4855,45.028,Vrani,ADM2,DISTRICT
SM296156,21.6833,48.9,Vranov nad Toplou,ADM2,DISTRICT
SM296232,14.9497,46.2351,Vransko,ADM1:ADM2,DISTRICT
SM3707,20.85,41.85,Vrapčište,ADM1,DISTRICT
SM305354,16.4576,46.481,Vratišinec,ADM2,DISTRICT
SM262488,23.6,43.2833,Vratsa,ADM2,DISTRICT
SM3708,23.7667,43.4,Vratsa,ADM1,DISTRICT
SM305553,18.9272,44.9808,Vrbanja,ADM2,DISTRICT
SM305578,17.4187,45.1871,Vrbje,ADM2,DISTRICT
SM305430,14.6752,45.0755,Vrbnik,ADM2,DISTRICT
SM276034,16.4333,45.8667,Vrbovec,ADM2,DISTRICT
SM275978,15.0786,45.3754,Vrbovsko,ADM2,DISTRICT
SM11105,32.6642,34.8945,Vretsia,ADM2,DISTRICT
SM276006,17.3667,43.2,Vrgorac,ADM2,DISTRICT
SM4147,14.2927,45.9614,Vrhnika (2006-),ADM1,DISTRICT
SM305753,15.4229,44.8513,Vrhovine,ADM2,DISTRICT
SM276007,16.3993,43.911,Vrlika,ADM2,DISTRICT
SM305579,18.4074,45.2073,Vrpolje,ADM2,DISTRICT
SM10545,13.6056,45.1507,Vrsar-Orsera (2006-),ADM2,DISTRICT
SM10546,15.2317,44.2637,Vrsi,ADM2,DISTRICT
SM271204,18.0833,49.3667,Vsetín,ADM2,DISTRICT
SM17142,30.5,60.1667,Vsevolozhskiy Rayon,ADM2,DISTRICT
SM18178,32.9025,-14.0061,Vubwi District,ADM2,DISTRICT
SM304742,20.9833,42.8,Vucitrn,ADM2,DISTRICT
SM285960,5.2675,51.6582,Vught,ADM2,DISTRICT
SM261977,29.6785,-4.1538,Vugizo,ADM2,DISTRICT
SM299968,37.2549,47.7752,Vuhledars'ka,ADM2,DISTRICT
SM295378,159.9644,-9.4357,Vuhokesa,ADM2,DISTRICT
SM305395,18.5063,45.4386,Vuka,ADM2,DISTRICT
SM313213,18.2431,45.0086,Vukosavlje,ADM2:ADM3,DISTRICT
SM276020,19,45.3333,Vukovar,ADM2,DISTRICT
SM3709,18.9167,45.1667,Vukovarsko-Srijemska,ADM1,DISTRICT
SM290800,23.2748,45.3799,Vulcan,ADM2,DISTRICT
SM323891,-113.2682,50.4015,Vulcan,ADM2:ADM3,DISTRICT
SM10170,-112.9611,50.4412,Vulcan County,ADM2,DISTRICT
SM290324,25.3822,45.0791,Vulcana-bai,ADM2,DISTRICT
SM295366,160.183,-9.6178,Vulolo,ADM2,DISTRICT
SM291390,23.9272,44.4585,Vulpeni,ADM2,DISTRICT
SM291312,24.9186,44.6021,Vulpesti,ADM2,DISTRICT
SM290145,23.5676,46.956,Vultureni,ADM2,DISTRICT
SM292326,27.5326,46.8025,Vulturesti,ADM2,DISTRICT
SM291391,24.3335,44.7299,Vulturesti,ADM2,DISTRICT
SM292392,27.4228,45.6078,Vulturu,ADM2,DISTRICT
SM261970,30.102,-2.6847,Vumbi,ADM2,DISTRICT
SM248063,10.6498,-2.1767,Vumbu,LANGUAGE,LANGUAGE
SM248064,166.691,-14.8477,Vunapu,LANGUAGE,LANGUAGE
SM248067,37.5847,-3.4253,Vunjo,LANGUAGE,LANGUAGE
SM295379,159.9846,-9.4443,Vura,ADM2,DISTRICT
SM292807,46.9167,55.5,Vurnarskiy rayon,ADM2,DISTRICT
SM291765,24.3432,45.8967,Vurpar,ADM2,DISTRICT
SM248068,167.4514,-13.9057,Vurës,LANGUAGE,LANGUAGE
SM292327,27.9737,46.4457,Vutcani,ADM2,DISTRICT
SM248069,11.8839,6.136,Vute,LANGUAGE,LANGUAGE
SM250798,13.45,5.5,Vute,ETHNICITY,ETHNICITY
SM250798,13.45,5.5,Vute,ETHNICITY,ETHNICITY
SM250798,12,6,Vute,ETHNICITY,ETHNICITY
SM250798,12,6,Vute,ETHNICITY,ETHNICITY
SM296278,15.1522,46.5689,Vuzenica,ADM1:ADM2,DISTRICT
SM248073,34.0757,-9.0068,Vwanji,LANGUAGE,LANGUAGE
SM262045,29.5964,-4.0698,Vyanda,ADM2,DISTRICT
SM17143,51.25,56.3333,Vyatsko-Polyanskiy Rayon,ADM2,DISTRICT
SM293194,135,47.5,Vyazemskiy rayon,ADM2,DISTRICT
SM17144,42.1667,56.25,Vyaznikovskiy Rayon,ADM2,DISTRICT
SM17146,28.75,60.75,Vyborgskiy Rayon (Leningrad),ADM2,DISTRICT
SM17145,30.3662,60.0566,Vyborgskiy Rayon (Sankt Peterburg),ADM2,DISTRICT
SM262678,28.1667,55.75,Vyerkhnyazdvinsk,ADM2,DISTRICT
SM262593,31.25,52.6667,Vyetka,ADM2,DISTRICT
SM295025,34,53.0833,Vygonichskiy rayon,ADM2,DISTRICT
SM17147,42.1656,55.3329,Vyksa Urban Okrug,ADM2,DISTRICT
SM299759,23.0367,48.1413,Vynohradivs'kyi,ADM2,DISTRICT
SM293406,39.75,45.6667,Vyselkovskiy rayon,ADM2,DISTRICT
SM18289,30.4054,50.8144,Vyshgorodskiy Rayon,ADM2,DISTRICT
SM17148,34.5833,57.5833,Vyshnevolotskiy Rayon,ADM2,DISTRICT
SM294640,49.3167,55.9,Vysokogorskiy rayon,ADM2,DISTRICT
SM300034,33.53,47.4892,Vysokopil's'kyi,ADM2,DISTRICT
SM294960,36.5,61,Vytegorskiy rayon,ADM2,DISTRICT
SM11106,33.0212,35.0745,Vyzakia,ADM2,DISTRICT
SM299869,25.1895,48.2483,Vyzhnyts'kyi,ADM2,DISTRICT
SM271211,16.9936,49.2784,Vyškov,ADM2,DISTRICT
SM276154,19.2388,47.7832,Vác,ADM2,DISTRICT
SM274546,-7.1617,62.0827,Vágur,ADM2,DISTRICT
SM274544,-6.8088,61.4676,Vágur,ADM2,DISTRICT
SM276205,18.1221,47.1851,Várpalota,ADM2,DISTRICT
SM265510,-37.0371,-6.8028,Várzea (Paraibo) and Varzea (Rio Grande do Norte) (pre-1997),ADM2,DISTRICT
SM265510,-37.0371,-6.8028,Várzea (Paraibo) and Varzea (Rio Grande do Norte) (pre-1997),ADM2,DISTRICT
SM265510,-35.3684,-6.3515,Várzea (Paraibo) and Varzea (Rio Grande do Norte) (pre-1997),ADM2,DISTRICT
SM265510,-35.3684,-6.3515,Várzea (Paraibo) and Varzea (Rio Grande do Norte) (pre-1997),ADM2,DISTRICT
SM267873,-39.3163,-6.7619,Várzea Alegre,ADM2,DISTRICT
SM264355,-42.9516,-9.3441,Várzea Branca,ADM2,DISTRICT
SM265157,-42.1773,-6.5637,Várzea Grande,ADM2,DISTRICT
SM263126,-56.2439,-15.5658,Várzea Grande,ADM2,DISTRICT
SM267708,-41.2323,-11.0552,Várzea Nova,ADM2,DISTRICT
SM267028,-46.825,-23.2182,Várzea Paulista,ADM2,DISTRICT
SM264045,-44.7404,-17.4602,Várzea da Palma,ADM2,DISTRICT
SM267706,-40.0765,-11.5391,Várzea da Roça,ADM2,DISTRICT
SM267707,-40.3092,-11.5302,Várzea do Poço,ADM2,DISTRICT
SM270971,-84,10.06,Vásquez de Coronado,ADM2,DISTRICT
SM276176,22.3586,48.1798,Vásárosnamény,ADM2,DISTRICT
SM3626,24.1667,45.0833,Vâlcea,ADM1,DISTRICT
SM304603,108.9622,13.6637,Vân Canh,ADM2,DISTRICT
SM304461,104.0229,21.2256,Vân Hồ,ADM2,DISTRICT
SM304421,107.5097,21.0492,Vân Đồn,ADM2,DISTRICT
SM296610,12.2755,58.3983,Vänersborg,ADM2,DISTRICT
SM296523,19.7083,63.9496,Vännäs,ADM2,DISTRICT
SM296487,18.7041,59.3161,Värmdö,ADM2,DISTRICT
SM3640,13.25,59.75,Värmland,ADM1,DISTRICT
SM296644,14.0532,57.1713,Värnamo,ADM2,DISTRICT
SM3645,17.5,65,Västerbotten,ADM1,DISTRICT
SM3646,17.5,63,Västernorrland,ADM1,DISTRICT
SM296656,16.3894,57.8524,Västervik,ADM2,DISTRICT
SM296558,16.5645,59.6491,Västerås,ADM2,DISTRICT
SM3647,16.3333,59.75,Västmanland,ADM1,DISTRICT
SM3648,13,58,Västra Götaland,ADM1,DISTRICT
SM296673,14.8998,56.9453,Växjö,ADM2,DISTRICT
SM286091,14.5623,68.2322,Vågan,ADM2,DISTRICT
SM286116,9.0957,61.8757,Vågå,ADM2,DISTRICT
SM3620,-7.25,62.0833,Vågø,ADM1,DISTRICT
SM286292,10.8655,59.4887,Våler,ADM2,DISTRICT
SM286369,11.8331,60.6785,Våler,ADM2,DISTRICT
SM3638,20.4167,60.25,Vårdö,ADM1:ADM2,DISTRICT
SM296613,12.7689,57.9877,Vårgårda,ADM2,DISTRICT
SM286090,12.6934,67.6637,Værøy,ADM2,DISTRICT
SM270523,-73.7258,6.2327,Vélez,ADM2,DISTRICT
SM295284,-13.813,12.986,Vélingara,ADM2,DISTRICT
SM304505,106.0342,21.7685,Võ Nhai,ADM2,DISTRICT
SM274198,27.0012,57.8355,Võru,ADM2,DISTRICT
SM3704,26.9333,57.7667,Võru,ADM1:ADMX,DISTRICT
SM261835,13.6577,48.0031,Vöcklabruck,ADM2,DISTRICT
SM261802,14.6666,46.5964,Völkermarkt,ADM2,DISTRICT
SM304284,104.1812,22.07,Văn Bàn,ADM2,DISTRICT
SM304591,104.6404,21.5572,Văn Chấn,ADM2,DISTRICT
SM304210,105.9628,20.943,Văn Giang,ADM2,DISTRICT
SM304211,106.0316,20.9755,Văn Lâm,ADM2,DISTRICT
SM304246,106.5888,22.0829,Văn Lãng,ADM2,DISTRICT
SM304247,106.5494,21.8333,Văn Quan,ADM2,DISTRICT
SM304592,104.5667,21.9002,Văn Yên,ADM2,DISTRICT
SM304080,106.485,20.6626,Vĩnh Bảo,ADM2,DISTRICT
SM304450,106.003,9.3623,Vĩnh Châu,ADM2,DISTRICT
SM303999,107.0277,11.2573,Vĩnh Cửu,ADM2,DISTRICT
SM304291,105.8036,10.883,Vĩnh Hưng,ADM2,DISTRICT
SM304439,106.9338,17.016,Vĩnh Linh,ADM2,DISTRICT
SM304575,105.9423,10.2518,Vĩnh Long,ADM2,DISTRICT
SM3684,106,10.1,Vĩnh Long,ADM1,DISTRICT
SM304524,105.6576,20.0348,Vĩnh Lộc,ADM2,DISTRICT
SM304034,105.6336,9.3293,Vĩnh Lợi,ADM2,DISTRICT
SM3685,105.5667,21.3333,Vĩnh Phúc (2008-),ADM1,DISTRICT
SM304226,105.2449,9.5331,Vĩnh Thuận,ADM2,DISTRICT
SM304604,108.7439,14.211,Vĩnh Thạnh,ADM2,DISTRICT
SM303952,105.3803,10.2029,Vĩnh Thạnh,ADM2,DISTRICT
SM304583,105.4968,21.2443,Vĩnh Tường,ADM2,DISTRICT
SM304584,105.5947,21.3054,Vĩnh Yên,ADM2,DISTRICT
SM304172,105.449,18.3391,Vũ Quang,ADM2,DISTRICT
SM304496,106.2653,20.4373,Vũ Thư,ADM2,DISTRICT
SM304576,106.1604,10.0872,Vũng Liêm,ADM2,DISTRICT
SM304600,107.1424,10.4024,Vũng Tàu,ADM2,DISTRICT
SM304219,109.2459,12.7234,Vạn Ninh,ADM2,DISTRICT
SM304095,105.4341,9.7418,Vị Thanh,ADM2,DISTRICT
SM304096,105.5628,9.8227,Vị Thuỷ,ADM2,DISTRICT
SM304131,104.9083,22.731,Vị Xuyên,ADM2,DISTRICT
SM304316,106.0971,20.3771,Vụ Bản,ADM2,DISTRICT
SM458091,127.1081,-26.3373,WESTERN DESERT LANGUAGE,ETHNICITY,ETHNICITY
SM459210,146.6434,-18.865,WULGURUKABA,ETHNICITY,ETHNICITY
SM274955,-2.4833,10.05,Wa,ADM2,DISTRICT
SM258464,98.95,20.25,Wa,ETHNICITY,ETHNICITY
SM274956,-1.8685,9.9293,Wa East,ADM2,DISTRICT
SM274957,-2.631,9.9656,Wa West,ADM2,DISTRICT
SM248112,150.398,-10.2897,Wa'ema,LANGUAGE,LANGUAGE
SM242576,-69.5032,0.5071,Wa'ikhana,LANGUAGE,LANGUAGE
SM15179,5.5079,53.1895,Waadhoeke,ADM2,DISTRICT
SM258465,135.86,-21.39,Waagai,ETHNICITY,ETHNICITY
SM258465,135.86,-21.39,Waagai,ETHNICITY,ETHNICITY
SM258465,137.85,-20.08,Waagai,ETHNICITY,ETHNICITY
SM258465,137.85,-20.08,Waagai,ETHNICITY,ETHNICITY
SM258465,136.1691,-20.1529,Waagai,ETHNICITY,ETHNICITY
SM258465,136.1691,-20.1529,Waagai,ETHNICITY,ETHNICITY
SM285961,5.4638,51.3876,Waalre,ADM2,DISTRICT
SM285962,5.0172,51.6897,Waalwijk,ADM2,DISTRICT
SM248081,1.6532,10.5956,Waama,LANGUAGE,LANGUAGE
SM248080,164.409,-20.5014,Waamwang,LANGUAGE,LANGUAGE
SM458480,137.6209,-18.3132,Waanyi,ETHNICITY,ETHNICITY
SM248084,37.8568,0.5566,Waata,LANGUAGE,LANGUAGE
SM250799,37,3.5,Waata,ETHNICITY,ETHNICITY
SM248086,146.461,-5.6122,Wab,LANGUAGE,LANGUAGE
SM288643,143.65,-5.3333,Wabag,ADM2,DISTRICT
SM258466,143.65,-6.16,Wabaga,ETHNICITY,ETHNICITY
SM324032,-114.4777,53.5616,Wabamun,ADM2:ADM3,DISTRICT
SM300685,-87.8443,38.4461,Wabash,ADM2,DISTRICT
SM300775,-85.794,40.8457,Wabash,ADM2,DISTRICT
SM301416,-92.2303,44.2843,Wabasha,ADM2,DISTRICT
SM300987,-96.205,38.9533,Wabaunsee,ADM2,DISTRICT
SM248087,136.757,-1.773,Wabo,LANGUAGE,LANGUAGE
SM258467,137.98,-1.83,Wabo,ETHNICITY,ETHNICITY
SM248088,143.501,-8.4165,Waboda,LANGUAGE,LANGUAGE
SM258468,-78.59,33.46,Waccamaw,ETHNICITY,ETHNICITY
SM297358,100.1167,16.5167,Wachira Barami,ADM2,DISTRICT
SM250961,1.08,7.02,Waci,ETHNICITY,ETHNICITY
SM248091,1.693,6.4557,Waci Gbe,LANGUAGE,LANGUAGE
SM458892,132.5637,-12.4699,Wada,ETHNICITY,ETHNICITY
SM258469,-119,43,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119,43,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119,43,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119,43,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119.14,43.25,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119.14,43.25,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119.14,43.25,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119.14,43.25,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119,43.3,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119,43.3,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119,43.3,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119,43.3,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119,43.5,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119,43.5,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119,43.5,Wadadokado,ETHNICITY,ETHNICITY
SM258469,-119,43.5,Wadadokado,ETHNICITY,ETHNICITY
SM258470,-116,41,Wadaduka,ETHNICITY,ETHNICITY
SM258470,-116,41,Wadaduka,ETHNICITY,ETHNICITY
SM258470,-116,41,Wadaduka,ETHNICITY,ETHNICITY
SM258470,-115.39,40.36,Wadaduka,ETHNICITY,ETHNICITY
SM258470,-115.39,40.36,Wadaduka,ETHNICITY,ETHNICITY
SM258470,-115.39,40.36,Wadaduka,ETHNICITY,ETHNICITY
SM258470,-115.2,40.8,Wadaduka,ETHNICITY,ETHNICITY
SM258470,-115.2,40.8,Wadaduka,ETHNICITY,ETHNICITY
SM258470,-115.2,40.8,Wadaduka,ETHNICITY,ETHNICITY
SM248099,144.972,-4.5275,Wadaginam,LANGUAGE,LANGUAGE
SM258471,131,-15.13,Wadaman,ETHNICITY,ETHNICITY
SM258471,131,-15.13,Wadaman,ETHNICITY,ETHNICITY
SM258471,131.527,-15.3569,Wadaman,ETHNICITY,ETHNICITY
SM258471,131.527,-15.3569,Wadaman,ETHNICITY,ETHNICITY
SM258472,116.2,-33.59,Wadandi,ETHNICITY,ETHNICITY
SM258472,116.2,-33.59,Wadandi,ETHNICITY,ETHNICITY
SM258472,115.4379,-33.8569,Wadandi,ETHNICITY,ETHNICITY
SM258472,115.4379,-33.8569,Wadandi,ETHNICITY,ETHNICITY
SM258474,-120,41,Wadatkuht,ETHNICITY,ETHNICITY
SM258474,-120,41,Wadatkuht,ETHNICITY,ETHNICITY
SM258474,-120.44,40.27,Wadatkuht,ETHNICITY,ETHNICITY
SM258474,-120.44,40.27,Wadatkuht,ETHNICITY,ETHNICITY
SM459048,143.9212,-37.8382,Wadawurrung,ETHNICITY,ETHNICITY
SM248100,77.1673,19.8838,Waddar,LANGUAGE,LANGUAGE
SM285710,4.6449,52.0425,Waddinxveen,ADM2,DISTRICT
SM301188,-164.5532,61.9906,Wade Hampton,ADM2,DISTRICT
SM301417,-94.9694,46.5858,Wadena,ADM2,DISTRICT
SM258476,136.63,-16.2,Waderi,ETHNICITY,ETHNICITY
SM258476,136.63,-16.2,Waderi,ETHNICITY,ETHNICITY
SM258476,136.8079,-15.8021,Waderi,ETHNICITY,ETHNICITY
SM258476,136.8079,-15.8021,Waderi,ETHNICITY,ETHNICITY
SM304856,43.4667,15.7142,Wadhrah,ADM2,DISTRICT
SM304823,48.1475,15.7403,Wadi Al Ayn,ADM2,DISTRICT
SM3712,21.5,15,Wadi Fira,ADM1,DISTRICT
SM278018,35.4673,30.2757,Wadi Musa,ADM2,DISTRICT
SM17823,44.4333,21,Wadi ad Dawasir,ADM2,DISTRICT
SM3710,12.7,26.3167,Wadi al Hayat,ADM1,DISTRICT
SM3711,12.7333,27.8,Wadi ash Shati',ADM1,DISTRICT
SM10348,22.9302,15.5873,Wadi-Hawar,ADM2,DISTRICT
SM458668,141.2096,-29.2608,Wadigali,ETHNICITY,ETHNICITY
SM248101,72.1312,26.0713,Wadiyara Koli,LANGUAGE,LANGUAGE
SM458682,145.8108,-24.1056,Wadjabangai,ETHNICITY,ETHNICITY
SM258479,116.92,-24.61,Wadjari,ETHNICITY,ETHNICITY
SM258479,116.92,-24.61,Wadjari,ETHNICITY,ETHNICITY
SM258479,116.9931,-26.1002,Wadjari,ETHNICITY,ETHNICITY
SM258479,116.9931,-26.1002,Wadjari,ETHNICITY,ETHNICITY
SM248109,130.737,-12.8596,Wadjiginy,LANGUAGE,LANGUAGE
SM248110,147.421,-23.8029,Wadjigu,LANGUAGE,LANGUAGE
SM458415,148.5734,-24.8085,Wadjigu,ETHNICITY,ETHNICITY
SM288942,19.4837,49.8903,Wadowice,ADM2,DISTRICT
SM297250,101.8619,5.9004,Waeng,ADM2,DISTRICT
SM296991,102.4236,15.8063,Waeng Noi,ADM2,DISTRICT
SM296992,102.4622,15.9253,Waeng Yai,ADM2,DISTRICT
SM248116,146.09,-6.5578,Waffa,LANGUAGE,LANGUAGE
SM274342,38.8481,12.7005,Wag Himra,ADM2,DISTRICT
SM274342,38.8481,12.7005,Wag Himra,ADM2,DISTRICT
SM274342,38.843,12.765,Wag Himra,ADM2,DISTRICT
SM274342,38.843,12.765,Wag Himra,ADM2,DISTRICT
SM261599,130.7537,-12.4416,Wagait,ADM2,DISTRICT
SM258481,144.9,-17.99,Wagamen,ETHNICITY,ETHNICITY
SM258481,144.9,-17.99,Wagamen,ETHNICITY,ETHNICITY
SM258481,144.1668,-17.6757,Wagamen,ETHNICITY,ETHNICITY
SM258481,144.1668,-17.6757,Wagamen,ETHNICITY,ETHNICITY
SM248123,150.3781,-10.4179,Wagawaga,LANGUAGE,LANGUAGE
SM248118,137.623,-20.329,Wagaya,LANGUAGE,LANGUAGE
SM248124,73.9022,23.6888,Wagdi,LANGUAGE,LANGUAGE
SM248125,131.893,-13.8627,Wageman,LANGUAGE,LANGUAGE
SM296082,-56.6887,5.7707,Wageningen,ADM2,DISTRICT
SM285814,5.6668,51.9688,Wageningen,ADM2,DISTRICT
SM258483,27.22,-1.66,Wagera,ETHNICITY,ETHNICITY
SM261479,147.3551,-35.1859,Wagga Wagga,ADM2,DISTRICT
SM258484,149.93,-28.1,Waggumbura,ETHNICITY,ETHNICITY
SM258484,149.93,-28.1,Waggumbura,ETHNICITY,ETHNICITY
SM258484,147.7249,-28.4146,Waggumbura,ETHNICITY,ETHNICITY
SM258484,147.7249,-28.4146,Waggumbura,ETHNICITY,ETHNICITY
SM9596,68.212,33.4091,Waghaz,ADM2,DISTRICT
SM295345,157.7665,-7.4341,Waghina,ADM2,DISTRICT
SM248129,145.726,-5.1783,Wagi (Papua New Guinea),LANGUAGE,LANGUAGE
SM261325,117.3855,-33.275,Wagin,ADM2,DISTRICT
SM267714,-41.221,-12.2593,Wagner,ADM2,DISTRICT
SM302242,-95.5212,35.9611,Wagoner,ADM2,DISTRICT
SM248130,142.7268,-4.3804,Wagu,LANGUAGE,LANGUAGE
SM459241,144.9246,-15.1133,Waguurrga,ETHNICITY,ETHNICITY
SM250724,39.43,-6.07,Wahadimu (Zanzibar),ETHNICITY,ETHNICITY
SM248133,116.6795,1.4209,Wahau Kayan,LANGUAGE,LANGUAGE
SM241021,144.665,-6.1163,Wahgi,LANGUAGE,LANGUAGE
SM303097,-123.4332,46.2913,Wahkiakum,ADM2,DISTRICT
SM370445,5.9064,49.8367,Wahl,ADM1:ADM2:ADM3,DISTRICT
SM458198,152.5079,-28.8876,Wahlubal,ETHNICITY,ETHNICITY
SM258499,-58.99,1.02,Wai-wai,ETHNICITY,ETHNICITY
SM258499,-58.99,1.02,Wai-wai,ETHNICITY,ETHNICITY
SM258499,-59,1,Wai-wai,ETHNICITY,ETHNICITY
SM258499,-59,1,Wai-wai,ETHNICITY,ETHNICITY
SM258490,-111.19,24.88,Waicuri,ETHNICITY,ETHNICITY
SM258490,-111.19,24.88,Waicuri,ETHNICITY,ETHNICITY
SM258490,-111.54,25,Waicuri,ETHNICITY,ETHNICITY
SM258490,-111.54,25,Waicuri,ETHNICITY,ETHNICITY
SM261815,15.3581,48.8593,Waidhofen an der Thaya,ADM2,DISTRICT
SM261816,14.7744,47.96,Waidhofen an der Ybbs,ADM2,DISTRICT
SM248144,70.9761,35.0358,Waigali,LANGUAGE,LANGUAGE
SM248144,70.9761,35.0358,Waigali,LANGUAGE,LANGUAGE
SM248144,70.9935,35.1912,Waigali,LANGUAGE,LANGUAGE
SM248144,70.9935,35.1912,Waigali,LANGUAGE,LANGUAGE
SM248145,130.917,-0.1796,Waigeo,LANGUAGE,LANGUAGE
SM258492,130.92,-0.18,Waigeo,ETHNICITY,ETHNICITY
SM258493,143.95,-7.35,Waiiemi,ETHNICITY,ETHNICITY
SM286516,175.101,-37.5158,Waikato,ADM2,DISTRICT
SM3713,175.3333,-37.5,Waikato,ADM1,DISTRICT
SM258494,-122.16,39.05,Wailaki,ETHNICITY,ETHNICITY
SM258495,131.91,-20.45,Wailbri,ETHNICITY,ETHNICITY
SM258495,131.91,-20.45,Wailbri,ETHNICITY,ETHNICITY
SM258495,131.91,-20.45,Wailbri,ETHNICITY,ETHNICITY
SM258495,131.91,-20.45,Wailbri,ETHNICITY,ETHNICITY
SM258495,132,-22,Wailbri,ETHNICITY,ETHNICITY
SM258495,132,-22,Wailbri,ETHNICITY,ETHNICITY
SM258495,132,-22,Wailbri,ETHNICITY,ETHNICITY
SM258495,132,-22,Wailbri,ETHNICITY,ETHNICITY
SM258495,130.57,-20,Wailbri,ETHNICITY,ETHNICITY
SM258495,130.57,-20,Wailbri,ETHNICITY,ETHNICITY
SM258495,130.57,-20,Wailbri,ETHNICITY,ETHNICITY
SM258495,130.57,-20,Wailbri,ETHNICITY,ETHNICITY
SM258495,131.0524,-20.4336,Wailbri,ETHNICITY,ETHNICITY
SM258495,131.0524,-20.4336,Wailbri,ETHNICITY,ETHNICITY
SM258495,131.0524,-20.4336,Wailbri,ETHNICITY,ETHNICITY
SM258495,131.0524,-20.4336,Wailbri,ETHNICITY,ETHNICITY
SM458696,138.583,-30.9484,Wailpi,ETHNICITY,ETHNICITY
SM248155,146.544,-8.6715,Waima,LANGUAGE,LANGUAGE
SM248156,126.317,-8.5342,Waima'a,LANGUAGE,LANGUAGE
SM248159,-70.1644,0.3042,Waimaha,LANGUAGE,LANGUAGE
SM286544,172.3329,-43.2075,Waimakariri,ADM2,DISTRICT
SM286545,171.0333,-44.75,Waimate,ADM2,DISTRICT
SM248157,-60.4259,-0.6183,Waimiri-Atroari,LANGUAGE,LANGUAGE
SM258496,-60.48,2.01,Waimiri-Atroari,ETHNICITY,ETHNICITY
SM295412,162.1268,-10.5924,Wainoni East,ADM2,DISTRICT
SM295413,162.0699,-10.5815,Wainoni West,ADM2,DISTRICT
SM323947,-110.8509,52.839,Wainwright,ADM2:ADM3,DISTRICT
SM323948,-110.7058,52.8433,Wainwright No. 61,ADM2:ADM3,DISTRICT
SM248162,127.494,1.2341,Waioli,LANGUAGE,LANGUAGE
SM286517,175.3582,-37.9838,Waipa,ADM2,DISTRICT
SM324116,-114.8407,51.2838,Waiparous,ADM2:ADM3,DISTRICT
SM258498,-69.33,-5.45,Wairacu,ETHNICITY,ETHNICITY
SM286551,177.4333,-39.0333,Wairoa,ADM2,DISTRICT
SM12810,-77.5084,18.2533,Wait-A-Bit,ADM2,DISTRICT
SM286537,170.5545,-44.9599,Waitaki,ADM2,DISTRICT
SM286509,175,-38.5,Waitomo,ADM2,DISTRICT
SM248163,-59.1417,1.5088,Waiwai,LANGUAGE,LANGUAGE
SM258500,-54.39,3.16,Waiyarikule,ETHNICITY,ETHNICITY
SM248168,11.7206,9.9118,Waja,LANGUAGE,LANGUAGE
SM248166,117.153,-28.1761,Wajarri,LANGUAGE,LANGUAGE
SM295808,43.4329,3.8165,Wajid,ADM2,DISTRICT
SM278422,136.8876,37.3375,Wajima,ADM2,DISTRICT
SM3714,40.0167,1.75,Wajir,ADM1:ADM2,DISTRICT
SM276586,120.1667,-4,Wajo,ADM2,DISTRICT
SM248174,11.4932,8.7997,Waka,LANGUAGE,LANGUAGE
SM258502,142.63,-6.51,Waka,ETHNICITY,ETHNICITY
SM458471,139.0648,-19.5389,Wakabunga,ETHNICITY,ETHNICITY
SM248181,139.0591,-20.8214,Wakabunga,LANGUAGE,LANGUAGE
SM459308,144.5984,-17.2105,Wakaman,ETHNICITY,ETHNICITY
SM279652,135.88,35.5118,Wakasa,ADM2,DISTRICT
SM276608,123.5948,-5.3193,Wakatobi,ADM2,DISTRICT
SM248173,151.868,-26.248,Wakawaka,LANGUAGE,LANGUAGE
SM279531,135.196,34.2425,Wakayama,ADM2,DISTRICT
SM3715,135.4167,33.9167,Wakayama,ADM1:ADMX,DISTRICT
SM248185,139.008,-1.9846,Wakde,LANGUAGE,LANGUAGE
SM302022,-78.6502,35.7901,Wake,ADM2,DISTRICT
SM279041,134.1379,34.8387,Wake,ADM2,DISTRICT
SM3716,166.6483,19.2801,Wake,ADM0:ADM1,DISTRICT
SM274591,-1.499,53.6809,Wakefield,ADM1:ADM2,DISTRICT
SM12811,-77.7091,18.4146,Wakefield,ADM2,DISTRICT
SM261743,138.3946,-34.0074,Wakefield,ADM2,DISTRICT
SM258504,149.21,-23.88,Wakelbara,ETHNICITY,ETHNICITY
SM259979,73.3493,37.0571,Wakhan,ADM2,DISTRICT
SM248186,73.9777,37.1768,Wakhi,LANGUAGE,LANGUAGE
SM279585,132.2127,34.2013,Waki,ADM2,DISTRICT
SM458111,133.0945,-20.6721,Wakirti Warlpiri,ETHNICITY,ETHNICITY
SM3717,32.4784,0.2376,Wakiso,ADM1:ADM2,DISTRICT
SM278229,141.8283,45.3324,Wakkanai,ADM2,DISTRICT
SM248188,-36.5,-9.9167,Wakoná,LANGUAGE,LANGUAGE
SM260441,15.3168,-11.2873,Waku Kungo,ADM2,DISTRICT
SM300374,-84.3756,30.1478,Wakulla,ADM2,DISTRICT
SM279212,139.6199,35.7893,Wakō,ADM2,DISTRICT
SM248193,160.741,-8.8194,Wala,LANGUAGE,LANGUAGE
SM248199,127.6168,-15.6974,Waladja,DIALECT,LANGUAGE
SM248196,138.84,-3.8238,Walak,LANGUAGE,LANGUAGE
SM248190,142.4891,-17.6024,Walangama,LANGUAGE,LANGUAGE
SM458492,141.9347,-17.8857,Walangama,ETHNICITY,ETHNICITY
SM258507,-114.06,35.74,Walapai,ETHNICITY,ETHNICITY
SM258507,-114.06,35.74,Walapai,ETHNICITY,ETHNICITY
SM258507,-114.06,35.74,Walapai,ETHNICITY,ETHNICITY
SM258507,-114.06,35.74,Walapai,ETHNICITY,ETHNICITY
SM258507,-114,36,Walapai,ETHNICITY,ETHNICITY
SM258507,-114,36,Walapai,ETHNICITY,ETHNICITY
SM258507,-114,36,Walapai,ETHNICITY,ETHNICITY
SM258507,-114,36,Walapai,ETHNICITY,ETHNICITY
SM258507,-114.5,35.95,Walapai,ETHNICITY,ETHNICITY
SM258507,-114.5,35.95,Walapai,ETHNICITY,ETHNICITY
SM258507,-114.5,35.95,Walapai,ETHNICITY,ETHNICITY
SM258507,-114.5,35.95,Walapai,ETHNICITY,ETHNICITY
SM258507,-114,35.3,Walapai,ETHNICITY,ETHNICITY
SM258507,-114,35.3,Walapai,ETHNICITY,ETHNICITY
SM258507,-114,35.3,Walapai,ETHNICITY,ETHNICITY
SM258507,-114,35.3,Walapai,ETHNICITY,ETHNICITY
SM15393,16.2756,50.7688,Walbrzych city,ADM2,DISTRICT
SM459071,149.8854,-35.8239,Walbunja,ETHNICITY,ETHNICITY
SM261481,151.8185,-31.1976,Walcha,ADM2,DISTRICT
SM305013,44.906,14.5557,Wald Rabi',ADM2,DISTRICT
SM370474,6.2867,49.7889,Waldbillig,ADM1:ADM2:ADM3,DISTRICT
SM370492,6.2875,49.555,Waldbredimus,ADM1:ADM2:ADM3,DISTRICT
SM269026,7.7414,47.3937,Waldenburg,ADM2,DISTRICT
SM12812,-77.4861,18.1164,Walderston,ADM2,DISTRICT
SM301203,-69.1219,44.4853,Waldo,ADM2,DISTRICT
SM3718,-3.5,52.5,Wales,ADM1,DISTRICT
SM370519,6.1372,49.6583,Walferdange,ADM1:ADM2:ADM3,DISTRICT
SM261482,148.1339,-29.7903,Walgett,ADM2,DISTRICT
SM250800,-1.01,10.71,Wali,ETHNICITY,ETHNICITY
SM248207,-2.3126,10.0212,Wali (Ghana),LANGUAGE,LANGUAGE
SM248206,29.1228,11.8634,Wali (Sudan),LANGUAGE,LANGUAGE
SM9597,68.2989,33.5591,Wali Muhammad Shahid Khugyani,ADM2,DISTRICT
SM269652,28.0333,-1.4167,Walikale,ADM2,DISTRICT
SM248205,86.4837,26.6369,Waling,LANGUAGE,LANGUAGE
SM240866,142.231,-4.4123,Walio,LANGUAGE,LANGUAGE
SM458025,122.723,-29.2312,Waljen,ETHNICITY,ETHNICITY
SM302791,-95.5723,30.7391,Walker,ADM2,DISTRICT
SM300432,-85.301,34.7357,Walker,ADM2,DISTRICT
SM300306,-87.2974,33.8033,Walker,ADM2,DISTRICT
SM12813,-77.0708,18.3194,Walkers Wood,ADM2,DISTRICT
SM261744,138.6163,-34.8944,Walkerville,ADM2,DISTRICT
SM458614,127.063,-17.8461,Walki,ETHNICITY,ETHNICITY
SM265161,-41.8373,-7.2953,Wall Ferraz,ADM2,DISTRICT
SM303098,-118.4785,46.2298,Walla Walla,ADM2,DISTRICT
SM258508,-118.85,46.82,Walla Walla,ETHNICITY,ETHNICITY
SM300885,-101.7636,38.9167,Wallace,ADM2,DISTRICT
SM10198,-101.2006,49.9784,Wallace-Woodworth,ADM2,DISTRICT
SM302792,-95.9877,30.0108,Waller,ADM2,DISTRICT
SM258509,-115.46,64.73,Wallirmiut,ETHNICITY,ETHNICITY
SM258509,-126.17,66.58,Wallirmiut,ETHNICITY,ETHNICITY
SM3719,4.75,50.5,Wallonie,ADM1,DISTRICT
SM248213,5.1451,50.2235,Walloon,LANGUAGE,LANGUAGE
SM258510,4.68,51.73,Walloon,ETHNICITY,ETHNICITY
SM258510,4.68,51.73,Walloon,ETHNICITY,ETHNICITY
SM258510,5,50,Walloon,ETHNICITY,ETHNICITY
SM258510,5,50,Walloon,ETHNICITY,ETHNICITY
SM302274,-117.1811,45.5798,Wallowa,ADM2,DISTRICT
SM258511,126.96,-19.64,Walmadje,ETHNICITY,ETHNICITY
SM258511,126.96,-19.64,Walmadje,ETHNICITY,ETHNICITY
SM258511,126.1513,-19.4674,Walmadje,ETHNICITY,ETHNICITY
SM258511,126.1513,-19.4674,Walmadje,ETHNICITY,ETHNICITY
SM248220,126.113,-19.7229,Walmajarri,LANGUAGE,LANGUAGE
SM459223,145.9956,-18.059,Walmalbarra,ETHNICITY,ETHNICITY
SM247892,142.499,-3.2496,Walman,LANGUAGE,LANGUAGE
SM258512,144.22,-14.34,Walmbaria,ETHNICITY,ETHNICITY
SM258512,144.22,-14.34,Walmbaria,ETHNICITY,ETHNICITY
SM258512,144.3889,-14.2756,Walmbaria,ETHNICITY,ETHNICITY
SM258512,144.3889,-14.2756,Walmbaria,ETHNICITY,ETHNICITY
SM274592,-2,52.5833,Walsall,ADM1:ADM2,DISTRICT
SM248223,9.1827,46.6995,Walser,LANGUAGE,LANGUAGE
SM302075,-97.7214,48.3695,Walsh,ADM2,DISTRICT
SM301497,-90.1061,31.1484,Walthall,ADM2,DISTRICT
SM12814,-76.8205,18.0109,Waltham Gardens,ADM2,DISTRICT
SM300433,-83.7339,33.7816,Walton,ADM2,DISTRICT
SM300375,-86.1715,30.6185,Walton,ADM2,DISTRICT
SM458795,137.0277,-15.7212,Walu,ETHNICITY,ETHNICITY
SM248224,87.2374,27.695,Walungge,LANGUAGE,LANGUAGE
SM284498,15.1122,-23.3298,Walvisbay Rural,ADM2,DISTRICT
SM284499,14.5014,-22.9601,Walvisbay Urban,ADM2,DISTRICT
SM303284,-88.5419,42.6685,Walworth,ADM2,DISTRICT
SM302538,-100.0316,45.43,Walworth,ADM2,DISTRICT
SM458084,121.8962,-20.0585,Walyirli,ETHNICITY,ETHNICITY
SM260144,70.7956,35.1823,Wama,ADM2,DISTRICT
SM258513,-54.58,3.85,Wama,ETHNICITY,ETHNICITY
SM258513,-54.58,3.85,Wama,ETHNICITY,ETHNICITY
SM258513,-55,3,Wama,ETHNICITY,ETHNICITY
SM258513,-55,3,Wama,ETHNICITY,ETHNICITY
SM285232,5.1195,13.0399,Wamakko,ADM2,DISTRICT
SM248226,145.541,-4.9776,Wamas,LANGUAGE,LANGUAGE
SM285013,8.6946,8.9821,Wamba,ADM2,DISTRICT
SM458113,135.4311,-18.1731,Wambaya,ETHNICITY,ETHNICITY
SM231797,134.73,-17.42,Wambaya-Gudanji,DIALECT,LANGUAGE
SM248230,135.683,-17.496,Wambayan,LANGUAGE,LANGUAGE
SM248231,140.48,-5.4433,Wambon,LANGUAGE,LANGUAGE
SM248229,86.4765,27.1481,Wambule,LANGUAGE,LANGUAGE
SM248233,-13.177,12.5421,Wamey,LANGUAGE,LANGUAGE
SM258514,-13.72,13.12,Wamey,ETHNICITY,ETHNICITY
SM258514,-13.72,13.12,Wamey,ETHNICITY,ETHNICITY
SM258514,-13,13,Wamey,ETHNICITY,ETHNICITY
SM258514,-13,13,Wamey,ETHNICITY,ETHNICITY
SM248235,143.943,-18.3727,Wamin,LANGUAGE,LANGUAGE
SM459208,144.1412,-18.7814,Wamin,ETHNICITY,ETHNICITY
SM248240,-73.3798,41.2021,Wampano,LANGUAGE,LANGUAGE
SM248239,-73.3148,41.705,Wampanoag,LANGUAGE,LANGUAGE
SM258515,-70.89,41.85,Wampanoag,ETHNICITY,ETHNICITY
SM248237,146.662,-6.6736,Wampar,LANGUAGE,LANGUAGE
SM248238,146.126,-6.3364,Wampur,LANGUAGE,LANGUAGE
SM275947,-84.6167,15.1833,Wampusirpi,ADM2,DISTRICT
SM248293,-5.8209,7.8877,Wan,LANGUAGE,LANGUAGE
SM3720,114.1723,22.277,Wan Chai,ADM1,DISTRICT
SM297088,104.7287,16.7203,Wan Yai,ADM2,DISTRICT
SM284522,17.0369,-22.5186,Wanaheda,ADM2,DISTRICT
SM259218,139.108,-7.451,Wanam Yelmek,LANGUAGE,LANGUAGE
SM258516,140.63,-21.27,Wanamara,ETHNICITY,ETHNICITY
SM258516,140.63,-21.27,Wanamara,ETHNICITY,ETHNICITY
SM258516,141.8533,-20.9131,Wanamara,ETHNICITY,ETHNICITY
SM258516,141.8533,-20.9131,Wanamara,ETHNICITY,ETHNICITY
SM248247,145.419,-4.7882,Wanambre,LANGUAGE,LANGUAGE
SM248246,142.5,-3.5964,Wanap,LANGUAGE,LANGUAGE
SM258517,-117.22,47.46,Wanapam,ETHNICITY,ETHNICITY
SM11697,78.0642,16.3602,Wanaparthy,ADM2,DISTRICT
SM459080,150.9248,-32.2396,Wanarruwa,ETHNICITY,ETHNICITY
SM248250,94.8936,26.2581,Wancho Naga,LANGUAGE,LANGUAGE
SM248254,32.4375,-8.3452,Wanda,LANGUAGE,LANGUAGE
SM258518,32.98,-8.68,Wanda,ETHNICITY,ETHNICITY
SM248260,13.8996,11.3169,Wandala,LANGUAGE,LANGUAGE
SM258519,14.59,11.67,Wandala,ETHNICITY,ETHNICITY
SM258519,14.59,11.67,Wandala,ETHNICITY,ETHNICITY
SM258519,14,11.3,Wandala,ETHNICITY,ETHNICITY
SM258519,14,11.3,Wandala,ETHNICITY,ETHNICITY
SM248257,133.996,-2.2562,Wandamen,LANGUAGE,LANGUAGE
SM458864,132.9782,-12.3127,Wandangbag,ETHNICITY,ETHNICITY
SM248253,135.705,-14.2996,Wandarang,LANGUAGE,LANGUAGE
SM295367,159.7872,-9.7076,Wanderer Bay,ADM2,DISTRICT
SM261326,116.5667,-32.5259,Wandering,ADM2,DISTRICT
SM10043,-43.9938,-11.765,Wanderley,ADM2:ADM3,DISTRICT
SM267192,-48.0042,-6.798,Wanderlândia,ADM2,DISTRICT
SM248256,12.8951,-1.5195,Wandji,LANGUAGE,LANGUAGE
SM258521,128.5,-17.84,Wandjira,ETHNICITY,ETHNICITY
SM258521,128.5,-17.84,Wandjira,ETHNICITY,ETHNICITY
SM258521,129.7059,-18.1059,Wandjira,ETHNICITY,ETHNICITY
SM258521,129.7059,-18.1059,Wandjira,ETHNICITY,ETHNICITY
SM280598,126.7385,34.3118,Wando,ADM2,DISTRICT
SM295448,161.0412,-8.9589,Waneagu-Taelanasin,ADM2,DISTRICT
SM248264,67.8327,30.0501,Waneci,LANGUAGE,LANGUAGE
SM258523,131.38,-18.72,Waneiga,ETHNICITY,ETHNICITY
SM258523,131.38,-18.72,Waneiga,ETHNICITY,ETHNICITY
SM258523,124.8034,-28.6837,Waneiga,ETHNICITY,ETHNICITY
SM258523,124.8034,-28.6837,Waneiga,ETHNICITY,ETHNICITY
SM297463,101.5153,12.9625,Wang Chan,ADM2,DISTRICT
SM297399,99.6499,17.8667,Wang Chin,ADM2,DISTRICT
SM297567,104.2193,14.9613,Wang Hin,ADM2,DISTRICT
SM297537,101.1335,14.8229,Wang Muang,ADM2,DISTRICT
SM297491,102.0872,13.519,Wang Nam Yen,ADM2,DISTRICT
SM297383,100.725,14.2435,Wang Noi,ADM2,DISTRICT
SM297013,99.6477,19.1601,Wang Nua,ADM2,DISTRICT
SM297137,101.8553,14.4479,Wang Num Khiaw,ADM2,DISTRICT
SM297338,100.8077,16.3505,Wang Pong,ADM2,DISTRICT
SM297359,100.5367,16.3792,Wang Sai Phun,ADM2,DISTRICT
SM297725,103.4626,17.046,Wang Sam Mo,ADM2,DISTRICT
SM297042,101.7334,17.2803,Wang Saphung,ADM2,DISTRICT
SM297375,100.5833,16.85,Wang Thong,ADM2,DISTRICT
SM297186,100.608,13.7819,Wang Thonglang,ADM2,DISTRICT
SM297680,99.4086,7.7606,Wang Wiset,ADM2,DISTRICT
SM248275,34.477,0.343,Wanga,LANGUAGE,LANGUAGE
SM458147,145.8191,-31.4133,Wangaaypuwan,ETHNICITY,ETHNICITY
SM248283,34.497,-9.1737,Wangama,DIALECT,LANGUAGE
SM458420,147.6086,-22.7972,Wangan,ETHNICITY,ETHNICITY
SM258524,-3.44,8.86,Wangara,ETHNICITY,ETHNICITY
SM261283,146.4284,-36.616,Wangaratta,ADM2,DISTRICT
SM3721,90.1333,27.5,Wangduephodrang,ADM1,DISTRICT
SM248270,137.433,-24.7022,Wanggamala,LANGUAGE,LANGUAGE
SM248271,135.941,-25.8148,Wangganguru,LANGUAGE,LANGUAGE
SM248280,140.194,-5.6009,Wanggom,LANGUAGE,LANGUAGE
SM299141,34.5485,-9.0205,Wanging'ombe,ADM2,DISTRICT
SM248269,126.5343,-20.2687,Wangkajunga,DIALECT,LANGUAGE
SM458693,136.1604,-28.9143,Wangkakupa,ETHNICITY,ETHNICITY
SM458036,121.9181,-29.7783,Wangkatha,ETHNICITY,ETHNICITY
SM458018,122.6987,-29.8659,Wangkatja,ETHNICITY,ETHNICITY
SM458695,137.5855,-27.4945,Wangkatyaka,ETHNICITY,ETHNICITY
SM458675,141.8854,-27.5705,Wangkumara,ETHNICITY,ETHNICITY
SM249078,143.1962,-27.1015,Wangkumara,DIALECT,LANGUAGE
SM250802,34.39,0.13,Wangu,ETHNICITY,ETHNICITY
SM296078,-54.4559,5.7831,Wanhatti,ADM2,DISTRICT
SM248287,142.53,-3.7586,Wanib Sign Language,LANGUAGE,LANGUAGE
SM3722,-55.25,5.75,Wanica,ADM1,DISTRICT
SM280579,127.2586,35.8872,Wanju,ADM2,DISTRICT
SM296025,27.6,9,Wanjuk,ADM2,DISTRICT
SM258528,-83.92,14.31,Wanki,ETHNICITY,ETHNICITY
SM295793,44.9065,2.7916,Wanla Weyn,ADM2,DISTRICT
SM248290,122.325,-22.7078,Wanman,LANGUAGE,LANGUAGE
SM258529,127.18,-22.93,Wanman,ETHNICITY,ETHNICITY
SM258529,127.18,-22.93,Wanman,ETHNICITY,ETHNICITY
SM258529,123.0524,-22.2348,Wanman,ETHNICITY,ETHNICITY
SM258529,123.0524,-22.2348,Wanman,ETHNICITY,ETHNICITY
SM261327,115.7221,-31.6529,Wanneroo,ADM2,DISTRICT
SM10366,110.2875,18.8378,Wanning Shi,ADM2,DISTRICT
SM248292,8.9659,7.8386,Wannu,LANGUAGE,LANGUAGE
SM248294,137.678,-3.4786,Wano,LANGUAGE,LANGUAGE
SM243535,167.051,-15.0757,Wanohe,LANGUAGE,LANGUAGE
SM297501,103.7654,17.6152,Wanon Niwat,ADM2,DISTRICT
SM248297,146.513,-6.1522,Wantoat,LANGUAGE,LANGUAGE
SM258530,146.77,-6.36,Wantoat,ETHNICITY,ETHNICITY
SM258530,146.77,-6.36,Wantoat,ETHNICITY,ETHNICITY
SM258530,146.53,-6.15,Wantoat,ETHNICITY,ETHNICITY
SM258530,146.53,-6.15,Wantoat,ETHNICITY,ETHNICITY
SM248299,119.468,-9.6983,Wanukaka,LANGUAGE,LANGUAGE
SM248302,-63.1,-12.1,Wanyam,LANGUAGE,LANGUAGE
SM248303,137.608,-18.6529,Wanyi,LANGUAGE,LANGUAGE
SM458149,142.3425,-31.901,Wanyuparlku,ETHNICITY,ETHNICITY
SM459239,145.9433,-17.3832,Wanyurr,ETHNICITY,ETHNICITY
SM10367,108.402,30.7058,Wanzhou District,ADM2,DISTRICT
SM248265,-6.4441,4.8517,Wané,LANGUAGE,LANGUAGE
SM248307,-76.683,-1.1231,Waorani,LANGUAGE,LANGUAGE
SM258532,-76.58,0.08,Waorani,ETHNICITY,ETHNICITY
SM458450,150.9668,-23.1914,Wapabara,ETHNICITY,ETHNICITY
SM248308,9.833,7.9786,Wapan,LANGUAGE,LANGUAGE
SM258533,141.67,-3.21,Wapei-Paei,ETHNICITY,ETHNICITY
SM300873,-92.4095,41.0306,Wapello,ADM2,DISTRICT
SM288644,143.9167,-5.6667,Wapenamanda,ADM2,DISTRICT
SM297072,103.3409,15.8595,Wapi Pathum,ADM2,DISTRICT
SM248312,-59.8021,2.5874,Wapishana,LANGUAGE,LANGUAGE
SM258534,-58.51,3.8,Wapixana,ETHNICITY,ETHNICITY
SM258534,-58.51,3.8,Wapixana,ETHNICITY,ETHNICITY
SM258534,-58.51,3.8,Wapixana,ETHNICITY,ETHNICITY
SM258534,-59,3,Wapixana,ETHNICITY,ETHNICITY
SM258534,-59,3,Wapixana,ETHNICITY,ETHNICITY
SM258534,-59,3,Wapixana,ETHNICITY,ETHNICITY
SM258534,-60,3,Wapixana,ETHNICITY,ETHNICITY
SM258534,-60,3,Wapixana,ETHNICITY,ETHNICITY
SM258534,-60,3,Wapixana,ETHNICITY,ETHNICITY
SM258535,-73.43,42.03,Wappinger,ETHNICITY,ETHNICITY
SM248313,-122.5,38.25,Wappo,LANGUAGE,LANGUAGE
SM258536,-122.28,38.56,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.28,38.56,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.28,38.56,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.28,38.56,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.28,38.56,Wappo,ETHNICITY,ETHNICITY
SM258536,-123,38,Wappo,ETHNICITY,ETHNICITY
SM258536,-123,38,Wappo,ETHNICITY,ETHNICITY
SM258536,-123,38,Wappo,ETHNICITY,ETHNICITY
SM258536,-123,38,Wappo,ETHNICITY,ETHNICITY
SM258536,-123,38,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.56,38.22,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.56,38.22,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.56,38.22,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.56,38.22,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.56,38.22,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.54,38.59,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.54,38.59,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.54,38.59,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.54,38.59,Wappo,ETHNICITY,ETHNICITY
SM258536,-122.54,38.59,Wappo,ETHNICITY,ETHNICITY
SM258536,-123,38.7,Wappo,ETHNICITY,ETHNICITY
SM258536,-123,38.7,Wappo,ETHNICITY,ETHNICITY
SM258536,-123,38.7,Wappo,ETHNICITY,ETHNICITY
SM258536,-123,38.7,Wappo,ETHNICITY,ETHNICITY
SM258536,-123,38.7,Wappo,ETHNICITY,ETHNICITY
SM258537,12.12,5.68,War,ETHNICITY,ETHNICITY
SM248349,91.6266,25.1154,War-Jaintia,LANGUAGE,LANGUAGE
SM279213,139.6855,35.8253,Warabi,ADM2,DISTRICT
SM258538,149.47,-22.9,Warabul,ETHNICITY,ETHNICITY
SM258539,134.01,-20.17,Waramunga,ETHNICITY,ETHNICITY
SM258539,134.01,-20.17,Waramunga,ETHNICITY,ETHNICITY
SM258539,134.5052,-19.6027,Waramunga,ETHNICITY,ETHNICITY
SM258539,134.5052,-19.6027,Waramunga,ETHNICITY,ETHNICITY
SM258540,151.04,-22.2,Waranbura,ETHNICITY,ETHNICITY
SM11698,79.8333,18,Warangal (2016-),ADM2,DISTRICT
SM11699,79.5813,17.9995,Warangal Urban,ADM2,DISTRICT
SM248327,-59.3528,7.5085,Warao,LANGUAGE,LANGUAGE
SM258541,-62.24,9.62,Warao,ETHNICITY,ETHNICITY
SM258541,-62.24,9.62,Warao,ETHNICITY,ETHNICITY
SM258541,-62.24,9.62,Warao,ETHNICITY,ETHNICITY
SM258541,-62.24,9.62,Warao,ETHNICITY,ETHNICITY
SM258541,-61.05,8.14,Warao,ETHNICITY,ETHNICITY
SM258541,-61.05,8.14,Warao,ETHNICITY,ETHNICITY
SM258541,-61.05,8.14,Warao,ETHNICITY,ETHNICITY
SM258541,-61.05,8.14,Warao,ETHNICITY,ETHNICITY
SM258541,-61.15,8.8415,Warao,ETHNICITY,ETHNICITY
SM258541,-61.15,8.8415,Warao,ETHNICITY,ETHNICITY
SM258541,-61.15,8.8415,Warao,ETHNICITY,ETHNICITY
SM258541,-61.15,8.8415,Warao,ETHNICITY,ETHNICITY
SM258541,-62,9,Warao,ETHNICITY,ETHNICITY
SM258541,-62,9,Warao,ETHNICITY,ETHNICITY
SM258541,-62,9,Warao,ETHNICITY,ETHNICITY
SM258541,-62,9,Warao,ETHNICITY,ETHNICITY
SM258541,-62,9.08,Warao,ETHNICITY,ETHNICITY
SM258541,-62,9.08,Warao,ETHNICITY,ETHNICITY
SM258541,-62,9.08,Warao,ETHNICITY,ETHNICITY
SM258541,-62,9.08,Warao,ETHNICITY,ETHNICITY
SM3723,28.85,8,Warap,ADM1,DISTRICT
SM260260,66.9735,34.1514,Waras,ADM2,DISTRICT
SM261774,145.4583,-41.3071,Waratah/Wynyard,ADM2,DISTRICT
SM284890,8.7715,11.9081,Warawa,ADM2,DISTRICT
SM258542,124.84,12.13,Waray,ETHNICITY,ETHNICITY
SM248324,125.012,11.4911,Waray (Philippines),LANGUAGE,LANGUAGE
SM248323,124.015,12.6795,Waray Sorsogon,LANGUAGE,LANGUAGE
SM258543,151.88,-25.04,Warbaa,ETHNICITY,ETHNICITY
SM324034,-114.3187,53.1852,Warburg,ADM2:ADM3,DISTRICT
SM302793,-103.1024,31.5095,Ward,ADM2,DISTRICT
SM302076,-101.5418,48.2218,Ward,ADM2,DISTRICT
SM9471,-61.3157,10.7667,Ward of Blanchisseuse,ADM2,DISTRICT
SM9491,-61.8,10.098,Ward of Cedros,ADM2,DISTRICT
SM9477,-61.2,10.3833,Ward of Charuma,ADM2,DISTRICT
SM9478,-61.0833,10.3667,Ward of Cocal,ADM2,DISTRICT
SM9472,-61.45,10.4167,Ward of Couva,ADM2,DISTRICT
SM9473,-61.3333,10.55,Ward of Cunupia,ADM2,DISTRICT
SM9492,-61.6333,10.0833,Ward of Erin,ADM2,DISTRICT
SM9479,-61.05,10.2,Ward of Guayaguayare,ADM2,DISTRICT
SM9493,-61.65,10.1667,Ward of La Brea,ADM2,DISTRICT
SM9485,-61.1333,10.6167,Ward of Manzanilla,ADM2,DISTRICT
SM9486,-61.0667,10.7,Ward of Matura,ADM2,DISTRICT
SM9474,-61.35,10.4167,Ward of Montserrat,ADM2,DISTRICT
SM9482,-61.2859,10.1117,Ward of Moruga,ADM2,DISTRICT
SM9481,-61.45,10.2333,Ward of Naparima,ADM2,DISTRICT
SM9483,-61.3167,10.2667,Ward of Ortoire,ADM2,DISTRICT
SM9475,-61.4,10.3333,Ward of Pointe-a-Pierre,ADM2,DISTRICT
SM9476,-61.2667,10.5333,Ward of San Rafael,ADM2,DISTRICT
SM9484,-61.3667,10.3,Ward of Savana Grande,ADM2,DISTRICT
SM9494,-61.4667,10.15,Ward of Siparia,ADM2,DISTRICT
SM9487,-61.1833,10.5333,Ward of Tamana,ADM2,DISTRICT
SM9488,-61.05,10.7833,Ward of Toco,ADM2,DISTRICT
SM9480,-61.1549,10.1919,Ward of Trinity,ADM2,DISTRICT
SM9489,-61.1167,10.5,Ward of Turure,ADM2,DISTRICT
SM9490,-61.1668,10.6823,Ward of Valencia,ADM2,DISTRICT
SM3724,68,34.25,Wardak,ADM1,DISTRICT
SM248331,132.604,-15.4155,Wardaman,LANGUAGE,LANGUAGE
SM248333,115.2693,-33.9211,Wardandi,DIALECT,LANGUAGE
SM277059,78.6,20.8333,Wardha,ADM2,DISTRICT
SM9598,71.1824,36.7571,Warduj,ADM2,DISTRICT
SM248330,71.1475,36.6083,Warduji,LANGUAGE,LANGUAGE
SM300434,-82.4237,31.0536,Ware,ADM2,DISTRICT
SM258544,151.06,-10.96,Ware,ETHNICITY,ETHNICITY
SM258545,132.04,-12.78,Warei,ETHNICITY,ETHNICITY
SM258545,132.04,-12.78,Warei,ETHNICITY,ETHNICITY
SM258545,131.2538,-13.1632,Warei,ETHNICITY,ETHNICITY
SM258545,131.2538,-13.1632,Warei,ETHNICITY,ETHNICITY
SM248337,-67.3173,1.6682,Warekena Velha,LANGUAGE,LANGUAGE
SM248337,-67.3173,1.6682,Warekena Velha,LANGUAGE,LANGUAGE
SM248337,-67.0921,2.9041,Warekena Velha,LANGUAGE,LANGUAGE
SM248337,-67.0921,2.9041,Warekena Velha,LANGUAGE,LANGUAGE
SM248335,137.566,-1.6594,Warembori,LANGUAGE,LANGUAGE
SM248336,139.337,-2.4736,Wares,LANGUAGE,LANGUAGE
SM258546,-63.65,-12.89,Wari,ETHNICITY,ETHNICITY
SM248346,-64.5615,-10.6995,Wari',LANGUAGE,LANGUAGE
SM459194,145.7718,-17.5048,Waribarra Mamu,ETHNICITY,ETHNICITY
SM297705,104.875,15.1239,Warin Chamrap,ADM2,DISTRICT
SM258547,-69.07,3.13,Wariperidakena,ETHNICITY,ETHNICITY
SM248344,140.931,-3.139,Waris,LANGUAGE,LANGUAGE
SM248342,138.456,-3.2302,Waritai,LANGUAGE,LANGUAGE
SM297502,103.6228,17.2569,Waritchaphum,ADM2,DISTRICT
SM248340,116.927,-24.353,Wariyangga,LANGUAGE,LANGUAGE
SM250805,13.06,11.91,Warjawa,ETHNICITY,ETHNICITY
SM248350,9.5693,11.1718,Warji,LANGUAGE,LANGUAGE
SM285320,9.739,11.158,Warji,ADM2,DISTRICT
SM248353,138.638,-5.7716,Warkay-Bipim,LANGUAGE,LANGUAGE
SM459033,138.8509,-35.46,Warki,ETHNICITY,ETHNICITY
SM248355,133.862,-18.949,Warlmanpa,LANGUAGE,LANGUAGE
SM458112,132.871,-19.2496,Warlmanpa,ETHNICITY,ETHNICITY
SM248354,131.05,-20.1008,Warlpiri,LANGUAGE,LANGUAGE
SM248356,138.738,-22.2909,Warluwara,LANGUAGE,LANGUAGE
SM458465,138.4286,-21.7622,Warluwarra^,ETHNICITY,ETHNICITY
SM12815,-77.6396,17.9624,Warminister,ADM2,DISTRICT
SM3725,20.8333,53.8333,Warmińsko-Mazurskie,ADM1,DISTRICT
SM248360,31.1574,10.594,Warnang,LANGUAGE,LANGUAGE
SM458131,130.7031,-19.7387,Warnayaka,ETHNICITY,ETHNICITY
SM258548,134.74,-16.01,Warndarrang,ETHNICITY,ETHNICITY
SM258548,134.74,-16.01,Warndarrang,ETHNICITY,ETHNICITY
SM258548,135.3451,-15.0429,Warndarrang,ETHNICITY,ETHNICITY
SM258548,135.3451,-15.0429,Warndarrang,ETHNICITY,ETHNICITY
SM323839,-112.208,49.2823,Warner,ADM2:ADM3,DISTRICT
SM323840,-112.1137,49.2761,Warner County No. 5,ADM2:ADM3,DISTRICT
SM261328,115.9043,-32.8492,Waroona,ADM2,DISTRICT
SM248362,136.633,-2.3114,Waropen,LANGUAGE,LANGUAGE
SM276543,136.6457,-2.7301,Waropen,ADM2,DISTRICT
SM258549,137.47,-1.93,Waropen,ETHNICITY,ETHNICITY
SM258549,137.47,-1.93,Waropen,ETHNICITY,ETHNICITY
SM258549,137.23,-2.14,Waropen,ETHNICITY,ETHNICITY
SM258549,137.23,-2.14,Waropen,ETHNICITY,ETHNICITY
SM248316,131.236,-13.0941,Warray,LANGUAGE,LANGUAGE
SM302965,-78.2075,38.9088,Warren,ADM2,DISTRICT
SM302631,-85.7785,35.6787,Warren,ADM2,DISTRICT
SM302343,-79.2741,41.8146,Warren,ADM2,DISTRICT
SM301920,-73.846,43.561,Warren,ADM2,DISTRICT
SM301826,-74.997,40.8573,Warren,ADM2,DISTRICT
SM302023,-78.1069,36.3966,Warren,ADM2,DISTRICT
SM302165,-84.1668,39.4276,Warren,ADM2,DISTRICT
SM301498,-90.852,32.3572,Warren,ADM2,DISTRICT
SM301519,-91.1607,38.7646,Warren,ADM2,DISTRICT
SM300776,-87.3533,40.3469,Warren,ADM2,DISTRICT
SM300874,-93.5614,41.3344,Warren,ADM2,DISTRICT
SM300686,-90.615,40.8488,Warren,ADM2,DISTRICT
SM301005,-86.4238,36.9936,Warren,ADM2,DISTRICT
SM300435,-82.6768,33.409,Warren,ADM2,DISTRICT
SM261483,147.7285,-31.2955,Warren,ADM2,DISTRICT
SM248364,146.179,-18.5468,Warrgamay,LANGUAGE,LANGUAGE
SM459209,145.7979,-18.4375,Warrgamay^,ETHNICITY,ETHNICITY
SM284664,5.2769,5.8824,Warri North,ADM2,DISTRICT
SM284665,5.6106,5.6133,Warri South,ADM2,DISTRICT
SM284666,5.3742,5.4969,Warri South-West,ADM2,DISTRICT
SM300777,-87.272,38.0922,Warrick,ADM2,DISTRICT
SM458201,151.9969,-32.4868,Warrimay,ETHNICITY,ETHNICITY
SM274593,-2.5833,53.4167,Warrington,ADM1:ADM2,DISTRICT
SM459137,115.772,-24.6297,Warriyangga,ETHNICITY,ETHNICITY
SM458133,129.7479,-20.5094,Warrmarla,ETHNICITY,ETHNICITY
SM248366,141.5397,-38.1556,Warrnambool,LANGUAGE,LANGUAGE
SM261284,142.5217,-38.3782,Warrnambool,ADM2,DISTRICT
SM248371,144.37,-18.2496,Warrongo,LANGUAGE,LANGUAGE
SM261486,149.4538,-31.4552,Warrumbungle,ADM2,DISTRICT
SM248367,123.971,-17.0965,Warrwa,LANGUAGE,LANGUAGE
SM458513,124.0136,-17.276,Warrwa,ETHNICITY,ETHNICITY
SM260210,70.1388,36.096,Warsaj,ADM2,DISTRICT
SM12816,-77.5677,18.2618,Warsop,ADM2,DISTRICT
SM288979,20.6191,52.2684,Warszaw Zachodni,ADM2,DISTRICT
SM288980,21.0419,52.2355,Warszawa,ADM2,DISTRICT
SM231916,141.253,-8.8081,Warta Thuntai,LANGUAGE,LANGUAGE
SM248373,122.058,-3.412,Waru,LANGUAGE,LANGUAGE
SM248372,134.375,-20.6479,Warumungu,LANGUAGE,LANGUAGE
SM248374,142.47,-8.2357,Waruna,LANGUAGE,LANGUAGE
SM258551,145.61,-18.41,Warunggu,ETHNICITY,ETHNICITY
SM258551,145.61,-18.41,Warunggu,ETHNICITY,ETHNICITY
SM258551,145.2397,-18.034,Warunggu,ETHNICITY,ETHNICITY
SM258551,145.2397,-18.034,Warunggu,ETHNICITY,ETHNICITY
SM3726,-64.8072,32.2658,Warwick,ADM1,DISTRICT
SM17704,-61.0005,13.8108,Warwick/Daban,ADM2,DISTRICT
SM274594,-1.5833,52.3333,Warwickshire,ADM1:ADM2,DISTRICT
SM242299,-63.1903,-12.8553,Warázu,LANGUAGE,LANGUAGE
SM248378,-2.1603,5.7411,Wasa,LANGUAGE,LANGUAGE
SM4411,-2.2864,5.7421,Wasa Amenfi Central,ADM2,DISTRICT
SM274859,-2.0096,5.7976,Wasa Amenfi East,ADM2,DISTRICT
SM4410,-2.4333,5.8,Wasa Amenfi West (2012-),ADM2,DISTRICT
SM302911,-111.1685,40.3304,Wasatch,ADM2,DISTRICT
SM302275,-121.1682,45.1601,Wasco,ADM2,DISTRICT
SM258552,-121.64,44.87,Wasco,ETHNICITY,ETHNICITY
SM285187,10.2052,9.0826,Wase,ADM2,DISTRICT
SM301418,-93.5873,44.0221,Waseca,ADM2,DISTRICT
SM248385,145.969,-6.0132,Wasembo,LANGUAGE,LANGUAGE
SM303309,-107.6829,43.905,Washakie,ADM2,DISTRICT
SM303285,-91.7912,45.8992,Washburn,ADM2,DISTRICT
SM260000,63.9466,32.1032,Washer,ADM2,DISTRICT
SM304857,43.3755,16.3216,Washhah,ADM2,DISTRICT
SM277060,77.2,20.2,Washim,ADM2,DISTRICT
SM302966,-81.9597,36.7245,Washington,ADM2,DISTRICT
SM303286,-88.2308,43.3685,Washington,ADM2,DISTRICT
SM302925,-72.6149,44.2734,Washington,ADM2,DISTRICT
SM302912,-113.5049,37.2804,Washington,ADM2,DISTRICT
SM302794,-96.4034,30.2145,Washington,ADM2,DISTRICT
SM302276,-123.0985,45.5601,Washington,ADM2,DISTRICT
SM302633,-82.4974,36.2933,Washington,ADM2,DISTRICT
SM302243,-95.9044,36.7152,Washington,ADM2,DISTRICT
SM302344,-80.2482,40.1894,Washington,ADM2,DISTRICT
SM302431,-71.6197,41.3965,Washington,ADM2,DISTRICT
SM301765,-96.222,41.531,Washington,ADM2,DISTRICT
SM301921,-73.4308,43.3137,Washington,ADM2,DISTRICT
SM302024,-76.5687,35.8389,Washington,ADM2,DISTRICT
SM302166,-81.4953,39.4553,Washington,ADM2,DISTRICT
SM301227,-77.814,39.6037,Washington,ADM2,DISTRICT
SM301162,-90.0405,30.8533,Washington,ADM2,DISTRICT
SM301499,-90.9475,33.2837,Washington,ADM2,DISTRICT
SM301204,-67.6091,44.9695,Washington,ADM2,DISTRICT
SM301521,-90.8774,37.9617,Washington,ADM2,DISTRICT
SM301419,-92.884,45.0387,Washington,ADM2,DISTRICT
SM300778,-86.1053,38.6,Washington,ADM2,DISTRICT
SM301006,-85.1748,37.7534,Washington,ADM2,DISTRICT
SM300687,-89.4105,38.3522,Washington,ADM2,DISTRICT
SM300886,-97.0875,39.7842,Washington,ADM2,DISTRICT
SM300875,-91.7179,41.3356,Washington,ADM2,DISTRICT
SM300582,-116.7848,44.4524,Washington,ADM2,DISTRICT
SM300437,-82.7959,32.9695,Washington,ADM2,DISTRICT
SM300376,-85.6653,30.6106,Washington,ADM2,DISTRICT
SM303376,-103.2013,39.9711,Washington,ADM2,DISTRICT
SM302421,-94.2156,35.9791,Washington,ADM2,DISTRICT
SM300307,-88.2079,31.4076,Washington,ADM2,DISTRICT
SM3727,-120.5015,47.5001,Washington,ADM1,DISTRICT
SM12817,-76.8402,18.0217,Washington Gardens,ADM2,DISTRICT
SM302244,-98.9922,35.2904,Washita,ADM2,DISTRICT
SM248386,-119.64,38.8184,Washo,LANGUAGE,LANGUAGE
SM258554,-119.59,39.2,Washo,ETHNICITY,ETHNICITY
SM258554,-119.59,39.2,Washo,ETHNICITY,ETHNICITY
SM258554,-119.59,39.2,Washo,ETHNICITY,ETHNICITY
SM258554,-119.59,39.2,Washo,ETHNICITY,ETHNICITY
SM258554,-119.8335,39.25,Washo,ETHNICITY,ETHNICITY
SM258554,-119.8335,39.25,Washo,ETHNICITY,ETHNICITY
SM258554,-119.8335,39.25,Washo,ETHNICITY,ETHNICITY
SM258554,-119.8335,39.25,Washo,ETHNICITY,ETHNICITY
SM258554,-120,39,Washo,ETHNICITY,ETHNICITY
SM258554,-120,39,Washo,ETHNICITY,ETHNICITY
SM258554,-120,39,Washo,ETHNICITY,ETHNICITY
SM258554,-120,39,Washo,ETHNICITY,ETHNICITY
SM258554,-119.82,39.08,Washo,ETHNICITY,ETHNICITY
SM258554,-119.82,39.08,Washo,ETHNICITY,ETHNICITY
SM258554,-119.82,39.08,Washo,ETHNICITY,ETHNICITY
SM258554,-119.82,39.08,Washo,ETHNICITY,ETHNICITY
SM301778,-119.6643,40.6654,Washoe,ADM2,DISTRICT
SM301334,-83.8388,42.2532,Washtenaw,ADM2,DISTRICT
SM5101,64.5,27.75,Washuk,ADM2:ADM3,DISTRICT
SM3728,45.4167,32.75,Wasit,ADM1,DISTRICT
SM324060,-112.7829,54.0951,Waskatenau,ADM2:ADM3,DISTRICT
SM248388,145.979,-4.5798,Waskia,LANGUAGE,LANGUAGE
SM285412,-85.3629,13.3335,Waslala,ADM2,DISTRICT
SM285413,-84.4167,14.5833,Waspán,ADM2,DISTRICT
SM4409,-1.65,5.1333,Wassa East (2012-),ADM2,DISTRICT
SM285712,4.3755,52.1379,Wassenaar,ADM2,DISTRICT
SM268955,7.5916,47.1891,Wasseramt,ADM2,DISTRICT
SM278005,35.8482,32.5707,Wastiyyeh,ADM2,DISTRICT
SM248390,-36.8333,-9.75,Wasu,LANGUAGE,LANGUAGE
SM258556,-76.64,49.78,Waswani Cree,ETHNICITY,ETHNICITY
SM296017,31.999,7.501,Wat,ADM2,DISTRICT
SM297376,100.363,17.1638,Wat Bot,ADM2,DISTRICT
SM297447,99.8696,13.4471,Wat Phleng,ADM2,DISTRICT
SM297670,99.9668,15.2157,Wat Sing,ADM2,DISTRICT
SM248396,143.005,-4.5897,Watakataui,LANGUAGE,LANGUAGE
SM248398,144.583,-4.0259,Watam,LANGUAGE,LANGUAGE
SM13116,136.5268,34.3398,Watarai-gun,ADM2,DISTRICT
SM13117,140.8791,37.9964,Watari-gun,ADM2,DISTRICT
SM302025,-81.6964,36.2311,Watauga,ADM2,DISTRICT
SM12818,-77.7725,17.9388,Watchwell,ADM2,DISTRICT
SM303910,-64.9338,18.3282,Water Island,ADM2,DISTRICT
SM12819,-77.2595,17.8245,Water Lane,ADM2,DISTRICT
SM12820,-78.043,18.2332,Water Works,ADM2,DISTRICT
SM305129,27.9787,-24.2051,Waterberg,ADM2,DISTRICT
SM12821,-76.8609,17.9934,Waterford,ADM2,DISTRICT
SM3729,-7.1439,52.24,Waterford,ADM1:ADM2,DISTRICT
SM12822,-76.8351,18.0123,Waterhouse,ADM2,DISTRICT
SM286018,5.0137,52.4376,Waterland,ADM2,DISTRICT
SM268889,-80.4997,43.5001,Waterloo,ADM2,DISTRICT
SM12823,-77.0911,18.0669,Watermount,ADM2,DISTRICT
SM17705,-60.9764,14.005,Waterworks,ADM2,DISTRICT
SM12824,-77.5187,18.0068,Watham,ADM2,DISTRICT
SM248401,144.1013,-37.6878,Wathawurrung,LANGUAGE,LANGUAGE
SM258558,143.49,-35.15,Wathi-Wathi,ETHNICITY,ETHNICITY
SM258558,143.49,-35.15,Wathi-Wathi,ETHNICITY,ETHNICITY
SM258558,143.3705,-35.0866,Wathi-Wathi,ETHNICITY,ETHNICITY
SM258558,143.3705,-35.0866,Wathi-Wathi,ETHNICITY,ETHNICITY
SM459150,119.9874,-30.4293,Watjanmay,ETHNICITY,ETHNICITY
SM459131,114.688,-28.1005,Watjanti,ETHNICITY,ETHNICITY
SM301420,-94.6141,43.9784,Watonwan,ADM2,DISTRICT
SM12825,-77.4147,18.2943,Watt Town,ADM2,DISTRICT
SM297187,100.5828,13.735,Wattana,ADM2,DISTRICT
SM297492,102.3565,13.8858,Watthana Nakhon,ADM2,DISTRICT
SM261745,140.4211,-37.508,Wattle Range,ADM2,DISTRICT
SM248406,131.641,-4.4729,Watubela,LANGUAGE,LANGUAGE
SM296043,28.0964,7.8143,Wau,ADM2,DISTRICT
SM289068,125.31,38.7486,Waudo,ADM2,DISTRICT
SM303288,-88.3045,43.0182,Waukesha,ADM2,DISTRICT
SM303289,-88.9648,44.4704,Waupaca,ADM2,DISTRICT
SM258559,-52.08,-11.89,Waura,ETHNICITY,ETHNICITY
SM248407,-53.6971,-13.296,Waurá,LANGUAGE,LANGUAGE
SM303290,-89.2429,44.1131,Waushara,ADM2,DISTRICT
SM248410,130.597,-0.2828,Wauyai,LANGUAGE,LANGUAGE
SM261487,151.256,-33.9018,Waverley,ADM2,DISTRICT
SM248412,11.7802,6.6897,Wawa,LANGUAGE,LANGUAGE
SM296862,0.75,7.75,Wawa,ADM2,DISTRICT
SM458647,127.8501,-18.2703,Wawarl,ETHNICITY,ETHNICITY
SM248416,123.1,-4.1359,Wawonii,LANGUAGE,LANGUAGE
SM258560,123.1,-4.04,Wawonii,ETHNICITY,ETHNICITY
SM458054,121.1196,-25.0101,Wawula,ETHNICITY,ETHNICITY
SM458130,130.0439,-21.8047,Wawulya,ETHNICITY,ETHNICITY
SM248417,110.362,28.644,Waxianghua,LANGUAGE,LANGUAGE
SM276444,104.5275,-4.4471,Way Kanan,ADM2,DISTRICT
SM248422,177.1234,-17.2947,Waya (Western Fijian),DIALECT,LANGUAGE
SM296106,-55.4676,5.8429,Wayambo Weg,ADM2,DISTRICT
SM248424,-52.6887,1.7382,Wayampi,LANGUAGE,LANGUAGE
SM248423,-54.4429,2.7754,Wayana,LANGUAGE,LANGUAGE
SM276958,76.083,11.605,Wayanad,ADM2,DISTRICT
SM248418,76.3704,11.6702,Wayanad Chetti,LANGUAGE,LANGUAGE
SM260145,71.0262,35.2256,Waygal,ADM2,DISTRICT
SM10349,15.3686,13.6428,Wayi (excl. Kouloudia),ADM2,DISTRICT
SM461513,-76.5199,-6.4221,Wayku,ETHNICITY,ETHNICITY
SM303153,-82.427,38.146,Wayne,ADM2,DISTRICT
SM302913,-110.9037,38.3244,Wayne,ADM2,DISTRICT
SM302345,-75.3033,41.6487,Wayne,ADM2,DISTRICT
SM302634,-87.788,35.2399,Wayne,ADM2,DISTRICT
SM302026,-78.0042,35.3638,Wayne,ADM2,DISTRICT
SM301767,-97.1193,42.2093,Wayne,ADM2,DISTRICT
SM301923,-76.9785,43.0659,Wayne,ADM2,DISTRICT
SM302167,-81.888,40.8289,Wayne,ADM2,DISTRICT
SM301335,-83.2611,42.2848,Wayne,ADM2,DISTRICT
SM301500,-88.6958,31.6408,Wayne,ADM2,DISTRICT
SM301522,-90.4614,37.1126,Wayne,ADM2,DISTRICT
SM300688,-88.4256,38.4296,Wayne,ADM2,DISTRICT
SM300876,-93.3274,40.7395,Wayne,ADM2,DISTRICT
SM300780,-85.0099,39.8644,Wayne,ADM2,DISTRICT
SM301007,-84.8286,36.8013,Wayne,ADM2,DISTRICT
SM300438,-81.9168,31.5514,Wayne,ADM2,DISTRICT
SM302967,-78.8895,38.0685,Waynesboro,ADM2,DISTRICT
SM258566,-62.35,-14.35,Wayoro,ETHNICITY,ETHNICITY
SM248429,-61.3064,-12.6945,Wayoró,LANGUAGE,LANGUAGE
SM248430,86.1227,27.3047,Wayu,LANGUAGE,LANGUAGE
SM248431,-61.2108,3.1331,Wayumara,LANGUAGE,LANGUAGE
SM258567,-61.17,3.42,Wayumara,ETHNICITY,ETHNICITY
SM248432,-72.1998,11.3956,Wayuu,LANGUAGE,LANGUAGE
SM258568,-72.35,10.15,Wayuu,ETHNICITY,ETHNICITY
SM258568,-72.35,10.15,Wayuu,ETHNICITY,ETHNICITY
SM258568,-72.35,10.15,Wayuu,ETHNICITY,ETHNICITY
SM258568,-72.35,10.15,Wayuu,ETHNICITY,ETHNICITY
SM258568,-71.75,11.75,Wayuu,ETHNICITY,ETHNICITY
SM258568,-71.75,11.75,Wayuu,ETHNICITY,ETHNICITY
SM258568,-71.75,11.75,Wayuu,ETHNICITY,ETHNICITY
SM258568,-71.75,11.75,Wayuu,ETHNICITY,ETHNICITY
SM258568,-72,12,Wayuu,ETHNICITY,ETHNICITY
SM258568,-72,12,Wayuu,ETHNICITY,ETHNICITY
SM258568,-72,12,Wayuu,ETHNICITY,ETHNICITY
SM258568,-72,12,Wayuu,ETHNICITY,ETHNICITY
SM258568,-71.75,11.92,Wayuu,ETHNICITY,ETHNICITY
SM258568,-71.75,11.92,Wayuu,ETHNICITY,ETHNICITY
SM258568,-71.75,11.92,Wayuu,ETHNICITY,ETHNICITY
SM258568,-71.75,11.92,Wayuu,ETHNICITY,ETHNICITY
SM459104,146.3075,-36.2298,Waywurru / Pallanganmiddang,ETHNICITY,ETHNICITY
SM9599,68.3273,32.249,Wazah Khwah (2005-),ADM2,DISTRICT
SM288835,16.3385,53.2363,Wałcz,ADM2,DISTRICT
SM295449,160.8406,-8.9302,Weagu Silana Sina,ADM2,DISTRICT
SM302635,-88.7177,36.2983,Weakley,ADM2,DISTRICT
SM258571,-75.9,37.12,Weapemeoc,ETHNICITY,ETHNICITY
SM295414,161.8019,-10.6673,Weather Coast,ADM2,DISTRICT
SM302796,-99.3316,27.7611,Webb,ADM2,DISTRICT
SM370216,-7.7101,4.9258,Webbo Clan,ADM2:ADM3,DISTRICT
SM302914,-111.9133,41.2699,Weber,ADM2,DISTRICT
SM258573,-111.1,40.84,Weber Ute,ETHNICITY,ETHNICITY
SM303154,-80.4219,38.4947,Webster,ADM2,DISTRICT
SM301768,-98.5,40.1764,Webster,ADM2,DISTRICT
SM301164,-93.335,32.7135,Webster,ADM2,DISTRICT
SM301501,-89.2848,33.6131,Webster,ADM2,DISTRICT
SM301523,-92.8759,37.2809,Webster,ADM2,DISTRICT
SM300877,-94.1818,42.428,Webster,ADM2,DISTRICT
SM301008,-87.6832,37.5184,Webster,ADM2,DISTRICT
SM300439,-84.5511,32.0467,Webster,ADM2,DISTRICT
SM248437,150.172,-10.1246,Wedau,LANGUAGE,LANGUAGE
SM261488,148.0113,-33.879,Weddin,ADM2,DISTRICT
SM370249,-8.8194,5.2983,Wedjah District,ADM2:ADM3,DISTRICT
SM14127,-8.4167,5.5,Wedjah-Cabade,ADM2,DISTRICT
SM305252,31.6536,-18.7273,Wedza,ADM2,DISTRICT
SM285895,5.6907,51.2342,Weert,ADM2,DISTRICT
SM286019,5.0415,52.3058,Weesp,ADM2,DISTRICT
SM296091,-55.2204,5.8371,Weg Naar Zee,ADM2,DISTRICT
SM258574,-90.64,52.79,Wegamon Ojibwa,ETHNICITY,ETHNICITY
SM248444,10.121,6.4616,Weh,LANGUAGE,LANGUAGE
SM458449,152.5518,-29.2244,Wehlubal,ETHNICITY,ETHNICITY
SM269295,119.0625,36.6258,Weifang,ADM2,DISTRICT
SM269296,122.0683,37.2239,Weihai,ADM2,DISTRICT
SM370520,6.2008,49.5408,Weiler-la-Tour,ADM1:ADM2:ADM3,DISTRICT
SM269287,109.735,34.9369,Weinan,ADM2,DISTRICT
SM457995,9.1571,47.5424,Weinfelden (2011-),ADM2,DISTRICT
SM261673,141.8756,-12.6367,Weipa,ADM2,DISTRICT
SM370430,6.075,50.1372,Weiswampach,ADM1:ADM2:ADM3,DISTRICT
SM261856,15.65,47.3,Weiz,ADM2,DISTRICT
SM288708,18.0488,54.6171,Wejherowo,ADM2,DISTRICT
SM459054,143.3622,-35.0367,Weki Weki,ETHNICITY,ETHNICITY
SM250811,36.8,5.65,Welaita,ETHNICITY,ETHNICITY
SM248450,125.2395,-9.0973,Welaun,LANGUAGE,LANGUAGE
SM248449,98.3197,27.0457,Welaung,LANGUAGE,LANGUAGE
SM12826,-77.8367,18.369,Welcome Hall,ADM2,DISTRICT
SM303377,-104.3925,40.5548,Weld,ADM2,DISTRICT
SM3730,11.1,1.5,Wele-Nzas (pre-2015),ADM1,DISTRICT
SM296092,-55.2071,5.8377,Welgelegen,ADM2,DISTRICT
SM258575,146.56,-7.57,Weli,ETHNICITY,ETHNICITY
SM248452,147.137,-5.9859,Weliki,LANGUAGE,LANGUAGE
SM286525,174.7542,-41.253,Wellington,ADM2,DISTRICT
SM268890,-80.4997,43.8334,Wellington,ADM2,DISTRICT
SM261285,147.017,-38.0377,Wellington,ADM2,DISTRICT
SM3731,174.7667,-41.2833,Wellington,ADM1,DISTRICT
SM302077,-99.661,47.5875,Wells,ADM2,DISTRICT
SM300781,-85.2212,40.7292,Wells,ADM2,DISTRICT
SM14991,138.1191,9.5271,Weloy Municipality,ADM2,DISTRICT
SM261837,14.0216,48.1608,Wels,ADM2,DISTRICT
SM261836,13.9664,48.1166,Wels Land,ADM2,DISTRICT
SM248455,-4,52,Welsh,LANGUAGE,LANGUAGE
SM258576,-2.76,52.47,Welsh,ETHNICITY,ETHNICITY
SM248454,-3.7888,52.1166,Welsh Romani,LANGUAGE,LANGUAGE
SM240291,128.839,-3.0514,Wemale,LANGUAGE,LANGUAGE
SM248458,144.09,-35.445,Wemba Wemba,DIALECT,LANGUAGE
SM458142,144.1419,-35.3412,Wemba Wemba,ETHNICITY,ETHNICITY
SM323810,-119.1406,55.1566,Wembley,ADM2:ADM3,DISTRICT
SM248460,2.4746,6.7723,Weme Gbe,LANGUAGE,LANGUAGE
SM458134,128.7492,-23.7084,Wenamba,ETHNICITY,ETHNICITY
SM258577,-120.42,47.29,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-120.42,47.29,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-120.42,47.29,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-120.42,47.29,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-121,48,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-121,48,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-121,48,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-121,48,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-120.31,47.41,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-120.31,47.41,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-120.31,47.41,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-120.31,47.41,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-120.3,47.5,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-120.3,47.5,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-120.3,47.5,Wenatchi,ETHNICITY,ETHNICITY
SM258577,-120.3,47.5,Wenatchi,ETHNICITY,ETHNICITY
SM264062,-45.4021,-22.5397,Wenceslau Braz,ADM2,DISTRICT
SM267715,-39.5809,-13.6182,Wenceslau Guimarães,ADM2,DISTRICT
SM4342,-2.1,7.7333,Wenchi,ADM2,DISTRICT
SM14992,151.881,7.44,Weno Municipality,ADM2,DISTRICT
SM248466,-78.4756,43.14,Wenro,LANGUAGE,LANGUAGE
SM258578,-77.04,42.25,Wenrohronon,ETHNICITY,ETHNICITY
SM269370,104.7083,23.6513,Wenshan Zhuang and Miao,ADM2,DISTRICT
SM261490,142.3368,-33.6711,Wentworth,ADM2,DISTRICT
SM241874,120.7012,28.0055,Wenzhou,DIALECT,LANGUAGE
SM269383,120.4852,27.815,Wenzhou,ADM2,DISTRICT
SM268940,9.463,47.1774,Werdenberg,ADM2,DISTRICT
SM248472,142.447,-8.4219,Were,LANGUAGE,LANGUAGE
SM248477,146.914,-7.7183,Weri,LANGUAGE,LANGUAGE
SM258579,-5.98,33.31,Werigha,ETHNICITY,ETHNICITY
SM248480,124.932,-8.3394,Wersing,LANGUAGE,LANGUAGE
SM249248,19.15,-23.59,West !Xoon,LANGUAGE,LANGUAGE
SM461535,20.2727,-23.6399,West !Xoon,ETHNICITY,ETHNICITY
SM461535,20.2727,-23.6399,West !Xoon,ETHNICITY,ETHNICITY
SM461535,22.4159,-23.0146,West !Xoon,ETHNICITY,ETHNICITY
SM461535,22.4159,-23.0146,West !Xoon,ETHNICITY,ETHNICITY
SM11450,-0.6667,5.8667,West Akim (2012-),ADM2,DISTRICT
SM248636,167.807,-15.4281,West Ambae,LANGUAGE,LANGUAGE
SM261600,133.3793,-12.3872,West Arnhem,ADM2,DISTRICT
SM261329,116.686,-33.4406,West Arthur,ADM2,DISTRICT
SM3734,45,37.6667,West Azarbaijan,ADM1,DISTRICT
SM295450,160.7739,-8.5651,West Baegut-Fatale,ADM2,DISTRICT
SM3735,26,8.15,West Bahr-al-Ghazal,ADM1,DISTRICT
SM3736,35.25,31.6667,West Bank,ADM1:ADM2,DISTRICT
SM3736,35.25,31.6667,West Bank,ADM1:ADM2,DISTRICT
SM3736,35.1864,32.1058,West Bank,ADM1:ADM2,DISTRICT
SM3736,35.1864,32.1058,West Bank,ADM1:ADM2,DISTRICT
SM324094,-113.5654,54.7546,West Baptiste,ADM2:ADM3,DISTRICT
SM301165,-91.3127,30.4634,West Baton Rouge,ADM2,DISTRICT
SM3737,-81.3887,19.3804,West Bay,ADM1,DISTRICT
SM280904,35.7429,33.6316,West Bekaa,ADM2,DISTRICT
SM3738,88,24,West Bengal,ADM1,DISTRICT
SM248680,114.528,3.9815,West Berawan,LANGUAGE,LANGUAGE
SM274595,-1.25,51.4167,West Berkshire,ADM1:ADM2,DISTRICT
SM15180,5.2094,51.8616,West Betuwe,ADM2,DISTRICT
SM301166,-91.4567,32.7886,West Carroll,ADM2,DISTRICT
SM248594,24.1012,6.8144,West Central Banda,LANGUAGE,LANGUAGE
SM248771,36.7365,8.8139,West Central Oromo,LANGUAGE,LANGUAGE
SM305147,18.627,-32.0951,West Coast,ADM2,DISTRICT
SM261775,145.5226,-42.4525,West Coast,ADM2,DISTRICT
SM3739,171.2208,-42.4566,West Coast,ADM1,DISTRICT
SM248677,116.124,6.0063,West Coast Bajau,LANGUAGE,LANGUAGE
SM324095,-114.4943,53.6996,West Cove,ADM2:ADM3,DISTRICT
SM12827,-76.8866,17.9789,West Cumberland,ADM2,DISTRICT
SM9738,130.0036,-14.0867,West Daly,ADM2,DISTRICT
SM248666,128.585,-7.1207,West Damar,LANGUAGE,LANGUAGE
SM3740,22.6667,13.4167,West Darfur (2012-),ADM1,DISTRICT
SM5864,77.1007,28.6566,West Delhi,ADM2,DISTRICT
SM12828,-76.797,17.9729,West Down Town,ADM2,DISTRICT
SM274737,-4.5333,55.9667,West Dunbartonshire,ADM1:ADM2,DISTRICT
SM303911,-65.0124,18.3552,West End,ADM2,DISTRICT
SM4232,-63.1511,18.1714,West End,ADM1,DISTRICT
SM3741,28.5,5.5,West Equatoria,ADM1:ADM2,DISTRICT
SM3741,28.5,5.5,West Equatoria,ADM1:ADM2,DISTRICT
SM3741,28.4,5.4,West Equatoria,ADM1:ADM2,DISTRICT
SM3741,28.4,5.4,West Equatoria,ADM1:ADM2,DISTRICT
SM301167,-91.42,30.8798,West Feliciana,ADM2,DISTRICT
SM277086,90.2245,25.5679,West Garo Hills,ADM2,DISTRICT
SM295305,160.1398,-9.0462,West Gela,ADM2,DISTRICT
SM295368,160.1443,-9.4602,West Ghaobata,ADM2,DISTRICT
SM295466,159.7792,-11.2938,West Ghongau,ADM2,DISTRICT
SM277022,81.1667,17,West Godavari,ADM2,DISTRICT
SM4378,-1.8167,9.0833,West Gonja (2012-),ADM2,DISTRICT
SM3742,-78.6006,26.5933,West Grand Bahama,ADM1,DISTRICT
SM275156,21.2915,38.488,West Greece,ADM1:ADM2,DISTRICT
SM12829,-77.9176,18.4624,West Green,ADM2,DISTRICT
SM248865,-50.8458,68.9635,West Greenlandic Eskimo Pidgin,LANGUAGE,LANGUAGE
SM262560,17.4239,43.4003,West Herzegovina,ADM1:ADM2,DISTRICT
SM233086,156.307,56.0462,West Itelmen,LANGUAGE,LANGUAGE
SM5810,92.25,25.5,West Jaintia Hills,ADM2,DISTRICT
SM277204,92.35,27.4,West Kameng,ADM2,DISTRICT
SM11700,92.8388,25.8899,West Karbi Anglong,ADM2,DISTRICT
SM3743,50.6667,49.8333,West Kazakhstan,ADM1:ADMX,DISTRICT
SM248709,143.73,-6.321,West Kewa,LANGUAGE,LANGUAGE
SM277078,91.2696,25.5478,West Khasi Hills (2012-),ADM2,DISTRICT
SM3744,28.3167,11.8333,West Kurdufan,ADM1,DISTRICT
SM248659,123.298,-8.4967,West Lembata,LANGUAGE,LANGUAGE
SM274738,-3.5,55.9167,West Lothian,ADM1:ADM2,DISTRICT
SM285817,5.5107,51.8449,West Maas en Waal,ADM2,DISTRICT
SM275151,21.3245,40.267,West Macedonia,ADM1:ADM2,DISTRICT
SM248710,127.63,0.4533,West Makian,LANGUAGE,LANGUAGE
SM243537,44.32,-20.28,West Malagasy Sakalava,LANGUAGE,LANGUAGE
SM4361,-0.8,10.35,West Mamprusi (2012-),ADM2,DISTRICT
SM248654,129.833,-8.1804,West Masela,LANGUAGE,LANGUAGE
SM3745,149.8261,-5.8783,West New Britain,ADM1,DISTRICT
SM277014,75.6103,21.8231,West Nimar (1998-),ADM2,DISTRICT
SM248862,33.99,0.08,West Nyala,LANGUAGE,LANGUAGE
SM2670,134.0612,-0.8653,West Papua,ADM1,DISTRICT
SM11742,103.9398,-5.1932,West Pesisir Regency,ADM2,DISTRICT
SM3746,35.278,1.6359,West Pokot,ADM1:ADM2,DISTRICT
SM3746,35.278,1.6359,West Pokot,ADM1:ADM2,DISTRICT
SM3746,35.25,1.75,West Pokot,ADM1:ADM2,DISTRICT
SM3746,35.25,1.75,West Pokot,ADM1:ADM2,DISTRICT
SM305113,27.5554,-26.2457,West Rand,ADM2,DISTRICT
SM268988,7.8,46.3,West Raron,ADM2,DISTRICT
SM11701,94.55,28.4,West Siang (2017-),ADM2,DISTRICT
SM277217,88.25,27.3333,West Sikkim,ADM2,DISTRICT
SM327848,-97.1628,49.9994,West St. Paul,ADM2:ADM3,DISTRICT
SM274597,-0.4167,51,West Sussex,ADM1:ADM2,DISTRICT
SM261777,146.8912,-41.2644,West Tamar,ADM2,DISTRICT
SM248657,134.168,-6.6205,West Tarangan,LANGUAGE,LANGUAGE
SM295458,160.3887,-11.7081,West Te Nggano,ADM2,DISTRICT
SM261746,138.5509,-34.9313,West Torrens,ADM2,DISTRICT
SM277267,91.5,23.9167,West Tripura (2012-),ADM2,DISTRICT
SM295416,161.9621,-9.7779,West Ulawa,ADM2,DISTRICT
SM248639,166.589,-20.4145,West Uvean,LANGUAGE,LANGUAGE
SM3747,-80.5001,38.5004,West Virginia,ADM1,DISTRICT
SM261286,141.4004,-36.5992,West Wimmera,ADM2,DISTRICT
SM248547,99.3112,39.0548,West Yugur,LANGUAGE,LANGUAGE
SM248588,-12.3138,9.5239,West-Central Limba,LANGUAGE,LANGUAGE
SM262097,3,51,West-Vlaanderen,ADM1:ADM2,DISTRICT
SM17706,-60.9531,13.7271,Westall Group/The Mangue,ADM2,DISTRICT
SM301924,-73.7534,41.1515,Westchester,ADM2,DISTRICT
SM296083,-57.049,5.9016,Westelijke Polders,ADM2,DISTRICT
SM15181,6.3,53.2,Westerkwartier,ADM2,DISTRICT
SM293669,37.4689,55.7503,Western,ADM2,DISTRICT
SM3751,-16.35,13.2333,Western,ADM1,DISTRICT
SM3749,177.5,-18,Western,ADM1,DISTRICT
SM3748,-170.7667,-14.3167,Western,ADM1,DISTRICT
SM3753,157,-8,Western,ADM1,DISTRICT
SM3755,24,-15,Western,ADM1,DISTRICT
SM3752,142,-7.5,Western,ADM1,DISTRICT
SM3754,-13.1167,8.3333,Western,ADM1:ADMX,DISTRICT
SM248737,-72.6511,46.1381,Western Abenaki,LANGUAGE,LANGUAGE
SM228692,2.199,36.5917,Western Algerian Berber,LANGUAGE,LANGUAGE
SM248725,-110.123,33.8574,Western Apache,LANGUAGE,LANGUAGE
SM232445,44.4839,40.1897,Western Armenian,LANGUAGE,LANGUAGE
SM248581,133.2462,-24.0505,Western Arrarnta,LANGUAGE,LANGUAGE
SM3756,122,-25,Western Australia,ADM1,DISTRICT
SM248518,62.17,37.83,Western Balochi,LANGUAGE,LANGUAGE
SM286534,176.1612,-37.7739,Western Bay of Plenty,ADM2,DISTRICT
SM248747,-64.1662,-20.2819,Western Bolivian Guaraní,LANGUAGE,LANGUAGE
SM248542,104.754,16.4906,Western Bru,LANGUAGE,LANGUAGE
SM248672,124.825,7.6682,Western Bukidnon Manobo,LANGUAGE,LANGUAGE
SM248727,-96.148,64.3486,Western Canadian Inuktitut,LANGUAGE,LANGUAGE
SM3758,20,-34,Western Cape,ADM1,DISTRICT
SM248755,104.026,12.4771,Western Cham,LANGUAGE,LANGUAGE
SM248498,145.2045,-41.8859,Western Coastal Tasmanian,LANGUAGE,LANGUAGE
SM248705,138.217,-3.7713,Western Dani,LANGUAGE,LANGUAGE
SM255923,-117.1,32.7,Western Diegueno,ETHNICITY,ETHNICITY
SM261674,150.3859,-26.8067,Western Downs,ADM2,DISTRICT
SM248785,-104.9,22.6,Western Durango Nahuatl,LANGUAGE,LANGUAGE
SM248519,53.3,32.9,Western Farsi,LANGUAGE,LANGUAGE
SM248641,177.657,-17.8355,Western Fijian,LANGUAGE,LANGUAGE
SM248030,3.1135,51.0754,Western Flemish,LANGUAGE,LANGUAGE
SM248510,5.8609,53.143,Western Frisian,LANGUAGE,LANGUAGE
SM248558,86.2899,27.4169,Western Gurung,DIALECT,LANGUAGE
SM248750,-97.3244,16.2495,Western Highland Chatino,LANGUAGE,LANGUAGE
SM248738,-102.154,19.5392,Western Highland Purepecha,LANGUAGE,LANGUAGE
SM3760,144.25,-5.75,Western Highlands,ADM1,DISTRICT
SM248731,-98.7711,21.3711,Western Huasteca Nahuatl,LANGUAGE,LANGUAGE
SM248784,-88.04,15.427,Western Jicaque,LANGUAGE,LANGUAGE
SM248749,-98.2096,17.3003,Western Juxtlahuaca Mixtec,LANGUAGE,LANGUAGE
SM248601,-4.7648,10.6298,Western Karaboro,LANGUAGE,LANGUAGE
SM248522,70.3474,35.4326,Western Kativiri,DIALECT,LANGUAGE
SM248543,107.166,15.7949,Western Katu,LANGUAGE,LANGUAGE
SM248554,97.4877,18.687,Western Kayah,LANGUAGE,LANGUAGE
SM248739,-107.517,34.9733,Western Keres,LANGUAGE,LANGUAGE
SM248616,-8.4095,6.1442,Western Krahn,LANGUAGE,LANGUAGE
SM248491,98.9,24.73,Western Lalu,LANGUAGE,LANGUAGE
SM248541,99.2057,19.8678,Western Lawa,LANGUAGE,LANGUAGE
SM236552,114.82,3.8523,Western Lowland Kenyah,LANGUAGE,LANGUAGE
SM248562,83.511,28.2462,Western Magar,LANGUAGE,LANGUAGE
SM239815,11.3161,6.6974,Western Mambila,LANGUAGE,LANGUAGE
SM248626,-12.8009,13.5677,Western Maninkakan,LANGUAGE,LANGUAGE
SM248539,46.569,56.22,Western Mari,LANGUAGE,LANGUAGE
SM248492,106.122,25.6518,Western Mashan Hmong,LANGUAGE,LANGUAGE
SM248566,87.2893,27.4265,Western Meohang,LANGUAGE,LANGUAGE
SM458127,131.4546,-16.7674,Western Mudburra,ETHNICITY,ETHNICITY
SM248553,80.7006,20.2384,Western Muria,LANGUAGE,LANGUAGE
SM248561,101.418,29.444,Western Muya,LANGUAGE,LANGUAGE
SM248778,36.484,33.8162,Western Neo-Aramaic,LANGUAGE,LANGUAGE
SM248592,-0.52,14.2471,Western Niger Fulfulde,LANGUAGE,LANGUAGE
SM3938,-2.8,6.3,Western North,ADM1,DISTRICT
SM248494,-102.525,50.5664,Western Ojibwa,LANGUAGE,LANGUAGE
SM236351,109.6917,19.8855,Western Ong-Be,LANGUAGE,LANGUAGE
SM248533,75.3474,30.0948,Western Panjabi,LANGUAGE,LANGUAGE
SM235909,124.057,-8.5279,Western Pantar,LANGUAGE,LANGUAGE
SM248564,82.7348,28.6521,Western Parbate Kham,LANGUAGE,LANGUAGE
SM248679,114.523,3.4667,Western Penan,LANGUAGE,LANGUAGE
SM18567,148.8367,-32.4745,Western Plains Regional,ADM2,DISTRICT
SM4206,80.0833,6.8333,Western Province-Sri Lanka,ADM1,DISTRICT
SM248874,93.1365,27.6809,Western Puroik,LANGUAGE,LANGUAGE
SM3939,-2.25,5.45,Western Region (2019-),ADM1,DISTRICT
SM4218,30.5557,-0.6118,Western Region-Uganda,ADM1,DISTRICT
SM4697,82.429,28.7566,Western Rukum,ADM2,DISTRICT
SM295496,-13.0948,8.3232,Western Rural,ADM1:ADM2,DISTRICT
SM248600,-2.3642,10.8561,Western Sisaala,LANGUAGE,LANGUAGE
SM248776,53.384,12.548,Western Soqotri,DIALECT,LANGUAGE
SM248674,122.341,7.7996,Western Subanon,LANGUAGE,LANGUAGE
SM248559,85.0563,28.0239,Western Tamang,LANGUAGE,LANGUAGE
SM248676,121.026,12.8227,Western Tawbuid,LANGUAGE,LANGUAGE
SM248495,-97.9486,20.4574,Western Totonac,LANGUAGE,LANGUAGE
SM248756,-72.6507,6.907,Western Tunebo,LANGUAGE,LANGUAGE
SM295497,-13.2662,8.4536,Western Urban,ADM1:ADM2,DISTRICT
SM248583,143.6694,-34.1383,Western Victoria,LANGUAGE,LANGUAGE
SM4196,122.53,11.13,Western Visayas,ADM1,DISTRICT
SM248575,124.6675,-16.1094,Western Worrorran,FAMILY,LANGUAGE
SM248572,109.67,28.33,Western Xiangxi Miao,LANGUAGE,LANGUAGE
SM248593,2.039,6.3346,Western Xwla Gbe,LANGUAGE,LANGUAGE
SM248516,12.2601,51.6889,Western Yiddish,LANGUAGE,LANGUAGE
SM228958,4.4856,9.8846,Western-Kambari-Cishingini,LANGUAGE,LANGUAGE
SM285549,6.2906,52.827,Westerveld,ADM2,DISTRICT
SM285818,5.9744,51.9595,Westervoort,ADM2,DISTRICT
SM15182,7.1567,53.028,Westerwolde,ADM2,DISTRICT
SM10044,-51.7562,-29.423,Westfalia,ADM2:ADM3,DISTRICT
SM286528,170,-43.5,Westland,ADM2,DISTRICT
SM324096,-113.8442,54.1557,Westlock,ADM2:ADM3,DISTRICT
SM324097,-113.8515,54.2535,Westlock County,ADM2:ADM3,DISTRICT
SM3761,-7.5,53.5,Westmeath,ADM1:ADM2,DISTRICT
SM302968,-76.7999,38.1128,Westmoreland,ADM2,DISTRICT
SM302346,-79.467,40.3107,Westmoreland,ADM2,DISTRICT
SM3762,-78.15,18.2333,Westmoreland,ADM1,DISTRICT
SM268799,-64.6655,46.0002,Westmorland,ADM2,DISTRICT
SM303310,-104.5678,43.8405,Weston,ADM2,DISTRICT
SM261331,118.7297,-30.8844,Westonia,ADM2,DISTRICT
SM248511,7.499,51.459,Westphalic,LANGUAGE,LANGUAGE
SM285748,6.02,52.8765,Weststellingwerf,ADM2,DISTRICT
SM285714,4.0892,51.8931,Westvoorne,ADM2,DISTRICT
SM258581,126.42,-7.8,Wetar,ETHNICITY,ETHNICITY
SM324035,-113.3784,52.9721,Wetaskiwin,ADM2:ADM3,DISTRICT
SM299143,39.7619,-5.0912,Wete,ADM2,DISTRICT
SM303155,-80.6391,39.6053,Wetzel,ADM2,DISTRICT
SM288629,143.5,-3.5833,Wewak,ADM2,DISTRICT
SM248964,96.5405,19.0206,Wewaw,LANGUAGE,LANGUAGE
SM248448,119.349,-9.551,Wewewa,LANGUAGE,LANGUAGE
SM301336,-85.5784,44.3384,Wexford,ADM2,DISTRICT
SM3763,-6.6667,52.5,Wexford,ADM1:ADM2,DISTRICT
SM248969,35.6337,11.9415,Weyto,LANGUAGE,LANGUAGE
SM258582,116.39,-32.2,Whadjug,ETHNICITY,ETHNICITY
SM258582,116.39,-32.2,Whadjug,ETHNICITY,ETHNICITY
SM258582,116.1239,-31.9815,Whadjug,ETHNICITY,ETHNICITY
SM258582,116.1239,-31.9815,Whadjug,ETHNICITY,ETHNICITY
SM286535,176.7824,-38.3244,Whakatane,ADM2,DISTRICT
SM286558,175.1408,-39.6355,Whanganui,ADM2,DISTRICT
SM286491,174.3333,-35.7333,Whangarei,ADM2,DISTRICT
SM302797,-96.2221,29.2779,Wharton,ADM2,DISTRICT
SM303099,-121.8728,48.8298,Whatcom,ADM2,DISTRICT
SM301671,-109.8444,46.4663,Wheatland,ADM2,DISTRICT
SM10171,-112.9366,51.067,Wheatland County,ADM2,DISTRICT
SM302798,-100.2697,35.4013,Wheeler,ADM2,DISTRICT
SM302277,-120.0275,44.7261,Wheeler,ADM2,DISTRICT
SM301769,-98.5282,41.9148,Wheeler,ADM2,DISTRICT
SM300440,-82.7246,32.1171,Wheeler,ADM2,DISTRICT
SM12830,-76.2962,17.9587,Wheelerfield,ADM2,DISTRICT
SM258583,-123.72,40.88,Whilkut,ETHNICITY,ETHNICITY
SM324098,-113.5483,54.7597,Whispering Hills,ADM2:ADM3,DISTRICT
SM302636,-85.4552,35.9264,White,ADM2,DISTRICT
SM300689,-88.1796,38.0875,White,ADM2,DISTRICT
SM300782,-86.8655,40.7498,White,ADM2,DISTRICT
SM302422,-91.7456,35.2563,White,ADM2,DISTRICT
SM300441,-83.7471,34.6464,White,ADM2,DISTRICT
SM12831,-76.4311,17.9981,White Hall,ADM2,DISTRICT
SM12832,-77.8107,18.1565,White Hill,ADM2,DISTRICT
SM12833,-76.4708,17.8962,White Horses,ADM2,DISTRICT
SM258584,-117,41,White Knife Shoshoni,ETHNICITY,ETHNICITY
SM258584,-117,41,White Knife Shoshoni,ETHNICITY,ETHNICITY
SM258584,-117,41,White Knife Shoshoni,ETHNICITY,ETHNICITY
SM258584,-117.31,41,White Knife Shoshoni,ETHNICITY,ETHNICITY
SM258584,-117.31,41,White Knife Shoshoni,ETHNICITY,ETHNICITY
SM258584,-117.31,41,White Knife Shoshoni,ETHNICITY,ETHNICITY
SM258584,-117,40.8,White Knife Shoshoni,ETHNICITY,ETHNICITY
SM258584,-117,40.8,White Knife Shoshoni,ETHNICITY,ETHNICITY
SM258584,-117,40.8,White Knife Shoshoni,ETHNICITY,ETHNICITY
SM248974,104.66,22.726,White Lachi,LANGUAGE,LANGUAGE
SM3764,32.4,13.5,White Nile,ADM1,DISTRICT
SM301779,-114.9016,39.4422,White Pine,ADM2,DISTRICT
SM323949,-112.8116,52.4691,White Sands,ADM2:ADM3,DISTRICT
SM324100,-115.6571,54.1364,Whitecourt,ADM2:ADM3,DISTRICT
SM12834,-76.8058,18.0384,Whitehall,ADM2,DISTRICT
SM327809,-100.2348,49.7794,Whitehead,ADM2:ADM3,DISTRICT
SM261287,145.1543,-37.8287,Whitehorse,ADM2,DISTRICT
SM12835,-77.957,18.1028,Whitehouse,ADM2,DISTRICT
SM327664,-95.9575,49.9747,Whitemouth,ADM2:ADM3,DISTRICT
SM248977,169.381,-19.5019,Whitesands,LANGUAGE,LANGUAGE
SM300690,-89.9141,41.7563,Whiteside,ADM2,DISTRICT
SM300442,-84.9672,34.8056,Whitfield,ADM2,DISTRICT
SM12836,-76.8091,17.9921,Whitfield Town,ADM2,DISTRICT
SM12837,-78.0381,18.2711,Whithorn,ADM2,DISTRICT
SM301009,-84.1452,36.7581,Whitley,ADM2,DISTRICT
SM300783,-85.5051,41.1394,Whitley,ADM2,DISTRICT
SM303100,-117.523,46.9012,Whitman,ADM2,DISTRICT
SM261676,148.0135,-20.6978,Whitsunday,ADM2,DISTRICT
SM261288,145.0938,-37.5509,Whittlesea,ADM2,DISTRICT
SM261747,137.5447,-33.0457,Whyalla,ADM2,DISTRICT
SM258615,145.96,-34.4,Wi-Thai-Ja,ETHNICITY,ETHNICITY
SM258615,145.96,-34.4,Wi-Thai-Ja,ETHNICITY,ETHNICITY
SM258615,151.0501,-29.7574,Wi-Thai-Ja,ETHNICITY,ETHNICITY
SM258615,151.0501,-29.7574,Wi-Thai-Ja,ETHNICITY,ETHNICITY
SM296903,100.0006,19.8636,Wiang Chai,ADM2,DISTRICT
SM296888,98.6585,19.5919,Wiang Haeng,ADM2,DISTRICT
SM296993,102.2919,16.7014,Wiang Kao,ADM2,DISTRICT
SM296904,99.4493,19.3046,Wiang Pa Pao,ADM2,DISTRICT
SM297238,100.7275,18.5509,Wiang Sa,ADM2,DISTRICT
SM297628,99.3528,8.5981,Wiang Sa,ADM2,DISTRICT
SM248981,143.756,-3.6341,Wiarumus,LANGUAGE,LANGUAGE
SM301672,-104.249,46.9654,Wibaux,ADM2,DISTRICT
SM258591,-63.34,-24,Wichi,ETHNICITY,ETHNICITY
SM258591,-63.34,-24,Wichi,ETHNICITY,ETHNICITY
SM258591,-63.34,-24,Wichi,ETHNICITY,ETHNICITY
SM258591,-63,-23,Wichi,ETHNICITY,ETHNICITY
SM258591,-63,-23,Wichi,ETHNICITY,ETHNICITY
SM258591,-63,-23,Wichi,ETHNICITY,ETHNICITY
SM258591,-63,-24,Wichi,ETHNICITY,ETHNICITY
SM258591,-63,-24,Wichi,ETHNICITY,ETHNICITY
SM258591,-63,-24,Wichi,ETHNICITY,ETHNICITY
SM297339,101.1,15.65,Wichian Buri,ADM2,DISTRICT
SM248984,-98.1831,35.0665,Wichita,LANGUAGE,LANGUAGE
SM302799,-98.7036,33.988,Wichita,ADM2,DISTRICT
SM300887,-101.3474,38.4821,Wichita,ADM2,DISTRICT
SM258592,-96.13,38.04,Wichita,ETHNICITY,ETHNICITY
SM258592,-96.13,38.04,Wichita,ETHNICITY,ETHNICITY
SM258592,-98,34,Wichita,ETHNICITY,ETHNICITY
SM258592,-98,34,Wichita,ETHNICITY,ETHNICITY
SM248988,-62.1757,-23.1633,Wichí Lhamtés Güisnay,LANGUAGE,LANGUAGE
SM248986,-63.5971,-21.988,Wichí Lhamtés Nocten,LANGUAGE,LANGUAGE
SM248987,-61.9117,-24.2189,Wichí Lhamtés Vejoz,LANGUAGE,LANGUAGE
SM261332,117.64,-32.8221,Wickepin,ADM2,DISTRICT
SM3765,-6.4167,53,Wicklow,ADM1:ADM2,DISTRICT
SM301228,-75.6315,38.3694,Wicomico,ADM2,DISTRICT
SM458076,120.6394,-21.328,Widagari,ETHNICITY,ETHNICITY
SM258593,118.32,-29.46,Widi,ETHNICITY,ETHNICITY
SM258593,118.32,-29.46,Widi,ETHNICITY,ETHNICITY
SM258593,116.7215,-28.8268,Widi,ETHNICITY,ETHNICITY
SM258593,116.7215,-28.8268,Widi,ETHNICITY,ETHNICITY
SM252127,10,6,Widikum,ETHNICITY,ETHNICITY
SM283449,14.5592,35.8606,Wied il-Għajn,ADM1:ADM2,DISTRICT
SM288943,20.0549,49.9857,Wieliczka,ADM2,DISTRICT
SM3766,17.2,52.3,Wielkopolskie,ADM1,DISTRICT
SM288880,18.6303,51.2326,Wieluń,ADM2,DISTRICT
SM261879,16.3734,48.2066,Wien,ADM2,DISTRICT
SM3767,16.3717,48.2082,Wien,ADM1,DISTRICT
SM261819,16.25,47.8,Wiener Neustadt,ADM2,DISTRICT
SM261818,16.1429,47.832,Wiener Neustadt Land,ADM2,DISTRICT
SM296905,100.4864,20.0087,Wieng Kaen,ADM2,DISTRICT
SM285574,6.558,52.3425,Wierden,ADM2,DISTRICT
SM288881,18.3185,51.2942,Wieruszów,ADM2,DISTRICT
SM274598,-2.5833,53.5,Wigan,ADM1:ADM2,DISTRICT
SM297538,100.9646,14.3344,Wihan Daeng,ADM2,DISTRICT
SM258594,141.82,-34.28,Wiimbaio,ETHNICITY,ETHNICITY
SM258594,141.82,-34.28,Wiimbaio,ETHNICITY,ETHNICITY
SM258594,141.4486,-33.6664,Wiimbaio,ETHNICITY,ETHNICITY
SM258594,141.4486,-33.6664,Wiimbaio,ETHNICITY,ETHNICITY
SM285819,5.6986,51.8188,Wijchen,ADM2,DISTRICT
SM286024,5.0845,52.2276,Wijdemeren,ADM2,DISTRICT
SM285607,5.3366,51.9745,Wijk bij Duurstede,ADM2,DISTRICT
SM459403,141.6108,-13.4928,Wik Adinda,ETHNICITY,ETHNICITY
SM459406,141.5995,-13.7174,Wik Elken,ETHNICITY,ETHNICITY
SM459410,141.7888,-13.8954,Wik Epa,ETHNICITY,ETHNICITY
SM459246,141.9375,-14.4299,Wik Iyanh,ETHNICITY,ETHNICITY
SM459411,141.9278,-14.0118,Wik Me'anh,ETHNICITY,ETHNICITY
SM459412,141.6479,-14.0643,Wik Ngathan,ETHNICITY,ETHNICITY
SM459409,141.6079,-13.7539,Wik Ngatharr,ETHNICITY,ETHNICITY
SM459405,142.0863,-13.3442,Wik Ompom,ETHNICITY,ETHNICITY
SM459404,141.6733,-13.3456,Wik Paach,ETHNICITY,ETHNICITY
SM248998,141.858,-13.846,Wik-Epa,LANGUAGE,LANGUAGE
SM248999,142.781,-14.1273,Wik-Iiyanh,LANGUAGE,LANGUAGE
SM249000,142.005,-14.2649,Wik-Keyangan,LANGUAGE,LANGUAGE
SM459247,141.9054,-14.1895,Wik-Keyangan,ETHNICITY,ETHNICITY
SM249001,141.925,-14.058,Wik-Me'anha,LANGUAGE,LANGUAGE
SM249002,141.8161,-13.9805,Wik-Mungkan,LANGUAGE,LANGUAGE
SM249004,141.532,-13.8691,Wik-Ngathana,LANGUAGE,LANGUAGE
SM248995,141.557,-13.6835,Wikalkan,LANGUAGE,LANGUAGE
SM258595,142.2,-12.96,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142.2,-12.96,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142.2,-12.96,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142.2,-12.96,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142,-14,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142,-14,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142,-14,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142,-14,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142,-13.47,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142,-13.47,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142,-13.47,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142,-13.47,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142.3263,-13.7227,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142.3263,-13.7227,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142.3263,-13.7227,Wikmunkan,ETHNICITY,ETHNICITY
SM258595,142.3263,-13.7227,Wikmunkan,ETHNICITY,ETHNICITY
SM249005,141.599,-13.4957,Wikngenchera,LANGUAGE,LANGUAGE
SM268941,9.1245,47.4259,Wil,ADM2,DISTRICT
SM249006,126.6,-14.9105,Wilawila,LANGUAGE,LANGUAGE
SM458631,127.0231,-15.078,Wilawila,ETHNICITY,ETHNICITY
SM302800,-99.2411,34.0807,Wilbarger,ADM2,DISTRICT
SM300443,-83.4324,31.9729,Wilcox,ADM2,DISTRICT
SM300308,-87.3082,31.9892,Wilcox,ADM2,DISTRICT
SM459026,135.9307,-15.7086,Wilingura,ETHNICITY,ETHNICITY
SM302027,-81.1629,36.2062,Wilkes,ADM2,DISTRICT
SM300444,-82.7432,33.782,Wilkes,ADM2,DISTRICT
SM301421,-96.4684,46.3571,Wilkin,ADM2,DISTRICT
SM301502,-91.3109,31.1611,Wilkinson,ADM2,DISTRICT
SM300445,-83.1713,32.8024,Wilkinson,ADM2,DISTRICT
SM300590,-87.9787,41.445,Will,ADM2,DISTRICT
SM302801,-97.5918,26.477,Willacy,ADM2,DISTRICT
SM302168,-84.5881,41.5603,Williams,ADM2,DISTRICT
SM302078,-103.4802,48.3437,Williams,ADM2,DISTRICT
SM261333,116.7094,-33.0522,Williams,ADM2,DISTRICT
SM302969,-76.7075,37.2707,Williamsburg,ADM2,DISTRICT
SM302471,-79.7277,33.6199,Williamsburg,ADM2,DISTRICT
SM18481,-77.4658,18.0666,Williamsfield (Manchester),ADM2,DISTRICT
SM12838,-78.0696,18.3339,Williamsfield (Westmoreland),ADM2,DISTRICT
SM302802,-97.6008,30.648,Williamson,ADM2,DISTRICT
SM302637,-86.8986,35.8938,Williamson,ADM2,DISTRICT
SM300591,-88.9299,37.7303,Williamson,ADM2,DISTRICT
SM323788,-112.1181,53.8283,Willingdon,ADM2:ADM3,DISTRICT
SM268925,7.9593,47.141,Willisau,ADM2,DISTRICT
SM261491,151.1878,-33.8017,Willoughby,ADM2,DISTRICT
SM323857,-113.6423,49.9608,Willow Creek No. 26,ADM2:ADM3,DISTRICT
SM12839,-76.982,17.9919,Willowdene,ADM2,DISTRICT
SM258597,118.26,-33.01,Wilmen,ETHNICITY,ETHNICITY
SM258597,118.26,-33.01,Wilmen,ETHNICITY,ETHNICITY
SM258597,117.1282,-33.143,Wilmen,ETHNICITY,ETHNICITY
SM258597,117.1282,-33.143,Wilmen,ETHNICITY,ETHNICITY
SM12840,-76.3896,17.9286,Wilmington,ADM2,DISTRICT
SM302803,-98.0866,29.174,Wilson,ADM2,DISTRICT
SM302638,-86.2976,36.1549,Wilson,ADM2,DISTRICT
SM302028,-77.9186,35.705,Wilson,ADM2,DISTRICT
SM300888,-95.7434,37.5592,Wilson,ADM2,DISTRICT
SM12841,-77.6932,18.2847,Wilson's Run,ADM2,DISTRICT
SM12842,-76.8042,17.9829,Wilton Gardens/ Rema,ADM2,DISTRICT
SM17707,-60.9864,14.0097,Wilton'S Yard/Grave Yard,ADM2,DISTRICT
SM274599,-1.9167,51.25,Wiltshire,ADM1:ADM2,DISTRICT
SM281369,5.907,49.947,Wiltz,ADM1:ADM2,DISTRICT
SM9427,5.9398,49.9789,Wiltz (2015-),ADM1:ADM2,DISTRICT
SM261334,121.9324,-25.3663,Wiluna,ADM2,DISTRICT
SM258598,142.61,-30.37,Wilya,ETHNICITY,ETHNICITY
SM258598,142.61,-30.37,Wilya,ETHNICITY,ETHNICITY
SM258598,140.9902,-31.948,Wilya,ETHNICITY,ETHNICITY
SM258598,140.9902,-31.948,Wilya,ETHNICITY,ETHNICITY
SM458045,120.4157,-26.7996,Wilyara,ETHNICITY,ETHNICITY
SM258600,141.77,-11.64,Wimmarao,ETHNICITY,ETHNICITY
SM258600,141.77,-11.64,Wimmarao,ETHNICITY,ETHNICITY
SM258600,141.6867,-12.3799,Wimmarao,ETHNICITY,ETHNICITY
SM258600,141.6867,-12.3799,Wimmarao,ETHNICITY,ETHNICITY
SM302971,-78.1633,39.1857,Winchester,ADM2,DISTRICT
SM12843,-76.2982,17.9418,Winchester,ADM2,DISTRICT
SM370431,5.9192,50.0533,Wincrange,ADM1:ADM2:ADM3,DISTRICT
SM258602,-109,43,Wind River Eastern Shoshone,ETHNICITY,ETHNICITY
SM258602,-109,43,Wind River Eastern Shoshone,ETHNICITY,ETHNICITY
SM258602,-108.86,43.19,Wind River Eastern Shoshone,ETHNICITY,ETHNICITY
SM258602,-108.86,43.19,Wind River Eastern Shoshone,ETHNICITY,ETHNICITY
SM302926,-72.7138,42.9906,Windham,ADM2,DISTRICT
SM303389,-71.9875,41.83,Windham,ADM2,DISTRICT
SM284523,17.1,-22.5667,Windhoek East,ADM2,DISTRICT
SM284524,17.103,-22.5658,Windhoek Rural,ADM2,DISTRICT
SM302927,-72.5862,43.58,Windsor,ADM2,DISTRICT
SM12844,-76.4463,18.1067,Windsor,ADM2,DISTRICT
SM12845,-76.6903,18.2489,Windsor Castle,ADM2,DISTRICT
SM12846,-76.3388,18.1206,Windsor Forest,ADM2,DISTRICT
SM274600,-0.6667,51.4667,Windsor and Maidenhead,ADM1:ADM2,DISTRICT
SM261492,150.3549,-34.4909,Wingecarribee,ADM2,DISTRICT
SM249021,124.5392,-15.3552,Winjarumi,LANGUAGE,LANGUAGE
SM458651,124.2594,-15.3024,Winjarumi,ETHNICITY,ETHNICITY
SM302804,-103.0482,31.8501,Winkler,ADM2,DISTRICT
SM301168,-92.6368,31.9443,Winn,ADM2,DISTRICT
SM303291,-88.6447,44.0689,Winnebago,ADM2,DISTRICT
SM300592,-89.1609,42.3363,Winnebago,ADM2,DISTRICT
SM300878,-93.7342,43.3776,Winnebago,ADM2,DISTRICT
SM300879,-91.8437,43.2907,Winneshiek,ADM2,DISTRICT
SM301422,-91.7791,43.9868,Winona,ADM2,DISTRICT
SM370458,5.8903,49.9678,Winseler,ADM1:ADM2:ADM3,DISTRICT
SM301504,-89.0344,33.0885,Winston,ADM2,DISTRICT
SM300309,-87.3737,34.1492,Winston,ADM2,DISTRICT
SM285820,6.7254,51.9723,Winterswijk,ADM2,DISTRICT
SM269011,8.769,47.5078,Winterthur,ADM2,DISTRICT
SM261677,142.662,-22.5075,Winton,ADM2,DISTRICT
SM249025,-122.445,39.7176,Wintu,LANGUAGE,LANGUAGE
SM258604,-122.03,39.86,Wintu,ETHNICITY,ETHNICITY
SM258604,-122.03,39.86,Wintu,ETHNICITY,ETHNICITY
SM258604,-122.03,39.86,Wintu,ETHNICITY,ETHNICITY
SM258604,-122,41,Wintu,ETHNICITY,ETHNICITY
SM258604,-122,41,Wintu,ETHNICITY,ETHNICITY
SM258604,-122,41,Wintu,ETHNICITY,ETHNICITY
SM258604,-122.35,40.9,Wintu,ETHNICITY,ETHNICITY
SM258604,-122.35,40.9,Wintu,ETHNICITY,ETHNICITY
SM258604,-122.35,40.9,Wintu,ETHNICITY,ETHNICITY
SM258605,-122.4,40.8,Wintu (McCloud River),ETHNICITY,ETHNICITY
SM258606,-122.4,41.2,Wintu (Sacramento River),ETHNICITY,ETHNICITY
SM258607,-123.2,40.6,Wintu (Trinity River),ETHNICITY,ETHNICITY
SM249026,-2.9752,11.8239,Winyé,LANGUAGE,LANGUAGE
SM249027,143.064,-8.7974,Wipi,LANGUAGE,LANGUAGE
SM454365,-112.2,34.6,Wipukepa Yavapai,ETHNICITY,ETHNICITY
SM249031,145.994,-34.0742,Wiradhuri,LANGUAGE,LANGUAGE
SM258608,148.46,-33.8,Wiradjura,ETHNICITY,ETHNICITY
SM258608,148.46,-33.8,Wiradjura,ETHNICITY,ETHNICITY
SM258608,147.2573,-33.9092,Wiradjura,ETHNICITY,ETHNICITY
SM258608,147.2573,-33.9092,Wiradjura,ETHNICITY,ETHNICITY
SM249033,-59.4769,-9.3054,Wiraféd,LANGUAGE,LANGUAGE
SM258609,142.04,-8.61,Wiram,ETHNICITY,ETHNICITY
SM258610,129.3,-31.27,Wiranggu,ETHNICITY,ETHNICITY
SM258610,129.3,-31.27,Wiranggu,ETHNICITY,ETHNICITY
SM258610,133.5221,-31.536,Wiranggu,ETHNICITY,ETHNICITY
SM258610,133.5221,-31.536,Wiranggu,ETHNICITY,ETHNICITY
SM249034,134,-32,Wirangu,LANGUAGE,LANGUAGE
SM249029,131.719,-31.6356,Wirangu-Nauo,LANGUAGE,LANGUAGE
SM458069,120.4708,-23.8336,Wirdinya,ETHNICITY,ETHNICITY
SM458093,131.9111,-26.3049,Wirdjaragandja,ETHNICITY,ETHNICITY
SM12847,-77.5328,18.2531,Wirefence,ADM2,DISTRICT
SM458427,148.7038,-21.2986,Wiri,ETHNICITY,ETHNICITY
SM458152,150.6214,-29.1129,Wiriyaraay,ETHNICITY,ETHNICITY
SM459095,143.0211,-38.4882,Wirngilgnad dhalinanong,ETHNICITY,ETHNICITY
SM458182,148.1971,-28.2195,Wirraay-Wirraay,ETHNICITY,ETHNICITY
SM458181,148.5865,-32.0984,Wirraayaraay,ETHNICITY,ETHNICITY
SM274601,-3.0833,53.3333,Wirral,ADM1:ADM2,DISTRICT
SM303156,-81.3786,39.0224,Wirt,ADM2,DISTRICT
SM249036,144.214,-6.3586,Wiru,LANGUAGE,LANGUAGE
SM258611,144.45,-6.51,Wiru,ETHNICITY,ETHNICITY
SM3768,-90.0004,44.5002,Wisconsin,ADM1,DISTRICT
SM302972,-82.6212,36.9752,Wise,ADM2,DISTRICT
SM302805,-97.6545,33.2159,Wise,ADM2,DISTRICT
SM297028,100.3174,14.5499,Wiset Chai Chan,ADM2,DISTRICT
SM258613,-120.89,45.22,Wishram,ETHNICITY,ETHNICITY
SM258613,-120.89,45.22,Wishram,ETHNICITY,ETHNICITY
SM258613,-120.89,45.22,Wishram,ETHNICITY,ETHNICITY
SM258613,-120.89,45.22,Wishram,ETHNICITY,ETHNICITY
SM258613,-121,46,Wishram,ETHNICITY,ETHNICITY
SM258613,-121,46,Wishram,ETHNICITY,ETHNICITY
SM258613,-121,46,Wishram,ETHNICITY,ETHNICITY
SM258613,-121,46,Wishram,ETHNICITY,ETHNICITY
SM258613,-121.13,45.72,Wishram,ETHNICITY,ETHNICITY
SM258613,-121.13,45.72,Wishram,ETHNICITY,ETHNICITY
SM258613,-121.13,45.72,Wishram,ETHNICITY,ETHNICITY
SM258613,-121.13,45.72,Wishram,ETHNICITY,ETHNICITY
SM258613,-121.2,45.5,Wishram,ETHNICITY,ETHNICITY
SM258613,-121.2,45.5,Wishram,ETHNICITY,ETHNICITY
SM258613,-121.2,45.5,Wishram,ETHNICITY,ETHNICITY
SM258613,-121.2,45.5,Wishram,ETHNICITY,ETHNICITY
SM258614,18.38,-22.32,Witboois,ETHNICITY,ETHNICITY
SM458103,116.2101,-31.756,Witjaari,ETHNICITY,ETHNICITY
SM266349,-49.8559,-26.9501,Witmarsum,ADM2,DISTRICT
SM258616,-72.36,1.46,Witoto,ETHNICITY,ETHNICITY
SM258616,-72.36,1.46,Witoto,ETHNICITY,ETHNICITY
SM258616,-72.36,1.46,Witoto,ETHNICITY,ETHNICITY
SM258616,-71.45,-1.09,Witoto,ETHNICITY,ETHNICITY
SM258616,-71.45,-1.09,Witoto,ETHNICITY,ETHNICITY
SM258616,-71.45,-1.09,Witoto,ETHNICITY,ETHNICITY
SM258616,-73,-1,Witoto,ETHNICITY,ETHNICITY
SM258616,-73,-1,Witoto,ETHNICITY,ETHNICITY
SM258616,-73,-1,Witoto,ETHNICITY,ETHNICITY
SM258616,-74,-1,Witoto,ETHNICITY,ETHNICITY
SM258616,-74,-1,Witoto,ETHNICITY,ETHNICITY
SM258616,-74,-1,Witoto,ETHNICITY,ETHNICITY
SM226132,-126.41,55.0997,Witsuwit'en-Babine,LANGUAGE,LANGUAGE
SM15207,-85.8196,13.6125,Wiwili de Jinotega,ADM2,DISTRICT
SM15208,-85.857,13.6806,Wiwili de Nueva Segovia,ADM2,DISTRICT
SM289039,126.1703,40.7975,Wiwŏn,ADM2,DISTRICT
SM258617,11.31,5.63,Wiya,ETHNICITY,ETHNICITY
SM458196,152.2282,-29.308,Wiyabal,ETHNICITY,ETHNICITY
SM458195,153.2361,-28.7326,Wiyabal,ETHNICITY,ETHNICITY
SM258618,-117,39,Wiyambituka,ETHNICITY,ETHNICITY
SM258618,-117,39,Wiyambituka,ETHNICITY,ETHNICITY
SM258618,-115.84,39.33,Wiyambituka,ETHNICITY,ETHNICITY
SM258618,-115.84,39.33,Wiyambituka,ETHNICITY,ETHNICITY
SM249045,-122.952,39.2492,Wiyot,LANGUAGE,LANGUAGE
SM258619,-122.89,41,Wiyot,ETHNICITY,ETHNICITY
SM258619,-122.89,41,Wiyot,ETHNICITY,ETHNICITY
SM258619,-122.89,41,Wiyot,ETHNICITY,ETHNICITY
SM258619,-122.89,41,Wiyot,ETHNICITY,ETHNICITY
SM258619,-122.89,41,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124,40,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124,40,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124,40,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124,40,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124,40,Wiyot,ETHNICITY,ETHNICITY
SM258619,-123.55,39.81,Wiyot,ETHNICITY,ETHNICITY
SM258619,-123.55,39.81,Wiyot,ETHNICITY,ETHNICITY
SM258619,-123.55,39.81,Wiyot,ETHNICITY,ETHNICITY
SM258619,-123.55,39.81,Wiyot,ETHNICITY,ETHNICITY
SM258619,-123.55,39.81,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124.14,40.75,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124.14,40.75,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124.14,40.75,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124.14,40.75,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124.14,40.75,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124,40.8,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124,40.8,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124,40.8,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124,40.8,Wiyot,ETHNICITY,ETHNICITY
SM258619,-124,40.8,Wiyot,ETHNICITY,ETHNICITY
SM248465,-7.2462,7.4039,Wobe-Wè Northern,LANGUAGE,LANGUAGE
SM249049,-77.986,35.3791,Woccon,LANGUAGE,LANGUAGE
SM258620,136.52,-3.4,Woda,ETHNICITY,ETHNICITY
SM258622,5,13,Wodaabe,ETHNICITY,ETHNICITY
SM258622,5,13,Wodaabe,ETHNICITY,ETHNICITY
SM258622,5,13,Wodaabe,ETHNICITY,ETHNICITY
SM258622,7.5,15,Wodaabe,ETHNICITY,ETHNICITY
SM258622,7.5,15,Wodaabe,ETHNICITY,ETHNICITY
SM258622,7.5,15,Wodaabe,ETHNICITY,ETHNICITY
SM258622,14.8,11.8,Wodaabe,ETHNICITY,ETHNICITY
SM258622,14.8,11.8,Wodaabe,ETHNICITY,ETHNICITY
SM258622,14.8,11.8,Wodaabe,ETHNICITY,ETHNICITY
SM261289,146.8882,-36.1447,Wodonga,ADM2,DISTRICT
SM288741,18.425,49.9932,Wodzisław,ADM2,DISTRICT
SM285964,4.3351,51.4095,Woensdrecht,ADM2,DISTRICT
SM285608,4.9007,52.1068,Woerden,ADM2,DISTRICT
SM258623,129.95,-14.02,Wogaidj,ETHNICITY,ETHNICITY
SM258623,129.95,-14.02,Wogaidj,ETHNICITY,ETHNICITY
SM258623,130.274,-13.2088,Wogaidj,ETHNICITY,ETHNICITY
SM258623,130.274,-13.2088,Wogaidj,ETHNICITY,ETHNICITY
SM249052,142.401,-4.2294,Wogamusin,LANGUAGE,LANGUAGE
SM258624,132.32,-14.49,Wogeman,ETHNICITY,ETHNICITY
SM258624,132.32,-14.49,Wogeman,ETHNICITY,ETHNICITY
SM258624,131.1694,-14.0122,Wogeman,ETHNICITY,ETHNICITY
SM258624,131.1694,-14.0122,Wogeman,ETHNICITY,ETHNICITY
SM249053,144.089,-3.2024,Wogeo,LANGUAGE,LANGUAGE
SM258625,143.11,-2.97,Wogeo Island,ETHNICITY,ETHNICITY
SM258625,143.11,-2.97,Wogeo Island,ETHNICITY,ETHNICITY
SM258625,144.1,-3.24,Wogeo Island,ETHNICITY,ETHNICITY
SM258625,144.1,-3.24,Wogeo Island,ETHNICITY,ETHNICITY
SM249056,135.484,-1.6275,Woi,LANGUAGE,LANGUAGE
SM249057,145.588,-37.6427,Woiwurrung-Thagungwurrung,LANGUAGE,LANGUAGE
SM249059,-7.6104,9.7258,Wojenaka,LANGUAGE,LANGUAGE
SM277096,94.25,26.1667,Wokha,ADM2,DISTRICT
SM274602,-0.9167,51.4167,Wokingham,ADM1:ADM2,DISTRICT
SM258626,143.28,-6.77,Wola,ETHNICITY,ETHNICITY
SM249064,38.14,8.23,Wolane,LANGUAGE,LANGUAGE
SM249063,136.703,-3.4164,Wolani,LANGUAGE,LANGUAGE
SM274320,37.7005,6.8431,Wolayita,ADM2,DISTRICT
SM249062,37.7537,6.3267,Wolaytta,LANGUAGE,LANGUAGE
SM14993,143.857,7.345,Woleai Municipality,ADM2,DISTRICT
SM249065,143.916,7.3796,Woleaian,LANGUAGE,LANGUAGE
SM274583,11.793,1.3727,Woleu,ADM2,DISTRICT
SM301010,-83.4932,37.7393,Wolfe,ADM2,DISTRICT
SM261794,14.8333,46.8333,Wolfsberg,ADM2,DISTRICT
SM258627,149.53,-35.28,Wolgal,ETHNICITY,ETHNICITY
SM258627,149.53,-35.28,Wolgal,ETHNICITY,ETHNICITY
SM258627,148.6051,-35.6961,Wolgal,ETHNICITY,ETHNICITY
SM258627,148.6051,-35.6961,Wolgal,ETHNICITY,ETHNICITY
SM249067,122.67,-5.33,Wolio,LANGUAGE,LANGUAGE
SM258628,148.08,-30.28,Wollarai,ETHNICITY,ETHNICITY
SM258628,148.08,-30.28,Wollarai,ETHNICITY,ETHNICITY
SM258628,148.0268,-31.0181,Wollarai,ETHNICITY,ETHNICITY
SM258628,148.0268,-31.0181,Wollarai,ETHNICITY,ETHNICITY
SM261493,150.4627,-34.0696,Wollondilly,ADM2,DISTRICT
SM261494,150.8506,-34.3424,Wollongong,ADM2,DISTRICT
SM260150,68.8349,31.9336,Wolmamay,ADM2,DISTRICT
SM240766,-15.383,15.2534,Wolof,LANGUAGE,LANGUAGE
SM250812,-16.3,16.1,Wolof,ETHNICITY,ETHNICITY
SM250812,-16.3,16.1,Wolof,ETHNICITY,ETHNICITY
SM250812,-16.3,16.1,Wolof,ETHNICITY,ETHNICITY
SM250812,-16.3,16.1,Wolof,ETHNICITY,ETHNICITY
SM250812,-14.167,14.4165,Wolof,ETHNICITY,ETHNICITY
SM250812,-14.167,14.4165,Wolof,ETHNICITY,ETHNICITY
SM250812,-14.167,14.4165,Wolof,ETHNICITY,ETHNICITY
SM250812,-14.167,14.4165,Wolof,ETHNICITY,ETHNICITY
SM250812,-17,15,Wolof,ETHNICITY,ETHNICITY
SM250812,-17,15,Wolof,ETHNICITY,ETHNICITY
SM250812,-17,15,Wolof,ETHNICITY,ETHNICITY
SM250812,-17,15,Wolof,ETHNICITY,ETHNICITY
SM250812,-15.33,13.75,Wolof,ETHNICITY,ETHNICITY
SM250812,-15.33,13.75,Wolof,ETHNICITY,ETHNICITY
SM250812,-15.33,13.75,Wolof,ETHNICITY,ETHNICITY
SM250812,-15.33,13.75,Wolof,ETHNICITY,ETHNICITY
SM288813,16.1498,52.0879,Wolsztyn,ADM2,DISTRICT
SM274603,-2.1167,52.5833,Wolverhampton,ADM1:ADM2,DISTRICT
SM249072,126.2216,-15.5572,Wolyamidi,DIALECT,LANGUAGE
SM458623,127.3644,-15.6369,Wolyamidi,ETHNICITY,ETHNICITY
SM258629,142.5,-3.75,Wom,ETHNICITY,ETHNICITY
SM249073,12.4533,8.9271,Wom (Nigeria),LANGUAGE,LANGUAGE
SM249076,142.87,-3.5332,Wom (Papua New Guinea),LANGUAGE,LANGUAGE
SM249074,141.83,-2.92,Womo-Sumararu,LANGUAGE,LANGUAGE
SM14994,151.601,7.387,Wonei Municipality,ADM2,DISTRICT
SM3769,114.193,22.343,Wong Tai Sin,ADM1,DISTRICT
SM258630,147.17,-32.68,Wongaibon,ETHNICITY,ETHNICITY
SM258630,147.17,-32.68,Wongaibon,ETHNICITY,ETHNICITY
SM258630,147.17,-32.68,Wongaibon,ETHNICITY,ETHNICITY
SM258630,147.17,-32.68,Wongaibon,ETHNICITY,ETHNICITY
SM258630,146,-32,Wongaibon,ETHNICITY,ETHNICITY
SM258630,146,-32,Wongaibon,ETHNICITY,ETHNICITY
SM258630,146,-32,Wongaibon,ETHNICITY,ETHNICITY
SM258630,146,-32,Wongaibon,ETHNICITY,ETHNICITY
SM258630,146.09,-32.14,Wongaibon,ETHNICITY,ETHNICITY
SM258630,146.09,-32.14,Wongaibon,ETHNICITY,ETHNICITY
SM258630,146.09,-32.14,Wongaibon,ETHNICITY,ETHNICITY
SM258630,146.09,-32.14,Wongaibon,ETHNICITY,ETHNICITY
SM258630,146.7046,-31.2703,Wongaibon,ETHNICITY,ETHNICITY
SM258630,146.7046,-31.2703,Wongaibon,ETHNICITY,ETHNICITY
SM258630,146.7046,-31.2703,Wongaibon,ETHNICITY,ETHNICITY
SM258630,146.7046,-31.2703,Wongaibon,ETHNICITY,ETHNICITY
SM261335,116.8485,-30.7314,Wongan-Ballidu,ADM2,DISTRICT
SM249079,20.5054,-5.7384,Wongo,LANGUAGE,LANGUAGE
SM258631,20.61,-4.46,Wongo,ETHNICITY,ETHNICITY
SM258631,21.95,-5.17,Wongo,ETHNICITY,ETHNICITY
SM280687,127.9213,37.321,Wonju,ADM2,DISTRICT
SM258632,136.44,-26.52,Wonkamala,ETHNICITY,ETHNICITY
SM258633,138.11,-28.51,Wonkanguru,ETHNICITY,ETHNICITY
SM258633,138.11,-28.51,Wonkanguru,ETHNICITY,ETHNICITY
SM258633,137.1818,-25.8863,Wonkanguru,ETHNICITY,ETHNICITY
SM258633,137.1818,-25.8863,Wonkanguru,ETHNICITY,ETHNICITY
SM258634,137.09,-27.22,Wonkatyeri,ETHNICITY,ETHNICITY
SM258634,137.09,-27.22,Wonkatyeri,ETHNICITY,ETHNICITY
SM258634,138.5657,-22.8817,Wonkatyeri,ETHNICITY,ETHNICITY
SM258634,138.5657,-22.8817,Wonkatyeri,ETHNICITY,ETHNICITY
SM276331,111,-7.9167,Wonogiri,ADM2,DISTRICT
SM276332,109.9267,-7.3614,Wonosobo,ADM2,DISTRICT
SM302807,-95.3821,32.7864,Wood,ADM2,DISTRICT
SM303292,-90.0416,44.4553,Wood,ADM2,DISTRICT
SM303157,-81.515,39.2111,Wood,ADM2,DISTRICT
SM302169,-83.623,41.3617,Wood,ADM2,DISTRICT
SM10143,-112.0069,57.6644,Wood Buffalo (2012-),ADM2,DISTRICT
SM261336,117.3358,-33.5194,Woodanilling,ADM2,DISTRICT
SM300880,-96.0448,42.3897,Woodbury,ADM2,DISTRICT
SM301012,-84.7436,38.0424,Woodford,ADM2,DISTRICT
SM300593,-89.2111,40.7882,Woodford,ADM2,DISTRICT
SM12848,-76.7463,18.0736,Woodford,ADM2,DISTRICT
SM12849,-76.7812,17.9871,Woodford Park,ADM2,DISTRICT
SM12850,-77.2758,18.0476,Woodhall,ADM2,DISTRICT
SM12851,-77.9996,18.4456,Woodlands,ADM2,DISTRICT
SM327853,-97.7535,50.1667,Woodlands,ADM2:ADM3,DISTRICT
SM17708,-60.9805,13.7992,Woodlands (Saint Lucia),ADM2,DISTRICT
SM324101,-115.4019,54.2687,Woodlands County,ADM2:ADM3,DISTRICT
SM12852,-76.9811,18.2973,Woodpark,ADM2,DISTRICT
SM302423,-91.2431,35.1863,Woodruff,ADM2,DISTRICT
SM302245,-98.8651,36.7669,Woods,ADM2,DISTRICT
SM249081,-99,57,Woods Cree,LANGUAGE,LANGUAGE
SM12853,-76.9268,18.2526,Woodside,ADM2,DISTRICT
SM300889,-95.7402,37.8867,Woodson,ADM2,DISTRICT
SM12854,-78.0722,18.3695,Woodsville,ADM2,DISTRICT
SM302246,-99.265,36.4227,Woodward,ADM2,DISTRICT
SM261496,151.2471,-33.879,Woollahra,ADM2,DISTRICT
SM261678,149.4498,-24.1501,Woorabinda,ADM2,DISTRICT
SM3770,44.5,10,Woqooyi Galbeed,ADM1,DISTRICT
SM301229,-75.2967,38.2165,Worcester,ADM2,DISTRICT
SM301242,-71.9077,42.3514,Worcester,ADM2,DISTRICT
SM274604,-2.1667,52.1667,Worcestershire,ADM1:ADM2,DISTRICT
SM249085,136.519,-2.38,Woria,LANGUAGE,LANGUAGE
SM249084,152.107,-32.4806,Worimi,LANGUAGE,LANGUAGE
SM458639,127.2189,-16.4277,Worla,ETHNICITY,ETHNICITY
SM370482,6.4053,49.612,Wormeldange,ADM1:ADM2:ADM3,DISTRICT
SM286027,4.8336,52.497,Wormerland,ADM2,DISTRICT
SM3771,-6.6254,7.9904,Woroba,ADM1,DISTRICT
SM249087,-6.7224,8.2345,Worodougou,LANGUAGE,LANGUAGE
SM269471,-6.666,8.1648,Worodougou,ADM2,DISTRICT
SM249089,125.1559,-15.8243,Worora,DIALECT,LANGUAGE
SM258635,125.52,-15.59,Worora,ETHNICITY,ETHNICITY
SM258635,125.52,-15.59,Worora,ETHNICITY,ETHNICITY
SM258635,125.52,-15.59,Worora,ETHNICITY,ETHNICITY
SM258635,124.72,-15.32,Worora,ETHNICITY,ETHNICITY
SM258635,124.72,-15.32,Worora,ETHNICITY,ETHNICITY
SM258635,124.72,-15.32,Worora,ETHNICITY,ETHNICITY
SM258635,124.8759,-15.6213,Worora,ETHNICITY,ETHNICITY
SM258635,124.8759,-15.6213,Worora,ETHNICITY,ETHNICITY
SM258635,124.8759,-15.6213,Worora,ETHNICITY,ETHNICITY
SM249092,124.8812,-15.6128,Worrorra,LANGUAGE,LANGUAGE
SM301524,-94.4221,40.4791,Worth,ADM2,DISTRICT
SM300881,-93.2609,43.3774,Worth,ADM2,DISTRICT
SM300446,-83.8509,31.5515,Worth,ADM2,DISTRICT
SM288630,142.7833,-4.55,Wosera-Gawi,ADM2,DISTRICT
SM249097,70.8565,34.7033,Wotapuri-Katarqalai,LANGUAGE,LANGUAGE
SM249097,70.8565,34.7033,Wotapuri-Katarqalai,LANGUAGE,LANGUAGE
SM249097,71.0909,34.9583,Wotapuri-Katarqalai,LANGUAGE,LANGUAGE
SM249097,71.0909,34.9583,Wotapuri-Katarqalai,LANGUAGE,LANGUAGE
SM4091,165.9833,10.1,Wotho Atoll,ADM1,DISTRICT
SM4106,170.023,9.5134,Wotje Atoll,ADM1,DISTRICT
SM258636,142.37,-35.82,Wotjobaluk,ETHNICITY,ETHNICITY
SM258636,142.37,-35.82,Wotjobaluk,ETHNICITY,ETHNICITY
SM459103,142.9996,-35.7241,Wotjobaluk,ETHNICITY,ETHNICITY
SM258636,142.1207,-35.8685,Wotjobaluk,ETHNICITY,ETHNICITY
SM258636,142.1207,-35.8685,Wotjobaluk,ETHNICITY,ETHNICITY
SM249098,141.4179,-35.8211,Wotjobaluk,DIALECT,LANGUAGE
SM249100,120.9,-2.5959,Wotu,LANGUAGE,LANGUAGE
SM285609,5.3981,52.0806,Woudenberg,ADM2,DISTRICT
SM3772,12,1.5,Wouleu-Ntem,ADM1,DISTRICT
SM249102,-78,8.5,Woun Meu,LANGUAGE,LANGUAGE
SM10350,16.0343,21.3616,Wour,ADM2,DISTRICT
SM269528,9.6667,4,Wouri,ADM2,DISTRICT
SM288982,21.4012,52.4062,Wołomin,ADM2,DISTRICT
SM288666,16.614,51.372,Wołów,ADM2,DISTRICT
SM274759,-3,53,Wrexham,ADM1:ADM2,DISTRICT
SM301525,-92.4687,37.2701,Wright,ADM2,DISTRICT
SM301423,-93.9631,45.1739,Wright,ADM2,DISTRICT
SM300882,-93.7352,42.7331,Wright,ADM2,DISTRICT
SM288668,16.9712,51.0428,Wrocław,ADM2,DISTRICT
SM288667,17.0386,51.1081,Wrocław (City),ADM2,DISTRICT
SM288814,17.5902,52.2409,Września,ADM2,DISTRICT
SM288916,16.121,51.8302,Wschowa,ADM2,DISTRICT
SM249109,119.682,29.8621,Wu Chinese,LANGUAGE,LANGUAGE
SM241219,135.753,-14.2623,Wubuy,LANGUAGE,LANGUAGE
SM284891,8.8186,11.7296,Wudil,ADM2,DISTRICT
SM249111,102.48,25.48,Wuding-Luquan Yi,LANGUAGE,LANGUAGE
SM261748,135.3917,-32.9775,Wudinna,ADM2,DISTRICT
SM258637,120.96,-33.36,Wudjari,ETHNICITY,ETHNICITY
SM258637,120.96,-33.36,Wudjari,ETHNICITY,ETHNICITY
SM258637,120.5919,-33.6671,Wudjari,ETHNICITY,ETHNICITY
SM258637,120.5919,-33.6671,Wudjari,ETHNICITY,ETHNICITY
SM249113,1.5352,7.5762,Wudu,LANGUAGE,LANGUAGE
SM269256,106.9058,39.5383,Wuhai,ADM2,DISTRICT
SM269181,114.3895,30.7262,Wuhan,ADM2,DISTRICT
SM249114,114.3057,30.6241,Wuhan Mandarin,DIALECT,LANGUAGE
SM269126,118.3547,31.0319,Wuhu,ADM2,DISTRICT
SM261679,145.3152,-15.969,Wujal Wujal,ADM2,DISTRICT
SM285247,9.8477,7.9633,Wukari,ADM2,DISTRICT
SM258638,-120,36,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-120,36,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-120,36,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-120,36,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-119,36,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-119,36,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-119,36,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-119,36,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-118.96,36.45,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-118.96,36.45,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-118.96,36.45,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-118.96,36.45,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-119.1,36.4,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-119.1,36.4,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-119.1,36.4,Wukchumni,ETHNICITY,ETHNICITY
SM258638,-119.1,36.4,Wukchumni,ETHNICITY,ETHNICITY
SM249123,146.3454,-19.851,Wulguru,LANGUAGE,LANGUAGE
SM275066,-14.0833,13.5,Wuli,ADM2,DISTRICT
SM458456,150.6356,-25.3033,Wuli Wuli,ETHNICITY,ETHNICITY
SM249124,149.843,-24.415,Wuliwuli,LANGUAGE,LANGUAGE
SM459096,141.6342,-37.8432,Wulluwurrung,ETHNICITY,ETHNICITY
SM249126,131.261,-12.3022,Wulna,LANGUAGE,LANGUAGE
SM10368,107.7042,29.3754,Wulong District,ADM2,DISTRICT
SM249129,87.4227,43.8216,Wulumuqi Mandarin,DIALECT,LANGUAGE
SM249131,131.831,-13.8178,Wulwulam,LANGUAGE,LANGUAGE
SM458876,132.0546,-13.6417,Wulwulam,ETHNICITY,ETHNICITY
SM250015,10.0664,6.3834,Wum,ETHNICITY,ETHNICITY
SM249132,9.0803,4.2472,Wumboko-Bubia,LANGUAGE,LANGUAGE
SM249133,12.264,-2.3923,Wumbvu,LANGUAGE,LANGUAGE
SM249134,103.646,27.574,Wumeng Nasu,LANGUAGE,LANGUAGE
SM249136,110.473,27.9649,Wunai Bunu,LANGUAGE,LANGUAGE
SM249137,125.72,-14.8347,Wunambal,LANGUAGE,LANGUAGE
SM258639,126.95,-14.56,Wunambal,ETHNICITY,ETHNICITY
SM258639,126.95,-14.56,Wunambal,ETHNICITY,ETHNICITY
SM258639,126.95,-14.56,Wunambal,ETHNICITY,ETHNICITY
SM258639,128.19,-15.55,Wunambal,ETHNICITY,ETHNICITY
SM258639,128.19,-15.55,Wunambal,ETHNICITY,ETHNICITY
SM258639,128.19,-15.55,Wunambal,ETHNICITY,ETHNICITY
SM258639,125.4638,-14.8794,Wunambal,ETHNICITY,ETHNICITY
SM258639,125.4638,-14.8794,Wunambal,ETHNICITY,ETHNICITY
SM258639,125.4638,-14.8794,Wunambal,ETHNICITY,ETHNICITY
SM258640,133.6,-13.18,Wuningag,ETHNICITY,ETHNICITY
SM258640,133.6,-13.18,Wuningag,ETHNICITY,ETHNICITY
SM258640,133.0565,-12.5457,Wuningag,ETHNICITY,ETHNICITY
SM258640,133.0565,-12.5457,Wuningag,ETHNICITY,ETHNICITY
SM459422,143.5669,-14.5999,Wurangung,ETHNICITY,ETHNICITY
SM458825,131.9235,-11.2871,Wurdang,ETHNICITY,ETHNICITY
SM249148,127.52,-16.15,Wurla,DIALECT,LANGUAGE
SM458128,129.6478,-16.3416,Wurlayi,ETHNICITY,ETHNICITY
SM285233,5.4865,13.2568,Wurno,ADM2,DISTRICT
SM249150,132.303,-11.364,Wurrugu,LANGUAGE,LANGUAGE
SM258641,132.45,-11.43,Wurugu,ETHNICITY,ETHNICITY
SM258641,132.45,-11.43,Wurugu,ETHNICITY,ETHNICITY
SM258641,132.0931,-11.3614,Wurugu,ETHNICITY,ETHNICITY
SM258641,132.0931,-11.3614,Wurugu,ETHNICITY,ETHNICITY
SM258642,145.51,-38.12,Wurunjeri,ETHNICITY,ETHNICITY
SM258642,145.51,-38.12,Wurunjeri,ETHNICITY,ETHNICITY
SM258642,145.2932,-37.7422,Wurunjeri,ETHNICITY,ETHNICITY
SM258642,145.2932,-37.7422,Wurunjeri,ETHNICITY,ETHNICITY
SM249152,102.367,25.7707,Wusa Nasu,LANGUAGE,LANGUAGE
SM304793,43.7947,14.3344,Wusab Al Ali,ADM2,DISTRICT
SM304794,43.6325,14.2548,Wusab As Safil,ADM2,DISTRICT
SM249153,10.6035,6.0742,Wushi,LANGUAGE,LANGUAGE
SM285039,5.9366,9.702,Wushishi,ADM2,DISTRICT
SM9600,71.0818,34.9961,Wutahpur,ADM2,DISTRICT
SM249159,143.0879,-11.971,Wuthathi,LANGUAGE,LANGUAGE
SM459175,143.0671,-12.0221,Wuthathi,ETHNICITY,ETHNICITY
SM249161,141.104,-2.6464,Wutung,LANGUAGE,LANGUAGE
SM249160,101.957,35.269,Wutunhua,LANGUAGE,LANGUAGE
SM259032,15.72,-4.27,Wuumu-Mpuono,LANGUAGE,LANGUAGE
SM249164,142.851,-1.7394,Wuvulu-Aua,LANGUAGE,LANGUAGE
SM269406,103.1241,38.0086,Wuwei,ADM2,DISTRICT
SM269221,120.2094,31.4467,Wuxi,ADM2,DISTRICT
SM10369,109.6333,31.45,Wuxi County,ADM2,DISTRICT
SM269270,106.5132,37.3283,Wuzhong,ADM2,DISTRICT
SM269441,111.3115,23.482,Wuzhou,ADM2,DISTRICT
SM249170,14.1378,10.955,Wuzlam,LANGUAGE,LANGUAGE
SM454503,14.3,11,Wuzlam,ETHNICITY,ETHNICITY
SM370404,-9.6149,8.3677,Wy-Wonnegomia,ADM2:ADM3,DISTRICT
SM370404,-9.6149,8.3677,Wy-Wonnegomia,ADM2:ADM3,DISTRICT
SM370404,-9.5972,8.3653,Wy-Wonnegomia,ADM2:ADM3,DISTRICT
SM370404,-9.5972,8.3653,Wy-Wonnegomia,ADM2:ADM3,DISTRICT
SM261337,117.4468,-31.1953,Wyalkatchem,ADM2,DISTRICT
SM302170,-83.3044,40.8424,Wyandot,ADM2,DISTRICT
SM300890,-94.7645,39.1147,Wyandotte,ADM2,DISTRICT
SM249173,19.152,49.9162,Wymysorys,LANGUAGE,LANGUAGE
SM261290,144.6357,-37.8928,Wyndham,ADM2,DISTRICT
SM261338,126.5878,-15.5129,Wyndham-East Kimberley,ADM2,DISTRICT
SM258643,-123.66,46.8,Wynoochee,ETHNICITY,ETHNICITY
SM303158,-81.5492,37.6096,Wyoming,ADM2,DISTRICT
SM302347,-76.0166,41.5183,Wyoming,ADM2,DISTRICT
SM301925,-78.2244,42.7024,Wyoming,ADM2,DISTRICT
SM3773,-107.5009,43.0002,Wyoming,ADM1,DISTRICT
SM288686,22.5554,52.8646,Wysokie Mazowieckie,ADM2,DISTRICT
SM288983,21.4454,52.6514,Wyszków,ADM2,DISTRICT
SM302973,-81.0786,36.9171,Wythe,ADM2,DISTRICT
SM248309,9.9038,9.1083,Wãpha,LANGUAGE,LANGUAGE
SM248484,-7.6343,6.5001,Wè Southern,LANGUAGE,LANGUAGE
SM248965,-8.4023,6.3646,Wè Western,LANGUAGE,LANGUAGE
SM288856,18.9265,53.2827,Wąbrzeźno,ADM2,DISTRICT
SM288812,17.2108,52.8081,Wągrowiec,ADM2,DISTRICT
SM288779,21.7786,54.2073,Węgorzewo,ADM2,DISTRICT
SM288981,21.8734,52.4563,Węgrów,ADM2,DISTRICT
SM288858,19.0516,52.5566,Włocławek,ADM2,DISTRICT
SM288857,19.0608,52.6654,Włocławek (City),ADM2,DISTRICT
SM288905,23.3828,51.5227,Włodawa,ADM2,DISTRICT
SM288757,19.977,50.8321,Włoszczowa,ADM2,DISTRICT
SM289176,127.3542,39.14,Wŏnsan,ADM2,DISTRICT
SM249174,-10.95,14.1,Xaasongaxango,LANGUAGE,LANGUAGE
SM249175,-96.0318,15.8822,Xadani Zapotec,LANGUAGE,LANGUAGE
SM15080,33.7498,-25.0253,Xai-Xai District (excl. Xai-xai city),ADM2,DISTRICT
SM3774,101.5,18.5,Xaignabouri,ADM1,DISTRICT
SM3775,103.0947,18.9027,Xaisômboun,ADM1,DISTRICT
SM258645,-47.14,-16.56,Xakriaba,ETHNICITY,ETHNICITY
SM249178,-47,-19,Xakriabá,LANGUAGE,LANGUAGE
SM282833,-96.8814,19.5467,Xalapa,ADM2,DISTRICT
SM282143,-104.9239,21.3983,Xalisco,ADM2,DISTRICT
SM282712,-98.0151,19.4203,Xaloztoc,ADM2,DISTRICT
SM282337,-97.8561,20.4432,Xalpan,ADM2,DISTRICT
SM281647,-98.5601,17.3889,Xalpatláhuac,ADM2,DISTRICT
SM14907,-98.2179,19.4479,Xaltocan (1995-),ADM2,DISTRICT
SM250814,21.94,-28.25,Xam,ETHNICITY,ETHNICITY
SM250814,21.94,-28.25,Xam,ETHNICITY,ETHNICITY
SM250814,21.94,-28.25,Xam,ETHNICITY,ETHNICITY
SM250814,23,-30,Xam,ETHNICITY,ETHNICITY
SM250814,23,-30,Xam,ETHNICITY,ETHNICITY
SM250814,23,-30,Xam,ETHNICITY,ETHNICITY
SM250814,19.77,-31.47,Xam,ETHNICITY,ETHNICITY
SM250814,19.77,-31.47,Xam,ETHNICITY,ETHNICITY
SM250814,19.77,-31.47,Xam,ETHNICITY,ETHNICITY
SM258646,-64.65,2.92,Xamatari,ETHNICITY,ETHNICITY
SM258646,-64.65,2.92,Xamatari,ETHNICITY,ETHNICITY
SM258646,-64.65,2.92,Xamatari,ETHNICITY,ETHNICITY
SM258646,-63.75,2.5,Xamatari,ETHNICITY,ETHNICITY
SM258646,-63.75,2.5,Xamatari,ETHNICITY,ETHNICITY
SM258646,-63.75,2.5,Xamatari,ETHNICITY,ETHNICITY
SM258646,-66,4,Xamatari,ETHNICITY,ETHNICITY
SM258646,-66,4,Xamatari,ETHNICITY,ETHNICITY
SM258646,-66,4,Xamatari,ETHNICITY,ETHNICITY
SM267193,-48.4314,-6.5941,Xambioá,ADM2,DISTRICT
SM264779,-53.604,-23.7385,Xambrê,ADM2,DISTRICT
SM280863,104.0656,20.4076,Xamneua,ADM2,DISTRICT
SM249181,38.8352,12.4479,Xamtanga,LANGUAGE,LANGUAGE
SM249182,-96.1927,15.9748,Xanaguía Zapotec,LANGUAGE,LANGUAGE
SM13381,101.6943,18.0142,Xanakharm (excl. Meun),ADM2,DISTRICT
SM266014,-50.0922,-29.8105,Xangri-lá,ADM2,DISTRICT
SM261919,46.3333,40.5,Xanlar,ADM1:ADM2,DISTRICT
SM266351,-52.4149,-26.8667,Xanxerê,ADM2,DISTRICT
SM262795,-68.6489,-10.5966,Xapuri,ADM2,DISTRICT
SM249184,166.376,-21.8223,Xaragure,LANGUAGE,LANGUAGE
SM295776,47.8352,4.7918,Xarardheere,ADM2,DISTRICT
SM17878,47.9464,8.5839,Xarfo (excl. Gaalkacyo town),ADM2,DISTRICT
SM258647,143.35,-4.04,Xariwari,ETHNICITY,ETHNICITY
SM258648,-60.41,5.83,Xaruma,ETHNICITY,ETHNICITY
SM303554,66,40.1667,Xatirchi,ADM2,DISTRICT
SM258649,-51.7,-14.34,Xavante,ETHNICITY,ETHNICITY
SM258649,-51.7,-14.34,Xavante,ETHNICITY,ETHNICITY
SM258649,-51.7,-14.34,Xavante,ETHNICITY,ETHNICITY
SM258649,-51.7,-14.34,Xavante,ETHNICITY,ETHNICITY
SM258649,-51.5,-13.5,Xavante,ETHNICITY,ETHNICITY
SM258649,-51.5,-13.5,Xavante,ETHNICITY,ETHNICITY
SM258649,-51.5,-13.5,Xavante,ETHNICITY,ETHNICITY
SM258649,-51.5,-13.5,Xavante,ETHNICITY,ETHNICITY
SM258649,-52,-14,Xavante,ETHNICITY,ETHNICITY
SM258649,-52,-14,Xavante,ETHNICITY,ETHNICITY
SM258649,-52,-14,Xavante,ETHNICITY,ETHNICITY
SM258649,-52,-14,Xavante,ETHNICITY,ETHNICITY
SM258649,-52.5,-14.3333,Xavante,ETHNICITY,ETHNICITY
SM258649,-52.5,-14.3333,Xavante,ETHNICITY,ETHNICITY
SM258649,-52.5,-14.3333,Xavante,ETHNICITY,ETHNICITY
SM258649,-52.5,-14.3333,Xavante,ETHNICITY,ETHNICITY
SM266352,-52.3291,-26.9882,Xavantina,ADM2,DISTRICT
SM249191,-52.4367,-14.3017,Xavánte,LANGUAGE,LANGUAGE
SM266353,-52.5161,-26.9869,Xaxim,ADM2,DISTRICT
SM280897,101.9555,20.6645,Xay,ADM2,DISTRICT
SM280819,101.5954,19.3229,Xayabury,ADM2,DISTRICT
SM282338,-97.9792,18.2388,Xayacatlán de Bravo,ADM2,DISTRICT
SM280873,105.5518,17.1811,Xaybuathong,ADM2,DISTRICT
SM280780,104.9231,16.9443,Xaybuly,ADM2,DISTRICT
SM13382,104.3581,18.7058,Xaychamphone,ADM2,DISTRICT
SM280781,105.0217,16.3737,Xayphoothong,ADM2,DISTRICT
SM280798,102.7079,17.9758,Xaysetha,ADM2,DISTRICT
SM280760,106.9789,14.9059,Xaysetha,ADM2,DISTRICT
SM280824,103.0927,18.8973,Xaysomboun,ADM1:ADM2,DISTRICT
SM280799,102.7238,18.1413,Xaythany,ADM2,DISTRICT
SM261951,48.75,41.5833,Xaçmaz,ADM1:ADM2,DISTRICT
SM280874,105.1259,17.1762,Xebangfay,ADM2,DISTRICT
SM11107,32.7248,35.1292,Xerovounos,ADM2,DISTRICT
SM249195,-48.2629,-9.5943,Xerénte,LANGUAGE,LANGUAGE
SM258650,-50.03,-23.95,Xeta,ETHNICITY,ETHNICITY
SM258650,-50.03,-23.95,Xeta,ETHNICITY,ETHNICITY
SM258650,-53.68,-23.52,Xeta,ETHNICITY,ETHNICITY
SM258650,-53.68,-23.52,Xeta,ETHNICITY,ETHNICITY
SM249197,-52.2266,-24.9408,Xetá,LANGUAGE,LANGUAGE
SM258651,45,43,Xevsur,ETHNICITY,ETHNICITY
SM264936,-35.6428,-8.8647,Xexéu,ADM2,DISTRICT
SM283450,14.5464,35.8868,Xgħajra,ADM1:ADM2,DISTRICT
SM305108,25.8329,-29.8431,Xhariep,ADM2,DISTRICT
SM249199,28.0769,-31.0389,Xhosa,LANGUAGE,LANGUAGE
SM250815,27.73,-32.87,Xhosa,ETHNICITY,ETHNICITY
SM250815,27.73,-32.87,Xhosa,ETHNICITY,ETHNICITY
SM250815,27.73,-32.87,Xhosa,ETHNICITY,ETHNICITY
SM250815,27.91,-32.89,Xhosa,ETHNICITY,ETHNICITY
SM250815,27.91,-32.89,Xhosa,ETHNICITY,ETHNICITY
SM250815,27.91,-32.89,Xhosa,ETHNICITY,ETHNICITY
SM250815,28,-32,Xhosa,ETHNICITY,ETHNICITY
SM250815,28,-32,Xhosa,ETHNICITY,ETHNICITY
SM250815,28,-32,Xhosa,ETHNICITY,ETHNICITY
SM269288,108.7858,34.0689,Xi'an,ADM2,DISTRICT
SM249205,108.9606,34.2282,Xi'an Mandarin,DIALECT,LANGUAGE
SM249200,118.0892,24.4942,Xiamen,DIALECT,LANGUAGE
SM269400,118.1157,24.7021,Xiamen,ADM2,DISTRICT
SM249201,97.9052,24.5458,Xiandao,DIALECT,LANGUAGE
SM249203,111.4711,27.6685,Xiang Chinese,LANGUAGE,LANGUAGE
SM269182,112.1213,32.0043,Xiangfan,ADM2,DISTRICT
SM3777,103.5,19.4667,"Xiangkhoang (1994-2006, 2013-)",ADM1,DISTRICT
SM249206,112.7688,27.8483,Xiangtan,DIALECT,LANGUAGE
SM269207,112.6263,27.6819,Xiangtan,ADM2,DISTRICT
SM269208,109.7333,28.7057,Xiangxi Tujia and Miao,ADM2,DISTRICT
SM249202,100.4479,25.5314,Xiangyun,DIALECT,LANGUAGE
SM269183,114.2639,29.6661,Xianning,ADM2,DISTRICT
SM269289,108.3936,34.7342,Xianyang,ADM2,DISTRICT
SM269185,113.9344,30.929,Xiaogan,ADM2,DISTRICT
SM249211,81.0034,43.5854,Xibe,LANGUAGE,LANGUAGE
SM258652,81.5,43.4979,Xibe,ETHNICITY,ETHNICITY
SM258652,81.5,43.4979,Xibe,ETHNICITY,ETHNICITY
SM258652,81.5,43.5,Xibe,ETHNICITY,ETHNICITY
SM258652,81.5,43.5,Xibe,ETHNICITY,ETHNICITY
SM281573,-99.9997,21.3442,Xichú,ADM2,DISTRICT
SM282834,-97.0433,19.4399,Xico,ADM2,DISTRICT
SM282715,-98.2333,19.1739,Xicohtzinco,ADM2,DISTRICT
SM282339,-97.9089,20.3181,Xicotepec,ADM2,DISTRICT
SM249213,-97.7463,20.6532,Xicotepec De Juárez Totonac,LANGUAGE,LANGUAGE
SM282340,-98.5936,18.0811,Xicotlán,ADM2,DISTRICT
SM282674,-98.9832,23.0099,Xicoténcatl,ADM2,DISTRICT
SM280882,102.1849,19.7586,Xieng Ngeun,ADM2,DISTRICT
SM280811,100.8346,19.7048,Xienghone,ADM2,DISTRICT
SM280865,104.15,20.8167,Xiengkhor,ADM2,DISTRICT
SM269257,115.7279,44.8549,Xilin Gol,ADM2,DISTRICT
SM282525,-99.0004,21.3771,Xilitla,ADM2,DISTRICT
SM226021,99.4833,22.75,Ximeng-Menglian Awa,LANGUAGE,LANGUAGE
SM249224,-89.8235,14.3103,Xinca,LANGUAGE,LANGUAGE
SM249219,-90.3803,14.0833,Xinca-Chiquimulilla,LANGUAGE,LANGUAGE
SM249223,-90.4167,14.07,Xinca-Guazacapan,LANGUAGE,LANGUAGE
SM249222,-90.3585,14.1269,Xinca-Sinacantan,LANGUAGE,LANGUAGE
SM249220,-89.791,14.1945,Xinca-Yupiltepeque,LANGUAGE,LANGUAGE
SM269258,121.2417,46.6484,Xing'an,ADM2,DISTRICT
SM269137,114.49,37.06,Xingtai,ADM2,DISTRICT
SM264128,-49.2557,-6.849,Xinguara,ADM2,DISTRICT
SM249226,-52.321,-4.1346,Xingú Asuriní,LANGUAGE,LANGUAGE
SM269280,101.4408,36.8245,Xining,ADM2,DISTRICT
SM249228,101.7769,36.6713,Xining Mandarin,DIALECT,LANGUAGE
SM3778,85.5,41.5,Xinjiang Uygur,ADM1,DISTRICT
SM249229,-90.2565,14.3419,Xinka-Jumaytepeque,LANGUAGE,LANGUAGE
SM269164,114.0511,35.3086,Xinxiang,ADM2,DISTRICT
SM269165,114.8533,32.075,Xinyang,ADM2,DISTRICT
SM269232,114.9242,27.795,Xinyu,ADM2,DISTRICT
SM269319,112.3858,38.765,Xinzhou,ADM2,DISTRICT
SM249233,103.39,44.61,Xiong-Nu,LANGUAGE,LANGUAGE
SM249234,-49.948,-4.133,Xipaya,LANGUAGE,LANGUAGE
SM249236,-68.8333,-8.75,Xipináwa,LANGUAGE,LANGUAGE
SM267716,-42.5632,-10.8339,Xique-Xique,ADM2,DISTRICT
SM249237,20.726,-28.4258,Xiri,LANGUAGE,LANGUAGE
SM249237,20.726,-28.4258,Xiri,LANGUAGE,LANGUAGE
SM249237,18.6333,-32.7255,Xiri,LANGUAGE,LANGUAGE
SM249237,18.6333,-32.7255,Xiri,LANGUAGE,LANGUAGE
SM249238,-62.5413,2.6993,Xiriâna,LANGUAGE,LANGUAGE
SM249238,-62.5413,2.6993,Xiriâna,LANGUAGE,LANGUAGE
SM249238,-63.4529,0.8387,Xiriâna,LANGUAGE,LANGUAGE
SM249238,-63.4529,0.8387,Xiriâna,LANGUAGE,LANGUAGE
SM249239,100.711,24.8709,Xishanba Lalo,LANGUAGE,LANGUAGE
SM269371,101.0248,22.0883,Xishuangbanna Dai,ADM2,DISTRICT
SM275081,-14.693,11.763,Xitole,ADM2,DISTRICT
SM251024,34.36,-24.11,Xitsonga,ETHNICITY,ETHNICITY
SM251024,34.36,-24.11,Xitsonga,ETHNICITY,ETHNICITY
SM251024,34.36,-24.11,Xitsonga,ETHNICITY,ETHNICITY
SM251024,32,-24,Xitsonga,ETHNICITY,ETHNICITY
SM251024,32,-24,Xitsonga,ETHNICITY,ETHNICITY
SM251024,32,-24,Xitsonga,ETHNICITY,ETHNICITY
SM251024,32.33,-25.83,Xitsonga,ETHNICITY,ETHNICITY
SM251024,32.33,-25.83,Xitsonga,ETHNICITY,ETHNICITY
SM251024,32.33,-25.83,Xitsonga,ETHNICITY,ETHNICITY
SM251130,35.03,-21.79,Xitswa,ETHNICITY,ETHNICITY
SM282341,-97.3425,19.7575,Xiutetelco,ADM2,DISTRICT
SM18463,60.4167,41.3333,Xiva Tumani,ADM2,DISTRICT
SM258655,-105.55,24.13,Xixime,ETHNICITY,ETHNICITY
SM259162,99.584,22.499,Xiyun Va,LANGUAGE,LANGUAGE
SM3779,88,31.6667,Xizang,ADM1,DISTRICT
SM261883,49.1667,40.75,Xizı,ADM1:ADM2,DISTRICT
SM258656,106.32,14.77,Xo Dang,ETHNICITY,ETHNICITY
SM303399,72.5833,40.6667,Xo'jaobod,ADM2,DISTRICT
SM303504,59.3055,42.5132,Xo'jayli,ADM2,DISTRICT
SM261893,46.75,39.8333,Xocalı  (Nagorno-Karabakh),ADM1:ADM2,DISTRICT
SM261885,47,39.6667,Xocavənd  (Nagorno-Karabakh),ADM1:ADM2,DISTRICT
SM282937,-89.1506,20.8199,Xocchel,ADM2,DISTRICT
SM249242,-98.4499,17.3813,Xochapa Mixtec,DIALECT,LANGUAGE
SM282342,-97.6506,19.8342,Xochiapulco,ADM2,DISTRICT
SM281730,-98.2821,20.8579,Xochiatipan,ADM2,DISTRICT
SM281731,-98.6372,20.7971,Xochicoatlán,ADM2,DISTRICT
SM281648,-98.4789,17.8924,Xochihuehuetlán,ADM2,DISTRICT
SM282343,-98.3254,18.6527,Xochiltepec,ADM2,DISTRICT
SM283332,-99.1159,19.2776,Xochimilco,ADM2,DISTRICT
SM281649,-98.1869,16.8168,Xochistlahuaca,ADM2,DISTRICT
SM282119,-99.2327,18.7705,Xochitepec,ADM2,DISTRICT
SM282347,-97.7775,18.6808,Xochitlán Todos Santos,ADM2,DISTRICT
SM282344,-97.6438,19.95,Xochitlán de Vicente Suárez,ADM2,DISTRICT
SM249243,-38.6336,-7.6338,Xoco,LANGUAGE,LANGUAGE
SM249244,-49.5886,-26.9217,Xokleng,LANGUAGE,LANGUAGE
SM258657,-48.99,-27.22,Xokleng,ETHNICITY,ETHNICITY
SM258657,-48.99,-27.22,Xokleng,ETHNICITY,ETHNICITY
SM258657,-50,-28,Xokleng,ETHNICITY,ETHNICITY
SM258657,-50,-28,Xokleng,ETHNICITY,ETHNICITY
SM281883,-99.533,19.4,Xonacatlán,ADM2,DISTRICT
SM280782,105.5739,16.4004,Xonbuly,ADM2,DISTRICT
SM303534,60.7833,41.4667,Xonqa,ADM2,DISTRICT
SM282835,-97.1606,18.6318,Xoxocotla,ADM2,DISTRICT
SM269127,118.8419,30.6449,Xuancheng,ADM2,DISTRICT
SM269166,113.7147,34.1478,Xuchang,ADM2,DISTRICT
SM295797,47.5075,9.1416,Xudun,ADM2,DISTRICT
SM17880,43.8878,4.12,Xudur (excl. Xudur town),ADM2,DISTRICT
SM17879,43.9062,4.1889,Xudur Town,ADM2,DISTRICT
SM249252,-37.0869,-8.6606,Xukurú,LANGUAGE,LANGUAGE
SM304601,107.4657,10.6305,Xuyên Mộc,ADM2,DISTRICT
SM249256,99.1122,25.344,Xuzhang Lalo,LANGUAGE,LANGUAGE
SM269210,117.549,34.2626,Xuzhou,ADM2,DISTRICT
SM304000,107.4364,10.9116,Xuân Lộc,ADM2,DISTRICT
SM304317,106.3611,20.3091,Xuân Trường,ADM2,DISTRICT
SM249258,2.0299,6.4134,Xwela Gbe,LANGUAGE,LANGUAGE
SM11108,33.0355,35.0189,Xyliatos,ADM2,DISTRICT
SM11109,33.8484,34.9759,Xylofagou,ADM2,DISTRICT
SM11110,33.7424,35.0187,Xylotymvou,ADM2,DISTRICT
SM260339,17.8243,-9.194,Xá Muteba,ADM2,DISTRICT
SM249185,165.996,-21.7034,Xârâcùù,LANGUAGE,LANGUAGE
SM3776,107,15.5,Xékong,ADM1,DISTRICT
SM304122,104.5209,22.6415,Xín Mần,ADM2,DISTRICT
SM277023,78.75,14.4167,Y.S.R.,ADM2,DISTRICT
SM269330,103,29.98,Ya'an,ADM2,DISTRICT
SM249263,37.1008,0.4686,Yaaku,LANGUAGE,LANGUAGE
SM454496,37,0.5,Yaaku,ETHNICITY,ETHNICITY
SM258659,139.42,-8,Yab-Anim,ETHNICITY,ETHNICITY
SM249270,-66.276,5.3269,Yabarana,LANGUAGE,LANGUAGE
SM258660,-66,5,Yabarana,ETHNICITY,ETHNICITY
SM249271,-65.5,0.5833,Yabaâna,LANGUAGE,LANGUAGE
SM289593,-57.143,-27.2563,Yabebyry,ADM2,DISTRICT
SM249275,147.81,-6.6705,Yabem,LANGUAGE,LANGUAGE
SM249276,145.361,-4.9087,Yaben,LANGUAGE,LANGUAGE
SM235023,132.06,-1.8264,Yaben (Konda),LANGUAGE,LANGUAGE
SM262550,24.1333,43.0667,Yablanitsa,ADM2,DISTRICT
SM285234,4.9496,12.7891,Yabo,ADM2,DISTRICT
SM249279,146.2065,-5.6172,Yabong,LANGUAGE,LANGUAGE
SM296750,36.446,33.9531,Yabrud,ADM2,DISTRICT
SM278361,134.8167,35.3833,Yabu,ADM2,DISTRICT
SM3781,-65.8871,18.073,Yabucoa,ADM1,DISTRICT
SM16285,-65.8801,18.0473,Yabucoa Barrio-Pueblo,ADM2,DISTRICT
SM249281,145.2321,-35.7612,Yabula Yabula,DIALECT,LANGUAGE
SM304824,47.7358,14.7182,Yabuth,ADM2,DISTRICT
SM249284,8.8408,6.8426,Yace,LANGUAGE,LANGUAGE
SM279394,140.2969,35.6424,Yachimata,ADM2,DISTRICT
SM279395,140.105,35.7392,Yachiyo,ADM2,DISTRICT
SM278405,139.893,36.1784,Yachiyo,ADM2,DISTRICT
SM269973,-74.3733,5.5822,Yacopí,ADM2,DISTRICT
SM273581,-78.9167,-3.6667,Yacuambi,ADM2,DISTRICT
SM270357,-77.4242,1.125,Yacuanquer,ADM2,DISTRICT
SM262716,-65.925,-13.575,Yacuma,ADM2,DISTRICT
SM11702,78.8854,17.5129,Yadadri Bhuvanagiri,ADM2,DISTRICT
SM17824,44.6667,18.8333,Yadamah,ADM2,DISTRICT
SM276946,76.94,16.73,Yadgir,ADM2,DISTRICT
SM249287,142.725,-11.314,Yadhaykenu,LANGUAGE,LANGUAGE
SM459433,142.7258,-11.4838,Yadhaykenu,ETHNICITY,ETHNICITY
SM302029,-80.6653,36.1605,Yadkin,ADM2,DISTRICT
SM458710,139.8041,-31.0978,Yadliawara,ETHNICITY,ETHNICITY
SM292808,46.3333,55.9167,Yadrinskiy rayon,ADM2,DISTRICT
SM258664,-2.37,15.85,Yadsi,ETHNICITY,ETHNICITY
SM249290,123.801,24.3401,Yaeyama,LANGUAGE,LANGUAGE
SM13118,123.7606,24.335,Yaeyama-gun,ADM2,DISTRICT
SM304889,45.2014,13.862,Yafa'a,ADM2,DISTRICT
SM249291,140.897,-3.3977,Yafi,LANGUAGE,LANGUAGE
SM258665,47.32,15.33,Yafi,ETHNICITY,ETHNICITY
SM9601,70.371,37.312,Yaftal-e Sufla,ADM2,DISTRICT
SM258813,146.6329,-23.1569,Yagalingu,ETHNICITY,ETHNICITY
SM249298,152.4603,-27.8765,Yagara,DIALECT,LANGUAGE
SM249292,152.4603,-27.8765,Yagara-Jandai,LANGUAGE,LANGUAGE
SM249296,145.388,-6.3243,Yagaria,LANGUAGE,LANGUAGE
SM284964,5.7613,8.1641,Yagba East,ADM2,DISTRICT
SM284965,5.5275,8.2964,Yagba West,ADM2,DISTRICT
SM262195,0.5833,13.4167,Yagha,ADM1:ADM2,DISTRICT
SM303538,71.75,41.25,Yagiqo'rg'on,ADM2,DISTRICT
SM249301,69.2422,39.1486,Yagnobi,LANGUAGE,LANGUAGE
SM293618,151.3333,62.6667,Yagodninskiy rayon,ADM2,DISTRICT
SM249302,146.603,-5.7405,Yagomi,LANGUAGE,LANGUAGE
SM249303,-72.2186,-3.4291,Yagua,LANGUAGE,LANGUAGE
SM258667,-71.81,-3,Yagua,ETHNICITY,ETHNICITY
SM258667,-71.81,-3,Yagua,ETHNICITY,ETHNICITY
SM258667,-72,-3,Yagua,ETHNICITY,ETHNICITY
SM258667,-72,-3,Yagua,ETHNICITY,ETHNICITY
SM258668,-77.14,18.14,Yaguabo,ETHNICITY,ETHNICITY
SM271045,-79.2333,22.3167,Yaguajay,ADM2,DISTRICT
SM270110,-75.5322,2.6353,Yaguará,ADM2,DISTRICT
SM289624,-57.2748,-25.5987,Yaguarón,ADM2,DISTRICT
SM249304,145.854,-7.1404,Yagwoia,LANGUAGE,LANGUAGE
SM297757,101.1268,6.4053,Yaha,ADM2,DISTRICT
SM249307,132.096,-1.9432,Yahadian,LANGUAGE,LANGUAGE
SM258669,-115,42,Yahanduka,ETHNICITY,ETHNICITY
SM249306,142.4,-3.7304,Yahang,LANGUAGE,LANGUAGE
SM258670,-69.61,-54.55,Yahgan,ETHNICITY,ETHNICITY
SM258670,-69.61,-54.55,Yahgan,ETHNICITY,ETHNICITY
SM258670,-69.61,-54.55,Yahgan,ETHNICITY,ETHNICITY
SM258670,-68.98,-55.02,Yahgan,ETHNICITY,ETHNICITY
SM258670,-68.98,-55.02,Yahgan,ETHNICITY,ETHNICITY
SM258670,-68.98,-55.02,Yahgan,ETHNICITY,ETHNICITY
SM258670,-69.5,-55.5,Yahgan,ETHNICITY,ETHNICITY
SM258670,-69.5,-55.5,Yahgan,ETHNICITY,ETHNICITY
SM258670,-69.5,-55.5,Yahgan,ETHNICITY,ETHNICITY
SM278954,138.8377,37.692,Yahiko,ADM2,DISTRICT
SM304890,45.1305,13.7227,Yahr,ADM2,DISTRICT
SM281733,-98.3787,20.9149,Yahualica,ADM2,DISTRICT
SM281761,-102.9192,21.1379,Yahualica de González Gallo,ADM2,DISTRICT
SM16286,-66.7959,18.1985,Yahuecas Barrio,ADM2,DISTRICT
SM276544,139.5841,-4.604,Yahukimo,ADM2,DISTRICT
SM249309,-70.4782,-0.5054,Yahuna,LANGUAGE,LANGUAGE
SM9602,68.6624,32.9231,Yahya Khel,ADM2,DISTRICT
SM298614,35.4401,37.9666,Yahyalı,ADM2,DISTRICT
SM298636,33.4562,39.8599,Yahşihan,ADM2,DISTRICT
SM279339,139.8981,36.8275,Yaita,ADM2,DISTRICT
SM13119,138.3013,34.8401,Yaizu Shi (2008-),ADM2,DISTRICT
SM283112,-92.3272,17.1852,Yajalón,ADM2,DISTRICT
SM250816,16.79,-6.07,Yaka,ETHNICITY,ETHNICITY
SM250816,16.79,-6.07,Yaka,ETHNICITY,ETHNICITY
SM250816,18.45,-5.62,Yaka,ETHNICITY,ETHNICITY
SM250816,18.45,-5.62,Yaka,ETHNICITY,ETHNICITY
SM250816,20.04,-6.85,Yaka,ETHNICITY,ETHNICITY
SM250816,20.04,-6.85,Yaka,ETHNICITY,ETHNICITY
SM250816,17,-7,Yaka,ETHNICITY,ETHNICITY
SM250816,17,-7,Yaka,ETHNICITY,ETHNICITY
SM249316,17.2713,3.6515,Yaka (Central African Republic),LANGUAGE,LANGUAGE
SM249318,13.3637,-3.733,Yaka (Congo),LANGUAGE,LANGUAGE
SM249313,16.9965,-6.9396,Yaka-Pelende-Lonzo,LANGUAGE,LANGUAGE
SM249313,16.9965,-6.9396,Yaka-Pelende-Lonzo,LANGUAGE,LANGUAGE
SM249313,16.775,-5.984,Yaka-Pelende-Lonzo,LANGUAGE,LANGUAGE
SM249313,16.775,-5.984,Yaka-Pelende-Lonzo,LANGUAGE,LANGUAGE
SM279043,133.5901,34.633,Yakage,ADM2,DISTRICT
SM249319,149.982,-10.0852,Yakaikeke,LANGUAGE,LANGUAGE
SM298855,35.5201,41.6182,Yakakent,ADM2,DISTRICT
SM249321,122.0217,6.531,Yakan,LANGUAGE,LANGUAGE
SM260261,66.948,34.979,Yakawlang,ADM2,DISTRICT
SM303101,-120.7387,46.4569,Yakima,ADM2,DISTRICT
SM258672,-121.53,46.35,Yakima,ETHNICITY,ETHNICITY
SM258672,-121.53,46.35,Yakima,ETHNICITY,ETHNICITY
SM258672,-120.27,46.33,Yakima,ETHNICITY,ETHNICITY
SM258672,-120.27,46.33,Yakima,ETHNICITY,ETHNICITY
SM262304,23.3333,43.6333,Yakimovo,ADM2,DISTRICT
SM303517,66.7713,38.893,Yakkabog',ADM2,DISTRICT
SM18465,69.2456,41.2786,Yakkasaroy Tumani,ADM2,DISTRICT
SM249326,87.9306,27.3714,Yakkha,LANGUAGE,LANGUAGE
SM249328,22.1702,4.1651,Yakoma,LANGUAGE,LANGUAGE
SM6729,22.65,5.54,Yakoma,ETHNICITY,ETHNICITY
SM6729,22.65,5.54,Yakoma,ETHNICITY,ETHNICITY
SM6729,22.3,4.3,Yakoma,ETHNICITY,ETHNICITY
SM6729,22.3,4.3,Yakoma,ETHNICITY,ETHNICITY
SM262293,23.7,42.0833,Yakoruda,ADM2,DISTRICT
SM17150,133.5,44.4167,Yakovlevskiy Rayon,ADM2,DISTRICT
SM294693,36.4167,50.8333,Yakovlevskiy rayon,ADM2,DISTRICT
SM278231,140.1942,42.1878,Yakumo,ADM2,DISTRICT
SM285397,8.175,5.8061,Yakurr,ADM2,DISTRICT
SM250437,7.57,5.69,Yakurr,ETHNICITY,ETHNICITY
SM250437,7.57,5.69,Yakurr,ETHNICITY,ETHNICITY
SM250437,8,6,Yakurr,ETHNICITY,ETHNICITY
SM250437,8,6,Yakurr,ETHNICITY,ETHNICITY
SM258673,-136.33,59.06,Yakutat,ETHNICITY,ETHNICITY
SM18322,-139.1333,59.6667,Yakutat City and Borough,ADM2,DISTRICT
SM294331,129.754,61.9738,Yakutsk gorsovet,ADM2,DISTRICT
SM17151,47.9167,55.1667,Yal'chikskiy Rayon,ADM2,DISTRICT
SM249335,8.6075,6.7385,Yala,LANGUAGE,LANGUAGE
SM3782,101.25,6.3333,Yala,ADM1,DISTRICT
SM285398,8.5494,6.654,Yala Cross,ADM2,DISTRICT
SM285433,-86.4908,13.4797,Yalagüina,ADM2,DISTRICT
SM249338,129.315,-3.3619,Yalahatan-Haruru-Awaiya,LANGUAGE,LANGUAGE
SM249332,-59.9925,-13.2396,Yalakalore,LANGUAGE,LANGUAGE
SM249546,142.808,-4.0991,Yalaku,LANGUAGE,LANGUAGE
SM249334,139.511,-21.731,Yalarnnga,LANGUAGE,LANGUAGE
SM458511,139.7133,-21.8218,Yalarnnga,ETHNICITY,ETHNICITY
SM249341,141.566,-3.7976,Yale,LANGUAGE,LANGUAGE
SM261339,117.1582,-28.4879,Yalgoo,ADM2,DISTRICT
SM276545,139.4218,-3.7881,Yalimo,ADM2,DISTRICT
SM268590,23.5852,7.3728,Yalinga,ADM2,DISTRICT
SM12855,-76.5603,17.8754,Yallahs,ADM2,DISTRICT
SM301505,-89.7076,34.0282,Yalobusha,ADM2,DISTRICT
SM10254,16.9688,5.418,Yaloke Sub-Prefecture,ADM2,DISTRICT
SM3783,29.1667,40.5833,Yalova,ADM1,DISTRICT
SM18290,34.1237,44.4934,Yaltynskyi Raion (excl. Yaltyn),ADM2,DISTRICT
SM249346,-11.2836,9.9719,Yalunka,LANGUAGE,LANGUAGE
SM250817,-11.65,10.97,Yalunka,ETHNICITY,ETHNICITY
SM250817,-11.65,10.97,Yalunka,ETHNICITY,ETHNICITY
SM250817,-12,10,Yalunka,ETHNICITY,ETHNICITY
SM250817,-12,10,Yalunka,ETHNICITY,ETHNICITY
SM298474,31.1904,38.3058,Yalvaç,ADM2,DISTRICT
SM249339,-96.2183,17.2134,Yalálag Zapotec,LANGUAGE,LANGUAGE
SM270171,-74.7902,6.7026,Yalí,ADM2,DISTRICT
SM298689,32.0892,37.3111,Yalıhüyük,ADM2,DISTRICT
SM13120,140.136,37.6174,Yama-gun,ADM2,DISTRICT
SM295735,-88.1721,13.6498,Yamabal,ADM2,DISTRICT
SM258674,-66.47,-6.86,Yamadi,ETHNICITY,ETHNICITY
SM278658,130.7231,33.0559,Yamaga,ADM2,DISTRICT
SM279566,140.3727,38.2491,Yamagata,ADM2,DISTRICT
SM279822,136.7431,35.5989,Yamagata,ADM2,DISTRICT
SM3784,140.0997,38.4503,Yamagata,ADM1:ADMX,DISTRICT
SM13121,132.3484,34.6721,Yamagata-gun,ADM2,DISTRICT
SM3785,131.5667,34.2,Yamaguchi,ADM1:ADMX,DISTRICT
SM13122,131.5467,34.1775,Yamaguchi (2010-),ADM2,DISTRICT
SM295039,70,70,Yamal'skiy rayon,ADM2,DISTRICT
SM3786,76,66,Yamal-Nenets,ADM1,DISTRICT
SM284746,11.4415,10.2381,Yamaltu,ADM2,DISTRICT
SM258675,-68.93,-6.04,Yamamadi,ETHNICITY,ETHNICITY
SM13123,140.2653,40.3601,Yamamoto-gun,ADM2,DISTRICT
SM279616,138.6974,35.7969,Yamanashi,ADM2,DISTRICT
SM3787,138.6,35.6167,Yamanashi,ADM1:ADMX,DISTRICT
SM249348,146.817,-7.0569,Yamap,LANGUAGE,LANGUAGE
SM275666,-88.2465,14.2683,Yamaranguila,ADM2,DISTRICT
SM258676,-81.89,32.22,Yamasee,ETHNICITY,ETHNICITY
SM271807,-70.05,18.7833,Yamasá,ADM2,DISTRICT
SM278546,139.4617,35.4693,Yamato,ADM2,DISTRICT
SM278921,135.7828,34.6492,Yamatokōriyama,ADM2,DISTRICT
SM278922,135.7366,34.5149,Yamatotakada,ADM2,DISTRICT
SM278923,136.0249,34.6673,Yamazoe,ADM2,DISTRICT
SM249355,11.0771,6.5355,Yamba,LANGUAGE,LANGUAGE
SM16360,14.0075,-4.1501,Yamba,ADM2,DISTRICT
SM258677,11.3,4.8,Yambassa,ETHNICITY,ETHNICITY
SM249358,17.5305,2.5041,Yambe,LANGUAGE,LANGUAGE
SM249358,17.5305,2.5041,Yambe,LANGUAGE,LANGUAGE
SM249358,17.113,1.9003,Yambe,LANGUAGE,LANGUAGE
SM249358,17.113,1.9003,Yambe,LANGUAGE,LANGUAGE
SM259448,145.5701,-4.771,Yamben,LANGUAGE,LANGUAGE
SM249357,142.763,-3.5062,Yambes,LANGUAGE,LANGUAGE
SM249356,10.9369,4.8362,Yambeta,LANGUAGE,LANGUAGE
SM458423,147.4753,-22.314,Yambina,ETHNICITY,ETHNICITY
SM262501,26.5,42.4833,Yambol,ADM2,DISTRICT
SM3788,26.6333,42.3333,Yambol,ADM1,DISTRICT
SM249360,131.44,-7.7372,Yamdena,LANGUAGE,LANGUAGE
SM13124,130.6278,33.233,Yame Shi (2010-),ADM2,DISTRICT
SM249362,-73.6667,-4.1667,Yameo,LANGUAGE,LANGUAGE
SM258678,-72.96,-4.53,Yameo,ETHNICITY,ETHNICITY
SM283519,96.1198,20.5022,Yamethin,ADM2,DISTRICT
SM9603,70.7913,36.4368,Yamgan,ADM2,DISTRICT
SM302278,-123.3082,45.2326,Yamhill,ADM2,DISTRICT
SM258679,-123.41,45.22,Yamhill,ETHNICITY,ETHNICITY
SM249363,121.544,22.055,Yami,LANGUAGE,LANGUAGE
SM258680,121.55,22.05,Yami,ETHNICITY,ETHNICITY
SM249365,-71.5964,-9.8846,Yaminahua,LANGUAGE,LANGUAGE
SM249367,139.206,-2.0912,Yamna,LANGUAGE,LANGUAGE
SM249368,22.3073,-0.2425,Yamongeri,LANGUAGE,LANGUAGE
SM275006,-9.1667,7.5,Yamou,ADM1:ADM2,DISTRICT
SM269472,-5.2531,6.8145,Yamoussoukro,ADM2,DISTRICT
SM3789,-5.2833,6.8167,Yamoussoukro,ADM1,DISTRICT
SM262687,-64.9635,-19.1723,Yamparáez,ADM2,DISTRICT
SM249372,87.1309,27.6069,Yamphe,LANGUAGE,LANGUAGE
SM249371,87.0441,27.6322,Yamphu,LANGUAGE,LANGUAGE
SM276857,77.305,30.2364,Yamunanagar,ADM2,DISTRICT
SM284402,100.3943,5.7905,Yan,ADM2,DISTRICT
SM297672,99.7344,7.4258,Yan Ta Khao,ADM2,DISTRICT
SM269290,109.3608,36.6056,Yan'an,ADM2,DISTRICT
SM249418,134.877,-12.0853,Yan-nhangu,LANGUAGE,LANGUAGE
SM458906,135.2364,-12.0077,Yan-nhangu,ETHNICITY,ETHNICITY
SM249380,-121.608,39.2769,Yana,LANGUAGE,LANGUAGE
SM258682,-121.3,40.05,Yana,ETHNICITY,ETHNICITY
SM258682,-121.3,40.05,Yana,ETHNICITY,ETHNICITY
SM258682,-121.3,40.05,Yana,ETHNICITY,ETHNICITY
SM258682,-121.3,40.05,Yana,ETHNICITY,ETHNICITY
SM258682,-121.3,40.05,Yana,ETHNICITY,ETHNICITY
SM258682,-122,40,Yana,ETHNICITY,ETHNICITY
SM258682,-122,40,Yana,ETHNICITY,ETHNICITY
SM258682,-122,40,Yana,ETHNICITY,ETHNICITY
SM258682,-122,40,Yana,ETHNICITY,ETHNICITY
SM258682,-122,40,Yana,ETHNICITY,ETHNICITY
SM258682,-122,41,Yana,ETHNICITY,ETHNICITY
SM258682,-122,41,Yana,ETHNICITY,ETHNICITY
SM258682,-122,41,Yana,ETHNICITY,ETHNICITY
SM258682,-122,41,Yana,ETHNICITY,ETHNICITY
SM258682,-122,41,Yana,ETHNICITY,ETHNICITY
SM258682,-122.89,40.38,Yana,ETHNICITY,ETHNICITY
SM258682,-122.89,40.38,Yana,ETHNICITY,ETHNICITY
SM258682,-122.89,40.38,Yana,ETHNICITY,ETHNICITY
SM258682,-122.89,40.38,Yana,ETHNICITY,ETHNICITY
SM258682,-122.89,40.38,Yana,ETHNICITY,ETHNICITY
SM258682,-122,40.5,Yana,ETHNICITY,ETHNICITY
SM258682,-122,40.5,Yana,ETHNICITY,ETHNICITY
SM258682,-122,40.5,Yana,ETHNICITY,ETHNICITY
SM258682,-122,40.5,Yana,ETHNICITY,ETHNICITY
SM258682,-122,40.5,Yana,ETHNICITY,ETHNICITY
SM258683,79.17,13,Yanadi,ETHNICITY,ETHNICITY
SM279721,130.4073,33.1493,Yanagawa,ADM2,DISTRICT
SM249381,-76.5314,-10.4929,Yanahuanca Pasco Quechua,LANGUAGE,LANGUAGE
SM279588,132.1311,33.9624,Yanai,ADM2,DISTRICT
SM277140,82.2177,16.7346,Yanam,ADM2,DISTRICT
SM17152,54.9167,56.25,Yanaul'skiy Rayon,ADM2,DISTRICT
SM269241,129.1283,43.1258,Yanbian Korean,ADM2,DISTRICT
SM17825,38.3333,24.75,Yanbu al Bahr,ADM2,DISTRICT
SM301932,-82.3076,35.8989,Yancey,ADM2,DISTRICT
SM269211,120.213,33.551,Yancheng,ADM2,DISTRICT
SM249383,140.5135,-21.2327,Yanda,LANGUAGE,LANGUAGE
SM458512,140.7388,-22.0847,Yanda,ETHNICITY,ETHNICITY
SM249388,-3.15,14.63,Yanda Dom Dogon,LANGUAGE,LANGUAGE
SM249392,145.6474,-24.747,Yandjibara,LANGUAGE,LANGUAGE
SM458686,145.6109,-24.6563,Yandjibara,ETHNICITY,ETHNICITY
SM258685,134.94,-12.48,Yandjinung,ETHNICITY,ETHNICITY
SM258685,134.94,-12.48,Yandjinung,ETHNICITY,ETHNICITY
SM258685,134.8635,-12.3379,Yandjinung,ETHNICITY,ETHNICITY
SM258685,134.8635,-12.3379,Yandjinung,ETHNICITY,ETHNICITY
SM249385,140.583,-28.4744,Yandruwandha,LANGUAGE,LANGUAGE
SM249393,-75.315,-10.5454,Yanesha',LANGUAGE,LANGUAGE
SM283384,-8.0365,11.0074,Yanfolila,ADM2,DISTRICT
SM297568,104.3908,15.2369,Yang Chum Noi,ADM2,DISTRICT
SM297073,103.0892,15.6495,Yang Si Surat,ADM2,DISTRICT
SM296942,103.3409,16.4396,Yang Talat,ADM2,DISTRICT
SM249394,106.3932,23.0919,Yang Zhuang,LANGUAGE,LANGUAGE
SM282836,-96.7979,18.8179,Yanga,ADM2,DISTRICT
SM459207,143.4655,-19.0858,Yanga,ETHNICITY,ETHNICITY
SM249413,141.846,-12.032,Yangathimri,LANGUAGE,LANGUAGE
SM249401,11.0277,4.4197,Yangben,LANGUAGE,LANGUAGE
SM249409,94.2543,18.524,Yangbye,LANGUAGE,LANGUAGE
SM280626,126.8583,37.5216,Yangcheon,ADM2,DISTRICT
SM289069,126.8053,39.1906,Yangdŏk,ADM2,DISTRICT
SM250069,18.57,4.7,Yangere,ETHNICITY,ETHNICITY
SM280688,127.9856,38.1016,Yanggu,ADM2,DISTRICT
SM249400,13.0213,-1.9222,Yangho,LANGUAGE,LANGUAGE
SM9604,69.6702,37.4608,Yangi Qal'ah (2005-),ADM2,DISTRICT
SM303535,60.5833,41.3333,Yangiariq,ADM2,DISTRICT
SM303527,60.4839,41.7037,Yangibozor,ADM2,DISTRICT
SM18466,70.1078,41.1278,Yangiobod Shahri,ADM2,DISTRICT
SM18467,68.8255,40.2769,Yangiyer Shahri,ADM2,DISTRICT
SM303449,69,41.1667,Yangiyo'l,ADM2,DISTRICT
SM249415,111.9667,21.7896,Yangjiang,DIALECT,LANGUAGE
SM269423,111.6922,22.079,Yangjiang,ADM2,DISTRICT
SM280721,127.046,37.8173,Yangju,ADM2,DISTRICT
SM241300,138.023,-16.74,Yangkaal,LANGUAGE,LANGUAGE
SM458493,138.9811,-16.9049,Yangkaal,ETHNICITY,ETHNICITY
SM249398,10.2881,9.4496,Yangkam,LANGUAGE,LANGUAGE
SM249412,99.0463,25.2248,Yangliu,LANGUAGE,LANGUAGE
SM249396,132.52,-14.4964,Yangman,LANGUAGE,LANGUAGE
SM258688,131.68,-14.91,Yangman,ETHNICITY,ETHNICITY
SM258688,131.68,-14.91,Yangman,ETHNICITY,ETHNICITY
SM258688,132.4995,-15.4143,Yangman,ETHNICITY,ETHNICITY
SM258688,132.4995,-15.4143,Yangman,ETHNICITY,ETHNICITY
SM249397,19.0108,2.8152,Yango,LANGUAGE,LANGUAGE
SM3790,96.1667,17,Yangon,ADM1,DISTRICT
SM283492,96.1289,16.8717,Yangon-E,ADM2,DISTRICT
SM283492,96.1289,16.8717,Yangon-E,ADM2,DISTRICT
SM283492,96.248,16.8979,Yangon-E,ADM2,DISTRICT
SM283492,96.248,16.8979,Yangon-E,ADM2,DISTRICT
SM283493,96.0304,17.2692,Yangon-N,ADM2,DISTRICT
SM283494,96.4999,16.7405,Yangon-S,ADM2,DISTRICT
SM259524,96.1387,16.786,Yangoon Myanmar Sign Language,LANGUAGE,LANGUAGE
SM288631,143.4667,-3.8167,Yangoro-Saussia,ADM2,DISTRICT
SM280722,127.4922,37.4888,Yangpyeong,ADM2,DISTRICT
SM269310,113.4963,38.1111,Yangquan,ADM2,DISTRICT
SM280554,129.0361,35.3987,Yangsan,ADM2,DISTRICT
SM3791,91.4547,27.5847,Yangtse,ADM1,DISTRICT
SM249406,145.858,-5.527,Yangulam,LANGUAGE,LANGUAGE
SM249405,142.4815,-3.5116,Yangum Dey,LANGUAGE,LANGUAGE
SM249403,142.45,-3.53,Yangum Gel,LANGUAGE,LANGUAGE
SM249404,142.527,-3.55,Yangum Mon,LANGUAGE,LANGUAGE
SM280689,128.6147,38.0622,Yangyang,ADM2,DISTRICT
SM269212,119.5253,32.732,Yangzhou,ADM2,DISTRICT
SM249414,119.2092,32.4171,Yangzhou Mandarin,DIALECT,LANGUAGE
SM261750,138.3423,-35.4939,Yankalilla,ADM2,DISTRICT
SM258689,146.57,-24.17,Yankibura,ETHNICITY,ETHNICITY
SM258689,146.57,-24.17,Yankibura,ETHNICITY,ETHNICITY
SM258689,144.2752,-24.5383,Yankibura,ETHNICITY,ETHNICITY
SM258689,144.2752,-24.5383,Yankibura,ETHNICITY,ETHNICITY
SM302539,-97.3948,43.009,Yankton,ADM2,DISTRICT
SM249417,133.577,-26.4064,Yankunytjatjara,LANGUAGE,LANGUAGE
SM284801,8.5369,12.7697,Yankwashi,ADM2,DISTRICT
SM297189,100.5395,13.6918,Yannawa,ADM2,DISTRICT
SM258489,-64.24,3.33,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-64.24,3.33,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-64.24,3.33,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-64.24,3.33,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-63.75,2.5,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-63.75,2.5,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-63.75,2.5,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-63.75,2.5,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-65,2,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-65,2,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-65,2,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-65,2,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-65,2.42,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-65,2.42,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-65,2.42,Yanomamo,ETHNICITY,ETHNICITY
SM258489,-65,2.42,Yanomamo,ETHNICITY,ETHNICITY
SM249419,-64.8781,1.6797,Yanomamö,LANGUAGE,LANGUAGE
SM249420,-62.8522,2.49,Yanomám,LANGUAGE,LANGUAGE
SM249429,18.2341,-3.8231,Yansi,LANGUAGE,LANGUAGE
SM269297,120.8153,37.217,Yantai,ADM2,DISTRICT
SM292811,47.8333,55.5,Yantikovskiy rayon,ADM2,DISTRICT
SM258690,141.33,-28.77,Yantruwunta,ETHNICITY,ETHNICITY
SM258690,141.33,-28.77,Yantruwunta,ETHNICITY,ETHNICITY
SM258690,140.3919,-28.3258,Yantruwunta,ETHNICITY,ETHNICITY
SM258690,140.3919,-28.3258,Yantruwunta,ETHNICITY,ETHNICITY
SM273582,-78.75,-3.85,Yantzaza,ADM2,DISTRICT
SM249432,136.055,-15.7621,Yanyuwa,LANGUAGE,LANGUAGE
SM250819,18.72,-4.28,Yanzi,ETHNICITY,ETHNICITY
SM250819,18.72,-4.28,Yanzi,ETHNICITY,ETHNICITY
SM250819,18,-4,Yanzi,ETHNICITY,ETHNICITY
SM250819,18,-4,Yanzi,ETHNICITY,ETHNICITY
SM249438,35.2387,-13.6119,Yao,LANGUAGE,LANGUAGE
SM279127,135.601,34.6263,Yao,ADM2,DISTRICT
SM250820,36.69,-16.04,Yao,ETHNICITY,ETHNICITY
SM250820,36.69,-16.04,Yao,ETHNICITY,ETHNICITY
SM250820,36.69,-16.04,Yao,ETHNICITY,ETHNICITY
SM250820,36.69,-16.04,Yao,ETHNICITY,ETHNICITY
SM250820,35.55,-14.53,Yao,ETHNICITY,ETHNICITY
SM250820,35.55,-14.53,Yao,ETHNICITY,ETHNICITY
SM250820,35.55,-14.53,Yao,ETHNICITY,ETHNICITY
SM250820,35.55,-14.53,Yao,ETHNICITY,ETHNICITY
SM250820,35.68,-12.57,Yao,ETHNICITY,ETHNICITY
SM250820,35.68,-12.57,Yao,ETHNICITY,ETHNICITY
SM250820,35.68,-12.57,Yao,ETHNICITY,ETHNICITY
SM250820,35.68,-12.57,Yao,ETHNICITY,ETHNICITY
SM250820,37.56,-11.45,Yao,ETHNICITY,ETHNICITY
SM250820,37.56,-11.45,Yao,ETHNICITY,ETHNICITY
SM250820,37.56,-11.45,Yao,ETHNICITY,ETHNICITY
SM250820,37.56,-11.45,Yao,ETHNICITY,ETHNICITY
SM258691,105.94,21.3,Yao,ETHNICITY,ETHNICITY
SM258691,112.78,26.7,Yao,ETHNICITY,ETHNICITY
SM250820,34.5,-14.6335,Yao,ETHNICITY,ETHNICITY
SM250820,34.5,-14.6335,Yao,ETHNICITY,ETHNICITY
SM250820,34.5,-14.6335,Yao,ETHNICITY,ETHNICITY
SM250820,34.5,-14.6335,Yao,ETHNICITY,ETHNICITY
SM250820,36,-13,Yao,ETHNICITY,ETHNICITY
SM250820,36,-13,Yao,ETHNICITY,ETHNICITY
SM250820,36,-13,Yao,ETHNICITY,ETHNICITY
SM250820,36,-13,Yao,ETHNICITY,ETHNICITY
SM250820,35.0083,-15.7856,Yao,ETHNICITY,ETHNICITY
SM250820,35.0083,-15.7856,Yao,ETHNICITY,ETHNICITY
SM250820,35.0083,-15.7856,Yao,ETHNICITY,ETHNICITY
SM250820,35.0083,-15.7856,Yao,ETHNICITY,ETHNICITY
SM282348,-97.4518,19.9002,Yaonáhuac,ADM2,DISTRICT
SM258692,122.34,-17.6,Yaora,ETHNICITY,ETHNICITY
SM258692,122.34,-17.6,Yaora,ETHNICITY,ETHNICITY
SM258692,122.6253,-17.9439,Yaora,ETHNICITY,ETHNICITY
SM258692,122.6253,-17.9439,Yaora,ETHNICITY,ETHNICITY
SM258693,151.29,-29.02,Yaoro,ETHNICITY,ETHNICITY
SM258693,151.29,-29.02,Yaoro,ETHNICITY,ETHNICITY
SM258693,152.5468,-27.7085,Yaoro,ETHNICITY,ETHNICITY
SM258693,152.5468,-27.7085,Yaoro,ETHNICITY,ETHNICITY
SM249439,138.582,-5.6102,Yaosakor Asmat,LANGUAGE,LANGUAGE
SM249440,-5.5785,6.9528,Yaouré,LANGUAGE,LANGUAGE
SM3792,138.097,9.538,Yap,ADM1,DISTRICT
SM258694,138.11,9.54,Yap,ETHNICITY,ETHNICITY
SM258694,138.11,9.54,Yap,ETHNICITY,ETHNICITY
SM258694,138.11,9.54,Yap,ETHNICITY,ETHNICITY
SM258694,138.13,9.55,Yap,ETHNICITY,ETHNICITY
SM258694,138.13,9.55,Yap,ETHNICITY,ETHNICITY
SM258694,138.13,9.55,Yap,ETHNICITY,ETHNICITY
SM258694,138.16,9.52,Yap,ETHNICITY,ETHNICITY
SM258694,138.16,9.52,Yap,ETHNICITY,ETHNICITY
SM258694,138.16,9.52,Yap,ETHNICITY,ETHNICITY
SM249441,138.125,9.5581,Yapese,LANGUAGE,LANGUAGE
SM298277,33.7838,40.7686,Yapraklı,ADM2,DISTRICT
SM459152,116.6868,-20.6715,Yapurarra,ETHNICITY,ETHNICITY
SM249450,139.249,-6.6663,Yaqay,LANGUAGE,LANGUAGE
SM249452,-110.681,29.9446,Yaqui,LANGUAGE,LANGUAGE
SM258695,-110.4,27.75,Yaqui,ETHNICITY,ETHNICITY
SM258695,-110.4,27.75,Yaqui,ETHNICITY,ETHNICITY
SM258695,-110.4,27.75,Yaqui,ETHNICITY,ETHNICITY
SM258695,-110,28,Yaqui,ETHNICITY,ETHNICITY
SM258695,-110,28,Yaqui,ETHNICITY,ETHNICITY
SM258695,-110,28,Yaqui,ETHNICITY,ETHNICITY
SM258695,-109.9598,27.5767,Yaqui,ETHNICITY,ETHNICITY
SM258695,-109.9598,27.5767,Yaqui,ETHNICITY,ETHNICITY
SM258695,-109.9598,27.5767,Yaqui,ETHNICITY,ETHNICITY
SM258696,-123.8,44.91,Yaquina,ETHNICITY,ETHNICITY
SM228948,5.8421,10.3419,Yara,LANGUAGE,LANGUAGE
SM271105,-76.9543,20.2733,Yara,ADM2,DISTRICT
SM3793,-68.7373,10.3397,Yaracuy,ADM1,DISTRICT
SM249458,139.2054,-35.5756,Yaraldi,DIALECT,LANGUAGE
SM297290,101.3141,6.6924,Yarang,ADM2,DISTRICT
SM293296,48,57.1667,Yaranskiy rayon,ADM2,DISTRICT
SM249454,145.454,-4.9394,Yarawata,LANGUAGE,LANGUAGE
SM459306,142.7057,-11.0918,Yaraytyana,ETHNICITY,ETHNICITY
SM249460,141.7735,-29.7954,Yardliyawarra,DIALECT,LANGUAGE
SM261939,48.25,38.9167,Yardımlı,ADM1:ADM2,DISTRICT
SM249461,148.605,-9.5065,Yareba,LANGUAGE,LANGUAGE
SM3794,166.916,-0.5472,Yaren,ADM1,DISTRICT
SM249462,-96.7052,17.3406,Yareni Zapotec,LANGUAGE,LANGUAGE
SM258697,151.57,-26.9,Yargo,ETHNICITY,ETHNICITY
SM258697,151.57,-26.9,Yargo,ETHNICITY,ETHNICITY
SM258697,151.6778,-27.2117,Yargo,ETHNICITY,ETHNICITY
SM258697,151.6778,-27.2117,Yargo,ETHNICITY,ETHNICITY
SM304876,44.3118,14.2539,Yarim,ADM2,DISTRICT
SM297291,101.3907,6.8351,Yaring,ADM2,DISTRICT
SM249469,140.9592,-29.4905,Yarli,LANGUAGE,LANGUAGE
SM458132,132.132,-20.4836,Yarlpiri,ETHNICITY,ETHNICITY
SM249471,139.5844,-25.8371,Yarluyandi,DIALECT,LANGUAGE
SM258698,150.4,-27.1,Yarmbura,ETHNICITY,ETHNICITY
SM370183,-8.6162,7.572,Yarmein,ADM2:ADM3,DISTRICT
SM268831,-66.0322,43.9502,Yarmouth,ADM2,DISTRICT
SM258699,-77.51,-10.43,Yaro,ETHNICITY,ETHNICITY
SM249472,-62.2302,1.7202,Yaroame,LANGUAGE,LANGUAGE
SM3795,39.5,58,Yaroslavl',ADM1,DISTRICT
SM17153,40,57.5833,Yaroslavskiy Rayon,ADM2,DISTRICT
SM286730,-76.5952,-9.8026,Yarowilca,ADM2,DISTRICT
SM261291,144.9959,-37.8018,Yarra,ADM2,DISTRICT
SM261292,145.7371,-37.7501,Yarra Ranges,ADM2,DISTRICT
SM261680,145.8806,-16.9946,Yarrabah,ADM2,DISTRICT
SM261294,142.4045,-36.0262,Yarriambiack,ADM2,DISTRICT
SM458697,143.8487,-25.9743,Yarrumada,ETHNICITY,ETHNICITY
SM249473,139.472,-2.2122,Yarsun,LANGUAGE,LANGUAGE
SM249477,-62,-10.25,Yaru,LANGUAGE,LANGUAGE
SM275689,-88.08,14.1,Yarula,ADM2,DISTRICT
SM270172,-75.454,6.9853,Yarumal,ADM2,DISTRICT
SM249475,-52.8,-11.6333,Yarumá,LANGUAGE,LANGUAGE
SM283840,96.759,48.1065,Yaruu,ADM2,DISTRICT
SM280940,-9.0824,6.6109,Yarwein-Mehnsohnne District,ADM2,DISTRICT
SM249465,-74.3715,1.4302,Yarí,LANGUAGE,LANGUAGE
SM249480,10.678,2.0604,Yasa,LANGUAGE,LANGUAGE
SM258700,9.95,2.23,Yasa,ETHNICITY,ETHNICITY
SM258700,9.95,2.23,Yasa,ETHNICITY,ETHNICITY
SM258700,9.8,2.5,Yasa,ETHNICITY,ETHNICITY
SM258700,9.8,2.5,Yasa,ETHNICITY,ETHNICITY
SM293056,42.5,46.25,Yashaltinskiy rayon,ADM2,DISTRICT
SM279215,139.8467,35.8092,Yashio,ADM2,DISTRICT
SM17154,85.5,56,Yashkinskiy Rayon,ADM2,DISTRICT
SM293057,45.5,46.3333,Yashkul'skiy rayon,ADM2,DISTRICT
SM294724,37.75,54.5,Yasnogorskiy rayon,ADM2,DISTRICT
SM3796,104.2833,15.85,Yasothon,ADM1,DISTRICT
SM261498,148.9724,-34.9263,Yass Valley,ADM2,DISTRICT
SM279241,136.0233,35.068,Yasu,ADM2,DISTRICT
SM279263,133.1976,35.3357,Yasugi,ADM2,DISTRICT
SM15380,-55.8698,-24.5626,Yasy Kany,ADM2,DISTRICT
SM289554,-56.4754,-25.6663,Yataity del Guairá,ADM2,DISTRICT
SM289645,-56.329,-24.8374,Yataity del Norte,ADM2,DISTRICT
SM459307,142.6241,-10.6831,Yatay,ETHNICITY,ETHNICITY
SM298763,28.1144,37.3586,Yatağan,ADM2,DISTRICT
SM258703,145.75,-6.38,Yate,ETHNICITY,ETHNICITY
SM249489,-96.198,17.2888,Yatee Zapotec,LANGUAGE,LANGUAGE
SM262187,-2.4167,13.5833,Yatenga (1995-),ADM1:ADM2,DISTRICT
SM271115,-74.9197,20.3913,Yateras,ADM2,DISTRICT
SM301926,-77.1055,42.6334,Yates,ADM2,DISTRICT
SM278081,136.7245,35.1094,Yatomi,ADM2,DISTRICT
SM278660,130.7497,32.4906,Yatsushiro,ADM2,DISTRICT
SM289584,-55.0518,-26.6719,Yatytay,ADM2,DISTRICT
SM249494,-96.284,17.1869,Yatzachi Zapotec,LANGUAGE,LANGUAGE
SM284587,166.75,-22.0833,Yaté,ADM2,DISTRICT
SM249496,146.841,-6.0827,Yau (Morobe Province),LANGUAGE,LANGUAGE
SM249502,141.974,-3.4663,Yau (Sandaun Province),LANGUAGE,LANGUAGE
SM3797,114.1692,22.3133,Yau Tsim Mong,ADM1,DISTRICT
SM3798,-66.8552,18.0786,Yauco,ADM1,DISTRICT
SM16287,-66.8493,18.0363,Yauco Barrio-Pueblo,ADM2,DISTRICT
SM454627,-118.8,35.3,Yauelmani Yokuts,ETHNICITY,ETHNICITY
SM282716,-98.176,19.4004,Yauhquemecan,ADM2,DISTRICT
SM286752,-76.1667,-11.6667,Yauli,ADM2,DISTRICT
SM249497,21.8814,-15.8215,Yauma,LANGUAGE,LANGUAGE
SM249499,134.879,-3.3252,Yaur,LANGUAGE,LANGUAGE
SM16288,-66.0647,18.0243,Yaurel Barrio,ADM2,DISTRICT
SM284943,4.5149,11.0214,Yauri,ADM2,DISTRICT
SM284943,4.5149,11.0214,Yauri,ADM2,DISTRICT
SM284943,4.742,10.7764,Yauri,ADM2,DISTRICT
SM284943,4.742,10.7764,Yauri,ADM2,DISTRICT
SM258707,139.16,-28.27,Yaurorka,ETHNICITY,ETHNICITY
SM258707,139.16,-28.27,Yaurorka,ETHNICITY,ETHNICITY
SM258707,140.4391,-26.5344,Yaurorka,ETHNICITY,ETHNICITY
SM258707,140.4391,-26.5344,Yaurorka,ETHNICITY,ETHNICITY
SM282121,-99.0434,18.8847,Yautepec,ADM2,DISTRICT
SM249503,-95.9283,16.4415,Yautepec Zapotec,LANGUAGE,LANGUAGE
SM258708,-76.5,-12.57,Yauyo,ETHNICITY,ETHNICITY
SM286777,-76,-12.4167,Yauyos,ADM2,DISTRICT
SM275904,-87.0761,13.7487,Yauyupe,ADM2,DISTRICT
SM301793,-112.5539,34.5999,Yavapai,ADM2,DISTRICT
SM258706,-112.6,33.74,Yavapai-generic,ETHNICITY,ETHNICITY
SM270652,-69.6296,0.8283,Yavaraté,ADM2,DISTRICT
SM277061,78.35,20.15,Yavatmal,ADM2,DISTRICT
SM454366,-113.23,35.22,Yavbe Yavapai,ETHNICITY,ETHNICITY
SM454366,-113.23,35.22,Yavbe Yavapai,ETHNICITY,ETHNICITY
SM454366,-112,35,Yavbe Yavapai,ETHNICITY,ETHNICITY
SM454366,-112,35,Yavbe Yavapai,ETHNICITY,ETHNICITY
SM260831,-65.6,-22.3,Yavi,ADM2,DISTRICT
SM249508,-68.0842,2.8003,Yavitero,LANGUAGE,LANGUAGE
SM249508,-68.0842,2.8003,Yavitero,LANGUAGE,LANGUAGE
SM249508,-67.4389,2.9248,Yavitero,LANGUAGE,LANGUAGE
SM249508,-67.4389,2.9248,Yavitero,LANGUAGE,LANGUAGE
SM298428,37.5696,37.3234,Yavuzeli,ADM2,DISTRICT
SM249521,94.8333,21.6,Yaw,DIALECT,LANGUAGE
SM240867,136.263,-1.772,Yawa,LANGUAGE,LANGUAGE
SM258711,151.27,-25.98,Yawai,ETHNICITY,ETHNICITY
SM258711,151.27,-25.98,Yawai,ETHNICITY,ETHNICITY
SM258711,152.0389,-24.8073,Yawai,ETHNICITY,ETHNICITY
SM258711,152.0389,-24.8073,Yawai,ETHNICITY,ETHNICITY
SM258712,-52.04,-12.33,Yawalapiti,ETHNICITY,ETHNICITY
SM249512,-53.4236,-12.5104,Yawalapití,LANGUAGE,LANGUAGE
SM9605,70.3804,37.5281,Yawan,ADM2,DISTRICT
SM249511,-71.8199,-8.8756,Yawanawa,LANGUAGE,LANGUAGE
SM258713,-65.01,5.43,Yawani,ETHNICITY,ETHNICITY
SM249509,140.871,-26.7334,Yawarawarga,LANGUAGE,LANGUAGE
SM278684,135.7147,34.8666,Yawata,ADM2,DISTRICT
SM279636,132.4234,33.463,Yawatahama,ADM2,DISTRICT
SM249515,145.359,-6.1868,Yaweyuha,LANGUAGE,LANGUAGE
SM249517,124.5297,-15.3481,Yawijibaya,DIALECT,LANGUAGE
SM458649,124.1488,-16.0307,Yawijibaya,ETHNICITY,ETHNICITY
SM249516,142.112,-4.5813,Yawiyo,LANGUAGE,LANGUAGE
SM249520,122.693,-18.004,Yawuru,LANGUAGE,LANGUAGE
SM282938,-88.7805,20.4725,Yaxcabá,ADM2,DISTRICT
SM14908,-96.4724,16.7264,Yaxe,ADM2,DISTRICT
SM282939,-89.4344,21.0562,Yaxkukul,ADM2,DISTRICT
SM16361,12.9739,-3.0374,Yaya,ADM2,DISTRICT
SM16289,-66.7654,18.1899,Yayales Barrio,ADM2,DISTRICT
SM295708,-88.0189,13.4428,Yayantique,ADM2,DISTRICT
SM249522,153.1878,-29.6867,Yaygir,LANGUAGE,LANGUAGE
SM458191,153.1878,-29.6867,Yaygirr^,ETHNICITY,ETHNICITY
SM298458,36.0975,35.9517,Yayladağı,ADM2,DISTRICT
SM298207,40.3425,38.4984,Yayladere,ADM2,DISTRICT
SM17155,86.4167,56.1667,Yayskiy Rayon,ADM2,DISTRICT
SM3799,55.5833,32.5,Yazd,ADM1,DISTRICT
SM249525,71.565,38.2801,Yazgulyam,LANGUAGE,LANGUAGE
SM258715,46.6,36.9,Yazidi,ETHNICITY,ETHNICITY
SM301506,-90.3964,32.7803,Yazoo,ADM2,DISTRICT
SM13125,134.3406,35.3194,Yazu-gun,ADM2,DISTRICT
SM18469,71.617,40.6538,Yazyavan,ADM2,DISTRICT
SM298714,38.1797,38.5957,Yazıhan,ADM2,DISTRICT
SM298436,38.6324,40.8571,Yağlıdere,ADM2,DISTRICT
SM289526,-56.3814,-22.9961,Yby Yaù,ADM2,DISTRICT
SM289625,-56.9377,-26.0384,Ybycui,ADM2,DISTRICT
SM15381,-55.1162,-24.3509,Ybyrarobana,ADM2,DISTRICT
SM289626,-56.7671,-25.7809,Ybytimí,ADM2,DISTRICT
SM296419,15.2627,57.8636,Ydre,ADM2,DISTRICT
SM14129,-9.6896,6.8414,Yeallequelleh District,ADM2,DISTRICT
SM249436,-61.8102,10.1257,Yebarana,LANGUAGE,LANGUAGE
SM282122,-98.8722,18.8591,Yecapixtla,ADM2,DISTRICT
SM280743,128.4301,36.6527,Yecheon,ADM2,DISTRICT
SM282837,-96.7792,19.8521,Yecuatla,ADM2,DISTRICT
SM249528,-96.8741,20.1765,Yecuatla Totonac,LANGUAGE,LANGUAGE
SM370144,-7.6667,4.6667,Yederobo District,ADM2:ADM3,DISTRICT
SM298208,40.5448,39.434,Yedisu,ADM2,DISTRICT
SM17156,39.0833,55.4167,Yegor'yevskiy Rayon,ADM2,DISTRICT
SM16290,-66.8905,18.4757,Yeguada Barrio (Camuy),ADM2,DISTRICT
SM16291,-66.425,18.4776,Yeguada Barrio (Vega Baja),ADM2,DISTRICT
SM282349,-97.6835,18.7621,Yehualtepec,ADM2,DISTRICT
SM249533,140.863,-7.8749,Yei,LANGUAGE,LANGUAGE
SM258717,141.17,-7.86,Yei,ETHNICITY,ETHNICITY
SM17893,30.3501,4.239,Yei County,ADM2,DISTRICT
SM346073,38.824,9.049,Yeka,ADM2:ADM3,DISTRICT
SM250668,23.28,-6.26,Yeke,ETHNICITY,ETHNICITY
SM250668,23.28,-6.26,Yeke,ETHNICITY,ETHNICITY
SM250668,28,-11,Yeke,ETHNICITY,ETHNICITY
SM250668,28,-11,Yeke,ETHNICITY,ETHNICITY
SM249535,6.4492,7.102,Yekhee,LANGUAGE,LANGUAGE
SM249536,147.463,-7.8625,Yekora,LANGUAGE,LANGUAGE
SM293651,43.8333,54.5833,Yel'nikovskiy rayon,ADM2,DISTRICT
SM262594,28.9167,51.75,Yel'sk,ADM2,DISTRICT
SM249538,22.5891,-2.462,Yela-Kela,LANGUAGE,LANGUAGE
SM294642,52.0667,55.75,Yelabuga,ADM2,DISTRICT
SM294643,51.8333,55.8333,Yelabuzhskiy rayon,ADM2,DISTRICT
SM249541,154.127,-11.3796,Yele,LANGUAGE,LANGUAGE
SM17157,38.5,52.5833,Yeletskiy Rayon,ADM2,DISTRICT
SM302424,-93.4113,35.0026,Yell,ADM2,DISTRICT
SM301424,-95.8684,44.7163,Yellow Medicine,ADM2,DISTRICT
SM10199,-100.4827,50.4846,Yellowhead,ADM2,DISTRICT
SM324112,-116.7134,53.4104,Yellowhead County,ADM2:ADM3,DISTRICT
SM258720,-112.25,62.85,Yellowknife,ETHNICITY,ETHNICITY
SM301673,-108.2744,45.9373,Yellowstone,ADM2,DISTRICT
SM324103,-114.3796,53.7338,Yellowstone,ADM2:ADM3,DISTRICT
SM249544,139.096,-7.4453,Yelmek,LANGUAGE,LANGUAGE
SM249544,139.096,-7.4453,Yelmek,LANGUAGE,LANGUAGE
SM258721,139.35,-7.55,Yelmek,ETHNICITY,ETHNICITY
SM249544,139.32,-7.93,Yelmek,LANGUAGE,LANGUAGE
SM249544,139.32,-7.93,Yelmek,LANGUAGE,LANGUAGE
SM258722,139.01,-26.17,Yelyuyendi,ETHNICITY,ETHNICITY
SM258722,139.01,-26.17,Yelyuyendi,ETHNICITY,ETHNICITY
SM258722,139.4987,-25.9045,Yelyuyendi,ETHNICITY,ETHNICITY
SM258722,139.4987,-25.9045,Yelyuyendi,ETHNICITY,ETHNICITY
SM274321,37.5,7.8333,Yem,ADM2,DISTRICT
SM250823,37.23,7.94,Yem,ETHNICITY,ETHNICITY
SM250823,37.23,7.94,Yem,ETHNICITY,ETHNICITY
SM250823,38,8,Yem,ETHNICITY,ETHNICITY
SM250823,38,8,Yem,ETHNICITY,ETHNICITY
SM258723,147.39,-7.86,Yemai,ETHNICITY,ETHNICITY
SM249548,10.0214,5.4742,Yemba,LANGUAGE,LANGUAGE
SM8009,10.77,3.14,Yemba,ETHNICITY,ETHNICITY
SM258725,44.2,15.35,Yemeni Jew,ETHNICITY,ETHNICITY
SM258725,44.2,15.35,Yemeni Jew,ETHNICITY,ETHNICITY
SM258725,45,15,Yemeni Jew,ETHNICITY,ETHNICITY
SM258725,45,15,Yemeni Jew,ETHNICITY,ETHNICITY
SM249549,44.2003,15.3545,Yemeni Sign Language,LANGUAGE,LANGUAGE
SM258726,-75.12,8.03,Yemici,ETHNICITY,ETHNICITY
SM258727,56.37,31.52,Yemreli,ETHNICITY,ETHNICITY
SM249550,37.4412,7.7982,Yemsa,LANGUAGE,LANGUAGE
SM249552,11.8,9,Yendang,LANGUAGE,LANGUAGE
SM249551,12.1396,9.0159,Yendang (Retired),LANGUAGE,LANGUAGE
SM4374,-0.0167,9.4333,Yende (2012-),ADM2,DISTRICT
SM285337,6.3593,5.0316,Yenegoa,ADM2,DISTRICT
SM249557,11.5392,6.4895,Yeni,LANGUAGE,LANGUAGE
SM298573,32.3366,41.2101,Yenice,ADM2,DISTRICT
SM298265,27.2653,39.9422,Yenice,ADM2,DISTRICT
SM249554,8.4727,48.2483,Yeniche,LANGUAGE,LANGUAGE
SM299029,35.0146,39.2107,Yenifakılı,ADM2,DISTRICT
SM298898,32.6956,39.9904,Yenimahalle,ADM2,DISTRICT
SM298200,30.5082,40.1894,Yenipazar,ADM2,DISTRICT
SM298152,28.1844,37.8333,Yenipazar,ADM2,DISTRICT
SM18110,40.2061,37.9137,Yenisehir (Diyarbakır),ADM2,DISTRICT
SM18111,34.5762,36.816,Yenisehir (Mersin),ADM2,DISTRICT
SM298224,32.0492,40.7777,Yeniçağa,ADM2,DISTRICT
SM298475,31.3718,37.7194,Yenişarbademli,ADM2,DISTRICT
SM298252,29.6576,40.2743,Yenişehir,ADM2,DISTRICT
SM280723,127.6367,37.2956,Yeoju,ADM2,DISTRICT
SM280725,127.0758,38.094,Yeoncheon,ADM2,DISTRICT
SM280600,126.6265,34.7979,Yeongam,ADM2,DISTRICT
SM280745,129,36,Yeongcheon,ADM2,DISTRICT
SM280746,129.3108,36.4813,Yeongdeok,ADM2,DISTRICT
SM280627,126.9031,37.5261,Yeongdeungpo,ADM2,DISTRICT
SM280532,129.0648,35.0785,Yeongdo,ADM2,DISTRICT
SM280644,127.7937,36.2166,Yeongdong,ADM2,DISTRICT
SM280601,126.4362,35.2871,Yeonggwang,ADM2,DISTRICT
SM280610,127.2966,36.5931,Yeongi,ADM2,DISTRICT
SM280747,128.5863,36.8746,Yeongju,ADM2,DISTRICT
SM280690,128.4615,37.1833,Yeongwol,ADM2,DISTRICT
SM280748,129.142,36.6959,Yeongyang,ADM2,DISTRICT
SM280533,129.0829,35.1821,Yeonje,ADM2,DISTRICT
SM280563,126.6649,37.4191,Yeonsu,ADM2,DISTRICT
SM280602,127.6425,34.7765,Yeosu,ADM2,DISTRICT
SM275395,-90.9742,14.4673,Yepocapa,ADM2,DISTRICT
SM249559,142.947,-4.2883,Yerakai,LANGUAGE,LANGUAGE
SM258730,142.37,-4.49,Yerakai,ETHNICITY,ETHNICITY
SM258731,142.91,-4.57,Yeraki,ETHNICITY,ETHNICITY
SM295126,112.25,53,Yeravninskiy rayon,ADM2,DISTRICT
SM261073,-65.3173,-26.8183,Yerba Buena,ADM2,DISTRICT
SM249562,134.656,-3.0171,Yeretuar,LANGUAGE,LANGUAGE
SM249448,142.436,-3.4726,Yeri,LANGUAGE,LANGUAGE
SM232995,135.025,-3.628,Yerisiam,LANGUAGE,LANGUAGE
SM299030,34.4839,39.6442,Yerköy,ADM2,DISTRICT
SM294297,53.6667,54,Yermekeyevskiy rayon,ADM2,DISTRICT
SM249567,105.754,23.342,Yerong-Southern Buyang,LANGUAGE,LANGUAGE
SM258735,79.65,14.77,Yerukala,ETHNICITY,ETHNICITY
SM249568,81.9748,17.3702,Yerukula,LANGUAGE,LANGUAGE
SM283662,106.6498,49.7618,Yeröö,ADM2,DISTRICT
SM280659,126.7959,36.6822,Yesan,ADM2,DISTRICT
SM258736,108.35,69.34,Yesey,ETHNICITY,ETHNICITY
SM258737,27.86,-30.05,Yesibe,ETHNICITY,ETHNICITY
SM249572,7.9791,9.0545,Yeskwa,LANGUAGE,LANGUAGE
SM250824,9.34,10.06,Yeskwa,ETHNICITY,ETHNICITY
SM249573,142.574,-4.1572,Yessan-Mayo,LANGUAGE,LANGUAGE
SM283550,96.2588,46.364,Yesönbulag,ADM2,DISTRICT
SM283550,96.2588,46.364,Yesönbulag,ADM2,DISTRICT
SM283550,96.2692,46.185,Yesönbulag,ADM2,DISTRICT
SM283550,96.2692,46.185,Yesönbulag,ADM2,DISTRICT
SM283646,103.6459,46.6173,Yesönzüil,ADM2,DISTRICT
SM283646,103.6459,46.6173,Yesönzüil,ADM2,DISTRICT
SM283646,103.6459,46.6173,Yesönzüil,ADM2,DISTRICT
SM283646,103.5194,46.7575,Yesönzüil,ADM2,DISTRICT
SM283646,103.5194,46.7575,Yesönzüil,ADM2,DISTRICT
SM283646,103.5194,46.7575,Yesönzüil,ADM2,DISTRICT
SM283646,103.525,46.746,Yesönzüil,ADM2,DISTRICT
SM283646,103.525,46.746,Yesönzüil,ADM2,DISTRICT
SM283646,103.525,46.746,Yesönzüil,ADM2,DISTRICT
SM249574,140.653,-4.0588,Yetfa,LANGUAGE,LANGUAGE
SM258738,140.67,-3.47,Yeti,ETHNICITY,ETHNICITY
SM258739,148.33,-23.44,Yetti-Maralla,ETHNICITY,ETHNICITY
SM258739,148.33,-23.44,Yetti-Maralla,ETHNICITY,ETHNICITY
SM258739,149.4836,-22.8938,Yetti-Maralla,ETHNICITY,ETHNICITY
SM258739,149.4836,-22.8938,Yetti-Maralla,ETHNICITY,ETHNICITY
SM249575,35.1084,31.801,Yevanic,LANGUAGE,LANGUAGE
SM261902,47,40.6667,Yevlax (excl. Yevlax City),ADM1:ADM2,DISTRICT
SM261903,47.1667,40.5833,Yevlax City,ADM1:ADM2,DISTRICT
SM18291,33.3269,45.3157,Yevpatoriiskyi Raion (excl. Yevpatorii),ADM2,DISTRICT
SM3801,132,48.5,Yevrey,ADM1,DISTRICT
SM249578,23.6088,-18.9178,Yeyi,LANGUAGE,LANGUAGE
SM17158,38.0295,46.5137,Yeyskiy Rayon,ADM2,DISTRICT
SM298615,35.118,38.3348,Yeşilhisar,ADM2,DISTRICT
SM298741,40.8261,37.3394,Yeşilli,ADM2,DISTRICT
SM298236,29.7586,37.5183,Yeşilova,ADM2,DISTRICT
SM298950,36.2348,39.9992,Yeşilyurt,ADM2,DISTRICT
SM298715,38.247,38.2981,Yeşilyurt,ADM2,DISTRICT
SM289663,-55.1002,-25.4178,Yguazú,ADM2,DISTRICT
SM258740,57.24,23.63,Yhamad,ETHNICITY,ETHNICITY
SM15382,-55.9086,-25.0595,Yhu (1993-),ADM2,DISTRICT
SM258741,102.98,29.73,Yi,ETHNICITY,ETHNICITY
SM258741,102.98,29.73,Yi,ETHNICITY,ETHNICITY
SM258741,102.98,29.73,Yi,ETHNICITY,ETHNICITY
SM258741,102.98,29.73,Yi,ETHNICITY,ETHNICITY
SM258741,102.98,29.73,Yi,ETHNICITY,ETHNICITY
SM258741,103,29,Yi,ETHNICITY,ETHNICITY
SM258741,103,29,Yi,ETHNICITY,ETHNICITY
SM258741,103,29,Yi,ETHNICITY,ETHNICITY
SM258741,103,29,Yi,ETHNICITY,ETHNICITY
SM258741,103,29,Yi,ETHNICITY,ETHNICITY
SM258741,103.5,27.5,Yi,ETHNICITY,ETHNICITY
SM258741,103.5,27.5,Yi,ETHNICITY,ETHNICITY
SM258741,103.5,27.5,Yi,ETHNICITY,ETHNICITY
SM258741,103.5,27.5,Yi,ETHNICITY,ETHNICITY
SM258741,103.5,27.5,Yi,ETHNICITY,ETHNICITY
SM258741,103,28,Yi,ETHNICITY,ETHNICITY
SM258741,103,28,Yi,ETHNICITY,ETHNICITY
SM258741,103,28,Yi,ETHNICITY,ETHNICITY
SM258741,103,28,Yi,ETHNICITY,ETHNICITY
SM258741,103,28,Yi,ETHNICITY,ETHNICITY
SM258741,103,28,Yi,ETHNICITY,ETHNICITY
SM258741,103,28,Yi,ETHNICITY,ETHNICITY
SM258741,103,28,Yi,ETHNICITY,ETHNICITY
SM258741,103,28,Yi,ETHNICITY,ETHNICITY
SM258741,103,28,Yi,ETHNICITY,ETHNICITY
SM297251,101.7004,6.4166,Yi-ngo,ADM2,DISTRICT
SM269332,104.7187,28.5869,Yibin,ADM2,DISTRICT
SM269186,111.1338,30.7431,Yichang,ADM2,DISTRICT
SM269151,129.199,47.6926,Yichun,ADM2,DISTRICT
SM269223,114.9442,28.3877,Yichun,ADM2,DISTRICT
SM249580,34.7818,31.7342,Yiddish Sign Language,LANGUAGE,LANGUAGE
SM249583,71.5077,36.014,Yidgha,LANGUAGE,LANGUAGE
SM459193,145.7656,-17.0871,Yidinj^,ETHNICITY,ETHNICITY
SM249585,145.876,-17.1332,Yidiñ,LANGUAGE,LANGUAGE
SM3802,144.8891,13.536,Yigo,ADM1,DISTRICT
SM458628,127.9889,-15.139,Yiiji,ETHNICITY,ETHNICITY
SM249592,142.178,-3.465,Yil,LANGUAGE,LANGUAGE
SM299063,121.6336,24.5425,Yilan,ADM2,DISTRICT
SM249588,121.5743,24.3813,Yilan Creole Japanese,LANGUAGE,LANGUAGE
SM261340,119.2588,-30.9094,Yilgarn,ADM2,DISTRICT
SM274909,-0.1765,6.1774,Yilo Krobo,ADM2,DISTRICT
SM249594,143.572,-4.7173,Yimas,LANGUAGE,LANGUAGE
SM258742,143.33,-4.45,Yimas,ETHNICITY,ETHNICITY
SM249593,143.7005,-4.9449,Yimas-Alamblak-Pidgin,LANGUAGE,LANGUAGE
SM249595,143.6738,-4.8837,Yimas-Arafundi-Pidgin,LANGUAGE,LANGUAGE
SM249597,143.4195,-4.2959,Yimas-Iatmul Pidgin,LANGUAGE,LANGUAGE
SM249596,143.5629,-4.6395,Yimas-Karawari Pidgin,LANGUAGE,LANGUAGE
SM249600,94.8087,25.7675,Yimchungru Naga,LANGUAGE,LANGUAGE
SM249604,97.2134,19.0383,Yinbaw Karen,LANGUAGE,LANGUAGE
SM249605,97.9166,20.0833,Yinchia,LANGUAGE,LANGUAGE
SM269271,106.3167,38.4667,Yinchuan,ADM2,DISTRICT
SM249606,106.2401,38.5467,Yinchuan Mandarin,DIALECT,LANGUAGE
SM249608,117.001,-21.5379,Yindjibarndi,LANGUAGE,LANGUAGE
SM459145,117.8682,-21.7377,Yindjibarndi,ETHNICITY,ETHNICITY
SM249609,137.5894,-19.2425,Yindjilandji,LANGUAGE,LANGUAGE
SM249611,-73.3087,-11.1086,Yine,LANGUAGE,LANGUAGE
SM249614,115.58,-25.4446,Yinggarda,LANGUAGE,LANGUAGE
SM10370,122.2476,40.639,Yingkou,ADM2,DISTRICT
SM249616,115.3468,30.7463,Yingshan Mandarin,DIALECT,LANGUAGE
SM269224,117.1057,28.2099,Yingtan,ADM2,DISTRICT
SM249617,117.5628,-22.9178,Yinhawangka,LANGUAGE,LANGUAGE
SM15110,94.7157,22.2486,Yinmabin District,ADM2,DISTRICT
SM249618,97.3368,18.9378,Yintale Karen,LANGUAGE,LANGUAGE
SM258743,143.5,-14,Yintjingga,ETHNICITY,ETHNICITY
SM258743,143.5,-14,Yintjingga,ETHNICITY,ETHNICITY
SM258743,143.2399,-14.4185,Yintjingga,ETHNICITY,ETHNICITY
SM258743,143.2399,-14.4185,Yintjingga,ETHNICITY,ETHNICITY
SM226066,142.893,-14.6652,Yintyinka-Ayabadhu,LANGUAGE,LANGUAGE
SM249620,142.7036,-12.5207,Yinwum,LANGUAGE,LANGUAGE
SM459389,142.7246,-12.7004,Yinwum,ETHNICITY,ETHNICITY
SM249624,141.767,-15.1117,Yir-Yoront,LANGUAGE,LANGUAGE
SM258744,142.14,-14.96,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,142.14,-14.96,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,142.14,-14.96,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,142.14,-14.96,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,142,-15,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,142,-15,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,142,-15,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,142,-15,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,142.17,-15,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,142.17,-15,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,142.17,-15,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,142.17,-15,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,141.712,-15.1257,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,141.712,-15.1257,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,141.712,-15.1257,Yir-Yoront,ETHNICITY,ETHNICITY
SM258744,141.712,-15.1257,Yir-Yoront,ETHNICITY,ETHNICITY
SM249621,143.9626,-20.6581,Yirandhali,LANGUAGE,LANGUAGE
SM459035,140.5328,-34.2671,Yirawirung,ETHNICITY,ETHNICITY
SM459187,145.7334,-16.9412,Yirrgay,ETHNICITY,ETHNICITY
SM459289,141.6169,-15.3013,Yirrk-Thangalkl,ETHNICITY,ETHNICITY
SM249627,141.88,-3.5737,Yis,LANGUAGE,LANGUAGE
SM249628,144.22,-33.89,Yitha Yitha,DIALECT,LANGUAGE
SM458186,143.9409,-33.8732,Yitha Yitha,ETHNICITY,ETHNICITY
SM249629,9.7876,8.974,Yiwom,LANGUAGE,LANGUAGE
SM269196,112.0567,28.4387,Yiyang,ADM2,DISTRICT
SM346790,24.393,64.1206,Ylivieska,ADM2:ADM3,DISTRICT
SM302808,-102.8278,33.173,Yoakum,ADM2,DISTRICT
SM249634,149.283,-10.2167,Yoba,LANGUAGE,LANGUAGE
SM282940,-89.1189,21.283,Yobaín,ADM2,DISTRICT
SM3803,11.5,12,Yobe,ADM1,DISTRICT
SM271651,42.11,11.56,Yoboki,ADM2,DISTRICT
SM249635,-5.1224,5.4013,Yocoboué Dida,LANGUAGE,LANGUAGE
SM275757,-86.82,14.95,Yocón,ADM2,DISTRICT
SM289121,126.9119,39.7144,Yodŏk,ADM2,DISTRICT
SM249637,121.718,16.7015,Yogad,LANGUAGE,LANGUAGE
SM14909,-96.7974,16.4498,Yogana,ADM2,DISTRICT
SM3804,110.3439,-7.8008,Yogyakarta,ADM1,DISTRICT
SM249639,110.3687,-7.8,Yogyakarta Sign Language,LANGUAGE,LANGUAGE
SM13126,140.8282,43.0971,Yoichi-gun,ADM2,DISTRICT
SM249641,145.758,-4.9404,Yoidik,LANGUAGE,LANGUAGE
SM258746,147.73,66.26,Yokaghir,ETHNICITY,ETHNICITY
SM258746,147.73,66.26,Yokaghir,ETHNICITY,ETHNICITY
SM258746,147.73,66.26,Yokaghir,ETHNICITY,ETHNICITY
SM258746,153.5,64.75,Yokaghir,ETHNICITY,ETHNICITY
SM258746,153.5,64.75,Yokaghir,ETHNICITY,ETHNICITY
SM258746,153.5,64.75,Yokaghir,ETHNICITY,ETHNICITY
SM258746,145,70,Yokaghir,ETHNICITY,ETHNICITY
SM258746,145,70,Yokaghir,ETHNICITY,ETHNICITY
SM258746,145,70,Yokaghir,ETHNICITY,ETHNICITY
SM249644,138.028,-1.688,Yoke,LANGUAGE,LANGUAGE
SM278713,136.6244,34.9648,Yokkaichi,ADM2,DISTRICT
SM278547,139.5765,35.4623,Yokohama,ADM2,DISTRICT
SM278549,139.6603,35.2499,Yokosuka,ADM2,DISTRICT
SM278574,140.5443,39.2837,Yokote,ADM2,DISTRICT
SM284844,12.2511,9.2663,Yola North,ADM2,DISTRICT
SM284845,12.4362,9.2529,Yola South,ADM2,DISTRICT
SM249652,136.8167,-12.2833,Yolngu Sign Language,LANGUAGE,LANGUAGE
SM303215,-121.9016,38.6867,Yolo,ADM2,DISTRICT
SM295736,-88.1342,13.7616,Yoloaiquín,ADM2,DISTRICT
SM270173,-74.9912,6.6657,Yolombó,ADM2,DISTRICT
SM249654,-98.6293,16.8059,Yoloxochitl Mixtec,LANGUAGE,LANGUAGE
SM249658,1.811,9.8719,Yom,LANGUAGE,LANGUAGE
SM249655,12.6362,-4.8623,Yombe,LANGUAGE,LANGUAGE
SM249655,12.6362,-4.8623,Yombe,LANGUAGE,LANGUAGE
SM249655,12.9657,-5.1263,Yombe,LANGUAGE,LANGUAGE
SM249655,12.9657,-5.1263,Yombe,LANGUAGE,LANGUAGE
SM298969,39.8657,40.9549,Yomra,ADM2,DISTRICT
SM258749,55.42,36.44,Yomut,ETHNICITY,ETHNICITY
SM3805,144.7771,13.4097,Yona,ADM1,DISTRICT
SM13127,133.3587,35.4349,Yonago Shi,ADM2,DISTRICT
SM249663,122.991,24.45,Yonaguni,LANGUAGE,LANGUAGE
SM289161,126.0833,37.9167,Yonan,ADM2,DISTRICT
SM249664,-123.466,43.568,Yoncalla,LANGUAGE,LANGUAGE
SM258750,-123.05,43.61,Yoncalla,ETHNICITY,ETHNICITY
SM270174,-74.1769,6.9074,Yondó,ADM2,DISTRICT
SM279568,140.1216,37.85,Yonezawa,ADM2,DISTRICT
SM249673,98.9765,18.3873,Yong,LANGUAGE,LANGUAGE
SM249672,108.15,23.1,Yongbei Zhuang,LANGUAGE,LANGUAGE
SM249676,141.213,-6.4296,Yonggom,LANGUAGE,LANGUAGE
SM249679,112.0748,23.7154,Yonggu Biao,LANGUAGE,LANGUAGE
SM280726,127.178,37.2383,Yongin,ADM2,DISTRICT
SM249671,108,22.8,Yongnan Zhuang,LANGUAGE,LANGUAGE
SM280628,126.9775,37.5339,Yongsan,ADM2,DISTRICT
SM269197,111.6029,26.4401,Yongzhou,ADM2,DISTRICT
SM274476,3.75,47.9167,Yonne,ADM2,DISTRICT
SM270889,-72.2644,5.2581,Yopal,ADM2,DISTRICT
SM249689,146.661,-5.9409,Yopno,LANGUAGE,LANGUAGE
SM249690,-72.0409,-11.3704,Yora,LANGUAGE,LANGUAGE
SM279217,139.2028,36.1113,Yorii,ADM2,DISTRICT
SM275803,-87.33,15.06,Yorito,ADM2,DISTRICT
SM302974,-76.5205,37.2254,York,ADM2,DISTRICT
SM302472,-81.1844,34.9748,York,ADM2,DISTRICT
SM302348,-76.7265,39.92,York,ADM2,DISTRICT
SM301770,-97.5971,40.8728,York,ADM2,DISTRICT
SM301205,-70.6702,43.4292,York,ADM2,DISTRICT
SM274605,-1.0914,53.964,York,ADM1:ADM2,DISTRICT
SM12856,-76.4398,17.9285,York,ADM2,DISTRICT
SM268891,-79.4663,44.0001,York,ADM2,DISTRICT
SM268800,-66.9989,46.1668,York,ADM2,DISTRICT
SM261342,116.7216,-31.917,York,ADM2,DISTRICT
SM12857,-77.2292,18.2752,York Castle,ADM2,DISTRICT
SM12858,-77.3,17.9252,York Town,ADM2,DISTRICT
SM17709,-60.9792,14.0169,Yorke Hill,ADM2,DISTRICT
SM261751,137.5818,-34.7055,Yorke Peninsula,ADM2,DISTRICT
SM275804,-87.23,15.23,Yoro,ADM2,DISTRICT
SM3806,-87.1667,15.25,Yoro,ADM1,DISTRICT
SM249694,128.428,27.0407,Yoron,LANGUAGE,LANGUAGE
SM283385,-4.7817,12.345,Yorosso,ADM2,DISTRICT
SM285248,11.4843,8.9087,Yorro,ADM2,DISTRICT
SM249695,145.01,-35.9,Yorta Yorta,LANGUAGE,LANGUAGE
SM458148,145.2636,-36.0939,Yorta Yorta,ETHNICITY,ETHNICITY
SM249697,3.6723,7.1535,Yoruba,LANGUAGE,LANGUAGE
SM250827,2.57,8.1,Yoruba,ETHNICITY,ETHNICITY
SM250827,2.57,8.1,Yoruba,ETHNICITY,ETHNICITY
SM250827,2.57,8.1,Yoruba,ETHNICITY,ETHNICITY
SM250827,2.57,8.1,Yoruba,ETHNICITY,ETHNICITY
SM250827,2.76,9.94,Yoruba,ETHNICITY,ETHNICITY
SM250827,2.76,9.94,Yoruba,ETHNICITY,ETHNICITY
SM250827,2.76,9.94,Yoruba,ETHNICITY,ETHNICITY
SM250827,2.76,9.94,Yoruba,ETHNICITY,ETHNICITY
SM250827,3.933,7.383,Yoruba,ETHNICITY,ETHNICITY
SM250827,3.933,7.383,Yoruba,ETHNICITY,ETHNICITY
SM250827,3.933,7.383,Yoruba,ETHNICITY,ETHNICITY
SM250827,3.933,7.383,Yoruba,ETHNICITY,ETHNICITY
SM250827,3.9,7.4,Yoruba,ETHNICITY,ETHNICITY
SM250827,3.9,7.4,Yoruba,ETHNICITY,ETHNICITY
SM250827,3.9,7.4,Yoruba,ETHNICITY,ETHNICITY
SM250827,3.9,7.4,Yoruba,ETHNICITY,ETHNICITY
SM250827,4,8,Yoruba,ETHNICITY,ETHNICITY
SM250827,4,8,Yoruba,ETHNICITY,ETHNICITY
SM250827,4,8,Yoruba,ETHNICITY,ETHNICITY
SM250827,4,8,Yoruba,ETHNICITY,ETHNICITY
SM249698,3.66,7.15,Yoruba Sign Language,LANGUAGE,LANGUAGE
SM249699,95.7566,25.4364,Yos,LANGUAGE,LANGUAGE
SM13128,135.0989,35.5281,Yosa-gun,ADM2,DISTRICT
SM279218,139.8609,35.8951,Yoshikawa,ADM2,DISTRICT
SM457945,135.9167,34.1667,Yoshino,ADM2,DISTRICT
SM279145,130.4,33.3632,Yoshinogari,ADM2,DISTRICT
SM279418,134.2835,34.0371,Yoshinogawa,ADM2,DISTRICT
SM258752,-84.35,12.3,Yosko,ETHNICITY,ETHNICITY
SM249701,-97.579,16.874,Yosondúa Mixtec,LANGUAGE,LANGUAGE
SM249700,-97.7727,17.4272,Yosoñama,DIALECT,LANGUAGE
SM9606,68.6458,33.0499,Yosuf Khel,ADM2,DISTRICT
SM296856,1.5,6.6667,Yoto,ADM2,DISTRICT
SM270639,-76.4249,3.9317,Yotoco,ADM2,DISTRICT
SM279397,140.1791,35.6717,Yotsukaido,ADM2,DISTRICT
SM249702,11.6728,9.161,Yotti,LANGUAGE,LANGUAGE
SM249704,106.9,23.75,Youjiang Zhuang,LANGUAGE,LANGUAGE
SM249705,101.057,22.0473,Youle Jinuo,LANGUAGE,LANGUAGE
SM300072,-57.615,-32.6407,Young,ADM2,DISTRICT
SM302809,-98.6878,33.1767,Young,ADM2,DISTRICT
SM323868,-111.2023,51.5285,Youngstown,ADM2:ADM3,DISTRICT
SM249706,110.474,25.7638,Younuo Bunu,LANGUAGE,LANGUAGE
SM370192,-8.5,6.6667,Yourpea,ADM2:ADM3,DISTRICT
SM4445,-8.524,32.2354,Youssoufia,ADM2,DISTRICT
SM249707,146.5823,-5.8354,Yout Wam,LANGUAGE,LANGUAGE
SM283371,-4.488,15.373,Youwarou,ADM2,DISTRICT
SM305965,69.0042,38.2874,Yovon,ADM2,DISTRICT
SM305965,69.0042,38.2874,Yovon,ADM2,DISTRICT
SM305965,69,38.25,Yovon,ADM2,DISTRICT
SM305965,69,38.25,Yovon,ADM2,DISTRICT
SM249708,104.785,17.6215,Yoy,LANGUAGE,LANGUAGE
SM3807,35.3333,39.5833,Yozgat,ADM1,DISTRICT
SM18470,71.7333,40.65,Yozyovon Tumani,ADM2,DISTRICT
SM289725,-57.2642,-25.3933,Ypacaraí,ADM2,DISTRICT
SM289727,-57.5093,-25.4604,Ypané,ADM2,DISTRICT
SM289714,-55.7676,-23.9281,Ypejhú,ADM2,DISTRICT
SM11111,32.9499,34.7042,Ypsonas,ADM2,DISTRICT
SM15383,-56.1371,-24.5047,Yryvu Cua,ADM2,DISTRICT
SM258756,159.06,-8.02,Ysabel,ETHNICITY,ETHNICITY
SM296454,13.9115,55.4637,Ystad,ADM2,DISTRICT
SM280316,75.0744,42.7267,Ysyk-Ata,ADM2,DISTRICT
SM280347,77.1834,42.7096,Ysyk-Köl,ADM2,DISTRICT
SM3808,78,42,Ysyk-Köl,ADM1:ADMX,DISTRICT
SM249714,164.51,-20.5664,Yuaga,LANGUAGE,LANGUAGE
SM303216,-121.3513,39.269,Yuba,ADM2,DISTRICT
SM13129,141.8125,42.9886,Yubari-gun,ADM2,DISTRICT
SM249722,-88.9567,18.7757,Yucatec Maya,LANGUAGE,LANGUAGE
SM258757,-89.03,20.08,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-89.03,20.08,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-89.03,20.08,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-88.6,17.47,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-88.6,17.47,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-88.6,17.47,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-90.38,14.93,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-90.38,14.93,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-90.38,14.93,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-90,18,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-90,18,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-90,18,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-91,19,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-91,19,Yucatec Maya,ETHNICITY,ETHNICITY
SM258757,-91,19,Yucatec Maya,ETHNICITY,ETHNICITY
SM249719,-89,20.5,Yucatec Maya Sign Language,LANGUAGE,LANGUAGE
SM3809,-88.8333,20.75,Yucatán,ADM1,DISTRICT
SM249723,-86.75,35.75,Yuchi,LANGUAGE,LANGUAGE
SM258758,-85.3,36.39,Yuchi,ETHNICITY,ETHNICITY
SM258758,-85.3,36.39,Yuchi,ETHNICITY,ETHNICITY
SM258758,-80.58,35.15,Yuchi,ETHNICITY,ETHNICITY
SM258758,-80.58,35.15,Yuchi,ETHNICITY,ETHNICITY
SM258758,-86,35,Yuchi,ETHNICITY,ETHNICITY
SM258758,-86,35,Yuchi,ETHNICITY,ETHNICITY
SM249724,112.565,37.6394,Yuci Mandarin,DIALECT,LANGUAGE
SM249727,-97.8915,17.6341,Yucu Ñuti,DIALECT,LANGUAGE
SM295709,-87.9985,13.5282,Yucuaiquín,ADM2,DISTRICT
SM249726,-97.4285,17.2832,Yucuañe Mixtec,LANGUAGE,LANGUAGE
SM249725,-97.7824,17.0304,Yucuhiti,DIALECT,LANGUAGE
SM249729,-71.0033,-0.7608,Yucuna,LANGUAGE,LANGUAGE
SM259452,-97.9182,17.2374,Yucunicoco Mixtec,LANGUAGE,LANGUAGE
SM249731,113,23,Yue Chinese,LANGUAGE,LANGUAGE
SM258759,117.07,-31.01,Yued,ETHNICITY,ETHNICITY
SM258759,117.07,-31.01,Yued,ETHNICITY,ETHNICITY
SM258759,115.9194,-30.8574,Yued,ETHNICITY,ETHNICITY
SM258759,115.9194,-30.8574,Yued,ETHNICITY,ETHNICITY
SM3810,114.05,22.4167,Yuen Long,ADM1,DISTRICT
SM18112,35.454,36.9921,Yueregir (2008-),ADM2,DISTRICT
SM269198,113.302,29.3247,Yueyang,ADM2,DISTRICT
SM279011,131.4322,33.1792,Yufu,ADM2,DISTRICT
SM13130,142.1628,42.8385,Yufutsu-gun,ADM2,DISTRICT
SM249739,153.082,-27.803,Yugambal,LANGUAGE,LANGUAGE
SM458435,153.0253,-27.2776,Yugarabul,ETHNICITY,ETHNICITY
SM249740,89.7479,60.8778,Yugh,LANGUAGE,LANGUAGE
SM249741,89.7479,60.8778,Yugh (Retired),LANGUAGE,LANGUAGE
SM258760,18.99,43.09,Yugoslavian,ETHNICITY,ETHNICITY
SM249742,17.5193,45.3912,Yugoslavian Sign Language,LANGUAGE,LANGUAGE
SM258761,134.33,-15.19,Yugul,ETHNICITY,ETHNICITY
SM258761,134.33,-15.19,Yugul,ETHNICITY,ETHNICITY
SM258761,134.8307,-14.7462,Yugul,ETHNICITY,ETHNICITY
SM258761,134.8307,-14.7462,Yugul,ETHNICITY,ETHNICITY
SM249747,-69.8079,0.2485,Yuhup,LANGUAGE,LANGUAGE
SM258763,149.67,-37.19,Yuin,ETHNICITY,ETHNICITY
SM258764,-170.12,63.35,Yuit,ETHNICITY,ETHNICITY
SM258764,-172.61,66.32,Yuit,ETHNICITY,ETHNICITY
SM249750,162.9585,68.0518,Yukaghir Omok,LANGUAGE,LANGUAGE
SM293085,35.25,54.75,Yukhnovskiy rayon,ADM2,DISTRICT
SM258765,-122.23,38.84,Yuki,ETHNICITY,ETHNICITY
SM258765,-122.23,38.84,Yuki,ETHNICITY,ETHNICITY
SM258765,-122.23,38.84,Yuki,ETHNICITY,ETHNICITY
SM258765,-122.23,38.84,Yuki,ETHNICITY,ETHNICITY
SM258765,-123.35,39.62,Yuki,ETHNICITY,ETHNICITY
SM258765,-123.35,39.62,Yuki,ETHNICITY,ETHNICITY
SM258765,-123.35,39.62,Yuki,ETHNICITY,ETHNICITY
SM258765,-123.35,39.62,Yuki,ETHNICITY,ETHNICITY
SM258765,-123,40,Yuki,ETHNICITY,ETHNICITY
SM258765,-123,40,Yuki,ETHNICITY,ETHNICITY
SM258765,-123,40,Yuki,ETHNICITY,ETHNICITY
SM258765,-123,40,Yuki,ETHNICITY,ETHNICITY
SM258765,-123.15,39.7,Yuki,ETHNICITY,ETHNICITY
SM258765,-123.15,39.7,Yuki,ETHNICITY,ETHNICITY
SM258765,-123.15,39.7,Yuki,ETHNICITY,ETHNICITY
SM258765,-123.15,39.7,Yuki,ETHNICITY,ETHNICITY
SM258765,-123.2,39.8,Yuki,ETHNICITY,ETHNICITY
SM258765,-123.2,39.8,Yuki,ETHNICITY,ETHNICITY
SM258765,-123.2,39.8,Yuki,ETHNICITY,ETHNICITY
SM258765,-123.2,39.8,Yuki,ETHNICITY,ETHNICITY
SM3812,-135.004,62.9996,Yukon,ADM1,DISTRICT
SM301190,-152.7167,65.7,Yukon-Koyukuk,ADM2,DISTRICT
SM249755,-73.0557,9.6753,Yukpa,LANGUAGE,LANGUAGE
SM258766,-73,9.77,Yukpa,ETHNICITY,ETHNICITY
SM258766,-73,9.77,Yukpa,ETHNICITY,ETHNICITY
SM258766,-72,9,Yukpa,ETHNICITY,ETHNICITY
SM258766,-72,9,Yukpa,ETHNICITY,ETHNICITY
SM249758,9.9777,6.9183,Yukuben,LANGUAGE,LANGUAGE
SM279723,130.9711,33.714,Yukuhashi,ADM2,DISTRICT
SM258767,121.77,-20.18,Yulbaridja,ETHNICITY,ETHNICITY
SM258767,121.77,-20.18,Yulbaridja,ETHNICITY,ETHNICITY
SM258767,124.9545,-21.184,Yulbaridja,ETHNICITY,ETHNICITY
SM258767,124.9545,-21.184,Yulbaridja,ETHNICITY,ETHNICITY
SM299064,120.3848,23.707,Yulin,ADM2,DISTRICT
SM269442,110.4004,22.4745,Yulin,ADM2,DISTRICT
SM269282,109.6719,38.0536,Yulin,ADM2,DISTRICT
SM249761,122.3703,-18.9235,Yulparija,LANGUAGE,LANGUAGE
SM249762,23.6166,8.0412,Yulu,LANGUAGE,LANGUAGE
SM303378,-102.4242,40.0029,Yuma,ADM2,DISTRICT
SM301794,-113.9056,32.7694,Yuma,ADM2,DISTRICT
SM258768,-66.85,-7.79,Yuma,ETHNICITY,ETHNICITY
SM3813,31.2824,3.5235,Yumbe,ADM1:ADM2,DISTRICT
SM270640,-76.5438,3.5699,Yumbo,ADM2,DISTRICT
SM258769,-77.27,-0.35,Yumbo,ETHNICITY,ETHNICITY
SM459263,142.4648,-10.9149,Yumukuntyi,ETHNICITY,ETHNICITY
SM298126,35.7995,36.7825,Yumurtalık,ADM2,DISTRICT
SM298690,31.7375,38.8237,Yunak,ADM2,DISTRICT
SM269311,111.0537,35.218,Yuncheng,ADM2,DISTRICT
SM269424,111.8047,22.8783,Yunfu,ADM2,DISTRICT
SM454619,115,-34,Yungar,ETHNICITY,ETHNICITY
SM454619,115,-34,Yungar,ETHNICITY,ETHNICITY
SM454619,117.84,-32.9221,Yungar,ETHNICITY,ETHNICITY
SM454619,117.84,-32.9221,Yungar,ETHNICITY,ETHNICITY
SM286802,-77.75,-9.1667,Yungay,ADM2,DISTRICT
SM458457,153.2158,-29.4638,Yunggaya,ETHNICITY,ETHNICITY
SM458873,130.4134,-13.5973,Yunggor,ETHNICITY,ETHNICITY
SM250090,12,10,Yungur,ETHNICITY,ETHNICITY
SM286826,-69.1179,-16.3057,Yunguyo,ADM2,DISTRICT
SM249767,99.0353,25.9224,Yunlong,DIALECT,LANGUAGE
SM3814,101.5,25,Yunnan,ADM1,DISTRICT
SM258770,100.36,20.49,Yunnan,ETHNICITY,ETHNICITY
SM285265,11.7865,13.1043,Yunusari,ADM2,DISTRICT
SM18113,27.4081,38.6206,Yunusemre,ADM2,DISTRICT
SM18471,69.2989,41.3519,Yunusobod Tumani,ADM2,DISTRICT
SM258771,-149.18,60.43,Yupik,ETHNICITY,ETHNICITY
SM258771,-149.18,60.43,Yupik,ETHNICITY,ETHNICITY
SM258771,-153.667,57,Yupik,ETHNICITY,ETHNICITY
SM258771,-153.667,57,Yupik,ETHNICITY,ETHNICITY
SM275188,-89.7756,14.1574,Yupiltepeque,ADM2,DISTRICT
SM233514,-70,-0.5,Yupua,LANGUAGE,LANGUAGE
SM249771,141.847,-12.004,Yuputhimri,LANGUAGE,LANGUAGE
SM459262,141.7946,-12.1535,Yuputhimri,ETHNICITY,ETHNICITY
SM303450,69.5,41.25,Yuqorichirchiq,ADM2,DISTRICT
SM249772,-64.9438,-16.7875,Yuqui,LANGUAGE,LANGUAGE
SM258772,-65.1,-16.23,Yuqui,ETHNICITY,ETHNICITY
SM258772,-65.1,-16.23,Yuqui,ETHNICITY,ETHNICITY
SM258772,-64.97,-16.46,Yuqui,ETHNICITY,ETHNICITY
SM258772,-64.97,-16.46,Yuqui,ETHNICITY,ETHNICITY
SM294893,39.5833,56.5833,Yur'ev-Pol'skiy rayon,ADM2,DISTRICT
SM293297,49.25,59,Yur'yanovskiy rayon,ADM2,DISTRICT
SM258773,-66.56,-15.71,Yuracare,ETHNICITY,ETHNICITY
SM249773,-65.1224,-16.7479,Yuracaré,LANGUAGE,LANGUAGE
SM293486,64.5,55.3333,Yurgam'shskiy rayon,ADM2,DISTRICT
SM17159,84.75,55.75,Yurginskiy Rayon,ADM2,DISTRICT
SM258774,-68.11,-3.64,Yuri,ETHNICITY,ETHNICITY
SM278575,140.165,39.2934,Yurihonjō,ADM2,DISTRICT
SM293625,46.3,56.3,Yurinskiy rayon,ADM2,DISTRICT
SM281574,-101.2257,20.1832,Yuriria,ADM2,DISTRICT
SM294075,54.25,59.3333,Yurlinskiy rayon,ADM2,DISTRICT
SM249776,-123.824,41.3262,Yurok,LANGUAGE,LANGUAGE
SM258775,-123.82,41.58,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.82,41.58,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.82,41.58,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.82,41.58,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.82,41.58,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.75,41.25,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.75,41.25,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.75,41.25,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.75,41.25,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.75,41.25,Yurok,ETHNICITY,ETHNICITY
SM258775,-124,41,Yurok,ETHNICITY,ETHNICITY
SM258775,-124,41,Yurok,ETHNICITY,ETHNICITY
SM258775,-124,41,Yurok,ETHNICITY,ETHNICITY
SM258775,-124,41,Yurok,ETHNICITY,ETHNICITY
SM258775,-124,41,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.89,41.4,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.89,41.4,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.89,41.4,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.89,41.4,Yurok,ETHNICITY,ETHNICITY
SM258775,-123.89,41.4,Yurok,ETHNICITY,ETHNICITY
SM258775,-124,41.5,Yurok,ETHNICITY,ETHNICITY
SM258775,-124,41.5,Yurok,ETHNICITY,ETHNICITY
SM258775,-124,41.5,Yurok,ETHNICITY,ETHNICITY
SM258775,-124,41.5,Yurok,ETHNICITY,ETHNICITY
SM258775,-124,41.5,Yurok,ETHNICITY,ETHNICITY
SM249783,147.361,-19.836,Yuru,LANGUAGE,LANGUAGE
SM458432,147.6746,-19.8272,Yuru,ETHNICITY,ETHNICITY
SM249778,-76.5226,3.4369,Yurumanguí,LANGUAGE,LANGUAGE
SM258776,-52.35,-8.25,Yuruna,ETHNICITY,ETHNICITY
SM258776,-52.84,-5.95,Yuruna,ETHNICITY,ETHNICITY
SM268512,91.3447,27.0437,Yurung,ADM2,DISTRICT
SM249782,-69.6955,0.7356,Yurutí,LANGUAGE,LANGUAGE
SM281993,-102.2502,20.3125,Yurécuaro,ADM2,DISTRICT
SM292946,42.8333,57.25,Yur’evskiy rayon,ADM2,DISTRICT
SM294077,55.25,59.0833,Yus'vinskiy rayon,ADM2,DISTRICT
SM275905,-86.8317,13.9571,Yuscarán,ADM2,DISTRICT
SM280680,127.327,36.3669,Yuseong,ADM2,DISTRICT
SM10371,94.1902,34.398,Yushu Zangzu Zizhizhou,ADM2,DISTRICT
SM258777,-83.65,30.16,Yustaga,ETHNICITY,ETHNICITY
SM293058,46.5,47,Yustinskiy rayon,ADM2,DISTRICT
SM285266,10.8827,13.2205,Yusufari,ADM2,DISTRICT
SM298143,41.5423,40.8202,Yusufeli,ADM2,DISTRICT
SM258778,74.58,31.81,Yusufzai,ETHNICITY,ETHNICITY
SM258778,74.58,31.81,Yusufzai,ETHNICITY,ETHNICITY
SM258778,72,35,Yusufzai,ETHNICITY,ETHNICITY
SM258778,72,35,Yusufzai,ETHNICITY,ETHNICITY
SM249786,-97.2293,17.0311,Yutanduchi Mixtec,LANGUAGE,LANGUAGE
SM14910,-97.2911,17.0487,Yutanduchi de Guerrero,ADM2,DISTRICT
SM294644,53.5,54.6667,Yutazinskiy rayon,ADM2,DISTRICT
SM15384,-56.3352,-26.6072,Yuty (2012-),ADM2,DISTRICT
SM249790,147.9237,-29.7582,Yuwaalaraay,DIALECT,LANGUAGE
SM458151,148.2755,-29.3131,Yuwaalaraay,ETHNICITY,ETHNICITY
SM231180,149.543,-29.9037,Yuwaalaraay-Gamilaraay,LANGUAGE,LANGUAGE
SM458170,147.5403,-29.6044,Yuwaalayaay,ETHNICITY,ETHNICITY
SM269372,102.033,24.1307,Yuxi,ADM2,DISTRICT
SM459041,140.9993,-34.0988,Yuyu,ETHNICITY,ETHNICITY
SM279569,139.9517,39.05,Yuza,ADM2,DISTRICT
SM278955,138.8007,36.854,Yuzawa,ADM2,DISTRICT
SM278576,140.5427,39.0366,Yuzawa,ADM2,DISTRICT
SM17160,146.7705,44.5924,Yuzhno-Kurilsky District,ADM2,DISTRICT
SM292776,61.25,54.4667,Yuzhnoural'sk,ADM2,DISTRICT
SM10372,106.5381,29.5521,Yuzhong District,ADM2,DISTRICT
SM292947,42,56.5833,Yuzhskiy rayon,ADM2,DISTRICT
SM274511,1.857,48.8055,Yvelines,ADM2,DISTRICT
SM249352,-68.2648,-55.2748,Yámana,LANGUAGE,LANGUAGE
SM249427,-62.3009,1.3021,Yãnoma,LANGUAGE,LANGUAGE
SM282615,-108.9556,28.4012,Yécora,ADM2,DISTRICT
SM283352,-10.5449,15.1077,Yélimané,ADM2,DISTRICT
SM304593,104.9064,21.7324,Yên Bái,ADM2,DISTRICT
SM3800,104.5833,21.75,Yên Bái,ADM1,DISTRICT
SM304594,104.954,21.8546,Yên Bình,ADM2,DISTRICT
SM304462,104.3326,21.005,Yên Châu,ADM2,DISTRICT
SM304190,106.25,21.2167,Yên Dũng,ADM2,DISTRICT
SM304345,106.0961,20.1902,Yên Khánh,ADM2,DISTRICT
SM304585,105.5759,21.2185,Yên Lạc,ADM2,DISTRICT
SM304358,105.0049,21.3557,Yên Lập,ADM2,DISTRICT
SM304123,105.1787,23.0699,Yên Minh,ADM2,DISTRICT
SM304346,106.002,20.1298,Yên Mô,ADM2,DISTRICT
SM304203,106.0239,20.9112,Yên Mỹ,ADM2,DISTRICT
SM304437,105.9943,21.2068,Yên Phong,ADM2,DISTRICT
SM304568,105.2766,21.8836,Yên Sơn,ADM2,DISTRICT
SM304331,105.4365,19.0272,Yên Thành,ADM2,DISTRICT
SM304182,106.1169,21.5167,Yên Thế,ADM2,DISTRICT
SM304193,105.6212,20.4222,Yên Thủy,ADM2,DISTRICT
SM304525,105.6148,19.9867,Yên Định,ADM2,DISTRICT
SM298454,44.2583,37.5463,Yüksekova,ADM2,DISTRICT
SM298253,29.0364,40.1957,Yıldırım,ADM2,DISTRICT
SM298929,36.6065,39.876,Yıldızeli,ADM2,DISTRICT
SM298340,31.4567,40.965,Yığılca,ADM2,DISTRICT
SM279824,136.56,35.2911,Yōrō,ADM2,DISTRICT
SM289201,124.5382,39.882,Yŏmju,ADM2,DISTRICT
SM289123,127.3369,40.1144,Yŏnggwang,ADM2,DISTRICT
SM289102,129.0914,41.7969,Yŏnsa,ADM2,DISTRICT
SM289138,126.3167,38.8817,Yŏnsan,ADM2,DISTRICT
SM289139,126.0217,38.6539,Yŏntan,ADM2,DISTRICT
SM278233,142.1107,43.0434,Yūbari,ADM2,DISTRICT
SM278406,139.864,36.2613,Yūki,ADM2,DISTRICT
SM278520,130.7287,31.965,Yūsui,ADM2,DISTRICT
SM262129,2.201,7.228,Za-Kpota,ADM2,DISTRICT
SM249795,-96.8234,17.0025,Zaachila Zapotec,LANGUAGE,LANGUAGE
SM283716,104.7742,48.2068,Zaamar,ADM2,DISTRICT
SM286028,4.8125,52.4531,Zaanstad,ADM2,DISTRICT
SM296751,36.112,33.7082,Zabadani,ADM2,DISTRICT
SM290236,26.1325,45.8914,Zabala,ADM2,DISTRICT
SM242410,51.0996,35.6922,Zaban Eshareh Irani,LANGUAGE,LANGUAGE
SM249797,158.753,-7.7431,Zabana,LANGUAGE,LANGUAGE
SM295104,117.3333,49.6667,Zabaykal'skiy rayon,ADM2,DISTRICT
SM3815,117,52,Zabaykal'ye (2008-),ADM1,DISTRICT
SM264358,-37.0564,-8.0763,Zabelê,ADM2,DISTRICT
SM305048,43.3684,14.2774,Zabid,ADM2,DISTRICT
SM305743,15.9086,46.0281,Zabok,ADM2,DISTRICT
SM290553,21.5853,46.0631,Zabrani,ADM2,DISTRICT
SM288742,18.7966,50.3141,Zabrze,ADM2,DISTRICT
SM3817,67.25,32.25,Zabul,ADM1,DISTRICT
SM4368,0.3667,9.2833,Zabzugu,ADM2,DISTRICT
SM3818,-89.5,15,Zacapa,ADM1,DISTRICT
SM282350,-98.0451,18.5781,Zacapala,ADM2,DISTRICT
SM282351,-97.5806,19.8567,Zacapoaxtla,ADM2,DISTRICT
SM281994,-101.8003,19.8154,Zacapu,ADM2,DISTRICT
SM267037,-50.0615,-21.1165,Zacarias,ADM2,DISTRICT
SM258779,-102.4,23.37,Zacatec,ETHNICITY,ETHNICITY
SM283089,-102.6689,22.739,Zacatecas,ADM2,DISTRICT
SM3819,-103,23.25,Zacatecas,ADM1,DISTRICT
SM295692,-88.8833,13.4,Zacatecoluca,ADM2,DISTRICT
SM14911,-98.2609,19.188,Zacatelco (1995-),ADM2,DISTRICT
SM249801,-97.3069,16.08,Zacatepec Chatino,LANGUAGE,LANGUAGE
SM282123,-99.2077,18.6523,Zacatepec de Hidalgo,ADM2,DISTRICT
SM282352,-97.9886,19.9535,Zacatlán,ADM2,DISTRICT
SM249800,-97.8974,20.0581,Zacatlán-Ahuacatlán-Tepetzintla Nahuatl,LANGUAGE,LANGUAGE
SM281884,-100.2662,19.0547,Zacazonapan,ADM2,DISTRICT
SM11112,32.5502,34.9835,Zacharia,ADM2,DISTRICT
SM3820,15.5833,53.5833,Zachodniopomorskie,ADM1,DISTRICT
SM281763,-103.5737,20.2329,Zacoalco de Torres,ADM2,DISTRICT
SM275247,-90.8851,15.0924,Zacualpa,ADM2,DISTRICT
SM282838,-98.3393,20.4993,Zacualpan,ADM2,DISTRICT
SM282124,-98.7527,18.8086,Zacualpan,ADM2,DISTRICT
SM281885,-99.8133,18.7019,Zacualpan,ADM2,DISTRICT
SM281734,-98.5935,20.6561,Zacualtipán de Angeles,ADM2,DISTRICT
SM276029,15.2302,44.1217,Zadar,ADM2,DISTRICT
SM3821,15.3333,44.0833,Zadarska,ADM1,DISTRICT
SM274572,13.9081,0.9258,Zadié,ADM2,DISTRICT
SM293576,38.9167,52.4167,Zadonskiy rayon,ADM2,DISTRICT
SM305494,16.891,43.4309,Zadvarje,ADM2,DISTRICT
SM303487,67.7772,40.3661,Zafarobod,ADM2,DISTRICT
SM305978,68.9076,40.1819,Zafarobod,ADM2,DISTRICT
SM258780,47.75,-22.42,Zafisoro,ETHNICITY,ETHNICITY
SM283756,99.1666,46.9373,Zag,ADM2,DISTRICT
SM291213,24.6034,46.3567,Zagar,ADM2,DISTRICT
SM462106,23.45,16.41,Zaghawa,ETHNICITY,ETHNICITY
SM298041,10.1713,36.4425,Zaghouan,ADM2,DISTRICT
SM3822,10,36.3333,Zaghouan,ADM1,DISTRICT
SM262130,2.392,7.254,Zagnanado,ADM2,DISTRICT
SM290237,26.129,45.7796,Zagon,ADM2,DISTRICT
SM281429,-5.8365,30.3484,Zagora,ADM2,DISTRICT
SM296246,14.9658,46.1297,Zagorje ob Savi,ADM1:ADM2,DISTRICT
SM305745,15.6353,46.0944,Zagorska Sela,ADM2,DISTRICT
SM292542,24.2606,47.3708,Zagra,ADM2,DISTRICT
SM3823,16.0833,45.75,Zagrebačka,ADM1,DISTRICT
SM305495,17.0526,43.3973,Zagvozd,ADM2,DISTRICT
SM280905,35.9,33.8333,Zahleh,ADM2,DISTRICT
SM11597,22.1893,48.3491,Zahonyi Jaras,ADM2,DISTRICT
SM17826,43.4667,17.8833,Zahran al Janub,ADM2,DISTRICT
SM12859,-76.8098,18.0257,Zaidie Gardens,ADM2,DISTRICT
SM295127,108.5,51.8333,Zaigraevskiy rayon,ADM2,DISTRICT
SM294646,52.05,55.3167,Zainsk,ADM2,DISTRICT
SM294647,52,55.3,Zainskiy rayon,ADM2,DISTRICT
SM3824,13.5197,-6.5872,Zaire,ADM1,DISTRICT
SM249809,98.3643,24.1726,Zaiwa,LANGUAGE,LANGUAGE
SM3826,21.9878,43.6992,Zaječarski,ADM1:ADM2,DISTRICT
SM18479,31.4267,-20.3601,Zaka City,ADM2,DISTRICT
SM18480,31.37,-20.05,Zaka District (excl. Zaka),ADM2,DISTRICT
SM295128,103.5,50.5833,Zakamenskiy rayon,ADM2,DISTRICT
SM10547,15.3379,45.6095,Zakanje (2003-),ADM2,DISTRICT
SM294227,39.25,54.4167,Zakharovskiy rayon,ADM2,DISTRICT
SM305777,42.8333,37.15,Zakho,ADM2,DISTRICT
SM249812,97.022,28.129,Zakhring,LANGUAGE,LANGUAGE
SM285322,10.3089,12.2992,Zaki,ADM2,DISTRICT
SM249816,9.2333,9.7333,Zakshi,DIALECT,LANGUAGE
SM3827,16.8333,46.6667,Zala,ADM1,DISTRICT
SM292919,101.5,53.1667,Zalarinskiy rayon,ADM2,DISTRICT
SM276222,17.101,46.9207,Zalaszentgrót,ADM1:ADM2,DISTRICT
SM291640,23.0966,47.1663,Zalau,ADM2,DISTRICT
SM13946,23.5121,56.5081,Zalenieku pagasts,ADM2,DISTRICT
SM13947,28.1028,56.3543,Zalesjes pagasts,ADM2,DISTRICT
SM293016,84.6667,54.0833,Zalesovskiy rayon,ADM2,DISTRICT
SM291641,23.5314,47.1979,Zalha,ADM2,DISTRICT
SM299742,25.7312,48.64,Zalishchyts'kyi,ADM2,DISTRICT
SM285822,5.1525,51.7868,Zaltbommel,ADM2,DISTRICT
SM13948,25.1967,56.3385,Zalves pagasts,ADM2,DISTRICT
SM290801,22.4879,46.0458,Zam,ADM2,DISTRICT
SM278551,139.4092,35.4875,Zama,ADM2,DISTRICT
SM304826,47.7033,17.2032,Zamakh wa Manwakh,ADM2,DISTRICT
SM16292,-66.6051,18.1873,Zamas Barrio,ADM2,DISTRICT
SM249826,18.3,1.25,Zamba,LANGUAGE,LANGUAGE
SM3828,120.1667,15.3333,Zambales,ADM1:ADM2,DISTRICT
SM305200,22.954,-13.5896,Zambezi,ADM2,DISTRICT
SM3829,23.75,-17.8333,Zambezi,ADM1,DISTRICT
SM3830,37,-17,Zambezia,ADM1,DISTRICT
SM249823,28.9898,-15.5134,Zambian Sign Language,LANGUAGE,LANGUAGE
SM4203,123.1348,7.896,Zamboanga (2001-),ADM1,DISTRICT
SM3833,122.5436,7.7627,Zamboanga Sibugay,ADM1:ADM2,DISTRICT
SM3831,122.6667,8,Zamboanga del Norte,ADM1:ADM2,DISTRICT
SM3832,123.3194,7.9043,Zamboanga del Sur (2001-),ADM1:ADM2,DISTRICT
SM270710,-74.8849,9.7482,Zambrano,ADM2,DISTRICT
SM291952,24.9985,44.3035,Zambreasca,ADM2,DISTRICT
SM288687,22.285,53.0226,Zambrów,ADM2,DISTRICT
SM3834,6.25,12.1667,Zamfara,ADM1,DISTRICT
SM303648,-66.5,10.5,Zamora,ADM2,DISTRICT
SM303580,-69.0333,11.3667,Zamora,ADM2,DISTRICT
SM303832,-67.5,10.0667,Zamora,ADM2,DISTRICT
SM274075,-6,41.75,Zamora,ADM2,DISTRICT
SM281995,-102.286,20.0214,Zamora,ADM2,DISTRICT
SM273583,-78.95,-4.0667,Zamora,ADM2,DISTRICT
SM3835,-78.8333,-4.25,Zamora Chinchipe,ADM1,DISTRICT
SM291865,26.2033,47.8661,Zamostea,ADM2,DISTRICT
SM288907,23.2126,50.6916,Zamość,ADM2,DISTRICT
SM288906,23.2485,50.7214,Zamość (City),ADM2,DISTRICT
SM249831,-59.4397,-18.9104,Zamuco,LANGUAGE,LANGUAGE
SM283802,111.9061,43.7337,Zamyn-Üüd,ADM2,DISTRICT
SM283802,111.9061,43.7337,Zamyn-Üüd,ADM2,DISTRICT
SM283802,111.8943,43.7666,Zamyn-Üüd,ADM2,DISTRICT
SM283802,111.8943,43.7666,Zamyn-Üüd,ADM2,DISTRICT
SM249837,19.1416,10.5885,Zan Gula,LANGUAGE,LANGUAGE
SM260295,68.6659,33.6792,Zana Khan,ADM2,DISTRICT
SM269828,13.8255,-2.8514,Zanaga,ADM2,DISTRICT
SM249832,34.0542,-2.2637,Zanaki,LANGUAGE,LANGUAGE
SM13949,22.2071,56.4835,Zanas pagasts,ADM2,DISTRICT
SM249835,26.7157,4.6205,Zande,LANGUAGE,LANGUAGE
SM250829,29.46,3.37,Zande,ETHNICITY,ETHNICITY
SM250829,29.46,3.37,Zande,ETHNICITY,ETHNICITY
SM250829,29.46,3.37,Zande,ETHNICITY,ETHNICITY
SM250829,29.46,3.37,Zande,ETHNICITY,ETHNICITY
SM250829,26.5,4.4165,Zande,ETHNICITY,ETHNICITY
SM250829,26.5,4.4165,Zande,ETHNICITY,ETHNICITY
SM250829,26.5,4.4165,Zande,ETHNICITY,ETHNICITY
SM250829,26.5,4.4165,Zande,ETHNICITY,ETHNICITY
SM250829,27,5,Zande,ETHNICITY,ETHNICITY
SM250829,27,5,Zande,ETHNICITY,ETHNICITY
SM250829,27,5,Zande,ETHNICITY,ETHNICITY
SM250829,27,5,Zande,ETHNICITY,ETHNICITY
SM250829,28.25,5.08,Zande,ETHNICITY,ETHNICITY
SM250829,28.25,5.08,Zande,ETHNICITY,ETHNICITY
SM250829,28.25,5.08,Zande,ETHNICITY,ETHNICITY
SM250829,28.25,5.08,Zande,ETHNICITY,ETHNICITY
SM286029,4.5439,52.3577,Zandvoort,ADM2,DISTRICT
SM291385,26.5621,46.8257,Zanesti,ADM2,DISTRICT
SM10255,20.8206,4.6007,Zangba,ADM2,DISTRICT
SM303451,69.0833,41.25,Zangiota,ADM2,DISTRICT
SM284924,8.5327,12.9311,Zango,ADM2,DISTRICT
SM284823,8.2549,9.841,ZangonKa,ADM2,DISTRICT
SM249836,77.4013,33.2397,Zangskari,LANGUAGE,LANGUAGE
SM249840,9.7469,10.2284,Zangwal,LANGUAGE,LANGUAGE
SM249842,-97.2876,16.6804,Zaniza Zapotec,LANGUAGE,LANGUAGE
SM16293,-66.8428,18.4528,Zanja Barrio,ADM2,DISTRICT
SM15385,-55.7088,-22.7719,Zanja Pyta,ADM2,DISTRICT
SM277671,48.25,36.5833,Zanjan,ADM2,DISTRICT
SM3836,48.3333,36.5,Zanjan (1996-),ADM1,DISTRICT
SM13950,22.7299,56.8469,Zantes pagasts,ADM2,DISTRICT
SM3837,-3.2138,8.5002,Zanzan,ADM1,DISTRICT
SM3838,39.2833,-5.9167,Zanzibar North,ADM1,DISTRICT
SM3839,39.4167,-6.25,Zanzibar South and Central,ADM1,DISTRICT
SM3840,39.25,-6.1667,Zanzibar West,ADM1,DISTRICT
SM294725,37.5,54.6667,Zaokskiy rayon,ADM2,DISTRICT
SM18013,10.6222,35.7764,Zaouia-Ksiba-Thrayet,ADM2,DISTRICT
SM269298,117.4108,34.9342,Zaozhuang,ADM2,DISTRICT
SM3841,19.1122,45.7742,Zapadno-Bački,ADM1:ADM2,DISTRICT
SM260922,-69.8333,-39,Zapala,ADM2,DISTRICT
SM258791,-74.5,-0.21,Zaparo,ETHNICITY,ETHNICITY
SM302810,-99.1686,27.0008,Zapata,ADM2,DISTRICT
SM270526,-73.2677,6.8058,Zapatoca,ADM2,DISTRICT
SM10489,-74.6914,10.1185,Zapayan,ADM2,DISTRICT
SM292328,27.6416,46.7664,Zapodeni,ADM2,DISTRICT
SM281764,-103.4971,20.7927,Zapopan,ADM2,DISTRICT
SM3842,35.1406,47.8397,Zaporizhzhya,ADM1,DISTRICT
SM18292,35.0109,47.8609,Zaporizhzhya Raion,ADM2,DISTRICT
SM258792,-95.47,16.37,Zapotec,ETHNICITY,ETHNICITY
SM258792,-95.47,16.37,Zapotec,ETHNICITY,ETHNICITY
SM258792,-95.47,16.37,Zapotec,ETHNICITY,ETHNICITY
SM258792,-95.47,16.37,Zapotec,ETHNICITY,ETHNICITY
SM258792,-97,17,Zapotec,ETHNICITY,ETHNICITY
SM258792,-97,17,Zapotec,ETHNICITY,ETHNICITY
SM258792,-97,17,Zapotec,ETHNICITY,ETHNICITY
SM258792,-97,17,Zapotec,ETHNICITY,ETHNICITY
SM258792,-96,17,Zapotec,ETHNICITY,ETHNICITY
SM258792,-96,17,Zapotec,ETHNICITY,ETHNICITY
SM258792,-96,17,Zapotec,ETHNICITY,ETHNICITY
SM258792,-96,17,Zapotec,ETHNICITY,ETHNICITY
SM258792,-96.5167,17.0167,Zapotec,ETHNICITY,ETHNICITY
SM258792,-96.5167,17.0167,Zapotec,ETHNICITY,ETHNICITY
SM258792,-96.5167,17.0167,Zapotec,ETHNICITY,ETHNICITY
SM258792,-96.5167,17.0167,Zapotec,ETHNICITY,ETHNICITY
SM273469,-80.3212,-4.2204,Zapotillo,ADM2,DISTRICT
SM281765,-103.3489,19.6167,Zapotiltic,ADM2,DISTRICT
SM14912,-98.3934,17.7494,Zapotitlan Lagunas,ADM2,DISTRICT
SM14913,-97.8172,17.8797,Zapotitlan Palmas,ADM2,DISTRICT
SM282353,-97.5084,18.2814,Zapotitlán,ADM2,DISTRICT
SM275189,-89.8174,14.1112,Zapotitlán,ADM2,DISTRICT
SM281650,-98.7976,17.397,Zapotitlán Tablas (pre-1993),ADM2,DISTRICT
SM282354,-97.691,20.0016,Zapotitlán de Méndez,ADM2,DISTRICT
SM281766,-103.7888,19.5127,Zapotitlán de Vadillo,ADM2,DISTRICT
SM281768,-103.0452,20.6226,Zapotlanejo,ADM2,DISTRICT
SM281735,-98.8608,19.9924,Zapotlán de Juárez,ADM2,DISTRICT
SM281767,-102.9143,20.455,Zapotlán del Rey,ADM2,DISTRICT
SM276035,15.8,45.8667,Zaprešić,ADM2,DISTRICT
SM261958,46.6667,41.5833,Zaqatala,ADM1:ADM2,DISTRICT
SM298930,37.775,39.8241,Zara,ADM2,DISTRICT
SM274060,-1,41.5833,Zaragoza,ADM2,DISTRICT
SM282839,-94.6436,17.9487,Zaragoza,ADM2,DISTRICT
SM282526,-100.6891,22.0253,Zaragoza,ADM2,DISTRICT
SM282355,-97.5616,19.7573,Zaragoza,ADM2,DISTRICT
SM283308,-101.1543,28.7224,Zaragoza,ADM2,DISTRICT
SM275396,-90.8829,14.6799,Zaragoza,ADM2,DISTRICT
SM295670,-89.2979,13.5788,Zaragoza,ADM2,DISTRICT
SM270175,-74.8757,7.4829,Zaragoza,ADM2,DISTRICT
SM249859,38.9258,-6.8771,Zaramo,LANGUAGE,LANGUAGE
SM290554,21.5691,46.4391,Zarand,ADM2,DISTRICT
SM277509,56.4167,30.85,Zarand,ADM2,DISTRICT
SM249861,9.57,10.28,Zaranda,LANGUAGE,LANGUAGE
SM277571,50.3333,35.3667,Zarandiyeh,ADM2,DISTRICT
SM258793,43.38,15.02,Zaraniq,ETHNICITY,ETHNICITY
SM260139,62.0365,30.911,Zaranj,ADM2,DISTRICT
SM281364,26.25,55.7333,Zarasų,ADM2,DISTRICT
SM17161,38.9167,54.75,Zarayskiy Rayon,ADM2,DISTRICT
SM303594,-65.3245,9.3503,Zaraza,ADM2,DISTRICT
SM303488,68.1667,40.1667,Zarbdor,ADM2,DISTRICT
SM14131,-9.5536,5.557,Zarflahn District,ADM2,DISTRICT
SM9607,68.4778,32.8537,Zarghun Shahr (2005-),ADM2,DISTRICT
SM249864,9.4033,9.7127,Zari,LANGUAGE,LANGUAGE
SM9608,66.7262,35.8473,Zari,ADM2,DISTRICT
SM284824,7.6962,10.9885,Zaria,ADM2,DISTRICT
SM293017,85.3333,53.9167,Zarinskiy rayon,ADM2,DISTRICT
SM249866,2.4117,12.8554,Zarma-Kaado,LANGUAGE,LANGUAGE
SM292666,25.352,45.5565,Zarnesti,ADM2,DISTRICT
SM249869,4.3877,45.5627,Zarphatic,LANGUAGE,LANGUAGE
SM273820,31.6789,31.2323,Zarqa,ADM2,DISTRICT
SM3843,36.0777,32.0837,Zarqa,ADM1,DISTRICT
SM277672,48.25,36.3333,Zarrinabad,ADM2,DISTRICT
SM273620,-79.6167,-3.6833,Zaruma,ADM2,DISTRICT
SM286851,-80.25,-3.6667,Zarumilla,ADM2,DISTRICT
SM270641,-76.0484,4.3608,Zarzal,ADM2,DISTRICT
SM16294,-65.7872,18.3869,Zarzal Barrio,ADM2,DISTRICT
SM297906,10.9637,33.431,Zarzis,ADM2,DISTRICT
SM18014,9.8863,37.2598,Zarzouna,ADM2,DISTRICT
SM13951,26.0241,56.273,Zasas pagasts,ADM2,DISTRICT
SM299870,25.8449,48.5231,Zastavnets'kyi,ADM2,DISTRICT
SM291214,24.1522,46.6083,Zau De Campie,ADM2,DISTRICT
SM13952,25.3043,56.9919,Zaubes pagasts,ADM2,DISTRICT
SM282356,-97.6595,19.7201,Zautla,ADM2,DISTRICT
SM249870,98.8634,26.4572,Zauzou,LANGUAGE,LANGUAGE
SM294845,53.0833,56.8333,Zav'yalovskiy rayon,ADM2,DISTRICT
SM293018,81,52.9167,Zav'yalovskiy rayon,ADM2,DISTRICT
SM302811,-99.7606,28.8662,Zavala,ADM2,DISTRICT
SM283906,34.6926,-24.6729,Zavala,ADM2,DISTRICT
SM262363,26.6833,43.7667,Zavet,ADM2,DISTRICT
SM293229,129.5,50.1667,Zavitinskiy rayon,ADM2,DISTRICT
SM283733,93.1091,48.8055,Zavkhan,ADM2,DISTRICT
SM283841,95.1071,48.3111,Zavkhanmandal,ADM2,DISTRICT
SM292708,27.4561,44.9528,Zavoaia,ADM2,DISTRICT
SM9821,27.6622,53.8619,Zavodski Rayon,ADM2,DISTRICT
SM11354,44.75,41.7,Zavodskoy Rayon,ADM2,DISTRICT
SM290063,22.4435,45.4971,Zavoi,ADM2,DISTRICT
SM292948,42.0833,57.5,Zavolzhskiy rayon,ADM2,DISTRICT
SM296350,16.0453,46.3592,Zavrc,ADM1:ADM2,DISTRICT
SM288743,19.6119,50.5423,Zawiercie,ADM2,DISTRICT
SM249875,38.8258,8.0115,Zay,LANGUAGE,LANGUAGE
SM258795,-6.05,34.1,Zayr,ETHNICITY,ETHNICITY
SM279954,85,47.5,Zaysanskiy,ADM2,DISTRICT
SM249873,37.3409,5.8375,Zayse-Zergulla,LANGUAGE,LANGUAGE
SM249876,159.255,-8.1872,Zazao,LANGUAGE,LANGUAGE
SM258796,8,11,Zazzagawa Hausa,ETHNICITY,ETHNICITY
SM258796,8,11,Zazzagawa Hausa,ETHNICITY,ETHNICITY
SM258796,7.5,10.5,Zazzagawa Hausa,ETHNICITY,ETHNICITY
SM258796,7.5,10.5,Zazzagawa Hausa,ETHNICITY,ETHNICITY
SM305644,17.6568,43.0111,Zažablje,ADM2,DISTRICT
SM299743,25.7781,49.666,Zbaraz'kyi,ADM2,DISTRICT
SM299744,25.1383,49.6667,Zborivs'kyi,ADM2,DISTRICT
SM249879,101.7048,32.1506,Zbu,LANGUAGE,LANGUAGE
SM305528,17.9515,45.5825,Zdenci,ADM2,DISTRICT
SM288882,18.9509,51.6204,Zduńska Wola,ADM2,DISTRICT
SM293884,78.75,54.6667,Zdvinskiy rayon,ADM2,DISTRICT
SM303627,-71.7819,8.3763,Zea,ADM2,DISTRICT
SM15291,145.8535,16.8953,Zealandia Bank,ADM2,DISTRICT
SM259980,71.342,36.4266,Zebak,ADM2,DISTRICT
SM13953,22.8937,56.5986,Zebrenes pagasts,ADM2,DISTRICT
SM3845,3.75,51.4167,Zeeland,ADM1,DISTRICT
SM249882,9.2893,9.95,Zeem,LANGUAGE,LANGUAGE
SM249883,3.8543,51.4981,Zeeuws,LANGUAGE,LANGUAGE
SM285726,5.459,52.3442,Zeewolde,ADM2,DISTRICT
SM249885,52.1167,32.8059,Zefra'i,DIALECT,LANGUAGE
SM12027,61.5667,30.8667,Zehak,ADM2,DISTRICT
SM285610,5.2328,52.0885,Zeist,ADM2,DISTRICT
SM250158,-2,34,Zekara,ETHNICITY,ETHNICITY
SM262613,24.9167,53.1667,Zel'va,ADM2,DISTRICT
SM293108,41.5,43.5,Zelenchukskiy rayon,ADM2,DISTRICT
SM3846,21.5833,41.8333,Zelenikovo,ADM1,DISTRICT
SM294648,48.5,55.8333,Zelenodol'sk,ADM2,DISTRICT
SM294649,48.75,55.9167,Zelenodol'skiy rayon,ADM2,DISTRICT
SM13288,50.8333,51.1667,Zelenoe Audany (exc. Oral),ADM2,DISTRICT
SM293661,37.1661,55.9874,Zelenogradsky,ADM2,DISTRICT
SM261851,12.7,47.2833,Zell am See,ADM2,DISTRICT
SM13954,26.7675,57.3495,Zeltinu pagasts,ADM2,DISTRICT
SM249886,13.4424,-17.2958,Zemba,LANGUAGE,LANGUAGE
SM249887,93.6463,25.3499,Zeme Naga,LANGUAGE,LANGUAGE
SM262329,22.7144,42.5507,Zemen,ADM2,DISTRICT
SM292170,26.4338,46.5917,Zemes,ADM2,DISTRICT
SM249889,70.7511,34.8883,Zemiaki,DIALECT,LANGUAGE
SM13955,22.8183,56.9037,Zemites pagasts,ADM2,DISTRICT
SM258797,-5.59,34.59,Zemmur,ETHNICITY,ETHNICITY
SM281736,-98.6687,19.9385,Zempoala,ADM2,DISTRICT
SM305606,15.3797,44.1056,Zemunik Donji,ADM2,DISTRICT
SM249890,146.546,-6.9386,Zenag,LANGUAGE,LANGUAGE
SM249891,-16.1711,16.82,Zenaga,LANGUAGE,LANGUAGE
SM250832,-7,16,Zenaga,ETHNICITY,ETHNICITY
SM250832,-7,16,Zenaga,ETHNICITY,ETHNICITY
SM250832,-8,18,Zenaga,ETHNICITY,ETHNICITY
SM250832,-8,18,Zenaga,ETHNICITY,ETHNICITY
SM262552,18.1922,44.23,Zenica-Doboj,ADM1:ADM2,DISTRICT
SM17710,-61.0339,13.8595,Zenon,ADM2,DISTRICT
SM258798,15.75,30.05,Zentan,ETHNICITY,ETHNICITY
SM258798,11.86,31.4,Zentan,ETHNICITY,ETHNICITY
SM13956,23.0371,57.1594,Zentenes pagasts,ADM2,DISTRICT
SM282842,-96.7544,19.0724,Zentla,ADM2,DISTRICT
SM278471,133.7769,34.2258,Zentsūji,ADM2,DISTRICT
SM249896,-97.4555,16.528,Zenzontepec Chatino,LANGUAGE,LANGUAGE
SM297913,10.6084,35.5341,Zeramdine,ADM2,DISTRICT
SM283587,92.8436,47.1061,Zereg,ADM2,DISTRICT
SM13289,69.1667,52.9167,Zerendi (excl. Kokshetau),ADM2,DISTRICT
SM290555,21.5078,46.6309,Zerind,ADM2,DISTRICT
SM274796,43.05,42.1,Zestaponi,ADM2,DISTRICT
SM270744,-73.1828,5.2582,Zetaquirá,ADM2,DISTRICT
SM290731,25.4282,46.4605,Zetea,ADM2,DISTRICT
SM295767,43.1689,10.8942,Zeylac,ADM2,DISTRICT
SM17163,128.5,54.5,Zeyskiy Rayon,ADM2,DISTRICT
SM298523,28.9095,41.0082,Zeytinburnu,ADM2,DISTRICT
SM250834,31.54,-20.2,Zezeru,ETHNICITY,ETHNICITY
SM280923,35.9086,34.3465,Zgharta,ADM2,DISTRICT
SM288883,19.4802,51.9041,Zgierz,ADM2,DISTRICT
SM288670,15.0786,51.163,Zgorzelec,ADM2,DISTRICT
SM291313,25.086,44.9465,Zgripcesti,ADM2,DISTRICT
SM249901,100.983,30.7416,Zhaba,LANGUAGE,LANGUAGE
SM262575,24.0833,52.1667,Zhabinka,ADM2,DISTRICT
SM13290,67.5,52,Zhaksy (excl. Qima),ADM2,DISTRICT
SM279870,64.6667,45.0833,Zhalagashskiy,ADM2,DISTRICT
SM13291,50,49.75,Zhalpaqtal Audany,ADM2,DISTRICT
SM3849,72.0833,44.3333,Zhambyl,ADM1:ADMX,DISTRICT
SM13292,75.75,43.5,Zhambyl Audany (excl. Kurti),ADM2,DISTRICT
SM13292,75.75,43.5,Zhambyl Audany (excl. Kurti),ADM2,DISTRICT
SM13292,76.313,43.2234,Zhambyl Audany (excl. Kurti),ADM2,DISTRICT
SM13292,76.313,43.2234,Zhambyl Audany (excl. Kurti),ADM2,DISTRICT
SM13293,66.5,54.3333,Zhambyl Audany (excl. Presnovka),ADM2,DISTRICT
SM13293,66.5,54.3333,Zhambyl Audany (excl. Presnovka),ADM2,DISTRICT
SM13293,71.25,43,Zhambyl Audany (excl. Presnovka),ADM2,DISTRICT
SM13293,71.25,43,Zhambyl Audany (excl. Presnovka),ADM2,DISTRICT
SM13295,71.3667,42.9,Zhambyl Qalasy,ADM2,DISTRICT
SM279871,67.25,43.5,Zhanakorganskiy,ADM2,DISTRICT
SM13296,70.5,47.6667,Zhangaarqa Audany (excl. Qarazhal),ADM2,DISTRICT
SM13297,67.0833,51.1667,Zhangadala Audany,ADM2,DISTRICT
SM13298,52.8,43.3,Zhangaozen Qalasy,ADM2,DISTRICT
SM13299,50.25,48.7667,Zhangaqala Audany (excl. Zhalpaqtal),ADM2,DISTRICT
SM13300,79.75,50.1667,Zhangasemey Audany,ADM2,DISTRICT
SM13301,69.75,43.5667,Zhangatas Qalasy,ADM2,DISTRICT
SM269199,110.5301,29.3738,Zhangjiajie,ADM2,DISTRICT
SM269138,114.9333,40.8333,Zhangjiakou,ADM2,DISTRICT
SM249903,117.4167,25.2833,Zhangping-Longyan,DIALECT,LANGUAGE
SM269407,99.925,39.1218,Zhangye,ADM2,DISTRICT
SM269401,117.4084,24.3563,Zhangzhou,ADM2,DISTRICT
SM249902,81.3125,31.0667,Zhangzhung,LANGUAGE,LANGUAGE
SM269425,109.9714,21.0814,Zhanjiang,ADM2,DISTRICT
SM269426,112.2178,23.6363,Zhaoqing,ADM2,DISTRICT
SM269373,104.0387,27.7045,Zhaotong,ADM2,DISTRICT
SM9609,65.3986,31.6418,Zharey,ADM2,DISTRICT
SM13302,66.3333,51.1667,Zharkain (excl. Zhangadala),ADM2,DISTRICT
SM13303,81.25,48.8333,Zharma Audany (excl.  Shar),ADM2,DISTRICT
SM299435,30.0977,49.246,Zhashkivs'kyi,ADM2,DISTRICT
SM299969,38.2713,48.1264,Zhdanivs'ka,ADM2,DISTRICT
SM3850,120,29.1667,Zhejiang,ADM1,DISTRICT
SM258799,120.083,31,Zhejiang,ETHNICITY,ETHNICITY
SM258799,120.083,31,Zhejiang,ETHNICITY,ETHNICITY
SM258799,120.083,31,Zhejiang,ETHNICITY,ETHNICITY
SM258799,120,31,Zhejiang,ETHNICITY,ETHNICITY
SM258799,120,31,Zhejiang,ETHNICITY,ETHNICITY
SM258799,120,31,Zhejiang,ETHNICITY,ETHNICITY
SM258799,120.08,31,Zhejiang,ETHNICITY,ETHNICITY
SM258799,120.08,31,Zhejiang,ETHNICITY,ETHNICITY
SM258799,120.08,31,Zhejiang,ETHNICITY,ETHNICITY
SM279986,76,53.8333,Zhelezinskiy,ADM2,DISTRICT
SM17956,68.8,38.55,Zheleznodorozhnyy Rayon,ADM2,DISTRICT
SM293519,35.4167,52.25,Zheleznogorskiy rayon,ADM2,DISTRICT
SM3851,90.85,27.0833,Zhemgang,ADM1,DISTRICT
SM249907,119.2779,25.2475,Zhenghe,DIALECT,LANGUAGE
SM269167,113.5542,34.6883,Zhengzhou,ADM2,DISTRICT
SM249906,113.6394,34.8077,Zhengzhou Mandarin,DIALECT,LANGUAGE
SM269213,119.5184,32.0337,Zhenjiang,ADM2,DISTRICT
SM240724,99.108,23.1024,Zhenkang Wa,LANGUAGE,LANGUAGE
SM13304,61,52,Zhetiqara Audany (excl. Zhetiqara),ADM2,DISTRICT
SM13305,61.2,52.1833,Zhetiqara Qalasy,ADM2,DISTRICT
SM13306,68.25,40.75,Zhetisay Audany,ADM2,DISTRICT
SM13307,66.5,49.3333,Zhezdi Audany,ADM2,DISTRICT
SM13308,67.7667,47.7833,Zhezqazghan Qalasy,ADM2,DISTRICT
SM292920,105.5,55,Zhigalovskiy rayon,ADM2,DISTRICT
SM294332,123.25,66.75,Zhiganskiy rayon,ADM2,DISTRICT
SM249909,7.93,9.55,Zhire,LANGUAGE,LANGUAGE
SM294932,45,51,Zhirnovskiy rayon,ADM2,DISTRICT
SM295026,33.5833,53.25,Zhiryatinskiy rayon,ADM2,DISTRICT
SM293086,34.75,53.6667,Zhizdrinskiy rayon,ADM2,DISTRICT
SM9822,29.8333,52.75,Zhlobinski Rayon (excl. Zhlobin),ADM2,DISTRICT
SM11387,28.1149,49.0337,Zhmerynka Raion (2020-),ADM2,DISTRICT
SM249910,10.1665,6.5361,Zhoa,LANGUAGE,LANGUAGE
SM5104,69.2075,31.3644,Zhob (2006-),ADM2:ADM3,DISTRICT
SM269428,113.3755,22.5536,Zhongshan,ADM2,DISTRICT
SM249911,103.4418,32.4358,Zhongu,LANGUAGE,LANGUAGE
SM457983,105.4966,36.9851,Zhongwei (incl. Hanjiaoshui),ADM2,DISTRICT
SM249916,100.0988,25.8959,Zhoucheng,DIALECT,LANGUAGE
SM269168,114.9119,33.8083,Zhoukou,ADM2,DISTRICT
SM269384,122.4028,30.1694,Zhoushan,ADM2,DISTRICT
SM299599,32.0204,46.8729,Zhovtnevyi,ADM2,DISTRICT
SM279901,70.75,42.6667,Zhualynskiy,ADM2,DISTRICT
SM269429,113.3139,22.1761,Zhuhai,ADM2,DISTRICT
SM293087,36.75,55.0833,Zhukovskiy rayon,ADM2,DISTRICT
SM295028,33.8333,53.5,Zhukovskiy rayon,ADM2,DISTRICT
SM269169,114.0617,32.9942,Zhumadian,ADM2,DISTRICT
SM269200,113.0823,27.8245,Zhuzhou,ADM2,DISTRICT
SM13309,53.98,47,Zhylyoi,ADM2,DISTRICT
SM13310,54.3333,46.8333,Zhylyoy Audany (excl. Zhylyoi),ADM2,DISTRICT
SM262597,27.75,52.25,Zhytkavichy,ADM2,DISTRICT
SM3852,28.5,50.5833,Zhytomyr,ADM1,DISTRICT
SM18293,28.6716,50.2497,Zhytomyr City Amalgamated Territorial Commmunity,ADM2,DISTRICT
SM299859,28.6732,50.265,Zhytomyrs'ka,ADM2,DISTRICT
SM299860,28.5833,50.25,Zhytomyrs'kyi,ADM2,DISTRICT
SM249919,147.579,-7.9581,Zia,LANGUAGE,LANGUAGE
SM258801,-107,36,Zia,ETHNICITY,ETHNICITY
SM258801,-107,36,Zia,ETHNICITY,ETHNICITY
SM258801,-106.5,35.3,Zia,ETHNICITY,ETHNICITY
SM258801,-106.5,35.3,Zia,ETHNICITY,ETHNICITY
SM249921,-9.51,8.43,Zialo,LANGUAGE,LANGUAGE
SM294298,57,52,Zianchurinskiy rayon,ADM2,DISTRICT
SM375396,67.5096,30.4359,Ziarat,ADM2:ADM3,DISTRICT
SM269299,118.0017,36.6503,Zibo,ADM2,DISTRICT
SM289935,27.0597,45.2821,Ziduri,ADM2,DISTRICT
SM302540,-101.6659,44.9804,Ziebach,ADM2,DISTRICT
SM288920,15.444,51.9333,Zielona Góra,ADM2,DISTRICT
SM288919,15.5122,51.9377,Zielona Góra  (City),ADM2,DISTRICT
SM13957,27.0287,57.511,Ziemera pagasts,ADM2,DISTRICT
SM273917,31.2184,30.7265,Zifta,ADM2,DISTRICT
SM269333,104.6984,29.2889,Zigong,ADM2,DISTRICT
SM250835,38.53,-5.57,Zigua,ETHNICITY,ETHNICITY
SM250835,38.53,-5.57,Zigua,ETHNICITY,ETHNICITY
SM250835,38.79,-5.84,Zigua,ETHNICITY,ETHNICITY
SM250835,38.79,-5.84,Zigua,ETHNICITY,ETHNICITY
SM295265,-16.2162,12.522,Ziguinchor,ADM2,DISTRICT
SM3853,-16.3667,12.8,Ziguinchor,ADM1,DISTRICT
SM249927,38.4114,-5.6186,Zigula,DIALECT:LANGUAGE,LANGUAGE
SM249929,38.4114,-5.6186,Zigula-Mushungulu,LANGUAGE,LANGUAGE
SM13958,27.6959,57.2991,Ziguru pagasts,ADM2,DISTRICT
SM282358,-97.82,20.287,Zihuateutla,ADM2,DISTRICT
SM294299,57.25,52.5,Zilairskiy rayon,ADM2,DISTRICT
SM13959,25.1737,57.5768,Zilakalna pagasts,ADM2,DISTRICT
SM298951,35.894,40.3143,Zile,ADM2,DISTRICT
SM260152,69.3268,33.1558,Ziluk,ADM2,DISTRICT
SM13960,28.1162,56.3907,Zilupe,ADM2,DISTRICT
SM3983,28.1111,56.3225,Zilupes Novads,ADM1,DISTRICT
SM249934,141.452,-7.3701,Zimakani,LANGUAGE,LANGUAGE
SM258802,142.06,-7.94,Zimakani,ETHNICITY,ETHNICITY
SM290556,21.3916,46.275,Zimandu Nou,ADM2,DISTRICT
SM281737,-99.3554,20.7438,Zimapán,ADM2,DISTRICT
SM14915,-97.0345,16.7803,Zimatlan de Alvarez,ADM2,DISTRICT
SM249938,26.6083,-4.013,Zimba,LANGUAGE,LANGUAGE
SM250031,33.18,-16.61,Zimba,ETHNICITY,ETHNICITY
SM258803,27.75,-2.55,Zimba,ETHNICITY,ETHNICITY
SM18179,26.5447,-17.6173,Zimba District,ADM2,DISTRICT
SM249936,31.0216,-17.9128,Zimbabwe Sign Language,LANGUAGE,LANGUAGE
SM240232,27.4096,-20.2513,Zimbabwean Ndebele,LANGUAGE,LANGUAGE
SM291642,23.2903,46.9889,Zimbor,ADM2,DISTRICT
SM17164,101.6667,55.8333,Ziminskiy Rayon,ADM2,DISTRICT
SM291953,25.366,43.6566,Zimnicea,ADM2,DISTRICT
SM299657,34.3585,50.2092,Zin'kivs'kyi,ADM2,DISTRICT
SM283113,-92.7757,16.7243,Zinacantán,ADM2,DISTRICT
SM282359,-97.2357,18.33,Zinacatepec,ADM2,DISTRICT
SM281886,-99.7761,19.2646,Zinacatepec,ADM2,DISTRICT
SM281998,-100.7418,19.8689,Zinapécuaro,ADM2,DISTRICT
SM260016,61.8201,34.3711,Zinda Jan,ADM2,DISTRICT
SM3857,10.5,15,Zinder,ADM1,DISTRICT
SM285249,11.7452,8.8834,Zing,ADM2,DISTRICT
SM304952,45.4304,13.1342,Zingibar,ADM2,DISTRICT
SM249941,32.1755,-2.6413,Zinza,LANGUAGE,LANGUAGE
SM258805,32.28,-3.07,Zinza,ETHNICITY,ETHNICITY
SM258805,32.28,-3.07,Zinza,ETHNICITY,ETHNICITY
SM258805,31,-3,Zinza,ETHNICITY,ETHNICITY
SM258805,31,-3,Zinza,ETHNICITY,ETHNICITY
SM281996,-101.9992,20.1694,Zináparo,ADM2,DISTRICT
SM17981,1.1667,6.5833,Zio Prefecture (1995-),ADM2,DISTRICT
SM269974,-74.3876,4.7545,Zipacón,ADM2,DISTRICT
SM269975,-74.0421,5.0263,Zipaquirá,ADM2,DISTRICT
SM281999,-101.9088,19.4189,Ziracuaretiro,ADM2,DISTRICT
SM276207,17.8841,47.2858,Zirc,ADM2,DISTRICT
SM249944,165.482,-21.6544,Zire,LANGUAGE,LANGUAGE
SM249945,18.5374,12.2275,Zirenkel,LANGUAGE,LANGUAGE
SM249946,8.7279,10.5218,Ziriya,LANGUAGE,LANGUAGE
SM13961,22.2519,56.6737,Zirnu pagasts,ADM2,DISTRICT
SM262211,-1.9167,11.5833,Ziro,ADM1:ADM2,DISTRICT
SM13962,21.6096,57.1393,Ziru pagasts,ADM2,DISTRICT
SM281651,-101.1905,18.364,Zirándaro,ADM2,DISTRICT
SM281652,-99.1957,17.7197,Zitlala,ADM2,DISTRICT
SM282718,-97.9105,19.2031,Zitlaltepec de Trinidad Sánchez Santos,ADM2,DISTRICT
SM282001,-100.3574,19.432,Zitácuaro,ADM2,DISTRICT
SM269334,105.17,30.1,Ziyang,ADM2,DISTRICT
SM249949,13.5233,10.4689,Zizilivakan,LANGUAGE,LANGUAGE
SM249950,98.1357,29.7837,Zlarong,LANGUAGE,LANGUAGE
SM276064,16.077,46.092,Zlatar,ADM2,DISTRICT
SM305746,16.0853,46.0479,Zlatar Bistrica,ADM2,DISTRICT
SM262467,25.9833,43.0667,Zlataritsa,ADM2,DISTRICT
SM3859,19.6731,43.7092,Zlatiborski,ADM1:ADM2,DISTRICT
SM262430,24.1333,42.7,Zlatitsa,ADM2,DISTRICT
SM289848,23.2022,46.1375,Zlatna,ADM2,DISTRICT
SM262407,25.0459,41.4003,Zlatograd,ADM2,DISTRICT
SM292777,59.5,55,Zlatoust gorsovet,ADM2,DISTRICT
SM296151,18.3984,48.3829,Zlaté Moravce,ADM2,DISTRICT
SM13963,21.8672,57.1347,Zleku pagasts,ADM2,DISTRICT
SM295029,33.6023,52.5392,Zlynkovskiy rayon,ADM2,DISTRICT
SM271205,17.7833,49.2167,Zlín,ADM2,DISTRICT
SM3860,17.75,49.1667,Zlínský,ADM1,DISTRICT
SM293019,82.5,51.1667,Zmeinogorskiy rayon,ADM2,DISTRICT
SM305496,17.207,43.4112,Zmijavci,ADM2,DISTRICT
SM294594,41.3333,52.3333,Znamenskiy rayon,ADM2,DISTRICT
SM293921,73.75,57.25,Znamenskiy rayon,ADM2,DISTRICT
SM299521,32.6572,48.7205,Znamians'kyi,ADM2,DISTRICT
SM271212,16.0627,48.862,Znojmo,ADM2,DISTRICT
SM249953,-55.5075,-1.7721,Zo'é,LANGUAGE,LANGUAGE
SM370200,-8.5,6.8667,Zoe,ADM2:ADM3,DISTRICT
SM461919,-8.7194,7.0305,Zoe-Gbao,ADM2,DISTRICT
SM285717,4.4883,52.0595,Zoetermeer,ADM2,DISTRICT
SM285719,4.5114,52.1141,Zoeterwoude,ADM2,DISTRICT
SM268897,7.957,47.2886,Zofingen,ADM2,DISTRICT
SM262131,2.183,7.018,Zogbodomey,ADM2,DISTRICT
SM249956,104.8,23.8,Zokhuo,LANGUAGE,LANGUAGE
SM293027,42.9167,43.75,Zol'skiy rayon,ADM2,DISTRICT
SM370387,-8.0833,4.5833,Zolokengban,ADM2:ADM3,DISTRICT
SM370387,-8.0833,4.5833,Zolokengban,ADM2:ADM3,DISTRICT
SM370387,-8.0863,4.6234,Zolokengban,ADM2:ADM3,DISTRICT
SM370387,-8.0863,4.6234,Zolokengban,ADM2:ADM3,DISTRICT
SM299436,32.0376,49.669,Zolotonis'kyi,ADM2,DISTRICT
SM293520,36.5,52.0833,Zolotukhinskiy rayon,ADM2,DISTRICT
SM3861,35.4183,-15.4301,Zomba,ADM1:ADM2,DISTRICT
SM258807,16.15,-5.34,Zombo,ETHNICITY,ETHNICITY
SM296720,31.338,-27.2275,Zombodze,ADM2,DISTRICT
SM303489,68.3333,40,Zomin,ADM2,DISTRICT
SM10490,-74.1572,10.7642,Zona Bananera,ADM2,DISTRICT
SM293020,84.9167,52.6667,Zonal'nyy rayon,ADM2,DISTRICT
SM260976,-68.8994,-31.6826,Zonda,ADM2,DISTRICT
SM262188,-2.3667,13.1833,Zondoma,ADM1:ADM2,DISTRICT
SM269704,18.6,4.35,Zongo,ADM2,DISTRICT
SM282843,-96.9333,18.6512,Zongolica,ADM2,DISTRICT
SM14916,-97.7402,19.9788,Zongozotla,ADM2,DISTRICT
SM3858,31.8333,41.25,Zonguldak (1995-),ADM1,DISTRICT
SM282844,-98.2997,20.7417,Zontecomatlán,ADM2,DISTRICT
SM249961,-96.3433,17.2016,Zoogocho Zapotec,LANGUAGE,LANGUAGE
SM11113,33.0029,34.8662,Zoopigi,ADM2,DISTRICT
SM258808,-93.27,16.62,Zoque,ETHNICITY,ETHNICITY
SM282360,-97.5969,20.0074,Zoquiapan,ADM2,DISTRICT
SM282361,-96.9817,18.377,Zoquitlan,ADM2,DISTRICT
SM292329,27.7387,46.2508,Zorleni,ADM2,DISTRICT
SM290064,21.9816,45.4642,Zorlentu Mare,ADM2,DISTRICT
SM249965,54.4469,31.8161,Zoroastrian Yazdi,LANGUAGE,LANGUAGE
SM266354,-51.5086,-27.4974,Zortéa,ADM2,DISTRICT
SM14132,-9.649,7.9023,Zorzor (excl. Gizima),ADM2,DISTRICT
SM13964,25.8408,57.164,Zosenu pagasts,ADM2,DISTRICT
SM280960,-9.4124,7.3001,Zota,ADM2,DISTRICT
SM249969,93.29,22.5298,Zotung Chin,LANGUAGE,LANGUAGE
SM249970,93.9253,24.0649,Zou,LANGUAGE,LANGUAGE
SM3862,2.1667,7.25,Zou (1999-),ADM1,DISTRICT
SM281445,-5.1026,34.1456,Zouagha-Moulay Yacoub,ADM2,DISTRICT
SM262214,-1,11.5833,Zoundwéogo,ADM1:ADM2,DISTRICT
SM283987,-12.4355,22.7458,Zouérate,ADM2,DISTRICT
SM282845,-97.5613,20.1404,Zozocolco de Hidalgo,ADM2,DISTRICT
SM296234,15.38,46.3939,Zrece,ADM1:ADM2,DISTRICT
SM298042,10.2129,36.3269,Zriba,ADM2,DISTRICT
SM305336,16.7551,46.0212,Zrinski Topolovac,ADM2,DISTRICT
SM3863,22.45,41.8333,Zrnovci,ADM1,DISTRICT
SM250113,11,7,Zuande,ETHNICITY,ETHNICITY
SM304743,20.6333,42.9167,Zubin Potok,ADM2,DISTRICT
SM293652,42.75,54,Zubovo-Polyanskiy rayon,ADM2,DISTRICT
SM294792,34.5833,56.1667,Zubtsovskiy rayon,ADM2,DISTRICT
SM293298,51,58.3333,Zuevskiy rayon,ADM2,DISTRICT
SM269007,8.5205,47.1645,Zug,ADM2,DISTRICT
SM3864,8.5387,47.1589,Zug,ADM1,DISTRICT
SM274835,41.7983,42.4674,Zugdidi,ADM2,DISTRICT
SM296088,-55.2175,5.4396,Zuid,ADM2,DISTRICT
SM3865,4.6667,52,Zuid-Holland,ADM1,DISTRICT
SM249980,9.5667,10.2,Zul,DIALECT,LANGUAGE
SM259857,26.74,-4.584,Zula,LANGUAGE,LANGUAGE
SM454495,14,10.8,Zulgo,ETHNICITY,ETHNICITY
SM249978,14.0578,10.827,Zulgo-Gemzek,LANGUAGE,LANGUAGE
SM3866,-72.1667,10,Zulia,ADM1,DISTRICT
SM249981,30.2,-28.9,Zulu,LANGUAGE,LANGUAGE
SM250837,31.51,-28.54,Zulu,ETHNICITY,ETHNICITY
SM250837,31.51,-28.54,Zulu,ETHNICITY,ETHNICITY
SM250837,31.51,-28.54,Zulu,ETHNICITY,ETHNICITY
SM250837,30.75,-28.5,Zulu,ETHNICITY,ETHNICITY
SM250837,30.75,-28.5,Zulu,ETHNICITY,ETHNICITY
SM250837,30.75,-28.5,Zulu,ETHNICITY,ETHNICITY
SM250837,31,-29,Zulu,ETHNICITY,ETHNICITY
SM250837,31,-29,Zulu,ETHNICITY,ETHNICITY
SM250837,31,-29,Zulu,ETHNICITY,ETHNICITY
SM305116,31.2943,-27.8114,Zululand,ADM2,DISTRICT
SM249985,14.4445,10.558,Zumaya,LANGUAGE,LANGUAGE
SM283867,30.9341,-15.2293,Zumbu,ADM2,DISTRICT
SM249986,9.9683,10.827,Zumbun,LANGUAGE,LANGUAGE
SM281888,-99.5636,18.8359,Zumpahuacan,ADM2,DISTRICT
SM281889,-99.097,19.7989,Zumpango,ADM2,DISTRICT
SM285966,4.6418,51.4735,Zundert,ADM2,DISTRICT
SM277097,94.5,26,Zunheboto,ADM2,DISTRICT
SM249992,-108.782,35.0056,Zuni,LANGUAGE,LANGUAGE
SM258810,-108.85,35.07,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.85,35.07,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.85,35.07,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.85,35.07,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.85,35.07,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.75,35.667,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.75,35.667,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.75,35.667,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.75,35.667,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.75,35.667,Zuni,ETHNICITY,ETHNICITY
SM258810,-109,35,Zuni,ETHNICITY,ETHNICITY
SM258810,-109,35,Zuni,ETHNICITY,ETHNICITY
SM258810,-109,35,Zuni,ETHNICITY,ETHNICITY
SM258810,-109,35,Zuni,ETHNICITY,ETHNICITY
SM258810,-109,35,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.9,35.2,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.9,35.2,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.9,35.2,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.9,35.2,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.9,35.2,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.75,35.67,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.75,35.67,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.75,35.67,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.75,35.67,Zuni,ETHNICITY,ETHNICITY
SM258810,-108.75,35.67,Zuni,ETHNICITY,ETHNICITY
SM275226,-91.4915,14.7436,Zunil,ADM2,DISTRICT
SM275363,-91.4986,14.6303,Zunilito,ADM2,DISTRICT
SM269459,107.0748,28.1547,Zunyi,ADM2,DISTRICT
SM249993,106.9,22.4,Zuojiang Zhuang,LANGUAGE,LANGUAGE
SM260164,69.0418,33.424,Zurmat,ADM2,DISTRICT
SM285280,6.7938,12.8115,Zurmi,ADM2,DISTRICT
SM284944,5.2872,11.4464,Zuru,ADM2,DISTRICT
SM268898,8.2802,47.5618,Zurzach,ADM2,DISTRICT
SM278552,139.5913,35.299,Zushi,ADM2,DISTRICT
SM258812,22.14,29.46,Zuwayah,ETHNICITY,ETHNICITY
SM13965,22.6038,56.5533,Zvardes pagasts,ADM2,DISTRICT
SM13966,26.2185,57.589,Zvartavas pagasts,ADM2,DISTRICT
SM293626,47.75,56.25,Zvenigovskiy rayon,ADM2,DISTRICT
SM299437,30.9619,49.0765,Zvenyhorods'kyi,ADM2,DISTRICT
SM304744,20.7752,42.9827,Zvečan,ADM2,DISTRICT
SM305258,30.475,-17.5398,Zvimba,ADM2,DISTRICT
SM13967,27.7125,56.5764,Zvirgzdenes pagasts,ADM2,DISTRICT
SM305228,30.0751,-20.3035,Zvishavane,ADM2,DISTRICT
SM296117,19.1333,48.5833,Zvolen,ADM2,DISTRICT
SM291866,26.2864,47.8181,Zvoristea,ADM2,DISTRICT
SM313241,19.0414,44.4103,Zvornik,ADM2:ADM3,DISTRICT
SM285575,6.074,52.6024,Zwartewaterland,ADM2,DISTRICT
SM261820,15.1716,48.5744,Zwettl,ADM2,DISTRICT
SM285720,4.6126,51.824,Zwijndrecht,ADM2,DISTRICT
SM288986,21.5928,51.3608,Zwoleń,ADM2,DISTRICT
SM285576,6.0936,52.5126,Zwolle,ADM2,DISTRICT
SM11114,33.3383,34.7332,Zygi,ADM2,DISTRICT
SM250001,93.264,22.524,Zyphe,LANGUAGE,LANGUAGE
SM13311,84.5,49.8333,Zyryanovsk Audany (excl. Serebryansk and Zyranovsk),ADM2,DISTRICT
SM13312,84.2744,49.7256,Zyryanovsk Qalasy,ADM2,DISTRICT
SM249852,-76.364,-1.9987,Záparo,LANGUAGE,LANGUAGE
SM260728,-59.1667,-34,Zárate,ADM2,DISTRICT
SM262148,2.336,6.765,Zè,ADM2,DISTRICT
SM262935,-46.0573,-3.2259,Zé Doca,ADM2,DISTRICT
SM268587,25.2374,5.4004,Zémio,ADM2,DISTRICT
SM269012,8.5432,47.3711,Zürich,ADM2,DISTRICT
SM3867,8.6667,47.4167,Zürich,ADM1,DISTRICT
SM283647,102.5944,46.5192,Züünbayan-Ulaan,ADM2,DISTRICT
SM283647,102.5944,46.5192,Züünbayan-Ulaan,ADM2,DISTRICT
SM283647,102.5958,46.5222,Züünbayan-Ulaan,ADM2,DISTRICT
SM283647,102.5958,46.5222,Züünbayan-Ulaan,ADM2,DISTRICT
SM283663,105.8804,50.0593,Züünbüren,ADM2,DISTRICT
SM283663,105.8804,50.0593,Züünbüren,ADM2,DISTRICT
SM283663,105.8897,50.0697,Züünbüren,ADM2,DISTRICT
SM283663,105.8897,50.0697,Züünbüren,ADM2,DISTRICT
SM283734,93.977,50.1236,Züüngovi,ADM2,DISTRICT
SM283734,93.977,50.1236,Züüngovi,ADM2,DISTRICT
SM283734,93.977,50.1236,Züüngovi,ADM2,DISTRICT
SM283734,93.7857,49.9015,Züüngovi,ADM2,DISTRICT
SM283734,93.7857,49.9015,Züüngovi,ADM2,DISTRICT
SM283734,93.7857,49.9015,Züüngovi,ADM2,DISTRICT
SM283734,93.7928,49.9053,Züüngovi,ADM2,DISTRICT
SM283734,93.7928,49.9053,Züüngovi,ADM2,DISTRICT
SM283734,93.7928,49.9053,Züüngovi,ADM2,DISTRICT
SM283735,95.4529,49.3074,Züünkhangai,ADM2,DISTRICT
SM283735,95.4529,49.3074,Züünkhangai,ADM2,DISTRICT
SM283735,95.4492,49.2974,Züünkhangai,ADM2,DISTRICT
SM283735,95.4492,49.2974,Züünkhangai,ADM2,DISTRICT
SM288669,16.8722,50.5567,Ząbkowice Śląskie,ADM2,DISTRICT
SM288671,15.8795,51.0913,Złotoryja,ADM2,DISTRICT
SM288815,16.9152,53.4126,Złotów,ADM2,DISTRICT
SM261932,46.6667,39.0833,Zəngilan,ADM1:ADM2,DISTRICT
SM261904,47.75,40.25,Zərdab,ADM1:ADM2,DISTRICT
SM255,44.9167,12.8333,Adan,ADM1,DISTRICT
SM17827,42.75,23.6667,Afif,ADM2,DISTRICT
SM12079,35.2333,32.95,Akko,ADM2,DISTRICT
SM17828,41.5,30.9167,Arar,ADM2,DISTRICT
SM256,42.95,19.15,Asir,ADM1,DISTRICT
SM17829,43.5833,26.5,Uyun al Jiwa',ADM2,DISTRICT
SM251745,44.6,40.3,asserian,ETHNICITY,ETHNICITY
SM253199,130.65,-14.59,djamadjong,ETHNICITY,ETHNICITY
SM253199,130.65,-14.59,djamadjong,ETHNICITY,ETHNICITY
SM253199,130.3903,-15.154,djamadjong,ETHNICITY,ETHNICITY
SM253199,130.3903,-15.154,djamadjong,ETHNICITY,ETHNICITY
SM305117,31.0167,-29.8667,eThekwini,ADM2,DISTRICT
SM305118,31.1425,-29.2733,iLembe,ADM2,DISTRICT
SM276099,-72.2877,19.656,l'Acul-du-Nord,ADM2,DISTRICT
SM276105,-72.4723,18.8406,l'Arcahaie,ADM2,DISTRICT
SM284591,167.4833,-22.6167,l'Île des Pins,ADM2,DISTRICT
SM255211,98.53,18.4,lawa,ETHNICITY,ETHNICITY
SM255211,98.53,18.4,lawa,ETHNICITY,ETHNICITY
SM255211,98,18,lawa,ETHNICITY,ETHNICITY
SM255211,98,18,lawa,ETHNICITY,ETHNICITY
SM276100,-72.1446,19.6905,le Cap-Haïtien,ADM2,DISTRICT
SM276101,-72.4051,19.6914,le Limbé,ADM2,DISTRICT
SM276091,-72.0011,19.6013,le Trou-du-Nord,ADM2,DISTRICT
SM276076,-73.8205,18.2622,les Cayes,ADM2,DISTRICT
SM276077,-74.2189,18.3411,les Chardonnières,ADM2,DISTRICT
SM276078,-74.0234,18.2427,les Côteaux,ADM2,DISTRICT
SM276085,-72.5923,19.4681,les Gonaïves,ADM2,DISTRICT
SM300093,-57.7409,-30.8523,n.a11,ADM2,DISTRICT
SM300118,-57.7992,-33.4699,n.a112,ADM2,DISTRICT
SM300230,-54.4985,-33.4872,n.a115,ADM2,DISTRICT
SM300120,-57.5279,-33.6435,n.a122,ADM2,DISTRICT
SM300123,-57.2382,-33.8589,n.a126,ADM2,DISTRICT
SM300085,-54.2609,-33.7651,n.a128,ADM2,DISTRICT
SM300095,-57.0059,-31.082,n.a13,ADM2,DISTRICT
SM300096,-56.3253,-31.1639,n.a14,ADM2,DISTRICT
SM300184,-57.3633,-33.9689,n.a145,ADM2,DISTRICT
SM300097,-57.5691,-31.1213,n.a150,ADM2,DISTRICT
SM300098,-57.7569,-31.385,n.a151,ADM2,DISTRICT
SM300105,-56.53,-34.2007,n.a154,ADM2,DISTRICT
SM300077,-55.8456,-31.2127,n.a16,ADM2,DISTRICT
SM300159,-56.0732,-34.4234,n.a171,ADM2,DISTRICT
SM300160,-55.9584,-34.415,n.a172,ADM2,DISTRICT
SM300228,-55.4136,-34.5938,n.a179,ADM2,DISTRICT
SM300162,-55.8121,-34.5235,n.a181,ADM2,DISTRICT
SM300163,-56.2413,-34.5356,n.a182,ADM2,DISTRICT
SM300164,-56.0649,-34.5217,n.a183,ADM2,DISTRICT
SM300100,-57.1789,-31.3556,n.a19,ADM2,DISTRICT
SM300187,-57.8267,-33.959,n.a197,ADM2,DISTRICT
SM300081,-55.4998,-31.5103,n.a25,ADM2,DISTRICT
SM300051,-57.8626,-31.6521,n.a26,ADM2,DISTRICT
SM300082,-54.6022,-31.7963,n.a28,ADM2,DISTRICT
SM300086,-53.5,-33.7313,n.a289,ADM2,DISTRICT
SM300087,-53.8789,-34.0174,n.a291,ADM2,DISTRICT
SM300043,-57.578,-30.3761,n.a3,ADM2,DISTRICT
SM300237,-54.6435,-34.2547,n.a307,ADM2,DISTRICT
SM300108,-56.9921,-34.3839,n.a312,ADM2,DISTRICT
SM300190,-57.586,-34.2626,n.a315,ADM2,DISTRICT
SM300165,-56.2942,-34.4216,n.a324,ADM2,DISTRICT
SM300092,-54.1784,-34.5682,n.a328,ADM2,DISTRICT
SM300150,-56.0645,-34.6205,n.a340,ADM2,DISTRICT
SM300243,-55.3866,-34.7097,n.a345,ADM2,DISTRICT
SM300155,-56.0926,-34.7263,n.a347,ADM2,DISTRICT
SM300179,-58.3452,-33.8897,n.a382,ADM2,DISTRICT
SM300058,-57.9364,-31.9264,n.a39,ADM2,DISTRICT
SM300052,-57.5383,-32.3109,n.a49,ADM2,DISTRICT
SM300045,-57.3792,-30.5069,n.a5,ADM2,DISTRICT
SM300053,-56.8926,-32.3018,n.a50,ADM2,DISTRICT
SM300245,-54.6123,-34.6283,n.a523,ADM2,DISTRICT
SM300176,-54.8049,-32.6611,n.a61,ADM2,DISTRICT
SM300177,-53.716,-32.6866,n.a62,ADM2,DISTRICT
SM300167,-55.016,-32.7389,n.a65,ADM2,DISTRICT
SM300168,-53.4614,-32.6208,n.a66,ADM2,DISTRICT
SM300169,-54.132,-32.6569,n.a67,ADM2,DISTRICT
SM300064,-57.9857,-32.9746,n.a76,ADM2,DISTRICT
SM300048,-57.3996,-30.7603,n.a8,ADM2,DISTRICT
SM300144,-54.8929,-33.0015,n.a83,ADM2,DISTRICT
SM300145,-54.0685,-32.9413,n.a86,ADM2,DISTRICT
SM300193,-55.915,-33.1651,n.a92,ADM2,DISTRICT
SM265989,-52.0656,-27.6954,urea,ADM2,DISTRICT
SM235698,22.2901,-29.4046,||Kxau,DIALECT,LANGUAGE
SM225031,6.009,7.4414,Àhàn,LANGUAGE,LANGUAGE
SM264167,-50.6215,-6.6313,Água Azul do Norte,ADM2,DISTRICT
SM263779,-42.2303,-18.0642,Água Boa,ADM2,DISTRICT
SM265169,-42.6106,-5.8991,Água Branca,ADM2,DISTRICT
SM264222,-37.661,-7.4696,Água Branca,ADM2,DISTRICT
SM265337,-37.9192,-9.2699,Água Branca,ADM2,DISTRICT
SM263890,-48.1103,-19.9912,Água Comprida,ADM2,DISTRICT
SM266377,-51.6364,-26.7969,Água Doce,ADM2,DISTRICT
SM262944,-42.1413,-2.9125,Água Doce do Maranhão,ADM2,DISTRICT
SM267976,-40.9919,-18.5065,Água Doce do Norte,ADM2,DISTRICT
SM267725,-38.735,-11.7476,Água Fria,ADM2,DISTRICT
SM268230,-47.8496,-14.9606,Água Fria de Goiás,ADM2,DISTRICT
SM296045,6.7333,0.3333,Água Grande,ADM1:ADM2,DISTRICT
SM268241,-48.7995,-18.08,Água Limpa,ADM2,DISTRICT
SM265540,-38.3063,-6.2131,Água Nova,ADM2,DISTRICT
SM264971,-35.486,-8.7614,Água Preta,ADM2,DISTRICT
SM264982,-37.019,-9.1047,Águas Belas,ADM2,DISTRICT
SM264067,-40.9727,-17.0485,Águas Formosas,ADM2,DISTRICT
SM266399,-52.8506,-26.8576,Águas Frias,ADM2,DISTRICT
SM268252,-48.2861,-15.7661,Águas Lindas de Goiás,ADM2,DISTRICT
SM266410,-48.9202,-27.7423,Águas Mornas,ADM2,DISTRICT
SM263225,-41.5604,-15.6868,Águas Vermelhas,ADM2,DISTRICT
SM266765,-46.6867,-21.9162,Águas da Prata,ADM2,DISTRICT
SM266388,-52.9639,-27.0723,Águas de Chapecó,ADM2,DISTRICT
SM266876,-46.6284,-22.477,Águas de Lindóia,ADM2,DISTRICT
SM266987,-49.2536,-22.8576,Águas de Santa Bárbara,ADM2,DISTRICT
SM267043,-47.8748,-22.6024,Águas de São Pedro,ADM2,DISTRICT
SM289209,-8.4254,40.5805,Águeda,ADM2,DISTRICT
SM282572,-108.9027,27.0816,Álamos,ADM2,DISTRICT
SM274053,-2.75,42.8333,Álava,ADM2,DISTRICT
SM266488,-49.9141,-20.2776,Álvares Florence,ADM2,DISTRICT
SM266499,-51.5042,-22.1402,Álvares Machado,ADM2,DISTRICT
SM282021,-101.0215,19.8598,Álvaro Obregón,ADM2,DISTRICT
SM266510,-49.7351,-22.0868,Álvaro de Carvalho,ADM2,DISTRICT
SM225429,11.1087,6.6652,Áncá,LANGUAGE,LANGUAGE
SM282774,-95.3972,18.608,Ángel R. Cabada,ADM2,DISTRICT
SM277832,-21.75,66.0667,Árneshreppur,ADM2,DISTRICT
SM277801,-20.5948,63.8759,Ásahreppur,ADM2,DISTRICT
SM274067,-5,40.5833,Ávila,ADM2,DISTRICT
SM304202,106.1,20.8109,Ân Thi,ADM2,DISTRICT
SM264499,-51.9385,-23.2163,Ângulo,ADM2,DISTRICT
SM226076,166.3287,-10.2826,Äiwoo,LANGUAGE,LANGUAGE
SM296666,14.155,56.5856,Älmhult,ADM2,DISTRICT
SM296541,13.2726,61.6134,Älvdalen,ADM2,DISTRICT
SM296496,17.4453,60.5821,Älvkarleby,ADM2,DISTRICT
SM296389,20.7141,65.732,Älvsbyn,ADM2,DISTRICT
SM296428,12.9662,56.2681,Ängelholm,ADM2,DISTRICT
SM346802,26.0107,62.6785,Äänekoski,ADM2:ADM3,DISTRICT
SM346811,22.0202,60.1186,Åboland-Turunmaa,ADM2:ADM3,DISTRICT
SM461892,10.2259,63.962,Åfjord (2020-),ADM2,DISTRICT
SM286315,8.5612,60.6303,Ål,ADM2,DISTRICT
SM225191,19.9422,60.2151,Åland Islands Swedish,DIALECT,LANGUAGE
SM286300,8.4853,58.766,Åmli,ADM2,DISTRICT
SM286366,11.3733,61.132,Åmot,ADM2,DISTRICT
SM296589,12.5958,59.0457,Åmål,ADM2,DISTRICT
SM296534,15.6445,62.5123,Ånge,ADM2,DISTRICT
SM286154,7.702,61.2364,Årdal,ADM2,DISTRICT
SM296635,13.1262,63.3831,Åre,ADM2,DISTRICT
SM271689,10.169,56.1632,Århus,ADM2,DISTRICT
SM296503,12.1037,59.4359,Årjäng,ADM2,DISTRICT
SM286035,10.791,59.6637,Ås,ADM2,DISTRICT
SM296519,17.6236,64.166,Åsele,ADM2,DISTRICT
SM286248,7.4112,58.6173,Åseral,ADM2,DISTRICT
SM286370,12.012,60.6136,Åsnes,ADM2,DISTRICT
SM296439,12.9726,56.14,Åstorp,ADM2,DISTRICT
SM296415,16.1252,58.228,Åtvidaberg,ADM2,DISTRICT
SM271747,10.4333,54.8583,Ærø,ADM2,DISTRICT
SM298318,29.4047,38.0937,Çal,ADM2,DISTRICT
SM298997,43.9142,39.1505,Çaldıran,ADM2,DISTRICT
SM298795,34.99,37.8416,Çamardı,ADM2,DISTRICT
SM298810,37.5216,40.9084,Çamaş,ADM2,DISTRICT
SM298301,29.3523,37.0866,Çameli,ADM2,DISTRICT
SM298902,32.4799,40.4999,Çamlıdere,ADM2,DISTRICT
SM298828,41.0207,41.0409,Çamlıhemşin,ADM2,DISTRICT
SM298754,34.6068,37.18,Çamlıyayla,ADM2,DISTRICT
SM298438,38.7339,40.1342,Çamoluk,ADM2,DISTRICT
SM298269,27.0665,40.044,Çan,ADM2,DISTRICT
SM825,26.8333,40.0833,Çanakkale,ADM1,DISTRICT
SM298439,39.05,40.86,Çanakçı,ADM2,DISTRICT
SM299033,35.5228,39.2545,Çandır,ADM2,DISTRICT
SM298903,32.8418,39.8609,Çankaya,ADM2,DISTRICT
SM831,33.4167,40.6667,Çankiri (1995-),ADM1,DISTRICT
SM298302,29.7059,37.7749,Çardak,ADM2,DISTRICT
SM298859,36.732,41.2105,Çarşamba,ADM2,DISTRICT
SM298973,39.4004,41.042,Çarşıbaşı,ADM2,DISTRICT
SM298399,40.9785,39.6106,Çat,ADM2,DISTRICT
SM298998,43.062,38.0078,Çatak,ADM2,DISTRICT
SM298498,28.4542,41.2965,Çatalca,ADM2,DISTRICT
SM298604,34.2029,41.938,Çatalzeytin,ADM2,DISTRICT
SM298703,29.6309,39.2033,Çavdarhisar,ADM2,DISTRICT
SM298239,29.6994,37.1648,Çavdır,ADM2,DISTRICT
SM298373,31.0355,38.6008,Çay,ADM2,DISTRICT
SM298811,37.1087,41.0253,Çaybaşı,ADM2,DISTRICT
SM299040,32.0846,41.4366,Çaycuma,ADM2,DISTRICT
SM298829,40.737,41.0894,Çayeli,ADM2,DISTRICT
SM298974,40.2419,40.7456,Çaykara,ADM2,DISTRICT
SM299034,35.6516,39.3138,Çayıralan,ADM2,DISTRICT
SM298379,40.0372,39.8045,Çayırlı,ADM2,DISTRICT
SM298561,37.2903,37.7498,Çağlayancerit,ADM2,DISTRICT
SM299035,35.4987,40.0833,Çekerek,ADM2,DISTRICT
SM298499,29.2003,41.0382,Çekmekoy,ADM2,DISTRICT
SM298630,33.529,39.4747,Çelebi,ADM2,DISTRICT
SM298226,38.2371,38.0328,Çelikhan,ADM2,DISTRICT
SM298694,31.7941,39.0339,Çeltik,ADM2,DISTRICT
SM298240,30.4682,37.5388,Çeltikçi,ADM2,DISTRICT
SM298978,38.914,39.0623,Çemişgezek,ADM2,DISTRICT
SM298279,32.9001,40.8217,Çerkeş,ADM2,DISTRICT
SM298325,39.4524,38.136,Çermik,ADM2,DISTRICT
SM298556,26.321,38.3166,Çeşme,ADM2,DISTRICT
SM298413,31.0435,39.3927,Çifteler,ADM2,DISTRICT
SM298796,34.4746,38.1858,Çiftlik,ADM2,DISTRICT
SM299006,29.3395,40.653,Çiftlikköy,ADM2,DISTRICT
SM298334,31.0591,40.9027,Çilimli,ADM2,DISTRICT
SM298154,28.0679,37.6227,Çine,ADM2,DISTRICT
SM298303,29.7243,38.3119,Çivril,ADM2,DISTRICT
SM298655,34.4183,39.6164,Çiçekdağı,ADM2,DISTRICT
SM298557,26.966,38.488,Çiğli,ADM2,DISTRICT
SM298374,30.7887,38.7109,Çobanlar,ADM2,DISTRICT
SM1025,34.75,40.5,Çorum,ADM1,DISTRICT
SM298904,33.0471,40.2398,Çubuk,ADM2,DISTRICT
SM298451,43.6212,37.2549,Çukurca,ADM2,DISTRICT
SM298696,32.7797,37.5817,Çumra,ADM2,DISTRICT
SM298327,39.2882,38.2118,Çüngüş,ADM2,DISTRICT
SM299045,43.1417,41.1378,Çıldır,ADM2,DISTRICT
SM298326,40.4148,37.7256,Çınar,ADM2,DISTRICT
SM299007,29.1388,40.6367,Çınarcık,ADM2,DISTRICT
SM3906,18.9333,0.2,Équateur (pre-2015),ADM1,DISTRICT
SM276159,18.9017,47.356,Érd,ADM1:ADM2,DISTRICT
SM267394,-42.1033,-13.437,Érico Cardoso,ADM2,DISTRICT
SM274577,9.707,-1.631,Étimboué,ADM2,DISTRICT
SM289496,-7.8946,38.5665,Évora,ADM2,DISTRICT
SM1229,-7.8333,38.5833,Évora,ADM1,DISTRICT
SM289227,-8.72,40.6117,Ílhavo,ADM2,DISTRICT
SM277839,-23.1279,66.0759,Ísafjarðarbær,ADM2,DISTRICT
SM1558,2.5,48.7,Île-de-France,ADM1,DISTRICT
SM1559,-150,-23,Îles Australes,ADM1,DISTRICT
SM1560,51,-46.4167,Îles Crozet,ADM1,DISTRICT
SM1563,167.2614,-20.9191,Îles Loyauté,ADM1,DISTRICT
SM1564,-139,-9.75,Îles Marquises,ADM1,DISTRICT
SM1565,77.55,-37.8333,Îles Saint Paul et Amsterdam,ADM1,DISTRICT
SM1566,-151.5,-16.6667,Îles Sous-le-Vent,ADM1,DISTRICT
SM1567,-140.9333,-18.1667,Îles Tuamotu-Gambier,ADM1,DISTRICT
SM1561,-149.5,-17.5,Îles du Vent,ADM1,DISTRICT
SM1562,42.7448,-17.0433,Îles Éparses,ADM1,DISTRICT
SM2398,-57.9167,-26.6667,Ñeembucú,ADM1,DISTRICT
SM289718,-57.5377,-25.397,Ñemby,ADM2,DISTRICT
SM260941,-70.4167,-41.6667,Ñorquinco,ADM2,DISTRICT
SM260918,-70.5833,-37.75,Ñorquín,ADM2,DISTRICT
SM2538,-72.2164,-36.6229,Ñuble,ADM1,DISTRICT
SM289551,-56.3144,-25.9166,Ñumí,ADM2,DISTRICT
SM286651,-81.4702,8.4782,Ñürüm,ADM2,DISTRICT
SM289245,-9.176,39.3585,Óbidos,ADM2,DISTRICT
SM264203,-55.8239,0.1324,Óbidos,ADM2,DISTRICT
SM266747,-49.39,-22.9496,Óleo,ADM2,DISTRICT
SM303948,105.5933,10.1254,Ô Môn,ADM2,DISTRICT
SM296591,11.6483,57.6953,Öckerö,ADM2,DISTRICT
SM298545,27.9801,38.2382,Ödemiş,ADM2,DISTRICT
SM296427,14.7407,58.2264,Ödeshög,ADM2,DISTRICT
SM283525,102.5505,47.6603,Ögii nuur,ADM2,DISTRICT
SM277809,-21.4001,63.9744,Ölfushreppur,ADM2,DISTRICT
SM283742,92.0366,49.0257,Ölgii,ADM2,DISTRICT
SM283697,89.9621,48.9638,Ölgii (city),ADM2,DISTRICT
SM283697,89.9621,48.9638,Ölgii (city),ADM2,DISTRICT
SM283697,89.8951,48.9883,Ölgii (city),ADM2,DISTRICT
SM283697,89.8951,48.9883,Ölgii (city),ADM2,DISTRICT
SM283641,103.3165,46.6441,Ölziit,ADM2,DISTRICT
SM283753,100.8169,46.0623,Ölziit,ADM2,DISTRICT
SM283753,100.8169,46.0623,Ölziit,ADM2,DISTRICT
SM283813,106.199,45.2906,Ölziit,ADM2,DISTRICT
SM283813,106.199,45.2906,Ölziit,ADM2,DISTRICT
SM283813,106.6724,44.7934,Ölziit,ADM2,DISTRICT
SM283813,106.6724,44.7934,Ölziit,ADM2,DISTRICT
SM283753,100.8158,46.0667,Ölziit,ADM2,DISTRICT
SM283753,100.8158,46.0667,Ölziit,ADM2,DISTRICT
SM283526,102.557,48.0979,Ölziit,ADM2,DISTRICT
SM298748,40.9947,37.3794,Ömerli,ADM2,DISTRICT
SM241673,148.107,-9.0314,Ömie,LANGUAGE,LANGUAGE
SM283569,109.9165,47.8609,Ömnödelger,ADM2,DISTRICT
SM283743,91.7179,49.1021,Ömnögovi,ADM2,DISTRICT
SM2589,104,43,Ömnögovi,ADM1,DISTRICT
SM283527,100.5088,48.0395,Öndör-Ulaan,ADM2,DISTRICT
SM283726,94.87,49.229,Öndörkhangai,ADM2,DISTRICT
SM283726,94.87,49.229,Öndörkhangai,ADM2,DISTRICT
SM283726,94.8594,49.2652,Öndörkhangai,ADM2,DISTRICT
SM283726,94.8594,49.2652,Öndörkhangai,ADM2,DISTRICT
SM283814,108.2828,45.222,Öndörshil,ADM2,DISTRICT
SM283711,105.2312,47.3035,Öndörshireet,ADM2,DISTRICT
SM241693,92.4707,10.6926,Önge,LANGUAGE,LANGUAGE
SM296406,15.2836,59.2717,Örebro,ADM2,DISTRICT
SM283798,110.7676,44.7089,Örgön,ADM2,DISTRICT
SM296440,13.3394,56.3246,Örkelljunga,ADM2,DISTRICT
SM296537,18.271,63.5146,Örnsköldsvik,ADM2,DISTRICT
SM2623,15.75,58.4167,Östergötland,ADM1,DISTRICT
SM296640,14.9086,63.2207,Östersund,ADM2,DISTRICT
SM296473,18.2998,59.4835,Österåker,ADM2,DISTRICT
SM296500,18.2697,60.2416,Östhammar,ADM2,DISTRICT
SM296442,14.1598,56.2721,Östra Göinge,ADM2,DISTRICT
SM296391,22.5793,66.4704,Överkalix,ADM2,DISTRICT
SM296392,23.4387,66.4832,Övertorneå,ADM2,DISTRICT
SM2644,102.5,46,Övörhangay,ADM1,DISTRICT
SM298994,43.996,38.6628,Özalp,ADM2,DISTRICT
SM298623,35.7115,39.1174,Özvatan,ADM2,DISTRICT
SM286075,15.087,68.9146,Øksnes,ADM2,DISTRICT
SM461875,9.6663,63.6857,Ørland (2020-),ADM2,DISTRICT
SM286106,10.82,60.6766,Østre Toten,ADM2,DISTRICT
SM286323,9.9084,59.7711,Øvre Eiker,ADM2,DISTRICT
SM286107,10.413,61.265,Øyer,ADM2,DISTRICT
SM461878,4.8389,60.5904,Øygarden (2020-),ADM2,DISTRICT
SM286108,9.0787,61.1338,Øystre Slidre,ADM2,DISTRICT
SM269074,-74,-50.75,Última Esperanza,ADM2,DISTRICT
SM282828,-96.3876,19.4393,Úrsulo Galván,ADM2,DISTRICT
SM3607,13.8333,50.5,Ústecký,ADM1,DISTRICT
SM271201,14.05,50.6667,Ústí nad Labem,ADM2,DISTRICT
SM271244,16.5333,50,Ústí nad Orlicí,ADM2,DISTRICT
SM298521,29.109,41.0348,Ümraniye,ADM2,DISTRICT
SM298808,37.2725,41.1392,Ünye,ADM2,DISTRICT
SM298792,34.9402,38.5734,Ürgüp,ADM2,DISTRICT
SM269349,87.5833,43.8,Ürümqi,ADM2,DISTRICT
SM283586,92.0261,46.0479,Üyench,ADM2,DISTRICT
SM298387,39.7013,39.7094,Üzümlü,ADM2,DISTRICT
SM304309,106.0233,20.3167,Ý Yên,ADM2,DISTRICT
SM269089,-109.2975,-27.1105,ísla de Pascua,ADM2,DISTRICT
SM275977,14.5833,45.5833,Čabar,ADM2,DISTRICT
SM305402,17.9883,45.3511,Čaglin,ADM2,DISTRICT
SM810,21.4439,42.0792,Čair,ADM1:ADM2,DISTRICT
SM313214,19.1056,43.5753,Čajniče,ADM2:ADM3,DISTRICT
SM275955,16.4333,46.3858,Čakovec,ADM2,DISTRICT
SM305431,14.4877,45.3573,Čavle,ADM2,DISTRICT
SM275949,16.6167,45.75,Čazma,ADM2,DISTRICT
SM305523,17.8738,45.6,Čačinci,ADM2,DISTRICT
SM305529,17.8556,45.7428,Čađavica,ADM2,DISTRICT
SM852,21.5903,41.5983,Čaška,ADM1,DISTRICT
SM313196,17.4172,44.7269,Čelinac,ADM2:ADM3,DISTRICT
SM305397,18.6692,45.6863,Čeminac,ADM2,DISTRICT
SM305398,18.566,45.5242,Čepin,ADM2,DISTRICT
SM271226,14.6667,50.6667,Česká Lípa,ADM2,DISTRICT
SM271147,14.5,49,České Budějovice,ADM2,DISTRICT
SM271148,14.3333,48.75,Český Krumlov,ADM2,DISTRICT
SM905,22.2667,41.9,Češinovo-Obleševo,ADM1,DISTRICT
SM296377,16.2994,46.5572,Črenšovci,ADM1:ADM2,DISTRICT
SM296275,14.8214,46.4642,Črna na Koroškem,ADM1:ADM2,DISTRICT
SM1039,21.4167,42.1667,Čučer Sandevo,ADM1,DISTRICT
SM304441,106.9721,16.5554,Đa Krông,ADM2,DISTRICT
SM304733,20.3833,42.3833,Đakovica,ADM2,DISTRICT
SM1062,20.4235,42.3877,Đakovica,ADM1,DISTRICT
SM275962,18.4167,45.3,Đakovo,ADM2,DISTRICT
SM304274,108.1494,12.0541,Đam Rông,ADM2,DISTRICT
SM304160,105.6756,21.1153,Đan Phượng,ADM2,DISTRICT
SM305704,16.8701,46.2499,Đelekovec,ADM2,DISTRICT
SM304038,102.9824,21.5019,Điện Biên,ADM2,DISTRICT
SM1103,102.9333,21.3333,Điện Biên,ADM1,DISTRICT
SM304037,103.0584,21.4035,Điện Biên Phủ,ADM2,DISTRICT
SM304035,103.269,21.2527,Điện Biên Đông,ADM2,DISTRICT
SM304395,108.2247,15.8992,Điện Bàn,ADM2,DISTRICT
SM304359,105.1611,21.6178,Đoan Hùng,ADM2,DISTRICT
SM305338,17.4298,45.6659,Đulovac,ADM2,DISTRICT
SM305747,15.8379,46.1971,Đurmanec,ADM2,DISTRICT
SM305366,18.0503,45.5413,Đurđenovac,ADM2,DISTRICT
SM304194,105.0793,20.9006,Đà Bắc,ADM2,DISTRICT
SM304273,108.4741,11.9315,Đà Lạt,ADM2,DISTRICT
SM1052,108.0833,16.0833,Đà Nẵng,ADM1,DISTRICT
SM304251,107.1293,21.5421,Đình Lập,ADM2,DISTRICT
SM304334,105.3403,18.8926,Đô Lương,ADM2,DISTRICT
SM304161,105.8423,21.1352,Đông Anh,ADM2,DISTRICT
SM304396,107.7557,15.9379,Đông Giang,ADM2,DISTRICT
SM304442,107.0907,16.8055,Đông Hà,ADM2,DISTRICT
SM304368,109.3523,12.9452,Đông Hòa,ADM2,DISTRICT
SM304489,106.3409,20.5439,Đông Hưng,ADM2,DISTRICT
SM304029,105.4343,9.1482,Đông Hải,ADM2,DISTRICT
SM304527,105.7247,19.7974,Đông Sơn,ADM2,DISTRICT
SM304426,106.5966,21.1104,Đông Triều,ADM2,DISTRICT
SM303994,107.8024,12.1014,Đăk Glong,ADM2,DISTRICT
SM304061,108.6118,13.9207,Đăk Pơ,ADM2,DISTRICT
SM304060,108.142,14.0659,Đăk Đoa,ADM2,DISTRICT
SM304265,108.554,11.7531,Đơn Dương,ADM2,DISTRICT
SM304270,107.6383,11.4247,Đạ Huoai,ADM2,DISTRICT
SM304271,107.5279,11.5845,Đạ Tẻh,ADM2,DISTRICT
SM304394,107.9789,15.8372,Đại Lộc,ADM2,DISTRICT
SM304497,105.6153,21.6343,Đại Từ,ADM2,DISTRICT
SM304597,107.2788,10.4803,Đất Đỏ,ADM2,DISTRICT
SM303955,105.2411,8.9603,Đầm Dơi,ADM2,DISTRICT
SM304425,107.5618,21.3969,Đầm Hà,ADM2,DISTRICT
SM304235,107.7509,15.1136,Đắk Glei,ADM2,DISTRICT
SM304237,107.9855,14.6033,Đắk Hà,ADM2,DISTRICT
SM303991,107.6729,12.5163,Đắk Mil,ADM2,DISTRICT
SM1049,107.75,12.1667,Đắk Nông (2003-),ADM1,DISTRICT
SM303992,107.5224,11.8799,Đắk R'Lấp,ADM2,DISTRICT
SM303993,107.6217,12.2328,Đắk Song,ADM2,DISTRICT
SM304238,107.8082,14.7009,Đắk Tô,ADM2,DISTRICT
SM304498,105.6212,21.9053,Định Hóa,ADM2,DISTRICT
SM304002,107.3396,11.2187,Định Quán,ADM2,DISTRICT
SM304159,105.8268,21.0135,Đống Đa,ADM2,DISTRICT
SM304084,106.7684,20.7243,Đồ Sơn,ADM2,DISTRICT
SM304499,105.8901,21.6536,Đồng Hỷ,ADM2,DISTRICT
SM1050,107.1667,11,Đồng Nai (1992-),ADM1,DISTRICT
SM303930,107.0119,11.5007,Đồng Phú,ADM2,DISTRICT
SM1051,105.6333,10.5833,Đồng Tháp,ADM1,DISTRICT
SM304125,105.2763,23.2545,Đồng Văn,ADM2,DISTRICT
SM303931,106.8508,11.5212,Đồng Xoài,ADM2,DISTRICT
SM304367,108.9846,13.4148,Đồng Xuân,ADM2,DISTRICT
SM304059,107.6697,13.7845,Đức Cơ,ADM2,DISTRICT
SM304296,106.2591,10.8647,Đức Huệ,ADM2,DISTRICT
SM304295,106.4242,10.8784,Đức Hòa,ADM2,DISTRICT
SM303936,107.5302,11.1777,Đức Linh,ADM2,DISTRICT
SM304409,108.9786,14.78,Đức Phổ,ADM2,DISTRICT
SM304174,105.6102,18.497,Đức Thọ,ADM2,DISTRICT
SM304272,108.3533,11.6269,Đức Trọng,ADM2,DISTRICT
SM283396,14.4319,35.8917,Ħ'Attard,ADM1:ADM2,DISTRICT
SM283436,14.3833,35.8597,Ħad-Dingli,ADM1:ADM2,DISTRICT
SM283397,14.4554,35.8993,Ħal Balzan,ADM1:ADM2,DISTRICT
SM283425,14.5167,35.8458,Ħal Għaxaq,ADM1:ADM2,DISTRICT
SM283426,14.4842,35.8442,Ħal Kirkop,ADM1:ADM2,DISTRICT
SM283398,14.4422,35.9014,Ħal Lija,ADM1:ADM2,DISTRICT
SM283427,14.4833,35.8597,Ħal Luqa,ADM1:ADM2,DISTRICT
SM283428,14.4667,35.8792,Ħal Qormi,ADM1:ADM2,DISTRICT
SM283429,14.4917,35.8347,Ħal Safi,ADM1:ADM2,DISTRICT
SM283452,14.5131,35.8667,Ħal Tarxien,ADM1:ADM2,DISTRICT
SM283430,14.4883,35.8869,Ħamrun,ADM1:ADM2,DISTRICT
SM283453,14.5417,35.875,Ħaż-Żabbar,ADM1:ADM2,DISTRICT
SM283431,14.4361,35.8736,Ħaż-Żebbuġ,ADM1:ADM2,DISTRICT
SM299022,31.6097,37.1076,İbradi,ADM2,DISTRICT
SM298918,41.8882,37.3418,İdil,ADM2,DISTRICT
SM298591,33.5697,41.209,İhsangazi,ADM2,DISTRICT
SM298363,30.4221,39.0388,İhsaniye,ADM2,DISTRICT
SM298816,37.0857,41.0679,İkizce,ADM2,DISTRICT
SM298834,40.5608,40.7771,İkizdere,ADM2,DISTRICT
SM298380,38.5641,39.4559,İliç,ADM2,DISTRICT
SM298130,35.6665,37.275,İmamoğlu,ADM2,DISTRICT
SM261912,48.0833,39.8333,İmişli,ADM1:ADM2,DISTRICT
SM298938,38.1202,39.8852,İmranlı,ADM2,DISTRICT
SM298620,35.1939,38.6379,İncesu,ADM2,DISTRICT
SM298157,27.7298,37.8624,İncirliova,ADM2,DISTRICT
SM298592,33.7657,41.9662,İnebolu,ADM2,DISTRICT
SM298257,29.5368,40.0119,İnegöl,ADM2,DISTRICT
SM298195,30.3837,40.0509,İnhisar,ADM2,DISTRICT
SM298416,30.1604,39.8253,İnönü,ADM2,DISTRICT
SM298343,26.3978,40.932,İpsala,ADM2,DISTRICT
SM298364,30.75,38.939,İscehisar,ADM2,DISTRICT
SM298295,34.4791,40.7436,İskilip,ADM2,DISTRICT
SM298421,36.7097,36.9653,İslahiye,ADM2,DISTRICT
SM261914,48.1667,40.8333,İsmayıllı,ADM1:ADM2,DISTRICT
SM298403,40.9984,40.4831,İspir,ADM2,DISTRICT
SM298164,27.4959,39.5932,İvrindi,ADM2,DISTRICT
SM298835,40.3778,41.0091,İyidere,ADM2,DISTRICT
SM298258,29.7264,40.4394,İznik,ADM2,DISTRICT
SM288885,19.0382,51.5588,Łask,ADM2,DISTRICT
SM289029,22.2689,50.0866,Łańcut,ADM2,DISTRICT
SM288824,15.4724,53.6729,Łobez,ADM2,DISTRICT
SM288695,22.0825,53.2069,Łomża,ADM2,DISTRICT
SM288694,22.0589,53.1827,Łomża (City),ADM2,DISTRICT
SM288952,22.8451,52.2236,Łosice,ADM2,DISTRICT
SM288889,19.9461,52.1063,Łowicz,ADM2,DISTRICT
SM288896,22.2404,51.8537,Łuków,ADM2,DISTRICT
SM288887,19.701,51.6862,Łódzki Wschodni,ADM2,DISTRICT
SM2026,19.4333,51.6,Łódzkie,ADM1,DISTRICT
SM288888,19.4829,51.7653,Łódź,ADM2,DISTRICT
SM288892,22.967,51.3043,Łęczna,ADM2,DISTRICT
SM288886,19.195,52.1061,Łęczyca,ADM2,DISTRICT
SM279374,140.343,35.5128,Ōamishirasato,ADM2,DISTRICT
SM278053,136.9542,35.0192,Ōbu,ADM2,DISTRICT
SM279255,132.4881,35.1396,Ōda,ADM2,DISTRICT
SM278568,140.55,40.3,Ōdate,ADM2,DISTRICT
SM278447,141.7167,39.0667,Ōfunato,ADM2,DISTRICT
SM279553,140.3361,38.5977,Ōishida,ADM2,DISTRICT
SM279005,131.6409,33.1798,Ōita,ADM2,DISTRICT
SM279699,130.3825,33.2074,Ōkawa,ADM2,DISTRICT
SM279700,130.4399,33.2129,Ōki,ADM2,DISTRICT
SM278833,137.75,36.55,Ōmachi,ADM2,DISTRICT
SM279462,139.275,35.7881,Ōme,ADM2,DISTRICT
SM278874,129.993,32.9398,Ōmura,ADM2,DISTRICT
SM279701,130.4691,33.0462,Ōmuta,ADM2,DISTRICT
SM279647,136.617,35.9084,Ōno,ADM2,DISTRICT
SM279703,130.4793,33.513,Ōnojō,ADM2,DISTRICT
SM279110,135.5489,34.4965,Ōsakasayama,ADM2,DISTRICT
SM278504,130.9721,31.4518,Ōsaki,ADM2,DISTRICT
SM278731,140.8467,38.6801,Ōsaki,ADM2,DISTRICT
SM278132,132.8917,34.2443,Ōsakikamijima,ADM2,DISTRICT
SM278448,141.0948,39.1296,Ōshū,ADM2,DISTRICT
SM279463,139.7161,35.5613,Ōta,ADM2,DISTRICT
SM278102,139.3667,36.3,Ōta,ADM2,DISTRICT
SM278133,132.1876,34.2579,Ōtake,ADM2,DISTRICT
SM279764,140.3479,37.5446,Ōtama,ADM2,DISTRICT
SM279232,135.9245,35.102,Ōtsu,ADM2,DISTRICT
SM278449,141.8141,39.423,Ōtsuchi,ADM2,DISTRICT
SM279610,138.9199,35.6429,Ōtsuki,ADM2,DISTRICT
SM278679,135.6861,34.9004,Ōyamazaki,ADM2,DISTRICT
SM279627,132.5443,33.5062,Ōzu,ADM2,DISTRICT
SM289097,129.6555,41.4422,Ŏrang,ADM2,DISTRICT
SM3252,18.9333,50.3333,Śląskie,ADM1,DISTRICT
SM288807,17.0585,52.0615,Śrem,ADM2,DISTRICT
SM288808,17.3069,52.1801,Środa Wielkopolska,ADM2,DISTRICT
SM288660,16.619,51.1263,Środa Śląska,ADM2,DISTRICT
SM288663,16.4304,50.8705,Świdnica,ADM2,DISTRICT
SM288903,22.854,51.1247,Świdnik,ADM2,DISTRICT
SM288830,15.8986,53.8076,Świdwin,ADM2,DISTRICT
SM288928,15.4852,52.238,Świebodzin,ADM2,DISTRICT
SM288851,18.4116,53.4813,Świecie,ADM2,DISTRICT
SM288831,14.3131,53.8502,Świnoujście,ADM2,DISTRICT
SM288737,18.9102,50.298,Świętochłowice,ADM2,DISTRICT
SM3379,20.75,50.75,Świętokrzyskie,ADM1,DISTRICT
SM298276,33.2833,40.4833,Şabanözü,ADM2,DISTRICT
SM261946,45.5833,39.4167,Şahbuz,ADM1:ADM2,DISTRICT
SM298426,37.3782,37.0374,Şahinbey,ADM2,DISTRICT
SM298965,39.178,40.9461,Şalpazarı,ADM2,DISTRICT
SM261916,48.6667,40.5833,Şamaxı,ADM1:ADM2,DISTRICT
SM298699,29.2274,39.036,Şaphane,ADM2,DISTRICT
SM298482,31.377,38.0902,Şarkikaraağaç,ADM2,DISTRICT
SM298947,27.1007,40.6251,Şarköy,ADM2,DISTRICT
SM298926,36.4183,39.3617,Şarkışla,ADM2,DISTRICT
SM298142,42.3694,41.2494,Şavşat,ADM2,DISTRICT
SM299027,34.7639,39.5008,Şefaatli,ADM2,DISTRICT
SM298427,37.3697,37.1722,Şehitkamil,ADM2,DISTRICT
SM298453,44.5802,37.3132,Şemdinli,ADM2,DISTRICT
SM298395,42.3452,40.5622,Şenkaya,ADM2,DISTRICT
SM298596,33.2177,41.8165,Şenpazar,ADM2,DISTRICT
SM298516,29.6185,41.129,Şile,ADM2,DISTRICT
SM298449,39.1248,40.1908,Şiran,ADM2,DISTRICT
SM298881,42.0278,38.0629,Şirvan,ADM2,DISTRICT
SM298517,29.0001,41.1251,Şişli,ADM2,DISTRICT
SM3264,28.6667,47.8333,Şoldăneşti,ADM1,DISTRICT
SM3323,29.6667,46.5,Ştefan Voda,ADM1,DISTRICT
SM298369,30.5547,38.5408,Şuhut,ADM2,DISTRICT
SM298434,38.4145,40.2946,Şultan Karahisar,ADM2,DISTRICT
SM298897,33.548,38.9545,Şultan Koçhisar,ADM2,DISTRICT
SM261889,46.75,39.75,Şuşa (CIty)  (Nagorno-Karabakh),ADM1:ADM2,DISTRICT
SM261890,46.664,39.6777,Şuşa (excl. Şuşa city),ADM1:ADM2,DISTRICT
SM261956,47.1667,41.0833,Şəki (excl. Şəki city),ADM1:ADM2,DISTRICT
SM261957,47.1667,41.1833,Şəki City,ADM1:ADM2,DISTRICT
SM261926,46.0833,40.8333,Şəmkir,ADM1:ADM2,DISTRICT
SM281341,23.05,54.95,Šakių,ADM2,DISTRICT
SM296149,17.8731,48.1477,Šala,ADM2,DISTRICT
SM296371,16.2783,46.8311,Šalovci,ADM1:ADM2,DISTRICT
SM281321,25.3833,54.3,Šalčininkų,ADM2,DISTRICT
SM313212,18.4978,44.9836,Šamac,ADM2:ADM3,DISTRICT
SM305328,17.029,45.9041,Šandrovac,ADM2,DISTRICT
SM3162,19.0939,42.9564,Šavnik,ADM1,DISTRICT
SM313238,18.835,44.295,Šekovići,ADM2:ADM3,DISTRICT
SM296248,13.6522,45.9119,Šempeter-Vrtojba,ADM1:ADM2,DISTRICT
SM305348,16.4185,46.407,Šenkovec,ADM2,DISTRICT
SM296342,15.7175,46.6883,Šentilj,ADM1:ADM2,DISTRICT
SM296262,15.3336,45.8236,Šentjernej,ADM1:ADM2,DISTRICT
SM296219,15.4306,46.1789,Šentjur pri Celju,ADM1:ADM2,DISTRICT
SM296195,14.4233,46.2381,Šenčur,ADM1:ADM2,DISTRICT
SM305486,16.9135,43.454,Šestanovac,ADM2,DISTRICT
SM3213,23.3333,56,Šiauliai,ADM1,DISTRICT
SM275982,15.896,43.7358,Šibenik,ADM2,DISTRICT
SM3215,15.9167,43.8333,Šibensko-Kninska,ADM1,DISTRICT
SM281355,22.2,55.4667,Šilalės,ADM2,DISTRICT
SM313190,17.1278,44.2125,Šipovo,ADM2:ADM3,DISTRICT
SM281322,24.95,55.05,Širvintų,ADM2,DISTRICT
SM305599,15.4515,44.0895,Škabrnja,ADM2,DISTRICT
SM296263,15.3014,45.9197,Škocjan,ADM1:ADM2,DISTRICT
SM296196,14.2817,46.1631,Škofja Loka,ADM1:ADM2,DISTRICT
SM296312,14.5772,45.9583,Škofljica,ADM1:ADM2,DISTRICT
SM296222,15.525,46.2107,Šmarje pri Jelšah,ADM1:ADM2,DISTRICT
SM296223,15.0306,46.3431,Šmartno ob Paki,ADM1:ADM2,DISTRICT
SM305488,16.2887,43.3871,Šolta,ADM2,DISTRICT
SM296225,15.0083,46.4131,Šoštanj,ADM1:ADM2,DISTRICT
SM305525,17.3009,45.8574,Špišić Bukovica,ADM2,DISTRICT
SM305331,16.7149,45.8022,Štefanje,ADM2,DISTRICT
SM304760,21.0167,42.4167,Štimlje,ADM2,DISTRICT
SM3326,22.2,41.7,Štip,ADM1,DISTRICT
SM296226,15.3272,46.2011,Štore,ADM1:ADM2,DISTRICT
SM305350,16.2865,46.5005,Štrigova,ADM2,DISTRICT
SM304761,21,42.2333,Štrpce,ADM2,DISTRICT
SM3361,20.9178,44.0022,Šumadijski,ADM1:ADM2,DISTRICT
SM271240,17,50,Šumperk,ADM2,DISTRICT
SM3374,21.4167,42.05,Šuto Orizari,ADM1:ADM2,DISTRICT
SM281323,26.1667,55.15,Švenčionių,ADM2,DISTRICT
SM289198,124.6686,40.2114,Ŭiju,ADM2,DISTRICT
SM289157,125.4547,38.6219,Ŭnch'ŏn,ADM2,DISTRICT
SM289101,130.1197,42.3846,Ŭndŏk,ADM2,DISTRICT
SM289066,125.8644,39.1996,Ŭnjŏng,ADM2,DISTRICT
SM289137,125.8558,38.35,Ŭnp'a,ADM2,DISTRICT
SM289158,125.2128,38.5617,Ŭnryul,ADM2,DISTRICT
SM289067,126.1267,39.4169,Ŭnsan,ADM2,DISTRICT
SM288917,15.4225,51.5947,Żagań,ADM2,DISTRICT
SM288918,14.9617,51.6286,Żary,ADM2,DISTRICT
SM288859,17.7374,52.8226,Żnin,ADM2,DISTRICT
SM288744,18.6884,50.039,Żory,ADM2,DISTRICT
SM288985,19.891,53.0166,Żuromin,ADM2,DISTRICT
SM288987,20.4328,52.0003,Żyrardów,ADM2,DISTRICT
SM288745,19.2137,49.6115,Żywiec,ADM2,DISTRICT
SM3816,19.1233,43.1572,Žabljak,ADM1,DISTRICT
SM296233,15.1727,46.2551,Žalec,ADM1:ADM2,DISTRICT
SM296115,18.7208,48.4844,Žarnovica,ADM2,DISTRICT
SM296198,14.1058,46.2125,Železniki,ADM1:ADM2,DISTRICT
SM3847,21.1333,41.9333,Želino,ADM1,DISTRICT
SM296351,15.8058,46.2822,Žetale,ADM1:ADM2,DISTRICT
SM296116,18.8667,48.5833,Žiar nad Hronom,ADM2,DISTRICT
SM296183,18.7404,49.223,Žilina,ADM2,DISTRICT
SM3856,19.1667,49.1667,Žilinský,ADM1,DISTRICT
SM296199,14.1156,46.0517,Žiri,ADM1:ADM2,DISTRICT
SM296200,14.1757,46.4124,Žirovnica,ADM1:ADM2,DISTRICT
SM305683,13.9082,45.1419,Žminj,ADM2,DISTRICT
SM305627,15.4155,45.767,Žumberak,ADM2,DISTRICT
SM305645,18.1948,42.6268,Župa Dubrovačka,ADM2,DISTRICT
SM296266,14.9497,45.8047,Žužemberk,ADM1:ADM2,DISTRICT
SM271220,16.0333,49.5,Žďár nad Sázavou,ADM2,DISTRICT
SM261894,48.8818,39.9604,Əli Bayramlı,ADM1:ADM2,DISTRICT
SM304132,105.8092,20.7087,Ứng Hòa,ADM2,DISTRICT`

map1 = parseCsvData(map1);

export default map1;